import React from 'react';

export const WatchIcon = () => {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.8436 0.0784626C7.09356 0.467525 3.80762 2.50659 1.81075 5.68471C-1.0205 10.1894 -0.486128 16.1707 3.09512 20.066C5.08731 22.2316 7.55293 23.5207 10.5154 23.9425C11.0732 24.0222 12.9154 24.0175 13.492 23.9378C16.4076 23.5347 18.8732 22.2503 20.8607 20.0941C22.4967 18.3222 23.542 16.0582 23.9076 13.5035C23.9873 12.9503 23.9873 11.1035 23.9076 10.5503C23.5186 7.81753 22.4029 5.52065 20.5561 3.64096C18.6623 1.7144 16.342 0.537838 13.6561 0.1394C13.0795 0.0550251 11.4154 0.0175247 10.8436 0.0784626ZM13.5389 1.92534C17.1998 2.49253 20.2842 5.00971 21.5732 8.47846C22.9654 12.2144 22.0232 16.4425 19.1639 19.2925C16.0092 22.4378 11.0826 23.1457 7.14981 21.0175C4.29981 19.4753 2.38731 16.7472 1.88106 13.5222C1.76856 12.7816 1.76856 11.2722 1.88106 10.5316C2.60762 5.8769 6.27325 2.37534 10.9607 1.85971C11.5139 1.79877 12.9482 1.83628 13.5389 1.92534Z"
                    fill="#F08201"
                />
                <path
                    d="M11.6406 4.21777C11.4578 4.30215 11.2656 4.5084 11.1766 4.72402C11.1109 4.87871 11.1016 5.35215 11.1016 8.5209C11.1016 10.7475 11.1203 12.21 11.1484 12.3084C11.2 12.4959 11.4813 12.7959 11.6781 12.8662C11.8609 12.9365 17.1859 12.9365 17.3688 12.8662C17.6688 12.7537 17.9453 12.3553 17.9453 12.0271C17.9453 11.8256 17.8094 11.5256 17.6594 11.385C17.3922 11.1412 17.3641 11.1365 15.0391 11.1365H12.8828V8.00527C12.8828 5.06152 12.8734 4.86934 12.7938 4.69121C12.6906 4.4709 12.4938 4.27402 12.3063 4.20371C12.1281 4.1334 11.8094 4.14277 11.6406 4.21777Z"
                    fill="#F08201"
                />
            </svg>
        </div>
    );
};
