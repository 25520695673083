import React from 'react';

export const DragIcon = () => {
    return (
        <div>
            <svg width="26" height="20" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="12" width="20" height="4" rx="2" fill="black" />
                <rect y="6" width="20" height="4" rx="2" fill="black" />
                <rect width="20" height="4" rx="2" fill="black" />
            </svg>
        </div>
    );
};
