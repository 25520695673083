import React, { useState } from 'react';

import { SearchInput } from 'components/molecules/SearchInput';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
const TDoperations = ({ handleFilterByBranch, searchByItem, branches, _data, selectedBranch }) => {
    const [search_term, set_search_term] = useState('');

    const handleInputChange = (e, type) => {
        if (type === 'search') {
            searchByItem(e.target.value);
            set_search_term(e.target.value);
        } else {
            handleFilterByBranch(e);
        }
    };

    const resetFilter = () => {
        set_search_term('');

        handleFilterByBranch('');
        searchByItem('');
    };

    return (
        <div className="md:px-10 px-5 ">
            <div className="grid md:grid-cols-2 gap-3">
                <div>
                    <SearchInput
                        width="300px"
                        value={search_term}
                        atChange={(e) => {
                            console.error('');
                            handleInputChange(e, 'search');
                        }}
                        placeholder="Search"
                    />
                </div>

                <div className="flex md:justify-end gap-3 items-center">
                    <div className="max-w-[280px]">
                        {' '}
                        <SimpleSelectMenu
                            placeholder="Filter By Branch"
                            targetProperty="title"
                            selectedValue={selectedBranch}
                            valuePropertyName="business_branch_id"
                            optionsData={branches}
                            sx="rounded-xl py-3 bg-transparent ring-gray-400 td-select min-w-[150px]"
                            onChangeValue={(obj) => handleInputChange(obj.business_branch_id, 'branch')}
                        />
                    </div>

                    {selectedBranch && (
                        <div onClick={resetFilter} className="td-generic flex justify-center items-center">
                            <button className="px-10 py-2 rounded-md">Reset</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TDoperations;
