/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { SearchInput } from 'components/molecules/SearchInput';
import React, { useEffect, useState } from 'react';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { motion } from 'framer-motion';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'components/atoms/buttons/Button';
import { formateDateAndTime } from 'utills/moment';
import { getSummaryForBusinessThunk, getsummaryDetailChartThunk } from 'store/deductions';
import moment from 'moment';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { Menu } from 'components/molecules/Menu';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { BUSINESS_ID, SORT_OPTIONS } from 'utills/globalVars';
import { DatePick } from 'components/atoms/inputs/DatePick';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { MixedChart } from 'components/molecules/MixedChart';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { toastHandler } from 'responseHanlder';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import dayjs from 'dayjs';
export const SummaryListing = ({
    record_id,
    business_client_id,
    deduction_type,
    toggleSummary,
    toggleProfitAndLoss,
    toggleRentalSchdule
}) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const business_id = localStorage.getItem(BUSINESS_ID);

    const [sortAsc, setSortAsc] = useState(false);

    const [SummaryFromDate, setSummaryFromDate] = useState('');
    const [SummaryToDate, setSummaryToDate] = useState('');
    const [SortBy, setSortBy] = useState('DESC');
    const [SummaryP, setSummaryP] = useState(1);
    const [SummaryList, setSummaryList] = useState([]);
    const [summaryPS, SetSummaryPS] = useState(10);
    const [summaryCount, SetSummaryCount] = useState(0);
    const [summary_id, setSummaryId] = useState('');
    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [expenseV, setExpenseV] = useState([]);
    const [incomeV, setIncomeV] = useState([]);
    const [monthV, setMonthV] = useState([]);

    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [chartTitle, setChartTitle] = useState('');
    const [customizeRow, setCustomizeRow] = useState(false);

    const headings = ['Ref No', 'Expense Reported', 'Income Reported', 'Date and Time', 'Action'];

    useEffect(() => {
        const currentFinancialYearStart =
            moment().month() < 6
                ? moment().subtract(1, 'year').month(6).date(1).startOf('month')
                : moment().month(6).date(1).startOf('month');
        const currentFinancialYearEnd =
            moment().month() < 6
                ? moment().month(5).date(30).endOf('month')
                : moment().add(1, 'year').month(5).date(30).endOf('month');

        const lastFinancialYearStart =
            moment().month() < 6
                ? moment().subtract(2, 'years').month(6).date(1).startOf('month')
                : moment().subtract(1, 'year').month(6).date(1).startOf('month');
        const lastFinancialYearEnd =
            moment().month() < 6
                ? moment().subtract(1, 'year').month(5).date(30).endOf('month')
                : moment().month(5).date(30).endOf('month');

        const quarters = [];
        for (let i = 0; i < 4; i++) {
            let quarterStart, quarterEnd;
            switch (i) {
                case 0:
                    quarterStart = moment().month(6).date(1).startOf('month');
                    quarterEnd = moment().month(8).date(30).endOf('month');
                    break;
                case 1:
                    quarterStart = moment().month(9).date(1).startOf('month');
                    quarterEnd = moment().month(11).date(31).endOf('month');
                    break;
                case 2:
                    quarterStart = moment().month(0).date(1).startOf('month');
                    quarterEnd = moment().month(2).date(31).endOf('month');
                    break;
                case 3:
                    quarterStart = moment().month(3).date(1).startOf('month');
                    quarterEnd = moment().month(5).date(30).endOf('month');
                    break;
                default:
                    break;
            }
            quarters.push({
                title: `Quarter ${i + 1}`,
                start_date: quarterStart.format('DD-MM-YYYY'),
                end_date: quarterEnd.format('DD-MM-YYYY')
            });
        }

        const last6MonthsStart = moment().subtract(6, 'months').startOf('month');
        const last6MonthsEnd = moment().subtract(1, 'months').endOf('month');

        setFilters([
            {
                title: 'Last Financial Year',
                start_date: lastFinancialYearStart.format('DD-MM-YYYY'),
                end_date: lastFinancialYearEnd.format('DD-MM-YYYY')
            },
            {
                title: 'Current Financial Year',
                start_date: currentFinancialYearStart.format('DD-MM-YYYY'),
                end_date: currentFinancialYearEnd.format('DD-MM-YYYY')
            },
            ...quarters,
            {
                title: 'Last 6 Months',
                start_date: last6MonthsStart.format('DD-MM-YYYY'),
                end_date: last6MonthsEnd.format('DD-MM-YYYY')
            }
        ]);

        if (summary_id && currentFinancialYearStart && currentFinancialYearEnd) {
            const payload = {
                summary_id: summary_id,
                start_date: currentFinancialYearStart.format('DD-MM-YYYY'),
                end_date: currentFinancialYearEnd.format('DD-MM-YYYY'),
                business_client_id: id
            };
            getChartData(payload);
        }
    }, [summary_id]);

    useEffect(() => {
        setCustomizeRow(selectedFilter?.title === 'Customize' ? true : false);

        if (selectedFilter && selectedFilter.start_date && selectedFilter.end_date) {
            const payload = {
                summary_id: summary_id,
                start_date: selectedFilter?.start_date,
                end_date: selectedFilter?.end_date,
                business_client_id: id
            };
            getChartData(payload);
        }
    }, [selectedFilter]);

    const datePickerProps = {
        right: CalendarSmallIco
    };
    const onClickItem = (summary_id, summary_type) => {
        setSummaryId(summary_id);
        updateChartTitleFun(summary_type);
    };

    const location = useLocation();
    console.error('location', location);
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get('summary_id');
    const summary_type = queryParams.get('summary_type');
    useEffect(() => {
        if (queryId) {
            onClickItem(queryId, summary_type);
        }
    }, [queryId]);

    const updateChartTitleFun = (summary_type) => {
        if (summary_type === 'income') {
            setChartTitle('Reported Income Financial Graph');
        } else if (summary_type === 'expense') {
            setChartTitle('Reported Expenses Financial Graph');
        } else {
            setChartTitle('Reported Income-Expenses Financial Graph');
        }
    };

    const getChartData = (payload) => {
        if (payload) {
            dispatch(getsummaryDetailChartThunk(payload))
                .then((response) => {
                    if (response?.payload?.expense) {
                        const month = [];
                        const expnese = [];
                        const income = [];
                        for (const item in response?.payload?.expense) {
                            month.push(item);
                            expnese.push(response?.payload?.expense[item].value ?? 0);
                        }
                        for (const item in response?.payload?.income) {
                            income.push(response?.payload?.income[item].value ?? 0);
                        }
                        const sortedXv = month.sort((a, b) => a - b);
                        setMonthV(sortedXv);
                        setExpenseV(expnese);
                        setIncomeV(income);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {});
        }
    };

    const getSummaryforBusinessDef = (data) => {
        setHasMoreContent(true);
        const payload = {
            deduction_type: data.deduction_type,
            business_id: data.business_id,
            business_client_id: data.business_client_id,
            from_date: data?.from,
            to_date: data?.to,
            record_id: data?.record_id,
            sort_by: data?.sort_by ?? 'ASC',
            search_term: data?.search_term ?? '',
            page: data?.page ?? 1,
            page_size: data?.page_size ?? 10
        };
        dispatch(getSummaryForBusinessThunk(payload))
            .then((response) => {
                if (response.payload) {
                    SetSummaryCount(response?.payload?.count);
                    if (data?.filter) {
                        const list = [...response.payload.rows];
                        if (list.length === response?.payload?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                    } else {
                        const list = [...SummaryList, ...response.payload.rows];
                        if (list.length === response?.payload?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                        if (summary_id === '') {
                            setSummaryId(response?.payload?.rows[0]?.summary_id);
                            updateChartTitleFun(response?.payload?.rows[0]?.summary_type);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const handleSummarySearch = (term) => {
        setSearchTerm(term);
    };
    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        setSortBy(sortAsc ? 'ASC' : 'DESC');
    };
    const handleSummaryFromDateChange = (date) => {
        setSummaryFromDate(date);
    };
    const handleSummaryToDateChange = (date) => {
        setSummaryToDate(date);
    };
    const handleSummaryClear = () => {
        setSummaryFromDate('');
        setSummaryToDate('');
        setSearchTerm('');
        setSummaryP(1);
        setSummaryList([]);
        getSummaryforBusinessDef({
            deduction_type: deduction_type,
            business_id: business_id,
            business_client_id: business_client_id,
            from: '',
            to: '',
            record_id: record_id,
            sort_by: 'ASC',
            search_term: '',
            page: SummaryP,
            page_size: PAGINATION_PAGE_SIZE,
            filter: true
        });
    };

    const onFilterChange = () => {
        if (record_id) {
            const fromdate =
                SummaryFromDate === '' ? '' : moment(new Date(SummaryFromDate)).format('DD-MM-YYYY').toString();
            const todate =
                SummaryFromDate === '' ? '' : moment(new Date(SummaryToDate)).format('DD-MM-YYYY').toString();

            if (moment(todate).isBefore(moment(fromdate))) {
                return toastHandler('test', 'error');
            }

            setSummaryList([]);
            setSummaryP(1);
            getSummaryforBusinessDef({
                deduction_type: deduction_type,
                business_id: business_id,
                business_client_id: business_client_id,
                from: fromdate,
                to: todate,
                record_id: record_id,
                sort_by: SortBy,
                search_term: searchTerm,
                page: SummaryP,
                page_size: summaryPS,
                filter: true
            });
        }
    };
    useEffect(() => {
        if (SummaryFromDate && SummaryToDate) {
            onFilterChange();
        }
    }, [SummaryFromDate, SummaryToDate]);

    useEffect(() => {
        onFilterChange();
    }, [searchTerm, sortAsc]);

    const getsummary = () => {
        const fromdate =
            SummaryFromDate === '' ? '' : moment(new Date(SummaryFromDate)).format('DD-MM-YYYY').toString();
        const todate = SummaryToDate === '' ? '' : moment(new Date(SummaryToDate)).format('DD-MM-YYYY').toString();
        if (record_id) {
            getSummaryforBusinessDef({
                deduction_type: deduction_type,
                business_id: business_id,
                business_client_id: id,
                from: fromdate,
                to: todate,
                record_id: record_id,
                sort_by: SortBy,
                search_term: searchTerm,
                page: SummaryP,
                page_size: summaryPS,
                filter: false
            });
        }
    };
    useEffect(() => {
        getsummary();
    }, [SummaryP]);

    const handleMoreSummry = () => {
        const totalPages = Math.ceil(summaryCount / summaryPS);
        if (SummaryP < totalPages) {
            setSummaryP(SummaryP + 1);
        }
    };

    return (
        <div>
            <hr className="mt-2" />
            <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.1, type: 'easeIn', duration: 0.4 }}
                className="my-10"
            >
                <div className="md:grid grid-cols-12 gap-3">
                    <div className="col-span-8 ">
                        <div className="border rounded-lg  mx-2">
                            <div className="mt-5 bg-white rounded-lg">
                                <div className="mx-5 py-5 flex items-center justify-between gap-5">
                                    <div className="detail-card-title text-2xl ">
                                        {' '}
                                        {deduction_type === 'rental_property' || deduction_type === 'abn'
                                            ? 'Received Summary'
                                            : 'Sent Summary'}{' '}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        {SummaryToDate || SummaryFromDate || searchTerm ? (
                                            <ClearFilterButton atClick={() => handleSummaryClear()} />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="flex gap-5 items-center justify-between flex-wrap mx-5  mb-5">
                                    <SearchInput
                                        width="300px"
                                        atChange={(e) => {
                                            handleSummarySearch(e.target.value);
                                        }}
                                        value={searchTerm}
                                        placeholder="Search"
                                        inputBorder="1px solid #DCDCDC"
                                    />

                                    <div className="flex gap-3 items-center">
                                        <DatePick
                                            iconSet={datePickerProps}
                                            onDateChange={handleSummaryFromDateChange}
                                            placeholder="From"
                                            value={SummaryFromDate}
                                            bg="transparent"
                                            sx="!rounded-xl text-gray-900"
                                        />
                                        <DatePick
                                            iconSet={datePickerProps}
                                            onDateChange={handleSummaryToDateChange}
                                            placeholder="To"
                                            value={SummaryToDate}
                                            bg="transparent"
                                            sx="!rounded-xl text-gray-900"
                                        />
                                        <div>
                                            <Menu
                                                title="Order By"
                                                iconSet={{
                                                    first: SortIcon,
                                                    second: StepDownIcon
                                                }}
                                                options={SORT_OPTIONS}
                                                gap={5}
                                                lastMl={5}
                                                isModalDisabled={true}
                                                condition={sortAsc}
                                                atMenuClick={() => {
                                                    handleSortByDate();
                                                }}
                                                atClick={(title) => {}}
                                                text="max-md"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="default-table-container relative overflow-auto">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-2 lg:px-4">
                                        <InfiniteScrollComp
                                            height={250}
                                            dataLength={SummaryList.length}
                                            next={handleMoreSummry}
                                            hasMore={hasMoreContent || false}
                                            loader={<SkeletonTable columns={4} />}
                                        >
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            {deduction_type === 'rental_property'
                                                                ? 'Ref. No'
                                                                : 'Summary Id'}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            Income Reported{' '}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            Expense Reported
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            Date and Time
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody className="divide-y divide-gray-200">
                                                    {SummaryList?.map((item, index) => (
                                                        <tr
                                                            key={index}
                                                            onClick={() =>
                                                                onClickItem(item.summary_id, item.summary_type)
                                                            }
                                                            className={`mt-2 ${
                                                                summary_id === item?.summary_id
                                                                    ? 'summary-bg-color rounded-xl overlow-hidden'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <td className="py-4 pl-3 pr-3 text-sm font-medium text-gray-900 min-w-[150px] max-w-[200px] !break-all">
                                                                {item?.ref_no}
                                                            </td>
                                                            <td className="px-3 py-4 text-sm text-gray-900">
                                                                {item?.summary_type === 'income' ||
                                                                item?.summary_type === 'all'
                                                                    ? `$${item?.income}`
                                                                    : 'N/A'}
                                                            </td>
                                                            <td className="px-3 py-4 text-sm text-gray-900">
                                                                {item?.summary_type === 'expense' ||
                                                                item?.summary_type === 'all'
                                                                    ? `$${item?.expense}`
                                                                    : 'N/A'}
                                                            </td>
                                                            <td className="px-3 py-4 text-sm text-gray-900">
                                                                {formateDateAndTime(item?.created_at)}
                                                            </td>
                                                            <td className="relative py-4 pl-3 pr-3 text-right text-sm font-medium sm:pr-2">
                                                                <div className="flex gap-2 items-center col-span-2 flex-nowrap sm:flex-wrap">
                                                                    {deduction_type === 'rental_property' ? (
                                                                        <Button
                                                                            title="Rental Schedule"
                                                                            classes="border border-blueish  font-fira text-md font-font4 rounded-lg !text-sm px-2.5 py-2"
                                                                            atClick={() => {
                                                                                toggleRentalSchdule(item?.summary_id);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            disabled={
                                                                                item.summary_type !== 'all'
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            title="Profit/Loss"
                                                                            classes="border border-blueish  font-fira text-md font-font4 rounded-lg !text-sm px-3 py-2"
                                                                            atClick={() => {
                                                                                if (item.summary_type === 'all') {
                                                                                    toggleProfitAndLoss(
                                                                                        item?.summary_id
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}

                                                                    <Button
                                                                        title="View Summary"
                                                                        classes="primary-bg border border-blueish text-white font-fira text-md font-font4 rounded-lg !text-sm px-3 py-2"
                                                                        atClick={() =>
                                                                            toggleSummary(
                                                                                item?.summary_id,
                                                                                item?.from_date,
                                                                                item?.to_date,
                                                                                item?.summary_type,
                                                                                item?.deduction_type
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </InfiniteScrollComp>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-4">
                        <div className=" rounded-lg  mr-2 border">
                            {' '}
                            <div className="detail-card-title p-3 px-4 text-2xl">
                                {chartTitle || 'Reported Income-Expenses Financial Graph'}
                            </div>
                            <div className="flex items-center justify-between p-2 gap-3 flex-wrap">
                                <div className="text-lg text-semibold">Filter</div>
                                <div className="w-[230px]">
                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="title"
                                        valuePropertyName={'title'}
                                        selectedValue={filters.length > 0 ? filters[1]?.title : ''}
                                        optionsData={filters || []}
                                        sx="rounded-xl py-2 ring-gray-400 bg-white !font-normal"
                                        onChangeValue={(obj) => {
                                            setSelectedFilter(obj);
                                        }}
                                    />
                                </div>
                            </div>
                            {customizeRow && (
                                <div className="flex items-center justify-between p-2 gap-3">
                                    <div className="w-[230px]">
                                        <DatePick
                                            iconSet={datePickerProps}
                                            onDateChange={(date) => {
                                                setSelectedFilter({
                                                    ...selectedFilter,
                                                    start_date: date ? dayjs(date).format('DD-MM-YYYY') : ''
                                                });
                                            }}
                                            placeholder="From"
                                            value={
                                                selectedFilter?.start_date && selectedFilter?.start_date !== ''
                                                    ? dayjs(selectedFilter?.start_date, 'DD-MM-YYYY')
                                                    : null
                                            }
                                            bg="transparent"
                                            sx="!rounded-xl text-gray-900"
                                        />
                                    </div>
                                    <div className="w-[230px]">
                                        <DatePick
                                            iconSet={datePickerProps}
                                            onDateChange={(date) => {
                                                setSelectedFilter({
                                                    ...selectedFilter,
                                                    end_date: date ? dayjs(date).format('DD-MM-YYYY') : ''
                                                });
                                            }}
                                            placeholder="To"
                                            value={
                                                selectedFilter?.end_date && selectedFilter?.end_date !== ''
                                                    ? dayjs(selectedFilter?.end_date, 'DD-MM-YYYY')
                                                    : null
                                            }
                                            bg="transparent"
                                            sx="!rounded-xl text-gray-900"
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="flex items-center gap-5 mx-5 mt-3">
                                <div className="flex items-center gap-2">
                                    <div className="pre-week"></div>
                                    <div className="week-label">Income</div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="this-week"></div>
                                    <div className="week-label">Expense</div>
                                </div>
                            </div>
                            <MixedChart expenseValues={expenseV} incomeValues={incomeV} months={monthV} />
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};
