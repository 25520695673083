import React from "react";

export const ArrowUpdown = () => {
  return (
    <div>
      {" "}
      <svg
        width="8"
        height="13"
        viewBox="0 0 10 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.13397 1.5C4.51887 0.833334 5.48113 0.833333 5.86603 1.5L8.4641 6C8.849 6.66667 8.36788 7.5 7.59808 7.5H2.40192C1.63212 7.5 1.151 6.66667 1.5359 6L4.13397 1.5Z"
          fill="#B695F8"
        />
        <path
          d="M5.86603 18.5C5.48113 19.1667 4.51887 19.1667 4.13397 18.5L1.5359 14C1.151 13.3333 1.63212 12.5 2.40192 12.5L7.59808 12.5C8.36788 12.5 8.849 13.3333 8.4641 14L5.86603 18.5Z"
          fill="#B695F8"
        />
      </svg>
    </div>
  );
};
