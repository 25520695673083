import React from 'react';

export const BgNotePencil = () => {
    return (
        <div>
            <svg width="26" height="26" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.05" width="36" height="36" rx="18" fill="url(#paint0_linear_179_9083)" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.8029 10.1761C23.0836 10.0598 23.3844 10 23.6881 10C23.9919 10 24.2927 10.0598 24.5733 10.1761C24.854 10.2923 25.109 10.4627 25.3237 10.6775C25.5385 10.8923 25.7089 11.1473 25.8252 11.4279C25.9414 11.7086 26.0012 12.0093 26.0012 12.3131C26.0012 12.6169 25.9414 12.9177 25.8252 13.1983C25.7089 13.4789 25.5385 13.7339 25.3237 13.9487L20.4234 18.849C20.0685 19.2039 19.6175 19.445 19.1261 19.5434C19.126 19.5434 19.1261 19.5433 19.1261 19.5434L16.8603 19.9968C16.6219 20.0446 16.3753 19.97 16.2034 19.7981C16.0314 19.6261 15.9567 19.3797 16.0043 19.1412L16.457 16.8754C16.5556 16.384 16.7976 15.9321 17.1522 15.5778L22.0525 10.6775C22.2673 10.4627 22.5223 10.2923 22.8029 10.1761ZM23.6881 11.4546C23.5754 11.4546 23.4638 11.4768 23.3596 11.52C23.2554 11.5631 23.1608 11.6263 23.0811 11.7061L22.2148 12.5723L23.4292 13.7861L24.2952 12.9202C24.3749 12.8404 24.4381 12.7458 24.4813 12.6416C24.5244 12.5375 24.5466 12.4259 24.5466 12.3131C24.5466 12.2004 24.5244 12.0887 24.4813 11.9846C24.4381 11.8804 24.3749 11.7858 24.2952 11.7061C24.2155 11.6263 24.1208 11.5631 24.0167 11.52C23.9125 11.4768 23.8009 11.4546 23.6881 11.4546ZM22.4007 14.8147L21.1863 13.6009L18.1808 16.6064C18.0292 16.7577 17.9255 16.9508 17.8833 17.1607C17.8833 17.1608 17.8834 17.1606 17.8833 17.1607L17.6445 18.3564L18.8406 18.117C19.0508 18.075 19.2432 17.9721 19.3949 17.8205L22.4007 14.8147Z"
                    fill="url(#paint1_linear_179_9083)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4386 13.1655C12.1776 13.1655 11.9274 13.2692 11.7428 13.4538C11.5583 13.6383 11.4546 13.8886 11.4546 14.1495V23.5617C11.4546 23.8227 11.5583 24.073 11.7428 24.2575C11.9274 24.4421 12.1776 24.5457 12.4386 24.5457H21.8508C22.1118 24.5457 22.3621 24.4421 22.5466 24.2575C22.7311 24.073 22.8348 23.8227 22.8348 23.5617V20.9948C22.8348 20.5931 23.1604 20.2675 23.5621 20.2675C23.9638 20.2675 24.2894 20.5931 24.2894 20.9948V23.5617C24.2894 24.2085 24.0325 24.8288 23.5752 25.2861C23.1178 25.7434 22.4976 26.0003 21.8508 26.0003H12.4386C11.7919 26.0003 11.1716 25.7434 10.7143 25.2861C10.2569 24.8288 10 24.2085 10 23.5617V14.1495C10 13.5028 10.2569 12.8825 10.7143 12.4252C11.1716 11.9679 11.7919 11.7109 12.4386 11.7109H15.0056C15.4072 11.7109 15.7329 12.0366 15.7329 12.4382C15.7329 12.8399 15.4072 13.1655 15.0056 13.1655H12.4386Z"
                    fill="url(#paint2_linear_179_9083)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_179_9083"
                        x1="19.7664"
                        y1="0.447084"
                        x2="19.7394"
                        y2="36.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_179_9083"
                        x1="21.4869"
                        y1="10.1243"
                        x2="21.4794"
                        y2="20.011"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_179_9083"
                        x1="17.8458"
                        y1="11.8884"
                        x2="17.8351"
                        y2="26.0004"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
