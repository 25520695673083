import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { TripAndLogbookCard } from './TripAndLogbookCard';
import carIcon from 'files/d.png';

import { handleSummaryDates, handleSummaryType, handleSunmmaryId, setLoader } from 'store/global/globalReducer';
import { getAllTripLogsThunk } from 'store/deductions';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import { BoxSkeleton } from 'components/molecules/skeletons/BoxSkeleton';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { TripAndLogbookSummary } from 'components/organisms/TripAndLogbookSummary';
import { formatNumberWithCommas } from 'utills/uid';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { NoDataFound } from 'components/common/NoDataFound';

export const TripAndLogbook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: customer } = useSelector((state) => state.viewBusinessCustomerInfo);
    const { data: tripLogsData } = useSelector((state) => state.getAllTripLogs);

    const { isLoading } = useSelector((state) => state.global);

    const [openIndexes, setOpenIndexes] = useState([]);
    const [isDetailOpen, setIsDetailOpen] = useState(false);

    const [showSummary, setShowSummary] = useState(false);
    const [loadAtFirst, setLoadAtFirst] = useState(false);
    const [tempData, setTempData] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get('summary_id');
    const selectedSummaryType = queryParams.get('summary_type');
    const selectedVehicleId = queryParams.get('vehicle_id');
    useEffect(() => {
        if (queryId && loadAtFirst) {
            const findSummary = tripLogsData?.find((item) => item.vehicle_id === selectedVehicleId);
            const findIndex = tripLogsData.findIndex((obj) => obj.vehicle_id === selectedVehicleId);
            if (findIndex !== -1) {
                toggleDetails(findIndex, selectedVehicleId);
                toggleSummary(queryId, selectedSummaryType, '', '');
                setTempData(findSummary || null);
            }
        }
    }, [queryId, loadAtFirst]);

    useEffect(() => {
        fetchCustomer();
        getTripData();
    }, []);

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const getTripData = () => {
        const payload = {
            business_client_id: id
        };
        dispatch(setLoader(true));
        dispatch(getAllTripLogsThunk(payload)).finally(() => {
            dispatch(setLoader(false));
            setLoadAtFirst(true);
        });
    };

    const toggleSummary = (id, summary_type, from, to) => {
        if (id) {
            dispatch(handleSunmmaryId(id));
        }

        if ((from && to, summary_type)) {
            dispatch(
                handleSummaryDates({
                    from,
                    to
                })
            );
            dispatch(handleSummaryType(summary_type));
        }
        setShowSummary(!showSummary);
    };

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/customer/deductions/${id}`);
        }
    };
    const managerActions = [
        {
            title: 'View Logs',
            classes: 'flex gap-2 items-center px-8 !rounded-xl ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                console.error();
            }
        }
    ];

    const toggleDetails = (index, _rental_property_id) => {
        const newIndexes = [...openIndexes];

        const alreadyExists = newIndexes?.find((item) => item?.key === index);
        if (alreadyExists) {
            const newIndex = newIndexes?.indexOf(alreadyExists);
            newIndexes?.splice(newIndex, 1);
        } else {
            newIndexes.push({ key: index });
        }
        setOpenIndexes(newIndexes);
        setIsDetailOpen(!isDetailOpen);
    };

    const closeSummary = () => {
        setShowSummary(false);
        setTempData(null);
    };

    return (
        <div>
            {showSummary && (
                <TripAndLogbookSummary
                    title={tempData?.vehicle_make + ' ' + tempData?.vehicle_model}
                    registration_number={tempData?.registration_number || 'N/A'}
                    onToggle={closeSummary}
                    setOpen={setShowSummary}
                    open={showSummary}
                    cols={6}
                    gap={5}
                />
            )}
            <SidebarLayout>
                <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    <Jumbotron
                        title="Trip Tracking Logbook"
                        path={[
                            { title: 'Clients', link: 'clients' },
                            {
                                title: `${
                                    customer?.client_type === 'prospective_client'
                                        ? 'Prospective Client'
                                        : customer?.client_type === 'active_client'
                                        ? 'Active Client'
                                        : customer?.client_type === 'inactive_client'
                                        ? 'Inactive Client'
                                        : 'Client Profile'
                                }`,
                                link: `app/users?type=${
                                    customer?.client_type === 'prospective_client'
                                        ? 'prospective'
                                        : customer?.client_type === 'active_client'
                                        ? 'active'
                                        : customer?.client_type === 'inactive_client'
                                        ? 'invites'
                                        : 'all'
                                }&reload=false`
                            },
                            {
                                title: ` ${customer?.first_name} ${customer?.last_name}`,
                                link: `app/user/profile/${id}`
                            },
                            { title: `Deduction`, link: `customer/deductions/${id}` },
                            { title: `Trip Tracking Logbook`, link: '' }
                        ]}
                        backArrowProp={backArrowProp}
                        actionsRequired={true}
                        actions={managerActions}
                    />
                    {isLoading ? (
                        <BoxSkeleton />
                    ) : (
                        <div className="mt-5  py-5">
                            <div className="flex flex-col gap-5 px-10">
                                {tripLogsData && tripLogsData?.length === 0 && !isLoading && (
                                    <NoDataFound message="Client did't setup Trips Logbook" />
                                )}
                                {tripLogsData && tripLogsData?.length > 0 && !isLoading
                                    ? tripLogsData?.map((data, index) => (
                                          <div key={'tripLogs' + index}>
                                              <div className="bg-white shadow-md rounded-2xl p-5">
                                                  <div className="flex items-center">
                                                      <h3 className="flex-1 text-lg font-semibold">
                                                          {data?.vehicle_make + ' ' + data?.vehicle_model}
                                                      </h3>
                                                      <div className="flex items-center justify-center gap-5">
                                                          <div className="tracking-normal">
                                                              <span className="px-3 py-1.5 text-third bg-[#b695f838] !font-medium !rounded-xl !text-sm lg:text-lg">
                                                                  Registration Num: {data?.registration_number || 'N/A'}
                                                              </span>
                                                          </div>
                                                          <span
                                                              className="cursor-pointer"
                                                              onClick={() => toggleDetails(index, data.vehicle_id)}
                                                          >
                                                              {openIndexes?.find((item) => item?.key === index) ? (
                                                                  <CollapsIcon className={'w-6 h-6'} />
                                                              ) : (
                                                                  <ExpandIcon className={'w-6 h-6'} />
                                                              )}
                                                          </span>
                                                      </div>
                                                  </div>
                                                  <div className="grid grid-cols-4 lg:grid-cols-5 gap-2 my-5">
                                                      <div className="col-span-2">
                                                          <div className="grid grid-cols-2 gap-5 items-center">
                                                              <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                  Vehicle Type:
                                                              </h2>
                                                              <div className="text-black font-medium">
                                                                  {data?.vehicle_type || 'N/A'}
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <div className="col-span-2">
                                                          <div className="grid grid-cols-2 gap-5 items-center">
                                                              <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                  Preferred Trip Method:
                                                              </h2>
                                                              <span className="text-black font-medium min-w-[98px] inline-block text-left">
                                                                  {data?.method?.label || 'N/A'}
                                                              </span>
                                                          </div>
                                                      </div>
                                                      <div className="col-span-1 hidden lg:block row-span-2 relative">
                                                          <img
                                                              src={carIcon}
                                                              className="absolute -right-[20px]"
                                                              alt="car-icon"
                                                          />
                                                      </div>
                                                      <div className="col-span-2">
                                                          <div className="grid grid-cols-2 gap-5 items-center">
                                                              <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                  Odometer Reading:{' '}
                                                              </h2>
                                                              <div className="text-black font-medium">
                                                                  {data?.current_oddo_meter_reading
                                                                      ? formatNumberWithCommas(
                                                                            data?.current_oddo_meter_reading
                                                                        ) + ' Km'
                                                                      : 'N/A'}
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <div className="col-span-2">
                                                          <div className="grid grid-cols-2 gap-5 items-center">
                                                              <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                  Odometer Reading Frequency
                                                              </h2>
                                                              <span className="text-black font-medium min-w-[98px] inline-block text-left">
                                                                  {data?.oddometer_reading_frequency || 'N/A'}
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                              {openIndexes?.find((item) => item?.key === index) && (
                                                  <div>
                                                      <TripAndLogbookCard
                                                          vehicle_id={data.vehicle_id}
                                                          business_client_id={id}
                                                          toggleSummary={(
                                                              summary_id,
                                                              summary_type,
                                                              from_date,
                                                              to_date
                                                          ) => {
                                                              toggleSummary(
                                                                  summary_id,
                                                                  summary_type,
                                                                  from_date,
                                                                  to_date
                                                              );
                                                              setTempData(data);
                                                          }}
                                                      />
                                                  </div>
                                              )}
                                          </div>
                                      ))
                                    : ''}
                            </div>
                        </div>
                    )}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
