/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import OutlookSvg from 'files/outlook.png';
import { cn } from 'utills/tailwindUtil';
import { ExplainationSignSvg } from 'utills/svgs/ExplainationSignSvg';
import { Switch, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import DialogModalComponent from 'global-components/DialogModalComponent';
import DisconnectIcon from 'utills/svgs/DisconnectIcon';
import { Button } from 'components/atoms/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAppsCalendarsThunk,
    getAppsStatusThunk,
    outlookDisconnectThunk,
    outlookSynkClientThunk,
    refreshOutlookSynkThunk,
    updateAppsCalendarsThunk
} from 'store/appConnectoins';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { Loader } from 'components/common/Loader';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { SearchInput } from 'components/molecules/SearchInput';
import { HintIcon } from 'utills/svgs/HintIcon';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

export const OutlookAppConnected = () => {
    const items = [
        { name: 'Refresh Integration Data', onClick: () => handleRefreshSync() },
        { name: 'Disconnect', onClick: () => handleDisconnect() }
    ];
    const infoList = [
        `<span className="text-third font-semibod" >  No need to worry about losing data! </span> Disconnecting your Synkli and Outlook account won't affect any information that's already been synced between them. Everything will stay safe and sound.`
    ];

    const [enabled, setEnabled] = useState(false);
    const [decision, setDecision] = useState('keepData');
    const [error, setError] = useState(null);
    const { isLoading } = useSelector((state) => state.global);
    const [actionType, setActionType] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disconnectModal, setDisconnectModal] = useState(null);
    const [loader, setLoaderState] = useState(false);
    const [newLoader, _setNewLoader] = useState(false);

    const [data, setData] = useState({
        status: 'active'
    });

    useEffect(() => {
        getAppStatus();
    }, []);

    const getAppStatus = () => {
        dispatch(setLoader(true));
        dispatch(getAppsStatusThunk())
            .then((res) => {
                if (res?.payload) {
                    const AppData = res?.payload.find((app) => app.app_name === 'microsoft');
                    if (AppData?.status === 'inactive') {
                        navigate('/app-connections/microsoft');
                        return false;
                    }
                    setData(AppData);
                    setEnabled(AppData.allow_sync);
                } else {
                    navigate('/app-connections');
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    const handleDisconnect = () => {
        setDisconnectModal('confirm');
        setActionType('disconnect');
        setDecision('keepData');
    };

    const handleDisconnectOutlook = () => {
        setDisconnectModal(null);
        navigate('/app-connections');
    };

    const handleSyncClients = async () => {
        setLoaderState(true);
        const payload = {
            allow_sync: !enabled
        };
        dispatch(setLoader(true));
        await dispatch(outlookSynkClientThunk(payload))
            .then((_res) => {
                setEnabled(!enabled);
            })
            .finally(() => {
                setLoaderState(false);
                dispatch(setLoader(false));
            });
    };
    const handleRefreshSync = async () => {
        setLoaderState(true);
        dispatch(setLoader(true));
        await dispatch(refreshOutlookSynkThunk()).finally(() => {
            setLoaderState(false);
            dispatch(setLoader(false));
        });
    };

    const handleDisableAction = () => {
        if (!decision) {
            setError('Please Select One of the Following Options');
        }
        if (decision && actionType) {
            if (actionType === 'disconnect') {
                setLoaderState(true);
                dispatch(outlookDisconnectThunk())
                    .then((res) => {
                        if (res.meta?.requestStatus === 'fulfilled') {
                            setDisconnectModal('disconnected');
                        } else {
                            toastHandler('Error while Diconnect', 'error');
                        }
                    })
                    .finally(() => {
                        setLoaderState(false);
                    });
            }
        }
    };

    const checkbox = useRef();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const [calendarsList, setCalendarsList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { data: getAppsCalendars } = useSelector((state) => state?.getAppsCalendars);

    useEffect(() => {
        if (data?.status === 'list') {
            if (!getAppsCalendars) {
                getAppCalendarList();
            } else {
                setCalendarsList(getAppsCalendars);
                setSelectedPeople(
                    getAppsCalendars
                        ?.filter((obj) => obj?.status === 'active')
                        ?.map((obj) => obj?.synkli_microsoft_calendar_id)
                );
            }
        }
    }, [getAppsCalendars, data]);

    const getAppCalendarList = () => {
        dispatch(setLoader(true));
        dispatch(getAppsCalendarsThunk()).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useLayoutEffect(() => {
        const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < calendarsList.length;
        setChecked(selectedPeople.length === calendarsList.length && calendarsList.length !== 0);
        setIndeterminate(isIndeterminate);
        if (checkbox?.current) {
            checkbox.current.indeterminate = isIndeterminate;
        }
    }, [selectedPeople]);

    const toggleAll = () => {
        setSelectedPeople(
            checked || indeterminate ? [] : calendarsList.map((obj) => obj?.synkli_microsoft_calendar_id)
        );
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    };

    const updateChanges = () => {
        const newCalendarsList = calendarsList.map((obj) => {
            return {
                synkli_microsoft_calendar_id: obj?.synkli_microsoft_calendar_id,
                status: selectedPeople.includes(obj?.synkli_microsoft_calendar_id) ? 'active' : 'inactive'
            };
        });
        if (newCalendarsList.length > 0) {
            dispatch(setLoader(true));
            dispatch(updateAppsCalendarsThunk(newCalendarsList))
                .then((res) => {
                    if (res.payload) {
                        getAppCalendarList();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    let filteredPeople = [];
    if (calendarsList) {
        filteredPeople =
            searchTerm === ''
                ? calendarsList
                : calendarsList.filter((obj) => {
                      return obj?.name?.toLowerCase().includes(searchTerm.toLowerCase());
                  });
    }
    return (
        <div>
            {(isLoading || newLoader) && <Loader />}
            <SidebarLayout>
                <Jumbotron
                    title="App Connections"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },

                        {
                            link: 'app-connections',
                            title: 'App Connections'
                        },
                        {
                            link: '',
                            title: 'Outlook Calendar'
                        }
                    ]}
                />

                <RoleBasedGuard module={'App_connections'} permissions={['write', 'admin']} sx="h-[50vh]">
                    <div className="md:mx-10 mx-5 mt-5">
                        {data && (
                            <div className="px-10 py-12 bg-white rounded-2xl shadow-lg mt-7">
                                <div className="md:col-span-2 col-span-3  flex flex-wrap items-center relative ">
                                    <div className="isolate inline-flex rounded-md shadow-sm w-fit">
                                        <div className="flex gap-0 bg-transparent rounded-xl ring-1 ring-outside ring-gray-300 shadow-sm w-full">
                                            <div
                                                className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-12 ${
                                                    data?.status === 'active' ? 'bg-leaner' : ''
                                                }`}
                                                onClick={() => setData({ ...data, status: 'active' })}
                                            >
                                                Connected
                                            </div>
                                            <div
                                                className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-5`}
                                            >
                                                <div className="inline-flex gap-2">
                                                    <Menu as="div" className="relative -ml-px block">
                                                        <Menu.Button className="relative inline-flex items-center ">
                                                            <span className="text-[12px] md:text-[14px] lg:text-[16px] font-poppins">
                                                                Manage Connections
                                                            </span>
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                        </Menu.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="absolute right-0   -mr-1 mt-2 w-56 bottom-[101%] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {items.map((item) => (
                                                                        <Menu.Item key={item.name}>
                                                                            {({ active }) => (
                                                                                <h3
                                                                                    onClick={item.onClick}
                                                                                    className={cn(
                                                                                        active
                                                                                            ? 'bg-third text-white'
                                                                                            : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm'
                                                                                    )}
                                                                                >
                                                                                    {item.name}
                                                                                </h3>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                            <div
                                                className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-12 ${
                                                    data?.status === 'list' ? 'bg-leaner' : ''
                                                }`}
                                                onClick={() => setData({ ...data, status: 'list' })}
                                            >
                                                Connected Calendar List
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="min-h-[300px]">
                                    {data?.status === 'list' ? (
                                        <div className="mt-5 grid">
                                            <div className="flex items-center justify-between my-5">
                                                <div className="w-[300px]">
                                                    <SearchInput
                                                        placeholder={'Search'}
                                                        atChange={(e) => {
                                                            setSearchTerm(e.target.value);
                                                        }}
                                                        zIndex="1"
                                                    />
                                                </div>

                                                <div className="rounded-lg bg-gray-100 p-2">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0">
                                                            <HintIcon className={'w-7'} />
                                                        </div>
                                                        <div className="ml-3 flex-1 md:flex md:justify-between">
                                                            <p className="text-sm text-gray-800">
                                                                Select all calendar from where you want to fetch your
                                                                event details
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-8 flow-root">
                                                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                            <div className="relative">
                                                                <table className="min-w-full table-fixed divide-y divide-gray-300 border-b border-gray-300">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="relative px-7 sm:w-12 sm:px-6"
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                                    ref={checkbox}
                                                                                    checked={checked}
                                                                                    onChange={toggleAll}
                                                                                />
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                                                                            >
                                                                                Calendar Name
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                                                                            >
                                                                                Status
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                                        {filteredPeople.map((obj, index) => (
                                                                            <tr
                                                                                key={'Calendartable' + index}
                                                                                className={
                                                                                    selectedPeople.includes(
                                                                                        obj.synkli_microsoft_calendar_id
                                                                                    )
                                                                                        ? 'bg-gray-50'
                                                                                        : undefined
                                                                                }
                                                                            >
                                                                                <td className="relative px-7 sm:w-12 sm:px-6">
                                                                                    {selectedPeople.includes(
                                                                                        obj.synkli_microsoft_calendar_id
                                                                                    ) && (
                                                                                        <div className="absolute inset-y-0 left-0 w-0.5 bg-third" />
                                                                                    )}
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={'checkBox' + index}
                                                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                                        value={
                                                                                            obj.synkli_microsoft_calendar_id
                                                                                        }
                                                                                        checked={selectedPeople.includes(
                                                                                            obj.synkli_microsoft_calendar_id
                                                                                        )}
                                                                                        onChange={(e) =>
                                                                                            setSelectedPeople(
                                                                                                e.target.checked
                                                                                                    ? [
                                                                                                          ...selectedPeople,
                                                                                                          obj.synkli_microsoft_calendar_id
                                                                                                      ]
                                                                                                    : selectedPeople.filter(
                                                                                                          (p) =>
                                                                                                              p !==
                                                                                                              obj.synkli_microsoft_calendar_id
                                                                                                      )
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                                <td
                                                                                    className={cn(
                                                                                        'whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900'
                                                                                    )}
                                                                                >
                                                                                    <label htmlFor={'checkBox' + index}>
                                                                                        {obj.name}
                                                                                    </label>
                                                                                </td>
                                                                                <td
                                                                                    className={cn(
                                                                                        'whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900'
                                                                                    )}
                                                                                >
                                                                                    <label
                                                                                        className={cn(
                                                                                            'px-3 py-2 rounded-xl bg-gray-100 text-sm capitalize',
                                                                                            obj.status === 'active' &&
                                                                                                'bg-green-100'
                                                                                        )}
                                                                                    >
                                                                                        {' '}
                                                                                        {obj.status}
                                                                                    </label>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                {filteredPeople.length === 0 && (
                                                                    <div
                                                                        className={cn(
                                                                            'mx-auto my-[10px] text-center !bg-white py-2 rounded-lg !shadow-sm  ring-1 ring-gray-200 px-1',
                                                                            'py-2 rounded-lg'
                                                                        )}
                                                                        style={{ width: 'calc(100% - 10px)' }}
                                                                    >
                                                                        <h3 className="text-gray-800 text-sm font-semibold">
                                                                            No More Content
                                                                        </h3>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="flex mt-5 items-start">
                                                                <Button
                                                                    title={'Save Changes'}
                                                                    atClick={() => updateChanges()}
                                                                    classes={
                                                                        'bg-linear text-white rounded-lg px-4 py-3'
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flow-root mt-10">
                                            <div className="grid grid-cols-4 gap-4">
                                                <div>
                                                    <div className="flex items-center gap-3">
                                                        <img src={OutlookSvg} alt="OutlookSvg" className="w-28" />
                                                        <h3 className="text-2xl font-bold ">Outlook Calendar</h3>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 pr-5">
                                                    <h3 className="text-2xl font-semibold ">
                                                        General Outlook Calendar Settings
                                                    </h3>
                                                    <h3 className="text-xl font-semibold my-5">
                                                        Automatic Synchronisation
                                                    </h3>
                                                    <div className="flex items-start gap-5 px-5 py-7 bg-gray-50 rounded-xl">
                                                        <div className="div">
                                                            <ExplainationSignSvg />
                                                        </div>
                                                        <div>
                                                            <p className="text-md font-normal text-gray-900">
                                                                Once automatic synchronisation is enabled, Synkli
                                                                synchronises your calendar events. Synkli then
                                                                automatically sends invitations to your clients
                                                                according to your schedule, streamlining your process
                                                                and saving you time and effort. Invitations are tailored
                                                                based on event specifics, ensuring efficient
                                                                communication even with clients not currently using
                                                                Synkli. This feature facilitates smoother onboarding,
                                                                potentially allowing clients to complete necessary
                                                                processes before their appointments. Synkli sends
                                                                reminders at 6 AM each morning to clients with
                                                                appointments scheduled for that day.
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <Switch.Group
                                                        as="div"
                                                        className="flex items-center justify-start gap-5 mt-5"
                                                    >
                                                        <span className="flex">
                                                            <Switch.Label
                                                                as="span"
                                                                className="text-md font-normal leading-6 text-gray-900"
                                                                passive
                                                            >
                                                                Automatically fetch all the appointments
                                                            </Switch.Label>
                                                        </span>
                                                        <Switch
                                                            checked={enabled}
                                                            onChange={() => handleSyncClients()}
                                                            className={cn(
                                                                enabled ? 'bg-third' : 'bg-gray-200',
                                                                'relative inline-flex mx-5 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-third focus:ring-offset-2'
                                                            )}
                                                        >
                                                            <span
                                                                aria-hidden="true"
                                                                className={cn(
                                                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                )}
                                                            />
                                                        </Switch>
                                                    </Switch.Group>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {disconnectModal === 'confirm' && (
                            <DialogModalComponent
                                open={disconnectModal === 'confirm' ? true : false}
                                setOpen={() => {
                                    setDisconnectModal(null);
                                    setActionType(null);
                                }}
                                sx="max-w-[660px]"
                                title="Disconnect Integration"
                                icon={<DisconnectIcon />}
                            >
                                <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5 text-center">
                                    This will stop the application from sending future appointments client invite. Are
                                    you sure you want to disconnect your Outlook Calendar Integration?
                                </p>

                                {error && <p className="text-sm text-red-500 font-normal leading-10">{error}</p>}
                                {decision === 'mergeLater' && <></>}
                                {decision === 'keepData' && (
                                    <>
                                        <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5">
                                            This will:
                                        </p>
                                        {infoList.map((item, index) => (
                                            <div className="relative flex items-start my-5" key={'info_key' + index}>
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        id={'comments_' + index}
                                                        aria-describedby="comments-description"
                                                        name="comments"
                                                        checked={true}
                                                        type="checkbox"
                                                        className="h-5 w-5 rounded-full border-gray-300 text-third focus:ring-third"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6">
                                                    <label
                                                        htmlFor={'comments_' + index}
                                                        className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5"
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: item }} />
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}

                                <div className="flex justify-center items-center my-8 gap-5">
                                    <Button
                                        atClick={() => setDisconnectModal(null)}
                                        title={'Cancel'}
                                        classes={
                                            'rounded-lg !bg-white text-linear min-h-[3rem] ring-2 ring-inset ring-primary px-16 buttons-font-lighter font-fira'
                                        }
                                    />
                                    <Button
                                        atClick={() => handleDisableAction()}
                                        iconSet={{ loader: SmallLoaderWhite }}
                                        isloading={loader}
                                        disabled={loader}
                                        title={'Disconnect'}
                                        classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                    />
                                </div>
                            </DialogModalComponent>
                        )}

                        {disconnectModal === 'disconnected' && (
                            <DialogModalComponent
                                open={disconnectModal === 'disconnected' ? true : false}
                                setOpen={() => setDisconnectModal(null)}
                                sx="max-w-[460px]"
                                title="Outlook Calendar Disconnected"
                                icon={<DisconnectIcon />}
                            >
                                <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins">
                                    You have successfully disconnected Outlook Calendar
                                </p>
                                <div className="flex justify-center items-center my-8 gap-5">
                                    <Button
                                        atClick={() => handleDisconnectOutlook()}
                                        title={'Finish'}
                                        classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                    />
                                </div>
                            </DialogModalComponent>
                        )}
                    </div>
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
