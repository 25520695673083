/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import { SettingsCards } from './SettingsCards';
import AddedForms from 'components/appusers-dash/forms/added-forms/AddedForms';
import PreviewForm from 'components/appusers-dash/forms/dynamic-form/PreviewForm';
import { UpdateForm } from './form-settings/UpdateForm';
import { useEffect } from 'react';
import { AddForm } from 'components/appusers-dash/forms/dynamic-form/AddForm';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { Terms } from 'components/appusers-dash/forms/dynamic-form/Terms';
import useRoleGuard from 'Hooks/useRoleGuard';
import { NoPermission } from 'components/molecules/NoPermission';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleRemoveQueryParams } from 'utills/globalVars';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const SettingsPage = () => {
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [updatedForms, setUpdatedForms] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [condition, setCondition] = useState('');
    const [updateTable, setUpdateTable] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const [allowAction, setAllowAction] = useState(false);

    const handleUpdateForm = () => {
        setShowUpdateForm(!showUpdateForm);
    };
    const [showAddedForms, setShowAddedForms] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showPreviewForm, setShowPreviewForm] = useState(false);
    const [formArr, setFormArr] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const formToggle = queryParams.get('form');
    const [allowFirst, setAllowFirst] = useState(true);

    useEffect(() => {
        if (formToggle && allowAction && allowFirst) {
            toggleAddedForms();
            setAllowFirst(false);
        }
    }, [formToggle, allowAction]);

    useEffect(() => {
        if (formToggle && !showAddedForms && !allowFirst) {
            handleRemoveQueryParams(navigate);
        }
    }, [formToggle, showAddedForms]);

    const toggleAddedForms = () => {
        if (showAddedForms) {
            setShowAddedForms(false);
        } else {
            setShowAddedForms(true);
        }
    };
    const toggleCreateForm = (condition) => {
        setCondition(condition);
        setShowCreateForm(!showCreateForm);
        sessionStorage.removeItem('form_id');
    };

    const toggleTerms = () => {
        setShowTerms(!showTerms);
    };

    const togglePreviewForm = () => {
        setShowPreviewForm(!showPreviewForm);
    };

    const formHandler = (form) => {
        setFormArr(form);
    };
    const directory = ' Forms &gt; Added Forms &gt; Preview Form &gt;';

    useEffect(() => {
        if (hasPermissions('Forms', ['read', 'write', 'admin'])) {
            setAllowAction(true);
        } else {
            setAllowAction(false);
        }
    }, []);

    const forceReloadTables = () => {
        setUpdateTable(!updateTable);
    };

    return (
        <SidebarLayout>
            <div className="app-dashboard">
                {allowAction && showAddedForms ? (
                    <AddedForms
                        updatedForms={updatedForms}
                        handleUpdateForm={handleUpdateForm}
                        toggleAddedForms={toggleAddedForms}
                        toggleCreateForm={toggleCreateForm}
                        togglePreviewForm={togglePreviewForm}
                        showCreateForm={showCreateForm}
                        setShowCreateForm={setShowCreateForm}
                        open={showAddedForms}
                        setOpen={setShowAddedForms}
                        updateTable={updateTable}
                    />
                ) : (
                    <NoPermission
                        slider={{
                            open: showAddedForms,
                            setOpen: setShowAddedForms,
                            title: 'Added Forms',
                            size: 'max-w-5xl'
                        }}
                        classes="p-10"
                    />
                )}

                {showCreateForm && (
                    <AddForm
                        condition={condition}
                        open={showCreateForm}
                        setOpen={setShowCreateForm}
                        togglePreviewForm={togglePreviewForm}
                        toggleCreateForm={toggleCreateForm}
                        forceReloadTables={forceReloadTables}
                    />
                )}

                {showPreviewForm && (
                    <PreviewForm
                        togglePreviewForm={togglePreviewForm}
                        toggleCreateForm={toggleCreateForm}
                        directory={directory}
                        toggleTerms={toggleTerms}
                    />
                )}
                {showUpdateForm ? (
                    <UpdateForm handleUpdateForm={handleUpdateForm} forceReloadTables={forceReloadTables} />
                ) : (
                    ''
                )}

                {showTerms && <Terms toggleTerms={toggleTerms} />}

                <div>
                    <Jumbotron
                        title="Settings"
                        path={[
                            {
                                link: '',
                                title: 'Settings'
                            }
                        ]}
                    />
                    <SettingsCards toggleAddedForms={toggleAddedForms} />
                </div>
            </div>
        </SidebarLayout>
    );
};
