import React from 'react';
import { UploadDocument } from './upload-document';
import { useEffect, useState } from 'react';
import AddSigner from './add-signer';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { useGetAllEmployees } from 'Hooks/useGetAllEmployees';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchSynkliCustomersThunk, viewBusinessCustomerInfoThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { BUSINESS_ID, clientTypes, getProfileName } from 'utills/globalVars';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';

const DocumentsPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const fileItem = location.state;
    const [pdf64, setpdf64] = useState('');
    const dispatch = useDispatch();

    const [step, setstep] = useState('one');

    const { getEmployees } = useGetAllEmployees();
    const [customer, setCustomer] = useState(null);

    const customers = [];

    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);

    const { data: user } = useSelector((state) => state?.viewBusinessCustomerInfo);
    const { state } = useLocation();

    const business_id = localStorage.getItem(BUSINESS_ID);

    const setPdfUrl = (url, _name) => {
        setpdf64(url);
        setstep('two');
    };

    const fetchCustomers = () => {
        const payload = {
            business_id,
            customer_type: false,
            is_deleted: true,
            page: 1,
            page_size: 10,
            client_type: 'all_client'
        };

        dispatch(fetchSynkliCustomersThunk(payload)).catch((error) => {
            console.error(error);
        });
    };
    useEffect(() => {
        fetchCustomers();
        getEmployees();
    }, []);

    useEffect(() => {
        if (user?.user) {
            const newUser = {
                ...user,
                user_id: user?.user?.user_id
            };
            setCustomer(newUser);
        }
    }, [user]);

    useEffect(() => {
        if (fileItem) {
            setPdfUrl(fileItem.url);
        }
    }, [fileItem]);

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id,
            userInfo: 'true'
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/file/manager/${id}`);
        }
    };

    return (
        <SidebarLayout>
            <div className="app-dashboard">
                <div>
                    <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                        <div>
                            {' '}
                            <Jumbotron
                                title="Authorised Folder "
                                path={[
                                    { title: 'Clients', link: 'clients' },
                                    {
                                        title:
                                            user && clientTypes[user?.client_type] !== 'All'
                                                ? clientTypes[user?.client_type]
                                                : 'All Clients',
                                        link: `app/users?type=${
                                            user
                                                ? clientTypes[user?.client_type]?.[0]?.toLowerCase() +
                                                  clientTypes[user?.client_type]?.slice(1)
                                                : 'all'
                                        }&reload=true`
                                    },
                                    {
                                        title: `${user ? getProfileName(user) : 'unknown'}`,
                                        link: 'app/user/profile/' + user?.business_client_id
                                    },
                                    {
                                        title: 'Document Hub',
                                        link: 'file/manager/' + user?.business_client_id
                                    },
                                    { title: 'Authorised Folder', link: '' }
                                ]}
                                backArrowProp={backArrowProp}
                            />
                            <div className="md:mx-10 mx-5">
                                {' '}
                                {step === 'one' && <UploadDocument setPdfUrl={setPdfUrl} />}
                                {step === 'two' && (
                                    <AddSigner
                                        pdfString={state?.url ?? pdf64}
                                        file_manager_id={state?.file_manager_id ?? ''}
                                        feilds={[]}
                                        customers={customers}
                                        employees={employees}
                                        customer={customer}
                                        id={id}
                                        fileItem={fileItem}
                                    />
                                )}
                            </div>
                        </div>
                    </RoleBasedGuard>
                </div>
            </div>
        </SidebarLayout>
    );
};
export default DocumentsPage;
