import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeHttpRequest } from '../../axios';
import { endpoint, methods } from 'apiEndpoints';

const initialState = {
    data: [],
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 5,
    isLoading: true,
    error: null,
    search: '',
    email_type: 'all',
    total_count: '',
    threadDetailsObj: null,
    replyObject: null,
    totalSendEmail: 0,
    totalReceivedEmail: 0,
    totalDraftEmail: 0,
    totalUnRead: 0
};

const getRelayRoomListThunk = createAsyncThunk('getRelayRoomList/request', async (payload) => {
    try {
        const payloadData = {
            page: initialState?.currentPage,
            page_size: initialState?.itemsPerPage,
            email_type: initialState?.email_type === 'all' ? '' : initialState?.email_type,
            ...payload
        };

        const res = await makeHttpRequest(methods.GET, endpoint.getRelayRoomList, payloadData);
        return res;
    } catch (error) {
        return error;
    }
});

const getThreadDetailsThunk = createAsyncThunk('threadDetails/request', async (params) => {
    try {
        let urlParams = null;
        if (params?.urlParams) {
            urlParams = params?.urlParams;
        }
        delete params?.urlParams;
        if (urlParams) {
            const res = await makeHttpRequest(methods.GET, endpoint.threadDetails, params, null, urlParams);
            return res;
        }
        return false;
    } catch (error) {
        return error;
    }
});
const getTotalThreadsCountThunk = createAsyncThunk('getTotalThreadsCount/request', async (params) => {
    try {
        const res = await makeHttpRequest(methods.GET, endpoint.getTotalThreadsCount, params);
        return res;
    } catch (error) {
        return error;
    }
});

export const relayRoomListSlice = createSlice({
    name: 'relayRoomList',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setCountPerPage: (state, action) => {
            state.currentPage = 1;
            state.itemsPerPage = action.payload;
        },
        updateData: (state, action) => {
            state.data = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setEmailType: (state, action) => {
            state.currentPage = 1;
            state.email_type = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setReplyObject: (state, action) => {
            state.replyObject = action.payload;
        },
        updateDraftCount: (state, action) => {
            state.totalDraftEmail = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRelayRoomListThunk.pending, (state) => {
                state.data = [];
                state.error = null;
            })
            .addCase(getRelayRoomListThunk.fulfilled, (state, action) => {
                state.data = action.payload?.rows;
                state.total_count = action.payload?.count;
                state.totalPages = Math.ceil(action.payload?.count / state.itemsPerPage);
            })
            .addCase(getRelayRoomListThunk.rejected, (state, action) => {
                state.error = action.payload;
                state.data = [];
            })
            .addCase(getThreadDetailsThunk.pending, (state) => {
                state.error = null;
                state.threadDetailsObj = null;
            })
            .addCase(getThreadDetailsThunk.fulfilled, (state, action) => {
                state.threadDetailsObj = action.payload;
            })
            .addCase(getThreadDetailsThunk.rejected, (state, action) => {
                state.error = action.payload;
                state.threadDetailsObj = null;
            })
            .addCase(getTotalThreadsCountThunk.pending, (state) => {
                state.totalSendEmail = 0;
                state.totalReceivedEmail = 0;
                state.totalDraftEmail = 0;
                state.totalUnRead = 0;
            })
            .addCase(getTotalThreadsCountThunk.fulfilled, (state, action) => {
                state.totalSendEmail = action?.payload?.totalSendEmail;
                state.totalReceivedEmail = action?.payload?.totalReceivedEmail;
                state.totalDraftEmail = action?.payload?.totalDraftEmail;
                state.totalUnRead = action?.payload?.totalUnRead;
            })
            .addCase(getTotalThreadsCountThunk.rejected, (state, _action) => {
                state.totalSendEmail = 0;
                state.totalReceivedEmail = 0;
                state.totalDraftEmail = 0;
                state.totalUnRead = 0;
            });
    }
});

export const {
    setCurrentPage,
    setCountPerPage,
    updateData,
    setSearch,
    setEmailType,
    setIsLoading,
    setReplyObject,
    updateDraftCount
} = relayRoomListSlice.actions;

export default relayRoomListSlice;
export { getRelayRoomListThunk, getThreadDetailsThunk, getTotalThreadsCountThunk };
