import React from 'react';
import AppointmentsReportsPage from '../../components/workplace-content/appointment-reminder-pages/appointments-report-pages/AppoimentsReportsPage';
const AppointmentsReports = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <AppointmentsReportsPage />
            </div>
        </div>
    );
};
export default AppointmentsReports;
