/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SmallLoader } from 'components/common/SmallLoader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toastHandler } from 'responseHanlder';
import {
    googleAuthPreThunk,
    googleAuthThunk,
    verifyGoogleAuthAtSetupThunk,
    verifyGoogleAuthPreThunk
} from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { BackArrow } from 'utills/svgs/BackArrow';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import whitelogo from '../../files/whiteBgLogo.png.png';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';

export const GoogleQrCode = (props) => {
    const dispatch = useDispatch();
    const forgotPasswordData = JSON.parse(sessionStorage.getItem('forgotPasswordData'));
    const isLost = sessionStorage.getItem('isLost');

    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};

    const base64String = useSelector((state) => state?.googleAuth?.data?.otpauth_url);
    const data = useSelector((state) => state.login.data);

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [otp, setOtp] = useState([null, null, null, null, null, null]);

    const [otpVal, setOtpVal] = useState('');

    const googleAuthData = {
        email: parsedData.email,
        isBeforeLogin: true,
        user_type: 'employee'
    };

    const handleKeyUp = (event, index) => {
        const input = inputRefs[index].current;
        let value = input.value;

        if (value.length > 1) {
            value = value.charAt(0);
            input.value = value;
        }

        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (event.keyCode === 8 && value.length === 0 && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    function hasNullItem() {
        return otp.some((item) => item === null);
    }

    const verifyGoogleData = {
        email: parsedData.email,
        isBeforeLogin: true,
        user_type: 'employee',
        user_id: forgotPasswordData?.user_id,
        code: otpVal
    };

    const verifyGoogleDataSetup = {
        user_id: data?.user_id,
        user_type: 'employee',
        code: otpVal
    };

    const handleOtpSubmit = async () => {
        if (otpVal.length < 6) {
            return toastHandler('Please enter full otp', 'error');
        }

        dispatch(setLoader(true));
        await dispatch(
            isLost ? verifyGoogleAuthAtSetupThunk(verifyGoogleDataSetup) : verifyGoogleAuthPreThunk(verifyGoogleData)
        )
            .then((response) => {
                if (response.payload) {
                    props.handleCondition(4);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [code, setCode] = useState('XXXX XXXX XXXX XXXX');
    const [codeLoader, setCodeLoader] = useState(false);

    useEffect(() => {
        setCodeLoader(true);
        dispatch(isLost ? googleAuthThunk(googleAuthData) : googleAuthPreThunk(googleAuthData))
            .then((response) => {
                if (response.payload) {
                    setCode(response.payload.code);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setCodeLoader(false);
            });
    }, []);

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    const splitCode = (code) => {
        if (code.length !== 8) return ['', ''];
        const firstPart = code.slice(0, 4);
        const secondPart = code.slice(4, 8);
        return [firstPart, secondPart];
    };

    return (
        <div className="md:flex justify-center flex-wrap  items-center w-[100%] h-[100vh] gap-2 overflow-y-auto relative">
            <div className="absolute left-5 top-5">
                <img src={whitelogo} alt="logo" className="Synkli-logo" />
            </div>

            <div className="white   max-w-[650px] rounded-lg min-h-[90vh]  bg-white p-3">
                <div>
                    <div className="card-heading-two font-poppins  flex   ">
                        <div className="px-3 pt-2 flex   gap-2 items-center">
                            <div className="arrow-wrapper  cursor-pointer" onClick={() => props.handleCondition(2)}>
                                <BackArrow />
                            </div>
                            <div>
                                {' '}
                                <h1>Google Authenticator</h1>
                            </div>
                        </div>
                    </div>

                    <div className="small-text text-secondarybg font-poppins flex  mx-5 gap-1 mt-3">
                        <div>
                            <div className=" mt-1">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>
                                Please use your authentication app (such as Duo or Google Authenticator) to activate the
                                Google Authentication..
                            </span>
                        </div>
                    </div>

                    <div className="flex justify-center items-center  mt-5">
                        {base64String && <img src={base64String} alt="qrcode" />}
                    </div>

                    <div className="text-3xl font-poppins my-5 flex justify-center items-center">
                        <div>
                            <div>
                                {codeLoader ? (
                                    <div className="flex items-center justify-center">
                                        {' '}
                                        <SmallLoader />{' '}
                                    </div>
                                ) : (
                                    <div className='"flex gap-5 items-center'>
                                        {' '}
                                        <span> {code?.slice(0, 4)} </span>
                                        <span> {code?.slice(4, 8)} </span>
                                        <span> {code?.slice(8, 12)} </span>
                                        <span> {code?.slice(12, 16)} </span>
                                        <span> {code?.slice(16, code?.length)} </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="buttons-font font-poppins px-5 mt-5">
                        Enter the 6-digit authentication code generated by your app:
                    </div>

                    <div className="small-text text-secondarybg font-poppins px-5">
                        Upon scanning the QR code, the app will present a 6-digit code for you to input below.
                    </div>

                    <div className="mt-8 flex justify-center items-center">
                        <OtpInputs value={otpVal} onChange={handleOtpChange} required={true} />
                    </div>

                    <div className="flex justify-center mb-5 items-center gap-3 sm:mt-10 md:mt-10 lg:mt-20 max-xl:mt-28">
                        <button
                            onClick={() => props.handleCondition(2)}
                            className="btn-1 buttons-font font-fira px-12 py-2 rounded-md"
                        >
                            Cancel{' '}
                        </button>
                        <button
                            onClick={handleOtpSubmit}
                            className="btn-2 buttons-font font-fira  px-12 py-2 rounded-md"
                        >
                            Enable
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
