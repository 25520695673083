/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ChecklistIcon } from 'utills/svgs/ChecklistIcon';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID, TOAST_TYPE_ERROR, USER_TYPE, acceptFilesFormats, acceptFormate } from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import Slideover from 'components/atoms/SlideOver';
import { NoDataFound } from 'components/common/NoDataFound';
import Badge from 'components/atoms/Badge';
import { Loader } from 'components/common/Loader';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { generateId } from 'utills/uid';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';
import EditableText from 'components/atoms/EditableText/EditableText';
import { ParseHtml } from 'Hooks/ParseHtml';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { Button } from 'components/atoms/buttons/Button';
import { toastHandler } from 'responseHanlder';
import {
    addAttachmentTemplateTaskThunk,
    createChecklistItemTemplateTaskThunk,
    createChecklistTemplateTaskThunk,
    getTemplateTaskById,
    getTemplateTaskByIdThunk,
    removeAttachmentTemplateTaskThunk,
    removeChecklistTemplateTaskThunk,
    updateTemplateTaskThunk
} from 'store/settings/JobPortal';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import PreSelectedCheckList from 'global-components/PreSelectedCheckList';

const TaskTemplateDetails = ({ open, setOpen, handleTasksUpdate }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);

    const [isZoomed, setIsZoomed] = useState(false);

    const [openpdf, setOpenpdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const { isLoading } = useSelector((state) => state.global);
    const { data: getTemplateTaskByIdData } = useSelector((state) => state.getTemplateTaskById);
    const [task, setTask] = useState(null);

    const [addItemIndex, setAddItemIndex] = useState(null);
    const [preBuildCheckListView, setPreBuildCheckListView] = useState(false);
    const [taskName, setTaskName] = useState('');
    const [taskTitle, setTaskTitle] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [selectedImage, setSelectedImage] = useState('');

    const [uploadFiles, setUploadFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const [showAddItem, setShowAddItem] = useState(false);

    const [itemName, setItemName] = useState('');

    useEffect(() => {
        if (getTemplateTaskByIdData) {
            setTask(getTemplateTaskByIdData);
            setTaskTitle(getTemplateTaskByIdData?.title);
            setTaskName(getTemplateTaskByIdData?.name);
            setTaskDescription(getTemplateTaskByIdData?.description);
        }
    }, [getTemplateTaskByIdData]);

    const handleSubmitItem = (payloadData) => {
        const payload = {
            ...payloadData,
            value: itemName,
            is_completed: 'false'
        };

        dispatch(createChecklistItemTemplateTaskThunk(payload)).then((response) => {
            if (response.payload) {
                setItemName('');
                setShowAddItem(false);
                getTaskTemplateDetails();
            }
        });
    };

    const hideAddList = () => {
        setShowAddItem(false);
    };

    const removeFile = (index) => {
        const newArr = [...uploadFiles];
        const newPreview = [...uploadingFiles];

        const uploadingFile = newPreview[index];
        const file = newArr[index];

        const newIndex = newArr.indexOf(file);
        const uploadingIndex = newPreview?.indexOf(uploadingFile);

        newArr.splice(newIndex, 1);
        newPreview?.splice(uploadingIndex, 1);

        setUploadingFiles([...newPreview]);
        setUploadFiles([...newArr]);
    };

    const uploadNewAttachments = () => {
        const formData = new FormData();
        formData.append('task_template_id', task?.task_template_id);
        formData.append('uploaded_by', USER_TYPE);

        if (uploadFiles) {
            uploadFiles.forEach((file, _index) => {
                formData.append('files', file);
            });
        }
        dispatch(setLoader(true));
        dispatch(addAttachmentTemplateTaskThunk(formData))
            .then((response) => {
                if (response.payload) {
                    console.info('response.payload', response.payload);
                    setUploadFiles([]);
                    getTaskTemplateDetails();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const getTaskTemplateDetails = () => {
        dispatch(getTemplateTaskByIdThunk({ urlParams: task?.task_template_id }));
    };

    const removeTaskFile = (task_template_attachment_id, index) => {
        const payload = {
            urlParams: task_template_attachment_id
        };

        dispatch(removeAttachmentTemplateTaskThunk(payload)).then((response) => {
            if (response.payload) {
                const newTask = {
                    ...task,
                    taskAttachment: task.taskAttachment.filter((_, i) => i !== index)
                };
                setTask(newTask);
                dispatch(getTemplateTaskById.actions.handleUpdate(newTask));
            }
        });
    };

    const handleUpdateValues = (key, value, setStateFun) => {
        if (!value || value === '' || value.replace(/<\/?[^>]+(>|$)/g, '').trim() === '') {
            return toastHandler(`Value is required`, TOAST_TYPE_ERROR);
        }
        if (value) {
            setStateFun(value);
            const jsonPayload = {
                task_template_id: task?.task_template_id,
                business_id: business_id,
                [key]: value
            };
            dispatch(setLoader(true));
            dispatch(updateTemplateTaskThunk(jsonPayload))
                .then((response) => {
                    if (response.payload) {
                        handleTasksUpdate();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handlePreviewFile = (file) => {
        if (file instanceof File || file instanceof Blob) {
            const url = URL.createObjectURL(file);

            if (file.type.includes('jpg') || file.type.includes('png') || file.type.includes('jpeg')) {
                setSelectedImage(url);
            } else if (file.type.includes('application/pdf')) {
                setPdfUrl(url);
                setOpenpdf(true);
            }
        } else {
            console.error('Invalid file object passed to handlePreviewFile.');
        }
    };

    const onRemoveFile = (index) => {
        const newFiles = [...uploadFiles];
        newFiles.splice(index, 1);
        setUploadFiles(newFiles);
    };

    const addItem = (index) => {
        setAddItemIndex(index);
        setShowAddItem(!showAddItem);
    };

    const handleSelectedPrebuildList = (prebuiltList) => {
        const newArr = [];
        prebuiltList.map((obj) => {
            newArr.push({
                task_template_id: task?.task_template_id,
                title: obj.label,
                duration_label: obj.duration_label,
                duration_value: obj.duration_value,
                taskCheckListItems: obj?.items?.map((item, ind) => ({
                    value: item?.label,
                    sequence: item?.sequence && item?.sequence !== 'undefined' ? item?.sequence : ind.toString(),
                    is_completed: 'false'
                }))
            });
        });

        dispatch(setLoader(true));
        dispatch(createChecklistTemplateTaskThunk({ taskCheckList: newArr }))
            .then((response) => {
                if (response.payload) {
                    getTaskTemplateDetails();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
                setPreBuildCheckListView(false);
                setShowAddItem(false);
                setItemName('');
            });
    };

    const handleRemoveChecklist = (payload) => {
        dispatch(setLoader(true));
        dispatch(removeChecklistTemplateTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    getTaskTemplateDetails();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                size={'max-w-[1200px]'}
                title={'Task Template Details'}
                hideBackArrow
            >
                {openpdf && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpenpdf} open={openpdf} /> : ''}
                {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
                {isLoading && <Loader />}

                <div className="text-xl px-6 font-fira font-semibold mt-4">
                    <EditableText
                        text={taskName}
                        onUpdate={(text) => handleUpdateValues('name', text, setTaskName)}
                        isTextArea={false}
                        componentName="text"
                    />
                </div>

                <div className="flex justify-end items-center mx-5 gap-3 "></div>

                <div className="m-5 relative">
                    <div className="lg:grid lg:grid-cols-2 gap-5">
                        <div className="col-span-2">
                            <div className="task-detail-main-card default-forms bg-white  rounded-xl p-5 ">
                                <label className="!text-xl mb-2 font-semibold font-poppins text-blueish">Title</label>
                                <EditableText
                                    text={taskTitle}
                                    onUpdate={(text) => handleUpdateValues('title', text, setTaskTitle)}
                                    isTextArea={false}
                                    componentName="text"
                                />
                                <div className="mt-3">
                                    <label className="!text-xl mb-2 font-semibold font-poppins text-blueish">
                                        Description
                                    </label>
                                    <EditableText
                                        componentName="editor"
                                        text={taskDescription}
                                        onUpdate={(text) => handleUpdateValues('description', text, setTaskDescription)}
                                    >
                                        <ParseHtml htmlContent={taskDescription} />
                                    </EditableText>
                                </div>
                            </div>

                            <div className="task-detail-main-card p-5 rounded-xl mt-5">
                                <div className="flex w-full justify-between items-center pb-4">
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <ChecklistIcon />
                                        </div>
                                        <div>
                                            <label className="text-lg font-semibold font-poppins text-blueish">
                                                Checklists
                                            </label>
                                        </div>
                                    </div>
                                    <button
                                        className="bg-linear text-white font-fira buttons-font-lighter px-8 h-[2.5rem] rounded-xl"
                                        onClick={() => setPreBuildCheckListView(true)}
                                    >
                                        Add Prebuilt Item
                                    </button>
                                </div>
                                {task?.taskCheckLists?.length > 0 ? (
                                    <div>
                                        {task?.taskCheckLists.map((checklist, PlistIndex) => (
                                            <div
                                                className=" text-black mt-3 "
                                                key={checklist.task_checklist_template_id}
                                            >
                                                <div className="mt-5">
                                                    <div>
                                                        <div className="flex justify-between items-center">
                                                            {' '}
                                                            <div className="checklist-title text-lg  font-[500]">
                                                                {checklist?.title}{' '}
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    handleRemoveChecklist({
                                                                        task_checklist_template_id:
                                                                            checklist.task_checklist_template_id
                                                                    });
                                                                }}
                                                                className="cursor-pointer"
                                                            >
                                                                <BgDeleteIcon />
                                                            </div>
                                                        </div>

                                                        <div className="mt-2 border-t border-gray-200">
                                                            {checklist?.taskCheckListItems &&
                                                                checklist?.taskCheckListItems?.map((check, _index) => (
                                                                    <div
                                                                        key={
                                                                            'checklistItem' +
                                                                            check?.task_checklist_template_id +
                                                                            check?.task_checklist_item_template_id
                                                                        }
                                                                        className="flex justify-between items-center"
                                                                    >
                                                                        <div className="flex gap-3 mt-5  task-check items-center">
                                                                            <div className="!rounded-full bg-primary h-[10px] w-[10px] !border-[#979797]"></div>
                                                                            <label
                                                                                htmlFor={
                                                                                    'task_check' +
                                                                                    check.task_checklist_item_template_id
                                                                                }
                                                                                className={`buttons-font-lighter font-poppins cursor-pointer ${
                                                                                    check.is_completed === 'true' &&
                                                                                    'line-through'
                                                                                }`}
                                                                            >
                                                                                {' '}
                                                                                {check?.value}{' '}
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            onClick={() => {
                                                                                handleRemoveChecklist({
                                                                                    task_checklist_item_template_id:
                                                                                        check?.task_checklist_item_template_id
                                                                                });
                                                                            }}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            <BgDeleteIcon />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>

                                                    {showAddItem && addItemIndex === PlistIndex ? (
                                                        <div className="mt-2 add-ann-form">
                                                            <div>
                                                                {' '}
                                                                <input
                                                                    onChange={(e) => setItemName(e.target.value)}
                                                                    type="text"
                                                                    placeholder="Item Name"
                                                                    className="pl-2"
                                                                />{' '}
                                                            </div>
                                                            <div className="flex items-center gap-3 mt-3">
                                                                <button
                                                                    onClick={() =>
                                                                        handleSubmitItem({
                                                                            task_checklist_template_id:
                                                                                checklist.task_checklist_template_id,
                                                                            sequence:
                                                                                checklist?.taskCheckListItems?.length +
                                                                                1
                                                                        })
                                                                    }
                                                                    className="bg-linear text-white font-fira buttons-font-lighter px-8 h-[2.5rem] rounded-xl "
                                                                >
                                                                    Add
                                                                </button>
                                                                <button
                                                                    className="checklist-cancel-btn  font-fira buttons-font-lighter rounded-xl px-5 h-[2.5rem]"
                                                                    onClick={() => hideAddList()}
                                                                >
                                                                    Cancel
                                                                </button>{' '}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                <div className="my-5 mt-7 flex gap-3 items-center">
                                                    {showAddItem && addItemIndex === PlistIndex ? (
                                                        ''
                                                    ) : (
                                                        <div className="flex items-center gap-2">
                                                            <button
                                                                disabled={!checklist?.title ? true : false}
                                                                className="ann-btn1 bg-leaner rounded-xl px-5 py-2"
                                                                onClick={() => addItem(PlistIndex)}
                                                            >
                                                                Add An Item
                                                            </button>
                                                        </div>
                                                    )}

                                                    <hr className="mt-4"></hr>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <>
                                        <NoDataFound message="No Checklist found!" height="20vh" />
                                    </>
                                )}
                            </div>

                            <div className="task-detail-main-card mt-3 text-black p-5 rounded-xl">
                                <div className="flex gap-5 items-center flex-wrap ">
                                    {task?.taskAttachment?.map((file, index) => (
                                        <FileCardPreview
                                            url={file?.url}
                                            key={index}
                                            name={file?.originalname}
                                            showImage={true}
                                            imgPrperties={{
                                                url: file?.url,
                                                type: file?.mimetype
                                            }}
                                            size={file?.size}
                                            extension={
                                                file?.url?.includes('.')
                                                    ? file?.url?.split('.')?.pop()?.toLowerCase()
                                                    : file?.url.split('/').pop().toLowerCase()
                                            }
                                            onClick={() => {
                                                if (file?.url?.toLowerCase()?.endsWith('.pdf')) {
                                                    setPdfUrl(file?.url);
                                                    setOpenpdf(true);
                                                } else if (
                                                    file?.url?.toLowerCase()?.endsWith('.jpg') ||
                                                    file?.url?.toLowerCase()?.endsWith('.jpeg') ||
                                                    file?.url?.toLowerCase()?.endsWith('.png')
                                                ) {
                                                    setSelectedImage(file?.url);
                                                }
                                            }}
                                            onRemove={() => removeTaskFile(file?.task_template_attachment_id, index)}
                                        />
                                    ))}
                                </div>
                                <div className="mt-3">
                                    <div className="flex items-center flex-wrap gap-2">
                                        {uploadingFiles.map((image, index) => (
                                            <div key={generateId() + index}>
                                                <div className="flex items-start gap-3 my-3 flex-wrap">
                                                    <Badge
                                                        leftIcon={
                                                            <div>
                                                                <GenerateFileIcon
                                                                    url={uploadFiles[index]?.type}
                                                                    showImage
                                                                    imgPrperties={{
                                                                        url: uploadFiles[index]?.url,
                                                                        type: uploadFiles[index].type,
                                                                        onClick: () => {
                                                                            setIsZoomed(true);
                                                                        }
                                                                    }}
                                                                    sx={
                                                                        'thumbnail-image cursor-pointer !w-10 !h-10 bg-white rounded-md mr-2'
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                        text={uploadFiles[index]?.name?.slice(0, 7)}
                                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                                        onCancel={() => removeFile(index)}
                                                    />
                                                    {isZoomed && (
                                                        <ZoomImage
                                                            src={image}
                                                            alt="snow"
                                                            onClose={() => setIsZoomed(false)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="flex gap-2 items-center flex-wrap mt-5">
                                    <div className="default-forms w-full">
                                        <label className="mb-2"> Upload Attachment</label>
                                        <DragFile
                                            selectedFiles={uploadFiles}
                                            atChange={(file) => {
                                                setUploadFiles([...file, ...uploadFiles]);
                                            }}
                                            accept={[...acceptFormate, ...acceptFilesFormats]}
                                            isMultiple={true}
                                            hideSelectedFiles={true}
                                            dragMessage="Drag files here to upload, or browse for files"
                                            dropMessage="Drop the selected files in this box... "
                                            message="This file type is not allowed"
                                        />

                                        <div className="mt-5 flex gap-5 items-center flex-wrap">
                                            {uploadFiles?.map((file, index) => (
                                                <FileCardPreview
                                                    url={file?.type}
                                                    key={index}
                                                    showImage={true}
                                                    name={file?.name}
                                                    size={file?.size}
                                                    extension={
                                                        file?.type?.includes('.')
                                                            ? file?.type?.split('.')?.pop()?.toLowerCase()
                                                            : file?.type?.split('/').pop().toLowerCase()
                                                    }
                                                    imgPrperties={{
                                                        url: file,
                                                        type: file?.type,
                                                        sx: ''
                                                    }}
                                                    onClick={() => handlePreviewFile(file)}
                                                    onRemove={() => onRemoveFile(index)}
                                                />
                                            ))}
                                        </div>
                                        {uploadFiles?.length > 0 && (
                                            <div className="flex justify-end w-full mt-5">
                                                <Button
                                                    title={'Upload Attachments'}
                                                    atClick={() => {
                                                        uploadNewAttachments();
                                                    }}
                                                    classes="primaryButton flex gap-3 bg-leaner child:text-semibold text-white py-3 px-7"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-1 lg:mt-0 mt-5 "></div>
                    </div>
                </div>
                <PreSelectedCheckList
                    open={preBuildCheckListView}
                    setOpen={setPreBuildCheckListView}
                    handleSelectedPrebuildList={handleSelectedPrebuildList}
                />
            </Slideover>
        </>
    );
};

export default TaskTemplateDetails;
