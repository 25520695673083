import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getJobActions, markReadJobActionsThunk } from 'store/JobManager';
import { BUSINESS_ID } from 'utills/globalVars';

const useReadActivityHook = () => {
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const markCompleteJobAtion = async (payload) => {
        await dispatch(markReadJobActionsThunk({ urlParams: { ...payload, job_id: id, business_id } })).then((res) => {
            if (res.payload) {
                dispatch(getJobActions.actions.handleUpdate(res.payload));
            }
        });
    };
    return {
        markCompleteJobAtion
    };
};

export default useReadActivityHook;
