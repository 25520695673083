import React, { useEffect, useRef, useState } from 'react';
import { PasteIcon } from 'utills/svgs/PasteIcon';

export const OtpInputs = ({ _value, onChange, required, clearOtp }) => {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const inputRefs = useRef([]);

    const handleChange = (index, inputValue) => {
        if (!/^\d*$/.test(inputValue)) {
            return;
        }

        const newOtp = [...otp];
        newOtp[index] = inputValue.slice(-1);

        setOtp(newOtp);

        if (inputValue && index < 5) {
            inputRefs.current[index + 1].focus();
        }

        onChange(newOtp.join(''));
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
        const formattedData = pastedData.replace(/[^\d]/g, '');
        const newOtp = formattedData.split('');
        const otpCopy = [...otp];

        newOtp.forEach((digit, index) => {
            otpCopy[index] = digit;
        });

        setOtp(otpCopy);
        onChange(otpCopy.join(''));
    };

    const handleFocusNext = (index, e) => {
        if (index < 5) {
            e.preventDefault();
            inputRefs.current[index + 1].focus();
        }
    };

    const handleFocusPrev = (index, e) => {
        if (index > 0) {
            e.preventDefault();
            inputRefs.current[index - 1].focus();
        }
    };

    const handleBackspace = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            e.preventDefault();
            inputRefs.current[index - 1].focus();
        }
    };

    useEffect(() => {
        setOtp(Array(6).fill(''));
    }, [clearOtp]);

    const handleDivClick = () => {
        try {
            navigator.clipboard?.readText().then((copiedData) => {
                const formattedData = copiedData.replace(/[^\d]/g, '');
                const newOtp = Array(6).fill('');

                for (let i = 0; i < formattedData.length && i < 6; i++) {
                    newOtp[i] = formattedData[i];
                }

                setOtp(newOtp);
                onChange(newOtp.join(''));
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className="flex gap-5 items-center flex-wrap">
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        required={required ?? false}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onPaste={handlePaste}
                        ref={(input) => (inputRefs.current[index] = input)}
                        className={`border rounded-xl lg:w-[53px] lg:h-[53px] w-[45px] h-[45px]   text-center buttons-font ${
                            digit ? '!border-thirdbg' : 'secondary-border'
                        }`}
                        style={{
                            animation: digit ? 'fillBorder 0.3s forwards' : 'none'
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowRight') {
                                handleFocusNext(index, e);
                            } else if (e.key === 'ArrowLeft') {
                                handleFocusPrev(index, e);
                            } else if (e.key === 'Backspace') {
                                handleBackspace(index, e);
                            }
                        }}
                    />
                ))}
            </div>
            <div
                className="mt-3 cursor-pointer resend-confirmation text-thirdbg font-poppins small-text flex gap-2 items-center"
                onClick={handleDivClick}
            >
                Click here to paste code <PasteIcon />
            </div>
        </div>
    );
};
