/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, verifyTwoFactorEmailThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN, USER_TYPE } from 'utills/globalVars';
import { BackArrow } from 'utills/svgs/BackArrow';
import { EmailVerifiedIcon } from 'utills/svgs/EmailVerifiedIcon';
import EmailInput from 'components/atoms/Input/EmailInput';
export const VerifyEmailAuth = ({ handleCondition }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.login);

    const verifyOtpData = {
        user_id: data?.user_id,
        otp: '',
        user_type: USER_TYPE
    };
    const handleOtpSubmit = async () => {
        dispatch(setLoader(true));
        await dispatch(verifyTwoFactorEmailThunk(verifyOtpData))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    const payload = response.payload;
                    if (payload.access_token && payload.refresh_token) {
                        const access_token = payload.access_token;
                        const refresh_token = payload.refresh_token;

                        localStorage.setItem('loggedInUser', payload);
                        localStorage.setItem(ACCESS_TOKEN, access_token);
                        localStorage.setItem(REFRESH_TOKEN, refresh_token);
                        localStorage.setItem(BUSINESS_ID, payload?.business?.business_id);
                    }

                    const newPayload = {
                        ...data,
                        email_2fa: true
                    };
                    dispatch(login.actions.handleUpdate(newPayload));
                    localStorage.setItem('email_2fa', true);
                    navigate('/email/success');
                    handleCondition(1);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });

        dispatch(setLoader(false));
    };

    return (
        <div>
            <div>
                <div className="card-heading-two px-5 pt-5 flex   items-center">
                    <div onClick={() => handleCondition(1)} className="arrow-wrapper mt-1 cursor-pointer">
                        <BackArrow />
                    </div>
                    <div>
                        {' '}
                        <h1>2FA Email Verification</h1>
                    </div>
                </div>

                <div className="flex justify-center items-center mt-10">
                    <EmailVerifiedIcon />
                </div>
                <div className="small-text text-[#666666] font-poppins px-5 mt-10">
                    {' '}
                    Your email has been already verified! Click &apos;Activate&apos; to enable email 2FA and enhance
                    your account security.
                </div>
                <div className="px-5 default-forms mt-5">
                    <label>Email </label>
                    <div className="mt-2">
                        <EmailInput input type="email" value={data?.email} disabled />
                    </div>
                </div>

                <div className="email-verify mt-24  my-5 flex justify-center items-center">
                    <button onClick={handleOtpSubmit} className=" px-12 buttons-font rounded-lg">
                        Activate
                    </button>
                </div>
            </div>
        </div>
    );
};
