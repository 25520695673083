import React, { useState } from 'react';
import { Chip } from '../buttons/Chip';
import { AttachmentIcon2 } from 'utills/svgs/AttachmentIcon2';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../buttons/Button';
import { DimmedDeleteIcon2 } from 'utills/svgs/DimmedDeleteIcon2';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { downloadFile } from 'utills/filesHandlers';
import { useDispatch, useSelector } from 'react-redux';
import { removeDraftFileThunk } from 'store/JobManager';
import _DraftEditor from 'components/molecules/DraftEditor';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

const MailerInputBody2 = ({
    sx,
    _hideTemplate = false,

    editorContent,
    setEditorContent = () => {},
    forceUpdate,
    setForceUpdate,
    selectedFiles = [],
    setSelectedFilesFun,
    handleSubmitData = () => {},
    inputId = 'mailer-select',
    onRemove
}) => {
    const dispatch = useDispatch();

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');
    const { selectedDraft } = useSelector((state) => state.draftListReducer);

    const handleRemoveFile = (i, file = null) => {
        if (file && file?.url) {
            const jsonPayload = {
                email_draft_id: selectedDraft?.email_draft_id,
                url: file?.url
            };
            dispatch(removeDraftFileThunk(jsonPayload));
        }

        const newFiles = [...selectedFiles];
        newFiles.splice(i, 1);
        setSelectedFilesFun(newFiles);
    };

    const handlePreviewFile = (file) => {
        if ((file instanceof File || file instanceof Blob) && !file?.url) {
            const url = URL.createObjectURL(file);

            if (file.type.includes('jpg') || file.type.includes('png') || file.type.includes('jpeg')) {
                setSelectedImage(url);
            } else if (file.type.includes('application/pdf')) {
                setSelectedPdf(url);
            } else {
                downloadFile(url);
            }
        } else {
            if (file?.url) {
                if (file.mimetype.includes('jpg') || file.mimetype.includes('png') || file.mimetype.includes('jpeg')) {
                    setSelectedImage(file?.url);
                } else if (file.mimetype.includes('application/pdf')) {
                    setSelectedPdf(file?.url);
                } else {
                    downloadFile(file?.url);
                }
            }
        }
    };
    return (
        <div>
            {selectedImage && (
                <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} isLocal={true} />
            )}
            {selectedPdf && (
                <GlobalPDFViewer
                    url={selectedPdf}
                    setOpen={() => setSelectedPdf('')}
                    open={selectedPdf ? true : false}
                    isLocal={true}
                />
            )}
            <div className={sx?.wraper}>
                {/* <DraftEditor
                    onChange={(val) => setEditorContent(val)}
                    initialValue={editorContent}
                    maxWords={10000}
                    required={true}
                    placeholder="Enter Message"
                    forceUpdate={forceUpdate}
                    setForceUpdate={setForceUpdate}
                    sx={sx?.innerWrap}
                    inputsx={'!h-auto !mb-0'}
                /> */}
                <DraftEditorNew
                    onChange={(val) => setEditorContent(val)}
                    initialValue={editorContent}
                    required={true}
                    placeholder="Enter Message"
                    forceUpdate={forceUpdate}
                    setForceUpdate={setForceUpdate}
                    sx={sx?.innerWrap}
                    inputsx={' !mb-5 !border-none !w-[100%]'}
                    extraConfigs={{
                        statusbar: false,
                        enter: 'DIV',
                        height: 400,
                        width: '97%',
                        allowResizeX: false,
                        allowResizeY: false
                    }}
                />
                <div className="flex items-start justify-start gap-1 flex-wrap px-3 mb-4">
                    {selectedFiles?.map((file, i) => (
                        <div key={'chip_index' + i}>
                            <Chip
                                title={file?.name ?? file?.originalname ?? ''}
                                classes="bg-[#B695F842] flex items-center cursor-pointer gap-1 px-3 py-1.5 !rounded-lg !font-medium text-gray-800"
                                titleClick={() => handlePreviewFile(file)}
                                iconSet={{
                                    leftIcon: AttachmentIcon2,
                                    rightIcon: XMarkIcon,
                                    rightIconProps: {
                                        className: 'w-5 h-5 !p-1 !text-black !bg-white rounded-full cursor-pointer',
                                        onClick: () => {
                                            handleRemoveFile(i, file);
                                        }
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
                <hr />
                <div className="px-3 py-4">
                    <div className="flex items-center justify-between gap-2 flex-wrap">
                        {setSelectedFilesFun && (
                            <input
                                type="file"
                                onChange={(e) => {
                                    setSelectedFilesFun([...selectedFiles, ...e.target.files]);
                                }}
                                className="hidden"
                                multiple
                                id={inputId}
                            />
                        )}
                        <div className="flex items-center gap-4 flex-wrap">
                            <label htmlFor={inputId}>
                                <AttachmentIcon2 className={'w-4 cursor-pointer'} />
                            </label>
                        </div>
                        <div className="flex items-center gap-2 flex-wrap">
                            {onRemove && (
                                <span onClick={() => onRemove()}>
                                    <DimmedDeleteIcon2 className={'w-7 h-5 fill-gray-800 cursor-pointer'} />
                                </span>
                            )}
                            <Button
                                atClick={() => {
                                    handleSubmitData();
                                }}
                                title="Send"
                                classes="!rounded-lg px-4 text-white buttons-font-lighter bg-linear !py-2 font-poppins flex justify-center items-center !text-sm"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MailerInputBody2;
