import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BoardIcon } from 'utills/svgs/BoardIcon';
import { useState } from 'react';
import { SearchInput } from 'components/molecules/SearchInput';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { DatePick } from 'components/atoms/inputs/DatePick';

const NotesOperations = ({ searchByTitle, toggleAddNote, filterByDate }) => {
    const [date, setDate] = useState('');

    const [fromDate, setFromDate] = useState(null);

    const handleClearFilter = () => {
        setDate('');
        filterByDate('');
    };

    const datePickerProps = {
        right: CalendarSmallIco
    };

    const handleFromDateChange = (date) => {
        filterByDate(date);
        setFromDate(date);
    };
    return (
        <div className="flex justify-between flex-wrap md:px-10 px-5 gap-5">
            <SearchInput
                width="300px"
                atChange={(e) => {
                    searchByTitle(e.target.value);
                }}
                placeholder="Search"
            />
            <div className="flex gap-5">
                <div>
                    {' '}
                    <div className="flex gap-2 items-center">
                        {date && (
                            <div onClick={handleClearFilter} className="td-generic flex justify-center items-center">
                                <button className="px-10 py-2 rounded-md">Clear</button>
                            </div>
                        )}

                        <DatePick
                            iconSet={datePickerProps}
                            onDateChange={handleFromDateChange}
                            placeholder="Select Date"
                            value={fromDate}
                            bg="transparent"
                            sx="!rounded-xl text-gray-900 cursor-pointer"
                        />
                    </div>
                </div>

                <button
                    onClick={toggleAddNote}
                    className="ann-btn flex gap-2 items-center px-8  rounded-xl buttons-font-lighter font-fira"
                >
                    <span>
                        <BoardIcon />
                    </span>
                    <span>Add Note</span>
                </button>
            </div>
        </div>
    );
};

export default NotesOperations;
