import { useDispatch } from 'react-redux';
import { employeeEfficiencyThunk } from 'store/workspace/workspaceEmployeeTasks';
import { setLoader } from 'store/global/globalReducer';

export const useGetEmpEfficiency = () => {
    const dispatch = useDispatch();

    const fetchEfficiency = (id) => {
        dispatch(setLoader(true));
        dispatch(employeeEfficiencyThunk({ employee_id: id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return { fetchEfficiency };
};
