import React from 'react';
import GoogleQrPage from '../../../../components/dashboard-components/auth/GoogleQrPage';
const GoogleQr = () => {
    return (
        <div>
            <GoogleQrPage />
        </div>
    );
};

export default GoogleQr;
