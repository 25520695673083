import { APP_ENVIRONMENT, BASE_URL, CSRF_TOKEN } from './../../utills/globalVars';
import { toastHandler } from './../../responseHanlder';
import { getCookie } from 'utills/encryption/cryptoUtils';

export const useDownloadRecentAcitivty = () => {
    const handleDownloadPdf = async (payload) => {
        let csrfToken = getCookie(CSRF_TOKEN);

        const _response = await fetch(`${BASE_URL}/customers/pdf/activity`, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            credentials: 'include',

            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'file';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    toastHandler('File Has been downloaded!', 'success');
                } else {
                    toastHandler('No File to download', 'error');
                }
            })
            .finally(() => {});
    };

    return { handleDownloadPdf };
};
