import React from 'react';

export const TextInfoIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="13"
            height="15"
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id="mask0_1344_954" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="21">
                <path
                    d="M16.2 6.88905V1.95C16.2 1.69804 16.0999 1.45641 15.9218 1.27825C15.7436 1.10009 15.502 1 15.25 1H1.95C1.69804 1 1.45641 1.10009 1.27825 1.27825C1.10009 1.45641 1 1.69804 1 1.95V19.05C1 19.302 1.10009 19.5436 1.27825 19.7218C1.45641 19.8999 1.69804 20 1.95 20H6.7"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.80273 5.75H10.9777M4.80273 9.075H7.17773"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                />
                <path
                    d="M8.59961 15.25C8.59961 17.8734 10.7263 20 13.3496 20C15.973 20 18.0996 17.8734 18.0996 15.25C18.0996 12.6266 15.973 10.5 13.3496 10.5C10.7263 10.5 8.59961 12.6266 8.59961 15.25Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M13.3496 16.2007V17.6257" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
                <path
                    d="M13.3504 14.2999C13.8751 14.2999 14.3004 13.8746 14.3004 13.3499C14.3004 12.8252 13.8751 12.3999 13.3504 12.3999C12.8257 12.3999 12.4004 12.8252 12.4004 13.3499C12.4004 13.8746 12.8257 14.2999 13.3504 14.2999Z"
                    fill="black"
                />
            </mask>
            <g mask="url(#mask0_1344_954)">
                <path d="M-2.80273 -0.899902H19.9973V21.9001H-2.80273V-0.899902Z" fill="url(#paint0_linear_1344_954)" />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1344_954"
                    x1="9.71596"
                    y1="-0.616749"
                    x2="9.69889"
                    y2="21.9001"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
