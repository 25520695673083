import React from 'react';

export const FilesIcon = ({ className }) => {
    return (
        <div>
            <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                    fill="#2B3467"
                />
                <rect y="8" width="34" height="16.79" rx="4" fill="#2B3467" />
            </svg>
        </div>
    );
};
