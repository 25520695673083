/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { APP_RECAPCHA_KEY } from 'utills/globalVars';

const Recaptcha = forwardRef(({ onValueChange, captchaError, setCaptchaError }, ref) => {
    const [reloadCaptcha, setReloadCaptcha] = useState(true);

    useEffect(() => {
        if (!reloadCaptcha) {
            setTimeout(() => {
                setReloadCaptcha(true);
            }, 200);
        }
    }, [reloadCaptcha]);
    useEffect(() => {
        if (captchaError) {
            setTimeout(() => {
                setCaptchaError(false);
            }, 2000);
        }
    }, [captchaError]);

    // Expose the resetCaptcha method to the parent component
    useImperativeHandle(ref, () => ({
        resetCaptcha() {
            setReloadCaptcha(false);
            // captchaRef.current?.reset();
        }
    }));

    const onChange = (value) => {
        onValueChange(value);
    };

    return (
        <>
            {reloadCaptcha && (
                <>
                    <ReCAPTCHA sitekey={APP_RECAPCHA_KEY} onChange={onChange} />
                    {captchaError && (
                        <p className={'flex text-sm text-red-600 font-[400] mt-1 ml-1  text-sm text-red-900'}>
                            Captcha is required
                        </p>
                    )}
                </>
            )}
        </>
    );
});

export default Recaptcha;
