/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-concat */
import React from 'react';
import { cn } from 'utills/tailwindUtil';

const SkeletonTable = ({ columns, showLogo, logoIndex, sxLogo, showZigZag, minLength = 3, showExtraRows = false }) => {
    return (
        <>
            {Array.from({ length: minLength }, (_, ind) => (
                <div className="my-3" key={'SkeletonTable_' + ind}>
                    <div className="bg-white rounded-lg shadow-md p-4 animate-pulse">
                        <table className="bg-transparent w-full text-left">
                            <tbody>
                                <tr>
                                    {Array.from({ length: columns }, (_, i) => {
                                        if (showLogo && i === logoIndex) {
                                            return (
                                                <td
                                                    className="whitespace-nowrap ml-1 pr-[4px] max-w-[1px]"
                                                    key={'SkeletonTable' + '_' + ind + i}
                                                >
                                                    <div className="flex items-center justify-start flex-wrap">
                                                        <div
                                                            className={cn(
                                                                'w-10 h-10 bg-gray-300 m-auto rounded-full mb-1',
                                                                sxLogo || ''
                                                            )}
                                                        />
                                                    </div>
                                                </td>
                                            );
                                        }
                                        if (i === length - 1) {
                                            return (
                                                <td
                                                    className="whitespace-nowrap px-3 py-3.5"
                                                    key={'SkeletonTable' + '_' + ind + i}
                                                >
                                                    <div className="w-full h-3 bg-gray-300 rounded-full mb-1" />
                                                    <div
                                                        className={cn(
                                                            'w-2/3 h-3 bg-gray-300 rounded-full mb-1',
                                                            showZigZag && ind + (i % 2) !== 0 && 'float-right'
                                                        )}
                                                    />
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td
                                                    className="whitespace-nowrap px-3 py-3.5"
                                                    key={'SkeletonTable' + '_' + ind + i}
                                                >
                                                    <div className="w-full h-3 bg-gray-300 rounded-full mb-1" />
                                                    <div
                                                        className={cn(
                                                            'w-2/3 h-3 bg-gray-300 rounded-full mb-1',
                                                            showZigZag && ind + (i % 2) !== 0 && 'float-right'
                                                        )}
                                                    />
                                                </td>
                                            );
                                        }
                                    })}
                                </tr>
                                {showExtraRows &&
                                    Array.from({ length: columns }, (_, i) => {
                                        return (
                                            <tr key={'SkeletonTable_row_extra' + '_' + ind + i}>
                                                <td colSpan={columns} className="whitespace-nowrap px-3 py-3.5">
                                                    <div className="w-full h-3 bg-gray-300 rounded-full mb-1" />
                                                    <div className="w-full h-3 bg-gray-300 rounded-full mb-1" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </>
    );
};

export default SkeletonTable;
