import React from 'react';
import { DefaultProfileViewer } from './DefaultProfileViewer';
import { WhiteTimesIcon } from 'utills/svgs/WhiteTimesIcon';
import './css/molecules.css';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';
export const UserProfileChip = ({ image, name, email, atClick, colorScheme, hideIcon, id, user_id }) => {
    return (
        <div
            style={{
                backgroundColor: colorScheme?.backgroundColor,
                color: colorScheme?.textColor
            }}
            className="user-profile-chip flex gap-2 items-center p-2"
        >
            <DefaultProfileViewer image={image} width="37px" height="37px" name={name} />
            <div>
                <TooltipChipWrap
                    sx={'!bg-gray-100 !text-gray-800 !text-[10px] !-transform-y-[20px]'}
                    hideChip
                    title={name || 'No Name'}
                    placement="top"
                >
                    <div className="user-profile-chip-name">{name && name?.slice(0, 15)}</div>
                </TooltipChipWrap>
                <TooltipChipWrap
                    title={email || 'No Email'}
                    sx={'!bg-gray-100 !text-gray-800 !text-[10px]'}
                    hideChip
                    placement="bottom"
                >
                    <div className="user-profile-chip-email text-sm">{email && email?.slice(-30)}</div>
                </TooltipChipWrap>
            </div>
            {hideIcon || id === user_id ? (
                ''
            ) : (
                <div onClick={atClick} className="cursor-pointer">
                    <WhiteTimesIcon />
                </div>
            )}
        </div>
    );
};
