/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/atoms/buttons/Button';
import React, { useEffect, useState } from 'react';
import { SendMailIcon2 } from 'utills/svgs/SendMailIcon2';
import ComposeNewEmail from './ComposeNewEmail';
import RelayRoomLeftbar from './RelayRoomLeftbar';
import RelayRoomRightbar from './RelayRoomRightbar';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDraft } from 'store/custom-reducers/RelayRoomDraftReducer';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';

const RelayRoom = () => {
    const dispatch = useDispatch();

    const { selectedDraft } = useSelector((state) => state.draftListReducer);
    const { totalSendEmail, totalReceivedEmail, totalDraftEmail, totalUnRead } = useSelector(
        (state) => state.getRelayRoomList
    );

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    const [searchParams] = useSearchParams();
    const [newEmail, setNewEmail] = useState(false);
    const [preSelectedData, setPreSelectedData] = useState(null);
    const [hasEmpty, setHasEmpty] = useState(true);

    const [openThread, setOpenThread] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
        if (totalSendEmail === 0 && totalReceivedEmail === 0 && totalDraftEmail === 0 && totalUnRead === 0) {
            setHasEmpty(true);
        } else {
            setHasEmpty(false);
        }
    }, [totalSendEmail, totalReceivedEmail, totalDraftEmail, totalUnRead]);

    useEffect(() => {
        if (selectedDraft) {
            setNewEmail(true);
        }
    }, [selectedDraft]);

    useEffect(() => {
        if (!newEmail) {
            dispatch(setSelectedDraft(null));
        }
    }, [newEmail]);

    useEffect(() => {
        const jobEmailId = searchParams.get('job_email_id');
        if (jobEmailId) {
            setOpenThread(true);
        } else {
            setOpenThread(false);
        }
    }, [searchParams]);

    const GenerateEmptyDiv = ({ title = 'No Email Found' }) => {
        return (
            <div className="chat-box w-full mt-5 px-5 pt-10 pb-20 h-full content-center">
                <div className="flex flex-col items-center justify-center gap-2">
                    <SendMailIcon2 className={'w-18 h-18'} />
                    <span className="text-secondarybg font-medium text-md">{title}</span>
                    <Button
                        title="Compose New Mail"
                        atClick={() => {
                            setNewEmail(true);
                        }}
                        classes="bg-primary text-base text-white rounded-xl px-4 py-2.5 mt-3"
                        disabled={disabled}
                    />
                </div>
            </div>
        );
    };
    return (
        <div className="mb-5">
            {hasEmpty && (
                <div className="bg-white rounded-xl shadow-md py-5">
                    <div className="flex justify-between items-center px-5 border-b border-gray-200 pb-5">
                        <h3 className={`text-xl font-[500] font-poppins`}>Relay Room</h3>
                    </div>
                    <GenerateEmptyDiv />
                </div>
            )}

            {!hasEmpty && (
                <div className="grid xl:grid-cols-3 gap-2">
                    <div className="bg-white !py-5 rounded-xl shadow-md">
                        <RelayRoomLeftbar
                            setOpen={setOpenThread}
                            forceUpdate={forceUpdate}
                            setForceUpdate={setForceUpdate}
                            setNewEmail={setNewEmail}
                        />
                    </div>
                    <div className="xl:col-span-2 bg-white rounded-xl shadow-md">
                        {openThread ? (
                            <RelayRoomRightbar
                                setNewEmail={setNewEmail}
                                setForceUpdate={setForceUpdate}
                                setPreSelectedData={setPreSelectedData}
                            />
                        ) : (
                            <GenerateEmptyDiv title={'Click to open Threads'} />
                        )}
                    </div>
                </div>
            )}

            {newEmail && (
                <ComposeNewEmail
                    open={newEmail}
                    setOpen={setNewEmail}
                    preSelectedData={preSelectedData}
                    setPreSelectedData={setPreSelectedData}
                />
            )}
        </div>
    );
};

export default RelayRoom;
