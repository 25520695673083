import useRoleGuard from 'Hooks/useRoleGuard';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { Loader } from 'components/common/Loader';
import { NoDataFound } from 'components/common/NoDataFound';
import { ComingSoon } from 'components/molecules/ComingSoon';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { CreateDesignation } from 'components/settings/workspace-settings/CreateDesignation';
import { EditDesignation } from 'components/settings/workspace-settings/EditDesignation';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { getAllDesignationsThunk, inactiveDesignationThunk } from 'store/settings/team/workspaceSettings';
import ConfirmationModal from 'utills/confirmationModal';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { formateDate } from 'utills/moment';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';

export const TelephonicDirectorySettings = () => {
    const dispatch = useDispatch();
    const [addDesignation, setAddDesignation] = useState(false);
    const [edit_designation, set_edit_designation] = useState(false);
    const { data: designations } = useSelector((state) => state.getAllDesignations);
    const { isLoading } = useSelector((state) => state.global);

    const [designation_id, set_designation_id] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selected_designation, set_selected_designation] = useState({});
    const [commingSoon, setCommingSoom] = useState(false);

    const { hasPermissions } = useRoleGuard();

    const toggleCreateDesignation = () => {
        if (hasPermissions('Telephonic_directories', ['write', 'admin'])) {
            setAddDesignation(!addDesignation);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const handleGetDesignations = () => {
        dispatch(setLoader(true));
        dispatch(getAllDesignationsThunk())
            .then((response) => {
                console.error(response);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (hasPermissions('Telephonic_directories', ['read', 'write', 'admin'])) {
            handleGetDesignations();
        }
    }, []);

    const handleOpenConfirmation = (id) => {
        if (hasPermissions('Telephonic_directories', ['write', 'admin'])) {
            set_designation_id(id);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const handleInactiveDesignation = () => {
        const payload = {
            designation_id
        };
        dispatch(setLoader(true));
        dispatch(inactiveDesignationThunk(payload))
            .then((response) => {
                if (response.payload) {
                    handleGetDesignations();
                    setIsConfirmationOpen(false);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleEditDesignation = (designation) => {
        if (hasPermissions('Telephonic_directories', ['write', 'admin'])) {
            set_selected_designation(designation);
            set_edit_designation(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    return (
        <div>
            <SidebarLayout>
                <RoleBasedGuard
                    module={'Telephonic_directories'}
                    permissions={['read', 'write', 'admin']}
                    sx="h-[50vh]"
                >
                    {commingSoon && (
                        <Slideover open={commingSoon} setOpen={setCommingSoom}>
                            <ComingSoon />
                        </Slideover>
                    )}
                    <ConfirmationModal
                        isOpen={isConfirmationOpen}
                        onClose={() => setIsConfirmationOpen(false)}
                        onConfirm={handleInactiveDesignation}
                    />

                    {isLoading && <Loader />}
                    {addDesignation && (
                        <CreateDesignation
                            open={addDesignation}
                            setOpen={setAddDesignation}
                            handleGetDesignations={handleGetDesignations}
                        />
                    )}

                    {edit_designation && (
                        <EditDesignation
                            open={edit_designation}
                            setOpen={set_edit_designation}
                            handleGetDesignations={handleGetDesignations}
                            selected_designation={selected_designation}
                        />
                    )}

                    <Jumbotron
                        title="Workspace Settings"
                        path={[
                            {
                                link: 'settings',
                                title: 'Settings'
                            },
                            {
                                link: 'workspace-settings',
                                title: 'Workspace Settings'
                            },
                            {
                                link: '',
                                title: 'Telephonic Directory Settings'
                            }
                        ]}
                    />

                    <div className="md:mx-10 mx-5 mt-5">
                        <div className="flex justify-end items-center">
                            <Button
                                title="Create Designation"
                                classes="ann-btn rounded-xl buttons-font-lighter px-5 "
                                atClick={() => toggleCreateDesignation()}
                            />
                        </div>

                        <div className="mt-10"></div>

                        {!isLoading && designations?.length < 1 ? (
                            <div className="w-full ">
                                {' '}
                                <NoDataFound
                                    message="No designation Found!.You can click on 'Create Designation' button to add one    "
                                    height="400px"
                                />
                            </div>
                        ) : (
                            <div className="px-4 sm:px-6 lg:px-8 bg-white rounded-xl">
                                <div className="mt-3 flow-root">
                                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                            <table className="min-w-full divide-y divide-gray-300">
                                                <thead>
                                                    <tr className="divide-x divide-gray-200">
                                                        <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-4 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                                        >
                                                            Designation
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-3.5 text-left text-md font-semibold text-gray-900"
                                                        >
                                                            Created Date
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-3.5 text-left text-md font-semibold text-gray-900"
                                                        >
                                                            Last Updated
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-3.5 text-left text-md font-semibold text-gray-900"
                                                        >
                                                            Logs
                                                        </th>

                                                        <th
                                                            scope="col"
                                                            className="py-3.5 pl-4 pr-4 text-left text-md font-semibold flex justify-center items-center text-gray-900 sm:pr-0"
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {designations?.map((person, index) => (
                                                        <tr key={index} className="divide-x divide-gray-200">
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-md font-medium text-gray-900 sm:pl-0">
                                                                {person?.title}
                                                            </td>
                                                            <td className="whitespace-nowrap p-4 text-md text-gray-500">
                                                                {formateDate(person?.created_at)}
                                                            </td>
                                                            <td className="whitespace-nowrap p-4 text-md text-gray-500">
                                                                {formateDate(person?.updated_at)}
                                                            </td>
                                                            <td className="whitespace-nowrap p-4 text-md text-gray-500">
                                                                <div
                                                                    onClick={() => setCommingSoom(true)}
                                                                    className="font-poppins font-semibold buttons-font text-blueish flex items-center underline cursor-pointer"
                                                                >
                                                                    View Logs
                                                                </div>
                                                            </td>
                                                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-md text-gray-500 sm:pr-0">
                                                                <div className="flex gap-2 items-center justify-center">
                                                                    <span
                                                                        className="cursor-pointer"
                                                                        onClick={() => {
                                                                            if (
                                                                                !hasPermissions(
                                                                                    'Telephonic_directories',
                                                                                    ['admin']
                                                                                )
                                                                            ) {
                                                                                return toastHandler(
                                                                                    "You don't have permission to perform this action",
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }
                                                                            handleEditDesignation(person);
                                                                        }}
                                                                    >
                                                                        <BgEditIcon />
                                                                    </span>
                                                                    <span
                                                                        className="cursor-pointer"
                                                                        onClick={() => {
                                                                            if (
                                                                                !hasPermissions(
                                                                                    'Telephonic_directories',
                                                                                    ['admin']
                                                                                )
                                                                            ) {
                                                                                return toastHandler(
                                                                                    "You don't have permission to perform this action",
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }

                                                                            handleOpenConfirmation(
                                                                                person.designation_id
                                                                            );
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <BgDeleteIcon />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
