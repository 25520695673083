import React from 'react';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import '../css/atoms.css';
import { FileIconSvg } from 'utills/svgs/FileIconSvg';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { getFileExtenstion } from 'utills/filesHandlers';
export const DragFile = ({
    atChange,
    dragMessage,
    dropMessage,
    isMultiple,
    selectedFiles,
    accept,
    _onlyPdf,
    message,
    iconSet,
    _isDisabled,
    hideSelectedFiles,
    iconScale
}) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            const isValidFiles = acceptedFiles.every((file) => {
                return accept?.includes(file?.type ? file?.type : getFileExtenstion(file));
            });
            // const isValidFiles = acceptedFiles.every((file) => {
            //     // Get the file extension from the file name
            //     const fileExtension = file.name.split('.').pop();
            //     // Check if the file extension is included in the accepted file formats array
            //     return accept.some((format) => {
            //         // Extract the file format extension from the accepted format string
            //         const acceptedExtension = format.split('/').pop();
            //         // Compare the file extension with the accepted format extension
            //         return acceptedExtension === fileExtension;
            //     });
            // });

            const isValidFileSize = acceptedFiles.every((file) => file.size <= 10 * 1024 * 1024);

            if (!isValidFiles) {
                toastHandler(message ? message : 'File not allowed', TOAST_TYPE_ERROR);
            } else if (!isValidFileSize) {
                toastHandler('File size must be less than 10 MB', TOAST_TYPE_ERROR);
            } else {
                atChange(acceptedFiles);
            }
        },
        [accept, atChange]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: isMultiple
    });
    return (
        <div
            className={`drag-file-container rounded-md cursor-pointer font-poppins   font-font4  ${
                selectedFiles && ''
            } `}
        >
            {' '}
            <div
                {...getRootProps()}
                className="drag-file-inner-container rounded-md py-5 flex justify-center items-center"
            >
                <input {...getInputProps()} accept={'*'} />
                {!hideSelectedFiles && selectedFiles.length > 0 ? (
                    <div>
                        {selectedFiles?.map((file, index) => (
                            <span
                                key={'selected_item' + index}
                                className={`flex gap-2 items-center ${index > 0 && 'mt-2'} `}
                            >
                                {' '}
                                <span className="">
                                    <FileIconSvg />
                                </span>
                                <span>{file?.name}</span>
                            </span>
                        ))}
                    </div>
                ) : isDragActive ? (
                    <span className="flex gap-2 items-center flex-col">
                        {' '}
                        {iconSet?.icon ? (
                            <span className={`${iconScale ? 'scale-125' : ''}`}>
                                <iconSet.icon />{' '}
                            </span>
                        ) : (
                            <span className={`${iconScale ? 'scale-125' : ''}`}>
                                <FileIconSvg />{' '}
                            </span>
                        )}
                        <span>{dropMessage}</span>
                    </span>
                ) : (
                    <span className="flex gap-2 items-center flex-col ">
                        {iconSet?.icon ? (
                            <span className={`${iconScale ? 'scale-125' : ''}`}>
                                <iconSet.icon />{' '}
                            </span>
                        ) : (
                            <span className={`${iconScale ? 'scale-125' : ''}`}>
                                <FileIconSvg />{' '}
                            </span>
                        )}

                        <span className="mt-2"> {dragMessage}</span>
                    </span>
                )}
            </div>
        </div>
    );
};
