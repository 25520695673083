import React from 'react'
import blueForm from "../../files/blueForm.png"
export const BlueCustomerForm = () => {
  return (
    <div>
 
   <img src={blueForm} alt="profile" className='scale-75' />
    </div>
  )
}
