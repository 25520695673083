import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';
import { LargeTaskIcon } from './../../../utills/svgs/LargeTaskIcon';
import { LargeJobsIcon } from './../../../utills/svgs/LargeJobsIcon';
import { LargeNotePad } from './../../../utills/svgs/LargeNotePad';

export const JobManager = () => {
    return (
        <SidebarLayout>
            <Jumbotron
                title="Job Manager"
                path={[
                    {
                        link: '',
                        title: 'Job Manager'
                    }
                ]}
            />
            <div className="md:mx-10 mx-5 flex gap-5 items-center flex-wrap">
                <GridCard text="Jobs" iconSet={{ icon: LargeJobsIcon }} link={'/job-manager/jobs'} />

                <GridCard text="Tasks" iconSet={{ icon: LargeTaskIcon }} link={'/tasks'} />

                <GridCard text="Tasks Reports" iconSet={{ icon: LargeNotePad }} link="/tasks/reports" />
            </div>
        </SidebarLayout>
    );
};
