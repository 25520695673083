import React, { useEffect, useState, useRef } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';
import { PrimaryCheckbox } from '../inputs/PrimaryCheckbox';

import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { PlusVector } from 'utills/svgs/PlusVector';
import { SecondaryParagraph } from '../typography/paragraphs/SecondaryParagraph';

import { XMarkIcon } from '@heroicons/react/20/solid';
import InfiniteScrollComp from '../InfiniteScrollComp';
import { SmallLoader } from 'components/common/SmallLoader';
import TooltipChipWrap from '../tooltipChip/TooltipChipWrap';

export default function SearchAddSelectComboboxSSR({
    label,
    _labelInside = 'Selected Options',
    placeholder = '',
    mainSx = '',
    labelSX = '',
    boxSx = '',
    listSx = '',
    optionsSx = '',
    inputsx = '',
    _dropDownIcon = (
        <svg className="h-5 w-5 text-secondarybg" viewBox="0 0 20 20" fill="none" stroke="currentColor">
            <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    ),
    options = [],
    targetProperty = 'name',
    onChange = () => {},
    onRemove = () => {},
    selectedItems = [],
    maxDisplay = 5,
    children,
    isMultiple = false,
    childSliders = null,
    extraChildProps = null,
    addStr = 'Active Form',
    handlePageChange,
    hasMoreContent,
    onSearchChange
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openSlider, setOpenSlider] = useState(false);

    const dropdownRef = useRef(null);

    function isSelected(item) {
        return selectedOptions.find((el) => el?.[targetProperty] === item?.[targetProperty]) ? true : false;
    }

    function handleSelect(item) {
        if (!isMultiple) {
            if (!isSelected(item)) {
                onChange([item]);
                setSelectedOptions([item]);
            } else {
                handleDeselect(item);
            }
            setIsOpen(false);

            return;
        }

        if (!isSelected(item)) {
            const selectedOptionsUpdated = [
                ...selectedOptions,
                data.find((el) => el?.[targetProperty] === item?.[targetProperty])
            ];
            onChange(selectedOptionsUpdated);
            setSelectedOptions(selectedOptionsUpdated);
        } else {
            handleDeselect(item);
        }
        setIsOpen(true);
    }

    function handleDeselect(item) {
        const selectedOptionsUpdated = selectedOptions.filter((el) => el?.[targetProperty] !== item?.[targetProperty]);
        onChange(selectedOptionsUpdated);
        setSelectedOptions(selectedOptionsUpdated);
        setIsOpen(true);
    }

    // useEffect(() => {
    //     if (searchTerm) {
    //         const newSelected = options?.filter((item) => {
    //             return item?.[targetProperty]?.toLowerCase()?.includes(searchTerm?.toLowerCase());
    //         });
    //         setData(newSelected);
    //     } else {
    //         setData(options);
    //     }
    // }, [searchTerm]);

    useEffect(() => {
        if (options?.length > 0) {
            setData(options);
        } else {
            setData([]);
        }
    }, [options]);

    useEffect(() => {
        setSelectedOptions(selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //change

    const handleKeyDown = (event) => {
        if (
            event.key === 'Backspace' &&
            searchTerm === '' &&
            selectedOptions.length > 0 &&
            event?.target?.value === ''
        ) {
            event.preventDefault(); // Prevent default backspace action
            // Remove last selected item if input is empty
            const updatedState = selectedOptions.slice(0, selectedOptions.length - 1);
            setSelectedOptions(updatedState);
            onChange(updatedState);
        }
    };

    const filterUniqueTargetId = (dataArray) => {
        const uniqueData = dataArray.reduce((acc, current) => {
            const x = acc.find((item) => item?.[targetProperty] === current?.[targetProperty]);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        setSelectedOptions(uniqueData);
        onChange(uniqueData);
    };
    return (
        <Combobox
            as="div"
            // disabled={disabled}
            // onChange={(item) => {
            //     addItemToList(item);
            // }}
            style={{ width: '-webkit-fill-available' }}
        >
            {childSliders && openSlider && (
                <childSliders.addItem
                    open={openSlider}
                    setOpen={() => {
                        setOpenSlider(!openSlider);
                    }}
                    defaultName={searchTerm ? searchTerm : null}
                    {...extraChildProps}
                />
            )}
            <div className={cn('flex items-center justify-center  w-[400px]', mainSx)} ref={dropdownRef}>
                <div className="w-full max-w-full mx-auto">
                    <>
                        {label && (
                            <Combobox.Label
                                className={cn('block text-sm leading-5 font-medium text-gray-700', labelSX)}
                            >
                                {label}
                            </Combobox.Label>
                        )}

                        <div className="relative w-full">
                            <span className="inline-block w-full rounded-md shadow-sm">
                                <Combobox.Button
                                    className={cn(
                                        'cursor-default relative w-full  rounded-xl min-h-[3rem] border border-secondarybg bg-transparent pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-[#666666] transition ease-in-out duration-150 sm:text-sm text-secondarybg sm:leading-5',
                                        boxSx,
                                        selectedOptions?.length > 0 && 'text-gray-900'
                                    )}
                                    onClick={() => setIsOpen(!isOpen)}
                                    open={isOpen}
                                    type="button"
                                >
                                    {/* ......................................... */}

                                    <div className="flex flex-wrap gap-2   w-fit">
                                        {selectedOptions.length > 0 && (
                                            <div className="flex items-center flex-wrap gap-2">
                                                {selectedOptions?.slice(0, maxDisplay).map((item, index) => (
                                                    <div
                                                        key={'item_display' + index}
                                                        className="flex w-fit items-center bg-third rounded-lg px-2 mr-1 flex-nowrap py-1"
                                                    >
                                                        <div className="flex items-center">
                                                            <TooltipChipWrap
                                                                title={
                                                                    <div className="overflow-y-auto max-h-[300px] max-w-[320px]">
                                                                        <div className="flex flex-col gap-2 justify-center">
                                                                            <div className="flex  items-center bg-third rounded-lg px-2 py-2 w-full justify-between">
                                                                                <div className="flex items-center">
                                                                                    <div>
                                                                                        <span className="ml-1 text-white text-sm">
                                                                                            {item?.[targetProperty]}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                                {selectedOptions?.length === 1 ? (
                                                                                    ''
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="ml-1 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                                        onClick={() => {
                                                                                            if (onRemove) {
                                                                                                // if (

                                                                                                // ) {
                                                                                                //     return toastHandler(
                                                                                                //         'Atleast one option is required',
                                                                                                //         TOAST_TYPE_ERROR
                                                                                                //     );
                                                                                                // }

                                                                                                const getDeletedObj =
                                                                                                    selectedOptions.find(
                                                                                                        (prevItem) =>
                                                                                                            prevItem?.[
                                                                                                                targetProperty
                                                                                                            ] ===
                                                                                                            item?.[
                                                                                                                targetProperty
                                                                                                            ]
                                                                                                    );
                                                                                                onRemove(getDeletedObj);
                                                                                            }

                                                                                            const dummyState =
                                                                                                selectedOptions.filter(
                                                                                                    (prevItem) =>
                                                                                                        prevItem?.[
                                                                                                            targetProperty
                                                                                                        ] !==
                                                                                                        item?.[
                                                                                                            targetProperty
                                                                                                        ]
                                                                                                );
                                                                                            filterUniqueTargetId(
                                                                                                dummyState
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <XMarkIcon
                                                                                            className="h-3 w-3"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                sx={'!text-xs !bg-third !p-0'}
                                                                placement="bottom"
                                                            >
                                                                <span className="flex items-center text-white text-sm">
                                                                    <div className="flex flex-col gap-2 justify-center">
                                                                        <div className="flex  items-center rounded-lg w-full justify-between">
                                                                            <div className="flex items-center">
                                                                                <div className="!max-w-[260px] !overflow-hidden truncate">
                                                                                    <span className="ml-1 text-white text-sm  ">
                                                                                        {item[targetProperty]}
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                            <button
                                                                                type="button"
                                                                                className="ml-2 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                                onClick={() => {
                                                                                    if (onRemove) {
                                                                                        const getDeletedObj =
                                                                                            selectedOptions.find(
                                                                                                (prevItem) =>
                                                                                                    prevItem?.[
                                                                                                        targetProperty
                                                                                                    ] ===
                                                                                                    item?.[
                                                                                                        targetProperty
                                                                                                    ]
                                                                                            );
                                                                                        onRemove(getDeletedObj);
                                                                                    }

                                                                                    const dummyState =
                                                                                        selectedOptions.filter(
                                                                                            (prevItem) =>
                                                                                                prevItem?.[
                                                                                                    targetProperty
                                                                                                ] !==
                                                                                                item?.[targetProperty]
                                                                                        );
                                                                                    filterUniqueTargetId(dummyState);
                                                                                }}
                                                                            >
                                                                                <XMarkIcon
                                                                                    className="h-3 w-3"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </TooltipChipWrap>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className="flex !w-auto !flex-1">
                                            <Combobox.Input
                                                className={cn(
                                                    'w-auto bg-transparent !py-1.5 !pl-1 pr-12 !m-[0px] !rounded-xl placeholder-[#979797] placeholder:text-[0.9rem] placeholder:font-[400] !font-[400] !text-[0.9rem] !border-0 focus:border-0 !ring-0 !focus:ring-[0px]',
                                                    selectedOptions.length > 0 ? 'max-w-[150px]' : '',
                                                    inputsx ? inputsx : 'text-gray-900 !text-sm'
                                                )}
                                                onChange={(event) => {
                                                    setIsOpen(true);
                                                    onSearchChange(event.target.value);
                                                    setSearchTerm(event.target.value);
                                                }}
                                                onKeyDown={handleKeyDown} // Handle Enter key press
                                                placeholder={(selectedOptions.length === 0 && placeholder) || ''}
                                                selectedItems={selectedOptions}
                                                focusOnValues
                                                query={searchTerm}
                                            />
                                            <Combobox.Button
                                                type="button"
                                                onClick={() => setIsOpen(!isOpen)}
                                                className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                                            >
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5 text-[#979797]"
                                                    aria-hidden="true"
                                                />
                                            </Combobox.Button>
                                        </div>
                                    </div>

                                    {/* ............................................. */}
                                </Combobox.Button>
                            </span>

                            <Transition
                                // unmount={false}
                                show={isOpen}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                className={cn(
                                    'absolute mt-1 w-full !rounded-lg overflow-hidden bg-white shadow-lg border !z-[25]',
                                    listSx
                                )}
                            >
                                {isOpen && (
                                    <>
                                        <div
                                            type="button"
                                            onClick={() => setOpenSlider(!openSlider)}
                                            className="p-3 border-b bg-white flex gap-5 items-center mx-3 cursor-pointer"
                                        >
                                            <PlusVector />
                                            <SecondaryParagraph sx="text-thirdbg">
                                                {' '}
                                                Add New {data?.length === 0 && searchTerm && `  "${searchTerm}"  `}{' '}
                                                {addStr}
                                            </SecondaryParagraph>
                                        </div>

                                        <InfiniteScrollComp
                                            height={215}
                                            next={handlePageChange}
                                            dataLength={
                                                (data?.length === 0 ? (hasMoreContent ? 1 : 0) : data?.length) || 0
                                            }
                                            hasMore={data?.length !== 0 ? hasMoreContent : false}
                                            loader={
                                                <div className="flex items-center justify-center py-2">
                                                    <SmallLoader scale={125} />
                                                </div>
                                            }
                                        >
                                            <Combobox.Options
                                                static
                                                className={cn(
                                                    '  py-1 text-base bg-white  leading-6   focus:outline-none sm:text-sm sm:leading-5',
                                                    optionsSx
                                                )}
                                            >
                                                {data?.length > 0 && isMultiple && (
                                                    <div
                                                        className={`${
                                                            selectedOptions?.length === data?.length
                                                                ? ' bg-thirdbg/10'
                                                                : 'text-gray-900'
                                                        } hover:bg-thirdbg hover:text-white cursor-pointer select-none relative py-2 px-4 pr-4 flex gap-2 items-center `}
                                                    >
                                                        <div className="h-full "></div>
                                                        <span>
                                                            <PrimaryCheckbox
                                                                checked={!!(selectedOptions?.length === data?.length)}
                                                                label={'Select All'}
                                                                htmlFor="select_all_id"
                                                                id="select_all_id"
                                                                name="select_all_name"
                                                                onchange={(_e) => {
                                                                    if (selectedOptions?.length === data?.length) {
                                                                        onChange([]);
                                                                        setSelectedOptions([]);
                                                                    } else {
                                                                        onChange(data);
                                                                        setSelectedOptions(data);
                                                                    }
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                )}
                                                {data?.map((person, i) => {
                                                    return (
                                                        <div
                                                            type="button"
                                                            key={person?.[targetProperty]}
                                                            value={person}
                                                            onClick={() => handleSelect(person)}
                                                        >
                                                            <div
                                                                className={`${
                                                                    isSelected(person)
                                                                        ? ' bg-thirdbg/10 '
                                                                        : 'text-gray-900'
                                                                } hover:bg-thirdbg hover:text-white cursor-pointer select-none relative py-2 px-4 pr-4 flex gap-2 items-center `}
                                                            >
                                                                <div className="h-full "></div>
                                                                <span>
                                                                    <PrimaryCheckbox
                                                                        checked={isSelected(person)}
                                                                        id={'select_item_id' + i}
                                                                        name={'select_item_id' + i}
                                                                        onchange={(_e) => handleSelect(person)}
                                                                    />
                                                                </span>
                                                                <label
                                                                    htmlFor={'select_item_id' + i}
                                                                    className={`${
                                                                        isSelected(person) ? '' : 'font-normal'
                                                                    } block truncate`}
                                                                >
                                                                    {person?.[targetProperty]}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Combobox.Options>
                                        </InfiniteScrollComp>
                                        <div
                                            className={` ${
                                                children &&
                                                'p-3 border-t flex justify-end bg-white rounded-bl-lg rounded-br-lg'
                                            }`}
                                        >
                                            {children}
                                        </div>
                                    </>
                                )}
                            </Transition>
                        </div>
                    </>
                </div>
            </div>
        </Combobox>
    );
}
