import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import PDFViewer from './PDFViewer';

import { Card, CardContent } from './hooks/card';
import { SigningProvider } from './hooks/provider';
import { SigningForm } from './hooks/form';

import { useDispatch, useSelector } from 'react-redux';
import { documentDetailToSignThunk, getSignaturesThunk } from 'store/fileManager';
import { setLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import useBase64Converter from 'Hooks/Files/useBase64Converter';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';

import { WhitePlus } from 'utills/svgs/WhitePlus';
import { WhiteMinus } from 'utills/svgs/WhiteMinus';
import { FitIcon } from 'utills/svgs/FitIcon';
import { Modal } from 'components/molecules/Modal';

import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { clientTypes, getProfileName } from 'utills/globalVars';
import cloneDeep from 'lodash/cloneDeep';
import { BoxDisplay } from './hooks/BoxDisplay';
import { SidebarLayout } from 'layouts/SidebarLayout';

const DocumentsPage = () => {
    const navigate = useNavigate();
    const { convertUrlToBase64 } = useBase64Converter();
    const { data: signatures } = useSelector((state) => state.getSignatures);
    const { isLoading } = useSelector((state) => state.global);
    const { data: userInfo } = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const [newData, setNewData] = useState(null);
    const { data } = useSelector((state) => state.documentDetailToSign);
    const { data: user } = useSelector((state) => state?.viewBusinessCustomerInfo);
    const [selectedSignature, setSelectedSignature] = useState(null);
    const { id, client_id } = useParams();
    const [pageNumber, _setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [isExpand, setIsExpand] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [submitedResponse, setSubmitedResponse] = useState(null);
    const [isPDFLoaded, setIsPDFLoaded] = useState(false);

    const handleExpand = () => {
        setIsExpand(!isExpand);
    };

    const handleZoomIn = () => {
        if (scale < 2) {
            setScale(scale + 0.1);
        }
    };

    const handleZoomOut = () => {
        setScale(Math.max(scale - 0.1, 0.1));
    };

    const zoomPercentage = Math.round(scale * 100);

    const toggleModal = (res) => {
        setSubmitedResponse(res);
        setShowModal(!showModal);
    };

    useEffect(() => {
        if (data) {
            setNewData(data);
        }
    }, [data]);

    const payload = {
        customer_document_id: id
    };

    const [currentIndex, setCurrentIndex] = useState(-1);

    const handleNextElement = () => {
        const elements = document.getElementsByClassName('elementToFocus');

        if (elements.length > 0) {
            if (currentIndex >= 0 && currentIndex < elements.length) {
                elements[currentIndex].classList.remove('focusedElement');
            }
            const nextIndex = (currentIndex + 1) % elements.length;
            setCurrentIndex(nextIndex);
            elements[nextIndex].classList.add('focusedElement');
            elements[nextIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const fetchSavedSignatures = () => {
        dispatch(getSignaturesThunk()).catch((error) => {
            console.error(error);
        });
    };
    useEffect(() => {
        fetchSavedSignatures();
    }, []);

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(documentDetailToSignThunk(payload))
            .then(() => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: client_id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    const handleSelectedSignature = (url) => {
        const copyData = cloneDeep(newData);

        copyData?.fields?.forEach((field) => {
            if (field?.field_type === 'signature') {
                field.field_value = url;
                field.inserted = true;
            }
        });

        setNewData(copyData);
        setSelectedSignature(url);
    };

    const setDefaultSign = async () => {
        if (signatures?.length > 0) {
            const base64Content = await convertUrlToBase64(signatures[0]?.signature);
            if (base64Content) {
                setSelectedSignature(base64Content);
            }
        }
    };
    useEffect(() => {
        if (isPDFLoaded) {
            setDefaultSign();
        }
    }, [signatures, isPDFLoaded]);

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/file/manager/authorized/folder/${client_id}`);
        }
    };
    const handlePages = (pages) => {
        setNumPages(pages);
    };

    const handleFinish = () => {
        navigate(`/file/manager/authorized/folder/${client_id}`);
    };
    return (
        <SidebarLayout>
            <div className="app-dashboard ">
                {isLoading && <Loader />}
                {showModal && submitedResponse ? <Modal res={submitedResponse} atFinish={handleFinish} /> : ''}
                <div>
                    <Jumbotron
                        title="Authorised Folder"
                        path={[
                            { title: 'Clients', link: 'clients' },
                            {
                                title:
                                    user && clientTypes[user?.client_type] !== 'All'
                                        ? clientTypes[user?.client_type]
                                        : 'All Clients',
                                link: `app/users?type=${
                                    user
                                        ? clientTypes[user?.client_type]?.[0]?.toLowerCase() +
                                          clientTypes[user?.client_type]?.slice(1)
                                        : 'all'
                                }&reload=true`
                            },
                            {
                                title: `${user ? getProfileName(user) : 'unknown'}`,
                                link: 'app/user/profile/' + user?.business_client_id
                            },
                            {
                                title: 'Document Hub',
                                link: 'file/manager/' + user?.business_client_id
                            },
                            { title: 'Authorised Folder', link: '' }
                        ]}
                        backArrowProp={backArrowProp}
                    />

                    <div className="mx-5">
                        {' '}
                        {!isLoading && data && (
                            <SigningProvider
                                email={userInfo?.email}
                                fullName={`${userInfo.first_name} ${userInfo?.last_name ?? ''}`}
                                signature={selectedSignature ?? ''}
                            >
                                <div className="mx-auto w-full max-w-screen-xl">
                                    <h1
                                        className="mt-4 truncate text-2xl font-semibold md:text-3xl"
                                        title={data?.document_title}
                                    >
                                        {data?.document_title}
                                    </h1>
                                    <div className="grid grid-cols-12 gap-5">
                                        <div className={` border-red-600 ${isExpand ? 'col-span-12' : 'col-span-7 '} `}>
                                            <div
                                                style={{ left: isExpand ? '55%' : '34%' }}
                                                className="sign-pdf-actions rounded-xl shadow "
                                            >
                                                <div className="flex gap-3  items-center">
                                                    <button onClick={handleZoomIn}>
                                                        <WhitePlus />
                                                    </button>
                                                    <div className="bg-white text-black px-2 p-1 rounded-md">
                                                        {' '}
                                                        {zoomPercentage}%
                                                    </div>
                                                    <button onClick={handleZoomOut}>
                                                        <WhiteMinus />
                                                    </button>{' '}
                                                    |
                                                    <button
                                                        onClick={() => handleExpand()}
                                                        className="flex gap-2 items-center"
                                                    >
                                                        {' '}
                                                        <FitIcon />
                                                        {isExpand ? 'Fit' : 'Expand'}
                                                    </button>
                                                    |
                                                    <button
                                                        onClick={() => handleNextElement()}
                                                        className="flex gap-2 items-center"
                                                    >
                                                        {' '}
                                                        <ChevronUpDownIcon className="text-white w-4 h-4" />
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-8 grid grid-cols-12 gap-y-8 lg:gap-x-8 lg:gap-y-0">
                                        <Card
                                            className={`rounded-xl before:rounded-xl ${
                                                isExpand ? 'col-span-12' : 'col-span-7'
                                            }  pdf-view `}
                                            gradient
                                        >
                                            <CardContent className="p-2">
                                                <PDFViewer
                                                    left={isExpand ? '55%' : '35%'}
                                                    key={data.customer_document_id}
                                                    documentData={data.initial_doc}
                                                    testTitle="test-title"
                                                    pageNumber={pageNumber}
                                                    scale={scale}
                                                    handlePages={handlePages}
                                                    numPages={numPages}
                                                    isExpand={isExpand}
                                                    handleExpand={handleExpand}
                                                    onDocumentLoad={() => {
                                                        setTimeout(() => {
                                                            setIsPDFLoaded(true);
                                                        }, 2000);
                                                    }}
                                                />
                                            </CardContent>
                                        </Card>

                                        <div className={` ${isExpand ? 'hidden' : 'col-span-5'} `}>
                                            <SigningForm
                                                data={data}
                                                handleSelectedSignature={handleSelectedSignature}
                                                toggleModal={toggleModal}
                                            />
                                        </div>
                                    </div>

                                    <BoxDisplay
                                        zoomPercentage={zoomPercentage}
                                        isExpand={isExpand}
                                        userInfo={userInfo}
                                        newData={newData}
                                    />
                                </div>
                            </SigningProvider>
                        )}
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};
export default DocumentsPage;
