import Input from 'components/atoms/Input';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { DragFile } from 'components/atoms/inputs/DragFile';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import cloneDeep from 'lodash/cloneDeep';
import { createNoteThunk } from 'store/workspace/workspaceNotes';
import { BUSINESS_ID, ERROR_TYPE_SUCCESS, USER_TYPE, all_files_types } from 'utills/globalVars';
import { setSideLoader } from 'store/global/globalReducer';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { FileIconSvg } from 'utills/svgs/FileIconSvg';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

export const AddNewNote = ({ open, setOpen, toggleNotesUpdate, data, setData }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [forceUpdate, setForceUpdate] = useState(false);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { sideLoader } = useSelector((state) => state.global);

    const handleSubmit = async () => {
        const formData = new FormData();

        formData.append('business_id', business_id);
        formData.append('title', title.trim());
        formData.append('description', description.trim());
        formData.append('uploaded_by', USER_TYPE);

        const sanitizedFilesPromises = selectedFiles.map((file) => createNewPDFWithoutMetadata(file));
        const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
        sanitizedFiles.forEach((sanitizedFile) => {
            formData.append('note_files', sanitizedFile);
        });

        dispatch(setSideLoader(true));

        dispatch(createNoteThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setOpen(false);

                    if (data) {
                        const newNotes = cloneDeep(data);
                        newNotes.unshift(response.payload);
                        setData(newNotes);
                    } else {
                        toggleNotesUpdate();
                    }
                    toastHandler('Note added successfully', ERROR_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles([...selectedFiles, ...files]);
    };
    return (
        <Slideover title="Add Note" open={open} setOpen={setOpen}>
            <div className="mt-5 ">
                <label className="buttons-font font-poppins">Title</label>
                <div className="default-forms">
                    {' '}
                    <Input
                        useWithoutForm
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter Title"
                        sx="mt-2"
                    />
                </div>
            </div>
            <div className="my-3 buttons-font font-poppins">Description</div>
            <div>
                <DraftEditorNew
                    initialValue={description}
                    onChange={(value) => setDescription(value)}
                    forceUpdate={forceUpdate}
                    setForceUpdate={setForceUpdate}
                    required={true}
                    extraConfigs={{
                        height: 300
                    }}
                    placeholder="Enter Description"
                />
            </div>

            <div className="mt-3">
                <div className="mb-2 buttons-font font-poppins">Add Attachment</div>
                <DragFile
                    atChange={(file) => {
                        handleSelectedFiles(file);
                    }}
                    iconSet={{ icon: FileIconSvg }}
                    accept={all_files_types}
                    selectedFiles={selectedFiles}
                    hideSelectedFiles
                    isMultiple={true}
                    dragMessage="Drag files here to upload file, or browse files"
                    dropMessage="Drop the selected files in this box... "
                    onlyPdf={false}
                    message="Invalid file format"
                />
            </div>
            <SelectedFiles files={selectedFiles} updateSelectedFiles={(files) => setSelectedFiles(files || [])} />

            <div className="flex justify-center items-center mt-10">
                {' '}
                <Button
                    disabled={sideLoader ? true : false}
                    isloading={sideLoader ? true : false}
                    title="Add"
                    classes="ann-btn px-20 rounded-lg "
                    atClick={() => handleSubmit()}
                    iconSet={{ loader: SmallLoaderWhite }}
                />
            </div>
        </Slideover>
    );
};
