import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: fetchAllConnectedTenants, request: fetchAllConnectedTenantsThunk } = generateThunkAndSlice(
    'fetchAllConnectedTenants',
    endpoint.fetchAllConnectedTenants,
    methods.GET
);

const { request: xeroSaveAccessTokenThunk } = generateThunkAndSlice(
    'xeroSaveAccessToken',
    endpoint.xeroSaveAccessToken,
    methods.POST
);

const { request: xeroGetAccessTokenThunk } = generateThunkAndSlice(
    'xeroGetAccessToken',
    endpoint.xeroGetAccessToken,
    methods.POST
);

const { request: outlookGetAccessTokenThunk } = generateThunkAndSlice(
    'outlookGetAccessTokenThunk',
    endpoint.outlookGetAccessToken,
    methods.POST
);

const { request: xeroDisconnectThunk } = generateThunkAndSlice(
    'xeroDisconnectThunk',
    endpoint.xeroDisconnectThunk,
    methods.POST
);

const { request: outlookDisconnectThunk } = generateThunkAndSlice(
    'outlookDisconnect',
    endpoint.outlookDisconnect,
    methods.POST
);

const { request: xeroSynkClientThunk } = generateThunkAndSlice('xeroSynkClient', endpoint.xeroSynkClient, methods.POST);
const { request: outlookSynkClientThunk } = generateThunkAndSlice(
    'outlookSynkClient',
    endpoint.outlookSynkClient,
    methods.POST
);

const { request: refreshOutlookSynkThunk } = generateThunkAndSlice(
    'refreshOutlookSynk',
    endpoint.refreshOutlookSynk,
    methods.POST
);
const { request: getAppsStatusThunk } = generateThunkAndSlice('getAppsStatus', endpoint.getAppsStatus, methods.GET);
const { slice: getAppsCalendars, request: getAppsCalendarsThunk } = generateThunkAndSlice(
    'getAppsCalendars',
    endpoint.getAppsCalendars,
    methods.POST
);
const { request: updateAppsCalendarsThunk } = generateThunkAndSlice(
    'updateAppsCalendars',
    endpoint.updateAppsCalendars,
    methods.POST
);
const { request: getClientSyncStatusThunk } = generateThunkAndSlice(
    'getClientSyncStatus',
    endpoint.getClientSyncStatus,
    methods.POST
);
const { request: getXeroClientsThunk } = generateThunkAndSlice('getXeroClients', endpoint.getXeroClients, methods.POST);

export {
    fetchAllConnectedTenants,
    fetchAllConnectedTenantsThunk,
    xeroSaveAccessTokenThunk,
    xeroDisconnectThunk,
    outlookDisconnectThunk,
    getAppsStatusThunk,
    xeroGetAccessTokenThunk,
    outlookGetAccessTokenThunk,
    xeroSynkClientThunk,
    outlookSynkClientThunk,
    getClientSyncStatusThunk,
    getAppsCalendars,
    getAppsCalendarsThunk,
    updateAppsCalendarsThunk,
    refreshOutlookSynkThunk,
    getXeroClientsThunk
};
