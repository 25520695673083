/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SummaryDrawer } from 'components/organisms/SummaryDrawer';
import React, { useEffect, useState } from 'react';
import { FillArrow } from 'utills/svgs/FillArrow';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/atoms/buttons/Button';
import { formateDate } from 'utills/moment';
import {
    handleDeductionType,
    handleOwners,
    handleSummaryDates,
    handleSummaryType,
    handleSunmmaryId,
    setLoader,
    setSideLoader
} from 'store/global/globalReducer';
import { getRentalBusinessThunk, getSummaryLogsThunk } from 'store/deductions';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import { BUSINESS_ID } from 'utills/globalVars';
import { DefaultLogs } from 'components/molecules/DefaultLogs';
import { Owners } from './Owners';
import { ProfitAndLoss } from './ProfitAndLoss';
import { BoxSkeleton } from 'components/molecules/skeletons/BoxSkeleton';
import { SummaryListing } from './SummaryListing';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { UseDownloadLogsPdf } from 'Hooks/Files/UseDownloadLogsPdf';
import { endpoint } from 'apiEndpoints';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import moment from 'moment';
import { RentalSchdule } from './RentalSchdule';
import { SidebarLayout } from 'layouts/SidebarLayout';
export const RentalProperty = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [showSummary, setShowSummary] = useState(false);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [detailIndex, setDetailIndex] = useState(null);
    const [openIndexes, setOpenIndexes] = useState([]);
    const { isLoading } = useSelector((state) => state.global);
    const { data: customer } = useSelector((state) => state.viewBusinessCustomerInfo);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { handleDownloadLogsPdf } = UseDownloadLogsPdf();

    const { data: summaryData } = useSelector((state) => state.getSummaryForBusiness);
    const { data: rentalBusiness } = useSelector((state) => state.getRentalBusiness);
    const [theSummary, setTheSummary] = useState([]);
    const [theBusinesses, setTheBusinesses] = useState([]);
    const [showLogs, setShowLogs] = useState(false);

    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [count, setCount] = useState(null);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [filteredLogs, setfilteredLogs] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [filterCount, setFilterCount] = useState(1);

    const [showProfitAndLost, setShowProfitAndLoss] = useState(false);
    const [showRentalSchdule, setShowRentalSchdule] = useState(false);
    const [tempData, setTempData] = useState(null);

    const [showOwners, setShowOwners] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get('summary_id');
    const rentalPropertyId = queryParams.get('record_id');

    useEffect(() => {
        if (queryId) {
            const findSummary = theBusinesses?.find((item) => item.rental_property_id === rentalPropertyId);
            const findIndex = theBusinesses.findIndex((obj) => obj.rental_property_id === rentalPropertyId);
            if (findIndex !== -1) {
                toggleDetails(findIndex, rentalPropertyId);
                toggleSummary(queryId, '', '', 'all', 'rental');
                setTempData(findSummary || null);
            }
        }
    }, [queryId, theBusinesses]);

    const toggleOwners = () => {
        setShowOwners(!showOwners);
    };

    const toggleLogs = () => {
        setShowLogs(!showLogs);
    };

    const toggleProfitAndLoss = (id) => {
        if (id) {
            dispatch(handleSunmmaryId(id));
        }
        setShowProfitAndLoss(!showProfitAndLost);
    };

    const toggleRentalSchdule = (id) => {
        if (id) {
            dispatch(handleSunmmaryId(id));
        }
        setShowRentalSchdule(!showRentalSchdule);
    };

    const toggleDetails = (index, rental_property_id) => {
        setDetailIndex(index);
        const newIndexes = [...openIndexes];

        const alreadyExists = newIndexes?.find((item) => item?.key === index);
        if (alreadyExists) {
            const newIndex = newIndexes?.indexOf(alreadyExists);
            newIndexes?.splice(newIndex, 1);
        } else {
            newIndexes.push({ key: index });
        }
        setOpenIndexes(newIndexes);
        setIsDetailOpen(!isDetailOpen);
    };

    const toggleSummary = (id, from, to, summary_type, deduction_type) => {
        if (id) {
            dispatch(handleSunmmaryId(id));
        }
        if (deduction_type) {
            dispatch(handleDeductionType(deduction_type));
        }
        if ((from && to, summary_type)) {
            dispatch(
                handleSummaryDates({
                    from,
                    to
                })
            );
            dispatch(handleSummaryType(summary_type));
        }
        setShowSummary(!showSummary);
    };
    const closeSummary = () => {
        setShowSummary(false);
        setTempData(null);
    };

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/customer/deductions/${id}`);
        }
    };

    const getRentalPropertyBusiness = () => {
        const payload = {
            business_client_id: id
        };
        dispatch(setLoader(true));
        dispatch(getRentalBusinessThunk(payload))
            .then((response) => {
                setTheBusinesses(response.payload);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        getRentalPropertyBusiness();
    }, []);

    const handleSearch = (term) => {
        setSearchTerm(term);
        const filteredInfo = rentalBusiness?.filter((item) => item?.name?.toLowerCase().includes(term?.toLowerCase()));
        setTheBusinesses(filteredInfo);
    };
    useEffect(() => {
        if (summaryData) {
            setTheSummary(summaryData);
        }
    }, [summaryData]);

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    const fetchLogs = () => {
        const payload = {
            business_client_id: id,
            deduction_type: 'rental_property',
            page: term || dateFrom || dateTo ? filterPage : page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            business_id,
            filter: {
                text: term,
                from_date: dateFrom && moment(new Date(dateFrom)).format('DD-MM-YYYY'),
                end_date: dateTo && moment(new Date(dateTo)).format('DD-MM-YYYY')
            }
        };

        dispatch(setSideLoader(true));
        dispatch(getSummaryLogsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (dateFrom || dateTo || term) {
                        setFilterCount(response?.payload?.count);
                        const newLogs = [...filteredLogs, ...response.payload.logs];
                        setfilteredLogs(newLogs);
                    } else {
                        setCount(response?.payload?.count);
                        if (page > 1) {
                            const newLogs = [...logs, ...response.payload.logs];
                            setLogs(newLogs);
                        } else {
                            const newLogs = response.payload.logs;
                            setLogs(newLogs);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleMore = () => {
        const totalPages = Math.ceil(count / Number(PAGINATION_PAGE_SIZE));
        const togleFilterPages = Math.ceil(filterCount / Number(PAGINATION_PAGE_SIZE));

        if (dateFrom || dateTo || term) {
            if (filterPage < togleFilterPages) {
                setFilterPage(filterPage + 1);
            }
            return;
        }
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const managerActions = [
        {
            title: 'View Logs',
            classes: 'flex gap-2 items-center px-8 rounded-lg ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                toggleLogs();
            }
        }
    ];

    const handleOwnersDisplay = (data) => {
        dispatch(handleOwners(data));
        toggleOwners();
    };

    const handleDownloadActivity = () => {
        const payload = {
            business_client_id: id,
            business_id: business_id,
            deduction_type: 'rental_property'
        };
        handleDownloadLogsPdf(payload, endpoint.downaloadDeductionsLogsPdf);
    };

    return (
        <SidebarLayout>
            <div className="app-dashboard relative  ">
                {showOwners && <Owners open={showOwners} setOpen={setShowOwners} toggleOwners={toggleOwners} />}
                {showProfitAndLost && (
                    <ProfitAndLoss
                        open={showProfitAndLost}
                        setOpen={setShowProfitAndLoss}
                        toggleProfitAndLoss={toggleProfitAndLoss}
                    />
                )}
                {showRentalSchdule && (
                    <RentalSchdule
                        open={showRentalSchdule}
                        setOpen={setShowRentalSchdule}
                        toggleRentalSchdule={toggleRentalSchdule}
                    />
                )}

                {showLogs && (
                    <DefaultLogs
                        toggleLogs={toggleLogs}
                        fetchLogs={fetchLogs}
                        logs={dateFrom || dateTo || term ? filteredLogs : logs}
                        count={count}
                        pageSize={pageSize}
                        page={page}
                        handleMore={handleMore}
                        term={term}
                        setTerm={setTerm}
                        dateFrom={dateFrom}
                        setDateFrom={setDateFrom}
                        dateTo={dateTo}
                        setDateTo={setDateTo}
                        downloadActivity={handleDownloadActivity}
                        setFilterPage={setFilterPage}
                        filterPage={filterPage}
                        filterCount={filterCount}
                        setfilteredLogs={setfilteredLogs}
                        open={showLogs}
                        setOpen={setShowLogs}
                    />
                )}
                {showSummary && (
                    <SummaryDrawer
                        title={tempData?.name + ' Rental Property'}
                        onToggle={closeSummary}
                        setOpen={setShowSummary}
                        open={showSummary}
                        cols={6}
                        gap={5}
                        showSwitch={false}
                        summaryType="all"
                    />
                )}

                <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    {' '}
                    <div>
                        <Jumbotron
                            title="Rental Property"
                            path={[
                                { title: 'Clients', link: 'clients' },
                                {
                                    title: `${
                                        customer?.client_type === 'prospective_client'
                                            ? 'Prospective Client'
                                            : customer?.client_type === 'active_client'
                                            ? 'Active Client'
                                            : customer?.client_type === 'inactive_client'
                                            ? 'Inactive Client'
                                            : 'Client Profile'
                                    }`,
                                    link: `app/users?type=${
                                        customer?.client_type === 'prospective_client'
                                            ? 'prospective'
                                            : customer?.client_type === 'active_client'
                                            ? 'active'
                                            : customer?.client_type === 'inactive_client'
                                            ? 'invites'
                                            : 'all'
                                    }&reload=false`
                                },
                                {
                                    title: ` ${customer?.first_name} ${customer?.last_name}`,
                                    link: `app/user/profile/${id}`
                                },
                                { title: `Deduction`, link: `customer/deductions/${id}` },
                                { title: `Rental Property`, link: '' }
                            ]}
                            backArrowProp={backArrowProp}
                            actionsRequired={true}
                            actions={managerActions}
                        />

                        <div className="mt-2 md:mx-10 mx-5">
                            <SearchInput
                                width="350px"
                                atChange={(e) => {
                                    handleSearch(e.target.value);
                                }}
                                placeholder="Search"
                            />
                            {isLoading ? (
                                <BoxSkeleton />
                            ) : (
                                <div className="mt-5  py-5">
                                    {theBusinesses?.length > 0 && !isLoading
                                        ? theBusinesses?.map((exp, index) => (
                                              <div
                                                  key={'business' + index}
                                                  className={`mt-2 ${
                                                      index > 0 && 'mt-3'
                                                  } rounded-lg bg-white py-3 border`}
                                              >
                                                  <div className="  grid grid-cols-6 gap-5 px-5">
                                                      <div className="default-table-heading">Property Name</div>
                                                      <div className="default-table-heading">Property Address </div>
                                                      <div className="default-table-heading flex  justify-center">
                                                          Date
                                                      </div>
                                                      <div className="default-table-heading flex  justify-center">
                                                          No. Of weeks{' '}
                                                      </div>

                                                      <div className="default-table-heading flex justify-center">
                                                          Private Use %
                                                      </div>
                                                  </div>
                                                  <hr className="mt-2" />

                                                  <div className="grid grid-cols-6 gap-5 summary-body px-5 mt-2">
                                                      <div className="flex items-center break-all">{exp?.name}</div>
                                                      <div className="flex items-center break-all">{exp?.address}</div>
                                                      <div className="flex items-center break-all justify-center">
                                                          {formateDate(exp?.created_at)}
                                                      </div>
                                                      <div className="flex items-center break-all justify-center">
                                                          {exp?.rented_duration_in_weeks}
                                                      </div>
                                                      <div className="flex items-center break-all justify-center">
                                                          {exp?.private_use_percentage}%
                                                      </div>
                                                      <div className="flex justify-end items-center gap-5">
                                                          <Button
                                                              title="View Owners"
                                                              classes="primary-bg  text-white font-fira text-md font-font4 rounded-lg px-3 py-1"
                                                              atClick={() => handleOwnersDisplay(exp?.owners)}
                                                          />
                                                          <span
                                                              className={`cursor-pointer transition-transform transform ${
                                                                  openIndexes?.find((item) => item?.key === index)
                                                                      ? 'rotate-180'
                                                                      : 'rotate-0'
                                                              }`}
                                                              onClick={() =>
                                                                  toggleDetails(index, exp.rental_property_id)
                                                              }
                                                          >
                                                              <span className="inline-block">
                                                                  {' '}
                                                                  <FillArrow />
                                                              </span>
                                                          </span>
                                                      </div>
                                                  </div>
                                                  {openIndexes?.find((item) => item?.key === index) && (
                                                      <SummaryListing
                                                          record_id={`${exp.rental_property_id}`}
                                                          business_client_id={id}
                                                          deduction_type="rental_property"
                                                          toggleSummary={(
                                                              summary_id,
                                                              from_date,
                                                              to_date,
                                                              summary_type,
                                                              deduction_type
                                                          ) => {
                                                              toggleSummary(
                                                                  summary_id,
                                                                  from_date,
                                                                  to_date,
                                                                  summary_type,
                                                                  deduction_type
                                                              );
                                                              setTempData(exp);
                                                          }}
                                                          toggleProfitAndLoss={toggleProfitAndLoss}
                                                          toggleRentalSchdule={toggleRentalSchdule}
                                                      />
                                                  )}
                                              </div>
                                          ))
                                        : ''}
                                </div>
                            )}
                        </div>
                    </div>{' '}
                </RoleBasedGuard>
            </div>
        </SidebarLayout>
    );
};
