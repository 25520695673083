import React from 'react';
import './css/googleAuthenticator.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { login, verifyGoogleAuthThunk } from 'store/auth/slices';
import { Loader } from 'components/common/Loader';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN, TOAST_TYPE_ERROR } from 'utills/globalVars';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { toastHandler } from 'responseHanlder';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import { Button } from 'components/atoms/buttons/Button';
import useRedirectToDashboardHook from 'Hooks/useRedirectToDashboardHook';

const GoogleAuthenticatorPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { redirectToDashboard } = useRedirectToDashboardHook();
    const { data } = useSelector((state) => state.login);
    const isLoading = useSelector((state) => state.global.isLoading);
    const [trustDevice, setTrustDevice] = useState(false);
    const [otpVal, setOtpVal] = useState('');
    const [timeRemaining, setTimeRemaining] = useState(67);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeRemaining]);

    const verifyGoogleData = {
        user_id: data?.user_id,
        user_type: 'employee',
        code: otpVal,
        trust_this_device: trustDevice
    };

    const handleGoogleAuthenticate = () => {
        const newOtp = otpVal;

        if (newOtp.length < 6) {
            toastHandler('Please enter full otp', TOAST_TYPE_ERROR);
            return;
        }

        dispatch(setLoader(true));
        dispatch(verifyGoogleAuthThunk(verifyGoogleData))
            .then((response) => {
                if (response.payload) {
                    if (data?.email_2fa) {
                        props.handleCondition(8);
                    } else {
                        dispatch(login.actions.handleUpdate(response.payload));

                        localStorage.removeItem('temp_token');
                        localStorage.removeItem('pre_auth_token');
                        const payload = response.payload;
                        if (payload?.business?.onboarded) {
                            localStorage.setItem('onboarding', true);
                        } else {
                            localStorage.setItem('onboarding', false);
                        }

                        if (payload?.email_2fa) {
                            localStorage.setItem('email_2fa', true);
                        } else {
                            localStorage.setItem('email_2fa', false);
                        }

                        localStorage.setItem('verified', true);

                        localStorage.setItem(ACCESS_TOKEN, payload.access_token);
                        localStorage.setItem(REFRESH_TOKEN, payload.refresh_token);
                        localStorage.setItem(BUSINESS_ID, payload?.business?.business_id);
                        sessionStorage.setItem('loginStepper', 1);

                        if (!payload?.business?.onboarded && !payload.is_employee) {
                            navigate('/onboarding');
                            return;
                        }
                        // navigate('/business/dashboard');
                        redirectToDashboard();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            {isLoading && <Loader />}
            <AuthCardLayout>
                <div className=" ">
                    <div className="auth-inner ">
                        <div className="top-wrapper">
                            <div className="text-center  mt-4 card-layout-title-text">Google Authenticator</div>

                            <div className="flex gap-2 text-sixth text-sm justify-center items-center mt-1">
                                <div className="mt-2">Enter the 6-digit code provided by your Authentication app</div>
                            </div>
                        </div>

                        <div className="mt-16 flex justify-center items-center">
                            <OtpInputs value={otpVal} onChange={handleOtpChange} />
                        </div>
                        <div>
                            <div className="mt-5 mx-24">
                                {data?.google_authenticator_2fa && (
                                    <div>
                                        <div className="flex gap-2 items-center">
                                            <div>
                                                <input
                                                    checked={trustDevice ? true : false}
                                                    type="checkbox"
                                                    id="trustCheckbox"
                                                    onChange={(e) => setTrustDevice(e.target.checked)}
                                                    className="text-secondarybg rounded-sm"
                                                />
                                            </div>
                                            <label htmlFor="trustCheckbox" className="buttons-font font-poppins pt-1">
                                                Trust this device (12 hours)
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mx-20 mt-10">
                            <div className="flex justify-between items-center mt-72 ">
                                <Button
                                    title=" Back"
                                    atClick={() => props.handleCondition(1)}
                                    classes="px-12 py-2 btn-1 border-button rounded-md"
                                />

                                <button onClick={handleGoogleAuthenticate} className="px-12 btn-2 py-2 rounded-md">
                                    verify
                                </button>
                            </div>
                            <div className="mt-4 text-sm text-center verify-section">
                                <p
                                    onClick={() => {
                                        sessionStorage.setItem('loginStepper', 1);

                                        navigate('/alternate/method');
                                    }}
                                    className="cursor-pointer"
                                >
                                    {' '}
                                    Try Another Way To Authenticate?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};
export default GoogleAuthenticatorPage;
