import React from 'react';

export const Times = () => {
    return (
        <div>
            <svg width="10" height="10" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L7.91421 6.5L12.7071 1.7071C13.0976 1.31658 13.0976 0.683414 12.7071 0.292889C12.3166 -0.0976349 11.6834 -0.0976349 11.2929 0.292889L6.49999 5.08578L1.7071 0.292889C1.31658 -0.0976354 0.68341 -0.0976354 0.292886 0.292889C-0.0976391 0.683413 -0.0976391 1.31658 0.292886 1.7071L5.08578 6.5L0.292886 11.2929C-0.0976387 11.6834 -0.0976387 12.3166 0.292886 12.7071C0.68341 13.0976 1.31657 13.0976 1.7071 12.7071L6.49999 7.91421L11.2929 12.7071Z"
                    fill="url(#paint0_linear_2369_9690)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_2369_9690"
                        x1="7.13784"
                        y1="0.161447"
                        x2="7.12811"
                        y2="13"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
