import React from 'react';
import { useSelector } from 'react-redux';
import './css/businessSteps.css';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { useState } from 'react';
import useRoleGuard from 'Hooks/useRoleGuard';
const Bio = ({ toggleBio }) => {
    const { data } = useSelector((state) => state.getBusinessprofile);

    const [editModal, setEditModal] = useState(false);

    const { hasPermissions } = useRoleGuard();

    const handleToggleBio = () => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            toggleBio();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    function toggleModal(_index) {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            setEditModal(!editModal);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    }
    return (
        <>
            <div className="md:px-10 px-5  mt-3 ">
                <div className="md:grid grid-cols-2 b-h-container">
                    <div className="p-5 service-scroll ">
                        <div className="mt-2">
                            <div className="service-div1 !shadow-lg !rounded-xl cursor-pointer px-4 py-5 mt-5 relative">
                                <div className="absolute right-4 cursor-pointer" onClick={() => toggleModal()}>
                                    <svg
                                        width="15"
                                        height="13"
                                        viewBox="0 0 4 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="2" cy="2" r="2" fill="url(#paint0_linear_1360_40)" />
                                        <circle cx="2" cy="8" r="2" fill="url(#paint1_linear_1360_40)" />
                                        <circle cx="2" cy="14" r="2" fill="url(#paint2_linear_1360_40)" />
                                        <defs>
                                            <linearGradient
                                                id="paint0_linear_1360_40"
                                                x1="2.19626"
                                                y1="0.049676"
                                                x2="2.19327"
                                                y2="4.00001"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stopColor="#101828" />
                                                <stop offset="0.998509" stopColor="#0D1B37" />
                                                <stop offset="1" stopColor="#0A1E46" />
                                            </linearGradient>
                                            <linearGradient
                                                id="paint1_linear_1360_40"
                                                x1="2.19626"
                                                y1="6.04968"
                                                x2="2.19327"
                                                y2="10"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stopColor="#101828" />
                                                <stop offset="0.998509" stopColor="#0D1B37" />
                                                <stop offset="1" stopColor="#0A1E46" />
                                            </linearGradient>
                                            <linearGradient
                                                id="paint2_linear_1360_40"
                                                x1="2.19626"
                                                y1="12.0497"
                                                x2="2.19327"
                                                y2="16"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop stopColor="#101828" />
                                                <stop offset="0.998509" stopColor="#0D1B37" />
                                                <stop offset="1" stopColor="#0A1E46" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>

                                {editModal ? (
                                    <div className="absolute  service-modal shadow-lg right-5 top-10">
                                        <div
                                            onClick={() => {
                                                handleToggleBio();
                                                toggleModal();
                                            }}
                                            className="flex gap-2 px-6 py-2 modal-div small-text font-poppins items-center cursor-pointer"
                                        >
                                            <div className="service-icon">
                                                <svg
                                                    width="15"
                                                    height="15"
                                                    viewBox="0 0 18 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1.875 20C1.35937 20 0.918125 19.8043 0.55125 19.413C0.18375 19.021 0 18.55 0 18V2C0 1.45 0.18375 0.979 0.55125 0.587C0.918125 0.195667 1.35937 0 1.875 0H9.375L15 6V10H13.125V7H8.4375V2H1.875V18H7.5V20H1.875ZM13.4062 12.525L14.4141 13.6L10.7812 17.45V18.5H11.7656L15.3984 14.65L16.3828 15.7L12.3516 20H9.375V16.825L13.4062 12.525ZM16.3828 15.7L13.4062 12.525L14.7656 11.075C14.9375 10.8917 15.1562 10.8 15.4219 10.8C15.6875 10.8 15.9062 10.8917 16.0781 11.075L17.7422 12.85C17.9141 13.0333 18 13.2667 18 13.55C18 13.8333 17.9141 14.0667 17.7422 14.25L16.3828 15.7Z" />
                                                </svg>
                                            </div>
                                            <div>Update Details</div>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="flex justify-between">
                                    <div className="buttons-font font-poppins    break-words ">Business Bio</div>
                                </div>

                                {data && (
                                    <div className="text-secondarybg buttons-font-lighter font-poppins mt-2 break-words">
                                        {data?.description}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="!px-4 !py-5 s-i-v"></div>
                </div>
            </div>
        </>
    );
};

export default Bio;
