import { GoogleAuthSuccess } from 'components/login-steps/GoogleAuthSuccess';
import { GoogleBackupCodes } from 'components/login-steps/GoogleBackupCodes';
import { GoogleInstructions } from 'components/login-steps/GoogleInstructions';
import { GoogleQrCode } from 'components/login-steps/GoogleQrCode';
import { TwoFactorAuthentication } from 'components/login-steps/TwoFactorAuthentication';
import { TwoFactorEmailSuccess } from 'components/login-steps/TwoFactorEmailSuccess';
import { VerifyTwoFaEmail } from 'components/login-steps/VerifyTwoFaEmail';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ResetAuth = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const myData = location.state?.isNavigating;
    const resetStepper = JSON.parse(sessionStorage.getItem('resetStepper'));

    const [condition, setCondition] = useState(resetStepper ?? 1);

    const handleCondition = (condition) => {
        sessionStorage.setItem('resetStepper', condition);
        setCondition(condition);
    };
    useEffect(() => {
        if (!myData) {
            navigate('/');
        }
    }, []);
    function MyComponent() {
        return (
            <div>
                {condition === 1 && <TwoFactorAuthentication handleCondition={handleCondition} />}
                {condition === 2 && <GoogleInstructions handleCondition={handleCondition} />}
                {condition === 3 && <GoogleQrCode handleCondition={handleCondition} />}
                {condition === 4 && <GoogleAuthSuccess handleCondition={handleCondition} />}
                {condition === 5 && <GoogleBackupCodes handleCondition={handleCondition} />}
                {condition === 6 && <VerifyTwoFaEmail handleCondition={handleCondition} />}
                {condition === 7 && <TwoFactorEmailSuccess handleCondition={handleCondition} />}
            </div>
        );
    }
    return <div>{MyComponent()}</div>;
};
