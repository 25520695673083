import generateThunkAndSlice from 'store/thunk/thunk';
import { endpoint, methods } from 'apiEndpoints';

const { slice: getAllNews, request: getAllNewsThunk } = generateThunkAndSlice(
    'getAllNews',
    endpoint.getAllNews,
    methods.POST
);

const { request: archiveNewsThunk } = generateThunkAndSlice('archiveNews', endpoint.archiveNews, methods.POST);
const { slice: getSingleNews, request: getSingleNewsThunk } = generateThunkAndSlice(
    'getSignleNews',
    endpoint.getSingleNews,
    methods.POST
);
const { request: updateNewsThunk } = generateThunkAndSlice('updateNews', endpoint.updateNews, methods.POST);

const { request: addNewsThunk } = generateThunkAndSlice('addNews', endpoint.addNews, methods.POST);

const { request: uploadWorkSpaceImgThunk } = generateThunkAndSlice(
    'uploadWorkSpaceImg',
    endpoint.uploadWorkSpaceImg,
    methods.POST
);
const { request: uploadWorkSpacepdfThunk } = generateThunkAndSlice(
    'uploadWorkSpacePdf',
    endpoint.uploadWorkSpacepdf,
    methods.POST
);

const { request: deleteAnnFileThunk } = generateThunkAndSlice('deleteAnnFile', endpoint.deleteAnnFile, methods.POST);
export {
    getAllNewsThunk,
    getAllNews,
    archiveNewsThunk,
    getSingleNews,
    getSingleNewsThunk,
    updateNewsThunk,
    addNewsThunk,
    uploadWorkSpaceImgThunk,
    uploadWorkSpacepdfThunk,
    deleteAnnFileThunk
};
