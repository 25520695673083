/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { UploadFile } from 'components/appusers-dash/app-user-profile/file-manager-pages/UploadFile';
import { Jumbotron } from 'components/organisms/Jumbotron';

import React, { useEffect, useState } from 'react';

import { SmallPlusIcon } from 'utills/svgs/SmallPlusIcon';
import { StorageCards } from './StorageCards';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import { fileManagerInfoThunk, fileManagerRecentActivityThunk } from 'store/fileManager';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'components/common/Loader';
import { BUSINESS_ID, acceptFormate, all_files_types, clientTypes, getProfileName } from 'utills/globalVars';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import { DiaLog } from 'components/molecules/DiaLog';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import moment from 'moment';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const FileManager = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { isLoading } = useSelector((state) => state.global);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [activityLoader, setActivityLoader] = useState(false);
    const [logs, setLogs] = useState([]);

    const [filteredLogs, setfilteredLogs] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [filterCount, setFilterCount] = useState(1);

    const [page, setPage] = useState(1);

    const [count, setCount] = useState(1);

    const [open, setOpen] = useState(false);

    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);

    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const { data: customer } = useSelector((state) => state?.viewBusinessCustomerInfo);

    const toggleUploadFile = () => {
        setShowUploadFile(!showUploadFile);
    };

    const managerActions = [
        {
            title: 'Add',
            classes: 'flex gap-2 items-center px-8 ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: SmallPlusIcon },
            onAction: () => {
                toggleUploadFile();
            }
        }
    ];

    const toggleDialog = () => {
        setOpen(!open);
    };
    const fetchActivity = () => {
        setActivityLoader(true);

        dispatch(setSideLoader(true));
        dispatch(
            fileManagerRecentActivityThunk({
                business_client_id: id,
                page: term || dateFrom || dateTo ? filterPage : page,
                page_size: Number(PAGINATION_PAGE_SIZE),
                business_id,
                filter: {
                    text: term,
                    from_date: dateFrom && moment(new Date(dateFrom)).format('DD-MM-YYYY'),
                    end_date: dateTo && moment(new Date(dateTo)).format('DD-MM-YYYY')
                }
            })
        )
            .then((response) => {
                if (response.payload) {
                    if (dateFrom || dateTo || term) {
                        setFilterCount(response?.payload?.count);
                        const newLogs = [...filteredLogs, ...response.payload.logs];
                        setfilteredLogs(newLogs);
                    } else {
                        setCount(response?.payload?.count);
                        if (page > 1) {
                            const newLogs = [...logs, ...response.payload.logs];
                            setLogs(newLogs);
                        } else {
                            const newLogs = response.payload.logs;
                            setLogs(newLogs);
                        }
                    }
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                setActivityLoader(false);
                dispatch(setSideLoader(false));
            });
    };

    const fetchFileManagerInfo = () => {
        dispatch(setLoader(true));
        dispatch(fileManagerInfoThunk({ business_client_id: id, business_id }))
            .then((response) => {})
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchFileManagerInfo();
        fetchCustomer();
    }, []);

    const handleMore = () => {
        const totalPages = Math.ceil(count / Number(PAGINATION_PAGE_SIZE));
        const togleFilterPages = Math.ceil(filterCount / Number(PAGINATION_PAGE_SIZE));

        if (dateFrom || dateTo || term) {
            if (filterPage < togleFilterPages) {
                setFilterPage(filterPage + 1);
            }
            return;
        }
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/app/user/profile/${id}`);
        }
    };
    //const acceptFormate = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

    return (
        <SidebarLayout>
            <div>
                <div className="app-dashboard relative  ">
                    {isLoading && <Loader />}
                    {showUploadFile && (
                        <UploadFile
                            message="Only Pdf and images are allowed"
                            toggleUploadFile={toggleUploadFile}
                            onlyPdf={false}
                            acceptFormate={all_files_types}
                        />
                    )}
                    <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                        <div>
                            <Jumbotron
                                title="Document Hub"
                                actionsRequired={true}
                                actions={managerActions}
                                backArrowProp={backArrowProp}
                                path={[
                                    { title: 'Clients', link: 'clients' },
                                    {
                                        title:
                                            customer && clientTypes[customer?.client_type] !== 'All'
                                                ? clientTypes[customer?.client_type]
                                                : 'All Clients',
                                        link: `app/users?type=${
                                            customer
                                                ? clientTypes[customer?.client_type]?.[0]?.toLowerCase() +
                                                  clientTypes[customer?.client_type]?.slice(1)
                                                : 'all'
                                        }&reload=true`
                                    },
                                    {
                                        title: `${getProfileName(customer)}`,
                                        link: `app/user/profile/${id}`
                                    },
                                    { title: 'Document Hub', link: '' }
                                ]}
                            />
                            <DiaLog
                                title="Well Done"
                                open={open}
                                text="Now lets add your first file in file manager by clicking top right button "
                                buttonTxt="Next"
                                atClick={() => {
                                    toggleDialog();
                                }}
                                atClose={() => {
                                    toggleDialog();
                                }}
                            />
                            <div>
                                <StorageCards
                                    toggleDialog={toggleDialog}
                                    logs={dateFrom || dateTo || term ? filteredLogs : logs}
                                    count={count}
                                    page={page}
                                    pageSize={pageSize}
                                    handleMore={handleMore}
                                    activityLoader={activityLoader}
                                    term={term}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    setTerm={setTerm}
                                    setDateFrom={setDateFrom}
                                    setDateTo={setDateTo}
                                    fetchLogs={fetchActivity}
                                    setFilterPage={setFilterPage}
                                    filterPage={filterPage}
                                    filterCount={filterCount}
                                    setfilteredLogs={setfilteredLogs}
                                    customer={customer}
                                />
                            </div>
                        </div>
                    </RoleBasedGuard>
                </div>
            </div>
        </SidebarLayout>
    );
};
