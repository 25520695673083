import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { cn } from 'utills/tailwindUtil';

const DisclosureBox = ({ name, children, sx, defaultOpen = false }) => {
    return (
        <Disclosure as="div" defaultOpen={defaultOpen}>
            {({ open }) => (
                <>
                    <Disclosure.Button
                        className={cn(
                            'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-md font-semibold text-gray-700',
                            sx ? sx?.name : ''
                        )}
                    >
                        {name}
                        <ChevronRightIcon
                            aria-hidden="true"
                            className={cn(
                                'ml-auto size-5 shrink-0 text-gray-400',
                                open ? 'rotate-90 text-gray-500' : '',
                                sx ? sx?.icon : ''
                            )}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel as="ul" className={('mt-1 px-2', sx ? sx?.panel : '')}>
                        {children}
                        {/* {item.children.map((subItem) => (
                    <li key={subItem.name}>
                        <Disclosure.Button
                            as="a"
                            href={subItem.href}
                            className={cn(
                                subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                'block rounded-md py-2 pr-2 pl-9 text-sm/6 text-gray-700'
                            )}
                        >
                            {subItem.name}
                        </Disclosure.Button>
                    </li>
                ))} */}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default DisclosureBox;
