import React from 'react';
import { CustomerDeductions } from 'pages/app-users/deductions/CustomerDeductions';
import { Protected } from './protected-routes/Protected';
import { TfnDeductions } from 'pages/app-users/deductions/TfnDeductions';
import { AbnDeduction } from 'pages/app-users/deductions/AbnDeduction';
import { RentalProperty } from 'pages/app-users/deductions/RentalProperty';
import { TripAndLogbook } from 'pages/app-users/deductions/TripAndLogbook';

const deductionsRoutes = [
    {
        path: '/customer/deductions/:id',
        component: <Protected Component={CustomerDeductions} />,
        isProtected: true,
        layout: 'deductions'
    },
    {
        path: '/customer/deductions/tfn/:id',
        component: <Protected Component={TfnDeductions} />,
        isProtected: true,
        layout: 'deductions'
    },
    {
        path: '/customer/deductions/abn/:id',
        component: <Protected Component={AbnDeduction} />,
        isProtected: true,
        layout: 'deductions'
    },
    {
        path: '/customer/deductions/rental_property/:id',
        component: <Protected Component={RentalProperty} />,
        isProtected: true,
        layout: 'deductions'
    },
    {
        path: '/trip-tracking-logbook/:id',
        component: <Protected Component={TripAndLogbook} />,
        isProtected: true,
        layout: 'deductions'
    }
];

export default deductionsRoutes;
