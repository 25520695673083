import React from 'react';
import { useSelector } from 'react-redux';
import { Envelope } from 'utills/svgs/Envelope';
import { Phone } from 'utills/svgs/Phone';
import { generateRandomProfileLink } from 'utills/globalVars';

export const ProfileCard = ({ _empInfo }) => {
    const { data: employeeinfo } = useSelector((state) => state.employeeInfoAndTasks);
    return (
        <div className="p-5 ">
            {!employeeinfo ? (
                <div className="flex justify-center items-center h-full"> </div>
            ) : (
                <div>
                    {' '}
                    <div className="flex items-center justify-center flex-col gap-5">
                        {' '}
                        <div className="profile-image-section !shadow-md">
                            <img
                                src={
                                    employeeinfo[0]?.image ??
                                    generateRandomProfileLink(
                                        employeeinfo[0]?.first_name + ' ' + employeeinfo[0]?.last_name
                                    )
                                }
                                alt="profile"
                                className="profile-section-img w-full"
                            />
                        </div>
                    </div>
                    <div className="my-5 flex justify-center items-center">
                        <div className="w-[150px] h-[1px] border-t  "></div>
                    </div>
                    <div className="buttons-font font-poppins ">
                        <div className="flex gap-3 items-center flex-wrap">
                            <div>
                                <Envelope />
                            </div>
                            <div>{employeeinfo && employeeinfo[0]?.email}</div>
                        </div>
                        <div className="flex gap-3 items-center flex-wrap mt-5">
                            <div>
                                <Phone />
                            </div>
                            <div>
                                {employeeinfo && employeeinfo[0]?.phone_number
                                    ? employeeinfo[0]?.phone_number
                                    : 'No phone number found'}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
