import Input from 'components/atoms/Input';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { InputField } from 'components/atoms/inputs/InputField';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { updateClientInviteThunk } from 'store/client';
import { setSideLoader } from 'store/global/globalReducer';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { validateEmailValue } from 'utills/dataValidation';
import { validatePhoneNumber } from 'utills/baseValidations';

export const EditClient = ({ open, setOpen, handleUpdateClientAction }) => {
    const dispatch = useDispatch();
    const { client } = useSelector((state) => state.global);
    const { sideLoader } = useSelector((state) => state.global);
    const [firstName, setFirstname] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [phoneError, setPhoneError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email || !firstName || !last_name || phoneNo?.length < 5) {
            toastHandler('Please fill in all required fields', TOAST_TYPE_ERROR);
            return;
        }
        if (phoneError) {
            return;
        }

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        const payload = {
            first_name: firstName,
            last_name,
            email,
            phone_number: phoneNo,
            client_invite_id: client?.client_invite_id
        };

        dispatch(setSideLoader(true));
        dispatch(updateClientInviteThunk(payload))
            .then((response) => {
                if (response.payload) {
                    handleUpdateClientAction(response.payload);
                    toastHandler('Client updated!', TOAST_TYPE_SUCCESS);

                    setOpen(false);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    useEffect(() => {
        if (client) {
            setFirstname(client?.first_name);
            setLastName(client?.last_name);
            setPhoneNo(`+${client?.phone_number}`);
            setEmail(client?.email);
        }
    }, [client]);

    const handlePhoneNo = (value) => {
        let isValid = false;

        setPhoneNo(value);
        const parsedPhoneNumber = parsePhoneNumberFromString(value);

        if (parsedPhoneNumber) {
            isValid = parsedPhoneNumber.isValid();
        } else {
            console.error('Invalid phone number');
        }

        if (!isValid || value?.length < 1) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    };

    return (
        <div>
            <Slideover open={open} setOpen={setOpen}>
                <div className="flex flex-col gap-3">
                    <h3 className="flex text-[32px] font-bold  text-slate-900 sm:truncate font-secondary">
                        Edit Client
                    </h3>
                </div>
                <div className="grid  grid-cols-1 gap-4 mt-10 default-forms">
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <InputField
                                label="First Name"
                                inputValue={firstName}
                                name="firstName"
                                placeholder="Enter first name"
                                maxLength={30}
                                allowSpaces={false}
                                onChange={(e) => setFirstname(e.target.value)}
                                classes="mt-2 px-5"
                                labelSx=" "
                                required={true}
                            />
                        </div>
                        <div className="mt-3">
                            <InputField
                                label="Last Name"
                                inputValue={last_name}
                                name="firstName"
                                placeholder="Enter last name"
                                maxLength={30}
                                allowSpaces={true}
                                onChange={(e) => setLastName(e.target.value)}
                                classes="mt-2 px-5"
                                labelSx=" "
                                required={true}
                            />
                        </div>
                        <div className="mt-3">
                            <PhoneInputComponent
                                title="Phone Number"
                                placeholder="Enter Phone Number"
                                titleSx="font-semibold"
                                sx="!rounded-xl"
                                atChange={(value) => handlePhoneNo(value)}
                                required={true}
                                value={phoneNo}
                            />
                        </div>
                        <div className="mt-3">
                            <Input
                                useWithoutForm={true}
                                title="Email"
                                placeholder="Email"
                                name="email"
                                titleSx="font-semibold"
                                sx="!rounded-xl !py-3"
                                onChange={(e) => setEmail(e.target.value)}
                                required={true}
                                type="email"
                                value={email}
                            />
                        </div>

                        <div className="flex items-center gap-3 justify-center mt-28 mb-4">
                            <Button
                                disabled={sideLoader ? true : false}
                                isloading={sideLoader ? true : false}
                                iconSet={{ loader: SmallLoaderWhite }}
                                title="Edit"
                                classes="py-3 px-4 sm:px-16 rounded-lg bg-leaner text-white buttons-font-lighter font-fira"
                            />
                        </div>
                    </form>
                </div>
            </Slideover>
        </div>
    );
};
