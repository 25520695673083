/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import { NumberInput } from 'components/atoms/inputs/NumberInput';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';

import useRoleGuard from 'Hooks/useRoleGuard';
import { TextInput } from 'components/atoms/inputs/TextInput';
import { getJobRefPatternsThunk, saveJobRefpatternThunk } from 'store/JobManager';

const useDebounce = (callback, delay) => {
    const latestCallback = useCallback(callback, [callback]);

    return useCallback(
        (...args) => {
            const id = setTimeout(() => {
                latestCallback(...args);
            }, delay);

            return () => {
                clearTimeout(id);
            };
        },
        [delay, latestCallback]
    );
};

const AddJobRefNoSetting = ({ open, setOpen }) => {
    const dispatch = useDispatch();

    const [taskLoader, setTaskLoader] = useState(false);

    const { isLoading } = useSelector((state) => state.global);

    const [prefixV, setPrefixV] = useState('');
    const [startSequence, setStartSequence] = useState('');
    const [displayRefNo, setDisplayRefNo] = useState('');

    useEffect(() => {
        const cleanUp = handleChangeRefNo();
        return cleanUp;
    }, [prefixV, startSequence]);

    const { hasPermissions } = useRoleGuard();

    const business_id = localStorage.getItem(BUSINESS_ID);

    const getRefs = () => {
        if (hasPermissions('JobManager', ['admin'])) {
            dispatch(setLoader(true));
            dispatch(getJobRefPatternsThunk({ business_id }))
                .then((response) => {
                    if (response.payload) {
                        const data = response?.payload;
                        setPrefixV(data?.string_prefix);
                        setStartSequence(data?.number_prefix);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    useEffect(() => {
        getRefs();
    }, []);

    const handleChangeRefNo = useDebounce(() => {
        let prefixName = prefixV !== '' ? prefixV : 'JOB';

        setDisplayRefNo(prefixName + (startSequence || ''));
    }, 500);

    const setDefaultValues = () => {
        setPrefixV('JOB');
        setStartSequence('001');
    };

    const handleCreate = () => {
        if (!hasPermissions('Job', ['admin'])) {
            return toastHandler("You don't have permission to perform this action", TOAST_TYPE_ERROR);
        }

        if (startSequence && prefixV) {
            setTaskLoader(true);
            const payload = {
                string_prefix: prefixV || '',
                number_prefix: startSequence || '',
                business_id: business_id
            };
            dispatch(setLoader(true));
            dispatch(saveJobRefpatternThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        toastHandler('Successfully Updated!', TOAST_TYPE_SUCCESS);
                        setOpen(false);
                    }
                })
                .catch((_error) => {
                    toastHandler('Somethng went wrong!', TOAST_TYPE_ERROR);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    setTaskLoader(false);
                });
        } else {
            toastHandler('Please Set the "Reference Starting Sequence" And "Pefix" Values', TOAST_TYPE_ERROR);
        }
    };

    const handleClear = () => {
        setPrefixV('');
        setStartSequence('');
        setDisplayRefNo('');
    };
    return (
        <>
            {isLoading && <Loader />}
            <Slideover open={open} setOpen={setOpen} hideBackArrow>
                <h3 className="flex text-3xl font-semibold leading-9 text-slate-900 break-words max-w-[80%] font-fira">
                    Job Reference Number Settings
                </h3>
                <p className="flex text-lg mt-5 font-normal break-words">
                    The Job reference number configuration involves creating a structured system for assigning unique
                    identification codes or numbers to jobs within the organisation.
                </p>
                <div className="add-status-container1">
                    <div className="add-ann-form flex flex-col gap-5 mt-8 mb-5">
                        <div>
                            <div>
                                <label>Experimental Details:</label>
                            </div>
                            <div className="flex items-center justify-between bg-third rounded-xl  px-4 py-3 my-2 text-gray-800">
                                <label>
                                    Prefix: <b>JOB</b>
                                </label>
                                <label>
                                    Starting Sequence: <b>001</b>
                                </label>
                            </div>
                        </div>

                        <div>
                            <div>
                                <label>Prefix</label>
                            </div>
                            <TextInput
                                value={prefixV}
                                onChange={(e) => setPrefixV(e.target.value?.toUpperCase())}
                                placeholder="Alphabets as Prefix"
                                sx="mt-2 pl-3"
                                maxLength={10}
                                required
                            />
                        </div>

                        <div>
                            <div>
                                <label>Reference Starting Sequence</label>
                            </div>
                            <NumberInput
                                type="text"
                                onChange={(e) => setStartSequence(e)}
                                value={startSequence}
                                maxLength={10}
                                placeholder="Reference Starting Sequence"
                                sx="mt-2 pl-3"
                                acceptZero
                            />
                        </div>
                    </div>
                    {(startSequence || prefixV) && (
                        <div className="flex w-[70%] h-[80px] rounded-xl mx-auto items-center justify-center bg-third text-white my-10 text-2xl">
                            {displayRefNo}
                        </div>
                    )}

                    <div className="mt-10 flex justify-center gap-5 items-center bg-white py-2">
                        <button
                            className="border-button px-5 md:px-14 py-2 flex gap-2 items-center cursor-pointer  rounded-xl"
                            onClick={handleClear}
                        >
                            <span>Clear</span>
                        </button>
                        <button
                            disabled={taskLoader ? true : false}
                            type="submit"
                            className="ann-btn text-white px-5 md:px-14 py-2 flex gap-2 items-center cursor-pointer  rounded-xl"
                            onClick={handleCreate}
                        >
                            <span>Save</span>
                            {taskLoader && <SmallLoaderWhite />}
                        </button>
                    </div>
                    <div
                        className="my-5 cursor-pointer text-sm text-third underline underline-offset-4  mx-auto text-center"
                        onClick={setDefaultValues}
                    >
                        Set Default Reference Pattren
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default AddJobRefNoSetting;
