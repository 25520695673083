import React from 'react';
import Breadcrumb from 'components/atoms/Breadcrumb';
import { ThreeDotIcon } from '../../../../utills/svgs/ThreeDotIcon';
import '../css/appuserProfile.css';
import { useState } from 'react';
import Correspondence from '../correspondence';
import { useLocation } from 'react-router-dom';
import { clientTypes, getProfileName } from 'utills/globalVars';

const ProfileJumbo = ({ toggleModal, user, path }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const slider = queryParams.get('slider');

    const [correspondence, setCorrespondence] = useState(slider ? true : false);

    if (!user) {
        return <div>Loading ...</div>;
    }

    return (
        <>
            {correspondence && <Correspondence open={correspondence} setOpen={setCorrespondence} />}
            <div className="profle-jumbo px-10 py-10">
                <div className="profile-jumbo-flex">
                    <div className="jumbo-flex-1">
                        <div className="page-title font-secondary">{getProfileName(user)}</div>
                        {path ? (
                            <Breadcrumb
                                path={[
                                    { title: 'Clients', link: 'clients' },
                                    {
                                        title:
                                            clientTypes[user?.client_type] !== 'All'
                                                ? clientTypes[user?.client_type]
                                                : 'All Clients',
                                        link: `app/users?type=${
                                            clientTypes[user?.client_type]
                                                ? clientTypes[user?.client_type]?.[0]?.toLowerCase() +
                                                  clientTypes[user?.client_type]?.slice(1)
                                                : 'All'
                                        }&reload=true`
                                    },
                                    {
                                        title: user?.first_name
                                            ? user?.first_name + ' ' + user?.last_name
                                            : user?.display_name,
                                        link: ''
                                    }
                                ]}
                            />
                        ) : (
                            <div className="jumbo-dir mt-2">
                                Applications &gt; App Users{' '}
                                <span className="special-jumbo-text">
                                    {' '}
                                    &gt; {user?.first_name} {user?.last_name} Profile
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="jumbo-flex-2 flex items-center gap-5">
                        <div onClick={toggleModal} className="burger cursor-pointer  rounded-full p-3">
                            <ThreeDotIcon />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileJumbo;
