import React from 'react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import './css/organizationalSettings.css';
import 'react-notifications/lib/notifications.css';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, ERROR_TYPE_ERROR, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import { createTaskTypeThunk } from 'store/workspace/workspaceTasks';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { generateId } from 'utills/uid';
import { PlusIcon } from 'utills/svgs/PlusIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import Slideover from 'components/atoms/SlideOver';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

const AddTaskType = ({ toggleTaskType, toggletypesUpdated, open, setOpen }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const [taskLoader, setTaskLoader] = useState(false);
    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    const handleAddInput = (e, index) => {
        const newValues = [...fields];
        newValues[index].text = e.target.value;
        setFields(newValues);
    };

    const handleAddField = (_index) => {
        const newValues = [...fields];
        const isEmpty = newValues.some((item) => item.text === '');
        if (isEmpty) {
            return toastHandler('Input cant be empty', TOAST_TYPE_ERROR);
        }

        const newInput = {
            id: generateId(),
            text: ''
        };

        newValues.push(newInput);
        setFields(newValues);
    };

    const handleDelete = (index) => {
        const newValues = [...fields];
        newValues.splice(index, 1);
        setFields(newValues);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let types = [];

        const newInputs = [...fields];

        if (newInputs.length < 1) {
            toastHandler('Please enter atleast one task type', ERROR_TYPE_ERROR);
            return;
        }

        let hasEmpty = false;
        newInputs.forEach((field) => {
            if (field.text === '') {
                hasEmpty = true;
            }
        });

        if (hasEmpty) {
            return toastHandler('Input cant be empty', TOAST_TYPE_ERROR);
        }

        newInputs.map((input) => {
            types.push(input.text);
        });

        const payload = {
            business_id,
            types
        };
        setTaskLoader(true);
        dispatch(createTaskTypeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggletypesUpdated();
                    toggleTaskType();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setTaskLoader(false);
            });
    };

    return (
        <Slideover title="Add Task Type" open={open} setOpen={setOpen}>
            <RoleBasedGuard module={'Tasks'} permissions={['admin']} sx="h-[50vh]">
                <div>
                    <div>
                        <motion.div
                            initial={{ x: 700 }}
                            animate={{ x: 0 }}
                            transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                        >
                            <form onSubmit={handleSubmit}>
                                {' '}
                                <div>
                                    <div className="add-ann-form px-5 mt-3 mb-5">
                                        {fields.map((input, index) => (
                                            <div className="mt-2 " key={index}>
                                                <div>
                                                    <label>Title</label>
                                                </div>

                                                <div className="grid grid-cols-12 mt-1">
                                                    <div className="col-span-11">
                                                        <input
                                                            onChange={(e) => handleAddInput(e, index)}
                                                            type="text"
                                                            placeholder="Enter title"
                                                            required
                                                            value={input.text}
                                                            maxLength={50}
                                                        />
                                                    </div>
                                                    <div className="col-span-1 flex justify-center items-center">
                                                        {fields?.length > 1 && index < fields?.length - 1 ? (
                                                            <div
                                                                onClick={() => handleDelete(index)}
                                                                className="cursor-pointer"
                                                            >
                                                                <BgDeleteIcon />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                onClick={() => handleAddField(index)}
                                                                className="cursor-pointer"
                                                            >
                                                                <PlusIcon />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="mt-24 w-full flex justify-center items-center bg-white py-2">
                                        <button
                                            disabled={taskLoader ? true : false}
                                            type="submit"
                                            className="ann-btn text-white px-5 md:px-28 py-2 flex gap-2 items-center cursor-pointer  rounded-lg"
                                        >
                                            <span>Add</span>
                                            {taskLoader && <SmallLoaderWhite />}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </motion.div>
                    </div>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
export default AddTaskType;
