import { useDispatch } from 'react-redux';
import { getAllServicesThunk } from 'store/auth/slices';

export const useGetAllServices = () => {
    const business_id = localStorage.getItem('business_id');
    const dispatch = useDispatch();
    const getServices = () => {
        dispatch(getAllServicesThunk({ business_id })).catch((error) => {
            console.error(error);
        });
    };

    return { getServices };
};
