import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import './css/forms.css';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { fetchCustomerFormsThunk } from 'store/form';
import { useDispatch, useSelector } from 'react-redux';
import { formHandler, handleFormSubmissions, setLoader } from 'store/global/globalReducer';

import { Loader } from 'components/common/Loader';

import { SideTimes } from 'utills/svgs/SideTimes';

import { useLocation, useParams } from 'react-router-dom';
import { BlueCustomerForm } from 'utills/svgs/BlueCustomerForm';
import { MidNightForm } from 'utills/svgs/MidNightForm';
import { NoDataFound } from 'components/common/NoDataFound';
import { Button } from 'components/atoms/buttons/Button';
import { viewBusinessCustomerInfoThunk } from 'store/client';

const Forms = ({ toggleIE, toggleForms, toggleAddedForms, toggleFormDetails, togglePreviewForm, handleUpdateForm }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: customer } = useSelector((state) => state.viewBusinessCustomerInfo);
    const { data: customerFroms } = useSelector((state) => state.fetchCustomerForms);
    const { data: customerFromData } = useSelector((state) => state.fetchSubmissionFormData);
    const [filteredForms, setFilteredForms] = useState([]);

    const [customerDetail, setCustomerDetail] = useState(null);
    const { isLoading } = useSelector((state) => state.global);
    const { businessFormEvent } = useSelector((state) => state.global);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const form_id = queryParams.get('form_id');

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        if (!customer) {
            fetchCustomer();
        } else {
            setCustomerDetail(customer);
        }
    }, [customer]);

    useEffect(() => {
        if (customerDetail) {
            dispatch(setLoader(true));
            const business_id = localStorage.getItem('business_id');
            const payload = {
                business_id: business_id,
                customer_id: customerDetail?.customer_id,
                business_client_id: customerDetail?.business_client_id
            };
            dispatch(fetchCustomerFormsThunk(payload))
                .then((response) => {
                    if (form_id) {
                        handleFormData(form_id);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    }, [customerDetail, form_id]);

    const handleFormData = (form_id) => {
        dispatch(handleFormSubmissions(form_id));
        toggleFormDetails();
    };

    useEffect(() => {
        if (customerFroms?.length > 0) {
            const newForms = customerFroms?.filter((item) => item?.count > 0);
            setFilteredForms(newForms);
        }
    }, [customerFroms]);
    return (
        <div className="add-p-side grid grid-cols-10 ">
            <div className="col-span-4 left-side"></div>
            <div className="right-side col-span-6">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    {isLoading && <Loader />}
                    <div>
                        <div onClick={toggleForms} className="absolute   text-white p-2 right-1 top-1 cursor-pointer">
                            <SideTimes />
                        </div>

                        <div className="add-detail pt-5 px-5">
                            <div className="title">Forms</div>
                        </div>
                    </div>

                    <div className="mt-5">
                        {filteredForms && filteredForms.length > 0 ? (
                            filteredForms.map((form, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleFormData(form?.form_id)}
                                    className="mx-5 cursor-pointer customer-form grid grid-cols-2 shadow-lg mt-2"
                                >
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            {' '}
                                            <div className="form-icon-wrapper ">
                                                {index % 2 === 0 ? (
                                                    <span>
                                                        {' '}
                                                        <BlueCustomerForm />{' '}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <MidNightForm />
                                                    </span>
                                                )}
                                            </div>{' '}
                                        </div>
                                        <div> {form?.form_name}</div>
                                    </div>
                                    <div className="flex items-center justify-end">
                                        <div className="flex items-center justify-end gap-1">
                                            <Button
                                                disabled={form?.count > 0 ? false : true}
                                                atClick={() => handleFormData(form?.form_id)}
                                                title={`View submissions (${form?.count})`}
                                                classes={`bg-linear text-white font-fira h-[2.6rem] rounded-xl px-7 buttons-font-lighter ${
                                                    form?.count < 1 && 'opacity-50'
                                                } `}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div>
                                <NoDataFound message="No Data Found" height="30vh" />
                            </div>
                        )}
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Forms;
