import { endpoint } from 'apiEndpoints';
import Accordian from 'components/atoms/Accordian';
import { Button } from 'components/atoms/buttons/Button';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { CreateJob } from 'components/job-manager-components/create-job/CreateJob';
import CreateTaskWithJob from 'components/job-manager-components/create-job/CreateTaskWithJob';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { useDownloadZip } from 'Hooks/Files/useDownloadZip';
import useRoleGuard from 'Hooks/useRoleGuard';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toastHandler } from 'responseHanlder';
import {
    getRelayRoomListThunk,
    getTotalThreadsCountThunk,
    setIsLoading,
    setReplyObject
} from 'store/custom-reducers/RelayRoomLeftBarReducer';
import { setLoader } from 'store/global/globalReducer';
import {
    deleteThreadResponseThunk,
    deleteThreadThunk,
    relayRoomEmailResponseStarredThunk,
    relayRoomEmailStarredThunk
} from 'store/JobManager';
import ConfirmationModal from 'utills/confirmationModal';
import { downloadFile } from 'utills/filesHandlers';
import {
    BUSINESS_ID,
    handleDownload,
    handleRemoveQueryParams,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from 'utills/globalVars';
import { FormatDateWithRelativeTime } from 'utills/moment';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import { CreateTaskIcon } from 'utills/svgs/CreateTaskIcon';
import { DimmedDeleteIcon } from 'utills/svgs/DimmedDeleteIcon';
import { DimmedDeleteIcon2 } from 'utills/svgs/DimmedDeleteIcon2';
import { DownloadIcon } from 'utills/svgs/DownloadIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { ForwardIcon } from 'utills/svgs/ForwardIcon';
import { PrintIcon } from 'utills/svgs/PrintIcon';
import { ReplyAllIcon } from 'utills/svgs/ReplyAllIcon';
import { ReplyIcon } from 'utills/svgs/ReplyIcon';
import StarIcon from 'utills/svgs/StarIcon';
import { cn } from 'utills/tailwindUtil';
import { truncateHtml } from 'utills/uid';

const RelayRoomThreadComponent = ({ thread, handleReload }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hasPermissions } = useRoleGuard();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { id } = useParams();
    const truncatedHtml = truncateHtml(thread?.message, 150);
    const [expand, setExpand] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isFav, setIsFav] = useState(thread?.is_favourite?.toLowerCase() === 'true' || false);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');
    const [open, setOpen] = useState(false);
    const [createJob, setCreateJob] = useState(false);
    const [actionType, setActionType] = useState('select');
    const [fileLoader, setFileLoader] = useState(false);

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    useEffect(() => {
        if (!open) {
            setActionType('select');
        }
    }, [open]);

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    const generateInlineArray = (inputArray) => {
        return inputArray?.map((to, i) => {
            return (
                <span key={'to' + i}>
                    {to}
                    {i < inputArray?.length - 1 && <span className="scale-150 text-gray-300">&bull;</span>}
                </span>
            );
        });
    };

    const handleDeleteThread = () => {
        if (thread?.job_email_id) {
            setIsConfirmationOpen(false);
            dispatch(setLoader(true));
            dispatch(deleteThreadThunk({ business_id, ids: [thread?.job_email_id] }))
                .then((response) => {
                    if (response.payload) {
                        handleRemoveQueryParams(navigate, ['activeTab']);
                        reloadLeftTableList();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleDeleteThreadResponse = (deleteId = null) => {
        if (deleteId) {
            setIsConfirmationOpen(false);
            dispatch(setLoader(true));
            dispatch(deleteThreadResponseThunk({ job_email_responces_id: deleteId }))
                .then((response) => {
                    if (response.payload) {
                        handleReload();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleIsFav = (job_email_id) => {
        if (job_email_id) {
            dispatch(setLoader(true));
            dispatch(
                relayRoomEmailStarredThunk({
                    job_email_id: job_email_id,
                    business_id: business_id,
                    is_favourite: (!isFav).toString()
                })
            )
                .then((response) => {
                    if (response?.payload) {
                        setIsFav(!isFav);
                        toastHandler(
                            !isFav
                                ? 'The email has been marked as favorite successfully'
                                : 'The email has been removed from favorites.',
                            TOAST_TYPE_SUCCESS
                        );

                        reloadLeftTableList();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };
    const handleIsFavResponse = (job_email_responces_id) => {
        if (job_email_responces_id) {
            dispatch(setLoader(true));
            dispatch(
                relayRoomEmailResponseStarredThunk({
                    job_email_responces_id,
                    is_favourite: (!isFav).toString()
                })
            )
                .then((response) => {
                    if (response?.payload) {
                        toastHandler(
                            !isFav
                                ? 'The email response has been marked as favorite successfully'
                                : 'The email response has been removed from favorites.',
                            TOAST_TYPE_SUCCESS
                        );

                        setIsFav(!isFav);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const reloadLeftTableList = () => {
        const payload = {
            job_id: id,
            business_id
        };
        dispatch(setIsLoading(true));
        dispatch(getRelayRoomListThunk(payload)).finally(() => {
            dispatch(getTotalThreadsCountThunk(payload));
            dispatch(setIsLoading(false));
        });
    };

    const handlePreviewFile = (file) => {
        if ((file instanceof File || file instanceof Blob) && !file?.url) {
            const url = URL.createObjectURL(file);

            if (file.type.includes('jpg') || file.type.includes('png') || file.type.includes('jpeg')) {
                setSelectedImage(url);
            } else if (file.type.includes('application/pdf')) {
                setSelectedPdf(url);
            } else {
                downloadFile(url);
            }
        } else {
            if (file?.url) {
                if (file.mimetype.includes('jpg') || file.mimetype.includes('png') || file.mimetype.includes('jpeg')) {
                    setSelectedImage(file?.url);
                } else if (file.mimetype.includes('application/pdf')) {
                    setSelectedPdf(file?.url);
                } else {
                    downloadFile(file?.url);
                }
            }
        }
    };

    const { handleDownloadZip } = useDownloadZip();

    const handleDownloadAllFiles = async () => {
        if (thread?.attachments) {
            setFileLoader(true);
            if (thread?.attachments?.length > 0) {
                const attachmentArray = thread?.attachments?.map((dataObj) => dataObj?.job_drive_id);
                let payload = {
                    job_id: id,
                    business_id,
                    job_drive_ids: attachmentArray?.join(',')
                };
                await handleDownloadZip(payload, { endpoint: endpoint.relayroomFilesDownload, method: 'GET' });
            } else {
                await handleDownload({ url: thread?.attachments[0]?.url });
            }
            setFileLoader(false);
        }
    };

    const handleTreadAction = (actionV) => {
        dispatch(setReplyObject({ ...thread, action: actionV }));
    };

    return (
        <>
            <div ref={contentRef}>
                <div className="flex gap-2 items-start w-full">
                    <DefaultProfileViewer
                        image={thread?.image}
                        width="40px"
                        height="40px"
                        name={thread?.name}
                        tooltip
                    />
                    <div className="flex-1">
                        <div className="flex items-start justify-between w-full">
                            <div className="flex-1">
                                <div className="flex items-center justify-start gap-x-1 -space-y-1 flex-wrap truncate">
                                    <PrimaryParagraph sx="max-w-[60%] truncate">{thread?.name}</PrimaryParagraph>
                                    <span className="w-2.5 h-2.5 rounded-full bg-gray-300" />
                                    <SecondaryParagraph sx="text-[#666666]">{thread?.email}</SecondaryParagraph>
                                </div>
                                <div className="w-fit mt-1">
                                    <Accordian
                                        title={
                                            <div className="text-[#666666]">
                                                <SecondaryParagraph sx="text-[#666666]">
                                                    <b>To:</b> {thread?.to[0]}
                                                </SecondaryParagraph>
                                            </div>
                                        }
                                    >
                                        <div className="group-open:animate-fadeIn">
                                            {thread?.to?.length > 1 && (
                                                <SecondaryParagraph sx="text-[#666666] flex flex-wrap gap-1">
                                                    {generateInlineArray(thread?.to?.slice(1))}
                                                </SecondaryParagraph>
                                            )}
                                            {thread?.cc && thread?.cc?.length > 0 && (
                                                <SecondaryParagraph sx="text-[#666666] flex flex-wrap gap-1">
                                                    <b>Cc:</b> {generateInlineArray(thread?.cc)}
                                                </SecondaryParagraph>
                                            )}
                                            {thread?.bcc && thread?.bcc?.length > 0 && (
                                                <SecondaryParagraph sx="text-[#666666] flex flex-wrap gap-1">
                                                    <b>Bcc:</b> {generateInlineArray(thread?.bcc)}
                                                </SecondaryParagraph>
                                            )}
                                        </div>
                                    </Accordian>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center gap-2">
                                    <SecondaryParagraph sx="text-sm text-[#666666] !text-right">
                                        {FormatDateWithRelativeTime({
                                            date: thread?.created_at,
                                            insertedFormat: 'YYYY-MM-DDTHH:mm:ss.SSSS'
                                        })}
                                    </SecondaryParagraph>
                                    <div className="hide-on-print">
                                        {expand ? (
                                            <span className="cursor-pointer" onClick={() => setExpand(false)}>
                                                <CollapsIcon className={'w-5 h-5'} />
                                            </span>
                                        ) : (
                                            <span className="cursor-pointer" onClick={() => setExpand(true)}>
                                                <ExpandIcon className={'w-5 h-5'} />
                                            </span>
                                        )}
                                    </div>
                                    <div className="hide-on-print">
                                        <StarIcon
                                            className={cn(
                                                'w-5 h-5 cursor-pointer',
                                                isFav ? 'fill-third stroke-third' : 'fill-white stroke-third',
                                                disabled && '!cursor-not-allowed opacity-50'
                                            )}
                                            onClick={() => {
                                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                                    return toastHandler(
                                                        'You dont have permission to do this action.',
                                                        TOAST_TYPE_ERROR
                                                    );
                                                }

                                                if (!disabled) {
                                                    if (thread?.job_email_responces_id) {
                                                        handleIsFavResponse(thread?.job_email_responces_id);
                                                    } else {
                                                        handleIsFav(thread?.job_email_id);
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="hide-on-print">
                                        <DropdownMenu disabled={disabled}>
                                            <DropdownMenuItem
                                                onClick={() => {
                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                        return toastHandler(
                                                            'You dont have permission to do this action.',
                                                            TOAST_TYPE_ERROR
                                                        );
                                                    }
                                                    setOpen(true);
                                                    setActionType('select');
                                                }}
                                            >
                                                <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                                    <CreateTaskIcon className="w-5 h-5 fill-gray-800 group-hover:fill-white" />
                                                    Add a new Task
                                                </div>
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                                onClick={() => {
                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                        return toastHandler(
                                                            'You dont have permission to do this action.',
                                                            TOAST_TYPE_ERROR
                                                        );
                                                    }
                                                    setIsConfirmationOpen(true);
                                                }}
                                            >
                                                <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                                    <DimmedDeleteIcon className="w-5 h-5 fill-gray-800 group-hover:fill-white" />
                                                    Delete this message
                                                </div>
                                            </DropdownMenuItem>
                                        </DropdownMenu>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            {!expand && (
                                <div className="break-all" onClick={() => setExpand(true)}>
                                    <div dangerouslySetInnerHTML={{ __html: truncatedHtml }} />
                                </div>
                            )}
                            {expand && (
                                <div className="break-all">
                                    <PrimaryParagraph sx={'my-2 text-lg font-medium'}>{thread?.title}</PrimaryParagraph>
                                    <div dangerouslySetInnerHTML={{ __html: thread?.message }} />
                                    {thread?.attachments && thread?.attachments?.length > 0 && (
                                        <>
                                            <hr className="my-5" />
                                            <div>
                                                <div className="flex justify-between items-center flex-wrap">
                                                    <PrimaryParagraph sx={'text-lg font-semibold'}>
                                                        {thread?.attachments?.length} Attachments
                                                    </PrimaryParagraph>
                                                    <Button
                                                        isloading={fileLoader}
                                                        title="Download All"
                                                        classes="bg-[#b695f8] rounded-xl px-4 h-[2.5rem] text-white secondary-fonts flex items-center gap-3 hide-on-print"
                                                        iconSet={{ leftIcon: DownloadIcon, loader: SmallLoaderWhite }}
                                                        atClick={() => {
                                                            handleDownloadAllFiles();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-5 flex gap-5 items-center flex-wrap">
                                                {thread?.attachments?.map((file, index) => (
                                                    <FileCardPreview
                                                        url={file?.url}
                                                        key={index}
                                                        showImage={true}
                                                        name={file?.name}
                                                        size={file?.size}
                                                        hideRemove
                                                        file_cx="!w-[180px] h-[180px] "
                                                        sx="w-7 h-7"
                                                        extension={
                                                            file?.mimetype?.includes('.')
                                                                ? file?.mimetype?.split('.')?.pop()?.toLowerCase()
                                                                : file?.mimetype.split('/').pop().toLowerCase()
                                                        }
                                                        imgPrperties={{
                                                            url: file?.url,
                                                            type: file?.mimetype,
                                                            sx: ''
                                                        }}
                                                        onClick={() => handlePreviewFile(file)}
                                                    />
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="flex items-center justify-between gap-4 flex-wrap mt-6 hide-on-print">
                                {thread?.status !== 'pending' ? (
                                    <div className="flex items-center gap-4 flex-wrap">
                                        <SecondaryParagraph
                                            sx={'text-md flex gap-2 items-center cursor-pointer'}
                                            onClick={() => {
                                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                                    return toastHandler(
                                                        'You dont have permission to do this action.',
                                                        TOAST_TYPE_ERROR
                                                    );
                                                }
                                                if (!disabled) {
                                                    handleTreadAction('reply');
                                                }
                                            }}
                                        >
                                            <span>
                                                <ReplyIcon
                                                    className={` ${
                                                        disabled && '!cursor-not-allowed opacity-50'
                                                    } w-5 fill-[#B695F8]`}
                                                />
                                            </span>{' '}
                                            Reply
                                        </SecondaryParagraph>
                                        <SecondaryParagraph
                                            sx={'text-md flex gap-2 items-center cursor-pointer'}
                                            onClick={() => {
                                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                                    return toastHandler(
                                                        'You dont have permission to do this action.',
                                                        TOAST_TYPE_ERROR
                                                    );
                                                }
                                                if (!disabled) {
                                                    handleTreadAction('reply_all');
                                                }
                                            }}
                                        >
                                            <span>
                                                <ReplyAllIcon
                                                    className={` ${
                                                        disabled && '!cursor-not-allowed opacity-50'
                                                    } w-5 fill-[#B695F8]`}
                                                />
                                            </span>{' '}
                                            Reply All
                                        </SecondaryParagraph>
                                        <SecondaryParagraph
                                            sx={'text-md flex gap-2 items-center cursor-pointer'}
                                            onClick={() => {
                                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                                    return toastHandler(
                                                        'You dont have permission to do this action.',
                                                        TOAST_TYPE_ERROR
                                                    );
                                                }
                                                if (!disabled) {
                                                    handleTreadAction('forward');
                                                }
                                            }}
                                        >
                                            <span>
                                                <ForwardIcon
                                                    className={` ${
                                                        disabled && '!cursor-not-allowed opacity-50'
                                                    } w-5 fill-[#B695F8]`}
                                                />
                                            </span>{' '}
                                            Forward
                                        </SecondaryParagraph>
                                        <SecondaryParagraph
                                            sx={'text-md flex gap-2 items-center cursor-pointer'}
                                            onClick={() => {
                                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                                    return toastHandler(
                                                        'You dont have permission to do this action.',
                                                        TOAST_TYPE_ERROR
                                                    );
                                                }
                                                if (!disabled) {
                                                    reactToPrintFn();
                                                }
                                            }}
                                        >
                                            <span>
                                                <PrintIcon
                                                    className={` ${
                                                        disabled && '!cursor-not-allowed opacity-50'
                                                    } w-5 fill-[#B695F8]`}
                                                />
                                            </span>{' '}
                                            Print
                                        </SecondaryParagraph>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                <SecondaryParagraph
                                    sx={'text-md flex gap-2 items-center cursor-pointer'}
                                    onClick={() => {
                                        if (!hasPermissions('Job', ['write', 'admin'])) {
                                            return toastHandler(
                                                'You dont have permission to do this action.',
                                                TOAST_TYPE_ERROR
                                            );
                                        }
                                        if (!disabled) {
                                            setIsConfirmationOpen(true);
                                        }
                                    }}
                                >
                                    <span>
                                        <DimmedDeleteIcon2
                                            className={` ${
                                                disabled && '!cursor-not-allowed opacity-50'
                                            } w-5 cursor-pointer`}
                                        />
                                    </span>{' '}
                                    Delete
                                </SecondaryParagraph>
                            </div>
                        </div>
                    </div>
                </div>
            </div>{' '}
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={() => {
                    if (thread?.job_email_responces_id) {
                        handleDeleteThreadResponse(thread?.job_email_responces_id);
                    } else {
                        handleDeleteThread();
                    }
                }}
            />
            {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
            {selectedPdf ? (
                <GlobalPDFViewer
                    url={selectedPdf}
                    setOpen={() => setSelectedPdf('')}
                    open={selectedPdf ? true : false}
                />
            ) : (
                ''
            )}{' '}
            {open && <CreateTaskWithJob open={open} setOpen={setOpen} actionType={actionType} jobRefData={thread} />}
            {createJob && (
                <CreateJob
                    title={'Create Job'}
                    open={createJob}
                    setOpen={(value) => {
                        setCreateJob(value);
                    }}
                    onAction={(_newJob) => {}}
                />
            )}
        </>
    );
};

export default RelayRoomThreadComponent;
