import React from 'react';
import { useGetBranch } from 'Hooks/useGetBranch';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { InputField } from 'components/atoms/inputs/InputField';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';

import { setSideLoader } from 'store/global/globalReducer';
import { getAllDesignationsThunk } from 'store/settings/team/workspaceSettings';
import { createTelephonicDirectoryThunk } from 'store/workspace/workspaceTelephonicDirectories';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue } from 'utills/dataValidation';
import { BUSINESS_ID, ERROR_TYPE_SUCCESS, TOAST_TYPE_ERROR } from 'utills/globalVars';

const AddDirectory = ({ open, setOpen, toggleAddDirectory, toggleDirectory }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: designations } = useSelector((state) => state.getAllDesignations);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        desk_extension: '',
        designation: '',
        business_branch_id: '',
        business_id
    });

    const handleName = (value) => {
        const hasDigit = /\d/.test(value);

        if (hasDigit) {
            return;
        } else {
            setFormData((prevData) => ({ ...prevData, first_name: value }));
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        if (!formData.first_name) {
            toastHandler('First name is required', TOAST_TYPE_ERROR);
            return;
        }
        if (formData.first_name.length > 15) {
            toastHandler('First name should be less than 15 character', TOAST_TYPE_ERROR);
            return;
        }
        if (!formData.last_name) {
            toastHandler('Last name is required', TOAST_TYPE_ERROR);
            return;
        }
        if (formData.last_name.length > 15) {
            toastHandler('Last name should be less than 15 character', TOAST_TYPE_ERROR);
            return;
        }
        if (!formData.email) {
            toastHandler('Email is required', TOAST_TYPE_ERROR);
            return;
        }
        if (formData.email.length > 40) {
            toastHandler('Last name should be less than 15 character', TOAST_TYPE_ERROR);
            return;
        }
        if (formData.email) {
            if (!validateEmailValue(formData.email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (!formData.phone_number) {
            toastHandler('Phone Number is required', TOAST_TYPE_ERROR);
            return;
        }

        if (formData.phone_number) {
            if (!validatePhoneNumber(formData.phone_number)) {
                return false;
            }
        }

        dispatch(setSideLoader(true));
        dispatch(createTelephonicDirectoryThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Added successfully', ERROR_TYPE_SUCCESS);
                    toggleDirectory();
                    toggleAddDirectory();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const { branches } = useGetBranch();

    const handleGetDesignations = () => {
        dispatch(getAllDesignationsThunk())
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    useEffect(() => {
        handleGetDesignations();
    }, []);

    return (
        <Slideover
            open={open}
            setOpen={setOpen}
            title={'Add Telephonic Directory '}
            footer={
                <div className="flex justify-center items-center mt-5">
                    <button onClick={handleSubmit} className="ann-btn px-20 py-2 cursor-pointer text-white rounded-lg">
                        Add
                    </button>
                </div>
            }
            showLoader
            hideBackArrow
        >
            <RoleBasedGuard module={'Telephonic_directories'} permissions={['admin']} sx="h-[50vh]">
                <div>
                    <div className="add-ann-form grid grid-cols-2 gap-5 mt-5 p-5">
                        <div className=" ">
                            <div>
                                <div>
                                    <label>First Name</label>
                                </div>
                                <div>
                                    <InputField
                                        inputValue={formData.first_name}
                                        name="first_name"
                                        placeholder="First Name"
                                        maxLength={30}
                                        allowSpaces={false}
                                        onChange={(e) => handleName(e.target.value)}
                                        classes="px-3 font-poppins  text-sm rounded-xl mt-1"
                                        labelSx=" "
                                    />
                                </div>
                            </div>

                            <div className="mt-5 add-ann-form">
                                <div>
                                    <label>Email</label>
                                </div>
                                <div>
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className="px-3 rounded-xl mt-1 font-poppins  text-sm"
                                        name="email"
                                        onChange={handleInputChange}
                                        maxLength={80}
                                    />
                                </div>
                            </div>

                            <div className="mt-5">
                                <div>
                                    <label>Branch Assign (optional)</label>
                                </div>
                                <div>
                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="title"
                                        optionsData={[{ business_branch_id: '', title: 'Select' }, ...branches]}
                                        sx="rounded-xl py-3 bg-transparent ring-gray-400 td-select min-w-[150px]"
                                        onChangeValue={(obj) =>
                                            handleInputChange({
                                                target: {
                                                    value: obj.business_branch_id,
                                                    name: 'business_branch_id'
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="mt-5">
                                <div>
                                    <label>Role (optional)</label>
                                </div>
                                <div>
                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="title"
                                        optionsData={designations || []}
                                        sx="rounded-xl py-3 bg-transparent ring-gray-400 td-select min-w-[150px]"
                                        onChangeValue={(obj) =>
                                            handleInputChange({
                                                target: { value: obj.title, name: 'designation' }
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className=" ">
                            <div>
                                <div>
                                    <label>Last Name</label>
                                </div>
                                <div>
                                    <InputField
                                        inputValue={formData.last_name}
                                        name="last_name"
                                        placeholder="Last Name"
                                        maxLength={30}
                                        allowSpaces={false}
                                        onChange={handleInputChange}
                                        classes="px-3 rounded-xl mt-1 font-poppins  text-sm"
                                        labelSx=" "
                                    />
                                </div>
                            </div>

                            <div className="mt-5">
                                <div>
                                    <label>Contact Number</label>
                                </div>
                                <div>
                                    <div className="w-full m-auto ">
                                        <div className="">
                                            <PhoneInputComponent
                                                placeholder="Enter Phone Number"
                                                wrapsx="mt-1.5"
                                                sx="!rounded-xl font-poppins  text-sm py-[5px]"
                                                atChange={(phone) => {
                                                    handleInputChange({
                                                        target: {
                                                            value: phone,
                                                            name: 'phone_number'
                                                        }
                                                    });
                                                }}
                                                value={formData?.phone_number}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label>Extension (optional) </label>
                                </div>

                                <input
                                    type="text"
                                    name="desk_extension"
                                    onChange={handleInputChange}
                                    placeholder="Extension"
                                    maxLength={100}
                                    className="font-poppins  text-sm"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};

export default AddDirectory;
