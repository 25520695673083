import React from 'react';
import { Jumbotron } from 'components/organisms/Jumbotron';
import OSPrebuitList from './OSPrebuitList';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/atoms/buttons/Button';

const PrebuiltChecklistPage = ({ toggleChecklist, typesUpdated, setEditId }) => {
    const navigate = useNavigate();
    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/settings/task-module`);
        }
    };
    return (
        <div>
            <Jumbotron
                title="Prebuilt Task Checklist"
                path={[
                    {
                        link: 'settings',
                        title: 'Settings'
                    },
                    {
                        link: 'settings/task-module',
                        title: 'Task Module'
                    },
                    {
                        link: '',
                        title: 'Prebuilt Task Checklist'
                    }
                ]}
                backArrowProp={backArrowProp}
            />
            <div>
                <div className="md:px-10 px-5 mt-2 ">
                    <div className="flex justify-end">
                        <div>
                            <Button
                                atClick={toggleChecklist}
                                title={'Add'}
                                classes={'ann-btn rounded-xl px-12 buttons-font-lighter font-fira '}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <OSPrebuitList typesUpdated={typesUpdated} setEditId={setEditId} />
            </div>
        </div>
    );
};
export default PrebuiltChecklistPage;
