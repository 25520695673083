import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import whiteBgLogo from '../files/whiteBgLogo.png.png';
const Header = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        sessionStorage.setItem('loginStepper', 1);

        navigate('/');
    };
    return (
        <div className="header w-full px-5 pt-3 text-black">
            <div onClick={() => handleNavigate()} className="cursor-pointer">
                <img src={whiteBgLogo} alt="logo" className="Synkli-logo mt-3 ml-7" />
            </div>
            <Outlet />
        </div>
    );
};

export default Header;
