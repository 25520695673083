import React from 'react';

export const ManagerBgIcon = ({ className }) => {
    return (
        <div>
            {' '}
            <svg
                width="90"
                height="90"
                viewBox="0 0 134 124"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <g opacity="0.5">
                    <path
                        d="M72.6405 140.44L76.4082 126.225C76.7241 125.033 77.9452 124.328 79.1356 124.65C80.326 124.972 81.0348 126.2 80.7188 127.392L75.57 146.818C75.254 148.01 74.0329 148.715 72.8425 148.393L53.4446 143.139C52.2543 142.817 51.5455 141.589 51.8614 140.397C52.1774 139.205 53.3985 138.5 54.5888 138.822L68.7833 142.667L57.0352 122.318L60.7891 119.913L72.6405 140.44Z"
                        fill="white"
                    />
                    <path
                        d="M123.632 111.111C122.777 114.267 120.612 116.399 118.819 117.434L93.4336 132.09L91.1945 128.212L116.58 113.556C117.554 112.993 118.835 111.737 119.328 109.919C119.793 108.206 119.66 105.609 117.151 102.068L117.091 101.983L110.779 91.0511L114.637 88.8241L120.845 99.5768C123.908 103.941 124.511 107.872 123.632 111.111Z"
                        fill="white"
                    />
                    <path
                        d="M8.20217 84.8429C-4.70181 103.264 -1.14554 124.923 20.2844 132.863C28.5226 135.915 37.507 133.354 45.0911 128.976L56.9335 122.138L54.0841 117.203C51.0208 112.839 50.4182 108.908 51.2963 105.669C52.1518 102.513 54.3171 100.381 56.1094 99.3459L81.4952 84.6894L83.7342 88.5675L58.3484 103.224C57.3744 103.786 56.0933 105.042 55.6004 106.861C55.1359 108.574 55.2688 111.171 57.7775 114.712L57.838 114.797L60.7891 119.913L110.779 91.0511L102.288 76.3409L98.5204 90.556C98.2045 91.7481 96.9833 92.4531 95.793 92.1307C94.6026 91.8082 93.8938 90.5805 94.2098 89.3884L99.3586 69.9624C99.6746 68.7703 100.896 68.0653 102.086 68.3877L121.484 73.6417C122.674 73.9641 123.383 75.1918 123.067 76.3839C122.751 77.576 121.53 78.281 120.34 77.9586L106.145 74.1139L114.637 88.8241L132.022 78.7858C138.694 74.9341 144.583 69.4623 146.922 62.1027C151.45 47.8545 148.235 37.0775 146.239 32.0571C134.952 11.3468 110.183 11.7178 99.7231 15.3823C92.8688 3.51031 70.911 -0.341363 62.8123 1.10898C36.9788 5.73533 24.3369 28.5671 25.6464 43.9041C-1.60736 57.1064 2.52783 75.9831 8.20217 84.8429Z"
                        fill="white"
                    />
                </g>
            </svg>
        </div>
    );
};
