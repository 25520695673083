import React from 'react';
import { generateId } from 'utills/uid';
import { DimmedDeleteIcon } from '../../../utills/svgs/DimmedDeleteIcon';
import { DimmedEditIcon } from '../../../utills/svgs/DimmedEditIcon';
import { EnvelopeIcon } from '../../../utills/svgs/EnvelopeIcon';
import { LocationPin } from '../../../utills/svgs/LocationPin';
import { Telephone } from '../../../utills/svgs/Telephone';
import './css/businessSteps.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BUSINESS_ID, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import { editBranch, setLoader, setSideLoader } from 'store/global/globalReducer';
import { deleteBranchThunk, getBranchLocationsThunk } from 'store/auth/slices';

import { useEffect } from 'react';
import { toastHandler } from 'responseHanlder';
import ConfirmationModal from 'utills/confirmationModal';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import MapComponent from 'components/atoms/MapComponent';
import useRoleGuard from 'Hooks/useRoleGuard';
const BranchLocationStep = ({ toggleBranchLocation, branchesUpdated, toggleEditBranch }) => {
    const { data: loginData } = useSelector((state) => state.login);
    const { hasPermissions } = useRoleGuard();

    const [delUpdate, setDelUpdate] = useState(false);
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.getBranchLocations);

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [_locationModal, setLocationModal] = useState(false);

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        const delBranchData = {
            branch_id: deleteId,
            business_id
        };

        setLocationModal(false);

        dispatch(setSideLoader(true));
        dispatch(deleteBranchThunk(delBranchData))
            .then((response) => {
                if (response.payload) {
                    setDelUpdate(!delUpdate);
                    toastHandler('Branch deleted', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const deleteBranch = (branch_id) => {
        setIsConfirmationOpen(true);
        setDeleteId(branch_id);
    };

    useEffect(() => {
        dispatch(getBranchLocationsThunk({ user_id: loginData?.user_id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, [branchesUpdated, delUpdate]);

    useEffect(() => {}, []);

    const handleBranchUpdate = (branch) => {
        dispatch(editBranch(branch));
        toggleEditBranch();
    };

    const handleAddlocation = () => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            toggleBranchLocation();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };
    return (
        <div className="md:px-10 px-5 mt-3">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            {data && data?.length < 1 ? (
                <div className=" branch-locations px-5">
                    {' '}
                    <div className="flex  justify-end my-3">
                        <button
                            onClick={handleAddlocation}
                            className="text-white ann-btn buttons-font-lighter  rounded-xl px-3 py-2"
                        >
                            Add Branch Location
                        </button>
                    </div>
                    <div className="location-sub-title flex justify-center items-center">
                        No branch added yet click on top right button to add one
                    </div>
                </div>
            ) : (
                <div className="branch-locations shadow md:grid grid-cols-2">
                    <div className="locations-left px-5">
                        <div className="flex justify-end my-3">
                            <button
                                onClick={handleAddlocation}
                                className="text-white ann-btn buttons-font-lighter  rounded-xl px-3 py-3"
                            >
                                Add Branch Location
                            </button>
                        </div>

                        {data &&
                            data?.map((location, index) => (
                                <div key={'branchlocation_step_' + index}>
                                    <div
                                        className="location-grid !shadow-md mt-6 mb-2 border  !rounded-xl relative "
                                        key={generateId()}
                                    >
                                        <div className="absolute right-3 top-2 cursor-pointer">
                                            <DropdownMenu>
                                                <DropdownMenuItem onClick={() => handleBranchUpdate(location)}>
                                                    <div className="flex items-center gap-2">
                                                        <DimmedEditIcon />
                                                        Edit
                                                    </div>
                                                </DropdownMenuItem>
                                                <DropdownMenuItem
                                                    onClick={() => deleteBranch(location?.business_branch_id)}
                                                >
                                                    <div className="flex items-center gap-2">
                                                        <DimmedDeleteIcon className="w-4 h-4" />
                                                        Delete
                                                    </div>
                                                </DropdownMenuItem>
                                            </DropdownMenu>
                                        </div>

                                        <div className="title border-r p-2 ">
                                            <h3 className="text-lg font-poppins mb-2  pt-4 break-all">
                                                {location?.title}
                                            </h3>

                                            <MapComponent
                                                addressData={{
                                                    geometry: {
                                                        location: {
                                                            lat: () => location?.lat || '0',
                                                            lng: () => location?.lng || '0'
                                                        }
                                                    },
                                                    formatted_address: location?.formatted_address || ''
                                                }}
                                                className="!h-[200px] rounded-xl bg-gray-50"
                                                openInNewTab
                                            />
                                        </div>
                                        <div className="p-5">
                                            <div className="flex gap-2  ">
                                                <div className="mt-1">
                                                    <EnvelopeIcon />
                                                </div>
                                                <div>
                                                    <div className="buttons-font font-poppins">Email</div>
                                                    <div className="buttons-font-lighter font-poppins text-secondarybg">
                                                        {location?.email}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex gap-2 mt-4  ">
                                                <div className="mt-1">
                                                    <LocationPin />
                                                </div>
                                                <div>
                                                    <div className="buttons-font-lighter font-poppins ">Address</div>
                                                    <div className="buttons-font-lighter font-poppins text-secondarybg">
                                                        {location?.address}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex gap-2 mt-4  ">
                                                <div className="mt-1">
                                                    <Telephone />
                                                </div>
                                                <div>
                                                    <div className="buttons-font-lighter font-poppins">Call us</div>
                                                    <div className="buttons-font-lighter font-poppins text-secondarybg">
                                                        {location?.phone_number}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div></div>
                </div>
            )}
        </div>
    );
};

export default BranchLocationStep;
