import Cookies from 'js-cookie';

const cryptoKeyName = (process.env?.REACT_APP_CRYPTO_KEY_NAME || 'AES-GCM').toString();

export const generateKey = async () => {
    return crypto.subtle.generateKey({ name: cryptoKeyName, length: 256 }, true, ['encrypt', 'decrypt']);
};

export const encryptData = async (key, data) => {
    const encoder = new TextEncoder();
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encrypted = await crypto.subtle.encrypt({ name: cryptoKeyName, iv: iv }, key, encoder.encode(data));
    return { iv, encrypted };
};

export const decryptData = async (key, encryptedData, iv) => {
    const decoder = new TextDecoder();
    const decrypted = await crypto.subtle.decrypt({ name: cryptoKeyName, iv: iv }, key, encryptedData);
    return decoder.decode(decrypted);
};

export const exportKey = async (key) => {
    return await crypto.subtle.exportKey('jwk', key);
};

export const importKey = async (jwk) => {
    return await crypto.subtle.importKey('jwk', jwk, { name: cryptoKeyName }, true, ['encrypt', 'decrypt']);
};

export const setCookie = (name, value, days) => {
    Cookies.set(name, value, { expires: days, secure: true, sameSite: 'Strict' });
};

export const getCookie = (name) => {
    return Cookies.get(name);
};

export const removeCookie = (name) => {
    Cookies.remove(name);
};
