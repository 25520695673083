import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCSRFThunk, logoutThunk, removeFCMThunk } from 'store/auth/slices';
import { setLogLoader } from 'store/global/globalReducer';
import { removeToken } from 'utills/fb-messages';
import { clearAllCookies } from 'utills/sortAndFilter';
import { CSRF_TOKEN } from 'utills/globalVars';
import { _getCookie, setCookie } from 'utills/encryption/cryptoUtils';
// import { getCSRFToken } from '../axios/index';

export const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = async (setIsConfirmationOpen, sidebarLog, timeoutLogout) => {
        const fcm_token = localStorage.getItem('fcm_token');
        dispatch(setLogLoader(true));
        if (fcm_token) {
            dispatch(
                removeFCMThunk({
                    token: fcm_token
                })
            )
                .then(async (response) => {
                    if (response.payload) {
                        console.info('fcm_token removed from DB', response.payload);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(async () => {
                    dispatch(setLogLoader(false));
                });
        }
        await handleLogoutFun(setIsConfirmationOpen, sidebarLog, timeoutLogout);
    };

    const handleLogoutFun = async (setIsConfirmationOpen, sidebarLog, timeoutLogout) => {
        if (timeoutLogout) {
            dispatch(setLogLoader(true));

            dispatch(logoutThunk())
                .then(async (response) => {
                    if (response.payload) {
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate('/');
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(async () => {
                    dispatch(setLogLoader(false));
                    await getCSRFTokenFun();
                    await removeToken();
                });
            return;
        }

        if (sidebarLog) {
            dispatch(setIsConfirmationOpen(false));
        } else {
            setIsConfirmationOpen(false);
        }

        dispatch(setLogLoader(true));

        dispatch(logoutThunk())
            .then(async (response) => {
                if (response.payload) {
                    clearAllCookies();
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate('/');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(async () => {
                await getCSRFTokenFun();
                await removeToken();
                dispatch(setLogLoader(false));
            });
    };

    const getCSRFTokenFun = async () => {
        dispatch(setLogLoader(true));
        dispatch(getCSRFThunk())
            .then(async (response) => {
                if (response.payload) {
                    const csrfToken = response?.payload?.csrfToken;
                    if (csrfToken) {
                        setCookie(CSRF_TOKEN, csrfToken);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(async () => {
                dispatch(setLogLoader(false));
            });
    };

    return { logout, getCSRFTokenFun };
};
