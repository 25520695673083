import React from 'react';
import { cn } from 'utills/tailwindUtil';

export const PrimaryLabel = ({ sx, htmlFor, children }) => {
    return (
        <label htmlFor={htmlFor} className={cn('buttons-font  font-poppins font-[500]', sx)}>
            {children}
        </label>
    );
};
