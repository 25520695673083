import React from 'react';

export const CalendarSmallIco = () => {
    return (
        <div>
            <svg width="18" height="16" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.469 3.46667H23.6253V5.20001H26.2503V24.2667H1.75023V5.20001H4.37524V3.46667H1.53148C1.32694 3.47006 1.12507 3.51332 0.93741 3.59399C0.749751 3.67467 0.579975 3.79116 0.43778 3.93683C0.295584 4.0825 0.183754 4.25449 0.108678 4.44298C0.0336011 4.63146 -0.00325148 4.83275 0.00022492 5.03534V24.4313C-0.00325148 24.6339 0.0336011 24.8352 0.108678 25.0237C0.183754 25.2122 0.295584 25.3842 0.43778 25.5299C0.579975 25.6755 0.749751 25.792 0.93741 25.8727C1.12507 25.9534 1.32694 25.9966 1.53148 26H26.469C26.6736 25.9966 26.8754 25.9534 27.0631 25.8727C27.2508 25.792 27.4205 25.6755 27.5627 25.5299C27.7049 25.3842 27.8168 25.2122 27.8918 25.0237C27.9669 24.8352 28.0038 24.6339 28.0003 24.4313V5.03534C28.0038 4.83275 27.9669 4.63146 27.8918 4.44298C27.8168 4.25449 27.7049 4.0825 27.5627 3.93683C27.4205 3.79116 27.2508 3.67467 27.0631 3.59399C26.8754 3.51332 26.6736 3.47006 26.469 3.46667Z"
                    fill="url(#paint0_linear_4342_591)"
                />
                <path d="M5.25 10.4004H7V12.1337H5.25V10.4004Z" fill="url(#paint1_linear_4342_591)" />
                <path d="M10.5 10.4004H12.25V12.1337H10.5V10.4004Z" fill="url(#paint2_linear_4342_591)" />
                <path d="M15.75 10.4004H17.5V12.1337H15.75V10.4004Z" fill="url(#paint3_linear_4342_591)" />
                <path d="M21 10.4004H22.75V12.1337H21V10.4004Z" fill="url(#paint4_linear_4342_591)" />
                <path d="M5.25 14.7334H7V16.4667H5.25V14.7334Z" fill="url(#paint5_linear_4342_591)" />
                <path d="M10.5 14.7334H12.25V16.4667H10.5V14.7334Z" fill="url(#paint6_linear_4342_591)" />
                <path d="M15.75 14.7334H17.5V16.4667H15.75V14.7334Z" fill="url(#paint7_linear_4342_591)" />
                <path d="M21 14.7334H22.75V16.4667H21V14.7334Z" fill="url(#paint8_linear_4342_591)" />
                <path d="M5.25 19.0665H7V20.7999H5.25V19.0665Z" fill="url(#paint9_linear_4342_591)" />
                <path d="M10.5 19.0665H12.25V20.7999H10.5V19.0665Z" fill="url(#paint10_linear_4342_591)" />
                <path d="M15.75 19.0665H17.5V20.7999H15.75V19.0665Z" fill="url(#paint11_linear_4342_591)" />
                <path d="M21 19.0665H22.75V20.7999H21V19.0665Z" fill="url(#paint12_linear_4342_591)" />
                <path
                    d="M7 6.93333C7.23207 6.93333 7.45463 6.84202 7.61872 6.67949C7.78282 6.51696 7.875 6.29652 7.875 6.06667V0.866667C7.875 0.636812 7.78282 0.416372 7.61872 0.253841C7.45463 0.0913092 7.23207 0 7 0C6.76794 0 6.54538 0.0913092 6.38128 0.253841C6.21719 0.416372 6.125 0.636812 6.125 0.866667V6.06667C6.125 6.29652 6.21719 6.51696 6.38128 6.67949C6.54538 6.84202 6.76794 6.93333 7 6.93333Z"
                    fill="url(#paint13_linear_4342_591)"
                />
                <path
                    d="M21 6.93333C21.2321 6.93333 21.4546 6.84202 21.6187 6.67949C21.7828 6.51696 21.875 6.29652 21.875 6.06667V0.866667C21.875 0.636812 21.7828 0.416372 21.6187 0.253841C21.4546 0.0913092 21.2321 0 21 0C20.7679 0 20.5454 0.0913092 20.3813 0.253841C20.2172 0.416372 20.125 0.636812 20.125 0.866667V6.06667C20.125 6.29652 20.2172 6.51696 20.3813 6.67949C20.5454 6.84202 20.7679 6.93333 21 6.93333Z"
                    fill="url(#paint14_linear_4342_591)"
                />
                <path d="M9.625 3.46667H18.375V5.20001H9.625V3.46667Z" fill="url(#paint15_linear_4342_591)" />
                <defs>
                    <linearGradient
                        id="paint0_linear_4342_591"
                        x1="15.3741"
                        y1="3.74652"
                        x2="15.3605"
                        y2="26.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_4342_591"
                        x1="6.21087"
                        y1="10.4219"
                        x2="6.20958"
                        y2="12.1337"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_4342_591"
                        x1="11.4609"
                        y1="10.4219"
                        x2="11.4596"
                        y2="12.1337"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_4342_591"
                        x1="16.7109"
                        y1="10.4219"
                        x2="16.7096"
                        y2="12.1337"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_4342_591"
                        x1="21.9609"
                        y1="10.4219"
                        x2="21.9596"
                        y2="12.1337"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_4342_591"
                        x1="6.21087"
                        y1="14.7549"
                        x2="6.20958"
                        y2="16.4667"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_4342_591"
                        x1="11.4609"
                        y1="14.7549"
                        x2="11.4596"
                        y2="16.4667"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_4342_591"
                        x1="16.7109"
                        y1="14.7549"
                        x2="16.7096"
                        y2="16.4667"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_4342_591"
                        x1="21.9609"
                        y1="14.7549"
                        x2="21.9596"
                        y2="16.4667"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_4342_591"
                        x1="6.21087"
                        y1="19.0881"
                        x2="6.20958"
                        y2="20.7999"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear_4342_591"
                        x1="11.4609"
                        y1="19.0881"
                        x2="11.4596"
                        y2="20.7999"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear_4342_591"
                        x1="16.7109"
                        y1="19.0881"
                        x2="16.7096"
                        y2="20.7999"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear_4342_591"
                        x1="21.9609"
                        y1="19.0881"
                        x2="21.9596"
                        y2="20.7999"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear_4342_591"
                        x1="7.08587"
                        y1="0.0861051"
                        x2="7.0653"
                        y2="6.93329"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint14_linear_4342_591"
                        x1="21.0859"
                        y1="0.0861051"
                        x2="21.0653"
                        y2="6.93329"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint15_linear_4342_591"
                        x1="14.4293"
                        y1="3.4882"
                        x2="14.4291"
                        y2="5.20001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
