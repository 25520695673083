import React from 'react';

const OutboxIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M12.792 5.29219C12.7546 5.33205 12.7094 5.36382 12.6593 5.38554C12.6091 5.40725 12.555 5.41846 12.5004 5.41846C12.4457 5.41846 12.3916 5.40725 12.3415 5.38554C12.2913 5.36382 12.2461 5.33205 12.2087 5.29219L10.417 3.50886V9.16719C10.417 9.2777 10.3731 9.38368 10.295 9.46182C10.2169 9.53996 10.1109 9.58386 10.0004 9.58386C9.88986 9.58386 9.78388 9.53996 9.70574 9.46182C9.6276 9.38368 9.5837 9.2777 9.5837 9.16719V3.50886L7.79204 5.29219C7.71468 5.36955 7.60977 5.41301 7.50037 5.41301C7.39097 5.41301 7.28606 5.36955 7.2087 5.29219C7.13135 5.21484 7.08789 5.10992 7.08789 5.00053C7.08789 4.89113 7.13135 4.78621 7.2087 4.70886L9.7087 2.20886C9.74391 2.16667 9.79004 2.13496 9.84204 2.11719C9.94348 2.07552 10.0573 2.07552 10.1587 2.11719C10.2107 2.13496 10.2568 2.16667 10.292 2.20886L12.792 4.70886C12.8319 4.74627 12.8637 4.79145 12.8854 4.84161C12.9071 4.89178 12.9183 4.94586 12.9183 5.00053C12.9183 5.05519 12.9071 5.10927 12.8854 5.15944C12.8637 5.2096 12.8319 5.25478 12.792 5.29219Z"
                fill="#B695F8"
                stroke="#B695F8"
                strokeWidth="0.3"
            />
            <path
                d="M15.4167 3.75C15.3062 3.75 15.2002 3.7939 15.122 3.87204C15.0439 3.95018 15 4.05616 15 4.16667C15 4.27717 15.0439 4.38315 15.122 4.46129C15.2002 4.53943 15.3062 4.58333 15.4167 4.58333C15.7482 4.58333 16.0661 4.71503 16.3005 4.94945C16.535 5.18387 16.6667 5.50181 16.6667 5.83333V11.6667H12.0833C11.9728 11.6667 11.8668 11.7106 11.7887 11.7887C11.7106 11.8668 11.6667 11.9728 11.6667 12.0833V13.3333H8.33333V12.0833C8.33333 11.9728 8.28943 11.8668 8.21129 11.7887C8.13315 11.7106 8.02717 11.6667 7.91667 11.6667H3.33333V5.83333C3.33333 5.50181 3.46503 5.18387 3.69945 4.94945C3.93387 4.71503 4.25181 4.58333 4.58333 4.58333C4.69384 4.58333 4.79982 4.53943 4.87796 4.46129C4.9561 4.38315 5 4.27717 5 4.16667C5 4.05616 4.9561 3.95018 4.87796 3.87204C4.79982 3.7939 4.69384 3.75 4.58333 3.75C4.0308 3.75 3.5009 3.96949 3.11019 4.36019C2.71949 4.7509 2.5 5.2808 2.5 5.83333V15.4167C2.5 15.9692 2.71949 16.4991 3.11019 16.8898C3.5009 17.2805 4.0308 17.5 4.58333 17.5H15.4167C15.9692 17.5 16.4991 17.2805 16.8898 16.8898C17.2805 16.4991 17.5 15.9692 17.5 15.4167V5.83333C17.5 5.2808 17.2805 4.7509 16.8898 4.36019C16.4991 3.96949 15.9692 3.75 15.4167 3.75ZM16.6667 15.4167C16.6667 15.7482 16.535 16.0661 16.3005 16.3005C16.0661 16.535 15.7482 16.6667 15.4167 16.6667H4.58333C4.25181 16.6667 3.93387 16.535 3.69945 16.3005C3.46503 16.0661 3.33333 15.7482 3.33333 15.4167V12.5H7.5V13.75C7.5 13.8605 7.5439 13.9665 7.62204 14.0446C7.70018 14.1228 7.80616 14.1667 7.91667 14.1667H12.0833C12.1938 14.1667 12.2998 14.1228 12.378 14.0446C12.4561 13.9665 12.5 13.8605 12.5 13.75V12.5H16.6667V15.4167Z"
                fill="#B695F8"
                stroke="#B695F8"
                strokeWidth="0.3"
            />
        </svg>
    );
};

export default OutboxIcon;
