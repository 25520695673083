import React from 'react';
import Slideover from 'components/atoms/SlideOver';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { useSelector } from 'react-redux';
import { DownloadIcon } from 'utills/svgs/DownloadIcon';

export const SingleCode = ({ _toggleSingleCode, open, setOpen }) => {
    const { code } = useSelector((state) => state.global);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = code?.qr_code;
        link.download = code?.title;
        link.click();
    };
    return (
        <Slideover title="QR Code" open={open} setOpen={setOpen}>
            <div>
                {' '}
                <div>
                    <div>
                        <div>
                            <RoleBasedGuard module={'Qr_codes'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                                {' '}
                                <div className="m-5">
                                    <div className="flex justify-end items-center">
                                        <div
                                            onClick={handleDownload}
                                            className="bg-thirdbg rounded-xl gap-2 cursor-pointer flex items-center px-5 text-white font-poppins buttons-font-lighter py-2"
                                        >
                                            {' '}
                                            <div>
                                                <DownloadIcon />
                                            </div>
                                            <div>Download</div>
                                        </div>
                                    </div>

                                    <div className="flex justify-center items-center gap-5 flex-col">
                                        {' '}
                                        <div>
                                            <img src={code?.qr_code} alt="qrcode" className="w-[250px] h-[250px]" />
                                        </div>
                                        <div className="lg-title font-poppins">{code?.title}</div>
                                    </div>
                                </div>
                            </RoleBasedGuard>
                        </div>
                    </div>
                </div>
            </div>
        </Slideover>
    );
};
