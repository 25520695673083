import React from 'react';
import AddBioSide from "../profile-sides/AddBioSide";
const AddBio = ({ toggleBio }) => {
  return (
    <div>
      <AddBioSide toggleBio={toggleBio} />
    </div>
  );
};
export default AddBio;
