import React from 'react';
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import '../../css-steps/login-css/newPass.css';
import { Loader } from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordThunk } from '../../store/auth/slices';
import { setLoader } from '../../store/global/globalReducer';
import {
    getPasswordProficiency,
    hasLowercaseLetter,
    hasMinimumLength,
    hasNumber,
    hasSpecialCharacter,
    hasUppercaseLetter,
    validatePasswords
} from 'utills/FormValidation';
import { ValidatePass } from 'components/common/ValidatePass';
import { ValidateLoader } from 'components/common/ValidateLoader';
import { OpenEye } from 'utills/svgs/OpenEye';
import { CloseEye } from 'utills/svgs/CloseEye';

const NewPass = (props) => {
    const InitialValues = {
        password: '',
        confirmPassword: '',
        agreedToTerms: false
    };

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);
    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};

    const [userPassword, setUserPassword] = useState(InitialValues);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordProficiency, setPasswordProficiency] = useState('');

    const [showValidator, setShowValidator] = useState(false);

    const [atleastEightChar, setAtleastEightChar] = useState(false);
    const [oneSmallLetter, setOneSmallLetter] = useState(false);
    const [onecapitalLetter, setOneCapitalLetter] = useState(false);
    const [oneNumber, setOneNumber] = useState(false);
    const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false);
    const [setEye, setSetEye] = useState(false);
    const [confirmEye, setConfirmEye] = useState(false);

    const toggleConfirmEye = () => {
        if (userPassword.confirmPassword) {
            setConfirmEye(!confirmEye);
        }
    };
    const toggleSetEye = () => {
        if (userPassword?.password) {
            setSetEye(!setEye);
            setShowValidator(true);
        }
    };
    const [errors, setErrors] = useState(true);

    useEffect(() => {
        setErrors(!(atleastEightChar || oneSmallLetter || onecapitalLetter || oneNumber || oneSpecialCharacter));
    }, [atleastEightChar, oneSmallLetter, onecapitalLetter, oneNumber, oneSpecialCharacter]);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        if (name === 'password') {
            if (!validatePasswords(value, userPassword.confirmPassword) && userPassword.confirmPassword) {
                setPasswordMismatch(true);
            } else {
                setPasswordMismatch(false);
            }
            setAtleastEightChar(hasMinimumLength(value));
            setOneSmallLetter(hasLowercaseLetter(value));
            setOneCapitalLetter(hasUppercaseLetter(value));
            setOneNumber(hasNumber(value));
            setOneSpecialCharacter(hasSpecialCharacter(value));
            setPasswordProficiency(getPasswordProficiency(value));
            if ('strong' === getPasswordProficiency(value)) {
                setShowValidator(false);
            } else {
                setShowValidator(true);
            }
        }
        if (name === 'confirmPassword') {
            if (value.trim().length === 0) {
                setPasswordMismatch(false);
            } else if (!validatePasswords(userPassword.password, value)) {
                setPasswordMismatch(true);
            } else if (validatePasswords) {
                setPasswordMismatch(false);
            }
        }
        setUserPassword({
            ...userPassword,
            [name]: value
        });
        if (!value) {
            setPasswordProficiency('');
            setShowValidator(false);
        }
    };

    const handleCheckboxChange = (event) => {
        setUserPassword({
            ...userPassword,
            agreedToTerms: event.target.checked
        });
    };

    const handleBlur = () => {
        setShowValidator(false);
    };

    const resetPasswordData = {
        email: parsedData?.email,
        user_type: 'employee',
        password: userPassword.password,
        confirm_password: userPassword.confirmPassword
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(setLoader(true));
        dispatch(resetPasswordThunk(resetPasswordData))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    const forgotPassData = {};
                    localStorage.setItem('forgotPassData', JSON.stringify(forgotPassData));

                    props.handleCondition(6);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });
        dispatch(setLoader(false));
    };

    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2  ">
            {isLoading && <Loader />}
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px]  lg:max-w-[640px] xl:max-w-[650px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                <div className="text-center  mt-3  card-layout-title-text font-fira">New Password</div>
                <div className=" px-28 mt-[70px] ">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="default-forms">
                                {' '}
                                <label>Password</label>
                            </div>

                            <div className="password-wrapper mt-2">
                                <div className="pass-eyes">
                                    {' '}
                                    <span onClick={toggleSetEye}> {setEye ? <CloseEye /> : <OpenEye />}</span>
                                </div>
                                <input
                                    onBlur={handleBlur}
                                    type={setEye ? 'text' : 'password'}
                                    className={`px-3 validation-password !border-none`}
                                    placeholder="Enter your password"
                                    name="password"
                                    value={userPassword.password}
                                    onChange={handleChange}
                                    maxLength={30}
                                    required
                                />

                                <div className="profiency">
                                    {' '}
                                    <span
                                        className={` ${
                                            passwordProficiency === 'weak'
                                                ? 'week-password'
                                                : passwordProficiency === 'medium'
                                                ? 'medium-password'
                                                : 'strong-password'
                                        } `}
                                    >
                                        {' '}
                                        {passwordProficiency === '' ? '' : <span> {passwordProficiency} </span>}{' '}
                                    </span>{' '}
                                </div>

                                {showValidator && (
                                    <div className="password-validator shadow">
                                        <div className="flex justify-between items-center">
                                            <div className="validate-text">
                                                {' '}
                                                At least <span className="validate-special"> 8 character </span>{' '}
                                            </div>

                                            <div>{atleastEightChar ? <ValidatePass /> : <ValidateLoader />}</div>
                                        </div>
                                        <div className=" border-t mt-1"></div>
                                        <div className="flex justify-between items-center mt-2">
                                            <div className="validate-text">
                                                {' '}
                                                At least <span className="validate-special">
                                                    {' '}
                                                    one small letters
                                                </span>{' '}
                                            </div>

                                            <div>{oneSmallLetter ? <ValidatePass /> : <ValidateLoader />}</div>
                                        </div>
                                        <div className=" border-t mt-2"></div>

                                        <div className="flex justify-between items-center mt-2">
                                            <div className="validate-text">
                                                {' '}
                                                At least <span className="validate-special">
                                                    {' '}
                                                    one capital letter{' '}
                                                </span>{' '}
                                            </div>

                                            <div>{onecapitalLetter ? <ValidatePass /> : <ValidateLoader />}</div>
                                        </div>
                                        <div className=" border-t mt-2"></div>

                                        <div className="flex justify-between items-center mt-2">
                                            <div className="validate-text">
                                                {' '}
                                                At least <span className="validate-special"> one number </span>{' '}
                                            </div>

                                            <div>{oneNumber ? <ValidatePass /> : <ValidateLoader />}</div>
                                        </div>
                                        <div className=" border-t mt-2"></div>

                                        <div className="flex justify-between items-center mt-2">
                                            <div className="validate-text">
                                                {' '}
                                                At least{' '}
                                                <span className="validate-special"> one special character</span>{' '}
                                            </div>

                                            <div>{oneSpecialCharacter ? <ValidatePass /> : <ValidateLoader />}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mt-4 ">
                            <div>
                                <div className="default-forms">
                                    {' '}
                                    <label className={`${showValidator ? 'mx-2' : ''}`}>Confirm Password</label>
                                </div>
                                <div className={`  mt-2 ${showValidator ? 'mx-5' : ''}`}>
                                    <div className="confirm-password-wrap ">
                                        {' '}
                                        <div className="pass-eyes">
                                            {' '}
                                            <span onClick={toggleConfirmEye}>
                                                {' '}
                                                {confirmEye ? <CloseEye /> : <OpenEye />}
                                            </span>
                                        </div>
                                        <input
                                            type={confirmEye ? 'text' : 'password'}
                                            className={`px-3 !border-none `}
                                            placeholder="Confirm password"
                                            name="confirmPassword"
                                            value={userPassword.confirmPassword}
                                            onChange={handleChange}
                                            required
                                            maxLength={30}
                                        />
                                    </div>
                                    <div className="error-div mt-1">
                                        {' '}
                                        {passwordMismatch && (
                                            <span>Confirm Password does not match with password </span>
                                        )}{' '}
                                    </div>
                                </div>
                            </div>

                            <div className={`check mt-5 flex items-start gap-3 ${showValidator ? 'mx-2' : ''}`}></div>
                        </div>
                    </form>

                    <div className=" flex justify-between items-center  mt-44 mb-10">
                        <button
                            type="button"
                            onClick={() => props.handleCondition(1)}
                            className="btn-1 buttons-font font-fira px-14 "
                        >
                            Back
                        </button>
                        <button
                            disabled={userPassword?.password !== userPassword?.confirmPassword ? true : false}
                            onClick={handleSubmit}
                            className={`px-12 buttons-font font-fira  btn-2 rounded-lg ${
                                userPassword?.password !== userPassword?.confirmPassword ? 'opacity-40' : ''
                            }`}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPass;
