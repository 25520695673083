import { CameraIcon } from '@heroicons/react/20/solid';
import { ImageCropper } from 'global-components/ImageCropper';
import React, { useEffect, useState } from 'react';
import { UploadCamera } from 'utills/svgs/UploadCamera';

export const ImageUploader = ({ onImage, selected, selectedImage, setSelectedImage }) => {
    const [showCroper, setShowCroper] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleImage = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setCroppedImage(reader.result);
            };

            reader.readAsDataURL(file);
            setShowCroper(true);
        }
    };

    const saveImage = (image) => {
        setShowCroper(false);
        setSelectedImage(image);
        setCroppedImage(image);

        onImage(image);
    };

    useEffect(() => {
        if (selected) {
            setSelectedImage(selected);
        }
    }, [selected]);
    return (
        <div>
            {showCroper ? (
                <>
                    <ImageCropper
                        width={'250'}
                        height={'250'}
                        setShowCropper={setShowCroper}
                        image={croppedImage}
                        handleCroppedImage={(image) => saveImage(image)}
                    />
                </>
            ) : (
                ''
            )}

            <input
                type="file"
                style={{ display: 'none' }}
                accept=".jpg, .jpeg, .png"
                onChange={(e) => handleImage(e)}
                id="client-image"
                name="image"
            />
            <div>
                <div className="w-[150px] relative flex border border-third justify-center items-center h-[150px] mt-3 rounded-xl bg-gray-200  ">
                    {selectedImage ? (
                        <div className="w-full overflow-hidden rounded-xl h-full">
                            <label
                                htmlFor="client-image"
                                className="!flex items-center justify-center h-full w-full cursor-pointer"
                            >
                                <img
                                    src={selectedImage}
                                    alt="client-profile"
                                    style={{
                                        height: '-webkit-fill-available',
                                        width: '-webkit-fill-available'
                                    }}
                                />
                            </label>
                        </div>
                    ) : (
                        <label
                            htmlFor="client-image"
                            className="!flex items-center justify-center h-full w-full cursor-pointer"
                        >
                            <CameraIcon className="h-7 w-7" />
                        </label>
                    )}
                    <label htmlFor="client-image" className="absolute top-[-8%] right-[-10%] !w-auto">
                        <div className="cursor-pointer">
                            <UploadCamera />
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
};
