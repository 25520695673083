import React from 'react';

export const FolderIcon = ({ className }) => {
    return (
        <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M50.339 16.2919V15.9258C50.339 15.0244 50.1614 14.1317 49.8165 13.2989C49.4715 12.4661 48.9659 11.7094 48.3284 11.0719C47.691 10.4345 46.9343 9.9289 46.1015 9.58393C45.2686 9.23896 44.376 9.06141 43.4746 9.06141H19.1471C18.5635 7.9596 17.6915 7.03702 16.6244 6.39221C15.5572 5.7474 14.335 5.40459 13.0881 5.40039H6.86441C5.04385 5.40039 3.29786 6.1236 2.01054 7.41093C0.723212 8.69825 0 10.4442 0 12.2648V42.9441C0 44.7647 0.723212 46.5107 2.01054 47.798C3.29786 49.0853 5.04385 49.8085 6.86441 49.8085H47.1356C48.9561 49.8085 50.7021 49.0853 51.9895 47.798C53.2768 46.5107 54 44.7647 54 42.9441V22.3326C53.9925 21.0889 53.6481 19.8704 53.0035 18.8068C52.3589 17.7432 51.4381 16.8741 50.339 16.2919ZM47.5566 15.5048H47.1356H24.4373C23.5941 15.5006 22.7724 15.2389 22.0821 14.7548C21.3918 14.2706 20.8659 13.587 20.5749 12.7956L20.2271 11.8438H43.4746C44.4858 11.8465 45.4606 12.2211 46.2134 12.8963C46.9662 13.5714 47.4443 14.4999 47.5566 15.5048ZM51.2176 42.9624C51.2129 44.0453 50.7818 45.0827 50.0179 45.8501C49.2539 46.6175 48.2184 47.0531 47.1356 47.0628H6.86441C5.77207 47.0628 4.72448 46.6288 3.95209 45.8564C3.17969 45.084 2.74576 44.0364 2.74576 42.9441V12.2648C2.74576 11.1725 3.17969 10.1249 3.95209 9.35248C4.72448 8.58008 5.77207 8.14615 6.86441 8.14615H13.0881C13.9313 8.15031 14.7531 8.41201 15.4434 8.8962C16.1336 9.38038 16.6595 10.0639 16.9505 10.8553L17.9939 13.6926C18.4745 15.0161 19.3505 16.1598 20.5031 16.9686C21.6558 17.7774 23.0292 18.2122 24.4373 18.2139H47.1356C48.2279 18.2139 49.2755 18.6479 50.0479 19.4203C50.8203 20.1927 51.2542 21.2403 51.2542 22.3326L51.2176 42.9624Z"
                fill="#0A1E46"
            />
            <path
                d="M8.10823 36.3356H20.5923C20.9564 36.3356 21.3056 36.191 21.5631 35.9335C21.8205 35.676 21.9652 35.3268 21.9652 34.9627C21.9652 34.5986 21.8205 34.2494 21.5631 33.992C21.3056 33.7345 20.9564 33.5898 20.5923 33.5898H8.10823C7.74412 33.5898 7.39493 33.7345 7.13746 33.992C6.87999 34.2494 6.73535 34.5986 6.73535 34.9627C6.73535 35.3268 6.87999 35.676 7.13746 35.9335C7.39493 36.191 7.74412 36.3356 8.10823 36.3356ZM25.736 39.8136H8.10823C7.74412 39.8136 7.39493 39.9582 7.13746 40.2157C6.87999 40.4731 6.73535 40.8223 6.73535 41.1865C6.73535 41.5506 6.87999 41.8998 7.13746 42.1572C7.39493 42.4147 7.74412 42.5593 8.10823 42.5593H25.736C26.1001 42.5593 26.4493 42.4147 26.7068 42.1572C26.9643 41.8998 27.1089 41.5506 27.1089 41.1865C27.1089 40.8223 26.9643 40.4731 26.7068 40.2157C26.4493 39.9582 26.1001 39.8136 25.736 39.8136Z"
                fill="#B695F8"
            />
        </svg>
    );
};
