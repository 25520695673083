import React from 'react';

const SentIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M19.0179 4.14687C19.0221 4.11785 19.0221 4.0884 19.0179 4.05937C19.0179 4.05937 19.0179 4.04375 19.0179 4.03437C19.0087 4.01338 18.9972 3.99347 18.9836 3.975L18.9492 3.94063L18.9242 3.91563H18.9054L18.8617 3.89375L18.8086 3.875H18.7617H18.6679L1.20545 7.65625C1.14305 7.67016 1.08643 7.70287 1.0432 7.74996C0.999979 7.79706 0.972238 7.85628 0.963723 7.91964C0.955207 7.98299 0.966326 8.04743 0.99558 8.10427C1.02483 8.16111 1.07081 8.20761 1.12732 8.2375L5.98982 10.7719L6.7742 15.6875C6.77921 15.7179 6.78869 15.7474 6.80232 15.775V15.7937C6.81815 15.8216 6.83817 15.8469 6.8617 15.8688C6.88261 15.8863 6.90572 15.901 6.93045 15.9125H6.9492H6.96795C6.99902 15.9172 7.03062 15.9172 7.0617 15.9125C7.10097 15.9304 7.14354 15.94 7.1867 15.9406H7.21482L7.25857 15.9219L10.6711 13.8219L14.1804 16.1281C14.2198 16.1541 14.2645 16.1709 14.3112 16.1771C14.358 16.1834 14.4055 16.179 14.4503 16.1642C14.4951 16.1495 14.536 16.1248 14.5699 16.0921C14.6038 16.0593 14.6298 16.0193 14.6461 15.975L19.0211 4.28125C19.0327 4.24916 19.0391 4.21538 19.0398 4.18125C19.0398 4.18125 19.0179 4.15312 19.0179 4.14687ZM8.08045 11.6187C8.06336 11.6317 8.04766 11.6463 8.03357 11.6625C8.01025 11.6914 7.99226 11.7242 7.98045 11.7594L7.18045 14.2594L6.61795 10.7406L15.4086 6.20938L8.08045 11.6187ZM16.4523 4.96875L6.2742 10.2156L2.1867 8.08125L16.4523 4.96875ZM7.6367 14.9313L8.45545 12.3562L10.0836 13.4406L7.6367 14.9313ZM14.1992 15.375L10.8617 13.1875L8.84295 11.8625L18.0461 5.03437L14.1992 15.375Z"
                fill="#B695F8"
                stroke="#B695F8"
                strokeWidth="0.3"
            />
        </svg>
    );
};

export default SentIcon;
