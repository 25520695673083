import moment from 'moment';

import Cookies from 'js-cookie';

export const filterArrayByDate = (dataArray, searchDate) => {
    if (!moment(searchDate, moment.ISO_8601, true).isValid()) {
        return [];
    }

    const filteredArray = dataArray.filter((item) => {
        if (moment(item.created_at, moment.ISO_8601, true).isValid()) {
            const itemDate = moment(item.created_at);
            const searchDateMoment = moment(searchDate);
            return itemDate.isSame(searchDateMoment, 'day');
        } else {
            return false;
        }
    });

    return filteredArray;
};

export const sortItemsAlphabetically = (dataArray, targetItem) => {
    const sortedItems = [...dataArray].sort((a, b) => {
        return a?.[targetItem].localeCompare(b?.[targetItem]);
    });
    return sortedItems;
};

export const sortByKey = (array, key) => {
    return array.sort((a, b) => a[key] - b[key]);
};

export const clearAllCookies = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookieName) => {
        Cookies.remove(cookieName, { path: '/' });
    });
};
