import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { request: createOnboardingServicesThunk } = generateThunkAndSlice(
    'createOnboardingServices',
    endpoint.createOnboardingServices,
    methods.POST
);

const { slice: getOnboardingServices, request: getOnboardingServicesThunk } = generateThunkAndSlice(
    'getOnboardingServices',
    endpoint.getOnboardingServices,
    methods.GET
);
const { slice: getIconList, request: getIconListThunk } = generateThunkAndSlice(
    'getIconList',
    endpoint.getIconList,
    methods.GET
);
const { request: updateOnboardingServicesThunk } = generateThunkAndSlice(
    'updateOnboardingServices',
    endpoint.updateOnboardingServices,
    methods.PUT
);
const { request: toggleOnboardingServiceThunk } = generateThunkAndSlice(
    'toggleOnboardingService',
    endpoint.toggleOnboardingService,
    methods.PUT
);
const { request: deleteOnboardingServicesThunk } = generateThunkAndSlice(
    'deleteOnboardingServices',
    endpoint.deleteOnboardingServices,
    methods.DELETE
);

export {
    createOnboardingServicesThunk,
    getOnboardingServices,
    getOnboardingServicesThunk,
    getIconList,
    getIconListThunk,
    updateOnboardingServicesThunk,
    toggleOnboardingServiceThunk,
    deleteOnboardingServicesThunk
};
