import React from 'react';
import { useEffect, useState } from 'react';
import './css/organizationalSettings.css';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { archivePrebuildChecklistThunk, getAllPrebuiltListThunk } from 'store/workspace/workspaceTasks';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import ConfirmationModal from 'utills/confirmationModal';
import { cn } from 'utills/tailwindUtil';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { sortItemsAlphabetically } from 'utills/sortAndFilter';
import TreeView2 from 'components/atoms/TreeView/TreeView2';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';

const OSPrebuitList = ({ typesUpdated, setEditId }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();
    const { data: checklListData } = useSelector((state) => state?.getAllPrebuiltList);
    const filterData = checklListData ? sortItemsAlphabetically(checklListData, 'label') : [];

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const [removeId, setRemoveId] = useState('');

    const [_typeLoader, setTypeLoader] = useState(false);

    const getAllList = (payload) => {
        dispatch(setLoader(true));
        dispatch(getAllPrebuiltListThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        const payload = {
            business_id
        };
        getAllList(payload);
    }, [typesUpdated]);

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        setTypeLoader(false);
        const payload = { pre_checklist_id: removeId };
        dispatch(archivePrebuildChecklistThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const payload = {
                        business_id
                    };
                    getAllList(payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setTypeLoader(false);
            });
    };
    const deleteType = (pre_checklist_id) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        setIsConfirmationOpen(true);
        setRemoveId(pre_checklist_id);
    };

    const handleEdit = (id) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        if (id) {
            setEditId(id);
        }
    };

    return (
        <div className="mx-5 ">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <div className="mt-5  mx-5 bg-white rounded-xl p-5">
                <div className="w-full">
                    <div className="buttons-font font-poppins text-md grid  grid-cols-2 gap-5 items-baseline">
                        <div className="flex gap-2 items-center">
                            <div>Checklist Title</div>
                        </div>

                        <div className="flex gap-2 items-center justify-end">Action</div>
                    </div>

                    <hr className="mt-2" />
                    {filterData &&
                        filterData?.map((data, ind) => (
                            <div key={'role_index_' + ind} className="">
                                <div className=" flex flex-row gap-5 border-y buttons-font-lighter font-poppins items-baseline">
                                    <div
                                        className={cn(
                                            'whitespace-nowrap1 py-4 pr-3 buttons-font-lighter',
                                            'text-gray-900 flex-1'
                                        )}
                                    >
                                        <div className="my-4">
                                            <TreeView2 dataObj={data} sx="bg-[#faf9f6]" />
                                        </div>
                                    </div>

                                    <div className="whitespace-nowrap py-2 pl-2 pr-3 text-sm font-medium sm:pr-3 flex justify-end">
                                        <div className="flex gap-2">
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    handleEdit(data.pre_checklist_id);
                                                }}
                                            >
                                                <BgEditIcon className={'w-[36px] h-[32px]'} />
                                            </div>
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    deleteType(data.pre_checklist_id);
                                                }}
                                            >
                                                <BgDeleteIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default OSPrebuitList;
