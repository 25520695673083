import React from 'react';

export const BlueTimes = () => {
    return (
        <div>
            <svg width="26" height="24" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="36" height="30" rx="15" fill="#B695F8" />
                <g clipPath="url(#clip0_1418_782)">
                    <path
                        d="M9.95087 6.03886C9.58525 6.10918 9.26884 6.35879 9.11064 6.69629C9.0333 6.86152 9.01572 6.9459 9.01572 7.17793C9.01572 7.72285 8.77666 7.45215 12.795 11.467L16.3458 15.0178L12.7634 18.6037C9.85595 21.5182 9.17041 22.2213 9.10361 22.3654C8.98408 22.615 8.98056 23.065 9.09658 23.3146C9.19853 23.5361 9.42353 23.7682 9.64853 23.8877C9.90166 24.0178 10.306 24.0353 10.5977 23.9299C10.8017 23.8525 10.9282 23.733 14.4052 20.2596L17.9981 16.6701L21.577 20.242C24.4493 23.1107 25.1876 23.8314 25.3282 23.8982C25.4759 23.9686 25.5567 23.9826 25.838 23.9826C26.1298 23.9826 26.1931 23.9686 26.3477 23.8877C26.5657 23.7752 26.8013 23.5256 26.9032 23.3041C27.0157 23.065 27.0087 22.6115 26.8927 22.3654C26.8259 22.2213 26.1403 21.5182 23.2329 18.6037L19.6505 15.0178L23.2013 11.467C27.2196 7.45215 26.9806 7.72285 26.9806 7.17793C26.9806 6.94941 26.963 6.86152 26.8892 6.70332C26.6044 6.09863 25.8907 5.85254 25.2931 6.14785C25.2017 6.19355 23.9185 7.44863 21.577 9.79004L17.9981 13.3654L14.4227 9.79004C12.0778 7.44863 10.7946 6.19355 10.7032 6.14785C10.5837 6.08808 10.1935 5.98965 10.1267 6.00371C10.1161 6.00722 10.0352 6.02129 9.95087 6.03886Z"
                        fill="#B695F8"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1418_782">
                        <rect width="18" height="18" fill="white" transform="translate(9 6)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
