import React from 'react';

export const ArrowRight = () => {
    return (
        <div>
            {' '}
            <svg width="8" height="8" viewBox="0 0 5 9" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.165329 0.167732C0.385768 -0.0559107 0.74317 -0.0559107 0.963608 0.167732L4.83467 4.09506C5.05511 4.3187 5.05511 4.6813 4.83467 4.90494L0.963608 8.83227C0.74317 9.05591 0.385768 9.05591 0.165329 8.83227C-0.0551097 8.60863 -0.0551097 8.24603 0.165329 8.02239L3.63725 4.5L0.165329 0.977614C-0.0551097 0.753971 -0.0551097 0.391375 0.165329 0.167732Z"
                />
            </svg>
        </div>
    );
};
