import { Button } from 'components/atoms/buttons/Button';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import { Loader } from 'components/common/Loader';
import Recaptcha from 'components/molecules/Recaptcha';
import { useCaptchaHandler } from 'Hooks/reCaptcha/useCaptchaHandler';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { accessauthEmailSendOtpThunk, forgetPassOtpAccessThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

export const EmailOtp = ({ handleCondition }) => {
    const [timeRemaining, setTimeRemaining] = useState(90);
    const { data } = useSelector((state) => state.login);
    const { error, setError, isCaptchaRequired, recaptchaRef, handleRequest, handleCaptchaChange } =
        useCaptchaHandler();

    const [timerEnded, setTimerEnded] = useState(false);
    const [otpVal, setOtpVal] = useState('');
    const { isLoading } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        timeExecution();
    }, []);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    const handleSubmit = () => {
        if (otpVal?.length < 6) {
            toastHandler('Please enter full otp', TOAST_TYPE_ERROR);
            return;
        }

        dispatch(setLoader(true));
        dispatch(
            forgetPassOtpAccessThunk({
                otp: otpVal
            })
        )
            .then((response) => {
                if (response.payload) {
                    handleCondition(2);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const resendOtp = async () => {
        const forgotPassData = {
            user_id: data?.user_id,
            user_type: 'employee',
            type: 1
        };

        dispatch(setLoader(true));
        // dispatch(accessauthEmailSendOtpThunk(forgotPassData))

        await handleRequest(
            'accessauthEmailSendOtp',
            (payload = {}) =>
                dispatch(
                    accessauthEmailSendOtpThunk({
                        ...payload
                    })
                ),
            forgotPassData
        )
            .then((response) => {
                if (response?.payload) {
                    setTimeRemaining(90);
                    setTimerEnded(false);
                    timeExecution();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <div>
            {' '}
            <div className="flex min-h-[60vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] bg-white shadow rounded-xl sm:rounded-2xl  py-4 ">
                    {isLoading && <Loader />}{' '}
                    <div className="card-layout-title-text text-center  mt-5   ">Email OTP Verification</div>
                    <div className="  mx-28  mt-2">
                        <div className="text-sixth text-sm secondary-fonts ">
                            <p className="p-1">
                                Please enter the six-digit code that has been sent to your email address.
                            </p>
                        </div>

                        <div className="mt-12">
                            <OtpInputs value={otpVal} onChange={handleOtpChange} />
                        </div>

                        {isCaptchaRequired && (
                            <div className="my-3">
                                <Recaptcha
                                    ref={recaptchaRef}
                                    captchaError={!!error}
                                    setCaptchaError={setError}
                                    onValueChange={handleCaptchaChange}
                                />
                            </div>
                        )}
                        <div className="resend-confirmation text-sm font-poppins mt-5">
                            <p>
                                {timerEnded ? (
                                    <span className="text-thirdbg  cursor-pointer" onClick={resendOtp}>
                                        Resend
                                    </span>
                                ) : (
                                    <span>
                                        <span>
                                            <span className="text-thirdbg  cursor-pointer mr-1">Resend</span>
                                            OTP in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`})
                                        </span>
                                    </span>
                                )}
                            </p>
                        </div>

                        <div className="mt-48 mb-5">
                            <div className="flex justify-between items-center px-1-">
                                <Button
                                    atClick={() => handleCondition(0)}
                                    title="Cancel"
                                    classes="border border-secondarybg !rounded-lg h-[3rem] text-md primary-fonts text-secondarybg px-14 rounded-md py-2"
                                />

                                <Button
                                    atClick={() => handleSubmit()}
                                    title="Verify"
                                    classes="ann-btn px-14 !rounded-lg py-2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
