import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { HintIcon } from 'utills/svgs/HintIcon';
import { cn } from 'utills/tailwindUtil';

export default function DefaultHint({ children, sx, hindSx }) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="flex items-center rounded-full bg-gray-100 text-[#979797] hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <HintIcon {...hindSx} />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={cn(
                        sx,
                        ' absolute right-0 z-10 mt-2 w-[350px] break-words origin-top-right rounded-md bg-white shadow-lg ring-2 ring-black ring-opacity-5 focus:outline-none'
                    )}
                >
                    <div className="!py-4 px-2 text-center text-sm font-normal">{children}</div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
