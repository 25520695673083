import React, { useEffect, useState } from 'react';

export const BsbInput = ({ onChange, value, placeholder, onError = () => {}, disabled }) => {
    const [bsb, setBsb] = useState('');
    const [bsbError, setBsbError] = useState(false);

    useEffect(() => {
        if (value) {
            setBsb(value);
        }
    }, [value]);
    const handleValueChange = (e) => {
        let { value } = e.target;

        value = value.replace(/[^0-9]/g, '');

        if (value.length <= 6) {
            setBsb(value);
            onChange(value);

            if (value.length === 6) {
                setBsbError(false);
            } else {
                setBsbError(true);
            }
        }
    };

    useEffect(() => {
        onError(bsb?.length > 0 ? bsbError : false);
    }, [bsb]);
    return (
        <div className="default-forms ">
            <div>
                {' '}
                <input
                    type="text"
                    name=""
                    id=""
                    onChange={(e) => {
                        handleValueChange(e);
                    }}
                    placeholder={placeholder}
                    className={`${!disabled ? '' : 'bg-gray-200 !border-none !cursor-no-drop'}  ${
                        bsbError && bsb ? 'add-error-border' : ''
                    } `}
                    value={bsb}
                    disabled={disabled}
                />
            </div>

            {bsb && bsbError ? <div className="text-sm text-red-500">Invalid BSB number</div> : ''}
        </div>
    );
};
