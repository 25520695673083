import React from 'react';
import './App.css';
import { MobileModal } from './utills/base-components/MobileModal';
import { RoutesManager } from 'routes/routes-manager/RoutesManager';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'utills/confirmationModal';
import { setIsConfirmationOpen } from 'store/global/globalReducer';
import { useLogout } from 'Hooks/useLogout';
import Notification from 'components/Notifications/Notifications';
import { Loader } from 'components/common/Loader';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { getCookie } from 'utills/encryption/cryptoUtils';
import { CSRF_TOKEN } from 'utills/globalVars';

function App() {
    const dispatch = useDispatch();
    const { isConfirmationOpen } = useSelector((state) => state.global);
    const { data: loginData } = useSelector((state) => state.login);
    const { logLoader } = useSelector((state) => state.global);
    const { logout } = useLogout();
    const sidebarLog = true;
    const timeoutLogout = true;

    const { getCSRFTokenFun } = useLogout();
    const csrfToken = getCookie(CSRF_TOKEN);
    useEffect(() => {
        if (!csrfToken) {
            getCSRFTokenFun();
        }
    }, []);

    //
    return (
        <div className="">
            {logLoader && <Loader />}
            {loginData?.access_token && <Notification />}
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => dispatch(setIsConfirmationOpen(false))}
                onConfirm={() => logout(setIsConfirmationOpen, sidebarLog, timeoutLogout)}
                description="Are you sure you want to logout from your account ?"
                confirmbtnTxt="Yes"
                cancelBtnTxt="No"
                type="logout"
                title="Logout"
                sidebarLog={true}
            />
            <MobileModal />
            <RoutesManager />
        </div>
    );
}

export default Sentry.withProfiler(App);
