import React from 'react';

const SortIcon2 = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 5.69767C20 6.08299 19.6876 6.39535 19.3023 6.39535H0.697674C0.312363 6.39535 0 6.08299 0 5.69767C0 5.31236 0.312363 5 0.697674 5H19.3023C19.6876 5 20 5.31236 20 5.69767ZM17.2093 10.3488C17.2093 10.7341 16.8969 11.0465 16.5116 11.0465H3.48837C3.10306 11.0465 2.7907 10.7341 2.7907 10.3488C2.7907 9.96353 3.10306 9.65116 3.48837 9.65116H16.5116C16.8969 9.65116 17.2093 9.96353 17.2093 10.3488ZM14.4186 15C14.4186 15.3853 14.1062 15.6977 13.7209 15.6977H6.27907C5.89376 15.6977 5.5814 15.3853 5.5814 15C5.5814 14.6147 5.89376 14.3023 6.27907 14.3023H13.7209C14.1062 14.3023 14.4186 14.6147 14.4186 15Z"
                fill="#B695F8"
            />
        </svg>
    );
};

export default SortIcon2;
