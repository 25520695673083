/* eslint-disable react/style-prop-object */
import React from 'react';

export const SendMailIcon2 = ({ className }) => {
    return (
        <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M79.125 26.325V26.4731L79.2426 26.5632L89.7 34.5731V85.2H0.3V34.5731L10.7574 26.5632L10.875 26.4731V26.325V15.375H25.2H25.3012L25.3817 15.3137L45 0.377056L64.6183 15.3137L64.6988 15.375H64.8H79.125V26.325ZM45.1821 5.38657L45 5.24753L44.8179 5.38657L32.4429 14.8366L31.7378 15.375H32.625H57.375H58.2622L57.5571 14.8366L45.1821 5.38657ZM14.85 18.6H14.55V18.9V45.45V45.6004L14.6705 45.6903L33.3455 59.6403L33.5271 59.776L33.7072 59.6383L44.9995 51.0031L56.5171 59.8628L56.6999 60.0034L56.8828 59.8629L75.3328 45.6879L75.45 45.5978V45.45V18.9V18.6H75.15H14.85ZM4.31582 36.4382L4.2 36.5283V36.675V38.025V38.1761L4.32143 38.2661L10.3964 42.7661L10.875 43.1206V42.525V31.95V31.3366L10.3908 31.7132L4.31582 36.4382ZM4.2 81V81.3H4.5H5.85H5.95227L6.03324 81.2375L29.6582 63.0125L29.9673 62.7741L29.6574 62.5368L4.68239 43.4118L4.2 43.0424V43.65V81ZM13.3168 80.7625L12.62 81.3H13.5H76.5H77.38L76.6832 80.7625L45.1832 56.4625L45 56.3211L44.8168 56.4625L13.3168 80.7625ZM85.5 81.3H85.8V81V43.65V43.0424L85.3176 43.4118L60.3426 62.5368L60.0327 62.7741L60.3418 63.0125L83.9668 81.2375L84.0477 81.3H84.15H85.5ZM85.6786 38.2661L85.8 38.1761V38.025V37.125V36.9783L85.6842 36.8882L79.6092 32.1632L79.125 31.7866V32.4V42.525V43.1206L79.6036 42.7661L85.6786 38.2661Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.6"
            />
            <path
                d="M30.2257 28.9516V25.2766H59.7757V28.9516H30.2257ZM22.3508 37.9516V33.8266H67.4257V37.9516H22.3508ZM22.3508 46.5016V42.3766H67.4257V46.5016H22.3508Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.6"
            />
        </svg>
    );
};
