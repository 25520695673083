import React from 'react'

export const WhiteMinus = () => {
  return (
    <div><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.80312 7.35308C1.52188 7.44058 1.34375 7.69058 1.34375 7.99995C1.34375 8.3187 1.54063 8.5812 1.83125 8.65308C2.02188 8.69995 13.9781 8.69995 14.1687 8.65308C14.4594 8.5812 14.6562 8.3187 14.6562 7.99995C14.6562 7.6812 14.4594 7.4187 14.1687 7.34683C13.9688 7.29683 1.96562 7.30308 1.80312 7.35308Z" fill="white" stroke="white"/>
    </svg>
    </div>
  )
}
