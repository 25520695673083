import React from 'react';

export const LargeTaskIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.1818 5.46212H14.8182C9.65097 5.46212 5.46212 9.65097 5.46212 14.8182V56.1818C5.46212 61.349 9.65097 65.5379 14.8182 65.5379H56.1818C61.349 65.5379 65.5379 61.349 65.5379 56.1818V14.8182C65.5379 9.65097 61.349 5.46212 56.1818 5.46212ZM14.8182 3C8.29118 3 3 8.29118 3 14.8182V56.1818C3 62.7088 8.29118 68 14.8182 68H56.1818C62.7088 68 68 62.7088 68 56.1818V14.8182C68 8.29118 62.7088 3 56.1818 3H14.8182Z"
                fill="black"
            />
            <path
                d="M38.4545 24.9128C38.4545 24.2329 39.0057 23.6818 39.6856 23.6818H53.9659C54.6458 23.6818 55.197 24.2329 55.197 24.9128C55.197 25.5927 54.6458 26.1439 53.9659 26.1439H39.6856C39.0057 26.1439 38.4545 25.5927 38.4545 24.9128Z"
                fill="black"
            />
            <path
                d="M38.4545 45.5947C38.4545 44.9148 39.0057 44.3636 39.6856 44.3636H53.9659C54.6458 44.3636 55.197 44.9148 55.197 45.5947C55.197 46.2746 54.6458 46.8258 53.9659 46.8258H39.6856C39.0057 46.8258 38.4545 46.2746 38.4545 45.5947Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.7797 20.5233C31.1643 20.9079 31.1643 21.5315 30.7797 21.9161L22.4085 30.2873C22.0239 30.6719 21.4003 30.6719 21.0157 30.2873L18.0612 27.3328C17.6766 26.9482 17.6766 26.3246 18.0612 25.94C18.4458 25.5554 19.0694 25.5554 19.454 25.94L21.7121 28.1981L29.3869 20.5233C29.7716 20.1387 30.3951 20.1387 30.7797 20.5233Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.7797 40.7126C31.1643 41.0973 31.1643 41.7208 30.7797 42.1054L22.4085 50.4766C22.0239 50.8613 21.4003 50.8613 21.0157 50.4766L18.0612 47.5221C17.6766 47.1375 17.6766 46.5139 18.0612 46.1293C18.4458 45.7447 19.0694 45.7447 19.454 46.1293L21.7121 48.3875L29.3869 40.7126C29.7716 40.328 30.3951 40.328 30.7797 40.7126Z"
                fill="black"
            />
        </svg>
    );
};
