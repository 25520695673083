import './ImageZoomer.css';
import { CloudArrowDownIcon, MinusCircleIcon, PlusCircleIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const ZoomImage = ({ src, alt, onClose, isLocal }) => {
    const [zoomLevel, setZoomLevel] = useState(100);
    const [rotationAngle, setRotationAngle] = useState(0);

    const handleZoomIn = () => {
        setZoomLevel(Math.min(zoomLevel + 10, 200));
    };

    const handleZoomOut = () => {
        setZoomLevel(Math.max(zoomLevel - 10, 10));
    };

    const handleRotate = () => {
        setRotationAngle((prevAngle) => prevAngle + 90);
    };

    return (
        <div className="modal-zoom">
            <div className="absolute left-5 top-1/2 transform -translate-y-1/2 !w-[50px] bg-thirdbg text-white p-2 rounded-lg buttons-font font-poppins">
                <div onClick={handleZoomIn} className="cursor-pointer">
                    <PlusCircleIcon />
                </div>
                <div className="my-5">{zoomLevel}%</div>
                <div onClick={handleZoomOut} className="cursor-pointer">
                    <MinusCircleIcon />
                </div>
                <div onClick={handleRotate} className="cursor-pointer mt-5">
                    <ArrowPathIcon />
                </div>
                {isLocal ? (
                    ''
                ) : (
                    <div className="mt-5">
                        <Link to={src} className="cursor-pointer">
                            <CloudArrowDownIcon />
                        </Link>
                    </div>
                )}
            </div>

            <span className="close" onClick={onClose}>
                &times;
            </span>
            <div
                style={{
                    width: 700,
                    height: 700,
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <img
                    className="modal-content"
                    src={src}
                    alt={alt}
                    style={{ transform: `scale(${zoomLevel / 100}) rotate(${rotationAngle}deg)` }}
                />
            </div>
        </div>
    );
};
