import React from 'react';
import { useEffect, useState } from 'react';
import { useRequiredSigningContext } from './provider';
import { SigningFieldContainer } from './signing-field-container';

export const SignatureField = ({ field, recipient, _newData }) => {
    const { signature: providedSignature } = useRequiredSigningContext();
    const [fieldData, setfieldData] = useState({});

    useEffect(() => {
        setfieldData(field);
    }, [field]);

    useEffect(() => {
        if (!providedSignature) {
            return;
        }
        const tempData = {
            ...fieldData,
            field_value: providedSignature,
            inserted: true
        };
        setfieldData(tempData);
    }, []);
    const onSign = async () => {
        if (!providedSignature) {
            return;
        }
        const tempData = {
            ...fieldData,
            field_value: providedSignature,
            inserted: true
        };
        setfieldData(tempData);
    };

    const onRemove = async () => {
        const tempData = {
            ...fieldData,
            field_value: '',
            inserted: false
        };
        setfieldData(tempData);
    };

    return (
        <SigningFieldContainer field={fieldData} onSign={onSign} onRemove={onRemove}>
            {!fieldData.inserted && (
                <p className="group-hover:text-primary font-signature text-muted-foreground text-sm duration-200 ">
                    Signature
                </p>
            )}

            {fieldData.inserted && fieldData?.field_value && (
                <img
                    src={fieldData?.field_value}
                    alt={`Signature for ${recipient.name}`}
                    className="h-full w-full object-contain "
                />
            )}
        </SigningFieldContainer>
    );
};
