import React from 'react';

const PendingEye = ({ className }) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_20373_275)">
                <path
                    d="M6.20141 14.8289C6.15275 14.8356 6.1034 14.8356 6.05474 14.8289C4.90908 14.4685 3.87578 13.8187 3.05474 12.9422C2.23261 12.065 1.65051 10.9906 1.36464 9.82283C1.07876 8.65506 1.09878 7.43331 1.42274 6.27552C1.74616 5.11801 2.36215 4.06326 3.21141 3.21285C4.0603 2.36379 5.11385 1.74806 6.27025 1.42515C7.42664 1.10224 8.64678 1.08308 9.81274 1.36952C10.9787 1.65682 12.0508 2.24006 12.9254 3.06285C13.8002 3.88695 14.4481 4.92241 14.8067 6.06952C14.8377 6.1637 14.8398 6.26502 14.8126 6.36037C14.7854 6.45572 14.7301 6.5407 14.6541 6.60433C14.578 6.66796 14.4847 6.70729 14.386 6.71725C14.2873 6.72721 14.188 6.70733 14.1007 6.66019C13.9838 6.5991 13.8953 6.49483 13.8541 6.36952C13.5513 5.38013 12.9978 4.48585 12.2474 3.77352C11.4976 3.06207 10.5765 2.55663 9.57368 2.30629C8.57083 2.05596 7.52028 2.06924 6.52407 2.34485C5.52784 2.62166 4.62005 3.15123 3.88874 3.88219C3.15672 4.61384 2.62617 5.52222 2.34853 6.51926C2.07088 7.5163 2.05557 8.56816 2.30407 9.57285C2.55102 10.5775 3.05381 11.5011 3.76352 12.2538C4.47323 13.0065 5.3657 13.5626 6.35408 13.8682C6.42022 13.8891 6.48144 13.9233 6.53401 13.9686C6.58659 14.0139 6.62944 14.0693 6.65997 14.1317C6.69051 14.194 6.70809 14.2618 6.71166 14.3311C6.71523 14.4004 6.70471 14.4697 6.68074 14.5349C6.6394 14.6263 6.57151 14.7031 6.48591 14.7554C6.4003 14.8077 6.30094 14.8331 6.20074 14.8282"
                    fill="#F08201"
                />
                <path
                    d="M11.695 15.3333C10.7309 15.3333 9.86216 15.0862 9.08883 14.592C8.31549 14.0984 7.7312 13.4566 7.33594 12.6667C7.7312 11.8767 8.31549 11.235 9.08883 10.7413C9.86216 10.2471 10.7309 10 11.695 10C12.6592 10 13.5279 10.2471 14.3013 10.7413C15.0746 11.2356 15.6589 11.8773 16.0542 12.6667C15.6589 13.4566 15.0746 14.0984 14.3013 14.592C13.5279 15.0862 12.6592 15.3333 11.695 15.3333ZM11.695 14.5467C12.3996 14.5467 13.0556 14.3819 13.663 14.0524C14.2705 13.723 14.7836 13.261 15.2026 12.6667C14.7836 12.0729 14.2705 11.611 13.663 11.2809C13.0556 10.9508 12.3996 10.7861 11.695 10.7867C10.9905 10.7873 10.3345 10.952 9.72705 11.2809C9.11964 11.6098 8.60675 12.0717 8.18838 12.6667C8.60734 13.2604 9.12053 13.7224 9.72794 14.0524C10.3353 14.3825 10.9913 14.5476 11.6959 14.5476M11.7004 13.6587C11.9765 13.6587 12.21 13.5618 12.4008 13.368C12.5922 13.1742 12.6879 12.9393 12.6879 12.6631C12.6879 12.3864 12.591 12.1523 12.3973 11.9609C12.2035 11.7707 11.9685 11.6756 11.6924 11.6756C11.4156 11.6756 11.1816 11.7721 10.9902 11.9653C10.7999 12.1591 10.7048 12.3941 10.7048 12.6702C10.7048 12.947 10.8014 13.181 10.9946 13.3724C11.1884 13.5633 11.4242 13.6587 11.7004 13.6587Z"
                    fill="#F08201"
                />
                <path d="M8 5.33398V8.00065H10.6667" stroke="#F08201" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_20373_275">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default PendingEye;
