import React from 'react';

export const DefaultDoubleSwitch = ({ items, onClick, disabled }) => {
    return (
        <div
            className={`field-switch-wrapper !text-[15px] md:text-[17px] lg:text-[17px] xl:text-[18px] font-[400] mt-2 cursor-pointer !rounded-xl ${
                disabled && 'opacity-50 !cursor-no-drop	'
            }`}
        >
            {items?.map((item, i) => (
                <div
                    key={('item', i)}
                    className={`field-switch ${
                        item?.selected && 'active'
                    } flex justify-center items-center  !rounded-xl`}
                    onClick={() => !disabled && onClick(item?.title)}
                >
                    {item?.title}
                </div>
            ))}
        </div>
    );
};
