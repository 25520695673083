import React from 'react';
import { useEffect, useState } from 'react';
import RoleOperations from './RoleOperations';
import RoleTable from './RoleTable';
import './css/rolemanagement.css';
import { fetchRoleGroupsThunk, updateBusinessGroupstatusThunk } from 'store/settings/team/team';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import ConfirmationModal from 'utills/confirmationModal';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { Jumbotron } from 'components/organisms/Jumbotron';

const RoleContent = ({ toggleAddRoleGroup, toggleEditRoleGroup, showAddRoleGroup }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);
    const { data: rolsData } = useSelector((state) => state.fetchRoleGroups);
    const [searchValue, setSearchValue] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const { hasPermissions } = useRoleGuard();
    const [filteredData, setFilteredData] = useState([]);

    const handleSearchValue = (value) => {
        setSearchValue(value);
    };

    useEffect(() => {
        const filteredDatas =
            rolsData && searchValue !== ''
                ? rolsData?.business?.business_groups.filter((rol) =>
                      rol?.business_group_name.toLowerCase().includes(searchValue.toLowerCase())
                  )
                : rolsData?.business?.business_groups;

        setFilteredData(filteredDatas);
    }, [searchValue, rolsData]);
    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(fetchRoleGroupsThunk())
            .then(() => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    const handleDelete = async () => {
        setIsConfirmationOpen(false);
        try {
            dispatch(setLoader(true));

            const promises = selectedRoles.map((item) => {
                const payload = {
                    business_group_id: item.business_group_id,
                    status: item.status
                };
                return dispatch(updateBusinessGroupstatusThunk(payload));
            });

            await Promise.all(promises);

            await dispatch(fetchRoleGroupsThunk());

            setSelectedRoles((prev) => {
                return prev.filter((_, index) => !selectedRoles.includes(selectedRoles[index]));
            });
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(setLoader(false));
        }
    };

    const deleteSelect = async () => {
        if (hasPermissions('Role_management', ['admin'])) {
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    return (
        <div>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            {isLoading && <Loader />}

            <Jumbotron
                title="Role Management"
                path={[
                    { title: 'Settings', link: 'settings' },
                    { title: 'Role Management', link: 'team/rolemanagement' }
                ]}
            />
            <div>
                <RoleOperations
                    toggleAddRoleGroup={toggleAddRoleGroup}
                    handleSearchValue={handleSearchValue}
                    deleteSelect={deleteSelect}
                    count={selectedRoles.length}
                    isVisible={selectedRoles.length > 0 ? true : false}
                    showAddRoleGroup={showAddRoleGroup}
                    searchValue={searchValue}
                />
            </div>
            <div>
                <RoleTable
                    data={rolsData && filteredData}
                    selectedRoles={selectedRoles}
                    setSelectedRoles={setSelectedRoles}
                    toggleEditRoleGroup={toggleEditRoleGroup}
                />
            </div>
        </div>
    );
};
export default RoleContent;
