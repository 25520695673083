import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import { ChipArrow } from 'utills/svgs/ChipArrow';
import { BUSINESS_ID, PRIORITY_OPTIONS, TOAST_TYPE_ERROR } from 'utills/globalVars';
import AvatarGroup from 'components/atoms/AvatarGroup';
import { TrashIcon } from 'utills/svgs/TrashIcon';
import { EditPencilIcon } from 'utills/svgs/EditPencilIcon';
import DataTable from 'components/atoms/DataTable';
import { createColumnHelper } from '@tanstack/react-table';
import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import { useDispatch, useSelector } from 'react-redux';
import { ClockIcon, StarIcon } from '@heroicons/react/24/outline';
import { isDatePastDue } from 'utills/moment';
import ConfirmationModal from 'utills/confirmationModal';
import { Button } from 'components/atoms/buttons/Button';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { cn } from 'utills/tailwindUtil';
import MoveOrCopy from 'components/job-manager-components/job-manager-detail-components/work-station/Documents/MoveOrCopy';
import { JobDocumentCountThunk } from 'store/JobManager';
import { setLoader } from 'store/global/globalReducer';
import Pulse from 'components/atoms/Pulse';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';
import { NoDataFound } from 'components/common/NoDataFound';

const columnHelper = createColumnHelper();

const JobsTable = ({
    jobs,
    jobStatuses,
    handleUpdateValues,
    setViewList,
    setAllClients,
    currentTab,
    handleOpenConfirmation,
    setSelectedJob,
    setCreateJob,
    ReturnSortIcons,
    handleSelectPeople,
    selectedPeople,
    setSelectedPeople,
    handleMarkFavorite,
    handleDelete,
    colsMeta
}) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [CutORCopy, setCutORCopy] = useState(null);
    const [jobCloseModal, setJobCloseModal] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [job_id, setJob_id] = useState(null);
    const [showMoveClientBtn, setShowMoveClientBtn] = useState(false);

    const { hasPermissions } = useRoleGuard();

    const getJobDocmentsCount = async (job_id) => {
        dispatch(setLoader(true));
        return await dispatch(JobDocumentCountThunk({ job_id, business_id }))
            .then((response) => {
                if (response.payload) {
                    return response.payload;
                } else {
                    return null;
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleRemoveAction = async (row) => {
        if (row) {
            if (!hasPermissions('Job', ['write', 'admin'])) {
                return toastHandler('You dont have permission to do this action.', TOAST_TYPE_ERROR);
            }

            const totalCount = await getJobDocmentsCount(row.original.job_id);
            if (totalCount?.count && totalCount?.count > 0) {
                setShowMoveClientBtn(true);
            } else {
                setShowMoveClientBtn(false);
            }
            handleOpenConfirmation([row.original.job_id]);
            setJob_id(row.original.job_id);
            setIsConfirmationOpen(true);
        }
    };

    const columns = [
        ...(colsMeta?.includes('job ref no')
            ? [
                  columnHelper.accessor('ref_no', {
                      header: () => (
                          <div className="flex gap-2 items-center">
                              <CheckboxInput
                                  checked={
                                      !isLoading &&
                                      jobs?.list?.length > 0 &&
                                      selectedPeople?.length === jobs?.list?.length
                                  }
                                  atChange={(_e) => {
                                      if (!hasPermissions('Job', ['write', 'admin'])) {
                                          return toastHandler(
                                              'You dont have permission to do this action.',
                                              TOAST_TYPE_ERROR
                                          );
                                      }
                                      handleSelectPeople();
                                  }}
                              />

                              <div>Job Ref No.</div>
                          </div>
                      ),
                      cell: ({ row }) => (
                          <div className="flex gap-2 items-center relative">
                              <CheckboxInput
                                  checked={selectedPeople?.some((person) => person?.job_id === row?.original?.job_id)}
                                  atChange={(_e) => {
                                      if (!hasPermissions('Job', ['write', 'admin'])) {
                                          return toastHandler(
                                              'You dont have permission to do this action.',
                                              TOAST_TYPE_ERROR
                                          );
                                      }
                                      const jobId = row?.original?.job_id;
                                      setSelectedPeople((prevSelected) => {
                                          if (prevSelected?.some((person) => person?.job_id === jobId)) {
                                              return prevSelected.filter((person) => person?.job_id !== jobId);
                                          } else {
                                              return [...prevSelected, row?.original];
                                          }
                                      });
                                  }}
                              />
                              <span
                                  onClick={() => {
                                      if (!hasPermissions('Job', ['write', 'admin'])) {
                                          return toastHandler(
                                              'You dont have permission to do this action.',
                                              TOAST_TYPE_ERROR
                                          );
                                      }
                                      handleMarkFavorite(row?.original?.is_favourite === 'yes' ? 'no' : 'yes', [
                                          row?.original
                                      ]);
                                  }}
                              >
                                  <StarIcon
                                      className={`w-5 h-5 text-thirdbg ${
                                          row?.original?.is_favourite === 'yes' ? 'fill-thirdbg' : ''
                                      } `}
                                  />
                              </span>
                              <Link
                                  to={`/job-details/${row.original.job_id}`}
                                  className=" w-full h-full flex gap-1 content-center"
                              >
                                  {row.original.ref_no}
                                  {row.original.activtyCount > 0 && (
                                      <div className="relative">
                                          <span className="absolute -top-2">
                                              <Pulse />
                                          </span>
                                      </div>
                                  )}

                                  {!['Closed', 'Cancelled'].includes(row?.original?.status?.label) &&
                                      isDatePastDue(row?.original?.due_date) &&
                                      currentTab !== 'Recurring Jobs' && (
                                          <span>
                                              <TooltipChipWrap
                                                  title="Job Delayed"
                                                  sx={
                                                      '!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-0 !-translate-y-[0px]'
                                                  }
                                                  hideChip
                                                  placement="right"
                                              >
                                                  <ClockIcon className="w-5 h-5 text-red-600 " />
                                              </TooltipChipWrap>
                                          </span>
                                      )}
                              </Link>
                          </div>
                      )
                  })
              ]
            : []),
        ...(colsMeta?.includes('job')
            ? [
                  columnHelper.accessor('title', {
                      //   header: 'Job',
                      header: () => (
                          <div className="flex gap-2 items-center">
                              <ReturnSortIcons sortType="title" text="Job" />
                          </div>
                      ),
                      cell: ({ row }) => (
                          <Link to={`/job-details/${row.original.job_id}`} className="w-full h-full content-center">
                              {row.original.title}
                          </Link>
                      )
                  })
              ]
            : []),
        ...(colsMeta?.includes('priority')
            ? [
                  columnHelper.accessor('priority', {
                      header: 'Priority',
                      cell: ({ row }) => (
                          <DropdownMenu
                              key={row.index}
                              className="!left-0"
                              icon={
                                  <div
                                      className={`flex gap-1 px-2 justify-between w-[100px] items-center p-1 rounded-xl !text-sm ${getPriorityClass(
                                          row.original.priority
                                      )}`}
                                  >
                                      {row.original.priority || '--'}
                                      <ChipArrow fill={getPriorityColorCode(row.original.priority)} />
                                  </div>
                              }
                          >
                              {PRIORITY_OPTIONS.map((item, index) => (
                                  <DropdownMenuItem
                                      key={'taskPriority' + index}
                                      onClick={() => {
                                          if (!hasPermissions('Job', ['write', 'admin'])) {
                                              return toastHandler(
                                                  'You dont have permission to do this action.',
                                                  TOAST_TYPE_ERROR
                                              );
                                          }

                                          handleUpdateValues('priority', item.label, row.original.job_id);
                                      }}
                                  >
                                      {item.label}
                                  </DropdownMenuItem>
                              ))}
                          </DropdownMenu>
                      )
                  })
              ]
            : []),
        ...(colsMeta?.includes('status')
            ? [
                  columnHelper.accessor('status', {
                      header: 'Status',
                      cell: ({ row }) => (
                          <DropdownMenu
                              key={row.index}
                              className="!left-0"
                              icon={
                                  <div
                                      className={`flex gap-1 px-2 justify-between w-[120px] items-center p-1 rounded-xl !text-sm ${getStatusColor(
                                          row.original.status?.label
                                      )} truncate overflow-hidden`}
                                  >
                                      <span className="truncate flex-1">{row.original.status?.label || '--'}</span>
                                      <ChipArrow fill={getStatusColorCode(row.original.status?.label)} />
                                  </div>
                              }
                          >
                              {jobStatuses &&
                                  jobStatuses?.map((item, index) => (
                                      <DropdownMenuItem
                                          key={index}
                                          onClick={() => {
                                              if (!hasPermissions('Job', ['write', 'admin'])) {
                                                  return toastHandler(
                                                      'You dont have permission to do this action.',
                                                      TOAST_TYPE_ERROR
                                                  );
                                              }

                                              if (item?.label !== 'Closed') {
                                                  handleUpdateValues('status', item.job_status_id, row.original.job_id);
                                              } else {
                                                  setJob_id(row.original.job_id);
                                                  setJobCloseModal(item?.job_status_id);
                                              }
                                          }}
                                      >
                                          {item.label}
                                      </DropdownMenuItem>
                                  ))}
                          </DropdownMenu>
                      )
                  })
              ]
            : []),
        ...(colsMeta?.includes('clients')
            ? [
                  columnHelper.accessor('businessClients', {
                      header: 'Clients',
                      cell: ({ row }) => (
                          <AvatarGroup
                              list={row.original.businessClients}
                              limit={2}
                              targetProperty="image"
                              onClick={() => {
                                  setViewList(true);
                                  setAllClients(row.original.businessClients);
                              }}
                              firstFull
                          />
                      )
                  })
              ]
            : []),
        ...(colsMeta?.includes('created date')
            ? [
                  columnHelper.accessor('created_at', {
                      header: () => (
                          <div className="flex gap-2 items-center">
                              <ReturnSortIcons sortType="created_at" text="Created Date" />
                          </div>
                      ),
                      cell: ({ getValue }) => dayjs(getValue()).format('DD-MM-YYYY') || '--'
                  })
              ]
            : []),
        ...(colsMeta?.includes('start date')
            ? [
                  columnHelper.accessor('repeats_or_start_date', {
                      header: () => (
                          <div className="flex gap-2 items-center ">
                              {currentTab === 'Recurring Jobs' ? (
                                  <>Repeats</>
                              ) : (
                                  <ReturnSortIcons sortType="start_date" text="Start Date" />
                              )}
                          </div>
                      ),
                      cell: ({ row }) => (
                          <Link
                              to={`/job-details/${row.original.job_id}`}
                              className="block w-full h-full content-center"
                          >
                              {currentTab === 'Recurring Jobs' ? row.original.repeats : row.original.start_date || '--'}
                          </Link>
                      )
                  })
              ]
            : []),
        ...(colsMeta?.includes('due date')
            ? [
                  columnHelper.accessor('due_date', {
                      header: () => (
                          <div className="flex gap-2 items-center">
                              <ReturnSortIcons sortType="due_date" text="Due Date" />
                          </div>
                      ),
                      cell: ({ getValue }) => getValue() || '--'
                  })
              ]
            : []),
        ...(colsMeta?.includes('manager')
            ? [
                  columnHelper.accessor('manager', {
                      header: 'Manager',
                      cell: ({ row }) => (
                          <div className="flex justify-between items-center">
                              {row.original.manager?.length > 0 ? (
                                  <AvatarGroup
                                      list={row.original.manager}
                                      limit={2}
                                      targetProperty="image"
                                      onClick={() => {
                                          setViewList(true);
                                          setAllClients(row.original.manager);
                                      }}
                                  />
                              ) : (
                                  <span className="ml-2"> --</span>
                              )}
                              <DropdownMenu>
                                  <DropdownMenuItem
                                      onClick={() => {
                                          handleRemoveAction(row);
                                      }}
                                  >
                                      <TrashIcon className="w-4 h-4 bg-transparent" /> Remove
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                      onClick={() => {
                                          setSelectedJob(row.original.job_id);
                                          setCreateJob(true);
                                      }}
                                  >
                                      <EditPencilIcon className="w-4 h-4 bg-transparent" /> Edit
                                  </DropdownMenuItem>
                              </DropdownMenu>
                          </div>
                      )
                  })
              ]
            : [])
    ];

    const getPriorityClass = (priority) => {
        switch (priority) {
            case 'Low':
                return 'bg-[#008C51]/10 text-[#008C51]';
            case 'Normal':
                return 'bg-[#b695f8]/10 text-[#b695f8]';
            case 'Lowest':
                return 'bg-[#666666]/10 text-[#666666]';
            case 'Highest':
                return 'bg-[#F14747]/10 text-[#F14747]';
            case 'High':
                return 'bg-[#0d1b37]/10 text-[#0d1b37]';
            default:
                return 'bg-[#F08201]/10 text-[#F08201]';
        }
    };

    const getPriorityColorCode = (priority) => {
        switch (priority) {
            case 'Low':
                return '#008C51';
            case 'Normal':
                return '#b695f8';
            case 'Lowest':
                return '#666666';
            case 'Highest':
                return '#F14747';
            case 'High':
                return '#0d1b37';
            default:
                return '#F08201';
        }
    };

    const getStatusColor = (status = '') => {
        switch (status) {
            case 'Completed':
                return 'bg-[#008C51]/10 text-[#008C51]';
            case 'In Progress':
                return 'bg-[#b695f8]/10 text-[#b695f8]';
            case 'On Hold':
                return 'bg-[#666666]/10 text-[#666666]';
            case 'Closed':
                return 'bg-[#F14747]/10 text-[#F14747]';
            case 'Cancelled':
                return 'bg-[#0d1b37]/10 text-[#0d1b37]';
            default:
                return 'bg-[#F08201]/10 text-[#F08201]';
        }
    };
    const getStatusColorCode = (status = '') => {
        switch (status) {
            case 'Completed':
                return '#008C51';
            case 'In Progress':
                return '#b695f8';
            case 'On Hold':
                return '#666666';
            case 'Closed':
                return '#F14747';
            case 'Cancelled':
                return '#0d1b37';
            default:
                return '#F08201';
        }
    };
    const data = React.useMemo(() => jobs.list || [], [jobs]);

    const changeJobStatus = (label) => {
        if (label === 'Delete') {
            handleDelete();
        } else {
            handleUpdateValues('status', label, job_id);
        }
        setCutORCopy(false);
        setSelectedIds([]);
        setJob_id(null);
    };

    //change

    return (
        <>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                sx="!min-w-[530px] rounded-xl !px-8 !py-5"
                description={"Do you want to move Job Documents to Client's Folder?"}
                customButtons={
                    <div className="flex gap-2 items-center flex-wrap justify-center">
                        {!showMoveClientBtn && (
                            <Button
                                title="Cancel"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-xl !font-medium font-fira !px-6'
                                }
                                atClick={() => {
                                    setIsConfirmationOpen(false);
                                }}
                            />
                        )}
                        <Button
                            title="Close and Delete"
                            classes={cn(
                                'rounded-xl !font-medium font-fira !px-6',
                                showMoveClientBtn
                                    ? 'border-blueish text-blueish border-2 h-[3rem]'
                                    : '!text-white ann-btn'
                            )}
                            atClick={() => {
                                handleDelete();
                                setIsConfirmationOpen(false);
                            }}
                        />
                        {showMoveClientBtn && (
                            <Button
                                atClick={() => {
                                    setIsConfirmationOpen(false);
                                    setCutORCopy('Delete');
                                }}
                                title={"Move to Client's Folder"}
                                classes={cn('rounded-xl !font-medium font-fira !text-white ann-btn !px-6')}
                            />
                        )}
                    </div>
                }
            />
            <ConfirmationModal
                isOpen={!!jobCloseModal}
                onClose={() => setJobCloseModal(false)}
                title="Action Required!"
                description={"Do you want to move Job Documents to Client's Folder?"}
                sx="max-w-3xl rounded-xl !px-8 !py-5"
                customButtons={
                    <div className="flex items-center justify-center gap-2 w-full">
                        <Button
                            title={"Close Anyway's"}
                            classes={
                                'border-blueish text-blueish border-2 h-[3rem] rounded-xl !font-medium font-fira !px-6'
                            }
                            atClick={() => {
                                changeJobStatus(jobCloseModal);
                                setJobCloseModal(false);
                            }}
                        />
                        <Button
                            atClick={() => {
                                setCutORCopy(jobCloseModal);
                                setJobCloseModal(false);
                            }}
                            title={"Move to Client's Folder"}
                            classes={cn('rounded-xl h-[3.2rem] !font-medium font-fira !text-white ann-btn !px-6')}
                        />
                    </div>
                }
            />
            {CutORCopy && (
                <MoveOrCopy
                    open={!!CutORCopy}
                    setOpen={(e) => {
                        if (!e) {
                            setCutORCopy(false);
                            setSelectedIds([]);
                        }
                    }}
                    title={'Move to Client Folder'}
                    action={'moveToClient'}
                    list={selectedIds}
                    getMainTableData={() => {
                        changeJobStatus(CutORCopy);
                        setJobCloseModal(false);
                        setIsConfirmationOpen(false);
                    }}
                    setFileList={setSelectedIds}
                    job_id={job_id}
                />
            )}
            <DataTable tableName="JobsTable" data={data} columns={columns} />
            {jobs?.count === 0 && (
                <NoDataFound classes="border-y border-gray-300" message="No Data Found!" height="420px" />
            )}
        </>
    );
};

export default JobsTable;
