import React, { useState } from 'react';
import { RosterPage } from '../../components/payrol-pages/roster-pages/RosterPage';
import { AddRoster } from '../../components/payrol-pages/roster-pages/AddRoster';
export const Roster = () => {
    const [showAddRoster, setShowAddRoster] = useState(false);

    const toggleAddRoster = () => {
        setShowAddRoster(!showAddRoster);
    };

    return (
        <div className="app-dashboard">
            {showAddRoster && <AddRoster toggleAddRoster={toggleAddRoster} />}

            <div className={`content ${''}`}>
                <RosterPage toggleAddRoster={toggleAddRoster} />
            </div>
        </div>
    );
};
