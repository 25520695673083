import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
    BUSINESS_ID,
    TOAST_TYPE_SUCCESS,
    USER_TYPE,
    acceptFilesFormats,
    acceptFormate,
    handleDownload
} from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';
import { deleteAnnFileThunk, getSingleNews, getSingleNewsThunk, updateNewsThunk } from 'store/workspace/workspaceNews';
import pdfIcon from '../../../files/pdfIcon.svg';
import { toastHandler } from 'responseHanlder';
import cloneDeep from 'lodash/cloneDeep';
import { LinkIcon } from 'utills/svgs/LinkIcon';
import { BgTimes } from 'utills/svgs/BgTimes';
import ConfirmationModal from 'utills/confirmationModal';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { DragFile } from 'components/atoms/inputs/DragFile';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { UploadPhotoIcon } from 'utills/svgs/UploadPhotoIcon';
import Badge from 'components/atoms/Badge';
import Slideover from 'components/atoms/SlideOver';
import { FilesIcon } from 'utills/svgs/FilesIcon';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

export const EditAnnoncement = ({ open, setOpen, toggleEditAnn, toggleAnnUpdate, isHome }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.getSingleNews);
    const { announcement } = useSelector((state) => state.global);

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [imagesList, setImagesList] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [pdfList, setPdfList] = useState([]);
    const [_imagesView, setimagesView] = useState([]);
    const [_pdfView, setPdfView] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const [showImage, setShowImage] = useState(null);
    const [title, setTitle] = useState(data?.title ?? '');
    const [description, setDescription] = useState(data?.description ?? '');
    const [forceUpdate, setForceUpdate] = useState(false);

    const updateNews = async () => {
        const formData = new FormData();

        formData.append('business_id', business_id);
        formData.append('news_id', data?.news_id);
        formData.append('title', title.trim());
        formData.append('description', description.trim());
        formData.append('type', data?.type);

        for (let i = 0; i < selectedImages.length; i++) {
            const file = selectedImages[i];
            formData.append('news_images', file);
        }

        const sanitizedFilesPromises = pdfList.map((file) => createNewPDFWithoutMetadata(file));
        const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
        sanitizedFiles.forEach((sanitizedFile) => {
            formData.append('news_docs', sanitizedFile);
        });

        formData.append('uploaded_by', USER_TYPE);

        dispatch(setLoader(true));
        dispatch(updateNewsThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toggleEditAnn();

                    toggleAnnUpdate();
                    toastHandler('Successfully Updated', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleFileRemove = (index, type) => {
        if (type === 'pdf') {
            const newArr = [...pdfList];
            newArr.splice(index, 1);
            setPdfList(newArr);
            fileView(type);
        }
        if (type === 'image') {
            const newSelected = [...selectedImages];
            newSelected.splice(index, 1);
            setSelectedImages(newSelected);
            const newArr = [...imagesList];
            newArr.splice(index, 1);
            setImagesList(newArr);
            fileView(type);
        }
    };
    const fileView = (type) => {
        const items = [];
        if (type === 'pdf') {
            if (pdfList) {
                for (let i = 0; i < pdfList.length; i++) {
                    items.push(
                        <button className="flex items-center gap-1 selected-file ">
                            <LinkIcon />
                            <span>{pdfList[i]?.name?.slice(0, 10)}</span>
                            <span onClick={() => handleFileRemove(pdfList[i], 'pdf')} className="cursor-pointer">
                                <BgTimes />
                            </span>{' '}
                        </button>
                    );
                }
            }
            setPdfView(items);
        }
        if (type === 'image') {
            if (imagesList) {
                for (let i = 0; i < imagesList.length; i++) {
                    items.push(
                        <button className="flex items-center gap-1 selected-file ">
                            <LinkIcon />
                            <span>{imagesList[i]?.name?.slice(0, 10)}</span>
                            <span onClick={() => handleFileRemove(imagesList[i], 'image')} className="cursor-pointer">
                                <BgTimes />
                            </span>{' '}
                        </button>
                    );
                }
            }
            setimagesView(items);
        }
    };
    useEffect(() => {
        fileView('image');
        fileView('pdf');
    }, [imagesList, pdfList]);

    const [_delLoader, setDelLoader] = useState(false);
    const [_deleteId, setDeleteId] = useState('');

    const handleFileDelete = (news_file_id) => {
        setDeleteId(news_file_id);
        setDelLoader(true);
        dispatch(deleteAnnFileThunk({ news_file_id }))
            .then((response) => {
                if (response.payload) {
                    const newObj = cloneDeep(data);

                    const findFile = newObj?.attachments?.find((file) => file.news_file_id === news_file_id);
                    const index = newObj.attachments.indexOf(findFile);
                    newObj.attachments.splice(index, 1);
                    dispatch(getSingleNews.actions.handleUpdate(newObj));
                }
            })

            .finally(() => {
                setIsConfirmationOpen(false);
                setDelLoader(false);
            });
    };

    const handleSelectedFiles = (files) => {
        let newFiles = Array.isArray(files) ? [...files] : [files];
        setSelectedImages([...selectedImages, ...newFiles]);
    };

    const handleSelectedPdf = (files) => {
        let newFiles = Array.isArray(files) ? [...files] : [files];
        setPdfList([...pdfList, ...newFiles]);
    };

    useEffect(() => {
        if (isHome) {
            const payload = {
                business_id,
                news_id: announcement
            };
            dispatch(setLoader(true));
            dispatch(getSingleNewsThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        const data = response.payload;

                        setTitle(data?.title);
                        setDescription(data?.description);
                        setForceUpdate(true);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    }, [announcement]);
    return (
        <>
            <Slideover title="Edit Announcement" open={open} setOpen={setOpen} showLoader hideBackArrow>
                <RoleBasedGuard module={'Announcements'} permissions={['admin']} sx="h-[50vh]">
                    <div className="mt-5 px-5 ">
                        <div className="primary-fonts text-md">
                            <label>Title</label>
                        </div>
                        <div className="add-ann-form">
                            <input
                                value={title}
                                type="text"
                                placeholder="Title"
                                name={title}
                                maxLength={100}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>

                        <div className="mt-3">
                            <div>
                                <div className="buttons-font font-poppins">Description</div>
                                <div>
                                    <div className="mt-2 resize-y">
                                        <DraftEditorNew
                                            initialValue={description}
                                            onChange={(value) => setDescription(value)}
                                            forceUpdate={forceUpdate}
                                            setForceUpdate={setForceUpdate}
                                            required={true}
                                            extraConfigs={{
                                                height: 300
                                            }}
                                            placeholder="Enter announcement description"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5">
                            <div className="mb-2 table-title">Upload Image</div>
                            <DragFile
                                atChange={(file) => {
                                    handleSelectedFiles(file);
                                }}
                                iconSet={{ icon: UploadPhotoIcon }}
                                accept={acceptFormate.filter((type) => type !== 'application/pdf')}
                                selectedFiles={selectedImages}
                                isMultiple={true}
                                hideSelectedFiles
                                dragMessage="Drag files here to upload image, or browse files"
                                dropMessage="Drop the selected files in this box... "
                                onlyPdf={false}
                                message="Only images are allowed"
                            />

                            <SelectedFiles
                                files={selectedImages || []}
                                updateSelectedFiles={(files) => setSelectedImages(files || [])}
                            />
                        </div>
                        <div className="mt-5">
                            <div className="mb-2 table-title">Upload Document</div>
                            <DragFile
                                atChange={(file) => {
                                    handleSelectedPdf(file);
                                }}
                                accept={acceptFilesFormats}
                                selectedFiles={pdfList}
                                isMultiple={true}
                                hideSelectedFiles
                                dragMessage="Drag files here to upload pdf, or browse files"
                                dropMessage="Drop the selected files in this box... "
                                onlyPdf={false}
                                message="This file type is not allowed"
                            />

                            <SelectedFiles files={pdfList} updateSelectedFiles={(files) => setPdfList(files || [])} />
                        </div>

                        {data?.attachments?.length > 0 && (
                            <div className="mt-5">
                                <div className="mt-1">
                                    <label>Uploaded files</label>
                                </div>
                                <div className="flex items-center gap-2 flex-wrap mt-2">
                                    {' '}
                                    {data?.attachments?.length > 0 &&
                                        data?.attachments?.map((file, index) => (
                                            <div key={'attachemtns' + index}>
                                                <Badge
                                                    leftIcon={
                                                        file.file_type === 'news_images' ||
                                                        file?.url?.toLowerCase()?.endsWith('.pdf') ? (
                                                            <img
                                                                src={
                                                                    file.file_type === 'news_images'
                                                                        ? file.url
                                                                        : pdfIcon
                                                                }
                                                                alt="alt"
                                                                className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                                onClick={() => {
                                                                    if (file.file_type === 'news_images') {
                                                                        setShowImage(file.url);
                                                                    } else {
                                                                        if (
                                                                            file?.url?.toLowerCase()?.endsWith('.pdf')
                                                                        ) {
                                                                            setOpen(true);
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        ) : (
                                                            <div onClick={() => handleDownload(file)}>
                                                                <FilesIcon
                                                                    className={
                                                                        'stroke-white !w-10 !h-10 mr-2 cursor-pointer'
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    text={file.name?.slice(-10)}
                                                    sx="text-white bg-third rounded-md !px-3 !text-md"
                                                    onCancel={() => handleFileDelete(file?.news_file_id)}
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="my-5 flex justify-center items-center gap-3 mt-10">
                        <button
                            onClick={updateNews}
                            className="ann-btn text-white px-5 md:px-20 py-2 cursor-pointer  rounded-lg"
                        >
                            Update
                        </button>
                    </div>{' '}
                </RoleBasedGuard>
            </Slideover>

            {showImage && <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />}
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleFileDelete}
            />
        </>
    );
};
