import React, { useRef, useState } from 'react';
import { cn } from 'utills/tailwindUtil';
import { TooltipChip } from '../tooltipChip/TooltipChip';

const Pulse = ({ sx, wsx, tooltip, groupSx }) => {
    const parentRef = useRef();
    const [hoveredIndex, setHoveredIndex] = useState(false);

    return (
        <>
            <div
                ref={parentRef}
                onMouseEnter={() => setHoveredIndex(true)}
                onMouseLeave={() => setHoveredIndex(null)}
                className={cn('group relative ', groupSx ? groupSx : '')}
            >
                {tooltip && (
                    <TooltipChip
                        text={tooltip}
                        direction={'top'}
                        parentRef={parentRef}
                        isVisible={hoveredIndex}
                        sx={'!text-xs'}
                        yAxis={'-50px'}
                        portalProps={{
                            onMouseEnter: () => setHoveredIndex(true),
                            onMouseLeave: () => setHoveredIndex(null)
                        }}
                    />
                )}
                <span className="relative flex">
                    <span
                        className={cn(
                            'animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-50',
                            wsx ?? ''
                        )}
                    ></span>
                    <span className={cn('w-1.5 h-1.5 rounded-full bg-[#a984f3] shadow-md opacity-75', sx ?? '')} />
                </span>
            </div>
        </>
    );
};

export default Pulse;
