import React, { useEffect, useState } from 'react';
import WorkplaceContent from '../../components/workplace-content/WorkplaceContent';
import AddApp from '../../components/workplace-content/workplace-apps/workspace-side/AddApp';
import { useSelector } from 'react-redux';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { NoPermission } from 'components/molecules/NoPermission';
import { EditApp } from 'components/workplace-content/workplace-apps/workspace-side/EditApp';
import { SidebarLayout } from 'layouts/SidebarLayout';

const Workplace = () => {
    const { isAddAppOpen } = useSelector((state) => state.global);
    const [showAddApp, setShowAddApp] = useState(isAddAppOpen);
    const [appsUpdated, setAppsUpdated] = useState(false);
    const [allowAction, setAllowAction] = useState(false);
    const { hasPermissions } = useRoleGuard();
    const [editApp, setEditApp] = useState(false);
    const [showPreviewImage, setShowPreviewImage] = useState(false);

    const togglePreviewAddImg = () => {
        setShowPreviewImage(!showPreviewImage);
    };

    const toggleEditApp = () => {
        setEditApp(!editApp);
    };
    function toggleShowAddApp() {
        setShowAddApp(!showAddApp);
    }

    const toggleAppsUpdated = () => {
        setAppsUpdated(!appsUpdated);
    };

    useEffect(() => {
        if (hasPermissions('Apps', ['write', 'admin'])) {
            setAllowAction(true);
        } else {
            setAllowAction(false);
        }
    }, []);

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {allowAction && showAddApp ? (
                    <AddApp
                        open={showAddApp}
                        setOpen={setShowAddApp}
                        togglePreviewAddImg={togglePreviewAddImg}
                        toggleShowAddApp={toggleShowAddApp}
                        toggleAppsUpdated={toggleAppsUpdated}
                    />
                ) : allowAction && editApp ? (
                    <EditApp toggleEditApp={toggleEditApp} toggleAppsUpdated={toggleAppsUpdated} />
                ) : (
                    <NoPermission
                        slider={{
                            open: showAddApp,
                            setOpen: setShowAddApp,
                            title: 'Add App'
                        }}
                        classes="p-5"
                    />
                )}

                <RoleBasedGuard module={'Apps'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    {' '}
                    <div>
                        <WorkplaceContent
                            toggleShowAddApp={toggleShowAddApp}
                            appsUpdated={appsUpdated}
                            toggleEditApp={toggleEditApp}
                        />
                    </div>
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};

export default Workplace;
