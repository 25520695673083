import React from 'react';

export const BgUserIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="url(#paint0_linear_4340_415)" />
                <path
                    d="M19.2296 9.08592C18.0565 9.26639 17.0081 9.82498 16.1874 10.6972C15.285 11.6555 14.8167 12.764 14.7522 14.0918C14.662 15.948 15.5729 17.7226 17.1456 18.7496C17.919 19.2566 18.6624 19.5144 19.5647 19.5875C21.2362 19.7164 22.6628 19.1922 23.8143 18.0191C24.9401 16.8719 25.4428 15.4582 25.3053 13.834C25.185 12.4418 24.5706 11.243 23.505 10.3234C22.6628 9.60154 21.8893 9.24061 20.8194 9.08592C20.3038 9.01287 19.7022 9.00858 19.2296 9.08592ZM21.06 10.4351C21.7346 10.607 22.2417 10.9035 22.8089 11.4492C23.5479 12.1496 23.9218 12.923 24.0206 13.9242C24.1366 15.1101 23.7714 16.193 22.9678 17.0523C22.4694 17.5851 21.7346 18.0363 21.1116 18.1953C20.1878 18.4273 19.0878 18.3285 18.2971 17.9375C17.2186 17.4047 16.4194 16.4078 16.1272 15.2305C16.0155 14.7793 16.0284 13.7609 16.153 13.2969C16.4839 12.0508 17.5667 10.8949 18.7698 10.5039C19.4229 10.2933 20.3983 10.2633 21.06 10.4351Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M15.3284 19.682C14.671 19.7508 13.9921 20 13.455 20.3609C12.1144 21.2719 11.2464 23.3086 11.0444 26.0156C10.9327 27.5367 11.023 28.2887 11.414 29.075C11.6288 29.5133 12.037 29.9945 12.4538 30.3039C12.7718 30.5359 13.3046 30.7766 13.773 30.8969L14.1597 31H20.1753H26.1909L26.5777 30.9012C27.9355 30.5445 28.8851 29.6293 29.2245 28.3359C29.4007 27.6699 29.3792 26.0801 29.173 24.8254C28.7218 22.0324 27.5788 20.3566 25.7698 19.8324C25.3573 19.7121 24.6183 19.6219 24.3991 19.6648C24.1886 19.7035 23.8964 19.8582 23.2347 20.2836C22.5214 20.7434 22.4097 20.8035 21.8769 20.9969C21.2495 21.2246 20.7812 21.3148 20.2011 21.3148C19.5995 21.3148 19.1355 21.2289 18.5253 21.0141C17.9495 20.8078 17.9237 20.7949 17.0214 20.2234C16.5487 19.9227 16.1964 19.7293 16.0589 19.6906C15.8183 19.6305 15.8183 19.6305 15.3284 19.682ZM16.4456 21.3793C17.2534 21.8992 17.5929 22.0668 18.3019 22.2988C20.1495 22.9047 22.0359 22.6125 23.8148 21.448C24.6183 20.9195 24.6011 20.9281 24.9405 20.9711C26.5003 21.1687 27.4757 22.4793 27.8882 24.9414C28.0558 25.9555 28.1073 27.3691 27.9956 27.9062C27.8152 28.7699 27.2308 29.3801 26.337 29.6336C26.0792 29.7066 25.7398 29.7109 20.1753 29.7109C14.598 29.7109 14.2757 29.7066 14.0093 29.6336C13.3691 29.4531 12.8148 29.0148 12.5569 28.4863C12.3335 28.0309 12.2777 27.6484 12.312 26.7246C12.3421 25.7922 12.3851 25.3582 12.5355 24.5977C12.6472 24.0047 12.9093 23.1625 13.0941 22.7973C13.6484 21.6973 14.4003 21.0957 15.3757 20.9711C15.5347 20.9496 15.6808 20.9324 15.698 20.9281C15.7152 20.9281 16.0503 21.1301 16.4456 21.3793Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_4340_415"
                        x1="21.9626"
                        y1="0.49676"
                        x2="21.9327"
                        y2="40.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
