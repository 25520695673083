import React from 'react';

export const BlueNoteIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="url(#paint0_linear_12329_160)" />
            <path
                d="M28.9061 12.5742H26.8749V13.8562H28.7499V27.9582H11.2501V13.8562H13.1251V12.5742H11.0939C10.9478 12.5767 10.8036 12.6087 10.6696 12.6684C10.5355 12.7281 10.4143 12.8142 10.3127 12.922C10.2111 13.0297 10.1313 13.1569 10.0776 13.2963C10.024 13.4357 9.99768 13.5846 10.0002 13.7344V28.08C9.99768 28.2299 10.024 28.3787 10.0776 28.5182C10.1313 28.6576 10.2111 28.7848 10.3127 28.8925C10.4143 29.0002 10.5355 29.0864 10.6696 29.1461C10.8036 29.2057 10.9478 29.2377 11.0939 29.2402H28.9061C29.0522 29.2377 29.1964 29.2057 29.3304 29.1461C29.4645 29.0864 29.5857 29.0002 29.6873 28.8925C29.7889 28.7848 29.8687 28.6576 29.9224 28.5182C29.976 28.3787 30.0023 28.2299 29.9998 28.08V13.7344C30.0023 13.5846 29.976 13.4357 29.9224 13.2963C29.8687 13.1569 29.7889 13.0297 29.6873 12.922C29.5857 12.8142 29.4645 12.7281 29.3304 12.6684C29.1964 12.6087 29.0522 12.5767 28.9061 12.5742Z"
                fill="white"
            />
            <path d="M13.75 17.7012H15V18.9832H13.75V17.7012Z" fill="white" />
            <path d="M17.5 17.7012H18.75V18.9832H17.5V17.7012Z" fill="white" />
            <path d="M21.25 17.7012H22.5V18.9832H21.25V17.7012Z" fill="white" />
            <path d="M25 17.7012H26.25V18.9832H25V17.7012Z" fill="white" />
            <path d="M13.75 20.9062H15V22.1883H13.75V20.9062Z" fill="white" />
            <path d="M17.5 20.9062H18.75V22.1883H17.5V20.9062Z" fill="white" />
            <path d="M21.25 20.9062H22.5V22.1883H21.25V20.9062Z" fill="white" />
            <path d="M25 20.9062H26.25V22.1883H25V20.9062Z" fill="white" />
            <path d="M13.75 24.1113H15V25.3933H13.75V24.1113Z" fill="white" />
            <path d="M17.5 24.1113H18.75V25.3933H17.5V24.1113Z" fill="white" />
            <path d="M21.25 24.1113H22.5V25.3933H21.25V24.1113Z" fill="white" />
            <path d="M25 24.1113H26.25V25.3933H25V24.1113Z" fill="white" />
            <path
                d="M15 15.1378C15.1657 15.1378 15.3247 15.0702 15.4419 14.95C15.5591 14.8298 15.625 14.6668 15.625 14.4968V10.6508C15.625 10.4808 15.5591 10.3177 15.4419 10.1975C15.3247 10.0773 15.1657 10.0098 15 10.0098C14.8342 10.0098 14.6753 10.0773 14.5581 10.1975C14.4408 10.3177 14.375 10.4808 14.375 10.6508V14.4968C14.375 14.6668 14.4408 14.8298 14.5581 14.95C14.6753 15.0702 14.8342 15.1378 15 15.1378Z"
                fill="white"
            />
            <path
                d="M25 15.1378C25.1657 15.1378 25.3247 15.0702 25.4419 14.95C25.5591 14.8298 25.625 14.6668 25.625 14.4968V10.6508C25.625 10.4808 25.5591 10.3177 25.4419 10.1975C25.3247 10.0773 25.1657 10.0098 25 10.0098C24.8342 10.0098 24.6753 10.0773 24.5581 10.1975C24.4408 10.3177 24.375 10.4808 24.375 10.6508V14.4968C24.375 14.6668 24.4408 14.8298 24.5581 14.95C24.6753 15.0702 24.8342 15.1378 25 15.1378Z"
                fill="white"
            />
            <path d="M16.875 12.5742H23.1249V13.8562H16.875V12.5742Z" fill="white" />
            <defs>
                <linearGradient
                    id="paint0_linear_12329_160"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                </linearGradient>
            </defs>
        </svg>
    );
};
