/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../css-steps/login-css/stepOne.css';
import 'react-notifications/lib/notifications.css';
import { validateEmail } from '../../utills/FormValidation';
import { Loader } from '../common/Loader';
import { loginThunk } from '../../store/auth/slices';
import { setLoader } from '../../store/global/globalReducer';
import { toastHandler } from '../../responseHanlder';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN, SOCKET_URL, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { Button } from 'components/atoms/buttons/Button';
import { OpenEye } from 'utills/svgs/OpenEye';
import { CloseEye } from 'utills/svgs/CloseEye';
import { io } from 'socket.io-client';
import { stopHeartbeatInterval } from 'utills/socketEvents';
import {
    decryptData,
    encryptData,
    exportKey,
    generateKey,
    getCookie,
    importKey,
    setCookie
} from 'utills/encryption/cryptoUtils';
import useRedirectToDashboardHook from 'Hooks/useRedirectToDashboardHook';
import Recaptcha from 'components/molecules/Recaptcha';
import { useCaptchaHandler } from 'Hooks/reCaptcha/useCaptchaHandler';

const StepOne = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { redirectToDashboard } = useRedirectToDashboardHook();

    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const { data } = useSelector((state) => state.login);
    const [passwordMsg, _setPasswordMsg] = useState('');
    const [showPasswordError, setShowPasswordError] = useState(false);
    const { intervalId } = useSelector((state) => state.global);
    const temp_token = localStorage.getItem('temp_token');
    const [rememberMe, setRememberMe] = useState(false);

    const { error, setError, isCaptchaRequired, recaptchaRef, handleRequest, handleCaptchaChange } =
        useCaptchaHandler();

    const token = localStorage.getItem('access_token');

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [emailError, setEmailError] = useState('');
    const isLoading = useSelector((state) => state.global.isLoading);

    const handleValidate = (e) => {
        const value = e.target.value;
        if (value?.length > 60) {
            return;
        }
        setEmail(value);
        if (value.trim().length === 0) {
            setEmailError('');
        } else if (!validateEmail(value)) {
            setEmailError('Invalid email address');
        } else {
            setEmailError('');
        }
    };

    const handleConnection = () => {
        const newSocket = io(SOCKET_URL, {
            query: {
                email: email,
                user_id: email,
                user_type: 'employee',
                category: 'pre_auth',
                temp_token: ''
            }
        });
        newSocket?.on('connect', () => {
            localStorage.setItem('socket_id', newSocket?.id);
        });

        newSocket.on('disconnect', () => {
            console.info('disconnected', '123');
        });
    };

    const handleNavigation = (payload) => {
        if (payload?.business?.onboarded) {
            localStorage.setItem('onboarding', true);
        } else {
            localStorage.setItem('onboarding', false);
        }

        if (payload?.email_2fa) {
            localStorage.setItem('email_2fa', true);
        } else {
            localStorage.setItem('email_2fa', false);
        }

        if (!payload?.email_2fa && !payload?.google_authenticator_2fa) {
            navigate('/authentication-and-security');
        }

        if (payload.email_2fa && payload?.google_authenticator_2fa) {
            props.handleCondition(7);
        } else if (payload?.email_2fa && !payload?.google_authenticator_2fa) {
            props.handleCondition(8);
        } else if (payload?.google_authenticator_2fa && !payload?.email_2fa) {
            props.handleCondition(7);
        }
    };

    const handleSubmit = async (e) => {
        localStorage.removeItem('pre_auth_token');
        const loginData = {
            email,
            password,
            user_type: 'employee',
            fcm_token: 'dummysts'
        };
        e.preventDefault();
        if (emailError) {
            return;
        }
        if (!email || !password || !validateEmail(email)) {
            toastHandler('Please enter valid credentials', TOAST_TYPE_ERROR);
            return;
        }

        if (rememberMe) {
            const data = JSON.stringify({ email, password });
            const key = await generateKey();
            const { iv, encrypted } = await encryptData(key, data);
            const exportedKey = await exportKey(key);

            setCookie('iv', JSON.stringify(Array.from(iv)), 30);
            setCookie('encrypted', JSON.stringify(Array.from(new Uint8Array(encrypted))), 30);
            setCookie('key', JSON.stringify(exportedKey), 30);
        } else {
            setCookie('iv', '');
            setCookie('encrypted', '');
            setCookie('key', '');
        }

        dispatch(setLoader(true));
        await handleRequest(
            'login',
            (payload = {}) =>
                dispatch(
                    loginThunk({
                        ...payload
                    })
                ),
            loginData
        )
            .then((response) => {
                if (response?.payload) {
                    if (response.payload?.temp_token) {
                        handleConnection();
                        localStorage.setItem('temp_token', response.payload?.temp_token);
                        localStorage.removeItem('pre_auth_token');
                        localStorage.removeItem('access_token');
                        if (response.payload?.is_password_change_required) {
                            navigate(`/update-ot-password/${response.payload.user_id}`);
                            return;
                        }

                        handleNavigation(response.payload);
                    } else {
                        localStorage.removeItem('temp_token');
                        localStorage.removeItem('pre_auth_token');
                        const payload = response.payload;
                        if (payload?.business?.onboarded) {
                            localStorage.setItem('onboarding', true);
                        } else {
                            localStorage.setItem('onboarding', false);
                        }

                        if (payload?.email_2fa) {
                            localStorage.setItem('email_2fa', true);
                        } else {
                            localStorage.setItem('email_2fa', false);
                        }

                        localStorage.setItem('verified', true);

                        localStorage.setItem(ACCESS_TOKEN, payload.access_token);
                        localStorage.setItem(REFRESH_TOKEN, payload.refresh_token);
                        localStorage.setItem(BUSINESS_ID, payload?.business?.business_id);

                        if (!payload?.business?.onboarded && !payload.is_employee) {
                            navigate('/onboarding');
                            return;
                        }
                        // navigate('/business/dashboard');
                        redirectToDashboard();
                    }
                }
            })
            .catch(() => {
                dispatch(setLoader(false));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const showSteptwo = () => {
        props.handleCondition(2);
    };

    useEffect(() => {
        if (!data?.email_2fa && !data?.google_authenticator_2fa && temp_token) {
            navigate('/authentication-and-security');
            return;
        }

        if (
            (data?.business?.onboarded && token && data?.email_2fa) ||
            (data?.google_authenticator_2fa && data?.business?.onboarded && token)
        ) {
            // navigate('/business/dashboard');
            redirectToDashboard();
        }
    }, []);

    useEffect(() => {
        if (showPasswordError) {
            setTimeout(() => {
                setShowPasswordError(false);
            }, 5000);
        }
    }, [showPasswordError]);

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    useEffect(() => {
        if (intervalId) {
            stopHeartbeatInterval(dispatch, intervalId);
        }
    }, []);

    useEffect(() => {
        const iv = getCookie('iv');
        const encrypted = getCookie('encrypted');
        const keyJson = getCookie('key');

        if (iv && encrypted && keyJson) {
            const ivArray = Uint8Array.from(JSON.parse(iv));
            const encryptedArray = Uint8Array.from(JSON.parse(encrypted));

            (async () => {
                const key = await importKey(JSON.parse(keyJson));
                const decrypted = await decryptData(key, encryptedArray, ivArray);
                const { email, password } = JSON.parse(decrypted);
                setEmail(email || '');
                setPassword(password || '');
                setRememberMe(email ? true : false);
            })();
        }
    }, []);

    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                {isLoading && <Loader />}
                <div className="">
                    <div className="login mx-5 md:px-20">
                        <div className="text-center  mt-8  card-layout-title-text ">Login</div>
                        <div className="login-body">
                            <div className="login-welcome mt-10 small-text font-poppins">
                                Welcome to Synkli! Please enter your login credentials below to begin using the app.
                            </div>
                            <div className="login-form mt-8 default-forms">
                                <div>
                                    <div>
                                        <form onSubmit={handleSubmit}>
                                            <div className="email-div">
                                                <label className="primary-fonts font-poppins">Email</label>
                                                <input
                                                    type="email"
                                                    className={`px-3 mt-2 inputs-font ${
                                                        emailError && email ? 'add-error-border' : ''
                                                    }`}
                                                    placeholder="Enter your registered email address"
                                                    onChange={handleValidate}
                                                    required
                                                    maxLength={60}
                                                    value={email}
                                                />
                                                <div className="error-div mt-1 small-text">
                                                    {emailError && <span> {emailError} </span>}
                                                </div>
                                            </div>
                                            <div className="password-div mt-5">
                                                <label className="primary-fonts font-poppins">Password</label>
                                                <div className="passowrd-input-wrap mt-2">
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        className={`password-input px-3 inputs-font ${
                                                            showPasswordError ? 'add-error-border' : ''
                                                        }`}
                                                        placeholder="Enter your Password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        required
                                                        maxLength={30}
                                                        value={password}
                                                    />
                                                    <span
                                                        className="password-eye cursor-pointer"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {!showPassword ? <OpenEye /> : <CloseEye />}
                                                    </span>
                                                </div>
                                                <div className="error-div mt-1 small-text">
                                                    {showPasswordError && <span> {passwordMsg} </span>}
                                                </div>
                                            </div>

                                            <div className=" flex  mt-5  justify-between items-center">
                                                <div>
                                                    <div className="flex gap-2 items-center">
                                                        <span>
                                                            <div className="check flex gap-2 items-center">
                                                                {' '}
                                                                <input
                                                                    type="checkbox"
                                                                    id="remember-me"
                                                                    className="mt-1 !rounded-[4px] !text-thirdbg !h-5 !w-5"
                                                                    checked={rememberMe}
                                                                    onChange={handleRememberMeChange}
                                                                />
                                                                <label
                                                                    htmlFor="remember-me"
                                                                    className="small-text !text-secondarybg font-poppins pt-1"
                                                                >
                                                                    Remember me
                                                                </label>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div
                                                    onClick={showSteptwo}
                                                    className="forgot-pass small-text cursor-pointer mt-1 "
                                                >
                                                    Forgot password?
                                                </div>
                                            </div>

                                            {isCaptchaRequired && (
                                                <div className="my-3">
                                                    <Recaptcha
                                                        ref={recaptchaRef}
                                                        captchaError={!!error}
                                                        setCaptchaError={setError}
                                                        onValueChange={handleCaptchaChange}
                                                    />
                                                </div>
                                            )}

                                            <div>
                                                <div>
                                                    <div className=" flex justify-center items-center">
                                                        <Button
                                                            type="submit"
                                                            title="Login"
                                                            classes=" px-16 ann-btn buttons-font rounded-lg py-2 mt-24"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex justify-center   items-center mt-5 mb-3">
                                                    <p className="dont-have-account small-text">
                                                        Dont have an account ?{' '}
                                                        <span
                                                            onClick={() => {
                                                                sessionStorage.setItem('signupStepper', 1);
                                                                navigate('/signup');
                                                            }}
                                                            className="signup-redirect cursor-pointer"
                                                        >
                                                            Sign up
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepOne;
