import { Chip } from 'components/atoms/buttons/Chip';
import TruncateDescription from 'global-components/TruncateDescription';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarIcon2 } from 'utills/svgs/CalendarIcon2';
import { DependentsIcon } from 'utills/svgs/DependentsIcon';
import { DesignationIcon } from 'utills/svgs/DesignationIcon';
import { DollarsignIcon } from 'utills/svgs/DollarsignIcon';
import { HomeIcon } from 'utills/svgs/HomeIcon';
import LocationPin2 from 'utills/svgs/LocationPin2';
import { MenIcon } from 'utills/svgs/MenIcon';
import { PartnershipIcon } from 'utills/svgs/PartnershipIcon';
import { RelationIcon } from 'utills/svgs/RelationIcon';
import { StateIcon } from 'utills/svgs/StateIcon';

const ClientProfileDetailsCard = ({
    link,
    profile = null,
    name,
    badgesArray,
    email,
    phone,
    taxableIcome,
    directorName,
    contact_director_name = [],
    residential_address,
    dob,
    companyState,
    establishDate,
    settledSum,
    ///
    designation,
    numberOfUnits,
    partnershipPercentage,
    percentageOfShares,
    no_of_dependentant,
    in_relation_for,
    residency_status
}) => {
    const LinkWraper = ({ children }) => {
        if (link) {
            return (
                <Link to={link ? '/app/user/profile/' + link : null} target="_blank">
                    {children}
                </Link>
            );
        } else {
            return children;
        }
    };
    return (
        <div>
            <div className="flex flex-col items-start justify-between flex-wrap gap-y-1 gap-x-6 py-5 w-full">
                <LinkWraper>
                    <div className="flex min-w-0 gap-x-3 flex-1">
                        <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={profile} alt="" />
                        <div className="flex items-center justify-between flex-wrap gap-2 w-full">
                            <div className="min-w-0 flex-auto">
                                <p className="text-lg font-[500] font-poppins">{name}</p>
                                <p className="mt-1 text-md font-[400] font-poppins text-[#3D3C3C]">{email}</p>
                                <p className="mt-1 text-md font-[400] font-poppins text-[#3D3C3C]">{phone}</p>
                                <p className="mt-1 text-md font-[400] font-poppins text-[#3D3C3C]">{dob}</p>
                            </div>
                        </div>
                    </div>
                </LinkWraper>
                <div className="flex flex-col items-start mt-2 justify-between flex-wrap gap-x-3 gap-y-3 2xl:pl-12 w-full">
                    {residential_address && (
                        <div className="flex items-center min-w-0 gap-x-1">
                            <LocationPin2 className="h-6" />
                            <TruncateDescription maxLength={50} text={residential_address} />
                        </div>
                    )}
                    {directorName && (
                        <>
                            {typeof directorName === 'string' && directorName !== '' ? (
                                <div className="flex items-center min-w-0 gap-x-1">
                                    <MenIcon className="h-6" />
                                    <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">
                                        {directorName} (Contact Name)
                                    </p>
                                </div>
                            ) : (
                                <>
                                    {directorName
                                        ?.filter((item) => item !== '')
                                        ?.map((item, index) => (
                                            <div
                                                key={'directName' + index}
                                                className="flex items-center min-w-0 gap-x-1"
                                            >
                                                <MenIcon className="h-6" />
                                                <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">
                                                    {item}
                                                </p>
                                            </div>
                                        ))}
                                </>
                            )}
                        </>
                    )}
                    {contact_director_name
                        ?.filter((item) => item !== '')
                        ?.map((item, index) => (
                            <div key={'contactdirectName' + index} className="flex items-center min-w-0 gap-x-1">
                                <MenIcon className="h-6" />
                                <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">{item}</p>
                            </div>
                        ))}
                    {companyState && (
                        <div className="flex items-center min-w-0 gap-x-1">
                            <StateIcon className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">
                                {companyState} (Company Registration State)
                            </p>
                        </div>
                    )}
                    {establishDate && (
                        <div className="flex items-center min-w-0 gap-x-1">
                            <CalendarIcon2 className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">
                                {moment(establishDate).format('DD-MM-YYYY')} (Deed Established Date)
                            </p>
                        </div>
                    )}
                    {settledSum && (
                        <div className="flex items-center min-w-0 gap-x-1">
                            <DollarsignIcon className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">
                                {settledSum} (Settled Sum)
                            </p>
                        </div>
                    )}

                    {taxableIcome && (
                        <div className="flex w-full items-center min-w-0 gap-x-1">
                            <DollarsignIcon className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">Taxable Income</p>
                            <p className="flex-1 !text-right text-md font-[600] leading-5 font-poppins text-[#3D3C3C]">
                                ${taxableIcome}
                            </p>
                        </div>
                    )}
                    {no_of_dependentant && (
                        <div className="flex w-full items-center min-w-0 gap-x-1">
                            <DependentsIcon className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">
                                No. of Dependents
                            </p>
                            <p className="flex-1 !text-right text-md font-[600] leading-5 font-poppins text-[#3D3C3C]">
                                {no_of_dependentant}
                            </p>
                        </div>
                    )}
                    {in_relation_for && (
                        <div className="flex w-full items-center min-w-0 gap-x-1">
                            <RelationIcon className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">In Relation For</p>
                            <p className="flex-1 !text-right text-md font-[600] leading-5 font-poppins text-[#3D3C3C]">
                                {in_relation_for}
                            </p>
                        </div>
                    )}

                    {designation && (
                        <div className="flex w-full items-center min-w-0 gap-x-1">
                            <DesignationIcon className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">Designation</p>
                            <p className="flex-1 !text-right text-md font-[600] leading-5 font-poppins text-[#3D3C3C]">
                                {designation}
                            </p>
                        </div>
                    )}
                    {(partnershipPercentage || numberOfUnits || percentageOfShares) && (
                        <div className="flex w-full items-center min-w-0 gap-x-1">
                            <PartnershipIcon className="h-6" />
                            <p className="text-md font-[400] leading-5 font-poppins text-[#3D3C3C]">
                                {' '}
                                {partnershipPercentage ? 'Partnership' : percentageOfShares ? 'Shares' : 'No of Units'}
                            </p>
                            <p className="flex-1 !text-right text-md font-[600] leading-5 font-poppins text-[#3D3C3C]">
                                {partnershipPercentage
                                    ? partnershipPercentage + '%'
                                    : percentageOfShares
                                    ? percentageOfShares + '%'
                                    : numberOfUnits}
                            </p>
                        </div>
                    )}

                    <div className="flex flex-wrap min-w-0 gap-3">
                        {badgesArray &&
                            badgesArray
                                .filter((obj) => obj.value !== '')
                                .map((obj, ind) => (
                                    <Chip
                                        title={obj.value}
                                        key={'chipInd ' + obj.value + ind}
                                        classes={'!text-xs capitalize font-medium px-5 py-1.5 ' + obj.sx}
                                    />
                                ))}
                    </div>
                </div>
                {residency_status && (
                    <div className="pt-5 mt-5 border-t-2 w-full border-gray-200">
                        <div className="lg-title font-poppins font-semibold">Australian Residency Status</div>

                        <div className="flex flex-wrap gap-5 w-full items-center justify-end min-w-0 mt-4">
                            <HomeIcon className="h-8" />
                            <p className="flex-1  text-lg font-[400] leading-5 font-poppins text-[#3D3C3C] min-w-[200px]">
                                Current Australian Residency Status
                            </p>
                            <p className="!text-right text-lg font-[600] leading-5 font-poppins text-[#3D3C3C]">
                                {residency_status}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClientProfileDetailsCard;
