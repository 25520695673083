import React from 'react';

export const PointRightIcon = () => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0563 17.0304C12.2239 16.9238 19.2825 10.2105 19.4501 9.99215C19.836 9.50465 19.8512 8.6109 19.4856 8.06754C19.3434 7.85933 12.2798 1.11559 12.0563 0.973398C11.8481 0.841366 11.4012 0.841366 11.1778 0.978476C10.9391 1.12574 10.8122 1.35934 10.8122 1.67418C10.8122 1.82144 10.8376 1.99918 10.8731 2.07027C10.9036 2.14137 12.3458 3.53785 14.0774 5.18316L17.2208 8.16402L8.9739 8.18941C1.13327 8.2148 0.721946 8.21988 0.589914 8.30621C0.127805 8.60582 0.127805 9.39801 0.589914 9.69762C0.721946 9.78394 1.13327 9.78902 8.96374 9.81441L17.2005 9.8398L14.1028 12.7851C12.3966 14.405 10.9594 15.8015 10.9087 15.8828C10.7766 16.091 10.7817 16.5378 10.9137 16.7613C10.9696 16.8527 11.0864 16.9695 11.1778 17.0253C11.4012 17.1625 11.8481 17.1625 12.0563 17.0304Z"
                fill="url(#paint0_linear_6323_13008)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_6323_13008"
                    x1="19.4484"
                    y1="0.875013"
                    x2="0.352942"
                    y2="1.80335"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
