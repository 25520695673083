/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useUploadFile } from 'Hooks/Files/uploadFile';

import { UploadFile } from 'components/appusers-dash/app-user-profile/file-manager-pages/UploadFile';
import { Button } from 'components/atoms/buttons/Button';

import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { TrippleSwitch } from 'components/atoms/inputs/TrippleSwitch';
import { Loader } from 'components/common/Loader';
import { NoDataFound } from 'components/common/NoDataFound';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { ImagesStack } from 'components/molecules/ImagesStack';
import { Menu } from 'components/molecules/Menu';
import { Jumbotron } from 'components/organisms/Jumbotron';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import { filesRevokeThunk, getFilesThunk } from 'store/fileManager';
import { setLoader } from 'store/global/globalReducer';
import ConfirmationModal from 'utills/confirmationModal';

import {
    BUSINESS_ID,
    PERMISSIONS_MESSAGE,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    clientTypes,
    getProfileName
} from 'utills/globalVars';
import { formateDate } from 'utills/moment';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';

import { BgEditPencil } from 'utills/svgs/BgEditPencil';
import { BgRevokeIcon } from 'utills/svgs/BgRevokeIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { UploadWhiteIcon } from 'utills/svgs/UploadWhiteIcon';

import { SearchInput } from 'components/molecules/SearchInput';
import { sortArrayByDate } from 'utills/dataValidation';
import { Badge } from 'components/molecules/Badge';
import { SortIcon } from 'utills/svgs/SortIcon';
import { cn } from 'pages/documents/hooks/helper';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { IncompleteCircle } from 'utills/svgs/IncompleteCircle';
import { BlueTick } from 'utills/svgs/BlueTick';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { EyeIcon } from 'utills/svgs/EyeIcon';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { SidebarLayout } from 'layouts/SidebarLayout';
import { RequestSignatureIcon } from 'utills/RequestSignatureIcon';

export const AuthorizedFolder = () => {
    const { data: loginData } = useSelector((state) => state.login);

    const { id } = useParams();
    const { hasPermissions } = useRoleGuard();
    const dispatch = useDispatch();
    const { uploadFileHanlder, fileLoader } = useUploadFile();
    const navigate = useNavigate();

    const [files, setFiles] = useState([]);
    const [deleteIds, setDeleteIds] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [_filterType, setFilterType] = useState(null);
    const { isLoading } = useSelector((state) => state.global);
    const [revokeLoader, setRevokeLoader] = useState(false);
    const [revokeIndex, setRevokeIndex] = useState(null);
    const [revokeId, setRevokeId] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [showChip, setShowChip] = useState(false);
    const [chipIndex, setChipIndex] = useState(null);
    const storedInDicators = JSON.parse(localStorage.getItem('indicators'));
    const [_pendingFilesLength, setPendingFilesLength] = useState(null);
    const [sortAsc, setSortAsc] = useState(false);
    const [_isModalOpen, setIsModalOpen] = useState(false);
    const [_openModalIndex, setOpenModalIndex] = useState(null);
    const [_pdfUrl, setPdfUrl] = useState('');

    const business_id = localStorage.getItem(BUSINESS_ID);

    const getFilesHandler = () => {
        const payload = {
            folder_name: 'authorized',
            business_id,
            employee_id: loginData?.user_id,
            business_client_id: id
        };
        dispatch(setLoader(true));
        dispatch(getFilesThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setFiles(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        getFilesHandler();
    }, []);

    useEffect(() => {
        if (files) {
            setFilesData(files);
            const pendingFiles = files?.filter(
                (file) => file?.customer_document_id !== null && file?.final_doc === null && file?.status !== 'revoke'
            );
            setPendingFilesLength(pendingFiles?.length);
        }
    }, [files]);

    const openModal = (index) => {
        setIsModalOpen(true);
        setOpenModalIndex(index);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleShowChip = (index) => {
        setChipIndex(index);
        setShowChip(true);
    };

    const { data: customer } = useSelector((state) => state?.viewBusinessCustomerInfo);
    const toggleUploadFile = () => {
        setShowUploadFile(!showUploadFile);
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };
    const acceptFormate = ['application/pdf'];

    const cancelAction = () => {
        setSelectedFiles([]);
    };
    const uploadFiles = async () => {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            const res = await uploadFileHanlder(selectedFiles, 'authorized', id);

            if (res) {
                navigate(`/request/pdf/${id}`, { state: res[0] });
                return;
            }
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/file/manager/${id}`);
        }
    };

    const managerActions = [
        {
            title: 'Upload Document',
            classes: 'flex gap-2 items-center px-3 ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: UploadWhiteIcon },
            onAction: () => {
                toggleUploadFile();
            }
        }
    ];

    const [filterCondition, setFilterCondition] = useState('All');

    const filterByStatus = (status) => {
        const condition = status === 'Completed' ? true : false;
        setFilterCondition(status);
        const newFiles = files?.filter((item) => {
            if (condition === false) {
                return !item?.final_doc && item?.status !== 'revoke';
            } else {
                return item.final_doc && item?.status !== 'revoke';
            }
        });
        setFilesData(newFiles);
    };

    const sort_options = [
        {
            title: 'Acsending'
        },
        {
            title: 'Descending'
        }
    ];

    const handleFilterByType = (term) => {
        setFilterType(term);
        const result = sortArrayByDate(filesData, term === 'Acsending' ? 'ASC' : 'DESC');

        setFilesData(result);
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        const result = sortArrayByDate(filesData, sortAsc ? 'ASC' : 'DESC');

        setFilesData(result);
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        setRevokeLoader(true);

        dispatch(filesRevokeThunk({ customer_document_id: [revokeId] }))
            .then((response) => {
                if (response.payload) {
                    getFilesHandler();
                    toastHandler('File Revoked Successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                setRevokeLoader(false);

                setRevokeIndex(null);
            });
    };

    const handleRevoke = (customer_document_id, index) => {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            if (!customer_document_id) {
                toastHandler('Either file is finalized or corrupted', TOAST_TYPE_ERROR);
                return;
            }
            setRevokeIndex(index);
            setRevokeId(customer_document_id);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };

    const checkExistance = (users) => {
        const findUser = users?.find((user) => user?.user_id === loginData?.user_id);
        if (findUser) {
            return true;
        } else {
            return false;
        }
    };

    const alreadySigned = (users) => {
        const findUser = users?.find((user) => user?.user_id === loginData?.user_id);
        if (findUser?.signed) {
            return true;
        } else {
            return false;
        }
    };

    const handleDeleteIds = (e, customer_document_id) => {
        if (e.target.checked) {
            const newIds = [...deleteIds];
            const filteredFiles = files?.filter(
                (file) => file?.total_signs_required > 0 && file?.total_signs_required !== file?.signed
            );

            const findFile = filteredFiles?.find((file) => file?.customer_document_id === customer_document_id);
            if (findFile) {
                newIds.push(customer_document_id);
                setDeleteIds(newIds);
            }
        } else {
            const newIds = [...deleteIds];
            const findId = newIds?.find((item) => item === customer_document_id);
            if (findId) {
                const index = newIds.indexOf(findId);
                newIds.splice(index, 1);
                setDeleteIds(newIds);
            }
        }
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            let ids = [];
            const filteredFiles = files?.filter(
                (file) =>
                    file?.total_signs_required > 0 &&
                    file?.total_signs_required !== file?.signed &&
                    file?.status !== 'revoke'
            );
            if (filteredFiles) {
                filteredFiles?.forEach((file) => {
                    ids?.push(file?.customer_document_id);
                });

                setDeleteIds(ids);
            }
        } else {
            setDeleteIds([]);
        }
    };

    const handleDeleteAll = () => {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            const ids = [...deleteIds];

            dispatch(setLoader(true));
            dispatch(filesRevokeThunk({ customer_document_id: ids }))
                .then((response) => {
                    if (response.payload) {
                        window.location.reload();
                        toastHandler('Files Revoked Successfully', TOAST_TYPE_SUCCESS);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    const pendingUsers = (users) => {
        const filteredUsers = users?.filter((user) => user?.signed === false);
        return filteredUsers;
    };

    useEffect(() => {
        const driverObj = driver({
            showProgress: true,
            onDestroyed: () => {
                const newIndicators = {
                    ...storedInDicators,
                    authorized_folder: true
                };

                localStorage.setItem('indicators', JSON.stringify(newIndicators));
            },
            steps: [
                {
                    element: '.sign-pdf-indicate',

                    popover: {
                        title: 'Requested PDF actions',
                        description: 'In this section you can invite your employees assign them role groups.',
                        popoverClass: 'my-custom-popover-class '
                    }
                },
                {
                    element: '.status-pdf-indicate',

                    popover: {
                        title: 'PDF status',
                        description:
                            'In this section you can create custom forms save them as draft or publish them for your clients',
                        popoverClass: 'my-custom-popover-class '
                    }
                },
                {
                    element: '.recipient-pdf-indicate',

                    popover: {
                        title: 'PDF Recipients',
                        description:
                            'In this section you can create custom forms save them as draft or publish them for your clients',
                        popoverClass: 'my-custom-popover-class '
                    }
                }
            ]
        });

        //remember to change this to  !loginData?.second_time_login && !storedInDicators?.file_manager
        if (!loginData?.second_time_login && !storedInDicators?.authorized_folder) {
            driverObj.drive();
        } else {
            driverObj.destroy();
        }

        return () => {
            driverObj.destroy();
        };
    }, []);
    const filterDataHandler = (value) => {
        setSearchTerm(value);

        const result = files?.filter((item) => item?.name?.toLowerCase()?.includes(value?.toLowerCase()));

        const newFiles = files?.filter((item) => {
            if (filterCondition === 'All') {
                return item?.name?.toLowerCase()?.includes(value?.toLowerCase());
            } else if (filterCondition === 'Completed') {
                return (
                    item?.name?.toLowerCase()?.includes(value?.toLowerCase()) &&
                    item.final_doc &&
                    item?.status !== 'revoke'
                );
            } else if (filterCondition === 'Pending') {
                return (
                    item?.name?.toLowerCase()?.includes(value?.toLowerCase()) &&
                    !item.final_doc &&
                    item?.status !== 'revoke'
                );
            } else {
                return item?.name?.toLowerCase()?.includes(value?.toLowerCase()) && item.final_doc;
            }
        });

        setFilesData(newFiles);
    };

    const [isZoomed, setIsZoomed] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);

    const handleViewFile = (file) => {
        if (file?.file_type !== 'application/pdf') {
            setIsZoomed(true);
            setPreviewFile(file);
        } else {
            navigate(`/pdf/viewer/${id}`, {
                state: {
                    url: file?.final_doc ? file?.final_doc : file?.url,
                    name: file?.name
                }
            });
        }
    };
    return (
        <SidebarLayout>
            <div>
                <div className="app-dashboard relative  ">
                    <ConfirmationModal
                        isOpen={isConfirmationOpen}
                        onClose={() => setIsConfirmationOpen(false)}
                        onConfirm={handleDelete}
                        title="Revoke Confirmation"
                        description="Are you sure you want to revoke this document. other signee's will get notified and they won't be able to sign this document."
                    />

                    {isLoading && <Loader />}
                    {showUploadFile && (
                        <UploadFile
                            message="Only Pdf files are allowed"
                            toggleUploadFile={toggleUploadFile}
                            autocheck={true}
                            onlyPdf={true}
                            acceptFormate={acceptFormate}
                        />
                    )}
                    <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                        {' '}
                        <div>
                            <Jumbotron
                                title="Authorised Folder"
                                path={[
                                    { title: 'Clients', link: 'clients' },
                                    {
                                        title:
                                            customer && clientTypes[customer?.client_type] !== 'All'
                                                ? clientTypes[customer?.client_type]
                                                : 'All Clients',
                                        link: `app/users?type=${
                                            customer
                                                ? clientTypes[customer?.client_type]?.[0]?.toLowerCase() +
                                                  clientTypes[customer?.client_type]?.slice(1)
                                                : 'all'
                                        }&reload=true`
                                    },
                                    {
                                        title: `${getProfileName(customer)}`,
                                        link: `app/user/profile/${id}`
                                    },
                                    { title: 'Document Hub', link: `file/manager/${id}` },
                                    { title: 'Authorised folder', link: '' }
                                ]}
                                backArrowProp={backArrowProp}
                                actionsRequired={true}
                                actions={customer?.status === 'active' ? managerActions : []}
                            />

                            {customer?.status === 'active' ? (
                                <div className="mb-5 md:mx-10 mx-5">
                                    <DragFile
                                        selectedFiles={selectedFiles}
                                        atChange={(file) => {
                                            handleSelectedFiles(file);
                                        }}
                                        accept={acceptFormate}
                                        isMultiple={false}
                                        dragMessage="Drag files here to upload, or browse for files"
                                        dropMessage="Drop the selected files in this box... "
                                        onlyPdf={true}
                                        message="Only PDF is allowed"
                                    />
                                    <div className="mt-2">
                                        {selectedFiles?.length > 0 && (
                                            <div className="flex justify-end items-center">
                                                <div className="flex gap-2 items-center">
                                                    <Button
                                                        classes="border-button text-secondarybg h-size8 px-10"
                                                        title="Cancel"
                                                        atClick={() => {
                                                            cancelAction();
                                                        }}
                                                        buttonType="button"
                                                    />
                                                    <Button
                                                        classes="ann-btn h-size8 px-10"
                                                        title="Upload"
                                                        atClick={() => {
                                                            uploadFiles();
                                                        }}
                                                        buttonType="button"
                                                        iconSet={{ loader: SmallLoaderWhite }}
                                                        isloading={fileLoader}
                                                        disabled={fileLoader ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : !isLoading ? (
                                <div className="mb-5 mx-5 md:mx-10">
                                    <div className="border-l-4 border-red-400 bg-red-50 p-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <InformationCircleIcon
                                                    className="h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-sm font-medium text-red-800">
                                                    Client Account has been Removed
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            <div className="md:mx-10 mx-5">
                                <div className="flex justify-between items-center flex-wrap gap-5">
                                    {' '}
                                    <div className="flex gap-2 items-center flex-wrap">
                                        <TrippleSwitch
                                            titleOne="All"
                                            titleTwo="Pending"
                                            titleThree="Completed"
                                            border="1px solid #BBBBBB"
                                            atClick={(title) => {
                                                if (title === 'All') {
                                                    setFilterCondition('All');
                                                    setFilesData(files);
                                                    return;
                                                }
                                                filterByStatus(title);
                                            }}
                                        />
                                        <div>
                                            <SearchInput
                                                width="300px"
                                                atChange={(e) => {
                                                    filterDataHandler(e.target.value);
                                                }}
                                                value={searchTerm}
                                                placeholder="Search"
                                                inputBorder="1px solid #979797"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        {deleteIds?.length > 0 && (
                                            <Button
                                                classes="ann-btn h-size8 px-12"
                                                title={`Revoke ( ${deleteIds?.length} )`}
                                                atClick={() => {
                                                    handleDeleteAll();
                                                }}
                                                buttonType="button"
                                                disabled={isLoading ? true : false}
                                            />
                                        )}
                                        <div>
                                            <Menu
                                                title="Order By"
                                                iconSet={{ first: SortIcon, second: null }}
                                                options={sort_options}
                                                gap={5}
                                                lastMl={5}
                                                isModalDisabled={true}
                                                condition={sortAsc}
                                                atMenuClick={() => {
                                                    handleSortByDate();
                                                }}
                                                atClick={(title) => {
                                                    handleFilterByType(title);
                                                }}
                                                text="max-md pr-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {' '}
                                    <div className="files-table-wrapper">
                                        {' '}
                                        <div className="files-table rounded-md p-5 mt-5">
                                            <div className="grid primary-fonts text-md  grid-cols-7 gap-5">
                                                <div className=" col-span-2 gap-5 flex items-center  ">
                                                    <div className="files-table-label  flex items-center">
                                                        <CheckboxInput atChange={(e) => handleSelectAll(e)} />
                                                    </div>
                                                    <div>Title</div>
                                                </div>

                                                <div className=" flex items-center ">Created At</div>
                                                <div className="flex items-center ">Last Updated</div>
                                                <div className=" flex items-center recipient-pdf-indicate">
                                                    Recipient
                                                </div>
                                                <div className="flex items-center status-pdf-indicate">Status</div>

                                                <div className=" flex items-center sign-pdf-indicate">Action</div>
                                            </div>

                                            <hr className="mt-2" />
                                            <div>
                                                {filesData?.length > 0 ? (
                                                    filesData?.map((file, index) => {
                                                        // if (!file?.customer_document_id) {
                                                        //     return;
                                                        // }
                                                        return (
                                                            <div key={'file_key' + index}>
                                                                {' '}
                                                                <div className="grid grid-cols-7 secondary-fonts text-md gap-5 mt-2">
                                                                    <div className=" col-span-2 flex gap-5 items-center ">
                                                                        <div>
                                                                            <CheckboxInput
                                                                                atChange={(e) =>
                                                                                    handleDeleteIds(
                                                                                        e,
                                                                                        file?.customer_document_id
                                                                                    )
                                                                                }
                                                                                checked={deleteIds.includes(
                                                                                    file?.customer_document_id
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        <span
                                                                            onClick={() => setPdfUrl(file?.url)}
                                                                            className=" cursor-pointer break-all"
                                                                        >
                                                                            {file ? file?.name : 'one'}
                                                                        </span>
                                                                    </div>

                                                                    <div className="flex items-center break-all">
                                                                        {formateDate(file?.created_at)}
                                                                    </div>
                                                                    <div className="flex items-center  break-all">
                                                                        {formateDate(file?.updated_at)}
                                                                    </div>
                                                                    <div className=" flex items-center">
                                                                        {file?.users?.length ? (
                                                                            <ImagesStack
                                                                                dataList={file?.users}
                                                                                keyword="image"
                                                                            />
                                                                        ) : (
                                                                            <div>--</div>
                                                                        )}
                                                                    </div>
                                                                    <div className=" flex items-center gap-2">
                                                                        {file?.status === 'revoke' ? (
                                                                            <span className="revoke-display relative">
                                                                                Revoked
                                                                            </span>
                                                                        ) : file?.final_doc ? (
                                                                            <span className="positive-status relative">
                                                                                Completed
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                onMouseLeave={handleShowChip}
                                                                                onMouseEnter={() =>
                                                                                    handleShowChip(index)
                                                                                }
                                                                                className="negitive-status relative flex gap-2 items-center flex-wrap"
                                                                            >
                                                                                <div className="">
                                                                                    {' '}
                                                                                    <span
                                                                                        onMouseEnter={() =>
                                                                                            openModal(index)
                                                                                        }
                                                                                        onMouseLeave={closeModal}
                                                                                    >
                                                                                        {' '}
                                                                                        <Badge
                                                                                            dataList={file?.users
                                                                                                ?.filter(
                                                                                                    (user) =>
                                                                                                        user.signed ===
                                                                                                        false
                                                                                                )
                                                                                                ?.slice(0, 3)}
                                                                                            keyword="image"
                                                                                            remain={
                                                                                                file?.users?.filter(
                                                                                                    (user) =>
                                                                                                        user.signed ===
                                                                                                        false
                                                                                                )?.length - 3 ?? 0
                                                                                            }
                                                                                            isStatus={true}
                                                                                        />{' '}
                                                                                    </span>
                                                                                    {showChip &&
                                                                                        chipIndex === index && (
                                                                                            <div className="absolute bg-white rounded-md shadow-custom top-full right-full z-40">
                                                                                                {file?.users?.map(
                                                                                                    (user, index) => (
                                                                                                        <div
                                                                                                            key={
                                                                                                                'file' +
                                                                                                                index
                                                                                                            }
                                                                                                            href="#"
                                                                                                            className={cn(
                                                                                                                '   hover:bg-gray-100  text-gray-700',
                                                                                                                'block px-4 py-2 text-sm '
                                                                                                            )}
                                                                                                        >
                                                                                                            <div className="flex gap-2 items-center justify-between">
                                                                                                                <div className="flex gap-2 items-center">
                                                                                                                    <div>
                                                                                                                        <DefaultProfileViewer
                                                                                                                            image={
                                                                                                                                user?.image
                                                                                                                            }
                                                                                                                            width="35px"
                                                                                                                            height="35px"
                                                                                                                            name={
                                                                                                                                user?.first_name +
                                                                                                                                ' ' +
                                                                                                                                user?.last_name
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div>
                                                                                                                        <div className="secondary-fonts text-sm">
                                                                                                                            {' '}
                                                                                                                            {`${user?.first_name} ${user?.last_name}`.slice(
                                                                                                                                0,
                                                                                                                                15
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                        <div className="secondary-fonts text-sm">
                                                                                                                            {user?.email.slice(
                                                                                                                                0,
                                                                                                                                15
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {user?.signed ? (
                                                                                                                        <BlueTick />
                                                                                                                    ) : (
                                                                                                                        <IncompleteCircle />
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}{' '}
                                                                                            </div>
                                                                                        )}
                                                                                </div>

                                                                                <span> Pending</span>
                                                                            </span>
                                                                        )}
                                                                    </div>

                                                                    <div className="flex items-center gap-2 ">
                                                                        <div
                                                                            onClick={() => handleViewFile(file)}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            {' '}
                                                                            <EyeIcon />{' '}
                                                                        </div>

                                                                        {file?.users?.length < 1 ? (
                                                                            <Button
                                                                                tooltip={'Request Signature'}
                                                                                atClick={() => {
                                                                                    navigate(`/request/pdf/${id}`, {
                                                                                        state: file
                                                                                    });
                                                                                }}
                                                                                classes=" cursor-pointer mt-2"
                                                                                iconSet={{
                                                                                    leftIconHTML: (
                                                                                        <BgEditPencil
                                                                                            className={
                                                                                                '!w-[32px] !h-[32px]'
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <div className="flex gap-2 items-center">
                                                                                {checkExistance(file.users) &&
                                                                                !alreadySigned(file?.users) &&
                                                                                file?.status !== 'revoke' ? (
                                                                                    <Button
                                                                                        tooltip={'Sign Document'}
                                                                                        iconSet={{
                                                                                            leftIconHTML: (
                                                                                                <RequestSignatureIcon className="w-5 !stroke-2 h-5" />
                                                                                            )
                                                                                        }}
                                                                                        atClick={() => {
                                                                                            if (
                                                                                                alreadySigned(
                                                                                                    file?.users
                                                                                                )
                                                                                            ) {
                                                                                                return toastHandler(
                                                                                                    'you have already signed this document',
                                                                                                    TOAST_TYPE_ERROR
                                                                                                );
                                                                                            }
                                                                                            navigate(
                                                                                                `/sign/pdf/${file.customer_document_id}/${id}`,
                                                                                                {
                                                                                                    state: id
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        classes="cursor-pointer mt-2"
                                                                                    />
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {!file?.final_doc &&
                                                                                file?.status !== 'revoke' ? (
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleRevoke(
                                                                                                file?.customer_document_id,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        className="cursor-pointer "
                                                                                    >
                                                                                        <span
                                                                                            className={`${
                                                                                                revokeLoader &&
                                                                                                revokeIndex === index
                                                                                                    ? 'animate-spin-fast'
                                                                                                    : ''
                                                                                            }`}
                                                                                        >
                                                                                            {' '}
                                                                                            <BgRevokeIcon
                                                                                                className={
                                                                                                    '!w-[32px] !h-[32px]'
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <hr className="mt-2" />
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <NoDataFound message="No Data Found" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RoleBasedGuard>
                </div>
                {isZoomed && previewFile && previewFile?.file_type !== 'application/pdf' && (
                    <ZoomImage
                        src={previewFile?.final_doc ? previewFile?.final_doc : previewFile?.url}
                        alt="Profile"
                        onClose={() => {
                            setIsZoomed(false);
                            setPreviewFile(null);
                        }}
                    />
                )}
            </div>
        </SidebarLayout>
    );
};
