import React, { useState } from 'react';
import { TasksCompletedPage } from 'components/workplace-content/tasks-pages/tasks-cards/tasks-completed/TasksCompletedPage';
import { CompleteTaskDetails } from 'components/workplace-content/tasks-pages/tasks-cards/tasks-completed/tasks/task-details/CompleteTaskDetails';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';
export const CompletedTasks = () => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleTaskDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {showDetails && (
                    <CompleteTaskDetails
                        open={showDetails}
                        setOpen={setShowDetails}
                        toggleTaskDetails={toggleTaskDetails}
                    />
                )}

                <div>
                    <RoleBasedGuard module={'Tasks'} sx="h-[50vh]" permissions={['read', 'write', 'admin']}>
                        <TasksCompletedPage toggleTaskDetails={toggleTaskDetails} />
                    </RoleBasedGuard>
                </div>
            </SidebarLayout>
        </div>
    );
};
