import React from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid';
import { generateRandomProfileLink } from 'utills/globalVars';

export const GridProfiles = ({ users, onClick }) => {
    return (
        <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {users &&
                users?.map((person) => (
                    <li
                        key={person?.email}
                        className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg cursor-pointer bg-white text-center shadow"
                    >
                        <div
                            onClick={() => onClick(person?.business_employee?.business_employee_id)}
                            className="flex flex-1 flex-col p-8"
                        >
                            <img
                                alt=""
                                src={
                                    !person?.image
                                        ? generateRandomProfileLink(person?.first_name, person?.last_name)
                                        : person?.image
                                }
                                className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                            />
                            <h3 className="mt-6 text-sm font-medium text-gray-900">
                                {person?.first_name} {person?.last_name}{' '}
                            </h3>
                            <dl className="mt-1 flex flex-grow flex-col justify-between">
                                <dt className="sr-only">Title</dt>
                                <dd className="text-sm text-gray-500">{person?.email}</dd>
                                <dt className="sr-only">Role</dt>
                                <dd className="mt-3">
                                    <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        {person?.business_group_name || '--'}
                                    </span>
                                </dd>
                            </dl>
                        </div>
                        <div>
                            <div className="-mt-px flex divide-x divide-gray-200">
                                <div className="flex w-0 flex-1">
                                    <a
                                        href={`mailto:${person?.email}`}
                                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                    >
                                        <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-[#979797]" />
                                        Email
                                    </a>
                                </div>
                                <div className="-ml-px flex w-0 flex-1">
                                    <a
                                        href={`tel:${person.telephone}`}
                                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                    >
                                        <PhoneIcon aria-hidden="true" className="h-5 w-5 text-[#979797]" />
                                        {person?.phone_number}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
        </ul>
    );
};
