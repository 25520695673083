import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { setDefaultSignatureThunk } from 'store/settings/JobPortal';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { toBoolean } from 'utills/handleText';

const SignatureModuleDefault = ({ title, email_type, dafaultDataObject, loadSignatureTable }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data: getUserEmailSignature } = useSelector((state) => state.getUserEmailSignature);

    const [optionsList, setOptionsList] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        let signatureslist = [
            {
                label: 'No Signature',
                value: ''
            }
        ];
        if (getUserEmailSignature) {
            getUserEmailSignature.map((obj) => {
                signatureslist.push({
                    label: obj?.name,
                    value: obj?.user_signature_id
                });
            });
        }
        setOptionsList(signatureslist);
    }, [getUserEmailSignature]);

    useEffect(() => {
        if (dafaultDataObject) {
            setData({
                ...dafaultDataObject,
                new_email_id: dafaultDataObject?.new_email_id
                    ? dafaultDataObject?.new_email_id?.user_signature_id
                    : null,
                reply_email_id: dafaultDataObject?.reply_email_id
                    ? dafaultDataObject?.reply_email_id?.user_signature_id
                    : null,
                is_positioning: toBoolean(dafaultDataObject?.is_positioning || 'false')
            });
        }
    }, [dafaultDataObject]);

    const setDataFun = (target, value) => {
        setData({ ...data, [target]: value });
    };

    const handleUpdateChanges = (target, value) => {
        if (target) {
            const relatedKey =
                target === 'is_positioning'
                    ? data?.new_email_id
                        ? 'new_email_id'
                        : data?.reply_email_id
                        ? 'reply_email_id'
                        : null
                    : null;

            if (target === 'is_positioning' && (!relatedKey || !data[relatedKey])) {
                toastHandler('At least one Signature is required', TOAST_TYPE_ERROR);
                setDataFun(target, false);
                return false;
            }

            const jsonPayload = {
                business_id: business_id,
                email_type: email_type,
                ...(['new_email_id', 'reply_email_id'].includes(target) && data[target] && data[target] !== null
                    ? { previous_selected_id: data?.[target] }
                    : {}),
                [target]: value ? value?.toString() : 'null',
                ...(relatedKey ? { [relatedKey]: data?.[relatedKey] } : {})
            };

            dispatch(setLoader(true));
            dispatch(setDefaultSignatureThunk(jsonPayload))
                .then((res) => {
                    if (res.payload) {
                        setDataFun(target, value);
                        loadSignatureTable();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    return (
        <div>
            <DefaultLightBgCard sx="bg-white" title={title}>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
                    <div className="col-span-5">
                        <div className="">
                            <label className="inputLabel">FOR NEW EMAILS USE</label>
                        </div>
                        <div className="mt-1">
                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="label"
                                selectedValue={data?.new_email_id}
                                valuePropertyName="value"
                                optionsData={optionsList}
                                sx="rounded-xl py-3 ring-gray-400"
                                onChangeValue={(obj) => {
                                    handleUpdateChanges('new_email_id', obj.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-span-5">
                        <div className="">
                            <label className="inputLabel">ON REPLY/FORWARD USE</label>
                        </div>
                        <div className="mt-1">
                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="label"
                                selectedValue={data?.reply_email_id}
                                valuePropertyName="value"
                                optionsData={optionsList}
                                sx="rounded-xl py-3 ring-gray-400"
                                onChangeValue={(obj) => handleUpdateChanges('reply_email_id', obj.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="my-3">
                    <PrimaryCheckbox
                        id={title + 'beforeQuoted'}
                        name={title + 'beforeQuoted'}
                        onchange={() => {
                            handleUpdateChanges('is_positioning', !data?.is_positioning);
                        }}
                        htmlFor={title + 'beforeQuoted'}
                        checked={data?.is_positioning === true || data?.is_positioning === 'true'}
                        label={`Insert signature before the quoted text in replies, and remove the '--' line that precedes it.`}
                    />
                </div>
            </DefaultLightBgCard>
        </div>
    );
};

export default SignatureModuleDefault;
