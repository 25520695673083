/* eslint-disable no-unused-vars */
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useRef, useState } from 'react';
import EmailManagerInboxMenu from './EmailManagerInboxMenu';
import EmailManagerThreadList from './EmailManagerThreadList';
import EmailManagerThreadDetails from './EmailManagerThreadDetails';
import { cn } from 'utills/tailwindUtil';
import EmailManagerThreadDetailsMenu from './EmailManagerThreadDetailsMenu';
import { useSelector } from 'react-redux';

export const EmailManager = () => {
    const [sideProfile, setSideProfile] = useState(false);

    const { isSidebarOpen } = useSelector((state) => state.global);

    const parentRef = useRef(null); // Create a ref for the parent div
    const [parentWidth, setParentWidth] = useState(0); // State to store the width
    // Function to update the width
    const updateWidth = () => {
        if (parentRef.current) {
            const width = parentRef.current.getBoundingClientRect().width;
            setParentWidth(width);
        }
    };

    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [isSidebarOpen]);

    return (
        <SidebarLayout>
            <div className="app-dashboard">
                <Jumbotron
                    title="Email Manager"
                    path={[
                        {
                            link: '',
                            title: 'Email Manager'
                        }
                    ]}
                />

                <div ref={parentRef} className="md:mx-10 mx-5 flex gap-5 items-center flex-wrap">
                    <div className="flex gap-2 w-full max-h-[80vh]">
                        <div className="hidden xl:flex w-auto  xl:col-span-2 bg-white rounded-xl px-2 py-2 shadow-md">
                            <EmailManagerInboxMenu defaultOpen />
                            {/* d */}
                        </div>

                        <div className="w-full">
                            <div className="flex h-full gap-2 transition-all duration-300 w-auto flex-col lg:flex-row">
                                <div
                                    className={cn(
                                        'bg-white overflow-auto rounded-xl px-2 py-2 shadow-md transition-all duration-300 !min-w-[250px]  lg:w-1/3'
                                    )}
                                >
                                    <EmailManagerThreadList />
                                </div>

                                <div
                                    className={cn(
                                        'bg-white overflow-auto rounded-xl px-2 py-2 shadow-md transition-all duration-300 !lg:min-w-[400px] flex-1 lg:w-1/3'
                                    )}
                                >
                                    <EmailManagerThreadDetails
                                        sideProfile={sideProfile}
                                        setSideProfile={setSideProfile}
                                    />
                                </div>

                                {sideProfile && (
                                    <EmailManagerThreadDetailsMenu
                                        parentWidth={parentWidth}
                                        open={sideProfile}
                                        setOpen={setSideProfile}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};
