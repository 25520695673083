import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../dashboard-css/auth-css/emailSuccess.css';
import { EnvelopeSuccess } from '../../../utills/svgs/EnvelopeSuccess';
import Ripples from 'react-ripples';
import AuthCardLayout from 'layouts/AuthCardLayout';

const EmailSuccessPage = () => {
    const navigate = useNavigate();

    return (
        <div className="flex min-h-[100vh] flex-col justify-center pb-10 sm:px-2 lg:px-2">
            <AuthCardLayout>
                {' '}
                <div>
                    <div>
                        <div className="envelope">
                            <div className="envelope-wrapper">
                                <EnvelopeSuccess />
                            </div>
                        </div>

                        <div className="congrats">
                            <h1 className="text-center">Congratulation!</h1>

                            <p className="text-center table-title">Your email 2FA has been enabled successfully.</p>
                        </div>

                        <div className="mt-5 mb-28">
                            <center>
                                <Ripples during={2000} color="#979797">
                                    <button
                                        onClick={() => navigate('/authentication-and-security')}
                                        className="px-12  ann-btn rounded-lg buttons-font py-2"
                                    >
                                        Proceed
                                    </button>
                                </Ripples>
                            </center>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};

export default EmailSuccessPage;
