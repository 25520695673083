import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import React from 'react';
import { AllClientsIcon } from 'utills/svgs/AllClientsIcon';
import { ClientsActive } from 'utills/svgs/ClientsActive';
import { ClientsInactive } from 'utills/svgs/ClientsInactive';
import { InvitedEmail } from 'utills/svgs/InvitedEmail';
import { MagnetUser } from 'utills/svgs/MagnetUser';
import { ProspectiveIcon } from 'utills/svgs/ProspectiveIcon';

export const ClientsPage = () => {
    const clientsCards = [
        {
            title: 'All Clients',
            iconSet: { icon: AllClientsIcon },
            link: '/app/users?type=all&reload=true'
        },
        {
            title: 'Prospective Clients',
            iconSet: { icon: ProspectiveIcon },
            link: '/app/users?type=prospective&reload=true'
        },
        {
            title: 'Active Clients',
            iconSet: { icon: ClientsActive },
            link: '/app/users?type=active&reload=true'
        },
        {
            title: 'Inactive Clients',
            iconSet: { icon: ClientsInactive },
            link: '/app/users?type=inactive&reload=true'
        },
        {
            title: 'Invited Clients',
            iconSet: { icon: InvitedEmail },
            link: '/invites'
        },
        {
            title: 'Leads',
            iconSet: { icon: MagnetUser },
            link: '/leads'
        }
    ];
    return (
        <div>
            <Jumbotron title="Clients" highlightDirectory="Clients" />
            <div className="flex items-center flex-wrap gap-5 mt-3 mx-5 md:mx-10">
                {clientsCards?.map((card, index) => (
                    <div key={'client_grid' + index}>
                        <GridCard text={card.title} iconSet={card.iconSet} atClick={card.atClick} link={card.link} />
                    </div>
                ))}
            </div>
        </div>
    );
};
