import React from 'react';

export const BgBrownTimes = () => {
    return (
        <div>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#666666" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.345 8.0909C17.7356 7.70037 17.7356 7.06721 17.345 6.67668C16.9545 6.28616 16.3213 6.28616 15.9308 6.67668L12.0104 10.5971L8.08992 6.67668C7.6994 6.28616 7.06623 6.28616 6.67571 6.67668C6.28518 7.06721 6.28518 7.70037 6.67571 8.0909L10.5962 12.0113L6.67668 15.9308C6.28616 16.3213 6.28616 16.9545 6.67668 17.345C7.06721 17.7356 7.70037 17.7356 8.0909 17.345L12.0104 13.4256L15.9298 17.345C16.3204 17.7356 16.9535 17.7356 17.3441 17.345C17.7346 16.9545 17.7346 16.3213 17.3441 15.9308L13.4246 12.0113L17.345 8.0909Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
