import { Button } from 'components/atoms/buttons/Button';
import Slideover from 'components/atoms/SlideOver';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import React from 'react';
import { GmailIcon } from 'utills/svgs/GmailIcon';
import { MailIconSuceess } from 'utills/svgs/MailIconSuceess';
import { OutlookIcon } from 'utills/svgs/OutlookIcon';

const AddAccountComponent = ({ open, setOpen, showSuccessModal }) => {
    return (
        <div>
            <Slideover open={open} setOpen={setOpen}>
                <div className="text-3xl font-fira font-[500]">Add Account</div>
                <SecondaryParagraph sx={'mt-5'}>
                    Auth-based login for Gmail and Outlook enable users to securely sign in using their existing email
                    accounts.
                </SecondaryParagraph>

                <div className="mt-8 w-full">
                    {showSuccessModal ? (
                        <SuccessModal open={open} setOpen={setOpen} />
                    ) : (
                        <div className="flex gap-5 w-full flex-col">
                            <Button
                                iconSet={{ leftIconHTML: <OutlookIcon className={'w-[25px] h-[25px]'} /> }}
                                title={'Login with Outlook'}
                                classes={
                                    'ann-btn rounded-xl w-full py-2.5 font-normal flex items-center justify-center gap-2'
                                }
                            />
                            <Button
                                iconSet={{ leftIconHTML: <GmailIcon className={'w-[25px] h-[25px]'} /> }}
                                title={'Login with Gmail'}
                                classes={
                                    'ann-btn rounded-xl w-full py-2.5 font-normal flex items-center justify-center gap-2'
                                }
                            />
                        </div>
                    )}
                </div>
            </Slideover>
        </div>
    );
};

const SuccessModal = ({ open, setOpen }) => {
    return (
        <>
            <div className="flex gap-3 flex-col items-center justify-center w-[80%] m-auto">
                <MailIconSuceess className={'w-[200px] h-[200px]'} />
                <PrimaryParagraph sx={'font-semibold text-lg'}>Account Connected!</PrimaryParagraph>
                <SecondaryParagraph sx={'font-normal text-center text-lg'}>
                    Your Gmail account has been connected successfully!
                </SecondaryParagraph>
                <Button
                    groupSx={'w-full'}
                    title={'Ok!'}
                    atClick={() => setOpen(!open)}
                    classes={
                        'ann-btn rounded-xl !w-full py-2.5 font-normal flex items-center justify-center gap-2 mt-5'
                    }
                />
            </div>
        </>
    );
};

export default AddAccountComponent;
