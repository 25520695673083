import React from 'react';
import './css/sides.css';
import { useState } from 'react';

import Ripples from 'react-ripples';
import { toastHandler } from 'responseHanlder';
import { useDispatch, useSelector } from 'react-redux';
import { editService, setLoader } from 'store/global/globalReducer';
import { addServiceThunk, serviceUpdate } from 'store/auth/slices';
import Slideover from 'components/atoms/SlideOver';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';

const AddServiceSide = ({ open, setOpen, toggleServiceSide, toggleServiceUpdated }) => {
    const dispatch = useDispatch();
    const service = useSelector((state) => state.global.singleService);

    const [formData, setFormData] = useState({
        title: service?.name ?? '',
        consultationCharges: service?.charges ?? '',
        minTimeOfConsultation: service?.duration ?? '',
        description: service?.description ?? ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        const hasEmptyValue = Object.values(formData).some((value) => {
            return (
                value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0)
            );
        });
        if (hasEmptyValue) {
            return toastHandler('Please fill in all required fields.', 'error');
        }

        dispatch(setLoader(true));
        const serviceData = {
            name: formData.title,
            charges: formData.consultationCharges,
            duration: formData.minTimeOfConsultation,
            description: formData.description
        };

        dispatch(addServiceThunk(serviceData))
            .then((response) => {
                if (response.payload) {
                    toggleServiceSide();
                    toastHandler('service added successfully', 'success');
                    toggleServiceUpdated();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const updateService = () => {
        const hasEmptyValue = Object.values(formData).some((value) => {
            return (
                value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0)
            );
        });
        if (hasEmptyValue) {
            return toastHandler('Please fill in all required fields.', 'error');
        }

        dispatch(setLoader(true));
        const serviceData = {
            business_service_id: service.business_service_id,
            name: formData.title,
            charges: formData.consultationCharges,
            duration: formData.minTimeOfConsultation?.toString(),
            description: formData.description
        };

        dispatch(serviceUpdate(serviceData))
            .then((response) => {
                if (response.payload) {
                    dispatch(editService(''));
                    toggleServiceSide();
                    toastHandler('service Updated successfully', 'success');
                    toggleServiceUpdated();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                footer={
                    <div className="mb-5 mt-28 flex justify-center items-center">
                        <Ripples during={2000} color="#333333">
                            <button
                                onClick={service ? updateService : handleSubmit}
                                className="ann-btn buttons-font-lighter font-fira px-20 py-2 text-white rounded-lg"
                            >
                                {service ? 'Update' : 'Add'}
                            </button>
                        </Ripples>
                    </div>
                }
                showLoader
                title={`${service ? 'Edit' : 'Add'} service`}
            >
                <div>
                    <div className="add-ann-form">
                        <div className=" mt-3 pl-5">
                            <div>
                                <div>
                                    <label className="buttons-font font-poppins">Title</label>
                                </div>
                                <div>
                                    <input
                                        onChange={handleInputChange}
                                        type="text"
                                        value={formData.title}
                                        name="title"
                                        maxLength={30}
                                        placeholder="Title"
                                        className="px-3 rounded-xl inputs-font font-poppins mt-1 font-normal "
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label className="buttons-font font-poppins">Consultation Charges</label>
                                </div>
                                <div>
                                    <input
                                        onChange={handleInputChange}
                                        type="number"
                                        value={formData.consultationCharges}
                                        name="consultationCharges"
                                        placeholder="charges"
                                        className="px-3 rounded-xl mt-1 inputs-font font-poppins font-normal "
                                        maxLength="7"
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label className="buttons-font font-poppins">Min-Time of Consultation:</label>
                                </div>
                                <div className="w-full">
                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="label"
                                        selectedValue={formData?.minTimeOfConsultation?.toString()}
                                        valuePropertyName={'value'}
                                        optionsData={[
                                            {
                                                label: '30 min',
                                                value: '30'
                                            },
                                            {
                                                label: '45 min',
                                                value: '45'
                                            },
                                            {
                                                label: '1 hour',
                                                value: '60'
                                            },
                                            { label: '1.5 Hour', value: '90' },
                                            { label: '2 Hour', value: '120' },
                                            { label: '2.5 Hour', value: '150' },
                                            { label: '3 Hour', value: '180' }
                                        ]}
                                        sx="rounded-xl mt-1 px-3 py-3 inputs-font w-full font-poppins font-normal  "
                                        onChangeValue={(obj) =>
                                            handleInputChange({
                                                target: { name: 'minTimeOfConsultation', value: obj.value }
                                            })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label className="buttons-font font-poppins">Description</label>
                                </div>
                                <div>
                                    <DefaultTextArea
                                        placeholder="Description"
                                        onChange={(e) =>
                                            handleInputChange({
                                                target: {
                                                    name: 'description',
                                                    value: e
                                                }
                                            })
                                        }
                                        cols="30"
                                        rows="5"
                                        className="rounded-xl mt-1 px-3 py-2 inputs-font font-poppins"
                                        value={formData.description}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default AddServiceSide;
