/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/atoms/buttons/Button';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import { Loader } from 'components/common/Loader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { authEmailSendOtpThunk, verifyTwoFactorEmailThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

export const EmailAuthAlternate = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);

    const { data } = useSelector((state) => state.login);

    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerEnded, setTimerEnded] = useState(false);

    const [otpVal, setOtpVal] = useState('');
    const [trustDevice, setTrustDevice] = useState(false);

    const resendOtpData = {
        user_id: data?.user_id,
        type: 5,
        user_type: 'employee'
    };

    const sendOtp = () => {
        dispatch(setLoader(true));
        dispatch(authEmailSendOtpThunk(resendOtpData))
            .then((response) => {
                if (response.payload) {
                    setTimerEnded(false);
                    setTimeRemaining(90);
                    timeExecution();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const resendOtp = () => {
        dispatch(setLoader(true));
        dispatch(authEmailSendOtpThunk(resendOtpData))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const verifyOtpData = {
        user_id: data.user_id,
        otp: otpVal,
        user_type: 'employee'
    };

    const handleOtp = () => {
        const newOtp = otpVal;
        if (newOtp.length < 6) {
            toastHandler('Please enter full otp', TOAST_TYPE_ERROR);
            return;
        }
        dispatch(setLoader(true));
        dispatch(verifyTwoFactorEmailThunk(verifyOtpData))
            .then((response) => {
                if (response.payload) {
                    sessionStorage.setItem('lostStepper', 1);

                    localStorage.setItem('isLost', true);
                    navigate('/authentication-and-security');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        resendOtp();
    }, []);

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    useEffect(() => {
        timeExecution();
    }, []);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;
    return (
        <div>
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                {isLoading && <Loader />}

                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px] bg-white shadow rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    {' '}
                    <div className="auth-inner email-auth  px-5">
                        <div className="flex gap-2 justify-center items-center ">
                            <div className="text-center text-2xl mt-4  card-layout-title-text">
                                Email OTP Verification
                            </div>
                        </div>

                        <div className="mx-20 mt-5">
                            {' '}
                            <div className="flex gap-2 text-sixth small-text font-poppins text-center ">
                                <div className="">Enter a 6-digit code that is sent to your email address</div>
                            </div>
                            <div className="mt-16 flex justify-center items-center ">
                                <OtpInputs value={otpVal} onChange={handleOtpChange} />
                            </div>
                            <div className="resend-confirmation text-sm font-poppins mt-5 ml-2">
                                <p>
                                    {timerEnded ? (
                                        <span className="text-thirdbg  cursor-pointer" onClick={sendOtp}>
                                            Resend
                                        </span>
                                    ) : (
                                        <span>
                                            <span>
                                                <span className="text-thirdbg  cursor-pointer mr-1">Resend</span>
                                                OTP in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`})
                                            </span>
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className="mt-5">
                                {data?.google_authenticator_2fa && data?.email_2fa ? (
                                    ''
                                ) : (
                                    <div>
                                        <div className="flex gap-2 ml-2 items-center">
                                            <div>
                                                <input
                                                    checked={trustDevice ? true : false}
                                                    type="checkbox"
                                                    onChange={(e) => setTrustDevice(e.target.checked)}
                                                />
                                            </div>
                                            <div className="buttons-font font-poppins">Trust this device</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mt-72 pb-12">
                                <div className="flex justify-between items-center px-1-">
                                    <div>
                                        <Button title=" Back" classes="btn-1 buttons-font px-10 py-2" />
                                    </div>

                                    <div>
                                        <Button
                                            title="Next"
                                            atClick={handleOtp}
                                            classes="btn-2 buttons-font px-10 py-2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
