import React from 'react';

export const ThreeDotIcon = ({ className }) => {
    return (
        <svg width="8" height="24" viewBox="0 0 8 34" xmlns="http://www.w3.org/2000/svg" className={className}>
            <circle cx="4" cy="4" r="4" fill="url(#paint0_linear_6_790)" />
            <circle cx="4" cy="17" r="4" fill="url(#paint1_linear_6_790)" />
            <circle cx="4" cy="30" r="4" fill="url(#paint2_linear_6_790)" />
            <defs>
                <linearGradient
                    id="paint0_linear_6_790"
                    x1="4.39252"
                    y1="0.099352"
                    x2="4.38653"
                    y2="8.00002"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_6_790"
                    x1="4.39252"
                    y1="13.0994"
                    x2="4.38653"
                    y2="21"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_6_790"
                    x1="4.39252"
                    y1="26.0994"
                    x2="4.38653"
                    y2="34"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
