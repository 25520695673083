import useRoleGuard from 'Hooks/useRoleGuard';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const QuadruppleSwitch = ({ titleOne, titleTwo, titleThree, titleFour, titleFive, atClick, activeTab }) => {
    const [selectedItem, setSelectedItem] = useState(activeTab);
    const navigate = useNavigate();
    const { hasPermissions } = useRoleGuard();

    const handleSelect = (title) => {
        setSelectedItem(title);
        atClick(title);
    };
    useEffect(() => {
        setSelectedItem(activeTab);
    }, [activeTab]);
    return (
        <div>
            <div className="md:col-span-2 col-span-3  flex flex-wrap items-center">
                <div className="isolate inline-flex rounded-md shadow-sm w-fit">
                    <div className="flex gap-0 bg-transparent rounded-xl ring-1 ring-outside ring-gray-300 shadow-sm w-full">
                        <div
                            onClick={() => handleSelect('all')}
                            className={`py-2.5 cursor-pointer text-[12px] sm:text-[14px] lg:text-[16px] font-poppins rounded-xl px-4 lg:px-9 ${
                                selectedItem === 'all' && 'bg-leaner '
                            } `}
                        >
                            {titleOne}
                        </div>
                        {hasPermissions('Leads', ['write', 'admin']) && (
                            <div
                                onClick={() => navigate('/leads?reload=false')}
                                className={`py-2.5 cursor-pointer text-[12px] sm:text-[14px] lg:text-[16px] font-poppins rounded-xl px-4 lg:px-9 ${
                                    selectedItem === 'leads' && 'bg-leaner '
                                } `}
                            >
                                Leads
                            </div>
                        )}
                        {hasPermissions('Clients', ['write', 'read', 'admin']) && (
                            <div
                                onClick={() => navigate('/invites?reload=false')}
                                className={`py-2.5 cursor-pointer text-[12px] sm:text-[14px] lg:text-[16px] font-poppins rounded-xl px-4 lg:px-9 ${
                                    selectedItem === 'invites' && 'bg-leaner '
                                } `}
                            >
                                Invited
                            </div>
                        )}

                        <div
                            onClick={() => handleSelect('prospective')}
                            className={`py-2.5 cursor-pointer text-[12px] sm:text-[14px] lg:text-[16px] font-poppins rounded-xl px-4 lg:px-9 ${
                                selectedItem === 'prospective' && 'bg-leaner '
                            }`}
                        >
                            {titleTwo}
                        </div>
                        <div
                            onClick={() => handleSelect('active')}
                            className={`py-2.5 cursor-pointer text-[12px] sm:text-[14px] lg:text-[16px] font-poppins rounded-xl  px-4 lg:px-9 ${
                                selectedItem === 'active' && 'bg-leaner '
                            } `}
                        >
                            {titleThree}
                        </div>
                        <div
                            onClick={() => handleSelect('inactive')}
                            className={`py-2.5 cursor-pointer text-[12px] sm:text-[14px] lg:text-[16px] font-poppins rounded-xl px-4 lg:px-9 ${
                                selectedItem === 'inactive' && 'bg-leaner '
                            } `}
                        >
                            {titleFour}
                        </div>
                        {titleFive && (
                            <div
                                onClick={() => handleSelect('favourits')}
                                className={`py-2.5 cursor-pointer text-[12px] sm:text-[14px] lg:text-[16px] font-poppins rounded-xl px-4 lg:px-9 ${
                                    selectedItem === 'favourits' && 'bg-leaner '
                                } `}
                            >
                                {titleFive}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
