import AuthCardLayout from 'layouts/AuthCardLayout';
import React from 'react';
import { MobileIconStarIcon } from 'utills/svgs/MobileIconStarIcon';

export const GoogleAuthSuccess = (props) => {
    return (
        <div className="flex min-h-[100vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <AuthCardLayout>
                {' '}
                <div className="">
                    <div className="email-success-inner">
                        <div className="envelope">
                            <div className="envelope-wrapper">
                                <MobileIconStarIcon />
                            </div>
                        </div>

                        <div className="congrats mt-5">
                            <h1 className="text-center">Congratulation!</h1>

                            <p className="text-center mt-3">Your Google 2FA has been enabled successfully.</p>
                        </div>

                        <div className=" mt-7 mb-12">
                            <center>
                                <button onClick={() => props.handleCondition(5)} className="px-12 py-2 btn-2">
                                    Proceed
                                </button>
                            </center>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};
