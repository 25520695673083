import React from 'react';

const DocumentListIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M60.7352 23.5572C60.8099 23.736 60.85 23.9285 60.85 24.125V58.2498C60.8441 63.5495 56.5495 67.8441 51.2498 67.85H18.7502C13.4505 67.8441 9.15586 63.5495 9.15 58.2498L9.15 12.7501C9.1559 7.45042 13.4505 3.15586 18.7502 3.15L39.875 3.15C40.0715 3.15 40.264 3.19005 40.4428 3.26477C40.6224 3.33985 40.7839 3.44796 40.9188 3.58306L40.9189 3.58313L60.4169 23.0811L60.4169 23.0812C60.552 23.2161 60.6601 23.3776 60.7352 23.5572ZM60.7352 23.5572L60.8736 23.4994L60.7353 23.5572C60.7353 23.5572 60.7352 23.5572 60.7352 23.5572ZM41.6061 8.44159L41.35 8.18552V8.54765V18.1677V18.1678C41.3521 20.6425 43.3575 22.6479 45.8321 22.65H45.8323H55.4523H55.8145L55.5584 22.3939L41.6061 8.44159ZM57.9 58.2502V58.25V25.75V25.6H57.75H45.8325C41.7299 25.5947 38.4053 22.2701 38.4 18.1675V6.25V6.1H38.25H18.75L18.7498 6.1C15.0791 6.10467 12.1047 9.0791 12.1 12.7498V12.75V58.25L12.1 58.2502C12.1047 61.9209 15.0791 64.8953 18.7498 64.9H18.75H51.25H51.2502C54.9209 64.8953 57.8953 61.9209 57.9 58.2502ZM30.125 25.6H23.625C22.8104 25.6 22.15 24.9396 22.15 24.125C22.15 23.3104 22.8104 22.65 23.625 22.65H30.125C30.9396 22.65 31.6 23.3104 31.6 24.125C31.6 24.9396 30.9396 25.6 30.125 25.6ZM23.625 38.6C22.8104 38.6 22.15 37.9396 22.15 37.125C22.15 36.3104 22.8104 35.65 23.625 35.65H46.375C47.1896 35.65 47.85 36.3104 47.85 37.125C47.85 37.9396 47.1896 38.6 46.375 38.6H23.625ZM23.625 48.65H46.375C47.1896 48.65 47.85 49.3104 47.85 50.125C47.85 50.9396 47.1896 51.6 46.375 51.6H23.625C22.8104 51.6 22.15 50.9396 22.15 50.125C22.15 49.3104 22.8104 48.65 23.625 48.65Z"
                fill="black"
                stroke="white"
                strokeWidth="0.3"
            />
        </svg>
    );
};

export default DocumentListIcon;
