import React, { useEffect, useState } from 'react';
import RoleContent from '../../components/team-components/role-management/RoleContent';
import AddRoleGroup from '../../components/team-components/role-management/role-sides/AddRoleGroup';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import EditRoleGroup from 'components/team-components/role-management/role-sides/EditRoleGroup';
import { SidebarLayout } from 'layouts/SidebarLayout';

const RoleManagement = () => {
    const [showAddRoleGroup, setShowAddRoleGroup] = useState(false);
    const [editRoleId, setEditRoleId] = useState(null);
    const [editModal, seteditModal] = useState(false);

    useEffect(() => {
        if (!editModal) {
            setEditRoleId(null);
        }
    }, [editModal]);

    const toggleAddRoleGroup = () => {
        setShowAddRoleGroup(!showAddRoleGroup);
    };
    const toggleEditRoleGroup = (editId) => {
        setEditRoleId(editId ?? null);
        seteditModal(!editModal);
    };

    return (
        <SidebarLayout>
            <div className="app-dashboard">
                <div>
                    {showAddRoleGroup && <AddRoleGroup toggleAddRoleGroup={toggleAddRoleGroup} />}
                    {editRoleId && <EditRoleGroup editRoleId={editRoleId} toggleEditRoleGroup={toggleEditRoleGroup} />}
                    <div className={``}>
                        <RoleBasedGuard
                            module={'Role_management'}
                            permissions={['admin', 'read', 'write']}
                            sx="h-[50vh]"
                        >
                            {' '}
                            <RoleContent
                                toggleAddRoleGroup={toggleAddRoleGroup}
                                toggleEditRoleGroup={toggleEditRoleGroup}
                                showAddRoleGroup={showAddRoleGroup}
                            />
                        </RoleBasedGuard>
                    </div>{' '}
                </div>
            </div>
        </SidebarLayout>
    );
};
export default RoleManagement;
