import { PlayIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { cn } from 'utills/tailwindUtil';

const Accordian = ({ title, children, icon, titleIconSx = '', mainSx = '', rightIcon = '' }) => {
    return (
        <div className={cn('w-full', mainSx)}>
            <details className="group w-full ">
                <summary
                    className={cn(
                        'flex justify-start items-center gap-1 text-black font-semibold cursor-pointer list-none w-full font-primary',
                        titleIconSx
                    )}
                >
                    <span className="flex gap-2 items-center">
                        {' '}
                        {rightIcon && rightIcon}
                        {title}
                    </span>
                    <span className="transition group-open:rotate-90">
                        {icon ? icon : <PlayIcon className="w-3 text-gray-400" />}
                    </span>
                </summary>
                <div className="group-open:animate-fadeIn">{children}</div>
            </details>
        </div>
    );
};

export default Accordian;
