/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { NumberInput } from 'components/atoms/inputs/NumberInput';
import { getRefPatternsThunk, saveDefaultRefpatternThunk, saveRefpatternThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';

const useDebounce = (callback, delay) => {
    const latestCallback = useCallback(callback, [callback]);

    return useCallback(
        (...args) => {
            const id = setTimeout(() => {
                latestCallback(...args);
            }, delay);

            return () => {
                clearTimeout(id);
            };
        },
        [delay, latestCallback]
    );
};

const seqOptions = [{ label: 'Display Name' }];
const seqOptionsInd = [{ label: 'Client Last Name' }];
const charKeyOpt = [
    { label: '(-) Em dash', value: '-' },
    { label: '(_) Underscore', value: '_' },
    { label: 'None', value: '' }
];

const AddClientRefNoSetting = ({ open, setOpen }) => {
    const dispatch = useDispatch();

    const [tempLName, _setTempLName] = useState('Doe');

    const [taskLoader, _setTaskLoader] = useState(false);
    const { isLoading } = useSelector((state) => state.global);

    const [startSeq, setStartSeq] = useState(null);
    const [startSeqInd, setStartSeqInd] = useState(null);
    const [alphabetStartSeq, setAlphabetStartSeq] = useState('');
    const [charKey, setCharKey] = useState('');
    const [endSequence, setEndSequence] = useState('');
    const [displayRefNo, setDisplayRefNo] = useState('');

    useEffect(() => {
        const cleanUp = handleChangeRefNo();
        return cleanUp;
    }, [startSeq, startSeqInd, alphabetStartSeq, charKey, endSequence]);

    const { hasPermissions } = useRoleGuard();

    const getRefs = () => {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            dispatch(setLoader(true));
            dispatch(getRefPatternsThunk())
                .then((response) => {
                    if (response.payload) {
                        const data = response.payload.reference;
                        setStartSeq(data?.starting_reference !== 'synkli' ? data?.starting_reference : 'Display Name');
                        setStartSeqInd(
                            data?.starting_i_reference !== 'synkli' ? data?.starting_i_reference : 'Client Last Name'
                        );
                        setAlphabetStartSeq(data?.starting_reference_count);
                        setCharKey(data?.character_key !== 'none' ? data?.character_key : '');
                        setEndSequence(data?.ending_reference);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    useEffect(() => {
        getRefs();
    }, []);

    const handleChangeRefNo = useDebounce(() => {
        if (startSeq || startSeqInd) {
            let s1 = '';
            if (startSeq) {
                s1 = 'Synkli';
            } else if (startSeqInd) {
                s1 = tempLName;
            }

            s1 = alphabetStartSeq ? s1.slice(0, alphabetStartSeq) : s1;

            setDisplayRefNo(s1 + (charKey || '') + (endSequence || ''));
        }
    }, 500);

    const setDefaultValues = () => {
        dispatch(setLoader(true));

        dispatch(saveDefaultRefpatternThunk())
            .then((response) => {
                if (response.payload) {
                    toastHandler('Successfully set default reference pattren', TOAST_TYPE_SUCCESS);
                    setOpen(false);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleCreate = () => {
        if (startSeq && startSeqInd) {
            const payload = {
                starting_reference: startSeq || '',
                starting_i_reference: startSeqInd || '',
                starting_reference_count: alphabetStartSeq || '5',
                character_key: charKey || 'none',
                ending_reference: endSequence
            };
            dispatch(setLoader(true));
            dispatch(saveRefpatternThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        toastHandler('Successfully Updated!', TOAST_TYPE_SUCCESS);
                        setOpen(false);
                    }
                })
                .catch((_error) => {
                    toastHandler('Somethng went wrong!', TOAST_TYPE_ERROR);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            toastHandler('Please Select the "Reference Starting Sequence"', TOAST_TYPE_ERROR);
        }
    };

    const handleClear = () => {
        setStartSeq(null);
        setStartSeqInd(null);
        setAlphabetStartSeq('');
        setCharKey('');
        setEndSequence('');
        setDisplayRefNo('');
    };

    return (
        <>
            {isLoading && <Loader />}
            <Slideover open={open} setOpen={setOpen} hideBackArrow>
                <RoleBasedGuard module={'Clients'} permissions={['write', 'admin']} sx="h-[50vh] !shadow-none">
                    <h3 className="flex text-3xl font-semibold leading-9 text-slate-900 break-words max-w-[80%] font-fira">
                        Clients Reference Number Settings
                    </h3>
                    <p className="flex text-lg mt-5 font-normal break-words">
                        The client reference number setting involves establishing a structured system for assigning
                        unique identification codes or numbers to individual clients within an organisation.
                    </p>{' '}
                    <div className="add-status-container1">
                        <div className="add-ann-form flex flex-col gap-5 mt-8 mb-5">
                            <div>
                                <div>
                                    <label>Experimental Details:</label>
                                </div>
                                <div className="flex items-center justify-between bg-third rounded-xl  px-4 py-3 my-2 text-gray-800">
                                    <label>
                                        Name: <b>John Doe</b>
                                    </label>
                                    <label>
                                        Business: <b>Synkli</b>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <label>Reference Starting Sequence (Company, Trust, Partnership)</label>
                                </div>

                                <SimpleSelectMenu
                                    placeholder="Select"
                                    targetProperty="label"
                                    selectedValue={startSeq}
                                    valuePropertyName="label"
                                    optionsData={seqOptions || []}
                                    sx="rounded-xl mt-2 py-3 ring-[#979797] bg-[#fff] !font-normal"
                                    showChipOnly={false}
                                    onChangeValue={(obj) => setStartSeq(obj.label)}
                                />
                            </div>
                            <div>
                                <div>
                                    <label>Reference Starting Sequence (Individual)</label>
                                </div>

                                <SimpleSelectMenu
                                    placeholder="Select"
                                    targetProperty="label"
                                    selectedValue={startSeqInd}
                                    valuePropertyName="label"
                                    optionsData={seqOptionsInd || []}
                                    sx="rounded-xl mt-2 py-3 ring-[#979797] bg-[#fff] !font-normal"
                                    showChipOnly={false}
                                    onChangeValue={(obj) => setStartSeqInd(obj.label)}
                                />
                            </div>
                            <div>
                                <div>
                                    <label>Alphabets for Starting Sequence</label>
                                </div>
                                <NumberInput
                                    type="number"
                                    onChange={(e) => setAlphabetStartSeq(e)}
                                    value={alphabetStartSeq}
                                    maxNumber={50}
                                    placeholder="Alphabets for Starting Sequence"
                                    sx="mt-2"
                                />
                            </div>

                            <div>
                                <div>
                                    <label>Character Key</label>
                                </div>

                                <SimpleSelectMenu
                                    placeholder="Select"
                                    targetProperty="label"
                                    selectedValue={charKey}
                                    valuePropertyName="value"
                                    optionsData={charKeyOpt || []}
                                    sx="rounded-xl mt-2 py-3 ring-[#979797] bg-[#fff] !font-normal"
                                    showChipOnly={false}
                                    onChangeValue={(obj) => setCharKey(obj?.value)}
                                />
                            </div>

                            <div>
                                <div>
                                    <label>Reference Ending Sequence</label>
                                </div>
                                <NumberInput
                                    type="text"
                                    onChange={(e) => setEndSequence(e)}
                                    value={endSequence}
                                    maxLength={10}
                                    placeholder="Reference Ending Sequence"
                                    sx="mt-2"
                                    acceptZero
                                />
                            </div>
                        </div>
                        {(startSeq || startSeqInd) && (
                            <div className="flex w-[70%] h-[80px] rounded-xl mx-auto items-center justify-center bg-third text-white my-10 text-2xl">
                                {displayRefNo}
                            </div>
                        )}

                        <div className="mt-10 flex justify-center gap-5 items-center bg-white py-2">
                            <button
                                className="border-button px-5 md:px-14 py-2 flex gap-2 items-center cursor-pointer  rounded-xl"
                                onClick={handleClear}
                            >
                                <span>Clear</span>
                            </button>
                            <button
                                disabled={taskLoader ? true : false}
                                type="submit"
                                className="ann-btn text-white px-5 md:px-14 py-2 flex gap-2 items-center cursor-pointer  rounded-xl"
                                onClick={handleCreate}
                            >
                                <span>Save</span>
                                {taskLoader && <SmallLoaderWhite />}
                            </button>
                        </div>
                        <div
                            className="my-5 cursor-pointer text-sm text-third underline underline-offset-4 cursor-pointer mx-auto text-center"
                            onClick={setDefaultValues}
                        >
                            Set Default Reference Pattren
                        </div>
                    </div>
                </RoleBasedGuard>
            </Slideover>
        </>
    );
};

export default AddClientRefNoSetting;
