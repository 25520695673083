/* eslint-disable react-hooks/exhaustive-deps */
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchRegisteredEmployeesForUserThunk } from 'store/settings/team/team';
import { generateId } from 'utills/uid';

export const TaskEmployees = () => {
    const dispatch = useDispatch();
    const [employees, setbusinessEmployees] = useState([]);
    const fetchEmployees = () => {
        const payload = {
            completed: 'true',
            task_stats: 'true'
        };

        dispatch(fetchRegisteredEmployeesForUserThunk(payload))
            .then((response) => {
                setbusinessEmployees(response.payload);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    useEffect(() => {
        fetchEmployees();
    }, []);

    return (
        <div className="p-3 max-h-[400px] overflow-auto">
            <div className=" ">
                {employees &&
                    employees?.map((emp, index) => (
                        <Link
                            key={generateId()}
                            className="emp-info-wrapper cursor-pointer !rounded-xl "
                            to={`/employee/tasks/${emp?.user_id}`}
                            style={{ boxShadow: '0px 6px 16px -6px #00000029' }}
                        >
                            <div
                                className={`emp-info-container flex gap-3 items-center px-3 pt-3 ${
                                    index > 0 && 'mt-3'
                                }`}
                            >
                                <div>
                                    <DefaultProfileViewer
                                        image={emp.image}
                                        width="45px"
                                        height="45px"
                                        name={emp?.first_name + ' ' + emp?.last_name}
                                    />
                                </div>
                                <div className="w-full truncate overflow-hidden">
                                    <div className="buttons-font">
                                        {' '}
                                        {emp?.first_name} {emp?.last_name}{' '}
                                    </div>
                                    <div className="buttons-font-lighter w-full truncate overflow-hidden">
                                        {emp?.email}
                                    </div>
                                </div>
                            </div>
                            <div className=" flex items-center justify-between  gap-3 mt-5 px-3">
                                <div>
                                    {' '}
                                    <div className="assigned-tasks small-text font-poppins">
                                        <div>Assigned Task </div>
                                        <div className="emp-task-count">
                                            {' '}
                                            {emp?.task_assign ? emp.task_assign : 0}{' '}
                                        </div>{' '}
                                    </div>{' '}
                                </div>
                                <div className="completed-tasks small-text font-poppins">
                                    {' '}
                                    <div>Completed Task </div>{' '}
                                    <div className="emp-task-count">
                                        {' '}
                                        {emp?.task_completed ? emp.task_completed : 0}{' '}
                                    </div>
                                </div>
                                <div className="delayed-tasks small-text font-poppins">
                                    {' '}
                                    <div> Delayed Task </div>{' '}
                                    <div className="emp-task-count">{emp?.task_overdue ? emp.task_overdue : 0} </div>
                                </div>
                            </div>
                        </Link>
                    ))}
            </div>
        </div>
    );
};
