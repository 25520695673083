import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: getJobRefPatterns, request: getJobRefPatternsThunk } = generateThunkAndSlice(
    'getJobRefPatterns',
    endpoint.getJobRefPatterns,
    methods.GET
);

const { request: saveJobRefpatternThunk } = generateThunkAndSlice(
    'saveJobRefpattern',
    endpoint.saveJobRefpattern,
    methods.POST
);

const { slice: getAllJobTypes, request: getAllJobTypesThunk } = generateThunkAndSlice(
    'getAllJobTypes',
    endpoint.getAllJobTypes,
    methods.GET
);

const { request: createJobThunk } = generateThunkAndSlice('createJob', endpoint.createJob, methods.POST);

const { slice: getAllJobs, request: getAllJobsThunk } = generateThunkAndSlice(
    'getAllJobs',
    endpoint.getAllJobs,
    methods.GET
);

const { request: createFolderThunk } = generateThunkAndSlice('createFolder', endpoint.createFolder, methods.POST);
const { request: renameFolderThunk } = generateThunkAndSlice('renameFolder', endpoint.renameFolder, methods.PUT);
const { request: uploadFilesToDocManagerThunk } = generateThunkAndSlice(
    'uploadFilesToDocManagerThunk',
    endpoint.uploadFilesToDocManager,
    methods.POST
);

const { request: JobManagerRequestDocRevokeAccessThunk } = generateThunkAndSlice(
    'JobManagerRequestDocRevokeAccess',
    endpoint.JobManagerRequestDocRevokeAccess,
    methods.DELETE
);

const { request: JobManagerDeleteItemsThunk } = generateThunkAndSlice(
    'JobManagerDeleteItems',
    endpoint.JobManagerDeleteItems,
    methods.DELETE
);

const { request: JobManagerRequestSignatureThunk } = generateThunkAndSlice(
    'JobManagerRequestSignature',
    endpoint.JobManagerRequestSignature,
    methods.POST
);

const { request: JobManagerCopyMoveThunk } = generateThunkAndSlice(
    'JobManagerCopyMove',
    endpoint.JobManagerCopyMove,
    methods.PUT
);

const { request: JobManagerMoveToClientThunk } = generateThunkAndSlice(
    'JobManagerMoveToClient',
    endpoint.JobManagerMoveToClient,
    methods.POST
);

const { request: JobManagerRequestSignatureListThunk } = generateThunkAndSlice(
    'JobManagerRequestSignatureList',
    endpoint.JobManagerRequestSignatureList,
    methods.GET
);

const { request: JobManagerCollateDocListThunk } = generateThunkAndSlice(
    'JobManagerCollateDocList',
    endpoint.JobManagerCollateDocList,
    methods.GET
);

const { slice: jobManagerDocumentDetailToSign, request: jobManagerDocumentDetailToSignThunk } = generateThunkAndSlice(
    'jobManagerDocumentDetailToSign',
    endpoint.jobManagerDocumentDetailToSign,
    methods.GET
);

const { request: JobManagerSubmitSignedDocumentThunk } = generateThunkAndSlice(
    'JobManagerSubmitSignedDocument',
    endpoint.JobManagerSubmitSignedDocument,
    methods.PUT
);

const { request: collateFilesThunk } = generateThunkAndSlice('collateFiles', endpoint.collateFiles, methods.PUT);

const { request: downloadItemsThunk } = generateThunkAndSlice('downloadItems', endpoint.downloadItems, methods.GET);

const { request: JobManagerListActivityThunk } = generateThunkAndSlice(
    'JobManagerListActivity',
    endpoint.JobManagerListActivity,
    methods.GET
);

const { request: JobManagerSendActivityMsgThunk } = generateThunkAndSlice(
    'JobManagerSendActivityMsg',
    endpoint.JobManagerSendActivityMsg,
    methods.POST
);

const { request: deleteJobsThunk } = generateThunkAndSlice('deleteJobs', endpoint.deleteJobs, methods.DELETE);
const { slice: getSingleJob, request: getSingleJobThunk } = generateThunkAndSlice(
    'getSingleJob',
    endpoint.getSingleJob,
    methods.GET
);

// GET JOB ACTIVITIES ACTIONS
const { slice: getJobActions, request: getJobActionsThunk } = generateThunkAndSlice(
    'getJobActions',
    endpoint.getJobActions,
    methods.GET
);
// MARK AS READ JOB ACTIVITIES ACTIONS
const { request: markReadJobActionsThunk } = generateThunkAndSlice(
    'markReadJobActions',
    endpoint.markReadJobActions,
    methods.PUT
);

const { request: deleteJobFileThunk } = generateThunkAndSlice('deleteJobFile', endpoint.deleteJobFile, methods.DELETE);
const { request: updateJobThunk } = generateThunkAndSlice('updateJob', endpoint.updateJob, methods.PUT);

const { request: updateJobKeyValueThunk } = generateThunkAndSlice(
    'updateJobKeyValue',
    endpoint.updateJobKeyValue,
    methods.PATCH
);

const { request: addMilestoneThunk } = generateThunkAndSlice('addMilestone', endpoint.addMilestone, methods.POST);

const { request: deleteMilestoneThunk } = generateThunkAndSlice(
    'deleteMilestone',
    endpoint.deleteMilestone,
    methods.DELETE
);

const { request: getAllMilestonesThunk } = generateThunkAndSlice(
    'getAllMilestones',
    endpoint.getAllMilestones,
    methods.GET
);

const { request: updateMilestoneThunk } = generateThunkAndSlice(
    'updateMilestone',
    endpoint.updateMilestone,
    methods.PUT
);

const { request: changeMilestoneSequenceThunk } = generateThunkAndSlice(
    'changeMilestoneSequence',
    endpoint.changeMilestoneSequence,
    methods.PATCH
);

const { request: getMilestoneTasksThunk } = generateThunkAndSlice(
    'getMilestoneTasks',
    endpoint.getMilestoneTasks,
    methods.GET
);
const { request: createTaskbyTitleThunk } = generateThunkAndSlice(
    'createTaskbyTitle',
    endpoint.createTaskbyTitle,
    methods.POST
);
const { slice: getJobAssignees, request: getJobAssigneesThunk } = generateThunkAndSlice(
    'getJobAssignees',
    endpoint.getJobAssignees,
    methods.GET
);
const { slice: getJobTasks, request: getJobTasksThunk } = generateThunkAndSlice(
    'getJobTasks',
    endpoint.getJobTasks,
    methods.GET
);
const { request: addJobFileThunk } = generateThunkAndSlice('addJobFile', endpoint.addJobFile, methods.PUT);
const { request: removeJobClientThunk } = generateThunkAndSlice(
    'removeJobClient',
    endpoint.removeJobClient,
    methods.DELETE
);
const { request: removeJobManagerThunk } = generateThunkAndSlice(
    'removeJobManager',
    endpoint.removeJobManager,
    methods.DELETE
);

const { request: addJobClientThunk } = generateThunkAndSlice('addJobClient', endpoint.addJobClient, methods.PUT);
const { request: changeMilestoneTaskSequenceThunk } = generateThunkAndSlice(
    'changeMilestoneTaskSequence',
    endpoint.changeMilestoneTaskSequence,
    methods.POST
);
const { request: createJobTemplateThunk } = generateThunkAndSlice(
    'createJobTemplate',
    endpoint.createJobTemplate,
    methods.POST
);
const { slice: getJobsTemplates, request: getJobsTemplatesThunk } = generateThunkAndSlice(
    'getJobsTemplates',
    endpoint.getJobsTemplates,
    methods.GET
);
const { request: deleteTemplatesThunk } = generateThunkAndSlice(
    'deleteTemplates',
    endpoint.deleteTemplates,
    methods.DELETE
);
const { slice: getSingleJobTemplate, request: getSingleJobTemplateThunk } = generateThunkAndSlice(
    'getSingleJobTemplate',
    endpoint.getSingleJobTemplate,
    methods.GET
);
const { request: createJobTypeThunk } = generateThunkAndSlice('createJobType', endpoint.createJobType, methods.POST);
const { slice: getClientServices, request: getClientServicesThunk } = generateThunkAndSlice(
    'getClientServices',
    endpoint.getClientServices,
    methods.GET
);
const { request: deleteJobTypesThunk } = generateThunkAndSlice(
    'deleteJobTypes',
    endpoint.deleteJobTypes,
    methods.DELETE
);
const { request: updateTypeThunk } = generateThunkAndSlice('updateType', endpoint.updateType, methods.PUT);
const { request: updateJobTemplateThunk } = generateThunkAndSlice(
    'updateJobTemplate',
    endpoint.updateJobTemplate,
    methods.PUT
);
const { request: getTemplateByIdThunk } = generateThunkAndSlice(
    'getTemplateById',
    endpoint.getTemplateById,
    methods.GET
);
const { request: addManagerToJobThunk } = generateThunkAndSlice(
    'addManagerToJob',
    endpoint.addManagerToJob,
    methods.PUT
);
const { request: createTaskUsingTemplateThunk } = generateThunkAndSlice(
    'createTaskUsingTemplate',
    endpoint.createTaskUsingTemplate,
    methods.POST
);

const { request: createRequestDocumentThunk } = generateThunkAndSlice(
    'createRequestDocument',
    endpoint.createRequestDocument,
    methods.POST
);

const { request: getRequestDocumentThunk } = generateThunkAndSlice(
    'getRequestDocument',
    endpoint.getRequestDocument,
    methods.GET
);

const { request: getRequestedDocActivityThunk } = generateThunkAndSlice(
    'getRequestedDocActivity',
    endpoint.getRequestedDocActivity,
    methods.GET
);
const { request: submitDocumentThunk } = generateThunkAndSlice('submitDocument', endpoint.submitDocument, methods.PUT);
const { request: updateRequestDocumentThunk } = generateThunkAndSlice(
    'updateRequestDocument',
    endpoint.updateRequestDocument,
    methods.PUT
);
const { request: removeRequestDocumentThunk } = generateThunkAndSlice(
    'removeRequestDocument',
    endpoint.removeRequestDocument,
    methods.DELETE
);

const { request: requstDocDeleteItemsThunk } = generateThunkAndSlice(
    'requstDocDeleteItems',
    endpoint.requstDocDeleteItems,
    methods.DELETE
);

const { request: createClearanceReqThunk } = generateThunkAndSlice(
    'createClearanceReq',
    endpoint.createClearanceReq,
    methods.POST
);

const { request: getClearanceReqListThunk } = generateThunkAndSlice(
    'getClearanceReqList',
    endpoint.getClearanceReqList,
    methods.GET
);
const { slice: getClearanceDetail, request: getClearanceDetailThunk } = generateThunkAndSlice(
    'getClearanceDetail',
    endpoint.getClearanceDetail,
    methods.GET
);
const { request: cancelClearanceRequestThunk } = generateThunkAndSlice(
    'cancelClearanceRequest',
    endpoint.cancelClearanceRequest,
    methods.DELETE
);
const { request: regenrateCleranceLinkThunk } = generateThunkAndSlice(
    'regenrateCleranceLink',
    endpoint.regenrateCleranceLink,
    methods.GET
);
const { request: confirmClearanceThunk } = generateThunkAndSlice(
    'confirmClearance',
    endpoint.confirmClearance,
    methods.GET
);
const { request: addFavoriteThunk } = generateThunkAndSlice('addFavorite', endpoint.addFavorite, methods.PUT);
const { request: updateJobGroupThunk } = generateThunkAndSlice('updateJobGroup', endpoint.updateJobGroup, methods.PUT);
const { request: closeDeleteJobThunk } = generateThunkAndSlice('closeDeleteJob', endpoint.closeDeleteJob, methods.PUT);
const { request: JobDocumentCountThunk } = generateThunkAndSlice(
    'JobDocumentCount',
    endpoint.JobDocumentCount,
    methods.GET
);

const { request: checkCollaborationEmailExistThunk } = generateThunkAndSlice(
    'checkCollaborationEmailExist',
    endpoint.checkCollaborationEmailExist,
    methods.GET
);
const { request: saveCollaborationEmailExistThunk } = generateThunkAndSlice(
    'saveCollaborationEmailExist',
    endpoint.saveCollaborationEmailExist,
    methods.POST
);

const { request: sendComposeEmailThunk } = generateThunkAndSlice(
    'sendComposeEmail',
    endpoint.sendComposeEmail,
    methods.POST
);

const { request: replyComposeEmailThunk } = generateThunkAndSlice(
    'replyComposeEmail',
    endpoint.replyComposeEmail,
    methods.POST
);

const { request: relayRoomEmailStarredThunk } = generateThunkAndSlice(
    'rrEmailStarred',
    endpoint.rrEmailStarred,
    methods.PUT
);

const { request: relayRoomEmailResponseStarredThunk } = generateThunkAndSlice(
    'relayRoomEmailResponseStarred',
    endpoint.rrEmailResponseStarred,
    methods.PUT
);
const { request: deleteThreadThunk } = generateThunkAndSlice('deleteThread', endpoint.deleteThread, methods.DELETE);
const { request: deleteThreadResponseThunk } = generateThunkAndSlice(
    'deleteThreadResponse',
    endpoint.deleteThread,
    methods.DELETE
);

const { request: setThreadReadStatusThunk } = generateThunkAndSlice(
    'threadReadStatus',
    endpoint.threadReadStatus,
    methods.PUT
);

const { request: handleCreateDraftThunk } = generateThunkAndSlice(
    'handleCreateDraft',
    endpoint.handleCreateDraft,
    methods.POST
);

const { request: handleUpdateDraftThunk } = generateThunkAndSlice(
    'handleUpdateDraft',
    endpoint.handleUpdateDraft,
    methods.PUT
);

const { request: removeDraftThunk } = generateThunkAndSlice('removeDraft', endpoint.removeDraft, methods.DELETE);
const { request: removeDraftFileThunk } = generateThunkAndSlice(
    'removeDraftFile',
    endpoint.removeDraftFile,
    methods.DELETE
);
const { request: createDeedThunk } = generateThunkAndSlice('createDeed', endpoint.createDeed, methods.POST);
const { slice: getDeeds, request: getDeedsThunk } = generateThunkAndSlice('getDeeds', endpoint.getDeeds, methods.GET);

export {
    getJobRefPatterns,
    getJobRefPatternsThunk,
    saveJobRefpatternThunk,
    createJobThunk,
    getAllJobTypes,
    getAllJobTypesThunk,
    getAllJobs,
    getAllJobsThunk,
    createFolderThunk,
    renameFolderThunk,
    uploadFilesToDocManagerThunk,
    JobManagerRequestSignatureThunk,
    JobManagerRequestSignatureListThunk,
    jobManagerDocumentDetailToSign,
    jobManagerDocumentDetailToSignThunk,
    JobManagerSubmitSignedDocumentThunk,
    JobManagerListActivityThunk,
    JobManagerSendActivityMsgThunk,
    deleteJobsThunk,
    getSingleJobThunk,
    deleteJobFileThunk,
    getSingleJob,
    getJobActions,
    getJobActionsThunk,
    markReadJobActionsThunk,
    updateJobThunk,
    updateJobKeyValueThunk,
    addMilestoneThunk,
    deleteMilestoneThunk,
    getAllMilestonesThunk,
    collateFilesThunk,
    downloadItemsThunk,
    JobManagerCollateDocListThunk,
    updateMilestoneThunk,
    changeMilestoneSequenceThunk,
    JobManagerCopyMoveThunk,
    JobManagerMoveToClientThunk,
    JobManagerDeleteItemsThunk,
    JobManagerRequestDocRevokeAccessThunk,
    getMilestoneTasksThunk,
    createTaskbyTitleThunk,
    getJobAssignees,
    getJobAssigneesThunk,
    getJobTasks,
    getJobTasksThunk,
    addJobFileThunk,
    removeJobClientThunk,
    removeJobManagerThunk,
    addJobClientThunk,
    changeMilestoneTaskSequenceThunk,
    createJobTemplateThunk,
    getJobsTemplatesThunk,
    getJobsTemplates,
    deleteTemplatesThunk,
    getSingleJobTemplateThunk,
    getSingleJobTemplate,
    createJobTypeThunk,
    getClientServices,
    getClientServicesThunk,
    deleteJobTypesThunk,
    updateTypeThunk,
    updateJobTemplateThunk,
    getTemplateByIdThunk,
    addManagerToJobThunk,
    createTaskUsingTemplateThunk,
    createRequestDocumentThunk,
    getRequestDocumentThunk,
    getRequestedDocActivityThunk,
    submitDocumentThunk,
    updateRequestDocumentThunk,
    removeRequestDocumentThunk,
    requstDocDeleteItemsThunk,
    createClearanceReqThunk,
    getClearanceReqListThunk,
    getClearanceDetail,
    getClearanceDetailThunk,
    cancelClearanceRequestThunk,
    regenrateCleranceLinkThunk,
    confirmClearanceThunk,
    addFavoriteThunk,
    updateJobGroupThunk,
    closeDeleteJobThunk,
    JobDocumentCountThunk,
    checkCollaborationEmailExistThunk,
    saveCollaborationEmailExistThunk,
    sendComposeEmailThunk,
    replyComposeEmailThunk,
    relayRoomEmailStarredThunk,
    relayRoomEmailResponseStarredThunk,
    deleteThreadThunk,
    deleteThreadResponseThunk,
    setThreadReadStatusThunk,
    handleCreateDraftThunk,
    handleUpdateDraftThunk,
    removeDraftThunk,
    removeDraftFileThunk,
    createDeedThunk,
    getDeedsThunk,
    getDeeds
};
