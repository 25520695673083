import { useCallback } from 'react';
import {
    decryptData,
    encryptData,
    exportKey,
    generateKey,
    getCookie,
    importKey,
    removeCookie,
    setCookie
} from 'utills/encryption/cryptoUtils';

export const useEncryptedStorage = () => {
    const encryptAndSave = useCallback(async (name, data, storeName = 'cookies', days = 30) => {
        const jsonData = JSON.stringify(data);
        const key = await generateKey();
        const { iv, encrypted } = await encryptData(key, jsonData);

        const exportedKey = await exportKey(key);
        if (storeName === 'cookies') {
            setCookie(`${name}_iv`, JSON.stringify(Array.from(iv)), days);
            setCookie(`${name}_encrypted`, JSON.stringify(Array.from(new Uint8Array(encrypted))), days);
            setCookie(`${name}_key`, JSON.stringify(exportedKey), days);
        } else if (storeName === 'session') {
            sessionStorage.setItem(`${name}_iv`, JSON.stringify(Array.from(iv)));
            sessionStorage.setItem(`${name}_encrypted`, JSON.stringify(Array.from(new Uint8Array(encrypted))));
            sessionStorage.setItem(`${name}_key`, JSON.stringify(exportedKey));
        }
    }, []);

    const decryptAndRetrieve = useCallback(async (name, storeName = 'cookies') => {
        let iv = '';
        let encrypted = '';
        let keyJson = '';
        if (storeName === 'cookies') {
            iv = getCookie(`${name}_iv`);
            encrypted = getCookie(`${name}_encrypted`);
            keyJson = getCookie(`${name}_key`);
        } else if (storeName === 'session') {
            iv = sessionStorage.getItem(`${name}_iv`);
            encrypted = sessionStorage.getItem(`${name}_encrypted`);
            keyJson = sessionStorage.getItem(`${name}_key`);
        }
        if (iv && encrypted && keyJson) {
            const ivArray = Uint8Array.from(JSON.parse(iv));
            const encryptedArray = Uint8Array.from(JSON.parse(encrypted));
            const key = await importKey(JSON.parse(keyJson));
            const decrypted = await decryptData(key, encryptedArray, ivArray);

            return JSON.parse(decrypted);
        }

        return null;
    }, []);

    const clearEncryptedValues = useCallback(async (name, storeName = 'cookies') => {
        if (storeName === 'cookies') {
            removeCookie(`${name}_iv`);
            removeCookie(`${name}_encrypted`);
            removeCookie(`${name}_key`);
        } else if (storeName === 'session') {
            sessionStorage.removeItem(`${name}_iv`);
            sessionStorage.removeItem(`${name}_encrypted`);
            sessionStorage.removeItem(`${name}_key`);
        }

        return null;
    }, []);

    return { encryptAndSave, decryptAndRetrieve, clearEncryptedValues };
};
