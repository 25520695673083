/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ArrowPointDown } from 'utills/svgs/ArrowPointDown';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { useDispatch, useSelector } from 'react-redux';
import { handleExpenseTypeId, setLoader } from 'store/global/globalReducer';
import { getRentalScheduleThunk } from 'store/deductions';
import { Pdf } from 'utills/svgs/Pdf';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { DropDownMenu } from 'components/molecules/DropDownMenu';
import { BASE_URL } from 'utills/globalVars';
import { toastHandler } from 'responseHanlder';
import Slideover from 'components/atoms/SlideOver';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { customDateFormat } from 'utills/moment';
import { ExpenseTypeDetail } from './ExpenseTypeDetail';
import { useParams } from 'react-router-dom';

export const RentalSchdule = ({ toggleRentalSchdule, open, setOpen }) => {
    const { summary_id } = useSelector((state) => state.global);
    const { sideLoader } = useSelector((state) => state.global);

    const dispatch = useDispatch();
    const { id } = useParams();

    const [rentalSchdule, setRentalSchdule] = useState(null);
    const [showExpenseTypeDetail, setshowExpenseTypeDetail] = useState(false);

    const buttonProps = {
        rightIcon: ArrowPointDown
    };
    const datePickerProps = {
        right: CalendarSmallIco
    };

    const toggleExpenseTypeDetail = (id) => {
        if (id) {
            dispatch(handleExpenseTypeId(id));
        }
        setshowExpenseTypeDetail(!showExpenseTypeDetail);
    };
    const getRentalSchedule = () => {
        const payload = {
            summary_id: summary_id,
            business_client_id: id
        };
        dispatch(setLoader(true));
        dispatch(getRentalScheduleThunk(payload))
            .then((response) => {
                setRentalSchdule(response.payload);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleDownload = (type) => {
        if (type === 'CSV File') {
            handleDownloadCP({ export_type: 'csv', summary_id, business_client_id: id });
        } else if (type === 'PDF File') {
            handleDownloadCP({ export_type: 'pdf', summary_id, business_client_id: id });
        }
    };
    const handleDownloadCP = async (payload) => {
        dispatch(setLoader(true));
        const token = localStorage.getItem('access_token');
        await fetch(`${BASE_URL}/exp-inc/rp-schedule`, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',

            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = rentalSchdule?.file_name ? rentalSchdule?.file_name : 'profit_and_loss';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    toastHandler('File Has been downloaded!', 'success');
                } else {
                    toastHandler(`No ${payload.export_type.toUpperCase()} to download!`, 'error');
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        getRentalSchedule();
    }, [summary_id]);

    const exportDataOptions = [
        {
            title: 'CSV File',
            icon: CsvIcon
        },
        {
            title: 'PDF File',
            icon: Pdf
        }
    ];

    const calculateTotal = (list) => {
        const total = list.reduce((accumulator, item) => accumulator + item.amount, 0);
        return formatCurrency(total);
    };

    const formatCurrency = (value) => {
        if (value % 1 === 0) {
            return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return '$' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                size={'max-w-5xl'}
                showLoader
                hideBackArrow
                title="Rental Property Schedule"
                titlesx={'title1 xl-title !font-semibold mt-0 py-2 flex gap-2 items-center'}
                subTitle={rentalSchdule ? rentalSchdule?.duration_str : ''}
            >
                {showExpenseTypeDetail && (
                    <ExpenseTypeDetail
                        open={showExpenseTypeDetail}
                        setOpen={setshowExpenseTypeDetail}
                        toggleExpenseTypeDetail={toggleExpenseTypeDetail}
                    />
                )}
                <div>
                    {rentalSchdule ? (
                        <>
                            <div className="flex justify-between items-center gap-3 mt-5">
                                <div className="flex items-center gap-3">
                                    <DatePick
                                        iconSet={datePickerProps}
                                        placeholder={rentalSchdule?.from}
                                        isDisabled={true}
                                    />
                                    <DatePick
                                        iconSet={datePickerProps}
                                        placeholder={rentalSchdule?.to}
                                        isDisabled={true}
                                    />
                                </div>
                                <DropDownMenu
                                    isLoading={sideLoader ? true : false}
                                    isButton={true}
                                    options={exportDataOptions}
                                    atClick={(type) => handleDownload(type)}
                                />
                            </div>

                            <div className="my-10">
                                <div className="px-5 py-2 text-lg primary-fonts text-white bg-third">
                                    Rental Property Details
                                </div>
                                <div className="divide-y divide-gray-300 border-b border-solid border-b-gray-300">
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">Property Name </div>
                                        <span className={'text-gray-900'}>
                                            {rentalSchdule?.rentalproperty?.name || ''}
                                        </span>
                                    </div>
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">Rental Property Address </div>
                                        <span className={'text-gray-900'}>
                                            {rentalSchdule?.rentalproperty?.address || ''}
                                        </span>
                                    </div>
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">
                                            First Earned Rental Income{' '}
                                        </div>
                                        <span className={'text-gray-900'}>
                                            {customDateFormat(
                                                rentalSchdule?.rentalproperty?.rental_first_income_date,
                                                'DD-MM-YYYY',
                                                'DD/MM/YYYY'
                                            )}
                                        </span>
                                    </div>
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">
                                            No. of weeks property was rented this FY{' '}
                                        </div>
                                        <span className={'text-gray-900'}>
                                            {rentalSchdule?.rentalproperty?.rented_duration_in_weeks || ''}
                                        </span>
                                    </div>
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">Private Use % </div>
                                        <span className={'text-gray-900'}>
                                            {rentalSchdule?.rentalproperty?.private_use_percentage || '0'}%
                                        </span>
                                    </div>
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">
                                            Date property was purchased{' '}
                                        </div>
                                        <span className={'text-gray-900'}>
                                            {customDateFormat(
                                                rentalSchdule?.rentalproperty?.purchase_date,
                                                'DD-MM-YYYY',
                                                'DD/MM/YYYY'
                                            )}
                                        </span>
                                    </div>
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">
                                            Purchase price of property{' '}
                                        </div>
                                        <span className={'text-gray-900'}>
                                            {rentalSchdule?.rentalproperty?.purchase_price !== ''
                                                ? '$' + rentalSchdule?.rentalproperty?.purchase_price
                                                : ''}
                                        </span>
                                    </div>
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">Loan amount </div>
                                        <span className={'text-gray-900'}>
                                            {rentalSchdule?.rentalproperty?.loan_amount !== ''
                                                ? '$' + rentalSchdule?.rentalproperty?.loan_amount
                                                : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="my-10">
                                <div className="px-5 py-2 text-lg primary-fonts text-white bg-third">Ownership</div>
                                <div className="divide-y divide-gray-300 border-b border-solid border-b-gray-300">
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-lg font-semibold text-black">Name </div>
                                        <span className="text-lg font-semibold text-black">Percentage Owned %</span>
                                    </div>
                                    {rentalSchdule?.rentalproperty?.owners &&
                                        rentalSchdule?.rentalproperty?.owners?.map((item, index) => (
                                            <div
                                                className={`flex justify-between items-center px-5 py-2.5`}
                                                key={'rentalOwner_' + index}
                                            >
                                                <div className="text-md font-medium text-black">
                                                    {item?.first_name} {item?.last_name}{' '}
                                                </div>
                                                <span className={'text-gray-900'}>
                                                    {item?.percentage_owned || '0'}%
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="my-10">
                                <div className="px-5 py-2 text-lg primary-fonts text-white bg-third">Income</div>
                                <div className="divide-y divide-gray-300 border-b border-solid border-b-gray-300">
                                    <div className={`flex justify-between items-center px-5 py-2.5`}>
                                        <div className="text-md font-medium text-black">Gross rental income </div>
                                        <span className={'text-gray-900'}>${rentalSchdule?.income || '0'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10">
                                <div className="px-5 py-2 text-lg primary-fonts text-white bg-third">Expenses</div>
                                <div className="divide-y divide-gray-300 border-b border-solid border-b-gray-300">
                                    {rentalSchdule?.list &&
                                        rentalSchdule?.list?.map((expence, index) => (
                                            <div
                                                className={`flex justify-between items-center px-5 py-2.5`}
                                                key={'list_Expense' + index}
                                            >
                                                <div className="text-md font-medium text-black">
                                                    {expence?.label || ''}{' '}
                                                </div>
                                                <span
                                                    className={
                                                        'cursor-pointer underline underline-offset-4 text-gray-900'
                                                    }
                                                    onClick={() => {
                                                        toggleExpenseTypeDetail(expence?.expense_type_id);
                                                    }}
                                                >
                                                    {expence?.value}
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="mb-5">
                                <div className={`flex justify-between items-center px-5 py-2.5 bg-[#0A1E46]`}>
                                    <div className="text-md font-medium text-white">Total Expenses </div>
                                    <span className={'text-md font-medium text-white'}>
                                        ${rentalSchdule?.total_expense || '0'}
                                    </span>
                                </div>
                                <div className={`flex justify-between items-center px-5 py-2.5`}>
                                    <div className="text-md font-medium text-black">Net Rent </div>
                                    <span className={'text-md font-medium text-black'}>
                                        {rentalSchdule?.net_rent > 0
                                            ? '$' + rentalSchdule?.net_rent
                                            : '-$' + rentalSchdule?.net_rent * -1 || '$0'}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <SkeletonTable columns={2} />
                    )}
                </div>
            </Slideover>
        </>
    );
};
