import { Button } from 'components/atoms/buttons/Button';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { AddJobType } from './AddJobType';
import { deleteJobTypesThunk, getAllJobTypesThunk } from 'store/JobManager';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import FormateDateTime from 'components/atoms/FormateDateTime';
import ConfirmationModal from 'utills/confirmationModal';
import { PlusVector } from 'utills/svgs/PlusVector';
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { NoDataFound } from 'components/common/NoDataFound';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const JobTypeSettings = () => {
    const { hasPermissions } = useRoleGuard();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [add, setAdd] = useState(false);
    const [filters, setFilters] = useState({
        type: 'none',
        template: 'none',
        service: 'none',
        created_at: 'none',
        updated_at: 'none'
    });

    const checkbox = useRef();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState([]);
    const { data } = useSelector((state) => state.getAllJobTypes);
    const [jobs, setJobs] = useState([]);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const [selectedType, setSelectedType] = useState(null);

    useLayoutEffect(() => {
        const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < jobs?.length;
        setChecked(jobs?.length > 0 && selectedPeople.length === jobs?.length);
        setIndeterminate(isIndeterminate);
        if (checkbox?.current) {
            checkbox.current.indeterminate = isIndeterminate;
        }
    }, [selectedPeople]);

    function toggleAll() {
        setSelectedPeople(checked || indeterminate ? [] : jobs);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const getJobTypes = () => {
        dispatch(setLoader(true));
        dispatch(getAllJobTypesThunk({ business_id }))
            .then((response) => {
                if (response.payload) {
                    setJobs(response.payload);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        if (hasPermissions('Job', ['read', 'write', 'admin'])) {
            getJobTypes();
        }
    }, []);

    useEffect(() => {
        if (searchTerm.trim() === '') {
            setJobs(data);
        } else if (!isLoading) {
            const filteredJobs = jobs.filter((job) =>
                [
                    job.label.toLowerCase(),
                    job?.template?.name?.toLowerCase(),
                    job?.service?.title?.toLowerCase()
                ].includes(searchTerm.toLowerCase())
            );
            setJobs(filteredJobs);
        }
    }, [searchTerm]);

    const handleOpenConfirmation = (ids) => {
        setSelectedIds(ids);
        setIsConfirmationOpen(true);
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        dispatch(setLoader(false));

        dispatch(deleteJobTypesThunk({ job_type_ids: selectedIds.join(','), business_id }))
            .then((response) => {
                if (response.payload) {
                    setSelectedIds([]);
                    setSelectedPeople([]);
                    getJobTypes();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (jobs && Object.values(filters).some((value) => value !== 'none')) {
            let NewjobsArray = [...jobs]?.sort((a, b) => {
                const { type, template, service, created_at, updated_at } = filters;

                if (type !== 'none') {
                    return type === 'ASC' ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label);
                }

                if (template !== 'none') {
                    return template === 'ASC'
                        ? a.template.name.localeCompare(b.template.name)
                        : b.template.name.localeCompare(a.template.name);
                }
                if (service !== 'none') {
                    return service === 'ASC'
                        ? a.service.title.localeCompare(b.service.title)
                        : b.service.title.localeCompare(a.service.title);
                }
                if (created_at !== 'none') {
                    return created_at === 'ASC'
                        ? moment(a.created_at).diff(moment(b.created_at))
                        : moment(b.created_at).diff(moment(a.created_at));
                }
                if (updated_at !== 'none') {
                    return updated_at === 'ASC'
                        ? moment(a.updated_at).diff(moment(b.updated_at))
                        : moment(b.updated_at).diff(moment(a.updated_at));
                }

                return 0;
            });

            setJobs(NewjobsArray);
        }
    }, [filters]);

    const updateFiltersObject = (id, value) => {
        setFilters({
            type: 'none',
            template: 'none',
            service: 'none',
            created_at: 'none',
            updated_at: 'none',
            [id]: value
        });
    };
    const RenderSortByArrows = (name, value = '', id) => {
        return (
            <div className="flex items-center justify-start gap-1">
                <label
                    htmlFor={id + '_sort'}
                    onClick={() => {
                        updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                    }}
                    className="cursor-pointer"
                >
                    {name}
                </label>
                {value === 'ASC' ? (
                    <ArrowDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'DESC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : value === 'DESC' ? (
                    <ArrowUpIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'ASC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : (
                    <ArrowsUpDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                        }}
                        className="w-4 h-4 text-[#979797] cursor-pointer"
                    />
                )}
            </div>
        );
    };
    return (
        <SidebarLayout>
            <RoleBasedGuard module="Job" permissions={['write', 'read', 'admin']} sx="p-5">
                <Jumbotron
                    title="Job Type"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },
                        {
                            link: 'settings/job-manager',
                            title: 'Job Manager'
                        },
                        {
                            link: 'job-manager/settings/job-type',
                            title: 'Job Type'
                        }
                    ]}
                />

                {add && (
                    <AddJobType
                        open={add}
                        setOpen={() => {
                            setAdd(!add);
                            setSelectedType(null);
                        }}
                        onComplete={() => getJobTypes()}
                        type={selectedType}
                    />
                )}
                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={handleDelete}
                />

                <div className="mx-5 md:mx-10">
                    <div className="flex justify-between items-center gap-5 flex-wrap">
                        <SearchInput
                            width="320px"
                            defaultValue={searchTerm || ''}
                            atChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            placeholder="Search job type"
                        />
                        <div className="flex gap-2 items-center">
                            {selectedPeople?.length > 0 && (
                                <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                        if (!hasPermissions('Job', ['admin'])) {
                                            return toastHandler(
                                                "You don't have permission to perform this action",
                                                TOAST_TYPE_ERROR
                                            );
                                        }
                                        handleOpenConfirmation(selectedPeople?.map((job) => job?.job_type_id));
                                    }}
                                >
                                    <BgDeleteIcon />
                                </span>
                            )}
                            <Button
                                title="Add Job Type"
                                iconSet={{ leftIcon: PlusVector }}
                                iconInvert
                                atClick={() => {
                                    setAdd(!add);
                                    setSelectedPeople([]);
                                }}
                                classes="ann-btn px-8 rounded-xl flex gap-2 items-center"
                            />
                        </div>
                    </div>

                    {!isLoading && jobs?.length < 1 ? (
                        <div className="w-full ">
                            {' '}
                            <NoDataFound
                                message="No Job Type Found!.You can click on 'Add Job Type' button to add one    "
                                height="400px"
                            />
                        </div>
                    ) : (
                        <div className=" pb-5 rounded-xl overflow-x-auto">
                            <div className="mt-8 flow-root">
                                <div className="inline-block min-w-full py-4 align-middle">
                                    <div className="relative">
                                        <table className="min-w-full table-fixed divide-y  divide-gray-300 bg-white">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                        <input
                                                            type="checkbox"
                                                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-secondary  text-indigo-600 focus:ring-indigo-600"
                                                            ref={checkbox}
                                                            checked={checked}
                                                            onChange={toggleAll}
                                                        />
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="min-w-[12rem] py-3.5 pr-3 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        {RenderSortByArrows('Job Type', filters?.type, 'type')}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        {RenderSortByArrows(
                                                            'Job Template',
                                                            filters?.template,
                                                            'template'
                                                        )}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        {RenderSortByArrows(
                                                            'On Boarding Service',
                                                            filters?.service,
                                                            'service'
                                                        )}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        {RenderSortByArrows(
                                                            'Created Date',
                                                            filters?.created_at,
                                                            'created_at'
                                                        )}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left flex gap-2 items-center text-md font-semibold text-gray-900"
                                                    >
                                                        {RenderSortByArrows(
                                                            'Last Updated',
                                                            filters?.updated_at,
                                                            'updated_at'
                                                        )}
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5  text-left text-md font-semibold text-gray-900"
                                                    >
                                                        <div className="flex gap-2 items-center ">Created By</div>
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {jobs?.map((job, _index) => (
                                                    <tr
                                                        key={job?.job_type_id}
                                                        className={` ${
                                                            selectedPeople?.includes(job) ? 'bg-gray-50' : undefined
                                                        } hover:bg-[#F8F4FE] transition-colors duration-200 cursor-pointer`}

                                                        //
                                                    >
                                                        <td className="relative py-3.5 px-7 sm:w-12 sm:px-6">
                                                            {selectedPeople?.includes(job) && (
                                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-thirdbg" />
                                                            )}
                                                            <input
                                                                type="checkbox"
                                                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-secondary  text-indigo-600 focus:ring-indigo-600"
                                                                value={job.job_type_id}
                                                                checked={selectedPeople?.includes(job)}
                                                                onChange={(e) =>
                                                                    setSelectedPeople(
                                                                        e.target.checked
                                                                            ? [...selectedPeople, job]
                                                                            : selectedPeople.filter((p) => p !== job)
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                'whitespace-nowrap   pr-3 text-md py-3.5 font-poppins',
                                                                selectedPeople.includes(job)
                                                                    ? 'text-thirdbg'
                                                                    : 'text-black'
                                                            )}
                                                        >
                                                            {job?.label}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                                            {job?.template?.name || '--'}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3  py-3.5 text-md ">
                                                            {job?.service?.title || '--'}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-3.5  text-md">
                                                            <FormateDateTime
                                                                dateTime={job.created_at}
                                                                insertedFormat="YYYY-MM-DDTHH:mm:ss.SSSS"
                                                            />
                                                        </td>

                                                        <td className="whitespace-nowrap px-3 py-3.5  text-md ">
                                                            <FormateDateTime
                                                                dateTime={job?.updated_at}
                                                                insertedFormat="YYYY-MM-DDTHH:mm:ss.SSSS"
                                                            />
                                                        </td>

                                                        <td className="whitespace-nowrap px-3 py-3.5 text-md">
                                                            <DefaultProfileViewer
                                                                height={'45px'}
                                                                width={'45px'}
                                                                image={job?.createdBy?.image}
                                                                name={
                                                                    job?.createdBy?.first_name +
                                                                    ' ' +
                                                                    job?.createdBy?.last_name
                                                                }
                                                                tooltip
                                                            />
                                                        </td>

                                                        <td className="whitespace-nowrap px-3 py-3.5 ">
                                                            <div className="flex gap-2 items-center">
                                                                {' '}
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        if (!hasPermissions('Job', ['admin'])) {
                                                                            return toastHandler(
                                                                                "You don't have permission to perform this action",
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        setSelectedType(job);
                                                                        setAdd(!add);
                                                                    }}
                                                                >
                                                                    <BgEditIcon />
                                                                </div>
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        if (!hasPermissions('Job', ['admin'])) {
                                                                            return toastHandler(
                                                                                "You don't have permission to perform this action",
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        handleOpenConfirmation([job?.job_type_id]);
                                                                    }}
                                                                >
                                                                    <BgDeleteIcon />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
