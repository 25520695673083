import { DOB } from './../../globalVars';
import { SALARY_WAGES_OCCUPATION } from './../../globalVars';
import { BUSINESS_INDUSTRY_OCCUPATION } from 'utills/globalVars';

export const LIST = 'list';
export const ABN = 'abn';
export const ADDRESS = 'address';
export const BSB = 'bsb';
export const BUSINESS_EMAIL = 'business-email';

export const CHECKBOX_FIELD = 'checkbox';
export const CONDITIONAL = 'conditional';
export const DATE = 'date';

export const TEL = 'tel';
export const PHONE_NUMBER_FIELD = 'phone-number';
export const DOCUMENT = 'document';
export const DROPDOWN = 'dropdown';
export const EMAIL = 'email';
export const FIRST_NAME = 'first-name';
export const IMAGE_FIELD = 'image';
export const LAST_NAME = 'last-name';
export const MIDDLE_NAME = 'middle-name';
export const NUMBER_FIELD = 'number';
export const PHONE_NUMBER_FILED = 'phone-number';
export const PRIVACY_POLICY = 'privacy-policy';
export const RADIO_FIELD = 'radio';
export const RECURRING = 'recurring';

export const SIGNATURE = 'signature';
export const SWITCH = 'switch';
export const TERMS_AND_CONDITIONS = 'terms-and-conditions';
export const TEXT = 'text';
export const TFN = 'tfn';

export const LABEL_FIELDS = [
    TEXT,
    NUMBER_FIELD,
    DATE,
    DOB,
    EMAIL,
    BUSINESS_EMAIL,
    TEL,
    FIRST_NAME,
    LAST_NAME,
    MIDDLE_NAME,
    PHONE_NUMBER_FILED,
    ABN,
    TFN,
    ADDRESS,
    SALARY_WAGES_OCCUPATION,
    BUSINESS_INDUSTRY_OCCUPATION,
    BSB,
    LIST,
    IMAGE_FIELD,
    SIGNATURE,
    DOCUMENT,
    CHECKBOX_FIELD,
    RADIO_FIELD,
    DROPDOWN,
    RECURRING,
    SWITCH
];

export const PLACESHOLDER_FIELDS = [
    TEXT,
    NUMBER_FIELD,
    DATE,
    DOB,
    EMAIL,
    BUSINESS_EMAIL,
    TEL,
    FIRST_NAME,
    LAST_NAME,
    MIDDLE_NAME,
    PHONE_NUMBER_FIELD,
    ABN,
    TFN,
    ADDRESS,
    BSB,
    LIST
];

export const OPTION_FIELDS = [CHECKBOX_FIELD, RADIO_FIELD, DROPDOWN];
export const EDITOR_FIELDS = [TERMS_AND_CONDITIONS, PRIVACY_POLICY];
