import React from 'react';
import { useState } from 'react';
import './css/backupAlternate.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { verifyBackupCodeThunk } from 'store/auth/slices';
import { Loader } from 'components/common/Loader';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
const BackupAlternatePage = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);
    const { data } = useSelector((state) => state.login);

    const [code, setCode] = useState('');

    const verifyBackupData = {
        user_id: data?.user_id,
        code: code,
        user_type: 'employee'
    };
    const handleBackupCode = (e) => {
        e.preventDefault();
        if (!code) {
            toastHandler('Please enter backup code', TOAST_TYPE_ERROR);
            return;
        }
        if (code?.length < 6) {
            toastHandler('Code must be atleat 6 characters long', TOAST_TYPE_ERROR);
            return;
        }
        dispatch(setLoader(true));
        dispatch(verifyBackupCodeThunk(verifyBackupData))
            .then((response) => {
                if (response.payload) {
                    sessionStorage.setItem('lostStepper', 1);
                    sessionStorage.setItem('twoFaStepper', 1);
                    sessionStorage.setItem('resetStepper', 1);

                    sessionStorage.setItem('isLost', true);

                    navigate('/reset-auth', {
                        state: {
                            isNavigating: true
                        }
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <div>
            {isLoading && <Loader />}

            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    {' '}
                    <div>
                        <form onSubmit={handleBackupCode}>
                            <div>
                                <div className="text-center   card-layout-title-text mt-7 font-fira">
                                    <h1>Enter Backup Code</h1>
                                </div>

                                <div className="px-20 text-sixth font-poppins small-text  mt-10 ">
                                    Use the backup code provided to get access to your account
                                </div>

                                <div className=" mx-20 font-poppins buttons-font  mt-16 pr-16">
                                    Enter one of your 6-digit backup codes
                                </div>

                                <div className="default-forms mx-20  mt-2">
                                    <input
                                        onChange={(e) => setCode(e.target.value)}
                                        type="text"
                                        maxLength="6"
                                        className="rounded-xl px-3"
                                        pattern="\d{8,}"
                                        placeholder="Enter backup code"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-between items-center mt-60 mb-10 mx-20">
                                <button
                                    onClick={() => props.handleCondition(1)}
                                    className="px-12 border-button buttons-font text-secondarybg font-fira py-2 rounded-lg"
                                >
                                    Back
                                </button>
                                <button type="submit" className="px-10 ann-btn  py-2 rounded-lg buttons-font font-fira">
                                    Continue
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BackupAlternatePage;
