import React from 'react';

export const BlackLeftIcon = ({ className }) => {
    return (
        <svg
            width="25"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M14.3727 19.2659C14.566 19.1428 22.7106 11.3967 22.9039 11.1448C23.3492 10.5823 23.3668 9.55104 22.9449 8.92408C22.7809 8.68385 14.6305 0.902598 14.3727 0.738536C14.1324 0.586192 13.6168 0.586192 13.359 0.744395C13.0836 0.914317 12.9371 1.18385 12.9371 1.54713C12.9371 1.71705 12.9664 1.92213 13.0074 2.00416C13.0426 2.08619 14.7067 3.69752 16.7047 5.59596L20.3317 9.03541L10.816 9.06471C1.76916 9.094 1.29455 9.09986 1.14221 9.19947C0.609005 9.54518 0.609005 10.4592 1.14221 10.8049C1.29455 10.9046 1.76916 10.9104 10.8043 10.9397L20.3082 10.969L16.734 14.3674C14.7653 16.2366 13.1071 17.8479 13.0485 17.9417C12.8961 18.1819 12.902 18.6975 13.0543 18.9553C13.1188 19.0608 13.2535 19.1956 13.359 19.26C13.6168 19.4182 14.1324 19.4182 14.3727 19.2659Z"
                fill="url(#paint0_linear_1065_2580)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1065_2580"
                    x1="10.8918"
                    y1="0.857911"
                    x2="10.9035"
                    y2="19.3795"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
