import React from 'react';
import { useState } from 'react';
import '../css/atoms.css';
import { cn } from 'pages/documents/hooks/helper';
export const NumberInput = ({
    value,
    type,
    onChange,
    maxLength,
    maxNumber,
    placeholder,
    width,
    required,
    sx,
    acceptZero,
    disabled,
    label,
    labelSx
}) => {
    const [inValidInput, setInvalidInput] = useState('');

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        if (inputValue === '') {
            onChange(inputValue);
            setInvalidInput('');
            return;
        }

        const number = inputValue.replace(/[^0-9]/g, '');

        if (number === '0' && !acceptZero) {
            setInvalidInput('Accept greater than 0 only');
            return;
        }

        if (!/^\d+$/.test(number)) {
            setInvalidInput('Accept numbers only');
            return;
        }

        if (maxNumber && Number(inputValue) > maxNumber) {
            setInvalidInput(`Max allowed value is ${maxNumber}`);
            return;
        }

        if (maxLength && inputValue.length > maxLength) {
            setInvalidInput(`Max allowed lenght is ${maxLength}`);
            return;
        }

        onChange(number);

        setInvalidInput('');
    };

    return (
        <div>
            <div>
                <label className={labelSx ?? ''}>{label}</label>{' '}
                <input
                    type={type || 'text'}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleInputChange}
                    className={cn(
                        ' px-2 py-1 h-size8 border border-border1 rounded-lg focus:outline-none transition-all duration-300 atom-input-field placeholder-[#979797] placeholder:text-[0.9rem] placeholder:font-[400] !font-[400] !text-[0.9rem] ',
                        sx || '',
                        inValidInput !== '' ? '!border-1 !border-red-500' : '',
                        disabled && '!bg-gray-200 !border-none !cursor-no-drop'
                    )}
                    style={{
                        width: width ?? '100%'
                    }}
                    maxLength={maxLength}
                    required={required}
                    disabled={disabled}
                />
            </div>
            {inValidInput !== '' && <span className="text-sm text-red-500"> {inValidInput} </span>}
        </div>
    );
};
