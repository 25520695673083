/* eslint-disable no-prototype-builtins */
import TextEditor from 'components/molecules/TextEditor';
import React, { useState } from 'react';
import { BSB, DOB } from 'utills/globalVars';
import { convertSpacesToUnderscores, convertUnderscoresToSpaces } from 'utills/handleText';
import { BgAddIcon } from 'utills/svgs/BgAddIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { HintIcon } from 'utills/svgs/HintIcon';
import { InfoIcon } from 'utills/svgs/InfoIcon';
import { SideTimes } from 'utills/svgs/SideTimes';
import { Hint } from './Hint';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { LIST } from 'utills/constants/forms/forms.constants';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';

export const RenderConditionalInputs = ({
    field,
    stepIndex,
    handleConditionalOption,
    handleConditionalStep,
    handleDisplayInStepsConditional,
    handleSkipStepDisplay,
    handleAddFieldInConditional,
    handleFieldTypeChangeCondtional,
    handleLabelConditional,
    addOptionConditional,
    handleOptionConditional,
    deleteOptionConditional,
    handleDeleteFieldConditional,
    handleSwitchOptionOneConditional,
    handleSwitchOptionTwoConditional,
    hanleRequiredConditional,
    addConditionalOption,
    addRemoveSkipStep,
    handleSubStepHeadingChange,
    isRecurring,
    handleAddHintSubField,
    steps,
    handleCalculateStepIndex
}) => {
    const [showHint, setShowHint] = useState(false);
    const [fieldRowId, setFieldRowId] = useState('');

    const toggleHint = (field_row_id) => {
        if (field_row_id) {
            setFieldRowId(field_row_id);
        }
        setShowHint(!showHint);
    };

    return (
        <div className={`${isRecurring && 'ml-[35px]'}`}>
            <label className="mt-3">Field Label</label>

            <div className="flex gap-3  mt-3items-center flex-wrap mt-3 ">
                <div>
                    <div className="w-[300px] ">
                        {' '}
                        <input
                            type="text"
                            placeholder="Enter field label"
                            value={field?.label}
                            className=""
                            onChange={(e) => handleLabelConditional(e.target.value, field.field_row_id)}
                            required
                        />
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <span onClick={() => addConditionalOption(field.field_row_id)} className="cursor-pointer">
                        {' '}
                        <BgAddIcon />
                    </span>
                </div>
            </div>

            <div className="flex gap-3 items-center small-text p-2 px-3 rounded-xl text-blueish w-[60%] break-words mt-5 bg-[#f4f2f2]">
                <div>
                    <HintIcon />
                </div>
                <div>
                    You can select additional conditions from dropdown menu and add step within step or in new step. You
                    can also select no additional conditions and you can skip step
                </div>
            </div>

            <div className="mt-5">
                {field?.options?.map((option, optionIndex) => (
                    <div key={'optionIndex' + optionIndex}>
                        {' '}
                        <div className="flex gap-3 items-ceter flex-wrap mt-2">
                            <div>
                                <input
                                    type="text"
                                    value={convertUnderscoresToSpaces(option)}
                                    placeholder="Option"
                                    onChange={(e) =>
                                        handleConditionalOption(
                                            e.target.value,
                                            option,
                                            optionIndex,
                                            stepIndex,
                                            field?.field_row_id
                                        )
                                    }
                                    required
                                />
                            </div>
                            <div>
                                <select
                                    className="h-[3rem] small-text font-poppins px-3 pr-20"
                                    onChange={(e) => {
                                        handleConditionalStep(
                                            e.target.value,
                                            optionIndex,
                                            field?.field_row_id,
                                            stepIndex
                                        );
                                    }}
                                    value={
                                        field?.conditional_steps?.hasOwnProperty(convertSpacesToUnderscores(option))
                                            ? 'additional-conditon'
                                            : 'no-additional-conditon'
                                    }
                                >
                                    <option value="no-additional-conditon">No Additional Condition</option>

                                    <option value="additional-conditon">Additional Condition</option>
                                </select>
                            </div>

                            {field?.conditional_steps?.hasOwnProperty(convertSpacesToUnderscores(option)) && (
                                <div>
                                    <select
                                        className="h-[3rem] small-text font-poppins px-3 pr-20"
                                        value={
                                            field?.conditional_steps[convertSpacesToUnderscores(option)]
                                                ?.display_on_new_page
                                                ? 'yes'
                                                : 'no'
                                        }
                                        onChange={(e) =>
                                            handleDisplayInStepsConditional(
                                                e.target.value,
                                                optionIndex,
                                                stepIndex,
                                                field.field_row_id
                                            )
                                        }
                                    >
                                        <option value="no">Add in same step</option>
                                        <option value="yes">Add in next step</option>
                                    </select>
                                </div>
                            )}
                            {field?.conditional_steps?.hasOwnProperty(convertSpacesToUnderscores(option)) && (
                                <div>
                                    <select
                                        className="h-[3rem] small-text font-poppins px-3 pr-20"
                                        onChange={(e) =>
                                            handleSkipStepDisplay(
                                                e.target.value,
                                                optionIndex,
                                                stepIndex,
                                                field.field_row_id
                                            )
                                        }
                                        value={
                                            field?.total_steps_indexes?.hasOwnProperty(
                                                convertSpacesToUnderscores(option)
                                            )
                                                ? 'yes'
                                                : 'no'
                                        }
                                    >
                                        <option value="" selected disabled>
                                            select
                                        </option>
                                        <option value="no">Don&apos;t Skip Steps</option>
                                        <option value="yes">Skip Steps</option>
                                    </select>
                                </div>
                            )}

                            <div className="flex gap-5 pl-10 justify-end items-center cursor-pointer">
                                {' '}
                                <div
                                    className="cursor-pointer"
                                    onClick={() => addConditionalOption(field.field_row_id, optionIndex, true)}
                                >
                                    {' '}
                                    <BgDeleteIcon />
                                </div>
                                <div
                                    onClick={() =>
                                        handleAddFieldInConditional(optionIndex, stepIndex, field?.field_row_id)
                                    }
                                >
                                    {' '}
                                    <BgAddIcon />{' '}
                                </div>
                            </div>
                        </div>
                        {field?.conditional_steps?.hasOwnProperty(convertSpacesToUnderscores(option)) &&
                            field?.conditional_steps[convertSpacesToUnderscores(option)]?.display_on_new_page && (
                                <div className="my-3 w-[250px]">
                                    <label>Step heading</label>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Enter step heading"
                                            className="mt-2"
                                            value={field?.conditional_steps[option]?.steps[0]?.step_heading}
                                            onChange={(e) =>
                                                handleSubStepHeadingChange(
                                                    e.target.value,
                                                    field.field_row_id,
                                                    optionIndex
                                                )
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                            )}
                        <div className="flex gap-3 items-center small-text p-1 px-3 rounded-xl text-blueish w-[60%] break-all mt-5 bg-[#f4f2f2]">
                            <div>
                                <HintIcon />
                            </div>
                            <div>
                                You have the option to either add a form in the current step or create a new step for
                                it.
                            </div>
                        </div>
                        {field?.total_steps_indexes?.hasOwnProperty(convertSpacesToUnderscores(option)) && (
                            <div>
                                <div className="flex gap-3 items-center small-text p-1 px-3 rounded-xl text-blueish w-[60%] break-all mt-5 bg-[#f4f2f2]">
                                    <div>
                                        <HintIcon />
                                    </div>
                                    <div>If there are any steps available, you have the option to skip them.</div>
                                </div>

                                <div className="buttons-font mt-3 font-poppins ">
                                    Please Choose the steps that you would like to skip
                                </div>

                                <div className="mt-3">
                                    {field?.total_steps_indexes[convertSpacesToUnderscores(option)]?.map(
                                        (stepItem, step_index) => (
                                            <div
                                                key={'step_index' + step_index}
                                                className="flex gap-2 my-3 items-center font-poppins buttons-font-lighter"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        field?.skip_steps &&
                                                        field?.skip_steps[convertSpacesToUnderscores(option)]?.includes(
                                                            stepItem?.step_id
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    className="!w-5 !h-5 !rounded-md  !text-thirdbg "
                                                    onChange={(e) =>
                                                        addRemoveSkipStep(
                                                            e.target.checked,
                                                            field.field_row_id,
                                                            option,
                                                            stepItem?.step_id,
                                                            step_index
                                                        )
                                                    }
                                                />
                                                <div>
                                                    Step{' '}
                                                    {steps?.length > 0 && handleCalculateStepIndex(stepItem?.step_id)}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                        {field?.conditional_steps?.hasOwnProperty(convertSpacesToUnderscores(option)) && (
                            <div className="border-l border-[#BBBBBB] m-5">
                                {field?.conditional_steps[convertSpacesToUnderscores(option)]?.steps[0]?.fields?.map(
                                    (field, fieldIndex) => (
                                        <div key={fieldIndex}>
                                            <div className="relative">
                                                <div className="border-b w-[30px] h-1 absolute top-[53px] border-[#BBBBBB]"></div>

                                                <div className="form-input-container mt-5 relative ml-[35px] ">
                                                    {showHint && fieldRowId === field?.field_row_id ? (
                                                        <Hint
                                                            onchange={(value) =>
                                                                handleAddHintSubField(value, field.field_row_id)
                                                            }
                                                            onClose={() => toggleHint()}
                                                            value={field.hint}
                                                        />
                                                    ) : (
                                                        ''
                                                    )}

                                                    <div className="form-child ">
                                                        <label>Field Type</label>

                                                        <div className="mt-2 ">
                                                            <SimpleSelectMenu
                                                                placeholder="Select"
                                                                targetProperty="value"
                                                                selectedValue={field.type}
                                                                valuePropertyName="key"
                                                                optionsData={
                                                                    [
                                                                        { key: 'abn', value: 'ABN' },
                                                                        { key: 'address', value: 'Address' },
                                                                        { key: 'bsb', value: 'BSB' },
                                                                        {
                                                                            key: 'business-email',
                                                                            value: 'Business Email'
                                                                        },
                                                                        {
                                                                            key: 'business-industry-occupation',
                                                                            value: 'Business Industry Occupation'
                                                                        },
                                                                        { key: 'checkbox', value: 'Checkbox' },
                                                                        { key: 'conditional', value: 'Conditional' },
                                                                        { key: 'date', value: 'Date' },
                                                                        { key: DOB, value: 'Date of birth' },
                                                                        { key: 'document', value: 'Document' },
                                                                        { key: 'dropdown', value: 'Selection' },
                                                                        { key: 'email', value: 'Email' },
                                                                        { key: 'first-name', value: 'First name' },
                                                                        { key: 'image', value: 'Image' },
                                                                        { key: 'last-name', value: 'Last name' },
                                                                        { key: 'middle-name', value: 'Middle name' },
                                                                        { key: 'number', value: 'Number' },
                                                                        { key: 'phone-number', value: 'Phone Number' },
                                                                        {
                                                                            key: 'privacy-policy',
                                                                            value: 'Privacy Policy'
                                                                        },
                                                                        { key: 'radio', value: 'Radio' },
                                                                        {
                                                                            key: 'salary-wages-occupation',
                                                                            value: 'Salary Wages Occupation'
                                                                        },
                                                                        { key: 'signature', value: 'Signature' },
                                                                        { key: 'switch', value: 'Switch Button' },
                                                                        {
                                                                            key: 'terms-and-conditions',
                                                                            value: 'Terms and conditions'
                                                                        },
                                                                        { key: 'text', value: 'Text' },
                                                                        { key: 'tfn', value: 'TFN' },
                                                                        { key: LIST, value: LIST }
                                                                    ] || []
                                                                }
                                                                sx="rounded-xl  py-3 ring-gray-400 bg-[#fff] !font-normal cursor-pointer"
                                                                showChipOnly={false}
                                                                onChangeValue={(obj) =>
                                                                    handleFieldTypeChangeCondtional(
                                                                        obj.key,
                                                                        stepIndex,
                                                                        field.field_row_id,
                                                                        optionIndex
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="form-child default-forms ">
                                                        {field.type === 'text' ||
                                                        field.type === 'number' ||
                                                        field.type === 'date' ||
                                                        field.type === DOB ||
                                                        field.type === 'email' ||
                                                        field.type === 'business-email' ||
                                                        field.type === 'tel' ||
                                                        field.type === 'first-name' ||
                                                        field.type === 'middle-name' ||
                                                        field.type === 'last-name' ||
                                                        field.type === 'phone-number' ||
                                                        field.type === 'abn' ||
                                                        field.type === 'tfn' ||
                                                        field.type === 'address' ||
                                                        field?.type === 'salary-wages-occupation' ||
                                                        field.type === 'business-industry-occupation' ||
                                                        field.type === BSB ||
                                                        field.type === LIST ? (
                                                            <div>
                                                                {' '}
                                                                <div>
                                                                    {' '}
                                                                    <label>Label Name</label>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={200}
                                                                        placeholder="Enter Label"
                                                                        className="mt-2"
                                                                        value={field.label}
                                                                        onChange={(e) =>
                                                                            handleLabelConditional(
                                                                                e.target.value,
                                                                                field.field_row_id
                                                                            )
                                                                        }
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {field.type === 'image' ||
                                                        field.type === 'signature' ||
                                                        field.type === 'document' ? (
                                                            <div>
                                                                {' '}
                                                                <div>
                                                                    {' '}
                                                                    <label>Label Name</label>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={200}
                                                                        placeholder="Enter Label"
                                                                        value={field.label}
                                                                        required
                                                                        onChange={(e) =>
                                                                            handleLabelConditional(
                                                                                e.target.value,
                                                                                field.field_row_id
                                                                            )
                                                                        }
                                                                        className="mt-2"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {field.type === 'checkbox' ||
                                                        field.type === 'radio' ||
                                                        field.type === 'dropdown' ||
                                                        field?.type === 'recurring' ? (
                                                            <div>
                                                                {' '}
                                                                <div>
                                                                    {' '}
                                                                    <label>Label</label>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={200}
                                                                        required
                                                                        placeholder="Enter Label"
                                                                        value={field.label}
                                                                        onChange={(e) =>
                                                                            handleLabelConditional(
                                                                                e.target.value,
                                                                                field.field_row_id
                                                                            )
                                                                        }
                                                                        className="mt-2"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {field.type === 'switch' ? (
                                                            <div>
                                                                <div>
                                                                    {' '}
                                                                    <label>Label</label>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={100}
                                                                        required
                                                                        placeholder="Enter Label"
                                                                        value={field.label}
                                                                        onChange={(e) =>
                                                                            handleLabelConditional(
                                                                                e.target.value,
                                                                                field.field_row_id
                                                                            )
                                                                        }
                                                                        className="mt-2"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="form-child">
                                                        {field.type === 'checkbox' ||
                                                        field.type === 'radio' ||
                                                        field.type === 'dropdown' ||
                                                        field?.type === 'recurring' ? (
                                                            <div className="">
                                                                <div className="invisible">fd</div>{' '}
                                                                <div
                                                                    onClick={() => {
                                                                        if (field?.type === 'recurring') {
                                                                            console.info(field);
                                                                        } else {
                                                                            addOptionConditional(field.field_row_id);
                                                                        }
                                                                    }}
                                                                    className="flex justify-end items-center cursor-pointer mt-3 "
                                                                >
                                                                    <TooltipChipWrap
                                                                        title={'Click here to add option' || '__'}
                                                                        variant="soft"
                                                                    >
                                                                        <div>
                                                                            {' '}
                                                                            <BgAddIcon />
                                                                        </div>
                                                                    </TooltipChipWrap>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {field.type === 'text' ||
                                                        field.type === 'number' ||
                                                        field.type === 'date' ||
                                                        field.type === DOB ||
                                                        field.type === 'email' ||
                                                        field.type === 'business-email' ||
                                                        field.type === 'tel' ||
                                                        field.type === 'first-name' ||
                                                        field.type === 'middle-name' ||
                                                        field.type === 'last-name' ||
                                                        field.type === 'phone-number' ||
                                                        field.type === 'abn' ||
                                                        field.type === 'tfn' ||
                                                        field.type === 'address' ||
                                                        field.type === BSB ||
                                                        field.type === LIST ? (
                                                            <div>
                                                                {' '}
                                                                <label>Placeholder</label>
                                                                <input
                                                                    type="text"
                                                                    maxLength={200}
                                                                    required
                                                                    placeholder="Enter Placeholder"
                                                                    className="mt-2"
                                                                    value={field.placeholder}
                                                                    onChange={(e) =>
                                                                        handleLabelConditional(
                                                                            e.target.value,
                                                                            field.field_row_id,
                                                                            true
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {field?.type === 'switch' && (
                                                            <div>
                                                                {' '}
                                                                <div className="invisible">switch</div>
                                                                <div className="grid grid-cols-2 gap-3 relative mt-2">
                                                                    <div className="flex items-center gap-2">
                                                                        {' '}
                                                                        <input
                                                                            type="text"
                                                                            maxLength={100}
                                                                            required
                                                                            placeholder="option one"
                                                                            value={field.options[0]}
                                                                            onChange={(e) =>
                                                                                handleSwitchOptionOneConditional(
                                                                                    e.target.value,
                                                                                    field.field_row_id
                                                                                )
                                                                            }
                                                                        />{' '}
                                                                    </div>

                                                                    <div className="flex items-center gap-2">
                                                                        {' '}
                                                                        <input
                                                                            value={field.options[1]}
                                                                            required
                                                                            onChange={(e) =>
                                                                                handleSwitchOptionTwoConditional(
                                                                                    e.target.value,
                                                                                    field.field_row_id
                                                                                )
                                                                            }
                                                                            type="text"
                                                                            placeholder="option two"
                                                                        />{' '}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-child   ">
                                                        <div className="invisible ">t</div>

                                                        <div className="flex gap-2 md:mt-3 justify-between md:justify-center items-center">
                                                            {' '}
                                                            <button
                                                                className="cursor-pointer"
                                                                onClick={() => toggleHint(field.field_row_id)}
                                                                type="button"
                                                            >
                                                                <InfoIcon />
                                                            </button>{' '}
                                                            <button
                                                                onClick={() =>
                                                                    handleDeleteFieldConditional(field.field_row_id)
                                                                }
                                                                type="button"
                                                            >
                                                                <BgDeleteIcon />
                                                            </button>{' '}
                                                        </div>
                                                    </div>
                                                </div>

                                                {field?.type === 'conditional' && (
                                                    <RenderConditionalInputs
                                                        field={field}
                                                        stepIndex={stepIndex}
                                                        handleConditionalOption={handleConditionalOption}
                                                        handleConditionalStep={handleConditionalStep}
                                                        handleDisplayInStepsConditional={
                                                            handleDisplayInStepsConditional
                                                        }
                                                        handleSkipStepDisplay={handleSkipStepDisplay}
                                                        handleAddFieldInConditional={handleAddFieldInConditional}
                                                        handleFieldTypeChangeCondtional={
                                                            handleFieldTypeChangeCondtional
                                                        }
                                                        handleLabelConditional={handleLabelConditional}
                                                        addOptionConditional={addOptionConditional}
                                                        handleOptionConditional={handleOptionConditional}
                                                        deleteOptionConditional={deleteOptionConditional}
                                                        handleDeleteFieldConditional={handleDeleteFieldConditional}
                                                        handleSwitchOptionOneConditional={
                                                            handleSwitchOptionOneConditional
                                                        }
                                                        handleSwitchOptionTwoConditional={
                                                            handleSwitchOptionTwoConditional
                                                        }
                                                        hanleRequiredConditional={hanleRequiredConditional}
                                                        addConditionalOption={addConditionalOption}
                                                        handleSubStepHeadingChange={handleSubStepHeadingChange}
                                                        addRemoveSkipStep={addRemoveSkipStep}
                                                        isRecurring={true}
                                                        handleAddHintSubField={handleAddHintSubField}
                                                        steps={steps}
                                                        handleCalculateStepIndex={handleCalculateStepIndex}
                                                    />
                                                )}
                                                {field.type === 'terms-and-conditions' ||
                                                field.type === 'privacy-policy' ? (
                                                    <div className="mt-4 ml-[35px]">
                                                        {' '}
                                                        <div>
                                                            {' '}
                                                            <label>Placeholder </label>
                                                            <div className="mt-2">
                                                                <TextEditor
                                                                    initialValue={field?.default_value || ''}
                                                                    maxWords={10000}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}

                                                {field.type === 'checkbox' ||
                                                field.type === 'radio' ||
                                                field.type === 'dropdown' ? (
                                                    <div className="mt-2 ml-[35px] ">
                                                        {field?.options?.length > 0 && (
                                                            <div>
                                                                {' '}
                                                                <label>options</label>
                                                            </div>
                                                        )}
                                                        <div className="grid grid-cols-3 md:grid-cols-4 gap-3 mt-2">
                                                            {field.options.map((option, optionIndex) => (
                                                                <div
                                                                    key={'optionIndex' + optionIndex}
                                                                    className="flex items-center gap-2"
                                                                >
                                                                    {' '}
                                                                    <input
                                                                        maxLength={100}
                                                                        onChange={(e) =>
                                                                            handleOptionConditional(
                                                                                e.target.value,
                                                                                field.field_row_id,
                                                                                optionIndex
                                                                            )
                                                                        }
                                                                        type="text"
                                                                        placeholder="option"
                                                                        value={option}
                                                                        required
                                                                    />
                                                                    <div
                                                                        className="cursor-pointer"
                                                                        onClick={() =>
                                                                            deleteOptionConditional(
                                                                                field.field_row_id,
                                                                                optionIndex
                                                                            )
                                                                        }
                                                                    >
                                                                        <SideTimes />
                                                                    </div>{' '}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}

                                                {field?.type !== 'recurring' && field?.type !== 'conditional' && (
                                                    <div className="flex items-center gap-2 mt-3 ml-[35px]">
                                                        <div className="default-forms ">
                                                            {' '}
                                                            <label> Required</label>{' '}
                                                        </div>
                                                        <div>
                                                            <input
                                                                onChange={(e) =>
                                                                    hanleRequiredConditional(
                                                                        e.target.checked,
                                                                        field.field_row_id
                                                                    )
                                                                }
                                                                type="checkbox"
                                                                className="!w-[20px] !h-5 !rounded-md !text-thirdbg "
                                                                checked={field?.required ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
