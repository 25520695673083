import React from 'react';
import useRoleGuard from 'Hooks/useRoleGuard';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { handleCodeUpdate } from 'store/global/globalReducer';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';

const QrCodesList = ({ qrcodes, handleArchiveQrCodeId, toggleUpdate }) => {
    const [selectedQRCode, _setSelectedQRCode] = useState(null);
    const [showImage, setShowImage] = useState('');
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();

    const handleUpdate = (code, createdBy) => {
        if (hasPermissions('Qr_codes', ['write', 'admin'], createdBy)) {
            toggleUpdate();
            dispatch(handleCodeUpdate(code));
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    return (
        <div className="md:px-10 px-5 mt-5">
            {showImage && <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />}

            <div className=" flex items-center flex-wrap gap-5">
                {qrcodes.map((code) => (
                    <div
                        key={code.qr_code_id}
                        className={`bg-white shadow-md w-[250px] min-h-[250px] p-5 rounded-xl relative ${
                            selectedQRCode === code.qr_code_id ? 'selected' : ''
                        }`}
                    >
                        <div className="flex justify-end items-center gap-2 ">
                            {' '}
                            <span onClick={() => handleUpdate(code, code?.created_by)} className="cursor-pointer">
                                <BgEditIcon />
                            </span>
                            <span
                                onClick={() => handleArchiveQrCodeId(code?.qr_code_id, code?.created_by)}
                                className="cursor-pointer"
                            >
                                <BgDeleteIcon />
                            </span>
                        </div>
                        <div
                            onClick={() => setShowImage(code?.qr_code)}
                            className="flex flex-col justify-center items-center"
                        >
                            <img src={code.qr_code} alt="qrcode" className="w-[150px] h-[150px] cursor-pointer" />
                            <div className="buttons-font font-poppins break-all">
                                {code.title} | {code.branch_name}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QrCodesList;
