import React, { useState, useEffect } from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { BluePointRight } from 'utills/svgs/BluePointRight';
import { NoDataFound } from 'components/common/NoDataFound';
import {
    updateBusinesGroupPermissionStatusThunk,
    updateBusinessGroupstatusThunk,
    fetchRoleGroupsThunk
} from 'store/settings/team/team';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { setLoader } from 'store/global/globalReducer';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { ADMIN_WRITE } from 'utills/constants/forms/roleManagement.constants';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { convertUnderscoresToSpaces } from 'utills/handleText';

const getPermissionUpdate = (updatedData, groupIndex, moduleIndex, permissionIndex, status) => {
    if (
        groupIndex >= 0 &&
        groupIndex < updatedData.length &&
        moduleIndex >= 0 &&
        moduleIndex < updatedData[groupIndex].business_modules.length &&
        permissionIndex >= 0 &&
        permissionIndex < updatedData[groupIndex].business_modules[moduleIndex].permissions.length
    ) {
        const group = updatedData[groupIndex];
        const module = group.business_modules[moduleIndex];
        const permission = module.permissions[permissionIndex];

        const updatedPermission = {
            ...permission,
            permission_status: status
        };

        const updatedModule = {
            ...module,
            permissions: [
                ...module.permissions.slice(0, permissionIndex),
                updatedPermission,
                ...module.permissions.slice(permissionIndex + 1)
            ]
        };

        const updatedGroup = {
            ...group,
            business_modules: [
                ...group.business_modules.slice(0, moduleIndex),
                updatedModule,
                ...group.business_modules.slice(moduleIndex + 1)
            ]
        };

        updatedData[groupIndex] = updatedGroup;
    }
    return updatedData;
};

const RoleTable = (props) => {
    const { data, setSelectedRoles, selectedRoles, toggleEditRoleGroup } = props;
    const [roleGroupData, setData] = useState([]);
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();
    useEffect(() => {
        if (data) {
            setData(data);
        }
    }, [data]);

    //handleRoleGroupId

    const handlePermissionChange = (groupIndex, moduleIndex, permissionIndex) => {
        if (!hasPermissions('Role_management', ['admin'])) {
            return toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }

        let updatedData = [...roleGroupData];
        const permission = updatedData[groupIndex].business_modules[moduleIndex].permissions[permissionIndex];
        const permission_status = permission?.permission_status === 'active' ? 'inactive' : 'active';
        updatedData = getPermissionUpdate(updatedData, groupIndex, moduleIndex, permissionIndex, permission_status);
        const payload = {
            business_group_permission_id: permission?.business_group_permission_id,
            permission_status: permission_status
        };
        dispatch(updateBusinesGroupPermissionStatusThunk(payload))
            .then(() => {
                setData(updatedData);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const handleGroupChange = (group) => {
        const status = group?.status === 'active' ? 'inactive' : 'active';
        let updateRoles = [...selectedRoles];
        const findRole = selectedRoles.find((role) => role.business_group_id == group.business_group_id);
        if (findRole) {
            const index = updateRoles.indexOf(findRole);
            updateRoles.splice(index, 1);
            setSelectedRoles(updateRoles);
            return;
        }
        setSelectedRoles([...selectedRoles, { business_group_id: group?.business_group_id, status: status }]);
    };

    const handleEditBusinessGroupStatus = (business_group_id) => {
        if (!hasPermissions('Role_management', ['admin'])) {
            return toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
        toggleEditRoleGroup(business_group_id);
    };

    const handleUpdateBusinessGroupStatus = (business_group_id, status, count) => {
        if (count > 0) {
            return toastHandler(
                'This role group is assigned to employees or notifications. It cant be deleted!',
                TOAST_TYPE_ERROR
            );
        }
        if (!hasPermissions('Role_management', ['admin'])) {
            return toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }

        dispatch(setLoader(true));
        const payload = {
            business_group_id: business_group_id,
            status: status
        };
        dispatch(updateBusinessGroupstatusThunk(payload))
            .then(() => {
                dispatch(fetchRoleGroupsThunk())
                    .then(() => {
                        let updatedRoles = selectedRoles.filter((item) => item.business_group_id !== business_group_id);
                        setSelectedRoles(updatedRoles);
                        dispatch(setLoader(false));
                    })
                    .catch((error) => {
                        dispatch(setLoader(false));
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });
            })
            .catch((error) => {
                dispatch(setLoader(false));

                console.error(error);
            });
    };

    const [showGroup, setShowGroup] = useState(false);
    const [groupId, setGroupId] = useState([]);

    const toggleModules = (business_group_id) => {
        const newGroupId = [...groupId];

        const findId = newGroupId.find((id) => id === business_group_id);
        if (findId) {
            const index = newGroupId.indexOf(findId);
            newGroupId.splice(index, 1);
            setGroupId(newGroupId);
        } else {
            setGroupId([...newGroupId, business_group_id]);
        }

        setShowGroup(!showGroup);
    };

    return (
        <div className="mt-5 md:px-10 relative px-5 bg-white rounded-xl mx-10 py-5">
            <div className="role-container">
                <div>
                    <div className="grid grid-cols-7 md:grid-cols-6 gap-3">
                        <div className="flex gap-3 items-center col-span-3 md:col-span-2">
                            <div className="table-title ">Role Name</div>
                        </div>
                        <div className="flex items-center ">Created By</div>
                        <div className="flex items-center ">Created Date</div>
                        <div className="flex items-center ">Last updated</div>

                        <div className="flex items-center ">Actions</div>
                    </div>
                    <div className="team-line mt-2"> </div>
                </div>
                {roleGroupData && roleGroupData.length ? (
                    roleGroupData.map((group, groupIndex) => (
                        <div key={groupIndex}>
                            <div className="grid grid-cols-7 md:grid-cols-6 mt-3 gap-3">
                                <div className="flex gap-3 col-span-3 role-body md:col-span-2">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={
                                                selectedRoles.some(
                                                    (item) => item.business_group_id === group.business_group_id
                                                ) ?? false
                                            }
                                            onChange={() => handleGroupChange(group)}
                                            className="cursor-pointer !text-[#b695f8]  rounded-[2px]"
                                        />
                                    </div>
                                    <div className="flex gap-1 items-center">
                                        <div
                                            onClick={() => toggleModules(group?.business_group_id)}
                                            className="flex items-center cursor-pointer"
                                        >
                                            <span
                                                className={`${
                                                    showGroup && groupId.includes(group.business_group_id)
                                                        ? 'transform rotate-90'
                                                        : 'defaultClass'
                                                }`}
                                            >
                                                {' '}
                                                <BluePointRight />
                                            </span>
                                        </div>
                                        <div className="flex items-center table-title font-fira">
                                            {group?.business_group_name}
                                        </div>
                                        <div className="role-type ml-2">{group?.business_group_type}</div>
                                    </div>
                                </div>
                                <div className="role-body flex items-center">
                                    {group?.user?.first_name} {group?.user?.last_name}
                                </div>
                                <div className="role-body">
                                    <FormateDateTime
                                        dateTime={group?.created_at}
                                        insertedFormat={'DD-MM-YYYYTHH:mm:ss[Z]'}
                                    />
                                </div>
                                <div className="role-body">
                                    <FormateDateTime
                                        dateTime={group?.updated_at}
                                        insertedFormat={'DD-MM-YYYYTHH:mm:ss[Z]'}
                                    />
                                </div>

                                <div className=" flex gap-2 ">
                                    <div
                                        onClick={() => {
                                            handleEditBusinessGroupStatus(group?.business_group_id);
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <BgEditIcon />
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleUpdateBusinessGroupStatus(
                                                group?.business_group_id,
                                                'inactive',
                                                group?.assignee_count
                                            )
                                        }
                                        className="cursor-pointer"
                                    >
                                        <BgDeleteIcon />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {groupId.includes(group.business_group_id) ? (
                                    <motion.div
                                        initial={{ y: '-80', opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: '-80', opacity: 0 }}
                                        transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                                    >
                                        <hr className="mt-3" />

                                        {group && group?.business_modules.length
                                            ? group?.business_modules.map((module, moduleIndex) => (
                                                  <div
                                                      key={'business_module' + moduleIndex}
                                                      className="w-[50%] pl-9 font-poppins text-secondarybg"
                                                  >
                                                      <div className="grid grid-cols-5 gap-5 mt-3">
                                                          <div className="col-span-2 table-title font-fira">
                                                              {convertUnderscoresToSpaces(module?.business_module_name)}
                                                          </div>
                                                          {module?.permissions
                                                              .filter(
                                                                  (permission) =>
                                                                      !(
                                                                          permission.permission_name === 'Write' &&
                                                                          ADMIN_WRITE.includes(
                                                                              module?.business_module_name
                                                                          )
                                                                      )
                                                              )
                                                              .map((permission, permissionIndex) => (
                                                                  <div
                                                                      key={'permissions' + permissionIndex}
                                                                      className="flex gap-2 items-center font-poppins buttons-font-lighter"
                                                                  >
                                                                      <input
                                                                          type="checkbox"
                                                                          className=" cursor-pointer text-thirdbg  rounded-[2px]"
                                                                          checked={
                                                                              permission.permission_status === 'active'
                                                                          }
                                                                          onChange={() =>
                                                                              handlePermissionChange(
                                                                                  groupIndex,
                                                                                  moduleIndex,
                                                                                  permissionIndex
                                                                              )
                                                                          }
                                                                      />
                                                                      <div>
                                                                          {convertUnderscoresToSpaces(
                                                                              permission?.permission_name
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                              ))}
                                                      </div>
                                                      <hr className="mt-3" />
                                                  </div>
                                              ))
                                            : ''}
                                    </motion.div>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="team-line mt-5"> </div>
                        </div>
                    ))
                ) : (
                    <NoDataFound message="No Data Found" />
                )}
            </div>
        </div>
    );
};
export default RoleTable;
