import { useState, useRef, useEffect } from 'react';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

export const useCaptchaHandler = ({ onSuccess } = {}) => {
    const [isCaptchaRequired, setIsCaptchaRequired] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const [error, setError] = useState(false);
    const [activeEndpoint, setActiveEndpoint] = useState(null);
    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (isCaptchaRequired && recaptchaRef.current !== null) {
            resetRecaptcha();
        }
    }, [isCaptchaRequired]);

    const handleRecaptchaChange = (token) => {
        setCaptchaValue(token);
        if (token && onSuccess) {
            onSuccess();
        }
    };

    const resetRecaptcha = () => {
        setCaptchaValue(null);
        if (recaptchaRef.current) {
            recaptchaRef.current.resetCaptcha();
        }
    };

    const handleRequest = async (endpointKey, requestFn, requestData) => {
        try {
            // Check if captcha is required for this endpoint
            if (isCaptchaRequired && activeEndpoint === endpointKey && !captchaValue) {
                toastHandler('Please complete the captcha', TOAST_TYPE_ERROR);
                return null;
            }

            // Prepare request payload
            const payload = {
                ...requestData,
                ...(captchaValue && activeEndpoint === endpointKey ? { captcha_value: captchaValue } : {})
            };

            // Make the request
            return await requestFn(payload)
                .then((response) => {
                    recaptchaRef.current?.resetCaptcha();

                    // Handle captcha requirement in response
                    if (response?.payload?.captcha_required) {
                        setActiveEndpoint(endpointKey);
                        setIsCaptchaRequired(true);
                        setCaptchaValue(null);
                        setError(true);
                        return null;
                    }

                    // Reset captcha state on success
                    if (response?.payload) {
                        setIsCaptchaRequired(false);
                        setActiveEndpoint(null);
                        setError(false);
                    }

                    return response;
                })
                .catch((_err) => {
                    resetRecaptcha();
                    setError(true);
                    return null;
                });
        } catch (err) {
            resetRecaptcha();
            setError(true);
            return null;
        }
    };

    return {
        isCaptchaRequired,
        setIsCaptchaRequired,
        captchaValue,
        recaptchaRef,
        handleCaptchaChange: handleRecaptchaChange,
        resetRecaptcha,
        handleRequest,
        error,
        setError,
        activeEndpoint
    };
};
