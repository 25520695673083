import React, { useEffect, useState } from 'react';
import { Loader } from 'components/common/Loader';
import { startHeartbeatInterval, stopHeartbeatInterval } from 'utills/socketEvents';
import { EMAIL_VERIFICATION_EVENT, EMAIL_VERIFICATION_EVENT_NEW, SOCKET_URL, USER_TYPE } from 'utills/globalVars';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { EnvelopeLink } from './../../utills/svgs/EnvelopeLink';
import { setContinueEnabled, setLoader } from 'store/global/globalReducer';
import { setEmailSocketConnection } from 'store/global/globalPresistReducer';
import { Button } from 'components/atoms/buttons/Button';
import { cn } from 'utills/tailwindUtil';
import { sendOtpPhoneRegisterThunk, signupUserThunk } from 'store/auth/slices';
import { InputField } from 'components/atoms/inputs/InputField';

export const EmailLinkVerification = ({ handleCondition }) => {
    const dispatch = useDispatch();

    const { isLoading, continueEnabled } = useSelector((state) => state.global);
    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerEnded, setTimerEnded] = useState(false);
    const tempSignUpData = JSON.parse(localStorage.getItem('signupUser'));

    const handleSubmit = (e) => {
        e.preventDefault();
    };
    const { emailIntervalId } = useSelector((state) => state.globalPresist);

    const handleSocket = async () => {
        try {
            const newSocket = io(SOCKET_URL, {
                query: {
                    email: tempSignUpData?.email,
                    type: 'email',
                    user_type: USER_TYPE,
                    category: EMAIL_VERIFICATION_EVENT,
                    event_ids: EMAIL_VERIFICATION_EVENT
                }
            });
            newSocket.on('connect', () => {
                console.info('Socket connected');

                startHeartbeatInterval(dispatch, newSocket, setEmailSocketConnection);
            });

            newSocket.on(EMAIL_VERIFICATION_EVENT, async (data) => {
                const newData = JSON.parse(data);
                if (newData?.data?.is_verified) {
                    dispatch(setContinueEnabled(true));
                    console.info(newData, 'data_in_socket');
                }
            });

            newSocket.on(EMAIL_VERIFICATION_EVENT_NEW, async (data) => {
                if (data === 'true') {
                    dispatch(setContinueEnabled(true));
                }
            });

            newSocket.on('disconnect', (reason) => {
                console.info('Socket disconnected:', reason);
            });

            newSocket.on('error', (error) => {
                console.error('Socket error:', error);
            });
        } catch (error) {
            console.error('Error initializing socket:', error);
        }
    };

    useEffect(() => {
        handleSocket();
        return () => {
            dispatch(setContinueEnabled(false));
            stopHeartbeatInterval(dispatch, emailIntervalId, setEmailSocketConnection);
        };
    }, []);

    const sendOtpToPhone = () => {
        dispatch(setLoader(true));

        const payload = {
            user_type: 'employee',
            email: tempSignUpData?.email,
            type: 'email',
            phone_number: `${tempSignUpData?.phone_number}`,
            socket_id: localStorage.getItem('socket_id')
        };
        dispatch(sendOtpPhoneRegisterThunk(payload))
            .then((response) => {
                if (response.payload) {
                    handleCondition(3);
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        timeExecution();
    }, []);
    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const sendOTPEmail = async (e) => {
        e.preventDefault();

        const payload = {
            email: tempSignUpData?.email,
            user_type: 'employee',
            type: 'email',
            phone_number: `${tempSignUpData?.phone_number}`,
            socket_id: localStorage.getItem('socket_id')
        };

        dispatch(setLoader(true));

        await dispatch(signupUserThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setTimerEnded(false);
                    setTimeRemaining(90);
                    timeExecution();
                }
            })
            .catch((error) => {
                console.error(error);
            });

        dispatch(setLoader(false));
    };

    return (
        <div>
            {' '}
            <div>
                {isLoading && (
                    <div className="loader-overlay">
                        <Loader />
                    </div>
                )}
                <div className="flex min-h-[85vh] flex-col justify-center sm:px-6 lg:px-8">
                    <div className="mb-10 w-full sm:mx-auto sm:w-full sm:max-w-[650px]  lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                        <div className="otp-wrapper px-5 md:px-24 mt-5">
                            <div className="flex flex-col gap-3 items-center justify-center py-8">
                                <EnvelopeLink className="w-30 mt-5 mr-5" />
                                <div className="text-center mt-5 text-4xl font-semibold font-secondary pt-4">
                                    Email Link Verification
                                </div>
                            </div>

                            <div className="text-md font-poppins font-[400] text-center">
                                {!continueEnabled
                                    ? `A verification link has been sent to your email. Please check your inbox and click the
                                link to verify your email address.`
                                    : `Email verified successfully please click on continue button to proceed.`}
                            </div>

                            <div className="default-forms mt-5">
                                <InputField
                                    classes="mt-2 pl-3"
                                    labelSx="  "
                                    label="Email"
                                    inputValue={tempSignUpData?.email}
                                    name="email"
                                    placeholder="Email"
                                    disabled={true}
                                />
                            </div>

                            {!continueEnabled && (
                                <div className="resend-confirmation text-sm font-poppins mt-5">
                                    <p>
                                        {timerEnded ? (
                                            <span className={`text-thirdbg  cursor-pointer`} onClick={sendOTPEmail}>
                                                Resend
                                            </span>
                                        ) : (
                                            <span>
                                                <span>
                                                    <span className="text-thirdbg  cursor-no-drop mr-1">Resend</span>
                                                    link in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`})
                                                </span>
                                            </span>
                                        )}
                                    </p>
                                </div>
                            )}

                            <form onSubmit={handleSubmit}>
                                <div className="mt-44 mb-10">
                                    <div className="flex flex-col sm:flex-row justify-between gap-5 items-center px-1-">
                                        <div>
                                            <button
                                                onClick={() => handleCondition(1)}
                                                type="button"
                                                className="btn-1 buttons-font font-fira px-12 py-2"
                                            >
                                                Back
                                            </button>
                                        </div>
                                        <div>
                                            <Button
                                                atClick={() => {
                                                    stopHeartbeatInterval(
                                                        dispatch,
                                                        emailIntervalId,
                                                        setEmailSocketConnection
                                                    );
                                                    sendOtpToPhone();
                                                }}
                                                disabled={continueEnabled ? false : true}
                                                classes={cn(
                                                    'btn-2 buttons-font font-fira  px-8 py-2',
                                                    continueEnabled ? '' : '!opacity-30'
                                                )}
                                                title={'Continue'}
                                            />
                                        </div>
                                    </div>

                                    <div className="small-text text-center mt-5">
                                        Wrong Email ?{' '}
                                        <span
                                            className="text-thirdbg cursor-pointer"
                                            onClick={() => handleCondition(1)}
                                        >
                                            Change it
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
