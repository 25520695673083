import React from 'react';

export const WhitePlus = () => {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1503_7898)">
                    <path
                        d="M7.69375 0.0656252C7.61562 0.10625 7.52187 0.1875 7.475 0.259375L7.39062 0.3875L7.38125 3.88125L7.375 7.375H3.91563H0.45625L0.31875 7.44063C0.2375 7.48125 0.15 7.55937 0.1 7.63437C0.028125 7.74375 0.015625 7.79375 0.015625 8C0.015625 8.20625 0.028125 8.25625 0.1 8.36562C0.15 8.44062 0.2375 8.51875 0.31875 8.55937L0.45625 8.625H3.91563H7.375V12.0844V15.5437L7.44063 15.6813C7.48125 15.7625 7.55938 15.85 7.63438 15.9C7.74375 15.9719 7.79375 15.9844 8 15.9844C8.20625 15.9844 8.25625 15.9719 8.36563 15.9C8.44063 15.85 8.51875 15.7625 8.55937 15.6813L8.625 15.5437V12.0844V8.625H12.0844H15.5437L15.6813 8.55937C15.7625 8.51875 15.85 8.44062 15.9 8.36562C15.9719 8.25625 15.9844 8.20625 15.9844 8C15.9844 7.79375 15.9719 7.74375 15.9 7.63437C15.85 7.55937 15.7625 7.48125 15.6813 7.44063L15.5437 7.375H12.0844H8.625V3.91562V0.45625L8.55937 0.318749C8.4625 0.11875 8.29375 0.0187502 8.03438 0.00624943C7.86875 0 7.8 0.00937462 7.69375 0.0656252Z"
                        fill="white"
                        stroke="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1503_7898">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
