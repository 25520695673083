import React from 'react';
import CustomersPage from '../../components/workplace-content/appointment-reminder-pages/customers/CustomersPage';
const Customers = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <CustomersPage />
            </div>
        </div>
    );
};

export default Customers;
