import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeHttpRequest } from '../../axios';
import { endpoint, methods } from 'apiEndpoints';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';

const initialState = {
    activeTab: '',
    tableView: '',
    filterByType: '',
    filters: {
        search_term: '',
        from_date: '',
        group_id: '',
        order_by: {}
    },
    data: {
        all: null,
        prospective: null,
        active: null,
        inactive: null,
        filtered: null,
        favourits: null
    },
    currentPage: {
        all: 0,
        prospective: 0,
        active: 0,
        inactive: 0,
        filtered: 1,
        favourits: 0
    },
    totalPages: {
        all: 1,
        prospective: 1,
        active: 1,
        inactive: 1,
        filtered: 1,
        favourits: 1
    },
    isLoading: {
        all: true,
        prospective: true,
        active: true,
        inactive: true,
        filtered: true,
        favourits: true
    },
    itemsPerPage: {
        all: PAGINATION_PAGE_SIZE,
        prospective: PAGINATION_PAGE_SIZE,
        active: PAGINATION_PAGE_SIZE,
        inactive: PAGINATION_PAGE_SIZE,
        filtered: PAGINATION_PAGE_SIZE,
        favourits: PAGINATION_PAGE_SIZE
    },
    error: null,
    globalLoading: false
};

const getAllClientsThunk = createAsyncThunk('clients/request', async ({ jsonData, signal }) => {
    try {
        const res = await makeHttpRequest(methods.POST, endpoint.fetchBusinessCustomer, jsonData, signal);
        return res;
    } catch (error) {
        return error;
    }
});

export const ClientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setDataArray: (state, action) => {
            state.isLoading[state.activeTab] = false;
            state.data[state.activeTab] = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage[state.activeTab] = action.payload;
        },
        setCountPerPage: (state, action) => {
            state.currentPage[state.activeTab] = 1;
            state.itemsPerPage[state.activeTab] = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = !state.globalLoading ? action.payload : state.activeTab;
        },
        setTableView: (state, action) => {
            state.tableView =
                !state.globalLoading && action.payload !== '' && action.payload !== 'filtered'
                    ? action.payload !== 'none'
                        ? action.payload
                        : ''
                    : state.tableView;
        },
        setStateLoading: (state, action) => {
            state.isLoading[state.activeTab] = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setFilterByType: (state, action) => {
            state.filterByType = action.payload;
        },
        setResetTable: (state, action) => {
            state.data[action?.payload] = [];
            state.currentPage[action?.payload] = 1;
            state.totalPages[action?.payload] = 1;
            state.isLoading[action?.payload] = true;
            state.itemsPerPage[action?.payload] = PAGINATION_PAGE_SIZE;
            state.globalLoading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllClientsThunk.pending, (state) => {
                state.isLoading[state.activeTab] = true;
                state.data[state.activeTab] = [];
                state.globalLoading = true;
                state.error = null;
            })
            .addCase(getAllClientsThunk.fulfilled, (state, action) => {
                state.isLoading[state.activeTab] = false;
                state.data[state.activeTab] = action.payload.data;
                state.totalPages[state.activeTab] = Math.ceil(
                    action.payload.count / state.itemsPerPage[state.activeTab]
                );
                state.globalLoading = false;
            })
            .addCase(getAllClientsThunk.rejected, (state, action) => {
                state.isLoading[state.activeTab] = false;
                state.data[state.activeTab] = [];
                state.error = action.payload;
            });
    }
});

export const {
    setDataArray,
    setCurrentPage,
    setCountPerPage,
    setActiveTab,
    setTableView,
    setStateLoading,
    setFilters,
    setFilterByType,
    setResetTable
} = ClientSlice.actions;

export default ClientSlice;
export { getAllClientsThunk };
