import React from 'react';
import '../../css-steps/signup-css/stepSix.css';

const StepSix = (props) => {
    const dummyText =
        "Lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,";
    const showStepFour = () => {
        props.handleCondition(5);
    };
    return (
        <div className="flex min-h-[70vh] flex-col justify-center pb-10 sm:px-6 lg:px-8 ">
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px]  lg:max-w-[670px] xl:max-w-[690px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                <div className="terms-and-conditions">
                    <div className="tearms">
                        <h1 className="pl-24 s5-title">Terms and Conditions</h1>
                        <p className=" s5-date">Updated February, 2022</p>
                    </div>

                    <div className="small-text font-poppins text-secondarybg px-20 mt-5">
                        <div>{dummyText}</div>
                        <div className="mt-5">{dummyText}</div>
                        <div className="mt-5">{dummyText}</div>
                        <div className="mt-5">{dummyText}</div>
                    </div>
                </div>

                <div className=" flex justify-center items-center mt-5">
                    <button
                        onClick={showStepFour}
                        className="border-button h-[3rem] text-secondarybg font-[500]  rounded-xl font-fira px-12"
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StepSix;
