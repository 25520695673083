import React from 'react';

const MediumIcon = (className) => {
    return (
        <svg version="1.1" id="Warstwa_1" x="0px" y="0px" viewBox="0 0 16 16" className={className}>
            <title>icon/16px/medium-priority</title>
            <desc>Created with Sketch.</desc>
            <g id="icon_x2F_16px_x2F_medium-priority-">
                <g>
                    <path
                        className="st0"
                        fill="#FFAB00"
                        d="M3,4h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3C2.4,6,2,5.6,2,5S2.4,4,3,4z M3,10h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H3    c-0.6,0-1-0.4-1-1S2.4,10,3,10z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default MediumIcon;
