import React from 'react';

export const BgShareIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.05" width="36" height="36" rx="18" fill="#B695F8" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.6412 11.5C21.614 11.5 20.7812 12.3328 20.7812 13.36C20.7812 13.6165 20.8332 13.8609 20.9271 14.0832C20.9507 14.1159 20.972 14.1509 20.9907 14.1882C21.0089 14.2247 21.024 14.2621 21.0358 14.2998C21.3588 14.8504 21.9568 15.22 22.6412 15.22C23.6685 15.22 24.5012 14.3872 24.5012 13.36C24.5012 12.3328 23.6685 11.5 22.6412 11.5ZM20.0488 15.4976C20.6651 16.2442 21.5976 16.72 22.6412 16.72C24.4969 16.72 26.0012 15.2157 26.0012 13.36C26.0012 11.5043 24.4969 10 22.6412 10C20.7856 10 19.2812 11.5043 19.2812 13.36C19.2812 13.6346 19.3142 13.9014 19.3763 14.1568L15.9558 15.8671C15.3396 15.1182 14.4056 14.6406 13.36 14.6406C11.5043 14.6406 10 16.1449 10 18.0006C10 19.8563 11.5043 21.3606 13.36 21.3606C14.4036 21.3606 15.3361 20.8849 15.9523 20.1384L19.375 21.8497C19.3137 22.1035 19.2812 22.3686 19.2812 22.6412C19.2812 24.4969 20.7856 26.0012 22.6412 26.0012C24.4969 26.0012 26.0012 24.4969 26.0012 22.6412C26.0012 20.7856 24.4969 19.2812 22.6412 19.2812C21.5956 19.2812 20.6616 19.7589 20.0453 20.5078L16.6249 18.7976C16.6871 18.5421 16.72 18.2752 16.72 18.0006C16.72 17.7279 16.6875 17.4628 16.6262 17.209L20.0488 15.4976ZM14.9583 17.0488C14.9709 17.0929 14.9878 17.1365 15.009 17.179C15.0302 17.2213 15.0548 17.2608 15.0824 17.2972C15.1711 17.5142 15.22 17.7517 15.22 18.0006C15.22 18.2547 15.1691 18.4968 15.0769 18.7174C15.0515 18.7517 15.0288 18.7887 15.009 18.8282C14.9897 18.8669 14.974 18.9065 14.9618 18.9466C14.638 19.4937 14.0418 19.8606 13.36 19.8606C12.3328 19.8606 11.5 19.0279 11.5 18.0006C11.5 16.9734 12.3328 16.1406 13.36 16.1406C14.0394 16.1406 14.6337 16.5049 14.9583 17.0488ZM20.9213 21.9318C20.9473 21.8969 20.9705 21.8593 20.9907 21.819C21.0109 21.7785 21.0272 21.737 21.0396 21.6951C21.3634 21.148 21.9595 20.7812 22.6412 20.7812C23.6685 20.7812 24.5012 21.614 24.5012 22.6412C24.5012 23.6685 23.6685 24.5012 22.6412 24.5012C21.614 24.5012 20.7812 23.6685 20.7812 22.6412C20.7812 22.39 20.8311 22.1505 20.9213 21.9318Z"
                    fill="#B695F8"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_179_8906"
                        x1="19.7664"
                        y1="0.447084"
                        x2="19.7394"
                        y2="36.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_179_8906"
                        x1="18.7857"
                        y1="10.1987"
                        x2="18.7738"
                        y2="26.0013"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
