import React from 'react';
import { LeadsPage } from 'components/appusers-dash/LeadsPage';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const Leads = () => {
    return (
        <SidebarLayout>
            <div className="app-dashboard">
                <div>
                    <RoleBasedGuard module={'Leads'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                        <LeadsPage />
                    </RoleBasedGuard>
                </div>
            </div>
        </SidebarLayout>
    );
};
