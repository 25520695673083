import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fileManagerInfo, uploadFileThunk } from 'store/fileManager';
import { BUSINESS_ID } from 'utills/globalVars';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';

export const useUploadFile = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: loggedInUser } = useSelector((state) => state.login);
    const [fileLoader, setFileLoader] = useState(false);
    const employee_id = loggedInUser?.user_id;

    const { data } = useSelector((state) => state.fileManagerInfo);

    const uploadFileHanlder = async (selectedFiles, currentFolder, business_client_id) => {
        const formData = new FormData();
        if (selectedFiles?.length > 0) {
            try {
                const sanitizedFilesPromises = selectedFiles.map((file) => createNewPDFWithoutMetadata(file));
                const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
                sanitizedFiles.forEach((sanitizedFile) => {
                    formData.append('taxation_files', sanitizedFile);
                });
                formData.append('uploaded_by', 'employee');
                formData.append('folder_name', currentFolder);
                formData.append('business_id', business_id);
                formData.append('employee_id', employee_id);
                formData.append('business_client_id', business_client_id);
                setFileLoader(true);
                const res = await dispatch(uploadFileThunk(formData))
                    .then((response) => {
                        if (response.payload) {
                            if (currentFolder === 'accountant') {
                                if (data) {
                                    dispatch(
                                        fileManagerInfo.actions.handleUpdate({
                                            ...data,
                                            count: { ...data.count, accountant: data?.count?.accountant + 1 }
                                        })
                                    );
                                }
                            }
                            return response.payload;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        setFileLoader(false);
                    });
                return res;
            } catch (error) {
                console.error('Error uploading file:', error);
                alert('Failed to upload file.');
            }
        }
    };
    return { uploadFileHanlder, fileLoader };
};
