import React from 'react';
import BackupCodesPage from '../../../components/dashboard-components/auth/BackupCodesPage';
const BackupCodes = () => {
    return (
        <div>
            <BackupCodesPage />
        </div>
    );
};

export default BackupCodes;
