import React from 'react';

const ListView = ({ className }) => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.78163 1.11875C1.48793 1.25625 1.22546 1.51875 1.13173 1.76875C1.08798 1.875 1.06299 2.5125 1.06299 3.3625C1.06299 4.6 1.08174 4.8125 1.18172 5.03125C1.32545 5.35 1.6754 5.6625 1.98161 5.74375C2.11284 5.78125 2.78149 5.8125 3.46889 5.8125C4.65622 5.8125 4.73121 5.80625 5.04366 5.6625C5.26863 5.55625 5.41861 5.425 5.54359 5.24375L5.71856 4.98125L5.73731 3.5875C5.76231 2.0375 5.72481 1.80625 5.41861 1.45625C5.04991 1.0375 4.89368 1 3.37515 1C2.20032 1 2.00035 1.01875 1.78163 1.11875ZM4.425 3.35625L4.40625 4.46875L3.36265 4.4875L2.31281 4.5L2.30031 3.65625C2.29406 3.19375 2.28781 2.70625 2.28781 2.575L2.28156 2.34375L3.15644 2.3125C3.63762 2.29375 4.12504 2.26875 4.23753 2.2625L4.4375 2.25L4.425 3.35625Z"
                strokeWidth="0.8"
            />
            <path
                d="M8.02361 3C7.81114 3.08125 7.68616 3.3125 7.68616 3.6125C7.68616 3.83125 7.72365 3.91875 7.86738 4.06875L8.0486 4.25H14.3102H20.5718L20.7843 4.0375C21.0155 3.80625 21.0592 3.575 20.9217 3.28125C20.7593 2.925 21.0342 2.9375 14.3289 2.94375C10.9357 2.94375 8.09235 2.96875 8.02361 3Z"
                strokeWidth="0.8"
            />
            <path
                d="M1.74954 8.83125C1.59331 8.9125 1.38709 9.1 1.28086 9.24375L1.09338 9.5125V11.0313V12.55L1.28086 12.825C1.38709 12.9688 1.58706 13.1563 1.71829 13.2313C1.95576 13.3688 2.03075 13.375 3.39305 13.375C4.99907 13.375 5.08031 13.3563 5.4615 12.9375C5.78645 12.5813 5.82395 12.3438 5.79895 10.8813C5.78021 9.68125 5.76771 9.575 5.63648 9.33125C5.56149 9.18125 5.38026 8.9875 5.23653 8.89375L4.98032 8.71875L3.50553 8.7C2.05574 8.68125 2.0245 8.68125 1.74954 8.83125ZM4.43665 10.9563V12.0438L3.83049 12.0813C3.49304 12.1063 3.01186 12.125 2.76814 12.125H2.31196V11.4125C2.31196 11.025 2.29321 10.5313 2.27446 10.325L2.23072 9.94375L2.7244 9.91875C2.99936 9.9125 3.49304 9.89375 3.83049 9.8875L4.43665 9.875V10.9563Z"
                strokeWidth="0.8"
            />
            <path
                d="M7.86738 10.7437C7.72365 10.8875 7.68616 10.9812 7.68616 11.1875C7.68616 11.5125 7.7674 11.6625 7.99861 11.7812C8.15484 11.8625 8.96722 11.875 14.3227 11.875C20.3531 11.875 20.4718 11.875 20.6718 11.75C21.0405 11.525 21.0967 10.9812 20.778 10.7L20.6155 10.5625H14.3352H8.0486L7.86738 10.7437Z"
                strokeWidth="0.8"
            />
            <path
                d="M1.84988 16.2813C1.54367 16.3875 1.26871 16.65 1.11873 16.9688C1.01875 17.1875 1 17.3875 1 18.5813C1 20.0625 1.03125 20.2313 1.36245 20.5875C1.7124 20.9625 1.88737 21 3.41215 21C4.73696 21 4.8057 20.9938 5.06816 20.8563C5.21814 20.775 5.43061 20.5875 5.53059 20.4438L5.71807 20.175L5.73681 18.8563C5.76806 17.2375 5.71807 16.975 5.35562 16.5938C5.19314 16.425 4.99942 16.3 4.84319 16.2563C4.46825 16.1563 2.15608 16.1688 1.84988 16.2813ZM4.437 18.5563V19.6688L3.42465 19.7C2.86223 19.7188 2.3873 19.7188 2.36855 19.6938C2.34356 19.675 2.31231 19.175 2.30606 18.5938L2.28106 17.5313L3.34341 17.5C3.93083 17.4813 4.41201 17.4563 4.4245 17.45C4.43075 17.4438 4.437 17.9375 4.437 18.5563Z"
                strokeWidth="0.8"
            />
            <path
                d="M7.98611 18.225C7.76115 18.3438 7.68616 18.4938 7.68616 18.8125C7.68616 19.0188 7.72365 19.1125 7.86738 19.2563L8.0486 19.4375H14.3227C20.1656 19.4375 20.5968 19.425 20.7218 19.325C21.0467 19.0625 21.0842 18.6625 20.8155 18.3625L20.6343 18.1563L14.3914 18.1438C8.97347 18.1313 8.12984 18.1438 7.98611 18.225Z"
                strokeWidth="0.8"
            />
        </svg>
    );
};

export default ListView;
