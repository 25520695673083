import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchUserDataThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import 'driver.js/dist/driver.css';

import { TwoFaAuthentication } from './TwoFaAuthentication';
const Dashboard = () => {
    const dispatch = useDispatch();

    const { data } = useSelector((state) => state.login);
    const [_complete_two_fa, setComplete_two_fa] = useState(false);

    useEffect(() => {
        const dataConfig = { user_id: data?.user_id, user_type: 'employee' };
        dispatch(setLoader(true));

        dispatch(fetchUserDataThunk(dataConfig))
            .then((response) => {
                if (response.payload) {
                    if (response.payload.email_2fa && response.payload.google_authenticator_2fa) {
                        setComplete_two_fa(true);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    return (
        <div>
            <TwoFaAuthentication />
        </div>
    );
};

export default Dashboard;
