import React from 'react';
import EmailTemplatesPage from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/email-templates/EmailTemplatesPage';
const EmailTemplates = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <EmailTemplatesPage />
            </div>
        </div>
    );
};

export default EmailTemplates;
