import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { AppsConnectionSvg } from 'utills/svgs/AppsConnectionSvg';
import { ClientsIcon } from 'utills/svgs/ClientsIcon';
import { FormsIcon } from 'utills/svgs/FormsIcon';
import { JobManagerIcon2 } from 'utills/svgs/JobManagerIcon2';
import { RolesIcon } from 'utills/svgs/RolesIcon';
import { ShieldIcon } from 'utills/svgs/ShieldIcon';
import { TeamIcon } from 'utills/svgs/TeamIcon';
import { WorkspaceIconSvg } from 'utills/svgs/WorkspaceIconSvg';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';
import { TaskListIcon } from 'utills/svgs/TaskListIcon';
import { ArrowRight } from 'utills/svgs/ArrowRight';
import DynamicStatusIcon from 'utills/svgs/DynamicStatusIcon';
import { LargeTaskIcon } from 'utills/svgs/LargeTaskIcon';
import PrebuiltChecklistIcon from 'utills/svgs/PrebuiltChecklistIcon';
import TaskTemplateIcons from 'utills/svgs/TaskTemplateIcons';
import { EmailNotificationIcon } from 'utills/svgs/EmailNotificationIcon';
import { OboardingServicesIcon } from 'utills/svgs/OboardingServicesIcon';
import { SignatureIcon } from 'utills/svgs/SignatureIcon';
import { LockChangeIcon } from 'utills/LockChangeIcon';
import { MultiFactorIcon } from 'utills/svgs/MultiFactorIcon';
import AppInviteIcon from 'utills/svgs/AppInviteIcon';
import { AddClientsIcon } from 'utills/svgs/AddClientsIcon';
import { UsersIcon } from 'utills/svgs/UsersIcon';
import { TelephonicDirectorySvg } from 'utills/svgs/TelephonicDirectorySvg';
import { JobTypeSettingsIcon } from 'utills/svgs/JobTypeSettingsIcon';
import { JobTemplatesIcon } from 'utills/svgs/JobTemplatesIcon';
import DocumentListIcon from 'utills/svgs/DocumentListIcon';
import RelayRoomSettingIcon from 'utills/svgs/RelayRoomSettingIcon';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
// import { EmailManagerAccountIcon } from 'utills/svgs/EmailManagerAccountIcon';
// import CategoriesIcon from 'utills/svgs/CategoriesIcon';
// import MailIcon2 from 'utills/svgs/MailIcon2';

const SettingsSide = ({ _hideSettings }) => {
    return (
        <motion.div
            initial={{ x: '-80', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
            // onMouseLeave={hideSettings}
            className="clients top-[10px]  h-sm:top-[120px] shadow py-2 max-h-[400px] h-sm:max-h-[700px] overflow-y-hidden hover:overflow-y-auto"
        >
            <div className=" mx-1">
                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/app-connections" className="flex gap-2 items-center w-full">
                            <div>
                                <AppsConnectionSvg
                                    className={'w-5 h-5 fill-black !stroke-[1px] !stroke-black '}
                                    // extraProps={{ paintOrder: 'stroke' }}
                                />
                            </div>
                            <div>App Connections</div>
                        </Link>
                    </div>
                </TooltipChipWrap>

                <TooltipChipWrap
                    title={
                        <div className="group-open:animate-fadeIn w-full">
                            <Link
                                to="/invites"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <AppInviteIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Application Invite</div>
                            </Link>
                            <Link
                                to="/offline/clients/import-export"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <AddClientsIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Offline clients import export</div>
                            </Link>
                            <Link
                                to="/settings/groups/clients"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <UsersIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Client Groups</div>
                            </Link>
                        </div>
                    }
                    sx={'!bg-white text-black shadow-lg top-[80px] md:top-0   md:left-full  !px-2 !m-0 min-w-[270px]'}
                    hideChip
                    placement="right"
                >
                    <Link
                        to="/settings/clients"
                        className="flex gap-2 items-center px-3 mx-2 justify-between settings-card-item  rounded-lg py-2"
                    >
                        <div className="flex gap-2 items-center">
                            <div>
                                <ClientsIcon className={'w-5 h-5'} />
                            </div>
                            <div>Clients</div>
                        </div>

                        <ArrowRight />
                    </Link>
                </TooltipChipWrap>

                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2   settings-card-item  ">
                        <Link to="/settings?form=true" className="flex gap-2 items-center w-full">
                            <div>
                                <FormsIcon
                                    // extraProps={{ paintOrder: 'stroke' }}
                                    className="!stroke-[1px]  fill-black h-[22px]  !stroke-black w-[22px] "
                                />
                            </div>
                            <div>Customised Form</div>
                        </Link>
                    </div>
                </TooltipChipWrap>

                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/settings/email-notifications" className="flex gap-2 items-center w-full">
                            <div>
                                <EmailNotificationIcon className={'w-5 h-5 fill-black stroke-black'} />
                            </div>
                            <div>Email and Notification</div>
                        </Link>
                    </div>
                </TooltipChipWrap>

                {/* <TooltipChipWrap
                    title={
                        <div className="group-open:animate-fadeIn w-full">
                            <Link
                                to="/settings/email-manager-account-settings/categories"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <CategoriesIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Categories</div>
                            </Link>
                            <Link
                                to="/settings/email-manager-account-settings/email-account"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <MailIcon2 className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Email Account</div>
                            </Link>
                        </div>
                    }
                    sx={'!bg-white text-black shadow-lg top-[80px] md:top-0   md:left-full  !px-2 !m-0 min-w-[270px]'}
                    hideChip
                    placement="right"
                >
                    <Link
                        to="/settings/email-manager-account-settings"
                        className="flex gap-2 items-center px-3 mx-2 justify-between settings-card-item  rounded-lg py-2"
                    >
                        <div className="flex gap-2 items-center ">
                            <div>
                                <EmailManagerAccountIcon
                                    className={'w-5 h-5 fill-black stroke-black'}
                                    extraProps={{ paintOrder: 'stroke' }}
                                />
                            </div>
                            <div>Email Manager Account Setting</div>
                        </div>

                        <ArrowRight />
                    </Link>
                </TooltipChipWrap> */}

                <TooltipChipWrap
                    title={
                        <div className="group-open:animate-fadeIn w-full">
                            <Link
                                to="/job-manager/settings/job-type"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <JobTypeSettingsIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Job Type</div>
                            </Link>
                            <Link
                                to="/job-manager/settings/job-status"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <DynamicStatusIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Job Status</div>
                            </Link>
                            <Link
                                to="/job-manager/settings/job-templates"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <JobTemplatesIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Job Templates</div>
                            </Link>
                            <Link
                                to="/settings/job-manager/documents"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <DocumentListIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Documents List</div>
                            </Link>
                            <Link
                                to="/settings/job-manager/relayroom-settings"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <RelayRoomSettingIcon
                                        className={'w-[20px] h-[20px]  !rounded-md !stroke-1 '}
                                        border="1px solid black"
                                    />
                                </div>
                                <div>Relay Room Settings</div>
                            </Link>
                            <Link
                                to="/settings/groups/jobs"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <RectangleStackIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Job Groups</div>
                            </Link>
                        </div>
                    }
                    sx={'!bg-white text-black shadow-lg top-[80px] md:top-0   md:left-full  !px-2 !m-0 min-w-[270px]'}
                    hideChip
                    placement="right"
                >
                    <Link
                        to="/settings/job-manager"
                        className="flex gap-2 items-center px-3 mx-2 justify-between settings-card-item  rounded-lg py-2"
                    >
                        <div className="flex gap-2 items-center">
                            <div>
                                <JobManagerIcon2
                                    className={'w-5 h-5 fill-black stroke-black'}
                                    extraProps={{ paintOrder: 'stroke' }}
                                />
                            </div>
                            <div>Job Manager</div>
                        </div>

                        <ArrowRight />
                    </Link>
                </TooltipChipWrap>

                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/settings/onboarding-services" className="flex gap-2 items-center w-full">
                            <div>
                                <OboardingServicesIcon
                                    className={'w-5 h-5 fill-black stroke-black'}
                                    extraProps={{ paintOrder: 'stroke' }}
                                />
                            </div>
                            <div>Onboarding Services</div>
                        </Link>
                    </div>
                </TooltipChipWrap>

                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/team/rolemanagement" className="flex gap-2 items-center w-full">
                            <div>
                                <RolesIcon className={'stroke-black fill-black'} />
                            </div>
                            <div>Role Management</div>
                        </Link>
                    </div>
                </TooltipChipWrap>
                <TooltipChipWrap
                    title={
                        <div className="group-open:animate-fadeIn w-full">
                            <Link
                                to="/change-password"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <LockChangeIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Change Password</div>
                            </Link>
                            <Link
                                to="/multi-factor-auth"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <MultiFactorIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Multi factor Authentication</div>
                            </Link>
                        </div>
                    }
                    sx={'!bg-white text-black shadow-lg top-[80px] md:top-0   md:left-full  !px-2 !m-0 min-w-[270px]'}
                    hideChip
                    placement="right"
                >
                    <Link
                        to="/security-and-authentication"
                        className="flex gap-2 items-center px-3 mx-2 justify-between settings-card-item  rounded-lg py-2"
                    >
                        <div className="flex gap-2 items-center">
                            <div>
                                <ShieldIcon className={'stroke-black fill-black'} />
                            </div>
                            <div>Security</div>
                        </div>

                        <ArrowRight />
                    </Link>
                </TooltipChipWrap>

                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/team" className="flex gap-2 items-center w-full">
                            <div>
                                <TeamIcon className={'stroke-black fill-black'} />
                            </div>
                            <div>Teams</div>
                        </Link>
                    </div>
                </TooltipChipWrap>

                <TooltipChipWrap
                    title={
                        <div className="group-open:animate-fadeIn w-full">
                            <Link
                                to="/organizational/settings"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <DynamicStatusIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Dynamic Status</div>
                            </Link>
                            <Link
                                to="/task/type"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <LargeTaskIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Task Types</div>
                            </Link>
                            <Link
                                to="/settings/task-module/prebuilt-checklist"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <PrebuiltChecklistIcon className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Prebuilt Checklist</div>
                            </Link>
                            <Link
                                to="/settings/task-module/task-templates"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <TaskTemplateIcons className={'w-[20px] h-[20px] '} />
                                </div>
                                <div>Task Templates</div>
                            </Link>
                        </div>
                    }
                    sx={'!bg-white text-black shadow-lg top-[80px] md:top-0   md:left-full  !px-2 !m-0 min-w-[270px]'}
                    hideChip
                    placement="right"
                >
                    <Link
                        to="/settings/task-module"
                        className="flex gap-2 items-center px-3 mx-2 justify-between settings-card-item  rounded-lg py-2"
                    >
                        <div className="flex gap-2 items-center">
                            <div>
                                <TaskListIcon className={'fill-black'} />
                            </div>
                            <div>Task Module</div>
                        </div>

                        <ArrowRight />
                    </Link>
                </TooltipChipWrap>
                {/* <Accordian
                        icon={<ArrowRight />}
                        titleIconSx="justify-between px-2 py-2 settings-card-item !rounded-md "
                        mainSx="px-3 "
                        rightIcon={<TaskListIcon />}
                        title={
                            <div className=" items-center">
                                <div className="!text-[16px] font-[400]">Task Module</div>
                            </div>
                        }
                    >
            
                    </Accordian> */}

                {/* <TooltipChipWrap
                        title="Click to view"
                        sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                        hideChip
                        placement="right"
                    >
                        <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                            <Link to="/settings/task-module" className="flex gap-2 items-center w-full">
                                <div>
                                    <TaskListIcon className={'fill-gray-600'} />
                                </div>
                                <div>Task Module</div>
                            </Link>
                        </div>
                    </TooltipChipWrap> */}

                {/* 
                    <TooltipChipWrap
                        title="Click to view"
                        sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                        hideChip
                        placement="right"
                    >
                        <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                            <Link to="/security-and-authentication" className="flex gap-2 items-center w-full">
                                <div>
                                    <ShieldIcon className={'stroke-black fill-black'} />
                                </div>
                                <div>Security</div>
                            </Link>
                        </div>
                    </TooltipChipWrap> */}

                {/* <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/settings/clients" className="flex gap-2 items-center w-full">
                            <div>
                                <ClientsIcon className={'w-5 h-5'} />
                            </div>
                            <div>Clients</div>
                        </Link>
                    </div>
                </TooltipChipWrap> */}
                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/settings/user-email-signature" className="flex gap-2 items-center w-full">
                            <div>
                                <SignatureIcon className={'w-5 h-5 fill-black !stroke-[2px] !stroke-black'} />
                            </div>
                            <div>User Email Signature</div>
                        </Link>
                    </div>
                </TooltipChipWrap>
                <TooltipChipWrap
                    title={
                        <div className="group-open:animate-fadeIn w-full">
                            <Link
                                to="/telephonic-directory-settings"
                                className="flex gap-2 items-center px-3 text-[16px]  w-full settings-card-item  rounded-lg py-2"
                            >
                                <div>
                                    <TelephonicDirectorySvg
                                        className={'w-[20px] h-[20px] !stroke-black !stroke-[1px]'}
                                    />
                                </div>
                                <div>Telephonic Directory</div>
                            </Link>
                        </div>
                    }
                    sx={
                        '!bg-white text-black shadow-lg top-[50px] md:top-[50px]   md:left-full  !px-2 !m-0 min-w-[270px]'
                    }
                    hideChip
                    placement="right"
                >
                    <Link
                        to="/workspace-settings"
                        className="flex gap-2 items-center px-3 mx-2 justify-between settings-card-item  rounded-lg py-2"
                    >
                        <div className="flex gap-2 items-center">
                            <div>
                                <WorkspaceIconSvg
                                    className={'!w-5 !h-5 fill-black stroke-black'}
                                    extraProps={{ paintOrder: 'stroke' }}
                                />
                            </div>
                            <div>Workspace</div>
                        </div>

                        <ArrowRight />
                    </Link>
                </TooltipChipWrap>

                {/* <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/workspace-settings" className="flex gap-2 items-center w-full">
                            <div>
                                <WorkspaceIconSvg
                                    className={'w-5 h-5 fill-black stroke-black'}
                                    extraProps={{ paintOrder: 'stroke' }}
                                />
                            </div>
                            <div>Workspace</div>
                        </Link>
                    </div>
                </TooltipChipWrap> */}

                {/* 
                <TooltipChipWrap
                    title="Click to view"
                    sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                    hideChip
                    placement="right"
                >
                    <div className="flex cursor-pointer rounded-md gap-3 mx-3 items-center p-2 py-2 mt-1  settings-card-item  ">
                        <Link to="/settings/job-manager" className="flex gap-2 items-center w-full">
                            <div>
                                <JobManagerIcon2
                                    className={'w-5 h-5 fill-black stroke-black'}
                                    extraProps={{ paintOrder: 'stroke' }}
                                />
                            </div>
                            <div>Job Manager</div>
                        </Link>
                    </div>
                </TooltipChipWrap> */}
            </div>
        </motion.div>
    );
};
export default SettingsSide;
