import React from 'react';
import { TailArrowRight } from 'utills/svgs/TailArrowRight';
import './css/molecules.css';
import { Link } from 'react-router-dom';
import { Button } from 'components/atoms/buttons/Button';
import { cn } from 'utills/tailwindUtil';

export const AppCards = ({ title, text, atClick, iconSet, link, state, isConnected }) => {
    const isLinkAvailable = !!link;

    const content = (
        <div
            onClick={!isLinkAvailable ? atClick : undefined}
            className="p-5 bg-white flex flex-col gap-4 cursor-pointer !rounded-xl h-full min-w-[220px] lg:min-w-[280px] overflow-auto 
                    border border-solid hover:shadow-md hover:border-third"
        >
            <div className="flex justify-between items-start">
                <div className="flex items-center gap-3">
                    <img src={iconSet.icon} className="w-12 md:w-14 lg:w-16 xl:w-18 rounded-full" alt={title} />
                    <label className="text-lg lg:text-2xl  text-gray-800 font-semibold">{title}</label>
                </div>
                <TailArrowRight />
            </div>
            <div className="flex flex-col items-start h-full  justify-between gap-5">
                <div className="mt-2 add-ann-form">
                    <label className="text-md lg:text-md text-gray-800">{text}</label>
                </div>
                <Button
                    title={isConnected ? 'Connected' : 'Connect'}
                    style={{
                        '& *': {
                            placeContent: 'center'
                        }
                    }}
                    classes={cn(
                        'font-semibold w-full py-2.5  text-center place-content-center ',
                        isConnected ? 'bg-gray-200 text-gray-900' : 'bg-linear text-white'
                    )}
                    groupSx={'w-full'}
                />
            </div>
        </div>
    );

    return isLinkAvailable ? (
        <Link to={link} state={state || {}}>
            {content}
        </Link>
    ) : (
        content
    );
};
