import React from 'react';
import { useEffect, useState } from 'react';
import { SigningFieldContainer } from './signing-field-container';
import { useRequiredSigningContext } from './provider';
import dayjs from 'dayjs';

export const DateField = ({ field, _recipient }) => {
    const { date, setDate } = useRequiredSigningContext();

    const [fieldData, setfieldData] = useState(field);

    useEffect(() => {
        const tempData = {
            ...fieldData,
            field_value: date ? date && dayjs(date, 'DD/MM/YYYY').format('DD/MM/YYYY') : dayjs(),
            inserted: true
        };
        setDate(date);
        setfieldData(tempData);
    }, []);

    const onSign = async () => {
        const tempData = {
            ...fieldData,

            field_value: date ? date && dayjs(date, 'DD/MM/YYYY').format('DD/MM/YYYY') : dayjs(),
            inserted: true
        };
        setDate(date);
        setfieldData(tempData);
    };

    const onRemove = async () => {
        const tempData = {
            ...fieldData,
            field_value: '',
            inserted: false
        };
        setfieldData(tempData);
    };

    return (
        <SigningFieldContainer field={fieldData} onSign={onSign} onRemove={onRemove}>
            {!fieldData.inserted && (
                <p className="group-hover:text-primary text-muted-foreground text-lg duration-200">Date</p>
            )}

            {fieldData.inserted && (
                <p className="text-muted-foreground text-sm duration-200">{fieldData.field_value}</p>
            )}
        </SigningFieldContainer>
    );
};
