import Input from 'components/atoms/Input';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import React from 'react';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { PlusIcon } from 'utills/svgs/PlusIcon';
import { generateId } from 'utills/uid';
import cloneDeep from 'lodash/cloneDeep';
export const MultiTextInputAddComponent = ({ title, placeholder, fields = [], setFields, addSingle = false }) => {
    const handleAddInput = (e, index) => {
        const newValues = cloneDeep(fields);
        newValues[index].text = e.target.value;
        setFields(newValues);
    };

    const handleAddField = (_index) => {
        const newValues = cloneDeep(fields);
        const isEmpty = newValues.some((item) => item.text === '');
        if (isEmpty) {
            return toastHandler('Input cant be empty', TOAST_TYPE_ERROR);
        }

        const newInput = {
            id: generateId(),
            text: ''
        };

        newValues.push(newInput);
        setFields(newValues);
    };

    const handleDelete = (index) => {
        const newValues = cloneDeep(fields);
        newValues.splice(index, 1);
        setFields(newValues);
    };

    return (
        <div>
            <div className="add-ann-form mb-5">
                {fields.map((input, index) => (
                    <div className="" key={index}>
                        {title && (
                            <div>
                                <PrimaryLabel>{title}</PrimaryLabel>
                            </div>
                        )}

                        <div className="grid grid-cols-12 mt-1">
                            <div className="col-span-10">
                                <Input
                                    onChange={(e) => handleAddInput(e, index)}
                                    type="text"
                                    placeholder={placeholder ?? ''}
                                    required
                                    value={input?.text}
                                    maxLength={50}
                                    useWithoutForm={true}
                                />
                            </div>

                            {!addSingle && (
                                <div className="col-span-2 flex justify-center items-center">
                                    {fields?.length === 1 ? (
                                        // Only one field: Show Plus Icon only
                                        <div onClick={() => handleAddField(index)} className="cursor-pointer z-10">
                                            <PlusIcon className="fill-gray-800" />
                                        </div>
                                    ) : fields?.length > 1 && index === fields?.length - 1 ? (
                                        // Last field: Show both Plus and Delete icons
                                        <div className="flex gap-2 items-center">
                                            <div onClick={() => handleDelete(index)} className="cursor-pointer">
                                                <BgDeleteIcon className="!w-[30px] !h-[30px]" />
                                            </div>
                                            <div onClick={() => handleAddField(index)} className="cursor-pointer z-10">
                                                <PlusIcon className="fill-gray-800" />
                                            </div>
                                        </div>
                                    ) : (
                                        // Middle fields: Show Delete icon only
                                        <div onClick={() => handleDelete(index)} className="cursor-pointer">
                                            <BgDeleteIcon className="!w-[30px] !h-[30px]" />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
