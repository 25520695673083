import React, { useState } from 'react';
import NotesPage from '../../../components/workplace-content/notes-pages/NotesPage';

import { AddNewNote } from 'components/business-dashboard-pages/AddNewNote';
import { EditNote } from 'components/workplace-content/notes-pages/EditNote';
import { SidebarLayout } from 'layouts/SidebarLayout';
const Notes = () => {
    const [showAddNote, setShowAddNote] = useState(false);
    const [notesUpdated, setNotesUpdated] = useState(false);
    const [editNote, setEditNote] = useState(false);

    const toggleEditNote = () => {
        setEditNote(!editNote);
    };
    const toggleAddNote = () => {
        setShowAddNote(!showAddNote);
    };

    const toggleNotesUpdate = () => {
        setNotesUpdated(!notesUpdated);
    };

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showAddNote && (
                    <AddNewNote
                        toggleNotesUpdate={toggleNotesUpdate}
                        toggleAddNote={toggleAddNote}
                        open={showAddNote}
                        setOpen={setShowAddNote}
                    />
                )}
                {editNote && <EditNote open={editNote} setOpen={setEditNote} toggleNotesUpdate={toggleNotesUpdate} />}
                <div>
                    <NotesPage
                        toggleAddNote={toggleAddNote}
                        notesUpdated={notesUpdated}
                        toggleNotesUpdate={toggleNotesUpdate}
                        toggleEditNote={toggleEditNote}
                    />
                </div>
            </SidebarLayout>
        </div>
    );
};
export default Notes;
