import React from 'react';
import { Switch } from 'antd';

const DefaultSwitch = ({ checked, onChange, disabled, ...rest }) => {
    // Define a default onChange handler if not provided
    const handleSwitchChange = (checked) => {
        if (onChange) {
            onChange(checked);
        }
    };

    return (
        <Switch
            checked={checked}
            onChange={handleSwitchChange}
            disabled={disabled}
            {...rest} // Spread any additional props passed to DefaultSwitch
        />
    );
};

export default DefaultSwitch;
