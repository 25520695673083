import React from 'react';
import { NoDataFound } from 'components/common/NoDataFound';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteNoteThunk } from 'store/workspace/workspaceNotes';
import ConfirmationModal from 'utills/confirmationModal';
import { BUSINESS_ID } from 'utills/globalVars';
import { formateDate } from 'utills/moment';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import pdfIcon from '../../../files/pdfIcon.svg';
import { generateId } from 'utills/uid';
import Badge from 'components/atoms/Badge';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { updateNoteHandler } from 'store/global/globalReducer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import Pagination from 'components/pagination';
import { Truncate } from 'components/molecules/Truncate';

const NotesList = ({ toggleNotesUpdate, filteredNotes, toggleEditNote, handleChangePage, count, pageSize, page }) => {
    const dispatch = useDispatch();

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [deleteId, setDeleteId] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const [showImage, setShowImage] = useState('');

    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const deleteNote = (business_note_id) => {
        setDeleteId(business_note_id);
        setIsConfirmationOpen(true);
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        const payload = {
            business_id,
            business_note_id: deleteId
        };
        dispatch(deleteNoteThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleNotesUpdate();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleEdit = (note) => {
        dispatch(updateNoteHandler(note));
        toggleEditNote();
    };
    return (
        <div className="flex flex-col md:px-10 px-5 my-5">
            {open && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpen} open={open} /> : ''}

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <div className=" ">
                {filteredNotes && filteredNotes?.rows?.length > 0 ? (
                    filteredNotes?.rows?.map((note) => (
                        <div
                            key={generateId()}
                            className="bg-white rounded-xl shadow-sm hover:shadow-md border hover:border-third p-5 relative mb-5"
                        >
                            <div className=" absolute flex gap-2 right-5">
                                <div className="cursor-pointer" onClick={() => handleEdit(note)}>
                                    <BgEditIcon />
                                </div>
                                <div className="cursor-pointer" onClick={() => deleteNote(note?.business_note_id)}>
                                    <BgDeleteIcon />
                                </div>
                            </div>
                            <div className="lg-title break-all max-w-[90%] text-md">{note?.title}</div>
                            <div className="buttons-font-lighter text-md mt-2">
                                <Truncate text={note?.description} maxLength={250} />
                            </div>

                            {showImage && <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />}

                            {note?.attachment?.length > 0 && (
                                <div className="ann-btns  mt-2 flex gap-2 flex-wrap">
                                    {note?.attachment?.map((att, index) => (
                                        <div key={'note_attachment' + generateId() + index}>
                                            <div>
                                                <Badge
                                                    leftIcon={
                                                        <img
                                                            src={
                                                                att.file_type === 'image/jpeg' ||
                                                                att.file_type === 'image/png' ||
                                                                att.file_type === 'image/heic' ||
                                                                att.file_type === 'image/heif'
                                                                    ? att.url
                                                                    : pdfIcon
                                                            }
                                                            alt="alt"
                                                            className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                            onClick={() => {
                                                                if (
                                                                    att.file_type === 'image/jpeg' ||
                                                                    att.file_type === 'image/png' ||
                                                                    att.file_type === 'image/heic' ||
                                                                    att.file_type === 'image/heif'
                                                                ) {
                                                                    setShowImage(att.url);
                                                                } else {
                                                                    if (att?.url?.toLowerCase()?.endsWith('.pdf')) {
                                                                        setPdfUrl(att?.url);
                                                                        setOpen(true);
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    text={att.name}
                                                    sx="text-white bg-third rounded-md !px-3 !text-md truncate"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="flex justify-end items-center mt-1">
                                <div className="text-secondarybg small-text font-poppins">
                                    {formateDate(note?.created_at)}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <NoDataFound height="30vh" message="No record found!" />
                )}

                <div className="md:mx-10 mx-5">
                    <Pagination
                        page={Number(page)}
                        pageSize={Number(pageSize)}
                        totalPages={Math.ceil(count / pageSize)}
                        onChangePage={(page) => handleChangePage(page, Number(pageSize))}
                        onCountPerPageChange={(perPageCount) => handleChangePage(Number(page), perPageCount)}
                    />
                </div>
            </div>
        </div>
    );
};
export default NotesList;
