/* eslint-disable valid-typeof */
import Input from 'components/atoms/Input';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { DragFile } from 'components/atoms/inputs/DragFile';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { addNewsThunk } from 'store/workspace/workspaceNews';
import {
    BUSINESS_ID,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    USER_TYPE,
    acceptFilesFormats,
    acceptFormate
} from 'utills/globalVars';
import { UploadPhotoIcon } from 'utills/svgs/UploadPhotoIcon';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

export const AddnewAnnouncement = ({ open, setOpen, toggleAnnUpdate }) => {
    const dispatch = useDispatch();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedPdfs, setSelectedPdfs] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [forceUpdate, setForceUpdate] = useState(false);
    const [type, setType] = useState('');
    const business_id = localStorage.getItem(BUSINESS_ID);

    const handleSelectedFiles = (files) => {
        let newFiles = Array.isArray(files) ? [...files] : [files];
        setSelectedFiles([...selectedFiles, ...newFiles]);
    };

    const handleSelectedPdf = (files) => {
        let newFiles = Array.isArray(files) ? [...files] : [files];
        setSelectedPdfs([...selectedPdfs, ...newFiles]);
    };

    const handleSelect = (obj) => {
        setType(obj.value);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        if (!title || !description || !type) {
            toastHandler('Please fill in all required fields', TOAST_TYPE_ERROR);
            return;
        }

        formData.append('business_id', business_id.trim());
        formData.append('title', title.trim());
        formData.append('description', description.trim());
        formData.append('type', type.trim());
        formData.append('uploaded_by', USER_TYPE);

        for (let i = 0; i < selectedFiles?.length; i++) {
            const file = selectedFiles[i];
            formData.append('news_images', file);
        }

        const sanitizedFilesPromises = selectedPdfs.map((file) => createNewPDFWithoutMetadata(file));
        const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
        sanitizedFiles.forEach((sanitizedFile) => {
            formData.append('news_docs', sanitizedFile);
        });

        dispatch(setLoader(true));

        dispatch(addNewsThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setSelectedFiles([]);
                    setSelectedPdfs([]);
                    setTitle('');
                    setDescription('');
                    setForceUpdate(true);
                    setType('');
                    toastHandler('Added Successfully', TOAST_TYPE_SUCCESS);
                    setOpen(false);

                    if (toggleAnnUpdate) {
                        toggleAnnUpdate();
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <Slideover title="Add Announcement" open={open} setOpen={setOpen} showLoader>
            <RoleBasedGuard module="Announcements" permissions={['admin']} sx="p-5">
                <div className="mt-5 mb-5">
                    <Input
                        useWithoutForm={true}
                        title="Title"
                        placeholder="Enter announcement title"
                        name="title"
                        titleSx="font-semibold"
                        sx="!rounded-xl !py-3"
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength={100}
                        value={title}
                    />

                    <div className="mt-3">
                        <div>
                            <div className="buttons-font font-poppins">Description</div>
                            <div>
                                <div className="mt-2 resize-y">
                                    <DraftEditorNew
                                        onChange={(val) => setDescription(val)}
                                        initialValue={description || ''}
                                        forceUpdate={forceUpdate}
                                        setForceUpdate={setForceUpdate}
                                        required={true}
                                        extraConfigs={{
                                            height: 300
                                        }}
                                        placeholder="Enter announcement description"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="mb-2 table-title">Upload Image</div>
                        <DragFile
                            atChange={(file) => {
                                handleSelectedFiles(file);
                            }}
                            iconSet={{ icon: UploadPhotoIcon }}
                            accept={acceptFormate.filter((type) => type !== 'application/pdf')}
                            selectedFiles={selectedFiles}
                            isMultiple={true}
                            hideSelectedFiles
                            dragMessage="Drag files here to upload image, or browse files"
                            dropMessage="Drop the selected files in this box... "
                            onlyPdf={false}
                            message="Only images are allowed"
                        />
                        <SelectedFiles
                            files={selectedFiles}
                            updateSelectedFiles={(files) => setSelectedFiles(files || [])}
                        />
                    </div>
                    <div className="mt-3">
                        <div className="mb-2 table-title">Upload Document</div>
                        <DragFile
                            atChange={(file) => {
                                handleSelectedPdf(file);
                            }}
                            accept={acceptFilesFormats}
                            selectedFiles={selectedPdfs}
                            isMultiple={true}
                            hideSelectedFiles
                            dragMessage="Drag files here to upload, or browse files"
                            dropMessage="Drop the selected files in this box... "
                            onlyPdf={false}
                            message="This file type is not allowed"
                        />
                        <SelectedFiles
                            files={selectedPdfs}
                            updateSelectedFiles={(files) => setSelectedPdfs(files || [])}
                        />
                    </div>

                    <div className="mt-3">
                        <div className="table-title">Is it news or announcement</div>
                        <div className="mt-2">
                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="value"
                                optionsData={[{ value: 'announcement' }, { value: 'news' }] || []}
                                sx="rounded-xl py-3 ring-gray-400 "
                                onChangeValue={(obj) => handleSelect(obj)}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    {' '}
                    <Button title="Add" atClick={() => handleSubmit()} classes="ann-btn px-20 rounded-lg" />
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
