import React from 'react';

import '../css/appuserProfile.css';
import { useState, useRef, useEffect } from 'react';
import { Loader } from '../../../common/Loader';
import { FormsBgIcon } from '../../../../utills/svgs/FormsBgIcon';
import { DeductionsBgIcon } from '../../../../utills/svgs/DeductionsBgIcon';
import { ManagerBgIcon } from '../../../../utills/svgs/ManagerBgIcon';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { customerActivityThunk } from 'store/form';
import { ALL_ClIENT_TYPES, BUSINESS_ID, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { DefaultRecentActivity } from 'components/molecules/DefaultRecentActivity';
import { Button } from 'components/atoms/buttons/Button';
import { useDownloadRecentAcitivty } from '../../../../Hooks/Files/useDownloadRecentAcitivty';
import { handleTaskDetails, setSideLoader } from 'store/global/globalReducer';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import CreateTask from 'global-components/CreateTask';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { formateDate, formateDateAndTime, formateDateOnly, getDueStatusWithTime } from 'utills/moment';
import { OverDueIcon } from 'utills/svgs/OverDueIcon';
import { getAllTaskStatusesThunk } from 'store/workspace/workspaceTasks';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import moment from 'moment';
import AvatarGroup from 'components/atoms/AvatarGroup';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { ManualClientProfile } from '../manual-client/ManualClientProfile';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import ClientProfileJobs from './ClientProfileJobs';
import { CreateJob } from 'components/job-manager-components/create-job/CreateJob';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

const colors = ['#71b4f7', '#9fb968', ' #b298ff', '#71b4f7', '#f7a561'];

const ProfileCards = ({
    user,
    toggleForms,
    toggleRentalDeduction,
    toggleAbnDeduction,
    toggleTfnDeduction,
    tasks,
    filters,
    setFilters,
    handlePageChange,
    hasMoreContent,
    handleResetTable,
    toggleTaskDetails
}) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const activityRef = useRef(null);
    const [deductionToggler, setDeductionToggler] = useState(false);
    const { data: taskStatuses } = useSelector((state) => state.getAllTaskStatuses);
    const [addJob, setAddJob] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const { hasPermissions } = useRoleGuard();

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setfilteredLogs] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [filterCount, setFilterCount] = useState(1);
    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [createTask, setCreateTask] = useState(false);
    const [jobsUpdated, setJobsUpdated] = useState(false);

    const [activeTab, setActiveTab] = useState([
        { title: 'Jobs', selected: true },
        { title: 'Tasks', selected: false }
    ]);

    const [activeView, setActiveView] = useState('Jobs');

    const scrollToBottom = () => {
        activityRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    };

    const toggleDeductions = () => {
        setDeductionToggler(!deductionToggler);
        scrollToBottom();
    };

    const fetchCustomerLogs = () => {
        const payload = {
            business_client_id: id,
            page: term || dateFrom || dateTo ? filterPage : page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            business_id,
            filter: {
                text: term,
                from_date: dateFrom && moment(new Date(dateFrom)).format('DD-MM-YYYY'),
                end_date: dateTo && moment(new Date(dateTo)).format('DD-MM-YYYY')
            }
        };
        dispatch(setSideLoader(true));
        dispatch(customerActivityThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (dateFrom || dateTo || term) {
                        setFilterCount(response?.payload?.count);
                        const newLogs = [...filteredLogs, ...response.payload.logs];
                        setfilteredLogs(newLogs);
                    } else {
                        setCount(response?.payload?.count);
                        if (page > 1) {
                            const newLogs = [...logs, ...response.payload.logs];
                            setLogs(newLogs);
                        } else {
                            const newLogs = response.payload.logs;
                            setLogs(newLogs);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    useEffect(() => {
        if (!taskStatuses && hasPermissions('Tasks', ['read', 'admin'])) {
            dispatch(getAllTaskStatusesThunk({ business_id }));
        }
    }, [taskStatuses]);

    const handleMore = () => {
        const totalPages = Math.ceil(count / Number(PAGINATION_PAGE_SIZE));
        const togleFilterPages = Math.ceil(filterCount / Number(PAGINATION_PAGE_SIZE));

        if (dateFrom || dateTo || term) {
            if (filterPage < togleFilterPages) {
                setFilterPage(filterPage + 1);
            }
            return;
        }
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const { handleDownloadPdf } = useDownloadRecentAcitivty();

    const handleDownloadActivity = () => {
        const payload = {
            business_client_id: id,
            business_id: business_id,
            page: 1,
            page_size: 20,
            filter: {
                text: '',
                from_date: '',
                end_date: ''
            }
        };
        handleDownloadPdf(payload);
    };

    if (!user) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    function ReturnDate(task) {
        const newDate = getDueStatusWithTime(task?.start_date, task?.start_time, task?.end_date, task?.end_time);

        if (newDate.includes('Due in')) {
            return <button className="">{newDate}</button>;
        } else if (newDate.includes('Overdue')) {
            return (
                <button className="text-red-500 !bg-gray-300 !font-semibold !text-sm lg:text-lg flex gap-1 items-center !text-md1 ">
                    <span>
                        <OverDueIcon />
                    </span>
                    {newDate}
                </button>
            );
        } else {
            return <span>{formateDate(task?.end_date)}</span>;
        }
    }
    function MyComponent({ htmlContent }) {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: htmlContent.concat(htmlContent.length > 40 ? '...' : '')
                }}
            />
        );
    }

    const handleDetails = (task_id) => {
        const findTask = tasks.find((task) => task.task_id === task_id);
        dispatch(handleTaskDetails(findTask));
        toggleTaskDetails();
    };

    const validateClientID = () => {
        if (
            ALL_ClIENT_TYPES.filter(
                (e) => !['offline_client', 'offline_entity', 'added-from-xero'].includes(e)
            ).includes(user?.client_type)
        ) {
            return true;
        } else {
            toastHandler(`Offline Client Can't Proceed`, TOAST_TYPE_ERROR);
            return false;
        }
    };
    const ValidateClientIDCom = ({ children, to, className }) => {
        if (
            ALL_ClIENT_TYPES.filter(
                (e) => !['offline_client', 'offline_entity', 'added-from-xero'].includes(e)
            ).includes(user?.client_type)
        ) {
            return (
                <Link to={to} className={className}>
                    {children}
                </Link>
            );
        } else {
            return (
                <div
                    className={className}
                    onClick={() => toastHandler(`Offline Client Can't Proceed`, TOAST_TYPE_ERROR)}
                >
                    {children}
                </div>
            );
        }
    };

    return (
        <>
            <div className="px-5 md:px-10 text-black pb-5">
                {user ? (
                    <div>
                        <ManualClientProfile user={user} />
                    </div>
                ) : (
                    <></>
                )}

                {addJob && (
                    <CreateJob
                        title={selectedJob ? 'Edit Job' : 'Create Job'}
                        open={addJob}
                        setOpen={(value) => {
                            setAddJob(value);
                            setSelectedJob(null);
                        }}
                        job_id={selectedJob}
                        onAction={() => setJobsUpdated(true)}
                        user={user}
                    />
                )}

                <div className=" my-5 md:grid grid-cols-12 gap-5">
                    <div className="col-span-12 lg:col-span-8">
                        {' '}
                        <div className="bg-white !border border-main  !rounded-[16px] py-5 px-6">
                            <div className="flex items-center justify-between flex-wrap gap-5 mb-2">
                                <div className="w-[300px]">
                                    <DefaultDoubleSwitch
                                        items={activeTab}
                                        onClick={(e) => {
                                            setActiveView(e);
                                            setActiveTab((prev) => {
                                                return prev.map((obj) => ({
                                                    ...obj,
                                                    selected: e === obj?.title ? true : false
                                                }));
                                            });
                                        }}
                                    />
                                </div>

                                {activeView === 'Tasks' && (
                                    <div className="flex items-center gap-4">
                                        <span className="text-blueish buttons-font-lighter  font-popppins font-[500] cursor-pointer underline-offset-4">
                                            <Link
                                                className="!text-underline underline-offset-8"
                                                to={
                                                    user?.business_client_id
                                                        ? `/tasks?customer_id=${user?.business_client_id}`
                                                        : `/tasks`
                                                }
                                            >
                                                {' '}
                                                View All
                                            </Link>
                                        </span>
                                        <Button
                                            title={'+ Create Task'}
                                            classes="bg-third text-white py-2 px-3 !rounded-lg small-text font-[400] font-poppins"
                                            atClick={() => {
                                                if (hasPermissions('Clients', ['write', 'admin'])) {
                                                    if (user?.business_client_id) {
                                                        setCreateTask(true);
                                                    }
                                                } else {
                                                    toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {activeView === 'Jobs' && (
                                    <div className="flex items-center gap-4">
                                        <span className="text-blueish buttons-font-lighter  font-popppins font-[500] cursor-pointer underline-offset-4">
                                            <Link
                                                className="!text-underline underline-offset-8"
                                                to={
                                                    user?.business_client_id
                                                        ? `/job-manager/jobs?customer_id=${user?.business_client_id}`
                                                        : `/job-manager/jobs`
                                                }
                                            >
                                                View All
                                            </Link>
                                        </span>
                                        <Button
                                            title={'+ Create Job'}
                                            classes="bg-third text-white py-2 px-3 !rounded-lg small-text font-[400] font-poppins"
                                            atClick={() => {
                                                if (hasPermissions('Clients', ['write', 'admin'])) {
                                                    if (user?.business_client_id) {
                                                        setAddJob(!addJob);
                                                    }
                                                } else {
                                                    toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            {activeView === 'Tasks' && (
                                <RoleBasedGuard module={'Tasks'} permissions={['read', 'admin']} sx="h-[50vh]">
                                    <InfiniteScrollComp
                                        height={330}
                                        next={handlePageChange}
                                        dataLength={
                                            (tasks?.length === 0 ? (hasMoreContent ? 1 : 0) : tasks?.length) || 0
                                        }
                                        hasMore={tasks?.length !== 0 ? hasMoreContent : false}
                                        loader={<SkeletonTable columns={1} />}
                                    >
                                        <div className="relative">
                                            <div className="flex items-center justify-between sticky top-0 w-full py-2 bg-white border-t border-b px-2">
                                                <h3 className="flex-1 text-md font-semibold text-gray-900">Filter</h3>
                                                <div className="">
                                                    <div className="w-[200px]">
                                                        <SimpleSelectMenu
                                                            placeholder="Task Status"
                                                            targetProperty="label"
                                                            selectedValue={filters?.task_status_id}
                                                            optionsData={taskStatuses || []}
                                                            sx="rounded-xl py-3 ring-gray-400 bg-[#fff] !font-normal"
                                                            showChipOnly
                                                            onChangeValue={(obj) => {
                                                                setFilters({
                                                                    ...filters,
                                                                    task_status_id: obj ? obj?.task_status_id : ''
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <div className="flex flex-col gap-5">
                                                    {tasks ? (
                                                        tasks.length > 0 &&
                                                        tasks?.map((task, index) => (
                                                            <div
                                                                key={'task' + index}
                                                                className="flex flex-col border hover:shadow-md ease-in duration-200 px-4 py-6 mx-2 rounded-xl cursor-pointer"
                                                                onClick={() => handleDetails(task.task_id)}
                                                            >
                                                                <div className="flex w-full items-start justify-betweeen">
                                                                    <div className="flex-1 cursor-pointer">
                                                                        <div className="flex items-center  gap-3">
                                                                            <h3 className="text-md font-[500] font-fira ">
                                                                                {task?.title}
                                                                            </h3>
                                                                            <span className="p-1.5 text-third bg-[#b695f838] text-xs font-poppins !rounded-xl ">
                                                                                Task ID: {task?.task_ref_no}
                                                                            </span>
                                                                        </div>
                                                                        <h3 className=" !text-[#666666] buttons-font-lighter font-poppins !font-normal">
                                                                            <MyComponent
                                                                                htmlContent={task?.description
                                                                                    .slice(0, 240)
                                                                                    ?.split(' ')
                                                                                    .map(
                                                                                        (word) =>
                                                                                            word
                                                                                                .charAt(0)
                                                                                                .toUpperCase() +
                                                                                            word.slice(1)
                                                                                    )
                                                                                    .join(' ')}
                                                                            />
                                                                        </h3>
                                                                    </div>
                                                                    {!task?.completed_at ||
                                                                        (task?.completed_at === 'null' && (
                                                                            <span className="p-1.5 px-3 text-black bg-gray-300 !font-semibold !rounded-xl  !text-sm lg:text-lg">
                                                                                {ReturnDate(task)}
                                                                            </span>
                                                                        ))}
                                                                </div>

                                                                <div className="grid grid-cols-4 gap-3 flex-wrap mt-5">
                                                                    <div className="small-text text-secondarybg  font-poppins font-normal break-all">
                                                                        Created On:
                                                                        <span className="ml-3  text-black small-text min-w-[100px]">
                                                                            {formateDateOnly(task?.created_at)}
                                                                        </span>
                                                                    </div>
                                                                    <div className="small-text text-secondarybg  font-poppins  font-normal break-all">
                                                                        Start Date
                                                                        <span className="ml-3 text-black min-w-[100px]">
                                                                            {formateDateOnly(
                                                                                task.start_date,
                                                                                'DD-MM-YYYY'
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div className="small-text text-secondarybg  font-poppins font-normal break-all">
                                                                        End Date:
                                                                        <span className="ml-3 text-black min-w-[100px]">
                                                                            {formateDateOnly(
                                                                                task.end_date,
                                                                                'DD-MM-YYYY'
                                                                            )}
                                                                        </span>
                                                                    </div>

                                                                    <div className="small-text text-secondarybg  font-poppins font-normal break-all">
                                                                        Client Name:
                                                                        <span className="ml-3 text-black min-w-[100px]">
                                                                            {task?.customer?.first_name}{' '}
                                                                            {task?.customer?.last_name}
                                                                        </span>
                                                                    </div>

                                                                    <div className="small-text text-secondarybg  font-poppins font-normal break-all">
                                                                        Assigned By:
                                                                        <span className="ml-3 text-black min-w-[100px]">
                                                                            {task?.created_by?.first_name}{' '}
                                                                            {task?.created_by?.last_name}
                                                                        </span>
                                                                    </div>

                                                                    <div className="flex items-start small-text text-secondarybg  font-poppins font-normal break-all">
                                                                        Assigned To:
                                                                        <span className="flex-1">
                                                                            <div>
                                                                                <AvatarGroup
                                                                                    list={task?.employees}
                                                                                    limit={2}
                                                                                    targetProperty={'image'}
                                                                                    onClick={() => {}}
                                                                                    sx="!w-7 !h-7"
                                                                                />
                                                                            </div>
                                                                        </span>
                                                                    </div>

                                                                    <div className="small-text text-secondarybg  font-poppins font-normal break-all">
                                                                        Status:
                                                                        <span
                                                                            className="ml-3  min-w-[100px]"
                                                                            style={{
                                                                                color: colors[
                                                                                    (taskStatuses &&
                                                                                        taskStatuses.findIndex(
                                                                                            (status) =>
                                                                                                status?.task_status_id ===
                                                                                                task?.task_status_id
                                                                                        )) ||
                                                                                        1 % colors.length
                                                                                ]
                                                                            }}
                                                                        >
                                                                            {task?.task_status?.label}
                                                                        </span>
                                                                    </div>

                                                                    <div className="small-text text-secondarybg  font-poppins font-normal break-all">
                                                                        Completion Date/Time:
                                                                        <span className="ml-3 text-black min-w-[100px]">
                                                                            {task?.completed_at &&
                                                                            task?.completed_at !== 'null'
                                                                                ? formateDateAndTime(
                                                                                      task?.completed_at,
                                                                                      'DD MMM, YYYY hh:mm a'
                                                                                  )
                                                                                : '--'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <SkeletonTable columns={1} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </InfiniteScrollComp>
                                </RoleBasedGuard>
                            )}
                            {activeView === 'Jobs' && (
                                <RoleBasedGuard module={'Job'} permissions={['read', 'admin']} sx="h-[50vh]">
                                    <ClientProfileJobs
                                        user={user}
                                        jobsUpdated={jobsUpdated}
                                        setJobsUpdated={setJobsUpdated}
                                    />
                                </RoleBasedGuard>
                            )}
                        </div>
                        <div className=" ">
                            <div className="">
                                <div className="a-button-container mt-5 pl-3">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 px:5 my-5 sm:my-5 sm:px-0">
                                        <div
                                            onClick={() => validateClientID() && toggleForms()}
                                            className="a-button bg-linear cursor-pointer overflow-hidden relative rounded-xl !py-11"
                                        >
                                            <div className="flex justify-start items-start text-lg">Forms</div>
                                            <div className="filed-svg">
                                                <FormsBgIcon />
                                            </div>
                                        </div>
                                        <ValidateClientIDCom
                                            to={`/customer/deductions/${id}`}
                                            className="a-button bg-linear relative cursor-pointer overflow-hidden rounded-xl !py-11"
                                        >
                                            <div className="flex justify-start items-start  text-lg">Deduction</div>
                                            <div className="filed-svg">
                                                <DeductionsBgIcon />
                                            </div>
                                        </ValidateClientIDCom>

                                        <Link
                                            to={`/file/manager/${id}`}
                                            className="a-button bg-linear relative cursor-pointer flex justify-between overflow-hidden rounded-xl !py-11 test"
                                        >
                                            <div className="flex justify-start items-start text-lg">Document Hub</div>
                                            <div className="filed-svg">
                                                <ManagerBgIcon />
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                {deductionToggler && (
                                    <div>
                                        <div className="toggle-deduction-icon  grid grid-cols-3 gap-5 mb-3">
                                            <div></div>
                                            <div className="flex items-center justify-center">
                                                <div className="cursor-pointer mt-3" onClick={toggleDeductions}>
                                                    <svg
                                                        width="17"
                                                        height="8"
                                                        viewBox="0 0 21 9"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M9.90687 0.194944C10.2596 -0.0649814 10.7405 -0.0649814 11.0933 0.194944L20.5933 7.19494C21.0379 7.52256 21.1327 8.14858 20.8051 8.5932C20.4775 9.03782 19.8515 9.13267 19.4069 8.80506L10.5001 2.24215L1.59326 8.80506C1.14864 9.13267 0.522624 9.03782 0.19501 8.5932C-0.132605 8.14858 -0.0377536 7.52256 0.406867 7.19494L9.90687 0.194944Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div></div>
                                        </div>

                                        <div className="toggle-deduction-wrapper grid grid-cols-3 gap-5">
                                            <div onClick={toggleTfnDeduction} className="deduction-btn cursor-pointer">
                                                TFN Deduction
                                            </div>
                                            <div onClick={toggleAbnDeduction} className="deduction-btn cursor-pointer">
                                                ABN Deduction
                                            </div>
                                            <div
                                                onClick={toggleRentalDeduction}
                                                className="deduction-btn  cursor-pointer"
                                            >
                                                Rental Deduction
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 lg:col-span-4  !overflow-hidden max-h-[550px]">
                        <div className="p-card-two-inner2 bg-white rounded-xl  !border border-main  h-full">
                            <DefaultRecentActivity
                                fetchLogs={fetchCustomerLogs}
                                logs={dateFrom || dateTo || term ? filteredLogs : logs}
                                count={count}
                                pageSize={Number(PAGINATION_PAGE_SIZE)}
                                page={page}
                                handleMore={handleMore}
                                term={term}
                                setTerm={setTerm}
                                dateFrom={dateFrom}
                                setDateFrom={setDateFrom}
                                dateTo={dateTo}
                                setDateTo={setDateTo}
                                title="Recent Activity"
                                downloadActivity={handleDownloadActivity}
                                setFilterPage={setFilterPage}
                                filterPage={filterPage}
                                filterCount={filterCount}
                                setfilteredLogs={setfilteredLogs}
                            />
                        </div>
                    </div>
                </div>
                {createTask && user && (
                    <CreateTask
                        open={createTask}
                        setOpen={setCreateTask}
                        user={user || ''}
                        handleTasksUpdate={handleResetTable}
                    />
                )}
            </div>
        </>
    );
};

export default ProfileCards;
