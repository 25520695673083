/* eslint-disable react-hooks/exhaustive-deps */
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { Loader } from 'components/common/Loader';
import { SearchInput } from 'components/molecules/SearchInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { getAllPrebuiltListThunk } from 'store/workspace/workspaceTasks';
import { BUSINESS_ID } from 'utills/globalVars';
import { sortItemsAlphabetically } from 'utills/sortAndFilter';

const PreSelectedCheckList = ({ open, setOpen, handleSelectedPrebuildList }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data: checklListData } = useSelector((state) => state?.getAllPrebuiltList);
    const [query, setQuery] = useState('');

    const filterData = checklListData ? sortItemsAlphabetically(checklListData, 'label') : [];

    const filteredDataWithQuery =
        query === ''
            ? filterData
            : filterData.filter((listObj) => {
                  return listObj.label.toLowerCase().includes(query.toLowerCase());
              });

    const { isLoading } = useSelector((state) => state.global);
    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        setCheckedItems([]);
    }, [open]);

    useEffect(() => {
        if (open && !checklListData) {
            fetchPrebuildChecklistData();
        }
    }, [checklListData, open]);

    const fetchPrebuildChecklistData = () => {
        const payload = {
            business_id
        };
        dispatch(setLoader(true));
        dispatch(getAllPrebuiltListThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const handleCheckboxChange = (event, obj) => {
        const { checked } = event.target;
        if (checked) {
            setCheckedItems((prevState) => [...prevState, obj]);
        } else {
            setCheckedItems((prevState) => prevState.filter((item) => item !== obj));
        }
    };

    const handleClick = () => {
        handleSelectedPrebuildList(checkedItems);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Slideover
                open={open}
                setOpen={setOpen}
                footer={
                    <div className="">
                        <div className="flex items-center gap-3 justify-center mt-9 mb-4">
                            <Button
                                atClick={() => setOpen(false)}
                                title={`Cancel`}
                                classes="flex items-center gap-2 py-3 px-4 sm:px-8 !rounded-xl !ring-inset ring-2  !ring-primary bg-white !text-primary child:text-semibold text-[16px]"
                            />
                            <Button
                                title={`Add`}
                                classes="flex items-center gap-2 py-3 px-4 sm:px-12 !rounded-xl bg-primary child:text-semibold text-white text-[16px]"
                                atClick={() => handleClick()}
                            />
                        </div>
                    </div>
                }
                title={'Select Prebuilt Checklist'}
                titlesx={'text-s-800 text-gray-800 flex-shrink-0 cursor-pointer'}
            >
                <div className="px-5 mt-10">
                    <div className="mb-5">
                        <SearchInput atChange={(e) => setQuery(e.target.value)} placeholder={'Search Checklist'} />
                    </div>
                    <div className="divide-y divide-gray-200 border-b border-t border-gray-200">
                        {filteredDataWithQuery &&
                            filteredDataWithQuery?.map((obj, personIdx) => (
                                <div key={personIdx} className="relative flex items-start py-4">
                                    <div className=" flex h-6 items-center">
                                        <input
                                            id={`person-${obj.pre_checklist_id}`}
                                            name={`person-${obj.pre_checklist_id}`}
                                            type="checkbox"
                                            className="h-5 w-5 rounded border-gray-300 text-gray-800 focus:ring-gray-800"
                                            onChange={(event) => handleCheckboxChange(event, obj)}
                                            checked={checkedItems.includes(obj)}
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1 text-md leading-6 ml-3">
                                        <label
                                            htmlFor={`person-${obj.pre_checklist_id}`}
                                            className="select-none font-medium text-gray-900"
                                        >
                                            {obj.label}
                                        </label>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default PreSelectedCheckList;
