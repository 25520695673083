import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { PreauthEmailSendOtpThunk, login, preverifyTwoFactorEmailThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { BackArrow } from 'utills/svgs/BackArrow';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import { useEffect } from 'react';
import whitelogo from '../../files/whiteBgLogo.png.png';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';

export const VerifyTwoFaEmail = ({ handleCondition }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.login);
    const [otpVal, setOtpVal] = useState('');
    const forgotPasswordData = JSON.parse(sessionStorage.getItem('forgotPasswordData'));

    const otpData = {
        user_id: forgotPasswordData?.user_id,
        type: 5,
        user_type: 'employee'
    };

    const sendOTP = async () => {
        dispatch(setLoader(true));
        await dispatch(PreauthEmailSendOtpThunk(otpData))
            .then((_response) => {
                dispatch(setLoader(false));
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });

        dispatch(setLoader(false));
    };
    const handleEmailSubmit = () => {
        sendOTP();
    };

    const [timeRemaining, setTimeRemaining] = useState(67);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeRemaining]);

    const verifyOtpData = {
        email: forgotPasswordData.email,
        isBeforeLogin: true,
        user_id: forgotPasswordData?.user_id,
        otp: otpVal,
        user_type: 'employee'
    };
    const handleOtpSubmit = async () => {
        if (otpVal.length < 6) {
            return toastHandler('Please enter full otp', TOAST_TYPE_ERROR);
        }

        dispatch(setLoader(true));
        await dispatch(preverifyTwoFactorEmailThunk(verifyOtpData))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    const payload = response.payload;
                    if (payload.access_token && payload.refresh_token) {
                        const access_token = payload.access_token;
                        const refresh_token = payload.refresh_token;

                        localStorage.setItem('loggedInUser', payload);
                        localStorage.setItem(ACCESS_TOKEN, access_token);
                        localStorage.setItem(REFRESH_TOKEN, refresh_token);
                        localStorage.setItem(BUSINESS_ID, payload?.business?.business_id);
                    }

                    const newPayload = {
                        ...data,
                        email_2fa: true
                    };
                    dispatch(login.actions.handleUpdate(newPayload));
                    localStorage.setItem('email_2fa', true);

                    handleCondition(7);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });

        dispatch(setLoader(false));
    };

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    return (
        <div className="md:flex justify-center flex-wrap  items-center w-[100%] h-[100vh] gap-2 overflow-y-auto relative">
            <div className="absolute left-5 top-5">
                <img src={whitelogo} alt="logo" className="Synkli-logo" />
            </div>
            <div className=" max-w-[650px] rounded-lg min-h-[90vh]  bg-white p-3">
                <div className="card-heading-two font-poppins px-5 pt-5 flex gap-2  items-center">
                    <div onClick={() => handleCondition(1)} className="arrow-wrapper cursor-pointer">
                        <BackArrow />
                    </div>
                    <div>
                        {' '}
                        <h1>Email Verification</h1>
                    </div>
                </div>

                <div className="text-secondarybg small-text font-poppins flex mx-5  gap-2 items-center  mt-3">
                    <div>
                        <div>
                            <BlueGreaterSign />
                        </div>
                    </div>
                    <div>
                        <span>
                            A message containing a code will be sent to the authorised email address for email
                            verification.
                        </span>
                    </div>
                </div>

                <div className="default-forms px-5 mt-6">
                    <div>
                        <label className="font-poppins"> Email</label>
                    </div>
                    <div>
                        <input
                            type="email"
                            className="px-3 mt-2"
                            placeholder="Enter your email"
                            value={data.email}
                            required
                        />
                    </div>
                </div>

                <div className=" flex justify-center items-center mt-10">
                    {' '}
                    <button
                        onClick={handleEmailSubmit}
                        className="px-14 py-2 ann-btn buttons-font font-fira rounded-lg text-white"
                    >
                        Send
                    </button>
                </div>

                <div className="otp-verification mt-16">
                    <div className="otp-title px-5">OTP Verification</div>

                    <div className="email-text flex gap-2  mx-5 mt-3">
                        <div>
                            <div className="mt-1">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>Please enter the six-digit code that has been sent to your email address.</span>
                        </div>
                    </div>

                    <div className="mt-10 flex justify-center items-center">
                        <OtpInputs value={otpVal} onChange={handleOtpChange} required={true} />
                    </div>
                </div>

                <div className=" my-5 mt-10 flex justify-center items-center">
                    <button onClick={handleOtpSubmit} className=" px-14 rounded-lg ann-btn buttons-font font-fira">
                        Verify
                    </button>
                </div>
            </div>
        </div>
    );
};
