import React from 'react';

export const CheckboxChip = ({ value, onChange, selectedValue, classes, p, profile, profileEmail }) => {
    return (
        <div
            onClick={(_e) => onChange(value)}
            className={`h-[3rem] rounded-xl pr-16 border ${p} border-secondarybg p-3 buttons-font-lighter cursor-pointer font-poppins ${
                selectedValue === value && 'bg-linear text-white !border-none'
            } `}
        >
            <div className={`flex gap-2 items-center small-text font-poppins cursor-pointer`}>
                <div>
                    <input
                        name={`${value}`}
                        type="checkbox"
                        className={` ${classes ? classes : ' !rounded-[3px] focus:outline-none'}`}
                        onChange={(_e) => onChange(value)}
                        checked={selectedValue === value}
                    />
                </div>{' '}
                <div htmlFor={`${value}`} className="flex gap-3 items-center justify-between">
                    {profile}
                    <div className="flex flex-col gap-0">
                        <div>{value}</div>
                        {profileEmail && <div className="text-secondarybg text-sm"> {profileEmail}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};
