import React from 'react';

const RecycleIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_5386_1295)">
                <path d="M9.2773 0.0233059C9.2148 0.0311184 8.99214 0.0584621 8.78901 0.0818996C6.63276 0.327993 4.49995 1.38268 2.9062 2.99987C-0.390673 6.33581 -0.902392 11.6131 1.69917 15.5077C2.30855 16.4217 2.99214 17.1678 3.80073 17.8124C4.0312 17.9921 4.2187 18.1522 4.2187 18.1678C4.2187 18.1835 4.1523 18.2069 4.07417 18.2225C3.61323 18.3046 3.14058 18.4452 3.05464 18.5272C2.75386 18.8085 2.78901 19.2772 3.13276 19.496C3.23823 19.5585 3.30464 19.5702 3.49605 19.5585C3.79683 19.5311 6.03901 19.0155 6.19917 18.9335C6.36323 18.8475 6.48433 18.6405 6.48433 18.4452C6.48433 18.3006 6.05073 16.3006 5.91792 15.8358C5.84761 15.5897 5.66011 15.41 5.43355 15.371C5.12495 15.3202 4.80464 15.5546 4.74605 15.8788C4.72651 15.9725 4.88276 16.7811 4.98823 17.1288C5.01167 17.203 5.00776 17.203 4.89839 17.1288C4.47651 16.8553 3.74605 16.1796 3.31245 15.6639C1.4648 13.4608 0.812452 10.5194 1.55073 7.71471C2.03511 5.86315 3.19917 4.14049 4.72651 3.00377C6.01558 2.04674 7.1562 1.56627 8.82808 1.28112C9.01558 1.24987 9.51167 1.23034 10.2226 1.23034C11.2656 1.23034 11.3281 1.22643 11.4296 1.15221C11.7734 0.890493 11.7421 0.327993 11.3671 0.136587C11.3046 0.105337 11.0898 0.0623684 10.8906 0.0389309C10.5507 0.00377464 9.53901 -0.00403786 9.2773 0.0233059Z" />
                <path d="M15.6052 0.492168C15.1989 0.585918 14.6247 0.718731 14.3356 0.785137C13.8552 0.898418 13.7966 0.921856 13.6638 1.05076C13.4255 1.29295 13.4255 1.32811 13.7653 2.80467C13.9294 3.52732 14.0935 4.17185 14.1325 4.23826C14.1716 4.30467 14.281 4.39451 14.3825 4.44529C14.6364 4.57029 14.8474 4.53904 15.0466 4.33982C15.2614 4.12498 15.2692 3.99607 15.1208 3.32811C15.0505 3.01951 14.9997 2.7617 15.0075 2.75389C15.0349 2.72654 15.695 3.25389 16.0622 3.59373C17.8005 5.21092 18.8278 7.58592 18.8278 9.99998C18.8278 13.3164 16.9489 16.3633 13.9919 17.8515C12.5896 18.5547 11.2927 18.8281 9.60908 18.7734L8.8083 18.75L8.66768 18.8437C8.30049 19.0937 8.32783 19.664 8.71455 19.8633C8.9958 20.0117 10.5271 20.0351 11.4255 19.9062C13.1286 19.6601 14.6833 19.0117 16.0856 17.9609C16.5661 17.6015 17.4177 16.7734 17.8161 16.2773C18.9528 14.8672 19.6599 13.25 19.9255 11.4648C20.031 10.7461 20.031 9.25389 19.9255 8.53514C19.5427 5.93357 18.1521 3.59764 16.0466 2.00779C15.7731 1.80076 15.6833 1.71873 15.7341 1.70311C15.7692 1.69529 16.0114 1.6367 16.2692 1.58201C16.9333 1.43357 17.1247 1.27732 17.1247 0.890606C17.1247 0.679668 17.0427 0.54295 16.8356 0.398418C16.6521 0.277325 16.4724 0.289043 15.6052 0.492168Z" />
            </g>
            <defs>
                <clipPath id="clip0_5386_1295">
                    <rect width="20" height="20" fill="black" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RecycleIcon;
