import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import { useSelector } from 'react-redux';

export const TaskTypesCard = () => {
    const { data } = useSelector((state) => state.countByType);
    const colors = ['#DC7B7B', '#2AD4AF', ' #657CEE', '#92D268', '#b695f8'];

    return (
        <div>
            <div className="p-8  ">
                <div className="xl-title text-blueish font-fira   mt-1"> Task Types</div>

                {data &&
                    data?.map((item, index) => {
                        const completionPercentage = (item.typeCount / item.count) * 100;

                        return (
                            <div className="completed-task-type-strip mt-7" key={index}>
                                <div className="flex justify-between items-center">
                                    <div className="buttons-font font-poppins">{item.label}</div>
                                    <div className="buttons-font font-poppins">
                                        {item.typeCount}/{item.count}
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <ProgressBar
                                        completed={completionPercentage}
                                        bgColor={
                                            index < 1
                                                ? colors[0]
                                                : index === 1
                                                ? colors[1]
                                                : index === 2
                                                ? colors[2]
                                                : index === 3
                                                ? colors[3]
                                                : index === 4
                                                ? colors[4]
                                                : colors[5]
                                        }
                                        height="5px"
                                        width="100%"
                                        animateOnRender={true}
                                        isLabelVisible={false}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
