import React from 'react';

export const SuccessTick = () => {
    return (
        <div>
            {' '}
            <svg width="120" height="120" viewBox="0 0 150 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M62.2562 0.476929C48.1937 1.82458 34.6293 7.65466 23.8773 16.9418C18.7211 21.3656 15.4105 25.1449 11.5726 30.9164C-1.11293 49.9301 -3.48598 73.6312 5.18589 94.9301C13.7113 115.965 32.5785 131.58 55.0492 136.268C61.0843 137.498 69.8734 137.938 76.1136 137.235C105.674 134.041 130.166 111.746 136.055 82.684C137.168 77.2054 137.491 73.1332 137.344 67.0101C137.198 61.0922 137.022 60.2719 135.469 59.0707C133.418 57.4594 130.84 57.6937 129.082 59.6273C127.881 60.975 127.94 60.4769 127.998 67.5375C128.086 73.5726 127.588 78.0258 126.387 83.0648C121.143 104.364 104.297 121.239 82.9105 126.541C60.0589 132.196 35.6253 123.495 21.3871 104.598C3.77964 81.2191 5.83043 48.2601 26.2503 27.5472C45.7035 7.80116 75.1468 3.90468 99.0824 17.9086C100.313 18.641 101.748 19.3441 102.276 19.4906C105.352 20.3695 108.662 17.0004 107.813 13.8656C107.373 12.2836 106.553 11.4633 103.828 9.82263C91.8753 2.5863 76.348 -0.900024 62.2562 0.476929Z"
                    fill="url(#paint0_linear_6_790)"
                />
                <path
                    d="M143.731 6.74292C142.794 7.06519 135.587 14.1257 109.512 40.2L76.4655 73.2468L63.6627 60.4441C56.6022 53.4128 50.4499 47.4949 50.0104 47.3191C48.7799 46.8796 47.5788 46.9675 46.2897 47.6121C44.2975 48.6375 43.36 50.7468 43.9459 52.9441C44.2389 54.0574 45.9674 55.9031 58.8288 68.7937C66.8268 76.821 73.8874 83.6472 74.4733 83.9695C75.7038 84.5847 76.9049 84.6726 78.1354 84.2332C79.2487 83.7937 149.063 14.0671 149.59 12.8367C151.173 9.20386 147.481 5.36597 143.731 6.74292Z"
                    fill="url(#paint1_linear_6_790)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_6_790"
                        x1="75.4445"
                        y1="135.864"
                        x2="75.3416"
                        y2="0.181012"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_6_790"
                        x1="102.08"
                        y1="83.5385"
                        x2="102.037"
                        y2="6.45609"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
