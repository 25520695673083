/* eslint-disable react/no-children-prop */
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import useRoleGuard from 'Hooks/useRoleGuard';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { Button } from 'components/atoms/buttons/Button';
import { Loader } from 'components/common/Loader';
import { NoDataFound } from 'components/common/NoDataFound';
import { SmallLoader } from 'components/common/SmallLoader';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { sendClientInviteThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { deleteLeadThunk } from 'store/leads';
import ConfirmationModal from 'utills/confirmationModal';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { convertDateFormatToLocal } from 'utills/moment';
import { EditPencilIcon } from 'utills/svgs/EditPencilIcon';
import { SideTimes } from 'utills/svgs/SideTimes';
import { TrashCan } from 'utills/svgs/TrashCan';

const LeadsTableDataDT = ({
    data,
    isLoading,
    toggleCreateTask,
    handleDetails,
    handleResetAndGet,
    handleEditLead,
    taskDetailLead
}) => {
    const dispatch = useDispatch();

    const [clientList, setClientList] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [leadId, setLeadId] = useState('');
    const [inviteLoader, setInviteLoader] = useState(false);

    const [viewMessage, setViewMessage] = useState(false);
    const [message, setMessage] = useState(null);
    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        if (!viewMessage) {
            setMessage(null);
        }
    }, [viewMessage]);

    useEffect(() => {
        setClientList(data);
    }, [data]);

    const handleDeleteLead = () => {
        dispatch(setLoader(true));
        setIsConfirmationOpen(false);
        dispatch(deleteLeadThunk({ lead_id: leadId }))
            .then((response) => {
                if (response.payload) {
                    handleResetAndGet();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const deleteLead = (lead_id) => {
        if (hasPermissions('Leads', ['admin'])) {
            setIsConfirmationOpen(true);
            setLeadId(lead_id);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const handleSendInvite = (payload) => {
        if (hasPermissions('Leads', ['admin'])) {
            setInviteLoader(true);
            dispatch(sendClientInviteThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        toastHandler('Invite sent!', TOAST_TYPE_SUCCESS);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setInviteLoader(false);
                });
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    return (
        <div className="px-10 pt-10 min-h-[500px]">
            {inviteLoader && <Loader />}
            {viewMessage && (
                <DefaultDialog
                    children={
                        <div className="relative py-4">
                            <div
                                className="absolute right-2 top-2 cursor-pointer"
                                onClick={() => {
                                    setViewMessage(!viewMessage);
                                }}
                            >
                                <SideTimes />
                            </div>

                            <div className="mx-5 table-title text-blueish font-poppins flex font-semibold justify-start items-center">
                                Message
                            </div>
                            <div className="buttons-font-lighter font-poppins text-start mt-3 mx-5">{message}</div>
                        </div>
                    }
                    p="!rounded-2xl !p-2"
                    open={viewMessage}
                    setOpen={setViewMessage}
                    width="md:max-w-[450px]"
                />
            )}
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDeleteLead}
            />

            <div className="">
                <div className="px-4 sm:px-6 rounded-xl  bg-white lg:px-8">
                    <div className="mt-5 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                            >
                                                Phone
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                            >
                                                Message
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                            >
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {clientList &&
                                            clientList?.map((person) => (
                                                <tr key={person?.email}>
                                                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-md sm:pl-0">
                                                        <div className="flex items-center">
                                                            <div className="">
                                                                <div className="font-medium text-gray-900">
                                                                    {person?.first_name} {person?.last_name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                        <div className="text-gray-900">{person?.phone_number}</div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                        {person?.email || '--'}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                        {convertDateFormatToLocal(
                                                            person.created_at,
                                                            'DD-MM-YYYYTHH:mm:ssZ',
                                                            'DD/MM/YYYY hh:mm A'
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                        <span
                                                            onClick={() => {
                                                                setMessage(person?.message);
                                                                setViewMessage(true);
                                                            }}
                                                        >
                                                            <span className="buttons-font cursor-pointer font-fira underline text-gray-800">
                                                                <span> View Message</span>
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                        <div className="flex justify-between items-center relative">
                                                            <div className="">
                                                                {!person?.task_id ? (
                                                                    <Button
                                                                        title="Create Task"
                                                                        classes="bg-linear text-white py-2 rounded-lg px-5 small-text    font-fira"
                                                                        atClick={() => {
                                                                            toggleCreateTask(person?.lead_id);
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <div className="flex gap-2 items-center">
                                                                        {isLoading &&
                                                                        taskDetailLead === person?.task_id ? (
                                                                            <SmallLoader />
                                                                        ) : (
                                                                            <span>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        if (isLoading) {
                                                                                            return;
                                                                                        }
                                                                                        handleDetails(person?.task_id);
                                                                                    }}
                                                                                    className="buttons-font cursor-pointer font-fira underline text-thirdbg"
                                                                                >
                                                                                    <span> {person?.task_ref_no}</span>
                                                                                </span>
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="cursor-pointer  text-black flex items-center">
                                                                <DropdownMenu>
                                                                    <DropdownMenuItem
                                                                        className="w-full hover-effect-invert"
                                                                        onClick={() => {
                                                                            handleSendInvite({
                                                                                first_name: person.first_name || '',
                                                                                last_name: person?.last_name || '',
                                                                                email: person?.email || '',
                                                                                phone_number: person?.phone_number || ''
                                                                            });
                                                                        }}
                                                                    >
                                                                        <div className="flex items-center gap-2 -ml-1">
                                                                            <PaperAirplaneIcon className="w-5 h-5" />
                                                                            Send Invite
                                                                        </div>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem
                                                                        className="w-full"
                                                                        onClick={() => {
                                                                            if (hasPermissions('Leads', ['admin'])) {
                                                                                handleEditLead(person);
                                                                            } else {
                                                                                toastHandler(
                                                                                    "You don't have permission to access this page",
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="flex items-stretch gap-2 hover-effect-invert w-full">
                                                                            <EditPencilIcon />
                                                                            Edit
                                                                        </div>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem
                                                                        className="w-full"
                                                                        onClick={() => deleteLead(person?.lead_id)}
                                                                    >
                                                                        <div className="flex items-stretch gap-2 hover-effect-invert w-full">
                                                                            <TrashCan className="w-4 h-4" />
                                                                            Delete
                                                                        </div>
                                                                    </DropdownMenuItem>
                                                                </DropdownMenu>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!isLoading && clientList?.length === 0 && (
                <NoDataFound classes="border-b border-gray-300" message="No Data Found!" height="420px" />
            )}
            {isLoading && <SkeletonTable columns={7} />}
        </div>
    );
};

export default LeadsTableDataDT;
