import React from 'react';
import { motion } from 'framer-motion';
import { LockerIcon } from 'utills/svgs/LockerIcon';
import { useNavigate } from 'react-router-dom';
export const SuccessPage = ({ handleCondition }) => {
    const navigate = useNavigate();
    return (
        <div className="flex min-h-[60vh] flex-col justify-center pb-10 sm:px-6 lg:px-8 ">
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] bg-white shadow rounded-xl sm:rounded-2xl  py-4 ">
                {' '}
                <div className="flex justify-center items-center mt-24">
                    <motion.div
                        initial={{ rotate: 100, opacity: 0 }}
                        animate={{ rotate: 0, opacity: 1 }}
                        transition={{ delay: 0.3, type: 'spring', duration: 0.5 }}
                        className="svg-wrapper scale-50 "
                    >
                        <LockerIcon />
                    </motion.div>
                </div>
                <div className="mt-5">
                    <h1 className="text-center card-layout-title-text ">Congratulations!</h1>
                </div>
                <div className="px-5 mt-2">
                    <p className="text-center s-text">Your Account Password has been Reset.</p>
                </div>
                <div className="s-btn-wrapper mt-24 mb-5">
                    <button
                        onClick={() => {
                            handleCondition(0);
                            navigate('/security-and-authentication');
                        }}
                        className="ann-btn px-16 py-2 rounded-lg"
                    >
                        Ok!
                    </button>
                </div>
            </div>
        </div>
    );
};
