import React from 'react';
import Chart from 'react-apexcharts';

export const MixedChart = ({ expenseValues, incomeValues, months }) => {
    const chartOptions = {
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadiusWhenStacked: 'all',
                borderRadius: 5,
                borderRadiusApplication: 'end',
                columnWidth: '40%'
            }
        },
        series: [
            {
                name: '',
                type: 'column',
                data: incomeValues,
                color: '#b695f8'
            },
            {
                name: '',
                type: 'line',
                data: expenseValues,
                color: '#101828'
            }
        ],
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false
            }
        },
        stroke: {
            width: [0, 4]
        },
        dataLabels: {
            enabled: false
        },
        labels: months,
        xaxis: {
            type: 'category'
        },
        yaxis: []
    };

    return (
        <div id="chart">
            <Chart options={chartOptions} series={chartOptions.series} type="line" height={350} />
        </div>
    );
};
