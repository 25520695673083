/* eslint-disable react/style-prop-object */
import React from 'react';

export const SendMailIcon = ({ className }) => {
    return (
        <svg
            width="35"
            height="20"
            viewBox="0 0 35 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M31.6068 0H7.71581C6.02028 0 4.63307 1.38429 4.63307 3.07689V4.23067C4.63307 4.65361 4.97987 5 5.40365 5C5.82785 5 6.17465 4.65361 6.17465 4.23067V3.07689C6.17465 2.92294 6.21314 2.76899 6.25162 2.61504L14.8832 10L6.25162 17.3845C6.21314 17.2306 6.17465 17.0766 6.17465 16.9227V15.7689C6.17465 15.346 5.82785 15 5.40365 15C4.97987 15 4.63307 15.346 4.63307 15.7689V16.9227C4.63307 18.6153 6.02028 20 7.71581 20H31.6068C33.3023 20 34.6895 18.6153 34.6895 16.9227V3.07689C34.6895 1.38429 33.3023 0 31.6068 0ZM7.4075 1.57672C7.48447 1.53824 7.60035 1.53824 7.71581 1.53824H31.6068C31.7227 1.53824 31.8381 1.53824 31.9151 1.57672L20.1623 11.6152C19.854 11.8461 19.4687 11.8461 19.1603 11.6152L7.4075 1.57672ZM31.6068 18.4613H7.71581C7.60035 18.4613 7.48447 18.4613 7.4075 18.4229L16.0776 10.9998L18.1584 12.8075C18.5826 13.1919 19.1219 13.3844 19.6615 13.3844C20.2008 13.3844 20.7404 13.1919 21.1642 12.8075L23.245 10.9998L31.9151 18.4229C31.8381 18.4613 31.7227 18.4613 31.6068 18.4613ZM33.1484 16.9227C33.1484 17.0766 33.1099 17.2306 33.0714 17.3845L24.4398 10L33.0714 2.61504C33.1099 2.76899 33.1484 2.92294 33.1484 3.07689V16.9227ZM2.32132 8.07689C2.32132 7.65353 2.66812 7.30756 3.0919 7.30756H7.71581C8.13959 7.30756 8.4864 7.65353 8.4864 8.07689C8.4864 8.49983 8.13959 8.8458 7.71581 8.8458H3.0919C2.66812 8.8458 2.32132 8.49983 2.32132 8.07689ZM7.71581 12.692H0.779738C0.355959 12.692 0.00915527 12.3461 0.00915527 11.9227C0.00915527 11.4997 0.355959 11.1538 0.779738 11.1538H7.71581C8.13959 11.1538 8.4864 11.4997 8.4864 11.9227C8.4864 12.3461 8.13959 12.692 7.71581 12.692Z"
                fill="white"
            />
        </svg>
    );
};
