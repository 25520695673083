import React from 'react';

export const TfnInfoCard = ({ data }) => {
    return (
        <div className="grid grid-cols-2 gap-5 mt-10">
            <div>
                {data?.slice(0, 4)?.map((item, index) => (
                    <div className={`flex gap-5 items-center  ${index > 0 && 'mt-10'}`} key={index}>
                        <div className="flex gap-2 items-center">
                            <div> {<item.icon />} </div>
                            <div className="detail-card-key "> {item.key} </div>
                        </div>
                        <div className="break-all detail-card-value"> {item.value} </div>
                    </div>
                ))}
            </div>
            <div>
                {data?.slice(4, data?.length)?.map((item, index) => (
                    <div className={`flex gap-5 items-center  ${index > 0 && 'mt-10'}`} key={index}>
                        <div className="flex gap-2 items-center">
                            <div> {<item.icon />} </div>
                            <div className="detail-card-key "> {item.key} </div>
                        </div>
                        <div className="break-all detail-card-value"> {item.value} </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
