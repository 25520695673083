import React, { useState } from 'react';

const WebsiteLinkInput = ({ onChange, value, required }) => {
    const [link, setLink] = useState(value ?? '');
    const [error, _setError] = useState('');

    const handleChange = (e) => {
        const inputValue = e.target.value.trim();
        setLink(inputValue);
        onChange && onChange(inputValue);
    };

    return (
        <div className="mb-4">
            <input
                className={`shadow appearance-none inputs-font border ${
                    error ? 'border-red-500' : ''
                } rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline focus:border-1 focus:border-gray-700 focus:ring-0`}
                id="websiteLink"
                type="text"
                placeholder="Enter website link"
                value={link}
                onChange={handleChange}
                required={required ?? false}
            />
            {error && link && <p className="text-red-500 text-xs mt-1 ">{error}</p>}
        </div>
    );
};

export default WebsiteLinkInput;
