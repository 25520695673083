import React from 'react';

export const LockerIcon = () => {
    return (
        <svg width="122" height="120" viewBox="0 0 142 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M64.5545 0.449051C64.1443 0.507645 62.6502 0.712723 61.1853 0.888504C51.4881 2.03108 41.0877 5.92757 32.826 11.4647C28.6365 14.2772 26.3221 16.1815 22.3377 20.1658C18.4705 24.033 16.5955 26.2889 13.6658 30.5076L11.8787 33.0858L10.7947 29.9217C10.1795 28.1932 9.41776 26.4061 9.09549 25.9373C8.45096 25.0291 6.51737 24.0037 5.37479 24.0037C4.20291 24.0037 2.29862 25.0291 1.56619 26.0545C0.218539 27.9295 0.365023 28.6326 3.73416 38.1541C5.43338 42.9295 7.04471 47.2069 7.30838 47.617C7.60135 48.0272 8.33377 48.6131 9.0076 48.9647C10.5017 49.7264 11.4099 49.6678 22.2205 47.8807C27.3474 47.0311 30.7459 46.328 31.3025 46.0057C34.2322 44.3065 34.2615 39.824 31.3904 38.0662C30.1599 37.3045 28.6658 37.3338 23.8318 38.0955C21.7224 38.4471 19.906 38.6522 19.8181 38.535C19.7303 38.4471 20.1404 37.5975 20.7556 36.6893C29.0174 24.1795 41.6736 15.1561 56.1756 11.4061C61.4783 10.0291 65.2869 9.56038 71.2928 9.56038C87.7576 9.53108 102.582 15.6834 114.213 27.3729C122.738 35.9276 128.392 46.5037 130.736 58.2518C131.234 60.7713 131.732 65.1658 132.025 70.0877C132.201 72.6658 133.695 74.3358 136.097 74.5994C137.797 74.8045 139.261 74.16 140.316 72.7537C141.166 71.6697 141.166 71.5819 141.136 67.9783C140.961 51.0155 134.017 34.1698 122.152 21.9823C118.051 17.7928 115.326 15.5076 110.726 12.4608C101.879 6.5428 92.0056 2.70491 81.1072 0.976395C78.324 0.536942 66.2537 0.156082 64.5545 0.449051Z"
                fill="url(#paint0_linear_6242_5006)"
            />
            <path
                d="M68.2168 35.1055C63.7051 35.8379 60.3066 37.5957 57.0547 40.8184C52.8652 45.0078 51.4004 48.6113 51.166 55.2617L51.0195 59.3926L47.2988 59.5098C43.0508 59.627 41.9961 60.0078 40.9121 61.9707L40.2383 63.1133V82.2441C40.2383 100.701 40.2676 101.404 40.8242 102.488C41.1465 103.133 41.8789 103.953 42.4941 104.305L43.6074 105.008H71H98.3926L99.5352 104.305C100.238 103.865 100.854 103.133 101.205 102.4C101.703 101.258 101.762 100.086 101.762 82.3027C101.762 64.5195 101.703 63.3477 101.205 62.2051C100.854 61.4727 100.238 60.7402 99.5352 60.3008C98.5098 59.6855 97.9824 59.5977 94.7012 59.5098L90.9805 59.3926L90.8633 55.2617C90.7461 51.6875 90.5996 50.75 89.8965 48.6406C88.168 43.543 84.4766 39.3535 79.7012 37.0684C78.2363 36.3652 76.4199 35.6328 75.6875 35.4863C73.7832 35.0176 69.916 34.8418 68.2168 35.1055ZM74.8379 45.0957C77.123 45.9453 79.2617 47.9375 80.4336 50.2812C81.3125 52.0684 81.3711 52.4492 81.4883 55.8184L81.6055 59.4512H71H60.3945L60.5117 55.8477C60.5996 52.7422 60.7168 52.0684 61.3613 50.6621C63.8223 45.3887 69.4473 43.0742 74.8379 45.0957ZM92.3867 82.3027V95.7793H71H49.6133V82.3027V68.8262H71H92.3867V82.3027Z"
                fill="url(#paint1_linear_6242_5006)"
            />
            <path
                d="M3.47094 65.7222C2.24047 66.2495 1.0393 67.7437 0.804923 69.0327C0.570548 70.3511 0.863517 75.9175 1.42016 79.814C3.20727 92.5874 8.51 104.863 16.2737 114.091C18.7932 117.08 23.3635 121.591 26.176 123.906C31.5666 128.33 40.5022 133.251 47.4162 135.625C51.6936 137.089 56.6741 138.349 61.0979 139.052C65.7561 139.785 77.1233 139.785 81.7815 139.052C100.531 136.123 116.235 126.337 127.426 110.693C128.539 109.14 129.594 107.617 129.77 107.324C130.033 106.855 130.239 107.207 131.03 109.58C132.553 114.179 134.106 115.996 136.537 115.996C138.793 115.996 141.283 113.593 141.313 111.425C141.313 110.312 135.365 93.3491 134.574 92.2358C133.842 91.269 132.319 90.5073 130.971 90.5073C128.803 90.5073 111.606 93.437 110.727 93.9644C107.768 95.6929 107.709 100.175 110.61 101.933C111.869 102.695 113.276 102.695 118.11 101.904C120.248 101.552 122.065 101.347 122.153 101.435C122.27 101.523 121.713 102.578 120.922 103.72C108.119 123.027 84.7698 133.31 61.7717 129.882C35.5803 125.957 14.9846 105.507 10.8245 79.3745C10.5608 77.8511 10.2678 74.7163 10.1213 72.4019C9.85766 67.9487 9.68188 67.3921 7.95336 66.103C6.98656 65.3706 4.64281 65.1655 3.47094 65.7222Z"
                fill="url(#paint2_linear_6242_5006)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_6242_5006"
                    x1="77.8457"
                    y1="73.7141"
                    x2="77.8163"
                    y2="0.345819"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_6242_5006"
                    x1="74.0187"
                    y1="104.138"
                    x2="73.959"
                    y2="34.9824"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_6242_5006"
                    x1="77.9163"
                    y1="138.68"
                    x2="77.8869"
                    y2="65.4044"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
