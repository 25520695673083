import React, { useEffect, useState } from "react";

export const ElementVisible = ({ target, children }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver((_mutations) => {
      const $el = document.querySelector(target);
      setVisible(!!$el);
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [target]);

  useEffect(() => {
    setVisible(!!document.querySelector(target));
  }, [target]);

  if (!visible) {
    return null;
  }

  return <>{children}</>;
};
