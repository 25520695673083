import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';
import { QrCodeIcon } from 'utills/QrCodeIcon';
import { AnnouncementIcon } from 'utills/svgs/AnnouncementIcon';
import { DeductionIcon } from 'utills/svgs/DeductionIcon';
import { TelephonicDirectorySvg } from 'utills/svgs/TelephonicDirectorySvg';
import { ThreadsIcon } from 'utills/ThreadsIcon';

export const Workspace = () => {
    return (
        <SidebarLayout>
            <Jumbotron title="Workspace" />

            <div className="md:mx-10 mx-5 mt-5">
                <div className="flex items-center flex-wrap gap-5 ">
                    <GridCard
                        text="Telephonic Directory"
                        iconSet={{ icon: TelephonicDirectorySvg }}
                        link={'/telephonic/directories'}
                    />
                    <GridCard
                        text="Announcements"
                        iconSet={{ icon: AnnouncementIcon, sx: '!w-[80px] !h-[80px]' }}
                        link={'/announcement'}
                    />
                    <GridCard
                        text="Threads"
                        iconSet={{ icon: ThreadsIcon, sx: '!w-[80px] !h-[80px]' }}
                        link={'/threads'}
                    />
                    <GridCard
                        text="Deduction"
                        iconSet={{ icon: DeductionIcon, sx: '!w-[80px] !h-[80px]' }}
                        link={'/deduction'}
                    />
                    <GridCard
                        text="QR Codes"
                        iconSet={{ icon: QrCodeIcon, sx: '!w-[80px] !h-[80px]' }}
                        link={'/qrcodes'}
                    />
                </div>
            </div>
        </SidebarLayout>
    );
};
