import React from 'react';
import './css/business.css';

const BusinessBar = ({ condition, handleCondition }) => {
    const changeCondition = () => {
        handleCondition(1);
    };

    const setBusinessHours = () => {
        handleCondition(2);
    };

    const setServices = () => {
        handleCondition(3);
    };

    const setPhotos = () => {
        handleCondition(4);
    };

    function showBranchLocations() {
        handleCondition(5);
    }
    return (
        <div className="md:px-10 px-5  w-full">
            <div className="business-bar buttons-font font-poppins text-secondarybg px-10 shadow border">
                <div
                    onClick={changeCondition}
                    className={`flex bar-heading justify-center items-center cursor-pointer buttons-font px-2 !pb-1  ${
                        condition === 1 ? 'add-border !text-black' : ''
                    }`}
                >
                    Bio
                </div>

                <div
                    onClick={setBusinessHours}
                    className={`flex  justify-center items-center cursor-pointer buttons-font px-2 !pb-1 ${
                        condition === 2 ? 'add-border !text-black' : ''
                    }`}
                >
                    Business Hours
                </div>

                <div
                    onClick={setServices}
                    className={`flex  justify-center items-center cursor-pointer buttons-font px-2 !pb-1 ${
                        condition === 3 ? 'add-border !text-black' : ''
                    }`}
                >
                    Services
                </div>

                <div
                    onClick={setPhotos}
                    className={`flex  justify-center items-center cursor-pointer buttons-font px-2 !pb-1 ${
                        condition === 4 ? 'add-border !text-black' : ''
                    }`}
                >
                    Workplace Photos
                </div>

                <div
                    onClick={showBranchLocations}
                    className={`flex justify-center items-center cursor-pointer buttons-font px-2 !pb-1  ${
                        condition === 5 ? 'add-border !text-black' : ''
                    }`}
                >
                    Branch Locations
                </div>
            </div>
        </div>
    );
};

export default BusinessBar;
