import React from 'react';
import { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { BUSINESS_ID, generateRandomProfileLink, TOAST_TYPE_ERROR } from 'utills/globalVars';
import InfiniteScrollComp from '../InfiniteScrollComp';
import { SmallLoader } from 'components/common/SmallLoader';
import { CompoboxInput } from 'components/molecules/CompoboxInput';
import { generateId } from 'utills/uid';
import { PrimaryLabel } from '../typography/labels/PrimaryLabel';
import { validateEmailValue } from 'utills/dataValidation';
import { toastHandler } from 'responseHanlder';
import { useDispatch } from 'react-redux';
import { viewBusinessCustomerInfoThunk } from 'store/client';

const MailerInput = ({
    defaultSelected,
    defaultDataLoadType = 'db',
    fetchDataFunction,
    inlineTitle,
    showNameOnly,
    removePreviousSelected,
    onChange,
    targetProperty,
    placeholder,
    sx,
    limit,
    hideSelectedValues,
    forceUpdate,
    disabled,
    onRemove,
    inputsx,
    inlineTitleSx,
    allowCustomEmails,
    preventDuplicateItems,
    handleDataWithCustomValidators
}) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedItems, setSelectedItems] = useState([]);

    const [query, setQuery] = useState(null);
    const [page, setPage] = useState(1);
    const [listData, setListData] = useState(null);
    const [hasMoreContent, setHasMoreContent] = useState(true);

    useEffect(() => {
        getAllData();
    }, [page, forceUpdate]);

    useEffect(() => {
        if (query !== null) {
            reloadDataPage();
        }
    }, [query]);

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const getAllData = async (hasReset = false) => {
        if (query !== null) {
            const payload = {
                business_id,
                page: page,
                page_size: 20,
                search_term: query
            };
            setHasMoreContent(true);

            try {
                const response = await fetchDataFunction(payload);
                if (response?.payload) {
                    const cloneData = [...(!hasReset ? listData || [] : []), ...response.payload.rows];
                    setListData(cloneData);
                    setHasMoreContent(cloneData?.length >= response.payload.count ? false : true);
                }
            } catch (error) {
                console.error('Error fetching businesses:', error);
            }
        }
    };

    useEffect(() => {
        if (defaultSelected) {
            handleDefaultSelect(defaultSelected);
        }
    }, [defaultSelected]);

    const handleDefaultSelect = async (defaultSelected = '') => {
        if (defaultSelected !== '') {
            if (defaultDataLoadType === 'db') {
                const selectedObject = await fetchCustomer(defaultSelected);
                if (selectedObject.payload) {
                    const dummyState = [...(removePreviousSelected ? [] : selectedItems), selectedObject.payload];
                    setSelectedItems(dummyState);

                    setQuery('');
                }
            }
            if (defaultDataLoadType === 'custom') {
                setSelectedItems(defaultSelected);

                setQuery('');
            }
        } else {
            setSelectedItems([]);

            setQuery('');
        }
    };
    const fetchCustomer = async (id) => {
        const payload = {
            business_client_id: id
        };
        return await dispatch(viewBusinessCustomerInfoThunk(payload));
    };

    const reloadDataPage = () => {
        setPage(1);
        getAllData(true);
        setListData(null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace' && query === '' && selectedItems?.length > 0 && event?.target?.value === '') {
            event.preventDefault();
            const updatedState = selectedItems.slice(0, selectedItems?.length - 1);
            setSelectedItems(updatedState);
            onChange(updatedState);
        }

        if (event.key === 'Enter') {
            if (allowCustomEmails && event?.target?.value !== '' && (listData === null || listData?.length === 0)) {
                if (!validateEmailValue(event?.target?.value)) {
                    return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
                }
                const customObjectEntry = {
                    [targetProperty]: generateId(),
                    display_name: event?.target?.value,
                    email: event?.target?.value
                };
                addItemToList(customObjectEntry);
            }
        }
    };

    const getName = (obj) => {
        return obj?.first_name && obj?.first_name !== '' ? obj?.first_name + ' ' + obj?.last_name : obj?.display_name;
    };

    const addItemToList = (item) => {
        if (preventDuplicateItems) {
            const isExist = selectedItems?.find(
                (prevObj) =>
                    prevObj?.[targetProperty] === item?.[targetProperty] || prevObj?.['email'] === item?.['email']
            );
            if (isExist) {
                return toastHandler(`Item Already Selected `, TOAST_TYPE_ERROR);
            }
        }

        if (handleDataWithCustomValidators) {
            const isValidate = handleDataWithCustomValidators(item);
            if (!isValidate) {
                setQuery('');
                return false;
            }
        }

        const updatedItems = hideSelectedValues ? [item] : [...selectedItems, item];
        setSelectedItems(updatedItems);
        onChange(updatedItems);
        setQuery('');
    };

    return (
        <Combobox
            as="div"
            value={selectedItems}
            onChange={(item) => {
                addItemToList(item);
            }}
        >
            <div className="relative default-forms">
                <div
                    className={cn(
                        'flex flex-wrap !rounded-xl border-0 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 px-2 mt-2 overflow-hidden',
                        sx || '',
                        selectedItems?.length > 0 && !showNameOnly ? '' : ''
                    )}
                >
                    <div className="flex-1 flex flex-wrap gap-2 py-1">
                        {inlineTitle && (
                            <PrimaryLabel
                                sx={cn(
                                    '!flex items-center py-2 text-md font-medium text-gray-800 pl-2 !w-auto',
                                    inlineTitleSx
                                )}
                            >
                                {inlineTitle}
                            </PrimaryLabel>
                        )}
                        {selectedItems?.length > 0 &&
                            selectedItems?.map((selectedItem, index) => (
                                <div className="flex items-center" key={'index' + generateId() + index}>
                                    <div
                                        className={cn(
                                            'flex w-fit items-center rounded-lg px-2 mr-1 flex-nowrap py-1.5',
                                            disabled ? 'bg-gray-600 opacity-75' : 'bg-third'
                                        )}
                                    >
                                        <div className="flex items-center">
                                            <div className="flex items-center text-white text-sm">
                                                <div className="flex flex-col gap-2 justify-center">
                                                    <div className="flex items-center rounded-lg w-full justify-between">
                                                        <div className="flex items-center w-full">
                                                            <DefaultProfileViewer
                                                                width={25}
                                                                height={25}
                                                                image={
                                                                    selectedItem?.image ||
                                                                    generateRandomProfileLink(getName(selectedItem))
                                                                }
                                                                name={getName(selectedItem)}
                                                            />
                                                            <div className="w-full">
                                                                <span className="ml-1 text-white text-sm">
                                                                    {selectedItem?.email}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {!disabled && (
                                                            <button
                                                                type="button"
                                                                tabIndex={-1}
                                                                data-headlessui-focus-guard="true"
                                                                className="ml-2 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                onClick={() => {
                                                                    if (onRemove) {
                                                                        const getDeletedObj = selectedItems.find(
                                                                            (prevItem) =>
                                                                                prevItem?.[targetProperty] ===
                                                                                selectedItem?.[targetProperty]
                                                                        );
                                                                        onRemove(getDeletedObj);
                                                                    }

                                                                    const updatedState = selectedItems.filter(
                                                                        (prevItem) =>
                                                                            prevItem?.[targetProperty] !==
                                                                            selectedItem?.[targetProperty]
                                                                    );

                                                                    setSelectedItems(updatedState);
                                                                    onChange(updatedState);
                                                                }}
                                                            >
                                                                <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        {selectedItems?.length !== limit && (
                            <div className="flex flex-1 min-w-[150px] center items-center">
                                <CompoboxInput
                                    atChange={(event) => {
                                        setQuery(event?.target?.value || '');
                                    }}
                                    placeholder={placeholder}
                                    selectedItems={selectedItems}
                                    inputsx={cn(
                                        ' !py-0 !pr-0',
                                        selectedItems?.length > 0 ? ' !h-auto ' : ' !h-[2.5rem] ',
                                        inputsx ? inputsx : ''
                                    )}
                                    onKeyDown={handleKeyDown}
                                    debounceTime={500}
                                    focusOnValues
                                    query={query}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <InfiniteScrollComp
                        height={215}
                        next={handlePageChange}
                        dataLength={(listData?.length === 0 ? (hasMoreContent ? 1 : 0) : listData?.length) || 0}
                        hasMore={listData?.length !== 0 ? hasMoreContent : false}
                        loader={
                            <div className="flex items-center justify-center py-2">
                                <SmallLoader scale={125} />
                            </div>
                        }
                    >
                        {listData && listData?.length > 0 && (
                            <>
                                {listData.map((obj, ind) => (
                                    <div key={'item_fileter1' + obj.id + ind}>
                                        <Combobox.Option
                                            value={obj}
                                            className={(props) =>
                                                cn(
                                                    'relative cursor-default select-none py-2.5 pl-3 pr-9 border-y border-gray-100',
                                                    props?.active ? 'bg-third !text-white' : 'text-gray-900'
                                                )
                                            }
                                        >
                                            <div className="flex items-center">
                                                <DefaultProfileViewer
                                                    image={obj?.image || generateRandomProfileLink(getName(obj))}
                                                    name={getName(obj)}
                                                />
                                                <div className="flex flex-col flex-1">
                                                    <span className={cn('ml-3 truncate')}>{getName(obj)}</span>
                                                    <span className={cn('ml-3 truncate')}>{obj?.email || ''}</span>
                                                </div>
                                            </div>
                                        </Combobox.Option>
                                    </div>
                                ))}
                            </>
                        )}
                    </InfiniteScrollComp>
                </Combobox.Options>
            </div>
        </Combobox>
    );
};

export default MailerInput;
