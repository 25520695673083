import dayjs from 'dayjs';
import isValidABN from 'is-valid-abn';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from './globalVars';
import isValidACN from 'is-valid-acn';

export const validateAbn = (abn) => {
    if (abn) {
        const abnNumber = abn.replace(/\s/g, '');
        const updatedABN = abnNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
        if (!isValidABN(updatedABN)) {
            return false;
        } else {
            return true;
        }
    }
};

export const validateBSB = (bsb) => {
    if (bsb.length < 6) {
        return false;
    } else {
        return true;
    }
};
export const validateStartEndDate = (start, end) => {
    const format = 'DD-MM-YYYY';

    const isStartFormatted = dayjs(start, format, true).isValid();
    const isEndFormatted = dayjs(end, format, true).isValid();

    const parsedStart = isStartFormatted ? dayjs(start, format) : dayjs(start);
    const parsedEnd = isEndFormatted ? dayjs(end, format) : dayjs(end);

    if (parsedStart && parsedStart?.isAfter(parsedEnd)) {
        return false;
    }

    return true;
};
export const validatePhoneNumber = (phone) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phone);

    if (!parsedPhoneNumber) {
        toastHandler('Invalid phone number', TOAST_TYPE_ERROR);
        return false;
    }

    if (!parsedPhoneNumber.isValid()) {
        toastHandler('Phone number is not valid for the given country', TOAST_TYPE_ERROR);
        return false;
    }

    if (parsedPhoneNumber.countryCallingCode.startsWith('0')) {
        toastHandler('Country code should not start with 0', TOAST_TYPE_ERROR);
        return false;
    }

    const region = parsedPhoneNumber.country;
    const isValidForRegion = parsedPhoneNumber.isValid() && parsedPhoneNumber.country === region;

    if (!isValidForRegion) {
        toastHandler(`Phone number is not valid for the region ${region}`, TOAST_TYPE_ERROR);
        return false;
    }

    return true;
};

export const validateACN = (acn) => {
    if (acn?.length < 1) {
        return false;
    }
    if (!isValidACN(acn)) {
        return false;
    }

    return true;
};
