import React from 'react';
import './css/taskDetail.css';
import { BlackLeftIcon } from 'utills/svgs/BlackLeftIcon';
import { generateId } from 'utills/uid';
import { BUSINESS_ID, TOAST_TYPE_ERROR, completedTaskStatusId } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import { updateTaskThunk } from 'store/workspace/workspaceTasks';
import { SideTimes } from 'utills/svgs/SideTimes';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
export const MoveCard = ({ toggleModal, data, handleTasksUpdate, task_id, toggleTaskDetails, task }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();

    const handleUpdateTask = (task_status_id) => {
        if (task_status_id === completedTaskStatusId) {
            let hasIncompleteItem = false;
            if (task?.checklists.length > 0) {
                for (const checkList of task?.checklists || []) {
                    const hasFalse = checkList?.checkListItems?.some((item) => item.is_completed === false);
                    if (hasFalse) {
                        hasIncompleteItem = true;
                        break;
                    }
                }
                if (hasIncompleteItem) {
                    toastHandler('Mark All Checklist', TOAST_TYPE_ERROR);
                    return false;
                }
            }
        }

        const payload = {
            task_id,
            task_status_id,
            business_id
        };

        dispatch(setLoader(true));
        dispatch(updateTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleTaskDetails();
                    handleTasksUpdate();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="moveModal rounded-xl shadow-lg  p-3">
            <div className="flex justify-between items-center mb-5">
                <span className="table-title font-fira">Move To</span>
                <span onClick={toggleModal} className="cursor-pointer">
                    <SideTimes />
                </span>
            </div>
            {data &&
                data?.map((status) => (
                    <div
                        onClick={() => handleUpdateTask(status?.task_status_id)}
                        className="flex items-center !px-5 !py-3 !rounded-xl justify-between gap-16 child mt-2 cursor-pointer"
                        key={generateId()}
                    >
                        <span>
                            <label className="buttons-font">{status.label}</label>
                        </span>
                        <span>
                            <BlackLeftIcon />
                        </span>
                    </div>
                ))}
        </div>
    );
};
