//email validator
export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

//validate website links
export function validateWebLink(link) {
    const webLinkRegex = /^(http|https):\/\/([\w.-]+)(\.[\w.-]+)([\w\-.~:/?#[\]@!$&'()*+,;=]*)?$/;
    return link === '' || webLinkRegex.test(link);
}
//passwor match validation
export const validatePasswords = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        return false;
    } else {
        return true;
    }
};
//aus phone number validation
export function validateAustralianPhoneNumber(phoneNumber) {
    return phoneNumber.length === 12;
}

//ABN number validation
export const abnValidation = (abn) => {
    if (abn.length !== 11) {
        return false;
    } else {
        return true;
    }
};

//validate account number
export function validateAccountNumber(accountNumber) {
    const sanitizedAccountNumber = accountNumber.replace(/\s/g, '');
    const isValidLength = /^\d{1,10}$/.test(sanitizedAccountNumber);
    return isValidLength;
}

//validate CVV
export function isValidCVV(cvv) {
    const cleanedCVV = cvv.replace(/\D/g, '');

    return /^\d{3,4}$/.test(cleanedCVV);
}

//text input validation
export function containsNumber(input) {
    return /\d/.test(input);
}

export function hasMinimumLength(password) {
    return password.length >= 8;
}
export function hasLowercaseLetter(password) {
    var lowercaseRegex = /^(?=.*[a-z])/;
    return lowercaseRegex.test(password);
}
export function hasUppercaseLetter(password) {
    var uppercaseRegex = /^(?=.*[A-Z])/;
    return uppercaseRegex.test(password);
}
export function hasNumber(password) {
    var numberRegex = /^(?=.*\d)/;
    return numberRegex.test(password);
}
export function hasSpecialCharacter(password) {
    var specialCharRegex = /^(?=.*[!@#$%^&*()\-_=+{}[\]|\\:;'"<>?,./])/;
    return specialCharRegex.test(password);
}

export function getPasswordProficiency(password) {
    var lengthRegex = /^.{8,}$/;
    var lowercaseRegex = /^(?=.*[a-z])/;
    var uppercaseRegex = /^(?=.*[A-Z])/;
    var numberRegex = /^(?=.*\d)/;
    var specialCharRegex = /^(?=.*[!@#$%^&*()\-_=+{}[\]|\\:;'"<>?,./])/;

    if (!lengthRegex.test(password) || !lowercaseRegex.test(password) || !uppercaseRegex.test(password)) {
        return 'weak';
    } else if (
        lengthRegex.test(password) &&
        lowercaseRegex.test(password) &&
        uppercaseRegex.test(password) &&
        !numberRegex.test(password)
    ) {
        return 'medium';
    } else if (
        lengthRegex.test(password) &&
        lowercaseRegex.test(password) &&
        uppercaseRegex.test(password) &&
        numberRegex.test(password) &&
        specialCharRegex.test(password)
    ) {
        return 'strong';
    }
}

export const validateTextField = (input) => {
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(input);
};

export const TextValidation = (input) => {
    if (!/^[^\d!@#$%^&*(),.?":{}|<>0-9]*$/.test(input)) {
        return true;
    } else {
        return false;
    }
};

export function containsOnlyIntegers(input) {
    const integerRegex = /^\d+$/;
    return integerRegex.test(input);
}
export function decimalToInteger(decimalNumber) {
    return parseInt(decimalNumber, 10);
}

/**
 * Converts a JSON object into FormData
 * @param {Object} jsonObject - The JSON object to convert
 * @param {FormData} [formData] - Optional FormData instance for recursive calls
 * @param {String} [parentKey] - Optional parent key for nested objects
 * @returns {FormData} - The resulting FormData object
 */
export const jsonToFormData = (jsonObject, formData = new FormData(), parentKey = '') => {
    if (typeof jsonObject !== 'object' || jsonObject === null) {
        throw new Error('Input must be a JSON object');
    }

    Object.keys(jsonObject).forEach((key) => {
        const value = jsonObject[key];

        const formKey = parentKey ? `${parentKey}` : key;

        if (value instanceof File) {
            formData.append(formKey, value);
        } else if (typeof value === 'object' && value !== null && !(value instanceof Date)) {
            jsonToFormData(value, formData, formKey);
        } else if (value instanceof Date) {
            formData.append(formKey, value.toISOString());
        } else if (Array.isArray(value)) {
            value.forEach((item, _index) => {
                const arrayKey = `${formKey}`;
                if (typeof item === 'object' && item !== null) {
                    jsonToFormData(item, formData, arrayKey);
                } else {
                    formData.append(arrayKey, item);
                }
            });
        } else {
            formData.append(formKey, value);
        }
    });

    return formData;
};
