import React from 'react';

export const EmailManagerIcon = ({ className }) => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="#222222"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M23.3712 23.3275H4.62866C3.64274 23.3263 2.69754 22.9342 2.00039 22.237C1.30324 21.5399 0.91107 20.5947 0.909912 19.6087V8.39124C0.91107 7.40532 1.30324 6.46011 2.00039 5.76296C2.69754 5.06581 3.64274 4.67364 4.62866 4.67249H23.3712C24.3571 4.67364 25.3023 5.06581 25.9994 5.76296C26.6966 6.46011 27.0888 7.40532 27.0899 8.39124V19.6087C27.0888 20.5947 26.6966 21.5399 25.9994 22.237C25.3023 22.9342 24.3571 23.3263 23.3712 23.3275ZM4.62866 5.98499C3.99048 5.98499 3.37845 6.2385 2.92719 6.68976C2.47593 7.14102 2.22241 7.75306 2.22241 8.39124V19.6087C2.22241 20.2469 2.47593 20.8589 2.92719 21.3102C3.37845 21.7615 3.99048 22.015 4.62866 22.015H23.3712C24.0093 22.015 24.6214 21.7615 25.0726 21.3102C25.5239 20.8589 25.7774 20.2469 25.7774 19.6087V8.39124C25.7774 7.75306 25.5239 7.14102 25.0726 6.68976C24.6214 6.2385 24.0093 5.98499 23.3712 5.98499H4.62866Z" />
            <path d="M14 16.66C13.1904 16.6613 12.4025 16.3986 11.7556 15.9119L1.54 8.18563C1.46782 8.13497 1.40659 8.07029 1.35997 7.99544C1.31335 7.92059 1.28229 7.83712 1.26864 7.75C1.255 7.66288 1.25906 7.5739 1.28057 7.48839C1.30208 7.40287 1.3406 7.32257 1.39384 7.25227C1.44707 7.18197 1.51393 7.12313 1.59042 7.07925C1.6669 7.03536 1.75145 7.00735 1.83901 6.99687C1.92656 6.98639 2.01533 6.99366 2.10001 7.01826C2.18469 7.04285 2.26355 7.08425 2.33187 7.14L12.5475 14.875C12.967 15.1882 13.4765 15.3574 14 15.3574C14.5235 15.3574 15.033 15.1882 15.4525 14.875L25.6681 7.14C25.7364 7.08425 25.8153 7.04285 25.9 7.01826C25.9847 6.99366 26.0734 6.98639 26.161 6.99687C26.2485 7.00735 26.3331 7.03536 26.4096 7.07925C26.4861 7.12313 26.5529 7.18197 26.6062 7.25227C26.6594 7.32257 26.6979 7.40287 26.7194 7.48839C26.7409 7.5739 26.745 7.66288 26.7314 7.75C26.7177 7.83712 26.6866 7.92059 26.64 7.99544C26.5934 8.07029 26.5322 8.13497 26.46 8.18563L16.2444 15.9119C15.5975 16.3986 14.8096 16.6613 14 16.66Z" />
            <path d="M1.82445 21.4987C1.69135 21.4989 1.56134 21.4587 1.45166 21.3832C1.34199 21.3078 1.25784 21.2009 1.21038 21.0765C1.16292 20.9522 1.15439 20.8163 1.18592 20.687C1.21746 20.5577 1.28757 20.441 1.38695 20.3525L9.43695 13.1731C9.56691 13.0571 9.73763 12.9974 9.91157 13.0073C10.0855 13.0171 10.2484 13.0957 10.3645 13.2256C10.4805 13.3556 10.5401 13.5263 10.5303 13.7002C10.5204 13.8742 10.4419 14.0371 10.312 14.1531L2.26195 21.3325C2.1424 21.4413 1.98607 21.5007 1.82445 21.4987ZM26.1757 21.4987C26.0141 21.5007 25.8577 21.4413 25.7382 21.3325L17.6882 14.1531C17.5582 14.0371 17.4797 13.8742 17.4699 13.7002C17.46 13.5263 17.5197 13.3556 17.6357 13.2256C17.7517 13.0957 17.9146 13.0171 18.0886 13.0073C18.2625 12.9974 18.4332 13.0571 18.5632 13.1731L26.6088 20.3525C26.7082 20.441 26.7783 20.5577 26.8099 20.687C26.8414 20.8163 26.8329 20.9522 26.7854 21.0765C26.7379 21.2009 26.6538 21.3078 26.5441 21.3832C26.4344 21.4587 26.3044 21.4989 26.1713 21.4987H26.1757Z" />
        </svg>
    );
};
