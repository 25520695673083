import React, { useEffect, useRef, useState } from 'react';
import { PLACES_API_KEY } from 'utills/globalVars';

const loadGooglePlacesScript = () => {
    return new Promise((resolve, reject) => {
        const existingScript = document.getElementById('google-places-script');
        if (existingScript) {
            resolve();
            return;
        }

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places`;
        script.id = 'google-places-script';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Google Maps script failed to load'));
    });
};

export const PickAddress = ({
    id,
    onSelect,
    setAddress,
    address,
    label = 'Address',
    placeholder = 'Select Location',
    required = false,
    disabled = false,
    name = '',
    classes = ''
}) => {
    const inputRef = useRef(null);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        loadGooglePlacesScript()
            .then(() => {
                if (inputRef.current && window.google && window.google.maps && !initialized) {
                    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
                        types: ['geocode'],
                        strictBounds: true
                    });

                    autocomplete.addListener('place_changed', () => {
                        const place = autocomplete.getPlace();
                        if (place && place.place_id && place.formatted_address) {
                            if (onSelect) {
                                onSelect(place);
                            }
                            setAddress(place.formatted_address);
                        }
                    });

                    setInitialized(true);
                }
            })
            .catch((error) => console.error(error));

        return () => {};
    }, [id, onSelect, setAddress, initialized]);

    const handleChange = (e) => {
        setAddress(e.target.value);
    };

    return (
        <div>
            <div className="default-forms">
                <label htmlFor={id}>{label}</label>
                <input
                    ref={inputRef}
                    value={address}
                    type="text"
                    id={id}
                    placeholder={placeholder}
                    className={`px-3 mt-2 ${classes} ${disabled ? '!bg-gray-200 !border-none !cursor-no-drop' : ''}`}
                    onChange={handleChange}
                    required={required}
                    disabled={disabled}
                    name={name}
                />
            </div>
        </div>
    );
};
