import React, { useState, useEffect } from 'react';
import './css/molecules.css';
import { Combobox } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';

export const CompoboxInput = ({
    atChange,
    placeholder,
    selectedItems,
    inputsx,
    onKeyDown,
    showDisplayValue,
    debounceTime = 1000,
    focusOnValues = false,
    query = null,
    displayCustomInputValue = null
}) => {
    const [inputValue, setInputValue] = useState('');
    const [text, setText] = useState('');

    useEffect(() => {
        const handleTypingStopped = () => {
            if (text) atChange(text);
        };
        const timeoutId = setTimeout(handleTypingStopped, debounceTime);
        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    useEffect(() => {
        if (query === '') {
            setInputValue('');
        }
    }, [query]);

    const handleChange = (e) => {
        setText(e);
        setInputValue(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setInputValue('');
        }

        if (onKeyDown) {
            onKeyDown(event);
        }
    };

    return (
        <Combobox.Input
            className={cn(
                'comboboxInput bg-transparent !pl-1 pr-12 !m-[0px] !rounded-xl !border-0 focus:border-0 placeholder-[#979797] placeholder:text-[0.9rem] placeholder:font-[400] !font-[400] !text-[0.9rem] !ring-0 !focus:ring-[0px]',
                selectedItems.length > 0 && !showDisplayValue ? 'max-w-[150px]' : '',
                inputsx ? inputsx : 'text-gray-900'
            )}
            onChange={(event) => handleChange(event)}
            onKeyDown={handleKeyDown}
            placeholder={(selectedItems.length === 0 && placeholder) || ''}
            autoComplete="off"
            {...(focusOnValues
                ? {
                      value: inputValue
                  }
                : {
                      displayValue: (person) => {
                          return showDisplayValue && person
                              ? displayCustomInputValue
                                  ? displayCustomInputValue(person)
                                  : person
                              : '';
                      }
                  })}
        />
    );
};
