import React from 'react';
import './css/business.css';
import { useDispatch, useSelector } from 'react-redux';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { ProfileSkeleton } from './profile-skeleton/ProfileSkeleton';
import { EnvelopeIcon } from 'utills/svgs/EnvelopeIcon';
import { Telephone } from 'utills/svgs/Telephone';
import { LocationPin } from 'utills/svgs/LocationPin';
import { ErrorIcon } from 'utills/svgs/ErrorIcon';
import { setSideLoader } from 'store/global/globalReducer';
import { deactivateUserThunk } from 'store/auth/slices';
import { ACCESS_TOKEN, BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, REFRESH_TOKEN } from 'utills/globalVars';
import { useNavigate } from 'react-router-dom';
import { BgPencilIcon } from 'utills/svgs/BgPencilIcon';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { useState } from 'react';
import ConfirmationModal from 'utills/confirmationModal';
import { toastHandler } from 'responseHanlder';
import useRoleGuard from 'Hooks/useRoleGuard';
import { HashtagIcon } from '@heroicons/react/20/solid';
import { GlobeIcon } from 'utills/svgs/GlobeIcon';
import { TextInfoIcon } from 'utills/svgs/TextInfoIcon';

const Business = ({ toggleUpdateBusiness }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isZoomed, setIsZoomed] = useState(false);
    const { businessLogo } = useSelector((state) => state.global);
    const { isLoading } = useSelector((state) => state.global);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const { data: loginData } = useSelector((state) => state.login);
    const { hasPermissions } = useRoleGuard();

    const { data: businessData } = useSelector((state) => state.getBusinessprofile);

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        dispatch(setSideLoader(true));
        dispatch(deactivateUserThunk())
            .then((response) => {
                if (response.payload) {
                    localStorage.setItem(ACCESS_TOKEN, '');
                    localStorage.setItem(REFRESH_TOKEN, '');
                    localStorage.setItem(BUSINESS_ID, '');
                    navigate('/signup');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    const deleteBusiness = () => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const editBusiness = () => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            toggleUpdateBusiness();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    return (
        <div className="md:mx-10 mx-5 mt-5 py-3 relativw">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <div className="text-xl font-semibold">Businesses</div>
            <div className="business-card relative business-infor-container border mt-2 shadow !rounded-xl py-3">
                {isLoading ? (
                    <ProfileSkeleton numOfSlides={5} />
                ) : (
                    <>
                        <div className=" absolute z-10 right-5 top-5 flex gap-2">
                            <div onClick={editBusiness} className="flex justify-center items-center cursor-pointer">
                                <BgPencilIcon />
                            </div>
                            {!loginData?.is_employee && (
                                <div onClick={deleteBusiness} className="cursor-pointer">
                                    <BgDeleteIcon />
                                </div>
                            )}
                        </div>

                        <div className="flex flex-wrap w-full items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8 py-4">
                            <div className="">
                                <div className=" business-infor-div relative !cursor-pointer">
                                    <div className="my-business-img-wrap">
                                        <img
                                            src={businessLogo ?? businessData?.logo}
                                            alt="business"
                                            className="business-img-wrap"
                                            srcSet=""
                                            onClick={() => businessData?.logo && setIsZoomed(true)}
                                        />
                                    </div>
                                </div>

                                {isZoomed && (
                                    <ZoomImage
                                        src={businessData?.logo}
                                        alt="Profile"
                                        onClose={() => setIsZoomed(false)}
                                    />
                                )}
                            </div>
                            <div className="order-last flex-1 flex w-full gap-x-8 buttons-font-lighter font-poppins leading-6 sm:order-none sm:w-auto  sm:pl-6 sm:leading-7">
                                <div className="business-infor-div2 relative">
                                    <div className="">
                                        <div className=" top-business ">
                                            <div className="business-title1 font-semibold xl-title">
                                                {businessData?.name ?? 'No business name'}
                                            </div>
                                        </div>
                                        <div className=" flex gap-5 md:gap-8 flex-wrap justify-start mt-5">
                                            <div className="flex gap-2 items-center">
                                                <div>
                                                    <EnvelopeIcon />
                                                </div>
                                                <div>{businessData?.email}</div>
                                            </div>

                                            <div className="flex gap-2 items-center">
                                                <div>
                                                    <Telephone />
                                                </div>
                                                <div>{businessData?.phone_number}</div>
                                            </div>

                                            <div className="flex items-center gap-2">
                                                <GlobeIcon className="!w-[22px] !h-[22px]" />
                                                <div>
                                                    <span className="text-blue-900 cursor-pointer">
                                                        <a
                                                            href={businessData?.website}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {businessData?.website}
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-2">
                                                <div>
                                                    <TextInfoIcon className="!w-[22px] !h-[22px]" />
                                                </div>
                                                <div>{businessData?.abn}</div>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div>
                                                    <HashtagIcon className="w-5" />
                                                </div>
                                                <div>{businessData?.tax_agent_number}</div>
                                            </div>

                                            <div className="flex items-center gap-2">
                                                <div>
                                                    <LocationPin className="!w-[22px] !h-[22px]" />
                                                </div>
                                                <div>
                                                    {businessData?.address ? (
                                                        <span>{businessData.address}</span>
                                                    ) : (
                                                        <span className="flex items-center gap-1">
                                                            {' '}
                                                            No address found <ErrorIcon />{' '}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>{' '}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Business;
