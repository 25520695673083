import AvatarGroup from 'components/atoms/AvatarGroup';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { usePagination } from 'Hooks/paginationHook/usePagination';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllJobsThunk } from 'store/JobManager';
import { BUSINESS_ID } from 'utills/globalVars';
import { formateDateOnly } from 'utills/moment';
import defaultfile from 'files/user.png';

const ClientProfileJobs = ({ user, jobsUpdated, setJobsUpdated }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { handlePageChange, getData, pageData, hasMoreContent } = usePagination({
        thunk: getAllJobsThunk,
        dataKey: 'list',
        extraPayload: {
            business_id,
            search_term: '',
            job_category: 'all_job',
            priority: '',
            status: '',
            job_type_id: '',
            business_client_id: user?.business_client_id ?? '',
            order_by__created_at: '',
            order_by__start_date: '',
            order_by__due_date: ''
        }
    });

    function MyComponent({ htmlContent }) {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: htmlContent.concat(htmlContent.length > 40 ? '...' : '')
                }}
            />
        );
    }

    useEffect(() => {
        if (jobsUpdated) {
            getData(true, 1);
            setJobsUpdated(false);
        }
    }, [jobsUpdated]);
    return (
        <div>
            <InfiniteScrollComp
                height={330}
                next={handlePageChange}
                dataLength={(pageData?.length === 0 ? (hasMoreContent ? 1 : 0) : pageData?.length) || 0}
                hasMore={pageData?.length !== 0 ? hasMoreContent : false}
                loader={<SkeletonTable columns={1} />}
            >
                <div className="relative">
                    <div className="mt-2">
                        <div className="flex flex-col gap-5">
                            {pageData ? (
                                pageData.length > 0 &&
                                pageData?.map((job, index) => (
                                    <Link key={index} to={`/job-details/${job?.job_id}`}>
                                        <div className="flex flex-col border hover:shadow-md ease-in duration-200 px-4 py-6 mx-2 rounded-xl cursor-pointer">
                                            <div className="flex w-full items-start justify-betweeen">
                                                <div className="flex-1 cursor-pointer">
                                                    <div className="flex items-center  gap-3">
                                                        <h3 className="text-md font-[500] font-fira flex-1 ">
                                                            {job?.title}
                                                        </h3>
                                                        <span className="p-1.5 text-third bg-[#b695f838] text-xs font-poppins !rounded-xl ">
                                                            Job Ref Num: {job?.ref_no}
                                                        </span>
                                                    </div>
                                                    <h3 className=" !text-[#666666] buttons-font-lighter font-poppins !font-normal">
                                                        <MyComponent
                                                            htmlContent={job?.description
                                                                .slice(0, 240)
                                                                ?.split(' ')
                                                                .map(
                                                                    (word) =>
                                                                        word.charAt(0).toUpperCase() + word.slice(1)
                                                                )
                                                                .join(' ')}
                                                        />
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 flex-wrap mt-5">
                                                <div className="small-text text-secondarybg  font-poppins flex flex-wrap gap-1 justify-start items-center font-normal break-all">
                                                    Clients:
                                                    <span className="ml-3 text-black small-text min-w-[100px]">
                                                        <AvatarGroup
                                                            list={job?.businessClients || []}
                                                            limit={2}
                                                            targetProperty={'image'}
                                                            onClick={() => {}}
                                                            sx="!min-w-10"
                                                        />
                                                    </span>
                                                </div>
                                                <div className="small-text text-secondarybg  font-poppins flex flex-wrap gap-1 justify-start items-center  font-normal break-all">
                                                    Job Type
                                                    <span className="text-black min-w-[100px]">
                                                        {job?.job_types_label}
                                                    </span>
                                                </div>
                                                <div className="small-text text-secondarybg  font-poppins  flex flex-wrap gap-1 justify-start items-center font-normal break-all">
                                                    Created at
                                                    <span className=" text-black small-text min-w-[100px]">
                                                        {formateDateOnly(job?.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSS')}
                                                    </span>
                                                </div>

                                                <div className="small-text text-secondarybg  font-poppins flex flex-wrap gap-1 justify-start items-center font-normal break-all ">
                                                    Manager:
                                                    <span className="text-black min-w-[100px]">
                                                        {job?.manager?.length > 0 ? (
                                                            <AvatarGroup
                                                                list={job?.manager}
                                                                limit={2}
                                                                targetProperty={'image'}
                                                                onClick={() => {}}
                                                                sx="!min-w-10"
                                                            />
                                                        ) : (
                                                            <DefaultProfileViewer
                                                                height="30px"
                                                                width="30px"
                                                                image={defaultfile}
                                                            />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <SkeletonTable columns={1} />
                            )}
                        </div>
                    </div>
                </div>
            </InfiniteScrollComp>
        </div>
    );
};

export default ClientProfileJobs;
