import React from 'react';

const SpamEmails = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_23867_5054)">
                <path
                    d="M9.99935 8.63542C11.2721 8.63542 12.3327 7.60511 12.3327 6.30208C12.3327 5.02936 11.3024 3.96875 9.99935 3.96875C8.72662 3.96875 7.66602 4.99905 7.66602 6.30208C7.66602 7.57481 8.72662 8.63542 9.99935 8.63542ZM11.7266 6.30208C11.7266 7.24148 10.9387 8.02936 9.99935 8.02936C9.63571 8.02936 9.30238 7.90814 9.02965 7.72633L11.4236 5.33239C11.6054 5.60511 11.7266 5.93845 11.7266 6.30208ZM9.99935 4.57481C10.363 4.57481 10.6963 4.69602 10.969 4.87784L8.57511 7.27178C8.39329 6.99905 8.27208 6.66572 8.27208 6.30208C8.27208 5.33239 9.05996 4.57481 9.99935 4.57481Z"
                    fill="#B695F8"
                    stroke="#B695F8"
                    strokeWidth="0.4"
                />
                <path
                    d="M18.9393 6.88045C18.9393 6.78954 18.909 6.72893 18.8484 6.66832L16.5757 4.3956V0.607718C16.5757 0.4259 16.4545 0.304688 16.2727 0.304688H6.6363C6.54539 0.304688 6.48479 0.334991 6.42418 0.395597L3.51509 3.30469C3.45449 3.36529 3.42418 3.4259 3.42418 3.51681V4.3956L1.15146 6.66832C1.09085 6.72893 1.06055 6.78954 1.06055 6.88045V18.6077C1.06055 19.2138 1.5454 19.7289 2.18176 19.7289H17.8484C18.4545 19.7289 18.9696 19.2441 18.9696 18.6077L18.9393 6.88045ZM18.3333 18.6683L12.7878 13.1229L18.3333 7.60772V18.6683ZM18.2121 6.88045L16.5757 8.48651V5.24408L18.2121 6.88045ZM15.9696 9.09257L9.99994 15.0623L4.03024 9.09257V3.81984H6.6363C6.81812 3.81984 6.93933 3.69863 6.93933 3.51681V0.910748H15.9696V9.09257ZM1.66661 18.6683V7.60772L7.21206 13.1229L1.66661 18.6683ZM4.45449 3.21378L6.33327 1.33499V3.21378H4.45449ZM3.42418 5.24408V8.48651L1.78782 6.85014L3.42418 5.24408ZM2.18176 19.0926H2.12115L7.66661 13.5774L9.81812 15.7289C9.87873 15.7895 9.96964 15.8198 10.0302 15.8198C10.1212 15.8198 10.1818 15.7895 10.2424 15.7289L12.3939 13.5774L17.909 19.0926H2.18176Z"
                    fill="#B695F8"
                    stroke="#B695F8"
                    strokeWidth="0.4"
                />
            </g>
            <defs>
                <clipPath id="clip0_23867_5054">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SpamEmails;
