import React from 'react';

export const OpenEye = () => {
    return (
        <div>
            <svg width="22" height="27" viewBox="0 0 30 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 6.30221C16.085 6.30221 17.1255 6.74486 17.8927 7.53279C18.6599 8.32072 19.0909 9.38938 19.0909 10.5037C19.0909 11.618 18.6599 12.6866 17.8927 13.4746C17.1255 14.2625 16.085 14.7052 15 14.7052C13.915 14.7052 12.8745 14.2625 12.1073 13.4746C11.3401 12.6866 10.9091 11.618 10.9091 10.5037C10.9091 9.38938 11.3401 8.32072 12.1073 7.53279C12.8745 6.74486 13.915 6.30221 15 6.30221ZM15 0C21.6273 0 27.5727 4.20147 30 10.5037C26.7409 19.0187 17.3864 23.1921 9.09546 19.845C4.93636 18.1644 1.63636 14.7892 0 10.5037C2.42727 4.20147 8.37273 0 15 0ZM2.97273 10.5037C6.21818 17.3241 14.25 20.1531 20.8909 16.8059C23.5614 15.4638 25.7205 13.2463 27.0273 10.5037C23.7818 3.68329 15.75 0.854299 9.10909 4.20147C6.43863 5.5436 4.27954 7.76105 2.97273 10.5037Z"
                    fill="#979797"
                />
            </svg>
        </div>
    );
};
