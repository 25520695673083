import React from 'react';
import { UserProfileChip } from './UserProfileChip';
import { ArrowDownDim } from 'utills/svgs/ArrowDownDim';
import { SelectModal } from './SelectModal';
import './css/molecules.css';
export const SelectWithprofileChip = ({
    data,
    onSelect,
    toggleModal,
    showModal,
    _selectedSignee,
    signee,
    hideIcon
}) => {
    return (
        <div>
            <div
                onClick={() => toggleModal()}
                className="selected-signee-container relative  flex items-center gap-2 flex-wrap p-2"
            >
                {!signee && <div className="text-size3"> Please select signee&apos;s </div>}

                <div>
                    <UserProfileChip
                        image={signee?.image}
                        name={signee?.name}
                        email={signee?.email}
                        colorScheme={signee?.colors}
                        hideIcon={hideIcon}
                    />
                </div>
                <span className="default-arrow-icon">
                    <ArrowDownDim />
                </span>
                {showModal && (
                    <div>
                        <SelectModal
                            users={data}
                            atClick={(user) => {
                                onSelect(user?.id);
                            }}
                            toggleModal={toggleModal}
                            isSingleSelect={true}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
