import React from 'react';

export const FitIcon = () => {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1503_7906)">
                    <path
                        d="M3.57812 0.0906744C3.25625 0.243799 3.05625 0.512549 3 0.86255C2.98125 0.965674 2.96875 1.26255 2.96875 1.52192V2.00005H3.51562H4.0625V1.54692V1.0938H9.46875H14.875V6.48442V11.875H14.4688H14.0625V12.425V12.9719L14.6344 12.9625C15.1719 12.9532 15.2125 12.95 15.3781 12.8719C15.5938 12.7688 15.7656 12.5969 15.8719 12.375L15.9531 12.2032L15.9625 6.54692C15.9688 2.17505 15.9625 0.859425 15.9344 0.75005C15.8469 0.434424 15.6156 0.17505 15.3125 0.0594244C15.1594 0.00317478 14.9719 4.95911e-05 9.45937 4.95911e-05H3.76562L3.57812 0.0906744Z"
                        fill="white"
                        stroke="white"
                    />
                    <path
                        d="M0.684246 3.10625C0.402996 3.22188 0.243621 3.37187 0.115496 3.64687L0.0467459 3.79688L0.0373709 9.45312C0.0311209 13.825 0.0373709 15.1406 0.0654959 15.25C0.152996 15.5656 0.384246 15.825 0.687371 15.9406C0.840496 15.9969 1.028 16 6.5405 16H12.2342L12.4217 15.9094C12.7499 15.7531 12.9467 15.4844 13.003 15.1187C13.0217 14.9937 13.0311 12.8781 13.0249 9.375L13.0155 3.82812L12.9249 3.63125C12.8124 3.39062 12.5967 3.18437 12.3561 3.09688C12.1874 3.03125 12.1592 3.03125 6.52175 3.03125H0.859246L0.684246 3.10625ZM11.9374 9.51562V14.9062H6.53112H1.12487V9.51562V4.125H6.53112H11.9374V9.51562Z"
                        fill="white"
                        stroke="white"
                    />
                    <path
                        d="M8.49062 4.77497C8.37813 4.89997 8.41563 4.96559 8.8875 5.43434L9.34063 5.88434L6.13125 9.11247L2.92188 12.3406L2.46562 11.8875C1.99063 11.4156 1.93125 11.3812 1.8125 11.5C1.75313 11.5593 1.75 11.6031 1.75 12.8718C1.75 13.9812 1.75625 14.1906 1.79688 14.2281C1.85938 14.2937 2.46562 14.3187 3.5875 14.3062C4.45312 14.2968 4.50625 14.2937 4.55 14.2375C4.65 14.1 4.6125 14.0375 4.15 13.575L3.70938 13.1281L5.68125 11.1343C6.76562 10.0375 8.20937 8.58434 8.8875 7.90622L10.125 6.67184L10.5719 7.11872C11.0406 7.58747 11.1156 7.63122 11.2281 7.50622C11.275 7.45309 11.2812 7.31247 11.2812 6.13434C11.2812 4.90309 11.2781 4.81872 11.225 4.77184C11.1719 4.72497 11.0312 4.71872 9.85313 4.71872C8.62188 4.71872 8.5375 4.72184 8.49062 4.77497Z"
                        fill="white"
                        stroke="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1503_7906">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
