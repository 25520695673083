import React, { useEffect, useRef } from 'react';
import './css/molecules.css';
import { DefaultProfileViewer } from './DefaultProfileViewer';
import { motion } from 'framer-motion';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';
export const SelectModal = ({ users, atClick, selectedUsers, toggleModal, isSingleSelect }) => {
    const modalRef = useRef(null);

    const checkExistance = (user_id) => {
        if (isSingleSelect) {
            return false;
        }
        const findUser = selectedUsers?.find((signee) => signee?.user_id === user_id);
        if (findUser) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                toggleModal(false);
            }
        }

        document?.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);
    return (
        <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: 'just', duration: 0.2 }}
            className="select-modal-wrapper shadow-custom rounded-md "
            ref={modalRef}
        >
            {users?.map((user, index) => (
                <div key={'user' + index}>
                    {!checkExistance(user?.user_id) && (
                        <div
                            onClick={() => atClick(user)}
                            style={{
                                backgroundColor: checkExistance(user?.user_id) ? '#f8ebeb' : '#fff'
                            }}
                            className="flex gap-2 items-center select-modal-user px-5 py-5 rounded-md"
                        >
                            <DefaultProfileViewer
                                image={user?.image}
                                name={isSingleSelect ? user?.name : user?.first_name}
                            />
                            <div>
                                <TooltipChipWrap
                                    sx={'!bg-gray-100 !text-gray-800 !text-[10px] !-transform-y-[20px]'}
                                    hideChip
                                    title={isSingleSelect ? user?.name : user?.first_name || 'No Name'}
                                    placement="top"
                                    groupSx={'w-full'}
                                >
                                    <div className="primary-fonts  text-md">
                                        {isSingleSelect
                                            ? user?.name
                                            : user?.first_name && user?.first_name?.slice(0, 15)}
                                    </div>
                                </TooltipChipWrap>
                                <TooltipChipWrap
                                    sx={'!bg-gray-100 !text-gray-800 !text-[10px] !-transform-y-[20px]'}
                                    hideChip
                                    groupSx={'w-full'}
                                    title={user?.email || 'No Email'}
                                    placement="bottom"
                                >
                                    <div className="user-profile-modal-email text-sm">
                                        {user?.email && user?.email?.slice(-30)}
                                    </div>
                                </TooltipChipWrap>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </motion.div>
    );
};
