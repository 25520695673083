/* eslint-disable react-hooks/exhaustive-deps */
import './css/apps.css';
import AppsJumbo from './AppsJumbo';
import { useState } from 'react';
import social from '../../../files/link.png';
import React, { useEffect } from 'react';
import { handleEditApp, setLoader } from 'store/global/globalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAppsThunk, updateFavAppThunk } from 'store/workspace/workspaceApps';

import { AppSkeleton } from './app-skeleton/AppSkeleton';
import { NoApp } from './NoApp';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { toastHandler } from 'responseHanlder';
import { SearchInput } from 'components/molecules/SearchInput';
import useRoleGuard from 'Hooks/useRoleGuard';
import { EditPencilIcon } from 'utills/svgs/EditPencilIcon';
import LazyLoad from 'react-lazyload';
import { Truncate } from 'components/molecules/Truncate';
import StarIcon from 'utills/svgs/StarIcon';

const WorkplaceApps = ({ toggleShowAddApp, appsUpdated, toggleEditApp }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);
    const { profileUpdating } = useSelector((state) => state.global);
    const [appUpdated, setAppUpdated] = useState(false);
    const [delIds, setDelIds] = useState([]);
    const [apps, setApps] = useState([]);
    const { hasPermissions } = useRoleGuard();

    const [search_term, setSearch_term] = useState('');

    const toggleAppUpdate = () => {
        setAppUpdated(!appUpdated);
    };

    useEffect(() => {
        const payload = { page: 1, page_size: 30, search_term: search_term };
        getApplications(payload);
    }, [appUpdated, profileUpdating, appsUpdated, search_term]);

    const getApplications = (payload) => {
        dispatch(setLoader(true));
        dispatch(getAllAppsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setApps(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const delHanlder = (e, app_id, createdBy) => {
        if (hasPermissions('Apps', ['write', 'admin'], createdBy)) {
            const checked = e.target.checked;
            if (checked) {
                setDelIds([...delIds, app_id]);
            } else {
                const remainingAppIdsToDelete = delIds.filter((id) => id !== app_id);
                setDelIds(remainingAppIdsToDelete);
            }
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    const handleOpenLink = (link) => {
        const url = link.startsWith('http') || link.startsWith('www') ? link : `http://www.${link}`;
        const windowFeatures = 'width=600,height=400';

        window.open(url, '_blank', windowFeatures);
    };

    const handleUpdate = (app) => {
        if (hasPermissions('Apps', ['write', 'admin'], app?.created_by)) {
            dispatch(handleEditApp(app));
            toggleEditApp();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const handleUpdateFav = (app) => {
        if (hasPermissions('Apps', ['write', 'admin'], app?.created_by)) {
            dispatch(
                updateFavAppThunk({
                    list: [
                        {
                            app_id: app.app_id,
                            is_favourite: (!app.is_favourite).toString()
                        }
                    ]
                })
            ).then((_response) => {
                const payload = { page: 1, page_size: 30, search_term: search_term };
                getApplications(payload);
            });
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    return (
        <div>
            <div>
                <AppsJumbo
                    toggleShowAddApp={toggleShowAddApp}
                    delIds={delIds}
                    setDelIds={setDelIds}
                    toggleAppUpdate={toggleAppUpdate}
                    apps={apps}
                />

                <div className="md:px-10 px-5 mb-5">
                    <div className="grid md:grid-cols-2 gap-3">
                        <div>
                            <SearchInput
                                width="300px"
                                value={search_term}
                                atChange={(e) => {
                                    setSearch_term(e.target.value);
                                }}
                                placeholder="Search"
                            />
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <AppSkeleton />
                ) : (
                    <div>
                        {apps?.rows?.length < 1 ? (
                            <NoApp />
                        ) : (
                            <>
                                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 md:mx-10 mx-5">
                                    {apps &&
                                        apps?.rows?.map((app, index) => (
                                            <div
                                                className="overflow-hidden bg-white rounded-xl shadow-md hover:shadow-lg hover:border hover:border-solid hover:border-third cursor-pointer"
                                                key={'application_' + index}
                                            >
                                                <div className="p-4">
                                                    <div className="flex items-center justify-between w-full">
                                                        <div
                                                            className="flex items-center gap-4 justify-start flex-wrap text-left pb-3"
                                                            onClick={() => handleOpenLink(app?.link)}
                                                        >
                                                            <LazyLoad>
                                                                <img
                                                                    src={
                                                                        app.image && app.image !== 'null'
                                                                            ? app.image
                                                                            : social
                                                                    }
                                                                    alt="file"
                                                                    className="app-img-card1 rounded-full bg-gray-50  min-w-[50px] h-[50px] xl:min-w-[100px] xl:h-[100px]"
                                                                />
                                                            </LazyLoad>
                                                            <div className="font-fira text-md lg:text-lg font-semibold flex px-2 break-all">
                                                                {app.name}
                                                            </div>
                                                        </div>
                                                        <div className="flex h-6 items-center gap-2 self-start">
                                                            <span
                                                                onClick={() => handleUpdateFav(app, index)}
                                                                className="cursor-pointer"
                                                            >
                                                                <StarIcon
                                                                    className={
                                                                        app?.is_favourite
                                                                            ? 'fill-third stroke-third'
                                                                            : ''
                                                                    }
                                                                />
                                                            </span>
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) =>
                                                                    delHanlder(e, app?.app_id, app?.created_by)
                                                                }
                                                                checked={delIds.includes(app.app_id)}
                                                                className="h-6 w-6 cursor-pointer rounded-full border-gray-300 text-third shadow-none focus:shadow-none focus:outline-transparent"
                                                            />
                                                            <span
                                                                onClick={() => handleUpdate(app)}
                                                                className="cursor-pointer"
                                                            >
                                                                <EditPencilIcon />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {app.description && (
                                                        <div className="text-gray-900 text-sm break-words">
                                                            <Truncate
                                                                index={index}
                                                                text={app.description}
                                                                maxLength={200}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default WorkplaceApps;
