import React from 'react';
import { cn } from 'utills/tailwindUtil';
import locksvg from '../../files/locksvg.svg';
import Slideover from 'components/atoms/SlideOver';

export const NoPermission = (props) => {
    if (props.slider) {
        return (
            <Slideover {...props.slider}>
                <div className={'p-10'}>
                    <div
                        className={cn(
                            ' flex justify-center items-center flex-col gap-5 bg-white rounded-xl shadow-md ',
                            props.classes
                        )}
                    >
                        <div>
                            <img src={locksvg} alt="no data" style={{ height: '100px', width: '100px' }} />
                        </div>
                        <div className="lg-title font-poppins">
                            {' '}
                            {props.message ?? "You don't have permission to view this page"}
                        </div>

                        <div className="text-secondarybg font-poppins table-title mx-5">
                            Please contact your administrator to get the access levels updated
                        </div>
                    </div>
                </div>
            </Slideover>
        );
    } else {
        return (
            <div className={'p-10'}>
                <div
                    className={cn(
                        ' flex justify-center items-center flex-col gap-5 bg-white rounded-xl shadow-md ',
                        props.classes
                    )}
                >
                    <div>
                        <img src={locksvg} alt="no data" style={{ height: '100px', width: '100px' }} />
                    </div>
                    <div className="lg-title font-poppins text-center mx-5">
                        {' '}
                        {props.message ?? "You don't have permission to view this page"}
                    </div>

                    <div className="text-secondarybg font-poppins table-title mx-5 text-center">
                        Please contact your administrator to get the access levels updated
                    </div>
                </div>
            </div>
        );
    }
};
