import React from 'react';
const OSOperations = ({ toggleAddStatus, _handleSearchTerm }) => {
    return (
        <div className="md:px-10 px-5 mt-2 ">
            <div className="flex justify-end flex-wrap items-center gap-3">
                <div>
                    <button
                        onClick={toggleAddStatus}
                        className="ann-btn font-fira buttons-font-lighter px-10 py-2  rounded-xl"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};
export default OSOperations;
