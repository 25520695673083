import { XMarkIcon } from '@heroicons/react/20/solid';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import React from 'react';
import { SecondaryParagraph } from '../typography/paragraphs/SecondaryParagraph';
import { PrimaryParagraph } from '../typography/paragraphs/PrimaryParagraph';
import { getProfileName } from 'utills/globalVars';
import { cn } from 'utills/tailwindUtil';

export const UserChip = ({ obj, onClick, _limit = null, sx }) => {
    return (
        <div className={cn('bg-thirdbg  text-white rounded-2xl p-2 flex gap-2 items-center w-fit', sx ? sx : '')}>
            <div className="flex gap-2 items-center">
                <div>
                    <DefaultProfileViewer image={obj?.image} width="37px" height="37px" name={getProfileName(obj)} />
                </div>
                <div className="flex-1 grid overflow-hidden">
                    <div className="truncate">
                        <PrimaryParagraph>{getProfileName(obj)}</PrimaryParagraph>
                    </div>
                    <div className=" truncate">
                        <SecondaryParagraph>{obj?.email} </SecondaryParagraph>
                    </div>
                </div>
            </div>
            {onClick && (
                <div onClick={onClick} className="  ml-2 cursor-pointer">
                    <div className="bg-white rounded-full p-[0.15rem]">
                        <XMarkIcon className="h-4 w-4 text-black" aria-hidden="true" />
                    </div>
                </div>
            )}
        </div>
    );
};
