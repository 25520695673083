import React from "react";

export const IncompleteCircle = () => {
  return (
    
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.53458 19.4654C3.97133 20.0287 3.05015 20.0334 2.55849 19.4066C1.33942 17.8526 0.517972 16.0153 0.177231 14.0548C-0.246171 11.6186 0.0933357 9.11114 1.14932 6.87529C2.2053 4.63943 3.92619 2.78437 6.07664 1.56382C8.22708 0.343275 10.7021 -0.183166 13.1631 0.0565008C15.6241 0.296168 17.9511 1.29024 19.8256 2.90276C21.7002 4.51528 23.0308 6.66751 23.6356 9.0651C24.2403 11.4627 24.0897 13.9886 23.2042 16.2973C22.4916 18.1552 21.3311 19.7995 19.8351 21.0891C19.2317 21.6092 18.3288 21.4269 17.8848 20.7655V20.7655C17.4408 20.1042 17.6268 19.2156 18.2099 18.6729C19.2219 17.7311 20.0113 16.5668 20.5109 15.2643C21.1835 13.5105 21.2979 11.5918 20.8386 9.7706C20.3792 7.94936 19.3684 6.31449 17.9445 5.08959C16.5205 3.86469 14.753 3.10958 12.8835 2.92753C11.0141 2.74547 9.13403 3.14536 7.50052 4.07251C5.86701 4.99966 4.55979 6.40879 3.75765 8.10719C2.95551 9.80558 2.69761 11.7103 3.01923 13.5608C3.25811 14.9353 3.80801 16.23 4.61932 17.3494C5.08679 17.9944 5.09784 18.9022 4.53458 19.4654V19.4654Z"
          fill="#B695F8"
        />
      </svg>

  );
};
