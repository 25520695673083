import { Button } from 'components/atoms/buttons/Button';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { Jumbotron } from 'components/organisms/Jumbotron';
import AddAccountComponent from 'components/settings/email-account-settings/AddAccountComponent';
import {
    AccountCard,
    AccountCardAction,
    AccountCardIcon,
    AccountCardInfo
} from 'components/settings/email-account-settings/EmailAccountCard';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useState } from 'react';

const EmailAccounts = () => {
    const [open, setOpen] = useState(false);

    const account = {
        email: 'Jacksmith45@outlook.com',
        provider: 'Outlook.com',
        isConnected: true
    };
    const account2 = {
        email: 'leomessi@gmail.com',
        provider: 'Gmail.com',
        isConnected: false
    };
    return (
        <div>
            <div>
                <SidebarLayout>
                    <Jumbotron
                        title="Email Accounts"
                        path={[
                            {
                                link: 'settings',
                                title: 'Settings'
                            },
                            {
                                link: 'settings/email-manager-account-settings',
                                title: 'Email Manager Account Settings'
                            },
                            {
                                link: '',
                                title: 'Email Accounts'
                            }
                        ]}
                    />
                    <div className="md:mx-10 mx-5 mt-5">
                        <div className="flex justify-between items-end">
                            <SecondaryParagraph sx={'text-md'}>You can switch between your accounts</SecondaryParagraph>
                            <Button
                                title="Add Account"
                                classes="ann-btn rounded-xl buttons-font-lighter px-5 "
                                atClick={() => setOpen(true)}
                            />
                        </div>

                        <div className="mt-5">
                            <AccountCard account={account}>
                                <div className="flex items-start gap-2">
                                    <AccountCardIcon />
                                    <AccountCardInfo />
                                </div>
                                <AccountCardAction />
                            </AccountCard>
                        </div>
                        <div className="mt-5">
                            <AccountCard account={account2}>
                                <div className="flex items-start gap-2">
                                    <AccountCardIcon />
                                    <AccountCardInfo />
                                </div>
                                <AccountCardAction />
                            </AccountCard>
                        </div>
                    </div>
                </SidebarLayout>
            </div>

            <AddAccountComponent open={open} setOpen={setOpen} showSuccessModal={false} />
        </div>
    );
};

export default EmailAccounts;
