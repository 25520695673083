import React from 'react';

export const FormsBgIcon = () => {
    return (
        <div>
            {' '}
            <svg width="90" height="90" viewBox="0 0 132 121" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M88.2413 0.629307C85.5121 0.988307 82.8805 1.88145 80.4967 3.25774L10.6798 43.5666C5.86543 46.3461 2.35281 50.9249 0.914629 56.2956C-0.523556 61.6664 0.230504 67.3891 3.01092 72.205L43.3324 142.044C44.7091 144.428 46.5419 146.518 48.726 148.195C50.9102 149.871 53.403 151.101 56.0621 151.814C58.7212 152.527 61.4945 152.709 64.2237 152.35C66.9529 151.991 69.5846 151.098 71.9684 149.722L141.785 109.413C144.169 108.036 146.258 106.204 147.934 104.02C149.609 101.836 150.838 99.343 151.55 96.6837C152.263 94.0243 152.444 91.2506 152.084 88.5209C151.725 85.7912 150.831 83.1589 149.454 80.7743L109.133 10.9355C107.756 8.55098 105.923 6.46085 103.739 4.7845C101.555 3.10814 99.0621 1.87837 96.403 1.16543C93.7439 0.452479 90.9705 0.27031 88.2413 0.629307ZM44.1385 96.1805C43.0322 100.312 43.6123 104.714 45.751 108.418C47.8898 112.123 51.4122 114.826 55.5431 115.934C59.6741 117.042 64.0754 116.463 67.7787 114.324C71.4821 112.186 74.1841 108.664 75.2904 104.533C76.3967 100.402 75.8166 95.9994 73.6778 92.2949C71.5391 88.5904 68.0167 85.887 63.8857 84.7795C59.7547 83.6719 55.3535 84.2508 51.6501 86.3889C47.9468 88.527 45.2448 92.0492 44.1385 96.1805ZM53.4841 98.6862C53.9266 97.0337 55.0074 95.6248 56.4887 94.7696C57.9701 93.9143 59.7306 93.6828 61.383 94.1258C63.0354 94.5688 64.4443 95.6502 65.2998 97.132C66.1553 98.6138 66.3873 100.375 65.9448 102.027C65.5023 103.68 64.4215 105.089 62.9402 105.944C61.4588 106.799 59.6983 107.031 58.0459 106.588C56.3935 106.145 54.9846 105.063 54.1291 103.581C53.2736 102.1 53.0416 100.339 53.4841 98.6862Z"
                />
                <path d="M113.269 6.17502C116.795 6.32111 120.226 7.35679 123.244 9.18568C126.262 11.0146 128.769 13.5773 130.531 16.6352L164.401 75.2998C173.756 91.5024 168.211 112.21 152.013 121.562L93.3668 155.421C90.3103 157.188 86.8566 158.154 83.3266 158.228C79.7966 158.302 76.3049 157.482 73.1762 155.845L73.877 155.485L74.5648 155.102L147.174 113.181C152.729 109.974 156.782 104.691 158.442 98.4938C160.101 92.2968 159.231 85.6936 156.023 80.1369L114.089 7.50453C113.828 7.05379 113.555 6.61045 113.269 6.17502Z" />
            </svg>
        </div>
    );
};
