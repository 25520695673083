import Slideover from 'components/atoms/SlideOver';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import DefaultSwitch from 'components/atoms/inputs/DefaultSwitch';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';
import { updateStatusThunk } from 'store/workspace/workspaceTasks';
import { Button } from 'components/atoms/buttons/Button';
import { toastHandler } from 'responseHanlder';
export const EditStatus = ({ open, setOpen, toggleStatusUpdate }) => {
    const dispatch = useDispatch();
    const { status } = useSelector((state) => state.global);
    const [title, setTitle] = useState('');
    const [partial_notification, setPartialNotification] = useState('');
    const [in_app_notification, setInAppNotification] = useState('');
    const [email_notification, setEmailNotification] = useState('');
    const business_id = localStorage.getItem(BUSINESS_ID);

    useEffect(() => {
        if (status) {
            setTitle(status?.label);
            setPartialNotification(status?.partial_notification);
            setEmailNotification(status?.email_notification);
            setInAppNotification(status?.in_app_notification);
        }
    }, [status]);

    const handlePartialNotifications = (value) => {
        if (value === true) {
            setPartialNotification('active');
        } else {
            setPartialNotification('inactive');
        }
    };

    const handleEmailNotifications = (value) => {
        if (value === true) {
            setEmailNotification('active');
        } else {
            setEmailNotification('inactive');
        }
    };
    const handleInAppNotifications = (value) => {
        if (value === true) {
            setInAppNotification('active');
        } else {
            setInAppNotification('inactive');
        }
    };

    const handleSubmit = () => {
        // updateStatusThunk
        if (!title) {
            return toastHandler('Title is required', TOAST_TYPE_ERROR);
        }
        const payload = {
            task_status_id: status?.task_status_id,
            label: title,
            business_id,
            partial_notification,
            in_app_notification,
            email_notification
        };

        dispatch(setLoader(true));
        dispatch(updateStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleStatusUpdate();
                    setOpen(false);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <Slideover
            open={open}
            setOpen={setOpen}
            title="Update Dynamic Status"
            footer={
                <div className="flex justify-center items-center ">
                    <Button
                        atClick={handleSubmit}
                        title="Update"
                        classes="ann-btn rounded-xl buttons-font-lighter px-10"
                    />
                </div>
            }
        >
            <div>
                <div>
                    <motion.div
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    >
                        <div className="m-5 default-forms">
                            <label>Title</label>
                            <input
                                type="text"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                                className="mt-2"
                                placeholder="Please enter title"
                            />

                            <div className="mt-5">
                                <div className="flex gap-5 justify-between items-center">
                                    {' '}
                                    <label>Partial Notifications </label>
                                    <div>
                                        <DefaultSwitch
                                            checked={partial_notification === 'active'}
                                            onChange={(value) => handlePartialNotifications(value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-5 justify-between items-center mt-5">
                                    {' '}
                                    <label>In App Notifications </label>
                                    <div>
                                        <DefaultSwitch
                                            checked={in_app_notification === 'active'}
                                            onChange={(value) => handleInAppNotifications(value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex gap-5 justify-between items-center mt-5">
                                    {' '}
                                    <label>Email Notifications </label>
                                    <div>
                                        <DefaultSwitch
                                            checked={email_notification === 'active'}
                                            onChange={(value) => handleEmailNotifications(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Slideover>
    );
};
