import React from 'react';
import { EnvelopeSuccess } from 'utills/svgs/EnvelopeSuccess';

export const EmailAuthSuccess = ({ handleCondition }) => {
    return (
        <div className="flex min-h-[60vh] flex-col justify-center pb-10 sm:px-2 lg:px-2">
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] bg-white shadow rounded-xl sm:rounded-2xl  py-4">
                {' '}
                <div>
                    <div>
                        <div className="envelope">
                            <div className="envelope-wrapper">
                                <EnvelopeSuccess />
                            </div>
                        </div>

                        <div className="congrats">
                            <h1 className="text-center">Congratulation!</h1>

                            <p className="text-center">Your email 2FA has been enabled successfully.</p>
                        </div>

                        <div className="mt-5 mb-28">
                            <center>
                                <button onClick={() => handleCondition(0)} className="px-12  ann-btn rounded-lg py-2">
                                    proceed
                                </button>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
