import React from 'react';

export const ExplainationSignSvg = () => {
    return (
        <svg width="53" height="51" viewBox="0 0 53 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.1" width="53" height="50.0556" rx="25" fill="#B695F8" />
            <path
                d="M26.4996 26.4991C26.9167 26.4991 27.2666 26.3578 27.5493 26.0751C27.8319 25.7925 27.9728 25.4431 27.9718 25.0269V19.1012C27.9718 18.6841 27.8305 18.3406 27.5478 18.0707C27.2651 17.8008 26.9157 17.6658 26.4996 17.6658C26.0824 17.6658 25.7325 17.8071 25.4499 18.0898C25.1672 18.3725 25.0264 18.7219 25.0273 19.138V25.0637C25.0273 25.4808 25.1687 25.8244 25.4513 26.0943C25.734 26.3642 26.0834 26.4991 26.4996 26.4991ZM26.4996 32.388C26.9167 32.388 27.2666 32.2467 27.5493 31.964C27.8319 31.6814 27.9728 31.3319 27.9718 30.9158C27.9718 30.4987 27.8305 30.1488 27.5478 29.8661C27.2651 29.5834 26.9157 29.4426 26.4996 29.4436C26.0824 29.4436 25.7325 29.5849 25.4499 29.8676C25.1672 30.1502 25.0264 30.4996 25.0273 30.9158C25.0273 31.3329 25.1687 31.6828 25.4513 31.9655C25.734 32.2482 26.0834 32.389 26.4996 32.388ZM26.4996 39.7491C24.463 39.7491 22.5491 39.3624 20.7579 38.589C18.9667 37.8156 17.4086 36.7669 16.0836 35.4429C14.7586 34.1179 13.7099 32.5598 12.9375 30.7686C12.165 28.9774 11.7783 27.0635 11.7773 25.0269C11.7773 22.9903 12.164 21.0764 12.9375 19.2852C13.7109 17.494 14.7596 15.9359 16.0836 14.6109C17.4086 13.2859 18.9667 12.2372 20.7579 11.4648C22.5491 10.6924 24.463 10.3057 26.4996 10.3047C28.5361 10.3047 30.45 10.6914 32.2412 11.4648C34.0324 12.2382 35.5905 13.2869 36.9155 14.6109C38.2405 15.9359 39.2897 17.494 40.0631 19.2852C40.8366 21.0764 41.2228 22.9903 41.2218 25.0269C41.2218 27.0635 40.8351 28.9774 40.0617 30.7686C39.2883 32.5598 38.2396 34.1179 36.9155 35.4429C35.5905 36.7679 34.0324 37.8171 32.2412 38.5905C30.45 39.3639 28.5361 39.7501 26.4996 39.7491ZM26.4996 36.8047C29.763 36.8047 32.5421 35.6578 34.8368 33.3641C37.1315 31.0704 38.2783 28.2913 38.2773 25.0269C38.2773 21.7635 37.1305 18.9844 34.8368 16.6897C32.543 14.395 29.764 13.2482 26.4996 13.2491C23.2361 13.2491 20.4571 14.396 18.1624 16.6897C15.8677 18.9834 14.7208 21.7625 14.7218 25.0269C14.7218 28.2903 15.8686 31.0694 18.1624 33.3641C20.4561 35.6588 23.2352 36.8057 26.4996 36.8047Z"
                fill="#B695F8"
            />
        </svg>
    );
};
