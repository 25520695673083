import React from 'react';
import './appSidebar.css';
import { useState, useEffect } from 'react';
import ClientsSide from './ClientsSide';
import WorkplaceSide from './WorkplaceSide';
import SettingsSide from './SettingsSide';
import { Link } from 'react-router-dom';
import logoutIcon from '../../files/powerOff.png.png';
import logo from '../../files/transparentLogo.png.png';
//icons
import { DashboardIcon } from '../../utills/svgs/DashboardIcon';
import { ClientsIcon } from '../../utills/svgs/ClientsIcon';
import { WorkspaceIcon } from '../../utills/svgs/WorkspaceIcon';
import { SettingsIcon } from '../../utills/svgs/SettingsIcon';
import { ArrowRight } from '../../utills/svgs/ArrowRight';
import { CrossIcon } from '../../utills/svgs/CrossIcon';
import { useDispatch, useSelector } from 'react-redux';
import { SidebarOpen } from 'utills/svgs/SidebarOpen';
import { SidebarClose } from 'utills/svgs/SidebarClose';
import { useOwnerProfile } from 'Hooks/useOwnerProfile';
import { generateRandomProfileLink, getProfileName } from 'utills/globalVars';
import { setIsConfirmationOpen } from 'store/global/globalReducer';
import DAppsIcon from 'utills/svgs/DAppsIcon';
import { cn } from 'utills/tailwindUtil';
import { BoardIcon } from 'utills/svgs/BoardIcon';
import { JobManagerIcon } from './../../utills/svgs/JobManagerIcon';
import { JobManagerSide } from './JobManagerSide';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';
import { _EmailManagerIcon } from 'utills/svgs/EmailManagerIcon';

// --------------

const AppSidebar = ({ isOpen, toggleSidebar }) => {
    const dispatch = useDispatch();
    const { profileImg } = useSelector((state) => state.global);

    const [showClient, setShowClient] = useState(false);
    const [showWrokplace, setShowWorkplace] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showJobs, setShowJobs] = useState(false);

    const { getOwnerProfile } = useOwnerProfile();
    const { data: ownerProfile } = useSelector((state) => state.businessOwnerGetProfile);

    useEffect(() => {
        getOwnerProfile();
    }, []);

    const handleShowClientsSide = () => {
        setShowSettings(false);
        setShowWorkplace(false);
        setShowJobs(false);
        setShowClient(true);
    };

    const handleShowWorkspace = () => {
        setShowClient(false);
        setShowSettings(false);
        setShowWorkplace(true);
    };

    const handleHideWorkspace = () => {
        setShowWorkplace(false);
    };

    const showSettingsSide = () => {
        setShowClient(false);
        setShowWorkplace(false);
        setShowSettings(true);
    };

    const hideSettings = () => {
        setShowSettings(false);
    };

    const handleLogout = () => {
        dispatch(setIsConfirmationOpen(true));
    };

    const handleCloseAllSubSidebars = () => {
        setShowClient(false);
        setShowWorkplace(false);
        setShowSettings(false);
        setShowJobs(false);
    };

    const navigation = [
        {
            name: 'Job Manager',
            href: '/job-manager',
            icon: JobManagerIcon,
            current: false,
            classes: ' job-icon ',
            iconCss: 'group-hover:stroke-primary stroke-white',
            onMouseEnter: () => {
                setShowJobs(true);
                setShowClient(false);
            }
        },
        { name: 'Clients', href: '/clients', icon: ClientsIcon, current: false, onMouseEnter: handleShowClientsSide },
        {
            name: 'Workspace',
            href: '/workspace',
            icon: WorkspaceIcon,
            current: false,
            onMouseEnter: handleShowWorkspace
        },
        { name: 'Apps', href: '/workspace/apps', icon: DAppsIcon, current: false },
        {
            name: 'Notes',
            href: '/notes',
            icon: BoardIcon,
            current: false,
            classes: 'taskItemSidebar',
            iconCss: '!fill-none'
        }
        // {
        //     name: 'Email Manager',
        //     href: '/email-manager',
        //     icon: EmailManagerIcon,
        //     current: false,
        //     classes: ' job-icon',
        //     iconCss: 'group-hover:fill-primary fill-white !stroke-0'
        // }
    ];

    return (
        <div onMouseLeave={() => handleCloseAllSubSidebars()}>
            <div className={`activate-tab-sidebar blue-bg`}>
                <span onClick={toggleSidebar} className="cursor-pointer">
                    <CrossIcon />
                </span>
            </div>
            <div className={`app-sidebar min-h-screen flex text-white ${isOpen ? 'sidebar-open' : 'sidebar-close'}`}>
                {showWrokplace && (
                    <div>
                        <WorkplaceSide handleHideWorkspace={handleHideWorkspace} />
                    </div>
                )}
                {showClient && (
                    <div>
                        <ClientsSide handleHideClientSide={handleCloseAllSubSidebars} />
                    </div>
                )}

                {showSettings && (
                    <div>
                        <SettingsSide hideSettings={hideSettings} />
                    </div>
                )}
                {showJobs && (
                    <div>
                        <JobManagerSide handleHideJobSide={handleCloseAllSubSidebars} />
                    </div>
                )}

                <div className="inner-app-side pt-5 relative z-[99]">
                    {!isOpen ? (
                        <div onClick={toggleSidebar} className="toggle-open">
                            <SidebarOpen />
                        </div>
                    ) : (
                        <div onClick={toggleSidebar} className="toggle-open">
                            <SidebarClose />
                        </div>
                    )}
                    <div className="logo-div mx-4 flex items-center my-4">
                        <img src={logo} alt="logo" className={` ${isOpen ? 'open-logo' : 'close-logo'}`} />
                    </div>
                    <div className="resize-y">
                        <div className={cn('max-h-[70vh] overflow-auto ', !isOpen ? 'pb-[30px]' : 'pb-[120px]')}>
                            <nav className={cn('flex flex-1 flex-col', isOpen ? 'mx-5' : 'mx-2')}>
                                <ul className="flex flex-1 flex-col gap-y-4">
                                    <li className="-mx-2 space-y-1">
                                        <div className="group relative"></div>
                                        <TooltipChipWrap
                                            sx={
                                                '!bg-gray-200 !text-gray-800 !text-xs left-[5px] translate-y-[5px] !translate-x-0'
                                            }
                                            hideChip
                                            title="Dashboard"
                                            placement="bottom"
                                        >
                                            <Link
                                                to={'/business/dashboard'}
                                                className={cn(
                                                    'text-white hover:bg-white hover:text-primary',
                                                    'group flex gap-x-3 items-start rounded-md p-2 text-sm/6 font-medium only-icon',
                                                    !isOpen ? 'items-center justify-center mx-3' : ''
                                                )}
                                                onMouseEnter={() => handleCloseAllSubSidebars()}
                                            >
                                                <DashboardIcon
                                                    aria-hidden="true"
                                                    className={cn(
                                                        'text-gray-400 group-hover:text-primary',
                                                        'h-6 w-6 shrink-0 '
                                                    )}
                                                />
                                                {isOpen && 'Dashboard'}
                                            </Link>
                                        </TooltipChipWrap>
                                    </li>
                                    <li>
                                        <div
                                            className={cn(
                                                '!text-[14px] relations flex',
                                                !isOpen ? 'justify-center' : 'justify-start'
                                            )}
                                        >
                                            Relations
                                        </div>
                                    </li>

                                    <li>
                                        <ul className="-mx-2 space-y-1 flex flex-1 flex-col gap-y-2">
                                            {/* ============================================================ */}

                                            {/* <li>
                                                {' '}
                                                <PopoverComponent
                                                    title={
                                                        <div
                                                            className={cn(
                                                                'flex items-center gap-x-3 flex-1 bg-white',
                                                                isOpen ? 'justify-start' : 'justify-center'
                                                            )}
                                                        >
                                                            <JobManagerIcon
                                                                aria-hidden="true"
                                                                className={cn('text-white', 'h-6 w-6 shrink-0 ')}
                                                            />
                                                            item name
                                                            {isOpen && <ArrowRight />}
                                                        </div>
                                                    }
                                                />
                                            </li> */}
                                            {/* <li>
                                                <TooltipChipWrap
                                                    sx={
                                                        '!bg-gray-200 !text-gray-800 !text-xs left-[5px] !translate-x-0 translate-y-[5px]'
                                                    }
                                                    hideChip
                                                    title={'Testing Dropdown Item'}
                                                    placement="bottom"
                                                >
                                                  
                                                </TooltipChipWrap>
                                            </li> */}
                                            {/* ============================================================ */}
                                            {navigation.map((item) => (
                                                <li key={item.name}>
                                                    <TooltipChipWrap
                                                        sx={
                                                            '!bg-gray-200 !text-gray-800 !text-xs left-[5px] !translate-x-0 translate-y-[5px]'
                                                        }
                                                        hideChip
                                                        title={item?.name || 'click to view'}
                                                        placement="bottom"
                                                    >
                                                        <Link
                                                            onMouseEnter={
                                                                item?.onMouseEnter
                                                                    ? item?.onMouseEnter
                                                                    : handleCloseAllSubSidebars
                                                            }
                                                            to={item.href}
                                                            className={cn(
                                                                item.current
                                                                    ? 'bg-white text-primary'
                                                                    : 'text-white hover:bg-white hover:text-primary',
                                                                'group flex gap-x-3 rounded-md p-2 text-sm/6 font-medium only-icon items-center ',
                                                                !isOpen ? 'justify-center mx-3' : '',
                                                                item?.classes ? item?.classes : ''
                                                            )}
                                                        >
                                                            <div
                                                                className={cn(
                                                                    'flex items-center gap-x-3 flex-1',
                                                                    isOpen ? 'justify-start' : 'justify-center'
                                                                )}
                                                            >
                                                                <item.icon
                                                                    aria-hidden="true"
                                                                    className={cn(
                                                                        item.current
                                                                            ? 'text-primary'
                                                                            : 'text-gray-400 group-hover:text-primary',
                                                                        'h-6 w-6 shrink-0 ',
                                                                        item?.iconCss
                                                                    )}
                                                                />
                                                                {isOpen && item.name}
                                                            </div>
                                                            {isOpen && item?.onMouseEnter && <ArrowRight />}
                                                        </Link>
                                                    </TooltipChipWrap>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li>
                                        <div
                                            className={cn(
                                                '!text-[14px] relations flex',
                                                !isOpen ? 'justify-center' : 'justify-start'
                                            )}
                                        >
                                            Support
                                        </div>
                                    </li>
                                    <li className="-mx-2 space-y-1">
                                        <Link
                                            to={'/settings'}
                                            onMouseEnter={showSettingsSide}
                                            className={cn(
                                                'text-white hover:bg-white hover:text-primary',
                                                'group flex gap-x-3 items-center rounded-md p-2 text-sm/6 font-medium only-icon',
                                                !isOpen ? 'justify-center mx-3' : ''
                                            )}
                                        >
                                            <div
                                                className={cn(
                                                    'flex items-center gap-x-3 flex-1',
                                                    isOpen ? 'justify-start' : 'justify-center'
                                                )}
                                            >
                                                <SettingsIcon
                                                    className={cn(
                                                        'text-gray-400 group-hover:text-primary',
                                                        'h-6 w-6 shrink-0 ',
                                                        'group-hover:stroke-primary stroke-white'
                                                    )}
                                                />
                                                {isOpen && 'Settings'}
                                            </div>
                                            {isOpen && <ArrowRight />}
                                        </Link>
                                    </li>
                                    <li className="-mx-2 space-y-1">
                                        <Link
                                            to={'/profile'}
                                            onMouseEnter={() => handleCloseAllSubSidebars()}
                                            className={cn(
                                                'text-white hover:bg-white hover:text-primary',
                                                'group flex gap-x-3 items-start rounded-md p-2 text-sm/6 font-medium only-icon',
                                                !isOpen ? 'items-center justify-center mx-3' : ''
                                            )}
                                        >
                                            <DefaultProfileViewer
                                                image={
                                                    profileImg ||
                                                    ownerProfile?.image ||
                                                    generateRandomProfileLink(getProfileName(ownerProfile))
                                                }
                                                width="25px"
                                                height="25px"
                                                name={getProfileName(ownerProfile)}
                                                className={cn(
                                                    'text-gray-400 group-hover:text-primary',
                                                    'h-6 w-6 shrink-0 '
                                                )}
                                            />
                                            {isOpen && getProfileName(ownerProfile)}
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className="absolute bottom-0 w-full sidebar-logout-btn ">
                        <div className="add-ann-form cursor-pointer mx-4  flex items-center py-3">
                            {isOpen ? (
                                <TooltipChipWrap
                                    sx={
                                        '!bg-gray-200 !text-gray-800 !text-xs left-[1px] -translate-y-[75px] !translate-x-0 w-full'
                                    }
                                    hideChip
                                    title="Logout"
                                    groupSx="w-full"
                                    placement="top"
                                >
                                    <button
                                        onClick={handleLogout}
                                        className="flex items-center gap-2 justify-between  logout-button p-2 rounded-md w-full !text-md"
                                    >
                                        <div className="!text-[16px]">Logout</div>
                                        <div>
                                            <img src={logoutIcon} alt="logout" className="logout-icon" />
                                        </div>
                                    </button>
                                </TooltipChipWrap>
                            ) : (
                                <TooltipChipWrap
                                    sx={
                                        '!bg-gray-200 !text-gray-800 !text-xs left-[1px] -translate-y-[75px] !translate-x-0 w-full'
                                    }
                                    hideChip
                                    title="Logout"
                                    groupSx="w-full"
                                    placement="top"
                                >
                                    <button
                                        onClick={handleLogout}
                                        className="flex items-center justify-center logout-button p-2 rounded-md w-full"
                                    >
                                        <div>
                                            <img src={logoutIcon} alt="logout" className="logout-icon" />
                                        </div>
                                    </button>
                                </TooltipChipWrap>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppSidebar;
