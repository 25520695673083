import React from 'react';

export const PlaneIcon = () => {
    return (
        <svg width="86" height="86" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9343_815)">
                <path
                    d="M96.9941 0.145309C95.5242 0.476559 5.40352 30.6203 4.24414 31.1586C1.325 32.5457 0 34.3262 0 36.852C0 38.6531 0.6625 40.0195 2.21523 41.3859C3.20898 42.2762 5.42422 43.3941 18.198 49.5016C26.3551 53.3937 34.0773 57.0996 35.3609 57.7207C36.6445 58.3211 38.4043 59.3148 39.2738 59.8738L40.8266 60.9297L71.1152 30.641C87.7812 13.975 101.383 0.269527 101.321 0.166012C101.218 0.000389099 97.7394 -0.0203133 96.9941 0.145309Z"
                    fill="url(#paint0_linear_9343_815)"
                />
                <path
                    d="M75.3592 34.8848L45.0498 65.1941L45.94 66.457C47.0166 67.9891 47.5135 68.9828 56.4986 87.802C62.6061 100.576 63.724 102.791 64.6143 103.785C65.9807 105.338 67.3471 106 69.1482 106C71.674 106 73.4545 104.675 74.8416 101.756C75.2143 100.948 82.3568 79.8105 90.7002 54.7598L105.876 9.2129L105.958 7.2254C106 6.12813 105.958 5.07227 105.855 4.88594C105.71 4.6375 99.4783 10.7656 75.3592 34.8848Z"
                    fill="url(#paint1_linear_9343_815)"
                />
                <path
                    d="M21.3244 68.9838C19.2334 74.1389 17.3494 78.9627 17.1423 79.708C16.5626 81.6748 16.7076 84.3869 17.4529 85.8361C18.1361 87.1611 19.399 88.2791 20.8482 88.8174C22.2974 89.3557 24.7611 89.3764 26.438 88.8588C28.8189 88.1135 46.3751 80.8881 46.3751 80.6396C46.3751 80.5154 45.3193 78.1967 44.015 75.5053C41.4064 70.1018 40.6404 68.9631 37.8248 66.3338C36.2306 64.8639 35.8166 64.6154 31.1169 62.2967C28.3427 60.951 25.8791 59.7916 25.6099 59.7295C25.1544 59.6053 24.9681 59.9779 21.3244 68.9838Z"
                    fill="url(#paint2_linear_9343_815)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_9343_815"
                    x1="1.56682"
                    y1="60.9296"
                    x2="100.537"
                    y2="54.2593"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_9343_815"
                    x1="45.9919"
                    y1="106"
                    x2="105.737"
                    y2="104.542"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_9343_815"
                    x1="17.2437"
                    y1="89.2344"
                    x2="46.2296"
                    y2="88.0575"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <clipPath id="clip0_9343_815">
                    <rect width="106" height="106" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
