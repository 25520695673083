import React from 'react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, ERROR_TYPE_ERROR, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { generateId } from 'utills/uid';

import Slideover from 'components/atoms/SlideOver';
import { createTemplateDocThunk, getTemplateDocThunk, updateTemplateDocThunk } from 'store/settings/JobPortal';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';

const DocumentListAddDocument = ({ open, setOpen, editObj = null }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    useEffect(() => {
        if (editObj) {
            setFields([
                {
                    id: editObj?.template_job_document_id,
                    text: editObj?.title
                }
            ]);
        }
    }, [editObj]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let titles = [];

        const newInputs = [...fields];

        if (newInputs.length < 1) {
            toastHandler('Please enter atleast one Document Title', ERROR_TYPE_ERROR);
            return;
        }

        let hasEmpty = false;
        newInputs.forEach((field) => {
            if (field.text === '') {
                hasEmpty = true;
            }
        });

        if (hasEmpty) {
            return toastHandler('Input cant be empty', TOAST_TYPE_ERROR);
        }

        newInputs.map((input) => {
            titles.push(input.text);
            return true;
        });

        const payload = {
            business_id,
            titles
        };

        if (editObj) {
            delete payload.titles;
            payload.template_job_document_id = editObj?.template_job_document_id;
            payload.title = newInputs[0]?.text;
        }

        setLoader(true);
        dispatch(editObj ? updateTemplateDocThunk(payload) : createTemplateDocThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setFields([
                        {
                            id: generateId(),
                            text: ''
                        }
                    ]);
                    setOpen(false);
                    dispatch(getTemplateDocThunk({ business_id }));
                    toastHandler(`Documents ${editObj ? 'Updated' : 'Added'} Successfully`, TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <Slideover open={open} setOpen={setOpen}>
            <RoleBasedGuard module="Job" permissions={['admin']} sx="p-5">
                <div>
                    <div className="text-2xl font-fira pb-5 font-semibold ">{editObj ? 'Edit' : 'Add'} Document </div>
                    <div className="text-[#898989] font-[300] text-base font-poppins">
                        {!editObj ? (
                            <>
                                Add new document names to the list that will be used when requesting documents to
                                clients. Once added, these document names can be selected during Request Document,
                                streamlining the process and saving time.
                            </>
                        ) : (
                            <>
                                Edit the existing document names in the list to ensure they remain accurate and
                                up-to-date. This will help keep your Request Documents clear and relevant for clients
                            </>
                        )}
                    </div>
                    <div>
                        <motion.div
                            initial={{ x: 700 }}
                            animate={{ x: 0 }}
                            transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                        >
                            <form onSubmit={handleSubmit}>
                                {' '}
                                <div>
                                    <div className="default-forms  mt-3 mb-5">
                                        <MultiTextInputAddComponent
                                            placeholder={'Enter document title'}
                                            fields={fields}
                                            setFields={setFields}
                                            addSingle={false}
                                        />
                                    </div>

                                    <div className="mt-24 w-full flex justify-center items-center bg-white py-2">
                                        <button
                                            disabled={loader ? true : false}
                                            type="submit"
                                            className="ann-btn text-white px-5 md:px-28 py-2 flex gap-2 items-center cursor-pointer  rounded-lg"
                                        >
                                            <span>{editObj ? 'Update' : 'Add'}</span>
                                            {loader && <SmallLoaderWhite />}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </motion.div>
                    </div>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
export default DocumentListAddDocument;
