/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './css/molecules.css';
import { generateId } from 'utills/uid';
import { generateRandomProfileLink } from 'utills/globalVars';
export const ImagesStack = ({ dataList, atClick, keyword, remain, isStatus }) => {
    const [showName, setShowName] = useState(false);
    const [nameId, setNameId] = useState(null);

    const handleShowName = (id) => {
        setNameId(id);
        setShowName(true);
    };
    const handleHideName = () => {
        setShowName(false);
    };
    return (
        <div>
            <div style={{ marginLeft: '15px' }} className="flex flex-wrap">
                {dataList?.map((emp, index) => (
                    <div
                        key={generateId()}
                        onMouseEnter={() => handleShowName(emp?.user_id)}
                        onMouseLeave={handleHideName}
                        style={{
                            marginLeft: '-15px',
                            zIndex: dataList?.length - index
                        }}
                        className="signees-images-container mt-1"
                    >
                        {/* <Tooltip
                            title={emp?.first_name && emp?.last_name ? emp?.first_name + emp?.last_name : 'no name'}
                            variant="soft"
                        > */}
                        <img
                            src={emp.image ?? generateRandomProfileLink(emp?.first_name + ' ' + emp?.last_name)}
                            alt="taskcommentpicture"
                            className={`signee-image ${index === 2 && isStatus ? '' : ''} `}
                        />
                        {/* </Tooltip> */}
                    </div>
                ))}
            </div>
        </div>
    );
};
