export const STATE_TERRITORY = [
    { label: 'New South Wales' },
    { label: 'Victoria' },
    { label: 'Queensland' },
    { label: 'Western Australia' },
    { label: 'South Australia' },
    { label: 'Tasmania' },
    { label: 'Australian Capital Territory' },
    { label: 'Northern Territory' },
    { label: 'Jervis Bay Territory' }
];

export const OFFLINE_CLIENT = 'offline_client';
export const PROSPECTIVE_CLIENT = 'prospective_client';
export const ACTIVE_CLIENT = 'active_client';

export const INACTIVE_CLIENT = 'inactive_client';
export const INDIVIDUAL_CATEGORY = 'Individual';
export const COMPANY_CLIENT = 'Company';
