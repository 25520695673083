import Badge from 'components/atoms/Badge';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import SelectedFiles from 'components/molecules/SelectedFiles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID, USER_TYPE, all_files_types } from 'utills/globalVars';
import pdfIcon from '../../../files/pdfIcon.svg';
import { setSideLoader, updateNoteHandler } from 'store/global/globalReducer';
import cloneDeep from 'lodash/cloneDeep';
import { delNoteFileThunk, updateNoteThunk } from 'store/workspace/workspaceNotes';
import { FileIconSvg } from 'utills/svgs/FileIconSvg';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

export const EditNote = ({ open, setOpen, toggleNotesUpdate }) => {
    const dispatch = useDispatch();
    const { note } = useSelector((state) => state?.global);

    const [title, setTitle] = useState(note?.title ?? '');
    const [description, setDescription] = useState(note?.description || '');
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { sideLoader } = useSelector((state) => state.global);
    const [forceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
        if (note) {
            setForceUpdate(true);
        }
    }, [note]);

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('business_id', business_id);
        formData.append('title', title.trim());
        formData.append('description', description.trim());
        formData.append('uploaded_by', USER_TYPE);
        formData.append('business_note_id', note?.business_note_id);

        const sanitizedFilesPromises = selectedFiles.map((file) => createNewPDFWithoutMetadata(file));
        const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
        sanitizedFiles.forEach((sanitizedFile) => {
            formData.append('note_files', sanitizedFile);
        });

        dispatch(setSideLoader(true));
        dispatch(updateNoteThunk(formData))
            .then((response) => {
                console.info(response.payload);
                if (response.payload) {
                    setOpen(false);
                    toggleNotesUpdate();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles([...selectedFiles, ...files]);
    };

    const handleDelNoteFile = (noteFileid, index, noteid) => {
        dispatch(delNoteFileThunk({ note_id: noteid, note_file_id: noteFileid })).then((response) => {
            if (response.payload) {
                const newNote = cloneDeep(note);
                newNote?.attachment?.splice(index, 1);
                toggleNotesUpdate();

                dispatch(updateNoteHandler(newNote));
            }
        });
    };

    return (
        <Slideover
            title="Update Note"
            open={open}
            setOpen={setOpen}
            footer={
                <div className="flex justify-center items-center">
                    {' '}
                    <Button
                        disabled={sideLoader ? true : false}
                        isloading={sideLoader ? true : false}
                        title="Update"
                        classes="ann-btn px-20 rounded-lg "
                        atClick={() => handleSubmit()}
                        iconSet={{ loader: SmallLoaderWhite }}
                    />
                </div>
            }
        >
            <div className="mt-5 ">
                <label className="buttons-font font-poppins">Title</label>
                <div className="default-forms">
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Enter note title"
                        className="mt-2"
                    />
                </div>
            </div>
            <div className="my-3  buttons-font font-poppins">Description</div>
            <div>
                <DraftEditorNew
                    initialValue={description}
                    onChange={(value) => setDescription(value)}
                    required={true}
                    extraConfigs={{
                        height: 300
                    }}
                    placeholder="Enter Description"
                    setForceUpdate={setForceUpdate}
                    forceUpdate={forceUpdate}
                />
            </div>

            <div className="mt-3">
                <div className="mb-2 buttons-font font-poppins ">Add Attachment</div>
                <DragFile
                    atChange={(file) => {
                        handleSelectedFiles(file);
                    }}
                    iconSet={{ icon: FileIconSvg }}
                    accept={all_files_types}
                    selectedFiles={selectedFiles}
                    hideSelectedFiles
                    isMultiple={true}
                    dragMessage="Drag files here to upload file, or browse files"
                    dropMessage="Drop the selected files in this box... "
                    onlyPdf={false}
                    message="Only images and PDF's are allowed"
                />
            </div>
            <SelectedFiles files={selectedFiles} updateSelectedFiles={(files) => setSelectedFiles(files || [])} />

            {note?.attachment?.length > 0 && (
                <div>
                    <div className="buttons-font font-poppins mt-5">Note files</div>{' '}
                    <div className="ann-btns  mt-2 mb-5 flex gap-2 flex-wrap">
                        {note?.attachment?.map((att, index) => (
                            <div key={'attachments' + index}>
                                <div>
                                    <Badge
                                        onCancel={() => handleDelNoteFile(att?.note_file_id, index, att?.note_id)}
                                        leftIcon={
                                            <img
                                                src={
                                                    att.file_type === 'image/jpeg' ||
                                                    att.file_type === 'image/png' ||
                                                    att.file_type === 'image/heic' ||
                                                    att.file_type === 'image/heif'
                                                        ? att.url
                                                        : pdfIcon
                                                }
                                                alt="alt"
                                                className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                onClick={() => {}}
                                            />
                                        }
                                        text={att.name}
                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Slideover>
    );
};
