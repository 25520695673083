import React, { useEffect, useState } from 'react';
import { validateTFN } from 'utills/dataValidation';

export const TFNInputField = ({
    label,
    maxLength,
    _allowSpaces,
    onChange,
    name,
    inputValue,
    placeholder,
    classes,
    labelSx,
    required,
    disabled
}) => {
    const [value, setValue] = useState('');
    const [tfnError, setTfnError] = useState(false);
    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    const handleTfnValidation = (e) => {
        let inputValue = e.target.value;
        setTfnError(false);

        if (inputValue !== '' && !/^[0-9]+$/.test(inputValue)) {
            setTfnError(true);
            return false;
        }

        if (maxLength && inputValue.length > maxLength) {
            setTfnError(true);
        }

        if (validateTFN(inputValue)) {
            setTfnError(false);
        } else {
            if (inputValue?.length <= 9) {
                setTfnError(true);
            }
        }

        setValue(inputValue);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div>
            {' '}
            <div>
                <label className={labelSx ?? ''}>{label}</label>

                <input
                    type="text"
                    placeholder={placeholder}
                    name={name}
                    value={value}
                    onChange={handleTfnValidation}
                    className={`${classes} !border !border-secondarybg ${
                        disabled && '!bg-gray-200 !border-none !cursor-no-drop'
                    } ${tfnError && value ? 'add-error-border' : ''} `}
                    required={required ?? false}
                    maxLength={maxLength}
                    disabled={disabled}
                />
                {tfnError && (
                    <div className="text-sm text-red-500">
                        {tfnError && value ? <span> Invalid TFN Number </span> : ''}
                    </div>
                )}
            </div>
        </div>
    );
};
