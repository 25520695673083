import React from 'react';

export const FirmIcon = ({ className }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect width="40" height="40" rx="20" fill="url(#paint0_linear_21727_423)" />
            <g clipPath="url(#clip0_21727_423)">
                <path
                    d="M10.8749 10.2109C10.6483 10.2813 10.3827 10.4766 10.2382 10.6719C9.98036 11.0195 9.99989 10.3438 10.0077 20.1563L10.0194 29.082L10.1171 29.2773C10.2382 29.5273 10.4725 29.7617 10.7225 29.8828L10.9179 29.9805H19.9999H29.0819L29.2772 29.8828C29.5272 29.7617 29.7616 29.5273 29.8827 29.2773L29.9804 29.082V24.5117V19.9414L29.8866 19.7344C29.7694 19.4727 29.4413 19.168 29.1913 19.0859C29.0858 19.0547 27.3944 18.707 25.4296 18.3203C23.4647 17.9297 21.8163 17.6016 21.7694 17.5898L21.6835 17.5703L21.6718 15.043L21.66 12.5195L21.5311 12.2578C21.2772 11.7422 21.0311 11.6172 19.9218 11.4492C19.4804 11.3828 17.3827 11.0664 15.2616 10.7422C13.1405 10.4219 11.3202 10.1563 11.2186 10.1602C11.1171 10.1602 10.9647 10.1836 10.8749 10.2109ZM15.8944 11.6797C18.4608 12.0703 20.5897 12.4023 20.6288 12.4258C20.6679 12.4453 20.7265 12.5 20.7577 12.5469C20.8124 12.625 20.8202 13.4961 20.8202 20.9063V29.1797H19.5702H18.3202V27.1914C18.3202 24.8516 18.3319 24.9297 17.9491 24.5508C17.5741 24.1758 17.5936 24.1797 15.8319 24.1797C14.4491 24.1797 14.3202 24.1836 14.1405 24.2539C13.8358 24.3711 13.6171 24.5703 13.4686 24.875L13.3397 25.1367L13.328 27.1563L13.3163 29.1797H12.2616C11.078 29.1797 10.996 29.1641 10.8827 28.9258C10.828 28.8086 10.8202 27.9375 10.8202 20.0859C10.8202 13.7109 10.8319 11.3438 10.8632 11.2539C10.9061 11.1367 11.0936 10.9766 11.1913 10.9766C11.2108 10.9766 13.328 11.293 15.8944 11.6797ZM25.3085 19.1445C27.2772 19.5313 28.9296 19.875 28.9764 19.9063C29.1874 20.043 29.1796 19.8945 29.1796 24.5156C29.1796 29.2617 29.1952 28.9922 28.9257 29.1172C28.8124 29.1719 28.3983 29.1797 25.2382 29.1797H21.6796V23.8086C21.6796 20.8555 21.6913 18.4375 21.703 18.4375C21.7186 18.4375 23.3397 18.7539 25.3085 19.1445ZM17.371 25.1289L17.4804 25.2344L17.4921 27.207L17.5038 29.1797H15.8397H14.1796V27.2461C14.1796 25.9531 14.1952 25.2852 14.2225 25.2227C14.246 25.1719 14.3124 25.1016 14.3671 25.0664C14.4608 25.0078 14.6171 25 15.8671 25.0117L17.2655 25.0195L17.371 25.1289Z"
                    fill="white"
                />
                <path
                    d="M12.6922 14.2383C12.4734 14.3555 12.45 14.7031 12.6414 14.8867L12.743 14.9805H13.7508H14.7586L14.8601 14.8867C15.0555 14.7031 15.0242 14.3516 14.8016 14.2383C14.6453 14.1563 12.8406 14.1563 12.6922 14.2383Z"
                    fill="white"
                />
                <path
                    d="M16.7919 14.293C16.6083 14.4805 16.6474 14.8008 16.8739 14.9336C16.9677 14.9922 17.1161 15 17.9091 15C18.9482 15 19.0224 14.9844 19.1239 14.7383C19.1982 14.5703 19.1435 14.375 18.995 14.2578C18.9013 14.1836 18.8388 14.1797 17.9013 14.1797H16.9052L16.7919 14.293Z"
                    fill="white"
                />
                <path
                    d="M12.6922 16.7383C12.4734 16.8555 12.45 17.2031 12.6414 17.3867L12.743 17.4805H13.7508H14.7586L14.8601 17.3867C15.0555 17.2031 15.0242 16.8516 14.8016 16.7383C14.6453 16.6563 12.8406 16.6563 12.6922 16.7383Z"
                    fill="white"
                />
                <path
                    d="M16.7919 16.793C16.6083 16.9805 16.6474 17.3008 16.8739 17.4336C16.9677 17.4922 17.1161 17.5 17.9091 17.5C18.9482 17.5 19.0224 17.4844 19.1239 17.2383C19.1982 17.0703 19.1435 16.875 18.995 16.7578C18.9013 16.6836 18.8388 16.6797 17.9013 16.6797H16.9052L16.7919 16.793Z"
                    fill="white"
                />
                <path
                    d="M12.6922 19.2383C12.4734 19.3555 12.45 19.7031 12.6414 19.8867L12.743 19.9805H13.7508H14.7586L14.8601 19.8867C15.0555 19.7031 15.0242 19.3516 14.8016 19.2383C14.6453 19.1563 12.8406 19.1563 12.6922 19.2383Z"
                    fill="white"
                />
                <path
                    d="M16.7919 19.293C16.6083 19.4805 16.6474 19.8008 16.8739 19.9336C16.9677 19.9922 17.1161 20 17.9091 20C18.9482 20 19.0224 19.9844 19.1239 19.7383C19.1982 19.5703 19.1435 19.375 18.995 19.2578C18.9013 19.1836 18.8388 19.1797 17.9013 19.1797H16.9052L16.7919 19.293Z"
                    fill="white"
                />
                <path
                    d="M12.6922 21.7383C12.4734 21.8555 12.45 22.2031 12.6414 22.3867L12.743 22.4805H13.7508H14.7586L14.8601 22.3867C15.0555 22.2031 15.0242 21.8516 14.8016 21.7383C14.6453 21.6563 12.8406 21.6563 12.6922 21.7383Z"
                    fill="white"
                />
                <path
                    d="M16.7919 21.793C16.6083 21.9805 16.6474 22.3008 16.8739 22.4336C16.9677 22.4922 17.1161 22.5 17.9091 22.5C18.9482 22.5 19.0224 22.4844 19.1239 22.2383C19.1982 22.0703 19.1435 21.875 18.995 21.7578C18.9013 21.6836 18.8388 21.6797 17.9013 21.6797H16.9052L16.7919 21.793Z"
                    fill="white"
                />
                <path
                    d="M24.2919 21.793C24.1083 21.9805 24.1474 22.3008 24.3739 22.4336C24.4677 22.4922 24.6161 22.5 25.4091 22.5C26.4482 22.5 26.5224 22.4844 26.6239 22.2383C26.6982 22.0703 26.6435 21.875 26.495 21.7578C26.4013 21.6836 26.3388 21.6797 25.4013 21.6797H24.4052L24.2919 21.793Z"
                    fill="white"
                />
                <path
                    d="M24.2919 24.293C24.1083 24.4805 24.1474 24.8008 24.3739 24.9336C24.4677 24.9922 24.6161 25 25.4091 25C26.4482 25 26.5224 24.9844 26.6239 24.7383C26.6982 24.5703 26.6435 24.375 26.495 24.2578C26.4013 24.1836 26.3388 24.1797 25.4013 24.1797H24.4052L24.2919 24.293Z"
                    fill="white"
                />
                <path
                    d="M24.2919 26.793C24.1083 26.9805 24.1474 27.3008 24.3739 27.4336C24.4677 27.4922 24.6161 27.5 25.4091 27.5C26.4482 27.5 26.5224 27.4844 26.6239 27.2383C26.6982 27.0703 26.6435 26.875 26.495 26.7578C26.4013 26.6836 26.3388 26.6797 25.4013 26.6797H24.4052L24.2919 26.793Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_21727_423"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                </linearGradient>
                <clipPath id="clip0_21727_423">
                    <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                </clipPath>
            </defs>
        </svg>
    );
};
