/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ProfileContent from '../../components/profile-content/ProfileContent';
import { useEffect, useState } from 'react';
import ChangePasswordSide from '../../components/profile-content/profile-sides/ChangePasswordSide';
import DeactivateAccount from '../../components/profile-content/profile-sides/DeactivateAccount';
import AddBusinessSide from '../../components/profile-content/profile-sides/AddBusinessSide';
import AddBio from '../../components/profile-content/step-components/AddBio';
import AddServiceSide from '../../components/profile-content/profile-sides/AddServiceSide';
import AddBranchLocation from '../../components/profile-content/profile-sides/AddBranchLocation';

import { UpdateBusiness } from 'components/profile-content/profile-sides/UpdateBusiness';

import { useDispatch } from 'react-redux';
import { editService } from 'store/global/globalReducer';
import { EditBranch } from 'components/profile-content/profile-sides/EditBranch';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { SidebarLayout } from 'layouts/SidebarLayout';

const Profile = () => {
    const dispatch = useDispatch();
    const [showPassSide, setShowPassSide] = useState(false);
    const [showDeactivateAcc, setShowDeactivateAcc] = useState(false);
    const [showBusinessSide, setShowBusinessSide] = useState(false);
    const [showAddBio, setShowAddBio] = useState(false);
    const [serviceSide, setServiceSide] = useState(false);
    const [branchLocation, setBranchLocation] = useState(false);
    const [serviceUpdated, setServiceUpdated] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const [branchesUpdated, setBranchesUpdated] = useState(false);
    const [showEditBranch, setShowEditBranch] = useState(false);
    const [showUpdateBusiness, setShowUpdateBusiness] = useState(false);

    const toggleEditBranch = () => {
        setShowEditBranch(!showEditBranch);
    };
    const toggleUpdateBusiness = () => {
        setShowUpdateBusiness(!showUpdateBusiness);
    };

    const togglePassSide = () => {
        setShowPassSide(!showPassSide);
    };
    function toggleDeactivateAcc() {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            setShowDeactivateAcc(!showDeactivateAcc);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    }

    function toggleBusinessSide() {
        setShowBusinessSide(!showBusinessSide);
    }

    function toggleBio() {
        setShowAddBio(!showAddBio);
    }

    function toggleServiceSide() {
        setServiceSide(!serviceSide);
    }

    function toggleBranchLocation() {
        setBranchLocation(!branchLocation);
    }

    const toggleServiceUpdated = () => {
        setServiceUpdated(!serviceUpdated);
    };

    const togglebranchesUpdated = () => {
        setBranchesUpdated(!branchesUpdated);
    };
    useEffect(() => {
        dispatch(editService(''));
    }, []);

    return (
        <div className="dashboard">
            <SidebarLayout>
                {showEditBranch && (
                    <EditBranch open={showEditBranch} setOpen={setShowEditBranch} toggleEditBranch={toggleEditBranch} />
                )}
                {showUpdateBusiness && (
                    <UpdateBusiness
                        open={showUpdateBusiness}
                        setOpen={setShowUpdateBusiness}
                        toggleUpdateBusiness={toggleUpdateBusiness}
                    />
                )}
                {showPassSide ? <ChangePasswordSide togglePassSide={togglePassSide} /> : ''}

                {showDeactivateAcc ? <DeactivateAccount toggleDeactivateAcc={toggleDeactivateAcc} /> : ''}

                {showBusinessSide ? (
                    <AddBusinessSide
                        open={showBusinessSide}
                        setOpen={setShowBusinessSide}
                        toggleBusinessSide={toggleBusinessSide}
                    />
                ) : (
                    ''
                )}

                {showAddBio ? <AddBio toggleBio={toggleBio} /> : ''}

                {serviceSide ? (
                    <AddServiceSide
                        open={serviceSide}
                        setOpen={setServiceSide}
                        toggleServiceSide={toggleServiceSide}
                        toggleServiceUpdated={toggleServiceUpdated}
                    />
                ) : (
                    ''
                )}

                {branchLocation ? (
                    <AddBranchLocation
                        open={branchLocation}
                        setOpen={setBranchLocation}
                        togglebranchesUpdated={togglebranchesUpdated}
                        toggleBranchLocation={toggleBranchLocation}
                        toggleServiceUpdated={toggleServiceUpdated}
                    />
                ) : (
                    ''
                )}

                <ProfileContent
                    toggleEditBranch={toggleEditBranch}
                    toggleUpdateBusiness={toggleUpdateBusiness}
                    toggleBio={toggleBio}
                    togglePassSide={togglePassSide}
                    toggleDeactivateAcc={toggleDeactivateAcc}
                    toggleBusinessSide={toggleBusinessSide}
                    toggleServiceSide={toggleServiceSide}
                    toggleBranchLocation={toggleBranchLocation}
                    serviceUpdated={serviceUpdated}
                    branchesUpdated={branchesUpdated}
                />
            </SidebarLayout>
        </div>
    );
};

export default Profile;
//loyibes370@watrf.com
