/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { MultiSelectWithModal } from 'components/organisms/MultiSelectWithModal';
import { useSelector } from 'react-redux';
import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';

const SelectSigner = ({
    options,
    onChange,
    customers,
    handleSelecChange,
    handleSelectedEmployees,
    handleAssignItYourSelf,
    assignToYourSelf,
    customer,
    selectedCustomer,
    selectedEmployees
}) => {
    const [selectCus, setSelectCus] = useState(customers?.filter((item) => item.user_id !== customer.user_id));
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);

    return (
        <>
            <div className="">
                <div>
                    <div className="add-ann-form my-2">
                        {' '}
                        <label>Select Customers</label>
                    </div>
                    <MultiSelectWithModal
                        inputBorder="1px solid #979797"
                        options={selectCus}
                        atSelect={() => {}}
                        placeholder="Select Customers"
                        selectedOptions={selectedCustomer}
                        atChange={(e) => {
                            handleSelecChange(e);
                        }}
                    />
                </div>
                <div>
                    <div className="add-ann-form my-2">
                        {' '}
                        <label>Select Employees</label>
                    </div>
                    <div>
                        <select>
                            {employees?.map((emp, index) => (
                                <option key={'employees' + index} value={emp?.first_name}>
                                    <span className="flex gap-2 items-center ">
                                        <DefaultProfileViewer
                                            image={emp?.image}
                                            atClick={() => {}}
                                            name={emp?.first_name + ' ' + emp?.last_name}
                                        />
                                        <span>{emp.first_name}</span>
                                    </span>
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex gap-2 items-center">
                    <div className="add-ann-form my-2">
                        {' '}
                        <label>Assign it to your self</label>
                    </div>
                    <CheckboxInput
                        atChange={(e) => {
                            handleAssignItYourSelf(e);
                        }}
                        checked={assignToYourSelf}
                    />
                </div>
            </div>
        </>
    );
};

export default SelectSigner;
