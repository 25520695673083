import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeHttpRequest } from '../../axios';
import { endpoint, methods } from 'apiEndpoints';

const initialState = {
    data: null,
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 15,
    search: '',
    total_count: '',

    isLoading: true,
    error: null,
    selectedDraft: null
};

const getAllDraftThunk = createAsyncThunk('getDraftList/request', async (payload) => {
    try {
        const payloadData = {
            page: initialState?.currentPage,
            page_size: initialState?.itemsPerPage,
            ...payload
        };

        const res = await makeHttpRequest(methods.GET, endpoint.getDraftList, payloadData);
        return res;
    } catch (error) {
        return error;
    }
});

export const draftListSlice = createSlice({
    name: 'getDraftList',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setCountPerPage: (state, action) => {
            state.currentPage = 1;
            state.itemsPerPage = action.payload;
        },
        updateData: (state, action) => {
            state.data = action.payload;
        },
        setSelectedDraft: (state, action) => {
            state.selectedDraft = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDraftThunk.pending, (state) => {
                state.data = [];
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllDraftThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload?.rows;
                state.total_count = action.payload?.count;
                state.totalPages = Math.ceil(action.payload?.count / state.itemsPerPage);
            })
            .addCase(getAllDraftThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.data = [];
            });
    }
});

export const { setCurrentPage, setCountPerPage, updateData, setSelectedDraft, setSearch } = draftListSlice.actions;

export default draftListSlice;
export { getAllDraftThunk };
