import React from 'react';
import { useEffect, useState } from 'react';
import { useRequiredSigningContext } from './provider';
import { SigningFieldContainer } from './signing-field-container';

export const NameField = ({ field, _recipient }) => {
    const { fullName: providedFullName, setFullName: _setProvidedFullName } = useRequiredSigningContext();
    const [fieldData, setfieldData] = useState(field);
    useEffect(() => {
        if (!providedFullName) {
            return;
        }
        const tempData = {
            ...fieldData,
            field_value: providedFullName,
            inserted: true
        };
        setfieldData(tempData);
    }, []);
    const onSign = async () => {
        if (!providedFullName) {
            return;
        }
        const tempData = {
            ...fieldData,
            field_value: providedFullName,
            inserted: true
        };
        setfieldData(tempData);
    };

    const onRemove = async () => {
        const tempData = {
            ...fieldData,
            field_value: '',
            inserted: false
        };
        setfieldData(tempData);
    };

    return (
        <SigningFieldContainer field={fieldData} onSign={onSign} onRemove={onRemove}>
            {!fieldData.inserted && (
                <p className="group-hover:text-primary text-muted-foreground text-lg duration-200">Name</p>
            )}

            {fieldData.inserted && <p className="text-muted-foreground duration-200">{fieldData.field_value}</p>}
        </SigningFieldContainer>
    );
};
