import React from 'react';
import { cn } from 'utills/tailwindUtil';

export const DefaultLightBgCard = ({ title, children, rightButton, sx, childsx = '', titleSx = '', htmlFor = '' }) => {
    return (
        <div className={`border  rounded-xl mt-5 ${sx}`}>
            <label htmlFor={htmlFor}>
                <div className="bg-dimThird relative  rounded-tl-xl rounded-tr-xl flex justify-between items-center">
                    {/* <div className="border-l h-7 border-b w-[10px] rounded-bl-md border-thirdbg  absolute top-0 left-2"></div> */}
                    <div className={cn('px-5 py-3 text-xl font-fira font-semibold', titleSx)}>{title}</div>
                    {rightButton && <div className="py-2 px-3"> {rightButton} </div>}
                </div>
            </label>
            <div className={cn('p-5', childsx)}>{children}</div>
        </div>
    );
};
