import React from 'react';
import { motion } from 'framer-motion';
import '../../css-steps/signup-css/signupSuccess.css';
import { useNavigate } from 'react-router-dom';
import Ripples from 'react-ripples';
import { SuccessTick } from '../../utills/svgs/SuccessTick';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { stopHeartbeatInterval } from 'utills/socketEvents';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { loginThunk } from 'store/auth/slices';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN } from 'utills/globalVars';
import { useEffect, useState } from 'react';
import { Loader } from 'components/common/Loader';
import useRedirectToDashboardHook from 'Hooks/useRedirectToDashboardHook';
const SignupSuccess = ({ pass }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { redirectToDashboard } = useRedirectToDashboardHook();
    const [password, setPassword] = useState('');

    const { isLoading } = useSelector((state) => state.global);

    const user = localStorage.getItem('signupUser');
    const register = user ? JSON.parse(user) : {};

    const { intervalId } = useSelector((state) => state.global);

    const handleNavigation = (payload) => {
        localStorage.setItem('signupStepper', 1);

        if (payload?.business?.onboarded) {
            localStorage.setItem('onboarding', true);
        } else {
            localStorage.setItem('onboarding', false);
        }

        if (payload?.email_2fa) {
            localStorage.setItem('email_2fa', true);
        } else {
            localStorage.setItem('email_2fa', false);
        }

        if (!payload?.email_2fa && !payload?.google_authenticator_2fa) {
            navigate('/authentication-and-security');
        }
    };

    const handleDirectLogin = () => {
        stopHeartbeatInterval(dispatch, intervalId);
        localStorage.setItem('socket_id', '');
        localStorage.setItem('signupUser', '');
        localStorage.setItem('questions', '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.info('password', password);

        localStorage.removeItem('pre_auth_token');
        const loginData = {
            email: register?.email,
            password: password,
            user_type: 'employee',
            fcm_token: 'dummysts'
        };

        dispatch(setLoader(true));
        await dispatch(loginThunk(loginData))
            .then((response) => {
                if (response.payload) {
                    handleDirectLogin();
                    if (response.payload?.temp_token) {
                        localStorage.setItem('temp_token', response.payload?.temp_token);
                        localStorage.removeItem('pre_auth_token');
                        localStorage.removeItem('access_token');
                        if (response.payload?.is_password_change_required) {
                            navigate(`/update-ot-password/${response.payload.user_id}`);
                            return;
                        }

                        handleNavigation(response.payload);
                    } else {
                        localStorage.removeItem('temp_token');
                        localStorage.removeItem('pre_auth_token');
                        const payload = response.payload;
                        if (payload?.business?.onboarded) {
                            localStorage.setItem('onboarding', true);
                        } else {
                            localStorage.setItem('onboarding', false);
                        }

                        if (payload?.email_2fa) {
                            localStorage.setItem('email_2fa', true);
                        } else {
                            localStorage.setItem('email_2fa', false);
                        }

                        localStorage.setItem('verified', true);

                        localStorage.setItem(ACCESS_TOKEN, payload.access_token);
                        localStorage.setItem(REFRESH_TOKEN, payload.refresh_token);
                        localStorage.setItem(BUSINESS_ID, payload?.business?.business_id);

                        if (!payload?.business?.onboarded && !payload.is_employee) {
                            navigate('/onboarding');
                            return;
                        }
                        // navigate('/business/dashboard');
                        redirectToDashboard();
                    }
                }
            })
            .catch((_error) => {
                dispatch(setLoader(false));
            })
            .finally(() => {
                dispatch(setLoader(false));
                //dd
            });
    };

    useEffect(() => {
        if (pass) {
            setPassword(pass);
        } else {
            stopHeartbeatInterval(dispatch, intervalId);
            localStorage.setItem('socket_id', '');
            localStorage.setItem('signupUser', '');
            localStorage.setItem('questions', '');
            navigate('/');
        }
    }, [pass]);
    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8 ">
            {isLoading && <Loader />}
            <AuthCardLayout>
                {' '}
                <div className="flex justify-center items-center mt-8">
                    <motion.div
                        initial={{ rotate: 100, opacity: 0 }}
                        animate={{ rotate: 0, opacity: 1 }}
                        transition={{ delay: 0.3, type: 'spring', duration: 0.5 }}
                        className="svg-wrapper scale-50 "
                    >
                        <SuccessTick />
                    </motion.div>
                </div>
                <div className="mt-5">
                    <h1 className="text-center card-layout-title-text  ">Registration Successful</h1>
                </div>
                <div className="px-5 mt-2">
                    <p className="text-center table-title font-poppins">Thanks, You have successfully signed up</p>
                </div>
                <div className="s-btn-wrapper mt-5 mb-52">
                    <Ripples during={2000} color="#ffec3b">
                        <button onClick={handleSubmit} className="ann-btn buttons-font font-fira px-12 py-2 rounded-lg">
                            Continue
                        </button>
                    </Ripples>
                </div>
            </AuthCardLayout>
        </div>
    );
};

export default SignupSuccess;
