import React from 'react';

const ExcelFile = ({ className }) => {
    return (
        <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M39.6 42H33.6L27 30.6L20.4 42H14.4L24 27L14.4 12H20.4L27 23.4L33.6 12H39.6L30 27M48 0H6C2.67 0 0 2.67 0 6V48C0 49.5913 0.632141 51.1174 1.75736 52.2426C2.88258 53.3679 4.4087 54 6 54H48C49.5913 54 51.1174 53.3679 52.2426 52.2426C53.3679 51.1174 54 49.5913 54 48V6C54 4.4087 53.3679 2.88258 52.2426 1.75736C51.1174 0.632141 49.5913 0 48 0Z"
                fill="#0E5F76"
            />
        </svg>
    );
};

export default ExcelFile;
