import React, { useState } from 'react';
import { PayrollSettingsPage } from '../../components/payrol-pages/payroll-settings/PayrollSettingsPage';
import { OrganizationSettings } from '../../components/payrol-pages/payroll-settings/payroll-settings-sides/OrganizationSettings';
import { STPSettings } from '../../components/payrol-pages/payroll-settings/payroll-settings-sides/stp-settings/STPSettings';
import { EmpPaymentOptions } from '../../components/payrol-pages/payroll-settings/emp-payment-options/EmpPaymentOptions';
import { SuperannuationSettings } from '../../components/payrol-pages/payroll-settings/superannuation-settings/css/SuperannuationSettings';
export const PayrollSettings = () => {
    const [showOrganizationSettings, setShowOrganizationSettings] = useState(false);
    const [showStpSettings, setShowStpSettings] = useState(false);
    const [showEmpPaymentOptions, setShowEmpPaymentOptions] = useState(false);
    const [showSuperSettings, setShpwSuperSettings] = useState(false);

    const toggleShowSuperSettings = () => {
        setShpwSuperSettings(!showSuperSettings);
    };

    const toggleEmpPaymentOptions = () => {
        setShowEmpPaymentOptions(!showEmpPaymentOptions);
    };

    const toggleStpSettings = () => {
        setShowStpSettings(!showStpSettings);
    };

    const toggleOrganizationSettings = () => {
        setShowOrganizationSettings(!showOrganizationSettings);
    };
    return (
        <div className="app-dashboard">
            {showSuperSettings && <SuperannuationSettings toggleShowSuperSettings={toggleShowSuperSettings} />}
            {showEmpPaymentOptions && <EmpPaymentOptions toggleEmpPaymentOptions={toggleEmpPaymentOptions} />}
            {showStpSettings && <STPSettings toggleStpSettings={toggleStpSettings} />}

            {showOrganizationSettings && (
                <OrganizationSettings toggleOrganizationSettings={toggleOrganizationSettings} />
            )}

            <div className={`content ${''}`}>
                <PayrollSettingsPage
                    toggleStpSettings={toggleStpSettings}
                    toggleOrganizationSettings={toggleOrganizationSettings}
                    toggleEmpPaymentOptions={toggleEmpPaymentOptions}
                    toggleShowSuperSettings={toggleShowSuperSettings}
                />
            </div>
        </div>
    );
};
