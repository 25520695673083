import React, { useState } from 'react';
import ThreadListPage from '../../components/workplace-content/workplace-threads/my-threads/ThreadsListPage';
import MyNewThread from '../../components/workplace-content/workplace-threads/my-threads/MyNewThread';
import StopThread from '../../components/workplace-content/workplace-threads/my-threads/StopThread';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { EditThread } from 'components/workplace-content/workplace-threads/my-threads/EditThread';
import { SidebarLayout } from 'layouts/SidebarLayout';
const ThreadList = () => {
    const [showMyNewThread, setShowMyNewThread] = useState(false);
    const [showStopThread, setShowStopThread] = useState(false);
    const [threadsUpdated, setThreadsUpdated] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [editThread, setEditThread] = useState(false);

    const toggleEditThread = () => {
        setEditThread(!editThread);
    };
    const toggleMyNewThread = () => {
        setShowMyNewThread(!showMyNewThread);
    };

    function toggleStopThread() {
        setShowStopThread(!showStopThread);
    }
    const closeThreadHandler = (id) => {
        setThreadId(id);
    };

    const toggleThreadUpdate = () => {
        setThreadsUpdated(!threadsUpdated);
    };

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showMyNewThread && (
                    <MyNewThread
                        toggleMyNewThread={toggleMyNewThread}
                        toggleThreadUpdate={toggleThreadUpdate}
                        open={showMyNewThread}
                        setOpen={setShowMyNewThread}
                    />
                )}
                {showStopThread && (
                    <StopThread
                        open={showStopThread}
                        setOpen={setShowStopThread}
                        toggleStopThread={toggleStopThread}
                        threadId={threadId}
                        toggleThreadUpdate={toggleThreadUpdate}
                    />
                )}
                {editThread && (
                    <EditThread open={editThread} setOpen={setEditThread} toggleThreadUpdate={toggleThreadUpdate} />
                )}
                <RoleBasedGuard module={'Threads'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    {' '}
                    <div>
                        <ThreadListPage
                            toggleStopThread={toggleStopThread}
                            toggleMyNewThread={toggleMyNewThread}
                            closeThreadHandler={closeThreadHandler}
                            threadsUpdated={threadsUpdated}
                            toggleThreadUpdate={toggleThreadUpdate}
                            toggleEditThread={toggleEditThread}
                        />
                    </div>{' '}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};

export default ThreadList;
