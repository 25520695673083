import React, { useState } from 'react';
import { BgTimes } from 'utills/svgs/BgTimes';
import { LinkIcon } from 'utills/svgs/LinkIcon';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID, TOAST_TYPE_ERROR, USER_TYPE, acceptFilesFormats, acceptFormate } from 'utills/globalVars';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { toastHandler } from 'responseHanlder';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import Input from 'components/atoms/Input';
import { Loader } from 'components/common/Loader';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { Chip } from 'components/atoms/buttons/Chip';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
// import DraftEditor from 'components/molecules/DraftEditor';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import PreSelectedCheckList from 'global-components/PreSelectedCheckList';
import { createTemplateTaskThunk } from 'store/settings/JobPortal';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

const CreateTaskTemplate = ({ open, setOpen, handleTasksUpdate, extraPayload }) => {
    const dispatch = useDispatch();

    const [errors, setErrors] = useState({});

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [editorContent, setEditorContent] = useState('');
    const [checklists, setChecklists] = useState([]);
    const [listname, setListName] = useState('');
    const [showAddItem, setShowAddItem] = useState(false);
    const [addItemIndex, setAddItemIndex] = useState(null);
    const [itemName, setItemName] = useState('');
    const [delIconIndex, setDelIconIndex] = useState(null);
    const [showDelIcon, setShowDelIcon] = useState(false);
    const [listIndex, setListIndex] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleSelectedFiles = (files) => {
        setSelectedFiles([...files, ...selectedFiles]);
    };
    const onRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };

    const handlePreviewFile = (file) => {
        if (file instanceof File || file instanceof Blob) {
            const url = URL.createObjectURL(file);

            if (file.type.includes('jpg') || file.type.includes('png') || file.type.includes('jpeg')) {
                setSelectedImage(url);
            } else if (file.type.includes('application/pdf')) {
                setSelectedPdf(url);
            }
        } else {
            console.error('Invalid file object passed to handlePreviewFile.');
        }
    };

    const { sideLoader } = useSelector((state) => state.global);
    const [title, setTitle] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [preBuildCheckListView, setPreBuildCheckListView] = useState(false);
    const [files, setFiles] = useState([]);
    const { isLoading } = useSelector((state) => state.global);

    const handleChecklistName = (index) => {
        const newArr = [...checklists];
        newArr[index].title = listname;
        setChecklists(newArr);
        setListName('');
    };

    const addItem = (index) => {
        setAddItemIndex(index);
        setShowAddItem(!showAddItem);
    };

    const handleItemName = (index) => {
        if (itemName !== '') {
            const newArr = [...checklists];
            newArr[index].taskCheckListItems.push({
                value: itemName,
                is_completed: 'false',
                sequence: (newArr[index].taskCheckListItems?.length + 1).toString() || '1'
            });
            setChecklists(newArr);
            setShowAddItem(false);
            setItemName('');
        }
    };

    const deleteItem = (index, checkIndex) => {
        const newArr = [...checklists];
        newArr[index].taskCheckListItems.splice(checkIndex, 1);
        setChecklists(newArr);
    };

    const handleDelIcon = (index, checkIndex) => {
        setListIndex(index);
        setDelIconIndex(checkIndex);
        setShowDelIcon(true);
    };
    const hideDelIcon = () => {
        setShowDelIcon(false);
    };

    const handleShowListDel = (index) => {
        setListIndex(index);
    };

    const handleHideListDel = () => {};

    const handleListDelete = (index) => {
        const newArr = [...checklists];
        newArr.splice(index, 1);
        setChecklists(newArr);
    };

    const validateDateTime = () => {
        let errorsObj = {};
        if (!title) {
            errorsObj.title = true;
            toastHandler('Title is required', TOAST_TYPE_ERROR);
        }
        if (!templateName) {
            errorsObj.templateName = true;
            toastHandler('Template Name is required', TOAST_TYPE_ERROR);
        }

        if (!editorContent || editorContent === '' || editorContent.replace(/<\/?[^>]+(>|$)/g, '').trim() === '') {
            errorsObj.editorContent = true;
            toastHandler('Task Description is required', TOAST_TYPE_ERROR);
        }

        setErrors(errorsObj);
        if (Object.entries(errorsObj).length > 0) {
            return false;
        } else {
            return true;
        }
    };

    const defAddTask = async () => {
        const valid = validateDateTime();
        if (!valid) {
            return;
        }

        const formData = new FormData();
        formData.append('business_id', business_id.trim());
        formData.append('name', templateName.trim());
        formData.append('title', title.trim());
        formData.append('description', editorContent.trim());
        formData.append('taskCheckList', JSON.stringify(checklists));

        if (extraPayload) {
            for (const [key, value] of Object.entries(extraPayload)) {
                formData.append(key, value);
            }
        }

        formData.append('uploaded_by', USER_TYPE);

        const totalFiles = [...selectedFiles];

        if (totalFiles) {
            const sanitizedFilesPromises = totalFiles.map((file) => createNewPDFWithoutMetadata(file));
            const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
            sanitizedFiles.forEach((sanitizedFile) => {
                formData.append('files', sanitizedFile);
            });
        }

        dispatch(setLoader(true));
        dispatch(setSideLoader(true));
        dispatch(createTemplateTaskThunk(formData))
            .then((response) => {
                if (response.payload) {
                    if (handleTasksUpdate) {
                        handleTasksUpdate();
                    }
                    setOpen(false);
                    resetFormdata();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
                dispatch(setLoader(false));
            });
    };

    const resetFormdata = () => {
        setErrors({});

        setChecklists([]);
        setListName('');
        setShowAddItem(false);

        setAddItemIndex(null);
        setItemName('');
        setDelIconIndex(null);
        setShowDelIcon(false);
        setListIndex(null);

        setTemplateName('');
        setTitle('');
        setEditorContent('');
        setFiles([]);
    };

    const handleFileRemove = (index) => {
        const newArr = [...files];
        newArr.splice(index, 1);
        setFiles(newArr);
    };

    const items = [];
    if (files) {
        for (let i = 0; i < files.length - 1; i++) {
            items.push(
                <button className="flex items-center gap-1 selected-file ">
                    <LinkIcon />
                    <span>{files[i]?.name?.slice(0, 10)}</span>
                    <span onClick={() => handleFileRemove(files[i])} className="cursor-pointer">
                        <BgTimes />
                    </span>{' '}
                </button>
            );
        }
    }

    const handleSelectedPrebuildList = (prebuiltList) => {
        const newArr = [...checklists];
        prebuiltList.map((obj) => {
            newArr.push({
                title: obj.label,
                duration_label: obj.duration_label,
                duration_value: obj.duration_value,
                taskCheckListItems: obj?.items?.map((item, ind) => ({
                    value: item?.label,
                    sequence: item?.sequence && item?.sequence !== 'undefined' ? item?.sequence : ind.toString(),
                    is_completed: 'false'
                }))
            });
        });

        setChecklists(newArr);
        setPreBuildCheckListView(false);
        setShowAddItem(false);
        setItemName('');
    };
    const { hasPermissions } = useRoleGuard();

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                size={'max-w-7xl'}
                footer={
                    <div className="">
                        {hasPermissions('Tasks', ['admin']) && (
                            <div className="flex items-center gap-3 justify-center mt-9 mb-4">
                                <Button
                                    iconSet={{
                                        rightIcon: sideLoader ? SmallLoaderWhite : null
                                    }}
                                    title={`Create Task`}
                                    atClick={defAddTask}
                                    classes="primaryButton flex gap-3 bg-leaner child:text-semibold text-white py-3 px-7"
                                />
                            </div>
                        )}
                    </div>
                }
            >
                <RoleBasedGuard module={'Tasks'} permissions={['admin']} sx="h-[50vh]">
                    {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
                    {selectedPdf ? (
                        <GlobalPDFViewer
                            url={selectedPdf}
                            setOpen={() => setSelectedPdf('')}
                            open={selectedPdf ? true : false}
                        />
                    ) : (
                        ''
                    )}{' '}
                    {isLoading && <Loader />}
                    <div className="add-detail px-5 text-black">
                        <div className="text-3xl font-fira font-semibold">Task Template</div>
                    </div>
                    <div className="add-task-container1  px-5 mt-5 text-black">
                        <div className=" add-ann-form">
                            <DefaultLightBgCard title="Task Template">
                                <div className="">
                                    <div className="mt-4 default-forms">
                                        <div>
                                            <label className="inputLabel">Task Template Name</label>
                                        </div>
                                        <div>
                                            <Input
                                                name={'Template'}
                                                useWithoutForm
                                                onChange={(e) => {
                                                    setTemplateName(e.target.value);
                                                    setErrors({ ...errors, templateName: false });
                                                }}
                                                placeholder="Enter Task Template Name"
                                                maxLength={90}
                                                hasError={errors?.templateName || false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </DefaultLightBgCard>

                            <DefaultLightBgCard title="Checklist">
                                <div className="">
                                    <div>
                                        <div className="flex justify-between items-center">
                                            <div className="text-xl font-semibold"></div>
                                            <div className="flex items-center gap-2">
                                                <button
                                                    className="bg-linear text-white font-fira buttons-font-lighter px-8 h-[2.5rem] rounded-xl"
                                                    onClick={() => setPreBuildCheckListView(true)}
                                                >
                                                    Add Prebuilt Item
                                                </button>
                                            </div>
                                        </div>
                                        <div className=" mt-1">
                                            {checklists &&
                                                checklists?.map((list, index) => (
                                                    <div key={index} className={`${index > 0 && ' mt-5'} border-b`}>
                                                        {!list?.title ? (
                                                            <div>
                                                                <div className="flex items-center justify-between">
                                                                    <label>Checklist Name</label>
                                                                </div>
                                                                <div className="flex gap-2 items-center">
                                                                    {' '}
                                                                    <input
                                                                        className="!rounded-xl !pl-4"
                                                                        onChange={(e) => setListName(e.target.value)}
                                                                        value={listname}
                                                                        placeholder="Title"
                                                                        type="text"
                                                                    />{' '}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div className="flex items-center justify-between w-full md:w-1/2">
                                                                    <label className="inputLabel">
                                                                        {' '}
                                                                        Checklist Title
                                                                    </label>{' '}
                                                                    <Chip
                                                                        title={list?.duration_label}
                                                                        classes={'bg-gray-200 px-3'}
                                                                    />
                                                                </div>
                                                                <div
                                                                    onMouseEnter={() => handleShowListDel(index)}
                                                                    onMouseLeave={() => handleHideListDel()}
                                                                    className="flex items-center justify-between gap-2 mt-3 w-full md:w-1/2 !break-all"
                                                                >
                                                                    <div className=" h-[3rem] w-[90%] p-3 border border-secondarybg rounded-xl flex items-center !pl-2 !break-all">
                                                                        {list.title}{' '}
                                                                    </div>
                                                                    <div
                                                                        onClick={() => handleListDelete(index)}
                                                                        className="cursor-pointer"
                                                                    >
                                                                        <BgDeleteIcon />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div>
                                                            <div>
                                                                {list?.taskCheckListItems?.length > 0 && (
                                                                    <div className="mt-5 w-full md:w-1/2">
                                                                        {list?.taskCheckListItems
                                                                            .sort((a, b) => {
                                                                                if (
                                                                                    a?.sequence == null &&
                                                                                    b?.sequence == null
                                                                                )
                                                                                    return 0;
                                                                                if (a?.sequence == null) return 1;
                                                                                if (b?.sequence == null) return -1;
                                                                                return (
                                                                                    Number(a.sequence) -
                                                                                    Number(b.sequence)
                                                                                );
                                                                            })
                                                                            .map((check, checkIndex) => (
                                                                                <div
                                                                                    key={checkIndex}
                                                                                    className="contents p-0 py-2"
                                                                                    onMouseEnter={() =>
                                                                                        handleDelIcon(index, checkIndex)
                                                                                    }
                                                                                    onMouseLeave={hideDelIcon}
                                                                                >
                                                                                    <div className="flex justify-between items-center my-2">
                                                                                        {' '}
                                                                                        <div className="flex items-center gap-3">
                                                                                            <div>
                                                                                                {' '}
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    disabled
                                                                                                    className="!w-2 !h-2 !rounded-md border-secondarybg  focus:ring-gray-800"
                                                                                                />{' '}
                                                                                            </div>
                                                                                            <div className="primary-fonts1 mt-2 !text-[16px] !break-all">
                                                                                                {check?.value}{' '}
                                                                                            </div>
                                                                                        </div>{' '}
                                                                                        <div
                                                                                            onClick={() =>
                                                                                                deleteItem(
                                                                                                    index,
                                                                                                    checkIndex
                                                                                                )
                                                                                            }
                                                                                            className="cursor-pointer"
                                                                                        >
                                                                                            {' '}
                                                                                            {showDelIcon &&
                                                                                            delIconIndex ===
                                                                                                checkIndex &&
                                                                                            listIndex === index ? (
                                                                                                <BgDeleteIcon />
                                                                                            ) : (
                                                                                                <BgDeleteIcon />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {showAddItem && addItemIndex === index ? (
                                                                <div>
                                                                    <div className="mt-6 add-ann-form">
                                                                        <div>
                                                                            {' '}
                                                                            <label className="inputLabel">
                                                                                Add an Item
                                                                            </label>
                                                                        </div>
                                                                        <input
                                                                            type="text"
                                                                            maxLength={70}
                                                                            onChange={(e) =>
                                                                                setItemName(e.target.value)
                                                                            }
                                                                        />
                                                                        <div className="flex items-center gap-3 mt-3">
                                                                            <button
                                                                                onClick={() => handleItemName(index)}
                                                                                className="bg-linear text-white font-fira buttons-font-lighter px-8 h-[2.5rem] rounded-xl "
                                                                            >
                                                                                Add
                                                                            </button>
                                                                            <button
                                                                                className="checklist-cancel-btn  font-fira buttons-font-lighter rounded-xl px-5 h-[2.5rem]"
                                                                                onClick={() => addItem(index)}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                        <div className="my-5 mt-7 flex gap-3 items-center">
                                                            {showAddItem && addItemIndex === index ? (
                                                                ''
                                                            ) : (
                                                                <div className="flex items-center gap-2">
                                                                    {!list.title ? (
                                                                        <button
                                                                            onClick={() => handleChecklistName(index)}
                                                                            className="ann-btn1 bg-leaner rounded-xl px-5 py-2"
                                                                        >
                                                                            Add Checklist
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            disabled={!list?.title ? true : false}
                                                                            className="ann-btn1 bg-leaner rounded-xl px-5 py-2"
                                                                            onClick={() => addItem(index)}
                                                                        >
                                                                            Add An Item
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            )}

                                                            <hr className="mt-4"></hr>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </DefaultLightBgCard>

                            <DefaultLightBgCard title="Task Information">
                                <div className="">
                                    <div className="mt-4 default-forms">
                                        <div>
                                            <label className="inputLabel">Title</label>
                                        </div>
                                        <div>
                                            <Input
                                                name={'title'}
                                                useWithoutForm
                                                onChange={(e) => {
                                                    setTitle(e.target.value);
                                                    setErrors({ ...errors, title: false });
                                                }}
                                                placeholder="Title"
                                                maxLength={90}
                                                hasError={errors?.title || false}
                                            />
                                            <div className="text-xs text-right mt-1 mr-1 text-secondarybg">
                                                {title.length}/90
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <label className="inputLabel">Description</label>
                                    </div>
                                    <div className="mt-2    ">
                                        <DraftEditorNew
                                            onChange={(val) => {
                                                setEditorContent(val);
                                                setErrors({ ...errors, editorContent: false });
                                            }}
                                            initialValue={editorContent || ''}
                                            required={true}
                                            placeholder="Enter description"
                                            hasError={errors?.editorContent || false}
                                        />
                                    </div>
                                </div>
                            </DefaultLightBgCard>

                            <DefaultLightBgCard title={'Attachments'}>
                                <div className="default-forms">
                                    <label className="mb-2"> Upload Attachment</label>
                                    <DragFile
                                        selectedFiles={selectedFiles}
                                        atChange={(file) => {
                                            handleSelectedFiles(file);
                                        }}
                                        accept={[...acceptFormate, ...acceptFilesFormats]}
                                        isMultiple={true}
                                        hideSelectedFiles={true}
                                        dragMessage="Drag files here to upload, or browse for files"
                                        dropMessage="Drop the selected files in this box... "
                                        message="This file type is not allowed"
                                    />

                                    <div className="mt-5 flex gap-5 items-center flex-wrap">
                                        {selectedFiles?.map((file, index) => (
                                            <FileCardPreview
                                                url={file?.type}
                                                key={index}
                                                showImage={true}
                                                name={file?.name}
                                                size={file?.size}
                                                extension={
                                                    file?.type?.includes('.')
                                                        ? file?.type?.split('.')?.pop()?.toLowerCase()
                                                        : file?.type?.split('/').pop().toLowerCase()
                                                }
                                                imgPrperties={{
                                                    url: file,
                                                    type: file?.type,
                                                    sx: ''
                                                }}
                                                onClick={() => handlePreviewFile(file)}
                                                onRemove={() => onRemoveFile(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </DefaultLightBgCard>
                        </div>
                        <PreSelectedCheckList
                            open={preBuildCheckListView}
                            setOpen={setPreBuildCheckListView}
                            handleSelectedPrebuildList={handleSelectedPrebuildList}
                        />
                    </div>
                </RoleBasedGuard>
            </Slideover>
        </>
    );
};

export default CreateTaskTemplate;
