import React from 'react';

export const OutlookIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M79.9998 42.403C80.0043 41.7775 79.6809 41.1953 79.1478 40.8681H79.1384L79.1049 40.8495L51.3841 24.4403C51.2643 24.3593 51.1401 24.2855 51.0119 24.2188C49.9416 23.6666 48.6701 23.6666 47.5998 24.2188C47.4716 24.2855 47.3474 24.3593 47.2278 24.4403L19.5069 40.8495L19.4734 40.8681C18.6261 41.395 18.3664 42.509 18.8933 43.3561C19.0486 43.6058 19.2623 43.8138 19.5161 43.9621L47.2371 60.3713C47.3573 60.4515 47.4814 60.5255 47.6093 60.5928C48.6796 61.145 49.9509 61.145 51.0213 60.5928C51.1491 60.5255 51.2733 60.4516 51.3934 60.3713L79.1143 43.9621C79.6681 43.6391 80.0061 43.0441 79.9998 42.403Z"
                fill="#0A2767"
            />
            <path
                d="M22.7384 31.4494H40.9301V48.1248H22.7384V31.4494ZM76.2789 14.4987V6.8708C76.3226 4.96365 74.8131 3.38164 72.9059 3.33594H25.6986C23.7914 3.38164 22.2817 4.96365 22.3254 6.8708V14.4987L50.2324 21.9406L76.2789 14.4987Z"
                fill="#0364B8"
            />
            <path d="M22.3254 14.4922H40.9301V31.2363H22.3254V14.4922Z" fill="#0078D4" />
            <path d="M59.5348 14.4922H40.9302V31.2363L59.5348 47.9805H76.279V31.2363L59.5348 14.4922Z" fill="#28A8EA" />
            <path d="M40.9302 31.2422H59.5348V47.9864H40.9302V31.2422Z" fill="#0078D4" />
            <path d="M40.9302 47.9844H59.5348V64.7287H40.9302V47.9844Z" fill="#0364B8" />
            <path d="M22.7385 48.125H40.9302V63.284H22.7385V48.125Z" fill="#14447D" />
            <path d="M59.5349 47.9844H76.2791V64.7287H59.5349V47.9844Z" fill="#0078D4" />
            <path
                d="M79.1479 43.8629L79.1126 43.8816L51.3916 59.4722C51.2707 59.5467 51.1479 59.6174 51.0196 59.6806C50.5487 59.9049 50.0374 60.0314 49.5162 60.0527L48.0019 59.1671C47.8739 59.1029 47.7496 59.0314 47.6297 58.9532L19.5367 42.9197H19.5237L18.6047 42.4062V73.9671C18.6191 76.0729 20.3371 77.7684 22.4427 77.7551H76.2214C76.2531 77.7551 76.2809 77.7402 76.3144 77.7402C76.7591 77.7118 77.1976 77.6203 77.6167 77.4686C77.7977 77.3919 77.9726 77.3011 78.1396 77.1969C78.2641 77.1262 78.4781 76.9719 78.4781 76.9719C79.4311 76.2666 79.9952 75.1528 79.9999 73.9673V42.4062C79.9994 43.0099 79.6737 43.5666 79.1479 43.8629Z"
                fill="url(#paint0_linear_23962_3092)"
            />
            <path
                opacity="0.5"
                d="M78.5115 42.2809V44.2158L49.5255 64.1731L19.5163 42.9321C19.5163 42.9218 19.5078 42.9134 19.4976 42.9134L16.7441 41.2578V39.8623L17.879 39.8438L20.279 41.2204L20.3348 41.2391L20.5395 41.3693C20.5395 41.3693 48.744 57.4623 48.8185 57.4994L49.8975 58.1321C49.9906 58.0948 50.0836 58.0576 50.1951 58.0204C50.251 57.9832 78.1951 42.2623 78.1951 42.2623L78.5115 42.2809Z"
                fill="#0A2767"
            />
            <path
                d="M79.1479 43.8629L79.1126 43.8834L51.3916 59.4741C51.2707 59.5486 51.1479 59.6192 51.0196 59.6824C49.9429 60.2084 48.6841 60.2084 47.6074 59.6824C47.4799 59.6194 47.3557 59.5497 47.2352 59.4741L19.5144 43.8834L19.4811 43.8629C18.9457 43.5728 18.6102 43.0151 18.6047 42.4062V73.9671C18.6181 76.0724 20.3356 77.7684 22.4409 77.7551H76.1637C78.2691 77.7684 79.9866 76.0726 80.0001 73.9673V42.4062C79.9994 43.0099 79.6737 43.5666 79.1479 43.8629Z"
                fill="#1490DF"
            />
            <path
                opacity="0.1"
                d="M51.7952 59.2422L51.3804 59.4747C51.2601 59.5514 51.1359 59.6215 51.0082 59.685C50.5511 59.9093 50.0541 60.0409 49.5459 60.0719L60.0929 72.5445L78.4911 76.978C78.9952 76.5972 79.3962 76.0964 79.6576 75.5212L51.7952 59.2422Z"
                fill="black"
            />
            <path
                opacity="0.05"
                d="M53.6742 58.1875L51.3804 59.4767C51.2601 59.5533 51.1359 59.6235 51.0082 59.687C50.5511 59.9113 50.0541 60.0428 49.5459 60.0738L54.4872 73.6982L78.4966 76.9743C79.4424 76.2642 79.9992 75.1507 79.9997 73.9678V73.5603L53.6742 58.1875Z"
                fill="black"
            />
            <path
                d="M22.4931 77.7551H76.1581C76.9839 77.7593 77.7892 77.4984 78.4557 77.0109L48.0001 59.1709C47.8721 59.1068 47.7477 59.0352 47.6279 58.9569L19.5349 42.9234H19.5219L18.6047 42.4062V73.8593C18.6026 76.0088 20.3436 77.7529 22.4931 77.7551Z"
                fill="#28A8EA"
            />
            <path
                opacity="0.1"
                d="M44.6512 21.6289V61.3127C44.6479 62.7041 43.8019 63.9546 42.5117 64.4754C42.1119 64.6472 41.6816 64.7359 41.2466 64.7359H18.6047V20.0793H22.3256V18.2188H41.2466C43.1261 18.2259 44.6471 19.7494 44.6512 21.6289Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M42.7907 23.4885V63.1721C42.7954 63.6216 42.7001 64.0665 42.5117 64.4745C41.9949 65.748 40.7604 66.5835 39.3861 66.5898H18.6047V20.0783H39.3861C39.9259 20.0728 40.4579 20.2075 40.9302 20.469C42.0707 21.0436 42.7902 22.2115 42.7907 23.4885Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M42.7907 23.4885V59.4513C42.7817 61.3299 41.2647 62.8526 39.3861 62.869H18.6047V20.0783H39.3861C39.9259 20.0728 40.4579 20.2075 40.9302 20.469C42.0707 21.0436 42.7902 22.2115 42.7907 23.4885Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M40.9302 23.4883V59.4511C40.9282 61.3328 39.4072 62.8596 37.5256 62.8688H18.6047V20.0781H37.5256C39.4069 20.0791 40.9312 21.6051 40.9302 23.4865V23.4883Z"
                fill="black"
            />
            <path
                d="M3.41022 20.0781H37.52C39.4033 20.0781 40.9302 21.605 40.9302 23.4883V57.5981C40.9302 59.4815 39.4033 61.0083 37.52 61.0083H3.41022C1.52679 61.0083 0 59.4815 0 57.5981V23.4883C0 21.605 1.52683 20.0781 3.41022 20.0781Z"
                fill="url(#paint1_linear_23962_3092)"
            />
            <path
                d="M10.6585 34.3831C11.499 32.5925 12.8557 31.0938 14.5543 30.08C16.4355 29.003 18.5774 28.466 20.7441 28.5283C22.7522 28.4848 24.7339 28.9938 26.4724 30C28.1071 30.9746 29.4236 32.403 30.2622 34.1115C31.1756 35.9941 31.6307 38.066 31.5906 40.158C31.6349 42.3445 31.1666 44.5108 30.2231 46.4836C29.3646 48.2531 28.0062 49.7323 26.3162 50.7385C24.5106 51.7755 22.4554 52.2978 20.3739 52.2491C18.3227 52.2986 16.2975 51.7838 14.519 50.7608C12.8702 49.7846 11.5372 48.3548 10.679 46.6416C9.76027 44.7863 9.29969 42.7378 9.33574 40.6678C9.29746 38.5 9.74966 36.3516 10.6585 34.3831ZM14.811 44.4855C15.2592 45.6176 16.0191 46.5995 17.0027 47.3171C18.0044 48.0173 19.2041 48.3785 20.4259 48.3478C21.7271 48.3993 23.0097 48.0256 24.0799 47.2836C25.0509 46.5681 25.7909 45.5836 26.2082 44.452C26.6747 43.1881 26.9049 41.8493 26.8872 40.5021C26.9017 39.1423 26.6852 37.7898 26.2472 36.5023C25.8604 35.3398 25.1442 34.3146 24.1859 33.5515C23.1426 32.7743 21.8634 32.3801 20.5636 32.4353C19.3152 32.403 18.0887 32.767 17.0604 33.4753C16.0601 34.196 15.2858 35.1865 14.8278 36.331C13.8118 38.9546 13.8065 41.862 14.8129 44.4891L14.811 44.4855Z"
                fill="white"
            />
            <path d="M59.5349 14.4922H76.2791V31.2363H59.5349V14.4922Z" fill="#50D9FF" />
            <defs>
                <linearGradient
                    id="paint0_linear_23962_3092"
                    x1="49.3017"
                    y1="42.4064"
                    x2="49.3017"
                    y2="77.7548"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#35B8F1" />
                    <stop offset="1" stopColor="#28A8EA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_23962_3092"
                    x1="7.11"
                    y1="17.414"
                    x2="33.82"
                    y2="63.6723"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1784D9" />
                    <stop offset="0.5" stopColor="#107AD5" />
                    <stop offset="1" stopColor="#0A63C9" />
                </linearGradient>
            </defs>
        </svg>
    );
};
