/* eslint-disable react-hooks/exhaustive-deps */
import { arrayMoveImmutable } from 'array-move';
import AvatarGroup from 'components/atoms/AvatarGroup';
import { Button } from 'components/atoms/buttons/Button';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import React, { useEffect, useState } from 'react';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, PRIORITY_OPTIONS, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS, USER_TYPE } from 'utills/globalVars';
import { ChipArrow } from 'utills/svgs/ChipArrow';
import { PlusVector } from 'utills/svgs/PlusVector';

import Input from 'components/atoms/Input';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import CreateTask from 'global-components/CreateTask';
import { useParams } from 'react-router-dom';
import {
    changeMilestoneTaskSequenceThunk,
    createTaskbyTitleThunk,
    createTaskUsingTemplateThunk,
    getMilestoneTasksThunk
} from 'store/JobManager';
import { usePagination } from 'Hooks/paginationHook/usePagination';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonTable from 'components/atoms/SkeletonTable';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTaskStatusesThunk, removeTaskThunk, updateTaskThunk } from 'store/workspace/workspaceTasks';
import { handleTaskDetails, setLoader } from 'store/global/globalReducer';
import ConfirmationModal from 'utills/confirmationModal';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import ComboboxComponent from 'components/atoms/Combobox';

import TaskDetails from 'components/workplace-content/tasks-pages/task-details/TaskDetails';
import EditTaskDetails from 'components/workplace-content/tasks-pages/task-details/EditTaskDetails';
import { sortByKey } from 'utills/sortAndFilter';
import { DateFormatToLocal } from 'utills/moment';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { getTemplateTaskThunk } from 'store/settings/JobPortal';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { Bars4Icon } from '@heroicons/react/24/outline';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';

export const MilestoneTasks = ({ milestone_id, milestone }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: statuses } = useSelector((state) => state.getAllTaskStatuses);

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);

    const { data: getTemplateTask } = useSelector((state) => state?.getTemplateTask);

    const { data: jobData } = useSelector((state) => state?.getSingleJob);
    const [prefieldData, setPrefieldData] = useState(null);

    useEffect(() => {
        if (jobData && milestone) {
            setPrefieldData({
                job_obj: jobData,
                milestone_obj: milestone
            });
        }
    }, [milestone, jobData]);

    const { hasPermissions } = useRoleGuard();

    const { id: job_id } = useParams();
    const dispatch = useDispatch();

    const [filters, setFilters] = useState({
        status: '',
        employee: '',
        priority: ''
    });

    const { handlePageChange, pageData, hasMoreContent } = usePagination({
        thunk: getMilestoneTasksThunk,
        dataKey: 'rows',
        extraPayload: {
            business_id,
            job_id,
            milestone_id,
            employee_id: filters?.employee ?? '',
            task_status_id: filters?.status ?? '',
            priority: filters?.priority ?? ''
        },
        permissions: { module: 'Tasks', permissions: ['admin', 'read', 'write'] }
    });

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [createTask, setCreateTask] = useState(false);
    const [newTaskTitle, setNewTaskTitle] = useState({
        value: '',
        id: ''
    });
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [editTask, setEditTask] = useState(false);
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (pageData && hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            setTasks(sortByKey(pageData, 'sequence'));
        }
    }, [pageData]);

    useEffect(() => {
        if (hasPermissions('Tasks', ['read', 'write', 'admin'])) {
            handlePageChange(1);
        }
    }, [filters]);

    const [highlightedChipTask, setHighlightedChipTask] = useState(false);
    const [highlightedChipIndexTask, setHighlightedChipIndexTask] = useState(0);

    const handleNewMileStoneTaskObj = (index, sequence) => {
        const newTasks = cloneDeep(tasks);

        const alreadyAdded = newTasks?.some((item) => item?.is_new);

        if (alreadyAdded) {
            return;
        }

        const task = {
            id: index + 1,
            title: '',
            created_date: '',
            due_date: '',
            priority: '',
            status: '',
            assignees: [],
            is_new: true,
            sequence: sequence
        };

        newTasks.splice(index, 0, task);

        setTasks(newTasks);
    };

    const onSortEndTasks = (oldIndex, newIndex) => {
        if (!hasPermissions('Tasks', ['write', 'admin'])) {
            return toastHandler('You dont have permission to perform this action', TOAST_TYPE_ERROR);
        }

        if (disabled) {
            return;
        }

        const newArray = arrayMoveImmutable(tasks, oldIndex, newIndex);

        const payload = {
            job_id,
            business_id,
            milestone_id,
            list: newArray.map((item, i) => ({ sequence: i + 1, task_id: item?.task_id }))
        };

        dispatch(changeMilestoneTaskSequenceThunk(payload)).then((response) => {
            console.info(response.payload);
        });

        setTasks(newArray);
    };

    const handleDelTask = (index) => {
        const newTasks = [...tasks];
        newTasks.splice(index, 1);
        setTasks(newTasks);
    };

    const handleCreateTaskSubmit = (e, sequence, _index) => {
        e.preventDefault();
        dispatch(setLoader(true));

        const payload = {
            title: newTaskTitle?.value,
            uploaded_by: USER_TYPE,
            business_id,

            job_id,
            milestone_id,
            sequence
        };
        dispatch(
            newTaskTitle?.id
                ? createTaskUsingTemplateThunk({ ...payload, task_template_id: newTaskTitle?.id })
                : createTaskbyTitleThunk(payload)
        )
            .then((response) => {
                if (response.payload) {
                    handlePageChange(1);
                    setNewTaskTitle({
                        value: '',
                        id: ''
                    });

                    toastHandler('Task Added', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleRemoveTask = (task_id) => {
        setIsConfirmationOpen(true);
        setSelectedId(task_id);
    };

    const handleDeleteConfirmed = () => {
        setIsConfirmationOpen(false);
        const payload = {
            task_id: selectedId,
            business_id
        };
        dispatch(setLoader(true));
        dispatch(removeTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    handlePageChange(1);
                    setSelectedId('');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (!statuses && hasPermissions('Tasks', ['write', 'admin'])) {
            dispatch(getAllTaskStatusesThunk({ business_id }));
        }
    }, [statuses]);

    useEffect(() => {
        if (!employees && hasPermissions('Tasks', ['write', 'admin'])) {
            dispatch(fetchRegisteredEmployeesThunk());
        }
    }, [employees]);

    const handleUpdateTask = (itemid, key, val) => {
        const payload = {
            task_id: itemid,
            business_id,
            [key]: val
        };

        dispatch(updateTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    handlePageChange(1);
                    toastHandler(`Task Updated`, TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleDetails = (findTask) => {
        dispatch(handleTaskDetails(findTask));

        setShowTaskDetails(!showTaskDetails);
    };

    const handleTaskEdit = (task) => {
        dispatch(handleTaskDetails(task));
        setEditTask(!editTask);
    };

    const clearFilter = () => {
        setFilters({
            status: '',
            employee: '',
            priority: ''
        });
    };

    useEffect(() => {
        if (hasPermissions('Tasks', ['write', 'admin'])) {
            dispatch(getTemplateTaskThunk({ business_id }));
        }
    }, []);

    const handleSelectTaskTemplate = (template) => {
        if (template) {
            setNewTaskTitle({
                value: template?.title,
                id: template?.task_template_id
            });
        }
    };
    return (
        <DefaultLightBgCard
            title={'Tasks'}
            rightButton={
                <Button
                    title="+ Add Task"
                    type="button"
                    atClick={() => {
                        if (!hasPermissions('Tasks', ['write', 'admin'])) {
                            return toastHandler('You dont have permission to perform this action', TOAST_TYPE_ERROR);
                        }
                        setCreateTask(true);
                    }}
                    classes="ann-btn px-12 rounded-xl"
                    disabled={disabled}
                />
            }
            sx="w-full"
        >
            <RoleBasedGuard module={'Tasks'} permissions={['read', 'write', 'admin']} sx="h-[35vh]">
                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={handleDeleteConfirmed}
                />

                {showTaskDetails && (
                    <TaskDetails
                        open={showTaskDetails}
                        setOpen={setShowTaskDetails}
                        toggleTaskDetails={() => setShowTaskDetails(!showTaskDetails)}
                        handleTasksUpdate={() => handlePageChange(1)}
                    />
                )}

                {editTask && (
                    <>
                        <EditTaskDetails
                            open={editTask}
                            setOpen={setEditTask}
                            toggleTaskDetails={() => setEditTask(!editTask)}
                            handleTasksUpdate={() => handlePageChange(1)}
                        />
                    </>
                )}

                {createTask && (
                    <CreateTask
                        open={createTask}
                        setOpen={setCreateTask}
                        prefieldData={prefieldData}
                        extraPayload={{ job_id, milestone_id, sequence: 1 }}
                        handleTasksUpdate={() => handlePageChange(1)}
                    />
                )}
                <div className="flex gap-2 items-center flex-wrap">
                    <div className="w-[300px]">
                        <ComboboxComponent
                            data={employees}
                            selectedValue={filters?.employee ? filters?.employee : ''}
                            valuePropertyName={'user_id'}
                            showNameOnly
                            limit={1}
                            inputsx="!font-normal !text-sm"
                            sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                            placeholder="Filter by employees"
                            onChange={(obj) => {
                                setFilters((prev) => ({ ...prev, employee: obj[0]?.user_id || '' }));
                            }}
                            targetProperty="user_id"
                        />
                    </div>
                    <div className="w-[200px]">
                        <SimpleSelectMenu
                            placeholder="Filter By Task Status"
                            targetProperty="label"
                            selectedValue={filters?.status}
                            optionsData={statuses || []}
                            sx="rounded-xl py-3 ring-gray-400  !font-normal"
                            showChipOnly
                            onChangeValue={(obj) => {
                                setFilters((prev) => ({ ...prev, status: obj?.task_status_id || '' }));
                            }}
                        />
                    </div>

                    <div className="w-[200px]">
                        <SimpleSelectMenu
                            placeholder="Filter by Priority"
                            targetProperty="label"
                            selectedValue={filters?.priority}
                            valuePropertyName="label"
                            optionsData={PRIORITY_OPTIONS || []}
                            sx="rounded-xl py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                            showChipOnly={false}
                            onChangeValue={(obj) => setFilters((prev) => ({ ...prev, priority: obj?.label || '' }))}
                        />
                    </div>

                    {Object.values(filters).some((value) => value !== null && value !== undefined && value !== '') && (
                        <ClearFilterButton
                            atClick={() => {
                                clearFilter();
                            }}
                        />
                    )}
                </div>

                <div className=" ">
                    <div className="overflow-x-auto">
                        <div className="px-5 py-3 min-w-[900px]">
                            <div className=" mt-5 grid grid-cols-7 gap-5 buttons-font">
                                <div className="col-span-2 buttons-font">Title</div>
                                <div className=" buttons-font">Created Date</div>
                                <div className=" buttons-font">Due Date</div>
                                <div className=" buttons-font">Priority</div>
                                <div className=" buttons-font">Status</div>
                                <div className=" buttons-font">Assignees</div>
                            </div>

                            <hr className="mt-5 " />

                            <InfiniteScroll
                                height={'300px'}
                                next={handlePageChange}
                                dataLength={pageData?.length}
                                hasMore={hasMoreContent}
                                loader={<SkeletonTable columns={6} />}
                            >
                                <SortableList
                                    onSortEnd={onSortEndTasks}
                                    className="listvs"
                                    draggedItemClassName="draggeddd"
                                >
                                    {tasks?.map((task, index) => {
                                        if (!task?.is_new) {
                                            return (
                                                <SortableItem key={'stortable_itemd' + index} className="draggedd">
                                                    <div key={index}>
                                                        <div className="mt-5 grid grid-cols-7 gap-5 buttons-font-lighter">
                                                            <div className="col-span-2 flex items-center gap-2">
                                                                <SortableKnob>
                                                                    <div className="cursor-grab">
                                                                        <Bars4Icon className="w-5" />
                                                                    </div>
                                                                </SortableKnob>
                                                                <span
                                                                    onClick={() => handleDetails(task)}
                                                                    className="cursor-pointer break-all"
                                                                >
                                                                    {task?.title || '--'}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className=" flex items-center cursor-pointer"
                                                                onClick={() => handleDetails(task)}
                                                            >
                                                                {DateFormatToLocal({
                                                                    date: task?.created_at,
                                                                    insertedFormat: 'DD-MM-YYYYTHH:mm:ssZ',
                                                                    format: 'DD-MM-YYYY'
                                                                }) || '--'}
                                                            </div>
                                                            <div
                                                                onClick={() => handleDetails(task)}
                                                                className=" cursor-pointer flex items-center"
                                                            >
                                                                {task?.end_date || '--'}
                                                            </div>
                                                            <div className="text-sm  flex items-center">
                                                                <DropdownMenu
                                                                    className="!left-0"
                                                                    preventOpenTop={true}
                                                                    icon={
                                                                        <div
                                                                            className={`flex gap-1 px-2 justify-between w-[100px] items-center p-1 rounded-xl ${
                                                                                task?.priority === 'Low'
                                                                                    ? 'bg-[#008C51]/10 text-[#008C51]'
                                                                                    : task?.priority === 'Normal'
                                                                                    ? 'bg-[#b695f8]/10  text-[#b695f8]'
                                                                                    : task?.priority === 'Lowest'
                                                                                    ? 'bg-[#666666]/10  text-[#666666]'
                                                                                    : task?.priority === 'Highest'
                                                                                    ? 'bg-[#F14747]/10  text-[#F14747]'
                                                                                    : task?.priority === 'High'
                                                                                    ? 'bg-[#0d1b37]/10  text-[#0d1b37]'
                                                                                    : 'bg-[#F08201]/10  text-[#F08201]'
                                                                            }`}
                                                                        >
                                                                            {task?.priority || '--'}
                                                                            <ChipArrow
                                                                                fill={
                                                                                    task?.priority === 'Low'
                                                                                        ? '#008C51'
                                                                                        : task?.priority === 'Normal'
                                                                                        ? '#b695f8'
                                                                                        : task?.priority === 'Lowest'
                                                                                        ? '#666666'
                                                                                        : task?.priority === 'Highest'
                                                                                        ? '#F14747'
                                                                                        : task?.priority === 'High'
                                                                                        ? '#0d1b37'
                                                                                        : '#F08201'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    }
                                                                >
                                                                    {PRIORITY_OPTIONS?.map((item, index) => (
                                                                        <DropdownMenuItem
                                                                            onClick={() => {
                                                                                if (
                                                                                    !hasPermissions('Tasks', [
                                                                                        'write',
                                                                                        'admin'
                                                                                    ])
                                                                                ) {
                                                                                    return toastHandler(
                                                                                        'You dont have permission to perform this action',
                                                                                        TOAST_TYPE_ERROR
                                                                                    );
                                                                                }
                                                                                handleUpdateTask(
                                                                                    task?.task_id,
                                                                                    'priority',
                                                                                    item?.label
                                                                                );
                                                                            }}
                                                                            key={index}
                                                                        >
                                                                            <div className="flex items-center gap-2  cursor-pointer">
                                                                                {item?.label}
                                                                            </div>
                                                                        </DropdownMenuItem>
                                                                    ))}
                                                                </DropdownMenu>
                                                            </div>
                                                            <div className=" text-sm  flex items-center">
                                                                <DropdownMenu
                                                                    className="!left-0"
                                                                    icon={
                                                                        <div
                                                                            className={`flex gap-1 px-2 justify-between w-[120px] items-center p-1 rounded-xl capitalize ${
                                                                                task?.task_status_id?.label ===
                                                                                'Completed'
                                                                                    ? 'bg-[#008C51]/10 text-[#008C51]'
                                                                                    : 'bg-[#F08201]/10  text-[#F08201]'
                                                                            }`}
                                                                        >
                                                                            {task.task_status_id?.label || '--'}

                                                                            <ChipArrow
                                                                                fill={
                                                                                    task?.task_status_id?.label ===
                                                                                    'Completed'
                                                                                        ? '#008C51'
                                                                                        : '#F08201'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    }
                                                                >
                                                                    {statuses?.map((item, index) => (
                                                                        <DropdownMenuItem
                                                                            key={'statuses' + index}
                                                                            onClick={() => {
                                                                                if (
                                                                                    !hasPermissions('Tasks', [
                                                                                        'write',
                                                                                        'admin'
                                                                                    ])
                                                                                ) {
                                                                                    return toastHandler(
                                                                                        'You dont have permission to perform this action',
                                                                                        TOAST_TYPE_ERROR
                                                                                    );
                                                                                }
                                                                                handleUpdateTask(
                                                                                    task?.task_id,
                                                                                    'task_status_id',
                                                                                    item?.task_status_id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <div className="flex items-center gap-2  cursor-pointer">
                                                                                {item?.label}
                                                                            </div>
                                                                        </DropdownMenuItem>
                                                                    ))}
                                                                </DropdownMenu>
                                                            </div>
                                                            <div className=" flex items-center justify-between gap-3">
                                                                <AvatarGroup
                                                                    list={task?.employees || []}
                                                                    limit={4}
                                                                    targetProperty={'image'}
                                                                    onClick={() => {}}
                                                                />

                                                                <div>
                                                                    <DropdownMenu disabled={disabled ? true : false}>
                                                                        {/* <DropdownMenuItem >
                                                                    <div className="flex items-center gap-2  cursor-pointer">
                                                                        Details
                                                                    </div>
                                                                </DropdownMenuItem> */}
                                                                        <DropdownMenuItem
                                                                            onClick={() => {
                                                                                if (
                                                                                    !hasPermissions('Tasks', [
                                                                                        'write',
                                                                                        'admin'
                                                                                    ])
                                                                                ) {
                                                                                    return toastHandler(
                                                                                        'You dont have permission to perform this action',
                                                                                        TOAST_TYPE_ERROR
                                                                                    );
                                                                                }
                                                                                handleTaskEdit(task);
                                                                            }}
                                                                        >
                                                                            <div className="flex items-center gap-2  cursor-pointer">
                                                                                Edit
                                                                            </div>
                                                                        </DropdownMenuItem>
                                                                        <DropdownMenuItem
                                                                            onClick={() => {
                                                                                if (
                                                                                    !hasPermissions('Tasks', [
                                                                                        'write',
                                                                                        'admin'
                                                                                    ])
                                                                                ) {
                                                                                    return toastHandler(
                                                                                        'You dont have permission to perform this action',
                                                                                        TOAST_TYPE_ERROR
                                                                                    );
                                                                                }
                                                                                handleRemoveTask(task?.task_id);
                                                                            }}
                                                                        >
                                                                            <div className="flex items-center gap-2  cursor-pointer">
                                                                                Remove
                                                                            </div>
                                                                        </DropdownMenuItem>
                                                                    </DropdownMenu>{' '}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="h-2 w-full bg-transparent"
                                                            onMouseEnter={() => {
                                                                setHighlightedChipTask(true);
                                                                setHighlightedChipIndexTask(index);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHighlightedChipTask(false);
                                                                setHighlightedChipIndexTask('');
                                                            }}
                                                        ></div>
                                                        {highlightedChipTask && highlightedChipIndexTask === index ? (
                                                            <div
                                                                onMouseEnter={() => {
                                                                    setHighlightedChipTask(true);
                                                                    setHighlightedChipIndexTask(index);
                                                                }}
                                                                onClick={() => {
                                                                    if (!hasPermissions('Tasks', ['write', 'admin'])) {
                                                                        return toastHandler(
                                                                            'You dont have permission to perform this action',
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }

                                                                    if (!disabled) {
                                                                        handleNewMileStoneTaskObj(
                                                                            index + 1,
                                                                            task?.sequence
                                                                                ? parseInt(task?.sequence) + 1
                                                                                : 1
                                                                        );
                                                                    }
                                                                }}
                                                                className={`relative w-full mt-3 cursor-pointer ${
                                                                    disabled && '!cursor-not-allowed  opacity-50'
                                                                }`}
                                                            >
                                                                <div className="bg-thirdbg w-full h-[1px]"></div>
                                                                <div className="absolute left-[90px] transform -translate-x-1/2 flex gap-2 items-center -top-[14px] bg-[#D6BAFB] font-[400] py-1 text-sm text-white px-2 rounded-md ">
                                                                    <PlusVector /> Add Task
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <hr className="mt-3" />
                                                        )}

                                                        <div
                                                            className="h-2 w-full bg-transparent"
                                                            onMouseEnter={() => {
                                                                setHighlightedChipTask(true);
                                                                setHighlightedChipIndexTask(index);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHighlightedChipTask(false);
                                                                setHighlightedChipIndexTask('');
                                                            }}
                                                        ></div>
                                                    </div>
                                                </SortableItem>
                                            );
                                        } else {
                                            return (
                                                <SortableItem key={'stortable_itemd' + index} className="draggedd">
                                                    <div key={index} className="mt-3">
                                                        <form
                                                            onSubmit={(e) =>
                                                                handleCreateTaskSubmit(e, task?.sequence, index)
                                                            }
                                                        >
                                                            <div className="flex gap-2 items-center">
                                                                <div className="w-[3%]">
                                                                    <DropdownMenu
                                                                        className="!left-0"
                                                                        icon={<ChevronDownIcon className="w-7" />}
                                                                    >
                                                                        {getTemplateTask?.map((task, taskIndex) => (
                                                                            <DropdownMenuItem
                                                                                key={taskIndex}
                                                                                onClick={() => {
                                                                                    handleSelectTaskTemplate(task);
                                                                                }}
                                                                            >
                                                                                <div className="flex items-center gap-2  cursor-pointer">
                                                                                    {task?.title || '--'}
                                                                                </div>
                                                                            </DropdownMenuItem>
                                                                        ))}
                                                                    </DropdownMenu>
                                                                </div>

                                                                <div className="w-[50%] md:w-[80%] default-forms">
                                                                    <Input
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            setNewTaskTitle((prev) => ({
                                                                                ...prev,
                                                                                value: e.target.value
                                                                            }))
                                                                        }
                                                                        maxLength={120}
                                                                        placeholder="Enter Task Title"
                                                                        value={newTaskTitle?.value || ''}
                                                                        useWithoutForm={true}
                                                                        required={true}
                                                                    />
                                                                </div>

                                                                <div className="min-w-[13%]">
                                                                    <Button
                                                                        title="+ Add Task"
                                                                        buttonType="submit"
                                                                        classes="ann-btn px-8 text-sm font-[400] md:buttons-font-lighter rounded-xl"
                                                                        disabled={disabled}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="w-[3%] cursor-pointer"
                                                                    onClick={() => handleDelTask(index)}
                                                                >
                                                                    <XMarkIcon className="w-7" />
                                                                </div>
                                                            </div>
                                                        </form>

                                                        <div
                                                            className="h-2 w-full bg-transparent"
                                                            onMouseEnter={() => {
                                                                setHighlightedChipTask(true);
                                                                setHighlightedChipIndexTask(index);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHighlightedChipTask(false);
                                                                setHighlightedChipIndexTask('');
                                                            }}
                                                        ></div>
                                                        {highlightedChipTask && highlightedChipIndexTask === index ? (
                                                            <div
                                                                onMouseEnter={() => {
                                                                    setHighlightedChipTask(true);
                                                                    setHighlightedChipIndexTask(index);
                                                                }}
                                                                onClick={() => {
                                                                    if (!disabled) {
                                                                        handleNewMileStoneTaskObj(
                                                                            index + 1,
                                                                            parseInt(task?.sequence) + 1
                                                                        );
                                                                    }
                                                                }}
                                                                className={`relative w-full mt-3 cursor-pointer ${
                                                                    disabled && '!cursor-not-allowed  opacity-50'
                                                                }`}
                                                            >
                                                                <div className="bg-thirdbg w-full h-[1px]"></div>
                                                                <div className="absolute left-[90px] transform -translate-x-1/2 flex gap-2 items-center -top-[14px] bg-[#D6BAFB] font-[400] py-1 text-sm text-white px-2 rounded-md ">
                                                                    <PlusVector /> Add Task
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <hr className="mt-3" />
                                                        )}

                                                        <div
                                                            className="h-2 w-full bg-transparent"
                                                            onMouseEnter={() => {
                                                                setHighlightedChipTask(true);
                                                                setHighlightedChipIndexTask(index);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHighlightedChipTask(false);
                                                                setHighlightedChipIndexTask('');
                                                            }}
                                                        ></div>
                                                    </div>
                                                </SortableItem>
                                            );
                                        }
                                    })}
                                </SortableList>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>
        </DefaultLightBgCard>
    );
};
