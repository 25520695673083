import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponent from 'components/atoms/Combobox';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import Input from 'components/atoms/Input';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { DragFile } from 'components/atoms/inputs/DragFile';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import _DraftEditor from 'components/molecules/DraftEditor';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchClientsThunk } from 'store/client';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import {
    all_files_types,
    BUSINESS_ID,
    PRIORITY_OPTIONS,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    USER_TYPE
} from 'utills/globalVars';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { AnimatePresence, motion } from 'framer-motion';
import { UserChip } from 'components/atoms/UserChip/UserChip';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { toastHandler } from 'responseHanlder';
import {
    createJobThunk,
    deleteJobFileThunk,
    getAllJobTypesThunk,
    getSingleJobThunk,
    getTemplateByIdThunk,
    removeJobClientThunk,
    removeJobManagerThunk,
    updateJobThunk
} from 'store/JobManager';
import { setLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import { downloadFile } from 'utills/filesHandlers';
import { formatDateIfNecessary } from 'utills/moment';
import { Link } from 'react-router-dom';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import SimpleCombobox from 'components/atoms/SimpleCombobox';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

export const CreateJob = ({ title, open, setOpen, job_id, onAction, user = null }) => {
    const dispatch = useDispatch();
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);
    const { data: jobTypes } = useSelector((state) => state.getAllJobTypes);
    const { data: loginData } = useSelector((state) => state.login);
    const { hasPermissions } = useRoleGuard();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);
    const [jobtitle, setJobTitle] = useState('');
    const [editorContent, setEditorContent] = useState(null);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedManagers, setSelectedManagers] = useState(job_id ? [] : [loginData] ?? []);
    const [forceUpdate, setForceUpdate] = useState(false);

    const [uploadedDocs, setUploadedDocs] = useState([]);
    const [jobType, setJobType] = useState({
        label: '',
        job_type_id: ''
    });
    const [priority, setPriority] = useState('Normal');

    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs(fromDate).add(2, 'day') ?? '');

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');

    const [isRecurring, setIsRecurring] = useState('No');
    const [numOfRepeats, setNumOfRepeats] = useState('');
    const [recurringNextDate, setRecurringNextDate] = useState('');
    const [recurringEndDate, setRecurringEndDate] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    useEffect(() => {
        if (hasPermissions('Job', ['write', 'admin'])) {
            dispatch(fetchRegisteredEmployeesThunk())
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {});
        }
    }, []);

    const handleSelectedFiles = (files) => {
        setSelectedFiles([...files, ...selectedFiles]);
    };
    const onRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };

    const handleClientRemove = (index, business_client_id = null) => {
        const newClients = [...selectedClients];
        newClients.splice(index, 1);
        setSelectedClients(newClients);
        if (business_client_id) {
            deleteCient(business_client_id);
        }
    };
    const deleteCient = (business_client_id) => {
        dispatch(setLoader(true));
        dispatch(removeJobClientThunk({ job_id, business_client_id: business_client_id, business_id }))
            .catch((_error) => {
                console.error('error');
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleManagerRemove = (index, manager_id = null) => {
        const newClients = [...selectedManagers];
        newClients.splice(index, 1);
        setSelectedManagers(newClients);
        if (manager_id) {
            deleteManager(manager_id);
        }
    };
    const deleteManager = (manager_id) => {
        dispatch(setLoader(true));
        dispatch(removeJobManagerThunk({ job_id, employee_id: manager_id, business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const handlePreviewFile = (file) => {
        if (file instanceof File || file instanceof Blob) {
            const url = URL.createObjectURL(file);

            if (file.type.includes('jpg') || file.type.includes('png') || file.type.includes('jpeg')) {
                setSelectedImage(url);
            } else if (file.type.includes('application/pdf')) {
                setSelectedPdf(url);
            }
        } else {
            console.error('Invalid file object passed to handlePreviewFile.');
        }
    };

    const stripHtmlTags = (html) => {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    const validateDescription = (description) => {
        const strippedDescription = stripHtmlTags(description).trim();
        return strippedDescription === '' ? false : true;
    };

    //

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!jobType?.job_type_id) {
            return toastHandler('Please select a job type', TOAST_TYPE_ERROR);
        }
        if (selectedClients?.length < 1) {
            return toastHandler('Please select atleast one client', TOAST_TYPE_ERROR);
        }
        if (!validateDescription(editorContent)) {
            return toastHandler(`Description is required `, TOAST_TYPE_ERROR);
        }

        if (!fromDate) {
            return toastHandler('Start date is required', TOAST_TYPE_ERROR);
        }
        if (!toDate) {
            return toastHandler('Due date is required', TOAST_TYPE_ERROR);
        }

        if (!priority) {
            return toastHandler('Please select job Priority', TOAST_TYPE_ERROR);
        }

        if (selectedManagers?.length === 0) {
            return toastHandler('Please select a manager', TOAST_TYPE_ERROR);
        }

        if (isRecurring === 'Yes') {
            if (!numOfRepeats) {
                return toastHandler('Please select number of repeats', TOAST_TYPE_ERROR);
            }
            if (!recurringNextDate) {
                return toastHandler('Next date is required', TOAST_TYPE_ERROR);
            }

            if (!recurringEndDate) {
                return toastHandler('End date is required', TOAST_TYPE_ERROR);
            }
        }

        const formData = new FormData();

        formData.append('title', jobtitle);
        formData.append('description', editorContent);
        formData.append('job_type_id', jobType?.job_type_id);
        formData.append('is_recurrence', isRecurring);

        if (isRecurring === 'Yes') {
            formData.append('repeats', numOfRepeats);
            formData.append('next_start_date', formatDateIfNecessary(recurringNextDate));
            formData.append('end_date', formatDateIfNecessary(recurringEndDate));
        }

        formData.append('start_date', formatDateIfNecessary(fromDate));
        formData.append('due_date', formatDateIfNecessary(toDate));
        formData.append('priority', priority);

        formData.append('employee_id', JSON.stringify(selectedManagers?.map((item) => item?.user_id)));
        formData.append('business_id', business_id);
        formData.append('uploaded_by', USER_TYPE);

        formData.append('clients', JSON.stringify(selectedClients.map((client) => client.business_client_id)));

        selectedFiles?.forEach((file) => {
            formData.append('job_files', file);
        });

        if (job_id) {
            formData.append('job_id', job_id);
        }

        dispatch(setLoader(true));
        dispatch(job_id ? updateJobThunk(formData) : createJobThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toastHandler(`Successfully ${job_id ? 'Updated' : 'Created'}`, TOAST_TYPE_SUCCESS);
                    setOpen(false);
                    onAction(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleSelectClient = (client) => {
        const isClientAlreadySelected = selectedClients?.some(
            (item) => item?.business_client_id === client?.business_client_id
        );

        if (isClientAlreadySelected) {
            return toastHandler('Client already selected', TOAST_TYPE_ERROR);
        }

        const nonIndividuals = selectedClients?.filter((item) => item?.client_category !== 'Individual');

        if (nonIndividuals?.length > 0 && client?.client_category !== 'Individual') {
            return toastHandler('Only one non Individual client can be selected', TOAST_TYPE_ERROR);
        }

        setSelectedClients([...selectedClients, client]);
        return true;
    };

    const getMinDate = (frequency) => {
        switch (frequency) {
            case 'weekly':
                return dayjs(recurringNextDate).add(1, 'week');
            case 'Fortnightly':
                return dayjs(recurringNextDate).add(2, 'weeks');
            case 'monthly':
                return dayjs(recurringNextDate).add(1, 'month');
            case 'quarterly':
                return dayjs(recurringNextDate).add(3, 'months');
            case 'annually':
                return dayjs(recurringNextDate).add(1, 'year');
            default:
                return dayjs(recurringNextDate);
        }
    };

    const fetchJobTypes = () => {
        const payload = {
            business_id: business_id
        };
        dispatch(getAllJobTypesThunk(payload));
    };

    useEffect(() => {
        if (hasPermissions('Job', ['write', 'admin'])) {
            fetchJobTypes();
        }
    }, []);
    useEffect(() => {
        if (user) {
            setSelectedClients([...selectedClients, user]);
        }
    }, [user]);

    useEffect(() => {
        if (job_id && hasPermissions('Job', ['write', 'admin'])) {
            dispatch(getSingleJobThunk({ job_id })).then((response) => {
                if (response.payload) {
                    const job = response.payload;
                    setJobTitle(job?.title || '');
                    setEditorContent(job?.description || ' ');
                    setSelectedClients(job?.clients || []);
                    setSelectedManagers(job?.manager || []);
                    setJobType((prev) => ({
                        ...prev,
                        label: job?.jobType?.label,
                        job_type_id: job?.jobType?.job_type_id
                    }));
                    setIsRecurring(job?.is_recurrence || 'No');
                    setFromDate(job?.start_date || '');
                    setToDate(job?.due_date || '');
                    setPriority(job?.priority || 'Normal');

                    setNumOfRepeats(job?.repeats || '');
                    setRecurringNextDate(job?.next_start_date || '');
                    setRecurringEndDate(job?.end_date || '');
                    setUploadedDocs(job?.attachments);
                    setForceUpdate(true);
                }
            });
        } else {
            setEditorContent(' ');
            setForceUpdate(true);
        }
    }, [job_id]);

    const handleDeleteFile = (index) => {
        const files = [...uploadedDocs];
        const file = uploadedDocs[index];
        dispatch(setLoader(true));
        dispatch(deleteJobFileThunk({ job_id, job_attachment_id: file?.job_attachment_id }))
            .then((response) => {
                if (response.payload) {
                    files.splice(index, 1);
                    setUploadedDocs(files);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const jobTypeSelect = (obj) => {
        setJobType((prev) => ({ ...prev, label: obj?.label, job_type_id: obj?.job_type_id }));
        if (obj?.job_template_id) {
            dispatch(setLoader(true));
            dispatch(getTemplateByIdThunk({ urlParams: `${obj?.job_template_id}` }))
                .then((response) => {
                    if (response.payload) {
                        setJobTitle(response.payload?.title);
                        setEditorContent(response?.payload?.description || ' ');
                        setForceUpdate(true);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleSelectManager = (obj) => {
        const findManager = selectedManagers?.some((item) => item?.user_id === obj?.user_id);
        if (findManager) {
            return toastHandler('This Manager is already selected', TOAST_TYPE_ERROR);
        }
        setSelectedManagers([...selectedManagers, obj]);
    };
    return (
        <Slideover open={open} setOpen={setOpen} size={'max-w-[1500px]'}>
            <RoleBasedGuard module={'Job'} permissions={['write', 'admin']} sx="h-[50vh]">
                <div className="text-3xl font-fira font-[500]">{title}</div>
                {selectedImage && (
                    <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} isLocal={true} />
                )}
                {isLoading && <Loader />}
                {selectedPdf ? (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                        isLocal={true}
                    />
                ) : (
                    ''
                )}{' '}
                <div className="mt-8">
                    <form onSubmit={handleSubmit}>
                        <DefaultLightBgCard title={'Job Information'}>
                            <div className="default-forms">
                                <div className="grid md:grid-cols-2 gap-5 lg:grid-cols-3 mt-4">
                                    <div>
                                        <label> Job Type </label>
                                        <div className="mt-2">
                                            <SimpleCombobox
                                                placeholder="Select Job Type"
                                                targetProperty="label"
                                                optionsData={jobTypes || []}
                                                valuePropertyName={'job_type_id'}
                                                selectedValue={jobType?.job_type_id ?? ''}
                                                sx="rounded-xl py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                                onChangeValue={(obj) => jobTypeSelect(obj)}
                                            />
                                        </div>

                                        {jobTypes?.length < 1 && (
                                            <div className="bg-dimThird mt-3 rounded-xl p-3 text-sm font-poppins">
                                                Job type is required in order to create a job. No job type found!{' '}
                                                <Link
                                                    to="/job-manager/settings/job-type"
                                                    className="text-third"
                                                    target="_blank"
                                                >
                                                    click here
                                                </Link>{' '}
                                                to add
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        <label> Clients </label>

                                        <ComboboxComponentSSR
                                            preventLoadingInfo
                                            hideSelectedValues
                                            fetchDataFunction={(payload) =>
                                                fetchReferencesListFun({
                                                    ...payload,
                                                    type: ''
                                                })
                                            }
                                            onChange={(res) => {
                                                const obj = res[0];

                                                handleSelectClient(obj);
                                            }}
                                            targetProperty="business_client_id"
                                            placeholder="Search by name/reference number/email"
                                            sx="rounded-xl ring-gray-400"
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 mb-4 flex gap-3 items-center flex-wrap">
                                    {selectedClients?.map((client, index) => (
                                        <div key={index}>
                                            {' '}
                                            <UserChip
                                                obj={client}
                                                onClick={() =>
                                                    handleClientRemove(
                                                        index,
                                                        job_id ? client?.business_client_id : null
                                                    )
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>{' '}
                                <label> Job Title </label>
                                <Input
                                    type="text"
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    maxLength={120}
                                    required
                                    placeholder="Enter Job Title"
                                    value={jobtitle}
                                    useWithoutForm={true}
                                    wrapsx="mt-2"
                                />
                                <label className="mt-4"> Description </label>
                                <div className="mt-2">
                                    <DraftEditorNew
                                        onChange={(val) => setEditorContent(val)}
                                        initialValue={editorContent}
                                        required={true}
                                        placeholder="Enter description"
                                        forceUpdate={forceUpdate}
                                        setForceUpdate={setForceUpdate}
                                    />
                                    {/* {editorContent && (
                                        <DraftEditor
                                            onChange={(val) => setEditorContent(val)}
                                            initialValue={editorContent}
                                            maxWords={10000}
                                            required={true}
                                            placeholder="Enter description"
                                            forceUpdate={forceUpdate}
                                            setForceUpdate={setForceUpdate}
                                        />
                                    )} */}
                                </div>
                            </div>
                        </DefaultLightBgCard>
                        <AnimatePresence>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ delay: 0.2, type: 'tween', duration: 0.3 }}
                                className="mt-10"
                            >
                                <DefaultLightBgCard title={'Recurrence'}>
                                    <div className="grid md:grid-cols-2 gap-5 mt-4 default-forms">
                                        <div className="  default-forms">
                                            <label>Is recurring Job </label>
                                            <div className="mt-2">
                                                {' '}
                                                <DefaultDoubleSwitch
                                                    items={[
                                                        { title: 'Yes', selected: isRecurring === 'Yes' },
                                                        { title: 'No', selected: isRecurring === 'No' }
                                                    ]}
                                                    onClick={(value) => {
                                                        setIsRecurring(value);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {isRecurring === 'Yes' && (
                                            <div>
                                                <label>Repeats </label>
                                                <SimpleSelectMenu
                                                    placeholder="Select "
                                                    targetProperty="label"
                                                    selectedValue={numOfRepeats}
                                                    valuePropertyName="label"
                                                    optionsData={
                                                        [
                                                            { label: 'weekly' },
                                                            { label: 'Fortnightly' },
                                                            { label: 'monthly' },
                                                            { label: 'quarterly' },
                                                            { label: 'annually' }
                                                        ] || []
                                                    }
                                                    sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                                    showChipOnly={false}
                                                    onChangeValue={(obj) => setNumOfRepeats(obj.label)}
                                                />
                                            </div>
                                        )}

                                        {isRecurring === 'Yes' && (
                                            <div>
                                                <label> Next Date</label>
                                                <DatePick
                                                    minDate={dayjs()}
                                                    iconSet={{
                                                        right: CalendarSmallIco
                                                    }}
                                                    onDateChange={(date) => {
                                                        setRecurringNextDate(date);
                                                    }}
                                                    placeholder="DD-MM-YYYY"
                                                    value={
                                                        recurringNextDate ? dayjs(recurringNextDate, 'DD-MM-YYYY') : ''
                                                    }
                                                    sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                                />
                                            </div>
                                        )}

                                        {isRecurring === 'Yes' && (
                                            <div>
                                                <label> End Date</label>
                                                <DatePick
                                                    iconSet={{
                                                        right: CalendarSmallIco
                                                    }}
                                                    onDateChange={(date) => {
                                                        setRecurringEndDate(date);
                                                    }}
                                                    minDate={
                                                        numOfRepeats === 'weekly'
                                                            ? getMinDate('weekly')
                                                            : numOfRepeats === 'Fortnightly'
                                                            ? getMinDate('Fortnightly')
                                                            : numOfRepeats === 'monthly'
                                                            ? getMinDate('monthly')
                                                            : numOfRepeats === 'quarterly'
                                                            ? getMinDate('quarterly')
                                                            : numOfRepeats === 'annually'
                                                            ? getMinDate('annually')
                                                            : dayjs()
                                                    }
                                                    placeholder="DD-MM-YYYY"
                                                    value={
                                                        recurringEndDate ? dayjs(recurringEndDate, 'DD-MM-YYYY') : ''
                                                    }
                                                    sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </DefaultLightBgCard>
                            </motion.div>
                        </AnimatePresence>
                        <div className="mt-10">
                            <DefaultLightBgCard title={'Schedule Information'}>
                                <div className="default-forms">
                                    <div className="grid md:grid-cols-2 gap-5 mt-4">
                                        <div>
                                            <label> Start Date</label>
                                            <DatePick
                                                minDate={dayjs()}
                                                iconSet={{
                                                    right: CalendarSmallIco
                                                }}
                                                onDateChange={(date) => {
                                                    setFromDate(date);
                                                }}
                                                placeholder="DD-MM-YYYY"
                                                value={fromDate ? dayjs(fromDate, 'DD-MM-YYYY') : ''}
                                                sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                            />
                                        </div>
                                        <div>
                                            <label> Due Date</label>
                                            <DatePick
                                                minDate={fromDate ? dayjs(fromDate) : dayjs()}
                                                iconSet={{
                                                    right: CalendarSmallIco
                                                }}
                                                onDateChange={(date) => {
                                                    setToDate(date);
                                                }}
                                                placeholder="DD-MM-YYYY"
                                                value={toDate ? dayjs(toDate, 'DD-MM-YYYY') : ''}
                                                sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                                            />
                                        </div>

                                        <div>
                                            <label>Priority </label>
                                            <SimpleSelectMenu
                                                placeholder="Select "
                                                targetProperty="label"
                                                selectedValue={priority}
                                                valuePropertyName="label"
                                                optionsData={PRIORITY_OPTIONS || []}
                                                sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                                showChipOnly={false}
                                                onChangeValue={(obj) => setPriority(obj.label)}
                                            />
                                        </div>

                                        {!!employees && (
                                            <div>
                                                <label> Manager </label>
                                                <ComboboxComponent
                                                    data={employees}
                                                    removePreviousSelected
                                                    hideSelectedValues
                                                    placeholder={'Search Manager by Name or Reference Number'}
                                                    onChange={(list) => {
                                                        const obj = list[0];

                                                        handleSelectManager(obj);
                                                    }}
                                                    targetProperty="user_id"
                                                />
                                                <div className="mt-5 mb-4 flex gap-3 items-center flex-wrap">
                                                    {selectedManagers?.map((manager, index) => (
                                                        <div key={'manager' + index}>
                                                            <UserChip
                                                                obj={manager}
                                                                onClick={() =>
                                                                    handleManagerRemove(
                                                                        index,
                                                                        job_id ? manager?.user_id : null
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ))}
                                                </div>{' '}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </DefaultLightBgCard>
                        </div>

                        <div className="mt-10">
                            <DefaultLightBgCard title={'Attachments'}>
                                <div className="default-forms">
                                    <label className="mb-2"> Upload Attachment</label>
                                    <DragFile
                                        selectedFiles={selectedFiles}
                                        atChange={(file) => {
                                            handleSelectedFiles(file);
                                        }}
                                        accept={all_files_types}
                                        isMultiple={true}
                                        hideSelectedFiles={true}
                                        dragMessage="Drag files here to upload, or browse for files"
                                        dropMessage="Drop the selected files in this box... "
                                        onlyPdf={false}
                                        message="This file type is not allowed"
                                    />

                                    <div className="mt-5 flex gap-5 items-center flex-wrap">
                                        {selectedFiles?.map((file, index) => (
                                            <FileCardPreview
                                                url={file?.type}
                                                key={index}
                                                showImage={true}
                                                name={file?.name}
                                                size={file?.size}
                                                extension={
                                                    file?.type?.includes('.')
                                                        ? file?.type?.split('.')?.pop()?.toLowerCase()
                                                        : file?.type.split('/').pop().toLowerCase()
                                                }
                                                imgPrperties={{
                                                    url: file,
                                                    type: file?.type,
                                                    sx: ''
                                                }}
                                                onClick={() => handlePreviewFile(file)}
                                                onRemove={() => onRemoveFile(index)}
                                            />
                                        ))}
                                    </div>
                                    {uploadedDocs?.length > 0 && (
                                        <div className="default-forms my-3">
                                            <label> Uploaded Documents </label>
                                        </div>
                                    )}
                                    <div className="flex gap-5 items-center flex-wrap ">
                                        {uploadedDocs?.map((file, index) => (
                                            <FileCardPreview
                                                url={file?.url}
                                                key={index}
                                                name={file?.name}
                                                size={''}
                                                extension={
                                                    file?.url?.includes('.')
                                                        ? file?.url?.split('.')?.pop()?.toLowerCase()
                                                        : file?.url.split('/').pop().toLowerCase()
                                                }
                                                onClick={() => {
                                                    if (file?.url?.toLowerCase()?.endsWith('.pdf')) {
                                                        setSelectedPdf(file?.url);
                                                    } else if (
                                                        file?.url?.toLowerCase()?.endsWith('.jpg') ||
                                                        file?.url?.toLowerCase()?.endsWith('.jpeg') ||
                                                        file?.url?.toLowerCase()?.endsWith('.png')
                                                    ) {
                                                        setSelectedImage(file?.url);
                                                    } else {
                                                        downloadFile(file?.url);
                                                    }
                                                }}
                                                onRemove={() => handleDeleteFile(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </DefaultLightBgCard>
                        </div>

                        <div className="mt-24 flex justify-center items-center">
                            <Button
                                title={job_id ? 'Update Job' : 'Create Job'}
                                buttonType={'submit'}
                                classes="ann-btn rounded-xl px-16 `buttons-font-lighter"
                            />
                        </div>
                    </form>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
