import { useDispatch, useSelector } from 'react-redux';
import OnboardingPage from '../../../components/dashboard-components/onboarding-components/OnboardingPage';
import React, { useEffect } from 'react';
import { getBranchLocationsThunk, getBusinessprofileThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { useGetAllServices } from 'Hooks/useGetAllServices';

const Onboarding = () => {
    const dispatch = useDispatch();

    const { getServices } = useGetAllServices();

    const { data } = useSelector((state) => state.login);

    const getBranches = () => {
        dispatch(getBranchLocationsThunk({ user_id: data?.user_id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        dispatch(getBusinessprofileThunk())
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                getServices();
                getBranches();
            });
    }, []);

    return (
        <div>
            <OnboardingPage />
        </div>
    );
};

export default Onboarding;
