import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import { FolderIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/atoms/buttons/Button';

import { Loader } from 'components/common/Loader';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import {
    getNotificationModulesThunk,
    resetAllNotificationsThunk,
    updateSettingsObjThunk
} from 'store/settings/notification-settings';
import ConfirmationModal from 'utills/confirmationModal';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { getOrdinalValue } from 'utills/handleText';
import { AllClientsIcon } from 'utills/svgs/AllClientsIcon';
import { AnnouncementIcon } from 'utills/svgs/AnnouncementIcon';
import { DeductionIcon } from 'utills/svgs/DeductionIcon';
import { FormsIcon } from 'utills/svgs/FormsIcon';
import { JobManagerIcon2 } from 'utills/svgs/JobManagerIcon2';
import { LargeTaskIcon } from 'utills/svgs/LargeTaskIcon';
import { MagnetUser } from 'utills/svgs/MagnetUser';
import { MultipleUsersSvg } from 'utills/svgs/MultipleUsersSvg';
import { NotepadIcon } from 'utills/svgs/NotepadIcon';
import { WorkspaceIconSvg } from 'utills/svgs/WorkspaceIconSvg';
import _ from 'lodash';
import { TelephonicDirectoryIcon } from 'utills/TelephonicDirectoryIcon';
import { ThreadsIcon } from 'utills/ThreadsIcon';
import { QrCodeIcon } from 'utills/QrCodeIcon';
import DAppsIcon from 'utills/svgs/DAppsIcon';

import { toastHandler } from 'responseHanlder';
import { resetModuleThunk } from 'store/groups';
import { ResetIcon } from 'utills/ResetIcon';
import { NotificationModule } from './NotificationModule';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { fetchRoleGroupsThunk } from 'store/settings/team/team';

export const EmailNotifications = () => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const { hasPermissions } = useRoleGuard();

    const [notificationModules, setNotificationModules] = useState([]);
    const { data } = useSelector((state) => state.getNotificationModules);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const ReturnModuleIcon = (module) => {
        switch (module) {
            case 'Task Module':
                return <LargeTaskIcon className=" w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !fill-none !stroke-none" />;
            case 'Form Submission':
                return <FormsIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !fill-black !stroke-none" />;
            case 'Clients':
                return <AllClientsIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1" />;
            case 'Leads':
                return <MagnetUser className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1" />;
            case 'News':
                return <AnnouncementIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 !fill-none" />;
            case 'Accountant':
                return <UserCircleIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'Deduction':
                return <DeductionIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1" />;
            case 'Job':
                return <JobManagerIcon2 className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'File Manager':
                return <FolderIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'Workspace':
                return <WorkspaceIconSvg className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'Employee':
                return <MultipleUsersSvg className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'Ethical Clearance':
                return <NotepadIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'Telephonic directory':
                return <TelephonicDirectoryIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'Thread':
                return <ThreadsIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1 " />;
            case 'Qr code':
                return <QrCodeIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] ml-2 !stroke-1 " />;
            case 'App':
                return <DAppsIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] !stroke-1" />;
            default:
                return <QuestionMarkCircleIcon className="w-[30px] h-[30px] lg:w-[50px] lg:h-[50px]" />;
        }
    };

    const handleGetNotificationModules = () => {
        dispatch(setLoader(true));
        dispatch(getNotificationModulesThunk({ business_id }))
            .then((response) => {
                if (response.payload) {
                    setNotificationModules(response.payload);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        if (hasPermissions('Notification_setting', ['admin', 'read'])) {
            handleGetNotificationModules();
        }
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const filteredModules = data?.filter((module) =>
                module?.name?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setNotificationModules(filteredModules);
        } else {
            setNotificationModules(data);
        }
    }, [searchTerm]);

    const updateObjSetting = (item, val, key, si) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        let newItem;

        if (key === 'send_to' || key === 'select') {
            const customItem = _.cloneDeep(item);
            const findSchedule = customItem?.schedule[si];
            findSchedule[key] = val;

            newItem = customItem;
        } else {
            newItem = {
                ...item,
                [key]: `${!val}`
            };
        }
        dispatch(updateSettingsObjThunk(newItem))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Notification settings updated', TOAST_TYPE_SUCCESS);

                    handleGetNotificationModules();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [resetAllLoader, setResetAllLoader] = useState(false);
    const handleResetAll = () => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        setIsConfirmationOpen(false);
        // dispatch(setLoader(true));
        setResetAllLoader(true);
        dispatch(resetAllNotificationsThunk({ urlParams: business_id }))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Notification settings updated', TOAST_TYPE_SUCCESS);

                    handleGetNotificationModules();
                }
            })
            .finally(() => {
                // dispatch(setLoader(false));

                setResetAllLoader(false);
            });
    };

    const handleAddReminder = (itemIndex, settingIndex) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const newArr = _.cloneDeep(notificationModules);
        const newObj = {
            ...newArr[itemIndex].settings[settingIndex].schedule[0],
            select: null
        };
        newArr[itemIndex].settings[settingIndex].schedule.push(newObj);
        setNotificationModules(newArr);
    };
    const handleRemoveReminder = (itemIndex, settingIndex, i) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const newArr = _.cloneDeep(notificationModules);

        newArr[itemIndex].settings[settingIndex].schedule.splice(i, 1);
        setNotificationModules(newArr);
    };
    const handleScheduleValueChange = (itemIndex, settingIndex, scheduleIndex, value, key) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const newArr = _.cloneDeep(notificationModules);
        newArr[itemIndex].settings[settingIndex].schedule[scheduleIndex][key] = value;
        setNotificationModules(newArr);
    };

    const handleSwitchChange = (moduleIndex, index, value, key) => {
        const newArr = _.cloneDeep(notificationModules);
        newArr[moduleIndex].settings[index][key] = value;
        setNotificationModules(newArr);
    };

    const handleReturnPossibleOptions = (itemIndex, settingIndex, schduleIndex) => {
        const newArr = _.cloneDeep(notificationModules);
        let possibleOptions = newArr[itemIndex]?.settings[settingIndex].select;

        newArr[itemIndex].settings[settingIndex].schedule.forEach((s, _i) => {
            const currentSelect = newArr[itemIndex]?.settings[settingIndex].schedule[schduleIndex]?.select;
            if (s.select) {
                possibleOptions = possibleOptions.filter(
                    (option) => option?.name === currentSelect?.name || option.name !== s.select?.name
                );
            }
        });

        return possibleOptions;
    };

    const handleupdatesettingFun = (itemIndex, settingIndex) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const settingItem = notificationModules[itemIndex]?.settings[settingIndex];
        let reminderError = settingItem?.schedule?.some((s) => !s?.select?.name);

        if (reminderError) {
            return toastHandler('Please select reminder time', TOAST_TYPE_ERROR);
        }

        if (settingItem?.schedule?.some((s) => s?.send_to?.length === 0)) {
            return toastHandler('Send to cant be empty', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(updateSettingsObjThunk(settingItem))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Notification settings updated', TOAST_TYPE_SUCCESS);

                    handleGetNotificationModules();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleResetModule = (notification_module_id) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(resetModuleThunk({ notification_module_id, business_id }))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Notification settings updated', TOAST_TYPE_SUCCESS);

                    handleGetNotificationModules();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(fetchRoleGroupsThunk())
            .then(() => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    return (
        <SidebarLayout>
            <RoleBasedGuard module="Notification_setting" permissions={['admin', 'read']} sx="p-5">
                {isLoading && <Loader />}
                <Jumbotron
                    title="Email Notifications"
                    path={[
                        { title: 'Settings', link: 'settings' },
                        { title: 'Email Notifications', link: '#' }
                    ]}
                />

                {isConfirmationOpen && (
                    <ConfirmationModal
                        isOpen={isConfirmationOpen}
                        onClose={() => setIsConfirmationOpen(false)}
                        onConfirm={handleResetAll}
                        description="Do you want to reset all email notifications ?"
                        confirmbtnTxt="Reset"
                        title="Reset Confirmation"
                    />
                )}
                <div className="mx-5 md:mx-10">
                    <div className="flex justify-between items-center gap-5 flex-wrap">
                        <SearchInput
                            atChange={(e) => setSearchTerm(e.target.value)}
                            width="300px"
                            placeholder="Search"
                            value={searchTerm}
                        />
                        <Button
                            title="Reset All Notifications"
                            iconSet={{
                                leftIconHTML: (
                                    <ResetIcon
                                        className={`h-5 w-5 group-hover:invert group-hover:transform transition-all     group-hover:rotate-[-45deg] ${
                                            resetAllLoader && 'animate-reverse-spin '
                                        }`}
                                    />
                                )
                            }}
                            atClick={() => setIsConfirmationOpen(true)}
                            disabled={resetAllLoader}
                            classes="border border-thirdbg py-3 flex gap-2 items-center font-[400] text-sm font-poppins px-4 rounded-lg hover:bg-thirdbg hover:text-white"
                        />
                    </div>

                    <div className="mt-3">
                        {notificationModules?.map((module, moduleIndex) => (
                            <NotificationModule
                                module={module}
                                key={moduleIndex}
                                moduleIndex={moduleIndex}
                                ReturnModuleIcon={ReturnModuleIcon}
                                handleResetModule={handleResetModule}
                                updateObjSetting={updateObjSetting}
                                handleAddReminder={handleAddReminder}
                                handleRemoveReminder={handleRemoveReminder}
                                getOrdinalValue={getOrdinalValue}
                                handleReturnPossibleOptions={handleReturnPossibleOptions}
                                handleScheduleValueChange={handleScheduleValueChange}
                                handleupdatesettingFun={handleupdatesettingFun}
                                handleGetNotificationModules={handleGetNotificationModules}
                                handleSwitchChange={handleSwitchChange}
                            />
                        ))}
                    </div>
                </div>
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
