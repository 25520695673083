/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { cn } from 'utills/tailwindUtil';
import ArrowDown from 'utills/svgs/ArrowDown';
import TruncateText from 'global-components/StringSlicer';

const TreeView2 = ({ dataObj, sx }) => {
    const [showChildren, setShowChildren] = useState({});

    const sortedData = [...dataObj.items].sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));

    useEffect(() => {
        const defaultVisibility = {};
        defaultVisibility[`ParentHead`] = false;
        sortedData?.forEach((p_obj, ind) => {
            defaultVisibility[`ParentHead_${ind}`] = true;
            p_obj?.items?.forEach((_child, child_ind) => {
                defaultVisibility[`ChildHead_${ind}_${child_ind}`] = true;
            });
        });
        setShowChildren(defaultVisibility);
    }, []);

    const toggleChildrenVisibility = (className) => {
        setShowChildren((prevShowChildren) => ({
            ...prevShowChildren,
            [className]: !prevShowChildren[className]
        }));
    };
    return (
        <div className="">
            <h3
                className="flex items-center  gap-2 ml-2.5 ParentHead"
                onClick={() => toggleChildrenVisibility(`ParentHead`)}
            >
                <ArrowDown
                    className={cn('cursor-pointer transition', !showChildren[`ParentHead`] ? 'rotate-[270deg]' : '')}
                />{' '}
                <TruncateText text={dataObj.label} maxLength={200} />
            </h3>
            {showChildren[`ParentHead`] && (
                <div className="border-[#bbbbbb] border-l ml-4">
                    {sortedData.map((p_obj, ind) => (
                        <div key={'checklist_item_' + ind} className="relative py-3 small-text !bg-white">
                            <div
                                className={cn(
                                    'border-t h-[100%] border-[#bbbbbb] w-[29px] absolute top-1/2 left-0',
                                    ind + 1 === dataObj.items.length && `${sx ? sx : 'bg-white'} -left-[1px]`
                                )}
                            ></div>
                            <div className={cn('flex items-center p gap-3 pl-[20px]', sx ? sx : 'bg-white')}>
                                <p className="flex items-center gap-2 ParentHead cursor-pointer">
                                    <ArrowDown className={cn('cursor-pointer transition rotate-[270deg]')} />{' '}
                                    <TruncateText text={p_obj.label} maxLength={200} />
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeView2;
