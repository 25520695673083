import React from 'react';

const ArchiveIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M5.625 17.5H14.375C15.2038 17.5 15.9987 17.1708 16.5847 16.5847C17.1708 15.9987 17.5 15.2038 17.5 14.375V5.625C17.4997 5.56067 17.4869 5.49701 17.4625 5.4375C17.4633 5.42293 17.4633 5.40832 17.4625 5.39375L16.875 4.225C16.6152 3.70658 16.2163 3.27067 15.7229 2.96602C15.2295 2.66138 14.6611 2.50002 14.0813 2.5H5.91875C5.33889 2.50002 4.77046 2.66138 4.27707 2.96602C3.78368 3.27067 3.38478 3.70658 3.125 4.225L2.56875 5.34375C2.56795 5.35832 2.56795 5.37293 2.56875 5.3875C2.52631 5.45966 2.50267 5.54133 2.5 5.625V14.375C2.5 15.2038 2.82924 15.9987 3.41529 16.5847C4.00134 17.1708 4.7962 17.5 5.625 17.5ZM16.25 14.375C16.25 14.8723 16.0525 15.3492 15.7008 15.7008C15.3492 16.0525 14.8723 16.25 14.375 16.25H5.625C5.12772 16.25 4.65081 16.0525 4.29917 15.7008C3.94754 15.3492 3.75 14.8723 3.75 14.375V6.25H16.25V14.375ZM4.24375 4.7875C4.39918 4.47617 4.63817 4.21424 4.93399 4.031C5.22981 3.84777 5.57078 3.75048 5.91875 3.75H14.0813C14.4292 3.75048 14.7702 3.84777 15.066 4.031C15.3618 4.21424 15.6008 4.47617 15.7562 4.7875L15.8625 5H4.1375L4.24375 4.7875Z"
                fill="#B695F8"
            />
            <path
                d="M8.67502 14.45C9.02631 14.8017 9.5029 14.9996 10 15C10.495 14.9979 10.969 14.8002 11.3188 14.45L13.125 12.6813C13.2274 12.5617 13.2809 12.4079 13.2748 12.2506C13.2688 12.0933 13.2036 11.9441 13.0922 11.8328C12.9809 11.7215 12.8317 11.6563 12.6744 11.6502C12.5171 11.6441 12.3633 11.6976 12.2438 11.8L10.625 13.3812V8.125C10.625 7.95924 10.5592 7.80027 10.442 7.68306C10.3247 7.56585 10.1658 7.5 10 7.5C9.83426 7.5 9.67529 7.56585 9.55808 7.68306C9.44087 7.80027 9.37502 7.95924 9.37502 8.125V13.3812L7.78752 11.8C7.66795 11.6976 7.51416 11.6441 7.35686 11.6502C7.19956 11.6563 7.05035 11.7215 6.93904 11.8328C6.82773 11.9441 6.76252 12.0933 6.75645 12.2506C6.75037 12.4079 6.80388 12.5617 6.90627 12.6813L8.67502 14.45Z"
                fill="#B695F8"
            />
        </svg>
    );
};

export default ArchiveIcon;
