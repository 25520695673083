import React from 'react';
import LostTwoFaPage from '../../components/dashboard-components/auth/lost-two-fa-pages/LostTwoFaPage';

const LostTwoFa = () => {
    return (
        <div>
            <LostTwoFaPage />
        </div>
    );
};

export default LostTwoFa;
