import React, { useState, useCallback } from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import Card from './Card';
import ArrowDown from 'utills/svgs/ArrowDown';
import { cn } from 'utills/tailwindUtil';
import { SortableKnob } from 'react-easy-sort';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import { Bars4Icon } from '@heroicons/react/24/outline';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';

export const GenerateStep = ({
    step,
    stepIndex,
    handleStepHeading,
    handleDeleteStep,
    handleAddField,
    handleClearStep,
    showModal,
    modalIndex,
    toggleModal,
    handleFieldTypeChange,
    handleLabel,
    handleRecuringLabel,
    addOption,
    handlePlaceholder,
    handleSwitchOptionOne,
    handleSwitchOptionTwo,
    handleDeleteField,
    handleOption,
    deleteOption,
    steps,
    setSteps,
    handleHint,
    hanleRequired,
    handleSubmit,
    modalStep,
    handleDefaultValue,
    handleValueChange,
    addRecuringOption,
    handleRecurringOption,
    handleAddSubField,
    handleAddStepConditions,
    addConditionalOption,
    handleConditionalOption,
    handleConditionalStep,
    handleDisplayInStepsConditional,
    handleSkipStepDisplay,
    handleAddFieldInConditional,
    handleFieldTypeChangeCondtional,
    handleLabelConditional,
    addOptionConditional,
    handleOptionConditional,
    deleteOptionConditional,
    handleDeleteFieldConditional,
    handleSwitchOptionOneConditional,
    handleSwitchOptionTwoConditional,
    hanleRequiredConditional,
    addRemoveSkipStep,
    handleSubStepHeadingChange,
    handleAddHintSubField,
    handleAddHintSubFieldRecurring,
    duplicateStep,
    handleCalculateStepIndex
}) => {
    const [fieldsHidden, setFieldsHidden] = useState(false);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const newArray = [...steps[[stepIndex]].fields];

        [newArray[dragIndex], newArray[hoverIndex]] = [newArray[hoverIndex], newArray[dragIndex]];
        let stepsss = steps;
        stepsss[stepIndex].fields = newArray;
        setSteps([...stepsss]);
    });

    const renderCard = useCallback(
        (card, index) => (
            <Card
                modalStep={modalStep}
                field={card}
                fieldIndex={index}
                showModal={showModal}
                modalIndex={modalIndex}
                toggleModal={toggleModal}
                handleFieldTypeChange={handleFieldTypeChange}
                handleDefaultValue={handleDefaultValue}
                stepIndex={stepIndex}
                handleLabel={handleLabel}
                handleRecuringLabel={handleRecuringLabel}
                addOption={addOption}
                handlePlaceholder={handlePlaceholder}
                handleSwitchOptionOne={handleSwitchOptionOne}
                handleSwitchOptionTwo={handleSwitchOptionTwo}
                handleDeleteField={handleDeleteField}
                handleOption={handleOption}
                deleteOption={deleteOption}
                key={card.id}
                index={index}
                id={card.id}
                text={card.text}
                moveCard={moveCard}
                handleHint={handleHint}
                hanleRequired={hanleRequired}
                handleValueChange={handleValueChange}
                addRecuringOption={addRecuringOption}
                handleRecurringOption={handleRecurringOption}
                handleAddSubField={handleAddSubField}
                handleAddStepConditions={handleAddStepConditions}
                addConditionalOption={addConditionalOption}
                handleConditionalOption={handleConditionalOption}
                handleConditionalStep={handleConditionalStep}
                handleDisplayInStepsConditional={handleDisplayInStepsConditional}
                handleSkipStepDisplay={handleSkipStepDisplay}
                handleAddFieldInConditional={handleAddFieldInConditional}
                handleFieldTypeChangeCondtional={handleFieldTypeChangeCondtional}
                handleLabelConditional={handleLabelConditional}
                addOptionConditional={addOptionConditional}
                handleOptionConditional={handleOptionConditional}
                deleteOptionConditional={deleteOptionConditional}
                handleDeleteFieldConditional={handleDeleteFieldConditional}
                handleSwitchOptionOneConditional={handleSwitchOptionOneConditional}
                handleSwitchOptionTwoConditional={handleSwitchOptionTwoConditional}
                hanleRequiredConditional={hanleRequiredConditional}
                addRemoveSkipStep={addRemoveSkipStep}
                handleSubStepHeadingChange={handleSubStepHeadingChange}
                handleAddHintSubField={handleAddHintSubField}
                handleAddHintSubFieldRecurring={handleAddHintSubFieldRecurring}
                steps={steps}
                handleCalculateStepIndex={handleCalculateStepIndex}
            />
        ),
        [moveCard]
    );

    return (
        <div className=" ">
            <div>
                <div className="flex justify-between items-center bg-dimThird p-3 rounded-tl-xl rounded-tr-xl ">
                    <div className="lg-title my-1 flex gap-3 items-center">
                        <div onClick={() => setFieldsHidden(!fieldsHidden)} className="cursor-pointer">
                            <ArrowDown
                                className={cn('cursor-pointer transition', fieldsHidden ? 'rotate-[270deg]' : '')}
                            />{' '}
                        </div>
                        <div className="cursor-grab">
                            {' '}
                            <SortableKnob>
                                <div>
                                    <Bars4Icon className="w-5" />
                                </div>
                            </SortableKnob>
                        </div>
                        Step {stepIndex + 1}{' '}
                        <div>
                            <DropdownMenu
                                icon={
                                    <div className="small-text font-poppins text-third underline">Duplicate Step</div>
                                }
                            >
                                <DropdownMenuItem
                                    className="w-full"
                                    onClick={() => duplicateStep('top', step?.step_id)}
                                >
                                    <div className="flex items-stretch cursor-pointer gap-2 hover-effect-invert w-full">
                                        At top
                                    </div>
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    className="w-full"
                                    onClick={() => duplicateStep('bottom', step?.step_id)}
                                >
                                    <div className="flex cursor-pointer items-stretch gap-2 hover-effect-invert w-full">
                                        At the bottom
                                    </div>
                                </DropdownMenuItem>

                                {steps?.map((stp, stepIndex) => (
                                    <div key={'stepIndex' + stepIndex}>
                                        {stepIndex > 0 && (
                                            <DropdownMenuItem
                                                className="w-full"
                                                onClick={() => duplicateStep('custom', step?.step_id, stepIndex)}
                                            >
                                                <div className="flex cursor-pointer items-stretch gap-2 hover-effect-invert w-full">
                                                    At {stepIndex + 1}
                                                </div>
                                            </DropdownMenuItem>
                                        )}
                                    </div>
                                ))}
                            </DropdownMenu>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center gap-2">
                            <div className="cursor-pointer" onClick={() => handleDeleteStep(stepIndex)}>
                                <BgDeleteIcon />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-3">
                    <div className="flex items-center justify-between gap-5 mt-5">
                        <div>
                            <div>
                                <label>Heading</label>
                            </div>
                            <div className="step-heading-indicate mt-2">
                                <input
                                    onChange={(e) => handleStepHeading(e, stepIndex)}
                                    value={step.step_heading}
                                    type="text"
                                    maxLength={200}
                                    name=""
                                    id=""
                                    placeholder="Step heading"
                                    className="!w-[500px]"
                                />
                            </div>
                        </div>
                        <div></div>
                    </div>
                    {fieldsHidden ? (
                        <div
                            onClick={() => setFieldsHidden(!fieldsHidden)}
                            className="mt-5 cursor-pointer text-secondarybg font-poppins small-text underline"
                        >
                            {step?.fields?.length > 0 ? 'Fields hidden click to view' : ''}
                        </div>
                    ) : (
                        <div>
                            {step.fields.map((field, fieldIndex) => renderCard(field, fieldIndex))}

                            <div>
                                <div className="flex justify-end mt-3 items-center gap-2">
                                    <div>
                                        <TooltipChipWrap title={'Click to add new field' || '__'} variant="soft">
                                            <div>
                                                <button
                                                    className="add-input-indicate bg-thirdbg text-white buttons-font-lighter font-fira rounded-xl px-5 h-[2.5rem]"
                                                    onClick={() => handleAddField(stepIndex)}
                                                    type="button"
                                                >
                                                    Add field
                                                </button>
                                            </div>
                                        </TooltipChipWrap>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end items- mt-3 gap-3">
                                <button
                                    onClick={(e) => handleSubmit(e, 'draft', false)}
                                    className="bg-linear text-white h-[2.5] rounded-xl px-9 cursor-pointer buttons-font-lighter font-fira"
                                    type="button"
                                >
                                    Save
                                </button>
                                <button
                                    onClick={() => handleClearStep(stepIndex)}
                                    className="border-secondarybg text-secondarybg border h-[2.5rem]  rounded-xl px-8 buttons-font-lighter font-fira"
                                    type="button"
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
