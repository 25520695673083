import {
    Bars4Icon,
    ChevronDownIcon,
    InboxArrowDownIcon,
    InboxIcon,
    InboxStackIcon,
    PaperAirplaneIcon,
    PlusCircleIcon
} from '@heroicons/react/20/solid';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { SearchInput } from 'components/molecules/SearchInput';
import InlinePagination from 'components/pagination/InlinePagination';
import React, { useEffect, useState } from 'react';
import RelayRoomLeftbarMenuComponent from './RelayRoomLeftbarMenuComponent';
import { DimmedDeleteIcon2 } from 'utills/svgs/DimmedDeleteIcon2';
import { useDispatch, useSelector } from 'react-redux';
import { deleteThreadThunk, removeDraftThunk } from 'store/JobManager';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BUSINESS_ID, handleRemoveQueryParams, RELAY_ROOM, TOAST_TYPE_ERROR } from 'utills/globalVars';
import SkeletonTable from 'components/atoms/SkeletonTable';
import ConfirmationModal from 'utills/confirmationModal';
import { setLoader } from 'store/global/globalReducer';
import {
    getRelayRoomListThunk,
    setCurrentPage,
    setEmailType,
    setIsLoading,
    setSearch
} from 'store/custom-reducers/RelayRoomLeftBarReducer';
import DraftLeftBar from './DraftLeftBar';
import { getAllDraftThunk } from 'store/custom-reducers/RelayRoomDraftReducer';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import { Button } from 'components/atoms/buttons/Button';

const RelayRoomLeftbar = ({ setOpen, forceUpdate, setForceUpdate, setNewEmail }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { hasPermissions } = useRoleGuard();

    const [selected_ids, setSelected_ids] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [sideView, setSideView] = useState('msgs');
    const jobEmailId = searchParams.get('job_email_id');

    const { data: getJobActions } = useSelector((state) => state.getJobActions);
    const getActivityCount =
        getJobActions
            ?.filter((obj) => obj?.type === RELAY_ROOM)
            .map((obj) => {
                const { meta, ...restOfData } = obj;
                const mainObj = meta.ids.map((id) => {
                    return {
                        ...restOfData,
                        id: id
                    };
                });
                return mainObj;
            })
            ?.flat() || [];

    // ---------------------------------------------------------------- Pagination ----------------------------------------------------------------

    const {
        data: pageData,
        currentPage,
        itemsPerPage,
        total_count,
        totalPages,
        search,
        email_type,
        isLoading,
        totalUnRead,
        totalSendEmail,
        totalReceivedEmail,
        totalDraftEmail
    } = useSelector((state) => state.getRelayRoomList);

    useEffect(() => {
        if (forceUpdate) {
            getData();
            setForceUpdate(false);
        }
    }, [forceUpdate]);

    useEffect(() => {
        if (email_type === 'draft') {
            setSideView('draft');
        } else {
            setSideView('msgs');
        }
    }, [email_type]);

    useEffect(() => {
        if (email_type !== 'draft') {
            getData(false);
        }
    }, [currentPage, email_type, search]);

    const getData = async (hasReset = false) => {
        const payload = {
            job_id: id,
            business_id,
            page: hasReset ? 1 : currentPage,
            email_type: email_type,
            search: search
        };

        dispatch(setIsLoading(true));

        try {
            dispatch(getRelayRoomListThunk(payload)).finally(() => {
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        dispatch(setSearch(value));
    };

    const handleSelectThread = (selectedId) => {
        if (selected_ids.includes(selectedId)) {
            setSelected_ids((prev) => {
                return prev?.filter((item) => item !== selectedId);
            });
        } else {
            setSelected_ids((prev) => {
                return [...prev, selectedId];
            });
        }
    };
    const handleSelectAll = () => {
        if (selected_ids.length === pageData?.length) {
            setSelected_ids([]);
        } else {
            setSelected_ids(pageData?.map((item) => item?.job_email_id));
        }
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        dispatch(setLoader(true));
        if (email_type === 'draft') {
            dispatch(removeDraftThunk({ ids: selected_ids }))
                .then((res) => {
                    if (res.payload) {
                        fetchDraftList();
                        setSelected_ids([]);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            dispatch(deleteThreadThunk({ business_id, ids: selected_ids }))
                .then((response) => {
                    if (response.payload) {
                        getData(true);
                        setSelected_ids([]);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const fetchDraftList = async () => {
        const jsonData = {
            business_id,
            job_id: id,
            email_type: 'inbox'
        };

        await dispatch(getAllDraftThunk(jsonData));
    };

    return (
        <div className="overflow-hidden">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <RelayRoomLeftbarWrapper
                email_type={email_type}
                setEmailType={(e) => {
                    if (e !== email_type) {
                        handleRemoveQueryParams(navigate, ['activeTab']);
                    }
                    dispatch(setEmailType(e));
                }}
                handleDeleteThread={() => setIsConfirmationOpen(true)}
                selected_ids={selected_ids}
                totalSendEmail={totalSendEmail}
                totalReceivedEmail={totalReceivedEmail}
                totalUnRead={totalUnRead}
                totalDraftEmail={totalDraftEmail}
                setNewEmail={setNewEmail}
            >
                {sideView === 'msgs' && (
                    <>
                        <div className="flex flex-col gap-5 py-5 border-b !border-[#97979766]/40% px-3">
                            <div className="">
                                <SearchInput
                                    placeholder="Search"
                                    width="100%"
                                    name="search"
                                    classes="!py-2 !h-auto"
                                    defaultValue={search || ''}
                                    atChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                />
                            </div>

                            <div className="flex justify-between flex-wrap gap-2 items-center">
                                <div>
                                    <PrimaryCheckbox
                                        id="select_all"
                                        name="select_all"
                                        onchange={() => {
                                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                                return toastHandler(
                                                    'You dont have permission to do this action.',
                                                    TOAST_TYPE_ERROR
                                                );
                                            }
                                            handleSelectAll();
                                        }}
                                        checked={
                                            !isLoading &&
                                            pageData?.length > 0 &&
                                            selected_ids.length === pageData?.length
                                        }
                                        htmlFor="select_all"
                                        sx=""
                                        inputSx="!border-third text-xs"
                                        labelsx={'!text-sm'}
                                        label="Select All"
                                    />
                                </div>
                                <div>
                                    <InlinePagination
                                        page={currentPage}
                                        pageSize={itemsPerPage}
                                        total_count={total_count}
                                        totalPages={totalPages}
                                        onChangePage={(e) => {
                                            dispatch(setCurrentPage(e));
                                        }}
                                        scrollTo="items_list"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full divide-y border-[#97979766]/40% ">
                            {isLoading && (
                                <div className="w-full h-full">
                                    <SkeletonTable
                                        minLength={5}
                                        columns={2}
                                        showLogo
                                        logoIndex={0}
                                        sxLogo={'!w-15 !h-15'}
                                    />
                                </div>
                            )}

                            {!isLoading &&
                                pageData?.map((dataObj, index) => {
                                    return (
                                        <div key={'relayroom_list' + index}>
                                            <RelayRoomLeftbarMenuComponent
                                                dataObj={dataObj}
                                                setOpen={setOpen}
                                                selected_ids={selected_ids}
                                                handleSelectThread={handleSelectThread}
                                                active_id={jobEmailId}
                                                email_type={email_type}
                                                getActivityCount={getActivityCount}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </>
                )}
                {sideView === 'draft' && (
                    <DraftLeftBar
                        selected_ids={selected_ids}
                        setSelected_ids={setSelected_ids}
                        handleSelectThread={handleSelectThread}
                    />
                )}
            </RelayRoomLeftbarWrapper>
        </div>
    );
};

const RelayRoomLeftbarWrapper = ({
    email_type,
    setEmailType,
    children,
    handleDeleteThread,
    selected_ids,
    totalSendEmail,
    totalUnRead,
    totalReceivedEmail,
    totalDraftEmail,
    setNewEmail
}) => {
    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;
    let allEmailCount = totalSendEmail + totalReceivedEmail;
    return (
        <div>
            <div className="flex justify-between  flex-wrap gap-2 items-center px-3" id="items_list">
                <div className="flex xl:hidden">
                    <DropdownMenu
                        icon={<Bars4Icon className="w-5 h-5" />}
                        className="!left-0 !w-fit min-w-[300px] xl:min-w-auto !max-w-[500px]"
                        extraProps={null}
                        preventOpenTop
                        preventOnClickFuns
                    >
                        {children}
                    </DropdownMenu>
                </div>
                <PrimaryLabel sx={'!flex items-center gap-1 !w-auto cursor-pointer'}>
                    {email_type === 'send'
                        ? `Send Emails (${totalSendEmail || 0})`
                        : email_type === 'received'
                        ? `My Inbox (${totalUnRead || 0})`
                        : email_type === 'draft'
                        ? `Draft Box (${totalDraftEmail || 0})`
                        : `All Mails (${allEmailCount})`}
                    <DropdownMenu className={'!left-0'} icon={<ChevronDownIcon className="w-5 h-5" />}>
                        <DropdownMenuItem onClick={() => setEmailType('all')}>
                            <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                <InboxStackIcon className="w-5 h-5" />
                                All Mails ({allEmailCount})
                            </div>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => setEmailType('received')}>
                            <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                <InboxArrowDownIcon className="w-5 h-5" />
                                My Inbox ({totalUnRead})
                            </div>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => setEmailType('send')}>
                            <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                <PaperAirplaneIcon className="w-5 h-5" />
                                Send Emails ({totalSendEmail})
                            </div>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => setEmailType('draft')}>
                            <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                <InboxIcon className="w-5 h-5" />
                                Draft Box ({totalDraftEmail})
                            </div>
                        </DropdownMenuItem>
                    </DropdownMenu>{' '}
                </PrimaryLabel>

                {selected_ids?.length > 0 ? (
                    <span
                        onClick={() => {
                            if (!disabled) {
                                handleDeleteThread();
                            }
                        }}
                        className={`flex items-center gap-2 cursor-pointer p-1.5 ${
                            disabled && '!cursor-not-allowed opacity-50'
                        }`}
                    >
                        <DimmedDeleteIcon2 className={'w-5'} />
                        Delete
                    </span>
                ) : (
                    <span>
                        <Button
                            classes={'bg-gray-200 !rounded-full p-1'}
                            tooltip={'Compose New Mail'}
                            iconSet={{ leftIconHTML: <PlusCircleIcon className={'w-6'} /> }}
                            atClick={() => {
                                setNewEmail(true);
                            }}
                            tooltipDirection={'none'}
                        />
                    </span>
                )}
            </div>
            <div className="hidden xl:block border-b !border-[#97979766]/40%">{children}</div>
        </div>
    );
};

export default RelayRoomLeftbar;
