import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import TruncateDescription from 'global-components/TruncateDescription';
import React from 'react';
import { DateFormatToLocal } from 'utills/moment';
import { cn } from 'utills/tailwindUtil';

const ActivityMsgView = ({ data, loginUser, bgColor }) => {
    const isOwned = data?.user?.user_id === loginUser?.user_id;
    return (
        <>
            <div
                className={cn(
                    'flex items-start justify-start gap-3',
                    isOwned && 'flex-row-reverse',
                    bgColor ? bgColor : ''
                )}
            >
                <div className="mt-1">
                    <DefaultProfileViewer
                        image={data?.user?.image}
                        width="40px"
                        height="40px"
                        name={data?.user?.first_name + ' ' + data?.user?.last_name}
                    />
                </div>
                <div className={cn('max-w-[80%]', isOwned && 'text-right')}>
                    <h3 className={cn('text-md font-medium')}>
                        {' '}
                        {data?.user?.first_name + ' ' + data?.user?.last_name}
                    </h3>
                    <div className="p-2 bg-gray-100 rounded-lg mt-1 px-3 text-left break-all">
                        <TruncateDescription
                            maxLength={200}
                            text={data?.message}
                            sx="!text-sm !font-normal !text-gray-900 !leading-relaxed"
                        />
                    </div>
                    <div className={cn('text-xs text-secondarybg mt-1', isOwned ? 'float-start' : 'float-end')}>
                        {DateFormatToLocal({ date: data?.created_at, format: 'MMM DD [at] hh:mm A' })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActivityMsgView;
