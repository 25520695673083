import React from "react";

export const ReportsListIcon = () => {
  return (
    <div>
      <svg
        width="80"
        height="80"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50" cy="50" r="50" fill="#657CEE" />
        <path
          d="M38.3398 23.3398C29.3281 24.3477 24.793 28.625 23.4453 37.4023C23.2344 38.7031 23.2227 39.6875 23.2227 50C23.2227 60.1836 23.2461 61.3086 23.4336 62.5391C24.7695 71.1406 28.9297 75.2891 37.5195 76.5664C38.7969 76.7539 39.957 76.7773 50.0586 76.7773C62.2344 76.7773 62.1875 76.7773 65.1055 76.0625C71.3047 74.5273 74.9141 70.7188 76.2383 64.2969C76.8125 61.5547 76.8477 60.5 76.8008 49.1445C76.7539 37.5547 76.7656 37.7773 76.0156 34.7422C74.5742 28.9414 70.9648 25.3672 65.1172 23.9375C62.1992 23.2344 62.3984 23.2344 50.5859 23.2109C42.1367 23.1875 39.4297 23.2227 38.3398 23.3398ZM62.4805 27.1836C68.3281 28.2266 71.1875 30.6992 72.4414 35.7969C73.0156 38.082 73.0273 38.457 73.0273 50.1172C73.0273 61.918 73.0273 61.9766 72.3945 64.4375C71.293 68.7266 68.7266 71.293 64.4375 72.3945C61.9766 73.0273 61.918 73.0273 50.1172 73.0273C38.457 73.0273 38.082 73.0156 35.7969 72.4414C30.5703 71.1523 27.9453 67.9648 27.125 61.9648C26.9023 60.3359 26.9023 39.6641 27.125 38.0352C27.9453 32.0352 30.5703 28.8477 35.7969 27.5586C36.4062 27.4062 37.3789 27.2188 37.9766 27.1602C38.5625 27.0898 39.207 27.0078 39.3945 26.9844C39.5938 26.9609 44.6211 26.9492 50.5859 26.9727C59.9375 26.9961 61.5664 27.0195 62.4805 27.1836Z"
          fill="white"
        />
        <path
          d="M42.5 36.8277C42.336 36.9332 41.2227 37.9996 40.0156 39.1949L37.836 41.3629L37.2852 40.9176C36.8164 40.5309 36.6406 40.4605 36.0547 40.4137C35.211 40.3551 34.6485 40.648 34.2969 41.3394C34.0039 41.9019 34.0039 42.4176 34.2852 43.0269C34.5313 43.566 36.5586 45.5934 37.0391 45.7809C37.4961 45.9449 38.2344 45.9332 38.6094 45.734C38.7852 45.6402 40.3321 44.1519 42.0547 42.4176C44.7735 39.6871 45.1836 39.2184 45.2656 38.8316C45.418 37.9996 44.9024 37.0269 44.1641 36.7457C43.7188 36.5699 42.8164 36.6285 42.5 36.8277Z"
          fill="white"
        />
        <path
          d="M50.1641 40.4961C49.4492 40.8242 49.1211 41.3516 49.1211 42.1719C49.1211 42.9102 49.3438 43.3086 50 43.7539L50.3867 44.0234H57.5H64.6133L65 43.7539C65.5625 43.3789 65.8438 42.9453 65.9023 42.3711C65.9844 41.6445 65.5625 40.9062 64.8828 40.543L64.3789 40.2734H57.5117C51.0664 40.2734 50.6094 40.2969 50.1641 40.4961Z"
          fill="white"
        />
        <path
          d="M42.7344 54.2296C42.5704 54.2882 41.3985 55.378 40.1329 56.6437L37.8243 58.9523L37.2969 58.4601C36.4297 57.6632 35.4102 57.6515 34.6368 58.4249C34.1563 58.9054 33.9922 59.4562 34.1212 60.1593C34.2383 60.7921 36.5938 63.1593 37.3204 63.3702C38.3165 63.6632 38.4805 63.546 41.8438 60.2179C45.1485 56.9366 45.3946 56.5968 45.254 55.671C45.0899 54.5109 43.836 53.796 42.7344 54.2296Z"
          fill="white"
        />
        <path
          d="M50.2344 57.9453C49.5664 58.2383 49.0625 58.9766 49.0625 59.6562C49.0625 60.2422 49.4727 60.957 50 61.2852L50.4102 61.543H57.5H64.5898L65.0234 61.2734C65.5977 60.9102 65.9844 60.1133 65.9023 59.4688C65.832 58.9062 65.5508 58.4844 65 58.1094L64.6133 57.8516L57.5117 57.8633C53.6094 57.8633 50.3281 57.8984 50.2344 57.9453Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
