import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { InputField } from 'components/atoms/inputs/InputField';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import dayjs from 'dayjs';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';
import { TFNInputField } from 'components/atoms/inputs/TFNInputField';
import EmailInput from 'components/atoms/Input/EmailInput';
import { validateACN, validatePhoneNumber } from '../../../../../utills/baseValidations';
import { validateEmailValue, validateIndividualsObjects, validateTFN } from './../../../../../utills/dataValidation';
import { toastHandler } from '../../../../../responseHanlder';
import { getProfileName, TOAST_TYPE_ERROR } from '../../../../../utills/globalVars';
import { ACNInputField } from 'components/atoms/inputs/ACNInputField';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import Input from 'components/atoms/Input';
import { BigPlusIcon } from 'utills/svgs/BigPlusIcon';
import { DimmedDeleteIcon } from 'utills/svgs/DimmedDeleteIcon';
import cloneDeep from 'lodash/cloneDeep';
import { useGetDirectors } from 'Hooks/clients/useGetDirectors';
const TrusteeDetailsSubForm = ({ setCurrentStep, trusteeList, setTrusteeList, defaultTrustObject, gender }) => {
    const dispatch = useDispatch();

    const handleRemoveEntry = (index, bcr_id = null) => {
        if (bcr_id) {
            dispatch(deleteClientRefThunk({ bcr_id: bcr_id }));
        }
        const newInputs = [...trusteeList];
        newInputs.splice(index, 1);
        setTrusteeList(newInputs);
    };
    const handleAddEntry = () => {
        setTrusteeList([...trusteeList, defaultTrustObject]);
    };

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty, index) => {
        let targetPropertyV = targetProperty;
        setTrusteeList((pre) => {
            return pre.map((prevObj, preInd) => {
                if (preInd === index && ['current_address', 'residential_address'].includes(targetProperty)) {
                    targetPropertyV = prevObj.trust_type === 'individual' ? 'residential_address' : 'current_address';
                }
                return {
                    ...prevObj,
                    [targetPropertyV]: preInd === index ? value : prevObj?.[targetPropertyV]
                };
            });
        });
    };

    const { handleGetDirectors } = useGetDirectors();

    const handleGetDirectorsFun = async (business_client_id = null) => {
        return await handleGetDirectors(business_client_id);
    };

    const handleComboxboxEntity = async (obj = null, index, isFirstLoad = false) => {
        const trustType = trusteeList[index]?.trust_type;

        let directors = [''];
        if (trustType === 'company' && obj?.business_client_id) {
            const director_list = await handleGetDirectorsFun(obj?.business_client_id);
            if (director_list?.length > 0) {
                directors = director_list.map((directorObj) => getProfileName(directorObj));
            }
        }

        const newArrayValue = trusteeList.map((prevObj, preInd) => {
            if (preInd === index) {
                if (isFirstLoad) {
                    return {
                        ...prevObj,
                        isFirstLoad: false
                    };
                }

                if (obj?.business_client_id === prevObj?.business_client_id) {
                    return {
                        ...prevObj
                    };
                }

                return {
                    ...prevObj,
                    business_client_id: obj ? obj?.business_client_id : '',
                    first_name: obj ? obj?.first_name : '',
                    last_name: obj ? obj?.last_name : '',
                    display_name: obj ? obj?.display_name : '',
                    tfn: obj ? obj?.tfn : '',
                    acn: obj ? obj?.acn : '',
                    phone_number: obj ? obj?.phone_number : '',
                    email_address: obj ? obj?.email : '',
                    dob: obj ? obj?.date_of_birth : '',
                    director_name: obj ? (obj?.business_client_id ? directors : prevObj?.director_name) : [''],
                    [prevObj.trust_type === 'individual' ? 'residential_address' : 'current_address']: obj
                        ? obj?.residential_address
                        : '',

                    disabled: {
                        first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                        display_name: obj && obj?.display_name && obj?.display_name !== '' ? true : false,
                        last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,
                        tfn: obj && obj?.tfn && obj?.tfn !== '' && obj?.tfn !== 'null' ? true : false,
                        acn: obj && obj?.acn && obj?.acn !== '' && obj?.acn !== 'null' ? true : false,
                        phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                        email_address: obj && obj?.email && obj?.email !== '' ? true : false,

                        dob: obj && obj?.date_of_birth && obj?.date_of_birth !== '' ? true : false,

                        [prevObj.trust_type === 'individual' ? 'residential_address' : 'current_address']:
                            obj && obj?.residential_address && obj?.residential_address !== '' ? true : false
                    }
                };
            }
            return prevObj;
        });
        setTrusteeList(newArrayValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let hasError = false;
        const emailSet = new Set();
        for (const [index, partner] of trusteeList.entries()) {
            if (partner?.trust_type === 'individual') {
                const validationErrors = validateIndividualsObjects(partner);
                if (validationErrors?.length > 0) {
                    validationErrors?.forEach((error) => {
                        toastHandler(error + ' at ' + (index + 1), TOAST_TYPE_ERROR);
                    });
                    hasError = true;
                    break;
                }
            }

            if (partner?.email_address) {
                if (!validateEmailValue(partner?.email_address)) {
                    hasError = true;
                    toastHandler(`Invalid Email at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }

            if (partner?.email_address && partner?.trust_type === 'individual') {
                if (emailSet.has(partner.email_address)) {
                    hasError = true;
                    toastHandler(`Duplicate email found at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                } else {
                    emailSet.add(partner.email_address);
                }
            }

            if (partner?.phone_number?.length > 4) {
                if (!validatePhoneNumber(partner?.phone_number)) {
                    hasError = true;
                    break;
                }
            }

            if (partner?.tfn) {
                if (!validateTFN(partner?.tfn)) {
                    hasError = true;
                    toastHandler(`Invalid TFN at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }
            if (partner?.acn) {
                if (!validateACN(partner?.acn)) {
                    hasError = true;
                    toastHandler(`Invalid ACN at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }
        }

        if (hasError) {
            return;
        }

        if (gender !== 'Unit Trust') {
            setCurrentStep(2);
        } else {
            setCurrentStep(4);
        }
    };

    const handleDirectorInput = (val, index, i) => {
        const newList = cloneDeep(trusteeList);
        newList[index].director_name[i] = val;
        setTrusteeList(newList);
    };

    const handleAddEntryToDirectors = (i) => {
        const newList = cloneDeep(trusteeList);
        newList[i].director_name.push('');
        setTrusteeList(newList);
    };
    const handleRemoveEntryFromDirectors = (index, i) => {
        const newList = cloneDeep(trusteeList);
        newList[index].director_name.splice(i, 1);
        setTrusteeList(newList);
    };
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Trustee Details</div>
                <div onClick={handleAddEntry} className="cursor-pointer">
                    <NewPlusIcon className="w-8 h-8" />
                </div>
            </div>
            <form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                <div>
                    {trusteeList?.map((trusteeObj, trusteeObjIndex) => (
                        <div className=" !bg-dimThird p-5  rounded-xl mt-5  " key={'trusteeList' + trusteeObjIndex}>
                            <div
                                onClick={() => handleRemoveEntry(trusteeObjIndex, trusteeObj?.bcr_id)}
                                className="cursor-pointer w-fit ml-auto flex justify-end items-center"
                            >
                                <label className="invisible"> f</label>
                                <BgDeleteIcon />
                            </div>

                            <div className="row my-5">
                                <div className="md:w-[40%]">
                                    <label className="inputLabel">Is Trustee an individual or Company?</label>
                                    <DefaultDoubleSwitch
                                        items={[
                                            {
                                                title: 'Individual',
                                                selected: trusteeObj?.trust_type === 'individual'
                                            },
                                            {
                                                title: 'Company',
                                                selected: trusteeObj?.trust_type === 'company'
                                            }
                                        ]}
                                        onClick={(value) => {
                                            if (value) {
                                                handleComboxboxEntity(null, trusteeObjIndex);
                                                handleEntityValue(value?.toLowerCase(), 'trust_type', trusteeObjIndex);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className=" default-forms grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-5  rounded-xl ">
                                {trusteeObj.trust_type === 'company' && (
                                    <>
                                        <div className="">
                                            <label className="inputLabel">Company</label>

                                            <ComboboxComponentSSR
                                                fetchDataFunction={(payload) =>
                                                    fetchReferencesListFun({
                                                        ...payload,
                                                        type: 'Company'
                                                    })
                                                }
                                                defaultSelected={trusteeObj.business_client_id}
                                                removePreviousSelected
                                                onRemove={(_e) => {
                                                    handleComboxboxEntity(null, trusteeObjIndex);
                                                }}
                                                filterSelectedValues={trusteeList || []}
                                                filterSelectedValueTargetElement={'business_client_id'}
                                                onChange={(res) => {
                                                    const obj = res[0];
                                                    handleComboxboxEntity(
                                                        obj,
                                                        trusteeObjIndex,
                                                        trusteeObj?.isFirstLoad
                                                    );
                                                }}
                                                targetProperty="business_client_id"
                                                placeholder={'Search by Name/Reference No.'}
                                                limit={1}
                                                showNameOnly
                                                sx="rounded-xl ring-gray-400"
                                            />
                                        </div>

                                        <div className="">
                                            <InputField
                                                label={'Display Name'}
                                                maxLength={30}
                                                allowSpaces={true}
                                                onChange={(e) => {
                                                    handleEntityValue(e.target.value, 'display_name', trusteeObjIndex);
                                                }}
                                                name={'Display Name'}
                                                inputValue={trusteeObj.display_name}
                                                placeholder={'Display Name'}
                                                classes="mt-2 bg-transparent"
                                                disabled={trusteeObj?.disabled?.display_name}
                                            />
                                        </div>
                                        <div className="w-[100%]">
                                            <ACNInputField
                                                label={'ACN'}
                                                maxLength={20}
                                                allowSpaces={true}
                                                onChange={(e) => {
                                                    handleEntityValue(e.target.value, 'acn', trusteeObjIndex);
                                                }}
                                                name={'ACN'}
                                                inputValue={trusteeObj.acn}
                                                placeholder={'ACN'}
                                                classes="mt-2 bg-transparent"
                                                disabled={trusteeObj?.disabled?.acn}
                                            />
                                        </div>
                                    </>
                                )}

                                {trusteeObj.trust_type === 'individual' && (
                                    <>
                                        <div className="">
                                            <label className="inputLabel">Individual</label>

                                            <ComboboxComponentSSR
                                                fetchDataFunction={(payload) =>
                                                    fetchReferencesListFun({
                                                        ...payload,
                                                        type: 'individual'
                                                    })
                                                }
                                                defaultSelected={trusteeObj.business_client_id}
                                                removePreviousSelected
                                                onRemove={(_e) => {
                                                    handleComboxboxEntity(null, trusteeObjIndex);
                                                }}
                                                filterSelectedValues={trusteeList || []}
                                                filterSelectedValueTargetElement={'business_client_id'}
                                                onChange={(res) => {
                                                    const obj = res[0];

                                                    handleComboxboxEntity(
                                                        obj,
                                                        trusteeObjIndex,
                                                        trusteeObj?.isFirstLoad
                                                    );
                                                }}
                                                targetProperty="business_client_id"
                                                placeholder={'Search by Name/Reference No.'}
                                                limit={1}
                                                sx="rounded-xl ring-gray-400"
                                                showNameOnly
                                            />
                                        </div>
                                        <div className="">
                                            <InputField
                                                label={'First Name'}
                                                maxLength={30}
                                                allowSpaces={true}
                                                onChange={(e) => {
                                                    handleEntityValue(e.target.value, 'first_name', trusteeObjIndex);
                                                }}
                                                name={'First Name'}
                                                inputValue={trusteeObj.first_name}
                                                placeholder={'First Name'}
                                                classes="mt-2 bg-transparent"
                                                disabled={trusteeObj?.disabled?.first_name}
                                            />
                                        </div>
                                        <div className="">
                                            <InputField
                                                label={'Last Name'}
                                                maxLength={30}
                                                allowSpaces={true}
                                                onChange={(e) => {
                                                    handleEntityValue(e.target.value, 'last_name', trusteeObjIndex);
                                                }}
                                                name={'Last Name'}
                                                inputValue={trusteeObj.last_name}
                                                placeholder={'Last Name'}
                                                classes="mt-2 bg-transparent"
                                                disabled={trusteeObj?.disabled?.last_name}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="">
                                    <TFNInputField
                                        label={'TFN'}
                                        maxLength={9}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'tfn', trusteeObjIndex);
                                        }}
                                        name={'TFN'}
                                        inputValue={trusteeObj.tfn}
                                        placeholder={'TFN'}
                                        classes="mt-2 bg-transparent"
                                        disabled={trusteeObj?.disabled?.tfn}
                                    />
                                </div>

                                {trusteeObj.trust_type === 'company' && (
                                    <>
                                        {trusteeObj?.director_name?.map((val, i) => (
                                            <div key={'director_name' + i}>
                                                <div className="flex justify-between items-center gap-2 mb-2">
                                                    <PrimaryLabel>Director Name</PrimaryLabel>
                                                    {i === trusteeObj?.director_name?.length - 1 ? (
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                handleAddEntryToDirectors(trusteeObjIndex);
                                                            }}
                                                        >
                                                            <BigPlusIcon className={'w-8 h-8 p-[4px]'} />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                handleRemoveEntryFromDirectors(trusteeObjIndex, i);
                                                            }}
                                                        >
                                                            <DimmedDeleteIcon className={'w-5 h-5'} />
                                                        </div>
                                                    )}
                                                </div>
                                                <Input
                                                    onChange={(e) => {
                                                        handleDirectorInput(e.target.value, trusteeObjIndex, i);
                                                    }}
                                                    sx={'!bg-transparent'}
                                                    placeholder={'Enter Director Name'}
                                                    value={val === ' ' ? val?.trim() : val}
                                                    useWithoutForm
                                                />
                                            </div>
                                        ))}
                                    </>
                                )}

                                <div className="">
                                    <PhoneInputComponent
                                        value={trusteeObj.phone_number}
                                        title="Phone Number"
                                        placeholder="Enter Phone Number"
                                        titleSx=""
                                        sx="!rounded-xl bg-transparent"
                                        bg={'!bg-transparent'}
                                        atChange={(phone) => {
                                            handleEntityValue(phone, 'phone_number', trusteeObjIndex);
                                        }}
                                        isDisabled={trusteeObj?.disabled?.phone_number}
                                    />
                                </div>
                                <div className="">
                                    <EmailInput
                                        label={'Email Address'}
                                        onChange={(value) => {
                                            handleEntityValue(value, 'email_address', trusteeObjIndex);
                                        }}
                                        placeholder={'Email Address'}
                                        name={'Email Address'}
                                        value={trusteeObj.email_address}
                                        classes="mt-2 bg-transparent"
                                        disabled={trusteeObj?.disabled?.email_address}
                                    />
                                </div>

                                {trusteeObj.trust_type === 'individual' && (
                                    <div className="">
                                        <div>
                                            <label>Date of Birth</label>
                                        </div>
                                        <DatePick
                                            maxDate={dayjs()}
                                            iconSet={{
                                                right: CalendarSmallIco
                                            }}
                                            onDateChange={(date) => {
                                                handleEntityValue(date, 'dob', trusteeObjIndex);
                                            }}
                                            placeholder="DD-MM-YYYY"
                                            format="DD-MM-YYYY"
                                            value={trusteeObj?.dob ? dayjs(trusteeObj.dob, 'DD-MM-YYYY') : ''}
                                            sx="addTaskDate !border-gray-400 !rounded-xl mt-2 text-gray-900 !w-full hover:bg-[#FAF9F6] !bg-transparent"
                                            isDisabled={trusteeObj?.disabled?.dob}
                                        />
                                    </div>
                                )}

                                <div className="">
                                    <PickAddress
                                        id={'residential-autocomplete' + trusteeObjIndex}
                                        disabled={
                                            trusteeObj.trust_type === 'individual'
                                                ? trusteeObj?.disabled?.residential_address
                                                : trusteeObj?.disabled?.current_address
                                        }
                                        address={
                                            trusteeObj.trust_type === 'individual'
                                                ? trusteeObj?.residential_address
                                                : trusteeObj?.current_address
                                        }
                                        setAddress={(address) => {
                                            handleEntityValue(
                                                address,
                                                trusteeObj.trust_type === 'individual'
                                                    ? 'residential_address'
                                                    : 'current_address',
                                                trusteeObjIndex
                                            );
                                        }}
                                        onSelect={(place) => {
                                            handleEntityValue(
                                                place.formatted_address,
                                                trusteeObj.trust_type === 'individual'
                                                    ? 'residential_address'
                                                    : 'current_address',
                                                trusteeObjIndex
                                            );
                                        }}
                                        label={
                                            trusteeObj.trust_type === 'individual'
                                                ? 'Physical Address'
                                                : 'Current Address'
                                        }
                                        placeholder={
                                            trusteeObj.trust_type === 'individual'
                                                ? 'Physical Address'
                                                : 'Current Address'
                                        }
                                        classes="bg-transparent"
                                    />
                                </div>
                                <div className="">
                                    <label className="inputLabel">Trustee Status</label>
                                    <DefaultDoubleSwitch
                                        items={[
                                            {
                                                title: 'Active',
                                                selected: trusteeObj?.trustee_status === 'Active'
                                            },
                                            {
                                                title: 'Inactive',
                                                selected: trusteeObj?.trustee_status === 'Inactive'
                                            }
                                        ]}
                                        onClick={(value) => {
                                            if (value) {
                                                handleEntityValue(value, 'trustee_status', trusteeObjIndex);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(0)}
                                type="button"
                            />

                            <Button
                                title={'Next'}
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default TrusteeDetailsSubForm;
