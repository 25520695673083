import { methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';
import { endpoint } from 'apiEndpoints';

const { slice: getAllNotes, request: getAllNotesThunk } = generateThunkAndSlice(
    'getAllNotes',
    endpoint.getAllNotes,
    methods.POST
);

const { request: createNoteThunk } = generateThunkAndSlice('createNote', endpoint.createNote, methods.POST);

const { request: uploadNoteFileThunk } = generateThunkAndSlice('uploadNoteFile', endpoint.uploadNoteFile, methods.POST);

const { request: deleteNoteThunk } = generateThunkAndSlice('deleteNote', endpoint.deleteNote, methods.POST);
const { request: delNoteFileThunk } = generateThunkAndSlice('delNoteFile', endpoint.delNoteFile, methods.POST);

const { request: updateNoteThunk } = generateThunkAndSlice('updateNote', endpoint.updateNote, methods.POST);

export {
    getAllNotes,
    getAllNotesThunk,
    createNoteThunk,
    uploadNoteFileThunk,
    deleteNoteThunk,
    delNoteFileThunk,
    updateNoteThunk
};
