import React, { useEffect, useRef } from 'react';
import { cn } from 'utills/tailwindUtil';

const ColorInput = ({ sx, value, onChange, required, hasError, defaultOpen }) => {
    const colorPickerRef = useRef(null);

    useEffect(() => {
        if (defaultOpen && colorPickerRef.current) {
            setTimeout(() => {
                colorPickerRef.current.focus();
                colorPickerRef.current.click();
            }, 500);
        }
    }, [defaultOpen]);

    return (
        <div>
            <input
                type="color"
                value={value}
                ref={colorPickerRef}
                onChange={onChange}
                className={cn(
                    sx ? sx : '',
                    'block w-full rounded-lg shadow-sm  !placeholder-[#979797]  border-secondarybg px-2 pl-4 py-2 !outline-none font-poppins text-[0.9rem] font-[400] sm:leading-6 mt-2',
                    hasError ? '!border-red-400' : ''
                )}
                required={required}
            />

            {!hasError && (
                <p className="mt-1 ml-1 text-right text-xs text-red-600" id="email-error">
                    {hasError}
                </p>
            )}
        </div>
    );
};

export default ColorInput;
