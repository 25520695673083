import { XMarkIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/atoms/buttons/Button';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import Input from 'components/atoms/Input';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import DraftEditorNew from 'components/molecules/DraftEditorNew';
// import DraftEditor from 'components/molecules/DraftEditor';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { createJobTemplateThunk, getSingleJobTemplateThunk, updateJobTemplateThunk } from 'store/JobManager';
import { getTemplateTaskThunk } from 'store/settings/JobPortal';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { MileStoneIcon } from 'utills/svgs/MileStoneIcon';
import { SideTimes } from 'utills/svgs/SideTimes';

export const AddJobTemplate = ({ open, setOpen, onComplete, template }) => {
    const { hasPermissions } = useRoleGuard();
    const dispatch = useDispatch();
    const [templateTitle, setTemplateTitle] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [editorContent, setEditorContent] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);
    const { data: getTemplateTask } = useSelector((state) => state?.getTemplateTask);
    const [dataList, setDataList] = useState(null);

    const [milestones, setMilestones] = useState([]);

    const handleAddMileStone = () => {
        const newMilestones = [...milestones];
        newMilestones.push({
            id: newMilestones.length + 1,
            title: '',
            description: null,
            tasks: []
        });

        setMilestones(newMilestones);
    };

    const handleMilestoneKeyValue = (key, value, index, _task) => {
        const newMilestones = [...milestones];
        if (key !== 'task') {
            newMilestones[index][key] = value;
        } else {
            const findTask = newMilestones[index].tasks.some(
                (task) => task?.task_template_id === value?.task_template_id
            );
            if (findTask) {
                return toastHandler(`Task already selected`, TOAST_TYPE_ERROR);
            }
            newMilestones[index].tasks.push({
                title: value?.title,
                task_template_id: value?.task_template_id
            });
        }

        setMilestones(newMilestones);
        setForceUpdate(true);
    };

    const handleRemoveTaskTemplate = (milestoneIndex, index) => {
        const newMilestones = [...milestones];
        newMilestones[milestoneIndex].tasks.splice(index, 1);
        setMilestones(newMilestones);
    };
    const handleRemoveMilestone = (index) => {
        const newMilestones = [...milestones];
        newMilestones.splice(index, 1);
        setMilestones(newMilestones);
    };
    useEffect(() => {
        if (!getTemplateTask && hasPermissions('Job', ['admin'])) {
            dispatch(getTemplateTaskThunk({ business_id }));
        } else {
            setDataList(getTemplateTask);
        }
    }, [getTemplateTask]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!editorContent || editorContent === '' || editorContent.replace(/<\/?[^>]+(>|$)/g, '').trim() === '') {
            return toastHandler('Job description is required', TOAST_TYPE_ERROR);
        }

        let hasError = false;
        milestones?.forEach((milestone) => {
            if (
                !milestone.description ||
                milestone.description === '' ||
                milestone.description.replace(/<\/?[^>]+(>|$)/g, '').trim() === ''
            ) {
                hasError = true;
            } else {
                hasError = false;
            }
        });

        let emptyTasks = false;
        milestones?.forEach((milestone) => {
            if (milestone.tasks?.length < 1) {
                emptyTasks = true;
            } else {
                emptyTasks = false;
            }
        });

        if (hasError) {
            return toastHandler('Milestone description is required', TOAST_TYPE_ERROR);
        }

        if (emptyTasks) {
            return toastHandler('You need to select atleast one task', TOAST_TYPE_ERROR);
        }
        const payload = {
            name: templateTitle,
            title: jobTitle,
            description: editorContent,
            business_id,
            milestones: milestones?.map((milestone) => {
                return {
                    title: milestone?.title,
                    description: milestone?.description,
                    taskIds: milestone.tasks.map((taskObj) => taskObj?.task_template_id),
                    ...(template ? { job_template_milestone_id: milestone?.job_template_milestone_id } : {})
                };
            })
        };

        dispatch(setLoader(true));

        dispatch(
            template
                ? updateJobTemplateThunk({ ...payload, job_template_id: template?.job_template_id })
                : createJobTemplateThunk(payload)
        )
            .then((response) => {
                if (response.payload) {
                    setOpen(false);
                    onComplete();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (template) {
            dispatch(setLoader(true));
            dispatch(getSingleJobTemplateThunk({ urlParams: `${template?.job_template_id}` }))
                .then((response) => {
                    if (response.payload) {
                        const newTemplate = response.payload;
                        setTemplateTitle(newTemplate?.name ?? '');
                        setJobTitle(newTemplate?.title ?? '');
                        setEditorContent(newTemplate?.description ?? '');
                        setForceUpdate(true);

                        if (newTemplate?.milestones?.length > 0) {
                            let newmilestones = [];
                            newTemplate?.milestones?.forEach((milestone) => {
                                newmilestones.push({
                                    job_template_milestone_id: milestone?.job_template_milestone_id,
                                    title: milestone?.title,
                                    description: milestone?.description ? milestone?.description : ' ',
                                    tasks: milestone?.tasks?.map((task) => {
                                        return { title: task?.title, task_template_id: task?.task_template_id };
                                    })
                                });
                            });

                            setMilestones(newmilestones);
                        }
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            setEditorContent(' ');
        }
    }, [template]);
    return (
        <Slideover open={open} setOpen={setOpen} size={'max-w-[1400px]'}>
            <RoleBasedGuard module="Job" permissions={['admin']} sx="p-5">
                <div className="add-detail  text-black">
                    <div className="text-3xl font-fira font-semibold">{template ? 'Edit' : 'Create'} Job Template</div>
                </div>
                {isLoading && <Loader />}
                <div>
                    <form className="default-forms" onSubmit={handleSubmit}>
                        <DefaultLightBgCard title={'Job Template'}>
                            <label> Job Template Name</label>
                            <Input
                                useWithoutForm={true}
                                type="text"
                                placeholder={'Enter job template name'}
                                onChange={(e) => setTemplateTitle(e.target.value)}
                                value={templateTitle}
                                sx={'mt-2'}
                                required={true}
                            />
                        </DefaultLightBgCard>

                        <DefaultLightBgCard title={'Job Information '}>
                            <label> Job Title </label>
                            <Input
                                useWithoutForm={true}
                                type="text"
                                placeholder={'Enter job title'}
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                                sx={'mt-2'}
                                required={true}
                            />

                            <label className="mt-4"> Description </label>

                            <DraftEditorNew
                                onChange={(val) => setEditorContent(val)}
                                initialValue={editorContent}
                                forceUpdate={forceUpdate}
                                setForceUpdate={setForceUpdate}
                                required={true}
                                placeholder="Enter description"
                                sx="mt-2"
                            />

                            {/* {editorContent && (
                                <DraftEditor
                                    onChange={(val) => setEditorContent(val)}
                                    initialValue={editorContent}
                                    maxWords={10000}
                                    required={true}
                                    placeholder="Enter description"
                                    sx="mt-2"
                                />
                            )} */}
                        </DefaultLightBgCard>

                        <DefaultLightBgCard title={'Milestones'}>
                            <div className="mb-3 flex justify-between items-center">
                                <div className="text-sm font-[400]">You can add milestone</div>
                                <Button
                                    title="+Add Milestone"
                                    atClick={() => handleAddMileStone()}
                                    classes="ann-btn px-8 rounded-xl buttons-font-lighter"
                                    buttonType={'button'}
                                />
                            </div>
                            {milestones?.map((milestone, index) => (
                                <div key={index} className="border p-3 rounded-xl mt-2">
                                    <div className="flex justify-between mb-3">
                                        <div className="border-thirdbg border rounded-xl font-[500] p-2 flex gap-2 items-center">
                                            <MileStoneIcon className={'w-[27px]  h-[27px]'} fill={'#b695f8'} />
                                            <span> Milestone {index + 1} </span>
                                        </div>
                                        <span className="cursor-pointer" onClick={() => handleRemoveMilestone(index)}>
                                            <SideTimes />
                                        </span>
                                    </div>{' '}
                                    <label> Milestone Title </label>
                                    <Input
                                        useWithoutForm={true}
                                        type="text"
                                        placeholder={'Enter milestone title'}
                                        onChange={(e) => handleMilestoneKeyValue('title', e.target.value, index)}
                                        value={milestone?.title}
                                        required={true}
                                        sx={'mt-2'}
                                    />
                                    <label className="mt-4"> Description </label>
                                    <textarea
                                        required
                                        onChange={(e) => handleMilestoneKeyValue('description', e.target.value, index)}
                                        rows={2}
                                        defaultValue={milestone?.description || ''}
                                        placeholder="Enter description"
                                        maxLength={10000}
                                        className="w-full scrollbar-hide text-sm font-[400] placeholder:text-secondarybg border-secondarybg rounded-lg focus:!outline-none  focus:!ring-0 focus:!border-secondarybg pt-3 mt-2"
                                    />
                                    {/* <DraftEditorNew
                                        onChange={(val) => handleMilestoneKeyValue('description', val, index)}
                                        initialValue={milestone?.description}
                                        forceUpdate={forceUpdate}
                                        setForceUpdate={setForceUpdate}
                                        maxWords={10000}
                                        required={true}
                                        placeholder="Enter description"
                                        sx="mt-2"
                                    /> */}
                                    <label className="mt-4"> Task </label>
                                    <SimpleSelectMenu
                                        placeholder="Select Tasks"
                                        targetProperty="title"
                                        selectedValue={''}
                                        optionsData={dataList || []}
                                        sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                        hideSelectedValue
                                        onChangeValue={(obj) => handleMilestoneKeyValue('task', obj, index)}
                                        required={true}
                                        valuePropertyName="user_id"
                                    />
                                    {dataList?.length < 1 && (
                                        <div className="bg-dimThird mt-3 rounded-xl p-3 text-sm font-poppins">
                                            No task template found{' '}
                                            <Link
                                                to="/settings/task-module/task-templates"
                                                className="text-third"
                                                target="_blank"
                                            >
                                                click here
                                            </Link>{' '}
                                            to add
                                        </div>
                                    )}
                                    <div className="flex gap-3 items-center flex-wrap mt-5">
                                        {milestone?.tasks?.map((task, taskIndex) => (
                                            <div
                                                className="bg-third text-white rounded-xl flex gap-2 items-center text-sm p-1"
                                                key={taskIndex}
                                            >
                                                {task?.title}
                                                <XMarkIcon
                                                    onClick={() => handleRemoveTaskTemplate(index, taskIndex)}
                                                    className="h-4 w-4 text-black bg-white rounded-full cursor-pointer"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </DefaultLightBgCard>

                        <div className="mt-10 flex justify-center items-center">
                            <Button
                                title={`${template ? 'Edit' : '+Add'} Job Template`}
                                classes="ann-btn px-8 rounded-xl"
                            />
                        </div>
                    </form>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
