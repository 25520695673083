import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { UploadIcon } from 'utills/svgs/UploadIcon';
import { SmallLoader } from 'components/common/SmallLoader';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const DropDownMenu = ({ isButton, options, atClick, isLoading }) => {
    return (
        <div>
            {' '}
            <Menu as="div" className="relative inline-block text-left">
                {isButton ? (
                    <div>
                        <Menu.Button className=" w-full justify-center flex items-center gap-x-2 blue-border-btn rounded-md bg-white px-6 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100">
                            <UploadIcon />
                            Export Data {isLoading && <SmallLoader />}
                        </Menu.Button>
                    </div>
                ) : (
                    <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100">
                            Options
                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-[#979797]" aria-hidden="true" />
                        </Menu.Button>
                    </div>
                )}

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {options?.map((option, index) => (
                            <div key={'options' + index} className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            type="submit"
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block w-full px-4 py-2 text-left text-md '
                                            )}
                                            onClick={() => atClick(option?.title)}
                                        >
                                            <div className="flex gap-2 summary-body items-center">
                                                <div> {<option.icon />}</div>
                                                <div className="!text-[14px]">{option?.title} </div>
                                            </div>
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        ))}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};
