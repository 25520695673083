import React from 'react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import './css/organizationalSettings.css';
import 'react-notifications/lib/notifications.css';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, ERROR_TYPE_ERROR } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import { createTaskStatusThunk } from 'store/workspace/workspaceTasks';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';

import { generateId } from 'utills/uid';
import Slideover from 'components/atoms/SlideOver';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';

const AddDynamicStatus = ({ toggleAddStatus, toggleStatusUpdate, open, setOpen }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [taskLoader, setTaskLoader] = useState(false);

    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (fields.length < 1) {
            toastHandler('Please enter atleast one status', ERROR_TYPE_ERROR);
            return;
        }
        let statuses = [];
        const newInputs = [...fields];

        newInputs.map((input) => {
            statuses.push(input?.text);
        });

        const payload = {
            business_id,
            statuses: statuses
        };

        setTaskLoader(true);
        dispatch(createTaskStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleStatusUpdate();
                    toggleAddStatus();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setTaskLoader(false);
            });
    };

    return (
        <Slideover open={open} setOpen={setOpen} title="Add Dynamic Status">
            <RoleBasedGuard module={'Tasks'} permissions={['admin']} sx="h-[50vh]">
                <div>
                    <div>
                        <motion.div
                            initial={{ x: 700 }}
                            animate={{ x: 0 }}
                            transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                        >
                            <form onSubmit={handleSubmit}>
                                {' '}
                                <div className="add-status-container">
                                    <MultiTextInputAddComponent
                                        placeholder={'Enter title'}
                                        fields={fields}
                                        setFields={setFields}
                                        addSingle={false}
                                    />
                                    <div className="mt-28 w-full flex justify-center items-center bg-white py-2">
                                        <button
                                            disabled={taskLoader ? true : false}
                                            type="submit"
                                            className="ann-btn buttons-font-lighter text-white px-5 md:px-28 py-2 flex gap-2 items-center cursor-pointer  rounded-xl"
                                        >
                                            <span>Add</span>
                                            {taskLoader && <SmallLoaderWhite />}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </motion.div>
                    </div>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
export default AddDynamicStatus;
