import React from 'react';
import Slideover from 'components/atoms/SlideOver';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setSideLoader } from 'store/global/globalReducer';
import { closeThreadThunk } from 'store/workspace/workspaceThreads';
import { BUSINESS_ID, ERROR_TYPE_ERROR } from 'utills/globalVars';

const StopThread = ({ toggleStopThread, threadId, toggleThreadUpdate, open, setOpen }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [reason, setReason] = useState('');

    const stopThread = () => {
        if (!reason) {
            toastHandler('Please enter reason to close this thread', ERROR_TYPE_ERROR);
            return;
        }
        const payload = {
            business_id,
            thread_id: threadId,
            reason_for_closing: reason
        };
        dispatch(setSideLoader(true));
        dispatch(closeThreadThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleThreadUpdate();
                    toggleStopThread();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    return (
        <Slideover title="Reason to Close" open={open} setOpen={setOpen}>
            <div>
                <div>
                    <RoleBasedGuard module={'Threads'} permissions={['write', 'admin']} sx="h-[50vh]">
                        <div className="add-ann-form px-5 mt-5 ">
                            <div className="mt-1">
                                <label>Write a reason for stopping this thread</label>
                            </div>

                            <div className=" mt-3">
                                <DefaultTextArea
                                    placeholder="Reason to close"
                                    className="description-area rounded-xl"
                                    onChange={(e) => setReason(e)}
                                    cols="30"
                                    rows="10"
                                />
                            </div>
                        </div>
                        <div className="add-service-bottom">
                            <button
                                onClick={stopThread}
                                className="add-btn text-white px-12 buttons-font font-fira  rounded-xl"
                            >
                                Submit
                            </button>
                        </div>{' '}
                    </RoleBasedGuard>
                </div>
            </div>
        </Slideover>
    );
};
export default StopThread;
