/* eslint-disable no-restricted-globals */

import { initializeApp } from 'firebase/app';
import { deleteToken, getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STOREAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

let messaging = {};

try {
    messaging = isSupported() ? getMessaging(app) : {};
} catch (error) {
    console.error('error while initializing', error);
}

onMessage(messaging, (payload) => {
    console.info('Message received. ', payload);
});

export const requestPermission = (saveToken) => {
    console.info('Requesting User Permission......');
    try {
        Notification.requestPermission().then(async (permission) => {
            if (permission === 'granted') {
                console.info('Notification User Permission Granted.');
                if (messaging) {
                    return getToken(messaging, { vapidKey: process.env.REACT_APP_FB_VAPID_KEY })
                        .then((currentToken) => {
                            if (currentToken) {
                                localStorage.setItem('fcm_token', currentToken);
                                saveToken(currentToken);
                            } else {
                                console.error('Failed to generate the app registration token.');
                            }
                        })
                        .catch((err) => {
                            console.error('An error occurred when requesting to receive the token.', err);
                        });
                }
                console.error('Error in Messaging Object', messaging);
            } else {
                console.error('User Permission Denied.');
            }
        });
    } catch (error) {
        console.error('An error occurred while getting user permission. ', error);
    }
};

export const removeToken = async () => {
    try {
        const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FB_VAPID_KEY
        });
        if (token) {
            await deleteToken(messaging, token);
        }
    } catch (e) {
        console.error('An error occurred while removing token: ', e);
    }
};
export { messaging };
