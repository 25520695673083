import React from 'react';
export const SignupProtection = (props) => {
    const { Component } = props;

    return (
        <div>
            <Component />
        </div>
    );
};
