import React from 'react';

export const PdfFile = ({ className }) => {
    return (
        <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M48 0H6C2.7 0 0 2.7 0 6V48C0 51.3 2.7 54 6 54H48C51.3 54 54 51.3 54 48V6C54 2.7 51.3 0 48 0ZM19.5 25.5C19.5 27.9 17.4 30 15 30H12V36H7.5V18H15C17.4 18 19.5 20.1 19.5 22.5V25.5ZM34.5 31.5C34.5 33.9 32.4 36 30 36H22.5V18H30C32.4 18 34.5 20.1 34.5 22.5V31.5ZM46.5 22.5H42V25.5H46.5V30H42V36H37.5V18H46.5V22.5ZM27 22.5H30V31.5H27V22.5ZM12 22.5H15V25.5H12V22.5Z"
                fill="#F08201"
            />
        </svg>
    );
};
