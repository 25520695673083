import React from 'react';

export const DashboardIcon = () => {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 2H10C11.1046 2 12 2.89543 12 4V10C12 11.1046 11.1046 12 10 12H4C2.89543 12 2 11.1046 2 10V4C2 2.89543 2.89543 2 4 2ZM0 4C0 1.79086 1.79086 0 4 0H10C12.2091 0 14 1.79086 14 4V10C14 12.2091 12.2091 14 10 14H4C1.79086 14 0 12.2091 0 10V4ZM4 19H10C11.1046 19 12 19.8954 12 21V27C12 28.1046 11.1046 29 10 29H4C2.89543 29 2 28.1046 2 27V21C2 19.8954 2.89543 19 4 19ZM0 21C0 18.7909 1.79086 17 4 17H10C12.2091 17 14 18.7909 14 21V27C14 29.2091 12.2091 31 10 31H4C1.79086 31 0 29.2091 0 27V21ZM27 2H21C19.8954 2 19 2.89543 19 4V10C19 11.1046 19.8954 12 21 12H27C28.1046 12 29 11.1046 29 10V4C29 2.89543 28.1046 2 27 2ZM21 0C18.7909 0 17 1.79086 17 4V10C17 12.2091 18.7909 14 21 14H27C29.2091 14 31 12.2091 31 10V4C31 1.79086 29.2091 0 27 0H21ZM21 19H27C28.1046 19 29 19.8954 29 21V27C29 28.1046 28.1046 29 27 29H21C19.8954 29 19 28.1046 19 27V21C19 19.8954 19.8954 19 21 19ZM17 21C17 18.7909 18.7909 17 21 17H27C29.2091 17 31 18.7909 31 21V27C31 29.2091 29.2091 31 27 31H21C18.7909 31 17 29.2091 17 27V21Z"
                />
            </svg>
        </div>
    );
};
