import React from 'react';
import LoginSuccessfulPage from '../../components/dashboard-components/auth/lost-two-fa-pages/LoginSuccessfulPage';
const LoginSuccessful = () => {
    return (
        <div>
            <LoginSuccessfulPage />
        </div>
    );
};

export default LoginSuccessful;
