import React from 'react';

export const OverDueIcon = () => {
    return (
        <div>
            <svg width="13" height="13" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.9857 7.23333L17.8893 1.91611C17.9493 1.83262 17.9867 1.73244 17.997 1.62699C18.0074 1.52153 17.9904 1.41502 17.9479 1.31957C17.9054 1.22412 17.8392 1.14357 17.7568 1.08708C17.6743 1.03058 17.579 1.00041 17.4816 1H1.52047C1.38243 1 1.25005 1.0597 1.15244 1.16597C1.05484 1.27224 1 1.41638 1 1.56667V17.4333C1 17.5836 1.05484 17.7278 1.15244 17.834C1.25005 17.9403 1.38243 18 1.52047 18C1.65851 18 1.79089 17.9403 1.8885 17.834C1.98611 17.7278 2.04094 17.5836 2.04094 17.4333V13.4667H17.4816C17.579 13.4663 17.6743 13.4361 17.7568 13.3796C17.8392 13.3231 17.9054 13.2425 17.9479 13.1471C17.9904 13.0517 18.0074 12.9451 17.997 12.8397C17.9867 12.7342 17.9493 12.634 17.8893 12.5506L13.9857 7.23333ZM2.04094 12.3333V2.13333H16.3972L12.9101 6.88389C12.8383 6.98362 12.7993 7.10665 12.7993 7.23333C12.7993 7.36002 12.8383 7.48305 12.9101 7.58278L16.3972 12.3333H2.04094Z"
                    fill="#FD4A4A"
                    stroke="#FD4A4A"
                    strokeWidth="0.6"
                />
            </svg>
        </div>
    );
};
