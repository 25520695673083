import React from 'react';
import './css/loader.css';
import synkliLoader from '../../files/synkliLoader.gif';
export const Loader = () => {
    return (
        <div className="app-loader-wrapper">
            <img src={synkliLoader} alt="GIF" className="w-[80px] h-[80px]" />
        </div>
    );
};
