import React from 'react';

export const EditPencilIcon = ({ className }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                clipRule="evenodd"
                d="M15.9421 0.217883C16.2893 0.0740367 16.6616 0 17.0374 0C17.4133 0 17.7855 0.0740367 18.1328 0.217883C18.4801 0.361729 18.7956 0.572568 19.0614 0.838362C19.3272 1.10416 19.5381 1.4197 19.6819 1.76697C19.8258 2.11425 19.8998 2.48646 19.8998 2.86235C19.8998 3.23823 19.8258 3.61044 19.6819 3.95772C19.5381 4.305 19.3272 4.62054 19.0614 4.88633L12.9975 10.9502C12.5584 11.3894 12.0002 11.6877 11.3921 11.8094C11.3921 11.8094 11.3922 11.8094 11.3921 11.8094L8.58837 12.3706C8.29331 12.4296 7.98826 12.3373 7.77545 12.1246C7.56264 11.9118 7.47022 11.6068 7.52917 11.3117L8.08929 8.50798C8.21133 7.89982 8.51076 7.34063 8.94957 6.90225L15.0135 0.838362C15.2793 0.57257 15.5948 0.36173 15.9421 0.217883ZM17.0374 1.8C16.8979 1.8 16.7598 1.82748 16.6309 1.88087C16.502 1.93425 16.3849 2.0125 16.2863 2.11115L15.2143 3.18308L16.7171 4.68512L17.7886 3.61354C17.8873 3.51489 17.9655 3.39778 18.0189 3.26889C18.0723 3.14 18.0998 3.00186 18.0998 2.86235C18.0998 2.72284 18.0723 2.58469 18.0189 2.4558C17.9655 2.32691 17.8873 2.2098 17.7886 2.11115C17.69 2.01251 17.5729 1.93425 17.444 1.88087C17.3151 1.82748 17.177 1.8 17.0374 1.8ZM15.4443 5.95791L13.9415 4.45588L10.2224 8.17504C10.0349 8.36227 9.90654 8.60122 9.85434 8.86099C9.85431 8.86111 9.85436 8.86086 9.85434 8.86099L9.55874 10.3406L11.0389 10.0444C11.2989 9.99237 11.5371 9.86506 11.7248 9.67743L15.4443 5.95791Z"
            />
            <path
                clipRule="evenodd"
                d="M3.11726 3.91719C2.79432 3.91719 2.4846 4.04548 2.25625 4.27383C2.0279 4.50218 1.89961 4.8119 1.89961 5.13484V16.7819C1.89961 17.1048 2.0279 17.4146 2.25625 17.6429C2.4846 17.8713 2.79432 17.9995 3.11726 17.9995H14.7643C15.0873 17.9995 15.397 17.8713 15.6253 17.6429C15.8537 17.4146 15.982 17.1048 15.982 16.7819V13.6054C15.982 13.1084 16.3849 12.7054 16.882 12.7054C17.379 12.7054 17.782 13.1084 17.782 13.6054V16.7819C17.782 17.5822 17.464 18.3498 16.8981 18.9157C16.3322 19.4816 15.5647 19.7995 14.7643 19.7995H3.11726C2.31693 19.7995 1.54938 19.4816 0.983458 18.9157C0.417539 18.3498 0.0996094 17.5822 0.0996094 16.7819V5.13484C0.0996094 4.33451 0.417539 3.56696 0.983458 3.00104C1.54938 2.43512 2.31693 2.11719 3.11726 2.11719H6.29373C6.79079 2.11719 7.19373 2.52013 7.19373 3.01719C7.19373 3.51424 6.79079 3.91719 6.29373 3.91719H3.11726Z"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_6_790"
                    x1="14.3136"
                    y1="0.153848"
                    x2="14.3043"
                    y2="12.3881"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_6_790"
                    x1="9.80838"
                    y1="2.33678"
                    x2="9.79514"
                    y2="19.7996"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
