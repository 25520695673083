import React from 'react';
import './css/loader.css';
export const SmallLoaderWhite = () => {
    return (
        <div>
            <div>
                <div className="lds-dual-ring-white"></div>{' '}
            </div>
        </div>
    );
};
