/* eslint-disable react-hooks/exhaustive-deps */
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import XeroSvg from 'files/xero.png';
import AppConnectionTopSection from 'components/organisms/AppConnectionTopSection';
import TimelineComponent from 'components/organisms/TimelineComponent';
import { Button } from 'components/atoms/buttons/Button';
import { getAuthUrl } from 'connected-apps/xero/auth';
import OrganizationIcon from 'utills/svgs/OrganizationIcon';
import DisconnectIcon from 'utills/svgs/DisconnectIcon';
import DialogModalComponent from 'global-components/DialogModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import { xeroGetAccessTokenThunk, xeroSynkClientThunk } from 'store/appConnectoins';
import { config } from 'app-configs/XeroConfig';
import { Switch } from '@headlessui/react';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { cn } from 'utills/tailwindUtil';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { Loader } from 'components/common/Loader';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

const conntectInstructions = [
    `To initiate the software connection, simply click the "Connect" button. This action will redirect you to Xero's platform, where you'll
    be prompted to log in securely`,
    'Upon logging in, choose the organisation to which you wish to authorize access for Synkli.',

    `Depending on your Xero security settings, you might be prompted to authorise Synkli to access your Xero data. If you see this screen, simply follow the on-screen instructions to grant access. That's it! Once you've completed these steps, your <span className="text-third font-semibod" >  Synkli and Xero </span> accounts will be connected.`
];

export const XeroAppConnections = () => {
    const [active, setActive] = useState('connect');

    const [enabled, setEnabled] = useState(true);

    const [modalType, setModalType] = useState(null);
    const [isLoadingBtn, setIsLoading] = useState(false);
    const { isLoading } = useSelector((state) => state.global);

    const navigate = useNavigate();

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchToken = async () => {
            const query = new URLSearchParams(location.search);
            const code = query.get('code');
            const error = query.get('error');

            if (error) {
                console.error('Query Error', error);
                setModalType('error');
            }
            if (code) {
                try {
                    dispatch(setLoader(true));
                    dispatch(
                        xeroGetAccessTokenThunk({
                            code: code,
                            redirect_uri: config.redirectUri
                        })
                    )
                        .then((res) => {
                            if (res.payload?.status === 'active') {
                                setModalType('success');
                            } else {
                                setModalType('error');
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setModalType('error');
                        })
                        .finally(() => {
                            dispatch(setLoader(false));
                        });
                } catch (error) {
                    console.error('Error fetching token:', error);
                }
            }
        };

        if (location.search) {
            fetchToken();
        }
    }, [location, navigate]);

    const handleConnect = () => {
        setIsLoading(true);
        getAuthUrl();
    };

    const handleSyncClients = () => {
        const payload = {
            allow_sync: enabled
        };
        dispatch(setLoader(true));
        setIsLoading(true);
        dispatch(xeroSynkClientThunk(payload))
            .then((res) => {
                if (res.meta?.requestStatus === 'fulfilled') {
                    toastHandler('Successfully updated', TOAST_TYPE_SUCCESS);
                } else {
                    toastHandler('Error while Updating', TOAST_TYPE_ERROR);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
                setIsLoading(false);
                navigate('/app-connections');
            });
    };

    return (
        <div>
            {isLoading && <Loader />}

            <SidebarLayout>
                <Jumbotron
                    title="App Connections"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },

                        {
                            link: 'app-connections',
                            title: 'App Connections'
                        },
                        {
                            link: '',
                            title: 'Xero'
                        }
                    ]}
                />
                <RoleBasedGuard module={'App_connections'} permissions={['admin']} sx="h-[50vh]">
                    <div className="md:mx-10 mx-5 mt-5">
                        <AppConnectionTopSection
                            title={'Connect your Synkli account with your Xero Account'}
                            description={`Synkli and Xero can work together seamlessly! Connecting your accounts takes about a minute and lets you automatically send your data to Xero. This saves you time and ensures your accounting software is always up-to-date.`}
                            appIcon={XeroSvg}
                        />

                        <div className="px-10 py-12 bg-white rounded-2xl shadow-lg mt-7">
                            <div className="md:col-span-2 col-span-3  flex flex-wrap items-center">
                                <div className="isolate inline-flex rounded-md shadow-sm w-fit">
                                    <div className="flex gap-0 bg-transparent rounded-xl ring-1 ring-outside ring-gray-300 shadow-sm w-full">
                                        <div
                                            onClick={() => setActive('connect')}
                                            className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-9 ${
                                                active === 'connect' && 'bg-leaner '
                                            } `}
                                        >
                                            How to connect
                                        </div>
                                        <div
                                            onClick={() => setActive('used')}
                                            className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-9 ${
                                                active === 'used' && 'bg-leaner '
                                            }`}
                                        >
                                            How you data is used
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="min-h-[300px]">
                                {active === 'connect' && <TimelineComponent timeline={conntectInstructions} />}
                                {active === 'used' && (
                                    <div className="flow-root mt-10">
                                        <div className="text-[18px] font-normal  text-black">
                                            We understand your data is important, that&apos;s why we only access what
                                            you explicitly allow. We keep your information safe and never modify it.
                                            Plus, you&apos;re in control – you can easily disconnect or revoke access
                                            anytime. Here&apos;s the best part: once connected with auto-publishing
                                            turned on, your Synkli client data automatically syncs with your Xero
                                            Contacts, and vice versa! Changes made on Synkli client profiles will also
                                            update your Xero account. Don&apos;t worry, you can turn off auto-publishing
                                            whenever you like.
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-center gap-5 mt-3">
                                <Button
                                    title={'Back'}
                                    classes={
                                        'font-semibold px-12 py-3 rounded-lg text-center place-content-center bg-white text-linear ring-2 ring-inset ring-primary'
                                    }
                                    atClick={() => {
                                        navigate(-1);
                                    }}
                                />
                                <Button
                                    title={'Connect'}
                                    classes={
                                        'font-semibold px-8 py-3 rounded-lg text-center place-content-center bg-linear text-white ring-2 ring-inset ring-primary'
                                    }
                                    atClick={() => handleConnect()}
                                    iconSet={{ loader: SmallLoaderWhite }}
                                    disabled={isLoadingBtn}
                                    isloading={isLoadingBtn}
                                />
                            </div>
                        </div>
                    </div>

                    {modalType === 'success' && (
                        <DialogModalComponent
                            open={modalType === 'success' ? true : false}
                            setOpen={() => {}}
                            sx="max-w-[460px]"
                            title="Organisation Connected"
                            hideCross
                            icon={<OrganizationIcon />}
                        >
                            <p className="buttons-font-lighter text-gray-900 !font-medium font-poppins text-center">
                                Synkli & Xero are now connected! Just turn on &quot;Automatic publishing&quot; to keep
                                your client info up-to-date in both places, and turn it off whenever you like.
                            </p>

                            <Switch.Group as="div" className="flex items-center justify-center gap-5 my-6">
                                <span className="flex">
                                    <Switch.Label
                                        as="span"
                                        className="text-md font-normal leading-6 text-gray-900"
                                        passive
                                    >
                                        Automatically publish all Clients
                                    </Switch.Label>
                                </span>
                                <Switch
                                    checked={enabled}
                                    onChange={() => setEnabled(!enabled)}
                                    className={cn(
                                        enabled ? 'bg-third' : 'bg-gray-200',
                                        'relative inline-flex mx-5 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-third focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={cn(
                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </Switch.Group>

                            <div className="flex justify-center items-center my-5">
                                <Button
                                    atClick={() => handleSyncClients()}
                                    title={'Finish'}
                                    iconSet={{ loader: SmallLoaderWhite }}
                                    disabled={isLoadingBtn}
                                    isloading={isLoadingBtn}
                                    classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                />
                            </div>
                        </DialogModalComponent>
                    )}

                    {modalType === 'error' && (
                        <DialogModalComponent
                            open={modalType === 'error' ? true : false}
                            setOpen={() => setModalType(null)}
                            sx="max-w-[460px]"
                            title="Organisation Connection Failed"
                            icon={<DisconnectIcon />}
                        >
                            <p className="buttons-font-lighter text-gray-900 !font-medium font-poppins text-center">
                                Configuration errors in the integration settings between Xero and Synkli.
                            </p>

                            <div className="flex justify-center items-center my-5">
                                <Button
                                    atClick={() => setModalType(null)}
                                    title={'Close'}
                                    classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                />
                            </div>
                        </DialogModalComponent>
                    )}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
