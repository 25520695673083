import React, { useState } from 'react';
import ComboboxComponent from 'components/atoms/Combobox';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';

import { toBoolean } from 'utills/handleText';
import DefaultSwitch from 'components/atoms/inputs/DefaultSwitch';
import { Button } from 'components/atoms/buttons/Button';
import ConfirmationModal from 'utills/confirmationModal';
import { ResetIcon } from 'utills/ResetIcon';
import { setLoader } from 'store/global/globalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { resetSettingThunk } from 'store/settings/notification-settings';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
export const ModuleSetting = ({
    item,
    key,
    index,
    module,
    moduleIndex,
    _updateObjSetting,
    handleAddReminder,
    handleRemoveReminder,
    getOrdinalValue,
    handleReturnPossibleOptions,
    handleScheduleValueChange,
    handleupdatesettingFun,
    handleGetNotificationModules,
    handleSwitchChange
}) => {
    const dispatch = useDispatch();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { hasPermissions } = useRoleGuard();

    const { data: rolsData } = useSelector((state) => state.fetchRoleGroups);

    const handleResetSetting = (notification_module_s_id) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(
            resetSettingThunk({
                notification_module_s_id,
                notification_module_id: item?.notification_module_id,
                business_id
            })
        )
            .then((response) => {
                if (response.payload) {
                    toastHandler('Notification settings updated', TOAST_TYPE_SUCCESS);

                    handleGetNotificationModules();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleGetSelectedWhoRecieved = (ids) => {
        if (ids?.length > 0) {
            const filtered =
                rolsData &&
                rolsData?.business?.business_groups.filter((group) => ids.includes(group.business_group_id));

            const senitizedArr = filtered?.map((item) => {
                return { business_group_id: item.business_group_id, business_group_name: item.business_group_name };
            });
            return senitizedArr;
        } else {
            return [];
        }
    };
    return (
        <DefaultLightBgCard
            title={item?.title}
            sx="md:mx-10 mx-5 lg:mx-28"
            titleSx={'capitalize text-lg font-poppins font-[500]'}
            rightButton={
                <Button
                    atClick={() => setIsConfirmationOpen(true)}
                    iconSet={{
                        leftIconHTML: (
                            <ResetIcon
                                className={
                                    'h-5 w-5 group-hover:invert group-hover:transform transition-all     group-hover:rotate-[-45deg] '
                                }
                            />
                        )
                    }}
                    tooltip={`Reset ${item?.title} Notifications`}
                    classes="bg-[#f5f5f5] rounded-lg text-sm flex gap-2 items-center font-poppins font-[400] px-3 py-2 hover:bg-thirdbg hover:text-white"
                />
            }
            key={key}
        >
            <div key={index}>
                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={() => {
                        handleResetSetting(item?.notification_module_s_id);
                        setIsConfirmationOpen(false);
                    }}
                    description={`Do you want to reset ${item?.title ?? ''} Notifications ?`}
                    confirmbtnTxt="Reset"
                    title="Reset Confirmation"
                />

                <div className="lg:mx-10 mt-10 mx-5  ">
                    <div className="mt-8  grid gap-2 lg:gap-10 lg:grid-cols-2 p-5">
                        <div className="">
                            <PrimaryParagraph>
                                {item?.is_push !== null && item?.is_email !== null
                                    ? `Choose the type of notifications you want to enable or disable in ${
                                          module?.name
                                      } (${
                                          item?.title ?? ''
                                      }), such as email or push notifications. These notifications can be Enabled/Disabled for team members based on the toggle settings.`
                                    : `Enable or disable notification in ${module?.name} (${
                                          item?.title ?? ''
                                      }) to stay updated on important actions and updates.`}
                            </PrimaryParagraph>
                        </div>
                        <div className="w-full">
                            {item?.is_push !== null && (
                                <div className="flex justify-between items-center  ">
                                    <PrimaryLabel> Push Notification </PrimaryLabel>
                                    <DefaultSwitch
                                        checked={toBoolean(item?.is_push)}
                                        // onChange={(_e) => updateObjSetting(item, toBoolean(item.is_push), 'is_push')}
                                        disabled={!toBoolean(module.is_enable)}
                                        onChange={(_e) =>
                                            handleSwitchChange(
                                                moduleIndex,
                                                index,

                                                !toBoolean(item?.is_push),
                                                'is_push'
                                            )
                                        }
                                    />
                                </div>
                            )}

                            {item?.is_email !== null && (
                                <div className="flex mt-5 justify-between items-center  ">
                                    <PrimaryLabel> Email Notification </PrimaryLabel>
                                    <DefaultSwitch
                                        disabled={!toBoolean(module.is_enable)}
                                        checked={toBoolean(item?.is_email)}
                                        onChange={(_e) =>
                                            handleSwitchChange(
                                                moduleIndex,
                                                index,

                                                !toBoolean(item?.is_email),
                                                'is_email'
                                            )
                                        }
                                        // onChange={(_e) => updateObjSetting(item, toBoolean(item.is_email), 'is_email')}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {item?.schedule?.map((schedule, i) => (
                        <div key={i} className="bg-[#F8F4FE] mt-8 rounded-xl p-5">
                            <div className="grid gap-2 items-end lg:gap-10 lg:grid-cols-2">
                                <div className="flex items-center">
                                    <PrimaryParagraph>
                                        {/* {!item?.select?.some((i) => i?.name === 'Immediately') && (
                                            <div className="bg-linear invisible  text-white rounded-full p-2 cursor-pointer">
                                                <PlusIcon className="w-5 h-5" />
                                            </div>
                                        )} */}
                                        Set when notifications should be sent for actions in the {module?.name} (
                                        {item?.title})
                                    </PrimaryParagraph>
                                </div>
                                <div className="flex justify-end items-center w-full ">
                                    <div className=" w-full">
                                        {item?.select?.some((i) => i?.name === 'Immediately') ? (
                                            <div className="flex justify-between items-center ">
                                                <SimpleSelectMenu
                                                    placeholder="Select"
                                                    targetProperty="name"
                                                    selectedValue={schedule?.select?.name}
                                                    valuePropertyName="name"
                                                    optionsData={item?.select || []}
                                                    sx="rounded-xl mt-2 py-3 ring-gray-400  !font-normal cursor-pointer bg-transparent"
                                                    showChipOnly={false}
                                                    onChangeValue={(obj) => {
                                                        handleScheduleValueChange(moduleIndex, index, i, obj, 'select');
                                                    }}
                                                    disabled={!toBoolean(module.is_enable)}
                                                />
                                            </div>
                                        ) : (
                                            <div>
                                                {/* {!['Task Delayed'].includes(item?.title) && ( */}
                                                {item?.select?.length > 1 && (
                                                    <div className="flex justify-end items-center mb-3">
                                                        {Number(i) === 0 && item?.schedule?.length - 1 > 0 ? (
                                                            <div
                                                                onClick={() =>
                                                                    handleRemoveReminder(moduleIndex, index, i)
                                                                }
                                                                className={`${
                                                                    i > 0
                                                                } cursor-pointer bg-thirdbg text-white rounded-full p-2`}
                                                            >
                                                                <MinusIcon className="w-5 h-5" />
                                                            </div>
                                                        ) : Number(i) === 0 && item?.schedule?.length - 1 === i ? (
                                                            <div
                                                                onClick={() => handleAddReminder(moduleIndex, index)}
                                                                className="bg-linear text-white rounded-full p-2 cursor-pointer"
                                                            >
                                                                {/* <PlusVector /> */}

                                                                <PlusIcon className="w-5 h-5" />
                                                            </div>
                                                        ) : i > 0 && i < item?.schedule?.length - 1 ? (
                                                            <div
                                                                onClick={() =>
                                                                    handleRemoveReminder(moduleIndex, index, i)
                                                                }
                                                                className={`${
                                                                    i > 0
                                                                } cursor-pointer bg-thirdbg text-white rounded-full p-2`}
                                                            >
                                                                <MinusIcon className="w-5 h-5" />
                                                            </div>
                                                        ) : i > 0 && i === item?.schedule?.length - 1 ? (
                                                            <div className="flex gap-2 items-center">
                                                                <div
                                                                    onClick={() =>
                                                                        handleRemoveReminder(moduleIndex, index, i)
                                                                    }
                                                                    className={`${
                                                                        i > 0
                                                                    } cursor-pointer bg-thirdbg text-white rounded-full p-2`}
                                                                >
                                                                    <MinusIcon className="w-5 h-5" />
                                                                </div>
                                                                <div
                                                                    onClick={() =>
                                                                        handleAddReminder(moduleIndex, index)
                                                                    }
                                                                    className="bg-linear text-white rounded-full p-2 cursor-pointer"
                                                                >
                                                                    <PlusIcon className="w-5 h-5" />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                )}{' '}
                                                <div className="grid grid-cols-2 gap-5">
                                                    <div className="capitalize content-center">
                                                        {getOrdinalValue(i + 1)} Reminder{' '}
                                                    </div>

                                                    {/* {schedule?.select?.name || '--'} */}
                                                    <SimpleSelectMenu
                                                        placeholder="Select"
                                                        targetProperty="name"
                                                        selectedValue={schedule?.select?.name}
                                                        valuePropertyName="name"
                                                        optionsData={
                                                            handleReturnPossibleOptions(moduleIndex, index, i) || []
                                                        }
                                                        sx="rounded-xl mt-2 py-3 ring-gray-400 bg-transparent !font-normal cursor-pointer"
                                                        showChipOnly={false}
                                                        onChangeValue={(obj) => {
                                                            handleScheduleValueChange(
                                                                moduleIndex,
                                                                index,
                                                                i,
                                                                obj,
                                                                'select'
                                                            );
                                                        }}
                                                        disabled={!toBoolean(module.is_enable)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="w-full mt-4 grid gap-2 lg:gap-10 lg:grid-cols-2">
                                <div className="flex items-center">
                                    {' '}
                                    <PrimaryParagraph>
                                        Specify who should receive updates related to {module?.name} ({item?.title})
                                    </PrimaryParagraph>
                                </div>
                                <div className="!w-full">
                                    <div className="flex justify-end items-center !w-full">
                                        <div className="!w-full">
                                            <ComboboxComponent
                                                data={
                                                    rolsData?.business
                                                        ? rolsData?.business?.business_groups.map((group) => {
                                                              return {
                                                                  business_group_id: group.business_group_id,
                                                                  business_group_name: group.business_group_name
                                                              };
                                                          })
                                                        : []
                                                }
                                                // data={
                                                //     rolsData?.business
                                                //         ? handleGetSelectedWhoRecieved(item?.whow_received)
                                                //         : []
                                                // }
                                                // data={
                                                //     item?.whow_received?.length
                                                //         ? item?.whow_received?.map((item) => {
                                                //               return {
                                                //                   name: item
                                                //               };
                                                //           })
                                                //         : []
                                                // }
                                                placeholder={'Select'}
                                                onChange={(e) =>
                                                    handleScheduleValueChange(
                                                        moduleIndex,
                                                        index,
                                                        i,
                                                        e?.map((item) => {
                                                            return item?.business_group_id;
                                                        }),
                                                        'send_to'
                                                    )
                                                }
                                                targetProperty="business_group_name"
                                                displayPropertyName="business_group_name"
                                                showNameOnly
                                                maxDisplay={10}
                                                // limit={1}
                                                // selectedValue={handleGetSelectedWhoRecieved(schedule.send_to) ?? []}
                                                selectedData={handleGetSelectedWhoRecieved(schedule.send_to) ?? []}
                                                // restrictLastRemove
                                                sx={'w-full flex items-center flex-wrap bg-transparent '}
                                                inputsx={'!flex-1 max-w-none !p-0'}
                                                disabled={!toBoolean(module.is_enable)}
                                                hideImage
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-10 flex justify-end item-center lg:mx-10  mx-5 ">
                    <Button
                        title="Save"
                        atClick={() => handleupdatesettingFun(moduleIndex, index)}
                        classes="bg-linear px-9 py-2 text-white font-poppins font-[400]"
                    />
                </div>
                <div className="flex justify-center items-center mt-10">
                    <div className="border-b    "></div>
                </div>
            </div>
        </DefaultLightBgCard>
    );
};
