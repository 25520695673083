/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import TDjumbo from './TDjumbo';
import TDoperations from './TDoperations';
import TDlist from './TDlist';
import NoDirectory from './NoDirectory';
import './css/telephonicdirectories.css';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setLoader } from 'store/global/globalReducer';
import { getAllTelephonicDirectoriesThunk } from 'store/workspace/workspaceTelephonicDirectories';
import { BUSINESS_ID } from 'utills/globalVars';
import { useSelector } from 'react-redux';
import { getBranchLocationsThunk } from 'store/auth/slices';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';

const TelephonicDirectoriesPage = ({ toggleAddDirectory, showAddDirectory, directoryUpdated, toggleEditDirectory }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data } = useSelector((state) => state.getAllTelephonicDirectories);

    const [searchValue, setSearchValue] = useState('');
    const [branches, setBranches] = useState([]);
    const [branchId, setBranchId] = useState('');

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);

    function searchByItem(value) {
        setSearchValue(value);
    }

    const handleChangePage = (newPage, perPageCount) => {
        setPage(newPage);
        setPageSize(perPageCount);
        getAllTelephonicDirectories({
            business_id,
            page: newPage,
            pageSize: perPageCount
        });
    };

    const handleFilterByBranch = (value) => {
        setBranchId(value);
    };

    const getBranchLocations = (payload) => {
        dispatch(getBranchLocationsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setBranches(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const getAllTelephonicDirectories = (payload) => {
        dispatch(getAllTelephonicDirectoriesThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        dispatch(setLoader(true));
        getAllTelephonicDirectories({
            business_id,
            page: 1,
            pageSize: pageSize,
            search_term: searchValue,
            business_branch_id: branchId
        });
    }, [directoryUpdated, searchValue, branchId]);

    useEffect(() => {
        getBranchLocations({ business_id });
    }, []);

    return (
        <div>
            <div>
                <TDjumbo showAddDirectory={showAddDirectory} toggleAddDirectory={toggleAddDirectory} path />
            </div>
            <div>
                <TDoperations
                    data={data}
                    searchByItem={searchByItem}
                    branches={branches}
                    handleFilterByBranch={handleFilterByBranch}
                    selectedBranch={branchId}
                />
            </div>
            <div>
                {data?.rows.length < 1 ? (
                    <div className="mt-36">
                        {' '}
                        <NoDirectory />
                    </div>
                ) : (
                    <TDlist
                        filteredItems={data}
                        page={page}
                        pageSize={pageSize}
                        count={data?.count}
                        handleChangePage={handleChangePage}
                        getAllTelephonicDirectories={getAllTelephonicDirectories}
                        toggleEditDirectory={toggleEditDirectory}
                    />
                )}
            </div>
        </div>
    );
};

export default TelephonicDirectoriesPage;
