import React from 'react';

export const ShieldIcon = (className) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M9.76556 0.0351562C9.71088 0.0546875 7.92963 0.792969 5.80072 1.67578C2.77338 2.93359 1.90619 3.3125 1.80853 3.40625C1.54681 3.65625 1.55463 3.58203 1.57416 6.39062C1.58978 9.01172 1.60541 9.27734 1.77728 10.1953C2.27338 12.8125 3.53119 15.207 5.40228 17.0742C6.52338 18.1992 7.77338 19.0625 9.27728 19.7578C9.69525 19.9492 9.77338 19.9766 9.99994 19.9766C10.2265 19.9766 10.3085 19.9492 10.7343 19.7539C15.1288 17.7461 18.0234 13.6016 18.3984 8.77344C18.4179 8.49609 18.4374 7.26562 18.4374 6.03906C18.4374 3.95312 18.4296 3.79688 18.3632 3.65234C18.3242 3.56641 18.2382 3.44922 18.1757 3.39062C18.0976 3.31641 16.8554 2.78125 14.1601 1.65625C10.1718 0 10.0234 -0.0546875 9.76556 0.0351562ZM13.457 3.05859L16.8554 4.47266L16.8515 6.66016C16.8515 8.73047 16.8476 8.87891 16.7617 9.41406C16.7148 9.72656 16.6328 10.1641 16.582 10.3906C15.8007 13.7891 13.5273 16.6562 10.3945 18.1836L9.99603 18.3789L9.48041 18.1172C6.31634 16.5195 4.06244 13.582 3.35931 10.1484C3.16009 9.17188 3.14447 8.90625 3.14447 6.62109V4.47656L6.54291 3.0625C8.414 2.28125 9.96869 1.64453 9.99994 1.64453C10.0312 1.64453 11.5898 2.28125 13.457 3.05859Z"
                fill="black"
            />
            <path
                d="M12.7344 6.9375C12.6485 6.97266 11.9493 7.64063 10.8711 8.72266L9.14067 10.4453L8.27349 9.58594C7.56646 8.88281 7.36724 8.71094 7.2188 8.65234C6.62505 8.42969 6.04302 8.98438 6.21099 9.60938C6.25396 9.76563 6.39067 9.91797 7.43364 10.9688C8.08208 11.6211 8.67583 12.1914 8.75005 12.2383C8.92583 12.3477 9.29302 12.3555 9.49224 12.2539C9.57036 12.2188 10.5547 11.2578 11.6836 10.125C13.5821 8.22266 13.7422 8.05078 13.7891 7.88281C13.961 7.23828 13.336 6.67578 12.7344 6.9375Z"
                fill="black"
            />
        </svg>
    );
};
