import { NoPermission } from 'components/molecules/NoPermission';
import React from 'react';
import { useSelector } from 'react-redux';

export const RoleBasedGuard = ({ children, module, permissions = ['read', 'admin', 'write'], sx, title }) => {
    const { data: loginData } = useSelector((state) => state.login);
    const allPermissions = JSON.parse(localStorage.getItem('allPermissions'));

    if (
        !loginData?.is_employee ||
        (loginData?.is_employee && permissions.some((permission) => allPermissions?.[module]?.[permission]))
    ) {
        return children;
    } else if (title) {
        return (
            <>
                <div className="flex justify-between items-center bg-dimBlue p-3">
                    <div className={`card-heading  `}>{title}</div>
                    <div>
                        <div className="flex gap-3 items-center "> </div>
                    </div>
                </div>
                <NoPermission classes={sx || ''} />
            </>
        );
    } else {
        return (
            <div>
                <NoPermission classes={sx || ''} />
            </div>
        );
    }
};
