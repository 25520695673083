import { PencilIcon } from '@heroicons/react/20/solid';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { SearchInput } from 'components/molecules/SearchInput';
import InlinePagination from 'components/pagination/InlinePagination';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setSearch, setSelectedDraft } from 'store/custom-reducers/RelayRoomDraftReducer';

const DraftLeftBar = ({ selected_ids, setSelected_ids, handleSelectThread }) => {
    const dispatch = useDispatch();

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    const {
        data: pageData,
        currentPage,
        totalPages,
        total_count,
        itemsPerPage,
        search,
        isLoading
    } = useSelector((state) => state.draftListReducer);

    const handleInputChange = (e) => {
        const { value } = e.target;
        dispatch(setSearch(value));
    };

    const handleSelectAll = () => {
        if (selected_ids.length === pageData?.length) {
            setSelected_ids([]);
        } else {
            setSelected_ids(pageData?.map((item) => item?.job_email_id));
        }
    };

    const handleSelectDraft = (obj) => {
        dispatch(setSelectedDraft(obj));
    };
    return (
        <>
            <div className="flex flex-col gap-5 py-5 border-b !border-[#97979766]/40% px-3">
                <div className="">
                    <SearchInput
                        placeholder="Search"
                        width="100%"
                        name="search"
                        classes="!py-2 !h-auto"
                        defaultValue={search || ''}
                        atChange={(e) => {
                            handleInputChange(e);
                        }}
                    />
                </div>

                <div className="flex justify-between flex-wrap gap-2 items-center">
                    <div>
                        <PrimaryCheckbox
                            id="select_all"
                            name="select_all"
                            onchange={() => {
                                handleSelectAll();
                            }}
                            checked={!isLoading && pageData?.length > 0 && selected_ids.length === pageData?.length}
                            htmlFor="select_all"
                            sx=""
                            inputSx="!border-third text-xs"
                            labelsx={'!text-sm'}
                            label="Select All"
                        />
                    </div>
                    <div>
                        <InlinePagination
                            page={currentPage}
                            pageSize={itemsPerPage}
                            total_count={total_count}
                            totalPages={totalPages}
                            onChangePage={(e) => {
                                dispatch(setCurrentPage(e));
                            }}
                            scrollTo="items_list"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full divide-y border-[#97979766]/40% ">
                {isLoading && (
                    <div className="w-full h-full">
                        <SkeletonTable minLength={5} columns={2} showLogo logoIndex={0} sxLogo={'!w-15 !h-15'} />
                    </div>
                )}

                {!isLoading &&
                    pageData?.map((dataObj, index) => {
                        return (
                            <div key={'relayroom_list' + index}>
                                <div
                                    className="cursor-pointer flex truncate items-center justify-between hover:bg-slate-100 rounded-lg text-black text-sm px-3 py-4 w-full text-right"
                                    key={'minimized_' + index}
                                >
                                    <div className="flex gap-2 items-center flex-1 truncate">
                                        <PrimaryCheckbox
                                            id="select_all"
                                            name="select_all"
                                            checked={selected_ids?.includes(dataObj?.email_draft_id)}
                                            onchange={() => {
                                                handleSelectThread(dataObj?.email_draft_id);
                                            }}
                                            inputSx="!border-third"
                                        />
                                        <span
                                            className="flex-1 truncate text-left"
                                            onClick={() => {
                                                handleSelectDraft(dataObj);
                                            }}
                                        >
                                            {dataObj?.subject}
                                        </span>
                                    </div>
                                    <span
                                        onClick={() => {
                                            if (!disabled) {
                                                handleSelectDraft(dataObj);
                                            }
                                        }}
                                    >
                                        <PencilIcon
                                            className={` ${disabled && '!cursor-not-allowed opacity-50'} w-4 h-4`}
                                        />
                                    </span>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default DraftLeftBar;
