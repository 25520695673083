import React, { useState } from 'react';
import FinilizeSTPPage from '../../components/payrol-pages/finilize-stp-pages/FinilizeSTPPage';
import { YearToDateSummary } from '../../components/payrol-pages/finilize-stp-pages/YearToDateSummary';

const FinilizeSTP = () => {
    const [showYearToDateSummary, setShowYearToDateSummary] = useState(false);

    const toggleYearToDateSummary = () => {
        setShowYearToDateSummary(!showYearToDateSummary);
    };

    return (
        <div className="app-dashboard">
            {showYearToDateSummary && <YearToDateSummary toggleYearToDateSummary={toggleYearToDateSummary} />}

            <div className={`content ${''}`}>
                <FinilizeSTPPage toggleYearToDateSummary={toggleYearToDateSummary} />
            </div>
        </div>
    );
};

export default FinilizeSTP;
