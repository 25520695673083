/* eslint-disable react/no-children-prop */
import { ArrowDownIcon, ArrowUpIcon, ArrowsUpDownIcon } from '@heroicons/react/20/solid';
import { StarIcon } from '@heroicons/react/24/outline';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { NoDataFound } from 'components/common/NoDataFound';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSelectedClient } from 'store/global/globalReducer';
import { generateRandomProfileLink, getProfileName } from 'utills/globalVars';
import { DateFormatToLocal } from 'utills/moment';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { SideTimes } from 'utills/svgs/SideTimes';
import { cn } from 'utills/tailwindUtil';

const ClientsTableDT = ({
    data,
    isLoading,
    tableView,
    handleSelectId,
    selectedIds,
    handleSelectedAll,
    filters,
    setFilters,
    XeroClients,
    handleMarkFavorite,
    setSelectedIds
}) => {
    const [clientList, setClientList] = useState([]);
    const [openIndexes, setOpenIndexes] = useState([]);
    const [isDetailOpen, setIsDetailOpen] = useState(false);

    const [viewMessage, setViewMessage] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (!viewMessage) {
            setMessage(null);
        }
    }, [viewMessage]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (data) {
            setClientList(data);
        }
    }, [data]);

    const toggleDetails = (index) => {
        const newIndexes = [...openIndexes];
        const alreadyExists = newIndexes?.find((item) => item?.key === index);
        if (alreadyExists) {
            const newIndex = newIndexes?.indexOf(alreadyExists);
            newIndexes?.splice(newIndex, 1);
        } else {
            newIndexes.push({ key: index });
        }
        setOpenIndexes(newIndexes);
        setIsDetailOpen(!isDetailOpen);
    };

    function convertToGeneralName(variableName) {
        if (variableName) {
            const words = variableName?.split('_');
            const generalName = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            return (
                <span
                    className={cn(
                        `inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-sm font-medium`,
                        variableName === 'prospective_client'
                            ? ` bg-yellow-100 text-yellow-800`
                            : variableName === 'active'
                            ? ` !bg-green-100 !text-green-700`
                            : 'bg-gray-200 text-gray-600'
                    )}
                >
                    <svg
                        className={cn(
                            `h-1.5 w-1.5`,
                            variableName === 'prospective_client'
                                ? ` fill-yellow-500`
                                : variableName === 'active'
                                ? ` fill-green-500`
                                : 'fill-gray-400'
                        )}
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                    >
                        <circle cx={3} cy={3} r={3} />
                    </svg>
                    {generalName}
                </span>
            );
        }
    }

    const updateFiltersObject = (id, value) => {
        const updatedOrderBy = Object.fromEntries(
            Object.entries(filters?.order_by).filter(([_key, val]) => val !== 'none')
        );

        setFilters({
            ...filters,
            order_by: {
                ...updatedOrderBy,
                [id]: value
            }
        });
    };

    const RenderSortByArrows = (name, value = '', id) => {
        return (
            <div className="flex items-center justify-start gap-1">
                <label
                    htmlFor={id + '_sort'}
                    onClick={() => {
                        updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                    }}
                    className="cursor-pointer"
                >
                    {name}
                </label>
                {value === 'ASC' ? (
                    <ArrowDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'DESC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : value === 'DESC' ? (
                    <ArrowUpIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'ASC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : (
                    <ArrowsUpDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                        }}
                        className="w-4 h-4 text-[#979797] cursor-pointer"
                    />
                )}
            </div>
        );
    };

    const getXeroLabel = (label) => {
        switch (label) {
            case 'From Xero':
                return <span className="text-orange-400">From Xero</span>;
            case 'To Xero':
                return <span className="text-green-500">To Xero</span>;
            case 'Error Log':
                return <span className="text-red-500">Error Log</span>;
            default:
                return <span className="text-gray-700">{label}</span>;
        }
    };

    const handleSelectedAllCheck = () => {
        return (
            !isLoading &&
            clientList?.length === selectedIds?.length &&
            clientList?.every((client) => selectedIds.some((id) => id.business_client_id === client.business_client_id))
        );
    };

    return (
        <div>
            {selectedIds?.length > 0 && (
                <div className="m1-2 text-sm text-[#666] font-poppins md:mx-10 mx-5">
                    {selectedIds?.length} records selected{' '}
                    <span
                        className="text-thirdbg underline underline-offset-1 cursor-pointer"
                        onClick={() => setSelectedIds([])}
                    >
                        Deselect all records
                    </span>
                </div>
            )}

            <div className=" min-h-[500px]  bg-white mx-4 lg:mx-10 mt-5 rounded-xl overflow-auto">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className={cn(
                                    'buttons-font primary-fonts flex gap-2 items-center !px-0 !w-[300px]',
                                    'text-left'
                                )}
                                style={{ display: 'revert' }}
                            >
                                <div className="flex items-center gap-2">
                                    {handleSelectedAll && (
                                        <input
                                            type="checkbox"
                                            checked={handleSelectedAllCheck()}
                                            className="rounded-[3px] cursor-pointer ml-3"
                                            onChange={(e) => handleSelectedAll(e)}
                                        />
                                    )}

                                    {RenderSortByArrows('Client', filters?.order_by?.email, 'email')}
                                </div>
                            </th>

                            <th
                                scope="col"
                                className={cn('buttons-font primary-fonts !pl-[10px] !w-[200px]', 'text-left')}
                            >
                                {RenderSortByArrows('TFN', filters?.order_by?.tfn, 'tfn')}
                            </th>
                            <th
                                scope="col"
                                className={cn('buttons-font primary-fonts !w-[200px] !pl-[10px]', 'text-left')}
                            >
                                {RenderSortByArrows('Reference No.', filters?.order_by?.client_ref_no, 'client_ref_no')}
                            </th>
                            <th
                                scope="col"
                                className={cn('buttons-font primary-fonts !w-[200px] !pl-[10px]', 'text-left')}
                            >
                                {RenderSortByArrows('Phone', filters?.order_by?.phone_number, 'phone_number')}
                            </th>
                            <th
                                scope="col"
                                className={cn('buttons-font primary-fonts !w-[350px] !pl-[10px]', 'text-left')}
                            >
                                {RenderSortByArrows(
                                    'Address',
                                    filters?.order_by?.residential_address,
                                    'residential_address'
                                )}
                            </th>
                            {tableView === 'all' && (
                                <th
                                    scope="col"
                                    className={cn('tableHeadings primary-fonts !w-[200px] pl-[9px]', 'text-left')}
                                >
                                    {XeroClients
                                        ? RenderSortByArrows('Type', filters?.order_by?.type, 'type')
                                        : RenderSortByArrows(
                                              'Client Type',
                                              filters?.order_by?.client_type,
                                              'client_type'
                                          )}
                                </th>
                            )}

                            <th
                                scope="col"
                                className={cn('tableHeadings primary-fonts !w-[200px] pl-[9px]', 'text-left')}
                            >
                                {RenderSortByArrows('Date Connected', filters?.order_by?.created_at, 'created_at')}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-transparent gap-0">
                        {clientList &&
                            clientList?.length > 0 &&
                            clientList?.map((user, index) => {
                                return (
                                    <>
                                        <tr
                                            key={'clients_table1' + index}
                                            className={cn(
                                                'border-y hover:bg-[#F8F4FE]   transition-all relative  divide-gray-300',
                                                selectedIds?.some(
                                                    (item) => item?.business_client_id === user?.business_client_id
                                                ) && 'bg-[#f5f5f5] primary-shadow '
                                            )}
                                        >
                                            <td
                                                className={cn(
                                                    'whitespace-nowrap flex gap-2  items-center !text-[16px] py-5 pl-6 sm:pl-0',
                                                    !handleSelectedAll && '!block'
                                                )}
                                            >
                                                {selectedIds?.some(
                                                    (item) => item?.business_client_id === user?.business_client_id
                                                ) && (
                                                    <div className="absolute h-full w-[2px] bg-thirdbg left-0 top-0 bottom-0"></div>
                                                )}
                                                <div className="min-w-[18px] flex gap-2 items-center">
                                                    {handleSelectedAll && (
                                                        <>
                                                            <input
                                                                type="checkbox"
                                                                className="rounded-[3px] ml-3 cursor-pointer"
                                                                checked={
                                                                    selectedIds?.some(
                                                                        (item) =>
                                                                            item?.business_client_id ===
                                                                            user?.business_client_id
                                                                    ) || false
                                                                }
                                                                onClick={() =>
                                                                    handleSelectId({
                                                                        business_client_id: user?.business_client_id,
                                                                        client_type: user?.client_type,
                                                                        client_category: user?.client_category,
                                                                        is_favourite: user?.is_favourite,
                                                                        group_id: user?.group_id
                                                                    })
                                                                }
                                                            />
                                                            <span
                                                                onClick={() =>
                                                                    handleMarkFavorite(
                                                                        user?.is_favourite === 'yes' ? 'no' : 'yes',
                                                                        [user]
                                                                    )
                                                                }
                                                            >
                                                                <StarIcon
                                                                    className={`w-5 h-5 text-thirdbg cursor-pointer ${
                                                                        user?.is_favourite === 'yes'
                                                                            ? 'fill-thirdbg'
                                                                            : ''
                                                                    } `}
                                                                />
                                                            </span>
                                                        </>
                                                    )}
                                                </div>

                                                <Link
                                                    to={
                                                        user.business_client_id
                                                            ? `/app/user/profile/${user.business_client_id}`
                                                            : '#'
                                                    }
                                                    onClick={() => dispatch(setSelectedClient(user))}
                                                    rel="noopener noreferrer"
                                                >
                                                    <div className="flex items-center gap-4 cursor-pointer">
                                                        <div>
                                                            {user.image ? (
                                                                <div className="">
                                                                    <img
                                                                        src={user.image}
                                                                        alt="userimg"
                                                                        className="image-inside !min-w-11 !min-h-11 !w-12 !h-12 !rounded-md"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {user?.client_category === 'Partnership' ||
                                                                    user?.client_category === 'Company' ||
                                                                    user?.client_category === 'Trust' ? (
                                                                        <img
                                                                            src={generateRandomProfileLink(
                                                                                getProfileName(user)
                                                                            )}
                                                                            alt="userimg"
                                                                            className="image-inside !min-w-11 !min-h-11 !w-12 !h-12 !rounded-md"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={generateRandomProfileLink(
                                                                                getProfileName(user)
                                                                            )}
                                                                            alt="userimg"
                                                                            className="image-inside !min-w-11 !min-h-11 !w-12 !h-12 !rounded-md"
                                                                        />
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div
                                                            className={cn(
                                                                'flex flex-col',
                                                                selectedIds?.some(
                                                                    (item) =>
                                                                        item?.business_client_id ===
                                                                        user?.business_client_id
                                                                ) && 'text-thirdbg'
                                                            )}
                                                        >
                                                            <div className="app-user-name flex items-center gap-2">
                                                                <div>{getProfileName(user)}</div>
                                                            </div>
                                                            <div className="recently-added app-user-email break-all">
                                                                {user.email}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td
                                                className={
                                                    'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black'
                                                }
                                            >
                                                {user?.tfn || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                                {user?.client_ref_no || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                                {user.phone_number || 'N/A'}
                                            </td>
                                            <td className="whitespace-nowrap1 break-all text-[14px] lg:text-[16px] px-3 py-5 text-black max-w-[300px]">
                                                {user?.residential_address
                                                    ? user?.residential_address
                                                    : user?.address
                                                    ? user?.address
                                                    : 'N/A'}
                                            </td>
                                            {tableView === 'all' && (
                                                <td
                                                    className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black cursor-pointer"
                                                    onClick={() => {
                                                        if (XeroClients && user?.xero_error) {
                                                            setMessage(user?.xero_error);
                                                            setViewMessage(true);
                                                        }
                                                    }}
                                                >
                                                    {XeroClients
                                                        ? getXeroLabel(user?.xero_lable)
                                                        : convertToGeneralName(user?.client_type)}
                                                </td>
                                            )}


                                        <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 flex gap-3 justify-between w-full items-center  text-[#979797]">
                                            <div>
                                                {XeroClients && user?.created_at
                                                    ? DateFormatToLocal({
                                                          date: user?.created_at,
                                                          format: 'DD-MM-YYYY hh:mm A'
                                                      })
                                                    : user?.connected_at
                                                    ? DateFormatToLocal({
                                                          date: user?.connected_at,
                                                          format: 'DD-MM-YYYY hh:mm A'
                                                      })
                                                    : 'Recently Added'}
                                            </div>
                                            <span
                                                className={`cursor-pointer transition-transform transform 
                                                    ${''}
                                                `}
                                                >
                                                    {user?.relations?.length > 0 && (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => toggleDetails(index)}
                                                        >
                                                            {openIndexes?.find((item) => item?.key === index) ? (
                                                                <CollapsIcon className={'w-6 h-6'} />
                                                            ) : (
                                                                <ExpandIcon className={'w-6 h-6'} />
                                                            )}
                                                        </span>
                                                    )}
                                                </span>
                                            </td>
                                        </tr>

                                        <>
                                            {openIndexes?.find((item) => item?.key === index) && (
                                                <>
                                                    {user?.relations?.map((user, cIndex) => (
                                                        <tr
                                                            key={'clients_tableone' + index + '_' + cIndex}
                                                            colSpan={7}
                                                            className="bg-[#f7f4f4]"
                                                        >
                                                            <td className="whitespace-nowrap border-l flex gap-2 items-center !text-[16px] py-5 !pl-5 sm:pl-0">
                                                                <div className="min-w-[18px]">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="rounded-[3px] cursor-pointer"
                                                                        defaultChecked={selectedIds?.some(
                                                                            (item) =>
                                                                                item?.business_client_id ===
                                                                                user?.business_client_id
                                                                        )}
                                                                        onClick={() =>
                                                                            handleSelectId({
                                                                                business_client_id:
                                                                                    user?.business_client_id,
                                                                                client_type: user?.client_type,
                                                                                client_category: user?.client_category,
                                                                                is_favourite: user?.is_favourite,
                                                                                group_id: user?.group_id
                                                                            })
                                                                        }
                                                                    />
                                                                </div>

                                                                <Link
                                                                    to={
                                                                        user.business_client_id
                                                                            ? `/app/user/profile/${user.business_client_id}`
                                                                            : '#'
                                                                    }
                                                                    onClick={() => dispatch(setSelectedClient(user))}
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <div className="flex items-center gap-4 cursor-pointer">
                                                                        <div>
                                                                            {user.image ? (
                                                                                <div className="">
                                                                                    <img
                                                                                        src={user.image}
                                                                                        alt="userimg"
                                                                                        className="image-inside !min-w-11 !min-h-11 !w-12 !h-12 !rounded-md"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                <div>
                                                                                    {user?.client_category ===
                                                                                        'Partnership' ||
                                                                                    user?.client_category ===
                                                                                        'Company' ||
                                                                                    user?.client_category === 'Trust' ||
                                                                                    user?.client_category ===
                                                                                        'Association' ||
                                                                                    user?.client_category === 'SMSF' ? (
                                                                                        <img
                                                                                            src={generateRandomProfileLink(
                                                                                                user.display_name + ' '
                                                                                            )}
                                                                                            alt="userimg"
                                                                                            className="image-inside !min-w-11 !min-h-11 !w-12 !h-12 !rounded-md"
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            src={generateRandomProfileLink(
                                                                                                user.first_name +
                                                                                                    ' ' +
                                                                                                    user.last_name
                                                                                            )}
                                                                                            alt="userimg"
                                                                                            className="image-inside !min-w-11 !min-h-11 !w-12 !h-12 !rounded-md"
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div className="flex flex-col">
                                                                            <div className="app-user-name flex items-center gap-2">
                                                                                <div>
                                                                                    {user?.client_category ===
                                                                                        'Partnership' ||
                                                                                    user?.client_category ===
                                                                                        'Company' ||
                                                                                    user?.client_category === 'Trust' ||
                                                                                    user?.client_category ===
                                                                                        'Association' ||
                                                                                    user?.client_category === 'SMSF'
                                                                                        ? user?.display_name
                                                                                        : `${user?.first_name} ${user?.last_name}`}
                                                                                </div>
                                                                            </div>
                                                                            <div className="recently-added app-user-email break-all">
                                                                                {user?.email}
                                                                            </div>

                                                                            <div>
                                                                                {user?.relation === 'spouse' ? (
                                                                                    <div className="pt-2">
                                                                                        <span className="default-positive-opacity text-[#008C51] rounded-xl x-small-text font-poppins px-4 py-1 ">
                                                                                            Spouse
                                                                                        </span>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="pt-2">
                                                                                        <span className="default-negative-color text-[#F08201] rounded-xl x-small-text font-poppins px-4 py-1 ">
                                                                                            Related Entity
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                            <td
                                                                className={
                                                                    'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black'
                                                                }
                                                            >
                                                                {user?.tfn || 'N/A'}
                                                            </td>
                                                            <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                                                {user?.client_ref_no || 'N/A'}
                                                            </td>
                                                            <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                                                {user.phone_number}
                                                            </td>
                                                            <td className="whitespace-nowrap1 break-all text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                                                {user?.residential_address
                                                                    ? user?.residential_address
                                                                    : user?.address
                                                                    ? user?.address
                                                                    : 'N/A'}
                                                            </td>
                                                            {tableView === 'all' && (
                                                                <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                                                    {convertToGeneralName(user?.client_type)}
                                                                </td>
                                                            )}
                                                            <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 flex gap-3 items-center  text-[#979797]">
                                                                <div>
                                                                    {XeroClients && user?.created_at
                                                                        ? DateFormatToLocal({
                                                                              date: user?.created_at,
                                                                              format: 'DD-MM-YYYY hh:mm A'
                                                                          })
                                                                        : user?.connected_at
                                                                        ? DateFormatToLocal({
                                                                              date: user?.connected_at,
                                                                              format: 'DD-MM-YYYY hh:mm A'
                                                                          })
                                                                        : 'Recently Added'}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    </>
                                );
                            })}
                    </tbody>
                </table>

                {(isLoading || !clientList) && <SkeletonTable columns={7} showLogo logoIndex={0} sxLogo="rounded-md" />}
                {!isLoading && clientList?.length === 0 && (
                    <NoDataFound classes="border-y border-gray-300" message="No Data Found!" height="420px" />
                )}

                {viewMessage && (
                    <DefaultDialog
                        children={
                            <div className="relative py-4">
                                <div
                                    className="absolute right-2 top-2 cursor-pointer"
                                    onClick={() => {
                                        setViewMessage(!viewMessage);
                                    }}
                                >
                                    <SideTimes />
                                </div>

                                <div className="mx-5 table-title text-blueish font-poppins flex font-semibold justify-start items-center">
                                    Error Message
                                </div>
                                <div className="buttons-font-lighter font-poppins text-start mt-3 mx-5">{message}</div>
                            </div>
                        }
                        p="!rounded-2xl !p-2"
                        open={viewMessage}
                        setOpen={setViewMessage}
                        width="md:max-w-[450px]"
                    />
                )}
            </div>
        </div>
    );
};

export default ClientsTableDT;
