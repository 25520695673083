import React from 'react';
import { Page404 } from 'pages/404Page/Page404';
import ThisWasMe from 'pages/ThisWasMe';
import { AttentionRequired } from 'pages/AttentionRequired';
import { AcceptInvite } from 'pages/team/AcceptInvite';

const unProtectedRoutes = [
    {
        path: '/accept/invite/:token',
        component: <AcceptInvite />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/good-to-go',
        component: <ThisWasMe />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/block-device/:token',
        component: <AttentionRequired />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '*',
        component: <Page404 />,
        isProtected: false,
        layout: 'header'
    }
];
export default unProtectedRoutes;
