import React from 'react';
import { DateFormatToLocal } from 'utills/moment';

const FormateDateTime = ({ dateTime, insertedFormat = null }) => {
    return (
        <div className="flex flex-col items-start">
            <div>
                {DateFormatToLocal({
                    date: dateTime,
                    format: 'MMM DD, YYYY',
                    insertedFormat: insertedFormat ?? null
                })}
            </div>
            <div className="text-gray-500">
                at {DateFormatToLocal({ date: dateTime, format: 'hh:mm A', insertedFormat: insertedFormat ?? null })}
            </div>
        </div>
    );
};

export default FormateDateTime;
