import React from 'react';

export const WorkspaceIconSvg = ({ className, extraProps }) => {
    return (
        <svg
            width="70"
            height="66"
            viewBox="0 0 70 66"
            fill="#0A1E46"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...extraProps}
        >
            <path
                d="M34.25 36.9768V36.3268H33.6H5.6H4.95V36.9768V59.7318V60.3818H5.6H33.6H34.25V59.7318V36.9768ZM2.10342 63.2984L1.6402 63.7544L2.10342 63.2984C1.17396 62.3542 0.65 61.0714 0.65 59.7318V36.9768C0.65 35.6372 1.17396 34.3545 2.10342 33.4103C3.03254 32.4664 4.29051 31.9381 5.6 31.9381H33.6C34.9095 31.9381 36.1675 32.4664 37.0966 33.4103C38.026 34.3545 38.55 35.6372 38.55 36.9768V59.7318C38.55 61.0714 38.026 62.3542 37.0966 63.2984C36.1675 64.2422 34.9095 64.7706 33.6 64.7706H5.6C4.29051 64.7706 3.03254 64.2422 2.10342 63.2984ZM65.05 5.68874V5.03874H64.4H36.4H35.75V5.68874V19.9106V20.5606H36.4H64.4H65.05V19.9106V5.68874ZM65.05 36.9768V36.3268H64.4H50.4H49.75V36.9768V51.1987V51.8487H50.4H64.4H65.05V51.1987V36.9768ZM20.25 5.68874V5.03874H19.6H5.6H4.95V5.68874V19.9106V20.5606H5.6H19.6H20.25V19.9106V5.68874ZM36.4 0.65H64.4C65.7095 0.65 66.9675 1.17834 67.8966 2.12219C68.826 3.06638 69.35 4.34912 69.35 5.68874V19.9106C69.35 21.2502 68.826 22.533 67.8966 23.4772C66.9675 24.421 65.7095 24.9494 64.4 24.9494H36.4C35.0905 24.9494 33.8325 24.421 32.9034 23.4772C31.974 22.533 31.45 21.2502 31.45 19.9106V5.68874C31.45 4.34912 31.974 3.06638 32.9034 2.12219C33.8325 1.17834 35.0905 0.65 36.4 0.65ZM50.4 31.9381H64.4C65.7095 31.9381 66.9675 32.4664 67.8966 33.4103C68.826 34.3545 69.35 35.6372 69.35 36.9768V51.1987C69.35 52.5383 68.826 53.8211 67.8966 54.7653C66.9675 55.7091 65.7095 56.2374 64.4 56.2374H50.4C49.0905 56.2374 47.8325 55.7091 46.9034 54.7653C45.974 53.8211 45.45 52.5383 45.45 51.1987V36.9768C45.45 35.6372 45.974 34.3545 46.9034 33.4103C47.8325 32.4664 49.0905 31.9381 50.4 31.9381ZM5.6 0.65H19.6C20.9095 0.65 22.1675 1.17834 23.0966 2.12219C24.026 3.06638 24.55 4.34912 24.55 5.68874V19.9106C24.55 21.2502 24.026 22.533 23.0966 23.4772C22.1675 24.421 20.9095 24.9494 19.6 24.9494H5.6C4.29051 24.9494 3.03254 24.421 2.10342 23.4772C1.17396 22.533 0.65 21.2502 0.65 19.9106V5.68874C0.65 4.34912 1.17396 3.06638 2.10342 2.12219C3.03254 1.17834 4.29051 0.65 5.6 0.65Z"
                stroke="white"
                strokeWidth="1.3"
            />
        </svg>
    );
};
