import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeHttpRequest } from '../../axios';
import { endpoint, methods } from 'apiEndpoints';
import moment from 'moment';

const initialState = {
    jobId: null,
    activePath: [],
    filters: {
        search_term: '',
        order_by: {
            name: 'none',
            size: 'none',
            owner: 'none',
            updated_at: 'none'
        }
    },
    originalData: null,
    data: null,
    globalLoading: false,
    error: null,
    tableView: ''
};

const getDocumentDriveListForActionThunk = createAsyncThunk('jobManagerForActions/request', async (jsonData) => {
    try {
        const res = await makeHttpRequest(methods.GET, endpoint.getDocumentDriveList, jsonData);
        return res;
    } catch (error) {
        return error;
    }
});

export const JobManagerDocumentsForActionSlice = createSlice({
    name: 'jobManagerForActions',
    initialState,
    reducers: {
        setActivePath: (state, action) => {
            state.activePath = action.payload;
        },
        setJobId: (state, action) => {
            state.jobId = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
            state.data = state.originalData;

            if (state.data && action?.payload?.search_term !== '') {
                const searchTerm = action.payload.search_term.toLowerCase();
                state.data = state.data.filter((obj) => obj?.name?.toLowerCase().includes(searchTerm));
            }

            if (state.data && Object.values(action?.payload?.order_by).some((value) => value !== 'none')) {
                state.data = state.data.sort((a, b) => {
                    const { name, size, owner, updated_at } = state.filters.order_by;

                    if (name !== 'none') {
                        return name === 'ASC' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
                    }
                    if (size !== 'none') {
                        return size === 'ASC' ? a.size - b.size : b.size - a.size;
                    }
                    if (updated_at !== 'none') {
                        return updated_at === 'ASC'
                            ? moment(a.updated_at).diff(moment(b.updated_at))
                            : moment(b.updated_at).diff(moment(a.updated_at));
                    }
                    if (owner !== 'none') {
                        return owner === 'ASC'
                            ? a?.created_by?.first_name?.localeCompare(b?.created_by?.first_name)
                            : b?.created_by?.first_name?.localeCompare(a?.created_by?.first_name);
                    }

                    return 0;
                });
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocumentDriveListForActionThunk.pending, (state) => {
                state.data = [];
                state.originalData = [];
                state.globalLoading = true;
                state.error = null;
            })
            .addCase(getDocumentDriveListForActionThunk.fulfilled, (state, action) => {
                state.data = action.payload;
                state.originalData = action.payload;
                state.globalLoading = false;
                state.error = null;
            })
            .addCase(getDocumentDriveListForActionThunk.rejected, (state, action) => {
                state.data = [];
                state.originalData = [];
                state.globalLoading = false;
                state.error = action.payload;
            });
    }
});

export const { setActivePath, setFilters, setJobId } = JobManagerDocumentsForActionSlice.actions;

export default JobManagerDocumentsForActionSlice;
export { getDocumentDriveListForActionThunk };
