import React, { useEffect, useState } from 'react';

export const InputField = ({
    label,
    maxLength,
    _allowSpaces,
    onChange,
    allowCharatersOnly = true,
    preventSpecialCharactors = false,
    name,
    inputValue,
    placeholder,
    classes,
    labelSx,
    required,
    disabled,
    id
}) => {
    const [value, setValue] = useState('');
    //
    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    const handleChange = (e) => {
        let inputValue = e.target.value;

        if (allowCharatersOnly) {
            if (!/^[a-zA-Z\s]*$/.test(inputValue)) {
                return;
            }
        }

        if (preventSpecialCharactors) {
            if (/[^a-zA-Z0-9\s]/.test(inputValue)) {
                return;
            }
        }

        // if (!allowSpaces && inputValue?.includes(' ')) {
        //     return;
        // }

        if (maxLength && inputValue.length > maxLength) {
            inputValue = inputValue.slice(0, maxLength);
        }

        setValue(inputValue);

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div>
            {' '}
            <div>
                <label className={labelSx ?? ''}>{label}</label>
                <input
                    type="text"
                    value={value}
                    onChange={handleChange}
                    name={name}
                    placeholder={placeholder}
                    className={`${classes} !border border-secondarybg ${
                        disabled && '!bg-gray-200 !border-none !cursor-no-drop'
                    }`}
                    required={required ?? false}
                    maxLength={maxLength}
                    disabled={disabled}
                    id={id ? id : ''}
                />
            </div>
        </div>
    );
};
