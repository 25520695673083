import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { request: updateTableSettingsThunk } = generateThunkAndSlice(
    'updateTableSettings',
    endpoint.updateTableSettings,
    methods.POST
);

const { slice: getTableSettings, request: getTableSettingsThunk } = generateThunkAndSlice(
    'getTableSettings',
    endpoint.getTableSettings,
    methods.GET
);
export { updateTableSettingsThunk, getTableSettings, getTableSettingsThunk };
