/* eslint-disable no-unused-vars */
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import React, { useEffect, useState } from 'react';
import { COUNT_PER_PAGE } from 'utills/globalVars';

const InlinePagination = ({ page, pageSize, totalPages, total_count, onChangePage, scrollTo }) => {
    const [timeoutId, setTimeoutId] = useState(null);
    const [goToV, setGoToV] = useState('');
    const [toggle, setToggle] = useState(false);

    const handleChange = (value = 1) => {
        setToggle(false);
        onChangePage(value !== '' && value > 1 ? (value < totalPages ? value : totalPages) : 1);
        if (scrollTo) {
            const element = document.getElementById(scrollTo);
            element.scrollIntoView();
        } else {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleChange(Number(goToV || 1));
        }
    };

    const rightValue = page * pageSize;

    return (
        <div className="flex !justify-center gap-1 items-center">
            <SecondaryParagraph sx="flex gap-1 text-[#666666]">
                <span className={`cursor-pointer`}>{(page - 1) * pageSize + 1}</span>
                <span>-</span>
                <span className={`cursor-pointer`}>{total_count > rightValue ? rightValue : total_count}</span>
                <span>of</span>
                <span className={`cursor-pointer`}>{total_count}</span>
            </SecondaryParagraph>

            <SecondaryParagraph sx={'flex gap-1'}>
                <span
                    className={`text-third cursor-pointer ` + (page === 1 && 'opacity-50')}
                    onClick={() => page > 1 && handleChange(page - 1)}
                >
                    <ChevronLeftIcon className="w-5" />
                </span>
                <span
                    onClick={() => page < totalPages && handleChange(page + 1)}
                    className={`text-third cursor-pointer ` + (page === totalPages && 'opacity-50')}
                >
                    <ChevronRightIcon className="w-5" />
                </span>
            </SecondaryParagraph>
        </div>
    );
};

export default InlinePagination;
