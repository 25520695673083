import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { archiveSubmissionFormThunk, fetchSubmissionFormDataThunk, singleFormLogsThunk } from 'store/form';
import { formHandler, handleEditForm, handleFormDetails, setLoader } from 'store/global/globalReducer';
import { motion } from 'framer-motion';
import { SideTimes } from 'utills/svgs/SideTimes';
import './css/form-details.css';

import { formateDate } from 'utills/moment';
import { BgPdf } from 'utills/svgs/BgPdf';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { EyeIcon } from 'utills/svgs/EyeIcon';
import 'react-notifications/lib/notifications.css';
import { NoDataFound } from 'components/common/NoDataFound';
import {
    APP_ENVIRONMENT,
    BASE_URL,
    BUSINESS_ID,
    CSRF_TOKEN,
    ERROR_TYPE_ERROR,
    getProfileName,
    PERMISSIONS_MESSAGE,
    TOAST_TYPE_ERROR
} from 'utills/globalVars';
import { toastHandler } from 'responseHanlder';
import { useGetFormSubmissions } from 'Hooks/useGetFormSubmissions';
import { Loader } from 'components/common/Loader';
import ConfirmationModal from 'utills/confirmationModal';
import { DefaultLogs } from 'components/molecules/DefaultLogs';
import { UseDownloadLogsPdf } from 'Hooks/Files/UseDownloadLogsPdf';
import { endpoint } from 'apiEndpoints';
import SkeletonTable from 'components/atoms/SkeletonTable';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { fetchSingleFormFbThunk } from 'store/form';
import useRoleGuard from 'Hooks/useRoleGuard';
import { getCookie } from 'utills/encryption/cryptoUtils';

export const FormDetails = ({
    toggleFormDetails,
    id,
    toggleEditForm,
    togglePreviewForm,
    toggleAttachments,
    submissionsUpdate
}) => {
    const dispatch = useDispatch();
    const { formSubmissionsId } = useSelector((state) => state.global);
    const customerFormDataPayload = {
        form_id: formSubmissionsId,
        business_client_id: id
    };

    const [deleteIndex, _setDeleteIndex] = useState(null);
    const { isLoading } = useSelector((state) => state.global);
    const { data: loginData } = useSelector((state) => state.login);
    const { allPermissions } = useSelector((state) => state.global);
    const { fetchSubmissions } = useGetFormSubmissions();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [showFormLogs, setShowFormLogs] = useState(false);
    const [selectedForm, _setSelectedForm] = useState('');
    const { handleDownloadLogsPdf } = UseDownloadLogsPdf();
    const [pdfUrl, setPdfUrl] = useState('');
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [count, setCount] = useState(0);

    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [pageSize, _setPageSize] = useState(5);
    const [selectedDate, setSelectedDate] = useState(null);

    const business_id = localStorage.getItem(BUSINESS_ID);

    const [hasMoreContent, setHasMoreContent] = useState({
        all: true
    });
    const [allPage, setAllPage] = useState(1);

    const [activeTab, setActiveTab] = useState('all');

    const [allPageData, setAllPageData] = useState(null);
    const [formsData, setFormsData] = useState([]);

    useEffect(() => {
        getAllTasks('all');
    }, [allPage]);

    const handlePageChange = () => {
        if (activeTab === 'all') {
            setAllPage(allPage + 1);
        }
    };

    const getAllTasks = async (type, hasReset = false) => {
        let page;
        let dataSetter;
        let pageSetter;
        let stateData;

        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setAllPageData;
                stateData = allPageData;
                pageSetter = 'all';
                break;
            default:
                return;
        }

        const payload = {
            business_id,
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            form_id: formSubmissionsId,
            business_client_id: id
        };

        setHasMoreContent({ ...hasMoreContent, [pageSetter]: true });

        try {
            const response = await dispatch(fetchSubmissionFormDataThunk(payload));

            if (response.payload) {
                const cloneData = [...(!hasReset ? stateData || [] : []), ...response.payload.forms];
                dataSetter(cloneData);
                setHasMoreContent({
                    ...hasMoreContent,
                    [pageSetter]: cloneData.length === response.payload.count ? false : true
                });
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const reloadTaskPage = () => {
        setAllPage(1);
        setActiveTab('all');
        getAllTasks('all', true);
        setAllPageData(null);
    };

    useEffect(() => {
        reloadTaskPage();
    }, [submissionsUpdate]);

    useEffect(() => {
        if (activeTab === 'all') {
            setFormsData(allPageData || []);
        }
    }, [allPageData, activeTab]);

    useEffect(() => {
        dispatch(setLoader(true));
        fetchSubs();
    }, [formSubmissionsId]);

    const { hasPermissions } = useRoleGuard();
    const showEditForm = (form) => {
        if (!hasPermissions('Clients', ['admin', 'write'])) {
            return toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }

        if (form) {
            dispatch(setLoader(true));

            dispatch(
                fetchSingleFormFbThunk({
                    business_form_data_id: form?.business_form_data_id,
                    business_client_id: id
                })
            )
                .then((response) => {
                    if (response?.payload) {
                        dispatch(handleEditForm(response.payload));
                        toggleEditForm();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const fetchSubs = () => {
        fetchSubmissions(customerFormDataPayload);
    };

    const handleDelete = () => {
        dispatch(setLoader(true));
        setIsConfirmationOpen(false);
        dispatch(archiveSubmissionFormThunk({ business_form_data_id: deleteId }))
            .then((response) => {
                if (response.payload) {
                    fetchSubs();
                    reloadTaskPage();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const deleteForm = (delIndex, delID) => {
        if (loginData?.is_employee) {
            if (!allPermissions?.Clients?.write) {
                return toastHandler('You dont have permission to do this action.', ERROR_TYPE_ERROR);
            }
        }
        setDeleteId(delID);
        setIsConfirmationOpen(true);
    };

    const handleViewForm = (form) => {
        if (form) {
            dispatch(setLoader(true));

            dispatch(
                fetchSingleFormFbThunk({
                    business_form_data_id: form?.business_form_data_id,
                    business_client_id: id
                })
            )
                .then((response) => {
                    if (response?.payload) {
                        dispatch(formHandler(response.payload));

                        togglePreviewForm();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };
    const handlePdfClick = async (form) => {
        dispatch(setLoader(true));
        const payload = {
            business_form_data_id: form?.business_form_data_id,
            business_id: form.business_id,
            export_as: 'pdf'
        };

        let csrfToken = getCookie(CSRF_TOKEN);

        const _response = await fetch(`${BASE_URL}/business/form-pdf-fb`, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            credentials: 'include',
            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    setPdfUrl(url);
                } else {
                    toastHandler('Something went wrong!', TOAST_TYPE_ERROR);
                }
            })
            .catch((_error) => {
                toastHandler('Something went wrong!', TOAST_TYPE_ERROR);
            });
        dispatch(setLoader(false));
    };

    const handleAttchments = (index) => {
        const form = formsData[index];
        if (form) {
            dispatch(setLoader(true));

            dispatch(
                fetchSingleFormFbThunk({
                    business_form_data_id: form?.business_form_data_id,
                    business_client_id: id
                })
            )
                .then((response) => {
                    if (response?.payload) {
                        dispatch(handleFormDetails(response.payload));
                        toggleAttachments();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleClearFilter = () => {
        setFrom('');
        setTo('');
        const customerFormDataPayload = {
            form_id: formSubmissionsId,
            business_client_id: id
        };

        fetchSubmissions(customerFormDataPayload);
    };

    const fetchLogs = () => {
        const payload = {
            record_id: selectedForm,
            page,
            page_size: pageSize
        };
        dispatch(singleFormLogsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const newLogs = [...logs, ...response.payload.logs];
                    setLogs(newLogs);
                    setCount(response.payload.count);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleMore = () => {
        const totalPages = Math.ceil(count / pageSize);
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const toggleFormLogs = () => {
        setShowFormLogs(!showFormLogs);
    };

    const handleDownloadActivity = () => {
        const payload = {
            record_id: selectedForm
        };
        handleDownloadLogsPdf(payload, endpoint.downloadSingleFormLogsPdf);
    };

    return (
        <div className="add-p-side grid grid-cols-10 ">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />

            {pdfUrl ? (
                <GlobalPDFViewer
                    url={pdfUrl}
                    setOpen={() => setPdfUrl('')}
                    isLocal={true}
                    open={pdfUrl ? true : false}
                />
            ) : (
                ''
            )}

            <div className="col-span-1 left-side"></div>
            <div className="right-side col-span-9">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    {showFormLogs && (
                        <DefaultLogs
                            toggleLogs={toggleFormLogs}
                            fetchLogs={fetchLogs}
                            logs={logs}
                            count={count}
                            pageSize={pageSize}
                            page={page}
                            handleMore={handleMore}
                            term={term}
                            setTerm={setTerm}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            dateTo={dateTo}
                            setDateTo={setDateTo}
                            open={showFormLogs}
                            setOpen={setShowFormLogs}
                            downloadActivity={handleDownloadActivity}
                        />
                    )}
                    {isLoading && <Loader />}

                    <div>
                        <div
                            onClick={toggleFormDetails}
                            className="absolute   text-white p-2 right-1 top-1 cursor-pointer"
                        >
                            <SideTimes />
                        </div>

                        <div className="add-detail pt-5 px-5">
                            <div className="title">{formsData && <span>{formsData[0]?.form_name}</span>}</div>
                        </div>
                    </div>

                    <div className="flex justify-end items-center gap-5 mt-3">
                        {from !== '' || to !== '' ? (
                            <button onClick={handleClearFilter} className="ann-btn rounded-md px-2">
                                Clear Filter
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                    <div
                        className={`flex justify-end items-center gap-1 mx-5 add-ann-form ${
                            formsData?.length < 1 && 'mt-5'
                        }`}
                    ></div>

                    {!formsData || formsData?.length < 1 ? (
                        <NoDataFound message="There are no submissions on this form" />
                    ) : (
                        <div className="m-5 ">
                            <div className="  grid grid-cols-5 gap-5 buttons-font font-poppins">
                                <div> Name</div>

                                <div className="flex-1">Date Created</div>
                                <div className="flex-1">Date Modified</div>

                                <div className="flex-1">Attachments</div>

                                <div className="flex-1">Actions</div>
                            </div>

                            <hr className="mt-3 " />

                            <InfiniteScrollComp
                                height={550}
                                next={handlePageChange}
                                dataLength={
                                    (formsData?.length === 0
                                        ? hasMoreContent?.[activeTab]
                                            ? 1
                                            : 0
                                        : formsData?.length) || 0
                                }
                                hasMore={formsData?.length !== 0 ? hasMoreContent?.[activeTab] : false}
                                loader={<SkeletonTable columns={5} />}
                            >
                                {formsData?.map((form, index) => (
                                    <div key={index} className=" mt-3">
                                        <div
                                            className={`cursor-pointer buttons-font-lighter font-poppins   grid grid-cols-5 gap-5 
                         ${deleteIndex === index && isLoading ? 'animate-pulse opacity-25 ' : ''} `}
                                        >
                                            <div className="flex items-center">{getProfileName(form?.customer)}</div>

                                            <div className="flex-1 flex items-center form-details-date">
                                                {formateDate(form?.created_at)}
                                            </div>
                                            <div className="flex-1 flex items-center form-details-date">
                                                {formateDate(parseInt(form?.updated_at))}
                                            </div>

                                            <div className="flex-1 flex items-center">
                                                <div
                                                    onClick={() => handleAttchments(index)}
                                                    className="font-bold underline cursor-pointer"
                                                >
                                                    View Attachments
                                                </div>{' '}
                                            </div>

                                            <div>
                                                <div>
                                                    <div className="flex items-center gap-1">
                                                        <span
                                                            onClick={() => handleViewForm(form)}
                                                            className="cursor-pointer"
                                                        >
                                                            {' '}
                                                            <EyeIcon />{' '}
                                                        </span>
                                                        <span onClick={() => handlePdfClick(form)}>
                                                            {' '}
                                                            <span>
                                                                {' '}
                                                                <BgPdf />{' '}
                                                            </span>
                                                        </span>
                                                        <span
                                                            onClick={() => showEditForm(form)}
                                                            className="cursor-pointer"
                                                        >
                                                            {' '}
                                                            <BgEditIcon />{' '}
                                                        </span>
                                                        <span
                                                            className="cursor-pointer "
                                                            onClick={() =>
                                                                deleteForm(index, form?.business_form_data_id)
                                                            }
                                                        >
                                                            {' '}
                                                            <BgDeleteIcon />{' '}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div></div>
                                        <hr className="mt-3 " />
                                    </div>
                                ))}
                            </InfiniteScrollComp>
                        </div>
                    )}
                </motion.div>
            </div>
        </div>
    );
};
