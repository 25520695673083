/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DefaultBarChart } from 'components/molecules/DefaultBarChart';
import { useSelector } from 'react-redux';

export const ReportsBarCharts = () => {
    const { data: yearlyStats } = useSelector((state) => state.yearlyStatistics);
    const { data: reportsStats } = useSelector((state) => state.getTaskReportsInfo);
    const [DummyBusinessDetailsData, setDummyBusinessDetailsData] = useState([]);

    const statData = yearlyStats
        ? yearlyStats?.map((dataObj) => ({
              x: dataObj.month,
              y: dataObj.value
          }))
        : [];
    const xValues = yearlyStats ? yearlyStats.map((dataObj) => dataObj.month) : [];
    const totalValues = yearlyStats
        ? yearlyStats.flatMap((dataObj) => dataObj.value).reduce((total, value) => total + value, 0)
        : 0;

    const statDataTwo = DummyBusinessDetailsData?.map((dataObj) => ({
        x: dataObj.month,
        y: dataObj.count
    }));
    const xValuesTwo = DummyBusinessDetailsData?.map((dataObj) => dataObj.month);

    useEffect(() => {
        if (reportsStats) {
            setDummyBusinessDetailsData(reportsStats?.delayed_task);
        }
    }, [reportsStats]);

    return (
        <div>
            {' '}
            <div className="grid-container3 md:mx-10 mx-5 mt-5 mb-5">
                <div className="grid-item3 item10 all-emp-wrapper rounded-xl shadow-sm  ">
                    <div className="xl-title font-fira text-blueish p-5 mt-3">Tasks Completed</div>
                    <DefaultBarChart
                        xValues={xValues}
                        stats={[
                            {
                                data: statData
                            }
                        ]}
                        height={280}
                        Yaxes={true}
                        barWidth="30%"
                        borderRadius="10"
                    />
                </div>
                <div className="grid-item3 item11 all-emp-wrapper rounded-xl shadow-sm ">
                    <div className="xl-title font-fira text-blueish p-5 mt-3">Delayed Tasks</div>
                    <DefaultBarChart
                        xValues={xValuesTwo}
                        stats={[
                            {
                                data: statDataTwo
                            }
                        ]}
                        height={280}
                        Yaxes={true}
                        barWidth="30%"
                        borderRadius="10"
                        horizontal={true}
                    />
                </div>
            </div>
        </div>
    );
};
