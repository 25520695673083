import React from 'react';

export const ChipArrow = ({ fill }) => {
    return (
        <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7577 0.214928C13.0808 0.501498 13.0808 0.96612 12.7577 1.25269L7.08491 6.28507C6.76188 6.57164 6.23812 6.57164 5.91509 6.28507L0.24228 1.25269C-0.08076 0.96612 -0.08076 0.501498 0.24228 0.214927C0.565319 -0.0716431 1.08907 -0.0716431 1.41211 0.214927L6.5 4.72843L11.5879 0.214928C11.9109 -0.0716426 12.4347 -0.0716426 12.7577 0.214928Z"
                fill={fill ?? '#008C51'}
            />
        </svg>
    );
};
