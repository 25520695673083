import React from 'react';

export const AtoStatusIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="url(#paint0_linear_12329_238)" />
            <path
                d="M15.7246 10.2246C12.4203 10.5941 10.7574 12.1625 10.2633 15.3809C10.1859 15.8578 10.1816 16.2188 10.1816 20C10.1816 23.734 10.1902 24.1465 10.259 24.5977C10.7488 27.7516 12.2742 29.2727 15.4238 29.741C15.8922 29.8098 16.3176 29.8184 20.0215 29.8184C24.4859 29.8184 24.4688 29.8184 25.5387 29.5563C27.8117 28.9934 29.1352 27.5969 29.6207 25.2422C29.8313 24.2367 29.8441 23.85 29.827 19.6863C29.8098 15.4367 29.8141 15.5184 29.5391 14.4055C29.0105 12.2785 27.6871 10.968 25.543 10.4438C24.473 10.1859 24.5461 10.1859 20.2148 10.1773C17.1168 10.1687 16.1242 10.1816 15.7246 10.2246ZM24.5762 11.634C26.7203 12.0164 27.7688 12.923 28.2285 14.7922C28.4391 15.6301 28.4434 15.7676 28.4434 20.043C28.4434 24.3699 28.4434 24.3914 28.2113 25.2937C27.8074 26.8664 26.8664 27.8074 25.2938 28.2113C24.3914 28.4434 24.3699 28.4434 20.043 28.4434C15.7676 28.4434 15.6301 28.4391 14.7922 28.2285C12.8758 27.7559 11.9133 26.5871 11.6125 24.3871C11.5309 23.7898 11.5309 16.2102 11.6125 15.6129C11.9133 13.4129 12.8758 12.2441 14.7922 11.7715C15.0156 11.7156 15.3723 11.6469 15.5914 11.6254C15.8063 11.5996 16.0426 11.5695 16.1113 11.5609C16.1844 11.5523 18.0277 11.548 20.2148 11.5566C23.6438 11.5652 24.241 11.5738 24.5762 11.634Z"
                fill="white"
            />
            <path
                d="M23.9198 17.1299C23.8467 17.1858 23.2881 17.8647 22.6866 18.6467C22.0807 19.4245 21.5522 20.0819 21.5049 20.1034C21.4018 20.1507 21.419 20.1635 20.3663 19.3342C19.3092 18.5007 19.1803 18.4319 18.626 18.4319C18.2522 18.4319 18.192 18.4448 17.9385 18.5694C17.7838 18.6467 17.5819 18.7885 17.483 18.8917C17.2038 19.1839 15.1413 21.8952 15.0725 22.0542C14.8405 22.6128 15.5538 23.2057 16.0479 22.8663C16.1295 22.8147 16.7053 22.1057 17.3327 21.2893C18.351 19.9702 18.4928 19.8069 18.6045 19.7983C18.712 19.7897 18.8795 19.9014 19.6358 20.4987C20.6971 21.3409 20.8647 21.4311 21.376 21.4526C21.7928 21.4698 22.0893 21.3839 22.3987 21.1561C22.5061 21.0745 23.0389 20.4299 23.7393 19.5276C25.0541 17.8303 25.0713 17.796 24.8952 17.4393C24.7577 17.16 24.5686 17.0354 24.2807 17.0354C24.1217 17.0354 24.0229 17.0612 23.9198 17.1299Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_12329_238"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                </linearGradient>
            </defs>
        </svg>
    );
};
