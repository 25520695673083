import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../dashboard-css/auth-css/authSuccess.css';
import { MobileIconStarIcon } from '../../../utills/svgs/MobileIconStarIcon';
import Ripples from 'react-ripples';
import AuthCardLayout from 'layouts/AuthCardLayout';
const AuthSuccessPage = () => {
    const navigate = useNavigate();
    return (
        <div className="flex min-h-[100vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <AuthCardLayout>
                {' '}
                <div className="">
                    <div className="email-success-inner">
                        <div className="envelope">
                            <div className="envelope-wrapper">
                                <MobileIconStarIcon />
                            </div>
                        </div>

                        <div className="congrats mt-5">
                            <h1 className="text-center">Congratulation!</h1>

                            <p className="text-center mt-3">Your Google 2FA has been enabled successfully.</p>
                        </div>

                        <div className=" mt-7 mb-12">
                            <center>
                                <Ripples during={2000} color="#979797">
                                    <button onClick={() => navigate('/backup/codes')} className="px-12 py-2 btn-2">
                                        Proceed
                                    </button>
                                </Ripples>
                            </center>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};

export default AuthSuccessPage;
