/* eslint-disable no-unused-vars */
import { endpoint, methods } from 'apiEndpoints';
import { toastHandler } from '../responseHanlder';
import instance from './_instance';
import {
    ACCESS_TOKEN,
    APP_ENVIRONMENT,
    BASE_URL,
    BUSINESS_ID,
    CSRF_TOKEN,
    REDIRECT_URI,
    REFRESH_TOKEN,
    TOAST_TYPE_ERROR
} from 'utills/globalVars';
import { clearAllCookies } from 'utills/sortAndFilter';
import { getCookie, setCookie } from 'utills/encryption/cryptoUtils';

const url = BASE_URL;

const handleClearAndNavigate = async () => {
    try {
        let csrfToken = getCookie(CSRF_TOKEN);
        if (!csrfToken) {
            throw Error('');
        }
        // Get the current URL
        const _res = await instance({
            method: methods.DELETE,
            url: endpoint.logout,
            withCredentials: true,
            headers: {
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            }
        });
    } catch (e) {
        console.error('Error logging out');
        // toastHandler('Error logging out', TOAST_TYPE_ERROR);
    }
    clearStorageAndNavigate();
};

const clearStorageAndNavigate = () => {
    const currentUrl = window.location.pathname + window.location.search;
    clearAllCookies();
    localStorage.clear();
    sessionStorage.clear();
    // await getCSRFToken();
    sessionStorage.setItem(REDIRECT_URI, currentUrl);
    window.location.href = '/';
};

export const getCSRFToken = async () => {
    const response = await instance({
        method: 'GET',
        url: endpoint.getCSRF,
        withCredentials: true,
        headers: {
            app_environment: APP_ENVIRONMENT
        }
    });
    const token = response?.data?.data?.csrfToken;
    if (token) {
        setCookie(CSRF_TOKEN, token);
        return token;
    }
    return null;
};

export const makeHttpRequest = async (method, endpointParams, data, signal = null, urlParams = null) => {
    let csrfToken = getCookie(CSRF_TOKEN);

    let url = endpointParams;

    if (urlParams) {
        if (typeof urlParams === 'object') {
            url = `${url}?${new URLSearchParams(urlParams).toString()}`;
        } else {
            url = url + '/' + urlParams;
        }
    } else if (data && ['get', 'delete'].includes(method)) {
        // convets single data object into query params
        url = `${url}?${new URLSearchParams(data).toString()}`;
    }

    try {
        // if (!csrfToken) {
        //     csrfToken = await getCSRFToken();
        // }

        let config = {
            method: method,
            url: url,
            data: data,
            withCredentials: true,
            headers: {
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            signal: signal
        };

        if (['get', 'delete'].includes(method)) {
            delete config?.data;
        }

        const response = await instance(config);
        //development
        if (response.headers['content-type'].includes('/html')) {
            toastHandler('Something went wrong', 'error');
            return;
        }

        if (response?.data?.data?.message) {
            toastHandler(response.data.data.message, 'success');
        }

        return response.data.data;
    } catch (error) {
        if (error?.response?.data.captcha_required) {
            toastHandler(error?.response?.data?.message, 'error');
            return error?.response?.data;
        }

        if (error?.response?.status === 404) {
            window.location.href = '/Error404';
        }
        if (error?.response?.status === 400) {
            toastHandler(error.response?.data?.errors, 'error');
        }

        if (error?.response?.status === 401) {
            return handleClearAndNavigate();
        }

        if (error && !error.response && error.message !== 'canceled') {
            toastHandler(error.message, 'error');
        }

        Array.isArray(error.response?.data?.errors) &&
            error.response?.data?.errors?.map((error) => {
                if (error) {
                    if (error === 'Invalid csrf token!') {
                        return clearStorageAndNavigate();
                    }
                    toastHandler(error, 'error');
                }
                return null;
            });

        return null;
    }
};
