/* eslint-disable react-hooks/exhaustive-deps */
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    PencilIcon,
    PlusCircleIcon,
    XMarkIcon
} from '@heroicons/react/20/solid';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { BgChip } from 'components/atoms/bgChip/BgChip';
import { Button } from 'components/atoms/buttons/Button';
import { Chip } from 'components/atoms/buttons/Chip';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import { FileDropZone } from 'components/atoms/DropFilesZone/FileDropZone';
import EditableText from 'components/atoms/EditableText/EditableText';
import { Loader } from 'components/common/Loader';
import Activity from 'components/job-manager-components/job-manager-detail-components/Activity/Activity';
import { JobDetail } from 'components/job-manager-components/job-manager-detail-components/jobDetail/JobDetail';
import { MilestonesAndTasks } from 'components/job-manager-components/job-manager-detail-components/MilestonesAndTasks/MilestonesAndTasks';
import { WorkStation } from 'components/job-manager-components/job-manager-detail-components/work-station/WorkStation';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { Jumbotron } from 'components/organisms/Jumbotron';
import TaskDetails from 'components/workplace-content/tasks-pages/task-details/TaskDetails';
import dayjs from 'dayjs';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { usePagination } from 'Hooks/paginationHook/usePagination';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { searchClientsThunk, viewBusinessCustomerInfoThunk } from 'store/client';
import { handleTaskDetails, setLoader } from 'store/global/globalReducer';

import useRoleGuard from 'Hooks/useRoleGuard';
import {
    addJobClientThunk,
    addJobFileThunk,
    addManagerToJobThunk,
    deleteJobFileThunk,
    deleteJobsThunk,
    getAllJobTypesThunk,
    getAllMilestonesThunk,
    getJobActionsThunk,
    getJobAssigneesThunk,
    getJobTasksThunk,
    getSingleJob,
    getSingleJobThunk,
    removeDraftThunk,
    removeJobClientThunk,
    removeJobManagerThunk,
    updateJobKeyValueThunk
} from 'store/JobManager';
import ConfirmationModal from 'utills/confirmationModal';
import { downloadFile } from 'utills/filesHandlers';
import {
    all_files_types,
    BUSINESS_ID,
    getProfileName,
    JOB_ACTIVITY_CHAT,
    RELAY_ROOM,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    USER_TYPE
} from 'utills/globalVars';
import { convertDbDate, customDateFormat, DateFormatToLocal } from 'utills/moment';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgJobIcon } from 'utills/svgs/BgJobIcon';
import { CalenderBgChipIcon } from 'utills/svgs/CalenderBgChipIcon';
import { ChipArrow } from 'utills/svgs/ChipArrow';

import { UserChip } from 'components/atoms/UserChip/UserChip';
import { FileIconSvg } from 'utills/svgs/FileIconSvg';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import ComboboxComponent from 'components/atoms/Combobox';
import SubmitFormIcon from 'utills/svgs/SubmitFormIcon';
import RelayRoom from 'components/job-manager-components/job-manager-detail-components/RelayRoom/RelayRoom';
import { cn } from 'utills/tailwindUtil';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { getAllDraftThunk, setSelectedDraft } from 'store/custom-reducers/RelayRoomDraftReducer';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { AddClient } from 'components/appusers-dash/subcomponents/AddClient';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { getTotalThreadsCountThunk } from 'store/custom-reducers/RelayRoomLeftBarReducer';
import JobPortalDocuments from 'components/job-manager-components/job-manager-detail-components/work-station/Documents/JobPortalDocuments';
import MoveOrCopy from 'components/job-manager-components/job-manager-detail-components/work-station/Documents/MoveOrCopy';
import { JOB_CLOSED } from '../jobs.constants';
import { getAllJobsStatusesThunk } from 'store/workspace/workspaceTasks';
export const JobDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hasPermissions } = useRoleGuard();
    const [activeTab, setActiveTab] = useState('jobDetail');
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');
    const { isLoading } = useSelector((state) => state.global);
    const { data: jobTypes } = useSelector((state) => state.getAllJobTypes);
    const { data: assignees } = useSelector((state) => state.getJobAssignees);
    const { data: jobTasks } = useSelector((state) => state.getJobTasks);
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);
    const { data: jobStatuses } = useSelector((state) => state.getAllJobsStatuses);
    const [jobCloseModal, setJobCloseModal] = useState(false);
    const [CutORCopy, setCutORCopy] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const fileInputRef2 = useRef(null); // Reference to hidden file input

    const { data: getJobActions } = useSelector((state) => state.getJobActions);
    const getActivityCount = getJobActions?.filter((obj) => ![RELAY_ROOM, JOB_ACTIVITY_CHAT].includes(obj?.type)) || [];

    const relayRoomActivity = getJobActions?.filter((obj) => obj.type === RELAY_ROOM);
    const jobChatActivity = getJobActions?.filter((obj) => obj.type === JOB_ACTIVITY_CHAT);

    const [priorityOptions, _setPriorityOptions] = useState([
        { label: 'High' },
        { label: 'Highest' },
        { label: 'Normal' },
        { label: 'Low' },
        { label: 'Lowest' }
    ]);

    // const [jobStatuses, _setJobStatuses] = useState([
    //     { label: 'To-do' },
    //     { label: 'In Progress' },
    //     { label: 'On Hold' },
    //     { label: 'Completed' },
    //     { label: 'Closed' },
    //     { label: 'Cancelled' }
    // ]);

    const [jobType, setJobType] = useState('Normal');
    const [dueDate, setDueDate] = useState('12/02/2023');
    const [startDate, setStartDate] = useState('12/12/2015');

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [title, setTitle] = useState('');
    const [status, setStatus] = useState('');
    const [priority, setPriority] = useState('Normal');
    const [description, setDescription] = useState('');
    const [addClient, setAddClient] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);
    const [addManager, setAddManager] = useState(false);
    const [selectedManagers, setSelectedManagers] = useState([]);

    const [expandDraft, setExpandDraft] = useState(false);

    const { data: job } = useSelector((state) => state.getSingleJob);
    const { id: job_id } = useParams();

    const [showTaskDetails, setShowTaskDetails] = useState(false);

    const { handlePageChange, pageData, hasMoreContent } = usePagination({
        thunk: getAllMilestonesThunk,
        dataKey: 'rows',
        extraPayload: {
            business_id,
            job_id
        }
    });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paramsValue = searchParams.get('activeTab');

    useEffect(() => {
        if (paramsValue) {
            setActiveTab(paramsValue === 'tools' ? 'workStation' : paramsValue);
        } else {
            setActiveTab('jobDetail');
        }
    }, [paramsValue]);

    useEffect(() => {
        if (!jobStatuses) {
            fetchJobStatuses();
        }
    }, []);

    useEffect(() => {
        if (hasPermissions('Job', ['read', 'write', 'admin'])) {
            fetchJobTypes();
        }
    }, []);

    const fetchJobStatuses = () => {
        dispatch(setLoader(true));
        dispatch(getAllJobsStatusesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const fetchJobTypes = () => {
        const payload = {
            business_id: business_id
        };
        dispatch(getAllJobTypesThunk(payload));
    };

    const handleGetSingleJob = () => {
        dispatch(setLoader(true));
        dispatch(getSingleJobThunk({ job_id }))
            .then((response) => {
                if (response.payload) {
                    const job = response.payload;
                    setTitle(job?.title);
                    setDescription(job?.description || '');

                    setJobType(job?.jobType?.label);
                    setStartDate(job?.start_date || '');
                    setDueDate(job?.due_date || '');
                    setPriority(job?.priority || 'Normal');
                    setStatus(job?.status);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
                handleGetJobActivity();
            });
    };

    const handleGetJobActivity = () => {
        dispatch(getJobActionsThunk({ job_id, business_id }));
    };

    const handleSelectClient = (client) => {
        const isClientAlreadySelected = selectedClients?.some(
            (item) => item?.business_client_id === client?.business_client_id
        );

        const isClientAlreadyadded = job?.clients?.some(
            (item) => item?.business_client_id === client?.business_client_id
        );

        if (isClientAlreadySelected || isClientAlreadyadded) {
            return toastHandler('Client already selected', TOAST_TYPE_ERROR);
        }

        const nonIndividuals = [...selectedClients, ...job.clients]?.filter(
            (item) => item?.client_category.toLowerCase() !== 'individual'
        );

        if (nonIndividuals?.length > 0 && client?.client_category !== 'Individual') {
            return toastHandler('Only one non Individual client can be selected', TOAST_TYPE_ERROR);
        }

        setSelectedClients([...selectedClients, client]);
    };
    const handleSelectManager = (obj) => {
        const findManager = selectedManagers?.some((item) => item?.user_id === obj?.user_id);
        const selectedManagerFound = job?.manager?.some((item) => item?.user_id === obj?.user_id);
        if (findManager || selectedManagerFound) {
            return toastHandler('This Manager is already selected', TOAST_TYPE_ERROR);
        }
        setSelectedManagers([...selectedManagers, obj]);
    };
    const handleUpdateValues = (key, value, setState, isDropdown) => {
        if (!value || value?.trim()?.length < 1) {
            return toastHandler(`${isDropdown ? 'Please select a job type' : 'Value is required'}  `, TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(updateJobKeyValueThunk({ business_id, job_id, key, value }))
            .then((response) => {
                if (response.payload) {
                    const data = response.payload;

                    dispatch(
                        getSingleJob.actions.handleUpdate({
                            ...job,
                            status: data?.status
                        })
                    );
                    if (isDropdown) {
                        handleGetSingleJob();
                    } else {
                        setState(data[key]);
                    }

                    toastHandler('Job updated successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleGetAssignees = () => {
        dispatch(setLoader(true));
        dispatch(getJobAssigneesThunk({ job_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };
    const handleGetJobTasks = () => {
        dispatch(setLoader(true));
        dispatch(getJobTasksThunk({ job_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        if (hasPermissions('Job', ['read', 'write', 'admin'])) {
            handleGetSingleJob();
            handleGetAssignees();
            handleGetJobTasks();
        }
    }, [job_id]);

    const handleDetails = (findTask) => {
        dispatch(handleTaskDetails(findTask));

        setShowTaskDetails(!showTaskDetails);
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);

        // console.log(files, 'acceptedFiles_____acceptedFiles');
        if (files.length > 0) {
            const validFiles = files.filter((file) => {
                return [...all_files_types, 'msg', 'vsd'].includes(
                    file?.type === '' ? file.name.split('.').pop().toLowerCase() : file?.type
                );
            });
            if (validFiles.length === files.length) {
                handleAddJobFiles(validFiles);
            } else {
                toastHandler('Some files have an invalid file type.', TOAST_TYPE_ERROR);
            }
        }
    };

    const handleAddJobFiles = (files) => {
        const formData = new FormData();
        formData.append('uploaded_by', USER_TYPE);
        formData.append('business_id', business_id);
        formData.append('job_id', job_id);

        files?.forEach((file) => {
            formData.append('job_files', file);
        });

        dispatch(setLoader(true));
        dispatch(addJobFileThunk(formData))
            .then((response) => {
                if (response.payload) {
                    handleGetSingleJob();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleDeleteFile = (job_attachment_id) => {
        dispatch(setLoader(true));
        dispatch(deleteJobFileThunk({ job_id, job_attachment_id: job_attachment_id }))
            .then((response) => {
                if (response.payload) {
                    handleGetSingleJob();
                }
            })
            .catch((_error) => {
                console.error('error');
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const deleteCient = (business_client_id) => {
        dispatch(setLoader(true));
        dispatch(removeJobClientThunk({ job_id, business_client_id: business_client_id, business_id }))
            .then((response) => {
                if (response.payload) {
                    handleGetSingleJob();
                }
            })
            .catch((_error) => {
                console.error('error');
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleAddClients = () => {
        setIsConfirmationOpen(false);

        dispatch(setLoader(true));
        dispatch(
            addJobClientThunk({
                job_id,
                business_id,
                business_client_id: selectedClients?.map((client) => client?.business_client_id)
            })
        )
            .then((response) => {
                if (response.payload) {
                    handleGetSingleJob();
                    setAddClient(false);
                    setSelectedClients([]);
                }
            })
            .catch((_error) => {
                console.error('error');
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleClientRemove = (index) => {
        const newClients = [...selectedClients];
        newClients.splice(index, 1);
        setSelectedClients(newClients);
    };

    const handleDelete = () => {
        dispatch(setLoader(true));
        setIsConfirmationOpen(false);
        dispatch(deleteJobsThunk({ ids: [job_id], business_id }))
            .then((response) => {
                if (response.payload) {
                    navigate('/job-manager/jobs');
                }
            })
            .catch((_error) => {
                console.error('error');
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleManagerRemove = (index) => {
        const newClients = [...selectedManagers];
        newClients.splice(index, 1);
        setSelectedManagers(newClients);
    };
    const deleteManager = (manager_id) => {
        dispatch(setLoader(true));
        dispatch(removeJobManagerThunk({ job_id, employee_id: manager_id, business_id }))
            ?.then((res) => {
                if (res.payload) {
                    handleGetSingleJob();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleAddManagers = () => {
        dispatch(setLoader(true));
        dispatch(
            addManagerToJobThunk({
                job_id,
                business_id,
                employee_id: selectedManagers?.map((item) => item?.user_id)
            })
        )
            .then((response) => {
                if (response.payload) {
                    setAddManager(false);
                    setSelectedManagers([]);
                    handleGetSingleJob();
                    toastHandler('Job managers updated successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (hasPermissions('Job', ['read', 'write', 'admin'])) {
            dispatch(fetchRegisteredEmployeesThunk()).catch((_error) => {
                console.error('error');
            });
        }
    }, []);

    const handleTabClick = (value) => {
        getTotoalCountofInbox();
        searchParams.set('activeTab', value);
        navigate({
            pathname: location.pathname,
            search: searchParams.toString()
        });
    };

    const badgesArray = [
        {
            name: 'tfn',
            sx: 'bg-green-100 text-green-700'
        },

        {
            name: 'abn',
            sx: 'bg-blue-100 text-blue-700'
        },
        {
            name: 'acn',
            sx: 'bg-yellow-100  text-yellow-800'
        },
        {
            name: 'phone_number',
            sx: 'bg-gray-100 text-gray-600'
        },
        {
            name: 'gender',
            sx: 'bg-red-100 text-red-700'
        },
        {
            name: 'date_of_birth',
            sx: 'bg-pink-100 text-pink-700'
        },
        {
            name: 'residential_address',
            sx: 'bg-gray-100 text-gray-600'
        }
    ];

    const getValue = localStorage.getItem('expandJobs');
    const [expandRelayRoom, setExpandRelayRoom] = useState(getValue || false);

    const setExpandRelayRoomFun = (value) => {
        setExpandRelayRoom(value);
        localStorage.setItem('expandJobs', value);
    };

    const { data, currentPage, itemsPerPage } = useSelector((state) => state.draftListReducer);
    const [draftList, setDraftList] = useState(data ? data : []);

    useEffect(() => {
        if (data) {
            setDraftList(data);
        } else {
            fetchDraftList();
        }
    }, []);

    const fetchDraftList = async () => {
        const jsonData = {
            business_id,
            job_id: job_id,
            page: currentPage,
            page_size: itemsPerPage,
            email_type: 'inbox'
        };

        await dispatch(getAllDraftThunk(jsonData));
    };

    const handleSelectDraft = async (obj) => {
        await dispatch(setSelectedDraft(obj));
        setExpandDraft(!expandDraft);
    };
    const handleRemovvDraft = (id) => {
        if (id) {
            dispatch(setLoader(true));
            dispatch(removeDraftThunk({ ids: [id] }))
                .then((res) => {
                    if (res.payload) {
                        fetchDraftList();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const [isEditingClient, setIsEditingClient] = useState(null);
    const handleGetAndEdit = (business_client_id) => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setIsEditingClient(response.payload);
                }
            })

            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        getTotoalCountofInbox();
    }, []);

    const getTotoalCountofInbox = () => {
        const payload = {
            job_id: id,
            business_id
        };
        dispatch(getTotalThreadsCountThunk(payload));
    };

    const changeJobStatus = (label) => {
        if (label === 'Delete') {
            handleDelete();
        } else {
            const status_id = jobStatuses?.find((obj) => obj?.label === label)?.job_status_id ?? '';
            handleUpdateValues('status', status_id, setStatus);
        }
        setJobCloseModal(false);
        setCutORCopy(false);
        setSelectedIds([]);
    };

    let disabled = status?.label === JOB_CLOSED;

    return (
        <SidebarLayout>
            <RoleBasedGuard module={'Job'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                <Jumbotron
                    title="Job Details"
                    path={[
                        {
                            link: 'job-manager',
                            title: 'Job Manager'
                        },
                        {
                            link: 'job-manager/jobs',
                            title: 'Jobs'
                        },
                        {
                            link: '',
                            title: 'Job Details'
                        }
                    ]}
                    actionsRequired
                >
                    <div className="flex gap-2 items-center">
                        <Button
                            title="Close Job"
                            classes={'bg-thirdbg py-2 text-white !text-sm font-[400] px-5 rounded-lg'}
                            atClick={() => {
                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                    return toastHandler(
                                        'You dont have permission to do this action.',
                                        TOAST_TYPE_ERROR
                                    );
                                }
                                setJobCloseModal('Closed');
                                // handleUpdateValues('status', 'Closed', setStatus);
                            }}
                            disabled={disabled}
                        />
                        <Button
                            title="Delete Job"
                            classes={'bg-[#DCDCDC] py-2  !text-sm font-[400] px-5 rounded-lg'}
                            atClick={() => {
                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                    return toastHandler(
                                        'You dont have permission to do this action.',
                                        TOAST_TYPE_ERROR
                                    );
                                }

                                setIsConfirmationOpen(true);
                            }}
                        />
                    </div>
                </Jumbotron>
                {isLoading && <Loader />}

                <div>
                    {isEditingClient && (
                        <AddClient
                            open={isEditingClient ? true : false}
                            setOpen={() => {
                                setIsEditingClient(null);
                            }}
                            client={isEditingClient}
                            // handleReload={() =>  window.location.reload({ forceReload: true })}
                            handleReload={() => {
                                handleGetSingleJob();
                                setIsEditingClient(null);
                            }}
                        />
                    )}
                    {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
                    {selectedPdf ? (
                        <GlobalPDFViewer
                            url={selectedPdf}
                            setOpen={() => setSelectedPdf('')}
                            open={selectedPdf ? true : false}
                        />
                    ) : (
                        ''
                    )}{' '}
                    {showTaskDetails && (
                        <TaskDetails
                            open={showTaskDetails}
                            setOpen={setShowTaskDetails}
                            toggleTaskDetails={() => setShowTaskDetails(!showTaskDetails)}
                            handleTasksUpdate={() => console.info(1)}
                        />
                    )}
                    <ConfirmationModal
                        isOpen={isConfirmationOpen}
                        onClose={() => setIsConfirmationOpen(false)}
                        description={"Do you want to move Job Documents to Client's Folder?"}
                        onConfirm={handleDelete}
                        sx="!min-w-[530px] rounded-xl !px-8 !py-5"
                        customButtons={
                            <div className="flex gap-2 items-center">
                                <Button
                                    title="Close and Delete"
                                    classes={
                                        'border-blueish text-blueish border-2 h-[3rem] rounded-xl !font-medium font-fira !px-6'
                                    }
                                    atClick={() => {
                                        handleDelete();
                                        setIsConfirmationOpen(false);
                                    }}
                                />
                                <Button
                                    atClick={() => {
                                        setIsConfirmationOpen(false);
                                        setCutORCopy('Delete');
                                    }}
                                    title={"Move to Client's Folder"}
                                    classes={cn('rounded-xl !font-medium font-fira !text-white ann-btn !px-6')}
                                />
                            </div>
                        }
                    />
                    <div className="relative">
                        <div className=" mx-5 md:mx-10 pb-16">
                            {/* Main Grid */}
                            <div className="grid grid-cols-1 lg:grid-cols-7 lg:gap-4">
                                {/* Left Section - Main Content */}
                                <div className={cn('space-y-4', expandRelayRoom ? 'md:col-span-7' : 'md:col-span-5')}>
                                    {/* Header Section */}

                                    <div className="bg-white    rounded-xl  p-4">
                                        <div className=" gap-5 grid md:grid-cols-2  w-full ">
                                            <div className="text-xl text-[#0A1E46] font-poppins font-[500] w-full">
                                                <EditableText
                                                    text={title}
                                                    onUpdate={(text) => handleUpdateValues('title', text, setTitle)}
                                                    isTextArea={false}
                                                    componentName="text"
                                                    permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <div className="">
                                                <div className="flex gap-2  items-center">
                                                    <div className="flex w-full  gap-2 items-center flex-wrap justify-end">
                                                        <DropdownMenu
                                                            disabled={disabled}
                                                            className="!left-0"
                                                            icon={
                                                                <div
                                                                    className={`flex gap-1 text-sm px-3 justify-between w-[100px] items-center p-1 rounded-xl ${
                                                                        priority === 'Low'
                                                                            ? 'bg-[#008C51]/10 text-[#008C51]'
                                                                            : priority === 'Normal'
                                                                            ? 'bg-[#b695f8]/10  text-[#b695f8]'
                                                                            : priority === 'Lowest'
                                                                            ? 'bg-[#666666]/10  text-[#666666]'
                                                                            : priority === 'Highest'
                                                                            ? 'bg-[#F14747]/10  text-[#F14747]'
                                                                            : priority === 'High'
                                                                            ? 'bg-[#0d1b37]/10  text-[#0d1b37]'
                                                                            : 'bg-[#F08201]/10  text-[#F08201]'
                                                                    }`}
                                                                >
                                                                    {priority}
                                                                    <ChipArrow
                                                                        fill={
                                                                            priority === 'Low'
                                                                                ? '#008C51'
                                                                                : priority === 'Normal'
                                                                                ? '#b695f8'
                                                                                : priority === 'Lowest'
                                                                                ? '#666666'
                                                                                : priority === 'Highest'
                                                                                ? '#F14747'
                                                                                : priority === 'High'
                                                                                ? '#0d1b37'
                                                                                : '#F08201'
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        >
                                                            {priorityOptions?.map((item, index) => (
                                                                <DropdownMenuItem
                                                                    key={'menu' + index}
                                                                    onClick={() => {
                                                                        if (
                                                                            !hasPermissions('Job', ['write', 'admin'])
                                                                        ) {
                                                                            return toastHandler(
                                                                                'You dont have permission to do this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }

                                                                        handleUpdateValues(
                                                                            'priority',
                                                                            item?.label,
                                                                            setPriority
                                                                        );
                                                                    }}
                                                                >
                                                                    <div className="flex items-center gap-2  cursor-pointer">
                                                                        {item?.label}
                                                                    </div>
                                                                </DropdownMenuItem>
                                                            ))}
                                                        </DropdownMenu>

                                                        <DropdownMenu
                                                            className="!left-0"
                                                            icon={
                                                                <div
                                                                    className={`flex gap-1 text-sm px-3 justify-between w-[120px] items-center p-1 overflow-hidden rounded-xl ${
                                                                        status?.label === 'Completed'
                                                                            ? 'bg-[#008C51]/10 text-[#008C51]'
                                                                            : status?.label === 'In Progress'
                                                                            ? 'bg-[#b695f8]/10  text-[#b695f8]'
                                                                            : status?.label === 'On Hold'
                                                                            ? 'bg-[#666666]/10  text-[#666666]'
                                                                            : status?.label === 'Closed'
                                                                            ? 'bg-[#F14747]/10  text-[#F14747]'
                                                                            : status?.label === 'Cancelled'
                                                                            ? 'bg-[#0d1b37]/10  text-[#0d1b37]'
                                                                            : 'bg-[#F08201]/10  text-[#F08201]'
                                                                    }`}
                                                                >
                                                                    <span className="truncate"> {status?.label}</span>
                                                                    <ChipArrow
                                                                        fill={
                                                                            status?.label === 'Completed'
                                                                                ? '#008C51'
                                                                                : status?.label === 'In Progress'
                                                                                ? '#b695f8'
                                                                                : status?.label === 'On Hold'
                                                                                ? '#666666'
                                                                                : status?.label === 'Closed'
                                                                                ? '#F14747'
                                                                                : status?.label === 'Cancelled'
                                                                                ? '#0d1b37'
                                                                                : '#F08201'
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        >
                                                            {jobStatuses &&
                                                                jobStatuses?.map((item, index) => (
                                                                    <DropdownMenuItem
                                                                        key={'menu2' + index}
                                                                        onClick={() => {
                                                                            if (
                                                                                !hasPermissions('Job', [
                                                                                    'write',
                                                                                    'admin'
                                                                                ])
                                                                            ) {
                                                                                return toastHandler(
                                                                                    'You dont have permission to do this action.',
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }
                                                                            if (item?.label !== 'Closed') {
                                                                                handleUpdateValues(
                                                                                    'status',
                                                                                    item?.job_status_id,
                                                                                    setStatus
                                                                                );
                                                                            } else {
                                                                                setJobCloseModal(item?.label);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="flex items-center gap-2  cursor-pointer">
                                                                            {item?.label}
                                                                        </div>
                                                                    </DropdownMenuItem>
                                                                ))}
                                                        </DropdownMenu>

                                                        <Chip
                                                            title={`Job Ref Num: ${job?.ref_no}`}
                                                            classes={
                                                                'bg-[#b695f8]/10 text-[#b695f8] flex gap-2 items-center'
                                                            }
                                                        />

                                                        {expandRelayRoom ? (
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    setExpandRelayRoomFun(false);
                                                                }}
                                                            >
                                                                <CollapsIcon className={'w-6 h-6'} />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    setExpandRelayRoomFun(true);
                                                                }}
                                                            >
                                                                <ExpandIcon className={'w-6 h-6'} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-5 flex gap-5   items-center  flex-wrap">
                                            <BgChip
                                                title="Created Date"
                                                text={convertDbDate(job?.created_at)}
                                                iconSet={{ icon: CalenderBgChipIcon, fill: '#657CEE' }}
                                                classes={'bg-[#EEF0F8] w-[240px]'}
                                            />

                                            <EditableText
                                                text={startDate ? startDate : dayjs()}
                                                onUpdate={(text) =>
                                                    handleUpdateValues(
                                                        'start_date',
                                                        dayjs(text).format('DD-MM-YYYY'),
                                                        setStartDate
                                                    )
                                                }
                                                componentName="date"
                                                maxDate={dueDate}
                                                sx="w-fit"
                                                permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                                                disabled={disabled}
                                            >
                                                <BgChip
                                                    title="Start Date"
                                                    text={startDate || '--'}
                                                    iconSet={{ icon: CalenderBgChipIcon, fill: '#E6AA69' }}
                                                    classes={'bg-[#F8F0E7] min-w-[240px]'}
                                                />
                                            </EditableText>

                                            <EditableText
                                                text={dueDate ? dueDate : dayjs().add(2, 'day')}
                                                disabled={disabled}
                                                onUpdate={(text) =>
                                                    handleUpdateValues(
                                                        'due_date',
                                                        dayjs(text).format('DD-MM-YYYY'),
                                                        setDueDate
                                                    )
                                                }
                                                minDate={startDate}
                                                componentName="date"
                                                sx="w-fit"
                                                permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                                            >
                                                <BgChip
                                                    title="Due Date"
                                                    text={dueDate || '--'}
                                                    iconSet={{ icon: CalenderBgChipIcon, fill: '#DC7B7B' }}
                                                    classes={'bg-[#F9E8E8] min-w-[240px]'}
                                                />
                                            </EditableText>

                                            <EditableText
                                                disabled={disabled}
                                                text={jobType}
                                                onUpdate={(text) => {
                                                    text &&
                                                        text?.job_type_id !== undefined &&
                                                        handleUpdateValues(
                                                            'job_type_id',
                                                            text?.job_type_id,
                                                            setJobType,
                                                            true
                                                        );
                                                }}
                                                componentName="dropdown"
                                                options={jobTypes}
                                                sx="w-fit"
                                                getObject={true}
                                                permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                                            >
                                                <BgChip
                                                    title="Job Type"
                                                    text={jobType}
                                                    iconSet={{ icon: BgJobIcon, fill: '#DC7B7B' }}
                                                    classes={'bg-[#E6F2E2] min-w-[240px]'}
                                                />
                                            </EditableText>
                                        </div>
                                    </div>

                                    {/* Tabs Section */}
                                    <div className="bg-white rounded-xl shadow-md px-4">
                                        {/* Tab controls */}
                                        <div className="flex flex-wrap gap-4">
                                            <button
                                                className={`p-2 py-5 hover:text-[#0A1E46] ${
                                                    activeTab === 'jobDetail'
                                                        ? 'border-b-2 border-thirdbg text-[#0A1E46] font-[500]'
                                                        : 'text-secondarybg'
                                                }`}
                                                onClick={() => handleTabClick('jobDetail')}
                                            >
                                                Job Details
                                            </button>
                                            <button
                                                className={`p-2  hover:text-[#0A1E46] ${
                                                    activeTab === 'milestones'
                                                        ? 'border-b-2 border-thirdbg text-[#0A1E46] font-[500]'
                                                        : 'text-secondarybg'
                                                }`}
                                                onClick={() => handleTabClick('milestones')}
                                            >
                                                Milestones & Tasks
                                            </button>
                                            <button
                                                className={`p-2  hover:text-[#0A1E46] ${
                                                    activeTab === 'documents'
                                                        ? 'border-b-2 border-thirdbg text-[#0A1E46] font-[500]'
                                                        : 'text-secondarybg'
                                                }`}
                                                onClick={() => handleTabClick('documents')}
                                            >
                                                Documents
                                            </button>
                                            <button
                                                className={`p-2  hover:text-[#0A1E46] relative ${
                                                    activeTab === 'workStation'
                                                        ? 'border-b-2 border-thirdbg text-[#0A1E46] font-[500]'
                                                        : 'text-secondarybg'
                                                }`}
                                                onClick={() => handleTabClick('workStation')}
                                            >
                                                Workstation
                                                {getActivityCount?.length > 0 && (
                                                    <span className="absolute top-1 -right-1 bg-third rounded-full  w-auto p-1 min-w-4 flex items-center justify-center h-4 text-white font-medium text-xs">
                                                        {getActivityCount?.length}
                                                    </span>
                                                )}
                                            </button>
                                            <button
                                                className={`p-2  hover:text-[#0A1E46] relative ${
                                                    activeTab === 'activity'
                                                        ? 'border-b-2 border-thirdbg text-[#0A1E46] font-[500]'
                                                        : 'text-secondarybg'
                                                }`}
                                                onClick={() => handleTabClick('activity')}
                                            >
                                                Activity
                                                {jobChatActivity?.length > 0 && (
                                                    <span className="absolute top-1 right-0 bg-third rounded-full w-auto p-1 min-w-4 flex items-center justify-center h-4 text-white font-medium text-xs">
                                                        {jobChatActivity?.length}
                                                    </span>
                                                )}
                                            </button>
                                            <button
                                                className={`p-2  hover:text-[#0A1E46] relative ${
                                                    activeTab === 'relayRoom'
                                                        ? 'border-b-2 border-thirdbg text-[#0A1E46] font-[500]'
                                                        : 'text-secondarybg'
                                                }`}
                                                onClick={() => handleTabClick('relayRoom')}
                                            >
                                                Relay Room
                                                {relayRoomActivity?.length > 0 && (
                                                    <span className="absolute top-1 right-0 bg-third rounded-full w-auto p-1 min-w-4 flex items-center justify-center h-4 text-white font-medium text-xs">
                                                        {relayRoomActivity?.length}
                                                    </span>
                                                )}
                                            </button>
                                        </div>

                                        {/* Tab content */}
                                    </div>

                                    <div className="mt-4">
                                        {activeTab === 'jobDetail' && (
                                            <>
                                                <JobDetail
                                                    job={job}
                                                    description={description}
                                                    setDescription={setDescription}
                                                    handleUpdateValues={handleUpdateValues}
                                                    assignees={assignees}
                                                    // disabled={disabled}
                                                />
                                            </>
                                        )}
                                        {activeTab === 'milestones' && (
                                            <>
                                                <MilestonesAndTasks
                                                    data={pageData}
                                                    handlePageChange={handlePageChange}
                                                    hasMoreContent={hasMoreContent}
                                                />{' '}
                                            </>
                                        )}
                                        {activeTab === 'documents' && (
                                            <>
                                                <JobPortalDocuments disabled={disabled} />
                                            </>
                                        )}

                                        {activeTab === 'workStation' && (
                                            <>
                                                <WorkStation
                                                    handleGetSingleJob={handleGetSingleJob}
                                                    disabled={disabled}
                                                />
                                            </>
                                        )}
                                        {activeTab === 'activity' && (
                                            <>
                                                <Activity />
                                            </>
                                        )}
                                        {activeTab === 'relayRoom' && (
                                            <>
                                                <RelayRoom />
                                            </>
                                        )}
                                    </div>
                                </div>

                                {/* Right Section - Sidebar */}
                                <div className={cn('space-y-4', expandRelayRoom ? 'hidden' : 'md:col-span-2')}>
                                    <CollapsDivComponent
                                        title={'Clients'}
                                        h_one="max-h-[65px]"
                                        h_two="max-h-[300px]"
                                        titleSx={''}
                                        classes="p-5"
                                        extra={
                                            <Button
                                                classes={'bg-gray-200 !rounded-full p-2'}
                                                tooltip={'Add Client'}
                                                iconSet={{ leftIconHTML: <PlusCircleIcon className={'w-5'} /> }}
                                                atClick={() => {
                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                        return toastHandler(
                                                            'You dont have permission to do this action.',
                                                            TOAST_TYPE_ERROR
                                                        );
                                                    }
                                                    setAddClient(!addClient);
                                                }}
                                                tooltipDirection={'none'}
                                                disabled={disabled}
                                            />
                                        }
                                    >
                                        {addClient && (
                                            <div className="default-forms p-5 border rounded-xl mt-5 mx-2">
                                                {' '}
                                                <label> Select Clients </label>
                                                <ComboboxComponentSSR
                                                    preventLoadingInfo
                                                    hideSelectedValues
                                                    fetchDataFunction={(payload) =>
                                                        fetchReferencesListFun({
                                                            ...payload,
                                                            type: ''
                                                        })
                                                    }
                                                    removePreviousSelected
                                                    onChange={(res) => {
                                                        const obj = res[0];

                                                        handleSelectClient(obj);
                                                    }}
                                                    targetProperty="business_client_id"
                                                    placeholder="Search by name/reference number/email"
                                                    // limit={1}
                                                    sx="rounded-xl ring-gray-400"
                                                    // inputsx={'!py-2.5'}
                                                />
                                                <div className="mt-5 flex gap-3 items-center flex-wrap">
                                                    {selectedClients?.map((client, index) => (
                                                        <UserChip
                                                            key={'userChip' + index}
                                                            obj={client}
                                                            onClick={() => handleClientRemove(index)}
                                                            limit={{ name: 20, email: 30 }}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="w-full mt-5 flex justify-between items-center">
                                                    <Button
                                                        title="Cancel"
                                                        classes="border border-secondarybg  py-2  text-sm font-poppins text-secondarybg px-5 rounded-lg "
                                                        atClick={() => {
                                                            setAddClient(false);
                                                            setSelectedClients([]);
                                                        }}
                                                    />
                                                    {selectedClients?.length > 0 && (
                                                        <Button
                                                            title="Add"
                                                            atClick={() => handleAddClients()}
                                                            classes={
                                                                'bg-linear  rounded-lg px-7 py-2 text-white text-sm font-poppins'
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {job?.clients?.map((client, index) => (
                                            <div
                                                key={'div' + index}
                                                className="mt-5 border-b pb-4  flex justify-between items-start"
                                            >
                                                <div className="flex-1 overflow-hidden">
                                                    <Link to={`/app/user/profile/${client?.business_client_id}`}>
                                                        <div className="flex  items-center space-x-3 px-1">
                                                            <DefaultProfileViewer
                                                                image={client?.image}
                                                                width="45px"
                                                                height="45px"
                                                                name={getProfileName(client)}
                                                            />
                                                            <div className="overflow-hidden ">
                                                                <p className="truncate">{getProfileName(client)}</p>
                                                                <p className="text-sm text-[#666666] truncate">
                                                                    {client?.email}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <div className="flex items-center gap-2 flex-wrap mt-4">
                                                        {[
                                                            ...(client?.client_category === 'Individual'
                                                                ? ['gender', 'date_of_birth', 'phone_number', 'tfn']
                                                                : [
                                                                      'phone_number',
                                                                      'tfn',
                                                                      'abn',
                                                                      'acn',
                                                                      'residential_address'
                                                                  ])
                                                        ].map((name, ind) => {
                                                            const badgeStyle = badgesArray?.find(
                                                                (obj) => obj?.name === name
                                                            );
                                                            if (client?.[name]) {
                                                                return (
                                                                    <Chip
                                                                        title={
                                                                            ['tfn', 'abn', 'acn']?.includes(name)
                                                                                ? `${name?.toUpperCase()}: ${
                                                                                      client?.[name]
                                                                                  }`
                                                                                : client?.[name]
                                                                        }
                                                                        key={'chipInd ' + client?.[name].value + ind}
                                                                        classes={
                                                                            '!text-xs capitalize font-medium px-5 py-1.5 ' +
                                                                            badgeStyle?.sx
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                            return '';
                                                        })}
                                                    </div>
                                                </div>

                                                <div
                                                    className={`cursor-pointer mr-2 ${
                                                        disabled && '!cursor-not-allowed opacity-50'
                                                    }`}
                                                    onClick={() => {
                                                        if (!hasPermissions('Job', ['write', 'admin'])) {
                                                            return toastHandler(
                                                                'You dont have permission to do this action.',
                                                                TOAST_TYPE_ERROR
                                                            );
                                                        }
                                                        if (!disabled) {
                                                            handleGetAndEdit(client?.business_client_id);
                                                        }
                                                    }}
                                                >
                                                    <BgEditIcon />
                                                </div>
                                                <div
                                                    className={`cursor-pointer ${
                                                        disabled && '!cursor-not-allowed opacity-50'
                                                    } `}
                                                    onClick={() => {
                                                        if (!hasPermissions('Job', ['write', 'admin'])) {
                                                            return toastHandler(
                                                                'You dont have permission to do this action.',
                                                                TOAST_TYPE_ERROR
                                                            );
                                                        }
                                                        if (!disabled) {
                                                            deleteCient(client?.business_client_id);
                                                        }
                                                    }}
                                                >
                                                    <BgDeleteIcon />
                                                </div>
                                            </div>
                                        ))}
                                    </CollapsDivComponent>
                                    <CollapsDivComponent
                                        title="Attachments"
                                        h_one="max-h-[65px]"
                                        h_two="max-h-[300px]"
                                        classes="p-5"
                                        childSx="px-2"
                                        extra={
                                            <Button
                                                classes={'bg-gray-200 !rounded-full p-2'}
                                                tooltip={'Add File'}
                                                iconSet={{ leftIconHTML: <PlusCircleIcon className={'w-5'} /> }}
                                                atClick={() => {
                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                        return toastHandler(
                                                            'You dont have permission to do this action.',
                                                            TOAST_TYPE_ERROR
                                                        );
                                                    }
                                                    if (fileInputRef2.current) {
                                                        fileInputRef2.current.click(); // Trigger file input click
                                                    }
                                                }}
                                                disabled={disabled}
                                                tooltipDirection={'none'}
                                            />
                                        }
                                    >
                                        {job?.meta && (
                                            <div className="mt-5 cursor-pointer flex gap-2 items-center justify-between">
                                                <Link
                                                    to={`/app/user/profile/${job?.meta?.business_client_id}?businessFormDataId=${job?.meta?.business_form_data_id}&form_id=${job?.meta?.form_id}`}
                                                    target="_blank"
                                                    className="flex  cursor-pointer items-center space-x-3"
                                                >
                                                    <SubmitFormIcon className={'w-[45px]'} />
                                                    <div className="overflow-hidden ">
                                                        <p className="truncate font-[500]">{job?.meta?.form_name}</p>
                                                        <p className="text-xs  truncate">
                                                            {customDateFormat(
                                                                job?.meta?.created_at,
                                                                'DD-MM-YYYY',
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )}
                                        <FileDropZone
                                            accept={all_files_types} // Allow images, PDFs, and CSVs
                                            multiple={true} // Allow multiple file uploads
                                            onFilesDrop={(files) => {
                                                if (!hasPermissions('Job', ['write', 'admin'])) {
                                                    return toastHandler(
                                                        'You dont have permission to do this action.',
                                                        TOAST_TYPE_ERROR
                                                    );
                                                }
                                                handleAddJobFiles(files);
                                            }} // Callback to handle the dropped files
                                            card_h={'h-[160px]'}
                                            card_w={'w-[90px]'}
                                            disabled={disabled}
                                        >
                                            {job?.attachments?.length > 0 ? (
                                                job?.attachments?.map((file, index) => (
                                                    <div
                                                        className="mt-5 cursor-pointer flex gap-2 items-center justify-between"
                                                        key={'div2' + index}
                                                    >
                                                        {/* Client card */}

                                                        <div
                                                            onClick={() => {
                                                                if (file?.url?.includes('.pdf')) {
                                                                    setSelectedPdf(file?.url);
                                                                } else if (
                                                                    ['image/png', 'image/jpeg', 'image/jpg']?.includes(
                                                                        file?.file_type
                                                                    )
                                                                ) {
                                                                    setSelectedImage(file?.url);
                                                                } else {
                                                                    downloadFile(file?.url);
                                                                }
                                                            }}
                                                            className="flex  cursor-pointer items-center space-x-3"
                                                        >
                                                            <GenerateFileIcon url={file?.url} sx={'w-[45px]'} />
                                                            <div className="overflow-hidden ">
                                                                <p className="truncate font-[500]">
                                                                    {file?.name.slice(-15)}
                                                                </p>
                                                                <p className="text-xs  truncate">
                                                                    {/* {file?.created_at} */}
                                                                    {DateFormatToLocal({
                                                                        date: file?.created_at,
                                                                        format: 'DD MMM, YYYY'
                                                                    })}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            onClick={() => {
                                                                if (!disabled) {
                                                                    handleDeleteFile(file?.job_attachment_id);
                                                                }
                                                            }}
                                                            className={` ${
                                                                disabled && '!cursor-not-allowed opacity-50'
                                                            } cursor-pointer `}
                                                        >
                                                            <BgDeleteIcon />
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="h-[120px] w-full  border border-dashed border-[#e8defb] rounded-xl mt-4 p-[1px]  text-sm ">
                                                    <div className="!bg-[#b695f81a] w-full h-full rounded-xl flex justify-center items-center flex-col gap-2">
                                                        <FileIconSvg /> Drag files here to upload
                                                    </div>
                                                </div>
                                            )}
                                        </FileDropZone>
                                    </CollapsDivComponent>

                                    <CollapsDivComponent
                                        title={'Manager'}
                                        h_one="max-h-[65px]"
                                        h_two="h-[300px] max-h-[300px]"
                                        classes="p-5"
                                        extra={
                                            <Button
                                                classes={'bg-gray-200 !rounded-full p-2'}
                                                tooltip={'Add Manager'}
                                                iconSet={{ leftIconHTML: <PlusCircleIcon className={'w-5'} /> }}
                                                atClick={() => {
                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                        return toastHandler(
                                                            'You dont have permission to do this action.',
                                                            TOAST_TYPE_ERROR
                                                        );
                                                    }
                                                    setAddManager(!addManager);
                                                }}
                                                disabled={disabled}
                                                tooltipDirection={'none'}
                                            />
                                        }
                                    >
                                        {addManager && (
                                            <div className="default-forms p-5 border rounded-xl mt-5 mx-2">
                                                {' '}
                                                <label> Select Managers </label>
                                                {/* <ComboboxComponentSSR
                                                preventLoadingInfo
                                                hideSelectedValues
                                                fetchDataFunction={(payload) =>
                                                    fetchReferencesListFun({
                                                        ...payload,
                                                        type: ''
                                                    })
                                                }
                                                removePreviousSelected
                                                onChange={(res) => {
                                                    const obj = res[0];
                                                    handleSelectClient(obj);
                                                }}
                                                targetProperty="business_client_id"
                                                placeholder="Search by name/reference number/email"
                                                sx="rounded-xl ring-gray-400"
                                            /> */}
                                                <ComboboxComponent
                                                    data={employees}
                                                    removePreviousSelected
                                                    hideSelectedValues
                                                    placeholder={'Search Manager by Name or Reference Number'}
                                                    onChange={(list) => {
                                                        const obj = list[0];
                                                        handleSelectManager(obj);
                                                    }}
                                                    targetProperty="user_id"
                                                />
                                                <div className="mt-5 flex gap-3 items-center flex-wrap">
                                                    {selectedManagers?.map((manager, index) => (
                                                        <UserChip
                                                            key={'userChip' + index}
                                                            obj={manager}
                                                            onClick={() => {
                                                                if (!disabled) {
                                                                    handleManagerRemove(index);
                                                                }
                                                            }}
                                                            limit={{ name: 20, email: 30 }}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="w-full mt-5 flex justify-between items-center">
                                                    <Button
                                                        title="Cancel"
                                                        classes="border border-secondarybg  py-2  text-sm font-poppins text-secondarybg px-5 rounded-lg "
                                                        atClick={() => {
                                                            setAddManager(false);
                                                            setSelectedManagers([]);
                                                        }}
                                                    />
                                                    {selectedManagers?.length > 0 && (
                                                        <Button
                                                            title="Add"
                                                            atClick={() => handleAddManagers()}
                                                            classes={
                                                                'bg-linear rounded-lg px-7 py-2 text-white text-sm font-poppins'
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-6">
                                            {job?.manager?.map((manager, index) => (
                                                <div
                                                    key={'managerdiv' + index}
                                                    className="border-b py-2 px-2  flex justify-between items-center"
                                                >
                                                    <div className="flex-1 overflow-hidden">
                                                        <div className="flex items-center space-x-3">
                                                            <DefaultProfileViewer
                                                                image={manager?.image}
                                                                width="45px"
                                                                height="45px"
                                                                name={manager?.first_name + manager?.last_name}
                                                            />
                                                            <div>
                                                                <p>
                                                                    {manager?.first_name + ' ' + manager?.last_name ||
                                                                        '--'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={` ${
                                                            disabled && '!cursor-not-allowed opacity-50'
                                                        } cursor-pointer`}
                                                        onClick={() => {
                                                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                                                return toastHandler(
                                                                    'You dont have permission to do this action.',
                                                                    TOAST_TYPE_ERROR
                                                                );
                                                            }
                                                            if (!disabled) {
                                                                deleteManager(manager?.user_id);
                                                            }
                                                        }}
                                                    >
                                                        <BgDeleteIcon />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/* <div className="flex items-center space-x-3 mt-5">
                                        <DefaultProfileViewer
                                            image={job?.manager?.image}
                                            width="45px"
                                            height="45px"
                                            name={job?.manager?.first_name + job?.manager?.last_name}
                                        />
                                        <div>
                                            <p>{job?.manager?.first_name + job?.manager?.last_name || '--'}</p>
                                        </div>
                                    </div>
                                    <div className="default-forms mt-5">
                                        <div>
                                            <label> Select Manager </label>
                                            <ComboboxComponent
                                                data={employees}
                                                placeholder={'Search Manager by Name '}
                                                selectedValue={manager ? manager : ''}
                                                onChange={(list) => {
                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                        return toastHandler(
                                                            'You dont have permission to do this action.',
                                                            TOAST_TYPE_ERROR
                                                        );
                                                    }
                                                    const obj = list[0];
                                                    setManager(obj?.user_id);
                                                }}
                                                targetProperty="user_id"
                                                limit={1}
                                                showNameOnly
                                            />
                                        </div>

                                        {manager && (
                                            <div className="w-full mt-5">
                                                <Button
                                                    title="Replace"
                                                    classes="ann-btn px-16 rounded-xl w-full buttons-font-lighter font-fira"
                                                    atClick={handleAddManager}
                                                />
                                            </div>
                                        )}
                                    </div> */}
                                    </CollapsDivComponent>

                                    <CollapsDivComponent
                                        title={'Task Overview'}
                                        h_one="max-h-[65px]"
                                        h_two="max-h-[300px]"
                                        classes="p-5"
                                    >
                                        <div>
                                            {jobTasks?.map((task, index) => (
                                                <div
                                                    onClick={() => {
                                                        if (!disabled) {
                                                            handleDetails(task);
                                                        }
                                                    }}
                                                    key={'div3' + index}
                                                    className="flex items-start space-x-3 mt-5 cursor-pointer"
                                                >
                                                    <CalenderBgChipIcon
                                                        fill={(index + 1) % 2 === 0 ? '#0A1E46' : '#B695F8'}
                                                        className="w-[45px]"
                                                    />
                                                    <div className="flex-1 overflow-hidden">
                                                        <p className="truncate">{task?.title}</p>
                                                        <div>
                                                            {' '}
                                                            <Chip
                                                                title={task?.status}
                                                                classes={
                                                                    'bg-[#b695f8]/10 text-[#b695f8] w-[120px] mt-1 flex gap-2 items-center !capitalize'
                                                                }
                                                                // iconSet={{ rightIcon: ChipArrow, rightIconFill: '#0A1E46' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CollapsDivComponent>
                                </div>
                            </div>
                            {/* file upload */}
                            <input
                                ref={fileInputRef2}
                                type="file"
                                onChange={handleFileChange}
                                accept="*"
                                multiple
                                style={{ display: 'none' }}
                                id="fileInput-one"
                            />

                            {/* draft mails */}
                            {draftList?.length > 0 && (
                                <div className={cn('absolute right-0 top-40 h-auto overflow-hidden z-25')}>
                                    <div
                                        className={cn(
                                            `flex items-start gap-0 transition-width duration-300 ease-in-out`
                                        )}
                                        style={{
                                            width: expandDraft ? '337px' : '20px',
                                            transition: 'width 300ms ease-in-out'
                                        }}
                                    >
                                        <div
                                            className="h-[100px] w-[20px] border border-r-0 bg-blueish/95 backdrop-blur-md rounded-l-xl shadow-lg flex items-center justify-center cursor-pointer translate-x-px z-25"
                                            onClick={() => setExpandDraft(!expandDraft)}
                                        >
                                            <span className="relative flex">
                                                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-third opacity-75"></span>
                                                {expandDraft ? (
                                                    <ChevronDoubleRightIcon className="w-5 h-5 text-third" />
                                                ) : (
                                                    <ChevronDoubleLeftIcon className="w-5 h-5 text-third" />
                                                )}
                                            </span>
                                        </div>
                                        <div
                                            className={`flex flex-col items-start justify-start border border-r-0 gap-2 px-3 py-4 text-white shadow-lg rounded-bl-xl bg-blueish/95 min-h-[200px] !w-full`}
                                        >
                                            {draftList?.map((draft_obj, index) => (
                                                <div
                                                    className="cursor-pointer flex truncate items-center justify-between text-[#DCDCDC] hover:bg-slate-100 rounded-lg hover:text-black text-sm px-3 py-2 w-full text-right"
                                                    key={'minimized_' + index}
                                                >
                                                    <div
                                                        className="flex gap-1 items-center flex-1 truncate max-w-[250px]"
                                                        onClick={() => {
                                                            if (!disabled) {
                                                                handleSelectDraft(draft_obj);
                                                            }
                                                        }}
                                                    >
                                                        <PencilIcon
                                                            className={` ${
                                                                disabled && '!cursor-not-allowed opacity-50'
                                                            } w-4 h-4`}
                                                        />
                                                        <span className="flex-1 truncate text-left">
                                                            {draft_obj?.subject}
                                                        </span>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="ml-2 p-1 rounded-full bg-third text-white"
                                                        onClick={() => {
                                                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                                                return toastHandler(
                                                                    'You dont have permission to do this action.',
                                                                    TOAST_TYPE_ERROR
                                                                );
                                                            }
                                                            if (!disabled) {
                                                                handleRemovvDraft(draft_obj?.email_draft_id);
                                                            }
                                                        }}
                                                    >
                                                        <XMarkIcon
                                                            className={` ${
                                                                disabled && '!cursor-not-allowed opacity-50'
                                                            } w-3 h-3`}
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>

            <ConfirmationModal
                isOpen={!!jobCloseModal}
                onClose={() => setJobCloseModal(false)}
                title="Action Required!"
                description={"Do you want to move Job Documents to Client's Folder?"}
                sx="max-w-3xl rounded-xl !px-8 !py-5"
                customButtons={
                    <div className="flex items-center justify-center gap-2 w-full">
                        <Button
                            title={"Close Anyway's"}
                            classes={
                                'border-blueish text-blueish border-2 h-[3rem] rounded-xl !font-medium font-fira !px-6'
                            }
                            atClick={() => {
                                changeJobStatus(jobCloseModal);
                            }}
                        />
                        <Button
                            atClick={() => {
                                setCutORCopy(jobCloseModal);
                                setJobCloseModal(false);
                            }}
                            title={"Move to Client's Folder"}
                            classes={cn('rounded-xl h-[3.2rem] !font-medium font-fira !text-white ann-btn !px-6')}
                        />
                    </div>
                }
            />

            {CutORCopy && (
                <MoveOrCopy
                    open={!!CutORCopy}
                    setOpen={(e) => {
                        if (!e) {
                            setCutORCopy(false);
                            setSelectedIds([]);
                        }
                    }}
                    title={'Move to Client Folder'}
                    action={'moveToClient'}
                    list={selectedIds}
                    getMainTableData={() => changeJobStatus(CutORCopy)}
                    setFileList={setSelectedIds}
                />
            )}
        </SidebarLayout>
    );
};
