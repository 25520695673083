import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import './css/addService.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllServicesThunk } from 'store/auth/slices';
import { useState } from 'react';
import { toastHandler } from 'responseHanlder';
import { SmallLoader } from 'components/common/SmallLoader';
import { BUSINESS_ID, ERROR_TYPE_ERROR } from 'utills/globalVars';
import TruncateText from 'global-components/StringSlicer';
import { Button } from 'components/atoms/buttons/Button';
import { NoDataFound } from 'components/common/NoDataFound';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { editService } from 'store/global/globalReducer';

const AddService = ({ toggleServiceSide, isServiceUpdated, toggleEditService }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [servicesData, setServicesData] = useState(null);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [serviceLoader, setServiceLoader] = useState(false);

    useEffect(() => {
        if (!business_id) {
            toastHandler('You need to be logged in first', ERROR_TYPE_ERROR);
            navigate('/signup');
            return;
        }
        setServiceLoader(true);
        setTimeout(() => {
            dispatch(getAllServicesThunk({ business_id }))
                .then((response) => {
                    if (response.payload) {
                        setServicesData(response.payload);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setServiceLoader(false);
                });
        }, 1000);
    }, [isServiceUpdated]);

    const handleEdit = (service) => {
        dispatch(editService(service));
        toggleEditService();
    };

    return (
        <div className=" service py-2 relative ">
            <div className="flex justify-end mx-5 mt-3">
                <Button
                    title="Add Service"
                    atClick={toggleServiceSide}
                    classes="ann-btn px-6 buttons-font rounded-xl"
                />
            </div>
            {serviceLoader ? (
                <div className="branch-location-loader">
                    {' '}
                    <SmallLoader />{' '}
                </div>
            ) : (
                <div className="mt-5">
                    {servicesData && servicesData[0] ? (
                        servicesData?.map((service, index) => (
                            <div className={`service-body p-2 rounded-lg mx-5 ${index > 0 && 'mt-5'}`} key={index}>
                                <div className="flex justify-between items-center flex-wrap">
                                    <div className="buttons-font">{service.name}</div>{' '}
                                    <div className="cursor-pointer " onClick={() => handleEdit(service)}>
                                        {' '}
                                        <BgEditIcon />{' '}
                                    </div>
                                </div>
                                <div className="flex gap-2 mt-2 justify-between buttons-font">
                                    <div className="buttons-font text-secondarybg ">Consultation Charges:</div>
                                    <div className="dollers"> $ {service.charges}</div>
                                </div>

                                <div className="legend flex gap-2 items-center mt-3 buttons-font-lighter justify-between flex-wrap px-2 rounded-2xl">
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            <svg
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect width="20" height="20" rx="10" fill="white" />
                                                <path
                                                    d="M10 5V11.6667H15"
                                                    stroke="#B695F8"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        <div>Min-Time of Consultation:</div>
                                    </div>
                                    <div className="col-span-1">{service?.duration} mins </div>
                                </div>

                                <div className="details mt-5">
                                    <div className="buttons-font-lighter font-poppins">Details</div>
                                    <div className="buttons-font-light font-poppins mt-2 break-words">
                                        <TruncateText index={index} text={service?.description} maxLength={300} />
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <hr />
                                </div>
                            </div>
                        ))
                    ) : (
                        <NoDataFound
                            message={`No services found! Click on the "Add Service" button to add a service.
            `}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AddService;
