import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

export const DefaultBarChart = ({ bg, stats, horizontal, height, Yaxes, xValues, _radius, barWidth, borderRadius }) => {
    const [_statList, setStatList] = useState([]);

    useEffect(() => {
        if (stats) {
            setStatList(stats);
        }
    }, [stats]);

    const [chartData, _setChartData] = useState({
        options: {
            plotOptions: {
                bar: {
                    horizontal: horizontal || false,
                    borderRadiusWhenStacked: 'all',
                    borderRadius: borderRadius ?? 5,
                    borderRadiusApplication: 'end',
                    columnWidth: barWidth ?? '40%'
                }
            },
            grid: {
                line: {
                    style: 'dotted'
                }
            },
            chart: {
                id: 'area-datetime',
                type: 'bar',
                height: height,
                zoom: {
                    autoScaleYaxis: true
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                        zoom: true,
                        zoomin: false,
                        zoomout: false,
                        selection: true,
                        pan: true,
                        reset: true
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            markers: {
                size: 0,
                style: 'hollow'
            },
            xaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },

                labels: {
                    style: {
                        colors: [
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797',
                            '#979797'
                        ],
                        fontSize: '14px'
                    },
                    show: xValues ? true : false
                },
                categories: xValues
            },
            yaxis: {
                labels: {
                    style: {
                        colors: ['#979797'],
                        fontSize: '14px'
                    },
                    show: Yaxes || false
                }
            },
            axisBorder: {
                show: true
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0
            },
            colors: ['#B695F8', '#101828']
        },
        selection: 'one_year'
    });

    return (
        <div>
            <div className={`chartDiv max-h-[${height || '300'}px] bg-${bg} p-3 rounded-lg `}>
                <Chart options={chartData.options} series={stats} type="bar" height={height || 300} />
            </div>
        </div>
    );
};
