import React from 'react';

export const MessagesIcon = ({ className }) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.87682 5.48145C8.99159 5.48145 8.14262 5.83555 7.51667 6.46585C6.89072 7.09615 6.53906 7.95102 6.53906 8.84241V14.444C6.53906 15.3354 6.89072 16.1903 7.51667 16.8206C8.14262 17.4509 8.99159 17.805 9.87682 17.805L12.6227 17.8039L14.5308 20.5262C14.6205 20.6538 14.7357 20.7611 14.8691 20.8412C15.0024 20.9213 15.151 20.9724 15.3051 20.9912L15.4397 21.0001C15.7124 21.0001 15.9756 20.8992 16.1793 20.7166C16.3829 20.534 16.5129 20.2825 16.5445 20.0098L16.5523 19.8798L16.5534 17.8039L17.6649 17.805C18.5501 17.805 19.3991 17.4509 20.0251 16.8206C20.651 16.1903 21.0027 15.3354 21.0027 14.444V8.84241C21.0027 7.95102 20.651 7.09615 20.0251 6.46585C19.3991 5.83555 18.5501 5.48145 17.6649 5.48145H9.87682Z"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.53637 14.4438L4.3112 16.6845V12.2032H3.3032C2.71745 12.2032 2.15532 11.9707 1.73874 11.5561C1.32217 11.1414 1.08476 10.5781 1.07803 9.98833L1.00015 3.26641C0.996762 2.97 1.05182 2.67587 1.16213 2.40104C1.27244 2.12621 1.4358 1.87615 1.64277 1.66535C1.84973 1.45455 2.09618 1.2872 2.36783 1.17299C2.63948 1.05878 2.93094 0.99998 3.22532 1H13.2119C13.802 1 14.368 1.23607 14.7853 1.65627C15.2026 2.07647 15.4371 2.64639 15.4371 3.24064V5.48128"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
