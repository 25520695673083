import React from 'react';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { addBusinessThunk, uploadBusinessLogoThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { validateEmail } from '../../../../utills/FormValidation';
import './css/addBusiness.css';
import { useBusinessProfile } from 'Hooks/useBusinessProfile';
import { TOAST_TYPE_ERROR, USER_TYPE } from 'utills/globalVars';
import { SmallLoader } from 'components/common/SmallLoader';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import isValidABN from 'is-valid-abn';
import { PickAddress } from 'global-components/PickAddress';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { DefaultUserIcon } from 'utills/svgs/DefaultUserIcon';
import { DataURIToBlob, countWords, getFirst250Words, getFirstWords } from 'utills/dataValidation';
import { ImageCropper } from 'global-components/ImageCropper';
import Slideover from 'components/atoms/SlideOver';
import WebsiteLinkInput from 'components/atoms/inputs/WebsiteLinkInput';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import { validateAbn, validatePhoneNumber } from 'utills/baseValidations';
import { NumberInput } from 'components/atoms/inputs/NumberInput';

const AddBusinessSide = (props) => {
    const dispatch = useDispatch();

    const { fetchBusiness } = useBusinessProfile();
    const { data: businessProfile } = useSelector((state) => state.getBusinessprofile);

    const isLoading = useSelector((state) => state.global.isLoading);

    const business_id = localStorage.getItem('business_id');

    const data = useSelector((state) => state.login.data);

    const [businessName, setBusinessName] = useState(businessProfile?.name);
    const [taxAgentNum, setTaxAgentNum] = useState(businessProfile?.tax_agent_number || '');
    const [email, setEmail] = useState(businessProfile?.email ?? '');
    const [phoneNo, setPhoneNo] = useState(data?.phone_number);
    const [abn, setAbn] = useState(businessProfile?.abn);
    const [weblink, setWeblink] = useState(businessProfile?.website);
    const [selectedImage, setSelectedImage] = useState(null);
    const [businessLoader, setBusinessLoader] = useState(false);
    const [address, setAddress] = useState(data?.address);
    const [bio, setBio] = useState(businessProfile?.description ?? '');
    const [isZoomed, setIsZoomed] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showCropper, setShowCropper] = useState(false);
    const [_bioLength, setBioLength] = useState(
        businessProfile?.description ? countWords(businessProfile?.description) : 0
    );

    useEffect(() => {
        if (businessProfile) {
            setAddress(businessProfile?.address);
        }
    }, [businessProfile]);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setCroppedImage(file);
        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setSelectedFile(reader.result);
            };

            reader.readAsDataURL(file);
            setShowCropper(true);
        }
    };

    const handleBio = (value) => {
        const wordsLength = countWords(value);
        if (wordsLength <= 250) {
            setBioLength(countWords(value));
            setBio(value);
        }
    };

    const handlePastBio = (e) => {
        const value = e.target.value;
        if (bio.split(' ').length >= 250) {
            return;
        } else {
            setBio(value.split(' ').slice(0, 250).join(' '));
        }

        if (bio?.split(' ')?.length >= 250) {
            return;
        }
        if (value) {
            const countedInput = countWords(bio);

            const availableSpace = 250 - countedInput;

            const first250Words = getFirst250Words(value);

            const availableWordsToPaste = getFirstWords(first250Words, availableSpace);

            const newstr = bio + availableWordsToPaste;
            setBioLength(countWords(newstr));
            setBio(newstr);
        }
    };

    const [emailError, setEmailError] = useState(false);
    const [abnError, setAbnError] = useState(false);
    const [_weblinkError, setWeblinkError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'businessName') {
            setBusinessName(value);
        } else if (name === 'businessEmail') {
            if (!validateEmail(value)) {
                setEmailError(true);
                setEmail(value);
            } else if (value?.length === 0 || value?.length > 30) {
                setEmailError(false);
            } else {
                setEmailError(false);
                setEmail(value);
            }
        }

        if (name === 'phoneNumber') {
            if (value?.length < 16) {
                setPhoneNo(value);
            }
        }

        if (name === 'abn') {
            const abnNumber = value.replace(/\s/g, '');
            const updatedABN = abnNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
            if (!isValidABN(updatedABN)) {
                setAbnError(true);
            } else {
                setAbnError(false);
            }

            setAbn(abnNumber);
        }
        if (name === 'weblink') {
            if (value?.length > 40) {
                return;
            }
            if (!value.includes('.com')) {
                setWeblinkError(true);
            } else if (value?.length === 0) {
                setWeblinkError(false);
            } else {
                setWeblinkError(false);
            }
            setWeblink(value?.trim());
        }
    };

    const submitAddBusiness = (e) => {
        e.preventDefault();
        if (!businessName) {
            toastHandler('Business Name is required.', TOAST_TYPE_ERROR);
            return;
        }
        if (businessName?.length > 30) {
            toastHandler('Max length should be less than 30 character.', TOAST_TYPE_ERROR);
            return;
        }

        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        if (abn) {
            const validateAbnV = validateAbn(abn);
            if (!validateAbnV) {
                return toastHandler('Invalid ABN number', TOAST_TYPE_ERROR);
            }
        }

        const formData = new FormData();

        formData.append('logo', selectedImage);
        formData.append('business_id', business_id);
        formData.append('name', businessName);
        formData.append('email', email);
        formData.append('website', weblink);
        formData.append('abn', abn);
        formData.append('phone_number', phoneNo);

        const businessData = {
            business_id: business_id,
            name: businessName,
            email: email,
            website: weblink,
            abn: abn,
            phone_number: phoneNo,
            stripe_plan_id: 'id_1',
            address,
            description: bio,
            tax_agent_number: taxAgentNum
        };

        dispatch(setLoader(true));
        dispatch(addBusinessThunk(businessData))
            .then((response) => {
                if (response.payload) {
                    props.toggleBusinessSide();
                    toastHandler('Business added successfully ', 'success');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchBusiness();
    }, []);

    const onSelect = (place) => {
        setAddress(place.formatted_address);
    };

    const saveImage = (image) => {
        const file = DataURIToBlob(image);
        const formData = new FormData();

        formData.append('business_logo', file, croppedImage?.name);

        formData.append('uploaded_by', USER_TYPE);

        setBusinessLoader(true);
        dispatch(uploadBusinessLogoThunk(formData))
            .then((response) => {
                setSelectedImage(image);
                setSelectedImage(response.payload.logo);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setBusinessLoader(false);
                fetchBusiness();
            });
    };

    const handleWebsiteLinkChange = (value) => {
        setWeblink(value);
    };

    return (
        <Slideover title="Business Profile" open={props.open} setOpen={props.setOpen} size="max-w-3xl">
            <div className="">
                <div className="">
                    {selectedFile && showCropper ? (
                        <ImageCropper
                            setShowCroper={setShowCropper}
                            image={selectedFile}
                            handleCroppedImage={(image) => saveImage(image)}
                            isCircular={true}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="col-span-2   relative"
                >
                    <div className="add-business-info p-5 pt-10">
                        <div className="flex justify-center items-center">
                            {' '}
                            <div className="profile-image-container mt-5 ">
                                <div className={`flex justify-center items-center h-full w-full`}>
                                    {businessLoader ? (
                                        <SmallLoader />
                                    ) : selectedImage ?? businessProfile?.logo ? (
                                        <img
                                            className="profile-image-edit"
                                            src={selectedImage ? selectedImage : businessProfile?.logo}
                                            alt=""
                                            onClick={() => businessProfile?.logo && setIsZoomed(true)}
                                        />
                                    ) : (
                                        <div className="user-icon-dummy">
                                            <DefaultUserIcon />
                                        </div>
                                    )}
                                </div>
                                {isZoomed && (
                                    <ZoomImage
                                        src={businessProfile?.logo}
                                        alt="Profile"
                                        onClose={() => setIsZoomed(false)}
                                    />
                                )}
                                <input
                                    type="file"
                                    accept=".jpg, .jpeg, .gif"
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                    id="image-input"
                                />
                            </div>
                        </div>
                        <form onSubmit={submitAddBusiness}>
                            <div className=" add-ann-form  mt-5">
                                <div>
                                    <label className="buttons-font">Business name</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => handleChange(e)}
                                        name="businessName"
                                        type="text"
                                        maxLength={40}
                                        className=" px-3 rounded-xl inputs-font mt-2"
                                        placeholder="Business Name"
                                        value={businessName}
                                        required
                                    />
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 mt-5 gap-5">
                                    <div>
                                        <div>
                                            <label className="buttons-font">Business email address</label>
                                        </div>
                                        <div className="mt-1">
                                            <input
                                                onChange={(e) => handleChange(e)}
                                                name="businessEmail"
                                                type="email"
                                                placeholder="Enter Email"
                                                className="rounded-xl px-3 inputs-font"
                                                value={email}
                                                required
                                            />
                                        </div>
                                        {emailError && (
                                            <div className="error-div mt-1 small-text">
                                                <span> Invalid Email </span>
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        <div>
                                            <PhoneInputComponent
                                                title="Business phone number"
                                                placeholder="Enter business phone number"
                                                titleSx="font-semibold"
                                                sx="!rounded-xl"
                                                atChange={(phone) => setPhoneNo(phone)}
                                                value={phoneNo}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <label className="buttons-font">ABN</label>
                                        </div>
                                        <div className="">
                                            <input
                                                onChange={(e) => handleChange(e)}
                                                name="abn"
                                                type="text"
                                                maxLength="60"
                                                placeholder="ABN"
                                                className="rounded-xl px-3 inputs-font"
                                                value={abn}
                                                required
                                            />
                                        </div>
                                        {abnError && (
                                            <div className="error-div mt-1 small-text">
                                                <span> Invalid ABN number</span>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            <label className="buttons-font">Tax Agent Number</label>
                                        </div>
                                        <div className="">
                                            <NumberInput
                                                value={taxAgentNum}
                                                onChange={(e) => {
                                                    setTaxAgentNum(e);
                                                }}
                                                required
                                                maxLength={8}
                                                placeholder="Enter Tax Agent Number"
                                                acceptZero
                                            />
                                        </div>
                                        {emailError && (
                                            <div className="error-div mt-1 small-text">
                                                <span> Invalid Email </span>
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        <div>
                                            <label className="buttons-font">Website link</label>
                                        </div>
                                        <div>
                                            <WebsiteLinkInput
                                                onChange={handleWebsiteLinkChange}
                                                value={weblink}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <PickAddress
                                            id="add-business"
                                            onSelect={onSelect}
                                            address={address}
                                            setAddress={setAddress}
                                            label={'Business Address '}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="add-ann-form mt-5">
                                    <div>
                                        <label className="buttons-font">
                                            Please enter your business bio for your clients
                                        </label>
                                    </div>
                                    <div>
                                        <DefaultTextArea
                                            placeholder="Please enter your business bio for your clients"
                                            onChange={(e) => handleBio(e)}
                                            onInput={(e) => handlePastBio(e)}
                                            cols="30"
                                            rows="10"
                                            value={bio}
                                            maxCount={250}
                                            className="rounded-xl p-3 mt-2 font-poppins inputs-font"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center  btn-wrap mt-20 max-lg:mt-24">
                                <button className="ann-btn text-white buttons-font px-16 py-2 rounded-md cursor-pointer">
                                    {isLoading ? (
                                        <span>
                                            {' '}
                                            <SmallLoaderWhite />{' '}
                                        </span>
                                    ) : (
                                        'Add'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </motion.div>
            </div>
        </Slideover>
    );
};

export default AddBusinessSide;
