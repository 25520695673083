/* eslint-disable jsx-a11y/no-redundant-roles */
import { cn } from 'pages/documents/hooks/helper';
import React, { useEffect, useRef, useState } from 'react';
import { PLACES_API_KEY } from 'utills/globalVars';

const MapComponent = ({ addressData, className, openInNewTab }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const [mapLoaded, _setMapLoaded] = useState(false);

    useEffect(() => {
        const initializeMap = () => {
            const lat = Number(addressData?.geometry?.location?.lat() || 0);
            const lng = Number(addressData?.geometry?.location?.lng() || 0);

            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat, lng },
                zoom: 15
            });

            new window.google.maps.Marker({
                position: { lat, lng },
                map,
                title: addressData.formatted_address
            });

            const marker = new window.google.maps.Marker({
                position: { lat, lng },
                map,
                title: addressData.formatted_address
            });

            markerRef.current = marker;

            if (openInNewTab) {
                map.addListener('click', () => {
                    openMapInNewTab(lat, lng);
                });

                marker.addListener('click', () => {
                    openMapInNewTab(lat, lng);
                });
            }
        };

        const openMapInNewTab = (lat, lng) => {
            window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
        };

        if (!mapLoaded) {
            if (window.google) {
                initializeMap();
            } else {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places`;
                script.async = true;
                script.onload = initializeMap;
                document.body.appendChild(script);

                return () => {
                    document.body.removeChild(script);
                };
            }
        }
    }, [addressData, mapLoaded]);

    return <div ref={mapRef} className={cn('h-[200px] ', className || '')} style={{ width: '100%' }} />;
};

export default MapComponent;
