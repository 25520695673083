import React from "react";

export const LightBulb = () => {
  return (
    <div>
      {" "}
      <svg
        width="28"
        height="28"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.8 30V31.2C23.1183 31.2 23.4235 31.0736 23.6485 30.8485C23.8736 30.6235 24 30.3183 24 30H22.8ZM13.2 30H12C12 30.3183 12.1264 30.6235 12.3515 30.8485C12.5765 31.0736 12.8817 31.2 13.2 31.2V30ZM14.4 36H21.6V33.6H14.4V36ZM8.592 15.36L8.568 15.528L10.944 15.864L10.9704 15.696L8.592 15.36ZM18 7.2C15.7122 7.19962 13.501 8.02473 11.7727 9.52376C10.0444 11.0228 8.91503 13.0951 8.592 15.36L10.9704 15.696C11.2125 14.0036 12.0545 12.4554 13.3463 11.3356C14.6381 10.2158 16.2904 9.59953 18 9.6V7.2ZM27.408 15.36C27.085 13.0951 25.9556 11.0228 24.2273 9.52376C22.499 8.02473 20.2878 7.19962 18 7.2V9.6C19.7094 9.60003 21.3615 10.2165 22.6532 11.3362C23.9449 12.4559 24.7894 14.0039 25.032 15.696L27.408 15.36ZM27.432 15.528L27.408 15.36L25.032 15.696L25.0536 15.864L27.432 15.528ZM25.536 22.7016C27.048 20.748 27.8112 18.204 27.432 15.528L25.056 15.8664C25.1935 16.8102 25.1389 17.7719 24.8956 18.6941C24.6522 19.6162 24.2252 20.4797 23.64 21.2328L25.536 22.7016ZM21.6 26.16V30H24V26.1624H21.6V26.16ZM22.8 28.8H13.2V31.2H22.8V28.8ZM14.4 30V26.1624H12V30H14.4ZM8.568 15.528C8.38556 16.7898 8.45944 18.0754 8.78521 19.308C9.11098 20.5405 9.68196 21.6947 10.464 22.7016L12.3624 21.2328C11.7772 20.4797 11.3478 19.6162 11.1044 18.6941C10.8611 17.7719 10.8065 16.8078 10.944 15.864L8.568 15.528ZM14.4 26.16C14.4 24.1848 13.3488 22.5048 12.36 21.2304L10.464 22.7016C11.3832 23.8896 12 25.0224 12 26.16H14.4ZM23.64 21.2304C22.6488 22.5072 21.6 24.1848 21.6 26.16H24C24 25.0224 24.6168 23.8896 25.536 22.7016L23.64 21.2304ZM16.8 0V4.8H19.2V0H16.8ZM0 19.2H4.8V16.8H0V19.2ZM31.2 19.2H36V16.8H31.2V19.2ZM8.0496 8.7504L4.4496 5.1504L2.7504 6.8496L6.3504 10.4496L8.0496 8.7504ZM29.6496 10.4496L33.2496 6.8496L31.5504 5.1504L27.9504 8.7504L29.6496 10.4496Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
