import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: deductionsInfo, request: deductionsInfoThunk } = generateThunkAndSlice(
    'documentDetailToSign',
    endpoint.deductionsInfo,
    methods.POST
);

const { slice: getTfnDetails, request: getTfnDetailsThunk } = generateThunkAndSlice(
    'documentDetailToSign',
    endpoint.getTfnDetails,
    methods.POST
);
const { slice: getRentalProperty, request: getRentalPropertyThunk } = generateThunkAndSlice(
    'getRentalProperty',
    endpoint.getRentalProperty,
    methods.POST
);

const { request: downloadCsvThunk } = generateThunkAndSlice('downloadCsv', endpoint.downloadCsv, methods.POST);
const { request: downloadPdfThunk } = generateThunkAndSlice('downloadPdf', endpoint.downloadPdf, methods.POST);

const { request: downloadAllZipThunk } = generateThunkAndSlice('downloadAllZip', endpoint.downloadAllZip, methods.POST);
const { request: downloadImagesThunk } = generateThunkAndSlice('downloadImages', endpoint.downloadImages, methods.POST);
const { slice: getSummaryForBusiness, request: getSummaryForBusinessThunk } = generateThunkAndSlice(
    'getSummaryForBusiness',
    endpoint.getSummaryForBusiness,
    methods.POST
);

const { slice: getSummaryForTripLogs, request: getSummaryForTripLogsThunk } = generateThunkAndSlice(
    'getSummaryForTripLogs',
    endpoint.getSummaryForTripLogs,
    methods.POST
);
const { slice: summaryDetailChart, request: getsummaryDetailChartThunk } = generateThunkAndSlice(
    'summaryDetailChart',
    endpoint.summaryDetailChart,
    methods.POST
);
const { slice: tripDetailChart, request: tripDetailChartThunk } = generateThunkAndSlice(
    'tripDetailChart',
    endpoint.tripDetailChart,
    methods.POST
);
const { slice: getSummaryDetails, request: getSummaryDetailsThunk } = generateThunkAndSlice(
    'getSummaryForBusiness',
    endpoint.getSummaryDetails,
    methods.POST
);
const { slice: getTripSummaryDetails, request: getTripSummaryDetailsThunk } = generateThunkAndSlice(
    'getSummaryForBusiness',
    endpoint.getTripSummaryDetails,
    methods.POST
);
const { slice: getBusinesses, request: getBusinessesThunk } = generateThunkAndSlice(
    'getBusinesses',
    endpoint.getBusinesses,
    methods.POST
);
const { slice: getRentalBusiness, request: getRentalBusinessThunk } = generateThunkAndSlice(
    'getRentalBusiness',
    endpoint.getRentalBusiness,
    methods.POST
);
const { slice: getSummaryLogs, request: getSummaryLogsThunk } = generateThunkAndSlice(
    'getRentalBusiness',
    endpoint.getSummaryLogs,
    methods.POST
);
const { slice: getAllCustomerDeduction, request: getAllCustomerDeductionThunk } = generateThunkAndSlice(
    'getAllCustomerDeduction',
    endpoint.getAllCustomerDeduction,
    methods.POST
);

const { slice: getAllTripLogs, request: getAllTripLogsThunk } = generateThunkAndSlice(
    'getAllTripLogs',
    endpoint.getAllTripLogs,
    methods.POST
);

const { slice: getProfitAndLoss, request: getProfitAndLossThunk } = generateThunkAndSlice(
    'getProfitAndLoss',
    endpoint.getprofitAndLoss,
    methods.POST
);
const { slice: getRentalSchedule, request: getRentalScheduleThunk } = generateThunkAndSlice(
    'getRentalSchedule',
    endpoint.getRentalSchedule,
    methods.POST
);
const { slice: getExpenseByType, request: getExpenseByTypeThunk } = generateThunkAndSlice(
    'getExpenseByType',
    endpoint.getExpenseByType,
    methods.POST
);

export {
    getExpenseByTypeThunk,
    getExpenseByType,
    getProfitAndLoss,
    getProfitAndLossThunk,
    getRentalSchedule,
    getRentalScheduleThunk,
    summaryDetailChart,
    getsummaryDetailChartThunk,
    tripDetailChart,
    tripDetailChartThunk,
    getAllCustomerDeduction,
    getAllCustomerDeductionThunk,
    getAllTripLogs,
    getAllTripLogsThunk,
    deductionsInfo,
    deductionsInfoThunk,
    getTfnDetails,
    getTfnDetailsThunk,
    getRentalProperty,
    getRentalPropertyThunk,
    downloadCsvThunk,
    downloadPdfThunk,
    downloadAllZipThunk,
    downloadImagesThunk,
    getSummaryForBusinessThunk,
    getSummaryForTripLogs,
    getSummaryForTripLogsThunk,
    getSummaryForBusiness,
    getSummaryDetails,
    getSummaryDetailsThunk,
    getTripSummaryDetails,
    getTripSummaryDetailsThunk,
    getBusinesses,
    getBusinessesThunk,
    getRentalBusiness,
    getRentalBusinessThunk,
    getSummaryLogsThunk,
    getSummaryLogs
};
