import React from 'react';

export const TailArrowRight = ({ className }) => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M16.2145 22.6482C16.395 22.5333 23.9965 15.3036 24.177 15.0685C24.5926 14.5435 24.609 13.581 24.2153 12.9958C24.0622 12.7716 16.4551 5.50909 16.2145 5.35597C15.9903 5.21378 15.509 5.21378 15.2684 5.36144C15.0114 5.52003 14.8747 5.77159 14.8747 6.11065C14.8747 6.26925 14.902 6.46065 14.9403 6.53722C14.9731 6.61378 16.5262 8.11769 18.3911 9.88956L21.7762 13.0997L12.895 13.1271C4.45122 13.1544 4.00825 13.1599 3.86606 13.2528C3.3684 13.5755 3.3684 14.4286 3.86606 14.7513C4.00825 14.8442 4.45122 14.8497 12.884 14.8771L21.7543 14.9044L18.4184 18.0763C16.5809 19.8208 15.0332 21.3247 14.9786 21.4122C14.8364 21.6364 14.8418 22.1177 14.984 22.3583C15.0442 22.4567 15.17 22.5825 15.2684 22.6427C15.509 22.7903 15.9903 22.7903 16.2145 22.6482Z"
                fill="url(#paint0_linear_5287_1516)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5287_1516"
                    x1="13.9964"
                    y1="5.25"
                    x2="13.9964"
                    y2="22.7541"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
