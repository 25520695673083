import { Button } from 'components/atoms/buttons/Button';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import TimelineComponent from 'components/organisms/TimelineComponent';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { MultipleUsersIcon } from 'utills/svgs/MultipleUsersIcon';
import { SingleUserIcon } from 'utills/svgs/SingleUserIcon';
import { OfflineClientsTable } from './OfflineClientsTable';
import { AddClient } from 'components/appusers-dash/subcomponents/AddClient';
import { BaseSlider } from 'components/molecules/BaseSlider';
import { ComingSoon } from 'components/molecules/ComingSoon';
import { deleteBusinessClientThunk, uploadedFileClientsThunk } from 'store/client';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import ConfirmationModal from 'utills/confirmationModal';

export const ImportClientInfo = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [add, setAdd] = useState(false);
    const { data: clients } = useSelector((state) => state.uploadedFileClients);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState('');
    const { isLoading } = useSelector((state) => state.global);

    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);

    const [selectedIds, setSelectedIds] = useState([]);

    const [logs, setLogs] = useState(false);

    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings/clients`);
        }
    };

    const handleChangePage = (newPage, perPageCount) => {
        setPage(newPage);
        setPageSize(perPageCount);
        getClients({
            page: newPage,
            pageSize: perPageCount,

            search_term: searchTerm
        });
    };

    const getClients = (payload) => {
        dispatch(setLoader(true));
        dispatch(uploadedFileClientsThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        const payload = {
            page: 1,
            pageSize,
            search_term: searchTerm
        };

        getClients(payload);
    }, []);

    const handleSearchTerm = (value) => {
        setSearchTerm(value);
        const payload = {
            page: 1,
            pageSize,
            search_term: value
        };

        getClients(payload);
    };

    const handleDeleteClients = (clients) => {
        dispatch(setLoader(true));
        setIsConfirmationOpen(false);
        dispatch(deleteBusinessClientThunk({ business_client_id: clients }))
            .then((response) => {
                if (response.payload) {
                    setSelectedClient('');
                    setSelectedIds([]);
                    handleChangePage(1, 10);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const data = ['Download Template', 'Add Client Information', 'Upload Completed File'];

    const handleDeletionId = (id, _isAll) => {
        if (id) {
            setSelectedClient(id);
            setSelectedIds([]);
        } else {
            setSelectedClient('');
        }
        setIsConfirmationOpen(true);
    };

    const handleSelectId = (id) => {
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item === id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(id);
            setSelectedIds(newIds);
        }
    };

    const handleSelectedAll = (e) => {
        const newIds = [];
        const allClients = clients?.data;
        if (e.target.checked) {
            allClients?.forEach((client) => {
                newIds.push(client?.business_client_id);
                setSelectedIds(newIds);
            });
        } else {
            setSelectedIds([]);
        }
    };

    return (
        <div>
            {' '}
            <Jumbotron
                title="Offline Clients Import Export"
                backArrowProp={backArrowProp}
                path={[
                    { title: 'Settings', link: 'settings' },
                    {
                        title: 'Clients',
                        link: 'settings/clients'
                    },
                    {
                        title: 'Offline client import export',
                        link: ''
                    }
                ]}
            />
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={() => handleDeleteClients(selectedIds?.length > 0 ? selectedIds : [selectedClient])}
            />
            {logs && (
                <BaseSlider title={'Logs'} open={logs} setOpen={setLogs}>
                    <ComingSoon />
                </BaseSlider>
            )}
            {add && <AddClient open={add} setOpen={setAdd} handleReload={() => handleChangePage(1, 10)} />}
            <div className="mx-5 md:mx-10">
                {' '}
                <div className=" flex justify-between items-center  primary-fonts mt-5 flex-wrap">
                    <SearchInput
                        width="300px"
                        atChange={(e) => {
                            handleSearchTerm(e.target.value);
                        }}
                        value={searchTerm}
                        placeholder="Search"
                        inputBorder="1px solid #979797"
                    />

                    <div className="flex gap-3 items-center lg:mt-0 mt-5">
                        <Button
                            iconSet={{ leftIcon: SingleUserIcon }}
                            title="Add Client"
                            classes={
                                'bg-third rounded-lg px-5  flex gap-2 items-center h-[3rem] text-white buttons-font-lighter '
                            }
                            atClick={() => setAdd(!add)}
                        />
                        <Button
                            iconSet={{ leftIcon: SingleUserIcon }}
                            title="Bulk Import Via CSV"
                            classes={
                                'bg-linear rounded-lg px-5  flex gap-2 items-center h-[3rem] text-white buttons-font-lighter '
                            }
                            atClick={() => navigate('/offline/clients/bulk-import')}
                        />

                        {selectedIds?.length > 0 && (
                            <Button
                                title={`Delete ${selectedIds?.length}`}
                                atClick={() => handleDeletionId('', true)}
                                classes={
                                    'bg-linear rounded-lg px-5  flex gap-2 items-center h-[3rem] text-white buttons-font-lighter '
                                }
                            />
                        )}
                    </div>
                </div>
                {clients && clients?.count > 0 ? (
                    <div>
                        <OfflineClientsTable
                            users={clients?.data}
                            page={page}
                            pageSize={pageSize}
                            count={clients?.count}
                            handleChangePage={handleChangePage}
                            handleDeletionId={handleDeletionId}
                            handleSelectedAll={handleSelectedAll}
                            handleSelectId={handleSelectId}
                            selectedIds={selectedIds}
                        />
                    </div>
                ) : (
                    <div>
                        {' '}
                        {!isLoading && (
                            <div>
                                {' '}
                                <div className="mt-28 flex justify-center items-center">
                                    <MultipleUsersIcon />
                                </div>
                                <div className="text-center table-title font-poppins mt-5">
                                    Import Client information using Template
                                </div>
                                <div className="flex justify-center items-center">
                                    <TimelineComponent
                                        timeline={data}
                                        sx="buttons-font font-poppins text-blueish !font-medium"
                                    />
                                </div>
                                <div className="flex justify-center items-center mt-12">
                                    <Button
                                        title="Next"
                                        atClick={() => navigate('/offline/clients/bulk-import')}
                                        classes={
                                            'bg-linear rounded-lg px-28  flex gap-2 items-center h-[3rem] text-white buttons-font-lighter '
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
