/* eslint-disable react-hooks/exhaustive-deps */
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import EmailInput from 'components/atoms/Input/EmailInput';
// import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import ProgressBarComponent from 'components/atoms/ProgressBar';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { UserChip } from 'components/atoms/UserChip/UserChip';
import { Button } from 'components/atoms/buttons/Button';
import { InputField } from 'components/atoms/inputs/InputField';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { Loader } from 'components/common/Loader';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import DraftEditorNew from 'components/molecules/DraftEditorNew';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';
import { SearchInput } from 'components/molecules/SearchInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { createClearanceReqThunk } from 'store/JobManager';
import { searchClientsThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { getTemplateDocThunk } from 'store/settings/JobPortal';
// import { validatePhoneNumber } from 'utills/baseValidations';
import { BUSINESS_ID, getProfileName, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { sortItemsAlphabetically } from 'utills/sortAndFilter';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { generateId } from 'utills/uid';

const AddNewClearance = ({ open, setOpen, handleUpdateTable, clients }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { id } = useParams();
    const dispatch = useDispatch();
    const { data: getTemplateDoc } = useSelector((state) => state?.getTemplateDoc);
    const [dataList, setDataList] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [selectedEntriesList, setSelectedEntriesList] = useState([]);

    const [sent_to_name, set_sent_to_name] = useState('');
    const [phone, _setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [email_subject, set_email_subject] = useState('');
    const [message, setMessage] = useState('');

    const optionsData = [
        {
            label: 'Ethical Clearance Request-Individual'
        },
        {
            label: 'Ethical Clearance Request-Company'
        },
        {
            label: 'Ethical Clearance Request-Unit-Trust'
        },
        {
            label: 'Ethical Clearance Request-Partnership'
        },
        {
            label: 'Ethical Clearance Request-Family-Trust'
        }
    ];

    const [query, setQuery] = useState('');
    const [selectedClients, setSelectedClients] = useState([]);
    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    useEffect(() => {
        if (open && !getTemplateDoc) {
            dispatch(getTemplateDocThunk({ business_id }));
        } else {
            setDataList(getTemplateDoc);
        }
    }, [getTemplateDoc, open]);

    const filterData = dataList ? sortItemsAlphabetically(dataList, 'title') : [];

    const filteredDataWithQuery =
        query === ''
            ? filterData
            : filterData.filter((listObj) => {
                  return listObj.title.toLowerCase().includes(query.toLowerCase());
              });

    const { isLoading } = useSelector((state) => state.global);
    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        setCheckedItems([]);
    }, [open]);

    const handleCheckboxChange = (event, obj) => {
        const { checked } = event.target;
        if (checked) {
            setCheckedItems((prevState) => [...prevState, obj]);
        } else {
            setCheckedItems((prevState) => prevState.filter((item) => item !== obj));
        }
    };

    const validatePageOneData = () => {
        const documentsList = [...checkedItems.map((item) => item.title), ...fields.map((item) => item.text)].filter(
            (text) => text !== ''
        );

        if (documentsList?.length === 0) {
            setCurrentStep(0);
            return toastHandler('Select or add at least 1 Request Document Name', TOAST_TYPE_ERROR);
        }

        if (selectedClients?.length === 0) {
            setCurrentStep(0);
            return toastHandler('Select at least 1 Client', TOAST_TYPE_ERROR);
        }

        return true;
    };

    const handleClick = () => {
        if (emailError) {
            setCurrentStep(1);
            return toastHandler('Valid Email is required', TOAST_TYPE_ERROR);
        }

        if (!sent_to_name || sent_to_name === '') {
            setCurrentStep(1);
            return toastHandler('Name is required', TOAST_TYPE_ERROR);
        }
        if (!email || email === '') {
            setCurrentStep(1);
            return toastHandler('Email is required', TOAST_TYPE_ERROR);
        }

        // if (!phone || phone === '') {
        //     setCurrentStep(1);
        //     return toastHandler('Phone is required', TOAST_TYPE_ERROR);
        // }
        // if (phone) {
        //     if (!validatePhoneNumber(phone)) {
        //         return setCurrentStep(1);
        //     }
        // }
        if (!email_subject || email_subject === '') {
            setCurrentStep(1);
            return toastHandler('Email Subject is required', TOAST_TYPE_ERROR);
        }

        if (!message || message === '') {
            setCurrentStep(1);
            return toastHandler('Message is required', TOAST_TYPE_ERROR);
        }

        const payloadJson = {
            business_client: selectedEntriesList?.map((obj) => {
                return {
                    business_client_id: obj?.client?.business_client_id,
                    names: obj?.documentsList
                };
            }),
            business_id: business_id,
            job_id: id,
            sent_to_name: sent_to_name,
            phone: phone,
            email: email,
            email_subject: email_subject,
            note: message
        };

        dispatch(setLoader(true));
        dispatch(createClearanceReqThunk(payloadJson))
            .then((response) => {
                if (response.payload) {
                    setCurrentStep(0);
                    setForceUpdate(true);
                    setEmailError(false);
                    setOpen(false);
                    handleUpdateTable();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleSelectClient = (client) => {
        setSelectedClients([client]);

        if (client?.former_accounting_email_address && client?.former_accounting_email_address !== '') {
            set_sent_to_name(client?.former_accounting_name);
            setEmail(client?.former_accounting_email_address);
        }
    };

    useEffect(() => {
        if (clients?.length > 0) {
            const clientDetails = clients[0];
            setSelectedClients([clientDetails]);
            if (
                clientDetails?.former_accounting_email_address &&
                clientDetails?.former_accounting_email_address !== ''
            ) {
                set_sent_to_name(clientDetails?.former_accounting_name);
                setEmail(clientDetails?.former_accounting_email_address);
            }
        }
    }, []);

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleClientRemove = (index) => {
        const newClients = [...selectedClients];
        newClients.splice(index, 1);

        let lastSelectedClient = newClients.slice(-1);

        set_sent_to_name(lastSelectedClient?.former_accounting_name);
        setEmail(lastSelectedClient?.former_accounting_email_address);

        setSelectedClients(newClients);
    };

    const resetForm = () => {
        setForceUpdate(true);
        setEmailError(false);
        setSelectedClients([]);
        setCheckedItems([]);
        setFields([
            {
                id: generateId(),
                text: ''
            }
        ]);
    };

    const updateSelectedList = (navigate = 0) => {
        const documentsList = [...checkedItems.map((item) => item.title), ...fields.map((item) => item.text)].filter(
            (text) => text !== ''
        );
        if (selectedEntriesList.length === 0 && !validatePageOneData()) {
            return false;
        }

        if (selectedClients.length === 0 && documentsList?.length > 0) {
            setSelectedEntriesList((prevList) => {
                return prevList.map((listObj) => ({
                    ...listObj,
                    documentsList: Array.from(new Set([...listObj.documentsList, ...documentsList]))
                }));
            });
            resetForm();
            setCurrentStep(navigate);
            return false;
        } else {
            const newList = selectedClients?.map((obj) => {
                const newObj = {
                    client: obj,
                    documentsList: documentsList
                };
                return newObj;
            });

            const alreadyExists = selectedEntriesList?.find(
                (client) => client?.client?.business_client_id === newList[0]?.client?.business_client_id
            );
            if (alreadyExists) {
                return toastHandler('This client is already selected', TOAST_TYPE_ERROR);
            }
            setSelectedEntriesList([...selectedEntriesList, ...newList]);
            resetForm();
            setCurrentStep(navigate);
        }
    };

    const removeSelectedEntryDocuments = (EntityIndex, docIndex) => {
        setSelectedEntriesList((prevList) => {
            // Create a new list by mapping through the previous list
            return prevList.map((listObj, index) => {
                // Check if the current entity matches the EntityIndex
                if (index === EntityIndex) {
                    return {
                        ...listObj,
                        // Filter out the document at the specified docIndex
                        documentsList: listObj.documentsList.filter((_, dIndex) => dIndex !== docIndex)
                    };
                }
                // Return the unchanged object for other entities
                return listObj;
            });
        });
    };

    const removeSelectedEntry = (index) => {
        const newList = [...selectedEntriesList];
        newList.splice(index, 1);

        let lastSelectedClient = newList.slice(-1);

        set_sent_to_name(lastSelectedClient?.former_accounting_name);
        setEmail(lastSelectedClient?.former_accounting_email_address);

        setSelectedEntriesList(newList);
    };

    useEffect(() => {
        if (clients?.length > 0) {
            console.info('clients', clients);

            setSelectedClients([clients[0]]);
        }
    }, []);

    return (
        <>
            {isLoading && <Loader />}
            <Slideover
                open={open}
                setOpen={setOpen}
                hideBackArrow
                title={'Clearance Request'}
                titlesx={'!font-semibold'}
                size={'max-w-2xl'}
                footer={
                    <div className="">
                        {currentStep === 1 && (
                            <div className="flex items-center justify-center gap-3 mt-9 mb-4">
                                <Button
                                    title="Back"
                                    classes="border-2 border-primary text-primary  rounded-xl buttons-font-lighter py-2.5 px-4 sm:px-16 font-fira"
                                    atClick={() => setCurrentStep(0)}
                                />
                                <Button
                                    title={`Send Request`}
                                    classes="flex items-center gap-2 py-3 px-4 sm:px-12 !rounded-xl bg-primary child:text-semibold text-white text-[16px]"
                                    atClick={() => handleClick()}
                                />
                            </div>
                        )}
                    </div>
                }
            >
                <div className="grid w-[80%] max-w-[80%] mt-3">
                    <ProgressBarComponent
                        steps={[
                            {
                                step: '1',
                                step_heading: 'Documents'
                            },
                            {
                                step: '2',
                                step_heading: 'Accountant Detail'
                            }
                        ]}
                        targetProperty={'step'}
                        activeStep={currentStep}
                        hideArrows
                        divWidthValue="10"
                        isDragable={false}
                        customProgressWidth="calc(98% - 120px)"
                    />
                </div>
                {currentStep === 0 && (
                    <div className=" mt-5">
                        <div className="mb-5">
                            <div className="grid grid-cols-1 gap-4">
                                {selectedEntriesList.map((entity, index) => (
                                    <div
                                        key={index}
                                        className="relative flex items-start space-x-3 rounded-lg py-5 shadow-sm bg-[#F3EFF6]"
                                    >
                                        <div className="absolute right-5">
                                            <Button
                                                classes={'!rounded-full !p-0'}
                                                iconSet={{ leftIcon: BgDeleteIcon }}
                                                atClick={() => {
                                                    removeSelectedEntry(index);
                                                }}
                                            />
                                        </div>
                                        <div className="flex-shrink-0">
                                            <DefaultProfileViewer
                                                image={entity.client?.image}
                                                width={'45px'}
                                                height={'45px'}
                                                name={getProfileName(entity?.client)}
                                            />
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <p className="text-sm font-medium text-gray-900">
                                                {getProfileName(entity?.client)}
                                            </p>
                                            <p className="truncate text-sm text-gray-500">{entity?.client?.email}</p>
                                            <div>
                                                {entity?.documentsList?.map((obj, ind) => (
                                                    <div key={'reqdoc' + index + '' + ind}>
                                                        <PrimaryCheckbox
                                                            id={`person-${index + '' + ind}`}
                                                            name={`person-${index + '' + ind}`}
                                                            htmlFor={`person-${index + '' + ind}`}
                                                            checked={true}
                                                            onchange={() => {
                                                                console.info('change');
                                                                if (entity?.documentsList?.length === 1) {
                                                                    removeSelectedEntry(index);
                                                                }
                                                                if (entity?.documentsList?.length > 1) {
                                                                    removeSelectedEntryDocuments(index, ind);
                                                                }
                                                            }}
                                                            label={obj}
                                                            sx="mt-2"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-5">
                            <PrimaryLabel>Select Clients</PrimaryLabel>
                            <ComboboxComponentSSR
                                preventLoadingInfo
                                hideSelectedValues
                                fetchDataFunction={(payload) =>
                                    fetchReferencesListFun({
                                        ...payload,
                                        type: '',
                                        formerAccDetail: 'yes'
                                    })
                                }
                                removePreviousSelected
                                onChange={(res) => {
                                    const obj = res[0];
                                    handleSelectClient(obj);
                                }}
                                targetProperty="business_client_id"
                                placeholder="Search by name/reference number/email"
                                sx="rounded-xl !ring-0 border border-secondarybg"
                            />
                            {selectedClients?.length > 0 && (
                                <div className="mt-5 mb-4 flex gap-3 items-center flex-wrap">
                                    {selectedClients?.map((client, index) => (
                                        <div key={index}>
                                            <UserChip obj={client} onClick={() => handleClientRemove(index)} />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="mb-5">
                            <SearchInput atChange={(e) => setQuery(e.target.value)} placeholder={'Search'} />
                        </div>
                        <div>
                            <PrimaryLabel>Prebuild Documents List</PrimaryLabel>
                            <div className="mt-2 max-h-[250px] rounded-xl overflow-auto border border-secondarybg ">
                                <div className="m-2">
                                    {filteredDataWithQuery?.length === 0 && (
                                        <p className="text-sm text-gray-400">Not Found</p>
                                    )}
                                    {filteredDataWithQuery?.length > 0 &&
                                        filteredDataWithQuery?.map((obj, personIdx) => (
                                            <div key={personIdx}>
                                                <PrimaryCheckbox
                                                    id={`person-${obj.template_job_document_id}`}
                                                    name={`person-${obj.template_job_document_id}`}
                                                    htmlFor={`person-${obj.template_job_document_id}`}
                                                    onchange={(event) => handleCheckboxChange(event, obj)}
                                                    checked={checkedItems.includes(obj)}
                                                    label={obj?.title}
                                                    sx="mt-2"
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="mt-5">
                                <PrimaryLabel>Enter Manually</PrimaryLabel>

                                <MultiTextInputAddComponent
                                    placeholder="Enter Required Document Name"
                                    fields={fields}
                                    setFields={setFields}
                                />
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="">
                                <div className="flex items-center justify-center gap-3 mt-9 mb-4">
                                    <Button
                                        title="Save & Add New"
                                        classes="border-2 border-primary text-primary  rounded-xl buttons-font-lighter py-2.5 px-4 sm:px-10 font-fira"
                                        atClick={() => updateSelectedList()}
                                    />
                                    <Button
                                        title={`Next`}
                                        classes="flex items-center gap-2 py-3 !px-20 !rounded-xl bg-primary child:text-semibold text-white text-[16px]"
                                        atClick={() => {
                                            updateSelectedList(1);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {currentStep === 1 && (
                    <div className="default-forms">
                        <div className="grid">
                            <PrimaryLabel>Name</PrimaryLabel>

                            <InputField
                                inputValue={sent_to_name}
                                maxLength={70}
                                allowSpaces={true}
                                onChange={(e) => {
                                    set_sent_to_name(e.target.value);
                                }}
                                name="Name"
                                placeholder="Enter Name"
                                required
                                classes="mt-2"
                            />
                        </div>
                        {/* <div className="grid mt-5">
                            <div>
                                <PhoneInputComponent
                                    value={phone}
                                    title="Phone Number"
                                    placeholder="Enter Phone Number"
                                    titleSx=""
                                    sx="!rounded-xl"
                                    atChange={(phone) => {
                                        setPhone(phone);
                                    }}
                                    required={true}
                                />
                            </div>
                        </div> */}
                        <div className="grid mt-5">
                            <PrimaryLabel>Email</PrimaryLabel>
                            <EmailInput
                                onChange={(value) => setEmail(value)}
                                onError={(e) => {
                                    setEmailError(e);
                                }}
                                placeholder="Enter email"
                                required
                                label=" "
                                classes="mt-2"
                                value={email}
                            />
                        </div>
                        <div className="grid mt-5">
                            <PrimaryLabel>Email Subject</PrimaryLabel>
                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="label"
                                selectedValue={email_subject}
                                valuePropertyName="label"
                                optionsData={optionsData || []}
                                sx="rounded-xl mt-2 py-3 ring-gray-400 !font-normal cursor-pointer"
                                showChipOnly={false}
                                onChangeValue={(obj) => set_email_subject(obj.label)}
                            />
                        </div>
                        <div className="grid mt-5">
                            <PrimaryLabel>Message </PrimaryLabel>
                            <div className="mt-2">
                                <DraftEditorNew
                                    onChange={(val) => setMessage(val)}
                                    initialValue={message}
                                    required={true}
                                    placeholder="Enter description"
                                    forceUpdate={forceUpdate}
                                    setForceUpdate={setForceUpdate}
                                    extraConfigs={{
                                        height: 300
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </Slideover>
        </>
    );
};

export default AddNewClearance;
