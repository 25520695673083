import React from 'react';

export const BgClear = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2563 1.92398C15.4081 1.76681 15.4921 1.55631 15.4902 1.33781C15.4883 1.11931 15.4007 0.910303 15.2462 0.755796C15.0917 0.60129 14.8826 0.513649 14.6641 0.51175C14.4456 0.509852 14.2351 0.593847 14.078 0.745645L8.00047 6.82315L1.92297 0.745645C1.76581 0.593847 1.5553 0.509852 1.33681 0.51175C1.11831 0.513649 0.909299 0.60129 0.754792 0.755796C0.600286 0.910303 0.512645 1.11931 0.510746 1.33781C0.508848 1.55631 0.592843 1.76681 0.744641 1.92398L6.82214 8.00148L0.744641 14.079C0.665049 14.1558 0.601564 14.2478 0.55789 14.3495C0.514216 14.4511 0.491227 14.5605 0.490266 14.6711C0.489304 14.7818 0.510389 14.8915 0.55229 14.9939C0.594191 15.0964 0.656068 15.1894 0.734312 15.2676C0.812556 15.3459 0.905599 15.4078 1.00801 15.4497C1.11043 15.4916 1.22016 15.5126 1.33081 15.5117C1.44146 15.5107 1.55081 15.4877 1.65248 15.4441C1.75415 15.4004 1.8461 15.3369 1.92297 15.2573L8.00047 9.17981L14.078 15.2573C14.2351 15.4091 14.4456 15.4931 14.6641 15.4912C14.8826 15.4893 15.0917 15.4017 15.2462 15.2472C15.4007 15.0927 15.4883 14.8836 15.4902 14.6651C15.4921 14.4466 15.4081 14.2361 15.2563 14.079L9.17881 8.00148L15.2563 1.92398Z"
                fill="#B695F8"
            />
        </svg>
    );
};
