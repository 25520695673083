import React from 'react';
import { useEffect, useState } from 'react';
import 'react-notifications/lib/notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../css-steps/signup-css/stepFour.css';
import {
    getPasswordProficiency,
    hasLowercaseLetter,
    hasMinimumLength,
    hasNumber,
    hasSpecialCharacter,
    hasUppercaseLetter,
    validatePasswords
} from '../../utills/FormValidation';
import { Loader } from '../common/Loader';
import { ValidateLoader } from '../common/ValidateLoader';
import { ValidatePass } from '../common/ValidatePass';

import { setPasswordThunk } from '../../store/auth/slices';
import { setLoader } from '../../store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { OpenEye } from 'utills/svgs/OpenEye';
import { CloseEye } from 'utills/svgs/CloseEye';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { useEncryptedStorage } from '../../Hooks/encryption/useEncryptedStorage';

const StepFour = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { encryptAndSave, clearEncryptedValues } = useEncryptedStorage();

    const isLoading = useSelector((state) => state.global.isLoading);

    const showStepThree = () => {
        props.handleCondition(3);
    };

    const InitialValues = {
        password: '',
        confirmPassword: '',
        agreedToTerms: false
    };

    const [userPassword, setUserPassword] = useState(InitialValues);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordProficiency, setPasswordProficiency] = useState('');

    const [showValidator, setShowValidator] = useState(false);

    const [atleastEightChar, setAtleastEightChar] = useState(false);
    const [oneSmallLetter, setOneSmallLetter] = useState(false);
    const [onecapitalLetter, setOneCapitalLetter] = useState(false);
    const [oneNumber, setOneNumber] = useState(false);
    const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false);
    const [setEye, setSetEye] = useState(false);
    const [confirmEye, setConfirmEye] = useState(false);

    const user = localStorage.getItem('signupUser');
    const securityQuestions = localStorage.getItem('questions');
    const register = user ? JSON.parse(user) : {};
    const questions = securityQuestions ? JSON.parse(securityQuestions) : {};

    const toggleConfirmEye = () => {
        if (userPassword.confirmPassword) {
            setConfirmEye(!confirmEye);
        }
    };
    const toggleSetEye = () => {
        if (userPassword?.password) {
            setSetEye(!setEye);
        }
    };
    const [_errors, setErrors] = useState(true);

    useEffect(() => {
        setErrors(!(atleastEightChar || oneSmallLetter || onecapitalLetter || oneNumber || oneSpecialCharacter));
    }, [atleastEightChar, oneSmallLetter, onecapitalLetter, oneNumber, oneSpecialCharacter]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'password') {
            if (!validatePasswords(value, userPassword.confirmPassword) && userPassword.confirmPassword) {
                setPasswordMismatch(true);
            } else {
                setPasswordMismatch(false);
            }
            setAtleastEightChar(hasMinimumLength(value));
            setOneSmallLetter(hasLowercaseLetter(value));
            setOneCapitalLetter(hasUppercaseLetter(value));
            setOneNumber(hasNumber(value));
            setOneSpecialCharacter(hasSpecialCharacter(value));
            setPasswordProficiency(getPasswordProficiency(value));
            if ('strong' === getPasswordProficiency(value)) {
                setShowValidator(false);
            } else {
                setShowValidator(true);
            }
        }
        if (name === 'confirmPassword') {
            if (value.trim().length === 0) {
                setPasswordMismatch(false);
            } else if (!validatePasswords(userPassword.password, value)) {
                setPasswordMismatch(true);
            } else if (validatePasswords) {
                setPasswordMismatch(false);
            }
        }
        setUserPassword({
            ...userPassword,
            [name]: value
        });
        if (!value) {
            setPasswordProficiency('');
            setShowValidator(false);
        }
    };

    const handleCheckboxChange = (event) => {
        setUserPassword({
            ...userPassword,
            agreedToTerms: event.target.checked
        });
    };

    const handleBlur = () => {
        setShowValidator(false);
    };

    const saveStep = async (value) => {
        await encryptAndSave('LLT', { name: value }, 'session');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!hasMinimumLength(userPassword.password) || !hasMinimumLength(userPassword.confirmPassword)) {
            toastHandler('Passoword must be atleast 8 characters long', TOAST_TYPE_ERROR);
            return;
        }

        if (!hasLowercaseLetter(userPassword.password) || !hasLowercaseLetter(userPassword.confirmPassword)) {
            toastHandler('Passoword must have atleast one lowercase letter', TOAST_TYPE_ERROR);
            return;
        }

        if (!hasUppercaseLetter(userPassword.password) || !hasUppercaseLetter(userPassword.confirmPassword)) {
            toastHandler('Passoword must have atleast one uppercase letter', TOAST_TYPE_ERROR);
            return;
        }

        if (!hasNumber(userPassword.password) || !hasNumber(userPassword.confirmPassword)) {
            toastHandler('Passoword must have atleast one digit', TOAST_TYPE_ERROR);
            return;
        }

        if (!hasSpecialCharacter(userPassword.password) || !hasSpecialCharacter(userPassword.confirmPassword)) {
            toastHandler('Passoword must have atleast one special character', TOAST_TYPE_ERROR);
            return;
        }

        if (!userPassword.password || !userPassword.confirmPassword) {
            toastHandler('Please fill required fields to proceed', 'error');
            return;
        }
        if (passwordMismatch) {
            toastHandler('New passowrd and confirm password should be matched', 'error');
            return;
        }
        if (!userPassword.agreedToTerms) {
            toastHandler('Please accept terms and conditions', 'error');
            return;
        }

        const formData = {
            register,
            security_questions: questions.questions,
            password: userPassword?.confirmPassword,
            password_confirm: userPassword?.confirmPassword,

            agreed_to_term: userPassword.agreedToTerms
        };

        dispatch(setLoader(true));
        dispatch(setPasswordThunk(formData))
            .then((response) => {
                if (response.payload) {
                    clearEncryptedValues('LLT', 'session');

                    props.handlePassword(userPassword?.confirmPassword);
                    saveStep(userPassword?.confirmPassword);

                    props.handleCondition(6);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div>
            {isLoading && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8 ">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px]  lg:max-w-[640px] xl:max-w-[650px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    <div className="text-center  mt-3  card-layout-title-text   ">Set Password</div>
                    <div className=" px-28 mt-[70px] ">
                        <div className="form">
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label className="buttons-font">Password</label>

                                    <div
                                        className={`password-wrapper inputs-font mt-1 ${
                                            passwordProficiency === 'weak'
                                                ? 'week-password-border '
                                                : passwordProficiency === 'medium' || passwordProficiency === 'strong'
                                                ? 'strong-password-border'
                                                : ''
                                        }`}
                                    >
                                        <div className="pass-eyes">
                                            {' '}
                                            <span onClick={toggleSetEye}> {!setEye ? <OpenEye /> : <CloseEye />}</span>
                                        </div>
                                        <input
                                            onBlur={handleBlur}
                                            type={setEye ? 'text' : 'password'}
                                            className={`px-3 validation-password inputs-font !border-none`}
                                            placeholder="Enter your password"
                                            name="password"
                                            value={userPassword.password}
                                            onChange={handleChange}
                                            required
                                        />

                                        <div className="profiency">
                                            {' '}
                                            <span
                                                className={` ${
                                                    passwordProficiency === 'weak'
                                                        ? 'week-password'
                                                        : passwordProficiency === 'medium'
                                                        ? 'medium-password'
                                                        : 'strong-password'
                                                } `}
                                            >
                                                {' '}
                                                {passwordProficiency === '' ? (
                                                    ''
                                                ) : (
                                                    <span> {passwordProficiency} </span>
                                                )}{' '}
                                            </span>{' '}
                                        </div>

                                        {showValidator && (
                                            <div className="password-validator shadow inputs-font">
                                                <div className="flex justify-between items-center">
                                                    <div className="validate-text">
                                                        {' '}
                                                        At least <span className="validate-special">
                                                            {' '}
                                                            8 character{' '}
                                                        </span>{' '}
                                                    </div>

                                                    <div>
                                                        {atleastEightChar ? <ValidatePass /> : <ValidateLoader />}
                                                    </div>
                                                </div>
                                                <div className=" border-t mt-1"></div>
                                                <div className="flex justify-between items-center mt-2">
                                                    <div className="validate-text">
                                                        {' '}
                                                        At least{' '}
                                                        <span className="validate-special">
                                                            {' '}
                                                            one small letters
                                                        </span>{' '}
                                                    </div>

                                                    <div>{oneSmallLetter ? <ValidatePass /> : <ValidateLoader />}</div>
                                                </div>
                                                <div className=" border-t mt-2"></div>

                                                <div className="flex justify-between items-center mt-2">
                                                    <div className="validate-text">
                                                        {' '}
                                                        At least{' '}
                                                        <span className="validate-special">
                                                            {' '}
                                                            one capital letter{' '}
                                                        </span>{' '}
                                                    </div>

                                                    <div>
                                                        {onecapitalLetter ? <ValidatePass /> : <ValidateLoader />}
                                                    </div>
                                                </div>
                                                <div className=" border-t mt-2"></div>

                                                <div className="flex justify-between items-center mt-2">
                                                    <div className="validate-text">
                                                        {' '}
                                                        At least <span className="validate-special">
                                                            {' '}
                                                            one number{' '}
                                                        </span>{' '}
                                                    </div>

                                                    <div>{oneNumber ? <ValidatePass /> : <ValidateLoader />}</div>
                                                </div>
                                                <div className=" border-t mt-2"></div>

                                                <div className="flex justify-between items-center mt-2">
                                                    <div className="validate-text">
                                                        {' '}
                                                        At least{' '}
                                                        <span className="validate-special">
                                                            {' '}
                                                            one special character
                                                        </span>{' '}
                                                    </div>

                                                    <div>
                                                        {oneSpecialCharacter ? <ValidatePass /> : <ValidateLoader />}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {passwordProficiency === 'weak' && (
                                            <div className="text-[#FB4343] flex items-center small-text justify-end error-msg">
                                                Your password is not secure enough
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-5 ">
                                    <div>
                                        <label className={`buttons-font ${showValidator ? 'mx-2' : ''}`}>
                                            Confirm Password
                                        </label>
                                        <div className={`${showValidator ? 'mx-5' : ''} mt-1`}>
                                            <div
                                                className={`confirm-password-wrap inputs-font ${
                                                    userPassword.confirmPassword === userPassword.password &&
                                                    userPassword.confirmPassword &&
                                                    'strong-password-border'
                                                }`}
                                            >
                                                {' '}
                                                {userPassword.confirmPassword === userPassword.password &&
                                                    userPassword.confirmPassword && (
                                                        <div className="matched-pass ">
                                                            {' '}
                                                            <span className="strong-password pr-3">Matched</span>{' '}
                                                        </div>
                                                    )}
                                                <div className="pass-eyes">
                                                    {' '}
                                                    <span onClick={toggleConfirmEye}>
                                                        {' '}
                                                        {!confirmEye ? <OpenEye /> : <CloseEye />}
                                                    </span>
                                                </div>
                                                <input
                                                    type={confirmEye ? 'text' : 'password'}
                                                    className={`px-3 inputs-font !border-none `}
                                                    placeholder="Confirm password"
                                                    name="confirmPassword"
                                                    value={userPassword.confirmPassword}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="error-div small-text mt-1">
                                                {' '}
                                                {passwordMismatch && (
                                                    <span>Confirm Password does not match with password </span>
                                                )}{' '}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`check small-text font-poppins mt-5 flex  gap-3 ${
                                            showValidator ? 'mx-2' : ''
                                        }`}
                                    >
                                        <div>
                                            <input
                                                type="checkbox"
                                                className="mt-1 !rounded-[4px] !border-[#979797]"
                                                checked={userPassword.agreedToTerms}
                                                onChange={handleCheckboxChange}
                                                required
                                            />
                                        </div>
                                        <div>
                                            By Clicking Sign up, you agree to continue to our
                                            <a
                                                href="https://synkli.com.au/terms-and-conditions/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="cursor-pointer text-thirdbg"
                                            >
                                                {' '}
                                                Terms and Conditions
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                href="https://synkli.com.au/privacy-policy/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="cursor-pointer text-thirdbg"
                                            >
                                                {' '}
                                                Privacy Policy
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-28  flex justify-between items-center">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={showStepThree}
                                            className="user-btn-1 buttons-font font-fira"
                                        >
                                            Back
                                        </button>
                                    </div>

                                    <div>
                                        <button type="submit" className="user-btn-2 buttons-font font-fira">
                                            Sign Up
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="text-center us-a small-text font-poppins mb-5">
                            Already have an account ?{' '}
                            <span onClick={() => navigate('/')} className="cursor-pointer us-ab">
                                Sign In
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StepFour;
