import React, { useEffect, useState } from 'react';
import StepOne from '../components/login-steps/StepOne';
import OtpVerification from 'components/login-steps/OtpVerification';
import SecurityQuestions from 'components/login-steps/SecurityQuestions';
import NewPass from 'components/login-steps/NewPass';
import ResetSuccess from 'components/login-steps/ResetSuccess';
import ForgotPass from 'components/login-steps/ForgotPass';
import { useDispatch, useSelector } from 'react-redux';
import { stopHeartbeatInterval } from 'utills/socketEvents';
import GoogleAuthenticatorPage from 'components/second-time-login-pages/GoogleAuthenticatorPage';
import EmailAuthenticatorPage from 'components/second-time-login-pages/EmailAuthenticatorPage';
import LoginSuccessfullPage from 'components/second-time-login-pages/LoginSuccessfullPage';
import { ResetIndicator } from 'components/login-steps/ResetIndicator';
import { GoogleQrCode } from 'components/login-steps/GoogleQrCode';
import { VerifyGoogle } from 'components/login-steps/VerifyGoogle';
import { VerifyPhone } from 'components/login-steps/VerifyPhone';
import { ResetAuthIndicator } from 'components/login-steps/ResetAuthIndicator';
import { VerifyPhoneOTP } from 'components/login-steps/VerifyPhoneOTP';
import { ResetQuestions } from 'components/login-steps/ResetQuestions';
import { AuthenticateEmail } from 'components/login-steps/AuthenticateEmail';

const Login = () => {
    const { intervalId } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    const loginStepper = JSON.parse(sessionStorage.getItem('loginStepper'));
    const [condition, setCondition] = useState(loginStepper ?? 1);

    const handleCondition = (condition) => {
        sessionStorage.setItem('loginStepper', condition);
        setCondition(condition);
    };

    function MyComponent() {
        return (
            <div>
                {condition === 1 && <StepOne handleCondition={handleCondition} />}
                {condition === 2 && <ForgotPass handleCondition={handleCondition} />}

                {condition === 3 && <OtpVerification handleCondition={handleCondition} />}
                {condition === 4 && <SecurityQuestions handleCondition={handleCondition} />}
                {condition === 5 && <NewPass handleCondition={handleCondition} />}
                {condition === 6 && <ResetSuccess handleCondition={handleCondition} />}
                {condition === 7 && <GoogleAuthenticatorPage handleCondition={handleCondition} />}
                {condition === 8 && <EmailAuthenticatorPage handleCondition={handleCondition} />}
                {condition === 9 && <LoginSuccessfullPage handleCondition={handleCondition} />}
                {condition === 10 && <GoogleQrCode handleCondition={handleCondition} />}
                {condition === 11 && <VerifyGoogle handleCondition={handleCondition} />}
                {condition === 12 && <VerifyPhone handleCondition={handleCondition} />}
                {condition === 13 && <ResetAuthIndicator handleCondition={handleCondition} />}
                {condition === 14 && <VerifyPhoneOTP handleCondition={handleCondition} />}
                {condition === 15 && <ResetIndicator handleCondition={handleCondition} />}
                {condition === 16 && <ResetQuestions handleCondition={handleCondition} />}
                {condition === 17 && <AuthenticateEmail handleCondition={handleCondition} />}
            </div>
        );
    }
    useEffect(() => {
        stopHeartbeatInterval(dispatch, intervalId);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.clear();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div>
            <div>{MyComponent()}</div>
        </div>
    );
};

export default Login;
