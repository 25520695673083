import { ImportClientInfo } from 'components/settings/client-settings-components/ImportClientInfo';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';

export const OfflineClientsImportExport = () => {
    return (
        <div>
            <SidebarLayout>
                <RoleBasedGuard module={'Clients'} permissions={['write', 'admin']} sx="h-[50vh]">
                    <ImportClientInfo />
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
