import React from 'react';

const ArrowTurnBack = ({ className }) => {
    return (
        <svg
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M14.5307 9.31503C14.5307 9.96166 14.4087 10.5656 14.1647 11.1268C13.9207 11.688 13.5852 12.1882 13.1582 12.6275C12.7312 13.0667 12.237 13.4022 11.6758 13.634C11.1146 13.8658 10.5046 13.9878 9.84575 14V11.6575C10.1752 11.6575 10.4802 11.5965 10.7608 11.4745C11.0414 11.3525 11.2854 11.1878 11.4928 10.9804C11.7002 10.773 11.871 10.5229 12.0052 10.2301C12.1394 9.93725 12.2004 9.63224 12.1882 9.31503C12.1882 8.98562 12.1272 8.68061 12.0052 8.4C11.8832 8.11939 11.7185 7.87538 11.5111 7.66797C11.3037 7.46057 11.0536 7.28976 10.7608 7.15556C10.468 7.02135 10.163 6.96035 9.84575 6.97255H4.48366L7.44837 9.95556L5.80131 11.6026L0 5.80131L5.80131 0L7.44837 1.64706L4.48366 4.63006H9.84575C10.4924 4.63006 11.0963 4.75207 11.6575 4.99608C12.2187 5.24009 12.719 5.5756 13.1582 6.00261C13.5974 6.42963 13.9329 6.92375 14.1647 7.48497C14.3965 8.04619 14.5185 8.65621 14.5307 9.31503Z"
                fill="#7A8EF6"
            />
        </svg>
    );
};

export default ArrowTurnBack;
