import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setIsConfirmationOpen } from 'store/global/globalReducer';
import { motion } from 'framer-motion';
import warning from '../files/warning.png';
import logout from '../files/logout.png';
import deleteAccount from '../files/delete_account.png';
import { Cross } from './svgs/Cross';
import { cn } from './tailwindUtil';
const ConfirmationModal = ({
    isOpen,
    onClose,
    onConfirm = () => {},
    description,
    confirmbtnTxt,
    cancelBtnTxt,
    title = 'Delete Confirmation',
    type = 'warning',
    sidebarLog,
    customButtons,
    sx
}) => {
    const dispatch = useDispatch();
    if (!isOpen) {
        return null;
    }

    const handleConfirm = () => {
        sidebarLog && dispatch(setIsConfirmationOpen(false));
        onConfirm();
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center z-index confirmation-modal">
            <div className="bg-black opacity-75 fixed inset-0" onClick={onClose}></div>
            <motion.div
                initial={{ y: '-150' }}
                animate={{ y: 0 }}
                transition={{ type: 'spring', duration: 0.3 }}
                className={cn('relative bg-white p-5 max-w-xl  mx-auto rounded-md z-index', sx ? sx : '!w-full')}
            >
                <div onClick={onClose} className="absolute right-5 top-5 cursor-pointer scale-125">
                    <Cross />
                </div>
                <div className="flex flex-col items-center justify-center gap-5 py-10">
                    <div className="flex gap-2 items-center justify-center">
                        <div className="mx-auto">
                            <img
                                src={type === 'logout' ? logout : type === 'delete_account' ? deleteAccount : warning}
                                alt="file"
                                className="rounded-full bg-white cnf-icon-st"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className="flex gap-2 items-center justify-center">
                            <div className=" cnf-title font-bold ">
                                <h5>{title ? title : 'Delete Confirmation'}</h5>
                            </div>
                        </div>
                        <p className="text-xs text-gray-500 text-center desc  mt-0 break-words my-5 pt-5">
                            {description ? description : 'Are you sure you want to delete this ?'}{' '}
                        </p>
                    </div>

                    {customButtons ? (
                        <div className="w-full flex justify-center items-center">{customButtons} </div>
                    ) : (
                        <div className="flex justify-center gap-2">
                            <button className="px-14 py-2 blue-border-btn rounded-md" onClick={onClose}>
                                {cancelBtnTxt ? cancelBtnTxt : 'No'}
                            </button>
                            <button className=" px-14 py-2 ann-btn text-white rounded-md" onClick={handleConfirm}>
                                {confirmbtnTxt ? confirmbtnTxt : 'Yes'}
                            </button>
                        </div>
                    )}
                </div>
            </motion.div>
        </div>
    );
};

ConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default ConfirmationModal;
