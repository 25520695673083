import React from 'react';
import { ChartAndEmployees } from './chart-and-employees/ChartAndEmployees';

export const TasksCompletedCards = () => {
    return (
        <div className="md:mx-10  mx-5 mt-5">
            <div>
                <ChartAndEmployees />
            </div>
        </div>
    );
};
