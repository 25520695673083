import React from 'react';

import coming from '../../files/coming-soon.png';

export const ComingSoon = () => {
    return (
        <div>
            <div className="p-5">
                <div className="flex justify-center items-center">
                    <img src={coming} alt="clock" className="w-[100px] h-[100px]" />
                </div>

                <div className="text-center lg-titile font-poppins text-thirdbg  mt-5">
                    This feature is coming soon.
                </div>
            </div>
        </div>
    );
};
