/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './css/employeeTasks.css';
import { EmployeeProfileSection } from './employee-profile-section/EmployeeProfileSection';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'components/common/Loader';
import { useGetYearlyStatistics } from 'Hooks/useGetYearlyStatistics';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import { employeeInfoAndTasksThunk } from 'store/workspace/workspaceEmployeeTasks';
import { useGetWeeklyStatsEmp } from 'Hooks/useGetWeeklyStatsEmp';
import { useGetEmpEfficiency } from 'Hooks/useGetEmpEfficiency';
import { useGetTasksCountByType } from 'Hooks/useGetTasksCountByType';
import { getAllTaskStatusesThunk, getAllTaskTypeThunk, getEmployeeTaskThunk } from 'store/workspace/workspaceTasks';
import { BUSINESS_ID, SORT_OPTIONS, pendingTaskFilterOptions, taskPriorityOptions } from 'utills/globalVars';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { Menu } from 'components/molecules/Menu';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { SearchInput } from 'components/molecules/SearchInput';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { fetchAllCustomersForTaskThunk } from 'store/client';
import useRoleGuard from 'Hooks/useRoleGuard';
import dayjs from 'dayjs';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { TasksList } from '../../tasks-cards/tasks-completed/tasks/TasksList';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import ComboboxComponent from 'components/atoms/Combobox';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import Pagination from 'components/pagination';

export const EmployeeTasksPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);

    const { data: employee } = useSelector((state) => state.fetchBusinessEmployee);
    const { data: yearlyStats } = useSelector((state) => state.yearlyStatistics);
    const { data: weeklyStats } = useSelector((state) => state.weeklyStatsEmployee);
    const { data: efficiency } = useSelector((state) => state.employeeEfficiency);
    const { data: employeeinfo } = useSelector((state) => state.employeeInfoAndTasks);
    const { data: emps } = useSelector((state) => state.fetchRegisteredEmployees);

    const { data: taskTypes } = useSelector((state) => state.getAllTaskType);
    const { data: statuses } = useSelector((state) => state.getAllTaskStatuses);
    const { data: empInfo } = useSelector((state) => state.getTaskEmpInfo);
    const { isLoading } = useSelector((state) => state.global);

    const [sortAsc, setSortAsc] = useState(false);
    const [allowAction, setAllowAction] = useState(false);
    const { hasPermissions } = useRoleGuard();
    useEffect(() => {
        if (hasPermissions('Tasks', ['admin'])) {
            setAllowAction(true);
        }
    }, []);

    const [filters, setFilters] = useState({
        search_term: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        business_client_id: '',
        task_type_id: '',
        task_status_id: '',
        reference_number: '',
        sort_by: '',
        proirity: '',
        pending_tsak: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(PAGINATION_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState({
        all: 1,
        filtered: 1
    });
    const [tableView, _setTableView] = useState('all');
    const [activeTab, setActiveTab] = useState('all');

    const [allPage, setAllPage] = useState(1);
    const [filteredPage, setFilteredPage] = useState(1);

    const [allPageData, setAllPageData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    useEffect(() => {
        if (allPageData !== null)
            if (activeTab === 'all') {
                if (allPage === 1) {
                    reloadTaskPage();
                } else {
                    setAllPageData(null);
                    setAllPage(1);
                }
            } else {
                if (filteredPage === 1) {
                    updateFilters();
                } else {
                    setFilteredData(null);
                    setFilteredPage(1);
                }
            }
    }, [itemsPerPage]);

    useEffect(() => {
        updateFilters(filters);
    }, [filters]);

    useEffect(() => {
        getAllTasks('all');
    }, [allPage]);

    useEffect(() => {
        const hasValue = Object.values(filters).some((value) => value !== '');
        if (hasValue) {
            getAllTasks('filtered');
        } else {
            setActiveTab(tableView);
        }
    }, [filteredPage, filters]);

    const handlePageChange = () => {
        if (activeTab === 'all') {
            setAllPage(allPage + 1);
        } else if (activeTab === 'filtered') {
            setFilteredPage(filteredPage + 1);
        }
    };

    const getAllTasks = async (type, _hasReset = false) => {
        let page;
        let dataSetter;

        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setAllPageData;

                break;
            case 'filtered':
                page = filteredPage;
                dataSetter = setFilteredData;

                break;
            default:
                return;
        }

        const payload = {
            business_id,
            filter: {
                ...filters,
                start_date:
                    typeof filters?.start_date !== 'string'
                        ? dayjs(filters?.start_date).format('DD-MM-YYYY')
                        : filters?.start_date,
                end_date:
                    typeof filters?.end_date !== 'string'
                        ? dayjs(filters?.end_date).format('DD-MM-YYYY')
                        : filters?.end_date,
                is_completed: '',
                page: page,
                page_size: itemsPerPage,
                employee_id: id
            }
        };
        try {
            dispatch(setSideLoader(true));
            const response = await dispatch(getEmployeeTaskThunk(payload));
            if (response.payload) {
                dataSetter(response.payload.rows);
                setTotalPages({ ...totalPages, [type]: Math.ceil(response.payload?.count / itemsPerPage) });
                dispatch(setSideLoader(false));
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };
    const handleResetValue = (type) => {
        const payloadData = {
            ...filters,
            [type]: ''
        };
        setFilters(payloadData);
        updateFilters(payloadData);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const reloadTaskPage = () => {
        setAllPage(1);
        setAllPageData([]);
        setActiveTab('all');
        getAllTasks('all', true);
    };

    const updateFilters = (_newFilters) => {
        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');
    };

    const clearFilter = () => {
        setFilters({
            search_term: '',
            employee_id: '',
            start_date: '',
            end_date: '',
            business_client_id: '',
            task_type_id: '',
            task_status_id: '',
            reference_number: '',
            sort_by: '',
            proirity: '',
            pending_tsak: ''
        });
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        handleInputChange({
            target: {
                name: 'sort_by',
                value: sortAsc ? 'DESC' : 'ASC'
            }
        });
    };
    const { fetchYearlyStats } = useGetYearlyStatistics();
    const { fetchWeeklyStats } = useGetWeeklyStatsEmp();
    const { fetchEfficiency } = useGetEmpEfficiency();
    const { fetchCountByType } = useGetTasksCountByType();

    const fetchEmployees = () => {
        dispatch(setLoader(true));
        dispatch(fetchRegisteredEmployeesThunk({ task_stats: 'true' })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        fetchYearlyStats(id);
        fetchWeeklyStats(id);
        fetchEfficiency(id);
        fetchCountByType(id);
        employeeProfileAndInfo();
    }, []);

    useEffect(() => {
        if (!emps) {
            fetchEmployees();
        }
    }, [emps]);

    const employeeProfileAndInfo = () => {
        dispatch(setLoader(true));
        dispatch(employeeInfoAndTasksThunk({ employee_id: id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(-1);
        }
    };

    const fetchCustomersList = async (payload) => {
        return await dispatch(fetchAllCustomersForTaskThunk(payload));
    };

    const getAllTypes = () => {
        dispatch(setLoader(true));
        dispatch(getAllTaskTypeThunk({ business_id, page: 1, pageSize: 1000, sort_by: 'ASC' }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const getAllStatuses = () => {
        dispatch(setLoader(true));
        dispatch(getAllTaskStatusesThunk({ business_id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (!taskTypes?.rows) {
            getAllTypes();
        }
    }, [taskTypes]);

    useEffect(() => {
        if (!statuses) {
            getAllStatuses();
        }
    }, [statuses]);

    return (
        <div className="">
            {isLoading && <Loader />}
            {employeeinfo?.length > 0 && (
                <Jumbotron
                    title={`${employeeinfo?.length > 0 && employeeinfo[0]?.first_name} ${employeeinfo[0]?.last_name}`}
                    backArrowProp={backArrowProp}
                    path={[
                        { title: 'Tasks', link: 'tasks' },
                        {
                            title: `Employee's Statistics`,
                            link: ``
                        }
                    ]}
                />
            )}

            <div className="md:mx-10 mx-5">
                <EmployeeProfileSection
                    employee={employee}
                    yearlyStats={yearlyStats}
                    weeklyStats={weeklyStats}
                    efficiency={efficiency}
                    empInfo={empInfo}
                />
            </div>

            <div className="md:mx-10 mx-5 my-5 mt-10">
                <div className="my-5 flex gap-2 items-center">
                    <div>
                        <SearchInput
                            atChange={(e) => handleInputChange(e)}
                            width="300px"
                            placeholder="Search"
                            value={filters.search_term}
                        />
                    </div>

                    <div className="w-[350px]">
                        {' '}
                        <ComboboxComponentSSR
                            fetchDataFunction={fetchCustomersList}
                            selectedValue={filters?.business_client_id}
                            removePreviousSelected
                            inputsx="!font-normal !text-sm"
                            sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                            placeholder="Filter by client"
                            showNameOnly
                            limit={1}
                            onChange={(obj) => {
                                handleInputChange({
                                    target: {
                                        name: 'business_client_id',
                                        value: obj[0]?.business_client_id || ''
                                    }
                                });
                                if (obj?.length === 0) {
                                    handleResetValue('business_client_id');
                                }
                            }}
                            targetProperty="business_client_id"
                        />
                    </div>
                    {allowAction && (
                        <div className="w-[310px]">
                            <ComboboxComponent
                                data={emps}
                                selectedValue={filters?.assigned_by}
                                showNameOnly
                                limit={1}
                                inputsx="!font-normal !text-sm"
                                sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                                placeholder="Assigned By"
                                onChange={(obj) => {
                                    handleInputChange({
                                        target: {
                                            name: 'assigned_by',
                                            value: obj[0]?.user_id || ''
                                        }
                                    });
                                    if (obj?.length === 0) {
                                        handleResetValue('assigned_by');
                                    }
                                }}
                                targetProperty="user_id"
                            />
                        </div>
                    )}
                </div>
                <div className="flex gap-3 items-center flex-wrap">
                    <div className="w-[200px] default-forms">
                        <input
                            type="text"
                            name="reference_number"
                            onChange={handleInputChange}
                            value={filters.reference_number}
                            placeholder="Client Reference No."
                            className="!text-sm !text-gray-900 !border-gray-400 !font-normal px-3 py-1.5 bg-[#FAF9F6] w-full"
                        />
                    </div>

                    <div className="w-[200px]">
                        <SimpleSelectMenu
                            placeholder="Task Status"
                            targetProperty="label"
                            selectedValue={filters?.task_status_id}
                            optionsData={statuses || []}
                            sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                            showChipOnly
                            onChangeValue={(obj) =>
                                handleInputChange({
                                    target: {
                                        name: 'task_status_id',
                                        value: obj ? obj?.task_status_id : ''
                                    }
                                })
                            }
                        />
                    </div>

                    <div className="w-[200px]">
                        <SimpleSelectMenu
                            placeholder="Task type"
                            targetProperty="label"
                            selectedValue={filters?.task_type_id}
                            optionsData={taskTypes?.rows || []}
                            showChipOnly
                            sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                            onChangeValue={(obj) =>
                                handleInputChange({
                                    target: {
                                        name: 'task_type_id',
                                        value: obj ? obj?.task_type_id : ''
                                    }
                                })
                            }
                        />
                    </div>

                    <div className="w-[200px]">
                        <Menu
                            title="Order By Date"
                            iconSet={{ first: SortIcon, second: StepDownIcon }}
                            options={SORT_OPTIONS}
                            gap={1}
                            lastMl={1}
                            isModalDisabled={true}
                            condition={sortAsc}
                            atMenuClick={() => {
                                handleSortByDate();
                            }}
                            sx={'!w-full justify-evenly'}
                            atClick={(_title) => {}}
                            text="sm"
                        />
                    </div>
                    <div className="w-[200px]">
                        <DatePick
                            iconSet={{
                                right: CalendarSmallIco
                            }}
                            onDateChange={(date) => {
                                setFilters({
                                    ...filters,
                                    start_date: date
                                });
                            }}
                            placeholder="Select From"
                            value={filters?.start_date || ''}
                            sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#FAF9F6] hover:bg-[#FAF9F6]"
                        />
                    </div>
                    <div className="w-[200px]">
                        <DatePick
                            iconSet={{
                                right: CalendarSmallIco
                            }}
                            onDateChange={(date) => {
                                setFilters({
                                    ...filters,
                                    end_date: date
                                });
                            }}
                            placeholder="Select To"
                            value={filters?.end_date || ''}
                            sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 w-full bg-[#FAF9F6] hover:bg-[#FAF9F6]"
                        />
                    </div>
                    <div>
                        <div className="w-[200px]">
                            <SimpleSelectMenu
                                placeholder="Task Priority"
                                targetProperty="label"
                                selectedValue={filters?.proirity}
                                optionsData={taskPriorityOptions || []}
                                sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                                onChangeValue={(obj) =>
                                    handleInputChange({
                                        target: {
                                            name: 'proirity',
                                            value: obj ? obj?.label : ''
                                        }
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <div className="w-[200px]">
                            <SimpleSelectMenu
                                placeholder="Pending Tasks"
                                targetProperty="label"
                                selectedValue={filters?.pending_tsak}
                                optionsData={pendingTaskFilterOptions || []}
                                sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                                onChangeValue={(obj) =>
                                    handleInputChange({
                                        target: {
                                            name: 'pending_tsak',
                                            value: obj ? obj?.value : ''
                                        }
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div>
                        {filters.search_term ||
                        filters?.start_date ||
                        filters?.end_date ||
                        filters.task_status_id ||
                        filters?.task_type_id ||
                        filters?.business_client_id ||
                        filters?.reference_number ||
                        filters?.proirity ||
                        filters?.pending_tsak ? (
                            <ClearFilterButton atClick={clearFilter} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>

            <div className="my-5">
                <TasksList
                    tasks={activeTab === 'all' ? allPageData : filteredData}
                    handlePageChange={handlePageChange}
                    tableView={tableView}
                    getAllTasks={getAllTasks}
                    toggleTaskDetails={() => {}}
                    reloadTaskPage={reloadTaskPage}
                    hideActions={true}
                    id={'taskEmployeeTable'}
                />

                <Pagination
                    page={activeTab === 'all' ? Number(allPage) : Number(filteredPage)}
                    pageSize={Number(itemsPerPage)}
                    totalPages={Number(totalPages?.[activeTab] || 1)}
                    onChangePage={(e) => {
                        if (activeTab === 'all') {
                            setAllPage(e);
                        } else {
                            setFilteredPage(e);
                        }
                    }}
                    onCountPerPageChange={(value) => {
                        setItemsPerPage(value);
                        if (activeTab === 'all') {
                            setAllPage(1);
                        } else {
                            setFilteredPage(1);
                        }
                    }}
                    scrollTo={'taskEmployeeTable'}
                />
            </div>
        </div>
    );
};
