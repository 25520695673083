/* eslint-disable react/no-children-prop */
import { Menu } from 'components/molecules/Menu';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import InviteClientsComponent from './subcomponents/InviteClientsComponent';
import {
    deleteClientInviteThunk,
    getInvitedClientsThunk,
    resendClientInviteThunk,
    revokeInvitedClientThunk
} from 'store/client';
import { Loader } from 'components/common/Loader';
import cloneDeep from 'lodash/cloneDeep';
import { EditClient } from './subcomponents/EditClient';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { PersonIcon } from 'utills/svgs/PersonIcon';
import { BaseSlider } from 'components/molecules/BaseSlider';
import { ComingSoon } from 'components/molecules/ComingSoon';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import { SideTimes } from 'utills/svgs/SideTimes';
import { PlaneIcon } from 'utills/svgs/PlaneIcon';
import { Button } from 'components/atoms/buttons/Button';
import { QuadruppleSwitch } from 'components/molecules/QuadruppleSwitch';
import {
    getAllInvitesThunk,
    setCountPerPage,
    setCurrentPage,
    setFilters,
    updateData
} from 'store/custom-reducers/InviteReducer';
import Pagination from 'components/pagination';
import InvitesTableDT from './subcomponents/InvitesTableDT';
import useRoleGuard from 'Hooks/useRoleGuard';

export const InvitesPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { hasPermissions } = useRoleGuard();

    const { isLoading: globalIsLoading } = useSelector((state) => state.global);
    const settings = location.state?.settings;

    const [logs, setLogs] = useState(false);

    //getInvitedClients
    const [inviteClient, setInviteClient] = useState(false);
    const [editClient, setEditClient] = useState(false);

    const business_id = localStorage.getItem('business_id');

    const [sortAsc, setSortAsc] = useState(false);

    const [_activeTab, setActiveTab] = useState('all');
    const [hasMoreContent, setHasMoreContent] = useState({
        all: true,
        filtered: false
    });
    const [allPage, _setAllPage] = useState(1);
    const [filteredPage, setFilteredPage] = useState(1);

    const [allPageData, setAllPageData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modal, setModal] = useState(false);

    const toggleEditClient = () => {
        setEditClient(!editClient);
    };

    const toggleInviteClient = () => {
        setInviteClient(!inviteClient);
    };

    const params = new URLSearchParams(location.search);
    const [isReload, setIsReload] = useState(params.get('reload') || true);

    const { data, currentPage, totalPages, isLoading, itemsPerPage, activeFilter, filters } = useSelector(
        (state) => state.invitesReducer
    );
    const [timeoutId, setTimeoutId] = useState(null);
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);
    const handleChange = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            fetchDataTableData();
        }, 500);
        setTimeoutId(newTimeoutId);
    };

    useEffect(() => {
        if (!isReload || isReload === 'false' || isReload === false) {
            if (!data || activeFilter === true) {
                handleChange();
            }
            setIsReload(true);
        } else {
            handleChange();
        }
    }, [currentPage, itemsPerPage, filters]);

    const fetchDataTableData = async () => {
        const jsonData = {
            business_id,
            page: currentPage,
            page_size: itemsPerPage,
            status: filters?.status,
            search: { text: filters?.search_term },
            order_by: filters.order_by ? filters?.order_by : 'DESC'
        };
        await dispatch(getAllInvitesThunk(jsonData));
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);

        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');

        dispatch(
            setFilters({
                ...filters,
                order_by: sortAsc ? 'DESC' : 'ASC'
            })
        );
    };

    const handleStatus = (value) => {
        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');

        dispatch(
            setFilters({
                ...filters,
                status: value
            })
        );
    };

    const handleSearch = (term) => {
        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');

        dispatch(
            setFilters({
                ...filters,
                search_term: term
            })
        );
    };

    const fetchClientsList = async (type) => {
        let page;
        let dataSetter;
        let pageSetter;
        let stateData;
        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setAllPageData;
                stateData = allPageData;
                pageSetter = 'all';
                break;

            case 'filtered':
                page = filteredPage;
                dataSetter = setFilteredData;
                stateData = filteredData;
                pageSetter = 'filtered';
                break;
            default:
                return;
        }

        const jsonData = {
            page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            status: filters?.status,
            search: { text: filters?.search_term },
            order_by: filters.order_by ? filters?.order_by : 'desc',
            business_id
        };

        setHasMoreContent({ ...hasMoreContent, [pageSetter]: true });

        try {
            const response = await dispatch(getInvitedClientsThunk(jsonData));
            if (response.payload) {
                dataSetter([...(stateData || []), ...response.payload.data]);
                const responseLength = response.payload.data.length;
                setHasMoreContent({ ...hasMoreContent, [pageSetter]: responseLength >= Number(PAGINATION_PAGE_SIZE) });
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const [managerActions, _setManagerActions] = useState([
        {
            title: 'Invite',
            classes:
                'bg-thirdbg px-7 min-h-[3rem] flex gap-3 items-center rounded-xl text-white font-fira buttons-font-lighter  text-normal  px-3 rounded-xl',
            type: 'ann-btn',
            iconSet: { leftIcon: PersonIcon },
            onAction: () => {
                if (hasPermissions('Clients', ['write', 'admin'])) {
                    setInviteClient(true);
                } else {
                    toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
                }
            }
        },
        {
            title: 'View Logs',
            classes: 'ann-btn text-normal flex gap-2 items-center px-12 rounded-xl',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                setLogs(!logs);
            }
        },
        {
            title: 'Bulk Invite Via CSV',
            classes: 'ann-btn text-normal flex gap-2 items-center px-3 rounded-xl',
            type: 'ann-btn',
            iconSet: { leftIcon: PersonIcon },
            onAction: () => {
                if (hasPermissions('Clients', ['write', 'admin'])) {
                    navigate('/bulk-invite');
                } else {
                    toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
                }
            }
        }
    ]);

    const handleAddClientAction = (client) => {
        const newData = cloneDeep(data);
        newData?.unshift(client);
        dispatch(updateData(newData));
    };

    const handleUpdateClientAction = (client) => {
        setActiveTab('all');
        const newData = cloneDeep(data);
        const findclient = newData?.find((item) => item?.client_invite_id === client?.client_invite_id);
        const clientIndex = newData?.indexOf(findclient);
        if (client) {
            newData[clientIndex] = client;
            dispatch(updateData(newData));
        }
    };

    const handleResendinvitation = (client_invite_id) => {
        const newData = cloneDeep(data);
        const payload = {
            client_invite_id
        };

        dispatch(setLoader(true));
        dispatch(resendClientInviteThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const client = newData?.find((item) => item?.client_invite_id === client_invite_id);
                    const clientIndex = newData?.indexOf(client);
                    if (client) {
                        newData[clientIndex] = {
                            ...client,
                            status: 'revoke'
                        };
                        newData?.unshift(response.payload);

                        dispatch(updateData(newData));
                    }
                    toastHandler('Invite resent successfully!', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleDeleteinvitation = (client_invite_id) => {
        const newData = cloneDeep(data);

        const payload = {
            client_invite_id
        };

        dispatch(setLoader(true));
        dispatch(deleteClientInviteThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const client = newData?.find((item) => item?.client_invite_id === client_invite_id);
                    const clientIndex = newData?.indexOf(client);

                    if (client) {
                        newData.splice(clientIndex, 1);
                        dispatch(updateData(newData));
                    }
                    toastHandler('Client Deleted successfully!', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleRevoke = (client_invite_id) => {
        const newData = cloneDeep(data);

        const payload = {
            client_invite_id
        };

        dispatch(setLoader(true));
        dispatch(revokeInvitedClientThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const client = newData?.find((item) => item?.client_invite_id === client_invite_id);
                    const clientIndex = newData?.indexOf(client);

                    if (client) {
                        newData[clientIndex] = {
                            ...client,
                            status: 'revoke'
                        };
                        dispatch(updateData(newData));
                    }

                    toastHandler('Invite revoked successfully!', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleClearFilter = () => {
        setActiveTab('all');
        dispatch(
            setFilters({
                ...filters,
                status: '',
                search_term: '',
                order_by: ''
            })
        );
    };

    const settingsPath = [
        { title: 'Settings', link: 'settings' },
        {
            title: 'Invited Clients',
            link: ''
        }
    ];

    const clientsPath = [
        { title: 'Clients', link: 'clients' },
        {
            title: 'Invited Clients',
            link: ''
        }
    ];

    return (
        <div>
            <Jumbotron
                title="Invited Clients"
                directory={`Clients`}
                actionsRequired={true}
                actions={managerActions}
                path={settings ? settingsPath : clientsPath}
                alignCss="!top-12 !md:top-14"
            />
            {globalIsLoading && <Loader />}
            {logs && (
                <BaseSlider title={'Logs'} open={logs} setOpen={setLogs}>
                    <ComingSoon />
                </BaseSlider>
            )}

            {modal && (
                <DefaultDialog
                    children={
                        <div className="relative">
                            <div className="absolute right-0 top-0 cursor-pointer" onClick={() => setModal(!modal)}>
                                <SideTimes />
                            </div>
                            <div className="flex justify-center items-center pt-12">
                                <PlaneIcon />
                            </div>

                            <div className=" mt-4 table-title text-blueish font-poppins flex justify-center items-center">
                                Congratulation!
                            </div>
                            <div className="buttons-font-lighter font-poppins text-center mt-3 mx-5">
                                Your invitation have been sent successfully
                            </div>

                            <div className="mx-10 flex justify-center items-center my-8">
                                <Button
                                    atClick={() => setModal(!modal)}
                                    title="Ok"
                                    classes="ann-btn rounded-xl px-16 buttons-font font-fira w-full flex justify-center items-center"
                                />
                            </div>
                        </div>
                    }
                    p="!rounded-2xl !p-2"
                    open={modal}
                    setOpen={setModal}
                    width="md:max-w-[350px]"
                />
            )}

            <div className="mx-5 md:mx-10 flex justify-between gap-5 items-center flex-wrap">
                <div>
                    <SearchInput
                        width="320px"
                        atChange={(e) => {
                            handleSearch(e.target.value);
                        }}
                        defaultValue={filters?.search_term}
                        placeholder="Search"
                        name="search_term"
                    />
                </div>
                <div className="flex gap-2 items-center">
                    {filters?.status && <ClearFilterButton atClick={handleClearFilter} />}

                    <div className="w-[200px]">
                        {' '}
                        <SimpleSelectMenu
                            placeholder="Filter by Status"
                            targetProperty="title"
                            selectedValue={filters?.status}
                            valuePropertyName={'label'}
                            optionsData={
                                [
                                    { title: 'Invitation Accepted', label: 'accepted' },
                                    { title: 'Invitation Expired', label: 'expired' },
                                    { title: 'Invitation Error', label: 'invitation_error' },
                                    { title: 'Invitation Revoked', label: 'revoke' },
                                    { title: 'Invitation Sent', label: 'pending' }
                                ] || []
                            }
                            sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                            onChangeValue={(obj) => handleStatus(obj.label)}
                        />
                    </div>
                    <Menu
                        title="Sort By"
                        iconSet={{ first: SortIcon, second: StepDownIcon }}
                        gap={2}
                        lastMl={5}
                        isModalDisabled={true}
                        condition={sortAsc}
                        border=""
                        atMenuClick={() => {
                            handleSortByDate();
                        }}
                        text="gray-900"
                        sx="!border-gray-400 !rounded-[12px] customFilters"
                    />
                </div>
            </div>
            <div className="flex justify-center w-full items-center mt-5">
                <QuadruppleSwitch
                    titleOne="All Clients"
                    titleTwo="Prospective"
                    titleThree="Active"
                    titleFour="Inactive"
                    titleFive="Favourites"
                    border="1px solid #BBBBBB"
                    activeTab={'invites'}
                    atClick={(title) => {
                        navigate(`/app/users?type=${title}&reload=false`);
                    }}
                />
            </div>

            <InvitesTableDT
                data={data}
                isLoading={isLoading}
                handleResendinvitation={handleResendinvitation}
                handleRevoke={handleRevoke}
                handleDeleteinvitation={handleDeleteinvitation}
                toggleInviteClient={toggleInviteClient}
                toggleEditClient={toggleEditClient}
            />

            <Pagination
                page={Number(currentPage)}
                pageSize={Number(itemsPerPage)}
                totalPages={Number(totalPages)}
                onChangePage={(e) => dispatch(setCurrentPage(e))}
                onCountPerPageChange={(value) => dispatch(setCountPerPage(value))}
            />

            {inviteClient && (
                <InviteClientsComponent
                    open={inviteClient}
                    setOpen={setInviteClient}
                    fetchClientsList={fetchClientsList}
                    handleAddClientAction={handleAddClientAction}
                    setModal={setModal}
                />
            )}

            {editClient && (
                <EditClient
                    open={editClient}
                    setOpen={setEditClient}
                    fetchClientsList={fetchClientsList}
                    handleUpdateClientAction={handleUpdateClientAction}
                />
            )}
        </div>
    );
};
