/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';

const SimpleCombobox = ({
    titleSx,
    title,
    placeholder,
    targetProperty,
    optionsData,
    onChangeValue,
    removeSelected,
    selectedValue,
    valuePropertyName,
    sx,
    hasError,
    _bg,
    _w,
    disabled
}) => {
    const [selected, setSelected] = useState(null);
    const [query, setQuery] = useState('');

    const [data, setData] = useState([]);

    const filteredPeople =
        optionsData && query === ''
            ? optionsData
            : optionsData.filter((obj) => {
                  return obj[targetProperty]?.toLowerCase()?.includes(query?.toLowerCase());
              });

    useEffect(() => {
        if (selectedValue === '') {
            setSelected(null);
        } else {
            if (valuePropertyName) {
                const selectedObj = data.find((obj) => {
                    if (!obj) {
                        console.error('Object is null or undefined');
                        return false;
                    }
                    if (!(valuePropertyName in obj)) {
                        console.error('valuePropertyName does not exist in the object');
                        return false;
                    }
                    if (obj[valuePropertyName] === selectedValue) {
                        return true;
                    }
                });

                setSelected(selectedObj || null);
            }
        }
    }, [selectedValue, data]);

    useEffect(() => {
        setData(optionsData);
    }, [optionsData]);

    const handleOnChane = (e) => {
        setSelected(e);

        onChangeValue(e);
        if (removeSelected) {
            const filteredData = data?.filter((item) => item[targetProperty] !== e[targetProperty]);
            setData(filteredData);
        }
    };

    return (
        <>
            <Combobox as="div" value={selected} onChange={handleOnChane} disabled={disabled}>
                {title && (
                    <Combobox.Label
                        className={cn('block  text-sm font-medium leading-6 text-gray-900 ', titleSx ? titleSx : '')}
                    >
                        {title}
                    </Combobox.Label>
                )}

                <div className={cn('relative', title ? 'mt-2' : '')}>
                    <Combobox.Input
                        className={cn(
                            'w-full rounded-md border-0 bg-transparent py-1.5 pl-3 pr-10 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-third sm:text-sm sm:leading-6 placeholder:text-[0.9rem] placeholder:text-[#979797]',
                            hasError ? '!border-red-400 ' : ''
                        )}
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(person) => (person ? person[targetProperty] : '')}
                        placeholder={placeholder || ''}
                    />
                    <Combobox.Button
                        className={cn(
                            hasError ? '!ring-red-400' : '',
                            'absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none',
                            selected ? 'text-gray-900' : 'text-gray-500',
                            sx ? sx : ''
                        )}
                    >
                        <ChevronUpDownIcon className="h-5 w-5 text-[#979797]" aria-hidden="true" />
                    </Combobox.Button>

                    {filteredPeople.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredPeople.map((person) => (
                                <Combobox.Option
                                    key={person[valuePropertyName]}
                                    value={person}
                                    className={({ active }) =>
                                        cn(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active ? 'bg-third text-white' : 'text-gray-900'
                                        )
                                    }
                                >
                                    {({ active, selected }) => (
                                        <>
                                            <span className={cn('block truncate', selected && 'font-semibold')}>
                                                {person[targetProperty] || 'undefined'}
                                            </span>

                                            {selected && (
                                                <span
                                                    className={cn(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active ? 'text-white' : 'text-third'
                                                    )}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
        </>
    );
};

export default SimpleCombobox;
