import React from 'react';
import { useEffect, useState } from 'react';
import './css/organizationalSettings.css';
import { useDispatch, useSelector } from 'react-redux';
import { handleEditTaskType, setLoader } from 'store/global/globalReducer';
import { archiveTaskTypeThunk, getAllTaskTypeThunk } from 'store/workspace/workspaceTasks';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { formateDate } from 'utills/moment';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import ConfirmationModal from 'utills/confirmationModal';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';

const OStasksType = ({ searchTerm, typesUpdated, toggleEditType, sortAsc }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.getAllTaskType);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [task_type_id, setTaskTypeId] = useState('');
    const { hasPermissions } = useRoleGuard();

    const filtereData = data?.rows.filter((task) => task.label.toLowerCase().includes(searchTerm.toLowerCase()));
    const [_typeLoader, setTypeLoader] = useState(false);

    const getAllTypes = (payload) => {
        dispatch(setLoader(true));
        dispatch(getAllTaskTypeThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        const payload = {
            business_id,
            order_by: sortAsc ? 'ASC' : 'DESC',
            pageSize: 1000
        };
        getAllTypes(payload);
    }, [typesUpdated, sortAsc]);

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        setTypeLoader(false);
        const payload = { business_id, task_type_id };
        dispatch(archiveTaskTypeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const payload = {
                        business_id,
                        page: 1,
                        pageSize: 1000,
                        sort_by: sortAsc ? 'ASC' : 'DESC'
                    };
                    getAllTypes(payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setTypeLoader(false);
            });
    };
    const deleteType = (task_type_id) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        setIsConfirmationOpen(true);
        setTaskTypeId(task_type_id);
    };

    const updateType = (type) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        dispatch(handleEditTaskType(type));
        toggleEditType();
    };

    return (
        <div className=" flex flex-col w-full ">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />

            <div className="px-4 sm:px-6 lg:px-8 bg-white rounded-xl">
                <div className="mt-3 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr className="divide-x divide-gray-200">
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-4 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Task Type Names
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3.5 text-left text-md font-semibold text-gray-900"
                                        >
                                            Created Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-4 py-3.5 text-left text-md font-semibold text-gray-900"
                                        >
                                            Last Updated
                                        </th>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-4 text-left text-md font-semibold flex justify-center items-center text-gray-900 sm:pr-0"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {filtereData?.map((person) => (
                                        <tr key={person?.label} className="divide-x divide-gray-200">
                                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-md font-medium text-gray-900 sm:pl-0">
                                                {person?.label}
                                            </td>
                                            <td className="whitespace-nowrap p-4 text-md text-gray-500">
                                                {formateDate(person?.created_at)}
                                            </td>
                                            <td className="whitespace-nowrap p-4 text-md text-gray-500">
                                                {formateDate(person?.updated_at)}
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-4 text-md text-gray-500 sm:pr-0">
                                                {' '}
                                                <div className="flex gap-2 items-center justify-center">
                                                    <div className="flex gap-2">
                                                        <div
                                                            onClick={() => updateType(person)}
                                                            className="cursor-pointer"
                                                        >
                                                            <BgEditIcon />
                                                        </div>
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => deleteType(person.task_type_id)}
                                                        >
                                                            <BgDeleteIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OStasksType;
