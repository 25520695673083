import React from 'react';

import EmailSuccessPage from '../../../../components/dashboard-components/auth/EmailSuccessPage';
const EmailSuccess = () => {
    return (
        <div>
            <EmailSuccessPage />
        </div>
    );
};

export default EmailSuccess;
