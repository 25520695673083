import React from 'react';

export const BgEditIcon = ({ className }) => {
    return (
        <>
            <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <rect opacity="0.05" width="42" height="42" rx="21" fill="#B695F8" />
                <path
                    d="M27.5471 17.051V20.8544H25.7547V17.9914V17.8414H25.6047H20.5238V12.9696V12.8196H20.3738H13.0504H12.9004V12.9696V29.0392V29.1892H13.0504H19.1776V30.8979H13.0504C12.5131 30.8979 12.0581 30.7159 11.6771 30.3501C11.2954 29.9838 11.108 29.5494 11.108 29.0392V12.9696C11.108 12.4594 11.2954 12.025 11.6771 11.6587C12.0581 11.2929 12.5131 11.1109 13.0504 11.1109H21.3596L27.5471 17.051ZM25.9186 23.7483L26.8263 24.6197L22.8857 28.3783L22.8393 28.4226V28.4868V29.5414V29.6914H22.9893H24.0878H24.1478L24.1913 29.6499L28.1414 25.8822L29.0237 26.7292L24.6813 30.8979H21.57V27.923L25.9186 23.7483ZM30.6534 25.1647L29.2403 26.5213L28.2456 25.5664L28.1421 25.467L27.2602 24.6204L27.1471 24.5119L26.1352 23.5404L27.5395 22.1923C27.7 22.0382 27.9054 21.9579 28.1679 21.9579C28.4305 21.9579 28.6358 22.0382 28.7964 22.1923L30.6534 23.975C30.8135 24.1287 30.895 24.3229 30.895 24.5698C30.895 24.8168 30.8135 25.011 30.6534 25.1647Z"
                    fill="#B695F8"
                    stroke="white"
                    strokeWidth="0.3"
                />
            </svg>

            {/* <svg
                width="36"
                height="32"
                viewBox="0 0 36 30"
                // fill="none"
                fill="#B695F8"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <rect opacity="0.1" width="36" height="30" rx="15" />
                <path
                    d="M11.875 25C11.3594 25 10.9181 24.8043 10.5512 24.413C10.1837 24.021 10 23.55 10 23V7C10 6.45 10.1837 5.979 10.5512 5.587C10.9181 5.19567 11.3594 5 11.875 5H19.375L25 11V15H23.125V12H18.4375V7H11.875V23H17.5V25H11.875ZM23.4062 17.525L24.4141 18.6L20.7812 22.45V23.5H21.7656L25.3984 19.65L26.3828 20.7L22.3516 25H19.375V21.825L23.4062 17.525ZM26.3828 20.7L23.4062 17.525L24.7656 16.075C24.9375 15.8917 25.1562 15.8 25.4219 15.8C25.6875 15.8 25.9062 15.8917 26.0781 16.075L27.7422 17.85C27.9141 18.0333 28 18.2667 28 18.55C28 18.8333 27.9141 19.0667 27.7422 19.25L26.3828 20.7Z"
                    // fill="#B695F8"
                />
            </svg> */}
        </>
    );
};
