/* eslint-disable react/style-prop-object */
import React from 'react';

export const CreateTaskIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#656565"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M9.09091 19.092C7.83333 19.092 6.65152 18.8532 5.54546 18.3756C4.43939 17.898 3.47727 17.2505 2.65909 16.4329C1.84091 15.6153 1.19333 14.6532 0.716365 13.5465C0.239395 12.4399 0.000607211 11.258 1.15075e-06 10.0011C-0.00060491 8.74409 0.238183 7.56228 0.716365 6.45561C1.19455 5.34894 1.84212 4.38682 2.65909 3.56925C3.47606 2.75167 4.43818 2.1041 5.54546 1.62652C6.65273 1.14894 7.83455 0.910156 9.09091 0.910156C10.0758 0.910156 11.0076 1.0541 11.8864 1.34197C12.7652 1.62985 13.5758 2.03137 14.3182 2.54652L13 3.88743C12.4242 3.52379 11.8106 3.23985 11.1591 3.03561C10.5076 2.83137 9.81818 2.72894 9.09091 2.72834C7.07576 2.72834 5.3597 3.43682 3.94273 4.85379C2.52576 6.27076 1.81758 7.98652 1.81818 10.0011C1.81879 12.0156 2.52727 13.7317 3.94364 15.1492C5.36 16.5668 7.07576 17.275 9.09091 17.2738C9.57576 17.2738 10.0455 17.2283 10.5 17.1374C10.9545 17.0465 11.3939 16.9177 11.8182 16.7511L13.1818 18.1374C12.5606 18.4405 11.9091 18.6753 11.2273 18.842C10.5455 19.0086 9.83333 19.092 9.09091 19.092ZM15.4545 17.2738V14.5465H12.7273V12.7283H15.4545V10.0011H17.2727V12.7283H20V14.5465H17.2727V17.2738H15.4545ZM7.81818 14.1829L3.95455 10.3192L5.22727 9.04652L7.81818 11.6374L16.9091 2.52379L18.1818 3.79652L7.81818 14.1829Z" />
        </svg>
    );
};
