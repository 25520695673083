import React from 'react';

export const VerifyPersonIcon = ({ className }) => {
    return (
        <svg
            width="169"
            height="150"
            viewBox="0 0 169 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M5.82812 142.875C5.82812 110.122 32.4744 83.4531 65.25 83.4531C98.0027 83.4531 124.672 110.099 124.672 142.875C124.672 146.26 121.932 149 118.547 149C115.183 149 112.422 146.258 112.422 142.875C112.422 116.87 91.2554 95.7031 65.25 95.7031C39.2446 95.7031 18.0781 116.87 18.0781 142.875C18.0781 146.26 15.3383 149 11.9531 149C8.56791 149 5.82812 146.26 5.82812 142.875ZM100.953 36.6808C100.952 56.3776 84.9243 72.3828 65.25 72.3828C45.5519 72.3828 29.5469 56.377 29.5469 36.7031C29.5469 17.0054 45.5523 1 65.25 1C84.9241 1 100.93 17.0054 100.953 36.6808ZM88.6797 36.7031C88.6797 23.7758 78.1773 13.2734 65.25 13.2734C52.3227 13.2734 41.8203 23.7758 41.8203 36.7031C41.8203 49.6304 52.3227 60.1328 65.25 60.1328C78.1773 60.1328 88.6797 49.6304 88.6797 36.7031Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M131.181 47.2985C134.455 44.2338 139.545 44.2338 142.819 47.2985L145.655 49.9523C146.454 50.7002 147.498 51.1325 148.592 51.1687L152.473 51.2971C156.955 51.4454 160.555 55.0446 160.703 59.527L160.831 63.4085C160.868 64.5024 161.3 65.546 162.048 66.3452L164.702 69.1806C167.766 72.455 167.766 77.545 164.702 80.8194L162.048 83.6548C161.3 84.454 160.868 85.4976 160.831 86.5916L160.703 90.473C160.555 94.9554 156.955 98.5546 152.473 98.7029L148.592 98.8313C147.498 98.8675 146.454 99.2998 145.655 100.048L142.819 102.702C139.545 105.766 134.455 105.766 131.181 102.702L128.345 100.048C127.546 99.2998 126.502 98.8675 125.409 98.8313L121.527 98.7029C117.045 98.5546 113.445 94.9554 113.297 90.473L113.169 86.5916C113.133 85.4976 112.7 84.454 111.952 83.6548L109.299 80.8194C106.234 77.545 106.234 72.455 109.299 69.1806L111.952 66.3452C112.7 65.546 113.133 64.5024 113.169 63.4085L113.297 59.527C113.445 55.0446 117.045 51.4454 121.527 51.2971L125.409 51.1687C126.502 51.1325 127.546 50.7002 128.345 49.9523L131.181 47.2985ZM149.46 68.3668C150.215 67.5605 150.173 66.2948 149.367 65.54C148.561 64.7851 147.295 64.8268 146.54 65.6332L133.021 80.0736L127.46 74.1332C126.705 73.3268 125.44 73.2851 124.633 74.04C123.827 74.7948 123.785 76.0605 124.54 76.8668L131.561 84.3668C131.939 84.7708 132.468 85 133.021 85C133.575 85 134.103 84.7708 134.481 84.3668L149.46 68.3668Z"
                fill="#B695F8"
            />
        </svg>
    );
};
