/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    getPasswordProficiency,
    hasLowercaseLetter,
    hasMinimumLength,
    hasNumber,
    hasSpecialCharacter,
    hasUppercaseLetter,
    validatePasswords
} from 'utills/FormValidation';

import { ValidatePass } from 'components/common/ValidatePass';
import { ValidateLoader } from 'components/common/ValidateLoader';
import { Button } from 'components/atoms/buttons/Button';
import { CloseEye } from 'utills/svgs/CloseEye';
import { OpenEye } from 'utills/svgs/OpenEye';
import { authUpdatepasswordThunk } from 'store/auth/slices';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { Loader } from 'components/common/Loader';

export const SetPassword = ({ handleCondition }) => {
    const dispatch = useDispatch();
    const InitialValues = {
        currentPassword: '',
        password: '',
        confirmPassword: '',

        agreedToTerms: false
    };

    const [userPassword, setUserPassword] = useState(InitialValues);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [passwordProficiency, setPasswordProficiency] = useState('');
    const [showValidator, setShowValidator] = useState(false);

    const [atleastEightChar, setAtleastEightChar] = useState(false);
    const [oneSmallLetter, setOneSmallLetter] = useState(false);
    const [onecapitalLetter, setOneCapitalLetter] = useState(false);
    const [oneNumber, setOneNumber] = useState(false);
    const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false);
    const [setEye, setSetEye] = useState(false);
    const [confirmEye, setConfirmEye] = useState(false);

    const user = localStorage.getItem('signupUser');
    const securityQuestions = localStorage.getItem('questions');
    const register = user ? JSON.parse(user) : {};
    const questions = securityQuestions ? JSON.parse(securityQuestions) : {};

    const isLoading = useSelector((state) => state.global.isLoading);

    const [currentEye, setCurrentEye] = useState(false);

    const toggleConfirmEye = () => {
        if (userPassword.confirmPassword) {
            setConfirmEye(!confirmEye);
        }
    };

    const toggleCurrentEye = () => {
        setCurrentEye(!currentEye);
    };
    const toggleSetEye = () => {
        if (userPassword?.password) {
            setSetEye(!setEye);
        }
    };
    const [errors, setErrors] = useState(true);

    useEffect(() => {
        setErrors(!(atleastEightChar || oneSmallLetter || onecapitalLetter || oneNumber || oneSpecialCharacter));
    }, [atleastEightChar, oneSmallLetter, onecapitalLetter, oneNumber, oneSpecialCharacter]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'password') {
            if (!validatePasswords(value, userPassword.confirmPassword) && userPassword.confirmPassword) {
                setPasswordMismatch(true);
            } else {
                setPasswordMismatch(false);
            }
            setAtleastEightChar(hasMinimumLength(value));
            setOneSmallLetter(hasLowercaseLetter(value));
            setOneCapitalLetter(hasUppercaseLetter(value));
            setOneNumber(hasNumber(value));
            setOneSpecialCharacter(hasSpecialCharacter(value));
            setPasswordProficiency(getPasswordProficiency(value));
            if ('strong' === getPasswordProficiency(value)) {
                setShowValidator(false);
            } else {
                setShowValidator(true);
            }
        }
        if (name === 'confirmPassword') {
            if (value.trim().length === 0) {
                setPasswordMismatch(false);
            } else if (!validatePasswords(userPassword.password, value)) {
                setPasswordMismatch(true);
            } else if (validatePasswords) {
                setPasswordMismatch(false);
            }
        }

        setUserPassword({
            ...userPassword,
            [name]: value
        });
        if (!value) {
            setPasswordProficiency('');
            setShowValidator(false);
        }
    };

    const handleBlur = () => {
        setShowValidator(false);
    };

    const handleSubmit = () => {
        if (!userPassword.password || !userPassword.confirmPassword || !userPassword?.currentPassword) {
            toastHandler('Please fill required fields to proceed', 'error');
            return;
        }
        if (passwordMismatch) {
            toastHandler('New passowrd and confirm password should be matched', 'error');
            return;
        }

        const payload = {
            password: userPassword.currentPassword,
            newPassword: userPassword.password,
            confirm_password: userPassword.confirmPassword
        };

        dispatch(setLoader(true));
        dispatch(authUpdatepasswordThunk(payload))
            .then((response) => {
                if (response.payload) {
                    handleCondition(3);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="flex min-h-[60vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] bg-white shadow rounded-xl sm:rounded-2xl  py-4">
                {isLoading && <Loader />}{' '}
                <div className="card-layout-title-text text-center mt-5   ">Change Password</div>
                <div className="  mx-28  mt-4">
                    <div className=" mb-5">
                        <form className="mt-16 ">
                            <div>
                                <div className="mb-4">
                                    {' '}
                                    <div>
                                        <label className="buttons-font font-poppins">Current Password </label>
                                    </div>
                                    <div className="confirm-password-wrap mt-1">
                                        <input
                                            type={currentEye ? 'text' : 'password'}
                                            className="px-3 !border-none"
                                            maxLength={30}
                                            placeholder="Enter your current password"
                                            name="currentPassword"
                                            onChange={handleChange}
                                        />

                                        <div className="pass-eyes">
                                            {' '}
                                            <span onClick={toggleCurrentEye}>
                                                {' '}
                                                {!currentEye ? <OpenEye /> : <CloseEye />}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <label className="buttons-font font-poppins">New Password</label>

                                <div className="password-wrapper mt-1">
                                    <div className="pass-eyes">
                                        {' '}
                                        <span onClick={toggleSetEye}> {!setEye ? <OpenEye /> : <CloseEye />}</span>
                                    </div>
                                    <input
                                        onBlur={handleBlur}
                                        type={setEye ? 'text' : 'password'}
                                        className={`px-3 validation-password !border-none`}
                                        placeholder="Enter your password"
                                        name="password"
                                        value={userPassword.password}
                                        onChange={handleChange}
                                        required
                                    />

                                    <div className="profiency">
                                        {' '}
                                        <span
                                            className={` ${
                                                passwordProficiency === 'weak'
                                                    ? 'week-password'
                                                    : passwordProficiency === 'medium'
                                                    ? 'medium-password'
                                                    : 'strong-password'
                                            } `}
                                        >
                                            {' '}
                                            {passwordProficiency === '' ? (
                                                ''
                                            ) : (
                                                <span> {passwordProficiency} </span>
                                            )}{' '}
                                        </span>{' '}
                                    </div>

                                    {showValidator && (
                                        <div className="password-validator shadow">
                                            <div className="flex justify-between items-center">
                                                <div className="validate-text">
                                                    {' '}
                                                    At least <span className="validate-special">
                                                        {' '}
                                                        8 character{' '}
                                                    </span>{' '}
                                                </div>

                                                <div>{atleastEightChar ? <ValidatePass /> : <ValidateLoader />}</div>
                                            </div>
                                            <div className=" border-t mt-1"></div>
                                            <div className="flex justify-between items-center mt-2">
                                                <div className="validate-text">
                                                    {' '}
                                                    At least{' '}
                                                    <span className="validate-special"> one small letters</span>{' '}
                                                </div>

                                                <div>{oneSmallLetter ? <ValidatePass /> : <ValidateLoader />}</div>
                                            </div>
                                            <div className=" border-t mt-2"></div>

                                            <div className="flex justify-between items-center mt-2">
                                                <div className="validate-text">
                                                    {' '}
                                                    At least{' '}
                                                    <span className="validate-special"> one capital letter </span>{' '}
                                                </div>

                                                <div>{onecapitalLetter ? <ValidatePass /> : <ValidateLoader />}</div>
                                            </div>
                                            <div className=" border-t mt-2"></div>

                                            <div className="flex justify-between items-center mt-2">
                                                <div className="validate-text">
                                                    {' '}
                                                    At least <span className="validate-special"> one number </span>{' '}
                                                </div>

                                                <div>{oneNumber ? <ValidatePass /> : <ValidateLoader />}</div>
                                            </div>
                                            <div className=" border-t mt-2"></div>

                                            <div className="flex justify-between items-center mt-2">
                                                <div className="validate-text">
                                                    {' '}
                                                    At least{' '}
                                                    <span className="validate-special">
                                                        {' '}
                                                        one special character
                                                    </span>{' '}
                                                </div>

                                                <div>{oneSpecialCharacter ? <ValidatePass /> : <ValidateLoader />}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mt-5 ">
                                <div>
                                    <label className={`${showValidator ? 'mx-2' : ''} buttons-font font-poppins`}>
                                        Confirm Password
                                    </label>
                                    <div className={`mt-1 ${showValidator ? 'mx-5' : ''}`}>
                                        <div className="confirm-password-wrap ">
                                            {' '}
                                            {userPassword.confirmPassword === userPassword.password &&
                                                userPassword.confirmPassword && (
                                                    <div className="matched-pass ">
                                                        {' '}
                                                        <span className="strong-password">Matched</span>{' '}
                                                    </div>
                                                )}
                                            <div className="pass-eyes">
                                                {' '}
                                                <span onClick={toggleConfirmEye}>
                                                    {' '}
                                                    {!confirmEye ? <OpenEye /> : <CloseEye />}
                                                </span>
                                            </div>
                                            <input
                                                type={confirmEye ? 'text' : 'password'}
                                                className={`px-3 !border-none`}
                                                placeholder="Confirm password"
                                                name="confirmPassword"
                                                value={userPassword.confirmPassword}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="error-div !text-xs mt-1">
                                            {' '}
                                            {passwordMismatch && (
                                                <span>Confirm Password does not match with password </span>
                                            )}{' '}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="flex justify-between mt-20 items-center gap-5 flex-wrap ">
                            <Button
                                atClick={() => handleCondition(1)}
                                title="Cancel"
                                classes="border border-secondarybg !rounded-lg h-[3rem] text-md primary-fonts text-secondarybg px-14 rounded-md py-2"
                            />

                            <Button
                                atClick={() => handleSubmit()}
                                title="Confirm"
                                classes="ann-btn px-14 !rounded-lg py-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
