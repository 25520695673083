import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { generateBackupCodesAccessThunk, login } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';

export const AuthenticationBackupCodes = ({ handleCondition }) => {
    const dispatch = useDispatch();
    const [codes, setCodes] = useState(null);
    const contentRef = useRef(null);
    const secondContentRef = useRef(null);

    const [codesCopied, setCodesCopied] = useState(false);
    const [codesDownloaded, setCodesDoanloaded] = useState(false);

    const firstLineCodes = codes?.length > 0 && codes?.slice(0, 5);
    const lastLineCodes = codes?.length > 0 && codes?.slice(5, 10);

    const { data: loginData } = useSelector((state) => state.login);

    const handleGenerateCodes = () => {
        const codeConfig = {
            user_id: loginData?.user_id,
            user_type: 'employee'
        };
        dispatch(setLoader(true));

        dispatch(generateBackupCodesAccessThunk(codeConfig))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    setCodes(response.payload);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        handleGenerateCodes();
    }, []);

    //download content
    const handleDownload = () => {
        const paragraphs = contentRef.current.getElementsByTagName('p');
        const secondP = secondContentRef.current.getElementsByTagName('p');
        let content = '';
        for (let i = 0; i < paragraphs.length; i++) {
            content += paragraphs[i].textContent + '\n';
        }

        for (let i = 0; i < secondP.length; i++) {
            content += secondP[i].textContent + '\n';
        }

        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'content.txt';
        downloadLink.click();
        URL.revokeObjectURL(url);
        setCodesDoanloaded(true);
    };

    //copy content

    const handleMerge = () => {
        const paragraphs1 = contentRef.current.getElementsByTagName('p');
        const paragraphs2 = secondContentRef.current.getElementsByTagName('p');
        let content = '';
        for (let i = 0; i < paragraphs1.length; i++) {
            content += paragraphs1[i].textContent + '\n';
        }
        for (let i = 0; i < paragraphs2.length; i++) {
            content += paragraphs2[i].textContent + '\n';
        }

        navigator.clipboard
            .writeText(content)
            .then(() => {
                toastHandler('Codes copied to clipboard', 'success');
                setCodesCopied(true);
            })
            .catch((error) => {
                console.error('Failed to copy content to clipboard:', error);
            });
    };

    return (
        <div className="flex min-h-[60vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px] bg-white shadow rounded-xl sm:rounded-2xl  py-4 ">
                <div>
                    <div className="mx-10">
                        <div className="text-center  mt-3 card-layout-title-text">Backup Codes</div>

                        <div>
                            <div className=" mt-3 text-sm backup-text">
                                You have the option to copy or download the backup codes. These backup codes will be
                                necessary if you need to reset your Google 2FA later on.
                            </div>
                        </div>

                        <div className="main-codes  text-xl grid grid-cols-2 mx-20 mt-7 gap-16 shadow-md py-3">
                            <div ref={contentRef} className="justify-self-end ">
                                {firstLineCodes &&
                                    firstLineCodes.map((code) => <p key={code.user_backup_code_id}>{code.code}</p>)}
                            </div>
                            <div ref={secondContentRef} className=" ">
                                {lastLineCodes &&
                                    lastLineCodes.map((code) => <p key={code.user_backup_code_id}>{code.code}</p>)}
                            </div>
                        </div>

                        <div className="flex justify-center items-center gap-3 mt-7">
                            <button onClick={handleMerge} className=" blue-border-btn px-10 py-2 rounded-lg">
                                Copy Codes
                            </button>

                            <button onClick={handleDownload} className="btn-2 px-12 py-2 rounded-md">
                                Download
                            </button>
                        </div>

                        <div className=" backup-text mt-7 text-sm">
                            You can utilize any one of the codes provided above to reset your Google 2FA.
                        </div>

                        <div className="flex justify-between items-center  mb-8 mt-8 ">
                            <button
                                onClick={handleGenerateCodes}
                                className="ann-btn px-3 cursor-pointer py-2 rounded-lg"
                            >
                                Get New Codes
                            </button>{' '}
                            <button
                                disabled={!codesCopied && !codesDownloaded ? true : false}
                                onClick={() => {
                                    dispatch(
                                        login.actions.handleUpdate({
                                            ...loginData,
                                            google_authenticator_2fa: true
                                        })
                                    );
                                    handleCondition(0);
                                }}
                                className={`btn-2 px-12  py-2 rounded-md ${
                                    !codesCopied && !codesDownloaded ? 'opacity-50' : ''
                                }`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
