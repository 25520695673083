import React from 'react';

export const RedTickIcon = () => {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.8891 0.046875C8.08594 0.318748 5.51719 1.52812 3.525 3.51562C1.66406 5.37656 0.525 7.65 0.107813 10.3594C-0.0046875 11.1141 -0.0046875 12.8859 0.107813 13.6406C0.525 16.3453 1.65469 18.6047 3.51563 20.475C5.37656 22.3359 7.65 23.475 10.3594 23.8922C11.1141 24.0047 12.8859 24.0047 13.6406 23.8922C16.3453 23.475 18.6047 22.3453 20.475 20.4844C22.3359 18.6234 23.475 16.35 23.8922 13.6406C24.0047 12.8859 24.0047 11.1141 23.8922 10.3594C23.475 7.65469 22.3453 5.39531 20.4844 3.525C18.6375 1.67344 16.3359 0.520311 13.6875 0.117188C13.1016 0.0281239 11.4938 -0.00937653 10.8891 0.046875ZM13.5234 1.99219C15.6891 2.32969 17.5969 3.3 19.1484 4.85156C20.7141 6.41719 21.6703 8.31094 22.0172 10.5234C22.0734 10.8891 22.0969 11.3203 22.0969 12C22.0969 12.6797 22.0734 13.1109 22.0172 13.4766C21.6703 15.6891 20.7141 17.5828 19.1484 19.1484C17.5828 20.7141 15.6891 21.6703 13.4766 22.0172C12.7688 22.1297 11.2313 22.1297 10.5234 22.0172C8.31094 21.6703 6.41719 20.7141 4.85156 19.1484C3.28594 17.5828 2.32969 15.6891 1.98281 13.4766C1.87031 12.7687 1.87031 11.2312 1.98281 10.5234C2.32969 8.31094 3.28594 6.41719 4.85156 4.85156C6.57656 3.12656 8.625 2.17031 11.1797 1.90312C11.5594 1.86562 13.0688 1.92188 13.5234 1.99219Z"
                    fill="#008C51"
                />
                <path
                    d="M16.2656 7.99701C16.1625 8.03919 15.0609 9.10794 13.2328 10.9361L10.3594 13.8001L9.15467 12.6001C8.48905 11.9439 7.87967 11.372 7.80467 11.3298C7.32186 11.0861 6.77811 11.2595 6.53905 11.7376C6.39842 12.0189 6.38905 12.2767 6.51561 12.5345C6.57186 12.6611 7.18124 13.2986 8.23592 14.3486C9.5203 15.6283 9.90467 15.9845 10.05 16.0314C10.289 16.1064 10.65 16.0642 10.8422 15.9329C11.0672 15.7783 17.2312 9.61888 17.4047 9.37513C17.5359 9.18763 17.5547 9.12669 17.5547 8.85013C17.5547 8.57826 17.5359 8.51263 17.414 8.33919C17.1515 7.96888 16.6687 7.82357 16.2656 7.99701Z"
                    fill="#008C51"
                />
            </svg>
        </div>
    );
};
