import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';
import { TelephonicDirectorySvg } from 'utills/svgs/TelephonicDirectorySvg';

export const WorkspaceSettings = () => {
    return (
        <div>
            <SidebarLayout>
                <Jumbotron
                    title="Workspace Settings"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },
                        {
                            link: '',
                            title: 'Workspace Settings'
                        }
                    ]}
                />

                <div className="md:mx-10 mx-5 mt-5">
                    <div className="flex items-center flex-wrap gap-5 ">
                        <GridCard
                            text="Telephonic Directory"
                            iconSet={{ icon: TelephonicDirectorySvg }}
                            link={'/telephonic-directory-settings'}
                        />
                    </div>
                </div>
            </SidebarLayout>
        </div>
    );
};
