import React, { useState } from 'react';
import { AnnDetails } from 'components/workplace-content/announcement-content/AnnDetails';
import { EditAnnoncement } from 'components/workplace-content/announcement-content/EditAnnoncement';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const AnnouncementDetails = () => {
    const [showEditAnn, setShowEditAnn] = useState(false);
    const [annUpdated, setAnnUpdated] = useState(false);

    const toggleEditAnn = () => {
        setShowEditAnn(!showEditAnn);
    };

    const toggleAnnUpdate = () => {
        setAnnUpdated(!annUpdated);
    };
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showEditAnn && (
                    <EditAnnoncement
                        open={showEditAnn}
                        setOpen={setShowEditAnn}
                        toggleEditAnn={toggleEditAnn}
                        toggleAnnUpdate={toggleAnnUpdate}
                    />
                )}
                <RoleBasedGuard module={'Announcements'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    <div>
                        <AnnDetails toggleEditAnn={toggleEditAnn} annUpdated={annUpdated} />
                    </div>{' '}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
