import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { EmailOtp } from './EmailOtp';
import { SetPassword } from './SetPassword';
import { SuccessPage } from './SuccessPage';
import { ChangePassowordEmail } from './ChangePassowordEmail';

export const ChangePassword = () => {
    const navigate = useNavigate();

    const changePasswordStepper = JSON.parse(sessionStorage.getItem('changePasswordStepper'));
    const [condition, setCondition] = useState(changePasswordStepper ?? 0);

    const handleCondition = (condition) => {
        sessionStorage.setItem('changePasswordStepper', condition);
        setCondition(condition);
    };

    function MyComponent() {
        return (
            <div>
                {condition === 0 && <ChangePassowordEmail handleCondition={handleCondition} />}

                {condition === 1 && <EmailOtp handleCondition={handleCondition} />}
                {condition === 2 && <SetPassword handleCondition={handleCondition} />}
                {condition === 3 && <SuccessPage handleCondition={handleCondition} />}
            </div>
        );
    }

    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/security-and-authentication`);
        }
    };
    return (
        <div>
            <SidebarLayout>
                <Jumbotron
                    title="Change Password"
                    path={[
                        { title: 'Settings', link: 'settings' },
                        {
                            title: 'Security and Authentication',
                            link: 'security-and-authentication'
                        },
                        {
                            title: 'Change Password',
                            link: 'change-password'
                        }
                    ]}
                    backArrowProp={backArrowProp}
                />
                <div>{MyComponent()}</div>
            </SidebarLayout>
        </div>
    );
};
