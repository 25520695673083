/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { weeklyStatsCompletedThunk } from 'store/workspace/workspaceCompletedTasks';
import {
    VictoryChart,
    VictoryLine,
    VictoryAxis,
    VictoryScatter,
    VictoryLabel,
    VictoryVoronoiContainer,
    VictoryTheme
} from 'victory';

export const WeeklyChart = () => {
    const { data: weeklyStats } = useSelector((state) => state.weeklyStatsCompleted);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(weeklyStatsCompletedThunk());
    }, []);

    const data = {
        currentWeekStats: weeklyStats?.currentWeekStats || [],
        lastWeekStats: weeklyStats?.lastWeekStats || []
    };

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return (
        <div className=" p-2  all-emp-wrapper ">
            {' '}
            <div className="lg-title mx-3 mt-5 font-semibold">Weekly Statistics</div>
            <div className="flex items-center gap-10 mx-3 mt-3">
                <div className="flex items-center gap-2">
                    <div className="this-week"></div>
                    <div className="week-label">This Week</div>
                </div>
                <div className="flex items-center gap-2">
                    <div className="pre-week"></div>
                    <div className="week-label">Previous Week</div>
                </div>
            </div>
            <div className="mt-5">
                {' '}
                {data ? (
                    <VictoryChart
                        theme={VictoryTheme.grayscale}
                        width={600}
                        height={300}
                        padding={{ top: 50, bottom: 35, left: 40, right: 40 }}
                        containerComponent={
                            <VictoryVoronoiContainer labels={({ datum }) => `x: ${datum.x}, y: ${datum.y}`} />
                        }
                    >
                        <VictoryAxis
                            tickValues={dayNames.map((_, index) => index + 1)}
                            tickFormat={dayNames}
                            style={{
                                grid: { stroke: '#ccc', strokeDasharray: '5, 5' },
                                tickLabels: { fontSize: 14, fill: '#979797' },
                                axis: { stroke: 'none' }
                            }}
                        />

                        <VictoryAxis
                            tickValues={dayNames.map((_, index) => index + 1)}
                            tickFormat={dayNames}
                            style={{
                                tickLabels: { fontSize: 14, fill: '#979797' },
                                axis: { stroke: 'none' }
                            }}
                        />
                        <VictoryAxis
                            dependentAxis
                            style={{
                                tickLabels: { fill: '#979797' },
                                axis: { stroke: 'none' }
                            }}
                        />
                        <VictoryLine
                            animate={{
                                duration: 1500,
                                onLoad: { duration: 500 }
                            }}
                            data={data?.currentWeekStats}
                            style={{
                                data: { stroke: '#0D1B37' },
                                parent: { border: '1px solid #979797' }
                            }}
                        />
                        <VictoryLine
                            animate={{
                                duration: 2000,
                                onLoad: { duration: 1000 }
                            }}
                            data={data?.lastWeekStats}
                            style={{
                                data: { stroke: '#b695f8' }
                            }}
                        />
                        <VictoryScatter
                            data={[...data?.currentWeekStats, ...data?.lastWeekStats]}
                            style={{
                                data: { fill: 'black' }
                            }}
                            size={5}
                            labelComponent={<VictoryLabel dy={10} style={{ fill: 'black', margin: '' }} />}
                        />
                    </VictoryChart>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};
