import React from 'react';
import { SearchInput } from 'components/molecules/SearchInput';
const DeductionOperation = ({ searchByItem }) => {
    return (
        <div className="md:px-10 px-5">
            <SearchInput
                width="300px"
                atChange={(e) => {
                    searchByItem(e.target.value);
                }}
                placeholder="Search"
            />
        </div>
    );
};

export default DeductionOperation;
