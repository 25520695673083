/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './css/tasksReports.css';
import { NumberCards } from './number-cards/NumberCards';
import { ReportsBarCharts } from './reports-bar-charts/ReportsBarCharts';
import { LineChartAndInfo } from './linechart-and-info/LineChartAndInfo';
import { useDispatch, useSelector } from 'react-redux';
import { yearlyStatisticsThunk } from 'store/workspace/workspaceEmployeeTasks';
import {
    countByTypeThunk,
    getAllTaskStatusesThunk,
    getAllTaskTypeThunk,
    getFullTasksThunk,
    getTaskReportsInfoThunk
} from 'store/workspace/workspaceTasks';
import {
    APP_ENVIRONMENT,
    BASE_URL,
    BUSINESS_ID,
    CSRF_TOKEN,
    SORT_OPTIONS,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    taskPriorityOptions
} from 'utills/globalVars';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { Menu } from 'components/molecules/Menu';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { useState } from 'react';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import ComboboxComponent from 'components/atoms/Combobox';
import { fetchAllCustomersForTaskThunk } from 'store/client';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { toastHandler } from 'responseHanlder';
import useRoleGuard from 'Hooks/useRoleGuard';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import dayjs from 'dayjs';
import { TasksList } from '../tasks-cards/tasks-completed/tasks/TasksList';
import { BaseSlider } from 'components/molecules/BaseSlider';
import { ComingSoon } from 'components/molecules/ComingSoon';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import Pagination from 'components/pagination';
import { getCookie } from 'utills/encryption/cryptoUtils';

export const TasksReportsPage = ({ toggleTaskDetails, _tasksUpdated }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);
    const { data: taskTypes } = useSelector((state) => state.getAllTaskType);
    const { data: statuses } = useSelector((state) => state.getAllTaskStatuses);
    const { data: emps } = useSelector((state) => state.fetchRegisteredEmployees);
    const [logs, setLogs] = useState(false);
    const [sortAsc, setSortAsc] = useState(false);
    const { data: loginData } = useSelector((state) => state.login);
    const { hasPermissions } = useRoleGuard();
    const [allowAction, setAllowAction] = useState(false);
    useEffect(() => {
        if (hasPermissions('Tasks', ['admin'])) {
            setAllowAction(true);
        }
    }, []);

    const [filters, setFilters] = useState({
        search_term: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        business_client_id: '',
        task_type_id: '',
        task_status_id: '',
        reference_number: '',
        sort_by: '',
        assigned_by: '',
        proirity: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(PAGINATION_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState({
        all: 1,
        filtered: 1
    });
    const [tableView, _setTableView] = useState('all');
    const [activeTab, setActiveTab] = useState('all');

    const [allPage, setAllPage] = useState(1);
    const [filteredPage, setFilteredPage] = useState(1);

    const [allPageData, setAllPageData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    useEffect(() => {
        if (allPageData !== null)
            if (activeTab === 'all') {
                if (allPage === 1) {
                    reloadTaskPage();
                } else {
                    setAllPageData(null);
                    setAllPage(1);
                }
            } else {
                if (filteredPage === 1) {
                    updateFilters();
                } else {
                    setFilteredData(null);
                    setFilteredPage(1);
                }
            }
    }, [itemsPerPage]);

    useEffect(() => {
        updateFilters(filters);
    }, [filters]);

    useEffect(() => {
        getAllTasks('all');
    }, [allPage]);

    useEffect(() => {
        const hasValue = Object.values(filters).some((value) => value !== '');
        if (hasValue) {
            getAllTasks('filtered');
        } else {
            setActiveTab(tableView);
        }
    }, [filteredPage, filters]);

    const handlePageChange = () => {
        if (activeTab === 'all') {
            setAllPage(allPage + 1);
        } else if (activeTab === 'filtered') {
            setFilteredPage(filteredPage + 1);
        }
    };

    const getAllTasks = async (type, _hasReset = false) => {
        let page;
        let dataSetter;

        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setAllPageData;
                break;
            case 'filtered':
                page = filteredPage;
                dataSetter = setFilteredData;
                break;
            default:
                return;
        }

        const payload = {
            business_id,
            filter: {
                ...filters,
                start_date:
                    typeof filters?.start_date !== 'string'
                        ? dayjs(filters?.start_date).format('DD-MM-YYYY')
                        : filters?.start_date,
                end_date:
                    typeof filters?.end_date !== 'string'
                        ? dayjs(filters?.end_date).format('DD-MM-YYYY')
                        : filters?.end_date,
                is_completed: '',
                page: page,
                page_size: itemsPerPage,
                employee_id: hasPermissions('Tasks', ['admin']) ? filters?.employee_id : loginData?.user_id
            }
        };
        try {
            dispatch(setSideLoader(true));
            const response = await dispatch(getFullTasksThunk(payload));
            if (response.payload) {
                dataSetter(response.payload.rows);
                setTotalPages({ ...totalPages, [type]: Math.ceil(response.payload?.count / itemsPerPage) });
                dispatch(setSideLoader(false));
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handleResetValue = (type) => {
        const payloadData = {
            ...filters,
            [type]: ''
        };
        setFilters(payloadData);
        updateFilters(payloadData);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };

    const reloadTaskPage = () => {
        setAllPage(1);
        setAllPageData([]);
        setActiveTab('all');
        getAllTasks('all', true);
    };

    const updateFilters = (_newFilters) => {
        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');
    };

    const clearFilter = () => {
        setFilters({
            search_term: '',
            employee_id: '',
            start_date: '',
            end_date: '',
            business_client_id: '',
            task_type_id: '',
            task_status_id: '',
            reference_number: '',
            sort_by: '',
            assigned_by: '',
            proirity: ''
        });
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        handleInputChange({
            target: {
                name: 'sort_by',
                value: sortAsc ? 'DESC' : 'ASC'
            }
        });
    };

    const fetchyearlyStats = () => {
        dispatch(setLoader(true));

        dispatch(yearlyStatisticsThunk()).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const fetchCountByType = () => {
        dispatch(setLoader(true));
        dispatch(countByTypeThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const fetchEmployees = () => {
        dispatch(setLoader(true));
        dispatch(fetchRegisteredEmployeesThunk({ task_stats: 'true' })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const getReportsInfo = () => {
        dispatch(setLoader(true));
        const payload = {
            business_id: business_id,
            filter: {
                search_term: '',
                employee_id: '',
                start_date: '',
                task_type_id: '',
                task_status_id: '',
                reference_number: '',
                sort_by: '',
                is_completed: '',
                assigned_by: '',
                proirity: ''
            }
        };
        dispatch(getTaskReportsInfoThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        fetchyearlyStats();
        fetchCountByType();
        getReportsInfo();
        fetchEmployees();
    }, []);

    const getAllTypes = () => {
        dispatch(setLoader(true));
        dispatch(getAllTaskTypeThunk({ business_id, page: 1, pageSize: 1000, sort_by: 'ASC' })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const getAllStatuses = () => {
        dispatch(setLoader(true));
        dispatch(getAllTaskStatusesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        if (!taskTypes?.rows) {
            getAllTypes();
        }
    }, [taskTypes]);

    useEffect(() => {
        if (!statuses) {
            getAllStatuses();
        }
    }, [statuses]);

    const fetchCustomersList = async (payload) => {
        return await dispatch(fetchAllCustomersForTaskThunk(payload));
    };

    const handleDownloadPdf = async () => {
        let csrfToken = getCookie(CSRF_TOKEN);

        const payload = {
            business_id: business_id,
            filter: {
                is_completed: '',
                search_term: '',
                employee_id: '',
                start_date: '',
                end_date: '',
                business_client_id: '',
                task_type_id: '',
                task_status_id: '',
                reference_number: '',
                sort_by: sortAsc ? 'DESC' : 'ASC'
            }
        };
        dispatch(setLoader(true));
        await fetch(`${BASE_URL}/workspace/task/export-pdf`, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
                app_environment: APP_ENVIRONMENT,
                'Content-Type': 'application/json',
                'x-csrf-token': csrfToken
            },
            credentials: 'include',
            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'Reports';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    toastHandler('File Has been downloaded!', TOAST_TYPE_SUCCESS);
                } else {
                    toastHandler('Something went wrong!', TOAST_TYPE_ERROR);
                }
            })
            .catch((_error) => {
                toastHandler('Something went wrong', TOAST_TYPE_ERROR);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const actions = [
        {
            title: 'Export PDF',
            classes: 'flex gap-2 items-center px-7 !rounded-xl ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                handleDownloadPdf();
            }
        },
        {
            title: 'View Logs',
            classes: 'flex gap-2 items-center px-6 !rounded-xl ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                setLogs(!logs);
            }
        }
    ];

    return (
        <div className="mb-5">
            {isLoading && <Loader />}

            {logs && (
                <BaseSlider title={'Logs'} open={logs} setOpen={setLogs}>
                    <ComingSoon />
                </BaseSlider>
            )}

            <Jumbotron
                title="Reports"
                path={[
                    {
                        title: `Tasks Reports`,
                        link: ``
                    }
                ]}
                actionsRequired={true}
                actions={actions}
            />
            <NumberCards />
            <ReportsBarCharts />
            <LineChartAndInfo />

            <div className="md:mx-10 mx-5 my-5 mt-10">
                <div className="my-5 flex-wrap flex gap-2 items-center">
                    <div>
                        <SearchInput
                            atChange={(e) => handleInputChange(e)}
                            width="300px"
                            placeholder="Search"
                            value={filters.search_term}
                        />
                    </div>

                    <div className="w-[350px]">
                        {allowAction && (
                            <ComboboxComponent
                                data={emps}
                                selectedValue={filters?.employee_id}
                                inputsx="!font-normal !text-sm"
                                sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                                placeholder="Filter by employees"
                                onChange={(obj) => {
                                    handleInputChange({
                                        target: {
                                            name: 'employee_id',
                                            value: obj[0]?.user_id || ''
                                        }
                                    });
                                    if (obj?.length === 0) {
                                        handleResetValue('employee_id');
                                    }
                                }}
                                targetProperty="user_id"
                                showNameOnly
                                limit={1}
                            />
                        )}
                    </div>
                    <div className="w-[350px]">
                        {' '}
                        <ComboboxComponentSSR
                            fetchDataFunction={fetchCustomersList}
                            selectedValue={filters?.business_client_id}
                            removePreviousSelected
                            inputsx="!font-normal !text-sm"
                            sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                            placeholder="Filter by client"
                            showNameOnly
                            limit={1}
                            onChange={(obj) => {
                                handleInputChange({
                                    target: {
                                        name: 'business_client_id',
                                        value: obj[0]?.business_client_id || ''
                                    }
                                });
                                if (obj?.length === 0) {
                                    handleResetValue('business_client_id');
                                }
                            }}
                            targetProperty="business_client_id"
                        />
                    </div>
                    {allowAction && (
                        <div className="w-[310px]">
                            <ComboboxComponent
                                data={emps}
                                selectedValue={filters?.assigned_by}
                                showNameOnly
                                limit={1}
                                inputsx="!font-normal !text-sm"
                                sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                                placeholder="Assigned By"
                                onChange={(obj) => {
                                    handleInputChange({
                                        target: {
                                            name: 'assigned_by',
                                            value: obj[0]?.user_id || ''
                                        }
                                    });
                                    if (obj?.length === 0) {
                                        handleResetValue('assigned_by');
                                    }
                                }}
                                targetProperty="user_id"
                            />
                        </div>
                    )}
                </div>
                <div className="flex gap-3 items-center flex-wrap">
                    <div className="w-[200px] default-forms">
                        <input
                            type="text"
                            name="reference_number"
                            onChange={handleInputChange}
                            value={filters.reference_number}
                            placeholder="Client Reference No."
                            className="!text-sm !text-gray-900 !border-gray-400 !font-normal px-3 py-1.5 bg-[#FAF9F6] w-full"
                        />
                    </div>

                    <div className="w-[200px]">
                        <SimpleSelectMenu
                            placeholder="Task Status"
                            targetProperty="label"
                            selectedValue={filters?.task_status_id}
                            optionsData={statuses || []}
                            showChipOnly
                            sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                            onChangeValue={(obj) =>
                                handleInputChange({
                                    target: {
                                        name: 'task_status_id',
                                        value: obj ? obj?.task_status_id : ''
                                    }
                                })
                            }
                        />
                    </div>

                    <div className="w-[200px]">
                        <SimpleSelectMenu
                            placeholder="Task type"
                            targetProperty="label"
                            selectedValue={filters?.task_type_id}
                            optionsData={taskTypes?.rows || []}
                            showChipOnly
                            sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                            onChangeValue={(obj) =>
                                handleInputChange({
                                    target: {
                                        name: 'task_type_id',
                                        value: obj ? obj?.task_type_id : ''
                                    }
                                })
                            }
                        />
                    </div>

                    <div className="w-[200px]">
                        <Menu
                            title="Order By Date"
                            iconSet={{ first: SortIcon, second: StepDownIcon }}
                            options={SORT_OPTIONS}
                            gap={1}
                            lastMl={1}
                            isModalDisabled={true}
                            condition={sortAsc}
                            atMenuClick={() => {
                                handleSortByDate();
                            }}
                            atClick={(_title) => {}}
                            text="sm"
                            sx={'!w-full justify-evenly'}
                        />
                    </div>
                    <div className="w-[200px]">
                        <DatePick
                            iconSet={{
                                right: CalendarSmallIco
                            }}
                            onDateChange={(date) => {
                                if (date) {
                                    setFilters({
                                        ...filters,
                                        start_date: date
                                    });
                                } else {
                                    setFilters({
                                        ...filters,
                                        start_date: ''
                                    });
                                }
                            }}
                            placeholder="Select From"
                            value={filters?.start_date || ''}
                            sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#FAF9F6] hover:bg-[#FAF9F6]"
                        />
                    </div>
                    <div className="w-[200px]">
                        <DatePick
                            iconSet={{
                                right: CalendarSmallIco
                            }}
                            onDateChange={(date) => {
                                if (date) {
                                    setFilters({
                                        ...filters,
                                        end_date: date
                                    });
                                } else {
                                    setFilters({
                                        ...filters,
                                        end_date: ''
                                    });
                                }
                            }}
                            placeholder="Select To"
                            value={filters?.end_date || ''}
                            sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 w-full bg-[#FAF9F6] hover:bg-[#FAF9F6]"
                        />
                    </div>
                    <div>
                        <div className="w-[200px]">
                            <SimpleSelectMenu
                                placeholder="Task Priority"
                                targetProperty="label"
                                selectedValue={filters?.proirity}
                                optionsData={taskPriorityOptions || []}
                                sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                                onChangeValue={(obj) =>
                                    handleInputChange({
                                        target: {
                                            name: 'proirity',
                                            value: obj ? obj?.label : ''
                                        }
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div>
                        {filters.search_term ||
                        filters?.start_date ||
                        filters?.end_date ||
                        filters.task_status_id ||
                        filters?.task_type_id ||
                        filters?.business_client_id ||
                        filters?.reference_number ||
                        filters?.proirity ? (
                            <>
                                <ClearFilterButton atClick={clearFilter} />
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            {(filteredData || allPageData) && (
                <TasksList
                    tasks={activeTab === 'all' ? allPageData : filteredData}
                    handlePageChange={handlePageChange}
                    tableView={tableView}
                    getAllTasks={getAllTasks}
                    toggleTaskDetails={toggleTaskDetails}
                    reloadTaskPage={reloadTaskPage}
                    hideActions={true}
                    id={'taskReportTable'}
                />
            )}

            <Pagination
                page={activeTab === 'all' ? Number(allPage) : Number(filteredPage)}
                pageSize={Number(itemsPerPage)}
                totalPages={Number(totalPages?.[activeTab] || 1)}
                onChangePage={(e) => {
                    if (activeTab === 'all') {
                        setAllPage(e);
                    } else {
                        setFilteredPage(e);
                    }
                }}
                onCountPerPageChange={(value) => {
                    setItemsPerPage(value);
                    if (activeTab === 'all') {
                        setAllPage(1);
                    } else {
                        setFilteredPage(1);
                    }
                }}
                scrollTo={'taskReportTable'}
            />
        </div>
    );
};
