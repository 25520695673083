import React from 'react';
import { motion } from 'framer-motion';
import { cn } from 'utills/tailwindUtil';

const AuthCardLayout = ({ sx, children, _width, hideBorder }) => {
    return (
        <div className="h-full">
            <motion.div
                initial={{ scale: 0.9, opacity: 0.2 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.1, type: 'easeIn', duration: 0.4 }}
                className="relative"
            >
                <div
                    className={cn(
                        `mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[600px] bg-white shadow rounded-xl sm:rounded-2xl  py-4 ${
                            hideBorder ? '' : 'border-b-[15px] border-blueish'
                        } `,
                        sx ? sx : ''
                    )}
                >
                    {children}
                </div>
            </motion.div>
        </div>
    );
};

export default AuthCardLayout;
