import React from 'react';

export const ItalicIcon = () => {
    return (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.31518 2.04L6.62378 14.96H10.0515L9.64526 17H0.199951L0.606201 14.96H4.08472L6.80151 2.04H3.34839L3.75464 0H13.2L12.7937 2.04H9.31518Z"
                fill="#656565"
            />
        </svg>
    );
};
