import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setSideLoader } from 'store/global/globalReducer';
import { addDesignationsThunk } from 'store/settings/team/workspaceSettings';
import { ERROR_TYPE_ERROR, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { generateId } from 'utills/uid';
import { SmallLoaderWhite } from './../../common/SmallLoaderWhite';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';
export const CreateDesignation = ({ open, setOpen, handleGetDesignations }) => {
    const dispatch = useDispatch();
    const { sideLoader } = useSelector((state) => state.global);
    const [newFields, setNewFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    //addDesignationsThunk

    const handleSubmit = () => {
        if (newFields.length < 1) {
            toastHandler('Please enter atleast one designation', ERROR_TYPE_ERROR);
            return;
        }

        let hasEmpty = false;
        newFields.forEach((field) => {
            if (field.text === '') {
                hasEmpty = true;
            }
        });

        if (hasEmpty) {
            return toastHandler('Input cant be empty', TOAST_TYPE_ERROR);
        }
        let statuses = [];
        const newInputs = [...newFields];

        newInputs.map((input) => {
            statuses.push(input?.text);
        });

        const payload = {
            designations: statuses
        };

        dispatch(setSideLoader(true));
        dispatch(addDesignationsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setOpen(false);
                    handleGetDesignations();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    return (
        <div>
            <Slideover open={open} setOpen={setOpen}>
                <RoleBasedGuard module={'Telephonic_directories'} permissions={['admin']} sx="h-[50vh]">
                    <div className="xl-title font-fira">Create Designation </div>
                    <div className="mt-10 small-text text-secondarybg font-poppins">
                        Please create a list of various designations based on your accounting firm&apos;s structure.
                        This list will be used to assign titles to team members when creating the telephonic directory.
                    </div>

                    <div className="mt-3 h-[400px] overflow-y-auto">
                        <MultiTextInputAddComponent
                            placeholder={'Enter title'}
                            fields={newFields}
                            setFields={setNewFields}
                            addSingle={false}
                        />
                    </div>

                    <div className=" mt-10 flex justify-center items-center">
                        <Button
                            disabled={sideLoader}
                            isloading={sideLoader}
                            iconSet={{ loader: SmallLoaderWhite }}
                            title="Add"
                            classes="ann-btn rounded-xl buttons-font-lighter font-fira px-20"
                            atClick={() => handleSubmit()}
                        />
                    </div>
                </RoleBasedGuard>
            </Slideover>
        </div>
    );
};
