/* eslint-disable no-unsafe-optional-chaining */
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/atoms/buttons/Button';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import useRoleGuard from 'Hooks/useRoleGuard';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { getTableSettingsThunk, updateTableSettingsThunk } from 'store/common';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { cn } from 'utills/tailwindUtil';

export const DynamicFilters = ({ nameArray, defaultArr, sx, mainPermission }) => {
    const dispatch = useDispatch();
    const [selectedKeys, setSelectedKeys] = useState({});
    const { hasPermissions } = useRoleGuard();
    const business_id = localStorage.getItem(BUSINESS_ID);

    const handleGet = () => {
        dispatch(setLoader(true));

        dispatch(getTableSettingsThunk({ table_name: nameArray?.join(','), business_id }))
            .then((res) => {
                if (res.payload) {
                    for (let i = 0; i < defaultArr.length; i++) {
                        const findObj = res.payload?.find(
                            (payloadObj) => payloadObj?.table_name === defaultArr[i]?.name
                        );

                        setSelectedKeys((pre_obj) => ({
                            ...pre_obj,
                            [defaultArr[i]?.name]: findObj
                                ? findObj
                                : {
                                      uts_id: '',
                                      meta: defaultArr[i].defaultSelected === 'true' ? defaultArr[i]?.defaultList : []
                                  }
                        }));
                    }
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        if (mainPermission) {
            if (hasPermissions(mainPermission, ['read', 'write', 'admin'])) {
                handleGet();
            }
        }
    }, []);

    const handleSubmit = (table_name) => {
        const findObj = selectedKeys?.[table_name];
        if (findObj) {
            dispatch(setLoader(true));
            dispatch(
                updateTableSettingsThunk({
                    table_name: table_name,
                    meta: findObj?.meta,
                    uts_id: findObj?.uts_id ?? '',
                    business_id
                })
            )
                .then((res) => {
                    if (res.payload) {
                        handleGet();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };
    return (
        <div>
            <div className="flex gap-2">
                {defaultArr?.map((d_obj, i) => (
                    <div key={'index' + d_obj?.title + i}>
                        <DropdownMenu
                            className="left-auto !w-[250px]"
                            icon={
                                <div
                                    className={cn(
                                        'flex gap-2 items-center ring-1 ring-thirdbg rounded-lg px-3 py-2.5 cursor-pointer text-thirdbg',
                                        sx
                                    )}
                                >
                                    {d_obj?.title}
                                    {d_obj?.icon ?? <FunnelIcon className="w-5 h-5" />}
                                </div>
                            }
                            extraProps={null}
                            preventOnClickFuns
                        >
                            <div className="flex justify-between items-center px-4 py-2">
                                <PrimaryParagraph>{d_obj?.title} Show/Hide </PrimaryParagraph>
                            </div>
                            <hr />
                            <div className="my-2">
                                {d_obj?.defaultList?.map((item, index) => (
                                    <div
                                        key={'d_obj' + d_obj?.title + index}
                                        className="flex gap-2 items-center px-4 py-2 hover:bg-slate-200  cursor-pointer "
                                    >
                                        <CheckboxInput
                                            checked={selectedKeys?.[d_obj?.name]?.meta?.includes(item)}
                                            atChange={(_e) => {
                                                if (
                                                    !hasPermissions(d_obj?.permission_name ?? 'Job', ['write', 'admin'])
                                                ) {
                                                    return toastHandler(
                                                        'You dont have permission to do this action.',
                                                        TOAST_TYPE_ERROR
                                                    );
                                                }

                                                if (selectedKeys?.[d_obj?.name]?.meta?.includes(item)) {
                                                    setSelectedKeys({
                                                        ...selectedKeys,
                                                        [d_obj?.name]: {
                                                            ...selectedKeys?.[d_obj?.name],
                                                            meta: selectedKeys?.[d_obj?.name]?.meta?.filter(
                                                                (i) => i !== item
                                                            )
                                                        }
                                                    });
                                                } else {
                                                    setSelectedKeys({
                                                        ...selectedKeys,
                                                        [d_obj?.name]: {
                                                            ...selectedKeys?.[d_obj?.name],
                                                            meta: [...selectedKeys?.[d_obj?.name]?.meta, item]
                                                        }
                                                    });
                                                }
                                            }}
                                            id={index + 'select'}
                                        />
                                        <PrimaryLabel
                                            htmlFor={index + 'select'}
                                            sx={'!font-[400] capitalize text-[#666] text-sm'}
                                        >
                                            {item}
                                        </PrimaryLabel>
                                    </div>
                                ))}
                            </div>
                            <hr />
                            <div className="flex justify-end mt-2 mb-1 px-3">
                                <Button
                                    title="Update"
                                    classes={'bg-thirdbg text-white py-2  !text-sm font-[400] px-4 rounded-lg'}
                                    atClick={() => {
                                        handleSubmit(d_obj?.name);
                                    }}
                                />
                            </div>
                        </DropdownMenu>
                    </div>
                ))}
            </div>
        </div>
    );
};
