/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/atoms/buttons/Button';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { SidebarLayout } from 'layouts/SidebarLayout';
import { PlusVector } from 'utills/svgs/PlusVector';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { DocumentListTable } from './DocumentListTable';
import DocumentListAddDocument from './DocumentListAddDocument';
import { bulkDeleteTemplateDocThunk, getTemplateDocThunk } from 'store/settings/JobPortal';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import ConfirmationModal from 'utills/confirmationModal';
import { sortItemsAlphabetically } from 'utills/sortAndFilter';
import moment from 'moment';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';

export const DocumentListSetting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [add, setAdd] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState('');
    const { hasPermissions } = useRoleGuard();
    const [selectedIds, setSelectedIds] = useState([]);

    const { data: getTemplateDoc } = useSelector((state) => state?.getTemplateDoc);
    const [dataList, setDataList] = useState(null);
    const [editObj, setEditObj] = useState(null);
    const [filters, setFilters] = useState({
        title: 'none',
        created_at: 'none',
        updated_at: 'none'
    });

    useEffect(() => {
        if (editObj) {
            setAdd(true);
        } else {
            setAdd(false);
        }
    }, [editObj]);

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/settings/job-manager`);
        }
    };

    useEffect(() => {
        if (!getTemplateDoc && hasPermissions('Job', ['read', 'write', 'admin'])) {
            dispatch(getTemplateDocThunk({ business_id }));
        } else {
            setDataList(getTemplateDoc?.length > 0 ? sortItemsAlphabetically(getTemplateDoc, 'title') : []);
        }
    }, [getTemplateDoc]);

    const handleSearchTerm = (value) => {
        const searchTerm = value.toLowerCase();
        const filteredList = getTemplateDoc?.filter((item) => item.title?.toLowerCase()?.includes(searchTerm));
        setDataList(filteredList?.length > 0 ? sortItemsAlphabetically(filteredList, 'title') : []);
    };

    const handleDeleteClients = (ids) => {
        dispatch(setLoader(true));
        setIsConfirmationOpen(false);
        dispatch(bulkDeleteTemplateDocThunk({ business_id: business_id, ids: ids?.join(',') }))
            .then((response) => {
                if (response.payload) {
                    setSelectedClient('');
                    setSelectedIds([]);
                    dispatch(getTemplateDocThunk({ business_id }));
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleDeletionId = (id, _isAll) => {
        if (id) {
            setSelectedClient(id);
            setSelectedIds([]);
        } else {
            setSelectedClient('');
        }
        setIsConfirmationOpen(true);
    };

    const handleSelectId = (id) => {
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item === id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(id);
            setSelectedIds(newIds);
        }
    };

    const handleSelectedAll = (e) => {
        const newIds = [];
        const allClients = getTemplateDoc;
        if (e.target.checked) {
            allClients?.forEach((client) => {
                newIds.push(client?.template_job_document_id);
                setSelectedIds(newIds);
            });
        } else {
            setSelectedIds([]);
        }
    };

    useEffect(() => {
        if (dataList && Object.values(filters).some((value) => value !== 'none')) {
            let NewDataListArray = [...dataList]?.sort((a, b) => {
                const { title, created_at, updated_at } = filters;

                if (title !== 'none') {
                    return title === 'ASC' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                }

                if (created_at !== 'none') {
                    return created_at === 'ASC'
                        ? moment(a.created_at).diff(moment(b.created_at))
                        : moment(b.created_at).diff(moment(a.created_at));
                }
                if (updated_at !== 'none') {
                    return updated_at === 'ASC'
                        ? moment(a.updated_at).diff(moment(b.updated_at))
                        : moment(b.updated_at).diff(moment(a.updated_at));
                }

                return 0;
            });

            setDataList(NewDataListArray);
        }
    }, [filters]);
    const updateFiltersObject = (id, value) => {
        setFilters({
            title: 'none',
            created_at: 'none',
            updated_at: 'none',
            [id]: value
        });
    };

    return (
        <SidebarLayout>
            <RoleBasedGuard module="Job" permissions={['write', 'read', 'admin']} sx="p-5">
                {add && (
                    <DocumentListAddDocument
                        open={add}
                        setOpen={(e) => {
                            setAdd(e);
                            setEditObj(null);
                        }}
                        editObj={editObj}
                    />
                )}
                <div className="grid">
                    {' '}
                    <Jumbotron
                        title="Documents List"
                        backArrowProp={backArrowProp}
                        path={[
                            { title: 'Settings', link: 'settings' },
                            {
                                title: 'Job Manager',
                                link: 'settings/job-manager'
                            },
                            {
                                title: 'Documents List',
                                link: ''
                            }
                        ]}
                    />
                    <ConfirmationModal
                        isOpen={isConfirmationOpen}
                        onClose={() => setIsConfirmationOpen(false)}
                        onConfirm={() => handleDeleteClients(selectedIds?.length > 0 ? selectedIds : [selectedClient])}
                    />
                    <div className="mx-5 mb-5 md:mx-10">
                        {' '}
                        <div className=" flex justify-between items-center  primary-fonts mt-5 flex-wrap">
                            <SearchInput
                                width="300px"
                                atChange={(e) => {
                                    handleSearchTerm(e.target.value);
                                }}
                                value={''}
                                placeholder="Search"
                                inputBorder="1px solid #979797"
                            />

                            <div className="flex gap-3 items-center lg:mt-0 mt-5">
                                {selectedIds?.length > 0 && (
                                    <span
                                        className="cursor-pointer "
                                        onClick={() => {
                                            if (!hasPermissions('Job', ['admin'])) {
                                                return toastHandler(
                                                    "You don't have permission to perform this action",
                                                    TOAST_TYPE_ERROR
                                                );
                                            }

                                            handleDeletionId('', true);
                                        }}
                                    >
                                        <BgDeleteIcon className={'hover:opacity-75 hover:shadow-md rounded-full'} />
                                    </span>
                                )}
                                <Button
                                    iconSet={{ leftIcon: PlusVector }}
                                    title="Add Document"
                                    iconInvert={true}
                                    classes={
                                        'bg-linear rounded-xl px-5  flex gap-2 items-center h-[3rem] text-white buttons-font-lighter '
                                    }
                                    atClick={() => setAdd(!add)}
                                />
                            </div>
                        </div>
                        <div className="mb-10 grid">
                            <DocumentListTable
                                dataList={dataList}
                                handleDeletionId={handleDeletionId}
                                handleSelectedAll={handleSelectedAll}
                                handleSelectId={handleSelectId}
                                selectedIds={selectedIds}
                                setEditObj={setEditObj}
                                updateFiltersObject={updateFiltersObject}
                                filters={filters}
                            />
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
