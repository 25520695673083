import React from 'react';

export const BgTick = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="#E7E8EC" />
            <path
                d="M8.78012 19.9219C8.40012 19.5419 8.40012 18.9019 8.78012 18.5019C9.18012 18.1219 9.82012 18.1219 10.2001 18.5019L14.3601 22.6619L25.8001 11.2219C26.1801 10.8219 26.8201 10.8219 27.2201 11.2219C27.6001 11.6019 27.6001 12.2419 27.2201 12.6419L15.0601 24.7819C14.6801 25.1819 14.0401 25.1819 13.6401 24.7819L8.78012 19.9219Z"
                fill="#0A1E46"
            />
        </svg>
    );
};
