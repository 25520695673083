import React from 'react';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Ripples from 'react-ripples';
import { BluepencilIcon } from '../../utills/svgs/BluepencilIcon';
import { CakeIcon } from '../../utills/svgs/CakeIcon';
import { CameraIcon } from '../../utills/svgs/CameraIcon';
import { EnvelopeIcon } from '../../utills/svgs/EnvelopeIcon';

import 'react-toastify/dist/ReactToastify.css';
import { toastHandler } from 'responseHanlder';
import { businessOwnerAddProfileThunk, businessOwnerGetProfile, updateUserProfileImageThunk } from 'store/auth/slices';
import { handleProfileImage } from 'store/global/globalReducer';
import { USER_TYPE, generateRandomProfileLink } from 'utills/globalVars';
import { ProfileSkeleton } from './profile-skeleton/ProfileSkeleton';

import 'react-datepicker/dist/react-datepicker.css';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { ImageCropper } from 'global-components/ImageCropper';
import { DataURIToBlob } from 'utills/dataValidation';
import { SmallLoader } from 'components/common/SmallLoader';
import { Telephone } from 'utills/svgs/Telephone';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import dayjs from 'dayjs';
import { DatePick } from 'components/atoms/inputs/DatePick';
import moment from 'moment';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';

const ProfileInfo = ({ _ownerData, _handleGetOwnerProfile }) => {
    const dispatch = useDispatch();
    const [showEditor, setShowEditor] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);
    const { data: profile } = useSelector((state) => state?.businessOwnerGetProfile);
    const [dateEditor, setDateEditor] = useState(false);
    const [nameEditor, setNameEditor] = useState(false);
    const [image, setImage] = useState(null);
    const isLoading = useSelector((state) => state.global.isLoading);
    const [ownerLoader, setOwnerLoader] = useState(false);

    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');

    const [profileLoader, _setProfileLoader] = useState(false);
    const [formData, setFormData] = useState({
        name: ``,
        date: ''
    });

    const [imageLoader, setImageLoader] = useState(false);
    const { profileImg } = useSelector((state) => state.global);
    const [showCroper, setShowCroper] = useState(false);

    useEffect(() => {
        if (profile) {
            setName(`${profile?.first_name}`);
            setLastName(`${profile?.last_name}`);
            setFormData({ ...formData, date: moment(profile?.date_of_birth, 'DD-MM-YYYY').format('DD-MM-YYYY') });
        }
    }, [profile]);

    const handleSubmit = () => {
        const newformData = {
            first_name: name ?? profile?.first_name,
            last_name: last_name ?? profile?.last_name,
            date_of_birth: formData.date ?? profile?.date_of_birth
        };

        setOwnerLoader(true);
        dispatch(businessOwnerAddProfileThunk(newformData))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Profile Updated Successfully', 'success');
                    setShowEditor(false);
                    setDateEditor(false);
                    setNameEditor(false);

                    dispatch(
                        businessOwnerGetProfile?.actions.handleUpdate({
                            ...profile,
                            first_name: response?.payload?.first_name,
                            last_name: response?.payload?.last_name,
                            date_of_birth: response.payload?.date_of_birth
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setOwnerLoader(false);
            });
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImage(file);

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setImage(reader.result);
            };

            reader.readAsDataURL(file);
            setShowCroper(true);
        }
    };

    function toggleEditor() {
        setShowEditor(!showEditor);
    }

    function toggleDateEditor() {
        setDateEditor(!dateEditor);
    }

    function toggleEditors() {
        handleSubmit();
    }

    const closeEditors = () => {
        setShowEditor(false);
        setNameEditor(false);
        setDateEditor(false);
    };

    const saveProfile = (image) => {
        const file = DataURIToBlob(image);
        const formData = new FormData();

        formData.append('profile_image', file, 'image.jpg');
        formData.append('uploaded_by', USER_TYPE);

        setImageLoader(true);
        dispatch(updateUserProfileImageThunk(formData))
            .then((response) => {
                if (response.payload) {
                    dispatch(handleProfileImage(response.payload.image));
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setImageLoader(false);
            });
    };

    const datePickerProps = {
        right: CalendarSmallIco
    };

    const twelveYearsAgo = moment().subtract(12, 'years').format('YYYY-MM-DD');
    const minDate = '1950-01-01';

    const handleFromDateChange = (date) => {
        if (date) {
            setFormData({
                ...formData,
                date: moment(new Date(date))?.format('DD-MM-YYYY')
            });
        } else {
            setFormData({
                ...formData,
                date: ''
            });
        }
    };
    return (
        <div className="md:mx-10 mx-5 mt-2 ">
            {image && showCroper ? (
                <>
                    <ImageCropper
                        width={'120'}
                        height={'120'}
                        setShowCropper={setShowCroper}
                        image={image}
                        isCircular={true}
                        handleCroppedImage={(image) => saveProfile(image)}
                    />
                </>
            ) : (
                ''
            )}

            <div className="profile-information shadow border !rounded-xl py-3 mt-2">
                {isLoading || profileLoader ? (
                    <ProfileSkeleton numOfSlides={2} />
                ) : (
                    <div className="">
                        <div className="relative flex flex-wrap items-center gap-3 px-3 sm:flex-nowrap sm:px-6  py-3">
                            <div className="">
                                <div className="pr-5 ">
                                    <div className="profile-image-wrapper">
                                        <div className="profile-camera absolute bottom-2 cursor-pointer right-0">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none', cursor: 'pointer' }}
                                                id="image-input"
                                            />

                                            <label htmlFor="image-input" className="cursor-pointer">
                                                <CameraIcon />
                                            </label>
                                        </div>

                                        {imageLoader ? (
                                            <div className="flex justify-center items-center h-full w-full">
                                                <SmallLoader />{' '}
                                            </div>
                                        ) : profile?.image ? (
                                            <img
                                                src={profileImg ?? profile?.image}
                                                alt="profile-img"
                                                className="profile-image"
                                                srcSet=""
                                                onClick={() => profile?.image && setIsZoomed(true)}
                                            />
                                        ) : (
                                            <>
                                                <img
                                                    src={generateRandomProfileLink(
                                                        profile?.first_name + ' ' + profile?.last_name
                                                    )}
                                                    alt="profile-img"
                                                    className="profile-image"
                                                    srcSet=""
                                                />
                                            </>
                                        )}
                                        {isZoomed && (
                                            <ZoomImage
                                                src={profileImg ?? profile?.image}
                                                alt="Profile"
                                                onClose={() => setIsZoomed(false)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
                                <div className="flex-1 md:flex md:flex-col gap-3 md:mt-0 mt-3">
                                    {!nameEditor ? (
                                        <div className=" flex gap-2 items-center">
                                            <div className="profile-names1 font-semibold xl-title">
                                                {profile?.first_name} {profile?.last_name}
                                            </div>
                                            <div className=" cursor-pointer" onClick={() => setNameEditor(!nameEditor)}>
                                                <BluepencilIcon />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="profile-emails ">
                                            <div className="grid grid-cols-2 gap-5 relative default-forms">
                                                <input
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                    name="name"
                                                    type="text"
                                                    maxLength="60"
                                                    className="edit-input px-7"
                                                    placeholder="Enter Name"
                                                />
                                                <input
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    value={last_name}
                                                    name="name"
                                                    type="text"
                                                    maxLength="60"
                                                    className="edit-input px-7"
                                                    placeholder="Enter Name"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="md:flex flex-wrap gap-5 md:gap-10 items-center  ">
                                        <div>
                                            <div className="buttons-font-lighter font-poppins">
                                                <div className="flex gap-2 ">
                                                    <div className="flex justify-center items-center">
                                                        <EnvelopeIcon />
                                                    </div>
                                                    <div>{profile?.email}</div>
                                                    <div
                                                        onClick={toggleEditor}
                                                        className="flex justify-center cursor-pointer items-center"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="buttons-font-lighter font-poppins">
                                                <div className="flex gap-2">
                                                    <div className="flex justify-center items-center">
                                                        <Telephone />
                                                    </div>
                                                    <div>{profile?.phone_number}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" ">
                                            {' '}
                                            {!dateEditor ? (
                                                <div className=" buttons-font-lighter font-poppins ">
                                                    <div className="flex gap-2">
                                                        <div className="flex justify-center items-center">
                                                            <CakeIcon />
                                                        </div>
                                                        <div>
                                                            {profile?.date_of_birth && (
                                                                <div>{profile?.date_of_birth}</div>
                                                            )}
                                                        </div>
                                                        <div
                                                            onClick={toggleDateEditor}
                                                            className="flex cursor-pointer justify-center items-center"
                                                        >
                                                            <BluepencilIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <DatePick
                                                    iconSet={datePickerProps}
                                                    onDateChange={handleFromDateChange}
                                                    placeholder={'Enter DOB'}
                                                    value={formData?.date ? dayjs(formData?.date, 'DD/MM/YYYY') : ''}
                                                    bg="transparent"
                                                    sx="!rounded-xl  inputs-font text-secondarybg"
                                                    border="#979797"
                                                    width="100%"
                                                    isNextDateRequired={false}
                                                    maxDate={twelveYearsAgo}
                                                    minDate={minDate}
                                                    isDob={true}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            {showEditor || dateEditor || nameEditor ? (
                                <div className="flex justify-end gap-2 mt-5">
                                    <button onClick={closeEditors} className="edit-cancel-btn px-5 py-2">
                                        Cancel
                                    </button>
                                    <Ripples during={2000} color="#333333">
                                        <button onClick={toggleEditors} className="edit-save-btn px-7 py-2">
                                            {ownerLoader ? <SmallLoaderWhite /> : 'Save'}
                                        </button>
                                    </Ripples>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileInfo;
