/* eslint-disable react-hooks/exhaustive-deps */
import { SmallLoader } from 'components/common/SmallLoader';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDataThunk, login, logoutThunk } from 'store/auth/slices';
import { BlueTick } from 'utills/svgs/BlueTick';
import { BulbIcon } from 'utills/svgs/BulbIcon';
import { IncompleteCircle } from 'utills/svgs/IncompleteCircle';
import { WhiteGreaterSign } from 'utills/svgs/WhiteGreaterSign';
import whitelogo from '../../files/whiteBgLogo.png.png';
import { useNavigate } from 'react-router-dom';
import { TwoFactorInfo } from './TwoFactorInfo';
import { VerifyEmailAuth } from './VerifyEmailAuth';
import { TwoFaGoogleInstructions } from './TwoFaGoogleInstructions';
import { GooogleAuthWithCode } from './GooogleAuthWithCode';
import { Button } from 'components/atoms/buttons/Button';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN, SOCKET_URL } from 'utills/globalVars';
import { io } from 'socket.io-client';
import { startHeartbeatInterval } from 'utills/socketEvents';
import { handleTempAuthInterval } from 'store/global/globalReducer';

export const TwoFaAuthentication = () => {
    const twoFaStepper = JSON.parse(sessionStorage.getItem('twoFaStepper'));
    const { temp_auth_interval } = useSelector((state) => state.global);

    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.login);
    const [condition, setCondition] = useState(twoFaStepper ?? 1);
    const [complete_two_fa, setComplete_two_fa] = useState(false);
    const [userData, setUserData] = useState(null);
    const isLoading = useSelector((state) => state.global.isLoading);

    const navigate = useNavigate();

    const handleCondition = (condition) => {
        sessionStorage.setItem('twoFaStepper', condition);

        setCondition(condition);
    };
    const [infoLoader, setInfoLoader] = useState(false);
    useEffect(() => {
        const dataConfig = { user_id: data?.user_id, user_type: 'employee' };

        setInfoLoader(true);
        dispatch(fetchUserDataThunk(dataConfig))
            .then((response) => {
                if (response.payload) {
                    setUserData(response.payload);

                    if (response.payload.email_2fa && response.payload.google_authenticator_2fa) {
                        setComplete_two_fa(true);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setInfoLoader(false);
            });
    }, []);

    const handleConnection = () => {
        if (!temp_auth_interval) {
            const newSocket = io(SOCKET_URL, {
                query: {
                    email: data?.email,
                    user_id: data?.email,
                    user_type: 'employee',
                    category: 'temp_auth',
                    temp_token: ''
                }
            });
            newSocket?.on('connect', () => {
                localStorage.setItem('socket_id', newSocket?.id);
                startHeartbeatInterval(dispatch, newSocket, handleTempAuthInterval);
            });

            newSocket.on('disconnect', () => {
                console.error('disconnected', '123');
            });
        }
    };

    useEffect(() => {
        handleConnection();
    }, []);
    function MyComponent() {
        return (
            <div>
                {condition === 1 && (
                    <TwoFactorInfo
                        handleCondition={handleCondition}
                        userData={userData}
                        complete_two_fa={complete_two_fa}
                        isLoading={isLoading}
                    />
                )}
                {condition === 2 && <VerifyEmailAuth handleCondition={handleCondition} />}
                {condition === 3 && <TwoFaGoogleInstructions handleCondition={handleCondition} />}
                {condition === 4 && <GooogleAuthWithCode handleCondition={handleCondition} />}
            </div>
        );
    }

    const newLogout = () => {
        dispatch(logoutThunk());
        dispatch(login.actions.handleUpdate(null));

        localStorage.removeItem('verified');
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(BUSINESS_ID);
        localStorage.removeItem('temp_token');
        sessionStorage.setItem('loginStepper', 1);
        navigate('/');
    };
    return (
        <div className="md:flex justify-center flex-wrap  items-center w-[100%] h-[100vh] gap-2 overflow-y-auto relative">
            <div className="absolute left-5 top-5">
                <img src={whitelogo} alt="logo" className="Synkli-logo" />
            </div>
            <div className="absolute right-5 top-5">
                <Button
                    atClick={newLogout}
                    title="Logout"
                    classes="bg-thirdbg buttons-font font-poppins text-white px-5 rounded-lg py-2"
                />
            </div>
            <div className="bg-white w-[80%] md:w-[23%] h-[85%] rounded-lg mx-auto md:mx-0 md:mt-0 mt-24">
                <div className="px-5 pt-4">
                    <h1 className="flex items-center gap-2 card-heading-two font-poppins ">Welcome to Synkli </h1>
                </div>
                <div className="buttons-font px-5 pt-3">
                    <p>Kindly finish these steps to advance to the onboarding process.</p>
                </div>
                <div className="bulb grid grid-cols-10 py-3 text-white mt-5 col-span-2">
                    <div className="flex justify-center items-center">
                        <div className="svg-wrapper-div ml-5">
                            <BulbIcon />
                        </div>
                    </div>
                    <div className="col-span-8 pl-5 buttons-font-lighter pt-1">Quick start guide for Synkli</div>
                    <div className="flex justify-center items-center">
                        <div className="icon-wrapper scale-75">
                            <WhiteGreaterSign />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    {' '}
                    <div
                        className={`cursor-pointer p-3 shadow start-subs buttons-font two-fa ${
                            complete_two_fa ? 'complete_fa' : ''
                        } `}
                    >
                        <div className="flex justify-between items-center">
                            <div>Two Factor Authentication</div>
                            <div className=" flex justify-center items-center">
                                <div className="circle-wrapper scale-75">
                                    {infoLoader ? (
                                        <span className="two-fa-spiner">
                                            {' '}
                                            <SmallLoader />{' '}
                                        </span>
                                    ) : (
                                        <span>
                                            {userData?.email_2fa && userData?.google_authenticator_2fa ? (
                                                <BlueTick />
                                            ) : (
                                                <IncompleteCircle />
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white w-[80%] md:w-[36%] h-[85%] rounded-lg mx-auto md:mx-0 overflow-y-auto">
                {MyComponent()}
            </div>
        </div>
    );
};
