import React from 'react';
import Breadcrumb from 'components/atoms/Breadcrumb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BackwardArrow } from 'utills/svgs/BackwardArrow';

export const AnnDetailsJumbo = ({ path }) => {
    const navigate = useNavigate();
    const { data } = useSelector((state) => state.getSingleNews);
    return (
        <div className="relative  md:flex md:flex-row flex-col  px-10 py-10 md:justify-between ">
            <div className="profile-jumbo-flex">
                <div className="jumbo-flex-1 ">
                    <div className="jumbo-flex-1 ">
                        <div className="flex gap-1 items-center text-3xl font-fira font-[600]">
                            <span
                                className="cursor-pointer"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                {' '}
                                <BackwardArrow />{' '}
                            </span>{' '}
                            Announcements
                        </div>
                        {path ? (
                            <Breadcrumb
                                path={[
                                    { title: 'Workspace', link: '' },
                                    {
                                        title: 'Announcements',
                                        link: 'announcement'
                                    },
                                    {
                                        title: data?.title,
                                        link: ''
                                    }
                                ]}
                            />
                        ) : (
                            <div className="jumbo-dir mt-2">
                                Workspace <span className="special-jumbo-text">&gt; Announcements</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
