import React from 'react';

export const CheckboxInput = ({ atChange, checked, id }) => {
    return (
        <div>
            <input
                type="checkbox"
                id={id ?? ''}
                checked={checked}
                onChange={(e) => atChange(e)}
                className="rounded-[3px]"
            />
        </div>
    );
};
