import Slideover from 'components/atoms/SlideOver';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID } from 'utills/globalVars';
import { updateTaskTypeThunk } from 'store/workspace/workspaceTasks';
import { setLoader } from 'store/global/globalReducer';
import { Button } from 'components/atoms/buttons/Button';

export const EditType = ({ open, setOpen, toggletypesUpdated }) => {
    const dispatch = useDispatch();
    const { taskType } = useSelector((state) => state.global);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (taskType) {
            setTitle(taskType?.label);
        }
    }, [taskType]);

    const handleSubmit = () => {
        const payload = {
            task_type_id: taskType?.task_type_id,
            business_id,
            label: title
        };

        dispatch(setLoader(true));
        dispatch(updateTaskTypeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggletypesUpdated();
                    setOpen(false);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <Slideover
            open={open}
            setOpen={setOpen}
            title="Update task Type"
            footer={
                <div className="flex justify-center items-center ">
                    <Button
                        atClick={handleSubmit}
                        title="Update"
                        classes="ann-btn rounded-xl buttons-font-lighter px-10"
                    />
                </div>
            }
        >
            <div>
                <div>
                    <motion.div
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    >
                        <div className="m-5 default-forms">
                            <label>Title</label>
                            <input
                                type="text"
                                onChange={(e) => setTitle(e.target.value)}
                                value={title}
                                className="mt-2"
                                placeholder="Please enter title"
                            />
                        </div>
                    </motion.div>
                </div>
            </div>
        </Slideover>
    );
};
