import React from 'react';
import { motion } from 'framer-motion';
import { SideTimes } from 'utills/svgs/SideTimes';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import './css/organizationalSettings.css';
import { DimmedPointDown } from 'utills/svgs/DimmedPointDown';

export const Logs = ({ toggleLogs }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    return (
        <div className="add-p-side grid grid-cols-5 ">
            <div className="md:col-span-3 hidden md:block left-side"></div>
            <div className="right-side col-span-5 md:col-span-2">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    <div>
                        <div onClick={toggleLogs} className="absolute   text-white p-2 right-1 top-1 cursor-pointer">
                            <SideTimes />
                        </div>

                        <div className="add-detail pt-10 px-5">
                            <div className="title">Logs</div>
                        </div>
                    </div>

                    <div className="flex justify-end items-center ">
                        <div className="app-date-picker">
                            {' '}
                            <div className="date-picker-point">
                                {' '}
                                <DimmedPointDown />
                            </div>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dateFormat="MMM dd, yyyy"
                            />
                        </div>
                    </div>

                    <div className="logs-container"></div>

                    <div className="add-status-container">
                        <div className="add-ann-form px-5 mt-3 "></div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};
