import React from 'react';
import '../../css-steps/signup-css/stepTwo.css';
import { useState, useEffect } from 'react';
import { Loader } from '../common/Loader';
import 'react-notifications/lib/notifications.css';
import { useSelector, useDispatch } from 'react-redux';
import { otpVerificationThunk, sendOtpPhoneRegisterThunk, signupUserThunk } from '../../store/auth/slices';
import { setLoader } from '../../store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';

const StepTwo = (props) => {
    const dispatch = useDispatch();
    const userP = JSON.parse(localStorage.getItem('signupUser'));

    const [userData, setUserData] = useState({});

    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerEnded, setTimerEnded] = useState(false);
    const [clearOtp, setClearOtp] = useState(false);

    const isLoading = useSelector((state) => state.global.isLoading);

    const [otpVal, setOtpVal] = useState('');

    const sendOtpToPhone = () => {
        dispatch(setLoader(true));

        const payload = {
            user_type: 'employee',
            email: userData?.email,
            type: 'email',
            phone_number: `${userData?.phone_number}`,
            socket_id: localStorage.getItem('socket_id')
        };
        dispatch(sendOtpPhoneRegisterThunk(payload))
            .then((response) => {
                if (response.payload) {
                    props.handleCondition(3);
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally((_error) => {
                dispatch(setLoader(false));
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (otpVal?.length < 6) {
            toastHandler('Please enter full otp', TOAST_TYPE_ERROR);
            return;
        }
        const opt = otpVal;

        const otpData = {
            email: userP?.email,
            otp: opt,
            type: 'email',
            user_type: 'employee',
            phone_number: `${userData?.phone_number}`,
            socket_id: localStorage.getItem('socket_id')
        };

        dispatch(setLoader(true));
        await dispatch(otpVerificationThunk(otpData))
            .then((response) => {
                dispatch(setLoader(false));

                if (response.payload) {
                    sendOtpToPhone();
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    const sendOTPEmail = async (e) => {
        e.preventDefault();

        const payload = {
            email: userP?.email,
            user_type: 'employee',
            type: 'email',
            phone_number: `${userP?.phone_number}`,
            socket_id: localStorage.getItem('socket_id')
        };

        dispatch(setLoader(true));

        await dispatch(signupUserThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setTimerEnded(false);
                    setTimeRemaining(90);
                    timeExecution();
                    setClearOtp(!clearOtp);
                }
            })
            .catch((error) => {
                console.error(error);
            });

        dispatch(setLoader(false));
    };

    useEffect(() => {
        const userData = localStorage.getItem('signupUser');
        if (userData) {
            setUserData(JSON.parse(userData));
        }
        timeExecution();
    }, []);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const showPageOne = () => {
        props.handleCondition(1);
    };

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    return (
        <div>
            {isLoading && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px]  lg:max-w-[640px] xl:max-w-[650px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    {' '}
                    <div className="text-center  mt-3  card-layout-title-text  ">Email OTP Verification</div>
                    <div className="otp-wrapper  mx-24  mt-10">
                        <div className="otp-body small-text font-poppins">
                            <p className="p-1">
                                Please enter the six-digit code that has been sent to your email address.
                            </p>
                        </div>

                        <form onSubmit={(e) => handleSubmit(e)}>
                            {' '}
                            <div className="mt-8">
                                <OtpInputs
                                    value={otpVal}
                                    onChange={handleOtpChange}
                                    required={true}
                                    clearOtp={clearOtp}
                                />
                            </div>
                            <div className="resend-confirmation text-sm font-poppins mt-5">
                                <p>
                                    {timerEnded ? (
                                        <span className="text-thirdbg  cursor-pointer" onClick={sendOTPEmail}>
                                            Resend
                                        </span>
                                    ) : (
                                        <span>
                                            <span>
                                                <span className="text-thirdbg  cursor-pointer mr-1">Resend</span>
                                                OTP in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`})
                                            </span>
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className="mt-60 mb-16">
                                <div className="flex justify-between items-center px-1-">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={showPageOne}
                                            className="btn-1 buttons-font font-fira px-14 py-2"
                                        >
                                            Back
                                        </button>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            disabled={isLoading ? true : false}
                                            className="btn-2 buttons-font font-fira px-14 py-2"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepTwo;
