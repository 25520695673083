import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { Button } from 'components/atoms/buttons/Button';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import Pulse from 'components/atoms/Pulse';
import Slideover from 'components/atoms/SlideOver';
import { H3 } from 'components/atoms/typography/headings/H3';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import useReadActivityHook from 'Hooks/job-manager/useReadActivityHook';
import useRoleGuard from 'Hooks/useRoleGuard';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { removeRequestDocumentThunk, submitDocumentThunk, updateRequestDocumentThunk } from 'store/JobManager';
import { formatFileSize } from 'utills/filesHandlers';
import { BUSINESS_ID, getProfileName, handleDownload, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { DateFormatToLocal } from 'utills/moment';
import { BgClear } from 'utills/svgs/BgClear';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgTick } from 'utills/svgs/BgTick';
import { DownloadIcon2 } from 'utills/svgs/DownloadIcon2';
import { cn } from 'utills/tailwindUtil';

export const DocumentActivity = ({ open, setOpen, activityData, onComplete, updateActivity, getActivityCount }) => {
    const dispatch = useDispatch();
    const { id: job_id } = useParams();
    const { hasPermissions } = useRoleGuard();

    const business_id = localStorage.getItem(BUSINESS_ID);

    const [note, setNote] = useState(null);
    const [noteOpen, setNoteOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState('');

    const { markCompleteJobAtion } = useReadActivityHook();

    // ------------------------------------------------------ handle Notificaiton -----------------------------
    // Extracting query parameters
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const requestDocumentActivityId = queryParams.get('document_drive_activity_id') || null;

    const handleSubmitDoc = () => {
        if (!hasPermissions('Job', ['write', 'admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const request_document_id = activityData[0]?.request_document_id;
        const payload = {
            business_id,
            job_id,
            request_document_id
        };
        dispatch(setLoader(true));
        dispatch(submitDocumentThunk(payload))
            .then((response) => {
                if (response.payload) {
                    onComplete();
                    setOpen(false);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleActionOnFile = (data = null, existingStatus) => {
        if (!hasPermissions('Job', ['write', 'admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        if (data) {
            if (existingStatus === data?.status) {
                return toastHandler(`File is already ${existingStatus}`, TOAST_TYPE_ERROR);
            }

            const jsonPayload = {
                business_id,
                job_id,
                ...data
            };
            dispatch(setLoader(true));
            dispatch(updateRequestDocumentThunk(jsonPayload))
                .then((res) => {
                    if (res.payload) {
                        updateActivity(data?.request_document_id);
                        onComplete();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    setNoteOpen(false);
                });
        }
    };
    const handleRemoveFile = ({ job_drive_id = null, request_document_id = null }) => {
        if (!hasPermissions('Job', ['write', 'admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        if (job_drive_id) {
            const jsonPayload = {
                business_id,
                job_drive_id
            };
            dispatch(setLoader(true));
            dispatch(removeRequestDocumentThunk(jsonPayload))
                .then((res) => {
                    if (res.payload) {
                        updateActivity(request_document_id);
                        onComplete();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    setNoteOpen(false);
                });
        }
    };

    const getFileORFolderName = (obj) => {
        if (obj?.files?.length === 1) {
            return obj?.files[0]?.name;
        } else if (obj?.files?.length > 1) {
            return 'Documents';
        } else {
            return obj?.requested_document?.name;
        }
    };

    return (
        <>
            <Slideover open={open} setOpen={setOpen}>
                {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}

                {selectedPdf && (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                    />
                )}
                <div className="flex justify-between items-center">
                    <H3>Activity</H3>
                    {activityData?.length > 0 &&
                        activityData[0]?.requested_document?.status !== 'Completed' &&
                        activityData[0]?.requested_document?.status !== 'deleted' &&
                        activityData.some((activity) => activity.files.length > 0) &&
                        activityData.every((activity) =>
                            activity.files.every((file) => file.requested_status !== 'Pending Review')
                        ) && (
                            <Button
                                title="Submited"
                                classes={'bg-thirdbg py-2 text-white !text-sm font-[400] px-5 rounded-lg'}
                                atClick={() => {
                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                        return toastHandler(
                                            'You do not have permission to perform this action.',
                                            TOAST_TYPE_ERROR
                                        );
                                    }
                                    handleSubmitDoc();
                                }}
                            />
                        )}
                </div>
                <div className="mt-8">
                    {activityData &&
                        activityData?.map((item) => (
                            <div
                                key={item?.document_drive_activity_id}
                                className={cn(
                                    'bg-pulse rounded-lg p-3 mt-3',
                                    item?.document_drive_activity_id === requestDocumentActivityId
                                        ? 'animate-pulse-bg'
                                        : ''
                                )}
                            >
                                <div className="flex gap-3  ">
                                    <DefaultProfileViewer
                                        image={item?.created_by?.image}
                                        width="37px"
                                        height="37px"
                                        name={getProfileName(item?.created_by)}
                                    />
                                    <div>
                                        <div>
                                            <PrimaryParagraph>
                                                <span className="font-[500]"> {getProfileName(item?.created_by)}</span>{' '}
                                                <span className="text-thirdbg">{item?.status}</span>{' '}
                                                {getFileORFolderName(item)}
                                            </PrimaryParagraph>
                                            <SecondaryParagraph sx="text-[#666666] text-xs">
                                                {DateFormatToLocal({
                                                    date: item?.created_at,
                                                    format: 'MMM DD [at] hh:mm A'
                                                })}
                                            </SecondaryParagraph>
                                        </div>
                                    </div>
                                </div>

                                {item?.files?.length > 0 && item?.status !== 'Rejected' && (
                                    <div className="ml-[37px] bg-white rounded-lg p-3 mt-4">
                                        {item?.files?.map((file, fileIndex) => (
                                            <div key={file?.job_drive_id + fileIndex}>
                                                <div className="flex justify-between items-center gap-5 flex-wrap mt-3">
                                                    <div className="flex gap-2 flex-1">
                                                        {(() => {
                                                            const foundObj = getActivityCount.find(
                                                                (item) => item.id === file?.job_drive_id
                                                            );
                                                            return (
                                                                <div
                                                                    className="relative cursor-pointer"
                                                                    onClick={() => {
                                                                        if (file?.url?.includes('.pdf')) {
                                                                            setSelectedPdf(file?.url);
                                                                        } else if (
                                                                            [
                                                                                'image/png',
                                                                                'image/jpeg',
                                                                                'image/jpg'
                                                                            ]?.includes(file?.file_type)
                                                                        ) {
                                                                            setSelectedImage(file?.url);
                                                                        } else {
                                                                            handleDownload({ url: file?.url });
                                                                        }
                                                                        if (foundObj) {
                                                                            markCompleteJobAtion({
                                                                                id: foundObj?.id,
                                                                                job_action_id: foundObj?.job_action_id
                                                                            });
                                                                        }
                                                                    }}
                                                                >
                                                                    <GenerateFileIcon
                                                                        sx="h-[45px] w-[45px]"
                                                                        url={file?.url}
                                                                    />

                                                                    {foundObj && (
                                                                        <div className="absolute -right-3 -top-2">
                                                                            <Pulse
                                                                                sx={'h-3 w-3'}
                                                                                tooltip={foundObj?.description}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })()}

                                                        <div></div>

                                                        <div>
                                                            <PrimaryLabel>{file.name} </PrimaryLabel>
                                                            <div className="flex gap-1 items-center">
                                                                <SecondaryParagraph sx=" ">
                                                                    {file?.size && file?.size !== 'null'
                                                                        ? formatFileSize(file?.size / 1024 ?? 0)
                                                                        : '__'}
                                                                </SecondaryParagraph>
                                                                <div
                                                                    className={`flex items-center gap-1 capitalize ${
                                                                        file?.requested_status === 'Pending Review'
                                                                            ? 'text-[#F08201]'
                                                                            : file?.requested_status === 'Accepted'
                                                                            ? 'text-[#008C51]'
                                                                            : 'text-[#F14747]'
                                                                    }`}
                                                                >
                                                                    <span>●</span>
                                                                    <SecondaryParagraph>
                                                                        {file?.requested_status}{' '}
                                                                    </SecondaryParagraph>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-end items-center gap-1">
                                                        {item?.requested_document?.status !== 'Completed' &&
                                                            item?.requested_document?.status !== 'deleted' &&
                                                            file?.requested_status !== 'deleted' && (
                                                                <>
                                                                    <span
                                                                        onClick={() => {
                                                                            if (
                                                                                !hasPermissions('Job', [
                                                                                    'write',
                                                                                    'admin'
                                                                                ])
                                                                            ) {
                                                                                return toastHandler(
                                                                                    'You do not have permission to perform this action.',
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }

                                                                            if (file?.requested_status !== 'deleted') {
                                                                                handleActionOnFile(
                                                                                    {
                                                                                        job_drive_id:
                                                                                            file?.job_drive_id,
                                                                                        request_document_id:
                                                                                            file?.request_document_id,
                                                                                        status: 'Accepted'
                                                                                    },
                                                                                    file?.requested_status
                                                                                );
                                                                            }
                                                                        }}
                                                                        className="w-[36px] h-[36px] rounded-full cursor-pointer flex justify-center items-center bg-[#B695F8]/10 hover:!shadow-md"
                                                                    >
                                                                        <BgTick />
                                                                    </span>
                                                                    <span
                                                                        onClick={() => {
                                                                            if (
                                                                                !hasPermissions('Job', [
                                                                                    'write',
                                                                                    'admin'
                                                                                ])
                                                                            ) {
                                                                                return toastHandler(
                                                                                    'You do not have permission to perform this action.',
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }
                                                                            if (file?.requested_status !== 'deleted') {
                                                                                setSelectedFile(file?.job_drive_id);
                                                                                setNoteOpen(true);
                                                                            }
                                                                        }}
                                                                        className="w-[36px] h-[36px] rounded-full cursor-pointer flex justify-center items-center bg-[#B695F8]/10 hover:!shadow-md"
                                                                    >
                                                                        <BgClear />
                                                                    </span>
                                                                    <span
                                                                        onClick={() => {
                                                                            if (
                                                                                !hasPermissions('Job', [
                                                                                    'write',
                                                                                    'admin'
                                                                                ])
                                                                            ) {
                                                                                return toastHandler(
                                                                                    'You do not have permission to perform this action.',
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }
                                                                            if (file?.requested_status !== 'deleted') {
                                                                                handleRemoveFile({
                                                                                    job_drive_id: file?.job_drive_id,
                                                                                    request_document_id:
                                                                                        file?.request_document_id
                                                                                });
                                                                            }
                                                                        }}
                                                                        className="w-[36px] h-[36px] rounded-full cursor-pointer flex justify-center items-center bg-[#B695F8]/10 hover:!shadow-md"
                                                                    >
                                                                        <BgDeleteIcon />
                                                                    </span>
                                                                </>
                                                            )}

                                                        <Button
                                                            atClick={() => {
                                                                if (
                                                                    !hasPermissions('Job', ['write', 'read', 'admin'])
                                                                ) {
                                                                    return toastHandler(
                                                                        'You do not have permission to perform this action.',
                                                                        TOAST_TYPE_ERROR
                                                                    );
                                                                }
                                                                handleDownload({ url: file?.url });
                                                            }}
                                                            iconSet={{
                                                                leftIconHTML: (
                                                                    <DownloadIcon2 className={'w-5 text-[#B695F8]'} />
                                                                )
                                                            }}
                                                            classes={
                                                                '!rounded-full bg-[#B695F8]/10 p-2.5 cursor-pointer'
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {noteOpen && selectedFile === file?.job_drive_id && (
                                                    <div className="">
                                                        <div className="mt-5">
                                                            <DefaultTextArea
                                                                onChange={(e) => setNote(e)}
                                                                placeholder={
                                                                    'Enter the reason why you rejected the document....'
                                                                }
                                                                sx="mt-2 !h-auto"
                                                                maxCountsx={'text-sm !text-gray-400 -mt-1 float-right'}
                                                                rows={3}
                                                                maxCount={50}
                                                            />
                                                        </div>

                                                        <div className="mt-3 flex gap-2 items-center">
                                                            <Button
                                                                title="Submit"
                                                                atClick={() => {
                                                                    handleActionOnFile(
                                                                        {
                                                                            job_drive_id: file?.job_drive_id,
                                                                            request_document_id:
                                                                                file?.request_document_id,
                                                                            status: 'Rejected',
                                                                            note: note
                                                                        },
                                                                        file?.requested_status
                                                                    );
                                                                }}
                                                                classes="ann-btn px-8 rounded-xl font-[400] font-poppins "
                                                            />
                                                            <Button
                                                                title="Cancel"
                                                                atClick={() => {
                                                                    setNote('');
                                                                    setNoteOpen(false);
                                                                }}
                                                                classes="blue-border-btn px-8 font-[400] !border-2 font-poppins rounded-xl"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {item?.status === 'Requested' && item?.requested_document?.note ? (
                                    <div className="flex flex-col gap-3 mt-5">
                                        <div className="rounded-lg bg-white p-3 py-4 ml-[37px] !break-all">
                                            <PrimaryParagraph>
                                                {item?.requested_document?.note ?? 'N/A'}
                                            </PrimaryParagraph>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col gap-3 mt-5">
                                        {item?.notes?.map((noteObj) => (
                                            <div
                                                key={noteObj?.job_drive_note_id}
                                                className="rounded-lg bg-white p-3 py-4 ml-[37px] !break-all"
                                            >
                                                <PrimaryParagraph>{noteObj?.note ?? 'N/A'}</PrimaryParagraph>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </Slideover>
        </>
    );
};
