import React from 'react';

export const BoardIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 18 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M5 2.36914H2.33333C1.97971 2.36914 1.64057 2.51338 1.39052 2.77013C1.14048 3.02687 1 3.37509 1 3.73819V18.7977C1 19.1608 1.14048 19.509 1.39052 19.7658C1.64057 20.0225 1.97971 20.1668 2.33333 20.1668H15.6667C16.0203 20.1668 16.3594 20.0225 16.6095 19.7658C16.8595 19.509 17 19.1608 17 18.7977V3.73819C17 3.37509 16.8595 3.02687 16.6095 2.77013C16.3594 2.51338 16.0203 2.36914 15.6667 2.36914H13"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.66797 7.84524H14.3346M7.66797 11.9524H14.3346M7.66797 16.0595H14.3346M3.66797 7.84524H5.0013M3.66797 11.9524H5.0013M3.66797 16.0595H5.0013M6.33464 1H11.668C12.0216 1 12.3607 1.14424 12.6108 1.40098C12.8608 1.65773 13.0013 2.00595 13.0013 2.36905C13.0013 2.73214 12.8608 3.08036 12.6108 3.33711C12.3607 3.59386 12.0216 3.7381 11.668 3.7381H6.33464C5.98101 3.7381 5.64188 3.59386 5.39183 3.33711C5.14178 3.08036 5.0013 2.73214 5.0013 2.36905C5.0013 2.00595 5.14178 1.65773 5.39183 1.40098C5.64188 1.14424 5.98101 1 6.33464 1Z"
                stroke="white"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
