import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { MultiSwitch } from './../../../../components/atoms/multi-switch/MultiSwitch';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { ArrowDownIcon, ArrowUpIcon, ArrowsUpDownIcon } from '@heroicons/react/20/solid';

import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import { Button } from 'components/atoms/buttons/Button';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import Pagination from 'components/pagination';
import { CreateJob } from 'components/job-manager-components/create-job/CreateJob';
import {
    addFavoriteThunk,
    closeDeleteJobThunk,
    deleteJobsThunk,
    getAllJobsThunk,
    updateJobGroupThunk,
    updateJobKeyValueThunk
} from 'store/JobManager';
import { setLoader } from 'store/global/globalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { BUSINESS_ID, PRIORITY_OPTIONS, TOAST_TYPE_ERROR } from 'utills/globalVars';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import { fetchAllCustomersForTaskThunk } from 'store/client';
import ConfirmationModal from 'utills/confirmationModal';
import { toastHandler } from 'responseHanlder';
import cloneDeep from 'lodash/cloneDeep';
import { usePresistFilters } from 'Hooks/filterAndSort/usePresistFilters';
import { Loader } from 'components/common/Loader';
import CommandPalettes from 'components/atoms/CommandPalettes';
import JobsTable from './JobsTable';
import ComboboxComponent from 'components/atoms/Combobox';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import { useGetGroups } from 'Hooks/useGetGroups';
import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { PlusVector } from 'utills/svgs/PlusVector';
import { DynamicFilters } from 'components/molecules/daynamic-filters/DynamicFilters';
import { JOBS_FILTERS, JOBS_TABLE_COLS } from 'components/molecules/daynamic-filters/filtersConstants';
import { FunnelIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import { createGroupThunk } from 'store/groups';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import InlineAddGroup from 'components/molecules/InlineAddGroup';
import JobsGroup from 'global-components/Filters/JobsGroup';
import JobsTypeFilter from 'global-components/Filters/JobsTypeFilter';
import JobsStatusFilter from 'global-components/Filters/JobsStatusFilter';

export const Jobs = () => {
    const dispatch = useDispatch();
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);

    const { hasPermissions } = useRoleGuard();
    const { data: tasksFilters } = useSelector((state) => state.getTableSettings);
    const [data, setData] = useState(null);
    const [allowedCols, setAllowedCols] = useState(null);
    const { data: jobStatuses } = useSelector((state) => state.getAllJobsStatuses);

    useEffect(() => {
        if (tasksFilters) {
            const jobsMeta = tasksFilters?.find((item) => item?.table_name === 'jobs_table');
            const colsMeta = tasksFilters?.find((item) => item?.table_name === 'jobs_table_cols');
            if (jobsMeta) {
                setData(jobsMeta);
            }
            if (colsMeta) {
                setAllowedCols(colsMeta);
            }
        }
    }, [tasksFilters]);
    //

    const { isLoading } = useSelector((state) => state.global);
    // const [jobStatuses, _setJobStatuses] = useState([
    //     { label: 'To-do' },
    //     { label: 'In Progress' },
    //     { label: 'On Hold' },
    //     { label: 'Completed' },
    //     { label: 'Closed' },
    //     { label: 'Cancelled' }
    // ]);

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState('');
    const [selectedJob, setSelectedJob] = useState(null);
    const [createJob, setCreateJob] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [allClients, setAllClients] = useState([]);
    const [viewList, setViewList] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState([]);

    const handleSelectPeople = () => {
        if (!hasPermissions('Job', ['write', 'admin'])) {
            return toastHandler('You dont have permission to do this action.', TOAST_TYPE_ERROR);
        }

        if (selectedPeople.length < jobs?.list?.length) {
            setSelectedPeople(jobs.list);
        } else {
            setSelectedPeople([]);
        }
    };

    useEffect(() => {
        if (!viewList) {
            setAllClients([]);
        }
    }, [viewList]);

    const [jobs, setJobs] = useState([]);

    const [filters, setFilters] = useState({
        priority: '',
        status: [],
        client: '',
        job: '',
        manager: '',
        group_id: '',
        search_term: '',
        order_by: {
            title: '',
            created_at: '',
            start_date: '',
            due_date: ''
        }
    });
    const { page_p, pageSize_p, updateUrlParams, setPresistFilter, getPresistFilters, clearPresistFilter } =
        usePresistFilters();

    const [page, setPage] = useState(page_p);
    const [pageSize, setPageSize] = useState(pageSize_p ?? PAGINATION_PAGE_SIZE);

    const [currentTab, setCurrentTab] = useState('All Jobs');

    const [totalCounts, setTotalCounts] = useState({
        all_job: 0,
        new_job: 0,
        recurring_job: 0
    });

    const handleChangePage = (newPage, perPageCount) => {
        setPage(newPage);
        setPageSize(perPageCount);
        updateUrlParams(newPage, perPageCount);
        getJobs({
            page: newPage,
            page_size: perPageCount,
            business_id,

            job_category:
                currentTab === 'All Jobs'
                    ? 'all_job'
                    : currentTab === 'New Jobs'
                    ? 'new_job'
                    : currentTab === 'Recurring Jobs'
                    ? 'recurring_job'
                    : 'all_job',
            search_term: searchTerm,
            priority: filters?.priority,
            status:
                currentTab === 'Closed Jobs'
                    ? jobStatuses?.find((item) => item?.label === 'Closed')?.job_status_id ?? ''
                    : filters?.status
                    ? filters?.status?.map((item) => item?.job_status_id).join(',')
                    : '',
            job_type_id: filters?.job,
            business_client_id: filters?.client,
            group_id: filters?.group_id ? filters?.group_id : '',
            employee_id: filters?.manager,
            order_by__created_at: filters?.order_by?.created_at,
            order_by__title: filters?.order_by?.title,
            order_by__start_date: filters?.order_by?.start_date,
            order_by__due_date: filters?.order_by?.due_date,
            is_favourite: currentTab === 'Favourites' ? 'yes' : '',
            is_delayed: currentTab === 'Delayed Jobs' ? 'yes' : ''
        });
    };

    const handleFetchAllJobs = () => {
        // setPage(1);

        const payload = {
            page: 1,
            page_size: pageSize,
            search_term: searchTerm,
            business_id,
            job_category:
                currentTab === 'All Jobs'
                    ? 'all_job'
                    : currentTab === 'New Jobs'
                    ? 'new_job'
                    : currentTab === 'Recurring Jobs'
                    ? 'recurring_job'
                    : 'all_job',

            priority: filters?.priority,
            status:
                currentTab === 'Closed Jobs'
                    ? jobStatuses?.find((item) => item?.label === 'Closed')?.job_status_id ?? ''
                    : filters?.status
                    ? filters?.status?.map((item) => item?.job_status_id).join(',')
                    : '',
            group_id: filters?.group_id ? filters?.group_id : '',

            job_type_id: filters?.job,
            business_client_id: filters?.client,
            employee_id: filters?.manager,
            order_by__created_at: filters?.order_by?.created_at,
            order_by__title: filters?.order_by?.title,
            order_by__start_date: filters?.order_by?.start_date,
            order_by__due_date: filters?.order_by?.due_date,
            is_favourite: currentTab === 'Favourites' ? 'yes' : '',
            is_delayed: currentTab === 'Delayed Jobs' ? 'yes' : ''
        };

        getJobs(payload);
    };

    useEffect(() => {
        if (hasPermissions('Job', ['read', 'write', 'admin'])) {
            handleFetchAllJobs();
        }
    }, [filters, currentTab, searchTerm]);

    useEffect(() => {
        if (!employees && hasPermissions('Job', ['read', 'write', 'admin'])) {
            dispatch(fetchRegisteredEmployeesThunk());
        }
    }, [employees]);

    const handleSetFilters = () => {
        let activeFilter = false;

        for (let key in filters) {
            if (key === 'status' && Array.isArray(filters[key]) && filters[key].length === 0) {
                activeFilter = true;
                break;
            }
            if (typeof filters[key] === 'object') {
                for (let nestedKey in filters[key]) {
                    if (filters[key][nestedKey]) {
                        activeFilter = true;
                        break;
                    }
                }
            } else {
                if (filters[key]) {
                    activeFilter = true;
                    break;
                }
            }
        }

        if (activeFilter) {
            setPresistFilter('jobs', filters);
        }
    };

    const getJobs = (payload) => {
        dispatch(setLoader(true));
        dispatch(getAllJobsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setJobs(response.payload);
                    setTotalCounts(response.payload.category_count);
                    handleSetFilters();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const isFilterEnabled = () => {
        const { order_by: _order_by, ...filtersWithoutOrderBy } = filters;

        for (let key in filtersWithoutOrderBy) {
            if (
                filtersWithoutOrderBy[key] !== '' &&
                filtersWithoutOrderBy[key] !== null &&
                filtersWithoutOrderBy[key] !== undefined &&
                filtersWithoutOrderBy[key].length > 0
            ) {
                return true;
            }
        }

        return false;
    };

    useEffect(() => {
        const presistedFilters = getPresistFilters('jobs');

        if (presistedFilters) {
            setFilters(presistedFilters?.filters);
        }
    }, []);

    const ReturnSortIcons = ({ sortType, text }) => {
        return (
            <div className="flex gap-2 items-center">
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        setFilters((prev) => ({
                            ...prev,
                            order_by: {
                                ...prev.order_by,
                                [sortType]: ''
                            }
                        }));
                    }}
                >
                    {text}
                </span>
                <div>
                    {filters?.order_by?.[sortType] === 'ASC' ? (
                        <ArrowDownIcon
                            id={'_sort'}
                            onClick={() => {
                                setFilters((prev) => ({
                                    ...prev,
                                    order_by: {
                                        ...prev.order_by,
                                        [sortType]: 'DESC'
                                    }
                                }));
                            }}
                            className="w-5 h-5 cursor-pointer"
                        />
                    ) : filters?.order_by?.[sortType] === 'DESC' ? (
                        <ArrowUpIcon
                            id={'_sort'}
                            onClick={() => {
                                setFilters((prev) => ({
                                    ...prev,
                                    order_by: {
                                        ...prev.order_by,
                                        [sortType]: 'ASC'
                                    }
                                }));
                            }}
                            className="w-5 h-5 cursor-pointer"
                        />
                    ) : (
                        <ArrowsUpDownIcon
                            id={'_sort'}
                            onClick={() => {
                                setFilters((prev) => ({
                                    ...prev,
                                    order_by: {
                                        ...prev.order_by,
                                        [sortType]: 'ASC'
                                    }
                                }));
                            }}
                            className="w-4 h-4 text-gray-400 cursor-pointer"
                        />
                    )}
                </div>
            </div>
        );
    };

    const clearFilters = () => {
        clearPresistFilter();

        setFilters({
            priority: '',
            status: [],
            client: '',
            job: '',
            manager: '',
            group_id: '',
            search_term: '',
            order_by: {
                created_at: '',
                start_date: '',
                due_date: ''
            }
        });
    };

    // const fetchJobTypes = () => {
    //     const payload = {
    //         business_id: business_id
    //     };
    //     dispatch(getAllJobTypesThunk(payload));
    // };

    // useEffect(() => {
    //     if (hasPermissions('Job', ['read', 'write', 'admin'])) {
    //         fetchJobTypes();
    //     }
    // }, []);

    const fetchCustomersList = async (payload) => {
        return await dispatch(fetchAllCustomersForTaskThunk(payload));
    };

    const handleOpenConfirmation = (ids) => {
        setSelectedIds(ids);
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        dispatch(setLoader(false));

        dispatch(deleteJobsThunk({ ids: selectedIds, business_id }))
            .then((response) => {
                if (response.payload) {
                    setSelectedIds([]);
                    setSelectedPeople([]);
                    handleFetchAllJobs();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleUpdateValues = (key, value, job_id) => {
        if (!value) {
            return toastHandler(`Value is required`, TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(updateJobKeyValueThunk({ business_id, job_id, key, value }))
            .then((response) => {
                if (response.payload) {
                    const data = response.payload;
                    const newJobs = cloneDeep(jobs);

                    const job = newJobs?.list?.find((item) => item?.job_id === job_id);
                    job[key] = data[key];
                    handleFetchAllJobs();
                    setJobs(newJobs);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const { handleGetGroups, data: groups } = useGetGroups();

    useEffect(() => {
        if (hasPermissions('Job', ['read', 'write', 'admin'])) {
            handleGetGroups('jobs');
        }
    }, []);

    const handleMarkFavorite = (action, data) => {
        dispatch(setLoader(true));

        dispatch(addFavoriteThunk({ action, job_ids: data?.map((item) => item?.job_id) }))
            .then((response) => {
                if (response.payload) {
                    setSelectedPeople([]);
                    handleFetchAllJobs();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [createGroup, setCreateGroup] = useState(false);

    const handleCreateGroup = (name) => {
        const payload = {
            business_id,
            type: 'jobs',
            titles: [name]
        };
        return dispatch(createGroupThunk(payload)).then((response) => {
            if (response.payload) {
                handleGetGroups('jobs');
                setCreateGroup(false);
                return true;
            }
        });
    };

    const handleAddToGroup = (isRemove = false) => {
        if (!selectedGroup && !isRemove) {
            return toastHandler('Please select a group', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(
            updateJobGroupThunk({
                group_id: isRemove ? '' : selectedGroup?.group_id,
                action: isRemove ? 'remove' : 'add',
                job_ids: selectedPeople?.map((item) => item?.job_id)
            })
        )
            .then((response) => {
                if (response.payload) {
                    setSelectedGroup(null);
                    setSelectedPeople([]);
                    handleFetchAllJobs();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleCloseDelete = () => {
        setIsConfirmationOpen(false); //
        dispatch(setLoader(true));
        dispatch(
            closeDeleteJobThunk({
                job_ids: selectedPeople?.map((item) => item?.job_id),
                business_id,
                status: selectedAction
            })
        )
            .then((response) => {
                if (response.payload) {
                    setSelectedPeople([]);
                    setSelectedAction('');
                    handleFetchAllJobs();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <SidebarLayout>
            <RoleBasedGuard module={'Job'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                <Jumbotron
                    title="Job Manager"
                    path={[
                        {
                            link: 'job-manager',
                            title: 'Job Manager'
                        },
                        {
                            link: 'job-manager/jobs',
                            title: 'Jobs'
                        }
                    ]}
                    actionsRequired={true}
                    actions={[
                        {
                            title: 'Create Job',
                            onAction: () => setCreateJob(!createJob),
                            classes: 'ann-btn px-10 rounded-xl'
                        }
                    ]}
                />

                {isLoading && <Loader />}
                {createJob && (
                    <CreateJob
                        title={selectedJob ? 'Edit Job' : 'Create Job'}
                        open={createJob}
                        setOpen={(value) => {
                            setCreateJob(value);
                            setSelectedJob(null);
                        }}
                        job_id={selectedJob}
                        onAction={handleFetchAllJobs}
                    />
                )}

                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={() => {
                        setIsConfirmationOpen(false);
                        handleCloseDelete();
                    }}
                    title="Confirmation"
                    description={'Are you you want to perform this action?'}
                    customButtons={
                        <div className="flex gap-2 items-center flex-wrap justify-center">
                            <Button
                                title="Cancel"
                                classes="border-button h-[3rem]  px-12 buttons-font-lighter font-poppins rounded-xl"
                                atClick={() => {
                                    setIsConfirmationOpen(false);
                                }}
                            />
                            <Button
                                title="Submit"
                                classes="ann-btn px-14 rounded-xl buttons-font-lighter font-fira"
                                atClick={() => {
                                    handleCloseDelete();
                                }}
                            />
                        </div>
                    }
                />
                <div className="md:mx-10 mx-5">
                    <div className="flex flex-wrap justify-between gap-5 items-center">
                        <div className="hidden  md:block md:w-[950px]">
                            <MultiSwitch
                                cols={6}
                                items={[
                                    {
                                        title: 'All Jobs',
                                        selected: currentTab === 'All Jobs'
                                    },
                                    {
                                        title: 'New Jobs',
                                        selected: currentTab === 'New Jobs',
                                        count: totalCounts?.new_job ? totalCounts?.new_job : '0'
                                    },
                                    {
                                        title: 'Recurring Jobs',
                                        selected: currentTab === 'Recurring Jobs',
                                        count: totalCounts?.recurring_job ? totalCounts?.recurring_job : '0'
                                    },
                                    {
                                        title: 'Favourites',
                                        selected: currentTab === 'Favourites',
                                        count: totalCounts?.favourite_job ? totalCounts?.favourite_job : '0'
                                    },
                                    {
                                        title: 'Delayed Jobs',
                                        selected: currentTab === 'Delayed Jobs',
                                        count: totalCounts?.delayed_job ? totalCounts?.delayed_job : '0'
                                    },
                                    {
                                        title: 'Closed Jobs',
                                        selected: currentTab === 'Closed Jobs',
                                        count: totalCounts?.closed_job ? totalCounts?.closed_job : '0'
                                    }
                                ]}
                                onClick={(value) => {
                                    setCurrentTab(value);
                                }}
                            />
                        </div>

                        <div className="flex-1 flex justify-end">
                            <DynamicFilters
                                nameArray={['jobs_table', 'jobs_table_cols']}
                                mainPermission="Job"
                                defaultArr={[
                                    {
                                        name: 'jobs_table',
                                        title: 'Filters',
                                        defaultSelected: 'true',
                                        defaultList: JOBS_FILTERS,
                                        icon: <FunnelIcon className="w-5 h-5" />,
                                        permission_name: 'Job'
                                    },
                                    {
                                        name: 'jobs_table_cols',
                                        title: 'Columns',
                                        defaultSelected: 'true',
                                        defaultList: JOBS_TABLE_COLS,
                                        icon: <ViewColumnsIcon className="w-5 h-5 " />,
                                        permission_name: 'Job'
                                    }
                                ]}
                            />
                        </div>
                    </div>

                    <div
                        className={`flex justify-between items-center flex-wrap w-full ${
                            data?.meta?.length > 0 && 'mt-2'
                        } `}
                    >
                        <div className="block md:hidden">
                            <SimpleSelectMenu
                                placeholder="Job Types"
                                targetProperty="label"
                                selectedValue={currentTab}
                                valuePropertyName="label"
                                optionsData={
                                    [
                                        { label: 'All Jobs' },
                                        { label: 'New Jobs' },
                                        { label: 'Recurring Jobs' },
                                        { label: 'Favourites' },
                                        { label: 'Delayed Jobs' },
                                        { label: 'Closed Jobs' }
                                    ] || []
                                }
                                sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                showChipOnly={false}
                                onChangeValue={(obj) => setCurrentTab(obj?.label)}
                            />
                        </div>
                        <div
                            className={`flex justify-between items-center flex-wrap w-full ${
                                (data?.meta?.length > 0 || !data?.meta) && 'mt-8'
                            } `}
                        >
                            {(data?.meta?.includes('search') || !data?.meta) && (
                                <SearchInput
                                    width="320px"
                                    defaultValue={searchTerm || ''}
                                    atChange={(e) => {
                                        setSearchTerm(e.target.value);
                                    }}
                                    placeholder="Search by Reference No. or Job Title"
                                />
                            )}
                            {isFilterEnabled() && (
                                <span className="">
                                    <ClearFilterButton atClick={() => clearFilters()} />
                                </span>
                            )}

                            <div className="flex gap-3 items-start flex-wrap">
                                {(data?.meta?.includes('filter by group') || !data?.meta) && (
                                    <div className="w-[280px]">
                                        <JobsGroup
                                            filters={filters}
                                            setFilters={(obj) => {
                                                setFilters((prev) => ({ ...prev, group_id: obj?.group_id || '' }));
                                            }}
                                            groups={groups}
                                            handleGetGroups={() => handleGetGroups('jobs')}
                                        />
                                        {/* <SimpleSelectMenu
                                            placeholder="Filter by Group"
                                            targetProperty="title"
                                            selectedValue={filters.group_id}
                                            valuePropertyName="group_id"
                                            optionsData={groups || []}
                                            sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                            showChipOnly={false}
                                            onChangeValue={(obj) =>
                                                setFilters((prev) => ({ ...prev, group_id: obj.group_id }))
                                            }
                                        /> */}
                                    </div>
                                )}

                                {(data?.meta?.includes('filter by priority') || !data?.meta) && (
                                    <div className="w-[280px]">
                                        <SimpleSelectMenu
                                            placeholder="Filter by Priority"
                                            targetProperty="label"
                                            selectedValue={filters.priority}
                                            valuePropertyName="label"
                                            optionsData={PRIORITY_OPTIONS || []}
                                            sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                            showChipOnly={false}
                                            onChangeValue={(obj) =>
                                                setFilters((prev) => ({ ...prev, priority: obj.label }))
                                            }
                                        />
                                    </div>
                                )}

                                {(data?.meta?.includes('filter by job type') || !data?.meta) && (
                                    <div className="w-[280px] mt-2">
                                        <JobsTypeFilter
                                            filters={filters}
                                            setFilters={(obj) =>
                                                setFilters((prev) => ({ ...prev, job: obj?.label || '' }))
                                            }
                                        />
                                        {/* <SimpleCombobox
                                            placeholder="Filter By Job Type"
                                            targetProperty="label"
                                            valuePropertyName="label"
                                            selectedValue={filters?.job}
                                            optionsData={jobTypes || []}
                                            sx="rounded-xl py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                                            onChangeValue={(obj) =>
                                                setFilters((prev) => ({ ...prev, job: obj?.label }))
                                            }
                                        /> */}
                                    </div>
                                )}

                                {(data?.meta?.includes('filter by status') || !data?.meta) && (
                                    <div className="w-[280px] mt-2">
                                        <JobsStatusFilter
                                            filters={filters}
                                            onRemove={() => {
                                                handleSetFilters();
                                            }}
                                            setFilters={(arr) =>
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    status: arr?.map((item) => {
                                                        return {
                                                            label: item?.label,
                                                            job_status_id: item?.job_status_id
                                                        };
                                                    })
                                                }))
                                            }
                                        />
                                        {/* <MultiselectCheckboxDropdown
                                            options={jobStatuses ?? []}
                                            mainSx="!m-0 !p-0  !w-[280px]"
                                            targetProperty="label"
                                            onChange={(arr) => {
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    status: arr?.map((item) => item?.label)
                                                }));
                                            }}
                                            selectedItems={
                                                filters?.status?.length > 0
                                                    ? filters?.status?.map((item) => {
                                                          return { label: item };
                                                      })
                                                    : []
                                            }
                                            labelInside="Filter By Status"
                                        /> */}
                                    </div>
                                )}

                                {(data?.meta?.includes('filter by employee') || !data?.meta) && employees && (
                                    <div className="!w-[280px]">
                                        <ComboboxComponent
                                            data={employees}
                                            placeholder={'Filter By Employees'}
                                            {...(filters?.manager
                                                ? { defaultSelected: filters?.manager }
                                                : { selectedValue: filters?.manager ?? '' })}
                                            onChange={(list) => {
                                                const obj = list[0];
                                                if (obj?.user_id) {
                                                    setFilters((prev) => ({ ...prev, manager: obj?.user_id || '' }));
                                                }
                                            }}
                                            onRemove={(_e) => {
                                                setFilters((prev) => ({ ...prev, manager: '' }));
                                            }}
                                            targetProperty="user_id"
                                            limit={1}
                                            showNameOnly
                                        />
                                    </div>
                                )}

                                {(data?.meta?.includes('filter by client') || !data?.meta) && (
                                    <div className="!w-[280px]">
                                        <ComboboxComponentSSR
                                            fetchDataFunction={fetchCustomersList}
                                            {...(filters?.client
                                                ? { defaultSelected: filters?.client }
                                                : { selectedValue: '' })}
                                            inputsx="!font-normal !text-sm"
                                            sx="!rounded-xl !font-normal !ring-gray-400 mt-2 !flex"
                                            placeholder="Filter By Client"
                                            showNameOnly
                                            removePreviousSelected
                                            onChange={(obj) => {
                                                setFilters((prev) => ({
                                                    ...prev,
                                                    client: obj[0]?.business_client_id || ''
                                                }));
                                            }}
                                            targetProperty="business_client_id"
                                            limit={1}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className={` ${
                            selectedPeople?.length > 0 ? 'block' : 'invisible'
                        } flex flex-wrap justify-end mt-5`}
                    >
                        <div className="flex gap-2 flex-wrap items-center">
                            {selectedPeople?.length > 0 && (
                                <DropdownMenu
                                    className="left-0 md:left-auto w-[250px] md:w-[350px]"
                                    icon={
                                        <Button
                                            title="Add to group"
                                            classes={'bg-blueish text-white py-2  !text-sm font-[400] px-5 rounded-lg'}
                                            atClick={() => {}}
                                        />
                                    }
                                    extraProps={null}
                                    preventOpenTop
                                    preventOnClickFuns
                                >
                                    <div className="flex justify-between items-center px-4 py-2">
                                        <PrimaryParagraph>Add To Group </PrimaryParagraph>
                                        <Button
                                            title="Add"
                                            classes={'bg-thirdbg text-white py-2  !text-sm font-[400] px-2 rounded-lg'}
                                            atClick={() => {
                                                handleAddToGroup();
                                            }}
                                        />
                                    </div>

                                    <hr />
                                    <div className="my-2">
                                        {groups?.map((item, index) => (
                                            <div
                                                key={index}
                                                onClick={() => console.info('priority')}
                                                className="flex gap-2 items-center px-4 py-2 hover:bg-[#F8F4FE]  cursor-pointer "
                                            >
                                                <CheckboxInput
                                                    checked={selectedGroup?.group_id === item?.group_id}
                                                    atChange={(_e) => {
                                                        if (selectedGroup?.group_id === item?.group_id) {
                                                            setSelectedGroup(null);
                                                        } else {
                                                            setSelectedGroup(item);
                                                        }
                                                    }}
                                                    id={'group-select' + index}
                                                />
                                                <PrimaryLabel
                                                    htmlFor={'group-select' + index}
                                                    sx="!font-[400] capitalize"
                                                >
                                                    {item?.title}
                                                </PrimaryLabel>
                                            </div>
                                        ))}
                                    </div>

                                    <hr />
                                    {!createGroup ? (
                                        <Button
                                            classes={
                                                'flex gap-4 items-center p-3 cursor-pointer hover:!shadow-none hover:!outline-none'
                                            }
                                            atClick={() => {
                                                setCreateGroup(true);
                                            }}
                                            title={
                                                <>
                                                    <PlusVector className="!text-black !fill-black" />
                                                    <PrimaryParagraph>New Group</PrimaryParagraph>
                                                </>
                                            }
                                        />
                                    ) : (
                                        <InlineAddGroup
                                            onCreate={(name) => handleCreateGroup(name)}
                                            onCancel={() => {
                                                setCreateGroup(false);
                                            }}
                                        />
                                    )}
                                </DropdownMenu>
                            )}

                            {selectedPeople?.some((item) => item?.is_favourite !== 'yes') && (
                                <Button
                                    title="Add to favourites"
                                    classes={'bg-[#F08201]/10 text-[#F08201] py-2  !text-sm font-[400] px-5 rounded-lg'}
                                    atClick={() => {
                                        handleMarkFavorite('yes', selectedPeople);
                                    }}
                                />
                            )}
                            {selectedPeople?.some((item) => item?.is_favourite === 'yes') && (
                                <Button
                                    title="Remove from favourites"
                                    classes={'bg-[#b695f8]/10 text-[#b695f8] py-2  !text-sm font-[400] px-5 rounded-lg'}
                                    atClick={() => {
                                        handleMarkFavorite('no', selectedPeople);
                                    }}
                                />
                            )}

                            {/* <Button
                                title="Close jobs"
                                classes={'bg-thirdbg py-2 text-white !text-sm font-[400] px-5 rounded-lg'}
                                atClick={() => {
                                    setSelectedAction('Closed');
                                    setIsConfirmationOpen(true);
                                }}
                            />
                            <Button
                                title="Delete jobs"
                                classes={'bg-[#DCDCDC] py-2  !text-sm font-[400] px-5 rounded-lg'}
                                atClick={() => {
                                    setSelectedAction('deleted');
                                    setIsConfirmationOpen(true);
                                }}
                            /> */}
                        </div>
                    </div>
                </div>

                <div className="min-h-[500px] md:mx-10 mx-5r">
                    <div className=" pb-5 rounded-xl overflow-x-auto">
                        <div className=" flow-root">
                            <div className="inline-block min-w-full py-4 align-middle">
                                <div className="relative">
                                    <JobsTable
                                        jobs={jobs}
                                        jobStatuses={jobStatuses}
                                        handleUpdateValues={handleUpdateValues}
                                        setViewList={setViewList}
                                        setAllClients={setAllClients}
                                        currentTab={currentTab}
                                        handleOpenConfirmation={handleOpenConfirmation}
                                        setSelectedJob={setSelectedJob}
                                        setCreateJob={setCreateJob}
                                        ReturnSortIcons={ReturnSortIcons}
                                        handleSelectPeople={handleSelectPeople}
                                        selectedPeople={selectedPeople}
                                        setSelectedPeople={setSelectedPeople}
                                        handleMarkFavorite={handleMarkFavorite}
                                        handleDelete={handleDelete}
                                        colsMeta={allowedCols !== null ? allowedCols?.meta : JOBS_TABLE_COLS}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {jobs?.count > 0 && (
                    <div className="md:mx-10 mx-0">
                        <Pagination
                            page={Number(page)}
                            pageSize={Number(pageSize)}
                            totalPages={Math.ceil(jobs?.count / pageSize)}
                            onChangePage={(page) => handleChangePage(page, Number(pageSize))}
                            onCountPerPageChange={(perPageCount) => {
                                handleChangePage(1, perPageCount);
                            }}
                        />
                    </div>
                )}
                <CommandPalettes open={viewList} setOpen={setViewList} list={allClients} />
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
