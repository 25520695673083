import React, { useState } from 'react';
import PeoplePage from '../../components/payrol-pages/people-pages/PeoplePage';
import InviteEmployee from '../../components/payrol-pages/people-pages/InviteEmployee';
import EmploymentDetails from '../../components/payrol-pages/people-pages/EmploymentDetails';
const People = () => {
    const [showInviteEmployee, setShowInviteEmployee] = useState(false);
    const [showEmploymentDetails, setShowEmploymentDetails] = useState(false);

    const toggleInviteEmployee = () => {
        setShowInviteEmployee(!showInviteEmployee);
    };

    const toggleEmploymentDetails = () => {
        setShowEmploymentDetails(!showEmploymentDetails);
    };

    return (
        <div className="app-dashboard">
            {showEmploymentDetails && <EmploymentDetails toggleEmploymentDetails={toggleEmploymentDetails} />}
            {showInviteEmployee && <InviteEmployee toggleInviteEmployee={toggleInviteEmployee} />}

            <div className={`content ${''}`}>
                <PeoplePage
                    toggleInviteEmployee={toggleInviteEmployee}
                    toggleEmploymentDetails={toggleEmploymentDetails}
                />
            </div>
        </div>
    );
};

export default People;
