import AppSidebar from 'components/appSidebarComp/AppSidebar';
import { useEncryptedStorage } from 'Hooks/encryption/useEncryptedStorage';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSidebarOpen } from 'store/global/globalReducer';

export const SidebarLayout = ({ children }) => {
    const dispatch = useDispatch();
    const { isSidebarOpen: isOpen } = useSelector((state) => state.global);
    const { encryptAndSave, decryptAndRetrieve } = useEncryptedStorage();

    useEffect(() => {
        const retrieveData = async () => {
            const collapsV = await decryptAndRetrieve('sidebar', 'session');
            dispatch(setIsSidebarOpen(collapsV?.name ?? false));
        };
        retrieveData();
    }, []);

    const toggleSidebar = async () => {
        await encryptAndSave('sidebar', { name: !isOpen }, 'session');
        dispatch(setIsSidebarOpen(!isOpen));
    };

    return (
        <div className="app-dashboard w-full">
            {isOpen !== null && (
                <>
                    <AppSidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
                    <div>
                        <div>
                            <div className={`content ${isOpen ? 'squeeze' : ''}`}>{children}</div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
