import React from 'react';

export const ReplyAllIcon = ({ className }) => {
    return (
        <svg
            width="29"
            height="22"
            viewBox="0 0 29 22"
            fill="#B695F8"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M24.5625 10.0884H6.87286L10.8426 6.11868C11.1234 5.828 11.1234 5.36722 10.8426 5.07663C10.5549 4.77872 10.0801 4.77039 9.78215 5.05823L4.53212 10.3082C4.53203 10.3082 4.53212 10.3081 4.53212 10.3082C4.23934 10.601 4.23925 11.076 4.53212 11.3687L9.78215 16.6187C9.92259 16.7596 10.1135 16.8387 10.3124 16.8384C10.5113 16.8384 10.702 16.7594 10.8425 16.6188C11.1355 16.3259 11.1355 15.851 10.8427 15.5581L6.87286 11.5884H24.5625C25.8046 11.5898 26.8111 12.5963 26.8125 13.8384V20.5892C26.8128 21.0032 27.1485 21.3387 27.5625 21.3384C27.9764 21.3381 28.3128 21.0024 28.3125 20.5884V13.8384C28.3106 11.7681 26.6328 10.0903 24.5625 10.0884Z" />
            <g clipPath="url(#clip0_21471_2086)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.11896 11.853H20.8086C22.8789 11.8511 24.5567 10.1733 24.5586 8.10299V1.35299C24.5589 0.938988 24.2225 0.603264 23.8086 0.602989C23.3946 0.602715 23.0589 0.938164 23.0586 1.35217V8.10299C23.0572 9.34509 22.0507 10.3516 20.8086 10.353L9.90475 10.5727C10.1065 11.2035 9.63617 11.8482 8.97395 11.8486L3.11896 11.853ZM3.11896 10.353V11.853L7.0887 15.8227C7.36949 16.1134 7.36949 16.5742 7.0887 16.8648C6.80095 17.1627 6.32615 17.171 6.02824 16.8832L0.778218 11.6332C0.485431 11.3404 0.48534 10.8654 0.778218 10.5727L6.02824 5.32272C6.16868 5.18182 6.35957 5.10272 6.55852 5.10299C6.75737 5.10299 6.94807 5.182 7.08861 5.32263C7.38158 5.6155 7.38158 6.09039 7.08879 6.38327L3.11896 10.353Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_21471_2086">
                    <rect width="17" height="8" fill="white" transform="matrix(0 -1 1 0 0.3125 17.8477)" />
                </clipPath>
            </defs>
        </svg>
    );
};
