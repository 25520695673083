import moment from 'moment';

export const dynamicWidthgenerator = (start_date, end_date) => {
    if (start_date && end_date) {
        const formattedStartDate = moment(start_date, 'DD-MM-YYYY').startOf('day');
        const formattedEndDate = moment(end_date, 'DD-MM-YYYY').startOf('day');
        const formattedToday = moment().startOf('day');

        if (formattedToday.isBefore(formattedStartDate)) {
            return 0;
        }

        if (formattedToday.isAfter(formattedEndDate)) {
            return 100;
        }

        const totalDays = formattedEndDate.diff(formattedStartDate, 'days');

        const remainingDays = formattedEndDate.diff(formattedToday, 'days');

        const percentage = ((totalDays - remainingDays) / totalDays) * 100;

        return Math.ceil(percentage);
    }
};
