import React from 'react';
import { BoldArrowRight } from 'utills/svgs/BoldArrowRight';
import './css/alternateMethod.css';
import { useState } from 'react';

const AlternateMethodPage = (props) => {
    const [checkValue, setCheckValue] = useState('');

    const handleCheck = (event) => {
        if (event === true) {
            setCheckValue('code');
        } else {
            setCheckValue('');
        }
    };

    const handleSecondCheck = (event) => {
        if (event === true) {
            setCheckValue('email');
        } else {
            setCheckValue('');
        }
    };

    const navigateHandler = () => {
        if (checkValue === 'code') {
            props.handleCondition(2);
        } else {
            props.handleCondition(3);
        }
    };
    return (
        <div>
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    {' '}
                    <div>
                        <div>
                            <div className="text-center   card-layout-title-text mt-3 font-fira ">
                                Use Alternate Method
                            </div>

                            <div className="px-20">
                                <div className="small-text text-sixth font-poppins mt-10">
                                    You can choose your Alternate Method
                                </div>

                                <div className="radios mt-10">
                                    <div className="flex gap-2 buttons-font font-poppins justify-between">
                                        <div className="flex justify-center items-center ">
                                            <BoldArrowRight />
                                            <div className=" mx-2">By Backup Code</div>
                                        </div>

                                        <div className="flex justify-center items-center mt-1">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleCheck(e.target.checked)}
                                                checked={checkValue === 'code' ? true : false}
                                                className="!rounded-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="small-text text-sixth font-poppins mt-2 ml-3  w-[90%]">
                                        Use the provided backup codes. You can Sign In to your account by using a Backup
                                        code
                                    </div>

                                    <div className="flex gap-2 buttons-font font-poppins justify-between mt-5">
                                        <div className="flex justify-center items-center ">
                                            <BoldArrowRight />
                                            <div className="bel mx-2">By Phone Number Verification</div>
                                        </div>

                                        <div className="flex justify-center items-center mt-1">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => handleSecondCheck(e.target.checked)}
                                                checked={checkValue === 'email' ? true : false}
                                                className="!rounded-sm"
                                            />
                                        </div>
                                    </div>

                                    <div className="small-text text-sixth font-poppins  mt-2 ml-3 w-[90%]">
                                        We’ll send you a message with code to an authorized phone number.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center items-center mt-60 mb-10">
                            <button
                                disabled={!checkValue}
                                onClick={navigateHandler}
                                className="px-12 py-2 rounded-lg ann-btn buttons-font font-fira"
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlternateMethodPage;
