import React from 'react';
import StepOne from '../components/signup-steps/StepOne';
import StepThree from '../components/signup-steps/StepThree';
import StepFour from '../components/signup-steps/StepFour';
import SignupSuccess from '../components/signup-steps/SignupSuccess';
import PrivacyPolicy from '../components/signup-steps/PrivacyPolicy';

import { useState } from 'react';

import { TermsAndConditions } from './sign-up-pages/TermsAndConditions';
import { PhoneVerification } from 'components/signup-steps/PhoneVerification';
import { EmailLinkVerification } from './../components/signup-steps/EmailLinkVerification';

const Home = () => {
    const signupStepper = JSON.parse(sessionStorage.getItem('signupStepper'));
    const [connection, setConnection] = useState(null);
    const [password, setPassword] = useState('');

    const handlePassword = (pass) => {
        setPassword(pass);
    };

    const [condition, setCondition] = useState(signupStepper ?? 1);

    const [email, setEmail] = useState('');

    const handleEmail = (email) => {
        setEmail(email);
    };

    const handleCondition = (condition, connection) => {
        sessionStorage.setItem('signupStepper', condition);
        setConnection(connection);

        setCondition(condition);
    };

    function MyComponent() {
        return (
            <div>
                {condition === 1 && <StepOne handleCondition={handleCondition} handleEmail={handleEmail} />}
                {condition === 2 && (
                    <EmailLinkVerification handleCondition={handleCondition} email={email} connection={connection} />
                )}

                {condition === 3 && <PhoneVerification handleCondition={handleCondition} email={email} />}

                {condition === 4 && <StepThree handleCondition={handleCondition} email={email} />}
                {condition === 5 && (
                    <StepFour handleCondition={handleCondition} email={email} handlePassword={handlePassword} />
                )}
                {condition === 6 && <SignupSuccess handleCondition={handleCondition} email={email} pass={password} />}
                {condition === 7 && <TermsAndConditions handleCondition={handleCondition} email={email} />}
                {condition === 8 && <PrivacyPolicy handleCondition={handleCondition} />}
            </div>
        );
    }
    return (
        <div className="user-login">
            <div>{MyComponent()}</div>
        </div>
    );
};

export default Home;
