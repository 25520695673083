import React from 'react';
import JobPortalTools from './Tools/JobPortalTools';

export const WorkStation = ({ handleGetSingleJob, disabled = false }) => {
    return (
        <>
            <div className="mt-3">
                <JobPortalTools disabled={disabled} handleGetSingleJob={handleGetSingleJob} />
            </div>
        </>
    );
};
