import { useDispatch } from 'react-redux';
import { getBranchLocationsThunk } from 'store/auth/slices';

export const useGetAllBranches = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem('business_id');
    const getBranches = () => {
        dispatch(getBranchLocationsThunk({ business_id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };
    return { getBranches };
};
