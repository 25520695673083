import React, { useState } from 'react';
import { TextValidation, containsOnlyIntegers, decimalToInteger } from 'utills/FormValidation';
import '../css/atoms.css';
import { cn } from 'utills/tailwindUtil';
import { countWords } from 'utills/dataValidation';
export const Input = ({ value, onChange, maxLength, placeholder, width, required, type, sx, maxWords }) => {
    const [inValidInput, setInvalidInput] = useState(false);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;

        if (type === 'text') {
            if (TextValidation(inputValue)) {
                setInvalidInput(true);
                return;
            }
        } else if (type === 'number') {
            const number = decimalToInteger(inputValue);
            if (!containsOnlyIntegers(number)) {
                setInvalidInput(true);
                return;
            }
        }

        if (maxWords && countWords(inputValue) > maxWords) {
            setInvalidInput(`Max ${maxWords} word is allowed`);
            return;
        }

        if (maxLength && inputValue.length > maxLength) {
            setInvalidInput(true);
            return;
        }
        setInvalidInput(false);

        onChange(inputValue);
    };
    return (
        <div>
            <div>
                <div>
                    {' '}
                    <input
                        type={type || 'text'}
                        placeholder={placeholder}
                        value={value}
                        onChange={handleInputChange}
                        className={cn(
                            ' px-2 py-1 h-size8 border border-border1 rounded-lg focus:outline-none focus:border-border2  atom-input-field',
                            sx || ''
                        )}
                        style={{
                            borderColor: inValidInput ? 'red' : '',
                            width: width ?? '100%'
                        }}
                        maxLength={maxLength}
                        required={required}
                    />
                </div>
                {inValidInput && <span className="error-div"> {inValidInput || 'Invalid Input'} </span>}
            </div>
        </div>
    );
};
