import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Portal = ({ children, parentRef, isOpen, portalProps = {} }) => {
    const [position, setPosition] = useState(null);
    const [lastKnownPosition, setLastKnownPosition] = useState({ top: null, left: null });

    const updatePosition = () => {
        if (parentRef?.current) {
            const parentRect = parentRef.current.getBoundingClientRect();

            const newTop = parentRect.top + parentRect.height + window.scrollY;
            const newLeft = parentRect.left + window.scrollX;

            if (lastKnownPosition.top !== newTop || lastKnownPosition.left !== newLeft) {
                setLastKnownPosition({ top: newTop, left: newLeft });
                setPosition({
                    top: newTop,
                    left: newLeft,
                    width: parentRect.width
                });
            }
        }
    };

    useEffect(() => {
        updatePosition();

        window.addEventListener('scroll', updatePosition);
        window.addEventListener('resize', updatePosition);

        if (parentRef?.current) {
            parentRef.current.addEventListener('scroll', updatePosition);
        }

        return () => {
            window.removeEventListener('scroll', updatePosition);
            window.removeEventListener('resize', updatePosition);
            if (parentRef?.current) {
                parentRef.current.removeEventListener('scroll', updatePosition);
            }
        };
    }, [parentRef?.current?.getBoundingClientRect(), lastKnownPosition]);

    return position && isOpen
        ? createPortal(
              <div
                  data-portal
                  style={{
                      position: 'absolute',
                      top: `${position.top}px`,
                      left: `${position.left}px`,
                      width: `${position.width}px`,
                      zIndex: 99999,
                      height: Object.entries(portalProps)?.length > 0 ? '50px' : 'auto'
                  }}
                  {...portalProps}
              >
                  <div>{children}</div>
              </div>,
              document.body
          )
        : null;
};
