import React from 'react';

export const UploadIcon = () => {
    return (
        <div>
            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.60952 2.60938C9.44156 2.69531 9.11343 3 8.05874 4.06641C7.09781 5.03516 6.69937 5.46875 6.64077 5.58984C6.39077 6.14062 6.79312 6.75781 7.40249 6.75781C7.7189 6.75781 7.86343 6.65625 8.53531 5.98047L9.16031 5.35156L9.17984 9.07422L9.19937 12.8008L9.30484 12.9531C9.36343 13.0352 9.47671 13.1484 9.55874 13.2031C9.67984 13.2852 9.74624 13.3008 10.0001 13.3008C10.2541 13.3008 10.3205 13.2852 10.4416 13.2031C10.5236 13.1484 10.6369 13.0352 10.6955 12.9531L10.8009 12.8008L10.8205 9.07422L10.84 5.35156L11.465 5.98047C12.1369 6.65625 12.2814 6.75781 12.5978 6.75781C13.0548 6.75781 13.4376 6.38281 13.4376 5.9375C13.4376 5.61719 13.4142 5.58984 11.922 4.08594C10.9728 3.13281 10.4337 2.625 10.3322 2.57422C10.1056 2.46875 9.86343 2.48047 9.60952 2.60938Z"
                    fill="url(#paint0_linear_4409_1280)"
                />
                <path
                    d="M1.43756 8.36719C1.35553 8.38672 1.211 8.47656 1.11725 8.5625C0.808652 8.83203 0.820371 8.69531 0.820371 12.1445C0.820371 14.0547 0.835996 15.2695 0.86334 15.4258C1.0235 16.4141 1.7149 17.1758 2.67975 17.4219C2.97271 17.5 3.14068 17.5 10.0001 17.5C16.8594 17.5 17.0274 17.5 17.3204 17.4219C18.2852 17.1758 18.9766 16.4141 19.1368 15.4258C19.1954 15.0664 19.1954 9.19531 19.1368 8.96484C19.1094 8.85938 19.0274 8.71875 18.9376 8.61328C18.5547 8.17578 17.8633 8.26953 17.6016 8.79297L17.5001 9V12.1172C17.5001 14.6836 17.4922 15.2578 17.4454 15.3633C17.379 15.5156 17.168 15.7227 17.0196 15.7773C16.9415 15.8086 14.7774 15.8203 9.99615 15.8203C4.20318 15.8203 3.06256 15.8125 2.95709 15.7656C2.80475 15.6992 2.59771 15.4883 2.54303 15.3398C2.51178 15.2656 2.50006 14.2148 2.50006 12.1094V8.99219L2.39068 8.78516C2.211 8.4375 1.80865 8.26172 1.43756 8.36719Z"
                    fill="url(#paint1_linear_4409_1280)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_4409_1280"
                        x1="10.3387"
                        y1="13.1667"
                        x2="10.326"
                        y2="2.50301"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_4409_1280"
                        x1="10.9014"
                        y1="17.3862"
                        x2="10.898"
                        y2="8.33549"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
