import { Button } from 'components/atoms/buttons/Button';
import DefaultSwitch from 'components/atoms/inputs/DefaultSwitch';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
// import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { toBoolean } from 'utills/handleText';
import { BgFormsIcon } from 'utills/svgs/BgFormsIcon';
import { BgJobsIcon } from 'utills/svgs/BgJobsIcon';
import { AddOnboardingService } from './AddOnboardingService';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import {
    deleteOnboardingServicesThunk,
    getOnboardingServices,
    getOnboardingServicesThunk,
    toggleOnboardingServiceThunk
} from 'store/settings/onboardingServices';
import Pagination from 'components/pagination';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { BUSINESS_ID, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { cn } from 'utills/tailwindUtil';
import { SortIcon } from 'utills/svgs/SortIcon';
import { Loader } from 'components/common/Loader';
import { NoDataFound } from 'components/common/NoDataFound';
import ConfirmationModal from 'utills/confirmationModal';
import cloneDeep from 'lodash/cloneDeep';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { ONBOARDING_SERVICES, PERMISSIONS } from 'utills/constants/forms/roleManagement.constants';
import { toastHandler } from 'responseHanlder';

export const OnboardingServices = () => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.getOnboardingServices);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [searchTerm, setSearchTerm] = useState('');
    const [add, setAdd] = useState(false);
    const [selected, setSelected] = useState(null);
    const [order, setOrder] = useState('ASC');
    const { isLoading } = useSelector((state) => state.global);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const { hasPermissions } = useRoleGuard();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE ?? 10);

    const handleGetServices = (payload) => {
        dispatch(setLoader(true));
        dispatch(getOnboardingServicesThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const handleChangePage = (newPage, perPageCount) => {
        setPage(newPage);
        setPageSize(perPageCount);
        handleGetServices({
            page: newPage,
            page_size: perPageCount,
            business_id,
            search: searchTerm,
            order_by: order
        });
    };

    const resetGetServices = () => {
        setPage(1);
        handleGetServices({ page: 1, page_size: pageSize, business_id, search: searchTerm, order_by: order });
    };
    useEffect(() => {
        if (hasPermissions(ONBOARDING_SERVICES, [PERMISSIONS.ADMIN, PERMISSIONS.READ])) {
            resetGetServices();
        }
    }, [searchTerm, order]);

    const toggleService = (client_service_id, val) => {
        if (!hasPermissions(ONBOARDING_SERVICES, [PERMISSIONS.ADMIN])) {
            return toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }

        const newData = cloneDeep(data);

        const payload = {
            client_service_id: client_service_id,
            business_id,
            is_live: `${!val}`
        };

        dispatch(setLoader(true));
        dispatch(toggleOnboardingServiceThunk(payload))
            .then((res) => {
                if (res.payload) {
                    const findItem = newData?.rows?.find((item) => item?.client_service_id === client_service_id);
                    findItem.is_live = `${!val}`;
                    dispatch(getOnboardingServices.actions.handleUpdate(newData));
                    // resetGetServices();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const deleteService = () => {
        setIsConfirmationOpen(false);
        const payload = {
            client_service_id: deleteId,
            business_id
        };

        dispatch(setLoader(false));
        dispatch(deleteOnboardingServicesThunk(payload))
            .then((res) => {
                if (res.payload) {
                    resetGetServices();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <SidebarLayout>
            <RoleBasedGuard module={ONBOARDING_SERVICES} permissions={[PERMISSIONS.ADMIN, PERMISSIONS.READ]} sx="p-5">
                {isLoading && <Loader />}
                <Jumbotron
                    title="Onboarding Services"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },
                        {
                            link: 'settings/onboarding-services',
                            title: 'Onboarding Services'
                        }
                    ]}
                />

                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={deleteService}
                />

                {add && (
                    <AddOnboardingService
                        service={selected}
                        open={add}
                        setOpen={() => {
                            setAdd(false);
                            setSelected(null);
                        }}
                        onComplete={() => resetGetServices()}
                    />
                )}
                <div className="md:mx-10 mx-5 ">
                    <div className="flex justify-between items-center gap-5 flex-wrap">
                        <div className="flex gap-2 items-center">
                            <SearchInput
                                atChange={(e) => setSearchTerm(e.target.value)}
                                width="300px"
                                placeholder="Search"
                                value={searchTerm}
                            />
                            <div className="flex gap-2 items-center border border-secondarybg cursor-pointer  min-h-[3rem]  px-2 py-1 rounded-lg">
                                <span className={cn(order === 'DESC' && 'rotate-180')}>
                                    {' '}
                                    <SortIcon />{' '}
                                </span>
                                <span
                                    onClick={() => {
                                        if (order === 'ASC') {
                                            setOrder('DESC');
                                        } else {
                                            setOrder('ASC');
                                        }
                                    }}
                                    className="text-sm text-secondarybg"
                                >
                                    Sort By:
                                    {order === 'ASC' ? ' A - Z' : ' Z - A'}
                                </span>
                            </div>
                        </div>
                        <Button
                            title="Add Service"
                            atClick={() => setAdd(!add)}
                            classes="ann-btn py-3 flex gap-2 items-center font-[400]  px-6 rounded-lg "
                        />
                    </div>

                    <div className="mt-5">
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  grid-cols-1 max-w-[2000px] m-auto gap-5">
                            {data?.rows?.map((service, index) => (
                                <div
                                    key={index}
                                    className="bg-white rounded-xl primary-shadow hover:bg-[#F8F4FE]  transition-all hover:ring-thirdbg hover:ring-1 max-h-[400px] p-5  overflow-hidden hover:overflow-y-auto w-full"
                                >
                                    <div className="flex cursor-pointer  justify-end items-center gap-2">
                                        <DefaultSwitch
                                            checked={toBoolean(service?.is_live)}
                                            onChange={(_e) =>
                                                toggleService(service?.client_service_id, toBoolean(service?.is_live))
                                            }
                                            // disabled={toBoolean(service?.service_category === 'prebuilt')}
                                        />
                                        <span
                                            onClick={() => {
                                                if (!hasPermissions(ONBOARDING_SERVICES, [PERMISSIONS.ADMIN])) {
                                                    return toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
                                                }

                                                // if (service?.service_category !== 'prebuilt') {
                                                setSelected(service);
                                                setAdd(true);
                                                // }
                                            }}
                                            className={cn(
                                                'cursor-pointer'
                                                // service?.service_category === 'prebuilt' &&
                                                //     '!cursor-not-allowed opacity-50'
                                            )}
                                        >
                                            <BgEditIcon />
                                        </span>

                                        <span
                                            onClick={() => {
                                                if (!hasPermissions(ONBOARDING_SERVICES, [PERMISSIONS.ADMIN])) {
                                                    return toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
                                                }
                                                if (service?.service_category !== 'prebuilt') {
                                                    setDeleteId(service?.client_service_id);
                                                    setIsConfirmationOpen(true);
                                                }
                                            }}
                                            className={cn(
                                                'cursor-pointer',
                                                service?.service_category === 'prebuilt' &&
                                                    '!cursor-not-allowed opacity-50'
                                            )}
                                        >
                                            <BgDeleteIcon />
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        {/* <DefaultProfileViewer

                                        image={service?.service_icon}
                                        name={service?.title}
                                        width={'60px'}
                                        height={'60px'}
                                    /> */}

                                        <div
                                            className={`flex flex-row overflow-hidden  cursor-pointer items-center gap-2 w-16 h-16 rounded-md `}
                                        >
                                            <img
                                                src={service?.service_icon}
                                                alt="icon"
                                                className="w-full h-full bg-transparent"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <PrimaryLabel sx="!mt-2">{service?.title}</PrimaryLabel>
                                        {/* <p>{service.description}</p> */}
                                    </div>

                                    <div className="flex gap-2 items-center mt-3">
                                        <div>
                                            <BgFormsIcon />
                                        </div>
                                        <div>
                                            <SecondaryParagraph sx="text-[#898989] text-xs font-[400]">
                                                Linked From
                                            </SecondaryParagraph>
                                            <SecondaryParagraph sx="underline  underline-offset-4  text-[##0A1E46]  font-[400]">
                                                {service?.form?.form_name ?? '--'}
                                            </SecondaryParagraph>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 items-center mt-5">
                                        <div>
                                            <BgJobsIcon />
                                        </div>
                                        <div>
                                            <SecondaryParagraph sx="text-[#898989] text-xs font-[400]">
                                                Job Type
                                            </SecondaryParagraph>
                                            <SecondaryParagraph sx="  text-[#0A1E46]  font-[400]">
                                                {service?.job_type?.label ?? '--'}
                                            </SecondaryParagraph>
                                        </div>
                                    </div>

                                    <div>
                                        <PrimaryParagraph sx={'text-[#1E1E1E] mt-5'}>
                                            {service?.description ?? '--'}
                                        </PrimaryParagraph>
                                    </div>
                                </div>
                            ))}
                            <div></div>
                        </div>
                    </div>

                    {!isLoading && (data?.count < 1 || !data?.count) && (
                        <NoDataFound message="No Onboarding Service Found!" />
                    )}

                    {data?.count >= 0 && (
                        <div className="md:mx-10 mx-0">
                            <Pagination
                                page={Number(page)}
                                pageSize={Number(pageSize)}
                                totalPages={Math.ceil(data?.count / pageSize)}
                                // rows={items.length}
                                onChangePage={(page) => handleChangePage(page, Number(pageSize))}
                                onCountPerPageChange={(perPageCount) => {
                                    handleChangePage(1, perPageCount);
                                }}
                            />
                        </div>
                    )}
                </div>
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
