/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SmallLoader } from 'components/common/SmallLoader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuthInfothunk, login, skipSetupThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import { BlueTick } from 'utills/svgs/BlueTick';
import { LaptopMobile } from 'utills/svgs/LaptopMobile';
import whitelogo from '../../files/whiteBgLogo.png.png';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN } from 'utills/globalVars';

export const TwoFactorAuthentication = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.login);
    const [userData, setUserData] = useState(null);
    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};

    const [complete_two_fa, setComplete_two_fa] = useState(false);
    const { isLoading } = useSelector((state) => state.global);
    useEffect(() => {
        const dataConfig = { user_type: 'employee', isBeforeLogin: true, email: parsedData?.email };
        dispatch(setLoader(true));

        dispatch(getAuthInfothunk(dataConfig))
            .then((response) => {
                if (response.payload) {
                    setUserData(response.payload);
                    if (response.payload.email_2fa && response.payload.google_authenticator_2fa) {
                        setComplete_two_fa(true);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    const handleNavigate = () => {
        localStorage.setItem('verified', true);
        if (data) {
            if (data.is_password_change_required && data?.is_employee) {
                navigate(`/update-ot-password/${data.user_id}`);
            } else if (data?.is_employee && !data.is_password_change_required) {
                navigate('/settings');
            } else {
                navigate('/onboarding');
            }
        }
    };

    const navigateTo = () => {
        dispatch(skipSetupThunk())
            .then((response) => {
                if (response.payload) {
                    localStorage.removeItem('temp_token');
                    localStorage.setItem(ACCESS_TOKEN, response.payload?.access_token);
                    localStorage.setItem(REFRESH_TOKEN, response.payload?.refresh_token);
                    localStorage.setItem(BUSINESS_ID, response.payload?.business?.business_id);
                    dispatch(login.actions.handleUpdate(response.payload));
                    handleNavigate();
                }
            })
            .then((error) => {});
    };
    return (
        <div className="md:flex justify-center flex-wrap  items-center w-[100%] h-[100vh] gap-2 overflow-y-auto relative">
            <div className="absolute left-5 top-5">
                <img src={whitelogo} alt="logo" className="Synkli-logo" />
            </div>
            <div className="mt-28 md:mt-0   max-w-[650px] rounded-lg min-h-[90vh]  bg-white p-3">
                <div className="card-heading-two font-poppins px-5 pt-4 ">
                    <h1>Two Factor Authentication</h1>
                </div>

                <div className="flex justify-center items-center pt-10">
                    <LaptopMobile />
                </div>

                <div className="text-center table-title font-poppins mt-5">Add extra security to your account</div>

                <div className="text-secondarybg small-text font-popppins px-5 mt-3">
                    <p>Please enable the Two Factor authentication process to enhance the security of your account.</p>
                </div>

                <div className="authentication  py-3 w-[65%]">
                    <div className=" flex px-3 items-center gap-1">
                        <div className="flex justify-center items-center">
                            <div>
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div className="buttons-font-lighter font-poppins">Google Authenticator</div>
                    </div>
                    <div className="">
                        {isLoading ? (
                            <div>
                                <SmallLoader />{' '}
                            </div>
                        ) : (
                            <div>
                                {' '}
                                {userData && userData.google_authenticator_2fa ? (
                                    <BlueTick />
                                ) : (
                                    <button
                                        onClick={() => props.handleCondition(2)}
                                        className="px-3 py-1 bg-linear rounded-xl small-text font-fira text-white"
                                    >
                                        Set Up
                                    </button>
                                )}{' '}
                            </div>
                        )}
                    </div>
                </div>
                {userData?.email_2fa === 'true' && userData.google_authenticator_2fa === 'true' ? (
                    <div className="">
                        {' '}
                        <div className="flex justify-center mx-8 ro mt-20">
                            <button onClick={() => navigateTo()} className="proceed-btns px-10 rounded-md py-2">
                                Continue
                            </button>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};
