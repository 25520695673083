import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { getAllJobTypesThunk } from 'store/JobManager';
import { BUSINESS_ID } from 'utills/globalVars';

export const useGetJobTypes = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const getJobTypes = () => {
        dispatch(setLoader(true));
        dispatch(getAllJobTypesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    return { getJobTypes };
};
