import React from 'react';

export const NumberList = () => {
    return (
        <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.199951 0H2.26881V5.27222H1.17224V1.20379H0.199951V0Z" fill="#656565" />
            <path
                d="M1.56066 10.5756C1.81984 10.2912 2.00378 10.0655 2.11247 9.89846C2.22534 9.7269 2.28177 9.56212 2.28177 9.40411C2.28177 9.24611 2.2337 9.11518 2.13755 9.01135C2.0414 8.903 1.91808 8.84883 1.76759 8.84883C1.49168 8.84883 1.23041 9.06101 0.983768 9.48538L0.199951 8.98426C0.404789 8.64116 0.628438 8.38157 0.870899 8.20551C1.11754 8.02944 1.42898 7.94141 1.80521 7.94141C2.18562 7.94141 2.51796 8.07233 2.80222 8.33417C3.09067 8.5915 3.23489 8.94363 3.23489 9.39057C3.23489 9.63435 3.17637 9.86911 3.05932 10.0948C2.94645 10.316 2.73534 10.6005 2.42599 10.9481L1.6359 11.8352H3.36657V12.7832H0.275198V11.9977L1.56066 10.5756Z"
                fill="#656565"
            />
            <rect x="8.1167" y="1.05469" width="11.0832" height="3.22789" rx="1.61394" fill="#656565" />
            <rect x="8.11652" y="9.55469" width="11.0832" height="3.22789" rx="1.61394" fill="#656565" />
        </svg>
    );
};
