import { useDispatch } from 'react-redux';
import { getClientsDirectorsThunk } from 'store/client';

export const useGetDirectors = () => {
    const dispatch = useDispatch();

    const handleGetDirectors = async (business_client_id) => {
        if (business_client_id) {
            return await dispatch(getClientsDirectorsThunk({ bc_id: business_client_id })).then((response) => {
                if (response.payload) {
                    return response.payload;
                }
            });
        }
    };
    return { handleGetDirectors };
};
