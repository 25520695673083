'use client';

import React from 'react';
import { FieldRootContainer } from './field';
import { XMarkIcon } from '@heroicons/react/20/solid';

export const SigningFieldContainer = ({ field, _loading = false, onSign, onRemove, children }) => {
    const onSignFieldClick = async () => {
        if (field.inserted) {
            return;
        }
        onSign();
    };

    const onRemoveSignedFieldClick = async () => {
        if (!field.inserted) {
            return;
        }

        onRemove();
    };

    return (
        <FieldRootContainer field={field}>
            {!field.inserted && (
                <button type="submit" className="absolute inset-0 z-10 h-full w-full" onClick={onSignFieldClick} />
            )}

            {field.inserted && (
                <button
                    className="text-destructive1 bg-background/40 absolute inset-0 z-10 flex w-5 h-5 left-[93%] -top-1 items-center justify-center text-sm opacity-100 backdrop-blur-sm duration-200 group-hover:opacity-100 bg-red-400 rounded-full text-white"
                    onClick={onRemoveSignedFieldClick}
                >
                    <XMarkIcon className="w-4 h-4" />
                </button>
            )}

            {children}
        </FieldRootContainer>
    );
};
