import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { request: createGroupThunk } = generateThunkAndSlice('createGroup', endpoint.createGroup, methods.POST);
const { slice: getGroups, request: getGroupsThunk } = generateThunkAndSlice(
    'getGroups',
    endpoint.getGroups,
    methods.GET
);
const { request: deleteGroupThunk } = generateThunkAndSlice('deleteGroup', endpoint.deleteGroup, methods.DELETE);
const { request: updateGroupThunk } = generateThunkAndSlice('updateGroup', endpoint.updateGroup, methods.PUT);
const { request: resetModuleThunk } = generateThunkAndSlice('resetModule', endpoint.resetModule, methods.PUT);

export { createGroupThunk, getGroups, getGroupsThunk, deleteGroupThunk, updateGroupThunk, resetModuleThunk };
