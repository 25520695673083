import React from "react";

export const StorageIcon = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.30305 30.1061L3.30355 30.1089C3.4757 31.0869 3.9546 31.9034 4.72618 32.541C5.49516 33.1775 6.38626 33.5 7.38057 33.5H26.6194C27.6139 33.5 28.5055 33.1774 29.2756 32.541L29.276 32.5407C30.0463 31.903 30.5244 31.0866 30.6965 30.1089L30.6969 30.1061L33.4648 13.8394C33.5802 13.1612 33.4106 12.5357 32.9744 11.998C32.5279 11.4449 31.9212 11.1667 31.2023 11.1667H2.79774C2.07903 11.1667 1.47253 11.4447 1.02613 11.9974C0.589499 12.5352 0.419761 13.1609 0.535209 13.8394L3.30305 30.1061ZM12.4283 21.4647L12.427 21.4635C12.1786 21.221 12.055 20.9297 12.055 20.5556C12.055 20.1813 12.1786 19.8891 12.4276 19.6452C12.6756 19.4023 12.9782 19.2778 13.37 19.2778H20.63C21.0215 19.2778 21.3249 19.4021 21.5743 19.6453C21.8218 19.8889 21.945 20.1811 21.945 20.5556C21.945 20.9303 21.8216 21.2218 21.5742 21.4641C21.3246 21.7086 21.0213 21.8333 20.63 21.8333H13.37C12.9787 21.8333 12.6764 21.7086 12.4283 21.4647ZM4.4655 9.73526L4.46675 9.73647C4.91426 10.1733 5.47342 10.3889 6.11009 10.3889H27.8899C28.5266 10.3889 29.0855 10.1733 29.5321 9.73586C29.98 9.2971 30.2049 8.7443 30.2049 8.11111C30.2049 7.47792 29.98 6.92513 29.5321 6.48636C29.0855 6.04897 28.5266 5.83333 27.8899 5.83333H6.11009C5.47342 5.83333 4.91426 6.04894 4.46675 6.48575L4.46551 6.48697C4.01897 6.92587 3.7951 7.47848 3.7951 8.11111C3.7951 8.74374 4.01897 9.29635 4.4655 9.73526ZM8.09672 1.15242L8.09548 1.15364C7.64895 1.59254 7.42507 2.14515 7.42507 2.77778C7.42507 3.41043 7.64899 3.96281 8.0961 4.40075C8.5435 4.83898 9.10286 5.05556 9.74006 5.05556H24.2599C24.8969 5.05556 25.4558 4.83915 25.9021 4.40075C26.3502 3.96304 26.5749 3.4107 26.5749 2.77778C26.5749 2.14459 26.35 1.59179 25.9021 1.15303C25.4555 0.715637 24.8966 0.5 24.2599 0.5H9.74006C9.10339 0.5 8.54423 0.715605 8.09672 1.15242Z"
          stroke="black"
        />
      </svg>
    </div>
  );
};
