import React from 'react'

export const BluePencil = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.1" width="22" height="22" rx="11" fill="#B695F8"/>
    <path d="M10.6087 7.25L12.0109 8.65218L6.95652 13.6739V15.0435H8.32609L13.3804 10.0217L14.75 11.3913L9.1413 17H5V12.8587L10.6087 7.25ZM14.75 11.3913L10.6087 7.25L12.5 5.3587C12.7391 5.11957 13.0435 5 13.413 5C13.7826 5 14.087 5.11957 14.3261 5.3587L16.6413 7.67391C16.8804 7.91304 17 8.21739 17 8.58696C17 8.95652 16.8804 9.26087 16.6413 9.5L14.75 11.3913Z" fill="#B695F8"/>
    </svg>
    
  )
}
