import React, { useState } from 'react';
import TaskTypePage from '../../../components/workplace-content/tasks-pages/organizational-settings-pages/TaskTypePage';
import AddTaskType from '../../../components/workplace-content/tasks-pages/organizational-settings-pages/AddTaskType';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { EditType } from 'components/workplace-content/tasks-pages/organizational-settings-pages/EditType';
import { SidebarLayout } from 'layouts/SidebarLayout';
const TaskType = () => {
    const [showTaskType, setShowTaskType] = useState(false);
    const [typesUpdated, setTypesUpdated] = useState(false);
    const [editType, setEditType] = useState(false);

    const toggleEditType = () => {
        setEditType(!editType);
    };
    const toggleTaskType = () => {
        setShowTaskType(!showTaskType);
    };

    const toggletypesUpdated = () => {
        setTypesUpdated(!typesUpdated);
    };

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showTaskType && (
                    <AddTaskType
                        toggleTaskType={toggleTaskType}
                        toggletypesUpdated={toggletypesUpdated}
                        open={showTaskType}
                        setOpen={setShowTaskType}
                    />
                )}
                {editType && <EditType open={editType} setOpen={setEditType} toggletypesUpdated={toggletypesUpdated} />}
                <div className="md:mx-10 mx-5">
                    <RoleBasedGuard module={'Tasks'} permissions={['admin', 'read', 'write']} sx="h-[50vh]">
                        <TaskTypePage
                            toggleTaskType={toggleTaskType}
                            typesUpdated={typesUpdated}
                            toggleEditType={toggleEditType}
                        />
                    </RoleBasedGuard>
                </div>
            </SidebarLayout>
        </div>
    );
};

export default TaskType;
