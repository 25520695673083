import React from 'react';
import { EmailManager } from 'pages/email-manager/EmailManager';
import { Protected } from './protected-routes/Protected';

const emailSettingsRoutes = [
    {
        path: '/email-manager',
        component: <Protected Component={EmailManager} />,
        isProtected: false,
        layout: 'header'
    }
];

export default emailSettingsRoutes;
