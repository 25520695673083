import Badge from 'components/atoms/Badge';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import React, { useEffect, useState } from 'react';
import pdfIcon from 'files/pdfIcon.svg';
import { FileIconSvg } from 'utills/svgs/FileIconSvg';

const SelectedFiles = ({ files, updateSelectedFiles }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showImage, setShowImage] = useState(null);

    useEffect(() => {
        if (files) {
            setSelectedFiles(files);
        }
    }, [files]);

    const handleFileRemoveImg = (index) => {
        const newImages = [...selectedFiles];
        newImages.splice(index, 1);
        setSelectedFiles(newImages);
        updateSelectedFiles(newImages);
    };

    return (
        <div>
            {selectedFiles.length > 0 && (
                <div>
                    <div className="flex items-center flex-wrap gap-2 my-3">
                        {selectedFiles.map((file, index) => (
                            <div key={'selectedfiles' + index}>
                                <div className="flex items-start gap-3 flex-wrap">
                                    <Badge
                                        leftIcon={
                                            file.type.startsWith('image/') ? (
                                                <img
                                                    src={
                                                        file.type.startsWith('image/')
                                                            ? URL.createObjectURL(file)
                                                            : pdfIcon
                                                    }
                                                    alt="alt"
                                                    className="thumbnail-image cursor-pointer !w-8 !h-8 rounded-md"
                                                    onClick={() => {
                                                        if (file.type.startsWith('image/')) {
                                                            setShowImage(URL.createObjectURL(file));
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <FileIconSvg className={'stroke-white !w-7 !h-7 mr-1'} />
                                            )
                                        }
                                        text={file?.name}
                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                        onCancel={() => handleFileRemoveImg(index)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    {showImage && <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />}
                </div>
            )}
        </div>
    );
};

export default SelectedFiles;
