import React, { useState } from 'react';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import { toBoolean } from 'utills/handleText';
import { ResetIcon } from 'utills/ResetIcon';
import { Button } from 'components/atoms/buttons/Button';
import DefaultSwitch from 'components/atoms/inputs/DefaultSwitch';
import { FillArrow } from 'utills/svgs/FillArrow';
import { ModuleSetting } from './ModuleSetting';
import ConfirmationModal from 'utills/confirmationModal';
import { updateNotificationSettingsThunk } from 'store/settings/notification-settings';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
export const NotificationModule = ({
    module,
    key,
    moduleIndex,
    ReturnModuleIcon,
    handleResetModule,
    updateObjSetting,
    handleAddReminder,
    handleRemoveReminder,
    getOrdinalValue,
    handleReturnPossibleOptions,
    handleScheduleValueChange,
    handleupdatesettingFun,
    handleGetNotificationModules,
    handleSwitchChange
}) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const dispatch = useDispatch();
    const [closeId, setCloseId] = useState('');
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { hasPermissions } = useRoleGuard();

    const updateNotificationSettings = (notification_module_id, val) => {
        if (!hasPermissions('Notification_setting', ['admin', 'read'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(updateNotificationSettingsThunk({ notification_module_id, business_id, is_enable: `${!val}` }))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Notification settings updated', TOAST_TYPE_SUCCESS);
                    if (val === true) {
                        setCloseId(notification_module_id);
                    } else {
                        setCloseId('');
                    }
                    handleGetNotificationModules();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <CollapsDivComponent
            h_two="max-h-[2300px]"
            defaultClose
            title={module?.name}
            titleIcon={ReturnModuleIcon(module?.name)}
            ArrowIcon={<FillArrow />}
            extra={
                <div className="flex gap-2 items-center mr-2">
                    <Button
                        atClick={() => setIsConfirmationOpen(true)}
                        iconSet={{
                            leftIconHTML: (
                                <ResetIcon
                                    className={
                                        'h-5 w-5 group-hover:invert group-hover:transform transition-all     group-hover:rotate-[-45deg] '
                                    }
                                />
                            )
                        }}
                        tooltip={`Reset ${module?.name} Notifications`}
                        classes="bg-[#f5f5f5] rounded-lg text-sm  flex gap-2 items-center font-poppins font-[400] px-3 py-2 hover:bg-thirdbg hover:text-white"
                    />
                    <DefaultSwitch
                        checked={toBoolean(module.is_enable)}
                        onChange={(_e) =>
                            updateNotificationSettings(module?.notification_module_id, toBoolean(module.is_enable))
                        }
                    />
                </div>
            }
            classes="mt-3 primary-shadow "
            extraSx="!lg:gap-8 "
            headSx=" lg:px-7 py-4 px-3 border-b  hover:bg-[#F8F4FE] "
            key={moduleIndex + key}
            disabled={toBoolean(module.is_enable) ? false : true}
            forceClose={closeId === module?.notification_module_id ? true : false}
        >
            {' '}
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={() => {
                    setIsConfirmationOpen(false);
                    handleResetModule(module?.notification_module_id);
                }}
                description={`Do you want to reset ${module?.name ?? ''} Notifications ?`}
                confirmbtnTxt="Reset"
                title="Reset Confirmation"
            />
            {module?.settings && (
                <div className="pb-5">
                    {module?.settings?.map((item, index) => (
                        <ModuleSetting
                            item={item}
                            key={index}
                            index={index}
                            module={module}
                            moduleIndex={moduleIndex}
                            updateNotificationSettings={updateNotificationSettings}
                            closeId={closeId}
                            updateObjSetting={updateObjSetting}
                            handleAddReminder={handleAddReminder}
                            handleRemoveReminder={handleRemoveReminder}
                            getOrdinalValue={getOrdinalValue}
                            handleReturnPossibleOptions={handleReturnPossibleOptions}
                            handleScheduleValueChange={handleScheduleValueChange}
                            handleupdatesettingFun={handleupdatesettingFun}
                            handleGetNotificationModules={handleGetNotificationModules}
                            handleSwitchChange={handleSwitchChange}
                        />
                    ))}
                </div>
            )}
        </CollapsDivComponent>
    );
};
