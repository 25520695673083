import React from 'react'
import midnightForm from "../../files/midnightForm.png"
export const MidNightForm = () => {
  return (
    <div>
      
      <img  src={midnightForm} alt='alt' className='scale-75'/>
    </div>
  )
}
