import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNewsThunk } from 'store/workspace/workspaceNews';
import { BUSINESS_ID } from 'utills/globalVars';
import AnnouncementJumbo from './AnnouncementJumbo';
import AnnouncementList from './AnnouncementList';
import './css/announcement.css';
import { SearchInput } from 'components/molecules/SearchInput';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import moment from 'moment';
const AnnouncementContent = ({ toggleAnnDetails, handleAnn, toggleAddAnn, annUpdated: updatedAnn, toggleEditAnn }) => {
    const [filterDate, setFilterDate] = useState('');
    const { data } = useSelector((state) => state.getAllNews);
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [annUpdated, setAnnUpdated] = useState(false);
    const [filteredData, _setFilteredData] = useState([]);
    const [filterEnable, setFilterEnable] = useState('');
    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState(null);

    const [page, setPage] = useState(1);

    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);

    const toggleAnnUpdate = () => {
        setAnnUpdated(!annUpdated);
    };

    const onSearch = (e) => {
        const { value } = e.target;
        setSearchText(value);

        const payload = {
            page: 1,
            page_size: pageSize,
            business_id,
            search_term: value,
            from_date: fromDate && fromDate !== '' ? moment(new Date(fromDate)).format('DD-MM-YYYY') : ''
        };
        fetchNews(payload);
    };

    const handleChangePage = (newPage, perPageCount) => {
        setPage(newPage);
        setPageSize(perPageCount);
        fetchNews({
            business_id,
            page: newPage,
            page_size: perPageCount
        });
    };

    const handleFromDateChange = (date) => {
        let payload;
        if (date) {
            setFromDate(date);

            payload = {
                page: 1,
                page_size: pageSize,
                business_id,
                search_term: searchText,
                from_date: moment(new Date(date)).format('DD-MM-YYYY')
            };
        } else {
            setFromDate('');

            payload = {
                page: 1,
                page_size: pageSize,
                business_id,
                search_term: searchText,
                from_date: ''
            };
        }

        fetchNews(payload);
    };

    const fetchNews = (payload) => {
        dispatch(getAllNewsThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    useEffect(() => {
        const payload = {
            page: 1,
            page_size: pageSize,
            business_id,
            search_term: searchText,
            from_date: fromDate && fromDate !== '' ? moment(new Date(fromDate)).format('DD-MM-YYYY') : ''
        };
        fetchNews(payload);
    }, [annUpdated, updatedAnn]);

    const datePickerProps = {
        right: CalendarSmallIco
    };

    return (
        <div>
            <AnnouncementJumbo toggleAddAnn={toggleAddAnn} path />

            <div className="flex items-center justify-between  mx-10 flex-wrap gap-2">
                <SearchInput
                    width="280px"
                    atChange={(e) => {
                        onSearch(e);
                    }}
                    placeholder="Search"
                />

                <div className="flex gap-2 items-center">
                    {filterDate && (
                        <div
                            onClick={() => {
                                setFilterDate('');
                                setFilterEnable('');
                            }}
                            className="td-generic flex justify-center items-center"
                        >
                            <button className="px-10 py-2 rounded-md">Clear</button>
                        </div>
                    )}

                    <DatePick
                        iconSet={datePickerProps}
                        onDateChange={handleFromDateChange}
                        placeholder="Select Date"
                        value={fromDate}
                        bg="transparent"
                        sx="!rounded-xl"
                    />
                </div>
            </div>

            <div>
                <AnnouncementList
                    toggleAnnDetails={toggleAnnDetails}
                    handleAnn={handleAnn}
                    toggleAnnUpdate={toggleAnnUpdate}
                    filterDate={filterDate}
                    data={filterEnable !== '' ? filteredData : data}
                    toggleEditAnn={toggleEditAnn}
                    page={page}
                    pageSize={pageSize}
                    count={data?.count}
                    handleChangePage={handleChangePage}
                />
            </div>
        </div>
    );
};

export default AnnouncementContent;
