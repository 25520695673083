import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { Button } from 'components/atoms/buttons/Button';
import FormateDateTime from 'components/atoms/FormateDateTime';
import Pulse from 'components/atoms/Pulse';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { NoDataFound } from 'components/common/NoDataFound';
import { Badge } from 'components/molecules/Badge';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { AnimatePresence, motion } from 'framer-motion';
import useReadActivityHook from 'Hooks/job-manager/useReadActivityHook';
import useRoleGuard from 'Hooks/useRoleGuard';
import { cn } from 'pages/documents/hooks/helper';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import {
    collateFilesThunk,
    JobManagerRequestDocRevokeAccessThunk,
    JobManagerRequestSignatureListThunk
} from 'store/JobManager';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import {
    BUSINESS_ID,
    handleDownload,
    SIGNATURE_DOCUMENT,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from 'utills/globalVars';
import { BlueTick } from 'utills/svgs/BlueTick';
import { CollateFilesIcon } from 'utills/svgs/CollateFilesIcon';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import { DownloadIcon2 } from 'utills/svgs/DownloadIcon2';
import { IncompleteCircle } from 'utills/svgs/IncompleteCircle';
import { SignIcon } from 'utills/svgs/SignIcon';

const RequestSignature = ({ setCollateListReload, _disbaled = false }) => {
    const dispatch = useDispatch();
    const checkbox = useRef();
    const { id } = useParams();
    const navigate = useNavigate();
    const { hasPermissions } = useRoleGuard();
    const { data: loginData } = useSelector((state) => state.login);

    const { data: getJobActions } = useSelector((state) => state.getJobActions);
    const { markCompleteJobAtion } = useReadActivityHook();
    const getActivityCount =
        getJobActions
            ?.filter((obj) => obj?.type === SIGNATURE_DOCUMENT)
            .map((obj) => {
                const { meta, ...restOfData } = obj;
                const mainObj = meta.ids.map((id) => {
                    return {
                        ...restOfData,
                        id
                    };
                });
                return mainObj;
            })
            ?.flat() || [];

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [showChip, setShowChip] = useState(false);
    const [chipIndex, setChipIndex] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState('');

    // ----------------------------------------------------------- Pagination Start

    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [page, setPage] = useState(1);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        getData();
    }, [page]);

    const getData = async (hasReset = false) => {
        const payload = {
            job_id: id,
            business_id,
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE)
        };

        setHasMoreContent(true);

        try {
            const response = await dispatch(JobManagerRequestSignatureListThunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? pageData || [] : []), ...response.payload.rows];
                setPageData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handlePageChange = () => {
        setPage(page + 1);
    };

    useLayoutEffect(() => {
        const isIndeterminate = selectedIds.length > 0 && selectedIds.length < pageData.length;
        setChecked(pageData?.length > 0 && selectedIds.length === pageData.length);
        setIndeterminate(isIndeterminate);
        if (checkbox.current) {
            checkbox.current.indeterminate = isIndeterminate;
        }
    }, [selectedIds]);

    function toggleAll() {
        const newIds = [];
        pageData
            ?.filter((obj) => obj?.final_doc)
            ?.forEach((obj) => {
                newIds.push(obj);
            });

        setSelectedIds(checked || indeterminate ? [] : newIds);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const handleSelectId = (obj) => {
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item?.job_signature_document_id === obj?.job_signature_document_id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(obj);
            setSelectedIds(newIds);
        }
    };

    //

    const dynamicButtons = [
        {
            icon: <CollateFilesIcon className={'w-6'} />,
            title: 'Collate Files',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleCollatDocuments(selectedIds?.map((dataObj) => dataObj?.job_drive_id));
            },
            minimumSelect: 2
        }
    ];

    const [actions, setActions] = useState(dynamicButtons || []);

    useEffect(() => {
        if (!selectedIds?.length > 0) {
            setActions([]);
        } else {
            setActions(dynamicButtons.filter((obj) => obj.minimumSelect <= selectedIds.length));
        }
    }, [selectedIds]);

    const handleShowChip = (index) => {
        setChipIndex(index);
        setShowChip(true);
    };

    const checkExistance = (users) => {
        const findUser = users?.find((user) => user?.user_id === loginData?.user_id);
        if (findUser) {
            return true;
        } else {
            return false;
        }
    };

    const alreadySigned = (users) => {
        const findUser = users?.find((user) => user?.user_id === loginData?.user_id);
        if (findUser?.signed) {
            return true;
        } else {
            return false;
        }
    };

    const handleRevoke = (ids) => {
        const jsonPayload = {
            business_id,
            job_id: id,
            job_signature_document_ids: ids?.join(',')
        };

        dispatch(setLoader(true));
        dispatch(JobManagerRequestDocRevokeAccessThunk(jsonPayload))
            .then((res) => {
                if (res.payload) {
                    setSelectedIds([]);
                    if (page === 1) {
                        getData(true);
                    } else {
                        setPage(1);
                    }
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleCollatDocuments = (ids) => {
        let payload = {
            job_id: id,
            business_id,
            job_drive_ids: ids
        };
        dispatch(setLoader(true));
        dispatch(collateFilesThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Successfully Collated', TOAST_TYPE_SUCCESS);
                    setCollateListReload(true);
                }
            })
            .catch((error) => {
                console.error(error);
                toastHandler('Something went wrong', TOAST_TYPE_ERROR);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const parentRef = useRef(null);
    const handleHideChip = () => {
        setShowChip(false);
    };

    return (
        <div>
            <CollapsDivComponent
                title={
                    <span className="relative">
                        Request Signature
                        {getActivityCount?.length > 0 && (
                            <div className="absolute -right-2 -top-2">
                                <Pulse sx={'h-3 w-3'} tooltip={getActivityCount?.length} />
                            </div>
                        )}
                    </span>
                }
                h_one="max-h-[65px]"
                h_two="max-h-[350px]"
                classes="px-5 py-5"
                defaultClose
                extra={
                    <>
                        <div className="flex items-center justify-end gap-5">
                            <AnimatePresence>
                                {actions?.map((btnObj, index) => (
                                    <motion.span
                                        key={btnObj.title}
                                        initial={{ x: '100px', opacity: 0 }}
                                        animate={{
                                            x: 0,
                                            opacity: 1
                                        }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            animate: { delay: `0.${index + 1}`, type: 'tween', duration: 0.2 },
                                            exit: { type: 'tween', duration: 0.3 }
                                        }}
                                    >
                                        <Button
                                            classes={'bg-gray-200 !rounded-full p-2'}
                                            tooltip={btnObj.title}
                                            iconSet={{ leftIconHTML: btnObj.icon }}
                                            atClick={() => btnObj?.onClick()}
                                            tooltipDirection={'none'}
                                        />
                                    </motion.span>
                                ))}
                            </AnimatePresence>
                        </div>
                    </>
                }
            >
                {selectedPdf && (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                    />
                )}
                <div className="my-5 max-h-[540px] overflow-auto">
                    {pageData?.length === 0 ? (
                        <NoDataFound height="200px" message="No Record Found!" />
                    ) : (
                        <InfiniteScroll
                            height={'300px'}
                            next={handlePageChange}
                            dataLength={pageData.length}
                            hasMore={hasMoreContent}
                            loader={<SkeletonTable columns={6} />}
                        >
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts p-4 !px-0 !min-w-[40px] !w-[40px] text-center'
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                className="rounded-[3px] cursor-pointer"
                                                ref={checkbox}
                                                checked={checked}
                                                onChange={toggleAll}
                                            />
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !w-[200px] text-left'
                                            }
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Status
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Date & Time
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Created by
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Recipient
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-transparent gap-0">
                                    {pageData &&
                                        pageData?.length > 0 &&
                                        pageData?.map((dataObj, index) => {
                                            return (
                                                <tr
                                                    key={'req_doc_table' + index}
                                                    className="border-y relative  divide-gray-300"
                                                >
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap !text-[16px] p-4 !px-0 text-center',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div className="min-w-[18px]">
                                                            {dataObj?.final_doc && (
                                                                <input
                                                                    type="checkbox"
                                                                    className="rounded-[3px] cursor-pointer"
                                                                    checked={selectedIds?.some(
                                                                        (item) =>
                                                                            item.job_signature_document_id ===
                                                                            dataObj.job_signature_document_id
                                                                    )}
                                                                    onClick={() => handleSelectId(dataObj)}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap !text-[16px] py-2 !max-w-[200px] truncate relative ',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        {dataObj?.initial_doc ? (
                                                            <div className="flex items-center gap-2 cursor-pointer">
                                                                {(() => {
                                                                    const foundObj = getActivityCount.find(
                                                                        (item) =>
                                                                            item.id ===
                                                                            dataObj?.job_signature_document_id
                                                                    );
                                                                    return (
                                                                        <div
                                                                            className="relative cursor-pointer"
                                                                            onClick={() => {
                                                                                setSelectedPdf(
                                                                                    dataObj?.final_doc ??
                                                                                        dataObj?.initial_doc
                                                                                );
                                                                                if (foundObj) {
                                                                                    markCompleteJobAtion({
                                                                                        id: foundObj?.id,
                                                                                        job_action_id:
                                                                                            foundObj?.job_action_id
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            <GenerateFileIcon
                                                                                url={dataObj?.initial_doc}
                                                                                sx={'w-10'}
                                                                            />

                                                                            {foundObj && (
                                                                                <div className="absolute -right-2 -top-0">
                                                                                    <Pulse
                                                                                        sx={'h-3 w-3'}
                                                                                        // tooltip={foundObj?.description}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })()}

                                                                <div className="app-dataObj-name truncate overflow-hidden">
                                                                    <div>{dataObj?.name}</div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div className=" flex items-center gap-2">
                                                            {dataObj?.status === 'revoke' ? (
                                                                <span className="revoke-display relative text-[12px] md:text-[12px] lg:text-[12px] xl:text-[14px]  font-popppins ">
                                                                    Revoked
                                                                </span>
                                                            ) : dataObj?.final_doc ? (
                                                                <span className="positive-status relative text-[12px] md:text-[12px] lg:text-[12px] xl:text-[14px]  font-popppins ">
                                                                    Completed
                                                                </span>
                                                            ) : (
                                                                <span className="negitive-status text-red-[400] !font-normal text-[12px] md:text-[12px] lg:text-[12px] xl:text-[14px]  font-popppins ">
                                                                    Pending
                                                                </span>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        {dataObj?.created_at ? (
                                                            <FormateDateTime dateTime={dataObj?.created_at} />
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <DefaultProfileViewer
                                                            image={dataObj?.employee?.image || ''}
                                                            width="45px"
                                                            height="45px"
                                                            name={
                                                                dataObj?.employee?.first_name +
                                                                ' ' +
                                                                dataObj?.employee?.last_name
                                                            }
                                                            tooltip
                                                        />
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div
                                                            onMouseLeave={handleHideChip}
                                                            onMouseEnter={() => handleShowChip(index)}
                                                            className="relative"
                                                            ref={parentRef}
                                                        >
                                                            <div>
                                                                <Badge
                                                                    dataList={dataObj?.users?.slice(0, 3)}
                                                                    keyword="image"
                                                                    remain={
                                                                        dataObj?.users?.filter(
                                                                            (user) => user.signed === false
                                                                        )?.length - 3 ?? 0
                                                                    }
                                                                    isStatus={true}
                                                                    sx={'!w-[45px] !h-[45px]'}
                                                                />
                                                            </div>

                                                            {showChip && chipIndex === index && (
                                                                <div className="absolute bg-white rounded-md shadow-custom top-full right-full z-[2]">
                                                                    {dataObj?.users?.map((user, index) => (
                                                                        <div
                                                                            key={'user' + index}
                                                                            href="#"
                                                                            className={cn(
                                                                                'hover:bg-gray-100  text-gray-700',
                                                                                'block px-4 py-2 text-sm '
                                                                            )}
                                                                        >
                                                                            <div className="flex gap-2 items-center justify-between">
                                                                                <div className="flex gap-2 items-center">
                                                                                    <div className="flex items-start gap-1">
                                                                                        <DefaultProfileViewer
                                                                                            image={user?.image}
                                                                                            width="35px"
                                                                                            height="35px"
                                                                                            name={
                                                                                                user?.first_name +
                                                                                                ' ' +
                                                                                                user?.last_name
                                                                                            }
                                                                                        />
                                                                                        <div>
                                                                                            <div className="secondary-fonts text-sm">
                                                                                                {`${user?.first_name} ${user?.last_name}`.slice(
                                                                                                    0,
                                                                                                    15
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="secondary-fonts text-sm">
                                                                                                {user?.email.slice(
                                                                                                    0,
                                                                                                    15
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        {user?.signed ? (
                                                                                            <BlueTick />
                                                                                        ) : (
                                                                                            <IncompleteCircle />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div className="flex  items-center gap-2">
                                                            {checkExistance(dataObj.users) &&
                                                                !alreadySigned(dataObj.users) &&
                                                                dataObj.status !== 'revoke' && (
                                                                    <Button
                                                                        atClick={() => {
                                                                            if (
                                                                                !hasPermissions('Job', [
                                                                                    'write',
                                                                                    'admin'
                                                                                ])
                                                                            ) {
                                                                                return toastHandler(
                                                                                    'You do not have permission to perform this action.',
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }
                                                                            if (alreadySigned(dataObj.users)) {
                                                                                return toastHandler(
                                                                                    'you have already signed this document',
                                                                                    TOAST_TYPE_ERROR
                                                                                );
                                                                            }

                                                                            navigate(
                                                                                `/job-manager/sign-requested-doc/${dataObj?.job_id}/${dataObj?.job_signature_document_id}`
                                                                            );
                                                                        }}
                                                                        iconSet={{
                                                                            leftIconHTML: (
                                                                                <SignIcon
                                                                                    className={'w-5 text-[#0A1E46]'}
                                                                                />
                                                                            )
                                                                        }}
                                                                        classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                    />
                                                                )}

                                                            {dataObj?.final_doc && (
                                                                <Button
                                                                    atClick={() => {
                                                                        if (
                                                                            !hasPermissions('Job', [
                                                                                'write',
                                                                                'read',
                                                                                'admin'
                                                                            ])
                                                                        ) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        handleDownload({ url: dataObj?.final_doc });
                                                                    }}
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <DownloadIcon2
                                                                                className={'w-6 text-[#B695F8]'}
                                                                            />
                                                                        )
                                                                    }}
                                                                    classes={'!rounded-full bg-[#B695F8]/10 p-2'}
                                                                />
                                                            )}

                                                            {!dataObj?.final_doc && dataObj?.status !== 'revoke' && (
                                                                <Button
                                                                    atClick={() => {
                                                                        if (
                                                                            !hasPermissions('Job', ['write', 'admin'])
                                                                        ) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        handleRevoke([
                                                                            dataObj?.job_signature_document_id
                                                                        ]);
                                                                    }}
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <DeleteIcon
                                                                                className={
                                                                                    '!min-w-4 w-5 text-[#0A1E46]'
                                                                                }
                                                                            />
                                                                        )
                                                                    }}
                                                                    classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    )}
                </div>
            </CollapsDivComponent>
        </div>
    );
};

export default RequestSignature;
