import React from 'react';
import thanks from '../files/thank_you.png';

const ThisWasMe = () => {
    return (
        <>
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    <div className="">
                        <div className="login mx-5 md:px-20">
                            <div className="login-body">
                                <div className="login-welcome mt-10 small-text font-poppins">
                                    <div className="flex flex-col items-center justify-center gap-5 py-10">
                                        <div className="flex gap-2 items-center justify-center">
                                            <div className="mx-auto">
                                                <img src={thanks} alt="file" className=" bg-white cnf-icon-st" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <div className="flex gap-2 items-center justify-center">
                                                <div className=" cnf-title font-bold text-black ">
                                                    <h5>Thank You!</h5>
                                                </div>
                                            </div>
                                            <p className="text-xs text-center desc text-black mt-0 break-words my-5 pt-5">
                                                Thank you for confirming us.
                                            </p>
                                            <p className="text-black text-center desc  mt-0 break-words my-5 pt-5">
                                                This contributes to enhancing the security of the Synkli application.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ThisWasMe;
