import { Jumbotron } from 'components/organisms/Jumbotron';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';
import PDFViewer from 'pages/documents/PDFViewer';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';

export const PdfViewer = () => {
    const navigate = useNavigate();

    const { file } = useSelector((state) => state.global);
    const location = useLocation();
    const fileItem = location.state;

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(-1);
        }
    };
    return (
        <SidebarLayout>
            <div>
                {' '}
                <div className="app-dashboard">
                    <div>
                        <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                            <Jumbotron title={fileItem?.name} backArrowProp={backArrowProp} />

                            <div className="mx-5 md:mx-10">
                                <div style={{ width: '100%', height: '500px' }}>
                                    {fileItem && (
                                        <PDFViewer
                                            left="55%"
                                            key={file?.file_manager_id}
                                            documentData={fileItem?.url}
                                            hideExpand={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </RoleBasedGuard>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};
