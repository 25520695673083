import React, { useState } from 'react';
import AlternateMethodPage from '../../components/dashboard-components/auth/lost-two-fa-pages/AlternateMethodPage';
import BackupAlternatePage from 'components/dashboard-components/auth/lost-two-fa-pages/BackupAlternatePage';
import { PhoneNumberAltername } from 'components/dashboard-components/auth/lost-two-fa-pages/PhoneNumberAltername';
import { EmailAuthAlternate } from './EmailAuthAlternate';
const AlternateMethod = () => {
    const lostStepper = JSON.parse(sessionStorage.getItem('lostStepper'));
    const [condition, setCondition] = useState(lostStepper ?? 1);

    const handleCondition = (condition) => {
        sessionStorage.setItem('lostStepper', condition);
        setCondition(condition);
    };

    function MyComponent() {
        return (
            <div>
                {condition === 1 && <AlternateMethodPage handleCondition={handleCondition} />}
                {condition === 2 && <BackupAlternatePage handleCondition={handleCondition} />}
                {condition === 3 && <PhoneNumberAltername handleCondition={handleCondition} />}
                {condition === 4 && <EmailAuthAlternate handleCondition={handleCondition} />}
            </div>
        );
    }

    return <div>{MyComponent()}</div>;
};

export default AlternateMethod;
