import React from 'react';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { AvifFile } from 'utills/svgs/file-icons/AvifFile';
import { DocFile } from 'utills/svgs/file-icons/DocFile';
import ExcelFile from 'utills/svgs/file-icons/ExcelFile';
import { HeicFile } from 'utills/svgs/file-icons/HeicFile';
import { HeifFile } from 'utills/svgs/file-icons/HeifFile';
import { JPEGFile } from 'utills/svgs/file-icons/JPEGFile';
import { MsgFile } from 'utills/svgs/file-icons/MsgFile';
import { PdfFile } from 'utills/svgs/file-icons/PdfFile';
import { PngFile } from 'utills/svgs/file-icons/PngFile';
import { TxtIcon } from 'utills/svgs/file-icons/TxtIcon';
import { WebpFile } from 'utills/svgs/file-icons/WebpFile';
import { XmlIcon } from 'utills/svgs/file-icons/XmlIcon';
import { FilesIcon } from 'utills/svgs/FilesIcon';

export const getFileIcon = (url, sx = '', showImage = false, imgPrperties) => {
    if (
        showImage &&
        (imgPrperties.type.includes('jpg') || imgPrperties.type.includes('png') || imgPrperties.type.includes('jpeg'))
    ) {
        return (
            <img
                src={URL.createObjectURL(imgPrperties.url)}
                alt={'alt' + url}
                onClick={imgPrperties?.onClick ? imgPrperties.onClick : () => {}}
                className={imgPrperties.sx || sx}
            />
        );
    }
    const fileExtension = url?.includes('.')
        ? url?.split('.').pop().toLowerCase()
        : url?.split('/').pop().toLowerCase();

    switch (fileExtension) {
        case 'pdf':
            return <PdfFile className={sx} />;

        case 'msword':
        case 'doc':
        case 'docx':
            return <DocFile className={sx} />;
        case 'txt':
            return <TxtIcon className={sx} />;

        case 'jpeg':
        case 'jpg':
            return <JPEGFile className={sx} />;

        case 'vnd.ms-excel':
        case 'xlsx':
        case 'xls':
            return <ExcelFile className={sx} />;
        case 'csv':
            return <CsvIcon className={sx} />;
        case 'xml':
            return <XmlIcon className={sx} />;
        case 'png':
            return <PngFile className={sx} />;

        case 'heic':
            return <HeicFile className={sx} />;
        case 'heif':
            return <HeifFile className={sx} />;
        case 'webp':
            return <WebpFile className={sx} />;
        case 'avif':
            return <AvifFile className={sx} />;

        case 'ms-outlook':
        case 'rfc822':
        case 'msg':
        case 'octet-stream':
            return <MsgFile className={sx} />;
        default:
            return (
                <div className="py-1">
                    <div className={'bg-third rounded-md p-1 ' + sx}>
                        <FilesIcon className={'w-[85%] h-auto m-auto stroke-white'} />
                    </div>
                </div>
            );
    }
};
