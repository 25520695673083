import React from 'react';

export const WarningSign = () => {
    return (
        <div>
            {' '}
            <svg width="40" height="45" viewBox="0 0 56 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M29.8975 39.6202V62.7847H7.7272e-07C-0.000927164 59.1339 0.833905 55.5313 2.44053 52.253C4.04715 48.9747 6.38293 46.1077 9.26887 43.8717C12.1548 41.6357 15.5143 40.0899 19.09 39.353C22.6657 38.6161 26.3626 38.7075 29.8975 39.6202ZM23.918 35.877C14.007 35.877 5.9795 27.8495 5.9795 17.9385C5.9795 8.02748 14.007 0 23.918 0C33.829 0 41.8565 8.02748 41.8565 17.9385C41.8565 27.8495 33.829 35.877 23.918 35.877Z"
                    fill="url(#paint0_linear_1352_337)"
                />
                <path
                    d="M44.8452 48.2007L49.0717 43.9722C50.2396 42.8038 52.1336 42.8036 53.3017 43.9717C54.4698 45.1398 54.4696 47.0338 53.3012 48.2017L49.0727 52.4282L53.3012 56.6547C54.4696 57.8226 54.4698 59.7166 53.3017 60.8847C52.1336 62.0528 50.2396 62.0526 49.0717 60.8842L44.8452 56.6557L40.6187 60.8842C39.4508 62.0526 37.5568 62.0528 36.3887 60.8847C35.2205 59.7166 35.2208 57.8226 36.3892 56.6547L40.6177 52.4282L36.3892 48.2017C35.2208 47.0338 35.2205 45.1398 36.3887 43.9717C37.5568 42.8036 39.4508 42.8038 40.6187 43.9722L44.8452 48.2007Z"
                    fill="#FF4444"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_1352_337"
                        x1="22.982"
                        y1="0.779724"
                        x2="22.9114"
                        y2="62.7848"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
