import React from 'react';

export const RevokeIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M9.10156 1.40966C7.59766 1.562 6.08203 2.15185 4.83594 3.07372C4.33203 3.44872 3.46484 4.312 3.09766 4.80419C1.39063 7.10888 0.91797 10.019 1.82031 12.73C2.82813 15.7651 5.42578 17.9604 8.65234 18.5034C9.25 18.6011 10.7813 18.6011 11.3477 18.4995C13.2148 18.1714 14.7891 17.3628 16.0742 16.0737C17.4883 14.6597 18.3398 12.8706 18.5586 10.8667C18.6211 10.2573 18.5859 9.14013 18.4805 8.53466C18.2344 7.14404 17.7031 5.91357 16.8516 4.78075C16.5664 4.40185 15.8633 3.66747 15.4609 3.33154C13.7383 1.89404 11.3789 1.1831 9.10156 1.40966ZM10.5859 2.77294C12.3086 2.92529 13.8711 3.64794 15.0742 4.85107C17.6719 7.44091 17.9492 11.4448 15.7266 14.394C15.4258 14.7964 14.7969 15.4253 14.3945 15.7261C12.8945 16.855 11.0664 17.3745 9.26172 17.187C6.73438 16.9214 4.55078 15.3901 3.46094 13.1245C2.50781 11.144 2.51172 8.8081 3.46875 6.82763C4.44141 4.81591 6.37109 3.32372 8.55469 2.89013C9.28906 2.7456 9.87891 2.71044 10.5859 2.77294Z"
                fill="#000000"
                stroke="#000000"
                strokeWidth="0.5"
            />
            <path
                d="M6.77734 6.44011C6.58984 6.52604 6.50781 6.61198 6.42578 6.80729C6.35156 6.98698 6.35156 7.15104 6.42578 7.33464C6.46875 7.42839 6.91406 7.90495 7.74609 8.73698L9.00391 9.9987L7.74609 11.2604C6.47656 12.53 6.36719 12.6628 6.36719 12.9245C6.36719 13.4167 6.93359 13.7487 7.375 13.5182C7.45312 13.4753 8.07422 12.8854 8.75 12.2096L9.98047 10.9753L11.2305 12.2214C11.918 12.905 12.5352 13.4948 12.6055 13.53C12.9961 13.7253 13.5117 13.4635 13.582 13.0339C13.6367 12.6823 13.6523 12.6979 12.2539 11.2995L10.9609 9.9987L12.2344 8.72136C13.5977 7.34636 13.6211 7.31511 13.582 6.96745C13.5469 6.65495 13.2266 6.36589 12.9102 6.36589C12.6562 6.36589 12.4883 6.50651 11.25 7.7487C10.5742 8.42839 10.0039 8.98308 9.98047 8.98308C9.95703 8.98308 9.38672 8.42839 8.71094 7.7487C7.26953 6.30339 7.19141 6.2487 6.77734 6.44011Z"
                fill="#000000"
                stroke="#000000"
                strokeWidth="0.5"
            />
        </svg>
    );
};
