import React from 'react';

export const DownloadIcon = ({ className }) => {
    return (
        <svg
            width="16"
            height="15"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M10 12.2V1M10 12.2L6.4 8.46667M10 12.2L13.6 8.46667M1 14.0667L1.5589 16.386C1.65624 16.7898 1.88094 17.1483 2.1973 17.4045C2.51365 17.6607 2.90351 17.7999 3.3049 17.8H16.6951C17.0965 17.7999 17.4863 17.6607 17.8027 17.4045C18.1191 17.1483 18.3438 16.7898 18.4411 16.386L19 14.0667"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
