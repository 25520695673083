import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import './css/addProfileSide.css';

import { SmallLoader } from 'components/common/SmallLoader';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { toastHandler } from 'responseHanlder';
import { businessOwnerAddProfileThunk, updateUserProfileImageThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { UserLargeIcon } from 'utills/svgs/UserLargeIcon';

import { useOwnerProfile } from 'Hooks/useOwnerProfile';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { TOAST_TYPE_SUCCESS, USER_TYPE, generateRandomProfileLink } from 'utills/globalVars';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { handleProfileImage } from 'store/global/globalReducer';
import 'react-advanced-cropper/dist/style.css';
import { ImageCropper } from 'global-components/ImageCropper';
import { DataURIToBlob } from 'utills/dataValidation';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import moment from 'moment';
import Slideover from 'components/atoms/SlideOver';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { Button } from 'components/atoms/buttons/Button';
import { formateDateTime } from 'utills/moment';
import dayjs from 'dayjs';

const AddProfileSide = (props) => {
    const dispatch = useDispatch();

    const { getOwnerProfile } = useOwnerProfile();
    const { data: ownerProfile } = useSelector((state) => state.businessOwnerGetProfile);

    const [fromDate, setFromDate] = useState(
        ownerProfile?.date_of_birth ? formateDateTime(ownerProfile?.date_of_birth) : ''
    );

    const [profileLoader, setProfileLoader] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);

    const [name, setName] = useState(
        ownerProfile?.first_name
            ? ownerProfile?.last_name
                ? `${ownerProfile.first_name} ${ownerProfile.last_name}`
                : ownerProfile.first_name
            : ownerProfile?.last_name
            ? ownerProfile.last_name
            : ''
    );

    const [phoneNo, setPhoneNo] = useState(ownerProfile?.phone_number);
    const { isLoading } = useSelector((state) => state.global);

    const [selectedImage, setSelectedImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [file, setFile] = useState('');
    const [showCroper, setShowCroper] = useState(false);

    const saveProfile = (image) => {
        setCroppedImage(image);

        const file = DataURIToBlob(image);
        const formData = new FormData();

        formData.append('profile_image', file, 'image.jpg');

        formData.append('uploaded_by', USER_TYPE);

        setProfileLoader(true);

        dispatch(updateUserProfileImageThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setSelectedImage(response.payload.image);
                    dispatch(handleProfileImage(response.payload.image));
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setProfileLoader(false);
            });
    };
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setSelectedImage(reader.result);

                setCroppedImage(null);
            };

            reader.readAsDataURL(file);
            setShowCroper(true);
        }

        return;

        const formData = new FormData();
        formData.append('uploaded_by', USER_TYPE);
        formData.append('profile_image', file);
        setProfileLoader(true);

        dispatch(updateUserProfileImageThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setSelectedImage(response.payload.image);
                    dispatch(handleProfileImage(response.payload.image));
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setProfileLoader(false);
            });
    };

    const handleName = (value) => {
        if (/\d/.test(value)) {
            return;
        } else {
            setName(value);
        }
    };
    const handleSubmit = () => {
        const parsedFrom = new Date(fromDate);
        const parsed = dayjs(fromDate).format('DD-MM-YYYY');
        const parts = name.split(' ');
        const formData = {
            first_name: parts[0] || '',
            last_name: parts.slice(1).join(' ') || '',
            date_of_birth: fromDate ? parsed : '',
            phone_number: phoneNo || ownerProfile?.phone_number
        };

        dispatch(setLoader(true));
        dispatch(businessOwnerAddProfileThunk(formData))
            .then((response) => {
                if (response.payload) {
                    props.toggleAddProfile();
                    toastHandler('Profile Updated Successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        getOwnerProfile();
    }, []);

    const handlePhoneNo = (value) => {
        if (value?.length < 16) {
            setPhoneNo(value);
        }
    };

    const handleFromDateChange = (date) => {
        if (date) {
            setFromDate(date);
        } else {
            setFromDate('');
        }
    };

    const datePickerProps = {
        right: CalendarSmallIco
    };

    const twelveYearsAgo = moment().subtract(12, 'years').format('YYYY-MM-DD');
    const minDate = '1950-01-01';

    return (
        <div>
            {' '}
            {selectedImage && showCroper ? (
                <>
                    <ImageCropper
                        width={'120'}
                        height={'120'}
                        setShowCroper={setShowCroper}
                        image={selectedImage}
                        isCircular={true}
                        handleCroppedImage={(image) => saveProfile(image)}
                    />
                </>
            ) : (
                ''
            )}
            <Slideover open={props.isOpen} setOpen={props.setIsOpen} title="User Profile">
                {' '}
                <div>
                    <div className=""></div>
                    <div className="">
                        <motion.div
                            initial={{ x: 700 }}
                            animate={{ x: 0 }}
                            transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                            className=""
                        >
                            <div>
                                <div className="add-detail pt-10 px-5">
                                    <div className="flex justify-center items-center">
                                        {' '}
                                        <div className="profile-image-container mt-5 ">
                                            <div className={`flex justify-center items-center h-full w-full`}>
                                                {profileLoader ? (
                                                    <SmallLoader />
                                                ) : selectedImage ?? ownerProfile?.image ? (
                                                    <img
                                                        className="profile-image-edit"
                                                        src={selectedImage ?? ownerProfile?.image}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <>
                                                        <img
                                                            className="profile-image-edit"
                                                            src={generateRandomProfileLink(
                                                                ownerProfile?.first_name + ' ' + ownerProfile?.last_name
                                                            )}
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            {isZoomed && (
                                                <ZoomImage
                                                    src={ownerProfile?.image}
                                                    alt="Profile"
                                                    onClose={() => setIsZoomed(false)}
                                                />
                                            )}

                                            <input
                                                type="file"
                                                accept=".jpg, .jpeg, .gif"
                                                onChange={handleImageUpload}
                                                style={{ display: 'none' }}
                                                id="image-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="profile-info-container add-ann-form mt-3 mr-4">
                                        <div>
                                            <label className="buttons-font">Full name</label>
                                        </div>
                                        <div className="">
                                            <input
                                                onChange={(e) => handleName(e.target.value)}
                                                type="text"
                                                maxLength="60"
                                                className="px-3 rounded-xl default-font-small placeholder:text-[#979797]"
                                                placeholder="Name"
                                                value={name}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <label className="buttons-font">Date of birth</label>
                                        </div>
                                        <div className="mt-1">
                                            <DatePick
                                                iconSet={datePickerProps}
                                                onDateChange={handleFromDateChange}
                                                placeholder={'Enter DOB'}
                                                value={
                                                    fromDate !== 'Invalid date' && fromDate
                                                        ? dayjs(fromDate, 'DD-MM-YYYY')
                                                        : ''
                                                }
                                                bg="transparent"
                                                sx="!rounded-xl  inputs-font text-secondarybg"
                                                border="#979797"
                                                width="100%"
                                                isNextDateRequired={false}
                                                maxDate={twelveYearsAgo}
                                                minDate={minDate}
                                                isDob={true}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <div className="">
                                                <PhoneInputComponent
                                                    title="Personal Phone Number"
                                                    placeholder="Enter Phone Number"
                                                    titleSx="font-semibold"
                                                    sx="!rounded-xl"
                                                    atChange={(phone) => handlePhoneNo(phone)}
                                                    value={phoneNo}
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center  btn-wrap mt-20 max-lg:mt-48">
                                        <Button
                                            title="Add"
                                            disabled={isLoading}
                                            atClick={handleSubmit}
                                            isloading={isLoading}
                                            iconSet={{ loader: SmallLoaderWhite }}
                                            classes="ann-btn text-white  buttons-font px-16 py-2 rounded-lg"
                                        />
                                    </div>{' '}
                                </div>{' '}
                            </div>
                        </motion.div>
                    </div>
                </div>
            </Slideover>
        </div>
    );
};

export default AddProfileSide;
