import React, { Suspense, useEffect } from 'react';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';

import { useNavigate } from 'react-router-dom';

import { ACCESS_TOKEN, REDIRECT_URI } from 'utills/globalVars';
import { useSelector } from 'react-redux';

const LazyLoad = () => {
    useEffect(() => {
        nProgress.start();

        return () => {
            nProgress.done();
        };
    });

    return '';
};

export const Protected = (props) => {
    const navigate = useNavigate();

    const { data: loginData } = useSelector((state) => state.login);
    const { Component } = props;
    const access_token = localStorage.getItem(ACCESS_TOKEN);

    const verified = JSON.parse(localStorage.getItem('verified'));
    const isOnboarded = loginData?.business?.onboarded;
    useEffect(() => {
        if (access_token && verified === false) {
            returnToLogin('/');
            return;
        } else if (access_token && !isOnboarded) {
            returnToLogin('/onboarding');
            return;
        } else if (!access_token) {
            returnToLogin('/');
        }
    }, [access_token, verified, isOnboarded]);

    const returnToLogin = (route) => {
        const currentUrl = window.location.pathname + window.location.search;
        sessionStorage.setItem(REDIRECT_URI, currentUrl);
        navigate(route);
    };

    return (
        <div>
            <Suspense fallback={<LazyLoad />}>{access_token && verified && isOnboarded ? <Component /> : ''} </Suspense>
        </div>
    );
};
