import React from 'react';

export const SortIcon = (className) => {
    return (
        <div>
            {' '}
            <svg
                width="16"
                height="13"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className || ''}
            >
                <rect
                    x="24"
                    y="4"
                    width="24"
                    height="4"
                    rx="2"
                    transform="rotate(180 24 4)"
                    fill="url(#paint0_linear_2369_11349)"
                />
                <rect
                    x="20"
                    y="11"
                    width="16"
                    height="4"
                    rx="2"
                    transform="rotate(180 20 11)"
                    fill="url(#paint1_linear_2369_11349)"
                />
                <rect
                    x="17"
                    y="18"
                    width="10"
                    height="4"
                    rx="2"
                    transform="rotate(180 17 18)"
                    fill="url(#paint2_linear_2369_11349)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_2369_11349"
                        x1="37.1776"
                        y1="4.04968"
                        x2="37.1771"
                        y2="8.00001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_2369_11349"
                        x1="28.785"
                        y1="11.0497"
                        x2="28.7843"
                        y2="15"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_2369_11349"
                        x1="22.4907"
                        y1="18.0497"
                        x2="22.4895"
                        y2="22"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
