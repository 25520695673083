import React from 'react';
import { DashboardWelcomeCard } from 'pages/dashboard/dashboard-cards/DashboardWelcomeCard';
import { useNavigate } from 'react-router-dom';
import { BackArrow } from 'utills/svgs/BackArrow';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import { QrCodeMobile } from 'utills/svgs/QrCodeMobile';

export const GoogleAuthInstructions = () => {
    const navigate = useNavigate();
    return (
        <div>
            {' '}
            <div className="dashboard-page">
                <div className="main-grid-container">
                    <div></div>
                    <div className="two shadow-md">
                        <DashboardWelcomeCard />
                    </div>
                    <div className="three shadow-md">
                        <div className="one-head  pt-4 flex   ">
                            <div className="one-head px-3 pt-2 flex  items-center ">
                                <div className="arrow-wrapper mt-1 cursor-pointer">
                                    <BackArrow />
                                </div>
                                <div>
                                    {' '}
                                    <h1>Instructions For Google Authenticator</h1>
                                </div>
                            </div>
                        </div>

                        <div className="google-text flex items-center gap-1 mx-5 mt-3">
                            <div>
                                <div className=" scale-75">
                                    <BlueGreaterSign />
                                </div>
                            </div>
                            <div>
                                <span>Download the Google Authenticator App</span>
                            </div>
                        </div>

                        <div className="google-inner-text px-5 mt-1">
                            <p>
                                We recommended downloading Duo Mobile or Google Authenticator if you don’t have one
                                installed.
                            </p>
                        </div>

                        <div className="google-text mt-3 items-center flex gap-1 mx-5">
                            <div>
                                <div className="scale-75">
                                    <BlueGreaterSign />
                                </div>
                            </div>
                            <div>
                                <span>Scan the QR Code</span>
                            </div>
                        </div>

                        <div className="google-inner-text px-5 mt-1">
                            <p>
                                Open the Google Authenticator app on your phone and tap the &quot;+&quot; icon or the
                                option to add a new account.
                            </p>
                            <p>
                                Use your phone&apos;s camera to scan the QR code displayed on your account setup page.
                            </p>
                        </div>

                        <div className="m-5">
                            <QrCodeMobile />
                        </div>
                        <div className="mx-5 mt-3 font-bold">
                            XXXX {'  '} XXXX {'  '} XXXX {'  '} XXXX
                        </div>

                        <div className="google-text flex gap-1 items-center mx-5 mt-5">
                            <div>
                                <div className="scale-75">
                                    <BlueGreaterSign />
                                </div>
                            </div>
                            <div>
                                <span>Copy and enter 6-digit code</span>
                            </div>
                        </div>

                        <div className="google-inner-text px-5 mt-1">
                            <p>
                                Once you have scanned the QR code or entered the key, your authentication app will
                                generate a 6-digit code. Please copy the code and return here to enter it.
                            </p>
                        </div>

                        <div className="flex justify-center items-center mt-20">
                            <button onClick={() => navigate('/google/qr')} className="btn-2 px-16 ">
                                Next{' '}
                            </button>
                        </div>
                    </div>
                    <div className="four"></div>
                </div>
            </div>
        </div>
    );
};
