import React from 'react';
import { cn } from 'utills/tailwindUtil';

export const SecondaryParagraph = ({ sx, onClick, children }) => {
    return (
        <p className={cn('text-sm  font-poppins font-[400]', sx)} onClick={onClick}>
            {children}
        </p>
    );
};
