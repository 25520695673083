/* eslint-disable react/no-children-prop */
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import React, { useEffect, useState } from 'react';
import { ACNIcon } from 'utills/svgs/ACNIcon';
import { AbnIcon } from 'utills/svgs/AbnIcon';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { SpouseIcon } from 'utills/svgs/SpouseIcon';
import { TFNIcon } from 'utills/svgs/TFNIcon';
import mask_bg from 'files/mask_bg.png';
import { BSBIcon } from 'utills/svgs/BSBIcon';
import { BankIcon } from 'utills/svgs/BankIcon';
import { BlueNoteIcon } from 'utills/svgs/BlueNoteIcon';
import { AtoIcon } from 'utills/svgs/AtoIcon';
import { RefundIcon } from 'utills/svgs/RefundIcon';
import { SendMailIcon } from 'utills/svgs/SendMailIcon';
import { AtoStatusIcon } from 'utills/svgs/AtoStatusIcon';
import { BUSINESS_ID, generateRandomProfileLink, getProfileName } from 'utills/globalVars';
import { VerificationCheck } from 'utills/svgs/VerificationCheck';
import { Chip } from 'components/atoms/buttons/Chip';
import ClientProfileDetailsCard from './ClientProfileDetailsCard';
import { cn } from 'utills/tailwindUtil';
import { NoDataFound } from 'components/common/NoDataFound';
import { CloseEye } from 'utills/svgs/CloseEye';
import { OpenEye } from 'utills/svgs/OpenEye';
import { convertUnderscoresToSpaces } from 'utills/handleText';
import moment from 'moment';
import { DateFormatToLocal } from 'utills/moment';
import { WorkProfessionIcon } from 'utills/svgs/WorkProfessionIcon';
import { BusinessOccupationIcon } from 'utills/svgs/BusinessOccupationIcon';
import ProgressBarComponent from 'components/atoms/ProgressBar';
import { Button } from 'components/atoms/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getClientNominationThunk, resetClientNominationThunk, sendReminderClientNominationThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { EthicalClearanceIcon } from 'utills/svgs/EthicalClearanceIcon';
import { EmailIcon } from 'utills/svgs/EmailIcon';
import { FirmIcon } from 'utills/svgs/FirmIcon';
import { SideTimes } from 'utills/svgs/SideTimes';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import { Truncate } from 'components/molecules/Truncate';

export const ManualClientProfile = ({ user }) => {
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();

    const [isExpand, setIsExpand] = useState(false);

    const { data: clientNomination } = useSelector((state) => state?.getClientNomination);

    const [profileType, _setProfileType] = useState(user?.client_category?.toLowerCase());
    const [isAccountNumberOpen, setIsAccountNumberOpen] = useState(false);
    const [isBsbOpen, setIsBsbOpen] = useState(false);

    const [detailsSwitch, setDetailsSwitch] = useState(null);
    const [business, setBusiness] = useState([]);
    const [clientVerification, setClientVerification] = useState([]);
    const [compilance, setCompilance] = useState([]);
    const [formarAccountant, setFormarAccountant] = useState([]);

    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (user) {
            setBusiness([
                {
                    iconSet: <WorkProfessionIcon />,
                    value: user?.work_profession || '__',
                    title: 'Work Profession'
                },
                {
                    iconSet: <TFNIcon />,
                    value: user?.tfn || '__',
                    title: 'TFN'
                },
                {
                    iconSet: <AbnIcon />,
                    value: user?.abn || '__',
                    title: 'ABN'
                },
                {
                    iconSet: <ACNIcon />,
                    value: user?.acn || '__',
                    title: 'ACN'
                },
                {
                    iconSet: <BusinessOccupationIcon />,
                    value: user?.business_occupation || '__',
                    title: 'Principle Business Occupation'
                }
            ]);

            setClientVerification([
                {
                    iconSet: { icon: SpouseIcon },
                    value: user?.verified_by
                        ? user?.verified_by?.first_name + ' ' + user?.verified_by?.last_name
                        : '__',
                    title: 'Verified by'
                },
                {
                    iconSet: { icon: VerificationCheck },
                    value: user?.client_verification_method || '__',
                    title: 'Verification Method'
                },
                {
                    iconSet: { icon: BlueNoteIcon },
                    value: user?.date_verified || '__',
                    title: 'Date Verified'
                }
            ]);

            setCompilance([
                {
                    iconSet: { icon: SpouseIcon },
                    value: user?.accountant ? user?.accountant?.first_name + ' ' + user?.accountant?.last_name : '__',
                    title: 'Accountant'
                },
                {
                    iconSet: { icon: SpouseIcon },
                    value: user?.accountant ? user?.accountant?.first_name + ' ' + user?.accountant?.last_name : '__',
                    title: 'Email'
                },

                {
                    iconSet: { icon: BlueNoteIcon },
                    value: (user?.registered_for_gst && user?.activity_statement_due_date) || '__',
                    title: 'Activity Statement due date'
                },
                {
                    iconSet: { icon: BlueNoteIcon },
                    value: (user?.registered_for_gst && user?.tax_form_due_date) || '__',
                    title: 'Prepare Tax Form'
                },
                {
                    iconSet: { icon: AtoIcon },
                    value: (user?.registered_for_gst && user?.ato_retrieval_time_span) || '__',
                    title: 'Automatically Retrieve from ATO'
                },
                {
                    iconSet: { icon: RefundIcon },
                    value: (user?.registered_for_gst && user?.fee_from_refund) || '__',
                    title: 'Fee From Refund'
                },
                {
                    iconSet: { icon: AtoStatusIcon },
                    value: (user?.registered_for_gst && user?.ato_client_status) || '__',
                    title: 'Client ATO Status'
                },
                {
                    iconSet: { icon: EthicalClearanceIcon },
                    value:
                        (user?.ethical_cleanance_status && user?.ethical_cleanance_status?.replace('clearance ', '')) ||
                        '__',
                    title: 'Ethical Clearance'
                }
            ]);

            setFormarAccountant([
                {
                    iconSet: { icon: FirmIcon },
                    value: user?.former_accounting_firmname ? user?.former_accounting_firmname : '__',
                    title: 'Accounting Firm Name'
                },
                {
                    iconSet: { icon: SpouseIcon },
                    value: user?.former_accounting_name ? user?.former_accounting_name : '__',
                    title: 'Accountant Name'
                },
                {
                    iconSet: { icon: EmailIcon },
                    value: user?.former_accounting_email_address ? user?.former_accounting_email_address : '__',
                    title: 'Accountant Email'
                }
            ]);

            setDetailsSwitch('Primary Details');
        }
    }, [user]);

    const [profileTypeData, setProfileTypeData] = useState(null);

    useEffect(() => {
        if (detailsSwitch) {
            updateProfileTypeData();
        }
    }, [detailsSwitch]);

    function capitalize(s) {
        let ns = s && s.toString();
        return ns && ns[0]?.toUpperCase() + ns?.slice(1);
    }

    const getDataFromObject = (obj, activeLink = null) => {
        return {
            profile:
                obj?.info_image && obj?.info_image !== 'null'
                    ? obj.info_image
                    : generateRandomProfileLink(
                          obj?.display_name ? obj?.display_name : obj?.first_name + ' ' + obj?.last_name
                      ),
            username: obj?.display_name ? obj?.display_name : obj?.first_name + ' ' + obj?.last_name,
            email: obj?.email_address || obj?.email,
            phone: obj?.phone_number && obj?.phone_number.length > 4 ? obj?.phone_number : '',
            info_client_ref_no: obj?.info_client_ref_no,
            info_client_category: obj?.info_client_category,
            info_client_type: obj?.info_client_type,
            taxIcome: obj?.taxable_income && obj?.taxable_income !== 'null' && obj?.taxable_income,
            partnershipPercentage: !obj?.PP_chip && obj?.partnership_percentage,
            PP_chip: obj?.PP_chip,
            percentageOfShares: obj?.percentage_of_shares,
            directorId: obj?.director_identification_number,
            status: (obj?.director_status || obj?.status) && capitalize(obj?.director_status || obj?.status),
            acn: obj?.acn,
            companyName: obj?.company_name,
            directorName: obj?.director_name,
            contact_director_name: obj?.contact_director_name,
            number_of_shares: obj?.number_of_shares,
            number_of_units: obj?.number_of_units || obj?.no_of_units,
            designation: obj?.designation,
            link: activeLink,
            //
            tfn: obj?.tfn,
            dob: !obj?.dob_chip && obj?.dob,
            dob_chip: obj?.dob_chip,
            residential_address: obj?.residential_address || obj?.current_address,
            trust_type: obj?.trust_type,
            companyState: obj?.company_state,
            establishDate: obj?.deed_establish_date,
            settledSum: obj?.setteled_sum,
            client_ref_no: obj?.client_ref_no,
            DIN: obj?.DIN,
            no_of_dependentant: obj?.no_of_dependentant && obj?.no_of_dependentant !== 0 ? obj?.no_of_dependentant : '',
            in_relation_for:
                obj?.spouse_from_date && obj?.spouse_to_date
                    ? handleCalculateAge(obj?.spouse_from_date, obj?.spouse_to_date)
                    : '',
            gender: obj?.gender,
            residency_status: obj?.residency_status,
            benefit_percentage: obj?.benefit_percentage,
            relationship: obj?.relationship
        };
    };

    const updateProfileTypeData = () => {
        const dataObj = {};

        if (profileType === 'individual') {
            dataObj.title = 'Individual';
            dataObj.detailsTitle = 'Primary Details';
            dataObj.switchTitle = 'Related Entities';
            dataObj.column1DetailTitle = 'Spouse Reference';

            let spuseList = user?.spouse?.bcr_id
                ? [
                      getDataFromObject(
                          {
                              ...user?.spouse,
                              dob_chip: user?.spouse?.date_of_birth
                          },
                          user?.spouse?.business_client_id
                      )
                  ]
                : [];

            const manualAddedSpouse = user?.spouse_first_name
                ? getDataFromObject(
                      {
                          first_name: user?.spouse_first_name,
                          last_name: user?.spouse_last_name,
                          taxable_income: user?.taxable_income,
                          email: '',
                          status: null
                      },
                      user?.spouse?.business_client_id
                  )
                : {};

            spuseList = user?.spouse_first_name ? [...spuseList, manualAddedSpouse] : [...spuseList];

            dataObj.column1Data = spuseList;

            dataObj.businessInfoList = business.filter((item) =>
                ['ABN', 'Work Profession', 'Principle Business Occupation'].includes(item.title)
            );

            const inheritedRelatedRef = user?.inherited_related_references || [];

            const relatedRefList = inheritedRelatedRef.concat(user?.related_references || []);

            dataObj.column2Cards = [
                {
                    title: 'Related Entities',
                    data: relatedRefList?.map((obj) =>
                        getDataFromObject(
                            {
                                ...obj,
                                status: obj?.currently_effective === 'Yes' ? 'Active' : 'Inactive'
                            },
                            obj?.business_client_id
                        )
                    )
                }
            ];
            dataObj.column3Cards = [];
        } else if (profileType === 'partnership') {
            dataObj.title = 'Partnership';
            dataObj.detailsTitle = 'Partnership Details';
            dataObj.switchTitle = 'Partner Details';
            dataObj.businessInfoList = business.filter(
                (item) =>
                    item.title === 'ABN' || item.title === 'TFN' || item?.title === 'Principle Business Occupation'
            );

            dataObj.column1DetailTitle = 'Partner Details';
            dataObj.column1Data = user?.partner_details
                ? user?.partner_details?.map((obj) => {
                      const details = obj?.details;
                      return getDataFromObject(
                          {
                              ...details,
                              residential_address: details?.current_address,
                              status: details?.partner_status,
                              contact_director_name: details?.contact_director_name,

                              trust_type: obj?.partner_status,
                              PP_chip: details?.partnership_percentage,
                              dob_chip: details?.dob,
                              dob: details?.dob ? moment(details?.dob).format('DD-MM-YYYY') : ''
                          },
                          details?.business_client_id
                      );
                  })
                : [];

            const inheritedRelatedRef = user?.inherited_related_references || [];

            const relatedRefList = inheritedRelatedRef.concat(user?.related_references || []);
            dataObj.column2Cards = [
                {
                    title: 'Related Entities',
                    data: relatedRefList?.map((obj) =>
                        getDataFromObject(
                            {
                                ...obj,
                                status: obj?.currently_effective === 'Yes' ? 'Active' : 'Inactive'
                            },
                            obj?.business_client_id
                        )
                    )
                }
            ];
            dataObj.column3Cards = [];
        } else if (profileType === 'company') {
            dataObj.title = 'Company';
            dataObj.detailsTitle = 'Company Details';
            dataObj.switchTitle = 'Director Details';
            dataObj.businessInfoList = business.filter((obj) =>
                ['ABN', 'ACN', 'TFN', 'Principle Business Occupation'].includes(obj.title)
            );

            dataObj.column1DetailTitle = 'Director Details';
            dataObj.column1Data = user?.director_details
                ? user?.director_details?.map((obj) => {
                      return getDataFromObject(
                          {
                              ...obj,
                              status: obj?.director_status,
                              DIN: obj?.director_identification_number,
                              dob_chip: obj?.dob,
                              info_client_category: null
                          },
                          obj?.business_client_id
                      );
                  })
                : [];
            dataObj.column2Cards = [
                {
                    title: 'Share Holder Details',
                    data: user?.shareholder_details
                        ? user?.shareholder_details?.map((obj) => {
                              const details = obj?.details;
                              return getDataFromObject(
                                  {
                                      ...details,
                                      percentage_of_shares: details?.shareholder_percentage,
                                      status: details?.shareholder_status,
                                      trust_type: obj?.shareholder_type,
                                      info_client_category: ''
                                  },
                                  details?.business_client_id
                              );
                          })
                        : []
                },

                {
                    title: 'Public Officer Details',
                    data: user?.public_officer_details?.map((obj) =>
                        getDataFromObject(
                            { ...obj, status: obj?.officer_status, dob_chip: obj?.dob },
                            obj?.business_client_id
                        )
                    )
                }
            ];

            const inheritedRelatedRef = user?.inherited_related_references || [];

            const relatedRefList = inheritedRelatedRef.concat(user?.related_references || []);

            dataObj.column3Cards = [
                {
                    title: 'Secretary Details',
                    data: user?.secretary_details?.map((obj) =>
                        getDataFromObject(
                            { ...obj, status: obj?.seceretary_status, dob_chip: obj?.dob },
                            obj?.business_client_id
                        )
                    )
                },
                {
                    title: 'Related Entities',
                    data: relatedRefList?.map((obj) =>
                        getDataFromObject(
                            { ...obj, status: obj?.currently_effective === 'Yes' ? 'Active' : 'Inactive' },
                            obj?.business_client_id
                        )
                    )
                }
            ];
        } else if (profileType === 'trust' || profileType === 'familyTrust') {
            dataObj.title = 'Trust';
            dataObj.detailsTitle = 'Trust Details';
            dataObj.trust_structure = profileType === 'trust' ? 'Unit Trust' : 'Family Trust';
            dataObj.tradingNames = user?.trading_name;

            dataObj.switchTitle = 'Trustee Details';
            dataObj.businessInfoList = business.filter(
                (item) => item.title === 'ABN' || item.title === 'TFN' || item.title === 'Principle Business Occupation'
            );

            dataObj.column1DetailTitle = 'Trustee Details';

            dataObj.column1Data = user?.trust_details
                ? user?.trust_details?.map((obj) => {
                      const details = obj?.details;
                      if (obj?.trust_type === 'individual') {
                          return getDataFromObject(
                              {
                                  ...details,
                                  status: details?.trustee_status
                              },
                              details?.business_client_id
                          );
                      } else {
                          return getDataFromObject(
                              {
                                  ...details,
                                  display_name: details?.display_name,
                                  acn: details?.acn,

                                  residential_address: details?.current_address,
                                  trust_type: obj?.trust_type,
                                  status: details?.trustee_status
                              },
                              details?.business_client_id
                          );
                      }
                  })
                : [];

            dataObj.column2Cards = [
                {
                    title: 'Settlor Details',
                    data: user?.settlor_details?.map((obj) => getDataFromObject(obj, obj?.business_client_id))
                },
                {
                    title: 'Appointer Details',
                    data: user?.appointer_details?.map((obj) => getDataFromObject(obj, obj?.business_client_id))
                }
            ];

            let column3Data = [];

            if (profileType === 'trust' && user?.trust_structure === 'Unit Trust') {
                column3Data = user?.upholder_details?.map((obj) => {
                    return getDataFromObject(
                        {
                            ...obj?.details,
                            status: obj?.details?.unitholder_status,
                            contact_director_name: obj?.details?.contact_director_name || [],
                            trust_type: obj?.unitholder_type
                        },
                        obj?.details?.business_client_id
                    );
                });
            } else {
                column3Data = user?.beneficiary_details?.map((obj) => {
                    return getDataFromObject(
                        {
                            ...obj?.details,
                            status: obj?.details?.beneficiary_status,
                            contact_director_name: obj?.details?.contact_director_name || [],
                            trust_type: obj?.beneficiary_type
                        },
                        obj?.details?.business_client_id
                    );
                });
            }

            const inheritedRelatedRef = user?.inherited_related_references || [];

            const relatedRefList = inheritedRelatedRef.concat(user?.related_references || []);

            dataObj.column3Cards = [
                {
                    title:
                        profileType === 'trust' && user?.trust_structure === 'Unit Trust'
                            ? 'Unit Holder Details'
                            : 'Beneficiary  Details',
                    data: column3Data
                },
                {
                    title: 'Related Entities',
                    data: relatedRefList?.map((obj) =>
                        getDataFromObject(
                            {
                                ...obj,
                                status: obj?.currently_effective === 'Yes' ? 'Active' : 'Inactive',
                                trust_type: obj?.client_category,
                                client_ref_no: obj?.client_ref_no
                            },
                            obj?.business_client_id
                        )
                    )
                }
            ];
        } else if (profileType === 'association') {
            dataObj.title = 'Association';
            dataObj.detailsTitle = 'Association Details';
            dataObj.tradingNames = user?.trading_name;

            dataObj.switchTitle = 'President Details';
            dataObj.businessInfoList = business.filter(
                (item) =>
                    item.title === 'ABN' || item.title === 'TFN' || item?.title === 'Principle Business Occupation'
            );

            dataObj.column1DetailTitle = 'President Details';

            dataObj.column1Data = user?.association_president_details
                ? [
                      getDataFromObject(
                          {
                              ...user?.association_president_details,
                              status: user?.association_president_details?.president_status,
                              residential_address: user?.association_president_details?.current_address
                          },
                          user?.association_president_details?.business_client_id
                      )
                  ]
                : [];

            dataObj.column2Cards = [
                {
                    title: 'Treasurer Details',
                    data: user?.association_treasurer_details?.map((obj) =>
                        getDataFromObject(
                            {
                                ...obj,
                                status: obj?.treasurer_status,
                                residential_address: obj?.current_address
                            },
                            obj?.business_client_id
                        )
                    )
                }
            ];

            const inheritedRelatedRef = user?.inherited_related_references || [];

            const relatedRefList = inheritedRelatedRef.concat(user?.related_references || []);

            dataObj.column3Cards = [
                {
                    title: 'Secretary Details',
                    data: user?.association_secretary_details
                        ? [
                              getDataFromObject(
                                  {
                                      ...user?.association_secretary_details,
                                      status: user?.association_secretary_details?.secretary_status,
                                      residential_address: user?.association_secretary_details?.current_address
                                  },
                                  user?.association_secretary_details?.business_client_id
                              )
                          ]
                        : []
                },
                {
                    title: 'Related Entities',
                    data: relatedRefList?.map((obj) =>
                        getDataFromObject(
                            { ...obj, status: obj?.currently_effective === 'Yes' ? 'Active' : 'Inactive' },
                            obj?.business_client_id
                        )
                    )
                }
            ];
        } else if (profileType === 'smsf') {
            dataObj.title = 'SMSF';
            dataObj.detailsTitle = 'SMSF Details';
            dataObj.tradingNames = user?.trading_name;

            dataObj.switchTitle = 'Member Details';

            dataObj.column1DetailTitle = 'Trustee Details';
            dataObj.businessInfoList = business.filter(
                (item) => item.title === 'ABN' || item.title === 'TFN' || item.title === 'Principle Business Occupation'
            );

            dataObj.column1Data = user?.smsf_trust_details
                ? user?.smsf_trust_details?.map((obj) => {
                      const details = obj?.details;
                      return getDataFromObject(
                          {
                              ...details,
                              //
                              trust_type: obj?.smsf_trust_type,
                              status: details?.trustee_status
                          },
                          details?.business_client_id
                      );
                  })
                : [];

            dataObj.column2Cards = [
                {
                    title: 'Member Details',
                    data: user?.member_details?.map((obj) =>
                        getDataFromObject({ ...obj, dob_chip: obj?.dob }, obj?.business_client_id)
                    )
                }
            ];

            const inheritedRelatedRef = user?.inherited_related_references || [];

            const relatedRefList = inheritedRelatedRef.concat(user?.related_references || []);

            dataObj.column3Cards = [
                {
                    title: 'Specific Individual(s)',
                    data:
                        user?.smsf_spi_details?.map((obj) =>
                            getDataFromObject({
                                ...obj,
                                residential_address: obj?.address
                            })
                        ) || []
                },
                {
                    title: 'Related Entities',
                    data: relatedRefList?.map((obj) =>
                        getDataFromObject(
                            { ...obj, status: obj?.currently_effective === 'Yes' ? 'Active' : 'Inactive' },
                            obj?.business_client_id
                        )
                    )
                }
            ];
        }

        setProfileTypeData(dataObj);
    };
    const getName = (obj) => {
        return obj?.first_name && obj?.first_name !== ''
            ? obj?.first_name + ' ' + (obj?.last_name || '')
            : obj?.display_name;
    };

    const handleResetClientNomination = () => {
        const payload = {
            business_client_id: id,
            business_id
        };
        dispatch(resetClientNominationThunk(payload)).then((res) => {
            if (res.payload) {
                dispatch(getClientNominationThunk(payload));
            }
        });
    };
    const handleSendRemindeerClientNomination = () => {
        const payload = {
            business_client_id: id
        };
        dispatch(setLoader(true));
        dispatch(sendReminderClientNominationThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const handleCalculateAge = (value) => {
        if (value) {
            const birthDate = moment(value, 'DD-MM-YYYY');
            const currentDate = moment();
            const age = currentDate.diff(birthDate, 'years');
            return age;
        } else {
            return 0;
        }
    };

    return (
        <div className="bg-white rounded-xl border !border-main  mb-5 relative pb-5">
            <div className="lg:flex gap-5 p-5">
                <div className="w-[230px] ">
                    <div className="pl-7">
                        <img
                            src={
                                user?.image
                                    ? user?.image
                                    : generateRandomProfileLink(
                                          ['Partnership', 'Company', 'Trust'].includes(user?.client_category)
                                              ? user?.display_name
                                              : getName(user)
                                      )
                            }
                            alt="profile"
                            className="h-[150px] w-[150px] rounded-[30px]"
                        />
                    </div>
                </div>
                <div className="w-full">
                    <div>
                        <span className="cursor-pointer absolute right-4 top-4" onClick={() => setIsExpand(!isExpand)}>
                            {isExpand ? <CollapsIcon className={'w-6 h-6'} /> : <ExpandIcon className={'w-6 h-6'} />}
                        </span>
                    </div>

                    <div className="flex flex-wrap justify-between items-start mt-10 lg:mt-0 gap-2">
                        <div className="flex gap-4 items-center">
                            <div className="lg-title font-poppins">{getProfileName(user)}</div>
                            {user?.client_ref_no && (
                                <Chip
                                    title={'Ref Num: ' + user?.client_ref_no}
                                    classes={'default-opacity-bg text-thirdbg'}
                                />
                            )}
                        </div>
                        <div className="flex gap-4 items-center">
                            {user?.tfn && (
                                <Chip title={'TFN: ' + user?.tfn} classes={'default-opacity-bg text-thirdbg'} />
                            )}
                            {user?.client_type && (
                                <Chip
                                    title={convertUnderscoresToSpaces(user?.client_type)}
                                    classes={'bg-gray-200 capitalize'}
                                />
                            )}

                            {profileTypeData?.title && (
                                <Chip title={profileTypeData?.title} classes={'bg-gray-200 mr-10'} />
                            )}
                        </div>
                    </div>
                    <div>
                        <h3 className="table-title mt-5 font-poppins !font-medium">{profileTypeData?.detailsTitle}</h3>
                    </div>

                    <div
                        className={cn(
                            'flex flex-col md:grid md:auto-cols-min md:grid-cols-2 gap-x-4 gap-y-5 mt-5 items-start',
                            ['trust'].includes(profileType) && '2xl:grid-cols-3',
                            ['individual', 'partnership', 'association', 'smsf', 'company'].includes(profileType) &&
                                '2xl:grid-cols-2'
                        )}
                    >
                        {profileType === 'individual' && (
                            <div className="flex xl:flex-row flex-col gap-4 items-start">
                                <div className="flex flex-wrap items-start gap-4">
                                    {user?.gender && (
                                        <Chip
                                            title={user?.gender}
                                            classes={'default-positive-color px-5 py-1.5 text-[#F14747]'}
                                        />
                                    )}
                                    {user?.date_of_birth && (
                                        <Chip
                                            title={user?.date_of_birth}
                                            classes={'green-color-opacity px-5 py-1.5 text-[#2B3467]'}
                                        />
                                    )}

                                    {user?.date_of_birth && (
                                        <Chip
                                            title={`${handleCalculateAge(user?.date_of_birth)} Years`}
                                            classes={'default-negative-color px-6 py-1.5 text-[#F08201]'}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {profileType === 'trust' && (
                            <div
                                className={cn(
                                    'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start row-start-1',
                                    ['trust'].includes(profileType) && '2xl:!gap-5'
                                )}
                            >
                                <div className="text-secondarybg min-w-[165px]">Trust Structure: </div>
                                <div>{user?.trust_structure || '__'}</div>
                            </div>
                        )}

                        <div
                            className={cn(
                                'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start',
                                ['trust'].includes(profileType) && '2xl:!gap-5'
                            )}
                        >
                            <div className="text-secondarybg min-w-[165px]">Phone Number: </div>
                            <div>{user?.phone_number || '__'}</div>
                        </div>

                        <div
                            className={cn(
                                'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start',
                                ['partnership', 'company', 'association', 'trust', 'smsf'].includes(profileType) &&
                                    'row-start-1',
                                ['trust'].includes(profileType) && '2xl:!gap-5 overflow-hidden text-ellipsis"'
                            )}
                        >
                            <div className="text-secondarybg  min-w-[165px]">Email Address: </div>
                            <div className="overflow-hidden text-ellipsis">{user?.email || '__'}</div>
                        </div>

                        {user?.trading_name && (
                            <>
                                {user?.trading_name?.map((name, inx) => (
                                    <div key={'trust_trading_' + inx}>
                                        <div
                                            className={cn(
                                                'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start',
                                                ['trust'].includes(profileType) && '2xl:!gap-5'
                                            )}
                                        >
                                            <div className="text-secondarybg  min-w-[165px]">Trading Name: </div>
                                            <span className="break-all">{name}</span>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>

                    <div
                        className={cn(
                            'flex flex-col md:grid md:auto-cols-min md:grid-cols-2 gap-x-4 gap-y-5 mt-5 items-start',
                            ['trust'].includes(profileType) && '2xl:grid-cols-3',
                            ['individual', 'partnership', 'association', 'smsf', 'company'].includes(profileType) &&
                                '2xl:grid-cols-2'
                        )}
                    >
                        <div
                            className={cn(
                                ['partnership', 'trust', 'association', 'smsf', 'company'].includes(profileType) &&
                                    (isExpand ? 'contents' : 'hidden')
                            )}
                        >
                            <div className={cn('flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start')}>
                                <div className="text-secondarybg  min-w-[165px]">State: </div>
                                {user?.state ? <span className="break-all">{user?.state}</span> : '--'}
                            </div>
                        </div>
                        <div
                            className={cn(
                                ['partnership', 'trust', 'association', 'smsf', 'company'].includes(profileType) &&
                                    (isExpand ? 'contents' : 'hidden')
                            )}
                        >
                            <div
                                className={cn(
                                    'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start',
                                    ['trust'].includes(profileType) ? 'col-span-2 2xl:!gap-5' : 'col-span-1'
                                )}
                            >
                                <div className="text-secondarybg  min-w-[165px]">Website Link: </div>
                                {user?.website ? (
                                    <a
                                        href={user?.website || '!#'}
                                        className="!text-third break-all"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {user?.website}
                                    </a>
                                ) : (
                                    '--'
                                )}
                            </div>
                        </div>
                        {isExpand && (
                            <>
                                <div
                                    className={cn(
                                        'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start',
                                        ['trust'].includes(profileType) && '2xl:!gap-5'
                                    )}
                                >
                                    <div className="text-secondarybg  min-w-[165px]">Residential Address: </div>
                                    <div> {user?.residential_address || '__'} </div>
                                </div>
                                <div
                                    className={cn(
                                        'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start',
                                        ['trust'].includes(profileType) && '2xl:!gap-5'
                                    )}
                                >
                                    <div className="text-secondarybg  min-w-[165px]">Postal Address: </div>
                                    <div>{user?.postal_address || '__'} </div>
                                </div>
                                <div
                                    className={cn(
                                        'mt-5',
                                        ['trust'].includes(profileType) ? 'col-span-3' : 'col-span-2'
                                    )}
                                >
                                    <hr />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {isExpand && (
                <div>
                    <div className="m-7 mx-10 w-[430px]">
                        <DefaultDoubleSwitch
                            items={[
                                { title: 'Primary Details', selected: detailsSwitch === 'Primary Details' },
                                {
                                    title: profileTypeData?.switchTitle,
                                    selected: detailsSwitch === profileTypeData?.switchTitle
                                }
                            ]}
                            onClick={(value) => setDetailsSwitch(value)}
                        />
                    </div>

                    {detailsSwitch === 'Primary Details' && (
                        <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-5 px-10">
                            <div className="rounded-xl !border border-main  shadow-md ">
                                <div
                                    className="default-opacity-bg1 rounded-xl  overflow-y-auto relative !p-5 h-[250px] w-full"
                                    style={{
                                        backgroundImage: `url(${mask_bg})`,
                                        backgroundSize: '104% 104%',
                                        backgroundPosition: 'center'
                                    }}
                                >
                                    <div className="lg-title font-poppins font-semibold">Financial Details</div>

                                    <div className="flex gap-4 mt-3 lg:mt-5        ">
                                        <div>
                                            <SpouseIcon />
                                        </div>
                                        <div className="flex gap-1 flex-col">
                                            <div className="small-text text-[#666666] font-poppins ">
                                                Account Holder Name
                                            </div>
                                            <div className="buttons-font-lighter font-poppins">
                                                {user?.account_holdername || 'N/A'}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-between items-center gap-5 flex-wrap">
                                        <div className="flex gap-4 mt-2 lg:mt-4">
                                            <div>
                                                <BSBIcon />
                                            </div>
                                            <div className="flex gap-1 flex-col">
                                                <div className="small-text text-[#666666] font-poppins ">BSB</div>
                                                <div className="buttons-font-lighter font-poppins">
                                                    <div className="buttons-font-lighter font-poppins">
                                                        <div className="flex gap-2 items-center">
                                                            {user?.bsb_code ? (
                                                                <div>
                                                                    {' '}
                                                                    {isBsbOpen ? user?.bsb_code.slice(0, 6) : '******'}
                                                                </div>
                                                            ) : (
                                                                <div>N/A </div>
                                                            )}
                                                            {user?.bsb_code && (
                                                                <div>
                                                                    {' '}
                                                                    {isBsbOpen ? (
                                                                        <span
                                                                            onClick={() => setIsBsbOpen(!isBsbOpen)}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            <CloseEye />
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            onClick={() => setIsBsbOpen(!isBsbOpen)}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            <OpenEye />{' '}
                                                                        </span>
                                                                    )}{' '}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex gap-4 mt-2 lg:mt-4">
                                            <div>
                                                <BankIcon />
                                            </div>
                                            <div className="flex gap-1 flex-col">
                                                <div className="small-text text-[#666666] font-poppins ">
                                                    Bank Account No.
                                                </div>
                                                <div className="buttons-font-lighter font-poppins">
                                                    <div className="flex gap-2 items-center">
                                                        {user?.bank_account_number ? (
                                                            <div>
                                                                {' '}
                                                                {isAccountNumberOpen
                                                                    ? user?.bank_account_number
                                                                    : '****************'}
                                                            </div>
                                                        ) : (
                                                            <div>N/A </div>
                                                        )}

                                                        {user?.bank_account_number && (
                                                            <div>
                                                                {' '}
                                                                {isAccountNumberOpen ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsAccountNumberOpen(!isAccountNumberOpen)
                                                                        }
                                                                        className="cursor-pointer"
                                                                    >
                                                                        <CloseEye />
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        onClick={() =>
                                                                            setIsAccountNumberOpen(!isAccountNumberOpen)
                                                                        }
                                                                        className="cursor-pointer"
                                                                    >
                                                                        <OpenEye />{' '}
                                                                    </span>
                                                                )}{' '}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 p-5 h-[400px] overflow-y-auto">
                                    <div className="lg-title font-poppins font-semibold">Business Information</div>

                                    <div className={cn('flex flex-wrap items-start justify-between gap-4 px-5')}>
                                        {profileTypeData?.businessInfoList?.map((item, index) => (
                                            <div className="flex gap-4 mt-3 lg:mt-5" key={'businessInfo' + index}>
                                                <div>{item.iconSet}</div>
                                                <div className="flex gap-1 flex-col">
                                                    <div className="small-text text-[#666666] font-poppins ">
                                                        {item.title}
                                                    </div>
                                                    <div className="buttons-font-lighter font-poppins">
                                                        {item.value}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {user?.capital_contribution && (
                                        <div className="flex justify-between items-center mt-5">
                                            <div className="flex-grow flex-1">
                                                <div className="text-md font-semibold font-poppins">
                                                    Capital Contribution
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'text-md font-[500] font-poppins !text-green-600 !capitalize'
                                                }
                                            >
                                                {user?.capital_contribution}
                                            </div>
                                        </div>
                                    )}
                                    {user?.name_of_principle && (
                                        <div className="flex justify-between items-center mt-5 gap-5 flex-wrap">
                                            <div className="flex-1 grow-1">
                                                <div className="text-md font-semibold font-poppins">
                                                    Name of Principle
                                                </div>
                                            </div>
                                            <div className={'text-md font-[400] font-poppins !capitalize'}>
                                                {user?.name_of_principle}
                                            </div>
                                        </div>
                                    )}
                                    {user?.address_of_principle && (
                                        <div className="flex justify-between items-center mt-5 space-x-5 space-y-2 flex-wrap">
                                            <div className="flex-1 grow-1">
                                                <div className="text-md font-semibold font-poppins">
                                                    Address of Principle
                                                </div>
                                            </div>
                                            <div className={'text-md font-[400] font-poppins !capitalize'}>
                                                {user?.address_of_principle}
                                            </div>
                                        </div>
                                    )}

                                    {user?.objectives && (
                                        <div className="flex justify-between items-center mt-5 border-t pt-5">
                                            <div className="flex-grow flex-1">
                                                <div className="lg-title font-poppins font-semibold">Objective</div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    setMessage(user?.objectives);
                                                }}
                                                className="buttons-font cursor-pointer font-fira underline text-gray-800"
                                            >
                                                <span> View Message</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={cn(
                                    'rounded-xl !border border-main  shadow-md p-5 overflow-hidden hover:overflow-y-auto transition-all',
                                    user?.client_category !== 'Individual' ? 'h-[680px]' : 'h-[600px]'
                                )}
                            >
                                <div className="lg-title font-poppins font-semibold">Compliance</div>

                                <div className="flex flex-col space-y-5 mt-6 ">
                                    {compilance.map((item, index) => (
                                        <div key={index} className="flex items-center flex-col md:flex-row space-x-4">
                                            <div className="flex-shrink-0 w-10 h-10 lg:ml-0 ml-5 rounded-full flex items-center justify-center">
                                                <item.iconSet.icon />
                                            </div>
                                            <div className="flex-grow lg:mt-0 mt-3 flex-1">
                                                <div className="text-md font-[400] font-poppins">{item.title}</div>
                                            </div>
                                            <div
                                                className={cn(
                                                    'text-md font-[500] font-poppins',
                                                    item.title === 'Client ATO Status' &&
                                                        item?.value !== '__' &&
                                                        '!text-green-600 font-semibold !capitalize',
                                                    item.title === 'Ethical Clearance' &&
                                                        item?.value === 'granted' &&
                                                        '!text-green-600 font-semibold !capitalize',
                                                    item.title === 'Ethical Clearance' &&
                                                        item?.value === 'rejected' &&
                                                        '!text-red-600 font-semibold !capitalize'
                                                )}
                                            >
                                                {item?.value === true
                                                    ? 'True'
                                                    : item?.value === false
                                                    ? 'False'
                                                    : item?.value}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <>
                                    <div className="lg-title font-poppins font-semibold mt-6">Former Accountant</div>
                                    <div className="flex flex-col space-y-5 mt-6 ">
                                        {formarAccountant.map((item, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center flex-col md:flex-row space-x-4"
                                            >
                                                <div className="flex-shrink-0 w-10 h-10 lg:ml-0 ml-5 rounded-full flex items-center justify-center">
                                                    <item.iconSet.icon />
                                                </div>
                                                <div className="flex-grow lg:mt-0 mt-3 flex-1">
                                                    <div className="text-md font-[400] font-poppins">{item.title}</div>
                                                </div>
                                                <div className={cn('text-md font-[500] font-poppins')}>
                                                    {item?.value}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            </div>

                            <div>
                                <div
                                    className={cn(
                                        'rounded-xl shadow-md p-5  !border border-main overflow-y-auto',
                                        user?.client_category !== 'Individual' ? 'h-[680px]' : 'h-[600px]'
                                    )}
                                >
                                    <div className="flex justify-between items-center">
                                        <div className="lg-title font-poppins font-semibold">Client Verification</div>
                                        <Chip
                                            title={user?.is_client_verified ? 'Verified' : 'Not Verified'}
                                            classes={
                                                user?.is_client_verified
                                                    ? 'bg-green-100 text-[#008C51]'
                                                    : 'bg-red-100 text-red-700'
                                            }
                                        />
                                    </div>

                                    <div className="flex flex-col space-y-6 mt-8">
                                        {clientVerification.map((item, index) => (
                                            <div key={index} className="flex items-center space-x-4">
                                                <div className="flex-shrink-0 w-10 h-10  rounded-full flex items-center justify-center">
                                                    <item.iconSet.icon />
                                                </div>
                                                <div className="flex-grow">
                                                    <div className="text-md font-[400] font-poppins">{item.title}</div>
                                                </div>
                                                <div className="text-md font-[400] font-poppins">
                                                    {user?.is_client_verified ? item.value : '__'}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="mt-7">
                                        <div className="table-title font-poppins">Notes</div>
                                        <div className="mt-3 text-[#666666]">
                                            {user?.is_client_verified ? user?.notes : '__'}
                                        </div>
                                    </div>
                                    {user?.client_category !== 'Individual' && (
                                        <>
                                            <hr className="my-6" />

                                            <div className="flex justify-between items-center flex-wrap">
                                                <div className="lg-title font-poppins font-semibold">
                                                    Client Agent Nomination
                                                </div>
                                                <Chip
                                                    title={
                                                        clientNomination?.status === 'completed'
                                                            ? 'Completed'
                                                            : clientNomination?.status === 'in_progress'
                                                            ? 'In Progress'
                                                            : 'Not Started'
                                                    }
                                                    classes={
                                                        clientNomination?.status === 'completed'
                                                            ? 'bg-green-100 text-[#008C51]'
                                                            : clientNomination?.status === 'in_progress'
                                                            ? 'bg-yellow-50 text-yellow-700'
                                                            : 'bg-red-100 text-red-700'
                                                    }
                                                />
                                            </div>
                                            <div className="grid mt-6">
                                                <ProgressBarComponent
                                                    steps={[
                                                        {
                                                            step: '1'
                                                        },
                                                        {
                                                            step: '2'
                                                        },
                                                        {
                                                            step: '3'
                                                        },
                                                        {
                                                            step: '4'
                                                        },
                                                        {
                                                            step: '5'
                                                        },
                                                        {
                                                            step: '6'
                                                        }
                                                    ]}
                                                    targetProperty={'step'}
                                                    activeStep={clientNomination?.steps - 1}
                                                    hideArrows
                                                    divWidthValue="80"
                                                />
                                            </div>
                                            <div className="text-md text-[#666666]">
                                                {clientNomination?.status === 'completed' ? (
                                                    <>
                                                        The client has successfully completed all the steps in the
                                                        client-to-agent linking process. Your action is now required
                                                        within 28 days to finalize the connection, Last Date:{' '}
                                                        {DateFormatToLocal({
                                                            date: clientNomination?.updated_at,
                                                            format: 'DD MMM, YYYY',
                                                            insertedFormat: 'DD-MM-YYYYTHH:mm:ssZ',
                                                            addDays: 28
                                                        })}
                                                        .
                                                    </>
                                                ) : clientNomination?.status === 'in_progress' ? (
                                                    <>
                                                        The client is currently in the process of linking your account.
                                                        They are on Step {clientNomination?.steps}/6. You can remind
                                                        them to complete the process to establish your connection from
                                                        the button below.
                                                    </>
                                                ) : (
                                                    <>
                                                        The client has not yet initiated the client-to-agent linking
                                                        process. Please remind them to start the process to establish
                                                        your connection from the button below.
                                                    </>
                                                )}
                                            </div>
                                            {clientNomination?.status === 'completed' ? (
                                                <Button
                                                    title={'Reset Verification'}
                                                    classes={
                                                        'bg-red-100 text-red-700 flex items-start gap-1 px-3 py-2.5 !shadow-lg !rounded-xl my-3'
                                                    }
                                                    atClick={() => handleResetClientNomination()}
                                                />
                                            ) : (
                                                <Button
                                                    title={'Send Email Reminder'}
                                                    atClick={() => handleSendRemindeerClientNomination()}
                                                    iconSet={{ leftIconHTML: <SendMailIcon className={'w-7'} /> }}
                                                    classes={
                                                        'bg-third flex items-start gap-1 px-3 py-2.5 text-white !shadow-lg !rounded-xl my-3'
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {[
                        'Related Entities',
                        'Partner Details',
                        'Trustee Details',
                        'Director Details',
                        'President Details',
                        'Member Details'
                    ].includes(detailsSwitch) && (
                        <>
                            <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-5 px-10">
                                <div className="rounded-xl shadow-md !border border-main  p-5 h-[700px] overflow-y-auto row-span-2">
                                    <div className="lg-title font-poppins font-semibold">
                                        {profileTypeData?.column1DetailTitle}
                                    </div>

                                    {profileTypeData?.column1Data?.length > 0 ? (
                                        <div className="flex flex-col mt-3 space-y-5 divide-y-2 divide-gray-200 pb-5 border-b-2 border-gray-200">
                                            {profileTypeData?.column1Data?.map((item, index) => (
                                                <div key={'detail_card' + index}>
                                                    {item && (
                                                        <ClientProfileDetailsCard
                                                            link={item?.link}
                                                            profile={item.profile}
                                                            name={item.username}
                                                            status={item.status}
                                                            email={item.email}
                                                            phone={item.phone}
                                                            badgesArray={[
                                                                {
                                                                    value: item?.info_client_category
                                                                        ? item?.info_client_category
                                                                        : '',
                                                                    sx: 'bg-pink-100 text-pink-700'
                                                                },
                                                                {
                                                                    value: item?.trust_type ? item?.trust_type : '',
                                                                    sx: 'bg-pink-100 text-pink-700'
                                                                },
                                                                {
                                                                    value: item?.gender ? item?.gender : '',
                                                                    sx: 'bg-pink-100 text-pink-700'
                                                                },
                                                                {
                                                                    value: item?.DIN
                                                                        ? `DIN: ${item?.DIN}`
                                                                        : item?.acn
                                                                        ? `ACN:${item.acn}`
                                                                        : '',
                                                                    sx: 'bg-blue-100 text-blue-700'
                                                                },
                                                                {
                                                                    value: item?.info_client_ref_no
                                                                        ? item?.info_client_ref_no
                                                                        : item?.tfn
                                                                        ? `TFN:${item?.tfn}`
                                                                        : '',
                                                                    sx: 'bg-yellow-100  text-yellow-800'
                                                                },
                                                                {
                                                                    value: item?.info_client_type
                                                                        ? convertUnderscoresToSpaces(
                                                                              item?.info_client_type
                                                                          )
                                                                        : '',
                                                                    sx: 'bg-gray-100 text-gray-600'
                                                                },
                                                                {
                                                                    value: item?.PP_chip
                                                                        ? `Partnership: ${item?.PP_chip}${
                                                                              detailsSwitch !== 'Partner Details'
                                                                                  ? '%'
                                                                                  : ''
                                                                          }`
                                                                        : '',
                                                                    sx: 'bg-red-100 text-red-700'
                                                                },
                                                                {
                                                                    value: item?.percentageOfShares
                                                                        ? `Shares: ${item?.percentageOfShares}%`
                                                                        : item?.number_of_units
                                                                        ? `No. of Units:${item?.number_of_units}`
                                                                        : '',
                                                                    sx: 'bg-red-100 text-red-700'
                                                                },
                                                                {
                                                                    value: item?.status ? item.status : '',
                                                                    sx: 'bg-green-100 text-green-700'
                                                                },
                                                                {
                                                                    value: item?.dob_chip ? item?.dob_chip : '',
                                                                    sx: 'bg-gray-100 text-gray-600'
                                                                }
                                                            ]}
                                                            residential_address={item?.residential_address || ''}
                                                            dob={item?.dob}
                                                            companyState={item?.companyState || ''}
                                                            taxableIcome={item?.taxIcome}
                                                            no_of_dependentant={item?.no_of_dependentant}
                                                            in_relation_for={item?.in_relation_for}
                                                            residency_status={item?.residency_status}
                                                            partnershipPercentage={item?.partnershipPercentage}
                                                            companyName={item?.companyName}
                                                            directorName={item?.directorName}
                                                            contact_director_name={item?.contact_director_name}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="mt-5">
                                            <NoDataFound message="No Data Found" height="120px" />
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={cn(
                                        'grid gap-5 h-[700px]',
                                        [
                                            'Related Entities',
                                            'Partner Details',
                                            'President Details',
                                            'Member Details'
                                        ].includes(detailsSwitch)
                                            ? 'grid-rows-1'
                                            : 'grid-rows-2'
                                    )}
                                >
                                    {profileTypeData?.column2Cards?.length > 0 ? (
                                        <>
                                            {profileTypeData?.column2Cards?.map((cardData, index) => (
                                                <div key={'cardName' + index}>
                                                    <div
                                                        className={cn(
                                                            'rounded-xl !border border-main  transition-all shadow-md p-5 overflow-hidden hover:overflow-y-auto',
                                                            [
                                                                'Related Entities',
                                                                'Partner Details',
                                                                'President Details',
                                                                'Member Details'
                                                            ].includes(detailsSwitch)
                                                                ? 'h-full'
                                                                : 'h-[340px]'
                                                        )}
                                                    >
                                                        <div className="lg-title font-poppins font-semibold">
                                                            {cardData?.title}
                                                        </div>
                                                        {cardData?.data?.length > 0 ? (
                                                            <div className="flex flex-col mt-3 space-y-5 divide-y-2 divide-gray-200 border-b-2 border-gray-200">
                                                                {cardData?.data?.map((item, index) => (
                                                                    <div key={'detail_card' + index}>
                                                                        <ClientProfileDetailsCard
                                                                            link={item?.link}
                                                                            profile={item.profile}
                                                                            name={item.username}
                                                                            email={item.email}
                                                                            phone={item.phone}
                                                                            badgesArray={[
                                                                                {
                                                                                    value: item?.dob_chip
                                                                                        ? item?.dob_chip
                                                                                        : item?.info_client_category
                                                                                        ? item?.info_client_category
                                                                                        : '',
                                                                                    sx: 'bg-pink-100 text-pink-700'
                                                                                },
                                                                                {
                                                                                    value: item?.trust_type
                                                                                        ? item?.trust_type
                                                                                        : '',
                                                                                    sx: 'bg-pink-100 text-pink-700'
                                                                                },
                                                                                {
                                                                                    value: item?.DIN
                                                                                        ? `DIN: ${item?.DIN}`
                                                                                        : item?.acn
                                                                                        ? `ACN:${item.acn}`
                                                                                        : '',
                                                                                    sx: 'bg-blue-100 text-blue-700'
                                                                                },
                                                                                {
                                                                                    value: item?.tfn
                                                                                        ? `TFN:${item?.tfn}`
                                                                                        : item?.info_client_ref_no
                                                                                        ? item?.info_client_ref_no
                                                                                        : '',
                                                                                    sx: 'bg-yellow-100  text-yellow-800'
                                                                                },
                                                                                {
                                                                                    value: item?.info_client_type
                                                                                        ? convertUnderscoresToSpaces(
                                                                                              item?.info_client_type
                                                                                          )
                                                                                        : '',
                                                                                    sx: 'bg-gray-100 text-gray-600'
                                                                                },
                                                                                {
                                                                                    value: item?.percentageOfShares
                                                                                        ? `Shares: ${item?.percentageOfShares}%`
                                                                                        : item?.number_of_units
                                                                                        ? `No. of Units:${item?.number_of_units}`
                                                                                        : '',
                                                                                    sx: 'bg-red-100 text-red-700'
                                                                                },
                                                                                {
                                                                                    value: item?.status
                                                                                        ? item.status
                                                                                        : '',
                                                                                    sx: 'bg-green-100 text-green-700'
                                                                                }
                                                                            ]}
                                                                            residential_address={
                                                                                item?.residential_address || ''
                                                                            }
                                                                            dob={item?.dob}
                                                                            companyState={item?.companyState || ''}
                                                                            establishDate={item?.establishDate}
                                                                            settledSum={item?.settledSum}
                                                                            designation={item?.designation}
                                                                            partnershipPercentage={
                                                                                item.partnershipPercentage
                                                                            }
                                                                            taxableIcome={item.taxIcome}
                                                                            contact_director_name={
                                                                                item?.contact_director_name
                                                                            }
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <div className="mt-5">
                                                                <NoDataFound message="No Data Found" height="120px" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <NoDataFound message="No Data Found" height="120px" />
                                    )}
                                </div>
                                <div
                                    className={cn(
                                        'grid gap-5 h-[700px]',
                                        ['Member Details'].includes(detailsSwitch) ? 'grid-rows-2' : 'grid-rows-2'
                                    )}
                                >
                                    {profileTypeData?.column3Cards?.length > 0 ? (
                                        <>
                                            {profileTypeData?.column3Cards?.map((cardData, index) => (
                                                <div key={'cardName3' + index}>
                                                    <div
                                                        className={cn(
                                                            'rounded-xl !border border-main transition-all shadow-md p-5 overflow-hidden hover:overflow-y-auto',
                                                            ['Member Details'].includes(detailsSwitch)
                                                                ? 'h-[340px]'
                                                                : 'h-[340px]'
                                                        )}
                                                    >
                                                        <div className="lg-title font-poppins font-semibold">
                                                            {cardData?.title}
                                                        </div>

                                                        <div className="flex flex-col mt-3 space-y-5 divide-y-2 divide-gray-200 pb-5 border-b-2 border-gray-200">
                                                            {cardData?.data?.length > 0 ? (
                                                                <>
                                                                    {cardData?.data?.map((item, index) => (
                                                                        <div key={'detail_card' + index}>
                                                                            <ClientProfileDetailsCard
                                                                                link={item?.link}
                                                                                profile={item.profile}
                                                                                name={item.username}
                                                                                email={item.email}
                                                                                phone={item.phone}
                                                                                badgesArray={[
                                                                                    {
                                                                                        value: item?.info_client_category
                                                                                            ? item?.info_client_category
                                                                                            : item?.dob_chip
                                                                                            ? item?.dob_chip
                                                                                            : '',
                                                                                        sx: 'bg-pink-100 text-pink-700'
                                                                                    },
                                                                                    {
                                                                                        value: item?.trust_type
                                                                                            ? item?.trust_type
                                                                                            : '',
                                                                                        sx: 'bg-pink-100 text-pink-700'
                                                                                    },
                                                                                    {
                                                                                        value: item?.acn
                                                                                            ? `ACN:${item.acn}`
                                                                                            : '',
                                                                                        sx: 'bg-blue-100 text-blue-700'
                                                                                    },
                                                                                    {
                                                                                        value: item?.info_client_ref_no
                                                                                            ? item?.info_client_ref_no
                                                                                            : item?.tfn &&
                                                                                              item?.tfn !== 'null'
                                                                                            ? `TFN:${item?.tfn}`
                                                                                            : item?.client_ref_no
                                                                                            ? item?.client_ref_no
                                                                                            : '',
                                                                                        sx: 'bg-yellow-100  text-yellow-800'
                                                                                    },
                                                                                    {
                                                                                        value: item?.info_client_type
                                                                                            ? convertUnderscoresToSpaces(
                                                                                                  item?.info_client_type
                                                                                              )
                                                                                            : '',
                                                                                        sx: 'bg-gray-100 text-gray-600'
                                                                                    },
                                                                                    {
                                                                                        value: item?.number_of_units
                                                                                            ? `No. of Units:${item?.number_of_units}`
                                                                                            : '',
                                                                                        sx: 'bg-red-100 text-red-700'
                                                                                    },
                                                                                    {
                                                                                        value: item?.benefit_percentage
                                                                                            ? `Benefit Percentage: ${item?.benefit_percentage}%`
                                                                                            : '',
                                                                                        sx: 'bg-gray-100 text-gray-600'
                                                                                    },
                                                                                    {
                                                                                        value: item?.relationship
                                                                                            ? `Relationship: ${item?.relationship}`
                                                                                            : '',
                                                                                        sx: 'bg-pink-100 text-pink-700'
                                                                                    },
                                                                                    {
                                                                                        value: item?.status
                                                                                            ? item.status
                                                                                            : '',
                                                                                        sx: 'bg-green-100 text-green-700'
                                                                                    }
                                                                                ]}
                                                                                status={item.status}
                                                                                residential_address={
                                                                                    item?.residential_address || ''
                                                                                }
                                                                                dob={item?.dob}
                                                                                directorName={
                                                                                    profileType === 'trust' &&
                                                                                    item.directorName
                                                                                }
                                                                                companyName={
                                                                                    profileType === 'trust' &&
                                                                                    item.companyName
                                                                                }
                                                                                designation={item?.designation}
                                                                                numberOfUnits={
                                                                                    cardData?.title ===
                                                                                        'Related Entities' &&
                                                                                    item?.number_of_units
                                                                                }
                                                                                partnershipPercentage={
                                                                                    item?.partnershipPercentage
                                                                                }
                                                                                percentageOfShares={
                                                                                    item?.percentageOfShares
                                                                                }
                                                                                numberOfShares={item?.number_of_shares}
                                                                                contact_director_name={
                                                                                    item?.contact_director_name
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <NoDataFound message="No Data Found" height="120px" />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
            {message && (
                <DefaultDialog
                    children={
                        <div className="relative py-4">
                            <div
                                className="absolute right-2 top-2 cursor-pointer"
                                onClick={() => {
                                    setMessage(null);
                                }}
                            >
                                <SideTimes />
                            </div>

                            <div className="mx-5 table-title border-b pb-4 text-blueish font-poppins flex font-semibold justify-start items-center">
                                Objective Message
                            </div>
                            <div className="buttons-font-lighter font-poppins text-start mt-3 mx-5">
                                <Truncate text={message} maxLength={2000} />
                            </div>
                        </div>
                    }
                    p="!rounded-2xl !px-6 py-2"
                    open={!!message}
                    setOpen={(e) => setMessage(e)}
                    width="md:max-w-[80%]"
                />
            )}
        </div>
    );
};
