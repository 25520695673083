import React from 'react';
import AppointmentReminderPage from '../../components/workplace-content/appointment-reminder-pages/AppointmentReminderPage';
const AppointmentReminder = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <AppointmentReminderPage />
            </div>
        </div>
    );
};

export default AppointmentReminder;
