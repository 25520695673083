/* eslint-disable no-unused-vars */
import Badge from 'components/atoms/Badge';
import Slideover from 'components/atoms/SlideOver';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { DescriptionInput } from 'components/molecules/DescriptionInput';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from 'utills/filesHandlers';
import { BUSINESS_ID, ERROR_TYPE_ERROR, ERROR_TYPE_SUCCESS, USER_TYPE } from 'utills/globalVars';
import { UploadPhotoIcon } from 'utills/svgs/UploadPhotoIcon';
import pdfIcon from '../../../../files/pdfIcon.svg';
import { deleteThreadFileThunk, updateThreadThunk } from 'store/workspace/workspaceThreads';
import { setSideLoader } from 'store/global/globalReducer';
import cloneDeep from 'lodash/cloneDeep';
import { toastHandler } from 'responseHanlder';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';

export const EditThread = ({ open, setOpen, toggleThreadUpdate }) => {
    const { thread } = useSelector((state) => state.global);

    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState([]);
    const { sideLoader } = useSelector((state) => state.global);

    useEffect(() => {
        if (thread) {
            console.error(thread, 'thread_in_edit');
            setTitle(thread?.title);
            setDescription(thread?.description);
        }
    }, [thread]);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedPdfs, setSelectedPdfs] = useState([]);

    const [attachments, setAttachments] = useState([]);

    const handleSelectedFiles = (files) => {
        setSelectedFiles([...selectedFiles, ...files]);
    };

    const handleSelectedPdf = (files) => {
        setSelectedPdfs([...selectedPdfs, ...files]);
    };
    const acceptFormate = ['image/jpeg', 'image/jpg', 'image/png', 'image/heic', 'image/heif'];
    const pdfacceptFormate = ['application/pdf'];

    useEffect(() => {
        if (thread?.attachments?.length > 0) {
            setAttachments(thread?.attachments);
        }
    }, [thread]);

    const addNewThread = async () => {
        if (files.length > 4) {
            toastHandler('You can not add more than 4 files', ERROR_TYPE_ERROR);
            return;
        }
        if (!title || !description) {
            toastHandler('Please fill in required fields', ERROR_TYPE_ERROR);
            return;
        }
        const payload = {
            business_id,
            title,
            description
        };
        const formData = new FormData();
        formData.append('business_id', business_id);
        formData.append('title', title.trim());
        formData.append('description', description.trim());
        formData.append('uploaded_by', USER_TYPE);

        formData?.append('thread_id', thread?.thread_id);

        selectedFiles.forEach((file, index) => {
            formData.append('thread_files', file);
        });

        const sanitizedFilesPromises = selectedPdfs.map((file) => createNewPDFWithoutMetadata(file));
        const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
        sanitizedFiles.forEach((sanitizedFile) => {
            formData.append('thread_files', sanitizedFile);
        });

        dispatch(setSideLoader(true));
        dispatch(updateThreadThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toggleThreadUpdate();
                    setOpen(false);
                    toastHandler('Thread updated successfully', ERROR_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleDelFile = (id, fileId, createdBy, index) => {
        const payload = {
            thread_id: id,
            thread_file_id: fileId
        };

        dispatch(deleteThreadFileThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const newAtt = cloneDeep(attachments);
                    newAtt?.splice(index, 1);
                    toggleThreadUpdate();

                    setAttachments(newAtt);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    return (
        <Slideover
            title="Update Thread"
            open={open}
            setOpen={setOpen}
            footer={
                <div className="flex justify-center items-center mt-10">
                    {' '}
                    <button
                        onClick={addNewThread}
                        className="ann-btn  px-8 buttons-font-lighter cursor-pointer  rounded-xl"
                    >
                        {sideLoader ? <SmallLoaderWhite /> : 'Update Thread'}
                    </button>
                </div>
            }
        >
            <div>
                <div>
                    <RoleBasedGuard module={'Threads'} permissions={['write', 'admin']} sx="h-[50vh]">
                        {' '}
                        <div className="px-5 ">
                            <div className="add-ann-form">
                                <div>
                                    <label>Title</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setTitle(e.target.value)}
                                        type="text"
                                        placeholder="Title"
                                        maxLength={40}
                                        value={title}
                                    />
                                </div>
                            </div>

                            <div className="mt-2">
                                <DescriptionInput
                                    title="Description"
                                    placeholder="Enter thread description"
                                    onChange={(e) => setDescription(e)}
                                    value={description}
                                />
                            </div>

                            <div className="mt-3">
                                <div className="mb-2 buttons-font font-poppins">Select Images</div>
                                <DragFile
                                    atChange={(file) => {
                                        handleSelectedFiles(file);
                                    }}
                                    iconSet={{ icon: UploadPhotoIcon }}
                                    accept={acceptFormate}
                                    selectedFiles={selectedFiles}
                                    isMultiple={true}
                                    hideSelectedFiles
                                    dragMessage="Drag files here to upload image, or browse files"
                                    dropMessage="Drop the selected files in this box... "
                                    onlyPdf={false}
                                    message="Only images are allowed"
                                />
                            </div>

                            <SelectedFiles
                                files={selectedFiles}
                                updateSelectedFiles={(files) => setSelectedFiles(files || [])}
                            />

                            <div className="mt-3">
                                <div className="mb-2 buttons-font font-poppins">Select Pdf</div>
                                <DragFile
                                    atChange={(file) => {
                                        handleSelectedPdf(file);
                                    }}
                                    accept={pdfacceptFormate}
                                    hideSelectedFiles
                                    selectedFiles={selectedPdfs}
                                    isMultiple={true}
                                    dragMessage="Drag files here to upload pdf, or browse files"
                                    dropMessage="Drop the selected files in this box... "
                                    onlyPdf={false}
                                    message="Only Pdf files are allowed"
                                />
                            </div>

                            <SelectedFiles
                                files={selectedPdfs}
                                updateSelectedFiles={(files) => setSelectedPdfs(files || [])}
                            />

                            <div className="mt-5">
                                {attachments?.length > 0 && (
                                    <div className="ann-btns  mt-2 flex gap-2 flex-wrap">
                                        {attachments?.map((att, index) => (
                                            <div key={'attachments' + index}>
                                                <div>
                                                    <Badge
                                                        onCancel={() =>
                                                            handleDelFile(
                                                                thread?.thread_id,
                                                                att?.thread_file_id,
                                                                thread?.created_by_id,
                                                                index
                                                            )
                                                        }
                                                        leftIcon={
                                                            <img
                                                                src={
                                                                    att.file_type === 'image/jpeg' ||
                                                                    att.file_type === 'image/png' ||
                                                                    att.file_type === 'image/heic' ||
                                                                    att.file_type === 'image/heif'
                                                                        ? att.url
                                                                        : pdfIcon
                                                                }
                                                                alt="alt"
                                                                className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                                onClick={() => {
                                                                    if (
                                                                        att.file_type !== 'image/jpeg' &&
                                                                        att.file_type !== 'image/png' &&
                                                                        att.file_type !== 'image/heic' &&
                                                                        att.file_type !== 'image/heif'
                                                                    ) {
                                                                        downloadFile(att?.url);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        text={att.name.slice(0, 7)}
                                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </RoleBasedGuard>
                </div>
            </div>
        </Slideover>
    );
};
