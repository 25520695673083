/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/atoms/buttons/Button';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { cn } from 'pages/documents/hooks/helper';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import AddRequestDoc from './AddRequestDoc';
import { DownloadZipIcon } from 'utills/svgs/DownloadZipIcon';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { NoDataFound } from 'components/common/NoDataFound';
import { ActivityIcon } from 'utills/svgs/ActivityIcon';
import { DocumentActivity } from './DocumentActivity';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestDocumentThunk, getRequestedDocActivityThunk, requstDocDeleteItemsThunk } from 'store/JobManager';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    BUSINESS_ID,
    getProfileName,
    handleRemoveQueryParams,
    REQUEST_DOCUMENT,
    TOAST_TYPE_ERROR
} from 'utills/globalVars';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { setLoader } from 'store/global/globalReducer';
import { Chip } from 'components/atoms/buttons/Chip';
import ArrowTurnBack from 'utills/svgs/ArrowTurnBack';
import PendingEye from 'utills/svgs/PendingEye';
import RedFlag from 'utills/svgs/RedFlag';
import CirculerCheck from 'utills/svgs/CirculerCheck';
import { mergeClasses } from 'utills/tailwindUtil';
import { useDownloadZip } from 'Hooks/Files/useDownloadZip';
import { endpoint } from 'apiEndpoints';
import { toastHandler } from 'responseHanlder';
import useRoleGuard from 'Hooks/useRoleGuard';
import Pulse from 'components/atoms/Pulse';

const RequestedDocs = ({ disabled = false }) => {
    const checkbox = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { id } = useParams();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [activity, setActivity] = useState(false);
    const [allowFirst, setAllowFirst] = useState(true);
    const { handleDownloadZip } = useDownloadZip();
    const [activityData, setActivityData] = useState([]);
    const [newDoc, setNewDoc] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const { data: job } = useSelector((state) => state.getSingleJob);

    const { data: getJobActions } = useSelector((state) => state.getJobActions);
    const getActivityCount =
        getJobActions
            ?.filter((obj) => obj?.type === REQUEST_DOCUMENT)
            .map((obj) => {
                const { meta, ...restOfData } = obj;
                const mainObj = meta.ids.map((id) => {
                    return {
                        ...restOfData,
                        id: id,
                        folder_id: meta?.folder_id,
                        request_document_id: meta?.request_document_id
                    };
                });
                return mainObj;
            })
            ?.flat() || [];

    // ------------------------------------------------------ handle Notificaiton -----------------------------
    // Extracting query parameters
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const requestDocumentId = queryParams.get('request_document_id');

    useEffect(() => {
        if (allowFirst && requestDocumentId) {
            openActivity(requestDocumentId);
            setAllowFirst(false);
        }
    }, [requestDocumentId]);

    useEffect(() => {
        if (!activity && !allowFirst) {
            handleRemoveQueryParams(navigate, ['activeTab']);
        }
    }, [activity]);

    useEffect(() => {
        if (!activity) {
            setActivityData([]);
        }
    }, [activity]);

    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [page, setPage] = useState(1);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        getData();
    }, [page]);

    const getData = async (hasReset = false) => {
        const payload = {
            business_id,
            job_id: id,
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE)
        };

        setHasMoreContent(true);

        try {
            const response = await dispatch(getRequestDocumentThunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? pageData || [] : []), ...response.payload.rows];
                setPageData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handlePageChange = () => {
        setPage(page + 1);
    };

    useLayoutEffect(() => {
        const isIndeterminate = selectedIds.length > 0 && selectedIds.length < pageData.length;
        setChecked(pageData?.length > 0 && selectedIds.length === pageData.length);
        setIndeterminate(isIndeterminate);
        if (checkbox.current) {
            checkbox.current.indeterminate = isIndeterminate;
        }
    }, [selectedIds]);

    function toggleAll() {
        const newIds = [];
        pageData?.forEach((obj) => {
            newIds.push(obj);
        });

        setSelectedIds(checked || indeterminate ? [] : newIds);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const handleSelectId = (obj) => {
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item?.request_document_id === obj?.request_document_id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(obj);
            setSelectedIds(newIds);
        }
    };

    const dynamicButtons = [
        {
            icon: <DownloadZipIcon className={'w-6'} />,
            title: 'Export Zip Files',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'read', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDownloadItem(selectedIds?.map((obj) => obj?.request_document_id));
            }
        },
        {
            icon: <DeleteIcon className={'w-6'} />,
            title: 'Delete',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDeleteItem(selectedIds?.map((obj) => obj?.request_document_id));
            }
        },
        {
            icon: <PlusCircleIcon className={'w-7'} />,
            title: 'Request Document',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setNewDoc(true);
            }
        }
    ];

    const [actions, setActions] = useState(dynamicButtons || []);

    useEffect(() => {
        if (!selectedIds?.length > 0) {
            setActions([dynamicButtons?.reverse()[0]]);
        } else {
            setActions(dynamicButtons);
        }
    }, [selectedIds]);

    const openActivity = (request_document_id) => {
        if (request_document_id) {
            const jsonPayload = {
                job_id: id,
                business_id,
                request_document_id
            };
            dispatch(setLoader(true));
            dispatch(getRequestedDocActivityThunk(jsonPayload))
                .then((res) => {
                    if (res.payload) {
                        setActivityData(res.payload);
                        setActivity(true);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const statusList = [
        {
            icon: ArrowTurnBack,
            text: 'Requested',
            className: 'bg-[#7A8EF6]/10 text-[#7A8EF6]'
        },
        {
            icon: PendingEye,
            text: 'Pending Review',
            className: 'bg-[#F08201]/10 text-[#F08201]'
        },
        {
            icon: RedFlag,
            text: 'Resubmit',
            className: 'bg-[#F14747]/10 text-[#F14747]'
        },
        {
            icon: CirculerCheck,
            text: 'Completed',
            className: 'bg-[#008C51]/10 text-[#008C51]'
        },
        {
            text: 'deleted',
            className: 'bg-red-100 text-red-800'
        }
    ];

    const getStatus = (status) => {
        let findStatusObj = statusList?.find((obj) => obj?.text === status);
        return (
            <Chip
                title={findStatusObj ? findStatusObj.text : status || 'N/A'}
                classes={mergeClasses('flex w-fit items-center gap-2', findStatusObj?.className)}
                iconSet={findStatusObj ? { rightIcon: findStatusObj?.icon } : null}
            />
        );
    };

    const handleDeleteItem = (ids) => {
        const jsonPayload = {
            request_document_ids: ids?.join(',')
        };
        dispatch(setLoader(true));
        dispatch(requstDocDeleteItemsThunk(jsonPayload))
            .then((res) => {
                if (res.payload) {
                    if (page === 1) {
                        getData(true);
                    } else {
                        setPage(1);
                    }
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleDownloadItem = (ids) => {
        const jsonPayload = {
            business_id,
            job_id: id,
            item_ids: ids?.join(',')
        };
        handleDownloadZip(jsonPayload, { endpoint: endpoint.requstDocDownloadItems, method: 'GET' });
    };
    return (
        <div>
            <CollapsDivComponent
                title={
                    <span className="relative">
                        Requested Documents
                        {getActivityCount?.length > 0 && (
                            <div className="absolute -right-2 -top-2">
                                <Pulse sx={'h-3 w-3'} tooltip={getActivityCount?.length} />
                            </div>
                        )}
                    </span>
                }
                h_one="max-h-[70px]"
                h_two="max-h-[350px]"
                classes="px-5 py-3"
                defaultClose
                extra={
                    <>
                        <div className="flex items-center justify-end gap-5">
                            <AnimatePresence>
                                {actions?.map((btnObj, index) => (
                                    <motion.span
                                        key={btnObj.title}
                                        initial={{ x: '100px', opacity: 0 }}
                                        animate={{
                                            x: 0,
                                            opacity: 1
                                        }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            animate: { delay: `0.${index + 1}`, type: 'tween', duration: 0.2 },
                                            exit: { type: 'tween', duration: 0.3 }
                                        }}
                                    >
                                        <Button
                                            classes={'bg-gray-200 !rounded-full p-2'}
                                            tooltip={btnObj.title}
                                            iconSet={{ leftIconHTML: btnObj.icon }}
                                            atClick={() => btnObj?.onClick()}
                                            tooltipDirection={'none'}
                                            disabled={btnObj?.title === 'Export Zip Files' ? false : disabled}
                                        />
                                    </motion.span>
                                ))}
                            </AnimatePresence>
                        </div>
                    </>
                }
            >
                {newDoc && (
                    <AddRequestDoc
                        open={newDoc}
                        setOpen={() => setNewDoc(!newDoc)}
                        clients={job?.clients ?? []}
                        handleUpdateTable={() => {
                            if (page === 1) {
                                getData(true);
                            } else {
                                setPage(1);
                            }
                        }}
                    />
                )}

                {activity && (
                    <DocumentActivity
                        open={activity}
                        setOpen={setActivity}
                        activityData={activityData}
                        updateActivity={openActivity}
                        disabled={disabled}
                        onComplete={() => getData(true)}
                        getActivityCount={getActivityCount}
                    />
                )}
                <div className="my-5 max-h-[540px] overflow-auto">
                    {pageData?.length === 0 ? (
                        <NoDataFound height="200px" message="No Record Found!" />
                    ) : (
                        <div>
                            <InfiniteScroll
                                height={'300px'}
                                next={handlePageChange}
                                dataLength={pageData.length}
                                hasMore={hasMoreContent}
                                loader={<SkeletonTable columns={7} />}
                            >
                                <table className="min-w-full border-separate border-spacing-0">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[1] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts p-4 !px-0 !min-w-[40px] !w-[40px] text-center'
                                                }
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="rounded-[3px] cursor-pointer"
                                                    ref={checkbox}
                                                    checked={checked}
                                                    onChange={toggleAll}
                                                />
                                            </th>
                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[1] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !w-[200px] text-left'
                                                }
                                            >
                                                Title
                                            </th>

                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[1] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                }
                                            >
                                                Status
                                            </th>

                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[1] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                }
                                            >
                                                Date & Time
                                            </th>
                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[1] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                }
                                            >
                                                Created by
                                            </th>

                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[1] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                                }
                                            >
                                                Send By
                                            </th>

                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[1] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  tableHeadings primary-fonts !w-[200px] pl-[10px] text-center'
                                                }
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-transparent gap-0">
                                        {pageData &&
                                            pageData?.length > 0 &&
                                            pageData?.map((dataObj, index) => {
                                                return (
                                                    <tr
                                                        key={'req_doc_table' + index}
                                                        className="border-y relative  divide-gray-300"
                                                    >
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap !text-[16px] p-4 !px-0 text-center',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            <div className="min-w-[18px]">
                                                                {dataObj?.status !== 'deleted' && (
                                                                    <input
                                                                        type="checkbox"
                                                                        className="rounded-[3px] cursor-pointer"
                                                                        checked={selectedIds?.some(
                                                                            (item) =>
                                                                                item.request_document_id ===
                                                                                dataObj.request_document_id
                                                                        )}
                                                                        onClick={() => handleSelectId(dataObj)}
                                                                    />
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap !text-[16px] py-2',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {(() => {
                                                                const foundObj = getActivityCount.find(
                                                                    (item) =>
                                                                        item.request_document_id ===
                                                                        dataObj?.request_document_id
                                                                );

                                                                return (
                                                                    <div className="relative cursor-pointer">
                                                                        {dataObj?.name || 'N/A'}
                                                                        {foundObj && (
                                                                            <div className="absolute left-0 -top-3">
                                                                                <Pulse
                                                                                    sx={'h-3 w-3'}
                                                                                    tooltip={foundObj?.description}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })()}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black !capitalize',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : '',
                                                                dataObj?.status === 'Received'
                                                                    ? 'text-green-500'
                                                                    : 'text-red-500'
                                                            )}
                                                        >
                                                            {getStatus(dataObj?.status)}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {dataObj?.created_at ? (
                                                                <FormateDateTime dateTime={dataObj?.created_at} />
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            <DefaultProfileViewer
                                                                image={dataObj?.created_by?.image}
                                                                width="45px"
                                                                height="45px"
                                                                name={getProfileName(dataObj?.created_by)}
                                                                tooltip
                                                            />
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {dataObj?.sent_by &&
                                                            typeof dataObj?.sent_by === 'object' ? (
                                                                <DefaultProfileViewer
                                                                    image={dataObj?.sent_by?.image}
                                                                    width="45px"
                                                                    height="45px"
                                                                    name={getProfileName(dataObj?.sent_by)}
                                                                    tooltip
                                                                />
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            <div className="flex justify-center items-center gap-2">
                                                                <span
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        openActivity(dataObj?.request_document_id);
                                                                    }}
                                                                >
                                                                    <ActivityIcon className={'w-5 text-[#0A1E46]'} />
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                    )}
                </div>
            </CollapsDivComponent>
        </div>
    );
};

export default RequestedDocs;
