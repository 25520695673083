import React from 'react';
export const CopyIcon2 = ({ className }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_18500_2754)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.32418 4.58229C4.73441 4.58229 4.1669 4.81671 3.75146 5.234C3.33232 5.65166 3.09863 6.21769 3.09863 6.80783C3.09863 10.0375 3.09863 17.6733 3.09863 20.9029C3.09863 21.4931 3.33232 22.0591 3.75146 22.4768C4.1669 22.8941 4.73441 23.1285 5.32418 23.1285C7.75002 23.1285 12.5424 23.1285 14.9682 23.1285C15.558 23.1285 16.1255 22.8941 16.5409 22.4768C16.9601 22.0591 17.1937 21.4931 17.1937 20.9029C17.1937 20.4934 16.8599 20.1611 16.4519 20.1611C16.0439 20.1611 15.71 20.4934 15.71 20.9029C15.71 21.0995 15.6322 21.2883 15.4912 21.4274C15.354 21.5665 15.1648 21.6448 14.9682 21.6448H5.32418C5.12759 21.6448 4.9384 21.5665 4.80116 21.4274C4.66021 21.2883 4.58233 21.0995 4.58233 20.9029V6.80783C4.58233 6.61124 4.66021 6.42244 4.80116 6.28334C4.9384 6.14425 5.12759 6.06598 5.32418 6.06598C5.73219 6.06598 6.06602 5.73363 6.06602 5.32413C6.06602 4.91463 5.73219 4.58229 5.32418 4.58229ZM20.903 3.09859C20.903 2.50845 20.6693 1.94242 20.2502 1.52476C19.8347 1.10747 19.2672 0.873047 18.6774 0.873047C16.2516 0.873047 11.4593 0.873047 9.03342 0.873047C8.44365 0.873047 7.87614 1.10747 7.4607 1.52476C7.04156 1.94242 6.80787 2.50845 6.80787 3.09859V17.1937C6.80787 17.7838 7.04156 18.3499 7.4607 18.7675C7.87614 19.1848 8.44365 19.4192 9.03342 19.4192C11.4593 19.4192 16.2516 19.4192 18.6774 19.4192C19.2672 19.4192 19.8347 19.1848 20.2502 18.7675C20.6693 18.3499 20.903 17.7838 20.903 17.1937V3.09859ZM19.4193 3.09859V17.1937C19.4193 17.3903 19.3414 17.5791 19.2005 17.7182C19.0632 17.8573 18.874 17.9355 18.6774 17.9355H9.03342C8.83683 17.9355 8.64764 17.8573 8.5104 17.7182C8.36945 17.5791 8.29157 17.3903 8.29157 17.1937V3.09859C8.29157 2.902 8.36945 2.7132 8.5104 2.5741C8.64764 2.43501 8.83683 2.35674 9.03342 2.35674H18.6774C18.874 2.35674 19.0632 2.43501 19.2005 2.5741C19.3414 2.7132 19.4193 2.902 19.4193 3.09859Z"
                />
            </g>
        </svg>
    );
};
