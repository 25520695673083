import { AddForm } from 'components/appusers-dash/forms/dynamic-form/AddForm';
import PreviewForm from 'components/appusers-dash/forms/dynamic-form/PreviewForm';
import { Button } from 'components/atoms/buttons/Button';
import Input from 'components/atoms/Input';
import DefaultSwitch from 'components/atoms/inputs/DefaultSwitch';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import Slideover from 'components/atoms/SlideOver';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { useGetJobTypes } from 'Hooks/settings/useGetJobTypes';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFormsThunk } from 'store/form';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { toBoolean } from 'utills/handleText';
import SearchAddSelectComboboxSSR from 'components/atoms/SearchAddSelectComboboxSSR/SearchAddSelectComboboxSSR';
import { toastHandler } from 'responseHanlder';
import {
    createOnboardingServicesThunk,
    getIconListThunk,
    updateOnboardingServicesThunk
} from 'store/settings/onboardingServices';
import { setLoader } from 'store/global/globalReducer';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { ONBOARDING_SERVICES, PERMISSIONS } from 'utills/constants/forms/roleManagement.constants';
import useRoleGuard from 'Hooks/useRoleGuard';

export const AddOnboardingService = ({ open, setOpen, service, onComplete }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: icons } = useSelector((state) => state.getIconList);
    const [addForm, setAddForm] = useState(false);
    const [previewForm, setPreviewForm] = useState(false);
    const { getJobTypes } = useGetJobTypes();
    const [itemReload, setItemReload] = useState(false);
    const [formsSearch, setFormsSearch] = useState('');
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(null);
    const [pageSize, _setPageSize] = useState(5);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [form, setForm] = useState(null);
    const [isLive, setIsLive] = useState(false);

    const { hasPermissions } = useRoleGuard();

    const [selectedIcon, setSelectIcon] = useState('');

    const fetchForms = (isSearch = false) => {
        const payload = {
            page: page,
            pageSize: pageSize,

            form_status: 'live',
            service_id: '',
            business_id,
            filters: {
                search_term: formsSearch ?? ''
            }
        };

        dispatch(fetchFormsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setCount(response?.payload?.forms.count);
                    let newData;
                    if (isSearch) {
                        newData = [...response.payload.forms.rows];
                    } else {
                        newData = [...data, ...response.payload.forms.rows];
                    }
                    setData(newData);
                    setItemReload(!itemReload);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleMore = () => {
        // const totalPages = Math.ceil(count / Number(5));
        const totalPages = Math.ceil(count / Number(5));

        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (hasPermissions(ONBOARDING_SERVICES, [PERMISSIONS.ADMIN])) {
            fetchForms();
        }
    }, [page, formsSearch]);

    // const fetchForms = async (payload) => {
    //     const payloadNew = {
    //         ...payload,
    //         form_status: 'live',
    //         business_id,
    //         filters: {
    //             search_term: payload?.search_term ?? ''
    //         },
    //         service_id: '',
    //         page_size: 10
    //     };
    //     return await dispatch(fetchFormsThunk(payloadNew));
    // };
    const handleGetJobTypeItems = () => {
        getJobTypes();
        setItemReload(!itemReload);
    };

    useEffect(() => {
        if (hasPermissions(ONBOARDING_SERVICES, [PERMISSIONS.ADMIN])) {
            handleGetJobTypeItems();
        }
    }, []);

    const handleSubmit = () => {
        if (!name) {
            toastHandler('Please enter service name.', TOAST_TYPE_ERROR);
            return;
        }
        if (!description) {
            toastHandler('Please enter description.', TOAST_TYPE_ERROR);
            return;
        }
        if (!form) {
            toastHandler('Please select a Form.', TOAST_TYPE_ERROR);
            return;
        }
        if (!selectedIcon) {
            return toastHandler('Please select service icon.', TOAST_TYPE_ERROR);
        }
        const payload = {
            service_icon: selectedIcon ?? '',
            title: name ?? '',
            description: description ?? '',
            form_id: form?.form_id ?? '',
            is_live: `${isLive}`,
            business_id
        };
        dispatch(setLoader(true));
        dispatch(
            service
                ? updateOnboardingServicesThunk({ ...payload, client_service_id: service?.client_service_id })
                : createOnboardingServicesThunk(payload)
        )
            .then((res) => {
                if (res.payload) {
                    onComplete();
                    setOpen(false);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleGetIcons = () => {
        dispatch(setLoader(true));
        dispatch(getIconListThunk()).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        if (hasPermissions(ONBOARDING_SERVICES, [PERMISSIONS.ADMIN])) {
            handleGetIcons();
        }
    }, []);

    useEffect(() => {
        if (service) {
            setName(service?.title ?? '');
            setDescription(service?.description ?? '');
            setForm(service?.form?.form_id ?? '');
            setIsLive(toBoolean(service?.is_live ?? ''));
            setSelectIcon(service?.service_icon ?? '');
            setForm(service?.form ?? null);
        }
    }, [service]);
    return (
        <Slideover title={service ? 'Edit Service' : 'Add Service'} open={open} setOpen={setOpen} hideBackArrow>
            <RoleBasedGuard module={ONBOARDING_SERVICES} permissions={[PERMISSIONS.ADMIN]} sx="p-5">
                {previewForm && (
                    <PreviewForm
                        togglePreviewForm={() => setPreviewForm(!previewForm)}
                        toggleCreateForm={() => setAddForm(!addForm)}
                        directory={''}
                        toggleTerms={() => false}
                    />
                )}
                <div className="mt-10">
                    {/* <AddJobType
                                        open={add}
                                        setOpen={() => {
                                            setAdd(!add);
                                            setSelectedType(null);
                                        }}
                                        onComplete={() => getJobTypes()}
                                        type={selectedType}
                                    /> */}
                    <div className="default-forms mt-5">
                        {service?.service_category !== 'prebuilt' && (
                            <>
                                <PrimaryLabel>Select Service Icon</PrimaryLabel>
                                <div className="mb-5 mt-2 flex gap-5 items-center p-3 flex-wrap w-full bg-[#F8F4FE] ">
                                    {icons &&
                                        icons.map((item, i) => (
                                            <div key={i}>
                                                <div
                                                    onClick={() => {
                                                        setSelectIcon(item);
                                                    }}
                                                    className={`flex relative  bg-white rounded-xl p-1 transition-all justify-center pt-[0.4rem] cursor-pointer items-center  w-16 h-16  ${
                                                        selectedIcon === item ? 'ring-1 ring-thirdbg shadow-2xl ' : '  '
                                                    }`}
                                                >
                                                    {selectedIcon === item && (
                                                        <CheckCircleIcon className="h-6 w-6 z-10 absolute -top-2   -right-2 bg-white rounded-full text-thirdbg" />
                                                    )}
                                                    <img
                                                        src={item}
                                                        alt="icon"
                                                        className="w-full h-full  bg-transparent rounded-md overflow-hidden"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}
                        <PrimaryLabel>Service Name</PrimaryLabel>
                        <Input
                            sx={'mt-1'}
                            useWithoutForm
                            placeholder={'Enter Service Name'}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            required
                        />
                        <PrimaryLabel sx="mt-4">Service Description</PrimaryLabel>
                        <DefaultTextArea
                            maxCount={100}
                            onChange={(val) => setDescription(val)}
                            value={description}
                            placeholder={'Enter Description'}
                            sx={'mt-2'}
                            required
                        />
                    </div>
                    {service?.service_category !== 'prebuilt' && (
                        <div className="mt-4">
                            <PrimaryLabel sx="mt-4">Attach an active form </PrimaryLabel>
                            <SearchAddSelectComboboxSSR
                                options={data}
                                mainSx="!w-full !mx-0 mt-2 !p-0"
                                targetProperty="form_name"
                                onChange={(arr) => {
                                    setForm(arr[0] ?? '');
                                }}
                                isMultiple={false}
                                selectedItems={form ? [form] : []}
                                onRemove={() => {
                                    setData([]);
                                    setFormsSearch('');
                                    fetchForms(true);
                                }}
                                labelInside="Select Job Type"
                                childSliders={{ addItem: AddForm }}
                                extraChildProps={{
                                    condition: 'live',
                                    togglePreviewForm: () => {
                                        return false;
                                    },
                                    forceReloadTables: () => {
                                        setData([]);
                                        setFormsSearch('');
                                        fetchForms(true);
                                    }
                                }}
                                handlePageChange={handleMore}
                                hasMoreContent={page < Math.ceil(count / pageSize) ? true : false}
                                placeholder="Select Live Form"
                                onSearchChange={(val) => {
                                    setData([]);
                                    setFormsSearch(val);
                                }}
                            />
                        </div>
                    )}

                    {/* <div className="mt-4">
                        <PrimaryLabel>Job Type(Optional)</PrimaryLabel>
                        <SearchAddSelectCombobox
                            options={jobTypes}
                            mainSx="!w-full !mx-0 mt-2 !p-0   "
                            targetProperty="label"
                            onChange={(arr) => {
                                setJobType(arr[0] ?? '');
                            }}
                            isMultiple={false}
                            selectedItems={jobType ? [jobType] : []}
                            labelInside="Select Job Type"
                            placeholder="Select Job Type"
                            childSliders={{ addItem: AddJobType }}
                            extraChildProps={{
                                type: null,
                                onComplete: () => {
                                    handleGetJobTypeItems();
                                }
                            }}
                        />
                    </div> */}
                    <div className="flex justify-between items-center mt-4">
                        <PrimaryLabel>Make it live</PrimaryLabel>
                        <DefaultSwitch
                            checked={toBoolean(isLive)}
                            onChange={(_e) => setIsLive(!isLive)}
                            // disabled={!toBoolean(false)}
                        />
                    </div>

                    <div className="mt-10 flex justify-center items-center">
                        <Button
                            buttonType={'button'}
                            title={service ? 'Update Service' : 'Add Service'}
                            atClick={handleSubmit}
                            classes={
                                'ann-btn text-white px-5 md:px-20 py-2 flex gap-2 items-center cursor-pointer  rounded-xl'
                            }
                        />
                    </div>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
