import React from 'react';
import { ClientsPage } from 'components/appusers-dash/ClientsPage';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const Clients = () => {
    return (
        <SidebarLayout>
            <div className="app-dashboard">
                <ClientsPage />
            </div>
        </SidebarLayout>
    );
};
