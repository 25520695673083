import React from 'react';

export const BgPhoneIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="url(#paint0_linear_4340_386)" />
                <path
                    d="M15.7766 8.12713L14.7196 8.45122C13.7783 8.74009 12.9378 9.2966 12.2968 10.0552C11.6559 10.8139 11.2412 11.7431 11.1016 12.7334C10.6638 15.8363 11.6427 19.4643 14.0031 23.625C16.3575 27.7752 18.9493 30.449 21.8021 31.6193C22.7187 31.9953 23.7221 32.0954 24.6931 31.9079C25.664 31.7204 26.5616 31.2531 27.2791 30.5615L28.0782 29.7903C28.5971 29.2905 28.92 28.616 28.987 27.8921C29.054 27.1682 28.8605 26.4442 28.4424 25.8547L26.4432 23.0339C26.1729 22.653 25.7923 22.3678 25.3548 22.2182C24.9172 22.0686 24.4446 22.0621 24.0032 22.1996L20.9794 23.1404L20.9013 23.1554C20.5681 23.2049 19.7985 22.4712 18.8402 20.7817C17.8377 19.0142 17.6313 17.9804 17.907 17.7133L19.4447 16.2534C20.007 15.72 20.3911 15.0207 20.5432 14.2538C20.6952 13.4869 20.6075 12.6908 20.2924 11.9772L19.3164 9.77009C19.023 9.10592 18.5022 8.57302 17.8512 8.27073C17.2002 7.96845 16.4634 7.91741 15.7781 8.12713H15.7766ZM17.9733 10.3853L18.9464 12.5924C19.1359 13.0204 19.1889 13.498 19.0979 13.9582C19.0069 14.4184 18.7766 14.838 18.4392 15.1581L16.8971 16.6195C15.9108 17.5708 16.2381 19.1943 17.565 21.5319C18.8122 23.7316 19.9504 24.8179 21.1741 24.6288L21.3569 24.5898L24.4352 23.634C24.5824 23.588 24.74 23.5901 24.8859 23.6398C25.0319 23.6896 25.1588 23.7847 25.249 23.9116L27.2482 26.7324C27.4575 27.0272 27.5545 27.3893 27.5212 27.7514C27.4878 28.1135 27.3264 28.4509 27.0668 28.701L26.2663 29.4722C25.7537 29.9659 25.1127 30.2994 24.4194 30.4332C23.726 30.567 23.0095 30.4954 22.355 30.2269C19.8516 29.2006 17.4854 26.7594 15.2813 22.8748C13.0713 18.9812 12.1764 15.6698 12.5612 12.9465C12.6608 12.2389 12.957 11.5751 13.4148 11.0331C13.8727 10.4911 14.4732 10.0935 15.1456 9.88712L16.2027 9.56303C16.5454 9.45823 16.9139 9.48385 17.2394 9.63514C17.5649 9.78643 17.8253 10.053 17.9719 10.3853H17.9733Z"
                    fill="white"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_4340_386"
                        x1="21.9626"
                        y1="0.49676"
                        x2="21.9327"
                        y2="40.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
