import React from 'react';
import { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
export const CircularProgress = ({ value, text }) => {
    const [inputValue, setInputValue] = useState(parseInt(0));
    useEffect(() => {
        if (value) {
            setInputValue(parseInt(value));
        }
    }, [value]);
    return (
        <div className="circular-progress">
            <CircularProgressbarWithChildren
                value={inputValue}
                strokeWidth={13}
                styles={buildStyles({
                    rotation: 0.25,

                    pathTransitionDuration: 0.5,
                    pathColor: '#b695f8',
                    trail: { width: 5 },
                    path: { width: 5 }
                })}
            >
                <div className="flex flex-col items-center">
                    <div className="percentage-used">{inputValue}%</div>
                    <div className="percentage-text">{text && <span>{text}</span>}</div>
                </div>
            </CircularProgressbarWithChildren>
        </div>
    );
};
