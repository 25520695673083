import React from 'react';

export const HeifFile = () => {
    return (
        <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6857 1.23567H3.71382V12.612C3.71382 12.9524 3.43809 13.2298 3.09598 13.2298C2.75558 13.2298 2.47815 12.9524 2.47815 12.612V0.617835C2.47815 0.27743 2.75558 0 3.09598 0H18.0976C18.2627 0 18.4193 0.0663789 18.5367 0.182117L22.5399 4.18698C22.6573 4.30272 22.722 4.4593 22.722 4.6244V12.612C22.722 12.9524 22.4446 13.2298 22.1042 13.2298C21.7638 13.2298 21.4864 12.9524 21.4864 12.612V5.03629H20.1009C19.4371 5.03629 18.8329 4.76397 18.3955 4.32655C17.9581 3.88913 17.6857 3.28491 17.6857 2.62112V1.23567ZM3.71382 26.7643H21.4864V23.5458C21.4864 23.2054 21.7638 22.9297 22.1042 22.9297C22.4446 22.9297 22.722 23.2054 22.722 23.5458V27.3822C22.722 27.7226 22.4446 28 22.1042 28H3.09598C2.75558 28 2.47815 27.7226 2.47815 27.3822V23.5458C2.47815 23.2054 2.75558 22.9297 3.09598 22.9297C3.43809 22.9297 3.71382 23.2054 3.71382 23.5458V26.7643Z"
                fill="#0A1E46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.71382 23.9577H24.79C25.0163 23.9577 25.2002 23.7739 25.2002 23.5458V12.612C25.2002 12.3856 25.0163 12.2001 24.79 12.2001H0.41189C0.183819 12.2001 0 12.3856 0 12.612V23.5458C0 23.7739 0.183819 23.9577 0.41189 23.9577H3.71382Z"
                fill="#0A1E46"
            />
            <path
                d="M10.423 15.9573V17.3469H12.7448C13.0014 17.3469 13.2087 17.5537 13.2087 17.8095C13.2087 18.0653 13.0014 18.273 12.7448 18.273H10.423V19.6616H12.7448C13.0014 19.6616 13.2087 19.8683 13.2087 20.1241C13.2087 20.3799 13.0014 20.5877 12.7448 20.5877H10.423C9.9098 20.5877 9.49403 20.1722 9.49403 19.6616V15.9573C9.49403 15.4468 9.9098 15.0323 10.423 15.0323H12.7448C13.0014 15.0323 13.2087 15.239 13.2087 15.4948C13.2087 15.7506 13.0014 15.9573 12.7448 15.9573H10.423ZM8.10115 15.0323C7.84352 15.0323 7.63616 15.239 7.63616 15.4948V17.3469H4.85041V15.4948C4.85041 15.239 4.642 15.0323 4.38542 15.0323C4.12884 15.0323 3.92148 15.239 3.92148 15.4948V20.1241C3.92148 20.3799 4.12884 20.5877 4.38542 20.5877C4.642 20.5877 4.85041 20.3799 4.85041 20.1241V18.273H7.63616V20.1241C7.63616 20.3799 7.84352 20.5877 8.10115 20.5877C8.35774 20.5877 8.5651 20.3799 8.5651 20.1241V15.4948C8.5651 15.239 8.35774 15.0323 8.10115 15.0323ZM16.4595 15.9573C16.716 15.9573 16.9244 15.7506 16.9244 15.4948C16.9244 15.239 16.716 15.0323 16.4595 15.0323H14.6026C14.345 15.0323 14.1376 15.239 14.1376 15.4948C14.1376 15.7506 14.345 15.9573 14.6026 15.9573H15.0666V19.6616H14.6026C14.345 19.6616 14.1376 19.8683 14.1376 20.1241C14.1376 20.3799 14.345 20.5877 14.6026 20.5877H16.4595C16.716 20.5877 16.9244 20.3799 16.9244 20.1241C16.9244 19.8683 16.716 19.6616 16.4595 19.6616H15.9955V15.9573H16.4595ZM21.1041 17.8095H18.7813V15.9573H21.1041C21.3607 15.9573 21.5681 15.7506 21.5681 15.4948C21.5681 15.239 21.3607 15.0323 21.1041 15.0323H18.7813C18.2691 15.0323 17.8534 15.4468 17.8534 15.9573V20.1241C17.8534 20.3799 18.0607 20.5877 18.3173 20.5877C18.5739 20.5877 18.7813 20.3799 18.7813 20.1241V18.7355H21.1041C21.3607 18.7355 21.5681 18.5288 21.5681 18.273C21.5681 18.0162 21.3607 17.8095 21.1041 17.8095Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1554 10.4572H9.97841C9.90492 10.4572 9.83142 10.4197 9.83142 10.3451C9.79439 10.3081 9.79439 10.2335 9.83142 10.159L12.9199 4.9764C12.9199 4.9394 12.9934 4.90183 13.0669 4.90183C13.1404 4.90183 13.1774 4.9394 13.2139 4.9764L16.303 10.159C16.303 10.196 16.3394 10.2335 16.3394 10.2705C16.3394 10.3451 16.2659 10.4572 16.1554 10.4572ZM10.3094 10.0844H15.825L13.0669 5.42379L10.3094 10.0844Z"
                fill="#0A1E46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.83143 10.159C9.79439 10.2335 9.79439 10.3081 9.83143 10.3451C9.83143 10.4197 9.90492 10.4572 9.97842 10.4572H16.1554C16.2659 10.4572 16.3394 10.3451 16.3394 10.2705C16.3394 10.252 16.3303 10.2334 16.3212 10.2148C16.3121 10.1961 16.303 10.1775 16.303 10.159L13.2139 4.97639C13.1774 4.9394 13.1404 4.90183 13.0669 4.90183C12.9934 4.90183 12.9199 4.9394 12.9199 4.97639L9.83143 10.159ZM12.7668 4.91417L9.68787 10.0807L9.68509 10.0863C9.65748 10.1419 9.64026 10.2039 9.64026 10.2661C9.64026 10.3095 9.64902 10.3601 9.6751 10.4067C9.69148 10.476 9.73457 10.5297 9.78558 10.5643C9.84603 10.6054 9.91675 10.6206 9.97842 10.6206H16.1554C16.2696 10.6206 16.3577 10.5623 16.4126 10.4985C16.465 10.4377 16.5028 10.3545 16.5028 10.2705C16.5028 10.235 16.4942 10.2056 16.4871 10.1863C16.4807 10.1689 16.4728 10.1529 16.4687 10.1445L16.468 10.143C16.4674 10.1417 16.4668 10.1406 16.4664 10.1397V10.114L13.3441 4.87577L13.3303 4.86169C13.3292 4.86063 13.3281 4.85952 13.327 4.85838C13.2881 4.81874 13.2092 4.73843 13.0669 4.73843C13.0048 4.73843 12.9426 4.75375 12.8918 4.7796C12.8664 4.79249 12.8373 4.81133 12.8124 4.83842C12.7969 4.85527 12.7781 4.88079 12.7668 4.91417ZM15.825 10.0844L13.0669 5.42379L10.3094 10.0844H15.825ZM10.596 9.92101H15.5384L13.0669 5.74464L10.596 9.92101Z"
                fill="#0A1E46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.73259 10.4572H7.37046C7.2943 10.4572 7.25593 10.4183 7.21814 10.3412C7.17977 10.3029 7.17977 10.2257 7.21814 10.1485L9.92328 5.63259C9.96106 5.5943 9.99944 5.5554 10.0756 5.5554C10.1518 5.5554 10.1901 5.5943 10.2279 5.63259L11.4093 7.60122C11.4471 7.67841 11.4471 7.7556 11.4093 7.7939L9.88491 10.3412C9.84712 10.4183 9.80875 10.4572 9.73259 10.4572ZM7.71347 10.0713H9.61805L11.0279 7.71671L10.0756 6.09573L7.71347 10.0713Z"
                fill="#0A1E46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.07466 10.07L9.79316 5.5318L9.80694 5.51784C9.80801 5.51675 9.80911 5.51563 9.81025 5.51448C9.85049 5.47357 9.93072 5.39201 10.0756 5.39201C10.2204 5.39201 10.3007 5.47357 10.3409 5.51448C10.342 5.51563 10.3431 5.51675 10.3442 5.51784L10.3579 5.53176L11.5529 7.52313L11.556 7.52939C11.5838 7.58618 11.601 7.64925 11.601 7.71215C11.601 7.76741 11.5871 7.83437 11.542 7.89024L10.0284 10.4195C10.0064 10.4635 9.97532 10.515 9.92616 10.5549C9.86864 10.6017 9.80186 10.6206 9.73256 10.6206H7.37043C7.30114 10.6206 7.23435 10.6017 7.17683 10.5549C7.13237 10.5188 7.1027 10.4732 7.08114 10.4323C7.03919 10.3777 7.02594 10.3132 7.02594 10.2594C7.02594 10.196 7.04352 10.1326 7.0718 10.0757L7.07466 10.07ZM7.21812 10.3412C7.2559 10.4183 7.29427 10.4572 7.37043 10.4572H9.73256C9.80872 10.4572 9.84709 10.4183 9.88488 10.3412L11.4092 7.7939C11.447 7.75561 11.447 7.67842 11.4092 7.60123L10.2279 5.6326C10.1901 5.5943 10.1517 5.55541 10.0756 5.55541C9.99941 5.55541 9.96104 5.5943 9.92325 5.6326L7.21812 10.1485C7.17974 10.2257 7.17974 10.3029 7.21812 10.3412ZM9.52541 9.9079L10.8379 7.71592L10.0748 6.41695L8.00059 9.9079H9.52541ZM7.71345 10.0713H9.61802L11.0279 7.71671L10.0756 6.09573L7.71345 10.0713Z"
                fill="#0A1E46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1282 4.5554C10.5776 4.5554 10.1305 4.10801 10.1305 3.5577C10.1305 3.04206 10.5776 2.59467 11.1282 2.59467C11.6441 2.59467 12.0912 3.04206 12.0912 3.5577C12.0912 4.10801 11.6441 4.5554 11.1282 4.5554ZM11.1282 2.93861C10.7498 2.93861 10.4742 3.21376 10.4742 3.5577C10.4742 3.9363 10.7498 4.21146 11.1282 4.21146C11.4719 4.21146 11.7469 3.9363 11.7469 3.5577C11.7469 3.21376 11.4719 2.93861 11.1282 2.93861Z"
                fill="#0A1E46"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.1281 4.71879C10.4873 4.71879 9.96704 4.19822 9.96704 3.5577C9.96704 2.94813 10.4911 2.43127 11.1281 2.43127C11.7343 2.43127 12.2546 2.95184 12.2546 3.5577C12.2546 4.19446 11.738 4.71879 11.1281 4.71879ZM11.1281 3.102C10.834 3.102 10.6376 3.3099 10.6376 3.5577C10.6376 3.84597 10.8399 4.04806 11.1281 4.04806C11.3758 4.04806 11.5835 3.85206 11.5835 3.5577C11.5835 3.30392 11.3816 3.102 11.1281 3.102ZM10.1304 3.5577C10.1304 4.10801 10.5776 4.5554 11.1281 4.5554C11.644 4.5554 12.0912 4.10801 12.0912 3.5577C12.0912 3.04206 11.644 2.59467 11.1281 2.59467C10.5776 2.59467 10.1304 3.04206 10.1304 3.5577ZM10.4742 3.5577C10.4742 3.21376 10.7497 2.93861 11.1281 2.93861C11.4719 2.93861 11.7469 3.21376 11.7469 3.5577C11.7469 3.93631 11.4719 4.21146 11.1281 4.21146C10.7497 4.21146 10.4742 3.93631 10.4742 3.5577Z"
                fill="#0A1E46"
            />
        </svg>
    );
};
