import React, { useState } from 'react';
import './css/announcement.css';
import { AnnDetailSkeleton } from './ann-skeleton/AnnDetailSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { formateDate } from 'utills/moment';
import { LatestAnnSkeleton } from './ann-skeleton/LatestAnnSkeleton';
import { Link, useNavigate } from 'react-router-dom';
import { BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, handleDownload } from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';
import { archiveNewsThunk } from 'store/workspace/workspaceNews';
import { toastHandler } from 'responseHanlder';
import ConfirmationModal from 'utills/confirmationModal';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import Badge from 'components/atoms/Badge';
import pdfIcon from 'files/pdfIcon.svg';
import useRoleGuard from 'Hooks/useRoleGuard';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { FilesIcon } from 'utills/svgs/FilesIcon';
import TruncateDescription from 'global-components/TruncateDescription';

export const AnnDetailsCards = ({ toggleEditAnn }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [idTodelete, setidTodelete] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const { isLoading } = useSelector((state) => state.global);
    const { data } = useSelector((state) => state.getSingleNews);
    const { data: latestNews } = useSelector((state) => state.getAllNews);
    const [showImage, setShowImage] = useState(null);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [isZoomed, setIsZoomed] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const { data: loginData } = useSelector((state) => state.login);
    const allPermissions = JSON.parse(localStorage.getItem('allPermissions'));

    const parsedArray = latestNews?.rows?.map((obj) => ({
        ...obj,
        created_at: new Date(obj.created_at)
    }));

    const sortedArr = parsedArray?.sort((a, b) => b.created_at - a.created_at).reverse();
    const latestAnn = sortedArr?.slice(0, 4);

    const handleDeleteNews = () => {
        if ((allPermissions?.Announcements?.write || !loginData?.is_employee) && idTodelete) {
            const payload = {
                business_id,
                news_id: idTodelete
            };

            dispatch(setLoader(true));
            dispatch(archiveNewsThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        setIsConfirmationOpen(false);
                        setidTodelete('');
                        navigate('/announcement');
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            toastHandler("You don't have permission to do this action", ERROR_TYPE_ERROR);
        }
    };
    const deleteNews = (news_id, createdBy) => {
        if (hasPermissions('Announcements', ['write', 'admin'], createdBy)) {
            setidTodelete(news_id);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    const handleEdit = (createdBy) => {
        if (hasPermissions('Announcements', ['write', 'admin'], createdBy)) {
            toggleEditAnn();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    return (
        <div>
            {open && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpen} open={open} /> : ''}
            <div className="md:mx-10 mx-5 text-black">
                <div className="grid md:grid-cols-10 gap-5">
                    {isLoading ? (
                        <div className="col-span-6 ann-detail-card p-3 bg-white shadow  rounded-lg">
                            <AnnDetailSkeleton />
                        </div>
                    ) : (
                        <div className="col-span-6 ">
                            <div className=" bg-white shadow p-5 rounded-lg">
                                {data && (
                                    <div>
                                        {data?.attachments?.length > 0 &&
                                        data?.attachments[0]?.file_type === 'news_images' ? (
                                            <div className="ann-detail-img-container md:min-h-[480px]">
                                                <img
                                                    src={data.attachments[0].url}
                                                    alt="profile"
                                                    className="ann-detail-image"
                                                    onClick={() => setIsZoomed(true)}
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className="detail-heading ann-detail-title text-lg mt-2">
                                            {data?.title}
                                        </div>
                                        <div className="ann-detail-text text-md">
                                            <TruncateDescription text={data?.description} />
                                        </div>
                                        {data.attachments.length > 0 && (
                                            <div className="ann-btns  mt-2 flex gap-2 flex-wrap">
                                                {data?.attachments?.map((att, index) => (
                                                    <div key={'attachments_' + index}>
                                                        <div>
                                                            <Badge
                                                                leftIcon={
                                                                    att.file_type === 'news_images' ||
                                                                    att?.url?.toLowerCase()?.endsWith('.pdf') ? (
                                                                        <img
                                                                            src={
                                                                                att.file_type === 'news_images'
                                                                                    ? att.url
                                                                                    : pdfIcon
                                                                            }
                                                                            alt="alt"
                                                                            className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                                            onClick={() => {
                                                                                if (att.file_type === 'news_images') {
                                                                                    setShowImage(att.url);
                                                                                } else {
                                                                                    if (
                                                                                        att?.url
                                                                                            ?.toLowerCase()
                                                                                            ?.endsWith('.pdf')
                                                                                    ) {
                                                                                        setPdfUrl(att?.url);
                                                                                        setOpen(true);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <div onClick={() => handleDownload(att)}>
                                                                            <FilesIcon
                                                                                className={
                                                                                    'stroke-white !w-10 !h-10 mr-2 cursor-pointer'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                text={att.name}
                                                                sx="text-white bg-third rounded-md !px-3 !text-md"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <div className="ann-info flex justify-end my-3 flex-col items-end">
                                            <div className="ann-dates text-md">{formateDate(data?.created_at)}</div>
                                            <div className="ann-by text-md">
                                                By <span className="ann-auther">{data?.created_by}</span>
                                            </div>
                                        </div>
                                        {showImage && (
                                            <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />
                                        )}

                                        {isZoomed && (
                                            <ZoomImage
                                                src={data.attachments[0].url}
                                                alt="Profile"
                                                onClose={() => setIsZoomed(false)}
                                            />
                                        )}
                                    </div>
                                )}{' '}
                            </div>
                            <div className="my-5">
                                <div className="md:col-span-6 col-span-10">
                                    <div className="flex justify-center items-center gap-10">
                                        <div
                                            className="remove-ann-btn text-md px-12 py-3 cursor-pointer"
                                            onClick={() => deleteNews(data?.news_id, data?.created_by_id)}
                                        >
                                            Remove
                                        </div>
                                        <div
                                            className="edit-ann-btn text-md px-16 py-3 cursor-pointer"
                                            onClick={() => handleEdit(data?.created_by_id)}
                                        >
                                            Edit
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-4"></div>
                            </div>
                        </div>
                    )}

                    <div className="col-span-4  ">
                        <div className="ann-detail-card shadow bg-white p-3 rounded-md">
                            {' '}
                            <div className="primary-fonts text-xl my-2">Latest Announcements</div>
                            {isLoading ? (
                                <LatestAnnSkeleton />
                            ) : (
                                <div>
                                    {latestAnn &&
                                        latestAnn?.map((ann, index) => (
                                            <Link
                                                key={'latest_ann' + index}
                                                to={`/announcement/details/${ann?.news_id}`}
                                            >
                                                <div
                                                    className={`ann  p-3 cursor-pointer shadow ${index > 0 && 'mt-2'}`}
                                                >
                                                    <div>
                                                        <div className=" col-span-8 flex gap-3">
                                                            <div>
                                                                {ann?.attachments.length > 0 &&
                                                                    ann.attachments.find(
                                                                        (attachment) =>
                                                                            attachment.file_type === 'news_images'
                                                                    ) && (
                                                                        <div className="latest-ann-img-wrapper">
                                                                            <img
                                                                                src={
                                                                                    ann.attachments.find(
                                                                                        (attachment) =>
                                                                                            attachment.file_type ===
                                                                                            'news_images'
                                                                                    ).url
                                                                                }
                                                                                alt="announcementImg"
                                                                                className="ann-img"
                                                                            />
                                                                        </div>
                                                                    )}
                                                            </div>

                                                            <div className="">
                                                                <div className="ann-detail-title text-md">
                                                                    {ann?.title}
                                                                </div>
                                                                <div className="ann-detail-text text-md w-full">
                                                                    <TruncateDescription
                                                                        text={ann?.description.slice(0, 60) + '...'}
                                                                    />
                                                                </div>

                                                                {ann.attachments.length > 1 && (
                                                                    <div className="ann-btns  mt-2 flex gap-2 flex-wrap">
                                                                        {ann?.attachments?.map((att, index) => (
                                                                            <div key={'ann.attachment' + index}>
                                                                                <div>
                                                                                    <Badge
                                                                                        leftIcon={
                                                                                            att.file_type ===
                                                                                                'news_images' ||
                                                                                            att?.url
                                                                                                ?.toLowerCase()
                                                                                                ?.endsWith('.pdf') ? (
                                                                                                <img
                                                                                                    src={
                                                                                                        att.file_type ===
                                                                                                        'news_images'
                                                                                                            ? att.url
                                                                                                            : pdfIcon
                                                                                                    }
                                                                                                    alt="alt"
                                                                                                    className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                                                                    onClick={() => {
                                                                                                        if (
                                                                                                            att.file_type ===
                                                                                                            'news_images'
                                                                                                        ) {
                                                                                                            setShowImage(
                                                                                                                att.url
                                                                                                            );
                                                                                                        } else {
                                                                                                            if (
                                                                                                                att?.url
                                                                                                                    ?.toLowerCase()
                                                                                                                    ?.endsWith(
                                                                                                                        '.pdf'
                                                                                                                    )
                                                                                                            ) {
                                                                                                                setPdfUrl(
                                                                                                                    att?.url
                                                                                                                );
                                                                                                                setOpen(
                                                                                                                    true
                                                                                                                );
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            ) : (
                                                                                                <div
                                                                                                    onClick={() =>
                                                                                                        handleDownload(
                                                                                                            att
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <FilesIcon
                                                                                                        className={
                                                                                                            'stroke-white !w-10 !h-10 mr-2 cursor-pointer'
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        text={att.name}
                                                                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDeleteNews}
            />
        </div>
    );
};
