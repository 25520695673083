/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseByTypeThunk } from 'store/deductions';
import { formateDate } from 'utills/moment';
import { PhotoIcon } from 'utills/svgs/PhotoIcon';
import { DropDownMenu } from 'components/molecules/DropDownMenu';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { Pdf } from 'utills/svgs/Pdf';
import { CsvPlusImageIcon } from 'utills/svgs/CsvPlusImageIcon';
import { RoundedPhotoIcon } from 'utills/svgs/RoundedPhotoIcon';
import { Button } from 'components/atoms/buttons/Button';
import { DownloadFileIcon } from 'utills/svgs/DownloadFileIcon';
import { SmallLoader } from 'components/common/SmallLoader';
import { SearchInput } from 'components/molecules/SearchInput';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { SORT_OPTIONS } from 'utills/globalVars';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { Menu } from 'components/molecules/Menu';
import { useDownloadImages } from 'Hooks/Files/useDownloadImages';
import { useDownloadZip } from 'Hooks/Files/useDownloadZip';
import { useDownloadPdf } from 'Hooks/Files/useDownloadPdf';
import { UseDownloadCsv } from 'Hooks/Files/UseDownloadCsv';
import moment from 'moment';
import SkeletonTable from 'components/atoms/SkeletonTable';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import { toastHandler } from 'responseHanlder';
import Slideover from 'components/atoms/SlideOver';
import { useParams } from 'react-router-dom';

export const ExpenseTypeDetail = ({ open, setOpen, toggleExpenseTypeDetail }) => {
    const { id } = useParams();
    const { expense_type_id } = useSelector((state) => state.global);
    const { summary_id } = useSelector((state) => state.global);
    const { sideLoader } = useSelector((state) => state.global);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortAsc, setSortAsc] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const dispatch = useDispatch();
    const { handleDownloadCsv } = UseDownloadCsv();
    const { handleDownloadPdf } = useDownloadPdf();
    const { handleDownloadZip } = useDownloadZip();
    const { handleDownloadImages } = useDownloadImages();

    const [SortBy, setSortBy] = useState('DESC');
    const [SummaryP, setSummaryP] = useState(1);
    const [SummaryList, setSummaryList] = useState([]);
    const [summaryPS, SetSummaryPS] = useState(10);
    const [summaryCount, SetSummaryCount] = useState(0);
    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [summary, setSymmary] = useState(null);

    const exportDataOptions = [
        {
            title: 'CSV File',
            icon: CsvIcon
        },
        {
            title: 'PDF File',
            icon: Pdf
        },
        {
            title: 'CSV + Image File',
            icon: CsvPlusImageIcon
        },
        {
            title: 'Images',
            icon: RoundedPhotoIcon
        }
    ];
    const handleDownload = (type) => {
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom = moment(new Date(fromDate)).format('DD-MM-YYYY');
            formatedTo = moment(new Date(toDate)).format('DD-MM-YYYY');
        }

        if (formatedFrom !== '' && formatedTo === '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (type === 'CSV File') {
            handleDownloadCsv({
                summary_type: 'expense',
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summary?.ref_no
            });
        } else if (type === 'PDF File') {
            handleDownloadPdf({
                summary_type: 'expense',
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summary?.ref_no
            });
        } else if (type === 'CSV + Image File') {
            handleDownloadZip({
                summary_type: 'expense',
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summary?.ref_no
            });
        } else if (type === 'Images') {
            handleDownloadImages({
                summary_type: 'expense',
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summary?.ref_no
            });
        }
    };

    const downloadImages = () => {
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom = moment(new Date(fromDate)).format('DD-MM-YYYY');
            formatedTo = moment(new Date(toDate)).format('DD-MM-YYYY');
        }

        if (formatedFrom !== '' && formatedTo === '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        handleDownloadImages({
            summary_type: 'expense',
            summary_id,
            from: formatedFrom,
            to: formatedTo,
            search_term: searchTerm,
            ref_no: summary?.ref_no,
            business_client_id: id
        });
    };

    const getSummaryforBusinessDef = (data) => {
        if (data.from !== '' && data.to === '') {
            return;
        }
        if (data.from === '' && data.to !== '') {
            return;
        }

        setHasMoreContent(true);
        const payload = {
            summary_id,
            expense_type_id,
            from: data?.from,
            to: data?.to,
            sort_by: data?.sort_by ?? 'ASC',
            search_term: data?.search_term ?? '',
            page: data?.page ?? 1,
            page_size: data?.page_size ?? 15
        };
        dispatch(getExpenseByTypeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    SetSummaryCount(response?.payload?.detail?.count);
                    setSymmary(response?.payload?.summary);
                    if (data?.filter) {
                        const list = [...response?.payload?.detail?.data];
                        if (list.length === response?.payload?.detail?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                    } else {
                        const list = [...SummaryList, ...response.payload?.detail?.data];
                        if (list.length === response?.payload?.detail?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        setSortBy(sortAsc ? 'ASC' : 'DESC');
    };

    const handleSummaryClear = () => {
        setFromDate('');
        setToDate('');
        setSearchTerm('');
        setSummaryP(1);
        setSummaryList([]);
    };

    const onFilterChange = () => {
        const fromdate = fromDate === '' ? '' : moment(new Date(fromDate)).format('DD-MM-YYYY').toString();
        const todate = toDate === '' ? '' : moment(new Date(toDate)).format('DD-MM-YYYY').toString();
        if (fromdate !== '' && todate === '') {
            return;
        }
        if (fromdate === '' && todate !== '') {
            return;
        }
        setSummaryList([]);
        setSummaryP(1);
        getSummaryforBusinessDef({
            from: fromdate,
            to: todate,
            sort_by: SortBy,
            search_term: searchTerm,
            page: SummaryP,
            page_size: summaryPS,
            filter: true
        });
    };

    useEffect(() => {
        onFilterChange();
    }, [searchTerm, sortAsc, fromDate, toDate]);

    const getsummary = () => {
        const fromdate = fromDate === '' ? '' : moment(new Date(fromDate)).format('DD-MM-YYYY').toString();
        const todate = toDate === '' ? '' : moment(new Date(toDate)).format('DD-MM-YYYY').toString();
        getSummaryforBusinessDef({
            from: fromdate,
            to: todate,
            sort_by: SortBy,
            search_term: searchTerm,
            page: SummaryP,
            page_size: summaryPS,
            filter: false
        });
    };
    useEffect(() => {
        getsummary();
    }, [SummaryP]);

    const handleMoreSummry = () => {
        const totalPages = Math.ceil(summaryCount / summaryPS);
        if (SummaryP < totalPages) {
            setSummaryP(SummaryP + 1);
        }
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const datePickerProps = {
        right: CalendarSmallIco
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                size={'max-w-7xl'}
                title={`${SummaryList.length > 0 ? SummaryList[0]?.label : ''} Expense Detail`}
                showLoader
                hideBackArrow
            >
                <div className="grid">
                    <div className="my-5 flex items-baseline justify-between gap-2">
                        <div className="flex gap-2 items-center flex-wrap">
                            <SearchInput
                                width="250px"
                                atChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                                value={searchTerm}
                                placeholder="Search"
                                inputBorder="1px solid #DCDCDC"
                            />
                            <Menu
                                title="Order By"
                                border="1px solid #DCDCDC"
                                iconSet={{
                                    first: SortIcon,
                                    second: StepDownIcon
                                }}
                                options={SORT_OPTIONS}
                                gap={5}
                                lastMl={5}
                                isModalDisabled={true}
                                condition={sortAsc}
                                atMenuClick={() => {
                                    handleSortByDate();
                                }}
                                atClick={(title) => {}}
                                text="max-md"
                            />
                            {summary && (
                                <>
                                    <DatePick
                                        iconSet={datePickerProps}
                                        onDateChange={handleFromDateChange}
                                        placeholder="From"
                                        minDate={
                                            moment(summary?.start_end?.start, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''
                                        }
                                        maxDate={
                                            moment(summary?.start_end?.end, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''
                                        }
                                        value={fromDate}
                                    />
                                    <DatePick
                                        iconSet={datePickerProps}
                                        onDateChange={handleToDateChange}
                                        placeholder="To"
                                        minDate={
                                            moment(summary?.start_end?.start, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''
                                        }
                                        maxDate={
                                            moment(summary?.start_end?.end, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''
                                        }
                                        value={toDate}
                                    />
                                </>
                            )}
                            {toDate || fromDate || searchTerm ? (
                                <ClearFilterButton atClick={() => handleSummaryClear()} />
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="flex justify-between">
                            <div className="flex gap-2">
                                <DropDownMenu
                                    isLoading={sideLoader ? true : false}
                                    isButton={true}
                                    options={exportDataOptions}
                                    atClick={(type) => handleDownload(type)}
                                />

                                <Button
                                    title="Download Images"
                                    classes="ann-btn h-size8 px-3  flex gap-2 items-center"
                                    iconSet={{ leftIcon: DownloadFileIcon, loader: SmallLoader }}
                                    atClick={() => downloadImages()}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="rounded-lg my-5">
                        <div className="mt-5 bg-white rounded-lg">
                            <InfiniteScrollComp
                                height={580}
                                dataLength={SummaryList.length}
                                next={handleMoreSummry}
                                hasMore={hasMoreContent || false}
                                loader={<SkeletonTable columns={4} />}
                            >
                                <div className="default-table-container bg-white rounded-md pb-5">
                                    <div className={`grid px-5 grid-cols-${6} gap-${5}`}>
                                        <div className={`default-table-heading `}>
                                            <span> Date</span>
                                        </div>
                                        <div className={`default-table-heading col-span-2`}>
                                            <span> Description</span>
                                        </div>

                                        <div className={`default-table-heading `}>
                                            <span> GST</span>
                                        </div>
                                        <div className={`default-table-heading `}>
                                            <span> Expense</span>
                                        </div>

                                        <div className={`default-table-heading `}>
                                            <span> Image</span>
                                        </div>
                                    </div>
                                    <hr className="mt-2" />
                                    {SummaryList?.map((item, index) => (
                                        <div key={index} className={`mt-2 `}>
                                            <div className={`grid px-5 grid-cols-${6} gap-${5}`}>
                                                <div className={`summary-body flex items-center  `}>
                                                    {formateDate(item?.transaction_date)}
                                                </div>
                                                <div className={`summary-body flex items-center break-all col-span-2 `}>
                                                    {item?.description}
                                                </div>
                                                <div className={`summary-body flex items-center break-all`}>
                                                    {item?.gst_percentage === null ? 'No GST' : item?.gst_percentage}
                                                </div>
                                                <div className={`summary-body flex items-center break-all`}>
                                                    ${item?.amount}
                                                </div>

                                                <div className="summary-body flex items-center break-all">
                                                    {item?.invoice_image ? (
                                                        <button className="summary-btn rounded-lg flex gap-1  p-1 px-2 items-center">
                                                            <span>
                                                                <PhotoIcon />
                                                            </span>
                                                            <span>
                                                                {item?.invoice_image
                                                                    ? item.invoice_image.slice(-8)
                                                                    : 'No Image'}
                                                            </span>
                                                        </button>
                                                    ) : (
                                                        <span>No Image</span>
                                                    )}
                                                </div>
                                            </div>
                                            <hr className="mt-2" />
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScrollComp>
                        </div>

                        <div
                            className="bg-white py-3 absolute -bottom-[0px] border-secondarybg border-t-[1px]"
                            style={{ width: '-webkit-fill-available' }}
                        >
                            {summary && (
                                <div className=" flex justify-between items-center mx-5 primary-fonts text-xl">
                                    <div className="">Total Expense Reported</div>
                                    <div className="">{summary?.amount ?? 0}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Slideover>
        </>
    );
};
