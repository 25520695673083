import React from "react";

export const BucketIcon = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.29916 28.5897L3.29939 28.5916C3.45943 29.9554 3.90427 31.1159 4.65526 32.0469C5.40557 32.9787 6.31669 33.5 7.38057 33.5H26.6194C27.6832 33.5 28.5948 32.9788 29.3463 32.0473L29.3466 32.0469C30.0963 31.1158 30.5406 29.9554 30.7006 28.5916L30.7008 28.5897L33.4687 4.18969C33.5697 3.29913 33.4272 2.4733 33.0256 1.73107C32.6192 0.97596 32.0136 0.5 31.2023 0.5H2.79774C1.98664 0.5 1.38107 0.975716 0.974731 1.73049C0.572892 2.47287 0.430262 3.29889 0.531311 4.18969L3.29916 28.5897ZM12.4916 15.9513L12.4906 15.95C12.2105 15.5398 12.055 15.0109 12.055 14.3333C12.055 13.6558 12.2105 13.1257 12.4911 12.7133C12.7666 12.3086 13.0559 12.1667 13.37 12.1667H20.63C20.9441 12.1667 21.2339 12.3085 21.5105 12.713C21.7901 13.1254 21.945 13.6557 21.945 14.3333C21.945 15.0113 21.79 15.5404 21.5107 15.9507C21.2336 16.3578 20.9435 16.5 20.63 16.5H13.37C13.0567 16.5 12.7674 16.358 12.4916 15.9513Z"
          stroke="black"
        />
      </svg>
    </div>
  );
};
