import React from 'react';

const DocIcon = ({ className }) => {
    return (
        <svg width="62" height="72" viewBox="0 0 62 72" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M48.0102 1.28086C47.8687 1.13321 47.6991 1.01536 47.5114 0.934249C47.3236 0.853143 47.1215 0.810419 46.917 0.808594H20.1045C16.7446 0.808594 13.9375 3.56266 13.9375 6.92276V11.4727H6.39359C3.03365 11.4727 0.226562 14.2676 0.226562 17.6273V65.0772C0.226562 68.4373 3.03365 71.1914 6.39359 71.1914H41.8955C45.2554 71.1914 48.0625 68.4373 48.0625 65.0772V60.5273H55.6064C58.9664 60.5273 61.7734 57.7324 61.7734 54.3727V16.0125C61.76 15.6104 61.5929 15.2287 61.3065 14.9461L48.0102 1.28086ZM48.3672 6.10909L56.5747 14.5195H49.4004C49.1351 14.515 48.8812 14.4105 48.6897 14.2269C48.4981 14.0433 48.3829 13.7942 48.3672 13.5293V6.10909ZM45.0156 65.0772C45.0156 66.7573 43.5755 68.1445 41.8955 68.1445H6.39359C4.71354 68.1445 3.27344 66.7573 3.27344 65.0772V17.6273C3.28861 16.8057 3.62259 16.0222 4.20482 15.4422C4.78705 14.8623 5.57191 14.5314 6.39359 14.5195H13.9375V54.3727C13.9375 57.7327 16.7446 60.5273 20.1045 60.5273H45.0156V65.0772ZM55.6064 57.4805H20.1045C19.2828 57.4686 18.498 57.1377 17.9158 56.5578C17.3335 55.9778 16.9995 55.1943 16.9844 54.3727V6.92276C16.9844 5.24271 18.4245 3.85547 20.1045 3.85547H45.3203V13.5293C45.3203 15.7383 47.1911 17.5664 49.4004 17.5664H58.7266V54.3727C58.7114 55.1943 58.3774 55.9778 57.7952 56.5578C57.213 57.1377 56.4281 57.4686 55.6064 57.4805Z" />
            <path d="M51.7644 31.7344H23.1816C22.7776 31.7344 22.3901 31.8949 22.1044 32.1806C21.8187 32.4663 21.6582 32.8538 21.6582 33.2578C21.6582 33.6619 21.8187 34.0493 22.1044 34.335C22.3901 34.6207 22.7776 34.7812 23.1816 34.7812H51.7644C52.1684 34.7812 52.5559 34.6207 52.8416 34.335C53.1273 34.0493 53.2878 33.6619 53.2878 33.2578C53.2878 32.8538 53.1273 32.4663 52.8416 32.1806C52.5559 31.8949 52.1684 31.7344 51.7644 31.7344Z" />
            <path d="M23.1816 26.25H40.2929C40.6969 26.25 41.0844 26.0895 41.3701 25.8038C41.6558 25.5181 41.8163 25.1306 41.8163 24.7266C41.8163 24.3225 41.6558 23.935 41.3701 23.6493C41.0844 23.3636 40.6969 23.2031 40.2929 23.2031H23.1816C22.7776 23.2031 22.3901 23.3636 22.1044 23.6493C21.8187 23.935 21.6582 24.3225 21.6582 24.7266C21.6582 25.1306 21.8187 25.5181 22.1044 25.8038C22.3901 26.0895 22.7776 26.25 23.1816 26.25Z" />
            <path d="M51.7644 40.875H23.1816C22.7776 40.875 22.3901 41.0355 22.1044 41.3212C21.8187 41.6069 21.6582 41.9944 21.6582 42.3984C21.6582 42.8025 21.8187 43.19 22.1044 43.4757C22.3901 43.7614 22.7776 43.9219 23.1816 43.9219H51.7644C52.1684 43.9219 52.5559 43.7614 52.8416 43.4757C53.1273 43.19 53.2878 42.8025 53.2878 42.3984C53.2878 41.9944 53.1273 41.6069 52.8416 41.3212C52.5559 41.0355 52.1684 40.875 51.7644 40.875Z" />
        </svg>
    );
};

export default DocIcon;
