import React from "react";

export const OrangeHash = () => {
  return (
    <div>
      <svg
        width="36"
        height="36"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          opacity="0.1"
          y="0.0488281"
          width="40"
          height="40"
          rx="20"
          fill="#F1A167"
        />
        <path
          d="M17.0309 12.7389C16.7602 12.8292 16.5583 12.9839 16.4422 13.1944C16.3778 13.3061 16.3649 13.5382 16.3477 14.8487L16.3262 16.3741L14.8008 16.3956C13.4903 16.4128 13.2583 16.4257 13.1465 16.4901C12.5235 16.8339 12.5235 17.762 13.1465 18.1057C13.2583 18.1702 13.4903 18.1831 14.8008 18.2003L16.3262 18.2218V20.0479V21.8741L14.8008 21.8956C13.1551 21.9171 13.1422 21.9214 12.9274 22.162C12.5192 22.6089 12.6266 23.3222 13.1465 23.6057C13.2583 23.6702 13.4903 23.6831 14.8008 23.7003L16.3262 23.7218L16.3477 25.2472C16.3692 26.8929 16.3735 26.9057 16.6141 27.1206C17.0395 27.5073 17.6712 27.4429 18.002 26.9745L18.1309 26.7854L18.1524 25.2557L18.1739 23.7218H20.0001H21.8262L21.8477 25.2472C21.8692 26.8929 21.8735 26.9057 22.1141 27.1206C22.5395 27.5073 23.1712 27.4429 23.502 26.9745L23.6309 26.7854L23.6524 25.2557L23.6739 23.7218L25.2079 23.7003L26.7376 23.6788L26.9266 23.5499C27.395 23.219 27.4594 22.5874 27.0727 22.162C26.8579 21.9214 26.845 21.9171 25.1993 21.8956L23.6739 21.8741V20.0479V18.2218L25.2079 18.2003L26.7376 18.1788L26.9266 18.0499C27.395 17.719 27.4594 17.0874 27.0727 16.662C26.8579 16.4214 26.845 16.4171 25.1993 16.3956L23.6739 16.3741L23.6524 14.8487C23.6309 13.203 23.6266 13.1901 23.386 12.9753C22.9391 12.5671 22.2258 12.6745 21.9422 13.1944C21.8778 13.3061 21.8649 13.5382 21.8477 14.8487L21.8262 16.3741H20.0001H18.1739L18.1524 14.8487C18.1309 13.203 18.1266 13.1901 17.886 12.9753C17.6626 12.769 17.2715 12.6616 17.0309 12.7389ZM21.8262 20.0479V21.8741H20.0001H18.1739L18.161 20.0909C18.1567 19.1112 18.161 18.2819 18.1739 18.2518C18.1868 18.2089 18.5778 18.2003 20.0087 18.2089L21.8262 18.2218V20.0479Z"
          fill="#F1A167"
        />
      </svg>
    </div>
  );
};
