export function convertSpacesToUnderscores(str) {
    return str.replace(/ /g, '_');
}

export function convertUnderscoresToSpaces(str) {
    return str.replace(/_/g, ' ');
}

export function convertSpacesToUnderscoresTwo(str) {
    return str.replace(/ /g, '_').toLowerCase();
}

export function sanitizeString(input) {
    return input
        ? input
              .replace(/\s+/g, '')
              .replace(/[^a-zA-Z0-9]/g, '')
              .toLowerCase()
        : '';
}
export function convertHyphensToUnderscores(str) {
    return str?.replace(/[-/ ]/g, '_');
}

export function capitalizeFirstLetter(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toBoolean(value) {
    if (typeof value === 'string') {
        const lowerValue = value.toLowerCase();
        if (lowerValue === 'true') return true;
        if (lowerValue === 'false') return false;
    }
    // Return the value as-is if it's already a boolean or not a boolean-like string
    return value;
}

export function getOrdinalValue(number) {
    const ordinals = [
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'ninth',
        'tenth',
        'eleventh'
    ];

    if (number >= 1 && number <= 11) {
        return ordinals[number - 1];
    } else {
        return 'Invalid number'; // Handle out-of-range inputs
    }
}
