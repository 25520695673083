import React from 'react';

export const DownloadFileIcon = () => {
    return (
        <div>
            <svg width="14" height="14" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4409_1271)">
                    <path
                        d="M9.64409 0.062501C9.40581 0.132813 9.11284 0.398438 8.99956 0.644532L8.90581 0.839845V6.27734C8.90581 9.26953 8.89409 11.7188 8.87456 11.7188C8.85894 11.7188 8.26519 11.1367 7.55814 10.4258C6.84722 9.71484 6.19097 9.08984 6.09722 9.03514C5.86675 8.89844 5.43314 8.86328 5.14753 8.96484C4.94487 9.05078 4.67144 9.29688 4.55814 9.51172C4.43314 9.74219 4.43706 10.2617 4.56597 10.5078C4.69097 10.7539 9.24566 15.3086 9.49175 15.4336C9.74566 15.5664 10.2535 15.5664 10.5074 15.4336C10.7535 15.3086 15.3082 10.7539 15.4332 10.5078C15.5621 10.2617 15.566 9.74219 15.441 9.51172C15.3277 9.29688 15.0542 9.05078 14.8314 8.96484C14.566 8.86328 14.1324 8.89844 13.9019 9.03514C13.8082 9.08984 13.1519 9.71484 12.441 10.4258C11.7339 11.1367 11.1402 11.7188 11.1246 11.7188C11.105 11.7188 11.0933 9.26953 11.0933 6.27734V0.839845L10.9996 0.644532C10.7691 0.140626 10.1871 -0.105468 9.64409 0.062501Z"
                        fill="white"
                    />
                    <path
                        d="M0.878578 13.3555C0.503578 13.4531 0.143734 13.7617 0.0621718 14.1055C-0.0120469 14.3514 -0.0237657 15.6719 0.0426406 14.1562C0.314078 18.1211 1.87858 19.6836 3.84342 19.957C4.2653 20.0156 15.734 20.0156 14.1559 19.957C18.1208 19.6836 19.6833 18.1211 19.9567 14.1562C20.0231 15.6719 20.0114 14.3514 19.9372 14.1055C19.863 13.8514 19.6051 13.5586 19.3434 13.4336C18.9255 13.2344 18.3942 13.3514 18.0661 13.7188C17.8083 14.0078 17.7731 14.1441 17.7731 15.0586C17.7731 14.1523 17.6559 14.5391 17.1755 17.0547C14.9098 17.3398 14.6598 17.5117 14.2965 17.6523L14.0348 17.7539L10.1149 17.7656C4.32389 17.7734 4.19498 17.7734 3.8942 17.6953C3.48405 17.5938 3.2028 17.4258 2.87077 17.0938C2.34733 14.5664 2.22623 14.1836 2.22623 15.0586C2.22623 14.1441 2.19108 14.0078 1.93327 13.7188C1.67155 13.4297 1.21061 13.2695 0.878578 13.3555Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_4409_1271">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
