import { useDispatch } from 'react-redux';
import { getAllServicesThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID } from 'utills/globalVars';

export const useFetchServices = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);

    const fetchServices = () => {
        dispatch(getAllServicesThunk({ business_id }))
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                dispatch(setLoader(false));
            });
    };
    return { fetchServices };
};
