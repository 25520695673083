import React from 'react';

export const HomeIcon = ({ className }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M27.654 14.5668C27.8606 14.6935 28.114 14.7002 28.3273 14.5802C28.534 14.4669 28.6673 14.2402 28.6673 14.0002V11.3335C28.6673 11.1001 28.5473 10.8868 28.3473 10.7668L26.6673 9.74016V6.00016C26.6673 5.2668 26.0673 4.66683 25.334 4.66683H24.0007C23.2673 4.66683 22.6673 5.2668 22.6673 6.00016V7.2935L16.3473 3.43347C16.134 3.30017 15.8673 3.30017 15.654 3.43347L3.65397 10.7668C3.45398 10.8868 3.33398 11.1001 3.33398 11.3335V14.0002C3.33398 14.2402 3.46732 14.4668 3.67399 14.5802C3.88733 14.7002 4.14066 14.6935 4.34733 14.5668L5.33398 13.9669V24.6668H4.00065C3.63399 24.6668 3.33398 24.9669 3.33398 25.3335V28.0002C3.33398 28.3668 3.63399 28.6668 4.00065 28.6668H28.0007C28.3673 28.6668 28.6673 28.3668 28.6673 28.0002V25.3335C28.6673 24.9669 28.3673 24.6668 28.0007 24.6668H26.6673V13.9669L27.654 14.5668ZM24.0007 6.00017H25.334V8.92017L24.0007 8.10682V6.00017ZM10.0007 27.3335H4.66732V26.0002H10.0007V27.3335ZM18.0007 27.3335H14.0007V22.0002H18.0007V27.3335ZM20.6673 25.3335V27.3335H19.334V21.3335C19.334 20.9668 19.034 20.6668 18.6673 20.6668H13.334C12.9673 20.6668 12.6673 20.9668 12.6673 21.3335V27.3335H11.334V25.3335C11.334 24.9668 11.034 24.6668 10.6673 24.6668H6.66732V13.1535L16.0007 7.44682L25.334 13.1535V24.6668H21.334C20.9673 24.6668 20.6673 24.9668 20.6673 25.3335ZM27.334 26.0002V27.3335H22.0007V26.0002H27.334ZM16.3473 6.10014C16.2406 6.03349 16.1206 6.00017 16.0007 6.00017C15.8807 6.00017 15.7607 6.0335 15.654 6.10014L4.66732 12.8135V11.7068L16.0007 4.78016L27.334 11.7068V12.8135L16.3473 6.10014Z"
                fill="#0A1E46"
            />
            <path
                d="M12.666 13.9998C12.666 15.6743 15.0605 18.0285 15.541 18.4836C15.6693 18.6053 15.8346 18.6665 15.9993 18.6665C16.1641 18.6665 16.3294 18.6053 16.4577 18.4836C16.9381 18.0285 19.3327 15.6743 19.3327 13.9998C19.3327 12.1619 17.8372 10.6665 15.9993 10.6665C14.1615 10.6665 12.666 12.1619 12.666 13.9998ZM17.9993 13.9998C17.9993 14.6991 16.944 16.0702 15.9993 17.0578C15.0547 16.0701 13.9993 14.6991 13.9993 13.9998C13.9993 12.897 14.8965 11.9998 15.9993 11.9998C17.1022 11.9998 17.9993 12.897 17.9993 13.9998Z"
                fill="#0A1E46"
            />
            <path
                d="M16.0007 14.6668C16.3688 14.6668 16.6673 14.3684 16.6673 14.0002C16.6673 13.632 16.3688 13.3335 16.0007 13.3335C15.6325 13.3335 15.334 13.632 15.334 14.0002C15.334 14.3684 15.6325 14.6668 16.0007 14.6668Z"
                fill="#0A1E46"
            />
        </svg>
    );
};
