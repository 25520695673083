import { Button } from 'components/atoms/buttons/Button';
import React, { useEffect, useState } from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { MileStoneIcon } from 'utills/svgs/MileStoneIcon';
import { PlusVector } from 'utills/svgs/PlusVector';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { MilestoneCalendarIcon } from './../../../../utills/svgs/MilestoneCalendarIcon';
import EditableText from 'components/atoms/EditableText/EditableText';
import dayjs from 'dayjs';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { MilestoneTasks } from './mileStoneTasks/MilestoneTasks';
import { MilestoneCalendarRed } from 'utills/svgs/MilestoneCalendarRed';
import { useParams } from 'react-router-dom';
import { formatDateIfNecessary } from 'utills/moment';
import { useDispatch, useSelector } from 'react-redux';
import { changeMilestoneSequenceThunk, deleteMilestoneThunk, updateMilestoneThunk } from 'store/JobManager';
import { setLoader } from 'store/global/globalReducer';
import { sortByKey } from 'utills/sortAndFilter';
import cloneDeep from 'lodash/cloneDeep';

import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonTable from 'components/atoms/SkeletonTable';
import ConfirmationModal from 'utills/confirmationModal';

import useRoleGuard from 'Hooks/useRoleGuard';

import MilestoneForm from './MilestoneForm';
import { Bars4Icon } from '@heroicons/react/24/outline';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';

export const MilestonesAndTasks = ({ data, handlePageChange, hasMoreContent }) => {
    const dispatch = useDispatch();
    const [mileStonesLength, _setMileStonesLength] = useState(10);
    const { id: job_id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [milestones, setMilestones] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const { hasPermissions } = useRoleGuard();
    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    const [highlightedChip, setHighlightedChip] = useState(false);
    const [highlightedChipIndex, setHighlightedChipIndex] = useState(0);

    const [openIndexes, setOpenIndexes] = useState([]);

    const handleCollaps = (id) => {
        const newOpenIndexes = [...openIndexes];
        const findId = newOpenIndexes?.find((item) => item === id);
        if (findId) {
            const idIndex = newOpenIndexes?.indexOf(findId);

            newOpenIndexes?.splice(idIndex, 1);
        } else {
            newOpenIndexes?.push(id);
        }

        setOpenIndexes(newOpenIndexes);
    };

    const onSortEnd = (oldIndex, newIndex) => {
        if (!disabled) {
            if (!hasPermissions('Job', ['write', 'admin'])) {
                return toastHandler('You dont have permission to do this action.', TOAST_TYPE_ERROR);
            }
            const newArray = arrayMoveImmutable(milestones, oldIndex, newIndex);

            // Step 2: Swap the sequence between the old and new positions
            const oldSequence = newArray[newIndex].sequence;
            newArray[newIndex].sequence = newArray[oldIndex].sequence;
            newArray[oldIndex].sequence = oldSequence;

            const payload = {
                job_id,
                business_id,
                list: newArray.map((item, i) => ({ sequence: i + 1, milestone_id: item?.milestone_id }))
            };

            dispatch(changeMilestoneSequenceThunk(payload)).then((response) => {
                console.info(response.payload);
            });

            setMilestones(newArray);
        }
    };

    const handleNewMileStoneObj = (index, sequence) => {
        const newMilestones = [...milestones];

        const alreadyAdded = newMilestones?.some((item) => item?.is_new);

        if (alreadyAdded) {
            return;
        }

        const milestone = {
            id: index + 1,
            title: '',
            description: '',
            start_date: '',
            end_date: '',
            is_new: true,
            sequence: sequence ? sequence : 1
        };

        newMilestones.splice(index, 0, milestone);

        setMilestones(newMilestones);
    };

    const handleRemoveMilestone = (index) => {
        const newMilestones = [...milestones];
        newMilestones.splice(index, 1);
        setMilestones(newMilestones);
    };

    const handleNewMilestoneSubmit = (dataObj, index) => {
        const newMilestones = cloneDeep(milestones);
        newMilestones[index] = dataObj;
        setMilestones(sortByKey(newMilestones, 'sequence'));
    };

    const handleDeleteMilestone = () => {
        setIsConfirmationOpen(false);
        setDeleteId('');

        dispatch(setLoader(true));
        dispatch(deleteMilestoneThunk({ milestone_id: deleteId }))
            .then((response) => {
                if (response.payload) {
                    handlePageChange(1);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        setMilestones(sortByKey(data, 'sequence'));
    }, [data]);

    const handleInlineEdit = (key, value, id) => {
        const newMilestones = cloneDeep(milestones);
        const milestone = newMilestones?.find((item) => item?.milestone_id === id);

        delete milestone.sequence;
        delete milestone.status;
        delete milestone.created_at;
        delete milestone.updated_at;
        milestone[key] = value;

        if (!value || value?.trim()?.length < 1) {
            return toastHandler('Value is required', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(updateMilestoneThunk(milestone))
            .then((response) => {
                if (response.payload) {
                    handlePageChange(1);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div>
            {mileStonesLength < 1 && (
                <div className="w-full bg-white rounded-xl shadow-lg h-[500px] flex justify-center items-center flex-col gap-5">
                    <MileStoneIcon />
                    <div className="text-[#898989] text-md font-[400]">No Milestone Added Yet</div>
                    <Button
                        title="+Add Milestone"
                        classes={'ann-btn rounded-xl px-8 '}
                        atClick={() => {
                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                return toastHandler('You dont have permission to do this action.', TOAST_TYPE_ERROR);
                            }

                            handleNewMileStoneObj(0);
                        }}
                        disabled={disabled ? true : false}
                    />
                </div>
            )}

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDeleteMilestone}
            />

            <div className="w-full p-5 mb-5  bg-white rounded-xl shadow-lg flex justify-between items-center gap-5">
                <div className="text-xl text-[#0A1E46] font-poppins font-[500] ">Milestones</div>

                <Button
                    title="+ New Milestone"
                    classes={'ann-btn rounded-xl px-8 '}
                    atClick={() => {
                        if (!hasPermissions('Job', ['write', 'admin'])) {
                            return toastHandler('You dont have permission to do this action.', TOAST_TYPE_ERROR);
                        }
                        handleNewMileStoneObj(0);
                    }}
                    disabled={disabled}
                />
            </div>

            <InfiniteScroll
                height={'600px'}
                next={handlePageChange}
                dataLength={data?.length}
                hasMore={hasMoreContent}
                loader={<SkeletonTable columns={6} />}
            >
                <SortableList onSortEnd={onSortEnd} className="listv" draggedItemClassName="draggedd">
                    {milestones?.map((milestone, index) => {
                        if (milestone?.is_new) {
                            return (
                                <SortableItem key={'stortable_item' + index} className="dragged">
                                    <div className="">
                                        <div className="w-full p-5  bg-white rounded-xl shadow-lg  ">
                                            <div className="flex justify-between items-center gap-5 flex-wrap">
                                                <div className="flex gap-2 items-center">
                                                    <SortableKnob>
                                                        <div
                                                            className={`cursor-grab ${
                                                                disabled && '!cursor-not-allowed opacity-50'
                                                            } `}
                                                        >
                                                            <Bars4Icon className="w-5" />
                                                        </div>
                                                    </SortableKnob>
                                                    <div className="border-thirdbg border rounded-xl font-[500] p-2 flex gap-2 items-center">
                                                        <MileStoneIcon
                                                            className={'w-[27px]  h-[27px]'}
                                                            fill={'#b695f8'}
                                                        />
                                                        <span> Milestone {index + 1} </span>
                                                    </div>
                                                </div>
                                                <div className="flex gap-2 items-center">
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                                                return toastHandler(
                                                                    'You dont have permission to do this action.',
                                                                    TOAST_TYPE_ERROR
                                                                );
                                                            }
                                                            handleRemoveMilestone(index);
                                                        }}
                                                    >
                                                        {' '}
                                                        <BgDeleteIcon />{' '}
                                                    </span>
                                                </div>
                                            </div>

                                            <MilestoneForm
                                                milestone={milestone}
                                                index={index}
                                                job_id={job_id}
                                                onSubmit={handleNewMilestoneSubmit}
                                                onCancel={handleRemoveMilestone}
                                            />
                                        </div>
                                    </div>
                                </SortableItem>
                            );
                        } else {
                            return (
                                <SortableItem key={'stortable_item' + index} className="dragged">
                                    <div className="">
                                        <div className="p-5  bg-white rounded-xl shadow-lg  ">
                                            <div className="flex justify-between items-center gap-5 flex-wrap">
                                                <div className="flex gap-2 items-center">
                                                    <SortableKnob>
                                                        <div
                                                            className={`cursor-grab ${
                                                                disabled && '!cursor-not-allowed opacity-50'
                                                            } `}
                                                        >
                                                            <Bars4Icon className="w-5" />
                                                        </div>
                                                    </SortableKnob>
                                                    <div className="border-thirdbg border rounded-xl font-[500] p-2 flex gap-2 items-center">
                                                        <MileStoneIcon
                                                            className={'w-[27px]  h-[27px]'}
                                                            fill={'#b695f8'}
                                                        />
                                                        <span> Milestone {index + 1} </span>
                                                    </div>
                                                </div>
                                                <div className="flex gap-2 items-center">
                                                    <span
                                                        className={`cursor-pointer ${
                                                            disabled && '!cursor-not-allowed opacity-50'
                                                        } `}
                                                        onClick={() => {
                                                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                                                return toastHandler(
                                                                    'You dont have permission to do this action.',
                                                                    TOAST_TYPE_ERROR
                                                                );
                                                            }
                                                            if (!disabled) {
                                                                // handleRemoveMilestone(index);
                                                                setDeleteId(milestone?.milestone_id);
                                                                setIsConfirmationOpen(true);
                                                            }
                                                        }}
                                                    >
                                                        {' '}
                                                        <BgDeleteIcon />{' '}
                                                    </span>
                                                    <span
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            handleCollaps(milestone?.milestone_id);
                                                        }}
                                                    >
                                                        {openIndexes?.find(
                                                            (item) => item === milestone?.milestone_id
                                                        ) ? (
                                                            <CollapsIcon className={'w-6 h-6'} />
                                                        ) : (
                                                            <ExpandIcon className={'w-6 h-6'} />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="flex justify-between items-center gap-5 flex-wrap mt-5 ">
                                                <div className="text-xl text-[#0A1E46] font-poppins font-[500] flex-1 ">
                                                    <EditableText
                                                        text={milestone?.title}
                                                        onUpdate={(text) =>
                                                            handleInlineEdit('title', text, milestone?.milestone_id)
                                                        }
                                                        isTextArea={false}
                                                        componentName="text"
                                                        permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                                                        disabled={disabled}
                                                    />
                                                </div>

                                                <div className="flex gap-10 items-center cursor-pointer ">
                                                    <EditableText
                                                        text={milestone?.start_date}
                                                        onUpdate={(text) =>
                                                            handleInlineEdit(
                                                                'start_date',
                                                                formatDateIfNecessary(text),
                                                                milestone?.milestone_id
                                                            )
                                                        }
                                                        minDate={dayjs()}
                                                        componentName="date"
                                                        sx="w-fit"
                                                        maxDate={milestone?.due_date}
                                                        permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                                                        disabled={disabled}
                                                    >
                                                        <div className="flex gap-2 items-center ">
                                                            <div>
                                                                <MilestoneCalendarIcon
                                                                    fill="#F08201/10"
                                                                    className="w-[35px]"
                                                                />
                                                            </div>

                                                            <div className="text-sm">
                                                                {' '}
                                                                <div className="font-[400] text-[#666666]">
                                                                    Start Date
                                                                </div>
                                                                <div>{milestone?.start_date} </div>
                                                            </div>
                                                        </div>
                                                    </EditableText>

                                                    <div className="cursor-pointer">
                                                        {' '}
                                                        <EditableText
                                                            text={milestone?.due_date}
                                                            onUpdate={(text) =>
                                                                handleInlineEdit(
                                                                    'due_date',
                                                                    formatDateIfNecessary(text),
                                                                    milestone?.milestone_id
                                                                )
                                                            }
                                                            minDate={milestone?.start_date}
                                                            componentName="date"
                                                            sx="w-fit"
                                                            permissions={{
                                                                module: 'Job',
                                                                permissions: ['write', 'admin']
                                                            }}
                                                            disabled={disabled}
                                                        >
                                                            <div className="flex gap-2 items-center ">
                                                                <div>
                                                                    <MilestoneCalendarRed />
                                                                </div>

                                                                <div className="text-sm">
                                                                    {' '}
                                                                    <div className="font-[400] text-[#666666]">
                                                                        End Date
                                                                    </div>
                                                                    <div>{milestone?.due_date} </div>
                                                                </div>
                                                            </div>
                                                        </EditableText>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <EditableText
                                                    text={milestone?.description}
                                                    placeholder="Enter description"
                                                    onUpdate={(text) =>
                                                        handleInlineEdit('description', text, milestone?.milestone_id)
                                                    }
                                                    permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                                                    disabled={disabled}
                                                />
                                            </div>

                                            <div className="w-full">
                                                {openIndexes?.find((item) => item === milestone?.milestone_id) && (
                                                    <div className="mt-5 w-full">
                                                        <MilestoneTasks
                                                            milestone_id={milestone?.milestone_id}
                                                            milestone={milestone}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div
                                            className="h-5 w-full bg-transparent"
                                            onMouseEnter={() => {
                                                setHighlightedChip(true);
                                                setHighlightedChipIndex(index);
                                            }}
                                            onMouseLeave={() => {
                                                setHighlightedChip(false);
                                                setHighlightedChipIndex('');
                                            }}
                                        ></div>
                                        {highlightedChip && highlightedChipIndex === index ? (
                                            <div
                                                onMouseEnter={() => {
                                                    setHighlightedChip(true);
                                                    setHighlightedChipIndex(index);
                                                }}
                                                onClick={() => {
                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                        return toastHandler(
                                                            'You dont have permission to do this action.',
                                                            TOAST_TYPE_ERROR
                                                        );
                                                    }
                                                    if (!disabled) {
                                                        handleNewMileStoneObj(index + 1, milestone?.sequence);
                                                    }
                                                }}
                                                className={`relative w-full cursor-pointer ${
                                                    disabled && '!cursor-not-allowed opacity-50'
                                                } `}
                                            >
                                                <div className="bg-thirdbg w-full h-[1px]"></div>
                                                <div className="absolute left-[90px] transform -translate-x-1/2 flex gap-2 items-center -top-[14px] bg-[#D6BAFB] font-[400] py-1 text-sm text-white px-2 rounded-md ">
                                                    <PlusVector className="!fill-white" /> Add Milestone
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="w-full h-[1px] bg-transparent"> </div>
                                        )}

                                        <div
                                            className="h-5 w-full bg-transparent"
                                            onMouseEnter={() => {
                                                setHighlightedChip(true);
                                                setHighlightedChipIndex(index);
                                            }}
                                            onMouseLeave={() => {
                                                setHighlightedChip(false);
                                                setHighlightedChipIndex('');
                                            }}
                                        ></div>
                                    </div>
                                </SortableItem>
                            );
                        }
                    })}
                </SortableList>{' '}
            </InfiniteScroll>
        </div>
    );
};
