import React from 'react';
import { useDispatch } from 'react-redux';
import './css/businessSteps.css';
import { useEffect, useState } from 'react';
import Ripples from 'react-ripples';
import 'react-toastify/dist/ReactToastify.css';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, ERROR_TYPE_ERROR, ERROR_TYPE_SUCCESS, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { getBusinessHoursThunk, updateBusinessHoursThunk } from 'store/auth/slices';
import { generateId } from 'utills/uid';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import useRoleGuard from 'Hooks/useRoleGuard';
const BusinessHours = () => {
    const dispatch = useDispatch();
    const [_hoursLoader, setHourseLoader] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [data, setData] = useState(null);
    const [dayIndex, setDayIndex] = useState(null);
    const [weekInView, setWeekInView] = useState(null);
    const [hoursUpdated, setHoursUpdated] = useState(false);

    const [formData, setFormData] = useState({
        day: '',
        from: '',
        to: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            const findDay = data[dayIndex];

            const payload = {
                working_hour_id: findDay?.working_hour_id,
                day: findDay?.day,
                start_time: formData?.from,
                end_time: formData?.to,
                closed: findDay?.closed
            };

            const hasEmptyValue = Object.values(payload).some((value) => {
                return (
                    value === '' ||
                    value === null ||
                    value === undefined ||
                    (Array.isArray(value) && value.length === 0)
                );
            });
            if (hasEmptyValue) {
                return toastHandler('Please fill in all required fields.', ERROR_TYPE_ERROR);
            }
            setHourseLoader(true);
            dispatch(updateBusinessHoursThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        toastHandler('hours updated', ERROR_TYPE_SUCCESS);
                        setHoursUpdated(!hoursUpdated);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setHourseLoader(false);
                });
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    function dayManager(index) {
        const findDay = data[index];
        setWeekInView(findDay);
        setDayIndex(index);
    }

    const handleStatus = (working_hour_id) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.working_hour_id === working_hour_id ? { ...item, closed: !item.closed } : item
            )
        );
    };
    useEffect(() => {
        setHourseLoader(true);

        dispatch(getBusinessHoursThunk({ business_id }))
            .then((response) => {
                if (response.payload) {
                    setData(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setHourseLoader(false);
            });
    }, [hoursUpdated]);
    return (
        <div className="md:px-10 px-5 my-3 ">
            <div className="b-h-container  !shadow-md !rounded-xl overflow-hidden py-2">
                <div className="b-h-1 ">
                    {data &&
                        data?.map((day, index) => (
                            <div
                                onClick={() => dayManager(index)}
                                key={generateId()}
                                className={`flex items-center justify-between hover:ring-2 hover:ring-inset hover:ring-third px-3 py-3 !shadow-lg cursor-pointer mx-5 mt-5 rounded-xl ${
                                    !day?.closed === false ? 'day-opacity' : 'true-day'
                                }`}
                            >
                                <div className="flex items-start justify-start flex-1 gap-10">
                                    <div className="flex items-center">
                                        <input
                                            checked={!day?.closed}
                                            className="react-switch-checkbox"
                                            id={`HandleToggle-${day?.working_hour_id} react-switch-new`}
                                            type="checkbox"
                                            onChange={() => handleStatus(index)}
                                        />

                                        <label
                                            style={{ background: !day?.closed && '#B695F8' }}
                                            className="react-switch-label"
                                            htmlFor={`react-switch-new`}
                                            onClick={() => handleStatus(day?.working_hour_id)}
                                        >
                                            <span className={`react-switch-button`} />
                                        </label>
                                    </div>
                                    <div className=" flex gap-5 buttons-font font-poppins items-center ">
                                        {' '}
                                        <span>{day?.day}</span>
                                        {!day?.closed === true ? (
                                            <span>
                                                {day?.start_time} - {day?.end_time}
                                            </span>
                                        ) : (
                                            'Closed'
                                        )}{' '}
                                    </div>
                                </div>

                                <div className=" text-right ">
                                    {' '}
                                    {!day?.closed === true ? (
                                        <span className="day-icon">&gt;</span>
                                    ) : (
                                        <span className="day-icon">&lt;</span>
                                    )}{' '}
                                </div>
                            </div>
                        ))}
                </div>
                <div className="b-h-2 p-3">
                    <div className="title">{weekInView?.day}</div>
                    <div className="buttons-font font-poppins my-3">Opening Hours</div>
                    {weekInView && (
                        <div>
                            {' '}
                            <div className="day-time flex gap-5 mt-1">
                                <div>
                                    <TimePicker
                                        className="focus:border-0 !border-secondarybg !rounded-[10px] !text-gray-900 min-h-[2.5rem] font-semibold w-full hover:border-secondarybg"
                                        needConfirm={false}
                                        changeOnScroll
                                        placeholder="Start Time"
                                        use12Hours
                                        format="h:mm A"
                                        onChange={(time) => {
                                            if (time?.$d) {
                                                handleInputChange({
                                                    target: {
                                                        name: 'from',
                                                        value: dayjs(time?.$d).format('HH:mm')
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <TimePicker
                                        className="focus:border-0 !border-secondarybg !rounded-[10px] !text-gray-900 min-h-[2.5rem] font-semibold w-full hover:border-secondarybg"
                                        needConfirm={false}
                                        changeOnScroll
                                        placeholder="End Time"
                                        use12Hours
                                        format="h:mm A"
                                        onChange={(time) => {
                                            if (time?.$d) {
                                                handleInputChange({
                                                    target: {
                                                        name: 'to',
                                                        value: dayjs(time?.$d).format('HH:mm')
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="day-btns buttons-font-lighter mt-5 flex gap-5">
                                <button onClick={() => setWeekInView(null)} className="dim-btn px-10 py-2 rounded-lg">
                                    Cancel
                                </button>
                                <Ripples during={2000} color="#333333">
                                    <button onClick={handleSubmit} className="ann-btn px-10 py-2 rounded-lg text-white">
                                        Save
                                    </button>
                                </Ripples>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BusinessHours;
