import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { blockDeviceThunk } from 'store/settings/team/team';
import warning from '../files/warning.png';

export const AttentionRequired = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const blockDevice = () => {
        dispatch(setLoader(true));
        dispatch(blockDeviceThunk({ deviceId: token }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <>
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    <div className="">
                        <div className="login mx-5 md:px-20">
                            <div className="login-body">
                                <div className="login-welcome mt-10 small-text font-poppins">
                                    <div className="flex flex-col items-center justify-center gap-5 py-10">
                                        <div className="flex gap-2 items-center justify-center">
                                            <div className="mx-auto">
                                                <img src={warning} alt="file" className=" bg-white cnf-icon-st" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <p className="act-des !text-4xl text-center desc text-black mt-0 break-words my-5 pt-5 leading-6">
                                                Immediate Action Required
                                            </p>
                                            <p className=" !text-lg text-black text-center desc  mt-0 break-words my-5 pt-5">
                                                As the login attempt wasn&#39;t recognised, we&rsquo;ve blocked access
                                                for that device for the next 30 minutes.
                                            </p>
                                            <p className=" !text-lg text-black text-center desc  mt-0 break-words my-5 pt-5">
                                                We advise you to change your password and secure your account.
                                            </p>
                                        </div>
                                        <div className="flex justify-center gap-2">
                                            <button
                                                onClick={blockDevice}
                                                className=" px-14 py-2 ann-btn text-white rounded-md"
                                            >
                                                Block this device
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
