import { twMerge } from 'tailwind-merge';

export const getBoundingClientRect = (element) => {
    const rect = element.getBoundingClientRect();

    const { width, height } = rect;

    const top = rect.top + window.scrollY;
    const left = rect.left + window.scrollX;

    return { top, left, width, height };
};
export const PDF_VIEWER_CONTAINER_SELECTOR = '.react-pdf__Document';
export const PDF_VIEWER_PAGE_SELECTOR = '.react-pdf__Page';

export function cn(...inputs) {
    return twMerge(inputs);
}
