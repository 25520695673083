import React, { useState } from 'react';
import QrCodesPage from '../../../components/workplace-content/qr-codes-pages/QrCodesPage';
import AddQrCode from '../../../components/workplace-content/qr-codes-pages/AddQrCode';
import { useDispatch } from 'react-redux';
import { SingleCode } from 'components/workplace-content/qr-codes-pages/SingleCode';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { handleCodeUpdate } from 'store/global/globalReducer';
import { UpdateCode } from 'components/workplace-content/qr-codes-pages/UpdateCode';
import { SidebarLayout } from 'layouts/SidebarLayout';
const QrCodes = () => {
    const dispatch = useDispatch();
    const [showAddQrCode, setShowAddQrCode] = useState(false);
    const [qrCodeUpdated, setQrCodeUpdated] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [showSingleCode, setShowSingleCode] = useState(false);

    const toggleUpdate = () => {
        setShowUpdate(!showUpdate);
    };
    const toggleAddQrCode = (isUpdate) => {
        if (isUpdate) {
            setShowAddQrCode(!showAddQrCode);
            return;
        }
        dispatch(handleCodeUpdate({}));

        setShowAddQrCode(!showAddQrCode);
    };

    const toggleQrCode = () => {
        setQrCodeUpdated(!qrCodeUpdated);
    };

    const toggleSingleCode = () => {
        setShowSingleCode(!showSingleCode);
    };
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showSingleCode && (
                    <SingleCode toggleSingleCode={toggleSingleCode} open={showSingleCode} setOpen={setShowSingleCode} />
                )}
                {showUpdate && <UpdateCode open={showUpdate} setOpen={setShowUpdate} toggleQrCode={toggleQrCode} />}
                {showAddQrCode && (
                    <AddQrCode
                        toggleAddQrCode={toggleAddQrCode}
                        toggleQrCode={toggleQrCode}
                        open={showAddQrCode}
                        setOpen={setShowAddQrCode}
                    />
                )}
                <RoleBasedGuard module={'Qr_codes'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    {' '}
                    <div>
                        <QrCodesPage
                            toggleSingleCode={toggleSingleCode}
                            toggleAddQrCode={toggleAddQrCode}
                            toggleQrCode={toggleQrCode}
                            qrCodeUpdated={qrCodeUpdated}
                            toggleUpdate={toggleUpdate}
                        />
                    </div>{' '}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};

export default QrCodes;
