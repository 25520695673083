import React, { useEffect, useMemo } from 'react';
import SearchAddSelectCombobox from 'components/atoms/SearchAddSelectCombobox/SearchAddSelectCombobox';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID } from 'utills/globalVars';
import { getAllJobTypesThunk } from 'store/JobManager';
import { AddJobType } from 'pages/settings/job-manager/job-manager-settings/AddJobType';

const JobsTypeFilter = ({ setFilters, filters }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const { data: jobTypes } = useSelector((state) => state.getAllJobTypes);

    useEffect(() => {
        if (!jobTypes) {
            fetchJobTypes();
        }
    }, []);

    const fetchJobTypes = () => {
        const payload = {
            business_id: business_id
        };
        dispatch(getAllJobTypesThunk(payload));
    };

    // Memoize selectedItems to avoid unnecessary re-renders
    const selectedItems = useMemo(() => {
        if (!filters?.job || !jobTypes) return [];
        return jobTypes?.filter((obj) => obj.label === filters?.job) || [];
    }, [filters?.job, jobTypes]);

    return (
        <div>
            <SearchAddSelectCombobox
                options={jobTypes || []}
                mainSx="!w-full !mx-0 !p-0"
                boxChildSx="!flex-nowrap overflow-auto"
                targetProperty="label"
                addStr="Job Type"
                onChange={(arr) => {
                    if (arr.length > 0) {
                        const obj = arr[0];
                        setFilters(obj);
                    } else {
                        setFilters('');
                    }
                }}
                isMultiple={false}
                selectedItems={selectedItems} // Pass memoized selectedItems
                labelInside="Filter by Job Type"
                placeholder="Filter by Job Type"
                childSliders={{ addItem: AddJobType }}
                extraChildProps={{
                    onComplete: () => {
                        fetchJobTypes();
                    }
                }}
            />
        </div>
    );
};

export default JobsTypeFilter;
