import { useState } from 'react';

const useBase64Converter = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const convertUrlToBase64 = async (fileUrl) => {
        setLoading(true);

        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();

            const base64Content = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });

            setLoading(false);
            return base64Content;
        } catch (error) {
            setError(error);
            setLoading(false);
            throw error;
        }
    };

    return { loading, error, convertUrlToBase64 };
};

export default useBase64Converter;
