/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TasksCompletedJumbo } from './TasksCompletedJumbo';
import { TasksCompletedOperations } from './TasksCompletedOperations';
import { TasksCompletedCards } from './TasksCompletedCards';
import { TasksList } from './tasks/TasksList';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID } from 'utills/globalVars';
import { getAllCompletedTasksThunk } from 'store/workspace/workspaceTasks';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { setSideLoader } from 'store/global/globalReducer';
import useRoleGuard from 'Hooks/useRoleGuard';
import dayjs from 'dayjs';
import Pagination from 'components/pagination';

export const TasksCompletedPage = ({ toggleTaskDetails }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();

    const { data: loginData } = useSelector((state) => state.login);
    const { hasPermissions } = useRoleGuard();

    const [filters, setFilters] = useState({
        search_term: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        business_client_id: '',
        task_type_id: '',
        task_status_id: '',
        reference_number: '',
        sort_by: '',
        proirity: '',
        pending_tsak: ''
    });

    const [itemsPerPage, setItemsPerPage] = useState(PAGINATION_PAGE_SIZE);
    const [totalPages, setTotalPages] = useState({
        all: 1,
        filtered: 1
    });
    const [tableView, _setTableView] = useState('all');
    const [activeTab, setActiveTab] = useState('all');

    const [hasMoreContent, _setHasMoreContent] = useState({
        all: true,
        filtered: false
    });

    const [allPage, setAllPage] = useState(1);
    const [filteredPage, setFilteredPage] = useState(1);

    const [allPageData, setAllPageData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    useEffect(() => {
        if (allPageData !== null)
            if (activeTab === 'all') {
                if (allPage === 1) {
                    reloadTaskPage();
                } else {
                    setAllPageData(null);
                    setAllPage(1);
                }
            } else {
                if (filteredPage === 1) {
                    updateFilters();
                } else {
                    setFilteredData(null);
                    setFilteredPage(1);
                }
            }
    }, [itemsPerPage]);

    useEffect(() => {
        getAllTasks('all');
    }, [allPage]);

    useEffect(() => {
        const hasValue = Object.values(filters).some((value) => value !== '');
        if (hasValue) {
            getAllTasks('filtered');
        } else {
            setActiveTab(tableView);
        }
    }, [filteredPage, filters]);

    const handlePageChange = () => {
        if (activeTab === 'all') {
            setAllPage(allPage + 1);
        } else if (activeTab === 'filtered') {
            setFilteredPage(filteredPage + 1);
        }
    };

    const getAllTasks = async (type, _hasReset = false) => {
        let page;
        let dataSetter;
        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setAllPageData;
                break;
            case 'filtered':
                page = filteredPage;
                dataSetter = setFilteredData;
                break;
            default:
                return;
        }

        const payload = {
            business_id,
            filter: {
                ...filters,
                is_completed: 'true',
                page: page,
                pageSize: Number(PAGINATION_PAGE_SIZE),
                employee_id: hasPermissions('Tasks', ['admin']) ? filters?.employee_id : loginData?.user_id
            }
        };
        try {
            dispatch(setSideLoader(true));
            const response = await dispatch(getAllCompletedTasksThunk(payload));
            if (response.payload) {
                dataSetter(response.payload.rows);
                setTotalPages({ ...totalPages, [type]: Math.ceil(response.payload?.count / itemsPerPage) });
                dispatch(setSideLoader(false));
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const updateFilters = (newFilters) => {
        let filters = newFilters;
        if (newFilters.start_date) {
            filters = {
                ...filters,
                start_date: dayjs(newFilters.start_date).format('DD-MM-YYYY')
            };
        }
        if (newFilters.end_date) {
            filters = {
                ...filters,
                end_date: dayjs(newFilters.end_date).format('DD-MM-YYYY')
            };
        }
        setFilters(filters);
        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');
    };

    const reloadTaskPage = () => {
        setAllPage(1);
        setActiveTab('all');
        getAllTasks('all', true);
        setAllPageData(null);
    };

    return (
        <div className="grid">
            <TasksCompletedJumbo path />
            <TasksCompletedCards />
            <TasksCompletedOperations updateFilters={updateFilters} filters={filters} />
            <TasksList
                tasks={activeTab === 'all' ? allPageData : filteredData}
                handlePageChange={handlePageChange}
                tableView={tableView}
                hasMoreContent={hasMoreContent?.[activeTab]}
                getAllTasks={getAllTasks}
                toggleTaskDetails={toggleTaskDetails}
                reloadTaskPage={reloadTaskPage}
                id={'taskCompletedTable'}
            />

            {Number(totalPages?.[activeTab]) > 0 && (
                <Pagination
                    page={activeTab === 'all' ? Number(allPage) : Number(filteredPage)}
                    pageSize={Number(itemsPerPage)}
                    totalPages={Number(totalPages?.[activeTab] || 1)}
                    onChangePage={(e) => {
                        if (activeTab === 'all') {
                            setAllPage(e);
                        } else {
                            setFilteredPage(e);
                        }
                    }}
                    onCountPerPageChange={(value) => {
                        setItemsPerPage(value);
                        if (activeTab === 'all') {
                            setAllPage(1);
                        } else {
                            setFilteredPage(1);
                        }
                    }}
                    scrollTo={'taskCompletedTable'}
                />
            )}
        </div>
    );
};
