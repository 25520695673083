import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { cn } from 'utills/tailwindUtil';
import TooltipChipWrap from '../tooltipChip/TooltipChipWrap';

const Badge = ({ text, sx, onCancel, leftIcon, atClick }) => {
    return (
        <span
            className={cn(
                '  flex items-center w-100  rounded-full w-[200px] break-all px-3 py-1.5 text-md font-medium gap-1 text-ellipsis ',
                sx ? sx : '',
                onCancel ? 'gap-1' : ''
            )}
            onClick={() => {
                if (atClick) {
                    atClick();
                }
            }}
        >
            {leftIcon && leftIcon}
            <div className="flex flex-1 items-center justify-between w-full text-ellipsis">
                <TooltipChipWrap title={text} variant="soft" placement="bottom">
                    <span className="!truncate text-sm overflow-hidden max-w-[calc(100% - 20%)]">
                        {' '}
                        {text.slice(-15)}{' '}
                    </span>
                </TooltipChipWrap>
                {onCancel && <XCircleIcon className="color-white !cursor-pointer w-5 h-5" onClick={onCancel} />}
            </div>
        </span>
    );
};

export default Badge;
