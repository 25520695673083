import Slideover from 'components/atoms/SlideOver';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, ERROR_TYPE_ERROR, USER_TYPE } from 'utills/globalVars';
import { BgTimes } from 'utills/svgs/BgTimes';
import pdfIcon from '../../../files/pdfIcon.svg';
import { DragFile } from 'components/atoms/inputs/DragFile';
import Badge from 'components/atoms/Badge';
import { updateDeductionThunk } from 'store/workspace/workspaceDeduction';
import { setSideLoader } from 'store/global/globalReducer';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';

export const EditDeduction = ({ open, setOpen, toggleDeduction }) => {
    const dispatch = useDispatch();
    const { deduction } = useSelector((state) => state.global);

    const business_id = localStorage.getItem(BUSINESS_ID);
    const { sideLoader } = useSelector((state) => state.global);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (deduction) {
            setTitle(deduction?.title);
        }
    }, [deduction]);

    const addDeduction = async () => {
        if (title === '') {
            toastHandler('Please fill in all required fields', ERROR_TYPE_ERROR);
            return;
        }

        const formData = new FormData();
        formData.append('business_id', business_id);
        formData.append('title', title.trim());
        formData.append('uploaded_by', USER_TYPE);

        const sanitizedFile = await createNewPDFWithoutMetadata(selectedFiles[0]);
        formData.append('deduction_file', sanitizedFile);

        formData.append('business_deduction_id', deduction?.business_deduction_id);
        dispatch(setSideLoader(true));
        dispatch(updateDeductionThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setSelectedFile(null);
                    setSelectedFiles([]);
                    setOpen(false);

                    toggleDeduction();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };
    const acceptFormate = ['application/pdf'];

    const handleClearFiles = (_index) => {};

    return (
        <Slideover title="Edit Deduction" open={open} setOpen={setOpen}>
            {' '}
            <div>
                <div>
                    <RoleBasedGuard module={'Deduction'} permissions={['admin']} sx="h-[50vh]">
                        {' '}
                        <div>
                            <div className=" mt-3 p-5">
                                <div className="add-ann-form">
                                    <div>
                                        <div>
                                            <label>Title</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                value={title}
                                                maxLength="50"
                                                placeholder="Title"
                                                className="px-3 add-d-input rounded-xl mt-1"
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="primary-fonts text-md mt-4">
                                    <label> Upload pdf</label>
                                </div>
                                <div className="mt-2">
                                    {' '}
                                    <DragFile
                                        selectedFiles={selectedFiles}
                                        atChange={(file) => {
                                            handleSelectedFiles(file);
                                        }}
                                        hideSelectedFiles
                                        accept={acceptFormate}
                                        isMultiple={true}
                                        dragMessage="Drag files here to upload, or browse for files"
                                        dropMessage="Drop the selected files in this box... "
                                        onlyPdf={true}
                                        message="Only PDF is allowed"
                                        handleClearFiles={handleClearFiles}
                                    />
                                </div>
                                <SelectedFiles
                                    files={selectedFiles}
                                    updateSelectedFiles={(files) => setSelectedFiles(files || [])}
                                />
                            </div>
                        </div>
                        <div className="mx-5 grid grid-cols-3 gap-3">
                            {selectedFile && (
                                <div className=" add-ann-pdf mt-2 relative  ">
                                    <div className="absolute right-1 top-1">
                                        <div onClick={() => setSelectedFile('')} className="cursor-pointer">
                                            {' '}
                                            <BgTimes />{' '}
                                        </div>
                                    </div>
                                    <label className="upload-app-label">
                                        <div className="add-app-camera flex justify-center">
                                            <img src={pdfIcon} alt="alt" style={{ height: 30, width: 30 }} />
                                        </div>
                                        <div className="add-app-camera-text mt-2">{selectedFile?.name.slice(0, 8)}</div>
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className="mt-5 mx-5">
                            {deduction?.file_name && (
                                <div>
                                    <div className="buttons-font font-poppins"> Deduction file</div>{' '}
                                    <Badge
                                        leftIcon={
                                            <img
                                                src={pdfIcon}
                                                alt="alt"
                                                className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md "
                                                onClick={() => {}}
                                            />
                                        }
                                        text={'deduction file'}
                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                    />
                                </div>
                            )}
                        </div>
                        <div className="add-service-bottom">
                            <button
                                onClick={addDeduction}
                                className="ann-btn px-16 cursor-pointer text-white rounded-xl "
                            >
                                {sideLoader ? <SmallLoaderWhite /> : ' Update'}
                            </button>
                        </div>
                    </RoleBasedGuard>
                </div>
            </div>
        </Slideover>
    );
};
