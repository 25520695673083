import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeHttpRequest } from '../../axios';
import { endpoint, methods } from 'apiEndpoints';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';

const initialState = {
    data: null,
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: PAGINATION_PAGE_SIZE,

    isLoading: true,
    error: null,
    activeFilter: false,
    filters: {
        search_term: '',
        order_by: '',
        status: ''
    }
};

const getAllInvitesThunk = createAsyncThunk('invites/request', async (payload) => {
    try {
        const res = await makeHttpRequest(methods.POST, endpoint.getInvitedClients, payload);
        return res;
    } catch (error) {
        return error;
    }
});

export const InviteSlice = createSlice({
    name: 'invites',
    initialState,
    reducers: {
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setCountPerPage: (state, action) => {
            state.currentPage = 1;
            state.itemsPerPage = action.payload;
        },
        updateData: (state, action) => {
            state.data = action.payload;
        },
        setActiveFilter: (state, action) => {
            state.activeFilter = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllInvitesThunk.pending, (state) => {
                state.data = [];
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllInvitesThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload?.data;
                state.totalPages = Math.ceil(action.payload?.count / state.itemsPerPage);
            })
            .addCase(getAllInvitesThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.data = [];
            });
    }
});

export const { setCurrentPage, setCountPerPage, updateData, setActiveFilter, setFilters } = InviteSlice.actions;

export default InviteSlice;
export { getAllInvitesThunk };
