import React from 'react';
import { MobileIconStarIcon } from 'utills/svgs/MobileIconStarIcon';

export const AuthenticationSuccess = ({ handleCondition }) => {
    return (
        <div className="flex min-h-[60vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] bg-white shadow rounded-xl sm:rounded-2xl  py-4">
                {' '}
                <div className="">
                    <div className="email-success-inner">
                        <div className="envelope">
                            <div className="envelope-wrapper">
                                <MobileIconStarIcon />
                            </div>
                        </div>

                        <div className="congrats mt-6">
                            <h1 className="text-center">Congratulation!</h1>

                            <p className="text-center mt-3 text-blueish">
                                Your Google 2FA has been enabled successfully.
                            </p>
                        </div>

                        <div className=" mt-7 mb-12">
                            <center>
                                <button onClick={() => handleCondition(6)} className="px-20 py-2 ann-btn rounded-lg">
                                    Ok
                                </button>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
