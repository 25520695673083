import React from 'react';

export const NewPlusIcon = () => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="36" height="36" rx="18" fill="url(#paint0_linear_11918_1936)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9998 9.60156C17.2266 9.60156 16.5998 10.2284 16.5998 11.0016V16.6035H10.9986C10.2254 16.6035 9.59863 17.2303 9.59863 18.0035C9.59863 18.7767 10.2254 19.4035 10.9986 19.4035H16.5998V25.0016C16.5998 25.7748 17.2266 26.4016 17.9998 26.4016C18.773 26.4016 19.3998 25.7748 19.3998 25.0016V19.4035H24.9986C25.7718 19.4035 26.3986 18.7767 26.3986 18.0035C26.3986 17.2303 25.7718 16.6035 24.9986 16.6035H19.3998V11.0016C19.3998 10.2284 18.773 9.60156 17.9998 9.60156Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_11918_1936"
                    x1="0.5567"
                    y1="2.87474e-05"
                    x2="35.8231"
                    y2="1.42863"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
