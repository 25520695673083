import { ChevronDownIcon } from '@heroicons/react/20/solid';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import Input from 'components/atoms/Input';
import MailerInput from 'components/atoms/Mailer/MailerInput';
import MailerInputBody2 from 'components/atoms/Mailer/MailerInputBody2';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { getBusinessprofileThunk } from 'store/auth/slices';
import { searchClientsThunk } from 'store/client';
import { setReplyObject } from 'store/custom-reducers/RelayRoomLeftBarReducer';
import { setLoader } from 'store/global/globalReducer';
import { replyComposeEmailThunk } from 'store/JobManager';
import { getDefaultSignatureThunk } from 'store/settings/JobPortal';
import { jsonToFormData } from 'utills/FormValidation';
import { BUSINESS_ID, getProfileName, TOAST_TYPE_ERROR } from 'utills/globalVars';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import { EditIcon2 } from 'utills/svgs/EditIcon2';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { ForwardIcon } from 'utills/svgs/ForwardIcon';
import { ReplyAllIcon } from 'utills/svgs/ReplyAllIcon';
import { ReplyIcon } from 'utills/svgs/ReplyIcon';
import { cn } from 'utills/tailwindUtil';
import { generateId } from 'utills/uid';

const RelayRoomThreadHandler = ({ handleReload }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: getBusinessprofile } = useSelector((state) => state.getBusinessprofile);
    const { replyObject } = useSelector((state) => state?.getRelayRoomList);
    const { data: getDefaultSignatureList } = useSelector((state) => state.getDefaultSignature);

    const [threadType, setThreadType] = useState('reply');
    const [data, setData] = useState({});
    const [expand, setExpand] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [selectedFilesArray, setSelectedFilesArray] = useState([]);
    const [dispabledSubject, setDispabledSubject] = useState(true);
    const [jobRefno, setJobRefno] = useState(null);
    const [toggleSignature, setToggleSignature] = useState(false);

    useEffect(() => {
        if (replyObject) {
            const splitSubject = replyObject?.title?.split(']:');
            setThreadType(replyObject?.action);
            setJobRefno(`${splitSubject[0]}]:`);
            setData({
                ...replyObject,
                subject: splitSubject.splice(1).join(' '),
                from_email: getBusinessprofile?.business_email,
                to: getProfileObject(
                    [replyObject?.business_email ? replyObject?.business_email : replyObject?.email],
                    [replyObject?.from_detail]
                ),
                ...(replyObject?.action === 'reply_all'
                    ? {
                          cc: getProfileObject(replyObject?.cc?.filter((obj) => obj !== '')),
                          bcc: getProfileObject(replyObject?.bcc?.filter((obj) => obj !== ''))
                      }
                    : {
                          cc: [],
                          bcc: []
                      })
            });

            setToggleSignature(true);
        }
    }, [getBusinessprofile, replyObject]);

    useEffect(() => {
        if (!getBusinessprofile) {
            dispatch(getBusinessprofileThunk());
        }
    }, [getBusinessprofile]);

    useEffect(() => {
        if (threadType === 'editSubject') {
            setDispabledSubject(false);
        } else {
            setDispabledSubject(true);
        }
    }, [threadType]);

    // ------------------------------------------------------------ Get Default Signatures

    useEffect(() => {
        if (toggleSignature) {
            writeSignatureIfExist();
        }
    }, [getDefaultSignatureList, toggleSignature]);
    useEffect(() => {
        if (!getDefaultSignatureList) {
            dispatch(getDefaultSignatureThunk({ business_id }));
        }
    }, []);

    const writeSignatureIfExist = () => {
        const relayRoomSignature = getDefaultSignatureList
            ? getDefaultSignatureList.find((obj) => obj?.email_type === 'relay_room')
            : null;
        const signatureToAdd = relayRoomSignature ? relayRoomSignature?.reply_email_id : null;
        if (signatureToAdd) {
            setDataFun(
                'body',
                `${data?.body || ''} <br /> <br /> <br />
                 ${relayRoomSignature?.is_positioning === 'null' ? '--' : ''}
                <div style:"margin-top:10px" >
                ${signatureToAdd?.signature}  <br /> <br /> <br /> </div>`
            );
            setForceUpdate(true);
            setToggleSignature(false);
        }
    };

    // ------------------------------------------------------------ Get Default Signatures

    const getThreadIconByType = (type, css) => {
        switch (type) {
            case 'forward':
                return <ForwardIcon className={cn('w-5 h-5 fill-third', css)} />;
            case 'editSubject':
                return <EditIcon2 className={cn('w-5 h-5 fill-third', css)} />;
            case 'reply_all':
                return <ReplyAllIcon className={cn('w-5 h-5 fill-third', css)} />;
            case 'reply':
            default:
                return <ReplyIcon className={cn('w-5 h-5 fill-third', css)} />;
        }
    };
    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const setDataFun = (target, value) => {
        setData({ ...data, [target]: value });
    };

    const getProfileObject = (inputArray = [], arrayToCheck = null) => {
        return inputArray?.map((itemObj) => {
            if (typeof itemObj === 'object') {
                return {
                    ...itemObj,
                    business_client_id: itemObj?.business_client_id,
                    display_name: getProfileName(itemObj),
                    email: itemObj?.email
                };
            }

            const foundObject = arrayToCheck?.find((obj) => obj?.business_email === itemObj || obj?.email === itemObj);

            return foundObject
                ? {
                      ...foundObject,
                      business_client_id: foundObject?.business_client_id
                          ? foundObject?.business_client_id
                          : generateId(),
                      display_name: foundObject?.name || getProfileName(foundObject),
                      email: foundObject.email,
                      image: foundObject?.logo || foundObject?.image
                  }
                : {
                      business_client_id: generateId(),
                      display_name: itemObj,
                      email: itemObj
                  };
        });
    };

    const changeThreadType = (type) => {
        if (data?.subject !== '') {
            setThreadType(type);
            dispatch(setReplyObject({ ...replyObject, action: type }));
        } else {
            toastHandler('Please Type the Subject First', TOAST_TYPE_ERROR);
        }
    };

    const handleSubmitData = () => {
        if (!data?.to || data?.to?.length === 0) {
            return toastHandler('"To" Email is required!', TOAST_TYPE_ERROR);
        }
        if (!data?.from_email || data?.from_email === '') {
            return toastHandler('"From" Email is required!', TOAST_TYPE_ERROR);
        }
        if (!data?.subject || data?.subject === '') {
            return toastHandler('Subject is required!', TOAST_TYPE_ERROR);
        }

        const jsonData = {
            job_email_id: data?.job_email_id,
            from: data?.from_email,
            to: data?.to?.map((item) => item?.email)?.join(',') || '',
            cc: data?.cc?.map((item) => item?.email)?.join(',') || '',
            bcc: data?.bcc?.map((item) => item?.email)?.join(',') || '',
            subject: `${jobRefno}${data?.subject || ''}`,
            body: data?.body || '',
            job_id: id,
            business_id: business_id,
            files: selectedFilesArray?.filter((obj) => !obj?.url) || []
        };
        if (data?.job_email_responces_id) {
            jsonData.job_email_responces_id = data?.job_email_responces_id;
        }

        const formData = jsonToFormData(jsonData);
        dispatch(setLoader(true));
        dispatch(replyComposeEmailThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setData(null);
                    handleReload();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleRemoveThread = () => {
        dispatch(setReplyObject(null));
    };

    const validateDataFromOtherInputs = (obj) => {
        if (obj) {
            const arrayToValidate = [...(data?.to || []), ...(data?.cc || []), ...(data?.bcc || [])];
            const IsDuplicateFound = arrayToValidate?.find((item) => item?.email === obj?.email);
            if (IsDuplicateFound) {
                return toastHandler('Duplicate Email Found in To / Cc / Bcc', TOAST_TYPE_ERROR);
            }
            return true;
        }
    };
    return (
        <div className="border rounded-lg my-3">
            <div className="flex w-full rounded-lg items-center py-3 px-5 bg-[#F8F4FE]">
                <div className="flex items-center flex-1">
                    <div className="">
                        <DropdownMenu
                            className="!left-0"
                            icon={
                                <div className="flex items-center gap-2 mt-1">
                                    {getThreadIconByType(threadType)}
                                    <ChevronDownIcon className="w-5 h-5" />
                                </div>
                            }
                        >
                            <DropdownMenuItem onClick={() => changeThreadType('reply')}>
                                <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                    {getThreadIconByType('reply', 'fill-gray-800 group-hover:fill-white')}
                                    Reply
                                </div>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => changeThreadType('forward')}>
                                <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                    {getThreadIconByType('forward', 'fill-gray-800 group-hover:fill-white')}
                                    Forward
                                </div>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => changeThreadType('editSubject')}>
                                <div className="flex items-center gap-2 cursor-pointer group hover:text-white">
                                    {getThreadIconByType('editSubject', 'fill-gray-800 group-hover:fill-white')}
                                    Edit Subject
                                </div>
                            </DropdownMenuItem>
                        </DropdownMenu>
                    </div>
                    <div className="flex-1">
                        <MailerInput
                            defaultSelected={data && data?.to ? getProfileObject(data?.to) : []}
                            defaultDataLoadType="custom"
                            handleDataWithCustomValidators={validateDataFromOtherInputs}
                            fetchDataFunction={(payload) =>
                                fetchReferencesListFun({
                                    ...payload,
                                    type: ''
                                })
                            }
                            inlineTitle="To:"
                            showNameOnly
                            onChange={(res) => {
                                setDataFun('to', res);
                            }}
                            sx="!border-transparent !p-0 !m-0 ring-0 focus:ring-0 shadow-none"
                            inputsx="!h-[1.5rem]"
                            inlineTitleSx="!py-1.5"
                            targetProperty="business_client_id"
                            placeholder="Type to Search"
                        />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <PrimaryParagraph>Cc</PrimaryParagraph>
                    <PrimaryParagraph>Bcc</PrimaryParagraph>
                    {expand ? (
                        <span className="cursor-pointer" onClick={() => setExpand(false)}>
                            <CollapsIcon className={'w-5 h-5'} />
                        </span>
                    ) : (
                        <span className="cursor-pointer" onClick={() => setExpand(true)}>
                            <ExpandIcon className={'w-5 h-5'} />
                        </span>
                    )}
                </div>
            </div>
            {expand && (
                <div className="pt-3 px-5 flex flex-col gap-1 w-full">
                    <div className="flex-1">
                        <div className="flex items-center gap-0">
                            <PrimaryLabel sx={'flex pl-2'}> Subject: </PrimaryLabel>
                            <div className="flex items-center w-full font-poppins text-[0.9rem] font-[400] sm:leading-6">
                                <span className="flex select-none items-center pl-3 text-md font-medium text-gray-800">
                                    {jobRefno}
                                </span>
                                <Input
                                    type="text"
                                    onChange={(e) => setDataFun('subject', e.target.value)}
                                    maxLength={120}
                                    disabled={dispabledSubject}
                                    value={data?.subject || ''}
                                    placeholder="Type a subject for your email"
                                    useWithoutForm={true}
                                    wrapsx="m-0 !flex-1 !w-full"
                                    sx="!border-transparent !py-1.5 !h-auto !shadow-none !w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex-1">
                        <MailerInput
                            defaultSelected={data && data?.cc ? getProfileObject(data?.cc) : []}
                            defaultDataLoadType="custom"
                            handleDataWithCustomValidators={validateDataFromOtherInputs}
                            fetchDataFunction={(payload) =>
                                fetchReferencesListFun({
                                    ...payload,
                                    type: ''
                                })
                            }
                            inlineTitle="Cc:"
                            showNameOnly
                            onChange={(res) => {
                                setDataFun('cc', res);
                            }}
                            sx="!border-transparent !p-0 !m-0 ring-0 focus:ring-0 shadow-none"
                            inputsx="!h-[1.5rem]"
                            inlineTitleSx="!py-1.5 mr-0.5"
                            targetProperty="business_client_id"
                            placeholder="Type to Search"
                        />
                    </div>
                    <div className="flex-1">
                        <MailerInput
                            defaultSelected={data && data?.bcc ? getProfileObject(data?.bcc) : []}
                            defaultDataLoadType="custom"
                            handleDataWithCustomValidators={validateDataFromOtherInputs}
                            fetchDataFunction={(payload) =>
                                fetchReferencesListFun({
                                    ...payload,
                                    type: ''
                                })
                            }
                            inlineTitle="Bcc:"
                            showNameOnly
                            onChange={(res) => {
                                setDataFun('bcc', res);
                            }}
                            sx="!border-transparent !p-0 !m-0 ring-0 focus:ring-0 shadow-none"
                            inputsx="!h-[1.5rem]"
                            inlineTitleSx="!py-1.5"
                            targetProperty="business_client_id"
                            placeholder="Type to Search"
                        />
                    </div>
                </div>
            )}
            <div className="py-3 w-full">
                <MailerInputBody2
                    sx={{ wraper: '', innerWrap: '!border-none', bottomSx: 'px-5' }}
                    hideTemplate
                    editorContent={data?.body}
                    setEditorContent={(e) => setDataFun('body', e)}
                    forceUpdate={forceUpdate}
                    setForceUpdate={setForceUpdate}
                    selectedFiles={selectedFilesArray}
                    setSelectedFilesFun={(files) => setSelectedFilesArray(files)}
                    handleSubmitData={handleSubmitData}
                    inputId="mailer-select-files"
                    onRemove={handleRemoveThread}
                />
            </div>
        </div>
    );
};

export default RelayRoomThreadHandler;
