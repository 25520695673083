import React from 'react';

export const GmailIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_23962_3167)">
                <path
                    d="M5.45455 70.0143H18.1818V39.1051L0 25.4688V64.5596C0 67.5733 2.44092 70.0143 5.45455 70.0143Z"
                    fill="#4285F4"
                />
                <path
                    d="M61.8181 70.0143H74.5454C77.5591 70.0143 79.9999 67.5733 79.9999 64.5596V25.4688L61.8181 39.1051V70.0143Z"
                    fill="#34A853"
                />
                <path
                    d="M61.8181 15.4693V39.1057L79.9999 25.4692V18.1965C79.9999 11.4556 72.3044 7.6056 66.9091 11.6511L61.8181 15.4693Z"
                    fill="#FBBC04"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.1819 39.1051V15.4688L40.0001 31.8324L61.8182 15.4688V39.1051L40.0001 55.4688L18.1819 39.1051Z"
                    fill="#EA4335"
                />
                <path
                    d="M0 18.1965V25.4692L18.1818 39.1057V15.4693L13.0909 11.6511C7.69545 7.6056 0 11.4556 0 18.1965Z"
                    fill="#C5221F"
                />
            </g>
            <defs>
                <clipPath id="clip0_23962_3167">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
