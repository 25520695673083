import { Button } from 'components/atoms/buttons/Button';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { AnimatePresence } from 'framer-motion';
import { cn } from 'pages/documents/hooks/helper';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import SkeletonTable from 'components/atoms/SkeletonTable';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoDataFound } from 'components/common/NoDataFound';
import PendingEye from 'utills/svgs/PendingEye';
import CirculerCheck from 'utills/svgs/CirculerCheck';
import { Chip } from 'components/atoms/buttons/Chip';
import { mergeClasses } from 'utills/tailwindUtil';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { ActivityIcon } from 'utills/svgs/ActivityIcon';
import { BgClear } from 'utills/svgs/BgClear';
import { BellIcon2 } from 'utills/svgs/BellIcon2';
import { BUSINESS_ID, ETHICAL_CLEARANCE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import AddNewClearance from './AddNewClearance';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClearanceDetails } from './ClearanceDetails';
import {
    cancelClearanceRequestThunk,
    getClearanceDetailThunk,
    getClearanceReqListThunk,
    regenrateCleranceLinkThunk
} from 'store/JobManager';
import { setLoader } from 'store/global/globalReducer';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import Pulse from 'components/atoms/Pulse';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';

const EthicalClearance = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedIds, _setSelectedIds] = useState([]);
    const [activity, setActivity] = useState(false);
    const [activityData, setActivityData] = useState([]);
    const { hasPermissions } = useRoleGuard();
    const { data: job } = useSelector((state) => state.getSingleJob);
    const { data: getJobActions } = useSelector((state) => state.getJobActions);
    const getActivityCount =
        getJobActions
            ?.filter((obj) => obj?.type === ETHICAL_CLEARANCE)
            .map((obj) => {
                const { meta, ...restOfData } = obj;
                const mainObj = meta.ids.map((id) => {
                    return {
                        ...restOfData,
                        id: id,
                        folder_id: meta?.folder_id,
                        ethical_clearance_id: meta?.ethical_clearance_id
                    };
                });
                return mainObj;
            })
            ?.flat() || [];
    const disabled = job?.status?.label === JOB_CLOSED;

    // --------------------------- Table configs --------------------

    // ----------------------------------------------------------- Pagination Start

    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [page, setPage] = useState(1);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        getData();
    }, [page]);

    const getData = async (hasReset = false) => {
        const payload = {
            job_id: id,
            business_id,
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE)
        };

        setHasMoreContent(true);

        try {
            const response = await dispatch(getClearanceReqListThunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? pageData || [] : []), ...response.payload.rows];
                setPageData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const dynamicButtons = [
        {
            icon: <PlusCircleIcon className={'w-7'} />,
            title: 'Request Clearance',
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setNewRequest(true);
            }
        }
    ];

    const [actions, setActions] = useState(dynamicButtons || []);

    useEffect(() => {
        if (!selectedIds?.length > 0) {
            setActions([dynamicButtons?.reverse()[0]]);
        } else {
            setActions(dynamicButtons);
        }
    }, [selectedIds]);

    const statusList = [
        {
            icon: PendingEye,
            text: 'Pending',
            className: 'bg-[#F08201]/10 text-[#F08201]'
        },
        {
            icon: '',
            text: 'Cancelled',
            className: 'bg-[#F14747]/10 text-[#F14747]'
        },
        {
            icon: CirculerCheck,
            text: 'Completed',
            className: 'bg-[#008C51]/10 text-[#008C51]'
        }
    ];

    const getStatus = (status) => {
        let findStatusObj = statusList?.find((obj) => obj?.text?.toLowerCase() === status?.toLowerCase());
        return (
            <Chip
                title={findStatusObj ? findStatusObj.text : status || 'N/A'}
                classes={mergeClasses('flex w-fit items-center gap-2', findStatusObj?.className)}
                iconSet={findStatusObj ? { rightIcon: findStatusObj?.icon } : null}
            />
        );
    };

    const [newRequest, setNewRequest] = useState(false);

    useEffect(() => {
        if (!activity) {
            setActivityData([]);
        }
    }, [activity]);

    const openActivity = (ethical_clearance_id) => {
        if (ethical_clearance_id) {
            setActivityData({});
            setActivity(true);
            const jsonPayload = {
                urlParams: ethical_clearance_id
            };
            dispatch(setLoader(true));
            dispatch(getClearanceDetailThunk(jsonPayload))
                .then((res) => {
                    if (res.payload) {
                        setActivityData(res.payload);
                        setActivity(true);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleCancleClearance = (ethical_clearance_id) => {
        const payload = {
            job_id: id,
            business_id,
            ethical_clearance_id
        };
        dispatch(setLoader(true));
        dispatch(cancelClearanceRequestThunk(payload))
            .then((res) => {
                if (res.payload) {
                    if (page === 1) {
                        getData(true);
                    } else {
                        setPage(1);
                    }
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleRegenrateClearanceLink = (ethical_clearance_id) => {
        const payload = {
            job_id: id,
            business_id,
            ethical_clearance_id
        };
        dispatch(setLoader(true));
        dispatch(regenrateCleranceLinkThunk(payload))
            .then((res) => {
                if (res.payload) {
                    if (page === 1) {
                        getData(true);
                    } else {
                        setPage(1);
                    }
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div>
            <CollapsDivComponent
                title={
                    <span className="relative">
                        Ethical Clearance Requests
                        {getActivityCount?.length > 0 && (
                            <div className="absolute -right-2 -top-2">
                                <Pulse sx={'h-3 w-3'} tooltip={getActivityCount?.length} />
                            </div>
                        )}
                    </span>
                }
                h_one="max-h-[70px]"
                h_two="max-h-[450px]"
                classes="px-5 py-3"
                defaultClose
                extra={
                    <>
                        <div className="flex items-center justify-end gap-5">
                            <AnimatePresence>
                                {actions?.map((btnObj, index) => (
                                    <motion.span
                                        key={btnObj.title}
                                        initial={{ x: '100px', opacity: 0 }}
                                        animate={{
                                            x: 0,
                                            opacity: 1
                                        }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            animate: { delay: `0.${index + 1}`, type: 'tween', duration: 0.2 },
                                            exit: { type: 'tween', duration: 0.3 }
                                        }}
                                    >
                                        <Button
                                            classes={'bg-gray-200 !rounded-full p-2'}
                                            tooltip={btnObj.title}
                                            iconSet={{ leftIconHTML: btnObj.icon }}
                                            atClick={() => btnObj?.onClick()}
                                            tooltipDirection={'none'}
                                            disabled={disabled}
                                        />
                                    </motion.span>
                                ))}
                            </AnimatePresence>
                        </div>
                    </>
                }
            >
                {newRequest && (
                    <AddNewClearance
                        open={newRequest}
                        setOpen={() => setNewRequest(!newRequest)}
                        clients={job?.clients ?? []}
                        handleUpdateTable={() => {
                            if (page === 1) {
                                getData(true);
                            } else {
                                setPage(1);
                            }
                        }}
                    />
                )}

                {activity && (
                    <ClearanceDetails
                        open={activity}
                        setOpen={setActivity}
                        activityData={activityData}
                        updateActivity={openActivity}
                        onComplete={() => getData(true)}
                        getActivityCount={getActivityCount}
                    />
                )}

                <div className="my-5 max-h-[640px] overflow-auto">
                    {pageData?.length === 0 ? (
                        <NoDataFound height="200px" message="No Record Found!" />
                    ) : (
                        <InfiniteScroll
                            height={'400px'}
                            next={handlePageChange}
                            dataLength={pageData.length}
                            hasMore={hasMoreContent}
                            loader={<SkeletonTable columns={6} />}
                        >
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 bg-white whitespace-nowrap z-[4] !border-b !border-gray-300   buttons-font primary-fonts !w-[200px] text-left'
                                            }
                                        >
                                            Ethical Request ID
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 bg-white z-[4] whitespace-nowrap !border-b !border-gray-300   buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                            }
                                        >
                                            Date & Time
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 bg-white z-[4] whitespace-nowrap !border-b !border-gray-300   buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Requested From
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 bg-white z-[4] whitespace-nowrap !border-b !border-gray-300   buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                            }
                                        >
                                            Sent To
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 bg-white z-[4] whitespace-nowrap !border-b !border-gray-300   buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                            }
                                        >
                                            Status
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0 bg-white z-[4] whitespace-nowrap !border-b !border-gray-300   tableHeadings primary-fonts !w-[200px] pl-[10px] text-center'
                                            }
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-transparent gap-0">
                                    {pageData &&
                                        pageData?.length > 0 &&
                                        pageData?.map((dataObj, index) => {
                                            return (
                                                <>
                                                    <tr
                                                        key={'ethical_clearance_table' + index}
                                                        className="border-y relative  divide-gray-300"
                                                    >
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap !text-[16px] py-2',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {' '}
                                                            {(() => {
                                                                const foundObj = getActivityCount.find(
                                                                    (item) =>
                                                                        item.ethical_clearance_id ===
                                                                        dataObj?.ethical_clearance_id
                                                                );

                                                                return (
                                                                    <div className="relative cursor-pointer">
                                                                        {dataObj?.ethical_clearance_id?.slice(-12) ||
                                                                            'N/A'}
                                                                        {foundObj && (
                                                                            <div className="absolute right-3 -top-3">
                                                                                <Pulse
                                                                                    sx={'h-3 w-3'}
                                                                                    tooltip={foundObj?.description}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })()}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {dataObj?.created_at ? (
                                                                <FormateDateTime dateTime={dataObj?.created_at} />
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            <DefaultProfileViewer
                                                                image={dataObj?.created_by?.image}
                                                                width="45px"
                                                                height="45px"
                                                                name={
                                                                    dataObj?.created_by?.first_name +
                                                                    ' ' +
                                                                    dataObj?.created_by?.last_name
                                                                }
                                                                tooltip
                                                            />
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {dataObj?.sent_to_name || '--'}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : '',
                                                                dataObj?.status === 'Completed'
                                                                    ? 'text-green-500'
                                                                    : 'text-red-500'
                                                            )}
                                                        >
                                                            {getStatus(dataObj?.status)}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center',
                                                                index !== pageData?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            <div className="flex justify-center items-center gap-2">
                                                                <Button
                                                                    disabled={disabled}
                                                                    atClick={() => {
                                                                        if (
                                                                            !hasPermissions('Job', ['write', 'admin'])
                                                                        ) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        handleCancleClearance(
                                                                            dataObj?.ethical_clearance_id
                                                                        );
                                                                    }}
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <BgClear className={'w-6 text-[#B695F8]'} />
                                                                        )
                                                                    }}
                                                                    classes={`${
                                                                        ['Cancelled', 'completed'].includes(
                                                                            dataObj?.status
                                                                        )
                                                                            ? 'invisible'
                                                                            : ''
                                                                    }  w-[36px] h-[36px] flex justify-center items-center bg-[#B695F8]/10 hover:!shadow-md block !rounded-full`}
                                                                />

                                                                <Button
                                                                    atClick={() => {
                                                                        if (
                                                                            !hasPermissions('Job', [
                                                                                'write',
                                                                                'read',
                                                                                'admin'
                                                                            ])
                                                                        ) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        openActivity(dataObj?.ethical_clearance_id);
                                                                    }}
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <ActivityIcon
                                                                                className={'text-[#0A1E46]'}
                                                                            />
                                                                        )
                                                                    }}
                                                                    classes={
                                                                        'hover:bg-transparent w-[36px] h-[36px] block !rounded-full'
                                                                    }
                                                                />

                                                                <Button
                                                                    disabled={disabled}
                                                                    atClick={() => {
                                                                        if (
                                                                            !hasPermissions('Job', ['write', 'admin'])
                                                                        ) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        handleRegenrateClearanceLink(
                                                                            dataObj?.ethical_clearance_id
                                                                        );
                                                                    }}
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <BellIcon2 className={'text-[#0A1E46]'} />
                                                                        )
                                                                    }}
                                                                    classes={
                                                                        'hover:bg-transparent w-[36px] h-[36px] block !rounded-full'
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    )}
                </div>
            </CollapsDivComponent>
        </div>
    );
};

export default EthicalClearance;
