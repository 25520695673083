import React, { useState, useEffect } from 'react';

export const Toast = ({ message, type, onClose, key }) => {
    const [progress, setProgress] = useState(100);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 0) {
                    clearInterval(interval);
                    setIsClosing(true);
                    setTimeout(() => onClose(key), 10000);
                    return 0;
                }
                return oldProgress - 1;
            });
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [onClose, key]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => onClose(key), 10000);
    };

    return (
        <div
            className={`relative w-80 p-4 rounded-2xl shadow-lg text-white transition-opacity duration-300 ${
                isClosing ? 'opacity-0' : 'opacity-100'
            }`}
            style={{ backgroundColor: type === 'success' ? '#193265' : 'rgba(182, 149, 248, 0.7)' }}
            key={key}
        >
            <div className="flex items-center justify-between small-text gap-5 font-poppins">
                <span>{message}</span>
                <button onClick={handleClose} className="text-white">
                    ✕
                </button>
            </div>
            <div className="flex justify-center items-center">
                <div
                    className="relative !w-[95%] ml-[10px] w-full1 px-3 h-[0.30rem] mt-2 bg-gray-200 rounded-tr-md rounded-tl-md overflow-hidden"
                    style={{ position: 'absolute', bottom: 0, left: 0 }}
                >
                    <div
                        className="absolute top-0 left-0 h-full transition-width duration-50 ease-out"
                        style={{
                            width: `${progress}%`,
                            backgroundColor: type === 'success' ? '#b695f8' : 'red'
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Toast;
