import React, { useEffect, useState } from 'react';
import { ElementVisible } from './element-visible';
import { SignatureField } from './signature-field';
import { NameField } from './name-field';
import { DateField } from './date-field';
import { EmailField } from './email-field';
import { FRIENDLY_FIELD_TYPE } from './types';
import { PDF_VIEWER_PAGE_SELECTOR } from './helper';

export const BoxDisplay = ({ newData, userInfo }) => {
    const [fields, setFields] = useState([]);

    useEffect(() => {
        setFields(newData?.fields || []);
    }, [newData]);

    return (
        <ElementVisible target={PDF_VIEWER_PAGE_SELECTOR}>
            {fields?.map((field, index) => {
                switch (field.field_type) {
                    case FRIENDLY_FIELD_TYPE.SIGNATURE:
                        return (
                            <SignatureField
                                key={field.document_field_id}
                                field={field}
                                recipient={userInfo}
                                index={index}
                                newData={newData}
                            />
                        );
                    case FRIENDLY_FIELD_TYPE.NAME:
                        return <NameField key={field.document_field_id} field={field} recipient={userInfo} />;
                    case FRIENDLY_FIELD_TYPE.DATE:
                        return <DateField key={field.id} field={field} recipient={userInfo} />;
                    case FRIENDLY_FIELD_TYPE.EMAIL:
                        return <EmailField key={field.document_field_id} field={field} recipient={userInfo} />;
                    default:
                        return null;
                }
            })}
        </ElementVisible>
    );
};
