import React from 'react';

const SnoozeIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M15.0007 3.75H5.00065C3.39232 3.75 2.08398 5.05833 2.08398 6.66667V12.5C2.08398 14.1083 3.39232 15.4167 5.00065 15.4167H10.0007C10.234 15.4167 10.4173 15.2333 10.4173 15C10.4173 14.7667 10.234 14.5833 10.0007 14.5833H5.00065C3.85065 14.5833 2.91732 13.65 2.91732 12.5V6.66667C2.91732 5.51667 3.85065 4.58333 5.00065 4.58333H15.0007C16.1507 4.58333 17.084 5.51667 17.084 6.66667V10C17.084 10.2333 17.2673 10.4167 17.5007 10.4167C17.734 10.4167 17.9173 10.2333 17.9173 10V6.66667C17.9173 5.05833 16.609 3.75 15.0007 3.75Z"
                fill="#B695F8"
                stroke="#B695F8"
                strokeWidth="0.5"
            />
            <path
                d="M15.3501 6.43523C15.2251 6.24357 14.9584 6.19357 14.7751 6.31857L11.1584 8.7269C10.4584 9.19357 9.55008 9.19357 8.85008 8.7269L5.23341 6.31857C5.04174 6.19357 4.78341 6.24357 4.65841 6.43523C4.53341 6.6269 4.58341 6.88523 4.77508 7.01023L8.39174 9.41857C8.88341 9.74357 9.45008 9.91023 10.0084 9.91023C10.5667 9.91023 11.1334 9.74357 11.6251 9.41857L15.2417 7.01023C15.4334 6.88523 15.4834 6.6269 15.3584 6.43523H15.3501ZM14.5834 10.4186C12.7417 10.4186 11.2501 11.9102 11.2501 13.7519C11.2501 15.5936 12.7417 17.0852 14.5834 17.0852C16.4251 17.0852 17.9167 15.5936 17.9167 13.7519C17.9167 11.9102 16.4251 10.4186 14.5834 10.4186ZM14.5834 16.2519C13.2084 16.2519 12.0834 15.1269 12.0834 13.7519C12.0834 12.3769 13.2084 11.2519 14.5834 11.2519C15.9584 11.2519 17.0834 12.3769 17.0834 13.7519C17.0834 15.1269 15.9584 16.2519 14.5834 16.2519Z"
                fill="#B695F8"
                stroke="#B695F8"
                strokeWidth="0.5"
            />
            <path
                d="M15.0091 13.5984V12.9401C15.0091 12.7068 14.8258 12.5234 14.5924 12.5234C14.3591 12.5234 14.1758 12.7068 14.1758 12.9401V13.7734C14.1758 13.8818 14.2174 13.9901 14.3008 14.0651L15.1341 14.8984C15.2174 14.9818 15.3258 15.0234 15.4258 15.0234C15.5258 15.0234 15.6424 14.9818 15.7174 14.8984C15.8841 14.7318 15.8841 14.4734 15.7174 14.3068L15.0091 13.5984Z"
                fill="#B695F8"
                stroke="#B695F8"
                strokeWidth="0.5"
            />
        </svg>
    );
};

export default SnoozeIcon;
