import React from 'react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export const UploadDocument = ({ setPdfUrl }) => {
    const [files, setFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf']
        },
        multiple: false,
        onDrop: ([acceptedFile]) => {
            if (acceptedFile && onDrop) {
                const reader = new FileReader();

                reader.onload = () => {
                    setPdfUrl(reader.result, acceptedFile.name);
                };

                reader.readAsDataURL(acceptedFile);
            }
        }
    });

    return (
        <div>
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                <p>Drag & drop files here, or click to select files</p>
            </div>
            <div>
                <h4>Uploaded Files:</h4>
                <ul>
                    {files.map((file) => (
                        <li key={file.name}>{file.name}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
