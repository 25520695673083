import React, { useState } from 'react';
import NotificationComponent from 'components/atoms/Notifications';
import { messaging } from 'utills/fb-messages';
import { onMessage } from 'firebase/messaging';
import { useDispatch, useSelector } from 'react-redux';
import { handleRecentNotification } from 'store/global/globalReducer';
import { getNotificationCount } from 'store/workspace/workspaceNotifications';

function Notification() {
    const { data: unreadCount } = useSelector((state) => state.getNotificationCount);
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();

    const hideNotification = (index) => {
        setNotifications((prevNotifications) => prevNotifications.filter((_, i) => i !== index));
    };

    onMessage(messaging, (payload) => {
        const newNotification = {
            title: payload.notification?.title,
            message: payload.notification?.body,
            url: payload.data?.url
        };

        const newNotificationPayload = {
            ...payload?.data,
            title: payload.notification?.title,
            message: payload.notification?.body,
            is_read: false
        };
        dispatch(handleRecentNotification(newNotificationPayload));
        dispatch(getNotificationCount.actions.handleUpdate({ count: unreadCount?.count + 1 }));
        setNotifications((prevNotifications) => [...prevNotifications, newNotification]);
    });

    return (
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            style={{ zIndex: 99 }}
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end" id="notify_div">
                {notifications.map((notification, index) => (
                    <NotificationComponent
                        key={index}
                        title={notification.title}
                        message={notification.message}
                        url={notification.url || null}
                        hideNotification={() => hideNotification(index)}
                    />
                ))}
            </div>
        </div>
    );
}

export default Notification;
