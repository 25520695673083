import React from 'react';

export const BgTel = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#FAF9F6" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7271 12.8509L12.4555 12.3653C13.4967 11.6711 13.7781 10.2642 13.0839 9.22296L11.6082 7.00929L11.6082 7.00929C11.0022 6.1004 9.83413 5.75316 8.83011 6.18346L8.16438 6.46877C7.44126 6.77868 6.85138 7.33487 6.49955 8.03855C6.26515 8.50734 6.03949 9.08445 6.00857 9.74211C5.92887 11.4372 6.32822 15.2869 10.5207 19.4794C14.7132 23.6719 18.5629 24.0712 20.258 23.9915C20.9156 23.9606 21.4927 23.7349 21.9615 23.5005C22.6652 23.1487 23.2214 22.5588 23.5313 21.8357L23.8166 21.17C24.2469 20.1659 23.8997 18.9978 22.9908 18.3919L20.7771 16.9161C19.7358 16.2219 18.3289 16.5033 17.6347 17.5446L17.1491 18.273C16.7683 18.3201 15.5901 18.2677 13.6613 16.3388C11.7324 14.41 11.68 13.2318 11.7271 12.8509ZM17.9459 18.8807L18.4668 18.0993C18.8546 17.5175 19.6406 17.3603 20.2224 17.7482L22.4361 19.224C22.9439 19.5625 23.1379 20.2151 22.8975 20.776L22.6122 21.4418C22.3954 21.9475 22.0064 22.36 21.5143 22.6061C21.1031 22.8117 20.6702 22.971 20.211 22.9926C18.7639 23.0606 15.1989 22.7434 11.2278 18.7723C7.25674 14.8012 6.93943 11.2361 7.00747 9.78907C7.02906 9.32989 7.18839 8.89692 7.39397 8.48576C7.64004 7.99364 8.05257 7.60465 8.5583 7.38791L9.22403 7.1026C9.78497 6.8622 10.4376 7.0562 10.7761 7.56399L12.2519 9.77766C12.6397 10.3594 12.4825 11.1454 11.9008 11.5333L11.1194 12.0542C10.9634 12.1582 10.8303 12.2957 10.7824 12.4769C10.6256 13.0705 10.5548 14.6465 12.9542 17.0459C15.3535 19.4453 16.9295 19.3745 17.5231 19.2176C17.7044 19.1697 17.8419 19.0367 17.9459 18.8807Z"
                fill="black"
            />
        </svg>
    );
};
