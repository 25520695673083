import React from 'react';

import { useEffect, useState } from 'react';
import 'driver.js/dist/driver.css';
import PDFViewer from './PDFViewer';
import SelectSigner from './select-signer';
import AddFields from './add-fields';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/atoms/buttons/Button';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS, colorSchemes, getProfileName } from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';
import { requestToSignDocumentThunk } from 'store/fileManager';
import { Loader } from 'components/common/Loader';
import { useNavigate } from 'react-router-dom';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { TextInput } from 'components/atoms/inputs/TextInput';
import { UserProfileChip } from 'components/molecules/UserProfileChip';
import { extractStringBeforeLastDot } from 'utills/dataValidation';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import { fetchSynkliCustomersThunk, fetchSynkliProfilesThunk } from 'store/client';
import ComboboxComponent from 'components/atoms/Combobox';

const AddSigner = ({
    pdfString,
    feilds,
    customers,
    employees,
    customer,
    file_manager_id,
    _isOpen,
    id,
    fileItem,
    onSubmit,
    preEmployees,
    preClients
}) => {
    const navigate = useNavigate();
    const [signers, setSigners] = useState([]);
    const [step, setStep] = useState('one');
    const [totalSteps, _setTotalSteps] = useState(['one', 'two', 'three']);
    const { data: loginData } = useSelector((state) => state.login);
    const { isLoading } = useSelector((state) => state.global);
    const [sortedEmployees, setSortedEmployees] = useState([]);
    const [sortedCusomters, setSortedCustomers] = useState([]);
    const [selectedSignees, setSelectedSignees] = useState([]);
    const [showCustomersModal, setShowCustomersModal] = useState(false);
    const [selectedCustomer, setSelectedCustomers] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [_filteredCustomers, setFilteredCustomers] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);

    const [isExpand, setIsExpand] = useState(false);
    const [scale, setScale] = useState(1);
    const { data: user } = useSelector((state) => state?.viewBusinessCustomerInfo);

    const zoomPercentage = Math.round(scale * 100);

    const handleScale = (val) => {
        setScale(val);
    };

    const handleExpand = () => {
        setIsExpand(!isExpand);
    };

    const getColorSchemeAtIndex = (index) => {
        const colorIndex = index % colorSchemes.length;
        return colorSchemes[colorIndex];
    };

    const [assignToYourSelf, setAssignToYourSelf] = useState(false);
    const [preSelectedCustomer, setPreSelectedCustomer] = useState(null);

    const dispatch = useDispatch();

    const [title, setTitle] = useState('');

    const [recepient, setRecepient] = useState([
        {
            id: '',
            name: '',
            email: ''
        }
    ]);

    useEffect(() => {
        if (fileItem?.name) {
            setTitle(fileItem?.name ? extractStringBeforeLastDot(fileItem?.name) : '');
        }
    }, [fileItem]);

    const toggleCustomersModal = () => {
        setShowCustomersModal(!showCustomersModal);
    };

    const handleSelecChange = (selected) => {
        setSelectedCustomers(selected);
    };

    const handleSelectedEmployees = (selected) => {
        setSelectedEmployees(selected);
    };

    const handleAssignItYourSelf = (e) => {
        const newEmployees = [...selectedEmployees];
        if (e.target.checked) {
            setAssignToYourSelf(true);

            newEmployees.push({
                value: loginData?.first_name + loginData?.last_name,
                label: loginData?.first_name + loginData?.last_name,
                user_id: loginData?.user_id,
                name: loginData?.first_name + loginData?.last_name,
                id: loginData?.user_id,
                email: loginData?.email
            });
            setSelectedEmployees(newEmployees);
        } else {
            setAssignToYourSelf(false);
            const findUser = newEmployees?.find((user) => user?.user_id === loginData?.user_id);
            if (findUser) {
                const indexOfuser = newEmployees?.indexOf(findUser);
                newEmployees.splice(indexOfuser, 1);
                setSelectedEmployees(newEmployees);
            }
        }
    };

    const handleChange = (index, value) => {
        const newSelectFields = [...recepient];
        const item = signers.find((item) => item.id === value);
        newSelectFields[index].email = item.email;
        newSelectFields[index].name = item.name;
        newSelectFields[index].id = item.id;
        setRecepient(newSelectFields);

        const tempsigners = [...signers];

        const tempIndex = tempsigners.findIndex((item) => item.id === value);
        tempsigners[tempIndex].selected = true;

        setSigners(tempsigners);
    };
    const onNext = (step) => {
        const signers = [...selectedCustomer, ...selectedEmployees, preSelectedCustomer];

        if (signers.length < 1) {
            toastHandler('Pleaser select atleast one signer', TOAST_TYPE_ERROR);
            return;
        }
        setStep(step ?? 'two');
    };
    const onBack = (step) => {
        setStep(step ?? 'one');
    };
    const formatFields = (fields) => {
        let obj = {};
        for (const item of fields) {
            if (obj[item.user_id] === undefined) {
                obj[item.user_id] = [];
            }
            obj[item.user_id].push({
                field_type: item.type,
                page: item.pageNumber,
                position_x: item.pageX,
                position_y: item.pageY,
                width: item.pageWidth,
                height: item.pageHeight
            });
        }
        return obj;
    };
    const onAddFieldsFormSubmit = (data) => {
        const tempFields = [];

        const fieldObj = formatFields(data.fields);
        for (const item in fieldObj) {
            tempFields.push({
                user_id: item,
                fields: fieldObj[item]
            });
        }
        const payload = {
            authentication_file: pdfString,
            file_manager_id: file_manager_id,

            business_client_id: id,
            employee_id: loginData.user_id,
            business_id: localStorage.getItem('business_id'),
            folder_name: 'authorized',
            document_title: data.title,
            users: tempFields
        };
        dispatch(setLoader(true));
        dispatch(requestToSignDocumentThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Submitted Successfully.', TOAST_TYPE_SUCCESS);
                    navigate(`/file/manager/authorized/folder/${id}`);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        const filteredEmployees = [];
        const filteredCustomers = [];

        console.info(employees, customers, '+====');
        employees?.forEach((option) => {
            filteredEmployees?.push({
                value: option?.first_name + option?.last_name,
                label: option?.first_name + option?.last_name,
                user_id: option?.user_id,
                name: option?.first_name + option?.last_name,
                id: option?.user_id,
                email: option?.email
            });
        });
        customers?.forEach((option) => {
            filteredCustomers?.push({
                value: option?.first_name + option?.last_name,
                label: option?.first_name + option?.last_name,
                user_id: option?.user_id,
                name: option?.first_name + option?.last_name,
                id: option?.user_id,
                email: option?.email
            });
        });

        setSortedCustomers(filteredCustomers);
        setSortedEmployees(filteredEmployees);
    }, [employees, customers]);

    useEffect(() => {
        if (customer) {
            const customerObj = {
                value: getProfileName(customer),
                label: getProfileName(customer),
                user_id: customer?.user_id,
                name: getProfileName(customer),
                id: customer?.user_id,
                email: customer?.email,
                image: customer?.image,
                colors: getColorSchemeAtIndex(selectedSignees?.length)
            };

            const newSelectedSignees = [...selectedSignees];

            const alreadyExist = newSelectedSignees.find((signee) => signee.user_id === customerObj.user_id);
            if (!alreadyExist) {
                newSelectedSignees.push(customerObj);
                setSelectedSignees(newSelectedSignees);
                setPreSelectedCustomer(customerObj);
            }
        }
    }, [customer]);

    useEffect(() => {
        if (preClients?.length > 0 || preEmployees?.length > 0) {
            let preSelectedCustomers;

            preSelectedCustomers = preClients?.map((client) => {
                const userObj = {
                    ...client,
                    user_id: client.user_id,
                    value: getProfileName(client),
                    label: getProfileName(client),
                    name: getProfileName(client),
                    id: client.user_id,
                    email: client?.email,
                    image: client?.image,
                    colors: getColorSchemeAtIndex(selectedSignees?.length)
                };
                return userObj;
            });

            let preSelectedEmps;
            preSelectedEmps = preEmployees?.map((emp) => {
                const userObj = {
                    ...emp,
                    user_id: emp.user_id,
                    value: getProfileName(emp),
                    label: getProfileName(emp),
                    name: getProfileName(emp),
                    id: emp.user_id,
                    email: emp?.email,
                    image: emp?.image,
                    colors: getColorSchemeAtIndex(selectedSignees?.length)
                };
                return userObj;
            });

            setSelectedSignees([...selectedSignees, ...preSelectedCustomers, ...preSelectedEmps]);
        }
    }, []);

    const calculateStepCount = () => {
        if (step === 'one') {
            return 0;
        } else if (step === 'two') {
            return 50;
        } else if (step === 'three') {
            return 100;
        } else {
            return 0;
        }
    };

    const removeSignee = (index) => {
        const newSelectedSignees = [...selectedSignees];
        const findSignee = newSelectedSignees[index];
        if (findSignee?.user_id === customer?.user_id) {
            return toastHandler('Pre selected user cant be deleted', TOAST_TYPE_ERROR);
        }
        newSelectedSignees.splice(index, 1);
        setSelectedSignees(newSelectedSignees);

        const newList = employees?.filter((item) => !newSelectedSignees.some((e) => e?.user_id === item.user_id));
        setFilteredEmployees(newList);
    };

    const handleAddSignee = (user) => {
        const alreadyExist = selectedSignees?.find((signee) => signee?.user_id === user?.user_id);
        if (alreadyExist) {
            toastHandler('This signee already exist', TOAST_TYPE_ERROR);
            return;
        }
        if (user) {
            const userObj = {
                user_id: user?.user_id,
                value: user?.first_name + user?.last_name,
                label: user?.first_name + user?.last_name,
                name: `${user?.first_name} ${user?.last_name ? user?.last_name : ''}`,
                id: user?.user_id,
                email: user?.email,
                image: user?.image,
                colors: getColorSchemeAtIndex(selectedSignees?.length)
            };
            const newSelectedSignees = [...selectedSignees];
            newSelectedSignees.push(userObj);

            setFilteredEmployees(filteredEmployees.filter((item) => item?.user_id !== user?.user_id));
            setSelectedSignees(newSelectedSignees);
            toggleCustomersModal();
        }
    };

    useEffect(() => {
        if (customers) {
            setFilteredCustomers(customers);
        }
    }, [customers]);

    // useEffect(() => {
    //     if (preEmployees?.length > 0) {
    //         const preSelectedEmps = preEmployees?.map((emp) => {
    //             const userObj = {
    //                 ...emp,
    //                 user_id: emp.user_id,
    //                 value: getProfileName(emp),
    //                 label: getProfileName(emp),
    //                 name: getProfileName(emp),
    //                 id: emp.user_id,
    //                 email: emp?.email,
    //                 image: emp?.image,
    //                 colors: getColorSchemeAtIndex(selectedSignees?.length)
    //             };
    //             return userObj;
    //         });
    //         setSelectedSignees([...selectedSignees, ...preSelectedEmps]);
    //     }
    // }, []);

    // const handleFilterCustomers = (term) => {
    //     if (customers) {
    //         const findCustomers = customers?.filter(
    //             (customer) =>
    //                 customer?.first_name?.toLowerCase()?.includes(term.toLowerCase()) ||
    //                 customer?.last_name?.toLowerCase()?.includes(term.toLowerCase())
    //         );

    //         setFilteredCustomers(findCustomers);
    //     }
    // };

    useEffect(() => {
        if (employees) {
            setFilteredEmployees(employees);
        }
    }, [employees]);

    const business_id = localStorage.getItem(BUSINESS_ID);

    const fetchCustomersList = async (payload) => {
        if (!onSubmit && user && user?.client_category !== 'Individual') {
            const newPayload = {
                ...payload,
                business_id,
                business_client_id: user?.business_client_id || '',
                customer_type: false,
                is_deleted: true,
                client_type: 'all_client'
            };
            return await dispatch(fetchSynkliProfilesThunk(newPayload));
        } else {
            const newPayload = {
                ...payload,
                business_id,
                customer_type: false,
                is_deleted: true,
                client_type: 'all_client'
            };
            return await dispatch(fetchSynkliCustomersThunk(newPayload));
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <div className="app-dashboard ">
                <div className="md:grid grid-cols-12 gap-5">
                    <div className={` ${isExpand ? 'col-span-12' : 'col-span-7'}  pdf-view `}>
                        <div className="relative col-span-12 rounded-xl before:rounded-xl lg:col-span-6 xl:col-span-7 shadow-lg border p-2">
                            <div className="overflow-hidden">
                                <PDFViewer
                                    left={isExpand ? '55%' : '35%'}
                                    key={'documentData.id'}
                                    handleExpand={handleExpand}
                                    isExpand={isExpand}
                                    documentData={pdfString}
                                    handleScale={handleScale}
                                    scalValue={scale}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-5 order-first md:order-last ">
                        <div
                            style={{ width: '35%' }}
                            className={`bg-white md:mt-0 mt-2   signature-right-container  fixed  rounded-md shadow-custom  `}
                        >
                            <div className="p-5 pt-8 px-7">
                                {' '}
                                <ProgressBar percent={calculateStepCount()} filledBackground="#b695f8" height={2}>
                                    {totalSteps?.map((step, index) => (
                                        <Step transition="scale" key={'steps_' + index}>
                                            {({ accomplished }) => (
                                                <div className={` ${accomplished ? 'com-step' : 'incom-step'}`}>
                                                    {index + 1}
                                                </div>
                                            )}
                                        </Step>
                                    ))}
                                </ProgressBar>
                            </div>
                            {step === 'one' && (
                                <div className="mt-5 px-5">
                                    <div className="primary-fonts text-xl">Add Title </div>
                                    <div className="secondary-fonts text-sm">Add The Title to the document</div>
                                    <div className="mt-5">
                                        <div className="primary-fonts text-md">
                                            {' '}
                                            <label>Title</label>{' '}
                                        </div>
                                        <div>
                                            <TextInput
                                                onChange={(e) => {
                                                    setTitle(e.target.value);
                                                }}
                                                value={title}
                                                required={true}
                                                placeholder="Please Enter Title"
                                                maxLength={50}
                                                allowNumbers={true}
                                            />{' '}
                                        </div>

                                        <div className="flex justify-center items-center mt-44">
                                            <Button
                                                title="Next"
                                                classes="ann-btn px-12"
                                                buttonType="button"
                                                key="button"
                                                atClick={() => {
                                                    if (!title) {
                                                        toastHandler('Please add document title', TOAST_TYPE_ERROR);
                                                        return;
                                                    }
                                                    onNext('two');
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {step === 'two' && (
                                <div className="mt-5 px-5">
                                    <div className="primary-font text-xl">Add Signers</div>
                                    <div className="secondary-font text-sm">Add People who will sign the document</div>

                                    <div className="default-forms">
                                        <div className="primary-fonts text-md mt-2">
                                            {' '}
                                            <label>Selected Signees</label>{' '}
                                        </div>
                                        <div>
                                            <div className="border border-secondary  min-h-[3rem] !bg-gray-200 flex items-center gap-2 flex-wrap p-2 py-2.5 !rounded-xl mt-1">
                                                {selectedSignees.length < 1 && (
                                                    <label className="!text-sm !font-[400] !text-gray-600">
                                                        Selected Signees
                                                    </label>
                                                )}

                                                {selectedSignees.map((signee, index) => (
                                                    <div key={'user_profile_' + index}>
                                                        <UserProfileChip
                                                            image={signee.image}
                                                            name={signee.name}
                                                            email={signee.email}
                                                            user_id={customer?.user_id}
                                                            id={signee?.id}
                                                            atClick={() => removeSignee(index)}
                                                            colorScheme={signee?.colors}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div>
                                            {' '}
                                            <div className="mt-2 text-md">
                                                {' '}
                                                <label>Select Clients</label>{' '}
                                            </div>
                                            <div>
                                                <ComboboxComponentSSR
                                                    fetchDataFunction={fetchCustomersList}
                                                    defaultSelected={''}
                                                    removePreviousSelected
                                                    onChange={(e) => handleAddSignee(e[0])}
                                                    targetProperty="user_id"
                                                    hideSelectedValues
                                                    placeholder={'Select Client'}
                                                    sx={'!mt-1 '}
                                                    showDesignation={user?.client_category === 'Individual'}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            {' '}
                                            <div className="mt-2 text-md">
                                                {' '}
                                                <label>Select Employees</label>{' '}
                                            </div>
                                            <div>
                                                <ComboboxComponent
                                                    data={filteredEmployees}
                                                    placeholder={'Select Employees'}
                                                    onChange={(list) => {
                                                        const obj = list[0];
                                                        handleAddSignee(obj);
                                                    }}
                                                    onRemove={(_e) => {}}
                                                    targetProperty="user_id"
                                                    hideSelectedValues
                                                    sx={'!mt-1 '}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full mt-16 justify-center flex gap-2 items-center my-5 pb-2">
                                        <Button
                                            title="Back"
                                            classes="blue-border-btn px-12 "
                                            buttonType="button"
                                            key="button"
                                            atClick={() => onBack('one')}
                                        />{' '}
                                        <Button
                                            title="Next"
                                            classes="ann-btn  px-12"
                                            buttonType="button"
                                            key="button"
                                            atClick={() => {
                                                if (selectedSignees?.length < 1) {
                                                    toastHandler('Please select atleast one signee', TOAST_TYPE_ERROR);
                                                    return;
                                                }
                                                onNext('three');
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {step === 'four' && (
                                <form className="">
                                    <div>
                                        {customer && (
                                            <div className="add-ann-form">
                                                <div>
                                                    {' '}
                                                    <label>Selected Customer</label>{' '}
                                                </div>
                                                <div>
                                                    <div className="uneditable-data p-3 rounded-md mt-1">
                                                        {' '}
                                                        <span className="font-bold">
                                                            {' '}
                                                            {customer?.first_name} {customer?.last_name}{' '}
                                                        </span>{' '}
                                                        | {customer?.email}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {recepient &&
                                        recepient.map((item, index) => (
                                            <div key={'signers_' + index}>
                                                <SelectSigner
                                                    key={index}
                                                    options={signers}
                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                    customers={customers}
                                                    handleSelecChange={handleSelecChange}
                                                    handleSelectedEmployees={handleSelectedEmployees}
                                                    handleAssignItYourSelf={handleAssignItYourSelf}
                                                    assignToYourSelf={assignToYourSelf}
                                                    customer={customer}
                                                    selectedCustomer={selectedCustomer}
                                                    selectedEmployees={selectedEmployees}
                                                    sortedCusomters={sortedCusomters}
                                                    sortedEmployees={sortedEmployees}
                                                />
                                            </div>
                                        ))}
                                </form>
                            )}
                            {step === 'three' && (
                                <AddFields
                                    key={feilds.length}
                                    recipients={selectedSignees}
                                    fields={feilds}
                                    onSubmit={onSubmit ? onSubmit : onAddFieldsFormSubmit}
                                    onBack={onBack}
                                    title={title}
                                    isExpand={isExpand}
                                    scale={scale}
                                    zoomPercentage={zoomPercentage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AddSigner;
