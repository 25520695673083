import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CircleStencil, FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import { ZoomInIcon } from 'utills/svgs/ZoomInIcon';
import { ZoomoutIcon } from 'utills/svgs/ZoomoutIcon';
import { Dialog, Transition } from '@headlessui/react';
import {
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    BarsArrowDownIcon,
    BarsArrowUpIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';

export const ImageCropper = ({ image, handleCroppedImage, width, height, setShowCropper, isCircular }) => {
    const cropperRef = useRef();
    const [_zoom, setZoom] = useState(1);
    const [open, setOpen] = useState(true);
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        if (!open) {
            setShowCropper(false);
        } else {
            setOpen(true);
        }
    }, [open]);

    const handleCrop = () => {
        handleCroppedImage(cropperRef.current.getCanvas()?.toDataURL());
        setShowCropper(false);
    };

    const handleZoomIn = () => {
        if (cropperRef.current) {
            cropperRef.current.zoomImage(1.1);
            setZoom((prevZoom) => prevZoom * 1.1);
        }
    };

    const handleZoomOut = () => {
        if (cropperRef.current) {
            cropperRef.current.zoomImage(0.9);
            setZoom((prevZoom) => prevZoom * 0.9);
        }
    };

    const handleRotateLeft = () => {
        if (cropperRef.current) {
            setRotation(rotation - 90);
            cropperRef.current.rotateImage(-90);
        }
    };

    const handleRotateRight = () => {
        if (cropperRef.current) {
            setRotation(rotation + 90);
            cropperRef.current.rotateImage(90);
        }
    };

    const handleFlipHorizontal = () => {
        if (cropperRef.current) {
            cropperRef.current.flipImage(true, false);
        }
    };

    const handleFlipVertical = () => {
        if (cropperRef.current) {
            cropperRef.current.flipImage(false, true);
        }
    };

    return (
        <>
            <Transition.Root as={Fragment} appear show={open}>
                <Dialog as="div" className="relative z-[999]" onClose={() => setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-10 lg:p-15 xl:p-20">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                                <div className="contents top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[9999] w-[1000px] h-[670px] overflow-hidden bg-black rounded-lg shadow-lg ">
                                    <div className="w-full h-full relative">
                                        <div
                                            onClick={() => setOpen(false)}
                                            className="cursor-pointer  absolute top-3 right-3 bg-white p-2 rounded-full z-[9999]"
                                        >
                                            <XMarkIcon className="w-5" />
                                        </div>

                                        <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 flex items-center z-[9999]">
                                            <div className=" flex space-x-2">
                                                <button
                                                    onClick={handleZoomIn}
                                                    className="px-3 py-1 bg-white text-gray-800 rounded-md"
                                                >
                                                    <ZoomInIcon />
                                                </button>
                                                <button
                                                    onClick={handleZoomOut}
                                                    className="px-3 py-1 bg-white text-gray-800 rounded-md"
                                                >
                                                    <ZoomoutIcon />
                                                </button>
                                                <button
                                                    onClick={handleRotateLeft}
                                                    className="px-3 py-1 bg-white text-gray-800 rounded-md"
                                                >
                                                    <ArrowUturnLeftIcon className="w-5" />
                                                </button>
                                                <button
                                                    onClick={handleRotateRight}
                                                    className="px-3 py-1 bg-white text-gray-800 rounded-md"
                                                >
                                                    <ArrowUturnRightIcon className="w-5" />
                                                </button>
                                                <button
                                                    onClick={handleFlipHorizontal}
                                                    className="px-3 py-1 bg-white  text-gray-800 rounded-md"
                                                >
                                                    <BarsArrowUpIcon className="w-5" />
                                                </button>
                                                <button
                                                    onClick={handleFlipVertical}
                                                    className="px-3 py-1 bg-white text-gray-800 rounded-md"
                                                >
                                                    <BarsArrowDownIcon className="w-5" />
                                                </button>
                                                <button
                                                    onClick={handleCrop}
                                                    className="px-3 py-1 bg-thirdbg font-poppins text-white rounded-md"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>

                                        <div className="cursor-grab w-full h-full">
                                            <FixedCropper
                                                ref={cropperRef}
                                                src={image}
                                                backgroundClassName="w-full h-full"
                                                stencilProps={{
                                                    handlers: false,
                                                    lines: true,
                                                    movable: true,
                                                    resizable: false
                                                }}
                                                stencilComponent={isCircular && CircleStencil}
                                                stencilSize={{
                                                    width: width ?? 120,
                                                    height: height ?? 120
                                                }}
                                                imageRestriction={ImageRestriction.stencil}
                                                style={{
                                                    transform: `rotate(${rotation}deg)`,
                                                    overflow: 'hidden',
                                                    maxHeight: '80vh'
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};
