import { Button } from 'components/atoms/buttons/Button';
import { TextInput } from 'components/atoms/inputs/TextInput';
import { SmallLoader } from 'components/common/SmallLoader';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';

import { createFolderThunk, renameFolderThunk } from 'store/JobManager';
import { extractStringBeforeLastDot } from 'utills/dataValidation';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';

const EntityCreateUpdate = ({ open = false, setOpen, editObj, fileName, type = 'Folder', getData }) => {
    const [name, setName] = useState('');
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();

    useEffect(() => {
        setName(editObj && fileName ? extractStringBeforeLastDot(fileName) : 'Untitled folder');
    }, [fileName, editObj]);

    const handleSubmit = async () => {
        if (name?.trim() !== '') {
            let payload = {
                job_id: id,
                business_id,
                name: name,
                ...(editObj?.parent_id ? { parent_id: editObj?.parent_id } : {})
            };

            if (editObj?.id) {
                payload.job_drive_id = editObj?.id;
            }

            setLoader(true);
            dispatch(editObj?.id ? renameFolderThunk(payload) : createFolderThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        setOpen(false);
                        getData(null, editObj?.parent_id);
                        toastHandler('Successfully Created', TOAST_TYPE_SUCCESS);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toastHandler('Something went wrong', TOAST_TYPE_ERROR);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    };

    return (
        <div>
            <DefaultDialog width={'!max-w-md !sm:max-w-md'} open={open} setOpen={setOpen}>
                <div className="text-lg text-blueish font-poppins flex font-medium justify-start items-start mt-1">
                    {(editObj?.id ? 'Rename' : 'New') + ' ' + type}
                </div>
                <div className="text-start my-3">
                    <TextInput
                        value={name}
                        onChange={(e) => {
                            const value = e?.target?.value;
                            setName(value === ' ' ? value?.trim() : value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                        maxLength={40}
                        placeholder={type + ' Name'}
                        allowNumbers
                    />
                </div>
                <div className="flex items-end gap-5 w-full mt-6 justify-end">
                    <Button
                        title={'Cancel'}
                        classes={
                            '!px-2.5 btn-secondary text-primary cursor-pointer py-1.5 rounded-xl hover:!bg-gray-200 hover:!shadow-none'
                        }
                        atClick={() => {
                            setOpen(!open);
                        }}
                    />
                    <Button
                        title={editObj?.id ? 'Update' : 'Create'}
                        isloading={loader}
                        iconSet={{ loader: SmallLoader }}
                        classes={
                            '!px-2.5 btn-primary text-primary font-semibold cursor-pointer py-1.5 rounded-xl hover:!bg-gray-200 hover:!shadow-none'
                        }
                        disabled={loader || name === '' ? true : false}
                        atClick={() => {
                            handleSubmit();
                        }}
                    />
                </div>
            </DefaultDialog>
        </div>
    );
};

export default EntityCreateUpdate;
