import React, { useState } from 'react';
import { Button } from 'components/atoms/buttons/Button';
import Slideover from 'components/atoms/SlideOver';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';
import { Chip } from 'components/atoms/buttons/Chip';
import { cn, mergeClasses } from 'utills/tailwindUtil';
import { DownloadIcon2 } from 'utills/svgs/DownloadIcon2';
import PendingEye from 'utills/svgs/PendingEye';
import CirculerCheck from 'utills/svgs/CirculerCheck';
import { DateFormatToLocal } from 'utills/moment';
import { ParseHtml } from 'Hooks/ParseHtml';
import { useDownloadZip } from 'Hooks/Files/useDownloadZip';
import { useParams } from 'react-router-dom';
import { BUSINESS_ID, getProfileName, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { endpoint } from 'apiEndpoints';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { downloadFile } from 'utills/filesHandlers';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { confirmClearanceThunk } from 'store/JobManager';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import useReadActivityHook from 'Hooks/job-manager/useReadActivityHook';
import Pulse from 'components/atoms/Pulse';

export const ClearanceDetails = ({ open, setOpen, activityData, onComplete, _updateActivity, getActivityCount }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');
    const { hasPermissions } = useRoleGuard();
    const { markCompleteJobAtion } = useReadActivityHook();

    const [openedClients, setOpenedClients] = useState([]);

    const statusList = [
        {
            icon: PendingEye,
            text: 'clearance pending',
            className: 'bg-[#F08201]/10 text-[#F08201]'
        },
        {
            icon: PendingEye,
            text: 'pending',
            className: 'bg-[#F08201]/10 text-[#F08201]'
        },

        {
            icon: CirculerCheck,
            text: 'Completed',
            className: 'bg-[#008C51]/10 text-[#008C51]'
        },
        {
            icon: CirculerCheck,
            text: 'Submitted',
            className: 'bg-[#008C51]/10 text-[#008C51]'
        }
    ];

    const getStatus = (status) => {
        let findStatusObj = statusList?.find((obj) => obj?.text?.toLowerCase() === status?.toLowerCase());
        return (
            <Chip
                title={findStatusObj ? findStatusObj.text : status || 'N/A'}
                classes={mergeClasses('flex w-fit items-center gap-2', findStatusObj?.className)}
                iconSet={findStatusObj ? { rightIcon: findStatusObj?.icon } : null}
            />
        );
    };

    const { handleDownloadZip } = useDownloadZip();

    const handleDownlaodItems = async (business_client_id, ethical_clearance_doc_id) => {
        let payload = {
            job_id: id,
            business_id,
            business_client_id,
            ethical_clearance_id: activityData?.ethical_clearance_id,
            ethical_clearance_doc_id
        };
        handleDownloadZip(payload, { endpoint: endpoint.downloadClearanceFiles, method: 'GET' });
    };

    const handleClitentsOpenClose = (business_client_id) => {
        const newArr = [...openedClients];

        if (newArr.includes(business_client_id)) {
            newArr.splice(newArr.indexOf(business_client_id), 1);
        } else {
            newArr.push(business_client_id);
        }

        setOpenedClients(newArr);
    };

    const handleCompleteClearance = () => {
        if (!hasPermissions('Job', ['write', 'admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(
            confirmClearanceThunk({ job_id: id, business_id, ethical_clearance_id: activityData?.ethical_clearance_id })
        ).finally(() => {
            dispatch(setLoader(false));
            onComplete();
            setOpen(false);
        });
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                title={activityData?.ethical_clearance_id?.slice(-12)}
                titlesx={' !font-medium'}
                size="max-w-[1200px]"
            >
                {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
                {selectedPdf ? (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                    />
                ) : (
                    ''
                )}
                <div className="flex justify-end items-center">
                    {activityData?.status?.toLowerCase() === 'completed' ? (
                        <div> {getStatus(activityData?.status)}</div>
                    ) : (
                        <Button
                            title="Submited"
                            classes={'bg-thirdbg py-2 text-white !text-sm font-[400] px-5 rounded-lg'}
                            atClick={handleCompleteClearance}
                        />
                    )}
                </div>
                <div className="mt-2">
                    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2">
                        <li className="overflow-hidden rounded-2xl shadow-lg">
                            <div className="px-6 py-5">
                                <h3 className="text-2xl font-fira font-medium">Request Detail</h3>
                                <div className="grid grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-2 mt-5">
                                    <div className="text-secondarybg">Request ID:</div>
                                    <div className="">{activityData?.ethical_clearance_id?.slice(-12)}</div>
                                    <div className="text-secondarybg">Date and Time</div>
                                    <div className="">
                                        {' '}
                                        <DateFormatToLocal date={activityData?.created_at} />{' '}
                                    </div>
                                    <div className="text-secondarybg">Requested By:</div>
                                    {activityData?.created_by && (
                                        <div className=" flex gap-2 items-center">
                                            <DefaultProfileViewer
                                                width={'30px'}
                                                height={'30px'}
                                                name={
                                                    activityData?.created_by?.first_name +
                                                    ' ' +
                                                    activityData?.created_by?.last_name
                                                }
                                                image={activityData?.created_by?.image}
                                            />{' '}
                                            {activityData?.created_by?.first_name +
                                                ' ' +
                                                activityData?.created_by?.last_name}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </li>
                        <li className="overflow-hidden rounded-2xl shadow-lg">
                            <div className="px-6 py-5">
                                <h3 className="text-2xl font-fira font-medium">Former Accountant</h3>
                                <div className="grid grid-cols-1 gap-x-2 gap-y-4 mt-5">
                                    <div className="">{activityData?.sent_to_name || '--'}</div>
                                    <div className="text-secondarybg">{activityData?.email || '--'}</div>
                                    {/* {activityData?.phone && (
                                        <div className="text-secondarybg">{activityData?.phone || '--'}</div>
                                    )} */}
                                </div>
                            </div>
                        </li>
                        <li className="overflow-hidden grid-cols-1 lg:col-span-2 rounded-2xl shadow-lg">
                            <div className="px-6 py-5">
                                <h3 className="text-2xl font-fira font-medium">Request Message</h3>
                                <div className="grid grid-cols-1 mt-5">
                                    <div className="">
                                        <ParseHtml htmlContent={activityData?.note || '--'} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="mt-5">
                        <div className="px-6 py-5 overflow-hidden rounded-2xl shadow-lg">
                            <h3 className="text-2xl font-fira font-medium">Clients</h3>
                            {activityData?.ethicalClearanceDoc?.length > 0
                                ? activityData?.ethicalClearanceDoc?.map((client, index) => (
                                      <div key={index} className="rounded-xl shadow-md my-5 relative pb-5">
                                          <div className="lg:flex gap-5 p-5">
                                              <div className="w-[130px] ">
                                                  <div className="">
                                                      <DefaultProfileViewer
                                                          image={client?.business_client.image || ''}
                                                          height={'100px'}
                                                          width={'100px'}
                                                          name={getProfileName(client?.business_client)}
                                                      />
                                                  </div>
                                              </div>
                                              <div className="w-full flex flex-col justify-around">
                                                  <div className="flex flex-wrap justify-between items-start mt-10 lg:mt-0 gap-2">
                                                      <div className="flex gap-4 items-center">
                                                          <div className="lg-title font-poppins">
                                                              {getProfileName(client?.business_client)}
                                                          </div>
                                                          <Chip
                                                              title={
                                                                  'ABN:' +
                                                                  (client?.business_client?.abn &&
                                                                  client?.business_client?.abn !== 'null'
                                                                      ? client?.business_client?.abn
                                                                      : '--')
                                                              }
                                                              classes={'default-opacity-bg text-thirdbg'}
                                                          />
                                                      </div>
                                                      <div className="flex gap-4 items-center">
                                                          <Chip
                                                              title={
                                                                  'TFN:' +
                                                                  (client?.business_client?.tfn &&
                                                                  client?.business_client?.tfn !== 'null'
                                                                      ? client?.business_client?.tfn
                                                                      : '--')
                                                              }
                                                              classes={'default-opacity-bg text-thirdbg'}
                                                          />
                                                          <div>
                                                              <span
                                                                  className="cursor-pointer"
                                                                  onClick={() =>
                                                                      handleClitentsOpenClose(
                                                                          client?.business_client_id
                                                                      )
                                                                  }
                                                              >
                                                                  {openedClients?.includes(
                                                                      client?.business_client_id
                                                                  ) ? (
                                                                      <CollapsIcon className={'w-6 h-6'} />
                                                                  ) : (
                                                                      <ExpandIcon className={'w-6 h-6'} />
                                                                  )}
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </div>

                                                  <div
                                                      className={cn(
                                                          'flex flex-col md:grid md:auto-cols-min md:grid-cols-2 gap-x-4 gap-y-5 mt-5 items-start',
                                                          '2xl:grid-cols-2'
                                                      )}
                                                  >
                                                      <div
                                                          className={cn(
                                                              'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start'
                                                          )}
                                                      >
                                                          <div className="text-secondarybg  min-w-[165px]">
                                                              Email Address:{' '}
                                                          </div>
                                                          <div className="overflow-hidden text-ellipsis">
                                                              {client?.business_client?.email || '__'}
                                                          </div>
                                                      </div>
                                                      <div
                                                          className={cn(
                                                              'flex xl:flex-row flex-col gap-3 2xl:gap-10 items-start'
                                                          )}
                                                      >
                                                          <div className="text-secondarybg min-w-[165px]">
                                                              Phone Number:{' '}
                                                          </div>
                                                          <div>{client?.business_client?.phone_number || '__'}</div>
                                                      </div>
                                                  </div>
                                                  <div className={cn('mt-5')}>
                                                      <hr />
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="p-5">
                                              <h5 className="text-lg font-medium">Message</h5>
                                              <div className="border-l-4 pl-3 border-third text-base mt-3">
                                                  {client?.reason || 'No Response from Former Accountant yet.'}
                                              </div>

                                              {openedClients?.includes(client?.business_client_id) && (
                                                  <div>
                                                      <div className="w-full overflow-auto">
                                                          <div className="!rounded-xl my-5 px-3 py-2 !bg-[#F8F4FE] w-full overflow-auto">
                                                              <div className="grid grid-cols-5 gap-5 py-3">
                                                                  <div
                                                                      className={
                                                                          'z-[4]  buttons-font primary-fonts !w-[200px] text-left'
                                                                      }
                                                                  >
                                                                      Requested Documents
                                                                  </div>
                                                                  <div
                                                                      className={
                                                                          'z-[4]  buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                                                      }
                                                                  >
                                                                      Date & Time
                                                                  </div>

                                                                  <div
                                                                      className={
                                                                          'z-[4]  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                                      }
                                                                  >
                                                                      Sent By
                                                                  </div>

                                                                  <div
                                                                      className={
                                                                          'z-[4]  buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                                                      }
                                                                  >
                                                                      Status
                                                                  </div>

                                                                  <div
                                                                      className={
                                                                          'z-[4] buttons-font primary-fonts  !w-[200px] !pl-[10px] text-center'
                                                                      }
                                                                  >
                                                                      Action
                                                                  </div>
                                                              </div>

                                                              <div className="!border-b !border-gray-300"></div>
                                                              {client?.documents?.map((document, index) => (
                                                                  <div key={index}>
                                                                      <div className="grid grid-cols-5 gap-5">
                                                                          <div
                                                                              className={cn(
                                                                                  'whitespace-nowrap flex items-center  !text-[16px]',
                                                                                  ''
                                                                              )}
                                                                          >
                                                                              <div
                                                                                  className="flex items-center gap-2 cursor-pointer overflow-hidden"
                                                                                  onClick={() => {}}
                                                                              >
                                                                                  <div className="flex flex-col overflow-hidden">
                                                                                      <div className="app-dataObj-name flex items-center gap-2 text-ellipsis overflow-hidden">
                                                                                          {document?.name || '--'}
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                          <div
                                                                              className={cn(
                                                                                  'whitespace-nowrap  flex items-center text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                                  ''
                                                                              )}
                                                                          >
                                                                              <DateFormatToLocal
                                                                                  date={document?.created_at}
                                                                              />{' '}
                                                                          </div>
                                                                          <div
                                                                              className={cn(
                                                                                  'whitespace-nowrap flex items-center  text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                                  ''
                                                                              )}
                                                                          >
                                                                              {activityData?.sent_to_name || '--'}
                                                                          </div>

                                                                          <div
                                                                              className={cn(
                                                                                  'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black !capitalize',
                                                                                  ''
                                                                              )}
                                                                          >
                                                                              {getStatus(document?.status)}
                                                                          </div>
                                                                          <div
                                                                              className={cn(
                                                                                  'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center',
                                                                                  ''
                                                                              )}
                                                                          >
                                                                              <div className="flex justify-center items-center gap-2">
                                                                                  {document?.status === 'Submitted' && (
                                                                                      <Button
                                                                                          atClick={() => {
                                                                                              handleDownlaodItems(
                                                                                                  client?.business_client_id,
                                                                                                  document?.ethical_clearance_doc_id
                                                                                              );
                                                                                          }}
                                                                                          iconSet={{
                                                                                              leftIconHTML: (
                                                                                                  <DownloadIcon2
                                                                                                      className={
                                                                                                          'w-5 text-[#B695F8]'
                                                                                                      }
                                                                                                  />
                                                                                              )
                                                                                          }}
                                                                                          classes={
                                                                                              '!rounded-full bg-[#B695F8]/10 p-2.5 cursor-pointer'
                                                                                          }
                                                                                      />
                                                                                  )}
                                                                              </div>
                                                                          </div>
                                                                      </div>

                                                                      {document?.jobDrives?.length > 0 && (
                                                                          <div className="flex flex-wrap gap-3 my-2">
                                                                              {document?.jobDrives?.map(
                                                                                  (att, index) => (
                                                                                      <div
                                                                                          key={
                                                                                              'document?.jobDrives' +
                                                                                              index
                                                                                          }
                                                                                      >
                                                                                          {(() => {
                                                                                              const foundObj =
                                                                                                  getActivityCount.find(
                                                                                                      (item) =>
                                                                                                          item.id ===
                                                                                                          att?.job_drive_id
                                                                                                  );
                                                                                              return (
                                                                                                  <div
                                                                                                      className="bg-thirdbg cursor-pointer text-white rounded-lg w-[200px] p-2 flex gap-2 items-center relative"
                                                                                                      onClick={() => {
                                                                                                          if (
                                                                                                              att?.mimetype
                                                                                                                  ?.toLowerCase()
                                                                                                                  ?.includes(
                                                                                                                      '/png'
                                                                                                                  ) ||
                                                                                                              att?.mimetype
                                                                                                                  ?.toLowerCase()
                                                                                                                  ?.includes(
                                                                                                                      '/jpg'
                                                                                                                  ) ||
                                                                                                              att?.mimetype
                                                                                                                  ?.toLowerCase()
                                                                                                                  ?.includes(
                                                                                                                      '/jpeg'
                                                                                                                  )
                                                                                                          ) {
                                                                                                              setSelectedImage(
                                                                                                                  att?.url
                                                                                                              );
                                                                                                          } else if (
                                                                                                              att?.mimetype?.includes(
                                                                                                                  '/pdf'
                                                                                                              )
                                                                                                          ) {
                                                                                                              setSelectedPdf(
                                                                                                                  att?.url
                                                                                                              );
                                                                                                          } else {
                                                                                                              downloadFile(
                                                                                                                  att?.url
                                                                                                              );
                                                                                                          }
                                                                                                          if (
                                                                                                              foundObj
                                                                                                          ) {
                                                                                                              markCompleteJobAtion(
                                                                                                                  {
                                                                                                                      id: foundObj?.id,
                                                                                                                      job_action_id:
                                                                                                                          foundObj?.job_action_id
                                                                                                                  }
                                                                                                              );
                                                                                                          }
                                                                                                      }}
                                                                                                  >
                                                                                                      <div>
                                                                                                          <GenerateFileIcon
                                                                                                              sx="!h-[30px] !w-[30px]"
                                                                                                              url={
                                                                                                                  att?.url
                                                                                                              }
                                                                                                          />
                                                                                                          {foundObj && (
                                                                                                              <div className="absolute -right-0.5 -top-0.5">
                                                                                                                  <Pulse
                                                                                                                      sx={
                                                                                                                          'h-3 w-3 !bg-primary'
                                                                                                                      }
                                                                                                                      wsx={
                                                                                                                          '!bg-primary'
                                                                                                                      }
                                                                                                                      tooltip={
                                                                                                                          foundObj?.description
                                                                                                                      }
                                                                                                                  />
                                                                                                              </div>
                                                                                                          )}
                                                                                                      </div>
                                                                                                      <div className="truncate overflow-hidden">
                                                                                                          {att?.name}
                                                                                                      </div>
                                                                                                  </div>
                                                                                              );
                                                                                          })()}
                                                                                      </div>
                                                                                  )
                                                                              )}
                                                                          </div>
                                                                      )}

                                                                      <div className="!border-b !border-gray-300"></div>
                                                                  </div>
                                                              ))}
                                                          </div>
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  ))
                                : ''}
                        </div>
                    </div>
                </div>
            </Slideover>
        </>
    );
};
