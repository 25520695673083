import React from 'react';

export const BgPencilIcon = () => {
    return (
        <div>
            {' '}
            <svg width="36" height="34" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="36" height="30" rx="15" fill="#B695F8" />
                <path
                    d="M11.875 25C11.3594 25 10.9181 24.8043 10.5512 24.413C10.1837 24.021 10 23.55 10 23V7C10 6.45 10.1837 5.979 10.5512 5.587C10.9181 5.19567 11.3594 5 11.875 5H19.375L25 11V15H23.125V12H18.4375V7H11.875V23H17.5V25H11.875ZM23.4062 17.525L24.4141 18.6L20.7812 22.45V23.5H21.7656L25.3984 19.65L26.3828 20.7L22.3516 25H19.375V21.825L23.4062 17.525ZM26.3828 20.7L23.4062 17.525L24.7656 16.075C24.9375 15.8917 25.1562 15.8 25.4219 15.8C25.6875 15.8 25.9062 15.8917 26.0781 16.075L27.7422 17.85C27.9141 18.0333 28 18.2667 28 18.55C28 18.8333 27.9141 19.0667 27.7422 19.25L26.3828 20.7Z"
                    fill="#B695F8"
                />
            </svg>
        </div>
    );
};
