import React from 'react';
import GoogleAuthenticatorPage from '../../components/second-time-login-pages/GoogleAuthenticatorPage';
const GoogleAuthenticator = () => {
    return (
        <div>
            <GoogleAuthenticatorPage />
        </div>
    );
};

export default GoogleAuthenticator;
