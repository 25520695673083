import React, { useEffect, useState } from 'react';
import ActivityMsgView from './ActivityMsgView';
import ActivityInputBox from './ActivityInputBox';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { BUSINESS_ID, handleRemoveQueryParams, JOB_ACTIVITY_CHAT } from 'utills/globalVars';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { JobManagerListActivityThunk, JobManagerSendActivityMsgThunk } from 'store/JobManager';
import useReadActivityHook from 'Hooks/job-manager/useReadActivityHook';

const Activity = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: loginData } = useSelector((state) => state.login);

    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [page, setPage] = useState(1);

    const [pageData, setPageData] = useState([]);

    const [allowFirst, setAllowFirst] = useState(true);

    const { data: getJobActions } = useSelector((state) => state.getJobActions);
    const { markCompleteJobAtion } = useReadActivityHook();
    const getActivityCount =
        getJobActions
            ?.filter((obj) => obj?.type === JOB_ACTIVITY_CHAT)
            .map((obj) => {
                const { meta, ...restOfData } = obj;
                const mainObj = meta.ids.map((id) => {
                    return {
                        ...restOfData,
                        id
                    };
                });
                return mainObj;
            })
            ?.flat() || [];

    useEffect(() => {
        if (getActivityCount?.length > 0) {
            markCompleteJobAtion({
                id: getActivityCount[0]?.id,
                job_action_id: getActivityCount[0]?.job_action_id
            });
        }
    }, [getActivityCount]);

    // ------------------------------------------------------ handle Notificaiton -----------------------------
    // Extracting query parameters
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const job_activity_id = queryParams.get('job_activity_id');

    useEffect(() => {
        if (allowFirst && job_activity_id) {
            setAllowFirst(false);
        }
    }, [job_activity_id]);

    useEffect(() => {
        if (pageData?.length > 0 && !allowFirst) {
            handleRemoveQueryParams(navigate, ['activeTab']);
        }
    }, [pageData, allowFirst]);

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        getData(false, signal);
        return () => {
            controller.abort();
        };
    }, [page]);

    const getData = async (hasReset = false, signal = null) => {
        const payload = {
            job_id: id,
            business_id,
            page: hasReset ? 1 : page,
            page_size: Number(PAGINATION_PAGE_SIZE)
        };

        setHasMoreContent(true);

        try {
            const response = await dispatch(JobManagerListActivityThunk(payload, signal));
            if (response.payload) {
                const cloneData = [...(!hasReset && page !== 1 ? pageData || [] : []), ...response.payload.rows];
                setPageData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const [msgLoading, setMsgLoading] = useState(false);
    const handleSubmit = async (message) => {
        const payload = {
            job_id: id,
            business_id,
            message: message
        };

        setMsgLoading(true);

        try {
            const response = await dispatch(JobManagerSendActivityMsgThunk(payload));
            if (response.payload) {
                if (page === 1) {
                    getData(true);
                } else {
                    setPageData([]);
                    setPage(1);
                }
                setMsgLoading(false);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    return (
        <>
            <div className="bg-white rounded-xl shadow-md py-5">
                <div className="flex justify-between items-center px-5 border-b border-gray-200 pb-5">
                    <h3 className={`text-xl font-[500] font-poppins`}>Activity</h3>
                </div>

                <InfiniteScroll
                    height={'540px'}
                    next={handlePageChange}
                    dataLength={pageData.length}
                    hasMore={hasMoreContent}
                    loader={
                        <div className="mx-5">
                            <SkeletonTable columns={1} showZigZag />
                        </div>
                    }
                    endMessage={
                        <p className="text-center font-semibold text-xs underline underline-1">Start Conversation</p>
                    }
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        padding: '10px 20px',
                        flex: 1,
                        gap: '25px',
                        overflowY: 'auto',
                        justifyContent: pageData.length === 0 ? 'start' : 'revert'
                    }}
                    inverse={true}
                >
                    {pageData.map((msgObj, index) => (
                        <ActivityMsgView
                            key={msgObj.job_activity_id + ' ' + index}
                            data={msgObj}
                            loginUser={loginData}
                            bgColor={
                                msgObj.job_activity_id === job_activity_id ? 'animate-pulse-bg rounded-lg px-2' : ''
                            }
                        />
                    ))}
                </InfiniteScroll>

                <div className="chat-box w-full mt-5 px-5">
                    <ActivityInputBox handleSubmit={handleSubmit} isLoading={msgLoading} />
                </div>
            </div>
        </>
    );
};

export default Activity;
