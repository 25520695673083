import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BgDeleteIcon } from '../../../../utills/svgs/BgDeleteIcon';
import cloneDeep from 'lodash/cloneDeep';
import { archiveTaskStatusThunk, changeSequenceThunk, updateStatusThunk } from 'store/workspace/workspaceTasks';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { generateId } from 'utills/uid';
import ConfirmationModal from 'utills/confirmationModal';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { toastHandler } from 'responseHanlder';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { handleTaskStatus } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import useRoleGuard from 'Hooks/useRoleGuard';
import { Bars4Icon } from '@heroicons/react/24/outline';

const OSdynamicStatus = ({ filteredStatuses, getAllStatuses, toggleEditStatus }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [_statusLoader, setStatusLoader] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [task_status_id, setTaskStatus] = useState('');
    const { isLoading } = useSelector((state) => state.global);
    const { hasPermissions } = useRoleGuard();

    const handleToggle = (statusId) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        const findStatus = filteredStatuses?.find((status) => status.task_status_id === statusId);

        const payload = {
            task_status_id: statusId,
            business_id,
            partial_notification: findStatus.partial_notification === 'active' ? 'inactive' : 'active'
        };
        dispatch(updateStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setStatuses((prevStatuses) =>
                        prevStatuses.map((status) => {
                            if (status.task_status_id === statusId) {
                                return {
                                    ...status,
                                    partial_notification:
                                        status.partial_notification === 'active' ? 'inactive' : 'active'
                                };
                            }

                            return status;
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    function toggleInApp(statusId) {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        const findStatus = filteredStatuses?.find((status) => status.task_status_id === statusId);

        const payload = {
            task_status_id: statusId,
            business_id,
            in_app_notification: findStatus.in_app_notification === 'active' ? 'inactive' : 'active'
        };
        dispatch(updateStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setStatuses((prevStatuses) =>
                        prevStatuses.map((status) => {
                            if (status.task_status_id === statusId) {
                                return {
                                    ...status,
                                    in_app_notification: status.in_app_notification === 'active' ? 'inactive' : 'active'
                                };
                            }
                            return status;
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function toggleEmailRemainder(statusId) {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        const findStatus = filteredStatuses?.find((status) => status.task_status_id === statusId);

        const payload = {
            task_status_id: statusId,
            business_id,
            email_notification: findStatus.email_notification === 'active' ? 'inactive' : 'active'
        };

        dispatch(updateStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setStatuses((prevStatuses) =>
                        prevStatuses.map((status) => {
                            if (status.task_status_id === statusId) {
                                return {
                                    ...status,
                                    email_notification: status.email_notification === 'active' ? 'inactive' : 'active'
                                };
                            }
                            return status;
                        })
                    );
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        const payload = {
            business_id,
            task_status_id
        };

        setStatusLoader(true);
        dispatch(archiveTaskStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setStatuses((prevStatuses) =>
                        prevStatuses.filter((status) => status.task_status_id !== task_status_id)
                    );
                    const payload = {
                        business_id
                    };
                    getAllStatuses(payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setStatusLoader(false);
            });
    };
    const archiveStatus = (task_status_id) => {
        setIsConfirmationOpen(true);
        setTaskStatus(task_status_id);
    };

    useEffect(() => {
        setStatuses(cloneDeep(filteredStatuses));
    }, [filteredStatuses]);

    const onSortEnd = (oldIndex, newIndex) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        const newArray = arrayMoveImmutable(statuses, oldIndex, newIndex);
        setStatuses(newArray);

        const payload = {
            business_id,
            list: newArray
                .filter((status) => status.sequence !== 0)
                .map((status, index) => ({ task_status_id: status.task_status_id, sequence: index + 1 }))
        };

        setStatusLoader(true);
        dispatch(changeSequenceThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler(`Task Updated Successfully!`, TOAST_TYPE_SUCCESS);
                }
                setStatusLoader(false);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setStatusLoader(false);
            });
    };

    const handleEdit = (status) => {
        dispatch(handleTaskStatus(status));
        toggleEditStatus();
    };

    return (
        <div className="md:mx-10 mx-5 mb-5">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            {isLoading && <Loader />}
            <div className="  my-5 bg-white rounded-xl p-5">
                <div className="grid grid-cols-6 gap-5 primary-fonts text-md">
                    <div className="flex gap-2 col-span-2 items-center">
                        {/* <div>
                            <Bars4Icon className="w-5" />
                        </div> */}
                        <div>Status Name</div>
                    </div>
                    <div>Portal Notification</div>
                    <div>In-app Notification</div>
                    <div>Email Reminder</div>

                    <div>Action</div>
                </div>
                <div className="team-line team-streched-line mt-2"></div>
                <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                    {statuses &&
                        statuses?.map((status, index) => (
                            <SortableItem key={'stortable_item' + index} className="dragged">
                                <div key={generateId()}>
                                    <div className="grid grid-cols-6 gap-5 secondary-fonts text-md mt-3">
                                        <div className="flex gap-2 col-span-2 items-center">
                                            {status.sequence !== 0 ? (
                                                <div className="cursor-grab">
                                                    <SortableKnob>
                                                        <div>
                                                            <Bars4Icon className="w-5" />
                                                        </div>
                                                    </SortableKnob>
                                                </div>
                                            ) : (
                                                <div className="mx-3"></div>
                                            )}
                                            <div>{status?.label}</div>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                checked={status.partial_notification === 'active' ? true : false}
                                                className="os-react-switch-checkbox"
                                                id={`HandleToggle-${index} react-switch-new`}
                                                type="checkbox"
                                                onChange={() => handleToggle(status.id)}
                                            />

                                            <label
                                                style={{
                                                    background:
                                                        status.partial_notification === 'active' ? '#B695F8' : ''
                                                }}
                                                className="react-switch-label"
                                                htmlFor={`react-switch-new`}
                                                onClick={() => handleToggle(status.task_status_id)}
                                            >
                                                <span className={`react-switch-button`} />
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                checked={status.in_app_notification === 'active' ? true : false}
                                                className="os-react-switch-checkbox"
                                                id={`HandleToggle-${index} react-switch-new`}
                                                type="checkbox"
                                                onChange={() => toggleInApp(status.task_status_id)}
                                            />

                                            <label
                                                style={{
                                                    background: status.in_app_notification === 'active' ? '#B695F8' : ''
                                                }}
                                                className="react-switch-label"
                                                htmlFor={`react-switch-new`}
                                                onClick={() => toggleInApp(status.task_status_id)}
                                            >
                                                <span className={`react-switch-button`} />
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                checked={status.email_notification === 'active' ? true : false}
                                                className="os-react-switch-checkbox"
                                                id={`HandleToggle-${index} react-switch-new`}
                                                type="checkbox"
                                                onChange={() => toggleEmailRemainder(status.task_status_id)}
                                            />

                                            <label
                                                style={{
                                                    background: status.email_notification === 'active' ? '#B695F8' : ''
                                                }}
                                                className="react-switch-label"
                                                htmlFor={`react-switch-new`}
                                                onClick={() => toggleEmailRemainder(status.task_status_id)}
                                            >
                                                <span className={`react-switch-button`} />
                                            </label>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            {status?.can_delete && (
                                                <div
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        if (!hasPermissions('Tasks', ['admin'])) {
                                                            return toastHandler(
                                                                'You do not have permission to perform this action.',
                                                                TOAST_TYPE_ERROR
                                                            );
                                                        }
                                                        handleEdit(status);
                                                    }}
                                                >
                                                    <BgEditIcon />
                                                </div>
                                            )}

                                            {status?.can_delete && (
                                                <div
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        if (!hasPermissions('Tasks', ['admin'])) {
                                                            return toastHandler(
                                                                'You do not have permission to perform this action.',
                                                                TOAST_TYPE_ERROR
                                                            );
                                                        }
                                                        archiveStatus(status.task_status_id);
                                                    }}
                                                >
                                                    <BgDeleteIcon />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="team-line team-streched-line mt-3"></div>
                                </div>
                            </SortableItem>
                        ))}
                </SortableList>
            </div>
        </div>
    );
};
export default OSdynamicStatus;
