import { ChevronRightIcon } from '@heroicons/react/20/solid';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import React from 'react';
import { useDrag } from 'react-dnd';
import { formatFileSize } from 'utills/filesHandlers';
import { DateFormatToLocal } from 'utills/moment';
import { FolderIcon } from 'utills/svgs/file-icons/FolderIcon';
import { cn } from 'utills/tailwindUtil';

const DirectoryItem = ({ checkPDFORFolder, dataObj, handleInternalClick, list, handleSelectId, setSelectedPdf }) => {
    const [{ isDragging }, ref] = useDrag({
        type: 'DIV',
        item: {
            ...dataObj
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    return (
        <div
            className={cn('flex items-center justify-between my-2 hover:bg-gray-100 rounded-lg pl-2 ')}
            ref={dataObj?.mimetype?.includes('pdf') ? ref : null}
            style={{ opacity: isDragging ? 1 : 1 }}
        >
            <div
                className={cn(
                    'flex items-center gap-4 cursor-pointer',
                    !checkPDFORFolder(dataObj) && '!opacity-25 pointer-events-none'
                )}
                onClick={() => {
                    if (checkPDFORFolder(dataObj) && !dataObj?.mimetype?.includes('pdf')) {
                        handleInternalClick({
                            job_drive_id: dataObj?.job_drive_id,
                            name: dataObj?.name
                        });
                    }
                }}
            >
                {dataObj?.mimetype?.includes('pdf') && (
                    <div className="min-w-[18px]">
                        <input
                            type="checkbox"
                            className="rounded-[3px] cursor-pointer"
                            checked={list?.some((item) => item.job_drive_id === dataObj.job_drive_id) || false}
                            onChange={() => handleSelectId(dataObj)}
                        />
                    </div>
                )}
                {dataObj?.type === 'file' ? (
                    <GenerateFileIcon url={dataObj?.url} onClick={() => setSelectedPdf(dataObj?.url)} sx={'w-10'} />
                ) : (
                    <FolderIcon className={'w-10'} />
                )}

                <div className="flex flex-col">
                    <div className="app-dataObj-name flex items-center gap-2 cursor-grab">
                        <div>{dataObj?.name}</div>
                    </div>
                    <div className="recently-added text-gray-600 text-xs app-dataObj-email break-all">
                        {dataObj?.size && dataObj?.size !== 'null' ? formatFileSize(dataObj?.size / 1024 ?? 0) : '__'}{' '}
                        &bull;{' '}
                        {dataObj?.updated_at
                            ? DateFormatToLocal({
                                  date: dataObj?.updated_at,
                                  format: 'DD MMM, YYYY'
                              })
                            : '__'}
                    </div>
                </div>
            </div>
            <div>{dataObj?.type === 'folder' && <ChevronRightIcon className="w-6 h-7 text-[#979797]" />}</div>
        </div>
    );
};

export default DirectoryItem;
