import React, { useState } from 'react';
import MyThreads from '../../components/workplace-content/workplace-threads/MyThreads';
import MyNewThread from '../../components/workplace-content/workplace-threads/my-threads/MyNewThread';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { SidebarLayout } from 'layouts/SidebarLayout';
import useRoleGuard from 'Hooks/useRoleGuard';

const Threads = () => {
    const [showMyNewThread, setShowMyNewThread] = useState(false);

    const { hasPermissions } = useRoleGuard();

    const toggleMyNewThread = () => {
        if (hasPermissions('Threads', ['write', 'admin'])) {
            setShowMyNewThread(!showMyNewThread);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showMyNewThread && <MyNewThread toggleMyNewThread={toggleMyNewThread} />}
                <div>
                    {hasPermissions('Threads', ['read', 'write', 'admin']) ? (
                        <MyThreads toggleMyNewThread={toggleMyNewThread} />
                    ) : (
                        <div className="w-full h-72 flex justify-center items-center font-poppins">
                            {PERMISSIONS_MESSAGE}
                        </div>
                    )}
                </div>
            </SidebarLayout>
        </div>
    );
};

export default Threads;
