/* eslint-disable no-unused-vars */
import Slideover from 'components/atoms/SlideOver';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import React from 'react';
import DocIcon from 'utills/svgs/DocIcon';
import { JobManagerIcon2 } from 'utills/svgs/JobManagerIcon2';
import { LargeTaskIcon } from 'utills/svgs/LargeTaskIcon';
import { ProfileIcon } from 'utills/svgs/ProfileIcon';
import { SearchIcon } from 'utills/svgs/SearchIcon';

const EmailManagerThreadDetailsMenu = ({ parentWidth, open, setOpen }) => {
    console.info('parentWidth', parentWidth);

    const Content = () => {
        return (
            <div>
                <div className="space-y-3 mt-3 px-1">
                    <CollapsDivComponent
                        h_two="max-h-[2300px]"
                        defaultClose
                        title={'Profile'}
                        titleIcon={<ProfileIcon className="w-[20px] h-[20px] !fill-none !stroke-1" />}
                        headSx="py-4 px-3 border-b  bg-[#F8F4FE] rounded-xl"
                        classes="!shadow-sm"
                        titleSx="!font-normal !text-sm"
                    ></CollapsDivComponent>
                    <CollapsDivComponent
                        h_two="max-h-[2300px]"
                        defaultClose
                        title={'Tasks'}
                        titleIcon={<LargeTaskIcon className="w-[20px] h-[20px] !fill-none !stroke-1" />}
                        headSx="py-4 px-3 border-b  bg-[#F8F4FE] rounded-xl"
                        classes="!shadow-sm"
                        titleSx="!font-normal !text-sm"
                    ></CollapsDivComponent>
                    <CollapsDivComponent
                        h_two="max-h-[2300px]"
                        defaultClose
                        title={'Jobs'}
                        titleIcon={<JobManagerIcon2 className="w-[20px] h-[20px] !stroke-1 " />}
                        headSx="py-4 px-3 border-b  bg-[#F8F4FE] rounded-xl"
                        classes="!shadow-sm"
                        titleSx="!font-normal !text-sm"
                    ></CollapsDivComponent>
                    <CollapsDivComponent
                        h_two="max-h-[2300px]"
                        defaultClose
                        title={'Doc Management'}
                        titleIcon={<DocIcon className="w-[20px] h-[20px] !stroke-1 " />}
                        headSx="py-4 px-3 border-b  bg-[#F8F4FE] rounded-xl"
                        classes="!shadow-sm"
                        titleSx="!font-normal !text-sm"
                    ></CollapsDivComponent>
                    <CollapsDivComponent
                        h_two="max-h-[2300px]"
                        defaultClose
                        title={'Search Emails'}
                        titleIcon={<SearchIcon className="w-[20px] h-[20px] !stroke-1 " />}
                        headSx="py-4 px-3 border-b  bg-[#F8F4FE] rounded-xl"
                        classes="!shadow-sm"
                        titleSx="!font-normal !text-sm"
                    ></CollapsDivComponent>
                </div>
            </div>
        );
    };

    return (
        <>
            {/* Main content */}
            {parentWidth >= 1550 ? (
                <div className="min-w-[250px] w-[300px] bg-white rounded-xl px-2 py-2 shadow-md transition-all duration-300 h-full overflow-auto">
                    <Content />
                </div>
            ) : (
                <div className="">
                    <Slideover open={open} setOpen={setOpen}>
                        <Content />
                    </Slideover>
                </div>
            )}
        </>
    );
};

export default EmailManagerThreadDetailsMenu;
