import React, { useState } from 'react';
import AppointmentSettingsPage from '../../../components/workplace-content/appointment-reminder-pages/appointment-settings-pages/AppointmentSettingsPage';
import DefaultReminderSettings from '../../../components/workplace-content/appointment-reminder-pages/appointment-settings-pages/DefaultReminderSettings';
const AppointmentSettings = () => {
    const [showDefaultReminderSettings, setShowDefaultReminderSettings] = useState(true);

    const toggleDefaultReminderSettings = () => {
        setShowDefaultReminderSettings(!showDefaultReminderSettings);
    };

    return (
        <div className="app-dashboard">
            {showDefaultReminderSettings && (
                <DefaultReminderSettings toggleDefaultReminderSettings={toggleDefaultReminderSettings} />
            )}

            <div className={`content ${''}`}>
                <AppointmentSettingsPage />
            </div>
        </div>
    );
};

export default AppointmentSettings;
