import { motion } from 'framer-motion';
import moment from 'moment';
import './addtask.css';
import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SideTimes } from '../../../../utills/svgs/SideTimes';
import { generateId } from 'utills/uid';

import { DimmedDeleteIcon } from 'utills/svgs/DimmedDeleteIcon';
import { ImageIcon } from 'utills/svgs/ImageIcon';
import { BgTimes } from 'utills/svgs/BgTimes';
import { LinkIcon } from 'utills/svgs/LinkIcon';
import { useDispatch, useSelector } from 'react-redux';
import pdfIcon from '../../../../files/pdfIcon.svg';
import { addTaskThunk, getAllTaskStatusesThunk, getAllTaskTypeThunk } from 'store/workspace/workspaceTasks';
import { BUSINESS_ID, ERROR_TYPE_ERROR, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS, USER_TYPE } from 'utills/globalVars';
import { setSideLoader } from 'store/global/globalReducer';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import { fetchBusinessCustomerAllThunk } from 'store/client';
import { toastHandler } from 'responseHanlder';
import { WhiteBgTimes } from 'utills/svgs/WhiteBgTimes';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import DatePickerComponent from 'global-components/DatePicker';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import TextEditor from 'components/molecules/TextEditor';

const AddTask = ({ toggleAddTask, handleTasksUpdate }) => {
    const dispatch = useDispatch();
    const { data: customers } = useSelector((state) => state.fetchBusinessAllCustomer);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [editorContent, setEditorContent] = useState('');
    const [checklists, setChecklists] = useState([]);
    const [listname, setListName] = useState('');

    const [showAddItem, setShowAddItem] = useState(false);
    const [addItemIndex, setAddItemIndex] = useState(null);
    const [itemName, setItemName] = useState('');
    const [delIconIndex, setDelIconIndex] = useState(null);
    const [showDelIcon, setShowDelIcon] = useState(false);
    const [listIndex, setListIndex] = useState(null);

    const { sideLoader } = useSelector((state) => state.global);
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);

    const { status } = useSelector((state) => state.global);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [title, setTitle] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [reference_number, setReferenceNumber] = useState('');
    const [start_time, setStartTime] = useState(new Date());
    const [end_time, setEndTime] = useState(null);
    const [files, setFiles] = useState([]);
    const [pdf, setPdf] = useState(null);
    const { data: taskTypes } = useSelector((state) => state.getAllTaskType);
    const { data: taskStatuses } = useSelector((state) => state.getAllTaskStatuses);

    const [taskType, setTaskType] = useState('');
    const [taskStatus, setTaskStatus] = useState('');

    const toggleAddChecklist = () => {
        const newArr = [...checklists];
        newArr.push({
            checklist_name: '',
            checks: []
        });
        setChecklists(newArr);
    };
    const handleChecklistName = (index) => {
        const newArr = [...checklists];
        newArr[index].checklist_name = listname;
        setChecklists(newArr);
        setListName('');
    };

    const addItem = (index) => {
        setAddItemIndex(index);
        setShowAddItem(!showAddItem);
    };

    const handleItemName = (index) => {
        const newArr = [...checklists];
        newArr[index].checks.push({
            item_id: generateId(),
            value: itemName,
            is_completed: false
        });
        setChecklists(newArr);
        setShowAddItem(false);
    };

    const deleteItem = (index, checkIndex) => {
        const newArr = [...checklists];
        newArr[index].checks.splice(checkIndex, 1);
        setChecklists(newArr);
    };

    const handleDelIcon = (index, checkIndex) => {
        setListIndex(index);
        setDelIconIndex(checkIndex);
        setShowDelIcon(true);
    };
    const hideDelIcon = () => {
        setShowDelIcon(false);
    };

    const handleShowListDel = (index) => {
        setListIndex(index);
    };

    const handleHideListDel = () => {};

    const handleCheckChange = (e, index, checkIndex) => {
        const checked = e.target.checked;
        const newArr = [...checklists];
        if (newArr[index].checks[checkIndex]) {
            newArr[index].checks[checkIndex].is_completed = checked;
        }
        setChecklists(newArr);
    };

    const handleListDelete = (index) => {
        const newArr = [...checklists];
        newArr.splice(index, 1);
        setChecklists(newArr);
    };

    const [selectedImages, setSelectedImages] = useState([]);

    const handleFileSelect = (e) => {
        const files = e.target.files;

        const newFiles = [...files, files];

        if (files.length > 0) {
            const files = e.target.files;

            const updatedImages = [...selectedImages];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                const reader = new FileReader();

                reader.onloadend = () => {
                    updatedImages.push(reader.result);

                    setSelectedImages([...updatedImages]);
                };

                reader.readAsDataURL(file);
            }
        }

        setFiles(newFiles);
    };

    const handlePdfSelect = (e) => {
        const file = e.target.files[0];
        setPdf(file);
    };

    const fetchCustomers = () => {
        const payload = {
            business_id,
            client_type: 'all_client',
            customer_type: false,
            is_deleted: true
        };

        dispatch(fetchBusinessCustomerAllThunk(payload)).catch((error) => {
            console.error(error);
        });
    };

    const fetchEmployees = () => {
        dispatch(fetchRegisteredEmployeesThunk())
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                fetchCustomers();
            });
    };

    const getStatuses = (payload) => {
        dispatch(getAllTaskStatusesThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                fetchEmployees();
            });
    };

    const getTypes = (payload) => {
        dispatch(getAllTaskTypeThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                getStatuses(payload);
            });
    };

    useEffect(() => {
        const business_id = localStorage.getItem(BUSINESS_ID);
        const payload = {
            business_id,
            page: 1,
            pageSize: 1000,
            sort_by: 'ASC'
        };
        getTypes(payload);
    }, []);

    const handleSelectChange = (e) => {
        const newOptions = [...selectedOptions];
        const value = e.target.value;
        const employee = employees?.find((emp) => emp.user_id === value);
        const findDuplicate = selectedOptions?.find((emp) => emp.user_id === value);
        if (findDuplicate) {
            toastHandler('You cannot select one option multiple times', ERROR_TYPE_ERROR);
            return;
        }
        newOptions.push({
            label: employee.first_name,
            value
        });

        setSelectedOptions(newOptions);
    };

    const options = [];
    employees?.map((emp) => {
        options.push({
            value: emp.user_id,
            label: emp.first_name
        });
    });

    const removeOption = (user_id) => {
        const newArr = [...selectedOptions];
        const findOption = newArr.find((op) => op.user_id === user_id);
        const index = newArr.indexOf(findOption);
        newArr.splice(index, 1);
        setSelectedOptions(newArr);
    };

    const handleCustomer = (e) => {
        const { value } = e.target;
        const findcustomer = customers?.find((customer) => customer.customer_id === value);
        setSelectedCustomer({
            label: findcustomer?.first_name,
            value
        });
    };

    const defAddTask = () => {
        const employees = [];
        selectedOptions.forEach((option) => {
            employees.push(option.value);
        });

        const formData = new FormData();
        formData.append('business_id', business_id.trim());
        formData.append('task_type_id', taskType.trim());
        formData.append('task_status_id', taskStatus.trim());
        formData.append('title', title.trim());
        formData.append('description', editorContent.trim());

        formData.append('start_date', '10-10-2024');
        formData.append('end_date', '12-11-2024');
        const formatStartTime = moment(start_time).format('HH:mm:ss Z');
        const formatEndTime = moment(end_time).format('HH:mm:ss Z');
        formData.append('start_time', formatStartTime);
        formData.append('end_time', formatEndTime);
        formData.append('reference_number', reference_number.trim());
        formData.append('customer_id', selectedCustomer?.value.trim());
        formData.append('employees', JSON.stringify(employees));
        formData.append('checklists', JSON.stringify(checklists));

        formData.append('uploaded_by', USER_TYPE);

        if (files.length > 4) {
            toastHandler('You Cannot select more than 3 files', TOAST_TYPE_ERROR);
            return;
        }
        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append('task_files', files[i]);
            }
        }
        dispatch(setSideLoader(true));
        dispatch(addTaskThunk(formData))
            .then((response) => {
                if (response.payload) {
                    handleTasksUpdate();
                    toggleAddTask();
                    toastHandler('Task Added', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    useEffect(() => {
        if (status) {
            setTaskStatus(status?.task_status_id);
        }
    }, []);

    const handleFileRemove = (index) => {
        const newArr = [...files];
        newArr.splice(index, 1);
        setFiles(newArr);
    };

    const items = [];
    if (files) {
        for (let i = 0; i < files.length - 1; i++) {
            items.push(
                <button className="flex items-center gap-1 selected-file ">
                    <LinkIcon />
                    <span>{files[i]?.name?.slice(0, 10)}</span>
                    <span onClick={() => handleFileRemove(files[i])} className="cursor-pointer">
                        <BgTimes />
                    </span>{' '}
                </button>
            );
        }
    }

    const [isOpen, setIsOpen] = useState(false);
    const [endOpen, setEndOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleFileRemoveImg = (index) => {
        const newArr = [...files];
        const newImages = [...selectedImages];
        newImages.splice(index, 1);
        setSelectedImages(newImages);
        newArr.splice(index, 1);
        setFiles(newArr);
    };
    return (
        <div className="add-p-side grid grid-cols-10 ">
            <div className="col-span-2 left-side"></div>
            <div className="right-side col-span-8 ">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative w-full"
                >
                    <div>
                        <div onClick={toggleAddTask} className="absolute   text-white p-2 right-1 top-1 cursor-pointer">
                            <SideTimes />
                        </div>

                        <div className="add-detail pt-5 px-5 text-black">
                            <div className="title  text-black">Add Task</div>

                            <div className="jumbo-dir mt-2=1">
                                Workspace &gt; Tasks <span className="special-jumbo-text"> &gt; Add Task</span>
                            </div>
                        </div>
                    </div>

                    <div className="add-task-container  px-5 mt-3 text-black">
                        <div className=" add-ann-form">
                            <div className="bg-white shadow-lg rounded-md p-5 ">
                                <div className="mt-1">
                                    <div className="flex items-center gap-5">
                                        <div>
                                            <div>
                                                <label>Start Date</label>
                                            </div>

                                            <div>
                                                <div className="mt-1">
                                                    <DatePickerComponent
                                                        type="date"
                                                        sidebarDate={true}
                                                        isOpen={isOpen}
                                                        date={startDate}
                                                        setVisible={(value) => setIsOpen(value)}
                                                        onDateChange={(date) => {
                                                            setIsOpen(false);
                                                            setStartDate(date);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>End Date</label>
                                            </div>
                                            <div>
                                                <div className="mt-1">
                                                    <DatePickerComponent
                                                        type="date"
                                                        sidebarDate={true}
                                                        minDate={new Date()}
                                                        isOpen={endOpen}
                                                        date={endDate}
                                                        setVisible={(value) => setEndOpen(value)}
                                                        onDateChange={(date) => {
                                                            setEndOpen(false);
                                                            setEndDate(date);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>Start Time</label>
                                            </div>
                                            <div>
                                                <div className="">
                                                    <DatePicker
                                                        selected={start_time}
                                                        onChange={(date) => setStartTime(date)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <label>End Time</label>
                                            </div>
                                            <div>
                                                <div className="">
                                                    <DatePicker
                                                        selected={end_time}
                                                        onChange={(date) => setEndTime(date)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div>
                                        <label>Title</label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={(e) => setTitle(e.target.value.trim())}
                                            type="text"
                                            placeholder="Title"
                                            className="px-3"
                                            maxLength={40}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label>Description</label>
                                </div>

                                <div>
                                    <TextEditor
                                        onChange={(value) => setEditorContent(value)}
                                        hideFonts={true}
                                        initialValue={editorContent}
                                    />
                                </div>
                            </div>

                            <div className="add-task-checklist mt-3 grid grid-cols-2  bg-white shadow-lg rounded-md p-5">
                                <div>
                                    <div className="bolder-label">Checklist</div>
                                    <div>
                                        {checklists &&
                                            checklists?.map((list, index) => (
                                                <div key={index} className={`${index > 0 && ' mt-2'}`}>
                                                    {!list.checklist_name ? (
                                                        <div>
                                                            <label>Checklist Name</label>
                                                            <div className="flex gap-2 items-center">
                                                                {' '}
                                                                <input
                                                                    onChange={(e) => setListName(e.target.value)}
                                                                    value={listname}
                                                                    type="text"
                                                                />{' '}
                                                                <button
                                                                    onClick={() => handleChecklistName(index)}
                                                                    className="ann-btn px-3 py-2 rounded-md mt-2"
                                                                >
                                                                    Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <label> Checklist Title</label>{' '}
                                                            <div
                                                                onMouseEnter={() => handleShowListDel(index)}
                                                                onMouseLeave={() => handleHideListDel()}
                                                                className="flex items-center gap-2 mt-1"
                                                            >
                                                                <div className="checklist-name-div flex items-center">
                                                                    {list.checklist_name}{' '}
                                                                </div>
                                                                <div
                                                                    onClick={() => handleListDelete(index)}
                                                                    className="cursor-pointer"
                                                                >
                                                                    <BgDeleteIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div>
                                                        <div>
                                                            {list?.checks?.length > 0 && (
                                                                <div>
                                                                    {list?.checks.map((check, checkIndex) => (
                                                                        <div
                                                                            key={checkIndex}
                                                                            className=" mt-1 p-0 h-7"
                                                                            onMouseEnter={() =>
                                                                                handleDelIcon(index, checkIndex)
                                                                            }
                                                                            onMouseLeave={hideDelIcon}
                                                                        >
                                                                            <div className="flex justify-between items-center">
                                                                                {' '}
                                                                                <div className=" flex items-center gap-2">
                                                                                    <div>
                                                                                        {' '}
                                                                                        <input
                                                                                            onChange={(e) =>
                                                                                                handleCheckChange(
                                                                                                    e,
                                                                                                    index,
                                                                                                    checkIndex
                                                                                                )
                                                                                            }
                                                                                            type="checkbox"
                                                                                        />{' '}
                                                                                    </div>
                                                                                    <div className="check-item-name">
                                                                                        {check?.value}{' '}
                                                                                    </div>
                                                                                </div>{' '}
                                                                                <div
                                                                                    onClick={() =>
                                                                                        deleteItem(index, checkIndex)
                                                                                    }
                                                                                    className="cursor-pointer"
                                                                                >
                                                                                    {' '}
                                                                                    {showDelIcon &&
                                                                                    delIconIndex === checkIndex &&
                                                                                    listIndex === index ? (
                                                                                        <DimmedDeleteIcon />
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>

                                                        {showAddItem && addItemIndex === index ? (
                                                            <div className="mt-2 add-ann-form">
                                                                <div>
                                                                    {' '}
                                                                    <label>Add an Item</label>{' '}
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    onChange={(e) => setItemName(e.target.value)}
                                                                />
                                                                <div className="flex items-center gap-3 mt-3">
                                                                    <button
                                                                        onClick={() => handleItemName(index)}
                                                                        className="ann-btn px-3 py-2 rounded-md "
                                                                    >
                                                                        Add
                                                                    </button>
                                                                    <button
                                                                        className="checklist-cancel-btn px-5 py-2"
                                                                        onClick={() => addItem(index)}
                                                                    >
                                                                        Cancel
                                                                    </button>{' '}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="mt-5  items-center">
                                                        {showAddItem && addItemIndex === index ? (
                                                            ''
                                                        ) : (
                                                            <button
                                                                disabled={!list?.checklist_name ? true : false}
                                                                className="ann-btn rounded-md  px-5 py-2"
                                                                onClick={() => addItem(index)}
                                                            >
                                                                Add Item
                                                            </button>
                                                        )}
                                                    </div>

                                                    <hr className="mt-2"></hr>
                                                </div>
                                            ))}

                                        <button
                                            onClick={toggleAddChecklist}
                                            className="ann-btn px-3 py-2 rounded-md mt-2"
                                        >
                                            + Add Checklist
                                        </button>
                                    </div>{' '}
                                </div>
                            </div>

                            <div className="task-attachments add-task-checklist mt-3 grid grid-cols-2 gap-5 bg-white shadow-lg rounded-md p-5">
                                <div>
                                    <div className="bolder-label">Attachments</div>

                                    <div className="mt-1">
                                        <label>Upload Image</label>
                                    </div>

                                    <div>
                                        <div>
                                            <div className=" add-ann-img mt-2 flex justify-center ">
                                                <label htmlFor="file-input" className="upload-app-label">
                                                    <div className="add-app-camera flex justify-center">
                                                        <ImageIcon />
                                                    </div>
                                                    <div className="primary-fonts text-md mt-2">
                                                        Click to upload app image
                                                    </div>
                                                </label>
                                            </div>
                                            <input
                                                id="file-input"
                                                type="file"
                                                multiple
                                                accept="image/*"
                                                onChange={handleFileSelect}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>

                                    {selectedImages.length > 0 && (
                                        <div>
                                            <div className="flex items-center flex-wrap gap-2">
                                                {selectedImages.map((image, index) => (
                                                    <div
                                                        key={'selectedImage' + index}
                                                        className="my-3 thumbnail-wrapper relative "
                                                    >
                                                        <div className="absolute right-1 top-1">
                                                            <div
                                                                onClick={() => handleFileRemoveImg(index)}
                                                                className="cursor-pointer"
                                                            >
                                                                {' '}
                                                                <BgTimes />{' '}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img
                                                                src={image}
                                                                alt="alt"
                                                                className="thumbnail-image cursor-pointer"
                                                                onClick={() => {
                                                                    setShowModal(true);
                                                                }}
                                                            />
                                                            {showModal && (
                                                                <ZoomImage
                                                                    src={image}
                                                                    alt="snow"
                                                                    onClose={() => setShowModal(false)}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="add-ann-form text-white flex justify-center items-center">
                                                            {' '}
                                                            <label>{files[index]?.name?.slice(0, 7)}</label>{' '}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className="bolder-label invisible">Attachments</div>

                                    <div className="mt-1">
                                        <label>Upload Document</label>
                                    </div>

                                    <div>
                                        <div>
                                            <div className=" add-ann-img mt-2 ">
                                                <label htmlFor="file-input-pdf" className="upload-app-label">
                                                    <div className="add-app-camera flex justify-center">
                                                        <ImageIcon />
                                                    </div>
                                                    <div className="add-app-camera-text mt-2">
                                                        Click to upload app Document
                                                    </div>
                                                </label>
                                            </div>

                                            <input
                                                id="file-input-pdf"
                                                type="file"
                                                accept="application/pdf"
                                                onChange={handlePdfSelect}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-3 gap-3">
                                        {' '}
                                        {pdf && (
                                            <div className=" add-ann-pdf mt-2 relative mb-2  ">
                                                <div className="absolute right-1 top-1">
                                                    <div onClick={() => setPdf(null)} className="cursor-pointer">
                                                        {' '}
                                                        <BgTimes />{' '}
                                                    </div>
                                                </div>
                                                <label className="upload-app-label">
                                                    <div className="add-app-camera flex justify-center">
                                                        <img
                                                            src={pdfIcon}
                                                            alt="alt"
                                                            style={{ height: 30, width: 30 }}
                                                        />
                                                    </div>
                                                    <div className="add-app-camera-text mt-2">
                                                        {pdf?.name?.slice(0, 8)}
                                                    </div>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="grid md:grid-cols-2 gap-5">
                                    <div>
                                        {files &&
                                            files.map((file, index) => {
                                                return (
                                                    <button key={index} className="flex items-center gap-2 files-list">
                                                        <WhiteBgTimes />
                                                    </button>
                                                );
                                            })}
                                    </div>
                                    <div></div>
                                </div>
                            </div>

                            <div className="add-task-checklist mt-3 grid md:grid-cols-2 gap-5 bg-white shadow-lg rounded-md p-5">
                                <div>
                                    <div className="mt-2">
                                        <label>Task Type</label>
                                    </div>
                                    <div className="mt-1">
                                        <select onChange={(e) => setTaskType(e.target.value)}>
                                            <option value="" selected disabled>
                                                Select
                                            </option>
                                            {taskTypes &&
                                                taskTypes?.rows.map((type) => (
                                                    <option value={type.task_type_id} key={type.task_type_id}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div>
                                        <div className="mt-2">
                                            <label>Reference Number</label>
                                        </div>
                                        <div className="mt-1">
                                            {' '}
                                            <input
                                                type="number"
                                                className="px-2"
                                                placeholder="Reference Number"
                                                onChange={(e) => setReferenceNumber(e.target.value)}
                                            />{' '}
                                        </div>
                                    </div>

                                    <div className="mt-2">
                                        <label>Assign to Employee</label>

                                        <div className="select-employee-wrapper">
                                            <select onChange={handleSelectChange} className="select-employee-select">
                                                <option selected disabled>
                                                    Select
                                                </option>
                                                {employees &&
                                                    employees.map((emp) => (
                                                        <option value={emp.user_id} key={emp.user_id}>
                                                            {emp.first_name}
                                                        </option>
                                                    ))}
                                            </select>
                                            <div className="select-hider"></div>
                                            <div className="selected-employees flex gap-2  items-center">
                                                {selectedOptions
                                                    ?.reverse()
                                                    .slice(0, 2)
                                                    .map((op, index) => (
                                                        <div
                                                            className="flex add-task-file items-center px-2 py-1 gap-2"
                                                            key={generateId() + index}
                                                        >
                                                            <div>{op.label}</div>
                                                            <div
                                                                onClick={() => removeOption(op.value)}
                                                                className="cursor-pointer"
                                                            >
                                                                <BgTimes />
                                                            </div>
                                                        </div>
                                                    ))}
                                                {selectedOptions?.length > 2 && (
                                                    <span className="option-more">
                                                        {selectedOptions.length - 2} More
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="mt-2">
                                        <label>Task Status</label>
                                    </div>
                                    <div className="mt-1">
                                        <select onChange={(e) => setTaskStatus(e.target.value)}>
                                            <option value={status?.task_status_id} selected>
                                                {status?.label}
                                            </option>
                                            {taskStatuses &&
                                                taskStatuses?.map((type) => (
                                                    <option value={type.task_status_id} key={type.task_status_id}>
                                                        {type.label}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="mt-2">
                                        <label>Add to client</label>

                                        <div className="select-employee-wrapper">
                                            <select onChange={handleCustomer} className="select-employee-select">
                                                <option selected>Select</option>
                                                {customers &&
                                                    customers?.map((emp) => (
                                                        <option value={emp.customer_id} key={emp.customer_id}>
                                                            {emp.first_name}
                                                        </option>
                                                    ))}
                                            </select>
                                            <div className="select-hider"></div>
                                            <div className="selected-employees flex gap-2  items-center">
                                                {selectedCustomer && (
                                                    <div
                                                        className="flex add-task-file items-center px-2 py-1 gap-2"
                                                        key={generateId()}
                                                    >
                                                        <div>{selectedCustomer?.label}</div>
                                                        <div
                                                            onClick={() => setSelectedCustomer(null)}
                                                            className="cursor-pointer"
                                                        >
                                                            <BgTimes />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="task-add-btn-wrap">
                                <button onClick={defAddTask} className="flex items-center gap-2">
                                    Add {sideLoader && <SmallLoaderWhite />}
                                </button>
                            </div>

                            <div className="mb-5"></div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default AddTask;
