import { BgDateIcon } from 'utills/svgs/BgDateIcon';
import { BgEmailIcon } from 'utills/svgs/BgEmailIcon';
import { BgHomeIcon } from 'utills/svgs/BgHomeIcon';
import { BgLocationIcon } from 'utills/svgs/BgLocationIcon';
import { BgPhoneIcon } from 'utills/svgs/BgPhoneIcon';
import { BgTaxIcon } from 'utills/svgs/BgTaxIcon';
import { BgUserIcon } from 'utills/svgs/BgUserIcon';
export const SUMMARY = [
    {
        ref_no: 'REF001',
        expense_reported: 150.5,
        income_reported: 400,
        date_and_time: '2024-01-10T12:30:00'
    },
    {
        ref_no: 'REF002',
        expense_reported: 200.75,
        income_reported: 400,
        date_and_time: '2024-01-11T14:45:00'
    },
    {
        ref_no: 'REF003',
        expense_reported: 75.2,
        income_reported: 400,
        date_and_time: '2024-01-12T08:00:00'
    },
    {
        ref_no: 'REF004',
        expense_reported: 120.0,
        income_reported: 400,
        date_and_time: '2024-01-13T16:20:00'
    },
    {
        ref_no: 'REF005',
        expense_reported: 90.8,
        income_reported: 400,
        date_and_time: '2024-01-14T10:10:00'
    },
    {
        ref_no: 'REF006',
        expense_reported: 180.3,
        income_reported: 400,
        date_and_time: '2024-01-15T09:30:00'
    }
];

export const initialFiles = [
    {
        file_name: 'File 1',
        created_at: '2024-04-03',
        uploaded_by: 'User 1',
        status: 'pending'
    },
    {
        file_name: 'File 2',
        created_at: '2024-04-03',
        uploaded_by: 'User 2',
        status: 'in_progress'
    },
    {
        file_name: 'File 3',
        created_at: '2024-04-03',
        uploaded_by: 'User 3',
        status: 'errored'
    },
    {
        file_name: 'File 4',
        created_at: '2024-04-03',
        uploaded_by: 'User 4',
        status: 'completed'
    }
];
export const SUMMARY_ONE = [
    {
        ref_no: 'REF001',
        expense_reported: 150.5,
        date_and_time: '2024-01-10T12:30:00'
    },
    {
        ref_no: 'REF002',
        expense_reported: 200.75,
        date_and_time: '2024-01-11T14:45:00'
    },
    {
        ref_no: 'REF003',
        expense_reported: 75.2,
        date_and_time: '2024-01-12T08:00:00'
    },
    {
        ref_no: 'REF004',
        expense_reported: 120.0,
        date_and_time: '2024-01-13T16:20:00'
    },
    {
        ref_no: 'REF005',
        expense_reported: 90.8,
        date_and_time: '2024-01-14T10:10:00'
    },
    {
        ref_no: 'REF006',
        expense_reported: 180.3,
        date_and_time: '2024-01-15T09:30:00'
    }
];
export const DETAILS = [
    {
        icon: BgUserIcon,
        key: 'Name',
        value: 'alim'
    },
    {
        icon: BgTaxIcon,
        key: 'Tax File No',
        value: '04545454545'
    },
    {
        icon: BgEmailIcon,
        key: 'Email',
        value: 'ali@gmail.com'
    },
    {
        icon: BgPhoneIcon,
        key: 'Phone',
        value: '045454545'
    },
    {
        icon: BgDateIcon,
        key: 'Date Of Birth',
        value: '22-10-2023'
    },
    {
        icon: BgHomeIcon,
        key: 'Australian Resident',
        value: 'Yes'
    },
    {
        icon: BgLocationIcon,
        key: 'Residential Address',
        value: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert, MO 65584-5678'
    },
    {
        icon: BgLocationIcon,
        key: 'Postal Address',
        value: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert, MO 65584-5678'
    }
];
export const EXPENSE_ARRAY = [
    {
        id: 1,
        Description: 'Office Supplies ',
        Expense: 200.5,
        GST: 20.05,
        Type: 'Stationery',
        Date: '2024-01-10',
        Image: 'path/to/image1.jpg'
    },
    {
        id: 2,
        Description: 'Client Dinner',
        Expense: 350.75,
        GST: 35.08,
        Type: 'Entertainment',
        Date: '2024-01-11',
        Image: 'path/to/image2.jpg'
    },
    {
        id: 3,
        Description: 'Travel Expenses',
        Expense: 120.2,
        GST: 12.02,
        Type: 'Transportation',
        Date: '2024-01-12',
        Image: 'path/to/image3.jpg'
    },
    {
        id: 4,
        Description: 'Equipment Purchase',
        Expense: 800.0,
        GST: 80.0,
        Type: 'Assets',
        Date: '2024-01-13',
        Image: 'path/to/image4.jpg'
    },
    {
        id: 5,
        Description: 'Training Workshop',
        Expense: 450.8,
        GST: 45.08,
        Type: 'Education',
        Date: '2024-01-14',
        Image: 'path/to/image5.jpg'
    },
    {
        id: 6,
        Description: 'Marketing Campaign',
        Expense: 600.3,
        GST: 60.03,
        Type: 'Marketing',
        Date: '2024-01-15',
        Image: 'path/to/image6.jpg'
    }
];
export const ABN_DETAILS = [
    {
        business_name: 'ABC Services',
        business_type: 'Consulting',
        abn_number: '12345678901',
        business_address: '123 Main Street, Cityville'
    },
    {
        business_name: 'XYZ Tech Solutions',
        business_type: 'Technology',
        abn_number: '23456789012',
        business_address: '456 Tech Avenue, Techland'
    },
    {
        business_name: 'LMN Creative Studios',
        business_type: 'Design',
        abn_number: '34567890123',
        business_address: '789 Art Lane, Creativity City'
    },
    {
        business_name: 'PQR Manufacturing',
        business_type: 'Manufacturing',
        abn_number: '45678901234',
        business_address: '101 Factory Road, Industrytown'
    },
    {
        business_name: 'EFG Health Clinic',
        business_type: 'Healthcare',
        abn_number: '56789012345',
        business_address: '202 Wellness Street, Healthville'
    },
    {
        business_name: 'RST Financial Advisors',
        business_type: 'Finance',
        abn_number: '67890123456',
        business_address: '303 Money Lane, Financetown'
    }
];
export let expenses = [
    {
        property_name: 'Property 1',
        property_address: '123 Main St',
        date: '2024-02-01',
        no_of_weeks: 4,
        private_usage_percentage: 20,
        details: [
            { name: 'Owner 1', property_owned_percentage: 50 },
            { name: 'Owner 2', property_owned_percentage: 50 }
        ]
    },
    {
        property_name: 'Property 2',
        property_address: '456 Oak St',
        date: '2024-02-01',
        no_of_weeks: 4,
        private_usage_percentage: 15,
        details: [
            { name: 'Owner 3', property_owned_percentage: 70 },
            { name: 'Owner 4', property_owned_percentage: 30 }
        ]
    },
    {
        property_name: 'Property 3',
        property_address: '789 Pine St',
        date: '2024-02-01',
        no_of_weeks: 4,
        private_usage_percentage: 25,
        details: [
            { name: 'Owner 5', property_owned_percentage: 60 },
            { name: 'Owner 6', property_owned_percentage: 40 }
        ]
    },
    {
        property_name: 'Property 4',
        property_address: '101 Elm St',
        date: '2024-02-01',
        no_of_weeks: 4,
        private_usage_percentage: 10,
        details: [
            { name: 'Owner 7', property_owned_percentage: 80 },
            { name: 'Owner 8', property_owned_percentage: 20 }
        ]
    },
    {
        property_name: 'Property 5',
        property_address: '202 Maple St',
        date: '2024-02-01',
        no_of_weeks: 4,
        private_usage_percentage: 18,
        details: [
            { name: 'Owner 9', property_owned_percentage: 45 },
            { name: 'Owner 10', property_owned_percentage: 55 }
        ]
    }
];
export let leads = [
    {
        first_name: 'John',
        last_name: 'Doe',
        phone: '123-456-7890',
        email: 'john.doe@example.com',
        message: 'Interested in your services.'
    },
    {
        first_name: 'Jane',
        last_name: 'Smith',
        phone: '987-654-3210',
        email: 'jane.smith@example.com',
        message: 'Looking for more information.'
    },
    {
        first_name: 'Bob',
        last_name: 'Johnson',
        phone: '555-123-4567',
        email: 'bob.johnson@example.com',
        message: 'Considering a partnership.'
    },
    {
        first_name: 'Alice',
        last_name: 'Williams',
        phone: '333-888-9999',
        email: 'alice.williams@example.com',
        message: 'Need a quote for your services.'
    },
    {
        first_name: 'Charlie',
        last_name: 'Brown',
        phone: '111-222-3333',
        email: 'charlie.brown@example.com',
        message: 'Interested in a demo.'
    },
    {
        first_name: 'Emily',
        last_name: 'Davis',
        phone: '777-444-5555',
        email: 'emily.davis@example.com',
        message: 'Looking for pricing information.'
    },
    {
        first_name: 'Michael',
        last_name: 'Jones',
        phone: '888-999-0000',
        email: 'michael.jones@example.com',
        message: 'Inquiring about your products.'
    },
    {
        first_name: 'Sara',
        last_name: 'Miller',
        phone: '222-555-6666',
        email: 'sara.miller@example.com',
        message: 'Interested in a consultation.'
    }
];
export const DummyTeamMembersList = [
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Sent',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    },
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Sent',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    },
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Accepted',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    },
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Error',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    },
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Revoked',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    },
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Sent',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    },
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Sent',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    },
    {
        name: 'Lindsay Walton',
        title: 'Front-end Developer',
        email: 'lindsay.walton@example.com',
        status: 'Sent',
        role: 'Member',
        message: 'Lorem ipsum is simply a dummy text'
    }
];

export const DUMMY_JOB_TYPES = [
    {
        job_type_id: 'c6b73d3d-9f4e-4f31-81b3-b100cccb452b',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
        label: 'Bills - Xero',
        job_template_id: '7a223e4b-d442-4766-8e30-156e10abb3fc',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-20T03:00:13.4660',
        updated_at: '2024-12-20T03:00:13.4660',
        template: {
            job_template_id: '7a223e4b-d442-4766-8e30-156e10abb3fc',
            name: 'Bills - Xero',
            title: 'Bills - Xero',
            description: '<p>Creating Bills on Xero for Bookeeping Package Clients.</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
            created_at: '2024-12-20T02:59:30.7370',
            updated_at: '2024-12-20T02:59:30.7370'
        },
        service: null,
        createdBy: {
            user_id: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
            email: 'prav@thekalculators.com.au',
            first_name: 'Pravalika',
            last_name: 'Gaddameedi',
            image: 'https://api.synkli.com.au/files/employee?id=em/4a93a0/pi/image.jpg',
            phone_number: '+61449916411',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: '9eda7009-c748-45ac-88a1-4c941c114d0e',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
        label: 'Land Tax',
        job_template_id: '',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-17T07:54:21.0690',
        updated_at: '2024-12-17T07:54:21.0690',
        template: null,
        service: null,
        createdBy: {
            user_id: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            email: 'kaleem@thekalculators.com.au',
            first_name: 'Kaleem',
            last_name: 'Ulah',
            image: 'https://api.synkli.com.au/files/employee?id=em/6b8e4f/pi/image.jpg',
            phone_number: '+61430858990',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: '5b677a3b-1ec7-455e-bb62-f981eaece6a0',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '202251d8-4351-419d-8baf-4aeda7710455',
        label: 'Weekly Bookkeeping Update',
        job_template_id: '',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-17T06:45:38.0700',
        updated_at: '2024-12-17T06:45:38.0700',
        template: null,
        service: null,
        createdBy: {
            user_id: '202251d8-4351-419d-8baf-4aeda7710455',
            email: 'pritam@thekalculators.com.au',
            first_name: 'PRITAM',
            last_name: 'HIRANI',
            image: null,
            phone_number: '+61449506795',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: 'f7bc4737-4d3d-413c-bc92-013499929da2',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '202251d8-4351-419d-8baf-4aeda7710455',
        label: 'Payroll',
        job_template_id: '',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-17T06:41:10.3200',
        updated_at: '2024-12-17T06:41:10.3200',
        template: null,
        service: null,
        createdBy: {
            user_id: '202251d8-4351-419d-8baf-4aeda7710455',
            email: 'pritam@thekalculators.com.au',
            first_name: 'PRITAM',
            last_name: 'HIRANI',
            image: null,
            phone_number: '+61449506795',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: 'bd1879db-0447-419d-ab63-ce5a3cbf127e',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
        label: 'SGC ',
        job_template_id: 'dfeeb0ee-05dc-4921-8405-0f4443316d91',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-15T23:39:37.5460',
        updated_at: '2024-12-15T23:39:37.5460',
        template: {
            job_template_id: 'dfeeb0ee-05dc-4921-8405-0f4443316d91',
            name: 'Super Guarantee Charge ',
            title: 'Super Guarantee Charge ',
            description: '<p>Create client SGC Statement for the ATO lodgement&nbsp;</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            created_at: '2024-12-15T23:39:24.0180',
            updated_at: '2024-12-15T23:39:24.0180'
        },
        service: null,
        createdBy: {
            user_id: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            email: 'kaleem@thekalculators.com.au',
            first_name: 'Kaleem',
            last_name: 'Ulah',
            image: 'https://api.synkli.com.au/files/employee?id=em/6b8e4f/pi/image.jpg',
            phone_number: '+61430858990',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: 'b56d18c3-11ce-4a8e-8c48-0a2d7a10b824',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
        label: 'Individual Tax Return with Business & BAS',
        job_template_id: '0f8b10e5-e1b8-4751-a525-5d1fad8062d7',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-14T12:34:35.6640',
        updated_at: '2024-12-14T12:34:35.6640',
        template: {
            job_template_id: '0f8b10e5-e1b8-4751-a525-5d1fad8062d7',
            name: 'Individual Tax Return with Business & BAS ',
            title: 'Individual Tax Return with Business & BAS ',
            description: '<p>Attendance to the provision of accounting services including the following:</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            created_at: '2024-12-14T12:33:49.4350',
            updated_at: '2024-12-14T12:34:07.0710'
        },
        service: null,
        createdBy: {
            user_id: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            email: 'kaleem@thekalculators.com.au',
            first_name: 'Kaleem',
            last_name: 'Ulah',
            image: 'https://api.synkli.com.au/files/employee?id=em/6b8e4f/pi/image.jpg',
            phone_number: '+61430858990',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: '50556bc2-d3cd-455d-b1a2-6591169273a3',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
        label: 'Individual Tax Return with Capital Gains',
        job_template_id: '789ded87-5fe3-48c4-bbd9-19c9bcea0c5f',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-14T12:31:11.9210',
        updated_at: '2024-12-14T12:31:32.9970',
        template: {
            job_template_id: '789ded87-5fe3-48c4-bbd9-19c9bcea0c5f',
            name: 'Individual Tax Return with CGT',
            title: 'Individual Tax Return with CGT',
            description: '<p>Attendance to the provision of accounting services including the following:</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            created_at: '2024-12-14T12:30:42.0140',
            updated_at: '2024-12-14T12:30:42.0140'
        },
        service: null,
        createdBy: {
            user_id: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            email: 'kaleem@thekalculators.com.au',
            first_name: 'Kaleem',
            last_name: 'Ulah',
            image: 'https://api.synkli.com.au/files/employee?id=em/6b8e4f/pi/image.jpg',
            phone_number: '+61430858990',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: '1b560b80-d386-4fdb-a4c6-5de6dd89857c',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
        label: 'ABN Re-instatement/Re-apply',
        job_template_id: '7cb77d24-cb52-4fb4-938b-09ca941edfff',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-12T23:31:06.7550',
        updated_at: '2024-12-12T23:31:06.7550',
        template: {
            job_template_id: '7cb77d24-cb52-4fb4-938b-09ca941edfff',
            name: 'ABN Re-instatement/Re-apply',
            title: 'ABN Re-instatement/Re-apply',
            description: '<p>ABN Re-instatement/ Re-apply with ABR or ATO.</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
            created_at: '2024-11-14T07:51:07.8460',
            updated_at: '2024-11-14T07:51:07.8460'
        },
        service: null,
        createdBy: {
            user_id: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
            email: 'prav@thekalculators.com.au',
            first_name: 'Pravalika',
            last_name: 'Gaddameedi',
            image: 'https://api.synkli.com.au/files/employee?id=em/4a93a0/pi/image.jpg',
            phone_number: '+61449916411',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: '1a0d1e66-da1a-4f21-a0ef-b0349bbebcde',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: 'd9dcbe01-d9b7-43c9-855b-398495a3da45',
        label: 'Individual Tax Return with Rental Property and CGT',
        job_template_id: '6cf6a857-67b6-4fe1-92c8-3c483064ae9a',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-06T07:41:54.3800',
        updated_at: '2024-12-14T12:28:52.3710',
        template: {
            job_template_id: '6cf6a857-67b6-4fe1-92c8-3c483064ae9a',
            name: 'Individual Tax Return with Rental Property and CGT',
            title: 'Individual Tax Return with Rental Property and CGT',
            description: '<p>Attendance to the provision of accounting services including the following:</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
            created_at: '2024-10-28T07:21:55.2430',
            updated_at: '2024-12-14T12:29:27.7310'
        },
        service: null,
        createdBy: {
            user_id: 'd9dcbe01-d9b7-43c9-855b-398495a3da45',
            email: 'mamta@thekalculators.com.au',
            first_name: 'Mamta',
            last_name: 'Sharma',
            image: null,
            phone_number: '+61424690461',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: 'cd7fb761-edb7-4774-8001-3378fe12d95e',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: 'd9dcbe01-d9b7-43c9-855b-398495a3da45',
        label: '\tIndividual Tax Return with Business Schedule',
        job_template_id: '6680228d-e2b8-445c-80a7-aeb1db39510f',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-06T07:41:02.6590',
        updated_at: '2024-12-06T07:41:02.6600',
        template: {
            job_template_id: '6680228d-e2b8-445c-80a7-aeb1db39510f',
            name: 'Individual Tax Return with Business Schedule',
            title: 'Individual Tax Return with Business Schedule',
            description: '<p>Attendance to the provision of accounting services, including the following:</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '6b805514-64ed-4553-b56f-1e4ecc5cbe4f',
            created_at: '2024-10-25T11:42:57.2100',
            updated_at: '2024-10-25T11:42:57.2100'
        },
        service: null,
        createdBy: {
            user_id: 'd9dcbe01-d9b7-43c9-855b-398495a3da45',
            email: 'mamta@thekalculators.com.au',
            first_name: 'Mamta',
            last_name: 'Sharma',
            image: null,
            phone_number: '+61424690461',
            user_type: 'employee',
            status: 'active'
        }
    },
    {
        job_type_id: '93e121a2-f2f4-444c-9f50-63f7770b98b9',
        business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
        created_by: 'd9dcbe01-d9b7-43c9-855b-398495a3da45',
        label: 'Monthly BAS',
        job_template_id: 'c232d2fc-0708-4148-8f1e-badc164e78d0',
        form_id: null,
        onboarding_service_id: '',
        status: 'active',
        created_at: '2024-12-06T07:40:42.1140',
        updated_at: '2024-12-12T23:24:09.3610',
        template: {
            job_template_id: 'c232d2fc-0708-4148-8f1e-badc164e78d0',
            name: 'Monthly BAS',
            title: 'Monthly BAS',
            description:
                '<p>Link this job to a repeating invoice in Xero, by entering the Job Number into the Reference Field of the Repeating Invoice Template.</p>\n',
            status: 'active',
            business_id: '0d5b378e-35bb-441f-a226-75a5428f07e8',
            created_by: '4a92a4b0-4b66-4d3d-838c-6d69c27383a0',
            created_at: '2024-10-28T05:13:16.6180',
            updated_at: '2024-12-12T23:19:47.4470'
        },
        service: null,
        createdBy: {
            user_id: 'd9dcbe01-d9b7-43c9-855b-398495a3da45',
            email: 'mamta@thekalculators.com.au',
            first_name: 'Mamta',
            last_name: 'Sharma',
            image: null,
            phone_number: '+61424690461',
            user_type: 'employee',
            status: 'active'
        }
    }
];
