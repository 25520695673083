import React from 'react';

export const DeductionIcon = ({ className }) => {
    return (
        <svg
            width="88"
            height="88"
            viewBox="0 0 88 88"
            fill="black"
            stroke="white"
            strokeWidth="6"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.975 10.4C30.3295 10.4 27.7923 11.4509 25.9216 13.3216C24.0509 15.1923 23 17.7295 23 20.375C23 21.6849 23.258 22.982 23.7593 24.1922C24.2606 25.4025 24.9953 26.5021 25.9216 27.4284C26.8479 28.3546 27.9475 29.0894 29.1577 29.5907C30.368 30.092 31.6651 30.35 32.975 30.35C34.2849 30.35 35.582 30.092 36.7923 29.5907C38.0025 29.0894 39.1021 28.3546 40.0284 27.4284C40.9547 26.5021 41.6894 25.4025 42.1907 24.1922C42.692 22.982 42.95 21.6849 42.95 20.375C42.95 17.7295 41.8991 15.1923 40.0284 13.3216C38.1577 11.4509 35.6205 10.4 32.975 10.4ZM19.9819 7.38191C23.4279 3.93593 28.1016 2 32.975 2C37.8484 2 42.5221 3.93593 45.9681 7.38191C49.4141 10.8279 51.35 15.5016 51.35 20.375C51.35 22.788 50.8747 25.1774 49.9513 27.4068C49.0279 29.6361 47.6744 31.6618 45.9681 33.3681C44.2618 35.0743 42.2362 36.4278 40.0068 37.3513C37.7774 38.2747 35.388 38.75 32.975 38.75C30.562 38.75 28.1726 38.2747 25.9432 37.3513C23.7138 36.4278 21.6882 35.0743 19.9819 33.3681C18.2756 31.6618 16.9221 29.6361 15.9987 27.4068C15.0753 25.1774 14.6 22.788 14.6 20.375C14.6 15.5016 16.5359 10.8279 19.9819 7.38191ZM52.4 37.7C52.4 35.3804 54.2804 33.5 56.6 33.5H81.8C84.1196 33.5 86 35.3804 86 37.7C86 40.0196 84.1196 41.9 81.8 41.9H56.6C54.2804 41.9 52.4 40.0196 52.4 37.7ZM32.975 51.35C26.9877 51.35 21.2457 53.7284 17.0121 57.962C13.2377 61.7364 10.9379 66.7096 10.4833 71.9866C17.1833 75.5682 24.8358 77.5999 32.9708 77.5999L32.9779 77.5999C40.8349 77.6132 48.5596 75.6789 55.4667 71.9868C55.0122 66.7097 52.7123 61.7364 48.9379 57.962C44.7043 53.7284 38.9623 51.35 32.975 51.35ZM4.20813 78.0855C2.89335 77.376 2 75.9858 2 74.3869V73.9249C2 65.7099 5.26343 57.8313 11.0724 52.0223C16.8813 46.2134 24.7599 42.95 32.975 42.95C41.1901 42.95 49.0687 46.2134 54.8776 52.0223C60.6866 57.8313 63.95 65.7099 63.95 73.9249V74.3827C63.95 75.8555 63.1785 77.2207 61.9169 77.9806C53.1787 83.2434 43.1678 86.0165 32.9673 85.9999C22.4661 85.9993 12.6245 83.1111 4.20813 78.0855Z"
                // fill="url(#paint0_linear_355_3929)"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
