import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { NoDataFound } from 'components/common/NoDataFound';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import useRoleGuard from 'Hooks/useRoleGuard';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { cn } from 'utills/tailwindUtil';

export const DocumentListTable = ({
    dataList,
    handleDeletionId,
    handleSelectedAll,
    handleSelectId,
    selectedIds,
    setEditObj,
    updateFiltersObject,
    filters
}) => {
    const { hasPermissions } = useRoleGuard();
    const { isLoading } = useSelector((state) => state.global);
    const RenderSortByArrows = (name, value = '', id) => {
        return (
            <div className="flex items-center justify-start gap-1">
                <label
                    htmlFor={id + '_sort'}
                    onClick={() => {
                        updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                    }}
                    className="cursor-pointer"
                >
                    {name}
                </label>
                {value === 'ASC' ? (
                    <ArrowDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'DESC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : value === 'DESC' ? (
                    <ArrowUpIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'ASC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : (
                    <ArrowsUpDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                        }}
                        className="w-4 h-4 text-[#979797] cursor-pointer"
                    />
                )}
            </div>
        );
    };

    return (
        <div className="pb-5 rounded-xl overflow-x-auto">
            {!isLoading && dataList?.length < 1 ? (
                <NoDataFound height="400px" message="No Data Found!." />
            ) : (
                <div className="mt-8 flow-root">
                    <div className="inline-block min-w-full py-4 align-middle">
                        <div className="relative">
                            <table className="min-w-full table-fixed divide-y  divide-gray-300 bg-white">
                                <thead>
                                    <tr>
                                        <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                            <input
                                                type="checkbox"
                                                className="!rounded-[3px] bg-body"
                                                onChange={(e) => handleSelectedAll(e)}
                                                checked={selectedIds?.length === dataList?.length}
                                            />
                                        </th>
                                        <th
                                            scope="col"
                                            className="min-w-[12rem] py-3.5 pr-3 text-left text-md font-semibold text-gray-900"
                                        >
                                            {RenderSortByArrows('Document', filters?.title, 'title')}
                                        </th>

                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                        >
                                            {RenderSortByArrows('Created Date', filters?.created_at, 'created_at')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                        >
                                            {RenderSortByArrows('Last Updated', filters?.updated_at, 'updated_at')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                        >
                                            Created By
                                        </th>

                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left flex gap-2 items-center text-md font-semibold text-gray-900"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {dataList &&
                                        [...dataList]
                                            ?.sort((a, b) => moment(b?.created_at).diff(moment(a?.created_at)))
                                            ?.map((user, ind) => (
                                                <tr
                                                    key={'task_template_' + ind}
                                                    className={` ${
                                                        selectedIds?.includes(user?.template_job_document_id)
                                                            ? 'bg-gray-50'
                                                            : undefined
                                                    } hover:bg-[#F8F4FE] transition-colors duration-200 cursor-pointer`}
                                                >
                                                    <td className="relative py-3.5 px-7 sm:w-12 sm:px-6">
                                                        {selectedIds?.includes(user?.template_job_document_id) && (
                                                            <div className="absolute inset-y-0 left-0 w-0.5 bg-thirdbg" />
                                                        )}
                                                        <input
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-secondary  text-indigo-600 focus:ring-indigo-600"
                                                            value={user?.template_job_document_id}
                                                            checked={selectedIds?.includes(
                                                                user?.template_job_document_id
                                                            )}
                                                            onChange={(_e) =>
                                                                handleSelectId(user?.template_job_document_id)
                                                            }
                                                        />
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap   pr-3 text-md py-3.5 font-poppins',
                                                            selectedIds?.includes(user?.template_job_document_id)
                                                                ? 'text-thirdbg'
                                                                : 'text-black'
                                                        )}
                                                    >
                                                        {user?.title}
                                                    </td>

                                                    <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                                        <FormateDateTime dateTime={user?.created_at} />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                                        <FormateDateTime dateTime={user?.updated_at} />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                                        <DefaultProfileViewer
                                                            image={user?.created_by?.image}
                                                            width="45px"
                                                            height="45px"
                                                            name={
                                                                user?.created_by?.first_name +
                                                                ' ' +
                                                                user?.created_by?.last_name
                                                            }
                                                            tooltip
                                                        />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-3.5 ">
                                                        <div className="flex items-start gap-2 justify-start">
                                                            <div
                                                                onClick={() => {
                                                                    if (!hasPermissions('Job', ['admin'])) {
                                                                        return toastHandler(
                                                                            "You don't have permission to perform this action",
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }

                                                                    setEditObj(user);
                                                                }}
                                                                className="flex gap-2 items-center cursor-pointer "
                                                            >
                                                                <BgEditIcon />
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    if (!hasPermissions('Job', ['admin'])) {
                                                                        return toastHandler(
                                                                            "You don't have permission to perform this action",
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }

                                                                    handleDeletionId('', true);
                                                                    handleDeletionId(user?.template_job_document_id);
                                                                }}
                                                                className="flex gap-2 items-center cursor-pointer "
                                                            >
                                                                <BgDeleteIcon />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
