import React, { useState } from 'react';
import RunPayrollPage from '../../components/payrol-pages/run-payroll/RunPayrollPage';
import PayRunDetails from '../../components/payrol-pages/payroll-sides/PayRunDetails';
import PaymentSummary from '../../components/payrol-pages/payroll-sides/PaymentSummary';
const RunPayroll = () => {
    const [showPayDetails, setShowPayDetails] = useState(false);
    const [showPaymentSummary, setShowPaymentSummary] = useState(false);

    const togglePayDetails = () => {
        setShowPayDetails(!showPayDetails);
    };
    const togglePaymentSummary = () => {
        setShowPaymentSummary(!showPaymentSummary);
    };
    return (
        <div className="app-dashboard">
            {showPayDetails && <PayRunDetails togglePayDetails={togglePayDetails} />}
            {showPaymentSummary && <PaymentSummary togglePaymentSummary={togglePaymentSummary} />}

            <div className={`content ${''}`}>
                <RunPayrollPage togglePayDetails={togglePayDetails} togglePaymentSummary={togglePaymentSummary} />
            </div>
        </div>
    );
};
export default RunPayroll;
