/* eslint-disable react/jsx-key */
import React from 'react';
import { motion } from 'framer-motion';
import { SideTimes } from 'utills/svgs/SideTimes';
import { useDispatch, useSelector } from 'react-redux';
import { generateId } from 'utills/uid';
import { ImageIcon } from 'utills/svgs/ImageIcon';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import { BgTimes } from 'utills/svgs/BgTimes';
import { useEffect } from 'react';
import {
    BSB,
    BUSINESS_ID,
    BUSINESS_INDUSTRY_OCCUPATION,
    SALARY_WAGES_OCCUPATION,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    USER_TYPE
} from 'utills/globalVars';
import 'react-datepicker/dist/react-datepicker.css';
import { deleteFormFileThunk, getformsfieldsOptionsThunk, updateCustomerFormThunk } from 'store/form';
import { useGetFormSubmissions } from 'Hooks/useGetFormSubmissions';
import moment from 'moment';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { toastHandler } from 'responseHanlder';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { FileIcon } from 'utills/svgs/FileIcon';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { InputField } from 'components/atoms/inputs/InputField';
import isValidABN from 'is-valid-abn';
import { PickAddress } from 'global-components/PickAddress';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { DatePick } from 'components/atoms/inputs/DatePick';
import dayjs from 'dayjs';
import { convertSpacesToUnderscores, convertUnderscoresToSpaces } from 'utills/handleText';
import { v4 as uuidv4 } from 'uuid';
import { BsbInput } from 'components/atoms/inputs/BsbInput';
import SearchableComboboxSSR from 'components/molecules/comboboxes/SearchableComboboxSSR';
import { LIST, RECURRING } from 'utills/constants/forms/forms.constants';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import Input from 'components/atoms/Input';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { PlusIcon } from 'utills/svgs/PlusIcon';

export const EditCustomerForm = ({ toggleEditForm, id, toggleSubmissionsUpdate }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const { formSubmissionsId } = useSelector((state) => state.global);
    const customerFormDataPayload = {
        form_id: formSubmissionsId,
        business_client_id: id
    };
    const [showModal, setShowModal] = useState(false);
    const { fetchSubmissions } = useGetFormSubmissions();
    const [_addLoader, setAddLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const { data: customer } = useSelector((state) => state.viewBusinessCustomerInfo);
    const dispatch = useDispatch();

    const business_id = localStorage.getItem(BUSINESS_ID);

    const { editForm: form } = useSelector((state) => state.global);
    const [customerForm, setCustomerForm] = useState([]);

    const [BusemailStepIndex, _setBusEmailStepIndex] = useState(null);
    const [BusemailFieldIndex, _setBusEmailFieldIndex] = useState(null);
    const [BusemailError, _setBusEmailError] = useState();

    const [phoneStepIndex, setPhoneStepindex] = useState(null);
    const [phoneFieldIndex, setPhoneFieldIndex] = useState(null);
    const [phoneError, setPhoneError] = useState(false);

    const [abnStepIndex, setAbnStepindex] = useState(null);
    const [abnFieldIndex, setAbnFieldIndex] = useState(null);
    const [abnError, setAbnError] = useState(false);
    const [tfnStepIndex, setTfnStepIndex] = useState(null);
    const [tfnFieldIndex, setTfnFieldIndex] = useState(null);
    const [tfnError, setTfnError] = useState(false);
    const [addressDetails, _setAddressDetails] = useState(null);

    //recursive functions start
    const findFieldByRowIdInDefaultValue = (field_row_id, defaultValue, targetProperty = 'field_row_id') => {
        if (Array.isArray(defaultValue)) {
            for (const arrOrField of defaultValue) {
                if (Array.isArray(arrOrField)) {
                    for (const innerField of arrOrField) {
                        if (innerField?.[targetProperty] === field_row_id) {
                            return innerField;
                        }
                    }
                } else {
                    if (arrOrField?.[targetProperty] === field_row_id) {
                        return arrOrField;
                    }
                }
            }
        }
        return null;
    };
    const findFieldByRowId = (field_row_id, steps, targetProperty = 'field_row_id') => {
        for (const step of steps) {
            for (const field of step.fields) {
                if (field?.[targetProperty] === field_row_id) {
                    return field;
                }
                if (field.conditional_steps) {
                    for (const key in field.conditional_steps) {
                        const conditionalStep = field.conditional_steps[key];
                        const foundField = findFieldByRowId(field_row_id, conditionalStep.steps, targetProperty);
                        if (foundField) {
                            return foundField;
                        }
                    }
                }
                if (field.default_value && field.type === RECURRING) {
                    const foundField = findFieldByRowIdInDefaultValue(
                        field_row_id,
                        field.default_value,
                        targetProperty
                    );
                    if (foundField) {
                        return foundField;
                    }
                }
            }
        }
        return null;
    };

    const changeHandler = (e, stepIndex, fieldIndex, field_row_id, i = null, form_field_id = 'field_row_id') => {
        const newArr = cloneDeep(customerForm);
        let field = findFieldByRowId(field_row_id, newArr.steps, form_field_id);

        if (field) {
            if (i !== null) {
                field.default_value[i] = e.target.value;
                setCustomerForm(newArr);
            } else {
                field.default_value = e.target.value;
            }
            setCustomerForm(newArr);
        }
    };

    const addRemoveListHandler = (field_row_id, i = null, form_field_id = 'field_row_id', condition = 'add') => {
        const newArr = cloneDeep(customerForm);
        let field = findFieldByRowId(field_row_id, newArr.steps, form_field_id);

        if (field) {
            if (condition === 'add') {
                field.default_value.push('');
                setCustomerForm(newArr);
            } else {
                field.default_value.splice(i, 1);
            }
            setCustomerForm(newArr);
        }
    };

    const phonechangeHandler = (phone, stepIndex, fieldIndex, field_row_id) => {
        const newArr = cloneDeep(customerForm);
        const field = findFieldByRowId(field_row_id, newArr.steps);

        const parsedPhoneNumber = parsePhoneNumberFromString(phone);

        let isValid = false;
        if (parsedPhoneNumber) {
            isValid = parsedPhoneNumber.isValid();
        } else {
            console.error('Invalid phone number');
        }

        if (!isValid || phone?.length < 1) {
            setPhoneStepindex(stepIndex);
            setPhoneFieldIndex(fieldIndex);
            setPhoneError(true);
            console.error('Invalid phone number');
        } else {
            setPhoneStepindex(null);
            setPhoneFieldIndex(null);
            setPhoneError(false);
        }

        field.default_value = phone;

        setCustomerForm(newArr);
    };

    const handleAbnNumber = (e, stepIndex, fieldIndex, field_row_id) => {
        const newArr = cloneDeep(customerForm);
        const { value } = e.target;
        const field = findFieldByRowId(field_row_id, newArr.steps);

        if (value?.length > 12) {
            return;
        }

        const abnNumber = value.replace(/\s/g, '');
        const updatedABN = abnNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
        if (!isValidABN(updatedABN)) {
            setAbnStepindex(stepIndex);
            setAbnFieldIndex(fieldIndex);
            setAbnError(true);
        } else {
            setAbnStepindex(null);
            setAbnFieldIndex(null);
            setAbnError(false);
        }

        field.default_value = value;
        setCustomerForm(newArr);
    };

    const handleTfnNumber = (e, stepIndex, fieldIndex, field_row_id) => {
        const newArr = cloneDeep(customerForm);
        const inputValue = e.target.value;

        const field = findFieldByRowId(field_row_id, newArr.steps);

        if (inputValue.length > 9) {
            return;
        }

        if (inputValue?.length < 9) {
            setTfnFieldIndex(fieldIndex);
            setTfnStepIndex(stepIndex);
            setTfnError(true);
        } else {
            setTfnFieldIndex(null);
            setTfnStepIndex(null);
            setTfnError(false);
        }

        const limitedValue = inputValue?.slice(0, 9);

        field.default_value = limitedValue;
        setCustomerForm(newArr);
    };

    useEffect(() => {
        setCustomerForm(cloneDeep(form));
    }, []);

    const handleRemoveFile = (stepIndex, index, valIndex, field_file_id, customer_form_data_field_id) => {
        const newForm = cloneDeep(customerForm);
        const payload = {
            customer_form_data_id: form?.customer_form_data_id,
            field_file_id,
            customer_form_data_field_id
        };
        dispatch(deleteFormFileThunk(payload))
            .then((response) => {
                if (response.payload) {
                    newForm.steps[stepIndex].fields[index].default_value?.splice(valIndex, 1);
                    setCustomerForm(newForm);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleAddFile = (stepIndex, fieldIndex, form_field_id, customer_form_data_id) => {
        const newForm = cloneDeep(customerForm);

        const formData = new FormData();
        formData.append('customer_id', customer?.customer_id);
        formData.append('form_id', form?.form_id);
        formData.append('status', newForm?.status ?? 'complete');
        formData.append('data', JSON.stringify(newForm.steps));
        formData.append('uploaded_by', USER_TYPE);
        formData.append('business_id', business_id);
        formData.append('service_id', '');
        formData.append('customer_form_data_id', customer_form_data_id);

        if (selectedFiles) {
            for (let i = 0; i <= selectedFiles?.length; i++) {
                formData.append(`data.images['${form_field_id}']`, selectedFiles[i]);
            }
        }

        setAddLoader(true);
        dispatch(updateCustomerFormThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setCustomerForm(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setAddLoader(false);
            });
    };

    const handleRadioCheck = (e, stepIndex, fieldIndex, op, field_row_id) => {
        const newForm = cloneDeep(customerForm);
        const field = findFieldByRowId(field_row_id, newForm.steps);

        if (field) {
            field.default_value = op;
            setCustomerForm(newForm);
        }
    };

    const handleSwitch = (stepIndex, fieldIndex, val, field_row_id) => {
        const newForm = cloneDeep(customerForm);
        const field = findFieldByRowId(field_row_id, newForm.steps);
        if (field) {
            field.default_value = val;
            setCustomerForm(newForm);
        }
    };

    const handleDropDown = (e, stepindex, index, field_row_id) => {
        const newForm = cloneDeep(customerForm);
        const field = findFieldByRowId(field_row_id, newForm.steps);
        if (field) {
            field.default_value = e.target.value;
            setCustomerForm(newForm);
        }
    };

    const handleSubmit = () => {
        const newForm = cloneDeep(customerForm);

        const formData = new FormData();
        if (selectedFiles.length > 0) {
            selectedFiles.map((item) => {
                formData.append(`data['images']['${item.form_field_id}']`, item.fileData);
            });
        }

        let numberFields = [];

        newForm?.steps?.forEach((step) => {
            step?.fields?.forEach((field) => {
                if (field?.type === 'phone-number') {
                    numberFields?.push(field?.default_value);
                }

                if (field?.type === 'tfn') {
                    field.default_value = `${field?.default_value}`;
                }
            });
        });

        const hasValidationError = numberFields?.some((item) => item?.length < 11);

        if (hasValidationError) {
            return toastHandler('Invalid Phone number', TOAST_TYPE_ERROR);
        }

        formData.append('customer_id', customer?.customer_id);
        formData.append('form_id', form?.form_id);
        formData.append('status', newForm?.status ?? 'complete');
        formData.append('data', JSON.stringify(newForm.steps));
        formData.append('uploaded_by', USER_TYPE);
        formData.append('business_id', business_id);
        formData.append('service_id', '');
        formData.append('business_form_data_id', newForm?.business_form_data_id);

        setUpdateLoader(true);
        dispatch(updateCustomerFormThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toggleEditForm();
                    toggleSubmissionsUpdate();
                    fetchSubmissions(customerFormDataPayload);
                    toastHandler('Form updated successfully', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setUpdateLoader(false);
            });
    };

    const handleCheckBox = (e, stepIndex, fieldIndex, op, field_row_id) => {
        const newForm = cloneDeep(customerForm);
        const field = findFieldByRowId(field_row_id, newForm.steps);
        if (field) {
            const optionsArray = field?.default_value;

            if (optionsArray && Array.isArray(optionsArray)) {
                const opIndex = optionsArray.indexOf(op);

                if (opIndex !== -1) {
                    optionsArray.splice(opIndex, 1);
                } else {
                    optionsArray.push(op);
                }

                setCustomerForm(newForm);
            } else {
                console.error('Options array is missing or not an array');
            }
        }
    };

    const findFieldByRowIdTwo = (field_row_id, steps) => {
        for (const step of steps) {
            for (const field of step.fields) {
                if (field.field_row_id === field_row_id) {
                    return field;
                }
                if (field.conditional_steps) {
                    for (const key in field.conditional_steps) {
                        const conditionalStep = field.conditional_steps[key];
                        const foundField = findFieldByRowIdTwo(field_row_id, conditionalStep.steps);
                        if (foundField) {
                            return foundField;
                        }
                    }
                }
            }
        }
        return null;
    };

    const handleRecurringOptions = (field_row_id, value, fieldIndex, stepIndex) => {
        const newForm = cloneDeep(customerForm);

        newForm.steps[stepIndex].fields[fieldIndex].recurring_switch = value;

        const field = newForm.steps[stepIndex].fields[fieldIndex];
        const currentStep = newForm.steps[stepIndex];

        if (field.recurring_option === field.recurring_switch) {
            newForm.steps[stepIndex].fields[0].field_row_id = generateId() + uuidv4() + stepIndex;

            delete newForm.steps[stepIndex].fields[0].business_form_data_field_id;
            delete newForm.steps[stepIndex].fields[0].form_field_id;

            newForm.steps[stepIndex].fields[0].default_value.forEach((arr) => {
                arr.forEach((input) => {
                    delete input.customer_form_data_field_id;
                    input.form_field_id = uuidv4();
                    input.field_row_id = generateId() + uuidv4() + stepIndex;
                    input.default_value = '';
                });
            });
            newForm.steps[stepIndex].fields[0].sub_fields.forEach((input) => {
                delete input.customer_form_data_field_id;
                input.form_field_id = uuidv4();
                input.field_row_id = generateId() + uuidv4() + stepIndex;
                input.default_value =
                    input.type === 'checkbox' || input.type === 'dropdown' || input.type === 'switch' ? [] : '';
            });

            if (
                newForm.steps[stepIndex].fields[0].default_value?.length < 1 ||
                newForm.steps[stepIndex].fields[0].default_value.some((arr) => arr.length === 0)
            ) {
                newForm.steps[stepIndex].fields[0].default_value = [newForm.steps[stepIndex].fields[0].sub_fields];
            }
            const stepsAfterCurrent = newForm.steps.slice(stepIndex + 1, newForm.steps.length);
            const stepsWithCurrentStepId = stepsAfterCurrent.filter(
                (st) => st?.step_id === newForm.steps[stepIndex]?.step_id
            );
            if (stepsWithCurrentStepId?.length < 1) {
                const newStep = cloneDeep(newForm.steps[stepIndex]);

                newStep.fields[0].field_row_id = generateId() + uuidv4() + stepIndex;
                delete newStep.fields[0].business_form_data_field_id;
                delete newStep.fields[0].form_field_id;

                newStep.fields[0].recurring_switch =
                    newStep.fields[0]?.recurring_option === newStep.fields[0]?.options[0]
                        ? newStep.fields[0]?.options[1]
                        : newStep.fields[0]?.options[0];

                newStep.fields[0].default_value.forEach((arr) => {
                    arr.forEach((input) => {
                        delete input.customer_form_data_field_id;
                        input.form_field_id = uuidv4();
                        input.field_row_id = generateId() + uuidv4() + stepIndex;
                        input.default_value =
                            input.type === 'checkbox' || input.type === 'dropdown' || input.type === 'switch' ? [] : '';
                    });
                });

                newStep.fields[0].sub_fields.forEach((input) => {
                    delete input.customer_form_data_field_id;
                    input.form_field_id = uuidv4();
                    input.field_row_id = generateId() + uuidv4() + stepIndex;
                    input.default_value = '';
                });

                const currentStepNumber = currentStep.step;
                const parts = currentStepNumber.split('.');

                const beforeDot = parseInt(parts[0], 10);

                let afterDot = parseInt(parts[1], 10) + 1;

                const newStepNumber = `${beforeDot}.${afterDot}`;
                newStep.step = newStepNumber;
                newForm.steps.splice(stepIndex + 1, 0, newStep);
            } else {
                stepsWithCurrentStepId.forEach((step) => {
                    step.is_skipped = false;
                });
            }
        } else {
            const stepsAfterCurrent = newForm.steps.slice(stepIndex + 1, newForm.steps.length);
            const stepsWithCurrentStepId = stepsAfterCurrent.filter(
                (st) => st?.step_id === newForm.steps[stepIndex]?.step_id
            );
            field.default_value = [[]];
            stepsWithCurrentStepId.forEach((step) => {
                step.is_skipped = true;
            });
        }

        setCustomerForm(newForm);
    };

    const handleSkipStepsUpdate = (skipArr, steps) => {
        const findStepsToRevert = steps.filter((st) => skipArr?.includes(st?.step_id));

        if (findStepsToRevert?.length > 0) {
            findStepsToRevert.forEach((step) => {
                step.is_skipped = true;
            });
        }
    };

    const handleRevertStepsUpdate = (skipArr, steps) => {
        const findStepsToRevert = steps.filter((st) => skipArr?.includes(st?.step_id));
        if (findStepsToRevert?.length > 0) {
            findStepsToRevert.forEach((step) => {
                step.is_skipped = false;
            });
        }
    };

    const handleConditionalRadio = (checked, field_row_id, value, stepindex) => {
        const newForm = cloneDeep(customerForm);
        const field = findFieldByRowIdTwo(field_row_id, newForm.steps);
        const findStep = newForm.steps[stepindex];

        if (field) {
            field.default_value = value;
            setCustomerForm(newForm);

            const stepsAfterCurrent = newForm.steps.slice(stepindex + 1, newForm.steps.length);

            const stepsWithCurrentStepId = stepsAfterCurrent.filter(
                (st) => st?.step_id === newForm.steps[stepindex]?.step_id
            );

            if (stepsWithCurrentStepId?.length > 0) {
                stepsWithCurrentStepId.forEach((item) => {
                    const index = newForm.steps.indexOf(item);
                    if (index > -1) {
                        newForm.steps.splice(index, 1);
                    }
                });

                setCustomerForm(newForm);
            }

            let stepsToRevert = [];

            const stepsToBeSkipped = field.skip_steps[convertSpacesToUnderscores(field.default_value)];
            if (stepsToBeSkipped?.length > 0) {
                handleSkipStepsUpdate(stepsToBeSkipped, newForm.steps);
                setCustomerForm(newForm);
            }

            for (const key in field.skip_steps) {
                field.skip_steps[key]?.forEach((item) => {
                    if (key !== convertSpacesToUnderscores(field.default_value)) {
                        stepsToRevert.push(item);
                    }
                });
            }

            const newStepFind = field.conditional_steps[convertSpacesToUnderscores(field.default_value)];

            if (newStepFind) {
                if (newStepFind.display_on_new_page) {
                    const newStep = cloneDeep(newStepFind.steps[0]);
                    newStep.fields.forEach((field) => {
                        field.parent_field_id = 'null';
                        field.field_row_id = uuidv4();
                        field.form_field_id = uuidv4();
                        delete field.customer_form_data_field_id;
                    });

                    const currentStepNumber = findStep.step;
                    const parts = currentStepNumber.split('.');

                    const beforeDot = parseInt(parts[0], 10);

                    let afterDot = parseInt(parts[1], 10) + 1;

                    const newStepNumber = `${beforeDot}.${afterDot}`;
                    newStep.step = newStepNumber;
                    newStep.is_skipped = false;
                    newStep.step_id = findStep.step_id;
                    newForm.steps.splice(stepindex + 1, 0, newStep);

                    setCustomerForm(newForm);
                }
            }

            if (stepsToRevert?.length > 0) {
                handleRevertStepsUpdate(stepsToRevert, newForm.steps);
                setCustomerForm(newForm);
            }
        }
    };

    const handleReplaceFile = (e, stepindex, index, id, fileType, field_row_id) => {
        const newForm = cloneDeep(customerForm);
        const file = e.target.files[0];

        const field = findFieldByRowId(field_row_id, newForm.steps);

        if (fileType === 'image') {
            const allowedImageTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/heif'];
            if (!allowedImageTypes.includes(file.type)) {
                toastHandler('Invalid image type. Allowed types are: jpg, jpeg, png, heic, heif', TOAST_TYPE_ERROR);
                return;
            }
        }

        if (fileType === 'document') {
            const allowedDocumentTypes = [
                'application/pdf',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ];
            if (!allowedDocumentTypes.includes(file.type)) {
                toastHandler('Invalid document type. Allowed types are: pdf, doc, docx', TOAST_TYPE_ERROR);
                return;
            }
        }

        if (field) {
            let existingItem = selectedFiles?.find((obj) => obj.form_field_id === id);
            if (existingItem) {
                let updatedItems = selectedFiles.map((obj) =>
                    obj.form_field_id === id
                        ? {
                              form_field_id: id,
                              fileData: file
                          }
                        : obj
                );
                setSelectedFiles(updatedItems);
            } else {
                setSelectedFiles([
                    ...selectedFiles,
                    {
                        form_field_id: id,
                        fileData: file
                    }
                ]);
            }

            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const url = reader.result;

                    field.default_value = [{ url: url, originalname: file?.name }];
                    setCustomerForm(newForm);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const twelveYearsAgo = moment().subtract(12, 'years').format('YYYY-MM-DD');
    const minDate = '1950-01-01';

    const datePickerProps = {
        right: CalendarSmallIco
    };

    const handleDateEdit = (date, stepIndex, fieldindex, field_row_id) => {
        const newForm = cloneDeep(customerForm);
        const field = findFieldByRowId(field_row_id, newForm.steps);

        if (field) {
            const newDate = new Date(date);
            const formated = moment(newDate).format('DD-MM-YYYY');

            field.default_value = formated;
            setCustomerForm(newForm);
        }
    };

    const handleConditions = (step) => {
        const hasTerms = step.fields.some((field) => field.type === 'terms-and-conditions');
        const hasPrivacyPolicy = step.fields.some((field) => field.type === 'privacy-policy');

        if (hasTerms && !hasPrivacyPolicy) {
            return (
                <div className="flex items-center gap-3">
                    <div>
                        <input type="checkbox" checked={true} className=" cursor-pointer text-thirdbg  rounded-[2px]" />
                    </div>
                    <div>
                        {' '}
                        I agree to{' '}
                        <a href="#!" className="text-thirdbg">
                            terms and conditions
                        </a>
                        .
                    </div>
                </div>
            );
        } else if (!hasTerms && hasPrivacyPolicy) {
            return (
                <div className="flex items-center gap-3">
                    <div>
                        <input type="checkbox" checked={true} className=" cursor-pointer text-thirdbg  rounded-[2px]" />
                    </div>
                    <div>
                        {' '}
                        I agree to{' '}
                        <a href="#!" className="text-thirdbg">
                            privacy policy
                        </a>
                        .
                    </div>
                </div>
            );
        } else if (hasTerms && hasPrivacyPolicy) {
            return (
                <div className="flex items-center gap-3">
                    <div>
                        <input type="checkbox" checked={true} className=" cursor-pointer text-thirdbg  rounded-[2px]" />
                    </div>
                    <span>
                        I agree to{' '}
                        <a href="#!" className="text-thirdbg">
                            terms and conditions
                        </a>{' '}
                        and{' '}
                        <a href="#!" className="text-thirdbg">
                            privacy policy
                        </a>
                        .
                    </span>
                </div>
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        const newForm = cloneDeep(customerForm);
        if (addressDetails?.address) {
            const field = findFieldByRowId(addressDetails.field_row_id, newForm?.steps);
            if (field) {
                field.default_value = addressDetails.address;
                setCustomerForm(newForm);
            }
        }
    }, [addressDetails]);
    ///render fields

    const handleNewAddress = (field_row_id, value) => {
        const newForm = cloneDeep(customerForm);

        const field = findFieldByRowId(field_row_id, newForm?.steps);
        if (field) {
            field.default_value = value;
            setCustomerForm(newForm);
        }
    };

    const fetchWagesOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'salary-wages-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };

    const fetchBusinessOccupationOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'business-industry-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };

    const ReturnFormFields = (field, index, stepindex, _step) => {
        return (
            <div key={index} className="edit-form-inputs">
                {field.type === 'text' && (
                    <div className="mt-4">
                        <label>{field.label}</label>
                        <input
                            type={field.type}
                            placeholder={field?.example}
                            value={field?.default_value}
                            onChange={(e) => changeHandler(e, stepindex, index, field.field_row_id)}
                            className="mt-2"
                        />
                    </div>
                )}

                {field.type === 'number' && (
                    <div className="mt-4">
                        <label>{field.label}</label>
                        <input
                            type={field.type}
                            placeholder={field?.example}
                            value={field?.default_value}
                            onChange={(e) => changeHandler(e, stepindex, index, field.field_row_id)}
                            className="mt-2"
                        />
                    </div>
                )}

                {field.type === 'signature' && (
                    <div className="add-ann-form mt-2">
                        <label>{field?.label}</label>

                        <div className="signature-pad  overflow-hidden cursor-pointer shadow-lg mt-2">
                            {' '}
                            <img src={field?.default_value} alt="signature" />
                        </div>
                    </div>
                )}

                {field?.type === 'recurring' && (
                    <div className="mt-2">
                        <div> {field.label} </div>
                        <div>
                            <div className="field-switch-wrapper mt-2 cursor-pointer">
                                <div
                                    className={`field-switch ${
                                        field?.recurring_switch === field.options[0] && 'active'
                                    } flex justify-center items-center`}
                                    onClick={() =>
                                        handleRecurringOptions(field.field_row_id, field.options[0], index, stepindex)
                                    }
                                >
                                    {field.options[0]}
                                </div>

                                <div
                                    className={`field-switch ${
                                        field.recurring_switch === field.options[1] && 'active'
                                    } flex justify-center items-center`}
                                    onClick={() =>
                                        handleRecurringOptions(field.field_row_id, field.options[1], index, stepindex)
                                    }
                                >
                                    {field.options[1]}
                                </div>
                            </div>

                            <div>
                                {field.recurring_switch === field.recurring_option && (
                                    <div>
                                        {field.default_value.map((arr, arrIndex) => (
                                            <div key={'value' + arrIndex}>
                                                {arr.map((field, fieldIndex) => (
                                                    <div>{ReturnFormFields(field, fieldIndex, index, arr)} </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}{' '}
                            </div>
                        </div>
                    </div>
                )}

                {field.type === 'conditional' && (
                    <div className="mt-4">
                        <label> {field.label} </label>
                        <div>
                            {' '}
                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0 flex-wrap gap-2 w-full mt-2">
                                {field.options.map((op, opIndex) => (
                                    <div key={opIndex} className="flex items-center ">
                                        <input
                                            name={'notification-method' + field.field_row_id}
                                            id={'notification-method' + field.field_row_id + ' ' + opIndex}
                                            type="radio"
                                            defaultChecked={field.default_value === op}
                                            onChange={(e) =>
                                                handleConditionalRadio(
                                                    e.target.checked,
                                                    field.field_row_id,
                                                    op,
                                                    stepindex
                                                )
                                            }
                                            className="!h-[20px] !w-[20px] cursor-pointer rounded-full !border-gray-300 !text-primary focus:!ring-primary"
                                        />
                                        <label
                                            htmlFor={'notification-method' + field.field_row_id + ' ' + opIndex}
                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {convertUnderscoresToSpaces(op)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {field?.conditional_steps[convertSpacesToUnderscores(field?.default_value)]
                                    ?.display_on_new_page
                                    ? ''
                                    : field.conditional_steps[
                                          convertSpacesToUnderscores(field.default_value)
                                      ]?.steps[0]?.fields?.map((field, fieldIndex) => (
                                          <div> {ReturnFormFields(field, fieldIndex, index, {})} </div>
                                      ))}
                            </div>
                        </div>
                    </div>
                )}

                {field?.type === 'phone-number' && (
                    <div className="mt-4">
                        <div>
                            <div>
                                <PhoneInputComponent
                                    title={field?.label}
                                    placeholder="Enter Phone Number"
                                    titleSx=""
                                    sx="!rounded-xl"
                                    atChange={(phone) =>
                                        phonechangeHandler(phone, stepindex, index, field.field_row_id)
                                    }
                                    value={field?.default_value}
                                />
                            </div>
                            {field?.default_value &&
                            phoneStepIndex === stepindex &&
                            phoneFieldIndex === index &&
                            phoneError ? (
                                <div className="text-red-900 small-text font-poppins">Invalid phone number </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )}

                {field?.type === 'first-name' || field?.type === 'middle-name' || field.type === 'last-name' ? (
                    <div className="mt-4">
                        <div>
                            <InputField
                                classes=" mt-2"
                                labelSx="  "
                                label={field?.label}
                                inputValue={field?.default_value}
                                name="form"
                                placeholder={field?.placeholder || ''}
                                maxLength={30}
                                allowSpaces={false}
                                onChange={(e) => changeHandler(e, stepindex, index, field.field_row_id)}
                                required={true}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {field?.type === 'abn' ? (
                    <div className="mt-4">
                        <label>{field?.label}</label>
                        <input
                            type="number"
                            className={`px-3 mt-2  `}
                            placeholder={field?.placeholder || ''}
                            name="abnNumber"
                            value={field?.default_value}
                            onChange={(e) => handleAbnNumber(e, stepindex, index, field.field_row_id)}
                            required
                        />
                        {field?.default_value && abnStepIndex === stepindex && abnFieldIndex === index && abnError ? (
                            <div className="text-red-900 small-text font-poppins">Invalid abn </div>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )}

                {field?.type === 'tfn' ? (
                    <div className="mt-4">
                        <label>{field?.label}</label>
                        <input
                            type="number"
                            className={`px-3 mt-2  `}
                            placeholder={field?.placeholder || ''}
                            name="abnNumber"
                            value={field?.default_value}
                            onChange={(e) => handleTfnNumber(e, stepindex, index, field.field_row_id)}
                            required
                        />
                        {field?.default_value && tfnStepIndex === stepindex && tfnFieldIndex === index && tfnError ? (
                            <div className="text-red-900 small-text font-poppins">Invalid TFN </div>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )}

                {field?.type === 'address' ? (
                    <div className="mt-4">
                        <PickAddress
                            id={field.field_row_id}
                            address={field?.default_value ? field?.default_value : ''}
                            setAddress={(address) => handleNewAddress(field.field_row_id, address)}
                            onSelect={(address) => handleNewAddress(field.field_row_id, address.formatted_address)}
                            label={field?.label}
                            placeholder={'Address'}
                            required={true}
                        />
                    </div>
                ) : (
                    ''
                )}

                {field.type === SALARY_WAGES_OCCUPATION && (
                    <div>
                        <div className="mt-2">
                            <label>{field?.label}</label>
                        </div>

                        <div className="mt-2">
                            {' '}
                            <SearchableComboboxSSR
                                fetchDataFunction={fetchWagesOptions}
                                defaultSelected={field?.default_value}
                                removePreviousSelected
                                placeholder="Select"
                                inputsx="!font-normal !text-sm"
                                sx="!rounded-xl  !font-normal !ring-gray-400 mt-0 !flex"
                                showNameOnly
                                onChange={(obj) => {
                                    changeHandler(
                                        {
                                            target: {
                                                value: obj[0]
                                            }
                                        },
                                        stepindex,
                                        index,
                                        field.field_row_id
                                    );
                                }}
                                limit={1}
                            />
                        </div>
                    </div>
                )}

                {field.type === BUSINESS_INDUSTRY_OCCUPATION && (
                    <div>
                        <div className="mt-2">
                            <label>{field?.label}</label>
                        </div>

                        <div className="mt-2">
                            <SearchableComboboxSSR
                                fetchDataFunction={fetchBusinessOccupationOptions}
                                defaultSelected={field?.default_value}
                                removePreviousSelected
                                placeholder="Select"
                                inputsx="!font-normal !text-sm"
                                sx="!rounded-xl  !font-normal !ring-gray-400 mt-0 !flex"
                                showNameOnly
                                onChange={(obj) => {
                                    changeHandler(
                                        {
                                            target: {
                                                value: obj[0]
                                            }
                                        },
                                        stepindex,
                                        index,
                                        field.field_row_id
                                    );
                                }}
                                limit={1}
                            />
                        </div>
                    </div>
                )}

                {field?.type === BSB && (
                    <div>
                        <div className="mt-2">
                            <label>{field?.label}</label>
                        </div>
                        <div className="mt-2">
                            <BsbInput
                                onChange={(value) =>
                                    changeHandler(
                                        {
                                            target: {
                                                value: value
                                            }
                                        },
                                        stepindex,
                                        index,
                                        field.field_row_id
                                    )
                                }
                                placeholder={field?.placeholder ?? 'Enter BSB Code'}
                                value={field?.default_value ? field?.default_value : ''}
                                disabled={false}
                            />
                        </div>
                    </div>
                )}

                {field?.type === 'image' && (
                    <div className="mt-4">
                        <div>
                            <div>{field?.label}</div>
                            <input
                                type="file"
                                style={{ visibility: 'hidden' }}
                                id="input-file"
                                accept="image/jpeg, image/jpg, image/heic, image/heif"
                                onChange={(e) =>
                                    handleReplaceFile(
                                        e,
                                        stepindex,
                                        index,
                                        field?.form_field_id,
                                        'image',
                                        field.field_row_id
                                    )
                                }
                            />

                            <div className="w-full relative mt-2 h-[200px] border border-secondarybg overflow-hidden rounded-lg cursor-pointer">
                                <div className="absolute !cursor-pointer h-full w-full  flex justify-center flex-col gap-5 items-center backdrop-blur-[1px] rounded-xl">
                                    <label htmlFor="input-file" className="bg-white rounded-xl p-5 !cursor-pointer ">
                                        <ImageIcon />
                                    </label>
                                    <div className="bg-white text-black">
                                        {field?.default_value[0]?.originalname?.slice(-20)}
                                    </div>
                                </div>
                                <img
                                    src={field?.default_value[0]?.url}
                                    alt="alt"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    </div>
                )}

                {field?.type === 'document' && (
                    <div className="mt-4">
                        <input
                            type="file"
                            style={{ visibility: 'hidden' }}
                            id="input-file-pdf"
                            accept="application/pdf"
                            onChange={(e) =>
                                handleReplaceFile(
                                    e,
                                    stepindex,
                                    index,
                                    field?.form_field_id,
                                    'document',
                                    field.field_row_id
                                )
                            }
                        />

                        <div>{field?.label}</div>
                        <div className="flex gap-2 items-center flex-wrap">
                            <div className="w-full h-[200px] relative border mt-2 cursor-pointer bg-thirdbg text-white buttons-font font-poppins border-secondarybg overflow-hidden rounded-lg flex justify-center items-center">
                                <div className="absolute  !cursor-pointer h-full w-full flex-col rounded-xl gap-5 flex justify-center items-center backdrop-blur-[15px]">
                                    <label
                                        htmlFor="input-file-pdf"
                                        className="bg-white rounded-xl p-5 !cursor-pointer "
                                    >
                                        <FileIcon />
                                    </label>
                                    <div>{field?.default_value[0]?.originalname}</div>
                                </div>
                                <div className="flex gap-2 items-center flex-col">
                                    <div>
                                        <FileIcon />
                                    </div>
                                    <div>{field?.default_value[0]?.originalname}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {field?.type === LIST && (
                    <div className="mt-4">
                        <PrimaryLabel>{field?.label}</PrimaryLabel>
                        <div className="mt-2">
                            {field?.default_value?.map((input, i) => (
                                <div className="" key={i}>
                                    <div className="grid grid-cols-12 mt-1">
                                        <div className="col-span-11">
                                            <Input
                                                onChange={(e) =>
                                                    changeHandler(
                                                        e,
                                                        stepindex,
                                                        index,
                                                        field?.form_field_id,
                                                        i,
                                                        'form_field_id'
                                                    )
                                                }
                                                type="text"
                                                placeholder={field?.placeholder ?? ''}
                                                required
                                                value={input}
                                                maxLength={50}
                                                useWithoutForm={true}
                                            />
                                        </div>
                                        <div className="col-span-1 flex justify-center items-center">
                                            {field.default_value?.length > 1 && i < field?.default_value?.length - 1 ? (
                                                <div
                                                    onClick={() =>
                                                        addRemoveListHandler(
                                                            field?.form_field_id,
                                                            i,
                                                            'form_field_id',
                                                            'delete'
                                                        )
                                                    }
                                                    className="cursor-pointer"
                                                >
                                                    <BgDeleteIcon />
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() =>
                                                        addRemoveListHandler(field?.form_field_id, i, 'form_field_id')
                                                    }
                                                    className="cursor-pointer z-10"
                                                >
                                                    <PlusIcon className={'fill-gray-800'} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {field.type === 'file' && (
                    <div className="add-ann-form mt-2">
                        <label>{field?.label}</label>
                        <input
                            id="file-input"
                            type="file"
                            onChange={(e) =>
                                handleAddFile(e, stepindex, index, field?.form_field_id, field?.customer_form_data_id)
                            }
                            style={{ display: 'none' }}
                        />
                        <div>
                            <div>
                                <div className=" add-ann-img-form mt-2 ">
                                    <label htmlFor="file-input" className="upload-app-label">
                                        <div className="add-app-camera flex justify-center">
                                            <ImageIcon />
                                        </div>
                                        <div className="add-app-camera-text mt-2">Click to upload app image</div>
                                    </label>
                                </div>
                                <div className="flex gap-2 items-center flex-wrap">
                                    {field?.default_value?.map((op, opIndex) => (
                                        <div className="my-3 thumbnail-wrapper relative ">
                                            <div
                                                onClick={() =>
                                                    handleRemoveFile(
                                                        stepindex,
                                                        index,
                                                        opIndex,
                                                        field.options[opIndex].field_file_id,
                                                        field?.customer_form_data_field_id
                                                    )
                                                }
                                                className="absolute top-1 right-1 cursor-pointer"
                                            >
                                                {' '}
                                                <BgTimes />{' '}
                                            </div>
                                            <div>
                                                <img
                                                    src={op?.url}
                                                    alt="alt"
                                                    className="thumbnail-image cursor-pointer"
                                                    onClick={() => {
                                                        setShowModal(true);
                                                    }}
                                                />
                                                {showModal && (
                                                    <ZoomImage
                                                        src={op?.url}
                                                        alt="snow"
                                                        onClose={() => setShowModal(false)}
                                                    />
                                                )}
                                            </div>
                                            <div className="add-ann-form text-white flex justify-center items-center">
                                                {' '}
                                                <label>{op?.originalname?.slice(-8)}</label>{' '}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {field.type === 'radio' && (
                    <div className="mt-4 add-ann-form">
                        <div>
                            {' '}
                            <label> {field.label}</label>{' '}
                        </div>

                        <label>
                            <div className="flex gap-5 items-center flex-wrap mt-2 ">
                                {field?.options?.map((op, opIndex) => (
                                    <div className="flex items-center gap-2 h-5" key={generateId()}>
                                        <input
                                            name={'notification-method' + field.field_row_id}
                                            id={'notification-method' + field.field_row_id + ' ' + opIndex}
                                            type="radio"
                                            defaultChecked={field.default_value === op}
                                            onChange={(e) =>
                                                handleRadioCheck(e, stepindex, index, op, field.field_row_id)
                                            }
                                            className="!h-[20px] !w-[20px] rounded-full !border-gray-300 !text-primary focus:!ring-primary"
                                        />
                                        <label
                                            htmlFor={'notification-method' + field.field_row_id + ' ' + opIndex}
                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {convertUnderscoresToSpaces(op)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </label>
                    </div>
                )}

                {field.type === 'selection' ? (
                    <div className="mt-4 ">
                        <div> {field.label}</div>
                        <select className=" mt-2 !text-[0.9rem]">
                            <option value="">Select</option>
                            {field?.options?.map((option) => (
                                <option value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                ) : (
                    ''
                )}

                {field.type === 'checkbox' && (
                    <div className="mt-4 ">
                        <div>
                            {' '}
                            <label>{field?.label}</label>{' '}
                        </div>
                        <div className="flex gap-5 mt-2 flex-wrap items-center">
                            {field?.options.map((op, i) => (
                                <div key={'obj' + i} className="flex gap-2 items-center">
                                    <div>{op}</div>
                                    <div className="field-checkbx-wrapper ">
                                        <input
                                            type="checkbox"
                                            name=""
                                            id=""
                                            className="!rounded-[5px] !h-[5px] !w-[5px] !border-[#979797] !text-gray-800 focus:ring-gray-800"
                                            onChange={(e) =>
                                                handleCheckBox(e, stepindex, index, op, field.field_row_id)
                                            }
                                            checked={field?.default_value?.includes(op) ? true : false}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {field.type === 'switch' && (
                    <div className="mt-4 add-ann-form">
                        <div>
                            {' '}
                            <label> {field.label} </label>{' '}
                        </div>
                        <div className="field-switch-wrapper mt-2">
                            <div
                                onClick={() => handleSwitch(stepindex, index, field.options[0], field.field_row_id)}
                                className={`field-switch cursor-pointer ${
                                    field.default_value === field.options[0] && 'active'
                                } flex justify-center items-center`}
                            >
                                {' '}
                                {field.options[0]}{' '}
                            </div>

                            <div
                                onClick={() => handleSwitch(stepindex, index, field.options[1], field.field_row_id)}
                                className={`field-switch cursor-pointer  ${
                                    field.default_value === field.options[1] && 'active'
                                }  flex justify-center items-center`}
                            >
                                {' '}
                                {field.options[1]}{' '}
                            </div>
                        </div>
                    </div>
                )}

                {field?.type === 'dropdown' && (
                    <div className="mt-4 add-ann-form">
                        <div>
                            {' '}
                            <label> {field?.label} </label>{' '}
                        </div>
                        <div>
                            <select
                                onChange={(e) => handleDropDown(e, stepindex, index, field.field_row_id)}
                                value={field?.default_value}
                                className="!text-[0.9rem] font-poppins"
                            >
                                {field?.options?.map((op, index) => (
                                    <option
                                        key={'op' + index}
                                        value={op}
                                        selected={field?.default_value === op ? true : false}
                                    >
                                        {op}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}

                {field?.type === 'email' ? (
                    <div className="mt-4 add-ann-form">
                        <div>
                            <div>
                                {' '}
                                <label> {field?.label} </label>{' '}
                            </div>
                            <div className=" mt-1">
                                <input
                                    type="email"
                                    className="field-input"
                                    placeholder={field?.example}
                                    value={field?.default_value}
                                    onChange={(e) => changeHandler(e, stepindex, index, field.field_row_id)}
                                    maxLength={40}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {field?.type === 'business-email' ? (
                    <div className="mt-4 add-ann-form">
                        <div>
                            <div>
                                {' '}
                                <label> {field?.label} </label>{' '}
                            </div>
                            <div className=" mt-1">
                                <input
                                    type="email"
                                    className="field-input"
                                    placeholder={field?.example}
                                    value={field?.default_value}
                                    onChange={(e) => changeHandler(e, stepindex, index, field.field_row_id)}
                                    maxLength={40}
                                />
                            </div>
                            {field?.default_value &&
                            BusemailStepIndex === stepindex &&
                            BusemailFieldIndex === index &&
                            BusemailError ? (
                                <div className="text-red-900 small-text font-poppins">Invalid email </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {field?.type === 'password' && (
                    <div className="mt-2 add-ann-form">
                        <div>
                            {' '}
                            <label> {field?.label} </label>{' '}
                        </div>
                        <div className="mt-1">
                            <input
                                type="password"
                                className="field-input"
                                placeholder={field?.example}
                                value={field?.default_value}
                                onChange={(e) => changeHandler(e, stepindex, index, field.field_row_id)}
                            />
                        </div>
                    </div>
                )}
                {field?.type === 'date' && (
                    <div className="mt-4 ">
                        <div>
                            {' '}
                            <label> {field?.label} </label>{' '}
                        </div>
                        <div className=" mt-2">
                            <span>
                                <DatePick
                                    iconSet={datePickerProps}
                                    onDateChange={(date) => handleDateEdit(date, stepindex, index, field.field_row_id)}
                                    placeholder="date"
                                    value={field?.default_value ? dayjs(field?.default_value, 'DD-MM-YYYY') : ''}
                                    bg="transparent"
                                    sx="!rounded-xl text-gray-900 text-secondarybg placeholder-black"
                                    border="#979797"
                                    width="100%"
                                    isNextDateRequired={false}
                                    minDate={minDate}
                                />
                            </span>
                        </div>
                    </div>
                )}

                {field?.type === 'date-of-birth' && (
                    <div className="mt-4 ">
                        <div>
                            {' '}
                            <label> {field?.label} </label>{' '}
                        </div>
                        <div className=" mt-2">
                            <span>
                                <DatePick
                                    iconSet={datePickerProps}
                                    onDateChange={(date) => handleDateEdit(date, stepindex, index, field.field_row_id)}
                                    placeholder="date"
                                    value={field?.default_value ? dayjs(field?.default_value, 'DD-MM-YYYY') : ''}
                                    bg="transparent"
                                    sx="!rounded-xl text-gray-900 text-secondarybg placeholder-black"
                                    border="#979797"
                                    width="100%"
                                    isNextDateRequired={false}
                                    maxDate={twelveYearsAgo}
                                    minDate={minDate}
                                />
                            </span>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return (
        <div className="add-p-side grid grid-cols-10 ">
            <div className="col-span-2 left-side"></div>
            <div className="right-side col-span-8">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    <div>
                        <div
                            onClick={toggleEditForm}
                            className="absolute   text-white p-2 right-1 top-1 cursor-pointer"
                        >
                            <SideTimes />
                        </div>

                        <div className="add-detail pt-5 px-5">
                            <div className="title">{form?.form_name}</div>
                        </div>
                    </div>

                    <div className="flex gap-5  flex-wrap  mx-5">
                        {customerForm &&
                            customerForm?.steps?.map((step, stepindex) => (
                                <div className={`${!step?.is_skipped && 'w-[32%]'} `}>
                                    {' '}
                                    {!step?.is_skipped && (
                                        <div key={stepindex} className="border border-[#BBBBBB] rounded-xl my-5 p-3 ">
                                            <div className="lg-title my-2">{step?.step_heading}</div>
                                            <div className="add-ann-form">
                                                {step?.fields?.map((field, index) => (
                                                    <div> {ReturnFormFields(field, index, stepindex, step)} </div>
                                                ))}
                                            </div>

                                            <div className="my-4">{handleConditions(step)}</div>
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className="my-5 flex justify-center items-center">
                        <button
                            disabled={updateLoader ? true : false}
                            onClick={handleSubmit}
                            className="ann-btn rounded-xl px-10 flex gap-2 buttons-font-lighter font-fira items-center"
                        >
                            {' '}
                            Save {updateLoader && <SmallLoaderWhite />}
                        </button>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};
