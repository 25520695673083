import React, { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

import { cn, useFieldPageCoords } from './helper';
import { Card, CardContent } from './card';

export function FieldContainerPortal({ field, children, className = '' }) {
    const coords = useFieldPageCoords(field);

    return createPortal(
        <div
            className={cn('absolute', className)}
            style={{
                top: `${coords.y}px`,
                left: `${coords.x}px`,
                height: `${coords.height}px`,
                width: `${coords.width}px`
            }}
        >
            {children}
        </div>,
        document.body
    );
}

export function FieldRootContainer({ field, children }) {
    const [isValidating, setIsValidating] = useState(false);

    const ref = React.useRef(null);

    const observerFn = () => {
        const observer = new MutationObserver((_mutations) => {
            if (ref.current) {
                setIsValidating(ref.current.getAttribute('data-validate') === 'true');
            }
        });

        observer.observe(ref.current, {
            attributes: true
        });

        return () => {
            observer.disconnect();
        };
    };
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const observerCleanup = observerFn();
        return () => {
            observerCleanup();
        };
    }, []);

    return (
        <FieldContainerPortal field={field}>
            <Card
                id={`field-${field.document_field_id}`}
                className={cn(
                    'field-card-container flex justify-center items-center  bg-background shadow-custom relative z-20 min-h-[50px] max-h-[150px] max-w-[400px] min-w-[200px] transition-all elementToFocus',
                    {
                        'border-grey-300 ring-1 ring-orange-300': !field.inserted && isValidating
                    }
                )}
                ref={ref}
                data-inserted={field.inserted ? 'true' : 'false'}
            >
                <CardContent className="text-foreground hover:shadow-primary-foreground group flex h-full w-full flex-col items-center justify-center p-2">
                    {children}
                </CardContent>
            </Card>
        </FieldContainerPortal>
    );
}
