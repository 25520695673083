import React from 'react';
import '../../css-steps/signup-css/stepThree.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../common/Loader';
import 'react-notifications/lib/notifications.css';
import { useSelector, useDispatch } from 'react-redux';
import { SecurityInfo } from './signup-utills/securityQuestions/SecurityInfo';
import securityQuestionsText from './signup-utills/SignupText';
import { setLoader } from '../../store/global/globalReducer';
import { getSecurityQuestionsThunk } from '../../store/auth/slices';
import { isQuestionIdRepeated } from '../../utills/dataValidation';
import { toastHandler } from '../../responseHanlder';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
const StepThree = (props) => {
    const { user_id } = useParams();

    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);
    const [securityQuestions, setSecurityQuestions] = useState(null);

    const [selectedOption, setSelectedOption] = useState({
        key: '',
        value: ''
    });

    const [secondOption, setSecondOption] = useState({
        key: '',
        value: ''
    });

    const [thirdOption, setThirdOption] = useState({
        key: '',
        value: ''
    });
    const [firstAnswer, setFirstAnswer] = useState('');
    const [secondAnswer, setSecondAnswer] = useState('');
    const [thirdAnswer, setThirdAnswer] = useState('');

    const handleSelect = (obj) => {
        const newSelected = { ...selectedOption };
        newSelected.key = obj?.question_id;
        newSelected.value = obj?.question;
        setSelectedOption(newSelected);
    };

    const handleSecondSelect = (obj) => {
        const newSelected = { ...secondOption };
        newSelected.key = obj?.question_id;
        newSelected.value = obj?.question;
        setSecondOption(newSelected);
    };

    const handleThirdSelect = (obj) => {
        const newSelected = { ...thirdOption };
        newSelected.key = obj?.question_id;
        newSelected.value = obj?.question;
        setThirdOption(newSelected);
    };

    const showSteptwo = () => {
        props.handleCondition(1);
    };

    const handleSubmit = () => {
        const questionsData = {
            user_id: user_id,
            user_type: 'employee',
            questions: [
                {
                    question_id: selectedOption.key,
                    value: firstAnswer
                },
                {
                    question_id: secondOption.key,
                    value: secondAnswer
                },
                {
                    question_id: thirdOption.key,
                    value: thirdAnswer
                }
            ]
        };

        if (questionsData.questions.some((question) => question.question_id === '')) {
            toastHandler('Please select atleast three questions to proceed', 'error');
            return;
        }

        if (isQuestionIdRepeated(questionsData.questions)) {
            toastHandler("You can't select same question twice", 'error');
            return;
        }

        const values = questionsData.questions.map((question) => question.value);

        const hasDuplicates = new Set(values).size !== values.length;

        if (
            (!questionsData.questions[0].value && !questionsData.questions[1].value) ||
            !questionsData.questions[2].value
        ) {
            return toastHandler('All answers are required', TOAST_TYPE_ERROR);
        }
        if (hasDuplicates) {
            return toastHandler('Answers cannot be repeated', TOAST_TYPE_ERROR);
        }

        localStorage.setItem('questions', JSON.stringify(questionsData));
        props.handleCondition(5);
    };

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(getSecurityQuestionsThunk())
            .then((response) => {
                setSecurityQuestions(response.payload);
                dispatch(setLoader(false));
            })
            .catch((_error) => {
                dispatch(setLoader(false));
            });

        dispatch(setLoader(false));
    }, []);

    const [_filteredOptions, setfilteredOptions] = useState([]);

    const filterSelectedQuestions = () => {
        const filteredQuestions = securityQuestions?.filter(
            (item) =>
                item?.question_id !== selectedOption?.key &&
                item?.question_id !== secondOption?.key &&
                item?.question_id !== thirdOption?.key
        );

        return filteredQuestions;
    };

    useEffect(() => {
        setfilteredOptions(filterSelectedQuestions());
    }, [selectedOption, secondOption, thirdOption]);
    return (
        <div className="pb-5 ">
            <div>
                {' '}
                {isLoading && (
                    <div className="loader-overlay">
                        <Loader />
                    </div>
                )}
                <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8 ">
                    <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                        <div className="text-center  mt-3   card-layout-title-text ">Security Questions</div>

                        <div className="security-body p-5 px-16 ">
                            <SecurityInfo text={securityQuestionsText.textOne} />
                            <SecurityInfo text={securityQuestionsText.textTwo} />
                            <SecurityInfo text={securityQuestionsText.textThree} />
                        </div>

                        <div>
                            {' '}
                            <div className="security-qa default-forms font-poppins px-28 mt-5 ">
                                <div className="">
                                    <h1 className="table-title">Question 1</h1>

                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="question"
                                        optionsData={
                                            securityQuestions?.filter(
                                                (item) =>
                                                    item?.question_id !== selectedOption?.key &&
                                                    item?.question_id !== secondOption?.key &&
                                                    item?.question_id !== thirdOption?.key
                                            ) || []
                                        }
                                        sx="rounded-xl py-3 buttons-font ring-gray-400 mt-2"
                                        onChangeValue={(obj) => handleSelect(obj)}
                                        removeSelected={true}
                                    />

                                    <div className="q1-input">
                                        <input
                                            type="text"
                                            className="px-3 "
                                            name="securityAnswer1"
                                            placeholder="Answer"
                                            maxLength={30}
                                            onChange={(e) => setFirstAnswer(e.target.value)}
                                            value={firstAnswer}
                                        />
                                    </div>
                                </div>

                                <div className=" mt-5">
                                    <h1 className="table-title">Question 2</h1>

                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="question"
                                        optionsData={
                                            securityQuestions?.filter(
                                                (item) =>
                                                    item?.question_id !== selectedOption?.key &&
                                                    item?.question_id !== secondOption?.key &&
                                                    item?.question_id !== thirdOption?.key
                                            ) || []
                                        }
                                        sx="rounded-xl py-3 mt-2 ring-gray-400 buttons-font"
                                        onChangeValue={(obj) => handleSecondSelect(obj)}
                                        removeSelected={true}
                                    />

                                    <div className="q1-input">
                                        <input
                                            type="text"
                                            className="px-3 "
                                            placeholder="Answer"
                                            name="securityAnswer2"
                                            maxLength={30}
                                            onChange={(e) => setSecondAnswer(e.target.value)}
                                            value={secondAnswer}
                                        />
                                    </div>
                                </div>

                                <div className=" mt-5">
                                    <h1 className="table-title">Question 3</h1>

                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="question"
                                        optionsData={
                                            securityQuestions?.filter(
                                                (item) =>
                                                    item?.question_id !== selectedOption?.key &&
                                                    item?.question_id !== secondOption?.key &&
                                                    item?.question_id !== thirdOption?.key
                                            ) || []
                                        }
                                        sx="rounded-xl py-3 ring-gray-400 mt-2 buttons-font"
                                        onChangeValue={(obj) => handleThirdSelect(obj)}
                                    />

                                    <div className="q1-input">
                                        <input
                                            type="text"
                                            className="px-3 "
                                            name="securityAnswer3"
                                            placeholder="Answer"
                                            maxLength={30}
                                            onChange={(e) => setThirdAnswer(e.target.value)}
                                            value={thirdAnswer}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sq-btns my-5  mt-10 mx-28 ">
                                <div className="flex justify-between items-center">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={showSteptwo}
                                            className="btn-1 buttons-font font-fira  px-14 "
                                        >
                                            Back
                                        </button>
                                    </div>

                                    <div>
                                        <button onClick={handleSubmit} className="btn-2 buttons-font font-fira  px-14 ">
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{' '}
            </div>
        </div>
    );
};

export default StepThree;
