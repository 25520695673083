import { Bars3Icon, FolderIcon, XCircleIcon } from '@heroicons/react/20/solid';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { Button } from 'components/atoms/buttons/Button';
import React from 'react';
import { useDrop } from 'react-dnd';
import { useDndScrolling } from 'react-dnd-scrolling';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { crossTimes } from 'utills/svgs/crossTimes';

import { arrayMoveImmutable } from 'array-move';
import Input from 'components/atoms/Input';

const SelectedFilesList = ({ setFileList, setOpen, list, handleSelectId, fileTitle = '', setFileTitle }) => {
    const [, ref] = useDrop({
        accept: 'DIV',
        drop: (droppedItem) => {
            handleSelectId(droppedItem);
        }
    });
    useDndScrolling(ref);

    const onSortEndTasks = (oldIndex, newIndex) => {
        const newArray = arrayMoveImmutable(list, oldIndex, newIndex);

        setFileList(newArray);
    };
    return (
        <div className="default-opacity-bg px-4 py-3 rounded-lg max-h-[700px] overflow-auto h-full" ref={ref}>
            <div className="w-full flex justify-between items-center">
                <div className="text-xl font-[500]">Selected Files</div>
                <Button
                    title={'Clear All'}
                    classes={
                        'flex items-center gap-1 !px-2 text-third cursor-pointer py-1.5 rounded-xl hover:default-opacity-bg hover:!shadow-none'
                    }
                    iconSet={{ leftIcon: crossTimes }}
                    atClick={() => {
                        setFileList([]);
                        setOpen(null);
                    }}
                />
            </div>
            <div className="my-2 max-h-[450px] overflow-auto">
                <SortableList onSortEnd={onSortEndTasks} className="list" draggedItemClassName="draggedSelectedItem">
                    {list?.map((obj, index) => (
                        <SortableItem key={'stortable_item' + index} className="draggedSelectedItem">
                            <div key={'selected_item' + index} className="flex items-center justify-between my-1 gap-2">
                                <SortableKnob>
                                    <div className="cursor-grab">
                                        <Bars3Icon className="w-8" />
                                    </div>
                                </SortableKnob>
                                <div className="flex flex-1 items-center gap-2 overflow-hidden truncate">
                                    {obj?.type === 'file' ? (
                                        <GenerateFileIcon url={obj?.url} sx={'w-8'} />
                                    ) : (
                                        <FolderIcon className={'w-8'} />
                                    )}
                                    <div className="flex flex-col truncate">
                                        <div className="app-obj-name truncate">{obj?.name}</div>
                                    </div>
                                </div>
                                <span className="bg-third w-6 h-6 text-center rounded-full text-white">
                                    {index + 1}
                                </span>
                                <Button
                                    classes={
                                        'flex items-center !px-2 cursor-pointer py-1.5 rounded-xl hover:default-opacity-bg hover:!shadow-none'
                                    }
                                    iconSet={{ leftIconHTML: <XCircleIcon className="w-6 h-6" /> }}
                                    atClick={() => {
                                        setFileList((prevList) =>
                                            prevList.filter((listObj) => listObj?.job_drive_id !== obj?.job_drive_id)
                                        );
                                    }}
                                />
                            </div>
                        </SortableItem>
                    ))}
                </SortableList>
            </div>
            <div className="mt-5">
                <Input
                    useWithoutForm
                    type={'text'}
                    sx={'!py-3 !rounded-xl !bg-transparent !pl-3 !border-secondarybg'}
                    title="Collate Files Title"
                    titleSx={'text-semibold'}
                    placeholder="Enter Title"
                    hasError={fileTitle === false}
                    value={fileTitle !== false ? fileTitle || '' : ''}
                    onChange={(e) => {
                        setFileTitle(e.target.value);
                    }}
                />
            </div>
        </div>
    );
};

export default SelectedFilesList;
