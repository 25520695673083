import { Text } from 'components/atoms/inputs/Text';
import React, { useState } from 'react';
import './css/global-components.css';
import { motion } from 'framer-motion';
import { toastHandler } from 'responseHanlder';
import { SideTimes } from 'utills/svgs/SideTimes';
import { Button } from 'components/atoms/buttons/Button';

export const AddHint = ({ toggleModal, handleHint, stepIndex, fieldIndex, field, viewHint, newHint }) => {
    const [hint, setHint] = useState(field?.hint);
    const handleOnChange = (e) => {
        const value = e.target.value;
        setHint(value);
    };
    //hint

    const handleAddHint = () => {
        if (!hint) {
            toastHandler('please add hint', 'error');
            return;
        }

        handleHint(hint, stepIndex, fieldIndex);
        toggleModal();
    };
    return (
        <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="add-hint-container "
        >
            {viewHint ? (
                <div className="p-3 default-forms">
                    {' '}
                    <label>{newHint}</label>{' '}
                </div>
            ) : (
                <div className="w-full h-full relative p-3">
                    <div className="flex justify-end items-center cursor-pointer" onClick={toggleModal}>
                        <SideTimes />
                    </div>{' '}
                    <div className=" default-forms">
                        <label> Add Hint</label>
                    </div>
                    <div className="mt-2">
                        {' '}
                        <Text
                            length={80}
                            placeholder={'Enter hint'}
                            isRequired={true}
                            atChange={handleOnChange}
                            value={hint}
                        />{' '}
                        <div className="mt-2 flex justify-end items-center">
                            <Button
                                title={'Add'}
                                atClick={() => handleAddHint()}
                                isDisabled={false}
                                classes={'px-5 rounded-lg ann-btn buttons-font font-fira'}
                                type="button"
                                styles={{}}
                            />
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};
