import React from 'react';
import { useState } from 'react';
import '../../css-steps/login-css/forgotPass.css';
import { validateEmail } from '../../utills/FormValidation';
import 'react-notifications/lib/notifications.css';
import { Loader } from '../common/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader } from '../../store/global/globalReducer';
import { forgotPasswordThunk } from '../../store/auth/slices';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import Recaptcha from 'components/molecules/Recaptcha';
import { useCaptchaHandler } from 'Hooks/reCaptcha/useCaptchaHandler';

const ForgotPass = (props) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);
    const { error, setError, isCaptchaRequired, recaptchaRef, handleRequest, handleCaptchaChange } =
        useCaptchaHandler();
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email === '') {
            toastHandler('Please enter email', 'error');
            return;
        } else if (!validateEmail(email)) {
            toastHandler('Invalid email', 'error');
            return;
        }

        const forgotPassData = {
            email,
            user_type: 'employee'
        };
        dispatch(setLoader(true));

        // await dispatch(forgotPasswordThunk(forgotPassData))
        await handleRequest(
            'forgotPassword',
            (payload = {}) =>
                dispatch(
                    forgotPasswordThunk({
                        ...payload
                    })
                ),
            forgotPassData
        )
            .then((response) => {
                dispatch(setLoader(false));
                if (response?.payload) {
                    toastHandler('OTP sent to your email', TOAST_TYPE_SUCCESS);
                    props.handleCondition(3);
                    const forgotPassData = {
                        email,
                        otp: false,
                        questions: false
                    };

                    localStorage.setItem('pre_auth_token', response.payload.authData.pre_auth_token);
                    sessionStorage.setItem('forgotPasswordData', JSON.stringify(response.payload.authData));
                    localStorage.setItem('forgotPassData', JSON.stringify(forgotPassData));
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });

        dispatch(setLoader(false));
    };

    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2 ">
            {isLoading && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                {' '}
                <div className="card-layout-title-text mt-5 flex justify-center items-center">Forgotten password</div>
                <div className="px-24 mt-3">
                    {' '}
                    <div className="small-text font-poppins  text-sixth  ">
                        Please enter your email address to reset your password! A message with code will be sent to an
                        authorised email to reset the password.
                    </div>
                    <form onSubmit={handleSubmit} className="mt-16">
                        <div className="default-forms w-[90%] ml-5">
                            <div>
                                <label className="font-poppins">Email</label>
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    className="mt-2 px-3"
                                    placeholder="Enter your email "
                                    required
                                    maxLength={30}
                                />
                            </div>
                        </div>

                        {isCaptchaRequired && (
                            <div className="my-3">
                                <Recaptcha
                                    ref={recaptchaRef}
                                    captchaError={!!error}
                                    setCaptchaError={setError}
                                    onValueChange={handleCaptchaChange}
                                />
                            </div>
                        )}

                        <div className="mt-[16rem] mb-8">
                            <div className="flex justify-between items-center ">
                                <button
                                    type="button"
                                    onClick={() => props.handleCondition(1)}
                                    className="btn-1 buttons-font font-fira px-12 py-2"
                                >
                                    Back
                                </button>
                                <button type="submit" className=" px-12 rounded-lg buttons-font font-fira py-2 btn-2">
                                    Next
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPass;
