import React from 'react';

export const BlueTick = () => {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM2.88461 12C2.88461 17.0343 6.96571 21.1154 12 21.1154C17.0343 21.1154 21.1154 17.0343 21.1154 12C21.1154 6.96571 17.0343 2.88461 12 2.88461C6.96571 2.88461 2.88461 6.96571 2.88461 12Z"
                    fill="#B695F8"
                />
                <path
                    d="M8 13L10 15L16 9"
                    stroke="#B695F8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
