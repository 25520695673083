import React from 'react';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { FitIcon } from 'utills/svgs/FitIcon';
import { WhiteMinus } from 'utills/svgs/WhiteMinus';
import { WhitePlus } from 'utills/svgs/WhitePlus';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import './css/molecules.css';
import { Link } from 'react-router-dom';
import { SmallLoader } from 'components/common/SmallLoader';
import Slideover from 'components/atoms/SlideOver';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const GlobalPDFViewer = ({ url, setOpen, isLocal, open }) => {
    const [numPages, setNumPages] = useState(null);
    const [isExpand, setIsExpand] = useState(false);
    const [loading, setLoading] = useState(true);
    const [scale, setScale] = useState(1);
    const [rotationAngle, setRotationAngle] = useState(0);

    const handleScale = (val) => {
        setScale(val);
    };

    const handleExpand = () => {
        setIsExpand(!isExpand);
    };

    const handleZoomIn = () => {
        if (scale < 2) {
            handleScale(scale + 0.1);
        }
    };

    const handleZoomOut = () => {
        handleScale(Math.max(scale - 0.1, 0.1));
    };

    const handleRotate = () => {
        setRotationAngle((prevAngle) => prevAngle + 90);
    };

    const zoomPercentage = Math.round(scale * 100);

    const handleFit = () => {
        if (handleExpand) {
            handleExpand();
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setLoading(false);
    };

    const handleDownloadPdf = () => {
        const a = document.createElement('a');
        a.href = url;
        a.download = 'file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <Slideover open={open} setOpen={setOpen} size={isExpand ? '100%' : 'max-w-[1500px]'}>
            <div className="flex flex-col h-full border bg-[#F8F8FE] rounded-xl">
                <div className="pdf-actions-global rounded-2xl shadow flex justify-between items-center p-2 bg-white">
                    <div className="flex gap-5 items-center">
                        <button onClick={handleZoomIn}>
                            <WhitePlus />
                        </button>
                        <div className="bg-white text-black px-2 p-1 rounded-md">{zoomPercentage}%</div>
                        <button onClick={handleZoomOut}>
                            <WhiteMinus />
                        </button>
                        <button onClick={handleRotate}>
                            <ArrowPathIcon className="w-8 h-8" />
                        </button>
                        <div className="flex gap-2 items-center">
                            {' '}
                            |
                            <button onClick={() => handleFit()} className="flex gap-2 items-center">
                                {' '}
                                <FitIcon />
                                {isExpand ? 'Fit' : 'Expand'}
                            </button>
                        </div>
                        {isLocal ? (
                            <div onClick={handleDownloadPdf} className="cursor-pointer">
                                <CloudArrowDownIcon className="w-8 h-8" />
                            </div>
                        ) : (
                            <Link to={url}>
                                <CloudArrowDownIcon className="w-8 h-8" />
                            </Link>
                        )}
                    </div>
                </div>

                {loading && (
                    <div className="flex-grow flex justify-center items-center">
                        <SmallLoader />
                    </div>
                )}

                <div className="flex-grow overflow-auto bg-[#F8F8FE]">
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadProgress={() => setLoading(true)}
                        className="w-full h-full flex flex-col items-center py-1"
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={scale + 0.3}
                                className="w-full flex justify-center"
                                rotate={rotationAngle}
                            />
                        ))}
                    </Document>
                </div>
            </div>
        </Slideover>
    );
};

export default GlobalPDFViewer;
