import { createSlice } from '@reduxjs/toolkit';

const globalReducer = createSlice({
    name: 'global',
    initialState: {
        dashboardApps: null,
        userPasswords: null,
        profileUpdating: false,
        isLoading: false,
        logLoader: false,
        sideLoader: false,
        isSidebarOpen: false,
        pdfLoader: false,
        formSubmissionsId: '',
        singleService: '',
        businessFormEvent: false,
        form: [],
        previewForm: false,
        updateForm: false,
        editForm: [],
        allPermissions: {},
        task: null,
        status: null,
        branch: {},
        image: {},
        intervalId: null,
        profileImg: null,
        businessLogo: null,
        isConfirmationOpen: false,
        showindicators: true,
        selectedClient: '',
        file: null,
        summary_id: null,
        summaryDates: {},
        summaryType: null,
        isAddAppOpen: false,
        owners: [],
        signupConnection: null,
        terms: null,
        formDetails: [],
        code: null,
        deduction: null,
        directory: null,
        app: null,
        note: null,
        thread: null,
        announcement: null,
        taskType: null,
        client: null,
        role_group_id: '',
        recentNotification: null,
        prebuilt_page_size: 10,
        emailIntervalId: null,
        continueEnabled: false,
        temp_auth_interval: null
    },
    reducers: {
        setDashboardApps: (state, action) => {
            state.dashboardApps = action.payload;
        },
        setLoader: (state, action) => {
            state.isLoading = action.payload;
        },
        setSideLoader: (state, action) => {
            state.sideLoader = action.payload;
        },
        setLogLoader: (state, action) => {
            state.logLoader = action.payload;
        },
        setPdfLoader: (state, action) => {
            state.pdfLoader = action.payload;
        },
        handleFormSubmissions: (state, action) => {
            state.formSubmissionsId = action.payload;
        },
        handleBusinessFormEvent: (state, action) => {
            state.businessFormEvent = action.payload;
        },
        formHandler: (state, action) => {
            state.form = action.payload;
        },
        previewFormToggler: (state, action) => {
            state.previewForm = action.payload;
        },
        updateFormToggler: (state, action) => {
            state.updateForm = action.payload;
        },
        handleProfileUpdating: (state, action) => {
            state.profileUpdating = action.payload;
        },
        handleEditForm: (state, action) => {
            state.editForm = action.payload;
        },
        handleUserPasswords: (state, action) => {
            state.userPasswords = action.payload;
        },
        handleAllPermissions: (state, action) => {
            state.allPermissions = action.payload;
        },
        handleTaskDetails: (state, action) => {
            state.task = action.payload;
        },
        handleTaskStatus: (state, action) => {
            state.status = action.payload;
        },
        handleSingleCode: (state, action) => {
            state.code = action.payload;
        },
        editService: (state, action) => {
            state.singleService = action.payload;
        },
        editBranch: (state, action) => {
            state.branch = action.payload;
        },
        handleImage: (state, action) => {
            state.image = action.payload;
        },
        setGlobalSocketConnection: (state, action) => {
            state.intervalId = action.payload;
        },
        handleProfileImage: (state, action) => {
            state.profileImg = action.payload;
        },
        handleBusinessLogo: (state, action) => {
            state.businessLogo = action.payload;
        },
        setIsConfirmationOpen: (state, action) => {
            state.isConfirmationOpen = action.payload;
        },
        handleIndicators: (state, action) => {
            state.showindicators = action.payload;
        },
        setSelectedClient: (state, action) => {
            state.selectedClient = action.payload;
        },
        handlePdfView: (state, action) => {
            state.file = action.payload;
        },
        handleSunmmaryId: (state, action) => {
            state.summary_id = action.payload;
        },
        handleSummaryDates: (state, action) => {
            state.summaryDates = action.payload;
        },
        handleSummaryType: (state, action) => {
            state.summaryType = action.payload;
        },
        handleAddApp: (state, action) => {
            state.isAddAppOpen = action.payload;
        },
        handleOwners: (state, action) => {
            state.owners = action.payload;
        },
        handleSignupConnection: (state, action) => {
            state.signupConnection = action.payload;
        },
        handleExpenseTypeId: (state, action) => {
            state.expense_type_id = action.payload;
        },
        handleDeductionType: (state, action) => {
            state.deduction_type = action.payload;
        },
        handleTermsReducer: (state, action) => {
            state.terms = action.payload;
        },
        handleFormDetails: (state, action) => {
            state.formDetails = action.payload;
        },
        handleCodeUpdate: (state, action) => {
            state.code = action.payload;
        },
        handleDeductionUpdate: (state, action) => {
            state.deduction = action.payload;
        },
        handleEditDirectories: (state, action) => {
            state.directory = action.payload;
        },
        handleEditApp: (state, action) => {
            state.app = action.payload;
        },
        updateNoteHandler: (state, action) => {
            state.note = action.payload;
        },
        handleEditThread: (state, action) => {
            state.thread = action.payload;
        },
        handleEditAnnouncement: (state, action) => {
            state.announcement = action.payload;
        },
        handleEditTaskType: (state, action) => {
            state.taskType = action.payload;
        },
        handleEditClient: (state, action) => {
            state.client = action.payload;
        },
        handleRoleGroupId: (state, action) => {
            state.role_group_id = action.payload;
        },
        handleRecentNotification: (state, action) => {
            state.recentNotification = action.payload;
        },
        handlePrebuiltPageSize: (state, action) => {
            state.prebuilt_page_size = action.payload;
        },
        setContinueEnabled: (state, action) => {
            state.continueEnabled = action.payload;
        },
        handleTempAuthInterval: (state, action) => {
            state.temp_auth_interval = action.payload;
        },
        setIsSidebarOpen: (state, action) => {
            state.isSidebarOpen = action.payload;
        }
    }
});

export const {
    handleDeductionType,
    dashboardApps,
    setDashboardApps,
    setLoader,
    setSideLoader,
    handleFormSubmissions,
    handleBusinessFormEvent,
    formHandler,
    previewFormToggler,
    updateFormToggler,
    handleProfileUpdating,
    handleEditForm,
    handleUserPasswords,
    handleAllPermissions,
    handleTaskDetails,
    handleTaskStatus,
    handleSingleCode,
    editService,
    editBranch,
    handleImage,
    setGlobalSocketConnection,
    handleProfileImage,
    handleBusinessLogo,
    setIsConfirmationOpen,
    handleIndicators,
    setSelectedClient,
    handlePdfView,
    handleSunmmaryId,
    handleSummaryDates,
    handleSummaryType,
    handleAddApp,
    handleOwners,
    handleSignupConnection,
    handleExpenseTypeId,
    handleTermsReducer,
    handleFormDetails,
    handleCodeUpdate,
    handleDeductionUpdate,
    handleEditDirectories,
    handleEditApp,
    updateNoteHandler,
    handleEditThread,
    handleEditAnnouncement,
    handleEditTaskType,
    setPdfLoader,
    handleEditClient,
    handleRoleGroupId,
    handleRecentNotification,
    setLogLoader,
    handlePrebuiltPageSize,
    setContinueEnabled,
    handleTempAuthInterval,
    setIsSidebarOpen
} = globalReducer.actions;

export default globalReducer;
