/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { cn } from 'utills/tailwindUtil';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ path, onClick }) => {
    return (
        <nav className="sm:flex mt-2" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-[2px]">
                {path.map((item, i) => (
                    <li key={'path_' + i}>
                        <div className="flex items-center space-x-[2px]">
                            {i !== 0 && (
                                <ChevronRightIcon className="h-4 w-4 flex-shrink-0 text-[#979797]" aria-hidden="true" />
                            )}
                            {onClick ? (
                                <div
                                    className={cn(
                                        '!hover:text-gray-300 small-text capitalize !cursor-pointer ',
                                        i === path.length - 1
                                            ? '!small-text text-third'
                                            : '!text-[#979797] !font-normal'
                                    )}
                                    onClick={() => {
                                        onClick(item);
                                    }}
                                >
                                    {item.title || ''}
                                </div>
                            ) : (
                                <Link
                                    to={item?.link ? `/${item?.link}` : ''}
                                    aria-current="page"
                                    className={cn(
                                        '!hover:text-gray-300 small-text capitalize `    ',
                                        i === path.length - 1
                                            ? '!small-text text-third'
                                            : '!text-[#979797] !font-normal'
                                    )}
                                >
                                    {item.title || ''}
                                </Link>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
