import React, { useState } from 'react';

import { motion } from 'framer-motion';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { SideTimes } from 'utills/svgs/SideTimes';

import { Switch } from 'components/atoms/inputs/Switch';

import { DragFile } from 'components/atoms/inputs/DragFile';
import { useUploadFile } from 'Hooks/Files/uploadFile';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { useNavigate, useParams } from 'react-router-dom';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { Button } from 'components/atoms/buttons/Button';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

export const UploadFile = ({
    toggleUploadFile,
    autocheck,
    onlyPdf,
    files,
    setFilesData,
    message,
    acceptFormate,
    hideRequest = false
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { uploadFileHanlder, fileLoader } = useUploadFile();
    const [signature, setSignature] = useState(autocheck ? true : false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const handleSignature = () => {
        if (autocheck) {
            return;
        }
        setSignature(!signature);
    };
    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };

    const uploadFile = async () => {
        if (selectedFiles?.length > 0) {
            const res = await uploadFileHanlder(selectedFiles, signature ? 'authorized' : 'accountant', id);
            if (res) {
                if (signature) {
                    navigate(`/request/pdf/${id}`, { state: res[0] });
                    return;
                }
                if (files) {
                    setFilesData([...res, ...files]);
                }

                toastHandler('File upload successfully', TOAST_TYPE_SUCCESS);
                toggleUploadFile();
            }
            return;
        }
        toastHandler('Please attach atleast one file', TOAST_TYPE_ERROR);
    };

    return (
        <div>
            {' '}
            <div>
                <div className={`add-p-side grid grid-cols-6 text-black `}>
                    <div className={`md:col-span-4 hidden md:block left-side `} onClick={toggleUploadFile}></div>
                    <div className={`right-side col-span-6 md:col-span-2`}>
                        <motion.div
                            initial={{ x: 700 }}
                            animate={{ x: 0 }}
                            transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                            className="inner-right relative"
                        >
                            <div>
                                <div
                                    onClick={toggleUploadFile}
                                    className="absolute   text-white p-2 right-1 top-1 cursor-pointer"
                                >
                                    <SideTimes />
                                </div>

                                <div className="add-detail pt-5 px-5">
                                    <div className="title flex gap-2 items-center">
                                        <span onClick={toggleUploadFile} className="cursor-pointer">
                                            {' '}
                                            <ArrowLeft />
                                        </span>{' '}
                                        Upload File
                                    </div>
                                </div>
                            </div>

                            <RoleBasedGuard module={'Clients'} permissions={['write', 'admin']} sx="h-[50vh]">
                                {' '}
                                <div className="m-5">
                                    <DragFile
                                        atChange={(file) => {
                                            handleSelectedFiles(file);
                                        }}
                                        accept={acceptFormate}
                                        selectedFiles={selectedFiles}
                                        isMultiple={false}
                                        dragMessage="Drag files here to upload, or browse files"
                                        dropMessage="Drop the selected files in this box... "
                                        onlyPdf={onlyPdf}
                                        message={message && message}
                                    />
                                    {hideRequest ? (
                                        ''
                                    ) : (
                                        <div className="flex justify-between items-center  mt-5">
                                            <div className="primary-fonts"> Do you want to request signature ?</div>
                                            <div>
                                                <Switch condition={signature} atChange={handleSignature} />
                                            </div>
                                        </div>
                                    )}

                                    <div className="stick-to-bottom">
                                        <Button
                                            classes="ann-btn h-size8 px-16 rounded-xl"
                                            title="Upload"
                                            atClick={() => {
                                                uploadFile();
                                            }}
                                            buttonType="button"
                                            iconSet={{ loader: SmallLoaderWhite }}
                                            isloading={fileLoader}
                                            disabled={fileLoader ? true : false}
                                        />
                                    </div>
                                </div>{' '}
                            </RoleBasedGuard>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
};
