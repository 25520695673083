import { useDispatch, useSelector } from 'react-redux';
import { businessOwnerGetProfileThunk } from 'store/auth/slices';

export const useOwnerProfile = () => {
    const dispatch = useDispatch();
    const { data: businessOwnerGetProfile } = useSelector((state) => state.businessOwnerGetProfile);
    const getOwnerProfile = () => {
        if (!businessOwnerGetProfile) {
            dispatch(businessOwnerGetProfileThunk()).catch((error) => {
                console.error(error);
            });
        }
    };
    return { getOwnerProfile };
};
