import React from 'react';

export const ProfileInfoCard = ({ data }) => {
    return (
        <div className="flex gap-12 items-center flex-wrap bg-white shadow-custom p-5 rounded-xl">
            {data?.map((item, index) => (
                <div key={'profilecard' + index}>
                    {' '}
                    <div className="flex gap-2 items-start">
                        <div> {<item.icon />} </div>
                        <div>
                            <div className="detail-card-key">{item.key}</div>
                            <div className="break-all detail-card-value ">{item.value}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
