import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import { Loader } from 'components/common/Loader';
import AuthCardLayout from 'layouts/AuthCardLayout';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { verifyGoogleAuthPreThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

export const VerifyGoogle = (props) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);
    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};
    const [timeRemaining, setTimeRemaining] = useState(67);

    const [otpVal, setOtpVal] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeRemaining]);

    const verifyGoogleData = {
        user_type: 'employee',
        code: otpVal,
        user_id: JSON.parse(sessionStorage.getItem('forgotPasswordData')).user_id,
        isBeforeLogin: true,
        email: parsedData?.email
    };

    const handleGoogleAuthenticate = () => {
        if (otpVal.length < 6) {
            toastHandler('Please enter full otp', TOAST_TYPE_ERROR);
            return;
        }

        dispatch(setLoader(true));
        dispatch(verifyGoogleAuthPreThunk(verifyGoogleData))
            .then((response) => {
                if (response.payload) {
                    if (response.payload) {
                        props.handleCondition(5);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            {isLoading && <Loader />}
            <AuthCardLayout>
                <div className=" ">
                    <div className="auth-inner ">
                        <div className="top-wrapper">
                            <div className="text-center  mt-4 card-layout-title-text font-fira ">
                                Google Authenticator
                            </div>

                            <div className="flex gap-2 text-sixth text-sm justify-center items-center mt-1">
                                <div className="mt-2">Enter the 6-digit code provided by your Authentication app</div>
                            </div>
                        </div>

                        <div className="mx-20 mt-10">
                            <div className="mt-16 flex justify-center items-center">
                                <OtpInputs value={otpVal} onChange={handleOtpChange} />
                            </div>

                            <div className="flex justify-center items-center  mt-72">
                                <button
                                    onClick={handleGoogleAuthenticate}
                                    className="px-12 btn-2 py-2 buttons-font font-fira  rounded-md"
                                >
                                    Verify
                                </button>
                            </div>
                            <div className="mt-3 text-center verify-section small-text font-poppins">
                                <p onClick={() => props.handleCondition(12)} className="cursor-pointer">
                                    {' '}
                                    Try Another Way To Authenticate?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};
