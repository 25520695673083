import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFormsThunk } from 'store/form';
import { setLoader } from 'store/global/globalReducer';

export const useGetForms = () => {
    const { prebuilt_page_size } = useSelector((state) => state.global);
    const [_defaultPageSize, setDefaultPageSize] = useState(10);
    const dispatch = useDispatch();

    const fetchForms = (form_status, _pageSize) => {
        dispatch(setLoader(true));
        const payload = {
            business_id: localStorage.getItem('business_id'),
            service_id: '',
            form_status: form_status,
            page: 1,

            page_size: prebuilt_page_size ? prebuilt_page_size : 10
        };
        dispatch(fetchFormsThunk(payload))
            .then(() => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return { fetchForms, setDefaultPageSize };
};
