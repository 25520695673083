import React from 'react';
import BookingsPage from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/bookings/BookingsPage';
const Bookings = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <BookingsPage />
            </div>
        </div>
    );
};

export default Bookings;
