import React from 'react';
import { motion } from 'framer-motion';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { MobileLaptop } from 'utills/svgs/MobileLaptop';
import { useNavigate } from 'react-router-dom';
export const ResetAuthIndicator = () => {
    const navigate = useNavigate();
    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2  ">
            <AuthCardLayout>
                <div className="mt-20">
                    {' '}
                    <div className="flex justify-center items-center ">
                        <motion.div
                            initial={{ opacity: 0, rotate: 100 }}
                            animate={{ opacity: 1, rotate: 0 }}
                            transition={{ delay: 0.3, type: 'spring', duration: 0.6 }}
                            className="svg-wrapper scale-75 "
                        >
                            <MobileLaptop />
                        </motion.div>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-center s-title">Setup Two Factor Authentication</h1>
                    </div>
                    <div className="px-5 mt-3">
                        <p className="text-center security-info mx-10">
                            You reset your google authenticator. Now you have to setup your google authenticator again.
                        </p>
                    </div>
                    <div className="s-btn-wrapper my-3 mt-24 mb-5">
                        <button
                            onClick={() =>
                                navigate('/reset-auth', {
                                    state: {
                                        isNavigating: true
                                    }
                                })
                            }
                            className="btn-2 px-10 py-2 rounded-md"
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};
