import React, { useState, useEffect } from 'react';
import Toast from './Toast';

export const ToastManager = () => {
    const [toasts, setToasts] = useState([]);

    useEffect(() => {
        const handleAddToast = (event) => {
            const { message, type } = event.detail;
            const id = new Date().getTime();
            setToasts((prevToasts) => [...prevToasts, { id, message, type }]);
            setTimeout(() => removeToast(id), 100000);
        };

        window.addEventListener('addToast', handleAddToast);

        return () => {
            window.removeEventListener('addToast', handleAddToast);
        };
    }, []);

    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    };

    return (
        <div className="fixed top-4 right-4 z-[9999] space-y-4">
            {toasts.map((toast) => (
                <Toast key={toast.id} message={toast.message} type={toast.type} onClose={() => removeToast(toast.id)} />
            ))}
        </div>
    );
};

const toastContainer = document.createElement('div');
document.body.appendChild(toastContainer);

export const toastHandler = (message, type) => {
    const event = new CustomEvent('addToast', { detail: { message, type } });
    window.dispatchEvent(event);
};
