import React from 'react';
import { useEffect, useState } from 'react';
import { useRequiredSigningContext } from './provider';
import { SigningFieldContainer } from './signing-field-container';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';

export const EmailField = ({ field, _recipient }) => {
    const { email: providedEmail } = useRequiredSigningContext();
    const [fieldData, setfieldData] = useState(field);
    useEffect(() => {
        const tempData = {
            ...fieldData,
            field_value: providedEmail,
            inserted: true
        };
        setfieldData(tempData);
    }, []);
    const onSign = async () => {
        const tempData = {
            ...fieldData,
            field_value: providedEmail,
            inserted: true
        };
        setfieldData(tempData);
    };

    const onRemove = async () => {
        const tempData = {
            ...fieldData,
            field_value: '',
            inserted: false
        };
        setfieldData(tempData);
    };

    return (
        <SigningFieldContainer field={fieldData} onSign={onSign} onRemove={onRemove}>
            <TooltipChipWrap title={fieldData?.field_value || 'no title'} sx={'!bg-gray-100 !text-gray-800 !text-xs'}>
                {' '}
                <div>
                    {!fieldData.inserted && (
                        <p className="group-hover:text-primary text-muted-foreground text-lg duration-200">Email</p>
                    )}

                    {fieldData.inserted && (
                        <p className="text-muted-foreground duration-200 break-all">{fieldData.field_value}</p>
                    )}
                </div>
            </TooltipChipWrap>
        </SigningFieldContainer>
    );
};
