import React, { useRef, useState } from 'react';
import user from '../../files/user.png';
import { generateRandomProfileLink } from 'utills/globalVars';
import { TooltipChip } from 'components/atoms/tooltipChip/TooltipChip';
import { cn } from 'utills/tailwindUtil';

export const DefaultProfileViewer = ({ image, atClick, width, height, name, tooltip, imgSx }) => {
    const parentRef = useRef(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <div
            style={{ width: width, height: height }}
            className="group relative"
            onMouseEnter={() => setHoveredIndex(true)}
            onMouseLeave={() => setHoveredIndex(null)}
            ref={parentRef}
        >
            {tooltip && <TooltipChip text={name} parentRef={parentRef} isVisible={hoveredIndex} />}
            <div>
                {image !== '' && image !== null && image !== undefined ? (
                    <div
                        style={{ width: width, height: height }}
                        className={cn('h-[40px] w-[40px] rounded-full overflow-hidden', imgSx)}
                    >
                        <img
                            src={image}
                            alt="member"
                            className="member-image-table"
                            onClick={() => {
                                if (!atClick) {
                                    return;
                                }
                                atClick();
                            }}
                        />
                    </div>
                ) : (
                    <div
                        style={{ width: width, height: height }}
                        className={cn('h-[40px] w-[40px] rounded-full overflow-hidden', imgSx)}
                    >
                        <img
                            src={name ? generateRandomProfileLink(name) : user}
                            alt="member"
                            className="member-image-table"
                            onClick={() => {
                                if (!atClick) {
                                    return;
                                }
                                atClick();
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
