import React from 'react';
import './css/noDataFound.css';
import { cn } from 'utills/tailwindUtil';
import noData from '../../files/noData.svg';

export const NoDataFound = (props) => {
    return (
        <div className={cn(' no-team', props.classes)} style={{ height: props?.height }}>
            <div>
                <img src={noData} alt="no data" style={{ height: '70px', width: '70px' }} />
            </div>
            <div> {props.message}</div>
        </div>
    );
};
