import { Button } from 'components/atoms/buttons/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import whiteBgLogo from '../../files/whiteBgLogo.png.png';

export const Page404 = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full h-full">
            <div onClick={() => navigate('/business/dashboard')} className="absolute cursor-pointer">
                <img src={whiteBgLogo} alt="logo" className="Synkli-logo mt-5 ml-7" />
            </div>

            <div className="relative w-full !h-[100vh] flex justify-center items-center text-center">
                <div className="text-[250px] lg:text-[376px] absolute top-[50%] left-[50%] translate-x-[-50%] z-[-10]  translate-y-[-50%] opacity-10 font-[900]">
                    <h1 className="font-poppins tracking-widest	">404</h1>
                </div>
                <div className="notfound">
                    <h2 className="text-black text-5xl font-fira tracking-wider uppercase">
                        We are sorry, Page not found!
                    </h2>
                    <p className="my-5 text-xl text-gray-700 tracking-wide uppercase">
                        The page you are looking for might have been removed had its name changed or is temporarily
                        unavailable.
                    </p>
                    <div className="flex items-center justify-center gap-10">
                        {/* <Button
                            title={'Go Back'}
                            classes={
                                'px-6 py-3 !rounded-full hover:text-white hover:bg-primary text-normal mt-5 bg-transparent ring-inset ring-2 ring-primary text-primary !transition-all !tracking-wide'
                            }
                            atClick={() => navigate(-2)}
                        /> */}
                        <Button
                            title={'Back To Dashboard'}
                            classes={
                                'px-6 py-3 !rounded-full text-white bg-primary text-normal mt-5 hover:bg-transparent hover:ring-inset hover:ring-2 hover:ring-primary hover:text-primary !transition-all !tracking-wide'
                            }
                            atClick={() => navigate('/business/dashboard')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
