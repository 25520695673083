import React from 'react';

export const BulletList = () => {
    return (
        <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1.94994" cy="1.74999" r="1.74999" fill="#656565" />
            <circle cx="1.94994" cy="11.1055" r="1.74999" fill="#656565" />
            <rect x="7.20001" width="13.9999" height="3.49998" rx="1.74999" fill="#656565" />
            <rect x="7.19989" y="9.35547" width="13.9999" height="3.49998" rx="1.74999" fill="#656565" />
        </svg>
    );
};
