import React from 'react';

export const CalenderBgChipIcon = ({ fill = '#657CEE', className }) => {
    return (
        <svg
            width="52"
            height="52"
            viewBox="0 0 52 52"
            className={className}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="26" cy="26" r="26" fill={fill} />
            <path
                d="M37.9192 17.6531V17.6031H37.8692H35.3757V15.9641H38.0807C38.272 15.9674 38.4608 16.0093 38.6364 16.0875C38.8121 16.1657 38.9712 16.2787 39.1044 16.4201C39.2377 16.5615 39.3426 16.7284 39.413 16.9115C39.4835 17.0946 39.5181 17.2902 39.5148 17.4871V17.4879L39.5148 36.948L39.5148 36.9489C39.5181 37.1457 39.4835 37.3413 39.413 37.5244C39.3426 37.7075 39.2377 37.8745 39.1044 38.0159C38.9712 38.1572 38.8121 38.2702 38.6364 38.3485C38.4608 38.4266 38.2719 38.4686 38.0806 38.4719H13.919C13.7277 38.4686 13.5388 38.4266 13.3632 38.3485C13.1875 38.2702 13.0284 38.1572 12.8951 38.0159C12.7618 37.8745 12.657 37.7075 12.5865 37.5244C12.5161 37.3413 12.4815 37.1457 12.4848 36.9489H12.4848V36.948V17.4879H12.4848L12.4848 17.4871C12.4815 17.2902 12.5161 17.0946 12.5865 16.9115C12.657 16.7284 12.7618 16.5615 12.8951 16.4201C13.0284 16.2787 13.1875 16.1657 13.3632 16.0875C13.5388 16.0093 13.7276 15.9674 13.9189 15.9641H16.6238V17.6031H14.1304H14.0804V17.6531V36.7828V36.8328H14.1304H37.8692H37.9192V36.7828V17.6531Z"
                fill="white"
                stroke={fill}
                strokeWidth="0.1"
            />
            <path d="M17.5715 22.9172H19.1671V24.5563H17.5715V22.9172Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M22.6584 22.9172H24.254V24.5563H22.6584V22.9172Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M27.7453 22.9172H29.3409V24.5563H27.7453V22.9172Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M32.8322 22.9172H34.4279V24.5563H32.8322V22.9172Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M17.5715 27.2648H19.1671V28.9039H17.5715V27.2648Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M22.6584 27.2648H24.254V28.9039H22.6584V27.2648Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M27.7453 27.2648H29.3409V28.9039H27.7453V27.2648Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M32.8322 27.2648H34.4279V28.9039H32.8322V27.2648Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M17.5715 31.6125H19.1671V33.2516H17.5715V31.6125Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M22.6584 31.6125H24.254V33.2516H22.6584V31.6125Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M27.7453 31.6125H29.3409V33.2516H27.7453V31.6125Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path d="M32.8322 31.6125H34.4279V33.2516H32.8322V31.6125Z" fill="white" stroke={fill} strokeWidth="0.1" />
            <path
                d="M19.7806 19.1003C19.6309 19.2538 19.4281 19.3398 19.217 19.3398C19.0058 19.3398 18.803 19.2538 18.6533 19.1003C18.5035 18.9467 18.4191 18.7381 18.4191 18.5203V13.3031C18.4191 13.0854 18.5035 12.8768 18.6533 12.7232C18.803 12.5696 19.0058 12.4836 19.217 12.4836C19.4281 12.4836 19.6309 12.5696 19.7806 12.7232C19.9304 12.8768 20.0148 13.0854 20.0148 13.3031V18.5203C20.0148 18.7381 19.9304 18.9467 19.7806 19.1003Z"
                fill="white"
                stroke={fill}
                strokeWidth="0.1"
            />
            <path
                d="M33.3461 19.1003C33.1964 19.2538 32.9936 19.3398 32.7824 19.3398C32.5712 19.3398 32.3684 19.2538 32.2187 19.1003C32.0689 18.9467 31.9846 18.7381 31.9846 18.5203V13.3031C31.9846 13.0854 32.0689 12.8768 32.2187 12.7232C32.3684 12.5696 32.5712 12.4836 32.7824 12.4836C32.9936 12.4836 33.1964 12.5696 33.3461 12.7232C33.4958 12.8768 33.5802 13.0854 33.5802 13.3031V18.5203C33.5802 18.7381 33.4958 18.9467 33.3461 19.1003Z"
                fill="white"
                stroke={fill}
                strokeWidth="0.1"
            />
            <path d="M21.8107 15.9641H30.1889V17.6031H21.8107V15.9641Z" fill="white" stroke={fill} strokeWidth="0.1" />
        </svg>
    );
};
