import React from 'react';
import { useEffect, useState } from 'react';
import { EyeIcon } from '../../../utills/svgs/EyeIcon';
import { BgDeleteIcon } from '../../../utills/svgs/BgDeleteIcon';
import { LgTimes } from '../../../utills/svgs/LgTimes';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { deleteBusinessPhotoThunk, getWorkspacephotosThunk, uploadBusinessPhotoThunk } from 'store/auth/slices';
import { BUSINESS_ID, ERROR_TYPE_ERROR, ERROR_TYPE_SUCCESS, PERMISSIONS_MESSAGE, USER_TYPE } from 'utills/globalVars';
import { generateId } from 'utills/uid';
import { toastHandler } from 'responseHanlder';
import { SmallLoader } from 'components/common/SmallLoader';
import useRoleGuard from 'Hooks/useRoleGuard';

const WorkSpacePhotos = () => {
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();

    const [imageInView, setImageInView] = useState(null);
    const [_photosLoader, setPhotosLoader] = useState(false);
    const { data } = useSelector((state) => state.getWorkspacephotos);
    const [imagesUpdated, setImagesUpdated] = useState(false);
    const [imageUploading, setImageUploading] = useState(false);
    const [delLoad, setDelLoad] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const business_id = localStorage.getItem(BUSINESS_ID);

    const imageHandler = (index) => {
        const findImage = data[index];
        setImageInView(findImage);
    };
    const handleImageUpload = (event) => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            const file = event.target.files[0];

            const formData = new FormData();
            formData.append('uploaded_by', USER_TYPE);
            formData.append('business_images', file);
            setImageUploading(true);
            dispatch(uploadBusinessPhotoThunk(formData))
                .then((response) => {
                    if (response.payload) {
                        toastHandler('Image uploaded', ERROR_TYPE_SUCCESS);
                        setImagesUpdated(!imagesUpdated);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setImageUploading(false);
                });
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const deletePhoto = (business_photo_id) => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            setDelLoad(true);
            setDeleteId(business_photo_id);
            dispatch(deleteBusinessPhotoThunk({ business_photo_id }))
                .then((response) => {
                    if (response.payload) {
                        setImageInView(null);
                        setImagesUpdated(!imagesUpdated);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setDelLoad(false);
                });
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    useEffect(() => {
        setPhotosLoader(true);
        dispatch(getWorkspacephotosThunk({ business_id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setPhotosLoader(false);
            });
    }, [imagesUpdated]);

    const [imgId, setImgId] = useState('');
    const [showImgActions, setShowImgActions] = useState(false);

    const handleImgActions = (business_photo_id) => {
        setImgId(business_photo_id);
        setShowImgActions(true);
    };

    const hideImgActions = () => {
        setShowImgActions(false);
    };
    return (
        <div className="md:px-10 px-5 mt-2">
            <div className="grid md:grid-cols-2 b  ">
                <div>
                    <div className=" gap-5 flex  items-center flex-wrap py-2">
                        {' '}
                        <div className="p-2 h-[100px] w-[100px]">
                            <div className="add-image1 border border-gray-500 p-3 py-4 h-full w-full rounded-xl overflow-hidden flex justify-center items-center ">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                    id="image-input-file"
                                    disabled={imageUploading ? true : false}
                                />
                                <label htmlFor="image-input-file" className="cursor-pointer">
                                    {imageUploading ? <SmallLoader /> : <LgTimes />}
                                </label>
                            </div>
                        </div>
                        {data &&
                            data?.map((im, index) => (
                                <div
                                    onMouseEnter={() => handleImgActions(im?.business_photo_id)}
                                    onMouseLeave={hideImgActions}
                                    key={generateId()}
                                    className={`image-container1 p-2 h-[100px] w-[100px] rounded-xl relative ${
                                        delLoad && deleteId === im.business_photo_id ? 'animate-pulse' : ''
                                    } `}
                                >
                                    {showImgActions && imgId === im?.business_photo_id ? (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.4, type: 'tween' }}
                                            className="image-actions-container"
                                        >
                                            <div className="cursor-pointer" onClick={() => imageHandler(index)}>
                                                <EyeIcon />
                                            </div>
                                            <div
                                                onClick={() => deletePhoto(im?.business_photo_id)}
                                                className="cursor-pointer"
                                            >
                                                <BgDeleteIcon />
                                            </div>
                                        </motion.div>
                                    ) : (
                                        ''
                                    )}
                                    <img src={im.url} alt="img" className=" galery-image h-full w-full rounded-xl" />
                                </div>
                            ))}
                    </div>
                </div>
                <div>
                    {' '}
                    <div className="p-2 in-view">
                        <div className="in-view-container">
                            {imageInView && <img src={imageInView?.url} alt="inview" className="view-image" />}
                        </div>
                    </div>{' '}
                </div>
            </div>
        </div>
    );
};
export default WorkSpacePhotos;
