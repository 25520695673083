import { Button } from 'components/atoms/buttons/Button';
import React, { createContext, useContext, useState } from 'react';
import { GmailIcon } from 'utills/svgs/GmailIcon';
import { OutlookIcon } from 'utills/svgs/OutlookIcon';

const AccountCardContext = createContext();

export const AccountCard = ({ children, account, _handleDisconnect, _handleConnect }) => {
    const [selectedAccount, setSelectedAccount] = useState(account);
    return (
        <AccountCardContext.Provider value={{ account, selectedAccount, setSelectedAccount }}>
            <div className="flex w-full items-center justify-between bg-white primary-shadow rounded-xl p-5 py-7">
                {children}
            </div>
        </AccountCardContext.Provider>
    );
};

const useAccountCard = () => useContext(AccountCardContext);

export const AccountCardIcon = () => {
    const { account } = useAccountCard();
    return (
        <div>
            {account?.provider === 'Outlook.com' ? (
                <OutlookIcon className={'w-[50px] h-[50px]'} />
            ) : (
                <GmailIcon className={'w-[50px] h-[50px]'} />
            )}
        </div>
    );
};

export const AccountCardInfo = () => {
    const { account } = useAccountCard();
    return (
        <div className="ml-2">
            <p className="text-lg font-medium">{account.email}</p>
            <p className="text-gray-500 text-sm">{account.provider}</p>
        </div>
    );
};

export const AccountCardAction = () => {
    const { account, handleDisconnect } = useAccountCard();
    return (
        <div className="flex items-center justify-center gap-2">
            {account?.isConnected ? (
                <Button
                    title={'Disconnect'}
                    onClick={handleDisconnect}
                    classes="px-7 py-2 min-h-[3rem] border border-blueish text-blueish font-[500] font-fira rounded-[12px] hover:bg-blue-50"
                />
            ) : (
                <div className="flex items-center justify-center gap-2">
                    <Button
                        title={'Sign-in'}
                        onClick={handleDisconnect}
                        classes="ann-btn px-10 py-2 border font-[500] rounded-[12px] hover:bg-blue-50"
                    />

                    <Button
                        title={'Connect'}
                        onClick={handleDisconnect}
                        classes=" px-8 py-2 min-h-[3rem] border border-blueish text-blueish font-[500] font-fira rounded-[12px] hover:bg-blue-50"
                    />
                </div>
            )}
        </div>
    );
};
