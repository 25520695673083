import React from 'react';

export const LockIcon = () => {
    return (
        <div>
            <svg width="15" height="16" viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 10.1111C8.83633 10.1136 8.19563 10.3501 7.69444 10.7775C7.19324 11.2049 6.86484 11.7949 6.76897 12.4401C6.6731 13.0853 6.81613 13.7428 7.17204 14.2932C7.52795 14.8435 8.0731 15.2501 8.70833 15.4389V17.1111C8.70833 17.3174 8.79174 17.5152 8.94021 17.6611C9.08867 17.8069 9.29004 17.8889 9.5 17.8889C9.70996 17.8889 9.91133 17.8069 10.0598 17.6611C10.2083 17.5152 10.2917 17.3174 10.2917 17.1111V15.4389C10.9269 15.2501 11.4721 14.8435 11.828 14.2932C12.1839 13.7428 12.3269 13.0853 12.231 12.4401C12.1352 11.7949 11.8068 11.2049 11.3056 10.7775C10.8044 10.3501 10.1637 10.1136 9.5 10.1111ZM9.5 14C9.26513 14 9.03554 13.9316 8.84026 13.8034C8.64498 13.6752 8.49277 13.493 8.40289 13.2798C8.31301 13.0666 8.2895 12.832 8.33532 12.6057C8.38114 12.3794 8.49424 12.1715 8.66031 12.0084C8.82639 11.8452 9.03798 11.7341 9.26833 11.6891C9.49868 11.6441 9.73745 11.6672 9.95444 11.7555C10.1714 11.8438 10.3569 11.9933 10.4874 12.1852C10.6179 12.377 10.6875 12.6026 10.6875 12.8333C10.6875 13.1428 10.5624 13.4395 10.3397 13.6583C10.117 13.8771 9.81495 14 9.5 14ZM17.4167 7H13.8542V4.27778C13.8542 3.14324 13.3954 2.05517 12.5789 1.25293C11.7623 0.450693 10.6548 0 9.5 0C8.3452 0 7.2377 0.450693 6.42114 1.25293C5.60457 2.05517 5.14583 3.14324 5.14583 4.27778V7H1.58333C1.16341 7 0.76068 7.16389 0.463748 7.45561C0.166815 7.74734 0 8.143 0 8.55556V19.4444C0 19.857 0.166815 20.2527 0.463748 20.5444C0.76068 20.8361 1.16341 21 1.58333 21H17.4167C17.8366 21 18.2393 20.8361 18.5363 20.5444C18.8332 20.2527 19 19.857 19 19.4444V8.55556C19 8.143 18.8332 7.74734 18.5363 7.45561C18.2393 7.16389 17.8366 7 17.4167 7ZM6.72917 4.27778C6.72917 3.5558 7.02109 2.86339 7.54073 2.35288C8.06036 1.84236 8.76513 1.55556 9.5 1.55556C10.2349 1.55556 10.9396 1.84236 11.4593 2.35288C11.9789 2.86339 12.2708 3.5558 12.2708 4.27778V7H6.72917V4.27778ZM17.4167 19.4444H1.58333V8.55556H17.4167V19.4444Z" />
                <defs>
                    <linearGradient
                        id="paint0_linear_1353_394"
                        x1="10.4322"
                        y1="0.260799"
                        x2="10.4149"
                        y2="21"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
