import React from 'react';
import OrganizationalSettingsPage from '../../../components/workplace-content/tasks-pages/organizational-settings-pages/OrganizationalSettingsPage';
import { useState } from 'react';
import AddTask from '../../../components/workplace-content/tasks-pages/add-task/AddTask';
import AddDynamicStatus from '../../../components/workplace-content/tasks-pages/organizational-settings-pages/AddDynamicStatus';
import { Logs } from 'components/workplace-content/tasks-pages/organizational-settings-pages/Logs';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { EditStatus } from 'components/workplace-content/tasks-pages/organizational-settings-pages/EditStatus';
import { SidebarLayout } from 'layouts/SidebarLayout';
const OrganizationalSettings = () => {
    const [showAddTask, setShowAddTask] = useState(false);
    const [showAddStatus, setShowAddStatus] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const [statusUpdated, setStatusUpdated] = useState(false);
    const [editStatus, setEditStatus] = useState(false);

    const toggleEditStatus = () => {
        setEditStatus(!editStatus);
    };
    function toggleAddTask() {
        setShowAddTask(!showAddTask);
    }

    function toggleAddStatus() {
        setShowAddStatus(!showAddStatus);
    }

    const toggleLogs = () => {
        setShowLogs(!showLogs);
    };

    const toggleStatusUpdate = () => {
        setStatusUpdated(!statusUpdated);
    };
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showLogs && <Logs toggleLogs={toggleLogs} />}
                {showAddTask && <AddTask toggleAddTask={toggleAddTask} />}
                {showAddStatus && (
                    <AddDynamicStatus
                        toggleAddStatus={toggleAddStatus}
                        toggleStatusUpdate={toggleStatusUpdate}
                        open={showAddStatus}
                        setOpen={setShowAddStatus}
                    />
                )}
                {editStatus && (
                    <EditStatus open={editStatus} setOpen={setEditStatus} toggleStatusUpdate={toggleStatusUpdate} />
                )}
                <div>
                    <RoleBasedGuard module={'Tasks'} permissions={['admin', 'read', 'write']} sx="h-[50vh]">
                        <OrganizationalSettingsPage
                            toggleAddStatus={toggleAddStatus}
                            toggleLogs={toggleLogs}
                            statusUpdated={statusUpdated}
                            toggleEditStatus={toggleEditStatus}
                        />
                    </RoleBasedGuard>
                </div>
            </SidebarLayout>
        </div>
    );
};
export default OrganizationalSettings;
