import React from "react";
import SecurityQuestions from "../../components/login-steps/SecurityQuestions";

export const LoginSecurityQuestions = () => {
  return (
    <div>
      <SecurityQuestions />
    </div>
  );
};
