import { useDispatch } from 'react-redux';
import { getBusinessprofileThunk } from 'store/auth/slices';

export const useBusinessProfile = () => {
    const dispatch = useDispatch();
    const fetchBusiness = () => {
        dispatch(getBusinessprofileThunk()).catch((error) => {
            console.error(error);
        });
    };
    return { fetchBusiness };
};
