import React from 'react';
import EmailAuthenticatorPage from '../../components/second-time-login-pages/EmailAuthenticatorPage';
const EmailAuthenticator = () => {
    return (
        <div>
            <EmailAuthenticatorPage />
        </div>
    );
};

export default EmailAuthenticator;
