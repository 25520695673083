import React from 'react';
import { SearchInput } from 'components/molecules/SearchInput';
import { Button } from 'components/atoms/buttons/Button';
import { CodeIcon } from './../../../utills/svgs/CodeIcon';

const QrCodesOperation = ({ toggleAddQrCode, searchByItem }) => {
    return (
        <div className="flex justify-between md:px-10 px-5 flex-wrap gap-5">
            <SearchInput
                width="300px"
                atChange={(e) => {
                    searchByItem(e.target.value);
                }}
                placeholder="Search"
            />
            <div className="flex gap-2">
                <Button
                    title="Create QR Code"
                    iconSet={{ leftIcon: CodeIcon }}
                    classes="px-2 flex gap-2 items-center ann-btn rounded-xl buttons-font-lighter px-5"
                    atClick={toggleAddQrCode}
                />
            </div>
        </div>
    );
};

export default QrCodesOperation;
