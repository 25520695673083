/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/atoms/buttons/Button';
import Slideover from 'components/atoms/SlideOver';
import { SearchInput } from 'components/molecules/SearchInput';
import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/atoms/Breadcrumb';
import { cn } from 'utills/tailwindUtil';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDocumentDriveListForActionThunk,
    setActivePath,
    setFilters,
    setJobId
} from 'store/custom-reducers/JobManagerDocumentActionsReducer';
import { useParams } from 'react-router-dom';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { SmallLoader } from 'components/common/SmallLoader';
import { NoDataFound } from 'components/common/NoDataFound';
import SelectedFilesList from './SelectedFilesList';
import DirectoryItem from './DirectoryItem';
import { collateFilesThunk, uploadFilesToDocManagerThunk } from 'store/JobManager';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { extractStringBeforeLastDot } from 'utills/dataValidation';

const CollateDocuments = ({ open = false, setOpen, title, list, setFileList, getMainTableData, handleSelectId }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { id } = useParams();
    const [fileTitle, setFileTitle] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');

    const { activePath, data, filters, globalLoading } = useSelector((state) => state.jobManagerDocForActionsReducer);

    useEffect(() => {
        getData();
        return () => {
            dispatch(setActivePath([]));
        };
    }, []);

    useEffect(() => {
        if (list?.length > 0) {
            setFileTitle(extractStringBeforeLastDot(list[0]?.name) || '');
        } else {
            setFileTitle('');
        }
    }, [list]);

    const getData = (parentObj = null, parent_id_Only = null) => {
        if (parentObj) {
            if (parentObj?.job_drive_id !== '') {
                const splitArray = activePath.slice(
                    0,
                    activePath.findIndex((obj) => obj.job_drive_id === parentObj?.job_drive_id) + 1
                );
                dispatch(setActivePath(splitArray?.length > 0 ? splitArray : [...activePath, parentObj]));
            } else {
                dispatch(setActivePath([]));
            }
        }

        const jsonData = {
            job_id: id,
            business_id,
            ...(parent_id_Only
                ? { parent_id: parent_id_Only }
                : parentObj?.job_drive_id
                ? { parent_id: parentObj?.job_drive_id }
                : {})
        };
        dispatch(setJobId(id));
        dispatch(getDocumentDriveListForActionThunk(jsonData));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        dispatch(
            setFilters({
                ...filters,
                [name]: value
            })
        );
    };

    const handleInternalClick = async (obj) => {
        await getData(obj);
    };

    const activeDocPath = () => {
        let initialArray = [{ link: '', title: 'Job Documents' }];
        if (activePath?.length > 0) {
            let updatedArray = activePath?.map((pathObj) => ({
                link: pathObj?.job_drive_id,
                title: pathObj?.name || ''
            }));
            initialArray = initialArray.concat(updatedArray);
        }
        return initialArray;
    };

    const checkPDFORFolder = (dataObj) => {
        if (
            ['file', 'folder'].includes(dataObj?.type) &&
            (!dataObj?.mimetype || dataObj?.mimetype === 'null' || dataObj?.mimetype?.includes('pdf'))
        ) {
            return true;
        } else {
            return false;
        }
    };

    const handleFileUpload = (files) => {
        const invalidFiles = Array.from(files).filter((file) => file.type !== 'application/pdf');
        if (invalidFiles.length > 0) {
            toastHandler('Only PDF files are allowed.', TOAST_TYPE_ERROR);
            return;
        }

        if (files.length > 0) {
            const formDataJSON = new FormData();
            formDataJSON.append('job_id', id);
            formDataJSON.append('business_id', business_id);
            formDataJSON.append('uploaded_by', 'employee');

            Array.from(files).forEach((file) => {
                formDataJSON.append('files', file);
            });

            const activeTabId = activePath.at(-1)?.job_drive_id;
            if (activePath?.length > 0 && activeTabId) {
                formDataJSON.append('folder_id', activeTabId);
            }

            dispatch(setLoader(true));
            dispatch(uploadFilesToDocManagerThunk(formDataJSON))
                .then((response) => {
                    if (response.payload) {
                        getData({ job_drive_id: activeTabId });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toastHandler('Something went wrong', TOAST_TYPE_ERROR);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleCollatDocuments = () => {
        if (fileTitle === '') {
            setFileTitle(false);
            toastHandler('File Title is Required', TOAST_TYPE_SUCCESS);
            return false;
        }
        if (list.length < 2) {
            toastHandler('Please select at least 2 files to collate', TOAST_TYPE_SUCCESS);
            return false;
        }
        if (fileTitle !== '' && fileTitle !== false && list.length >= 2) {
            let payload = {
                job_id: id,
                business_id,
                title: fileTitle,
                job_drive_ids: list?.map((dataObj) => dataObj?.job_drive_id)
            };

            dispatch(setLoader(true));
            dispatch(collateFilesThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        toastHandler('Successfully Collated', TOAST_TYPE_SUCCESS);
                        setFileList([]);
                        setOpen(null);
                        getMainTableData();
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toastHandler('Something went wrong', TOAST_TYPE_ERROR);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };
    return (
        <div>
            <Slideover
                size="max-w-[1400px]"
                open={open}
                setOpen={setOpen}
                title={title}
                titlesx={'text-3xl font-fira font-[500]'}
                hideBackArrow
                footer={
                    <div className="flex pl-0 lg:pl-28 items-center justify-center gap-4">
                        <Button
                            title="Cancel"
                            classes={
                                'border-blueish text-blueish border-2 h-[3rem] rounded-xl !font-medium font-fira px-12'
                            }
                            atClick={() => {
                                setFileList([]);
                                setOpen(null);
                            }}
                            type="button"
                        />
                        <Button
                            title={title}
                            disabled={list?.length < 2}
                            classes={cn('rounded-xl h-[3.2rem] px-7 !font-medium font-fira !text-white ', 'ann-btn')}
                            atClick={() => {
                                handleCollatDocuments();
                            }}
                        />
                    </div>
                }
            >
                {selectedPdf && (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                    />
                )}
                <div className="grid grid-col lg:grid-cols-2 gap-4 mt-6 lg:h-[90%]">
                    <div className="flow px-4">
                        <div className="flex justify-start items-center flex-wrap gap-3">
                            <div className="!w-auto flex-1">
                                <SearchInput
                                    width="100%"
                                    defaultValue={filters?.search_term || ''}
                                    atChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    placeholder="Search"
                                />
                            </div>
                            <label htmlFor="inputfilenewfile">
                                <div
                                    className={
                                        'grid !px-3 text-white !bg-linear !text-sm cursor-pointer py-2.5  !rounded-xl hover:default-opacity-bg'
                                    }
                                >
                                    Upload File
                                </div>
                            </label>
                        </div>

                        <input
                            type="file"
                            multiple
                            id="inputfilenewfile"
                            accept="application/pdf"
                            className="hidden"
                            onChange={(e) => handleFileUpload(e?.target?.files)}
                        />

                        <div className="flex flex-wrap mt-2">
                            {!globalLoading && (
                                <Breadcrumb
                                    path={activeDocPath()}
                                    onClick={(obj) => {
                                        getData({
                                            job_drive_id: obj?.link,
                                            name: obj?.title
                                        });
                                    }}
                                />
                            )}
                        </div>
                        <div className="mt-4 overflow-auto max-h-[550px]">
                            {data &&
                                data?.length > 0 &&
                                [...data]
                                    ?.filter((obj) => {
                                        let isFound = list.find(
                                            (selectedItem) => selectedItem?.job_drive_id === obj?.job_drive_id
                                        );
                                        if (obj?.type === 'folder' && isFound) {
                                            return false;
                                        }
                                        return true;
                                    })
                                    ?.sort((a, b) => {
                                        if (a?.type === 'folder' && b?.type !== 'folder') {
                                            return -1;
                                        } else if (a?.type !== 'folder' && b?.type === 'folder') {
                                            return 1;
                                        } else {
                                            return 0;
                                        }
                                    })
                                    ?.map((dataObj, index) => {
                                        return (
                                            <div key={'tableList' + index}>
                                                <DirectoryItem
                                                    checkPDFORFolder={checkPDFORFolder}
                                                    dataObj={dataObj}
                                                    handleInternalClick={handleInternalClick}
                                                    list={list}
                                                    handleSelectId={handleSelectId}
                                                    setSelectedPdf={(e) => {
                                                        setSelectedPdf(e);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                        </div>
                        <div className="flex w-full items-center justify-center">
                            {(globalLoading || !data) && <SmallLoader />}
                            {!globalLoading && data?.length === 0 && (
                                <NoDataFound
                                    classes="border-y border-gray-300 w-full"
                                    message="No Data Found!"
                                    height="420px"
                                />
                            )}
                        </div>
                    </div>

                    <div className="grid">
                        <SelectedFilesList
                            setFileList={setFileList}
                            setOpen={setOpen}
                            list={list}
                            handleSelectId={handleSelectId}
                            fileTitle={fileTitle}
                            setFileTitle={setFileTitle}
                        />
                    </div>
                </div>
            </Slideover>
        </div>
    );
};

export default CollateDocuments;
