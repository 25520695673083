import React from 'react';

export const HintIcon = ({ className }) => {
    return (
        <svg
            width="36"
            height="34"
            viewBox="0 0 36 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect opacity="0.05" width="36" height="34" rx="17" fill="#B695F8" />
            <path
                d="M18 18C18.2833 18 18.521 17.904 18.713 17.712C18.905 17.52 19.0007 17.2827 19 17V12.975C19 12.6917 18.904 12.4583 18.712 12.275C18.52 12.0917 18.2827 12 18 12C17.7167 12 17.479 12.096 17.287 12.288C17.095 12.48 16.9993 12.7173 17 13V17.025C17 17.3083 17.096 17.5417 17.288 17.725C17.48 17.9083 17.7173 18 18 18ZM18 22C18.2833 22 18.521 21.904 18.713 21.712C18.905 21.52 19.0007 21.2827 19 21C19 20.7167 18.904 20.479 18.712 20.287C18.52 20.095 18.2827 19.9993 18 20C17.7167 20 17.479 20.096 17.287 20.288C17.095 20.48 16.9993 20.7173 17 21C17 21.2833 17.096 21.521 17.288 21.713C17.48 21.905 17.7173 22.0007 18 22ZM18 27C16.6167 27 15.3167 26.7373 14.1 26.212C12.8833 25.6867 11.825 24.9743 10.925 24.075C10.025 23.175 9.31267 22.1167 8.788 20.9C8.26333 19.6833 8.00067 18.3833 8 17C8 15.6167 8.26267 14.3167 8.788 13.1C9.31333 11.8833 10.0257 10.825 10.925 9.925C11.825 9.025 12.8833 8.31267 14.1 7.788C15.3167 7.26333 16.6167 7.00067 18 7C19.3833 7 20.6833 7.26267 21.9 7.788C23.1167 8.31333 24.175 9.02567 25.075 9.925C25.975 10.825 26.6877 11.8833 27.213 13.1C27.7383 14.3167 28.0007 15.6167 28 17C28 18.3833 27.7373 19.6833 27.212 20.9C26.6867 22.1167 25.9743 23.175 25.075 24.075C24.175 24.975 23.1167 25.6877 21.9 26.213C20.6833 26.7383 19.3833 27.0007 18 27ZM18 25C20.2167 25 22.1043 24.221 23.663 22.663C25.2217 21.105 26.0007 19.2173 26 17C26 14.7833 25.221 12.8957 23.663 11.337C22.105 9.77833 20.2173 8.99933 18 9C15.7833 9 13.8957 9.779 12.337 11.337C10.7783 12.895 9.99933 14.7827 10 17C10 19.2167 10.779 21.1043 12.337 22.663C13.895 24.2217 15.7827 25.0007 18 25Z"
                fill="#B695F8"
            />
        </svg>
    );
};
