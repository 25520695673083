import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID, ERROR_TYPE_ERROR, USER_TYPE } from 'utills/globalVars';
import { setSideLoader } from 'store/global/globalReducer';
import { createDeductionThunk } from 'store/workspace/workspaceDeduction';
import { toastHandler } from 'responseHanlder';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { BgTimes } from 'utills/svgs/BgTimes';
import pdfIcon from '../../../files/pdfIcon.svg';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import Slideover from 'components/atoms/SlideOver';
import { DragFile } from 'components/atoms/inputs/DragFile';
import SelectedFiles from 'components/molecules/SelectedFiles';

const AddDeduction = ({ toggleAddDeduction, toggleDeduction, open, setOpen }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { sideLoader } = useSelector((state) => state.global);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [title, setTitle] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    const addDeduction = async () => {
        if (!title || selectedFiles.length < 1) {
            toastHandler('Please fill in all required fields', ERROR_TYPE_ERROR);
            return;
        }

        const formData = new FormData();
        formData.append('business_id', business_id);
        formData.append('title', title);
        formData.append('uploaded_by', USER_TYPE);

        formData.append('deduction_file', selectedFiles[0]);

        dispatch(setSideLoader(true));
        dispatch(createDeductionThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toggleDeduction();
                    toggleAddDeduction();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };
    const acceptFormate = ['application/pdf'];

    const handleClearFiles = (_index) => {};
    return (
        <Slideover title="Add Deduction" open={open} setOpen={setOpen}>
            {' '}
            <div>
                <div>
                    <RoleBasedGuard module={'Deduction'} permissions={['admin']} sx="h-[50vh]">
                        {' '}
                        <div>
                            <div className=" mt-3 p-5">
                                <div className="add-ann-form">
                                    <div>
                                        <div>
                                            <label>Title</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                maxLength="50"
                                                placeholder="Title"
                                                className="px-3 add-d-input rounded-xl mt-1"
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="primary-fonts text-md mt-4">
                                    <label> Upload pdf</label>
                                </div>
                                <div className="mt-2">
                                    {' '}
                                    <DragFile
                                        selectedFiles={selectedFiles}
                                        atChange={(file) => {
                                            handleSelectedFiles(file);
                                        }}
                                        hideSelectedFiles
                                        accept={acceptFormate}
                                        isMultiple={true}
                                        dragMessage="Drag files here to upload, or browse for files"
                                        dropMessage="Drop the selected files in this box... "
                                        onlyPdf={true}
                                        message="Only PDF is allowed"
                                        handleClearFiles={handleClearFiles}
                                    />
                                </div>
                                <SelectedFiles
                                    files={selectedFiles}
                                    updateSelectedFiles={(files) => setSelectedFiles(files || [])}
                                />
                            </div>
                        </div>
                        <div className="mx-5 grid grid-cols-3 gap-3">
                            {selectedFile && (
                                <div className=" add-ann-pdf mt-2 relative  ">
                                    <div className="absolute right-1 top-1">
                                        <div onClick={() => setSelectedFile('')} className="cursor-pointer">
                                            {' '}
                                            <BgTimes />{' '}
                                        </div>
                                    </div>
                                    <label className="upload-app-label">
                                        <div className="add-app-camera flex justify-center">
                                            <img src={pdfIcon} alt="alt" style={{ height: 30, width: 30 }} />
                                        </div>
                                        <div className="add-app-camera-text mt-2">{selectedFile?.name.slice(0, 8)}</div>
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className="add-service-bottom">
                            <button
                                onClick={addDeduction}
                                className="ann-btn px-16 cursor-pointer text-white rounded-xl "
                            >
                                {sideLoader ? <SmallLoaderWhite /> : ' Add'}
                            </button>
                        </div>
                    </RoleBasedGuard>
                </div>
            </div>
        </Slideover>
    );
};
export default AddDeduction;
