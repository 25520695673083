import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN, TEMP_TOKEN } from 'utills/globalVars';

const EmailVerificationByExternalLink = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const signupUserObj = localStorage.getItem('signupUser');
        const tempSignUpData = signupUserObj ? JSON.parse(signupUserObj) : null;
        if (tempSignUpData) {
            sessionStorage.setItem('signupStepper', 2);
            navigate('/signup');
        } else {
            sessionStorage.setItem('signupStepper', 1);
            sessionStorage.setItem('loginStepper', 1);
            localStorage?.removeItem(TEMP_TOKEN);
            localStorage.removeItem(ACCESS_TOKEN);
            navigate('/login');
        }
    }, []);
    return <div></div>;
};

export default EmailVerificationByExternalLink;
