import EditableText from 'components/atoms/EditableText/EditableText';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { ParseHtml } from 'Hooks/ParseHtml';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CollapsIcon from 'utills/svgs/CollapsIcon';

import ExpandIcon from 'utills/svgs/ExpandIcon';

export const JobDetail = ({ description, setDescription, handleUpdateValues, assignees }) => {
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    return (
        <div>
            <div className="bg-white rounded-xl p-5 shadow-md">
                <div className="flex justify-between items-center gap-5">
                    <h2 className="text-xl text-[#0A1E46] font-poppins font-[500]">Job Description</h2>

                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            setDescriptionOpen(!descriptionOpen);
                        }}
                    >
                        {descriptionOpen ? <CollapsIcon className={'w-6 h-6'} /> : <ExpandIcon className={'w-6 h-6'} />}
                    </span>
                </div>

                <div className={`mt-3 ${!descriptionOpen && 'h-[410px] overflow-hidden pb-5'}`}>
                    <EditableText
                        componentName="editor"
                        text={description}
                        onUpdate={(text) => handleUpdateValues('description', text, setDescription)}
                        permissions={{ module: 'Job', permissions: ['write', 'admin'] }}
                        disabled={disabled ? true : false}
                    >
                        <ParseHtml htmlContent={description} />
                    </EditableText>
                </div>
            </div>

            <div className="bg-white rounded-xl mt-5 p-5 shadow-md">
                <div>
                    <h2 className="text-xl text-[#0A1E46] font-poppins font-[500] ">Assignee</h2>
                </div>

                <div className="mt-5 flex gap-x-20 gap-y-10 items-center flex-wrap">
                    {assignees?.map((person, index) => (
                        <div key={index} className="flex gap-4 items-center buttons-font-lighter">
                            <div>
                                <DefaultProfileViewer
                                    image={person?.image}
                                    width="50px"
                                    height="50px"
                                    name={person?.first_name + ' ' + person?.last_name}
                                />
                            </div>

                            <div>{person?.first_name + ' ' + person?.last_name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
