import React from 'react';

export const LocationPin = ({ className }) => {
    return (
        <svg
            width="23"
            height="23"
            viewBox="0 0 18 23"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="black"
            stroke="black"
            strokeWidth="0.2"
        >
            <path d="M9 6C8.40666 6 7.82664 6.17595 7.33329 6.50559C6.83994 6.83524 6.45543 7.30377 6.22836 7.85195C6.0013 8.40013 5.94189 9.00333 6.05765 9.58527C6.1734 10.1672 6.45912 10.7018 6.87868 11.1213C7.29824 11.5409 7.83279 11.8266 8.41473 11.9424C8.99667 12.0581 9.59987 11.9987 10.1481 11.7716C10.6962 11.5446 11.1648 11.1601 11.4944 10.6667C11.8241 10.1734 12 9.59334 12 9C11.9991 8.20463 11.6827 7.44209 11.1203 6.87968C10.5579 6.31727 9.79537 6.00091 9 6ZM9 10.5C8.70333 10.5 8.41332 10.412 8.16665 10.2472C7.91997 10.0824 7.72771 9.84811 7.61418 9.57402C7.50065 9.29994 7.47094 8.99833 7.52882 8.70736C7.5867 8.41639 7.72956 8.14912 7.93934 7.93934C8.14912 7.72956 8.41639 7.5867 8.70736 7.52882C8.99834 7.47094 9.29994 7.50065 9.57403 7.61418C9.84811 7.72771 10.0824 7.91997 10.2472 8.16664C10.412 8.41332 10.5 8.70333 10.5 9C10.4995 9.39768 10.3414 9.77895 10.0602 10.0602C9.77895 10.3414 9.39768 10.4995 9 10.5Z" />
            <path d="M14.6568 3.28559C13.258 1.92121 11.3909 1.11072 9.41573 1.01053C7.44055 0.910347 5.49735 1.52757 3.96116 2.74307C2.42498 3.95858 1.40471 5.6862 1.09727 7.5925C0.789827 9.4988 1.21701 11.4486 2.29638 13.0657L7.85806 21.3963C7.98183 21.5816 8.15106 21.7339 8.3504 21.8394C8.54975 21.9448 8.77291 22 8.99966 22C9.22641 22 9.44957 21.9448 9.64891 21.8394C9.84826 21.7339 10.0175 21.5816 10.1413 21.3963L15.7031 13.0657C16.7046 11.5656 17.1473 9.77528 16.9568 7.99468C16.7663 6.21409 15.9542 4.5515 14.6568 3.28559ZM14.4698 12.2818L8.99968 20.4749L3.52954 12.2818C1.85515 9.77385 2.21427 6.41763 4.38343 4.30111C4.98964 3.70963 5.70932 3.24043 6.50138 2.92032C7.29343 2.60021 8.14236 2.43545 8.99968 2.43545C9.857 2.43545 10.7059 2.60021 11.498 2.92032C12.29 3.24043 13.0097 3.70963 13.6159 4.30111C15.7851 6.41763 16.1442 9.77385 14.4698 12.2818Z" />
        </svg>
    );
};
