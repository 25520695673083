/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ArrowPointDown } from 'utills/svgs/ArrowPointDown';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { useDispatch, useSelector } from 'react-redux';
import { handleExpenseTypeId, setLoader } from 'store/global/globalReducer';
import { getProfitAndLossThunk } from 'store/deductions';
import { Pdf } from 'utills/svgs/Pdf';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { DropDownMenu } from 'components/molecules/DropDownMenu';
import { APP_ENVIRONMENT, BASE_URL, CSRF_TOKEN } from 'utills/globalVars';
import { toastHandler } from 'responseHanlder';
import { ExpenseTypeDetail } from './ExpenseTypeDetail';
import SkeletonTable from 'components/atoms/SkeletonTable';
import Slideover from 'components/atoms/SlideOver';
import { useParams } from 'react-router-dom';
import { getCookie } from 'utills/encryption/cryptoUtils';

export const ProfitAndLoss = ({ toggleProfitAndLoss, open, setOpen }) => {
    const { summary_id } = useSelector((state) => state.global);
    const { sideLoader } = useSelector((state) => state.global);
    const [showExpenseTypeDetail, setshowExpenseTypeDetail] = useState(false);

    const dispatch = useDispatch();
    const { id } = useParams();

    const [profitAndLoss, setProfitAndLoss] = useState(null);

    const buttonProps = {
        rightIcon: ArrowPointDown
    };
    const datePickerProps = {
        right: CalendarSmallIco
    };
    const toggleExpenseTypeDetail = (id) => {
        if (id) {
            dispatch(handleExpenseTypeId(id));
        }
        setshowExpenseTypeDetail(!showExpenseTypeDetail);
    };
    const getprofitAndLoass = () => {
        const payload = {
            summary_id: summary_id
        };
        dispatch(setLoader(true));
        dispatch(getProfitAndLossThunk(payload))
            .then((response) => {
                setProfitAndLoss(response.payload);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleDownload = (type) => {
        if (type === 'CSV File') {
            handleDownloadCP({ export_type: 'csv', summary_id, business_client_id: id });
        } else if (type === 'PDF File') {
            handleDownloadCP({ export_type: 'pdf', summary_id, business_client_id: id });
        }
    };
    const handleDownloadCP = async (payload) => {
        let csrfToken = getCookie(CSRF_TOKEN);

        dispatch(setLoader(true));
        const token = localStorage.getItem('access_token');
        await fetch(`${BASE_URL}/exp-inc/profit-loss`, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            credentials: 'include',

            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = profitAndLoss?.file_name ? profitAndLoss?.file_name : 'profit_and_loss';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    toastHandler('File Has been downloaded!', 'success');
                } else {
                    toastHandler(`No ${payload.export_type.toUpperCase()} to download!`, 'error');
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        getprofitAndLoass();
    }, [summary_id]);

    const exportDataOptions = [
        {
            title: 'CSV File',
            icon: CsvIcon
        },
        {
            title: 'PDF File',
            icon: Pdf
        }
    ];

    const calculateTotal = (list) => {
        const total = list.reduce((accumulator, item) => accumulator + item.amount, 0);
        return formatCurrency(total);
    };

    const formatCurrency = (value) => {
        if (value % 1 === 0) {
            return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return '$' + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                showLoader
                size={'max-w-2xl'}
                title="Profit And Loss"
                subTitle={profitAndLoss?.duration_str || ''}
                titlesx={'title1 xl-title !font-semibold mt-0 py-2 flex gap-2 items-center'}
                hideBackArrow
            >
                {showExpenseTypeDetail && (
                    <ExpenseTypeDetail
                        open={showExpenseTypeDetail}
                        setOpen={setshowExpenseTypeDetail}
                        toggleExpenseTypeDetail={toggleExpenseTypeDetail}
                    />
                )}

                {profitAndLoss ? (
                    <>
                        <div className="flex items-center justify-between mt-5">
                            <div className="flex justify-end items-center gap-3">
                                <DatePick
                                    iconSet={datePickerProps}
                                    placeholder={profitAndLoss?.from}
                                    isDisabled={true}
                                />
                                <DatePick iconSet={datePickerProps} placeholder={profitAndLoss?.to} isDisabled={true} />
                            </div>
                            <DropDownMenu
                                isLoading={sideLoader ? true : false}
                                isButton={true}
                                options={exportDataOptions}
                                atClick={(type) => handleDownload(type)}
                            />
                        </div>

                        <div className="my-5 mt-7">
                            <div className="flex justify-between items-center ">
                                <div className="primary-fonts text-xl">
                                    {profitAndLoss?.gst_value ? 'Gross Income' : 'Total Income'}
                                </div>
                                <div className="primary-fonts text-xl">${profitAndLoss?.income}</div>
                            </div>
                            <hr className="mt-2" />
                            <div className=" mt-2 text-xl primary-fonts ">Operating Expenses</div>
                            <hr className="mt-2" />

                            <div className="mt-2">
                                {profitAndLoss?.list?.length &&
                                    profitAndLoss?.list.map((item, index) => (
                                        <div key={'profitloss' + index}>
                                            <div
                                                className={`flex justify-between items-center  summary-body  ${
                                                    index > 0 && 'mt-2'
                                                }`}
                                                key={index}
                                            >
                                                <div>{item.label}</div>
                                                <div>
                                                    <div
                                                        onClick={() => {
                                                            toggleExpenseTypeDetail(item?.expense_type_id);
                                                        }}
                                                    >
                                                        <span className={'cursor-pointer'}>
                                                            <u className={'underline-color'}>
                                                                {' '}
                                                                <span className={'black-color'}>${item.amount}</span>
                                                            </u>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mt-2" />
                                        </div>
                                    ))}
                            </div>
                            <div className="mt-3 primary-fonts flex justify-between items-center text-lg">
                                <div>Total Operating Expenses</div>
                                <div>{calculateTotal(profitAndLoss.list)}</div>
                            </div>
                            <hr className="mt-2" />
                            {profitAndLoss?.gst_value ? (
                                <>
                                    <div className="mt-3 primary-fonts flex justify-between items-center text-lg">
                                        <div>GST</div>
                                        <div>${profitAndLoss?.gst_value}</div>
                                    </div>
                                    <hr className="mt-2" />
                                </>
                            ) : null}

                            <div className="mt-3 primary-fonts flex justify-between items-center text-xl">
                                <div>Net Profit</div>
                                <div>
                                    {profitAndLoss?.profit > 0
                                        ? `$${profitAndLoss?.profit}`
                                        : `-$${Math.abs(profitAndLoss?.profit)}`}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <SkeletonTable columns={2} />
                )}
            </Slideover>
        </>
    );
};
