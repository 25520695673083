const { methods, endpoint } = require('apiEndpoints');
const { default: generateThunkAndSlice } = require('store/thunk/thunk');

const { slice: getAllWidgets, request: getAllWidgetsThunk } = generateThunkAndSlice(
    'getAllWidgets',
    endpoint.getAllWidgets,
    methods.POST
);

const { request: updateSelectWidgetThunk } = generateThunkAndSlice(
    'updateSelectWidget',
    endpoint.updateSelectWidget,
    methods.POST
);

const { slice: getBusinessTasks, request: getBusinessTasksThunk } = generateThunkAndSlice(
    'getBusinessTasks',
    endpoint.getBusinessTasks,
    methods.POST
);

const { request: updateDragWidgetThunk } = generateThunkAndSlice(
    'updateDragWidget',
    endpoint.updateDragWidget,
    methods.POST
);

export {
    getAllWidgets,
    getAllWidgetsThunk,
    updateSelectWidgetThunk,
    getBusinessTasksThunk,
    getBusinessTasks,
    updateDragWidgetThunk
};
