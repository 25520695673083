import React from 'react';

import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { SignDocumentIcon } from 'utills/svgs/SignDocumentIcon';
import { RedTickIcon } from 'utills/svgs/RedTickIcon';
import './css/molecules.css';
import { Button } from 'components/atoms/buttons/Button';
import { WatchIcon } from 'utills/svgs/WatchIcon';
import { PendingDocIcon } from 'utills/svgs/PendingDocIcon';

export const Modal = ({ res, atFinish }) => {
    const [open, _setOpen] = useState(true);
    const [unsignedUsers, setUnsignedUsers] = useState([]);
    useEffect(() => {
        if (res) {
            const filteredUsers = res?.users?.filter((users) => users?.signed === false);
            setUnsignedUsers(filteredUsers);
        }
    }, []);

    const handleDownloadClick = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'your-file-name.pdf';
        link.click();
    };
    const finishDocument = () => {
        atFinish();
    };
    return (
        <div>
            {' '}
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => {}}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            style={{ backgroundColor: 'rgba(0,0,0,0.8)' }}
                            className="fixed inset-0 transition-opacity"
                        />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                    <div>
                                        <div className="flex justify-center items-center w-full mt-5">
                                            {res?.final_doc ? <SignDocumentIcon /> : <PendingDocIcon />}
                                        </div>

                                        <div className="mt-3 text-center sm:mt-5 mx-5">
                                            {res && res?.final_doc ? (
                                                <div className="flex gap-2 items-center positive-text justify-center">
                                                    <div>
                                                        <RedTickIcon />
                                                    </div>
                                                    <div>Everyone has signed</div>
                                                </div>
                                            ) : (
                                                <div className="   negitive-text ">
                                                    {' '}
                                                    <div className="grid grid-cols-12 ">
                                                        {' '}
                                                        <span className="col-span-1">
                                                            <WatchIcon />
                                                        </span>
                                                        <div className="col-span-11 break-all">
                                                            {' '}
                                                            Pending -{' '}
                                                            {unsignedUsers &&
                                                                unsignedUsers?.map((user, index) => (
                                                                    <span key={'unsigneduser' + index}>
                                                                        {' '}
                                                                        {user?.first_name} {user?.last_name},{' '}
                                                                    </span>
                                                                ))}{' '}
                                                            havn&apos;t sigend yet{' '}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="modal-label mt-4">
                                                <label className="break-all">
                                                    {res?.final_doc
                                                        ? ` Everyone has signed ${res?.name}`
                                                        : ` You have signed ${res?.name}`}
                                                </label>
                                            </div>
                                            <div className="mt-4 modal-body">
                                                {res?.final_doc ? (
                                                    'You have signed! you will receive an Email copy of the signed document'
                                                ) : (
                                                    <div>
                                                        {' '}
                                                        {unsignedUsers &&
                                                            unsignedUsers?.map((user, index) => (
                                                                <span key={'usersigned2' + index}>
                                                                    {' '}
                                                                    {user?.first_name} {user?.last_name},{' '}
                                                                </span>
                                                            ))}{' '}
                                                        hasn’t signed yet! Final copy of the document will be generated
                                                        after everyone’s signature
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 px-12">
                                        {' '}
                                        {!res.final_doc ? (
                                            <Button
                                                title="Finish"
                                                classes="ann-btn w-full rounded-lg flex justify-center items-center"
                                                atClick={finishDocument}
                                            />
                                        ) : (
                                            <div className="mt-5 sm:mt-6 grid grid-cols-2 gap-2 ">
                                                <Button
                                                    title="Finish"
                                                    classes="blue-border-btn rounded-lg w-full justify-center items-center"
                                                    atClick={finishDocument}
                                                />
                                                <Button
                                                    title="Download"
                                                    classes="ann-btn w-full rounded-lg justify-center items-center"
                                                    atClick={() => handleDownloadClick(res?.final_doc)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};
