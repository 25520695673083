import React from 'react';
import IntegrationsPage from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/integrations-pages/IntegrationsPage';
const Integrations = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <IntegrationsPage />
            </div>
        </div>
    );
};

export default Integrations;
