/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const getBoundingClientRect = (element) => {
    const rect = element.getBoundingClientRect();

    const { width, height } = rect;

    const top = rect.top + window.scrollY;
    const left = rect.left + window.scrollX;

    return { top, left, width, height };
};
export const PDF_VIEWER_CONTAINER_SELECTOR = '.react-pdf__Document';
export const PDF_VIEWER_PAGE_SELECTOR = '.react-pdf__Page';

export function cn(...inputs) {
    return twMerge(inputs);
}
export const Input = React.forwardRef(({ className, type, ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                'border-input ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border bg-transparent px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                className,
                {
                    'ring-2 !ring-red-500 transition-all': props['aria-invalid']
                }
            )}
            ref={ref}
            {...props}
        />
    );
});
export const useFieldPageCoords = (field) => {
    const [coords, setCoords] = useState({
        x: 0,
        y: 0,
        height: 0,
        width: 0
    });

    const calculateCoords = useCallback(() => {
        const $page = document.querySelector(`${PDF_VIEWER_PAGE_SELECTOR}[data-page-number="${field.page}"]`);

        if (!$page) {
            return;
        }

        const { top, left, height, width } = getBoundingClientRect($page);

        const fieldX = (Number(field.position_x) / 100) * width + left;
        const fieldY = (Number(field.position_y) / 100) * height + top;

        const fieldHeight = (Number(field.height) / 100) * height;
        const fieldWidth = (Number(field.width) / 100) * width;

        setCoords({
            x: fieldX,
            y: fieldY,
            height: fieldHeight,
            width: fieldWidth
        });
    }, [field.height, field.page, field.position_x, field.position_y, field.width]);

    useEffect(() => {
        calculateCoords();
    }, [calculateCoords]);

    useEffect(() => {
        const onResize = () => {
            calculateCoords();
        };

        window.addEventListener('resize', onResize);
        window.onload = (_e) => {};
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [calculateCoords]);

    useEffect(() => {
        const $page = document.querySelector(`${PDF_VIEWER_PAGE_SELECTOR}[data-page-number="${field.page}"]`);

        if (!$page) {
            return;
        }

        const observer = new ResizeObserver(() => {
            calculateCoords();
        });

        observer.observe($page);

        return () => {
            observer.disconnect();
        };
    }, [calculateCoords, field.page]);

    return coords;
};

/**
 * Sort the fields by the Y position on the document.
 */
export const sortFieldsByPosition = (fields) => {
    const clonedFields = JSON.parse(JSON.stringify(fields));

    return clonedFields.sort((a, b) => a.page - b.page || Number(a.position_y) - Number(b.position_y));
};

/**
 * Validate whether all the provided fields are inserted.
 *
 * If there are any non-inserted fields it will be highlighted and scrolled into view.
 *
 * @returns `true` if all fields are inserted, `false` otherwise.
 */
export const validateFieldsInserted = (fields) => {
    const fieldCardElements = document.getElementsByClassName('field-card-container');

    Array.from(fieldCardElements).forEach((element) => {
        element.setAttribute('data-validate', 'true');
    });

    const uninsertedFields = sortFieldsByPosition(fields.filter((field) => !field.inserted));

    const firstUninsertedField = uninsertedFields[0];

    const firstUninsertedFieldElement =
        firstUninsertedField && document.getElementById(`field-${firstUninsertedField.id}`);

    if (firstUninsertedFieldElement) {
        firstUninsertedFieldElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        return false;
    }

    return uninsertedFields.length === 0;
};
