import React from 'react';

export const RadioChip = ({ value, onChange, selectedValue, classes, p }) => {
    return (
        <div
            onClick={(_e) => onChange(value)}
            className={`h-[3rem] rounded-xl pr-16 border ${p} border-secondarybg p-3 buttons-font-lighter cursor-pointer font-poppins ${
                selectedValue === value && 'bg-linear text-white !border-none'
            } `}
        >
            <div className={`flex gap-2 items-center small-text font-poppins cursor-pointer`}>
                <div>
                    <input
                        name={`${value}`}
                        type="radio"
                        className={` ${classes ? classes : ' text-third focus:outline-none'}`}
                        onChange={(_e) => onChange(value)}
                        checked={selectedValue === value}
                    />
                </div>{' '}
                <div htmlFor={`${value}`}>{value}</div>
            </div>
        </div>
    );
};
