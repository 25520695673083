import React from 'react';
import { TailArrowRight } from 'utills/svgs/TailArrowRight';
import './css/molecules.css';
import { Link } from 'react-router-dom';
export const GridCard = ({ text, atClick, iconSet, link, state }) => {
    const isLinkAvailable = !!link;

    const content = (
        <div
            onClick={!isLinkAvailable ? atClick : undefined}
            className="p-5 bg-white  flex  flex-col justify-between cursor-pointer !rounded-xl !h-[170px] lg:h-[130px] w-[220px] lg:min-w-[280px] 
                    border border-solid overflow-hidden hover:shadow-md hover:border-third"
        >
            <div className="flex justify-between items-start">
                <div>{<iconSet.icon className={iconSet?.sx} />}</div>
                <TailArrowRight />
            </div>
            <div className="flex flex-col items-start justify-evenly">
                <div className="mt-2 add-ann-form break-words">
                    <label className=" lg:text-[18px] break-words">{text}</label>
                </div>
            </div>
        </div>
    );

    return isLinkAvailable ? (
        <Link to={link} state={state || {}}>
            {content}
        </Link>
    ) : (
        content
    );
};
