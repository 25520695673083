import TaskDetails from 'components/workplace-content/tasks-pages/task-details/TaskDetails';
import { TasksReportsPage } from 'components/workplace-content/tasks-pages/tasks-reports/TasksReportsPage';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useState } from 'react';

export const TasksReports = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [tasksUpdated, setTasksUpdated] = useState(false);
    const toggleTaskDetails = () => {
        setShowDetails(!showDetails);
    };

    const handleTasksUpdate = () => {
        setTasksUpdated(!tasksUpdated);
    };

    return (
        <div>
            {' '}
            <SidebarLayout>
                <div className="app-dashboard">
                    {showDetails && (
                        <TaskDetails
                            open={showDetails}
                            setOpen={setShowDetails}
                            toggleTaskDetails={toggleTaskDetails}
                            handleTasksUpdate={handleTasksUpdate}
                        />
                    )}

                    <div>
                        <RoleBasedGuard module={'Tasks'} sx="h-[50vh]">
                            <TasksReportsPage toggleTaskDetails={toggleTaskDetails} tasksUpdated={tasksUpdated} />
                        </RoleBasedGuard>
                    </div>
                </div>
            </SidebarLayout>
        </div>
    );
};
