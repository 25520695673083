import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { request: addDesignationsThunk } = generateThunkAndSlice(
    'addDesignations',
    endpoint.addDesignations,
    methods.POST
);

const { slice: getAllDesignations, request: getAllDesignationsThunk } = generateThunkAndSlice(
    'getAllDesignations',
    endpoint.getAllDesignations,
    methods.POST
);

const { request: inactiveDesignationThunk } = generateThunkAndSlice(
    'inactiveDesignation',
    endpoint.inactiveDesignation,
    methods.POST
);

const { request: editDesignationThunk } = generateThunkAndSlice(
    'editDesignation',
    endpoint.editDesignation,
    methods.POST
);

const { request: refreshIntegrationDataThunk } = generateThunkAndSlice(
    'refreshIntegrationData',
    endpoint.refreshIntegrationData,
    methods.POST
);

export {
    addDesignationsThunk,
    getAllDesignationsThunk,
    getAllDesignations,
    inactiveDesignationThunk,
    editDesignationThunk,
    refreshIntegrationDataThunk
};
