import React from 'react';
const CategoriesIcon = ({ className }) => {
    return (
        <>
            <svg
                width="70"
                height="70"
                viewBox="0 0 70 70"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M54.6875 41.5625L32.8125 63.4375L6.5625 37.1875V15.3125H28.4375L54.6875 41.5625Z"
                    stroke="#0A1E46"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.9375 6.5625H32.8125L59.0625 32.8125"
                    stroke="#0A1E46"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.6875 32.8125C22.1037 32.8125 24.0625 30.8537 24.0625 28.4375C24.0625 26.0213 22.1037 24.0625 19.6875 24.0625C17.2713 24.0625 15.3125 26.0213 15.3125 28.4375C15.3125 30.8537 17.2713 32.8125 19.6875 32.8125Z"
                    stroke="#0A1E46"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};

export default CategoriesIcon;
