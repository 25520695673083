import React from 'react';
import { Jumbotron } from 'components/organisms/Jumbotron';
import TeamOperations from './TeamOperations';
import InvitedTeam from './teams/InvitedTeam';
import RegisteredTeam from './teams/RegisteredTeam';
import { useState } from 'react';
const TeamContent = ({
    toggleTeamDetails,
    handleMember,
    toggleAddTeam,
    toggleAddRole,
    handleTeamMember,
    inviteUpdated,
    resendPasseord,
    handleEditMember,
    registeredUpdated
}) => {
    const [teamToggler, setTeamToggler] = useState(false);
    const [team, setTeam] = useState('registered');

    const changeTeam = () => {
        setTeamToggler(!teamToggler);
    };

    const [searchTerm, setSearchTerm] = useState('');

    function searchByname(value) {
        setSearchTerm(value);
    }

    return (
        <div className="team-content">
            <Jumbotron
                title="Teams"
                path={[
                    { title: 'Settings', link: 'settings' },
                    { title: 'Teams', link: 'team' }
                ]}
            />
            <TeamOperations
                changeTeam={changeTeam}
                toggleAddTeam={toggleAddTeam}
                searchByname={searchByname}
                teamToggler={teamToggler}
                setTeam={setTeam}
                team={team}
            />
            <div>
                {team === 'invited' ? (
                    <InvitedTeam
                        toggleTeamDetails={toggleTeamDetails}
                        handleMember={handleMember}
                        inviteUpdated={inviteUpdated}
                        searchTerm={searchTerm}
                    />
                ) : (
                    <RegisteredTeam
                        handleTeamMember={handleTeamMember}
                        toggleAddRole={toggleAddRole}
                        searchTerm={searchTerm}
                        handleMember={handleMember}
                        handleEditMember={handleEditMember}
                        resendPasseord={resendPasseord}
                        registeredUpdated={registeredUpdated}
                    />
                )}
            </div>
        </div>
    );
};

export default TeamContent;
