import React from 'react';

export const RedoIcon = () => {
    return (
        <div>
            {' '}
            <svg width="24" height="9" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.7437 6.12428C20.5027 4.07499 17.2216 2.13053 13.7746 2.00631C11.8553 1.93714 9.85644 2.42875 7.89783 3.82088C5.92668 5.22191 3.95018 7.56884 2.15878 11.2796C1.91868 11.777 1.32084 11.9855 0.823483 11.7454C0.32612 11.5053 0.117575 10.9075 0.357679 10.4101C2.25138 6.48744 4.42061 3.83866 6.73915 2.19071C9.07021 0.533856 11.505 -0.0767743 13.8466 0.00760667C17.9614 0.155887 21.6971 2.43883 24.1589 4.70903L28.6527 0.215201C28.9676 -0.0996624 29.506 0.123121 29.5063 0.568405L29.5137 11.1289C29.5139 11.4053 29.2897 11.6294 29.0133 11.6292L18.4529 11.6218C18.0076 11.6215 17.7848 11.0832 18.0997 10.7683L22.7437 6.12428Z"
                    fill="#454545"
                />
            </svg>
        </div>
    );
};
