/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BoxSkeleton } from 'components/molecules/skeletons/BoxSkeleton';
import { SearchInput } from 'components/molecules/SearchInput';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { Menu } from 'components/molecules/Menu';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { BUSINESS_ID, CPK_TRIP_EXPENSE, LB_EXPENSE, LB_TRIP, LB_TRIP_EXPENSE, SORT_OPTIONS } from 'utills/globalVars';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { DefaultBarChart } from 'components/molecules/DefaultBarChart';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { Button } from 'components/atoms/buttons/Button';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getSummaryForTripLogsThunk, tripDetailChartThunk } from 'store/deductions';
import { formatNumberWithCommas } from 'utills/uid';

const datePickerProps = {
    right: CalendarSmallIco
};
export const TripAndLogbookCard = ({
    vehicle_id,
    business_client_id,
    toggleSummary,
    _toggleProfitAndLoss,
    _toggleRentalSchdule
}) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { isLoading } = useSelector((state) => state.global);
    const business_id = localStorage.getItem(BUSINESS_ID);

    const [SummaryFromDate, setSummaryFromDate] = useState('');
    const [SummaryToDate, setSummaryToDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortAsc, setSortAsc] = useState(false);
    const [SortBy, setSortBy] = useState('DESC');
    const [summary_id, setSummaryId] = useState('');
    const [summary_type, setSummaryType] = useState('');

    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);

    const [expenseXv, setexpenseXv] = useState([]);
    const [expenseChartData, setexpenseChartData] = useState([]);

    const [tripXv, setTripXv] = useState([]);
    const [tripChartData, setTripChartData] = useState([]);

    const [SummaryList, setSummaryList] = useState([]);
    const [SummaryP, setSummaryP] = useState(1);
    const [summaryCount, SetSummaryCount] = useState(0);
    const [summaryPS, _setSummaryPS] = useState(3);
    const [hasMoreContent, setHasMoreContent] = useState(true);

    const [chartType, setChartType] = useState('expense');

    useEffect(() => {
        const currentFinancialYearStart =
            moment().month() < 6
                ? moment().subtract(1, 'year').month(6).date(1).startOf('month')
                : moment().month(6).date(1).startOf('month');
        const currentFinancialYearEnd =
            moment().month() < 6
                ? moment().month(5).date(30).endOf('month')
                : moment().add(1, 'year').month(5).date(30).endOf('month');

        const lastFinancialYearStart =
            moment().month() < 6
                ? moment().subtract(2, 'years').month(6).date(1).startOf('month')
                : moment().subtract(1, 'year').month(6).date(1).startOf('month');
        const lastFinancialYearEnd =
            moment().month() < 6
                ? moment().subtract(1, 'year').month(5).date(30).endOf('month')
                : moment().month(5).date(30).endOf('month');

        const quarters = [];
        for (let i = 0; i < 4; i++) {
            let quarterStart, quarterEnd;
            switch (i) {
                case 0:
                    quarterStart = moment().month(6).date(1).startOf('month');
                    quarterEnd = moment().month(8).date(30).endOf('month');
                    break;
                case 1:
                    quarterStart = moment().month(9).date(1).startOf('month');
                    quarterEnd = moment().month(11).date(31).endOf('month');
                    break;
                case 2:
                    quarterStart = moment().month(0).date(1).startOf('month');
                    quarterEnd = moment().month(2).date(31).endOf('month');
                    break;
                case 3:
                    quarterStart = moment().month(3).date(1).startOf('month');
                    quarterEnd = moment().month(5).date(30).endOf('month');
                    break;
                default:
                    break;
            }
            quarters.push({
                title: `Quarter ${i + 1}`,
                start_date: quarterStart.format('DD-MM-YYYY'),
                end_date: quarterEnd.format('DD-MM-YYYY')
            });
        }

        const last6MonthsStart = moment().subtract(6, 'months').startOf('month');
        const last6MonthsEnd = moment().subtract(1, 'months').endOf('month');

        setFilters([
            {
                title: 'Last Financial Year',
                start_date: lastFinancialYearStart.format('DD-MM-YYYY'),
                end_date: lastFinancialYearEnd.format('DD-MM-YYYY')
            },
            {
                title: 'Current Financial Year',
                start_date: currentFinancialYearStart.format('DD-MM-YYYY'),
                end_date: currentFinancialYearEnd.format('DD-MM-YYYY')
            },
            ...quarters,
            {
                title: 'Last 6 Months',
                start_date: last6MonthsStart.format('DD-MM-YYYY'),
                end_date: last6MonthsEnd.format('DD-MM-YYYY')
            }
        ]);

        if (summary_id && currentFinancialYearStart && currentFinancialYearEnd) {
            const payload = {
                trip_summary_id: summary_id,
                summary_type: summary_type,
                from_date: currentFinancialYearStart.format('DD-MM-YYYY'),
                to_date: currentFinancialYearEnd.format('DD-MM-YYYY'),
                business_client_id: id
            };
            getChartData(payload);
        }
    }, [summary_id]);

    useEffect(() => {
        if (selectedFilter && selectedFilter.start_date && selectedFilter.end_date) {
            const payload = {
                trip_summary_id: summary_id,
                summary_type: summary_type,
                from_date: selectedFilter?.start_date,
                to_date: selectedFilter?.end_date,
                business_client_id: id
            };
            getChartData(payload, false);
        }
    }, [selectedFilter]);

    useEffect(() => {
        onFilterChange();
    }, [searchTerm, sortAsc, SummaryFromDate, SummaryToDate]);

    useEffect(() => {
        getsummary();
    }, [SummaryP]);

    const onFilterChange = () => {
        if (vehicle_id) {
            const fromdate =
                SummaryFromDate && SummaryFromDate !== '' && SummaryToDate && SummaryToDate !== ''
                    ? moment(new Date(SummaryFromDate)).format('DD-MM-YYYY').toString()
                    : '';
            const todate =
                SummaryFromDate && SummaryFromDate !== '' && SummaryToDate && SummaryToDate !== ''
                    ? moment(new Date(SummaryToDate)).format('DD-MM-YYYY').toString()
                    : '';

            setSummaryList([]);
            setSummaryP(1);
            getSummaryforBusinessDef({
                business_client_id: business_client_id,
                vehicle_id: vehicle_id,
                sort_by: SortBy,
                business_id: business_id,
                page: SummaryP,
                page_size: summaryPS,
                //
                from: fromdate,
                to: todate,
                search_term: searchTerm,
                filter: true
            });
        }
    };

    const getsummary = () => {
        const fromdate =
            SummaryFromDate === '' ? '' : moment(new Date(SummaryFromDate)).format('DD-MM-YYYY').toString();
        const todate = SummaryToDate === '' ? '' : moment(new Date(SummaryToDate)).format('DD-MM-YYYY').toString();
        if (vehicle_id) {
            getSummaryforBusinessDef({
                business_client_id: id,
                vehicle_id: vehicle_id,
                sort_by: SortBy,
                business_id: business_id,
                page: SummaryP,
                page_size: summaryPS,
                //
                from: fromdate,
                to: todate,
                search_term: searchTerm,
                filter: false
            });
        }
    };

    const getSummaryforBusinessDef = (data) => {
        setHasMoreContent(true);
        const payload = {
            business_client_id: data.business_client_id,
            vehicle_id: data?.vehicle_id,
            sort_by: data?.sort_by ?? 'ASC',
            business_id: data.business_id,
            page: data?.page ?? 1,
            page_size: data?.page_size ?? 10,
            from_date: data?.from,
            to_date: data?.to,
            search_term: data?.search_term ?? ''
        };
        dispatch(getSummaryForTripLogsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    SetSummaryCount(response?.payload?.count);
                    if (data?.filter) {
                        const list = [...response.payload.rows];
                        if (list.length === response?.payload?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                    } else {
                        const list = [...SummaryList, ...response.payload.rows];
                        if (list.length === response?.payload?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                        if (summary_id === '') {
                            setSummaryId(response?.payload?.rows[0]?.trip_summary_id);
                            setSummaryType(response?.payload?.rows[0]?.summary_type);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const getChartData = (payload, updateChart = true) => {
        let tempChartType = chartType;
        if (payload && summary_id) {
            setChartType('');
            dispatch(tripDetailChartThunk(payload))
                .then((response) => {
                    if (response?.payload?.expense) {
                        const xv = [];
                        const chartData = [];
                        for (const item in response?.payload?.expense) {
                            xv.push(response?.payload?.expense[item].label);
                            chartData.push({
                                x: response?.payload?.expense[item].label,
                                y: response?.payload?.expense[item].value
                            });
                        }
                        const sortedXv = xv.sort((a, b) => a - b);
                        setexpenseXv(sortedXv);
                        setexpenseChartData(chartData);
                        if (updateChart) {
                            setChartType('expense');
                        } else {
                            setChartType(tempChartType);
                        }
                    } else {
                        setexpenseXv([]);
                        setexpenseChartData([]);
                    }

                    if (response?.payload?.trip) {
                        if (summary_type === CPK_TRIP_EXPENSE) {
                            const xv = [];
                            const chartData = [];
                            for (const item in response?.payload?.trip) {
                                xv.push(response?.payload?.trip[item].label);
                                chartData.push({
                                    x: response?.payload?.trip[item].label,
                                    y: response?.payload?.trip[item].value
                                });
                            }
                            const sortedXv = xv.sort((a, b) => a - b);
                            setTripXv(sortedXv);
                            setTripChartData([
                                {
                                    data: chartData
                                }
                            ]);
                        } else {
                            const dataObject =
                                summary_type === LB_TRIP_EXPENSE ? response?.payload.trip : response?.payload;

                            setTripChartData(dataObject?.trip);
                            setTripXv(dataObject?.months);
                        }
                        if (updateChart) {
                            setChartType('workTrip');
                        } else {
                            setChartType(tempChartType);
                        }
                    } else {
                        setTripChartData([]);
                        setTripXv([]);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const handleMoreSummry = () => {
        const totalPages = Math.ceil(summaryCount / summaryPS);
        if (SummaryP < totalPages) {
            setSummaryP(SummaryP + 1);
        }
    };
    const handleClear = () => {
        setSummaryFromDate(null);
        setSummaryToDate(null);
        setSearchTerm('');
        setSummaryP(1);
        setSummaryList([]);
    };

    const onClickItem = (summary_id, summary_type) => {
        setSummaryId(summary_id);
        setSummaryType(summary_type);
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
    };
    const handleFromDateChange = (date) => {
        setSummaryFromDate(date);
    };
    const handleToDateChange = (date) => {
        setSummaryToDate(date);
    };
    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        setSortBy(sortAsc ? 'ASC' : 'DESC');
    };

    const showExpenseType = (type) => {
        switch (type) {
            case LB_TRIP:
                return 'Trips Only';
            case LB_EXPENSE:
                return 'Expense Only';
            case LB_TRIP_EXPENSE:
            case CPK_TRIP_EXPENSE:
                return 'Trips and Expense';
            default:
                return '';
        }
    };
    return (
        <div>
            <div className={`transition-opacity duration-1000 ease-out ${'opacity-100 h-auto'}`}>
                <div className="my-2">
                    <div className="deductions-cards-container py-5 ">
                        {isLoading ? (
                            <BoxSkeleton />
                        ) : (
                            <div className="bg-white rounded-2xl shadow-custom overflow-hidden">
                                <div className=" user-details-card ">
                                    <div className="flex justify-between items-center gap-5 pr-5 p-5">
                                        <div className="detail-card-title text-2xl">Received Summary</div>
                                        <div className="flex gap-2 items-center">
                                            {SummaryFromDate || SummaryToDate || searchTerm ? (
                                                <ClearFilterButton atClick={() => handleClear()} />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className="my-3 mx-5 flex gap-5 items-center justify-between flex-wrap">
                                        <SearchInput
                                            width="300px"
                                            atChange={(e) => {
                                                handleSearch(e.target.value);
                                            }}
                                            value={searchTerm}
                                            placeholder="Search"
                                            inputBorder="1px solid #DCDCDC"
                                        />
                                        <div className="flex gap-3 items-center ">
                                            {' '}
                                            <DatePick
                                                iconSet={datePickerProps}
                                                onDateChange={handleFromDateChange}
                                                value={SummaryFromDate}
                                                placeholder="From"
                                                bg="transparent"
                                                sx="!rounded-xl text-gray-900"
                                            />
                                            <DatePick
                                                iconSet={datePickerProps}
                                                onDateChange={handleToDateChange}
                                                value={SummaryToDate}
                                                placeholder="To"
                                                bg="transparent"
                                                sx=" !rounded-xl text-gray-900"
                                            />
                                            <div>
                                                <Menu
                                                    title="Order By"
                                                    iconSet={{ first: SortIcon, second: StepDownIcon }}
                                                    options={SORT_OPTIONS}
                                                    gap={5}
                                                    lastMl={5}
                                                    isModalDisabled={true}
                                                    condition={sortAsc}
                                                    atMenuClick={() => {
                                                        handleSortByDate();
                                                    }}
                                                    atClick={(_title) => {}}
                                                    text="max-md"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="my-5  sm:px-2 lg:px-4 ">
                                        <InfiniteScrollComp
                                            height={450}
                                            dataLength={SummaryList.length}
                                            next={handleMoreSummry}
                                            hasMore={hasMoreContent || false}
                                            loader={<SkeletonTable columns={4} />}
                                        >
                                            <div className="px-2">
                                                {SummaryList && SummaryList?.length > 0 ? (
                                                    SummaryList?.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() =>
                                                                onClickItem(item.trip_summary_id, item.summary_type)
                                                            }
                                                            className={`mt-5 `}
                                                        >
                                                            <div
                                                                className={`w-full bg-white rounded-2xl shadow-xl px-4 pb-5 pt-5 ${
                                                                    summary_id === item?.trip_summary_id
                                                                        ? 'ring-2 ring-inset ring-third'
                                                                        : ''
                                                                } `}
                                                            >
                                                                <div className="flex flex-wrap items-start justify-between gap-y-3">
                                                                    <div className="flex gap-3 flex-wrap">
                                                                        <div className="tracking-normal">
                                                                            <span className="px-4 py-1.5 text-third bg-[#b695f838] !font-medium !rounded-xl !text-sm lg:text-lg ruby">
                                                                                Summary ID:{' '}
                                                                                <b>
                                                                                    {item?.ref_no
                                                                                        ? item?.ref_no
                                                                                        : 'N/A'}
                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div className="tracking-normal">
                                                                            <span className="px-4 py-1.5 text-primary bg-gray-200 !font-medium !rounded-xl !text-sm lg:text-lg ruby">
                                                                                {item?.method?.label || 'N/A'}
                                                                            </span>
                                                                        </div>
                                                                        <div className="tracking-normal">
                                                                            <span className="px-4 py-1.5 text-green-600 bg-green-100 !font-medium !rounded-xl !text-sm lg:text-lg ruby">
                                                                                {showExpenseType(item?.summary_type)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            title="View Summary"
                                                                            classes="primary-bg  text-white font-fira text-md font-font4 rounded-xl px-3 py-2"
                                                                            atClick={() =>
                                                                                toggleSummary(
                                                                                    item?.trip_summary_id,
                                                                                    item?.summary_type,
                                                                                    item?.from_date,
                                                                                    item?.to_date
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="grid  grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-2 mt-3">
                                                                    <div>
                                                                        {[
                                                                            LB_TRIP,
                                                                            LB_TRIP_EXPENSE,
                                                                            CPK_TRIP_EXPENSE
                                                                        ].includes(item?.summary_type) && (
                                                                            <div className="grid grid-cols-2 gap-5 items-center">
                                                                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                    Total Distance Reported:
                                                                                </h2>
                                                                                <div className="text-black font-medium text-right">
                                                                                    {item?.distance_covered
                                                                                        ? formatNumberWithCommas(
                                                                                              item?.distance_covered
                                                                                          ) + ' Km'
                                                                                        : 'N/A'}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="hidden lg:block"></div>
                                                                    {[
                                                                        LB_TRIP,
                                                                        LB_TRIP_EXPENSE,
                                                                        CPK_TRIP_EXPENSE
                                                                    ].includes(item?.summary_type) && (
                                                                        <>
                                                                            <div>
                                                                                <div className="grid grid-cols-2 gap-5 items-center">
                                                                                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                        Work Use:
                                                                                    </h2>
                                                                                    <div className="text-black font-medium text-right">
                                                                                        {item?.work_use
                                                                                            ? formatNumberWithCommas(
                                                                                                  item?.work_use
                                                                                              ) + ' Km'
                                                                                            : 'N/A'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="grid grid-cols-2 gap-5 items-center">
                                                                                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                        Work Use Percentage
                                                                                    </h2>
                                                                                    <div className="text-black font-medium text-right">
                                                                                        {item?.work_percent || 'N/A'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="grid grid-cols-2 gap-5 items-center">
                                                                                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                        Personal Use
                                                                                    </h2>
                                                                                    <div className="text-black font-medium text-right">
                                                                                        {item?.personal_use
                                                                                            ? formatNumberWithCommas(
                                                                                                  item?.personal_use
                                                                                              ) + ' Km'
                                                                                            : 'N/A'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="grid grid-cols-2 gap-5 items-center">
                                                                                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                        Personal Use Percentage
                                                                                    </h2>
                                                                                    <div className="text-black font-medium text-right">
                                                                                        {item?.personal_percent ||
                                                                                            'N/A'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    {[LB_EXPENSE, LB_TRIP_EXPENSE].includes(
                                                                        item?.summary_type
                                                                    ) && (
                                                                        <div>
                                                                            <div className="grid grid-cols-2 gap-5 items-center">
                                                                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                    Total Expense Reported:
                                                                                </h2>
                                                                                <div className="text-black font-medium text-right">
                                                                                    {item?.total_expense_report ||
                                                                                        'N/A'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {[
                                                                        LB_EXPENSE,
                                                                        LB_TRIP_EXPENSE,
                                                                        CPK_TRIP_EXPENSE
                                                                    ].includes(item?.summary_type) && (
                                                                        <div>
                                                                            <div className="grid grid-cols-2 gap-5 items-center">
                                                                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                    Total Claimable Expense
                                                                                </h2>
                                                                                <div className="text-black font-medium text-right">
                                                                                    {item?.claimable_expense || 'N/A'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {[CPK_TRIP_EXPENSE].includes(
                                                                        item?.summary_type
                                                                    ) && (
                                                                        <div>
                                                                            <div className="grid grid-cols-2 gap-5 items-center">
                                                                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                                                                    Current Rate per kilometre
                                                                                </h2>
                                                                                <div className="text-black font-medium text-right">
                                                                                    {item?.current_rate || 'N/A'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </InfiniteScrollComp>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div>
                            {isLoading ? (
                                <BoxSkeleton />
                            ) : (
                                <div className="bg-white shadow-custom rounded-2xl overflow-hidden ">
                                    <div className="detail-card-title p-3 px-4 text-2xl">Reported Expenses</div>
                                    <div className="flex items-center justify-center py-2 px-10">
                                        {[LB_TRIP_EXPENSE].includes(summary_type) && (
                                            <div className="field-switch-wrapper mt-2 cursor-pointer !h-[2.6rem] !rounded-xl ">
                                                <div
                                                    className={`field-switch ${
                                                        chartType === 'workTrip' && 'active'
                                                    } flex justify-center items-center !h-[2.6rem] !rounded-xl `}
                                                    onClick={() => setChartType('workTrip')}
                                                >
                                                    Work Trip
                                                </div>

                                                <div
                                                    className={`field-switch ${
                                                        chartType === 'expense' && 'active'
                                                    } flex justify-center items-center !h-[2.6rem] !rounded-xl `}
                                                    onClick={() => setChartType('expense')}
                                                >
                                                    Expense
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex items-center justify-between p-2 gap-3 mx-2 flex-wrap">
                                        <div className="text-lg text-semibold">Filter</div>
                                        <div className="w-[230px]">
                                            <SimpleSelectMenu
                                                placeholder="Select"
                                                targetProperty="title"
                                                valuePropertyName={'title'}
                                                selectedValue={filters.length > 0 ? filters[1]?.title : ''}
                                                optionsData={filters || []}
                                                sx="rounded-xl py-2 ring-gray-400 bg-white !font-normal"
                                                onChangeValue={(obj) => {
                                                    setSelectedFilter(obj);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {chartType === 'expense' && (
                                        <>
                                            <DefaultBarChart
                                                xValues={expenseXv}
                                                stats={[
                                                    {
                                                        data: expenseChartData
                                                    }
                                                ]}
                                                barWidth={`70%`}
                                                height={280}
                                                Yaxes={true}
                                                radius="20%"
                                            />
                                        </>
                                    )}
                                    {chartType === 'workTrip' && (
                                        <>
                                            <DefaultBarChart
                                                xValues={tripXv}
                                                stats={tripChartData}
                                                barWidth={`70%`}
                                                height={280}
                                                Yaxes={true}
                                                radius="20%"
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
