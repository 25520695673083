import React from 'react';
import MyThreadDetailsPage from '../../components/workplace-content/workplace-threads/my-threads/MyThreadDetailsPage';
import { SidebarLayout } from 'layouts/SidebarLayout';
const MyThreadDetails = () => {
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                <div>
                    <MyThreadDetailsPage />
                </div>
            </SidebarLayout>
        </div>
    );
};
export default MyThreadDetails;
