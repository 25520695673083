import React from 'react';

export const BigPlusIcon = () => {
    return (
        <div>
            {' '}
            <svg width="22" height="22" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="30" height="30" rx="15" fill="url(#paint0_linear_1526_3881)" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 8C14.3557 8 13.8333 8.52234 13.8333 9.16667V13.8333H9.16667C8.52233 13.8333 8 14.3557 8 15C8 15.6443 8.52234 16.1667 9.16667 16.1667H13.8333V20.8333C13.8333 21.4777 14.3557 22 15 22C15.6443 22 16.1667 21.4777 16.1667 20.8333V16.1667H20.8333C21.4777 16.1667 22 15.6443 22 15C22 14.3557 21.4777 13.8333 20.8333 13.8333H16.1667V9.16667C16.1667 8.52233 15.6443 8 15 8Z"
                    fill="url(#paint1_linear_1526_3881)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_1526_3881"
                        x1="16.472"
                        y1="0.37257"
                        x2="16.4495"
                        y2="30.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_1526_3881"
                        x1="15.6869"
                        y1="8.17387"
                        x2="15.6764"
                        y2="22"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
