import React from 'react';
import './css/organizationalSettings.css';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { cn } from 'utills/tailwindUtil';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

const TaskTemplateTable = ({
    dataList,
    handleDeletionId,
    handleSelectedAll,
    handleSelectId,
    selectedIds,
    _setEditObj,
    handleTemplateDetails
}) => {
    const { hasPermissions } = useRoleGuard();
    return (
        <div className="pb-5 rounded-xl  overflow-x-auto">
            <div className="mt-8 flow-root">
                <div className="inline-block min-w-full py-4 align-middle">
                    <div className="relative">
                        <table className="min-w-full table-fixed divide-y  divide-gray-300 bg-white">
                            <thead>
                                <tr>
                                    <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                        <input
                                            type="checkbox"
                                            className="!rounded-[3px] bg-body"
                                            onChange={(e) => handleSelectedAll(e)}
                                            checked={selectedIds?.length === dataList?.length && dataList?.length !== 0}
                                        />
                                    </th>
                                    <th
                                        scope="col"
                                        className="min-w-[12rem] py-3.5 pr-3 text-left text-md font-semibold text-gray-900"
                                    >
                                        Task Templates
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                    >
                                        Checklist
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                    >
                                        Created Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                    >
                                        Last Updated
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                    >
                                        Created By
                                    </th>

                                    <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left flex gap-2 items-center text-md font-semibold text-gray-900"
                                    >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {dataList?.map((user) => (
                                    <tr
                                        key={user?.task_template_id}
                                        className={` ${
                                            selectedIds?.includes(user?.task_template_id) ? 'bg-gray-50' : undefined
                                        } hover:bg-[#F8F4FE] transition-colors duration-200 cursor-pointer`}
                                    >
                                        <td className="relative py-3.5 px-7 sm:w-12 sm:px-6">
                                            {selectedIds?.includes(user?.task_template_id) && (
                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-thirdbg" />
                                            )}
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-secondary  text-indigo-600 focus:ring-indigo-600"
                                                value={user?.task_template_id}
                                                checked={selectedIds?.includes(user?.task_template_id)}
                                                onChange={(_e) => handleSelectId(user?.task_template_id)}
                                            />
                                        </td>
                                        <td
                                            className={cn(
                                                'whitespace-nowrap   pr-3 text-md py-3.5 font-poppins',
                                                selectedIds?.includes(user?.task_template_id)
                                                    ? 'text-thirdbg'
                                                    : 'text-black'
                                            )}
                                        >
                                            {user?.name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                            {user?.taskCheckLists && user?.taskCheckLists?.length > 0
                                                ? user?.taskCheckLists[0]?.title
                                                : '__'}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                            <FormateDateTime dateTime={user?.created_at} />
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                            <FormateDateTime dateTime={user?.updated_at} />
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                            <DefaultProfileViewer
                                                image={user?.created_by?.image}
                                                width="45px"
                                                height="45px"
                                                name={user?.created_by?.first_name + ' ' + user?.created_by?.last_name}
                                                tooltip
                                            />
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-3.5 ">
                                            <div className="flex items-start gap-2 justify-start">
                                                <div
                                                    onClick={() => {
                                                        if (!hasPermissions('Tasks', ['admin'])) {
                                                            return toastHandler(
                                                                'You do not have permission to perform this action.',
                                                                TOAST_TYPE_ERROR
                                                            );
                                                        }
                                                        handleTemplateDetails(user?.task_template_id);
                                                    }}
                                                    className="flex gap-2 items-center cursor-pointer "
                                                >
                                                    <BgEditIcon />
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        if (!hasPermissions('Tasks', ['admin'])) {
                                                            return toastHandler(
                                                                'You do not have permission to perform this action.',
                                                                TOAST_TYPE_ERROR
                                                            );
                                                        }

                                                        handleDeletionId(user?.task_template_id);
                                                    }}
                                                    className="flex gap-2 items-center cursor-pointer "
                                                >
                                                    <BgDeleteIcon />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskTemplateTable;
