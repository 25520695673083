import React from 'react';
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { EyeIcon } from '../../../../../utills/svgs/EyeIcon';
import { BgPencilIcon } from '../../../../../utills/svgs/BgPencilIcon';
import { useDispatch, useSelector } from 'react-redux';
import { formateDate } from '../../../../../utills/moment';
import { formHandler, handlePrebuiltPageSize, setLoader } from 'store/global/globalReducer';
import { SearchInput } from 'components/molecules/SearchInput';
import { fetchFormsThunk, getSingleFormThunk, publishFormsThunk } from 'store/form';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import Pagination from 'components/pagination';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const PrebuiltForms = ({ handleUpdateForm, togglePreviewForm }) => {
    const { hasPermissions } = useRoleGuard();

    const { data } = useSelector((state) => state.fetchForms);
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
    const business_id = localStorage.getItem(BUSINESS_ID);

    useEffect(() => {
        if (pageSize) {
            dispatch(handlePrebuiltPageSize(pageSize));
        }
    }, [pageSize]);

    function searchByItem(value) {
        setSearchValue(value);
    }

    const handleChangePage = (newPage, perPageCount) => {
        setPage(newPage);
        setPageSize(perPageCount);
        getAllTelephonicDirectories({
            business_id,
            page: newPage,
            page_size: perPageCount,
            form_status: 'prebuilt',
            service_id: ''
        });
    };

    const getAllTelephonicDirectories = (payload) => {
        dispatch(fetchFormsThunk(payload))
            .then(() => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        dispatch(setLoader(true));
        getAllTelephonicDirectories({
            business_id,
            page: 1,
            page_size: pageSize,
            filters: {
                search_term: searchValue
            },
            form_status: 'prebuilt',
            service_id: ''
        });
    }, [searchValue]);

    const { isLoading } = useSelector((state) => state?.global);

    const checkbox = useRef();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState([]);

    useLayoutEffect(() => {
        const isIndeterminate = selectedPeople.length > 0 && selectedPeople?.length < data?.forms?.length;
        setChecked(selectedPeople.length === data?.forms?.length);
        setIndeterminate(isIndeterminate);
        checkbox.current.indeterminate = isIndeterminate;
    }, [selectedPeople]);

    function toggleAll() {
        if (!hasPermissions('Forms', ['admin'])) {
            toastHandler("You don't have permission to do this action", TOAST_TYPE_ERROR);
            return;
        }
        setSelectedPeople(checked || indeterminate ? [] : data?.forms?.rows);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const updateFormHandle = (form) => {
        if (!hasPermissions('Forms', ['admin'])) {
            toastHandler("You don't have permission to do this action", TOAST_TYPE_ERROR);
            return;
        }

        if (form?.form_id) {
            dispatch(setLoader(true));
            dispatch(getSingleFormThunk({ form_id: form?.form_id }))
                .then((response) => {
                    if (response.payload) {
                        dispatch(formHandler(response.payload));
                        handleUpdateForm();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const viewBusinessForm = (form) => {
        if (form) {
            if (form?.form_id) {
                dispatch(setLoader(true));
                dispatch(getSingleFormThunk({ form_id: form?.form_id }))
                    .then((response) => {
                        if (response.payload) {
                            dispatch(formHandler(response.payload));
                            togglePreviewForm();
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });
            }
        }
    };

    const handlePublish = () => {
        let ids = [];

        selectedPeople?.forEach((item) => {
            ids?.push(item?.form_id);
        });

        dispatch(setLoader(true));
        dispatch(publishFormsThunk({ form_ids: ids }))
            .then((response) => {
                if (response.payload) {
                    setSelectedPeople([]);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="prebuilt-forms">
            <SearchInput
                atChange={(e) => searchByItem(e.target.value)}
                placeholder="Search"
                inputBorder="1px solid #979797"
                width="350px"
            />

            <div className="px-8 ">
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 h-[550px] overflow-y-auto overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="relative">
                                {selectedPeople.length > 0 && (
                                    <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-white sm:left-12">
                                        <button
                                            onClick={() => handlePublish()}
                                            type="button"
                                            className="inline-flex items-center gap-3 rounded  px-2 py-1 text-md bg-linear text-white  shadow-sm ring-1 ring-inset ring-blueish hover:bg-blueish disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-blueish"
                                        >
                                            {selectedPeople?.length > 1 ? ' Publish Forms' : 'Publish Form'}{' '}
                                            {isLoading && <SmallLoaderWhite />}
                                        </button>
                                    </div>
                                )}
                                <table className="min-w-full  table-fixed divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="relative  sm:w-12 ">
                                                <input
                                                    type="checkbox"
                                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-third focus:ring-third"
                                                    ref={checkbox}
                                                    checked={checked}
                                                    onChange={toggleAll}
                                                />
                                            </th>
                                            <th
                                                scope="col"
                                                className="min-w-[12rem] py-3.5 pr-3 text-left text-md font-semibold text-gray-900"
                                            >
                                                Form Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                            >
                                                Created Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y  divide-gray-200 bg-white">
                                        {data?.forms?.rows?.map((person, index) => (
                                            <tr
                                                key={index}
                                                className={selectedPeople.includes(person) ? 'bg-gray-50' : undefined}
                                            >
                                                <td className="relative px-7 sm:w-12 sm:px-6">
                                                    {selectedPeople.includes(person) && (
                                                        <div className="absolute inset-y-0 left-0 w-0.5 bg-linear" />
                                                    )}
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-thirdbg focus:thirdbg"
                                                        value={person.email}
                                                        checked={selectedPeople.includes(person)}
                                                        onChange={(e) => {
                                                            if (!hasPermissions('Forms', ['admin'])) {
                                                                toastHandler(
                                                                    "You don't have permission to do this action",
                                                                    TOAST_TYPE_ERROR
                                                                );
                                                                return;
                                                            }

                                                            setSelectedPeople(
                                                                e.target.checked
                                                                    ? [...selectedPeople, person]
                                                                    : selectedPeople.filter((p) => p !== person)
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                <td
                                                    className={classNames(
                                                        'whitespace-nowrap py-4 pr-3 text-md font-medium',
                                                        selectedPeople.includes(person) ? 'text-third' : 'text-gray-900'
                                                    )}
                                                >
                                                    {person?.form_name}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-md text-gray-500">
                                                    {formateDate(person?.created_at)}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-md text-gray-500">
                                                    <div className="flex  gap-1 items-center">
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => viewBusinessForm(person)}
                                                        >
                                                            <EyeIcon />
                                                        </div>

                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => updateFormHandle(person)}
                                                        >
                                                            <BgPencilIcon />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <div></div>
                            </div>
                        </div>
                    </div>

                    <div className=" lg:mx-10 mx-5">
                        <Pagination
                            page={Number(page)}
                            pageSize={Number(pageSize)}
                            totalPages={Math.ceil(data?.count / pageSize)}
                            onChangePage={(page) => handleChangePage(page, Number(pageSize))}
                            onCountPerPageChange={(perPageCount) => handleChangePage(1, perPageCount)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PrebuiltForms;
