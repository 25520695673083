import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import { Truncate } from 'components/molecules/Truncate';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { getSingleJobTemplateThunk } from 'store/JobManager';
import { MileStoneIcon } from 'utills/svgs/MileStoneIcon';

export const JobTemplateDetails = ({ open, setOpen, template }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.getSingleJobTemplate);
    const { isLoading } = useSelector((state) => state.global);

    useEffect(() => {
        if (template) {
            dispatch(setLoader(true));
            dispatch(getSingleJobTemplateThunk({ urlParams: `${template?.job_template_id}` })).finally(() => {
                dispatch(setLoader(false));
            });
        }
    }, [template]);

    return (
        <Slideover open={open} setOpen={setOpen} size="max-w-[1400px]">
            <div className="px-3">
                <div className="add-detail  text-black">
                    <div className="text-2xl font-fira font-semibold"> Job Template Detail</div>
                </div>{' '}
                {isLoading && <Loader />}
                <div className="mt-10">
                    <DefaultLightBgCard title={data?.name || '--'}>
                        <div className="  ">
                            <div className="text-xl font-[500] font-poppins text-blueish ">{data?.title}</div>

                            <div className="mt-3 break-all">
                                <Truncate text={data?.description} maxLength={1500} />
                            </div>
                        </div>
                    </DefaultLightBgCard>
                </div>
                <div>
                    {data?.milestones?.map((milestone, index) => (
                        <div className="shadow-sm rounded-xl  mt-5" key={index}>
                            <div className="p-5">
                                <div className="flex gap-2 items-center">
                                    <div className="border-thirdbg border rounded-xl font-[500] p-2 flex gap-2 items-center">
                                        <MileStoneIcon className={'w-[27px]  h-[27px]'} fill={'#b695f8'} />
                                        <span> Milestone {index + 1} </span>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <div className="text-xl font-[500] font-poppins text-blueish ">
                                        {milestone?.title}
                                    </div>

                                    <div className="mt-3">
                                        <Truncate text={milestone?.description} maxLength={1500} />
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div key={index}>
                                <DefaultLightBgCard title="Tasks">
                                    <div className="  rounded-xl overflow-x-auto">
                                        <div className=" flow-root">
                                            <div className="inline-block min-w-full py-4 align-middle">
                                                <div className="relative">
                                                    <table className="min-w-full table-fixed divide-y  divide-gray-300 bg-white">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className=" py-3.5 text-left text-md font-semibold text-gray-900"
                                                                >
                                                                    Title
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                                >
                                                                    Created At
                                                                </th>

                                                                <th
                                                                    scope="col"
                                                                    className="px-3 py-3.5 text-left flex gap-2 items-center text-md font-semibold text-gray-900"
                                                                >
                                                                    Status
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {milestone?.tasks?.map((task, _index) => (
                                                                <tr
                                                                    key={task?.task_template_id}
                                                                    className={`  hover:bg-[#F8F4FE] transition-colors duration-200 cursor-pointer`}

                                                                    //
                                                                >
                                                                    <td className=" text-black whitespace-nowrap   pr-3 text-md py-3.5 font-poppins">
                                                                        {task?.title}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                                                        {task?.created_at}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3  py-3.5 text-md ">
                                                                        <span className="bg-[#F08201]/10  text-[#F08201] rounded-xl text-sm p-1 px-2">
                                                                            {task?.status}{' '}
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DefaultLightBgCard>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Slideover>
    );
};
