import React from 'react';
import "./css/payroll.css";
import PayrollCards from "./PayrollCards";
const PayrollPage = () => {
  return (
    <div>
      <PayrollCards />
    </div>
  );
};

export default PayrollPage;
