import React, { useState } from 'react';
import './css/taskDetail.css';
import { useDispatch } from 'react-redux';
import { addEmployeeToTaskThunk } from 'store/workspace/workspaceTasks';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { BgTimes } from 'utills/svgs/BgTimes';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
export const EmployeesModal = ({ employees, task_id, toggleShowAddEmp, getSingleTask, handleTasksUpdate }) => {
    const dispatch = useDispatch();
    const [user_id, setUserId] = useState('');
    const [empLoader, setEmpLoader] = useState(false);

    const addEmp = () => {
        if (!user_id) {
            toastHandler('Please select an employee first', TOAST_TYPE_ERROR);
            return;
        }
        const payload = {
            employee_id: user_id,
            task_id
        };
        setEmpLoader(true);
        dispatch(addEmployeeToTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleShowAddEmp();
                    getSingleTask();
                    handleTasksUpdate();
                    toastHandler('Employee added', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setEmpLoader(false);
            });
    };
    return (
        <div className="employees-modal shadow-md p-5 mt-2 add-ann-form">
            <div className="flex justify-end items-enter mb-2">
                <span onClick={toggleShowAddEmp} className="cursor-pointer">
                    {' '}
                    <BgTimes />
                </span>
            </div>

            <label>Select Employees</label>

            <SimpleSelectMenu
                placeholder="Select Employee"
                targetProperty="first_name"
                optionsData={employees || []}
                sx="rounded-xl py-3 ring-gray-400 "
                onChangeValue={(obj) => setUserId(obj?.user_id)}
            />

            <hr className="my-2"></hr>
            <div className="mt-2 flex items-center justify-between gap-3">
                <button
                    onClick={toggleShowAddEmp}
                    className="border-button font-fira rounded-lg buttons-font  px-3 py-2"
                >
                    {' '}
                    Cancel{' '}
                </button>{' '}
                <button
                    onClick={addEmp}
                    className="ann-btn buttons-font font-fira px-7 py-2 rounded-lg mt-1 flex items-center gap-2"
                >
                    Add {empLoader ? <SmallLoaderWhite /> : ''}{' '}
                </button>{' '}
            </div>
        </div>
    );
};
