import { Switch } from 'components/atoms/inputs/Switch';
import { Loader } from 'components/common/Loader';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { disableEmailAuthThunk, disableGoogleAuthThunk, login } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { USER_TYPE } from 'utills/globalVars';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import { LaptopMobile } from 'utills/svgs/LaptopMobile';

export const MultiFactorInfo = ({ handleCondition }) => {
    const { data: loginData } = useSelector((state) => state.login);
    const [googleAuth, setGoogleAuth] = useState(loginData?.google_authenticator_2fa ?? false);
    const [emailAuth, setEmailAuth] = useState(loginData?.email_2fa ?? false);
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);

    const onSwitch = (type) => {
        if (type === 'email') {
            if (loginData?.email_2fa) {
                if (!loginData?.google_authenticator_2fa) {
                    toastHandler('To ensure your security, at least one 2FA method is required.', 'error');
                    return;
                }

                dispatch(setLoader(true));
                const payload = { user_id: loginData.user_id, user_type: USER_TYPE };
                dispatch(disableEmailAuthThunk(payload))
                    .then((response) => {
                        if (response.payload) {
                            dispatch(
                                login.actions.handleUpdate({
                                    ...loginData,
                                    email_2fa: false
                                })
                            );
                            setEmailAuth(false);
                        }
                        return;
                    })
                    .catch((_error) => {
                        return;
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });
                //disable email_2fa api here

                return;
            }
            handleCondition(1);
            return;
        }
        if (type === 'google') {
            if (loginData?.google_authenticator_2fa) {
                if (!loginData?.email_2fa) {
                    toastHandler('To ensure your security, at least one 2FA method is required.', 'error');
                    return;
                }
                dispatch(setLoader(true));
                const payload = { user_id: loginData.user_id, user_type: USER_TYPE };
                dispatch(disableGoogleAuthThunk(payload))
                    .then((response) => {
                        if (response.payload) {
                            dispatch(
                                login.actions.handleUpdate({
                                    ...loginData,
                                    google_authenticator_2fa: false
                                })
                            );
                            setGoogleAuth(false);
                        }
                        return;
                    })
                    .catch((_error) => {
                        return;
                    })
                    .finally(() => {
                        dispatch(setLoader(false));
                    });

                return;
            }

            handleCondition(3);
            return;
        }
    };

    return (
        <div>
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px] bg-white shadow rounded-xl sm:rounded-2xl  py-4 ">
                {isLoading && <Loader />}
                <div>
                    <div className="one-head px-5 pt-2">
                        <h1 className="text-blueish">Two Factor Authentication</h1>
                    </div>

                    <div className="flex justify-center items-center pt-16">
                        <LaptopMobile />
                    </div>

                    <div className="text-center font-poppins table-title mt-7 text-blueish">
                        Add extra security to your account
                    </div>

                    <div className="small-text font-poppins text-sixth  px-5 mt-4">
                        <p>
                            Please enable the Two Factor authentication process to enhance the security of your account.
                        </p>
                    </div>

                    <div className="flex gap-5 justify-between mt-3 buttons-font-lighter items-center font-poppins px-2 py-3 w-[60%]">
                        <div className=" flex px-3 items-center gap-2">
                            <div className="flex justify-center items-center ">
                                <BlueGreaterSign />
                            </div>
                            <div className="font-poppins ">Email Verification</div>
                        </div>
                        <div className="">
                            <div>
                                <span>
                                    <Switch condition={emailAuth} atChange={() => onSwitch('email')} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-5 justify-between mt-3 buttons-font-lighter  items-center font-poppins  px-2 py-3 w-[60%]">
                        <div className=" flex px-3 items-center gap-2">
                            <div className="flex justify-center items-center">
                                <div>
                                    <BlueGreaterSign />
                                </div>
                            </div>
                            <div className="font-poppins ">Google Authenticator</div>
                        </div>
                        <div className="">
                            <div>
                                <span>
                                    <Switch condition={googleAuth} atChange={() => onSwitch('google')} />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="mt-72"> </div>
                </div>
            </div>
        </div>
    );
};
