import React from 'react';

export const SideTimes = ({ className }) => {
    return (
        <div className="hover:scale-105 transition-all">
            <svg
                width="22"
                height="22"
                viewBox="0 0 26 26"
                fill="#b695f8"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <circle cx="13" cy="13" r="13" />
                <path d="M19 8L8 19" stroke="white" strokeWidth="2" strokeLinecap="round" />
                <path d="M19 19L8 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </div>
    );
};
