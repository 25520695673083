import React from 'react';

const RedFlag = ({ className }) => {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M11.92 5.66667C11.8615 5.56532 11.8307 5.45036 11.8307 5.33333C11.8307 5.21631 11.8615 5.10135 11.92 5L13.0667 3C13.2423 2.69587 13.3347 2.35086 13.3346 1.99968C13.3346 1.6485 13.242 1.30352 13.0663 0.999445C12.8907 0.695368 12.638 0.442913 12.3338 0.267469C12.0296 0.0920238 11.6845 -0.00022483 11.3333 4.11489e-07H0.666667C0.489856 4.11489e-07 0.320287 0.0702384 0.195262 0.195263C0.070238 0.320287 0 0.489856 0 0.666667V15.3333C0 15.5101 0.070238 15.6797 0.195262 15.8047C0.320287 15.9298 0.489856 16 0.666667 16C0.843478 16 1.01305 15.9298 1.13807 15.8047C1.2631 15.6797 1.33333 15.5101 1.33333 15.3333V10.6667H11.3333C11.6835 10.6662 12.0275 10.5738 12.3308 10.3987C12.6341 10.2236 12.8861 9.97196 13.0615 9.66889C13.237 9.36583 13.3299 9.022 13.3308 8.6718C13.3317 8.32159 13.2406 7.97729 13.0667 7.67333L11.92 5.66667ZM11.92 9C11.8607 9.10273 11.7751 9.18777 11.672 9.24637C11.5688 9.30496 11.4519 9.33498 11.3333 9.33333H1.33333V1.33333H11.3333C11.4497 1.33404 11.5638 1.36517 11.6644 1.42365C11.765 1.48213 11.8485 1.56591 11.9067 1.66667C11.9652 1.76801 11.996 1.88298 11.996 2C11.996 2.11702 11.9652 2.23199 11.9067 2.33333L10.7533 4.33333C10.5778 4.63737 10.4854 4.98226 10.4854 5.33333C10.4854 5.68441 10.5778 6.0293 10.7533 6.33333L11.9067 8.33333C11.9652 8.43468 11.996 8.54964 11.996 8.66667C11.996 8.78369 11.9652 8.89865 11.9067 9H11.92Z"
                fill="#F14747"
            />
        </svg>
    );
};

export default RedFlag;
