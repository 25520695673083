import React from 'react';

export const ArrowDownDim = ({ className }) => {
    return (
        <svg
            width="12"
            height="8"
            viewBox="0 0 18 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M1 1L9 8L17 1" stroke="#979797" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
