import React from 'react';
import './css/loginSuccessfull.css';
import { useNavigate } from 'react-router-dom';
import { LaptopUser } from '../../utills/svgs/LaptopUser';
import { useDispatch, useSelector } from 'react-redux';
import { viewOnBoardingDetailsThunk } from 'store/auth/slices';
import { setSideLoader } from 'store/global/globalReducer';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { Button } from 'components/atoms/buttons/Button';
import useRedirectToDashboardHook from 'Hooks/useRedirectToDashboardHook';
const LoginSuccessfullPage = ({ handleCondition }) => {
    const navigate = useNavigate();
    const { redirectToDashboard } = useRedirectToDashboardHook();
    const { data: loginData } = useSelector((state) => state.login);

    const dispatch = useDispatch();
    const onBoardingCheck = () => {
        if (loginData?.is_employee) {
            // navigate('/business/dashboard');
            redirectToDashboard();
            return;
        }
        dispatch(viewOnBoardingDetailsThunk())
            .then((response) => {
                if (response.payload) {
                    if (response.payload?.user && response.payload?.business) {
                        sessionStorage.setItem('loginStepper', 1);
                        localStorage.setItem('onboarding', true);
                        // navigate('/business/dashboard');
                        redirectToDashboard();

                        handleCondition(1);
                        return;
                    } else {
                        sessionStorage.setItem('loginStepper', 1);
                        navigate('/onboarding');
                        handleCondition(1);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    return (
        <div>
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <AuthCardLayout>
                    {' '}
                    <div className="mt-24 flex justify-center items-center">
                        <LaptopUser />
                    </div>
                    <div className="successfull-text flex mt-10 justify-center items-center">Login Successful!</div>
                    <div className="mb-20  mt-12 flex justify-center items-center">
                        <Button
                            title="Proceed"
                            atClick={() => onBoardingCheck()}
                            classes="px-12 ann-btn flex justify-center buttons-font items-center rounded-lg"
                        />
                    </div>
                </AuthCardLayout>
            </div>
        </div>
    );
};

export default LoginSuccessfullPage;
