import React from 'react';
import { BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import { archiveDeductionThunk } from 'store/workspace/workspaceDeduction';
import { toastHandler } from 'responseHanlder';
import { BgPdfIcon } from 'utills/svgs/BgPdfIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import ConfirmationModal from 'utills/confirmationModal';
import { useState } from 'react';
import useRoleGuard from 'Hooks/useRoleGuard';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { handleDeductionUpdate } from 'store/global/globalReducer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { BgShareIcon } from 'utills/svgs/BgShareIcon';

const DeductionList = ({ filteredDeductions, toggleEditDeduction, getAllDeductions }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [delId, setDelId] = useState('');
    const { hasPermissions } = useRoleGuard();
    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const handleArchiveDeduction = (business_deduction_id, createdby) => {
        if (hasPermissions('Deduction', ['admin'], createdby)) {
            setDelId(business_deduction_id);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const deleteDeduction = () => {
        const payload = {
            business_id,
            business_deduction_id: delId
        };
        dispatch(archiveDeductionThunk(payload))
            .then((_response) => {
                setDelId('');
                const payload = {
                    business_id
                };
                getAllDeductions(payload);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});

        setIsConfirmationOpen(false);
    };

    const handleEdit = (deduction) => {
        if (hasPermissions('Deduction', ['admin'], deduction?.created_by)) {
            dispatch(handleDeductionUpdate(deduction));
            toggleEditDeduction();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    const handleEmailClick = (subjectMail, mailbody, fileName) => {
        const subject = subjectMail || '';
        const body = mailbody || '';
        const fileLink = fileName ? `\nDownload Link: ${fileName}` : '';
        const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body + fileLink)}`;
        window.location.href = mailtoLink;
    };
    return (
        <div className="md:px-10 mt-5 px-5 text-black ">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={deleteDeduction}
            />
            {open && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpen} open={open} /> : ''}

            <div className="">
                <div className="">
                    <div className="px-4 sm:px-6 rounded-lg bg-white lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                                >
                                                    Deductions
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 pr-16 text-right text-md font-semibold text-gray-900"
                                                >
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {filteredDeductions &&
                                                filteredDeductions?.map((d, index) => (
                                                    <tr key={index}>
                                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-md sm:pl-0">
                                                            <div>{d.title}</div>
                                                        </td>
                                                        <td className="whitespace-nowrap flex justify-end px-3 py-5 text-right text-md text-gray-500">
                                                            <div className="flex gap-2 items-center">
                                                                <div
                                                                    onClick={() => {
                                                                        setPdfUrl(d?.file_name);
                                                                        setOpen(true);
                                                                    }}
                                                                    className="cursor-pointer"
                                                                >
                                                                    <BgPdfIcon />
                                                                </div>
                                                                <span
                                                                    className="cursor-pointer"
                                                                    onClick={() => handleEdit(d)}
                                                                >
                                                                    <BgEditIcon />
                                                                </span>
                                                                <span
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        handleEmailClick(
                                                                            'Share Deduction',
                                                                            `Hello there, here is the deduction report for Deduction - Apprentice. Please check the attached document and you will be able to download or view it. Please click the following link: \n`,
                                                                            d?.file_name
                                                                        )
                                                                    }
                                                                >
                                                                    <BgShareIcon />
                                                                </span>

                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        handleArchiveDeduction(
                                                                            d.business_deduction_id,
                                                                            d?.created_by
                                                                        )
                                                                    }
                                                                >
                                                                    <BgDeleteIcon />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DeductionList;
