import React from 'react';

import { Clients } from 'pages/app-users/Clients';
import { Invites } from 'pages/app-users/Invites';
import { Leads } from 'pages/app-users/Leads';
import { ChangePassword } from 'pages/settings/ChangePassword';
import { MultiFactorAuthentication } from 'pages/settings/MultiFactorAuthentication';
import { SecurityAndAuthentication } from 'pages/settings/SecurityAndAuthentication';
import { TaskModuleSettings } from 'pages/settings/TaskModuleSettings';
import { BulkClientInvite } from 'pages/settings/BulkClientInvite';
import { WorkspaceSettings } from 'pages/settings/WorkspaceSettings';
import { TelephonicDirectorySettings } from 'pages/settings/TelephonicDirectorySettings';
import { AppConnections } from 'pages/settings/AppConnections';
import { XeroAppConnections } from 'pages/settings/XeroAppConnections';
import { XeroAppConnected } from 'pages/settings/XeroAppConnected';
import { ClientsSettings } from 'pages/settings/ClientsSettings';
import { OfflineClientsImportExport } from 'pages/settings/OfflineClientsImportExport';
import { OfflineClientBulkImport } from 'pages/settings/OfflineClientBulkImport';
import { OutLookAppConnections } from 'pages/settings/OutLookAppConnections';
import { OutlookAppConnected } from 'pages/settings/OutlookAppConnected';
import { JobDetails } from 'pages/settings/job-manager/job-details/JobDetails';

import { JobManager } from './../../pages/settings/job-manager/JobManager';
import { Jobs } from './../../pages/settings/job-manager/jobs/Jobs';
import { JobManagerSettings } from 'pages/settings/job-manager/job-manager-settings/JobManagerSettings';
import { DocumentListSetting } from 'pages/settings/job-manager/job-manager-settings/DocumentListSetting';
import JobManagerRequestSignature from 'components/job-manager-components/job-manager-detail-components/work-station/Documents/DocumentSignature/JobManagerRequestSignature';
import JobManagerSignRequestedDoc from 'components/job-manager-components/job-manager-detail-components/work-station/Documents/DocumentSignature/JobManagerSignRequestedDoc';
import { JobTypeSettings } from 'pages/settings/job-manager/job-manager-settings/JobTypeSettings';
import { JobTemplates } from 'pages/settings/job-manager/job-manager-settings/JobTemplates';
import { RelayRoomSettings } from 'pages/settings/job-manager/job-manager-settings/RelayRoomSettings';
import { Groups } from 'pages/settings/groups/Groups';
import { ClientGroups } from 'pages/settings/groups/clientGroups/ClientGroups';
import { JobGroups } from 'pages/settings/groups/jobGroups/JobGroups';
import { EmailNotifications } from 'pages/settings/email-notifications/EmailNotifications';
import { OnboardingServices } from 'pages/settings/onboarding-services/OnboardingServices';
import { JobStatusSettings } from 'pages/settings/job-manager/job-manager-settings/JobStatusSettings';
import { UserEmailSignatureSettings } from 'pages/settings/job-manager/UserEmailSignatureSettings';
import { OutLookMessagesAppConnections } from 'pages/settings/OutLookMessagesAppConnections';
import { OutLookMessagesAppConnected } from 'pages/settings/OutLookMessagesAppConnected';
import EmailManagerAccountSettings from 'pages/settings/email-manager-account-settings';
import EmailAccounts from 'pages/settings/email-manager-account-settings/EmailAccounts';
import EmailCategories from 'pages/settings/email-manager-account-settings/EmailCategories';

const AppUsers = React.lazy(() => import('pages/app-users/AppUsers'));

const { default: AppUserProfile } = require('pages/app-users/AppUserfProfile');
const { default: Profile } = require('pages/profile/Profile');
const { Settings } = require('pages/settings/Settings');
const { default: RoleManagement } = require('pages/team/RoleManagement');
const { default: Team } = require('pages/team/Team');
const { Protected } = require('routes/protected-routes/Protected');

const settingsRoutes = [
    {
        path: '/settings',
        component: <Protected Component={Settings} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/app/users',
        component: <Protected Component={AppUsers} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/app/user/profile/:id',
        component: <Protected Component={AppUserProfile} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/team/rolemanagement',
        component: <Protected Component={RoleManagement} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/profile',
        component: <Protected Component={Profile} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/team',
        component: <Protected Component={Team} />,
        isProtected: true,
        layout: 'header'
    },
    {
        path: '/clients',
        component: <Protected Component={Clients} />,
        isProtected: true,
        layout: 'header'
    },
    {
        path: '/invites',
        component: <Protected Component={Invites} />,
        isProtected: true,
        layout: 'header'
    },
    {
        path: '/leads',
        component: <Protected Component={Leads} />,
        isProtected: true,
        layout: 'header'
    },
    {
        path: '/security-and-authentication',
        component: <Protected Component={SecurityAndAuthentication} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/settings/task-module',
        component: <Protected Component={TaskModuleSettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/settings/clients',
        component: <Protected Component={ClientsSettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/settings/job-manager',
        component: <Protected Component={JobManagerSettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/settings/job-manager/documents',
        component: <Protected Component={DocumentListSetting} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/settings/job-manager/relayroom-settings',
        component: <Protected Component={RelayRoomSettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/offline/clients/import-export',
        component: <Protected Component={OfflineClientsImportExport} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/offline/clients/bulk-import',
        component: <Protected Component={OfflineClientBulkImport} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/change-password',
        component: <Protected Component={ChangePassword} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/multi-factor-auth',
        component: <Protected Component={MultiFactorAuthentication} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/bulk-invite',
        component: <Protected Component={BulkClientInvite} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/workspace-settings',
        component: <Protected Component={WorkspaceSettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/app-connections',
        component: <Protected Component={AppConnections} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/app-connections/xero',
        component: <Protected Component={XeroAppConnections} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/app-connections/xero/connected',
        component: <Protected Component={XeroAppConnected} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/app-connections/microsoft',
        component: <Protected Component={OutLookAppConnections} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/app-connections/microsoft/connected',
        component: <Protected Component={OutlookAppConnected} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/app-connections/outlook',
        component: <Protected Component={OutLookMessagesAppConnections} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/app-connections/outlook/connected',
        component: <Protected Component={OutLookMessagesAppConnected} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/telephonic-directory-settings',
        component: <Protected Component={TelephonicDirectorySettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-details/:id',
        component: <Protected Component={JobDetails} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-manager',
        component: <Protected Component={JobManager} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-manager/jobs',
        component: <Protected Component={Jobs} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-manager/request-signature/:jobId',
        component: <Protected Component={JobManagerRequestSignature} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-manager/sign-requested-doc/:jobId/:docId',
        component: <Protected Component={JobManagerSignRequestedDoc} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-manager/settings/job-type',
        component: <Protected Component={JobTypeSettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-manager/settings/job-status',
        component: <Protected Component={JobStatusSettings} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/job-manager/settings/job-templates',
        component: <Protected Component={JobTemplates} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/settings/groups',
        component: <Protected Component={Groups} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/groups/clients',
        component: <Protected Component={ClientGroups} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/groups/jobs',
        component: <Protected Component={JobGroups} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/email-notifications',
        component: <Protected Component={EmailNotifications} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/onboarding-services',
        component: <Protected Component={OnboardingServices} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/user-email-signature',
        component: <Protected Component={UserEmailSignatureSettings} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/email-manager-account-settings',
        component: <Protected Component={EmailManagerAccountSettings} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/email-manager-account-settings/email-account',
        component: <Protected Component={EmailAccounts} />,
        isProtected: true,
        layout: 'sidebar'
    },
    {
        path: '/settings/email-manager-account-settings/categories',
        component: <Protected Component={EmailCategories} />,
        isProtected: true,
        layout: 'sidebar'
    }
];

export default settingsRoutes;
