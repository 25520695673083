import React from 'react';
import Breadcrumb from 'components/atoms/Breadcrumb';
import { Button } from 'components/atoms/buttons/Button';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleIndicators } from 'store/global/globalReducer';
import AddTDIcon from 'utills/svgs/AddTDIcon';
const TDjumbo = ({ toggleAddDirectory, showAddDirectory, path }) => {
    const dispatch = useDispatch();
    const { data: loginData } = useSelector((state) => state.login);
    const { showindicators } = useSelector((state) => state.global);

    useEffect(() => {
        const driverObj = driver({
            showProgress: true,
            onHighlighted: () => {},
            steps: [
                {
                    element: '.add-directory-indicate',

                    popover: {
                        title: 'Client',
                        description:
                            'By clicking this button you can add a telephonic directory.Please note that atleast one branch is required.',
                        popoverClass: 'my-custom-popover-class '
                    }
                }
            ]
        });
        if (!showAddDirectory && showindicators && !loginData?.second_time_login) {
            driverObj.drive();
        } else {
            driverObj.destroy();
        }

        return () => {
            dispatch(handleIndicators(false));
            driverObj.destroy();
        };
    }, [showAddDirectory, showindicators]);

    return (
        <div>
            <div className=" relative  md:flex md:flex-row flex-col  px-10 py-10 md:justify-between ">
                <div className="profile-jumbo-flex">
                    <div className="jumbo-flex-1 ">
                        <div className="text-3xl font-fira font-[600]">Telephonic Directory</div>
                        {path ? (
                            <Breadcrumb
                                path={[
                                    { title: 'Workspace', link: 'workspace' },
                                    {
                                        title: 'Telephonic Directory',
                                        link: ''
                                    }
                                ]}
                            />
                        ) : (
                            <div className="jumbo-dir mt-2">
                                Workspace <span className="special-jumbo-text">&gt; Telephonic Directory</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className=" md:mt-0 my-2   md:absolute right-10 top-5 md:top-11">
                    <div className="flex gap-2 items-start flex-wrap">
                        <Button
                            title={'Add Telephonic Directory'}
                            type={'button'}
                            atClick={toggleAddDirectory}
                            classes={'bg-primary flex items-center gap-2 text-white py-3 !px-3 rounded-lg'}
                            iconSet={{ leftIcon: AddTDIcon }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TDjumbo;
