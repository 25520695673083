import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { authEmailSendOtpThunk, authEmailVerifyOtpThunk } from 'store/auth/slices';
import { Loader } from 'components/common/Loader';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { BoldArrowRight } from 'utills/svgs/BoldArrowRight';

const EmailALternatePage = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.login);
    const isLoading = useSelector((state) => state.global.isLoading);

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [otp, setOtp] = useState([null, null, null, null, null, null]);

    const [timeRemaining, setTimeRemaining] = useState(67);
    const [timerEnded, setTimerEnded] = useState(false);

    const handleKeyUp = (event, index) => {
        const input = inputRefs[index].current;
        let value = input.value;

        if (value.length > 1) {
            value = value.charAt(0);
            input.value = value;
        }

        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (event.keyCode === 8 && value.length === 0 && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    }, [timeRemaining]);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const resendOtpData = {
        user_id: data?.user_id,
        type: 5,
        user_type: 'employee'
    };

    const resendOtp = () => {
        setTimeRemaining(67);
        dispatch(setLoader(true));
        dispatch(authEmailSendOtpThunk(resendOtpData))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const verifyEmailData = {
        user_id: data?.user_id,
        otp: otp.join(''),
        user_type: 'employee'
    };

    const handleOtp = () => {
        const newOtp = otp.join('');
        if (!newOtp || newOtp.length < 6) {
            NotificationManager.error('OTP inputs cant be empty');
            return;
        }

        dispatch(setLoader(true));
        dispatch(authEmailVerifyOtpThunk(verifyEmailData))
            .then((response) => {
                if (response.payload) {
                    navigate('/security/questions/alternate');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        resendOtp();
    }, []);

    return (
        <div>
            {isLoading && <Loader />}

            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <AuthCardLayout>
                    {' '}
                    <div className=" px-5">
                        <div className="flex gap-2 mt-3">
                            <div
                                onClick={() => props.handleCondition(1)}
                                className="flex justify-center items-center cursor-pointer"
                            >
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M12.1199 0.447335C12.6462 1.02223 12.6231 1.93037 12.0683 2.47572L4.85611 9.56522L22.6154 9.56521C23.3801 9.56521 24 10.2076 24 11C24 11.7924 23.3801 12.4348 22.6154 12.4348L4.85611 12.4348L12.0683 19.5243C12.6231 20.0696 12.6462 20.9778 12.1199 21.5527C11.5936 22.1276 10.7173 22.1515 10.1625 21.6061L0.431695 12.0409C0.156086 11.77 1.99213e-06 11.3937 1.9233e-06 11C1.85447e-06 10.6063 0.156085 10.23 0.431695 9.95906L10.1625 0.393848C10.7173 -0.151504 11.5936 -0.127558 12.1199 0.447335Z"
                                        fill="url(#paint0_linear_2369_10798)"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_2369_10798"
                                            x1="13.1776"
                                            y1="0.273216"
                                            x2="13.1625"
                                            y2="22"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#101828" />
                                            <stop offset="0.998509" stopColor="#0D1B37" />
                                            <stop offset="1" stopColor="#0A1E46" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div className="authenticator-title ">
                                <h1>Email Verification</h1>
                            </div>
                        </div>

                        <div className="flex gap-2 authenticator-text">
                            <div className="flex justify-center items-center">
                                <BoldArrowRight />
                            </div>
                            <div className="mt-2">Enter a 6-digit code that is sent to your email address</div>
                        </div>

                        <div>
                            <div className="otp-inputs pr-10">
                                <div className="flex gap-3 items-center ">
                                    <div>
                                        <input
                                            ref={inputRefs[0]}
                                            onKeyUp={(event) => handleKeyUp(event, 0)}
                                            maxLength="1"
                                            type="number"
                                        />
                                    </div>
                                    <div>
                                        <input
                                            ref={inputRefs[1]}
                                            onKeyUp={(event) => handleKeyUp(event, 1)}
                                            maxLength="1"
                                            type="number"
                                        />
                                    </div>
                                    <div>
                                        <input
                                            ref={inputRefs[2]}
                                            onKeyUp={(event) => handleKeyUp(event, 2)}
                                            maxLength="1"
                                            type="number"
                                        />
                                    </div>
                                    <div>
                                        <input
                                            ref={inputRefs[3]}
                                            onKeyUp={(event) => handleKeyUp(event, 3)}
                                            maxLength="1"
                                            type="number"
                                        />
                                    </div>
                                    <div>
                                        <input
                                            ref={inputRefs[4]}
                                            onKeyUp={(event) => handleKeyUp(event, 4)}
                                            maxLength="1"
                                            type="number"
                                        />
                                    </div>{' '}
                                    <div>
                                        <input
                                            ref={inputRefs[5]}
                                            onKeyUp={(event) => handleKeyUp(event, 5)}
                                            maxLength="1"
                                            type="number"
                                        />
                                    </div>
                                </div>

                                <div className="resend mt-5">
                                    <div className="resend-confirmation mt-5">
                                        <p>
                                            {' '}
                                            <span className="text-blue-600 cursor-pointer">
                                                {timerEnded ? (
                                                    <span
                                                        onClick={resendOtp}
                                                        className={`my-element ${timeRemaining > 0 ? 'hidden' : ''}`}
                                                    >
                                                        Resend
                                                    </span>
                                                ) : (
                                                    ' '
                                                )}{' '}
                                            </span>
                                            ( {minutes}:{seconds < 10 ? `0${seconds}` : seconds} )
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" mt-60 flex justify-between items-center">
                            <button onClick={() => props.handleCondition(1)} className="px-12 py-2 btn-1 rounded-md">
                                Back
                            </button>

                            <button onClick={handleOtp} className="px-12 py-2 btn-2 rounded-md">
                                Verify
                            </button>
                        </div>
                    </div>
                </AuthCardLayout>
            </div>
        </div>
    );
};

export default EmailALternatePage;
