import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS, _TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import ConfirmationModal from 'utills/confirmationModal';
import { Bars4Icon } from '@heroicons/react/20/solid';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { archiveJobStatusThunk, changeJobSequenceThunk, getAllJobsStatusesThunk } from 'store/workspace/workspaceTasks';
import { arrayMoveImmutable } from 'array-move';
import { Button } from 'components/atoms/buttons/Button';
import { PlusVector } from 'utills/svgs/PlusVector';
import { SearchInput } from 'components/molecules/SearchInput';
import AddJobStatus from './AddJobStatus';
import FormateDateTime from 'components/atoms/FormateDateTime';

export const JobStatusSettings = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [add, setAdd] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [job_status_id, setTaskStatus] = useState('');
    const [editJobStatus, setEditJobStatus] = useState(null);
    const { data } = useSelector((state) => state.getAllJobsStatuses);
    const [searchTerm, setSearchTerm] = useState('');
    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        if (!add) {
            setEditJobStatus(null);
        }
    }, [add]);

    useEffect(() => {
        const filteredStatuses =
            data?.length && data?.filter((row) => row?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
        setStatuses(filteredStatuses);
    }, [data, searchTerm]);

    useEffect(() => {
        getAllStatuses();
    }, []);

    const getAllStatuses = () => {
        dispatch(setLoader(true));
        dispatch(getAllJobsStatusesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        const payload = {
            business_id,
            job_status_id
        };

        dispatch(setLoader(true));
        dispatch(archiveJobStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setStatuses((prevStatuses) =>
                        prevStatuses.filter((status) => status.job_status_id !== job_status_id)
                    );
                    const payload = {
                        business_id
                    };
                    getAllStatuses(payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const archiveStatus = (job_status_id) => {
        setIsConfirmationOpen(true);
        setTaskStatus(job_status_id);
    };

    const onSortEnd = (oldIndex, newIndex) => {
        if (!hasPermissions('Job', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const newArray = arrayMoveImmutable(statuses, oldIndex, newIndex);
        setStatuses(newArray);

        const payload = {
            business_id,
            list: newArray
                .filter((status) => status.sequence !== 0)
                .map((status, index) => ({ job_status_id: status.job_status_id, sequence: index + 1 }))
        };
        dispatch(setLoader(true));
        dispatch(changeJobSequenceThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler(`Job Status Updated Successfully!`, TOAST_TYPE_SUCCESS);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleEdit = (status) => {
        setEditJobStatus(status);
        setAdd(true);
    };

    return (
        <SidebarLayout>
            <RoleBasedGuard module="Job" permissions={['write', 'read', 'admin']} sx="p-5">
                <Jumbotron
                    title="Job Status"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },
                        {
                            link: 'settings/job-manager',
                            title: 'Job Manager'
                        },
                        {
                            link: '',
                            title: 'Job Status'
                        }
                    ]}
                />

                {add && (
                    <AddJobStatus
                        open={add}
                        setOpen={setAdd}
                        editJobStatus={editJobStatus}
                        addSingle={editJobStatus ? true : false}
                    />
                )}

                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={handleDelete}
                />

                <div className="mx-5 md:mx-10">
                    <div className="flex justify-between items-center gap-5 flex-wrap">
                        <SearchInput
                            width="320px"
                            defaultValue={searchTerm || ''}
                            atChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            placeholder="Search job Status"
                        />
                        <div className="flex gap-2 items-center">
                            <Button
                                title="Add Job Status"
                                iconSet={{ leftIcon: PlusVector }}
                                iconInvert
                                atClick={() => {
                                    setAdd(!add);
                                }}
                                classes="ann-btn px-8 rounded-xl flex gap-2 items-center"
                            />
                        </div>
                    </div>

                    <div className=" pb-5 rounded-xl overflow-x-auto">
                        <div className="mt-8 flow-root">
                            <div className="my-5 bg-white rounded-xl p-5">
                                <div className="grid grid-cols-5 gap-5 primary-fonts text-md">
                                    <div className="flex gap-2 col-span-2 items-center">
                                        <div>Status Name</div>
                                    </div>
                                    <div>Created Date</div>
                                    <div>Last Updated</div>
                                    <div>Action</div>
                                </div>
                                <div className="team-line team-streched-line mt-2"></div>
                                <SortableList
                                    onSortEnd={onSortEnd}
                                    dropTarget
                                    className="list"
                                    draggedItemClassName="dragged"
                                >
                                    {statuses &&
                                        statuses?.map((status, index) => (
                                            <SortableItem
                                                key={'stortable_item' + index}
                                                className={status.sequence !== 0 ? 'dragged' : ''}
                                            >
                                                <div>
                                                    <div className="grid grid-cols-5 gap-5 secondary-fonts text-md mt-3">
                                                        <div className="flex gap-2 col-span-2 items-center">
                                                            {status.sequence !== 0 ? (
                                                                <div className="cursor-grab">
                                                                    <SortableKnob>
                                                                        <div>
                                                                            <Bars4Icon className="w-5" />
                                                                        </div>
                                                                    </SortableKnob>
                                                                </div>
                                                            ) : (
                                                                <div className="mx-3"></div>
                                                            )}
                                                            <div className="truncate">{status?.label}</div>
                                                        </div>

                                                        <div className="flex items-center">
                                                            <FormateDateTime
                                                                dateTime={status.created_at}
                                                                insertedFormat="YYYY-MM-DDTHH:mm:ss.SSSS"
                                                            />
                                                        </div>
                                                        <div className="flex items-center">
                                                            <FormateDateTime
                                                                dateTime={status.updated_at}
                                                                insertedFormat="YYYY-MM-DDTHH:mm:ss.SSSS"
                                                            />
                                                        </div>
                                                        <div className="flex gap-2 items-center">
                                                            {status?.can_delete && (
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        if (!hasPermissions('Tasks', ['admin'])) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        handleEdit(status);
                                                                    }}
                                                                >
                                                                    <BgEditIcon />
                                                                </div>
                                                            )}

                                                            {status?.can_delete && (
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        if (!hasPermissions('Tasks', ['admin'])) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        archiveStatus(status.job_status_id);
                                                                    }}
                                                                >
                                                                    <BgDeleteIcon />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="team-line team-streched-line mt-3"></div>
                                                </div>
                                            </SortableItem>
                                        ))}
                                </SortableList>
                            </div>
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
