/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AnnDetailsJumbo } from './AnnDetailsJumbo';
import { AnnDetailsCards } from './AnnDetailsCards';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllNewsThunk, getSingleNewsThunk } from 'store/workspace/workspaceNews';
import { BUSINESS_ID } from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';

export const AnnDetails = ({ toggleEditAnn, annUpdated }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);

    const fetchLatest = () => {
        dispatch(getAllNewsThunk({ page: 1, page_size: 50, business_id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        const payload = {
            business_id,
            news_id: id
        };
        dispatch(setLoader(true));
        dispatch(getSingleNewsThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                fetchLatest();
            });
    }, [id, annUpdated]);
    return (
        <div>
            <AnnDetailsJumbo path />
            <AnnDetailsCards toggleEditAnn={toggleEditAnn} />
        </div>
    );
};
