/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { useEffect, useState } from 'react';
import { EyeIcon } from '../../../../../utills/svgs/EyeIcon';
import { BgPencilIcon } from '../../../../../utills/svgs/BgPencilIcon';
import { BgDeleteIcon } from '../../../../../utills/svgs/BgDeleteIcon';
import { useDispatch } from 'react-redux';
import { formateDate } from '../../../../../utills/moment';
import { toastHandler } from 'responseHanlder';
import { formHandler, setLoader, setSideLoader } from 'store/global/globalReducer';
import { archiveBusinessFormThunk, fetchFormsThunk, getSingleFormThunk } from 'store/form';
import { BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import ConfirmationModal from 'utills/confirmationModal';
import { SearchInput } from 'components/molecules/SearchInput';
import useRoleGuard from 'Hooks/useRoleGuard';
import SkeletonTable from 'components/atoms/SkeletonTable';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
const DraftForms = ({ toggleFormsUpdated, togglePreviewForm, handleUpdateForm, updateTable }) => {
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [form_id, setFormId] = useState('');
    const [service_id, setServiceId] = useState('');

    const business_id = localStorage.getItem(BUSINESS_ID);

    const [filters, setFilters] = useState({
        search_term: ''
    });

    const [activeTab, setActiveTab] = useState('all');

    const [hasMoreContent, setHasMoreContent] = useState({
        all: true,
        filtered: false
    });

    const [allPage, setAllPage] = useState(1);
    const [filteredPage, setFilteredPage] = useState(1);

    const [allPageData, setAllPageData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    const [filteredForms, setFilteredForms] = useState([]);

    useEffect(() => {
        getAllTasks('all');
    }, [allPage]);

    useEffect(() => {
        const hasValue = Object.values(filters).some((value) => value !== '');

        if (hasValue) {
            getAllTasks('filtered');
        } else {
            setActiveTab('all');
        }
    }, [filteredPage, filters]);

    const handlePageChange = () => {
        if (activeTab === 'all') {
            setAllPage(allPage + 1);
        } else if (activeTab === 'filtered') {
            setFilteredPage(filteredPage + 1);
        }
    };

    const updateFilters = (newFilters) => {
        let filters = newFilters;
        setFilters(filters);
        setFilteredPage(1);
        setFilteredData([]);
        setActiveTab('filtered');
    };

    const getAllTasks = async (type, hasReset = false) => {
        let page;
        let dataSetter;
        let pageSetter;
        let stateData;

        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setAllPageData;
                stateData = allPageData;
                pageSetter = 'all';
                break;
            case 'filtered':
                page = filteredPage;
                dataSetter = setFilteredData;
                stateData = filteredData;
                pageSetter = 'filtered';
                break;
            default:
                return;
        }

        const payload = {
            business_id,
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            service_id: '',
            form_status: 'draft',
            filters: {
                ...filters
            }
        };

        setHasMoreContent({ ...hasMoreContent, [pageSetter]: true });

        try {
            const response = await dispatch(fetchFormsThunk(payload));

            if (response.payload) {
                const cloneData = [...(!hasReset ? stateData || [] : []), ...response.payload.forms?.rows];
                dataSetter(cloneData);
                setHasMoreContent({
                    ...hasMoreContent,
                    [pageSetter]: cloneData.length === response.payload?.count ? false : true
                });
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const reloadTaskPage = () => {
        setAllPage(1);
        setActiveTab('all');
        getAllTasks('all', true);
        setAllPageData(null);
    };

    useEffect(() => {
        reloadTaskPage();
    }, [updateTable]);
    useEffect(() => {
        if (activeTab === 'filtered') {
            setFilteredForms(filteredData || []);
        } else {
            setFilteredForms(allPageData || []);
        }
    }, [allPageData, filteredData, activeTab]);

    const handleDelete = () => {
        dispatch(setSideLoader(true));
        dispatch(archiveBusinessFormThunk({ form_id, service_id }))
            .then((response) => {
                if (response.payload) {
                    reloadTaskPage();
                    toggleFormsUpdated();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
        setIsConfirmationOpen(false);
    };

    const archiveBusinessForm = (form_id, service_id) => {
        if (hasPermissions('Forms', ['admin'])) {
            setIsConfirmationOpen(true);
            setFormId(form_id);
            setServiceId(service_id);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const viewBusinessForm = (form) => {
        if (form?.form_id) {
            dispatch(setLoader(true));
            dispatch(getSingleFormThunk({ form_id: form?.form_id }))
                .then((response) => {
                    if (response.payload) {
                        dispatch(formHandler(response.payload));
                        togglePreviewForm();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };
    const updateFormHandle = (form) => {
        if (hasPermissions('Forms', ['admin'])) {
            if (form) {
                if (form?.form_id) {
                    dispatch(setLoader(true));
                    dispatch(getSingleFormThunk({ form_id: form?.form_id }))
                        .then((response) => {
                            if (response.payload) {
                                dispatch(formHandler(response.payload));
                                handleUpdateForm();
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {
                            dispatch(setLoader(false));
                        });
                }
            }
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    return (
        <div className="prebuilt-forms text-black">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />

            <SearchInput
                atChange={(e) =>
                    updateFilters({
                        search_term: e.target.value
                    })
                }
                placeholder="Search"
                inputBorder="1px solid #979797"
                width="350px"
            />

            <div>
                <InfiniteScrollComp
                    height={550}
                    next={handlePageChange}
                    dataLength={
                        (filteredForms?.length === 0 ? (hasMoreContent?.[activeTab] ? 1 : 0) : filteredForms?.length) ||
                        0
                    }
                    hasMore={filteredForms?.length !== 0 ? hasMoreContent?.[activeTab] : false}
                    loader={<SkeletonTable columns={4} />}
                >
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr className="divide-x divide-gray-200">
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-4 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                                >
                                                    Form Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-4 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Created At
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-4 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Last Updated
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-4 text-left text-md font-semibold flex justify-center items-center text-gray-900 sm:pr-0"
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {filteredForms?.map((person) => (
                                                <tr key={person?.form_name} className="divide-x divide-gray-200">
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-md font-medium text-gray-900 sm:pl-0">
                                                        {person?.form_name}
                                                    </td>
                                                    <td className="whitespace-nowrap p-4 text-md text-gray-500">
                                                        {formateDate(person?.created_at)}
                                                    </td>
                                                    <td className="whitespace-nowrap p-4 text-md text-gray-500">
                                                        {formateDate(person?.updated_at)}
                                                    </td>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-4 text-md text-gray-500 sm:pr-0">
                                                        {' '}
                                                        <div className="flex justify-center items-center gap-1">
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => viewBusinessForm(person)}
                                                            >
                                                                <EyeIcon />
                                                            </div>
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => updateFormHandle(person)}
                                                            >
                                                                <BgPencilIcon />
                                                            </div>

                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() =>
                                                                    archiveBusinessForm(
                                                                        person?.form_id,
                                                                        person?.service_id
                                                                    )
                                                                }
                                                            >
                                                                <BgDeleteIcon />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </InfiniteScrollComp>
            </div>
        </div>
    );
};
export default DraftForms;
