import { PlusCircleIcon } from '@heroicons/react/24/outline';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { cn } from 'utills/tailwindUtil';
import GenerateFileIcon from '../AttachmentComponents/FileIcons/GenerateFileIcon';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

export const FileDropZone = ({
    multiple = true,
    onFilesDrop,
    children,
    card_h,
    card_w,
    disabled = false,
    accept = [
        'image/jpeg',
        'image/png',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [_files, setFiles] = useState([]);

    const isValidFile = (file) => {
        return [...accept, 'msg', 'vsd', 'qif'].includes(
            file?.type === '' ? file.name.split('.').pop().toLowerCase() : file?.type
        );
    };

    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            if (disabled) {
                return false;
            }
            // Custom validation: Check if the files are valid
            const validFiles = acceptedFiles.filter(isValidFile);
            const invalidFiles = acceptedFiles.filter((file) => !isValidFile(file));

            if (validFiles.length > 0) {
                setFiles((prevFiles) => [...prevFiles, ...validFiles]);
                if (onFilesDrop) {
                    onFilesDrop(validFiles);
                }
            }

            if (invalidFiles.length > 0) {
                invalidFiles.forEach((file) => {
                    toastHandler(`File "${file.name}" is not an allowed type.`, TOAST_TYPE_ERROR);
                });
            }

            if (rejectedFiles.length > 0) {
                rejectedFiles.forEach(({ file, errors }) => {
                    errors.forEach((error) => {
                        toastHandler(`File "${file.name}": ${error.message}`, TOAST_TYPE_ERROR);
                    });
                });
            }

            setIsDragging(false);
        },
        [onFilesDrop, toastHandler]
    );

    const onDragEnter = () => setIsDragging(true);
    const onDragLeave = () => setIsDragging(false);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDragEnter,
        onDragLeave,
        multiple,
        noClick: true,
        noKeyboard: true
    });

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (isDragging) {
            const timeout = setTimeout(() => setAnimate(true), 100);
            return () => clearTimeout(timeout);
        } else {
            setAnimate(false);
        }
    }, [isDragging]);

    return (
        <div
            {...getRootProps()}
            className={`relative pb-5 file-drop-zone-container rounded-lg transition-all duration-300 ease-in-out  
    ${isDragging ? 'border-blue-500 bg-blue-50' : ''}`}
            style={{ height: '100%' }}
        >
            <input {...getInputProps()} />

            {isDragging && !disabled && (
                <div
                    className={cn(
                        'absolute inset-0 flex items-center justify-center bg-[#ffffff] bg-opacity-75 !z-[101]  text-white text-lg font-semibold',
                        animate ? 'drop-card-animate' : 'drop-card-wrap'
                    )}
                >
                    <div
                        className={`drop-card-one  ${card_h ? card_h : 'h-[200px]'} ${
                            card_w ? card_w : 'w-[120px]'
                        }   rounded-xl shadow-xl bg-thirdbg flex flex-col gap-1 justify-center items-center`}
                    >
                        <div className="bg-white w-[45px] flex justify-center items-center h-[45px] rounded-xl ">
                            <GenerateFileIcon sx={'w-[30px]'} url={'file.jpg'} />
                        </div>
                        <div className="bg-white w-[45px] flex justify-center items-center h-[45px] rounded-xl">
                            <GenerateFileIcon url={'file.png'} sx={'w-[30px]'} />
                        </div>

                        <div className="bg-white w-[45px] flex justify-center items-center h-[45px] rounded-xl">
                            <GenerateFileIcon url={'file.msg'} sx={'w-[30px]'} />
                        </div>
                    </div>
                    <div
                        className={`drop-card-two  ${card_h ? card_h : 'h-[200px]'} ${
                            card_w ? card_w : 'w-[120px]'
                        }  rounded-xl shadow-xl bg-thirdbg flex flex-col gap-2 justify-center items-center`}
                    >
                        <PlusCircleIcon className="w-16 text-white " />
                        <p className="!text-xs !font-[400] text-center">Drop Files here</p>
                    </div>
                    <div
                        className={`drop-card-three ${card_h ? card_h : 'h-[200px]'} ${
                            card_w ? card_w : 'w-[120px]'
                        }  rounded-xl shadow-xl bg-thirdbg flex flex-col gap-1 justify-center items-center`}
                    >
                        <div className="bg-white w-[45px] flex justify-center items-center h-[45px] rounded-xl ">
                            <GenerateFileIcon sx={'w-[30px]'} url={'file.pdf'} />
                        </div>
                        <div className="bg-white w-[45px] flex justify-center items-center h-[45px] rounded-xl">
                            <GenerateFileIcon url={'file.docx'} sx={'w-[30px]'} />
                        </div>

                        <div className="bg-white w-[45px] flex justify-center items-center h-[45px] rounded-xl">
                            <GenerateFileIcon url={'file.csv'} sx={'w-[30px]'} />
                        </div>
                    </div>
                </div>
            )}

            <div>{children}</div>
        </div>
    );
};
