/* eslint-disable no-unused-vars */
import { ACCESS_TOKEN } from './globalVars';
import { io } from 'socket.io-client';
import { setGlobalSocketConnection } from 'store/global/globalReducer';
const url = 'http://localhost:5000';

export const handleConnection = (events, category) => {
    const access_token = localStorage.getItem(ACCESS_TOKEN);
    const socket = io(url, {
        query: {
            access_token: access_token,
            category,
            event_ids: events
        }
    });
};

export const connectSocket = (category, requestedEvents, userType, userEmail) => {
    const access_token = localStorage.getItem('access_token');
    const eventsIds = requestedEvents?.length > 0 && requestedEvents.join('!'); //condionally
    const queryObject = {
        category: category,
        event_ids: eventsIds ? eventsIds : '', //condionally
        access_token: '',
        ...(userType ? { user_type: userType } : {}), //condionally
        ...(userEmail ? { email: userEmail } : {}) //condionally
    };
    return io(url, { query: queryObject });
};

export const disconnectSocket = (socket) => {
    socket?.disconnect();
};

export function startHeartbeatInterval(dispatch, connection, dispatchFun = setGlobalSocketConnection) {
    if (connection) {
        const heartbeatInterval = setInterval(() => {
            if (connection && connection.id !== undefined && connection.id !== null) {
                connection.emit('heartbeat', '');
                console.warn('sending heart beat', connection.id);
            } else {
                clearInterval(heartbeatInterval);
                console.info('No connection ID. Stopping heartbeat.');
            }
        }, 3000);
        dispatch(dispatchFun(heartbeatInterval));
    }
}

export function stopHeartbeatInterval(dispatch, intervalId, dispatchFun = setGlobalSocketConnection) {
    clearInterval(intervalId);
    console.info('end', intervalId);
    dispatch(dispatchFun(null));
}
