import React, { useMemo } from 'react';
import SearchAddSelectCombobox from 'components/atoms/SearchAddSelectCombobox/SearchAddSelectCombobox';
// import { useGetGroups } from 'Hooks/useGetGroups';
import { AddEditJobGroup } from 'pages/settings/groups/jobGroups/AddEditJobsGroup';

const JobsGroup = ({ setFilters, filters, groups, handleGetGroups }) => {
    // const { handleGetGroups, data: groups } = useGetGroups();

    // useEffect(() => {
    //     handleGetGroups('jobs');
    // }, []);
    // Memoize selectedItems to avoid unnecessary re-renders
    const selectedItems = useMemo(() => {
        if (!filters?.group_id || !groups) return [];
        return groups.filter((obj) => obj.group_id === filters?.group_id);
    }, [filters?.group_id, groups]);

    return (
        <div>
            <SearchAddSelectCombobox
                options={groups || []}
                mainSx="!w-full !mx-0 mt-2 !p-0"
                boxSx="!min-h-[2.5rem]"
                boxChildSx="!flex-nowrap overflow-auto"
                targetProperty="title"
                addStr="Group"
                onChange={(arr) => {
                    if (arr.length > 0) {
                        const obj = arr[0];
                        setFilters(obj);
                    } else {
                        setFilters('');
                    }
                }}
                isMultiple={false}
                selectedItems={selectedItems} // Pass memoized selectedItems
                labelInside="Filter by Group"
                placeholder="Filter by Group"
                childSliders={{ addItem: AddEditJobGroup }}
                extraChildProps={{
                    addSingle: true,
                    handleComplete: () => {
                        handleGetGroups();
                    }
                }}
            />
        </div>
    );
};

export default JobsGroup;
