/* eslint-disable react-hooks/exhaustive-deps */
import { DatePick } from 'components/atoms/inputs/DatePick';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { SearchInput } from './SearchInput';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LogsSkeleton } from 'components/workplace-content/tasks-pages/task-details/skeletons/LogsSkeleton';
import { generateId } from 'utills/uid';
import './css/molecules.css';
import cloneDeep from 'lodash/cloneDeep';
import { NoDataFound } from 'components/common/NoDataFound';
import { Button } from '../atoms/buttons/Button';
import { DownloadIcon } from './../../utills/svgs/DownloadIcon';
import { SmallLoader } from 'components/common/SmallLoader';
import moment from 'moment';
import GlobalPDFViewer from './GlobalPDFViewer';
import { generateRandomProfileLink, handleDownload } from 'utills/globalVars';
import pdfIcon from 'files/pdfIcon.svg';
import { FilesIcon } from 'utills/svgs/FilesIcon';
import { cn } from 'utills/tailwindUtil';

export const DefaultRecentActivity = ({
    logs,
    count,
    page,
    fetchLogs,
    pageSize,
    handleMore,
    term,
    setTerm,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    downloadActivity,
    title,
    height,
    filterPage,
    filterCount,
    setfilteredLogs,
    setFilterPage,
    preventSx,
    hideFilters = false
}) => {
    const { sideLoader, pdfLoader } = useSelector((state) => state?.global);

    const [searchTerm, setSearchTerm] = useState('');
    const theLogs = cloneDeep(logs);
    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [preventLoad, setPreventLoad] = useState(true);

    const datePickerProps = {
        right: CalendarSmallIco
    };

    const handleSearch = (term) => {
        setTerm(term);
        setSearchTerm(term);
    };

    const handleFromChange = (date) => {
        setDateFrom(date);
    };

    const handeDateTo = (date) => {
        setDateTo(date);
    };

    useEffect(() => {
        if (!preventLoad) {
            fetchLogs();
        }
    }, [page, filterPage, dateFrom, term, dateTo, preventLoad]);

    useEffect(() => {
        if (dateFrom || term || dateTo) {
            setFilterPage(1);
            setfilteredLogs([]);
        }
    }, [dateFrom, term, dateTo]);

    const exportButtonProps = {
        leftIcon: DownloadIcon,
        loader: SmallLoader
    };

    function HandleText({ logText }) {
        const processedText = logText.replace(/<TEXT>(.*?)<\/TEXT>/g, '<span style="color: #b695f8">$1</span>');

        return <div className="break-all" dangerouslySetInnerHTML={{ __html: processedText }} />;
    }
    return (
        <>
            <div className="py-5">
                {open && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpen} open={open} /> : ''}{' '}
                <div className="flex justify-between items-center mx-5 flex-wrap gap-3">
                    <div> {title && <div className="card-heading text-xl ">{title}</div>}</div>
                    <Button
                        disabled={pdfLoader ? true : false}
                        title="Export Data"
                        classes="bg-[#b695f8] rounded-lg px-4 h-[2.5rem] text-white secondary-fonts flex items-center gap-3"
                        iconSet={exportButtonProps}
                        isloading={pdfLoader ? true : false}
                        atClick={() => {
                            if (downloadActivity) {
                                downloadActivity();
                            }
                        }}
                    />
                </div>
                <div className="default-logs-actions mx-5 mt-3">
                    <div className="flex justify-center items-center w-full">
                        {!hideFilters && (
                            <div className="mt-3 flex  items-center gap-5 w-full justify-center flex-col-reverse">
                                <SearchInput
                                    atChange={(e) => {
                                        handleSearch(e.target.value);
                                    }}
                                    value={searchTerm}
                                    placeholder="Search"
                                    inputBorder="1px solid #DCDCDC"
                                />

                                <div className="flex gap-3 items-center justify-between w-full">
                                    {' '}
                                    <DatePick
                                        iconSet={datePickerProps}
                                        onDateChange={(date) => handleFromChange(date)}
                                        placeholder="From"
                                        bg="transparent"
                                        sx="!rounded-xl text-gray-900 customDatePicker w-full"
                                        maxDate={dateTo ? dateTo : moment().format('DD-MM-YYYY')}
                                    />
                                    <DatePick
                                        iconSet={datePickerProps}
                                        onDateChange={(date) => handeDateTo(date)}
                                        placeholder="To"
                                        bg="transparent"
                                        sx="!rounded-xl text-gray-900 customDatePicker w-full"
                                        minDate={dateFrom ? dateFrom : null}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div id="scrollableDiv" className="ml-1 mt-3">
                    {preventLoad ? (
                        <div className={cn('relative min-h-[250px] h-full w-full', preventSx)}>
                            <div
                                className="absolute"
                                style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            >
                                <button
                                    className="bg-third px-3 py-2 text-sm text-white font-medium rounded-lg shadow-xl ring-1 ring-outset ring-gray-500"
                                    onClick={() => setPreventLoad(false)}
                                >
                                    Click to View
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {page < 2 && filterPage < 2 && sideLoader ? (
                                <LogsSkeleton />
                            ) : (
                                <InfiniteScroll
                                    dataLength={logs?.length}
                                    next={handleMore}
                                    className="scroler-component"
                                    height={height ? height : 350}
                                    hasMore={
                                        dateFrom || dateTo || term
                                            ? filterPage < Math.ceil(filterCount / pageSize)
                                                ? true
                                                : false
                                            : page < Math.ceil(count / pageSize)
                                            ? true
                                            : false
                                    }
                                    loader={<LogsSkeleton />}
                                >
                                    {theLogs && theLogs.length < 1 && !sideLoader ? (
                                        <div>
                                            <NoDataFound message="No Activity Found!" height="30vh" />
                                        </div>
                                    ) : (
                                        <div className="customer-log-container">
                                            {theLogs &&
                                                theLogs?.map((log, index) => (
                                                    <div
                                                        key={generateId()}
                                                        className={`relative ${index > 0 && 'pt-5'} log ml-5`}
                                                    >
                                                        {log?.user?.image ? (
                                                            <div className="activity-img-wrapper">
                                                                <img
                                                                    src={log.user.image}
                                                                    alt="src"
                                                                    className="activity-img"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="activity-img-wrapper">
                                                                <img
                                                                    src={generateRandomProfileLink(
                                                                        log?.user?.first_name +
                                                                            ' ' +
                                                                            log?.user?.last_name
                                                                    )}
                                                                    alt="src"
                                                                    className="activity-img"
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="activity-details pl-9 py-3">
                                                            <div className="activity-text">
                                                                <span className="pr-1 primary-fonts">
                                                                    {log?.user?.first_name}
                                                                </span>

                                                                <HandleText logText={log.text} />
                                                            </div>
                                                            {log?.files && (
                                                                <div className="flex items-center w-fit rounded-md p-1 border my-2">
                                                                    {log?.files?.map((item, index) => (
                                                                        <div
                                                                            className="bg-thirdbg w-fit rounded-lg px-1 cursor-pointer"
                                                                            key={'activity_card_index' + index}
                                                                            onClick={() => {
                                                                                if (
                                                                                    item?.url
                                                                                        ?.toLowerCase()
                                                                                        ?.endsWith('.pdf')
                                                                                ) {
                                                                                    setPdfUrl(item?.url);
                                                                                    setOpen(true);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="flex items-center px-3 py-2 gap-2">
                                                                                {item?.url
                                                                                    ?.toLowerCase()
                                                                                    ?.endsWith('.png') ||
                                                                                item?.url
                                                                                    ?.toLowerCase()
                                                                                    ?.endsWith('.jpg') ||
                                                                                item?.url
                                                                                    ?.toLowerCase()
                                                                                    ?.endsWith('.pdf') ? (
                                                                                    <img
                                                                                        src={
                                                                                            item?.url
                                                                                                ?.toLowerCase()
                                                                                                ?.endsWith('.pdf')
                                                                                                ? pdfIcon
                                                                                                : item.url
                                                                                        }
                                                                                        alt="alt"
                                                                                        className="thumbnail-image cursor-pointer !w-8 !h-8 rounded-md"
                                                                                    />
                                                                                ) : (
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            handleDownload(item)
                                                                                        }
                                                                                    >
                                                                                        <FilesIcon
                                                                                            className={
                                                                                                'stroke-white !w-8 !h-8 cursor-pointer'
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}

                                                                                <div className="flex flex-col items-start">
                                                                                    <h3 className="text-sm text-white font-medium">
                                                                                        {item.name}
                                                                                    </h3>
                                                                                    <h3 className="text-xs text-white font-normal">
                                                                                        {item.size}
                                                                                    </h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}

                                                            <div className="activity-date text-xs mt-1">
                                                                {log.created_at}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </InfiniteScroll>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
