import React from 'react';
import { generateId } from 'utills/uid';
import './css/molecules.css';
import user from '../../files/user.png';
import { generateRandomProfileLink } from 'utills/globalVars';
export const Badge = ({ dataList, remain, isStatus, sx }) => {
    return (
        <div>
            {' '}
            <div>
                {' '}
                <div className="flex">
                    {dataList?.slice(0, 3).map((emp, index) => (
                        <div
                            key={'dataList' + index}
                            style={{
                                marginLeft: index > 0 && '-15px',
                                zIndex: dataList?.slice(0, 4).length - index
                            }}
                            className={'signees-images-container relative ' + sx}
                        >
                            {isStatus && remain && index === dataList.slice(0, 3).length - 1 && remain > 0 ? (
                                <div className="remain-number-badge shadow-custom">+{remain} </div>
                            ) : (
                                ''
                            )}
                            <img
                                key={generateId()}
                                src={
                                    emp.image ??
                                    generateRandomProfileLink(emp?.first_name + ' ' + emp?.last_name) ??
                                    user
                                }
                                alt="taskcommentpicture"
                                className={`signee-image ${index === 2 && isStatus ? '' : ''}  ${sx}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
