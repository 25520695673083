import { Bars3Icon, PlusIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/atoms/buttons/Button';
import DisclosureBox from 'components/atoms/Disclosure/Disclosure';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ArchiveIcon from 'utills/svgs/ArchiveIcon';
import DeleteItemIcon from 'utills/svgs/DeleteItemIcon';
import DraftIcon from 'utills/svgs/DraftIcon';
import InboxIcon from 'utills/svgs/InboxIcon';
import OutboxIcon from 'utills/svgs/OutboxIcon';
import SentIcon from 'utills/svgs/SentIcon';
import SnoozeIcon from 'utills/svgs/SnoozeIcon';
import SpamEmails from 'utills/svgs/SpamEmails';
import { cn } from 'utills/tailwindUtil';

const allAccountsOptions = [
    {
        icon: <InboxIcon />,
        title: 'Inbox',
        value: 'inbox'
    },
    {
        icon: <DraftIcon />,
        title: 'Drafts',
        value: 'draft'
    },
    {
        icon: <OutboxIcon />,
        title: 'Outbox',
        value: 'outbox'
    },
    {
        icon: <ArchiveIcon />,
        title: 'Archive',
        value: 'archive'
    },
    {
        icon: <SentIcon />,
        title: 'Sent',
        value: 'sent'
    },
    {
        icon: <SnoozeIcon />,
        title: 'Snoozed',
        value: 'snoozed'
    },
    {
        icon: <DeleteItemIcon />,
        title: 'Deleted Items',
        value: 'deleteItems'
    },
    {
        icon: <SpamEmails />,
        title: 'Spam Emails',
        value: 'spamEmails'
    }
];

const EmailManagerInboxMenu = ({ wrapInSlider, open, setOpen }) => {
    const [favList, _setFavList] = useState([]);
    const [collapsMenu, setCollapsMenu] = useState(false);
    const { isSidebarOpen } = useSelector((state) => state.global);

    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        return () => {
            if (setOpen) {
                setOpen(false);
            }
        };
    }, []);

    const Childrens = () => {
        return (
            <div className={cn('py-2 px-2', collapsMenu ? 'w-auto' : 'min-w-[300px]')}>
                <div className={cn('flex items-center w-full', collapsMenu ? 'justify-center' : 'justify-between')}>
                    {!wrapInSlider ? (
                        <Button
                            iconSet={{ leftIconHTML: <Bars3Icon className="w-7 h-7" /> }}
                            groupSx={'contents'}
                            classes={'hover:!shadow-none'}
                            atClick={() => setCollapsMenu(!collapsMenu)}
                        />
                    ) : (
                        <div />
                    )}
                    {!collapsMenu && (
                        <Button
                            iconSet={{ leftIconHTML: <PlusIcon className="w-5 h-5 !text-white" /> }}
                            classes={'bg-primary !rounded-full p-2'}
                        />
                    )}
                </div>
                <nav className="flex flex-1 flex-col mt-5">
                    <ul role="list" className="flex flex-1 flex-col gap-y-5">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1 divide-y">
                                <li>
                                    <DisclosureBox
                                        defaultOpen
                                        name={collapsMenu ? 'Fav' : 'Favourites'}
                                        sx={{
                                            name: collapsMenu ? '!text-xs !gap-x-0 justify-center !px-0' : '',
                                            icon: collapsMenu ? 'ml-0 hidden !p-0' : '',
                                            panel: collapsMenu ? '!px-0' : ''
                                        }}
                                    >
                                        {favList.map((listObj, i) => (
                                            <div
                                                className="flex w-full items-center justify-between"
                                                key={'listObj.name' + i}
                                            >
                                                {/* <div
                                                className={classNames(
                                                    subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                    'block rounded-md py-2 pr-2 pl-9 text-sm/6 text-gray-700'
                                                )}
                                            >
                                                {subItem.name}
                                            </div> */}
                                            </div>
                                        ))}
                                    </DisclosureBox>
                                </li>
                                <li>
                                    <DisclosureBox
                                        defaultOpen
                                        name={collapsMenu ? 'All' : 'All Accounts'}
                                        sx={{
                                            name: collapsMenu ? '!text-xs !gap-x-0 justify-center !px-0' : '',
                                            icon: collapsMenu ? 'ml-0 hidden !p-0' : '',
                                            panel: collapsMenu ? '!px-0' : ''
                                        }}
                                    >
                                        {allAccountsOptions.map((listObj, i) => (
                                            <li
                                                className={cn(
                                                    'flex w-full items-center cursor-pointer',
                                                    collapsMenu ? 'justify-center' : 'justify-between'
                                                )}
                                                key={'listObj.name' + i}
                                            >
                                                <div
                                                    className={cn(
                                                        listObj?.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                        'block w-full rounded-md py-2.5  text-sm/6 text-gray-700 flex items-center justify-between',
                                                        !collapsMenu ? 'pl-3 pr-2' : '!px-2'
                                                    )}
                                                >
                                                    <div
                                                        className={cn(
                                                            'flex-1 flex w-full items-center gap-2',
                                                            collapsMenu ? 'justify-center' : 'justify-start'
                                                        )}
                                                    >
                                                        <span className="">{listObj.icon}</span>
                                                        {!collapsMenu && (
                                                            <span className="text-md text-primary font-poppins">
                                                                {listObj.title}
                                                            </span>
                                                        )}
                                                    </div>
                                                    {!collapsMenu && <div>(124)</div>}
                                                </div>
                                            </li>
                                        ))}
                                    </DisclosureBox>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    };
    const left = isSidebarOpen ? 'md:left-[var(--size-25)]' : 'md:left-[var(--size-13)]';
    return (
        <>
            {wrapInSlider ? (
                <div className="">
                    {open && (
                        <div
                            className={cn(
                                'bg-gray-800 bg-opacity-50 transition-opacity flex justify-start items-center !z-[1] fixed top-0 left-0 right-0 bottom-0 w-full h-[100vh] pt-[80px]',
                                left
                            )}
                        >
                            <div
                                ref={dropdownRef}
                                className="bg-white w-[300px] min-h-[70%] rounded-tr-xl rounded-br-xl    shadow-xl px-2 py-3"
                            >
                                <Childrens />
                            </div>
                            <div className={``}></div>
                        </div>
                    )}
                </div>
            ) : (
                <Childrens />
            )}
        </>
    );
};

export default EmailManagerInboxMenu;
