import { DefaultDialog } from 'components/molecules/DefaultDialog';
import React from 'react';
import { SideTimes } from 'utills/svgs/SideTimes';
import { cn } from 'utills/tailwindUtil';

const DialogModalComponent = ({ open, setOpen, sx, icon, title, children, hideCross }) => {
    return (
        <DefaultDialog width={cn('rounded-xl !p-2', sx)} open={open || true} setOpen={setOpen}>
            <div className="flex justify-end items-center">
                {!hideCross && (
                    <span onClick={setOpen} className="cursor-pointer">
                        <SideTimes />
                    </span>
                )}
            </div>
            <div className="px-5">
                <div className="flex justify-center items-center mt-5">{icon}</div>
                <h3 className="table-title text-gray-900 text-center font-poppins !font-bold my-5">{title}</h3>
                {children}
            </div>
        </DefaultDialog>
    );
};

export default DialogModalComponent;
