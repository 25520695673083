/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { SearchInput } from 'components/molecules/SearchInput';
import { Button } from 'components/atoms/buttons/Button';
import { CopyIcon2 } from 'utills/svgs/CopyIcon2';
import { DownloadIcon2 } from 'utills/svgs/DownloadIcon2';
import { DownloadZipIcon } from 'utills/svgs/DownloadZipIcon';
import { CollateFilesIcon } from 'utills/svgs/CollateFilesIcon';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import { AddFolderIcon } from 'utills/svgs/AddFolderIcon';
import { AnimatePresence, motion } from 'framer-motion';
import JobPortalDocumentsTable from './JobPortalDocumentsTable';
import Breadcrumb from 'components/atoms/Breadcrumb';
import EntityCreateUpdate from './EntityCreateUpdate';
import MoveOrCopy from './MoveOrCopy';
import { DocumentMinusIcon } from '@heroicons/react/24/outline';
import { RenameIcon } from 'utills/svgs/RenameIcon';
import { MoveIcon } from 'utills/svgs/MoveIcon';
import { SignIcon } from 'utills/svgs/SignIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDocumentDriveListThunk,
    setActivePath,
    setFilters,
    setJobId
} from 'store/custom-reducers/JobManagerDocumentReducer';
import { useNavigate, useParams } from 'react-router-dom';
import { all_files_types, BUSINESS_ID, handleDownload, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { JobManagerDeleteItemsThunk, uploadFilesToDocManagerThunk } from 'store/JobManager';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { useDownloadZip } from 'Hooks/Files/useDownloadZip';
import { endpoint } from 'apiEndpoints';
import CollateDocuments from './CollateDocuments';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import useRoleGuard from 'Hooks/useRoleGuard';

const JobPortalDocuments = ({ disabled = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [createFolder, setCreateFolder] = useState(false);
    const [editEntity, setEditEntity] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const { handleDownloadZip } = useDownloadZip();
    const fileInputRef = useRef(null);

    const { hasPermissions } = useRoleGuard();

    const { activePath, data, filters, globalLoading, jobId } = useSelector((state) => state.jobManagerDocReducer);

    useEffect(() => {
        if ((!data || id !== jobId) && hasPermissions('Job', ['read', 'write', 'admin'])) {
            getData();
        }
    }, []);

    const getData = (parentObj = null, parent_id_Only = null) => {
        if (parentObj) {
            if (parentObj?.job_drive_id !== '') {
                const splitArray = activePath.slice(
                    0,
                    activePath.findIndex((obj) => obj.job_drive_id === parentObj?.job_drive_id) + 1
                );
                dispatch(setActivePath(splitArray?.length > 0 ? splitArray : [...activePath, parentObj]));
            } else {
                dispatch(setActivePath([]));
            }
        }

        const jsonData = {
            job_id: id,
            business_id,
            ...(parent_id_Only
                ? { parent_id: parent_id_Only }
                : parentObj?.job_drive_id
                ? { parent_id: parentObj?.job_drive_id }
                : {})
        };
        setSelectedIds([]);
        dispatch(setJobId(id));
        dispatch(getDocumentDriveListThunk(jsonData));
    };

    const actionButtons = [
        {
            icon: <RenameIcon className={'w-6'} />,
            title: 'Rename',
            allowedTypes: ['file', 'folder'],
            minimumSelect: 1,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                const file = selectedIds[0];
                setEditEntity({
                    ...file,
                    name: file.name,
                    parent_id: activePath?.length > 0 ? activePath?.at(-1) : ''
                });
                setCreateFolder(true);
            }
        },
        {
            icon: <DocumentMinusIcon className={'w-6'} />,
            title: 'Cut and Move',
            allowedTypes: ['pdf', 'file', 'folder'],
            minimumSelect: 1,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleBtnAction({ title: 'Cut and Move', action: 'cut' });
            }
        },
        {
            icon: <CopyIcon2 className={'w-6'} />,
            title: 'Copy and Paste',
            allowedTypes: ['pdf', 'file', 'folder'],
            minimumSelect: 1,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleBtnAction({ title: 'Copy and Paste', action: 'copy' });
            }
        },
        {
            icon: <DownloadIcon2 className={'w-6'} />,
            title: 'Download',
            allowedTypes: ['pdf', 'file'],
            minimumSelect: 1,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'read', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDownlaodItems(selectedIds);
            }
        },
        {
            icon: <DownloadZipIcon className={'w-6'} />,
            title: 'Export Zip Files',
            allowedTypes: ['pdf', 'file', 'folder'],
            minimumSelect: 2,
            disabled: false,
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'read', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDownlaodItems(selectedIds);
            }
        },
        {
            icon: <CollateFilesIcon className={'w-6'} />,
            title: 'Collate Files',
            allowedTypes: ['pdf'],
            minimumSelect: 2,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleBtnAction({ title: 'Collate Documents', action: 'collateDocs' });
            }
        },
        {
            title: 'Request Signature',
            icon: <SignIcon />,
            allowedTypes: ['pdf'],
            minimumSelect: 1,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                navigate(`/job-manager/request-signature/${id}`, { state: selectedIds });
            }
        },
        {
            icon: <MoveIcon />,
            title: 'Move to Client Folder',
            allowedTypes: ['file', 'folder'],
            minimumSelect: 1,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleBtnAction({ title: 'Move To Client Folder', action: 'moveToClient' });
            }
        },
        {
            icon: <DeleteIcon className={'w-6'} />,
            title: 'Delete',
            allowedTypes: ['pdf', 'file', 'folder'],
            minimumSelect: 1,

            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDeleteItem(selectedIds?.map((obj) => obj?.job_drive_id));
            }
        },
        {
            icon: <PlusCircleIcon className={'w-6'} />,
            title: 'Add File',
            allowedTypes: ['pdf', 'file', 'folder'],
            minimumSelect: 0,
            disabled: disabled ? true : false,
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                if (fileInputRef.current) {
                    fileInputRef.current.click();
                }
            }
        },
        {
            icon: <AddFolderIcon className={'w-6'} />,
            title: 'Add Folder',
            allowedTypes: ['pdf', 'file', 'folder'],
            minimumSelect: 0,
            disabled: disabled ? true : false,
            onClick: () => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setEditEntity({
                    type: 'folder',
                    parent_id: activePath?.length > 0 ? activePath?.at(-1)?.job_drive_id : ''
                });
                setCreateFolder(true);
            }
        }
    ];

    const [btnlist, setBtnlist] = useState(actionButtons || []);
    const [action, setAction] = useState(null);

    const handleBtnAction = ({ title, action }) => {
        setAction({ title, list: selectedIds, open: true, action: action });
    };

    const handleSelectId = (obj) => {
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item?.job_drive_id === obj?.job_drive_id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(obj);
            setSelectedIds(newIds);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        dispatch(
            setFilters({
                ...filters,
                [name]: value
            })
        );
    };

    useEffect(() => {
        filterActionBtns();
    }, [selectedIds]);

    const filterActionBtns = () => {
        const newBtnsList = actionButtons.filter((btnObj) => {
            const selectedTypes = selectedIds.map((file) =>
                file?.type === 'file' ? file?.mimetype?.split('/').at(-1) : file.type
            );
            const hasFolder = selectedTypes.includes('folder');

            switch (btnObj.title) {
                case 'Cut and Move':
                case 'Copy and Paste':
                case 'Move to Client Folder':
                    return selectedIds.length >= btnObj.minimumSelect;
                case 'Download':
                    return (
                        selectedIds.length === btnObj.minimumSelect &&
                        selectedIds.every((file) => file.type !== 'folder')
                    );
                case 'Export Zip Files':
                    return hasFolder || selectedIds.length >= btnObj.minimumSelect;
                case 'Collate Files':
                    return selectedIds.length >= btnObj.minimumSelect && selectedTypes.every((type) => type === 'pdf');
                case 'Delete':
                    return selectedIds.length >= btnObj.minimumSelect;
                case 'Add Folder':
                case 'Add File':
                    return selectedIds.length === 0;
                case 'Rename':
                case 'Request Signature':
                    return !hasFolder && selectedIds.length === 1;
                default:
                    return false;
            }
        });

        setBtnlist(newBtnsList);
    };

    const activeDocPath = () => {
        let initialArray = [{ link: '', title: 'Job Documents' }];
        if (activePath?.length > 0) {
            let updatedArray = activePath?.map((pathObj) => ({
                link: pathObj?.job_drive_id,
                title: pathObj?.name || ''
            }));
            initialArray = initialArray.concat(updatedArray);
        }
        return initialArray;
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            const validFiles = files.filter((file) => {
                return [...all_files_types, 'msg', 'vsd'].includes(
                    file?.type === '' ? file.name.split('.').pop().toLowerCase() : file?.type
                );
            });
            if (validFiles.length === files.length) {
                handleSubmitFiles(validFiles);
            } else {
                toastHandler('Some files have an invalid file type.', TOAST_TYPE_ERROR);
            }
        }
    };

    const handleSubmitFiles = async (files) => {
        if (files.length > 0) {
            const formDataJSON = new FormData();
            formDataJSON.append('job_id', id);
            formDataJSON.append('business_id', business_id);
            formDataJSON.append('uploaded_by', 'employee');

            files.forEach((file) => {
                formDataJSON.append('files', file);
            });
            const activeTabId = activePath.at(-1)?.job_drive_id;
            if (activePath?.length > 0 && activeTabId) {
                formDataJSON.append('folder_id', activeTabId);
            }

            dispatch(setLoader(true));
            dispatch(uploadFilesToDocManagerThunk(formDataJSON))
                .then((response) => {
                    if (response.payload) {
                        getData({ job_drive_id: activeTabId });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    toastHandler('Something went wrong', TOAST_TYPE_ERROR);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleDownlaodItems = async (ids) => {
        if (ids) {
            if (ids.length > 1) {
                let payload = {
                    job_id: id,
                    business_id,
                    item_ids: ids?.map((dataObj) => dataObj?.job_drive_id)
                };
                handleDownloadZip(payload, { endpoint: endpoint.downloadItems, method: 'GET' });
            } else {
                handleDownload({ url: ids[0]?.url });
            }
        }
    };

    const handleDeleteItem = (ids) => {
        const jsonPayload = {
            business_id,
            job_id: id,
            item_ids: ids?.join(',')
        };
        dispatch(setLoader(true));
        dispatch(JobManagerDeleteItemsThunk(jsonPayload))
            .then((res) => {
                if (res.payload) {
                    getData(null, activePath?.length > 0 ? activePath.at(-1)?.job_drive_id : null);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <>
            <div className="bg-white rounded-lg py-5">
                <div className="flex items-center justify-between flex-wrap gap-y-5 px-5">
                    <div className="flex justify-start items-center flex-wrap">
                        <SearchInput
                            width="300px"
                            defaultValue={filters?.search_term || ''}
                            atChange={(e) => {
                                handleInputChange(e);
                            }}
                            placeholder="Search"
                        />
                    </div>
                    <div>
                        {!globalLoading && (
                            <div className="flex items-center justify-end gap-5">
                                <AnimatePresence>
                                    {btnlist.map((btnObj, index) => (
                                        <motion.span
                                            key={btnObj.title}
                                            initial={{ x: '100px', opacity: 0 }}
                                            animate={{
                                                x: 0,
                                                opacity: 1
                                            }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                animate: { delay: `0.${index + 1}`, type: 'tween', duration: 0.2 },
                                                exit: { type: 'tween', duration: 0.3 }
                                            }}
                                        >
                                            <Button
                                                classes={'bg-gray-200 !rounded-full p-2'}
                                                tooltip={btnObj.title}
                                                iconSet={{ leftIconHTML: btnObj.icon }}
                                                atClick={() => btnObj?.onClick()}
                                                disabled={btnObj?.title === 'Download' ? false : disabled}
                                            />
                                        </motion.span>
                                    ))}
                                </AnimatePresence>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex flex-wrap mx-5 mt-5">
                    {!globalLoading && (
                        <Breadcrumb
                            path={activeDocPath()}
                            onClick={(obj) => {
                                getData({
                                    job_drive_id: obj?.link,
                                    name: obj?.title
                                });
                            }}
                        />
                    )}
                </div>

                <JobPortalDocumentsTable
                    data={data}
                    isLoading={globalLoading}
                    handleSelectId={handleSelectId}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    filters={filters}
                    setFilters={(filters) => {
                        dispatch(setFilters(filters));
                    }}
                    getData={getData}
                    getMainTableData={() =>
                        getData(null, activePath?.length > 0 ? activePath.at(-1)?.job_drive_id : null)
                    }
                    handleSubmitFiles={handleSubmitFiles}
                    handleDeleteItem={handleDeleteItem}
                    handleDownlaodItems={handleDownlaodItems}
                    disabled={disabled}
                />
                <EntityCreateUpdate
                    editObj={{
                        id: editEntity?.job_drive_id,
                        parent_id:
                            editEntity?.parent_id ?? activePath?.length > 0 ? activePath.at(-1)?.job_drive_id : null
                    }}
                    fileName={editEntity?.name}
                    type={editEntity?.type}
                    open={createFolder}
                    setOpen={(e) => {
                        setCreateFolder(e);
                        setEditEntity(null);
                    }}
                    getData={getData}
                    currentList={data}
                />

                {action && action?.open && action?.action !== 'collateDocs' && (
                    <MoveOrCopy
                        open={!!action}
                        setOpen={(e) => {
                            if (!e) {
                                setAction(null);
                            }
                        }}
                        setFileList={setSelectedIds}
                        title={action?.title}
                        action={action?.action}
                        list={selectedIds}
                        getMainTableData={() =>
                            getData(null, activePath?.length > 0 ? activePath.at(-1)?.job_drive_id : null)
                        }
                    />
                )}
                {action && action?.open && action?.action === 'collateDocs' && (
                    <CollateDocuments
                        open={!!action}
                        setOpen={(e) => {
                            if (!e) {
                                setAction(null);
                            }
                        }}
                        setFileList={setSelectedIds}
                        title={action?.title}
                        action={action?.action}
                        list={selectedIds}
                        handleSelectId={handleSelectId}
                        getMainTableData={() =>
                            getData(null, activePath?.length > 0 ? activePath.at(-1)?.job_drive_id : null)
                        }
                    />
                )}

                <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    accept={'*'}
                    multiple
                    style={{ display: 'none' }}
                    id="fileInput"
                />
            </div>
        </>
    );
};

export default JobPortalDocuments;
