import { RectangleStackIcon } from '@heroicons/react/20/solid';
import { UsersIcon } from '@heroicons/react/24/outline';
import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';

export const Groups = () => {
    return (
        <SidebarLayout>
            <Jumbotron
                title="Groups"
                path={[
                    { link: 'settings', title: 'Settings' },
                    { link: 'settings/groups', title: 'Groups' }
                ]}
            />

            <div className="mt-10 md:mx-10 mx-5 flex items-center gap-5 flex-wrap">
                <GridCard
                    text="Client Groups"
                    iconSet={{ icon: UsersIcon, sx: ' w-20 h-20' }}
                    link={'/settings/groups/clients'}
                />
                <GridCard
                    text="Jobs Groups Page"
                    iconSet={{ icon: RectangleStackIcon, sx: ' w-20 h-20' }}
                    link={'/settings/groups/jobs'}
                />
            </div>
        </SidebarLayout>
    );
};
