import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from 'components/common/Loader';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setSideLoader } from 'store/global/globalReducer';
import {
    addRoleGroupThunk,
    fetchAllPermissionsThunk,
    getAllBusinessModulesThunk,
    fetchRoleGroupsThunk
} from 'store/settings/team/team';

import { BluePointRight } from 'utills/svgs/BluePointRight';
import { SideTimes } from 'utills/svgs/SideTimes';
import { validateTextField } from 'utills/FormValidation';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { convertUnderscoresToSpaces } from 'utills/handleText';
import useRoleGuard from 'Hooks/useRoleGuard';
import { ADMIN_WRITE } from 'utills/constants/forms/roleManagement.constants';
const AddRoleGroup = ({ toggleAddRoleGroup }) => {
    const dispatch = useDispatch();
    const [businessModules, setBusinessModules] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const { sideLoader } = useSelector((state) => state.global);
    const [roleName, setRoleName] = useState('');
    const [showPermissions, setShowPermissions] = useState(false);
    const [permissionsArr, setPermissionsArr] = useState([]);
    const [roleNameError, setRoleNameError] = useState(false);

    const [modules, setModules] = useState([]);

    const handleSelect = (value, e, _index) => {
        const isChecked = e.target.checked;
        if (!isChecked) {
            const exist = modules.find((module) => module.business_module_id === value);
            const index = modules.indexOf(exist);
            let newModules = [...modules];
            newModules.splice(index, 1);
            setModules(newModules);
        } else {
            const module = {
                business_module_id: value,
                permissions: permissions.map((item) => {
                    return { permission_id: item.permission_id };
                })
            };
            setModules([...modules, module]);
        }
    };

    const permissionHandler = (business_module_id, permission_id, e, permission) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            const existingModuleIndex = modules?.findIndex((item) => item.business_module_id === business_module_id);

            let permissionsArr = [];

            if (permission?.permission_name?.toLowerCase() === 'write') {
                const readWritePermissions = permissions?.filter(
                    (item) =>
                        item?.permission_name?.toLowerCase()?.includes('read') ||
                        item?.permission_name?.toLowerCase()?.includes('write')
                );

                readWritePermissions?.forEach((item) => {
                    permissionsArr.push({ permission_id: item.permission_id });
                });
            } else if (permission?.permission_name?.toLowerCase() === 'admin') {
                permissionsArr = permissions?.map((item) => {
                    return {
                        permission_id: item?.permission_id
                    };
                });
            }

            if (existingModuleIndex === -1) {
                const module = {
                    business_module_id: business_module_id,
                    permissions: permissionsArr?.length > 0 ? permissionsArr : [{ permission_id: permission_id }]
                };
                setModules([...modules, module]);
            } else {
                const newModules = [...modules];

                if (permissionsArr.length > 0) {
                    newModules[existingModuleIndex].permissions = permissionsArr;
                } else {
                    newModules[existingModuleIndex]?.permissions.unshift({
                        permission_id: permission_id
                    });
                }

                setModules(newModules);
            }
        } else {
            let module = modules.find((module) => module.business_module_id === business_module_id);
            const index = modules.indexOf(module);
            const permissionsList = module?.permissions?.filter((item) => item.permission_id !== permission_id);
            let newModules = [...modules];
            newModules[index] = module;

            if (
                permission?.permission_name?.toLowerCase() === 'read' ||
                permission?.permission_name?.toLowerCase() === 'admin'
            ) {
                module.permissions = [];
            } else {
                module.permissions = permissionsList;
            }

            if (permissionsList.length === 0) {
                newModules.splice(index, 1);
            }

            setModules(newModules);
        }
    };

    const togglePermissions = (business_module_id) => {
        const newArr = [...permissionsArr];

        setShowPermissions(!showPermissions);

        const module = businessModules?.find((m) => m.business_module_id === business_module_id);
        const index = businessModules.indexOf(module);

        newArr.push(index);
        setPermissionsArr(newArr);

        const valueToCheck = business_module_id;

        let isFound = false;

        for (const item of newArr) {
            if (item === valueToCheck) {
                isFound = true;
                break;
            }
        }

        if (isFound) {
            const index = newArr.indexOf(valueToCheck);
            newArr.splice(index, 1);
            setPermissionsArr(newArr);
        } else {
            newArr.unshift(valueToCheck);
            setPermissionsArr(newArr);
        }
    };
    const hasPermissionss = (business_module_id, permission_id) => {
        const moduleExist = modules?.find((item) => item.business_module_id === business_module_id);
        if (!moduleExist) {
            return false;
        }
        const index = modules.indexOf(moduleExist);
        if (modules[index]?.permissions?.some((item) => item?.permission_id === permission_id)) {
            return true;
        }
        return false;
    };

    const isAdminSelected = (business_module_id, name) => {
        if (name?.toLowerCase() === 'admin') {
            return false;
        }
        const moduleExist = modules?.find((item) => item.business_module_id === business_module_id);
        if (!moduleExist) {
            return false;
        }
        const parentAdmin = permissions?.find((item) => item?.permission_name.toLowerCase() === 'admin');

        const hasAdmin = moduleExist?.permissions?.some((item) => item?.permission_id === parentAdmin?.permission_id);
        if (hasAdmin) {
            return true;
        } else {
            return false;
        }
    };
    const handleSubmit = () => {
        if (roleNameError) {
            toastHandler('Invalid role name', 'error');
            return;
        }
        if (!roleName || !modules.length > 0) {
            toastHandler('Please fill in required fields', 'error');
            return;
        }

        const payload = {
            business_group_name: roleName,
            business_group_type: 'Customised',
            business_modules: modules
        };

        dispatch(setSideLoader(true));
        dispatch(addRoleGroupThunk(payload))
            .then((_response) => {
                dispatch(fetchRoleGroupsThunk())
                    .then(() => {
                        toggleAddRoleGroup();
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {});
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    const handleRoleName = (e) => {
        const { value } = e.target;
        if (validateTextField(value)) {
            setRoleNameError(false);
        } else {
            setRoleNameError(true);
        }

        setRoleName(value);
    };

    const fetchPermissions = () => {
        dispatch(fetchAllPermissionsThunk())
            .then((response) => {
                if (response.payload) {
                    setPermissions(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        if (hasPermissions('Role_management', ['admin'])) {
            dispatch(setSideLoader(true));
            dispatch(getAllBusinessModulesThunk())
                .then((response) => {
                    if (response.payload) {
                        setBusinessModules(response.payload);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    fetchPermissions();
                });
        }
    }, []);

    return (
        <div className="add-p-side grid grid-cols-6 text-black">
            <div className="md:col-span-4 hidden md:block left-side" onClick={toggleAddRoleGroup}></div>
            <div className="right-side col-span-6 md:col-span-2">
                <div className="">
                    <motion.div
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                        className="inner-right relative"
                    >
                        {sideLoader && <Loader />}
                        <div>
                            <div
                                onClick={toggleAddRoleGroup}
                                className="absolute   text-white p-2 right-1 top-1 cursor-pointer"
                            >
                                <SideTimes />
                            </div>

                            <div className="add-detail pt-10 px-5">
                                <div className="title">Add Role Group</div>
                            </div>
                        </div>

                        <RoleBasedGuard module={'Role_management'} permissions={['admin']} sx="h-[50vh]">
                            <div>
                                <div className="add-ann-form mb-5">
                                    <div className=" mt-5 px-5">
                                        <div>
                                            <div>
                                                <label>Role Name</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    maxLength={40}
                                                    placeholder="Role name"
                                                    className="px-3 rounded-xl mt-1"
                                                    onChange={handleRoleName}
                                                />
                                                <div className="error-div mt-1">
                                                    {roleNameError && roleName && <span> {'Invalid role name'} </span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {businessModules &&
                                    businessModules.map((module, index) => (
                                        <div className={`mx-5 my-2 `} key={module?.business_module_id}>
                                            <div className="flex gap-3  role-body">
                                                <div className="flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="cursor-pointer text-thirdbg   rounded-[2px]"
                                                        checked={
                                                            modules?.some((item) => {
                                                                return (
                                                                    item.business_module_id ===
                                                                        module.business_module_id &&
                                                                    item?.permissions.length
                                                                );
                                                            })
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) =>
                                                            handleSelect(module?.business_module_id, e, index)
                                                        }
                                                    />
                                                </div>
                                                <div className="flex gap-1">
                                                    <div
                                                        className="flex items-center cursor-pointer"
                                                        onClick={() => togglePermissions(module?.business_module_id)}
                                                    >
                                                        {permissionsArr.includes(module?.business_module_id) ? (
                                                            <span className="transform rotate-90">
                                                                {' '}
                                                                <BluePointRight />{' '}
                                                            </span>
                                                        ) : (
                                                            <BluePointRight />
                                                        )}
                                                    </div>
                                                    <div className="flex items-center">
                                                        {convertUnderscoresToSpaces(module?.business_module_name)}
                                                    </div>
                                                </div>
                                            </div>
                                            {permissionsArr.includes(module?.business_module_id) ? (
                                                <div className="flex  border-items-container py-3">
                                                    <div className="hidden-check">
                                                        <input type="checkbox" />
                                                        <input type="checkbox" />
                                                    </div>

                                                    <div className=" border-items-wrapper ">
                                                        {permissionsArr.includes(module?.business_module_id)
                                                            ? permissions
                                                                  .filter(
                                                                      (permission) =>
                                                                          !(
                                                                              permission.permission_name === 'Write' &&
                                                                              ADMIN_WRITE.includes(
                                                                                  module?.business_module_name
                                                                              )
                                                                          )
                                                                  )
                                                                  .map((permission, pIndex) => (
                                                                      <motion.div
                                                                          initial={{ x: '60', opacity: 0 }}
                                                                          animate={{ x: 0, opacity: 1 }}
                                                                          transition={{ duration: 0.5, type: 'tween' }}
                                                                          key={permission?.permission_id}
                                                                          className="border-item-one relative gap-1 flex  pt-7 items-center"
                                                                      >
                                                                          <div className="bottom-line">
                                                                              <div className="hidden-check">e</div>
                                                                          </div>
                                                                          <div className="flex gap-1 items-center role-item">
                                                                              <div className="mt-1 scale-75">
                                                                                  <input
                                                                                      type="checkbox"
                                                                                      className={`${
                                                                                          isAdminSelected(
                                                                                              module.business_module_id,
                                                                                              permission?.permission_name
                                                                                          )
                                                                                              ? 'text-gray-400 opacity-50 cursor-not-allowed'
                                                                                              : 'text-thirdbg cursor-pointer'
                                                                                      }   rounded-[2px]`}
                                                                                      checked={
                                                                                          hasPermissionss(
                                                                                              module.business_module_id,
                                                                                              permission.permission_id
                                                                                          )
                                                                                              ? true
                                                                                              : false
                                                                                      }
                                                                                      onChange={(e) => {
                                                                                          permissionHandler(
                                                                                              module.business_module_id,
                                                                                              permission.permission_id,
                                                                                              e,
                                                                                              permission
                                                                                          );
                                                                                      }}
                                                                                      disabled={isAdminSelected(
                                                                                          module.business_module_id,
                                                                                          permission?.permission_name
                                                                                      )}
                                                                                      id={
                                                                                          pIndex +
                                                                                          permission?.permission_id
                                                                                      }
                                                                                  />
                                                                              </div>
                                                                              <label
                                                                                  htmlFor={
                                                                                      pIndex + permission?.permission_id
                                                                                  }
                                                                                  className={`${
                                                                                      isAdminSelected(
                                                                                          module.business_module_id,
                                                                                          permission?.permission_name
                                                                                      )
                                                                                          ? 'text-gray-400 opacity-50 cursor-not-allowed'
                                                                                          : 'cursor-pointer'
                                                                                  } mt-1 `}
                                                                              >
                                                                                  {' '}
                                                                                  {convertUnderscoresToSpaces(
                                                                                      permission?.permission_name
                                                                                  )}{' '}
                                                                              </label>
                                                                          </div>
                                                                      </motion.div>
                                                                  ))
                                                            : ''}
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <hr className="mt-2"></hr>
                                        </div>
                                    ))}
                            </div>

                            <div className="my-5 mt-10 flex justify-center items-center">
                                <button
                                    onClick={handleSubmit}
                                    className="add-btn px-10 add-role-btn py-2 cursor-pointer text-white rounded-lg"
                                >
                                    Add Role Group
                                </button>
                            </div>
                        </RoleBasedGuard>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};
export default AddRoleGroup;
