import React from "react";

export const BluepencilIcon = () => {
  return (
    <div>
      {" "}
      <svg
        width="12"
        height="12"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1446 1.00635C12.7866 0.363223 13.6581 0.00122822 14.5673 3.11969e-06C15.4765 -0.00122199 16.3489 0.358423 16.9927 0.99982C17.6364 1.64122 17.9988 2.51183 18 3.42013C18.0012 4.32842 17.6412 5.20001 16.9992 5.84314L7.23116 15.6304C7.02534 15.8368 6.77604 15.9948 6.5014 16.0929L1.31069 17.9429C1.13565 18.0054 0.946454 18.0168 0.765138 17.976C0.583823 17.9352 0.417854 17.8437 0.286564 17.7123C0.155274 17.5808 0.0640674 17.4148 0.0235681 17.2336C-0.0169313 17.0524 -0.00505605 16.8634 0.0578104 16.6887L1.92275 11.5109C2.0202 11.2404 2.17602 10.9946 2.37918 10.791L12.1446 1.00635ZM16.0707 1.92615C15.8736 1.72935 15.6396 1.5733 15.3821 1.46691C15.1246 1.36052 14.8487 1.30588 14.5701 1.30612C14.2914 1.30637 14.0156 1.36148 13.7583 1.46832C13.501 1.57516 13.2673 1.73162 13.0705 1.92877L12.0478 2.95309L15.0466 5.94897L16.0733 4.92073C16.4703 4.52322 16.6931 3.98446 16.6926 3.4229C16.6921 2.86134 16.4684 2.32296 16.0707 1.92615ZM14.1233 6.87399L11.1245 3.87811L3.30511 11.7134C3.23749 11.7814 3.1857 11.8635 3.1534 11.9538L1.52256 16.4796L6.06067 14.8621C6.15268 14.8296 6.23624 14.7769 6.30523 14.708L14.1233 6.87399Z"
          fill="#B695F8"
        />
      </svg>
    </div>
  );
};
