import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Rnd } from 'react-rnd';

import { PDF_VIEWER_PAGE_SELECTOR } from './helper';

import { cn } from './helper';
import { Card, CardContent } from './card';
import { TrashIcon } from 'utills/svgs/TrashIcon';

export const FieldItem = ({
    field,
    _passive,
    disabled,
    minHeight: _minHeight,
    minWidth: _minWidth,
    onResize,
    onMove,
    onRemove,
    _colors,
    _scale
}) => {
    const [active, setActive] = useState(false);
    const [coords, setCoords] = useState({
        pageX: 0,
        pageY: 0,
        pageHeight: 0,
        pageWidth: 0
    });

    const calculateCoords = useCallback(() => {
        const $page = window.document.querySelector(
            `${PDF_VIEWER_PAGE_SELECTOR}[data-page-number="${field.pageNumber}"]`
        );
        if (!$page) {
            return;
        }

        const { height, width } = $page.getBoundingClientRect();

        const top = $page.getBoundingClientRect().top + window.scrollY;
        const left = $page.getBoundingClientRect().left + window.scrollX;

        const pageX = (field.pageX / 100) * width + left;
        const pageY = (field.pageY / 100) * height + top;

        const pageHeight = (field.pageHeight / 100) * height;
        const pageWidth = (field.pageWidth / 100) * width;

        setCoords({
            pageX: pageX,
            pageY: pageY,
            pageHeight: pageHeight,
            pageWidth: pageWidth
        });
    }, [field.pageHeight, field.pageNumber, field.pageWidth, field.pageX, field.pageY]);
    useEffect(() => {
        calculateCoords();
    }, [calculateCoords]);

    useEffect(() => {
        const onResize = () => {
            calculateCoords();
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [calculateCoords]);

    return createPortal(
        <Rnd
            style={{
                border: 'none',
                borderRadius: '0.7rem',
                boxShadow: `2px 2px 7px 2px ${field?.shadowItem}`,
                backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }}
            minWidth={200}
            maxWidth={400}
            minHeight={80}
            maxHeight={200}
            key={coords.pageX + coords.pageY + coords.pageHeight + coords.pageWidth}
            className={cn('z-10 rounded-md border-none', {
                'pointer-events-none ': disabled,

                'pointer-events-none opacity-25': disabled,
                'z-5': !active || disabled
            })}
            default={{
                x: coords.pageX,
                y: coords.pageY,
                height: coords.pageHeight,
                width: coords.pageWidth
            }}
            bounds={`${PDF_VIEWER_PAGE_SELECTOR}[data-page-number="${field.pageNumber}"]`}
            onDragStart={() => setActive(true)}
            onResizeStart={() => setActive(true)}
            onResizeStop={(_e, _d, ref) => {
                setActive(false);
                onResize?.(ref);
            }}
            onDragStop={(_e, d) => {
                setActive(false);
                onMove?.(d.node);
            }}
        >
            <button
                className="text-muted-foreground/50 shadow-custom hover:text-muted-foreground/80 bg-background absolute -right-2 -top-2 z-20 flex h-8 w-8 items-center justify-center rounded-full border"
                onClick={() => onRemove?.()}
            >
                <TrashIcon className="h-4 w-4" />
            </button>

            <Card
                className={cn('bg-background h-full w-full', {
                    'border-none': active
                })}
            >
                <CardContent
                    className={cn('text-foreground flex  h-full w-full flex-col items-center justify-center p-2', {
                        'text-muted-foreground/50': disabled
                    })}
                >
                    <p className="capitalize font-poppins font-bolder">{field.type}</p>
                    <p className="text-muted-foreground/50 w-full truncate text-center text-xs">{field?.signerName}</p>
                </CardContent>
            </Card>
        </Rnd>,
        document.body
    );
};
