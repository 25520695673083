import React from 'react';
import { ProfileCard } from './profile-section-cards/ProfileCard';
import './css/emp-profile.css';
import { TaskNumbersCard } from './profile-section-cards/TaskNumbersCard';
import { WeeklyStatistics } from './profile-section-cards/WeeklyStatistics';
import { CompletedTaskTypesCard } from './profile-section-cards/CompletedTaskTypesCard';
import { CompletionEfficiency } from './profile-section-cards/CompletionEfficiency';
import { DefaultBarChart } from 'components/molecules/DefaultBarChart';
import { useSelector } from 'react-redux';
export const EmployeeProfileSection = ({ efficiency, empInfo }) => {
    const { data: yearlyStats } = useSelector((state) => state.yearlyStatistics);
    const { data: weeklyStats } = useSelector((state) => state.weeklyStatsEmployee);

    const statData = yearlyStats
        ? yearlyStats?.map((dataObj) => ({
              x: dataObj.month,
              y: dataObj.value
          }))
        : [];
    const xValues = yearlyStats ? yearlyStats.map((dataObj) => dataObj.month) : [];
    const _totalValues = yearlyStats
        ? yearlyStats.flatMap((dataObj) => dataObj.value).reduce((total, value) => total + value, 0)
        : 0;

    return (
        <div>
            <div className="grid-container">
                <div className="grid-item item1 all-emp-wrapper rounded-xl shadow-sm">
                    <ProfileCard empInfo={empInfo} />
                </div>
                <div className="grid-item    item2 all-emp-wrapper rounded-xl shadow-sm">
                    <TaskNumbersCard empInfo={empInfo} />
                </div>
                <div className="grid-item item3 ">
                    <div className="bg-white rounded-lg">
                        {' '}
                        <div className="flex justify-between gap-2 flex-col">
                            <div className="xl-title text-blueish font-fira mx-5 pt-5">Tasks Completed</div>
                            <DefaultBarChart
                                xValues={xValues}
                                stats={[
                                    {
                                        data: statData
                                    }
                                ]}
                                height={280}
                                Yaxes={true}
                                barWidth="40%"
                                borderRadius="7"
                                bg="white"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-container2 mt-5">
                <div className="grid-item2 item7 all-emp-wrapper  shadow-sm rounded-xl overflow-y-auto">
                    <WeeklyStatistics weeklyStats={weeklyStats} />
                </div>
                <div className="grid-item2 item8 all-emp-wrapper h-[450px] shadow-sm rounded-xl overflow-y-auto">
                    <CompletedTaskTypesCard />
                </div>
                <div className="grid-item2  item9 all-emp-wrapper  rounded-xl">
                    <CompletionEfficiency efficiency={efficiency} />
                </div>
            </div>
        </div>
    );
};
