import { Button } from 'components/atoms/buttons/Button';
import Input from 'components/atoms/Input';
import ColorInput from 'components/atoms/Input/ColorInput';
import Slideover from 'components/atoms/SlideOver';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import React, { useState } from 'react';

const AddAccountCategories = ({ open, setOpen }) => {
    const [data, setData] = useState(null);

    const setDataFun = (target, value) => {
        setData((data) => ({
            ...(data ? data : {}),
            [target]: value
        }));
    };
    return (
        <div>
            <Slideover
                open={open}
                setOpen={setOpen}
                footer={
                    <div>
                        <div className="flex w-full items-center gap-2 justify-center">
                            <Button
                                title={'Cancel'}
                                atClick={() => setOpen(false)}
                                classes=" px-12 py-2 min-h-[3rem] border border-blueish text-blueish font-[500] font-fira rounded-[12px] hover:bg-blue-50"
                            />
                            <Button
                                title={'Add'}
                                atClick={() => setOpen(false)}
                                classes="ann-btn px-14 py-2 border font-[500] rounded-[12px] hover:bg-blue-50"
                            />
                        </div>
                    </div>
                }
            >
                <div className="text-3xl font-fira font-[500]">Add Category</div>

                <div className="mt-8 w-full">
                    <div className="flex gap-5 w-full flex-col">
                        <div>
                            <PrimaryLabel> Job Title </PrimaryLabel>
                            <Input
                                type="text"
                                value={data?.title || ''}
                                onChange={(e) => setDataFun('title', e.target.value)}
                                maxLength={120}
                                required
                                placeholder="Enter Category Name"
                                useWithoutForm={true}
                                wrapsx="mt-2"
                            />
                        </div>
                        <div>
                            <PrimaryLabel> Category Color </PrimaryLabel>
                            <ColorInput
                                sx={'h-[50px]'}
                                defaultOpen={true}
                                value={data?.color || ''}
                                onChange={(e) => setDataFun('color', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </Slideover>
        </div>
    );
};

export default AddAccountCategories;
