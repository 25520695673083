import React from 'react';

export const BoldIcon = () => {
    return (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.32898 17H0.199951V0H6.53687C7.63962 0 8.58705 0.137816 9.37916 0.413448C10.1868 0.68908 10.7925 1.06199 11.1964 1.53219C11.9264 2.40773 12.2913 3.39676 12.2913 4.49929C12.2913 5.8288 11.8798 6.81784 11.0566 7.46638C10.777 7.69337 10.5829 7.8393 10.4741 7.90415C10.3654 7.95279 10.1713 8.04197 9.89171 8.17167C10.9013 8.39867 11.7011 8.87697 12.2913 9.60658C12.8971 10.32 13.2 11.2117 13.2 12.2818C13.2 13.4654 12.8117 14.5112 12.0351 15.4192C11.1342 16.4731 9.56554 17 7.32898 17ZM3.83436 6.88269H5.55837C6.56793 6.88269 7.31345 6.7692 7.79493 6.5422C8.29195 6.31521 8.54045 5.8288 8.54045 5.08298C8.54045 4.32094 8.31524 3.81831 7.86483 3.57511C7.41441 3.3319 6.65336 3.2103 5.58167 3.2103H3.83436V6.88269ZM3.83436 13.7897H6.32719C7.36781 13.7897 8.1444 13.66 8.65694 13.4006C9.18502 13.1249 9.44905 12.598 9.44905 11.8197C9.44905 11.0253 9.16948 10.5064 8.61034 10.2632C8.06674 10.0038 7.1892 9.87411 5.97773 9.87411H3.83436V13.7897Z"
                fill="#656565"
            />
        </svg>
    );
};
