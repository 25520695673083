import React from 'react';
import Breadcrumb from 'components/atoms/Breadcrumb';
import './css/announcement.css';

import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'components/atoms/buttons/Button';
import AddAnouncementsIcon from 'utills/svgs/AddAnouncementsIcon';

const AnnouncementJumbo = (props) => {
    const { toggleAddAnn, path } = props;

    return (
        <div className=" relative  md:flex md:flex-row flex-col  px-10 py-10 md:justify-between ">
            <div className="profile-jumbo-flex">
                <div className="jumbo-flex-1 ">
                    <div className="text-3xl font-fira font-[600]">Announcements</div>
                    {path ? (
                        <Breadcrumb
                            path={[
                                { title: 'Workspace', link: 'workspace' },
                                {
                                    title: 'Announcements',
                                    link: ''
                                }
                            ]}
                        />
                    ) : (
                        <div className="jumbo-dir mt-2">
                            Workspace <span className="special-jumbo-text">&gt; Announcements</span>
                        </div>
                    )}
                </div>
            </div>
            <div className=" md:mt-0 my-2   md:absolute right-10 top-5 md:top-11">
                {' '}
                <div className="flex gap-2 items-start flex-wrap">
                    <Button
                        title={'Add Announcement'}
                        type={'button'}
                        atClick={toggleAddAnn}
                        classes={'bg-primary flex items-center gap-2 text-white py-3 !px-3 rounded-lg'}
                        iconSet={{ leftIcon: AddAnouncementsIcon }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AnnouncementJumbo;
