/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import './css/deductions.css';
import { EXPENSE_ARRAY } from 'playground';
import { Button } from 'components/atoms/buttons/Button';
import { formateDate, formateDateAndTime } from 'utills/moment';
import { SummaryDrawer } from 'components/organisms/SummaryDrawer';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    handleDeductionType,
    handleSummaryDates,
    handleSummaryType,
    handleSunmmaryId,
    setLoader,
    setSideLoader
} from 'store/global/globalReducer';
import {
    getSummaryForBusinessThunk,
    getSummaryLogsThunk,
    getTfnDetailsThunk,
    getsummaryDetailChartThunk
} from 'store/deductions';
import { BgUserIcon } from 'utills/svgs/BgUserIcon';
import { BgTaxIcon } from 'utills/svgs/BgTaxIcon';
import { BgEmailIcon } from 'utills/svgs/BgEmailIcon';
import { BgPhoneIcon } from 'utills/svgs/BgPhoneIcon';
import { BgDateIcon } from 'utills/svgs/BgDateIcon';
import { BgHomeIcon } from 'utills/svgs/BgHomeIcon';
import { BgLocationIcon } from 'utills/svgs/BgLocationIcon';
import moment from 'moment';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { NoDataFound } from 'components/common/NoDataFound';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { Menu } from 'components/molecules/Menu';
import { SortIcon } from 'utills/svgs/SortIcon';
import { BUSINESS_ID, SORT_OPTIONS } from 'utills/globalVars';
import { DefaultLogs } from 'components/molecules/DefaultLogs';
import { ProfileInfoCard } from 'components/molecules/ProfileInfoCard';
import { DefaultBarChart } from 'components/molecules/DefaultBarChart';
import { BoxSkeleton } from 'components/molecules/skeletons/BoxSkeleton';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { UseDownloadLogsPdf } from 'Hooks/Files/UseDownloadLogsPdf';
import { endpoint } from 'apiEndpoints';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import dayjs from 'dayjs';
import { SidebarLayout } from 'layouts/SidebarLayout';
export const TfnDeductions = () => {
    const navigate = useNavigate();

    const [summaryFromDate, setSummaryFromDate] = useState(null);
    const [summaryToDate, setSummaryToDate] = useState(null);

    const { data: tfnInfo } = useSelector((state) => state.getTfnDetails);
    const [selectedDate, setSelectedDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showSummary, setShowSummary] = useState(false);
    const { isLoading } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: customer } = useSelector((state) => state.viewBusinessCustomerInfo);

    const { handleDownloadLogsPdf } = UseDownloadLogsPdf();

    const [sortAsc, setSortAsc] = useState(false);
    const [sortBy, setSortBy] = useState('DESC');
    const business_id = localStorage.getItem(BUSINESS_ID);

    const [showLogs, setShowLogs] = useState(false);
    const [hasMoreContent, setHasMoreContent] = useState(true);

    const [summaryList, setSummaryList] = useState([]);
    const [count, setCount] = useState(0);

    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [summaryCount, setsummaryCount] = useState(0);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
    const [summaryPS, setSummaryPS] = useState(3);
    const [summaryP, setSummaryP] = useState(1);
    const [summary_id, setSummaryId] = useState('');
    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [expenseXv, setexpenseXv] = useState([]);
    const [expenseChartData, setexpenseChartData] = useState([]);

    const [filteredLogs, setfilteredLogs] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [filterCount, setFilterCount] = useState(1);

    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [customizeRow, setCustomizeRow] = useState(false);
    const [loadAtFirst, setLoadAtFirst] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get('summary_id');
    useEffect(() => {
        if (queryId && loadAtFirst) {
            toggleSummary(queryId, '', '', 'expense', 'tfn');
        }
    }, [queryId, loadAtFirst]);

    const toggleLogs = () => {
        setShowLogs(!showLogs);
    };

    const headings = ['Ref No', 'Expense Reported', 'Date and Time', 'Action'];
    const summaryHeadings = ['Description', 'Expense', 'GST', 'Type', 'Date', 'Image'];

    const toggleSummary = (id, from, to, summary_type, deduction_type) => {
        if (id) {
            dispatch(handleSunmmaryId(id));
        }
        if (deduction_type) {
            dispatch(handleDeductionType(deduction_type));
        }
        if (from && to && summary_type) {
            dispatch(
                handleSummaryDates({
                    from,
                    to
                })
            );
            dispatch(handleSummaryType(summary_type));
        }
        setShowSummary(!showSummary);
    };

    const closeSummary = () => {
        setShowSummary(false);
    };
    const datePickerProps = {
        right: CalendarSmallIco
    };

    const colSpanTwo = [1];
    const checkBoxAt = [1];

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/customer/deductions/${id}`);
        }
    };

    const onFilterChange = () => {
        if (tfnInfo && tfnInfo?.customer_tfn_id) {
            const fromdate =
                summaryFromDate === null ? '' : moment(new Date(summaryFromDate)).format('DD-MM-YYYY').toString();
            const todate =
                summaryToDate === null ? '' : moment(new Date(summaryToDate)).format('DD-MM-YYYY').toString();
            setSummaryList([]);
            setSummaryP(1);
            getSummaryforBusinessDef({
                deduction_type: 'tfn',
                business_id: business_id,
                business_client_id: id,
                from: fromdate,
                to: todate,
                record_id: tfnInfo.customer_tfn_id,
                sort_by: sortBy,
                search_term: searchTerm,
                page: summaryP,
                page_size: summaryPS,
                filter: true
            });
        }
    };

    const getTfn = () => {
        const payload = {
            business_client_id: id
        };
        dispatch(setLoader(true));
        dispatch(getTfnDetailsThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const getChartData = (payload) => {
        dispatch(getsummaryDetailChartThunk(payload))
            .then((response) => {
                if (response?.payload?.expense) {
                    const xv = [];
                    const chartData = [];
                    for (const item in response?.payload?.expense) {
                        xv.push(item);
                        chartData.push({
                            x: item,
                            y: response?.payload?.expense[item].value
                        });
                    }
                    const sortedXv = xv.sort((a, b) => a - b);
                    setexpenseXv(sortedXv);
                    setexpenseChartData(chartData);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const getsummary = () => {
        const fromdate =
            summaryFromDate === null ? '' : moment(new Date(summaryFromDate)).format('DD-MM-YYYY').toString();
        const todate = summaryFromDate === null ? '' : moment(new Date(summaryToDate)).format('DD-MM-YYYY').toString();
        if (tfnInfo && tfnInfo?.customer_tfn_id) {
            getSummaryforBusinessDef({
                deduction_type: 'tfn',
                business_id: business_id,
                business_client_id: id,
                from: fromdate,
                to: todate,
                record_id: tfnInfo.customer_tfn_id,
                sort_by: sortBy,
                search_term: searchTerm,
                page: summaryP,
                page_size: summaryPS,
                filter: false
            });
        }
    };

    const [summaryLoader, setSummaryLoader] = useState(false);

    const getSummaryforBusinessDef = (data) => {
        setSummaryLoader(true);
        setHasMoreContent(true);
        const payload = {
            deduction_type: data.deduction_type,
            business_id: data.business_id,
            business_client_id: data.business_client_id,
            from_date: data?.from,
            to_date: data?.to,
            record_id: data?.record_id,
            sort_by: data?.sort_by ?? 'ASC',
            search_term: data?.search_term ?? '',
            page: data?.page ?? 1,
            page_size: data?.page_size ?? 10
        };
        dispatch(getSummaryForBusinessThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setsummaryCount(response?.payload?.count);
                    setLoadAtFirst(true);
                    if (data?.filter) {
                        const list = [...response.payload.rows];
                        if (list.length === response?.payload?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                    } else {
                        const list = [...summaryList, ...response.payload.rows];
                        if (list.length === response?.payload?.count) {
                            setHasMoreContent(false);
                        }
                        setSummaryList(list);
                        if (!summary_id) {
                            setSummaryId(response?.payload?.rows[0]?.summary_id);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setSummaryLoader(false);
            });
    };

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleSearch = (term) => {
        setSearchTerm(term);
    };
    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        setSortBy(sortAsc ? 'ASC' : 'DESC');
    };
    const handleFromDateChange = (date) => {
        setSummaryFromDate(date);
    };
    const handleToDateChange = (date) => {
        setSummaryToDate(date);
    };
    const handleClear = () => {
        setSummaryFromDate(null);
        setSummaryToDate(null);
        setSearchTerm('');
        setSummaryP(1);
        setSummaryList([]);
    };

    useEffect(() => {
        if (summaryFromDate && summaryToDate) {
            onFilterChange();
        }
    }, [summaryFromDate, summaryToDate]);

    useEffect(() => {
        onFilterChange();
    }, [searchTerm, sortAsc]);

    useEffect(() => {
        getsummary();
    }, [summaryP]);

    useEffect(() => {
        fetchCustomer();
    }, []);
    useEffect(() => {
        if (tfnInfo && tfnInfo?.customer_tfn_id) {
            getsummary();
        }
    }, [tfnInfo, id]);
    useEffect(() => {
        getTfn();
    }, []);

    useEffect(() => {
        const currentFinancialYearStart =
            moment().month() < 6
                ? moment().subtract(1, 'year').month(6).date(1).startOf('month')
                : moment().month(6).date(1).startOf('month');
        const currentFinancialYearEnd =
            moment().month() < 6
                ? moment().month(5).date(30).endOf('month')
                : moment().add(1, 'year').month(5).date(30).endOf('month');

        const lastFinancialYearStart =
            moment().month() < 6
                ? moment().subtract(2, 'years').month(6).date(1).startOf('month')
                : moment().subtract(1, 'year').month(6).date(1).startOf('month');
        const lastFinancialYearEnd =
            moment().month() < 6
                ? moment().subtract(1, 'year').month(5).date(30).endOf('month')
                : moment().month(5).date(30).endOf('month');

        const quarters = [];
        for (let i = 0; i < 4; i++) {
            let quarterStart, quarterEnd;
            switch (i) {
                case 0:
                    quarterStart = moment().month(6).date(1).startOf('month');
                    quarterEnd = moment().month(8).date(30).endOf('month');
                    break;
                case 1:
                    quarterStart = moment().month(9).date(1).startOf('month');
                    quarterEnd = moment().month(11).date(31).endOf('month');
                    break;
                case 2:
                    quarterStart = moment().month(0).date(1).startOf('month');
                    quarterEnd = moment().month(2).date(31).endOf('month');
                    break;
                case 3:
                    quarterStart = moment().month(3).date(1).startOf('month');
                    quarterEnd = moment().month(5).date(30).endOf('month');
                    break;
                default:
                    break;
            }
            quarters.push({
                title: `Quarter ${i + 1}`,
                start_date: quarterStart.format('DD-MM-YYYY'),
                end_date: quarterEnd.format('DD-MM-YYYY')
            });
        }

        const last6MonthsStart = moment().subtract(6, 'months').startOf('month');
        const last6MonthsEnd = moment().subtract(1, 'months').endOf('month');

        setFilters([
            {
                title: 'Last Financial Year',
                start_date: lastFinancialYearStart.format('DD-MM-YYYY'),
                end_date: lastFinancialYearEnd.format('DD-MM-YYYY')
            },
            {
                title: 'Current Financial Year',
                start_date: currentFinancialYearStart.format('DD-MM-YYYY'),
                end_date: currentFinancialYearEnd.format('DD-MM-YYYY')
            },
            ...quarters,
            {
                title: 'Last 6 Months',
                start_date: last6MonthsStart.format('DD-MM-YYYY'),
                end_date: last6MonthsEnd.format('DD-MM-YYYY')
            }
        ]);

        if (summary_id && currentFinancialYearStart && currentFinancialYearEnd) {
            const payload = {
                summary_id: summary_id,
                start_date: currentFinancialYearStart.format('DD-MM-YYYY'),
                end_date: currentFinancialYearEnd.format('DD-MM-YYYY'),
                business_client_id: id
            };
            getChartData(payload);
        }
    }, [summary_id]);

    useEffect(() => {
        setCustomizeRow(selectedFilter?.title === 'Customize' ? true : false);

        if (selectedFilter && selectedFilter.start_date && selectedFilter.end_date) {
            const payload = {
                summary_id: summary_id,
                start_date: selectedFilter?.start_date,
                end_date: selectedFilter?.end_date,
                business_client_id: id
            };
            getChartData(payload);
        }
    }, [selectedFilter]);

    const managerActions = [
        {
            title: 'View Logs',
            classes: 'flex gap-2 items-center px-8 !rounded-xl ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                toggleLogs();
            }
        }
    ];

    const fetchLogs = () => {
        const payload = {
            business_client_id: id,
            deduction_type: 'tfn',
            page: term || dateFrom || dateTo ? filterPage : page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            business_id,
            filter: {
                text: term,
                from_date: dateFrom && moment(new Date(dateFrom)).format('DD-MM-YYYY'),
                end_date: dateTo && moment(new Date(dateTo)).format('DD-MM-YYYY')
            }
        };

        dispatch(setSideLoader(true));
        dispatch(getSummaryLogsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (dateFrom || dateTo || term) {
                        setFilterCount(response?.payload?.count);
                        const newLogs = [...filteredLogs, ...response.payload.logs];
                        setfilteredLogs(newLogs);
                    } else {
                        setCount(response?.payload?.count);
                        if (page > 1) {
                            const newLogs = [...logs, ...response.payload.logs];
                            setLogs(newLogs);
                        } else {
                            const newLogs = response.payload.logs;
                            setLogs(newLogs);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleMore = () => {
        const totalPages = Math.ceil(count / Number(PAGINATION_PAGE_SIZE));
        const togleFilterPages = Math.ceil(filterCount / Number(PAGINATION_PAGE_SIZE));

        if (dateFrom || dateTo || term) {
            if (filterPage < togleFilterPages) {
                setFilterPage(filterPage + 1);
            }
            return;
        }
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handleMoreSummry = () => {
        const totalPages = Math.ceil(summaryCount / summaryPS);
        if (summaryP < totalPages) {
            setSummaryP(summaryP + 1);
        }
    };
    const onClickItem = (summary_id) => {
        setSummaryId(summary_id);
    };

    const handleDownloadActivity = () => {
        const payload = {
            business_client_id: id,
            business_id: business_id,
            deduction_type: 'tfn'
        };
        handleDownloadLogsPdf(payload, endpoint.downaloadDeductionsLogsPdf);
    };
    return (
        <SidebarLayout>
            <div>
                {showLogs && (
                    <DefaultLogs
                        toggleLogs={toggleLogs}
                        fetchLogs={fetchLogs}
                        logs={dateFrom || dateTo || term ? filteredLogs : logs}
                        count={count}
                        pageSize={pageSize}
                        page={page}
                        handleMore={handleMore}
                        term={term}
                        setTerm={setTerm}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        dateFrom={dateFrom}
                        setDateFrom={setDateFrom}
                        dateTo={dateTo}
                        setDateTo={setDateTo}
                        downloadActivity={handleDownloadActivity}
                        setFilterPage={setFilterPage}
                        filterPage={filterPage}
                        filterCount={filterCount}
                        setfilteredLogs={setfilteredLogs}
                        open={showLogs}
                        setOpen={setShowLogs}
                    />
                )}
                <div>
                    <div className="app-dashboard relative  ">
                        {showSummary && (
                            <SummaryDrawer
                                title="Salary/Wages Expenses"
                                onToggle={closeSummary}
                                setOpen={setShowSummary}
                                open={showSummary}
                                headings={summaryHeadings}
                                cols={7}
                                gap={5}
                                tableBody={EXPENSE_ARRAY}
                                colSpanTwo={colSpanTwo}
                                checkBoxAt={checkBoxAt}
                                showSwitch={false}
                                summaryType="expense"
                            />
                        )}
                        <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                            {' '}
                            <div>
                                <Jumbotron
                                    title="Salary/Wages Expenses"
                                    path={[
                                        { title: 'Clients', link: 'clients' },
                                        {
                                            title: `${
                                                customer?.client_type === 'prospective_client'
                                                    ? 'Prospective Client'
                                                    : customer?.client_type === 'active_client'
                                                    ? 'Active Client'
                                                    : customer?.client_type === 'inactive_client'
                                                    ? 'Inactive Client'
                                                    : 'Client Profile'
                                            }`,
                                            link: `app/users?type=${
                                                customer?.client_type === 'prospective_client'
                                                    ? 'prospective'
                                                    : customer?.client_type === 'active_client'
                                                    ? 'active'
                                                    : customer?.client_type === 'inactive_client'
                                                    ? 'invites'
                                                    : 'all'
                                            }&reload=false`
                                        },
                                        {
                                            title: ` ${customer?.first_name} ${customer?.last_name}`,
                                            link: `app/user/profile/${id}`
                                        },
                                        { title: `Deduction`, link: `customer/deductions/${id}` },
                                        { title: `Salary/Wages`, link: '' }
                                    ]}
                                    backArrowProp={backArrowProp}
                                    actionsRequired={true}
                                    actions={managerActions}
                                />

                                <div className="mx-5 md:mx-10 ">
                                    {isLoading ? (
                                        <BoxSkeleton />
                                    ) : (
                                        <ProfileInfoCard
                                            data={[
                                                {
                                                    icon: BgUserIcon,
                                                    key: 'Name',
                                                    value: tfnInfo?.given_name
                                                        ? tfnInfo?.given_name + ' ' + tfnInfo?.surname
                                                        : ''
                                                },
                                                {
                                                    icon: BgTaxIcon,
                                                    key: 'Tax File No',
                                                    value: tfnInfo?.tfn ?? ''
                                                },
                                                {
                                                    icon: BgEmailIcon,
                                                    key: 'Email',
                                                    value: tfnInfo?.email ?? ''
                                                },
                                                {
                                                    icon: BgPhoneIcon,
                                                    key: 'Phone',
                                                    value: tfnInfo?.mobile_number ?? ''
                                                },
                                                {
                                                    icon: BgDateIcon,
                                                    key: 'Date Of Birth',
                                                    value: formateDate(tfnInfo?.date_of_birth) ?? ''
                                                },
                                                {
                                                    icon: BgHomeIcon,
                                                    key: 'Australian Resident',
                                                    value: tfnInfo?.is_australian_address ? 'Yes' : 'No' ?? ''
                                                },
                                                {
                                                    icon: BgLocationIcon,
                                                    key: 'Residential Address',
                                                    value: tfnInfo?.home_address ?? ''
                                                },
                                                {
                                                    icon: BgLocationIcon,
                                                    key: 'Postal Address',
                                                    value: tfnInfo?.postal_address ?? ''
                                                }
                                            ]}
                                        />
                                    )}
                                </div>
                                <div className="my-2 md:mx-10 mx-5">
                                    <div className="deductions-cards-container py-5 ">
                                        {isLoading ? (
                                            <BoxSkeleton />
                                        ) : (
                                            <div className="bg-white rounded-xl shadow-custom ">
                                                <div className=" user-details-card ">
                                                    <div className="flex justify-between items-center gap-5 pr-5 p-5">
                                                        <div className="detail-card-title text-2xl">
                                                            Received Summary
                                                        </div>
                                                        <div className="flex gap-2 items-center">
                                                            {summaryFromDate || summaryToDate || searchTerm ? (
                                                                <ClearFilterButton atClick={() => handleClear()} />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="my-3 mx-5 flex gap-5 items-center justify-between flex-wrap">
                                                        <SearchInput
                                                            width="300px"
                                                            atChange={(e) => {
                                                                handleSearch(e.target.value);
                                                            }}
                                                            value={searchTerm}
                                                            placeholder="Search"
                                                            inputBorder="1px solid #DCDCDC"
                                                        />
                                                        <div className="flex gap-3 items-center ">
                                                            {' '}
                                                            <DatePick
                                                                iconSet={datePickerProps}
                                                                onDateChange={handleFromDateChange}
                                                                placeholder="From"
                                                                value={summaryFromDate}
                                                                bg="transparent"
                                                                sx="!rounded-xl text-gray-900"
                                                            />
                                                            <DatePick
                                                                iconSet={datePickerProps}
                                                                onDateChange={handleToDateChange}
                                                                placeholder="To"
                                                                value={summaryToDate}
                                                                bg="transparent"
                                                                sx=" !rounded-xl text-gray-900"
                                                            />
                                                            <div>
                                                                <Menu
                                                                    title="Order By"
                                                                    iconSet={{ first: SortIcon, second: StepDownIcon }}
                                                                    options={SORT_OPTIONS}
                                                                    gap={5}
                                                                    lastMl={5}
                                                                    isModalDisabled={true}
                                                                    condition={sortAsc}
                                                                    atMenuClick={() => {
                                                                        handleSortByDate();
                                                                    }}
                                                                    atClick={(title) => {}}
                                                                    text="max-md"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-8  sm:px-2 lg:px-4 ">
                                                        <div className={`grid px-5 grid-cols-${5} gap-${5}`}>
                                                            <div className={`default-table-heading col-span-2`}>
                                                                Summary Id
                                                            </div>
                                                            <div className={`default-table-heading `}>
                                                                Expense Reported
                                                            </div>
                                                            <div className={`default-table-heading `}>
                                                                Date and Time
                                                            </div>
                                                            <div className={`default-table-heading `}>Action</div>
                                                        </div>

                                                        <hr className="mt-2" />
                                                        <InfiniteScrollComp
                                                            height={250}
                                                            dataLength={summaryList.length}
                                                            next={handleMoreSummry}
                                                            hasMore={hasMoreContent || false}
                                                            loader={<SkeletonTable columns={4} />}
                                                        >
                                                            <div className="default-table-container ">
                                                                {summaryList && summaryList?.length > 0 ? (
                                                                    summaryList?.map((item, index) => (
                                                                        <div
                                                                            key={index}
                                                                            onClick={() => onClickItem(item.summary_id)}
                                                                            className={`mt-2 ${
                                                                                summary_id === item?.summary_id
                                                                                    ? 'summary-bg-color'
                                                                                    : ''
                                                                            }`}
                                                                        >
                                                                            <div
                                                                                className={`grid px-5 py-2 grid-cols-${5} gap-${5}`}
                                                                            >
                                                                                <div
                                                                                    className={`summary-body !break-words col-span-2 flex items-center break-all `}
                                                                                >
                                                                                    {item?.ref_no}
                                                                                </div>
                                                                                <div
                                                                                    className={`summary-body flex items-center break-all `}
                                                                                >
                                                                                    {item?.expense}
                                                                                </div>
                                                                                <div
                                                                                    className={`summary-body flex items-center  break-all`}
                                                                                >
                                                                                    {formateDateAndTime(
                                                                                        item?.created_at
                                                                                    )}
                                                                                </div>

                                                                                <div className="flex gap-2 items-center">
                                                                                    <div>
                                                                                        <Button
                                                                                            title="View Summary"
                                                                                            classes="primary-bg  text-white font-fira text-md font-font4 rounded-lg px-2 py-1"
                                                                                            atClick={() =>
                                                                                                toggleSummary(
                                                                                                    item?.summary_id,
                                                                                                    item?.from_date,
                                                                                                    item?.to_date,
                                                                                                    item?.summary_type,
                                                                                                    item?.deduction_type
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr className="mt-2" />
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <NoDataFound
                                                                        message="No Summary Found"
                                                                        height="30vh"
                                                                    />
                                                                )}
                                                            </div>
                                                        </InfiniteScrollComp>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div>
                                            {isLoading ? (
                                                <BoxSkeleton />
                                            ) : (
                                                <div className="bg-white shadow-custom rounded-xl ">
                                                    <div className="detail-card-title p-3 px-4 text-2xl">
                                                        Reported Expenses Financial Graph
                                                    </div>

                                                    <div className="flex items-center justify-between p-2 gap-3 mx-2 flex-wrap">
                                                        <div className="text-lg text-semibold">Filter</div>
                                                        <div className="w-[230px]">
                                                            <SimpleSelectMenu
                                                                placeholder="Select"
                                                                targetProperty="title"
                                                                valuePropertyName={'title'}
                                                                selectedValue={
                                                                    filters.length > 0 ? filters[1]?.title : ''
                                                                }
                                                                optionsData={filters || []}
                                                                sx="rounded-xl py-2 ring-gray-400 bg-white !font-normal"
                                                                onChangeValue={(obj) => {
                                                                    setSelectedFilter(obj);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {customizeRow && (
                                                        <div className="flex items-center justify-between p-2 gap-3">
                                                            <div className="w-[230px]">
                                                                <DatePick
                                                                    iconSet={datePickerProps}
                                                                    onDateChange={(date) => {
                                                                        setSelectedFilter({
                                                                            ...selectedFilter,
                                                                            start_date: date
                                                                                ? dayjs(date).format('DD-MM-YYYY')
                                                                                : ''
                                                                        });
                                                                    }}
                                                                    placeholder="From"
                                                                    value={
                                                                        selectedFilter?.start_date &&
                                                                        selectedFilter?.start_date !== ''
                                                                            ? dayjs(
                                                                                  selectedFilter?.start_date,
                                                                                  'DD-MM-YYYY'
                                                                              )
                                                                            : null
                                                                    }
                                                                    bg="transparent"
                                                                    sx="!rounded-xl text-gray-900"
                                                                />
                                                            </div>
                                                            <div className="w-[230px]">
                                                                <DatePick
                                                                    iconSet={datePickerProps}
                                                                    onDateChange={(date) => {
                                                                        setSelectedFilter({
                                                                            ...selectedFilter,
                                                                            end_date: date
                                                                                ? dayjs(date).format('DD-MM-YYYY')
                                                                                : ''
                                                                        });
                                                                    }}
                                                                    placeholder="To"
                                                                    value={
                                                                        selectedFilter?.end_date &&
                                                                        selectedFilter?.end_date !== ''
                                                                            ? dayjs(
                                                                                  selectedFilter?.end_date,
                                                                                  'DD-MM-YYYY'
                                                                              )
                                                                            : null
                                                                    }
                                                                    bg="transparent"
                                                                    sx="!rounded-xl text-gray-900"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <DefaultBarChart
                                                        xValues={expenseXv}
                                                        stats={[
                                                            {
                                                                data: expenseChartData
                                                            }
                                                        ]}
                                                        height={280}
                                                        Yaxes={true}
                                                        radius="20%"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>{' '}
                        </RoleBasedGuard>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};
