import React, { useEffect, useState } from 'react';
import { cn } from 'utills/tailwindUtil';

const DefaultTextArea = ({
    placeholder,
    onInput,
    onChange,
    value,
    className,
    rows,
    cols,
    required,
    disabled,
    maxCountsx,
    maxCount,
    sx,
    hideCount = false
}) => {
    const [text, setText] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [maxWords, setMaxWords] = useState(1200);

    useEffect(() => {
        if (maxCount) {
            setMaxWords(maxCount);
        }
    }, [maxCount]);

    useEffect(() => {
        if (value) {
            const wordsArray = value.split(/\s+/).filter((word) => word.length > 0);
            setWordCount(wordsArray.length);
            setText(value);
        }
    }, [value]);

    const handleChange = (event) => {
        const newText = event.target.value;
        const wordsArray = newText.split(/\s+/).filter((word) => word.length > 0);
        if (wordsArray.length <= maxWords) {
            setText(newText);
            setWordCount(wordsArray.length);
            onChange(newText);
        } else {
            const trimmedText = wordsArray.slice(0, maxWords).join(' ');
            setText(trimmedText);
            setWordCount(maxWords);
            onChange(trimmedText);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();

        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        const newText = e.target.value + (pastedData || '');
        const wordsArray = newText.split(/\s+/).filter((word) => word.length > 0);

        if (wordsArray.length <= maxWords) {
            setText(newText);
            setWordCount(wordsArray.length);
            onChange(newText);
        } else {
            const trimmedText = wordsArray.slice(0, maxWords).join(' ');
            setText(trimmedText);
            setWordCount(maxWords);
            onChange(trimmedText);
        }
    };

    return (
        <div className={cn('default-forms', sx)}>
            <textarea
                value={text}
                onChange={handleChange}
                onPaste={handlePaste}
                onInput={onInput}
                placeholder={placeholder}
                rows={rows || '10'}
                cols={cols || '50'}
                className={
                    className ||
                    'border-secondarybg rounded-xl p-3 text-sm !text-black placeholder:text-[#979797]  font-poppins  w-full focus:border-transparent h-auto'
                }
                required={required || false}
                disabled={disabled || false}
            />
            {!hideCount && (
                <p
                    className={cn(
                        `transition-opacity duration-500 ease-in-out text-sm ${
                            wordCount >= maxWords && ' text-red-900'
                        }`,
                        maxCountsx || ''
                    )}
                >
                    {wordCount}/{maxWords}{' '}
                    {wordCount >= maxWords && <span className="x-small-text"> - Max length reached</span>}
                </p>
            )}
        </div>
    );
};

export default DefaultTextArea;
