import React from 'react';
import { useSelector } from 'react-redux';
import { TaskAssignedIcon } from 'utills/svgs/TaskAssignedIcon';
import { TaskTick } from 'utills/svgs/TaskTick';
import { TasksClock } from 'utills/svgs/TasksClock';

export const TaskNumbersCard = ({ _empInfo }) => {
    const { data: employeeinfo } = useSelector((state) => state.employeeInfoAndTasks);

    return (
        <div className="p-3">
            <div className="profile-number-card p-4 flex gap-5 items-center">
                <div>
                    <TaskAssignedIcon />
                </div>
                <div>
                    {' '}
                    <div className="flex  gap-1 flex-col">
                        <div className="table-title font-poppins"> Assigned Tasks</div>
                        <div className="lg-title font-poppins">{employeeinfo && employeeinfo[0]?.task_assign}</div>
                    </div>
                </div>
            </div>
            <div className="profile-number-card-two p-4 mt-2 flex gap-5 items-center">
                <div>
                    <TaskTick />
                </div>
                <div>
                    {' '}
                    <div className="flex  gap-1 flex-col">
                        <div className="table-title font-poppins"> Completed Tasks</div>
                        <div className="lg-title font-poppins">{employeeinfo && employeeinfo[0]?.task_completed}</div>
                    </div>
                </div>
            </div>

            <div className="profile-number-card-three p-4 mt-2 flex gap-5 items-center">
                <div>
                    <TasksClock />
                </div>
                <div>
                    {' '}
                    <div className="flex  gap-1 flex-col">
                        <div className="table-title font-poppins">Delayed Tasks</div>
                        <div className="lg-title font-poppins">{employeeinfo && employeeinfo[0]?.task_overdue}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
