import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateId } from 'utills/uid';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { EditIcon } from 'utills/svgs/EditIcon';
import Badge from 'components/atoms/Badge';
import { handleTaskDetails, setLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import { taskReopenThunk } from 'store/workspace/workspaceCompletedTasks';
import { BUSINESS_ID, getProfileName, handleDownload, taskPriorityOptions, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { getAllTaskStatusesThunk } from 'store/workspace/workspaceTasks';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { convertDateFormatToLocal, formateDateOnly } from 'utills/moment';
import { Menu, Transition } from '@headlessui/react';
import { BlackLeftIcon } from 'utills/svgs/BlackLeftIcon';
import AvatarGroup from 'components/atoms/AvatarGroup';
import CommandPalettes from 'components/atoms/CommandPalettes';
import { FileIconSvg } from 'utills/svgs/FileIconSvg';
import { Chip } from 'components/atoms/buttons/Chip';
import MediumIcon from 'utills/svgs/MediumIcon';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { Link } from 'react-router-dom';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { NoDataFound } from 'components/common/NoDataFound';

const colors = ['#71b4f7', '#9fb968', ' #b298ff', '#71b4f7', '#f7a561'];

export const TasksList = ({
    toggleTaskDetails,
    tasks,

    reloadTaskPage,
    hideActions,
    id
}) => {
    const { data: taskStatuses } = useSelector((state) => state.getAllTaskStatuses);
    const { isLoading, sideLoader } = useSelector((state) => state.global);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [pdfUrl, setPdfUrl] = useState('');
    const [showImage, setShowImage] = useState('');
    const { hasPermissions } = useRoleGuard();

    const dispatch = useDispatch();
    const [formatTask, setFormatTask] = useState(null);

    const [viewList, setViewList] = useState(false);
    const [allAssignees, setAllAssignees] = useState([]);

    useEffect(() => {
        if (!viewList) {
            setAllAssignees([]);
        }
    }, [viewList]);

    useEffect(() => {
        if (!taskStatuses) {
            dispatch(getAllTaskStatusesThunk({ business_id }));
        }
    }, [taskStatuses]);

    useEffect(() => {
        setFormatTask(tasks);
    }, [tasks]);

    const handleDetails = (task) => {
        dispatch(handleTaskDetails(task));
        toggleTaskDetails();
    };

    const handleReopenTask = (task_id, task_status_id) => {
        if (!hasPermissions('Tasks', ['admin', 'write'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        dispatch(
            taskReopenThunk({
                business_id,
                task_id,
                task_status_id
            })
        )
            .then((_response) => {
                reloadTaskPage();
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleDisplay = (task) => {
        handleDetails(task);
    };

    const renderHtmlContent = (content) => {
        const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, '');
        return strippedContent.split('\n').map((line, index) => <p key={index}>{line}</p>);
    };
    return (
        <div className="mb-5">
            {isLoading && <Loader />}
            {pdfUrl ? (
                <GlobalPDFViewer url={pdfUrl} setOpen={() => setPdfUrl('')} open={pdfUrl ? true : false} />
            ) : (
                ''
            )}{' '}
            {showImage && <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />}
            {!isLoading && formatTask?.length < 1 ? (
                <div className="w-full ">
                    {' '}
                    <NoDataFound message="No Completed Task Found!." height="400px" />
                </div>
            ) : (
                <div className="px-10 min-h-[250px] my-5" id={id}>
                    <div className="bg-white  pb-5 shadow rounded-2xl overflow-x-auto">
                        <div className="w-[150%]  lg:w-full ">
                            <div className=" ">
                                <div className=" border-b-2 border-gray-200  grid grid-cols-12 gap-5 p-5 mx-3">
                                    <div className="text-md font-semibold text-gray-900 col-span-2">Task ID</div>
                                    <div className="text-md font-semibold text-gray-900 ">Created On</div>
                                    <div className="text-md font-semibold text-gray-900 ">Start Date</div>
                                    <div className="text-md font-semibold text-gray-900 ">End Date</div>
                                    <div className="text-md font-semibold text-gray-900">Client Name</div>
                                    <div className="text-md font-semibold text-gray-900">Assigned By</div>
                                    <div className="text-md font-semibold text-gray-900">Assignees</div>
                                    <div className="text-md font-semibold text-gray-900">Status</div>
                                    <div className="text-md font-semibold text-gray-900 col-span-2 text-center">
                                        Completion Date/Time
                                    </div>
                                    {hideActions && <div></div>}
                                </div>
                            </div>
                            <div className="mx-3">
                                {(!formatTask || sideLoader) && (
                                    <SkeletonTable columns={7} logoIndex={0} sxLogo="rounded-md" />
                                )}
                                {!sideLoader &&
                                    formatTask &&
                                    formatTask.length > 0 &&
                                    formatTask?.map((task) => (
                                        <div
                                            key={task?.task_id}
                                            className="rounded-xl border mt-2 hover:border-third py-5 cursor-pointer shadow-md hover:shadow-lg"
                                        >
                                            <div className="grid grid-cols-12 gap-5 px-5 pt-5 mt-3">
                                                <div
                                                    className="!text-md  text-gray-900 col-span-2 cursor-pointer"
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {task?.task_ref_no && (
                                                        <span className="px-3 py-1.5 text-third bg-[#b695f838] !rounded-xl !text-sm">
                                                            Ref Num: {task?.task_ref_no}
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    className="!text-md  text-gray-900 "
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {formateDateOnly(task?.created_at)}
                                                </div>
                                                <div
                                                    className="!text-md  text-gray-900 "
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {formateDateOnly(task.start_date, 'DD-MM-YYYY')}
                                                </div>
                                                <div
                                                    className="!text-md  text-gray-900 "
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {formateDateOnly(task.end_date, 'DD-MM-YYYY')}
                                                </div>
                                                <div
                                                    className="!text-md  text-gray-900 "
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {task?.customer ? getProfileName(task?.customer) : '__'}
                                                </div>
                                                <div
                                                    className="!text-md  text-gray-900 "
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {task?.created_by
                                                        ? task?.created_by?.first_name +
                                                          ' ' +
                                                          task?.created_by?.last_name
                                                        : '_'}
                                                </div>
                                                <div
                                                    className="!text-md  text-gray-900 "
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    <div>
                                                        <div className="task-card-images-wrapper ">
                                                            <AvatarGroup
                                                                list={task?.employees}
                                                                limit={3}
                                                                targetProperty={'image'}
                                                                onClick={() => {
                                                                    setViewList(true);
                                                                    setAllAssignees(task?.employees || []);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`text-md`}
                                                    style={{
                                                        color: colors[
                                                            ((taskStatuses &&
                                                                taskStatuses.findIndex(
                                                                    (status) =>
                                                                        status.task_status_id === task?.task_status_id
                                                                )) ||
                                                                1) % colors.length
                                                        ]
                                                    }}
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {task?.task_status?.label}
                                                </div>
                                                <div
                                                    className="text-md text-gray-900 col-span-2 text-center"
                                                    onClick={() => handleDisplay(task)}
                                                >
                                                    {task?.completed_at && task?.completed_at !== 'null'
                                                        ? convertDateFormatToLocal(
                                                              task?.completed_at,
                                                              'DD-MM-YYYYTHH:mm:ssZ',
                                                              'MMM, DD, YYYY hh:mm A'
                                                          )
                                                        : '--'}
                                                </div>
                                                {!hideActions && (
                                                    <div className="text-md tracking-normal ">
                                                        <div className="cursor-pointer">
                                                            <DropdownMenu>
                                                                <DropdownMenuItem onClick={() => handleDetails(task)}>
                                                                    <div className="flex items-center gap-2">
                                                                        <EditIcon />
                                                                        Details
                                                                    </div>
                                                                </DropdownMenuItem>

                                                                <Menu
                                                                    as="div"
                                                                    className="relative inline-block text-left w-full"
                                                                >
                                                                    <Menu.Button className="flex items-center w-full !group-hover:fill-white !group-hover:text-white !group-hover:stroke-white">
                                                                        <DropdownMenuItem onClick={() => {}}>
                                                                            <div className="flex items-center gap-2 w-full">
                                                                                <ArrowLeftIcon className="w-4 h-4 bg-transparent" />
                                                                                Reopen
                                                                            </div>
                                                                        </DropdownMenuItem>
                                                                    </Menu.Button>
                                                                    <div className="absolute -left-[5px] -top-[10px]">
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {taskStatuses &&
                                                                                        taskStatuses.map(
                                                                                            (taskStatus) => (
                                                                                                <DropdownMenuItem
                                                                                                    onClick={() =>
                                                                                                        handleReopenTask(
                                                                                                            task?.task_id,
                                                                                                            taskStatus?.task_status_id
                                                                                                        )
                                                                                                    }
                                                                                                    key={
                                                                                                        'taskStatus_id' +
                                                                                                        taskStatus?.task_status_id
                                                                                                    }
                                                                                                >
                                                                                                    <div className="flex items-center gap-2">
                                                                                                        <BlackLeftIcon className="w-4 h-4" />
                                                                                                        {
                                                                                                            taskStatus?.label
                                                                                                        }
                                                                                                    </div>
                                                                                                </DropdownMenuItem>
                                                                                            )
                                                                                        )}
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </div>
                                                                </Menu>
                                                            </DropdownMenu>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="!text-md  text-gray-900 px-5 font-semibold mt-1 flex items-start gap-5 flex-wrap"
                                                onClick={() => handleDisplay(task)}
                                            >
                                                <div>
                                                    {task.title
                                                        .slice(0, 40)
                                                        ?.split(' ')
                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(' ')}
                                                </div>
                                                {task?.priority && (
                                                    <Chip
                                                        title={task?.priority}
                                                        iconSet={{
                                                            leftIcon:
                                                                taskPriorityOptions?.find(
                                                                    (obj) => obj.label === task?.priority
                                                                )?.icon || MediumIcon
                                                        }}
                                                        classes={
                                                            'flex items-center gap-1 bg-gray-100 px-3 !text-sm py-1 w-fit'
                                                        }
                                                    />
                                                )}

                                                {task?.job && (
                                                    <Link
                                                        to={`/job-details/${task?.job?.job_id}`}
                                                        className="bg-[#b695f8]/10 text-thirdbg rounded-lg font-poppins text-xs px-2 p-2"
                                                    >
                                                        Job Ref : {task?.job?.ref_no || '--'}
                                                    </Link>
                                                )}
                                            </div>
                                            <div
                                                className="!text-md  px-5 text-secondarybg "
                                                onClick={() => handleDisplay(task)}
                                            >
                                                {renderHtmlContent(task?.description?.slice(0, 140))}
                                                {/* <div>
                                                    {
                                                        <MyComponent
                                                            htmlContent={task?.description
                                                                .slice(0, 140)
                                                                ?.split(' ')
                                                                .map(
                                                                    (word) =>
                                                                        word.charAt(0).toUpperCase() + word.slice(1)
                                                                )
                                                                .join(' ')}
                                                        />
                                                    }
                                                </div> */}
                                            </div>
                                            <div className="flex items-center gap-3 px-3 mt-2 text-secondarybg flex-wrap ">
                                                {task?.attachments &&
                                                    task?.attachments?.map((attachmentObj) => (
                                                        <div key={generateId()}>
                                                            <Badge
                                                                leftIcon={
                                                                    <FileIconSvg className="fill-white stroke-white min-w-5 h-5" />
                                                                }
                                                                atClick={() => {
                                                                    if (
                                                                        attachmentObj?.file_type === 'application/pdf'
                                                                    ) {
                                                                        setPdfUrl(attachmentObj?.url);
                                                                    } else if (
                                                                        attachmentObj?.file_type === 'image/jpg' ||
                                                                        attachmentObj?.file_type === 'image/jpeg' ||
                                                                        attachmentObj?.file_type === 'image/JPG' ||
                                                                        attachmentObj?.file_type === 'image/png'
                                                                    ) {
                                                                        setShowImage(attachmentObj?.url);
                                                                    } else {
                                                                        handleDownload(attachmentObj);
                                                                    }
                                                                }}
                                                                text={attachmentObj?.name || ''}
                                                                sx="text-white bg-third rounded-lg !text-sm"
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <CommandPalettes open={viewList} setOpen={setViewList} list={allAssignees} />
        </div>
    );
};
