import React from 'react';
import { PayrollReportsPage } from '../../components/payrol-pages/payroll-reports/PayrollReportsPage';
export const PayrollReports = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <PayrollReportsPage />
            </div>
        </div>
    );
};
