/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Document as PDFDocument, Page as PDFPage, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { PDF_VIEWER_PAGE_SELECTOR, cn } from './hooks/helper';
import { WhitePlus } from 'utills/svgs/WhitePlus';
import { WhiteMinus } from 'utills/svgs/WhiteMinus';
import { FitIcon } from 'utills/svgs/FitIcon';

/**
 * This imports the worker from the `pdfjs-dist` package.
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PDFViewer = ({
    className,
    left,
    documentData,
    onDocumentLoad,
    onPageClick,
    handleExpand,
    isExpand,
    hideExpand,
    handleScale,
    scalValue,
    ...props
}) => {
    const $el = useRef();

    const [isDocumentBytesLoading, setIsDocumentBytesLoading] = useState(false);
    const [documentBytes, setDocumentBytes] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [width, setWidth] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [pdfError, setPdfError] = useState(false);
    const [scale, setScale] = useState(1);

    const handleZoomIn = () => {
        if (scale < 2) {
            if (handleScale) {
                handleScale(scalValue + 0.1);
            }
            setScale(scale + 0.1);
        }
    };

    const handleZoomOut = () => {
        if (handleScale) {
            handleScale(Math.max(scale - 0.1, 0.1));
        }
        setScale(Math.max(scale - 0.1, 0.1));
    };

    const zoomPercentage = Math.round(scale * 100);
    const handleFit = () => {
        if (handleExpand) {
            handleExpand();
        }
    };
    const memoizedData = useMemo(() => ({ data: documentData }), [documentData]);

    const isLoading = isDocumentBytesLoading || !documentBytes;

    const onDocumentLoaded = (doc) => {
        setNumPages(doc.numPages);
        onDocumentLoad?.(doc);
    };

    const onDocumentPageClick = (event, pageNumber) => {
        const $el = event.target instanceof HTMLElement ? event.target : null;
        if (!$el) {
            return;
        }

        const $page = $el.closest(PDF_VIEWER_PAGE_SELECTOR);
        if (!$page) {
            return;
        }

        const { height, width, top, left } = $page.getBoundingClientRect();

        const pageX = event.clientX - left;
        const pageY = event.clientY - top;
        if (onPageClick) {
            void onPageClick({
                pageNumber,
                numPages,
                originalEvent: event,
                pageHeight: height,
                pageWidth: width,
                pageX,
                pageY
            });
        }
    };

    useEffect(() => {
        if ($el.current) {
            const $current = $el.current;

            const { width } = $current.getBoundingClientRect();

            setWidth(width);

            const onResize = () => {
                const { width } = $current.getBoundingClientRect();

                setWidth(width);
            };

            window.addEventListener('resize', onResize);

            return () => {
                window.removeEventListener('resize', onResize);
            };
        }
    }, []);

    useEffect(() => {
        setDocumentBytes(documentData);
        setIsDocumentBytesLoading(true);
    }, [memoizedData]);

    const goToPreviousPage = () => {
        setPageNumber(pageNumber - 1);
        setTimeout(() => {
            window.scrollTo({
                top: -(document.documentElement.scrollHeight - window.innerHeight) * 0.8,
                behavior: 'smooth'
            });
        }, 0);
    };

    const goToNextPage = () => {
        setPageNumber(pageNumber + 1);
        setTimeout(() => {
            window.scrollTo({
                top: (document.documentElement.scrollHeight - window.innerHeight) * 0.8,
                behavior: 'smooth'
            });
        }, 0);

        //setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
    };

    const PDFLoader = () => (
        <>
            <p className="text-muted-foreground mt-4">Loading document...</p>
        </>
    );

    return (
        <div ref={$el} className={cn('overflow-hidden    ', className)} {...props}>
            <div style={{ left: left && left }} className="pdf-actions rounded-xl shadow ">
                <div className="flex gap-3  items-center">
                    <button onClick={handleZoomIn}>
                        <WhitePlus />
                    </button>
                    <div className="bg-white text-black px-2 p-1 rounded-md"> {zoomPercentage} %</div>
                    <button onClick={handleZoomOut}>
                        <WhiteMinus />
                    </button>{' '}
                    {hideExpand ? (
                        ''
                    ) : (
                        <div className="flex gap-2 items-center">
                            {' '}
                            |
                            <button onClick={() => handleFit()} className="flex gap-2 items-center">
                                {' '}
                                <FitIcon />
                                {isExpand ? 'Fit' : 'Expand'}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <PDFDocument
                file={documentData}
                className={cn('w-full overflow-hidden rounded  ', {
                    'h-[80vh] max-h-[60rem]': numPages === 0
                })}
                onLoadSuccess={(d) => onDocumentLoaded(d)}
                onSourceError={() => {
                    setPdfError(true);
                }}
                externalLinkTarget="_blank"
                loading={
                    <div className="dark:bg-background flex h-[80vh] max-h-[60rem] flex-col items-center justify-center bg-white/50">
                        {pdfError ? (
                            <div className="text-muted-foreground text-center">
                                <p>Something went wrong while loading the document.</p>
                                <p className="mt-1 text-sm">Please try again or contact our support.</p>
                            </div>
                        ) : (
                            <PDFLoader />
                        )}
                    </div>
                }
                error={
                    <div className="dark:bg-background flex h-[80vh] max-h-[60rem] flex-col items-center justify-center bg-white/50">
                        <div className="text-muted-foreground text-center">
                            <p>Something went wrong while loading the document.</p>
                            <p className="mt-1 text-sm">Please try again or contact our support.</p>
                        </div>
                    </div>
                }
            >
                {Array(numPages)
                    .fill(null)
                    .map((_, i) => (
                        <div
                            key={i}
                            className="border-border relative  my-8 overflow-hidden rounded border will-change-transform first:mt-0 last:mb-0"
                        >
                            <PDFPage
                                pageNumber={i + 1}
                                width={width}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                loading={() => ''}
                                onClick={(e) => onDocumentPageClick(e, i + 1)}
                                scale={scale}
                            />
                        </div>
                    ))}
            </PDFDocument>
        </div>
    );
};

export default PDFViewer;
