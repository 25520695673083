import React from 'react';

const NotificationIcon = ({ className }) => {
    return (
        <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M27.2983 21.6317L28.348 22.5363V23.4364H1.63281V22.5763L2.81501 21.6552L2.84868 21.6289L2.87917 21.599C3.83453 20.6621 4.27341 19.1902 4.58303 17.6596C4.72974 16.9343 4.85455 16.1517 4.98199 15.3525C4.99242 15.2872 5.00286 15.2217 5.01333 15.1561C5.15285 14.2827 5.29909 13.3853 5.48663 12.4802C6.21943 8.962 8.15709 6.63059 9.9235 5.17019C10.8077 4.43914 11.6457 3.92961 12.2597 3.60417C12.5663 3.44166 12.8158 3.32571 12.9856 3.25149C13.0705 3.2144 13.1354 3.18778 13.1774 3.17104C13.1984 3.16267 13.2137 3.15677 13.2229 3.15327L13.2318 3.14989L13.232 3.14981L13.2324 3.14968L13.2328 3.14954L13.2331 3.14941L13.725 2.97015V2.44522C13.725 1.78609 14.2823 1.21875 14.9904 1.21875C15.6985 1.21875 16.2558 1.78609 16.2558 2.44522V2.97441L16.7531 3.15138L16.7534 3.15149L16.7538 3.15163L16.7543 3.15181L16.7543 3.15182L16.7639 3.15535C16.7734 3.1589 16.7892 3.16484 16.8108 3.17326C16.8541 3.19011 16.9208 3.21683 17.0079 3.25403C17.1824 3.32848 17.4383 3.44468 17.7526 3.60746C18.3819 3.93347 19.2399 4.44359 20.144 5.17518C21.9505 6.63707 23.9236 8.96731 24.6553 12.4798C24.8429 13.39 24.9891 14.291 25.1286 15.1669C25.1385 15.2288 25.1483 15.2906 25.1581 15.3523C25.2862 16.1577 25.4115 16.9455 25.559 17.6744C25.8684 19.2041 26.3075 20.6744 27.2663 21.6025L27.2819 21.6175L27.2983 21.6317ZM18.1818 26.9129C17.8405 28.3183 16.5585 29.3658 14.9904 29.3658C13.4222 29.3658 12.1403 28.3183 11.799 26.9129H18.1818Z"
                stroke="url(#paint0_linear_10308_3110)"
                strokeWidth="1.5"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_10308_3110"
                    x1="1.31944"
                    y1="0.468774"
                    x2="28.9836"
                    y2="1.53606"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default NotificationIcon;
