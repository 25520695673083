/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './css/completeTaskDetails.css';
import { OrangeUserIcon } from 'utills/svgs/OrangeUserIcon';
import { formateDate, formateDateAndTime } from 'utills/moment';
import Activity from 'components/workplace-content/tasks-pages/task-details/Activity';
import { Biglink } from 'utills/svgs/Biglink';
import { useDispatch, useSelector } from 'react-redux';
import { generateId } from 'utills/uid';
import { ChecklistIcon } from 'utills/svgs/ChecklistIcon';
import { OrangeCalendar } from 'utills/svgs/OrangeCalendar';
import { OrangeEmail } from 'utills/svgs/OrangeEmail';
import { OrangeTel } from 'utills/svgs/OrangeTel';
import { OrangeHash } from 'utills/svgs/OrangeHash';
import ProgressBar from '@ramonak/react-progress-bar';
import { dynamicWidthgenerator } from 'utills/dynamicWidthGenerator';
import { useTaskBarColor } from 'components/workplace-content/tasks-pages/task-details/hooks/useTaskBarColor';
import { getActivityThunk, getSingleTaskThunk } from 'store/workspace/workspaceTasks';
import { setSideLoader } from 'store/global/globalReducer';
import { cn } from 'utills/tailwindUtil';
import defaultUser from 'files/default_user.png';
import Slideover from 'components/atoms/SlideOver';
import { handleDownload } from 'utills/globalVars';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import pdfIcon from 'files/pdfIcon.svg';
import { Truncate } from 'components/molecules/Truncate';
import { Link } from 'react-router-dom';

export const CompleteTaskDetails = ({ open, setOpen, toggleTaskDetails }) => {
    const [taskDetail, setTaskDetail] = useState(null);
    const [openpdf, setOpenpdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [isZoomed, setIsZoomed] = useState(false);

    function MyComponent({ htmlContent }) {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: htmlContent.concat(htmlContent.length > 40 ? '...' : '')
                }}
            />
        );
    }
    const dispatch = useDispatch();
    const { task } = useSelector((state) => state.global);
    const getSingleTask = () => {
        const payload = {
            task_id: task.task_id,
            business_id: task.business_id
        };
        dispatch(getSingleTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setTaskDetail(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                fetchActivity();
            });
    };
    const fetchActivity = () => {
        dispatch(setSideLoader(true));
        dispatch(
            getActivityThunk({
                task_id: task?.task_id
            })
        )
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    useEffect(() => {
        getSingleTask();
    }, []);

    const handleChecklistProgress = (task_checklist_id) => {
        if (taskDetail) {
            const checklist = taskDetail?.checklists?.find((list) => list.task_checklist_id === task_checklist_id);
            const total = checklist.checkListItems.length;
            const completed = checklist.checkListItems.filter((items) => items.is_completed === true).length;
            const totalres = 100 / total;
            const result = completed * totalres;

            return Math.ceil(result);
        }
    };
    const { color } = useTaskBarColor(taskDetail ? taskDetail : {});

    const getName = (obj) => {
        return obj?.first_name && obj?.first_name !== ''
            ? obj?.first_name + ' ' + (obj?.last_name || '')
            : obj?.display_name;
    };

    const getClickProfileLink = (customer = null) => {
        if (customer && customer?.business_client_id !== '') {
            return '/app/user/profile/' + customer?.business_client_id;
        } else {
            return false;
        }
    };

    return (
        <>
            <Slideover open={open || false} setOpen={setOpen} size={'max-w-7xl'}>
                {openpdf && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpenpdf} open={openpdf} /> : ''}

                <div>
                    <div className="add-detail px-5 text-black">
                        <div className="page-title">Task Details</div>

                        <div className="jumbo-dir mt-2">
                            Tasks &gt; Completed Tasks
                            <span className="special-jumbo-text"> &gt; Task Details</span>
                        </div>
                    </div>

                    <div className="m-5 relative">
                        {taskDetail && (
                            <div className="grid md:grid-cols-3 gap-5">
                                <div className="col-span-2">
                                    <div className="task-detail-main-card add-ann-form bg-white  rounded-md p-5 ">
                                        <div className="flex gap-2 items-center">
                                            {' '}
                                            <div className="task-title detail-task-title">{taskDetail?.title}</div>{' '}
                                        </div>

                                        <div className="detail-description flex gap-2 w-full relative mt-2">
                                            <div className="flex-1 w-[80%] break-all">
                                                {task?.description && (
                                                    <Truncate text={task?.description} maxLength={250} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {taskDetail?.checklists?.map((checklist, listIndex) => (
                                        <div
                                            className="task-detail-main-card text-black  mt-5 p-5 rounded-md"
                                            key={generateId()}
                                        >
                                            <div className="flex justify-between items-center">
                                                <div className="flex gap-2 items-center">
                                                    <div>
                                                        {' '}
                                                        <ChecklistIcon />
                                                    </div>
                                                    <div>
                                                        <label>Checklist</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <div>
                                                    <div className="my-2 mt-5 add-ann-form grid grid-cols-12 gap-2 place-content-center">
                                                        {' '}
                                                        <div className="col-span-11 pt-1">
                                                            {' '}
                                                            <ProgressBar
                                                                completed={handleChecklistProgress(
                                                                    checklist.task_checklist_id
                                                                )}
                                                                bgColor="#b695f8"
                                                                height="5px"
                                                                width="100%"
                                                                animateOnRender={true}
                                                                isLabelVisible={false}
                                                            />
                                                        </div>
                                                        <div className="assigned-people-detail">
                                                            {' '}
                                                            {handleChecklistProgress(checklist.task_checklist_id)}%
                                                        </div>
                                                    </div>

                                                    <div className="checklist-title">{checklist?.title} </div>

                                                    {checklist?.checkListItems &&
                                                        [...checklist?.checkListItems]
                                                            ?.sort((a, b) => {
                                                                if (a?.sequence == null && b?.sequence == null)
                                                                    return 0;
                                                                if (a?.sequence == null) return 1;
                                                                if (b?.sequence == null) return -1;
                                                                return Number(a?.sequence) - Number(b?.sequence);
                                                            })
                                                            ?.map((check, index) => (
                                                                <div
                                                                    key={'checklist' + index}
                                                                    className="flex justify-between items-center"
                                                                >
                                                                    <div className="flex gap-2  task-check items-center">
                                                                        <div>
                                                                            <input
                                                                                className=" !rounded-[5px] h-[20px] w-[20px] !border-[#979797] !text-gray-800 focus:ring-gray-800 bg-gray-300"
                                                                                type="checkbox"
                                                                                disabled
                                                                                checked={
                                                                                    check.is_completed ? true : false
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className={cn(
                                                                                'detail-description',
                                                                                check.is_completed ? 'cross-text' : ''
                                                                            )}
                                                                        >
                                                                            {' '}
                                                                            {check?.value}{' '}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="task-detail-main-card mt-5 text-black p-5 add-ann-form rounded-md">
                                        <div className="flex justify-between items-center">
                                            <div className="flex gap-2 items-center">
                                                <div>
                                                    {' '}
                                                    <Biglink />
                                                </div>
                                                <div>
                                                    <label>Attachments</label>
                                                </div>
                                            </div>
                                            <div> </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="grid grid-cols-2 gap-2">
                                                {taskDetail?.attachments?.length > 0 &&
                                                    taskDetail?.attachments?.map((att, index) => (
                                                        <div key={'thunk' + index}>
                                                            <div
                                                                className={`attachment-card grid grid-cols-2 gap-5 col-span-2 md:col-span-1 ${
                                                                    index > 0 && ''
                                                                }`}
                                                                key={index}
                                                            >
                                                                <div className="col-span-2 md:col-span-1 p-2">
                                                                    <div className="attachment-file-container">
                                                                        {att?.file_type.includes('/pdf') ? (
                                                                            <div
                                                                                onClick={() => {
                                                                                    if (
                                                                                        att?.url
                                                                                            ?.toLowerCase()
                                                                                            ?.endsWith('.pdf')
                                                                                    ) {
                                                                                        setPdfUrl(att?.url);
                                                                                        setOpenpdf(true);
                                                                                    }
                                                                                }}
                                                                                className="pdf-container"
                                                                            >
                                                                                <div className="pdf-file"> PDF </div>
                                                                            </div>
                                                                        ) : att?.file_type.includes('image') ? (
                                                                            <>
                                                                                <img
                                                                                    src={att?.url || pdfIcon}
                                                                                    alt="profile"
                                                                                    className="attchement-file"
                                                                                    onClick={() => setIsZoomed(true)}
                                                                                />
                                                                                {isZoomed && att?.url && (
                                                                                    <ZoomImage
                                                                                        src={att?.url}
                                                                                        alt="Profile"
                                                                                        onClose={() =>
                                                                                            setIsZoomed(false)
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <div
                                                                                onClick={() => handleDownload(att)}
                                                                                className="pdf-container cursor-pointer"
                                                                            >
                                                                                <div className="pdf-file">
                                                                                    {att.file_type}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-2 md:col-span-1 items-center self-center">
                                                                    <div className="flex flex-col items-center justify-center p-2">
                                                                        <label className="buttons-font font-poppins">
                                                                            {att?.name.slice(0, 10)}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="task-detail-main-card mt-5 text-black p-5 rounded-xl">
                                        <div className="flex justify-between items-center">
                                            <div className="flex gap-2 items-center">
                                                <div>
                                                    {' '}
                                                    <Biglink />
                                                </div>
                                                <div>
                                                    <label className="table-title">Personal Notes</label>
                                                </div>
                                            </div>
                                            <div> </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="detail-personal_note buttons-font-lighter flex gap-2  relative mt-4">
                                                {task?.personal_note && (
                                                    <Truncate text={task?.personal_note} maxLength={250} />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {taskDetail && <Activity task={taskDetail} fetchActivity={fetchActivity} />}
                                    </div>
                                </div>
                                <div className="col-span-1 ">
                                    <div className="add-ann-form text-black task-detail-main-card p-5 w-72 md:w-full rounded-md relative">
                                        <label>Assigned To</label>
                                        <div className="mt-2 task-assigned-people ">
                                            <div className="task-images-wrapper items-center relative">
                                                <div className="task-images-stack  ">
                                                    <div className="assigned-images-wrapper">
                                                        {taskDetail?.employees?.length &&
                                                            taskDetail?.employees?.map((emp, index) => (
                                                                <img
                                                                    key={index}
                                                                    src={emp?.image ?? defaultUser}
                                                                    alt="taskcommentpicture"
                                                                    className="taskImage"
                                                                />
                                                            ))}

                                                        <label> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-2 mt-5">
                                            {' '}
                                            <ProgressBar
                                                completed={dynamicWidthgenerator(
                                                    taskDetail?.start_date,
                                                    taskDetail?.end_date
                                                )}
                                                bgColor={color}
                                                height="5px"
                                                width="100%"
                                                animateOnRender={true}
                                                isLabelVisible={false}
                                            />
                                        </div>
                                        <div className="progress-date flex justify-between items-center ">
                                            <div>{formateDate(taskDetail?.start_date)}</div>
                                            <div>{formateDate(taskDetail?.end_date)}</div>
                                        </div>

                                        <div className="assigned-details-people mt-5 text-black">
                                            <div className="flex gap-3 items-center">
                                                <div>
                                                    <OrangeUserIcon />
                                                </div>
                                                <div>
                                                    <label>Created By</label>
                                                    <div className="assigned-people-detail">
                                                        {taskDetail?.created_by?.first_name}{' '}
                                                        {taskDetail?.created_by?.last_name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 mt-3 items-center">
                                                <div>
                                                    <OrangeCalendar />
                                                </div>
                                                <div>
                                                    <label>Updated On</label>
                                                    <div className="assigned-people-detail">
                                                        {formateDateAndTime(taskDetail?.updated_at)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 mt-3 items-center">
                                                <div>
                                                    <OrangeUserIcon />
                                                </div>
                                                <div>
                                                    <label>Client Name</label>
                                                    <Link to={getClickProfileLink(taskDetail?.customer)}>
                                                        <div className="assigned-people-detail">
                                                            {getName(taskDetail?.customer)}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 mt-3 items-center">
                                                <div>
                                                    <OrangeEmail />
                                                </div>
                                                <div>
                                                    <label>Client Email</label>
                                                    <Link to={getClickProfileLink(taskDetail?.customer)}>
                                                        <div className="assigned-people-detail">
                                                            {taskDetail?.customer?.email}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="flex gap-3 mt-3 items-center">
                                                <div>
                                                    <OrangeTel />
                                                </div>
                                                <div>
                                                    <label>Client Phone No</label>
                                                    <Link to={getClickProfileLink(taskDetail?.customer)}>
                                                        <div className="assigned-people-detail">
                                                            {taskDetail?.customer?.phone_number}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="flex gap-3 mt-3 items-center">
                                                <div>
                                                    <OrangeHash />
                                                </div>
                                                <div>
                                                    <label>Client Reference No</label>
                                                    <Link to={getClickProfileLink(taskDetail?.customer)}>
                                                        <div className="assigned-people-detail">
                                                            {taskDetail?.reference_number}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Slideover>{' '}
        </>
    );
};
