import { useDispatch } from 'react-redux';
import { getBusinessprofileThunk } from 'store/auth/slices';

export const useGetBusinessProfile = () => {
    const dispatch = useDispatch();

    const fetchBusiness = () => {
        dispatch(getBusinessprofileThunk());
    };

    return { fetchBusiness };
};
