import React from "react";
import StepThree from "../../components/signup-steps/StepThree";

export const SignupSecurityQuestions = () => {
  return (
    <div>
      <StepThree />
    </div>
  );
};
