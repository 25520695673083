import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setSideLoader } from 'store/global/globalReducer';
import { getCookie } from 'utills/encryption/cryptoUtils';
import { APP_ENVIRONMENT, BASE_URL, CSRF_TOKEN } from 'utills/globalVars';

export const useDownloadZip = () => {
    const dispatch = useDispatch();
    const handleDownloadZip = async (payload, { endpoint = `exp-inc/download/all-zip`, method = 'POST' }) => {
        dispatch(setSideLoader(true));

        let csrfToken = getCookie(CSRF_TOKEN);

        let defaultURL = `${BASE_URL}/${endpoint}`;

        if (payload && ['GET', 'DELETE'].includes(method)) {
            defaultURL = `${defaultURL}?${new URLSearchParams(payload).toString()}`;
        }

        let payloadJSON = {
            method: method,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            credentials: 'include',

            body: JSON.stringify(payload)
        };

        if (['GET', 'DELETE'].includes(method)) {
            delete payloadJSON.body;
        }

        const _response = await fetch(defaultURL, payloadJSON)
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = payload?.ref_no ? `${payload?.ref_no}_csv` : 'file';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    toastHandler('File Has been downloaded!', 'success');
                } else {
                    toastHandler('No File to download!', 'error');
                }
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    return { handleDownloadZip };
};
