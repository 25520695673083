import React from 'react';
import dayjs from 'dayjs';
import { createContext, useContext, useState } from 'react';
const SigningContext = createContext(null);

export const useSigningContext = () => {
    return useContext(SigningContext);
};

export const useRequiredSigningContext = () => {
    const context = useSigningContext();

    if (!context) {
        throw new Error('Signing context is required');
    }

    return context;
};

export const SigningProvider = ({
    fullName: initialFullName,
    email: initialEmail,
    signature: initialSignature,
    date: currentDateAndTime,
    children
}) => {
    const [fullName, setFullName] = useState(initialFullName || '');
    const [email, setEmail] = useState(initialEmail || '');
    const [signature, setSignature] = useState(initialSignature || null);
    const [date, setDate] = useState(currentDateAndTime || dayjs().format('DD/MM/YYYY'));

    return (
        <SigningContext.Provider
            value={{
                fullName,
                setFullName,
                email,
                setEmail,
                signature,
                setSignature,
                date,
                setDate
            }}
        >
            {children}
        </SigningContext.Provider>
    );
};

SigningProvider.displayName = 'SigningProvider';
