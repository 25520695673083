import React from "react";

export const BgMessageIcon = () => {
  return (
    <div>
      {" "}
      <svg
        width="26"
        height="22"
        viewBox="0 0 36 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect opacity="0.1" width="36" height="30" rx="15" fill="#B695F8" />
        <path
          d="M26 5H10C8.9 5 8 5.9 8 7V25L12 21H26C27.1 21 28 20.1 28 19V7C28 5.9 27.1 5 26 5ZM26 19H12L10 21V7H26V19ZM13 13C13 12.4477 13.4477 12 14 12C14.5523 12 15 12.4477 15 13C15 13.5523 14.5523 14 14 14C13.4477 14 13 13.5523 13 13ZM17 13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13C19 13.5523 18.5523 14 18 14C17.4477 14 17 13.5523 17 13ZM21 13C21 12.4477 21.4477 12 22 12C22.5523 12 23 12.4477 23 13C23 13.5523 22.5523 14 22 14C21.4477 14 21 13.5523 21 13Z"
          fill="#B695F8"
        />
      </svg>
    </div>
  );
};
