/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/atoms/buttons/Button';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { googleAuthAccessThunk, verifyGoogleAuthAccessThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { BackArrow } from 'utills/svgs/BackArrow';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';

export const VerifyGoogleAuth = (props) => {
    const dispatch = useDispatch();
    const [otpVal, setOtpVal] = useState('');
    const base64String = useSelector((state) => state?.googleAuthAccess?.data?.otpauth_url);
    const { data: loginData } = useSelector((state) => state.login);
    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    const [code, setCode] = useState('');

    const verifyGoogleData = {
        user_id: loginData.user_id,
        user_type: 'employee',
        code: otpVal
    };

    const handleOtpSubmit = () => {
        if (otpVal.length < 6) {
            return toastHandler('Please enter full otp', 'error');
        }

        dispatch(setLoader(true));
        dispatch(verifyGoogleAuthAccessThunk(verifyGoogleData))
            .then((response) => {
                if (response.payload) {
                    props.handleCondition(5);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const googleAuthData = {
        user_id: loginData?.user_id,
        user_type: 'employee'
    };
    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(googleAuthAccessThunk(googleAuthData))
            .then((response) => {
                if (response.payload) {
                    setCode(response.payload.code);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    return (
        <div className="md:flex justify-center flex-wrap  items-center w-[100%] min-h-[60vh] gap-2 overflow-y-auto relative">
            <div className="white  shadow-md max-w-[630px] rounded-lg min-h-[60vh]  bg-white p-3">
                <div>
                    <div className="one-head   flex   ">
                        <div className="one-head px-3 pt-2 flex   ">
                            <div className="arrow-wrapper mt-1 cursor-pointer" onClick={() => props.handleCondition(3)}>
                                <BackArrow />
                            </div>
                            <div>
                                {' '}
                                <h1>Google Authenticator</h1>
                            </div>
                        </div>
                    </div>

                    <div className="email-text flex  mx-5 gap-2 mt-3">
                        <div>
                            <div className="mt-1">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span className="text-sixth font-poppins small-text ">
                                Please use your authentication app (such as Duo or Google Authenticator) to activate the
                                Google Authentication..
                            </span>
                        </div>
                    </div>

                    <div className="flex justify-center items-center  mt-10">
                        {base64String && <img src={base64String} alt="qrcode" className="scale-125" />}
                    </div>

                    <div className="text-3xl font-poppins my-5 flex justify-center items-center">
                        <div>
                            <div>
                                <div className='"flex gap-5 items-center'>
                                    {' '}
                                    <span> {code?.slice(0, 4)} </span>
                                    <span> {code?.slice(4, 8)} </span>
                                    <span> {code?.slice(8, 12)} </span>
                                    <span> {code?.slice(12, 16)} </span>
                                    <span> {code?.slice(16, code?.length)} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="buttons-font  px-5 mt-10">
                        Enter the 6-digit authentication code generated by your app:
                    </div>

                    <div className="text-sixth font-poppins small-text  px-5">
                        Upon scanning the QR code, the app will present a 6-digit code for you to input below.
                    </div>

                    <div className="mt-12 mx-5 flex justify-center items-center">
                        <OtpInputs value={otpVal} onChange={handleOtpChange} />
                    </div>

                    <div className="flex justify-center mb-5 items-center gap-3 mt-14">
                        <Button
                            atClick={() => props.handleCondition(3)}
                            classes="border border-secondarybg text-secondarybg buttons-font font-fira h-[3rem] px-9 rounded-lg text-center "
                            title="Cancel"
                        />

                        <Button
                            classes="ann-btn buttons-font font-fira  px-10 py-2 rounded-lg"
                            title="Enable"
                            atClick={handleOtpSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
