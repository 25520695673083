import React from 'react';
import { useState, useEffect } from 'react';
import DeductionList from './DeductionList';
import DeductionOperation from './DeductionOperation';
import './css/deduction.css';
import { getAllDeductionsThunk } from 'store/workspace/workspaceDeduction';
import { BUSINESS_ID } from 'utills/globalVars';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { NoDataFound } from 'components/common/NoDataFound';
import { AddDeductionIcon } from 'utills/svgs/AddDeductionIcon';
import { Jumbotron } from 'components/organisms/Jumbotron';

const DeductionPage = ({ toggleAddDeduction, deductionUpdated, toggleEditDeduction }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data } = useSelector((state) => state.getAllDeductions);

    const [searchTerm, setSearchValue] = useState('');

    function searchByItem(value) {
        setSearchValue(value);
    }

    const filteredDeductions = data?.filter((row) => row.title.toLowerCase().includes(searchTerm.toLowerCase()));

    const getAllDeductions = (payload) => {
        dispatch(setLoader(true));
        dispatch(getAllDeductionsThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        getAllDeductions({
            business_id
        });
    }, [deductionUpdated]);

    const managerActions = [
        {
            title: 'Add Deduction',
            classes: 'flex gap-2 items-center px-8 !rounded-xl ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: AddDeductionIcon },
            onAction: () => {
                toggleAddDeduction();
            }
        }
    ];

    return (
        <div>
            <Jumbotron
                title="Deductions"
                path={[
                    { title: 'Workspace', link: 'workspace' },
                    { title: 'Deductions', link: 'deduction' }
                ]}
                actionsRequired={true}
                actions={managerActions}
            />
            <div>
                <DeductionOperation searchByItem={searchByItem} />
            </div>
            <div>
                {!filteredDeductions?.length ? (
                    <NoDataFound message="No record found!" height="30vh" />
                ) : (
                    <DeductionList
                        toggleEditDeduction={toggleEditDeduction}
                        filteredDeductions={filteredDeductions}
                        getAllDeductions={getAllDeductions}
                    />
                )}
            </div>
        </div>
    );
};

export default DeductionPage;
