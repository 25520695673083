import React from 'react';

export const Switch = ({ condition, atChange }) => {
    return (
        <div>
            {' '}
            <div className="flex items-center">
                <input
                    checked={condition ? true : false}
                    className="os-react-switch-checkbox"
                    id={`HandleToggle- react-switch-new`}
                    type="checkbox"
                    onChange={() => {
                        atChange();
                    }}
                />

                <label
                    style={{
                        background: condition ? '#b695f8' : ''
                    }}
                    className="react-switch-label"
                    htmlFor={`react-switch-new`}
                    onClick={() => {
                        atChange();
                    }}
                >
                    <span className={`react-switch-button`} />
                </label>
            </div>
        </div>
    );
};
