import React from 'react';
import { useEffect, useState } from 'react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { cn } from 'utills/tailwindUtil';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { BUSINESS_ID, generateRandomProfileLink } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import InfiniteScrollComp from '../InfiniteScrollComp';
import { SmallLoader } from 'components/common/SmallLoader';
import { CompoboxInput } from 'components/molecules/CompoboxInput';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import TooltipChipWrap from '../tooltipChip/TooltipChipWrap';

const ComboboxComponentSSR = ({
    selectedValue,
    defaultSelected = '',
    onChange,
    targetProperty,
    limit,
    sx,
    placeholder,
    showNameOnly,
    disabled,
    inputsx,
    removePreviousSelected,
    onRemove,
    showTaskDetails,

    fetchDataFunction,

    filterSelectedValues,
    hideSelectedValues,
    filterSelectedValueTargetElement,
    filterSelectedValueTargetIndex,
    preventLoadingInfo,
    forceUpdate,
    showDesignation,

    handleDataWithCustomValidators
}) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [selectedItems, setSelectedItems] = useState([]);

    const [query, setQuery] = useState(null);
    const [page, setPage] = useState(1);
    const [listData, setListData] = useState(null);
    const [hasMoreContent, setHasMoreContent] = useState(true);

    const [valuesToFilter, setValuesToFilter] = useState(null);

    useEffect(() => {
        if (filterSelectedValues) {
            setValuesToFilter(filterSelectedValues);
        }
    }, [filterSelectedValues]);

    const dispatch = useDispatch();

    useEffect(() => {
        getAllData();
    }, [page, forceUpdate]);

    useEffect(() => {
        if (query !== null) {
            reloadDataPage();
        }
    }, [query]);

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const getAllData = async (hasReset = false) => {
        const payload = {
            business_id,
            page: page,
            page_size: 20,
            search_term: query
        };
        setHasMoreContent(true);

        try {
            const response = await fetchDataFunction(payload);
            if (response.payload) {
                const cloneData = [...(!hasReset ? listData || [] : []), ...response.payload.rows];
                setListData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const reloadDataPage = () => {
        setPage(1);
        getAllData(true);
        setListData(null);
    };

    useEffect(() => {
        if (selectedValue === '') {
            setSelectedItems([]);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (defaultSelected) {
            handleDefaultSelect(defaultSelected);
        }
    }, [defaultSelected]);

    const handleDefaultSelect = async (defaultSelected = '') => {
        if (defaultSelected !== '') {
            const selectedObject = await fetchCustomer(defaultSelected);

            if (selectedObject.payload) {
                const dummyState = [...(removePreviousSelected ? [] : selectedItems), selectedObject.payload];
                setSelectedItems(dummyState);
                if (preventLoadingInfo) {
                    onChange(dummyState);
                }
                setQuery('');
            }
        } else {
            setSelectedItems([]);
            onChange([]);
            setQuery('');
        }
    };

    const fetchCustomer = async (id) => {
        const payload = {
            business_client_id: id
        };
        return await dispatch(viewBusinessCustomerInfoThunk(payload));
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && listData.length > 0) {
            if (limit && selectedItems.length === limit) {
                return false;
            } else {
                if (!hideSelectedValues) {
                    const dummyState = [...selectedItems, listData[0]];
                    setSelectedItems(dummyState);
                    onChange(dummyState);
                    setQuery('');
                } else {
                    onChange([listData[0]]);
                    setQuery('');
                }
                event.preventDefault();
            }
        }
    };

    const getName = (obj) => {
        return obj?.first_name && obj?.first_name !== '' ? obj?.first_name + ' ' + obj?.last_name : obj?.display_name;
    };
    return (
        <Combobox as="div" disabled={disabled}>
            <div className="relative default-forms">
                <div
                    className={cn(
                        '!rounded-xl border-0 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 px-2 mt-2 overflow-hidden',
                        sx || '',
                        selectedItems.length > 0 && !showNameOnly ? '' : '',
                        disabled ? '!bg-[#dfdfdd]' : ''
                    )}
                >
                    {selectedItems.length > 0 && (
                        <div className="flex flex-wrap gap-2 py-[0.5rem] min-h-[3rem]">
                            {showNameOnly && (
                                <div className="flex w-full items-center">
                                    <div className="flex w-fit items-center bg-third rounded-lg px-2 mr-1 flex-nowrap py-1">
                                        <div className="flex items-center">
                                            <TooltipChipWrap
                                                title={
                                                    <div className="overflow-y-auto max-h-[300px] max-w-[320px]">
                                                        <div className="flex flex-col gap-2 justify-center">
                                                            <div className="flex  items-center bg-third rounded-lg px-2 py-2 w-full justify-between">
                                                                <div className="flex items-center">
                                                                    <DefaultProfileViewer
                                                                        width={30}
                                                                        height={30}
                                                                        image={
                                                                            selectedItems[0]?.image ||
                                                                            generateRandomProfileLink(
                                                                                getName(selectedItems[0])
                                                                            )
                                                                        }
                                                                        atClick={() => {}}
                                                                        name={getName(selectedItems[0])}
                                                                    />
                                                                    <div>
                                                                        <span className="ml-1 text-white text-sm">
                                                                            {getName(selectedItems[0])}
                                                                        </span>
                                                                        <br />
                                                                        <span className="ml-1 text-white text-sm">
                                                                            {selectedItems[0]?.email || '---'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="ml-1 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                    onClick={() => {
                                                                        if (onRemove) {
                                                                            const getDeletedObj = selectedItems.find(
                                                                                (prevItem) =>
                                                                                    prevItem?.[targetProperty] ===
                                                                                    selectedItems[0]?.[targetProperty]
                                                                            );
                                                                            onRemove(getDeletedObj);
                                                                        }

                                                                        const dummyState = selectedItems.filter(
                                                                            (prevItem) =>
                                                                                prevItem?.[targetProperty] !==
                                                                                selectedItems[0]?.[targetProperty]
                                                                        );
                                                                        setSelectedItems(dummyState);
                                                                        onChange(dummyState);
                                                                    }}
                                                                >
                                                                    <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                variant="soft"
                                                placement="bottom"
                                                sx={'!bg-gray-100 !p-0'}
                                                hideChip
                                                groupSx={'w-full'}
                                                useWithoutPortal
                                            >
                                                <span className="flex items-center text-white text-sm">
                                                    <div className="flex flex-col gap-2 justify-center">
                                                        <div className="flex  items-center bg-third rounded-lg w-full justify-between">
                                                            <div className="flex items-center">
                                                                <div>
                                                                    <span className="ml-1 text-white text-sm">
                                                                        {getName(selectedItems[0])}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="ml-2 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                onClick={() => {
                                                                    if (onRemove) {
                                                                        const getDeletedObj = selectedItems.find(
                                                                            (prevItem) =>
                                                                                prevItem?.[targetProperty] ===
                                                                                selectedItems[0]?.[targetProperty]
                                                                        );
                                                                        onRemove(getDeletedObj);
                                                                    }

                                                                    const dummyState = selectedItems.filter(
                                                                        (prevItem) =>
                                                                            prevItem?.[targetProperty] !==
                                                                            selectedItems[0]?.[targetProperty]
                                                                    );
                                                                    setSelectedItems(dummyState);
                                                                    onChange(dummyState);
                                                                }}
                                                            >
                                                                <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </TooltipChipWrap>
                                        </div>
                                    </div>
                                    {selectedItems.length > 1 && (
                                        <div className="flex w-fit items-center bg-third rounded-lg px-2 mr-1 flex-nowrap py-1">
                                            <div className="flex items-center">
                                                <TooltipChipWrap
                                                    title={
                                                        <div className="overflow-y-auto max-h-[300px] max-w-[320px]">
                                                            <div className="flex flex-col gap-2 justify-center">
                                                                {selectedItems.slice(1).map((item, index) => (
                                                                    <div
                                                                        key={'item_fileter' + item.id + index}
                                                                        className="flex  items-center bg-third rounded-lg px-2 py-2 w-full justify-between"
                                                                    >
                                                                        <div className="flex items-center">
                                                                            <DefaultProfileViewer
                                                                                width={30}
                                                                                height={30}
                                                                                image={
                                                                                    item?.image ||
                                                                                    generateRandomProfileLink(
                                                                                        getName(item)
                                                                                    )
                                                                                }
                                                                                atClick={() => {}}
                                                                                name={getName(item)}
                                                                            />
                                                                            <div>
                                                                                <span className="ml-1 text-white text-sm">
                                                                                    {getName(item)}
                                                                                </span>
                                                                                <br />
                                                                                <span className="ml-1 text-white text-sm">
                                                                                    {item?.email || '---'}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <button
                                                                            type="button"
                                                                            className="ml-1 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                                            onClick={() => {
                                                                                if (onRemove) {
                                                                                    const getDeletedObj =
                                                                                        selectedItems.find(
                                                                                            (prevItem) =>
                                                                                                prevItem?.[
                                                                                                    targetProperty
                                                                                                ] ===
                                                                                                item?.[targetProperty]
                                                                                        );
                                                                                    onRemove(getDeletedObj);
                                                                                }

                                                                                const dummyState = selectedItems.filter(
                                                                                    (prevItem) =>
                                                                                        prevItem?.[targetProperty] !==
                                                                                        item?.[targetProperty]
                                                                                );
                                                                                setSelectedItems(dummyState);
                                                                                onChange(dummyState);
                                                                            }}
                                                                        >
                                                                            <XMarkIcon
                                                                                className="h-3 w-3"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }
                                                    variant="soft"
                                                    placement="bottom"
                                                    sx={'!bg-gray-100 !p-0'}
                                                    hideChip
                                                    groupSx={'w-full'}
                                                    useWithoutPortal
                                                >
                                                    <span className="flex items-center text-white text-sm">
                                                        {`+ ${selectedItems.length - 1} Selected`}
                                                    </span>
                                                </TooltipChipWrap>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!showNameOnly &&
                                selectedItems?.map((item) => (
                                    <div key={`${item?.display_name}_item_show`}>
                                        <div className="flex w-fit items-center bg-third rounded-lg px-2 mr-4 py-2">
                                            <DefaultProfileViewer
                                                image={item?.image || generateRandomProfileLink(getName(item))}
                                                atClick={() => {}}
                                                name={getName(item)}
                                            />
                                            <div>
                                                <span className="ml-2 text-white">{getName(item)}</span>
                                                <TooltipChipWrap
                                                    title={item?.email || 'No Email'}
                                                    variant="soft"
                                                    placement="bottom"
                                                    sx={'!bg-gray-100 !text-gray-600 border'}
                                                    hideChip
                                                    groupSx={'w-full'}
                                                    useWithoutPortal
                                                >
                                                    <div className="ml-2 text-sm text-white max-w-[150px] text-ellipsis overflow-hidden">
                                                        {item?.email && item?.email?.slice(-30)}
                                                    </div>
                                                </TooltipChipWrap>
                                            </div>
                                            <button
                                                type="button"
                                                className="ml-1 p-[2px] rounded-full bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:white"
                                                onClick={() => {
                                                    if (onRemove) {
                                                        const getDeletedObj = selectedItems.find(
                                                            (prevItem) =>
                                                                prevItem?.[targetProperty] === item?.[targetProperty]
                                                        );
                                                        onRemove(getDeletedObj);
                                                    }

                                                    const dummyState = selectedItems?.filter(
                                                        (prevItem) =>
                                                            prevItem?.[targetProperty] !== item?.[targetProperty]
                                                    );
                                                    setSelectedItems(dummyState);
                                                    onChange(dummyState);
                                                }}
                                            >
                                                <XMarkIcon className="h-3 w-3" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}

                    {selectedItems.length !== limit && (
                        <div className="flex ">
                            <CompoboxInput
                                atChange={(event) => {
                                    setQuery(event?.target?.value || '');
                                }}
                                placeholder={placeholder}
                                selectedItems={selectedItems}
                                inputsx={inputsx}
                                onKeyDown={handleKeyDown}
                                debounceTime={700}
                                focusOnValues
                                query={query}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-[#979797]" aria-hidden="true" />
                            </Combobox.Button>
                        </div>
                    )}
                </div>

                <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <InfiniteScrollComp
                        height={215}
                        next={handlePageChange}
                        dataLength={(listData?.length === 0 ? (hasMoreContent ? 1 : 0) : listData?.length) || 0}
                        hasMore={listData?.length !== 0 ? hasMoreContent : false}
                        loader={
                            <div className="flex items-center justify-center py-2">
                                <SmallLoader scale={125} />
                            </div>
                        }
                    >
                        {listData && listData?.length > 0 && (
                            <>
                                {listData.map((obj, ind) => (
                                    <div key={'item_fileter1' + obj.id + ind}>
                                        <Combobox.Option
                                            value={obj}
                                            className={({ active }) =>
                                                cn(
                                                    'relative cursor-default select-none py-2.5 pl-3 pr-9 border-y border-gray-100',
                                                    active ? 'bg-third !text-white' : 'text-gray-900'
                                                )
                                            }
                                            onClick={() => {
                                                if (!hideSelectedValues) {
                                                    if (limit && selectedItems.length === limit) {
                                                        return false;
                                                    } else if (handleDataWithCustomValidators) {
                                                        const isValidateData = handleDataWithCustomValidators(obj);
                                                        setQuery('');
                                                        if (!isValidateData) {
                                                            return false;
                                                        }
                                                        const dummyState = [...selectedItems, obj];
                                                        setSelectedItems(dummyState);
                                                    } else {
                                                        const isDataFound = valuesToFilter
                                                            ? valuesToFilter?.find((entity) => {
                                                                  if (filterSelectedValueTargetElement) {
                                                                      return (
                                                                          entity?.[filterSelectedValueTargetElement] ===
                                                                          obj?.[targetProperty]
                                                                      );
                                                                  } else {
                                                                      return (
                                                                          entity?.fields[
                                                                              filterSelectedValueTargetIndex ?? 1
                                                                          ]?.value === obj?.[targetProperty]
                                                                      );
                                                                  }
                                                              })
                                                            : false;

                                                        if (isDataFound) {
                                                            return false;
                                                        }
                                                        const dummyState = [...selectedItems, obj];
                                                        setSelectedItems(dummyState);
                                                        onChange(dummyState);
                                                        setQuery('');
                                                    }
                                                } else {
                                                    onChange([obj]);
                                                    setQuery('');
                                                }
                                            }}
                                        >
                                            {({ active, _selected }) => (
                                                <>
                                                    <div className="flex items-center">
                                                        <DefaultProfileViewer
                                                            image={
                                                                obj?.image || generateRandomProfileLink(getName(obj))
                                                            }
                                                            name={getName(obj)}
                                                        />
                                                        <div className="flex flex-col flex-1">
                                                            <span className={cn('ml-3 truncate')}>{getName(obj)}</span>

                                                            {showTaskDetails ? (
                                                                <>
                                                                    <div
                                                                        className={cn(
                                                                            'flex items-center justify-between text-[12px] mt-1 gap-4 mx-3 flex-wrap text-[#979797]',
                                                                            active && '!text-white'
                                                                        )}
                                                                    >
                                                                        <span className={cn('truncate')}>
                                                                            Total Pending Tasks
                                                                        </span>
                                                                        <span className={cn('truncate font-semibold')}>
                                                                            {obj?.totalTask || 0}
                                                                        </span>
                                                                    </div>
                                                                    <div
                                                                        className={cn(
                                                                            'flex items-center justify-between text-[12px] gap-4 mx-3 flex-wrap text-[#979797]',
                                                                            active && '!text-white'
                                                                        )}
                                                                    >
                                                                        <span className={cn('truncate')}>
                                                                            Today&apos;s Pending Tasks
                                                                        </span>
                                                                        <span className={cn('truncate font-semibold')}>
                                                                            {obj?.todayTask || 0}
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <span className={cn('ml-3 truncate')}>
                                                                    {obj?.email || '__'}
                                                                </span>
                                                            )}
                                                            {showDesignation && obj?.designation && (
                                                                <div
                                                                    className={cn(
                                                                        'flex items-center justify-between text-[12px] mt-2 gap-4 mx-3 flex-wrap text-gray-500',
                                                                        active && '!text-white'
                                                                    )}
                                                                >
                                                                    <span className={cn('truncate')}>Designation:</span>
                                                                    <span className={cn('truncate font-semibold')}>
                                                                        {obj?.designation || ''}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Combobox.Option>
                                    </div>
                                ))}
                            </>
                        )}
                    </InfiniteScrollComp>
                </Combobox.Options>
            </div>
        </Combobox>
    );
};

export default ComboboxComponentSSR;
