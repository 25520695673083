/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';

const DataTable = ({ tableName = '', columns, data }) => {
    // Load persisted column sizes from localStorage
    const [columnSizing, setColumnSizing] = useState(() => {
        const savedSizing = localStorage.getItem(`${tableName}_columnSizing`);
        return savedSizing && savedSizing !== 'undefined' ? JSON.parse(savedSizing) : {};
    });

    // Update localStorage whenever column sizes change
    const handleColumnSizingChange = (newSizing) => {
        const newObj = { ...columnSizing, ...newSizing() };
        setColumnSizing(newObj);
        localStorage.setItem(`${tableName}_columnSizing`, JSON.stringify(newObj));
    };

    const table = useReactTable({
        data,
        columns: columns.map((col) => ({
            ...col,
            minSize: 2
        })),
        columnResizeMode: 'onChange',
        columnResizeDirection: 'ltr',
        getCoreRowModel: getCoreRowModel(),
        state: {
            columnSizing
        },
        onColumnSizingChange: handleColumnSizingChange // Capture resize updates
        // debugTable: true,
        // debugHeaders: true,
        // debugColumns: true
    });

    return (
        <div style={{ direction: table.options.columnResizeDirection }}>
            <div className="overflow-x-auto">
                <div
                    className="divTable table divide-y divide-gray-300 bg-white"
                    style={{
                        width: '100%'
                    }}
                >
                    <div className="thead">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <div key={headerGroup.id} className="tr flex">
                                {headerGroup.headers.map((header) => (
                                    <div
                                        key={header.id}
                                        className="th border-b border-r text-left text-gray-600 font-semibold truncate px-3 py-4 relative"
                                        style={{
                                            width: header.getSize(),
                                            minWidth: header.column.columnDef.minSize,
                                            maxWidth: header.column.columnDef.maxSize,
                                            flexGrow: 1,
                                            flexShrink: 1
                                        }}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                        <div
                                            onDoubleClick={() => header.column.resetSize()}
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                            className={`resizer w-[5px] h-full absolute -right-0.5 top-0 hover:bg-third cursor-col-resize ${
                                                table.options.columnResizeDirection
                                            } ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="tbody divide-y divide-gray-200 bg-white">
                        {table.getRowModel().rows.map((row) => (
                            <div
                                key={row.id}
                                className="tr flex hover:bg-[#F8F4FE] transition-colors duration-200 cursor-pointer"
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <div
                                        key={cell.id}
                                        className="td whitespace-nowrap text-md font-normal text-gray-900 truncate px-3 py-4 !content-center"
                                        style={{
                                            width: cell.column.getSize(),
                                            minWidth: cell.column.columnDef.minSize,
                                            maxWidth: cell.column.columnDef.maxSize,
                                            flexGrow: 1,
                                            flexShrink: 1
                                        }}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataTable;
