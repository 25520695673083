import React from 'react';

export const PhotoIcon = () => {
    return (
        <div>
            {' '}
            <svg width="26" height="24" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.2 14.4315L8.69173 10.7921L9.76716 11.8727L5.65507 16.0857H5.2V14.4315ZM20.75 1.3H3.25C2.72636 1.3 2.2283 1.51414 1.86422 1.88862C1.50079 2.26243 1.3 2.76533 1.3 3.28571V18.7143C1.3 19.2347 1.50079 19.7376 1.86422 20.1114C2.2283 20.4859 2.72636 20.7 3.25 20.7H20.75C21.2736 20.7 21.7717 20.4859 22.1358 20.1114C22.4992 19.7376 22.7 19.2347 22.7 18.7143V3.28571C22.7 2.76534 22.4992 2.26243 22.1358 1.88862C21.7717 1.51414 21.2736 1.3 20.75 1.3ZM6.325 5.27143C6.55293 5.27143 6.77743 5.34085 6.97021 5.47334C7.16325 5.60601 7.31662 5.79668 7.40795 6.02346C7.49934 6.25039 7.52354 6.50119 7.47671 6.74334C7.42989 6.98541 7.3148 7.20514 7.14893 7.37575C6.98326 7.54615 6.77461 7.65987 6.55048 7.70572C6.3265 7.75155 6.09411 7.72822 5.88184 7.63778C5.66933 7.54724 5.48449 7.39253 5.35301 7.19012C5.22139 6.98751 5.15 6.74731 5.15 6.5C5.15 6.1679 5.27845 5.85323 5.50107 5.62425C5.72304 5.39593 6.01984 5.27143 6.325 5.27143ZM18.8 16.0857H9.3756L12.1144 13.2687L15.4281 9.86026L18.8 13.3285V16.0857Z"
                    stroke="white"
                    strokeWidth="1.4"
                />
            </svg>
        </div>
    );
};
