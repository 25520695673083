import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { totalPermissionsThunk } from 'store/auth/slices';
import { handleAllPermissions } from 'store/global/globalReducer';
import { authPermissionsThunk } from 'store/settings/team/team';
import { REDIRECT_URI } from 'utills/globalVars';

const useRedirectToDashboardHook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: loginData } = useSelector((state) => state.login);
    const allPermissions = JSON.parse(localStorage.getItem('allPermissions'));
    const { data: auth } = useSelector((state) => state.authPermissions);
    const { data: total } = useSelector((state) => state.totalPermissions);

    useEffect(() => {
        if (!auth && loginData) {
            getAuthPermissions();
        }
    }, [auth, loginData]);

    const getAuthPermissions = async () => {
        if (loginData?.is_employee) {
            await dispatch(authPermissionsThunk())
                .catch((error) => {
                    console.error(error);
                })
                .finally(async () => {
                    await fetchTotalPermissions();
                });
        } else {
            return false;
        }
    };
    const fetchTotalPermissions = async () => {
        await dispatch(totalPermissionsThunk());
    };

    useEffect(() => {
        if (loginData && auth && total) {
            const tmp4 = {};
            Object.keys(auth.permissions).forEach((module) => {
                const tmp1 = auth.permissions[module].map((p) => total[p.toLowerCase()]);

                const tmp3 = {};
                Object.keys(total).map((p) => {
                    tmp3[p] = tmp1.some((p2) => p2[p]);
                });

                tmp4[module] = tmp3;
            });
            localStorage.setItem('allPermissions', JSON.stringify(tmp4));
            dispatch(handleAllPermissions(tmp4));
        }
    }, [auth, total]);

    // Redirect To Dashboard after login
    const redirectToDashboard = async (url = '/business/dashboard') => {
        // if (!allPermissions && loginData) {
        //     await getAuthPermissions();
        // }

        const redirectURL = sessionStorage.getItem(REDIRECT_URI);
        sessionStorage.removeItem(REDIRECT_URI);
        if (redirectURL && redirectURL !== '/') {
            // if user is not redirecting to the dashboard
            if (!allPermissions && loginData) {
                await getAuthPermissions();
            }
            return navigate(redirectURL, { replace: true });
        }
        // Navigate to the Dashboard URL
        return navigate(url, { replace: true });
    };

    return { redirectToDashboard, getAuthPermissions };
};

export default useRedirectToDashboardHook;
