import React, { useState } from 'react';
import { LeavePage } from '../../components/payrol-pages/leave-pages/LeavePage';
import { AnnualLeave } from '../../components/payrol-pages/leave-pages/AnnualLeave';
export const Leave = () => {
    const [showAnnualLeave, setShowAnnualLeave] = useState(false);

    const toggleAnnualLeave = () => {
        setShowAnnualLeave(!showAnnualLeave);
    };

    return (
        <div className="app-dashboard">
            {showAnnualLeave && <AnnualLeave toggleAnnualLeave={toggleAnnualLeave} />}

            <div className={`content ${''}`}>
                <LeavePage toggleAnnualLeave={toggleAnnualLeave} />
            </div>
        </div>
    );
};
