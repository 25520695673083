import React, { useState, useEffect } from 'react';
import {
    Editor,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    convertToRaw,
    convertFromHTML,
    ContentState,
    Modifier
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { BoldIcon } from 'utills/svgs/BoldIcon';
import { UnderlineIcon } from 'utills/svgs/UnderlineIcon';
import { BulletList } from 'utills/svgs/BulletList';
import { NumberList } from 'utills/svgs/NumberList';
import { AnchorIcon } from 'utills/svgs/AnchorIcon';
import { ItalicIcon } from 'utills/svgs/ItalicIcon';

const TextEditor = ({ onChange, initialValue, hideFonts, maxWords = 1200, sx }) => {
    const [editorState, setEditorState] = useState(() => {
        if (initialValue) {
            const blocksFromHTML = convertFromHTML(initialValue);
            const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            return EditorState.createWithContent(state);
        }
        return EditorState.createEmpty();
    });
    const [fontFamily, setFontFamily] = useState('Arial');
    const [fontSize, setFontSize] = useState(16);
    const [wordCount, setWordCount] = useState(0);

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        const count = plainText.trim().split(/\s+/).length;
        setWordCount(plainText !== '' ? count : 0);

        const rawContentState = convertToRaw(contentState);
        const htmlContent = stateToHTML(contentState);
        onChange(htmlContent, rawContentState);
    }, [editorState]);

    const handleFontFamilyChange = (e) => {
        setFontFamily(e.target.value);
    };

    const handleFontSizeChange = (e) => {
        setFontSize(parseInt(e.target.value));
    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const mapKeyToEditorCommand = (e) => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
            if (newEditorState !== editorState) {
                setEditorState(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    };

    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const addLink = () => {
        const selection = editorState.getSelection();
        const link = window.prompt('Paste the link:');
        if (!link) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
            return 'handled';
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url: link });
        const newEditorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        setEditorState(RichUtils.toggleLink(newEditorState, selection, entityKey));
    };

    const fontFamilies = ['Arial', 'Times New Roman', 'Courier New', 'Verdana', 'Poppins'];
    const fontSizes = [12, 14, 16, 18, 20, 24, 28, 32];

    const handleBeforeInput = (char, editorState) => {
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        const count = plainText.trim().split(/\s+/).length;

        if (count >= maxWords) {
            return 'handled';
        }
        return 'not-handled';
    };

    const handlePastedText = (text, html, editorState) => {
        const contentState = editorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        const count = plainText.trim().split(/\s+/).length;

        const remainingWords = maxWords - count;
        if (remainingWords <= 0) {
            return 'handled';
        }

        if (text) {
            const pastedText = text.trim().split(/\s+/).slice(0, remainingWords).join(' ');
            const modifiedContentState = Modifier.replaceText(contentState, editorState.getSelection(), pastedText);
            setEditorState(EditorState.push(editorState, modifiedContentState, 'insert-characters'));
            return 'handled';
        }

        return 'not-handled';
    };
    return (
        <div key={1} className={`border border-secondarybg rounded-xl py-1 relative ${sx}`}>
            <div className={`mb-1 flex items-center gap-8 px-4 small-text font-poppins ${hideFonts && 'py-3'} `}>
                {hideFonts ? (
                    ''
                ) : (
                    <div className="flex gap-5">
                        {' '}
                        <select
                            value={fontFamily}
                            onChange={handleFontFamilyChange}
                            className="border-none rounded-lg w-[120px] h-[3rem]"
                        >
                            {fontFamilies.map((font, index) => (
                                <option key={index} value={font}>
                                    {font}
                                </option>
                            ))}
                        </select>
                        <select
                            value={fontSize}
                            onChange={handleFontSizeChange}
                            className="border-none rounded-lg w-[80px] h-[3rem]"
                        >
                            {fontSizes.map((size, index) => (
                                <option key={index} value={size}>
                                    {size}px
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <button onClick={() => toggleInlineStyle('BOLD')} type="button">
                    <BoldIcon />
                </button>
                <button onClick={() => toggleInlineStyle('ITALIC')} type="button">
                    <ItalicIcon />
                </button>
                <button onClick={() => toggleInlineStyle('UNDERLINE')} type="button">
                    <UnderlineIcon />
                </button>
                <button onClick={() => toggleBlockType('unordered-list-item')} type="button">
                    <BulletList />
                </button>
                <button onClick={() => toggleBlockType('ordered-list-item')} type="button">
                    <NumberList />
                </button>
                <button onClick={addLink} type="button">
                    <AnchorIcon />
                </button>
            </div>
            <hr />
            <div
                className="px-3 mt-2 w-full mb-[20px] h-[200px] !overflow-auto"
                style={{ fontFamily, fontSize: `${fontSize}px`, color: '#979797' }}
            >
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={mapKeyToEditorCommand}
                    placeholder="Enter description"
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePastedText}
                />
            </div>
            <div className="absolute bottom-1 right-4 text-sm !text-secondarybg w-full z-10 text-right ">
                {wordCount}/{maxWords} words{' '}
                {wordCount >= maxWords && <span className="text-red-700">Max words reached</span>}
            </div>
        </div>
    );
};

export default TextEditor;
