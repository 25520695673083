import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { getBranchLocationsThunk } from 'store/auth/slices';
import { setSideLoader } from 'store/global/globalReducer';
import { updateQrcodeThunk } from 'store/workspace/workspaceQrCode';
import { BUSINESS_ID, ERROR_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';

export const UpdateCode = ({ open, setOpen, toggleQrCode }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { sideLoader, code } = useSelector((state) => state.global);

    const [title, setTitle] = useState(code?.title ?? '');
    const [link, setLink] = useState(code?.link ?? '');
    const [selectedBranch, setSelectedBranch] = useState(code?.business_branch_id ?? '');
    const [branches, setBranches] = useState([]);

    const addQrCode = () => {
        if (!title || !link) {
            toastHandler('Please fill in all required fields', ERROR_TYPE_ERROR);
            return;
        }
        const payload = {
            business_id,
            title,
            link,
            business_branch_id: selectedBranch,
            qr_code_id: code?.qr_code_id
        };
        dispatch(setSideLoader(true));
        dispatch(updateQrcodeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleQrCode();

                    setOpen(false);
                    toastHandler('QR Code updated', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    useEffect(() => {
        dispatch(getBranchLocationsThunk({ business_id }))
            .then((response) => {
                if (response.payload) {
                    setBranches(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <Slideover title="Update QR Code" open={open} setOpen={setOpen}>
            {' '}
            <div>
                <div>
                    <div>
                        <RoleBasedGuard module={'Qr_codes'} permissions={['admin']} sx="h-[50vh]">
                            {' '}
                            <div className="add-ann-form px-5 mt-3 ">
                                <div>
                                    <label>Title</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setTitle(e.target.value)}
                                        type="text"
                                        placeholder="Title"
                                        value={title}
                                        maxLength={30}
                                    />
                                </div>

                                <div className="mt-3">
                                    <label>Paste QR Link</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setLink(e.target.value)}
                                        type="text"
                                        placeholder="Link"
                                        maxLength={50}
                                        value={link}
                                    />
                                </div>

                                <div className=" mt-3">
                                    <label>Branch</label>
                                </div>

                                <div className="mt-2">
                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="title"
                                        optionsData={branches || []}
                                        selectedValue={selectedBranch}
                                        valuePropertyName={'business_branch_id'}
                                        sx="rounded-xl py-3 ring-gray-400 "
                                        onChangeValue={(obj) => setSelectedBranch(obj?.business_branch_id)}
                                    />
                                </div>
                            </div>
                            <div className="add-service-bottom">
                                <button
                                    onClick={addQrCode}
                                    className="add-btn text-white px-5 flex gap-2 md:px-10 py-2 buttons-font-lighter cursor-pointer  rounded-xl"
                                >
                                    {sideLoader ? <SmallLoaderWhite /> : 'Update QR Code'}
                                </button>
                            </div>{' '}
                        </RoleBasedGuard>
                    </div>
                </div>
            </div>
        </Slideover>
    );
};
