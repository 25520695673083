import React from 'react';

import './css/teamDetails.css';
import { TeamDetailSkeleton } from './team-skeleton/TeamDetailSkeleton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { businessEmployeeArchiveThunk, fetchBusinessEmployeeThunk } from 'store/settings/team/team';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { setSideLoader } from 'store/global/globalReducer';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR } from 'utills/globalVars';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import useRoleGuard from 'Hooks/useRoleGuard';
import { ADMIN_WRITE } from 'utills/constants/forms/roleManagement.constants';
import { convertUnderscoresToSpaces } from 'utills/handleText';

const TeamDetails = ({ id, toggleTeamDetails, handleEditMember, open, setOpen }) => {
    const dispatch = useDispatch();
    const [detailLoader, setDetailLoader] = useState(false);
    const [employee, setEmployee] = useState(null);
    const { sideLoader } = useSelector((state) => state.global);
    const [isZoomed, setIsZoomed] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const deleteMember = (business_employee_id) => {
        if (hasPermissions('Team', ['admin'])) {
            dispatch(setSideLoader(true));
            dispatch(businessEmployeeArchiveThunk(business_employee_id))
                .then((response) => {
                    if (response.payload) {
                        toggleTeamDetails();
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setSideLoader(false));
                });
        } else {
            toastHandler('You dont have permission to perform this action', ERROR_TYPE_ERROR);
        }
    };

    useEffect(() => {
        setDetailLoader(true);
        dispatch(fetchBusinessEmployeeThunk({ business_employee_id: id }))
            .then((response) => {
                if (response.payload) {
                    setEmployee(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setDetailLoader(false);
            });
    }, []);

    return (
        <Slideover title="Team Details" open={open} setOpen={setOpen} size="max-w-3xl">
            {sideLoader && <Loader />}

            {detailLoader ? (
                <TeamDetailSkeleton />
            ) : (
                <div>
                    {employee && (
                        <div className="detail-info flex gap-5 px-5 relative">
                            <div className="team-detail-operations absolute right-5">
                                {employee?.business_group_name && (
                                    <div className="flex items-center gap-1">
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                handleEditMember(employee?.business_employee?.business_employee_id)
                                            }
                                        >
                                            <BgEditIcon />
                                        </div>
                                        <div
                                            className="cursor-pointer"
                                            onClick={() =>
                                                deleteMember(employee?.business_employee?.business_employee_id)
                                            }
                                        >
                                            {' '}
                                            <BgDeleteIcon />{' '}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mt-10">
                                <DefaultProfileViewer
                                    image={employee?.image}
                                    width="100px"
                                    height="100px"
                                    name={employee?.first_name + ' ' + employee?.last_name}
                                />

                                {isZoomed && (
                                    <ZoomImage src={employee?.image} alt="Profile" onClose={() => setIsZoomed(false)} />
                                )}
                            </div>
                            <div className="flex justify-center items-center pt-5">
                                <div>
                                    <div className="detail-title">
                                        {employee.first_name} {employee.last_name}
                                    </div>

                                    <div className="detail-email">{employee.email}</div>
                                    <div className="detail-number">{employee.phone_number}</div>
                                </div>
                            </div>
                        </div>
                    )}{' '}
                </div>
            )}

            <hr className=" mt-3" />

            <div className="mx-5 my-5 xl-title font-fira">{employee?.business_group_name}</div>

            {employee
                ? employee?.business_group_modules?.map((module, moduleIndex) => (
                      <div key={'group' + moduleIndex} className=" m-5">
                          <div className="grid grid-cols-5 gap-5 mt-3">
                              <div className="col-span-2 table-title font-fira">
                                  {convertUnderscoresToSpaces(module?.business_module_name)}
                              </div>
                              {module?.permissions
                                  .filter(
                                      (permission) =>
                                          !(
                                              permission.permission_name === 'Write' &&
                                              ADMIN_WRITE.includes(module?.business_module_name)
                                          )
                                  )
                                  .map((permission, permissionIndex) => (
                                      <div
                                          key={'permissions' + permissionIndex}
                                          className="flex gap-2 items-center font-poppins buttons-font-lighter"
                                      >
                                          <input
                                              type="checkbox"
                                              className=" cursor-pointer text-thirdbg  rounded-[2px]"
                                              checked={permission.permission_status === 'active'}
                                          />
                                          <div>{permission?.permission_name}</div>
                                      </div>
                                  ))}
                          </div>
                          <hr className="mt-3" />
                      </div>
                  ))
                : ''}
        </Slideover>
    );
};

export default TeamDetails;
