import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input, cn } from './helper';
import { Card, CardContent } from './card';
import { SignaturePad } from '../hooks/signature-pad/signature-pad';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useRequiredSigningContext } from './provider';
import { FRIENDLY_FIELD_TYPE } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { deleteSignatureThunk, getSignatures, saveSignatureThunk, submitSignedDocumentThunk } from 'store/fileManager';
import { toast } from 'react-toastify';
import { Loader } from 'components/common/Loader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'components/atoms/buttons/Button';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { CheckboxInput } from 'components/atoms/inputs/CheckboxInput';
import cloneDeep from 'lodash/cloneDeep';
import { SmallLoader } from 'components/common/SmallLoader';
import useBase64Converter from 'Hooks/Files/useBase64Converter';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { DatePick } from 'components/atoms/inputs/DatePick';
import dayjs from 'dayjs';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
export const SigningForm = ({ data, handleSelectedSignature, toggleModal, onSubmitHandlerThunk }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { loading, error, convertUrlToBase64 } = useBase64Converter();

    const { fullName, signature, email, setFullName, setEmail, setDate, setSignature, date } =
        useRequiredSigningContext();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);
    const [saveSignature, setSaveSignature] = useState(false);
    const storedInDicators = JSON.parse(localStorage.getItem('indicators'));
    const { data: loginData } = useSelector((state) => state?.login);
    const { data: signatures } = useSelector((state) => state.getSignatures);
    const [deleteSignLoader, setDeleteSignLoader] = useState(false);
    const [deleteSignId, setDeleteSignId] = useState(null);
    const [selectedSignature, setSelectedSignature] = useState(null);
    const { id, client_id } = useParams();

    const {
        handleSubmit,
        formState: { isSubmitting }
    } = useForm();

    const handleSaveSignature = (signature) => {
        const payload = [
            {
                id: 1,
                type: 'add',
                signature
            }
        ];
        dispatch(saveSignatureThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            });
    };
    const onFormSubmit = async () => {
        const fieldValues = {
            [FRIENDLY_FIELD_TYPE.NAME]: fullName,
            [FRIENDLY_FIELD_TYPE.EMAIL]: email,
            [FRIENDLY_FIELD_TYPE.DATE]: date,
            [FRIENDLY_FIELD_TYPE.SIGNATURE]: signature
        };
        for (const item of data.fields) {
            if (!fieldValues[item.field_type] || fieldValues[item.field_type] === null) {
                toastHandler(`${item.field_type} is required`, TOAST_TYPE_ERROR);
                return;
            }
        }
        let tempFields = data.fields.map((item) => ({
            ...item,
            field_value: fieldValues[item.field_type]
        }));
        const payload = {
            ...data,
            fields: tempFields
        };

        dispatch(setLoader(true));
        dispatch(onSubmitHandlerThunk ? onSubmitHandlerThunk(payload) : submitSignedDocumentThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (saveSignature) {
                        handleSaveSignature(fieldValues?.signature);
                    }

                    toggleModal(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
                toastHandler('Submitted Successfully.', TOAST_TYPE_SUCCESS);
            });
    };
    useEffect(() => {
        const driverObj = driver({
            showProgress: true,
            onDestroyed: () => {
                const newIndicators = {
                    ...storedInDicators,
                    sign_signature: true
                };

                localStorage.setItem('indicators', JSON.stringify(newIndicators));
            },
            steps: [
                {
                    element: '.input-pdf-indicate',

                    popover: {
                        title: 'Your full name',
                        description:
                            'In this section you can view your clients details. You can view there submited forms',
                        popoverClass: 'my-custom-popover-class '
                    }
                },
                {
                    element: '.signature-pdf-indicate',

                    popover: {
                        title: 'Signature Pad',
                        description:
                            'In this section you can create role groups. You can add permissions to modules and assigne those modules to a particuler employee.',
                        popoverClass: 'my-custom-popover-class '
                    }
                }
            ]
        });

        //remember to change this to  !loginData?.second_time_login && !storedInDicators?.file_manager
        if (!loginData?.second_time_login && !storedInDicators?.sign_signature) {
            driverObj.drive();
        } else {
            driverObj.destroy();
        }

        return () => {
            driverObj.destroy();
        };
    }, []);

    const handleDeleteSingature = (signature_id) => {
        setDeleteSignId(signature_id);
        setDeleteSignLoader(true);
        dispatch(deleteSignatureThunk({ signature_ids: [signature_id] }))
            .then((response) => {
                if (response.payload) {
                    const newSignatures = cloneDeep(signatures);
                    const updatedSignatures = newSignatures?.filter((signs) => signs?.signature_id !== signature_id);
                    dispatch(getSignatures.actions.handleUpdate(updatedSignatures));
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setDeleteSignId(null);
                setDeleteSignLoader(false);
            });
    };

    const handleSelectSign = async (signatureee) => {
        if (selectedSignature) setSelectedSignature(null);

        try {
            const base64Content = await convertUrlToBase64(signatureee);
            handleSelectedSignature(base64Content);
            setSelectedSignature(base64Content);
        } catch (error) {
            console.error('Error converting URL to Base64:', error.message);
        }
    };
    return (
        <div className="shadow-custom bg-white rounded-lg sticky sticky-column top-0 w-full max-w-[500px]">
            {isLoading && <Loader />}

            <form
                className={cn('dark:bg-background  flex h-full flex-col rounded-xl  px-4 py-6', {})}
                onSubmit={handleSubmit(onFormSubmit)}
            >
                <fieldset disabled={isSubmitting} className={cn('-mx-2 flex flex-col overflow-hidden px-2')}>
                    <div className={cn('flex flex-1 flex-col')}>
                        <h3 className="text-foreground text-xl font-semibold">Sign Document</h3>

                        <p className="text-muted-foreground mt-2 text-sm secondary-fonts">
                            <label> Please review the document before signing.</label>
                        </p>

                        <hr className="border-border mb-8 mt-4" />

                        <div className="-mx-2 flex flex-1 flex-col gap-4 overflow-y-auto px-2">
                            <div className="flex flex-1 flex-col gap-y-4 ">
                                <div>
                                    <label htmlFor="full-name primary-fonts text-md">Email</label>

                                    <Input
                                        type="text"
                                        id="full-name"
                                        className="bg-background mt-2 secondary-fonts text-sm input-pdf-indicate"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value.trimStart())}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="full-name primary-fonts text-md">Full Name</label>

                                    <Input
                                        type="text"
                                        id="full-name"
                                        className="bg-background mt-2 secondary-fonts text-sm input-pdf-indicate"
                                        value={fullName}
                                        onChange={(e) => setFullName(e.target.value.trimStart())}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="full-name primary-fonts text-md">Date</label>
                                    <DatePick
                                        iconSet={{
                                            right: CalendarSmallIco
                                        }}
                                        onDateChange={(date) => {
                                            if (date) {
                                                setDate(dayjs(date)?.format('DD/MM/YYYY'));
                                            }
                                        }}
                                        placeholder="Select Date"
                                        value={date ? dayjs(date, 'DD/MM/YYYY').format('DD/MM/YYYY') : dayjs()}
                                        bg="transparent"
                                        sx="addTaskDate !border-secondarybg !rounded-[12px] text-gray-900 w-full"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="Signature" className="primary-fonts text-md">
                                        Signature
                                    </label>

                                    <Card className="mt-2 signature-pdf-indicate" gradient degrees={-120}>
                                        <CardContent className="p-0">
                                            <SignaturePad
                                                className="h-44 w-full text-sm secondary-fonts"
                                                defaultValue={signature}
                                                newValue={selectedSignature}
                                                onChange={(value) => {
                                                    setSignature(value);
                                                }}
                                            />
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                            <div className="flex gap-2 items-center">
                                <div>
                                    {' '}
                                    <CheckboxInput
                                        checked={saveSignature}
                                        atChange={(e) => {
                                            if (e.target.checked) {
                                                setSaveSignature(true);
                                            } else {
                                                setSaveSignature(false);
                                            }
                                        }}
                                    />{' '}
                                </div>
                                <div>
                                    {' '}
                                    <label className="primary-fonts text-md"> Save Signature</label>{' '}
                                </div>
                            </div>
                            {typeof signatures !== 'string' && signatures?.length > 0 && (
                                <div className="flex gap-5 items-center flex-wrap saved-signatures ">
                                    {signatures?.length > 0 &&
                                        signatures?.map((sign, index) => (
                                            <div
                                                key={'file_saved_sign_' + index}
                                                onClick={() => sign?.signature && handleSelectSign(sign?.signature)}
                                                className="saved-signature-container cursor-pointer"
                                            >
                                                <div className="saved-signature-inner">
                                                    <img
                                                        src={sign?.signature}
                                                        alt="signature"
                                                        className="save-signature-item"
                                                    />
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        sign?.signature_id && handleDeleteSingature(sign?.signature_id)
                                                    }
                                                    className="remove-signature-icon"
                                                >
                                                    {deleteSignLoader && deleteSignId === sign?.signature_id ? (
                                                        <span>
                                                            {' '}
                                                            <SmallLoader scale="75" />
                                                        </span>
                                                    ) : (
                                                        <BgDeleteIcon />
                                                    )}
                                                </div>
                                            </div>
                                        ))}{' '}
                                </div>
                            )}

                            <div className="flex justify-between gap-5 flex-wrap mt-2">
                                <Button
                                    atClick={() => navigate(`/file/manager/authorized/folder/${client_id}`)}
                                    buttonType="button"
                                    classes="blue-border-btn px-12  rounded-lg"
                                    title="Cancel"
                                />

                                <Button buttonType="submit" title="Submit" classes="ann-btn px-12 rounded-lg" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};
