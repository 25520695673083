import React from 'react';
import { useEffect } from 'react';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useDispatch, useSelector } from 'react-redux';
import { handleIndicators } from 'store/global/globalReducer';
import { SearchInput } from 'components/molecules/SearchInput';

const RoleOperations = ({ toggleAddRoleGroup, handleSearchValue, deleteSelect, isVisible, count, searchValue }) => {
    const { data: login } = useSelector((state) => state.login);
    const { showindicators } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    useEffect(() => {
        const driverObj = driver({
            showProgress: true,
            steps: [
                {
                    element: '.add-group-indicate',

                    popover: {
                        title: 'Add role group',
                        description:
                            'By clicking this button you can create role groups. This application is devided into different modules you can create a role group by adding permissions to a module then create a role group of those modules and assign them to your employees so they can only have access to specified modules',
                        popoverClass: 'my-custom-popover-class text-red-900'
                    }
                }
            ]
        });
        if (showindicators && !login?.second_time_login) {
            driverObj.drive();
        } else {
            driverObj.destroy();
        }

        return () => {
            dispatch(handleIndicators(false));
        };
    }, [showindicators]);
    const handleInputChange = (e) => {
        handleSearchValue(e);
    };

    const handleAdd = () => {
        dispatch(handleIndicators(false));
        toggleAddRoleGroup();
    };

    return (
        <div className="md:px-10 px-5">
            <div className="flex justify-between items-center flex-wrap  gap-5">
                <div>
                    <div>
                        <SearchInput
                            width="300px"
                            atChange={(e) => {
                                handleInputChange(e.target.value);
                            }}
                            value={searchValue}
                            placeholder="Search"
                            inputBorder="1px solid #979797"
                        />
                    </div>
                </div>
                <div className="flex gap-2 items-center">
                    {isVisible && (
                        <div>
                            <div onClick={() => deleteSelect()} className="delete-all-roles rounded-lg cursor-pointer">
                                Archive ({count})
                            </div>
                        </div>
                    )}
                    <div>
                        <div
                            onClick={handleAdd}
                            className="role-btn add-group-indicate  cursor-pointer text-white rounded-lg"
                        >
                            Add
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default RoleOperations;
