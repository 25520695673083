import React from 'react';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setSideLoader } from 'store/global/globalReducer';
import { fetchAllBusinessGroupsThunk, sendEmployeeInviteThunk } from 'store/settings/team/team';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmail } from 'utills/FormValidation';

const AddTeam = ({ toggleInviteSuccess, open, setOpen }) => {
    const dispatch = useDispatch();
    const { sideLoader } = useSelector((state) => state.global);

    const [businessGroups, setBusinessGroups] = useState(null);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        business_group_id: ''
    });

    const [emailError, setEmailError] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            if (!validateEmail(value)) {
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }

        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        const hasEmptyValue = Object.values(formData).some((value) => {
            return (
                value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0)
            );
        });
        if (hasEmptyValue) {
            return toastHandler('Please fill in all required fields.', 'error');
        }

        if (formData?.phone_number) {
            if (!validatePhoneNumber(formData?.phone_number)) {
                return false;
            }
        }

        const newFormData = {
            first_name: formData.first_name.trim(),
            last_name: formData.last_name.trim(),
            email: formData.email.trim(),
            phone_number: formData.phone_number.trim(),
            business_group_id: formData.business_group_id.trim()
        };

        dispatch(setSideLoader(true));
        dispatch(sendEmployeeInviteThunk(newFormData))
            .then((response) => {
                if (response.payload) {
                    toggleInviteSuccess();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    useEffect(() => {
        dispatch(setSideLoader(true));

        dispatch(fetchAllBusinessGroupsThunk())
            .then((response) => {
                if (response.payload) {
                    setBusinessGroups(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    }, []);

    return (
        <Slideover
            title="Add Team"
            open={open}
            setOpen={setOpen}
            footer={
                <div className="my-5 flex items-center justify-center">
                    <button
                        onClick={handleSubmit}
                        disabled={emailError}
                        className="ann-btn px-20 py-2 cursor-pointer text-white buttons-font-lighter font-fira rounded-lg flex items-center gap-2"
                    >
                        Invite {sideLoader && <SmallLoaderWhite />}
                    </button>
                </div>
            }
        >
            <RoleBasedGuard module={'Team'} permissions={['admin']} sx="h-[50vh]">
                <div>
                    <div>
                        <div className="add-ann-form font-poppins">
                            <div className="mt-4 pl-5">
                                <div>
                                    <div>
                                        <label className="buttons-font font-poppins">First Name</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            name="first_name"
                                            className="px-3 rounded-xl mt-1 inputs-font font-poppins"
                                            onChange={handleInputChange}
                                            maxLength={30}
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div>
                                        <label className="buttons-font font-poppins">Last Name</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            className="px-3 rounded-xl mt-1 inputs-font font-poppins"
                                            name="last_name"
                                            onChange={handleInputChange}
                                            maxLength={30}
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div>
                                        <label className="buttons-font font-poppins">Email</label>
                                    </div>
                                    <div>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            className="px-3 rounded-xl mt-1 inputs-font font-poppins"
                                            name="email"
                                            onChange={handleInputChange}
                                            maxLength={50}
                                        />
                                        <div className="error-div mt-1">
                                            {emailError && formData.email !== '' ? <span> Invalid Email </span> : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div className="mt-2">
                                        <PhoneInputComponent
                                            title="Phone Number"
                                            placeholder="Enter Phone Number"
                                            titleSx="buttons-font font-poppins"
                                            sx="!rounded-xl "
                                            atChange={(phone) => {
                                                setFormData((prevData) => ({
                                                    ...prevData,
                                                    ['phone_number']: phone
                                                }));
                                            }}
                                            value={formData?.phone_number}
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div>
                                        <label className="buttons-font font-poppins">Business Groups</label>
                                    </div>
                                    <div>
                                        <div className="mt-1">
                                            <SimpleSelectMenu
                                                placeholder="Select"
                                                targetProperty="business_group_name"
                                                optionsData={businessGroups || []}
                                                sx="rounded-xl py-3 ring-gray-400 inputs-font font-poppins "
                                                onChangeValue={(obj) => {
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        ['business_group_id']: obj?.business_group_id
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};

export default AddTeam;
