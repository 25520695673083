import useRoleGuard from 'Hooks/useRoleGuard';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';

export const usePagination = ({ thunk, dataKey = 'rows', extraPayload, permissions }) => {
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();

    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [page, setPage] = useState(1);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        if (permissions) {
            if (!hasPermissions(permissions?.module, permissions?.permissions)) {
                return;
            }
        }
        getData();
    }, [page]);

    const getData = async (hasReset = false, customPage) => {
        const payload = {
            ...extraPayload,
            page: customPage ? customPage : page,
            page_size: Number(PAGINATION_PAGE_SIZE)
        };

        setHasMoreContent(true);

        try {
            const response = await dispatch(thunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? pageData || [] : []), ...response.payload[dataKey]];
                setPageData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handlePageChange = (custom) => {
        if (custom) {
            getData(true, 1);
        } else {
            setPage(custom ? custom : page + 1);
        }
    };

    return { handlePageChange, getData, pageData, hasMoreContent };
};
