import React from 'react';
import { useState, useEffect } from 'react';
import '../../css-steps/signup-css/stepTwo.css';
import { Loader } from '../common/Loader';
import 'react-notifications/lib/notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../store/global/globalReducer';
import { PreauthEmailSendOtpThunk, verifyForgetPassThunk } from '../../store/auth/slices';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import Recaptcha from 'components/molecules/Recaptcha';
import { useCaptchaHandler } from 'Hooks/reCaptcha/useCaptchaHandler';

const OtpVerification = (props) => {
    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};
    const forgotPasswordData = JSON.parse(sessionStorage.getItem('forgotPasswordData'));
    const { error, setError, isCaptchaRequired, recaptchaRef, handleRequest, handleCaptchaChange } =
        useCaptchaHandler();

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);

    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerEnded, setTimerEnded] = useState(false);

    const [otpVal, setOtpVal] = useState('');

    const handleSubmit = async (e) => {
        if (otpVal?.length < 6) {
            toastHandler('Please enter full otp', TOAST_TYPE_ERROR);
            return;
        }
        e.preventDefault();

        dispatch(setLoader(true));
        await dispatch(
            verifyForgetPassThunk({
                email: parsedData?.email,
                otp: otpVal,
                user_type: 'employee'
            })
        )
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    const forgotPassData = {
                        email: parsedData?.email,
                        otp: true,
                        questions: false
                    };
                    localStorage.setItem('forgotPassData', JSON.stringify(forgotPassData));

                    if (forgotPasswordData?.google_authenticator_2fa) {
                        props.handleCondition(11);
                    } else {
                        props.handleCondition(5);
                    }
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });

        dispatch(setLoader(false));
    };

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    const sendOTPEmail = async (e) => {
        e.preventDefault();
        dispatch(setLoader(true));
        // await dispatch(
        //     PreauthEmailSendOtpThunk({ user_id: forgotPasswordData.user_id, user_type: 'employee', type: 2 })
        // )
        await handleRequest(
            'PreauthEmailSendOtp',
            (payload = {}) =>
                dispatch(
                    PreauthEmailSendOtpThunk({
                        ...payload
                    })
                ),
            { user_id: forgotPasswordData.user_id, user_type: 'employee', type: 2 }
        )
            .then((response) => {
                if (response?.payload) {
                    setTimerEnded(false);
                    setTimeRemaining(90);
                    timeExecution();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    useEffect(() => {
        timeExecution();
    }, []);

    return (
        <div>
            {isLoading && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    <div className="text-center card-layout-title-text mt-7">Email OTP Verification</div>
                    <div className="otp-wrapper mx-28 mt-3">
                        <div className=" ">
                            <p className="small-text text-sixth font-poppins">
                                A 6-digit code is sent to your email. Kindly enter that code here to continue further.
                                Thanks
                            </p>
                        </div>
                        <div className="ml-5">
                            <div className="mt-20">
                                <OtpInputs value={otpVal} onChange={handleOtpChange} required={true} />
                            </div>

                            {isCaptchaRequired && (
                                <div className="my-3">
                                    <Recaptcha
                                        ref={recaptchaRef}
                                        captchaError={!!error}
                                        setCaptchaError={setError}
                                        onValueChange={handleCaptchaChange}
                                    />
                                </div>
                            )}

                            <div className="resend-confirmation mt-5 text-sm">
                                <p>
                                    {timerEnded ? (
                                        <span className="text-thirdbg  cursor-pointer" onClick={sendOTPEmail}>
                                            Resend
                                        </span>
                                    ) : (
                                        <span>
                                            <span>
                                                <span className="text-thirdbg  cursor-pointer mr-1">Resend</span>
                                                OTP in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`})
                                            </span>
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className="mt-60 mb-12">
                            <div className="flex justify-between items-center px-1-">
                                <div>
                                    <button
                                        onClick={() => props.handleCondition(2)}
                                        className="btn-1 px-12 buttons-font font-fira py-2"
                                    >
                                        Back
                                    </button>
                                </div>

                                <div>
                                    <button
                                        onClick={(e) => handleSubmit(e)}
                                        className="btn-2 buttons-font font-fira px-10 py-2"
                                    >
                                        Verify
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpVerification;
