import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import MailIcon2 from 'utills/svgs/MailIcon2';
import CollaborationManager from './CollaborationManager';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

export const RelayRoomSettings = () => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings`);
        }
    };
    return (
        <div>
            <SidebarLayout>
                <RoleBasedGuard module="Job" permissions={['admin']} sx="p-5">
                    {open && <CollaborationManager open={open} setOpen={setOpen} />}
                    <div>
                        <Jumbotron
                            title="Relay Room Settings"
                            directory={`Settings`}
                            path={[
                                { title: 'Settings', link: 'settings' },
                                {
                                    title: 'Job Manager',
                                    link: 'settings/job-manager'
                                },
                                {
                                    title: 'Relay Room Settings',
                                    link: ''
                                }
                            ]}
                            backArrowProp={backArrowProp}
                        />
                        <div className="mx-5 md:mx-10">
                            <div className="flex gap-5 items-center flex-wrap">
                                <GridCard
                                    text="Automated Email Template"
                                    iconSet={{ icon: MailIcon2 }}
                                    atClick={() => {
                                        setOpen(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
