import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import DynamicStatusIcon from 'utills/svgs/DynamicStatusIcon';
import { LargeTaskIcon } from 'utills/svgs/LargeTaskIcon';
import PrebuiltChecklistIcon from 'utills/svgs/PrebuiltChecklistIcon';
import TaskTemplateIcons from 'utills/svgs/TaskTemplateIcons';

export const TaskModuleSettings = () => {
    const navigate = useNavigate();
    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings`);
        }
    };
    return (
        <div>
            <SidebarLayout>
                <div>
                    <Jumbotron
                        title="Task Module"
                        directory={`Settings`}
                        path={[
                            { title: 'Settings', link: 'settings' },
                            {
                                title: 'Task Module',
                                link: ''
                            }
                        ]}
                        backArrowProp={backArrowProp}
                    />
                    <div className="mx-5 md:mx-10">
                        <div className="flex gap-5 items-center flex-wrap">
                            <GridCard
                                text="Dynamic Status"
                                iconSet={{ icon: DynamicStatusIcon }}
                                link={'/organizational/settings'}
                            />
                            <GridCard
                                text="Task Templates"
                                iconSet={{ icon: TaskTemplateIcons }}
                                link={'/settings/task-module/task-templates'}
                            />
                            <GridCard text="Task Types" iconSet={{ icon: LargeTaskIcon }} link={'/task/type'} />

                            <GridCard
                                text="Prebuilt Checklist"
                                iconSet={{ icon: PrebuiltChecklistIcon }}
                                link={'/settings/task-module/prebuilt-checklist'}
                            />
                        </div>
                    </div>
                </div>
            </SidebarLayout>
        </div>
    );
};
