import React from "react";

export const TaskAssignedIcon = () => {
  return (
    <div>
      <svg
        width="66"
        height="66"
        viewBox="0 0 76 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="38" cy="38" r="38" fill="#E6AA69" />
        <path
          d="M29.0605 17.5605C22.1516 18.3332 18.6746 21.6125 17.6414 28.3418C17.4797 29.3391 17.4707 30.0938 17.4707 38C17.4707 45.8074 17.4887 46.6699 17.6324 47.6133C18.6566 54.2078 21.8461 57.3883 28.4316 58.3676C29.4109 58.5113 30.3004 58.5293 38.0449 58.5293C47.3797 58.5293 47.3438 58.5293 49.5809 57.9812C54.3336 56.8043 57.1008 53.8844 58.116 48.9609C58.5562 46.8586 58.5832 46.05 58.5473 37.3441C58.5113 28.4586 58.5203 28.6293 57.9453 26.3023C56.8402 21.8551 54.073 19.1148 49.5898 18.0187C47.3527 17.4797 47.5055 17.4797 38.4492 17.4617C31.9715 17.4438 29.8961 17.4707 29.0605 17.5605ZM47.5684 20.5074C52.0516 21.307 54.2437 23.2027 55.2051 27.1109C55.6453 28.8629 55.6543 29.1504 55.6543 38.0898C55.6543 47.1371 55.6543 47.182 55.1691 49.0688C54.3246 52.357 52.357 54.3246 49.0687 55.1691C47.182 55.6543 47.1371 55.6543 38.0898 55.6543C29.1504 55.6543 28.8629 55.6453 27.1109 55.2051C23.1039 54.2168 21.0914 51.773 20.4625 47.173C20.2918 45.9242 20.2918 30.0758 20.4625 28.827C21.0914 24.227 23.1039 21.7832 27.1109 20.7949C27.5781 20.6781 28.3238 20.5344 28.782 20.4895C29.2312 20.4355 29.7254 20.3727 29.8691 20.3547C30.0219 20.3367 33.8762 20.3277 38.4492 20.3457C45.6187 20.3637 46.8676 20.3816 47.5684 20.5074Z"
          fill="white"
        />
        <path
          d="M32.2499 27.9023C32.1241 27.9832 31.2706 28.8007 30.3452 29.7171L28.6741 31.3793L28.2519 31.0378C27.8925 30.7414 27.7577 30.6875 27.3085 30.6515C26.6616 30.6066 26.2304 30.8312 25.9609 31.3613C25.7362 31.7925 25.7362 32.1878 25.9519 32.655C26.1405 33.0683 27.6948 34.6226 28.0632 34.7664C28.4136 34.8921 28.9796 34.8832 29.2671 34.7304C29.4019 34.6585 30.5878 33.5175 31.9085 32.1878C33.9929 30.0945 34.3073 29.7351 34.3702 29.4386C34.487 28.8007 34.0917 28.055 33.5257 27.8394C33.1843 27.7046 32.4925 27.7496 32.2499 27.9023Z"
          fill="white"
        />
        <path
          d="M38.1258 30.7137C37.5777 30.9652 37.3262 31.3695 37.3262 31.9984C37.3262 32.5645 37.4969 32.8699 38 33.2113L38.2965 33.418H43.75H49.2035L49.5 33.2113C49.9312 32.9238 50.1469 32.5914 50.1918 32.1512C50.2547 31.5941 49.9312 31.0281 49.4102 30.7496L49.0238 30.543H43.759C38.8176 30.543 38.4672 30.5609 38.1258 30.7137Z"
          fill="white"
        />
        <path
          d="M32.4296 41.2422C32.3039 41.2872 31.4054 42.1227 30.4351 43.093L28.6652 44.8629L28.2609 44.4856C27.596 43.8747 26.8144 43.8657 26.2214 44.4587C25.8531 44.827 25.7273 45.2493 25.8261 45.7883C25.916 46.2735 27.7218 48.0883 28.2789 48.2501C29.0425 48.4747 29.1683 48.3848 31.7468 45.8333C34.2804 43.3176 34.4691 43.0571 34.3613 42.3473C34.2355 41.4579 33.2742 40.9098 32.4296 41.2422Z"
          fill="white"
        />
        <path
          d="M38.1797 44.0914C37.6676 44.316 37.2812 44.882 37.2812 45.4031C37.2812 45.8523 37.5957 46.4004 38 46.652L38.3145 46.8496H43.75H49.1855L49.518 46.643C49.9582 46.3645 50.2547 45.7535 50.1918 45.2594C50.1379 44.8281 49.9223 44.5047 49.5 44.2172L49.2035 44.0195L43.759 44.0285C40.7672 44.0285 38.2516 44.0555 38.1797 44.0914Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
