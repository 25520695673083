/* eslint-disable no-unused-vars */
import generateThunkAndSlice from 'store/thunk/thunk';
import { endpoint, methods } from 'apiEndpoints';

const { slice: createTaskStatus, request: createTaskStatusThunk } = generateThunkAndSlice(
    'createTaskStatus',
    endpoint.createTaskStatus,
    methods.POST
);

const { request: createJobsStatusThunk } = generateThunkAndSlice(
    'createJobsStatus',
    endpoint.createJobsStatus,
    methods.POST
);
const { request: updateJobsStatusThunk } = generateThunkAndSlice(
    'updateJobsStatus',
    endpoint.updateJobsStatus,
    methods.PUT
);

const { slice: createTaskType, request: createTaskTypeThunk } = generateThunkAndSlice(
    'createTaskType',
    endpoint.createTaskType,
    methods.POST
);
const { slice: createChecklist, request: createChecklistThunk } = generateThunkAndSlice(
    'createChecklist',
    endpoint.createCheckList,
    methods.POST
);

const { request: updateChecklistThunk } = generateThunkAndSlice(
    'updateChecklist',
    endpoint.updateChecklist,
    methods.POST
);
const { slice: getChecklistDetails, request: getChecklistDetailsThunk } = generateThunkAndSlice(
    'getChecklistDetails',
    endpoint.getChecklistDetails,
    methods.POST
);

const { slice: getAllTaskStatuses, request: getAllTaskStatusesThunk } = generateThunkAndSlice(
    'getAllTaskStatuses',
    endpoint.getAllTaskStatuses,
    methods.POST
);

const { slice: getAllJobsStatuses, request: getAllJobsStatusesThunk } = generateThunkAndSlice(
    'getAllJobsStatuses',
    endpoint.getAllJobsStatuses,
    methods.GET
);

const { slice: updateStatus, request: updateStatusThunk } = generateThunkAndSlice(
    'updateStatus',
    endpoint.updateStatus,
    methods.POST
);

const { slice: archiveTaskStatus, request: archiveTaskStatusThunk } = generateThunkAndSlice(
    'archiveTaskStatus',
    endpoint.archiveTaskStatus,
    methods.POST
);
const { request: archiveJobStatusThunk } = generateThunkAndSlice(
    'archiveJobStatus',
    endpoint.archiveJobStatus,
    methods.DELETE
);

const { slice: changeSequence, request: changeSequenceThunk } = generateThunkAndSlice(
    'changeSequence',
    endpoint.changeTaskSequence,
    methods.POST
);
const { request: changeJobSequenceThunk } = generateThunkAndSlice(
    'changeJobSequence',
    endpoint.changeJobSequence,
    methods.POST
);

const { slice: getAllTaskType, request: getAllTaskTypeThunk } = generateThunkAndSlice(
    'getAllTaskType',
    endpoint.getAllTaskType,
    methods.POST
);

const { slice: getAllPrebuiltList, request: getAllPrebuiltListThunk } = generateThunkAndSlice(
    'getAllPrebuiltList',
    endpoint.getAllPrebuiltList,
    methods.POST
);

const { slice: archiveTaskType, request: archiveTaskTypeThunk } = generateThunkAndSlice(
    'archiveTaskType',
    endpoint.archiveTaskType,
    methods.POST
);
const { slice: archivePrebuildChecklist, request: archivePrebuildChecklistThunk } = generateThunkAndSlice(
    'archivePrebuildChecklist',
    endpoint.archivePrebuildChecklist,
    methods.POST
);

const { slice: addTask, request: addTaskThunk } = generateThunkAndSlice('addTask', endpoint.addTask, methods.POST);
const { request: editTaskThunk } = generateThunkAndSlice('editTask', endpoint.editTask, methods.POST);

const { slice: getAllTasks, request: getAllTasksThunk } = generateThunkAndSlice(
    'getAllTasks',
    endpoint.getAllTasks,
    methods.POST
);

const { slice: getAllCompletedTasks, request: getAllCompletedTasksThunk } = generateThunkAndSlice(
    'getAllCompletedTasks',
    endpoint.getAllCompletedTasks,
    methods.POST
);
const { slice: getEmployeeTask, request: getEmployeeTaskThunk } = generateThunkAndSlice(
    'getEmployeeTask',
    endpoint.getEmployeeTask,
    methods.POST
);

const { slice: getSingleTask, request: getSingleTaskThunk } = generateThunkAndSlice(
    'getSingleTask',
    endpoint.getSingleTask,
    methods.POST
);

const { slice: removeEmployeeFromTask, request: removeEmployeeFromTaskThunk } = generateThunkAndSlice(
    'removeEmployeeFromTask',
    endpoint.removeEmployeeFromTask,
    methods.POST
);

const { slice: updateChecklistItem, request: updateChecklistItemThunk } = generateThunkAndSlice(
    'updateChecklistItem',
    endpoint.updateChecklistItem,
    methods.POST
);

const { slice: createChecklistItem, request: createChecklistItemThunk } = generateThunkAndSlice(
    'createChecklistItem',
    endpoint.createChecklistItem,
    methods.POST
);

const { slice: updateTask, request: updateTaskThunk } = generateThunkAndSlice(
    'updateTask',
    endpoint.updateTask,
    methods.POST
);

const { slice: removeTask, request: removeTaskThunk } = generateThunkAndSlice(
    'removeTask',
    endpoint.removeTask,
    methods.POST
);

const { slice: archiveTaskChecklist, request: archiveTaskChecklistTunk } = generateThunkAndSlice(
    'archiveTaskChecklist',
    endpoint.archiveTaskChecklist,
    methods.POST
);

const { slice: archiveTaskChecklistItem, request: archiveTaskChecklistItemThunk } = generateThunkAndSlice(
    'archiveTaskchecklistItemThunk',
    endpoint.archiveTaskChecklistItem,
    methods.POST
);

const { slice: addEmployeeToTask, request: addEmployeeToTaskThunk } = generateThunkAndSlice(
    'addEmployeeToTask',
    endpoint.addEmployeeToTask,
    methods.POST
);

const { slice: uploadTaskFiles, request: uploadTaskFilesThunk } = generateThunkAndSlice(
    'uploadTaskFiles',
    endpoint.uploadTaskFiles,
    methods.POST
);

const { slice: createTaskComment, request: createTaskCommentThunk } = generateThunkAndSlice(
    'createTaskComment',
    endpoint.createTaskComment,
    methods.POST
);

const { slice: getActivity, request: getActivityThunk } = generateThunkAndSlice(
    'getActivity',
    endpoint.getActivity,
    methods.POST
);

const { slice: deleteActivity, request: deleteActivityThunk } = generateThunkAndSlice(
    'deleteActivity',
    endpoint.deleteActivity,
    methods.POST
);

const { slice: deleteSingleCommentFile, request: deleteSingleCommentFileThunk } = generateThunkAndSlice(
    'deleteSingleCommentFile',
    endpoint.deleteSingleCommentFile,
    methods.POST
);

const { slice: updateActivity, request: updateActivityThunk } = generateThunkAndSlice(
    'updateActivity',
    endpoint.updateActivity,
    methods.POST
);

const { slice: getTaskLogs, request: getTaskLogsThunk } = generateThunkAndSlice(
    'getTaskLogs',
    endpoint.getTaskLogs,
    methods.POST
);

const { slice: uploadTaskFile, request: uploadTaskFileThunk } = generateThunkAndSlice(
    'uploadTaskFile',
    endpoint.uploadTaskFile,
    methods.POST
);

const { slice: getAllTasksLogs, request: getAllTasksLogsThunk } = generateThunkAndSlice(
    'getAllTasksLogs',
    endpoint.getAllTasksLogs,
    methods.POST
);

const { slice: archiveTaskFile, request: archiveTaskFileThunk } = generateThunkAndSlice(
    'archiveTaskfile',
    endpoint.archiveTaskFile,
    methods.POST
);
const { slice: shareTask, request: shareTaskThunk } = generateThunkAndSlice(
    'shareTask',
    endpoint.shareTask,
    methods.POST
);
const { slice: markAsCompleteTask, request: markAsCompleteTaskThunk } = generateThunkAndSlice(
    'markAsCompleteTask',
    endpoint.markAsCompleteTask,
    methods.POST
);

const { slice: countByType, request: countByTypeThunk } = generateThunkAndSlice(
    'countByType',
    endpoint.countByType,
    methods.POST
);
const { slice: getFullTasks, request: getFullTasksThunk } = generateThunkAndSlice(
    'getFullTasks',
    endpoint.getFullTasks,
    methods.POST
);

const { slice: getTaskReportsInfo, request: getTaskReportsInfoThunk } = generateThunkAndSlice(
    'getTaskReportsInfo',
    endpoint.getTaskReportsInfo,
    methods.POST
);

const { slice: updateTaskType, request: updateTaskTypeThunk } = generateThunkAndSlice(
    'updateTaskType',
    endpoint.updateTaskType,
    methods.POST
);

const { slice: getTaskHistory, request: getTaskHistoryThunk } = generateThunkAndSlice(
    'getTaskHistory',
    endpoint.getTaskHistory,
    methods.POST
);

export {
    getEmployeeTask,
    getEmployeeTaskThunk,
    createTaskStatusThunk,
    createJobsStatusThunk,
    updateJobsStatusThunk,
    createTaskTypeThunk,
    createChecklistThunk,
    updateChecklistThunk,
    getChecklistDetailsThunk,
    getAllTaskStatuses,
    getAllTaskStatusesThunk,
    getAllJobsStatuses,
    getAllJobsStatusesThunk,
    updateStatusThunk,
    archiveTaskStatusThunk,
    archiveJobStatusThunk,
    changeSequenceThunk,
    changeJobSequenceThunk,
    getAllTaskTypeThunk,
    getAllTaskType,
    getAllPrebuiltListThunk,
    getAllPrebuiltList,
    archiveTaskTypeThunk,
    archivePrebuildChecklistThunk,
    addTaskThunk,
    editTaskThunk,
    getAllTasks,
    getAllTasksThunk,
    getAllCompletedTasks,
    getAllCompletedTasksThunk,
    getSingleTask,
    getSingleTaskThunk,
    removeEmployeeFromTask,
    removeEmployeeFromTaskThunk,
    updateChecklistItemThunk,
    createChecklistItemThunk,
    updateTaskThunk,
    removeTaskThunk,
    archiveTaskChecklistTunk,
    archiveTaskChecklistItemThunk,
    addEmployeeToTaskThunk,
    uploadTaskFilesThunk,
    createTaskCommentThunk,
    getActivityThunk,
    getActivity,
    deleteActivityThunk,
    deleteSingleCommentFileThunk,
    updateActivityThunk,
    getTaskLogs,
    getTaskLogsThunk,
    uploadTaskFileThunk,
    archiveTaskFileThunk,
    getAllTasksLogs,
    getAllTasksLogsThunk,
    shareTaskThunk,
    markAsCompleteTaskThunk,
    countByTypeThunk,
    countByType,
    getFullTasks,
    getFullTasksThunk,
    getTaskReportsInfo,
    getTaskReportsInfoThunk,
    updateTaskTypeThunk,
    getTaskHistoryThunk,
    getTaskHistory
};
