import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import Slideover from 'components/atoms/SlideOver';
import { UserChip } from 'components/atoms/UserChip/UserChip';
import { Button } from 'components/atoms/buttons/Button';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { Loader } from 'components/common/Loader';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';
import { SearchInput } from 'components/molecules/SearchInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { createRequestDocumentThunk } from 'store/JobManager';
import { searchClientsThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { getTemplateDocThunk } from 'store/settings/JobPortal';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { sortItemsAlphabetically } from 'utills/sortAndFilter';
import { generateId } from 'utills/uid';

const AddRequestDoc = ({ open, setOpen, handleUpdateTable, clients }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { id } = useParams();
    const dispatch = useDispatch();

    const { data: getTemplateDoc } = useSelector((state) => state?.getTemplateDoc);
    const { data: getSingleJob } = useSelector((state) => state?.getSingleJob);
    const [dataList, setDataList] = useState(null);
    const [note, setNote] = useState('');

    const [query, setQuery] = useState('');
    const [selectedClients, setSelectedClients] = useState([]);
    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    useEffect(() => {
        if (open && !getTemplateDoc) {
            dispatch(getTemplateDocThunk({ business_id }));
        } else {
            setDataList(getTemplateDoc);
        }
    }, [getTemplateDoc, open]);

    const filterData = dataList ? sortItemsAlphabetically(dataList, 'title') : [];

    const filteredDataWithQuery =
        query === ''
            ? filterData
            : filterData.filter((listObj) => {
                  return listObj.title.toLowerCase().includes(query.toLowerCase());
              });

    const { isLoading } = useSelector((state) => state.global);
    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(() => {
        setCheckedItems([]);
    }, [open]);

    const handleCheckboxChange = (event, obj) => {
        const { checked } = event.target;
        if (checked) {
            setCheckedItems((prevState) => [...prevState, obj]);
        } else {
            setCheckedItems((prevState) => prevState.filter((item) => item !== obj));
        }
    };

    const handleClick = () => {
        const documentsList = [...checkedItems.map((item) => item.title), ...fields.map((item) => item.text)].filter(
            (text) => text !== ''
        );

        if (documentsList?.length === 0) {
            return toastHandler('Select or add at least 1 Request Document Name', TOAST_TYPE_ERROR);
        }

        if (selectedClients?.length === 0) {
            return toastHandler('Select at least 1 Client', TOAST_TYPE_ERROR);
        }

        const selectedClientList = selectedClients?.map((obj) => obj?.business_client_id);

        dispatch(setLoader(true));

        const payloadJson = {
            client_ids: selectedClientList,
            names: documentsList,
            business_id: business_id,
            job_id: id,
            note: note
        };
        dispatch(createRequestDocumentThunk(payloadJson))
            .then((response) => {
                if (response.payload) {
                    setNote('');
                    setSelectedClients([]);
                    setCheckedItems([]);
                    setFields([
                        {
                            id: generateId(),
                            text: ''
                        }
                    ]);
                    setOpen(false);
                    handleUpdateTable();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (clients?.length > 0) {
            setSelectedClients([...selectedClients, ...clients]);
        }
    }, []);

    const handleSelectClient = (client) => {
        const isClientAlreadySelected = selectedClients?.some(
            (item) => item?.business_client_id === client?.business_client_id
        );
        if (isClientAlreadySelected) {
            return toastHandler('Client already selected', TOAST_TYPE_ERROR);
        }

        if (client?.client_category !== 'Individual') {
            const filterNonIndClients = getSingleJob?.clients?.filter((obj) => obj?.client_category !== 'Individual');
            if (filterNonIndClients.length > 0) {
                const isSelectedClientExistInjob = getSingleJob?.clients?.filter(
                    (obj) => obj?.business_client_id === client?.business_client_id
                );
                if (isSelectedClientExistInjob.length === 0) {
                    return toastHandler('Only one non Individual client can be selected', TOAST_TYPE_ERROR);
                }
            }
            const filterNonIndSelectedClients = selectedClients?.filter((obj) => obj?.client_category !== 'Individual');
            if (filterNonIndSelectedClients.length > 0) {
                return toastHandler('Only one non Individual client can be selected', TOAST_TYPE_ERROR);
            }
        }

        setSelectedClients([...selectedClients, client]);
    };

    useEffect(() => {
        if (clients?.length > 0) {
            setSelectedClients([...selectedClients, ...clients]);
        }
    }, []);

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleClientRemove = (index) => {
        const newClients = [...selectedClients];
        newClients.splice(index, 1);
        setSelectedClients(newClients);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Slideover
                open={open}
                setOpen={setOpen}
                hideBackArrow
                title={'Request Document'}
                titlesx={'!font-semibold'}
                footer={
                    <div className="">
                        <div className="flex items-center justify-center mt-9 mb-4">
                            <Button
                                title={`Send Request`}
                                classes="flex items-center gap-2 py-3 px-4 sm:px-12 !rounded-xl bg-primary child:text-semibold text-white text-[16px]"
                                atClick={() => handleClick()}
                            />
                        </div>
                    </div>
                }
            >
                <div className=" mt-5">
                    <div className="mb-5">
                        <SearchInput atChange={(e) => setQuery(e.target.value)} placeholder={'Search'} />
                    </div>
                    <div className="">
                        <PrimaryLabel>Prebuild Documents List</PrimaryLabel>
                        <div className="mt-2 max-h-[250px] rounded-xl  overflow-auto border  ">
                            <div className="m-2">
                                {filteredDataWithQuery?.length === 0 && (
                                    <p className="text-sm text-gray-400">Not Found</p>
                                )}
                                {filteredDataWithQuery?.length > 0 &&
                                    filteredDataWithQuery?.map((obj, personIdx) => (
                                        <PrimaryCheckbox
                                            id={`person-${obj.template_job_document_id}`}
                                            name={`person-${obj.template_job_document_id}`}
                                            htmlFor={`person-${obj.template_job_document_id}`}
                                            onchange={(event) => handleCheckboxChange(event, obj)}
                                            checked={checkedItems.includes(obj)}
                                            label={obj?.title}
                                            key={personIdx}
                                            sx="mt-2"
                                        />
                                    ))}
                            </div>
                        </div>
                        <div className="mt-5">
                            <PrimaryLabel>Enter Manually</PrimaryLabel>

                            <MultiTextInputAddComponent
                                placeholder="Enter Required Document Name"
                                fields={fields}
                                setFields={setFields}
                            />
                        </div>
                        <div className="mt-5">
                            <PrimaryLabel> Clients</PrimaryLabel>
                            <ComboboxComponentSSR
                                preventLoadingInfo
                                hideSelectedValues
                                fetchDataFunction={(payload) =>
                                    fetchReferencesListFun({
                                        ...payload,
                                        type: ''
                                    })
                                }
                                removePreviousSelected
                                onChange={(res) => {
                                    const obj = res[0];
                                    handleSelectClient(obj);
                                }}
                                targetProperty="business_client_id"
                                placeholder="Search by name/reference number/email"
                                sx="rounded-xl ring-gray-400"
                            />
                            {selectedClients?.length > 0 && (
                                <div className="mt-5 mb-4 flex gap-3 items-center flex-wrap">
                                    {selectedClients?.map((client, index) => (
                                        <div key={index}>
                                            <UserChip obj={client} onClick={() => handleClientRemove(index)} />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="mt-5">
                            <PrimaryLabel>Note</PrimaryLabel>
                            <DefaultTextArea
                                onChange={(e) => setNote(e)}
                                value={note}
                                placeholder={'Enter note'}
                                sx="mt-2"
                                maxCount={50}
                            />
                        </div>
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default AddRequestDoc;
