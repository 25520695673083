import React from 'react';
import WorkplaceApps from './workplace-apps/WorkplaceApps';
const WorkplaceContent = ({ toggleShowAddApp, appsUpdated, toggleEditApp }) => {
    return (
        <div>
            <WorkplaceApps
                toggleShowAddApp={toggleShowAddApp}
                appsUpdated={appsUpdated}
                toggleEditApp={toggleEditApp}
            />
        </div>
    );
};

export default WorkplaceContent;
