import { StarIcon } from '@heroicons/react/20/solid';
import { Chip } from 'components/atoms/buttons/Chip';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';
import Pulse from 'components/atoms/Pulse';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import useReadActivityHook from 'Hooks/job-manager/useReadActivityHook';
import useRoleGuard from 'Hooks/useRoleGuard';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { relayRoomEmailStarredThunk } from 'store/JobManager';
import {
    BUSINESS_ID,
    generateRandomProfileLink,
    getProfileName,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from 'utills/globalVars';
import { DateFormatToLocal } from 'utills/moment';
import { AttachmentIcon2 } from 'utills/svgs/AttachmentIcon2';
import { CalendarIcon3 } from 'utills/svgs/CalendarIcon3';
import { ClockIcon } from 'utills/svgs/ClockIcon';
import { cn } from 'utills/tailwindUtil';
import { truncateHtml } from 'utills/uid';

const RelayRoomLeftbarMenuComponent = ({
    dataObj,
    selected_ids,
    handleSelectThread,
    active_id,
    email_type,
    getActivityCount = []
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { hasPermissions } = useRoleGuard();

    const [isFav, setIsFav] = useState(dataObj?.is_favourite?.toLowerCase() === 'true' || false);
    const [isRead, setIsRead] = useState(dataObj?.is_read === 'true' || false);

    const foundObj = getActivityCount.find((item) => item.id === dataObj?.job_email_id);
    const { markCompleteJobAtion } = useReadActivityHook();

    const handleClick = (dataObj) => {
        if (dataObj?.job_email_id) {
            setIsRead(true);
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.set('job_email_id', dataObj.job_email_id);
            navigate({
                search: currentParams.toString()
            });
        }
    };

    const mainThreadObj = useMemo(() => {
        if (email_type === 'received' && ['received']?.includes(dataObj?.email_type)) {
            return dataObj?.from_details
                ? dataObj?.from_details
                : {
                      display_name: dataObj?.from[0],
                      email: dataObj?.from[0]
                  };
        } else {
            return dataObj?.to_details?.length > 0
                ? dataObj?.to_details[0]
                : {
                      display_name: dataObj?.to[0],
                      email: dataObj?.to[0]
                  };
        }
    }, [dataObj]);

    const truncatedHtml = truncateHtml(dataObj?.body, 50);

    const getStatusColor = (statusValue) => {
        switch (statusValue) {
            case 'pending':
                return 'bg-[#F08201]/10 text-[#F08201]';
            default:
                return 'bg-[#008C51]/10 text-[#008C51]';
        }
    };

    const handleIsFav = (job_email_id) => {
        if (job_email_id) {
            dispatch(setLoader(true));
            dispatch(
                relayRoomEmailStarredThunk({
                    job_email_id: job_email_id,
                    business_id: business_id,
                    is_favourite: (!isFav).toString()
                })
            )
                .then((response) => {
                    if (response?.payload) {
                        toastHandler(
                            !isFav
                                ? 'The email has been marked as favorite successfully'
                                : 'The email has been removed from favorites.',
                            TOAST_TYPE_SUCCESS
                        );

                        setIsFav(!isFav);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };
    const renderHtmlContent = (content) => {
        // Parse the content into React components manually
        const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, '');
        return strippedContent.split('\n').map((line, index) => <p key={index}>{line}</p>);
    };
    return (
        <>
            <div
                className={cn(
                    'flex items-start justify-start py-3 px-3 w-full hover:bg-thirdbg1/10  hover:bg-gray-50 cursor-pointer',
                    email_type !== 'send' && !isRead ? '!bg-[#b695f81a]' : 'bg-white',

                    active_id === dataObj?.job_email_id ? 'bg-[#b695f81a]' : ''
                )}
            >
                <div className="flex flex-col gap-2 items-center justify-between min-w-[50px]">
                    <DefaultProfileViewer
                        image={
                            mainThreadObj?.logo ||
                            mainThreadObj?.image ||
                            generateRandomProfileLink(getProfileName(mainThreadObj))
                        }
                        width="40px"
                        height="40px"
                        name={mainThreadObj?.name || getProfileName(mainThreadObj)}
                        tooltip
                    />
                    <PrimaryCheckbox
                        id="select_all"
                        name="select_all"
                        checked={selected_ids?.includes(dataObj?.job_email_id)}
                        onchange={() => {
                            if (!hasPermissions('Job', ['write', 'admin'])) {
                                return toastHandler('You dont have permission to do this action.', TOAST_TYPE_ERROR);
                            }
                            handleSelectThread(dataObj?.job_email_id);
                        }}
                        inputSx="!border-third"
                    />
                </div>
                <div className="relative cursor-pointer flex-1">
                    <div className="contents overflow-hidden">
                        <div className="inline-grid w-full flex-1 flex-col">
                            <div className="flex justify-between flex-col gap-2 flex-1 -space-y-2 w-full truncate">
                                <div
                                    className="flex justify-between items-center gap-2 mt-1.5"
                                    onClick={() => {
                                        handleClick(dataObj);
                                        if (foundObj) {
                                            markCompleteJobAtion({
                                                id: foundObj?.id,
                                                job_action_id: foundObj?.job_action_id
                                            });
                                        }
                                    }}
                                >
                                    <SecondaryParagraph sx="!text-base flex-1 text-[#666666] truncate">
                                        {mainThreadObj?.name || getProfileName(mainThreadObj)}
                                    </SecondaryParagraph>
                                    <div className="flex items-center gap-2">
                                        {dataObj?.attachments && dataObj?.attachments !== 0 ? (
                                            <AttachmentIcon2 />
                                        ) : (
                                            <></>
                                        )}

                                        {email_type !== 'send' && !['delivered'].includes(dataObj?.status) && (
                                            <Chip
                                                title={
                                                    dataObj?.is_read === 'false'
                                                        ? 'New'
                                                        : dataObj?.category !== 'known'
                                                        ? dataObj?.category
                                                        : ''
                                                }
                                                classes={cn(
                                                    'text-xs !capitalize',
                                                    getStatusColor(
                                                        dataObj?.is_read === 'false'
                                                            ? 'New'
                                                            : dataObj?.category !== 'known'
                                                            ? dataObj?.category
                                                            : ''
                                                    ),
                                                    dataObj?.is_read !== 'false' &&
                                                        dataObj?.category === 'known' &&
                                                        '!hidden'
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                                {foundObj && (
                                    <div className="absolute right-1 top-1">
                                        <Pulse sx={'h-3 w-3'} tooltip={foundObj?.description} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="contents overflow-hidden">
                        <div className="inline-grid w-full flex-1 flex-col" onClick={() => handleClick(dataObj)}>
                            <SecondaryParagraph sx="truncate font-medium">{dataObj?.subject}</SecondaryParagraph>
                            <div className="w-[100%] break-all overflow-hidden text-[#666666] mt-1">
                                {renderHtmlContent(truncatedHtml)}
                            </div>
                        </div>
                        <div className="flex justify-between items-start mt-3">
                            <div className="flex-1 flex items-center flex-wrap gap-2">
                                <div className="flex gap-2 items-center">
                                    <CalendarIcon3 />
                                    <SecondaryParagraph>
                                        {DateFormatToLocal({
                                            date: dataObj?.created_at,
                                            format: 'DD MMM, YYYY',
                                            showRecentDays: true
                                        })}
                                    </SecondaryParagraph>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <ClockIcon />
                                    <SecondaryParagraph>
                                        {DateFormatToLocal({
                                            date: dataObj?.created_at,
                                            format: 'hh:mm A'
                                        })}
                                    </SecondaryParagraph>
                                </div>
                            </div>

                            <StarIcon
                                className={cn(
                                    'w-5 h-5 cursor-pointer',
                                    isFav ? 'fill-third stroke-third' : 'fill-white stroke-third'
                                )}
                                onClick={() => {
                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                        return toastHandler(
                                            'You dont have permission to do this action.',
                                            TOAST_TYPE_ERROR
                                        );
                                    }
                                    handleIsFav(dataObj?.job_email_id);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RelayRoomLeftbarMenuComponent;
