import { XCircleIcon } from '@heroicons/react/24/outline';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { Button } from 'components/atoms/buttons/Button';
import Slideover from 'components/atoms/SlideOver';
import { SearchInput } from 'components/molecules/SearchInput';
import React, { useEffect, useState } from 'react';
import { crossTimes } from 'utills/svgs/crossTimes';
import { FolderIcon } from 'utills/svgs/file-icons/FolderIcon';
import EntityCreateUpdate from './EntityCreateUpdate';
import { ChevronRightIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Breadcrumb from 'components/atoms/Breadcrumb';
import { cn } from 'utills/tailwindUtil';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDocumentDriveListForActionThunk,
    setActivePath,
    setFilters,
    setJobId
} from 'store/custom-reducers/JobManagerDocumentActionsReducer';
import { useParams } from 'react-router-dom';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { formatFileSize } from 'utills/filesHandlers';
import { DateFormatToLocal } from 'utills/moment';
import { SmallLoader } from 'components/common/SmallLoader';
import { NoDataFound } from 'components/common/NoDataFound';
import { getSingleJobThunk, JobManagerCopyMoveThunk, JobManagerMoveToClientThunk } from 'store/JobManager';
import { setLoader } from 'store/global/globalReducer';
import { UserChip } from 'components/atoms/UserChip/UserChip';
import ComboboxComponent from 'components/atoms/Combobox';
import { toastHandler } from 'responseHanlder';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';

const MoveOrCopy = ({
    open = false,
    setOpen,
    title,
    list,
    setFileList,
    getMainTableData = () => {},
    action,
    job_id
}) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { id: paramsId } = useParams();
    const id = job_id ? job_id : paramsId;
    const [createFolder, setCreateFolder] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const destinationFoldersList = [
        { value: 'accountant', label: 'Accountant' },
        {
            value: 'legal-document',
            label: 'Legal Document'
        }
    ];

    const { activePath, data, filters, globalLoading } = useSelector((state) => state.jobManagerDocForActionsReducer);

    useEffect(() => {
        setCurrentStep(1);
        getData();
        return () => {
            setCurrentStep(1);
            dispatch(setActivePath([]));
        };
    }, []);

    const getData = (parentObj = null, parent_id_Only = null) => {
        if (parentObj) {
            if (parentObj?.job_drive_id !== '') {
                const splitArray = activePath.slice(
                    0,
                    activePath.findIndex((obj) => obj.job_drive_id === parentObj?.job_drive_id) + 1
                );
                dispatch(setActivePath(splitArray?.length > 0 ? splitArray : [...activePath, parentObj]));
            } else {
                dispatch(setActivePath([]));
            }
        }

        const jsonData = {
            job_id: id,
            business_id,
            ...(parent_id_Only
                ? { parent_id: parent_id_Only }
                : parentObj?.job_drive_id
                ? { parent_id: parentObj?.job_drive_id }
                : {})
        };
        dispatch(setJobId(id));
        dispatch(getDocumentDriveListForActionThunk(jsonData));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        dispatch(
            setFilters({
                ...filters,
                [name]: value
            })
        );
    };

    const handleInternalClick = async (obj) => {
        await getData(obj);
    };

    const activeDocPath = () => {
        let initialArray = [{ link: '', title: 'Job Documents' }];
        if (activePath?.length > 0) {
            let updatedArray = activePath?.map((pathObj) => ({
                link: pathObj?.job_drive_id,
                title: pathObj?.name || ''
            }));
            initialArray = initialArray.concat(updatedArray);
        }
        return initialArray;
    };

    const handleActionFunc = () => {
        if (action !== 'moveToClient') {
            const payloadJSON = {
                business_id,
                action: action,
                job_id: id,
                job_drive_ids: list?.map((obj) => obj?.job_drive_id),
                target_drive_id: ''
            };
            if (activePath?.length > 0) {
                payloadJSON.target_drive_id = activePath?.at(-1)?.job_drive_id;
            }
            dispatch(setLoader(true));
            dispatch(JobManagerCopyMoveThunk(payloadJSON))
                .then((res) => {
                    if (res.payload) {
                        getData();
                        getMainTableData(null, activePath?.length > 0 ? activePath?.at(-1)?.job_drive_id : null);
                        setFileList([]);
                        setOpen(null);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const { data: job } = useSelector((state) => state?.getSingleJob);
    const [selectedClients, setSelectedClients] = useState([]);

    useEffect(() => {
        if (!job) {
            dispatch(getSingleJobThunk({ job_id: id }));
        }
    }, [job]);

    const handleSelectClient = (client) => {
        const isClientAlreadySelected = selectedClients?.some(
            (item) => item?.business_client_id === client?.business_client_id
        );
        if (isClientAlreadySelected) {
            return false;
        }
        setSelectedClients([...selectedClients, { ...client, folder: 'accountant' }]);
        // setSelectedClients([client]);
    };

    const handleClientRemove = (index) => {
        const newClients = [...selectedClients];
        newClients.splice(index, 1);
        setSelectedClients(newClients);
    };

    const handleMoveToClientFun = () => {
        if (currentStep === 1) {
            setCurrentStep(2);
            return false;
        }
        if (selectedClients?.length === 0) {
            return toastHandler('Please Select at least 1 client', TOAST_TYPE_ERROR);
        }
        const payloadJSON = {
            business_id,
            job_id: id,
            item_ids: list?.map((obj) => obj?.job_drive_id),
            // business_client_id: selectedClients[0]?.business_client_id
            // business_client_id: selectedClients?.map((obj) => obj?.business_client_id)
            clients: selectedClients?.map((obj) => ({
                business_client_id: obj?.business_client_id,
                folder: obj?.folder
            }))
        };

        dispatch(setLoader(true));
        dispatch(JobManagerMoveToClientThunk(payloadJSON))
            .then((res) => {
                if (res.payload) {
                    setSelectedClients([]);
                    getMainTableData(null, activePath?.length > 0 ? activePath?.at(-1)?.job_drive_id : null);
                    setFileList([]);
                    setOpen(null);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleSelectId = (obj) => {
        const newIds = [...list];
        const findId = newIds?.find((item) => item?.job_drive_id === obj?.job_drive_id);
        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setFileList(newIds);
        } else {
            newIds.push(obj);
            setFileList(newIds);
        }
    };

    const handleSelectedFolderDestination = (obj, index) => {
        setSelectedClients((prev) => {
            return prev.map((prevObj, obj_ind) => {
                return {
                    ...prevObj,
                    folder: obj_ind === index ? obj?.value ?? '' : prevObj?.folder
                };
            });
        });
    };
    return (
        <div>
            <Slideover
                size="max-w-[1400px]"
                open={open}
                setOpen={setOpen}
                title={title}
                titlesx={'text-3xl font-fira font-[500]'}
                hideBackArrow
                footer={
                    <div className="flex pl-0 lg:pl-28 items-center justify-center gap-4">
                        <Button
                            title={currentStep === 2 ? 'Back' : 'Cancel'}
                            classes={
                                'border-blueish text-blueish border-2 h-[3rem] rounded-xl !font-medium font-fira px-12'
                            }
                            atClick={() => {
                                if (currentStep === 2) {
                                    setCurrentStep(1);
                                    return false;
                                }
                                setFileList([]); // Clear the file list
                                setOpen(null);
                            }}
                            type="button"
                        />
                        <Button
                            title={action === 'moveToClient' && currentStep === 1 ? 'Next' : title}
                            disabled={list?.length === 0}
                            classes={cn(
                                'rounded-xl h-[3.2rem] !font-medium font-fira !text-white ann-btn',
                                action === 'moveToClient' && currentStep === 1 ? 'px-14' : 'px-7'
                            )}
                            atClick={() => (action === 'moveToClient' ? handleMoveToClientFun() : handleActionFunc())}
                        />
                    </div>
                }
            >
                <div className="grid grid-col lg:grid-cols-3 gap-4 mt-6 lg:h-[90%]">
                    <div className="default-opacity-bg px-4 py-3 rounded-lg max-h-[700px] overflow-auto">
                        <div className="w-full flex justify-between items-center">
                            <div className="text-xl font-[500] py-1.5">Selected Files</div>
                            {list?.length !== 0 && (
                                <Button
                                    title={'Clear All'}
                                    classes={
                                        'flex items-center gap-1 !px-2 text-third cursor-pointer py-1.5 rounded-xl hover:default-opacity-bg hover:!shadow-none'
                                    }
                                    iconSet={{ leftIcon: crossTimes }}
                                    atClick={() => {
                                        setFileList([]);
                                        setOpen(null);
                                    }}
                                />
                            )}
                        </div>
                        <div className="my-2">
                            {list?.map((obj, index) => (
                                <div key={'selected_item' + index} className="flex items-center justify-between my-1">
                                    <div className="flex flex-1 items-center gap-2 overflow-hidden truncate">
                                        {obj?.type === 'file' ? (
                                            <GenerateFileIcon url={obj?.url} sx={'w-8 my-1'} />
                                        ) : (
                                            <FolderIcon className={'w-8'} />
                                        )}
                                        <div className="flex flex-col">
                                            <div className="app-obj-name flex items-center gap-2 truncate">
                                                {obj?.name}
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        classes={
                                            'flex items-center !px-2 cursor-pointer py-1.5 rounded-xl hover:default-opacity-bg hover:!shadow-none'
                                        }
                                        iconSet={{ leftIconHTML: <XCircleIcon className="w-6 h-6" /> }}
                                        atClick={() => {
                                            setFileList((prevList) =>
                                                prevList.filter(
                                                    (listObj) => listObj?.job_drive_id !== obj?.job_drive_id
                                                )
                                            );
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {currentStep === 1 && (
                        <div className="lg:col-span-2 px-4">
                            <div className="flex justify-start items-center flex-wrap">
                                <SearchInput
                                    width="100%"
                                    defaultValue={filters?.search_term || ''}
                                    atChange={(e) => {
                                        handleInputChange(e);
                                    }}
                                    placeholder="Search"
                                />
                            </div>
                            <div className="w-full flex justify-between items-center mt-5">
                                <div className="text-lg font-[400]">
                                    {action !== 'moveToClient' ? 'Select Destination' : 'Select Files and Folders'}
                                </div>
                                {action !== 'moveToClient' && (
                                    <Button
                                        title={'Create Folder'}
                                        classes={
                                            '!px-3 text-white bg-third !text-sm cursor-pointer py-2  !rounded-full hover:default-opacity-bg'
                                        }
                                        atClick={() => {
                                            setCreateFolder(true);
                                        }}
                                    />
                                )}
                            </div>
                            <div className="flex flex-wrap mb-2">
                                {!globalLoading && (
                                    <Breadcrumb
                                        path={activeDocPath()}
                                        onClick={(obj) => {
                                            getData({
                                                job_drive_id: obj?.link,
                                                name: obj?.title
                                            });
                                        }}
                                    />
                                )}
                            </div>
                            <div className="mt-5 overflow-auto max-h-[550px]">
                                {data &&
                                    data?.length > 0 &&
                                    [...data]
                                        ?.filter((obj) => {
                                            let isFound = list.find(
                                                (selectedItem) => selectedItem?.job_drive_id === obj?.job_drive_id
                                            );
                                            if (obj?.type === 'folder' && isFound) {
                                                return false;
                                            }
                                            return true;
                                        })
                                        ?.sort((a, b) => {
                                            if (a?.type === 'folder' && b?.type !== 'folder') {
                                                return -1;
                                            } else if (a?.type !== 'folder' && b?.type === 'folder') {
                                                return 1;
                                            } else {
                                                return 0;
                                            }
                                        })
                                        ?.map((dataObj, index) => {
                                            return (
                                                <div
                                                    className="flex items-center justify-between my-2 hover:bg-gray-100 rounded-lg pl-2"
                                                    key={'tableList' + index}
                                                >
                                                    <div className="flex items-center gap-2">
                                                        {action === 'moveToClient' && (
                                                            <div className="min-w-[10px]">
                                                                <input
                                                                    type="checkbox"
                                                                    className="rounded-[3px] cursor-pointer"
                                                                    checked={
                                                                        list?.some(
                                                                            (item) =>
                                                                                item.job_drive_id ===
                                                                                dataObj.job_drive_id
                                                                        ) || false
                                                                    }
                                                                    onChange={() => handleSelectId(dataObj)}
                                                                />
                                                            </div>
                                                        )}
                                                        <div
                                                            className={cn(
                                                                'flex items-center gap-4 cursor-pointer',
                                                                action !== 'moveToClient' &&
                                                                    dataObj?.type !== 'folder' &&
                                                                    '!opacity-25 pointer-events-none'
                                                            )}
                                                            onClick={() => {
                                                                if (dataObj?.type === 'folder') {
                                                                    handleInternalClick({
                                                                        job_drive_id: dataObj?.job_drive_id,
                                                                        name: dataObj?.name
                                                                    });
                                                                } else {
                                                                    if (action === 'moveToClient') {
                                                                        handleSelectId(dataObj);
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {dataObj?.type === 'file' ? (
                                                                <GenerateFileIcon url={dataObj?.url} sx={'w-10'} />
                                                            ) : (
                                                                <FolderIcon className={'w-10'} />
                                                            )}

                                                            <div className="flex flex-col">
                                                                <div className="app-dataObj-name flex items-center gap-2">
                                                                    <div>{dataObj?.name}</div>
                                                                </div>
                                                                <div className="recently-added text-gray-600 text-xs app-dataObj-email break-all">
                                                                    {dataObj?.size && dataObj?.size !== 'null'
                                                                        ? formatFileSize(dataObj?.size / 1024 ?? 0)
                                                                        : '__'}{' '}
                                                                    &bull;{' '}
                                                                    {dataObj?.updated_at
                                                                        ? DateFormatToLocal({
                                                                              date: dataObj?.updated_at,
                                                                              format: 'DD MMM, YYYY'
                                                                          })
                                                                        : '__'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {dataObj?.type === 'folder' && (
                                                            <ChevronRightIcon className="w-6 h-7 text-[#979797]" />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </div>
                            <div className="flex w-full items-center justify-center">
                                {(globalLoading || !data) && <SmallLoader />}
                                {!globalLoading && data?.length === 0 && (
                                    <NoDataFound
                                        classes="border-y border-gray-300 w-full"
                                        message="No Data Found!"
                                        height="420px"
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {currentStep === 2 && (
                        <div className="lg:col-span-2 px-4">
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-5 w-5 text-yellow-400"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                                        <div className="mt-2 text-sm text-yellow-700">
                                            <p>
                                                Note: All Selected Files/Folders will be moved to the Selected
                                                Client&apos;s Document Hub in Accountant&apos;s Folder
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5">
                                <label className="text-xl font-medium"> Select Client </label>
                                {job?.clients && (
                                    <ComboboxComponent
                                        data={job?.clients}
                                        selectedValue={''}
                                        hideSelectedValues
                                        inputsx="!font-normal !text-sm w-full"
                                        sx="!rounded-xl !ring-gray-400 mt-2 !flex"
                                        placeholder="Search by name/reference number/email"
                                        onChange={(res) => {
                                            const obj = res[0];
                                            if (obj) {
                                                handleSelectClient(obj);
                                            }
                                        }}
                                        targetProperty="business_client_id"
                                    />
                                )}
                                {/* <div className="flex gap-5 flex-col items-start flex-nowrap mt-5 max-h-[550px] overflow-auto">
                                    {job?.clients?.map((client, index) => (
                                        <div key={'client_list' + index} className="w-full">
                                            <CheckboxChip
                                                p={'pr-24 !h-[auto] w-[80%]'}
                                                value={getProfileName(client)}
                                                selectedValue={
                                                    selectedClients?.find(
                                                        (item) =>
                                                            item?.business_client_id === client?.business_client_id
                                                    )
                                                        ? getProfileName(client)
                                                        : ''
                                                }
                                                profileEmail={client?.email}
                                                profile={
                                                    <DefaultProfileViewer
                                                        image={client?.image}
                                                        width="45px"
                                                        height="45px"
                                                        name={getProfileName(client)}
                                                    />
                                                }
                                                onChange={(_value) => handleSelectClient(client)}
                                            />
                                        </div>
                                    ))}
                                </div> */}

                                <div className="mt-5 flex gap-3 items-center flex-wrap">
                                    {/* {selectedClients?.map((client, index) => (
                                        <div key={'client_' + index}>
                                            <UserChip obj={client} onClick={() => handleClientRemove(index)} />
                                        </div>
                                    ))} */}

                                    {selectedClients?.length > 0 && (
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pr-3 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                    >
                                                        Client
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="py-3.5 pr-3 pl-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                    >
                                                        Destination Folder
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {selectedClients?.map((client, index) => (
                                                    <tr key={'client_' + index}>
                                                        <td className="py-4 pr-3 pl-4 text-sm font-medium whitespace-nowrap text-gray-900 sm:pl-5">
                                                            <UserChip obj={client} sx="!bg-transparent !text-black" />
                                                        </td>
                                                        <td className="py-4 pr-3 pl-4 text-sm font-medium whitespace-nowrap text-gray-900 sm:pl-5">
                                                            <SimpleSelectMenu
                                                                placeholder="Select"
                                                                targetProperty="label"
                                                                selectedValue={client?.folder || 'accountant'}
                                                                valuePropertyName="value"
                                                                optionsData={
                                                                    client?.client_category === 'Individual'
                                                                        ? destinationFoldersList.filter(
                                                                              (obj) => obj?.value !== 'legal-document'
                                                                          )
                                                                        : destinationFoldersList
                                                                }
                                                                sx="rounded-xl py-2.5 max-w-[150px] ring-gray-400 !font-normal cursor-pointer"
                                                                onChangeValue={(obj) =>
                                                                    handleSelectedFolderDestination(obj, index)
                                                                }
                                                            />
                                                        </td>
                                                        <td className="relative py-4 pr-4 pl-3 text-right text-sm font-medium whitespace-nowrap sm:pr-5">
                                                            <div
                                                                onClick={() => handleClientRemove(index)}
                                                                className="  ml-2 cursor-pointer"
                                                            >
                                                                <div className="bg-white rounded-full p-[0.15rem]">
                                                                    <XMarkIcon
                                                                        className="h-5 w-5 text-black"
                                                                        aria-hidden="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <EntityCreateUpdate
                    open={createFolder}
                    setOpen={setCreateFolder}
                    getData={getData}
                    currentList={data}
                    editObj={{
                        parent_id: activePath?.length > 0 ? activePath.at(-1)?.job_drive_id : null
                    }}
                />
            </Slideover>
        </div>
    );
};

export default MoveOrCopy;
