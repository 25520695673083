import React from 'react';
import { Menu } from 'components/molecules/Menu';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
const TaskTypeOperations = ({ toggleTaskType, _addSearchTerm, sortAsc, handleSort }) => {
    return (
        <div className=" mt-2 ">
            <div className="flex justify-between">
                <div>
                    <Menu
                        title="Sort By"
                        iconSet={{ first: SortIcon, second: StepDownIcon }}
                        gap={2}
                        lastMl={5}
                        isModalDisabled={true}
                        condition={sortAsc}
                        border=""
                        atMenuClick={() => {
                            handleSort();
                        }}
                        text="gray-900"
                        sx="!border-gray-400 !rounded-[12px] small-text font-poppins customFilters"
                    />
                </div>
                <div>
                    <button onClick={toggleTaskType} className="ann-btn px-12 py-2  rounded-md">
                        + Add
                    </button>
                </div>
            </div>
        </div>
    );
};
export default TaskTypeOperations;
