/* eslint-disable no-useless-escape */
/* eslint-disable no-prototype-builtins */
import dayjs from 'dayjs';

/* eslint-disable no-unused-vars */
export const isAnyValueEmpty = (questionsArray) => {
    return questionsArray.some((question) => question.value === '');
};

export const isQuestionIdRepeated = (questionsArray) => {
    const questionIdsSet = new Set();

    for (const question of questionsArray) {
        if (questionIdsSet.has(question.question_id)) {
            return true;
        }
        questionIdsSet.add(question.question_id);
    }

    return false;
};

export function hasNullOrEmpty(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === null || arr[i] === '') {
            return true;
        }
    }
    return false;
}

export function validateAustralianPhoneNumber(phoneNumber) {
    const phoneRegex = /^(\+?61|0)4\d{8}$/;

    return phoneRegex.test(phoneNumber);
}

export const convertStringToArr = (str) => {};

export function countWords(inputString) {
    const wordsArray = inputString?.split(/\s+/);

    const nonEmptyWordsArray = wordsArray?.filter((word) => word.length > 0);

    return nonEmptyWordsArray?.length;
}

export const getFirst250Words = (text) => {
    const wordsArray = text.split(/\s+/);
    const nonEmptyWordsArray = wordsArray.filter((word) => word.length > 0);
    const first250Words = nonEmptyWordsArray.slice(0, 250);
    return first250Words.join(' ');
};

export function convertMinutesToHours(minutes) {
    if (typeof minutes !== 'number' || minutes < 0) {
        throw new Error('Invalid input. Please provide a non-negative number of minutes.');
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
        return `${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}`;
    }

    const hourString = hours === 1 ? 'hour' : 'hours';
    const minuteString = remainingMinutes > 0 ? ` ${remainingMinutes} minutes` : '';

    if (remainingMinutes === 0) {
        return `${hours} ${hourString}`;
    }

    const fractionHour = remainingMinutes / 60;
    return `${hours}${hourString} ${fractionHour} ${fractionHour === 1 ? 'hour' : 'hours'}`;
}

export function getFirstWords(inputString, numberOfWords) {
    const trimmedString = inputString.trim();

    const wordsArray = trimmedString.split(/\s+/);

    const selectedWords = wordsArray.slice(0, numberOfWords);

    const result = selectedWords.join(' ');

    return result;
}

export function DataURIToBlob(dataURI) {
    if (dataURI) {
        const splitDataURI = dataURI?.split(',');
        const byteString = splitDataURI[0]?.indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0]?.split(':')[1]?.split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    }
}

export function dataURIToBlobTwo(dataURI, fileName) {
    if (dataURI) {
        const splitDataURI = dataURI?.split(',');
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0]?.split(':')[1]?.split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ia], { type: mimeString });

        blob.fileName = fileName;

        return blob;
    }
}

export function hasAtLeastTwoSameValues(arr) {
    const lowercasedArray = arr.map((value) => value.toLowerCase());

    for (let i = 0; i < lowercasedArray.length; i++) {
        for (let j = i + 1; j < lowercasedArray.length; j++) {
            if (lowercasedArray[i] === lowercasedArray[j]) {
                return true;
            }
        }
    }
    return false;
}

export function extractStringBeforeLastDot(inputString) {
    const lastDotIndex = inputString.lastIndexOf('.');

    if (lastDotIndex !== -1) {
        const resultString = inputString.substring(0, lastDotIndex);

        return resultString;
    } else {
        return inputString;
    }
}

export const sortArrayByDate = (dataArray, sortType) => {
    const compareDates = (a, b) => {
        const dateA = new Date(
            a.created_at.substring(6, 10),
            a.created_at.substring(3, 5) - 1,
            a.created_at.substring(0, 2),
            a.created_at.substring(11, 13),
            a.created_at.substring(14, 16),
            a.created_at.substring(17, 19)
        );

        const dateB = new Date(
            b.created_at.substring(6, 10),
            b.created_at.substring(3, 5) - 1,
            b.created_at.substring(0, 2),
            b.created_at.substring(11, 13),
            b.created_at.substring(14, 16),
            b.created_at.substring(17, 19)
        );

        if (sortType === 'ASC') {
            return dateB - dateA;
        } else if (sortType === 'DESC') {
            return dateA - dateB;
        }

        return 0;
    };

    return [...dataArray].sort(compareDates);
};

export const validateTFN = (tfn) => {
    const weights = [1, 4, 3, 7, 5, 8, 6, 9, 10];

    tfn = tfn?.replace(/[^\d]/g, '');

    if (tfn.length === 9) {
        let sum = 0;
        for (let i = 0; i < weights.length; i++) {
            const digit = parseInt(tfn[i], 10);
            sum += weights[i] * digit;
        }
        return sum % 11 === 0;
    }
    return false;
};

export const formatDobToString = (obj) => {
    if (Array.isArray(obj)) {
        obj.forEach((item) => formatDobToString(item));
    } else if (typeof obj === 'object' && obj !== null) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key === 'dob') {
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        obj[key] = dayjs(obj[key]).format('DD-MM-YYYY');
                    }
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    formatDobToString(obj[key]);
                }
            }
        }
    }
    return obj;
};

export const filterEmptyObject = (obj, ignoreList = [], showConsole = false) => {
    try {
        const item = obj?.details ? obj.details : obj;
        let isEmptyObj = true;

        delete obj?.isFirstLoad;

        for (const key in item) {
            const lowerCaseKey = key.toLowerCase();

            if (
                lowerCaseKey.includes('status') ||
                lowerCaseKey.includes('type') ||
                lowerCaseKey.includes('disabled') ||
                lowerCaseKey.includes('isFirstLoad') ||
                lowerCaseKey.includes('bcr_id') ||
                ignoreList.map((ignoreKey) => ignoreKey.toLowerCase()).includes(lowerCaseKey)
            ) {
                continue;
            }

            if (Array.isArray(item[key]) && item[key]?.length === 0) {
                continue;
            }

            if (key === 'phone_number' && item[key].length < 5) {
                continue;
            }

            if (item[key] !== '' && item[key] !== null && item[key] !== undefined) {
                isEmptyObj = false;
                break;
            }
        }

        return !isEmptyObj;
    } catch (error) {
        console.error(error);
    }
};

export const recursiveSearch = (obj, targetProperty, result) => {
    if (Array.isArray(obj)) {
        obj.forEach((item) => recursiveSearch(item, targetProperty, result));
    } else if (obj && typeof obj === 'object') {
        if (obj.hasOwnProperty(targetProperty)) {
            result.push(obj[targetProperty]);
        }

        Object.values(obj).forEach((value) => recursiveSearch(value, targetProperty, result));
    }
};

export const validateIndividualsObjects = (client) => {
    const { first_name, last_name, email_address, phone_number } = client;

    const isAnyFieldFilled = [first_name, last_name, email_address, phone_number].some((field) => {
        if (field === phone_number) {
            return phone_number && phone_number.trim().length >= 5;
        }

        return field && field.trim() !== '';
    });

    let errors = [];

    if (isAnyFieldFilled) {
        if (!first_name || first_name.trim() === '') {
            errors.push('First name is required');
        }
        if (!last_name || last_name.trim() === '') {
            errors.push('Last name is required');
        }
        if (!email_address || email_address.trim() === '') {
            errors.push('Email address is required');
        }

        if (!phone_number || phone_number.trim() === '' || phone_number.length < 5 || phone_number.length > 14) {
            errors.push('Valid phone number is required');
        }
    }

    return errors;
};
export const validateEmailValue = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const noSpecialCharsRegex = /^[^!#$%^&*()+={}\[\]:;"'<>,?/|~`]+$/;

    const isValid = email === '' || (emailRegex.test(email) && noSpecialCharsRegex.test(email));
    return isValid;
};
