/* eslint-disable react-hooks/exhaustive-deps */
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { Fragment, useEffect, useState } from 'react';
import XeroSvg from 'files/xero.png';
import { cn } from 'utills/tailwindUtil';
import { ExplainationSignSvg } from 'utills/svgs/ExplainationSignSvg';
import { Switch, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import DialogModalComponent from 'global-components/DialogModalComponent';
import DisconnectIcon from 'utills/svgs/DisconnectIcon';
import { Button } from 'components/atoms/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAppsStatusThunk,
    getClientSyncStatusThunk,
    getXeroClientsThunk,
    xeroDisconnectThunk,
    xeroSynkClientThunk
} from 'store/appConnectoins';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { Loader } from 'components/common/Loader';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { refreshIntegrationDataThunk } from 'store/settings/team/workspaceSettings';
import {
    BUSINESS_ID,
    DEFAULT_CLIENT_ORDER,
    ERROR_TYPE_ERROR,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from 'utills/globalVars';
import loadingSvg from 'files/loading.gif';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SearchInput } from 'components/molecules/SearchInput';
import ClientsTableDT from 'components/appusers-dash/subcomponents/ClientsTableDT';
import Pagination from 'components/pagination';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import useRoleGuard from 'Hooks/useRoleGuard';

export const XeroAppConnected = () => {
    const { hasPermissions } = useRoleGuard();
    const items = [
        { name: 'Refresh Integration Data', onClick: () => getCurrentStatus(handleRefresh) },
        { name: 'Change Connection', onClick: () => handleDisconnect() },
        { name: 'Disconnect', onClick: () => handleDisconnect() }
    ];
    const infoList = [
        `<span className="text-third font-semibod" >  No need to worry about losing data! </span> Disconnecting your Synkli and Xero accounts won't affect any information that's already been synced between them. Everything will stay safe and sound.`
    ];
    const infoList1 = [
        `<span className="text-third font-semibod" > Data Deletion: </span> Disconnecting your Synkli and Xero accounts will permanently delete all client data that was synchronized from Xero to Synkli. This data will not be recoverable.`,
        `<span className="text-third font-semibod" > Synkli User Data Unaffected: </span> Clients who have already registered directly with Synkli (becoming Synkli users themselves) will remain unaffected. Their data will continue to reside within Synkli.`,
        `<span className="text-third font-semibod" > Xero contacts converted to Synkli User: </span>  Clients synced from xero to synkli but later converted into synkli user will remain unaffected. Their data will continue to reside within Synkli.`
    ];

    const [enabled, setEnabled] = useState(false);
    const [decision, setDecision] = useState('keepData');
    const [error, setError] = useState(null);
    const { isLoading } = useSelector((state) => state.global);
    const [actionType, setActionType] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disconnectModal, setDisconnectModal] = useState(null);
    const [syncModal, setSyncModal] = useState(null);
    const [pausedFun, setPausedFun] = useState(null);
    const [loader, setLoaderState] = useState(false);
    const [newLoader, setNewLoader] = useState(false);

    const [data, setData] = useState(null);

    useEffect(() => {
        getCurrentStatus(getAppStatus);
    }, []);

    const getAppStatus = () => {
        dispatch(setLoader(true));
        dispatch(getAppsStatusThunk())
            .then((res) => {
                if (res?.payload) {
                    const xeroAppData = res?.payload.find((app) => app.app_name === 'xero');
                    setEnabled(xeroAppData?.allow_sync);

                    setData(xeroAppData);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const getCurrentStatus = (childFunction) => {
        setNewLoader(true);
        dispatch(getClientSyncStatusThunk())
            .then((res) => {
                if (res?.payload) {
                    setNewLoader(false);
                    const xeroAppStatus = res?.payload.status;
                    if (xeroAppStatus === 'complete' || xeroAppStatus === 'NO_PREVIOUS_SYNC_FOUND') {
                        setSyncModal(null);
                        childFunction();
                    } else if (xeroAppStatus === 'in_progress') {
                        setSyncModal('syncing');
                    } else {
                        setPausedFun({ childFunction: childFunction });
                        setSyncModal('syncingError');
                    }
                } else {
                    navigate('/app-connections');
                }
            })
            .catch((e) => {
                console.error(e);
                toastHandler('Error while getting the Xero Status', ERROR_TYPE_ERROR);
                setNewLoader(false);
            })
            .finally(() => {
                setNewLoader(false);
            });
    };

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    const handleDisconnect = () => {
        setDisconnectModal('confirm');
        setActionType('disconnect');
        setDecision('keepData');
    };

    const handleDisconnectXero = () => {
        setDisconnectModal(null);
        navigate('/app-connections');
    };

    const handleSyncClients = () => {
        setLoaderState(true);

        const payload = {
            allow_sync: !enabled
        };
        dispatch(setLoader(true));

        dispatch(xeroSynkClientThunk(payload))
            .then((res) => {
                setEnabled(!enabled);
                if (res.meta?.requestStatus === 'fulfilled') {
                    toastHandler('Successfully updated', TOAST_TYPE_SUCCESS);
                } else {
                    toastHandler('Error while Updating', TOAST_TYPE_ERROR);
                }
            })
            .finally(() => {
                setLoaderState(false);
                dispatch(setLoader(false));
            });
    };

    const handleRefresh = () => {
        dispatch(setLoader(true));
        dispatch(refreshIntegrationDataThunk())
            .then((response) => {
                if (response.meta?.requestStatus === 'fulfilled') {
                    toastHandler('Successfully updated', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleDisableAction = () => {
        if (!decision) {
            setError('Please Select One of the Following Options');
        }
        if (decision && actionType) {
            if (actionType === 'disconnect') {
                const payload = {
                    delete_xero_clients: decision === 'mergeLater' ? true : false
                };
                setLoaderState(true);
                dispatch(xeroDisconnectThunk(payload))
                    .then((res) => {
                        if (res.payload) {
                            setDisconnectModal('disconnected');
                        } else {
                            toastHandler('Error while Diconnect', 'error');
                        }
                    })
                    .finally(() => {
                        setLoaderState(false);
                    });
            }
        }
    };

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [filters, setFilters] = useState({
        search_term: '',
        from_date: '',
        order_by: {}
    });
    const [filterByType, setFilterByType] = useState('');
    const typeOptions = [
        {
            title: 'Select All',
            label: ''
        },
        {
            title: 'From Xero',
            label: 'from-xero'
        },
        {
            title: 'To Xero',
            label: 'to-xero'
        },
        {
            title: 'Conflicted',
            label: 'conflicted'
        },
        {
            title: 'Not synced yet',
            label: 'Not synced yet'
        }
    ];

    const [itemsPerPage, setItemsPerPage] = useState(PAGINATION_PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [clientsList, setClientsList] = useState([]);
    const [isLoadingContent, setIsLoadingContent] = useState(false);

    useEffect(() => {
        const hasValue = Object.values(filters).some((value) => {
            if (typeof value === 'object' && value !== null) {
                return Object.values(value).some((nestedValue) => nestedValue !== '' && nestedValue !== 'none');
            }
            return value !== '';
        });
        if (hasValue || filterByType) {
            if (currentPage === 1) {
                getAppCalendarList();
            } else {
                setCurrentPage(1);
            }
        } else {
            getAppCalendarList();
        }
    }, [filters, filterByType]);

    useEffect(() => {
        if (data?.status === 'list') {
            getAppCalendarList();
        }
    }, [currentPage, itemsPerPage, data]);

    const getAppCalendarList = async () => {
        const payload = {
            business_id,
            type: filterByType,
            pageSize: itemsPerPage,
            page: currentPage,
            search_term: filters.search_term,
            from_date: '',
            order_by:
                Object.entries(filters?.order_by)?.length > 0
                    ? Object.fromEntries(
                          Object.entries(filters?.order_by)?.map(([key, value]) => [key, value === 'none' ? '' : value])
                      )
                    : DEFAULT_CLIENT_ORDER
        };
        try {
            setIsLoadingContent(true);
            setClientsList([]);
            const response = await dispatch(getXeroClientsThunk(payload));
            if (response.payload) {
                setClientsList(response.payload.data);
                setTotalPages(Math.ceil(response.payload?.count / itemsPerPage));
                setIsLoadingContent(false);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    return (
        <div>
            {(isLoading || newLoader) && <Loader />}
            <SidebarLayout>
                <Jumbotron
                    title="App Connections"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },

                        {
                            link: 'app-connections',
                            title: 'App Connections'
                        },
                        {
                            link: '',
                            title: 'Xero'
                        }
                    ]}
                />

                <RoleBasedGuard module={'App_connections'} permissions={['write', 'admin']} sx="h-[50vh]">
                    <div className="md:mx-10 mx-5 mt-5">
                        {data && (
                            <div className="px-10 py-12 bg-white rounded-2xl overflow-inherit shadow-lg mt-7">
                                <div className="md:col-span-2 col-span-3  flex flex-wrap items-center">
                                    <div className="isolate inline-flex rounded-md shadow-sm w-fit">
                                        <div className="flex gap-0 bg-transparent rounded-xl ring-1 ring-outside ring-gray-300 shadow-sm w-full">
                                            <div
                                                className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-12  ${
                                                    data?.status === 'active' ? 'bg-leaner' : ''
                                                }`}
                                                onClick={() => setData({ ...data, status: 'active' })}
                                            >
                                                {data?.status === 'active' ? 'Connected' : 'Reconnect'}
                                            </div>
                                            <div
                                                className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-5`}
                                            >
                                                <div className="inline-flex gap-2">
                                                    <Menu as={'div'} className="relative -ml-px block">
                                                        <Menu.Button className="relative inline-flex items-center focus:z-10">
                                                            <span className="text-[12px] md:text-[14px] lg:text-[16px] font-poppins">
                                                                Manage Connections
                                                            </span>
                                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                        </Menu.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="absolute right-0   -mr-1 mt-2 w-56 bottom-[101%] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                <div className="py-1">
                                                                    {items.map((item) => (
                                                                        <Menu.Item key={item.name}>
                                                                            {({ active }) => (
                                                                                <h3
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            !hasPermissions(
                                                                                                'App_connections',
                                                                                                ['admin']
                                                                                            )
                                                                                        ) {
                                                                                            toastHandler(
                                                                                                "You don't have permission to do this action",
                                                                                                TOAST_TYPE_ERROR
                                                                                            );
                                                                                            return;
                                                                                        }
                                                                                        item.onClick();
                                                                                    }}
                                                                                    className={cn(
                                                                                        active
                                                                                            ? 'bg-third text-white'
                                                                                            : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm'
                                                                                    )}
                                                                                >
                                                                                    {item.name}
                                                                                </h3>
                                                                            )}
                                                                        </Menu.Item>
                                                                    ))}
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                            <div
                                                className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-12 ${
                                                    data?.status === 'list' ? 'bg-leaner' : ''
                                                }`}
                                                onClick={() => setData({ ...data, status: 'list' })}
                                            >
                                                Connected Clients List
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="min-h-[300px]">
                                    {data?.status === 'list' ? (
                                        <div className="mt-5 grid">
                                            <div className="flex items-center justify-between my-5">
                                                <div className="w-[300px]">
                                                    <SearchInput
                                                        placeholder={'Search'}
                                                        atChange={(e) => {
                                                            setFilters({ ...filters, search_term: e.target.value });
                                                        }}
                                                        zIndex="1"
                                                    />
                                                </div>

                                                <div>
                                                    <div className="w-[190px]">
                                                        <SimpleSelectMenu
                                                            placeholder="Filter by type"
                                                            targetProperty="title"
                                                            selectedValue={filterByType}
                                                            valuePropertyName={'label'}
                                                            optionsData={typeOptions}
                                                            sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                                                            onChangeValue={(obj) => setFilterByType(obj.label)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mt-8 flow-root">
                                                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <ClientsTableDT
                                                            data={clientsList}
                                                            isLoading={isLoadingContent}
                                                            tableView={'all'}
                                                            filters={filters}
                                                            setFilters={setFilters}
                                                            XeroClients={true}
                                                        />
                                                    </div>
                                                    <Pagination
                                                        page={Number(currentPage || 0) !== 0 ? Number(currentPage) : 1}
                                                        pageSize={Number(itemsPerPage || 0)}
                                                        totalPages={Number(totalPages || 0)}
                                                        onChangePage={(e) => {
                                                            setCurrentPage(e);
                                                        }}
                                                        onCountPerPageChange={(value) => {
                                                            setItemsPerPage(value);
                                                            setCurrentPage(1);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flow-root mt-10">
                                            <div className="grid grid-cols-4 gap-4">
                                                <div>
                                                    <div className="flex items-center gap-3">
                                                        <img src={XeroSvg} alt="XeroSvg" className="w-28" />
                                                        <h3 className="text-2xl font-bold ">Xero</h3>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 pr-5">
                                                    <h3 className="text-2xl font-semibold ">General Xero Settings</h3>
                                                    <h3 className="text-xl font-semibold my-5">Automatic Publishing</h3>
                                                    <div className="flex items-start gap-5 px-5 py-7 bg-gray-50 rounded-xl">
                                                        <div className="div">
                                                            <ExplainationSignSvg />
                                                        </div>
                                                        <div>
                                                            <p className="text-md font-normal text-gray-900">
                                                                This synchronisation makes managing your client data a
                                                                breeze. No more worrying about inconsistencies or delays
                                                                – access and update everything from either platform!
                                                                Whether you&lsquo;re editing client information on
                                                                Synkli or managing finances in Xero, the synchronized
                                                                data ensures both systems are always accurate and
                                                                up-to-date.
                                                            </p>
                                                            <p className="text-md font-normal text-gray-900 mt-5">
                                                                Say goodbye to tedious manual data entry and
                                                                reconciliation. This integration automatically reflects
                                                                any changes you make in one platform to the other. This
                                                                not only saves you valuable time but also eliminates the
                                                                risk of errors that can creep in with manual data
                                                                handling. You can focus on what matters – providing
                                                                exceptional service for your clients!
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <Switch.Group
                                                        as="div"
                                                        className="flex items-center justify-start gap-5 mt-5"
                                                    >
                                                        <span className="flex">
                                                            <Switch.Label
                                                                as="span"
                                                                className="text-md font-normal leading-6 text-gray-900"
                                                                passive
                                                            >
                                                                Automatically publish all Clients
                                                            </Switch.Label>
                                                        </span>
                                                        <Switch
                                                            checked={enabled}
                                                            onChange={() => {
                                                                if (!hasPermissions('App_connections', ['admin'])) {
                                                                    toastHandler(
                                                                        "You don't have permission to do this action",
                                                                        TOAST_TYPE_ERROR
                                                                    );
                                                                    return;
                                                                }
                                                                getCurrentStatus(handleSyncClients);
                                                            }}
                                                            className={cn(
                                                                enabled ? 'bg-third' : 'bg-gray-200',
                                                                'relative inline-flex mx-5 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-third focus:ring-offset-2'
                                                            )}
                                                        >
                                                            <span
                                                                aria-hidden="true"
                                                                className={cn(
                                                                    enabled ? 'translate-x-5' : 'translate-x-0',
                                                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                                )}
                                                            />
                                                        </Switch>
                                                    </Switch.Group>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {disconnectModal === 'confirm' && (
                            <DialogModalComponent
                                open={disconnectModal === 'confirm' ? true : false}
                                setOpen={() => {
                                    setDisconnectModal(null);
                                    setActionType(null);
                                }}
                                sx="max-w-[660px]"
                                title="Disconnect Integration"
                                icon={<DisconnectIcon />}
                            >
                                <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5">
                                    Just checking! Disabling this connection will disconnect Synkli from Xero in your
                                    account. If you change your mind later, you&apos;ll need to reconnect them again.
                                    Are you sure you want to turn it off?
                                </p>

                                <fieldset>
                                    <legend className="sr-only">Decision</legend>
                                    <div className="space-y-5">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    id={'mergeLater'}
                                                    aria-describedby={`large-description`}
                                                    name="plan"
                                                    type="radio"
                                                    defaultChecked={decision === 'mergeLater'}
                                                    onChange={() => setDecision('mergeLater')}
                                                    className="h-5 w-5 border-gray-300 text-black focus:ring-black"
                                                />
                                            </div>
                                            <div className="ml-3 text-md leading-6">
                                                <label htmlFor={'mergeLater'} className="font-medium text-gray-900">
                                                    I want to discard all the clients synced from Xero.
                                                </label>
                                            </div>
                                        </div>
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    id={'keepData'}
                                                    aria-describedby={`large-description`}
                                                    name="plan"
                                                    type="radio"
                                                    defaultChecked={decision === 'keepData'}
                                                    onChange={() => setDecision('keepData')}
                                                    className="h-5 w-5 border-gray-300 text-black focus:ring-black"
                                                />
                                            </div>
                                            <div className="ml-3 text-md leading-6">
                                                <label htmlFor={'keepData'} className="font-medium text-gray-900">
                                                    I want to save all the clients synced from Xero
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                {error && <p className="text-sm text-red-500 font-normal leading-10">{error}</p>}
                                {decision === 'mergeLater' && (
                                    <>
                                        <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5">
                                            This will:
                                        </p>
                                        {infoList1.map((item, index) => (
                                            <div className="relative flex items-start my-5" key={'info2_key' + index}>
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        id={'comments_' + index}
                                                        aria-describedby="comments-description"
                                                        name="comments"
                                                        checked={true}
                                                        type="checkbox"
                                                        className="h-5 w-5 rounded-full border-gray-300 text-third focus:ring-third"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6">
                                                    <label
                                                        htmlFor={'comments_' + index}
                                                        className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5"
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: item }} />
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                                {decision === 'keepData' && (
                                    <>
                                        <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5">
                                            This will:
                                        </p>
                                        {infoList.map((item, index) => (
                                            <div className="relative flex items-start my-5" key={'info_key' + index}>
                                                <div className="flex h-6 items-center">
                                                    <input
                                                        id={'comments_' + index}
                                                        aria-describedby="comments-description"
                                                        name="comments"
                                                        checked={true}
                                                        type="checkbox"
                                                        className="h-5 w-5 rounded-full border-gray-300 text-third focus:ring-third"
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm leading-6">
                                                    <label
                                                        htmlFor={'comments_' + index}
                                                        className="buttons-font-lighter text-gray-900 !font-normal font-poppins my-5"
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: item }} />
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}

                                <div className="flex justify-center items-center my-8 gap-5">
                                    <Button
                                        atClick={() => setDisconnectModal(null)}
                                        title={'Cancel'}
                                        classes={
                                            'rounded-lg !bg-white text-linear min-h-[3rem] ring-2 ring-inset ring-primary px-16 buttons-font-lighter font-fira'
                                        }
                                    />
                                    <Button
                                        atClick={() => handleDisableAction()}
                                        iconSet={{ loader: SmallLoaderWhite }}
                                        isloading={loader}
                                        disabled={loader}
                                        title={'Disconnect'}
                                        classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                    />
                                </div>
                            </DialogModalComponent>
                        )}

                        {disconnectModal === 'disconnected' && (
                            <DialogModalComponent
                                open={disconnectModal === 'disconnected' ? true : false}
                                setOpen={() => setDisconnectModal(null)}
                                sx="max-w-[460px]"
                                title="Xero Disconnected"
                                icon={<DisconnectIcon />}
                            >
                                <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins">
                                    The data in your lists will revert to its pre-integration state, showcasing the
                                    information you had before any software integration occurred. You retain the
                                    flexibility to integrate via the app connection whenever you choose in the future.
                                </p>
                                <div className="flex justify-center items-center my-8 gap-5">
                                    <Button
                                        atClick={() => handleDisconnectXero()}
                                        title={'Finish'}
                                        classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                    />
                                </div>
                            </DialogModalComponent>
                        )}

                        {syncModal === 'syncing' && (
                            <DialogModalComponent
                                open={syncModal === 'syncing' ? true : false}
                                hideCross
                                setOpen={() => {}}
                                sx="max-w-[460px]"
                                title="Syncing Data with Xero"
                                icon={<img src={loadingSvg} alt="loading...." className="w-24 h-24" />}
                            >
                                <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins text-center">
                                    Your data from Xero is being synced with Synkli. Please hold on while we complete
                                    the process.
                                </p>
                                <div className="flex justify-center items-center my-8 gap-5">
                                    <Button
                                        atClick={() => navigate(-1)}
                                        title={'Back'}
                                        classes={'rounded-lg border-button px-14 buttons-font-lighter font-fira'}
                                    />
                                    <Button
                                        atClick={() => handleDisconnect()}
                                        title={'Disconnect'}
                                        classes={'rounded-lg ann-btn px-12 buttons-font-lighter font-fira'}
                                    />
                                </div>
                            </DialogModalComponent>
                        )}
                        {syncModal === 'syncingError' && (
                            <DialogModalComponent
                                open={syncModal === 'syncing' ? true : false}
                                setOpen={() => {}}
                                hideCross={true}
                                sx="max-w-[460px]"
                                title="Error while Syncing!"
                                icon={<ExplainationSignSvg />}
                            >
                                <p className="buttons-font-lighter text-gray-900 !font-normal font-poppins text-center">
                                    Error While Syncing Data with Xero!
                                    <br /> Press &apos;Ok&apos; to continue
                                </p>
                                <div className="flex justify-center items-center my-8 gap-5">
                                    <Button
                                        atClick={() => {
                                            setSyncModal(false);
                                            if (pausedFun) {
                                                pausedFun.childFunction();
                                            }
                                        }}
                                        title={'Ok'}
                                        classes={'rounded-lg ann-btn px-12 buttons-font-lighter font-fira'}
                                    />
                                </div>
                            </DialogModalComponent>
                        )}
                    </div>
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
