import React from 'react';
/* eslint-disable no-unused-vars */
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import { toastHandler } from 'responseHanlder';
import { addServiceThunk } from 'store/auth/slices';
import { countWords, getFirst250Words, getFirstWords } from 'utills/dataValidation';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

const AddServiceSide = ({ toggleServiceSide, open, setOpen }) => {
    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [consultationCharges, setConsultationCharges] = useState('$');
    const [minTimeConsultation, setMinTimeConsultation] = useState('');
    const [description, setDescription] = useState('');
    const [serviceLoader, setServiceLoader] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const serviceObj = {
            title,
            consultationCharges,
            minTimeConsultation,
            description
        };
        if (!title) {
            toastHandler('Title is required.', TOAST_TYPE_ERROR);
            return;
        }
        if (title.length > 60) {
            toastHandler('Title length should be less than 60 character', TOAST_TYPE_ERROR);
            return;
        }
        if (!description) {
            toastHandler('description is required.', TOAST_TYPE_ERROR);
            return;
        }

        if (!consultationCharges) {
            toastHandler('Charges is required.', TOAST_TYPE_ERROR);
            return;
        }

        const hasEmptyValue = Object.values(serviceObj).some((value) => {
            return (
                value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0)
            );
        });

        if (hasEmptyValue) {
            toastHandler('Please fill in all required fields.', TOAST_TYPE_ERROR);
            return;
        }

        const serviceData = {
            name: title,
            charges: consultationCharges,
            duration: minTimeConsultation,
            description
        };

        setServiceLoader(true);
        dispatch(addServiceThunk(serviceData))
            .then((response) => {
                if (response.payload) {
                    toggleServiceSide();
                    toastHandler('service added successfully', 'success');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setServiceLoader(false);
            });
    };

    const [descLength, setDescLength] = useState(0);
    const handleDesc = (value) => {
        const wordsLength = countWords(value);

        if (wordsLength <= 250) {
            setDescLength(wordsLength);
            setDescription(value);
        }
    };

    const handlePasteDesc = (e) => {
        const value = e.target.value;

        if (description?.split(' ')?.length >= 250) {
            return;
        }

        const countedInput = countWords(description);

        const availableSpace = 250 - countedInput;

        const first250Words = getFirst250Words(value);

        const availableWordsToPaste = getFirstWords(first250Words, availableSpace);

        const newstr = description + availableWordsToPaste;
        setDescLength(countWords(newstr));
        setDescription(newstr);
    };

    return (
        <Slideover title="Add Service" open={open} setOpen={setOpen}>
            <div className="">
                <div className=""></div>
                <div className=" ">
                    <motion.div
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                        className=" relative"
                    >
                        <div className={`service-info pt-10 p-5 ${serviceLoader && 'opacity-30'}`}>
                            <div className="add-ann-form mt-3">
                                <div>
                                    <div>
                                        <label className="buttons-font">Title</label>
                                    </div>
                                    <div>
                                        <input
                                            onChange={(e) => setTitle(e.target.value)}
                                            type="text"
                                            maxLength={60}
                                            placeholder="Add service title"
                                            className="px-3 rounded-[0.7rem] mt-2 inputs-font"
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div>
                                        <label className="buttons-font">Consultation Charges</label>
                                    </div>

                                    <div className="charges-input-container mt-2">
                                        <input
                                            type="number"
                                            onChange={(e) => setConsultationCharges(e.target.value)}
                                            className="charges-input inputs-font"
                                            value={consultationCharges}
                                        />
                                        <div className="doller-sign">$</div>
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div>
                                        <label className="buttons-font">Min-Time of Consultation:</label>
                                    </div>

                                    <div className="mt-2">
                                        <SimpleSelectMenu
                                            placeholder="Select"
                                            targetProperty="label"
                                            optionsData={
                                                [
                                                    { label: '30 minutes', value: '30' },
                                                    { label: '45 minutes', value: '45' },
                                                    { label: '1 Hour', value: '60' },
                                                    { label: '1.5 Hour', value: '90' },
                                                    { label: '2 Hour', value: '120' },
                                                    { label: '2.5 Hour', value: '150' },
                                                    { label: '3 Hour', value: '180' }
                                                ] || []
                                            }
                                            sx="rounded-xl py-3 ring-gray-400 "
                                            onChangeValue={(obj) => setMinTimeConsultation(obj.value)}
                                        />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <div>
                                        <label className="buttons-font">Description</label>
                                    </div>
                                    <div>
                                        <DefaultTextArea
                                            placeholder="Description"
                                            onChange={(e) => handleDesc(e)}
                                            onInput={handlePasteDesc}
                                            cols="30"
                                            rows="5"
                                            value={description}
                                            className="rounded-xl mt-2 inputs-font px-3 py-2"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center items-center mt-10">
                                {' '}
                                <button
                                    onClick={handleSubmit}
                                    className="px-20 py-2 buttons-font rounded-lg ann-btn flex gap-2 items-center text-white"
                                >
                                    Add {serviceLoader && <SmallLoaderWhite />}
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Slideover>
    );
};

export default AddServiceSide;
