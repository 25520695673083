import { Button } from 'components/atoms/buttons/Button';
import { TextInput } from 'components/atoms/inputs/TextInput';
import { SmallLoaderTwo } from 'components/common/SmallLoaderTwo';
import useRoleGuard from 'Hooks/useRoleGuard';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

const ActivityInputBox = ({ handleSubmit, isLoading }) => {
    const { hasPermissions } = useRoleGuard();
    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    const [inputValue, setInputValue] = useState('');

    const handleUpdateMessage = () => {
        if (inputValue !== '') {
            handleSubmit(inputValue);
            setInputValue('');
        }
    };

    return (
        <>
            <div className="flex items start justify-start gap-5">
                <div className="flex-1">
                    <TextInput
                        value={inputValue}
                        onChange={(e) => {
                            const value = e?.target?.value;
                            setInputValue(value === ' ' ? value?.trim() : value);
                        }}
                        maxLength={500}
                        placeholder={'Write'}
                        allowNumbers
                        sx={'!pl-4 !rounded-lg'}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleUpdateMessage();
                            }
                        }}
                    />
                </div>
                <Button
                    title={'Send'}
                    atClick={() => {
                        if (!hasPermissions('Job', ['write', 'admin'])) {
                            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                        }
                        handleUpdateMessage();
                    }}
                    iconSet={{ loader: SmallLoaderTwo }}
                    isloading={isLoading || false}
                    classes={'ann-btn px-16 !rounded-lg !text-white'}
                    disabled={isLoading || inputValue?.trim() === '' || disabled ? true : false}
                />
            </div>
        </>
    );
};

export default ActivityInputBox;
