import React, { useState, useEffect, useCallback } from 'react';
import { EditorState, convertToRaw, ContentState, Modifier, SelectionState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { cn } from 'utills/tailwindUtil';
import { MicrophoneIcon as MicIconSolid } from '@heroicons/react/20/solid';
import { MicrophoneIcon } from '@heroicons/react/24/outline';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

const DraftEditor = ({
    onChange,
    initialValue,
    placeholder,
    required,
    hasError,
    sx,
    forceUpdate = false,
    setForceUpdate,
    toolbarOnFocus = false,
    inputsx
}) => {
    const MAX_CHAR_LIMIT = 6000;
    const [readOnly, setReadOnly] = useState(true);
    const [hasFocusedOnce, setHasFocusedOnce] = useState(false);

    const [editorState, setEditorState] = useState(() => {
        if (initialValue) {
            const contentBlock = htmlToDraft(initialValue);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            return EditorState.createWithContent(contentState);
        } else {
            return EditorState.createEmpty();
        }
    });

    const moveCursorToEnd = (state) => {
        const contentState = state.getCurrentContent();
        const blockMap = contentState.getBlockMap();
        const lastBlock = blockMap.last();
        const endKey = lastBlock.getKey();
        const endOffset = lastBlock.getLength();

        const selection = new SelectionState({
            anchorKey: endKey,
            anchorOffset: endOffset,
            focusKey: endKey,
            focusOffset: endOffset,
            isBackward: false
        });

        return EditorState.forceSelection(state, selection);
    };

    useEffect(() => {
        if (forceUpdate) {
            setEditorState(() => {
                if (initialValue) {
                    const contentBlock = htmlToDraft(initialValue);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    return EditorState.createWithContent(contentState);
                } else {
                    return EditorState.createEmpty();
                }
            });
            setForceUpdate(false);
        }
    }, [forceUpdate]);

    useEffect(() => {
        const contentState = editorState.getCurrentContent();

        const rawContentState = convertToRaw(contentState);
        const htmlContent = draftToHtml(rawContentState);
        onChange(htmlContent, rawContentState);
    }, [editorState]);

    const handleBeforeInput = (_input) => {
        const currentContent = editorState.getCurrentContent();
        const plainText = currentContent.getPlainText();

        if (plainText.length >= MAX_CHAR_LIMIT) {
            return 'handled';
        }
        return 'not-handled';
    };

    const handlePastedText = (pastedText) => {
        const currentContent = editorState.getCurrentContent();
        const plainText = currentContent.getPlainText();
        const availableCharacters = MAX_CHAR_LIMIT - plainText.length;

        if (availableCharacters <= 0) {
            return 'handled';
        }

        if (pastedText.length > availableCharacters) {
            const trimmedText = pastedText.substring(0, availableCharacters);
            const newContent = Modifier.insertText(currentContent, editorState.getSelection(), trimmedText);
            setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));
            return 'handled';
        }

        return 'not-handled';
    };

    const onEditorStateChange = useCallback((newEditorState) => {
        const currentContent = newEditorState.getCurrentContent();
        const plainText = currentContent.getPlainText();

        if (plainText.length <= MAX_CHAR_LIMIT) {
            setEditorState(newEditorState);
        }
    }, []);

    const handleEditorFocus = () => {
        if (!hasFocusedOnce) {
            setEditorState((prevState) => moveCursorToEnd(prevState));
            setHasFocusedOnce(true);
        }
        setReadOnly(false);
    };

    const [recognition, setRecognition] = useState(null);

    useEffect(() => {
        return () => {
            setRecognition(null);
        };
    }, []);

    const startVoiceToText = () => {
        if (!SpeechRecognition) {
            alert('Speech Recognition API is not supported in your browser');
            return;
        }

        const recognitionInstance = new SpeechRecognition();
        recognitionInstance.continuous = false;
        recognitionInstance.interimResults = false;
        recognitionInstance.lang = 'en-US';

        recognitionInstance.onresult = (event) => {
            const speechToText = event.results[0][0].transcript;

            const currentContent = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            const newContent = Modifier.insertText(currentContent, selection, speechToText);
            setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));
        };

        recognitionInstance.onerror = (event) => {
            console.error('Speech recognition error', event);
        };

        recognitionInstance.onend = () => {
            setRecognition(null);
        };

        setRecognition(recognitionInstance);
        recognitionInstance.start();
    };

    const toolbarOptions = {
        options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji'],
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'poppins, sans']
        },
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        image: {
            previewImage: true
        }
    };

    const CustomMicButton = () => (
        <div
            onClick={startVoiceToText}
            title="Voice to Text"
            className="cursor-pointer flex items-center justify-center w-8 h-8 rounded hover:bg-gray-200"
        >
            {recognition ? (
                <MicIconSolid className="h-5 w-5 text-gray-600" />
            ) : (
                <MicrophoneIcon className="h-5 w-5 text-gray-600" />
            )}
        </div>
    );

    return (
        <div
            className={cn('border border-secondarybg rounded-xl py-1 relative', sx, hasError ? '!border-red-400' : '')}
            onClick={handleEditorFocus}
        >
            <div className={cn('px-3 mt-2 w-full mb-[20px] h-[250px] !overflow-auto', inputsx)}>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper group"
                    editorClassName="demo-editor max-h-[200px]"
                    toolbarClassName={
                        toolbarOnFocus
                            ? 'demo-toolbar-absolute !hidden group-focus-within:!flex group-focus-within:!absolute '
                            : 'toolbar-on-top'
                    }
                    toolbarOnFocus={toolbarOnFocus}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={toolbarOptions}
                    toolbarCustomButtons={[<CustomMicButton key="mic-button" />]}
                    required={required ?? false}
                    placeholder={placeholder ?? ''}
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePastedText}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
};

export default DraftEditor;
