import React from 'react';

export const crossTimes = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9" stroke="#B695F8" strokeWidth="1.6" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1868 7.24617C14.5773 6.85564 14.5773 6.22248 14.1868 5.83196C13.7962 5.44143 13.1631 5.44143 12.7726 5.83196L10.0094 8.59515L7.24617 5.83196C6.85564 5.44143 6.22248 5.44143 5.83196 5.83196C5.44143 6.22248 5.44143 6.85565 5.83196 7.24617L8.59515 10.0094L5.83196 12.7726C5.44143 13.1631 5.44143 13.7962 5.83196 14.1868C6.22248 14.5773 6.85564 14.5773 7.24617 14.1868L10.0094 11.4236L12.7726 14.1868C13.1631 14.5773 13.7962 14.5773 14.1868 14.1868C14.5773 13.7962 14.5773 13.1631 14.1868 12.7726L11.4236 10.0094L14.1868 7.24617Z"
                fill="#B695F8"
            />
        </svg>
    );
};
