import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor } from './store/store';
import store from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastManager } from 'components/molecules/ToastManager';
import * as Sentry from '@sentry/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

document.addEventListener('DOMContentLoaded', function (_event) {
    if ('serviceWorker' in navigator && typeof window.navigator.serviceWorker !== 'undefined') {
        try {
            window.addEventListener('load', () => {
                navigator.serviceWorker
                    .register('/firebase-messaging-sw.js')
                    .then((registration) => {
                        console.info('Service worker registered:', registration);
                    })
                    .catch((error) => {
                        console.error('Service worker registration failed:', error);
                    });
            });
        } catch (error) {
            console.error('error', error);
        }
    }
});

if (process.env.REACT_APP_NODE_ENV === 'production') {
    // Sentry.init({
    //     dsn: 'https://a6935084855fac99569334be5d92cd32@o4507530414718976.ingest.de.sentry.io/4507546320437328',
    //     integrations: [Sentry.browserTracingIntegration()],
    //     tracesSampleRate: 0.5, //  Capture 50% of the transactions
    //     tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    // });

    // filter integrations that use the global variable
    const integrations = Sentry.getDefaultIntegrations({}).filter((defaultIntegration) => {
        return !['BrowserApiErrors', 'Breadcrumbs', 'GlobalHandlers'].includes(defaultIntegration.name);
    });
    const client = new Sentry.BrowserClient({
        dsn: 'https://a6935084855fac99569334be5d92cd32@o4507530414718976.ingest.de.sentry.io/4507546320437328',
        integrations: integrations,
        tracesSampleRate: 0.5, //  Capture 50% of the transactions
        tracePropagationTargets: ['localhost', /^https:\/\/api.synkli\.dev\/api/],
        transport: Sentry.makeFetchTransport,
        stackParser: Sentry.defaultStackParser
    });

    const scope = new Sentry.Scope();
    scope.setClient(client);
    client.init();
}

const FallbackComponent = () => {
    return <div>An error has occured :(</div>;
};

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <DndProvider backend={HTML5Backend}>
                    {' '}
                    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
                        {' '}
                        <ToastManager />
                        <ToastContainer />
                        <App />
                    </Sentry.ErrorBoundary>
                </DndProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
