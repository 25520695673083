import React from 'react';

export const ChecklistIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.16992 1.66992C4.66406 2.17383 2.39648 4.3125 1.72266 8.70117C1.61719 9.35156 1.61133 9.84375 1.61133 15C1.61133 20.0918 1.62305 20.6543 1.7168 21.2695C2.38477 25.5703 4.46484 27.6445 8.75977 28.2832C9.39844 28.377 9.97852 28.3887 15.0293 28.3887C21.1172 28.3887 21.0938 28.3887 22.5527 28.0312C25.6523 27.2637 27.457 25.3594 28.1191 22.1484C28.4062 20.7773 28.4238 20.25 28.4004 14.5723C28.377 8.77734 28.3828 8.88867 28.0078 7.37109C27.2871 4.4707 25.4824 2.68359 22.5586 1.96875C21.0996 1.61719 21.1992 1.61719 15.293 1.60547C11.0684 1.59375 9.71484 1.61133 9.16992 1.66992ZM21.2402 3.5918C24.1641 4.11328 25.5938 5.34961 26.2207 7.89844C26.5078 9.04102 26.5137 9.22852 26.5137 15.0586C26.5137 20.959 26.5137 20.9883 26.1973 22.2188C25.6465 24.3633 24.3633 25.6465 22.2188 26.1973C20.9883 26.5137 20.959 26.5137 15.0586 26.5137C9.22852 26.5137 9.04102 26.5078 7.89844 26.2207C5.28516 25.5762 3.97266 23.9824 3.5625 20.9824C3.45117 20.168 3.45117 9.83203 3.5625 9.01758C3.97266 6.01758 5.28516 4.42383 7.89844 3.7793C8.20312 3.70312 8.68945 3.60938 8.98828 3.58008C9.28125 3.54492 9.60352 3.50391 9.69727 3.49219C9.79688 3.48047 12.3105 3.47461 15.293 3.48633C19.9688 3.49805 20.7832 3.50977 21.2402 3.5918Z"
                    fill="url(#paint0_linear_862_1349)"
                />
                <path
                    d="M11.25 8.41386C11.168 8.4666 10.6113 8.9998 10.0078 9.59746L8.91798 10.6814L8.64259 10.4588C8.40821 10.2654 8.32032 10.2303 8.02735 10.2068C7.60548 10.1775 7.32423 10.324 7.14845 10.6697C7.00196 10.951 7.00196 11.2088 7.14259 11.5135C7.26564 11.783 8.27931 12.7967 8.51954 12.8904C8.74806 12.9725 9.1172 12.9666 9.3047 12.867C9.39259 12.8201 10.166 12.076 11.0274 11.2088C12.3867 9.84355 12.5918 9.60918 12.6328 9.41582C12.709 8.9998 12.4512 8.51347 12.082 8.37285C11.8594 8.28496 11.4082 8.31425 11.25 8.41386Z"
                    fill="url(#paint1_linear_862_1349)"
                />
                <path
                    d="M15.082 10.248C14.7246 10.4121 14.5605 10.6758 14.5605 11.0859C14.5605 11.4551 14.6719 11.6543 15 11.877L15.1934 12.0117H18.75H22.3066L22.5 11.877C22.7812 11.6895 22.9219 11.4727 22.9512 11.1855C22.9922 10.8223 22.7812 10.4531 22.4414 10.2715L22.1895 10.1367H18.7559C15.5332 10.1367 15.3047 10.1484 15.082 10.248Z"
                    fill="url(#paint2_linear_862_1349)"
                />
                <path
                    d="M11.3672 17.1158C11.2852 17.1451 10.6992 17.69 10.0664 18.3228L8.91214 19.4771L8.64847 19.231C8.21487 18.8326 7.70511 18.8267 7.31839 19.2134C7.07815 19.4537 6.99612 19.7291 7.06058 20.0806C7.11917 20.397 8.2969 21.5806 8.66018 21.6861C9.15823 21.8326 9.24026 21.774 10.9219 20.1099C12.5742 18.4693 12.6973 18.2994 12.627 17.8365C12.545 17.2564 11.918 16.899 11.3672 17.1158Z"
                    fill="url(#paint3_linear_862_1349)"
                />
                <path
                    d="M15.1172 18.9727C14.7832 19.1191 14.5312 19.4883 14.5312 19.8281C14.5312 20.1211 14.7363 20.4785 15 20.6426L15.2051 20.7715H18.75H22.2949L22.5117 20.6367C22.7988 20.4551 22.9922 20.0566 22.9512 19.7344C22.916 19.4531 22.7754 19.2422 22.5 19.0547L22.3066 18.9258L18.7559 18.9316C16.8047 18.9316 15.1641 18.9492 15.1172 18.9727Z"
                    fill="url(#paint4_linear_862_1349)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_862_1349"
                        x1="16.3243"
                        y1="28.056"
                        x2="16.3043"
                        y2="1.60198"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_862_1349"
                        x1="10.1172"
                        y1="12.8898"
                        x2="10.1143"
                        y2="8.3206"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_862_1349"
                        x1="19.1703"
                        y1="11.9884"
                        x2="19.17"
                        y2="10.1367"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_862_1349"
                        x1="10.1154"
                        y1="21.6801"
                        x2="10.1125"
                        y2="17.0518"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_862_1349"
                        x1="19.1573"
                        y1="20.7486"
                        x2="19.157"
                        y2="18.9258"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
