import React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getStroke } from 'perfect-freehand';

import { cn } from '../helper';
import { getSvgPathFromStroke } from './helper';
import { Point } from './point';
import { useRequiredSigningContext } from '../provider';

const DPI = 2;

export const SignaturePad = ({ className, containerClassName, defaultValue, newValue, onChange, ...props }) => {
    const $el = useRef(null);
    const { _signature, setSignature } = useRequiredSigningContext();

    const [isPressed, setIsPressed] = useState(false);
    const [points, setPoints] = useState([]);

    const perfectFreehandOptions = useMemo(() => {
        const size = $el.current ? Math.min($el.current.height, $el.current.width) * 0.03 : 10;

        return {
            size,
            thinning: 0.25,
            streamline: 0.5,
            smoothing: 0.5,
            end: {
                taper: size * 2
            }
        };
    }, []);

    const onMouseDown = (event) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        setIsPressed(true);

        const point = Point.fromEvent(event, DPI, $el.current);

        const newPoints = [...points, point];

        setPoints(newPoints);

        if ($el.current) {
            const ctx = $el.current.getContext('2d');

            if (ctx) {
                ctx.save();

                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';

                const pathData = new Path2D(getSvgPathFromStroke(getStroke(newPoints, perfectFreehandOptions)));

                ctx.fill(pathData);
            }
        }
    };

    const onMouseMove = (event) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        if (!isPressed) {
            return;
        }

        const point = Point.fromEvent(event, DPI, $el.current);

        if (point.distanceTo(points[points.length - 1]) > 5) {
            const newPoints = [...points, point];

            setPoints(newPoints);

            if ($el.current) {
                const ctx = $el.current.getContext('2d');

                if (ctx) {
                    ctx.restore();

                    ctx.imageSmoothingEnabled = true;
                    ctx.imageSmoothingQuality = 'high';

                    const pathData = new Path2D(getSvgPathFromStroke(getStroke(points, perfectFreehandOptions)));

                    ctx.fill(pathData);
                }
            }
        }
    };

    const onMouseUp = (event, addPoint = true) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        setIsPressed(false);

        const point = Point.fromEvent(event, DPI, $el.current);

        const newPoints = [...points];

        if (addPoint) {
            newPoints.push(point);

            setPoints(newPoints);
        }

        if ($el.current && newPoints.length > 0) {
            const ctx = $el.current.getContext('2d');

            if (ctx) {
                ctx.restore();

                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';

                const pathData = new Path2D(getSvgPathFromStroke(getStroke(newPoints, perfectFreehandOptions)));

                ctx.fill(pathData);

                ctx.save();
            }

            onChange?.($el.current.toDataURL());
        }

        setPoints([]);
    };

    const onMouseEnter = (event) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        if ('buttons' in event && event.buttons === 1) {
            onMouseDown(event);
        }
    };

    const onMouseLeave = (event) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        onMouseUp(event, false);
    };

    const onClearClick = () => {
        if ($el.current) {
            const ctx = $el.current.getContext('2d');

            ctx?.clearRect(0, 0, $el.current.width, $el.current.height);
        }

        onChange?.(null);

        setPoints([]);
    };

    useEffect(() => {
        if ($el.current) {
            $el.current.width = $el.current.clientWidth * DPI;
            $el.current.height = $el.current.clientHeight * DPI;
        }
    }, []);
    useEffect(() => {
        if (newValue) {
            onClearClick();
            setSignature(newValue);
        }
    }, [newValue]);

    useEffect(() => {
        if ($el.current && typeof defaultValue === 'string') {
            const ctx = $el.current.getContext('2d');

            const { width, height } = $el.current;

            const img = new Image();

            img.onload = () => {
                ctx?.drawImage(img, 0, 0, Math.min(width, img.width), Math.min(height, img.height));
            };

            img.src = defaultValue;
        }
    }, [defaultValue]);
    //dark:invert
    return (
        <div className={cn('relative block', containerClassName)}>
            <canvas
                ref={$el}
                className={cn('relative block ', className)}
                style={{ touchAction: 'none' }}
                onPointerMove={(event) => onMouseMove(event)}
                onPointerDown={(event) => onMouseDown(event)}
                onPointerUp={(event) => onMouseUp(event)}
                onPointerLeave={(event) => onMouseLeave(event)}
                onPointerEnter={(event) => onMouseEnter(event)}
                {...props}
            />

            <div className="absolute bottom-4 right-4">
                <button
                    type="button"
                    className="focus-visible:ring-ring ring-offset-background text-muted-foreground rounded-full p-0 text-xs focus-visible:outline-none focus-visible:ring-2"
                    onClick={() => onClearClick()}
                >
                    Clear Signature
                </button>
            </div>
        </div>
    );
};
