import React from 'react';

export const BgEditPencil = ({ className }) => {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect opacity="0.05" width="36" height="36" rx="18" fill="#101828" />
            <g clipPath="url(#clip0_1503_7432)">
                <path
                    d="M21.0059 11.6371L19.7402 12.9028L17.5113 14.2317L15.2824 15.5571L14.1258 19.6352L12.9727 23.7133L12.6984 23.9875C12.3469 24.3426 12.048 24.5008 11.6684 24.5289C11.0602 24.5746 10.5152 24.2618 10.241 23.7028C10.1109 23.4461 10.1074 23.4215 10.1074 23.0664C10.1074 22.5391 10.1812 22.395 10.7965 21.7129C11.3484 21.1012 11.5277 20.8586 11.6543 20.5387C11.9812 19.7266 11.8793 18.809 11.3871 18.0813C11.257 17.8914 9.81562 16.4184 9.75586 16.4184C9.71016 16.4184 9 17.1286 9 17.1743C9 17.1918 9.3375 17.5469 9.75234 17.9653C10.2305 18.4469 10.5328 18.7774 10.582 18.8793C10.7578 19.2274 10.7965 19.5578 10.7016 19.9164C10.6242 20.2188 10.5187 20.377 10.0652 20.8762C9.52383 21.4703 9.4043 21.6286 9.25664 21.9133C9.07734 22.2719 9 22.6164 9 23.0629C9 23.527 9.08086 23.868 9.28828 24.2688C9.48516 24.652 9.95977 25.1336 10.3184 25.32C11.0496 25.6996 11.9004 25.7278 12.6105 25.4008C12.9445 25.2461 13.1203 25.1196 13.4754 24.7856L13.7742 24.5043L17.891 23.3161L22.0113 22.1313L23.2418 19.8989L24.4723 17.6664L25.7379 16.4008C26.4305 15.7047 27 15.1176 27 15.1C27 15.0649 22.3242 10.3715 22.2891 10.3715C22.2785 10.3715 21.702 10.9411 21.0059 11.6371ZM23.8887 13.5004L25.4707 15.0825L24.757 15.7926L24.0469 16.5063L22.4578 14.9172L20.8652 13.3246L21.5684 12.6215C21.9551 12.2348 22.2785 11.9184 22.2891 11.9184C22.2996 11.9184 23.0203 12.6321 23.8887 13.5004ZM21.6984 15.7047L23.3754 17.3852L22.3137 19.3047L21.252 21.2278L18.3516 22.0786C16.7555 22.5496 15.4195 22.9399 15.3809 22.9504C15.3422 22.9575 15.982 22.293 16.8047 21.4668L18.2988 19.9692H18.8402H19.3816L19.3605 19.0516C19.35 18.5453 19.3359 18.127 19.3289 18.1235C19.3219 18.1164 18.9105 18.12 18.4113 18.1305L17.5078 18.1516V18.6825V19.2133L16.0102 20.7075C15.1102 21.6075 14.523 22.1664 14.5406 22.1137C14.5547 22.0645 14.9309 20.7356 15.3809 19.1606L16.193 16.2953L18.0914 15.1633C19.132 14.5411 19.9934 14.0313 20.0039 14.0278C20.0145 14.0278 20.7773 14.7836 21.6984 15.7047Z"
                    fill="#101828"
                />
            </g>
            <defs>
                <clipPath id="clip0_1503_7432">
                    <rect width="18" height="18" fill="white" transform="translate(9 9)" />
                </clipPath>
            </defs>
        </svg>
    );
};
