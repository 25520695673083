import { getFileIcon } from 'Hooks/Files/getFileIcon';
import React from 'react';

const GenerateFileIcon = ({ url, sx, showImage, imgPrperties, onClick }) => {
    const icon = getFileIcon(url, sx, showImage, imgPrperties);

    return (
        <div onClick={onClick} className={onClick ? 'cursor-pointer' : ''}>
            {icon}
        </div>
    );
};

export default GenerateFileIcon;
