import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import { Loader } from 'components/common/Loader';
import Recaptcha from 'components/molecules/Recaptcha';
import { useCaptchaHandler } from 'Hooks/reCaptcha/useCaptchaHandler';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { sentOtpToPhoneTempThunk, verifyPhoneOtpTempThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';

export const PhoneNumberAltername = (props) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.login);
    const navigate = useNavigate();
    const { error, setError, isCaptchaRequired, recaptchaRef, handleRequest, handleCaptchaChange } =
        useCaptchaHandler();
    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerEnded, setTimerEnded] = useState(false);

    const isLoading = useSelector((state) => state.global.isLoading);

    const [otpVal, setOtpVal] = useState('');

    const handleSubmit = (_e) => {
        if (otpVal?.length < 6) {
            toastHandler('Please enter full otp', 'error');
            return;
        }
        const otpData = {
            email: data?.email,
            otp: otpVal,
            user_type: 'employee',
            type: 2,
            disabel_google_auth: true
        };
        dispatch(setLoader(true));
        dispatch(verifyPhoneOtpTempThunk(otpData))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    const forgotPassData = {
                        email: data?.email,
                        otp: false,
                        questions: false
                    };
                    localStorage.setItem('forgotPassData', JSON.stringify(forgotPassData));
                    sessionStorage.setItem('resetStepper', 1);
                    sessionStorage.setItem('twoFaStepper', 1);

                    sessionStorage.setItem('lostStepper', 1);

                    sessionStorage.setItem('isLost', true);

                    navigate('/reset-auth', {
                        state: {
                            isNavigating: true
                        }
                    });
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });
        dispatch(setLoader(false));
    };

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    const sentOtp = async (resetTimer) => {
        dispatch(setLoader(true));
        await handleRequest(
            'sentOtpToPhoneTemp',
            (payload = {}) =>
                dispatch(
                    sentOtpToPhoneTempThunk({
                        ...payload
                    })
                ),
            { email: data?.email, type: 2, user_type: 'employee' }
        )
            .then((response) => {
                if (response?.payload) {
                    if (resetTimer) {
                        setTimerEnded(false);
                        setTimeRemaining(90);
                        timeExecution();
                    }
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        sentOtp();
    }, []);

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    useEffect(() => {
        timeExecution();
    }, []);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    return (
        <div>
            {' '}
            <div>
                {isLoading && (
                    <div className="loader-overlay">
                        <Loader />
                    </div>
                )}
                <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                    <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px]  lg:max-w-[640px] xl:max-w-[650px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                        {' '}
                        <div className="text-center card-layout-title-text font-fira ">Phone OTP Verification</div>
                        <div className="otp-wrapper px-5 md:px-24 mt-10">
                            <div className="otp-body small-text font-poppins text-sixth">
                                <p>
                                    A 6-digit code is sent to your phone. Kindly enter that code here to continue
                                    further. Thanks
                                </p>
                            </div>
                            <div className=" ">
                                <div className="mt-8">
                                    <OtpInputs value={otpVal} onChange={handleOtpChange} required={true} />
                                </div>
                            </div>

                            {isCaptchaRequired && (
                                <div className="my-3">
                                    <Recaptcha
                                        ref={recaptchaRef}
                                        captchaError={!!error}
                                        setCaptchaError={setError}
                                        onValueChange={handleCaptchaChange}
                                    />
                                </div>
                            )}

                            <div className="resend-confirmation text-sm font-poppins mt-5">
                                <p>
                                    {timerEnded ? (
                                        <span className="text-thirdbg  cursor-pointer" onClick={() => sentOtp(true)}>
                                            Resend
                                        </span>
                                    ) : (
                                        <span>
                                            <span>
                                                <span className="text-thirdbg  cursor-pointer mr-1">Resend</span>
                                                OTP in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`})
                                            </span>
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className="mt-60 mb-16">
                                <div className="flex justify-between items-center px-1-">
                                    <div onClick={() => props.handleCondition(1)}>
                                        <button className="border-button text-secondarybg buttons-font font-fira px-10 py-2 rounded-lg">
                                            Back
                                        </button>
                                    </div>

                                    <div>
                                        <button
                                            onClick={handleSubmit}
                                            className="ann-btn buttons-font font-fira px-10 py-2 rounded-lg"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
