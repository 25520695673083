import React from 'react';
import '../css/atoms.css';
import { cn } from 'utills/tailwindUtil';
export const Chip = ({ title, classes, iconSet, atClick, titleClick }) => {
    return (
        <>
            <div
                onClick={atClick}
                className={cn(
                    'rounded-xl text-[12px] md:text-[12px] lg:text-[12px] xl:text-[14px]  font-popppins font-[500] px-4 py-1',
                    classes
                )}
            >
                {iconSet?.leftIcon && <span className="w-5 h-5">{<iconSet.leftIcon />}</span>}

                <span onClick={titleClick}>{title}</span>
                {iconSet?.rightIcon && (
                    <span className="">
                        {
                            <iconSet.rightIcon
                                className={iconSet?.rightIconClass}
                                fill={iconSet?.rightIconFill ? iconSet?.rightIconFill : ''}
                                {...iconSet.rightIconProps}
                            />
                        }
                    </span>
                )}
            </div>
        </>
    );
};
