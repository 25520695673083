import React from 'react';
import AdvancedSettingsPage from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/advanced-settings/AdvancedSettingsPage';
const AdvancedSettings = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <AdvancedSettingsPage />
            </div>
        </div>
    );
};

export default AdvancedSettings;
