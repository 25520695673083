import { Button } from 'components/atoms/buttons/Button';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { cn } from 'utills/tailwindUtil';
import { useGetGroups } from 'Hooks/useGetGroups';
import ConfirmationModal from 'utills/confirmationModal';
import { useDeleteGroup } from 'Hooks/useDeleteGroup';
import { useSelector } from 'react-redux';
import { Loader } from 'components/common/Loader';
import { AddEditJobGroup } from './AddEditJobsGroup';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

export const JobGroups = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [createGroup, setCreateGroup] = useState(false);
    const { hasPermissions } = useRoleGuard();

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedGroups, _setSelectedGroups] = useState([]);
    const { handleGetGroups, data: groups } = useGetGroups();
    const { handleDeleteGroup } = useDeleteGroup();
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [data, setData] = useState([]);
    const [deleteIds, setDeleteIds] = useState('');
    const { isLoading } = useSelector((state) => state.global);

    useEffect(() => {
        handleGetGroups('jobs');
    }, []);

    const handleDelete = () => {
        setIsConfirmationOpen(false);

        handleDeleteGroup(deleteIds, () => {
            setDeleteIds('');
            handleGetGroups('jobs');
        });
    };

    useEffect(() => {
        if (groups) {
            setData(groups?.filter((item) => item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())));
        }
    }, [searchTerm, groups]);

    return (
        <SidebarLayout>
            <RoleBasedGuard module={'Job'} permissions={['admin', 'read', 'write']} sx="h-[50vh]">
                {isLoading && <Loader />}
                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={handleDelete}
                />
                {createGroup && (
                    <AddEditJobGroup
                        open={createGroup}
                        setOpen={(val) => {
                            setSelectedGroup(null);
                            setCreateGroup(val);
                        }}
                        group={selectedGroup}
                        handleComplete={() => handleGetGroups('jobs')}
                    />
                )}
                <Jumbotron
                    title="Job Groups"
                    path={[
                        { link: 'settings', title: 'Settings' },
                        { link: 'settings/job-manager', title: 'Job Manager Settings' },
                        { link: 'settings/groups/jobs', title: 'Job Groups' }
                    ]}
                />
                <div className="md:mx-10 mx-5 mt-10">
                    <div className="flex justify-between items-center">
                        <SearchInput
                            width="320px"
                            defaultValue={searchTerm || ''}
                            atChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            placeholder="Search by Title..."
                        />
                        <Button
                            title="Create Group"
                            classes="ann-btn px-10 rounded-xl"
                            atClick={() => setCreateGroup(!createGroup)}
                        />
                    </div>
                    <div className="mt-10">
                        <div className=" pb-5 rounded-xl overflow-x-auto">
                            <div className="mt-8 flow-root">
                                <div className="inline-block min-w-full py-4 align-middle">
                                    <div className="relative">
                                        <table className="min-w-full table-fixed divide-y  divide-gray-300 bg-white">
                                            <thead>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="min-w-[12rem] py-3.5 px-3 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        Title
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        Created At
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        Update At
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                    >
                                                        Created By
                                                    </th>

                                                    <th
                                                        scope="col"
                                                        className="px-3 py-3.5 text-left flex gap-2 items-center text-md font-semibold text-gray-900"
                                                    >
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {data?.map((group, index) => (
                                                    <tr
                                                        key={group?.group_id + index}
                                                        className={` ${
                                                            selectedGroups?.includes(group) ? 'bg-gray-50' : undefined
                                                        } hover:bg-[#F8F4FE] transition-colors duration-200 cursor-pointer`}

                                                        //
                                                    >
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap   px-3 text-md py-3.5 font-poppins',
                                                                selectedGroups.includes(group)
                                                                    ? 'text-thirdbg'
                                                                    : 'text-black'
                                                            )}
                                                        >
                                                            {group?.title}
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                                            <FormateDateTime dateTime={group?.created_at} />
                                                        </td>
                                                        <td className="whitespace-nowrap px-3  py-3.5 text-md ">
                                                            <FormateDateTime dateTime={group?.updated_at} />
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-3.5  text-md text-gray-500">
                                                            <DefaultProfileViewer
                                                                height={'45px'}
                                                                width={'45px'}
                                                                image={group?.created_by?.image}
                                                                name={
                                                                    group?.created_by?.first_name +
                                                                    ' ' +
                                                                    group?.created_by?.last_name
                                                                }
                                                                tooltip
                                                            />
                                                        </td>

                                                        <td className="whitespace-nowrap px-3 py-3.5 ">
                                                            <div className="flex gap-2 items-center">
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        if (!hasPermissions('Job', ['admin'])) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }

                                                                        setSelectedGroup(group);
                                                                        setCreateGroup(true);
                                                                    }}
                                                                >
                                                                    <BgEditIcon />
                                                                </div>
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        if (!hasPermissions('Job', ['admin'])) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }

                                                                        setDeleteIds(group?.group_id);
                                                                        setIsConfirmationOpen(true);
                                                                    }}
                                                                >
                                                                    <BgDeleteIcon />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
