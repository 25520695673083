import React from 'react';

export const AnnouncementIcon = ({ className }) => {
    return (
        <svg
            width="28"
            height="24"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M4.85714 2.28564H2.28571C1.94472 2.28564 1.6177 2.4211 1.37658 2.66222C1.13546 2.90334 1 3.23037 1 3.57136V17.7142C1 18.0552 1.13546 18.3822 1.37658 18.6234C1.6177 18.8645 1.94472 18.9999 2.28571 18.9999H15.1429C15.4838 18.9999 15.8109 18.8645 16.052 18.6234C16.2931 18.3822 16.4286 18.0552 16.4286 17.7142V3.57136C16.4286 3.23037 16.2931 2.90334 16.052 2.66222C15.8109 2.4211 15.4838 2.28564 15.1429 2.28564H12.5714"
                stroke="url(#paint0_linear_144_1065)"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.42868 7.42857H13.8572M7.42868 11.2857H13.8572M7.42868 15.1429H13.8572M3.57153 7.42857H4.85725M3.57153 11.2857H4.85725M3.57153 15.1429H4.85725M6.14296 1H11.2858C11.6268 1 11.9538 1.13546 12.195 1.37658C12.4361 1.6177 12.5715 1.94472 12.5715 2.28571C12.5715 2.62671 12.4361 2.95373 12.195 3.19485C11.9538 3.43597 11.6268 3.57143 11.2858 3.57143H6.14296C5.80197 3.57143 5.47494 3.43597 5.23382 3.19485C4.99271 2.95373 4.85725 2.62671 4.85725 2.28571C4.85725 1.94472 4.99271 1.6177 5.23382 1.37658C5.47494 1.13546 5.80197 1 6.14296 1Z"
                stroke="url(#paint1_linear_144_1065)"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_144_1065"
                    x1="9.4713"
                    y1="2.49322"
                    x2="9.45774"
                    y2="19"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_144_1065"
                    x1="9.21906"
                    y1="1.17564"
                    x2="9.2045"
                    y2="15.1429"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
