import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: getNotificationModules, request: getNotificationModulesThunk } = generateThunkAndSlice(
    'getNotificationModules',
    endpoint.getNotificationModules,
    methods.GET
);
const { slice: getNotificationSettings, request: getNotificationSettingsThunk } = generateThunkAndSlice(
    'getNotificationSettings',
    endpoint.getNotificationSettings,
    methods.GET
);
const { request: updateNotificationSettingsThunk } = generateThunkAndSlice(
    'updateNotificationSettings',
    endpoint.updateNotificationSettings,
    methods.PUT
);
const { request: resetAllNotificationsThunk } = generateThunkAndSlice(
    'resetAllNotifications',
    endpoint.resetAllNotifications,
    methods.PUT
);
const { request: updateSettingsObjThunk } = generateThunkAndSlice(
    'updateSettingsObj',
    endpoint.updateSettingsObj,
    methods.PUT
);
const { request: resetSettingThunk } = generateThunkAndSlice('resetSetting', endpoint.resetSetting, methods.PUT);

export {
    getNotificationModules,
    getNotificationModulesThunk,
    getNotificationSettings,
    getNotificationSettingsThunk,
    updateNotificationSettingsThunk,
    resetAllNotificationsThunk,
    updateSettingsObjThunk,
    resetSettingThunk
};
