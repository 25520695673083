import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { cn } from 'utills/tailwindUtil';
import { SideTimes } from 'utills/svgs/SideTimes';
import { useSelector } from 'react-redux';
import { Loader } from 'components/common/Loader';

const Slideover = ({
    outerCss = '',
    open,
    setOpen,
    title,
    titlesx,
    children,
    size,
    onBack,
    footer,
    hideBackArrow,
    subTitle,
    disableOutClick,
    id,
    extraButton,
    flexSx,
    WrapComponent,
    showLoader = false
}) => {
    const [openSlider, setOpenSlider] = useState(false);
    const containerRef = useRef(null);
    const { isLoading } = useSelector((state) => state.global);

    useEffect(() => {
        setTimeout(() => {
            setOpenSlider(open);
            if (open && containerRef.current) {
                containerRef.current.scrollTop = 0;
            }
        }, 100);
    }, [open]);

    const content = (
        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl rounded-xl">
            <div className="px-4 sm:px-6">
                <div className={cn('flex items-start justify-between', flexSx)}>
                    {title ? (
                        <div className="flex flex-col gap-0">
                            <Dialog.Title
                                className={cn(
                                    'flex text-2xl mt-4 font-bold leading-6 text-slate-900 sm:truncate font-secondary',
                                    titlesx || ''
                                )}
                            >
                                {!hideBackArrow && (
                                    <ChevronLeftIcon
                                        className="text-s-800 pb-2 h-8 w-7 flex-shrink-0 cursor-pointer"
                                        onClick={onBack ? onBack : () => setOpen(false)}
                                    />
                                )}
                                {title}
                            </Dialog.Title>
                            {subTitle && <div className="jumbo-dir">{subTitle}</div>}
                        </div>
                    ) : (
                        <div className="flex"></div>
                    )}
                    <div className="ml-3 flex gap-2 h-7 items-center">
                        {extraButton}
                        <button
                            type="button"
                            className="relative rounded-full  text-gray-100 hover:text-gray-200 p-1"
                            onClick={() => setOpen(false)}
                        >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <SideTimes className={''} />
                        </button>
                    </div>
                </div>
            </div>
            <div id={id} className={cn('relative mt-2 flex-1 px-4 sm:px-6', id && '!px-[10px]')}>
                {children}
            </div>
            {footer}
        </div>
    );

    return (
        <>
            {isLoading && showLoader && <Loader />}
            <Transition.Root show={openSlider} as={Fragment}>
                <Dialog
                    as="div"
                    className={cn('relative !z-[999]', outerCss)}
                    onClose={() => !disableOutClick && setOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        leave="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform ease-out duration-300 sm:duration-500"
                                    leave="transform ease-out duration-300 sm:duration-500"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel
                                        className={cn('pointer-events-auto w-screen', size ? size : 'max-w-2xl')}
                                        ref={containerRef}
                                    >
                                        {WrapComponent ? <WrapComponent>{content}</WrapComponent> : content}
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default Slideover;
