import React, { useEffect, useState } from 'react';

export const AccountNumberInput = ({ onChange, value, placeholder, disabled }) => {
    const [accountNumber, setAccountNumber] = useState('');
    const [_accountError, setAccountError] = useState(false);

    useEffect(() => {
        if (value) {
            setAccountNumber(value);
        }
    }, [value]);
    const handleValueChange = (e) => {
        let { value } = e.target;

        value = value.replace(/[^0-9]/g, '');

        if (value.length <= 12) {
            setAccountNumber(value);
            onChange(value);

            if (value.length < 12) {
                setAccountError(false);
            } else {
                setAccountError(true);
            }
        }
    };
    return (
        <div className="default-forms ">
            <div>
                {' '}
                <input
                    type="text"
                    name=""
                    id=""
                    onChange={(e) => {
                        handleValueChange(e);
                    }}
                    placeholder={placeholder}
                    className={`${!disabled ? '' : 'bg-gray-200 !border-none !cursor-no-drop'}`}
                    value={accountNumber}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
