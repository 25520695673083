import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import AvatarGroup from 'components/atoms/AvatarGroup';
import { Button } from 'components/atoms/buttons/Button';
import FormateDateTime from 'components/atoms/FormateDateTime';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { NoDataFound } from 'components/common/NoDataFound';
import { CollapsDivComponent } from 'components/molecules/collapsDivComponent/CollapsDivComponent';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import useRoleGuard from 'Hooks/useRoleGuard';
import { cn } from 'pages/documents/hooks/helper';
import { JOB_CLOSED } from 'pages/settings/job-manager/jobs.constants';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { JobManagerCollateDocListThunk, JobManagerDeleteItemsThunk } from 'store/JobManager';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { downloadFile } from 'utills/filesHandlers';
import { BUSINESS_ID, handleDownload, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import { DownloadIcon2 } from 'utills/svgs/DownloadIcon2';
import { PdfFile } from 'utills/svgs/file-icons/PdfFile';
import { SignIcon } from 'utills/svgs/SignIcon';

const CollateDocs = ({ collateListReload, setCollateListReload }) => {
    const dispatch = useDispatch();
    const checkbox = useRef();

    const { data: jobDetails } = useSelector((state) => state.getSingleJob);
    const disabled = jobDetails?.status?.label === JOB_CLOSED;

    const { hasPermissions } = useRoleGuard();
    const { id } = useParams();
    const navigate = useNavigate();
    const business_id = localStorage.getItem(BUSINESS_ID);

    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');

    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [page, setPage] = useState(1);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        if (collateListReload) {
            setCollateListReload(false);
            if (page === 1) {
                getData(true);
            } else {
                setPage(1);
            }
        }
    }, [collateListReload]);

    useEffect(() => {
        getData();
    }, [page]);

    const getData = async (hasReset = false) => {
        const payload = {
            business_id,
            job_id: id,
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE)
        };

        setHasMoreContent(true);

        try {
            const response = await dispatch(JobManagerCollateDocListThunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? pageData || [] : []), ...response.payload.rows];
                setPageData(cloneData);
                setHasMoreContent(cloneData.length >= response.payload.count ? false : true);
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const handlePageChange = () => {
        setPage(page + 1);
    };

    useLayoutEffect(() => {
        const isIndeterminate = selectedIds.length > 0 && selectedIds.length < pageData.length;
        setChecked(pageData?.length > 0 && selectedIds.length === pageData.length);
        setIndeterminate(isIndeterminate);

        if (checkbox.current) {
            checkbox.current.indeterminate = isIndeterminate;
        }
    }, [selectedIds]);

    function toggleAll() {
        const newIds = [];
        pageData?.forEach((obj) => {
            newIds.push(obj);
        });

        setSelectedIds(checked || indeterminate ? [] : newIds);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const handleSelectId = (obj) => {
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item?.job_drive_id === obj?.job_drive_id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(obj);
            setSelectedIds(newIds);
        }
    };

    const handleDeleteItem = (ids) => {
        const jsonPayload = {
            business_id,
            job_id: id,
            item_ids: ids?.join(',')
        };
        dispatch(setLoader(true));
        dispatch(JobManagerDeleteItemsThunk(jsonPayload))
            .then((res) => {
                if (res.payload) {
                    if (page === 1) {
                        getData(true);
                    } else {
                        setPage(1);
                    }
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return (
        <div>
            <CollapsDivComponent
                title={'Collate Documents'}
                h_one="max-h-[65px]"
                h_two="max-h-[350px]"
                classes="px-5 py-5"
                defaultClose
            >
                {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
                {selectedPdf && (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                    />
                )}
                <div className="my-5 max-h-[540px] overflow-auto">
                    {pageData?.length === 0 ? (
                        <NoDataFound height="200px" message="No Record Found!" />
                    ) : (
                        <InfiniteScroll
                            height={'300px'}
                            next={handlePageChange}
                            dataLength={pageData.length}
                            hasMore={hasMoreContent}
                            loader={<SkeletonTable columns={6} />}
                        >
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts p-4 !px-0 !min-w-[40px] !w-[40px] text-center'
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                className="rounded-[3px] cursor-pointer"
                                                ref={checkbox}
                                                checked={checked}
                                                onChange={toggleAll}
                                            />
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !w-[200px] !max-w-[200px] text-left'
                                            }
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'
                                            }
                                        >
                                            Status
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Date & Time
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Created By
                                        </th>
                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                            }
                                        >
                                            Merged Documents
                                        </th>

                                        <th
                                            scope="col"
                                            className={
                                                'sticky top-0  !border-b !border-gray-300 bg-white z-[1] backdrop-blur backdrop-filter   tableHeadings primary-fonts !w-[200px] pl-[10px] text-center'
                                            }
                                        >
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-transparent gap-0">
                                    {pageData &&
                                        pageData?.length > 0 &&
                                        pageData?.map((dataObj, index) => {
                                            return (
                                                <tr
                                                    key={'req_doc_table' + index}
                                                    className="border-y relative  divide-gray-300"
                                                >
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap !text-[16px] p-4 !px-0 text-center',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div className="min-w-[18px]">
                                                            <input
                                                                type="checkbox"
                                                                className="rounded-[3px] cursor-pointer"
                                                                checked={selectedIds?.some(
                                                                    (item) => item.job_drive_id === dataObj.job_drive_id
                                                                )}
                                                                onClick={() => handleSelectId(dataObj)}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap !text-[16px] py-2 !max-w-[200px]',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div
                                                            className="flex items-center gap-2 cursor-pointer overflow-hidden"
                                                            onClick={() => {
                                                                if (dataObj?.url && dataObj?.url?.includes('.pdf')) {
                                                                    setSelectedPdf(dataObj?.url);
                                                                }
                                                            }}
                                                        >
                                                            <GenerateFileIcon url={dataObj?.url} sx={'w-10'} />

                                                            <div className="flex flex-col overflow-hidden">
                                                                <div className="app-dataObj-name flex items-center gap-2 text-ellipsis overflow-hidden">
                                                                    {dataObj?.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : '',
                                                            dataObj?.status === 'collated'
                                                                ? 'text-orange-500'
                                                                : 'text-red-500'
                                                        )}
                                                    >
                                                        {dataObj?.status === 'collated' ? dataObj?.status : 'Error'}
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        {dataObj?.created_at ? (
                                                            <FormateDateTime dateTime={dataObj?.created_at} />
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <DefaultProfileViewer
                                                            image={dataObj?.created_by?.image}
                                                            width="45px"
                                                            height="45px"
                                                            name={
                                                                dataObj?.created_by?.first_name +
                                                                ' ' +
                                                                dataObj?.created_by?.last_name
                                                            }
                                                            tooltip
                                                        />
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <AvatarGroup
                                                            list={dataObj?.meta}
                                                            limit={2}
                                                            targetProperty={'url'}
                                                            sx2={
                                                                '!w-7 !h-7 bg-[#d17c19] p-1 !rounded-lg dark:border-gray-800'
                                                            }
                                                            onClick={(e) => {
                                                                if (e?.url?.includes('.pdf')) {
                                                                    setSelectedPdf(e?.url);
                                                                } else if (e?.url) {
                                                                    downloadFile(e?.url);
                                                                }
                                                            }}
                                                            showIcon={
                                                                <>
                                                                    <PdfFile
                                                                        className={
                                                                            'w-10 bg-white border-2 border-white dark:border-gray-800'
                                                                        }
                                                                    />
                                                                </>
                                                            }
                                                        />
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center',
                                                            index !== pageData?.length
                                                                ? '!border-b !border-gray-200'
                                                                : ''
                                                        )}
                                                    >
                                                        <div className="flex justify-center items-center gap-2">
                                                            {dataObj?.url && (
                                                                <Button
                                                                    atClick={() => {
                                                                        if (
                                                                            !hasPermissions('Job', ['write', 'admin'])
                                                                        ) {
                                                                            return toastHandler(
                                                                                'You do not have permission to perform this action.',
                                                                                TOAST_TYPE_ERROR
                                                                            );
                                                                        }
                                                                        navigate(
                                                                            `/job-manager/request-signature/${id}`,
                                                                            {
                                                                                state: [dataObj]
                                                                            }
                                                                        );
                                                                    }}
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <SignIcon
                                                                                className={'w-5 text-[#0A1E46]'}
                                                                            />
                                                                        )
                                                                    }}
                                                                    classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                    disabled={disabled}
                                                                />
                                                            )}
                                                            <Button
                                                                atClick={() => {
                                                                    if (
                                                                        !hasPermissions('Job', [
                                                                            'write',
                                                                            'read',
                                                                            'admin'
                                                                        ])
                                                                    ) {
                                                                        return toastHandler(
                                                                            'You do not have permission to perform this action.',
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }

                                                                    handleDownload({ url: dataObj?.url });
                                                                }}
                                                                iconSet={{
                                                                    leftIconHTML: (
                                                                        <DownloadIcon2
                                                                            className={'w-6 text-[#B695F8]'}
                                                                        />
                                                                    )
                                                                }}
                                                                classes={'!rounded-full bg-[#B695F8]/10 p-2'}
                                                            />
                                                            <Button
                                                                atClick={() => {
                                                                    if (!hasPermissions('Job', ['write', 'admin'])) {
                                                                        return toastHandler(
                                                                            'You do not have permission to perform this action.',
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }
                                                                    handleDeleteItem([dataObj?.job_drive_id]);
                                                                }}
                                                                iconSet={{
                                                                    leftIconHTML: (
                                                                        <DeleteIcon
                                                                            className={'!min-w-4 w-5 text-[#0A1E46]'}
                                                                        />
                                                                    )
                                                                }}
                                                                classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                disabled={disabled}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </InfiniteScroll>
                    )}
                </div>
            </CollapsDivComponent>
        </div>
    );
};

export default CollateDocs;
