import React from 'react';

export const BgRevokeIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="26"
            height="26"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect opacity="0.05" width="36" height="36" rx="18" fill="url(#paint0_linear_4455_729)" />
            <g clipPath="url(#clip0_4455_729)">
                <path
                    d="M17.1093 6.03272C14.9156 6.23897 13.064 6.87647 11.3906 7.99678C9.5859 9.20147 8.18903 10.7952 7.24215 12.7265C6.45465 14.3202 6.07965 15.8249 6.01403 17.6015C5.94372 19.5983 6.35622 21.5015 7.24684 23.2921C7.87028 24.553 8.51715 25.4437 9.53434 26.4655C10.5703 27.4968 11.4656 28.139 12.7265 28.7577C13.6078 29.189 14.175 29.3999 15.0703 29.6249C17.8734 30.328 20.7375 30.0233 23.2922 28.753C24.5765 28.1155 25.4156 27.5062 26.4656 26.4608C27.4828 25.4437 28.125 24.553 28.7531 23.2921C30.2015 20.3812 30.3937 17.053 29.2922 13.9499C29.0718 13.3265 28.5281 12.2015 28.1906 11.6718C27.2015 10.1108 25.6734 8.61084 24.1781 7.72022C22.2422 6.56709 20.0953 5.98116 17.9062 6.00928C17.5593 6.00928 17.1984 6.02334 17.1093 6.03272ZM19.1718 7.78116C23.8875 8.35303 27.5672 11.9812 28.2 16.6874C28.3359 17.714 28.2656 19.1999 28.0359 20.2358C27.0797 24.4968 23.5359 27.689 19.1718 28.2187C18.6 28.289 17.3062 28.2749 16.7062 28.1999C11.9953 27.5765 8.35309 23.8968 7.78122 19.1718C7.72028 18.6608 7.72028 17.339 7.78122 16.828C8.3484 12.1358 11.9672 8.45147 16.6406 7.80459C17.189 7.72959 18.6375 7.71553 19.1718 7.78116Z"
                    fill="url(#paint1_linear_4455_729)"
                />
                <path
                    d="M13.4391 12.9141C13.0079 13.0641 12.7782 13.4954 12.8907 13.9313C12.9376 14.1094 13.1626 14.3532 14.8735 16.0641L16.8048 18L14.8735 19.9313C13.1766 21.6375 12.9376 21.8907 12.8907 22.0688C12.7266 22.6969 13.3032 23.2735 13.9313 23.1094C14.1095 23.0625 14.3626 22.8235 16.0688 21.1266L18.0001 19.1954L19.936 21.1266C21.6376 22.8235 21.8907 23.0625 22.0688 23.1094C22.697 23.2735 23.2735 22.6969 23.1095 22.0688C23.0626 21.8907 22.8235 21.6375 21.1266 19.9313L19.1954 18L21.1266 16.0641C22.8235 14.3625 23.0626 14.1094 23.1095 13.9313C23.2735 13.3032 22.697 12.7266 22.0688 12.8907C21.8907 12.9375 21.6376 13.1766 19.936 14.8735L18.0001 16.8047L16.0688 14.8735C14.4282 13.2375 14.1048 12.9375 13.9454 12.8954C13.711 12.8297 13.6595 12.8344 13.4391 12.9141Z"
                    fill="url(#paint2_linear_4455_729)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4455_729"
                    x1="19.7664"
                    y1="0.447084"
                    x2="19.7394"
                    y2="36.0001"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_4455_729"
                    x1="19.1796"
                    y1="29.6967"
                    x2="19.1617"
                    y2="6.00826"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_4455_729"
                    x1="18.5042"
                    y1="23.0099"
                    x2="18.4965"
                    y2="12.8499"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="0.998509" stopColor="#0D1B37" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
                <clipPath id="clip0_4455_729">
                    <rect width="24" height="24" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>
    );
};
