import React from 'react';
import { useSelector } from 'react-redux';
import { ProgressIcon } from 'utills/svgs/ProgressIcon';
import { RedClock } from 'utills/svgs/RedClock';
import { ReportsListIcon } from 'utills/svgs/ReportsListIcon';
import { YellowClock } from 'utills/svgs/YellowClock';

export const NumberCards = () => {
    const { data } = useSelector((state) => state.getTaskReportsInfo);

    return (
        <div className="md:mx-10  mx-5 mt-5  rounded-xl">
            <div className="flex items-center flex-wrap gap-5">
                <div className="reports-card-strip  flex gap-4 w-[300px] items-center">
                    <div>
                        <ReportsListIcon />
                    </div>
                    <div className="flex   gap-4 flex-col ">
                        <div className="buttons-font font-poppins text-[#4E4E50] ">Total Tasks</div>
                        <div className="text-3xl font-poppins">{data?.stats?.total}</div>
                    </div>
                </div>
                <div className="reports-card-strip-one flex gap-4 items-center w-[300px]">
                    <div>
                        <YellowClock />
                    </div>
                    <div className="flex  gap-4 flex-col">
                        <div className="buttons-font font-poppin text-[#4E4E50] s">Completed Tasks </div>
                        <div className="text-3xl font-poppins">{data?.stats?.completed}</div>
                    </div>
                </div>

                <div className="reports-card-strip-two flex gap-4 items-center w-[300px]">
                    <div>
                        <RedClock />
                    </div>
                    <div className="flex  gap-4 flex-col">
                        <div className="buttons-font font-poppins text-[#4E4E50] ">Delayed Tasks </div>
                        <div className="text-3xl font-poppins">{data?.stats?.overdue}</div>
                    </div>
                </div>
                <div className="reports-card-strip-three flex gap-4 items-center w-[300px]">
                    <div>
                        <ProgressIcon />
                    </div>
                    <div className="flex  gap-4 flex-col">
                        <div className="buttons-font text-[#4E4E50] font-poppins">Task in progress</div>
                        <div className="text-3xl font-poppins">{data?.stats?.in_progress}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
