import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: getLeads, request: getLeadsThunk } = generateThunkAndSlice(
    'fetchCustomerForms',
    endpoint.getLeads,
    methods.POST
);

const { request: addLeadThunk } = generateThunkAndSlice('addLead', endpoint.addLead, methods.POST);

const { request: deleteLeadThunk } = generateThunkAndSlice('deleteLead', endpoint.deleteLead, methods.POST);

const { request: updateLeadThunk } = generateThunkAndSlice('deleteLead', endpoint.updateLead, methods.POST);

export { getLeads, getLeadsThunk, addLeadThunk, deleteLeadThunk, updateLeadThunk };
