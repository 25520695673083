import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { MultiFactorInfo } from './MultiFactorInfo';
import { EmailVerification } from './EmailVerification';
import { EmailAuthSuccess } from './EmailAuthSuccess';
import { GoogleAuthenticationInstructions } from './GoogleAuthenticationInstructions';
import { VerifyGoogleAuth } from './VerifyGoogleAuth';
import { AuthenticationSuccess } from './AuthenticationSuccess';
import { AuthenticationBackupCodes } from './AuthenticationBackupCodes';

export const MultiFactorAuthentication = () => {
    const navigate = useNavigate();

    const multifactorStepper = JSON.parse(sessionStorage.getItem('multifactorStepper'));
    const [condition, setCondition] = useState(multifactorStepper ?? 0);

    const handleCondition = (condition) => {
        sessionStorage.setItem('multifactorStepper', condition);
        setCondition(condition);
    };

    function MyComponent() {
        return (
            <div>
                {condition === 0 && <MultiFactorInfo handleCondition={handleCondition} />}
                {condition === 1 && <EmailVerification handleCondition={handleCondition} />}
                {condition === 2 && <EmailAuthSuccess handleCondition={handleCondition} />}
                {condition === 3 && <GoogleAuthenticationInstructions handleCondition={handleCondition} />}
                {condition === 4 && <VerifyGoogleAuth handleCondition={handleCondition} />}
                {condition === 5 && <AuthenticationSuccess handleCondition={handleCondition} />}
                {condition === 6 && <AuthenticationBackupCodes handleCondition={handleCondition} />}
            </div>
        );
    }

    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/security-and-authentication`);
        }
    };

    return (
        <div>
            <SidebarLayout>
                <Jumbotron
                    title="Multi Factor Authentication"
                    path={[
                        { title: 'Settings', link: 'settings' },
                        {
                            title: 'Security and Authentication',
                            link: 'security-and-authentication'
                        },

                        {
                            title: 'Multi Factor Authentication',
                            link: 'multi-factor-auth'
                        }
                    ]}
                    backArrowProp={backArrowProp}
                />

                <div>{MyComponent()}</div>
            </SidebarLayout>
        </div>
    );
};
