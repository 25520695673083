import React, { useState } from 'react';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { Button } from 'components/atoms/buttons/Button';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import { cn } from 'utills/tailwindUtil';
import AddAccountCategories from 'components/settings/email-account-settings/AddAccountCategories';

const EmailCategories = () => {
    const [open, setOpen] = useState(false);
    const [categoriesList, _setCategoriesList] = useState([]);

    return (
        <div>
            <div>
                <SidebarLayout>
                    <Jumbotron
                        title="Categories"
                        actionsRequired={true}
                        actions={[
                            {
                                title: 'Add Category',
                                type: 'button',
                                onAction: () => {
                                    setOpen(true);
                                },
                                classes: 'ann-btn rounded-xl buttons-font-lighter px-6'
                            }
                        ]}
                        path={[
                            {
                                link: 'settings',
                                title: 'Settings'
                            },
                            {
                                link: 'settings/email-manager-account-settings',
                                title: 'Email Manager Account Settings'
                            },
                            {
                                link: '',
                                title: 'Categories'
                            }
                        ]}
                    />
                    <div className="md:mx-10 mx-5 my-7">
                        <SecondaryParagraph sx={'text-md ml-1'}>
                            Categorizing your emails can help you to track messages related to a project, task or
                            person. You can create new categories and assign them names and colors.
                        </SecondaryParagraph>

                        <DefaultLightBgCard sx="bg-white !mt-3" title="Categories List">
                            <div className="">
                                <table className="min-w-full border-separate border-spacing-0">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !w-[200px] text-left'
                                                }
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                }
                                            >
                                                Create Date
                                            </th>

                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                }
                                            >
                                                Last Updated
                                            </th>

                                            <th
                                                scope="col"
                                                className={
                                                    'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left text-center'
                                                }
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-transparent gap-0">
                                        {categoriesList?.map((dataObj, _index) => {
                                            return (
                                                <tr
                                                    key={'req_doc_table'}
                                                    className="border-y relative  divide-gray-300"
                                                >
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap !text-[16px] py-2 !max-w-[200px] truncate relative '
                                                            // index !== getUserEmailSignature?.length
                                                            //     ? '!border-b !border-gray-200'
                                                            //     : ''
                                                        )}
                                                    >
                                                        {dataObj?.name || ''}
                                                    </td>
                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black'
                                                            // index !== getUserEmailSignature?.length
                                                            //     ? '!border-b !border-gray-200'
                                                            //     : ''
                                                        )}
                                                    >
                                                        {dataObj?.created_at ? (
                                                            <FormateDateTime dateTime={dataObj?.created_at} />
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black'
                                                            // index !== getUserEmailSignature?.length
                                                            //     ? '!border-b !border-gray-200'
                                                            //     : ''
                                                        )}
                                                    >
                                                        {dataObj?.updated_at ? (
                                                            <FormateDateTime dateTime={dataObj?.updated_at} />
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </td>

                                                    <td
                                                        className={cn(
                                                            'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center'
                                                            // index !== getUserEmailSignature?.length
                                                            //     ? '!border-b !border-gray-200'
                                                            //     : ''
                                                        )}
                                                    >
                                                        <div className="flex justify-center items-center gap-2">
                                                            <Button
                                                                iconSet={{
                                                                    leftIconHTML: <BgEditIcon className={''} />
                                                                }}
                                                                classes={'!rounded-full'}
                                                                groupSx={'!contents'}
                                                                // atClick={() => {
                                                                //     setEditObj(dataObj);
                                                                //     setOpen(true);
                                                                // }}
                                                            />
                                                            <Button
                                                                iconSet={{
                                                                    leftIconHTML: (
                                                                        <DeleteIcon
                                                                            className={'!min-w-4 w-5 text-[#0A1E46]'}
                                                                        />
                                                                    )
                                                                }}
                                                                classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                // atClick={() => {
                                                                //     setRemoveId(dataObj?.user_signature_id);
                                                                //     setIsConfirmationOpen(true);
                                                                // }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </DefaultLightBgCard>
                    </div>
                </SidebarLayout>
            </div>

            <AddAccountCategories open={open} setOpen={setOpen} />
        </div>
    );
};

export default EmailCategories;
