import React from 'react';
import PayrollPage from '../../components/payrol-pages/PayrollPage';

const Payroll = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <PayrollPage />
            </div>
        </div>
    );
};
export default Payroll;
