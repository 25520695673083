import React from 'react';

const ExpanderIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M17 0L3 0C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20L17 20C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17L20 3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 18C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H4L4 18H3ZM18 17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18L6 18L6 2L17 2C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L18 17Z"
                fill="white"
            />
            <path
                d="M15.9999 9H10.4099L11.7099 7.71C11.8982 7.5217 12.004 7.2663 12.004 7C12.004 6.7337 11.8982 6.47831 11.7099 6.29C11.5216 6.1017 11.2662 5.99591 10.9999 5.99591C10.7336 5.99591 10.4782 6.1017 10.2899 6.29L7.28994 9.29C7.19621 9.38297 7.12182 9.49357 7.07105 9.61543C7.02028 9.73728 6.99414 9.86799 6.99414 10C6.99414 10.132 7.02028 10.2627 7.07105 10.3846C7.12182 10.5064 7.19621 10.617 7.28994 10.71L10.2899 13.71C10.3829 13.8037 10.4935 13.8781 10.6154 13.9289C10.7372 13.9797 10.8679 14.0058 10.9999 14.0058C11.132 14.0058 11.2627 13.9797 11.3845 13.9289C11.5064 13.8781 11.617 13.8037 11.7099 13.71C11.8037 13.617 11.8781 13.5064 11.9288 13.3846C11.9796 13.2627 12.0057 13.132 12.0057 13C12.0057 12.868 11.9796 12.7373 11.9288 12.6154C11.8781 12.4936 11.8037 12.383 11.7099 12.29L10.4099 11H15.9999C16.2652 11 16.5195 10.8946 16.707 10.7071C16.8946 10.5196 16.9999 10.2652 16.9999 10C16.9999 9.73479 16.8946 9.48043 16.707 9.2929C16.5195 9.10536 16.2652 9 15.9999 9Z"
                fill="white"
            />
        </svg>
    );
};

export default ExpanderIcon;
