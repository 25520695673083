/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { SideTimes } from 'utills/svgs/SideTimes';
import { motion } from 'framer-motion';
import { generateId } from 'utills/uid';
import {
    BUSINESS_INDUSTRY_OCCUPATION,
    SALARY_WAGES_OCCUPATION,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS
} from 'utills/globalVars';
import { toastHandler } from 'responseHanlder';
import { useDispatch, useSelector } from 'react-redux';
import { formHandler, setLoader } from 'store/global/globalReducer';
import { postDynamicFormThunk, updateBusniessFormThunk } from 'store/form';
import { useGetForms } from 'Hooks/useGetForms';
import { Loader } from 'components/common/Loader';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';
import { GenerateStep } from './GenerateStep';
import { hasAtLeastTwoSameValues } from 'utills/dataValidation';
import { Button } from 'components/atoms/buttons/Button';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { convertSpacesToUnderscores, sanitizeString } from 'utills/handleText';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import { RedirectArrow } from 'utills/svgs/RedirectArrow';
import { EyeIcon } from '@heroicons/react/20/solid';
import { H3 } from 'components/atoms/typography/headings/H3';
import { CONDITIONAL, LIST, RECURRING } from 'utills/constants/forms/forms.constants';
import PreviewForm from './PreviewForm';

export const AddForm = ({ _togglePreviewForm, setOpen, condition, forceReloadTables, defaultName }) => {
    const dispatch = useDispatch();
    const { fetchForms } = useGetForms();
    const [form, setForm] = useState([]);
    const { isLoading } = useSelector((state) => state.global);
    const [showModal, setShowModal] = useState(false);
    const [modalIndex, setModalIndex] = useState(null);
    const [modalStep, setModalStep] = useState(null);
    const PreviousFormId = sessionStorage.getItem('form_id');
    const [previewForm, setPreviewForm] = useState(false);

    const toggleModal = (index, stepIndex) => {
        setShowModal(!showModal);
        setModalStep(stepIndex);
        setModalIndex(index);
    };

    useEffect(() => {
        if (defaultName) {
            const newForm = [...form];
            newForm.form_heading = defaultName;
            setForm(newForm);
        }
    }, [defaultName]);

    const [steps, setSteps] = useState([
        {
            step_heading: '',
            step_id: generateId(),
            step_condition: false,
            fields: []
        }
    ]);

    const handleFormName = (e) => {
        const newForm = [...form];
        newForm.form_heading = e.target.value;
        setForm(newForm);
    };

    const checkStepHeading = () => {
        for (const step of steps) {
            if (!step.step_heading) {
                return true;
            }
        }

        return false;
    };

    const handleAddStep = () => {
        const newSteps = cloneDeep(steps);

        if (!form?.form_heading?.trim()) {
            return toastHandler('Please add form name', TOAST_TYPE_ERROR);
        } else if (checkStepHeading()) {
            return toastHandler('Step heading is required', TOAST_TYPE_ERROR);
        } else {
            const step = {
                step_heading: '',
                step_id: generateId(),
                step: steps?.length,
                step_condition: false,
                fields: [],
                step_tag: ''
            };

            newSteps.push(step);
            setSteps(newSteps);

            toastHandler('step added', TOAST_TYPE_SUCCESS);
        }
    };

    const handleStepHeading = (e, stepIndex) => {
        const newSteps = cloneDeep(steps);
        newSteps[stepIndex].step_heading = e.target.value;
        setSteps(newSteps);
    };

    const handleDeleteStep = (stepIndex) => {
        const newSteps = cloneDeep(steps);
        newSteps.splice(stepIndex, 1);
        setSteps(newSteps);
        toastHandler('step deleted', TOAST_TYPE_SUCCESS);
    };

    const handleAddField = (stepIndex) => {
        const newSteps = cloneDeep(steps);
        if (!newSteps[stepIndex]?.step_heading?.trim()) {
            return toastHandler('Please add step heading first', TOAST_TYPE_ERROR);
        }

        const findIfConditionalOrRecurringALreadyExist = newSteps[stepIndex].fields.find(
            (field) => field.type === 'conditional' || field.type === 'recurring'
        );
        if (findIfConditionalOrRecurringALreadyExist) {
            return toastHandler(
                'You cannot add other fields if Recurring or Conditional field already exists in a step ',
                TOAST_TYPE_ERROR
            );
        }

        newSteps[stepIndex]?.fields?.push({
            type: '',
            label: '',
            form_field_id: uuidv4(),
            tag: '',
            default_value: '',
            isLabelValid: false,
            numOptions: 0,
            options: [],
            hint: '',
            field_row_id: generateId(),
            required: false,
            position: 1,
            placeholder: '',
            conditional_steps: {},
            sub_fields: [],
            field_tag: '',
            skip_steps: {}
        });
        setSteps(newSteps);
    };

    const handleAddSubField = (stepIndex, fieldIndex) => {
        const newSteps = cloneDeep(steps);

        if (!newSteps[stepIndex]?.fields[fieldIndex].recurring_label) {
            return toastHandler('Please add second label', TOAST_TYPE_ERROR);
        }

        newSteps[stepIndex]?.fields[fieldIndex]?.sub_fields.push({
            type: '',
            form_field_id: uuidv4(),
            label: '',
            tag: '',
            default_value: '',
            isLabelValid: false,
            numOptions: 0,
            options: [],
            hint: '',
            field_row_id: generateId(),
            required: false,
            position: newSteps[stepIndex]?.fields[fieldIndex]?.sub_fields.length + 1 || 0,
            placeholder: '',
            skip_steps: {},
            field_tag: ''
        });
        setSteps(newSteps);
    };

    const handleFieldTypeChange = (val, stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);

        if (val.target.value === 'recurring' || val.target.value === 'conditional') {
            const fieldsLength = newSteps[stepIndex].fields.length;
            if (fieldsLength > 1) {
                return toastHandler(
                    ` You cannot add  ${val.target.value.toUpperCase()} field along with other fields. One step can only have ${val.target.value.toUpperCase()} field or other fields!`,
                    TOAST_TYPE_ERROR
                );
            }
        }

        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].type =
                val.target.value === 'terms and conditions'
                    ? 'terms-and-conditions'
                    : val.target.value === 'privacy policy'
                    ? 'privacy-policy'
                    : val.target.value;

            if (val.target.value === LIST) {
                newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].default_value = [''];
            }

            setSteps(newSteps);
        } else {
            if (val.target.value === 'recurring') {
                newSteps[stepIndex].fields[fieldIndex].display_on_new_page = false;
                newSteps[stepIndex].fields[fieldIndex].options = ['', ''];
            }
            newSteps[stepIndex].fields[fieldIndex].type =
                val.target.value === 'terms and conditions'
                    ? 'terms-and-conditions'
                    : val.target.value === 'privacy policy'
                    ? 'privacy-policy'
                    : val.target.value;
            setSteps(newSteps);
        }
    };

    const handleDeleteField = (stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);
        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields.splice(subFieldIndex, 1);
            setSteps(newSteps);
        } else {
            newSteps[stepIndex].fields.splice(fieldIndex, 1);
            setSteps(newSteps);
        }
    };

    const handlePlaceholder = (e, stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        if (isSubField) {
            const newSteps = cloneDeep(steps);
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].placeholder = e.target.value;
            setSteps(newSteps);
        } else {
            const newSteps = cloneDeep(steps);
            newSteps[stepIndex].fields[fieldIndex].placeholder = e.target.value;
            setSteps(newSteps);
        }
    };

    const handleHint = (e, stepIndex, fieldIndex) => {
        const newSteps = cloneDeep(steps);
        newSteps[stepIndex].fields[fieldIndex].hint = e;
        setSteps(newSteps);
    };

    const handleLabel = (e, stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);

        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].label = e.target.value;

            setSteps(newSteps);
        } else {
            newSteps[stepIndex].fields[fieldIndex].label = e.target.value;

            setSteps(newSteps);
        }
    };

    const handleRecuringLabel = (e, stepIndex, fieldIndex) => {
        const newSteps = cloneDeep(steps);
        newSteps[stepIndex].fields[fieldIndex].recurring_label = e.target.value;
        setSteps(newSteps);
    };

    const handleAddStepConditions = (value, stepIndex, fieldIndex) => {
        const newSteps = cloneDeep(steps);
        newSteps[stepIndex].fields[fieldIndex].display_on_new_page = value;
        setSteps(newSteps);
    };

    const handleDefaultValue = (val, stepIndex, fieldIndex) => {
        const copiedSteps = cloneDeep(steps);
        copiedSteps[stepIndex].fields[fieldIndex].default_value = val;
        copiedSteps[stepIndex].fields[fieldIndex].label = 'No Label';
        copiedSteps[stepIndex].fields[fieldIndex].placeholder = 'No Placeholder';
    };

    const hanleRequired = (e, stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);

        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].required = e.target.checked ? true : false;
            setSteps(newSteps);
        } else {
            newSteps[stepIndex].fields[fieldIndex].required = e.target.checked ? true : false;
            setSteps(newSteps);
        }
    };

    const addOption = (stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);
        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].options.push('');
            setSteps(newSteps);
        } else {
            newSteps[stepIndex].fields[fieldIndex].options.push('');
            setSteps(newSteps);
        }
    };

    const addRecuringOption = (stepIndex, fieldIndex) => {
        const newSteps = cloneDeep(steps);
        if (!newSteps[stepIndex].fields[fieldIndex].label) {
            toastHandler('Please add field label first', TOAST_TYPE_ERROR);
            return;
        }

        newSteps[stepIndex].fields[fieldIndex].default_value = [];

        newSteps[stepIndex].fields[fieldIndex].options = ['no', 'yes'];
        setSteps(newSteps);
    };

    const handleRecurringOption = (stepIndex, fieldIndex, option, optionIndex, optionValue) => {
        const newSteps = cloneDeep(steps);

        if (!optionValue) {
            return toastHandler('Value is required', TOAST_TYPE_ERROR);
        }
        if (newSteps[stepIndex].fields[fieldIndex].options[0] === newSteps[stepIndex].fields[fieldIndex].options[1]) {
            return toastHandler('Options cant be same', TOAST_TYPE_ERROR);
        }

        if (!newSteps[stepIndex].fields[fieldIndex].label) {
            return toastHandler('Label is required', TOAST_TYPE_ERROR);
        }
        if (option === 'yes') {
            newSteps[stepIndex].fields[fieldIndex].recurring_option = optionValue;
        } else {
            newSteps[stepIndex].fields[fieldIndex].recurring_option = '';
        }
        setSteps(newSteps);
    };

    const handleOption = (e, stepIndex, fieldIndex, optionIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);
        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].options[optionIndex] = e.target.value;
            setSteps(newSteps);
        } else {
            newSteps[stepIndex].fields[fieldIndex].options[optionIndex] = e.target.value;
            setSteps(newSteps);
        }
    };

    const deleteOption = (stepIndex, fieldIndex, optionIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);
        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].options.splice(optionIndex, 1);
            setSteps(newSteps);
        } else {
            newSteps[stepIndex].fields[fieldIndex].options.splice(optionIndex, 1);
            setSteps(newSteps);
        }
    };

    const handleSwitchOptionOne = (e, stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        if (isSubField) {
            const newSteps = cloneDeep(steps);
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].options[0] = e.target.value;
            setSteps(newSteps);
        } else {
            const newSteps = cloneDeep(steps);
            newSteps[stepIndex].fields[fieldIndex].options[0] = e.target.value;
            setSteps(newSteps);
        }
    };

    const handleSwitchOptionTwo = (e, stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        if (isSubField) {
            const newSteps = cloneDeep(steps);
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].options[1] = e.target.value;
            setSteps(newSteps);
        } else {
            const newSteps = cloneDeep(steps);
            newSteps[stepIndex].fields[fieldIndex].options[1] = e.target.value;
            setSteps(newSteps);
        }
    };

    const handleClearStep = (stepIndex) => {
        const newSteps = cloneDeep(steps);
        newSteps[stepIndex].step_heading = '';
        newSteps[stepIndex].fields.forEach((field) => {
            field.placeholder = '';
            field.label = '';
        });
        setSteps(newSteps);
    };

    const handleValueChange = (value, stepIndex, fieldIndex, subFieldIndex, isSubField) => {
        const newSteps = cloneDeep(steps);
        if (isSubField) {
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].default_value = value.toString();
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].label = 'No Label';
            newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex].placeholder = 'No Placeholder';
        } else {
            newSteps[stepIndex].fields[fieldIndex].default_value = value.toString();
            newSteps[stepIndex].fields[fieldIndex].label = 'No Label';
            newSteps[stepIndex].fields[fieldIndex].placeholder = 'No Placeholder';
        }

        setSteps(newSteps);
    };

    //recursive functions

    const findFieldByRowId = (field_row_id, steps) => {
        for (const step of steps) {
            for (const field of step.fields) {
                if (field.field_row_id === field_row_id) {
                    return field;
                }
                if (field.conditional_steps) {
                    for (const key in field.conditional_steps) {
                        const conditionalStep = field.conditional_steps[key];
                        const foundField = findFieldByRowId(field_row_id, conditionalStep.steps);
                        if (foundField) {
                            return foundField;
                        }
                    }
                }
            }
        }
        return null;
    };

    const findFieldAndStepByRowId = (field_row_id, steps) => {
        for (const step of steps) {
            for (const field of step.fields) {
                if (field.field_row_id === field_row_id) {
                    return { field, step };
                }
                if (field.conditional_steps) {
                    for (const key in field.conditional_steps) {
                        const conditionalStep = field.conditional_steps[key];
                        const found = findFieldAndStepByRowId(field_row_id, conditionalStep.steps);
                        if (found) {
                            return found;
                        }
                    }
                }
            }
        }
        return null;
    };

    const findFieldAndRemoveByRowId = (field_row_id, steps) => {
        for (const step of steps) {
            const fieldIndex = step.fields.findIndex((field) => field.field_row_id === field_row_id);
            if (fieldIndex !== -1) {
                step.fields.splice(fieldIndex, 1);
                return true;
            }
            for (const field of step.fields) {
                if (field.conditional_steps) {
                    for (const key in field.conditional_steps) {
                        const conditionalStep = field.conditional_steps[key];
                        const removed = findFieldAndRemoveByRowId(field_row_id, conditionalStep.steps);
                        if (removed) return true;
                    }
                }
            }
        }
        return false;
    };

    const addConditionalOption = (field_row_id, optionIndex, isDeletion) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            if (isDeletion) {
                field.options.splice(optionIndex, 1);
            } else {
                field.options.push(convertSpacesToUnderscores(`Option ${field.options.length + 1}`));
            }

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const handleConditionalOption = (value, option, optionIndex, stepIndex, field_row_id) => {
        const newSteps = cloneDeep(steps);

        const field = findFieldByRowId(field_row_id, newSteps);

        if (field) {
            field.options[optionIndex] = convertSpacesToUnderscores(value);

            setSteps(newSteps);
        }
    };

    const handleConditionalStep = (value, optionIndex, field_row_id, stepIndex) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);

        if (field) {
            if (value === 'additional-conditon') {
                const option = field.options[optionIndex];
                field.conditional_steps = {
                    ...field.conditional_steps,
                    [convertSpacesToUnderscores(option)]: {
                        display_on_new_page: false,

                        steps: [
                            {
                                step: `${stepIndex}`,
                                step_heading: field?.step_heading || newSteps[stepIndex].step_heading || '',
                                step_id: newSteps[stepIndex]?.step_id ?? '',
                                fields: []
                            }
                        ]
                    }
                };

                setSteps(newSteps);
            } else {
                const option = field.options[optionIndex];

                const optName = convertSpacesToUnderscores(option);
                const { [optName]: _removedProperty, ...rest } = field.conditional_steps;

                field.conditional_steps = rest;
                setSteps(newSteps);
            }
        }
    };

    const handleDisplayInStepsConditional = (value, optionIndex, stepIndex, field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);

        if (field) {
            const option = convertSpacesToUnderscores(field.options[optionIndex]);
            field.conditional_steps = {
                ...field.conditional_steps,
                [option]: {
                    ...field.conditional_steps[option],
                    display_on_new_page: value === 'yes' ? true : false
                }
            };
            setSteps(newSteps);
        }
    };

    const getStepsAfterIndex = (steps, stepIndex) => {
        const stepsAfterIndex = [];
        let isPastIndex = false;

        for (let i = 0; i < steps.length; i++) {
            if (i === stepIndex) {
                isPastIndex = true;
                continue;
            }
            if (isPastIndex) {
                stepsAfterIndex.push(steps[i]);
            }
        }

        return stepsAfterIndex;
    };

    const handleSkipStepDisplay = (value, optionIndex, stepIndex, field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        const stepsAfterstep = getStepsAfterIndex(newSteps, stepIndex);

        let totalIds = [];

        if (stepsAfterstep?.length > 0) {
            stepsAfterstep.forEach((step) => {
                totalIds.push({ step_id: step.step_id, indexOfStep: step.step });
            });
        }

        if (field) {
            const option = convertSpacesToUnderscores(field.options[optionIndex]);

            if (value === 'yes') {
                if (field.conditional_steps[option]) {
                    field.total_steps_indexes = {
                        ...field.total_steps_indexes,
                        [option]: totalIds
                    };
                }
            } else {
                delete field.total_steps_indexes[option];
                delete field.skip_steps[option];
            }
            field.skip_steps_condition = value === 'yes' ? true : false;

            setSteps(newSteps);
        }
    };

    const handleAddFieldInConditional = (optionIndex, stepIndex, field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);

        if (field) {
            const option = field.options[optionIndex];
            const conditionalStepObj = field.conditional_steps[convertSpacesToUnderscores(option)];
            if (conditionalStepObj) {
                conditionalStepObj.steps[0].fields.push({
                    type: '',
                    form_field_id: uuidv4(),
                    label: '',
                    tag: '',
                    default_value: '',
                    isLabelValid: false,
                    numOptions: 0,
                    options: [],
                    hint: '',
                    field_row_id: generateId(),
                    required: false,
                    position: conditionalStepObj?.steps[0]?.fields?.length + 1 || 0,
                    placeholder: '',
                    sub_fields: [],
                    conditional_steps: {},
                    skip_steps: {}
                });

                setSteps(newSteps);
            }
        }
    };

    const handleFieldTypeChangeCondtional = (value, stepIndex, field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);

        if (value === CONDITIONAL) {
            const { field: _field, step } = findFieldAndStepByRowId(field_row_id, steps);
            const findField = step.fields.find((field) => field.type === CONDITIONAL);
            if (findField) {
                return toastHandler(
                    ` You cannot add  ${value.toUpperCase()} field multiple times in child steps!`,
                    TOAST_TYPE_ERROR
                );
            }
        }

        if (field) {
            field.type = value;
            if (value === LIST) {
                field.default_value = [''];
            }
            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const handleLabelConditional = (value, field_row_id, isPlaceholder) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            if (isPlaceholder) {
                field.placeholder = value;
            } else {
                field.label = value;
            }
            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const addOptionConditional = (field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            field.options.push('');

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const handleOptionConditional = (value, field_row_id, optionIndex) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            field.options[optionIndex] = value;

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const deleteOptionConditional = (field_row_id, optionIndex) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            field.options.splice(optionIndex, 1);

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const handleDeleteFieldConditional = (field_row_id) => {
        const newSteps = cloneDeep(steps);
        const removed = findFieldAndRemoveByRowId(field_row_id, newSteps);
        if (removed) {
            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const handleSwitchOptionOneConditional = (value, field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            field.options[0] = value;

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const handleSwitchOptionTwoConditional = (value, field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            field.options[1] = value;

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const hanleRequiredConditional = (checked, field_row_id) => {
        const newSteps = cloneDeep(steps);

        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            field.required = checked;

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const addRemoveSkipStep = (checked, field_row_id, option, stepItem, _step_index) => {
        const newSteps = cloneDeep(steps);

        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            if (checked) {
                field.skip_steps = {
                    ...field.skip_steps,
                    [convertSpacesToUnderscores(option)]: Array.from(
                        new Set([
                            ...(field.skip_steps?.[convertSpacesToUnderscores(option)]
                                ? field.skip_steps?.[convertSpacesToUnderscores(option)]
                                : []),
                            stepItem
                        ])
                    )
                };
            } else {
                const fieldStep = field.skip_steps[convertSpacesToUnderscores(option)];
                const findId = fieldStep?.find((id) => id === stepItem);
                if (findId) {
                    const findIndexOfId = fieldStep.indexOf(findId);
                    fieldStep.splice(findIndexOfId, 1);
                    setSteps(newSteps);
                }
            }

            setSteps(newSteps);
            //
        } else {
            console.error('Field not found');
        }
    };

    const handleSubStepHeadingChange = (value, field_row_id, optionIndex) => {
        const newSteps = cloneDeep(steps);

        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            const option = field.options[optionIndex];
            field.conditional_steps[convertSpacesToUnderscores(option)].steps[0].step_heading = value;

            setSteps(newSteps);
        } else {
            console.error('Field not found');
        }
    };

    const handleAddHintSubField = (value, field_row_id) => {
        const newSteps = cloneDeep(steps);
        const field = findFieldByRowId(field_row_id, newSteps);
        if (field) {
            field.hint = value;
        }

        setSteps(newSteps);
    };

    const handleAddHintSubFieldRecurring = (value, stepIndex, fieldIndex, subFieldIndex) => {
        const newSteps = cloneDeep(steps);

        const field = newSteps[stepIndex].fields[fieldIndex].sub_fields[subFieldIndex];

        if (field) {
            field.hint = value;
        }

        setSteps(newSteps);
    };

    const validateField = (field, stepIndex, fieldIndex, step) => {
        if (!field.label) {
            return `Label is required for field at step ${stepIndex + 1}, field ${fieldIndex + 1}`;
        }

        const isDuplicateLabelFound = step.fields.find(
            (n_field) => n_field.field_row_id !== field.field_row_id && n_field.label === field.label
        );
        if (isDuplicateLabelFound) {
            return 'Dulicate field label found';
        }

        if (
            !field.placeholder &&
            (field.type === 'text' ||
                field.type === 'number' ||
                field.type === 'date' ||
                field.type === 'date-of-birth' ||
                field.type === 'email' ||
                field.type === 'business-email' ||
                field.type === 'tel' ||
                field.type === 'first-name' ||
                field.type === 'middle-name' ||
                field.type === 'last-name' ||
                field.type === 'phone-number' ||
                field.type === 'abn' ||
                field.type === 'tfn' ||
                field.type === 'address')
        ) {
            return `Placeholder is required for field at step ${stepIndex + 1}, field ${fieldIndex + 1}`;
        }

        if (
            (field.type === 'checkbox' || field.type === 'radio' || field.type === 'dropdown') &&
            (!field.options || field.options.length === 0)
        ) {
            return `At least one option is required for field at step ${stepIndex + 1}, field ${fieldIndex + 1}`;
        }

        if (
            (field.type === 'checkbox' || field.type === 'radio' || field.type === 'dropdown') &&
            field.options.length > 1
        ) {
            const uniqueOptions = new Set();
            for (const option of field.options) {
                if (uniqueOptions.has(option)) {
                    return `Duplicate options found for field at step ${stepIndex + 1}, field ${fieldIndex + 1}`;
                }
                uniqueOptions.add(option);
            }
        }

        if (field.type === 'conditional') {
            if (!field.label) {
                return `Label is required for conditional field at step ${Number(step.step) + 1}`;
            }

            if (field.options.length <= 1) {
                return `Conditional field at step ${Number(step.step) + 1} must have more than one option`;
            }

            const uniqueOptions = new Set();
            for (const option of field.options) {
                if (uniqueOptions.has(option)) {
                    return `Duplicate options found for conditional field at step ${Number(step.step) + 1}`;
                }
                uniqueOptions.add(option);
            }

            if (field.conditional_steps) {
                for (const option in field.conditional_steps) {
                    if (option.replace(/ /g, '_') in field.conditional_steps) {
                        const conditionalSteps = field.conditional_steps[option].steps;
                        const errorMessage = validateSteps(conditionalSteps);
                        if (errorMessage) {
                            return errorMessage;
                        }
                    }
                }
            }
        }

        return null;
    };

    const validateSteps = (steps) => {
        for (let i = 0; i < steps.length; i++) {
            const step = steps[i];

            const isDuplicateFound = steps.find(
                (n_stp) => n_stp.step_id !== step.step_id && n_stp.step_heading === step.step_heading
            );
            if (isDuplicateFound) {
                return 'Dulicate step heading found';
            }
            for (let j = 0; j < step.fields.length; j++) {
                const field = step.fields[j];

                const errorMessage = validateField(field, i, j, step);
                if (errorMessage) {
                    return errorMessage;
                }
            }
        }
        return null;
    };

    const processSteps = (steps) => {
        return steps.map((step) => {
            const stepTag = sanitizeString(step?.step_heading);
            step.step_tag = stepTag;

            step.fields = step.fields.map((field) => {
                field.field_tag = `${stepTag}_${sanitizeString(field?.label)}`;

                if (field.type === 'recurring' && Array.isArray(field.default_value)) {
                    field.sub_fields = field.sub_fields.map((subField) => {
                        subField.field_tag = `${stepTag}_${sanitizeString(subField?.label)}`;
                        return subField;
                    });
                    field.default_value = field.default_value.map((subFieldsArray) => {
                        return subFieldsArray.map((subField) => {
                            subField.field_tag = `${stepTag}_${sanitizeString(subField?.label || '')}`;
                            return subField;
                        });
                    });
                }

                if (field.type === 'conditional' && field.conditional_steps) {
                    Object.keys(field.conditional_steps).forEach((optionKey) => {
                        const conditionalStep = field.conditional_steps[optionKey];

                        const stepHead = field?.step_heading || '';

                        const newSteps = cloneDeep(conditionalStep?.steps);
                        newSteps?.forEach((st) => {
                            st.step_heading = !conditionalStep?.display_on_new_page ? stepHead : st?.step_heading;
                            st?.fields?.forEach((f) => {
                                f.step_heading = !conditionalStep?.display_on_new_page ? stepHead : st?.step_heading;
                            });
                        });
                        conditionalStep.steps = processSteps(newSteps);
                    });
                }

                return field;
            });

            return step;
        });
    };

    //end recursive functions
    const handleSubmit = (e, type, applyValidations) => {
        e.preventDefault();

        const newSteps = cloneDeep(steps);
        const proccesed = processSteps(newSteps);

        if (!form?.form_heading) {
            toastHandler('Form name is required', 'error');
            return;
        }
        if (proccesed.length < 1) {
            toastHandler('Atleast one step is required', TOAST_TYPE_ERROR);
            return;
        }

        let isValid = true;

        if (applyValidations) {
            const errorMessage = validateSteps(proccesed, 0);
            if (errorMessage) {
                toastHandler(errorMessage, 'error');

                isValid = false;
                return false;
            }

            proccesed.map((step, index) => {
                if (!step?.step_heading?.trim()) {
                    isValid = false;

                    toastHandler(`Step ${index + 1} heading is required`, TOAST_TYPE_ERROR);
                    return;
                }
                if (step?.fields?.length < 1) {
                    isValid = false;
                    toastHandler('Each step must have atleast one field', TOAST_TYPE_ERROR);
                    return;
                }

                step.fields.map((field) => {
                    if (field.type === 'recurring') {
                        if (field.options[0] === field.options[1]) {
                            isValid = false;
                            toastHandler('Options cant be same in recurring field', TOAST_TYPE_ERROR);
                            return;
                        }

                        if (field.sub_fields.length < 1) {
                            isValid = false;
                            toastHandler('Sub fields are required for recurring field', TOAST_TYPE_ERROR);
                            return;
                        }
                        field.sub_fields.map((field, fieldIndex) => {
                            if (field?.type === 'switch') {
                                if (field?.options.length < 1) {
                                    isValid = false;
                                    toastHandler('Switch button must have 2 options', TOAST_TYPE_ERROR);
                                    return;
                                }
                            }

                            if (
                                field.type === 'dropdown' ||
                                field.type === 'radio' ||
                                field.type === 'checkbox' ||
                                field.type === 'switch'
                            ) {
                                if (field?.options?.length < 1) {
                                    isValid = false;
                                    toastHandler('Options are required', TOAST_TYPE_ERROR);
                                    return;
                                }
                                if (hasAtLeastTwoSameValues(field?.options)) {
                                    isValid = false;
                                    toastHandler(
                                        `Two options can't be same at step ${index + 1} field ${fieldIndex + 1}`,
                                        TOAST_TYPE_ERROR
                                    );
                                    return;
                                }

                                field.options.map((op) => {
                                    if (!op.trim()) {
                                        toastHandler("options can't be empty", TOAST_TYPE_ERROR);
                                        isValid = false;
                                        return;
                                    }
                                });
                            }
                            if (
                                field.type === 'dropdown' ||
                                field.type === 'radio' ||
                                field.type === 'checkbox' ||
                                field.type === 'image' ||
                                field.type === 'document' ||
                                field.type === 'address' ||
                                field.type === 'switch' ||
                                field.type === 'signature' ||
                                field.type === 'recurring' ||
                                field.type === SALARY_WAGES_OCCUPATION ||
                                field.type === BUSINESS_INDUSTRY_OCCUPATION
                            ) {
                                if (!field?.label?.trim()) {
                                    toastHandler(
                                        `label can't be empty at step ${index + 1} field ${fieldIndex + 1}`,
                                        TOAST_TYPE_ERROR
                                    );
                                    isValid = false;
                                    return;
                                }
                            } else {
                                if (!field?.label?.trim() || !field.placeholder.trim()) {
                                    isValid = false;
                                    toastHandler(
                                        `label or placeholder can't be empty at step ${index + 1} field ${
                                            fieldIndex + 1
                                        }`,
                                        TOAST_TYPE_ERROR
                                    );
                                    return;
                                }
                            }
                        });
                    }
                });
            });
            if (!isValid) {
                console.error('erorr in gpt function');
                return;
            }
        }

        proccesed.map((step, index) => {
            step.step = index.toString();

            step.fields.forEach((field, fieldIndex) => {
                const fieldPosition = fieldIndex + 1;
                if (field?.type === RECURRING) {
                    field?.sub_fields.forEach((item) => {
                        if (item?.type === LIST) {
                            field.default_value = [''];
                        }
                    });
                }

                field.tag = 'tag';
                field.default_value =
                    field.type === 'switch' || field.type === 'radio'
                        ? field.options[0]
                        : field?.type === 'checkbox'
                        ? [field?.options[0]]
                        : field?.type === 'terms-and-conditions'
                        ? field?.default_value
                        : field?.type === 'privacy-policy'
                        ? field?.default_value
                        : field?.type === 'recurring'
                        ? [field.sub_fields]
                        : field?.type === LIST
                        ? ['']
                        : '';
                field.recurring_switch =
                    field?.recurring_option === field?.options[0] ? field?.options[1] : field?.options[0];
                field.position = `${fieldPosition}`;
            });
        });

        const formData = {
            form_id: PreviousFormId ? PreviousFormId : '',
            service_id: '',
            form_name: form?.form_heading,
            form_status: type,
            steps: proccesed
        };

        dispatch(setLoader(true));
        dispatch(PreviousFormId ? updateBusniessFormThunk(formData) : postDynamicFormThunk(formData))
            .then((response) => {
                if (response.payload) {
                    fetchForms(condition);
                    if (applyValidations) {
                        setOpen(false);
                        forceReloadTables();
                        sessionStorage.removeItem('form_id');
                    } else {
                        sessionStorage.setItem('form_id', response?.payload?.form_id);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handlePreview = () => {
        const newSteps = cloneDeep(steps);
        if (newSteps.length < 1) {
            return;
        }
        newSteps.map((step, index) => {
            step.step = index.toString();

            step.fields.forEach((field) => {
                field.default_value =
                    field.type === 'switch' || field.type === 'radio'
                        ? field.options[0]
                        : field.type === 'recurring'
                        ? [field.sub_fields]
                        : field.type === LIST
                        ? ['']
                        : '';
                field.recurring_switch =
                    field?.recurring_option === field?.options[0] ? field?.options[1] : field?.options[0];
                field.step = newSteps.indexOf(step).toString();
            });
        });

        const formData = {
            service_id: '',
            form_name: form?.form_heading,
            form_status: '',
            steps: newSteps
        };

        if (formData.form_name === '') {
            return toastHandler('Please fill in all fields', TOAST_TYPE_ERROR);
        }

        dispatch(formHandler(formData));
        setPreviewForm(!previewForm);
        // togglePreviewForm();
    };

    const clearConditionalKeys = (steps) => {
        const processFields = (fields) => {
            fields.forEach((field) => {
                if (field.type === 'conditional') {
                    field.skip_steps = {};
                    field.total_steps_indexes = {};
                    field.skip_steps_condition = false;

                    if (field.conditional_steps) {
                        Object.keys(field.conditional_steps).forEach((optionKey) => {
                            const conditionalStep = field.conditional_steps[optionKey];
                            conditionalStep.steps = processSteps(conditionalStep.steps);
                        });
                    }
                }

                if (field.type === 'recurring' && Array.isArray(field.default_value)) {
                    field.default_value.forEach((subFieldsArray) => {
                        processFields(subFieldsArray);
                    });
                }
            });
        };

        const processSteps = (steps) => {
            steps.forEach((step) => {
                processFields(step.fields);
            });
            return steps;
        };

        return processSteps(steps);
    };

    const onSortEnd = (oldIndex, newIndex) => {
        const newArray = arrayMoveImmutable(steps, oldIndex, newIndex);
        const processedSteps = clearConditionalKeys(newArray);
        setSteps(processedSteps);

        toastHandler(
            'We have reset your skip step options.You can click on Skip Steps again to skip steps ',
            TOAST_TYPE_SUCCESS
        );
    };

    const updateFields = (step, parentId, stid) => {
        const newStepId = generateId();
        delete step.is_skipped;
        delete step.step_condition;
        delete step.step_index;
        step.step_id = stid ? stid : newStepId;

        step.fields = step.fields.map((field) => {
            const customId = uuidv4();
            field.field_row_id = generateId();

            field.form_field_id = customId;
            if (parentId) {
                field.parent_field_id = parentId;
            }
            field.step_id = step.step_id;
            field.isLabelValid = false;
            field.numOptions = field?.options?.length || 0;
            field.step_heading = step.step_heading;

            if (field.type !== 'recurring') {
                delete field.recurring_label;
                delete field.recurring_option;
            }

            if (field.type !== 'conditional') {
                delete field.skip_steps_condition;
                delete field.total_steps_indexes;
                delete field.skip_steps;
            }

            delete field.created_at;
            delete field.updated_at;
            delete field.step_index;
            delete field.is_skipped;
            delete field.step;
            delete field.is_valid;

            if (field.type === 'recurring' && Array.isArray(field.default_value)) {
                field.default_value = field.default_value.map((subFieldsArray) => {
                    return subFieldsArray.map((subField) => {
                        subField.field_row_id = generateId();
                        subField.form_field_id = uuidv4();
                        subField.numOptions = field?.options?.length || 0;

                        subField.isLabelValid = false;
                        delete subField.created_at;
                        delete subField.updated_at;
                        delete subField.step_tag;
                        delete subField.step_id;
                        delete subField.step_index;
                        delete subField.option_key;
                        delete subField.is_valid;
                        subField.step_id = step.step_id;
                        return subField;
                    });
                });
            }

            if (field.type === 'conditional' && field.conditional_steps) {
                Object.keys(field.conditional_steps).forEach((optionKey) => {
                    const conditionalStep = field.conditional_steps[optionKey];

                    conditionalStep.steps = conditionalStep.steps.map((nestedStep) => {
                        return updateFields(nestedStep, customId, newStepId);
                    });
                });
            }

            return field;
        });

        return step;
    };

    const duplicateStep = (position, id, customIndex) => {
        const newSteps = [...steps];
        const step = newSteps?.find((step) => step?.step_id === id);
        const clonedStep = cloneDeep(step);

        const findStep = updateFields(clonedStep);
        if (position === 'top') {
            const newStep = cloneDeep(findStep);
            newStep.step = '0';
            newSteps?.unshift(newStep);
            const processedSteps = clearConditionalKeys(newSteps);
            setSteps(processedSteps);

            toastHandler(
                'We have reset your skip step options.You can click on Skip Steps again to skip steps ',
                TOAST_TYPE_SUCCESS
            );

            toastHandler(
                'Duplicate step added. Please remember to change step heading of new step',
                TOAST_TYPE_SUCCESS
            );
        } else if (position === 'bottom') {
            const newStep = cloneDeep(findStep);
            newStep.step = `${steps?.length + 1}`;

            newSteps?.push(newStep);

            setSteps(newSteps);
            toastHandler(
                'Duplicate step added. Please remember to change step heading of new step',
                TOAST_TYPE_SUCCESS
            );
        } else if (position === 'custom') {
            const newStep = cloneDeep(findStep);
            newStep.step = `${customIndex}`;

            newSteps.splice(customIndex, 0, newStep);

            const processedSteps = clearConditionalKeys(newSteps);
            setSteps(processedSteps);

            toastHandler(
                'We have reset your skip step options.You can click on Skip Steps again to skip steps ',
                TOAST_TYPE_SUCCESS
            );

            toastHandler(
                'Duplicate step added. Please remember to change step heading of new step',
                TOAST_TYPE_SUCCESS
            );
        }
    };

    const handleCalculateStepIndex = (id) => {
        if (steps?.length > 0) {
            const findStep = steps?.find((item) => item?.step_id === id);
            if (findStep) {
                const findIndex = steps?.indexOf(findStep);
                if (findIndex) {
                    return parseInt(findIndex + 1);
                }
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };
    return (
        <div className="add-p-side grid grid-cols-5 ">
            {previewForm && (
                <PreviewForm
                    togglePreviewForm={() => setPreviewForm(!previewForm)}
                    toggleCreateForm={() => setPreviewForm(!previewForm)}
                    directory={''}
                    toggleTerms={() => false}
                />
            )}
            <div className="col-span-1 left-side" onClick={() => setOpen(false)}></div>

            <div className="right-side md:col-span-4 col-span-5">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    <div>
                        {isLoading && <Loader />}
                        <div>
                            <div
                                onClick={() => setOpen(false)}
                                className="absolute z-50 text-white p-2 right-1 top-1 cursor-pointer"
                            >
                                <SideTimes />
                            </div>
                            <div className="add-detail pt-10 ml-16 mr-5 relative">
                                <div className="flex absolute right-5 gap-2 top-16 ">
                                    <Button
                                        atClick={handlePreview}
                                        iconSet={{ leftIconHTML: <EyeIcon className="text-white w-5 h-5" /> }}
                                        title="Preview"
                                        classes="  preview-indicate flex gap-2 items-center bg-linear h-[2.5rem] text-white buttons-font-lighter font-fira rounded-xl px-4 "
                                    />
                                </div>

                                <div className="text-[28px] font-fira font-[600]  flex gap-4 items-center">
                                    <div onClick={() => setOpen(false)} className="cursor-pointer">
                                        <RedirectArrow />
                                    </div>
                                    <H3>Create Forms</H3>
                                </div>
                            </div>
                        </div>

                        <RoleBasedGuard module={'Forms'} permissions={['admin']} sx="h-[50vh]">
                            {' '}
                            <div className="add-detail  mx-10 mt-10">
                                <div className="default-forms my-3 ">
                                    <form onSubmit={(e) => handleSubmit(e, 'live', true)}>
                                        {' '}
                                        <div>
                                            <div className=" gap-3 place-content-center ">
                                                <div>
                                                    <div>
                                                        <label>Form Name</label>
                                                    </div>
                                                    <div className=" mt-2">
                                                        {' '}
                                                        <div className="flex gap-2 items-center">
                                                            <div className="heading-indicate">
                                                                {' '}
                                                                <input
                                                                    onChange={handleFormName}
                                                                    value={form?.form_heading}
                                                                    type="text"
                                                                    name=""
                                                                    placeholder={'Enter form name'}
                                                                    required
                                                                    className="!w-[500px]"
                                                                />
                                                            </div>
                                                            <div
                                                                onClick={handleAddStep}
                                                                className="add-step-indicate ann-btn rounded-xl cursor-pointer buttons-font-lighter flex justify-center items-center px-7 "
                                                            >
                                                                {' '}
                                                                + Add Step{' '}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <SortableList
                                            onSortEnd={onSortEnd}
                                            className="list "
                                            draggedItemClassName="dragged draging-class"
                                        >
                                            {steps?.map((step, stepIndex) => (
                                                <SortableItem
                                                    key={'stortable_form_steps' + stepIndex}
                                                    className="dragged "
                                                >
                                                    <motion.div
                                                        key={step?.step_id}
                                                        initial={{ x: -100, opacity: 0 }}
                                                        animate={{ x: 0, opacity: 1 }}
                                                        exit={{ x: 100, opacity: 0 }}
                                                        transition={{ duration: 0.5, ease: 'easeInOut' }}
                                                        className="border mt-4  rounded-xl"
                                                    >
                                                        <GenerateStep
                                                            steps={steps}
                                                            setSteps={setSteps}
                                                            step={step}
                                                            stepIndex={stepIndex}
                                                            handleStepHeading={handleStepHeading}
                                                            handleDeleteStep={handleDeleteStep}
                                                            handleAddField={handleAddField}
                                                            handleAddSubField={handleAddSubField}
                                                            handleClearStep={handleClearStep}
                                                            showModal={showModal}
                                                            modalIndex={modalIndex}
                                                            toggleModal={toggleModal}
                                                            handleFieldTypeChange={handleFieldTypeChange}
                                                            handleDefaultValue={handleDefaultValue}
                                                            handleLabel={handleLabel}
                                                            handleRecuringLabel={handleRecuringLabel}
                                                            addOption={addOption}
                                                            handleRecurringOption={handleRecurringOption}
                                                            handlePlaceholder={handlePlaceholder}
                                                            handleSwitchOptionOne={handleSwitchOptionOne}
                                                            handleSwitchOptionTwo={handleSwitchOptionTwo}
                                                            handleDeleteField={handleDeleteField}
                                                            handleOption={handleOption}
                                                            deleteOption={deleteOption}
                                                            handleHint={handleHint}
                                                            hanleRequired={hanleRequired}
                                                            handleSubmit={handleSubmit}
                                                            modalStep={modalStep}
                                                            handleValueChange={handleValueChange}
                                                            addRecuringOption={addRecuringOption}
                                                            handleAddStepConditions={handleAddStepConditions}
                                                            addConditionalOption={addConditionalOption}
                                                            handleConditionalOption={handleConditionalOption}
                                                            handleConditionalStep={handleConditionalStep}
                                                            handleDisplayInStepsConditional={
                                                                handleDisplayInStepsConditional
                                                            }
                                                            handleSkipStepDisplay={handleSkipStepDisplay}
                                                            handleAddFieldInConditional={handleAddFieldInConditional}
                                                            handleFieldTypeChangeCondtional={
                                                                handleFieldTypeChangeCondtional
                                                            }
                                                            handleLabelConditional={handleLabelConditional}
                                                            addOptionConditional={addOptionConditional}
                                                            handleOptionConditional={handleOptionConditional}
                                                            deleteOptionConditional={deleteOptionConditional}
                                                            handleDeleteFieldConditional={handleDeleteFieldConditional}
                                                            handleSwitchOptionOneConditional={
                                                                handleSwitchOptionOneConditional
                                                            }
                                                            handleSwitchOptionTwoConditional={
                                                                handleSwitchOptionTwoConditional
                                                            }
                                                            hanleRequiredConditional={hanleRequiredConditional}
                                                            addRemoveSkipStep={addRemoveSkipStep}
                                                            handleSubStepHeadingChange={handleSubStepHeadingChange}
                                                            handleAddHintSubField={handleAddHintSubField}
                                                            handleAddHintSubFieldRecurring={
                                                                handleAddHintSubFieldRecurring
                                                            }
                                                            duplicateStep={duplicateStep}
                                                            handleCalculateStepIndex={handleCalculateStepIndex}
                                                        />
                                                    </motion.div>
                                                </SortableItem>
                                            ))}
                                        </SortableList>
                                        <div className="flex items-center gap-3 mt-4 mb-5 justify-center flex-wrap">
                                            <button
                                                className="bg-linear text-white h-[2.5rem] font-fira buttons-font-lighter px-7 rounded-lg"
                                                type="submit"
                                            >
                                                Save as active
                                            </button>
                                            <button
                                                onClick={(e) => handleSubmit(e, 'draft', true)}
                                                type="button"
                                                className="border-blueish border h-[2.5rem]  px-8 rounded-lg buttons-font-lighter font-fira"
                                            >
                                                Save as Draft
                                            </button>
                                            <button
                                                className=" border text-secondarybg border-secondarybg h-[2.5rem] px-12 rounded-lg buttons-font-lighter font-fira"
                                                onClick={() => setOpen(false)}
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </RoleBasedGuard>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};
