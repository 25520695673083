import React from 'react';

export const QrCodeMobile = () => {
    return (
        <div>
            <svg width="120" height="120" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.05859 5.05859L4.375 5.71484V15.3125V24.9102L5.05859 25.5664L5.71484 26.25H15.3125H24.9102L25.5664 25.5664L26.25 24.9102V15.3125V5.71484L25.5664 5.05859L24.9102 4.375H15.3125H5.71484L5.05859 5.05859ZM21.875 15.3125V21.875H15.3125H8.75V15.3125V8.75H15.3125H21.875V15.3125Z"
                    fill="url(#paint0_linear_5824_4080)"
                />
                <path
                    d="M13.8095 13.8086C12.4423 15.1484 13.3993 17.5 15.3134 17.5C16.4345 17.5 17.5009 16.4336 17.5009 15.3125C17.5009 14.1914 16.4345 13.125 15.3134 13.125C14.7938 13.125 14.2196 13.3711 13.8095 13.8086Z"
                    fill="url(#paint1_linear_5824_4080)"
                />
                <path
                    d="M31.3095 5.05859C29.9423 6.39844 30.8993 8.75 32.8134 8.75C33.9345 8.75 35.0009 7.68359 35.0009 6.5625C35.0009 5.44141 33.9345 4.375 32.8134 4.375C32.2938 4.375 31.7196 4.62109 31.3095 5.05859Z"
                    fill="url(#paint2_linear_5824_4080)"
                />
                <path
                    d="M40.0586 5.05859C39.6211 5.46875 39.375 6.04297 39.375 6.5625C39.375 7.95703 40.4141 8.75 42.2461 8.75H43.75V14.6289C43.75 20.5352 43.75 20.5352 44.4336 21.1914C44.8438 21.6289 45.418 21.875 45.9375 21.875C46.457 21.875 47.0312 21.6289 47.4414 21.1914L48.125 20.5352V13.125V5.71484L47.4414 5.05859C46.8125 4.40234 46.5938 4.375 43.75 4.375C40.9062 4.375 40.6875 4.40234 40.0586 5.05859Z"
                    fill="url(#paint3_linear_5824_4080)"
                />
                <path
                    d="M53.1836 5.05859L52.5 5.71484V15.3125V24.9102L53.1836 25.5664C53.8125 26.2227 54.0312 26.25 56.875 26.25C59.7188 26.25 59.9375 26.2227 60.5664 25.5664C61.4688 24.6914 61.4688 23.4336 60.5664 22.5586C60.0195 21.9844 59.6367 21.875 58.3789 21.875H56.875V15.3125V8.75H63.4375H70V10.2539C70 11.5117 70.1094 11.8945 70.6836 12.4414C71.5586 13.3438 72.8164 13.3438 73.6914 12.4414C74.3477 11.8125 74.375 11.5938 74.375 8.75C74.375 5.90625 74.3477 5.6875 73.6914 5.05859L73.0352 4.375H63.4375H53.8398L53.1836 5.05859Z"
                    fill="url(#paint4_linear_5824_4080)"
                />
                <path
                    d="M31.3086 13.8086C30.8711 14.2188 30.625 14.793 30.625 15.3125C30.625 15.832 30.8711 16.4062 31.3086 16.8164C31.9375 17.4727 32.1562 17.5 35 17.5C37.8438 17.5 38.0625 17.4727 38.6914 16.8164C39.1289 16.4062 39.375 15.832 39.375 15.3125C39.375 14.793 39.1289 14.2188 38.6914 13.8086C38.0625 13.1523 37.8438 13.125 35 13.125C32.1562 13.125 31.9375 13.1523 31.3086 13.8086Z"
                    fill="url(#paint5_linear_5824_4080)"
                />
                <path
                    d="M61.9345 13.8086C60.5673 15.1484 61.5243 17.5 63.4384 17.5C64.5595 17.5 65.6259 16.4336 65.6259 15.3125C65.6259 14.1914 64.5595 13.125 63.4384 13.125C62.9188 13.125 62.3446 13.3711 61.9345 13.8086Z"
                    fill="url(#paint6_linear_5824_4080)"
                />
                <path
                    d="M73.9922 17.8008C72.2969 18.2109 69.8086 19.6602 68.6055 20.9727C68.0039 21.6289 67.1016 22.9961 66.6367 24.0078L65.7617 25.8398V76.5898V127.312L66.7734 129.391C68.0039 131.879 69.5898 133.41 72.1328 134.613L73.9648 135.488H100.625H127.285L129.117 134.613C131.66 133.41 133.246 131.879 134.477 129.391L135.488 127.312V76.5625V25.8125L134.477 23.7344C133.246 21.2461 131.66 19.7148 129.117 18.5117L127.285 17.6367L101.172 17.582C85.9688 17.5547 74.6211 17.6641 73.9922 17.8008ZM126.711 22.2031C128.215 22.6406 130.484 24.9102 130.922 26.4141C131.414 28.0547 131.414 125.07 130.922 126.711C130.484 128.215 128.215 130.484 126.711 130.922C125.098 131.387 76.1523 131.387 74.5391 130.922C73.0352 130.484 70.7656 128.215 70.3281 126.711C69.8359 125.07 69.8359 28.0547 70.3281 26.4141C70.7383 25.0195 73.0352 22.668 74.375 22.2305C75.8242 21.793 125.18 21.7656 126.711 22.2031Z"
                    fill="url(#paint7_linear_5824_4080)"
                />
                <path
                    d="M88.1836 26.9336C87.7461 27.3438 87.5 27.918 87.5 28.4375C87.5 28.957 87.7461 29.5312 88.1836 29.9414L88.8398 30.625H100.625H112.41L113.066 29.9414C113.504 29.5312 113.75 28.957 113.75 28.4375C113.75 27.918 113.504 27.3438 113.066 26.9336L112.41 26.25H100.625H88.8398L88.1836 26.9336Z"
                    fill="url(#paint8_linear_5824_4080)"
                />
                <path
                    d="M96.9336 123.184C96.4961 123.594 96.25 124.168 96.25 124.688C96.25 125.207 96.4961 125.781 96.9336 126.191C97.5625 126.848 97.7812 126.875 100.625 126.875C103.469 126.875 103.688 126.848 104.316 126.191C104.754 125.781 105 125.207 105 124.688C105 124.168 104.754 123.594 104.316 123.184C103.688 122.527 103.469 122.5 100.625 122.5C97.7812 122.5 97.5625 122.527 96.9336 123.184Z"
                    fill="url(#paint9_linear_5824_4080)"
                />
                <path
                    d="M31.3086 22.5586C30.8711 22.9688 30.625 23.543 30.625 24.0625C30.625 25.457 31.6641 26.25 33.4961 26.25H35V32.1289C35 38.0352 35 38.0352 35.6836 38.6914C36.0938 39.1289 36.668 39.375 37.1875 39.375C37.707 39.375 38.2812 39.1289 38.6914 38.6914C39.3477 38.0625 39.375 37.8984 39.375 34.3164V30.625H43.0664C46.6484 30.625 46.8125 30.5977 47.4414 29.9414C48.3438 29.0664 48.3438 27.8086 47.4414 26.9336C46.8125 26.2773 46.6484 26.25 43.0664 26.25H39.375V24.7461C39.375 23.4883 39.2656 23.1055 38.6914 22.5586C38.0625 21.9023 37.8438 21.875 35 21.875C32.1562 21.875 31.9375 21.9023 31.3086 22.5586Z"
                    fill="url(#paint10_linear_5824_4080)"
                />
                <path
                    d="M5.05859 31.3086C4.62109 31.7188 4.375 32.293 4.375 32.8125C4.375 33.332 4.62109 33.9062 5.05859 34.3164C5.6875 34.9727 5.90625 35 8.75 35C11.5938 35 11.8125 34.9727 12.4414 34.3164C13.3438 33.4414 13.3438 32.1836 12.4414 31.3086C11.8125 30.6523 11.5938 30.625 8.75 30.625C5.90625 30.625 5.6875 30.6523 5.05859 31.3086Z"
                    fill="url(#paint11_linear_5824_4080)"
                />
                <path
                    d="M18.1845 31.3086C16.8173 32.6484 17.7743 35 19.6884 35C20.8095 35 21.8759 33.9336 21.8759 32.8125C21.8759 31.6914 20.8095 30.625 19.6884 30.625C19.1688 30.625 18.5946 30.8711 18.1845 31.3086Z"
                    fill="url(#paint12_linear_5824_4080)"
                />
                <path
                    d="M26.9336 31.3086C26.3594 31.8555 26.25 32.2383 26.25 33.4961V35H24.7461C23.4883 35 23.1055 35.1094 22.5586 35.6836C21.9023 36.3125 21.875 36.4766 21.875 40.0586V43.75H20.3711C18.5391 43.75 17.5 44.543 17.5 45.9375C17.5 46.457 17.7461 47.0312 18.1836 47.4414C18.8398 48.125 18.8398 48.125 24.7461 48.125H30.625V49.6289C30.625 51.4609 31.418 52.5 32.8125 52.5C33.332 52.5 33.9062 52.2539 34.3164 51.8164C34.9727 51.1875 35 50.9688 35 48.125C35 45.2812 34.9727 45.0625 34.3164 44.4336C33.6875 43.7773 33.5234 43.75 29.9414 43.75H26.25V41.5625V39.375H27.7539C29.0117 39.375 29.3945 39.2656 29.9414 38.6914C30.5977 38.0625 30.625 37.8438 30.625 35C30.625 32.1562 30.5977 31.9375 29.9414 31.3086C29.5312 30.8711 28.957 30.625 28.4375 30.625C27.918 30.625 27.3438 30.8711 26.9336 31.3086Z"
                    fill="url(#paint13_linear_5824_4080)"
                />
                <path
                    d="M48.8096 31.3086C48.2354 31.8555 48.126 32.2383 48.126 33.4961V35H46.6221C45.3643 35 44.9814 35.1094 44.4346 35.6836C43.5322 36.5586 43.5322 37.8164 44.4346 38.6914C45.0635 39.3477 45.2275 39.375 48.8096 39.375H52.501V40.8789C52.501 42.7109 53.2939 43.75 54.6885 43.75C56.083 43.75 56.876 42.7109 56.876 40.8789V39.375H58.3799C59.6377 39.375 60.0205 39.2656 60.5674 38.6914C61.2236 38.0625 61.251 37.8438 61.251 35C61.251 32.1562 61.2236 31.9375 60.5674 31.3086C60.1572 30.8711 59.583 30.625 59.0635 30.625C57.6689 30.625 56.876 31.6641 56.876 33.4961V35H54.6885H52.501V33.4961C52.501 31.6641 51.708 30.625 50.3135 30.625C49.7939 30.625 49.2197 30.8711 48.8096 31.3086Z"
                    fill="url(#paint14_linear_5824_4080)"
                />
                <path
                    d="M13.8095 35.6836C12.4423 37.0234 13.3993 39.375 15.3134 39.375C16.4345 39.375 17.5009 38.3086 17.5009 37.1875C17.5009 36.0664 16.4345 35 15.3134 35C14.7938 35 14.2196 35.2461 13.8095 35.6836Z"
                    fill="url(#paint15_linear_5824_4080)"
                />
                <path
                    d="M5.05859 40.0586C4.40234 40.6875 4.375 40.9062 4.375 43.75C4.375 46.5938 4.40234 46.8125 5.05859 47.4414C5.6875 48.0977 5.90625 48.125 8.75 48.125C11.5938 48.125 11.8125 48.0977 12.4414 47.4414C13.3438 46.5664 13.3438 45.3086 12.4414 44.4336C11.8945 43.8594 11.5117 43.75 10.2539 43.75H8.75V42.2461C8.75 40.4141 7.95703 39.375 6.5625 39.375C6.04297 39.375 5.46875 39.6211 5.05859 40.0586Z"
                    fill="url(#paint16_linear_5824_4080)"
                />
                <path
                    d="M40.0586 40.0586C39.375 40.7148 39.375 40.7695 39.375 45.9375C39.375 51.1055 39.375 51.1602 40.0586 51.8164C40.4688 52.2539 41.043 52.5 41.5625 52.5C42.957 52.5 43.75 51.4609 43.75 49.6289V48.125H45.2539C47.0859 48.125 48.125 47.332 48.125 45.9375C48.125 44.543 47.0859 43.75 45.2539 43.75H43.75V42.2461C43.75 40.4141 42.957 39.375 41.5625 39.375C41.043 39.375 40.4688 39.6211 40.0586 40.0586Z"
                    fill="url(#paint17_linear_5824_4080)"
                />
                <path
                    d="M57.5586 44.4336C56.9844 44.9805 56.875 45.3633 56.875 46.6211V48.125H53.1836C49.6016 48.125 49.4375 48.1523 48.8086 48.8086C48.3711 49.2188 48.125 49.793 48.125 50.3125C48.125 50.832 48.3711 51.4062 48.8086 51.8164C49.4375 52.4727 49.6016 52.5 53.1836 52.5H56.875V54.0039C56.875 55.8359 57.668 56.875 59.0625 56.875C59.582 56.875 60.1562 56.6289 60.5664 56.1914C61.25 55.5352 61.25 55.4805 61.25 50.3125C61.25 45.1445 61.25 45.0898 60.5664 44.4336C60.1562 43.9961 59.582 43.75 59.0625 43.75C58.543 43.75 57.9688 43.9961 57.5586 44.4336Z"
                    fill="url(#paint18_linear_5824_4080)"
                />
                <path
                    d="M5.05859 53.1836L4.375 53.8398V63.4375V73.0352L5.05859 73.6914L5.71484 74.375H15.3125H24.9102L25.5664 73.6914L26.25 73.0352V63.4375V53.8398L25.5664 53.1836L24.9102 52.5H15.3125H5.71484L5.05859 53.1836ZM21.875 63.4375V70H15.3125H8.75V63.4375V56.875H15.3125H21.875V63.4375Z"
                    fill="url(#paint19_linear_5824_4080)"
                />
                <path
                    d="M13.8095 61.9336C12.4423 63.2734 13.3993 65.625 15.3134 65.625C16.4345 65.625 17.5009 64.5586 17.5009 63.4375C17.5009 62.3164 16.4345 61.25 15.3134 61.25C14.7938 61.25 14.2196 61.4961 13.8095 61.9336Z"
                    fill="url(#paint20_linear_5824_4080)"
                />
                <path
                    d="M35.6846 53.1836C35.1104 53.7305 35.001 54.1133 35.001 55.3711V56.875H33.4971C32.2393 56.875 31.8564 56.9844 31.3096 57.5586C30.4072 58.4336 30.4072 59.6914 31.3096 60.5664C31.9385 61.2227 32.1572 61.25 35.001 61.25C37.8447 61.25 38.0635 61.2227 38.6924 60.5664C39.3486 59.9375 39.376 59.7188 39.376 56.875C39.376 54.0312 39.3486 53.8125 38.6924 53.1836C38.2822 52.7461 37.708 52.5 37.1885 52.5C36.6689 52.5 36.0947 52.7461 35.6846 53.1836Z"
                    fill="url(#paint21_linear_5824_4080)"
                />
                <path
                    d="M44.4345 57.5586C43.0673 58.8984 44.0243 61.25 45.9384 61.25C47.0595 61.25 48.1259 60.1836 48.1259 59.0625C48.1259 57.9414 47.0595 56.875 45.9384 56.875C45.4188 56.875 44.8446 57.1211 44.4345 57.5586Z"
                    fill="url(#paint22_linear_5824_4080)"
                />
                <path
                    d="M53.1846 57.5586C52.6104 58.1055 52.501 58.4883 52.501 59.7461V61.25H50.9971C49.7393 61.25 49.3564 61.3594 48.8096 61.9336C47.9072 62.8086 47.9072 64.0664 48.8096 64.9414C49.4658 65.625 49.5205 65.625 54.6885 65.625C59.8564 65.625 59.9111 65.625 60.5674 64.9414C61.0049 64.5312 61.251 63.957 61.251 63.4375C61.251 62.043 60.2119 61.25 58.3799 61.25H56.876V59.7461C56.876 57.9141 56.083 56.875 54.6885 56.875C54.1689 56.875 53.5947 57.1211 53.1846 57.5586Z"
                    fill="url(#paint23_linear_5824_4080)"
                />
                <path
                    d="M40.0591 61.9331C39.3755 62.5894 39.3755 62.644 39.3755 67.812C39.3755 74.8394 38.9106 74.3745 45.938 74.3745C51.106 74.3745 51.1606 74.3745 51.8169 73.6909C52.7192 72.8159 52.7192 71.5581 51.8169 70.6831C51.188 70.0269 51.0239 69.9995 47.4419 69.9995H43.7505V66.3081C43.7505 62.7261 43.7231 62.562 43.0669 61.9331C42.6567 61.4956 42.0825 61.2495 41.563 61.2495C41.0435 61.2495 40.4692 61.4956 40.0591 61.9331Z"
                    fill="url(#paint24_linear_5824_4080)"
                />
                <path
                    d="M31.3086 66.3086C30.6523 66.9375 30.625 67.1562 30.625 70C30.625 72.8438 30.6523 73.0625 31.3086 73.6914C31.7188 74.1289 32.293 74.375 32.8125 74.375C33.332 74.375 33.9062 74.1289 34.3164 73.6914C34.9727 73.0625 35 72.8438 35 70C35 67.1562 34.9727 66.9375 34.3164 66.3086C33.9062 65.8711 33.332 65.625 32.8125 65.625C32.293 65.625 31.7188 65.8711 31.3086 66.3086Z"
                    fill="url(#paint25_linear_5824_4080)"
                />
                <path
                    d="M57.5595 70.6836C56.1923 72.0234 57.1493 74.375 59.0634 74.375C59.5829 74.375 60.1571 74.1289 60.5673 73.6914C61.0048 73.2812 61.2509 72.707 61.2509 72.1875C61.2509 71.668 61.0048 71.0938 60.5673 70.6836C60.1571 70.2461 59.5829 70 59.0634 70C58.5438 70 57.9696 70.2461 57.5595 70.6836Z"
                    fill="url(#paint26_linear_5824_4080)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_5824_4080"
                        x1="4.71327"
                        y1="26.25"
                        x2="26.1425"
                        y2="25.3819"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_5824_4080"
                        x1="13.233"
                        y1="17.5"
                        x2="17.4797"
                        y2="17.3295"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_5824_4080"
                        x1="30.733"
                        y1="8.75"
                        x2="34.9797"
                        y2="8.57955"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_5824_4080"
                        x1="39.5103"
                        y1="21.875"
                        x2="48.0926"
                        y2="21.7012"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_5824_4080"
                        x1="52.8383"
                        y1="26.25"
                        x2="74.2675"
                        y2="25.3819"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_5824_4080"
                        x1="30.7603"
                        y1="17.5"
                        x2="39.2901"
                        y2="16.8089"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_5824_4080"
                        x1="61.358"
                        y1="17.5"
                        x2="65.6047"
                        y2="17.3295"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_5824_4080"
                        x1="66.84"
                        y1="135.488"
                        x2="135.219"
                        y2="133.85"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_5824_4080"
                        x1="87.9059"
                        y1="30.625"
                        x2="112.226"
                        y2="24.7138"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_5824_4080"
                        x1="96.3853"
                        y1="126.875"
                        x2="104.915"
                        y2="126.184"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint10_linear_5824_4080"
                        x1="30.8955"
                        y1="39.375"
                        x2="48.0322"
                        y2="38.6811"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint11_linear_5824_4080"
                        x1="4.5102"
                        y1="35"
                        x2="13.0334"
                        y2="34.31"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint12_linear_5824_4080"
                        x1="17.608"
                        y1="35"
                        x2="21.8547"
                        y2="34.8295"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint13_linear_5824_4080"
                        x1="17.7706"
                        y1="52.5"
                        x2="34.9241"
                        y2="51.9441"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint14_linear_5824_4080"
                        x1="44.0283"
                        y1="43.75"
                        x2="61.1433"
                        y2="42.8259"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint15_linear_5824_4080"
                        x1="13.233"
                        y1="39.375"
                        x2="17.4797"
                        y2="39.2045"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint16_linear_5824_4080"
                        x1="4.5102"
                        y1="48.125"
                        x2="13.0752"
                        y2="47.7783"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint17_linear_5824_4080"
                        x1="39.5103"
                        y1="52.5"
                        x2="48.0898"
                        y2="52.2683"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint18_linear_5824_4080"
                        x1="48.328"
                        y1="56.875"
                        x2="61.1855"
                        y2="56.3541"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint19_linear_5824_4080"
                        x1="4.71327"
                        y1="74.375"
                        x2="26.1425"
                        y2="73.5069"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint20_linear_5824_4080"
                        x1="13.233"
                        y1="65.625"
                        x2="17.4797"
                        y2="65.4545"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint21_linear_5824_4080"
                        x1="30.768"
                        y1="61.25"
                        x2="39.333"
                        y2="60.9033"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint22_linear_5824_4080"
                        x1="43.858"
                        y1="61.25"
                        x2="48.1047"
                        y2="61.0795"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint23_linear_5824_4080"
                        x1="48.3357"
                        y1="65.625"
                        x2="61.1603"
                        y2="64.8461"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint24_linear_5824_4080"
                        x1="39.5721"
                        y1="74.3808"
                        x2="52.4292"
                        y2="73.8603"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint25_linear_5824_4080"
                        x1="30.6927"
                        y1="74.375"
                        x2="34.9838"
                        y2="74.2881"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint26_linear_5824_4080"
                        x1="56.983"
                        y1="74.375"
                        x2="61.2297"
                        y2="74.2045"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
