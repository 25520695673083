/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react';
import { cn } from 'utills/tailwindUtil';

const TimelineComponent = ({ timeline, sx }) => {
    return (
        <div className="flow-root mt-10">
            <ul role="list" className="-mb-8">
                {timeline.map((event, eventIdx) => (
                    <li key={'timeline' + eventIdx}>
                        <div className="relative pb-10">
                            {eventIdx !== timeline.length - 1 ? (
                                <span
                                    className="absolute left-5 top-4 -ml-px h-full w-0.5 bg-third"
                                    aria-hidden="true"
                                />
                            ) : null}
                            <div className="relative flex space-x-3">
                                <div>
                                    <span
                                        className={cn(
                                            'h-10 w-10 rounded-full flex items-center justify-center ring-0 ring-white bg-third'
                                        )}
                                    >
                                        <span className="h-5 w-5 text-white bg-third contents" aria-hidden="true">
                                            {eventIdx + 1}
                                        </span>
                                    </span>
                                </div>
                                <div className="flex min-w-0 flex-1 justify-between space-x-6 pt-1.5">
                                    <div>
                                        <div
                                            className={cn('text-[18px] font-normal  text-black', sx)}
                                            dangerouslySetInnerHTML={{ __html: event }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TimelineComponent;
