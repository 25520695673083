import React from 'react';
import { motion } from 'framer-motion';

import 'react-toastify/dist/ReactToastify.css';

import { validateEmail } from '../../../../utills/FormValidation';
import { useState } from 'react';
import { toastHandler } from 'responseHanlder';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { addBranchLocationThunk } from 'store/auth/slices';

import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { PickAddress } from 'global-components/PickAddress';
import Slideover from 'components/atoms/SlideOver';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import MapComponent from 'components/atoms/MapComponent';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue } from 'utills/dataValidation';

const AddBranchSide = ({ toggleBranchSide, open, setOpen }) => {
    const dispatch = useDispatch();

    const [branchTitle, setBranchTitle] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [mapValues, setMapValues] = useState(null);

    const { isLoading } = useSelector((state) => state.global);

    const [emailError, setEmailError] = useState(false);

    const handleEmail = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (value.trim().length === 0) {
            setEmailError(false);
        } else if (!validateEmail(value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const branchObj = {
            title: branchTitle,
            phone_number: phoneNo,
            email,
            address
        };
        if (!branchTitle) {
            toastHandler('Title is required.', TOAST_TYPE_ERROR);
            return;
        }
        if (branchTitle.length > 30) {
            toastHandler('Title should be less than 30 character', TOAST_TYPE_ERROR);
            return;
        }
        if (!phoneNo) {
            toastHandler('Phone number is required.', TOAST_TYPE_ERROR);
            return;
        }

        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        if (!email) {
            toastHandler('Email is required.', TOAST_TYPE_ERROR);
            return;
        }
        if (email.length > 40) {
            toastHandler('Email should be less than 40 character', TOAST_TYPE_ERROR);
            return;
        }

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (!address) {
            toastHandler('Adress is required.', TOAST_TYPE_ERROR);
            return;
        }
        if (address.length > 100) {
            toastHandler('Adress should be less than 100 character', TOAST_TYPE_ERROR);
            return;
        }
        const hasEmptyValue = Object.values(branchObj).some((value) => {
            return (
                value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0)
            );
        });

        if (hasEmptyValue) {
            toastHandler('Please fill in all required fields.', 'error');
            return;
        }

        dispatch(setLoader(true));
        dispatch(
            addBranchLocationThunk({
                ...branchObj,
                lat: (mapValues && mapValues?.geometry?.location?.lat()?.toString()) || '0',
                lng: (mapValues && mapValues?.geometry?.location?.lng()?.toString()) || '0',
                formatted_address: (mapValues && mapValues?.formatted_address) || ''
            })
        )
            .then((response) => {
                if (response.payload) {
                    toggleBranchSide();
                    setBranchTitle('');
                    setEmail('');
                    setPhoneNo('');
                    setAddress('');
                    toastHandler('Branch location added successfully', 'success');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const onSelect = (place) => {
        setAddress(place.formatted_address);
        setMapValues(place);
    };
    return (
        <Slideover title="Add Branch" open={open} setOpen={setOpen}>
            <div>
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="relative"
                >
                    <div className="service-info pt-10 p-5">
                        <div className="add-ann-form mt-3">
                            <div>
                                <div>
                                    <label className="buttons-font">Branch Title</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setBranchTitle(e.target.value)}
                                        type="text"
                                        value={branchTitle}
                                        maxLength="60"
                                        placeholder="Branch Title"
                                        className="px-3 rounded-xl mt-1 inputs-font"
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <PhoneInputComponent
                                        title="Phone Number"
                                        placeholder="Enter Phone Number"
                                        titleSx="font-semibold"
                                        sx="!rounded-xl"
                                        atChange={(phone) => setPhoneNo(phone)}
                                        value={phoneNo}
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label className="buttons-font">Email</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => handleEmail(e)}
                                        type="email"
                                        value={email}
                                        placeholder="Email"
                                        className="px-3 rounded-xl mt-1 inputs-font"
                                    />

                                    <div className="error-div mt-1 small-text">
                                        {emailError && <span> Invalid email format </span>}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <PickAddress
                                        id="branch-location"
                                        onSelect={onSelect}
                                        address={address}
                                        setAddress={setAddress}
                                    />
                                </div>
                            </div>

                            {mapValues && (
                                <div className="mt-2">
                                    <div>
                                        <label>Map</label>
                                    </div>
                                    <div>
                                        <MapComponent addressData={mapValues} className={'rounded-xl mt-2'} />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className=" flex justify-center items-center mt-10 md:mt-16 lg:mt-20">
                            <button
                                onClick={handleSubmit}
                                className="px-20 py-2 rounded-lg ann-btn buttons-font text-white"
                            >
                                {isLoading ? <SmallLoaderWhite /> : 'Add'}
                            </button>
                        </div>
                    </div>
                </motion.div>
            </div>
        </Slideover>
    );
};

export default AddBranchSide;
