import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { SearchInput } from './SearchInput';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SmallLoader } from 'components/common/SmallLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generateId } from 'utills/uid';
import { LogsSkeleton } from 'components/workplace-content/tasks-pages/task-details/skeletons/LogsSkeleton';
import './css/molecules.css';
import { NoDataFound } from 'components/common/NoDataFound';
import { Button } from 'components/atoms/buttons/Button';
import { DownloadIcon } from 'utills/svgs/DownloadIcon';
import { PhotoIconWhite } from 'utills/svgs/PhotoIconWhite';
import Slideover from 'components/atoms/SlideOver';
import { FileIcon } from 'utills/svgs/FileIcon';
import GlobalPDFViewer from './GlobalPDFViewer';
import { generateRandomProfileLink } from 'utills/globalVars';

export const DefaultLogs = ({
    _toggleLogs,
    logs,
    count,
    page,
    fetchLogs,
    pageSize,
    handleMore,
    term,
    setTerm,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    _title,
    downloadActivity,
    filterPage,
    filterCount,
    setfilteredLogs,
    setFilterPage,
    open,
    setOpen
}) => {
    const { sideLoader, pdfLoader } = useSelector((state) => state?.global);
    const [pdfUrl, setPdfUrl] = useState('');
    const [openpdf, setOpenpdf] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const datePickerProps = {
        right: CalendarSmallIco
    };

    const handleSearch = (term) => {
        setTerm(term);
        setSearchTerm(term);
    };

    const handleFromChange = (date) => {
        setDateFrom(date);
    };

    const handeDateTo = (date) => {
        setDateTo(date);
    };

    useEffect(() => {
        fetchLogs();
    }, [page, filterPage, dateFrom, term, dateTo]);

    useEffect(() => {
        if (dateFrom || term || dateTo) {
            setFilterPage(1);
            setfilteredLogs([]);
        }
    }, [dateFrom, term, dateTo]);

    const exportButtonProps = {
        leftIcon: DownloadIcon,
        loader: SmallLoader
    };

    function HandleText({ logText }) {
        const processedText = logText.replace(/<TEXT>(.*?)<\/TEXT>/g, '<span style="color: #b695f8">$1</span>');

        return <div className="break-all" dangerouslySetInnerHTML={{ __html: processedText }} />;
    }

    return (
        <Slideover open={open} setOpen={setOpen} size={'max-w-2xl'} title="Logs">
            {openpdf && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpenpdf} open={openpdf} /> : ''}{' '}
            <div>
                <div>
                    {' '}
                    <div>
                        <div>
                            <motion.div
                                initial={{ x: 700 }}
                                animate={{ x: 0 }}
                                transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                            >
                                <div>
                                    <div className="flex justify-between items-center mt-3">
                                        {' '}
                                        <div className="flex items-center justify-between w-full">
                                            <div className="flex gap-2 items-center justify-between flex-wrap">
                                                <DatePick
                                                    iconSet={datePickerProps}
                                                    onDateChange={(date) => handleFromChange(date)}
                                                    placeholder="From"
                                                    maxDate={dateTo ? dateTo : null}
                                                    value={dateFrom}
                                                />

                                                <DatePick
                                                    iconSet={datePickerProps}
                                                    onDateChange={(date) => handeDateTo(date)}
                                                    placeholder="To"
                                                    minDate={dateFrom ? dateFrom : null}
                                                    value={dateTo}
                                                />
                                            </div>
                                            <Button
                                                title="Export Data"
                                                classes="bg-[#b695f8] rounded-lg px-3 h-[3rem] text-white secondary-fonts flex items-center gap-3"
                                                iconSet={exportButtonProps}
                                                isloading={pdfLoader ? true : false}
                                                disabled={pdfLoader ? true : false}
                                                atClick={() => {
                                                    if (downloadActivity) {
                                                        downloadActivity();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {' '}
                                        <div className="default-logs-actions mt-5 flex gap-3 items-center justify-center flex-wrap-reverse">
                                            <div className="mt-3 w-full">
                                                <SearchInput
                                                    atChange={(e) => {
                                                        handleSearch(e.target.value);
                                                    }}
                                                    value={searchTerm}
                                                    placeholder="Search"
                                                    inputBorder="1px solid #DCDCDC"
                                                />
                                            </div>
                                        </div>
                                        {page < 2 && filterPage < 2 && sideLoader ? (
                                            <LogsSkeleton />
                                        ) : (
                                            <div id="scrollableDiv" className="ml-1 mt-3">
                                                <InfiniteScroll
                                                    dataLength={logs?.length}
                                                    next={handleMore}
                                                    className="scroler-component"
                                                    height={750}
                                                    hasMore={
                                                        dateFrom || dateTo || term
                                                            ? filterPage < Math.ceil(filterCount / pageSize)
                                                                ? true
                                                                : false
                                                            : page < Math.ceil(count / pageSize)
                                                            ? true
                                                            : false
                                                    }
                                                    loader={<LogsSkeleton />}
                                                >
                                                    {logs && logs.length < 1 && !sideLoader ? (
                                                        <div>
                                                            <NoDataFound message="No Activity Found!" height="30vh" />
                                                        </div>
                                                    ) : (
                                                        <div className="customer-log-container">
                                                            {logs &&
                                                                logs?.map((log, index) => (
                                                                    <div
                                                                        key={generateId()}
                                                                        className={`relative ${
                                                                            index > 0 && 'pt-5'
                                                                        } log ml-5`}
                                                                    >
                                                                        {log?.user?.image ? (
                                                                            <div className="activity-img-wrapper">
                                                                                <img
                                                                                    src={log.user.image}
                                                                                    alt="src"
                                                                                    className="activity-img"
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div className="activity-img-wrapper">
                                                                                <img
                                                                                    src={generateRandomProfileLink(
                                                                                        log?.user?.first_name +
                                                                                            ' ' +
                                                                                            log?.user?.last_name
                                                                                    )}
                                                                                    alt="src"
                                                                                    className="activity-img"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <div className="activity-details pl-9 py-3">
                                                                            <div className="activity-text">
                                                                                <span className="pr-1 primary-fonts">
                                                                                    {log?.user?.first_name}
                                                                                </span>

                                                                                <HandleText logText={log.text} />
                                                                            </div>
                                                                            {log?.files && (
                                                                                <div className="flex gap-2 items-center flex-wrap">
                                                                                    {log?.files?.map((item, index) => (
                                                                                        <div
                                                                                            key={'fils' + index}
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    item?.url
                                                                                                        ?.toLowerCase()
                                                                                                        ?.endsWith(
                                                                                                            '.pdf'
                                                                                                        )
                                                                                                ) {
                                                                                                    setPdfUrl(
                                                                                                        item?.url
                                                                                                    );
                                                                                                    setOpen(true);
                                                                                                }
                                                                                            }}
                                                                                            className="flex items-center w-fit rounded-md p-1 border my-2"
                                                                                        >
                                                                                            <div
                                                                                                className="bg-thirdbg w-fit rounded-lg px-1"
                                                                                                key={
                                                                                                    'activity_card_index' +
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                <div className="flex items-center px-3 py-2 gap-2">
                                                                                                    {item?.url
                                                                                                        ?.toLowerCase()
                                                                                                        ?.endsWith(
                                                                                                            '.pdf'
                                                                                                        ) ? (
                                                                                                        <span className="invert scale-75">
                                                                                                            {' '}
                                                                                                            <FileIcon />
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <PhotoIconWhite />
                                                                                                    )}

                                                                                                    <div className="flex flex-col items-start">
                                                                                                        <h3 className="text-sm text-white font-medium">
                                                                                                            {item.name}
                                                                                                        </h3>
                                                                                                        <h3 className="text-xs text-white font-normal">
                                                                                                            {item.size}
                                                                                                        </h3>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )}
                                                                            <div className="activity-date text-xs mt-1">
                                                                                {log.created_at}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    )}
                                                </InfiniteScroll>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </Slideover>
    );
};
