import React, { useState } from 'react';
import Input from 'components/atoms/Input';
import { Button } from 'components/atoms/buttons/Button';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';

const InlineAddGroup = ({ onCreate, onCancel }) => {
    const [newGroupName, setNewGroupName] = useState('');
    const [groupLoader, setGroupLoader] = useState(false);

    const handleCreateGroup = async () => {
        setGroupLoader(true);
        await onCreate(newGroupName).then(() => {
            setGroupLoader(false);
            setNewGroupName('');
        });
    };

    //
    return (
        <div>
            {' '}
            <div className="flex items-center justify-start gap-1 flex-wrap px-5 py-2">
                <div className="flex-1">
                    <Input
                        useWithoutForm={true}
                        placeholder="Enter group title"
                        name="title"
                        sx="!rounded-xl border !py-2"
                        value={newGroupName}
                        onChange={(e) => {
                            // console.info('e.target.value', e.target.value);
                            setNewGroupName(e.target.value);
                        }}
                        required={true}
                        type="title"
                    />
                </div>
                <Button
                    classes={'hover:!shadow-none hover:!outline-none flex items-center justify-center w-10'}
                    isloading={groupLoader}
                    disabled={groupLoader || !newGroupName}
                    groupSx={'!h-full py-2 bg-third !rounded-lg shadow-md overflow-hidden'}
                    atClick={() => handleCreateGroup()}
                    iconSet={{
                        leftIconHTML: groupLoader ? <SmallLoaderWhite /> : <CheckIcon className="w-5 fill-white" />
                    }}
                />
                <Button
                    classes={'hover:!shadow-none hover:!outline-none flex items-center justify-center w-10'}
                    groupSx={
                        '!h-full py-2 bg-white ring ring-1 ring-inset !ring-secondarybg !rounded-lg shadow-md overflow-hidden'
                    }
                    atClick={() => onCancel()}
                    iconSet={{
                        leftIconHTML: <XMarkIcon className="w-5 fill-secondarybg" />
                    }}
                />
            </div>
        </div>
    );
};

export default InlineAddGroup;
