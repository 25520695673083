import React from 'react';
import './css/notes.css';
import NotesOperations from './NotesOperations';
import NotesList from './NotesList';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { getAllNotesThunk } from 'store/workspace/workspaceNotes';
import { BUSINESS_ID } from 'utills/globalVars';
import { Jumbotron } from 'components/organisms/Jumbotron';
import moment from 'moment';
const NotesPage = ({ toggleAddNote, notesUpdated, toggleNotesUpdate, toggleEditNote }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data } = useSelector((state) => state.getAllNotes);
    const [filterDate, setFilterDate] = useState('');

    const [pageSize, setPageSize] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');

    const [page, setPage] = useState(1);

    const handleChangePage = (newPage, perPageCount) => {
        setPage(newPage);
        setPageSize(perPageCount);
        getNotes({
            business_id,
            page: newPage,
            pageSize: perPageCount,
            sort_by: 'DESC',
            search_term: searchTerm,
            from_date: filterDate ? moment(new Date(filterDate)).format('DD-MM-YYYY') : ''
        });
    };

    const filterByDate = (date) => {
        if (!date) {
            setFilterDate('');

            getNotes({
                business_id,
                page: page,
                pageSize: pageSize,

                sort_by: 'DESC',
                search_term: searchTerm,
                from_date: ''
            });
            return;
        }
        if (date) {
            getNotes({
                business_id,
                page: page,
                pageSize: pageSize,

                sort_by: 'DESC',
                search_term: searchTerm,
                from_date: date ? moment(new Date(date)).format('DD-MM-YYYY') : ''
            });
        }
    };

    const getNotes = (payload) => {
        dispatch(setLoader(true));
        dispatch(getAllNotesThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        const payload = {
            business_id,
            page: 1,
            pageSize,
            sort_by: 'DESC',
            search_term: searchTerm,
            from_date: filterDate ? moment(new Date(filterDate)).format('DD-MM-YYYY') : ''
        };

        getNotes(payload);
    }, []);

    useEffect(() => {
        handleChangePage(1, 10);
    }, [notesUpdated]);
    const handleSearchTerm = (value) => {
        setSearchTerm(value);
        const payload = {
            business_id,
            page: 1,
            pageSize,
            sort_by: 'DESC',
            search_term: value,
            from_date: filterDate ? moment(new Date(filterDate)).format('DD-MM-YYYY') : ''
        };

        getNotes(payload);
    };

    return (
        <div>
            <Jumbotron title="Notes" path={[{ title: 'Notes', link: 'notes' }]} />
            <div>
                <NotesOperations
                    filteredNotes={data}
                    searchByTitle={handleSearchTerm}
                    toggleAddNote={toggleAddNote}
                    filterByDate={filterByDate}
                />
            </div>
            <div>
                <NotesList
                    toggleNotesUpdate={toggleNotesUpdate}
                    filteredNotes={data}
                    toggleEditNote={toggleEditNote}
                    page={page}
                    pageSize={pageSize}
                    count={data?.count}
                    handleChangePage={handleChangePage}
                />
            </div>
        </div>
    );
};
export default NotesPage;
