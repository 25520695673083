import React from 'react';
import { useState } from 'react';

export const Truncate = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const truncatedHTML = isExpanded ? text : text?.slice(0, maxLength);

    return (
        <div>
            <div
                className="ann-desc text-black  !font-poppins buttons-font-lighter font-[400] contents"
                dangerouslySetInnerHTML={{ __html: truncatedHTML }}
            />
            {!isExpanded && text?.length > maxLength && (
                <span onClick={toggleExpand} style={{ color: '#b695f8', cursor: 'pointer' }}>
                    {' '}
                    Read more
                </span>
            )}
            {isExpanded && (
                <span onClick={toggleExpand} style={{ color: '#b695f8', cursor: 'pointer' }}>
                    {' '}
                    Read less
                </span>
            )}
        </div>
    );
};
