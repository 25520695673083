import React from 'react';

export const BgGSTIcon = ({ className }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect width="40" height="40" rx="20" fill="url(#paint0_linear_179_4)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3623 16.2458C17.8675 16.2458 17.4648 15.8678 17.4648 15.403C17.4648 15.313 17.5376 15.24 17.628 15.24C17.7181 15.24 17.7911 15.313 17.7911 15.403C17.7911 15.688 18.0471 15.9194 18.3623 15.9194C18.6772 15.9194 18.9338 15.688 18.9338 15.403C18.9338 15.1184 18.6775 14.887 18.3626 14.887C17.8678 14.887 17.4648 14.5087 17.4648 14.0439C17.4648 13.5792 17.8678 13.2012 18.3626 13.2012C18.8575 13.2012 19.2604 13.5792 19.2604 14.0439C19.2604 14.1343 19.1871 14.2072 19.0969 14.2072C19.0071 14.2072 18.9338 14.1343 18.9338 14.0439C18.9338 13.7593 18.6775 13.5275 18.3626 13.5275C18.0474 13.5275 17.7914 13.7593 17.7914 14.0439C17.7914 14.3285 18.0474 14.5603 18.3626 14.5603C18.8575 14.5603 19.2601 14.9386 19.2601 15.403C19.2601 15.8678 18.8575 16.2458 18.3623 16.2458Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5127 16.2449C14.6946 16.2449 14.0293 15.5797 14.0293 14.7616C14.0293 13.9438 14.6946 13.2783 15.5127 13.2783C16.0423 13.2783 16.5354 13.5638 16.799 14.0232C16.8435 14.1003 16.8168 14.199 16.7399 14.2432C16.6628 14.2879 16.5639 14.2609 16.52 14.1839C16.3131 13.8243 15.9271 13.6008 15.5127 13.6008C14.8724 13.6008 14.3517 14.1216 14.3517 14.7616C14.3517 15.4019 14.8724 15.9227 15.5127 15.9227C16.0999 15.9227 16.5871 15.484 16.6631 14.9175H16.0313C15.9423 14.9175 15.8698 14.8452 15.8698 14.7563C15.8698 14.667 15.9423 14.595 16.0313 14.595H16.8346C16.9237 14.595 16.9958 14.667 16.9958 14.7563V14.7616C16.9958 15.5797 16.3306 16.2449 15.5127 16.2449Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9001 16.2458C20.8138 16.2458 20.7441 16.1728 20.7441 16.0824V13.3645C20.7441 13.2744 20.8138 13.2012 20.9001 13.2012C20.9868 13.2012 21.0564 13.2744 21.0564 13.3645V16.0824C21.0564 16.1728 20.9868 16.2458 20.9001 16.2458Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.0663 13.5134H19.7341C19.6454 13.5134 19.5732 13.4434 19.5732 13.3573C19.5732 13.2712 19.6454 13.2012 19.7341 13.2012H22.0663C22.1551 13.2012 22.2275 13.2712 22.2275 13.3573C22.2275 13.4434 22.1551 13.5134 22.0663 13.5134Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <mask
                id="mask0_179_4"
                className="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="23"
                y="9"
                width="6"
                height="7"
            >
                <path d="M23.9102 10H28.4901V15.7086H23.9102V10Z" fill="white" stroke="white" strokeWidth="0.27" />
            </mask>
            <g mask="url(#mask0_179_4)">
                <path
                    d="M24.4414 15.1693H27.671L24.4414 11.0211V15.1693ZM28.193 15.6795H24.186C24.0449 15.6795 23.9307 15.5653 23.9307 15.4242V10.2783C23.9307 10.1693 24.0002 10.0721 24.1031 10.0368C24.2063 10.0014 24.3206 10.0355 24.3873 10.1213L28.3943 15.2676C28.4541 15.3445 28.4651 15.449 28.4224 15.5364C28.3793 15.6238 28.2906 15.6795 28.193 15.6795Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.27"
                />
            </g>
            <mask
                id="mask1_179_4"
                className="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="11"
                y="9"
                width="18"
                height="23"
            >
                <path d="M12 10H28.4876V31H12V10Z" fill="white" stroke="white" strokeWidth="0.27" />
            </mask>
            <g mask="url(#mask1_179_4)">
                <path
                    d="M12.5093 30.4907H27.9357V25.1147L27.5758 25.7941C27.5099 25.9185 27.3554 25.9661 27.2306 25.9002L25.6876 25.0825C25.6278 25.0508 25.5831 24.9968 25.5632 24.9321C25.5432 24.8675 25.5497 24.7975 25.5814 24.7377L27.9357 20.2932V15.6795H24.184C24.0429 15.6795 23.9286 15.5653 23.9286 15.4242V10.5332H12.5093V30.4907ZM28.191 31.001H12.2544C12.1133 31.001 11.999 30.8867 11.999 30.7456V10.2783C11.999 10.1372 12.1133 10.0229 12.2544 10.0229H24.184C24.3251 10.0229 24.4393 10.1372 24.4393 10.2783V15.1693H28.191C28.3317 15.1693 28.4463 15.2835 28.4463 15.4242V20.3567C28.4463 20.3985 28.4362 20.4396 28.4163 20.4762L26.1519 24.7512L27.2444 25.3298L27.9653 23.9681C28.0202 23.8648 28.139 23.8119 28.2524 23.8404C28.3662 23.8684 28.4463 23.9705 28.4463 24.088V30.7456C28.4463 30.8867 28.3317 31.001 28.191 31.001Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.27"
                />
            </g>
            <mask
                id="mask2_179_4"
                className="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="25"
                y="14"
                width="9"
                height="13"
            >
                <path
                    d="M25.4688 14.1475H32.9998V26.0136H25.4688V14.1475Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.27"
                />
            </mask>
            <g mask="url(#mask2_179_4)">
                <path
                    d="M26.1528 24.7508L27.2453 25.3294L32.397 15.6044C32.5263 15.36 32.4331 15.0559 32.1884 14.9266L31.9826 14.8172C31.8643 14.7546 31.7285 14.7416 31.6004 14.781C31.4723 14.8204 31.367 14.9075 31.3044 15.0258L26.1528 24.7508ZM27.3511 25.9295C27.3108 25.9295 27.2697 25.9198 27.2315 25.8998L25.6885 25.0822C25.6287 25.0505 25.584 24.9964 25.5641 24.9317C25.5441 24.8671 25.5506 24.7971 25.5824 24.7374L30.8535 14.7867C30.98 14.548 31.1922 14.3728 31.4504 14.2931C31.7086 14.2138 31.9826 14.2398 32.2213 14.3663L32.4274 14.4756C32.6661 14.6021 32.8414 14.8139 32.9207 15.0725C33.0003 15.3307 32.9743 15.6044 32.8479 15.8434L27.5767 25.7937C27.5308 25.8803 27.4426 25.9295 27.3511 25.9295Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.27"
                />
            </g>
            <path
                d="M26.0032 25.248L25.7418 26.8484L26.9185 25.7331L26.0032 25.248ZM25.3677 27.8096C25.3266 27.8096 25.286 27.7998 25.2481 27.7795C25.1514 27.7283 25.0981 27.6213 25.1156 27.5128L25.5568 24.815C25.5698 24.7341 25.6218 24.6641 25.695 24.6275C25.769 24.5909 25.8552 24.5926 25.928 24.6308L27.4714 25.4485C27.5438 25.4867 27.5938 25.5578 27.6048 25.6396C27.6162 25.7209 27.5873 25.8026 27.5275 25.8591L25.5433 27.7392C25.4945 27.7856 25.4315 27.8096 25.3677 27.8096Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <mask
                id="mask3_179_4"
                className="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="29"
                y="14"
                width="5"
                height="4"
            >
                <path
                    d="M29.8408 14.1484H33.0001V17.6875H29.8408V14.1484Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.27"
                />
            </mask>
            <g mask="url(#mask3_179_4)">
                <path
                    d="M30.5192 16.5084L31.6117 17.087L32.3965 15.6053C32.4591 15.487 32.4721 15.3512 32.4327 15.2231C32.3932 15.0955 32.3062 14.9902 32.1879 14.9275L31.9822 14.8182C31.8638 14.7555 31.728 14.7425 31.5999 14.782C31.4719 14.8214 31.3666 14.9084 31.3039 15.0267L30.5192 16.5084ZM31.7175 17.6871C31.6772 17.6871 31.6361 17.6778 31.5983 17.6574L30.0545 16.8398C29.9947 16.8081 29.95 16.754 29.9305 16.6893C29.9101 16.6247 29.917 16.5547 29.9488 16.495L30.853 14.7877C30.9795 14.549 31.1917 14.3738 31.4499 14.2941C31.7081 14.2148 31.9822 14.2408 32.2208 14.3672L32.427 14.4766C32.6656 14.6031 32.8409 14.8149 32.9202 15.0735C32.9999 15.3317 32.9738 15.6053 32.8474 15.8444L31.9431 17.5513C31.8972 17.6379 31.8089 17.6871 31.7175 17.6871Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.27"
                />
            </g>
            <path
                d="M27.2356 24.2831C27.195 24.2831 27.1543 24.2737 27.1165 24.2534C26.9917 24.1875 26.9441 24.033 27.0104 23.9086L28.7937 20.5415C28.8596 20.4171 29.0141 20.3695 29.1389 20.4354C29.2633 20.5013 29.3109 20.6562 29.2446 20.7806L27.4613 24.1472C27.4153 24.2339 27.3271 24.2831 27.2356 24.2831Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                d="M28.6299 18.1512C28.5897 18.1512 28.549 18.1419 28.5108 18.1215C28.3864 18.0557 28.3388 17.9012 28.4046 17.7767L30.1266 14.5264C30.1925 14.402 30.347 14.3544 30.4714 14.4203L31.1463 14.7777C31.2708 14.8435 31.3183 14.9985 31.2525 15.1229C31.1862 15.2473 31.0321 15.2949 30.9073 15.229L30.458 14.9907L28.8556 18.0154C28.81 18.102 28.7214 18.1512 28.6299 18.1512Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                d="M24.2439 28.0063H23.6064C23.2315 28.0063 22.9115 27.7384 22.8464 27.3688L22.7928 27.0634C22.7789 26.9866 22.7188 26.9622 22.694 26.9552C22.6724 26.9491 22.622 26.9414 22.5761 26.9768L22.3114 27.6005C22.2028 27.8563 21.9454 28.0018 21.6706 27.9636C21.3953 27.925 21.1879 27.7144 21.1538 27.4387L20.9602 25.8737C20.9432 25.796 20.8797 25.7753 20.8529 25.77C20.8269 25.7647 20.7651 25.7603 20.7199 25.818L20.5057 26.3075C20.4491 26.4368 20.2987 26.4954 20.1694 26.4389C20.0405 26.3823 19.9816 26.2319 20.0381 26.103L20.2625 25.5907C20.2678 25.5785 20.2739 25.5667 20.2812 25.5553C20.4256 25.3301 20.6882 25.2178 20.9509 25.2691C21.2135 25.3203 21.4148 25.5236 21.4632 25.7871C21.4644 25.792 21.4648 25.7968 21.4656 25.8021L21.6604 27.3761C21.6677 27.4354 21.7084 27.4533 21.7413 27.4582C21.7738 27.4627 21.8178 27.4566 21.8413 27.4013L22.1255 26.7316C22.1357 26.7072 22.1499 26.6849 22.167 26.6645C22.3321 26.4734 22.5858 26.3962 22.8294 26.4633C23.0729 26.5303 23.2514 26.7263 23.2953 26.9752L23.349 27.2801C23.3714 27.4049 23.4795 27.496 23.6064 27.496H24.2439C24.3846 27.496 24.4993 27.6103 24.4993 27.751C24.4993 27.892 24.3846 28.0063 24.2439 28.0063Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                d="M22.8225 20.6363H17.6225C17.4814 20.6363 17.3672 20.522 17.3672 20.3809C17.3672 20.2402 17.4814 20.126 17.6225 20.126H22.8225C22.9636 20.126 23.0779 20.2402 23.0779 20.3809C23.0779 20.522 22.9636 20.6363 22.8225 20.6363Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                d="M24.6435 22.1265H15.8028C15.6621 22.1265 15.5479 22.0122 15.5479 21.8716C15.5479 21.7305 15.6621 21.6162 15.8028 21.6162H24.6435C24.7841 21.6162 24.8984 21.7305 24.8984 21.8716C24.8984 22.0122 24.7841 22.1265 24.6435 22.1265Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <path
                d="M24.6435 23.6181H15.8028C15.6621 23.6181 15.5479 23.5034 15.5479 23.3628C15.5479 23.2221 15.6621 23.1074 15.8028 23.1074H24.6435C24.7841 23.1074 24.8984 23.2221 24.8984 23.3628C24.8984 23.5034 24.7841 23.6181 24.6435 23.6181Z"
                fill="white"
                stroke="white"
                strokeWidth="0.27"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_179_4"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                    <stop offset="1" stopColor="#0A1E46" />
                </linearGradient>
            </defs>
        </svg>
    );
};
