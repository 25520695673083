/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AnnouncementContent from '../../components/workplace-content/announcement-content/AnnouncementContent';
import AnnouncementDetails from '../../components/workplace-content/announcement-content/AnnouncementDetails';

import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { AddnewAnnouncement } from 'components/business-dashboard-pages/AddnewAnnouncement';
import { EditAnnoncement } from 'components/workplace-content/announcement-content/EditAnnoncement';
import { getSingleNews } from 'store/workspace/workspaceNews';
import { useDispatch } from 'react-redux';
import { SidebarLayout } from 'layouts/SidebarLayout';

const Announcement = () => {
    const [showAnnDetails, setShowAnnDetails] = useState(false);
    const [showAddAnn, setShowAddAnn] = useState(false);
    const [ann, setAnn] = useState(null);
    const [annUpdated, setAnnUpdated] = useState(false);
    const [editAnn, setEditAnn] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!editAnn) {
            dispatch(getSingleNews.actions.handleUpdate(null));
        }
    }, [editAnn]);

    const toggleEditAnn = () => {
        setEditAnn(!editAnn);
    };

    const toggleAnnDetails = () => {
        setShowAnnDetails(!showAnnDetails);
    };

    const handleAnn = (ann) => {
        setAnn(ann);
    };

    function toggleAddAnn() {
        setShowAddAnn(!showAddAnn);
    }

    const toggleAnnUpdate = () => {
        setAnnUpdated(!annUpdated);
    };

    //handleEditAnnouncement
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {showAnnDetails && <AnnouncementDetails toggleAnnDetails={toggleAnnDetails} ann={ann} />}
                <AddnewAnnouncement open={showAddAnn} setOpen={setShowAddAnn} toggleAnnUpdate={toggleAnnUpdate} />

                {editAnn && (
                    <EditAnnoncement
                        isHome={true}
                        open={editAnn}
                        setOpen={setEditAnn}
                        toggleAnnUpdate={toggleAnnUpdate}
                        toggleEditAnn={toggleEditAnn}
                    />
                )}

                <div>
                    <RoleBasedGuard module={'Announcements'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                        <div>
                            <AnnouncementContent
                                toggleAnnDetails={toggleAnnDetails}
                                handleAnn={handleAnn}
                                toggleAddAnn={toggleAddAnn}
                                annUpdated={annUpdated}
                                toggleEditAnn={toggleEditAnn}
                            />
                        </div>
                    </RoleBasedGuard>
                </div>
            </SidebarLayout>
        </div>
    );
};
export default Announcement;
