import React from 'react';

import EmailAuthPage from '../../../../components/dashboard-components/auth/EmailAuthPage';
const EmailAuth = () => {
    return (
        <div>
            <EmailAuthPage />
        </div>
    );
};

export default EmailAuth;
