import React from 'react';

export const Cross = () => {
    return (
        <div className="hover:scale-105 transition-all">
            <svg width="23" height="23" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="13" cy="12.8502" rx="13" ry="12.5729" fill="#0A1E46" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.3048 19.3716C18.7018 19.7555 19.3349 19.745 19.7188 19.348C20.1028 18.951 20.0922 18.3179 19.6952 17.9339L14.9384 13.3335L19.6952 8.73298C20.0922 8.34903 20.1028 7.71595 19.7188 7.31896C19.3349 6.92197 18.7018 6.9114 18.3048 7.29535L13.5 11.9423L8.6952 7.29534C8.29821 6.9114 7.66513 6.92197 7.28119 7.31896C6.89724 7.71595 6.90781 8.34903 7.3048 8.73298L12.0616 13.3335L7.3048 17.9339C6.90781 18.3179 6.89724 18.951 7.28118 19.348C7.66513 19.745 8.29821 19.7555 8.6952 19.3716L13.5 14.7246L18.3048 19.3716Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
