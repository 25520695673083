/* eslint-disable no-unused-vars */
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import React, { useEffect, useState } from 'react';
import { countWords, getFirst250Words, getFirstWords } from 'utills/dataValidation';

export const DescriptionInput = ({ title, placeholder, onChange, value, maxCount, required }) => {
    const [bio, setBio] = useState(value ?? '');
    const [bioLength, setBioLength] = useState(0);

    const handleBio = (value) => {
        setBio(value);
        onChange(value);
    };

    const handlePastBio = (e) => {
        const value = e.target.value;
        setBio(value);
        onChange(value);
    };

    return (
        <div>
            {title && <div className="buttons-font font-poppins">{title ?? 'Description'}</div>}
            <div>
                <div className="mt-2">
                    <DefaultTextArea
                        placeholder={placeholder ?? 'Description'}
                        onChange={(e) => handleBio(e)}
                        onInput={(e) => handlePastBio(e)}
                        cols="30"
                        rows="10"
                        value={bio ?? ''}
                        maxCount={maxCount}
                        required={required}
                        className="rounded-xl p-3 font-poppins w-full inputs-font focus:border-1 focus:border-gray-700 focus:ring-0 "
                    />
                </div>
            </div>
        </div>
    );
};
