import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import '../../css-steps/login-css/securityQuestions.css';
import { useEffect, useState } from 'react';

import { Loader } from '../common/Loader';
import { SecurityInfo } from '../signup-steps/signup-utills/securityQuestions/SecurityInfo';
import securityQuestionsText from '../signup-steps/signup-utills/SignupText';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../store/global/globalReducer';
import { loginGetSecurityQuestionsThunk, loginVerifyAnswersThunk } from '../../store/auth/slices';
import { toastHandler } from '../../responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

const SecurityQuestions = (props) => {
    const dispatch = useDispatch();
    const forgotPasswordData = JSON.parse(sessionStorage.getItem('forgotPasswordData'));

    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};

    const isLoading = useSelector((state) => state.global.isLoading);
    const [securityQuestions, setSecurityQuestions] = useState([]);

    const [questionResponses, setQuestionResponses] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        const existingResponseIndex = questionResponses.findIndex((response) => response.question_id === name);

        if (value === '') {
            if (existingResponseIndex !== -1) {
                const updatedResponses = questionResponses.filter((response) => response.question_id !== name);
                setQuestionResponses(updatedResponses);
            }
        } else {
            if (existingResponseIndex !== -1) {
                const updatedResponses = [...questionResponses];
                updatedResponses[existingResponseIndex] = {
                    ...updatedResponses[existingResponseIndex],
                    value
                };
                setQuestionResponses(updatedResponses);
            } else {
                setQuestionResponses((prevResponses) => [...prevResponses, { question_id: name, value }]);
            }
        }
    };

    const answersToSubmit = {
        email: parsedData?.email,
        user_type: 'employee',
        questions: [...questionResponses]
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!parsedData?.otp) {
            toastHandler('You need to verify your email to proceed ', TOAST_TYPE_ERROR);
            return;
        }
        if (questionResponses.length < 3) {
            toastHandler('Please fill all in answers', 'error');
            return;
        }

        dispatch(setLoader(true));
        await dispatch(loginVerifyAnswersThunk(answersToSubmit))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    const forgotPassData = {
                        email: parsedData?.email,
                        otp: true,
                        questions: true
                    };
                    localStorage.setItem('forgotPassData', JSON.stringify(forgotPassData));
                    if (forgotPasswordData.google_authenticator_2fa && !forgotPasswordData.email_2fa) {
                        props.handleCondition(11);
                    } else if (forgotPasswordData.google_authenticator_2fa && forgotPasswordData.email_2fa) {
                        props.handleCondition(11);
                    } else if (!forgotPasswordData.google_authenticator_2fa && forgotPasswordData.email_2fa) {
                        props.handleCondition(17);
                    } else if (!forgotPasswordData.google_authenticator_2fa && !forgotPasswordData.email_2fa) {
                        props.handleCondition(5);
                    }
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                setQuestionResponses([]);
                console.error(error);
            });
        dispatch(setLoader(false));
    };

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            dispatch(
                loginGetSecurityQuestionsThunk({
                    email: parsedData?.email?.toLowerCase(),
                    user_type: 'employee'
                })
            )
                .then((response) => {
                    setSecurityQuestions(response.payload);
                    dispatch(setLoader(false));
                })
                .catch((error) => {
                    dispatch(setLoader(false));
                    console.error(error);
                });
        }, 1000);
    }, []);

    useEffect(() => {
        if (!parsedData?.otp) {
            toastHandler('You need to verify your email to proceed ', TOAST_TYPE_ERROR);
        }
    }, []);
    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2 ">
            {isLoading && <Loader />}
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                <div className="text-center card-layout-title-text mt-2 font-fira ">Security Questions</div>

                <div className=" p-5 px-16 mt-5">
                    <SecurityInfo text={securityQuestionsText.textOne} />

                    <SecurityInfo text={securityQuestionsText.textTwo} />

                    <SecurityInfo text={securityQuestionsText.textThree} />
                </div>
                <form onSubmit={handleSubmit} className="default-forms">
                    {' '}
                    <div className="security-qa px-16 w-[94%]  mt-3 ">
                        {securityQuestions &&
                            securityQuestions?.map((question, index) => (
                                <div className="q1 mt-4" key={question.question_id}>
                                    <div className="table-title font-poppins">Question {index + 1}</div>

                                    <div className="question-div px-3 mt-2 font-[500] !text-[16px] font-poppins">
                                        {question.question}
                                    </div>

                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            maxLength="60"
                                            className="px-3 buttons-font-lighter font-poppins"
                                            name={question.question_id}
                                            value={
                                                questionResponses.map((res) => res.question_id === question.question_id)
                                                    .value
                                            }
                                            onChange={handleChange}
                                            required
                                            placeholder="Write Answer"
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className=" flex justify-between items-center mt-5 px-16 w-[94%]">
                        <button
                            type="button"
                            onClick={() => props.handleCondition(1)}
                            className="px-12 btn-1  rounded-lg buttons-font font-fira"
                        >
                            Back
                        </button>
                        <button type="submit" className="px-12 ann-btn buttons-font font-fira  rounded-lg">
                            Next
                        </button>
                    </div>
                    <p className="dont-have-account flex justify-center items-center mt-2 mb-5 small-text">
                        Forgot your security answers ?{' '}
                        <span onClick={() => props.handleCondition(14)} className="signup-redirect cursor-pointer pl-2">
                            Try another way
                        </span>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default SecurityQuestions;
