import React from 'react';

export const BgLocation = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#FAF9F6" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.407 24.2551C13.8893 23.4214 13.1933 22.3146 11.2725 19.6958C10.9195 19.221 10.5882 18.7994 10.2821 18.4099C8.87389 16.6175 8 15.5053 8 13.0206C8 9.17891 11.1324 6.00944 15 6.00944C18.8676 6.00944 22 9.17891 22 13.0206C22 15.5053 21.1261 16.6175 19.7179 18.4099C19.4118 18.7994 19.0805 19.221 18.7275 19.6958C16.9609 22.0952 16.1186 23.4043 15.5886 24.2537C15.5237 24.3577 15.4634 24.4548 15.4068 24.5462C15.2523 24.7952 15.1243 25.0016 15 25.1889C14.804 24.8945 14.6388 24.6285 14.4545 24.3315C14.4388 24.3062 14.423 24.2808 14.407 24.2551ZM19.5287 20.2998C19.8728 19.8372 20.1904 19.4329 20.4931 19.0477C20.6177 18.8892 20.7397 18.7339 20.8599 18.5791C21.2635 18.0597 21.6431 17.5511 21.9623 17.0154C22.6287 15.8968 23 14.7125 23 13.0206C23 8.61699 19.4155 5 15 5C10.5845 5 7 8.61699 7 13.0206C7 14.7125 7.37128 15.8968 8.03774 17.0154C8.35692 17.5511 8.73653 18.0597 9.14006 18.5791C9.26033 18.7339 9.38234 18.8892 9.50689 19.0477C9.80945 19.4327 10.1269 19.8368 10.4708 20.2992C12.4239 22.9621 13.0775 24.0149 13.5995 24.8557C13.7884 25.1602 13.9602 25.4368 14.1701 25.752L15.0012 27L15.8306 25.7509C15.9661 25.547 16.1011 25.3294 16.2614 25.0709C16.7836 24.2291 17.5752 22.9532 19.5287 20.2998ZM12.5 13.0206C12.5 14.36 13.6121 15.4878 15 15.4878C16.3879 15.4878 17.5 14.36 17.5 13.0206C17.5 11.6813 16.3879 10.5535 15 10.5535C13.6121 10.5535 12.5 11.6813 12.5 13.0206ZM11.5 13.0206C11.5 14.9327 13.0751 16.4973 15 16.4973C16.9249 16.4973 18.5 14.9327 18.5 13.0206C18.5 11.1086 16.9249 9.54402 15 9.54402C13.0751 9.54402 11.5 11.1086 11.5 13.0206Z"
                fill="black"
            />
        </svg>
    );
};
