import React, { useEffect, useState } from 'react';
import './css/organizationalSettings.css';
import 'react-notifications/lib/notifications.css';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR } from 'utills/globalVars';
import { useDispatch, useSelector } from 'react-redux';
import { createChecklistThunk, getChecklistDetailsThunk, updateChecklistThunk } from 'store/workspace/workspaceTasks';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { generateId } from 'utills/uid';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { setLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { Input } from 'components/atoms/inputs/Input';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { Bars4Icon } from '@heroicons/react/24/outline';

const timeOptions = [
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '45 minutes', value: 45 },
    { label: '1 hour', value: 60 },
    { label: '1h 30m', value: 90 },
    { label: '2 hours', value: 2 * 60 },
    { label: '3 hours', value: 3 * 60 },
    { label: '5 hours', value: 5 * 60 },
    { label: '1 day', value: 24 * 60 },
    { label: '1.5 days', value: 24 * 60 + 12 * 60 },
    { label: '2 days', value: 2 * 24 * 60 },
    { label: '2.5 days', value: 2 * 24 * 60 + 12 * 60 },
    { label: '3 days', value: 3 * 24 * 60 },
    { label: '3.5 days', value: 3 * 24 * 60 + 12 * 60 },
    { label: '4 days', value: 4 * 24 * 60 },
    { label: '4.5 days', value: 4 * 24 * 60 + 12 * 60 },
    { label: '5 days', value: 5 * 24 * 60 },
    { label: '5.5 days', value: 5 * 24 * 60 + 12 * 60 },
    { label: '6 days', value: 6 * 24 * 60 },
    { label: '6.5 days', value: 6 * 24 * 60 + 12 * 60 },
    { label: '7 days', value: 7 * 24 * 60 }
];

const AddPrebuiltChecklist = ({ showChecklist, toggleChecklist, toggletypesUpdated, editId }) => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [taskLoader, setTaskLoader] = useState(false);
    const [fields, setFields] = useState([]);
    const { isLoading } = useSelector((state) => state.global);
    const [timeDuration, setTimeDuration] = useState(timeOptions[3]);
    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        if (hasPermissions('Tasks', ['admin'])) {
            if (editId) {
                fetchChecklistData();
            } else {
                resetForm();
            }
        }
    }, [editId]);

    const fetchChecklistData = () => {
        if (editId) {
            const payload = {
                pre_checklist_id: editId
            };
            dispatch(setLoader(true));
            dispatch(getChecklistDetailsThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        const data = response.payload;
                        setTitle(data?.label || '');

                        setTimeDuration({
                            label: data?.duration_label,
                            value: Number(data?.duration_value)
                        });

                        const sortedData = data.items
                            ? [...data.items].sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence))
                            : [];

                        setFields(
                            sortedData
                                ? sortedData.map((obj) => ({
                                      ...obj,
                                      text: obj.label,
                                      id: obj?.pre_checklist_item_id
                                  }))
                                : []
                        );
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setTaskLoader(false);
                    dispatch(setLoader(false));
                });
        }
    };

    const handleAddInput = (value, index) => {
        const newValues = [...fields];
        newValues[index].text = value;
        setFields(newValues);
    };

    const handleAddField = () => {
        const newValues = [...fields];
        const newInput = {
            id: generateId(),
            text: ''
        };
        newValues.push(newInput);
        setFields(newValues);
    };

    const handleDelete = (id) => {
        const newValues = fields.filter((item) => item.id !== id);
        setFields(newValues);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let types = [];

        const newInputs = [...fields];
        if (newInputs.length < 1) {
            toastHandler('Please enter atleast one Checklist Item', ERROR_TYPE_ERROR);
            return;
        }

        let seqInd = 1;
        newInputs.map((input, _index) => {
            if (input.text && input.text !== '') {
                const value = input.text.trim();
                if (value !== ' ' && value !== '') {
                    types.push({
                        label: value,
                        sequence: seqInd
                    });
                    seqInd += 1;
                }
            }
        });

        const payload = {
            label: title,
            duration_label: timeDuration.label,
            duration_value: timeDuration.value.toString(),
            items: types
        };

        setTaskLoader(true);
        dispatch(createChecklistThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Successfully Added', 'success');
                    toggletypesUpdated();
                    resetForm();
                    toggleChecklist();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setTaskLoader(false);
            });
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        let types = [];

        const newInputs = [...fields];
        if (newInputs.length < 1) {
            toastHandler('Please enter atleast one Checklist Item', ERROR_TYPE_ERROR);
            return;
        }

        let seqInd = 1;
        newInputs.map((input) => {
            if (input.text && input.text !== '') {
                const value = input.text.trim();
                if (value !== ' ' && value !== '') {
                    const obj = {
                        label: input.text,
                        sequence: seqInd
                    };
                    input?.pre_checklist_item_id && (obj.pre_checklist_item_id = input?.pre_checklist_item_id);
                    types.push(obj);
                    seqInd += 1;
                }
            }
        });

        const payload = {
            pre_checklist_id: editId,
            label: title,
            duration_label: timeDuration.label,
            duration_value: timeDuration.value.toString(),
            items: types
        };

        setTaskLoader(true);
        dispatch(updateChecklistThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggletypesUpdated();
                    resetForm();
                    toggleChecklist();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setTaskLoader(false);
            });
    };

    const resetForm = () => {
        setFields([]);
        setTitle('');
        setTimeDuration(timeOptions[3]);
    };

    const onSortEnd = (oldIndex, newIndex) => {
        const newArray = arrayMoveImmutable(fields, oldIndex, newIndex);
        setFields(newArray);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Slideover
                open={showChecklist}
                setOpen={() => {
                    setTitle('');
                    setFields([]);
                    toggleChecklist();
                }}
                title={`${editId ? 'Edit' : 'Add'} Prebuilt Checklist`}
                hideBackArrow
            >
                <RoleBasedGuard module={'Tasks'} permissions={['admin']} sx="h-[50vh]">
                    <form onSubmit={editId ? handleUpdate : handleSubmit}>
                        {' '}
                        <div className="add-status-container1">
                            <div className="add-ann-form mt-3 mb-5">
                                <div>
                                    <div>
                                        <label>Time Duration</label>
                                    </div>

                                    <div className="grid grid-cols-12 items-stretch gap-2">
                                        <div className="col-span-10">
                                            <SimpleSelectMenu
                                                placeholder="Select"
                                                targetProperty="label"
                                                selectedValue={timeDuration?.value}
                                                valuePropertyName={'value'}
                                                optionsData={timeOptions || []}
                                                sx="rounded-xl my-2 py-3 ring-[#979797] bg-[#fff] !font-normal"
                                                showChipOnly={false}
                                                onChangeValue={(obj) => setTimeDuration(obj)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="my-2 ">
                                    <div>
                                        <label>Title</label>
                                    </div>

                                    <div className="grid grid-cols-12 items-stretch gap-2 mt-1">
                                        <div className="col-span-10">
                                            <Input
                                                value={title}
                                                placeholder="Enter title"
                                                sx="!rounded-xl mt-0"
                                                required
                                                maxWords={250}
                                                onChange={(e) => setTitle(e)}
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <Button
                                                title="Add Item"
                                                buttonType="button"
                                                atClick={() => handleAddField()}
                                                classes={'bg-linear rounded-xl px-3 py-4 mt-1 text-xs text-white '}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                                    {fields.map((input, index) => (
                                        <SortableItem key={'stortable_item' + index} className="dragged">
                                            <div className="mt-4 relative z-[1000]">
                                                <div>
                                                    <label>Item {index + 1}</label>
                                                </div>

                                                <div className="grid grid-cols-12 items-center gap-2">
                                                    <div className="col-span-10">
                                                        <Input
                                                            value={input.text}
                                                            placeholder="Enter title"
                                                            className="!rounded-xl"
                                                            required
                                                            maxWords={250}
                                                            onChange={(e) => handleAddInput(e, index)}
                                                        />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <div
                                                            onClick={() => handleDelete(input.id)}
                                                            className="cursor-pointer"
                                                        >
                                                            <BgDeleteIcon />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-1">
                                                        {input.sequence !== 0 ? (
                                                            <div className="cursor-grab">
                                                                <SortableKnob>
                                                                    <div>
                                                                        {/* <DragIndicatorIcon /> */}
                                                                        <Bars4Icon className="w-5" />
                                                                    </div>
                                                                </SortableKnob>
                                                            </div>
                                                        ) : (
                                                            <div className="mx-3"></div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </SortableItem>
                                    ))}
                                </SortableList>
                            </div>

                            <div className="mt-10 flex justify-center items-center bg-white py-2">
                                <button
                                    disabled={taskLoader ? true : false}
                                    type="submit"
                                    className="ann-btn text-white px-5 md:px-28 py-2 flex gap-2 items-center cursor-pointer  rounded-lg"
                                >
                                    <span>{editId ? 'Update' : 'Add'}</span>
                                    {taskLoader && <SmallLoaderWhite />}
                                </button>
                            </div>
                        </div>
                    </form>
                </RoleBasedGuard>
            </Slideover>
        </>
    );
};
export default AddPrebuiltChecklist;
