const isTouchEvent = (event) => {
    return 'touches' in event;
};

export class Point {
    x;
    y;
    timestamp;

    constructor(x, y, timestamp) {
        this.x = x;
        this.y = y;
        this.timestamp = timestamp ?? Date.now();
    }

    distanceTo(point) {
        return Math.sqrt(Math.pow(point.x - this.x, 2) + Math.pow(point.y - this.y, 2));
    }

    equals(point) {
        return this.x === point.x && this.y === point.y && this.timestamp === point.timestamp;
    }

    velocityFrom(start) {
        const timeDifference = this.timestamp - start.timestamp;

        if (timeDifference !== 0) {
            return this.distanceTo(start) / timeDifference;
        }

        return 0;
    }

    static fromPointLike({ x, y, timestamp }) {
        return new Point(x, y, timestamp);
    }

    static fromEvent(event, dpi = 1, el) {
        const target = el ?? event.target;

        if (!(target instanceof HTMLElement)) {
            throw new Error('Event target is not an HTMLElement.');
        }

        const { top, bottom, left, right } = target.getBoundingClientRect();

        let clientX, clientY;

        if (isTouchEvent(event)) {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
        } else {
            clientX = event.clientX;
            clientY = event.clientY;
        }

        let x = Math.min(Math.max(left, clientX), right) - left;
        let y = Math.min(Math.max(top, clientY), bottom) - top;

        x *= dpi;
        y *= dpi;

        return new Point(x, y);
    }
}
