import React from 'react';

export const Biglink = () => {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.0001 8.17537e-06C22.7519 -0.000120411 24.4656 0.511015 25.9311 1.47072C27.3966 2.43042 28.5501 3.79697 29.2502 5.40273C29.9502 7.00849 30.1664 8.78365 29.8721 10.5105C29.5778 12.2373 28.7859 13.8407 27.5935 15.124L27.3086 15.4149L14.2191 28.5037L14.1396 28.5787L14.0556 28.6461C12.9894 29.5653 11.6151 30.0476 10.2082 29.9963C8.80132 29.945 7.4658 29.3639 6.46929 28.3696C5.47277 27.3752 4.88888 26.041 4.83464 24.6343C4.7804 23.2276 5.25981 21.8524 6.1768 20.7842L6.40028 20.5382L6.42278 20.5217L17.3569 9.56722C17.6379 9.28559 18.0193 9.12714 18.4172 9.12672C18.8151 9.1263 19.1968 9.28394 19.4785 9.56497C19.7601 9.84599 19.9185 10.2274 19.919 10.6252C19.9194 11.0231 19.7617 11.4048 19.4807 11.6864L8.5466 22.641L8.5286 22.653C8.08729 23.1242 7.83861 23.7437 7.83162 24.3893C7.82463 25.0349 8.05984 25.6596 8.49084 26.1403C8.92185 26.621 9.51736 26.9227 10.1599 26.986C10.8024 27.0492 11.4454 26.8694 11.9618 26.4819L12.1448 26.332L12.1463 26.3335L25.1982 13.2852L25.4367 13.0377C26.4868 11.8836 27.0459 10.3657 26.9953 8.80623C26.9448 7.24671 26.2886 5.76825 25.166 4.68451C24.0433 3.60077 22.5426 2.99704 20.9822 3.0014C19.4218 3.00576 17.9245 3.61788 16.8079 4.70787L16.5754 4.94784L16.5484 4.96584L2.56059 18.959C2.42124 19.0982 2.25582 19.2086 2.07378 19.284C1.89175 19.3593 1.69666 19.398 1.49965 19.3979C1.30264 19.3979 1.10758 19.359 0.925596 19.2836C0.743613 19.2081 0.578273 19.0975 0.439018 18.9582C0.299763 18.8189 0.189319 18.6534 0.113992 18.4714C0.038665 18.2894 -6.95445e-05 18.0943 9.37315e-08 17.8973C0.000140734 17.4995 0.15833 17.118 0.439768 16.8367L14.4006 2.86912L14.4696 2.80463C15.3099 1.91725 16.3225 1.21079 17.4454 0.728523C18.5684 0.246261 19.778 -0.00164108 21.0001 8.17537e-06Z"
                    fill="url(#paint0_linear_862_1358)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_862_1358"
                        x1="16.472"
                        y1="0.37257"
                        x2="16.4495"
                        y2="30.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
