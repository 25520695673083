import React from 'react';
import { AuthorizedFolder } from 'pages/app-users/file-manager/AuthorizedFolder';
import { PdfViewer } from 'pages/app-users/file-manager/PdfViewer';
import { UsersFolder } from 'pages/app-users/file-manager/UsersFolder';
import { LegalDocuments } from './../pages/app-users/file-manager/LegalDocuments';
const { default: SignDocument } = require('pages/sign-requested-pdf/page');
const { default: RequestDocument } = require('pages/documents/page');

const { FileManager } = require('pages/app-users/file-manager/FileManager');
const { Protected } = require('./protected-routes/Protected');

const fileManagerRoutes = [
    {
        path: '/file/manager/:id',
        component: <Protected Component={FileManager} />,
        isProtected: true,
        layout: 'manager'
    },
    {
        path: '/file/manager/accountants/folder/:id',
        component: <Protected Component={UsersFolder} />,
        isProtected: true,
        layout: 'manager'
    },
    {
        path: '/file/manager/legal-documents/:id',
        component: <Protected Component={LegalDocuments} />,
        isProtected: true,
        layout: 'manager'
    },
    {
        path: '/file/manager/authorized/folder/:id',
        component: <Protected Component={AuthorizedFolder} />,
        isProtected: true,
        layout: 'manager'
    },
    {
        path: '/request/pdf/:id',
        component: <Protected Component={RequestDocument} />,
        isProtected: true,
        layout: 'header'
    },
    {
        path: '/sign/pdf/:id/:client_id',
        component: <Protected Component={SignDocument} />,
        isProtected: true,
        layout: 'header'
    },
    {
        path: '/pdf/viewer/:id',
        component: <Protected Component={PdfViewer} />,
        isProtected: true,
        layout: 'header'
    }
];

export default fileManagerRoutes;
