/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { BgTimes } from 'utills/svgs/BgTimes';
import './css/tasksCompleted.css';
import { useDispatch, useSelector } from 'react-redux';
import { SearchInput } from 'components/molecules/SearchInput';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { BUSINESS_ID, SORT_OPTIONS, taskPriorityOptions } from 'utills/globalVars';
import { getAllTaskStatusesThunk, getAllTaskTypeThunk } from 'store/workspace/workspaceTasks';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import { fetchAllCustomersForTaskThunk } from 'store/client';
import ComboboxComponent from 'components/atoms/Combobox';
import { Menu } from 'components/molecules/Menu';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import useRoleGuard from 'Hooks/useRoleGuard';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';

export const TasksCompletedOperations = ({ updateFilters, _filters }) => {
    const { data: types } = useSelector((state) => state.getAllTaskType);
    const { data: status } = useSelector((state) => state.getAllTaskStatuses);
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();

    const [sortAsc, setSortAsc] = useState(false);

    const [allowAction, setAllowAction] = useState(false);

    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        if (hasPermissions('Tasks', ['admin'])) {
            setAllowAction(true);
        }
    }, []);

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        handleInputChange({
            target: {
                name: 'sort_by',
                value: sortAsc ? 'DESC' : 'ASC'
            }
        });
    };

    useEffect(() => {
        if (!types) {
            dispatch(getAllTaskTypeThunk({ business_id, page: 1, pageSize: 1000, sort_by: 'ASC' }));
        }
    }, [types]);

    useEffect(() => {
        if (!status) {
            dispatch(getAllTaskStatusesThunk({ business_id }));
        }
    }, [status]);

    useEffect(() => {
        if (!employees) {
            dispatch(fetchRegisteredEmployeesThunk());
        }
    }, [employees]);

    const fetchCustomersList = async (payload) => {
        return await dispatch(fetchAllCustomersForTaskThunk(payload));
    };

    const [formData, setFormData] = useState({
        search_term: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        business_client_id: '',
        task_type_id: '',
        task_status_id: '',
        reference_number: '',
        sort_by: '',
        proirity: '',
        pending_tsak: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const clearFilter = () => {
        setFormData({
            search_term: '',
            employee_id: '',
            start_date: '',
            end_date: '',
            business_client_id: '',
            task_type_id: '',
            task_status_id: '',
            reference_number: '',
            sort_by: '',
            proirity: '',
            pending_tsak: ''
        });
    };

    useEffect(() => {
        updateFilters(formData);
    }, [formData]);

    const handleResetValue = (type) => {
        const payloadData = {
            ...formData,
            [type]: ''
        };
        setFormData(payloadData);
        updateFilters(payloadData);
    };

    return (
        <div>
            <div className="md:px-10 px-5 mt-5">
                <div className="tasks-operations-container">
                    <div className="flex items-center justify-start flex-wrap gap-5 mb-2">
                        <SearchInput
                            atChange={(e) => handleInputChange(e)}
                            width="300px"
                            placeholder="Search"
                            value={formData.search_term}
                        />
                        {allowAction && (
                            <div className="w-[360px]">
                                <ComboboxComponent
                                    data={employees}
                                    selectedValue={formData?.employee_id}
                                    inputsx="!font-normal !text-sm"
                                    sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                                    placeholder="Filter by employees"
                                    onChange={(obj) => {
                                        handleInputChange({
                                            target: {
                                                name: 'employee_id',
                                                value: obj[0]?.user_id || ''
                                            }
                                        });
                                        if (obj?.length === 0) {
                                            handleResetValue('employee_id');
                                        }
                                    }}
                                    limit={1}
                                    showNameOnly
                                    targetProperty="user_id"
                                />
                            </div>
                        )}
                        <div className="w-[360px]">
                            <ComboboxComponentSSR
                                fetchDataFunction={fetchCustomersList}
                                selectedValue={formData?.business_client_id}
                                removePreviousSelected
                                inputsx="!font-normal !text-sm"
                                sx="!rounded-xl !ring-gray-400 mt-0 !flex"
                                placeholder="Filter by client"
                                showNameOnly
                                limit={1}
                                onChange={(obj) => {
                                    handleInputChange({
                                        target: {
                                            name: 'business_client_id',
                                            value: obj[0]?.business_client_id || ''
                                        }
                                    });
                                    if (obj?.length === 0) {
                                        handleResetValue('business_client_id');
                                    }
                                }}
                                targetProperty="business_client_id"
                            />
                        </div>
                    </div>
                    <div className="flex  gap-3 flex-wrap mt-5">
                        <div className="default-forms w-[200px]">
                            <input
                                type="text"
                                name="reference_number"
                                onChange={handleInputChange}
                                value={formData.reference_number}
                                placeholder="Client Reference No."
                                className="!text-sm !text-gray-900 !border-gray-400 !font-normal px-3 py-1.5 bg-[#FAF9F6] w-full"
                            />
                        </div>
                        <div className="w-[200px]">
                            <SimpleSelectMenu
                                placeholder="Task Status"
                                targetProperty="label"
                                selectedValue={formData?.task_status_id}
                                optionsData={status || []}
                                showChipOnly
                                sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                                onChangeValue={(obj) =>
                                    handleInputChange({
                                        target: {
                                            name: 'task_status_id',
                                            value: obj ? obj?.task_status_id : ''
                                        }
                                    })
                                }
                            />
                        </div>
                        <div className="w-[200px]">
                            <SimpleSelectMenu
                                placeholder="Task type"
                                targetProperty="label"
                                selectedValue={formData?.task_type_id}
                                optionsData={types?.rows || []}
                                showChipOnly
                                sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                                onChangeValue={(obj) =>
                                    handleInputChange({
                                        target: {
                                            name: 'task_type_id',
                                            value: obj ? obj?.task_type_id : ''
                                        }
                                    })
                                }
                            />
                        </div>

                        <div className="w-[200px]">
                            <Menu
                                title="Order By Date"
                                iconSet={{ first: SortIcon, second: StepDownIcon }}
                                options={SORT_OPTIONS}
                                gap={1}
                                lastMl={1}
                                isModalDisabled={true}
                                condition={sortAsc}
                                atMenuClick={() => {
                                    handleSortByDate();
                                }}
                                sx={'!w-full justify-evenly'}
                                atClick={(_title) => {}}
                                text="sm"
                            />
                        </div>
                        <div>
                            <div className="w-[200px]">
                                <DatePick
                                    iconSet={{
                                        right: CalendarSmallIco
                                    }}
                                    onDateChange={(date) => {
                                        setFormData({
                                            ...formData,
                                            start_date: date
                                        });
                                    }}
                                    placeholder="Select From"
                                    value={formData?.start_date || ''}
                                    sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#FAF9F6] hover:bg-[#FAF9F6]"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="w-[200px]">
                                <DatePick
                                    iconSet={{
                                        right: CalendarSmallIco
                                    }}
                                    onDateChange={(date) => {
                                        setFormData({
                                            ...formData,
                                            end_date: date
                                        });
                                    }}
                                    placeholder="Select To"
                                    value={formData?.end_date || ''}
                                    sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 w-full bg-[#FAF9F6] hover:bg-[#FAF9F6]"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="w-[200px]">
                                <SimpleSelectMenu
                                    placeholder="Task Priority"
                                    targetProperty="label"
                                    selectedValue={formData?.proirity}
                                    optionsData={taskPriorityOptions || []}
                                    sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6] !font-normal"
                                    onChangeValue={(obj) =>
                                        handleInputChange({
                                            target: {
                                                name: 'proirity',
                                                value: obj ? obj?.label : ''
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>

                        {formData.search_term ||
                        formData?.start_date ||
                        formData?.end_date ||
                        formData.task_status_id ||
                        formData?.task_type_id ||
                        formData?.business_client_id ||
                        formData?.reference_number ||
                        formData?.proirity ? (
                            <button
                                onClick={clearFilter}
                                className="flex items-center gap-2 rounded-xl px-4 py-2.5 bg-gray-200 text-md "
                            >
                                <BgTimes /> Clear Filter
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
