import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setSideLoader } from 'store/global/globalReducer';
import { getCookie } from 'utills/encryption/cryptoUtils';
import { APP_ENVIRONMENT, BASE_URL, CSRF_TOKEN } from 'utills/globalVars';

export const UseDownloadCsv = () => {
    const dispatch = useDispatch();
    const handleDownloadCsv = async (payload, url) => {
        let csrfToken = getCookie(CSRF_TOKEN);

        dispatch(setSideLoader(true));
        const _response = await fetch(`${BASE_URL}/${url ? url : 'exp-inc/download/csv'}`, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            credentials: 'include',
            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = payload?.ref_no ? `${payload?.ref_no}_file` : 'file';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    toastHandler('File has been downloaded', 'success');
                } else {
                    toastHandler('Something went wrong!', 'error');
                }
            })
            .catch((_error) => {
                toastHandler('Something went wrong!', 'error');
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    return { handleDownloadCsv };
};
