import { useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';

export const usePresistFilters = () => {
    const params = new URLSearchParams(window.location.search);
    const [page_p, setPage_p] = useState(parseInt(params.get('page') || '1', 10));
    const [pageSize_p, setPageSize_p] = useState(parseInt(params.get('page_size') || `${PAGINATION_PAGE_SIZE}`, 10));

    // Initialize state from URL on component mount
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setPage_p(parseInt(params.get('page') || '1', 10));
        setPageSize_p(parseInt(params.get('page_size') || '10', 10));
    }, []);
    // Function to update URL without refreshing
    const updateUrlParams = (newPage, newPageSize) => {
        const params = new URLSearchParams();
        params.set('page', newPage);
        params.set('page_size', newPageSize);
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    };

    const setPresistFilter = (name, filters) => {
        const obj = {
            name,
            filters
        };
        sessionStorage.setItem('filters', JSON.stringify(obj));
    };
    const getPresistFilters = (name) => {
        const obj = sessionStorage.getItem('filters');
        const parsedObj = JSON.parse(obj);
        if (parsedObj && parsedObj?.name === name) {
            return parsedObj;
        } else {
            return false;
        }
    };

    const clearPresistFilter = () => {
        sessionStorage.removeItem('filters');
    };
    return { page_p, pageSize_p, updateUrlParams, setPresistFilter, getPresistFilters, clearPresistFilter };
};
