import React from 'react';

export const TxtIcon = ({ className }) => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#0A1E46"
            />
            <rect y="8" width="34" height="16.79" rx="4" fill="white" />
            <mask id="mask0_15913_302" maskUnits="userSpaceOnUse" x="0" y="8" width="34" height="17">
                <path
                    d="M0.540527 8H33.4385C33.7507 8 34 8.35953 34 8.80696V24.195C34 24.6425 33.7507 25 33.4385 25H0.540527C0.249259 25 0 24.6425 0 24.195V8.80696C0 8.35953 0.249259 8 0.540527 8Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_15913_302)">
                <path
                    d="M35.9999 23.084V10.916C36.0023 10.5324 35.9282 10.1522 35.7817 9.79732C35.6352 9.44246 35.4194 9.12005 35.1467 8.8488C34.874 8.57755 34.5499 8.36285 34.1932 8.21715C33.8364 8.07146 33.4542 7.99767 33.0685 8.00006H0.931476C0.545846 7.99767 0.163575 8.07146 -0.193166 8.21715C-0.549907 8.36285 -0.87402 8.57755 -1.14671 8.8488C-1.41939 9.12005 -1.63523 9.44246 -1.7817 9.79732C-1.92816 10.1522 -2.00235 10.5324 -1.99994 10.916V23.084C-2.00235 23.4676 -1.92816 23.8478 -1.7817 24.2027C-1.63523 24.5575 -1.41939 24.8799 -1.14671 25.1512C-0.87402 25.4225 -0.549907 25.6371 -0.193166 25.7828C0.163575 25.9285 0.545846 26.0023 0.931476 25.9999H33.0685C33.4542 26.0023 33.8364 25.9285 34.1932 25.7828C34.5499 25.6371 34.874 25.4225 35.1467 25.1512C35.4194 24.8799 35.6352 24.5575 35.7817 24.2027C35.9282 23.8478 36.0023 23.4676 35.9999 23.084ZM12.0419 13.76H9.18288V21.698H7.59051V13.76H4.73146V12.302H11.9695L12.0419 13.76ZM19.28 21.698L16.9638 18.098L14.6476 21.698H12.6572L15.8962 16.874L12.8924 12.302H14.8648L17 15.47L19.099 12.302H21.0714L18.0676 16.874L21.3067 21.698H19.28ZM29.1962 13.76H26.4095V21.698H24.8171V13.76H21.9581V12.302H29.1962V13.76Z"
                    fill="#0A1E46"
                />
            </g>
        </svg>
    );
};
