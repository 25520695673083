/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useGetFiles } from 'Hooks/Files/useGetFiles';
import 'driver.js/dist/driver.css';

import { FilesTable } from 'components/appusers-dash/app-user-profile/file-manager-pages/FilesTable';
import { UploadFile } from 'components/appusers-dash/app-user-profile/file-manager-pages/UploadFile';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { UploadWhiteIcon } from 'utills/svgs/UploadWhiteIcon';
import { filterByType } from 'utills/filesHandlers';
import moment from 'moment';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { Button } from 'components/atoms/buttons/Button';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { useUploadFile } from 'Hooks/Files/uploadFile';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { deleteFileThunk } from 'store/fileManager';
import { Loader } from 'components/common/Loader';
import ConfirmationModal from 'utills/confirmationModal';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import {
    ACCOUNTANTS_FOLDER,
    PERMISSIONS_MESSAGE,
    TOAST_TYPE_ERROR,
    all_files_types,
    clientTypes,
    getProfileName
} from 'utills/globalVars';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import dayjs from 'dayjs';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const UsersFolder = () => {
    const { uploadFileHanlder, fileLoader } = useUploadFile();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: loginData } = useSelector((state) => state.login);

    const { hasPermissions } = useRoleGuard();

    const [searchTerm, setSearchTerm] = useState('');
    const { id } = useParams();
    const { files } = useGetFiles(ACCOUNTANTS_FOLDER, id);
    const [filesData, setFilesData] = useState([]);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [filterDate, setFilterDate] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [filterOwnerType, setFilterOwnerType] = useState(null);

    const { data: customer } = useSelector((state) => state?.viewBusinessCustomerInfo);

    const [deleteIds, setDeleteIds] = useState([]);

    const { isLoading } = useSelector((state) => state.global);
    const handleSelectedFiles = (files) => {
        setSelectedFiles([...selectedFiles, ...files]);
    };
    //const acceptFormate = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

    const cancelAction = () => {
        setSelectedFiles([]);
    };
    const uploadFiles = async () => {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            const res = await uploadFileHanlder(selectedFiles, ACCOUNTANTS_FOLDER, id);
            if (res) {
                setFilesData([...res, ...filesData]);
                setSelectedFiles([]);
            }
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };

    const filterBydate = (term) => {
        setFilterDate(term);

        const formatedDate = moment(term).format('DD-MM-YYYY').toString();

        const result = searchTerm
            ? files?.filter(
                  (item) =>
                      item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                      item?.employee?.first_name?.includes(searchTerm?.toLowerCase()) ||
                      item?.employee?.last_name?.includes(searchTerm?.toLowerCase())
              )
            : files;

        const filteredData =
            term && term !== 'Invalid Date'
                ? result?.filter((item) => {
                      const formatedCreatedAt = moment(item.created_at, 'DD-MM-YYYY HH:mm:ss Z').format('DD-MM-YYYY');

                      return formatedDate === formatedCreatedAt;
                  })
                : result;

        setFilesData(filteredData);
    };
    const toggleUploadFile = () => {
        setShowUploadFile(!showUploadFile);
    };

    const filterDataHandler = (value) => {
        setSearchTerm(value);

        const formatedDate = moment(selectedDate).format('DD-MM-YYYY').toString();

        const newResult = selectedDate
            ? files?.filter((item) => {
                  const formatedCreatedAt = moment(item.created_at, 'DD-MM-YYYY HH:mm:ss Z').format('DD-MM-YYYY');

                  return formatedDate === formatedCreatedAt;
              })
            : filterOwnerType
            ? files?.filter((item) => {
                  if (item?.uploaded_by === 'employee') {
                      if (filterOwnerType === 'Me') {
                          return item?.employee?.user_id === loginData?.user_id;
                      } else {
                          return item?.employee?.user_id !== loginData?.user_id;
                      }
                  }
              })
            : filterType
            ? filterByType(files, filterType)
            : files;

        const result = newResult?.filter(
            (item) =>
                item?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
                item?.employee?.first_name?.includes(value?.toLowerCase()) ||
                item?.employee?.last_name?.includes(value?.toLowerCase())
        );

        setFilesData(result);
    };

    const handleFilterByType = (term) => {
        const formatedDate = moment(selectedDate).format('DD-MM-YYYY').toString();

        setFilterType(term);
        const result = filterByType(files, term);

        const updatedResult = selectedDate
            ? result?.filter((item) => {
                  const formatedCreatedAt = moment(item.created_at, 'DD-MM-YYYY HH:mm:ss Z').format('DD-MM-YYYY');

                  return formatedDate === formatedCreatedAt;
              })
            : result;
        setFilesData(updatedResult);
    };

    useEffect(() => {
        if (files) {
            setFilesData(files);
        }
    }, [files]);

    const managerActions = [
        {
            title: 'Upload Document',
            classes: 'flex gap-2 items-center px-3 ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: UploadWhiteIcon },
            onAction: () => {
                toggleUploadFile();
            }
        }
    ];

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/file/manager/${id}`);
        }
    };

    const handleClearFilter = () => {
        setFilterDate('');
        setFilterType('');
        setFilterOwnerType('');
        setSelectedDate('');

        setFilesData(files);
    };

    const handleSortByOwner = (type) => {
        setFilterOwnerType(type);
        const formatedDate = moment(selectedDate).format('DD-MM-YYYY').toString();

        const result = selectedDate
            ? files?.filter((item) => {
                  const formatedCreatedAt = moment(item.created_at, 'DD-MM-YYYY HH:mm:ss Z').format('DD-MM-YYYY');

                  return formatedDate === formatedCreatedAt;
              })
            : searchTerm
            ? files?.filter(
                  (item) =>
                      item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                      item?.employee?.first_name?.includes(searchTerm?.toLowerCase()) ||
                      item?.employee?.last_name?.includes(searchTerm?.toLowerCase())
              )
            : files;

        const filteredData = result?.filter((item) => {
            if (item?.uploaded_by === 'employee') {
                if (type === 'Me') {
                    return item?.employee?.user_id === loginData?.user_id;
                } else {
                    return item?.employee?.user_id !== loginData?.user_id;
                }
            }
        });

        if (filteredData) {
            setFilesData(filteredData);
        }
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        const ids = [...deleteIds];

        dispatch(setLoader(true));
        dispatch(deleteFileThunk({ file_manager_id: ids }))
            .then((response) => {
                if (response.payload) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const handleDeleteAll = () => {
        if (hasPermissions('Clients', ['write', 'admin'])) {
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    return (
        <SidebarLayout>
            <div>
                {' '}
                <div>
                    <div className="app-dashboard relative  ">
                        <ConfirmationModal
                            isOpen={isConfirmationOpen}
                            onClose={() => setIsConfirmationOpen(false)}
                            onConfirm={handleDelete}
                        />
                        {isLoading && <Loader />}
                        {showUploadFile && (
                            <UploadFile
                                message="This file type is not allowed"
                                toggleUploadFile={toggleUploadFile}
                                files={filesData}
                                setFilesData={setFilesData}
                                // acceptFormate={acceptFormate}
                                acceptFormate={all_files_types}
                            />
                        )}

                        <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                            {' '}
                            <div>
                                <Jumbotron
                                    title="Accountant's Folder"
                                    path={[
                                        { title: 'Clients', link: 'clients' },
                                        {
                                            title:
                                                customer && clientTypes[customer?.client_type] !== 'All'
                                                    ? clientTypes[customer?.client_type]
                                                    : 'All Clients',
                                            link: `app/users?type=${
                                                customer
                                                    ? clientTypes[customer?.client_type]?.[0]?.toLowerCase() +
                                                      clientTypes[customer?.client_type]?.slice(1)
                                                    : 'all'
                                            }&reload=true`
                                        },
                                        {
                                            title: `${getProfileName(customer)}`,
                                            link: `app/user/profile/${id}`
                                        },
                                        { title: 'Document Hub', link: `file/manager/${id}` },
                                        { title: "Accountant's Folder", link: '' }
                                    ]}
                                    actionsRequired={true}
                                    actions={customer?.status === 'active' ? managerActions : []}
                                    backArrowProp={backArrowProp}
                                />
                                <div className="mb-5 mx-5 md:mx-10">
                                    {customer?.status === 'active' ? (
                                        <>
                                            <div className="drag-file-indicate">
                                                <DragFile
                                                    selectedFiles={selectedFiles}
                                                    atChange={(file) => {
                                                        handleSelectedFiles(file);
                                                    }}
                                                    accept={all_files_types}
                                                    isMultiple={true}
                                                    dragMessage="Drag files here to upload, or browse for files"
                                                    dropMessage="Drop the selected files in this box... "
                                                    onlyPdf={false}
                                                    message="This file type is not allowed"
                                                />
                                            </div>
                                            <div className="mt-2">
                                                {selectedFiles?.length > 0 && (
                                                    <div className="flex justify-end items-center">
                                                        <div className="flex gap-2 items-center">
                                                            <Button
                                                                classes="border-button h-size8 px-10 text-secondarybg"
                                                                title="Cancel"
                                                                atClick={() => {
                                                                    cancelAction();
                                                                }}
                                                                buttonType="button"
                                                            />
                                                            <Button
                                                                classes="ann-btn h-size8 px-10"
                                                                title="Upload"
                                                                atClick={() => {
                                                                    uploadFiles();
                                                                }}
                                                                buttonType="button"
                                                                iconSet={{ loader: SmallLoaderWhite }}
                                                                isloading={fileLoader}
                                                                disabled={fileLoader ? true : false}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : !isLoading ? (
                                        <div className="border-l-4 border-red-400 bg-red-50 p-4">
                                            <div className="flex">
                                                <div className="flex-shrink-0">
                                                    <InformationCircleIcon
                                                        className="h-5 w-5 text-red-400"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-sm font-medium text-red-800">
                                                        Client Account has been Removed
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="md:mx-10 mx-5">
                                    <div className="flex justify-between items-center flex-wrap gap-5">
                                        {' '}
                                        <div>
                                            <SearchInput
                                                width="300px"
                                                atChange={(e) => {
                                                    filterDataHandler(e.target.value);
                                                }}
                                                value={searchTerm}
                                                placeholder="Search"
                                                inputBorder="1px solid #979797"
                                            />
                                        </div>
                                        <div className="flex gap-2 items-center flex-wrap">
                                            {deleteIds?.length > 0 && (
                                                <Button
                                                    classes="ann-btn h-size8 px-12"
                                                    title={`Delete ( ${deleteIds?.length} )`}
                                                    atClick={() => {
                                                        handleDeleteAll();
                                                    }}
                                                    buttonType="button"
                                                    disabled={isLoading ? true : false}
                                                />
                                            )}
                                            <div>
                                                <DatePick
                                                    maxDate={dayjs()}
                                                    iconSet={{
                                                        right: CalendarSmallIco
                                                    }}
                                                    onDateChange={(date) => {
                                                        filterBydate(dayjs(date, 'DD-MM-YYYY').format('DD-MM-YYYY'));
                                                        setSelectedDate(dayjs(date, 'DD-MM-YYYY').format('DD-MM-YYYY'));
                                                    }}
                                                    placeholder="Select Date"
                                                    value={selectedDate ? dayjs(selectedDate, 'DD-MM-YYYY') : null}
                                                    sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full  bg-[#FAF9F6]"
                                                />
                                            </div>

                                            <div className="w-[200px]">
                                                {' '}
                                                <SimpleSelectMenu
                                                    placeholder="Filter by owner"
                                                    targetProperty="value"
                                                    optionsData={[{ value: 'Me' }, { value: 'Others' }] || []}
                                                    bg="#FAF9F6"
                                                    sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6]"
                                                    selectedValue={filterOwnerType}
                                                    onChangeValue={(obj) => {
                                                        handleSortByOwner(obj?.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="w-[200px]">
                                                {' '}
                                                <SimpleSelectMenu
                                                    placeholder="Filter by file type"
                                                    targetProperty="value"
                                                    optionsData={
                                                        [{ value: 'jpg' }, { value: 'pdf' }, { value: 'png' }] || []
                                                    }
                                                    selectedValue={filterType}
                                                    bg="#FAF9F6"
                                                    sx="rounded-xl py-3 ring-gray-400 bg-[#FAF9F6]"
                                                    onChangeValue={(obj) => {
                                                        handleFilterByType(obj?.value);
                                                    }}
                                                />
                                            </div>

                                            {filterDate || filterType || filterOwnerType ? (
                                                <div>
                                                    <ClearFilterButton
                                                        atClick={() => {
                                                            handleClearFilter();
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <FilesTable
                                            files={filesData}
                                            setFilesData={setFilesData}
                                            currentFolder={ACCOUNTANTS_FOLDER}
                                            deleteIds={deleteIds}
                                            setDeleteIds={setDeleteIds}
                                        />
                                    </div>
                                </div>
                            </div>
                        </RoleBasedGuard>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};
