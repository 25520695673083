import React from 'react';
import LostGoogleAuthPage from '../../components/dashboard-components/auth/lost-two-fa-pages/LostGoogleAuthPage';
const LostGoogleAuth = () => {
    return (
        <div>
            <LostGoogleAuthPage />
        </div>
    );
};

export default LostGoogleAuth;
