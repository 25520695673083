import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID, ERROR_TYPE_ERROR, ERROR_TYPE_SUCCESS, USER_TYPE, acceptFormate } from 'utills/globalVars';
import { setSideLoader } from 'store/global/globalReducer';
import { addThreadThunk } from 'store/workspace/workspaceThreads';
import { toastHandler } from 'responseHanlder';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import Slideover from 'components/atoms/SlideOver';
import { DescriptionInput } from 'components/molecules/DescriptionInput';
import { UploadPhotoIcon } from 'utills/svgs/UploadPhotoIcon';
import { DragFile } from 'components/atoms/inputs/DragFile';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';
import useRoleGuard from 'Hooks/useRoleGuard';

const MyNewThread = ({ toggleMyNewThread, toggleThreadUpdate, open, setOpen }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { sideLoader } = useSelector((state) => state.global);

    const { hasPermissions } = useRoleGuard();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedPdfs, setSelectedPdfs] = useState([]);

    const handleSelectedFiles = (files) => {
        setSelectedFiles([...selectedFiles, ...files]);
    };

    const handleSelectedPdf = (files) => {
        setSelectedPdfs([...selectedPdfs, ...files]);
    };

    const pdfacceptFormate = ['application/pdf'];

    const addNewThread = async () => {
        if (!title || !description) {
            toastHandler('Please fill in required fields', ERROR_TYPE_ERROR);
            return;
        }

        const formData = new FormData();
        formData.append('business_id', business_id);
        formData.append('title', title.trim());
        formData.append('description', description.trim());
        formData.append('uploaded_by', USER_TYPE);
        selectedFiles.forEach((file, _index) => {
            formData.append('thread_files', file);
        });

        const sanitizedFilesPromises = selectedPdfs.map((file) => createNewPDFWithoutMetadata(file));
        const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
        sanitizedFiles.forEach((sanitizedFile) => {
            formData.append('thread_files', sanitizedFile);
        });

        dispatch(setSideLoader(true));
        dispatch(addThreadThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toggleThreadUpdate();
                    toggleMyNewThread();
                    toastHandler('Thread added successfully', ERROR_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    return (
        <Slideover
            title="Add Thread"
            open={open}
            setOpen={setOpen}
            footer={
                <div className="flex justify-center items-center mt-10">
                    {' '}
                    {hasPermissions('Threads', ['admin']) && (
                        <button
                            onClick={addNewThread}
                            className="ann-btn  px-8 buttons-font-lighter cursor-pointer  rounded-xl"
                        >
                            {sideLoader ? <SmallLoaderWhite /> : 'Create Thread'}
                        </button>
                    )}
                </div>
            }
        >
            <div>
                <div>
                    <RoleBasedGuard module={'Threads'} permissions={['admin']} sx="h-[50vh]">
                        {' '}
                        <div className="px-5 ">
                            <div className="add-ann-form">
                                <div>
                                    <label>Title</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setTitle(e.target.value)}
                                        type="text"
                                        placeholder="Title"
                                        maxLength={40}
                                    />
                                </div>
                            </div>

                            <div className="mt-2">
                                <DescriptionInput
                                    title="Description"
                                    placeholder="Enter thread description"
                                    onChange={(e) => setDescription(e)}
                                    value={description}
                                />
                            </div>

                            <div className="mt-3">
                                <div className="mb-2 buttons-font font-poppins">Select Images</div>
                                <DragFile
                                    atChange={(file) => {
                                        handleSelectedFiles(file);
                                    }}
                                    iconSet={{ icon: UploadPhotoIcon }}
                                    accept={acceptFormate}
                                    selectedFiles={selectedFiles}
                                    isMultiple={true}
                                    hideSelectedFiles
                                    dragMessage="Drag files here to upload image, or browse files"
                                    dropMessage="Drop the selected files in this box... "
                                    onlyPdf={false}
                                    message="Only images are allowed"
                                />
                            </div>

                            <SelectedFiles
                                files={selectedFiles}
                                updateSelectedFiles={(files) => setSelectedFiles(files || [])}
                            />

                            <div className="mt-3">
                                <div className="mb-2 buttons-font font-poppins">Select Pdf</div>
                                <DragFile
                                    atChange={(file) => {
                                        handleSelectedPdf(file);
                                    }}
                                    accept={pdfacceptFormate}
                                    hideSelectedFiles
                                    selectedFiles={selectedPdfs}
                                    isMultiple={true}
                                    dragMessage="Drag files here to upload pdf, or browse files"
                                    dropMessage="Drop the selected files in this box... "
                                    onlyPdf={false}
                                    message="Only Pdf files are allowed"
                                />
                            </div>

                            <SelectedFiles
                                files={selectedPdfs}
                                updateSelectedFiles={(files) => setSelectedPdfs(files || [])}
                            />
                        </div>
                    </RoleBasedGuard>
                </div>
            </div>
        </Slideover>
    );
};
export default MyNewThread;
