import React, { useEffect, useState } from 'react';
import { BUSINESS_ID, PLACES_API_KEY, TOAST_TYPE_ERROR } from 'utills/globalVars';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utills/FormValidation';

import { updateBranchThunk } from 'store/auth/slices';
import { useGetAllBranches } from 'Hooks/useGetAllBranches';
import Slideover from 'components/atoms/SlideOver';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import MapComponent from 'components/atoms/MapComponent';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue } from 'utills/dataValidation';

export const EditBranch = ({ open, setOpen, toggleEditBranch }) => {
    const { branch } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const [title, setTitle] = useState(branch?.title);
    const [phone_number, setPhoneNo] = useState(branch?.phone_number);
    const [email, setEmail] = useState(branch?.email);
    const [address, setAddress] = useState(branch?.address);
    const [mapValues, setMapValues] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const { getBranches } = useGetAllBranches();
    const business_id = localStorage.getItem(BUSINESS_ID);

    useEffect(() => {
        if (branch) {
            setMapValues({
                geometry: {
                    location: {
                        lat: () => branch?.lat || '',
                        lng: () => branch?.lng || ''
                    }
                },
                formatted_address: branch?.formatted_address || ''
            });
        }
    }, [branch]);

    const handleEmail = (e) => {
        const value = e.target.value;
        if (!validateEmail(value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        setEmail(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (title.length > 30) {
            toastHandler('Title should be less than 30 character', TOAST_TYPE_ERROR);
            return false;
        }

        if (!email) {
            toastHandler('Email is required.', TOAST_TYPE_ERROR);
            return;
        }
        if (email.length > 40) {
            toastHandler('Email should be less than 40 character', TOAST_TYPE_ERROR);
            return;
        }

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (phone_number) {
            if (!validatePhoneNumber(phone_number)) {
                return false;
            }
        }

        if (!address) {
            toastHandler('Adress is required.', TOAST_TYPE_ERROR);
            return false;
        }
        if (address.length > 100) {
            toastHandler('Adress should be less than 100 character', TOAST_TYPE_ERROR);
            return false;
        }

        const payload = {
            title,
            phone_number,
            email,
            address,
            branch_id: branch?.business_branch_id,
            business_id: business_id,
            lat: (mapValues && mapValues?.geometry?.location?.lat()?.toString()) || '0',
            lng: (mapValues && mapValues?.geometry?.location?.lng()?.toString()) || '0',
            formatted_address: (mapValues && mapValues?.formatted_address) || ''
        };
        dispatch(setLoader(true));
        dispatch(updateBranchThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Updated Successfully!', 'success');
                    getBranches();
                    toggleEditBranch();
                }
                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <div>
            <Slideover title="Update Branch Location" open={open} setOpen={setOpen} showLoader>
                <div className="service-info  p-5">
                    <form onSubmit={handleSubmit}>
                        {' '}
                        <div className="add-ann-form mt-5">
                            <div>
                                <div>
                                    <label className="buttons-font font-poppins">Branch Title</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setTitle(e.target.value)}
                                        value={title}
                                        name="branchTitle"
                                        type="text"
                                        maxLength="60"
                                        placeholder="Branch Title"
                                        className="px-3 rounded-xl inputs-font font-poppins mt-1"
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label>Phone Number</label>
                                </div>
                                <div>
                                    <PhoneInputComponent
                                        placeholder="Enter Phone Number"
                                        titleSx="buttons-font font-poppins"
                                        sx="!rounded-xl inputs-font font-poppins"
                                        atChange={(phone) => setPhoneNo(phone)}
                                        value={phone_number}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label className="buttons-font font-poppins">Email</label>
                                </div>
                                <div>
                                    <input
                                        onChange={handleEmail}
                                        value={email}
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        className="px-3 rounded-xl inputs-font font-poppins mt-1"
                                    />

                                    {email !== '' ? (
                                        <div className="error-div mt-1">
                                            {emailError && <span> Invalid Email </span>}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>

                            <div className="mt-4">
                                <div>
                                    <label>Address</label>
                                </div>
                                <div>
                                    <ReactGoogleAutocomplete
                                        apiKey={PLACES_API_KEY}
                                        onChange={(e) => setAddress(e.target.value)}
                                        defaultValue={address}
                                        className="px-3 rounded-xl inputs-font font-poppins mt-1"
                                        placeholder="Enter your business address"
                                        onPlaceSelected={(place) => {
                                            setAddress(place.formatted_address);
                                            setMapValues(place);
                                        }}
                                    />
                                </div>
                            </div>
                            {mapValues && (
                                <div className="mt-2">
                                    <div>
                                        <label>Map</label>
                                    </div>
                                    <div>
                                        <MapComponent addressData={mapValues} className={'rounded-xl mt-2'} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=" flex justify-center items-center mt-20">
                            <button
                                type="submit"
                                className="px-20 ann-btn buttons-font-lighter font-fira py-2 rounded-md text-white"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </Slideover>
        </div>
    );
};
