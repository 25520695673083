/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { NoDataFound } from 'components/common/NoDataFound';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CopyIcon2 } from 'utills/svgs/CopyIcon2';
import { DownloadIcon2 } from 'utills/svgs/DownloadIcon2';
import { FolderIcon } from 'utills/svgs/file-icons/FolderIcon';
import { MoveIcon } from 'utills/svgs/MoveIcon';
import { RenameIcon } from 'utills/svgs/RenameIcon';
import { SignIcon } from 'utills/svgs/SignIcon';
import { cn } from 'utills/tailwindUtil';
import EntityCreateUpdate from './EntityCreateUpdate';
import MoveOrCopy from './MoveOrCopy';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { FileDropZone } from 'components/atoms/DropFilesZone/FileDropZone';
import { DateFormatToLocal } from 'utills/moment';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import { downloadFile, formatFileSize } from 'utills/filesHandlers';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { toastHandler } from 'responseHanlder';
import {
    acceptFilesFormats,
    acceptFormate,
    all_files_types,
    getProfileName,
    TOAST_TYPE_ERROR
} from 'utills/globalVars';
import useRoleGuard from 'Hooks/useRoleGuard';

const JobPortalDocumentsTable = ({
    isLoading,
    data,
    selectedIds,
    setSelectedIds,
    handleSelectId,
    filters,
    setFilters,
    getData,
    getMainTableData,
    handleSubmitFiles,
    handleDeleteItem,
    handleDownlaodItems,
    disabled = false
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedPdf, setSelectedPdf] = useState('');

    const { hasPermissions } = useRoleGuard();
    const [dataList, setDataList] = useState([]);

    const checkbox = useRef();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [editEntity, setEditEntity] = useState(null);
    const [CutORCopy, setCutORCopy] = useState(null);

    const dropdownRef = useRef(null);
    const [isDropUp, setIsDropUp] = useState(false);
    const { globalLoading } = useSelector((state) => state.jobManagerDocReducer);

    useEffect(() => {
        const handlePosition = () => {
            if (dropdownRef.current) {
                const dropdownRect = dropdownRef.current.getBoundingClientRect();
                const isNearBottom = dropdownRect.bottom + 200 > window.innerHeight;
                setIsDropUp(isNearBottom);
            }
        };

        handlePosition();
        window.addEventListener('resize', handlePosition);
        return () => {
            window.removeEventListener('resize', handlePosition);
        };
    }, []);

    useLayoutEffect(() => {
        const isIndeterminate = selectedIds.length > 0 && selectedIds.length < data?.length;
        setChecked(data?.length > 0 && selectedIds.length === data?.length);
        setIndeterminate(isIndeterminate);
        checkbox.current.indeterminate = isIndeterminate;
    }, [selectedIds]);

    function toggleAll() {
        const newIds = [];
        data?.forEach((obj) => {
            newIds.push(obj);
        });

        setSelectedIds(checked || indeterminate ? [] : newIds);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    useEffect(() => {
        if (data) {
            setDataList(data);
        }
    }, [data]);

    const updateFiltersObject = (id, value) => {
        setFilters({
            ...filters,
            order_by: {
                name: 'none',
                size: 'none',
                owner: 'none',
                updated_at: 'none',
                [id]: value
            }
        });
    };
    const RenderSortByArrows = (name, value = '', id) => {
        return (
            <div className="flex items-center justify-start gap-1">
                <label
                    htmlFor={id + '_sort'}
                    onClick={() => {
                        updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                    }}
                    className="cursor-pointer"
                >
                    {name}
                </label>
                {value === 'ASC' ? (
                    <ArrowDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'DESC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : value === 'DESC' ? (
                    <ArrowUpIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'ASC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : (
                    <ArrowsUpDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                        }}
                        className="w-4 h-4 text-[#979797] cursor-pointer"
                    />
                )}
            </div>
        );
    };

    const singleFileOptions = [
        {
            name: 'Rename',
            icon: <RenameIcon />,
            allowedTypes: ['file', 'folder'],
            onClick: (file) => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setEditEntity(file);
            }
        },
        {
            name: 'Copy and Paste',
            icon: <CopyIcon2 />,
            allowedTypes: ['file', 'folder'],
            onClick: (file) => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setCutORCopy({
                    list: [file],
                    open: true,
                    title: 'Copy and Paste',
                    action: 'copy'
                });
            }
        },
        {
            name: 'Cut and Move',
            icon: <CopyIcon2 />,
            allowedTypes: ['file', 'folder'],
            onClick: (file) => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setCutORCopy({
                    list: [file],
                    open: true,
                    title: 'Cut and Move',
                    action: 'cut'
                });
            }
        },
        {
            name: 'Move to Client Folder',
            icon: <MoveIcon />,
            allowedTypes: ['file', 'folder'],
            onClick: (file) => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                setCutORCopy({
                    list: [file],
                    open: true,
                    title: 'Move to Client Folder',
                    action: 'moveToClient'
                });
            }
        },
        {
            name: 'Request Signature',
            icon: <SignIcon />,
            allowedTypes: ['pdf'],
            onClick: (file) => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                navigate(`/job-manager/request-signature/${id}`, { state: [file] });
            }
        },
        {
            name: 'Download',
            icon: <DownloadIcon2 />,
            allowedTypes: ['file', 'folder'],
            onClick: (file) => {
                if (!hasPermissions('Job', ['write', 'read', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDownlaodItems([file]);
            }
        },
        {
            name: 'Delete',
            icon: <DeleteIcon className={'w-5'} />,
            allowedTypes: ['file', 'folder'],
            onClick: (file) => {
                if (!hasPermissions('Job', ['write', 'admin'])) {
                    return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
                }
                handleDeleteItem([file?.job_drive_id]);
            }
        }
    ];

    const generatePath = (path) => {
        let newPath = path?.split('Jobdocuments');
        if (newPath?.length > 0) {
            return 'Job Documents' + newPath.at(-1);
        }
        return 'Job Documents';
    };

    const handleInternalClick = async (obj) => {
        await getData(obj);
    };

    return (
        <div className="mt-5 overflow-x-auto h-[650px]">
            {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
            {selectedPdf ? (
                <GlobalPDFViewer
                    url={selectedPdf}
                    setOpen={() => setSelectedPdf('')}
                    open={selectedPdf ? true : false}
                />
            ) : (
                ''
            )}{' '}
            <FileDropZone
                accept={all_files_types} // Allow images, PDFs, and CSVs
                multiple={true} // Allow multiple file uploads
                onFilesDrop={(files) => handleSubmitFiles(files)} // Callback to handle the dropped files
            >
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className={
                                    'relative buttons-font primary-fonts p-4 !px-0 !min-w-[40px] !w-[40px] text-center'
                                }
                            >
                                <input
                                    type="checkbox"
                                    className="rounded-[3px] cursor-pointer"
                                    ref={checkbox}
                                    checked={checked}
                                    onChange={toggleAll}
                                />
                            </th>
                            <th
                                scope="col"
                                className={
                                    'buttons-font primary-fonts !pl-[10px] !w-[200px] !max-w-[300px] text-left truncate'
                                }
                            >
                                {RenderSortByArrows('Name', filters?.order_by?.name, 'name')}
                            </th>

                            <th scope="col" className={'buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'}>
                                {RenderSortByArrows('Owner', filters?.order_by?.owner, 'owner')}
                            </th>
                            <th scope="col" className={'buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'}>
                                {RenderSortByArrows('Size', filters?.order_by?.size, 'size')}
                            </th>
                            <th scope="col" className={'buttons-font primary-fonts !w-[200px] !pl-[10px] text-left'}>
                                {RenderSortByArrows('Date Modified', filters?.order_by?.updated_at, 'updated_at')}
                            </th>

                            <th
                                scope="col"
                                className={'tableHeadings primary-fonts !w-[20px] pl-[9px] text-center'}
                            ></th>
                        </tr>
                    </thead>

                    <tbody className="bg-transparent gap-0">
                        {!globalLoading &&
                            dataList &&
                            dataList?.length > 0 &&
                            dataList?.map((dataObj, index) => {
                                return (
                                    <tr
                                        key={'file_Manager_table' + index}
                                        className="border-y relative  divide-gray-300 hover:!bg-[#f8f4fe]"
                                    >
                                        <td className={cn('whitespace-nowrap !text-[16px] p-4 !px-0 text-center')}>
                                            <div className="min-w-[18px]">
                                                <input
                                                    type="checkbox"
                                                    className="rounded-[3px] cursor-pointer"
                                                    checked={
                                                        selectedIds?.some(
                                                            (item) => item.job_drive_id === dataObj.job_drive_id
                                                        ) || false
                                                    }
                                                    onChange={() => handleSelectId(dataObj)}
                                                />
                                            </div>
                                        </td>
                                        <td
                                            className={cn(
                                                'whitespace-nowrap flex gap-2 items-center !text-[16px] py-5 pl-6 sm:pl-0'
                                            )}
                                        >
                                            <div
                                                className="flex items-center gap-4 cursor-pointer"
                                                onClick={() => {
                                                    if (dataObj?.type === 'folder') {
                                                        handleInternalClick({
                                                            job_drive_id: dataObj?.job_drive_id,
                                                            name: dataObj?.name
                                                        });
                                                    } else {
                                                        if (['application/pdf'].includes(dataObj?.mimetype)) {
                                                            setSelectedPdf(dataObj?.url);
                                                        } else if (
                                                            ['image/jpg', 'image/png', 'image/jpeg'].includes(
                                                                dataObj?.mimetype
                                                            )
                                                        ) {
                                                            setSelectedImage(dataObj?.url);
                                                        } else {
                                                            downloadFile(dataObj?.url);
                                                        }
                                                    }
                                                }}
                                            >
                                                {dataObj?.type === 'file' ? (
                                                    <GenerateFileIcon url={dataObj?.url} sx={'w-10'} />
                                                ) : (
                                                    <FolderIcon className={'w-10'} />
                                                )}

                                                <div className="flex flex-col">
                                                    <div className="group relative">
                                                        <div className="app-dataObj-name max-w-[300px] truncate flow">
                                                            {dataObj?.name}
                                                        </div>
                                                        <span
                                                            className="group-hover:opacity-100 transition-opacity 
                                                        bg-gray-100 px-2 py-1.5 text-xs text-gray-800 rounded-md absolute left-0 
                                                            translate-x-1 -translate-y-12 opacity-0 mx-auto font-secondary !w-max"
                                                        >
                                                            {dataObj?.name}
                                                        </span>
                                                    </div>
                                                    <div className="group relative">
                                                        <div className="recently-added text-gray-600 max-w-[200px] text-ellipsis overflow-hidden text-xs app-dataObj-email break-all">
                                                            {generatePath(dataObj?.path)}
                                                        </div>
                                                        <span
                                                            className="group-hover:opacity-100 transition-opacity 
                                                        bg-gray-100 px-2 py-1.5 text-xs text-gray-800 rounded-md absolute left-1/2 
                                                            -translate-x-1/2 translate-y-1.5 opacity-0 mx-auto font-secondary !w-max"
                                                        >
                                                            {generatePath(dataObj?.path)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            className={
                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black'
                                            }
                                        >
                                            <DefaultProfileViewer
                                                image={dataObj?.created_by?.image}
                                                width="40px"
                                                height="40px"
                                                name={getProfileName(dataObj?.created_by)}
                                                tooltip
                                            />
                                        </td>
                                        <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                            {dataObj?.size && dataObj?.size !== 'null'
                                                ? formatFileSize(dataObj?.size / 1024 ?? 0)
                                                : '__'}
                                        </td>
                                        <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black">
                                            {dataObj?.created_at ? (
                                                <FormateDateTime dateTime={dataObj?.updated_at} />
                                            ) : (
                                                '__'
                                            )}
                                        </td>
                                        <td className="whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center">
                                            <DropdownMenu
                                                extraProps={{
                                                    button: {
                                                        disabled: selectedIds.length > 0
                                                    }
                                                }}
                                            >
                                                {singleFileOptions
                                                    .filter((optObj) => {
                                                        return (
                                                            optObj.allowedTypes.includes(dataObj?.type) ||
                                                            optObj.allowedTypes.includes(
                                                                dataObj?.mimetype?.split('/').at(-1)
                                                            )
                                                        );
                                                    })
                                                    .map((obj, keyInd) => (
                                                        <DropdownMenuItem
                                                            key={'otp' + index + keyInd}
                                                            onClick={() => {
                                                                if (obj?.name === 'Download' || !disabled) {
                                                                    obj.onClick({
                                                                        ...dataObj,
                                                                        name: dataObj?.name,
                                                                        type: dataObj?.type,
                                                                        id: dataObj?.job_drive_id
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                className={`flex items-center gap-2 cursor-pointer group hover:text-white ${
                                                                    obj?.name === 'Download' || !disabled
                                                                        ? ''
                                                                        : '!cursor-not-allowed !opacity-50'
                                                                }`}
                                                            >
                                                                <span className="w-5 h-5">{obj.icon}</span>
                                                                {obj.name}
                                                            </div>
                                                        </DropdownMenuItem>
                                                    ))}
                                            </DropdownMenu>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>

                {(isLoading || !dataList) && (
                    <SkeletonTable columns={6} showLogo logoIndex={0} sxLogo="rounded-md !w-5 !h-5" />
                )}
                {!isLoading && dataList?.length === 0 && (
                    <NoDataFound classes="border-y border-gray-300" message="No Data Found!" height="420px" />
                )}
            </FileDropZone>
            {editEntity && (
                <EntityCreateUpdate
                    open={!!editEntity}
                    setOpen={(e) => {
                        if (!e) {
                            setEditEntity(null);
                        }
                    }}
                    editObj={{
                        id: editEntity?.job_drive_id,
                        parent_id: editEntity?.parent_id
                    }}
                    type={editEntity?.type}
                    fileName={editEntity?.name}
                    getData={getData}
                    currentList={data}
                />
            )}
            {CutORCopy && CutORCopy?.open && (
                <MoveOrCopy
                    open={!!CutORCopy}
                    setOpen={(e) => {
                        if (!e) {
                            setCutORCopy(null);
                        }
                    }}
                    setFileList={(e) => setCutORCopy(null)}
                    title={CutORCopy?.title}
                    list={CutORCopy?.list}
                    action={CutORCopy?.action}
                    data={data}
                    getMainTableData={getMainTableData}
                />
            )}
        </div>
    );
};

export default JobPortalDocumentsTable;
