import React from 'react';
import ReportsPage from '../../../components/workplace-content/tasks-pages/reports-page/ReportsPage';
import { SidebarLayout } from 'layouts/SidebarLayout';
const Reports = () => {
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                <div>
                    <ReportsPage />
                </div>
            </SidebarLayout>
        </div>
    );
};

export default Reports;
