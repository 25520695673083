import { useDispatch } from 'react-redux';
import { fetchSubmissionFormDataThunk } from 'store/form';
import { setLoader } from 'store/global/globalReducer';

export const useGetFormSubmissions = () => {
    const dispatch = useDispatch();
    const fetchSubmissions = (payload) => {
        dispatch(setLoader(true));
        dispatch(fetchSubmissionFormDataThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return { fetchSubmissions };
};
