import React from 'react';

export const BrownFolderIcon = () => {
    return (
        <div>
            <svg width="45" height="45" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M82.207 83.5446H5.70703C4.24609 83.5446 2.91797 82.0903 2.91797 80.1511V12.4428C2.91797 10.6653 4.11328 9.04932 5.70703 9.04932H43.8242C44.8867 9.04932 45.8164 8.40294 46.2148 7.27177L48.4727 1.77755C49.0039 0.646381 49.9336 0 50.8633 0H82.0742C83.5352 0 84.8633 1.45436 84.8633 3.3935V80.1511C84.9961 81.9287 83.8008 83.5446 82.207 83.5446Z"
                    fill="#F0884B"
                />
                <path
                    d="M81.0117 84.9977H5.70703C4.24609 84.9977 2.91797 83.5434 2.91797 81.6042V13.8959C2.91797 12.1184 4.11328 10.5024 5.70703 10.5024H43.8242C44.8867 10.5024 45.8164 9.85607 46.2148 8.7249L48.4727 3.23067C49.0039 2.0995 49.9336 1.45312 50.8633 1.45312H82.0742C83.5352 1.45312 84.8633 2.90748 84.8633 4.84662V80.1499C84.9961 82.7354 83.2695 84.9977 81.0117 84.9977Z"
                    fill="#C37C49"
                />
                <path
                    d="M0 20.8478V78.6989C0 81.446 1.72656 83.5467 3.98438 83.5467H81.0156C83.2734 83.5467 85 81.446 85 78.6989V28.6044C85 25.8573 83.2734 23.7566 81.0156 23.7566H42.2344C41.1719 23.7566 40.1094 23.2718 39.4453 22.3022L35.4609 17.4544C34.6641 16.4848 33.7344 16 32.6719 16H3.98438C1.72656 16 0 18.2623 0 20.8478Z"
                    fill="#F0884B"
                />
                <path
                    d="M0 22.301V80.152C0 82.8991 1.72656 84.9998 3.98438 84.9998H81.0156C83.2734 84.9998 85 82.8991 85 80.152V30.0575C85 27.3104 83.2734 25.2097 81.0156 25.2097H42.2344C41.1719 25.2097 40.1094 24.7249 39.4453 23.7553L35.4609 18.9075C34.6641 17.9379 33.7344 17.4531 32.6719 17.4531H3.98438C1.72656 17.4531 0 19.7155 0 22.301Z"
                    fill="#F0884B"
                />
            </svg>
        </div>
    );
};
