/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { TaskEmployees } from './TaskEmployees';
import { WeeklyChart } from './WeeklyChart';
import { DefaultBarChart } from 'components/molecules/DefaultBarChart';
import { useDispatch, useSelector } from 'react-redux';
import { yearlyStatisticsThunk } from 'store/workspace/workspaceEmployeeTasks';

export const ChartAndEmployees = () => {
    const { data: yearlyStats } = useSelector((state) => state.yearlyStatistics);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(yearlyStatisticsThunk());
    }, []);

    const statData = yearlyStats
        ? yearlyStats?.map((dataObj) => ({
              x: dataObj.month,
              y: dataObj.value
          }))
        : [];
    const xValues = yearlyStats ? yearlyStats.map((dataObj) => dataObj.month) : [];
    const totalValues = yearlyStats
        ? yearlyStats.flatMap((dataObj) => dataObj.value).reduce((total, value) => total + value, 0)
        : 0;
    return (
        <div className="md:grid grid-cols-12 gap-3">
            <div className="col-span-5 bg-white  !rounded-2xl overflow-hidden ">
                <div className="">
                    <div className="p-5 mt-3 px-8 lg-title font-semibold">
                        Tasks Completed <span className="ml-3">{totalValues || 0}</span>
                    </div>

                    <div className="mt-0">
                        <DefaultBarChart
                            xValues={xValues}
                            stats={[
                                {
                                    data: statData
                                }
                            ]}
                            height={300}
                            Yaxes={true}
                            barWidth="40%"
                            borderRadius="7"
                            bg="white"
                        />
                    </div>
                </div>
            </div>
            <div className="col-span-4 bg-white  !rounded-2xl overflow-hidden">
                <WeeklyChart />
            </div>
            <div className="col-span-3 bg-white  !rounded-2xl overflow-hidden">
                <div className="employees-list-cards py-2">
                    {' '}
                    <TaskEmployees />
                </div>
            </div>
        </div>
    );
};
