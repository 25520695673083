import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TEMP_TOKEN } from 'utills/globalVars';

export const TempProtected = (props) => {
    const navigate = useNavigate();
    const { Component } = props;
    const temp_token = localStorage.getItem(TEMP_TOKEN);

    useEffect(() => {
        if (!temp_token) {
            sessionStorage.setItem('loginStepper', 1);

            navigate('/');
        }
    }, [temp_token]);
    return <div> {temp_token ? <Component /> : ''}</div>;
};
