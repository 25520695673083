import React from 'react';

export const MilestoneCalendarRed = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.1" width="46" height="46" rx="23" fill="#F14747" />
            <path
                d="M33.6873 14.0781H31.2499V15.6165H33.4998V32.5389H12.5002V15.6165H14.7501V14.0781H12.3127C12.1374 14.0811 11.9643 14.1195 11.8035 14.1911C11.6426 14.2627 11.4971 14.3661 11.3752 14.4954C11.2534 14.6247 11.1575 14.7773 11.0932 14.9446C11.0288 15.1119 10.9972 15.2906 11.0002 15.4704V32.6851C10.9972 32.8649 11.0288 33.0436 11.0932 33.2108C11.1575 33.3781 11.2534 33.5308 11.3752 33.6601C11.4971 33.7894 11.6426 33.8927 11.8035 33.9643C11.9643 34.0359 12.1374 34.0743 12.3127 34.0773H33.6873C33.8626 34.0743 34.0357 34.0359 34.1965 33.9643C34.3574 33.8927 34.5029 33.7894 34.6248 33.6601C34.7466 33.5308 34.8425 33.3781 34.9068 33.2108C34.9712 33.0436 35.0028 32.8649 34.9998 32.6851V15.4704C35.0028 15.2906 34.9712 15.1119 34.9068 14.9446C34.8425 14.7773 34.7466 14.6247 34.6248 14.4954C34.5029 14.3661 34.3574 14.2627 34.1965 14.1911C34.0357 14.1195 33.8626 14.0811 33.6873 14.0781Z"
                fill="#F14747"
                stroke="#F14747"
                strokeWidth="0.4"
            />
            <path d="M15.5 20.2305H17V21.7689H15.5V20.2305Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M20 20.2305H21.5V21.7689H20V20.2305Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M24.5 20.2305H26V21.7689H24.5V20.2305Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M29 20.2305H30.5V21.7689H29V20.2305Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M15.5 24.0781H17V25.6165H15.5V24.0781Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M20 24.0781H21.5V25.6165H20V24.0781Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M24.5 24.0781H26V25.6165H24.5V24.0781Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M29 24.0781H30.5V25.6165H29V24.0781Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M15.5 27.9219H17V29.4603H15.5V27.9219Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M20 27.9219H21.5V29.4603H20V27.9219Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M24.5 27.9219H26V29.4603H24.5V27.9219Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path d="M29 27.9219H30.5V29.4603H29V27.9219Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
            <path
                d="M17 17.1536C17.1989 17.1536 17.3897 17.0726 17.5303 16.9283C17.671 16.7841 17.75 16.5884 17.75 16.3844V11.7692C17.75 11.5652 17.671 11.3695 17.5303 11.2253C17.3897 11.081 17.1989 11 17 11C16.8011 11 16.6103 11.081 16.4697 11.2253C16.329 11.3695 16.25 11.5652 16.25 11.7692V16.3844C16.25 16.5884 16.329 16.7841 16.4697 16.9283C16.6103 17.0726 16.8011 17.1536 17 17.1536Z"
                fill="#F14747"
                stroke="#F14747"
                strokeWidth="0.4"
            />
            <path
                d="M29 17.1536C29.1989 17.1536 29.3897 17.0726 29.5303 16.9283C29.671 16.7841 29.75 16.5884 29.75 16.3844V11.7692C29.75 11.5652 29.671 11.3695 29.5303 11.2253C29.3897 11.081 29.1989 11 29 11C28.8011 11 28.6103 11.081 28.4697 11.2253C28.329 11.3695 28.25 11.5652 28.25 11.7692V16.3844C28.25 16.5884 28.329 16.7841 28.4697 16.9283C28.6103 17.0726 28.8011 17.1536 29 17.1536Z"
                fill="#F14747"
                stroke="#F14747"
                strokeWidth="0.4"
            />
            <path d="M19.25 14.0781H26.7499V15.6165H19.25V14.0781Z" fill="#F14747" stroke="#F14747" strokeWidth="0.4" />
        </svg>
    );
};
