import React from 'react';
import LoginSuccessfullPage from '../../components/second-time-login-pages/LoginSuccessfullPage';
const LoginSuccessfull = () => {
    return (
        <div>
            <LoginSuccessfullPage />
        </div>
    );
};
export default LoginSuccessfull;
