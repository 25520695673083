import React from 'react';

export const StepDownIcon = ({ className }) => {
    return (
        <div>
            <svg
                width="10"
                height="5"
                className={className}
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.81363 0.165329C10.0621 0.385768 10.0621 0.74317 9.81363 0.963608L5.44993 4.83467C5.20144 5.05511 4.79856 5.05511 4.55007 4.83467L0.186369 0.963608C-0.0621233 0.743169 -0.0621233 0.385767 0.186369 0.165329C0.434861 -0.0551101 0.837747 -0.0551101 1.08624 0.165329L5 3.63725L8.91376 0.165329C9.16225 -0.0551097 9.56514 -0.0551097 9.81363 0.165329Z"
                    fill="#979797"
                />
            </svg>
        </div>
    );
};
