import React from 'react';
import './css/leave.css';
import { LeaveJumbo } from './LeaveJumbo';
import { LeaveOperations } from './LeaveOperations';
import { LeaveCards } from './LeaveCards';
export const LeavePage = ({ toggleAnnualLeave }) => {
    return (
        <div>
            <div>
                {' '}
                <LeaveJumbo />
            </div>
            <div>
                <LeaveOperations />
            </div>
            <div>
                {' '}
                <LeaveCards toggleAnnualLeave={toggleAnnualLeave} />{' '}
            </div>
        </div>
    );
};
