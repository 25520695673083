import React from 'react';

export const EditIcon = () => {
    return (
        <div>
            {' '}
            <svg width="14" height="16" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.875 20C1.35937 20 0.918125 19.8043 0.55125 19.413C0.18375 19.021 0 18.55 0 18V2C0 1.45 0.18375 0.979 0.55125 0.587C0.918125 0.195667 1.35937 0 1.875 0H9.375L15 6V10H13.125V7H8.4375V2H1.875V18H7.5V20H1.875ZM13.4062 12.525L14.4141 13.6L10.7812 17.45V18.5H11.7656L15.3984 14.65L16.3828 15.7L12.3516 20H9.375V16.825L13.4062 12.525ZM16.3828 15.7L13.4062 12.525L14.7656 11.075C14.9375 10.8917 15.1562 10.8 15.4219 10.8C15.6875 10.8 15.9062 10.8917 16.0781 11.075L17.7422 12.85C17.9141 13.0333 18 13.2667 18 13.55C18 13.8333 17.9141 14.0667 17.7422 14.25L16.3828 15.7Z" />
            </svg>
        </div>
    );
};
