import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAllBusinessGroupsThunk,
    fetchBusinessEmployeeThunk,
    updateBusinessEmployeeThunk
} from 'store/settings/team/team';
import { TeamDetailSkeleton } from './team-skeleton/TeamDetailSkeleton';
import './css/teamDetails.css';
import { setSideLoader } from 'store/global/globalReducer';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import Slideover from 'components/atoms/SlideOver';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';

export const EditMember = ({ business_employee_id, handleEditMember, open, setOpen }) => {
    const dispatch = useDispatch();
    const [detailLoader, setDetailLoader] = useState(false);
    const [employee, setEmployee] = useState(null);
    const { sideLoader } = useSelector((state) => state.global);
    const [businessGroups, setBusinessGroups] = useState(null);

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [businessGroup, setBusinessGroup] = useState('');

    const updateEmployee = () => {
        const updateEmployeeData = {
            business_employee_id,
            first_name,
            last_name,
            business_group_id: businessGroup
        };

        dispatch(setSideLoader(true));
        dispatch(updateBusinessEmployeeThunk(updateEmployeeData))
            .then((response) => {
                if (response.payload) {
                    handleEditMember(business_employee_id);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    useEffect(() => {
        setDetailLoader(true);
        dispatch(fetchBusinessEmployeeThunk({ business_employee_id }))
            .then((response) => {
                if (response.payload) {
                    setEmployee(response.payload);
                    setFirstName(response.payload.first_name);
                    setLastName(response.payload.last_name);
                    setBusinessGroup(response.payload?.business_group_id);
                }
            })
            .catch((error) => {
                console.error(error);
            });

        setTimeout(() => {
            dispatch(fetchAllBusinessGroupsThunk())
                .then((response) => {
                    if (response.payload) {
                        setBusinessGroups(response.payload);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setDetailLoader(false);
                });
        }, 500);
    }, []);
    return (
        <Slideover title="Edit Member" open={open} setOpen={setOpen}>
            {detailLoader ? (
                <TeamDetailSkeleton />
            ) : (
                <div className="pl-5 mt-5">
                    <DefaultProfileViewer
                        image={employee?.image}
                        width="100px"
                        height="100px"
                        name={employee?.first_name + ' ' + employee?.last_name}
                    />
                </div>
            )}

            <div className="team-line mt-3 mx-5"></div>

            <div className="default-forms p-5">
                <div className="grid grid-cols-2 gap-5">
                    <div>
                        <label className="buttons-font font-poppins">First Name</label>
                        <input
                            type="text"
                            value={first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="mt-2"
                        />
                    </div>
                    <div>
                        <label>Last Name</label>
                        <input
                            type="text"
                            value={last_name}
                            onChange={(e) => setLastName(e.target.value)}
                            className="mt-2"
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <label>Email</label>
                    <input type="text" value={employee?.email} disabled className="mt-2 bg-gray-200 !border-none" />
                </div>
                <div className="mt-3">
                    <label>Contact Number</label>
                    <input
                        type="text"
                        value={employee?.phone_number}
                        disabled
                        className="mt-2 bg-gray-200 !border-none"
                    />
                </div>

                <div className="grid grid-cols-3 gap-2 mt-3">
                    <div className="col-span-2">
                        <label>Role Group</label>
                        <div className="mt-2">
                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="business_group_name"
                                optionsData={businessGroups || []}
                                selectedValue={businessGroup}
                                valuePropertyName={'business_group_id'}
                                sx="rounded-xl py-3 ring-gray-400 "
                                onChangeValue={(obj) => {
                                    setBusinessGroup(obj?.business_group_id);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center items-center mt-5">
                <div
                    className="ann-btn buttons-font-lighter cursor-pointer font-fira rounded-xl px-10 flex gap-2 items-center"
                    onClick={updateEmployee}
                >
                    Update {sideLoader && <SmallLoaderWhite />}
                </div>
            </div>
        </Slideover>
    );
};
