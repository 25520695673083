import React from 'react';

export const EnvelopeIcon = () => {
    return (
        <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.9117 1.77869L18.1396 1.6H17.85H3.15H2.86039L3.0883 1.77869L10.4383 7.54169L10.5 7.59007L10.5617 7.54169L17.9117 1.77869ZM1.4 15.3V15.4H1.5H19.5H19.6V15.3V2.4735V2.26798L19.4383 2.39481L10.8658 9.11831L10.8657 9.11839C10.7569 9.20391 10.6293 9.2486 10.5 9.2486C10.3707 9.2486 10.2431 9.20391 10.1343 9.11839L10.1342 9.11831L1.56171 2.39481L1.4 2.26798V2.4735V15.3ZM1.5 0.1H19.5C19.867 0.1 20.2219 0.265111 20.4857 0.56408C20.7498 0.863455 20.9 1.27189 20.9 1.7V15.3C20.9 15.7281 20.7498 16.1365 20.4857 16.4359C20.2219 16.7349 19.867 16.9 19.5 16.9H1.5C1.13302 16.9 0.778121 16.7349 0.514324 16.4359C0.25017 16.1365 0.1 15.7281 0.1 15.3V1.7C0.1 1.27189 0.25017 0.863455 0.514324 0.564081C0.778121 0.265111 1.13302 0.1 1.5 0.1Z"
                fill="black"
                stroke="black"
                strokeWidth="0.2"
            />
        </svg>
    );
};
