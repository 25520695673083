import React from 'react';

export const GlobIcon = () => {
    return (
        <div>
            <svg width="22" height="25" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 22C17.299 22 22 17.299 22 11.5C22 5.70101 17.299 1 11.5 1C5.70101 1 1 5.70101 1 11.5C1 17.299 5.70101 22 11.5 22Z"
                    stroke="black"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.70312 8H21.3031M1.70312 15H21.3031M10.9198 1C8.95436 4.14955 7.91236 7.78751 7.91236 11.5C7.91236 15.2125 8.95436 18.8505 10.9198 22M12.0865 1C14.0519 4.14955 15.0939 7.78751 15.0939 11.5C15.0939 15.2125 14.0519 18.8505 12.0865 22"
                    stroke="black"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
