import React from 'react';

export const FormsIcon = ({ className, extraProps }) => {
    return (
        <svg
            className={className}
            {...extraProps}
            width="57"
            height="65"
            viewBox="0 0 57 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.96548 6.93515C2.18938 5.0327 3.01365 3.85846 4.2523 3.11527C5.56393 2.3283 7.44166 1.96548 9.82738 1.96548H42.2577C45.0115 1.96548 46.6911 2.76322 47.6882 3.81023C48.6968 4.86927 49.1369 6.31918 49.1369 7.8619V25.5512C49.1369 26.0939 49.5769 26.5339 50.1196 26.5339C50.6624 26.5339 51.1024 26.0939 51.1024 25.5512V7.8619C51.1024 5.96504 50.5597 3.97537 49.1115 2.45473C47.6518 0.922045 45.4004 0 42.2577 0H9.82738C7.29941 0 5.00051 0.374231 3.24107 1.42989C1.42003 2.52252 0.282556 4.28173 0.00601069 6.77064C0.00200663 6.80668 0 6.84291 0 6.87917V56.9988C0 58.864 0.408443 61.2248 2.81624 62.903C5.12954 64.5153 9.10294 65.3538 15.7964 64.858C16.3377 64.8179 16.744 64.3466 16.7039 63.8054C16.6638 63.2641 16.1925 62.8578 15.6512 62.8979C9.07771 63.3848 5.68058 62.5036 3.94008 61.2905C2.29409 60.1433 1.96548 58.5731 1.96548 56.9988V6.93515Z"
                fill="black"
            />
            <path
                d="M38.6089 30.6419C38.0983 30.7494 37.0907 31.1256 36.392 31.4615C35.3037 31.9855 34.9409 32.2542 33.8929 33.3022C32.5628 34.6458 31.9447 35.6669 31.3938 37.4136C31.1386 38.26 31.0848 38.69 31.0983 40.1679C31.0983 41.6862 31.152 42.0624 31.461 42.9895C32.1059 44.9511 33.3286 46.644 34.9543 47.8398L35.7202 48.4041L35.2231 48.5654C34.4169 48.8206 32.4821 49.8149 31.5416 50.4464C30.319 51.266 28.0886 53.5501 27.269 54.813C25.603 57.3658 24.5684 60.5905 24.5684 63.2373C24.5684 64.0435 24.6087 64.2047 24.9043 64.4869L25.2268 64.8228H40.6915H56.1562L56.5055 64.4734C56.8414 64.1375 56.8548 64.0972 56.7742 62.8342C56.4114 57.0837 53.1062 52.0184 48.0006 49.4118C47.275 49.0356 46.442 48.6594 46.1599 48.5654L45.6627 48.4041L46.4286 47.8398C48.2559 46.4962 49.5188 44.5615 50.11 42.2371C50.3787 41.2025 50.3384 38.7572 50.0428 37.6823C49.586 35.9894 48.8067 34.6324 47.5438 33.3694C46.2674 32.0795 45.1656 31.3943 43.5399 30.8703C42.2097 30.4404 39.9928 30.3329 38.6089 30.6419ZM43.1234 33.0066C45.5015 33.8799 47.0601 35.4922 47.8931 37.951C48.0812 38.4884 48.1484 39.0796 48.1484 40.1008C48.1484 41.1219 48.0812 41.7131 47.8931 42.2505C47.0466 44.763 45.3537 46.4559 42.8412 47.3024C41.7529 47.6786 39.4285 47.6383 38.3536 47.2486C33.9467 45.6363 31.9716 40.9338 33.9735 36.809C34.9812 34.6995 36.9025 33.2216 39.2807 32.6976C40.2212 32.496 42.1425 32.6573 43.1234 33.0066ZM42.989 49.9761C47.4363 50.742 51.1714 53.5098 53.16 57.5002C53.7646 58.7094 54.3289 60.4024 54.4632 61.3966C54.5035 61.7594 54.5707 62.2028 54.611 62.3774L54.6648 62.673H40.6915H26.7047L26.7853 62.0415C27.6452 55.7267 32.6165 50.7151 38.8776 49.8686C39.845 49.7343 41.9544 49.788 42.989 49.9761Z"
                fill="black"
            />
            <rect x="9.82739" y="15.7238" width="30.4649" height="2.45684" rx="1.22842" fill="black" />
            <rect x="9.82739" y="26.5339" width="19.6548" height="2.45684" rx="1.22842" fill="black" />
            <rect x="9.82739" y="37.344" width="13.7583" height="2.45684" rx="1.22842" fill="black" />
        </svg>
    );
};
