import React from 'react';
import { useState } from 'react';
import { deleteServiceThunk } from 'store/auth/slices';
import { generateId } from 'utills/uid';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { editService, setSideLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { useFetchServices } from 'components/workplace-content/tasks-pages/task-details/hooks/useFetchServices';
import ConfirmationModal from 'utills/confirmationModal';
import useRoleGuard from 'Hooks/useRoleGuard';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { EditPencilIcon } from 'utills/svgs/EditPencilIcon';
import { NoDataFound } from 'components/common/NoDataFound';
import { TrashIcon } from 'utills/svgs/TrashIcon';
const BusinessServices = ({ toggleServiceSide }) => {
    const dispatch = useDispatch();
    const [serviceModal, setServiceModal] = useState(false);
    const { data } = useSelector((state) => state.getAllServices);
    const [serviceInView, setServiceInView] = useState('');
    const [serviceIndex, setServiceIndex] = useState(null);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { fetchServices } = useFetchServices();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const { isLoading } = useSelector((state) => state.global);

    const { hasPermissions } = useRoleGuard();

    function toggleServiceModal(index) {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            setServiceIndex(index);
            setServiceModal(!serviceModal);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    }

    function serviceHandler(id) {
        const findService = data?.find((ser) => ser?.business_service_id === id);
        setServiceInView(findService);
    }
    const editHandler = (service) => {
        toggleServiceSide();
        dispatch(editService(service));
    };

    const handleDelete = () => {
        const delData = {
            business_service_id: deleteId,
            business_id: business_id
        };
        setIsConfirmationOpen(false);

        dispatch(deleteServiceThunk(delData))
            .then((response) => {
                if (response.payload) {
                    fetchServices();
                    toastHandler('Service deleted successfully', 'success');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const deleteService = (service_id) => {
        dispatch(setSideLoader(true));

        setDeleteId(service_id);
        setIsConfirmationOpen(true);
    };

    const handleAdService = () => {
        if (hasPermissions('Profile', ['write', 'admin'])) {
            toggleServiceSide();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };
    return (
        <div className="md:px-10 px-5  mt-3 ">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <div className="md:grid grid-cols-2 b-h-container">
                <div className="p-5 service-scroll ">
                    <div className=" flex justify-end">
                        <button
                            onClick={handleAdService}
                            className="ann-btn buttons-font-lighter font-fira  text-white px-8 py-3 rounded-xl"
                        >
                            Add Service
                        </button>
                    </div>

                    <div className="mt-2">
                        {!isLoading && data?.length < 1 && <NoDataFound message="No Service Found!" height="300px" />}
                        {data &&
                            data?.map((service, index) => (
                                <div
                                    onClick={() => serviceHandler(service?.business_service_id)}
                                    key={generateId()}
                                    className="service-div !shadow-lg !rounded-xl cursor-pointer px-4 py-5 mt-5 relative"
                                >
                                    <div
                                        onClick={() => toggleServiceModal(index)}
                                        className="absolute right-4 cursor-pointer"
                                    >
                                        <EllipsisVerticalIcon className="w-5" />
                                    </div>

                                    {serviceModal && serviceIndex === index ? (
                                        <div className="absolute service-modal small-text shadow-lg right-5 top-10">
                                            <div
                                                onClick={() => {
                                                    editHandler(service);
                                                    toggleServiceModal(index);
                                                }}
                                                className="flex gap-2 px-6 py-2 modal-div  items-center cursor-pointer"
                                            >
                                                <div className="service-icon">
                                                    <EditPencilIcon />
                                                </div>
                                                <div>Edit</div>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    deleteService(service?.business_service_id);
                                                    toggleServiceModal(index);
                                                }}
                                                className="flex gap-2 px-6 py-2 modal-div cursor-pointer"
                                            >
                                                <div className="service-icon ">
                                                    <TrashIcon className="w-5 h-5" />
                                                </div>
                                                <div>Delete</div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className="flex justify-between">
                                        <div className="buttons-font font-poppins break-words ">{service.name}</div>
                                        <div className="service-active mr-5"></div>
                                    </div>

                                    <div className="text-secondarybg buttons-font-lighter font-poppins mt-2 break-words">
                                        {service?.description.slice(0, 200)}
                                    </div>

                                    <div className="service-time small-text font-poppins grid grid-cols-10 px-2 py-2 mt-3">
                                        <div className="flex gap-2 col-span-9 text-md">
                                            <div>
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect width="20" height="20" rx="10" fill="white" />
                                                    <path
                                                        d="M10 5V11.6667H15"
                                                        stroke="#B695F8"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                            <div>Min-Time of Consulation</div>
                                        </div>
                                        <div className="flex justify-end text-md">{service?.duration} min</div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className="!px-4 !py-5 s-i-v">
                    {serviceInView && (
                        <div className="ring-1 ring-gray-300 rounded-2xl px-4 py-3">
                            <div className="title break-words !text-md">{serviceInView?.name}</div>

                            <div className="charges my-2 flex justify-between">
                                <div className="charge-title">Consultation Charges:</div>
                                <div className="charge !text-black text-md ">${serviceInView?.charges}</div>
                            </div>

                            <div className="service-time grid grid-cols-2  px-3 py-2 mt-3">
                                <div className="flex gap-2">
                                    <div>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect width="20" height="20" rx="10" fill="white" />
                                            <path
                                                d="M10 5V11.6667H15"
                                                stroke="#B695F8"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div>Min-Time of Consulation</div>
                                </div>
                                <div className="flex justify-end">{serviceInView?.duration}</div>
                            </div>

                            <div className="service-details mt-5">
                                <div className="title">Details</div>
                                <div className="mt-2 break-words">{serviceInView?.description}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BusinessServices;
