import React from 'react';
import Onboarding from 'pages/dashboard/onboarding/Onboarding';

import LostGoogleAuth from 'pages/lost-two-fa/LostGoogleAuth';
import LostTwoFa from 'pages/lost-two-fa/LostTwoFa';
import { OneTimeProtection } from './protected-routes/OneTimeProtection';
import { GoogleAuthInstructions } from 'pages/dashboard/auth/google-auth/GoogleAuthInstructions';
import { ResetAuth } from 'pages/login-pages/ResetAuth';
import { TempProtected } from './protected-routes/TempProtected';

const { default: Dashboard } = require('pages/dashboard/Dashboard');
const { default: TwoFactor } = require('pages/dashboard/auth/TwoFactor');
const { default: EmailAuth } = require('pages/dashboard/auth/email-auth/EmailAuth');
const { default: EmailSuccess } = require('pages/dashboard/auth/email-auth/EmailSuccess');
const { default: GoogleAuth } = require('pages/dashboard/auth/google-auth/GoogleAuth');
const { default: GoogleQr } = require('pages/dashboard/auth/google-auth/GoogleQr');
const { default: AuthSuccess } = require('pages/dashboard/auth/google-auth/AuthSuccess');
const { default: BackupCodes } = require('pages/dashboard/auth/BackupCodes');

const twoFactorRoutes = [
    {
        path: '/authentication-and-security',
        component: <TempProtected Component={Dashboard} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/two/factor/auth',
        component: <TwoFactor />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/email/auth',
        component: <EmailAuth />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/email/success',
        component: <EmailSuccess />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/google/auth',
        component: <GoogleAuth />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/google/qr',
        component: <GoogleQr />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/auth/success',
        component: <TempProtected Component={AuthSuccess} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/backup/codes',
        component: <TempProtected Component={BackupCodes} />,
        isProtected: true,
        layout: 'none'
    },

    {
        path: '/lost/two/fa',
        component: <LostTwoFa />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/lost/google/auth',
        component: <LostGoogleAuth />,
        isProtected: true,
        layout: 'none'
    },

    {
        path: '/onboarding',
        component: <OneTimeProtection Component={Onboarding} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/google/auth/instructions',
        component: <OneTimeProtection Component={GoogleAuthInstructions} />,
        isProtected: true,
        layout: 'none'
    },
    {
        path: '/reset-auth',
        component: <ResetAuth />,
        isProtected: false,
        layout: 'header'
    }
];

export default twoFactorRoutes;
