import React from 'react';

export const OrangeUserIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="40" height="40" rx="20" fill="#69E48E" />
                <path
                    d="M19.2296 9.05748C18.0565 9.23795 17.0081 9.79654 16.1874 10.6688C15.285 11.627 14.8167 12.7356 14.7522 14.0633C14.662 15.9196 15.5729 17.6942 17.1456 18.7211C17.919 19.2282 18.6624 19.486 19.5647 19.559C21.2362 19.6879 22.6628 19.1637 23.8143 17.9907C24.9401 16.8434 25.4428 15.4297 25.3053 13.8055C25.185 12.4133 24.5706 11.2145 23.505 10.295C22.6628 9.5731 21.8893 9.21216 20.8194 9.05748C20.3038 8.98443 19.7022 8.98013 19.2296 9.05748ZM21.06 10.4067C21.7346 10.5786 22.2417 10.8751 22.8089 11.4208C23.5479 12.1211 23.9218 12.8946 24.0206 13.8958C24.1366 15.0817 23.7714 16.1645 22.9678 17.0239C22.4694 17.5567 21.7346 18.0079 21.1116 18.1669C20.1878 18.3989 19.0878 18.3001 18.2971 17.909C17.2186 17.3762 16.4194 16.3794 16.1272 15.202C16.0155 14.7508 16.0284 13.7325 16.153 13.2684C16.4839 12.0223 17.5667 10.8665 18.7698 10.4754C19.4229 10.2649 20.3983 10.2348 21.06 10.4067Z"
                    fill="#69E48E"
                    stroke="#69E48E"
                    strokeWidth="0.5"
                />
                <path
                    d="M15.3284 19.6527C14.671 19.7215 13.9921 19.9707 13.455 20.3316C12.1144 21.2426 11.2464 23.2793 11.0444 25.9863C10.9327 27.5074 11.023 28.2594 11.414 29.0457C11.6288 29.484 12.037 29.9652 12.4538 30.2746C12.7718 30.5066 13.3046 30.7473 13.773 30.8676L14.1597 30.9707H20.1753H26.1909L26.5777 30.8719C27.9355 30.5152 28.8851 29.6 29.2245 28.3066C29.4007 27.6406 29.3792 26.0508 29.173 24.7961C28.7218 22.0031 27.5788 20.3273 25.7698 19.8031C25.3573 19.6828 24.6183 19.5926 24.3991 19.6355C24.1886 19.6742 23.8964 19.8289 23.2347 20.2543C22.5214 20.7141 22.4097 20.7742 21.8769 20.9676C21.2495 21.1953 20.7812 21.2855 20.2011 21.2855C19.5995 21.2855 19.1355 21.1996 18.5253 20.9848C17.9495 20.7785 17.9237 20.7656 17.0214 20.1941C16.5487 19.8934 16.1964 19.7 16.0589 19.6613C15.8183 19.6012 15.8183 19.6012 15.3284 19.6527ZM16.4456 21.35C17.2534 21.8699 17.5929 22.0375 18.3019 22.2695C20.1495 22.8754 22.0359 22.5832 23.8148 21.4188C24.6183 20.8902 24.6011 20.8988 24.9405 20.9418C26.5003 21.1395 27.4757 22.45 27.8882 24.9121C28.0558 25.9262 28.1073 27.3398 27.9956 27.877C27.8152 28.7406 27.2308 29.3508 26.337 29.6043C26.0792 29.6773 25.7398 29.6816 20.1753 29.6816C14.598 29.6816 14.2757 29.6773 14.0093 29.6043C13.3691 29.4238 12.8148 28.9855 12.5569 28.457C12.3335 28.0016 12.2777 27.6191 12.312 26.6953C12.3421 25.7629 12.3851 25.3289 12.5355 24.5684C12.6472 23.9754 12.9093 23.1332 13.0941 22.768C13.6484 21.668 14.4003 21.0664 15.3757 20.9418C15.5347 20.9203 15.6808 20.9031 15.698 20.8988C15.7152 20.8988 16.0503 21.1008 16.4456 21.35Z"
                    fill="#69E48E"
                    stroke="#69E48E"
                    strokeWidth="0.5"
                />
            </svg>
        </div>
    );
};
