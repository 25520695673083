import React from "react";

import StepFour from "../../components/signup-steps/StepFour";
export const SignupSetPassword = () => {
  return (
    <div>
      <StepFour />
    </div>
  );
};
