import React from 'react';

const ArrowDown = ({ className }) => {
    return (
        <div>
            <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={className}
            >
                <path
                    d="M6.8294 7.76866C6.43303 8.35712 5.56697 8.35712 5.1706 7.76867L0.987699 1.55866C0.540292 0.894434 1.01624 -1.04765e-06 1.81709 -9.77641e-07L10.1829 -2.46278e-07C10.9838 -1.76265e-07 11.4597 0.894433 11.0123 1.55866L6.8294 7.76866Z"
                    fill="#B695F8"
                />
            </svg>
        </div>
    );
};

export default ArrowDown;
