import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import EmailInput from 'components/atoms/Input/EmailInput';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { InputField } from 'components/atoms/inputs/InputField';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { deleteClientRefThunk, searchClientsThunk } from 'store/client';
import { validatePhoneNumber } from 'utills/baseValidations';
import { validateEmailValue, validateIndividualsObjects } from 'utills/dataValidation';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';

const AppointerDetail = ({ setCurrentStep, appointersList, setAppointersList, defaultAppointerObject }) => {
    const dispatch = useDispatch();

    const handleRemoveEntry = (index, bcr_id) => {
        if (bcr_id) {
            dispatch(deleteClientRefThunk({ bcr_id: bcr_id }));
        }
        const newInputs = [...appointersList];
        newInputs.splice(index, 1);
        setAppointersList(newInputs);
    };
    const handleAddEntry = () => {
        setAppointersList([...appointersList, defaultAppointerObject]);
    };

    const fetchReferencesListFun = async (payload) => {
        return await dispatch(searchClientsThunk(payload));
    };

    const handleEntityValue = (value, targetProperty, index) => {
        setAppointersList((pre) => {
            return pre.map((prevObj, preInd) => {
                return {
                    ...prevObj,
                    [targetProperty]: preInd === index ? value : prevObj?.[targetProperty]
                };
            });
        });
    };

    const handleComboxboxEntity = (obj = null, index, isFirstLoad = false) => {
        setAppointersList((pre) => {
            return pre.map((prevObj, preInd) => {
                if (preInd === index) {
                    if (isFirstLoad === true) {
                        return {
                            ...prevObj,
                            isFirstLoad: false
                        };
                    }

                    if (obj?.business_client_id === prevObj?.business_client_id) {
                        return {
                            ...prevObj
                        };
                    }

                    return {
                        ...prevObj,
                        business_client_id: obj ? obj?.business_client_id : '',
                        first_name: obj ? obj?.first_name : '',
                        last_name: obj ? obj?.last_name : '',
                        phone_number: obj ? obj?.phone_number : '',
                        email_address: obj ? obj?.email : '',
                        residential_address: obj ? obj?.residential_address : '',
                        disabled: {
                            first_name: obj && obj?.first_name && obj?.first_name !== '' ? true : false,
                            last_name: obj && obj?.last_name && obj?.last_name !== '' ? true : false,
                            phone_number: obj && obj?.phone_number && obj?.phone_number !== '' ? true : false,
                            email_address: obj && obj?.email && obj?.email !== '' ? true : false,
                            residential_address:
                                obj && obj?.residential_address && obj?.residential_address !== '' ? true : false
                        }
                    };
                }
                return prevObj;
            });
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        let hasError = false;
        const emailSet = new Set();

        for (const [index, appointer] of appointersList.entries()) {
            const validationErrors = validateIndividualsObjects(appointer);
            if (validationErrors?.length > 0) {
                validationErrors.forEach((error) => {
                    toastHandler(error + ' at ' + (index + 1), TOAST_TYPE_ERROR);
                });
                hasError = true;
                break;
            }

            if (appointer?.email_address) {
                if (!validateEmailValue(appointer?.email_address)) {
                    hasError = true;
                    toastHandler(`Invalid Email at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                }
            }

            if (appointer?.email_address) {
                if (emailSet.has(appointer.email_address)) {
                    hasError = true;
                    toastHandler(`Duplicate email found at ${index + 1}`, TOAST_TYPE_ERROR);
                    break;
                } else {
                    emailSet.add(appointer.email_address);
                }
            }

            if (appointer?.phone_number?.length > 4) {
                if (!validatePhoneNumber(appointer?.phone_number)) {
                    hasError = true;
                    break;
                }
            }
        }

        if (hasError) {
            return;
        }

        setCurrentStep(4);
    };
    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Appointer Details</div>
                <div onClick={handleAddEntry} className="cursor-pointer">
                    <NewPlusIcon className="w-8 h-8" />
                </div>
            </div>
            <form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                <div>
                    {appointersList?.map((memberObj, memberObjIndex) => (
                        <div className=" !bg-dimThird p-5  rounded-xl mt-5  " key={'appointersList' + memberObjIndex}>
                            <div
                                onClick={() => handleRemoveEntry(memberObjIndex, memberObj?.bcr_id)}
                                className="cursor-pointer flex justify-end items-center"
                            >
                                <label className="invisible"> f</label>
                                <BgDeleteIcon />
                            </div>

                            <div className=" default-forms grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  gap-5  rounded-xl ">
                                <div className="">
                                    <label className="inputLabel">Appointer</label>

                                    <ComboboxComponentSSR
                                        fetchDataFunction={(payload) =>
                                            fetchReferencesListFun({
                                                ...payload,
                                                type: 'individual'
                                            })
                                        }
                                        defaultSelected={memberObj.business_client_id}
                                        removePreviousSelected
                                        onRemove={(_e) => {
                                            handleComboxboxEntity(null, memberObjIndex);
                                        }}
                                        filterSelectedValues={appointersList || []}
                                        filterSelectedValueTargetElement={'business_client_id'}
                                        onChange={(res) => {
                                            const obj = res[0];
                                            handleComboxboxEntity(obj, memberObjIndex, memberObj?.isFirstLoad);
                                        }}
                                        targetProperty="business_client_id"
                                        placeholder={'Search by Name/Reference No.'}
                                        limit={1}
                                        sx="rounded-xl ring-gray-400 "
                                        showNameOnly
                                    />
                                </div>

                                <div className="">
                                    <InputField
                                        label={'First Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'first_name', memberObjIndex);
                                        }}
                                        name={'First Name'}
                                        inputValue={memberObj.first_name}
                                        placeholder={'First Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={memberObj?.disabled?.first_name}
                                    />
                                </div>
                                <div className="">
                                    <InputField
                                        label={'Last Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'last_name', memberObjIndex);
                                        }}
                                        name={'Last Name'}
                                        inputValue={memberObj.last_name}
                                        placeholder={'Last Name'}
                                        classes="mt-2 bg-transparent"
                                        disabled={memberObj?.disabled?.last_name}
                                    />
                                </div>

                                <div className="">
                                    <PhoneInputComponent
                                        value={memberObj.phone_number}
                                        title="Phone Number"
                                        placeholder="Enter Phone Number"
                                        titleSx=""
                                        sx="!rounded-xl bg-transparent"
                                        bg={'!bg-transparent'}
                                        atChange={(phone) => {
                                            handleEntityValue(phone, 'phone_number', memberObjIndex);
                                        }}
                                        isDisabled={memberObj?.disabled?.phone_number}
                                    />
                                </div>
                                <div className="">
                                    <EmailInput
                                        label={'Email Address'}
                                        onChange={(value) => {
                                            handleEntityValue(value, 'email_address', memberObjIndex);
                                        }}
                                        placeholder={'Email Address'}
                                        name={'Email Address'}
                                        value={memberObj.email_address}
                                        classes="mt-2 bg-transparent"
                                        disabled={memberObj?.disabled?.email_address}
                                    />
                                </div>

                                <div className="">
                                    <PickAddress
                                        id={'residential-autocomplete' + memberObjIndex}
                                        address={memberObj?.residential_address}
                                        setAddress={(address) => {
                                            handleEntityValue(address, 'residential_address', memberObjIndex);
                                        }}
                                        onSelect={(place) => {
                                            handleEntityValue(
                                                place.formatted_address,
                                                'residential_address',
                                                memberObjIndex
                                            );
                                        }}
                                        label="Physical Address"
                                        placeholder="Physical Address"
                                        classes="bg-transparent"
                                        disabled={memberObj?.disabled?.residential_address}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(2)}
                                type="button"
                            />

                            <Button
                                title={'Next'}
                                type="submit"
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AppointerDetail;
