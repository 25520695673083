import generateThunkAndSlice from '../thunk/thunk';
import { endpoint, methods } from 'apiEndpoints';

const { request: addAppThunk } = generateThunkAndSlice('addApp', endpoint.addApp, methods.POST);

const { slice: getAllSApps, request: getAllAppsThunk } = generateThunkAndSlice(
    'getAllApps',
    endpoint.getAllApps,
    methods.POST
);
const { request: deleteAppThunk } = generateThunkAndSlice('deleteApp', endpoint.deleteApp, methods.DELETE);

const { request: updateFavAppThunk } = generateThunkAndSlice('updateFavApp', endpoint.updateFavApp, methods.POST);

const { request: uploadAppImageThunk } = generateThunkAndSlice('uploadAppImage', endpoint.uploadAppImage, methods.POST);

const { request: editAppThunk } = generateThunkAndSlice('editApp', endpoint.editApp, methods.POST);
const { request: delAppImageThunk } = generateThunkAndSlice('delAppImage', endpoint.delAppImage, methods.POST);

export {
    addAppThunk,
    getAllAppsThunk,
    deleteAppThunk,
    updateFavAppThunk,
    uploadAppImageThunk,
    getAllSApps,
    editAppThunk,
    delAppImageThunk
};
