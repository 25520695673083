import { Button } from 'components/atoms/buttons/Button';
import { InputField } from 'components/atoms/inputs/InputField';
import { PickAddress } from 'global-components/PickAddress';
import React from 'react';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { NewPlusIcon } from 'utills/svgs/NewPlusIcon';

const SpecificIndividualForm = ({ setCurrentStep, specificIndList, setSpecificIndList, defaultSpecificIndObject }) => {
    const handleRemoveEntry = (index) => {
        const newInputs = [...specificIndList];
        newInputs.splice(index, 1);
        setSpecificIndList(newInputs);
    };
    const handleAddEntry = () => {
        setSpecificIndList([...specificIndList, defaultSpecificIndObject]);
    };

    const handleEntityValue = (value, targetProperty, index) => {
        setSpecificIndList((pre) => {
            return pre.map((prevObj, preInd) => {
                return {
                    ...prevObj,
                    [targetProperty]: preInd === index ? value : prevObj?.[targetProperty]
                };
            });
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let hasError = false;

        for (const obj of specificIndList) {
            const isAnyFieldFilled = Object.values(obj).some((value) => value.trim() !== '');

            if (isAnyFieldFilled) {
                const isAllFieldsFilled = Object.values(obj).every((value) => value.trim() !== '');
                if (!isAllFieldsFilled) {
                    hasError = true;
                    toastHandler('If any field is filled, all fields in the object must be filled.', TOAST_TYPE_ERROR);
                    break;
                }
            }
        }

        if (hasError) {
            return false;
        }

        setCurrentStep(4);
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <div className="table-title font-poppins font-semibold">Specific Individual Details</div>
                <div onClick={handleAddEntry} className="cursor-pointer">
                    <NewPlusIcon className="w-8 h-8" />
                </div>
            </div>

            <div>
                <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                >
                    {specificIndList?.map((treasurerObj, treasurerObjIndex) => (
                        <div
                            className=" !bg-dimThird p-5  rounded-xl mt-5  "
                            key={'specificIndList' + treasurerObjIndex}
                        >
                            <div
                                onClick={() => handleRemoveEntry(treasurerObjIndex)}
                                className="cursor-pointer w-fit ml-auto flex justify-end items-center"
                            >
                                <label className="invisible"> f</label>
                                <BgDeleteIcon />
                            </div>

                            <div className=" default-forms grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-5  rounded-xl ">
                                <div className="">
                                    <InputField
                                        label={'First Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'first_name', treasurerObjIndex);
                                        }}
                                        name={'First Name'}
                                        inputValue={treasurerObj.first_name}
                                        placeholder={'First Name'}
                                        classes="mt-2 bg-transparent"
                                    />
                                </div>
                                <div className="">
                                    <InputField
                                        label={'Last Name'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'last_name', treasurerObjIndex);
                                        }}
                                        name={'Last Name'}
                                        inputValue={treasurerObj.last_name}
                                        placeholder={'Last Name'}
                                        classes="mt-2 bg-transparent"
                                    />
                                </div>
                                <div className="">
                                    <InputField
                                        label={'Relationship'}
                                        maxLength={30}
                                        allowSpaces={true}
                                        onChange={(e) => {
                                            handleEntityValue(e.target.value, 'relationship', treasurerObjIndex);
                                        }}
                                        name={'Relationship'}
                                        inputValue={treasurerObj.relationship}
                                        placeholder={'Enter Relationship'}
                                        classes="mt-2 bg-transparent"
                                    />
                                </div>

                                <div className="">
                                    <label className="!flex items-center gap-2 mb-2 text-ellipsis">
                                        Benefit Percentage
                                    </label>

                                    <input
                                        type="number"
                                        value={treasurerObj.benefit_percentage}
                                        className="bg-transparent px-2"
                                        placeholder="Enter Benefit Percentage"
                                        onChange={(e) =>
                                            handleEntityValue(e.target.value, 'benefit_percentage', treasurerObjIndex)
                                        }
                                    />
                                </div>

                                <div className="">
                                    <PickAddress
                                        id={'residential-autocomplete' + treasurerObjIndex}
                                        address={treasurerObj?.address}
                                        setAddress={(address) => {
                                            handleEntityValue(address, 'address', treasurerObjIndex);
                                        }}
                                        onSelect={(place) => {
                                            handleEntityValue(place.formatted_address, 'address', treasurerObjIndex);
                                        }}
                                        label="Physical Address"
                                        placeholder="Physical Address"
                                        classes="bg-transparent"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="flex justify-center mt-24 items-center">
                        <div className="flex gap-8 items-center">
                            <Button
                                title="Back"
                                classes={
                                    'border-blueish text-blueish border-2 h-[3rem] rounded-lg buttons-font-lighter font-fira px-14'
                                }
                                atClick={() => setCurrentStep(2)}
                                type="button"
                            />

                            <Button
                                title={'Next'}
                                classes={'ann-btn rounded-lg px-14 buttons-font-lighter font-fira'}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SpecificIndividualForm;
