import React from 'react';
import { BackArrow } from 'utills/svgs/BackArrow';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import { QrCodeMobile } from 'utills/svgs/QrCodeMobile';

export const GoogleAuthenticationInstructions = (props) => {
    return (
        <div className="md:flex justify-center flex-wrap  items-center w-[100%] min-h-[60vh] gap-2 overflow-y-auto relative">
            <div className="white  shadow-md max-w-[630px] rounded-lg min-h-[60vh]  bg-white p-3">
                <div>
                    <div className="one-head  pt-2 flex   ">
                        <div className="one-head px-3 pt-2 flex  gap-1 ">
                            <div onClick={() => props.handleCondition(0)} className="arrow-wrapper mt-1 cursor-pointer">
                                <BackArrow />
                            </div>
                            <div>
                                {' '}
                                <h1>Instructions For Google Authenticator</h1>
                            </div>
                        </div>
                    </div>

                    <div className="google-text flex gap-2 mx-5 mt-3 items-center">
                        <div>
                            <div className=" ">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>Download the Google Authenticator App</span>
                        </div>
                    </div>

                    <div className="google-inner-text text-sm px-5 mt-2">
                        <p>
                            We recommended downloading Duo Mobile or Google Authenticator if you don’t have one
                            installed.
                        </p>
                    </div>

                    <div className="google-text mt-3 flex gap-2 items-center mx-5">
                        <div>
                            <div className="">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>Scan the QR Code</span>
                        </div>
                    </div>

                    <div className="google-inner-text px-5 mt-2 text-sm">
                        <p>
                            Open the Google Authenticator app on your phone and tap the &quot;+&quot; icon or the option
                            to add a new account.
                        </p>
                        <p>Use your phone&apos;s camera to scan the QR code displayed on your account setup page.</p>
                    </div>

                    <div className="m-5">
                        <QrCodeMobile />
                    </div>
                    <div className="mx-5 mt-3 font-[600] text-2xl text-blueish flex gap-10 items-center">
                        <span>XXXX</span> <span>XXXX</span> <span>XXXX</span> <span>XXXX</span>
                    </div>

                    <div className="google-text flex gap-2 items-center  mx-5 mt-10">
                        <div>
                            <div className="">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>Copy and enter 6-digit code</span>
                        </div>
                    </div>

                    <div className="google-inner-text px-5 mt-2 text-sm">
                        <p>
                            Once you have scanned the QR code or entered the key, your authentication app will generate
                            a 6-digit code. Please copy the code and return here to enter it.
                        </p>
                    </div>

                    <div className="flex justify-center  mt-20 mb-5">
                        <button onClick={() => props.handleCondition(4)} className="ann-btn rounded-lg px-16 ">
                            Next{' '}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
