import React, { useState } from 'react';
import DeductionPage from '../../../components/workplace-content/deduction/DeductionPage';
import AddDeduction from '../../../components/workplace-content/deduction/AddDeduction';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { EditDeduction } from 'components/workplace-content/deduction/EditDeduction';
import { SidebarLayout } from 'layouts/SidebarLayout';
const Deductioon = () => {
    const [showAddDeduction, setShowAddDeduction] = useState(false);
    const [deductionUpdated, setDeductionUpdated] = useState(false);
    const [showEditDeduction, setEditDeduction] = useState(false);

    function toggleAddDeduction() {
        setShowAddDeduction(!showAddDeduction);
    }
    const toggleEditDeduction = () => {
        setEditDeduction(!showEditDeduction);
    };

    const toggleDeduction = () => {
        setDeductionUpdated(!deductionUpdated);
    };

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {showAddDeduction && (
                    <AddDeduction
                        toggleAddDeduction={toggleAddDeduction}
                        toggleDeduction={toggleDeduction}
                        open={showAddDeduction}
                        setOpen={setShowAddDeduction}
                    />
                )}
                {toggleEditDeduction && (
                    <EditDeduction
                        toggleDeduction={toggleDeduction}
                        open={showEditDeduction}
                        setOpen={setEditDeduction}
                    />
                )}
                <RoleBasedGuard module={'Deduction'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    {' '}
                    <div>
                        <DeductionPage
                            toggleAddDeduction={toggleAddDeduction}
                            deductionUpdated={deductionUpdated}
                            toggleEditDeduction={toggleEditDeduction}
                        />
                    </div>{' '}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};

export default Deductioon;
