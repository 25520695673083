import { Jumbotron } from 'components/organisms/Jumbotron';
import { useGetAllEmployees } from 'Hooks/useGetAllEmployees';
import { SidebarLayout } from 'layouts/SidebarLayout';
import AddSigner from 'pages/documents/add-signer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { fetchSynkliCustomersThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { JobManagerRequestSignatureThunk } from 'store/JobManager';
import { BUSINESS_ID, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';

const JobManagerRequestSignature = () => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { jobId } = useParams();
    const { data: job } = useSelector((state) => state.getSingleJob);

    const { getEmployees } = useGetAllEmployees();
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);
    const { data: loginData } = useSelector((state) => state.login);

    const [file, setFile] = useState(null);

    useEffect(() => {
        if (Array.isArray(state) && state?.length > 0) {
            setFile({ ...state[0] });
        }
    }, [state]);

    const customers = [];

    const fetchCustomers = () => {
        const payload = {
            business_id,
            customer_type: false,
            is_deleted: true,
            page: 1,
            page_size: 10,
            client_type: 'all_client'
        };

        dispatch(fetchSynkliCustomersThunk(payload)).catch((error) => {
            console.error(error);
        });
    };
    useEffect(() => {
        fetchCustomers();
        getEmployees();
    }, []);

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/job-details/${jobId}?activeTab=tools`);
        }
    };

    const handleSubmit = (data) => {
        const tempFields = [];
        const fieldObj = formatFields(data.fields);
        for (const item in fieldObj) {
            tempFields.push({
                user_id: item,
                fields: fieldObj[item]
            });
        }
        const payload = {
            authentication_file: file?.url,
            job_drive_id: file?.job_drive_id,
            job_id: file?.job_id,
            employee_id: loginData.user_id,
            business_id: business_id,
            document_title: data.title,
            users: tempFields
        };
        dispatch(setLoader(true));
        dispatch(JobManagerRequestSignatureThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toastHandler('Submitted Successfully.', TOAST_TYPE_SUCCESS);
                    navigate(`/job-details/${jobId}?activeTab=tools`);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const formatFields = (fields) => {
        let obj = {};
        for (const item of fields) {
            if (obj[item.user_id] === undefined) {
                obj[item.user_id] = [];
            }
            obj[item.user_id].push({
                field_type: item.type,
                page: item.pageNumber,
                position_x: item.pageX,
                position_y: item.pageY,
                width: item.pageWidth,
                height: item.pageHeight
            });
        }
        return obj;
    };

    return (
        <SidebarLayout>
            <div className="app-dashboard">
                <div>
                    <div className="pt-4">
                        <Jumbotron
                            title="Request Signature"
                            path={[
                                { title: 'Job Manager', link: 'job-manager' },
                                { title: 'Jobs', link: 'job-manager/jobs' },
                                { title: 'Job Details', link: `job-details/${jobId}?activeTab=workStation` },
                                { title: 'Request Signature', link: `` }
                            ]}
                            backArrowProp={backArrowProp}
                        />
                        <div className="md:mx-10 mx-5">
                            <div>
                                <div className="inherit w-full h-full">
                                    <AddSigner
                                        pdfString={file?.url}
                                        file_manager_id={''}
                                        feilds={[]}
                                        id={null}
                                        fileItem={file}
                                        customers={customers}
                                        employees={employees}
                                        isOpen={true}
                                        onSubmit={handleSubmit}
                                        preClients={job?.clients ?? []}
                                        preEmployees={job?.manager ?? []}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};

export default JobManagerRequestSignature;
