import React from 'react';

export const Telephone = () => {
    return (
        <div>
            <svg width="22" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.72706 6.85093L6.45546 6.36533C7.49675 5.67114 7.77813 4.26425 7.08393 3.22296L5.60815 1.00929L5.60815 1.00929C5.00223 0.100404 3.83413 -0.246839 2.83011 0.183456L2.16438 0.468769C1.44126 0.778679 0.851385 1.33487 0.499545 2.03855C0.265149 2.50734 0.0394917 3.08445 0.00857145 3.74211C-0.0711253 5.43721 0.32822 9.28693 4.52069 13.4794C8.71316 17.6719 12.5629 18.0712 14.258 17.9915C14.9156 17.9606 15.4927 17.7349 15.9615 17.5005C16.6652 17.1487 17.2214 16.5588 17.5313 15.8357L17.8166 15.17C18.2469 14.1659 17.8997 12.9978 16.9908 12.3919L14.7771 10.9161C13.7358 10.2219 12.3289 10.5033 11.6347 11.5446L11.1491 12.273C10.7683 12.3201 9.59009 12.2677 7.66126 10.3388C5.73242 8.40999 5.68 7.2318 5.72706 6.85093ZM11.9459 12.8807L12.4668 12.0993C12.8546 11.5175 13.6406 11.3603 14.2224 11.7482L16.4361 13.224C16.9439 13.5625 17.1379 14.2151 16.8975 14.776L16.6122 15.4418C16.3954 15.9475 16.0064 16.36 15.5143 16.6061C15.1031 16.8117 14.6702 16.971 14.211 16.9926C12.7639 17.0606 9.19886 16.7434 5.2278 12.7723C1.25674 8.80123 0.939434 5.23613 1.00747 3.78907C1.02906 3.32989 1.18839 2.89692 1.39397 2.48576C1.64004 1.99364 2.05257 1.60465 2.5583 1.38791L3.22403 1.1026C3.78497 0.862198 4.43758 1.0562 4.7761 1.56399L6.25188 3.77766C6.63972 4.35942 6.48252 5.14544 5.90076 5.53328L5.11937 6.0542C4.96341 6.15818 4.83032 6.2957 4.78244 6.47692C4.62558 7.07055 4.55476 8.64655 6.95415 11.0459C9.35354 13.4453 10.9295 13.3745 11.5231 13.2176C11.7044 13.1697 11.8419 13.0367 11.9459 12.8807Z"
                    fill="url(#paint0_linear_1375_217)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_1375_217"
                        x1="9.88321"
                        y1="0.223543"
                        x2="9.86974"
                        y2="18.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
