import React from 'react';

export const AnchorIcon = () => {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.96388 10.2367C1.48819 8.91262 2.15473 7.44643 3.40938 6.74364L5.86015 5.37085C6.29509 5.12722 6.44711 4.5768 6.19599 4.15481C5.94487 3.73283 5.37755 3.58534 4.9426 3.82898L2.57549 5.15491C0.392384 6.37777 -0.634142 9.01942 0.412333 11.2465C0.689759 11.8423 1.09274 12.375 1.59533 12.8105C2.09791 13.246 2.68894 13.5744 3.33035 13.7748C3.97175 13.9751 4.6493 14.0429 5.31935 13.9737C5.98939 13.9045 6.63706 13.6999 7.22064 13.3731L9.77178 11.9441C10.2067 11.7005 10.3587 11.1501 10.1076 10.7281C9.8565 10.3061 9.28918 10.1586 8.85423 10.4022L6.3031 11.8312C5.91822 12.0453 5.48924 12.174 5.04699 12.2081C4.60475 12.2421 4.16022 12.1807 3.74534 12.0283C3.33046 11.8758 2.95554 11.6361 2.64752 11.3263C2.3395 11.0166 2.10603 10.6445 1.96388 10.2367ZM6.96185 9.19001L11.9805 6.37885C12.4405 6.12116 12.5996 5.54514 12.334 5.09881C12.0684 4.65247 11.4747 4.49813 11.0146 4.75582L5.99601 7.56698C5.53597 7.82467 5.37689 8.40069 5.64249 8.84702C5.9081 9.29335 6.50181 9.4477 6.96185 9.19001ZM10.5718 0.675791L8.20471 2.00172C7.76976 2.24536 7.61775 2.79577 7.86886 3.21776C8.11998 3.63975 8.6873 3.78723 9.12225 3.5436L11.573 2.17081C12.8277 1.46802 14.4697 1.64108 15.4138 2.70282C15.7067 3.02612 15.9221 3.40834 16.0446 3.82203C16.1671 4.23571 16.1936 4.67059 16.1222 5.0954C16.0509 5.52022 15.8834 5.92442 15.6319 6.27897C15.3803 6.63352 15.051 6.92961 14.6675 7.14597L12.1163 8.57498C11.6814 8.81861 11.5294 9.36903 11.7805 9.79101C12.0316 10.213 12.5989 10.3605 13.0339 10.1168L15.585 8.68784C18.0274 7.31974 18.7726 4.18649 17.0993 1.89937C15.6346 -0.0934284 12.7549 -0.547065 10.5718 0.675791Z"
                fill="#656565"
            />
        </svg>
    );
};
