import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cleanClassNames(classNames) {
    return classNames
        ? classNames
              .split(' ')
              .filter((cls) => cls !== 'undefined' && cls !== 'null')
              .join(' ')
        : '';
}

export function removeUndefinedAndNull(arr) {
    return arr.filter((item) => item !== undefined && item !== null);
}

export function combineStrings(arr) {
    return arr.filter((item) => item !== undefined && item !== null).join(' ');
}

export const cn = (...inputs) => {
    return twMerge(clsx(inputs));
};

export function mergeClasses(...classes) {
    return classes.filter(Boolean).join(' ');
}
