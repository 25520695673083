/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Button } from 'components/atoms/buttons/Button';
import { DownloadFileIcon } from 'utills/svgs/DownloadFileIcon';
import { SearchInput } from 'components/molecules/SearchInput';
import { SORT_OPTIONS } from 'utills/globalVars';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { Menu } from 'components/molecules/Menu';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import './css/organisms.css';
import { DropDownMenu } from 'components/molecules/DropDownMenu';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { Pdf } from 'utills/svgs/Pdf';
import { CsvPlusImageIcon } from 'utills/svgs/CsvPlusImageIcon';
import { RoundedPhotoIcon } from 'utills/svgs/RoundedPhotoIcon';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { useDispatch, useSelector } from 'react-redux';
import { formateDate } from 'utills/moment';
import { UseDownloadCsv } from 'Hooks/Files/UseDownloadCsv';
import { useDownloadPdf } from 'Hooks/Files/useDownloadPdf';
import { useDownloadZip } from 'Hooks/Files/useDownloadZip';
import { useDownloadImages } from 'Hooks/Files/useDownloadImages';
import { SmallLoader } from 'components/common/SmallLoader';
import { NoDataFound } from 'components/common/NoDataFound';
import { getSummaryDetailsThunk } from 'store/deductions';
import { DatePick } from 'components/atoms/inputs/DatePick';
import moment from 'moment';
import { TrippleSwitch } from '../atoms/inputs/TrippleSwitch';
import { PhotoIcon } from 'utills/svgs/PhotoIcon';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { toastHandler } from 'responseHanlder';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import dayjs from 'dayjs';
import Slideover from 'components/atoms/SlideOver';
import { useParams } from 'react-router-dom';

export const SummaryDrawer = ({ title, colSpanTwo, summaryType, hideGST, open, setOpen }) => {
    const { deduction_type } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const { id } = useParams();

    const [theSummary, setTheSummary] = useState([]);
    const [summaryCount, setSummaryCount] = useState(0);
    const [summaryInfo, setSummaryInfo] = useState(null);
    const [page, setPage] = useState(1);

    const [pageSize, _setPageSize] = useState(15);
    const [searchTerm, setSearchTerm] = useState('');
    const { summary_id } = useSelector((state) => state.global);
    const { handleDownloadCsv } = UseDownloadCsv();
    const { handleDownloadPdf } = useDownloadPdf();
    const { handleDownloadZip } = useDownloadZip();
    const { handleDownloadImages } = useDownloadImages();
    const { sideLoader } = useSelector((state) => state.global);

    const [sortAsc, setSortAsc] = useState(false);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [sumType, setSumType] = useState(summaryType);
    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomUrl, setZoomUrl] = useState(null);
    const [summaryDetailType, setSummaryDetailType] = useState('');
    const [defaultValues, setDefaultValues] = useState(null);

    const handleZoom = (url) => {
        setZoomUrl(url);
        setIsZoomed(!isZoomed);
    };

    const handleClear = () => {
        setTheSummary([]);
        setFromDate('');
        setToDate('');
        setSearchTerm('');
        setPage(1);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const datePickerProps = {
        right: CalendarSmallIco
    };

    const exportDataOptions = [
        {
            title: 'CSV File',
            icon: CsvIcon
        },
        {
            title: 'PDF File',
            icon: Pdf
        },
        {
            title: 'CSV + Image File',
            icon: CsvPlusImageIcon
        },
        {
            title: 'Images',
            icon: RoundedPhotoIcon
        }
    ];

    const getDetails = (filter) => {
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom = moment(new Date(fromDate)).format('DD-MM-YYYY');
            formatedTo = moment(new Date(toDate)).format('DD-MM-YYYY');
        }
        if (formatedFrom !== '' && formatedTo === '') {
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            return;
        }
        const payload = {
            business_client_id: id,
            summary_id: summary_id,
            summary_type: sumType,
            search_term: searchTerm,
            sort_by: sortAsc ? 'DESC' : 'ASC',
            page: page,
            page_size: pageSize,
            from: formatedFrom,
            to: formatedTo
        };
        setLoading(true);
        setHasMoreContent(true);
        dispatch(getSummaryDetailsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setSummaryInfo(response.payload?.summary);

                    if (!defaultValues) {
                        setDefaultValues({
                            from_date: response.payload?.summary?.from_date
                                ? dayjs(response.payload?.summary?.from_date, 'DD-MM-YYYY')
                                : '',
                            to_date: response.payload?.summary?.to_date
                                ? dayjs(response.payload?.summary?.to_date, 'DD-MM-YYYY')
                                : ''
                        });
                    }
                    setSummaryCount(response.payload?.detail?.count);
                    setSummaryDetailType(response.payload?.summary?.summary_type);
                    if (filter) {
                        setTheSummary([]);
                        const list = [...response.payload.detail?.data];
                        if (list.length === response?.payload?.detail?.count) {
                            setHasMoreContent(false);
                        }
                        setTheSummary(list);
                    } else {
                        const list = [...theSummary, ...response.payload.detail?.data];
                        if (list.length === response?.payload?.detail?.count) {
                            setHasMoreContent(false);
                        }
                        setTheSummary(list);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleMoreSummry = () => {
        const totalPages = Math.ceil(summaryCount / pageSize);
        if (page < totalPages) {
            setPage(page + 1);
        }
    };
    useEffect(() => {
        getDetails(true);
    }, [searchTerm, sortAsc, fromDate, toDate, sumType]);

    useEffect(() => {
        getDetails(false);
    }, [page]);

    const handleDownload = (type) => {
        if (theSummary.length === 0) {
            toastHandler('No File to download', 'error');
            return;
        }
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom = moment(new Date(fromDate)).format('DD-MM-YYYY');
            formatedTo = moment(new Date(toDate)).format('DD-MM-YYYY');
        }

        if (formatedFrom !== '' && formatedTo === '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (type === 'CSV File') {
            handleDownloadCsv({
                summary_type: sumType === 'all' ? summaryDetailType : sumType,
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summaryInfo?.ref_no,
                business_client_id: id
            });
        } else if (type === 'PDF File') {
            handleDownloadPdf({
                summary_type: sumType === 'all' ? summaryDetailType : sumType,
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summaryInfo?.ref_no,
                business_client_id: id
            });
        } else if (type === 'CSV + Image File') {
            handleDownloadZip({
                summary_type: sumType === 'all' ? summaryDetailType : sumType,
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summaryInfo?.ref_no,
                business_client_id: id
            });
        } else if (type === 'Images') {
            handleDownloadImages({
                summary_type: sumType === 'all' ? summaryDetailType : sumType,
                summary_id,
                from: formatedFrom,
                to: formatedTo,
                search_term: searchTerm,
                ref_no: summaryInfo?.ref_no,
                business_client_id: id
            });
        }
    };

    const downloadImages = () => {
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom = moment(new Date(fromDate)).format('DD-MM-YYYY');
            formatedTo = moment(new Date(toDate)).format('DD-MM-YYYY');
        }

        if (formatedFrom !== '' && formatedTo === '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        handleDownloadImages({
            summary_type: sumType,
            summary_id,
            from: formatedFrom,
            to: formatedTo,
            search_term: searchTerm,
            ref_no: summaryInfo?.ref_no,
            business_client_id: id
        });
    };
    const column = {
        col: {
            all: summaryInfo?.gst ? 8 : 7,
            expense: summaryInfo?.gst ? 6 : 5,
            income: summaryInfo?.gst ? 6 : 5
        },
        gap: {
            all: 10,
            expense: 10,
            income: 10
        }
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
    };

    const generateFilters = () => {
        return (
            <div className="flex gap-2 items-center flex-wrap">
                <SearchInput
                    width="250px"
                    atChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    value={searchTerm}
                    placeholder="Search"
                    inputBorder="1px solid #DCDCDC"
                />
                <Menu
                    title="Order By"
                    iconSet={{
                        first: SortIcon,
                        second: StepDownIcon
                    }}
                    options={SORT_OPTIONS}
                    gap={5}
                    lastMl={5}
                    isModalDisabled={true}
                    condition={sortAsc}
                    atMenuClick={() => {
                        handleSortByDate();
                    }}
                    text="max-md"
                />
                <DatePick
                    iconSet={datePickerProps}
                    onDateChange={handleFromDateChange}
                    placeholder="From"
                    value={fromDate}
                    defaultValue={defaultValues?.from_date}
                    minDate={moment(summaryInfo?.from_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                    maxDate={moment(summaryInfo?.to_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                />
                <DatePick
                    iconSet={datePickerProps}
                    onDateChange={handleToDateChange}
                    placeholder="To"
                    value={toDate}
                    defaultValue={defaultValues?.to_date}
                    minDate={moment(summaryInfo?.from_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                    maxDate={moment(summaryInfo?.to_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                />
                {toDate || fromDate || searchTerm ? <ClearFilterButton atClick={() => handleClear()} /> : ''}
            </div>
        );
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                showLoader
                size={'max-w-7xl'}
                title={title}
                titlesx={'title1 xl-title !font-semibold mt-0 py-2 flex gap-2 items-center'}
                hideBackArrow
            >
                {isZoomed && <ZoomImage src={zoomUrl} alt="Profile" onClose={() => setIsZoomed(false)} />}
                <div className="relative min-h-full pb-[0px]">
                    <div className="mt-4 flex justify-between items-baseline">
                        {deduction_type !== 'tfn' ? (
                            <TrippleSwitch
                                titleOne={'All'}
                                titleTwo="Income"
                                titleThree="Expense"
                                border="1px solid #BBBBBB"
                                atClick={(title) => {
                                    if (title === 'All') {
                                        setSumType('all');
                                        handleClear();
                                    } else if (title === 'Expense') {
                                        setSumType('expense');
                                        handleClear();
                                    } else if (title === 'Income') {
                                        setSumType('income');
                                        handleClear();
                                    }
                                }}
                            />
                        ) : (
                            <div>{generateFilters()}</div>
                        )}
                        <div className="flex gap-2 items-center">
                            <DropDownMenu
                                isLoading={sideLoader ? true : false}
                                isButton={true}
                                options={exportDataOptions}
                                atClick={(type) => handleDownload(type)}
                            />

                            <Button
                                title="Download Images"
                                classes="ann-btn !text-sm px-3  flex gap-2 items-center"
                                iconSet={{ leftIcon: DownloadFileIcon, loader: SmallLoader }}
                                atClick={() => downloadImages()}
                            />
                        </div>
                    </div>
                    {deduction_type !== 'tfn' && (
                        <div className="mt-5">
                            <div>{generateFilters()}</div>
                        </div>
                    )}
                    <div className="my-5 ">
                        {theSummary && theSummary?.length > 0 && (
                            <InfiniteScrollComp
                                height={470}
                                dataLength={theSummary.length}
                                next={handleMoreSummry}
                                hasMore={hasMoreContent || false}
                                loader={<SkeletonTable columns={6} />}
                            >
                                <div className="mt-10 ">
                                    <div
                                        className={`grid  grid-cols-${column.col[summaryInfo?.summary_type]} gap-${
                                            column.gap[summaryInfo?.summary_type]
                                        }`}
                                    >
                                        <div className={`default-table-heading `}>
                                            <span> Date</span>
                                        </div>
                                        <div className={`default-table-heading `}>
                                            <span> Description</span>
                                        </div>
                                        {['expense', 'all'].includes(summaryInfo?.summary_type) && (
                                            <div className={`default-table-heading `}>
                                                <span> Type</span>
                                            </div>
                                        )}
                                        {['income', 'all'].includes(summaryInfo?.summary_type) && (
                                            <div className={`default-table-heading `}>
                                                <span> Payment Method</span>
                                            </div>
                                        )}

                                        {summaryInfo.gst &&
                                            ['income', 'expense', 'all'].includes(summaryInfo?.summary_type) &&
                                            !hideGST && (
                                                <div className={`default-table-heading `}>
                                                    <span> GST</span>
                                                </div>
                                            )}

                                        {['income', 'all'].includes(summaryInfo?.summary_type) && (
                                            <div className={`default-table-heading `}>
                                                <span> Income</span>
                                            </div>
                                        )}
                                        {['expense', 'all'].includes(summaryInfo?.summary_type) && (
                                            <div className={`default-table-heading `}>
                                                <span> Expense</span>
                                            </div>
                                        )}

                                        <div className={`default-table-heading `}>
                                            <span> Image </span>
                                        </div>
                                    </div>
                                    <hr className="mt-2" />
                                    {theSummary && theSummary.length > 0 ? (
                                        theSummary?.map((item, index) => (
                                            <div key={'thesummary' + index} className="mt-2">
                                                {' '}
                                                <div
                                                    className={`grid grid-cols-${
                                                        column.col[summaryInfo.summary_type]
                                                    } gap-${column.gap[summaryInfo.summary_type]}`}
                                                >
                                                    <div className="summary-body flex items-center break-all">
                                                        {formateDate(item?.transaction_date)}
                                                    </div>
                                                    <div
                                                        className={` summary-body flex break-all items-center gap-5 ${
                                                            colSpanTwo?.includes(1) && ''
                                                        } `}
                                                    >
                                                        {item?.description ?? 'N/A'}
                                                    </div>
                                                    {['expense', 'all'].includes(summaryInfo.summary_type) && (
                                                        <div className="summary-body flex items-center break-all">
                                                            {item?.label ?? 'N/A'}
                                                        </div>
                                                    )}
                                                    {summaryInfo.summary_type === 'all' && (
                                                        <div className="summary-body flex items-center break-all">
                                                            {item?.payment_method ?? 'N/A'}
                                                        </div>
                                                    )}
                                                    {summaryInfo.summary_type === 'income' && (
                                                        <div className="summary-body flex items-center break-all">
                                                            {item?.name ?? 'N/A'}
                                                        </div>
                                                    )}
                                                    {summaryInfo.gst &&
                                                        ['income', 'expense', 'all'].includes(
                                                            summaryInfo.summary_type
                                                        ) &&
                                                        !hideGST && (
                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.gst_percentage ? item?.gst_percentage : 'No GST'}
                                                            </div>
                                                        )}
                                                    {summaryInfo.summary_type !== 'all' && (
                                                        <div className="summary-body flex items-center break-all">
                                                            {' '}
                                                            ${item?.amount}
                                                        </div>
                                                    )}
                                                    {summaryInfo.summary_type === 'all' && (
                                                        <>
                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.income ? `$${item?.income}` : 'N/A'}
                                                            </div>
                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.expense ? `$${item?.expense}` : 'N/A'}
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="summary-body flex items-center break-all">
                                                        {item?.invoice_image ? (
                                                            <button className="summary-btn rounded-lg flex gap-1  p-1 px-2 items-center">
                                                                <span>
                                                                    <PhotoIcon />
                                                                </span>
                                                                <span onClick={() => handleZoom(item?.invoice_image)}>
                                                                    {item?.invoice_image
                                                                        ? item.invoice_image.slice(-8)
                                                                        : 'No Image'}
                                                                </span>
                                                            </button>
                                                        ) : (
                                                            <span>No Image</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <hr className="mt-2 " />
                                            </div>
                                        ))
                                    ) : (
                                        <>
                                            {loading && theSummary.length === 0 ? (
                                                <SkeletonTable columns={6} />
                                            ) : (
                                                <NoDataFound message="No Data Found" />
                                            )}
                                        </>
                                    )}
                                </div>
                            </InfiniteScrollComp>
                        )}
                    </div>
                    {theSummary.length > 0 ? (
                        <div
                            className="bg-white py-3 absolute -bottom-[0px] w-full border-secondarybg border-t-[1px]"
                            style={{ width: '-webkit-fill-available!important' }}
                        >
                            {summaryInfo && theSummary?.length > 0 && (
                                <div>
                                    {sumType !== 'all' ? (
                                        <div className=" flex justify-between items-center mx-5 primary-fonts text-xl">
                                            <div className="">
                                                Total <span className="capitalize"> {sumType} </span> Reported
                                            </div>
                                            <div className="">
                                                ${summaryInfo?.[sumType] ? summaryInfo?.[sumType] : 0}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mt-3 flex justify-between items-center mx-5 primary-fonts text-xl">
                                            <div className="">
                                                Total <span className="capitalize"> Income </span> Reported
                                            </div>
                                            <div className="">
                                                $
                                                {summaryInfo.summary_type !== 'all'
                                                    ? summaryInfo.summary_type === 'income'
                                                        ? summaryInfo?.income
                                                        : 0
                                                    : summaryInfo?.income || 0}
                                            </div>
                                            <div className="">
                                                Total <span className="capitalize"> Expense </span> Reported
                                            </div>
                                            <div className="">
                                                $
                                                {summaryInfo.summary_type !== 'all'
                                                    ? summaryInfo.summary_type === 'expense'
                                                        ? summaryInfo?.expense
                                                        : 0
                                                    : summaryInfo?.expense || 0}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            {loading && theSummary.length === 0 ? (
                                <SkeletonTable columns={6} />
                            ) : (
                                <NoDataFound message="No Data Found" />
                            )}
                        </>
                    )}
                </div>
            </Slideover>
        </>
    );
};
