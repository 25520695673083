import React from "react";

export const SmallPlusIcon = () => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="10.5586" width="2.88" height="24" rx="1.44" fill="white" />
        <rect
          x="24"
          y="10.5625"
          width="2.88"
          height="24"
          rx="1.44"
          transform="rotate(90 24 10.5625)"
          fill="white"
        />
      </svg>
    </div>
  );
};
