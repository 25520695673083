/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { cn } from 'utills/tailwindUtil';
import cloneDeep from 'lodash/cloneDeep';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
const ProgressBarComponent = ({
    steps,
    targetProperty,
    activeStep,
    onClick,
    isSubmitted,
    hideArrows,
    divWidthValue,
    isDragable = true,
    customProgressWidth
}) => {
    const [stepsArray, setStepsArray] = useState([]);
    const [active, setActive] = useState(0);

    const containerRef = useRef(null);

    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    React.useEffect(() => {
        if (isDragable) {
            if (isDragging) {
                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
            } else {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            }

            return () => {
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            };
        }
    }, [isDragging, isDragable]);

    function removeUnnecessaryDots(str) {
        return str.replace(/\.{2,}/g, '.');
    }

    const labelChanger = (steps) => {
        let skipCount = 0;
        for (let i = 0; i < steps?.length; i++) {
            if (steps[i]?.step_condition) {
                skipCount = skipCount + 1;
            } else {
                const beforeNumber = steps[i - 1]?.step?.split('.');
                if (steps[i].step.includes('.')) {
                    steps[i].step = `${parseInt(beforeNumber[0])}.${steps[i].step.substring(
                        steps[i].step.indexOf('.')
                    )}`;
                    skipCount = skipCount + 1;
                } else {
                    steps[i].step = `${i - skipCount}`;
                }
            }
        }
        return steps;
    };

    const divWidth = divWidthValue ?? '150';

    useEffect(() => {
        setStepsArray(isSubmitted ? steps : labelChanger(cloneDeep(steps)));
        setActive(activeStep);
        smoothScroll(containerRef.current, (0 + activeStep - 1) * Number(divWidth), 300);
    }, [steps, activeStep]);

    const incrementAndConcat = (str) => {
        if (typeof str !== 'string' || str?.length === 0) {
            return str;
        }

        if (Number(str) >= 10) {
            return Number(removeUnnecessaryDots(`${Number(str) + 1}`));
        }

        if (Number(str < 10) || str?.length > 3) {
            const firstChar = parseInt(str[0]);
            if (!isNaN(firstChar)) {
                const incrementedChar = firstChar + 1;

                return removeUnnecessaryDots(incrementedChar.toString() + str.slice(1));
            }
        } else {
            return Number(removeUnnecessaryDots(str));
        }
        return str;
    };

    const handleClick = (step, index) => {
        if (onClick) {
            setActive(index);
            onClick(step, index);
            smoothScroll(containerRef.current, (index - 1) * Number(divWidth), 300);
        }
    };

    const calculateStepCount = () => {
        if (stepsArray?.length > 1) {
            const stepCount = (active / (stepsArray?.length - 1)) * 100;
            return stepCount.toFixed(0);
        } else {
            return 0;
        }
    };

    const handleScrollLeft = () => {
        if (containerRef.current) {
            smoothScroll(containerRef.current, containerRef.current.scrollLeft - Number(divWidth), 300);
        }
    };

    const handleScrollRight = () => {
        if (containerRef.current) {
            smoothScroll(containerRef.current, containerRef.current.scrollLeft + Number(divWidth), 300);
        }
    };

    const smoothScroll = (element, to, duration) => {
        const start = element.scrollLeft;
        const change = to - start;
        const startTime = performance.now();

        const animateScroll = (currentTime) => {
            const elapsed = currentTime - startTime;
            element.scrollLeft = easeInOutQuad(elapsed, start, change, duration);
            if (elapsed < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        requestAnimationFrame(animateScroll);
    };

    return (
        <div className=" overflow-hidden">
            <div className={cn('flex relative')}>
                {hideArrows || stepsArray?.length < 3 ? (
                    ' '
                ) : (
                    <div
                        className="w-10 text-center cursor-pointer absolute left-0 h-full z-[999] bg-white"
                        onClick={handleScrollLeft}
                    >
                        <span className="p-3">
                            <ChevronDoubleLeftIcon
                                className="w-5 mt-2 animate-bounce origin-right "
                                style={{ animation: 'bounce-left 3s infinite' }}
                            />
                        </span>
                    </div>
                )}

                <div
                    ref={containerRef}
                    className={cn(
                        'flex flex-1 preview-progress-bar mx-2 pb-3 relative ',
                        stepsArray?.length < 5 ? ' justify-between ' : '',
                        hideArrows || stepsArray?.length < 3 ? '' : 'px-10',
                        isDragable ? 'overflow-x-auto' : 'overflow-none'
                    )}
                    style={{
                        scrollbarWidth: 'none',
                        transition: 'scrollLeft 0.3s ease-in-out',
                        cursor: isDragable ? (isDragging ? 'grabbing' : 'grab') : 'auto'
                    }}
                    onMouseDown={handleMouseDown}
                >
                    <div
                        className={cn(
                            'absolute ml-2 mr-10 w-full bg-[#BBBBBB]',
                            divWidthValue ? 'h-[1px] top-[20px]' : 'h-[4px] top-[17px]'
                        )}
                        style={{
                            width: customProgressWidth
                                ? customProgressWidth
                                : stepsArray?.length > 4
                                ? divWidth * stepsArray?.length - divWidth + 'px'
                                : `calc(${(100 / stepsArray?.length - 1) * stepsArray?.length + '%'} - ${
                                      divWidth - (hideArrows ? (divWidthValue ? 150 : 50) : 100)
                                  }px)`
                        }}
                    >
                        <div
                            className={cn(
                                'bg-linear absolute content !h-[4px] !transition-all !duration-300 !m-0 ',
                                stepsArray?.length < 5 ? 'justify-between' : ''
                            )}
                            style={{ width: `${calculateStepCount()}%`, height: 'inherit!important' }}
                        ></div>
                    </div>
                    {stepsArray?.map((step, index) => (
                        <div
                            key={'step_' + index}
                            className={cn(
                                `flex-shrink-0 break-normal relative`,
                                stepsArray?.length > 3 && index !== stepsArray?.length - 1 ? `w-[${divWidth}px]` : '',
                                index === stepsArray?.length - 1 && '!bg-white'
                            )}
                            style={{
                                textAlign: '-webkit-left'
                            }}
                        >
                            <div
                                onClick={() => handleClick(step, index)}
                                className={cn(
                                    'py-2 px-4 w-12 h-10 flex items-center justify-center text-white rounded-full text-sm ',
                                    index <= active ? 'transition-all duration-300 bg-linear' : 'bg-[#BBBBBB]',
                                    index <= active && 'delay-active-color',
                                    hideArrows ? ' ' : ' cursor-pointer'
                                )}
                            >
                                {isSubmitted ? step?.step : incrementAndConcat(step?.[targetProperty])}
                            </div>
                            <div
                                className={`break-normal text-sm my-2 font-semibold text-gray-900 ${
                                    hideArrows ? ' max-w-[150px] ' : ' max-w-[60px] '
                                }`}
                            >
                                {step?.step_heading}
                            </div>
                        </div>
                    ))}
                </div>
                {hideArrows || stepsArray?.length < 3 ? (
                    ''
                ) : (
                    <div className="w-10 absolute right-0 h-full z-[999] bg-white">
                        <span className="p-3 pl-0 cursor-pointer " onClick={handleScrollRight}>
                            <ChevronDoubleRightIcon
                                className="w-5 mt-2 animate-bounce origin-right "
                                style={{ animation: 'bounce-left 3s infinite' }}
                            />
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProgressBarComponent;
