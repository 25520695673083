import React from 'react';

export const RefreshIcon = () => {
    return (
        <div>
            <svg width="26" height="24" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.05" width="36" height="30" rx="15" fill="url(#paint0_linear_1418_788)" />
                <g clipPath="url(#clip0_1418_788)">
                    <path
                        d="M17.2763 5.02331C17.2138 5.03112 16.9912 5.05846 16.788 5.0819C14.6318 5.32799 12.499 6.38268 10.9052 7.99987C7.60835 11.3358 7.09663 16.6131 9.69819 20.5077C10.3076 21.4217 10.9912 22.1678 11.7998 22.8124C12.0302 22.9921 12.2177 23.1522 12.2177 23.1678C12.2177 23.1835 12.1513 23.2069 12.0732 23.2225C11.6123 23.3046 11.1396 23.4452 11.0537 23.5272C10.7529 23.8085 10.788 24.2772 11.1318 24.496C11.2373 24.5585 11.3037 24.5702 11.4951 24.5585C11.7959 24.5311 14.038 24.0155 14.1982 23.9335C14.3623 23.8475 14.4834 23.6405 14.4834 23.4452C14.4834 23.3006 14.0498 21.3006 13.9169 20.8358C13.8466 20.5897 13.6591 20.41 13.4326 20.371C13.124 20.3202 12.8037 20.5546 12.7451 20.8788C12.7255 20.9725 12.8818 21.7811 12.9873 22.1288C13.0107 22.203 13.0068 22.203 12.8974 22.1288C12.4755 21.8553 11.7451 21.1796 11.3115 20.6639C9.46382 18.4608 8.81148 15.5194 9.54976 12.7147C10.0341 10.8631 11.1982 9.14049 12.7255 8.00377C14.0146 7.04674 15.1552 6.56627 16.8271 6.28112C17.0146 6.24987 17.5107 6.23034 18.2216 6.23034C19.2646 6.23034 19.3271 6.22643 19.4287 6.15221C19.7724 5.89049 19.7412 5.32799 19.3662 5.13659C19.3037 5.10534 19.0888 5.06237 18.8896 5.03893C18.5498 5.00377 17.538 4.99596 17.2763 5.02331Z"
                        fill="url(#paint1_linear_1418_788)"
                    />
                    <path
                        d="M23.6037 5.49217C23.1975 5.58592 22.6232 5.71873 22.3342 5.78514C21.8537 5.89842 21.7951 5.92186 21.6623 6.05076C21.424 6.29295 21.424 6.32811 21.7639 7.80467C21.9279 8.52732 22.092 9.17185 22.1311 9.23826C22.1701 9.30467 22.2795 9.39451 22.3811 9.44529C22.635 9.57029 22.8459 9.53904 23.0451 9.33982C23.26 9.12498 23.2678 8.99607 23.1193 8.32811C23.049 8.01951 22.9982 7.7617 23.0061 7.75389C23.0334 7.72654 23.6936 8.25389 24.0607 8.59373C25.799 10.2109 26.8264 12.5859 26.8264 15C26.8264 18.3164 24.9475 21.3633 21.9904 22.8515C20.5881 23.5547 19.2912 23.8281 17.6076 23.7734L16.8068 23.75L16.6662 23.8437C16.299 24.0937 16.3264 24.664 16.7131 24.8633C16.9943 25.0117 18.5256 25.0351 19.424 24.9062C21.1271 24.6601 22.6818 24.0117 24.0842 22.9609C24.5646 22.6015 25.4162 21.7734 25.8146 21.2773C26.9514 19.8672 27.6584 18.25 27.924 16.4648C28.0295 15.7461 28.0295 14.2539 27.924 13.5351C27.5412 10.9336 26.1506 8.59764 24.0451 7.00779C23.7717 6.80076 23.6818 6.71873 23.7326 6.70311C23.7678 6.69529 24.01 6.6367 24.2678 6.58201C24.9318 6.43357 25.1232 6.27732 25.1232 5.89061C25.1232 5.67967 25.0412 5.54295 24.8342 5.39842C24.6506 5.27732 24.4709 5.28904 23.6037 5.49217Z"
                        fill="url(#paint2_linear_1418_788)"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_1418_788"
                        x1="19.7664"
                        y1="0.37257"
                        x2="19.7476"
                        y2="30.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_1418_788"
                        x1="13.8555"
                        y1="24.5625"
                        x2="13.8555"
                        y2="5.00686"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_1418_788"
                        x1="22.2047"
                        y1="24.9902"
                        x2="22.2047"
                        y2="5.32046"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <clipPath id="clip0_1418_788">
                        <rect width="20" height="20" fill="white" transform="translate(8 5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
