import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getBranchLocationsThunk } from 'store/auth/slices';
import { BUSINESS_ID } from 'utills/globalVars';

export const useGetBranch = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [branches, setBranches] = useState([]);
    useEffect(() => {
        dispatch(getBranchLocationsThunk({ business_id }))
            .then((response) => {
                if (response.payload) {
                    setBranches(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    }, []);
    return { branches };
};
