import React from 'react';

export const RoundedPhotoIcon = () => {
    return (
        <div>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.96116 2.01562C5.92991 2.12343 4.98772 2.44218 4.21897 2.94843C3.83929 3.19687 3.22991 3.80156 2.98147 4.17187C2.69085 4.60312 2.3721 5.27812 2.25022 5.72812C1.99241 6.66562 2.00179 6.45468 1.98304 11.6016C1.96429 16.4297 1.97835 17.025 2.13304 17.7891C2.53147 19.7297 3.75491 21.1172 5.57835 21.6937C6.58616 22.0125 6.46897 22.0078 11.9768 22.0078C17.2877 22.0078 17.269 22.0078 18.1877 21.7687C18.7268 21.6281 19.5518 21.2344 19.969 20.9156C21.0283 20.1094 21.6658 18.975 21.9471 17.3906C22.0362 16.8797 22.0362 7.12031 21.9471 6.60937C21.7221 5.33437 21.2862 4.39218 20.5784 3.6375C19.819 2.83125 18.8909 2.35312 17.5455 2.07656C17.2268 2.01093 16.5752 2.00156 12.258 1.99218C9.55335 1.98281 7.16741 1.99687 6.96116 2.01562ZM16.9455 3.56718C17.5783 3.64687 18.019 3.77343 18.5159 4.01718C18.8862 4.19531 19.0315 4.30312 19.3643 4.63593C19.6971 4.96875 19.8049 5.11406 19.983 5.48437C20.2362 5.99062 20.3627 6.45 20.433 7.09687C20.494 7.62656 20.5033 12.5156 20.4471 12.5156C20.4237 12.5156 20.3674 12.4781 20.3252 12.4266C20.283 12.3797 20.1002 12.2719 19.9221 12.1922C19.3221 11.9109 18.5721 11.9203 17.9768 12.2062C17.7096 12.3328 17.2408 12.7172 15.5158 14.2031C14.344 15.2109 13.294 16.1016 13.1815 16.1812C12.7549 16.4766 12.108 16.6078 11.569 16.5C11.1893 16.425 10.8283 16.2422 10.4252 15.9141C9.82054 15.4172 9.47366 15.2812 8.80335 15.2812C8.1096 15.2812 8.03929 15.3187 5.85022 16.7859C4.77679 17.5031 3.88616 18.0937 3.8721 18.0937C3.84397 18.0937 3.7221 17.7141 3.62366 17.3203C3.55335 17.025 3.54397 16.5094 3.52991 12.3281C3.50647 6.6375 3.51585 6.50156 4.01741 5.48437C4.19554 5.11406 4.30335 4.96875 4.63616 4.63593C4.96897 4.30312 5.11429 4.19531 5.4846 4.01718C5.94866 3.7875 6.45022 3.6375 7.00804 3.56718C7.5096 3.50625 16.4346 3.50625 16.9455 3.56718Z"
                    fill="url(#paint0_linear_4409_56)"
                />
                <path
                    d="M8.64421 5.62971C7.73015 5.74221 6.91921 6.46877 6.68015 7.38284C6.45984 8.24065 6.75984 9.24846 7.40203 9.7969C8.3864 10.6453 9.80671 10.5938 10.7114 9.67971C11.1614 9.22502 11.3911 8.66721 11.3911 8.01565C11.3911 6.52034 10.1442 5.43752 8.64421 5.62971Z"
                    fill="url(#paint1_linear_4409_56)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_4409_56"
                        x1="12.9784"
                        y1="21.7592"
                        x2="12.9634"
                        y2="1.98916"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_4409_56"
                        x1="9.23441"
                        y1="10.3412"
                        x2="9.23081"
                        y2="5.60759"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
