import React from 'react';

export const JPEGFile = ({ className }) => {
    return (
        <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M48 0H6C2.7 0 0 2.7 0 6V48C0 51.3 2.7 54 6 54H48C51.3 54 54 51.3 54 48V6C54 2.7 51.3 0 48 0ZM18 31.5C18 34.8 15.3 36 12 36C8.7 36 6 34.8 6 31.5V27H10.5V31.5H13.5V18H18V31.5ZM33 25.5C33 27.9 30.9 30 28.5 30H25.5V36H21V18H28.5C30.9 18 33 20.1 33 22.5V25.5ZM48 22.5H40.5V31.5H43.5V27H48V32.1C48 34.2 46.5 36 44.1 36H40.2C37.8 36 36.3 33.9 36.3 32.1V22.2C36 20.1 37.5 18 39.9 18H43.8C46.2 18 47.7 20.1 47.7 21.9V22.5M25.5 22.5H28.5V25.5H25.5V22.5Z"
                fill="#3B3F76"
            />
        </svg>
    );
};
