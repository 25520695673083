import React from 'react';

export const UserLargeIcon = () => {
    return (
        <div>
            <svg width="50" height="71" viewBox="0 0 50 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M49.9704 48.9217L49.9652 53.9834L49.9674 54.0234C50.2831 59.7523 48.2988 63.8738 44.3317 66.639C40.2608 69.4766 33.9361 71 25.3966 71C16.8887 71 10.512 69.4981 6.30189 66.6765C2.18335 63.9163 0 59.8037 0 54.1209V48.9217C0 44.1281 3.8183 40.2734 8.49025 40.2734H41.4802C46.1516 40.2734 49.9696 44.1288 49.9704 48.9217ZM41.4802 44.1456H8.49025C5.86812 44.1456 3.7734 46.3012 3.7734 48.9217V54.1209C3.7734 56.2353 4.19568 58.1665 5.12657 59.8617C6.05978 61.5612 7.46101 62.9447 9.29711 64.0241C12.9184 66.1529 18.2788 67.1278 25.3966 67.1278C32.4751 67.1278 37.7538 66.1786 41.2431 64.0462C43.0188 62.961 44.3417 61.5626 45.1763 59.8393C46.0013 58.1358 46.3067 56.203 46.197 54.0947V48.9217C46.197 46.3012 44.1023 44.1456 41.4802 44.1456Z"
                    fill="#979797"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.9991 0C33.3196 0 40.0956 6.86113 40.0956 15.3633C40.0956 23.8655 33.3196 30.7266 24.9991 30.7266C16.6787 30.7266 9.90265 23.8655 9.90265 15.3633C9.90265 6.86113 16.6787 0 24.9991 0ZM24.9991 3.87223C18.7285 3.87223 13.6761 9.03421 13.6761 15.3633C13.6761 21.6924 18.7285 26.8544 24.9991 26.8544C31.2698 26.8544 36.3222 21.6924 36.3222 15.3633C36.3222 9.03421 31.2698 3.87223 24.9991 3.87223Z"
                    fill="#979797"
                />
            </svg>
        </div>
    );
};
