import React from 'react';

const ExpandIcon = ({ className }) => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_10018_414)">
                <path
                    d="M6.35774 0.0880585C3.5511 0.533371 1.26009 2.45525 0.387039 5.09782C0.0120392 6.2404 0.0354767 5.59587 0.0120392 14.6486C0.000320466 20.5314 0.0120392 23.0334 0.0589142 23.4259C0.410477 26.2795 2.39095 28.7052 5.1097 29.6134C6.2347 29.9884 5.60188 29.965 14.6488 29.9884C20.5316 30.0002 23.0335 29.9884 23.4261 29.9416C26.2796 29.59 28.7054 27.6095 29.6136 24.8908C29.9886 23.7658 29.9652 24.3986 29.9886 15.3517C30.0062 6.6447 30.0062 6.52165 29.7366 5.5197C29.0863 3.0822 27.1351 1.06071 24.7152 0.328293C23.6312 -0.00569153 23.7601 0.000167847 14.9417 0.00602722C8.66634 0.0118866 6.72102 0.0294647 6.35774 0.0880585ZM23.9359 1.92204C24.9788 2.22673 25.7523 2.66618 26.4788 3.36345C27.2523 4.11345 27.7679 4.98064 28.055 6.01775L28.2132 6.59196V15.0002V23.4084L28.055 23.9826C27.7855 24.967 27.3402 25.7404 26.637 26.4787C25.8929 27.2463 25.0199 27.7677 23.9827 28.0549L23.4085 28.2131L15.2406 28.2306C8.1097 28.2424 7.00227 28.2365 6.5511 28.1545C4.26595 27.7619 2.54915 26.1857 1.9222 23.9064L1.78743 23.4084V15.0002V6.59196L1.9222 6.09978C2.2093 5.05681 2.65462 4.25993 3.3636 3.52165C4.28352 2.57243 5.36751 2.01579 6.7386 1.799C6.82063 1.78142 10.6175 1.77556 15.1761 1.78142L23.4671 1.78728L23.9359 1.92204Z"
                    fill="#B695F8"
                />
                <path
                    d="M18.727 5.98852C18.4575 6.14672 18.2817 6.44555 18.2817 6.7561C18.2817 7.09594 18.5044 7.4475 18.8091 7.57641C18.8911 7.61157 19.4243 7.65258 19.9926 7.67016L21.0356 7.70532L19.1079 9.67407C17.0512 11.7717 17.0395 11.7893 17.1391 12.2405C17.2387 12.6975 17.7661 13.0081 18.229 12.8967C18.4633 12.8381 18.6801 12.6331 20.3969 10.8987C21.4458 9.83227 22.3188 8.96508 22.3423 8.96508C22.3657 8.96508 22.3833 9.43969 22.3833 10.0198C22.3833 11.1565 22.4067 11.262 22.6997 11.5373C22.9282 11.7541 23.4497 11.7776 23.7309 11.5901C24.1469 11.3088 24.1469 11.3206 24.1294 8.74828C24.1118 6.53344 24.1059 6.44555 23.9887 6.29321C23.9243 6.20532 23.7954 6.07641 23.7075 6.01196C23.5551 5.89477 23.4673 5.88891 21.2524 5.87133C18.9614 5.85961 18.9555 5.85961 18.727 5.98852Z"
                    fill="#B695F8"
                />
                <path
                    d="M11.6309 17.1621C11.4668 17.2324 10.8223 17.8535 9.55078 19.1484C8.53711 20.1855 7.69336 21.0352 7.66992 21.0352C7.64062 21.0352 7.61719 20.5195 7.60547 19.8926L7.58789 18.75L7.37109 18.5332C7.18359 18.3457 7.11328 18.3164 6.81445 18.293C6.52734 18.2754 6.43945 18.293 6.26953 18.4102C5.85938 18.6855 5.85938 18.6914 5.85938 21.1758C5.85938 23.3027 5.86523 23.4141 5.98242 23.6484C6.07031 23.8301 6.16992 23.9297 6.35156 24.0176C6.58594 24.1348 6.69727 24.1406 8.79492 24.1406C10.957 24.1406 10.9922 24.1406 11.25 24.0059C11.8594 23.6953 11.877 22.916 11.291 22.4766C11.1387 22.3652 11.0215 22.3535 10.0371 22.3242L8.94727 22.2949L10.8809 20.332C12.5332 18.6504 12.8203 18.334 12.8555 18.1465C12.9434 17.6895 12.668 17.2324 12.2402 17.1094C11.9707 17.0332 11.9004 17.0391 11.6309 17.1621Z"
                    fill="#B695F8"
                />
            </g>
            <defs>
                <clipPath id="clip0_10018_414">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ExpandIcon;
