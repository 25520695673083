import React from 'react';

export const XmlIcon = ({ className }) => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#2B3467"
            />
            <rect y="9" width="34" height="16.79" rx="4" fill="white" />
            <mask
                id="mask0_15913_266"
                style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="9"
                width="34"
                height="17"
            >
                <rect y="9" width="34" height="16.79" rx="4" fill="white" />
            </mask>
            <g mask="url(#mask0_15913_266)">
                <path
                    d="M22.1231 -5.01293V-17H-0.0741177C-0.394683 -17.0012 -0.712329 -16.9391 -1.00883 -16.8172C-1.30534 -16.6953 -1.57487 -16.5162 -1.80196 -16.2899C-2.02906 -16.0636 -2.20925 -15.7948 -2.3322 -15.4987C-2.45515 -15.2027 -2.51843 -14.8853 -2.51843 -14.5647V7.08237H36.5545V1.02119V-2.58665H24.5584C23.9141 -2.58665 23.296 -2.84198 22.8396 -3.29674C22.3832 -3.7515 22.1255 -4.36861 22.1231 -5.01293ZM38.1961 8.46237H-4.19608C-4.67451 8.46237 -5.13334 8.65243 -5.47164 8.99073C-5.80994 9.32903 -6 9.78786 -6 10.2663V24.6977C-6 25.1761 -5.80994 25.6349 -5.47164 25.9732C-5.13334 26.3115 -4.67451 26.5016 -4.19608 26.5016H38.1961C38.6745 26.5016 39.1333 26.3115 39.4716 25.9732C39.8099 25.6349 40 25.1761 40 24.6977V10.2663C40 9.78786 39.8099 9.32903 39.4716 8.99073C39.1333 8.65243 38.6745 8.46237 38.1961 8.46237ZM9.1349 23.2906L6.79882 19.349L4.49883 23.2906H2.05451L5.66235 17.4098L2.17176 11.7545H4.61608L6.81686 15.4706L8.99059 11.7545H11.4349L7.99843 17.3918L11.6063 23.2816L9.1349 23.2906ZM22.2675 23.2906H20.1569V16.9769L18.0553 21.8204H16.5851L14.4835 17.0941V23.3357H12.3729V11.7545H14.3122L17.3157 18.5102L20.3282 11.7545H22.2675V23.2906ZM31.9816 23.2906H24.351V11.7545H26.4976V21.2161H31.9816V23.2906Z"
                    fill="#2B3467"
                />
            </g>
        </svg>
    );
};
