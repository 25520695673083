import React, { useEffect, useRef, useState } from 'react';
import { cn } from 'utills/tailwindUtil';
import { generateId } from 'utills/uid';

export const Menu = ({
    iconSet,
    title,
    atClick,
    options,
    gap,
    lastMl,
    border,
    isModalDisabled,
    atMenuClick,
    condition,
    text,
    _isBetween,
    sx
}) => {
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);
    const toggleModal = () => {
        if (isModalDisabled) {
            atMenuClick();
        } else {
            setShowModal(!showModal);
        }
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        }

        document?.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);
    return (
        <div ref={modalRef}>
            <div
                onClick={toggleModal}
                style={{ border: border && border }}
                className={cn(
                    `sort-users flex rounded-xl   gap-${gap} items-center py-1.5 relative cursor-pointer`,
                    sx || 'text-sm px-2'
                )}
            >
                {iconSet?.first && (
                    <div className={`!text-gray-500  ${condition ? 'transform rotate-180' : ''}`}>
                        {iconSet?.first && <iconSet.first />}
                    </div>
                )}
                <div className={`text-${text}`}>{title}</div>
                {iconSet?.second && (
                    <div className={` ml-${lastMl}`}>
                        <span>
                            <iconSet.second />
                        </span>
                    </div>
                )}

                {showModal && !isModalDisabled && (
                    <div className="absolute top-full mt-1 z-50 left-0 right-0 !shadow-md rounded-md bg-white">
                        <div className="sort-modal rounded-md py-1.5">
                            {options?.map((option) => (
                                <div
                                    onClick={() => atClick(option.title)}
                                    className="sort-txt text-[14px] px-2 py-1.5"
                                    key={generateId()}
                                >
                                    {option.title}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
