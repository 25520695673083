import React from 'react';
import { CheckBadgeIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SideTimes } from 'utills/svgs/SideTimes';

export const toastHandler = (
    message,
    type,
    successIcon = <CheckBadgeIcon />,
    errorIcon = <ExclamationTriangleIcon className="w-5 text-red-500" />
) => {
    if (type === 'success') {
        toast.success(message, {
            autoClose: 3000,
            style: { backgroundColor: '#193265', color: 'white', borderRadius: '0.8rem' },
            closeButton: <SideTimes className={'h-5 w-5'} />,
            progressClassName: 'custom-progress-bar',
            pauseOnFocusLoss: false,
            icon: successIcon,
            position: 'bottom-left'
        });
    } else if (type === 'error') {
        toast.error(message, {
            autoClose: 3000,
            style: {
                backgroundColor: '#fff5f5',
                color: '#991b1b',
                borderRadius: '0.8rem',
                border: '1px solid #ef171563'
            },
            closeButton: <XCircleIcon className={'w-5 h-5 text-red-500'} />,
            progressClassName: 'custom-progress-bar',
            pauseOnFocusLoss: false,
            icon: errorIcon,
            position: 'bottom-left'
        });
    }
};
