import React, { useState } from 'react';

import OnlineBookingPage from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/OnlineBookingPage';
import AddServicePage from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/AddServicePage';
import BookingQuestionForm from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/BookingQuestionForm';
const OnlineBooking = () => {
    const [showAddService, setShowAddService] = useState(false);
    const [showBookingQuestionForm, setShowBookingQuestionForm] = useState(false);

    const toggleAddService = () => {
        setShowAddService(!showAddService);
    };

    const toggleBookingQuestionForm = () => {
        setShowBookingQuestionForm(!showBookingQuestionForm);
    };
    return (
        <div className="app-dashboard">
            {showAddService && (
                <AddServicePage
                    toggleAddService={toggleAddService}
                    toggleBookingQuestionForm={toggleBookingQuestionForm}
                />
            )}
            {showBookingQuestionForm && <BookingQuestionForm toggleBookingQuestionForm={toggleBookingQuestionForm} />}

            <div className={`content ${''}`}>
                <OnlineBookingPage
                    toggleAddService={toggleAddService}
                    toggleBookingQuestionForm={toggleBookingQuestionForm}
                />
            </div>
        </div>
    );
};
export default OnlineBooking;
