import React, { useEffect, useState } from 'react';
import './css/organisms.css';
import Select from 'react-select';

const customStyles = {
    control: (provided, _state) => ({
        ...provided,
        borderColor: '#979797',
        boxShadow: 'none',
        borderRadius: '0.5rem',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 500,
        fontSize: '0.8rem'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#b695f8',
        color: '#fff',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 400,
        fontSize: '0.7rem',
        borderRadius: '0.5rem'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#fff',
        ':hover': {
            backgroundColor: '#007bb5',
            color: '#fff'
        }
    }),
    menu: (provided) => ({
        ...provided,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 500,
        fontSize: '0.85rem'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 500,
        fontSize: '0.85rem'
    })
};

export const MultiSelectWithModal = ({
    _inputBorder,
    options,
    _atSelect,
    _showModal,
    atChange,
    selectedOptions,
    placeholder
}) => {
    const [sortedOptions, setSortedOptions] = useState([]);

    useEffect(() => {
        if (options) {
            const filteredOptions = [];
            options?.forEach((option, _index) => {
                filteredOptions?.push({
                    value: option?.first_name + option?.last_name,
                    label: option?.first_name + option?.last_name,
                    user_id: option?.user_id,
                    name: option?.first_name + option?.last_name,
                    id: option?.user_id,
                    email: option?.email
                });
            });

            setSortedOptions(filteredOptions);
        }
    }, [options]);

    const handleChange = (e) => {
        atChange(e);
    };

    return (
        <div>
            <Select
                defaultValue={selectedOptions}
                options={sortedOptions}
                isMulti
                onChange={handleChange}
                placeholder={placeholder}
                styles={customStyles}
            />
        </div>
    );
};
