import { Loader } from 'components/common/Loader';
import AuthCardLayout from 'layouts/AuthCardLayout';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { generateBackupCodesPreThunk, generateBackupCodesThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export const GoogleBackupCodes = (_props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);
    const [codes, setCodes] = useState(null);
    const { data } = useSelector((state) => state.login);
    const [codesCopied, setCodesCopied] = useState(false);
    const [codesDownloaded, setCodesDoanloaded] = useState(false);

    const isLost = sessionStorage.getItem('isLost');
    const firstLineCodes = codes?.slice(0, 5);
    const lastLineCodes = codes?.slice(5, 10);

    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};

    const contentRef = useRef(null);
    const secondContentRef = useRef(null);

    //download content
    const handleDownload = () => {
        const paragraphs = contentRef.current.getElementsByTagName('p');
        const secondP = secondContentRef.current.getElementsByTagName('p');
        let content = '';
        for (let i = 0; i < paragraphs.length; i++) {
            content += paragraphs[i].textContent + '\n';
        }

        for (let i = 0; i < secondP.length; i++) {
            content += secondP[i].textContent + '\n';
        }

        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'content.txt';
        downloadLink.click();
        URL.revokeObjectURL(url);
        setCodesDoanloaded(true);
    };

    //copy content

    const handleMerge = () => {
        const paragraphs1 = contentRef.current.getElementsByTagName('p');
        const paragraphs2 = secondContentRef.current.getElementsByTagName('p');
        let content = '';
        for (let i = 0; i < paragraphs1.length; i++) {
            content += paragraphs1[i].textContent + '\n';
        }
        for (let i = 0; i < paragraphs2.length; i++) {
            content += paragraphs2[i].textContent + '\n';
        }

        navigator.clipboard
            .writeText(content)
            .then(() => {
                NotificationManager.success('Codes copied to clipboard', 'Success');
                setCodesCopied(true);
            })
            .catch((error) => {
                console.error('Failed to copy content to clipboard:', error);
            });
    };

    useEffect(() => {
        const codeConfig = {
            email: parsedData.email,
            isBeforeLogin: true,
            user_type: 'employee'
        };
        const codeConfigSetup = {
            user_id: data?.user_id,
            user_type: 'employee'
        };
        dispatch(setLoader(true));

        dispatch(isLost ? generateBackupCodesThunk(codeConfigSetup) : generateBackupCodesPreThunk(codeConfig))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    setCodes(response.payload);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    const handleRegenrateCodes = async () => {
        const codeConfig = {
            email: parsedData.email,
            isBeforeLogin: true,
            user_type: 'employee'
        };

        const codeConfigSetup = {
            user_id: data?.user_id,
            user_type: 'employee'
        };
        dispatch(setLoader(true));

        await dispatch(isLost ? generateBackupCodesThunk(codeConfigSetup) : generateBackupCodesPreThunk(codeConfig))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    setCodes(response.payload);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleNext = () => {
        if (isLost) {
            sessionStorage.setItem('loginStepper', 7);
            navigate('/');
            return;
        }

        sessionStorage.setItem('loginStepper', 5);
        navigate('/');
    };

    return (
        <div className="flex min-h-[100vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <AuthCardLayout>
                {isLoading && <Loader />}
                <div>
                    <div>
                        <div className="text-center text-2xl  layoutcard-title font-secondary font-fira ">
                            Backup Codes
                        </div>

                        <div>
                            <div className="px-5 mt-3 backup-text">
                                You have the option to copy or download the backup codes. These backup codes will be
                                necessary if you need to reset your Google 2FA later on.
                            </div>
                        </div>

                        <div className="main-codes grid grid-cols-2 mx-20 mt-7 gap-16 shadow-md py-3">
                            <div ref={contentRef} className="justify-self-end ">
                                {firstLineCodes &&
                                    firstLineCodes.map((code) => <p key={code.user_backup_code_id}>{code.code}</p>)}
                            </div>
                            <div ref={secondContentRef} className=" ">
                                {lastLineCodes &&
                                    lastLineCodes.map((code) => <p key={code.user_backup_code_id}>{code.code}</p>)}
                            </div>
                        </div>

                        <div className="flex justify-center items-center gap-3 mt-7">
                            <button onClick={handleMerge} className=" blue-border-btn px-10 py-2 rounded-lg">
                                Copy Codes
                            </button>
                            <NotificationContainer />
                            <button onClick={handleDownload} className="btn-2 px-12 py-2 rounded-md">
                                Download
                            </button>
                        </div>

                        <div className="px-5 backup-text mt-5">
                            You can utilize any one of the codes provided above to reset your Google 2FA.
                        </div>

                        <div className="flex justify-between items-center  mb-10 mt-8 mx-20">
                            <button
                                onClick={handleRegenrateCodes}
                                className="btn-2 px-5 cursor-pointer py-2 rounded-md"
                            >
                                Get New Codes
                            </button>{' '}
                            <button
                                disabled={!codesCopied && !codesDownloaded}
                                onClick={() => handleNext()}
                                className={`btn-2 px-12  py-2 rounded-md ${
                                    !codesCopied && !codesDownloaded ? 'opacity-50' : ''
                                }`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};
