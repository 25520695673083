import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { createTaskCommentThunk, deleteActivityThunk } from 'store/workspace/workspaceTasks';
import { TOAST_TYPE_ERROR, USER_TYPE } from 'utills/globalVars';
import { bitsToMegabytes } from 'utills/moment';
import { AttachmentIcon } from 'utills/svgs/AttachmentIcon';
import { PhotoIconWhite } from 'utills/svgs/PhotoIconWhite';
import { generateId } from 'utills/uid';
import { EditCommentModal } from './EditCommentModal';
import { ReplyEditor } from './ReplyEditor';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import Badge from 'components/atoms/Badge';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import useRoleGuard from 'Hooks/useRoleGuard';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { Truncate } from 'components/molecules/Truncate';
import GenerateFileIcon from 'components/atoms/AttachmentComponents/FileIcons/GenerateFileIcon';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';

const Activity = ({ task, fetchActivity }) => {
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');
    const [files, setFiles] = useState([]);
    const [activityLoader, setActivityLoader] = useState(false);
    const { data: taskComments } = useSelector((state) => state.getActivity);
    const { data: loginData } = useSelector((state) => state.login);
    const { hasPermissions } = useRoleGuard();

    const [openpdf, setOpenpdf] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomUrl, setZoomUrl] = useState(null);

    const [editIndex, setEditIndex] = useState(null);
    const [showEditComment, setShowEditComment] = useState(false);

    const [allowAction, setAllowAction] = useState(false);

    useEffect(() => {
        if (hasPermissions('Tasks', ['admin', 'write'])) {
            setAllowAction(true);
        }
    }, []);

    const handleFilesChange = (e) => {
        const newfiles = e.target.files;

        if (newfiles.length > 3) {
            toastHandler('You can not select more than 3 files', TOAST_TYPE_ERROR);
            return;
        }
        if (newfiles.length > 0) {
            const files = e.target.files;

            const updatedImages = [...uploadingFiles];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                const reader = new FileReader();

                reader.onloadend = () => {
                    updatedImages.push(reader.result);

                    setUploadingFiles([...updatedImages]);
                };

                reader.readAsDataURL(file);
            }
        }

        setFiles([...files, ...newfiles]);
    };
    const handleRemove = (index) => {
        const newArr = [...files];
        const newPreview = [...uploadingFiles];

        const uploadingFile = newPreview[index];
        const file = newArr[index];

        const newIndex = newArr.indexOf(file);
        const uploadingIndex = newPreview?.indexOf(uploadingFile);

        newArr.splice(newIndex, 1);
        newPreview?.splice(uploadingIndex, 1);

        setUploadingFiles([...newPreview]);
        setFiles([...newArr]);
    };

    const handleSubmit = () => {
        if (!hasPermissions('Tasks', ['admin', 'write'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        if (!comment) {
            toastHandler('Please fill in all required fields', TOAST_TYPE_ERROR);
            return;
        }
        const formData = new FormData();
        formData.append('uploaded_by', USER_TYPE);
        formData.append('task_id', task?.task_id);
        formData.append('comment', comment);

        if (files) {
            files.forEach((file) => {
                formData.append('task_activity_docs', file);
            });
        }

        setActivityLoader(true);
        dispatch(createTaskCommentThunk(formData))
            .then((response) => {
                if (response.payload) {
                    fetchActivity();
                    setFiles([]);
                    setUploadingFiles([]);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setComment('');
                setActivityLoader(false);
            });
    };

    const deleteComment = (task_activity_id) => {
        const payload = {
            task_activity_id
        };

        dispatch(deleteActivityThunk(payload))
            .then((response) => {
                if (response.payload) {
                    fetchActivity();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [showEditReply, setShowEditReply] = useState('');
    const [editReplyIndex, setEditReplyIndex] = useState(null);

    const toggleEditReply = (index) => {
        setEditReplyIndex(index);
        setShowEditReply(!showEditReply);
    };
    return (
        <div>
            {openpdf && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpenpdf} open={openpdf} /> : ''}

            <div className="task-detail-main-card  text-black  mt-5 p-5 rounded-lg relative">
                <div className="flex justify-between items-center">
                    <div className="flex gap-2 items-center ">
                        <div>
                            <label className="text-lg font-semibold font-poppins text-blueish">Activity</label>
                        </div>
                    </div>
                </div>

                {isZoomed && zoomUrl ? <ZoomImage src={zoomUrl} alt="snow" onClose={() => setIsZoomed(false)} /> : ''}

                <div>
                    <div className="grid grid-cols-12 mt-5 gap-3 default-forms">
                        <div className="col-span-1 ">
                            <div>
                                <DefaultProfileViewer
                                    image={loginData?.image}
                                    atClick={() => {}}
                                    width="50px"
                                    height="50px"
                                    name={loginData?.first_name + ' ' + loginData?.last_name}
                                />
                            </div>
                        </div>
                        <input
                            id="fil-input"
                            type="file"
                            multiple
                            onChange={(e) => handleFilesChange(e)}
                            style={{ display: 'none' }}
                        />
                        <div className="md:col-span-9 col-span-7 md:ml-0 ml-5 flex items-center justify-center">
                            <div className="task-comment-input-container">
                                <input
                                    type="text"
                                    value={comment}
                                    className="task-comment-input !pr-[50px]"
                                    placeholder="Write a comment"
                                    onChange={(e) => setComment(e.target.value)}
                                />
                                <div className="task-comment-attachment-icon pl-2 bg-white cursor-pointer">
                                    <label htmlFor="fil-input">
                                        <div className="cursor-pointer">
                                            <AttachmentIcon />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 ">
                            <button
                                disabled={activityLoader ? true : false}
                                onClick={handleSubmit}
                                className="ann-btn px-7 py-2 rounded-lg buttons-font-lighter font-poppins "
                            >
                                {activityLoader ? <SmallLoaderWhite /> : 'Post'}
                            </button>
                        </div>
                    </div>

                    <div className="flex items-center flex-wrap gap-2">
                        {files.map((file, index) => (
                            <div key={'file' + index}>
                                <div className="flex items-start gap-3 my-3 flex-wrap">
                                    <Badge
                                        leftIcon={
                                            file.type.startsWith('image/') ? (
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt="alt"
                                                    className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                    onClick={() => {
                                                        if (file.type.startsWith('image/')) {
                                                            setIsZoomed(true);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <GenerateFileIcon
                                                    url={file?.type}
                                                    sx={'!w-10 !h-10 bg-white rounded-md mr-2 cursor-pointer'}
                                                />
                                            )
                                        }
                                        text={files[index]?.name?.slice(0, 7)}
                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                        onCancel={() => handleRemove(index)}
                                    />
                                    {isZoomed && (
                                        <ZoomImage
                                            src={URL.createObjectURL(file)}
                                            alt="snow"
                                            onClose={() => setIsZoomed(false)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="max-h-[50vh] overflow-y-auto">
                        {' '}
                        {taskComments?.map((cm, index) => (
                            <div key={generateId() + index}>
                                <div className="mt-5">
                                    <div className="grid grid-cols-12 gap-3">
                                        <div className="col-span-2 md:col-span-1">
                                            <DefaultProfileViewer
                                                image={cm?.commented_by?.image}
                                                height={'50px'}
                                                width={'50px'}
                                                name={cm?.commented_by?.first_name + ' ' + cm?.commented_by?.last_name}
                                            />
                                        </div>
                                        <div className="col-span-8 md:col-span-9 default-forms">
                                            <div>
                                                <label className="buttons-font font-poppins">
                                                    {cm?.commented_by?.first_name} {cm?.commented_by?.last_name}
                                                </label>
                                            </div>

                                            <div className="flex flex-wrap gap-5 my-2 items-center">
                                                {cm?.files?.map((file, index) => (
                                                    <FileCardPreview
                                                        url={file?.url}
                                                        key={index}
                                                        hideRemove={true}
                                                        name={file?.name}
                                                        size={''}
                                                        extension={
                                                            file?.url?.includes('.')
                                                                ? file?.url?.split('.')?.pop()?.toLowerCase()
                                                                : file?.url.split('/').pop().toLowerCase()
                                                        }
                                                        onClick={() => {
                                                            if (file?.url?.toLowerCase()?.endsWith('.pdf')) {
                                                                setPdfUrl(file?.url);
                                                                setOpenpdf(true);
                                                            } else if (
                                                                file?.url?.toLowerCase()?.endsWith('.jpg') ||
                                                                file?.url?.toLowerCase()?.endsWith('.jpeg') ||
                                                                file?.url?.toLowerCase()?.endsWith('.png')
                                                            ) {
                                                                setZoomUrl(file?.url);
                                                                setIsZoomed(true);
                                                            }
                                                        }}
                                                        onRemove={() => console.error(file?.task_files_id)}
                                                    />
                                                ))}
                                            </div>
                                            <div className="task-comment-data">
                                                <div className="buttons-font-lighter my-2 break-words">
                                                    <Truncate text={cm.comment} maxLength={100} />
                                                </div>
                                            </div>
                                            <div className="mt-2 att-actions text-xs font-poppins flex justify-between items-center ">
                                                <div>
                                                    <div className="att-actions flex items-center gap-2">
                                                        {(allowAction ||
                                                            cm?.commented_by?.user_id === loginData?.user_id) && (
                                                            <span
                                                                className="cursor-pointer underline"
                                                                onClick={() => deleteComment(cm?.task_activity_id)}
                                                            >
                                                                Delete
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div>{cm?.created_at}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 gap-3">
                                    <div className="col-span-1"> </div>
                                    <div className="col-span-9">
                                        {showEditComment && editIndex === index ? (
                                            <EditCommentModal
                                                index={index}
                                                fetchActivity={fetchActivity}
                                                setShowEditComment={setShowEditComment}
                                                setEditIndex={setEditIndex}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                {cm?.childs.length > 0 &&
                                    cm?.childs?.map((child, childIndex) => (
                                        <div key={'reply' + childIndex} className="mt-5 ml-10">
                                            <div className="grid grid-cols-12 gap-3">
                                                <div className=" col-span-1">
                                                    <DefaultProfileViewer
                                                        image={child?.commented_by?.image}
                                                        width={'50px'}
                                                        height={'50px'}
                                                        name={
                                                            child?.commented_by?.first_name +
                                                            ' ' +
                                                            child?.commented_by?.last_name
                                                        }
                                                    />
                                                </div>
                                                <div className="col-span-9 default-forms">
                                                    <div>
                                                        <label>
                                                            {child?.commented_by?.first_name}{' '}
                                                            {child?.commented_by?.last_name}
                                                        </label>
                                                    </div>
                                                    <div className="task-comment-data">
                                                        <div className="grid grid-cols-12 gap-3">
                                                            {child?.files?.length > 0 &&
                                                                child?.files.map((file, fileIndex) => (
                                                                    <div
                                                                        key={childIndex + ' ' + fileIndex}
                                                                        className="comment-info-container col-span-4"
                                                                    >
                                                                        <a
                                                                            href={file?.url}
                                                                            className="comment-file-container flex items-center gap-2 p-2 rounded-md"
                                                                        >
                                                                            <div>
                                                                                <PhotoIconWhite />
                                                                            </div>
                                                                            <div>
                                                                                <div className="comment-file-name">
                                                                                    {file?.name.slice(0, 7)}
                                                                                </div>
                                                                                <div className="comment-file-size">
                                                                                    {bitsToMegabytes(file?.size)} mb
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                        <div className="buttons-font-lighter my-2 ">
                                                            {child.comment}
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 att-actions flex justify-between items-center ">
                                                        <div>
                                                            <div className="att-actions text-xs font-poppins flex items-center gap-2">
                                                                <span
                                                                    className="cursor-pointer underline"
                                                                    onClick={() => toggleEditReply(childIndex)}
                                                                >
                                                                    Edit
                                                                </span>
                                                                <span
                                                                    className="cursor-pointer underline"
                                                                    onClick={() =>
                                                                        deleteComment(child?.task_activity_id)
                                                                    }
                                                                >
                                                                    Delete
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="text-xs font-poppins">{child?.created_at}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {showEditReply && editReplyIndex === childIndex ? (
                                                <ReplyEditor
                                                    child={child}
                                                    setShowEditReply={setShowEditReply}
                                                    fetchActivity={fetchActivity}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Activity;
