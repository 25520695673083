/* eslint-disable no-irregular-whitespace */
import Slideover from 'components/atoms/SlideOver';
import React from 'react';
import defaultUser from 'files/default.png';
const Correspondence = ({ open, setOpen }) => {
    return (
        <>
            <Slideover open={open} size={'max-w-7xl'} setOpen={setOpen} title={'Correspondence'} hideBackArrow>
                <div className="my-5">
                    <div className="px-10 pt-7 pb-10 rounded-lg custom-shadow">
                        <h3 className="text-xl font-semibold text-gray-900">Case Details</h3>
                        <div className="grid grid-cols-3 gap-9 mt-7">
                            <div className="flex flex-col gap-1">
                                <h4 className="text-md font-normal text-gray-900">Subject</h4>
                                <h4 className="text-md font-medium text-gray-900">Limited Information</h4>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h4 className="text-md font-normal text-gray-900">Case ID</h4>
                                <h4 className="text-md font-medium text-gray-900">CS123456789</h4>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h4 className="text-md font-normal text-gray-900">Created</h4>
                                <h4 className="text-md font-medium text-gray-900">12/04/2024 at 09:45 am</h4>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h4 className="text-md font-normal text-gray-900">Case Type</h4>
                                <h4 className="text-md font-medium text-gray-900">Limited Information</h4>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h4 className="text-md font-normal text-gray-900">Case Status</h4>
                                <h4 className="text-md font-medium text-green-600">Resolved</h4>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h4 className="text-md font-normal text-gray-900">Severity</h4>
                                <h4 className="text-md font-medium text-orange-400">General</h4>
                            </div>
                        </div>
                    </div>

                    <div className="px-10 pb-10 rounded-lg mt-5 custom-shadow">
                        <div className="py-10 border-b border-gray-300">
                            <div className="flex items-center">
                                <div className="mr-3">
                                    <img
                                        src={defaultUser}
                                        alt="dummy-img"
                                        className="w-[52px] h-[52px] rounded-full overflow-hidden shadow-md"
                                    />
                                </div>
                                <div>
                                    <h4 className="text-lg font-semibold">Jack Smith</h4>
                                    <p className="text-sm mt-0">Thursday April 11, 2024 at 09:45 pm</p>
                                </div>
                            </div>
                            <div className="flex flex-1 break-words text-md font-normal text-gray-900 mt-7">
                                Hello, <br />
                                <br />
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book. Lorem
                                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                been the industry&apos;s standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                        <div className="py-10 border-b border-gray-300">
                            <div className="flex items-center">
                                <div className="mr-3">
                                    <img
                                        src={defaultUser}
                                        alt="dummy-img"
                                        className="w-[52px] h-[52px] rounded-full overflow-hidden shadow-md"
                                    />
                                </div>
                                <div>
                                    <h4 className="text-lg font-semibold">Jack Smith</h4>
                                    <p className="text-sm mt-0">Thursday April 11, 2024 at 09:45 pm</p>
                                </div>
                            </div>
                            <div className="flex flex-1 break-words text-md font-normal text-gray-900 mt-7">
                                Hello, <br />
                                <br />
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry&apos;s standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book. Lorem
                                Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                been the industry&apos;s standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book.
                            </div>
                        </div>
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default Correspondence;
