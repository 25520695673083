export function generateId() {
    const randomPart = Math.random().toString(36).substr(2, 10);
    const timestampPart = new Date().getTime().toString(36).substr(-6);

    return randomPart + timestampPart;
}
export const formatNumberWithCommas = (number) => {
    if (number === null || number === undefined) return '';
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const generateRandomEmail = () => {
    const timestamp = Date.now();
    const timestampN = timestamp.toString().substring(2, 8);
    const randomChars = Math.random().toString(36).substring(2, 8);
    const fakeEmail = `${randomChars}${timestampN}@dummymail.com`;

    return fakeEmail;
};

export const truncateHtml = (html, limit) => {
    let charCount = 0;
    let isInsideTag = false;
    let truncatedHtml = '';

    for (let i = 0; i < html.length; i++) {
        const char = html[i];

        if (char === '<') {
            isInsideTag = true;
        }

        if (!isInsideTag && charCount >= limit) {
            truncatedHtml += '...';
            break;
        }

        truncatedHtml += char;

        if (!isInsideTag) {
            charCount++;
        }

        if (char === '>') {
            isInsideTag = false;
        }
    }

    const openTags = truncatedHtml.match(/<(\w+)[^>]*>(?!.*<\/\1>)/g) || [];
    openTags.reverse().forEach((tag) => {
        const tagName = tag.match(/<(\w+)/)[1];
        truncatedHtml += `</${tagName}>`;
    });

    return truncatedHtml;
};
