import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilesThunk } from 'store/fileManager';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID } from 'utills/globalVars';

export const useGetFiles = (folderName, business_client_id) => {
    const [files, setFiles] = useState([]);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: loginData } = useSelector((state) => state.login);
    const employee_id = loginData?.user_id;
    const dispatch = useDispatch();
    const getFilesHandler = () => {
        const payload = {
            folder_name: folderName,
            business_id,
            employee_id,
            business_client_id
        };
        dispatch(setLoader(true));
        dispatch(getFilesThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setFiles(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        getFilesHandler();
    }, []);
    return { files };
};
