import { useDispatch } from 'react-redux';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';

export const useGetAllEmployees = () => {
    const dispatch = useDispatch();
    const getEmployees = () => {
        dispatch(fetchRegisteredEmployeesThunk()).catch((error) => {
            console.error(error);
        });
    };
    return { getEmployees };
};
