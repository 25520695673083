/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from 'components/atoms/inputs/Input';
import React from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgPlusIcon } from 'utills/svgs/BgPlusIcon';
import { cn } from 'utills/tailwindUtil';

const TradingNames = ({ values, onChange, baseInputObj = { value: '' }, title = 'Trading Name', sx, itemsx }) => {
    const handleRemoveInput = (index) => {
        const newInputs = [...values];
        newInputs.splice(index, 1);
        onChange(newInputs);
    };

    return (
        <>
            <div className={cn('grid grid-cols-2 lg:grid-cols-3 gap-5 mt-3 default-forms', sx ? sx : '')}>
                {values?.map((inputObj, index) => (
                    <div className={itemsx ? itemsx : ''} key={'Trading_input' + index}>
                        <div className="flex items-center justify-between">
                            <label>{title}</label>
                            {index === 0 ? (
                                <div className="cursor-pointer" onClick={() => onChange([...values, baseInputObj])}>
                                    <BgPlusIcon className={'w-8 h-8 p-[4px]'} />
                                </div>
                            ) : (
                                <div className="cursor-pointer" onClick={() => handleRemoveInput(index)}>
                                    <BgDeleteIcon className={'w-8 h-8'} />
                                </div>
                            )}
                        </div>
                        <div className="mt-2 ">
                            <Input
                                onChange={(value) => {
                                    onChange(() => {
                                        return [...values]?.map((preObj, pre_ind) => ({
                                            ...preObj,
                                            value: pre_ind === index ? value : preObj.value
                                        }));
                                    });
                                }}
                                sx={'!bg-transparent'}
                                placeholder={title}
                                value={inputObj?.value}
                                disabled={inputObj?.disabled ? true : false}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default TradingNames;
