import React from 'react';
import BackupAlternatePage from '../../components/dashboard-components/auth/lost-two-fa-pages/BackupAlternatePage';
const BackupAlternate = () => {
    return (
        <div>
            <BackupAlternatePage />
        </div>
    );
};

export default BackupAlternate;
