import React from "react";

export const UploadWhiteIcon = () => {
  return (
    <div>
      <svg
        width="17"
        height="17"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6134 0.971095C10.4114 1.05703 4.79971 6.66016 4.6751 6.89649C4.5462 7.14141 4.55049 7.49805 4.68799 7.76445C4.83839 8.05664 5.08331 8.21992 5.40987 8.24141C5.88682 8.27578 5.80948 8.34023 8.02667 6.13164L10.0548 4.10352V9.90859C10.0548 16.268 10.0419 15.9371 10.304 16.2121C10.4931 16.4098 10.7079 16.5 11.0001 16.5C11.4298 16.5 11.7778 16.2508 11.8981 15.8598C11.9325 15.7395 11.9454 14.1195 11.9454 9.9V4.10352L13.9778 6.13164C16.1907 8.34023 16.1134 8.27578 16.5903 8.24141C16.9126 8.21992 17.1618 8.05664 17.3122 7.76445C17.4454 7.50234 17.4497 7.13711 17.3251 6.89219C17.1962 6.64297 11.5845 1.05274 11.3782 0.966798C11.1763 0.885158 10.811 0.885158 10.6134 0.971095Z"
          fill="white"
        />
        <path
          d="M1.43968 11.9844C1.22914 12.0832 1.05726 12.2594 0.971324 12.4699C0.872496 12.702 0.868199 16.9043 0.967027 17.45C1.285 19.2848 2.71586 20.7156 4.55062 21.0336C5.08773 21.1281 16.9127 21.1281 17.4498 21.0336C19.2846 20.7156 20.7155 19.2848 21.0334 17.45C21.1323 16.9043 21.128 12.702 21.0291 12.4699C20.7541 11.8039 19.8045 11.7051 19.3963 12.298L19.2717 12.4742L19.2459 14.7773C19.2159 17.248 19.2159 17.2437 18.9795 17.7551C18.6616 18.4426 18.0041 18.9754 17.2307 19.1687C16.8784 19.259 5.12211 19.259 4.76976 19.1687C3.99632 18.9754 3.3389 18.4426 3.02093 17.7551C2.78461 17.2437 2.78461 17.248 2.75453 14.7773L2.72875 12.4742L2.60414 12.298C2.36351 11.9457 1.8307 11.8039 1.43968 11.9844Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
