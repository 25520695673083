import { Button } from 'components/atoms/buttons/Button';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { createJobTypeThunk, getClientServicesThunk, getJobsTemplatesThunk, updateTypeThunk } from 'store/JobManager';
import { BUSINESS_ID, COMMON_TITLE_LIMIT, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';

export const AddJobType = ({ open, setOpen, onComplete, type, defaultName = null }) => {
    const formRef = useRef(null); // Create a form reference
    const { hasPermissions } = useRoleGuard();

    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [jobTemplate, setJobTemplate] = useState('');
    const [service, setService] = useState('');
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: services } = useSelector((state) => state.getClientServices);
    const { isLoading } = useSelector((state) => state.global);

    const { data: templates } = useSelector((state) => state.getJobsTemplates);

    const handleGetJobTemplates = () => {
        dispatch(setLoader(true));
        dispatch(getJobsTemplatesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        if (defaultName) {
            setTitle(defaultName);
        }
    }, [defaultName]);

    useEffect(() => {
        if (hasPermissions('Job', ['admin'])) {
            dispatch(setLoader(true));
            dispatch(getClientServicesThunk({ business_id }))
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });

            handleGetJobTemplates();
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!title) {
            return toastHandler('Job Type Title is Required', TOAST_TYPE_ERROR);
        }

        const payload = {
            business_id,
            job_template_id: jobTemplate,
            onboarding_service_id: service,
            label: title
        };

        dispatch(setLoader(true));
        dispatch(type ? updateTypeThunk({ ...payload, job_type_id: type?.job_type_id }) : createJobTypeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setOpen(false);
                    onComplete();
                    toastHandler(`Job type ${type ? 'updated' : 'added'} successfully`, TOAST_TYPE_SUCCESS);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (type) {
            setTitle(type?.label);
            setService(type?.service?.client_service_id ?? '');
            setJobTemplate(type?.template?.job_template_id ?? '');
        }
    }, [type]);
    const handleFormSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }
    };

    return (
        <Slideover
            open={open}
            setOpen={setOpen}
            footer={
                <div className="flex justify-center items-center mb-10 ">
                    <Button
                        title={type ? 'Update' : 'Add'}
                        buttonType={'submit'}
                        classes="ann-btn px-24 rounded-xl"
                        atClick={handleFormSubmit}
                    />
                </div>
            }
        >
            <RoleBasedGuard module="Job" permissions={['admin']} sx="p-5">
                {isLoading && <Loader />}
                <div className="text-2xl font-fira font-[500]"> {type ? 'Edit' : 'Add'} Job Type</div>
                <form onSubmit={handleSubmit} ref={formRef}>
                    {' '}
                    <div className="default-forms mt-10">
                        <label>Job Type Title </label>

                        <input
                            type="text"
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Enter job type title"
                            className="mt-2"
                            required
                            value={title}
                            maxLength={COMMON_TITLE_LIMIT}
                        />

                        <label className="mt-4">Job Template </label>

                        <SimpleSelectMenu
                            placeholder="Select Job Template"
                            targetProperty="name"
                            selectedValue={jobTemplate}
                            valuePropertyName="job_template_id"
                            optionsData={[{ name: 'none', job_template_id: '' }, ...(templates || [])] || []}
                            sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                            showChipOnly={false}
                            onChangeValue={(obj) => setJobTemplate(obj?.job_template_id)}
                        />

                        {templates?.length < 1 && (
                            <div className="bg-dimThird mt-3 rounded-xl p-3 text-sm font-poppins">
                                No job template found{' '}
                                <Link to="/job-manager/settings/job-templates" className="text-third" target="_blank">
                                    Click here
                                </Link>{' '}
                                to add
                            </div>
                        )}

                        <label className="mt-4">Onboarding Service</label>

                        <SimpleSelectMenu
                            placeholder="Select Onboarding Service"
                            targetProperty="title"
                            selectedValue={service}
                            valuePropertyName="client_service_id"
                            optionsData={services ? [{ title: 'none', client_service_id: '' }, ...services] : []}
                            sx="rounded-xl mt-2 py-3 ring-gray-400 !bg-transparent !font-normal cursor-pointer"
                            showChipOnly={false}
                            onChangeValue={(obj) => setService(obj?.client_service_id)}
                        />
                    </div>
                </form>{' '}
            </RoleBasedGuard>
        </Slideover>
    );
};
