import { useState } from 'react';
import { toastHandler } from 'responseHanlder';
import { getCookie } from 'utills/encryption/cryptoUtils';
import { APP_ENVIRONMENT, BASE_URL, CSRF_TOKEN } from 'utills/globalVars';

export const useCsvDownload = () => {
    const [fileLoader, setFileLoader] = useState(false);
    const downloadCsv = async (payload, url, type, isFirst, filename) => {
        let csrfToken = getCookie(CSRF_TOKEN);

        if (isFirst) {
            setFileLoader(true);
        }
        const _response = await fetch(`${BASE_URL}/${url}`, {
            method: type,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            credentials: 'include',
            ...(type !== 'GET' ? { body: JSON.stringify(payload) } : {})
        })
            .then(async (response) => {
                if (response.ok) {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = payload?.ref_no ? `${payload?.ref_no}_csv` : filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    toastHandler('File Has been downloaded!', 'success');
                }
            })
            .catch((_error) => {
                toastHandler('Something went wrong!');
            })
            .finally(() => {
                setFileLoader(false);
            });
    };
    return { downloadCsv, fileLoader };
};
