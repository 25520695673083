import React from 'react';

export const DollarsignIcon = ({ className }) => {
    return (
        <svg
            width="28"
            height="29"
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M13.6824 21.3794V21.2893L13.5926 21.28C11.9183 21.1068 10.4128 20.4236 9.07483 19.2823L10.3174 17.7697C11.3753 18.6903 12.4401 19.2152 13.5668 19.3931L13.6824 19.4114V19.2943V15.6007V15.5226L13.6066 15.5037C12.1784 15.1467 11.1277 14.7342 10.4922 14.1565L10.4923 14.1564L10.4882 14.1531C9.86182 13.6406 9.51214 12.8385 9.51214 11.7284C9.51214 10.6813 9.8598 9.81524 10.6119 9.12101C11.3652 8.42568 12.3536 8.0742 13.5278 8.01549L13.6228 8.01074V7.91562V7.06243H14.6143V7.91562V8.0073L14.7056 8.01524C16.026 8.13006 17.2918 8.58124 18.5039 9.37138L17.372 10.9345C16.548 10.3637 15.6639 10.0188 14.6664 9.90141L14.5547 9.88827V10.0007V13.5752V13.6752H14.6547H14.7019C16.1215 14.0309 17.1708 14.4426 17.8656 15.0208C18.4957 15.5944 18.8441 16.3993 18.8441 17.5071C18.8441 18.6113 18.4387 19.4764 17.688 20.1116L17.6879 20.1115L17.6847 20.1145C16.9327 20.8087 15.8857 21.1611 14.6499 21.22L14.5547 21.2245V21.3199V22.5901H13.6824V21.3794ZM12.2092 10.3992L12.2089 10.3988L12.201 10.4066C11.8883 10.7193 11.6951 11.101 11.6951 11.5497C11.6951 11.9843 11.8193 12.3706 12.0819 12.6331C12.3382 12.8895 12.835 13.1336 13.5507 13.3722L13.6824 13.4161V13.2773V10.0007V9.89022L13.5724 9.90122C12.961 9.96236 12.5248 10.1468 12.2092 10.3992ZM16.4531 16.6024L16.4535 16.6019L16.4448 16.595C16.1328 16.3453 15.6425 16.1025 14.8628 15.8626L14.7334 15.8228V15.9582V19.3539V19.4644L14.8434 19.4534C15.4533 19.3924 15.9506 19.2084 16.3272 18.8946C16.7045 18.5802 16.8994 18.1958 16.8994 17.7454C16.8994 17.307 16.773 16.9223 16.4531 16.6024Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.2"
            />
            <path
                d="M14 28.6351C6.39339 28.6351 0.25 22.4917 0.25 14.8851C0.25 7.27852 6.39339 1.13513 14 1.13513C21.6066 1.13513 27.75 7.27852 27.75 14.8851C27.75 22.4917 21.6066 28.6351 14 28.6351ZM14 2.95854C7.42789 2.95854 2.0734 8.31302 2.0734 14.8851C2.0734 21.4572 7.42789 26.8117 14 26.8117C20.5721 26.8117 25.9266 21.4572 25.9266 14.8851C25.9266 8.31302 20.5721 2.95854 14 2.95854Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.5"
            />
        </svg>
    );
};
