import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { deleteGroupThunk } from 'store/groups';

export const useDeleteGroup = () => {
    const dispatch = useDispatch();
    const handleDeleteGroup = (group_id, onComplete) => {
        dispatch(setLoader(true));
        dispatch(deleteGroupThunk({ group_id }))
            .then((response) => {
                if (response.payload) {
                    onComplete();
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return { handleDeleteGroup };
};
