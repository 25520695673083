import React from 'react';
import { PrimaryLabel } from '../typography/labels/PrimaryLabel';
import { cn } from 'utills/tailwindUtil';
import { generateId } from 'utills/uid';

export const PrimaryCheckbox = ({
    id,
    name,
    onchange = () => {},
    htmlFor = '',
    sx,
    inputSx,
    checked,
    label,
    key = generateId(),
    labelsx
}) => {
    return (
        <div key={key} className={cn('flex gap-2 items-center', sx)}>
            <div className={cn('flex  items-center')}>
                <input
                    id={id}
                    name={name}
                    type="checkbox"
                    className={cn(
                        'h-4 w-4 rounded cursor-pointer border-secondarybg border text-gray-800 focus:ring-gray-800',
                        inputSx
                    )}
                    onChange={(event) => onchange(event)}
                    defaultChecked={checked}
                />
            </div>
            {label && (
                <div className={cn('min-w-0 flex-1 text-md leading-6')}>
                    <PrimaryLabel htmlFor={htmlFor} sx={cn('font-[400] cursor-pointer', labelsx)}>
                        {label}
                    </PrimaryLabel>
                </div>
            )}
        </div>
    );
};
