import React from 'react';

export const ForwardIcon = ({ className }) => {
    return (
        <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="#B695F8"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d="M11.9714 0.529745C11.8966 0.575449 11.801 0.671013 11.7553 0.745802C11.6764 0.874607 11.6722 0.957706 11.6722 2.32053V3.75815L11.1861 3.78724C6.51593 4.06562 3.18365 6.23036 1.48011 10.0903C0.802851 11.6235 0.420594 13.3561 0.333339 15.3007C0.28348 16.356 0.32503 17.2119 0.437214 17.4031C0.682357 17.8186 1.343 17.856 1.59645 17.4695C1.63384 17.4114 1.72525 17.1164 1.80004 16.8131C2.5147 13.9212 4.78331 12.2509 8.63912 11.7689C9.27898 11.69 10.7208 11.6443 11.2692 11.6817L11.6722 11.7149V13.1733C11.6722 14.5611 11.6764 14.6442 11.7553 14.773C11.88 14.9766 12.067 15.0721 12.337 15.0721C12.4617 15.068 12.6071 15.0472 12.6611 15.0181C12.8315 14.9226 20.1941 8.19981 20.2564 8.08347C20.3312 7.93805 20.3312 7.58072 20.2564 7.4353C20.1941 7.31896 12.8315 0.596224 12.6611 0.50066C12.5115 0.417561 12.1293 0.434179 11.9714 0.529745ZM15.8189 5.21239C17.3438 6.60015 18.5903 7.74692 18.5903 7.75939C18.5903 7.78847 13.0558 12.8284 13.0226 12.8284C13.0101 12.8284 13.0018 12.3839 13.0018 11.8354C13.0018 10.8798 12.9977 10.8424 12.9063 10.7094C12.765 10.4934 12.6071 10.4227 12.1459 10.377C11.6058 10.3188 9.74434 10.3188 9.13772 10.377C5.9924 10.6679 3.61576 11.6401 2.16983 13.2232C2.02441 13.3769 1.90807 13.4808 1.90807 13.4558C1.90807 13.352 2.08258 12.5501 2.1823 12.1969C2.7266 10.2524 3.89415 8.37017 5.23205 7.28988C5.93008 6.72896 6.8691 6.19296 7.72918 5.87303C8.93412 5.42845 10.1266 5.20824 11.6806 5.14591C12.6362 5.10436 12.7276 5.07943 12.9063 4.80936C12.9977 4.6764 13.0018 4.63901 13.0018 3.68336C13.0018 3.13491 13.0101 2.69033 13.0226 2.69033C13.0351 2.69033 14.294 3.82463 15.8189 5.21239Z" />
        </svg>
    );
};
