import React from 'react';

export const OrangeTel = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="40" height="40" rx="20" fill="#DF921F" />
                <path
                    d="M16.2459 9.113L15.3063 9.40108C14.4696 9.65786 13.7225 10.1525 13.1527 10.8269C12.583 11.5012 12.2144 12.3272 12.0903 13.2075C11.7011 15.9656 12.5713 19.1905 14.6694 22.8889C16.7622 26.578 19.0661 28.9546 21.6019 29.9949C22.4166 30.3291 23.3086 30.4182 24.1716 30.2515C25.0347 30.0848 25.8325 29.6694 26.4703 29.0547L27.1806 28.3691C27.6419 27.9249 27.9289 27.3253 27.9885 26.6819C28.048 26.0384 27.876 25.3949 27.5043 24.8708L25.7273 22.3634C25.4871 22.0249 25.1487 21.7714 24.7598 21.6384C24.3709 21.5054 23.9508 21.4997 23.5584 21.6219L20.8706 22.4581L20.8012 22.4715C20.505 22.5155 19.8209 21.8633 18.9691 20.3615C18.078 18.7904 17.8945 17.8715 18.1396 17.6341L19.5064 16.3364C20.0062 15.8622 20.3477 15.2407 20.4828 14.559C20.618 13.8773 20.54 13.1696 20.2599 12.5353L19.3924 10.5734C19.1315 9.98304 18.6686 9.50935 18.0899 9.24065C17.5113 8.97196 16.8563 8.92659 16.2472 9.113H16.2459ZM18.1985 11.1202L19.0635 13.0821C19.2319 13.4625 19.279 13.8871 19.1981 14.2962C19.1173 14.7052 18.9125 15.0782 18.6126 15.3628L17.2419 16.6618C16.3652 17.5074 16.6561 18.9505 17.8355 21.0284C18.9442 22.9836 19.9559 23.9492 21.0436 23.7812L21.2061 23.7465L23.9424 22.8969C24.0732 22.856 24.2133 22.8578 24.343 22.9021C24.4728 22.9463 24.5856 23.0308 24.6658 23.1436L26.4428 25.651C26.6289 25.913 26.7151 26.2349 26.6855 26.5568C26.6558 26.8787 26.5123 27.1786 26.2816 27.4009L25.57 28.0864C25.1144 28.5252 24.5446 28.8217 23.9283 28.9406C23.312 29.0595 22.6751 28.9959 22.0933 28.7572C19.8681 27.845 17.7648 25.675 15.8056 22.2221C13.8411 18.7611 13.0457 15.8176 13.3877 13.3969C13.4763 12.7679 13.7395 12.1778 14.1465 11.6961C14.5535 11.2143 15.0873 10.8609 15.685 10.6774L16.6246 10.3894C16.9293 10.2962 17.2568 10.319 17.5461 10.4535C17.8355 10.5879 18.0669 10.8249 18.1972 11.1202H18.1985Z"
                    fill="#DF921F"
                    stroke="#DF921F"
                    strokeWidth="0.5"
                />
            </svg>
        </div>
    );
};
