import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: fetchBusinessCustomer, request: fetchBusinessCustomerThunk } = generateThunkAndSlice(
    'fetchBusinessCustomer',
    endpoint.fetchBusinessCustomer,
    methods.POST
);
const { slice: fetchBusinessAllCustomer, request: fetchBusinessCustomerAllThunk } = generateThunkAndSlice(
    'fetchBusinessAllCustomer',
    endpoint.fetchBusinessAllCustomer,
    methods.POST
);
const { request: fetchAllCustomersForTaskThunk } = generateThunkAndSlice(
    'fetchAllCustomersForTask',
    endpoint.fetchAllCustomersForTask,
    methods.POST
);
const { request: removeTaskChecklistThunk } = generateThunkAndSlice(
    'removeTaskChecklist',
    endpoint.removeTaskChecklist,
    methods.POST
);

const { request: removeTaskChecklistItemThunk } = generateThunkAndSlice(
    'removeTaskChecklistItem',
    endpoint.removeTaskChecklistItem,
    methods.POST
);
const { request: removeTaskEmployeeThunk } = generateThunkAndSlice(
    'removeTaskEmployee',
    endpoint.removeTaskEmployee,
    methods.POST
);

const { slice: viewBusinessCustomerInfo, request: viewBusinessCustomerInfoThunk } = generateThunkAndSlice(
    'viewBusinessCustomerInfo',
    endpoint.viewBusinessCustomerInfo,
    methods.POST
);

const { request: addToClientThunk } = generateThunkAndSlice('addToClient', endpoint.addToClient, methods.POST);

const { slice: addToInactiveClient, request: addToInactiveClientThunk } = generateThunkAndSlice(
    'addToInactiveClient',
    endpoint.addToInactiveClient,
    methods.POST
);

const { request: downloadLogsPfCsvThunk } = generateThunkAndSlice(
    'downloadLogsPfCsv',
    endpoint.downloadLogsPfCsv,
    methods.POST
);
const { request: sendClientInviteThunk } = generateThunkAndSlice(
    'sendClientInvite',
    endpoint.sendClientInvite,
    methods.POST
);

const { slice: getInvitedClients, request: getInvitedClientsThunk } = generateThunkAndSlice(
    'getInvitedClients',
    endpoint.getInvitedClients,
    methods.POST
);

const { request: resendClientInviteThunk } = generateThunkAndSlice(
    'resendClientInvite',
    endpoint.resendClientInvite,
    methods.POST
);

const { request: revokeInvitedClientThunk } = generateThunkAndSlice(
    'revokeInvitedClient',
    endpoint.revokeInvitedClient,
    methods.POST
);

const { request: deleteClientInviteThunk } = generateThunkAndSlice(
    'deleteClientInvite',
    endpoint.deleteClientInvite,
    methods.POST
);

const { request: updateClientInviteThunk } = generateThunkAndSlice(
    'updateClientInvite',
    endpoint.updateClientInvite,
    methods.POST
);

const { request: getCustomerStatsThunk } = generateThunkAndSlice(
    'getCustomerStats',
    endpoint.getCustomerStats,
    methods.POST
);

const { request: uploadBulkClientInviteFileThunk } = generateThunkAndSlice(
    'uploadBulkClientInviteFile',
    endpoint.uploadBulkClientInviteFile,
    methods.POST
);

const { slice: getinvitedFiles, request: getinvitedFilesThunk } = generateThunkAndSlice(
    'getinvitedFiles',
    endpoint.getinvitedFiles,
    methods.POST
);

const { request: addManualClientThunk } = generateThunkAndSlice(
    'addManualClient',
    endpoint.addManualClient,
    methods.POST
);

const { slice: getClientReferences, request: getClientReferencesThunk } = generateThunkAndSlice(
    'getClientReferences',
    endpoint.getClientReferences,
    methods.POST
);

const { request: updateManualClientThunk } = generateThunkAndSlice(
    'updateManualClient',
    endpoint.updateManualClient,
    methods.POST
);

const { request: deleteClientRefThunk } = generateThunkAndSlice(
    'deleteClientRef',
    endpoint.deleteClientRef,
    methods.POST
);

const { request: deleteBusinessClientThunk } = generateThunkAndSlice(
    'deleteBusinessClient',
    endpoint.deleteBusinessClient,
    methods.POST
);
const { slice: getClientTypes, request: getClientTypesThunk } = generateThunkAndSlice(
    'getClientTypes',
    endpoint.getClientTypes,
    methods.GET
);

const { request: saveRefpatternThunk } = generateThunkAndSlice('saveRefpattern', endpoint.saveRefpattern, methods.POST);
const { request: saveDefaultRefpatternThunk } = generateThunkAndSlice(
    'saveDefaultRefpattern',
    endpoint.saveDefaultRefpattern,
    methods.POST
);

const { slice: getRefPatterns, request: getRefPatternsThunk } = generateThunkAndSlice(
    'getRefPatterns',
    endpoint.getRefPatterns,
    methods.POST
);

const { request: checkClientUserNameThunk } = generateThunkAndSlice(
    'checkClientUserName',
    endpoint.checkClientUserName,
    methods.POST
);
const { slice: fetchSynkliCustomers, request: fetchSynkliCustomersThunk } = generateThunkAndSlice(
    'fetchSynkliCustomers',
    endpoint.fetchSynkliCustomers,
    methods.POST
);

const { slice: fetchSynkliProfiles, request: fetchSynkliProfilesThunk } = generateThunkAndSlice(
    'fetchSynkliProfiles',
    endpoint.fetchSynkliProfiles,
    methods.POST
);

const { request: uploadBulkImportFileThunk } = generateThunkAndSlice(
    'uploadBulkImportFile',
    endpoint.uploadBulkImportFile,
    methods.POST
);

const { slice: getBusinessClientInfo, request: getBusinessClientInfoThunk } = generateThunkAndSlice(
    'getBusinessClientInfo',
    endpoint.getBusinessClientInfo,
    methods.POST
);
const { slice: uploadedFileClients, request: uploadedFileClientsThunk } = generateThunkAndSlice(
    'uploadedFileClients',
    endpoint.uploadedFileClients,
    methods.POST
);

const { request: moveClientThunk } = generateThunkAndSlice('moveClient', endpoint.moveClient, methods.POST);

const { request: searchClientsThunk } = generateThunkAndSlice('searchClients', endpoint.searchClients, methods.POST);
const { request: updateClientProfileImageThunk } = generateThunkAndSlice(
    'updateClientProfileImage',
    endpoint.updateClientProfileImage,
    methods.POST
);

const { request: checkTfnAbnAcnThunk } = generateThunkAndSlice('checkTfnAbnAcn', endpoint.checkTfnAbnAcn, methods.POST);

const { request: verifyClientThunk } = generateThunkAndSlice('verifyClient', endpoint.verifyClient, methods.POST);

const { slice: getClientNomination, request: getClientNominationThunk } = generateThunkAndSlice(
    'getClientNomination',
    endpoint.getClientNomination,
    methods.GET
);

const { request: resetClientNominationThunk } = generateThunkAndSlice(
    'resetClientNomination',
    endpoint.resetClientNomination,
    methods.PUT
);
const { request: sendReminderClientNominationThunk } = generateThunkAndSlice(
    'sendReminderClientNomination',
    endpoint.sendReminderClientNomination,
    methods.GET
);
const { request: addClientToFavoriteThunk } = generateThunkAndSlice(
    'addClientToFavorite',
    endpoint.addClientToFavorite,
    methods.PUT
);
const { request: addClientToGroupThunk } = generateThunkAndSlice(
    'addClientToGroup',
    endpoint.addClientToGroup,
    methods.PUT
);
const { request: sendBulkInviteThunk } = generateThunkAndSlice('sendBulkInvite', endpoint.sendBulkInvite, methods.PUT);
const { slice: getClientsToMerge, request: getClientsToMergeThunk } = generateThunkAndSlice(
    'getClientsToMerge',
    endpoint.getClientsToMerge,
    methods.GET
);
const { request: mergeClientsThunk } = generateThunkAndSlice('mergeClients', endpoint.mergeClients, methods.POST);

const { request: getClientsDirectorsThunk } = generateThunkAndSlice(
    'getClientsDirectors',
    endpoint.getClientsDirectors,
    methods.GET
);

export {
    fetchBusinessCustomer,
    fetchBusinessCustomerThunk,
    viewBusinessCustomerInfoThunk,
    addToClientThunk,
    viewBusinessCustomerInfo,
    fetchBusinessCustomerAllThunk,
    fetchAllCustomersForTaskThunk,
    fetchBusinessAllCustomer,
    downloadLogsPfCsvThunk,
    addToInactiveClient,
    addToInactiveClientThunk,
    sendClientInviteThunk,
    getInvitedClientsThunk,
    getInvitedClients,
    resendClientInviteThunk,
    revokeInvitedClientThunk,
    deleteClientInviteThunk,
    updateClientInviteThunk,
    getCustomerStatsThunk,
    uploadBulkClientInviteFileThunk,
    getinvitedFilesThunk,
    getinvitedFiles,
    addManualClientThunk,
    getClientReferences,
    getClientReferencesThunk,
    updateManualClientThunk,
    deleteClientRefThunk,
    deleteBusinessClientThunk,
    getClientTypes,
    getClientTypesThunk,
    removeTaskChecklistThunk,
    removeTaskChecklistItemThunk,
    removeTaskEmployeeThunk,
    saveRefpatternThunk,
    saveDefaultRefpatternThunk,
    getRefPatternsThunk,
    getRefPatterns,
    checkClientUserNameThunk,
    fetchSynkliCustomers,
    fetchSynkliCustomersThunk,
    fetchSynkliProfiles,
    fetchSynkliProfilesThunk,
    uploadBulkImportFileThunk,
    getBusinessClientInfo,
    getBusinessClientInfoThunk,
    uploadedFileClients,
    uploadedFileClientsThunk,
    moveClientThunk,
    searchClientsThunk,
    updateClientProfileImageThunk,
    checkTfnAbnAcnThunk,
    verifyClientThunk,
    getClientNomination,
    getClientNominationThunk,
    resetClientNominationThunk,
    sendReminderClientNominationThunk,
    addClientToFavoriteThunk,
    addClientToGroupThunk,
    sendBulkInviteThunk,
    getClientsToMerge,
    getClientsToMergeThunk,
    getClientsDirectorsThunk,
    mergeClientsThunk
};
