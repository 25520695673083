import React from 'react';
import { BackArrow } from 'utills/svgs/BackArrow';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import { QrCodeMobile } from 'utills/svgs/QrCodeMobile';
import whitelogo from '../../files/whiteBgLogo.png.png';
export const GoogleInstructions = (props) => {
    return (
        <div className="md:flex justify-center flex-wrap  items-center w-[100%] h-[100vh] gap-2 overflow-y-auto relative">
            <div className="absolute left-5 top-5">
                <img src={whitelogo} alt="logo" className="Synkli-logo" />
            </div>
            <div className="white mt-28 md:mt-0   max-w-[650px] rounded-lg min-h-[90vh]  bg-white p-3">
                <div>
                    <div className="card-heading-two font-poppins flex gap-2 items-center ">
                        <div onClick={() => props.handleCondition(1)} className="arrow-wrapper mt-1 cursor-pointer">
                            <BackArrow />
                        </div>
                        <div>
                            {' '}
                            <h1>Instructions For Google Authenticator</h1>
                        </div>
                    </div>

                    <div className=" flex items-center gap-1 mx-5 mt-3">
                        <div>
                            <div>
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span className="buttons-font">Download the Google Authenticator App</span>
                        </div>
                    </div>

                    <div className="small-text font-poppins text-secondarybg px-5 mt-2">
                        <p>
                            We recommended downloading Duo Mobile or Google Authenticator if you don’t have one
                            installed.
                        </p>
                    </div>

                    <div className="buttons-font mt-3 items-center flex gap-1 mx-5">
                        <div>
                            <div>
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>Scan the QR Code</span>
                        </div>
                    </div>

                    <div className="small-text font-poppins text-secondarybg px-5 mt-2">
                        <p>
                            Open the Google Authenticator app on your phone and tap the &quot;+&quot; icon or the option
                            to add a new account.
                        </p>
                        <p>Use your phone&apos;s camera to scan the QR code displayed on your account setup page.</p>
                    </div>

                    <div className="m-5">
                        <QrCodeMobile />
                    </div>
                    <div className="mx-5 mt-3 text-2xl font-bold flex gap-10 items-center">
                        <span>XXXX</span> <span>XXXX</span> <span>XXXX</span> <span>XXXX</span>
                    </div>

                    <div className="buttons-font flex gap-1 items-center mx-5 mt-5">
                        <div>
                            <div className="scale-75">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>Copy and enter 6-digit code</span>
                        </div>
                    </div>

                    <div className="small-text font-poppins text-secondarybg px-5 mt-2">
                        <p>
                            Once you have scanned the QR code or entered the key, your authentication app will generate
                            a 6-digit code. Please copy the code and return here to enter it.
                        </p>
                    </div>

                    <div className="flex justify-center items-center mt-28">
                        <button onClick={() => props.handleCondition(3)} className="btn-2 px-16 buttons-font font-fira">
                            Next{' '}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
