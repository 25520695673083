import { Button } from 'components/atoms/buttons/Button';
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { EyeIcon } from 'utills/svgs/EyeIcon';
import { AddJobTemplate } from './AddJobTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTemplatesThunk, getJobsTemplatesThunk } from 'store/JobManager';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import FormateDateTime from 'components/atoms/FormateDateTime';
import ConfirmationModal from 'utills/confirmationModal';
import { JobTemplateDetails } from './JobTemplateDetails';
import moment from 'moment';
import { ArrowDownIcon, ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { toastHandler } from 'responseHanlder';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const JobTemplates = () => {
    const { hasPermissions } = useRoleGuard();

    const [searchTerm, setSearchTerm] = useState('');
    const [add, setAdd] = useState(false);
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.getJobsTemplates);
    const [jobs, setJobs] = useState([]);
    const [filters, setFilters] = useState({
        template: 'none',
        created_at: 'none',
        updated_at: 'none'
    });

    const business_id = localStorage.getItem(BUSINESS_ID);
    const checkbox = useRef();
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [editTemplate, setEditTemplate] = useState(null);
    const [templateDetails, setTemplateDetails] = useState(false);
    const [selectedDetailItem, setSelectedDetailItem] = useState(null);

    const { isLoading } = useSelector((state) => state.global);

    useLayoutEffect(() => {
        const isIndeterminate = selectedTemplate.length > 0 && selectedTemplate.length < jobs?.length;
        setChecked(jobs?.length > 0 && selectedTemplate.length === jobs?.length);
        setIndeterminate(isIndeterminate);

        if (checkbox?.current) {
            checkbox.current.indeterminate = isIndeterminate;
        }
    }, [selectedTemplate]);

    function toggleAll() {
        setSelectedTemplate(checked || indeterminate ? [] : jobs);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    const handleGetJobTemplates = () => {
        dispatch(setLoader(true));
        dispatch(getJobsTemplatesThunk({ business_id }))
            .then((response) => {
                if (response.payload) {
                    setJobs(response.payload);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    useEffect(() => {
        if (hasPermissions('Job', ['read', 'write', 'admin'])) {
            handleGetJobTemplates();
        }
    }, []);

    useEffect(() => {
        if (searchTerm.trim() === '') {
            setJobs(data);
        } else if (!isLoading) {
            const filteredJobs = jobs.filter((job) => job.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setJobs(filteredJobs);
        }
    }, [searchTerm]);

    const handleOpenConfirmation = (ids) => {
        setSelectedIds(ids);
        setIsConfirmationOpen(true);
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        dispatch(setLoader(false));

        dispatch(deleteTemplatesThunk({ ids: selectedIds, business_id }))
            .then((response) => {
                if (response.payload) {
                    setSelectedIds([]);
                    setSelectedTemplate([]);
                    handleGetJobTemplates();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (jobs && Object.values(filters).some((value) => value !== 'none')) {
            let NewjobsArray = [...jobs]?.sort((a, b) => {
                const { template, created_at, updated_at } = filters;

                if (template !== 'none') {
                    return template === 'ASC' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
                }

                if (created_at !== 'none') {
                    return created_at === 'ASC'
                        ? moment(a.created_at).diff(moment(b.created_at))
                        : moment(b.created_at).diff(moment(a.created_at));
                }
                if (updated_at !== 'none') {
                    return updated_at === 'ASC'
                        ? moment(a.updated_at).diff(moment(b.updated_at))
                        : moment(b.updated_at).diff(moment(a.updated_at));
                }

                return 0;
            });

            setJobs(NewjobsArray);
        }
    }, [filters]);
    const updateFiltersObject = (id, value) => {
        setFilters({
            template: 'none',
            created_at: 'none',
            updated_at: 'none',
            [id]: value
        });
    };
    const RenderSortByArrows = (name, value = '', id) => {
        return (
            <div className="flex items-center justify-start gap-1">
                <label
                    htmlFor={id + '_sort'}
                    onClick={() => {
                        updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                    }}
                    className="cursor-pointer"
                >
                    {name}
                </label>
                {value === 'ASC' ? (
                    <ArrowDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'DESC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : value === 'DESC' ? (
                    <ArrowUpIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, 'ASC');
                        }}
                        className="w-5 h-5 cursor-pointer"
                    />
                ) : (
                    <ArrowsUpDownIcon
                        id={id + '_sort'}
                        onClick={() => {
                            updateFiltersObject(id, value === 'DESC' ? 'ASC' : value === 'ASC' ? 'none' : 'DESC');
                        }}
                        className="w-4 h-4 text-[#979797] cursor-pointer"
                    />
                )}
            </div>
        );
    };

    return (
        <SidebarLayout>
            <RoleBasedGuard module="Job" permissions={['write', 'read', 'admin']} sx="p-5">
                <Jumbotron
                    title="Job Templates"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },
                        {
                            link: 'settings/job-manager',
                            title: 'Job Manager'
                        },
                        {
                            link: 'job-manager/settings/job-templates',
                            title: 'Job Templates'
                        }
                    ]}
                />
                {isLoading && <Loader />}
                {add && (
                    <AddJobTemplate
                        open={add}
                        setOpen={() => {
                            setAdd(false);
                            setEditTemplate(null);
                        }}
                        onComplete={handleGetJobTemplates}
                        template={editTemplate}
                    />
                )}

                {templateDetails && (
                    <JobTemplateDetails
                        open={templateDetails}
                        setOpen={setTemplateDetails}
                        template={selectedDetailItem}
                    />
                )}

                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={handleDelete}
                />

                <div className="mx-5 md:mx-10">
                    <div className="flex justify-between items-center gap-5 flex-wrap">
                        <SearchInput
                            width="320px"
                            defaultValue={searchTerm || ''}
                            atChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            placeholder="Search by job template"
                        />

                        <div className="flex gap-2 items-center">
                            {' '}
                            {selectedTemplate?.length > 0 && (
                                <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                        if (!hasPermissions('Job', ['admin'])) {
                                            return toastHandler(
                                                "You don't have permission to perform this action",
                                                TOAST_TYPE_ERROR
                                            );
                                        }

                                        handleOpenConfirmation(selectedTemplate?.map((job) => job?.job_template_id));
                                    }}
                                >
                                    <BgDeleteIcon />
                                </span>
                            )}
                            <Button
                                title="+Add Job Template"
                                atClick={() => setAdd(!add)}
                                classes="ann-btn px-8 rounded-xl"
                            />
                        </div>
                    </div>

                    <div className=" pb-5 rounded-xl overflow-x-auto">
                        <div className="mt-8 flow-root">
                            <div className="inline-block min-w-full py-4 align-middle">
                                <div className="relative">
                                    <table className="min-w-full table-fixed divide-y  divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                                                    <input
                                                        type="checkbox"
                                                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-secondary  text-indigo-600 focus:ring-indigo-600"
                                                        ref={checkbox}
                                                        checked={checked}
                                                        onChange={toggleAll}
                                                    />
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="min-w-[12rem] py-3.5 pr-3 text-left text-md font-semibold text-gray-900"
                                                >
                                                    {RenderSortByArrows('Job Template', filters?.template, 'template')}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    {RenderSortByArrows(
                                                        'Created Date',
                                                        filters?.created_at,
                                                        'created_at'
                                                    )}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    {RenderSortByArrows(
                                                        'Last Updated',
                                                        filters?.updated_at,
                                                        'updated_at'
                                                    )}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Created By
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left flex gap-2 items-center text-md font-semibold text-gray-900"
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {jobs?.map((job, _index) => (
                                                <tr
                                                    key={job?.id}
                                                    className={` ${
                                                        selectedTemplate?.includes(job) ? 'bg-gray-50' : undefined
                                                    } hover:bg-[#F8F4FE] transition-colors duration-200 cursor-pointer`}

                                                    //
                                                >
                                                    <td className="relative py-3.5 px-7 sm:w-12 sm:px-6">
                                                        {selectedTemplate?.includes(job) && (
                                                            <div className="absolute inset-y-0 left-0 w-0.5 bg-thirdbg" />
                                                        )}
                                                        <input
                                                            type="checkbox"
                                                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-secondary  text-indigo-600 focus:ring-indigo-600"
                                                            value={job.id}
                                                            checked={selectedTemplate?.includes(job)}
                                                            onChange={(e) =>
                                                                setSelectedTemplate(
                                                                    e.target.checked
                                                                        ? [...selectedTemplate, job]
                                                                        : selectedTemplate.filter((p) => p !== job)
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            'whitespace-nowrap   pr-3 text-md py-3.5 font-poppins',
                                                            selectedTemplate.includes(job)
                                                                ? 'text-thirdbg'
                                                                : 'text-black'
                                                        )}
                                                    >
                                                        {job?.name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-3.5 text-md  font-poppins">
                                                        <FormateDateTime dateTime={job?.created_at} />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3  py-3.5 text-md ">
                                                        <FormateDateTime dateTime={job?.updated_at} />
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-3.5  text-md text-gray-500">
                                                        <DefaultProfileViewer
                                                            height={'45px'}
                                                            width={'45px'}
                                                            image={job?.created_by?.image}
                                                            name={
                                                                job?.created_by?.first_name +
                                                                ' ' +
                                                                job?.created_by?.last_name
                                                            }
                                                            tooltip
                                                        />
                                                    </td>

                                                    <td className="whitespace-nowrap px-3 py-3.5 ">
                                                        <div className="flex gap-2 items-center">
                                                            {' '}
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    setTemplateDetails(!templateDetails);
                                                                    setSelectedDetailItem(job);
                                                                }}
                                                            >
                                                                <EyeIcon />
                                                            </div>
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    if (!hasPermissions('Job', ['admin'])) {
                                                                        return toastHandler(
                                                                            "You don't have permission to perform this action",
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }

                                                                    setAdd(!add);
                                                                    setEditTemplate(job);
                                                                }}
                                                            >
                                                                <BgEditIcon />
                                                            </div>
                                                            <div
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    if (!hasPermissions('Job', ['admin'])) {
                                                                        return toastHandler(
                                                                            "You don't have permission to perform this action",
                                                                            TOAST_TYPE_ERROR
                                                                        );
                                                                    }

                                                                    handleOpenConfirmation([job?.job_template_id]);
                                                                }}
                                                            >
                                                                <BgDeleteIcon />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </RoleBasedGuard>
        </SidebarLayout>
    );
};
