import React from 'react';
import './css/onboarding.css';
import { useState, useRef, useEffect } from 'react';
import AddProfile from './add-profile/AddProfile';
import AddProfileSide from './add-profile/AddProfileSide';
import AddBusiness from './add-business/AddBusiness';
import AddBusinessSide from './add-business/AddBusinessSide';
import AddService from './add-service/AddService';
import AddServiceSide from './add-service/AddServiceSide';
import AddBranchLocation from './add-branch/AddBranchLocation';
import AddBranchSide from './add-branch/AddBranchSide';
import StripeSide from './stripe-side/StripeSide';
import logoutIcon from '../../../files/powerOff.png.png';
import { BlueTick } from '../../../utills/svgs/BlueTick';
import { IncompleteCircle } from '../../../utills/svgs/IncompleteCircle';
import { LightBulb } from '../../../utills/svgs/LightBulb';
import { useDispatch, useSelector } from 'react-redux';
import { setSideLoader } from 'store/global/globalReducer';
import { getOnboardingStepDetailsThunk, login, logoutThunk } from 'store/auth/slices';
import { useNavigate } from 'react-router-dom';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN } from 'utills/globalVars';
import whiteBgLogo from '../../../files/whiteBgLogo.png.png';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { ProfileModal } from './modals/ProfileModal';
import { BusinessModal } from './modals/BusinessModal';
import { ServicesModal } from './modals/ServicesModal';
import { BranchesModal } from './modals/BranchesModal';
import ConfirmationModal from 'utills/confirmationModal';
import { EditService } from './add-service/EditService';
import { EditBranch } from 'components/profile-content/profile-sides/EditBranch';
import useRedirectToDashboardHook from 'Hooks/useRedirectToDashboardHook';

const OnboardingPage = () => {
    const { data: profileData } = useSelector((state) => state.businessOwnerGetProfile);

    const { data: businessData } = useSelector((state) => state.getBusinessprofile);
    const [showIndicators, setShowIndicators] = useState(false);
    const { data: services } = useSelector((state) => state.getAllServices);
    const { data: branches } = useSelector((state) => state.getBranchLocations);
    const { data: loginData } = useSelector((state) => state.login);
    const [stepsStatus, setStepsStatus] = useState('');
    const { redirectToDashboard } = useRedirectToDashboardHook();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const checkIfAllTrue = (obj) => {
        return stepsStatus ? Object.values(obj).every((value) => value === true) : false;
    };

    const toggleIndicators = () => {
        setShowIndicators(!showIndicators);
    };
    //profile modal

    const [showProfileModal, setShowProfileModal] = useState(false);
    const handleShowProfileModal = () => {
        setShowProfileModal(true);
    };

    const handleHideProfileModal = () => {
        setShowProfileModal(false);
    };

    //business modal
    const [showBusinessModal, setShowBusinessModal] = useState(false);
    const handleShowBusinessModal = () => {
        setShowBusinessModal(true);
    };

    const handleHideBusinessModal = () => {
        setShowBusinessModal(false);
    };

    //services modal
    const [showServicesModal, setShowServicesModal] = useState(false);
    const handleShowServicesModal = () => {
        setShowServicesModal(true);
    };

    const handleHideServicesModal = () => {
        setShowServicesModal(false);
    };

    //branches modal

    //services modal
    const [showBranchesModal, setShowbranchesModal] = useState(false);
    const handleShowBranchesModal = () => {
        setShowbranchesModal(true);
    };

    const handleHideBranchesModal = () => {
        setShowbranchesModal(false);
    };

    const [condition, setCondition] = useState(1);

    const [showAddProfile, setShowAddProfile] = useState(false);
    const [showAddBusinessSide, setShowAddBusinessSide] = useState(false);
    const [showAddServiceSide, setShowAddServiceSide] = useState(false);
    const [showBranchSide, setShowBranchSide] = useState(false);

    const [showEditService, setShowEditService] = useState(false);
    const [editBranch, setEditBranch] = useState(false);

    const [profileCompleted, setProfileCompleted] = useState(false);
    const [businessCompleted, setBusinessCompleted] = useState(false);
    const [branchCompleted, setBranchCompleted] = useState(false);
    const [serviceCompleted, setServiceCompleted] = useState(false);

    //stripe

    const [isUpdatedBranch, setIsUpdatedBranch] = useState(false);
    const [isServiceUpdated, setIsServiceUpdated] = useState(false);
    const [isBusinessUpdated, setIsBusinessUpdated] = useState(false);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const [showStripeSide, setShowStripeSide] = useState(false);

    const borderRef = useRef(null);

    const handleCondition = (conditonV) => {
        setCondition(conditonV);
    };

    const toggleEditService = () => {
        setShowEditService(!showEditService);
        showEditService && setIsServiceUpdated(!isServiceUpdated);
    };

    //add profile
    function toggleAddProfile() {
        setCondition(1);
    }

    const toggleEditBranch = () => {
        setEditBranch(!editBranch);
        editBranch && setIsUpdatedBranch(!isUpdatedBranch);
    };

    function toggleAddProfileSide() {
        setShowAddProfile(!showAddProfile);
        showAddProfile && setProfileUpdated(!profileUpdated);
    }

    function showAddBusiness() {
        setCondition(2);
    }

    function showAddService() {
        setCondition(3);
    }

    function showAddBranchLocation() {
        setCondition(4);
    }

    function toggleBusinessSide() {
        setShowAddBusinessSide(!showAddBusinessSide);
        showAddBusinessSide && setIsBusinessUpdated(!isBusinessUpdated);
    }

    function toggleServiceSide() {
        setShowAddServiceSide(!showAddServiceSide);
        showAddServiceSide && setIsServiceUpdated(!isServiceUpdated);
    }

    function toggleBranchSide() {
        setShowBranchSide(!showBranchSide);

        showBranchSide && setIsUpdatedBranch(!isUpdatedBranch);
    }

    function toggleStripeSide() {
        setShowStripeSide(!showStripeSide);
    }

    function myComponent() {
        return (
            <div>
                {condition === 1 && (
                    <AddProfile
                        handleCondition={handleCondition}
                        profileUpdated={profileUpdated}
                        setProfileUpdated={setProfileUpdated}
                        toggleAddProfileSide={toggleAddProfileSide}
                    />
                )}
                {condition === 2 && (
                    <AddBusiness
                        toggleBusinessSide={toggleBusinessSide}
                        handleCondition={handleCondition}
                        isBusinessUpdated={isBusinessUpdated}
                        setIsBusinessUpdated={setIsBusinessUpdated}
                    />
                )}
                {condition === 3 && (
                    <AddService
                        toggleServiceSide={toggleServiceSide}
                        isServiceUpdated={isServiceUpdated}
                        toggleEditService={toggleEditService}
                    />
                )}
                {condition === 4 && (
                    <AddBranchLocation
                        toggleBranchSide={toggleBranchSide}
                        isUpdated={isUpdatedBranch}
                        setIsUpdatedBranch={setIsUpdatedBranch}
                        toggleEditBranch={toggleEditBranch}
                    />
                )}
            </div>
        );
    }

    useEffect(() => {
        dispatch(setSideLoader(true));
        setTimeout(() => {
            dispatch(getOnboardingStepDetailsThunk())
                .then((response) => {
                    if (response.payload) {
                        setStepsStatus(response.payload);
                        const payload = response.payload;
                        if (payload.user && payload.business && payload.branch && payload.service) {
                            localStorage.setItem('onboarding', true);
                        } else {
                            localStorage.setItem('onboarding', false);
                        }
                        if (response.payload?.user) {
                            setProfileCompleted(true);
                        } else {
                            setProfileCompleted(false);
                        }

                        if (response.payload.business) {
                            setBusinessCompleted(true);
                        } else {
                            setBusinessCompleted(false);
                        }

                        if (response.payload.branch) {
                            setBranchCompleted(true);
                        } else {
                            setBranchCompleted(false);
                        }

                        if (response.payload.service) {
                            setServiceCompleted(true);
                        } else {
                            setServiceCompleted(false);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setSideLoader(false));
                });
        }, 1000);
    }, [isBusinessUpdated, profileUpdated, isUpdatedBranch, isServiceUpdated]);

    const handleLogout = () => {
        setIsConfirmationOpen(true);
    };

    const newLogout = () => {
        dispatch(logoutThunk());
        dispatch(login.actions.handleUpdate(null));

        setIsConfirmationOpen(false);
        localStorage.removeItem('verified');
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(BUSINESS_ID);
        localStorage.removeItem('temp_token');
        sessionStorage.setItem('loginStepper', 1);
        navigate('/');
    };
    useEffect(() => {
        if (!loginData?.second_time_login || showIndicators) {
            const driverObj = driver({
                showProgress: true,
                steps: [
                    {
                        element: '.add-profile-indcate',

                        popover: {
                            title: 'Complete Profile',
                            description:
                                'In this section, you are required to complete your user profile. Please remember that all fields are mandatory.',
                            popoverClass: 'my-custom-popover-class text-red-900'
                        }
                    },
                    {
                        element: '.add-business-indicate',
                        popover: {
                            title: 'Complete Business Profile',
                            description:
                                'In this section, it is necessary to fill out your business profile. Please remember that all fields are mandatory.'
                        }
                    },
                    {
                        element: '.service-indicate ',
                        popover: {
                            title: 'Add Service',
                            description:
                                'In this section, you have the option to add business services. We recommend adding at least one service.'
                        }
                    },
                    {
                        element: '.branch-indicate ',
                        popover: {
                            title: 'Add Branches',
                            description:
                                'In this section, you have the option to add business branches. We recommend adding at least one branch.'
                        }
                    }
                ]
            });

            driverObj.drive();
        }
    }, [showIndicators]);

    const handleSkipAndContinue = async () => {
        const updatedData = {
            ...loginData,
            business: {
                ...loginData?.business,
                onboarded: true
            }
        };

        await dispatch(login.actions.handleUpdate(updatedData));

        setTimeout(() => {
            // navigate('/business/dashboard');
            redirectToDashboard();
        }, 500);
    };

    return (
        <div className="app-dashboard p-3">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={() => newLogout()}
                description="Are you sure you want to logout!"
                confirmbtnTxt="Logout"
                cancelBtnTxt="Cancel"
                title="Logout Confirmation"
            />
            <div className="absolute right-5 top-5">
                <button
                    onClick={handleLogout}
                    className="flex items-center gap-2  p-2 px-3 rounded-lg bg-thirdbg small-text font-poppins text-white"
                >
                    <div>Logout</div>
                    <div>
                        <img src={logoutIcon} alt="logout-icon" className="logout-icon" />{' '}
                    </div>
                </button>
            </div>
            <div>
                {showAddProfile === true ? (
                    <AddProfileSide
                        isOpen={showAddProfile}
                        setIsOpen={setShowAddProfile}
                        toggleAddProfile={toggleAddProfileSide}
                    />
                ) : (
                    ''
                )}
                {showAddBusinessSide === true ? (
                    <AddBusinessSide
                        open={showAddBusinessSide}
                        setOpen={setShowAddBusinessSide}
                        toggleBusinessSide={toggleBusinessSide}
                    />
                ) : (
                    ''
                )}

                {showAddServiceSide === true ? (
                    <AddServiceSide
                        open={showAddServiceSide}
                        setOpen={setShowAddServiceSide}
                        toggleServiceSide={toggleServiceSide}
                    />
                ) : (
                    ''
                )}

                {showBranchSide === true ? (
                    <AddBranchSide
                        toggleBranchSide={toggleBranchSide}
                        open={showBranchSide}
                        setOpen={setShowBranchSide}
                        updateBranch={setIsUpdatedBranch}
                    />
                ) : (
                    ''
                )}

                {showEditService && (
                    <EditService
                        open={showEditService}
                        setOpen={setShowEditService}
                        toggleEditService={toggleEditService}
                    />
                )}

                {showStripeSide === true ? <StripeSide toggleStripeSide={toggleStripeSide} /> : ''}

                {editBranch && (
                    <EditBranch open={editBranch} setOpen={setEditBranch} toggleEditBranch={toggleEditBranch} />
                )}

                <div>
                    <div>
                        <div className="onboarding-container">
                            <div className="one ">
                                <div className="mt-5">
                                    <img src={whiteBgLogo} alt="logo" className="Synkli-logo" />
                                </div>
                            </div>
                            <div className="two  ">
                                <div className=" px-5 pt-6 card-heading-two">
                                    <h1> Welcome to Synkli</h1>
                                </div>

                                <div className=" default-font-light px-5 pt-3">
                                    <p>
                                        Your account has been successfully created. Here are a few things you can do
                                        right now to get started.
                                    </p>
                                </div>

                                <div
                                    onClick={toggleIndicators}
                                    className="bulb cursor-pointer  grid grid-cols-10 py-4 text-white mt-5 col-span-2"
                                >
                                    <div className="flex justify-center items-center">
                                        <div className="svg-wrapper-div ml-5">
                                            <LightBulb />
                                        </div>
                                    </div>
                                    <div className="col-span-8 pl-5 pt-1 default-font-light">
                                        Quick start guide for Synkli
                                    </div>
                                    <div className="flex justify-center items-center">
                                        <div className="icon-wrapper scale-75">
                                            <svg
                                                width="10"
                                                height="16"
                                                viewBox="0 0 8 14"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.433859 2.66497C-0.486076 1.68906 0.154012 0 1.44378 0C1.82265 0 2.18628 0.161092 2.45593 0.448399L7.57503 5.90261C8.14166 6.50634 8.14166 7.49366 7.57503 8.09739L2.45593 13.5516C2.18628 13.8389 1.82265 14 1.44378 14C0.154012 14 -0.486078 12.3109 0.433856 11.335L4.52022 7L0.433859 2.66497Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="add-steps px-5 mt-6">
                                    <div
                                        onMouseEnter={handleShowProfileModal}
                                        onMouseLeave={handleHideProfileModal}
                                        onClick={toggleAddProfile}
                                        className={` shadow-md relative py-3 px-3 add-profile-indcate rounded-lg grid grid-cols-12 ${
                                            profileCompleted || condition === 1
                                                ? 'add-blue'
                                                : 'add-white text-secondary-bg'
                                        }`}
                                    >
                                        {showProfileModal && (
                                            <ProfileModal
                                                profileData={profileData}
                                                toggleAddProfileSide={toggleAddProfileSide}
                                            />
                                        )}
                                        <div className="col-span-11">
                                            <div className="default-font">Add User Profile</div>
                                            <div>
                                                <p className="light-o default-font-small">
                                                    Please enter your personal details in this section to complete your
                                                    user profile.
                                                </p>
                                            </div>
                                        </div>

                                        <div className={`col-span-1 flex justify-center items-center  `}>
                                            <div className={`${!profileCompleted && 'animate-spin'}`}>
                                                {' '}
                                                {profileCompleted ? (
                                                    <BlueTick />
                                                ) : (
                                                    <span>
                                                        <IncompleteCircle />
                                                    </span>
                                                )}{' '}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        onMouseEnter={handleShowBusinessModal}
                                        onMouseLeave={handleHideBusinessModal}
                                        ref={borderRef}
                                        onClick={showAddBusiness}
                                        className={`onboard-step mt-4 relative shadow-md py-3 px-3 add-business-indicate rounded-lg grid grid-cols-12 ${
                                            businessCompleted || condition === 2 ? 'add-blue' : 'add-white text-black'
                                        }`}
                                    >
                                        <div className="col-span-11">
                                            {showBusinessModal && (
                                                <BusinessModal
                                                    businessData={businessData}
                                                    toggleBusinessSide={toggleBusinessSide}
                                                />
                                            )}
                                            <div className="default-font">Add Business</div>
                                            <div>
                                                <p className="light-o default-font-small">
                                                    Please add your business details in this section to complete your
                                                    business profile.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-span-1 flex justify-center items-center">
                                            <div className={`${!businessCompleted && 'animate-spin'}`}>
                                                {' '}
                                                {businessCompleted ? <BlueTick /> : <IncompleteCircle />}{' '}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        onMouseEnter={handleShowServicesModal}
                                        onMouseLeave={handleHideServicesModal}
                                        ref={borderRef}
                                        onClick={showAddService}
                                        className={`onboard-step  relative mt-4 service-indicate shadow-md py-3 px-3 rounded-lg grid grid-cols-12 ${
                                            serviceCompleted || condition === 3 ? 'add-blue' : 'add-white text-black'
                                        }`}
                                    >
                                        <div className="col-span-11">
                                            {showServicesModal && (
                                                <ServicesModal
                                                    services={services}
                                                    toggleServiceSide={toggleServiceSide}
                                                />
                                            )}
                                            <div className="default-font">Add Services</div>
                                            <div>
                                                <p className="light-o default-font-small">
                                                    Please add your business services in this section. We recommend
                                                    adding at least one service to enhance your profile.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-span-1 flex justify-center items-center">
                                            <div className={`${!serviceCompleted && 'animate-spin'}`}>
                                                {' '}
                                                {serviceCompleted ? <BlueTick /> : <IncompleteCircle />}{' '}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        onMouseEnter={handleShowBranchesModal}
                                        onMouseLeave={handleHideBranchesModal}
                                        ref={borderRef}
                                        onClick={showAddBranchLocation}
                                        className={`onboard-step mt-4 relative branch-indicate shadow-md py-3 px-3 rounded-lg grid grid-cols-12 ${
                                            branchCompleted || condition === 4 ? 'add-blue' : 'add-white text-black'
                                        }`}
                                    >
                                        <div className="col-span-11">
                                            {showBranchesModal && (
                                                <BranchesModal
                                                    branches={branches}
                                                    toggleBranchSide={toggleBranchSide}
                                                />
                                            )}
                                            <div className="default-font">Add Branch Location</div>
                                            <div>
                                                <p className="light-o default-font-small">
                                                    Kindly verify your business branch details in this section. For
                                                    better customer accessibility, we recommend adding at least one
                                                    branch.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-span-1 flex justify-center items-center">
                                            <div className={`${!branchCompleted && 'animate-spin'}`}>
                                                {' '}
                                                {branchCompleted ? <BlueTick /> : <IncompleteCircle />}{' '}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-center align-center">
                                    <button
                                        disabled={profileCompleted && businessCompleted ? false : true}
                                        className={`ann-btn  p-3 mt-12  buttons-font rounded-lg w-[150px]  ${
                                            profileCompleted && businessCompleted ? '' : 'opacity-50'
                                        } `}
                                        onClick={() => {
                                            handleSkipAndContinue();
                                        }}
                                    >
                                        {checkIfAllTrue(stepsStatus) ? 'Continue' : 'Skip & Continue'}
                                    </button>
                                </div>
                            </div>
                            <div className="three ">
                                {' '}
                                <div className="h-full w-full ">{myComponent()} </div>{' '}
                            </div>
                            <div className="four"></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingPage;
