import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import '../../dashboard-css/auth-css/googleQr.css';

import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { DashboardWelcomeCard } from '../../../pages/dashboard/dashboard-cards/DashboardWelcomeCard';
import { BackArrow } from '../../../utills/svgs/BackArrow';

import 'react-toastify/dist/ReactToastify.css';
import { BlueGreaterSign } from '../../../utills/svgs/BlueGreaterSign';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../store/global/globalReducer';
import { googleAuthThunk, verifyGoogleAuthThunk } from '../../../store/auth/slices';
import { toastHandler } from '../../../responseHanlder';
import { Loader } from '../../common/Loader';
import { SmallLoader } from 'components/common/SmallLoader';

const GoogleQrPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const base64String = useSelector((state) => state?.googleAuth?.data?.otpauth_url);
    const data = useSelector((state) => state.login);
    const isLoading = useSelector((state) => state.global.isLoading);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [otp, setOtp] = useState([null, null, null, null, null, null]);

    const googleAuthData = {
        user_id: data?.data?.user_id,
        user_type: 'employee'
    };

    const handleKeyUp = (event, index) => {
        const input = inputRefs[index].current;
        let value = input.value;

        if (value.length > 1) {
            value = value.charAt(0);
            input.value = value;
        }

        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (event.keyCode === 8 && value.length === 0 && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    function hasNullItem() {
        return otp.some((item) => item === null);
    }

    const verifyGoogleData = {
        user_id: data?.data.user_id,
        user_type: 'employee',
        code: otp.join('')
    };

    const handleOtpSubmit = async () => {
        if (otp.length <= 5 || hasNullItem()) {
            return toastHandler('Please fill in all OTP inputs', 'error');
        }

        dispatch(setLoader(true));
        await dispatch(verifyGoogleAuthThunk(verifyGoogleData))
            .then((response) => {
                if (response.payload) {
                    navigate('/auth/success');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [code, setCode] = useState('');

    useEffect(() => {
        setTimeout(() => {
            dispatch(googleAuthThunk(googleAuthData))
                .then((response) => {
                    if (response.payload) {
                        setCode(response.payload.code);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }, 3000);
    }, []);

    return (
        <div className="dashboard-page">
            {isLoading && <Loader />}

            <div className="main-grid-container">
                <div className="one "></div>
                <div className="two shadow-md">
                    <DashboardWelcomeCard />
                </div>
                <div className="three shadow-md">
                    <div className="one-head  pt-4 flex   ">
                        <div className="one-head px-3 pt-2 flex   ">
                            <div
                                className="arrow-wrapper mt-1 cursor-pointer"
                                onClick={() => navigate('/google/auth/instructions')}
                            >
                                <BackArrow />
                            </div>
                            <div>
                                {' '}
                                <h1>Google Authenticator</h1>
                            </div>
                        </div>
                    </div>

                    <div className="email-text flex  mx-5 gap-1 mt-3">
                        <div>
                            <div className="scale-75 mt-1">
                                <BlueGreaterSign />
                            </div>
                        </div>
                        <div>
                            <span>
                                Please use your authentication app (such as Duo or Google Authenticator) to activate the
                                Google Authentication..
                            </span>
                        </div>
                    </div>

                    <div className="flex justify-center items-center  mt-5">
                        {base64String && <img src={base64String} alt="qrcode" />}
                    </div>

                    <div className="code my-5 flex justify-center items-center">
                        <div>
                            <div>
                                {code ? (
                                    <span> {code} </span>
                                ) : (
                                    <div className="flex items-center justify-center">
                                        {' '}
                                        <SmallLoader />{' '}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="google-inner px-5 mt-5">
                        Enter the 6-digit authentication code generated by your app:
                    </div>

                    <div className="email-text px-5">
                        Upon scanning the QR code, the app will present a 6-digit code for you to input below.
                    </div>

                    <div className="otp-verification ">
                        <div className="email-text relative mt-3"></div>

                        <div className="px-5 flex justify-center items-center">
                            <div className="inputs flex items-center  gap-5 flex-wrap mt-5 ">
                                <div>
                                    <input
                                        ref={inputRefs[0]}
                                        onKeyUp={(event) => handleKeyUp(event, 0)}
                                        maxLength="1"
                                        type="number"
                                    />
                                </div>
                                <div>
                                    <input
                                        ref={inputRefs[1]}
                                        onKeyUp={(event) => handleKeyUp(event, 1)}
                                        maxLength="1"
                                        type="number"
                                    />
                                </div>
                                <div>
                                    <input
                                        ref={inputRefs[2]}
                                        onKeyUp={(event) => handleKeyUp(event, 2)}
                                        maxLength="1"
                                        type="number"
                                    />
                                </div>
                                <div>
                                    <input
                                        ref={inputRefs[3]}
                                        onKeyUp={(event) => handleKeyUp(event, 3)}
                                        maxLength="1"
                                        type="number"
                                    />
                                </div>
                                <div>
                                    <input
                                        ref={inputRefs[4]}
                                        onKeyUp={(event) => handleKeyUp(event, 4)}
                                        maxLength="1"
                                        type="number"
                                    />
                                </div>
                                <div>
                                    <input
                                        ref={inputRefs[5]}
                                        onKeyUp={(event) => handleKeyUp(event, 5)}
                                        maxLength="1"
                                        type="number"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center mb-5 items-center gap-3 sm:mt-10 md:mt-10 lg:mt-20 max-xl:mt-28">
                        <button
                            onClick={() => navigate('/google/auth/instructions')}
                            className="cancel-btn px-10 py-2 rounded-md"
                        >
                            Cancel{' '}
                        </button>
                        <button onClick={handleOtpSubmit} className="enable-btn  px-10 py-2 rounded-md">
                            Enable
                        </button>
                    </div>
                </div>
                <div className="four"></div>
            </div>
        </div>
    );
};
export default GoogleQrPage;
