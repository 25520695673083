import React, { useRef, useState } from 'react';
import { cn } from 'utills/tailwindUtil';
import { TooltipChip } from './TooltipChip';

const TooltipChipWrap = ({ children, groupSx, title, placement = 'bottom', sx, hideChip, useWithoutPortal }) => {
    const parentRef = useRef();
    const [hoveredIndex, setHoveredIndex] = useState(false);

    const setHover = (value) => {
        setHoveredIndex(value);
    };

    return (
        <div
            ref={parentRef}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(null)}
            className={cn('group relative !captitalize', groupSx ? groupSx : '')}
        >
            {title && (
                <TooltipChip
                    text={title}
                    direction={placement}
                    parentRef={parentRef}
                    isVisible={hoveredIndex}
                    sx={sx}
                    hideChip={hideChip}
                    portalProps={
                        useWithoutPortal
                            ? {
                                  onMouseEnter: () => setHover(true),
                                  onMouseLeave: () => setHover(null),
                                  id: 'ondsonodn'
                              }
                            : {}
                    }
                />
            )}
            {children}
        </div>
    );
};

export default TooltipChipWrap;
