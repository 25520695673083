import React from 'react';

export const GlobeIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="15"
            height="16"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5 22C17.299 22 22 17.299 22 11.5C22 5.70101 17.299 1 11.5 1C5.70101 1 1 5.70101 1 11.5C1 17.299 5.70101 22 11.5 22Z"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.69922 8H21.2992M1.69922 15H21.2992M10.9159 1C8.95045 4.14955 7.90846 7.78751 7.90846 11.5C7.90846 15.2125 8.95045 18.8505 10.9159 22M12.0826 1C14.048 4.14955 15.09 7.78751 15.09 11.5C15.09 15.2125 14.048 18.8505 12.0826 22"
                stroke="black"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
