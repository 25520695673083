import { Button } from 'components/atoms/buttons/Button';
import Input from 'components/atoms/Input';
import Slideover from 'components/atoms/SlideOver';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';
import useRoleGuard from 'Hooks/useRoleGuard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { createGroupThunk, updateGroupThunk } from 'store/groups';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { generateId } from 'utills/uid';

export const AddEditClientGroup = ({ open, setOpen, group, handleComplete }) => {
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();

    const business_id = localStorage.getItem(BUSINESS_ID);
    const [title, setTitle] = useState('');

    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    const handleSubmit = () => {
        const payload = {
            business_id,
            type: 'clients',
            titles: fields?.map((field) => field.text)
        };

        const hasEmptyValues = fields?.some((item) => !item.text);

        if (!group && hasEmptyValues) {
            toastHandler('Please fill in required fields', TOAST_TYPE_ERROR);
            return;
        }
        dispatch(setLoader(true));
        dispatch(
            group
                ? updateGroupThunk({ business_id, type: 'clients', title, group_id: group?.group_id })
                : createGroupThunk(payload)
        )
            .then((response) => {
                if (response.payload) {
                    handleComplete();
                    setOpen(false);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        if (group) {
            setTitle(group?.title);
        }
    }, [group]);

    return (
        <Slideover
            open={open}
            setOpen={setOpen}
            title={group ? 'Edit Group' : 'Add Group'}
            footer={
                <div className="flex items-center justify-center">
                    {hasPermissions('Clients', ['admin']) && (
                        <Button
                            title={group ? 'Edit Group' : 'Add Group'}
                            classes="ann-btn px-10 rounded-xl"
                            atClick={() => handleSubmit()}
                        />
                    )}
                </div>
            }
        >
            <RoleBasedGuard module={'Clients'} permissions={['admin']} sx="h-[50vh]">
                {group ? (
                    <div className=" default-forms mt-10">
                        <PrimaryLabel>Title</PrimaryLabel>
                        <Input
                            useWithoutForm={true}
                            placeholder="Enter group title"
                            name="title"
                            sx="!rounded-xl !py-3 mt-2"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required={true}
                            type="title"
                        />
                    </div>
                ) : (
                    <div className="mt-10 mb-5">
                        <SecondaryParagraph sx=" text-[#666666]">
                            You can add multiple groups at once. Please make sure no group name is repeated!
                        </SecondaryParagraph>
                        <MultiTextInputAddComponent
                            placeholder={'Enter group name'}
                            fields={fields}
                            setFields={setFields}
                        />
                    </div>
                )}
            </RoleBasedGuard>
        </Slideover>
    );
};
