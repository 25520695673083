import React from 'react';

export const ImagePlaceholder = () => {
    return (
        <div>
            <svg width="15" height="15" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20 18.0019V2.87915C20 1.69093 19 0.71875 17.7778 0.71875H2.22222C1 0.71875 0 1.69093 0 2.87915V18.0019C0 19.1902 1 20.1623 2.22222 20.1623H17.7778C19 20.1623 20 19.1902 20 18.0019ZM6.11111 12.0608L8.88889 15.3122L12.7778 10.4405L17.7778 16.9217H2.22222L6.11111 12.0608Z"
                    fill="url(#paint0_linear_2369_9684)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_2369_9684"
                        x1="10.9813"
                        y1="0.96022"
                        x2="10.9672"
                        y2="20.1624"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
