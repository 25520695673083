import React, { useEffect, useState } from 'react';
import PrebuiltChecklistPage from 'components/workplace-content/tasks-pages/organizational-settings-pages/PrebuiltChecklistPage';
import AddPrebuiltChecklist from 'components/workplace-content/tasks-pages/organizational-settings-pages/AddPrebuiltChecklist';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';

const PrebuiltChecklist = () => {
    const [showChecklist, setShowChecklist] = useState(false);
    const [typesUpdated, setTypesUpdated] = useState(false);
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        if (editId) {
            setShowChecklist(true);
        }
    }, [editId]);

    const toggleChecklist = () => {
        setShowChecklist(!showChecklist);
        setEditId(null);
    };

    const toggletypesUpdated = () => {
        setTypesUpdated(!typesUpdated);
    };

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                <AddPrebuiltChecklist
                    showChecklist={showChecklist}
                    toggleChecklist={toggleChecklist}
                    toggletypesUpdated={toggletypesUpdated}
                    editId={editId}
                />
                <div>
                    <RoleBasedGuard module={'Tasks'} permissions={['admin', 'read', 'write']} sx="h-[50vh]">
                        <PrebuiltChecklistPage
                            setEditId={setEditId}
                            toggleChecklist={toggleChecklist}
                            typesUpdated={typesUpdated}
                        />
                    </RoleBasedGuard>
                </div>
            </SidebarLayout>
        </div>
    );
};

export default PrebuiltChecklist;
