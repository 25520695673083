export const PROFILE = 'Profile';
export const LEADS = 'Leads';
export const ANNOUNCEMENTS = 'Announcements';
export const TELEPHONIC_DIRECTORIES = 'Telephonic_directories';
export const THREADS = 'Threads';
export const DEDUCTION = 'Deduction';
export const QR_CODES = 'Qr_codes';
export const ROLE_MANAGEMENT = 'Role_management';
export const TEAM = 'Team';
export const FORMS = 'Forms';
export const CLIENTS = 'Clients';
export const APPS = 'Apps';
export const TASKS = 'Tasks';
export const JOB = 'Job';
export const DASHBOARD = 'Dashboard';
export const APP_CONNECTIONS = 'App_connections';
export const NOTIFICATION_SETTING = 'Notification_setting';
export const ONBOARDING_SERVICES = 'Onboarding_services';

export const ADMIN_WRITE = [
    PROFILE,
    LEADS,
    ANNOUNCEMENTS,
    TELEPHONIC_DIRECTORIES,
    THREADS,
    DEDUCTION,
    QR_CODES,
    ROLE_MANAGEMENT,
    TEAM,
    FORMS,
    APP_CONNECTIONS,
    NOTIFICATION_SETTING,
    ONBOARDING_SERVICES
];

export const PERMISSIONS = {
    ADMIN: 'admin',
    READ: 'read',
    WRITE: 'write'
};
