import React from 'react';
import JobmakerPage from '../../components/payrol-pages/job-maker/JobmakerPage';
const JobMaker = () => {
    return (
        <div className="app-dashboard">
            <div className={`content ${''}`}>
                <JobmakerPage />
            </div>
        </div>
    );
};
export default JobMaker;
