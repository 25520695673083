/* eslint-disable react/no-children-prop */
import React from 'react';
import { motion } from 'framer-motion';
import heic2any from 'heic2any';
import SignatureCanvas from 'react-signature-canvas';
import './css/dynamicForm.css';
import { useState, useRef, useEffect } from 'react';
import { SideTimes } from 'utills/svgs/SideTimes';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
import { formHandler } from 'store/global/globalReducer';
import { generateId } from 'utills/uid';
import { ImageIcon } from 'utills/svgs/ImageIcon';
import { InfoIcon } from 'utills/svgs/InfoIcon';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { FileIcon } from 'utills/svgs/FileIcon';
import { BSB, BUSINESS_INDUSTRY_OCCUPATION, DOB, SALARY_WAGES_OCCUPATION, SOCKET_URL } from 'utills/globalVars';
import dayjs from 'dayjs';
import { Button } from 'components/atoms/buttons/Button';
import cloneDeep from 'lodash/cloneDeep';
import { convertSpacesToUnderscores, convertUnderscoresToSpaces } from 'utills/handleText';
import ProgressBarComponent from 'components/atoms/ProgressBar';
import { cn } from 'utills/tailwindUtil';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import DefaultHint from 'components/atoms/DefaultHint';
import { DefaultDoubleSwitch } from 'components/atoms/switch/DefaultDoubleSwitch';
import { getformsfieldsOptionsThunk } from 'store/form';
import SearchableComboboxSSR from 'components/molecules/comboboxes/SearchableComboboxSSR';
import { ParseHtml } from 'Hooks/ParseHtml';
import { Loader } from 'components/common/Loader';
import {
    CONDITIONAL,
    LIST,
    PRIVACY_POLICY,
    RECURRING,
    TERMS_AND_CONDITIONS
} from 'utills/constants/forms/forms.constants';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { PrimaryCheckbox } from 'components/atoms/inputs/PrimaryCheckbox';

const PreviewForm = ({ togglePreviewForm, directory }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const { form: formArr } = useSelector((state) => state.global);
    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    const dispatch = useDispatch();
    const [showHint, setShowHint] = useState(false);
    const [_hintStep, setHintStep] = useState();
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomUrl, setZoomUrl] = useState(null);
    const [confimationOpen, setConfirmationOpen] = useState(false);
    const [_confirmed, setConfirmed] = useState(false);
    const [hintId, sethintid] = useState('');
    const [displayHint, setDisplayHint] = useState(false);

    const [showTermsModal, setShowTermsModal] = useState(false);
    const [termsInfo, setTermsInfo] = useState('');

    const { isLoading } = useSelector((state) => state.global);

    const toggleHints = (field_row_id) => {
        if (field_row_id) {
            sethintid(field_row_id);
        }
        setHintStep(!showHint);
    };

    const [_unchangedForm, setunChangendForm] = useState();
    const [swipeForm, setSwipeForm] = useState([]);

    useEffect(() => {
        const newForm = cloneDeep(formArr);

        if (formArr?.customer_id) {
            const filteredSteps = newForm.steps.filter((st) => st.is_skipped === false);

            newForm.steps = filteredSteps;
            dispatch(formHandler(newForm));
        }

        if (formArr) {
            setunChangendForm(formArr);
            setSwipeForm(formArr);
        }
    }, []);

    const toggleZoom = (url) => {
        setZoomUrl(url);
        setIsZoomed(true);
    };

    const toggleHint = (hintField, stepIndex) => {
        setHintStep(stepIndex);
        setShowHint(!showHint);
    };
    const datePickerProps = {
        right: CalendarSmallIco
    };

    const signatureRef = useRef();

    function MyComponent({ htmlContent }) {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} className="text-secondarybg text-sm" />;
    }

    const handleCheckboxChange = (_e) => {};

    const convertToJpeg = async (_url) => {
        try {
            const response = await fetch(
                `${SOCKET_URL}/files/customer?id=ct/abae8a/undefined/1712542440613/IMG_0086.HEIC`,
                {
                    credentials: 'include'
                }
            );

            const blob = await response.blob();

            const conversionResult = await heic2any({ blob });

            const newurl = URL.createObjectURL(conversionResult);

            return newurl;
        } catch (error) {
            console.error('Error converting image:', error);
            return null;
        }
    };

    const handleAddSubStep = (recurringField, stepIndex, step, fieldIndex) => {
        const newForm = cloneDeep(formArr);

        const field = newForm.steps[stepIndex].fields[fieldIndex];

        const newStep = cloneDeep(step);

        const fineNextStepsArray = newForm.steps
            .slice(stepIndex + 1)
            .filter((stepItem) => stepItem.step_id === newStep.step_id);

        if (field.recurring_switch === field.recurring_option) {
            if (fineNextStepsArray.length > 0) {
                return;
            }

            const findTotalChildSteps = newForm.steps.filter((stepItem) => stepItem.step_id === newStep.step_id);

            const findStep = swipeForm?.steps?.find((stepItem) => stepItem.step_id === step.step_id);

            const indexOfStep = swipeForm?.steps?.indexOf(findStep);

            newStep.step = `${indexOfStep}.${findTotalChildSteps.length}`;
            newStep.step_heading = '';
            newStep.step_condition = false;

            newStep.fields[fieldIndex].label = step.fields[fieldIndex].recurring_label;
            newStep.fields[fieldIndex].recurring_switch =
                field?.recurring_option === field.options[0] ? field.options[1] : field.options[0];

            newForm.steps.splice(stepIndex + 1, 0, newStep);
            dispatch(formHandler(newForm));
            if (currentStep < newForm.steps.length - 1) {
                setCurrentStep(currentStep + 1);
            }
        } else {
            if (fineNextStepsArray.length > 0) {
                const isdeleted = true;
                if (isdeleted) {
                    for (let i = stepIndex + 1; i < newForm.steps.length; i++) {
                        if (newForm.steps[i].step_id === step.step_id) {
                            newForm.steps.splice(i, 1);
                            i--;
                        }
                    }

                    dispatch(formHandler(newForm));
                    return;
                } else {
                    return false;
                }
            }
        }
    };

    const handleShowRecurringFields = (field, option, index, step, fieldIndex) => {
        const newForm = cloneDeep(formArr);
        newForm.steps = newForm.steps.filter((st) => st.step_condition === false);
        if (newForm?.customer_id) {
            return;
        }

        newForm.steps[index].fields[fieldIndex].recurring_switch = option;
        if (option === newForm.steps[index].fields[fieldIndex].recurring_option) {
            newForm.steps[index].fields[fieldIndex].display_on_new_page = true;
        } else {
            newForm.steps[index].fields[fieldIndex].display_on_new_page = false;
        }

        dispatch(formHandler(newForm));
    };

    const findFieldByRowId = (field_row_id, steps) => {
        for (const step of steps) {
            for (const field of step.fields) {
                if (field.field_row_id === field_row_id) {
                    return field;
                }
                if (field.conditional_steps) {
                    for (const key in field.conditional_steps) {
                        const conditionalStep = field.conditional_steps[key];
                        const foundField = findFieldByRowId(field_row_id, conditionalStep.steps);
                        if (foundField) {
                            return foundField;
                        }
                    }
                }
            }
        }
        return null;
    };

    const handleDefaultValueConditional = (checked, field_row_id, optionIndex, stepIndex) => {
        const newForm = cloneDeep(formArr);
        const unchangedForm = cloneDeep(formArr);

        unchangedForm.steps = newForm.steps.filter((step) => step.step_condition === false);

        if (newForm?.customer_id) {
            return;
        }
        const field = findFieldByRowId(field_row_id, newForm.steps);
        const currentStep = newForm.steps[stepIndex];

        if (field) {
            const option = field.options[optionIndex];
            field.default_value = option;

            const selectedOptionObj = field?.conditional_steps[convertSpacesToUnderscores(option)];

            const newStep = selectedOptionObj?.steps[0];

            const fineNextStepsArray = newForm.steps
                .slice(stepIndex + 1)
                .filter((stepItem) => stepItem.step_id === currentStep?.step_id);

            if (fineNextStepsArray.length > 0) {
                for (let i = stepIndex + 1; i < newForm.steps.length; i++) {
                    if (newForm.steps[i].step_id === currentStep.step_id) {
                        newForm.steps.splice(i, 1);
                        i--;
                    }
                }
            }

            if (selectedOptionObj?.display_on_new_page) {
                newStep.step_id = newForm.steps[stepIndex].step_id;
                newStep.step = newForm.steps[stepIndex].step + '.1';
                newStep.step_condition = false;
                if (!Object.keys(field.skip_steps).length > 0) {
                    field.default_value = '';
                }

                newForm.steps.splice(stepIndex + 1, 0, newStep);
            }

            let stepsToSkipids = [];
            let stepstoRetriveIds = [];

            if (field?.skip_steps[convertSpacesToUnderscores(field?.default_value)]) {
                field?.skip_steps[convertSpacesToUnderscores(field.default_value)].forEach((item) => {
                    stepsToSkipids.push(item);
                });
            }

            for (const key in field?.skip_steps) {
                if (convertSpacesToUnderscores(key) !== convertSpacesToUnderscores(field?.default_value)) {
                    field?.skip_steps[convertSpacesToUnderscores(key)]?.forEach((item) => {
                        stepstoRetriveIds?.push(item);
                    });
                }
            }

            if (stepstoRetriveIds?.length > 0) {
                let stepsToRetrives = newForm?.steps?.filter((step) => stepstoRetriveIds?.includes(step.step_id));

                stepsToRetrives?.forEach((step) => {
                    step.step_condition = false;
                });
            }

            if (stepsToSkipids?.length > 0) {
                let stepsToSkip = newForm?.steps?.filter((step) => stepsToSkipids?.includes(step.step_id));

                stepsToSkip?.forEach((step) => {
                    step.step_condition = true;
                });
            }
        }

        dispatch(formHandler(newForm));
    };

    let itrations = 0;
    const limitItration = 3;

    const handleNext = (step, stepIndex) => {
        const recurringField = step.fields.find((field) => field.type === 'recurring');

        if (!formArr.customer_id) {
            if (recurringField) {
                //the field index will be zero because if there is recurring field inside a step it will be only one field
                const recuringResponse = handleAddSubStep(recurringField, stepIndex, step, 0);
                if (recuringResponse === false) {
                    return false;
                }
            }
        }

        if (currentStep < formArr.steps.length - 1) {
            setCurrentStep(currentStep + 1);
            itrations = 0;
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const renderAbsolute = (top, isLast) => {
        return (
            <div
                className={cn(
                    'border-b w-[18px] border-[#BBBBBB] absolute -left-[18px] block',
                    top || 'top-[65px]',
                    isLast ? 'border-b-0 border-t bg-white h-[50px] -left-[19px]' : ''
                )}
            ></div>
        );
    };

    const toggleDisplayHint = (field_row_id) => {
        setDisplayHint(!displayHint);
        if (field_row_id) {
            sethintid(field_row_id);
        }
    };

    const fetchWagesOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'salary-wages-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };

    const fetchBusinessOccupationOptions = async (payload) => {
        const payloadNew = {
            ...payload,
            type: 'business-industry-occupation'
        };
        return await dispatch(getformsfieldsOptionsThunk(payloadNew));
    };

    useEffect(() => {}, []);

    const handleSalaryWages = (field_row_id, value) => {
        const newForm = cloneDeep(formArr);
        const field = findFieldByRowId(field_row_id, newForm.steps);
        if (field) {
            field.default_value = value;
            dispatch(formHandler(newForm));
        }
    };

    const ReturnFieldsComponent = (
        field,
        fieldIndex,
        index,
        step,
        isConditional,
        itrateNumber,
        toggleHints,
        hintId
    ) => {
        if (field.type === 'conditional') {
            itrations = itrateNumber;
        }
        return (
            <div className=" relative1" key={fieldIndex}>
                {displayHint && hintId === field.field_row_id ? (
                    <div
                        onClick={() => toggleDisplayHint()}
                        className=" p-3 flex justify-end absolute right-2 break-all text-start cursor-pointer w-[360px] bg-white rounded-xl shadow-md z-[50]"
                    >
                        {field.hint}
                    </div>
                ) : (
                    ''
                )}

                {field.type === 'text' ||
                field.type === 'first-name' ||
                field.type === 'middle-name' ||
                field.type === 'last-name' ||
                field.type === 'phone-number' ||
                field.type === 'address' ||
                field.type === 'abn' ||
                field.type === 'tfn' ? (
                    <div className="mt-3 relative">
                        {isConditional && renderAbsolute(null, false)}
                        <div className="default-forms">
                            {' '}
                            <div className="flex justify-between items-center">
                                {' '}
                                <label>
                                    {' '}
                                    {field?.label} {!field?.required ? '(Optional)' : ''}{' '}
                                </label>{' '}
                                <DefaultHint children={field.hint} />
                            </div>
                        </div>
                        <div className=" mt-2">
                            <input
                                type="text"
                                maxLength="60"
                                className="field-input"
                                placeholder={field?.placeholder}
                                value={field?.default_value}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {field?.type === LIST && (
                    <div>
                        <div className="flex justify-between items-center mt-4">
                            {' '}
                            <PrimaryLabel>{field.label} </PrimaryLabel> <DefaultHint children={field.hint} />{' '}
                        </div>
                        {typeof field?.default_value !== 'string' && field?.default_value[0] !== '' ? (
                            <div>
                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                    {field?.default_value?.map((item, i) => (
                                        <li key={i}>
                                            <PrimaryParagraph>{item}</PrimaryParagraph>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div>
                                <MultiTextInputAddComponent
                                    placeholder={field?.placeholder || ''}
                                    fields={fields}
                                    setFields={setFields}
                                />{' '}
                            </div>
                        )}
                    </div>
                )}

                {field.type === CONDITIONAL && (
                    <div className="mt-3 relative">
                        {isConditional && renderAbsolute('top-[10px]', itrations > limitItration ? true : false)}
                        <div className="flex justify-between items-center">
                            {' '}
                            <div>{field.label} </div> <DefaultHint children={field.hint} />{' '}
                        </div>
                        <div>
                            {' '}
                            <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0 flex-wrap gap-2 w-full mt-2">
                                {field.options.map((op, opIndex) => (
                                    <div key={opIndex} className="flex items-center">
                                        <input
                                            name={'notification-method' + field.field_row_id}
                                            id={'notification-method' + field.field_row_id + ' ' + opIndex}
                                            type="radio"
                                            defaultChecked={field.default_value === op}
                                            disabled={formArr?.customer_id ? true : false}
                                            onChange={(e) =>
                                                handleDefaultValueConditional(
                                                    e.target.checked,
                                                    field.field_row_id,
                                                    opIndex,
                                                    index
                                                )
                                            }
                                            className="!h-[20px] !w-[20px] rounded-full !border-gray-300 !text-primary focus:!ring-primary"
                                        />
                                        <label
                                            htmlFor={'notification-method' + field.field_row_id + ' ' + opIndex}
                                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {convertUnderscoresToSpaces(op)}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={cn(
                                    '',
                                    itrations < limitItration ? 'border-l border-[#BBBBBB] pl-[18px] ml-[7px]' : ''
                                )}
                            >
                                {!field?.conditional_steps[convertSpacesToUnderscores(field.default_value)]
                                    ?.display_on_new_page &&
                                    field.conditional_steps[
                                        convertSpacesToUnderscores(field.default_value)
                                    ]?.steps[0].fields.map((field, fieldIndex) => (
                                        <div key={'fieldIndex' + fieldIndex}>
                                            {ReturnFieldsComponent(
                                                field,
                                                fieldIndex,
                                                index,
                                                step,
                                                true,
                                                itrations + 1,
                                                toggleHints,
                                                hintId
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}

                <DefaultDialog
                    width={'!w-[600px]'}
                    open={showTermsModal}
                    setOpen={setShowTermsModal}
                    children={
                        <div>
                            {' '}
                            <ParseHtml htmlContent={termsInfo} />{' '}
                        </div>
                    }
                />

                {field?.type === TERMS_AND_CONDITIONS && (
                    <div className="flex items-center gap-3 mt-2">
                        <div>
                            {' '}
                            <input type="checkbox" className="!rounded-[3px] !w-[18px] !h-[18px]" checked={true} />
                        </div>
                        <div>
                            {' '}
                            I agree to{' '}
                            <a
                                href="#!"
                                className="text-thirdbg"
                                onClick={() => {
                                    setTermsInfo(field?.default_value);
                                    setShowTermsModal(!showTermsModal);
                                }}
                            >
                                terms and conditions
                            </a>
                            .
                        </div>
                    </div>
                )}

                {field?.type === PRIVACY_POLICY && (
                    <div className="flex items-center gap-3 mt-2">
                        <div>
                            {' '}
                            <input type="checkbox" className="!rounded-[3px] !w-[18px] !h-[18px]" checked={true} />
                        </div>
                        <div>
                            {' '}
                            I agree to{' '}
                            <a
                                href="#!"
                                className="text-thirdbg"
                                onClick={() => {
                                    setTermsInfo(field?.default_value);
                                    setShowTermsModal(!showTermsModal);
                                }}
                            >
                                Privacy Policy
                            </a>
                            .
                        </div>
                    </div>
                )}

                {field?.type === RECURRING && (
                    <div className="mt-3 relative ">
                        <div className="flex justify-between items-center">
                            {' '}
                            <div>{field.label} </div> <DefaultHint children={field.hint} />{' '}
                        </div>
                        <div>
                            <div>
                                <DefaultDoubleSwitch
                                    items={[
                                        {
                                            title: field.options[0],
                                            selected: field?.recurring_switch === field.options[0]
                                        },
                                        {
                                            title: field.options[1],
                                            selected: field.recurring_switch === field.options[1]
                                        }
                                    ]}
                                    onClick={(value) =>
                                        handleShowRecurringFields(field, value, index, step, fieldIndex)
                                    }
                                />
                            </div>

                            <div>
                                {' '}
                                {field.recurring_switch === field.recurring_option && (
                                    <div>
                                        {field.default_value.map((arr, arrIndex) => (
                                            <div key={'arrIndex' + arrIndex}>
                                                {arr.map((field, fieldIndex) => (
                                                    <div key={'arrIndex' + arrIndex + fieldIndex}>
                                                        {ReturnFieldsComponent(
                                                            field,
                                                            fieldIndex,
                                                            index,
                                                            step,
                                                            false,
                                                            toggleHints,
                                                            hintId
                                                        )}{' '}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}{' '}
                            </div>
                        </div>
                    </div>
                )}

                {field?.type === BSB && (
                    <div className="default-forms">
                        <div className="flex mt-2 justify-between items-center">
                            {' '}
                            <label>{field.label} </label> <DefaultHint children={field.hint} />{' '}
                        </div>

                        <div className="mt-2">
                            <input
                                type="number"
                                className=""
                                placeholder={field?.placeholder}
                                value={field?.default_value ? field?.default_value : ''}
                                disabled
                            />
                        </div>
                    </div>
                )}

                {field.type === SALARY_WAGES_OCCUPATION && (
                    <div>
                        <div className="mt-2">
                            <label>{field?.label}</label>
                        </div>

                        <div className="mt-2">
                            {formArr?.customer_id ? (
                                <select
                                    value={field?.default_value}
                                    className="h-[3rem] mt-2 !text-[0.9rem] "
                                    disabled={true}
                                >
                                    <option value="">Select</option>
                                    <option
                                        value={field?.default_value}
                                        key={field.default_value}
                                        selected={field?.default_value ? true : false}
                                    >
                                        {' '}
                                        {field?.default_value}{' '}
                                    </option>
                                </select>
                            ) : (
                                <SearchableComboboxSSR
                                    fetchDataFunction={fetchWagesOptions}
                                    placeholder="Select"
                                    inputsx="!font-normal !text-sm"
                                    sx="!rounded-xl  !font-normal !ring-gray-400 mt-0 !flex"
                                    showNameOnly
                                    onChange={(obj) => {
                                        handleSalaryWages(field?.field_row_id, obj);
                                    }}
                                    limit={1}
                                />
                            )}
                        </div>
                    </div>
                )}

                {field.type === BUSINESS_INDUSTRY_OCCUPATION && (
                    <div>
                        <div className="mt-2">
                            <label>{field?.label}</label>
                        </div>

                        <div className="mt-2">
                            {formArr?.customer_id ? (
                                <select
                                    value={field?.default_value}
                                    className="h-[3rem] mt-2 !text-[0.9rem] "
                                    disabled={true}
                                >
                                    <option value="">Select</option>
                                    <option
                                        value={field?.default_value}
                                        key={field.default_value}
                                        selected={field?.default_value ? true : false}
                                    >
                                        {' '}
                                        {field?.default_value}{' '}
                                    </option>
                                </select>
                            ) : (
                                <SearchableComboboxSSR
                                    fetchDataFunction={fetchBusinessOccupationOptions}
                                    placeholder="Select"
                                    inputsx="!font-normal !text-sm"
                                    sx="!rounded-xl  !font-normal !ring-gray-400 mt-0 !flex"
                                    showNameOnly
                                    onChange={(obj) => {
                                        console.info(field?.field_row_id, obj);
                                    }}
                                    limit={1}
                                />
                            )}
                        </div>
                    </div>
                )}

                {field?.type === 'number' && (
                    <div className="mt-3 default-forms">
                        <div>
                            <div className="flex justify-between items-center">
                                {' '}
                                <label>
                                    {' '}
                                    {field?.label} {!field?.required && '(Optional)'}{' '}
                                </label>{' '}
                                <div onClick={() => toggleDisplayHint(field.field_row_id)}>
                                    <InfoIcon />{' '}
                                </div>
                            </div>
                        </div>
                        <div className=" ">
                            <input
                                type="number"
                                className=""
                                placeholder={field?.placeholder}
                                value={field?.default_value}
                            />
                        </div>
                    </div>
                )}
                {field?.type === 'email' || field?.type === 'business-email' ? (
                    <div className="mt-3 default-forms">
                        <div>
                            <div className="flex justify-between items-center">
                                {' '}
                                <label>
                                    {' '}
                                    {field?.label} {!field?.required && '(Optional)'}{' '}
                                </label>{' '}
                                <div onClick={() => toggleDisplayHint(field.field_row_id)}>
                                    <InfoIcon />{' '}
                                </div>
                            </div>
                            <div className=" ">
                                <input
                                    type="email"
                                    className="mt-2"
                                    placeholder={field?.placeholder}
                                    value={field?.default_value}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {field?.type === 'password' && (
                    <div className="mt-3 default-forms">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleHint(fieldIndex, index)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>
                        <div className=" mt-1">
                            <input
                                type="password"
                                className=""
                                placeholder={field?.placeholder}
                                value={field?.default_value}
                            />
                        </div>
                    </div>
                )}
                {field?.type === 'date' || field?.type === DOB ? (
                    <div className="mt-3 default-forms">
                        {' '}
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleDisplayHint(field.field_row_id)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>
                        {field?.default_value ? (
                            <div className="mt-1">
                                {' '}
                                <DatePick
                                    iconSet={datePickerProps}
                                    onDateChange={(date) => console.info(date)}
                                    placeholder="date"
                                    value={dayjs(field?.default_value, 'DD-MM-YYYY')}
                                    bg="transparent"
                                    sx="!rounded-xl text-gray-900 text-secondarybg"
                                    border="#979797"
                                    width="100%"
                                    isNextDateRequired={false}
                                />
                            </div>
                        ) : (
                            <div>
                                <div className="mt-1">
                                    <DatePick iconSet={datePickerProps} width="100%" />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    ''
                )}
                {field.type === 'switch' && (
                    <div className="mt-3 default-forms">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleHint(fieldIndex, index)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>

                        <div className="field-switch-wrapper mt-2">
                            <div
                                className={`field-switch ${
                                    field?.default_value === field.options[0] && 'active'
                                } flex justify-center items-center`}
                            >
                                {' '}
                                {field.options[0]}{' '}
                            </div>

                            <div
                                className={`field-switch ${
                                    field.default_value === field.options[1] && 'active'
                                } flex justify-center items-center`}
                            >
                                {' '}
                                {field.options[1]}{' '}
                            </div>
                        </div>
                    </div>
                )}
                {field.type === 'checkbox' && (
                    <div className="mt-3 items-center ">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleHint(fieldIndex, index)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>
                        <div className="flex gap-2 items-center flex-wrap mt-2">
                            {' '}
                            {field?.options?.map((op, i) => (
                                <div key={generateId()}>
                                    <PrimaryCheckbox
                                        id={'fieldOpt' + index + i}
                                        name={op + index + i}
                                        onchange={handleCheckboxChange}
                                        htmlFor={'fieldOpt' + index + i}
                                        checked={field?.default_value?.includes(op) ? true : false}
                                        label={op}
                                        inputSx={'!w-4 !h-4 !rounded-[4px]'}
                                        labelsx={'!text-md'}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {field.type === 'dropdown' ? (
                    <div className="mt-3 default-forms">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleDisplayHint(field.field_row_id)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>
                        <select
                            value={field?.default_value}
                            className="h-[3rem] mt-2 !text-[0.9rem] "
                            disabled={field?.default_value ? true : false}
                        >
                            <option value="">Select</option>
                            {field?.options?.map((option, i) => (
                                <option
                                    key={'option' + i}
                                    value={option}
                                    selected={field?.default_value === option ? true : false}
                                >
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : (
                    ''
                )}
                {field.type === 'radio' && (
                    <div className="mt-3  default-forms">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleDisplayHint(field.field_row_id)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>

                        <div className="flex flex-wrap w-full">
                            {field?.options?.map((op, ind) => (
                                <label
                                    key={ind}
                                    htmlFor={op + 'default' + ind}
                                    className="!flex items-center gap-3 flex-wrap text-sm font-poppins"
                                >
                                    <input
                                        type="radio"
                                        value={op}
                                        id={op + 'default' + ind}
                                        // name={'fieldoptions' + index}
                                        disabled
                                        defaultChecked={field?.default_value === op}
                                        // onChange={(event) => {
                                        //     console.info(event.target.value);
                                        // }}
                                        className="!w-4 !h-4 !text-third border-gray-300 focus:ring-third"
                                    />
                                    <PrimaryLabel sx={cn('font-[400] !w-auto cursor-pointer')}>{op || ''}</PrimaryLabel>
                                </label>
                            ))}
                        </div>
                    </div>
                )}
                {field.type === 'signature' && (
                    <div className="default-forms mt-3">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleHint(fieldIndex, index)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>
                        {field?.default_value ? (
                            <div className="signature-pad p-2 mt-1">
                                <img src={field?.default_value} alt="signature" />
                            </div>
                        ) : (
                            <div>
                                {' '}
                                <div className="signature-pad cursor-pointer shadow-lg mt-2">
                                    {' '}
                                    <SignatureCanvas
                                        ref={signatureRef}
                                        penColor="black"
                                        canvasProps={{
                                            width: 300,
                                            height: 200
                                        }}
                                    />{' '}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {field.type === 'image' && (
                    <div className="default-forms mt-3">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleDisplayHint(field.field_row_id)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>

                        {!field?.default_value ? (
                            <div>
                                <div>
                                    <div className=" add-ann-img-form mt-1 ">
                                        <label htmlFor="file-input" className="upload-app-label">
                                            <div className="add-app-camera flex justify-center">
                                                <ImageIcon />
                                            </div>
                                            <div className="add-app-camera-text mt-2 flex justify-center items-center">
                                                Click to upload image
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                onClick={() => toggleZoom(field?.default_value[0]?.url)}
                                className="w-full h-[200px] border border-secondarybg overflow-hidden rounded-lg cursor-pointer"
                            >
                                {field?.default_value[0]?.originalname?.toLowerCase()?.endsWith('.heic') ? (
                                    <img
                                        src={convertToJpeg(field?.default_value[0]?.url)}
                                        alt="alt"
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <img
                                        src={field?.default_value[0]?.url}
                                        alt="alt"
                                        className="w-full h-full object-cover"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
                {field.type === 'document' && (
                    <div className="default-forms mt-3">
                        <div className="flex justify-between items-center">
                            {' '}
                            <label>
                                {' '}
                                {field?.label} {!field?.required && '(Optional)'}{' '}
                            </label>{' '}
                            <div onClick={() => toggleHint(fieldIndex, index)}>
                                <InfoIcon />{' '}
                            </div>
                        </div>

                        {!field?.default_value ? (
                            <div>
                                <div>
                                    <div className=" add-ann-img-form mt-1 ">
                                        <label htmlFor="file-input" className="upload-app-label">
                                            <div className="add-app-camera flex justify-center invert">
                                                <FileIcon />
                                            </div>
                                            <div className="add-app-camera-text mt-2 flex justify-center items-center">
                                                Click to upload Document
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-2 items-center flex-wrap">
                                <a
                                    href={field?.default_value[0]?.url}
                                    className="w-full h-[200px] border cursor-pointer bg-thirdbg text-white buttons-font font-poppins border-secondarybg overflow-hidden rounded-lg flex justify-center items-center"
                                >
                                    <div className="flex gap-2 items-center flex-col">
                                        <div className="scale-125 invert">
                                            <FileIcon />
                                        </div>
                                        <div>{field?.default_value[0]?.originalname}</div>
                                    </div>
                                </a>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="add-p-side grid grid-cols-12 ">
            <div className="md:col-span-8 left-side" onClick={togglePreviewForm}></div>
            <div className="right-side col-span-12 md:col-span-4">
                {isLoading && <Loader />}
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    {isZoomed && <ZoomImage src={zoomUrl} alt="Profile" onClose={() => setIsZoomed(false)} />}

                    {confimationOpen && (
                        <DefaultDialog
                            open={confimationOpen}
                            setOpen={setConfirmationOpen}
                            width={'!w-[500px]'}
                            p="5"
                            children={
                                <div>
                                    <div className="buttons-font-lighter font-poppins my-4 text-center">
                                        As you have already created substeps of this field. by selecting this option all
                                        the substeps will be removed. <br /> Are you sure you want to select this option
                                    </div>
                                    <div className="w-full flex justify-between items-center mt-5">
                                        <Button
                                            title="Cancel"
                                            classes="!border-[#979797] border text-secondarybg rounded-xl px-5 py-2 buttons-font-lighter font-fira "
                                            atClick={() => {
                                                setConfirmed(false);
                                                setConfirmationOpen(false);
                                            }}
                                        />
                                        <Button
                                            title="Confirm"
                                            classes={
                                                'bg-linear font-fira text-white rounded-xl px-5 py-2 buttons-font-lighter '
                                            }
                                            atClick={() => {
                                                setConfirmed(true);
                                                setConfirmationOpen(false);
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    )}
                    <div>
                        <div
                            onClick={togglePreviewForm}
                            className="absolute   text-white p-2 right-1 top-1 cursor-pointer"
                        >
                            <SideTimes />
                        </div>
                        <div className="add-detail pt-5 px-5">
                            <div className="flex gap-2 items-center">
                                <div className="cursor-pointer" onClick={togglePreviewForm}>
                                    <span className="scale-75">
                                        {' '}
                                        <ArrowLeft />
                                    </span>
                                </div>
                                <div className="text-2xl font-poppins font-[500] ">
                                    {' '}
                                    {formArr?.form_name !== null || formArr?.form_name !== undefined ? (
                                        <span> {formArr?.form_name?.slice(0, 30)} </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>

                            <div className="small-text font-poppins mt-2 flex gap-1 items-center">
                                <MyComponent htmlContent={directory} />

                                {formArr?.form_name !== null || formArr?.form_name !== undefined ? (
                                    <span> {formArr?.form_name} </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex1 my-4 px-4">
                            <ProgressBarComponent
                                steps={
                                    formArr?.customer_id
                                        ? formArr?.steps.filter((item) => item.is_skipped === false)
                                        : formArr?.steps?.filter((st) => st.step_condition === false)
                                }
                                targetProperty="step"
                                activeStep={currentStep}
                                onClick={(step, index) => {
                                    setCurrentStep(index);
                                }}
                                isSubmitted={!formArr?.customer_id ? false : true}
                                is_creating={formArr?.customer_id ? false : true}
                            />
                        </div>
                    </div>

                    <div className="relative mx-8 mt-3 default-forms">
                        <div>
                            {Array.isArray(formArr?.steps) &&
                                formArr?.steps
                                    .filter((item) =>
                                        formArr?.customer_id ? item.is_skipped === false : item.step_condition === false
                                    )
                                    ?.map((step, index) => {
                                        return (
                                            <div key={'indexcustomer_id' + index}>
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: index === currentStep ? 'block' : 'none'
                                                    }}
                                                >
                                                    <div className=" mt-3">
                                                        {step?.fields?.map((field, fieldIndex) => (
                                                            <div key={'indexcustomer_id' + index + fieldIndex}>
                                                                {ReturnFieldsComponent(
                                                                    field,
                                                                    fieldIndex,
                                                                    index,
                                                                    step,
                                                                    false,
                                                                    field.type === 'conditional'
                                                                        ? itrations + 1
                                                                        : itrations,
                                                                    toggleHints,
                                                                    hintId
                                                                )}{' '}
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div
                                                        className={`mb-3 mt-10 ${step?.fields?.length < 4 && 'mt-32'}`}
                                                    >
                                                        <div className="flex justify-between items-center mt-5 ">
                                                            <div>
                                                                {currentStep > 0 && (
                                                                    <button
                                                                        className="border border-secondarybg h-[3rem] buttons-fonts px-8 rounded-xl font-fira"
                                                                        onClick={handlePrevious}
                                                                        disabled={currentStep === 0}
                                                                    >
                                                                        Previous
                                                                    </button>
                                                                )}
                                                            </div>

                                                            <div>
                                                                {' '}
                                                                {formArr.customer_id ? (
                                                                    <div>
                                                                        {currentStep < formArr.steps.length - 1 ||
                                                                        step?.fields?.some(
                                                                            (field) =>
                                                                                field?.display_on_new_page === true
                                                                        ) ? (
                                                                            <button
                                                                                className="ann-btn px-12 rounded-xl font-fira buttons-font"
                                                                                onClick={() => {
                                                                                    handleNext(step, index);
                                                                                }}
                                                                                disabled={
                                                                                    currentStep ===
                                                                                        formArr.steps.length - 1 &&
                                                                                    !step?.fields?.some(
                                                                                        (field) =>
                                                                                            field?.display_on_new_page ===
                                                                                            true
                                                                                    )
                                                                                }
                                                                            >
                                                                                Next
                                                                            </button>
                                                                        ) : (
                                                                            ''
                                                                        )}{' '}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        {currentStep <
                                                                            formArr.steps.filter(
                                                                                (step) => step.step_condition === false
                                                                            ).length -
                                                                                1 ||
                                                                        step?.fields?.some(
                                                                            (field) =>
                                                                                field?.display_on_new_page === true
                                                                        ) ? (
                                                                            <button
                                                                                className="ann-btn px-12 rounded-xl font-fira buttons-font"
                                                                                onClick={() => {
                                                                                    handleNext(step, index);
                                                                                }}
                                                                                disabled={
                                                                                    currentStep ===
                                                                                        formArr.steps.length - 1 &&
                                                                                    !step?.fields?.some(
                                                                                        (field) =>
                                                                                            field?.display_on_new_page ===
                                                                                            true
                                                                                    )
                                                                                }
                                                                            >
                                                                                Next
                                                                            </button>
                                                                        ) : (
                                                                            ''
                                                                        )}{' '}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default PreviewForm;
