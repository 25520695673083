/* eslint-disable no-prototype-builtins */
import Badge from 'components/atoms/Badge';
import { Button } from 'components/atoms/buttons/Button';
import ComboboxComponent from 'components/atoms/Combobox';
import { DatePick } from 'components/atoms/inputs/DatePick';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import Slideover from 'components/atoms/SlideOver';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import dayjs from 'dayjs';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { businessOwnerGetProfileThunk } from 'store/auth/slices';
import { verifyClientThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
// import { verifyClientThunk } from 'store/client';
// import { setLoader } from 'store/global/globalReducer';
import { fetchRegisteredEmployeesThunk } from 'store/settings/team/team';
import { downloadFile } from 'utills/filesHandlers';
import { acceptImages, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { FilesIcon } from 'utills/svgs/FilesIcon';
import { SideTimes } from 'utills/svgs/SideTimes';
import { VerifyPersonIcon } from 'utills/svgs/VerifyPersonIcon';

const VerifyClientProfileModal = ({ user, handleReload }) => {
    const [isVerifyModal, setIsVerifyModal] = useState(false);
    const [sliderState, setSliderState] = useState(false);
    const [verifyFiles, setVerifyFiles] = useState([]);
    const [showImage, setShowImage] = useState('');
    const navigation = useNavigate();

    const [dataValues, setDataValues] = useState({
        client_verification_method: 'Document Check'
    });
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployees);
    const { data: ownerProfile } = useSelector((state) => state.businessOwnerGetProfile);

    useEffect(() => {
        if (user) {
            setIsVerifyModal(!user?.is_client_verified);
            const newUser = generatePayload(user);
            setDataValues(newUser);
            if (user?.documents) {
                let newVerifyFiles = [];

                user?.documents?.forEach((doc) => {
                    newVerifyFiles?.push({ ...doc, is_opened: false });
                });

                setVerifyFiles(newVerifyFiles);
            }
        }
    }, [user]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (!employees) {
            dispatch(fetchRegisteredEmployeesThunk());
        }
        if (!ownerProfile) {
            dispatch(businessOwnerGetProfileThunk());
        }
    }, [employees, ownerProfile]);

    const seqOptions = [
        {
            label: 'Document Check'
        },
        {
            label: 'Verified in Person'
        },
        {
            label: 'Verified Online'
        },
        {
            label: 'Other'
        }
    ];

    const generatePayload = (user = null) => {
        if (user) {
            const propertiesArray = [
                'accountant',
                'partner_details',
                'director_details',
                'financial_profile',
                'shareholder_details',
                'public_officer_details',
                'secretary_details',
                'trustees',
                'member_details',
                'settlor_details',
                'appointer_details',
                'beneficiary_details',
                'upholder_details',
                'trading_name',
                //
                'related_references',
                'inherited_related_references',
                'smsf_trust_details'
            ];

            const updatedUser = { ...user };

            propertiesArray.forEach((property) => {
                if (user.hasOwnProperty(property)) {
                    if (property === 'accountant') {
                        if (updatedUser[property] !== null) {
                            updatedUser[property] = user[property]?.user_id;
                        }
                    } else {
                        if (updatedUser[property] === null) {
                            updatedUser[property] = [];
                        }
                    }
                }
            });
            return updatedUser;
        }
        return user;
    };

    const handleUpdate = () => {
        let hasError = false;

        if (verifyFiles?.length > 0) {
            for (let i = 0; i < verifyFiles.length; i++) {
                const file = verifyFiles[i];
                if (!file?.is_opened) {
                    hasError = true;
                    break;
                }
            }
        }

        if (hasError) {
            return toastHandler('Please preview all files first', TOAST_TYPE_ERROR);
        }

        if (!dataValues?.client_verification_method) {
            return toastHandler('Please provide client verification method', TOAST_TYPE_ERROR);
        }

        // if (!dataValues?.date_verified) {
        //     return toastHandler('Please provide client verification date', TOAST_TYPE_ERROR);
        // }

        // if (!dataValues?.verified_by) {
        //     return toastHandler('Please provide verified by', TOAST_TYPE_ERROR);
        // }
        dispatch(setLoader(true));

        dispatch(
            verifyClientThunk({
                client_verification_method: dataValues?.client_verification_method ?? 'Document Check',
                date_verified: dataValues?.date_verified ?? dayjs().format('DD-MM-YYYY'),
                verified_by: dataValues?.verified_by ?? ownerProfile?.user_id ?? '',
                notes: dataValues?.notes,
                is_client_verified: true,
                business_client_id: dataValues?.business_client_id ?? ''
            })
        )
            .then((response) => {
                if (response.payload) {
                    handleReload();
                    setSliderState(false);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleShowImage = (url, index) => {
        const newVerifyFiles = [...verifyFiles];

        setShowImage(url);
        newVerifyFiles[index].is_opened = true;

        setVerifyFiles(newVerifyFiles);
    };

    const handleShowPdf = (url, index) => {
        const newVerifyFiles = [...verifyFiles];

        newVerifyFiles[index].is_opened = true;

        setVerifyFiles(newVerifyFiles);
    };

    return (
        <>
            <DefaultDialog
                open={isVerifyModal}
                setOpen={() => {}}
                width={'md:w-[90%] lg:w-[550px] '}
                p={'!px-3 !py-2 !rounded-xl'}
            >
                <div className="relative">
                    <div className="absolute right-0 top-0 cursor-pointer" onClick={() => navigation(-1)}>
                        <SideTimes />
                    </div>
                    <div className="flex justify-center items-center pt-12">
                        <VerifyPersonIcon className={'w-36 ml-5'} />
                    </div>

                    <div className=" mt-4 text-blueish text-lg font-semibold font-poppins flex justify-center items-center">
                        Verification Required!
                    </div>
                    <div className="buttons-font-lighter font-poppins text-center mt-3 mx-5">
                        Effective client verification helps businesses build trust with their clients, protects against
                        fraud, and ensures compliance with regulatory requirements, Please verify your client.
                    </div>

                    <div className="mx-10 flex justify-center gap-4 items-center my-12">
                        <Button
                            atClick={() => navigation(-1)}
                            title="Cancel"
                            classes=" rounded-[9px] px-12 border focus:!ring-0 buttons-font-lighter border-secondarybg h-[3rem] text-secondarybg font-poppins   flex justify-center items-center"
                        />
                        <Button
                            atClick={() => {
                                setIsVerifyModal(!isVerifyModal);
                                setSliderState(true);
                            }}
                            title="Verify Client"
                            classes=" rounded-[9px] px-8 text-white buttons-font-lighter bg-linear h-[3rem]  font-poppins   flex justify-center items-center"
                        />
                    </div>
                </div>
            </DefaultDialog>

            <Slideover
                open={sliderState}
                setOpen={() => {
                    setIsVerifyModal(true);
                    setSliderState(false);
                }}
                hideBackArrow
            >
                {showImage && <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />}
                <h3 className="flex text-3xl font-semibold leading-9 text-slate-900 break-words max-w-[80%] font-fira">
                    Client Verification
                </h3>
                <p className="flex buttons-font-lighter mt-5 font-normal break-words">
                    Effective client verification helps businesses build trust with their clients, protects against
                    fraud, and ensures compliance with regulatory requirements.
                </p>{' '}
                <div className="add-status-container1 default-forms">
                    <div className=" flex flex-col gap-5 mt-8">
                        <div>
                            <div>
                                <label>Verification Method</label>
                            </div>

                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="label"
                                selectedValue={dataValues?.client_verification_method}
                                valuePropertyName="label"
                                optionsData={seqOptions || []}
                                sx="rounded-xl mt-2 py-3 ring-[#979797] bg-[#fff] !font-normal"
                                showChipOnly={false}
                                onChangeValue={(obj) =>
                                    setDataValues({ ...dataValues, client_verification_method: obj.label })
                                }
                            />
                        </div>
                    </div>

                    <div className="mt-5">
                        <div>
                            <label>Date Verified</label>
                        </div>
                        <div>
                            <DatePick
                                maxDate={dayjs()}
                                iconSet={{
                                    right: CalendarSmallIco
                                }}
                                onDateChange={(date) => {
                                    if (date) {
                                        setDataValues({
                                            ...dataValues,
                                            date_verified:
                                                typeof date === 'string' ? date : dayjs(date).format('DD-MM-YYYY')
                                        });
                                    } else {
                                        setDataValues({ ...dataValues, date_verified: '' });
                                    }
                                }}
                                placeholder="Select Date"
                                value={
                                    dataValues?.date_verified ? dayjs(dataValues?.date_verified, 'DD-MM-YYYY') : dayjs()
                                }
                                sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                            />
                        </div>
                    </div>

                    <div className="mt-5">
                        <div>
                            <label>Verified By</label>
                        </div>

                        <div className="default-forms">
                            {' '}
                            <ComboboxComponent
                                placeholder={'Verified By'}
                                data={employees}
                                defaultSelected={
                                    dataValues?.verified_by ? dataValues?.verified_by : ownerProfile?.user_id
                                }
                                onChange={(customerArray) => {
                                    if (customerArray.length) {
                                        const obj = customerArray[0];
                                        setDataValues({ ...dataValues, verified_by: obj?.user_id });
                                    }
                                }}
                                targetProperty="user_id"
                                limit={1}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <div>
                            <label>Notes</label>
                        </div>

                        <textarea
                            onChange={(e) => setDataValues({ ...dataValues, notes: e.target.value })}
                            value={dataValues?.notes}
                            className="w-full mt-2 rounded-xl border border-secondarybg h-[150px] focus:!ring-0 focus:!border-primary"
                            placeholder="Notes"
                        />
                    </div>
                    <div className="flex gap-3 items-center flex-wrap mt-5">
                        {verifyFiles?.map((file, index) => {
                            return (
                                <div key={'fileManager' + index}>
                                    {acceptImages.includes(file?.mimetype) ? (
                                        <div className="">
                                            <Badge
                                                leftIcon={
                                                    <img
                                                        src={file?.url}
                                                        alt="alt"
                                                        className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                        onClick={() => {
                                                            handleShowImage(file?.url, index);
                                                        }}
                                                    />
                                                }
                                                text={file?.originalname}
                                                sx="text-white bg-third rounded-md !px-3 !text-md"
                                            />
                                        </div>
                                    ) : ['application/pdf'].includes(file?.mimetype) ? (
                                        <div onClick={() => handleShowPdf(file.url, index)}>
                                            <FilesIcon className={'stroke-white !w-10 !h-10 mr-2 cursor-pointer'} />
                                        </div>
                                    ) : (
                                        <div onClick={() => downloadFile(file.url)}>
                                            <FilesIcon className={'stroke-white !w-10 !h-10 mr-2 cursor-pointer'} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-10 flex justify-center gap-5 items-center bg-white py-2">
                        <Button
                            atClick={() => {
                                setSliderState(false);
                                setIsVerifyModal(!isVerifyModal);
                            }}
                            title="Cancel"
                            classes=" rounded-[9px] px-12 border focus:!ring-0 buttons-font-lighter border-secondarybg h-[3rem] text-secondarybg font-poppins   flex justify-center items-center"
                        />
                        <Button
                            atClick={() => {
                                handleUpdate();
                            }}
                            title="Verify"
                            classes=" rounded-[9px] px-14 text-white buttons-font-lighter bg-linear h-[3rem]  font-poppins   flex justify-center items-center"
                        />
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default VerifyClientProfileModal;
