import React from 'react';

export const ProfileIcon = ({ className }) => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M7.32789 18.4279C9.36266 16.3931 12.1224 15.25 15 15.25C17.8776 15.25 20.6373 16.3931 22.6721 18.4279C24.7069 20.4627 25.85 23.2224 25.85 26.1C25.85 26.352 25.7499 26.5936 25.5718 26.7718C25.3936 26.9499 25.152 27.05 24.9 27.05C24.648 27.05 24.4064 26.9499 24.2282 26.7718C24.0501 26.5936 23.95 26.352 23.95 26.1C23.95 23.7263 23.0071 21.4498 21.3286 19.7714C19.6502 18.0929 17.3737 17.15 15 17.15C12.6263 17.15 10.3498 18.0929 8.67139 19.7714C6.99294 21.4498 6.05 23.7263 6.05 26.1C6.05 26.352 5.94991 26.5936 5.77175 26.7718C5.59359 26.9499 5.35196 27.05 5.1 27.05C4.84804 27.05 4.60641 26.9499 4.42825 26.7718C4.25009 26.5936 4.15 26.352 4.15 26.1C4.15 23.2224 5.29312 20.4627 7.32789 18.4279ZM17.9723 12.9484C17.0925 13.5362 16.0581 13.85 15 13.85C13.5811 13.85 12.2203 13.2863 11.217 12.283C10.2137 11.2797 9.65 9.91891 9.65 8.5C9.65 7.44187 9.96377 6.4075 10.5516 5.5277C11.1395 4.6479 11.9751 3.96218 12.9526 3.55725C13.9302 3.15232 15.0059 3.04637 16.0437 3.2528C17.0815 3.45923 18.0348 3.96877 18.783 4.71698C19.5312 5.46519 20.0408 6.41847 20.2472 7.45627C20.4536 8.49407 20.3477 9.56977 19.9428 10.5474C19.5378 11.5249 18.8521 12.3605 17.9723 12.9484ZM16.9167 5.63143C16.3494 5.25234 15.6823 5.05 15 5.05C14.085 5.05 13.2075 5.41348 12.5605 6.06048C11.9135 6.70748 11.55 7.58501 11.55 8.5C11.55 9.18235 11.7523 9.84937 12.1314 10.4167C12.5105 10.9841 13.0493 11.4263 13.6797 11.6874C14.3101 11.9485 15.0038 12.0168 15.6731 11.8837C16.3423 11.7506 16.957 11.422 17.4395 10.9395C17.922 10.457 18.2506 9.8423 18.3837 9.17306C18.5168 8.50383 18.4485 7.81015 18.1874 7.17974C17.9263 6.54934 17.4841 6.01052 16.9167 5.63143Z"
                fill="#0A1E46"
                stroke="#F8F4FE"
                strokeWidth="0.3"
            />
        </svg>
    );
};
