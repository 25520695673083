import React from 'react';

export const BgEnvelope = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#FAF9F6" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2437 16.89L23.8022 12.7454C24.5479 12.2742 25 11.4537 25 10.5716C25 9.15133 23.8487 8 22.4284 8H7.57156C6.15133 8 5 9.15133 5 10.5716C5 11.4537 5.45212 12.2742 6.1978 12.7454L12.7563 16.89C12.7924 16.9129 12.8282 16.9356 12.8638 16.9581C13.3755 17.2824 13.8254 17.5676 14.3294 17.6832C14.7707 17.7844 15.2293 17.7844 15.6706 17.6832C16.1746 17.5676 16.6245 17.2824 17.1363 16.9581C17.1718 16.9356 17.2076 16.9129 17.2437 16.89ZM16.7095 16.0447C16.0897 16.4363 15.7798 16.6322 15.4471 16.7085C15.1528 16.776 14.8472 16.776 14.5529 16.7085C14.2202 16.6322 13.9103 16.4363 13.2905 16.0447L6.73202 11.9001C6.27631 11.6121 6 11.1106 6 10.5716C6 9.70361 6.70361 9 7.57156 9H22.4284C23.2964 9 24 9.70361 24 10.5716C24 11.1106 23.7237 11.6121 23.268 11.9001L16.7095 16.0447Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 10.8414V18.8385C25 19.3657 25 19.8204 24.9694 20.195C24.9371 20.5904 24.8658 20.9836 24.673 21.362C24.3854 21.9264 23.9265 22.3854 23.362 22.673C22.9836 22.8658 22.5904 22.9371 22.195 22.9694C21.8205 23 21.3657 23 20.8385 23H9.16148C8.63432 23 8.17955 23 7.80497 22.9694C7.40963 22.9371 7.01641 22.8658 6.63803 22.673C6.07354 22.3854 5.6146 21.9264 5.32698 21.362C5.13419 20.9836 5.06287 20.5904 5.03057 20.195C4.99997 19.8204 4.99998 19.3657 5 18.8385L5 10.8414L12.97 15.8779C13.9624 16.5051 14.26 16.6771 14.5529 16.7443C14.8472 16.8118 15.1528 16.8118 15.4471 16.7443C15.74 16.6771 16.0376 16.5051 17.03 15.8779L25 10.8414ZM12.4358 16.7233C13.3654 17.3108 13.8303 17.6045 14.3294 17.719C14.7707 17.8202 15.2293 17.8202 15.6706 17.719C16.1697 17.6045 16.6346 17.3108 17.5642 16.7233L24 12.6562V18.8C24 19.9201 24 20.4801 23.782 20.908C23.5903 21.2843 23.2843 21.5902 22.908 21.782C22.4802 22 21.9201 22 20.8 22H9.2C8.0799 22 7.51984 22 7.09202 21.782C6.7157 21.5902 6.40974 21.2843 6.21799 20.908C6 20.4801 6 19.9201 6 18.8V12.6562L12.4358 16.7233Z"
                fill="black"
            />
        </svg>
    );
};
