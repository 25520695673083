import React, { useEffect, useState } from 'react';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import { SignatureIcon } from 'utills/svgs/SignatureIcon';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import { Button } from 'components/atoms/buttons/Button';
import { PlusVector } from 'utills/svgs/PlusVector';
import CreateUserEmailSignature from './CreateUserEmailSignature';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import { DeleteIcon } from 'utills/svgs/DeleteIcon';
import { cn } from 'utills/tailwindUtil';
import SignatureModuleDefault from 'components/molecules/SignatureModuleDefault';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/common/Loader';
import {
    getDefaultSignatureThunk,
    getUserEmailSignatureThunk,
    removeUserEmailSignatureThunk
} from 'store/settings/JobPortal';
import { BUSINESS_ID } from 'utills/globalVars';
import FormateDateTime from 'components/atoms/FormateDateTime';
import { toBoolean } from 'utills/handleText';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import { setLoader } from 'store/global/globalReducer';
import ConfirmationModal from 'utills/confirmationModal';

export const UserEmailSignatureSettings = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);
    const { data: getUserEmailSignature } = useSelector((state) => state.getUserEmailSignature);
    const [open, setOpen] = useState(false);
    const [editObj, setEditObj] = useState(null);
    const [defaultData, setDefaultData] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [removeId, setRemoveId] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            dispatch(setLoader(true));
            await loadSignatureTable();
        };
        if (!getUserEmailSignature) {
            loadData();
        }
        getDefaultData();
    }, []);

    const handleRemove = () => {
        if (removeId) {
            setIsConfirmationOpen(false);
            dispatch(setLoader(true));
            dispatch(removeUserEmailSignatureThunk({ business_id, user_signature_id: removeId }))
                .then((res) => {
                    if (res.payload) {
                        loadSignatureTable();
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    setRemoveId(null);
                });
        }
    };

    const loadSignatureTable = () => {
        dispatch(getUserEmailSignatureThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const getDefaultData = () => {
        dispatch(getDefaultSignatureThunk({ business_id })).then((res) => {
            if (res?.payload && res?.payload?.length > 0) {
                setDefaultData(res?.payload);
            } else {
                setDefaultData([]);
            }
        });
    };

    const NotFoundData = () => {
        return (
            <div>
                <div className="flex flex-col justify-center items-center lg:w-1/2 m-auto gap-3 mt-24">
                    <SignatureIcon className="!w-20 !h-20" />
                    <PrimaryLabel sx={'font-poppins font-semibold !text-3xl my-2'}>No Signature Found</PrimaryLabel>
                    <PrimaryLabel sx={'font-poppins font-medium text-lg'}>No Organization Signature Found</PrimaryLabel>
                    <PrimaryParagraph sx={'text-center mt-7'}>
                        It seems there’s no organization signature here. Please add a signature to proceed.
                    </PrimaryParagraph>
                    <Button
                        title={'Create Signature'}
                        iconSet={{ leftIcon: PlusVector }}
                        iconInvert={true}
                        atClick={() => {
                            setOpen(true);
                        }}
                        classes={'px-5 flex gap-2 items-center py-3 rounded-xl text-white ann-btn mt-5'}
                    />
                </div>
            </div>
        );
    };
    return (
        <>
            <SidebarLayout>
                <Jumbotron
                    title="User Email Signature"
                    actionsRequired={true}
                    actions={[
                        {
                            title: 'Create New',
                            type: 'button',
                            onAction: () => setOpen(true),
                            classes: 'ann-btn rounded-xl px-8 font-[400] font-fira'
                        }
                    ]}
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },
                        {
                            link: '',
                            title: 'User Email Signature'
                        }
                    ]}
                />

                {isLoading && <Loader />}
                {open && (
                    <CreateUserEmailSignature open={open} setOpen={setOpen} editObj={editObj} setEditObj={setEditObj} />
                )}

                <ConfirmationModal
                    isOpen={isConfirmationOpen}
                    onClose={() => setIsConfirmationOpen(false)}
                    onConfirm={() => {
                        handleRemove();
                    }}
                />

                <div className="flex flex-col gap-5 w-full px-10">
                    {!getUserEmailSignature || (getUserEmailSignature.length === 0 && <NotFoundData />)}
                    {getUserEmailSignature && getUserEmailSignature?.length > 0 && (
                        <div className="mb-10">
                            <DefaultLightBgCard sx="bg-white" title="Signature List">
                                <div className="">
                                    <table className="min-w-full border-separate border-spacing-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className={
                                                        'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !w-[200px] text-left'
                                                    }
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className={
                                                        'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                    }
                                                >
                                                    Create Date
                                                </th>

                                                <th
                                                    scope="col"
                                                    className={
                                                        'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                    }
                                                >
                                                    Last Updated
                                                </th>
                                                <th
                                                    scope="col"
                                                    className={
                                                        'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left'
                                                    }
                                                >
                                                    Status
                                                </th>

                                                <th
                                                    scope="col"
                                                    className={
                                                        'sticky top-0 z-[4] !border-b !border-gray-300 bg-white backdrop-blur backdrop-filter  buttons-font primary-fonts !pl-[10px] !w-[200px] text-left text-center'
                                                    }
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-transparent gap-0">
                                            {getUserEmailSignature?.map((dataObj, index) => {
                                                return (
                                                    <tr
                                                        key={'req_doc_table' + dataObj?.user_signature_id}
                                                        className="border-y relative  divide-gray-300"
                                                    >
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap !text-[16px] py-2 !max-w-[200px] truncate relative ',
                                                                index !== getUserEmailSignature?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {dataObj?.name || ''}
                                                        </td>
                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== getUserEmailSignature?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {dataObj?.created_at ? (
                                                                <FormateDateTime dateTime={dataObj?.created_at} />
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </td>

                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== getUserEmailSignature?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {dataObj?.updated_at ? (
                                                                <FormateDateTime dateTime={dataObj?.updated_at} />
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </td>

                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-black',
                                                                index !== getUserEmailSignature?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            {toBoolean(dataObj?.is_default) ? (
                                                                <span className="bg-green-100 rounded-xl px-4 py-1.5 positive-status relative !text-sm font-popppins flex items-center gap-1 w-fit">
                                                                    <span className="w-2 h-2 flex rounded-full bg-green-500" />{' '}
                                                                    Active
                                                                </span>
                                                            ) : (
                                                                <span className="bg-red-100 rounded-xl px-4 py-1.5 text-red-800 relative !text-sm font-popppins flex items-center gap-1 w-fit">
                                                                    <span className="w-2 h-2 rounded-full bg-red-500"></span>{' '}
                                                                    Inactive
                                                                </span>
                                                            )}
                                                        </td>

                                                        <td
                                                            className={cn(
                                                                'whitespace-nowrap text-[14px] lg:text-[16px] px-3 py-5 text-block text-center',
                                                                index !== getUserEmailSignature?.length
                                                                    ? '!border-b !border-gray-200'
                                                                    : ''
                                                            )}
                                                        >
                                                            <div className="flex justify-center items-center gap-2">
                                                                <Button
                                                                    iconSet={{
                                                                        leftIconHTML: <BgEditIcon className={''} />
                                                                    }}
                                                                    classes={'!rounded-full'}
                                                                    groupSx={'!contents'}
                                                                    atClick={() => {
                                                                        setEditObj(dataObj);
                                                                        setOpen(true);
                                                                    }}
                                                                />
                                                                <Button
                                                                    iconSet={{
                                                                        leftIconHTML: (
                                                                            <DeleteIcon
                                                                                className={
                                                                                    '!min-w-4 w-5 text-[#0A1E46]'
                                                                                }
                                                                            />
                                                                        )
                                                                    }}
                                                                    classes={'!rounded-full bg-[#0A1E46]/10 p-2'}
                                                                    atClick={() => {
                                                                        setRemoveId(dataObj?.user_signature_id);
                                                                        setIsConfirmationOpen(true);
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </DefaultLightBgCard>
                            {/* <SignatureModuleDefault
                                title="Signature defaults For Email Client"
                                email_type="email_client"
                                dafaultDataObject={
                                    defaultData ? defaultData?.find((obj) => obj?.email_type === 'email_client') : null
                                }
                                loadSignatureTable={loadSignatureTable}
                            /> */}
                            <SignatureModuleDefault
                                title="Signature defaults For Relay Room"
                                email_type="relay_room"
                                dafaultDataObject={
                                    defaultData ? defaultData?.find((obj) => obj?.email_type === 'relay_room') : null
                                }
                                loadSignatureTable={loadSignatureTable}
                            />
                        </div>
                    )}
                </div>
            </SidebarLayout>
        </>
    );
};
