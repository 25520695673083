import { Button } from 'components/atoms/buttons/Button';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import { MultiTextInputAddComponent } from 'components/molecules/MultiTextInputAddComponent/MultiTextInputAddComponent';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { createJobsStatusThunk, getAllJobsStatusesThunk, updateJobsStatusThunk } from 'store/workspace/workspaceTasks';
import { BUSINESS_ID, ERROR_TYPE_ERROR } from 'utills/globalVars';

import { generateId } from 'utills/uid';

const AddJobStatus = ({ open, setOpen, editJobStatus, onComplete = () => {}, addSingle = false }) => {
    const formRef = useRef(null); // Create a form reference
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { isLoading } = useSelector((state) => state.global);
    const [fields, setFields] = useState([
        {
            id: generateId(),
            text: ''
        }
    ]);

    useEffect(() => {
        if (editJobStatus) {
            setFields([{ id: editJobStatus?.job_status_id, text: editJobStatus?.label }]);
        }
    }, [editJobStatus]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (fields.length < 1) {
            toastHandler('Please enter atleast one status', ERROR_TYPE_ERROR);
            return;
        }

        const hasEmpty = fields?.find((obj) => obj?.text === '');
        if (hasEmpty) {
            toastHandler('Status Fileds cannot be Empty!', ERROR_TYPE_ERROR);
            return;
        }

        let statuses = [];
        const newInputs = [...fields];

        newInputs.map((input) => {
            statuses.push(input?.text);
        });

        const payload = {
            business_id,
            statuses: statuses
        };

        dispatch(createJobsStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    dispatch(getAllJobsStatusesThunk({ business_id }));
                    if (onComplete) {
                        onComplete();
                    }
                }
            })
            .finally(() => {
                setOpen(false);
            });
    };

    const handleUpdate = (e) => {
        e.preventDefault();

        const { id, text } = fields[0];

        if (text === '') {
            toastHandler('Status Filed is Required!', ERROR_TYPE_ERROR);
            return;
        }
        const payload = {
            business_id,
            job_status_id: id,
            label: text
        };
        dispatch(updateJobsStatusThunk(payload))
            .then((response) => {
                if (response.payload) {
                    dispatch(getAllJobsStatusesThunk({ business_id }));
                }
            })
            .finally(() => {
                setOpen(false);
            });
    };

    const handleFormSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }
    };

    return (
        <Slideover
            title={editJobStatus ? 'Edit Job Status' : 'Add Job Status'}
            open={open}
            setOpen={setOpen}
            footer={
                <div className="flex justify-center items-center mb-10 ">
                    <Button
                        title={editJobStatus ? 'Update' : 'Add'}
                        buttonType={'submit'}
                        classes="ann-btn px-24 rounded-xl"
                        atClick={handleFormSubmit}
                    />
                </div>
            }
        >
            {isLoading && <Loader />}

            <form onSubmit={editJobStatus ? handleUpdate : handleSubmit} ref={formRef} className="mt-10">
                <MultiTextInputAddComponent
                    placeholder="Enter Title"
                    fields={fields}
                    setFields={setFields}
                    addSingle={addSingle}
                />
            </form>
        </Slideover>
    );
};

export default AddJobStatus;
