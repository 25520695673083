import React from 'react';

export const MobileModal = () => {
    return (
        <div className="mobile-modal !bg-linear flex flex-col h-full w-full items-center justify-center">
            <div className="page-404-header absolute top-5 left-5">Synkli</div>
            <div className="px-10 font-bold">For mobile view, Synkli App is Coming Soon...</div>
        </div>
    );
};
