/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button } from 'components/atoms/buttons/Button';
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import { Loader } from 'components/common/Loader';
import Recaptcha from 'components/molecules/Recaptcha';
import { useCaptchaHandler } from 'Hooks/reCaptcha/useCaptchaHandler';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { preverifyPhoneOtpthunk, sentOtpToPhoneThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';

export const VerifyPhoneOTP = (props) => {
    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerEnded, setTimerEnded] = useState(false);
    const dispatch = useDispatch();
    const { error, setError, isCaptchaRequired, recaptchaRef, handleRequest, handleCaptchaChange } =
        useCaptchaHandler();

    const isLoading = useSelector((state) => state.global.isLoading);
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [otp, setOtp] = useState([null, null, null, null, null, null]);
    const [otpVal, setOtpVal] = useState('');

    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        timeExecution();
    }, []);

    const handleSubmit = async (e) => {
        if (otpVal?.length < 6) {
            toastHandler('Please enter full otp', 'error');
            return;
        }
        const opt = parseInt(otp.join(''));
        const otpData = {
            email: parsedData?.email,
            otp: otpVal,
            user_type: 'employee',
            type: 4
        };
        dispatch(setLoader(true));
        await dispatch(preverifyPhoneOtpthunk(otpData))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    props.handleCondition(15);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });
        dispatch(setLoader(false));
    };

    const handleSendOtpToPhone = () => {
        dispatch(setLoader(true));

        dispatch(sentOtpToPhoneThunk({ email: parsedData?.email, type: 4, user_type: 'employee' }))
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleReSendOtpToPhone = async () => {
        dispatch(setLoader(true));

        // dispatch(sentOtpToPhoneThunk({ email: parsedData?.email, type: 4, user_type: 'employee' }))
        await handleRequest(
            'sentOtpToPhone',
            (payload = {}) =>
                dispatch(
                    sentOtpToPhoneThunk({
                        ...payload
                    })
                ),
            { email: parsedData?.email, type: 4, user_type: 'employee' }
        )
            .then((response) => {
                if (response?.payload) {
                    setTimerEnded(false);
                    setTimeRemaining(90);
                    timeExecution();
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        handleSendOtpToPhone();
    }, []);

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    return (
        <div>
            {' '}
            <div>
                {isLoading && (
                    <div className="loader-overlay">
                        <Loader />
                    </div>
                )}
                <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                    <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px]  lg:max-w-[640px] xl:max-w-[650px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                        {' '}
                        <div className="text-center card-layout-title-text font-fira ">Phone OTP Verification</div>
                        <div className="otp-wrapper px-5 md:px-24 mt-10">
                            <div className=" small-text font-poppins text-sixth ">
                                <p className="p-1">
                                    A 6-digit code is sent to your phone. Kindly enter that code here to continue
                                    further. Thanks
                                </p>
                            </div>
                            <div className=" ">
                                <div className="mt-8">
                                    <OtpInputs value={otpVal} onChange={handleOtpChange} required={true} />
                                </div>
                            </div>

                            {isCaptchaRequired && (
                                <div className="my-3">
                                    <Recaptcha
                                        ref={recaptchaRef}
                                        captchaError={!!error}
                                        setCaptchaError={setError}
                                        onValueChange={handleCaptchaChange}
                                    />
                                </div>
                            )}

                            <div className="resend-confirmation mt-5 text-sm font-poppins">
                                <p>
                                    {timerEnded ? (
                                        <span onClick={handleReSendOtpToPhone} className="text-thirdbg cursor-pointer">
                                            Resend
                                        </span>
                                    ) : (
                                        <span>
                                            {!timerEnded && (
                                                <span>
                                                    <span className="text-thirdbg  cursor-pointer mr-1">Resend</span>
                                                    OTP in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`})
                                                </span>
                                            )}
                                        </span>
                                    )}
                                </p>
                            </div>

                            <div className="mt-60 mb-16">
                                <div className="flex justify-between items-center px-1-">
                                    <Button
                                        title="Back"
                                        atClick={() => props.handleCondition(4)}
                                        classes="border-button text-secondarybg buttons-font font-fira rounded-lg px-10"
                                    />
                                    <button onClick={handleSubmit} className="btn-2 px-10 buttons-font font-fira py-2">
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
