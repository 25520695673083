import React, { useState } from 'react';
import { ParseHtml } from './../../Hooks/ParseHtml';
import { Button } from 'components/atoms/buttons/Button';
import Breadcrumb from 'components/atoms/Breadcrumb';
import NotificationIcon from 'utills/svgs/NotificationIcon';
import NotificationSlider from './NotificationSlider';
import { cn } from 'utills/tailwindUtil';
import { useSelector } from 'react-redux';
import { generateId } from 'utills/uid';

export const Jumbotron = ({
    title,
    directory,
    path,
    highlightDirectory,
    actionsRequired,
    actions,
    backArrowProp,
    showNotification,
    inlineActions,
    alignCss,
    children
}) => {
    const [notificatonModal, setNotificatonModal] = useState(false);
    const { data: getNotificationCount } = useSelector((state) => state.getNotificationCount);
    return (
        <div>
            <div className=" relative  md:flex md:flex-row flex-col  px-10 pt-10 pb-5 md:justify-between mb-5">
                <div>
                    {' '}
                    <div className="profile-jumbo-flex">
                        <div className="jumbo-flex-1 ">
                            <div className="flex justify-between items-center ">
                                <div className={` ${backArrowProp && 'flex gap-4 items-center'} `}>
                                    <div>
                                        {backArrowProp && (
                                            <span onClick={backArrowProp.atClick} className="cursor-pointer">
                                                {' '}
                                                <backArrowProp.icon />{' '}
                                            </span>
                                        )}
                                    </div>
                                    <div className="text-3xl font-fira font-[600]"> {title}</div>
                                </div>
                            </div>
                            {path ? (
                                <Breadcrumb path={path} />
                            ) : (
                                <div className="text-secondarybg small-text flex items-center flex-wrap font-poppins">
                                    <ParseHtml htmlContent={directory} />
                                    <span className="special-jumbo-text pl-2 text-third small-text font-poppins">
                                        <ParseHtml htmlContent={highlightDirectory} />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={cn(
                            'md:mt-0 my-2 lg:absolute right-10',
                            inlineActions ? 'top-[33px] md:top-[33px]' : 'top-12 md:top-16',
                            alignCss
                        )}
                    >
                        {' '}
                        {actionsRequired && (
                            <div className="flex mt-5 lg:mt-0 gap-2 items-center flex-wrap">
                                {children}
                                {actions &&
                                    actions?.map((btn, index) => (
                                        <div key={generateId() + index}>
                                            <Button
                                                title={btn?.title}
                                                type={btn?.type}
                                                atClick={btn?.onAction}
                                                classes={btn?.classes}
                                                iconSet={btn?.iconSet}
                                            />
                                        </div>
                                    ))}
                                {showNotification && (
                                    <span
                                        className="relative  bg-[#F5F5F5]  rounded-full flex items-center justify-center w-12 h-12 cursor-pointer hover:bg-gray-200 hover:ring-1 hover:ring-third"
                                        onClick={() => setNotificatonModal(true)}
                                    >
                                        <NotificationIcon className={'w-5'} />
                                        {getNotificationCount && getNotificationCount?.count !== 0 && (
                                            <span
                                                className={cn(
                                                    'absolute right-0 -top-[5px] text-[12px] block bg-primary ring-1 text-white rounded-full ring-white text-xs text-center',
                                                    'min-w-5 h-5 w-fit px-[5px] content-center'
                                                )}
                                            >
                                                {getNotificationCount.count}
                                            </span>
                                        )}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {notificatonModal && <NotificationSlider open={notificatonModal} setOpen={setNotificatonModal} />}
        </div>
    );
};
