import React from "react";

import NewPass from "../../components/login-steps/NewPass";
export const NewPassword = () => {
  return (
    <div>
      <NewPass />
    </div>
  );
};
