/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { Button } from 'components/atoms/buttons/Button';
import { DownloadFileIcon } from 'utills/svgs/DownloadFileIcon';
import { SearchInput } from 'components/molecules/SearchInput';
import { CPK_TRIP_EXPENSE, LB_EXPENSE, LB_TRIP, LB_TRIP_EXPENSE, SORT_OPTIONS } from 'utills/globalVars';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import { Menu } from 'components/molecules/Menu';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import './css/organisms.css';
import { DropDownMenu } from 'components/molecules/DropDownMenu';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { Pdf } from 'utills/svgs/Pdf';
import { CsvPlusImageIcon } from 'utills/svgs/CsvPlusImageIcon';
import { RoundedPhotoIcon } from 'utills/svgs/RoundedPhotoIcon';
import { ClearFilterButton } from 'components/atoms/buttons/ClearFilterButton';
import { useDispatch, useSelector } from 'react-redux';
import { formateDate } from 'utills/moment';
import { UseDownloadCsv } from 'Hooks/Files/UseDownloadCsv';
import { useDownloadImages } from 'Hooks/Files/useDownloadImages';
import { SmallLoader } from 'components/common/SmallLoader';
import { NoDataFound } from 'components/common/NoDataFound';
import { getTripSummaryDetailsThunk } from 'store/deductions';
import { DatePick } from 'components/atoms/inputs/DatePick';
import moment from 'moment';
import 'moment-timezone';
import { PhotoIcon } from 'utills/svgs/PhotoIcon';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { toastHandler } from 'responseHanlder';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import dayjs from 'dayjs';
import Slideover from 'components/atoms/SlideOver';
import { cn } from 'utills/tailwindUtil';
import { Truncate } from 'components/molecules/Truncate';
import { useParams } from 'react-router-dom';

export const TripAndLogbookSummary = ({ title, registration_number, open, setOpen }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const [theSummary, setTheSummary] = useState([]);
    const [summaryCount, setSummaryCount] = useState(0);
    const [summaryInfo, setSummaryInfo] = useState(null);
    const [page, setPage] = useState(1);

    const [pageSize, _setPageSize] = useState(15);
    const [searchTerm, setSearchTerm] = useState('');
    const { summary_id, summaryType } = useSelector((state) => state.global);
    const { handleDownloadCsv } = UseDownloadCsv();

    const { handleDownloadImages } = useDownloadImages();
    const { sideLoader } = useSelector((state) => state.global);
    const [sortAsc, setSortAsc] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [sumType, _setSumType] = useState(summaryType);
    const [hasMoreContent, setHasMoreContent] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomUrl, setZoomUrl] = useState(null);
    const [defaultValues, setDefaultValues] = useState(null);
    const timeZone = moment.tz.guess();

    const [chartType, setChartType] = useState('trip');

    useEffect(() => {
        setSummaryID();
    }, [summaryType]);
    const setSummaryID = () => {
        let chartTypeValue = '';
        switch (summaryType) {
            case LB_TRIP:
                chartTypeValue = 'trip';
                break;
            case LB_EXPENSE:
                chartTypeValue = 'expense';
                break;
            case LB_TRIP_EXPENSE:
                chartTypeValue = 'trip';
                break;
            case CPK_TRIP_EXPENSE:
                chartTypeValue = 'cpk_trip';
                break;
            default:
                return '';
        }

        setChartType(chartTypeValue);
    };

    const handleZoom = (url) => {
        setZoomUrl(url);
        setIsZoomed(!isZoomed);
    };

    const handleClear = () => {
        setTheSummary([]);
        setFromDate('');
        setToDate('');
        setSearchTerm('');
        setPage(1);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const datePickerProps = {
        right: CalendarSmallIco
    };

    const exportDataOptions = [
        {
            title: 'CSV File',
            icon: CsvIcon
        },
        {
            title: 'PDF File',
            icon: Pdf
        },
        {
            title: 'CSV + Image File',
            icon: CsvPlusImageIcon
        },
        {
            title: 'Images',
            icon: RoundedPhotoIcon
        }
    ];

    const getDetails = (filter) => {
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom =
                fromDate && toDate && fromDate !== '' && toDate !== ''
                    ? moment(new Date(fromDate)).format('DD-MM-YYYY')
                    : '';
            formatedTo =
                fromDate && toDate && fromDate !== '' && toDate !== ''
                    ? moment(new Date(toDate)).format('DD-MM-YYYY')
                    : '';
        }
        if (formatedFrom !== '' && formatedTo === '') {
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            return;
        }
        const payload = {
            business_client_id: id,
            trip_summary_id: summary_id,
            summary_type: summaryType,
            list_type: chartType === 'cpk_trip' ? 'trip' : chartType,
            search_term: searchTerm,
            sort_by: sortAsc ? 'DESC' : 'ASC',
            page: filter ? 1 : page,
            page_size: pageSize,
            from_date: formatedFrom,
            to_date: formatedTo
        };
        setLoading(true);
        setHasMoreContent(true);
        dispatch(getTripSummaryDetailsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setSummaryInfo(response.payload?.summary);

                    if (!defaultValues) {
                        setDefaultValues({
                            from_date: response.payload?.summary?.from_date
                                ? dayjs(response.payload?.summary?.from_date, 'DD-MM-YYYY')
                                : '',
                            to_date: response.payload?.summary?.to_date
                                ? dayjs(response.payload?.summary?.to_date, 'DD-MM-YYYY')
                                : ''
                        });
                    }
                    setSummaryCount(response.payload?.detail?.count);

                    if (filter) {
                        setTheSummary([]);
                        const list = [...response.payload.detail?.rows];
                        if (list.length === response?.payload?.detail?.count) {
                            setHasMoreContent(false);
                        }
                        setTheSummary(list);
                    } else {
                        const list = [...theSummary, ...response.payload.detail?.rows];
                        if (list.length === response?.payload?.detail?.count) {
                            setHasMoreContent(false);
                        }
                        setTheSummary(list);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleMoreSummry = () => {
        const totalPages = Math.ceil(summaryCount / pageSize);
        if (page < totalPages) {
            setPage(page + 1);
        }
    };
    useEffect(() => {
        getDetails(true);
    }, [searchTerm, sortAsc, fromDate, toDate, sumType, chartType]);

    useEffect(() => {
        getDetails(false);
    }, [page]);

    const handleDownload = (type) => {
        if (theSummary.length === 0) {
            toastHandler('No File to download', 'error');
            return;
        }
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom = moment(new Date(fromDate)).format('DD-MM-YYYY');
            formatedTo = moment(new Date(toDate)).format('DD-MM-YYYY');
        }

        if (formatedFrom !== '' && formatedTo === '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        let exportType = '';
        if (type === 'CSV File') {
            exportType = 'csv';
        } else if (type === 'PDF File') {
            exportType = 'pdf';
        } else if (type === 'CSV + Image File') {
            exportType = 'zip';
        } else if (type === 'Images') {
            exportType = 'images';
        }
        if (exportType) {
            handleDownloadCsv(
                {
                    trip_summary_id: summary_id,
                    summary_type: chartType,
                    from: formatedFrom,
                    to: formatedTo,
                    search_term: searchTerm,
                    export_as: exportType,
                    timeZone: timeZone
                },
                'trip/download-file'
            );
        }
    };

    const downloadImages = () => {
        let formatedFrom = '';
        let formatedTo = '';
        if (fromDate && toDate) {
            formatedFrom = moment(new Date(fromDate)).format('DD-MM-YYYY');
            formatedTo = moment(new Date(toDate)).format('DD-MM-YYYY');
        }

        if (formatedFrom !== '' && formatedTo === '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        if (formatedFrom === '' && formatedTo !== '') {
            toastHandler('Kindly select From and To Date.', 'error');
            return;
        }
        handleDownloadImages(
            {
                trip_summary_id: summary_id,
                from_date: formatedFrom,
                to_date: formatedTo,
                search_term: searchTerm,
                business_client_id: id
            },
            'trip/download-images'
        );
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
    };

    const generateFilters = () => {
        return (
            <div className="flex gap-2 items-center flex-wrap">
                <SearchInput
                    width="250px"
                    atChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    value={searchTerm}
                    placeholder="Search"
                    inputBorder="1px solid #DCDCDC"
                />
                <Menu
                    title="Order By"
                    iconSet={{
                        first: SortIcon,
                        second: StepDownIcon
                    }}
                    options={SORT_OPTIONS}
                    gap={5}
                    lastMl={5}
                    isModalDisabled={true}
                    condition={sortAsc}
                    atMenuClick={() => {
                        handleSortByDate();
                    }}
                    text="max-md"
                />
                <DatePick
                    iconSet={datePickerProps}
                    onDateChange={handleFromDateChange}
                    placeholder="From"
                    value={fromDate}
                    defaultValue={defaultValues?.from_date}
                    minDate={moment(summaryInfo?.from_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                    maxDate={moment(summaryInfo?.to_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                />
                <DatePick
                    iconSet={datePickerProps}
                    onDateChange={handleToDateChange}
                    placeholder="To"
                    value={toDate}
                    defaultValue={defaultValues?.to_date}
                    minDate={moment(summaryInfo?.from_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                    maxDate={moment(summaryInfo?.to_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? ''}
                />
                {toDate || fromDate || searchTerm ? <ClearFilterButton atClick={() => handleClear()} /> : ''}
            </div>
        );
    };

    return (
        <>
            <Slideover open={open} setOpen={setOpen} showLoader size={'max-w-7xl'} hideBackArrow>
                {isZoomed && <ZoomImage src={zoomUrl} alt="Profile" onClose={() => setIsZoomed(false)} />}
                <div className="relative min-h-full pb-[0px]">
                    <div className="add-detail flex gap-5 items-center -mt-5">
                        <div className="title1 xl-title !font-semibold flex gap-2 items-center">{title}</div>
                        <div className="tracking-normal">
                            <span className="px-4 py-1.5 text-third bg-[#b695f838] !font-medium !rounded-xl !text-sm lg:text-lg ruby">
                                Registration Num: <b>{registration_number}</b>
                            </span>
                        </div>
                    </div>
                    <div className="mt-4 flex justify-between items-baseline">
                        <div>
                            {summaryType !== CPK_TRIP_EXPENSE && (
                                <div className="!w-[300px]">
                                    <div
                                        className={cn(
                                            'field-switch-wrapper mt-2 cursor-pointer !h-[2.6rem] !rounded-xl ',
                                            ![LB_TRIP_EXPENSE].includes(summaryType) && '!grid-cols-1 !max-w-[200px]'
                                        )}
                                    >
                                        {[LB_TRIP, LB_TRIP_EXPENSE].includes(summaryType) && (
                                            <div
                                                className={`field-switch ${
                                                    chartType === 'trip' && 'active'
                                                } flex justify-center items-center !h-[2.6rem] !rounded-xl `}
                                                onClick={() => setChartType('trip')}
                                            >
                                                Trips
                                            </div>
                                        )}
                                        {[LB_EXPENSE, LB_TRIP_EXPENSE].includes(summaryType) && (
                                            <div
                                                className={`field-switch ${
                                                    chartType === 'expense' && 'active'
                                                } flex justify-center items-center !h-[2.6rem] !rounded-xl `}
                                                onClick={() => setChartType('expense')}
                                            >
                                                Expense
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="flex gap-2 items-center">
                            <DropDownMenu
                                isLoading={sideLoader ? true : false}
                                isButton={true}
                                options={exportDataOptions}
                                atClick={(type) => handleDownload(type)}
                            />

                            <Button
                                title="Download Images"
                                classes="ann-btn h-size8 px-3  flex gap-2 items-center"
                                iconSet={{ leftIcon: DownloadFileIcon, loader: SmallLoader }}
                                atClick={() => downloadImages()}
                            />
                        </div>
                    </div>

                    <div className="mt-5">
                        <div>{generateFilters()}</div>
                    </div>

                    {chartType === 'expense' && (
                        <>
                            <div className="my-5 ">
                                {theSummary && theSummary?.length > 0 && (
                                    <InfiniteScrollComp
                                        height={470}
                                        dataLength={theSummary.length}
                                        next={handleMoreSummry}
                                        hasMore={hasMoreContent || false}
                                        loader={<SkeletonTable columns={6} />}
                                    >
                                        <div className="mt-10 ">
                                            <div className={`grid  grid-cols-6 gap-5`}>
                                                <div className={`default-table-heading `}>
                                                    <span> Description</span>
                                                </div>
                                                <div className={`default-table-heading `}>
                                                    <span> Expense</span>
                                                </div>
                                                <div className={`default-table-heading `}>
                                                    <span> GST</span>
                                                </div>
                                                <div className={`default-table-heading `}>
                                                    <span> Type</span>
                                                </div>

                                                <div className={`default-table-heading `}>
                                                    <span> Date</span>
                                                </div>
                                                <div className={`default-table-heading `}>
                                                    <span> Image </span>
                                                </div>
                                            </div>
                                            <hr className="mt-2" />
                                            {theSummary && theSummary.length > 0 ? (
                                                theSummary?.map((item, index) => (
                                                    <div key={'thesummary' + index} className="mt-2">
                                                        {' '}
                                                        <div className={`grid grid-cols-6 gap-5`}>
                                                            <div
                                                                className={` summary-body flex break-all items-center gap-5`}
                                                            >
                                                                <Truncate
                                                                    text={item?.description ?? 'N/A'}
                                                                    maxLength={50}
                                                                />
                                                            </div>
                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.amount && item?.amount !== 'N/A'
                                                                    ? `$${item?.amount}`
                                                                    : 'N/A'}
                                                            </div>

                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.gst_percentage ? item?.gst_percentage : 'No GST'}
                                                            </div>

                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.trip_type ?? 'N/A'}
                                                            </div>

                                                            <div className="summary-body flex items-center break-all">
                                                                {formateDate(item?.transaction_date)}
                                                            </div>

                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.invoice_image ? (
                                                                    <button className="summary-btn rounded-lg flex gap-1  p-1 px-2 items-center">
                                                                        <span>
                                                                            <PhotoIcon />
                                                                        </span>
                                                                        <span
                                                                            onClick={() =>
                                                                                handleZoom(item?.invoice_image)
                                                                            }
                                                                        >
                                                                            {item?.invoice_image
                                                                                ? item.invoice_image.slice(-8)
                                                                                : 'No Image'}
                                                                        </span>
                                                                    </button>
                                                                ) : (
                                                                    <span>No Image</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <hr className="mt-2 " />
                                                    </div>
                                                ))
                                            ) : (
                                                <>
                                                    {loading && theSummary.length === 0 ? (
                                                        <SkeletonTable columns={6} />
                                                    ) : (
                                                        <NoDataFound message="No Data Found" />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </InfiniteScrollComp>
                                )}
                            </div>
                            {theSummary.length > 0 ? (
                                <div
                                    className="bg-white py-3 absolute -bottom-[0px] w-full border-secondarybg border-t-[1px]"
                                    style={{ width: '-webkit-fill-available!important' }}
                                >
                                    <div>
                                        <div className=" flex justify-between items-center mx-5 primary-fonts text-xl">
                                            <div className="">Total Expense Reported</div>
                                            <div className="">
                                                {summaryInfo?.total_expense_report &&
                                                summaryInfo?.total_expense_report !== 'N/A'
                                                    ? '$' + summaryInfo?.total_expense_report
                                                    : '0'}
                                            </div>
                                        </div>
                                        <div className=" flex justify-between items-center mx-5 primary-fonts text-xl mt-5">
                                            <div className="">Total Claimable Expense</div>
                                            <div className="">
                                                {summaryInfo?.claimable_expense &&
                                                summaryInfo?.claimable_expense !== 'N/A'
                                                    ? '$' + summaryInfo?.claimable_expense
                                                    : '0'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {loading && theSummary.length === 0 ? (
                                        <SkeletonTable columns={6} />
                                    ) : (
                                        <NoDataFound message="No Data Found" />
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {(chartType === 'trip' || chartType === 'cpk_trip') && (
                        <>
                            <div className="my-5 ">
                                {theSummary && theSummary?.length > 0 && (
                                    <InfiniteScrollComp
                                        height={470}
                                        dataLength={theSummary.length}
                                        next={handleMoreSummry}
                                        hasMore={hasMoreContent || false}
                                        loader={<SkeletonTable columns={6} />}
                                    >
                                        <div className="mt-10 ">
                                            <div
                                                className={cn(
                                                    `grid gap-5`,
                                                    chartType === 'cpk_trip' ? 'grid-cols-6' : 'grid-cols-5'
                                                )}
                                            >
                                                <div className={`default-table-heading `}>
                                                    <span> Start Point</span>
                                                </div>
                                                <div className={`default-table-heading `}>
                                                    <span> End Point</span>
                                                </div>
                                                <div className={`default-table-heading `}>
                                                    <span> Trip Type</span>
                                                </div>
                                                <div className={`default-table-heading `}>
                                                    <span> Trip Details</span>
                                                </div>

                                                <div className={`default-table-heading `}>
                                                    <span> Total Km</span>
                                                </div>
                                                {chartType === 'cpk_trip' && (
                                                    <div className={`default-table-heading `}>
                                                        <span> Amount</span>
                                                    </div>
                                                )}
                                            </div>
                                            <hr className="mt-2" />
                                            {theSummary && theSummary.length > 0 ? (
                                                theSummary?.map((item, index) => (
                                                    <div key={'thesummary2' + index} className="mt-2">
                                                        {' '}
                                                        <div
                                                            className={cn(
                                                                `grid gap-5`,
                                                                chartType === 'cpk_trip' ? 'grid-cols-6' : 'grid-cols-5'
                                                            )}
                                                        >
                                                            <div
                                                                className={` summary-body flex break-all items-center gap-5`}
                                                            >
                                                                {item?.start_address ?? 'N/A'}
                                                            </div>
                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.end_address ?? 'N/A'}
                                                            </div>
                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.trip_type ?? 'N/A'}
                                                            </div>

                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.trip_nature ?? 'N/A'}
                                                            </div>

                                                            <div className="summary-body flex items-center break-all">
                                                                {item?.distance_covered !== 'N/A'
                                                                    ? item?.distance_covered + 'Km'
                                                                    : 'N/A'}
                                                            </div>
                                                            {chartType === 'cpk_trip' && (
                                                                <div className="summary-body flex items-center break-all">
                                                                    {item?.amount && item?.amount !== 'N/A'
                                                                        ? `$${item?.amount}`
                                                                        : 'N/A'}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <hr className="mt-2 " />
                                                    </div>
                                                ))
                                            ) : (
                                                <>
                                                    {loading && theSummary.length === 0 ? (
                                                        <SkeletonTable columns={6} />
                                                    ) : (
                                                        <NoDataFound message="No Data Found" />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </InfiniteScrollComp>
                                )}
                            </div>
                            {theSummary.length > 0 ? (
                                <div
                                    className="bg-white py-3 absolute -bottom-[0px] w-full border-secondarybg border-t-[1px]"
                                    style={{ width: '-webkit-fill-available!important' }}
                                >
                                    <div>
                                        <div className=" flex justify-between items-center mx-5 primary-fonts text-xl">
                                            <div className="">Total Kilometer Reported</div>
                                            <div className="">{summaryInfo?.distance_covered}</div>
                                        </div>
                                        {chartType === 'cpk_trip' ? (
                                            <>
                                                <div className=" flex justify-between items-center mx-5 primary-fonts mt-5">
                                                    <div className="">Total Claimable Expense</div>
                                                    <div className="">{summaryInfo?.claimable_expense || 'N/A'}</div>
                                                </div>
                                                <div className=" flex justify-between items-center mx-5 primary-fonts mt-5">
                                                    <div className="">Cents per kilometre</div>
                                                    <div className="">{summaryInfo?.current_rate || 'N/A'}</div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className=" flex justify-between items-center mx-5 primary-fonts mt-5">
                                                    <div className="">Work Use</div>
                                                    <div className="">{summaryInfo?.work_use || 'N/A'}</div>
                                                </div>
                                                <div className=" flex justify-between items-center mx-5 primary-fonts mt-5">
                                                    <div className="">Work Use Percentage</div>
                                                    <div className="">{summaryInfo?.work_percent || 'N/A'}</div>
                                                </div>
                                                <div className=" flex justify-between items-center mx-5 primary-fonts mt-5">
                                                    <div className="">Personal Use</div>
                                                    <div className="">{summaryInfo?.personal_use || 'N/A'}</div>
                                                </div>
                                                <div className=" flex justify-between items-center mx-5 primary-fonts mt-5">
                                                    <div className="">Personal Use Percentage</div>
                                                    <div className="">{summaryInfo?.personal_percent || 'N/A'}</div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {loading && theSummary.length === 0 ? (
                                        <SkeletonTable columns={6} />
                                    ) : (
                                        <NoDataFound message="No Data Found" />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </Slideover>
        </>
    );
};
