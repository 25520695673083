import React from 'react';
import { BgBrownTimes } from 'utills/svgs/BgBrownTimes';
import '../css/atoms.css';
export const ClearFilterButton = ({ atClick }) => {
    return (
        <div>
            <button
                onClick={atClick}
                className="clear-filter-button !bg-gray-200 hover:!bg-gray-300 flex gap-2 items-center rounded-xl !font-[400] p-2 h-[3rem]"
            >
                {' '}
                <span>
                    <BgBrownTimes />
                </span>
                <span>Clear Filter</span>
            </button>
        </div>
    );
};
