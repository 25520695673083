/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { acceptInviteThunk } from 'store/settings/team/team';
import { useLogout } from 'Hooks/useLogout';
import letter from '../../files/letter.png';

export const AcceptInvite = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    const [data, setData] = useState(null);
    const { logout } = useLogout();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const handleNavigate = () => {
        logout(setIsConfirmationOpen);
    };
    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(acceptInviteThunk({ invite_token: token }))
            .then((response) => {
                if (response.payload) {
                    setData(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);
    return (
        <>
            <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                    <div className="">
                        <div className="login mx-5 md:px-20">
                            <div className="login-body">
                                <div className="login-welcome mt-10 small-text font-poppins">
                                    <div className="flex flex-col items-center justify-center gap-5 py-10">
                                        <div className="flex gap-2 items-center justify-center">
                                            <div className="mx-auto">
                                                <img src={letter} alt="file" className=" bg-white cnf-icon-st" />
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <div className="flex gap-2 items-center justify-center">
                                                <div className=" cnf-title font-bold text-black ">
                                                    <h5>Thank You!</h5>
                                                </div>
                                            </div>
                                            <p className="text-xs  text-center desc text-black mt-0 break-words my-5 pt-5">
                                                Thank you for accepting the invitation.
                                            </p>
                                            <p className="  text-black text-center desc  mt-0 break-words my-5 pt-5">
                                                To proceed further, kindly click on the button provided.
                                            </p>
                                        </div>
                                        <div className="flex justify-center gap-2">
                                            <button
                                                disabled={!data ? true : false}
                                                onClick={handleNavigate}
                                                className=" px-14 py-2 ann-btn text-white rounded-md"
                                            >
                                                Proceed
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
