import React from 'react';

export const UndoIcon = () => {
    return (
        <div>
            {' '}
            <svg width="24" height="9" viewBox="0 0 30 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.77001 6.12428C9.01101 4.07499 12.2921 2.13053 15.7391 2.00631C17.6584 1.93714 19.6572 2.42875 21.6158 3.82088C23.587 5.22191 25.5635 7.56884 27.3549 11.2796C27.595 11.777 28.1928 11.9855 28.6902 11.7454C29.1876 11.5053 29.3961 10.9075 29.156 10.4101C27.2623 6.48744 25.0931 3.83866 22.7745 2.19071C20.4435 0.533856 18.0086 -0.0767743 15.6671 0.00760667C11.5523 0.155887 7.81662 2.43883 5.35476 4.70903L0.860925 0.215201C0.546062 -0.0996624 0.00768256 0.123121 0.00737173 0.568405L1.24487e-07 11.1289C-0.000192824 11.4053 0.223933 11.6294 0.500348 11.6292L11.0608 11.6218C11.5061 11.6215 11.7289 11.0832 11.414 10.7683L6.77001 6.12428Z"
                    fill="#454545"
                />
            </svg>
        </div>
    );
};
