import React from 'react';

export const CloseEye = () => {
    return (
        <svg width="24" height="20" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 9.30221C18.085 9.30221 19.1255 9.74486 19.8927 10.5328C20.6599 11.3207 21.0909 12.3894 21.0909 13.5037C21.0909 14.618 20.6599 15.6866 19.8927 16.4746C19.1255 17.2625 18.085 17.7052 17 17.7052C15.915 17.7052 14.8745 17.2625 14.1073 16.4746C13.3401 15.6866 12.9091 14.618 12.9091 13.5037C12.9091 12.3894 13.3401 11.3207 14.1073 10.5328C14.8745 9.74486 15.915 9.30221 17 9.30221ZM17 3C23.6273 3 29.5727 7.20147 32 13.5037C28.7409 22.0187 19.3864 26.1921 11.0955 22.845C6.93636 21.1644 3.63636 17.7892 2 13.5037C4.42727 7.20147 10.3727 3 17 3ZM4.97273 13.5037C8.21818 20.3241 16.25 23.1531 22.8909 19.8059C25.5614 18.4638 27.7205 16.2463 29.0273 13.5037C25.7818 6.68329 17.75 3.8543 11.1091 7.20147C8.43863 8.5436 6.27954 10.761 4.97273 13.5037Z"
                fill="#979797"
            />
            <path
                d="M30.3732 2.0011L16.3734 13.5018L2.37353 25.0024"
                stroke="#979797"
                strokeWidth="3"
                strokeLinecap="round"
            />
        </svg>
    );
};
