import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSideLoader } from 'store/global/globalReducer';
import { editDesignationThunk } from 'store/settings/team/workspaceSettings';

export const EditDesignation = ({ open, setOpen, handleGetDesignations, selected_designation }) => {
    const dispatch = useDispatch();
    const { sideLoader } = useSelector((state) => state.global);

    const [title, setTitle] = useState('');

    useEffect(() => {
        if (selected_designation) {
            setTitle(selected_designation?.title);
        }
    }, [selected_designation]);

    const handleSubmit = () => {
        const payload = {
            designation_id: selected_designation?.designation_id,
            title
        };

        dispatch(setSideLoader(true));
        dispatch(editDesignationThunk(payload))
            .then((response) => {
                if (response.payload) {
                    handleGetDesignations();
                    setOpen(false);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };
    return (
        <Slideover
            open={open}
            setOpen={setOpen}
            footer={
                <div className="flex justify-center items-center">
                    <Button
                        disabled={sideLoader}
                        isloading={sideLoader}
                        iconSet={{ loader: SmallLoaderWhite }}
                        title="Update"
                        classes="ann-btn rounded-xl buttons-font-lighter font-fira px-20"
                        atClick={() => handleSubmit()}
                    />
                </div>
            }
        >
            <RoleBasedGuard module={'Telephonic_directories'} permissions={['admin']} sx="h-[50vh]">
                {' '}
                <div className="xl-title font-fira">Edit Designation </div>
                <div className="default-forms mt-5">
                    <div>
                        <label>Designation</label>
                    </div>
                    <div className="mt-2">
                        <input
                            type="text"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                            placeholder="Enter designation"
                        />
                    </div>
                </div>
            </RoleBasedGuard>
        </Slideover>
    );
};
