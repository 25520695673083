import React from 'react';
import { motion } from 'framer-motion';
import '../../css-steps/login-css/resetSuccess.css';
import Ripples from 'react-ripples';
import { PasswordLock } from '../../utills/svgs/PasswordLock';
import AuthCardLayout from 'layouts/AuthCardLayout';
const ResetSuccess = (props) => {
    const goBackToLogin = () => {
        props.handleCondition(1);
    };
    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2  ">
            <AuthCardLayout>
                <div className="my-28">
                    {' '}
                    <div className="flex justify-center items-center ">
                        <motion.div
                            initial={{ opacity: 0, rotate: 100 }}
                            animate={{ opacity: 1, rotate: 0 }}
                            transition={{ delay: 0.3, type: 'spring', duration: 0.6 }}
                            className="svg-wrapper scale-75 "
                        >
                            <PasswordLock />
                        </motion.div>
                    </div>
                    <div>
                        <h1 className="text-center s-title">Congratulations</h1>
                    </div>
                    <div className="px-5">
                        <p className="text-center s-text">Your Account Password has been Reset.</p>
                    </div>
                    <div className="s-btn-wrapper my-3">
                        <Ripples duration={200} color="#979797">
                            <button onClick={goBackToLogin} className="s-btn px-16 py-2 rounded-md">
                                Ok
                            </button>
                        </Ripples>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};

export default ResetSuccess;
