import React from 'react';
import { useSelector } from 'react-redux';
import Slideover from 'components/atoms/SlideOver';
export const Owners = ({ open, setOpen, _toggleOwners }) => {
    const { owners } = useSelector((state) => state.global);
    return (
        <>
            <Slideover open={open} setOpen={setOpen} title={'Owners'}>
                <div className="mx-3 py-5">
                    {' '}
                    {owners &&
                        owners?.map((owner, index) => (
                            <div
                                key={index}
                                className={`shadow-md primary-fonts  bg-white rounded-xl flex justify-between items-center py-3 px-3 ${
                                    index > 0 && 'mt-2'
                                }`}
                            >
                                <div>
                                    {' '}
                                    {owner?.first_name} {owner?.last_name}
                                </div>
                                <div> {owner?.percentage_owned} %</div>
                            </div>
                        ))}
                </div>
            </Slideover>
        </>
    );
};
