import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css-steps/signup-css/stepOne.css';
import { validateEmail, validateTextField } from '../../utills/FormValidation';
import { Loader } from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { setLoader } from '../../store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import 'react-international-phone/style.css';
import { SOCKET_URL } from 'utills/globalVars';
import { PickAddress } from 'global-components/PickAddress';
import isValidABN from 'is-valid-abn';
import { io } from 'socket.io-client';
import { disconnectSocket, startHeartbeatInterval, stopHeartbeatInterval } from 'utills/socketEvents';
import { InputField } from 'components/atoms/inputs/InputField';
import { Button } from 'components/atoms/buttons/Button';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { signupUserThunk } from 'store/auth/slices';
import { validatePhoneNumber } from 'utills/baseValidations';

const StepOne = (props) => {
    const signupUser = localStorage.getItem('signupUser');
    const userP = signupUser ? JSON.parse(signupUser) : {};

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [emailError, setEmailError] = useState('');
    const [abnError, setAbnError] = useState(false);
    const [address, setAddress] = useState(userP?.address);

    const isLoading = useSelector((state) => state.global.isLoading);
    const [phoneNo, setPhoneNo] = useState(userP?.phone_number ?? '');
    const [nameError, setNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [socketId, _setSocketId] = useState('');
    const { intervalId } = useSelector((state) => state.global);
    const [breakConnection, setBreakConnection] = useState(false);
    const [businessEmail, setBusinessEmail] = useState(userP?.business_email ?? '');

    const [user, setUser] = useState({
        firstName: userP?.first_name ?? '',
        lastName: userP?.last_name ?? '',
        email: userP?.email ?? '',
        businessName: userP?.name ?? '',
        abnNumber: userP?.abn ?? ''
    });

    const [businessNameError, _setBusinessNameError] = useState(false);

    //Handle Changes to Get Values
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'firstName') {
            if (/\d/.test(value)) {
                setNameError(true);
                return;
            } else {
                if (!validateTextField(value)) {
                    setNameError(true);
                } else {
                    setNameError(false);
                }
                setUser({
                    ...user,
                    [name]: name === 'abnNumber' ? value.replace(/\s/g, '') : value
                });
            }
        }

        if (name === 'lastName') {
            if (/\d/.test(value)) {
                setLastNameError(true);
                return;
            }
            if (!validateTextField(value)) {
                setLastNameError(true);
            } else {
                setLastNameError(false);
            }
            setUser({
                ...user,
                [name]: name === 'abnNumber' ? value.replace(/\s/g, '') : value
            });
        }

        if (name === 'businessName') {
            setUser({
                ...user,
                [name]: name === 'abnNumber' ? value.replace(/\s/g, '') : value
            });
        }

        if (name === 'email') {
            if (value.trim().length === 0) {
                setEmailError('');
            } else if (!validateEmail(value)) {
                setEmailError('Invalid email address');
            } else {
                setEmailError('');
            }
            setUser({
                ...user,
                [name]: name === 'abnNumber' ? value.replace(/\s/g, '') : value
            });
        }

        if (name === 'abnNumber') {
            const abnNumber = value.replace(/\s/g, '');
            const updatedABN = abnNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1-$2-$3-$4');
            if (!isValidABN(updatedABN)) {
                setAbnError(true);
            } else {
                setAbnError(false);
            }
            setUser({
                ...user,
                [name]: name === 'abnNumber' ? value.replace(/\s/g, '') : value
            });
        }
    };

    const userData = {
        first_name: user.firstName,
        last_name: user.lastName,
        abn: user.abnNumber,
        email: user.email,
        name: user.businessName,
        address: address,
        user_type: 'employee',
        socket_id: socketId,
        phone_number: `${phoneNo}`,
        business_email: businessEmail
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setBreakConnection(true);

        if (nameError || lastNameError || businessNameError || emailError || abnError) {
            return;
        }

        if (!address) {
            toastHandler('Please enter a valid address. ', 'error');
            return;
        }
        if (abnError) {
            toastHandler('Invalid ABN number ', 'error');
            return;
        }
        if (phoneNo.length < 10) {
            toastHandler('invalid phone number ', 'error');
            return;
        }
        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        const isAnyValueEmpty = Object.values(user).some((value) => value === '');
        if (isAnyValueEmpty) {
            toastHandler('Please fill in signup credentials. ', 'error');
            return;
        }

        dispatch(setLoader(true));

        const newSocket = io(SOCKET_URL, {
            query: {
                email: user?.email,
                user_id: user?.email,
                phone_number: `${phoneNo}`,
                type: 'email',
                user_type: 'employee',
                category: 'pre_auth',
                event_ids: ''
            }
        });
        newSocket?.on('connect', () => {
            localStorage.setItem('socket_id', newSocket?.id);

            startHeartbeatInterval(dispatch, newSocket);
            const payload = {
                email: user?.email,
                user_type: 'employee',
                type: 'email',
                phone_number: `${phoneNo}`,
                socket_id: newSocket?.id
            };
            dispatch(signupUserThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        localStorage.setItem('signupUser', JSON.stringify(userData));
                        props.handleCondition(2, newSocket);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    disconnectSocket(newSocket);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        });

        newSocket.on('disconnect', () => {
            console.error('disconnected', '123');
        });
    };

    const onSelect = (place) => {
        setAddress(place.formatted_address);
    };

    useEffect(() => {
        if (intervalId) {
            stopHeartbeatInterval(dispatch, intervalId);
            setBreakConnection(false);
        }
    }, [breakConnection]);

    const handlePhoneNumberChange = (value) => {
        setPhoneNo(value);
    };

    const [businessEmailError, setBusinessEmailError] = useState('');

    const handleBusinessEmail = (value) => {
        if (value.trim().length === 0) {
            setBusinessEmailError('');
        } else if (!validateEmail(value)) {
            setBusinessEmailError('Invalid email address');
        } else {
            setBusinessEmailError('');
        }
        setBusinessEmail(value);
    };
    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2">
            {isLoading && <Loader />}
            <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                <div className="text-center  mt-3  card-layout-title-text ">Sign up</div>

                <form onSubmit={handleSubmit}>
                    <div className="default-forms">
                        <div className="grid md:grid-cols-2 gap-5 p-12">
                            <div>
                                <div className="body-subtitle  input-label-max font-poppins">Personal</div>
                                <div className="mt-5">
                                    <InputField
                                        label="First Name"
                                        inputValue={user.firstName}
                                        name="firstName"
                                        placeholder="Enter your first name"
                                        maxLength={30}
                                        allowSpaces={false}
                                        onChange={(e) => handleChange(e)}
                                        classes="mt-2 px-5"
                                        labelSx=" "
                                        required={true}
                                    />
                                </div>

                                <div className=" mt-5">
                                    <InputField
                                        classes=" mt-2"
                                        labelSx="  "
                                        label="Last Name"
                                        inputValue={user.lastName}
                                        name="lastName"
                                        placeholder="Enter your last name"
                                        maxLength={30}
                                        allowSpaces={false}
                                        onChange={(e) => handleChange(e)}
                                        required={true}
                                    />
                                </div>

                                <div className="mt-5">
                                    <label className="">Email</label>
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        className={`px-3 mt-2 ${emailError && user.email ? 'add-error-border' : ''}`}
                                        value={user.email}
                                        onChange={handleChange}
                                        required
                                    />
                                    <div className="error-div mt-1 ">{emailError && <span> {emailError} </span>}</div>
                                </div>

                                <div className="mt-5">
                                    <div className="w-full m-auto ">
                                        <div className="">
                                            <PhoneInputComponent
                                                title="Phone Number"
                                                placeholder="Enter Phone Number"
                                                titleSx=""
                                                sx="!rounded-xl"
                                                atChange={(phone) => handlePhoneNumberChange(phone)}
                                                value={phoneNo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="body-subtitle input-label-max font-poppins">Business</div>

                                <div className="mt-5">
                                    <label className="">Business Name</label>
                                    <input
                                        type="text"
                                        className={`px-3 mt-2  ${
                                            businessNameError && user.businessName ? 'add-error-border' : ''
                                        }`}
                                        maxLength="30"
                                        placeholder="Enter business name"
                                        name="businessName"
                                        value={user.businessName}
                                        onChange={handleChange}
                                        required
                                    />
                                    <div className="error-div mt-1">
                                        {businessNameError && user.businessName ? (
                                            <span> Invalid Business name </span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <PickAddress
                                        id="sign-up"
                                        address={address}
                                        setAddress={setAddress}
                                        onSelect={onSelect}
                                        label="Business Address"
                                        placeholder="Enter business address"
                                        required={true}
                                    />
                                </div>

                                <div className="mt-5">
                                    <label>ABN Number</label>
                                    <input
                                        type="number"
                                        className={`px-3 mt-2  ${abnError && user.abnNumber ? 'add-error-border' : ''}`}
                                        placeholder="Enter ABN number"
                                        name="abnNumber"
                                        value={user.abnNumber}
                                        onChange={handleChange}
                                        required
                                    />
                                    {abnError && (
                                        <div className="error-div mt-1">
                                            {abnError && user.abnNumber ? <span> Invalid ABN Number </span> : ''}
                                        </div>
                                    )}
                                </div>

                                <div className="mt-5">
                                    <label className=""> Business Email (optional) </label>
                                    <input
                                        type="text"
                                        className={`px-3 mt-2  ${
                                            businessNameError && user.businessName ? 'add-error-border' : ''
                                        }`}
                                        maxLength="30"
                                        placeholder="Enter your business email"
                                        name="businessEmail"
                                        value={businessEmail || ''}
                                        onChange={(e) => handleBusinessEmail(e.target.value)}
                                    />
                                    <div className="error-div mt-1">
                                        <div className="error-div mt-1">
                                            {businessEmailError && <span> {businessEmailError} </span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="signup-one-lower text-center mt-4 mb-8">
                            <Button
                                title="Next"
                                type="submit"
                                disabled={isLoading || businessEmailError ? true : false}
                                classes="ann-btn  font-fira px-12 rounded-lg buttons-font "
                            />

                            <div className="flex justify-center   items-center mt-4">
                                <div className="dont-have-account small-text flex gap-1 items-center">
                                    Already have an account ?
                                    <div
                                        type="button"
                                        onClick={() => {
                                            sessionStorage.setItem('loginStepper', 1);

                                            navigate('/');
                                        }}
                                        className="signup-redirect cursor-pointer"
                                    >
                                        Sign In
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default StepOne;
