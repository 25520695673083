import React from 'react';

const KanbanView = ({ className }) => {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.65 4V7C0.65 7.1933 0.8067 7.35 1 7.35H2.73438H4.46875C4.66205 7.35 4.81875 7.1933 4.81875 7V4V1C4.81875 0.8067 4.66205 0.65 4.46875 0.65H2.73438H1C0.8067 0.65 0.65 0.8067 0.65 1V4Z"
                strokeWidth="1.3"
            />
            <path
                d="M8.11875 4V7C8.11875 7.1933 8.27545 7.35 8.46875 7.35H10.2031H11.9375C12.1308 7.35 12.2875 7.1933 12.2875 7V4V1C12.2875 0.8067 12.1308 0.65 11.9375 0.65H10.2031H8.46875C8.27545 0.65 8.11875 0.8067 8.11875 1V4Z"
                strokeWidth="1.3"
            />
            <path
                d="M15.5875 4V7C15.5875 7.1933 15.7442 7.35 15.9375 7.35H17.6719H19.4062C19.5995 7.35 19.7562 7.1933 19.7562 7V4V1C19.7562 0.8067 19.5995 0.65 19.4062 0.65H17.6719H15.9375C15.7442 0.65 15.5875 0.8067 15.5875 1V4Z"
                strokeWidth="1.3"
            />
            <path
                d="M0.65 16V19C0.65 19.1933 0.8067 19.35 1 19.35H2.73438H4.46875C4.66205 19.35 4.81875 19.1933 4.81875 19V16V13C4.81875 12.8067 4.66205 12.65 4.46875 12.65H2.73438H1C0.8067 12.65 0.65 12.8067 0.65 13V16Z"
                strokeWidth="1.3"
            />
            <path
                d="M8.11875 16V19C8.11875 19.1933 8.27545 19.35 8.46875 19.35H10.2031H11.9375C12.1308 19.35 12.2875 19.1933 12.2875 19V16V13C12.2875 12.8067 12.1308 12.65 11.9375 12.65H10.2031H8.46875C8.27545 12.65 8.11875 12.8067 8.11875 13V16Z"
                strokeWidth="1.3"
            />
            <path
                d="M15.5875 16V19C15.5875 19.1933 15.7442 19.35 15.9375 19.35H17.6719H19.4062C19.5995 19.35 19.7562 19.1933 19.7562 19V16V13C19.7562 12.8067 19.5995 12.65 19.4062 12.65H17.6719H15.9375C15.7442 12.65 15.5875 12.8067 15.5875 13V16Z"
                strokeWidth="1.3"
            />
        </svg>
    );
};

export default KanbanView;
