import TaskTemplates from 'pages/workplace/tasks/TaskTemplates';
import { Workspace } from 'pages/workplace/Workspace';
import React from 'react';

const { default: Workplace } = require('pages/workplace/Workplace');
const { Protected } = require('./protected-routes/Protected');
const { default: Announcement } = require('pages/workplace/Announcement');
const { AnnouncementDetails } = require('pages/workplace/AnnouncementDetails');
const { default: Threads } = require('pages/workplace/Threads');
const { default: MyThreadDetails } = require('pages/workplace/MyThreadDetails');
const { default: ThreadList } = require('pages/workplace/ThreadList');
const { default: TelephonicDirectories } = require('pages/workplace/TelephonicDirectories');
const { default: Deductioon } = require('pages/workplace/deduction/Deduction');
const { default: QrCodes } = require('pages/workplace/qr-codes/QrCodes');
const { default: Notes } = require('pages/workplace/notes/Notes');

const Tasks = React.lazy(() => import('pages/workplace/tasks/Tasks'));

const { CompletedTasks } = require('pages/workplace/tasks/CompletedTasks');
const { EmployeeTasks } = require('pages/workplace/tasks/EmployeeTasks');
const { TasksReports } = require('pages/workplace/tasks/TasksReports');
const { default: OrganizationalSettings } = require('pages/workplace/tasks/OrganizationalSettings');
const { default: TaskType } = require('pages/workplace/tasks/TaskType');
const { default: PrebuiltChecklist } = require('pages/workplace/tasks/PrebuiltChecklist');

const workspaceRoutes = [
    {
        path: '/workspace/apps',
        component: <Protected Component={Workplace} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/announcement',
        component: <Protected Component={Announcement} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/announcement/details/:id',
        component: <Protected Component={AnnouncementDetails} />,
        isProtected: false,
        layout: 'header'
    },

    {
        path: '/my/threads',
        component: <Protected Component={Threads} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/my/threads/details/:id',
        component: <Protected Component={MyThreadDetails} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/threads',
        component: <Protected Component={ThreadList} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/telephonic/directories',
        component: <Protected Component={TelephonicDirectories} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/deduction',
        component: <Protected Component={Deductioon} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/qrcodes',
        component: <Protected Component={QrCodes} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/notes',
        component: <Protected Component={Notes} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/tasks',
        component: <Protected Component={Tasks} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/completed/tasks',
        component: <Protected Component={CompletedTasks} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/employee/tasks/:id',
        component: <Protected Component={EmployeeTasks} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/tasks/reports',
        component: <Protected Component={TasksReports} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/organizational/settings',
        component: <Protected Component={OrganizationalSettings} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/task/type',
        component: <Protected Component={TaskType} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/settings/task-module/prebuilt-checklist',
        component: <Protected Component={PrebuiltChecklist} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/settings/task-module/task-templates',
        component: <Protected Component={TaskTemplates} />,
        isProtected: false,
        layout: 'header'
    },
    {
        path: '/workspace',
        component: <Protected Component={Workspace} />,
        isProtected: false,
        layout: 'header'
    }
];

export default workspaceRoutes;
