/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { CircularProgress } from 'components/molecules/CircularProgress';
import './css/filemanager.css';
import { BucketIcon } from 'utills/svgs/BucketIcon';
import { StorageIcon } from 'utills/svgs/StorageIcon';
import { FoldersCard } from 'components/molecules/FoldersCard';

import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

import { TanFolderIcon } from 'utills/svgs/TanFolderIcon';
import { BrownFolderIcon } from 'utills/svgs/BrownFolderIcon';
import { useEffect, useState } from 'react';
import { generateId } from 'utills/uid';
import ConfirmationModal from 'utills/confirmationModal';

import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { formatFileSizekbs } from 'utills/filesHandlers';
import { DefaultRecentActivity } from 'components/molecules/DefaultRecentActivity';
import { BUSINESS_ID } from 'utills/globalVars';
import { UseDownloadLogsPdf } from 'Hooks/Files/UseDownloadLogsPdf';
import { endpoint } from 'apiEndpoints';

const generateRandomString = () => {
    return Math.random().toString(36).substring(7);
};

const generateRandomDate = () => {
    const randomDate = new Date(Math.floor(Math.random() * new Date()));
    return randomDate.toISOString().split('T')[0];
};

const generateRandomFileSize = () => {
    return Math.floor(Math.random() * (1024 - 100) + 100);
};

const generateRandomFileExtension = () => {
    return Math.random() < 0.5 ? 'jpg' : 'pdf';
};

const generateRandomOwnerType = () => {
    return Math.random() < 0.5;
};

const generateRandomFile = () => {
    return {
        fileType: generateRandomFileExtension(),
        fileName: generateId(),
        fileSize: generateRandomFileSize()
    };
};

const generateRandomFileArray = () => {
    const filesArray = [];
    for (let i = 0; i < 15; i++) {
        filesArray.push({
            name: generateRandomString(),
            date: generateRandomDate(),
            owner: generateRandomOwnerType(),
            file: generateRandomFile()
        });
    }
    return filesArray;
};

export const StorageCards = ({
    logs,
    count,
    page,
    pageSize,
    handleMore,
    toggleDialog,
    activityLoader,
    term,
    dateFrom,
    dateTo,
    setTerm,
    setDateFrom,
    setDateTo,
    fetchLogs,
    setFilterPage,
    filterPage,
    filterCount,
    setfilteredLogs,
    customer
}) => {
    const [files, setFiles] = useState(generateRandomFileArray());
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();
    const { data } = useSelector((state) => state.fileManagerInfo);
    const { isLoading } = useSelector((state) => state.global);
    const storedInDicators = JSON.parse(localStorage.getItem('indicators'));
    const { data: loginData } = useSelector((state) => state?.login);
    const business_id = localStorage.getItem(BUSINESS_ID);

    const { handleDownloadLogsPdf } = UseDownloadLogsPdf();

    const deleteFile = (file_id) => {
        setIsConfirmationOpen(true);
    };
    const handleDelete = () => {
        setIsConfirmationOpen(false);
    };

    const handleDownloadClick = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = 'your-file-name.pdf';
        link.click();
    };

    useEffect(() => {
        const driverObj = driver({
            showProgress: true,
            onDestroyed: () => {
                toggleDialog();
                const newIndicators = {
                    ...storedInDicators,
                    file_manager: true
                };

                localStorage.setItem('indicators', JSON.stringify(newIndicators));
            },
            steps: [
                {
                    element: '.percentage-use-indicate',

                    popover: {
                        title: 'Storage Used Percentage',
                        description: 'In this section you can view storage used by your client',
                        popoverClass: 'my-custom-popover-class '
                    }
                },
                {
                    element: '.accountant-folder-indicate',

                    popover: {
                        title: 'Accountants Folder',
                        description:
                            'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.',
                        popoverClass: 'my-custom-popover-class '
                    }
                },
                {
                    element: '.authorized-folder-indicate',

                    popover: {
                        title: 'Authorised Folder',
                        description:
                            'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.',
                        popoverClass: 'my-custom-popover-class '
                    }
                },
                {
                    element: '.recent-activity-indicate',

                    popover: {
                        title: 'Recent Activity',
                        description:
                            'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.',
                        popoverClass: 'my-custom-popover-class '
                    }
                }
            ]
        });

        //remember to change this to  !loginData?.second_time_login && !storedInDicators?.file_manager
        //!loginData?.second_time_login && !storedInDicators?.file_manager
        if (!loginData?.second_time_login && !storedInDicators?.file_manager) {
            driverObj.drive();
        } else {
            driverObj.destroy();
        }

        return () => {
            driverObj.destroy();
        };
    }, []);

    const handleDownloadActivity = () => {
        const payload = {
            business_client_id: id,
            business_id: business_id
        };
        handleDownloadLogsPdf(payload, endpoint.downloadFileManagerLogsPdf);
    };

    return (
        <div className="grid md:grid-cols-5 gap-5 md:mx-10 mx-5 mt-10">
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />

            <div className="manager-card-container p-4 border shadow-md !rounded-xl md:col-span-2">
                <div className="shadow-md  hover:border-thirdbg rounded-xl border ">
                    <div className="flex justify-center items-center gap-3 flex-col p-5">
                        <span className="percentage-use-indicate">
                            <CircularProgress
                                value={isLoading ? 0 : Math.ceil(data?.used_space_percentage)}
                                text="Used"
                            />
                        </span>
                    </div>
                    <div className="flex justify-between items-center p-3 ">
                        <div>
                            <div className="flex gap-2 items-center ">
                                <div>
                                    {' '}
                                    <BucketIcon />{' '}
                                </div>
                                <div>
                                    <div className="percentage-text ">Total Storage</div>
                                    <div className="storage-length text-sm">
                                        {data?.allocated_space && formatFileSizekbs(data?.allocated_space)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {' '}
                            <div className="flex gap-2 items-center ">
                                <div>
                                    {' '}
                                    <StorageIcon />{' '}
                                </div>
                                <div>
                                    <div className="percentage-text">Used Storage</div>
                                    <div className="storage-length text-sm">
                                        {data?.used_space && formatFileSizekbs(data?.used_space)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <div
                        onClick={() => navigate(`/file/manager/accountants/folder/${id}`)}
                        className="mt-3 shadow-md rounded-xl  hover:border-thirdbg  border p-3 py-5 cursor-pointer accountant-folder-indicate"
                    >
                        <FoldersCard
                            title="Accountant's Folder"
                            count={data?.count?.accountant ?? 0}
                            iconSet={{ icon: TanFolderIcon }}
                        />
                    </div>
                    <div
                        onClick={() => navigate(`/file/manager/authorized/folder/${id}`)}
                        className="mt-3 shadow rounded-xl hover:border-thirdbg border p-3 py-5 cursor-pointer authorized-folder-indicate"
                    >
                        <FoldersCard
                            title="Authorised Folder"
                            count={data?.count?.authorized ?? 0}
                            iconSet={{ icon: BrownFolderIcon }}
                        />
                    </div>

                    {customer?.client_type?.includes('entity') && (
                        <div
                            onClick={() => navigate(`/file/manager/legal-documents/${id}`)}
                            className="mt-3 shadow rounded-xl hover:border-thirdbg border p-3 py-5 cursor-pointer authorized-folder-indicate"
                        >
                            <FoldersCard
                                title="Legal Documents"
                                count={data?.count?.legal ?? 0}
                                iconSet={{ icon: BrownFolderIcon }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className=" md:col-span-3 hover:border-thirdbg recent-activity-indicate bg-white shadow-md  rounded-xl border">
                <div>
                    <DefaultRecentActivity
                        fetchLogs={fetchLogs}
                        logs={logs}
                        count={count}
                        pageSize={pageSize}
                        page={page}
                        handleMore={handleMore}
                        term={term}
                        setTerm={setTerm}
                        dateFrom={dateFrom}
                        setDateFrom={setDateFrom}
                        dateTo={dateTo}
                        setDateTo={setDateTo}
                        title="Recent Activity"
                        height={550}
                        downloadActivity={handleDownloadActivity}
                        setFilterPage={setFilterPage}
                        filterPage={filterPage}
                        filterCount={filterCount}
                        setfilteredLogs={setfilteredLogs}
                    />
                </div>
            </div>
        </div>
    );
};
