/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { isQuestionIdRepeated } from 'utills/dataValidation';
import cloneDeep from 'lodash/cloneDeep';
import { Loader } from 'components/common/Loader';
import { SecurityInfo } from 'components/signup-steps/signup-utills/securityQuestions/SecurityInfo';
import securityQuestionsText from 'components/signup-steps/signup-utills/SignupText';
import { setLoader } from 'store/global/globalReducer';
import { addSecurityQuestionsThunk, getSecurityQuestionsThunk } from 'store/auth/slices';
import { TOAST_TYPE_ERROR, USER_TYPE } from 'utills/globalVars';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
export const ResetQuestions = (props) => {
    const { user_id } = useParams();

    const forgotPassData = localStorage.getItem('forgotPassData');
    const parsedData = forgotPassData ? JSON.parse(forgotPassData) : {};
    const securityQuestionsArr = localStorage.getItem('questions');
    const questions = securityQuestionsArr ? JSON.parse(securityQuestionsArr) : {};
    const forgotPasswordData = JSON.parse(sessionStorage.getItem('forgotPasswordData'));

    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);
    const [securityQuestions, setSecurityQuestions] = useState(null);

    const [selectedOption, setSelectedOption] = useState({
        key: questions?.questions ? questions?.questions[0]?.question_id : '',
        value: ''
    });

    const [secondOption, setSecondOption] = useState({
        key: questions?.questions ? questions?.questions[1]?.question_id : '',
        value: ''
    });

    const [thirdOption, setThirdOption] = useState({
        key: questions?.questions ? questions?.questions[2]?.question_id : '',
        value: ''
    });
    const [firstAnswer, setFirstAnswer] = useState(questions?.questions ? questions?.questions[0]?.value : '');
    const [secondAnswer, setSecondAnswer] = useState(questions?.questions ? questions?.questions[1]?.value : '');
    const [thirdAnswer, setThirdAnswer] = useState(questions?.questions ? questions?.questions[2]?.value : '');

    const [questionsArr, setQuestionsArr] = useState([]);

    const handleSelect = (obj) => {
        const newSelected = { ...selectedOption };
        newSelected.key = obj?.question_id;
        newSelected.value = obj?.question;
        setSelectedOption(newSelected);
    };

    const handleSecondSelect = (obj) => {
        const newSelected = { ...secondOption };
        newSelected.key = obj?.question_id;
        newSelected.value = obj?.question;
        setSecondOption(newSelected);
    };

    const handleThirdSelect = (obj) => {
        const newSelected = { ...thirdOption };
        newSelected.key = obj?.question_id;
        newSelected.value = obj?.question;
        setThirdOption(newSelected);
    };

    const showSteptwo = () => {
        props.handleCondition(4);
    };

    const handletwoFa = () => {
        if (forgotPasswordData.google_authenticator_2fa && !forgotPasswordData.email_2fa) {
            props.handleCondition(11);
        } else if (forgotPasswordData.google_authenticator_2fa && forgotPasswordData.email_2fa) {
            props.handleCondition(11);
        } else if (!forgotPasswordData.google_authenticator_2fa && forgotPasswordData.email_2fa) {
            props.handleCondition(17);
        } else if (!forgotPasswordData.google_authenticator_2fa && !forgotPasswordData.email_2fa) {
            props.handleCondition(5);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const questionsData = {
            user_id: user_id,
            user_type: 'employee',
            questions: [
                {
                    question_id: selectedOption.key,
                    value: firstAnswer
                },
                {
                    question_id: secondOption.key,
                    value: secondAnswer
                },
                {
                    question_id: thirdOption.key,
                    value: thirdAnswer
                }
            ]
        };

        const values = questionsData.questions.map((question) => question.value);

        const hasDuplicates = new Set(values).size !== values.length;

        if (hasDuplicates) {
            return toastHandler('Answers cannot be repeated', TOAST_TYPE_ERROR);
        }
        if (questionsData.questions.some((question) => question.question_id === '')) {
            toastHandler('Please select atleast three questions to proceed', 'error');
            return;
        }

        if (isQuestionIdRepeated(questionsData.questions)) {
            toastHandler("You can't select same question twice", 'error');
            return;
        }

        const payload = {
            email: parsedData.email,
            user_type: USER_TYPE,
            questions: questionsData.questions
        };
        dispatch(addSecurityQuestionsThunk(payload)).then((response) => {
            if (response.payload) {
                handletwoFa();
            }
        });
    };

    const user = useSelector((state) => state.otpVerification.data);

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(getSecurityQuestionsThunk())
            .then((response) => {
                setSecurityQuestions(response.payload);
                dispatch(setLoader(false));
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });

        dispatch(setLoader(false));
    }, []);

    const handleAlready = (question) => {
        const newQuestions = cloneDeep(questionsArr);

        const exist = newQuestions?.find((q) => q.key === question);
        if (exist) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <div className="pb-5 ">
            <div>
                {' '}
                {isLoading && (
                    <div className="loader-overlay">
                        <Loader />
                    </div>
                )}
                <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8 ">
                    <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] lg:max-w-[670px] xl:max-w-[700px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                        <div className="text-center  mt-3   card-layout-title-text ">Security Questions</div>

                        <div className="security-body p-5 px-16 ">
                            <SecurityInfo text={securityQuestionsText.textOne} />
                            <SecurityInfo text={securityQuestionsText.textTwo} />
                            <SecurityInfo text={securityQuestionsText.textThree} />
                        </div>

                        <form onSubmit={handleSubmit}>
                            {' '}
                            <div className="security-qa default-forms font-poppins px-28 mt-5 ">
                                <div className=" ">
                                    <h1 className="table-title">Question 1</h1>

                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="question"
                                        optionsData={
                                            securityQuestions?.filter(
                                                (item) =>
                                                    item?.question_id !== selectedOption?.key &&
                                                    item?.question_id !== secondOption?.key &&
                                                    item?.question_id !== thirdOption?.key
                                            ) || []
                                        }
                                        sx="rounded-xl py-3 buttons-font ring-gray-400 mt-2"
                                        onChangeValue={(obj) => handleSelect(obj)}
                                    />

                                    <div className="q1-input">
                                        <input
                                            type="text"
                                            className="px-3"
                                            name="securityAnswer1"
                                            placeholder="Answer"
                                            maxLength={30}
                                            required
                                            onChange={(e) => setFirstAnswer(e.target.value)}
                                            value={firstAnswer}
                                        />
                                    </div>
                                </div>

                                <div className="q1 mt-4">
                                    <h1 className="table-title">Question 2</h1>
                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="question"
                                        optionsData={
                                            securityQuestions?.filter(
                                                (item) =>
                                                    item?.question_id !== selectedOption?.key &&
                                                    item?.question_id !== secondOption?.key &&
                                                    item?.question_id !== thirdOption?.key
                                            ) || []
                                        }
                                        sx="rounded-xl py-3 mt-2 ring-gray-400 buttons-font"
                                        onChangeValue={(obj) => handleSecondSelect(obj)}
                                    />

                                    <div className="q1-input">
                                        <input
                                            type="text"
                                            className="px-3"
                                            placeholder="Answer"
                                            required
                                            name="securityAnswer2"
                                            maxLength={30}
                                            onChange={(e) => setSecondAnswer(e.target.value)}
                                            value={secondAnswer}
                                        />
                                    </div>
                                </div>

                                <div className="q1 mt-4">
                                    <h1 className="table-title">Question 3</h1>

                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="question"
                                        optionsData={
                                            securityQuestions?.filter(
                                                (item) =>
                                                    item?.question_id !== selectedOption?.key &&
                                                    item?.question_id !== secondOption?.key &&
                                                    item?.question_id !== thirdOption?.key
                                            ) || []
                                        }
                                        sx="rounded-xl py-3 ring-gray-400 mt-2 buttons-font"
                                        onChangeValue={(obj) => handleThirdSelect(obj)}
                                    />

                                    <div className="q1-input">
                                        <input
                                            type="text"
                                            className="px-3"
                                            name="securityAnswer3"
                                            placeholder="Answer"
                                            maxLength={30}
                                            required
                                            onChange={(e) => setThirdAnswer(e.target.value)}
                                            value={thirdAnswer}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sq-btns my-5  mt-10 mx-28 ">
                                <div className="flex justify-center items-center">
                                    <div>
                                        <button type="submit" className="btn-2 px-14 py-2 buttons-font font-fira">
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>{' '}
            </div>
        </div>
    );
};
