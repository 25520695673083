import React from 'react';

const DraftIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_23867_5010)">
                <path
                    d="M1.42822 19.1532C1.89138 19.1533 9.46063 19.1547 8.97895 19.1547C9.06011 19.1547 9.14009 19.123 9.19989 19.0632C9.5493 18.7139 7.93638 20.3262 12.6057 15.6586C12.6543 15.61 12.6972 15.5159 12.6972 15.4377V12.8971C12.6972 12.7243 12.5575 12.5846 12.3847 12.5846C12.212 12.5846 12.0723 12.7243 12.0723 12.8971V15.1236H10.0953C9.30737 15.1236 8.6665 15.7645 8.6665 16.5525V18.5282H1.42822C0.985416 18.5282 0.624989 18.1681 0.624989 17.7253V2.42822C0.624989 1.98542 0.985416 1.62499 1.42822 1.62499H11.269C11.7118 1.62499 12.0722 1.98542 12.0722 2.42822V7.40933C12.0722 7.58205 12.212 7.72182 12.3847 7.72182C12.5574 7.72182 12.6972 7.58205 12.6972 7.40933V2.42822C12.6972 1.64055 12.0566 1 11.269 1H1.42822C0.640547 1 0 1.64059 0 2.42822V17.7253C0 18.5127 0.640547 19.1532 1.42822 19.1532ZM10.0953 15.7487H11.6306C8.42501 18.952 9.53062 17.8472 9.29144 18.0862V16.5525C9.29144 16.1094 9.65216 15.7487 10.0953 15.7487Z"
                    fill="#B695F8"
                    stroke="#B695F8"
                    strokeWidth="0.4"
                />
                <path
                    d="M10.0014 3.46015H2.69726C2.52454 3.46015 2.38477 3.59993 2.38477 3.77265C2.38477 3.94536 2.52454 4.08514 2.69726 4.08514H10.0014C10.1741 4.08514 10.3139 3.94536 10.3139 3.77265C10.3139 3.59993 10.1741 3.46015 10.0014 3.46015ZM10.0014 5.61804H2.69726C2.52454 5.61804 2.38477 5.75782 2.38477 5.93054C2.38477 6.10326 2.52454 6.24303 2.69726 6.24303H10.0014C10.1741 6.24303 10.3139 6.10326 10.3139 5.93054C10.3139 5.75782 10.1741 5.61804 10.0014 5.61804ZM10.0014 7.77626H2.69726C2.52454 7.77626 2.38477 7.91603 2.38477 8.08875C2.38477 8.26147 2.52454 8.40125 2.69726 8.40125H10.0014C10.1741 8.40125 10.3139 8.26147 10.3139 8.08875C10.3139 7.91603 10.1741 7.77626 10.0014 7.77626ZM10.3139 10.2467C10.3139 10.074 10.1741 9.93419 10.0014 9.93419H2.69726C2.52454 9.93419 2.38477 10.074 2.38477 10.2467C2.38477 10.4194 2.52454 10.5592 2.69726 10.5592H10.0014C10.1741 10.5592 10.3139 10.4194 10.3139 10.2467ZM2.69726 12.0921C2.52454 12.0921 2.38477 12.2319 2.38477 12.4046C2.38477 12.5773 2.52454 12.7171 2.69726 12.7171H7.65793C7.83065 12.7171 7.97043 12.5773 7.97043 12.4046C7.97043 12.2319 7.83065 12.0921 7.65793 12.0921H2.69726ZM19.5895 5.49935C20.1373 4.95154 20.1373 4.06044 19.5895 3.51263C19.042 2.96454 18.1502 2.96454 17.6028 3.51263C17.5706 3.54476 11.4561 9.66 11.487 9.62872C11.4462 9.67003 11.4132 9.7423 11.4055 9.77366L10.9275 11.7323L10.6477 12.0121C10.5257 12.1342 10.5257 12.332 10.6477 12.454C10.7698 12.5761 10.9675 12.5761 11.0896 12.454L11.3694 12.1743L13.3281 11.6963C13.3594 11.6886 13.4304 11.6569 13.4731 11.6148C13.5389 11.5498 19.5375 5.55134 19.5895 5.49935ZM18.0447 3.9545C18.3486 3.65054 18.8436 3.65054 19.1476 3.9545C19.4516 4.25847 19.4516 4.75347 19.1476 5.05744L18.7652 5.43984L17.6623 4.33691L18.0447 3.9545ZM11.6359 11.4659L11.881 10.4615L12.6403 11.2208L11.6359 11.4659ZM13.254 10.9507L12.1511 9.84779L17.2204 4.77878L18.3233 5.88171L13.254 10.9507Z"
                    fill="#B695F8"
                    stroke="#B695F8"
                    strokeWidth="0.4"
                />
            </g>
            <defs>
                <clipPath id="clip0_23867_5010">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DraftIcon;
