import React from 'react';
import '../css/molecules.css';
export const BoxSkeleton = () => {
    return (
        <div className="app-skeleton ">
            <div>
                {' '}
                <div className="app-skeleton-details"></div>{' '}
            </div>
        </div>
    );
};
