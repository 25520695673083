import generateThunkAndSlice from '../thunk/thunk';
import { endpoint, methods } from 'apiEndpoints';

const { slice: getAllNotifications, request: getAllNotificationsThunk } = generateThunkAndSlice(
    'getAllNotifications',
    endpoint.getAllNotifications,
    methods.POST
);
const { slice: getNotificationCount, request: getNotificationCountThunk } = generateThunkAndSlice(
    'getNotificationCount',
    endpoint.getNotificationCount,
    methods.POST
);
const { request: markNotificationAsReadThunk } = generateThunkAndSlice(
    'markNotificationAsRead',
    endpoint.markNotificationAsRead,
    methods.POST
);

//delAppImage
export {
    getAllNotifications,
    getAllNotificationsThunk,
    markNotificationAsReadThunk,
    getNotificationCount,
    getNotificationCountThunk
};
