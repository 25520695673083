import React from 'react';
import { Loader } from 'components/common/Loader';
import { useSelector } from 'react-redux';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import Pagination from 'components/pagination';
import { BUSINESS_ID, PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { useDispatch } from 'react-redux';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { archiveTelephonicDirectoryThunk } from 'store/workspace/workspaceTelephonicDirectories';
import { toastHandler } from 'responseHanlder';
import { useState } from 'react';
import ConfirmationModal from 'utills/confirmationModal';
import useRoleGuard from 'Hooks/useRoleGuard';
import { handleEditDirectories } from 'store/global/globalReducer';
import { NoDataFound } from 'components/common/NoDataFound';

const TDlist = ({
    filteredItems,
    page,
    pageSize,
    count,
    handleChangePage,
    getAllTelephonicDirectories,
    toggleEditDirectory
}) => {
    const { hasPermissions } = useRoleGuard();

    const { isLoading } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    let items = filteredItems?.rows;
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [idTodelete, setidTodelete] = useState('');

    const archiveTD = (telephone_dir_id, index, createdBy) => {
        if (hasPermissions('Telephonic_directories', ['write', 'admin'], createdBy)) {
            setidTodelete(telephone_dir_id);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };
    const handleArchiveTelephonicDirectory = () => {
        const payload = {
            business_id,
            telephone_dir_id: idTodelete
        };
        dispatch(archiveTelephonicDirectoryThunk(payload))
            .then((_response) => {
                setIsConfirmationOpen(false);
                setidTodelete('');
                const payload = {
                    business_id,
                    page: 1,
                    pageSize: PAGINATION_PAGE_SIZE
                };
                getAllTelephonicDirectories(payload);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const handleEditDirectory = (directory, createdBy) => {
        if (hasPermissions('Telephonic_directories', ['admin'], createdBy)) {
            dispatch(handleEditDirectories(directory));
            toggleEditDirectory();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, TOAST_TYPE_ERROR);
        }
    };

    return (
        <div>
            {isLoading && <Loader />}

            <div className="md:mx-10 mx-5 mt-8   text-black ">
                {!isLoading && items?.length > 0 ? (
                    <div className="px-4 sm:px-6 rounded-lg bg-white lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                                >
                                                    Name
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Branch Assign
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Extension
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Contact No
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Designation
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {items &&
                                                items?.map((person, index) => (
                                                    <tr key={person?.email}>
                                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-md sm:pl-0">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div className="font-medium text-gray-900">
                                                                        {person?.first_name} {person?.last_name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="text-gray-900">
                                                                {' '}
                                                                {person?.email || '--'}
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="text-gray-900">
                                                                {' '}
                                                                {person?.branch_name || '--'}
                                                            </div>
                                                        </td>

                                                        <td className="whitespace-nowrap px-3 py-5 break-all w-[100px] text-md text-gray-500">
                                                            <div className="text-gray-900">
                                                                {' '}
                                                                {person?.desk_extension || '--'}
                                                            </div>
                                                        </td>

                                                        <td className="whitespace-nowrap px-3 py-5 text-md  text-gray-500">
                                                            <div className="text-gray-900">
                                                                {' '}
                                                                {person?.phone_number || '--'}
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="text-gray-900">
                                                                {' '}
                                                                {person?.designation || '--'}
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="flex-1 flex gap-2 items-center break-all">
                                                                <span
                                                                    onClick={() =>
                                                                        handleEditDirectory(person, person?.created_by)
                                                                    }
                                                                    className="cursor-pointer"
                                                                >
                                                                    <BgEditIcon />
                                                                </span>
                                                                <div
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        archiveTD(
                                                                            person.telephone_dir_id,
                                                                            index,
                                                                            person?.created_by
                                                                        )
                                                                    }
                                                                >
                                                                    <BgDeleteIcon />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : !isLoading ? (
                    <NoDataFound message="No Directory Found !" height="30vh" />
                ) : (
                    ''
                )}
            </div>

            <div className="md:mx-10 mx-5">
                <Pagination
                    page={Number(page)}
                    pageSize={Number(pageSize)}
                    totalPages={Math.ceil(count / pageSize)}
                    onChangePage={(page) => handleChangePage(page, Number(pageSize))}
                    onCountPerPageChange={(perPageCount) => handleChangePage(Number(page), perPageCount)}
                />
            </div>

            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleArchiveTelephonicDirectory}
            />
        </div>
    );
};

export default TDlist;
