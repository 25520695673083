/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/atoms/buttons/Button';
import TaskTemplateTable from './TaskTemplateTable';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { setLoader } from 'store/global/globalReducer';
import ConfirmationModal from 'utills/confirmationModal';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { deleteTemplateTaskThunk, getTemplateTaskByIdThunk, getTemplateTaskThunk } from 'store/settings/JobPortal';
import CreateTaskTemplate from './CreateTaskTemplate';
import TaskTemplateDetails from './TaskTemplateDetails';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { NoDataFound } from 'components/common/NoDataFound';

const TaskTemplatePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { hasPermissions } = useRoleGuard();
    const { isLoading } = useSelector((state) => state.global);
    const [add, setAdd] = useState(false);
    const [templateDetails, setTemplateDetails] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState('');

    const [selectedIds, setSelectedIds] = useState([]);

    const { data: getTemplateTask } = useSelector((state) => state?.getTemplateTask);
    const [dataList, setDataList] = useState(null);
    const [editObj, setEditObj] = useState(null);

    useEffect(() => {
        if (editObj) {
            setAdd(true);
        } else {
            setAdd(false);
        }
    }, [editObj]);

    useEffect(() => {
        if (!getTemplateTask) {
            dispatch(getTemplateTaskThunk({ business_id }));
        } else {
            setDataList(getTemplateTask);
        }
    }, [getTemplateTask]);

    const handleDeleteClients = (ids) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        dispatch(setLoader(true));
        setIsConfirmationOpen(false);
        dispatch(deleteTemplateTaskThunk({ business_id: business_id, ids: ids.join(',') }))
            .then((response) => {
                if (response.payload) {
                    setSelectedClient('');
                    setSelectedIds([]);
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
                dispatch(getTemplateTaskThunk({ business_id }));
            });
    };

    const handleDeletionId = (id, _isAll) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }

        if (id) {
            setSelectedClient(id);
            setSelectedIds([]);
        } else {
            setSelectedClient('');
        }
        setIsConfirmationOpen(true);
    };

    const handleSelectId = (id) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const newIds = [...selectedIds];
        const findId = newIds?.find((item) => item === id);

        if (findId) {
            const indexOfId = newIds.indexOf(findId);
            newIds.splice(indexOfId, 1);
            setSelectedIds(newIds);
        } else {
            newIds.push(id);
            setSelectedIds(newIds);
        }
    };

    const handleSelectedAll = (e) => {
        if (!hasPermissions('Tasks', ['admin'])) {
            return toastHandler('You do not have permission to perform this action.', TOAST_TYPE_ERROR);
        }
        const newIds = [];
        const allClients = getTemplateTask;
        if (e.target.checked) {
            allClients?.forEach((client) => {
                newIds.push(client?.task_template_id);
            });
            setSelectedIds(newIds);
        } else {
            setSelectedIds([]);
        }
    };

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/settings/task-module`);
        }
    };

    const handleTemplateDetails = async (id) => {
        if (id) {
            await dispatch(getTemplateTaskByIdThunk({ urlParams: id })).finally(() => {
                setTemplateDetails(true);
            });
        }
    };

    return (
        <div>
            {add && (
                <CreateTaskTemplate
                    open={add}
                    setOpen={(e) => {
                        setAdd(e);
                        setEditObj(null);
                    }}
                    editObj={editObj}
                    handleTasksUpdate={() => {
                        dispatch(getTemplateTaskThunk({ business_id }));
                    }}
                />
            )}
            {templateDetails && (
                <TaskTemplateDetails
                    open={templateDetails}
                    setOpen={(e) => {
                        setTemplateDetails(e);
                        dispatch(getTemplateTaskThunk({ business_id }));
                    }}
                    handleTasksUpdate={() => {
                        dispatch(getTemplateTaskThunk({ business_id }));
                    }}
                />
            )}
            <Jumbotron
                title="Task Templates"
                path={[
                    {
                        link: 'settings',
                        title: 'Settings'
                    },
                    {
                        link: 'settings/task-module',
                        title: 'Task Module'
                    },
                    {
                        link: '',
                        title: 'Task Templates'
                    }
                ]}
                backArrowProp={backArrowProp}
            />
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={() => handleDeleteClients(selectedIds?.length > 0 ? selectedIds : [selectedClient])}
            />
            <div>
                <div className="md:px-10 px-5 mt-2 ">
                    <div className="flex items-center justify-end gap-2">
                        {selectedIds?.length > 0 && (
                            <span className="cursor-pointer " onClick={() => handleDeletionId('', true)}>
                                <BgDeleteIcon className={' hover:opacity-75 hover:shadow-md rounded-full'} />
                            </span>
                        )}
                        <div>
                            <Button
                                atClick={() => {
                                    setAdd(true);
                                    setEditObj(null);
                                }}
                                title={'Create Template'}
                                classes={'ann-btn rounded-xl px-8 buttons-font-lighter font-fira '}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {dataList?.length < 1 && !isLoading ? (
                <NoDataFound
                    message="No Task Template Found!. You can click on 'Create Template' button to add one "
                    height="500px"
                />
            ) : (
                <div className="mx-5 mb-5 md:mx-10">
                    <div className="mb-10 grid">
                        <TaskTemplateTable
                            dataList={dataList}
                            handleDeletionId={handleDeletionId}
                            handleSelectedAll={handleSelectedAll}
                            handleSelectId={handleSelectId}
                            selectedIds={selectedIds}
                            setEditObj={setEditObj}
                            handleTemplateDetails={handleTemplateDetails}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
export default TaskTemplatePage;
