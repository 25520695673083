import { AddHint } from 'global-components/AddHint';
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { BSB, BUSINESS_INDUSTRY_OCCUPATION, DOB, SALARY_WAGES_OCCUPATION } from 'utills/globalVars';
import { BgAddIcon } from 'utills/svgs/BgAddIcon';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { DragIcon } from 'utills/svgs/DragIcon';
import { HintIcon } from 'utills/svgs/HintIcon';
import { InfoIcon } from 'utills/svgs/InfoIcon';
import { SideTimes } from 'utills/svgs/SideTimes';
import { RenderConditionalInputs } from './RenderConditionalInputs';
import { generateId } from 'utills/uid';
import { Hint } from './Hint';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
// import DraftEditor from 'components/molecules/DraftEditor';
import {
    ABN,
    ADDRESS,
    BUSINESS_EMAIL,
    CHECKBOX_FIELD,
    CONDITIONAL,
    DATE,
    DOCUMENT,
    DROPDOWN,
    EDITOR_FIELDS,
    EMAIL,
    FIRST_NAME,
    IMAGE_FIELD,
    LABEL_FIELDS,
    LAST_NAME,
    LIST,
    MIDDLE_NAME,
    NUMBER_FIELD,
    OPTION_FIELDS,
    PHONE_NUMBER_FIELD,
    PLACESHOLDER_FIELDS,
    PRIVACY_POLICY,
    RADIO_FIELD,
    RECURRING,
    SIGNATURE,
    SWITCH,
    TERMS_AND_CONDITIONS,
    TEXT,
    TFN
} from 'utills/constants/forms/forms.constants';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

const ItemTypes = {
    CARD: 'card'
};

const Card = ({
    id,
    _text,
    index,
    moveCard,
    field,
    fieldIndex,
    showModal,
    modalIndex,
    toggleModal,
    handleFieldTypeChange,

    stepIndex,
    handleLabel,
    handleRecuringLabel,
    addOption,
    handlePlaceholder,
    handleSwitchOptionOne,
    handleSwitchOptionTwo,
    handleDeleteField,
    handleOption,
    deleteOption,
    handleHint,
    hanleRequired,
    modalStep,
    handleValueChange,
    addRecuringOption,
    handleRecurringOption,
    handleAddSubField,

    addConditionalOption,
    handleConditionalOption,
    handleConditionalStep,
    handleDisplayInStepsConditional,
    handleSkipStepDisplay,
    handleAddFieldInConditional,
    handleFieldTypeChangeCondtional,
    handleLabelConditional,
    addOptionConditional,
    handleOptionConditional,
    deleteOptionConditional,
    handleDeleteFieldConditional,
    handleSwitchOptionOneConditional,
    handleSwitchOptionTwoConditional,
    hanleRequiredConditional,
    addRemoveSkipStep,
    handleSubStepHeadingChange,
    handleAddHintSubField,
    handleAddHintSubFieldRecurring,
    steps,
    handleCalculateStepIndex
}) => {
    const ref = useRef(null);

    const [showHint, setShowHint] = useState(false);
    const [fieldRowId, setFieldRowId] = useState('');

    const toggleHint = (field_row_id) => {
        if (field_row_id) {
            setFieldRowId(field_row_id);
        }
        setShowHint(!showHint);
    };

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        }
    });

    const [{ isDragging: _isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => ({
            id,
            index
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    const FieldsGenerate = (field, index, handleAddHintSubFieldRecurring) => {
        return (
            <div className="relative">
                <div className="border-b w-[30px] h-1 absolute top-[53px] border-[#BBBBBB]"></div>

                <div className="form-input-container mt-5 relative ml-[35px] ">
                    {showHint && fieldRowId === field?.field_row_id ? (
                        <Hint
                            onchange={(value) => handleAddHintSubFieldRecurring(value, stepIndex, fieldIndex, index)}
                            onClose={() => toggleHint()}
                            value={field.hint}
                        />
                    ) : (
                        ''
                    )}

                    <div className="form-child ">
                        <label>Field Type {field?.type} </label>

                        <div className="mt-2 ">
                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="value"
                                selectedValue={field?.type}
                                valuePropertyName="key"
                                optionsData={
                                    [
                                        { key: 'abn', value: 'ABN' },
                                        { key: 'address', value: 'Address' },
                                        { key: 'bsb', value: 'BSB' },
                                        { key: 'business-email', value: 'Business Email' },
                                        { key: 'business-industry-occupation', value: 'Business Industry Occupation' },
                                        { key: 'checkbox', value: 'Checkbox' },
                                        { key: 'date', value: 'Date' },
                                        { key: DOB, value: 'Date of birth' },
                                        { key: 'document', value: 'Document' },
                                        { key: 'dropdown', value: 'Selection' },
                                        { key: 'email', value: 'Email' },
                                        { key: 'first-name', value: 'First name' },
                                        { key: 'image', value: 'Image' },
                                        { key: 'last-name', value: 'Last name' },
                                        { key: 'middle-name', value: 'Middle name' },
                                        { key: 'number', value: 'Number' },
                                        { key: 'phone-number', value: 'Phone Number' },
                                        { key: 'privacy-policy', value: 'Privacy Policy' },
                                        { key: 'radio', value: 'Radio' },
                                        { key: 'salary-wages-occupation', value: 'Salary Wages Occupation' },
                                        { key: 'signature', value: 'Signature' },
                                        { key: 'switch', value: 'Switch Button' },
                                        { key: 'terms-and-conditions', value: 'Terms and conditions' },
                                        { key: 'text', value: 'Text' },
                                        { key: 'tfn', value: 'TFN' },
                                        { key: LIST, value: LIST }
                                    ] || []
                                }
                                sx="rounded-xl  py-3 ring-gray-400 bg-[#fff] !font-normal cursor-pointer"
                                showChipOnly={false}
                                onChangeValue={(obj) =>
                                    handleFieldTypeChange(
                                        { target: { value: obj.key } },
                                        stepIndex,
                                        fieldIndex,
                                        index,
                                        true
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="form-child default-forms ">
                        {LABEL_FIELDS?.includes(field?.type) ? (
                            <div>
                                {' '}
                                <div>
                                    {' '}
                                    <label>Label Name</label>
                                    <input
                                        type="text"
                                        maxLength={200}
                                        placeholder="Enter Label"
                                        className="mt-2"
                                        value={field.label}
                                        onChange={(e) => handleLabel(e, stepIndex, fieldIndex, index, true)}
                                        required
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="form-child">
                        {field.type === 'checkbox' ||
                        field.type === 'radio' ||
                        field.type === 'dropdown' ||
                        field?.type === 'recurring' ? (
                            <div className="">
                                <div className="invisible">fd</div>{' '}
                                <div
                                    onClick={() => {
                                        if (field?.type === 'recurring') {
                                            addRecuringOption(stepIndex, fieldIndex);
                                        } else {
                                            addOption(stepIndex, fieldIndex, index, true);
                                        }
                                    }}
                                    className="flex justify-end items-center cursor-pointer mt-3 "
                                >
                                    <TooltipChipWrap title={'Click here to add option' || '__'} variant="soft">
                                        <div>
                                            {' '}
                                            <BgAddIcon />
                                        </div>
                                    </TooltipChipWrap>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {PLACESHOLDER_FIELDS.includes(field.type) ? (
                            <div>
                                {' '}
                                <label>Placeholder</label>
                                <input
                                    type="text"
                                    maxLength={200}
                                    placeholder="Enter Placeholder"
                                    className="mt-2"
                                    value={field.placeholder}
                                    onChange={(e) => handlePlaceholder(e, stepIndex, fieldIndex, index, true)}
                                    required
                                />
                            </div>
                        ) : (
                            ''
                        )}

                        {field?.type === 'switch' && (
                            <div>
                                {' '}
                                <div className="invisible">switch</div>
                                <div className="grid grid-cols-2 gap-3 relative mt-2">
                                    <div className="flex items-center gap-2">
                                        {' '}
                                        <input
                                            type="text"
                                            maxLength={50}
                                            placeholder="option one"
                                            value={field.options[0]}
                                            onChange={(e) =>
                                                handleSwitchOptionOne(e, stepIndex, fieldIndex, index, true)
                                            }
                                            required
                                        />{' '}
                                    </div>

                                    <div className="flex items-center gap-2">
                                        {' '}
                                        <input
                                            value={field.options[1]}
                                            onChange={(e) =>
                                                handleSwitchOptionTwo(e, stepIndex, fieldIndex, index, true)
                                            }
                                            type="text"
                                            placeholder="option two"
                                            required
                                        />{' '}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="form-child   ">
                        <div className="invisible ">t</div>

                        <div className="flex gap-2 md:mt-3 justify-between md:justify-center items-center">
                            {' '}
                            <button
                                className="cursor-pointer"
                                onClick={() => toggleHint(field.field_row_id)}
                                type="button"
                            >
                                <InfoIcon />
                            </button>{' '}
                            <button onClick={() => handleDeleteField(stepIndex, fieldIndex, index, true)} type="button">
                                <BgDeleteIcon />
                            </button>{' '}
                        </div>
                    </div>
                </div>
                {EDITOR_FIELDS?.includes(field.type) ? (
                    <div className="mt-4 ml-[35px]">
                        {' '}
                        <div>
                            {' '}
                            <label>Placeholder </label>
                            <div className="mt-2">
                                {/* <DraftEditor
                                    onChange={(val) => handleValueChange(val, stepIndex, fieldIndex, index, true)}
                                    initialValue={field?.default_value || ''}
                                    maxWords={10000}
                                /> */}
                                <DraftEditorNew
                                    onChange={(val) => handleValueChange(val, stepIndex, fieldIndex, index, true)}
                                    initialValue={field?.default_value || ''}
                                    maxWords={10000}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {OPTION_FIELDS?.includes(field.type) ? (
                    <div className="mt-2 ml-[35px] ">
                        {field?.options?.length > 0 && (
                            <div>
                                {' '}
                                <label>Options</label>
                            </div>
                        )}
                        <div className="grid grid-cols-3 md:grid-cols-4 gap-3 mt-2">
                            {field.options.map((option, optionIndex) => (
                                <div key={'optionIndex' + optionIndex} className="flex items-center gap-2">
                                    {' '}
                                    <input
                                        maxLength={30}
                                        onChange={(e) =>
                                            handleOption(e, stepIndex, fieldIndex, optionIndex, index, true)
                                        }
                                        type="text"
                                        placeholder="option"
                                        value={option}
                                        required
                                    />
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => deleteOption(stepIndex, fieldIndex, optionIndex, index, true)}
                                    >
                                        <SideTimes />
                                    </div>{' '}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                {field?.type !== RECURRING && field.type !== CONDITIONAL && (
                    <div className="flex items-center gap-2 mt-3 ml-[35px]">
                        <div className="default-forms ">
                            {' '}
                            <label> Required</label>{' '}
                        </div>
                        <div>
                            <input
                                onChange={(e) => hanleRequired(e, stepIndex, fieldIndex, index, true)}
                                type="checkbox"
                                className="!w-[20px] !h-5 !rounded-md !text-thirdbg "
                                checked={field?.required ? true : false}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div>
            <div>
                <div className="form-input-container mt-5 relative ">
                    {showModal && modalIndex === fieldIndex && modalStep === stepIndex ? (
                        <AddHint
                            toggleModal={toggleModal}
                            handleHint={handleHint}
                            stepIndex={stepIndex}
                            fieldIndex={fieldIndex}
                            field={field}
                        />
                    ) : (
                        ''
                    )}
                    <div className="form-child ">
                        <label>Field Type </label>

                        <div className="mt-2 ">
                            <SimpleSelectMenu
                                placeholder="Select"
                                targetProperty="value"
                                selectedValue={field.type}
                                valuePropertyName="key"
                                optionsData={
                                    [
                                        { key: ABN, value: 'ABN' },
                                        { key: ADDRESS, value: 'Address' },
                                        { key: BSB, value: 'BSB' },
                                        { key: BUSINESS_EMAIL, value: 'Business Email' },
                                        { key: BUSINESS_INDUSTRY_OCCUPATION, value: 'Business Industry Occupation' },
                                        { key: CHECKBOX_FIELD, value: 'Checkbox' },
                                        { key: CONDITIONAL, value: 'Conditional' },
                                        { key: DATE, value: 'Date' },
                                        { key: DOB, value: 'Date of birth' },
                                        { key: DOCUMENT, value: 'Document' },
                                        { key: DROPDOWN, value: 'Selection' },
                                        { key: EMAIL, value: 'Email' },
                                        { key: FIRST_NAME, value: 'First name' },
                                        { key: IMAGE_FIELD, value: 'Image' },
                                        { key: LAST_NAME, value: 'Last name' },
                                        { key: MIDDLE_NAME, value: 'Middle name' },
                                        { key: NUMBER_FIELD, value: 'Number' },
                                        { key: PHONE_NUMBER_FIELD, value: 'Phone Number' },
                                        { key: PRIVACY_POLICY, value: 'Privacy Policy' },
                                        { key: RADIO_FIELD, value: 'Radio' },
                                        { key: RECURRING, value: 'Recurring Condition' },
                                        { key: SALARY_WAGES_OCCUPATION, value: 'Salary Wages Occupation' },
                                        { key: SIGNATURE, value: 'Signature' },
                                        { key: SWITCH, value: 'Switch Button' },
                                        { key: TERMS_AND_CONDITIONS, value: 'Terms and conditions' },
                                        { key: TEXT, value: 'Text' },
                                        { key: TFN, value: 'TFN' },
                                        { key: LIST, value: LIST }
                                    ] || []
                                }
                                sx="rounded-xl  py-3 ring-gray-400 bg-[#fff] !font-normal cursor-pointer"
                                showChipOnly={false}
                                onChangeValue={(obj) =>
                                    handleFieldTypeChange({ target: { value: obj.key } }, stepIndex, fieldIndex)
                                }
                            />
                        </div>
                    </div>

                    <div className="form-child default-forms ">
                        {LABEL_FIELDS?.includes(field.type) && (
                            <div>
                                {' '}
                                <div>
                                    {' '}
                                    <label>Label Name</label>
                                    <input
                                        type="text"
                                        maxLength={200}
                                        placeholder="Enter Label"
                                        className="mt-2"
                                        value={field.label}
                                        onChange={(e) => handleLabel(e, stepIndex, fieldIndex)}
                                        required
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="form-child">
                        {OPTION_FIELDS?.includes(field.type) ? (
                            <div>
                                <div className="invisible">fd</div>{' '}
                                <div
                                    onClick={() => {
                                        if (field?.type === 'recurring') {
                                            addRecuringOption(stepIndex, fieldIndex);
                                        } else {
                                            addOption(stepIndex, fieldIndex);
                                        }
                                    }}
                                    className="flex justify-end items-center cursor-pointer mt-3 "
                                >
                                    <TooltipChipWrap title={'Click here to add option' || '__'} variant="soft">
                                        <div>
                                            {' '}
                                            <BgAddIcon />
                                        </div>
                                    </TooltipChipWrap>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        {PLACESHOLDER_FIELDS?.includes(field.type) && (
                            <div>
                                {' '}
                                <label>Placeholder</label>
                                <input
                                    type="text"
                                    maxLength={200}
                                    placeholder="Enter Placeholder"
                                    className="mt-2"
                                    value={field.placeholder}
                                    onChange={(e) => handlePlaceholder(e, stepIndex, fieldIndex)}
                                    required
                                />
                            </div>
                        )}

                        {field?.type === SWITCH && (
                            <div>
                                <div className="invisible">switch</div>
                                <div className="grid grid-cols-2 gap-3 relative mt-2">
                                    <div className="flex items-center gap-2">
                                        <input
                                            type="text"
                                            maxLength={100}
                                            placeholder="option one"
                                            value={field.options[0]}
                                            onChange={(e) => handleSwitchOptionOne(e, stepIndex, fieldIndex)}
                                            required
                                        />
                                    </div>

                                    <div className="flex items-center gap-2">
                                        <input
                                            value={field.options[1]}
                                            onChange={(e) => handleSwitchOptionTwo(e, stepIndex, fieldIndex)}
                                            type="text"
                                            placeholder="option two"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="form-child   ">
                        <div className="invisible ">t</div>

                        <div className="flex gap-2 md:mt-3 justify-between md:justify-center items-center">
                            {' '}
                            <button
                                className="cursor-pointer"
                                onClick={() => toggleModal(fieldIndex, stepIndex)}
                                type="button"
                            >
                                <InfoIcon />
                            </button>{' '}
                            <button onClick={() => handleDeleteField(stepIndex, fieldIndex)} type="button">
                                <BgDeleteIcon />
                            </button>{' '}
                            <button ref={ref} data-handler-id={handlerId} className="cursor-grab" type="button">
                                <DragIcon />
                            </button>{' '}
                        </div>
                    </div>
                </div>
                {EDITOR_FIELDS?.includes(field.type) && (
                    <div className="mt-4">
                        {' '}
                        <div>
                            {' '}
                            <label>Placeholder </label>
                            <div className="mt-2">
                                {/* <DraftEditor
                                    onChange={(val) => handleValueChange(val, stepIndex, fieldIndex)}
                                    initialValue={field?.default_value || ''}
                                    maxWords={10000}
                                /> */}
                                <DraftEditorNew
                                    onChange={(val) => handleValueChange(val, stepIndex, fieldIndex)}
                                    initialValue={field?.default_value || ''}
                                    maxWords={10000}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {field.type === CONDITIONAL && (
                    <RenderConditionalInputs
                        field={field}
                        stepIndex={stepIndex}
                        handleConditionalOption={handleConditionalOption}
                        handleConditionalStep={handleConditionalStep}
                        handleDisplayInStepsConditional={handleDisplayInStepsConditional}
                        handleSkipStepDisplay={handleSkipStepDisplay}
                        handleAddFieldInConditional={handleAddFieldInConditional}
                        handleFieldTypeChangeCondtional={handleFieldTypeChangeCondtional}
                        handleLabelConditional={handleLabelConditional}
                        addOptionConditional={addOptionConditional}
                        handleOptionConditional={handleOptionConditional}
                        deleteOptionConditional={deleteOptionConditional}
                        handleDeleteFieldConditional={handleDeleteFieldConditional}
                        handleSwitchOptionOneConditional={handleSwitchOptionOneConditional}
                        handleSwitchOptionTwoConditional={handleSwitchOptionTwoConditional}
                        hanleRequiredConditional={hanleRequiredConditional}
                        addConditionalOption={addConditionalOption}
                        isRecurring={false}
                        addRemoveSkipStep={addRemoveSkipStep}
                        handleSubStepHeadingChange={handleSubStepHeadingChange}
                        handleAddHintSubField={handleAddHintSubField}
                        steps={steps}
                        handleCalculateStepIndex={handleCalculateStepIndex}
                    />
                )}

                {field?.type === RECURRING && (
                    <div className="mt-3  p-5 rounded-xl">
                        {field?.options?.length > 0 && (
                            <div className="mt-2">
                                {' '}
                                <label>Options</label>
                            </div>
                        )}
                        <div className="flex gap-3 flex-col  ">
                            {' '}
                            {field.options.map((option, optionIndex) => (
                                <div className="flex items-center gap-2 flex-wrap" key={optionIndex}>
                                    <div>
                                        <div>
                                            {' '}
                                            <label className="invisible ">Second Label</label>
                                        </div>{' '}
                                        <input
                                            maxLength={200}
                                            type="text"
                                            placeholder="option"
                                            value={option}
                                            onChange={(e) =>
                                                handleOptionConditional(e.target.value, field.field_row_id, optionIndex)
                                            }
                                            className="!w-[200px]"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            {' '}
                                            <label className="invisible ">Second Label</label>
                                        </div>
                                        <select
                                            className="h-[3rem] small-text font-poppins px-3 pr-20"
                                            value={
                                                field?.recurring_option === option &&
                                                field.options[0] !== field.options[1]
                                                    ? 'yes'
                                                    : 'no'
                                            }
                                            onChange={(e) =>
                                                handleRecurringOption(
                                                    stepIndex,
                                                    fieldIndex,
                                                    e.target.value,
                                                    optionIndex,
                                                    option
                                                )
                                            }
                                        >
                                            <option key={index} selected disabled>
                                                select
                                            </option>
                                            <option value="yes" key={generateId()}>
                                                Recurring Condition
                                            </option>
                                            <option value="no" key={generateId()}>
                                                No Condition
                                            </option>
                                        </select>
                                    </div>

                                    {field?.recurring_option === option && field.options[0] !== field.options[1] ? (
                                        <div>
                                            <div>
                                                <label>Second Label</label>
                                            </div>
                                            <input
                                                maxLength={150}
                                                onChange={(e) => handleRecuringLabel(e, stepIndex, fieldIndex)}
                                                type="text"
                                                value={field.recurring_label}
                                                placeholder="Enter second label"
                                                className="!w-[350px]"
                                                required
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {field?.recurring_option === option && field.options[0] !== field.options[1] ? (
                                        <div>
                                            <div>
                                                <label className="invisible">Second Label</label>
                                            </div>
                                            <div className=" flex justify-end">
                                                <TooltipChipWrap
                                                    title={'Click here to add fields' || '__'}
                                                    variant="soft"
                                                >
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => handleAddSubField(stepIndex, fieldIndex)}
                                                    >
                                                        <BgAddIcon />
                                                    </div>
                                                </TooltipChipWrap>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ))}
                        </div>
                        {field?.recurring_option && !field.recurring_label ? (
                            <div className="flex gap-3 items-center small-text p-1 px-3 rounded-xl text-blueish w-[60%] break-all mt-4 bg-[#f4f2f2]">
                                <div>
                                    <HintIcon />
                                </div>
                                <div>For Recurring Condition you have to add second label</div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className=" border-l border-[#BBBBBB] ml-5">
                            {field?.sub_fields?.map((sub, index) => (
                                <div key={'subfield' + index}>
                                    <div className=" ">
                                        {FieldsGenerate(sub, index, handleAddHintSubFieldRecurring)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {OPTION_FIELDS?.includes(field.type) && (
                    <div className="mt-2">
                        {field?.options?.length > 0 && (
                            <div>
                                <label>Options</label>
                            </div>
                        )}
                        <div className="grid grid-cols-3 md:grid-cols-4 gap-3 mt-2">
                            {field.options.map((option, optionIndex) => (
                                <div key={'options' + optionIndex} className="flex items-center gap-2">
                                    <input
                                        maxLength={100}
                                        onChange={(e) => handleOption(e, stepIndex, fieldIndex, optionIndex)}
                                        type="text"
                                        placeholder="option"
                                        value={option}
                                        required
                                    />
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => deleteOption(stepIndex, fieldIndex, optionIndex)}
                                    >
                                        <SideTimes />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {field?.type !== RECURRING && field?.type !== CONDITIONAL && (
                    <div className="flex items-center gap-2 mt-3 ">
                        <div className="default-forms ">
                            {' '}
                            <label> Required</label>{' '}
                        </div>
                        <div>
                            <input
                                onChange={(e) => hanleRequired(e, stepIndex, fieldIndex)}
                                type="checkbox"
                                className="!w-[20px] !h-5 !rounded-md !text-thirdbg "
                                checked={field?.required ? true : false}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
