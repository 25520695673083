import { useSelector } from 'react-redux';

const useRoleGuard = () => {
    const { data: loginData } = useSelector((state) => state.login);
    const allPermissions = JSON.parse(localStorage.getItem('allPermissions'));

    const hasPermissions = (module, permissions, createdBy = null) => {
        if (!loginData?.is_employee) {
            return true;
        }

        if (createdBy) {
            if (!permissions.includes('read')) {
                if (
                    (allPermissions?.[module]?.write === true && createdBy === loginData?.user_id) ||
                    allPermissions?.[module]?.admin === true
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            if (permissions.some((permission) => allPermissions?.[module]?.[permission])) {
                return true;
            } else {
                return false;
            }
        }
    };
    return { hasPermissions };
};

export default useRoleGuard;
