import { toastHandler } from 'responseHanlder';
import { getCookie } from 'utills/encryption/cryptoUtils';
import { APP_ENVIRONMENT, BASE_URL, CSRF_TOKEN, TOAST_TYPE_ERROR } from 'utills/globalVars';

export const useDownloadImages = () => {
    const handleDownloadImages = async (payload, url) => {
        let csrfToken = getCookie(CSRF_TOKEN);

        const _response = await fetch(`${BASE_URL}/${url ? url : 'exp-inc/download/images'} `, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                app_environment: APP_ENVIRONMENT,
                'x-csrf-token': csrfToken
            },
            credentials: 'include',
            body: JSON.stringify(payload)
        }).then(async (response) => {
            if (response.ok) {
                const blob = await response.blob();

                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = payload?.ref_no ? `${payload?.ref_no}_file` : 'file';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                toastHandler('File Has been downloaded!', 'success');
            } else {
                toastHandler('No file to download', TOAST_TYPE_ERROR);
            }
        });
    };
    return { handleDownloadImages };
};
