import React from 'react';

export const BgLocationIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="url(#paint0_linear_4340_391)" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0146 23.1829L19.6429 31L26.2735 23.1798C26.3089 23.1381 26.5446 22.8286 26.5446 22.8286L26.5454 22.8262C27.6771 21.3354 28.2885 19.5145 28.2857 17.6429C28.2857 15.3506 27.3751 13.1523 25.7543 11.5314C24.1334 9.91058 21.9351 9 19.6429 9C17.3506 9 15.1523 9.91058 13.5314 11.5314C11.9106 13.1523 11 15.3506 11 17.6429C10.9973 19.5154 11.6089 21.3371 12.7412 22.8286C12.8313 22.9474 12.9225 23.0656 13.0146 23.1829ZM14.0804 21.9929C14.0347 21.9327 13.9959 21.8818 13.9952 21.8818H13.9959C13.0702 20.6627 12.5698 19.1736 12.5714 17.6429C12.5714 15.7674 13.3165 13.9688 14.6426 12.6426C15.9688 11.3165 17.7674 10.5714 19.6429 10.5714C21.5183 10.5714 23.317 11.3165 24.6431 12.6426C25.9693 13.9688 26.7143 15.7674 26.7143 17.6429C26.7161 19.1727 26.2163 20.6609 25.2914 21.8794C25.2912 21.8796 25.2801 21.8942 25.263 21.9168C25.2099 21.987 25.0983 22.1343 25.0722 22.167L19.6429 28.5706L14.2207 22.1756C14.1981 22.1476 14.1344 22.0639 14.0804 21.9929ZM17.4608 20.9094C18.1069 21.3411 18.8664 21.5715 19.6434 21.5715C20.685 21.5703 21.6835 21.156 22.42 20.4195C23.1564 19.683 23.5707 18.6845 23.572 17.6429C23.572 16.8659 23.3416 16.1064 22.9099 15.4603C22.4782 14.8143 21.8647 14.3107 21.1468 14.0134C20.429 13.7161 19.6391 13.6383 18.877 13.7898C18.1149 13.9414 17.4149 14.3156 16.8655 14.865C16.3161 15.4144 15.9419 16.1144 15.7903 16.8765C15.6387 17.6386 15.7165 18.4285 16.0139 19.1463C16.3112 19.8642 16.8148 20.4777 17.4608 20.9094ZM18.3339 15.683C18.7215 15.424 19.1772 15.2858 19.6434 15.2858C20.2684 15.2864 20.8676 15.535 21.3095 15.9769C21.7514 16.4188 21.9999 17.018 22.0006 17.6429C22.0006 18.1091 21.8623 18.5649 21.6033 18.9525C21.3443 19.3401 20.9762 19.6422 20.5455 19.8206C20.1147 19.9991 19.6408 20.0457 19.1836 19.9548C18.7263 19.8638 18.3063 19.6393 17.9767 19.3097C17.647 18.98 17.4225 18.56 17.3316 18.1028C17.2406 17.6455 17.2873 17.1716 17.4657 16.7409C17.6441 16.3102 17.9462 15.942 18.3339 15.683Z"
                    fill="white"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_4340_391"
                        x1="21.9626"
                        y1="0.49676"
                        x2="21.9327"
                        y2="40.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
