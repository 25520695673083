import { createSlice } from '@reduxjs/toolkit';

const globalPresistReducer = createSlice({
    name: 'globalPresist',
    initialState: {
        emailIntervalId: null
    },
    reducers: {
        setEmailSocketConnection: (state, action) => {
            state.emailIntervalId = action.payload;
        }
    }
});

export const { setEmailSocketConnection } = globalPresistReducer.actions;

export default globalPresistReducer;
