import React from 'react';
import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import MailIcon2 from 'utills/svgs/MailIcon2';
import CategoriesIcon from 'utills/svgs/CategoriesIcon';

const EmailManagerAccountSettings = () => {
    return (
        <div>
            <SidebarLayout>
                <Jumbotron
                    title="Email Manager Account Settings"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },
                        {
                            link: '',
                            title: 'Email Manager Account Settings'
                        }
                    ]}
                />
                <div className="md:mx-10 mx-5 flex gap-5 items-center flex-wrap">
                    <GridCard text="Categories" iconSet={{ icon: CategoriesIcon }} link={'categories'} />
                    <GridCard text="Email Account" iconSet={{ icon: MailIcon2 }} link={'email-account'} />
                </div>
            </SidebarLayout>
        </div>
    );
};

export default EmailManagerAccountSettings;
