import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '../../../../utills/svgs/ArrowLeft';
import Breadcrumb from 'components/atoms/Breadcrumb';
const OSJumbo = ({ _handleCondition, path }) => {
    const navigate = useNavigate();

    return (
        <div className=" relative  md:flex md:flex-row flex-col  px-10 py-10 md:justify-between ">
            <div className="profile-jumbo-flex">
                <div className="jumbo-flex-1 ">
                    <div className="flex justify-between">
                        <div className="jumbo-name flex gap-2 items-center">
                            <div onClick={() => navigate('/settings/task-module')} className="cursor-pointer">
                                <ArrowLeft />
                            </div>
                            <div className="text-3xl font-fira font-[600]"> Task Module Settings</div>
                        </div>
                    </div>
                    {path ? (
                        <Breadcrumb
                            path={[
                                {
                                    link: 'settings',
                                    title: 'Settings'
                                },
                                {
                                    link: 'settings/task-module',
                                    title: 'Task Settings'
                                },
                                {
                                    link: '',
                                    title: 'Dynamic Status'
                                }
                            ]}
                        />
                    ) : (
                        <div className="jumbo-dir mt-2">
                            Workspace &gt; Tasks{' '}
                            <span className="special-jumbo-text">&gt; Organisational Settings &gt; Dynamic Status</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OSJumbo;
