import React from 'react';
import 'driver.js/dist/driver.css';
import './css/settings.css';
import { RolsSvg } from 'utills/svgs/RolsSvg';
import { MultipleUsersSvg } from 'utills/svgs/MultipleUsersSvg';
import { FormsIcon } from 'utills/svgs/FormsIcon';
import { GridCard } from 'components/molecules/GridCard';
import { SecurityShieldIcon } from 'utills/svgs/SecurityShieldIcon';
import { WorkspaceIconSvg } from 'utills/svgs/WorkspaceIconSvg';
import { AppsConnectionSvg } from 'utills/svgs/AppsConnectionSvg';
import { JobManagerIcon2 } from 'utills/svgs/JobManagerIcon2';
import { LargeTaskIcon } from 'utills/svgs/LargeTaskIcon';
import { AllClientsIcon } from 'utills/svgs/AllClientsIcon';
import { EmailNotificationIcon } from 'utills/svgs/EmailNotificationIcon';
import { OboardingServicesIcon } from 'utills/svgs/OboardingServicesIcon';
import { SignatureIcon } from 'utills/svgs/SignatureIcon';
import { _EmailManagerAccountIcon } from 'utills/svgs/EmailManagerAccountIcon';

export const SettingsCards = ({ toggleAddedForms }) => {
    const handleAddedForms = () => {
        toggleAddedForms();
    };

    return (
        <div className="mx-10 mb-10 inline-block">
            <div className="add-ann-form flex items-center flex-wrap gap-5 ">
                <GridCard text="App Connections" iconSet={{ icon: AppsConnectionSvg }} link={'/app-connections'} />
                <GridCard
                    text="Clients"
                    iconSet={{ icon: AllClientsIcon }}
                    link={'/settings/clients'}
                    state={{
                        settings: true
                    }}
                />
                <GridCard text="Customised Forms" iconSet={{ icon: FormsIcon }} atClick={() => handleAddedForms()} />
                <GridCard
                    text="Email and Notification Settings"
                    iconSet={{ icon: EmailNotificationIcon, sx: '!stroke-0 h-20 w-20 ' }}
                    link={'/settings/email-notifications'}
                />
                {/* <GridCard
                    text="Email Manager Account Setting"
                    iconSet={{ icon: EmailManagerAccountIcon, sx: 'text-black h-20 w-20 ' }}
                    link={'/settings/email-manager-account-settings'}
                /> */}
                {/* <GridCard
                    text="Groups"
                    iconSet={{ icon: TagIcon, sx: 'text-black h-20 w-20 ' }}
                    link={'/settings/groups'}
                /> */}
                <GridCard text="Job Manager" iconSet={{ icon: JobManagerIcon2 }} link={'/settings/job-manager'} />
                <GridCard
                    text="Onboarding Services"
                    iconSet={{ icon: OboardingServicesIcon, sx: 'text-black h-20 w-20 ' }}
                    link={'/settings/onboarding-services'}
                />
                <GridCard text="Role Management" iconSet={{ icon: RolsSvg }} link={'/team/rolemanagement'} />
                <GridCard
                    text="Security and Authentication"
                    iconSet={{ icon: SecurityShieldIcon }}
                    link={'/security-and-authentication'}
                />
                <GridCard text="Task Module" iconSet={{ icon: LargeTaskIcon }} link={'/settings/task-module'} />
                <GridCard text="Teams" iconSet={{ icon: MultipleUsersSvg }} link={'/team'} />
                <GridCard
                    text="User Email Signature"
                    iconSet={{ icon: SignatureIcon, sx: 'text-black h-20 w-20 ' }}
                    link={'/settings/user-email-signature'}
                />
                <GridCard text="Workspace Settings" iconSet={{ icon: WorkspaceIconSvg }} link={'/workspace-settings'} />
            </div>
        </div>
    );
};
