import React from 'react';

export const FillArrow = () => {
    return (
        <div className="transform rotate-180">
            <svg width="25" height="26" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_206_10649)">
                    <path
                        d="M21.308 18.5108C21.3853 18.4827 21.54 18.3655 21.656 18.239C22.017 17.8452 22.0943 17.3015 21.8537 16.8562C21.7935 16.753 19.4689 14.1796 16.6846 11.1468C12.7357 6.84365 11.5799 5.60615 11.4295 5.53584C11.176 5.40928 10.8236 5.40928 10.5701 5.53584C10.4197 5.60615 9.26385 6.84365 5.31502 11.1468C2.53065 14.1796 0.206039 16.753 0.145883 16.8562C-0.0947427 17.3015 -0.0173977 17.8452 0.343539 18.239C0.468148 18.3749 0.614242 18.4827 0.713071 18.5108C0.932212 18.5765 21.0931 18.5765 21.308 18.5108Z"
                        fill="url(#paint0_linear_206_10649)"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_206_10649"
                        x1="9.9211"
                        y1="5.60385"
                        x2="9.92696"
                        y2="18.5601"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <clipPath id="clip0_206_10649">
                        <rect width="22" height="24" fill="white" transform="translate(22 24) rotate(180)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
