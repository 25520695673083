import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getGroupsThunk } from 'store/groups';
import { BUSINESS_ID } from 'utills/globalVars';

export const useGetGroups = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [data, setData] = useState([]);
    const handleGetGroups = (name) => {
        dispatch(getGroupsThunk({ business_id, type: name }))
            .then((response) => {
                if (response.payload) {
                    setData(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return { handleGetGroups, data };
};
