import React from 'react';
const ReportsCards = () => {
    return (
        <div className="md:px-10 mt-3">
            <div>
                <div className="grid grid-cols-4  my-reports-cards gap-5">
                    <div className="reports-card relative">
                        <div className="px-5 py-7 ">
                            <div className="flex gap-8 flex-col">
                                <div className="report-card-number">34</div>
                                <div className="report-card-text">Task Created</div>
                            </div>
                        </div>
                        <div className="scale-75 first-card-icon">
                            <svg
                                width="165"
                                height="170"
                                viewBox="0 0 147 135"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    opacity="0.4"
                                    d="M119.79 14.568L68.2741 0.808524C64.3277 -0.245513 60.0582 0.328125 56.4723 2.39844L7.96133 30.4062C0.518929 34.7031 -1.99265 44.2592 2.29735 51.6897L64.6297 159.809C68.9197 167.239 78.4513 169.842 85.8937 165.545L167.151 118.631C174.594 114.334 177.173 104.739 172.883 97.3087L129.32 21.8554C127.253 18.2752 123.843 15.6505 119.79 14.568ZM89.3879 46.4412C85.6667 48.5897 80.8671 47.3077 78.7221 43.5925L61.1721 13.195L119.834 28.8631L89.3879 46.4412Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className="reports-card  relative">
                        <div className="px-5 py-7 ">
                            <div className="flex gap-8 flex-col">
                                <div className="report-card-number">2</div>
                                <div className="report-card-text">Task Accepted</div>
                            </div>
                        </div>
                        <div className="p-3 ">
                            <div className="scale-75 card-icon">
                                <svg
                                    width="155"
                                    height="128"
                                    viewBox="0 0 147 138"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        opacity="0.4"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M119.735 14.568L68.2194 0.808524C64.2731 -0.245513 60.0035 0.328125 56.4176 2.39844L7.90665 30.4063C0.464241 34.7031 -2.04734 44.2592 2.24266 51.6897L64.575 159.809C68.865 167.239 78.3966 169.842 85.839 165.545L167.097 118.631C174.539 114.334 177.118 104.739 172.828 97.3087L129.265 21.8554C127.198 18.2752 123.788 15.6505 119.735 14.568ZM89.3332 46.4412C85.612 48.5897 80.8124 47.3077 78.6674 43.5925L61.1174 13.195L119.779 28.8631L89.3332 46.4412ZM115.608 76.7453C119.195 74.6742 123.782 75.9033 125.853 79.4905C127.924 83.0777 126.695 87.6646 123.108 89.7357L109.253 97.7347L117.253 111.591C119.324 115.178 118.095 119.765 114.508 121.836C110.921 123.907 106.334 122.678 104.263 119.091L96.2629 105.235L82.4048 113.236C78.8176 115.307 74.2306 114.078 72.1596 110.49C70.0885 106.903 71.3176 102.316 74.9048 100.245L88.7629 92.2443L80.7629 78.3879C78.6918 74.8007 79.9209 70.2138 83.5081 68.1427C87.0953 66.0717 91.6822 67.3007 93.7533 70.8879L101.753 84.7443L115.608 76.7453Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="reports-card  relative">
                        <div className="px-5 py-7 ">
                            <div className="flex gap-8 flex-col">
                                <div className="report-card-number">4</div>
                                <div className="report-card-text">Task Completed</div>
                            </div>
                        </div>
                        <div className="p-3 ">
                            <div className="scale-75 card-icon">
                                <svg
                                    width="157"
                                    height="126"
                                    viewBox="0 0 147 136"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        opacity="0.4"
                                        d="M119.79 14.568L68.2741 0.808524C64.3277 -0.245513 60.0582 0.328125 56.4723 2.39844L7.96133 30.4062C0.518929 34.7031 -1.99265 44.2592 2.29735 51.6897L64.6297 159.809C68.9197 167.239 78.4513 169.842 85.8937 165.545L167.151 118.631C174.594 114.334 177.173 104.739 172.883 97.3087L129.32 21.8554C127.253 18.2752 123.843 15.6505 119.79 14.568ZM96.2118 117.167L73.6002 111.127C72.6128 110.864 71.6872 110.408 70.8765 109.787C70.0657 109.165 69.3856 108.39 68.875 107.506C68.3643 106.621 68.0332 105.645 67.9004 104.632C67.7677 103.619 67.8359 102.59 68.1012 101.603C68.3665 100.616 68.8238 99.6904 69.4468 98.8794C70.0698 98.0684 70.8464 97.3878 71.7322 96.8763C72.6181 96.3649 73.5959 96.0326 74.6097 95.8986C75.6235 95.7645 76.6536 95.8313 77.641 96.095L92.6798 100.112L102.825 62.3709C103.09 61.3839 103.547 60.4585 104.17 59.6475C104.793 58.8365 105.57 58.1559 106.456 57.6444C107.342 57.133 108.319 56.8008 109.333 56.6667C110.347 56.5326 111.377 56.5994 112.365 56.8631C113.352 57.1269 114.278 57.5824 115.088 58.2038C115.899 58.8252 116.579 59.6003 117.09 60.4847C117.601 61.3691 117.932 62.3456 118.064 63.3585C118.197 64.3713 118.129 65.4006 117.864 66.3877L105.713 111.591C104.702 115.778 100.371 118.278 96.2118 117.167ZM89.3879 46.4412C85.6667 48.5897 80.8671 47.3077 78.7221 43.5925L61.1721 13.195L119.834 28.8631L89.3879 46.4412Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="reports-card  relative">
                        <div className="px-5 py-7 ">
                            <div className="flex gap-8 flex-col">
                                <div className="report-card-number">3</div>
                                <div className="report-card-text">Task Delayed</div>
                            </div>
                        </div>
                        <div className="p-3 ">
                            <div className="scale-75 card-icon">
                                <svg
                                    width="155"
                                    height="137"
                                    viewBox="0 0 150 137"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        opacity="0.4"
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M68.8444 1.08977L120.36 14.8492C124.413 15.9318 127.823 18.5565 129.89 22.1366L173.453 97.59C177.743 105.02 175.164 114.616 167.722 118.912L86.464 165.827C79.0216 170.123 69.49 167.52 65.2 160.09L2.86766 51.9709C-1.42234 44.5404 1.08924 34.9844 8.53165 30.6875L57.0426 2.67969C60.6285 0.609375 64.8981 0.0357367 68.8444 1.08977ZM79.2924 43.8737C81.4374 47.589 86.237 48.8709 89.9582 46.7225L120.404 29.1444L61.7424 13.4762L79.2924 43.8737ZM72.5875 110.886C64.0315 96.0661 69.088 77.1102 83.9074 68.5542C98.7537 59.9827 117.725 65.0661 126.281 79.8855C134.837 94.7049 129.754 113.676 114.907 122.248C100.088 130.804 81.1435 125.705 72.5875 110.886ZM115.84 101.007C116.087 100.684 116.268 100.316 116.373 99.9242C116.816 98.2727 115.839 96.5813 114.188 96.1388L101.483 92.7348L95.919 83.0968C95.0665 81.6202 93.1609 81.1096 91.6843 81.9621C90.2077 82.8146 89.6971 84.7202 90.5496 86.1968L96.7496 96.9355C96.9562 97.2899 97.231 97.5997 97.5581 97.8471C97.8853 98.0945 98.2583 98.2744 98.6555 98.3766L112.588 102.11C112.98 102.215 113.389 102.242 113.791 102.19C114.194 102.137 114.582 102.005 114.934 101.802C115.285 101.599 115.593 101.329 115.84 101.007Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReportsCards;
