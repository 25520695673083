import React from 'react';
const { Protected } = require('./protected-routes/Protected');

const BusinessDashboard = React.lazy(() => import('../pages/business-dashboard/BusinessDashboard'));

const businessDashboard = [
    {
        path: '/business/dashboard',
        component: <Protected Component={BusinessDashboard} />,
        isProtected: true,
        layout: 'businessDashboard'
    }
];

export default businessDashboard;
