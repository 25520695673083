import { SmallLoader } from 'components/common/SmallLoader';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, skipSetupThunk } from 'store/auth/slices';
import { ACCESS_TOKEN, BUSINESS_ID, REFRESH_TOKEN } from 'utills/globalVars';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';
import { BlueTick } from 'utills/svgs/BlueTick';
import { LaptopMobile } from 'utills/svgs/LaptopMobile';

import { stopHeartbeatInterval } from 'utills/socketEvents';
import { handleTempAuthInterval } from 'store/global/globalReducer';
import useRedirectToDashboardHook from 'Hooks/useRedirectToDashboardHook';

export const TwoFactorInfo = ({ userData, _complete_two_fa, handleCondition, isLoading }) => {
    const { temp_auth_interval } = useSelector((state) => state.global);
    const { redirectToDashboard } = useRedirectToDashboardHook();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigateTo = () => {
        stopHeartbeatInterval(dispatch, temp_auth_interval, handleTempAuthInterval);

        dispatch(skipSetupThunk()).then(async (response) => {
            if (response.payload) {
                localStorage.removeItem('temp_token');
                localStorage.setItem(ACCESS_TOKEN, response.payload?.access_token);
                localStorage.setItem(REFRESH_TOKEN, response.payload?.refresh_token);
                localStorage.setItem(BUSINESS_ID, response.payload?.business?.business_id);
                localStorage.setItem('verified', true);
                await dispatch(login?.actions?.handleUpdate(response.payload));

                if (response.payload?.is_employee) {
                    // navigate('/business/dashboard');
                    redirectToDashboard();
                    return;
                }
                if (response.payload.business.onboarded) {
                    localStorage.setItem('onboarding', true);

                    // navigate('/business/dashboard');
                    redirectToDashboard();
                    return;
                } else {
                    navigate('/onboarding');
                    return;
                }

                // if (response.payload.is_password_change_required && data?.is_employee) {
                //     navigate(`/update-ot-password/${data.user_id}`);
                // } else if (response.payload?.is_employee && !data.is_password_change_required) {
                //     navigate('/settings');
            }
        });
    };
    return (
        <div>
            <div className=" card-heading-two font-poppins px-5 pt-4">
                <h1>Two Factor Authentication</h1>
            </div>

            <div className="flex justify-center items-center pt-10">
                <LaptopMobile />
            </div>

            <div className="text-center table-title mt-3">Add extra security to your account</div>

            <div className="two-factor-text inputs-font px-5 mt-3">
                <p>Please enable the Two Factor authentication process to enhance the security of your account.</p>
            </div>

            <div className="authentication mt-3 flex justify-between items-center py-3 w-[65%]">
                <div className=" flex px-3 items-center gap-1 buttons-font">
                    <div className="flex justify-center items-center">
                        <BlueGreaterSign />
                    </div>
                    <div>Email Verification</div>
                </div>
                <div className="">
                    {isLoading ? (
                        <div>
                            <SmallLoader />{' '}
                        </div>
                    ) : (
                        <div>
                            {userData?.email_2fa ? (
                                <BlueTick />
                            ) : (
                                <button
                                    onClick={() => handleCondition(2)}
                                    className="px-3 py-1 bg-linear rounded-xl small-text text-white"
                                >
                                    Set Up
                                </button>
                            )}{' '}
                        </div>
                    )}
                </div>
            </div>

            <div className="authentication  py-3 w-[65%] flex justify-between items-center">
                <div className=" flex px-3 items-center gap-1 buttons-font">
                    <div className="flex justify-center items-center">
                        <div>
                            <BlueGreaterSign />
                        </div>
                    </div>
                    <div>Google Authenticator</div>
                </div>
                <div className=" buttons-font-lighter">
                    {isLoading ? (
                        <div>
                            <SmallLoader />{' '}
                        </div>
                    ) : (
                        <div>
                            {' '}
                            {userData && userData.google_authenticator_2fa ? (
                                <BlueTick />
                            ) : (
                                <button
                                    onClick={() => handleCondition(3)}
                                    className="px-3 py-1 bg-linear small-text rounded-xl text-white"
                                >
                                    Set Up
                                </button>
                            )}{' '}
                        </div>
                    )}
                </div>
            </div>
            {userData?.email_2fa || userData?.google_authenticator_2fa ? (
                <div>
                    <div className="flex justify-center mx-8 ro mt-20">
                        <button onClick={() => navigateTo()} className="ann-btn px-10 rounded-md py-2">
                            Continue
                        </button>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
