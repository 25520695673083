/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'components/atoms/buttons/Button';
import EmailInput from 'components/atoms/Input/EmailInput';
import AuthCardLayout from 'layouts/AuthCardLayout';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessverifyTwoFactorEmailThunk, login } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { USER_TYPE } from 'utills/globalVars';
import { EmailVerifiedIcon } from 'utills/svgs/EmailVerifiedIcon';

export const EmailVerification = ({ handleCondition }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.login);

    const handleOtpSubmit = async () => {
        const verifyOtpData = {
            user_id: data?.user_id,
            otp: '',
            user_type: USER_TYPE
        };
        dispatch(setLoader(true));
        await dispatch(AccessverifyTwoFactorEmailThunk(verifyOtpData))
            .then((response) => {
                if (response.payload) {
                    const newPayload = {
                        ...data,
                        email_2fa: true
                    };
                    dispatch(login.actions.handleUpdate(newPayload));
                    localStorage.setItem('email_2fa', true);
                    handleCondition(2);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <AuthCardLayout hideBorder={true}>
            {' '}
            <div className="one-head px-5 pt-3 flex   items-center">
                <h1>Email Verification</h1>
            </div>
            <div className="email-text text-sm flex mx-5  gap-2  mt-3"></div>
            <div className="flex justify-center items-center">
                <EmailVerifiedIcon />
            </div>
            <div className="small-text text-[#666666] font-poppins px-5">
                {' '}
                Your email has been already verified! Click &apos;Activate&apos; to enable email 2FA and enhance your
                account security.
            </div>
            <div className="px-5 default-forms mt-5">
                <label>Email </label>
                <div className="mt-2">
                    <EmailInput input type="email" value={data?.email} disabled />
                </div>
            </div>
            <div className="otp-verification mt-16"></div>
            <div className=" mt-10  my-5 flex justify-between items-center mx-5">
                <Button
                    atClick={() => handleCondition(0)}
                    title="Back"
                    classes="border border-secondarybg text-secondarybg text-md font-poppins h-[3rem] px-12 rounded-lg text-center "
                />
                <button onClick={() => handleOtpSubmit()} className=" px-12 ann-btn rounded-lg">
                    Activate
                </button>
            </div>
        </AuthCardLayout>
    );
};
