/* eslint-disable react-hooks/exhaustive-deps */
import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { googleAuthThunk, verifyGoogleAuthAtSetupThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { BackArrow } from 'utills/svgs/BackArrow';
import { BlueGreaterSign } from 'utills/svgs/BlueGreaterSign';

export const GooogleAuthWithCode = ({ handleCondition }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const base64String = useSelector((state) => state?.googleAuth?.data?.otpauth_url);
    const data = useSelector((state) => state.login);

    const [otpVal, setOtpVal] = useState('');

    const googleAuthData = {
        user_id: data?.data?.user_id,
        user_type: 'employee'
    };

    const verifyGoogleData = {
        user_id: data?.data.user_id,
        user_type: 'employee',
        code: otpVal
    };

    const handleOtpSubmit = async () => {
        if (otpVal.length < 6) {
            return toastHandler('Please enter full otp', 'error');
        }

        dispatch(setLoader(true));
        await dispatch(verifyGoogleAuthAtSetupThunk(verifyGoogleData))
            .then((response) => {
                if (response.payload) {
                    navigate('/auth/success');
                    handleCondition(1);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [code, setCode] = useState('');

    useEffect(() => {
        dispatch(googleAuthThunk(googleAuthData))
            .then((response) => {
                if (response.payload) {
                    setCode(response.payload.code);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    return (
        <div>
            <div className="one-head  pt-4 flex   ">
                <div className="one-head px-3 pt-2 flex   ">
                    <div className="arrow-wrapper mt-1 cursor-pointer" onClick={() => handleCondition(3)}>
                        <BackArrow />
                    </div>
                    <div>
                        {' '}
                        <h1>Google Authenticator</h1>
                    </div>
                </div>
            </div>

            <div className="email-text flex  mx-5 gap-1 mt-3">
                <div>
                    <div className=" mt-1">
                        <BlueGreaterSign />
                    </div>
                </div>
                <div>
                    <span className="text-sm">Please use your Google Authenticator app to scan the QR code.</span>
                </div>
            </div>

            <div className="flex justify-center items-center  mt-5">
                {base64String && <img src={base64String} alt="qrcode" />}
            </div>

            <div className="text-3xl font-poppins my-5 flex justify-center items-center">
                <div>
                    <div>
                        <div className='"flex gap-5 items-center'>
                            {' '}
                            <span> {code?.slice(0, 4)} </span>
                            <span> {code?.slice(4, 8)} </span>
                            <span> {code?.slice(8, 12)} </span>
                            <span> {code?.slice(12, 16)} </span>
                            <span> {code?.slice(16, code?.length)} </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="google-inner px-5 mt-5">Enter the 6-digit authentication code generated by your app:</div>

            <div className="email-text px-5 text-sm">
                Upon scanning the QR code, the app will present a 6-digit code for you to input below.
            </div>

            <div className="mt-10 mx-t  flex justify-center items-center">
                <OtpInputs value={otpVal} onChange={handleOtpChange} />
            </div>

            <div className="flex justify-center mb-5 items-center gap-3 sm:mt-10 md:mt-10 lg:mt-20 max-xl:mt-28">
                <button onClick={() => handleCondition(3)} className="btn-1 px-10 py-2 rounded-md">
                    Cancel{' '}
                </button>
                <button onClick={handleOtpSubmit} className="btn-2  px-10 py-2 rounded-md">
                    Enable
                </button>
            </div>
        </div>
    );
};
