import React from 'react';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';
import TaskTemplatePage from 'components/workplace-content/tasks-pages/organizational-settings-pages/TaskTemplatePage';

const TaskTemplates = () => {
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                <RoleBasedGuard module={'Tasks'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    <TaskTemplatePage />
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};

export default TaskTemplates;
