import React from 'react';

export const DocFile = ({ className }) => {
    return (
        <svg
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 5.4C0 3.96783 0.656454 2.59432 1.82495 1.58162C2.99345 0.568927 4.57827 0 6.23077 0L40.3214 0H50C52.2091 0 54 1.79086 54 4V48.6C54 50.0322 53.3436 51.4057 52.1751 52.4184C51.0066 53.4311 49.4217 54 47.7692 54H6.23077C4.57827 54 2.99345 53.4311 1.82495 52.4184C0.656454 51.4057 0 50.0322 0 48.6V5.4ZM10.3846 18.225H4.15385V36.225H10.3846C12.0371 36.225 13.6219 35.6561 14.7904 34.6434C15.9589 33.6307 16.6154 32.2572 16.6154 30.825V23.625C16.6154 22.1928 15.9589 20.8193 14.7904 19.8066C13.6219 18.7939 12.0371 18.225 10.3846 18.225ZM27 18.225C25.3475 18.225 23.7627 18.7939 22.5942 19.8066C21.4257 20.8193 20.7692 22.1928 20.7692 23.625V30.825C20.7692 32.2572 21.4257 33.6307 22.5942 34.6434C23.7627 35.6561 25.3475 36.225 27 36.225C28.6525 36.225 30.2373 35.6561 31.4058 34.6434C32.5743 33.6307 33.2308 32.2572 33.2308 30.825V23.625C33.2308 22.1928 32.5743 20.8193 31.4058 19.8066C30.2373 18.7939 28.6525 18.225 27 18.225ZM37.3846 36.225V18.225H49.8462V25.425H45.6923V21.825H41.5385V32.625H45.6923V29.025H49.8462V36.225H37.3846Z"
                fill="#198DCF"
            />
        </svg>
    );
};
