import React from 'react';
import carIcon from 'files/d.png';
import { formatNumberWithCommas } from 'utills/uid';

export const TripLogbookCard = ({ data }) => {
    return (
        <>
            <div className="bg-white shadow-md rounded-2xl p-5 relative">
                <div className="flex items-center">
                    <h3 className="flex-1 text-lg font-semibold">{data?.vehicle_make + ' ' + data?.vehicle_model}</h3>
                    <div className="flex items-center justify-center gap-5">
                        <div className="tracking-normal">
                            <span className="px-3 py-1.5 text-third bg-[#b695f838] !font-medium !rounded-xl !text-sm lg:text-lg">
                                Registration Num: {data?.registration_number || 'N/A'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 mt-5">
                    <div className="col-span-2">
                        <div className="grid grid-cols-2 gap-5 items-center">
                            <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                Vehicle Type:
                            </h2>
                            <div className="text-black font-medium">{data?.vehicle_type || 'N/A'}</div>
                        </div>
                    </div>

                    <div className="col-span-1 hidden lg:block row-span-2">
                        <img src={carIcon} className="absolute right-[0px] -bottom-[5px]" alt="car-icon" />
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-2 gap-5 items-center">
                            <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                Odometer Reading:{' '}
                            </h2>
                            <div className="text-black font-medium">
                                {data?.current_oddo_meter_reading
                                    ? formatNumberWithCommas(data?.current_oddo_meter_reading) + ' Km'
                                    : 'N/A'}
                            </div>
                        </div>
                    </div>

                    <div className="col-span-2">
                        <div className="grid grid-cols-2 gap-5 items-center">
                            <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                Odometer Reading Frequency
                            </h2>
                            <span className="text-black font-medium min-w-[98px] inline-block text-left">
                                {data?.oddometer_reading_frequency || 'N/A'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
