import React from "react";

import SignupSuccess from "../../components/signup-steps/SignupSuccess";
export const RegistrationSuccessfull = () => {
  return (
    <div>
      <SignupSuccess />
    </div>
  );
};
