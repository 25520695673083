import React from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTheme, VictoryGroup } from 'victory';

export const WeeklyStatistics = ({ weeklyStats }) => {
    return (
        <div className="p-5">
            {' '}
            <div className="xl-title font-fira px-3">Weekly Statistics</div>
            <div className="flex items-center gap-10 mt-3 px-3">
                <div className="flex items-center gap-2">
                    <div className="bg-blueish w-3 h-3"></div>
                    <div className="buttons-font font-poppins">This Week</div>
                </div>
                <div className="flex items-center gap-2">
                    <div className="bg-thirdbg h-3 w-3"></div>
                    <div className="buttons-font font-poppins">Last Week</div>
                </div>
            </div>
            <div>
                <VictoryChart
                    theme={VictoryTheme.material}
                    domain={{ y: [0, 6] }}
                    height={180}
                    padding={{ top: 5, bottom: 20, left: 35, right: 20 }}
                    style={{ fontSize: 8 }}
                >
                    <VictoryAxis
                        dependentAxis
                        style={{
                            tickLabels: { fill: '#979797', fontSize: 8 },
                            axis: { stroke: 'none' }
                        }}
                    />
                    <VictoryAxis
                        tickFormat={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'SAT', 'SUN']}
                        style={{
                            tickLabels: { fill: '#979797', fontSize: 8 },
                            axis: { stroke: 'none' }
                        }}
                    />
                    <VictoryGroup
                        horizontal
                        offset={10}
                        style={{ data: { width: 4 } }}
                        colorScale={['brown', 'tomato', 'gold']}
                    >
                        <VictoryBar
                            animate={{
                                duration: 2000,
                                onLoad: { duration: 1000 }
                            }}
                            cornerRadius={{ top: 3 }}
                            style={{ data: { fill: '#0D1B37' } }}
                            barWidth={6}
                            data={weeklyStats?.currentWeekStats}
                        />
                        <VictoryBar
                            animate={{
                                duration: 2000,
                                onLoad: { duration: 1000 }
                            }}
                            cornerRadius={{ top: 3 }}
                            style={{ data: { fill: '#b695f8' } }}
                            barWidth={6}
                            data={weeklyStats?.lastWeekStats}
                        />
                    </VictoryGroup>
                </VictoryChart>
            </div>
        </div>
    );
};
