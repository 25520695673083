import React from 'react';

const InboxIcon = ({ className }) => {
    return (
        <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M19 9.09998H13.6L11.8 11.8H8.2L6.4 9.09998H1"
                stroke="#B695F8"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.123 2.071L1 9.1V13.6C1 14.0774 1.18964 14.5352 1.52721 14.8728C1.86477 15.2104 2.32261 15.4 2.8 15.4H17.2C17.6774 15.4 18.1352 15.2104 18.4728 14.8728C18.8104 14.5352 19 14.0774 19 13.6V9.1L15.877 2.071C15.7357 1.75211 15.5049 1.48112 15.2125 1.29099C14.9201 1.10086 14.5788 0.999762 14.23 1H5.77C5.42123 0.999762 5.07989 1.10086 4.7875 1.29099C4.49511 1.48112 4.26425 1.75211 4.123 2.071Z"
                stroke="#B695F8"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default InboxIcon;
