import React from 'react';

export const BgAddIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="50" height="50" rx="25" fill="black" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.0001 13.334C23.9262 13.334 23.0556 14.2045 23.0556 15.2784V23.0586H15.2771C14.2032 23.0586 13.3326 23.9291 13.3326 25.003C13.3326 26.0769 14.2032 26.9475 15.2771 26.9475H23.0556V34.7229C23.0556 35.7968 23.9262 36.6673 25.0001 36.6673C26.074 36.6673 26.9445 35.7968 26.9445 34.7229V26.9475H34.7215C35.7954 26.9475 36.666 26.0769 36.666 25.003C36.666 23.9292 35.7954 23.0586 34.7215 23.0586H26.9445V15.2784C26.9445 14.2045 26.074 13.334 25.0001 13.334Z"
                    fill="black"
                />
            </svg>
        </div>
    );
};
