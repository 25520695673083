import React from 'react';

export const ReplyIcon = ({ className }) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="#B695F8"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_21471_1953)">
                <path d="M20.5625 9.33451H2.87295L6.84267 5.36478C7.12347 5.0741 7.12347 4.61331 6.84267 4.32272C6.55492 4.02481 6.08013 4.01648 5.78222 4.30432L0.532215 9.55432C0.532124 9.55432 0.532215 9.55423 0.532215 9.55432C0.23943 9.84711 0.239338 10.3221 0.532215 10.6148L5.78222 15.8648C5.92266 16.0057 6.11355 16.0848 6.31249 16.0845C6.51134 16.0845 6.70205 16.0055 6.84258 15.8649C7.13555 15.572 7.13555 15.0971 6.84277 14.8042L2.87295 10.8345H20.5625C21.8046 10.8359 22.8111 11.8424 22.8125 13.0845V19.8353C22.8128 20.2493 23.1485 20.5848 23.5625 20.5845C23.9764 20.5842 24.3128 20.2485 24.3125 19.8345V13.0845C24.3106 11.0142 22.6328 9.33643 20.5625 9.33451Z" />
            </g>
            <defs>
                <clipPath id="clip0_21471_1953">
                    <rect width="24" height="24" fill="white" transform="translate(0.3125 0.09375)" />
                </clipPath>
            </defs>
        </svg>
    );
};
