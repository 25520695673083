import React from 'react';

export const AttachmentIcon2 = ({ className }) => {
    return (
        <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M11 6.5V11.5C11 12.8261 10.4732 14.0979 9.53553 15.0355C8.59785 15.9732 7.32608 16.5 6 16.5C4.67392 16.5 3.40215 15.9732 2.46447 15.0355C1.52678 14.0979 1 12.8261 1 11.5V4.83333C1 3.94928 1.35119 3.10143 1.97631 2.47631C2.60143 1.85119 3.44928 1.5 4.33333 1.5C5.21739 1.5 6.06523 1.85119 6.69036 2.47631C7.31548 3.10143 7.66667 3.94928 7.66667 4.83333V11.5C7.66667 11.942 7.49107 12.366 7.17851 12.6785C6.86595 12.9911 6.44203 13.1667 6 13.1667C5.55797 13.1667 5.13405 12.9911 4.82149 12.6785C4.50893 12.366 4.33333 11.942 4.33333 11.5V4.83333"
                stroke="#656565"
                strokeWidth="1.15385"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
