import Pagination from 'components/pagination';
import React from 'react';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';

export const OfflineClientsTable = ({
    users,
    page,
    pageSize,
    count,
    handleChangePage,
    handleDeletionId,
    handleSelectedAll,
    handleSelectId,
    selectedIds
}) => {
    return (
        <div className="overflow-x-auto">
            {' '}
            <div className="mt-16 w-[150%] md:w-[125%] lg:w-[100%]">
                <div className="grid grid-cols-9 gap-5 buttons-font font-poppins">
                    <div className="col-span-2 flex items-center gap-10">
                        <input
                            type="checkbox"
                            className="!rounded-[3px] bg-body"
                            onChange={(e) => handleSelectedAll(e)}
                            checked={selectedIds?.length === users?.length}
                        />
                        <span>Reference Num</span>
                    </div>
                    <div className="flex items-center">Client Structure</div>
                    <div className="flex items-center">Display Name</div>
                    <div className="flex items-center">Full Name</div>
                    <div className="flex items-center">File Name</div>
                    <div className="flex items-center">Mobile</div>

                    <div className="flex items-center">Email</div>

                    <div className="flex items-center">Action</div>
                </div>
                <div className="mt-3">
                    <hr />
                </div>
                {users?.map((user, index) => (
                    <div key={'user' + index} className="">
                        <div className="grid grid-cols-9 gap-5 mt-3 buttons-font-lighter font-poppins">
                            <div className="col-span-2 flex gap-10 items-center">
                                <input
                                    type="checkbox"
                                    className="!rounded-[3px] bg-body"
                                    onChange={(_e) => handleSelectId(user?.business_client_id)}
                                    checked={selectedIds?.includes(user?.business_client_id)}
                                />
                                {user?.client_ref_no}
                            </div>
                            <div className="flex items-center">{user?.client_category}</div>
                            <div className="flex items-center">{user?.display_name}</div>
                            <div className="flex items-center break-all">
                                {user?.first_name || '-'} {user?.last_name || '-'}
                            </div>
                            <div className="flex items-center break-all">{user?.file_name || '--'}</div>
                            <div className="flex items-center break-all">{user?.phone_number}</div>
                            <div className="flex items-center break-all">{user?.email}</div>

                            <div
                                onClick={() => handleDeletionId(user?.business_client_id)}
                                className="flex gap-2 items-center cursor-pointer "
                            >
                                <BgDeleteIcon />
                            </div>
                        </div>
                        <div className="mt-3">
                            <hr />
                        </div>
                    </div>
                ))}

                <div className="md:mx-10 mx-5">
                    <Pagination
                        page={Number(page)}
                        pageSize={Number(pageSize)}
                        totalPages={Math.ceil(count / pageSize)}
                        onChangePage={(page) => handleChangePage(page, Number(pageSize))}
                        onCountPerPageChange={(perPageCount) => handleChangePage(Number(page), perPageCount)}
                    />
                </div>
            </div>
        </div>
    );
};
