import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toastHandler } from 'responseHanlder';
import { handleEditAnnouncement, setLoader } from 'store/global/globalReducer';
import { archiveNewsThunk } from 'store/workspace/workspaceNews';
import ConfirmationModal from 'utills/confirmationModal';
import { BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE, handleDownload } from 'utills/globalVars';
import { formateDate } from 'utills/moment';
import { EyeIcon } from '../../../utills/svgs/EyeIcon';
import { ANnSkeleton } from './ann-skeleton/AnnSkeleton';
import { NoDataFound } from 'components/common/NoDataFound';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import useRoleGuard from 'Hooks/useRoleGuard';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import Badge from 'components/atoms/Badge';
import pdfIcon from 'files/pdfIcon.svg';
import TruncateDescription from 'global-components/TruncateDescription';
import { FilesIcon } from 'utills/svgs/FilesIcon';
import Pagination from 'components/pagination';

const AnnouncementList = ({ toggleAnnUpdate, data, toggleEditAnn, page, pageSize, count, handleChangePage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: loginData } = useSelector((state) => state.login);
    const allPermissions = JSON.parse(localStorage.getItem('allPermissions'));
    const [open, setOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [showImage, setShowImage] = useState(null);

    const { hasPermissions } = useRoleGuard();

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [idTodelete, setidTodelete] = useState('');
    const { isLoading } = useSelector((state) => state.global);
    const handleDelete = () => {
        if ((allPermissions?.Announcements?.write || !loginData?.is_employee) && idTodelete) {
            const delPayload = {
                news_id: idTodelete,
                business_id
            };

            dispatch(setLoader(true));
            dispatch(archiveNewsThunk(delPayload))
                .then((response) => {
                    if (response.payload) {
                        toggleAnnUpdate();
                        setIsConfirmationOpen(false);
                        setidTodelete('');
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        } else {
            toastHandler("You don't have permission to access this page", ERROR_TYPE_ERROR);
        }
    };
    const archiveNews = (news_id, createdBy) => {
        if (hasPermissions('Announcements', ['admin'], createdBy)) {
            setidTodelete(news_id);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    //toggleEditAnn
    const editAnn = async (ann) => {
        if (hasPermissions('Announcements', ['admin'], ann?.created_by_id)) {
            dispatch(handleEditAnnouncement(ann?.news_id));

            toggleEditAnn();
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };
    return (
        <div className=" mx-10 mb-5 mt-5 text-black">
            {open && pdfUrl ? <GlobalPDFViewer url={pdfUrl} setOpen={setOpen} open={open} /> : ''}{' '}
            {showImage && <ZoomImage src={showImage} alt="snow" onClose={() => setShowImage(null)} />}
            {isLoading ? (
                <ANnSkeleton />
            ) : (
                <div>
                    {data && data?.rows?.length <= 0 ? (
                        <NoDataFound message="No Announcement Found" height="30vh" />
                    ) : (
                        <div className="ann-list pb-10">
                            <div className="font-semibold text-lg">Latest Announcements</div>
                            <div className="flex flex-col gap-5 mt-3">
                                {data?.rows?.map((ann, index) => (
                                    <div
                                        key={'rows' + index}
                                        className="p-2 py-3 bg-white rounded-xl border hover:shadow-md hover:border-thirdbg ease-in-out duration-200"
                                    >
                                        <div className={`flex `}>
                                            {ann.attachments?.length > 0 &&
                                                ann?.attachments[0]?.file_ext?.startsWith('image') && (
                                                    <div
                                                        className="p-1"
                                                        onClick={() =>
                                                            navigate(`/announcement/details/${ann?.news_id}`)
                                                        }
                                                    >
                                                        <div>
                                                            <div className="ann-img-wrapper">
                                                                <img
                                                                    src={ann?.attachments[0].url}
                                                                    alt="announcementImg"
                                                                    className="ann-img"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="flex-1">
                                                <div className="p-2">
                                                    <div className="flex items-center justify-between">
                                                        <div className="primary-fonts text-md"> {ann?.title} </div>
                                                    </div>
                                                    <div className="ann-desc">
                                                        <TruncateDescription text={ann?.description} maxLength={400} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-end justify-between p-3">
                                                <div className="flex justify-between flex-col ">
                                                    {' '}
                                                    <div className="flex justify-end h-full items-center gap-2">
                                                        {' '}
                                                        <div
                                                            onClick={() => {
                                                                editAnn(ann);
                                                            }}
                                                            className="cursor-pointer"
                                                        >
                                                            {' '}
                                                            <BgEditIcon />
                                                        </div>
                                                        <div
                                                            onClick={() =>
                                                                navigate(`/announcement/details/${ann?.news_id}`)
                                                            }
                                                            className="cursor-pointer"
                                                        >
                                                            <EyeIcon />
                                                        </div>
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                archiveNews(ann?.news_id, ann?.created_by_id)
                                                            }
                                                        >
                                                            <BgDeleteIcon />
                                                        </div>{' '}
                                                    </div>{' '}
                                                </div>
                                                <div>
                                                    {' '}
                                                    <div className=" flex flex-col gap-1 items-end ann-info text-xs justify-end">
                                                        <div className="">{formateDate(ann?.created_at)}</div>
                                                        <div className="ann-by">
                                                            By <span className="ann-auther">{ann?.created_by}</span>
                                                        </div>
                                                    </div>{' '}
                                                </div>{' '}
                                            </div>
                                        </div>

                                        {ann?.attachments?.length > 0 && (
                                            <div className="ann-btns  mt-2 flex gap-2 flex-wrap">
                                                {ann?.attachments?.map((att, index) => (
                                                    <div key={'ann_attachments' + index}>
                                                        <div>
                                                            <Badge
                                                                leftIcon={
                                                                    att.file_type === 'news_images' ||
                                                                    att?.url?.toLowerCase()?.endsWith('.pdf') ? (
                                                                        <img
                                                                            src={
                                                                                att.file_type === 'news_images'
                                                                                    ? att.url
                                                                                    : pdfIcon
                                                                            }
                                                                            alt="alt"
                                                                            className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md"
                                                                            onClick={() => {
                                                                                if (att.file_type === 'news_images') {
                                                                                    setShowImage(att.url);
                                                                                } else {
                                                                                    if (
                                                                                        att?.url
                                                                                            ?.toLowerCase()
                                                                                            ?.endsWith('.pdf')
                                                                                    ) {
                                                                                        setPdfUrl(att?.url);
                                                                                        setOpen(true);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <div onClick={() => handleDownload(att)}>
                                                                            <FilesIcon
                                                                                className={
                                                                                    'stroke-white !w-10 !h-10 mr-2 cursor-pointer'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                text={att.name}
                                                                sx="text-white bg-third rounded-md !px-3 !text-md"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className="md:mx-10 mx-5">
                <Pagination
                    page={Number(page)}
                    pageSize={Number(pageSize)}
                    totalPages={Math.ceil(count / pageSize)}
                    onChangePage={(page) => handleChangePage(page, Number(pageSize))}
                    onCountPerPageChange={(perPageCount) => handleChangePage(Number(page), perPageCount)}
                />
            </div>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
        </div>
    );
};

export default AnnouncementList;
