/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useCsvDownload } from 'Hooks/Files/useCsvDownload';
import { endpoint } from 'apiEndpoints';
import { Button } from 'components/atoms/buttons/Button';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { NoDataFound } from 'components/common/NoDataFound';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { DefaultDialog } from 'components/molecules/DefaultDialog';

import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';

import { Jumbotron } from 'components/organisms/Jumbotron';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { SidebarLayout } from 'layouts/SidebarLayout';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getinvitedFilesThunk, uploadBulkClientInviteFileThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID } from 'utills/globalVars';
import { formatDefaultDate } from 'utills/moment';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { PlaneIcon } from 'utills/svgs/PlaneIcon';
import { SideTimes } from 'utills/svgs/SideTimes';

export const BulkClientInvite = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: files } = useSelector((state) => state?.getinvitedFiles);
    const { isLoading } = useSelector((state) => state.global);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [modal, setModal] = useState(false);
    const { downloadCsv, fileLoader } = useCsvDownload();
    const [uploadLoader, setUploadLoader] = useState(false);
    const [open, setOpen] = useState(false);

    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings`);
        }
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };

    const acceptFormate = ['text/csv'];
    const handleClearFiles = () => {
        setSelectedFiles([]);
    };

    const handleDownloadTemplate = (isFirst) => {
        downloadCsv({ business_client_id: id }, endpoint.downloadBulkInviteTemplate, 'GET', isFirst, 'template');
    };

    const handleGetUploadedFiles = () => {
        //getinvitedFilesThunk

        const payload = {
            business_id: business_id
        };

        dispatch(setLoader(true));
        dispatch(getinvitedFilesThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleSubmit = () => {
        //uploadBulkClientInviteFileThunk
        const formData = new FormData();
        selectedFiles?.forEach((file) => {
            formData?.append('client-invite', file);
        });

        setUploadLoader(true);
        dispatch(uploadBulkClientInviteFileThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setTimeout(() => {
                        handleGetUploadedFiles();
                    }, 1000);

                    setSelectedFiles([]);
                    setModal(!modal);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setUploadLoader(false);
            });
    };

    const handleDownloadFailedEntry = (cif_id, filename) => {
        //downloadFailedEntry
        downloadCsv({ cif_id, business_client_id: id }, endpoint.downloadFailedEntry, 'POST', false, filename);
    };
    useEffect(() => {
        handleGetUploadedFiles();
    }, []);
    return (
        <div>
            <SidebarLayout>
                <Jumbotron
                    title="Bulk invite via CSV"
                    backArrowProp={backArrowProp}
                    path={[
                        { title: 'Settings', link: 'settings' },
                        {
                            title: 'Bulk invite via CSV',
                            link: ''
                        }
                    ]}
                />

                {open && (
                    <GlobalPDFViewer
                        url={
                            'https://stg-api.synkli.dev/files/employee?id=em/b26032/nd/1712633334991/TestingMultiple.pdf'
                        }
                        setOpen={setOpen}
                        open={open}
                    />
                )}

                {modal && (
                    <DefaultDialog
                        children={
                            <div className="relative">
                                <div className="absolute right-0 top-0 cursor-pointer" onClick={() => setModal(!modal)}>
                                    <SideTimes />
                                </div>
                                <div className="flex justify-center items-center pt-12">
                                    <PlaneIcon />
                                </div>

                                <div className=" mt-4 table-title text-blueish font-poppins flex justify-center items-center">
                                    Congratulation!
                                </div>
                                <div className="buttons-font-lighter font-poppins text-center mt-3 mx-5">
                                    Your file has been uploaded successfully
                                </div>

                                <div className="mx-10 my-8">
                                    <Button
                                        atClick={() => setModal(!modal)}
                                        title="Ok"
                                        classes="ann-btn rounded-xl buttons-font font-fira w-full flex justify-center items-center"
                                    />
                                </div>
                            </div>
                        }
                        p="!rounded-2xl !p-2"
                        open={modal}
                        setOpen={setModal}
                        width="md:max-w-[350px]"
                    />
                )}

                <RoleBasedGuard module={'Clients'} permissions={['write', 'admin']} sx="h-[50vh]">
                    <div className="mx-5 md:mx-10">
                        <div className="bg-white rounded-md p-5">
                            <div className="xl-title font-poppins">Prepare your CSV file</div>
                            <div className="buttons-font-lighter font-poppins mt-4">
                                Before uploading the CSV file, ensure it contains the following required fields: First
                                Name, Last Name, Email, and Phone Number. The Phone Number should include the country
                                code (e.g., +61) for accurate processing. If the CSV file contains missing or invalid
                                information, file upload will be failed.{' '}
                                <strong>Upto 1000 contacts can be imported each time.</strong>
                            </div>
                            <div className="mt-7  flex justify-center items-center">
                                <Button
                                    atClick={() => handleDownloadTemplate(true)}
                                    iconSet={{ leftIcon: CsvIcon, loader: SmallLoaderWhite }}
                                    disabled={fileLoader ? true : false}
                                    isloading={fileLoader}
                                    title="Download the CSV Template"
                                    classes="ann-btn rounded-lg px-3 flex gap-3 items-center buttons-font font-fira"
                                    iconInvert={true}
                                />
                            </div>
                        </div>
                        <div className="mt-7">
                            <div className="xl-title font-poppins">Upload CSV file</div>
                        </div>

                        <div className="mt-7">
                            <div className="mt-2">
                                {' '}
                                <DragFile
                                    selectedFiles={selectedFiles}
                                    atChange={(file) => {
                                        handleSelectedFiles(file);
                                    }}
                                    hideSelectedFiles
                                    accept={acceptFormate}
                                    iconSet={{ icon: CsvIcon }}
                                    iconScale={true}
                                    isMultiple={false}
                                    dragMessage="Click to upload file or you can drag and drop. Maximum file size should be 5MB"
                                    dropMessage="Drop the selected files in this box... "
                                    onlyPdf={true}
                                    message="Only CSV file is allowed"
                                    handleClearFiles={handleClearFiles}
                                />
                            </div>

                            {selectedFiles?.length > 0 && (
                                <div className="flex justify-between items-center flex-wrap mt-7">
                                    {' '}
                                    <div className="bg-thirdbg text-white small-text font-poppins flex justify-between items-center gap-3 w-[200px] p-3 rounded-xl ">
                                        <div className="invert">
                                            <CsvIcon />
                                        </div>
                                        <div>{selectedFiles[0]?.name}</div>
                                        <div className="cursor-pointer">
                                            {' '}
                                            <XCircleIcon
                                                className="color-white w-5 h-5"
                                                onClick={() => setSelectedFiles([])}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            iconSet={{ loader: SmallLoaderWhite }}
                                            isloading={uploadLoader}
                                            disabled={uploadLoader}
                                            title="Send Invitations"
                                            classes="ann-btn px-8 rounded-lg buttons-font-lighter font-fira"
                                            atClick={() => handleSubmit()}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="mb-10">
                                <div className="mt-7 ">
                                    <div className="xl-title font-poppins">Activity</div>
                                </div>

                                <div className="mt-5 ">
                                    <div className="grid grid-cols-5 gap-5 buttons-font-lg font-poppins">
                                        <div>File Name</div>
                                        <div>Date</div>
                                        <div>Uploaded By</div>
                                        <div>Status</div>
                                        <div>Action</div>
                                    </div>

                                    <hr className="mt-3 !bg-[#BBBBBB]" />

                                    {!isLoading && files?.length < 1 ? (
                                        <NoDataFound message="No Files Found!" height="30vh" />
                                    ) : (
                                        <div>
                                            {files &&
                                                files?.map((file, index) => (
                                                    <div className="mt-3" key={index}>
                                                        {' '}
                                                        <div className="grid grid-cols-5 gap-5 buttons-font-lighter font-poppins">
                                                            <div className="flex items-center break-all">
                                                                {file?.tbl_client_invitation_files_file_name}
                                                            </div>
                                                            <div className="flex items-center break-all">
                                                                {formatDefaultDate(
                                                                    file?.tbl_client_invitation_files_created_at
                                                                )}
                                                            </div>
                                                            <div className="flex items-center break-all">
                                                                {' '}
                                                                {file?.tbl_users_first_name} {file?.tbl_users_last_name}
                                                            </div>
                                                            <div
                                                                className={`flex items-center break-all ${
                                                                    file?.tbl_client_invitation_files_status ===
                                                                    'successful'
                                                                        ? 'text-[#008C51]'
                                                                        : file?.tbl_client_invitation_files_status ===
                                                                          'pending'
                                                                        ? 'text-thirdbg'
                                                                        : file?.tbl_client_invitation_files_status ===
                                                                          'failed'
                                                                        ? 'text-[#F14747]'
                                                                        : file?.tbl_client_invitation_files_status ===
                                                                          'in_progress'
                                                                        ? 'text-[#F08201]'
                                                                        : 'text-black'
                                                                }`}
                                                            >
                                                                {file?.tbl_client_invitation_files_status ===
                                                                'successful'
                                                                    ? 'Successful'
                                                                    : file?.tbl_client_invitation_files_status ===
                                                                      'pending'
                                                                    ? 'Pending'
                                                                    : file?.tbl_client_invitation_files_status ===
                                                                      'failed'
                                                                    ? 'Failed'
                                                                    : file?.tbl_client_invitation_files_status ===
                                                                      'in_progress'
                                                                    ? 'In Progress'
                                                                    : file?.tbl_client_invitation_files_status}
                                                            </div>
                                                            <div className="flex items-center">
                                                                {file?.tbl_client_invitation_files_status ===
                                                                    'failed' && (
                                                                    <Button
                                                                        title="Download errored file"
                                                                        atClick={() =>
                                                                            handleDownloadFailedEntry(
                                                                                file?.tbl_client_invitation_files_cif_id,
                                                                                file?.tbl_client_invitation_files_file_name
                                                                            )
                                                                        }
                                                                        classes="bg-linear rounded-lg px-5 text-white font-fira small-text py-2"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <hr className="mt-3 !bg-[#BBBBBB]" />
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
