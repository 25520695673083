import Input from 'components/atoms/Input';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { InputField } from 'components/atoms/inputs/InputField';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { DescriptionInput } from 'components/molecules/DescriptionInput';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { addLeadThunk } from 'store/leads';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import cloneDeep from 'lodash/cloneDeep';
import { validateEmailValue } from 'utills/dataValidation';
import { validatePhoneNumber } from 'utills/baseValidations';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
export const CreateLead = ({ open, setOpen, handleResetAndGet, toggleCreateTask, isDashboard, data, setData }) => {
    const dispatch = useDispatch();
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [createTaskItem, setCreateTaskItem] = useState(false);
    const [message, setMessage] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCreate = (e) => {
        e.preventDefault();

        if (phoneError) {
            return toastHandler('Phone number is required', TOAST_TYPE_ERROR);
        }

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        setLoading(true);

        const payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNo,
            message
        };

        dispatch(addLeadThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (isDashboard) {
                        const newLeads = cloneDeep(data);
                        newLeads.unshift(response.payload.data);
                        setData(newLeads);

                        setOpen(false);
                        return;
                    }
                    if (createTaskItem) {
                        toggleCreateTask(response?.payload?.data.lead_id);
                    }
                    handleResetAndGet();
                    setOpen(false);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePhoneNo = (value) => {
        let isValid = false;

        setPhoneNo(value);
        const parsedPhoneNumber = parsePhoneNumberFromString(value);

        if (parsedPhoneNumber) {
            isValid = parsedPhoneNumber.isValid();
        } else {
            console.error('Invalid phone number');
        }

        if (!isValid || value?.length < 1) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    };

    return (
        <Slideover
            open={open}
            title={'Create Lead'}
            titlesx={'pl-3 -mt-0 xl-title font-fira'}
            hideBackArrow
            setOpen={setOpen}
        >
            <RoleBasedGuard module="Leads" permissions={['admin']} sx="p-5">
                <form onSubmit={handleCreate}>
                    {' '}
                    <div className="px-3 mt-10">
                        {' '}
                        <div className="default-forms">
                            <div className="">
                                {' '}
                                <InputField
                                    label="First Name"
                                    inputValue={firstName}
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    maxLength={50}
                                    allowSpaces={false}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    classes="text-sm mt-2 "
                                    labelSx=""
                                    required={true}
                                />
                            </div>
                            <div className="mt-4">
                                <InputField
                                    label="Last Name"
                                    inputValue={lastName}
                                    name="firstName"
                                    placeholder="Enter your first name"
                                    maxLength={50}
                                    allowSpaces={true}
                                    onChange={(e) => setLastname(e.target.value)}
                                    classes="mt-2"
                                    labelSx=""
                                    required={true}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="mt-4">
                                {' '}
                                <Input
                                    useWithoutForm={true}
                                    onChange={(e) => setEmail(e.target.value)}
                                    title="Email"
                                    placeholder="Enter email"
                                    name="title"
                                    titleSx=""
                                    type="email"
                                    sx="!rounded-xl !py-3 mt-2"
                                    required={true}
                                    maxLength={100}
                                />
                            </div>
                            <div className="mt-4">
                                <PhoneInputComponent
                                    title="Phone Number"
                                    placeholder="Enter Phone Number"
                                    titleSx=""
                                    sx="!rounded-xl"
                                    atChange={(phone) => handlePhoneNo(phone)}
                                    value={phoneNo}
                                />
                            </div>

                            <div className="mt-4 default-forms">
                                <label>Message</label>

                                <div className="mt-2">
                                    <DescriptionInput
                                        placeholder="Message"
                                        onChange={(e) => setMessage(e)}
                                        value={message}
                                        maxCount={250}
                                    />
                                </div>
                            </div>
                        </div>
                        {isDashboard ? (
                            ''
                        ) : (
                            <div className="mt-4 flex gap-2 items-center">
                                <div>
                                    <input
                                        type="checkbox"
                                        className="rounded-[3px]"
                                        onChange={(e) => setCreateTaskItem(e.target.checked)}
                                    />
                                </div>
                                <div className="buttons-font font-poppins ">I would like to create a task</div>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className="mt-10 flex justify-center items-center">
                            {' '}
                            <Button
                                type="submit"
                                title={createTaskItem ? 'Next' : 'Create'}
                                classes="ann-btn rounded-lg px-20  buttons-font-lighter font-fira "
                                isloading={loading}
                                disabled={loading}
                                iconSet={{ loader: SmallLoaderWhite }}
                            />
                        </div>
                    </div>
                </form>
            </RoleBasedGuard>
        </Slideover>
    );
};
