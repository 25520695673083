import React from 'react';

export const BgDateIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="20" fill="url(#paint0_linear_4340_438)" />
                <path
                    d="M28.9061 12.5647H26.8749V13.8467H28.7499V27.9487H11.2501V13.8467H13.1251V12.5647H11.0939C10.9478 12.5672 10.8036 12.5992 10.6696 12.6589C10.5355 12.7185 10.4143 12.8047 10.3127 12.9124C10.2111 13.0202 10.1313 13.1474 10.0776 13.2868C10.024 13.4262 9.99768 13.5751 10.0002 13.7249V28.0705C9.99768 28.2203 10.024 28.3692 10.0776 28.5086C10.1313 28.648 10.2111 28.7752 10.3127 28.883C10.4143 28.9907 10.5355 29.0769 10.6696 29.1365C10.8036 29.1962 10.9478 29.2282 11.0939 29.2307H28.9061C29.0522 29.2282 29.1964 29.1962 29.3304 29.1365C29.4645 29.0769 29.5857 28.9907 29.6873 28.883C29.7889 28.7752 29.8687 28.648 29.9224 28.5086C29.976 28.3692 30.0023 28.2203 29.9998 28.0705V13.7249C30.0023 13.5751 29.976 13.4262 29.9224 13.2868C29.8687 13.1474 29.7889 13.0202 29.6873 12.9124C29.5857 12.8047 29.4645 12.7185 29.3304 12.6589C29.1964 12.5992 29.0522 12.5672 28.9061 12.5647Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.4"
                />
                <path d="M13.75 17.6921H15V18.9741H13.75V17.6921Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M17.5 17.6921H18.75V18.9741H17.5V17.6921Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M21.25 17.6921H22.5V18.9741H21.25V17.6921Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M25 17.6921H26.25V18.9741H25V17.6921Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M13.75 20.8967H15V22.1787H13.75V20.8967Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M17.5 20.8967H18.75V22.1787H17.5V20.8967Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M21.25 20.8967H22.5V22.1787H21.25V20.8967Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M25 20.8967H26.25V22.1787H25V20.8967Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M13.75 24.1013H15V25.3833H13.75V24.1013Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M17.5 24.1013H18.75V25.3833H17.5V24.1013Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M21.25 24.1013H22.5V25.3833H21.25V24.1013Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path d="M25 24.1013H26.25V25.3833H25V24.1013Z" fill="white" stroke="white" strokeWidth="0.4" />
                <path
                    d="M15 15.128C15.1657 15.128 15.3247 15.0605 15.4419 14.9403C15.5591 14.8201 15.625 14.657 15.625 14.487V10.641C15.625 10.471 15.5591 10.308 15.4419 10.1877C15.3247 10.0675 15.1657 10 15 10C14.8342 10 14.6753 10.0675 14.5581 10.1877C14.4408 10.308 14.375 10.471 14.375 10.641V14.487C14.375 14.657 14.4408 14.8201 14.5581 14.9403C14.6753 15.0605 14.8342 15.128 15 15.128Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.4"
                />
                <path
                    d="M25 15.128C25.1657 15.128 25.3247 15.0605 25.4419 14.9403C25.5591 14.8201 25.625 14.657 25.625 14.487V10.641C25.625 10.471 25.5591 10.308 25.4419 10.1877C25.3247 10.0675 25.1657 10 25 10C24.8342 10 24.6753 10.0675 24.5581 10.1877C24.4408 10.308 24.375 10.471 24.375 10.641V14.487C24.375 14.657 24.4408 14.8201 24.5581 14.9403C24.6753 15.0605 24.8342 15.128 25 15.128Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.4"
                />
                <path
                    d="M16.875 12.5647H23.1249V13.8467H16.875V12.5647Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.4"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_4340_438"
                        x1="21.9626"
                        y1="0.49676"
                        x2="21.9327"
                        y2="40.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
