import React from 'react';

export const WebpFile = ({ className }) => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#0C7785"
            />
            <rect y="9" width="34" height="16.79" rx="4" fill="white" />
            {/* <mask
                id="mask0_15913_43"
                style="mask-type:alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="9"
                width="34"
                height="17"
            >
                <rect y="9" width="34" height="16.79" rx="4" fill="white" />
            </mask> */}
            <g mask="url(#mask0_15913_43)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.33876 13.9851L5.36943 18.6727L6.61572 13.9851H8.25659L9.45328 18.7525L10.5012 13.9851H11.8876L10.2295 20.8117H8.76761L7.40704 15.708L6.05295 20.8117H4.5587L2.92861 13.9851H4.33876ZM12.5927 20.8117V13.9851H17.6533V15.1387H13.9705V16.6524H17.3967V17.8016H13.9705V19.6603H17.7849V20.8117H12.5927ZM18.9557 13.9851H21.6854C22.2266 13.9851 22.6299 14.0067 22.8972 14.052C23.1624 14.0973 23.3996 14.19 23.6088 14.3345C23.8179 14.4768 23.9904 14.6644 24.1306 14.9037C24.2707 15.1409 24.3419 15.4082 24.3419 15.7015C24.3419 16.0206 24.2556 16.316 24.0831 16.5834C23.9106 16.8507 23.6756 17.0491 23.3824 17.1828C23.7964 17.3057 24.1176 17.5105 24.3419 17.8016C24.564 18.0949 24.6761 18.4377 24.6761 18.8323C24.6761 19.1428 24.6049 19.4447 24.4605 19.7379C24.316 20.0311 24.1176 20.2662 23.8697 20.4408C23.6195 20.6155 23.3112 20.7233 22.9425 20.7664C22.7139 20.7901 22.1598 20.8052 21.2801 20.8117H18.9557V13.9851ZM25.8404 20.8117V13.9851H28.0527C28.8915 13.9851 29.437 14.0196 29.6914 14.0865C30.0817 14.1878 30.4116 14.4121 30.6747 14.7549C30.9399 15.0977 31.0714 15.5419 31.0714 16.0831C31.0714 16.5036 30.9959 16.8572 30.8428 17.1418C30.6897 17.4286 30.4978 17.6507 30.2628 17.8146C30.0299 17.9763 29.7906 18.0862 29.5491 18.138C29.2192 18.2027 28.7427 18.2372 28.1195 18.2372H27.2204V20.8117H25.8404ZM4.30642 25.7947H34.4114C34.7369 25.7947 35 25.5338 35 25.2082V9.58864C35 9.26306 34.7369 9 34.4114 9H-0.411356C-0.736943 9 -1 9.26306 -1 9.58864V25.2082C-1 25.5338 -0.736943 25.7947 -0.411356 25.7947H4.30642Z"
                    fill="#0C7785"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.3379 15.1216V16.6999H21.2392C21.7782 16.6999 22.1103 16.6913 22.2418 16.6762C22.4768 16.6482 22.6623 16.5662 22.7981 16.4326C22.934 16.2967 22.9986 16.1177 22.9986 15.8978C22.9986 15.6865 22.9426 15.5162 22.8262 15.3825C22.7076 15.251 22.5351 15.1712 22.3065 15.1453C22.1685 15.1281 21.7782 15.1216 21.1292 15.1216H20.3379Z"
                    fill="#0C7785"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.4483 17.8359H20.3379V19.6601H21.6122C22.1081 19.6601 22.4229 19.6471 22.5566 19.6191C22.7636 19.5825 22.9296 19.4897 23.0569 19.3474C23.1884 19.203 23.2531 19.0089 23.2531 18.7674C23.2531 18.5626 23.2013 18.3879 23.1021 18.2456C23.003 18.1033 22.8607 17.9977 22.6709 17.933C22.4855 17.8683 22.0758 17.8359 21.4483 17.8359Z"
                    fill="#0C7785"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.2207 15.1387V17.0749H27.9732C28.5166 17.0749 28.881 17.0404 29.0643 16.9693C29.2475 16.8981 29.3898 16.786 29.4955 16.6351C29.599 16.482 29.6507 16.3052 29.6507 16.1025C29.6507 15.8545 29.5774 15.6497 29.4308 15.488C29.2863 15.3263 29.1009 15.2249 28.8788 15.1861C28.7128 15.1538 28.3829 15.1387 27.887 15.1387H27.2207Z"
                    fill="#0C7785"
                />
            </g>
        </svg>
    );
};
