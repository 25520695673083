import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Ripples from 'react-ripples';
import 'react-toastify/dist/ReactToastify.css';
import { toastHandler } from 'responseHanlder';
import { addBranchLocationThunk } from 'store/auth/slices';
import { setSideLoader } from 'store/global/globalReducer';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';
import { validateEmail } from '../../../utills/FormValidation';
import { PickAddress } from 'global-components/PickAddress';
import Slideover from 'components/atoms/SlideOver';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import MapComponent from 'components/atoms/MapComponent';
import { validateEmailValue } from 'utills/dataValidation';
import { validatePhoneNumber } from 'utills/baseValidations';

const AddBranchLocation = ({ open, setOpen, toggleBranchLocation, toggleServiceUpdated, togglebranchesUpdated }) => {
    const dispatch = useDispatch();

    const [address, setAddress] = useState('');
    const [mapValues, setMapValues] = useState(null);
    const [formData, setFormData] = useState({
        branchTitle: '',
        phoneNumber: '',
        email: ''
    });
    const [emailError, setEmailError] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'email') {
            if (!validateEmail(value)) {
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        const hasEmptyValue = Object.values(formData).some((value) => {
            return (
                value === '' || value === null || value === undefined || (Array.isArray(value) && value.length === 0)
            );
        });
        if (hasEmptyValue) {
            if (!address) {
                toastHandler('Please fill in all required fields.', TOAST_TYPE_ERROR);
                return;
            }
            return toastHandler('Please fill in all required fields.', TOAST_TYPE_ERROR);
        }

        if (formData?.email) {
            if (!validateEmailValue(formData.email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (formData.phoneNumber) {
            if (!validatePhoneNumber(formData.phoneNumber)) {
                return false;
            }
        }

        const branchObj = {
            title: formData.branchTitle,
            phone_number: formData.phoneNumber,
            email: formData.email.trim(),
            address: address,
            lat: (mapValues && mapValues?.geometry?.location?.lat()?.toString()) || '0',
            lng: (mapValues && mapValues?.geometry?.location?.lng()?.toString()) || '0',
            formatted_address: (mapValues && mapValues?.formatted_address) || ''
        };

        dispatch(setSideLoader(true));
        dispatch(addBranchLocationThunk(branchObj))
            .then((response) => {
                if (response.payload) {
                    toggleServiceUpdated();
                    toggleBranchLocation();
                    togglebranchesUpdated();
                    toastHandler('Branch location added successfully', 'success');
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const onSelect = (place) => {
        setAddress(place.formatted_address);
        setMapValues(place);
    };

    return (
        <>
            <Slideover open={open} setOpen={setOpen} showLoader title={'Add Branch Location'}>
                <div className="service-info p-5">
                    <div className="add-ann-form mt-3">
                        <div>
                            <div>
                                <label className="buttons-font font-poppins">Branch Title</label>
                            </div>
                            <div>
                                <input
                                    onChange={handleInputChange}
                                    name="branchTitle"
                                    type="text"
                                    maxLength={50}
                                    placeholder="Branch Title"
                                    className="px-3 rounded-xl inputs-font font-poppins mt-1"
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <div>
                                <label>Phone Number</label>
                            </div>
                            <div>
                                <PhoneInputComponent
                                    placeholder="Enter Phone Number"
                                    titleSx="buttons-font"
                                    sx="!rounded-xl  mt-1"
                                    atChange={(phone) =>
                                        handleInputChange({
                                            target: { name: 'phoneNumber', value: phone }
                                        })
                                    }
                                    required
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <div>
                                <label className="buttons-font">Email</label>
                            </div>
                            <div>
                                <input
                                    onChange={handleInputChange}
                                    name="email"
                                    type="email"
                                    placeholder="Email"
                                    className="px-3 rounded-xl font-poppins inputs-font mt-1"
                                />

                                {formData.email !== '' ? (
                                    <div className="error-div mt-1">{emailError && <span> Invalid Email </span>}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div className="mt-4">
                            <div>
                                <PickAddress
                                    onSelect={onSelect}
                                    address={address}
                                    setAddress={setAddress}
                                    id="branch-location"
                                />
                            </div>
                        </div>

                        {mapValues && (
                            <div className="mt-2">
                                <div>
                                    <label>Map</label>
                                </div>
                                <div>
                                    <MapComponent addressData={mapValues} className={'rounded-xl mt-2'} />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className=" flex justify-center items-center mt-20">
                        <Ripples during={2000} color="#333333">
                            <button
                                disabled={emailError}
                                onClick={handleSubmit}
                                className="px-20 py-2 ann-btn buttons-font-lighter font-fira rounded-md text-white"
                            >
                                Add
                            </button>
                        </Ripples>
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default AddBranchLocation;
