import React from 'react';

export const PhotoIconWhite = () => {
    return (
        <div>
            <svg width="27" height="28" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.71131 2.71131C3.16676 2.25587 3.78447 2 4.42857 2H21.5714C22.2155 2 22.8332 2.25587 23.2887 2.71131C23.7441 3.16676 24 3.78448 24 4.42857V15.7275L20.5642 12.2918C20.1744 11.9019 19.5426 11.9012 19.1518 12.29L14.7032 16.7164L6.84996 8.86321C6.45944 8.47268 5.82627 8.47268 5.43575 8.86321L2 12.299V4.42857C2 3.78447 2.25587 3.16676 2.71131 2.71131ZM0 14.7133V14.713V4.42857C0 3.25404 0.46658 2.12762 1.2971 1.2971C2.12762 0.46658 3.25404 0 4.42857 0H21.5714C22.746 0 23.8724 0.466581 24.7029 1.2971C25.5334 2.12762 26 3.25404 26 4.42857V18.1408V18.1427V21.5714C26 22.746 25.5334 23.8724 24.7029 24.7029C23.8724 25.5334 22.746 26 21.5714 26H4.42857C3.25404 26 2.12762 25.5334 1.2971 24.7029C0.466581 23.8724 0 22.746 0 21.5714V14.7133ZM24 18.556L19.8554 14.4113L16.1174 18.1306L21.9561 23.9694C22.4577 23.8889 22.9249 23.6524 23.2887 23.2887C23.7441 22.8332 24 22.2155 24 21.5714V18.556ZM19.1583 24H4.42857C3.78448 24 3.16676 23.7441 2.71131 23.2887C2.25587 22.8332 2 22.2155 2 21.5714V15.1274L6.14286 10.9845L19.1583 24ZM19.0033 8.71373C19.9501 8.71373 20.7176 7.94622 20.7176 6.99944C20.7176 6.05267 19.9501 5.28516 19.0033 5.28516C18.0566 5.28516 17.2891 6.05267 17.2891 6.99944C17.2891 7.94622 18.0566 8.71373 19.0033 8.71373Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
