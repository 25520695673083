import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import React, { useEffect, useState } from 'react';
import { COUNT_PER_PAGE } from 'utills/globalVars';

const Pagination = ({ page, pageSize, totalPages, onChangePage, onCountPerPageChange, scrollTo }) => {
    const [timeoutId, _setTimeoutId] = useState(null);
    const [goToV, setGoToV] = useState('');
    const [toggle, setToggle] = useState(false);

    const handleChange = (value = 1) => {
        setToggle(false);
        onChangePage(value !== '' && value > 1 ? (value < totalPages ? value : totalPages) : 1);
        if (scrollTo) {
            const element = document.getElementById(scrollTo);
            element.scrollIntoView();
        } else {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleChange(Number(goToV || 1));
        }
    };

    return (
        <div className="py-10 pagination flex !justify-center items-center md:px-5 xl:px-10 font-poppins">
            <div className="box-third !bg-primary text-white rounded-full w-full xl:w-fit md:px-3 py-1.5 shadow-xl">
                <div className="flex flex-inherit divide-x-2 md:gap-2 xl:gap-5 divide-gray-700">
                    <div className="flex md:gap-2 items-center pr-3 pl-2 md:pl-4">
                        <div className="flex gap-2 items-center">
                            <ChevronDownIcon onClick={() => setToggle(!toggle)} className="w-5 cursor-pointer" />
                            <div className="w-[40px] h-full">
                                <SimpleSelectMenu
                                    placeholder="Select"
                                    targetProperty="label"
                                    selectedValue={pageSize}
                                    valuePropertyName="label"
                                    optionsData={COUNT_PER_PAGE || []}
                                    sx="block !p-[5px] w-[40px] rounded-md border-0 !ring-0 text-gray-900 shadow-sm sm:text-sm sm:leading-6 cursor-pointer"
                                    showChipOnly={false}
                                    onChangeValue={(obj) => {
                                        setToggle(false);
                                        onCountPerPageChange(obj.label);
                                    }}
                                    showOnTop
                                    hideArrows
                                    defaultOpen={toggle}
                                />{' '}
                            </div>
                            <div className="text-md hidden lg:flex text-gray-300 font-normal truncate">
                                Items per page
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-1 items-center justify-between gap-3 px-1 md:px-3 xl:px-6">
                        <span
                            onClick={() => handleChange(1)}
                            className={'opacity-75 cursor-pointer text-gray-100 font-light'}
                            disabled={page === 1}
                        >
                            FIRST
                        </span>

                        <span
                            className={`flex items-stretch cursor-pointer`}
                            onClick={() => page > 1 && handleChange(page - 1)}
                        >
                            <ChevronLeftIcon className="w-5" /> PREV
                        </span>

                        {page > 2 && <div className="w-[25px] h-[25px] flex items-center justify-center">...</div>}

                        {page - 1 > 0 && (
                            <div
                                className="w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
                                onClick={() => handleChange(page - 1)}
                            >
                                {' '}
                                {page - 1}
                            </div>
                        )}
                        <div className="min-w-fit w-[25px] h-[25px] rounded-full text-black flex justify-center items-center bg-white cursor-pointer">
                            {page}
                        </div>

                        {page + 1 < totalPages && (
                            <div
                                className="w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
                                onClick={() => handleChange(page + 1)}
                            >
                                {page + 1}
                            </div>
                        )}

                        {page + 2 < totalPages && (
                            <div
                                className="w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
                                onClick={() => handleChange(page + 2)}
                            >
                                {page + 2}
                            </div>
                        )}

                        {totalPages > page + 1 && totalPages - 2 !== page && (
                            <div className="w-[25px] h-[25px] flex items-center justify-center">...</div>
                        )}

                        {totalPages > page && (
                            <div
                                className="w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
                                onClick={() => handleChange(totalPages)}
                            >
                                {totalPages}
                            </div>
                        )}

                        <span
                            onClick={() => page < totalPages && handleChange(page + 1)}
                            className={`flex items-stretch cursor-pointer`}
                        >
                            NEXT <ChevronRightIcon className="w-5" />
                        </span>
                        <span
                            onClick={() => page < totalPages && handleChange(totalPages)}
                            className={'opacity-75 cursor-pointer text-gray-100 font-light'}
                            disabled={page === totalPages}
                        >
                            LAST
                        </span>
                    </div>
                    <div className="flex items-center gap-2 px-3 xl:px-6">
                        <h3 className="text-md hidden lg:flex text-gray-300 font-normal truncate">Go to Page</h3>
                        <input
                            type="number"
                            min={1}
                            max={totalPages}
                            name="goTo"
                            className="block p-[5px] w-[40px] rounded-md border-0 text-gray-900 shadow-sm sm:text-sm sm:leading-6"
                            value={goToV}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                                setGoToV(e.target.value);
                            }}
                        />
                        <ChevronRightIcon onClick={() => handleChange(Number(goToV))} className="w-5 cursor-pointer" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
