import React from 'react';
import logo from 'files/whiteBgLogo.png.png';
import connectIcon from 'files/connectIcon.png';
import { cn } from 'utills/tailwindUtil';

const AppConnectionTopSection = ({ title, description, appIcon, imgSx }) => {
    return (
        <div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 items-center px-10 py-10 bg-white rounded-2xl shadow-lg">
                <div className="left col-span-1 flex-1/2">
                    <h2 className="text-xl lg:text-2xl text-gray-900 font-semibold">{title}</h2>
                    <h4 className="text-lg mt-5 font-normal text-gray-900 break-words  ">{description}</h4>
                </div>
                <div className="col-span-1 lg:col-span-2 flex-1/2">
                    <div className="flex items-center gap-2 px-10">
                        <img src={logo} alt="logo" className="h-20" />
                        <div className="relative flex-1">
                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t-[3px] border-gray-700 border-dashed" />
                            </div>
                            <div className="relative flex justify-center">
                                <img src={connectIcon} alt={'app-connector'} className="h-15 px-3 bg-white" />
                            </div>
                        </div>
                        <img src={appIcon} alt={appIcon} className={cn('h-20', imgSx)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppConnectionTopSection;
