import React, { useState } from 'react';
import { TimesheetPage } from '../../components/payrol-pages/timesheet-pages/TimesheetPage';
import { AddTimesheet } from '../../components/payrol-pages/timesheet-pages/AddTimesheet';
export const Timesheet = () => {
    const [showAddTimesheet, setShowAddTimesheet] = useState(false);

    const toggleAddTimesheet = () => {
        setShowAddTimesheet(!showAddTimesheet);
    };
    return (
        <div className="app-dashboard">
            {showAddTimesheet && <AddTimesheet toggleAddTimesheet={toggleAddTimesheet} />}

            <div className={`content ${''}`}>
                <TimesheetPage toggleAddTimesheet={toggleAddTimesheet} />
            </div>
        </div>
    );
};
