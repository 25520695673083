import { OtpInputs } from 'components/atoms/inputs/OtpInputs';
import { Loader } from 'components/common/Loader';
import Recaptcha from 'components/molecules/Recaptcha';
import { useCaptchaHandler } from 'Hooks/reCaptcha/useCaptchaHandler';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { sendOtpPhoneRegisterThunk, verifyPhoneThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';

export const PhoneVerification = (props) => {
    const [timeRemaining, setTimeRemaining] = useState(90);
    const [timerEnded, setTimerEnded] = useState(false);
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem('signupUser'));

    const { error, setError, isCaptchaRequired, recaptchaRef, handleRequest, handleCaptchaChange } =
        useCaptchaHandler();

    const isLoading = useSelector((state) => state.global.isLoading);
    const [otpVal, setOtpVal] = useState('');
    const [clearOtp, setClearOtp] = useState(false);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    const timeExecution = () => {
        const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 1) {
                    setTimerEnded(true);
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        timeExecution();
    }, []);

    const sendOtpToPhone = async () => {
        dispatch(setLoader(true));

        const payload = {
            user_type: 'employee',
            email: userData?.email,
            type: 'email',
            phone_number: `${userData?.phone_number}`,
            socket_id: localStorage.getItem('socket_id')
        };
        // dispatch(sendOtpPhoneRegisterThunk(payload))
        await handleRequest(
            'sendOtpPhoneRegister',
            (payload = {}) =>
                dispatch(
                    sendOtpPhoneRegisterThunk({
                        ...payload
                    })
                ),
            payload
        )
            .then((response) => {
                if (response?.payload) {
                    setTimerEnded(false);
                    setTimeRemaining(90);
                    setClearOtp(!clearOtp);
                    if (timeRemaining < 1) {
                        timeExecution();
                    }
                }
            })
            .then((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (otpVal.length < 6) {
            toastHandler('Please enter full otp', 'error');
            return;
        }
        const opt = otpVal;

        const otpData = {
            email: userData?.email,
            otp: opt,
            user_type: 'employee',
            type: 'email',
            phone_number: userData?.phone_number,
            socket_id: localStorage.getItem('socket_id')
        };

        dispatch(setLoader(true));
        await dispatch(verifyPhoneThunk(otpData))
            .then((response) => {
                dispatch(setLoader(false));

                if (response.payload) {
                    props.handleCondition(5);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });
        dispatch(setLoader(false));
    };

    const handleOtpChange = (value) => {
        setOtpVal(value);
    };

    return (
        <div>
            {' '}
            <div>
                {isLoading && (
                    <div className="loader-overlay">
                        <Loader />
                    </div>
                )}
                <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
                    <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[630px]  lg:max-w-[640px] xl:max-w-[650px] bg-white  rounded-xl sm:rounded-2xl  py-4 border-b-[15px] border-blueish">
                        {' '}
                        <div className="text-center  mt-3  card-layout-title-text font-fira">
                            Phone OTP Verification
                        </div>
                        <div className="otp-wrapper px-5 md:px-24 mt-10">
                            <div className="otp-body small-text font-poppins">
                                <p className="p-1">Please enter the six-digit code that has been sent to your phone.</p>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className="mt-8">
                                    <OtpInputs
                                        value={otpVal}
                                        onChange={handleOtpChange}
                                        required={true}
                                        clearOtp={clearOtp}
                                    />
                                </div>

                                {isCaptchaRequired && (
                                    <div className="my-3">
                                        <Recaptcha
                                            ref={recaptchaRef}
                                            captchaError={!!error}
                                            setCaptchaError={setError}
                                            onValueChange={handleCaptchaChange}
                                        />
                                    </div>
                                )}

                                <div className="resend-confirmation mt-5 text-sm font-poppins">
                                    <p>
                                        {timerEnded ? (
                                            <span onClick={sendOtpToPhone} className="text-thirdbg cursor-pointer">
                                                Resend
                                            </span>
                                        ) : (
                                            <span>
                                                {!timerEnded && (
                                                    <span>
                                                        <span className="text-thirdbg  cursor-pointer mr-1">
                                                            Resend
                                                        </span>
                                                        OTP in ({`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                                                        )
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                    </p>
                                </div>

                                <div className="mt-60 mb-16">
                                    <div className="flex justify-between items-center px-1-">
                                        <div>
                                            <button
                                                onClick={() => props.handleCondition(1)}
                                                type="button"
                                                className="btn-1 buttons-font font-fira px-14 py-2"
                                            >
                                                Back
                                            </button>
                                        </div>

                                        <div>
                                            <button
                                                type="submit"
                                                disabled={isLoading ? true : false}
                                                className="btn-2 buttons-font font-fira  px-14 py-2"
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
