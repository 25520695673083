import React from 'react';

export const HeicFile = ({ className }) => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#3F93AF"
            />
            <rect y="8" width="34" height="17" rx="4" fill="white" />
            <mask id="mask0_15913_197" maskUnits="userSpaceOnUse" x="0" y="8" width="34" height="17">
                <path
                    d="M0.540527 8H33.4385C33.7507 8 34 8.35953 34 8.80696V24.195C34 24.6425 33.7507 25 33.4385 25H0.540527C0.249259 25 0 24.6425 0 24.195V8.80696C0 8.35953 0.249259 8 0.540527 8Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_15913_197)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.36492 12.7225H6.91429V15.6953H9.95056V12.7225H11.4974V20.2775H9.95056V16.975H6.91429V20.2775H5.36492V12.7225ZM13.0993 20.2775V12.7225H18.787V14.0022H14.6487V15.6756H18.4996V16.9479H14.6487V19.0052H18.9344V20.2775H13.0993ZM20.2014 20.2775V12.7225H21.7508V20.2775H20.2014ZM22.9528 16.5689C22.9528 15.3089 23.2852 14.3344 23.9449 13.638C24.6021 12.9415 25.4693 12.5945 26.5438 12.5945C27.4835 12.5945 28.2456 12.8677 28.8304 13.414C29.1803 13.7364 29.4427 14.199 29.6176 14.8044L28.0832 15.1662C27.9932 14.7724 27.8033 14.4648 27.5134 14.236C27.2261 14.0096 26.8762 13.8988 26.4664 13.8988C25.8991 13.8988 25.4343 14.0981 25.0819 14.5017C24.7271 14.9029 24.5521 15.5525 24.5521 16.4532C24.5521 17.4081 24.7246 18.0898 25.0744 18.4933C25.4218 18.9019 25.8766 19.1036 26.4339 19.1036C26.8462 19.1036 27.2011 18.9757 27.4959 18.7148C27.7933 18.4589 28.0057 18.0528 28.1357 17.4991L29.6376 17.9692C29.4077 18.7936 29.0253 19.4063 28.488 19.805C27.9533 20.2061 27.2735 20.4055 26.4489 20.4055C25.4318 20.4055 24.5946 20.0634 23.9374 19.3793C23.2827 18.6927 22.9528 17.7551 22.9528 16.5689ZM4.45279 25H35.3977C35.7301 25 36 24.7342 36 24.4045V8.59554C36 8.26824 35.7301 8 35.3977 8H-0.395245C-0.730109 8 -1 8.26824 -1 8.59554V24.4045C-1 24.7342 -0.730109 25 -0.395245 25H4.45279Z"
                    fill="#3F93AF"
                />
            </g>
        </svg>
    );
};
