import React from 'react';

export const OrangeCalendar = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" y="0.00976562" width="40" height="40" rx="20" fill="#F08201" />
                <path
                    d="M28.9061 12.5742H26.8749V13.8562H28.7499V27.9582H11.2501V13.8562H13.1251V12.5742H11.0939C10.9478 12.5767 10.8036 12.6087 10.6696 12.6684C10.5355 12.7281 10.4143 12.8142 10.3127 12.922C10.2111 13.0297 10.1313 13.1569 10.0776 13.2963C10.024 13.4357 9.99768 13.5846 10.0002 13.7344V28.08C9.99768 28.2299 10.024 28.3787 10.0776 28.5182C10.1313 28.6576 10.2111 28.7848 10.3127 28.8925C10.4143 29.0002 10.5355 29.0864 10.6696 29.1461C10.8036 29.2057 10.9478 29.2377 11.0939 29.2402H28.9061C29.0522 29.2377 29.1964 29.2057 29.3304 29.1461C29.4645 29.0864 29.5857 29.0002 29.6873 28.8925C29.7889 28.7848 29.8687 28.6576 29.9224 28.5182C29.976 28.3787 30.0023 28.2299 29.9998 28.08V13.7344C30.0023 13.5846 29.976 13.4357 29.9224 13.2963C29.8687 13.1569 29.7889 13.0297 29.6873 12.922C29.5857 12.8142 29.4645 12.7281 29.3304 12.6684C29.1964 12.6087 29.0522 12.5767 28.9061 12.5742Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path d="M13.75 17.7012H15V18.9832H13.75V17.7012Z" fill="#F08201" stroke="#F08201" strokeWidth="0.4" />
                <path
                    d="M17.4999 17.7012H18.7499V18.9832H17.4999V17.7012Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M21.2498 17.7012H22.4997V18.9832H21.2498V17.7012Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M24.9998 17.7012H26.2497V18.9832H24.9998V17.7012Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M13.7499 20.9062H14.9999V22.1883H13.7499V20.9062Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M17.4999 20.9062H18.7499V22.1883H17.4999V20.9062Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M21.2498 20.9062H22.4997V22.1883H21.2498V20.9062Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M24.9998 20.9062H26.2497V22.1883H24.9998V20.9062Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path d="M13.75 24.1113H15V25.3933H13.75V24.1113Z" fill="#F08201" stroke="#F08201" strokeWidth="0.4" />
                <path
                    d="M17.4999 24.1113H18.7499V25.3933H17.4999V24.1113Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M21.2498 24.1113H22.4997V25.3933H21.2498V24.1113Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M24.9998 24.1113H26.2497V25.3933H24.9998V24.1113Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M14.9999 15.1378C15.1656 15.1378 15.3246 15.0702 15.4418 14.95C15.559 14.8298 15.6249 14.6668 15.6249 14.4968V10.6508C15.6249 10.4808 15.559 10.3177 15.4418 10.1975C15.3246 10.0773 15.1656 10.0098 14.9999 10.0098C14.8341 10.0098 14.6751 10.0773 14.5579 10.1975C14.4407 10.3177 14.3749 10.4808 14.3749 10.6508V14.4968C14.3749 14.6668 14.4407 14.8298 14.5579 14.95C14.6751 15.0702 14.8341 15.1378 14.9999 15.1378Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M24.9997 15.1378C25.1655 15.1378 25.3245 15.0702 25.4417 14.95C25.5589 14.8298 25.6247 14.6668 25.6247 14.4968V10.6508C25.6247 10.4808 25.5589 10.3177 25.4417 10.1975C25.3245 10.0773 25.1655 10.0098 24.9997 10.0098C24.834 10.0098 24.675 10.0773 24.5578 10.1975C24.4406 10.3177 24.3748 10.4808 24.3748 10.6508V14.4968C24.3748 14.6668 24.4406 14.8298 24.5578 14.95C24.675 15.0702 24.834 15.1378 24.9997 15.1378Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
                <path
                    d="M16.8749 12.5742H23.1248V13.8562H16.8749V12.5742Z"
                    fill="#F08201"
                    stroke="#F08201"
                    strokeWidth="0.4"
                />
            </svg>
        </div>
    );
};
