/* eslint-disable react/no-children-prop */
import { XCircleIcon } from '@heroicons/react/20/solid';
import { useCsvDownload } from 'Hooks/Files/useCsvDownload';
import { endpoint } from 'apiEndpoints';
import { Button } from 'components/atoms/buttons/Button';
import { DragFile } from 'components/atoms/inputs/DragFile';
import { Loader } from 'components/common/Loader';
import { NoDataFound } from 'components/common/NoDataFound';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { DefaultDialog } from 'components/molecules/DefaultDialog';
import { RadioChip } from 'components/molecules/RadioChip';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getBusinessClientInfoThunk, uploadBulkImportFileThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';
import { convertUnderscoresToSpaces } from 'utills/handleText';
import { formateDate } from 'utills/moment';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { CsvIcon } from 'utills/svgs/CsvIcon';
import { ErrorCsv } from 'utills/svgs/ErrorCsv';
import { LgCsvIcon } from 'utills/svgs/LgCsvIcon';
import { SideTimes } from 'utills/svgs/SideTimes';

export const OfflineClientBulkImport = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data } = useSelector((state) => state.getBusinessClientInfo);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [downloadType, setDownloadType] = useState('Individual');
    const [showModal, setShowModal] = useState(false);

    const [selectedType, setSelectedType] = useState('Individual');
    const { isLoading } = useSelector((state) => state.global);

    const { downloadCsv } = useCsvDownload();
    const [isAnimating, setIsAnimating] = useState(false);

    const [files, _setFiles] = useState([
        {
            file_name: 'one',
            structure: 'Individual',
            date: '2024-01-01',
            imported_by: 'User1',
            status: 'Pending',
            error_file: false,
            id: 1
        },
        {
            file_name: 'two',
            structure: 'Partnership',
            date: '2024-01-02',
            imported_by: 'User2',
            status: 'Completed',
            error_file: false,
            id: 2
        },
        {
            file_name: 'three',
            structure: 'Trust',
            date: '2024-01-03',
            imported_by: 'User3',
            status: 'Pending',
            error_file: true,
            id: 3
        },
        {
            file_name: 'four',
            structure: 'Individual',
            date: '2024-01-04',
            imported_by: 'User4',
            status: 'Failed',
            error_file: true,
            id: 4
        },
        {
            file_name: 'few',
            structure: 'Trust',
            date: '2024-01-05',
            imported_by: 'User5',
            status: 'Completed',
            error_file: false,
            id: 5
        },
        {
            file_name: 'gen',
            structure: 'Individual',
            date: '2024-01-06',
            imported_by: 'User6',
            status: 'Pending',
            error_file: true,
            id: 6
        },
        {
            file_name: 'new',
            structure: 'Partnership',
            date: '2024-01-07',
            imported_by: 'User7',
            status: 'Failed',
            error_file: false,
            id: 7
        },
        {
            file_name: 'joe',
            structure: 'Individual',
            date: '2024-01-08',
            imported_by: 'User8',
            status: 'Completed',
            error_file: false,
            id: 8
        },
        {
            file_name: 'black',
            structure: 'Individual',
            date: '2024-01-09',
            imported_by: 'User9',
            status: 'Pending',
            error_file: true,
            id: 9
        },
        {
            file_name: 'bew',
            structure: 'Trust',
            date: '2024-01-10',
            imported_by: 'User10',
            status: 'Failed',
            error_file: true,
            id: 10
        }
    ]);

    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings`);
        }
    };

    const handleSelectedFiles = (files) => {
        setSelectedFiles(files);
    };

    const acceptFormate = ['text/csv'];

    const handleClearFiles = () => {
        setSelectedFiles([]);
    };

    const handleDownloadCsv = (payload, url, filename) => {
        //downloadFailedEntry
        downloadCsv(payload, url, 'POST', false, filename);
        setShowModal(false);
        setDownloadType('Individual');
    };

    const handleBulkUploadFile = () => {
        dispatch(setLoader(true));
        const formData = new FormData();

        formData.append('bulk-upload-client', selectedFiles[0]);
        formData.append('client_category', selectedType);

        dispatch(uploadBulkImportFileThunk(formData))
            .then((response) => {
                if (response.payload) {
                    setSelectedFiles([]);
                    setIsAnimating(true);
                    getUploadedFilesData();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const getUploadedFilesData = () => {
        dispatch(setLoader(true));
        dispatch(getBusinessClientInfoThunk())
            .then(() => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        getUploadedFilesData();
    }, []);

    useEffect(() => {
        if (isAnimating) {
            setTimeout(() => {
                setIsAnimating(false);
            }, 10000);
        }
    }, [isAnimating]);
    return (
        <div>
            {' '}
            <SidebarLayout>
                {isLoading && <Loader />}
                <DefaultDialog
                    open={showModal}
                    setOpen={setShowModal}
                    width={'!w-[480px] !rounded-xl'}
                    p={'!p-0'}
                    children={
                        <div>
                            <div className="flex justify-end items-center p-2 cursor-pointer">
                                <span onClick={() => setShowModal(false)}>
                                    <SideTimes />
                                </span>
                            </div>
                            <div className="flex justify-center items-center py-3">
                                <LgCsvIcon />
                            </div>
                            <div className="px-5 text-center mt-1 buttons-font font-poppins">Select CSV Template</div>
                            <div className="p-5">
                                <div className="flex justify-center items-center flex-col gap-7">
                                    {' '}
                                    <div className="flex gap-4 items-center ">
                                        <RadioChip
                                            value="Individual"
                                            selectedValue={downloadType}
                                            onChange={(value) => setDownloadType(value)}
                                            p="pr-16"
                                        />
                                        <RadioChip
                                            value="Partnership"
                                            selectedValue={downloadType}
                                            onChange={(value) => setDownloadType(value)}
                                            p="pr-16"
                                        />
                                    </div>
                                    <div className="flex gap-4 items-center ">
                                        <RadioChip
                                            value="Company"
                                            selectedValue={downloadType}
                                            onChange={(value) => setDownloadType(value)}
                                            p="pr-[61px]"
                                        />
                                        <RadioChip
                                            value="Trust"
                                            selectedValue={downloadType}
                                            onChange={(value) => setDownloadType(value)}
                                            p="pr-28"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className=" py-10 flex justify-center items-center">
                                <Button
                                    atClick={() =>
                                        handleDownloadCsv(
                                            { client_category: downloadType, business_client_id: id },
                                            endpoint.downloadBulkImportTemplate,
                                            'template'
                                        )
                                    }
                                    title="Download Template"
                                    classes={'ann-btn rounded-lg px-8 buttons-font-lighter font-fira'}
                                />
                            </div>
                        </div>
                    }
                />{' '}
                <Jumbotron
                    title="Offline Clients-Bulk Import"
                    backArrowProp={backArrowProp}
                    path={[
                        { title: 'Settings', link: 'settings' },
                        { title: 'Client Settings', link: 'settings/clients' },
                        { title: 'Offline clients bulk import export', link: 'offline/clients/import-export' },
                        {
                            title: 'Offline Clients-Bulk Import',
                            link: ''
                        }
                    ]}
                />
                <div className="mx-5 md:mx-10">
                    <div className="bg-white rounded-md p-5">
                        <div className="xl-title font-poppins">Prepare your CSV file</div>
                        <div className="buttons-font-lighter font-poppins mt-4">
                            Create or obtain a CSV file that contains the necessary client information. Ensure that the
                            file has columns for relevant details like client names and email addresses. To update
                            contacts already in Synkli, export your contacts to CSV file and edit them. This feature
                            allows you to import multiple users from a CSV file.{' '}
                            <strong> Upto 5000 contacts can be imported each time.</strong>
                        </div>
                        <div className="mt-7  flex justify-center items-center">
                            <Button
                                atClick={() => setShowModal(!showModal)}
                                iconSet={{ leftIcon: CsvIcon }}
                                title="Download the CSV Template"
                                classes="ann-btn rounded-lg px-3 flex gap-3 items-center buttons-font font-fira"
                                iconInvert={true}
                            />
                        </div>
                    </div>

                    <div className="mt-7">
                        <div className="xl-title font-poppins">Upload CSV file</div>
                    </div>

                    <div className="flex gap-5 items-center flex-wrap mt-5">
                        <RadioChip
                            value="Individual"
                            selectedValue={selectedType}
                            onChange={(value) => setSelectedType(value)}
                            p="pr-20"
                        />
                        <RadioChip
                            value="Partnership"
                            selectedValue={selectedType}
                            onChange={(value) => setSelectedType(value)}
                            p="pr-20"
                        />
                        <RadioChip
                            value="Company"
                            selectedValue={selectedType}
                            onChange={(value) => setSelectedType(value)}
                            p="pr-20"
                        />
                        <RadioChip
                            value="Trust"
                            selectedValue={selectedType}
                            onChange={(value) => setSelectedType(value)}
                            p="pr-28"
                        />
                    </div>

                    <div className="mt-10  ">
                        {' '}
                        <DragFile
                            selectedFiles={selectedFiles}
                            atChange={(file) => {
                                handleSelectedFiles(file);
                            }}
                            hideSelectedFiles
                            accept={acceptFormate}
                            iconSet={{ icon: CsvIcon }}
                            iconScale={true}
                            isMultiple={false}
                            dragMessage="Click to upload file or you can drag and drop. Maximum file size should be 5MB"
                            dropMessage="Drop the selected files in this box... "
                            onlyPdf={true}
                            message="Only CSV file is allowed"
                            handleClearFiles={handleClearFiles}
                        />
                    </div>

                    {selectedFiles?.length > 0 && (
                        <div className="flex justify-between items-center flex-wrap mt-7">
                            {' '}
                            <div className="bg-thirdbg text-white small-text font-poppins flex justify-between items-center gap-3 w-[250px] p-3 rounded-xl ">
                                <div className="invert">
                                    <CsvIcon />
                                </div>
                                <div className="break-all">{selectedFiles[0]?.name}</div>
                                <div className="cursor-pointer">
                                    {' '}
                                    <XCircleIcon className="color-white w-5 h-5" onClick={() => setSelectedFiles([])} />
                                </div>
                            </div>
                            <div>
                                <Button
                                    iconSet={{ loader: SmallLoaderWhite }}
                                    isloading={isLoading}
                                    disabled={isLoading ? true : false}
                                    title="Bulk Upload"
                                    classes="ann-btn px-8 rounded-lg buttons-font-lighter font-fira"
                                    atClick={() => handleBulkUploadFile()}
                                />
                            </div>
                        </div>
                    )}

                    <div className="mb-10">
                        <div className="mt-7 ">
                            <div className="xl-title font-poppins">Activity</div>
                        </div>

                        <div className="mt-5 ">
                            <div className="grid grid-cols-6 gap-5 buttons-font-lg font-poppins">
                                <div>File Name</div>
                                <div>Client Structure</div>
                                <div>Date</div>
                                <div>Imported By</div>
                                <div>Status</div>
                                <div>Error File</div>
                            </div>

                            <hr className="mt-3 !bg-[#BBBBBB]" />

                            {!isLoading && files?.length < 1 ? (
                                <NoDataFound message="No Files Found!" height="30vh" />
                            ) : (
                                <div>
                                    {data &&
                                        data?.map((file, index) => (
                                            <div
                                                className={`mt-3 ${
                                                    index === 0 &&
                                                    isAnimating &&
                                                    !isLoading &&
                                                    'bg-gray-300 animate-pulse'
                                                }`}
                                                key={index}
                                            >
                                                {' '}
                                                <div className="grid grid-cols-6 gap-5 buttons-font-lighter font-poppins">
                                                    <div className="flex items-center break-all">
                                                        {file?.tbl_client_invitation_files_file_name}
                                                    </div>
                                                    <div className="flex items-center break-all">
                                                        {file?.tbl_client_invitation_files_client_category || '--'}
                                                    </div>
                                                    <div className="flex items-center break-all">
                                                        {formateDate(file?.tbl_client_invitation_files_created_at)}
                                                    </div>
                                                    <div className="flex items-center">
                                                        {file.tbl_users_first_name} {file.tbl_users_last_name}
                                                    </div>
                                                    <div className="flex items-center break-all capitalize">
                                                        {convertUnderscoresToSpaces(
                                                            file?.tbl_client_invitation_files_status
                                                        )}
                                                    </div>
                                                    <div className="flex items-center">
                                                        {file?.tbl_client_invitation_files_status !== 'successful' &&
                                                        file?.tbl_client_invitation_files_status !== 'in_progress' ? (
                                                            <div className="flex gap-2 items-center">
                                                                <div>
                                                                    <ErrorCsv />
                                                                </div>
                                                                <div
                                                                    onClick={() =>
                                                                        handleDownloadCsv(
                                                                            {
                                                                                cif_id:
                                                                                    file?.tbl_client_invitation_files_cif_id ||
                                                                                    '',
                                                                                business_client_id: id
                                                                            },
                                                                            endpoint.downloadErroredClients,
                                                                            'file'
                                                                        )
                                                                    }
                                                                    className="text-[#F14747] cursor-pointer"
                                                                >
                                                                    Error File
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <hr className="mt-3 !bg-[#BBBBBB]" />
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </SidebarLayout>
        </div>
    );
};
