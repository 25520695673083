import React, { useLayoutEffect, useRef, useState } from 'react';
import { Portal } from '../Portal';
import { cn } from 'utills/tailwindUtil';

export const TooltipChip = ({
    text,
    direction,
    parentRef,
    isVisible,
    hideChip = false,
    sx = null,
    portalProps,
    xAxis,
    yAxis
}) => {
    const childRef = useRef();
    const [transformStyles, setTransformStyles] = useState({ transform: '' });

    useLayoutEffect(() => {
        if (parentRef?.current && childRef?.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const childRect = childRef.current.getBoundingClientRect();

            const xOffset = (childRect.width || 0) / 2 - (parentRect.width || 0) / 2;

            const yOffset = direction !== 'bottom' ? -84 : 10;

            // portalProps="trans"
            setTransformStyles({
                // transform: `translateY(${yOffset}px) translateX(-${xOffset}px)`
                transform: `translateY(${yAxis ? yAxis : yOffset + 'px'}) translateX(-${
                    xAxis ? xAxis : xOffset + 'px'
                })`
            });
        }
    }, [parentRef, direction, isVisible]);

    return (
        <>
            <Portal parentRef={parentRef} isOpen={isVisible} portalProps={portalProps}>
                <span
                    ref={childRef}
                    className={cn(
                        'flex bg-third bg-opacity-95 px-3 py-1.5 text-sm text-white rounded-lg absolute',
                        'mx-auto font-secondary !w-max z-[100]',
                        sx ? sx : ''
                    )}
                    style={transformStyles}
                >
                    {text}
                    {!hideChip && (
                        <span
                            className={`absolute left-1/2 transform -translate-x-1/2 ${
                                direction === 'bottom' ? 'top-[-4px]' : 'bottom-[-4px]'
                            } w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent ${
                                direction === 'bottom' ? 'border-b-4 border-b-third' : 'border-t-4 border-t-third'
                            }`}
                        ></span>
                    )}
                </span>
            </Portal>
        </>
    );
};
