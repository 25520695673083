import React from 'react';

export const BusinessOccupationIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="url(#paint0_linear_16889_2428)" />
            <g clipPath="url(#clip0_16889_2428)">
                <path
                    d="M17.3281 10.5685C16.9102 10.6974 16.5391 11.1099 16.4258 11.5825C16.3477 11.8962 16.3477 12.5751 16.4258 12.7384C16.5352 12.9704 16.8086 13.0607 17.0273 12.9361C17.2148 12.8286 17.2422 12.7556 17.2656 12.2743C17.293 11.7888 17.332 11.6642 17.4961 11.5696C17.6328 11.488 22.3672 11.488 22.5039 11.5696C22.668 11.6642 22.707 11.7888 22.7344 12.2743C22.7578 12.7556 22.7852 12.8286 22.9727 12.9361C23.1914 13.0607 23.4648 12.9704 23.5742 12.7384C23.6523 12.5751 23.6523 11.8962 23.5742 11.5825C23.4609 11.1013 23.0898 10.6931 22.6523 10.5685C22.3516 10.4782 17.6211 10.4825 17.3281 10.5685Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.3"
                />
                <path
                    d="M11.1475 14.0272C10.7022 14.1003 10.3194 14.4183 10.1241 14.878L10.0186 15.1229L10.0069 16.9878C9.99518 18.771 9.99909 18.8655 10.0772 19.1448C10.1788 19.5144 10.4796 19.8968 10.7803 20.0386C10.921 20.103 18.0264 21.8905 18.1475 21.8905C18.1514 21.8905 18.1631 22.2901 18.171 22.78C18.1827 23.7597 18.1944 23.8112 18.421 23.9315C18.6046 24.0261 21.3936 24.0261 21.5772 23.9315C21.8038 23.8112 21.8155 23.7597 21.8272 22.78C21.835 22.2901 21.8467 21.8905 21.8506 21.8905C21.9717 21.8905 29.0772 20.103 29.2178 20.0386C29.5186 19.8968 29.8194 19.5144 29.921 19.1448C29.9991 18.8655 30.003 18.771 29.9913 16.9878L29.9796 15.1229L29.8741 14.8737C29.7335 14.5515 29.4366 14.2335 29.1514 14.1046L28.9249 14.0058L20.1358 14.0015C15.3038 13.9972 11.2569 14.0101 11.1475 14.0272ZM28.9092 15.1015C28.9717 15.153 29.0381 15.2562 29.0616 15.3507C29.1202 15.5827 29.1085 18.6894 29.0499 18.8784C29.0225 18.9601 28.96 19.0589 28.9053 19.1019C28.8428 19.1534 27.6241 19.4714 25.3311 20.0343L21.8546 20.8894L21.8272 20.6315C21.7881 20.2749 21.7335 20.146 21.5811 20.0687C21.3897 19.9655 18.6085 19.9655 18.4171 20.0687C18.2647 20.146 18.21 20.2749 18.171 20.6315L18.1436 20.8894L14.6671 20.0343C12.3741 19.4714 11.1553 19.1534 11.0928 19.1019C11.0381 19.0589 10.9756 18.9601 10.9483 18.8784C10.8897 18.6937 10.878 15.5827 10.9366 15.3593C10.9561 15.2776 11.0225 15.1659 11.0811 15.1144L11.1905 15.0155H19.9913C28.796 15.0155 28.796 15.0155 28.9092 15.1015ZM20.8975 21.9979V23.0077H19.9991H19.1006V21.9979V20.9882H19.9991H20.8975V21.9979Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.3"
                />
                <path
                    d="M10.7533 21.0355C10.6869 21.057 10.5931 21.1301 10.5463 21.1988C10.4681 21.3234 10.4681 21.3406 10.476 24.8641L10.4877 28.4004L10.5892 28.641C10.7221 28.9504 10.9642 29.2168 11.2455 29.3629L11.4642 29.4746H19.9994H28.5346L28.7533 29.3629C29.0346 29.2168 29.2767 28.9504 29.4096 28.641L29.5111 28.4004L29.5228 24.8641C29.5306 21.3492 29.5306 21.3234 29.4525 21.2031C29.3627 21.0613 29.1517 20.9797 28.9799 21.0184C28.8314 21.0484 28.6869 21.2117 28.6556 21.3793C28.6439 21.4566 28.6322 23.0078 28.6322 24.8297C28.6322 28.0352 28.6283 28.1469 28.5541 28.2801C28.5033 28.3703 28.433 28.4305 28.3314 28.4605C28.1244 28.525 11.8744 28.525 11.6674 28.4605C11.3705 28.3703 11.39 28.6023 11.3666 24.748C11.3471 21.1215 11.351 21.2074 11.1752 21.0871C11.0697 21.0141 10.8861 20.9926 10.7533 21.0355Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.3"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_16889_2428"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                </linearGradient>
                <clipPath id="clip0_16889_2428">
                    <rect width="20" height="22" fill="white" transform="translate(10 9)" />
                </clipPath>
            </defs>
        </svg>
    );
};
