import React from 'react';

export const RoundedTick = () => {
    return (
        <div>
            <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3089_187)">
                    <path
                        d="M10.9218 0.0476551C8.6062 0.272655 6.4687 1.12578 4.5937 2.56953C3.67964 3.27266 2.4937 4.59453 1.80933 5.67266C1.51401 6.13672 0.937451 7.31797 0.731201 7.88047C0.501514 8.50859 0.253076 9.47422 0.131201 10.2195C-0.0141113 11.1336 -0.0141113 12.868 0.131201 13.782C0.342139 15.0758 0.674951 16.1164 1.24214 17.2742C1.86089 18.5352 2.50308 19.4305 3.53433 20.4664C4.57026 21.4977 5.46558 22.1398 6.72651 22.7586C7.88433 23.3258 8.92495 23.6586 10.2187 23.8695C11.1328 24.0148 12.8671 24.0148 13.7812 23.8695C15.075 23.6586 16.1156 23.3258 17.2734 22.7586C18.5343 22.1398 19.4296 21.4977 20.4656 20.4664C21.4968 19.4305 22.139 18.5352 22.7578 17.2742C23.1703 16.4352 23.3765 15.8867 23.6015 15.043C23.8968 13.918 23.9671 13.3039 23.9671 12.0008C23.9671 10.6977 23.8968 10.0836 23.6015 8.95859C23.3765 8.11484 23.1703 7.56641 22.7578 6.72734C22.139 5.46641 21.4968 4.57109 20.4656 3.53516C19.4296 2.50391 18.5343 1.86172 17.2734 1.24297C16.1203 0.675781 15.0515 0.33828 13.8046 0.141405C13.1156 0.0335922 11.5781 -0.0179691 10.9218 0.0476551ZM13.1156 1.92734C15.4031 2.17578 17.4937 3.19297 19.1531 4.84766C21.0984 6.79297 22.125 9.26797 22.125 12.0008C22.125 14.7336 21.0984 17.2086 19.1531 19.1539C17.2078 21.0992 14.7328 22.1258 12 22.1258C9.26714 22.1258 6.79214 21.0992 4.84683 19.1539C2.90151 17.2086 1.87495 14.7336 1.87495 12.0008C1.87495 9.26797 2.90151 6.79297 4.84683 4.84766C6.48276 3.21172 8.59683 2.18047 10.8281 1.92734C11.3437 1.87109 12.5906 1.86641 13.1156 1.92734Z"
                        fill="white"
                    />
                    <path
                        d="M16.4625 7.69756C16.3219 7.77256 15.4125 8.74756 13.4016 10.9647L10.5328 14.1194L9.05156 12.6429C7.73437 11.3257 7.54687 11.1569 7.35937 11.1101C6.93281 10.9976 6.50625 11.1897 6.30937 11.5882C6.16406 11.8788 6.15468 12.0944 6.28125 12.3663C6.39843 12.6194 9.84375 16.1444 10.1344 16.2991C10.3641 16.4257 10.6547 16.4397 10.9219 16.3319C11.0719 16.271 11.7187 15.5866 14.3719 12.6663C16.5891 10.2288 17.6672 9.00537 17.7328 8.86475C18.0703 8.10537 17.1937 7.30381 16.4625 7.69756Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3089_187">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
