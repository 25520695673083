import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BUSINESS_ID, ERROR_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { setSideLoader } from 'store/global/globalReducer';
import { createQrCodeThunk } from 'store/workspace/workspaceQrCode';
import { toastHandler } from 'responseHanlder';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { getBranchLocationsThunk } from 'store/auth/slices';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import Slideover from 'components/atoms/SlideOver';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';

const AddQrCode = ({ toggleAddQrCode, toggleQrCode, open, setOpen }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { sideLoader } = useSelector((state) => state.global);

    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [branches, setBranches] = useState([]);

    const addQrCode = () => {
        if (!title || !link) {
            toastHandler('Please fill in all required fields', ERROR_TYPE_ERROR);
            return;
        }
        if (!link?.includes('.')) {
            toastHandler('Please enter a valid link. ', 'error');
            return;
        }
        const payload = {
            business_id,
            title,
            link,
            business_branch_id: selectedBranch
        };
        dispatch(setSideLoader(true));
        dispatch(createQrCodeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    toggleQrCode();
                    toggleAddQrCode();
                    toastHandler('QR Code added', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    useEffect(() => {
        dispatch(getBranchLocationsThunk({ business_id }))
            .then((response) => {
                if (response.payload) {
                    setBranches(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <Slideover title="Add QR Code" open={open} setOpen={setOpen}>
            {' '}
            <div>
                <div>
                    <div>
                        <RoleBasedGuard module={'Qr_codes'} permissions={['admin']} sx="h-[50vh]">
                            {' '}
                            <div className="add-ann-form px-5 mt-3 ">
                                <div>
                                    <label>Title</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setTitle(e.target.value)}
                                        type="text"
                                        placeholder="Title"
                                        maxLength={30}
                                    />
                                </div>

                                <div className="mt-3">
                                    <label>Paste QR Link</label>
                                </div>
                                <div>
                                    <input
                                        onChange={(e) => setLink(e.target.value)}
                                        type="text"
                                        placeholder="Link"
                                        maxLength={50}
                                    />
                                </div>

                                <div className=" mt-3">
                                    <label>Branch</label>
                                </div>

                                <div className="mt-2">
                                    <SimpleSelectMenu
                                        placeholder="Select"
                                        targetProperty="title"
                                        optionsData={branches || []}
                                        sx="rounded-xl py-3 ring-gray-400 "
                                        onChangeValue={(obj) => setSelectedBranch(obj?.business_branch_id)}
                                    />
                                </div>
                            </div>
                            <div className="add-service-bottom">
                                <button
                                    onClick={addQrCode}
                                    className="add-btn text-white px-5 flex gap-2 md:px-10 py-2 buttons-font-lighter cursor-pointer  rounded-xl"
                                >
                                    {sideLoader ? <SmallLoaderWhite /> : 'Create QR Code'}
                                </button>
                            </div>{' '}
                        </RoleBasedGuard>
                    </div>
                </div>
            </div>
        </Slideover>
    );
};
export default AddQrCode;
