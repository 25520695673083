import AuthCardLayout from 'layouts/AuthCardLayout';
import React from 'react';
import { EnvelopeSuccess } from 'utills/svgs/EnvelopeSuccess';

export const TwoFactorEmailSuccess = (props) => {
    return (
        <div className="flex min-h-[100vh] flex-col justify-center pb-10 sm:px-2 lg:px-2">
            <AuthCardLayout>
                {' '}
                <div>
                    <div>
                        <div className="envelope">
                            <div className="envelope-wrapper">
                                <EnvelopeSuccess />
                            </div>
                        </div>

                        <div className="congrats">
                            <h1 className="text-center">Congratulation!</h1>

                            <p className="text-center">Your email 2FA has been enabled successfully.</p>
                        </div>

                        <div className="proceed-btn mt-5 mb-28">
                            <center>
                                <button onClick={() => props.handleCondition(1)} className="px-12 py-2">
                                    proceed
                                </button>
                            </center>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};
