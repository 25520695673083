/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { TrippleSwitch } from 'components/atoms/inputs/TrippleSwitch';
import { Loader } from 'components/common/Loader';
import { NoDataFound } from 'components/common/NoDataFound';
import { DefaultLogs } from 'components/molecules/DefaultLogs';
import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import {
    deductionsInfoThunk,
    getAllCustomerDeductionThunk,
    getAllTripLogsThunk,
    getSummaryLogsThunk
} from 'store/deductions';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import { BUSINESS_ID, clientTypes, getProfileName } from 'utills/globalVars';
import { AbnExpenseIcon } from 'utills/svgs/AbnExpenseIcon';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { BgDateIcon } from 'utills/svgs/BgDateIcon';
import { BgEmailIcon } from 'utills/svgs/BgEmailIcon';
import { BgHomeIcon } from 'utills/svgs/BgHomeIcon';
import { BgPhoneIcon } from 'utills/svgs/BgPhoneIcon';
import { BgTaxIcon } from 'utills/svgs/BgTaxIcon';
import { BgUserIcon } from 'utills/svgs/BgUserIcon';
import { RentalExpenseIcon } from 'utills/svgs/RentalExpenseIcon';
import { TfnExpenseIcon } from 'utills/svgs/TfnExpenseIcon';
import { BgLocationIcon } from './../../../utills/svgs/BgLocationIcon';
import { DefaultRecentActivity } from 'components/molecules/DefaultRecentActivity';
import { formateSpecificDate } from 'utills/moment';
import { TfnInfoCard } from './TfnInfoCard';
import { AbnIcon } from 'utills/svgs/AbnIcon';
import { BusinessIcon } from 'utills/svgs/BusinessIcon';
import { AbnInfoCard } from './AbnInfoCard';
import { RentalinfoCard } from './RentalInfoCard';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { endpoint } from 'apiEndpoints';
import { UseDownloadLogsPdf } from 'Hooks/Files/UseDownloadLogsPdf';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import moment from 'moment';
import { BgGSTIcon } from 'utills/svgs/BgGSTIcon';
import { TripExpenseIcon } from 'utills/svgs/TripExpenseIcon';
import { TripLogbookCard } from './TripLogbookCard';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const CustomerDeductions = () => {
    const { id } = useParams();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: info } = useSelector((state) => state.deductionsInfo);
    const { data: customer } = useSelector((state) => state.viewBusinessCustomerInfo);
    const { data: deductionData } = useSelector((state) => state.getAllCustomerDeduction);
    const { data: tripLogsData } = useSelector((state) => state.getAllTripLogs);
    const { isLoading } = useSelector((state) => state.global);
    const [showLogs, setShowLogs] = useState(false);
    const { handleDownloadLogsPdf } = UseDownloadLogsPdf();

    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [count, setCount] = useState(null);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
    const [term, setTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [filteredLogs, setfilteredLogs] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [filterCount, setFilterCount] = useState(1);

    const [currentTab, setCurrentTab] = useState('Salary/Wages');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleLogs = () => {
        setShowLogs(!showLogs);
    };

    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/app/user/profile/${id}`);
        }
    };

    const getInfo = () => {
        const payload = {
            business_client_id: id,
            business_id
        };

        dispatch(setLoader(true));
        dispatch(deductionsInfoThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    const fetchAllDeduction = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(getAllCustomerDeductionThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const fetchAllTripLogBook = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(getAllTripLogsThunk(payload)).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        getInfo();
        fetchCustomer();
        fetchAllDeduction();
        fetchAllTripLogBook();
    }, []);

    const handleAccess = (key) => {
        if (info?.length > 0) {
            const findItem = info?.find((item) => item?.key === key);
            if (findItem?.count > 0) {
                return true;
            } else {
                return false;
            }
        }
    };

    const managerActions = [
        {
            title: 'View Logs',
            classes: 'flex gap-2 items-center px-5 ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                toggleLogs();
            }
        }
    ];

    const fetchLogs = () => {
        const payload = {
            business_client_id: id,

            deduction_type: '',
            page: term || dateFrom || dateTo ? filterPage : page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            business_id,
            filter: {
                text: term,
                from_date: dateFrom && moment(new Date(dateFrom)).format('DD-MM-YYYY'),
                end_date: dateTo && moment(new Date(dateTo)).format('DD-MM-YYYY')
            }
        };

        dispatch(setSideLoader(true));
        dispatch(getSummaryLogsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (dateFrom || dateTo || term) {
                        setFilterCount(response?.payload?.count);
                        const newLogs = [...filteredLogs, ...response.payload.logs];
                        setfilteredLogs(newLogs);
                    } else {
                        setCount(response?.payload?.count);
                        if (page > 1) {
                            const newLogs = [...logs, ...response.payload.logs];
                            setLogs(newLogs);
                        } else {
                            const newLogs = response.payload.logs;
                            setLogs(newLogs);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleMore = () => {
        const totalPages = Math.ceil(count / Number(PAGINATION_PAGE_SIZE));
        const togleFilterPages = Math.ceil(filterCount / Number(PAGINATION_PAGE_SIZE));

        if (dateFrom || dateTo || term) {
            if (filterPage < togleFilterPages) {
                setFilterPage(filterPage + 1);
            }
            return;
        }
        if (page < totalPages) {
            setPage(page + 1);
        }
    };
    const [noData, setNoData] = useState();

    useEffect(() => {
        if (info?.length > 0) {
            const areAllCountsZero = info.every((item) => item.count === 0);
            if (areAllCountsZero) {
                setNoData(true);
            } else {
                setNoData(false);
            }
        }
    }, [info]);

    const handleDownloadActivity = () => {
        const payload = {
            business_client_id: id,
            business_id: business_id
        };
        handleDownloadLogsPdf(payload, endpoint.downaloadDeductionsLogsPdf);
    };

    //downaloadDeductionsLogsPdf
    return (
        <SidebarLayout>
            <div>
                <div className="app-dashboard relative  ">
                    {showLogs && (
                        <DefaultLogs
                            toggleLogs={toggleLogs}
                            fetchLogs={fetchLogs}
                            logs={logs}
                            count={count}
                            pageSize={pageSize}
                            page={page}
                            handleMore={handleMore}
                            term={term}
                            setTerm={setTerm}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            dateTo={dateTo}
                            setDateTo={setDateTo}
                        />
                    )}
                    {isLoading && <Loader />}{' '}
                    <div>
                        <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                            <Jumbotron
                                title="Deduction"
                                backArrowProp={backArrowProp}
                                path={[
                                    { title: 'Clients', link: 'clients' },
                                    {
                                        title:
                                            customer && clientTypes[customer?.client_type] !== 'All'
                                                ? clientTypes[customer?.client_type]
                                                : 'All Clients',
                                        link: `app/users?type=${
                                            customer
                                                ? clientTypes[customer?.client_type]?.[0]?.toLowerCase() +
                                                  clientTypes[customer?.client_type]?.slice(1)
                                                : 'all'
                                        }&reload=true`
                                    },
                                    {
                                        title: `${getProfileName(customer)}`,
                                        link: `app/user/profile/${id}`
                                    },
                                    { title: 'Deduction', link: '' }
                                ]}
                            />
                            <div className="mt-2 md:mx-10 mx-5">
                                {noData ? (
                                    <NoDataFound
                                        message="Client didn't setup deduction calculator"
                                        classes="bg-white rounded-lg"
                                    />
                                ) : (
                                    <div className="flex items-center flex-wrap gap-5 ">
                                        {handleAccess('tfn') && (
                                            <GridCard
                                                text="Salary/Wages Expenses"
                                                link={
                                                    handleAccess('tfn') ? `/customer/deductions/tfn/${id}` : undefined
                                                }
                                                iconSet={{ icon: TfnExpenseIcon }}
                                            />
                                        )}

                                        {handleAccess('abn') && (
                                            <GridCard
                                                text="Business Income/Expenses"
                                                link={
                                                    handleAccess('abn') ? `/customer/deductions/abn/${id}` : undefined
                                                }
                                                iconSet={{ icon: AbnExpenseIcon }}
                                            />
                                        )}

                                        {handleAccess('rental_property') && (
                                            <GridCard
                                                text="Rental Income/Expenses"
                                                link={
                                                    handleAccess('rental_property')
                                                        ? `/customer/deductions/rental_property/${id}`
                                                        : undefined
                                                }
                                                iconSet={{ icon: RentalExpenseIcon }}
                                            />
                                        )}

                                        {handleAccess('logbook') && (
                                            <GridCard
                                                text=" Trip Logbook/Expense"
                                                link={
                                                    handleAccess('logbook') ? `/trip-tracking-logbook/${id}` : undefined
                                                }
                                                iconSet={{ icon: TripExpenseIcon }}
                                            />
                                        )}
                                    </div>
                                )}
                                <div>
                                    <div className="xl:grid grid-cols-12 gap-5 mt-5">
                                        <div className="col-span-8 bg-white rounded-lg shadow-sm p-5">
                                            <div className="flex justify-center items-center py-2">
                                                <TrippleSwitch
                                                    titleOne="Salary/Wages"
                                                    titleTwo="Business"
                                                    titleThree="Rental"
                                                    titleFour="Trips Logbook"
                                                    border="1px solid #BBBBBB"
                                                    sxOne="!px-4 md:!px-6 lg:!px-9"
                                                    atClick={(title) => {
                                                        setCurrentTab(title);
                                                    }}
                                                />
                                            </div>

                                            {currentTab === 'Salary/Wages' && (
                                                <div className="mt-5">
                                                    <div className=" ">
                                                        {deductionData?.tfn ? (
                                                            <div>
                                                                <TfnInfoCard
                                                                    data={[
                                                                        {
                                                                            icon: BgUserIcon,
                                                                            key: 'Name',
                                                                            value: deductionData?.tfn?.given_name
                                                                                ? deductionData?.tfn?.given_name +
                                                                                  ' ' +
                                                                                  deductionData?.tfn?.surname
                                                                                : ''
                                                                        },
                                                                        {
                                                                            icon: BgTaxIcon,
                                                                            key: 'Tax File No',
                                                                            value: deductionData?.tfn?.tfn ?? ''
                                                                        },
                                                                        {
                                                                            icon: BgEmailIcon,
                                                                            key: 'Email',
                                                                            value: deductionData?.tfn?.email ?? ''
                                                                        },
                                                                        {
                                                                            icon: BgPhoneIcon,
                                                                            key: 'Phone',
                                                                            value:
                                                                                deductionData?.tfn?.mobile_number ?? ''
                                                                        },
                                                                        {
                                                                            icon: BgDateIcon,
                                                                            key: 'Date Of Birth',
                                                                            value:
                                                                                formateSpecificDate(
                                                                                    deductionData?.tfn?.date_of_birth
                                                                                ) ?? ''
                                                                        },
                                                                        {
                                                                            icon: BgHomeIcon,
                                                                            key: 'Australian Resident',
                                                                            value: deductionData?.tfn
                                                                                ?.is_australian_address
                                                                                ? 'Yes'
                                                                                : 'No' ?? ''
                                                                        },
                                                                        {
                                                                            icon: BgLocationIcon,
                                                                            key: 'Residential Address',
                                                                            value:
                                                                                deductionData?.tfn?.home_address ?? ''
                                                                        },
                                                                        {
                                                                            icon: BgLocationIcon,
                                                                            key: 'Postal Address',
                                                                            value:
                                                                                deductionData?.tfn?.postal_address ?? ''
                                                                        }
                                                                    ]}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <NoDataFound message="Client did't setup TFN" />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {currentTab === 'Business' && (
                                                <div className="mt-5">
                                                    <div className=" ">
                                                        {deductionData?.abn.length > 0 ? (
                                                            <div>
                                                                {deductionData?.abn?.map((item, index) => (
                                                                    <div
                                                                        className=" mb-10"
                                                                        key={'businessCard_' + index}
                                                                    >
                                                                        <AbnInfoCard
                                                                            data={[
                                                                                {
                                                                                    icon: BusinessIcon,
                                                                                    key: 'Business Name',
                                                                                    value: item?.name ?? ''
                                                                                },
                                                                                {
                                                                                    icon: BgTaxIcon,
                                                                                    key: 'Business Type',
                                                                                    value: item.business_type ?? ''
                                                                                },
                                                                                {
                                                                                    icon: BgGSTIcon,
                                                                                    key: 'GST Registered',
                                                                                    value: item?.gst ? 'Yes' : 'No'
                                                                                },
                                                                                {
                                                                                    icon: AbnIcon,
                                                                                    key: 'ABN NO.',
                                                                                    value: item.abn ?? ''
                                                                                },
                                                                                {
                                                                                    icon: BgLocationIcon,
                                                                                    key: 'Business Address',
                                                                                    value: item.address ?? ''
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <NoDataFound message="Client did't setup ABN" />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {currentTab === 'Rental' && (
                                                <div className="mt-5">
                                                    <div className=" ">
                                                        {deductionData?.rental_property.length > 0 ? (
                                                            <div>
                                                                {deductionData?.rental_property?.map((item, index) => (
                                                                    <div
                                                                        className=" mb-10"
                                                                        key={'rental_item_' + index}
                                                                    >
                                                                        <RentalinfoCard data={item} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <NoDataFound message="Client did't setup Rental" />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {currentTab === 'Trips Logbook' && (
                                                <div className="mt-5">
                                                    <h3 className="text-xl font-semibold text-black leading-6 my-5">
                                                        Trips Logbook
                                                    </h3>
                                                    <div className=" ">
                                                        {tripLogsData && tripLogsData?.length > 0 ? (
                                                            <div>
                                                                {tripLogsData?.map((item, index) => (
                                                                    <div className=" mb-10" key={'trip_logs_' + index}>
                                                                        <TripLogbookCard data={item} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <NoDataFound message="Client did't Trips Logbook" />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-span-4 bg-white rounded-lg shadow-sm xl:mt-0 mt-5">
                                            <DefaultRecentActivity
                                                fetchLogs={fetchLogs}
                                                logs={dateFrom || dateTo || term ? filteredLogs : logs}
                                                count={count}
                                                pageSize={pageSize}
                                                page={page}
                                                handleMore={handleMore}
                                                term={term}
                                                setTerm={setTerm}
                                                dateFrom={dateFrom}
                                                setDateFrom={setDateFrom}
                                                dateTo={dateTo}
                                                setDateTo={setDateTo}
                                                title="Recent Activity"
                                                downloadActivity={handleDownloadActivity}
                                                setFilterPage={setFilterPage}
                                                filterPage={filterPage}
                                                filterCount={filterCount}
                                                setfilteredLogs={setfilteredLogs}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RoleBasedGuard>
                    </div>
                </div>
            </div>
        </SidebarLayout>
    );
};
