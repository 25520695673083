import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeHttpRequest } from '../../axios';
// import { SanitizeInput } from './middleware';

const generateThunkAndSlice = (sliceName, endpoint, method) => {
    const initialState = {
        data: null
    };
    const slice = createSlice({
        name: sliceName,
        initialState,
        reducers: {
            handleUpdate: (state, action) => {
                state.data = action.payload;
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(request.pending, (_state) => {})
                .addCase(request.fulfilled, (state, action) => {
                    state.data = action.payload;
                });
        }
    });

    const request = createAsyncThunk(`/${sliceName}/request`, async (params) => {
        let urlParams = null;
        if (params?.urlParams) {
            urlParams = params?.urlParams;
        }
        delete params?.urlParams;

        // Sanitize params (both JSON and FormData)
        let sanitizedParams = params;
        // if (['post', 'put'].includes(method)) {
        //     sanitizedParams = SanitizeInput(params);
        // }

        try {
            const res = await makeHttpRequest(method, endpoint, sanitizedParams, null, urlParams);
            return res;
        } catch (error) {
            console.error(error);
            return error;
        }
    });

    return {
        slice,
        request
    };
};

export default generateThunkAndSlice;
