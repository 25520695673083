import { Button } from 'components/atoms/buttons/Button';
import React, { useState } from 'react';
import { BgTimes } from 'utills/svgs/BgTimes';

export const Hint = ({ onchange, onClose, value }) => {
    const [hintValue, setHintValue] = useState(value ?? '');
    return (
        <div className="add-hint-container p-3 relative">
            <div className="absolute right-2 top-2 cursor-pointer" onClick={() => onClose()}>
                <BgTimes />
            </div>
            <div>
                <div className="table-title font-fira my-2">Add Hint</div>
            </div>
            <div className="">
                <input
                    type="text"
                    onChange={(e) => setHintValue(e.target.value)}
                    placeholder="Enter hint"
                    value={hintValue}
                />
            </div>

            <div className="mt-2 flex justify-end items-center">
                <Button
                    title={'Add'}
                    atClick={() => {
                        onchange(hintValue);
                        onClose();
                    }}
                    isDisabled={false}
                    classes={'px-5 rounded-lg ann-btn buttons-font font-fira'}
                    type="button"
                    styles={{}}
                />
            </div>
        </div>
    );
};
