import React from 'react';

export const BgJobsIcon = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="38" height="38" rx="6" fill="#0A1E46" />
            <g clipPath="url(#clip0_23483_1950)">
                <path
                    d="M14.125 9.625C14.332 9.625 14.5 9.45738 14.5 9.25V8.875C14.5 8.25475 15.0048 7.75 15.625 7.75H19.375C19.9953 7.75 20.5 8.25475 20.5 8.875V9.25C20.5 9.45738 20.668 9.625 20.875 9.625C21.082 9.625 21.25 9.45738 21.25 9.25V8.875C21.25 7.84113 20.4089 7 19.375 7H15.625C14.5911 7 13.75 7.84113 13.75 8.875V9.25C13.75 9.45738 13.918 9.625 14.125 9.625ZM16.75 17.125C16.1298 17.125 15.625 17.6298 15.625 18.25V21.25C15.625 21.8702 16.1298 22.375 16.75 22.375H18.25C18.8703 22.375 19.375 21.8702 19.375 21.25V18.25C19.375 17.6298 18.8703 17.125 18.25 17.125H16.75ZM18.625 18.25V21.25C18.625 21.457 18.4566 21.625 18.25 21.625H16.75C16.5434 21.625 16.375 21.457 16.375 21.25V18.25C16.375 18.043 16.5434 17.875 16.75 17.875H18.25C18.4566 17.875 18.625 18.043 18.625 18.25ZM29.5 21.25H24.625C23.7977 21.25 23.125 21.9227 23.125 22.75V27.25H22.375C21.5477 27.25 20.875 27.9228 20.875 28.75V29.5C20.875 30.3272 21.5477 31 22.375 31H29.5C30.3272 31 31 30.3272 31 29.5V22.75C31 21.9227 30.3272 21.25 29.5 21.25ZM22.375 30.25C21.9614 30.25 21.625 29.9136 21.625 29.5V28.75C21.625 28.3364 21.9614 28 22.375 28H23.125V29.5C23.125 29.9136 22.7886 30.25 22.375 30.25ZM30.25 29.5C30.25 29.9136 29.9136 30.25 29.5 30.25H23.6736C23.8019 30.0291 23.875 29.773 23.875 29.5V22.75C23.875 22.3364 24.2114 22 24.625 22H29.5C29.9136 22 30.25 22.3364 30.25 22.75V29.5Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M28.75 23.875H25.75C25.543 23.875 25.375 24.0426 25.375 24.25C25.375 24.4574 25.543 24.625 25.75 24.625H28.75C28.957 24.625 29.125 24.4574 29.125 24.25C29.125 24.0426 28.957 23.875 28.75 23.875ZM28.75 25.75H25.75C25.543 25.75 25.375 25.9176 25.375 26.125C25.375 26.3324 25.543 26.5 25.75 26.5H28.75C28.957 26.5 29.125 26.3324 29.125 26.125C29.125 25.9176 28.957 25.75 28.75 25.75ZM28.75 27.625H25.75C25.543 27.625 25.375 27.7926 25.375 28C25.375 28.2074 25.543 28.375 25.75 28.375H28.75C28.957 28.375 29.125 28.2074 29.125 28C29.125 27.7926 28.957 27.625 28.75 27.625ZM22.75 26.125C22.75 25.9176 22.582 25.75 22.375 25.75H8.875C8.25475 25.75 7.75 25.2452 7.75 24.625V17.119C7.85913 17.2011 7.975 17.2754 8.1025 17.3331L12.5849 19.948C12.5972 19.9551 12.61 19.9615 12.6235 19.9675C12.8616 20.0718 13.1147 20.125 13.375 20.125H14.875C15.082 20.125 15.25 19.9574 15.25 19.75C15.25 19.5426 15.082 19.375 14.875 19.375H13.375C13.2257 19.375 13.0803 19.3461 12.9426 19.2887L8.4625 16.675C8.45012 16.6679 8.437 16.6615 8.42387 16.6555C8.01475 16.4766 7.75 16.0716 7.75 15.625V11.875C7.75 11.2548 8.25475 10.75 8.875 10.75H26.125C26.7453 10.75 27.25 11.2548 27.25 11.875V15.625C27.25 16.0829 26.9766 16.4913 26.5536 16.6652C26.5416 16.6705 26.53 16.6758 26.5187 16.6818L21.6621 19.297C21.5301 19.3488 21.3914 19.375 21.25 19.375H20.125C19.918 19.375 19.75 19.5426 19.75 19.75C19.75 19.9574 19.918 20.125 20.125 20.125H21.25C21.4956 20.125 21.7356 20.0778 21.9636 19.984C21.9756 19.9791 21.9876 19.9739 21.9993 19.9675L26.8581 17.3507C27.0006 17.2904 27.13 17.2101 27.25 17.1201V20.5C27.25 20.7074 27.418 20.875 27.625 20.875C27.832 20.875 28 20.7074 28 20.5V11.875C28 10.8411 27.1589 10 26.125 10H8.875C7.84113 10 7 10.8411 7 11.875V24.625C7 25.6589 7.84113 26.5 8.875 26.5H22.375C22.582 26.5 22.75 26.3324 22.75 26.125Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_23483_1950">
                    <rect width="24" height="24" fill="white" transform="translate(7 7)" />
                </clipPath>
            </defs>
        </svg>
    );
};
