import { Button } from 'components/atoms/buttons/Button';
import { DatePick } from 'components/atoms/inputs/DatePick';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { setLoader } from 'store/global/globalReducer';
import { addMilestoneThunk } from 'store/JobManager';
import { BUSINESS_ID, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { formatDateIfNecessary } from 'utills/moment';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';

const MilestoneForm = ({ milestone, job_id, index, prefieldData, onSubmit, onCancel, sx = '' }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);

    const [title, setTitle] = useState(prefieldData?.title || '');
    const [startDate, setStartDate] = useState(dayjs() ?? null);
    const [endDate, setEndDate] = useState(dayjs().add(2, 'day') ?? null);
    const [description, setDescription] = useState(prefieldData?.body || '');

    const handleNewMilestoneSubmit = (e, sequence, index) => {
        e.preventDefault();
        const payload = {
            business_id: business_id,
            job_id: job_id,
            title: title,
            start_date: formatDateIfNecessary(startDate),
            due_date: formatDateIfNecessary(endDate),
            sequence: sequence,
            description: description
        };
        dispatch(setLoader(true));
        dispatch(addMilestoneThunk(payload))
            .then((response) => {
                if (response.payload) {
                    onSubmit(response.payload, index);
                    toastHandler('Milestone added', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const stripHtml = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    };

    return (
        <div>
            <form onSubmit={(e) => handleNewMilestoneSubmit(e, parseInt(milestone?.sequence) + 1 || 1, index)}>
                <div className="flex md:justify-between items-center gap-5 flex-wrap mt-5">
                    <div className="text-xl min-w-[300px] text-[#0A1E46] font-poppins font-[500] flex-1 default-forms ">
                        <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Enter milestone title"
                            className=""
                            defaultValue={title || ''}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            maxLength={100}
                        />
                    </div>

                    <div className={'flex gap-5 items-center ' + sx}>
                        <div className="flex-1">
                            <DatePick
                                iconSet={{
                                    right: CalendarSmallIco
                                }}
                                required
                                onDateChange={(date) => {
                                    if (date) {
                                        setStartDate(date);
                                    } else {
                                        setStartDate(null);
                                    }
                                }}
                                maxDate={endDate ? dayjs(endDate, 'DD/MM/YYYY') : ''}
                                minDate={dayjs()}
                                placeholder="DD-MM-YYYY"
                                value={startDate ? dayjs(startDate, 'DD/MM/YYYY') : ''}
                                sx="addTaskDate !border-gray-400 !rounded-xl  text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                            />
                        </div>
                        <div className="flex-1">
                            <DatePick
                                iconSet={{
                                    right: CalendarSmallIco
                                }}
                                minDate={startDate ? dayjs(startDate) : null}
                                onDateChange={(date) => {
                                    if (date) {
                                        setEndDate(date);
                                    } else {
                                        setEndDate(null);
                                    }
                                }}
                                required
                                placeholder="DD-MM-YYYY"
                                value={endDate ? dayjs(endDate, 'DD/MM/YYYY') : ''}
                                sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#fff] hover:bg-[#FAF9F6]"
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-5 default-forms">
                    <textarea
                        required
                        onChange={(e) => setDescription(e.target.value)}
                        rows={2}
                        defaultValue={stripHtml(description) || ''}
                        placeholder="Enter description"
                        maxLength={5000}
                        className="w-full scrollbar-hide text-sm font-[400] placeholder:text-secondarybg border-secondarybg rounded-lg focus:!outline-none  focus:!ring-0 focus:!border-secondarybg"
                    />
                </div>

                <div className="flex gap-2 items-center justify-center mt-5">
                    <Button title="Cancel" classes="blue-border-btn px-14 rounded-xl" atClick={() => onCancel(index)} />
                    <Button title="Add" buttonType="submit" classes="ann-btn px-16 rounded-xl" />
                </div>
            </form>
        </div>
    );
};

export default MilestoneForm;
