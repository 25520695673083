import React from 'react';
import { useState } from 'react';
import '../css/atoms.css';
import { cn } from 'utills/tailwindUtil';
export const TextInput = ({
    value,
    onChange,
    maxLength,
    placeholder,
    width,
    required,
    allowNumbers,
    sx,
    onKeyDown
}) => {
    const [inValidInput, setInvalidInput] = useState(null);

    const handleInputChange = (event) => {
        setInvalidInput(null);

        const inputValue = event.target.value;

        if (!allowNumbers) {
            if (!/^[^\d!@#$%^&*(),.?":{}|<>0-9]*$/.test(inputValue)) {
                setInvalidInput('Invalid Input');
                return;
            }
        }

        if (maxLength && inputValue.length >= maxLength) {
            setInvalidInput('Maximum limit reached!');
            return;
        }

        onChange(event);
    };
    return (
        <div>
            <div className="default-forms">
                {' '}
                <input
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={handleInputChange}
                    className={cn(
                        `placeholder-[#979797] placeholder:text-[0.9rem] placeholder:font-[400] !font-[400] !text-[0.9rem] !pl-2`,
                        sx
                    )}
                    style={{
                        borderColor: inValidInput ? 'red' : '',
                        width: width ?? '100%'
                    }}
                    maxLength={maxLength}
                    required={required}
                    onKeyDown={onKeyDown}
                />
            </div>
            {inValidInput && <div className="text-sm text-red-500"> {inValidInput}</div>}
        </div>
    );
};
