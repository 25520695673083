import React, { useRef, useState } from 'react';
import { generateRandomProfileLink, getProfileName } from 'utills/globalVars';
import { cn } from 'utills/tailwindUtil';
import { TooltipChip } from '../tooltipChip/TooltipChip';

const AvatarGroup = ({ list = [], targetProperty, onClick, limit, sx, showIcon, sx2, firstFull }) => {
    const parentRef = useRef(null);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <div className="flex w-full items-center -space-x-2 rtl:space-x-reverse cursor-pointer">
            {list &&
                list.slice(0, limit).map((item, index) => (
                    <div
                        key={'imageShow' + index}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        className={`group relative 
                            ${list?.length === 1 && firstFull ? 'flex gap-2 items-center overflow-hidden' : ''} 
                        `}
                        ref={parentRef}
                    >
                        {(firstFull ? list?.length !== 1 : true) && (
                            <TooltipChip
                                text={getProfileName(item) || item?.name}
                                direction="top"
                                parentRef={parentRef}
                                isVisible={hoveredIndex === index}
                                hideChip
                            />
                        )}

                        {showIcon ? (
                            <div onClick={() => onClick(item)}>{showIcon}</div>
                        ) : (
                            <img
                                key={'alt_image_' + index + item?.[targetProperty]}
                                className={`w-10 h-10 min-w-10 min-h-10 border-2 border-white rounded-full dark:border-gray-800 ${
                                    sx || ''
                                } ${index > 0 ? 'taskImage' : ''}`}
                                src={item?.[targetProperty] || generateRandomProfileLink(getProfileName(item))}
                                alt={'alt_image_' + item?.[targetProperty]}
                                onClick={() => onClick(item)}
                            />
                        )}
                        {list?.length === 1 && firstFull && (
                            <div className="truncate ">{getProfileName(item) || '--'}</div>
                        )}
                    </div>
                ))}
            {list?.length > limit && (
                <div style={{ marginLeft: '-25px' }}>
                    <div
                        onClick={onClick}
                        className={cn(
                            'flex items-center justify-center min-w-10 min-h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800 group relative',
                            sx2
                        )}
                    >
                        +{list.length - limit}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AvatarGroup;
