import React from 'react';

export const EmailIcon = ({ className }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect width="40" height="40" rx="20" fill="url(#paint0_linear_21727_392)" />
            <path
                d="M12.616 27C12.1553 27 11.771 26.846 11.463 26.538C11.155 26.23 11.0007 25.8453 11 25.384V14.616C11 14.1553 11.1543 13.771 11.463 13.463C11.7717 13.155 12.1557 13.0007 12.615 13H27.385C27.845 13 28.229 13.1543 28.537 13.463C28.845 13.7717 28.9993 14.156 29 14.616V25.385C29 25.845 28.8457 26.2293 28.537 26.538C28.2283 26.8467 27.8443 27.0007 27.385 27H12.616ZM20 20.116L12 14.885V25.385C12 25.5643 12.0577 25.7117 12.173 25.827C12.2883 25.9423 12.436 26 12.616 26H27.385C27.5643 26 27.7117 25.9423 27.827 25.827C27.9423 25.7117 28 25.564 28 25.384V14.884L20 20.116ZM20 19L27.692 14H12.308L20 19ZM12 14.885V14V25.385C12 25.5643 12.0577 25.7117 12.173 25.827C12.2883 25.9423 12.436 26 12.616 26H12V14.885Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_21727_392"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                </linearGradient>
            </defs>
        </svg>
    );
};
