import React from 'react';

export const ImageIcon = () => {
    return (
        <div>
            {' '}
            <svg width="30" height="30" viewBox="0 0 33 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.5 12.2778C13.9797 12.2778 11.85 14.4914 11.85 17.1111C11.85 19.7308 13.9797 21.9444 16.5 21.9444C19.0203 21.9444 21.15 19.7308 21.15 17.1111C21.15 14.4914 19.0203 12.2778 16.5 12.2778Z"
                    stroke="url(#paint0_linear_1470_2400)"
                    strokeWidth="1.6"
                />
                <path
                    d="M28.9 5.83333H24.8917L20.6958 1.47206C20.5522 1.32216 20.3814 1.20327 20.1933 1.12226C20.0052 1.04124 19.8036 0.999695 19.6 1H13.4C13.1964 0.999695 12.9948 1.04124 12.8067 1.12226C12.6186 1.20327 12.4478 1.32216 12.3041 1.47206L8.1083 5.83333H4.1C2.39035 5.83333 1 7.2785 1 9.05556V26.7778C1 28.5548 2.39035 30 4.1 30H28.9C30.6096 30 32 28.5548 32 26.7778V9.05556C32 7.2785 30.6096 5.83333 28.9 5.83333Z"
                    stroke="url(#paint1_linear_1470_2400)"
                    strokeWidth="1.6"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_1470_2400"
                        x1="18.021"
                        y1="1.36015"
                        x2="18.0007"
                        y2="30.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_1470_2400"
                        x1="18.021"
                        y1="1.36015"
                        x2="18.0007"
                        y2="30.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
