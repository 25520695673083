/* eslint-disable react-hooks/exhaustive-deps */
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OutlookSvg from 'files/outlook2.png';
import AppConnectionTopSection from 'components/organisms/AppConnectionTopSection';
import TimelineComponent from 'components/organisms/TimelineComponent';
import { Button } from 'components/atoms/buttons/Button';
import { getMSAuthUrl } from 'connected-apps/xero/auth';
import OrganizationIcon from 'utills/svgs/OrganizationIcon';
import DisconnectIcon from 'utills/svgs/DisconnectIcon';
import DialogModalComponent from 'global-components/DialogModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import { outlookGetAccessTokenThunk, outlookSynkClientThunk } from 'store/appConnectoins';
import { Switch } from '@headlessui/react';
import { setLoader } from 'store/global/globalReducer';
import { cn } from 'utills/tailwindUtil';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { Loader } from 'components/common/Loader';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';

const conntectInstructions = [
    `Click the "Connect" button in your Synkli application. This will open the Microsoft Outlook sign-in page.`,
    'Log in using your Microsoft Outlook credentials. If you have multiple accounts, ensure you select the correct one for integration.',
    `You may be prompted to grant Synkli permission to access your  <span className="text-third font-semibod" > Outlook Messages  </span> data. Follow the on-screen instructions to complete the authorization.`
];

export const OutLookMessagesAppConnections = () => {
    const [active, setActive] = useState('connect');

    const [enabled, setEnabled] = useState(true);

    const [modalType, setModalType] = useState(null);
    const [isLoadingBtn, setIsLoading] = useState(false);
    const { isLoading } = useSelector((state) => state.global);

    const navigate = useNavigate();

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchToken = async () => {
            const query = new URLSearchParams(location.search);
            const code = query.get('code');
            const error = query.get('error');

            if (error) {
                console.error('Query Error', error);
                setModalType('error');
            }
            if (code) {
                try {
                    dispatch(setLoader(true));
                    dispatch(
                        outlookGetAccessTokenThunk({
                            code: code
                        })
                    )
                        .then((res) => {
                            if (res.payload?.status === 'active') {
                                setModalType('success');
                            } else {
                                setModalType('error');
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setModalType('error');
                        })
                        .finally(() => {
                            dispatch(setLoader(false));
                        });
                } catch (error) {
                    console.error('Error fetching token:', error);
                }
            }
        };

        if (location.search) {
            fetchToken();
        }
    }, [location, navigate]);

    const handleConnect = () => {
        setIsLoading(true);
        getMSAuthUrl();
    };

    const handleSyncClients = () => {
        const payload = {
            allow_sync: enabled
        };
        dispatch(setLoader(true));
        setIsLoading(true);
        dispatch(outlookSynkClientThunk(payload)).finally(() => {
            dispatch(setLoader(false));
            setIsLoading(false);
            navigate('/app-connections');
        });
    };

    return (
        <div>
            {isLoading && <Loader />}

            <SidebarLayout>
                <Jumbotron
                    title="App Connections"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },

                        {
                            link: 'app-connections',
                            title: 'App Connections'
                        },
                        {
                            link: '',
                            title: 'Outlook Messages'
                        }
                    ]}
                />
                <RoleBasedGuard module={'App_connections'} permissions={['write', 'admin']} sx="h-[50vh]">
                    <div className="md:mx-10 mx-5 mt-5">
                        <AppConnectionTopSection
                            title={'Unify your workflow with Synkli and Outlook Messages!'}
                            description={`Simply connect your accounts to automatically sync all messages and send invites to clients for your upcoming appointments. Focus on what matters most – building strong client relationships. This seamless integration ensures everyone stays on the same page.`}
                            appIcon={OutlookSvg}
                            imgSx="!h-24"
                        />

                        <div className="px-10 py-12 bg-white rounded-2xl shadow-lg mt-7">
                            <div className="md:col-span-2 col-span-3  flex flex-wrap items-center">
                                <div className="isolate inline-flex rounded-md shadow-sm w-fit">
                                    <div className="flex gap-0 bg-transparent rounded-xl ring-1 ring-outside ring-gray-300 shadow-sm w-full">
                                        <div
                                            onClick={() => setActive('connect')}
                                            className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-9 ${
                                                active === 'connect' && 'bg-leaner '
                                            } `}
                                        >
                                            How to connect
                                        </div>
                                        <div
                                            onClick={() => setActive('used')}
                                            className={`py-2.5 cursor-pointer text-[12px] md:text-[14px] lg:text-[16px] font-poppins rounded-xl !px-9 ${
                                                active === 'used' && 'bg-leaner '
                                            }`}
                                        >
                                            How you data is used
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="min-h-[300px]">
                                {active === 'connect' && <TimelineComponent timeline={conntectInstructions} />}
                                {active === 'used' && (
                                    <div className="flow-root mt-10">
                                        <div className="text-[18px] font-normal  text-black">
                                            We prioritize the security of your data. Synkli only accesses the Outlook
                                            Messages and you explicitly authorize. Your information is kept safe, and we
                                            never modify it. You&apos;re always in control – you can disconnect or
                                            revoke access at any time. Once your Outlook Messages are linked, Synkli
                                            synchronises your messages, Ensuring efficient communication even with
                                            clients. This feature facilitates smoother onboarding, potentially allowing
                                            clients to complete necessary processes before their appointments. You can
                                            disable this synchronisation whenever you like.
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-center gap-5 mt-3">
                                <Button
                                    title={'Back'}
                                    classes={
                                        'font-semibold px-12 py-3 rounded-lg text-center place-content-center bg-white text-linear ring-2 ring-inset ring-primary'
                                    }
                                    atClick={() => {
                                        navigate(-1);
                                    }}
                                />
                                <Button
                                    title={'Connect'}
                                    classes={
                                        'font-semibold px-8 py-3 rounded-lg text-center place-content-center bg-linear text-white ring-2 ring-inset ring-primary'
                                    }
                                    atClick={() => handleConnect()}
                                    iconSet={{ loader: SmallLoaderWhite }}
                                    disabled={isLoadingBtn}
                                    isloading={isLoadingBtn}
                                />
                            </div>
                        </div>
                    </div>

                    {modalType === 'success' && (
                        <DialogModalComponent
                            open={modalType === 'success' ? true : false}
                            setOpen={() => {}}
                            sx="max-w-[460px]"
                            title="Outlook Messages Connected"
                            hideCross
                            icon={<OrganizationIcon />}
                        >
                            <p className="buttons-font-lighter text-gray-900 !font-medium font-poppins text-center">
                                Simply enable &quot;Automatic Sync&quot; to keep your appointments synced between both
                                Synkli and Outlook Messages. You can turn it off anytime you like.
                            </p>

                            <Switch.Group as="div" className="flex items-center justify-center gap-5 my-6">
                                <span className="flex">
                                    <Switch.Label
                                        as="span"
                                        className="text-md font-normal leading-6 text-gray-900"
                                        passive
                                    >
                                        Automatically Sync Messages
                                    </Switch.Label>
                                </span>
                                <Switch
                                    checked={enabled}
                                    onChange={() => setEnabled(!enabled)}
                                    className={cn(
                                        enabled ? 'bg-third' : 'bg-gray-200',
                                        'relative inline-flex mx-5 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-third focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={cn(
                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                            </Switch.Group>
                            <div className="flex justify-center items-center my-5">
                                <Button
                                    atClick={() => handleSyncClients()}
                                    title={'Finish'}
                                    iconSet={{ loader: SmallLoaderWhite }}
                                    disabled={isLoadingBtn}
                                    isloading={isLoadingBtn}
                                    classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                />
                            </div>
                        </DialogModalComponent>
                    )}

                    {modalType === 'error' && (
                        <DialogModalComponent
                            open={modalType === 'error' ? true : false}
                            setOpen={() => setModalType(null)}
                            sx="max-w-[460px]"
                            title="Outlook Messahes Connection Failed"
                            icon={<DisconnectIcon />}
                        >
                            <p className="buttons-font-lighter text-gray-900 !font-medium font-poppins text-center">
                                Configuration errors in the integration settings between Outlook Messages and Synkli.
                            </p>

                            <div className="flex justify-center items-center my-5">
                                <Button
                                    atClick={() => setModalType(null)}
                                    title={'Close'}
                                    classes={'rounded-lg ann-btn px-16 buttons-font-lighter font-fira'}
                                />
                            </div>
                        </DialogModalComponent>
                    )}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
