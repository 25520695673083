import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './css/addBranch.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { editBranch, setLoader } from 'store/global/globalReducer';
import { addBranchLocationThunk, getBranchLocationsThunk } from 'store/auth/slices';
import { SmallLoader } from 'components/common/SmallLoader';

import { NoDataFound } from 'components/common/NoDataFound';
import { Button } from 'components/atoms/buttons/Button';
import { BgEnvelope } from 'utills/svgs/BgEnvelope';
import { BgLocation } from 'utills/svgs/BgLocation';
import { BgTel } from 'utills/svgs/BgTel';
import { BgEditIcon } from 'utills/svgs/BgEditIcon';
const AddBranchLocation = ({ toggleBranchSide, isUpdated, setIsUpdatedBranch, toggleEditBranch }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);
    const [branchLocations, setBranchLocations] = useState(null);
    const { data: businessProfile } = useSelector((state) => state.getBusinessprofile);
    const data = useSelector((state) => state.login.data);

    useEffect(() => {
        dispatch(setLoader(true));
        setTimeout(() => {
            dispatch(getBranchLocationsThunk({ user_id: data?.user_id }))
                .then((response) => {
                    if (response.payload) {
                        if (response.payload.length == 0) {
                            const branchObj = {
                                title: businessProfile?.name,
                                phone_number: data?.phone_number,
                                email: businessProfile?.email,
                                address: data?.business?.address ?? 'N/A',
                                lat: '0',
                                lng: '0',
                                formatted_address: data?.business?.address ?? 'N/A'
                            };

                            dispatch(addBranchLocationThunk(branchObj))
                                .then((response) => {
                                    if (response.payload) {
                                        setIsUpdatedBranch(true);
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                })
                                .finally(() => {});
                        }
                        setBranchLocations(response.payload);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }, 500);
    }, [isUpdated]);

    const handleBranchUpdate = (branch) => {
        dispatch(editBranch(branch));

        toggleEditBranch();
    };
    return (
        <div className="add-branch py-2">
            {isLoading ? (
                <div className="branch-location-loader">
                    {' '}
                    <SmallLoader />{' '}
                </div>
            ) : (
                <div>
                    {' '}
                    <div className="flex justify-end mx-5 mt-3">
                        <Button
                            title="Add Branch Location"
                            atClick={toggleBranchSide}
                            classes="ann-btn px-4 rounded-xl buttons-font"
                        />
                    </div>
                    {branchLocations && !branchLocations[0] ? (
                        <NoDataFound message="No branch found! . click on top button to add one" />
                    ) : (
                        <div>
                            {' '}
                            {branchLocations?.map((location, index) => (
                                <div key={index}>
                                    <div className={`service-body  mx-5  mt-5`}>
                                        <div className="card-one">
                                            <div className="flex justify-between items-center gap-5 flex-wrap">
                                                {' '}
                                                <div className="table-title break-all">{location.title}</div>{' '}
                                                <div
                                                    className="cursor-pointer"
                                                    onClick={() => handleBranchUpdate(location)}
                                                >
                                                    {' '}
                                                    <BgEditIcon />{' '}
                                                </div>
                                            </div>
                                            <div className="card-body mt-5">
                                                <div>
                                                    <div className="flex gap-2 items-center">
                                                        <div className="flex justify-center items-center opacity-80">
                                                            <BgEnvelope />
                                                        </div>
                                                        <div>
                                                            <label className="buttons-font">Email</label>
                                                        </div>
                                                    </div>
                                                    <span className="buttons-font-lighter sub-head ml-10">
                                                        {location.email}
                                                    </span>
                                                </div>

                                                <div className="mt-3">
                                                    <div className="flex gap-2 items-center">
                                                        <div className="flex justify-center items-center">
                                                            <BgLocation />
                                                        </div>
                                                        <div>
                                                            <label className="buttons-font">Address</label>
                                                        </div>
                                                    </div>
                                                    <span className="sub-head ml-10 buttons-font-lighter">
                                                        {location.address}
                                                    </span>
                                                </div>

                                                <div className="mt-3">
                                                    <div className="flex gap-2 items-center">
                                                        <div className="flex justify-center items-center">
                                                            <BgTel />
                                                        </div>
                                                        <div>
                                                            <label className="buttons-font">Call us</label>
                                                        </div>
                                                    </div>
                                                    <span className="sub-head ml-10 buttons-font-lighter">
                                                        {location.phone_number}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 mx-5">
                                        <hr />
                                    </div>
                                </div>
                            ))}{' '}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AddBranchLocation;
