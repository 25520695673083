import React from 'react';

export const MultiSwitch = ({ items, disabled, onClick, cols }) => {
    return (
        <div
            className={`grid grid-cols-2 md:grid-cols-${
                cols ?? 3
            } !text-[15px] min-h-[3rem] ring-1  ring-secondarybg md:text-[17px] lg:text-[17px] xl:text-[18px] font-[400] cursor-pointer !rounded-xl ${
                disabled && 'opacity-50 !cursor-no-drop	'
            }`}
        >
            {items?.map((item, index) => (
                <div
                    key={'item' + index}
                    className={`field-switch h-[3rem] ${
                        item?.selected && 'bg-linear text-white border border-blueish '
                    } flex justify-center items-center gap-1 !rounded-xl`}
                    onClick={() => !disabled && onClick(item?.title)}
                >
                    {item?.title}

                    {item?.count && <span className="text-xs text-secondarybg">{`(${item?.count})`}</span>}
                </div>
            ))}
        </div>
    );
};
