import React from 'react';
import Header from 'components/Header';
import authRoutes from './auth';
import twoFactorRoutes from './authTwoFactor';
import workspaceRoutes from './workspaceRoutes';
import settingsRoutes from './routes-manager/settingsRoutes';
import unProtectedRoutes from './unProtectedRoutes';
import reportsRoutes from './reportsRoutes';
import appointmentRoutes from './appointmentRoutes';
import fileManagerRoutes from './fileManagerRoutes';
import { HEADERLAYOUT } from 'utills/globalVars';
import deductionsRoutes from './deductionsRoutes';
import businessDashboard from './businessDashboardRoutes';
import emailSettingsRoutes from './emailManagerRoutes';

let AllRoutes = [];

const headerLayout = {
    layout: HEADERLAYOUT,
    path: '/',
    element: <Header />,
    routes: authRoutes
};

const twoFactorLayout = {
    layout: 'none',
    path: '/',
    element: '',
    routes: twoFactorRoutes
};
const workspaceLayout = {
    layout: 'none',
    path: '/',
    element: '',
    routes: workspaceRoutes
};
const settingsLayout = {
    layout: 'none',
    path: '/',
    element: '',
    routes: settingsRoutes
};

const unProtectedRoutesLayout = {
    layout: 'none',
    path: '/',
    element: '',
    routes: unProtectedRoutes
};

const reportsLayout = {
    layout: 'none',
    path: '/',
    element: '',
    routes: reportsRoutes
};

const appointmentRoutesLayout = {
    layout: 'none',
    path: '/',
    element: '',
    routes: appointmentRoutes
};

const fileManagerLayout = {
    layout: 'FileManager',
    path: '/',
    element: '',
    routes: fileManagerRoutes
};

const customerDeductionsRoutes = {
    layout: 'deductions',
    path: '/',
    element: '',
    routes: deductionsRoutes
};

const businessDashboardRoute = {
    layout: 'businessDashboard',
    path: '/',
    element: '',
    routes: businessDashboard
};
const emailSettingsRoutesArr = {
    layout: 'emailSettingsRoutes',
    path: '/',
    element: '',
    routes: emailSettingsRoutes
};
AllRoutes.unshift(
    headerLayout,
    twoFactorLayout,
    workspaceLayout,
    settingsLayout,
    unProtectedRoutesLayout,
    reportsLayout,
    appointmentRoutesLayout,
    fileManagerLayout,
    customerDeductionsRoutes,
    businessDashboardRoute,
    emailSettingsRoutesArr
);

export { AllRoutes };
