import React from 'react';

export const AvifFile = ({ className }) => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M30.2222 0H3.77778C1.7 0 0 1.7 0 3.77778V30.2222C0 32.3 1.7 34 3.77778 34H30.2222C32.3 34 34 32.3 34 30.2222V3.77778C34 1.7 32.3 0 30.2222 0ZM12.2778 16.0556C12.2778 17.5667 10.9556 18.8889 9.44444 18.8889H7.55556V22.6667H4.72222V11.3333H9.44444C10.9556 11.3333 12.2778 12.6556 12.2778 14.1667V16.0556ZM21.7222 19.8333C21.7222 21.3444 20.4 22.6667 18.8889 22.6667H14.1667V11.3333H18.8889C20.4 11.3333 21.7222 12.6556 21.7222 14.1667V19.8333ZM29.2778 14.1667H26.4444V16.0556H29.2778V18.8889H26.4444V22.6667H23.6111V11.3333H29.2778V14.1667ZM17 14.1667H18.8889V19.8333H17V14.1667ZM7.55556 14.1667H9.44444V16.0556H7.55556V14.1667Z"
                fill="#B695F8"
            />
            <rect y="8" width="34" height="16.79" rx="4" fill="white" />
            <path
                d="M0.540527 8H33.4385C33.7507 8 34 8.35953 34 8.80696V24.195C34 24.6425 33.7507 25 33.4385 25H0.540527C0.249259 25 0 24.6425 0 24.195V8.80696C0 8.35953 0.249259 8 0.540527 8Z"
                fill="#B695F8"
            />
            <path
                d="M7.3678 12.4716C7.14755 11.9013 6.22118 11.9013 6.00092 12.4716L3.05337 19.9715C2.90114 20.3539 3.08576 20.7923 3.46473 20.944C3.84047 21.0989 4.26802 20.911 4.42026 20.5286L5.41465 18.0001H7.95407L8.94847 20.5286C9.06183 20.8187 9.34039 21 9.63191 21C9.7226 21 9.81654 20.9802 9.90399 20.944C10.283 20.7923 10.4676 20.3539 10.3154 19.9715L7.3678 12.4716ZM6.00416 16.5001L6.68436 14.7694L7.36457 16.5001H6.00416ZM17.6842 13.0288L14.7367 20.5286C14.6265 20.8121 14.3545 21 14.0532 21C13.752 21 13.4799 20.8121 13.3698 20.5286L10.4222 13.0288C10.27 12.6431 10.4546 12.2079 10.8304 12.053C11.2061 11.9013 11.6369 12.0859 11.7891 12.4716L14.0532 18.2275L16.3173 12.4716C16.4663 12.0859 16.8971 11.9013 17.2729 12.053C17.6518 12.2079 17.8332 12.6431 17.6842 13.0288ZM22.1574 13.5002V19.5H22.8959C23.3007 19.5 23.6311 19.833 23.6311 20.2484C23.6311 20.6637 23.3007 21 22.8959 21H19.9483C19.5402 21 19.2098 20.6637 19.2098 20.2484C19.2098 19.833 19.5402 19.5 19.9483 19.5H20.6836V13.5002H19.9483C19.5402 13.5002 19.2098 13.1639 19.2098 12.7485C19.2098 12.3332 19.5402 12.0002 19.9483 12.0002H22.8959C23.3007 12.0002 23.6311 12.3332 23.6311 12.7485C23.6311 13.1639 23.3007 13.5002 22.8959 13.5002H22.1574ZM31 17.2484C31 17.6638 30.6696 18.0001 30.2647 18.0001H26.5787V20.2484C26.5787 20.6637 26.2483 21 25.8434 21C25.4353 21 25.1049 20.6637 25.1049 20.2484V13.5002C25.1049 12.6727 25.7657 12.0002 26.5787 12.0002H30.2647C30.6696 12.0002 31 12.3332 31 12.7485C31 13.1639 30.6696 13.5002 30.2647 13.5002H26.5787V16.5001H30.2647C30.6696 16.5001 31 16.8331 31 17.2484Z"
                fill="white"
            />
        </svg>
    );
};
