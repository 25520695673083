import React from 'react';

export const RenameIcon = ({ className }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="currentColor"
        >
            <path
                d="M18.4391 8.15395V11.7435H16.9467V9.03043V8.73043H16.6467H11.7158V4.0087V3.7087H11.4158H4.09239H3.79239V4.0087V20.0783V20.3783H4.09239H10.0696V21.787H4.09239C3.59323 21.787 3.17561 21.6196 2.82292 21.281C2.46981 20.942 2.3 20.5463 2.3 20.0783V4.0087C2.3 3.54064 2.46981 3.14496 2.82292 2.80597C3.17561 2.46739 3.59323 2.3 4.09239 2.3H12.3413L18.4391 8.15395ZM16.9606 14.9953L17.6513 15.6584L13.8242 19.3088L13.7312 19.3974V19.5259V20.5804V20.8804H14.0312H15.1298H15.2499L15.3368 20.7975L19.1831 17.1289L19.8491 17.7683L15.6629 21.787H12.762V19.026L16.9606 14.9953ZM21.5915 16.0955L20.2823 17.3524L19.3915 16.4973L19.1844 16.2985L18.5192 15.6598L18.293 15.4427L17.3938 14.5795L18.6853 13.3396C18.8147 13.2154 18.9813 13.147 19.2099 13.147C19.4386 13.147 19.6052 13.2154 19.7345 13.3396L21.5915 15.1223C21.7199 15.2456 21.787 15.3996 21.787 15.6089C21.787 15.8182 21.7199 15.9723 21.5915 16.0955Z"
                strokeWidth="0.6"
            />
        </svg>
    );
};
