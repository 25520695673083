import React from 'react';
import './css/addedForms.css';
import { useState, useEffect } from 'react';
import PrebuiltForms from './added-forms-lists/PrebuiltForms';
import LiveForms from './added-forms-lists/LiveForms';
import DraftForms from './added-forms-lists/DraftForms';
import { motion } from 'framer-motion';
import { SideTimes } from '../../../../utills/svgs/SideTimes';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/common/Loader';
import cloneDeep from 'lodash/cloneDeep';
import { useGetForms } from 'Hooks/useGetForms';
import { Button } from 'components/atoms/buttons/Button';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';

const AddedForms = ({ toggleCreateForm, toggleAddedForms, togglePreviewForm, handleUpdateForm, updateTable }) => {
    const [condition, setCondition] = useState('prebuilt');
    const [selectedForms, setSelectedForms] = useState([]);
    const { isLoading } = useSelector((state) => state.global);
    const [formsUpdated, setFormsUpdated] = useState(false);
    const dispatch = useDispatch();

    const toggleFormsUpdated = () => {
        setFormsUpdated(!formsUpdated);
    };

    useEffect(() => {
        handleFetchForms(condition);
    }, [dispatch, formsUpdated]);

    const handleCondition = (condition, pageSize) => {
        handleFetchForms(condition, pageSize);
        setCondition(condition);
    };

    const { fetchForms } = useGetForms();

    const handleFetchForms = (form_status, pageSize = 10) => {
        fetchForms(form_status, pageSize);
    };

    const handleForm = (form) => {
        const newSelected = cloneDeep(selectedForms);
        const alreadyExist = newSelected?.find((item) => item.form_id === form?.form_id);
        if (alreadyExist) {
            const index = newSelected?.indexOf(alreadyExist);
            newSelected.splice(index, 1);
            setSelectedForms(newSelected);
        } else {
            newSelected.push(form);
            setSelectedForms(newSelected);
        }
    };

    const componentProvider = () => {
        return (
            <div>
                {condition === 'prebuilt' && (
                    <PrebuiltForms
                        handleForm={handleForm}
                        handleUpdateForm={handleUpdateForm}
                        togglePreviewForm={togglePreviewForm}
                        updateTable={updateTable}
                        handleCondition={handleCondition}
                    />
                )}
                {condition === 'live' && (
                    <LiveForms
                        handleUpdateForm={handleUpdateForm}
                        toggleFormsUpdated={toggleFormsUpdated}
                        togglePreviewForm={togglePreviewForm}
                        updateTable={updateTable}
                    />
                )}
                {condition === 'draft' && (
                    <DraftForms
                        handleUpdateForm={handleUpdateForm}
                        toggleFormsUpdated={toggleFormsUpdated}
                        togglePreviewForm={togglePreviewForm}
                        updateTable={updateTable}
                    />
                )}
            </div>
        );
    };

    const handleCreateForm = () => {
        toggleCreateForm(condition);
    };

    const closeAddedForms = () => {
        toggleAddedForms();
    };

    return (
        <div className="add-p-side grid grid-cols-10  ">
            <div className="col-span-2 left-side" onClick={toggleAddedForms}></div>
            <div className="right-side col-span-8">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    {isLoading && <Loader />}
                    <div>
                        <div
                            onClick={closeAddedForms}
                            className="absolute  z-50 text-white p-2 right-1 top-1 cursor-pointer"
                        >
                            <SideTimes />
                        </div>

                        <div className="add-detail pt-10 px-5 relative">
                            <div className="flex absolute right-8 gap-2 top-16 ">
                                <div>
                                    <TooltipChipWrap title="click to create form" variant="soft" placement="left">
                                        <div>
                                            {' '}
                                            <Button
                                                title="+ Create Form"
                                                atClick={() => handleCreateForm()}
                                                classes="px-5 rounded-xl ann-btn font-poppins  buttons-font-lighter font-fira "
                                            />
                                        </div>
                                    </TooltipChipWrap>
                                </div>
                            </div>
                            <div className="title flex gap-4 items-center">
                                <div onClick={closeAddedForms} className="cursor-pointer">
                                    <svg
                                        width="16"
                                        height="20"
                                        viewBox="0 0 14 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M13.5371 24.5341C12.9199 25.1553 11.9191 25.1553 11.3019 24.5341L0.46292 13.6248C-0.154309 13.0036 -0.154309 11.9964 0.46292 11.3752L11.3019 0.465923C11.9191 -0.155308 12.9199 -0.155308 13.5371 0.465923C14.1543 1.08715 14.1543 2.09437 13.5371 2.7156L3.81569 12.5L13.5371 22.2844C14.1543 22.9056 14.1543 23.9128 13.5371 24.5341Z"
                                            fill="#666666"
                                        />
                                    </svg>
                                </div>
                                <div>Added Forms</div>
                            </div>

                            <div className="small-text font-poppins mt-3">Forms &gt; Added Forms</div>
                        </div>
                    </div>{' '}
                    <div className="added-forms-operations ">
                        <div className="forms-buttons mt-3 mx-5 flex gap-3 items-center ">
                            <div
                                onClick={() => handleCondition('prebuilt')}
                                className={`forms-btn cursor-pointer hover:border-thirdbg  px-7 shadow-xl ${
                                    condition === 'prebuilt' && 'forms-active-btn'
                                }`}
                            >
                                {' '}
                                Prebuilt Forms{' '}
                            </div>
                            <div
                                onClick={() => handleCondition('live')}
                                className={`forms-btn cursor-pointer  hover:border-thirdbg px-10  shadow-xl ${
                                    condition === 'live' && 'forms-active-btn'
                                }`}
                            >
                                {' '}
                                Active Forms{' '}
                            </div>
                            <div
                                onClick={() => handleCondition('draft')}
                                className={`forms-btn cursor-pointer hover:border-thirdbg px-10  shadow-xl ${
                                    condition === 'draft' && 'forms-active-btn'
                                }`}
                            >
                                {' '}
                                Draft Forms{' '}
                            </div>
                        </div>
                    </div>
                    <div className="p-5 mt-3">{componentProvider()}</div>{' '}
                </motion.div>
            </div>
        </div>
    );
};

export default AddedForms;
