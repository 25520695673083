/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { NoDataFound } from 'components/common/NoDataFound';
import { DefaultLogs } from 'components/molecules/DefaultLogs';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SummaryDrawer } from 'components/organisms/SummaryDrawer';
import { EXPENSE_ARRAY } from 'playground';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { viewBusinessCustomerInfoThunk } from 'store/client';
import { getBusinessesThunk, getSummaryLogsThunk } from 'store/deductions';
import {
    handleDeductionType,
    handleExpenseTypeId,
    handleSummaryDates,
    handleSummaryType,
    handleSunmmaryId,
    setLoader,
    setSideLoader
} from 'store/global/globalReducer';
import { BUSINESS_ID } from 'utills/globalVars';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { FillArrow } from 'utills/svgs/FillArrow';
import { ProfitAndLoss } from './ProfitAndLoss';
import { BoxSkeleton } from 'components/molecules/skeletons/BoxSkeleton';
import { SummaryListing } from './SummaryListing';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { UseDownloadLogsPdf } from 'Hooks/Files/UseDownloadLogsPdf';
import { endpoint } from 'apiEndpoints';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import moment from 'moment';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const AbnDeduction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [showSummary, setShowSummary] = useState(false);
    const { id } = useParams();
    const { data } = useSelector((state) => state.getBusinesses);
    const [theBusinesses, setTheBusinesses] = useState([]);
    const { isLoading } = useSelector((state) => state.global);
    const { data: customer } = useSelector((state) => state.viewBusinessCustomerInfo);
    const [showLogs, setShowLogs] = useState(false);
    const [openIndexes, setOpenIndexes] = useState([]);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [showProfitAndLost, setShowProfitAndLoss] = useState(false);
    const [showExpenseTypeDetail, setshowExpenseTypeDetail] = useState(false);
    const { handleDownloadLogsPdf } = UseDownloadLogsPdf();

    const [tempData, setTempData] = useState(null);

    const [detailIndex, setDetailIndex] = useState(null);

    const [page, setPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [count, setCount] = useState(null);
    const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
    const [term, setTerm] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [filteredLogs, setfilteredLogs] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [filterCount, setFilterCount] = useState(1);

    const business_id = localStorage.getItem(BUSINESS_ID);

    const location = useLocation();
    console.error('location', location);
    const queryParams = new URLSearchParams(location.search);
    const queryId = queryParams.get('summary_id');
    const customer_business_id = queryParams.get('record_id');

    useEffect(() => {
        if (queryId) {
            const findSummary = theBusinesses?.find((item) => item.customer_business_id === customer_business_id);
            const findIndex = theBusinesses?.findIndex((obj) => obj.customer_business_id === customer_business_id);
            if (findIndex !== -1) {
                toggleDetails(findIndex);
                setTempData(findSummary);
                toggleSummary(queryId, '', '', 'all', 'abn');
            }
        }
    }, [queryId, theBusinesses]);

    const toggleLogs = () => {
        setShowLogs(!showLogs);
    };

    const toggleProfitAndLoss = (id) => {
        if (id) {
            dispatch(handleSunmmaryId(id));
        }
        setShowProfitAndLoss(!showProfitAndLost);
    };
    const toggleExpenseTypeDetail = (id) => {
        if (id) {
            dispatch(handleExpenseTypeId(id));
        }
        setshowExpenseTypeDetail(!showExpenseTypeDetail);
    };

    const toggleDetails = (index) => {
        setDetailIndex(index);
        const newIndexes = [...openIndexes];

        const alreadyExists = newIndexes?.find((item) => item?.key === index);
        if (alreadyExists) {
            const newIndex = newIndexes?.indexOf(alreadyExists);
            newIndexes?.splice(newIndex, 1);
        } else {
            newIndexes.push({ key: index });
        }
        setOpenIndexes(newIndexes);
        setIsDetailOpen(!isDetailOpen);
    };

    const toggleSummary = (id, from, to, summary_type, deduction_type) => {
        if (id) {
            dispatch(handleSunmmaryId(id));
        }
        if (deduction_type) {
            dispatch(handleDeductionType(deduction_type));
        }
        if ((from && to, summary_type)) {
            dispatch(
                handleSummaryDates({
                    from,
                    to
                })
            );
            dispatch(handleSummaryType(summary_type));
        }
        setShowSummary(!showSummary);
    };

    const closeSummary = () => {
        setShowSummary(false);
        setTempData(null);
    };

    const tableOneHeadings = ['Business Name', 'Business  Type', 'ABN Number', 'Business Address'];

    const summaryHeadings = ['Description', 'Expense', 'GST', 'Type', 'Date', 'Image'];

    const backArrowProp = {
        icon: ArrowLeft,
        atClick: () => {
            navigate(`/customer/deductions/${id}`);
        }
    };

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(getBusinessesThunk({ business_client_id: id }))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }, []);

    const handleSearch = (term) => {
        setSearchTerm(term);

        const filteredInfo = data?.filter((item) => item?.name?.toLowerCase().includes(term?.toLowerCase()));
        setTheBusinesses(filteredInfo);
    };

    useEffect(() => {
        if (data) {
            setTheBusinesses(data);
        }
    }, [data]);

    const fetchCustomer = () => {
        dispatch(setLoader(true));
        const payload = {
            business_client_id: id
        };
        dispatch(viewBusinessCustomerInfoThunk(payload))
            .then((response) => {})
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        fetchCustomer();
    }, []);

    const managerActions = [
        {
            title: 'View Logs',
            classes: 'flex gap-2 items-center px-8 ann-btn rounded-xl',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                toggleLogs();
            }
        }
    ];

    const fetchLogs = () => {
        const payload = {
            business_client_id: id,
            deduction_type: 'abn',
            page: term || dateFrom || dateTo ? filterPage : page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            business_id,
            filter: {
                text: term,
                from_date: dateFrom && moment(new Date(dateFrom)).format('DD-MM-YYYY'),
                end_date: dateTo && moment(new Date(dateTo)).format('DD-MM-YYYY')
            }
        };

        dispatch(setSideLoader(true));
        dispatch(getSummaryLogsThunk(payload))
            .then((response) => {
                if (response.payload) {
                    if (dateFrom || dateTo || term) {
                        setFilterCount(response?.payload?.count);
                        const newLogs = [...filteredLogs, ...response.payload.logs];
                        setfilteredLogs(newLogs);
                    } else {
                        setCount(response?.payload?.count);
                        if (page > 1) {
                            const newLogs = [...logs, ...response.payload.logs];
                            setLogs(newLogs);
                        } else {
                            const newLogs = response.payload.logs;
                            setLogs(newLogs);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handleMore = () => {
        const totalPages = Math.ceil(count / Number(PAGINATION_PAGE_SIZE));
        const togleFilterPages = Math.ceil(filterCount / Number(PAGINATION_PAGE_SIZE));

        if (dateFrom || dateTo || term) {
            if (filterPage < togleFilterPages) {
                setFilterPage(filterPage + 1);
            }
            return;
        }
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handleDownloadActivity = () => {
        const payload = {
            business_client_id: id,
            business_id: business_id,
            deduction_type: 'abn'
        };
        handleDownloadLogsPdf(payload, endpoint.downaloadDeductionsLogsPdf);
    };

    return (
        <SidebarLayout>
            <div>
                <div className="app-dashboard relative  ">
                    {showProfitAndLost && (
                        <ProfitAndLoss
                            open={showProfitAndLost}
                            setOpen={setShowProfitAndLoss}
                            toggleProfitAndLoss={toggleProfitAndLoss}
                        />
                    )}

                    {showLogs && (
                        <DefaultLogs
                            toggleLogs={toggleLogs}
                            fetchLogs={fetchLogs}
                            logs={dateFrom || dateTo || term ? filteredLogs : logs}
                            count={count}
                            pageSize={pageSize}
                            page={page}
                            handleMore={handleMore}
                            term={term}
                            setTerm={setTerm}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            dateTo={dateTo}
                            setDateTo={setDateTo}
                            downloadActivity={handleDownloadActivity}
                            setFilterPage={setFilterPage}
                            filterPage={filterPage}
                            filterCount={filterCount}
                            setfilteredLogs={setfilteredLogs}
                            open={showLogs}
                            setOpen={setShowLogs}
                        />
                    )}
                    {showSummary && (
                        <SummaryDrawer
                            title={tempData?.name + ' Business Deduction'}
                            onToggle={closeSummary}
                            setOpen={setShowSummary}
                            open={showSummary}
                            headings={summaryHeadings}
                            cols={6}
                            gap={5}
                            tableBody={EXPENSE_ARRAY}
                            showSwitch={true}
                            summaryType="all"
                        />
                    )}

                    <RoleBasedGuard module={'Clients'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                        <div>
                            <Jumbotron
                                title="Business"
                                path={[
                                    { title: 'Clients', link: 'clients' },
                                    {
                                        title: `${
                                            customer?.client_type === 'prospective_client'
                                                ? 'Prospective Client'
                                                : customer?.client_type === 'active_client'
                                                ? 'Active Client'
                                                : customer?.client_type === 'inactive_client'
                                                ? 'Inactive Client'
                                                : 'Client Profile'
                                        }`,
                                        link: `app/users?type=${
                                            customer?.client_type === 'prospective_client'
                                                ? 'prospective'
                                                : customer?.client_type === 'active_client'
                                                ? 'active'
                                                : customer?.client_type === 'inactive_client'
                                                ? 'invites'
                                                : 'all'
                                        }&reload=false`
                                    },
                                    {
                                        title: ` ${customer?.first_name} ${customer?.last_name}`,
                                        link: `app/user/profile/${id}`
                                    },
                                    { title: `Deduction`, link: `customer/deductions/${id}` },
                                    { title: `Business`, link: '' }
                                ]}
                                backArrowProp={backArrowProp}
                                actionsRequired={true}
                                actions={managerActions}
                            />

                            <div className="mt-2 md:mx-10 mx-5">
                                <SearchInput
                                    width="350px"
                                    atChange={(e) => {
                                        handleSearch(e.target.value);
                                    }}
                                    placeholder="Search"
                                />
                                <div className="mt-5">
                                    {isLoading ? (
                                        <BoxSkeleton />
                                    ) : (
                                        <div className={`default-table-container  rounded-lg py-5 `}>
                                            {theBusinesses && theBusinesses?.length > 0 ? (
                                                theBusinesses?.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className={`mt-2 bg-white py-3 rounded-xl border ${
                                                            index > 0 && 'mt-3'
                                                        }`}
                                                    >
                                                        <div className={`grid grid-cols-${5} gap-${5} px-5`}>
                                                            {tableOneHeadings?.map((heading, index) => (
                                                                <div key={index} className="default-table-heading">
                                                                    {heading}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <hr className="mt-2" />
                                                        <div className={`grid grid-cols-5 px-5 gap-${5} mt-2`}>
                                                            <div className={`summary-body flex items-center  `}>
                                                                {item?.name}
                                                            </div>
                                                            <div className={`summary-body flex items-center  `}>
                                                                {item?.business_type}
                                                            </div>
                                                            <div className={`summary-body flex items-center  `}>
                                                                {item?.abn}
                                                            </div>
                                                            <div className={`summary-body flex items-center  `}>
                                                                {item?.address}
                                                            </div>
                                                            <div className="flex justify-end items-center">
                                                                <span
                                                                    className={`cursor-pointer transition-transform transform ${
                                                                        openIndexes?.find((item) => item?.key === index)
                                                                            ? 'rotate-180'
                                                                            : 'rotate-0'
                                                                    }`}
                                                                    onClick={() => toggleDetails(index)}
                                                                >
                                                                    <span className="inline-block">
                                                                        {' '}
                                                                        <FillArrow />
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {openIndexes?.find((item) => item?.key === index) ? (
                                                            <SummaryListing
                                                                record_id={`${item?.customer_business_id}`}
                                                                business_client_id={id}
                                                                deduction_type="abn"
                                                                toggleSummary={(
                                                                    summary_id,
                                                                    from_date,
                                                                    to_date,
                                                                    summary_type,
                                                                    deduction_type
                                                                ) => {
                                                                    toggleSummary(
                                                                        summary_id,
                                                                        from_date,
                                                                        to_date,
                                                                        summary_type,
                                                                        deduction_type
                                                                    );
                                                                    setTempData(item);
                                                                }}
                                                                toggleProfitAndLoss={toggleProfitAndLoss}
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <NoDataFound height="30vh" message="No Business Found" />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>{' '}
                    </RoleBasedGuard>
                </div>
            </div>
        </SidebarLayout>
    );
};
