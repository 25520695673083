import React from 'react';

export const BackArrow = () => {
    return (
        <div>
            <svg width="35" height="19" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.1199 0.447335C12.6462 1.02223 12.6231 1.93037 12.0683 2.47572L4.85611 9.56522L22.6154 9.56521C23.3801 9.56521 24 10.2076 24 11C24 11.7924 23.3801 12.4348 22.6154 12.4348L4.85611 12.4348L12.0683 19.5243C12.6231 20.0696 12.6462 20.9778 12.1199 21.5527C11.5936 22.1276 10.7173 22.1515 10.1625 21.6061L0.431695 12.0409C0.156086 11.77 1.99213e-06 11.3937 1.9233e-06 11C1.85447e-06 10.6063 0.156085 10.23 0.431695 9.95906L10.1625 0.393848C10.7173 -0.151504 11.5936 -0.127558 12.1199 0.447335Z"
                    fill="url(#paint0_linear_2369_4965)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_2369_4965"
                        x1="13.1776"
                        y1="0.273216"
                        x2="13.1625"
                        y2="22"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
