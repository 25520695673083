import { CheckIcon } from '@heroicons/react/20/solid';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import Slideover from 'components/atoms/SlideOver';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import SearchableComboboxSSR from 'components/molecules/comboboxes/SearchableComboboxSSR';
import { cn } from 'pages/documents/hooks/helper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobsThunk, getAllMilestonesThunk } from 'store/JobManager';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { BUSINESS_ID, TOAST_TYPE_ERROR } from 'utills/globalVars';
import { CreateJob } from './CreateJob';
import MilestoneForm from '../job-manager-detail-components/MilestonesAndTasks/MilestoneForm';
import { Button } from 'components/atoms/buttons/Button';
import { toastHandler } from 'responseHanlder';
import CreateTask from 'global-components/CreateTask';
import { setLoader } from 'store/global/globalReducer';
import { getAllJobsStatusesThunk } from 'store/workspace/workspaceTasks';
import { JOB_CANCELLED, JOB_CLOSED, JOB_COMPLETED } from 'pages/settings/job-manager/jobs.constants';

const CreateTaskWithJob = ({ open = false, setOpen, actionType, jobRefData }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data: getSingleJob } = useSelector((state) => state?.getSingleJob);
    const [prefieldData, setPrefieldData] = useState(null);

    const [currentAction, setCurrentAction] = useState(actionType || 'select');
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [createNewJob, setCreateNewJob] = useState(false);
    const [createNewMilestone, setCreateNewMilestone] = useState(false);
    const [createTask, setCreateTask] = useState(false);
    const [taskType, setTaskType] = useState('createTaskWithJob');
    const { data: jobStatuses } = useSelector((state) => state.getAllJobsStatuses);

    useEffect(() => {
        if (!jobStatuses) {
            fetchJobStatuses();
        }
    }, []);

    const fetchJobStatuses = () => {
        dispatch(setLoader(true));
        dispatch(getAllJobsStatusesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    useEffect(() => {
        if (jobRefData && taskType === 'createTaskWithJob') {
            handlePrefieldData(jobRefData);
        } else {
            setPrefieldData(null);
        }
    }, [jobRefData, selectedMilestone, selectedJob, taskType]);

    const handlePrefieldData = (jobObj) => {
        setPrefieldData({
            job_obj: selectedJob || null,
            milestone_obj: selectedMilestone || null,
            title: jobObj?.subject ?? jobObj?.title,
            body: jobObj?.body ?? jobObj?.message,
            job_email_id: jobObj?.job_email_responces_id || jobObj?.job_email_id
        });
    };

    useEffect(() => {
        return () => {
            setCurrentAction('select');
        };
    }, []);

    useEffect(() => {
        if (getSingleJob) {
            setSelectedJob(getSingleJob);
        }
    }, [getSingleJob]);

    const fetchJobs = async (payload) => {
        let searchValue = payload?.search_term;
        if (payload?.search_term?.includes('|')) {
            searchValue = searchValue?.split('|', 1);
        }

        const payloadNew = {
            ...payload,
            search_term: searchValue,
            page_size: PAGINATION_PAGE_SIZE,
            business_id: business_id,
            job_category: 'all_job',
            status: jobStatuses
                ?.filter((obj) => ![JOB_CLOSED, JOB_CANCELLED, JOB_COMPLETED].includes(obj?.label))
                ?.map((obj) => obj.job_status_id)
        };
        return await dispatch(getAllJobsThunk(payloadNew));
    };
    const fetchMilestones = async (payload) => {
        let searchValue = payload?.search_term;
        if (payload?.search_term?.includes('|')) {
            searchValue = searchValue?.split('|', 1);
        }
        const payloadNew = {
            ...payload,
            business_id: business_id,
            job_id: selectedJob?.job_id,
            search_term: searchValue,
            page_size: PAGINATION_PAGE_SIZE
        };
        return await dispatch(getAllMilestonesThunk(payloadNew));
    };

    const handleNext = () => {
        if (taskType === 'createTaskWithJob') {
            if (!selectedJob) {
                return toastHandler('Please Select a Job First', TOAST_TYPE_ERROR);
            }
            if (!selectedMilestone) {
                return toastHandler('Please Select a Milestone', TOAST_TYPE_ERROR);
            }
        }

        setCreateTask(true);
    };

    return (
        <div>
            {currentAction === 'select' && (
                <Slideover
                    size="max-w-6xl"
                    open={open}
                    setOpen={(v) => {
                        setOpen(v);
                    }}
                    title={'Create Milestone or Tasks'}
                    titlesx={'text-3xl font-fira font-[500] !m-0'}
                    flexSx="!items-center"
                    hideBackArrow
                    footer={
                        <>
                            <div className="flex items-center gap-3 justify-center mt-9 mb-4">
                                <Button
                                    title={'Next'}
                                    atClick={() => handleNext()}
                                    classes="primaryButton flex gap-3 bg-leaner child:text-semibold text-white py-3 px-16"
                                />
                            </div>
                        </>
                    }
                >
                    <div className="mt-6 flex flex-col gap-3">
                        <DefaultLightBgCard
                            title="Create New Milestone/Task"
                            htmlFor="createTaskWithJob"
                            sx={cn(taskType === 'createTaskWithJob' && 'ring-2 ring-inset ring-third')}
                            rightButton={
                                <div className="flex h-6 items-center">
                                    <input
                                        defaultChecked={taskType === 'createTaskWithJob'}
                                        onChange={(e) => {
                                            if (e.target?.checked) {
                                                setTaskType('createTaskWithJob');
                                            }
                                        }}
                                        id={'createTaskWithJob'}
                                        name="tasktype"
                                        type="radio"
                                        aria-describedby={`createTaskWithJob-description`}
                                        className="size-4 border-gray-300 text-third focus:ring-third cursor-pointer"
                                    />
                                </div>
                            }
                        >
                            <PrimaryParagraph sx={'!text-md cursor-pointer text-gray-700 mt-1'}>
                                Create a new job or Attach this task to an existing job by selecting the job and
                                specifying a relevant milestone for organization.
                            </PrimaryParagraph>
                            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5 mb-6">
                                <div className="w-full mt-3">
                                    <div>
                                        <div className="">
                                            <label className="inputLabel">Select Job</label>
                                        </div>
                                        {jobStatuses && (
                                            <div className="mt-1">
                                                <SearchableComboboxSSR
                                                    fetchDataFunction={fetchJobs}
                                                    defaultSelected={selectedJob}
                                                    removePreviousSelected
                                                    disabled
                                                    displayCustomInputValue={(obj) => {
                                                        return obj?.ref_no + ' | ' + obj?.title;
                                                    }}
                                                    displayContent={(obj, active, selected) => {
                                                        return (
                                                            <>
                                                                <span
                                                                    className={cn(
                                                                        'block truncate',
                                                                        selected && 'font-semibold'
                                                                    )}
                                                                >
                                                                    {obj?.ref_no + ' | ' + obj?.title || 'undefined'}
                                                                </span>

                                                                {selected && (
                                                                    <span
                                                                        className={cn(
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                            active ? 'text-white' : 'text-third'
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                )}
                                                            </>
                                                        );
                                                    }}
                                                    placeholder="Select Job"
                                                    inputsx="!font-normal !text-sm !w-full"
                                                    sx="!rounded-xl  !font-normal !ring-gray-400 mt-2 !flex py-2"
                                                    onChange={(obj) => {
                                                        setSelectedMilestone(null);
                                                        if (obj?.length > 0) {
                                                            setSelectedJob(obj[0]);
                                                        } else {
                                                            setSelectedJob(null);
                                                        }
                                                    }}
                                                    limit={1}
                                                />
                                            </div>
                                        )}
                                        {!selectedJob && (
                                            <div onClick={() => setCreateNewJob(true)}>
                                                <PrimaryParagraph
                                                    sx={
                                                        '!text-sm italic cursor-pointer text-gray-700 mt-1 underline underline-offset-4'
                                                    }
                                                >
                                                    Create new Job
                                                </PrimaryParagraph>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {selectedJob && (
                                    <div className="w-full mt-3">
                                        <div>
                                            <div className="">
                                                <label className="inputLabel">Select Milestone</label>
                                            </div>
                                            <div className="mt-1">
                                                <SearchableComboboxSSR
                                                    fetchDataFunction={fetchMilestones}
                                                    defaultSelected={selectedMilestone}
                                                    removePreviousSelected
                                                    showCancelBtn
                                                    targetResponseProperty="rows"
                                                    displayCustomInputValue={(obj) => {
                                                        return obj?.title;
                                                    }}
                                                    displayContent={(obj, active, selected) => {
                                                        return (
                                                            <>
                                                                <span
                                                                    className={cn(
                                                                        'block truncate',
                                                                        selected && 'font-semibold'
                                                                    )}
                                                                >
                                                                    {obj?.title || 'undefined'}
                                                                </span>

                                                                {selected && (
                                                                    <span
                                                                        className={cn(
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                            active ? 'text-white' : 'text-third'
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                )}
                                                            </>
                                                        );
                                                    }}
                                                    placeholder="Select Milestone"
                                                    inputsx="!font-normal !text-sm !w-full"
                                                    sx="!rounded-xl  !font-normal !ring-gray-400 mt-2 !flex py-2"
                                                    onChange={(obj) => {
                                                        if (obj?.length > 0) {
                                                            setSelectedMilestone(obj[0]);
                                                        } else {
                                                            setSelectedMilestone(null);
                                                        }
                                                    }}
                                                    limit={1}
                                                />
                                            </div>
                                            {!selectedMilestone && (
                                                <div onClick={() => setCreateNewMilestone(true)}>
                                                    <PrimaryParagraph
                                                        sx={
                                                            '!text-sm italic cursor-pointer text-gray-700 mt-1 underline underline-offset-4'
                                                        }
                                                    >
                                                        Create new Milestone
                                                    </PrimaryParagraph>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </DefaultLightBgCard>
                        <DefaultLightBgCard
                            title="Create only a task"
                            htmlFor="createTaskOnly"
                            sx={cn(taskType === 'createTaskOnly' && 'ring-2 ring-inset ring-third')}
                            rightButton={
                                <div className="flex h-6 items-center">
                                    <input
                                        defaultChecked={taskType === 'createTaskOnly'}
                                        onChange={(e) => {
                                            if (e.target?.checked) {
                                                setTaskType('createTaskOnly');
                                            }
                                        }}
                                        id={'createTaskOnly'}
                                        name="tasktype"
                                        type="radio"
                                        aria-describedby={`createTaskOnly-description`}
                                        className="size-4 border-gray-300 text-third focus:ring-third cursor-pointer"
                                    />
                                </div>
                            }
                        >
                            <PrimaryParagraph sx={'!text-md cursor-pointer text-gray-700 mt-1'}>
                                Add a standalone task without associating it with any job or milestone, for quick,
                                one-off tasks.
                            </PrimaryParagraph>
                        </DefaultLightBgCard>
                    </div>
                    {createNewJob && (
                        <CreateJob
                            title={'Create Job'}
                            open={createNewJob}
                            setOpen={(value) => {
                                setCreateNewJob(value);
                                setSelectedJob(null);
                            }}
                            onAction={(newJob) => {
                                setSelectedJob(newJob);
                            }}
                        />
                    )}
                    {selectedJob && createNewMilestone && (
                        <Slideover
                            open={createNewMilestone}
                            title={'Create Milestone'}
                            hideBackArrow
                            titlesx={'text-3xl font-fira font-[500] !m-0'}
                            flexSx="!items-center"
                            setOpen={(e) => {
                                setCreateNewMilestone(e);
                                setSelectedMilestone(null);
                            }}
                            size={'max-w-2xl'}
                        >
                            <div className="mt-8">
                                <MilestoneForm
                                    milestone={{ sequence: 0 }}
                                    index={0}
                                    job_id={selectedJob?.job_id || ''}
                                    prefieldData={prefieldData}
                                    onSubmit={(dataObj, _index) => {
                                        setSelectedMilestone(dataObj);
                                        setCreateNewMilestone(false);
                                    }}
                                    onCancel={(_e) => {
                                        setCreateNewMilestone(false);
                                        setSelectedMilestone(null);
                                    }}
                                    sx="w-full"
                                />
                            </div>
                        </Slideover>
                    )}

                    {createTask && (
                        <CreateTask
                            open={createTask}
                            setOpen={setCreateTask}
                            prefieldData={prefieldData}
                            disableJobAndMilestone={true}
                            extraPayload={{
                                email_id: prefieldData?.job_email_id
                            }}
                            handleTasksUpdate={() => {
                                setCreateTask(false);
                                setOpen(false);
                            }}
                        />
                    )}
                </Slideover>
            )}
        </div>
    );
};

export default CreateTaskWithJob;
