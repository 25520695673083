import React from 'react';

export const RedClock = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="#DC7B7B" />
            <path
                d="M47.3047 20.1182C41.5156 20.6807 36.1719 22.8135 31.4844 26.4228C29.1992 28.1807 26.2344 31.4853 24.5234 34.1807C23.7852 35.3408 22.3438 38.2939 21.8281 39.7002C21.2539 41.2705 20.6328 43.6846 20.3281 45.5478C19.9648 47.833 19.9648 52.1689 20.3281 54.4541C20.8555 57.6885 21.6875 60.29 23.1055 63.1846C24.6523 66.3369 26.2578 68.5752 28.8359 71.165C31.4258 73.7432 33.6641 75.3486 36.8164 76.8955C39.7109 78.3135 42.3125 79.1455 45.5469 79.6729C47.832 80.0361 52.168 80.0361 54.4531 79.6729C57.6875 79.1455 60.2891 78.3135 63.1836 76.8955C66.3359 75.3486 68.5742 73.7432 71.1641 71.165C73.7422 68.5752 75.3477 66.3369 76.8945 63.1846C77.9258 61.0869 78.4414 59.7158 79.0039 57.6064C79.7422 54.7939 79.918 53.2588 79.918 50.001C79.918 46.7432 79.7422 45.208 79.0039 42.3955C78.4414 40.2861 77.9258 38.915 76.8945 36.8174C75.3477 33.665 73.7422 31.4268 71.1641 28.8369C68.5742 26.2588 66.3359 24.6533 63.1836 23.1064C60.3008 21.6885 57.6289 20.8447 54.5117 20.3525C52.7891 20.083 48.9453 19.9541 47.3047 20.1182ZM52.7891 24.8174C58.5078 25.4385 63.7344 27.9814 67.8828 32.1182C72.7461 36.9814 75.3125 43.1689 75.3125 50.001C75.3125 56.833 72.7461 63.0205 67.8828 67.8838C63.0195 72.7471 56.832 75.3135 50 75.3135C43.168 75.3135 36.9805 72.7471 32.1172 67.8838C27.2539 63.0205 24.6875 56.833 24.6875 50.001C24.6875 43.1689 27.2539 36.9814 32.1172 32.1182C36.207 28.0283 41.4922 25.4502 47.0703 24.8174C48.3594 24.6768 51.4766 24.665 52.7891 24.8174Z"
                fill="white"
            />
            <path
                d="M48.9921 34.1684C48.4296 34.4263 47.9726 34.9888 47.7733 35.645C47.6796 35.9849 47.6562 38.1645 47.6796 43.438C47.7148 50.5747 47.7265 50.7739 47.9608 51.231C48.1483 51.606 49.0858 52.3794 52.8827 55.2505C55.4491 57.2075 57.7929 58.9419 58.0858 59.1177C60.0429 60.2895 62.3515 58.1919 61.3671 56.1528C61.1327 55.6606 60.6523 55.2739 56.7265 52.3208L52.3437 49.0395V42.5005C52.3437 37.9302 52.3085 35.8559 52.2148 35.5747C51.8749 34.5786 51.0194 33.9458 49.9999 33.9458C49.7069 33.9458 49.2499 34.0513 48.9921 34.1684Z"
                fill="white"
            />
        </svg>
    );
};
