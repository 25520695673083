/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import React, { useEffect, useRef } from 'react';
import { Calendar, DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { formateDateTime } from 'utills/moment';
import { PickDate } from 'utills/svgs/PickDate';
import './css/global-components.css';
const DatePickerComponent = (props) => {
    const {
        type,
        style,
        onDateChange,
        isOpen,
        setVisible,
        date,
        sidebarDate,
        range,
        maxDate = null,
        iconSet,
        formate,
        ml,
        border
    } = props;
    const modalRef = useRef(null);

    function handleClickOutside(event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setVisible(false);
        }
    }

    const onChange = (date) => {
        if (type === 'DateRange') {
            onDateChange(date);

            return;
        }
        onDateChange(date);
    };
    useEffect(() => {
        document?.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);

    const handleChange = (date) => {
        onDateChange(date?.selection);
    };

    if (type === 'DateRange') {
        return (
            <div onClick={() => !isOpen && setVisible(true)}>
                {' '}
                <DateRangePicker
                    className="date-range-pick"
                    ranges={[range]}
                    onChange={handleChange}
                    maxDate={maxDate}
                />
            </div>
        );
    } else {
        return (
            <div className={`date-ranger-picker-wraper`} style={style}>
                <div
                    onClick={() => !isOpen && setVisible(true)}
                    style={{ border: border && border }}
                    className={`date-selector-div justify-between gap-4 px-2 `}
                >
                    {iconSet?.leftIcon && <span>{<iconSet.leftIcon />}</span>}
                    <div>{date ? formateDateTime(date) : 'Select date'}</div>
                    {iconSet?.rightIcon ? (
                        <span className={`ml-${ml}`}>{<iconSet.rightIcon />}</span>
                    ) : (
                        <span className={`ml-${ml}`}>
                            {' '}
                            <PickDate />
                        </span>
                    )}
                </div>
                <div className={`${sidebarDate ? 'sidebarPicker' : 'picker '}`} ref={modalRef}>
                    {isOpen && (
                        <div className="border rounded-md overflow-hidden shadow-md mt-2">
                            <Calendar
                                date={new Date()}
                                onChange={(date) => onChange(date)}
                                maxDate={maxDate}
                                formate={formate}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
};

export default DatePickerComponent;
