import React from 'react';

export const BSBIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="20" fill="url(#paint0_linear_12329_338)" />
            <g clipPath="url(#clip0_12329_338)">
                <path
                    d="M11.2578 12.7509C10.8359 12.9111 10.5039 13.2236 10.2852 13.6689L10.1758 13.8876V20.0009V26.1142L10.3086 26.3798C10.5273 26.8134 10.8945 27.1337 11.3477 27.2821C11.5312 27.3407 12.2461 27.3446 20.0781 27.3368L28.6133 27.3251L28.8789 27.1923C29.3125 26.9736 29.6328 26.6064 29.7812 26.1532C29.8398 25.9696 29.8438 25.4384 29.8359 19.9228L29.8242 13.8876L29.6992 13.6298C29.5312 13.29 29.2109 12.9696 28.8711 12.8017L28.6133 12.6767L20.0586 12.6689L11.5039 12.6611L11.2578 12.7509ZM28.457 13.6962C28.6367 13.7939 28.7109 13.872 28.8125 14.0634C28.8828 14.1962 28.8867 14.4071 28.8867 20.0009C28.8867 25.5946 28.8828 25.8056 28.8125 25.9384C28.7109 26.1298 28.6367 26.2079 28.457 26.3056L28.3008 26.3876H20C11.9648 26.3876 11.6953 26.3837 11.5625 26.3134C11.3711 26.2118 11.293 26.1376 11.1953 25.9579L11.1133 25.8017V20.0009C11.1133 14.4071 11.1172 14.1962 11.1875 14.0634C11.3242 13.8056 11.4805 13.6884 11.7578 13.6298C11.832 13.6142 15.5859 13.6064 20.0977 13.6103L28.3008 13.6142L28.457 13.6962Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M16.0354 15.9541C15.7815 16.0635 15.7815 16.0557 15.7815 17.6768C15.7815 18.4814 15.7737 19.1416 15.7659 19.1416C15.7542 19.1416 15.2425 18.8486 14.6214 18.4893C14.0042 18.1338 13.4221 17.8057 13.3284 17.7666C13.0784 17.6533 12.8011 17.7354 12.7034 17.9541C12.6292 18.1104 12.6448 18.376 12.7346 18.4854C12.7776 18.54 13.3557 18.8994 14.0237 19.2822C14.6878 19.6689 15.2346 19.9893 15.2346 20.001C15.2346 20.0127 14.6878 20.333 14.0237 20.7197C13.3557 21.1025 12.7776 21.4619 12.7346 21.5166C12.6839 21.5791 12.6565 21.6729 12.6565 21.7861C12.6565 22.1104 12.8323 22.29 13.1409 22.2822C13.2737 22.2783 13.512 22.1533 14.5237 21.5674C15.1995 21.1807 15.7581 20.8604 15.7659 20.8604C15.7737 20.8604 15.7815 21.5205 15.7815 22.3291C15.7815 23.7471 15.7854 23.8018 15.8636 23.9111C16.0315 24.1494 16.469 24.1494 16.637 23.9111C16.7151 23.8018 16.719 23.7471 16.719 22.3291C16.719 21.5205 16.7268 20.8604 16.7346 20.8604C16.7464 20.8604 17.305 21.1807 17.9768 21.5674C19.2815 22.3252 19.3753 22.3643 19.6253 22.2275C19.844 22.1143 19.9221 21.7119 19.7659 21.5127C19.7229 21.458 19.1448 21.1025 18.4768 20.7158C17.8128 20.333 17.2659 20.0127 17.2659 20.001C17.2659 19.9893 17.8128 19.6689 18.4768 19.2861C19.1448 18.8994 19.7229 18.5439 19.7659 18.4893C19.8557 18.376 19.8714 18.0947 19.8011 17.958C19.6761 17.7314 19.4261 17.6533 19.1682 17.7666C19.0784 17.8057 18.4964 18.1338 17.8792 18.4893C17.262 18.8486 16.7464 19.1416 16.7386 19.1416C16.7268 19.1416 16.719 18.4814 16.719 17.6768C16.719 16.0479 16.7229 16.0635 16.4573 15.9541C16.3011 15.8877 16.1878 15.8877 16.0354 15.9541Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M21.5391 21.5391C21.4219 21.6562 21.4062 21.6953 21.4062 21.875C21.4062 22.0547 21.4219 22.0938 21.5391 22.2109C21.6562 22.3281 21.6953 22.3438 21.875 22.3438C22.0547 22.3438 22.0938 22.3281 22.2109 22.2109C22.3281 22.0938 22.3438 22.0547 22.3438 21.875C22.3438 21.6953 22.3281 21.6562 22.2109 21.5391C22.0938 21.4219 22.0547 21.4062 21.875 21.4062C21.6953 21.4062 21.6562 21.4219 21.5391 21.5391Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M24.0391 21.5391C23.9219 21.6562 23.9062 21.6953 23.9062 21.875C23.9062 22.0547 23.9219 22.0938 24.0391 22.2109C24.1562 22.3281 24.1953 22.3438 24.375 22.3438C24.5547 22.3438 24.5938 22.3281 24.7109 22.2109C24.8281 22.0938 24.8438 22.0547 24.8438 21.875C24.8438 21.6953 24.8281 21.6562 24.7109 21.5391C24.5938 21.4219 24.5547 21.4062 24.375 21.4062C24.1953 21.4062 24.1562 21.4219 24.0391 21.5391Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
                <path
                    d="M26.5391 21.5391C26.4219 21.6562 26.4062 21.6953 26.4062 21.875C26.4062 22.0547 26.4219 22.0938 26.5391 22.2109C26.6562 22.3281 26.6953 22.3438 26.875 22.3438C27.0547 22.3438 27.0938 22.3281 27.2109 22.2109C27.3281 22.0938 27.3438 22.0547 27.3438 21.875C27.3438 21.6953 27.3281 21.6562 27.2109 21.5391C27.0938 21.4219 27.0547 21.4062 26.875 21.4062C26.6953 21.4062 26.6562 21.4219 26.5391 21.5391Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_12329_338"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                </linearGradient>
                <clipPath id="clip0_12329_338">
                    <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                </clipPath>
            </defs>
        </svg>
    );
};
