import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import './css/profile.css';
import ProfileModals from './ProfileModals';
import ProfileInfo from './ProfileInfo';
import Business from './Business';
import BusinessBar from './BusinessBar';
import Bio from './step-components/Bio';
import BusinessHours from './step-components/BusinessHours';
import BusinessServices from './step-components/BusinessServices';
import WorkSpacePhotos from './step-components/WorkspacePhotos';
import BranchLocationStep from './step-components/BranchLocationStep';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { businessOwnerGetProfileThunk, getAllServicesThunk, getBusinessprofileThunk } from 'store/auth/slices';

import 'driver.js/dist/driver.css';
import ConfirmationModal from 'utills/confirmationModal';

import { useLogout } from 'Hooks/useLogout';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import useRoleGuard from 'Hooks/useRoleGuard';
const ProfileContent = ({
    togglePassSide,
    toggleDeactivateAcc,
    toggleServiceSide,
    toggleBranchLocation,
    serviceUpdated,
    branchesUpdated,
    toggleUpdateBusiness,
    toggleEditBranch
}) => {
    const { profileUpdating } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();

    const business_id = localStorage.getItem('business_id');
    const [ownerData, setOwnerData] = useState(null);
    const [businessData, setBusinessData] = useState(null);
    const [servicesData, setServicesData] = useState(null);

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const { logout } = useLogout();

    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);

    const [condition, setCondition] = useState(1);

    function handleCondition(condition) {
        setCondition(condition);
    }

    const fetchBusinessDef = () => {
        dispatch(getBusinessprofileThunk())
            .then((response) => {
                if (response.payload) {
                    setBusinessData(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                fetchServices();
            });
    };

    const fetchServices = () => {
        dispatch(getAllServicesThunk({ business_id }))
            .then((response) => {
                if (response.data) {
                    setServicesData(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                dispatch(setLoader(false));
            });
    };

    const handleGetOwnerProfile = () => {
        dispatch(businessOwnerGetProfileThunk())
            .then((response) => {
                if (response.payload) {
                    setOwnerData(response.payload);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                if (hasPermissions('Profile', ['admin', 'read', 'write'])) {
                    fetchBusinessDef();
                } else {
                    dispatch(setLoader(false));
                }
            });
    };
    useEffect(() => {
        dispatch(setLoader(true));
        handleGetOwnerProfile();
    }, [profileUpdating, serviceUpdated]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        }

        document?.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef]);

    function myComponent() {
        return (
            <div>
                <div>
                    {' '}
                    {condition === 1 && (
                        <Bio businessData={businessData?.description} toggleBio={toggleUpdateBusiness} />
                    )}{' '}
                </div>
                <div> {condition === 2 && <BusinessHours />} </div>
                <div>
                    {' '}
                    {condition === 3 ? (
                        <BusinessServices toggleServiceSide={toggleServiceSide} servicesData={servicesData} />
                    ) : (
                        ''
                    )}{' '}
                </div>
                <div> {condition === 4 && <WorkSpacePhotos />} </div>
                <div>
                    {' '}
                    {condition === 5 && (
                        <BranchLocationStep
                            toggleEditBranch={toggleEditBranch}
                            toggleBranchLocation={toggleBranchLocation}
                            branchesUpdated={branchesUpdated}
                        />
                    )}{' '}
                </div>
            </div>
        );
    }
    const handleLogout = () => {
        setIsConfirmationOpen(true);
    };
    return (
        <div>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={() => logout(setIsConfirmationOpen)}
                description="Are you sure you want to logout!"
                confirmbtnTxt="Logout"
                cancelBtnTxt="Cancel"
                title="Logout Confirmation"
            />

            <Jumbotron title="Profile" />

            <div>
                {showModal ? (
                    <div ref={modalRef}>
                        {' '}
                        <ProfileModals
                            logout={handleLogout}
                            togglePassSide={togglePassSide}
                            toggleDeactivateAcc={toggleDeactivateAcc}
                        />{' '}
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className="manage-user-profile">
                {' '}
                <ProfileInfo ownerData={ownerData} handleGetOwnerProfile={handleGetOwnerProfile} />
            </div>
            <RoleBasedGuard module={'Profile'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                <div className="manage-business-profile">
                    <Business toggleUpdateBusiness={toggleUpdateBusiness} businessData={businessData} />
                </div>
                <div className="manage-business-info">
                    <BusinessBar condition={condition} handleCondition={handleCondition} />
                </div>
                <div className="">{myComponent()}</div>
            </RoleBasedGuard>
        </div>
    );
};

export default ProfileContent;
