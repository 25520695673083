import React, { useState } from 'react';
import QuestionFormPage from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/question-form-pages/QuestionFormPage';
import EditQuestionForm from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/question-form-pages/EditQuestionForm';
import AddNewQuestion from '../../../components/workplace-content/appointment-reminder-pages/online-booking-pages/question-form-pages/AddNewQuestion';
const QuestionForm = () => {
    const [showEditQuestionForm, setShowEditQuestionForm] = useState(false);
    const [showAddNewQuestion, setShowAddNewQuestion] = useState(false);

    const toggleEditQuestionForm = () => {
        setShowEditQuestionForm(!showEditQuestionForm);
    };
    const toggleAddNewQuestion = () => {
        setShowAddNewQuestion(!showAddNewQuestion);
    };
    return (
        <div className="app-dashboard">
            {showEditQuestionForm && <EditQuestionForm toggleEditQuestionForm={toggleEditQuestionForm} />}
            {showAddNewQuestion && <AddNewQuestion toggleAddNewQuestion={toggleAddNewQuestion} />}

            <div className={`content ${''}`}>
                <QuestionFormPage
                    toggleEditQuestionForm={toggleEditQuestionForm}
                    toggleAddNewQuestion={toggleAddNewQuestion}
                />
            </div>
        </div>
    );
};

export default QuestionForm;
