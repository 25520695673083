import React from 'react';

export const BgChip = ({ iconSet, title, text, classes, onClick }) => {
    return (
        <div className={`flex gap-3 items-center p-5  text-[#0A1E46] rounded-2xl ${classes}`} onClick={onClick}>
            <div>
                <iconSet.icon fill={iconSet?.fill} />
            </div>

            <div>
                <div>{title}</div>
                <div className="buttons-text-lighter font-poppins"> {text} </div>
            </div>
        </div>
    );
};
