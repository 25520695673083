import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: fetchForms, request: fetchFormsThunk } = generateThunkAndSlice(
    'fetchForms',
    endpoint.fetchForms,
    methods.POST
);

const { slice: fetchCustomerForms, request: fetchCustomerFormsThunk } = generateThunkAndSlice(
    'fetchCustomerForms',
    endpoint.fetchCustomerForms,
    methods.POST
);

const { slice: getSingleForm, request: getSingleFormThunk } = generateThunkAndSlice(
    'getSingleForm',
    endpoint.getSingleForm,
    methods.POST
);

const { slice: fetchSubmissionFormData, request: fetchSubmissionFormDataThunk } = generateThunkAndSlice(
    'fetchSubmissionFormData',
    endpoint.fetchSubmissionFormData,
    methods.POST
);

const { request: postDynamicFormThunk } = generateThunkAndSlice(
    'postDynamicForm',
    endpoint.postDynamicForm,
    methods.POST
);
const { request: archiveSubmissionFormThunk } = generateThunkAndSlice(
    'archiveSubmissionForm',
    endpoint.archiveSubmissionForm,
    methods.POST
);

const { request: archiveBusinessFormThunk } = generateThunkAndSlice(
    'archiveBusinessForm',
    endpoint.archiveBusinessForm,
    methods.POST
);

const { request: updateBusniessFormThunk } = generateThunkAndSlice(
    'updateBusinessForm',
    endpoint.updateBusinessForm,
    methods.POST
);

const { request: updateCustomerFormThunk } = generateThunkAndSlice(
    'updateCustomerForm',
    endpoint.updateCustomerForm,
    methods.POST
);

const { request: deleteFormFileThunk } = generateThunkAndSlice('deleteFormFile', endpoint.deleteFormFile, methods.POST);

const { slice: singleFormLogs, request: singleFormLogsThunk } = generateThunkAndSlice(
    'singleFormLogs',
    endpoint?.singleFormLogs,
    methods.POST
);

const { slice: customerActivity, request: customerActivityThunk } = generateThunkAndSlice(
    'customerActivity',
    endpoint.customerActivity,
    methods.POST
);

const { slice: fetchSingleFormFb, request: fetchSingleFormFbThunk } = generateThunkAndSlice(
    'fetchSingleFormFb',
    endpoint.fetchSingleFormFb,
    methods.POST
);

const { request: getformsfieldsOptionsThunk } = generateThunkAndSlice(
    'getformsfieldsOptions',
    endpoint.getformsfieldsOptions,
    methods.POST
);

const { request: publishFormsThunk } = generateThunkAndSlice(
    'getformsfieldsOptions',
    endpoint.publishForms,
    methods.POST
);

export {
    fetchFormsThunk,
    fetchForms,
    fetchCustomerFormsThunk,
    fetchCustomerForms,
    fetchSubmissionFormDataThunk,
    fetchSubmissionFormData,
    postDynamicFormThunk,
    archiveSubmissionFormThunk,
    archiveBusinessFormThunk,
    updateBusniessFormThunk,
    updateCustomerFormThunk,
    deleteFormFileThunk,
    singleFormLogs,
    singleFormLogsThunk,
    customerActivity,
    customerActivityThunk,
    fetchSingleFormFb,
    fetchSingleFormFbThunk,
    getformsfieldsOptionsThunk,
    publishFormsThunk,
    getSingleFormThunk,
    getSingleForm
};
