import React from "react";

export const TimesIcon = () => {
  return (
    <div>
      {" "}
      <svg
        width="12"
        height="12"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.476679 0.476677C0.78189 0.171466 1.19585 0 1.62749 0C2.05898 0 2.47311 0.171659 2.77829 0.476678L9.5 7.20425L16.2217 0.476677C16.5269 0.171467 16.9409 0 17.3725 0C17.8042 0 18.2181 0.171467 18.5233 0.476678C18.8285 0.781889 19 1.19585 19 1.62749C19 2.05898 18.8283 2.47311 18.5233 2.77829L11.7957 9.5L18.5233 16.2217C18.6744 16.3728 18.7943 16.5522 18.8761 16.7497C18.9579 16.9472 19 17.1588 19 17.3725C19 17.5862 18.9579 17.7979 18.8761 17.9953C18.7943 18.1928 18.6744 18.3722 18.5233 18.5233C18.3722 18.6744 18.1928 18.7943 17.9953 18.8761C17.7979 18.9579 17.5862 19 17.3725 19C17.1588 19 16.9472 18.9579 16.7497 18.8761C16.5522 18.7943 16.3728 18.6744 16.2217 18.5233L9.5 11.7957L2.77798 18.5236C2.62693 18.6746 2.44763 18.7944 2.2503 18.8761C2.05284 18.9579 1.8412 19 1.62749 19C1.41377 19 1.20213 18.9579 1.00467 18.8761C0.807211 18.7943 0.6278 18.6744 0.476678 18.5233C0.325553 18.3722 0.205672 18.1928 0.123883 17.9953C0.0420954 17.7979 0 17.5862 0 17.3725C0 17.1588 0.0420957 16.9472 0.123883 16.7497C0.205671 16.5522 0.325552 16.3728 0.476678 16.2217L7.20425 9.5L0.47637 2.77798C0.171351 2.4728 0 2.05898 0 1.62749C0 1.19585 0.171467 0.781889 0.476679 0.476677Z"
          fill="#979797"
        />
      </svg>
    </div>
  );
};
