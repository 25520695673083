import React, { useState } from 'react';
import RequestedDocs from './RequestedDocs';
import RequestSignature from './RequestSignature';
import CollateDocs from './CollateDocs';

import EthicalClearance from './EthicalClearance';
import DeedBuilder from './DeedBuilder';
import { useSelector } from 'react-redux';

const JobPortalTools = ({ handleGetSingleJob, disabled }) => {
    const [collateListReload, setCollateListReload] = useState(false);
    const { data: job } = useSelector((state) => state.getSingleJob);
    if (job) {
        return (
            <div className="mb-5">
                <div className="flex flex-col gap-5">
                    <RequestedDocs disabled={disabled} />
                    <RequestSignature setCollateListReload={setCollateListReload} disabled={disabled} />
                    <CollateDocs collateListReload={collateListReload} setCollateListReload={setCollateListReload} />
                    <DeedBuilder setCollateListReload={setCollateListReload} handleGetSingleJob={handleGetSingleJob} />
                    <EthicalClearance />
                </div>
            </div>
        );
    }
};

export default JobPortalTools;
