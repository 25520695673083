import React, { useEffect, useState } from 'react';
import Input from 'components/atoms/Input';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import PhoneInputComponent from 'components/atoms/Input/PhoneInput';
import { useDispatch, useSelector } from 'react-redux';
import { setSideLoader } from 'store/global/globalReducer';
import { sendClientInviteThunk } from 'store/client';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { InputField } from 'components/atoms/inputs/InputField';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { recursiveSearch, validateEmailValue } from 'utills/dataValidation';
import { validatePhoneNumber } from 'utills/baseValidations';
import { Chip } from 'components/atoms/buttons/Chip';

const InviteClientsComponent = ({ open, setOpen, handleAddClientAction, setModal, preFilledData }) => {
    const dispatch = useDispatch();
    const { sideLoader } = useSelector((state) => state.global);
    const [firstName, setFirstname] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [firstNameSuggesstList, setFirstNameSuggesstList] = useState([]);
    const [lastNameSuggesstList, setLastNameSuggesstList] = useState([]);

    useEffect(() => {
        if (preFilledData) {
            setEmail(preFilledData?.email || '');
            if (preFilledData?.phone_number) {
                handlePhoneNo(preFilledData?.phone_number);
            }
            const firstNameList = extractProfileName(preFilledData, 'first_name');
            setFirstNameSuggesstList(Array.from(new Set(firstNameList)));
            const lastNameList = extractProfileName(preFilledData, 'last_name');
            setLastNameSuggesstList(Array.from(new Set(lastNameList)));
        }
    }, [preFilledData]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!email || !firstName || !last_name || phoneNo?.length < 5) {
            toastHandler('Please fill in all required fields', TOAST_TYPE_ERROR);
            return;
        }
        if (phoneError) {
            return;
        }

        if (email) {
            if (!validateEmailValue(email)) {
                return toastHandler(`invalid Email `, TOAST_TYPE_ERROR);
            }
        }

        if (phoneNo) {
            if (!validatePhoneNumber(phoneNo)) {
                return false;
            }
        }

        const payload = {
            first_name: firstName,
            last_name,
            email,
            phone_number: phoneNo
        };

        dispatch(setSideLoader(true));
        dispatch(sendClientInviteThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setFirstname('');
                    setLastName('');
                    setPhoneNo('');
                    setEmail('');
                    setOpen(false);
                    setModal(true);

                    const newItem = cloneDeep(response.payload);

                    handleAddClientAction({
                        ...newItem,
                        created_at: moment(newItem?.created_at).utc().format('DD-MM-YYYYTHH:mm:ss[Z]')
                    });
                    toastHandler('Invite sent!', TOAST_TYPE_SUCCESS);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const handlePhoneNo = (value) => {
        let isValid = false;

        setPhoneNo(value);
        const parsedPhoneNumber = parsePhoneNumberFromString(value);

        if (parsedPhoneNumber) {
            isValid = parsedPhoneNumber.isValid();
        } else {
            console.error('Invalid phone number');
        }

        if (!isValid || value?.length < 1) {
            setPhoneError(true);
        } else {
            setPhoneError(false);
        }
    };

    const extractProfileName = (preFilledData, targetProperty) => {
        let result = [];
        recursiveSearch(preFilledData, targetProperty, result);
        return result;
    };
    return (
        <div>
            <Slideover open={open} setOpen={setOpen}>
                <div className="flex flex-col gap-3">
                    <h3 className="flex text-[32px] font-bold  text-slate-900 sm:truncate font-secondary">
                        Invite Client
                    </h3>
                </div>
                <div className="grid  grid-cols-1 gap-4 mt-10 default-forms">
                    <form onSubmit={handleSubmit}>
                        <div className="">
                            <InputField
                                label="First Name"
                                inputValue={firstName}
                                name="firstName"
                                placeholder="Enter first name"
                                maxLength={30}
                                allowSpaces={false}
                                onChange={(e) => setFirstname(e.target.value)}
                                classes="mt-2 px-5"
                                labelSx=" "
                                required={true}
                            />
                        </div>
                        {firstNameSuggesstList && (
                            <div className="flex gap-2 my-2 flex-wrap">
                                {firstNameSuggesstList
                                    ?.filter((item) => item)
                                    ?.map((item, i) => (
                                        <Chip
                                            title={item}
                                            key={'first_name' + i}
                                            classes={'bg-third text-white cursor-pointer'}
                                            atClick={() => setFirstname(item)}
                                        />
                                    ))}
                            </div>
                        )}
                        <div className="mt-3">
                            <InputField
                                label="Last Name"
                                inputValue={last_name}
                                name="lastName"
                                placeholder="Enter last name"
                                maxLength={30}
                                allowSpaces={true}
                                onChange={(e) => setLastName(e.target.value)}
                                classes="mt-2 px-5"
                                labelSx=" "
                                required={true}
                            />
                        </div>
                        {lastNameSuggesstList && (
                            <div className="flex gap-2 my-2 flex-wrap">
                                {lastNameSuggesstList
                                    ?.filter((item) => item)
                                    ?.map((item, i) => (
                                        <Chip
                                            title={item}
                                            key={'first_name' + i}
                                            classes={'bg-third text-white cursor-pointer'}
                                            atClick={() => setLastName(item)}
                                        />
                                    ))}
                            </div>
                        )}
                        <div className="mt-3">
                            <PhoneInputComponent
                                title="Phone Number"
                                placeholder="Enter Phone Number"
                                titleSx="font-semibold"
                                sx="!rounded-xl"
                                value={phoneNo}
                                atChange={(value) => handlePhoneNo(value)}
                                required={true}
                            />
                        </div>
                        <div className="mt-3">
                            <Input
                                useWithoutForm={true}
                                title="Email"
                                placeholder="Email"
                                name="email"
                                titleSx="font-semibold"
                                sx="!rounded-xl !py-3"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required={true}
                                type="email"
                            />
                        </div>

                        <div className="flex items-center gap-3 justify-center mt-28 mb-4">
                            <Button
                                disabled={sideLoader ? true : false}
                                isloading={sideLoader ? true : false}
                                iconSet={{ loader: SmallLoaderWhite }}
                                title="Invite"
                                classes="py-3 px-4 sm:px-16 rounded-lg bg-leaner text-white buttons-font-lighter font-fira"
                            />
                        </div>
                    </form>
                </div>
            </Slideover>
        </div>
    );
};

export default InviteClientsComponent;
