import React from 'react';
import { useState } from 'react';
import { ThreeDotIcon } from '../../../../utills/svgs/ThreeDotIcon';
import { ArrowLeft } from '../../../../utills/svgs/ArrowLeft';
import { useNavigate } from 'react-router-dom';
const ReportJumbo = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const toggleShowModal = () => {
        setShowModal(!showModal);
    };
    return (
        <div>
            <div className="profle-jumbo relative ">
                <div
                    onClick={toggleShowModal}
                    className="calendar-jumbo-toggler absolute cursor-pointer right-5 top-10"
                >
                    <ThreeDotIcon />
                </div>
                {showModal && (
                    <div className="calendar-jumbo-modal absolute right-10 top-10 shadow">
                        <div className="calendar-jumbo-modal-link px-3 py-2 flex gap-1 items-center">
                            <div>
                                <svg
                                    width="14"
                                    height="16"
                                    viewBox="0 0 14 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.958 0.609631C7.58119 0.220002 7.06237 0 6.52034 0H1.47368C0.663158 0 0 0.685714 0 1.52381V13.7143C0 14.0267 0.0884212 14.3238 0.250526 14.5676C0.302105 14.6514 0.368421 14.7276 0.434737 14.7886C0.7 15.0705 1.06842 15.2381 1.47368 15.2381H7.0221C6.63158 14.7962 6.34421 14.2857 6.15263 13.7143H1.47368V1.52381H6.63158V4.33333C6.63158 4.88562 7.07929 5.33333 7.63158 5.33333H10.3158V7.61905C10.8316 7.61905 11.3253 7.71048 11.7895 7.8781V5.38033C11.7895 4.86148 11.5878 4.36293 11.2271 3.98996L7.958 0.609631ZM10.3158 14.8517C10.3158 15.2885 10.8366 15.5153 11.1564 15.2177L13.528 13.0107C13.783 12.7734 13.783 12.3695 13.528 12.1322L11.7895 10.5143L11.1564 9.92516C10.8366 9.62755 10.3158 9.85434 10.3158 10.2912V11.4286H7.86842C7.59228 11.4286 7.36842 11.6524 7.36842 11.9286V13.2143C7.36842 13.4904 7.59228 13.7143 7.86842 13.7143H10.3158V14.8517Z"
                                        fill="#000"
                                    />
                                </svg>
                            </div>
                            <div>Generate Report</div>
                        </div>

                        <div className="calendar-jumbo-modal-link px-3 py-2 flex gap-1 items-center">
                            <div>
                                <svg width="14" height="14" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.3286 13.1143H12.3857C12.1925 13.1143 12.0072 13.0376 11.8705 12.9009C11.7339 12.7643 11.6571 12.579 11.6571 12.3857C11.6571 12.1925 11.7339 12.0072 11.8705 11.8706C12.0072 11.7339 12.1925 11.6572 12.3857 11.6572H14.3286C14.6506 11.6572 14.9595 11.5292 15.1872 11.3015C15.4149 11.0738 15.5429 10.7649 15.5429 10.4429V6.55717C15.5429 6.23512 15.4149 5.92626 15.1872 5.69854C14.9595 5.47082 14.6506 5.34289 14.3286 5.34289H2.67143C2.34938 5.34289 2.04052 5.47082 1.8128 5.69854C1.58508 5.92626 1.45714 6.23512 1.45714 6.55717V10.4429C1.45714 10.7649 1.58508 11.0738 1.8128 11.3015C2.04052 11.5292 2.34938 11.6572 2.67143 11.6572H4.61429C4.80751 11.6572 4.99283 11.7339 5.12946 11.8706C5.2661 12.0072 5.34286 12.1925 5.34286 12.3857C5.34286 12.579 5.2661 12.7643 5.12946 12.9009C4.99283 13.0376 4.80751 13.1143 4.61429 13.1143H2.67143C1.96292 13.1143 1.28343 12.8329 0.782443 12.3319C0.281453 11.8309 0 11.1514 0 10.4429V6.55717C0 5.84866 0.281453 5.16918 0.782443 4.66819C1.28343 4.1672 1.96292 3.88574 2.67143 3.88574H14.3286C15.0371 3.88574 15.7166 4.1672 16.2176 4.66819C16.7185 5.16918 17 5.84866 17 6.55717V10.4429C17 11.1514 16.7185 11.8309 16.2176 12.3319C15.7166 12.8329 15.0371 13.1143 14.3286 13.1143Z" />
                                    <path d="M12.3828 5.34286C12.1904 5.34034 12.0065 5.26277 11.8704 5.12668C11.7343 4.99059 11.6568 4.80673 11.6542 4.61429V1.45714H5.33996V4.61429C5.33996 4.80751 5.2632 4.99283 5.12656 5.12946C4.98993 5.2661 4.80461 5.34286 4.61138 5.34286C4.41815 5.34286 4.23284 5.2661 4.09621 5.12946C3.95957 4.99283 3.88281 4.80751 3.88281 4.61429V1.21429C3.88281 0.892237 4.01075 0.583379 4.23847 0.355656C4.46619 0.127933 4.77505 0 5.0971 0H11.8971C12.2191 0 12.528 0.127933 12.7557 0.355656C12.9835 0.583379 13.1114 0.892237 13.1114 1.21429V4.61429C13.1089 4.80673 13.0313 4.99059 12.8952 5.12668C12.7591 5.26277 12.5753 5.34034 12.3828 5.34286ZM11.8971 17H5.0971C4.77505 17 4.46619 16.8721 4.23847 16.6443C4.01075 16.4166 3.88281 16.1078 3.88281 15.7857V8.98571C3.88281 8.66367 4.01075 8.35481 4.23847 8.12708C4.46619 7.89936 4.77505 7.77143 5.0971 7.77143H11.8971C12.2191 7.77143 12.528 7.89936 12.7557 8.12708C12.9835 8.35481 13.1114 8.66367 13.1114 8.98571V15.7857C13.1114 16.1078 12.9835 16.4166 12.7557 16.6443C12.528 16.8721 12.2191 17 11.8971 17ZM5.33996 15.5429H11.6542V9.22857H5.33996V15.5429Z" />
                                </svg>
                            </div>
                            <div>Print</div>
                        </div>

                        <div className="calendar-jumbo-modal-link px-3 py-2 flex gap-1 items-center">
                            <div>
                                <svg width="14" height="11" viewBox="0 0 15 11" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.37 7.08956H9.45741C9.28447 7.08956 9.11861 7.01827 8.99632 6.89138C8.87402 6.76449 8.80532 6.59239 8.80532 6.41294V2.35324H1.63231V9.79603C1.63231 9.97548 1.56361 10.1476 1.44132 10.2745C1.31902 10.4014 1.15316 10.4726 0.980217 10.4726C0.807272 10.4726 0.641409 10.4014 0.519118 10.2745C0.396827 10.1476 0.328125 9.97548 0.328125 9.79603V2.35324C0.328125 1.99434 0.46553 1.65014 0.710112 1.39636C0.954694 1.14258 1.28642 1.00001 1.63231 1.00001H9.45741C9.6309 0.998996 9.7978 1.06888 9.92203 1.19454L14.4785 5.93085C14.5411 5.99313 14.5907 6.06802 14.6244 6.15092C14.658 6.23382 14.6749 6.32299 14.6742 6.41294V9.79603C14.6742 9.97548 14.6054 10.1476 14.4832 10.2745C14.3609 10.4014 14.195 10.4726 14.0221 10.4726C13.8491 10.4726 13.6833 10.4014 13.561 10.2745C13.4387 10.1476 13.37 9.97548 13.37 9.79603V7.08956ZM10.1095 5.73633H12.4489L10.1095 3.30896V5.73633Z"
                                        strokeWidth="0.3"
                                    />
                                </svg>
                            </div>
                            <div>Export to CSV</div>
                        </div>
                    </div>
                )}

                <div className="profile-jumbo-flex px-10 py-5">
                    <div className="jumbo-flex-1 ">
                        <div
                            className="flex gap-2 items-center cursor-pointer"
                            onClick={() => navigate('/appointment/reminder')}
                        >
                            <div>
                                <ArrowLeft />
                            </div>
                            <div className="jumbo-name">Reports</div>
                        </div>
                        <div className="jumbo-dir mt-2">
                            Workspace &gt; Appointments <span className="special-jumbo-text">&gt; Reports</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportJumbo;
