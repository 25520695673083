import React from 'react';
/* eslint-disable react-hooks/exhaustive-deps */
import QrCodesOperation from './QrCodesOperations';
import QrCodesList from './QrCodesList';
import './css/qrcodes.css';
import { useState, useEffect } from 'react';
import { getAllQrCodesThunk, archiveQrCodeThunk } from 'store/workspace/workspaceQrCode';
import { BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { handleSingleCode, setLoader } from 'store/global/globalReducer';
import ConfirmationModal from 'utills/confirmationModal';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { NoDataFound } from 'components/common/NoDataFound';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';

const QrCodesPage = ({ toggleAddQrCode, qrCodeUpdated, toggleSingleCode, toggleUpdate }) => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { data } = useSelector((state) => state.getAllQrCodes);
    const { hasPermissions } = useRoleGuard();

    const [searchValue, setSearchValue] = useState('');
    const [qr_code_id, setQrCodeId] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        const payload = {
            business_id,
            qr_code_id
        };
        dispatch(archiveQrCodeThunk(payload))
            .then((response) => {
                if (response.payload) {
                    setQrCodeId('');
                    handleGetAllQrCodes({ business_id });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    function searchByItem(value) {
        setSearchValue(value);
    }

    const filteredCodes = data?.filter(
        (row) =>
            row.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            row.branch_name.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleGetAllQrCodes = (payload) => {
        dispatch(setLoader(true));
        dispatch(getAllQrCodesThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        handleGetAllQrCodes({
            business_id
        });
    }, [qrCodeUpdated]);

    const handleArchiveQrCodeId = (qr_code_id, createdby) => {
        if (hasPermissions('Qr_codes', ['admin'], createdby)) {
            setQrCodeId(qr_code_id);
            setIsConfirmationOpen(true);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const handleArchiveQrCode = () => {
        if (!qr_code_id) {
            return;
        }

        setIsConfirmationOpen(true);
    };

    const handleCode = (qr_code_id) => {
        const code = data?.find((c) => c?.qr_code_id === qr_code_id);
        if (code) {
            dispatch(handleSingleCode(code));
            toggleSingleCode();
        }
    };

    return (
        <div>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <Jumbotron
                title="QR Codes"
                path={[
                    { title: 'Workspace', link: 'workspace' },
                    { title: 'QR Codes', link: 'qrcodes' }
                ]}
            />
            <div>
                <QrCodesOperation
                    toggleAddQrCode={toggleAddQrCode}
                    searchByItem={searchByItem}
                    qr_code_id={qr_code_id}
                    handleArchiveQrCode={handleArchiveQrCode}
                />
            </div>
            <div>
                {!filteredCodes?.length ? (
                    <NoDataFound message="No record found!" height="30vh" />
                ) : (
                    <QrCodesList
                        handleCode={handleCode}
                        qrcodes={filteredCodes}
                        handleArchiveQrCodeId={handleArchiveQrCodeId}
                        toggleAddQrCode={toggleAddQrCode}
                        toggleUpdate={toggleUpdate}
                    />
                )}
            </div>
        </div>
    );
};

export default QrCodesPage;
