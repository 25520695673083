import React from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';

export const Terms = ({ toggleTerms }) => {
    const { terms } = useSelector((state) => state.global);

    function MyComponent({ htmlContent }) {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }

    return (
        <div className="add-p-side grid grid-cols-12 text-black">
            <div className="md:col-span-8 hidden md:block left-side"></div>
            <div className="right-side col-span-12 md:col-span-4">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    <div>
                        <div onClick={toggleTerms} className="absolute   text-white p-2 right-1 top-1 cursor-pointer">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="13" cy="13" r="13" fill="#666666" />
                                <path d="M19 8L8 19" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                <path d="M19 19L8 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </div>

                        <div className="add-detail pt-10 px-5">
                            <div className="title">
                                {terms && terms?.type === 'terms-and-conditions'
                                    ? 'Terms and conditions'
                                    : terms?.type === 'privacy-policy'
                                    ? 'Privacy Policy'
                                    : ''}
                            </div>
                        </div>

                        <div className="m-5 buttons-font-lighter font-poppins mx-7">
                            <MyComponent htmlContent={terms?.text} />
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};
