import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { Menu } from 'components/molecules/Menu';
import { SearchInput } from 'components/molecules/SearchInput';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import { SortIcon } from 'utills/svgs/SortIcon';
import { StepDownIcon } from 'utills/svgs/StepDownIcon';
import CreateTask from 'global-components/CreateTask';
import dayjs from 'dayjs';
import { QuadruppleSwitch } from 'components/molecules/QuadruppleSwitch';
import { CreateLead } from './subcomponents/leads-components/CreateLead';
import TaskDetails from 'components/workplace-content/tasks-pages/task-details/TaskDetails';
import { handleTaskDetails, setLoader } from 'store/global/globalReducer';
import { BUSINESS_ID, ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';
import { getSingleTaskThunk } from 'store/workspace/workspaceTasks';
import { EditLead } from './subcomponents/leads-components/EditLead';
import { Loader } from 'components/common/Loader';
import { getAllLeadsThunk, setCountPerPage, setCurrentPage, setFilters } from 'store/custom-reducers/LeadsReducer';
import Pagination from 'components/pagination';
import LeadsTableDataDT from './subcomponents/LeadsTableDataDT';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';

export const LeadsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sortAsc, setSortAsc] = useState(false);
    const [createLead, setCreateLead] = useState(false);
    const [editLead, setEditLead] = useState(null);
    const [showEditLead, setShowEditLead] = useState(false);
    const { data, currentPage, totalPages, isLoading, itemsPerPage, activeFilter, filters } = useSelector(
        (state) => state.leadsReducer
    );

    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const business_id = localStorage.getItem(BUSINESS_ID);
    const [taskDetailLead, setTaskDetailLead] = useState('');
    const { sideLoader } = useSelector((state) => state.global);

    const { hasPermissions } = useRoleGuard();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [isReload, setIsReload] = useState(params.get('reload') || true);

    const [timeoutId, setTimeoutId] = useState(null);
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const handleChange = (_value) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            fetchClientsListTest();
        }, 500);
        setTimeoutId(newTimeoutId);
    };

    useEffect(() => {
        if (!isReload || isReload === 'false' || isReload === false) {
            if (!data || activeFilter === true) {
                handleChange();
            }
            setIsReload(true);
        } else {
            handleChange();
        }
    }, [currentPage, itemsPerPage, filters]);

    const fetchClientsListTest = async () => {
        const jsonData = {
            page: currentPage,
            pageSize: itemsPerPage,
            search_term: filters?.search_term,
            from_date:
                filters?.from_date && filters?.from_date !== '' ? dayjs(filters?.from_date).format('DD-MM-YYYY') : '',
            order_by: filters?.order_by !== '' ? filters?.order_by : 'DESC'
        };

        await dispatch(getAllLeadsThunk(jsonData));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch(
            setFilters({
                ...filters,
                [name]: value
            })
        );
    };

    const handleSortByDate = () => {
        setSortAsc(!sortAsc);
        handleInputChange({
            target: {
                name: 'order_by',
                value: sortAsc ? 'DESC' : 'ASC'
            }
        });
    };

    const [createTask, setCreateTask] = useState(false);
    const [leadId, setLeadId] = useState('');
    const actions = [
        {
            title: 'Create ',
            classes: 'flex gap-2 items-center px-8 !rounded-xl ann-btn',
            type: 'ann-btn',
            iconSet: { leftIcon: '' },
            onAction: () => {
                if (hasPermissions('Leads', ['write', 'admin'])) {
                    setCreateLead(true);
                } else {
                    toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
                }
            }
        }
    ];

    const handleResetAndGet = () => {
        fetchClientsListTest();
    };

    const toggleCreateTask = (lead_id) => {
        if (hasPermissions('Leads', ['write', 'admin'])) {
            if (lead_id) {
                setLeadId(lead_id);
            }
            setCreateTask(!createTask);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const toggleTaskDetails = () => {
        setShowTaskDetails(!showTaskDetails);
    };
    const getSingleTask = (task_id) => {
        const payload = {
            task_id: task_id,
            business_id
        };
        dispatch(setLoader(true));
        setTaskDetailLead(task_id);
        dispatch(getSingleTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    dispatch(handleTaskDetails(response.payload));
                    toggleTaskDetails();
                }
            })

            .finally(() => {
                dispatch(setLoader(false));
                setTaskDetailLead('');
            });
    };

    const handleDetails = async (task_id) => {
        if (hasPermissions('Leads', ['write', 'admin'])) {
            getSingleTask(task_id);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    const handleTasksUpdate = () => {};

    const handleEditLead = (lead) => {
        if (hasPermissions('Leads', ['write', 'admin'])) {
            setEditLead(lead);
            setShowEditLead(!showEditLead);
        } else {
            toastHandler(PERMISSIONS_MESSAGE, ERROR_TYPE_ERROR);
        }
    };

    return (
        <div>
            {sideLoader && <Loader />}
            <Jumbotron
                title="Leads"
                path={[
                    { title: 'Clients', link: 'clients' },
                    {
                        title: 'Leads',
                        link: ''
                    }
                ]}
                actionsRequired={true}
                actions={actions}
                alignCss="!top-12 !md:top-14"
            />

            {createLead && (
                <CreateLead
                    open={createLead}
                    setOpen={setCreateLead}
                    setCreateTask={setCreateTask}
                    handleResetAndGet={handleResetAndGet}
                    toggleCreateTask={toggleCreateTask}
                />
            )}

            {showTaskDetails && (
                <TaskDetails
                    open={showTaskDetails}
                    setOpen={setShowTaskDetails}
                    toggleTaskDetails={toggleTaskDetails}
                    handleTasksUpdate={handleTasksUpdate}
                />
            )}

            {showEditLead && (
                <EditLead
                    open={showEditLead}
                    setOpen={setShowEditLead}
                    setCreateTask={setCreateTask}
                    handleResetAndGet={handleResetAndGet}
                    toggleCreateTask={toggleCreateTask}
                    lead={editLead}
                />
            )}
            <div className="mx-5 md:mx-10 flex justify-between gap-5 items-center flex-wrap">
                <div>
                    <SearchInput
                        width="320px"
                        defaultValue={filters?.search_term}
                        atChange={(e) => {
                            handleInputChange(e);
                        }}
                        placeholder="Search"
                    />
                </div>
                <div className="flex gap-2 items-center">
                    <DatePick
                        iconSet={{
                            right: CalendarSmallIco
                        }}
                        onDateChange={(date) => {
                            handleInputChange({
                                target: {
                                    name: 'from_date',
                                    value: date ? date : ''
                                }
                            });
                        }}
                        placeholder="Select From"
                        value={filters?.from_date || ''}
                        sx="addTaskDate !border-gray-400 !rounded-xl text-gray-900 !w-full bg-[#FAF9F6] hover:bg-[#FAF9F6]"
                    />

                    <Menu
                        title="Sort By"
                        iconSet={{ first: SortIcon, second: StepDownIcon }}
                        gap={2}
                        lastMl={5}
                        isModalDisabled={true}
                        condition={sortAsc}
                        border=""
                        atMenuClick={() => {
                            handleSortByDate();
                        }}
                        text="gray-900"
                        sx="!border-gray-400 !rounded-[12px] customFilters"
                    />
                </div>
            </div>

            <div className="flex justify-center w-full items-center mt-5">
                <QuadruppleSwitch
                    titleOne="All Clients"
                    titleTwo="Prospective"
                    titleThree="Active"
                    titleFour="Inactive"
                    titleFive="Favourites"
                    border="1px solid #BBBBBB"
                    activeTab={'leads'}
                    atClick={(title) => {
                        navigate(`/app/users?type=${title}&reload=false`);
                    }}
                />
            </div>

            <LeadsTableDataDT
                data={data}
                isLoading={isLoading}
                setCreateTask={setCreateTask}
                toggleCreateTask={toggleCreateTask}
                handleDetails={handleDetails}
                handleResetAndGet={handleResetAndGet}
                handleEditLead={handleEditLead}
                taskDetailLead={taskDetailLead}
            />
            <Pagination
                page={Number(currentPage)}
                pageSize={Number(itemsPerPage)}
                totalPages={Number(totalPages)}
                onChangePage={(e) => dispatch(setCurrentPage(e))}
                onCountPerPageChange={(value) => dispatch(setCountPerPage(value))}
            />

            {createTask && (
                <CreateTask
                    open={createTask}
                    setOpen={setCreateTask}
                    lead_id={leadId}
                    handleResetAndGet={handleResetAndGet}
                />
            )}
        </div>
    );
};
