import TaskTypeJumbo from './TaskTypeJumbo';
import TaskTypeOperations from './TaskTypeOperations';
import OStasksType from './OStasksType';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'components/common/Loader';
const TaskTypePage = ({ toggleTaskType, typesUpdated, toggleEditType }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortAsc, setSortAsc] = useState(false);
    const { isLoading } = useSelector((state) => state.global);

    const addSearchTerm = (term) => {
        setSearchTerm(term);
    };

    const handleSort = () => {
        setSortAsc(!sortAsc);
    };
    return (
        <div>
            {isLoading && <Loader />}
            <TaskTypeJumbo path />
            <div>
                <TaskTypeOperations
                    toggleTaskType={toggleTaskType}
                    addSearchTerm={addSearchTerm}
                    handleSort={handleSort}
                    sortAsc={sortAsc}
                />
            </div>
            <div className="mt-5    ">
                <OStasksType
                    searchTerm={searchTerm}
                    typesUpdated={typesUpdated}
                    toggleEditType={toggleEditType}
                    sortAsc={sortAsc}
                />
            </div>
        </div>
    );
};
export default TaskTypePage;
