import React, { useState } from 'react';
import { customDateFormat } from 'utills/moment';
import CollapsIcon from 'utills/svgs/CollapsIcon';
import ExpandIcon from 'utills/svgs/ExpandIcon';

export const RentalinfoCard = ({ data }) => {
    const [isExpand, setIsExpand] = useState(false);

    return (
        <>
            <div className="bg-white shadow-md rounded-2xl p-5">
                <div className="flex items-center">
                    <h3 className="flex-1 text-lg font-semibold">Property Details</h3>
                    <span className="cursor-pointer" onClick={() => setIsExpand(!isExpand)}>
                        {isExpand ? <CollapsIcon className={'w-6 h-6'} /> : <ExpandIcon className={'w-6 h-6'} />}
                    </span>
                </div>
                <div className="flex items-center justify-between flex-wrap mt-2">
                    <h4 className="text-lg font-semibold">{data?.name || ''}</h4>
                    <div className="flex items-center gap-2">
                        <div className="tracking-normal">
                            <span className="px-3 py-1.5 text-third bg-[#b695f838] !font-medium !rounded-xl !text-sm lg:text-lg">
                                Private use : {data?.private_use_percentage || '0'}%
                            </span>
                        </div>
                        <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                            No. of Weeks:{' '}
                            <span className="text-black font-medium">{data?.rented_duration_in_weeks || ''}</span>
                        </h2>
                    </div>
                </div>
                <div className="flex items-center justify-between flex-wrap mt-2">
                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                        First Earned Rental Income:{' '}
                        <span className="text-black font-medium">
                            {customDateFormat(data?.rental_first_income_date, 'DD-MM-YYYY', 'DD/MM/YYYY')} &nbsp;
                        </span>
                    </h2>
                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                        Date Property was Purchase:{' '}
                        <span className="text-black font-medium min-w-[98px] inline-block text-left">
                            {customDateFormat(data?.purchase_date, 'DD-MM-YYYY', 'DD/MM/YYYY')} &nbsp;
                        </span>
                    </h2>
                </div>
                <div className="flex items-center justify-between flex-wrap mt-2">
                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                        Loan Amount:{' '}
                        <span className="text-black font-medium min-w-[90px] inline-block text-left">
                            {data?.loan_amount !== '' ? '$' + data?.loan_amount : ''}
                        </span>
                    </h2>
                    <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                        Purchase Price of Property:{' '}
                        <span className="text-black font-medium min-w-[112px] inline-block text-left">
                            {data?.purchase_price !== '' ? '$' + data?.purchase_price : ''}
                        </span>
                    </h2>
                </div>

                <div
                    className={`transition-opacity duration-1000 ease-out ${
                        isExpand ? 'opacity-100 h-auto' : 'opacity-0 h-0 hidden'
                    }`}
                >
                    {isExpand && (
                        <>
                            <div className="flex items-center justify-between flex-wrap mt-2">
                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                    Rental Property Address:
                                    <span className="text-black font-medium"> {data?.address || ''}</span>
                                </h2>
                            </div>

                            <hr className="my-4" />

                            <div className="my-2">
                                <h3 className="text-lg font-semibold">Lease Detail</h3>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-0">
                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                    Current Lease Start Date:{' '}
                                    <span className="text-black font-medium">
                                        {customDateFormat(data?.lease_start_date, 'DD-MM-YYYY', 'DD/MM/YYYY')} &nbsp;
                                    </span>
                                </h2>
                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                    Current Lease End Date:{' '}
                                    <span className="text-black font-medium">
                                        {customDateFormat(data?.lease_end_date, 'DD-MM-YYYY', 'DD/MM/YYYY')} &nbsp;
                                    </span>
                                </h2>
                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                    Payment Cycle:{' '}
                                    <span className="text-black font-medium">{data?.payment_cycle || ''}</span>
                                </h2>
                                <h2 className="tracking-normal py-1.5 text-[#666666] !font-normal !rounded-xl !text-md lg:text-md">
                                    Rent Amount:{' '}
                                    <span className="text-black font-medium">${data?.rent_amount || '0'}</span>
                                </h2>
                            </div>
                            <hr className="my-4" />

                            <div className="my-2">
                                <h3 className="text-lg font-semibold">Ownership</h3>
                            </div>

                            <div className="!rounded-xl overflow-hidden">
                                <table className="table-fixed min-w-full divide-y divide-gray-300">
                                    <thead className="">
                                        <tr className="bg-[#F8F8FE]">
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-md font-normal text-[#666666] sm:pl-3"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-md font-normal text-[#666666]"
                                            >
                                                Percentage Owned
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {data &&
                                            data?.owners?.map((owner, index) => (
                                                <tr className="even:bg-gray-50" key={'owner_details' + index}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900 sm:pl-3 font-poppins">
                                                        {owner?.first_name} {owner?.last_name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-md  font-medium text-gray-900 font-poppins">
                                                        {owner?.percentage_owned}%
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
