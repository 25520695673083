import React from 'react';

export const PhoneNo = () => {
    return (
        <svg width="22" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_53_8565" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="21">
                <path
                    d="M16.2 6.88905V1.95C16.2 1.69804 16.0999 1.45641 15.9218 1.27825C15.7436 1.10009 15.502 1 15.25 1H1.95C1.69804 1 1.45641 1.10009 1.27825 1.27825C1.10009 1.45641 1 1.69804 1 1.95V19.05C1 19.302 1.10009 19.5436 1.27825 19.7218C1.45641 19.8999 1.69804 20 1.95 20H6.7"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.80273 5.75H10.9777M4.80273 9.075H7.17773"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                />
                <path
                    d="M8.59961 15.25C8.59961 17.8734 10.7263 20 13.3496 20C15.973 20 18.0996 17.8734 18.0996 15.25C18.0996 12.6266 15.973 10.5 13.3496 10.5C10.7263 10.5 8.59961 12.6266 8.59961 15.25Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M13.3496 16.2031V17.6281" stroke="black" strokeWidth="1.4" strokeLinecap="round" />
                <path
                    d="M13.3504 14.2984C13.8751 14.2984 14.3004 13.8731 14.3004 13.3484C14.3004 12.8238 13.8751 12.3984 13.3504 12.3984C12.8257 12.3984 12.4004 12.8238 12.4004 13.3484C12.4004 13.8731 12.8257 14.2984 13.3504 14.2984Z"
                    fill="black"
                />
            </mask>
            <g mask="url(#mask0_53_8565)">
                <path d="M-2.80273 -0.898438H19.9973V21.9016H-2.80273V-0.898438Z" fill="black" />
            </g>
        </svg>
    );
};
