import { MSConfig } from 'app-configs/MSConfig';
import { config } from 'app-configs/XeroConfig';

export const getAuthUrl = () => {
    const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${
        config.clientId
    }&redirect_uri=${encodeURIComponent(config.redirectUri)}&scope=${encodeURIComponent(config.scope)}&state=${
        config.state
    }`;
    window.location.href = authUrl;
};
export const getMSAuthUrl = () => {
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${MSConfig.clientId}&prompt=select_account&redirect_uri=${MSConfig.redirectUri}&response_type=code&scope=${MSConfig.scope}&state=${MSConfig.state}`;
    window.location.href = authUrl;
};
