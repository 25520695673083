import React from "react";
import OtpVerification from "../../components/login-steps/OtpVerification";

export const OTPVerification = () => {
  return (
    <div>
      <OtpVerification />
    </div>
  );
};
