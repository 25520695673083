import React from 'react';

export const CollapsArrow = () => {
    return (
        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6653 8.6705C15.219 9.10983 14.4953 9.10983 14.049 8.6705L8 2.71599L1.95098 8.6705C1.50467 9.10983 0.781048 9.10983 0.334735 8.6705C-0.11158 8.23116 -0.11158 7.51884 0.334735 7.0795L7.19188 0.329505C7.63819 -0.109835 8.36181 -0.109835 8.80812 0.329505L15.6653 7.0795C16.1116 7.51884 16.1116 8.23116 15.6653 8.6705Z"
                fill="#222222"
            />
        </svg>
    );
};
