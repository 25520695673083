import React, { useState } from 'react';
import { cn } from 'utills/tailwindUtil';

const TruncateDescription = ({ maxLength = 200, text = '', sx = '' }) => {
    const [showFullText, setShowFullText] = useState(false);
    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    const truncatedText = showFullText
        ? text
        : `${text.slice(0, maxLength)}  ${text?.length > maxLength ? '... ' : ' '} `;

    return (
        <div>
            <div
                className={cn(
                    'ann-desc text-[10px] md:text-[12px] lg:text-[14] xl:text-[16px] font-[400] contents',
                    sx
                )}
                dangerouslySetInnerHTML={{ __html: truncatedText }}
            />
            {!showFullText && text?.length > maxLength && (
                <span style={{ color: '#b695f8', cursor: 'pointer' }} onClick={toggleShowFullText}>
                    Read more
                </span>
            )}
            {showFullText && (
                <span style={{ color: '#b695f8', cursor: 'pointer' }} onClick={toggleShowFullText}>
                    {' '}
                    Read less
                </span>
            )}
        </div>
    );
};

export default TruncateDescription;
