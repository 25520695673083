import React, { useMemo, useRef, useState, useEffect } from 'react';
import JoditEditor, { Jodit } from 'jodit-react';
// import JoditEditor, { Jodit } from 'jodit-pro-react';
import './css/TextEditor.css'; // Import CSS Module
import { cn } from 'utills/tailwindUtil';

const DraftEditorNew = ({
    onChange,
    initialValue,
    placeholder,
    required,
    hasError,
    sx, // CSS classes for the outer box
    forceUpdate = false, // When true, the component should reload
    setForceUpdate, // This should set forceUpdate to false after update
    inputsx, // Styles for the inner box that define height, overflow, etc.
    extraConfigs = {}
}) => {
    const editor = useRef(null);
    // const [content, setContent] = useState(initialValue || '<div></div>');
    const [content, setContent] = useState(initialValue || '');

    // Effect to handle forceUpdate logic
    useEffect(() => {
        if (forceUpdate) {
            setContent(initialValue || ''); // Reset content when forceUpdate is true
            if (setForceUpdate) {
                setForceUpdate(false); // Set forceUpdate back to false
            }
        }
    }, [forceUpdate, initialValue, setForceUpdate]);

    Jodit.prototype.beforeInitHook = function () {
        // do nothing
    };

    const config = useMemo(
        () => ({
            height: 500,
            placeholder: placeholder || 'Start typing...',

            // defaultEmptyCell: '&nbsp;', // Ensure valid content in empty cells
            // enter: 'BR', // Use <br> for new lines
            readonly: false, // All options from https://xdsoft.net/jodit/docs/
            toolbarAdaptive: true,
            askBeforePasteHTML: true,
            askBeforePasteFromWord: true,
            toolbar: true,
            //
            useSearch: true,
            uploader: {
                insertImageAsBase64URI: true
            },
            addNewLine: false,
            // buttons:
            //     'bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,superscript,subscript,|,cut,copy,paste,copyformat,|,hr, link,|, indent,outdent,left,|,ai-assistant,classSpan,file,image,video,spellcheck,speechRecognize,fullsize',

            buttons: [
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'eraser',
                'ul',
                'ol',
                'font',
                'fontsize',
                'paragraph',
                'lineHeight',
                'superscript',
                'subscript',
                '|',
                'cut',
                'copy',
                'paste',
                'copyformat',
                '|',
                'hr',
                'link',
                '|',
                'indent',
                'outdent',
                'left',
                '|',
                'ai-assistant',
                'ai-commands',
                'classSpan',
                'file',
                'image',
                'video',
                'spellcheck',
                'speechRecognize',
                'fullsize',
                'source'
            ],

            // extraButtons: [
            //     {
            //         name: 'customButton',
            //         iconURL: `https://img.icons8.com/?size=100&id=37628&format=png&color=000000`,
            //         exec: async (editor) => {
            //             const existingText = editor.getEditorValue();
            //             console.info('existingText', existingText);
            //             try {
            //                 const response = await new Promise((resolve) => {
            //                     setTimeout(() => {
            //                         resolve({ newText: 'resolved' });
            //                     }, 2000);
            //                 });
            //                 if (response.newText) {
            //                     editor.setEditorValue(response.newText);
            //                 } else {
            //                     alert('No new text returned from API');
            //                 }
            //             } catch (error) {
            //                 console.error('Error calling API:', error);
            //             }
            //         }
            //     },
            //     {
            //         name: 'customDropdown',
            //         iconURL: 'https://img.icons8.com/?size=100&id=11107&format=png&color=000000', // Replace with your custom icon URL
            //         popup: (editor) => {
            //             const dropdownContent = document.createElement('div');
            //             dropdownContent.style.padding = '10px';
            //             dropdownContent.style.border = '1px solid #ccc';
            //             dropdownContent.style.borderRadius = '5px';
            //             dropdownContent.style.backgroundColor = '#fff';
            //             const option1 = document.createElement('div');
            //             option1.textContent = 'Option 1';
            //             option1.title = 'This is Option 1';
            //             option1.style.cursor = 'pointer';
            //             option1.style.marginBottom = '5px';
            //             option1.addEventListener('click', () => {
            //                 alert('Option 1 clicked');
            //                 editor.selection.remove();
            //             });
            //             dropdownContent.appendChild(option1);
            //             const option2 = document.createElement('div');
            //             option2.textContent = 'Option 2';
            //             option2.title = 'This is Option 2';
            //             option2.style.cursor = 'pointer';
            //             option2.addEventListener('click', () => {
            //                 alert('Option 2 clicked');
            //                 editor.selection.remove();
            //             });
            //             dropdownContent.appendChild(option2);
            //             return dropdownContent;
            //         },
            //         tooltip: 'Custom Dropdown Button'
            //     }
            // ],
            aiAssistant: {
                aiCommonPrefixPrompt: 'Result should be on Spanish',
                aiCommonSuffixPrompt:
                    'Please make the necessary changes to enhance the quality of the content. Text should be clear, concise, and engaging.',
                aiAssistantCallback(_propmt, _htmlFragment) {
                    return Promise.resolve('AI Assistant is not configured');
                    // Make API call to OpenAI
                    // return fetch('https://api.openai.com/v1/chat/completions', {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json',
                    //         Authorization: 'Bearer ' + Jodit.constants.TOKENS.TOKEN_GPT
                    //     },
                    //     body: JSON.stringify({
                    //         model: 'gpt-3.5-turbo',
                    //         messages: [
                    //             {
                    //                 role: 'system',
                    //                 content: prompt
                    //             },
                    //             {
                    //                 role: 'user',
                    //                 content: htmlFragment
                    //             }
                    //         ]
                    //     })
                    // })
                    //     .then((response) => response.json())
                    //     .then((data) => {
                    //         if (data.error) {
                    //             throw new Error(data.error.message);
                    //         }
                    //         return Jodit.modules.Helpers.get('choices.0.message.content', data) ?? '';
                    //     });
                }
            },
            // safeMode: true,
            enter: 'DIV', // Use <div> instead of <p> for new lines
            cleanHTML: {
                removeEmptyNodes: false // Prevent removing empty nodes
            },
            defaultMode: '1',
            // extraPlugins: ['google-search'],
            usePlaceholderForEmptyElements: false,
            style: {
                fontFamily: 'Arial',
                fontSize: '18px'
            },
            ...extraConfigs
        }),
        [placeholder, forceUpdate]
    );

    const handleContentChange = (newContent) => {
        if (onChange) {
            onChange(newContent);
        } else {
            setContent(newContent);
        } // Call onChange if provided
    };

    return (
        <div className={`jodit-scope ${sx || ''}`}>
            {/* Jodit Editor */}
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // TabIndex of textarea
                // onBlur={(newContent) => handleContentChange(newContent)} // Update content on blur
                onBlur={(content) => {
                    // const cleanedContent = content.replace(/<div([^>]*)>\s*<br\s*\/?>\s*<\/div>/g, '<div$1></div>');
                    handleContentChange(content);
                }}
                // onChange={(newContent) => handleContentChange(newContent)} // Update content on change
                onChange={(_newContent) => {}} // Update content on change
                className={cn(
                    '!rounded-xl !overflow-hidden',
                    required && hasError ? '!border-red-500' : '!border-secondarybg',
                    inputsx || ''
                )}
            />
            {/* Validation Message */}
            {required && hasError && <div className="text-sm text-red-500">This field is required.</div>}
        </div>
    );
};

export default DraftEditorNew;
