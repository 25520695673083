import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import { FileIcon } from 'utills/svgs/FileIcon';
import { NoDataFound } from 'components/common/NoDataFound';
import { SideTimes } from 'utills/svgs/SideTimes';

export const Attachments = ({ toggleAttachments }) => {
    const { form } = useSelector((state) => state.global);
    const { formDetails } = useSelector((state) => state.global);
    const [attachments, setAttachments] = useState([]);
    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomUrl, setZoomUrl] = useState(null);

    const toggleZoom = (url) => {
        setZoomUrl(url);
        setIsZoomed(true);
    };

    useEffect(() => {
        if (formDetails?.steps?.length > 0) {
            const newAttachments = [];

            const findFieldByRowId = (steps) => {
                for (const step of steps) {
                    for (const field of step.fields) {
                        if (field?.type === 'document' || field?.type === 'image') {
                            newAttachments.push({
                                url: field?.default_value[0]?.url,
                                type: field?.type,
                                name: field?.default_value[0]?.originalname
                            });
                        }
                        if (field.conditional_steps) {
                            for (const key in field.conditional_steps) {
                                const conditionalStep = field.conditional_steps[key];
                                if (conditionalStep) {
                                    findFieldByRowId(conditionalStep.steps);
                                }
                            }
                        }
                    }
                }

                return null;
            };

            findFieldByRowId(formDetails?.steps);

            setAttachments(newAttachments);
        }
    }, [form]);

    return (
        <div>
            {' '}
            <div className="add-p-side grid grid-cols-5 text-black">
                <div className="md:col-span-3 hidden md:block left-side"></div>
                <div className="right-side col-span-5 md:col-span-2">
                    <motion.div
                        initial={{ x: 700 }}
                        animate={{ x: 0 }}
                        transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                        className="inner-right relative"
                    >
                        {isZoomed && <ZoomImage src={zoomUrl} alt="Profile" onClose={() => setIsZoomed(false)} />}

                        <div>
                            <div
                                onClick={toggleAttachments}
                                className="absolute   text-white p-2 right-1 top-1 cursor-pointer"
                            >
                                <SideTimes />
                            </div>

                            <div className="add-detail pt-10 px-5">
                                <div className="title"> Attachments</div>
                            </div>
                        </div>
                        <div className="m-5">
                            {' '}
                            {attachments?.length > 0 ? (
                                attachments?.map((att, index) => (
                                    <div key={index} className="mt-5">
                                        {att?.type === 'image' && (
                                            <div
                                                onClick={() => toggleZoom(att?.url)}
                                                className="w-full h-[200px] border border-secondarybg overflow-hidden rounded-lg cursor-pointer"
                                            >
                                                <img src={att.url} alt="alt" className="w-full h-full object-cover" />
                                            </div>
                                        )}
                                        {att?.type === 'document' && (
                                            <div className="flex gap-2 items-center flex-wrap">
                                                <a
                                                    href={att?.url}
                                                    className="w-full h-[200px] border cursor-pointer bg-thirdbg text-white buttons-font font-poppins border-secondarybg overflow-hidden rounded-lg flex justify-center items-center"
                                                >
                                                    <div className="flex gap-2 items-center flex-col">
                                                        <div className="scale-125 invert">
                                                            <FileIcon />
                                                        </div>
                                                        <div>{att?.name}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <NoDataFound height="30vh" message="No Attachments Found!" />
                            )}
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};
