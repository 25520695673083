import AuthCardLayout from 'layouts/AuthCardLayout';
import React from 'react';
import { SheildQuestion } from 'utills/svgs/SheildQuestion';
import { motion } from 'framer-motion';

export const ResetIndicator = (props) => {
    const handleResetQuestions = () => {
        props.handleCondition(16);
    };
    return (
        <div className="flex min-h-[90vh] flex-col justify-center pb-10 sm:px-2 lg:px-2  ">
            <AuthCardLayout>
                <div className="mt-20">
                    {' '}
                    <div className="flex justify-center items-center ">
                        <motion.div
                            initial={{ opacity: 0, rotate: 100 }}
                            animate={{ opacity: 1, rotate: 0 }}
                            transition={{ delay: 0.3, type: 'spring', duration: 0.6 }}
                            className="svg-wrapper scale-75 "
                        >
                            <SheildQuestion />
                        </motion.div>
                    </div>
                    <div className="mt-5">
                        <h1 className="text-center font-poppins text-2xl font-[600]">Setup Security Questions</h1>
                    </div>
                    <div className="px-5 mt-3">
                        <p className="text-center small-text text-sixth font-poppins mx-10">
                            You reset your security questions. Now you have to setup your security questions again.
                        </p>
                    </div>
                    <div className="s-btn-wrapper my-3 mt-24 mb-5">
                        <button
                            onClick={handleResetQuestions}
                            className="btn-2 px-16 py-2 buttons-font rounded-lg font-fira"
                        >
                            Proceed
                        </button>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};
