import React from 'react';

const CollapsIcon = ({ className }) => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M9.66778 1.64648C6.80841 1.81641 5.07403 2.37305 3.84942 3.51562C3.26348 4.06055 2.92364 4.52344 2.57794 5.24414C1.88067 6.66797 1.65216 8.19727 1.59942 11.7305C1.57013 13.4473 1.58184 13.9805 1.64044 14.1211C1.8045 14.5137 2.32013 14.7656 2.742 14.6543C3.01153 14.5781 3.32208 14.2734 3.39825 13.998C3.43341 13.8926 3.45684 13.1426 3.45684 12.334C3.45684 8.60156 3.77325 6.65039 4.56427 5.52539C5.49005 4.20703 6.82598 3.70312 9.90216 3.51562C11.1561 3.43945 18.8436 3.43945 20.0975 3.51562C22.5057 3.66211 23.7772 3.99609 24.6795 4.7168C25.8338 5.63086 26.3084 7.00781 26.4842 9.93164C26.5604 11.2266 26.5604 18.7734 26.4842 20.0684C26.3436 22.4766 26.0096 23.7598 25.283 24.6855C24.1932 26.0684 22.2654 26.543 17.6658 26.543C16.8572 26.543 16.1072 26.5723 15.9842 26.6016C15.6971 26.6836 15.3748 27.0469 15.3221 27.3574C15.2693 27.668 15.4686 28.0957 15.7557 28.2832C15.949 28.4121 15.9959 28.418 17.5428 28.418C22.3768 28.418 24.5858 27.9258 26.1092 26.5195C27.2108 25.5117 27.8084 24.293 28.119 22.4414C28.365 20.9766 28.3885 20.3613 28.3885 14.9707C28.3885 9.5625 28.3709 9.02344 28.119 7.53516C27.8318 5.85938 27.3338 4.74609 26.4315 3.79102C25.9158 3.24609 25.4471 2.90039 24.7557 2.57227C23.8182 2.12109 22.8162 1.88086 21.0936 1.69336C20.3377 1.61133 10.9217 1.57031 9.66778 1.64648Z"
                fill="#B695F8"
            />
            <path
                d="M20.9531 7.88086C20.7715 7.94531 20.1797 8.50195 18.334 10.3477L15.9434 12.7383L15.9258 11.3555C15.9082 10.0605 15.9023 9.96094 15.7852 9.80859C15.5566 9.49805 15.3691 9.4043 15 9.4043C14.6309 9.4043 14.4434 9.49805 14.2148 9.80859C14.0918 9.9668 14.0918 10.0312 14.0918 12.6562C14.0918 15.2812 14.0918 15.3457 14.2148 15.5039C14.2793 15.5918 14.4082 15.7207 14.4961 15.7852C14.6543 15.9082 14.7188 15.9082 17.3438 15.9082C19.9688 15.9082 20.0332 15.9082 20.1914 15.7852C20.502 15.5566 20.5957 15.3691 20.5957 15C20.5957 14.6309 20.502 14.4434 20.1914 14.2148C20.0391 14.0977 19.9395 14.0918 18.6445 14.0742L17.2617 14.0566L19.6699 11.6426C22.2832 9.02344 22.2656 9.04688 22.1484 8.53125C22.1016 8.32031 21.8672 8.00977 21.6797 7.91016C21.4922 7.81641 21.1758 7.79883 20.9531 7.88086Z"
                fill="#B695F8"
            />
            <path
                d="M4.74578 16.6463C3.0407 16.8455 2.09735 17.6717 1.71649 19.2772C1.59344 19.7811 1.53485 24.1404 1.63445 25.0955C1.83367 26.9471 2.60711 27.8904 4.27703 28.283C4.78094 28.4061 9.14031 28.4647 10.0954 28.3651C11.947 28.1658 12.8845 27.3924 13.2829 25.7283C13.4001 25.2186 13.4645 20.8651 13.3649 19.9041C13.1657 18.0526 12.3923 17.1092 10.7223 16.7166C10.4177 16.6404 9.84344 16.6229 7.76336 16.6053C6.34539 16.5994 4.98602 16.617 4.74578 16.6463ZM10.0602 18.5213C10.3122 18.5506 10.652 18.6444 10.822 18.7264C11.0798 18.8494 11.1501 18.9197 11.2731 19.1776C11.4958 19.6404 11.5427 20.2147 11.5427 22.4998C11.5427 24.785 11.4958 25.3592 11.2731 25.8221C11.1501 26.0799 11.0798 26.1502 10.822 26.2733C10.3591 26.4959 9.78485 26.5428 7.49969 26.5428C5.21453 26.5428 4.64031 26.4959 4.17742 26.2733C3.91961 26.1502 3.8493 26.0799 3.72625 25.8221C3.5036 25.3592 3.45672 24.785 3.45672 22.4998C3.45672 20.2147 3.5036 19.6404 3.72625 19.1776C3.8493 18.9197 3.91961 18.8494 4.17742 18.7264C4.34735 18.6444 4.65203 18.5565 4.86297 18.5272C5.40789 18.451 9.5036 18.4451 10.0602 18.5213Z"
                fill="#B695F8"
            />
        </svg>
    );
};

export default CollapsIcon;
