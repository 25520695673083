import React from 'react';

export const LargeJobsIcon = ({ className }) => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g clipPath="url(#clip0_17380_791)">
                <path
                    d="M23.75 8.75C24.44 8.75 25 8.19125 25 7.5V6.25C25 4.1825 26.6825 2.5 28.75 2.5H41.25C43.3175 2.5 45 4.1825 45 6.25V7.5C45 8.19125 45.56 8.75 46.25 8.75C46.94 8.75 47.5 8.19125 47.5 7.5V6.25C47.5 2.80375 44.6963 0 41.25 0H28.75C25.3038 0 22.5 2.80375 22.5 6.25V7.5C22.5 8.19125 23.06 8.75 23.75 8.75ZM32.5 33.75C30.4325 33.75 28.75 35.4325 28.75 37.5V47.5C28.75 49.5675 30.4325 51.25 32.5 51.25H37.5C39.5675 51.25 41.25 49.5675 41.25 47.5V37.5C41.25 35.4325 39.5675 33.75 37.5 33.75H32.5ZM38.75 37.5V47.5C38.75 48.19 38.1888 48.75 37.5 48.75H32.5C31.8112 48.75 31.25 48.19 31.25 47.5V37.5C31.25 36.81 31.8112 36.25 32.5 36.25H37.5C38.1888 36.25 38.75 36.81 38.75 37.5ZM75 47.5H58.75C55.9925 47.5 53.75 49.7425 53.75 52.5V67.5H51.25C48.4925 67.5 46.25 69.7425 46.25 72.5V75C46.25 77.7575 48.4925 80 51.25 80H75C77.7575 80 80 77.7575 80 75V52.5C80 49.7425 77.7575 47.5 75 47.5ZM51.25 77.5C49.8713 77.5 48.75 76.3788 48.75 75V72.5C48.75 71.1212 49.8713 70 51.25 70H53.75V75C53.75 76.3788 52.6287 77.5 51.25 77.5ZM77.5 75C77.5 76.3788 76.3787 77.5 75 77.5H55.5788C56.0063 76.7637 56.25 75.91 56.25 75V52.5C56.25 51.1213 57.3713 50 58.75 50H75C76.3787 50 77.5 51.1213 77.5 52.5V75Z"
                    fill="#0A1E46"
                    stroke="#0A1E46"
                    strokeWidth="0.5"
                />
                <path
                    d="M72.5 56.25H62.5C61.81 56.25 61.25 56.8088 61.25 57.5C61.25 58.1912 61.81 58.75 62.5 58.75H72.5C73.19 58.75 73.75 58.1912 73.75 57.5C73.75 56.8088 73.19 56.25 72.5 56.25ZM72.5 62.5H62.5C61.81 62.5 61.25 63.0588 61.25 63.75C61.25 64.4412 61.81 65 62.5 65H72.5C73.19 65 73.75 64.4412 73.75 63.75C73.75 63.0588 73.19 62.5 72.5 62.5ZM72.5 68.75H62.5C61.81 68.75 61.25 69.3088 61.25 70C61.25 70.6912 61.81 71.25 62.5 71.25H72.5C73.19 71.25 73.75 70.6912 73.75 70C73.75 69.3088 73.19 68.75 72.5 68.75ZM52.5 63.75C52.5 63.0588 51.94 62.5 51.25 62.5H6.25C4.1825 62.5 2.5 60.8175 2.5 58.75V33.73C2.86375 34.0037 3.25 34.2513 3.675 34.4438L18.6162 43.16C18.6575 43.1838 18.7 43.205 18.745 43.225C19.5387 43.5725 20.3825 43.75 21.25 43.75H26.25C26.94 43.75 27.5 43.1912 27.5 42.5C27.5 41.8088 26.94 41.25 26.25 41.25H21.25C20.7525 41.25 20.2675 41.1538 19.8088 40.9625L4.875 32.25C4.83375 32.2262 4.79 32.205 4.74625 32.185C3.3825 31.5887 2.5 30.2388 2.5 28.75V16.25C2.5 14.1825 4.1825 12.5 6.25 12.5H63.75C65.8175 12.5 67.5 14.1825 67.5 16.25V28.75C67.5 30.2763 66.5888 31.6375 65.1787 32.2175C65.1387 32.235 65.1 32.2525 65.0625 32.2725L48.8737 40.99C48.4338 41.1625 47.9712 41.25 47.5 41.25H43.75C43.06 41.25 42.5 41.8088 42.5 42.5C42.5 43.1912 43.06 43.75 43.75 43.75H47.5C48.3187 43.75 49.1188 43.5925 49.8787 43.28C49.9188 43.2638 49.9588 43.2463 49.9975 43.225L66.1938 34.5025C66.6688 34.3012 67.1 34.0337 67.5 33.7337V45C67.5 45.6912 68.06 46.25 68.75 46.25C69.44 46.25 70 45.6912 70 45V16.25C70 12.8038 67.1963 10 63.75 10H6.25C2.80375 10 0 12.8038 0 16.25V58.75C0 62.1963 2.80375 65 6.25 65H51.25C51.94 65 52.5 64.4412 52.5 63.75Z"
                    fill="#0A1E46"
                    stroke="#0A1E46"
                    strokeWidth="0.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_17380_791">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
