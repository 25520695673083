import React from 'react';

export const CalendarIcon2 = ({ className }) => {
    return (
        <svg
            width="29"
            height="28"
            viewBox="0 0 29 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M27.3373 5.57831V5.42831H27.1873H24.6186V3.86941H27.4125C27.6037 3.87285 27.7925 3.9148 27.9681 3.99299C28.1442 4.07135 28.3037 4.18462 28.4374 4.32652L28.5466 4.22363L28.4374 4.32652C28.5712 4.46843 28.6766 4.63618 28.7474 4.82029C28.8183 5.0044 28.8531 5.20114 28.8498 5.39923L28.8498 5.39923V5.40171L28.8497 26.2028L28.8498 26.2053C28.8531 26.4034 28.8183 26.6001 28.7474 26.7843C28.6766 26.9684 28.5712 27.1361 28.4374 27.278C28.3037 27.4199 28.1442 27.5332 27.9681 27.6116C27.7925 27.6898 27.6037 27.7317 27.4125 27.7351H1.58752C1.39632 27.7317 1.20753 27.6898 1.03187 27.6116C0.855835 27.5332 0.696324 27.4199 0.562554 27.278C0.428775 27.1361 0.323376 26.9684 0.252556 26.7843C0.181734 26.6001 0.14693 26.4034 0.150212 26.2053H0.150233V26.2028V5.40171H0.150254L0.150212 5.39923C0.14693 5.20114 0.181734 5.0044 0.252556 4.82029C0.323376 4.63618 0.428775 4.46843 0.562553 4.32652C0.696324 4.18462 0.855836 4.07135 1.03187 3.99299C1.20751 3.9148 1.39628 3.87285 1.58746 3.86941H4.38141V5.42831H1.8127H1.6627V5.57831V26.0262V26.1762H1.8127H27.1873H27.3373V26.0262V5.57831Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.3"
            />
            <path d="M5.5875 11.3035H7.09997V12.8624H5.5875V11.3035Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path d="M11.025 11.3035H12.5375V12.8624H11.025V11.3035Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path
                d="M16.4625 11.3035H17.975V12.8624H16.4625V11.3035Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.3"
            />
            <path d="M21.9 11.3035H23.4125V12.8624H21.9V11.3035Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path d="M5.5875 15.9504H7.09997V17.5093H5.5875V15.9504Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path d="M11.025 15.9504H12.5375V17.5093H11.025V15.9504Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path
                d="M16.4625 15.9504H17.975V17.5093H16.4625V15.9504Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.3"
            />
            <path d="M21.9 15.9504H23.4125V17.5093H21.9V15.9504Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path d="M5.5875 20.5973H7.09997V22.1562H5.5875V20.5973Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path d="M11.025 20.5973H12.5375V22.1562H11.025V20.5973Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path
                d="M16.4625 20.5973H17.975V22.1562H16.4625V20.5973Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.3"
            />
            <path d="M21.9 20.5973H23.4125V22.1562H21.9V20.5973Z" fill="#0A1E46" stroke="white" strokeWidth="0.3" />
            <path
                d="M7.78339 7.05866C7.64126 7.20443 7.44931 7.28561 7.24999 7.28561C7.05066 7.28561 6.85871 7.20443 6.71658 7.05866C6.57432 6.91276 6.49375 6.71409 6.49375 6.50616V0.929451C6.49375 0.721521 6.57432 0.522847 6.71658 0.376946C6.85871 0.231174 7.05066 0.15 7.24999 0.15C7.44931 0.15 7.64126 0.231174 7.78339 0.376946C7.92565 0.522847 8.00622 0.721521 8.00622 0.929451V6.50616C8.00622 6.71409 7.92565 6.91276 7.78339 7.05866Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.3"
            />
            <path
                d="M22.2834 7.05866C22.1413 7.20443 21.9493 7.28561 21.75 7.28561C21.5507 7.28561 21.3587 7.20443 21.2166 7.05866C21.0743 6.91276 20.9938 6.71409 20.9938 6.50616V0.929451C20.9938 0.72152 21.0743 0.522847 21.2166 0.376946C21.3587 0.231174 21.5507 0.15 21.75 0.15C21.9493 0.15 22.1413 0.231174 22.2834 0.376946C22.4256 0.522847 22.5062 0.72152 22.5062 0.929451V6.50616C22.5062 6.71409 22.4256 6.91276 22.2834 7.05866Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.3"
            />
            <path
                d="M10.1188 3.86941H18.8811V5.42831H10.1188V3.86941Z"
                fill="#0A1E46"
                stroke="white"
                strokeWidth="0.3"
            />
        </svg>
    );
};
