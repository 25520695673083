import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppInviteIcon from 'utills/svgs/AppInviteIcon';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import ClientRefNoIcon from 'utills/svgs/ClientRefNoIcon';
import AddClientRefNoSetting from './AddClientRefNoSetting';
import { AddClientsIcon } from 'utills/svgs/AddClientsIcon';
import { AllClientsIcon } from 'utills/svgs/AllClientsIcon';

export const ClientsSettings = () => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings`);
        }
    };
    return (
        <div>
            <SidebarLayout>
                {open && <AddClientRefNoSetting open={open} setOpen={setOpen} />}
                <div>
                    <Jumbotron
                        title="Clients"
                        directory={`Settings`}
                        path={[
                            { title: 'Settings', link: 'settings' },
                            {
                                title: 'Clients',
                                link: ''
                            }
                        ]}
                        backArrowProp={backArrowProp}
                    />
                    <div className="mx-5 md:mx-10">
                        <div className="flex gap-5 items-center flex-wrap">
                            <GridCard text="Application Invite" iconSet={{ icon: AppInviteIcon }} link={'/invites'} />

                            <GridCard
                                text="Client Groups"
                                iconSet={{ icon: AllClientsIcon, sx: ' w-20 h-20' }}
                                link={'/settings/groups/clients'}
                            />
                            <GridCard
                                text="Clients Reference Number Settings"
                                iconSet={{ icon: ClientRefNoIcon }}
                                atClick={() => {
                                    setOpen(true);
                                }}
                            />

                            <GridCard
                                text="Offline clients import export"
                                iconSet={{ icon: AddClientsIcon }}
                                link={'/offline/clients/import-export'}
                            />
                        </div>
                    </div>
                </div>
            </SidebarLayout>
        </div>
    );
};
