import React from 'react';

export const BgDownloadIcon = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.05" width="36" height="36" rx="18" fill="url(#paint0_linear_179_9082)" />
                <path
                    d="M18.5 20.3333V11M18.5 20.3333L15.5 17.2222M18.5 20.3333L21.5 17.2222M11 21.8889L11.4658 23.8217C11.5469 24.1582 11.7341 24.457 11.9977 24.6705C12.2614 24.884 12.5863 24.9999 12.9207 25H24.0792C24.4137 24.9999 24.7386 24.884 25.0023 24.6705C25.2659 24.457 25.4531 24.1582 25.5342 23.8217L26 21.8889"
                    stroke="url(#paint1_linear_179_9082)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_179_9082"
                        x1="19.7664"
                        y1="0.447084"
                        x2="19.7394"
                        y2="36.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_179_9082"
                        x1="19.236"
                        y1="11.1739"
                        x2="19.2262"
                        y2="25"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
