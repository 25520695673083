import React from 'react';
import { BlueTimes } from 'utills/svgs/BlueTimes';
import { RefreshIcon } from 'utills/svgs/RefreshIcon';
import './css/teams.css';

import { Loader } from 'components/common/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import {
    fetchInvitedEmployeesThunk,
    revokeEmployeeInviteThunk,
    sendEmployeeInviteThunk
} from 'store/settings/team/team';

import { IncompleteCircle } from 'utills/svgs/IncompleteCircle';
import ConfirmationModal from 'utills/confirmationModal';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR } from 'utills/globalVars';
import useRoleGuard from 'Hooks/useRoleGuard';

const InvitedTeam = ({ inviteUpdated, searchTerm }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);
    const { sideLoader } = useSelector((state) => state.global);
    const [loadIndex, setLoadIndex] = useState(null);
    const [team, setTeam] = useState(null);
    const [teamUpdated, setTeamUpdated] = useState(false);
    const [resendUpdated, setResendUpdated] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [invite_id, setInviteId] = useState('');
    const { hasPermissions } = useRoleGuard();

    const filteredTeam = team?.filter((member) => {
        return (
            member.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            member.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            member.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            member.phone_number.toLowerCase().includes(searchTerm.toLowerCase()) ||
            member.business_group_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const resendInvitation = (invite_id) => {
        if (hasPermissions('Team', ['admin'])) {
            const member = team?.find((member) => member.invite_id === invite_id);
            const index = team?.indexOf(member);
            setLoadIndex(index);
            setResendUpdated(!resendUpdated);
            const resendInviteData = {
                first_name: member?.first_name,
                last_name: member?.last_name,
                email: member?.email,
                phone_number: member?.phone_number,
                business_group_id: member?.business_group_id
            };

            dispatch(setSideLoader(true));
            dispatch(sendEmployeeInviteThunk(resendInviteData))
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setSideLoader(false));
                });
        } else {
            toastHandler('You dont have permission to perform this action', ERROR_TYPE_ERROR);
        }
    };

    const handleDelete = () => {
        setIsConfirmationOpen(false);
        const member = team?.find((member) => member.invite_id === invite_id);
        const index = team?.indexOf(member);
        setDeleteIndex(index);

        const revokeData = {
            invite_id
        };
        setTeamUpdated(!teamUpdated);

        dispatch(setSideLoader(true));
        dispatch(revokeEmployeeInviteThunk(revokeData))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
            });
    };

    const removeInvitation = (invite_id) => {
        if (hasPermissions('Team', ['admin'])) {
            setIsConfirmationOpen(true);
            setInviteId(invite_id);
        } else {
            toastHandler('You dont have permission to perform this action', ERROR_TYPE_ERROR);
        }
    };

    useEffect(() => {
        dispatch(setLoader(true));

        setTimeout(() => {
            dispatch(fetchInvitedEmployeesThunk())
                .then((response) => {
                    if (response.payload) {
                        setTeam(response.payload);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }, 500);
    }, [dispatch, inviteUpdated, teamUpdated]);

    return (
        <div>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                onClose={() => setIsConfirmationOpen(false)}
                onConfirm={handleDelete}
            />
            <div className="md:px-10 mt-8 px-5 relative">
                {isLoading && <Loader />}

                <div className="">
                    <div className="px-4 sm:px-6 rounded-lg bg-white lg:px-8">
                        <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                                >
                                                    Employee
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Contact No
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Role Group
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                >
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {team &&
                                                filteredTeam?.map((person, index) => (
                                                    <tr key={person?.email}>
                                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-md sm:pl-0">
                                                            <div className="flex items-center">
                                                                <div className="">
                                                                    <div className="font-medium text-gray-900">
                                                                        {person?.first_name} {person?.last_name}
                                                                    </div>
                                                                    <div className="mt-1 text-gray-500">
                                                                        {person?.email}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="text-gray-900">{person?.phone_number}</div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <span className="inline-flex items-center rounded-md bg-[#DCDCDC] px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                                {person?.business_group_name || '--'}
                                                            </span>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                            <div className="flex-1 flex gap-2 items-center">
                                                                <div
                                                                    onClick={() => removeInvitation(person?.invite_id)}
                                                                >
                                                                    {sideLoader &&
                                                                    deleteIndex === index &&
                                                                    teamUpdated ? (
                                                                        <span className="animate-spin-fast">
                                                                            <IncompleteCircle />
                                                                        </span>
                                                                    ) : (
                                                                        <span className="animate-spin">
                                                                            <BlueTimes />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div onClick={() => resendInvitation(person.invite_id)}>
                                                                    {sideLoader &&
                                                                    loadIndex === index &&
                                                                    resendUpdated ? (
                                                                        <div className="animate-spin-fast">
                                                                            {' '}
                                                                            <RefreshIcon />{' '}
                                                                        </div>
                                                                    ) : (
                                                                        <RefreshIcon />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvitedTeam;
