import Slideover from 'components/atoms/SlideOver';
import React from 'react';

export const BaseSlider = ({ open, setOpen, title, children }) => {
    return (
        <Slideover open={open} setOpen={setOpen} title={title}>
            {children}
        </Slideover>
    );
};
