import React from 'react';

export const OrangeEmail = () => {
    return (
        <div>
            <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.1" width="40" height="40" rx="20" fill="#F08201" />
                <path
                    d="M14.6223 15.3622C14.3043 14.9802 13.7368 14.9283 13.3548 15.2462C12.9727 15.5642 12.9208 16.1317 13.2388 16.5137C14.6614 18.2229 15.8057 19.5492 16.8056 20.4412C17.7995 21.3278 18.7987 21.9227 19.9321 21.9007C21.0506 21.8789 22.0505 21.2588 23.056 20.3775C24.073 19.4861 25.253 18.182 26.7227 16.5378C27.054 16.1672 27.0221 15.5982 26.6515 15.267C26.281 14.9357 25.712 14.9676 25.3807 15.3382C23.8881 17.008 22.7868 18.2199 21.8696 19.0238C20.9408 19.8378 20.3533 20.0921 19.8971 20.101C19.456 20.1096 18.8933 19.8914 18.0039 19.098C17.1205 18.31 16.06 17.0896 14.6223 15.3622Z"
                    fill="#F08201"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8828 12C10.905 12 10 12.7526 10 13.8147V26.1626C10 27.2246 10.905 27.9772 11.8828 27.9772H28.099C29.0769 27.9772 29.9818 27.2246 29.9818 26.1626V13.8147C29.9818 12.7526 29.0769 12 28.099 12H11.8828ZM11.8 26.1495V13.8277L11.801 13.8267C11.8125 13.816 11.8393 13.8 11.8828 13.8H28.099C28.1426 13.8 28.1693 13.816 28.1808 13.8267L28.1818 13.8277V26.1495L28.1808 26.1505C28.1693 26.1612 28.1426 26.1772 28.099 26.1772H11.8828C11.8393 26.1772 11.8125 26.1612 11.801 26.1505L11.8 26.1495Z"
                    fill="#F08201"
                />
                <path
                    d="M14.6223 15.3622C14.3043 14.9802 13.7368 14.9283 13.3548 15.2462C12.9727 15.5642 12.9208 16.1317 13.2388 16.5137C14.6614 18.2229 15.8057 19.5492 16.8056 20.4412C17.7995 21.3278 18.7987 21.9227 19.9321 21.9007C21.0506 21.8789 22.0505 21.2588 23.056 20.3775C24.073 19.4861 25.253 18.182 26.7227 16.5378C27.054 16.1672 27.0221 15.5982 26.6515 15.267C26.281 14.9357 25.712 14.9676 25.3807 15.3382C23.8881 17.008 22.7868 18.2199 21.8696 19.0238C20.9408 19.8378 20.3533 20.0921 19.8971 20.101C19.456 20.1096 18.8933 19.8914 18.0039 19.098C17.1205 18.31 16.06 17.0896 14.6223 15.3622Z"
                    stroke="#F08201"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8828 12C10.905 12 10 12.7526 10 13.8147V26.1626C10 27.2246 10.905 27.9772 11.8828 27.9772H28.099C29.0769 27.9772 29.9818 27.2246 29.9818 26.1626V13.8147C29.9818 12.7526 29.0769 12 28.099 12H11.8828ZM11.8 26.1495V13.8277L11.801 13.8267C11.8125 13.816 11.8393 13.8 11.8828 13.8H28.099C28.1426 13.8 28.1693 13.816 28.1808 13.8267L28.1818 13.8277V26.1495L28.1808 26.1505C28.1693 26.1612 28.1426 26.1772 28.099 26.1772H11.8828C11.8393 26.1772 11.8125 26.1612 11.801 26.1505L11.8 26.1495Z"
                    stroke="#F08201"
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
