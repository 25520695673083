import React, { useEffect, useState } from 'react';
import TelephonicDirectoriesPage from '../../components/workplace-content/telephonic-directories/TelephonicDirectoriesPage';
import AddDirectory from '../../components/workplace-content/telephonic-directories/AddDirectory';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';
import { NoPermission } from 'components/molecules/NoPermission';
import { EditDirectory } from 'components/workplace-content/telephonic-directories/EditDirectory';
import { SidebarLayout } from 'layouts/SidebarLayout';
const TelephonicDirectories = () => {
    const [showAddDirectory, setShowAddDirectory] = useState(false);
    const [directoryUpdated, setDirectoryUpdated] = useState(false);
    const [showEditDirectory, setShowEditDirectory] = useState(false);

    const { hasPermissions } = useRoleGuard();

    const [allowAction, setAllowAction] = useState(false);

    const toggleEditDirectory = () => {
        setShowEditDirectory(!showEditDirectory);
    };
    function toggleAddDirectory() {
        setShowAddDirectory(!showAddDirectory);
    }
    const toggleDirectory = () => {
        setDirectoryUpdated(!directoryUpdated);
    };

    useEffect(() => {
        if (hasPermissions('Telephonic_directories', ['write', 'admin'])) {
            setAllowAction(true);
        } else {
            setAllowAction(false);
        }
    }, []);

    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                {allowAction && showAddDirectory ? (
                    <AddDirectory
                        open={showAddDirectory}
                        setOpen={setShowAddDirectory}
                        toggleAddDirectory={toggleAddDirectory}
                        toggleDirectory={toggleDirectory}
                    />
                ) : allowAction && showEditDirectory ? (
                    <EditDirectory
                        open={showEditDirectory}
                        setOpen={setShowEditDirectory}
                        toggleDirectory={toggleDirectory}
                    />
                ) : (
                    <NoPermission
                        slider={{
                            open: showAddDirectory,
                            setOpen: setShowAddDirectory,
                            title: 'Add Telephonic Directory'
                        }}
                        classes="p-10"
                    />
                )}
                <RoleBasedGuard
                    module={'Telephonic_directories'}
                    permissions={['read', 'write', 'admin']}
                    sx="h-[50vh]"
                >
                    {' '}
                    <div>
                        <TelephonicDirectoriesPage
                            showAddDirectory={showAddDirectory}
                            toggleAddDirectory={toggleAddDirectory}
                            directoryUpdated={directoryUpdated}
                            toggleEditDirectory={toggleEditDirectory}
                        />
                    </div>{' '}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};

export default TelephonicDirectories;
