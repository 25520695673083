import { endpoint, methods } from 'apiEndpoints';
import generateThunkAndSlice from 'store/thunk/thunk';

const { slice: documentDetailToSign, request: documentDetailToSignThunk } = generateThunkAndSlice(
    'documentDetailToSign',
    endpoint.documentDetailToSign,
    methods.POST
);
const { slice: submitSignedDocument, request: submitSignedDocumentThunk } = generateThunkAndSlice(
    'submitSignedDocument',
    endpoint.submitSignedSocument,
    methods.POST
);
const { slice: uploadFile, request: uploadFileThunk } = generateThunkAndSlice(
    'uploadFile',
    endpoint.uploadFile,
    methods.POST
);
const { slice: getFiles, request: getFilesThunk } = generateThunkAndSlice('getFiles', endpoint.getFiles, methods.POST);
const { slice: requestToSignDocument, request: requestToSignDocumentThunk } = generateThunkAndSlice(
    'requestToSignDocument',
    endpoint.requestToSignDocument,
    methods.POST
);

const { request: deleteFileThunk } = generateThunkAndSlice('deleteFileThunk', endpoint.deleteFile, methods.POST);

const { slice: fileManagerInfo, request: fileManagerInfoThunk } = generateThunkAndSlice(
    'fileManagerInfo',
    endpoint.fileManagerInfo,
    methods.POST
);

const { request: filesRevokeThunk } = generateThunkAndSlice('filesRevoke', endpoint.filesRevoke, methods.POST);

const { slice: fileManagerRecentActivity, request: fileManagerRecentActivityThunk } = generateThunkAndSlice(
    'filesRevoke',
    endpoint.fileManagerRecentActivity,
    methods.POST
);

const { request: saveSignatureThunk } = generateThunkAndSlice('saveSignature', endpoint.saveSignature, methods.POST);
const { slice: getSignatures, request: getSignaturesThunk } = generateThunkAndSlice(
    'saveSignature',
    endpoint.getSignatures,
    methods.POST
);
const { request: deleteSignatureThunk } = generateThunkAndSlice(
    'saveSignature',
    endpoint.deleteSignature,
    methods.POST
);

export {
    documentDetailToSignThunk,
    documentDetailToSign,
    submitSignedDocument,
    submitSignedDocumentThunk,
    uploadFile,
    uploadFileThunk,
    getFiles,
    getFilesThunk,
    requestToSignDocumentThunk,
    requestToSignDocument,
    deleteFileThunk,
    fileManagerInfoThunk,
    fileManagerInfo,
    filesRevokeThunk,
    fileManagerRecentActivity,
    fileManagerRecentActivityThunk,
    saveSignatureThunk,
    getSignatures,
    getSignaturesThunk,
    deleteSignatureThunk
};
