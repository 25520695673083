import moment from 'moment';
import 'global-components/css/createtask.css';
import React, { useState, useEffect } from 'react';

import { BgTimes } from 'utills/svgs/BgTimes';
import { LinkIcon } from 'utills/svgs/LinkIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
    archiveTaskFileThunk,
    editTaskThunk,
    getAllJobsStatusesThunk,
    getAllTaskStatusesThunk,
    getAllTaskTypeThunk,
    getSingleTaskThunk
} from 'store/workspace/workspaceTasks';
import {
    BUSINESS_ID,
    TOAST_TYPE_ERROR,
    TOAST_TYPE_SUCCESS,
    USER_TYPE,
    acceptFilesFormats,
    acceptFormate,
    taskPriorityOptions
} from 'utills/globalVars';
import { setLoader, setSideLoader } from 'store/global/globalReducer';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { fetchRegisteredEmployeesForTaskThunk } from 'store/settings/team/team';
import { fetchAllCustomersForTaskThunk, removeTaskChecklistItemThunk, removeTaskChecklistThunk } from 'store/client';
import { toastHandler } from 'responseHanlder';
import { BgDeleteIcon } from 'utills/svgs/BgDeleteIcon';
import { ZoomImage } from 'global-components/ImageZoom/ImageZoomer';
import Slideover from 'components/atoms/SlideOver';
import { Button } from 'components/atoms/buttons/Button';
import { DatePick } from 'components/atoms/inputs/DatePick';
import { CalendarSmallIco } from 'utills/svgs/CalendarSmallIco';
import ComboboxComponent from 'components/atoms/Combobox';
import PreSelectedCheckList from 'global-components/PreSelectedCheckList';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import Input from 'components/atoms/Input';
import { cn } from 'utills/tailwindUtil';
import { Loader } from 'components/common/Loader';
import SimpleCombobox from 'components/atoms/SimpleCombobox';
import { DragFile } from 'components/atoms/inputs/DragFile';
import DefaultTextArea from 'components/atoms/inputs/DefaultTextArea';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import GlobalPDFViewer from 'components/molecules/GlobalPDFViewer';
import SimpleSelectMenu from 'components/atoms/SimpleSelectMenu';
import { Chip } from 'components/atoms/buttons/Chip';
import ComboboxComponentSSR from 'components/atoms/ComboboxSSR';
import { createNewPDFWithoutMetadata } from 'utills/removeMetadata';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import { FileCardPreview } from 'components/atoms/FileCardPreview/FileCardPreview';
import { UserChip } from 'components/atoms/UserChip/UserChip';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import { getAllJobsThunk, getAllMilestonesThunk } from 'store/JobManager';
import MilestoneForm from 'components/job-manager-components/job-manager-detail-components/MilestonesAndTasks/MilestoneForm';
import { PrimaryParagraph } from 'components/atoms/typography/paragraphs/PrimaryParagraph';
import SearchableComboboxSSR from 'components/molecules/comboboxes/SearchableComboboxSSR';
import { CheckIcon } from '@heroicons/react/20/solid';
import { JOB_CANCELLED, JOB_CLOSED, JOB_COMPLETED } from 'pages/settings/job-manager/jobs.constants';
import DraftEditorNew from 'components/molecules/DraftEditorNew';

dayjs.extend(utc);
dayjs.extend(timezone);

const EditTaskDetails = ({ open, setOpen, handleTasksUpdate, lead_id, handleResetAndGet }) => {
    const dispatch = useDispatch();

    const { task: taskItem } = useSelector((state) => state.global);

    const [errors, setErrors] = useState({});

    const business_id = localStorage.getItem(BUSINESS_ID);

    const [editorContent, setEditorContent] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [checklists, setChecklists] = useState([]);
    const [listname, setListName] = useState('');
    const [showAddItem, setShowAddItem] = useState(false);
    const [addItemIndex, setAddItemIndex] = useState(null);
    const [itemName, setItemName] = useState('');
    const [delIconIndex, setDelIconIndex] = useState(null);
    const [showDelIcon, setShowDelIcon] = useState(false);
    const [listIndex, setListIndex] = useState(null);

    const { sideLoader } = useSelector((state) => state.global);
    const { data: employees } = useSelector((state) => state.fetchRegisteredEmployeesForTask);

    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [title, setTitle] = useState('');
    const [personal_notes, setPersonal_notes] = useState('');
    const [preBuildCheckListView, setPreBuildCheckListView] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(dayjs().add(2, 'day'));

    const [reference_number, setReferenceNumber] = useState('');

    const [start_time, setStartTime] = useState(null);
    const [end_time, setEndTime] = useState(null);

    const [files, setFiles] = useState([]);

    const { data: taskTypes } = useSelector((state) => state.getAllTaskType);
    const { data: taskStatuses } = useSelector((state) => state.getAllTaskStatuses);
    const { isLoading } = useSelector((state) => state.global);

    const [taskType, setTaskType] = useState('');
    const [taskPriority, setTaskPriority] = useState(taskPriorityOptions[2].label);
    const [taskStatus, setTaskStatus] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [createNewMilestone, setCreateNewMilestone] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState('');

    const { data: jobStatuses } = useSelector((state) => state.getAllJobsStatuses);

    useEffect(() => {
        if (!jobStatuses) {
            fetchJobStatuses();
        }
    }, []);

    const fetchJobStatuses = () => {
        dispatch(setLoader(true));
        dispatch(getAllJobsStatusesThunk({ business_id })).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const onRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);
    };

    const handlePreviewFile = (file) => {
        if (file instanceof File || file instanceof Blob) {
            const url = URL.createObjectURL(file);

            if (file.type.includes('jpg') || file.type.includes('png') || file.type.includes('jpeg')) {
                setSelectedImage(url);
            } else if (file.type.includes('application/pdf')) {
                setSelectedPdf(url);
            }
        } else {
            console.error('Invalid file object passed to handlePreviewFile.');
        }
    };

    useEffect(() => {
        if (open) {
            getSingleTask();
        }
    }, [open]);

    useEffect(() => {
        const business_id = localStorage.getItem(BUSINESS_ID);
        const payload = {
            business_id,
            page: 1,
            pageSize: 1000,
            sort_by: 'ASC'
        };
        getTypes(payload);
    }, []);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const getSingleTask = () => {
        const payload = {
            task_id: taskItem.task_id,
            business_id
        };
        dispatch(setLoader(true));
        dispatch(getSingleTaskThunk(payload))
            .then((response) => {
                if (response.payload) {
                    const res = response.payload;

                    setStartDate(res?.start_date ? dayjs(res.start_date, 'DD-MM-YYYY') : null);
                    setEndDate(res?.end_date ? dayjs(res.end_date, 'DD-MM-YYYY') : null);

                    setStartTime(res?.start_time ? dayjs(res?.start_time, 'HH:mm:ss Z') : null);
                    setEndTime(res?.end_time ? dayjs(res?.end_time, 'HH:mm:ss Z') : null);

                    if (res?.job) {
                        setSelectedJob(res?.job);
                    }
                    if (res?.milestone) {
                        setSelectedMilestone(res?.milestone);
                    }

                    if (res?.milestone) {
                        setSelectedMilestone(res?.milestone);
                    }

                    setTitle(res?.title);
                    setEditorContent(res?.description ? res?.description : ' ');
                    setForceUpdate(true);
                    setTaskPriority(res?.priority);

                    setTaskStatus(res?.task_status_id);
                    setTaskType(res?.task_type_id);

                    const TaskChecklists = res?.checklists || [];

                    setChecklists(
                        TaskChecklists.map((checkListObj) => {
                            return {
                                title: checkListObj?.title,
                                task_checklist_id: checkListObj?.task_checklist_id,
                                duration_label: checkListObj.duration_label,
                                duration_value: checkListObj.duration_value,
                                checks: checkListObj?.checkListItems.map((item) => {
                                    return {
                                        is_completed: item?.is_completed,

                                        task_checklist_item_id: item?.task_checklist_item_id,
                                        value: item?.value,
                                        sequence: item?.sequence
                                    };
                                })
                            };
                        })
                    );

                    setUploadedFiles(res?.attachments || []);
                    setPersonal_notes(res?.personal_note || '');
                    setSelectedClients(res?.customer ? [res?.customer] : []);
                    setSelectedEmployees(res?.employees || []);
                    setTimeout(() => {
                        setReferenceNumber(res?.reference_number || '');
                    }, 1000);
                }
            })

            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const fetchCustomersList = async (payload) => {
        return await dispatch(fetchAllCustomersForTaskThunk(payload));
    };

    const handleChecklistName = (index) => {
        const newArr = [...checklists];
        newArr[index].title = listname;
        setChecklists(newArr);
        setListName('');
    };

    const addItem = (index) => {
        setAddItemIndex(index);
        setShowAddItem(!showAddItem);
    };

    const handleItemName = (index) => {
        if (itemName !== '') {
            const newArr = [...checklists];
            newArr[index].checks.push({
                value: itemName,
                is_completed: false,
                sequence: (newArr[index].checks?.length + 1).toString() || '1'
            });
            setChecklists(newArr);
            setShowAddItem(false);
            setItemName('');
        }
    };

    const deleteItem = (index, checkIndex, taskCheckList_id, id) => {
        const newArr = [...checklists];
        newArr[index].checks.splice(checkIndex, 1);
        setChecklists(newArr);
        if (taskCheckList_id && id) {
            dispatch(
                removeTaskChecklistItemThunk({
                    task_checklist_item_id: id,
                    task_checklist_id: taskCheckList_id
                })
            );
        }
    };

    const handleDelIcon = (index, checkIndex) => {
        setListIndex(index);
        setDelIconIndex(checkIndex);
        setShowDelIcon(true);
    };
    const hideDelIcon = () => {
        setShowDelIcon(false);
    };

    const handleShowListDel = (index) => {
        setListIndex(index);
    };

    const handleHideListDel = () => {};

    const handleCheckChange = (e, index, checkIndex) => {
        const checked = e.target.checked;
        const newArr = [...checklists];
        if (newArr[index].checks[checkIndex]) {
            newArr[index].checks[checkIndex].is_completed = checked;
        }
        setChecklists(newArr);
    };

    const handleListDelete = (index, id) => {
        const newArr = [...checklists];
        newArr.splice(index, 1);
        setChecklists(newArr);
        updateEndDateTime(newArr);
        if (id) {
            dispatch(removeTaskChecklistThunk({ task_checklist_id: id }));
        }
    };

    const fetchEmployees = () => {
        dispatch(fetchRegisteredEmployeesForTaskThunk()).catch((error) => {
            console.error(error);
        });
    };

    const getStatuses = (payload) => {
        dispatch(getAllTaskStatusesThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                fetchEmployees();
            });
    };

    const getTypes = (payload) => {
        dispatch(getAllTaskTypeThunk(payload))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                getStatuses(payload);
            });
    };

    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const handleSelectChange = (customerArray) => {
        const customerIds = new Set(customerArray.map((e) => e.user_id));

        if (selectedEmployees?.some((i) => customerIds?.has(i.user_id))) {
            return toastHandler('Duplicate employees found!', TOAST_TYPE_ERROR);
        }

        setSelectedEmployees([...selectedEmployees, ...customerArray]);

        // const newArray = [...selectedEmployees, ...customerArray].map((employee) => ({
        //     label: employee?.first_name,
        //     value: employee?.user_id
        // }));
        // setSelectedOptions(newArray);
    };

    const handleRemoveEmp = (index) => {
        const newEmps = [...selectedEmployees];
        // const newOps = [...selectedOptions];

        newEmps.splice(index, 1);
        // newOps.splice(index, 1);

        setSelectedEmployees(newEmps);
        // setSelectedOptions(newOps);
    };
    const validateDateTime = () => {
        if (!start_time) {
            toastHandler('Start time is required', TOAST_TYPE_ERROR);
            return false;
        }
        if (!end_time) {
            toastHandler('End time is required', TOAST_TYPE_ERROR);
            return false;
        }

        let errorsObj = {};
        if (!title) {
            errorsObj.title = true;
        }

        if (!editorContent) {
            errorsObj.editorContent = true;
        }

        setErrors(errorsObj);
        if (Object.entries(errorsObj).length > 0) {
            return false;
        } else {
            return true;
        }
    };
    const [selectedClients, setSelectedClients] = useState([]);
    const handleCustomer = (customerObj) => {
        setSelectedClients([customerObj]);

        setReferenceNumber(customerObj?.client_ref_no || '');
        setSelectedCustomer({
            label: customerObj?.first_name,
            value: customerObj?.business_client_id
        });
    };

    const defAddTask = async () => {
        const valid = validateDateTime();
        if (!valid) {
            return;
        }

        if (selectedJob && !selectedMilestone) {
            return toastHandler('You need to select a milestone if job is selected', TOAST_TYPE_ERROR);
        }

        const formData = new FormData();
        formData.append('business_id', business_id?.trim());
        formData.append('task_type_id', taskType?.trim());
        formData.append('title', title?.trim());
        formData.append('description', editorContent?.trim());
        formData.append('personal_note', personal_notes?.trim());
        formData.append('priority', taskPriority);

        const formatedStartDate = dayjs(startDate).format('DD-MM-YYYY');
        const formatedEndDate = dayjs(endDate).format('DD-MM-YYYY');
        formData.append('start_date', formatedStartDate?.toString());
        formData.append('end_date', formatedEndDate?.toString());

        const start_time1 = dayjs.isDayjs(start_time) ? start_time.toDate() : start_time;
        const end_time1 = dayjs.isDayjs(end_time) ? end_time.toDate() : end_time;

        const formatStartTime = moment(start_time1).format('HH:mm:ss Z');
        const formatEndTime = moment(end_time1).format('HH:mm:ss Z');

        formData.append('start_time', formatStartTime);
        formData.append('end_time', formatEndTime);

        formData.append('task_status_id', taskStatus);
        formData.append('reference_number', reference_number?.trim() || '');
        formData.append('business_client_id', selectedCustomer?.value?.trim() || '');
        formData.append('checklists', JSON.stringify(checklists));
        formData.append('employees', JSON.stringify(selectedEmployees?.map((item) => item?.user_id)));

        formData.append('task_id', taskItem?.task_id);

        if (lead_id) {
            formData.append('lead_id', lead_id);
        }

        formData.append('job_id', selectedJob?.job_id || null);
        formData.append('milestone_id', selectedMilestone?.milestone_id || null);

        formData.append('uploaded_by', USER_TYPE);

        const totalFiles = [...selectedFiles];

        if (totalFiles) {
            const sanitizedFilesPromises = totalFiles.map((file) => createNewPDFWithoutMetadata(file));
            const sanitizedFiles = await Promise.all(sanitizedFilesPromises);
            sanitizedFiles.forEach((sanitizedFile) => {
                formData.append('task_files', sanitizedFile);
            });
        }

        dispatch(setLoader(true));
        dispatch(setSideLoader(true));
        dispatch(editTaskThunk(formData))
            .then((response) => {
                if (response.payload) {
                    if (handleTasksUpdate) {
                        handleTasksUpdate();
                    }
                    if (lead_id) {
                        handleResetAndGet();
                    }

                    setOpen(false);
                    toastHandler('Task Updated', TOAST_TYPE_SUCCESS);
                    resetFormdata();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setSideLoader(false));
                dispatch(setLoader(false));
            });
    };

    const removeTaskFile = (task_files_id) => {
        const payload = {
            task_id: taskItem?.task_id,
            task_files_id
        };

        dispatch(archiveTaskFileThunk(payload)).then((response) => {
            if (response?.payload) {
                handleTasksUpdate();
                getSingleTask();
            }
        });
    };

    const resetFormdata = () => {
        setErrors({});
        setEditorContent(' ');
        setForceUpdate(true);
        setChecklists([]);
        setListName('');
        setShowAddItem(false);

        setAddItemIndex(null);
        setItemName('');
        setDelIconIndex(null);
        setShowDelIcon(false);
        setListIndex(null);

        setSelectedCustomer(null);

        setTitle('');
        setPersonal_notes('');

        setStartDate(null);
        setEndDate(dayjs().add(2, 'day'));
        setReferenceNumber('');
        setStartTime(new Date());
        setEndTime(new Date());

        setFiles([]);

        setTaskType('');
        setTaskStatus('');
    };

    const handleFileRemove = (index) => {
        const newArr = [...files];
        newArr.splice(index, 1);
        setFiles(newArr);
    };

    const items = [];
    if (files) {
        for (let i = 0; i < files.length - 1; i++) {
            items.push(
                <button className="flex items-center gap-1 selected-file ">
                    <LinkIcon />
                    <span>{files[i]?.name?.slice(0, 10)}</span>
                    <span onClick={() => handleFileRemove(files[i])} className="cursor-pointer">
                        <BgTimes />
                    </span>{' '}
                </button>
            );
        }
    }

    const handleSelectedPrebuildList = (prebuiltList) => {
        const newArr = [...checklists];
        prebuiltList.map((obj) => {
            newArr.push({
                title: obj.label,
                duration_label: obj.duration_label,
                duration_value: obj.duration_value,
                checks: obj?.items?.map((item) => ({
                    item_id: item?.pre_checklist_item_id,
                    value: item?.label,
                    sequence: item?.sequence,
                    is_completed: false
                }))
            });
        });

        setChecklists(newArr);
        setPreBuildCheckListView(false);
        setShowAddItem(false);
        setItemName('');

        updateEndDateTime(newArr);
    };

    const updateEndDateTime = (newArr) => {
        if (newArr.length > 0 && start_time) {
            let totalRequiredMinutes = 0;
            newArr?.forEach((obj) => {
                totalRequiredMinutes += Number(obj?.duration_value || 0);
            });

            const start_time1 = dayjs?.isDayjs(start_time) ? start_time?.toDate() : start_time;

            const startDateTime = dayjs(startDate)
                .hour(start_time1?.getHours())
                .minute(start_time1?.getMinutes())
                .second(start_time1?.getSeconds());
            const newEndDateTime = startDateTime?.add(totalRequiredMinutes, 'minute');

            setEndDate(newEndDateTime);
            setEndTime(newEndDateTime?.toDate());
        } else {
            setEndDate(dayjs()?.add(2, 'day'));
            setEndTime(new Date());
        }
    };

    const fetchJobs = async (payload) => {
        let searchValue = payload?.search_term;
        if (payload?.search_term?.includes('|')) {
            searchValue = searchValue?.split('|', 1);
        }

        const payloadNew = {
            ...payload,
            search_term: searchValue,
            page_size: PAGINATION_PAGE_SIZE,
            business_id: business_id,
            job_category: 'all_job',
            status: jobStatuses
                ?.filter((obj) => ![JOB_CLOSED, JOB_CANCELLED, JOB_COMPLETED].includes(obj?.label))
                ?.map((obj) => obj.job_status_id)
        };
        return await dispatch(getAllJobsThunk(payloadNew));
    };
    const fetchMilestones = async (payload) => {
        let searchValue = payload?.search_term;
        if (payload?.search_term?.includes('|')) {
            searchValue = searchValue?.split('|', 1);
        }
        const payloadNew = {
            ...payload,
            business_id: business_id,
            job_id: selectedJob?.job_id,
            search_term: searchValue,
            page_size: PAGINATION_PAGE_SIZE
        };
        return await dispatch(getAllMilestonesThunk(payloadNew));
    };

    return (
        <>
            <Slideover
                open={open}
                setOpen={setOpen}
                size={'max-w-7xl'}
                footer={
                    <div className="">
                        <div className="flex items-center gap-3 justify-center mt-9 mb-4">
                            <Button
                                iconSet={{
                                    rightIcon: sideLoader ? SmallLoaderWhite : null
                                }}
                                title={`Update Task`}
                                atClick={defAddTask}
                                classes="primaryButton flex gap-3 bg-leaner child:text-semibold text-white py-3 px-7"
                            />
                        </div>
                    </div>
                }
            >
                {selectedJob && createNewMilestone && (
                    <Slideover
                        open={createNewMilestone}
                        title={'Create Milestone'}
                        hideBackArrow
                        titlesx={'text-3xl font-fira font-[500] !m-0'}
                        flexSx="!items-center"
                        setOpen={(e) => {
                            setCreateNewMilestone(e);
                            setSelectedMilestone(null);
                        }}
                        size={'max-w-2xl'}
                    >
                        <div className="mt-8">
                            <MilestoneForm
                                milestone={{ sequence: 0 }}
                                index={0}
                                job_id={selectedJob?.job_id || ''}
                                onSubmit={(dataObj) => {
                                    setSelectedMilestone(dataObj);
                                    setCreateNewMilestone(false);
                                }}
                                onCancel={(_e) => {
                                    setCreateNewMilestone(false);
                                    setSelectedMilestone(null);
                                }}
                                sx="w-full"
                            />
                        </div>
                    </Slideover>
                )}
                {selectedImage && <ZoomImage src={selectedImage} alt="" onClose={() => setSelectedImage('')} />}
                {selectedPdf ? (
                    <GlobalPDFViewer
                        url={selectedPdf}
                        setOpen={() => setSelectedPdf('')}
                        open={selectedPdf ? true : false}
                    />
                ) : (
                    ''
                )}

                {isLoading && <Loader />}

                <div className="add-detail px-5 text-black">
                    <div className="text-3xl font-fira font-semibold">Edit Task</div>
                </div>
                <div className="add-task-container1  px-5 mt-3 text-black">
                    <div className=" add-ann-form">
                        <DefaultLightBgCard title="Checklist">
                            <div className="add-task-checklist ">
                                <div>
                                    <div className="flex justify-between items-center">
                                        <div className="text-xl font-semibold"></div>
                                        <div className="flex items-center gap-2">
                                            <button
                                                className="bg-leaner rounded-lg h-[3rem] font-poppins font-[400] px-5 py-2 mt-2"
                                                onClick={() => setPreBuildCheckListView(true)}
                                            >
                                                Add Prebuilt Item
                                            </button>
                                        </div>
                                    </div>
                                    <div className=" mt-1">
                                        {checklists &&
                                            checklists?.map((list, index) => (
                                                <div key={index} className={`${index > 0 && ' mt-2'} border-b`}>
                                                    {!list.title ? (
                                                        <div>
                                                            <div className="flex items-center justify-between">
                                                                <label>Checklist Name</label>
                                                            </div>
                                                            <div className="flex gap-2 items-center">
                                                                {' '}
                                                                <input
                                                                    className="!rounded-xl !pl-4"
                                                                    onChange={(e) => setListName(e.target.value)}
                                                                    value={listname}
                                                                    placeholder="Title"
                                                                    type="text"
                                                                />{' '}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="flex items-center justify-between w-full md:w-1/2">
                                                                <label className="inputLabel"> Checklist Title</label>{' '}
                                                                <Chip
                                                                    title={list?.duration_label}
                                                                    classes={'bg-gray-200 px-3'}
                                                                />
                                                            </div>

                                                            <div
                                                                onMouseEnter={() => handleShowListDel(index)}
                                                                onMouseLeave={() => handleHideListDel()}
                                                                className="flex items-center justify-between gap-2 mt-3 w-full md:w-1/2 !break-all"
                                                            >
                                                                <div className=" h-[3rem] w-[90%] p-3 border border-secondarybg rounded-xl flex items-center !pl-2 !break-all">
                                                                    {list.title}{' '}
                                                                </div>
                                                                <div
                                                                    onClick={() =>
                                                                        handleListDelete(index, list?.task_checklist_id)
                                                                    }
                                                                    className="cursor-pointer"
                                                                >
                                                                    <BgDeleteIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div>
                                                        <div className="mt-5 w-full md:w-1/2">
                                                            {list?.checks?.length > 0 && (
                                                                <div className="mt-5">
                                                                    {list?.checks
                                                                        .sort((a, b) => {
                                                                            if (
                                                                                a?.sequence == null &&
                                                                                b?.sequence == null
                                                                            )
                                                                                return 0;
                                                                            if (a?.sequence == null) return 1;
                                                                            if (b?.sequence == null) return -1;
                                                                            return (
                                                                                Number(a.sequence) - Number(b.sequence)
                                                                            );
                                                                        })
                                                                        .map((check, checkIndex) => (
                                                                            <div
                                                                                key={checkIndex}
                                                                                className="contents p-0 py-2"
                                                                                onMouseEnter={() =>
                                                                                    handleDelIcon(index, checkIndex)
                                                                                }
                                                                                onMouseLeave={hideDelIcon}
                                                                            >
                                                                                <div className="flex justify-between items-center my-2">
                                                                                    {' '}
                                                                                    <div className="flex items-center gap-3">
                                                                                        <div>
                                                                                            {' '}
                                                                                            <input
                                                                                                onChange={(e) =>
                                                                                                    handleCheckChange(
                                                                                                        e,
                                                                                                        index,
                                                                                                        checkIndex
                                                                                                    )
                                                                                                }
                                                                                                type="checkbox"
                                                                                                className="!w-2 !h-2 !rounded-md border-secondarybg  focus:ring-gray-800"
                                                                                            />{' '}
                                                                                        </div>
                                                                                        <div className="primary-fonts1 mt-2 !text-[16px] !break-all">
                                                                                            {check?.value}{' '}
                                                                                        </div>
                                                                                    </div>{' '}
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            deleteItem(
                                                                                                index,
                                                                                                checkIndex,
                                                                                                list?.task_checklist_id,
                                                                                                check?.task_checklist_item_id
                                                                                            )
                                                                                        }
                                                                                        className="cursor-pointer"
                                                                                    >
                                                                                        {' '}
                                                                                        {showDelIcon &&
                                                                                        delIconIndex === checkIndex &&
                                                                                        listIndex === index ? (
                                                                                            <BgDeleteIcon />
                                                                                        ) : (
                                                                                            <BgDeleteIcon />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            )}
                                                        </div>

                                                        {showAddItem && addItemIndex === index ? (
                                                            <div>
                                                                <div className="mt-6 add-ann-form">
                                                                    <div>
                                                                        {' '}
                                                                        <label className="inputLabel">
                                                                            Add an Item
                                                                        </label>{' '}
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={70}
                                                                        onChange={(e) => setItemName(e.target.value)}
                                                                    />
                                                                    <div className="flex items-center gap-3 mt-3">
                                                                        <button
                                                                            onClick={() => handleItemName(index)}
                                                                            className="ann-btn1 bg-leaner px-5 py-2.5 rounded-lg"
                                                                        >
                                                                            Add
                                                                        </button>
                                                                        <button
                                                                            className="checklist-cancel-btn px-5 py-2 rounded-lg"
                                                                            onClick={() => addItem(index)}
                                                                        >
                                                                            Cancel
                                                                        </button>{' '}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="my-5 mt-7 flex gap-3 items-center">
                                                        {showAddItem && addItemIndex === index ? (
                                                            ''
                                                        ) : (
                                                            <div className="flex items-center gap-2">
                                                                {!list.title ? (
                                                                    <button
                                                                        onClick={() => handleChecklistName(index)}
                                                                        className="ann-btn1 bg-leaner rounded-xl px-5 py-2"
                                                                    >
                                                                        Add Checklist
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        disabled={!list?.title ? true : false}
                                                                        className="ann-btn1 bg-leaner rounded-lg h-[3rem] font-poppins font-[400]  px-5 py-2"
                                                                        onClick={() => addItem(index)}
                                                                    >
                                                                        Add Item
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )}

                                                        <hr className="mt-4"></hr>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </DefaultLightBgCard>

                        <DefaultLightBgCard title="Priority">
                            <div className="">
                                <div>
                                    <div className="grid md:grid-cols-2">
                                        <div className="w-full mt-3">
                                            <div>
                                                <div className="">
                                                    <label className="inputLabel">Select Priority</label>
                                                </div>
                                                <div className="mt-1">
                                                    <SimpleSelectMenu
                                                        placeholder="Select"
                                                        targetProperty="label"
                                                        selectedValue={taskPriority}
                                                        valuePropertyName="label"
                                                        optionsData={taskPriorityOptions || []}
                                                        sx="rounded-xl py-3 ring-gray-400"
                                                        onChangeValue={(obj) => setTaskPriority(obj.label)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DefaultLightBgCard>

                        <DefaultLightBgCard
                            title="Select Job And Milestone"
                            htmlFor="createTaskWithJob"
                            sx={cn(taskType === 'createTaskWithJob' && 'ring-2 ring-inset ring-third')}
                        >
                            <PrimaryParagraph sx={'!text-md cursor-pointer text-gray-700 mt-1'}>
                                Create a new job or Attach this task to an existing job by selecting the job and
                                specifying a relevant milestone for organization.
                            </PrimaryParagraph>
                            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5 mb-6 default-forms">
                                <div className="w-full mt-3">
                                    <div>
                                        <div className="">
                                            <label className="inputLabel">Select Job</label>
                                        </div>
                                        {jobStatuses && (
                                            <div className="mt-1">
                                                <SearchableComboboxSSR
                                                    fetchDataFunction={fetchJobs}
                                                    defaultSelected={selectedJob}
                                                    removePreviousSelected
                                                    showCancelBtn
                                                    displayCustomInputValue={(obj) => {
                                                        return obj?.ref_no + ' | ' + obj?.title;
                                                    }}
                                                    displayContent={(obj, active, selected) => {
                                                        return (
                                                            <>
                                                                <span
                                                                    className={cn(
                                                                        'block truncate',
                                                                        selected && 'font-semibold'
                                                                    )}
                                                                >
                                                                    {obj?.ref_no + ' | ' + obj?.title || 'undefined'}
                                                                </span>

                                                                {selected && (
                                                                    <span
                                                                        className={cn(
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                            active ? 'text-white' : 'text-third'
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                )}
                                                            </>
                                                        );
                                                    }}
                                                    placeholder="Select Job"
                                                    inputsx="!font-normal !text-sm !w-full"
                                                    sx="!rounded-xl  !font-normal !ring-gray-400 mt-2 !flex !py-0"
                                                    onChange={(obj) => {
                                                        setSelectedMilestone(null);
                                                        if (obj?.length > 0) {
                                                            setSelectedJob(obj[0]);
                                                        } else {
                                                            setSelectedJob(null);
                                                        }
                                                    }}
                                                    limit={1}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {selectedJob && (
                                    <div className="w-full mt-3">
                                        <div>
                                            <div className="">
                                                <label className="inputLabel">Select Milestone</label>
                                            </div>
                                            <div className="mt-1">
                                                <SearchableComboboxSSR
                                                    fetchDataFunction={fetchMilestones}
                                                    defaultSelected={selectedMilestone}
                                                    removePreviousSelected
                                                    showCancelBtn
                                                    targetResponseProperty="rows"
                                                    displayCustomInputValue={(obj) => {
                                                        return obj?.title;
                                                    }}
                                                    displayContent={(obj, active, selected) => {
                                                        return (
                                                            <>
                                                                <span
                                                                    className={cn(
                                                                        'block truncate',
                                                                        selected && 'font-semibold'
                                                                    )}
                                                                >
                                                                    {obj?.title || 'undefined'}
                                                                </span>

                                                                {selected && (
                                                                    <span
                                                                        className={cn(
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                            active ? 'text-white' : 'text-third'
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                )}
                                                            </>
                                                        );
                                                    }}
                                                    placeholder="Select Milestone"
                                                    inputsx="!font-normal !text-sm !w-full"
                                                    sx="!rounded-xl  !font-normal !ring-gray-400 mt-2 !flex !py-0"
                                                    onChange={(obj) => {
                                                        if (obj?.length > 0) {
                                                            setSelectedMilestone(obj[0]);
                                                        } else {
                                                            setSelectedMilestone(null);
                                                        }
                                                    }}
                                                    limit={1}
                                                />
                                            </div>
                                            {!selectedMilestone && (
                                                <div onClick={() => setCreateNewMilestone(true)}>
                                                    <PrimaryParagraph
                                                        sx={
                                                            '!text-sm italic cursor-pointer text-gray-700 mt-1 underline underline-offset-4'
                                                        }
                                                    >
                                                        Create new Milestone
                                                    </PrimaryParagraph>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </DefaultLightBgCard>

                        <DefaultLightBgCard title="Task Information">
                            <div className="">
                                <div className="mt-1">
                                    <div className="">
                                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
                                            <div className="w-full">
                                                <div>
                                                    <label className="inputLabel mb-2">Start Date</label>
                                                </div>

                                                <div className="mt-1 w-full">
                                                    <DatePick
                                                        iconSet={{
                                                            right: CalendarSmallIco
                                                        }}
                                                        minDate={dayjs().format('YYYY-MM-DD')}
                                                        onDateChange={(date) => {
                                                            setStartDate(date);
                                                        }}
                                                        placeholder="Select Date"
                                                        value={startDate}
                                                        bg="transparent"
                                                        sx="addTaskDate !border-secondarybg !rounded-[12px] text-gray-900 w-full"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div>
                                                    <label className="inputLabel mb-2">End Date</label>
                                                </div>
                                                <div className="mt-1">
                                                    <DatePick
                                                        iconSet={{
                                                            right: CalendarSmallIco
                                                        }}
                                                        minDate={
                                                            startDate
                                                                ? dayjs(startDate).format('YYYY-MM-DD')
                                                                : dayjs().format('YYYY-MM-DD')
                                                        }
                                                        onDateChange={(date) => {
                                                            setEndDate(date);
                                                        }}
                                                        placeholder="Select Date"
                                                        value={endDate || dayjs()}
                                                        bg="transparent"
                                                        sx="addTaskDate !border-secondarybg !rounded-[12px] text-gray-900 w-full"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div>
                                                    <label className="inputLabel mb-2">Start Time</label>
                                                </div>

                                                <div className="mt-1 w-full">
                                                    <TimePicker
                                                        className="focus:ring-0 addTaskDate !border-secondarybg !rounded-[12px] !text-gray-900 min-h-[3rem] font-semibold w-full hover:border-secondarybg"
                                                        needConfirm={false}
                                                        changeOnScroll
                                                        use12Hours
                                                        value={start_time ? dayjs(start_time) : null}
                                                        format="h:mm A"
                                                        onChange={(time) => {
                                                            if (time?.$d) {
                                                                setStartTime(time?.$d);
                                                            }
                                                            setTimeout(() => {
                                                                updateEndDateTime(checklists);
                                                            }, 500);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div>
                                                    <label className="inputLabel mb-2">End Time</label>
                                                </div>

                                                <div className="mt-1 w-full">
                                                    <TimePicker
                                                        className="focus:ring-0 addTaskDate !border-secondarybg !rounded-[12px] !text-gray-900 min-h-[3rem] font-semibold w-full hover:border-secondarybg"
                                                        needConfirm={false}
                                                        changeOnScroll
                                                        use12Hours
                                                        value={end_time ? dayjs(end_time) : null}
                                                        format="h:mm A"
                                                        onChange={(time) => {
                                                            if (time?.$d) {
                                                                setEndTime(time?.$d);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-4 default-forms ">
                                    <div>
                                        <label className="inputLabel">Title</label>
                                    </div>
                                    <div>
                                        <Input
                                            name={'title'}
                                            useWithoutForm
                                            value={title || ''}
                                            onChange={(e) => setTitle(e.target.value)}
                                            placeholder="Title"
                                            maxLength={90}
                                            hasError={errors?.title || false}
                                        />
                                        <div className="text-xs text-right mt-1 mr-1 text-secondarybg">
                                            {title.length}/90
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label className="inputLabel">Description</label>
                                </div>

                                <div className="w-full mt-3">
                                    <DraftEditorNew
                                        onChange={(val) => setEditorContent(val !== '' ? val : ' ')}
                                        initialValue={editorContent}
                                        maxWords={10000}
                                        required={true}
                                        placeholder="Enter description"
                                        forceUpdate={forceUpdate}
                                        setForceUpdate={setForceUpdate}
                                    />
                                </div>
                            </div>
                        </DefaultLightBgCard>

                        <DefaultLightBgCard title={'Attachments'}>
                            <div>
                                <div>
                                    <div className="default-forms">
                                        <label className="inputLabel">Upload Attachments</label>
                                        <div className="mt-2">
                                            <DragFile
                                                selectedFiles={selectedFiles}
                                                atChange={(files) => {
                                                    setSelectedFiles([...files, ...selectedFiles]);
                                                }}
                                                accept={[...acceptFormate, ...acceptFilesFormats]}
                                                isMultiple={true}
                                                hideSelectedFiles
                                                dragMessage="Drag files here to upload image, or browse files"
                                                dropMessage="Drop the selected files in this box... "
                                                message="Only images are allowed"
                                            />
                                        </div>

                                        <div className="mt-5 flex gap-5 items-center flex-wrap">
                                            {selectedFiles?.map((file, index) => (
                                                <FileCardPreview
                                                    url={file?.type}
                                                    key={index}
                                                    showImage={true}
                                                    name={file?.name}
                                                    size={file?.size}
                                                    extension={
                                                        file?.type?.includes('.')
                                                            ? file?.type?.split('.')?.pop()?.toLowerCase()
                                                            : file?.type?.split('/').pop().toLowerCase()
                                                    }
                                                    imgPrperties={{
                                                        url: file,
                                                        type: file?.type,
                                                        sx: ''
                                                    }}
                                                    onClick={() => handlePreviewFile(file)}
                                                    onRemove={() => onRemoveFile(index)}
                                                />
                                            ))}
                                        </div>

                                        <label className="mt-5"> Uploaded Files </label>
                                        <div className="flex gap-5 mt-3 items-center flex-wrap ">
                                            {uploadedFiles?.length > 0 &&
                                                uploadedFiles?.map((file, index) => (
                                                    <FileCardPreview
                                                        url={file?.url}
                                                        key={index}
                                                        name={file?.name}
                                                        size={''}
                                                        extension={
                                                            file?.url?.includes('.')
                                                                ? file?.url?.split('.')?.pop()?.toLowerCase()
                                                                : file?.url.split('/').pop().toLowerCase()
                                                        }
                                                        onClick={() => {
                                                            if (file?.url?.toLowerCase()?.endsWith('.pdf')) {
                                                                setSelectedPdf(file?.url);
                                                            } else if (
                                                                file?.url?.toLowerCase()?.endsWith('.jpg') ||
                                                                file?.url?.toLowerCase()?.endsWith('.jpeg') ||
                                                                file?.url?.toLowerCase()?.endsWith('.png')
                                                            ) {
                                                                setSelectedImage(file?.url);
                                                            }
                                                        }}
                                                        onRemove={() => removeTaskFile(file?.task_files_id)}
                                                    />
                                                ))}
                                        </div>
                                    </div>
                                    <div className="w-full"></div>
                                </div>
                            </div>
                        </DefaultLightBgCard>

                        <DefaultLightBgCard title="Compliance Notes">
                            <div className="w-full mt-3">
                                <div className="flex items-start">
                                    <div className="min-w-0 flex-1">
                                        <form action="#" className="relative">
                                            <div className="overflow-hidden py-3 rounded-xl shadow-sm border !border-secondarybg">
                                                <DefaultTextArea
                                                    placeholder="Write here..."
                                                    rows="1"
                                                    name="comment"
                                                    id="comment"
                                                    value={personal_notes}
                                                    className="block w-full resize-none  h-20 outline-none !ring-0 bg-transparent py-1.5 text-gray-900 placeholder:text-[#979797] placeholder:text-[0.9rem] text-[0.9rem] focus:ring-0 sm:text-sm sm:leading-6 !border-0"
                                                    maxCountsx="pr-2 text-right text-sm !text-[#979797]"
                                                    onChange={(e) => setPersonal_notes(e)}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </DefaultLightBgCard>

                        <DefaultLightBgCard title="Staff Allocation">
                            <div className="add-task-checklist grid grid-cols-2 gap-5 ">
                                <div>
                                    <div className="">
                                        <label className="inputLabel">Task Type</label>
                                    </div>
                                    <div className="mt-1">
                                        {taskItem && (
                                            <SimpleCombobox
                                                hasError={errors?.taskType || false}
                                                placeholder="Select"
                                                targetProperty="label"
                                                valuePropertyName={'task_type_id'}
                                                selectedValue={taskItem?.task_type?.task_type_id || ''}
                                                optionsData={taskTypes?.rows || []}
                                                sx="rounded-xl py-3 ring-0 !border-secondarybg"
                                                onChangeValue={(obj) => setTaskType(obj.task_type_id)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="">
                                    <div className="">
                                        <label className="inputLabel">Task Status</label>
                                    </div>
                                    <div className="mt-1">
                                        <SimpleCombobox
                                            hasError={errors?.taskStatus || false}
                                            optionsData={taskStatuses || []}
                                            placeholder="Select"
                                            targetProperty="label"
                                            valuePropertyName={'task_status_id'}
                                            selectedValue={taskItem?.task_status_id || ''}
                                            sx="rounded-xl py-3 ring-0 !border-secondarybg"
                                            onChangeValue={(obj) => setTaskStatus(obj.task_status_id)}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="">
                                        <label className="inputLabel">Reference Number</label>
                                    </div>
                                    <div className="mt-1">
                                        {' '}
                                        <Input
                                            useWithoutForm
                                            type="text"
                                            value={reference_number}
                                            sx="px-2 placeholder:text-[0.9rem] placeholder:text-[#979797] ring-0 !border-secondarybg"
                                            hasError={errors?.reference_number || false}
                                            placeholder="Reference Number"
                                            onChange={(e) => {
                                                setReferenceNumber(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="hidden md:block"></div>

                                <div className="mt-1">
                                    <label className="inputLabel">Add to client</label>

                                    <ComboboxComponentSSR
                                        fetchDataFunction={fetchCustomersList}
                                        // defaultSelected={
                                        //     selectedCustomer === null && taskItem
                                        //         ? taskItem?.customer?.business_client_id || ''
                                        //         : ''
                                        // }
                                        // selectedValue={selectedCustomer ? '' : null}
                                        onChange={(e) => handleCustomer(e[0])}
                                        targetProperty="business_client_id"
                                        removePreviousSelected
                                        hideSelectedValues
                                        limit={1}
                                        placeholder={'Select customer'}
                                        sx={'!mt-1'}
                                    />

                                    <div className="mt-5 flex gap-3 items-center flex-wrap">
                                        {selectedClients?.length > 0 &&
                                            selectedClients?.map((client, index) => (
                                                <UserChip
                                                    key={'client' + index}
                                                    obj={client}
                                                    onClick={() => {
                                                        setSelectedClients([]);
                                                        setSelectedCustomer({});
                                                        setReferenceNumber('');
                                                    }}
                                                />
                                            ))}
                                    </div>
                                </div>

                                <div className="">
                                    <label className="inputLabel">Assign to Employee</label>
                                    <ComboboxComponent
                                        data={employees}
                                        showTaskDetails
                                        hideSelectedValues
                                        selectedValues={[]}
                                        placeholder={'Select Employees'}
                                        onChange={(e) => handleSelectChange(e)}
                                        targetProperty="user_id"
                                    />

                                    <div className="mt-5  flex gap-3 items-center flex-wrap">
                                        {selectedEmployees?.map((client, index) => (
                                            <UserChip
                                                key={'client' + index}
                                                obj={client}
                                                onClick={() => {
                                                    handleRemoveEmp(index);
                                                    // setSelectedEmployees([]);
                                                    // setSelectedOptions([]);
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </DefaultLightBgCard>
                    </div>
                    <PreSelectedCheckList
                        open={preBuildCheckListView}
                        setOpen={setPreBuildCheckListView}
                        handleSelectedPrebuildList={handleSelectedPrebuildList}
                    />
                </div>
            </Slideover>
        </>
    );
};

export default EditTaskDetails;
