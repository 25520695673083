import React, { useEffect, useState } from 'react';
import Slideover from 'components/atoms/SlideOver';
import { DefaultLightBgCard } from 'components/atoms/DefaultLightBgCard.js/DefaultLightBgCard';
import Input from 'components/atoms/Input';
import { Button } from 'components/atoms/buttons/Button';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { useDispatch, useSelector } from 'react-redux';
import DraftEditorNew from 'components/molecules/DraftEditorNew';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import {
    createUserEmailSignatureThunk,
    getUserEmailSignatureThunk,
    updateUserEmailSignatureThunk
} from 'store/settings/JobPortal';
import { setLoader } from 'store/global/globalReducer';

const CreateUserEmailSignature = ({ open, setOpen, editObj, setEditObj }) => {
    const business_id = localStorage.getItem(BUSINESS_ID);
    const { sideLoader } = useSelector((state) => state.global);
    const [data, setData] = useState(null);
    const dispatch = useDispatch();

    const setDataFun = (target, value) => {
        setData({ ...(data ? data : {}), [target]: value });
    };

    useEffect(() => {
        if (editObj) {
            handleEditDataObj(editObj);
        }
        return () => {
            setEditObj(null);
        };
    }, [editObj]);

    const handleEditDataObj = (editObj) => {
        setData({
            ...editObj,
            editorMessage: editObj?.signature
        });
    };

    const handleSubmit = () => {
        if (!data?.name || data?.name === '') {
            toastHandler('Signature Name is Required', TOAST_TYPE_ERROR);
            return false;
        }
        if (!data?.editorMessage || data?.editorMessage === '') {
            toastHandler('Signature is Required', TOAST_TYPE_ERROR);
            return false;
        }

        if (editObj) {
            handleUpdateData();
        } else {
            const jsonPayload = {
                business_id,
                name: data?.name,
                signature: data?.editorMessage
            };
            dispatch(setLoader(true));
            dispatch(createUserEmailSignatureThunk(jsonPayload))
                .then((res) => {
                    if (res.payload) {
                        toastHandler('Successfully Created', TOAST_TYPE_SUCCESS);
                        setOpen(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    dispatch(getUserEmailSignatureThunk({ business_id }));
                });
        }
    };

    const handleUpdateData = () => {
        if (editObj) {
            const jsonPayload = {
                business_id,
                name: data?.name,
                signature: data?.editorMessage,
                user_signature_id: data?.user_signature_id,
                user_id: data?.user_id,
                is_default: data?.is_default
            };
            dispatch(setLoader(true));
            dispatch(updateUserEmailSignatureThunk(jsonPayload))
                .then((res) => {
                    if (res.payload) {
                        toastHandler('Successfully Updated', TOAST_TYPE_SUCCESS);
                        setOpen(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    dispatch(getUserEmailSignatureThunk({ business_id }));
                });
        }
    };

    return (
        <Slideover
            open={open}
            setOpen={setOpen}
            title={editObj ? 'Edit Signature' : 'Create Signature'}
            size="max-w-7xl"
            hideBackArrow
            titlesx={'!font-medium font-fira'}
            footer={
                <div className="">
                    <div className="flex items-center gap-3 justify-center mt-9 mb-4">
                        <Button
                            title={`Cancel`}
                            atClick={() => setOpen(false)}
                            classes="secondaryButton flex gap-3 bg-transparent ring-inset ring-2 ring-primary !rounded-xl child:text-semibold text-primary py-3 px-12"
                        />
                        <Button
                            iconSet={{
                                rightIcon: sideLoader ? SmallLoaderWhite : null
                            }}
                            title={editObj ? 'update' : `Save`}
                            atClick={() => handleSubmit()}
                            classes="primaryButton flex gap-3 bg-leaner child:text-semibold text-white py-3 px-14"
                        />
                    </div>
                </div>
            }
        >
            <div>
                <DefaultLightBgCard title="Signature">
                    <div className="">
                        <div className="mt-4 default-forms">
                            <div>
                                <label className="inputLabel">Name New Signature</label>
                            </div>
                            <div>
                                <Input
                                    name={'name'}
                                    useWithoutForm
                                    value={data?.name || ''}
                                    hasError={data?.name === ''}
                                    onChange={(e) => setDataFun('name', e.target.value)}
                                    placeholder="Enter Signature Name"
                                    sx="!pl-3 mt-2"
                                    maxLength={90}
                                />
                            </div>
                        </div>

                        <div className="mt-5">
                            <DraftEditorNew
                                initialValue={data?.editorMessage || ''}
                                onChange={(e) => setDataFun('editorMessage', e)}
                                placeholder="Enter Signature"
                                required
                                hasError={data?.editorMessage === ''}
                                sx="" // CSS classes for the outer box
                                forceUpdate={false} // When true, the component should reload
                                setForceUpdate
                            />
                        </div>
                    </div>
                </DefaultLightBgCard>
            </div>
        </Slideover>
    );
};
export default CreateUserEmailSignature;
