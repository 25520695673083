import React from 'react';
import './css/molecules.css';
export const FoldersCard = ({ title, count, iconSet, atClick }) => {
    return (
        <div onClick={atClick} className="folders-card">
            <div className="flex items-center gap-3">
                <div>
                    {' '}
                    <iconSet.icon />{' '}
                </div>
                <div>
                    <div className="percentage-title">{title}</div>
                    <div className="percentage-text"> {count}</div>
                </div>
            </div>
        </div>
    );
};
