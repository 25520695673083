import React from 'react';
import './css/lineChartAndInfo.css';
import { TaskTypesCard } from './TaskTypesCard';
import { TopEmployees } from './TopEmployees';
export const LineChartAndInfo = () => {
    return (
        <div className="md:mx-10 mx-5">
            <div className="lg:grid md:grid-cols-3 gap-5   ">
                <div className="all-emp-wrapper h-[400px] overflow-hidden hover:overflow-y-auto shadow-sm rounded-xl">
                    <TaskTypesCard />
                </div>
                <div className="all-emp-wrapper col-span-2 shadow-sm rounded-xl h-[400px] overflow-hidden hover:overflow-y-auto">
                    <TopEmployees />
                </div>
            </div>
        </div>
    );
};
