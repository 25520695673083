import React from 'react';

export const PasswordLock = () => {
    return (
        <div>
            {' '}
            <svg width="100" height="100" viewBox="0 0 142 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M64.5545 0.445145C64.1443 0.503738 62.6502 0.708817 61.1853 0.884598C51.4881 2.02718 41.0877 5.92366 32.826 11.4608C28.6365 14.2733 26.3221 16.1776 22.3377 20.1619C18.4705 24.0291 16.5955 26.285 13.6658 30.5037L11.8787 33.0819L10.7947 29.9178C10.1795 28.1893 9.41776 26.4022 9.09549 25.9334C8.45096 25.0252 6.51737 23.9998 5.37479 23.9998C4.20291 23.9998 2.29862 25.0252 1.56619 26.0506C0.218539 27.9256 0.365023 28.6287 3.73416 38.1502C5.43338 42.9256 7.04471 47.203 7.30838 47.6131C7.60135 48.0233 8.33377 48.6092 9.0076 48.9608C10.5017 49.7225 11.4099 49.6639 22.2205 47.8768C27.3474 47.0272 30.7459 46.324 31.3025 46.0018C34.2322 44.3026 34.2615 39.8201 31.3904 38.0623C30.1599 37.3006 28.6658 37.3299 23.8318 38.0916C21.7224 38.4432 19.906 38.6483 19.8181 38.5311C19.7303 38.4432 20.1404 37.5936 20.7556 36.6854C29.0174 24.1756 41.6736 15.1522 56.1756 11.4022C61.4783 10.0252 65.2869 9.55647 71.2928 9.55647C87.7576 9.52718 102.582 15.6795 114.213 27.369C122.738 35.9237 128.392 46.4998 130.736 58.2479C131.234 60.7674 131.732 65.1619 132.025 70.0838C132.201 72.6619 133.695 74.3319 136.097 74.5955C137.797 74.8006 139.261 74.1561 140.316 72.7498C141.166 71.6658 141.166 71.578 141.136 67.9744C140.961 51.0115 134.017 34.1658 122.152 21.9783C118.051 17.7889 115.326 15.5037 110.726 12.4569C101.879 6.53889 92.0056 2.701 81.1072 0.972488C78.324 0.533035 66.2537 0.152176 64.5545 0.445145Z"
                    fill="url(#paint0_linear_6_790)"
                />
                <path
                    d="M68.2168 35.1055C63.7051 35.8379 60.3066 37.5957 57.0547 40.8184C52.8652 45.0078 51.4004 48.6113 51.166 55.2617L51.0195 59.3926L47.2988 59.5098C43.0508 59.627 41.9961 60.0078 40.9121 61.9707L40.2383 63.1133V82.2441C40.2383 100.701 40.2676 101.404 40.8242 102.488C41.1465 103.133 41.8789 103.953 42.4941 104.305L43.6074 105.008H71H98.3926L99.5352 104.305C100.238 103.865 100.854 103.133 101.205 102.4C101.703 101.258 101.762 100.086 101.762 82.3027C101.762 64.5195 101.703 63.3477 101.205 62.2051C100.854 61.4727 100.238 60.7402 99.5352 60.3008C98.5098 59.6855 97.9824 59.5977 94.7012 59.5098L90.9805 59.3926L90.8633 55.2617C90.7461 51.6875 90.5996 50.75 89.8965 48.6406C88.168 43.543 84.4766 39.3535 79.7012 37.0684C78.2363 36.3652 76.4199 35.6328 75.6875 35.4863C73.7832 35.0176 69.916 34.8418 68.2168 35.1055ZM74.8379 45.0957C77.123 45.9453 79.2617 47.9375 80.4336 50.2812C81.3125 52.0684 81.3711 52.4492 81.4883 55.8184L81.6055 59.4512H71H60.3945L60.5117 55.8477C60.5996 52.7422 60.7168 52.0684 61.3613 50.6621C63.8223 45.3887 69.4473 43.0742 74.8379 45.0957ZM92.3867 82.3027V95.7793H71H49.6133V82.3027V68.8262H71H92.3867V82.3027Z"
                    fill="url(#paint1_linear_6_790)"
                />
                <path
                    d="M3.46996 65.7222C2.23949 66.2495 1.03832 67.7437 0.803947 69.0327C0.569572 70.3511 0.86254 75.9175 1.41918 79.814C3.20629 92.5874 8.50903 104.863 16.2727 114.091C18.7922 117.08 23.3625 121.591 26.175 123.906C31.5657 128.33 40.5012 133.251 47.4153 135.625C51.6926 137.089 56.6731 138.349 61.0969 139.052C65.7551 139.785 77.1223 139.785 81.7805 139.052C100.531 136.123 116.234 126.337 127.425 110.693C128.538 109.14 129.593 107.617 129.769 107.324C130.032 106.855 130.238 107.207 131.029 109.58C132.552 114.179 134.105 115.996 136.536 115.996C138.792 115.996 141.282 113.593 141.312 111.425C141.312 110.312 135.365 93.3491 134.573 92.2358C133.841 91.269 132.318 90.5073 130.97 90.5073C128.802 90.5073 111.605 93.437 110.726 93.9644C107.767 95.6929 107.708 100.175 110.609 101.933C111.868 102.695 113.275 102.695 118.109 101.904C120.247 101.552 122.064 101.347 122.152 101.435C122.269 101.523 121.712 102.578 120.921 103.72C108.118 123.027 84.7688 133.31 61.7707 129.882C35.5793 125.957 14.9836 105.507 10.8235 79.3745C10.5598 77.8511 10.2668 74.7163 10.1204 72.4019C9.85668 67.9487 9.6809 67.3921 7.95238 66.103C6.98559 65.3706 4.64184 65.1655 3.46996 65.7222Z"
                    fill="url(#paint2_linear_6_790)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_6_790"
                        x1="77.8457"
                        y1="73.7102"
                        x2="77.8163"
                        y2="0.341913"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_6_790"
                        x1="74.0187"
                        y1="104.138"
                        x2="73.959"
                        y2="34.9824"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_6_790"
                        x1="77.9153"
                        y1="138.68"
                        x2="77.886"
                        y2="65.4044"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#101828" />
                        <stop offset="0.998509" stopColor="#0D1B37" />
                        <stop offset="1" stopColor="#0A1E46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
