import React from 'react';
import { DimmedArrowRight } from '../../../../utills/svgs/DimmedArrowRight';
export const SecurityInfo = ({ text }) => {
    return (
        <div>
            <div className={`security-info flex mt-2 gap-2 ${!text?.startsWith('The') && 'mt-7'} `}>
                <div className="mt-1">
                    <DimmedArrowRight />
                </div>

                <div className="small-text !font-[400] font-poppins">
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
};
