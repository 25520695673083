/* eslint-disable react/style-prop-object */
import React from 'react';

export const MailIcon = ({ className }) => {
    return (
        <svg
            width="21"
            height="17"
            viewBox="0 0 21 17"
            fill="none"
            stroke="#656565"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M13.1456 1.56641H2.78611C2.31241 1.56641 1.8581 1.75459 1.52314 2.08955C1.18818 2.42451 1 2.87881 1 3.35252V14.0692C1 14.5429 1.18818 14.9972 1.52314 15.3322C1.8581 15.6671 2.31241 15.8553 2.78611 15.8553H17.7328C18.2065 15.8553 18.6608 15.6671 18.9957 15.3322C19.3307 14.9972 19.5189 14.5429 19.5189 14.0692V7.95176"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.85547 4.42578L10.2855 9.4269L14.1703 6.49455"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.145 5.14158C19.3275 5.14158 20.2861 4.18298 20.2861 3.00048C20.2861 1.81798 19.3275 0.859375 18.145 0.859375C16.9625 0.859375 16.0039 1.81798 16.0039 3.00048C16.0039 4.18298 16.9625 5.14158 18.145 5.14158Z"
                fill="#656565"
            />
            <path
                d="M18.1446 5.85563C17.5798 5.85563 17.0277 5.68815 16.5582 5.37438C16.0886 5.06061 15.7226 4.61463 15.5064 4.09285C15.2903 3.57107 15.2338 2.99691 15.3439 2.44299C15.4541 1.88907 15.7261 1.38026 16.1254 0.980903C16.5248 0.581548 17.0336 0.309583 17.5875 0.199401C18.1414 0.0892192 18.7156 0.145768 19.2374 0.361898C19.7592 0.578028 20.2051 0.944031 20.5189 1.41362C20.8327 1.88322 21.0002 2.43531 21.0002 3.00008C20.9996 3.75724 20.6985 4.48321 20.1631 5.0186C19.6277 5.554 18.9018 5.85504 18.1446 5.85563ZM18.1446 1.57342C17.8619 1.57298 17.5854 1.65641 17.3502 1.81315C17.1149 1.96989 16.9314 2.1929 16.8229 2.45395C16.7144 2.71501 16.6858 3.00238 16.7407 3.2797C16.7956 3.55703 16.9315 3.81184 17.1312 4.0119C17.331 4.21196 17.5856 4.34827 17.8628 4.40358C18.14 4.4589 18.4274 4.43073 18.6887 4.32265C18.9499 4.21456 19.1732 4.03142 19.3303 3.79639C19.4874 3.56137 19.5713 3.28502 19.5713 3.00231C19.5713 2.62373 19.421 2.26063 19.1535 1.99272C18.8861 1.72482 18.5232 1.57401 18.1446 1.57342Z"
                fill="#656565"
            />
        </svg>
    );
};
