import { Loader } from 'components/common/Loader';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastHandler } from 'responseHanlder';
import { accessauthEmailSendOtpThunk } from 'store/auth/slices';
import { setLoader } from 'store/global/globalReducer';
import { validateEmail } from 'utills/FormValidation';

export const ChangePassowordEmail = ({ handleCondition }) => {
    const dispatch = useDispatch();

    const { data } = useSelector((state) => state.login);

    const [email, setEmail] = useState(data?.email ?? '');
    const { isLoading } = useSelector((state) => state.login);
    const handleSubmit = (e) => {
        if (email === '') {
            toastHandler('Please enter email', 'error');
            return;
        } else if (!validateEmail(email)) {
            toastHandler('Invalid format', 'error');
            return;
        }

        e.preventDefault();
        const forgotPassData = {
            user_id: data?.user_id,
            user_type: 'employee',
            type: 1
        };

        dispatch(setLoader(true));
        dispatch(accessauthEmailSendOtpThunk(forgotPassData))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    handleCondition(1);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            });

        dispatch(setLoader(false));
    };

    return (
        <div>
            {' '}
            <div className="flex min-h-[60vh]  flex-col justify-center pb-10 sm:px-2 lg:px-2 ">
                <div className="mt-3 w-full sm:mx-auto sm:w-full sm:max-w-[650px] bg-white shadow rounded-xl sm:rounded-2xl  py-4 ">
                    {isLoading && <Loader />}{' '}
                    <div className="card-layout-title-text text-center mt-4">Change Password</div>
                    <div className="px-24 mt-3">
                        {' '}
                        <div className="text-sixth font-poppins small-text">
                            Please enter your email address to reset your password! A message with code will be sent to
                            an authorised email to reset the password.
                        </div>
                        <form className="mt-8" onSubmit={handleSubmit}>
                            <div className="flex justify-center items-center default-forms font-poppins">
                                <div className="w-[95%]">
                                    <label className=" ">Email</label>
                                    <input
                                        type="email"
                                        className="mt-2 px-3 border border-secondarybg rounded-xl w-full h-12 "
                                        placeholder="Enter your email "
                                        required
                                        maxLength={50}
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                            </div>

                            <div className="mt-52 mb-5">
                                <div className="flex justify-center items-center ">
                                    <button type="submit" className=" px-12 rounded-lg ann-btn buttons-font font-fira">
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
