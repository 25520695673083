import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import ClientRefNoIcon from 'utills/svgs/ClientRefNoIcon';
import DocumentListIcon from 'utills/svgs/DocumentListIcon';
import AddJobRefNoSetting from './AddJobRefNoSetting';
import { JobTypeSettingsIcon } from 'utills/svgs/JobTypeSettingsIcon';
import { JobTemplatesIcon } from 'utills/svgs/JobTemplatesIcon';
import RelayRoomSettingIcon from 'utills/svgs/RelayRoomSettingIcon';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import DynamicStatusIcon from 'utills/svgs/DynamicStatusIcon';

export const JobManagerSettings = () => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings`);
        }
    };
    return (
        <div>
            <SidebarLayout>
                {open && <AddJobRefNoSetting open={open} setOpen={setOpen} />}
                <div>
                    <Jumbotron
                        title="Job Manager"
                        directory={`Settings`}
                        path={[
                            { title: 'Settings', link: 'settings' },
                            {
                                title: 'Job Manager',
                                link: ''
                            }
                        ]}
                        backArrowProp={backArrowProp}
                    />
                    <div className="mx-5 md:mx-10">
                        <div className="flex gap-5 items-center flex-wrap">
                            <GridCard
                                text="Documents List"
                                iconSet={{ icon: DocumentListIcon }}
                                link={'/settings/job-manager/documents'}
                            />

                            <GridCard
                                text="Job Groups"
                                iconSet={{ icon: RectangleStackIcon, sx: '!stroke-[0.9px] w-20 h-20' }}
                                link={'/settings/groups/jobs'}
                            />
                            <GridCard
                                text="Jobs Reference Number Settings"
                                iconSet={{ icon: ClientRefNoIcon }}
                                atClick={() => {
                                    setOpen(true);
                                }}
                            />
                            <GridCard
                                text="Job Status"
                                iconSet={{ icon: DynamicStatusIcon }}
                                link={'/job-manager/settings/job-status'}
                                atClick={() => {
                                    navigate('/job-manager/settings/job-status');
                                }}
                            />
                            <GridCard
                                text="Job Templates"
                                iconSet={{ icon: JobTemplatesIcon }}
                                link={'/job-manager/settings/job-templates'}
                                atClick={() => {
                                    navigate('/job-manager/settings/job-templates');
                                }}
                            />

                            <GridCard
                                text="Job Type"
                                iconSet={{ icon: JobTypeSettingsIcon }}
                                link={'/job-manager/settings/job-type'}
                                atClick={() => {
                                    navigate('/job-manager/settings/job-type');
                                }}
                            />

                            <GridCard
                                text="Relay Room Settings"
                                iconSet={{ icon: RelayRoomSettingIcon }}
                                link={'/settings/job-manager/relayroom-settings'}
                            />
                        </div>
                    </div>
                </div>
            </SidebarLayout>
        </div>
    );
};
