import React from 'react';
import { EmployeeTasksPage } from 'components/workplace-content/tasks-pages/task-details/employee-tasks/EmployeeTasksPage';
import { SidebarLayout } from 'layouts/SidebarLayout';

export const EmployeeTasks = () => {
    return (
        <div className="app-dashboard">
            <SidebarLayout>
                {' '}
                <div>
                    <EmployeeTasksPage />
                </div>
            </SidebarLayout>
        </div>
    );
};
