import React from 'react';

export const ClockIcon = ({ className }) => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M7.28203 0.824987C5.73828 0.969463 4.31328 1.51727 3.06328 2.44433C2.45391 2.89582 1.66328 3.74461 1.20703 4.4369C1.01016 4.73488 0.625781 5.49338 0.488281 5.85457C0.335156 6.2579 0.169531 6.87795 0.0882813 7.35652C-0.00859375 7.94346 -0.00859375 9.05713 0.0882813 9.64407C0.228906 10.4748 0.450781 11.143 0.828906 11.8865C1.24141 12.6961 1.66953 13.271 2.35703 13.9362C3.04766 14.5984 3.64453 15.0108 4.48516 15.4081C5.25703 15.7723 5.95078 15.986 6.81328 16.1214C7.42266 16.2147 8.57891 16.2147 9.18828 16.1214C10.0508 15.986 10.7445 15.7723 11.5164 15.4081C12.357 15.0108 12.9539 14.5984 13.6445 13.9362C14.332 13.271 14.7602 12.6961 15.1727 11.8865C15.4477 11.3477 15.5852 10.9955 15.7352 10.4537C15.932 9.73135 15.9789 9.33705 15.9789 8.5003C15.9789 7.66354 15.932 7.26924 15.7352 6.54685C15.5852 6.00507 15.4477 5.65291 15.1727 5.11413C14.7602 4.30446 14.332 3.72956 13.6445 3.06437C12.9539 2.40219 12.357 1.98983 11.5164 1.59252C10.7477 1.22832 10.0352 1.0116 9.20391 0.885185C8.74453 0.815957 7.71953 0.782848 7.28203 0.824987ZM8.74453 2.03197C10.2695 2.19149 11.6633 2.84465 12.7695 3.90715C14.0664 5.15627 14.7508 6.74551 14.7508 8.5003C14.7508 10.2551 14.0664 11.8443 12.7695 13.0934C11.4727 14.3426 9.82266 15.0017 8.00078 15.0017C6.17891 15.0017 4.52891 14.3426 3.23203 13.0934C1.93516 11.8443 1.25078 10.2551 1.25078 8.5003C1.25078 6.74551 1.93516 5.15627 3.23203 3.90715C4.32266 2.85669 5.73203 2.1945 7.21953 2.03197C7.56328 1.99585 8.39453 1.99284 8.74453 2.03197Z"
                fill="#B695F8"
            />
            <path
                d="M7.73507 4.43489C7.58507 4.50111 7.46319 4.64559 7.41007 4.81414C7.38507 4.90143 7.37882 5.46128 7.38507 6.81574C7.39444 8.64879 7.39757 8.69996 7.46007 8.81735C7.51007 8.91366 7.76007 9.11232 8.77257 9.84975C9.45694 10.3524 10.0819 10.7979 10.1601 10.843C10.6819 11.144 11.2976 10.6052 11.0351 10.0815C10.9726 9.9551 10.8444 9.85577 9.79757 9.09727L8.62882 8.25449V6.57495C8.62882 5.40108 8.61944 4.86832 8.59444 4.79608C8.50382 4.54024 8.27569 4.37771 8.00382 4.37771C7.92569 4.37771 7.80382 4.40479 7.73507 4.43489Z"
                fill="#B695F8"
            />
        </svg>
    );
};
