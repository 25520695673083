import React from 'react';
import { motion } from 'framer-motion';
import { JobsIcon } from './../../utills/svgs/JobsIcon';
import { NotepadIcon } from './../../utills/svgs/NotepadIcon';
import { LargeTaskIcon } from 'utills/svgs/LargeTaskIcon';
import { Link } from 'react-router-dom';
import TooltipChipWrap from 'components/atoms/tooltipChip/TooltipChipWrap';
export const JobManagerSide = ({ handleHideJobSide }) => {
    return (
        <div>
            <motion.div
                initial={{ x: '-80', opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                onMouseLeave={handleHideJobSide}
                className="clients top-[180px]   shadow py-2"
            >
                <div className="px-2">
                    <TooltipChipWrap
                        title="Click to view"
                        sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                        hideChip
                        placement="right"
                    >
                        <Link to="/job-manager/jobs">
                            {' '}
                            <div className="flex gap-3 items-center p-2 hover:ring-thirdbg hover:ring-1 rounded-xl cursor-pointer">
                                <div className="">
                                    <JobsIcon />
                                </div>
                                <div>Jobs</div>
                            </div>
                        </Link>
                    </TooltipChipWrap>
                    <TooltipChipWrap
                        title="Click to view"
                        sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                        hideChip
                        placement="right"
                    >
                        <Link to="/tasks">
                            <div className="flex gap-3 items-center p-2 cursor-pointer hover:ring-thirdbg hover:ring-1  rounded-xl">
                                <div className="">
                                    <LargeTaskIcon className={'w-[22px] h-[22px]'} />
                                </div>
                                <div>Tasks</div>
                            </div>
                        </Link>
                    </TooltipChipWrap>
                    <TooltipChipWrap
                        title="Click to view"
                        sx={'!bg-gray-100 !text-gray-800 !text-xs  left-full bottom-1/2'}
                        hideChip
                        placement="right"
                    >
                        <Link to="/tasks/reports">
                            <div className="flex gap-3 items-center p-2 hover:ring-1  hover:ring-thirdbg cursor-pointer rounded-xl">
                                <div className="">
                                    <NotepadIcon />
                                </div>
                                <div>Report</div>
                            </div>
                        </Link>
                    </TooltipChipWrap>
                </div>
            </motion.div>
        </div>
    );
};
