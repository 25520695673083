/* eslint-disable no-unused-vars */
import { DragFile } from 'components/atoms/inputs/DragFile';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { ImageCropper } from 'global-components/ImageCropper';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideTimes } from 'utills/svgs/SideTimes';
import { motion } from 'framer-motion';
import { ERROR_TYPE_ERROR, ERROR_TYPE_SUCCESS, USER_TYPE, acceptImages } from 'utills/globalVars';
import { UploadPhotoIcon } from 'utills/svgs/UploadPhotoIcon';
import Badge from 'components/atoms/Badge';
import { delAppImageThunk, editAppThunk, getAllSApps } from 'store/workspace/workspaceApps';
import cloneDeep from 'lodash/cloneDeep';
import { handleEditApp } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { DataURIToBlob } from 'utills/dataValidation';
import { DescriptionInput } from 'components/molecules/DescriptionInput';
export const EditApp = ({ toggleEditApp, toggleAppsUpdated }) => {
    const dispatch = useDispatch();

    const { app } = useSelector((state) => state.global);

    const [name, setName] = useState(app?.name ?? '');
    const [description, setDescription] = useState(app?.description ?? '');
    const [link, setLink] = useState(app?.link ?? '');
    const [selectedFile, setSelectedFile] = useState(null);

    const [appLoader, setAppLoader] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [appFile, setAppFile] = useState(null);
    const [showCroper, setShowCroper] = useState(false);
    const [showImage, setShowImage] = useState('');
    const { data: allAppsList } = useSelector((state) => state.getAllSApps);

    const handleFileSelect = (files) => {
        const file = files[0];
        setSelectedFile(files);
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setSelectedImage(reader.result);
                setAppFile(reader.result);
            };

            reader.readAsDataURL(file);
            setShowCroper(true);
        }
    };

    const addApp = (e) => {
        e.preventDefault();
        if (!name || !link) {
            toastHandler('Please fill in all required fields', ERROR_TYPE_ERROR);
            return;
        }

        if (linkError) {
            toastHandler('invalid link', 'error');
            return;
        }

        const formData = new FormData();

        if (appFile) {
            const file = DataURIToBlob(selectedImage);
            formData.append('image', file, 'image.jpg');
        }

        formData.append('uploaded_by', USER_TYPE);
        formData.append('name', name.trim());
        formData.append('description', description.trim());
        formData.append('link', link.trim());

        formData.append('app_id', app?.app_id);

        dispatch(editAppThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toastHandler('App added successfully', ERROR_TYPE_SUCCESS);
                    toggleAppsUpdated();

                    toggleEditApp();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {});
    };

    const [linkError, setLinkError] = useState(false);
    const handleAppLink = (value) => {
        setLink(value);
        if (!value?.includes('.') || !value.startsWith('http')) {
            setLinkError(true);
            return;
        } else {
            setLinkError(false);
            setLink(value);
        }
    };

    const handleSelectedFiles = () => {
        setSelectedFile(null);
        setSelectedImage(null);
    };

    const saveImage = (image) => {
        setSelectedImage(image);
    };

    const croperHeightWidth = 150;

    const handleDelFile = (id) => {
        dispatch(delAppImageThunk({ app_id: id }))
            .then((response) => {
                if (response.payload) {
                    const newApps = cloneDeep(allAppsList);

                    const findApp = newApps?.find((app) => app?.app_id === id);
                    const indexofApp = newApps?.indexOf(findApp);
                    newApps?.splice(indexofApp, 1);
                    dispatch(
                        handleEditApp({
                            ...app,
                            image: null
                        })
                    );
                    toggleAppsUpdated();

                    dispatch(getAllSApps?.actions.handleUpdate(newApps));
                }
            })
            .catch((error) => {});
    };
    return (
        <div className="add-p-side grid grid-cols-6 text-black">
            <div className="md:col-span-4 hidden md:block left-side">
                {appFile && showCroper ? (
                    <>
                        <ImageCropper
                            width={croperHeightWidth}
                            height={croperHeightWidth}
                            setShowCropper={setShowCroper}
                            image={appFile}
                            handleCroppedImage={(image) => saveImage(image)}
                        />
                    </>
                ) : (
                    ''
                )}
            </div>
            <div className="right-side col-span-6 md:col-span-2">
                <motion.div
                    initial={{ x: 700 }}
                    animate={{ x: 0 }}
                    transition={{ delay: 0.2, type: 'tween', duration: 0.4 }}
                    className="inner-right relative"
                >
                    <div>
                        <div onClick={toggleEditApp} className="absolute   text-white p-2 right-1 top-1 cursor-pointer">
                            <SideTimes />
                        </div>

                        <div className="add-detail pt-10 px-5">
                            <div className="title ">Update App</div>

                            <div className="jumbo-dir">
                                Workspace &gt; Apps <span className="special-jumbo-text"> &gt; Update App</span>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={addApp}>
                        {' '}
                        <div>
                            <div>
                                <div className="add-ann-form mt-5 px-5">
                                    <div>
                                        <div>
                                            <label>App Name</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                maxLength={40}
                                                placeholder="App Name"
                                                className="px-3 rounded-xl mt-1"
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                                value={name}
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-2">
                                        <div>
                                            <label>App Link</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                maxLength="50"
                                                placeholder="https://"
                                                className={`px-3 rounded-xl mt-1 ${
                                                    link && linkError ? 'add-error-border' : ''
                                                }`}
                                                onChange={(e) => handleAppLink(e.target.value)}
                                                required
                                                value={link}
                                            />
                                        </div>
                                        <div>
                                            {linkError && link ? (
                                                <span className="error-div">Link must be valid </span>
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <DescriptionInput
                                            title="Description"
                                            placeholder="Enter App description"
                                            onChange={(e) => setDescription(e)}
                                            value={description}
                                            maxCount={80}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <div>
                                            <label>Upload App Image (optional)</label>
                                        </div>

                                        <div className="mt-2">
                                            <DragFile
                                                iconSet={{ icon: UploadPhotoIcon }}
                                                atChange={(file) => {
                                                    handleFileSelect(file);
                                                }}
                                                accept={acceptImages}
                                                selectedFiles={selectedFile}
                                                isMultiple={false}
                                                hideSelectedFiles
                                                dragMessage="Drag files here to upload image, or browse files"
                                                dropMessage="Drop the selected files in this box... "
                                                onlyPdf={false}
                                                message="Only images are allowed"
                                            />
                                            <SelectedFiles
                                                files={selectedFile}
                                                updateSelectedFiles={(files) => setSelectedFile(files || [])}
                                            />
                                        </div>

                                        <div className="mt-5">
                                            {app?.image && (
                                                <div>
                                                    <div className="buttons-font font-poppins"> App Image</div>{' '}
                                                    <Badge
                                                        leftIcon={
                                                            <img
                                                                src={app?.image}
                                                                alt="alt"
                                                                className="thumbnail-image cursor-pointer !w-10 !h-10 rounded-md mt-2"
                                                                onClick={() => {
                                                                    setShowImage(app?.image);
                                                                }}
                                                            />
                                                        }
                                                        text={'app file'}
                                                        sx="text-white bg-third rounded-md !px-3 !text-md"
                                                        onCancel={() => handleDelFile(app?.app_id)}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center my-5">
                            <button
                                disabled={appLoader ? true : false}
                                type="submit"
                                className="ann-btn px-5 md:px-20 py-2 cursor-pointer text-white rounded-lg"
                            >
                                {appLoader ? <SmallLoaderWhite /> : 'Update'}
                            </button>
                        </div>{' '}
                    </form>
                </motion.div>
            </div>
        </div>
    );
};
