import React from 'react';
import GoogleAuthPage from '../../../../components/dashboard-components/auth/GoogleAuthPage';
const GoogleAuth = () => {
    return (
        <div>
            <GoogleAuthPage />
        </div>
    );
};

export default GoogleAuth;
