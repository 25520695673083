/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { DummyTeamMembersList } from 'playground';
import DropdownMenu from 'components/atoms/DropDownMenu/DropDownMenu';
import DropdownMenuItem from 'components/atoms/DropDownMenu/DropdownMenuItem';
import RecycleIcon from 'utills/svgs/RecycleIcon';
import { TrashIcon } from 'utills/svgs/TrashIcon';
import { convertDateFormatToLocal, DateFormatToLocal, formateDate } from 'utills/moment';
import { RevokeIcon } from 'utills/svgs/RevokeIcon';
import { EditPencilIcon } from 'utills/svgs/EditPencilIcon';
import { useDispatch } from 'react-redux';
import { handleEditClient } from 'store/global/globalReducer';
import { NoDataFound } from 'components/common/NoDataFound';
import { Button } from 'components/atoms/buttons/Button';
import useRoleGuard from 'Hooks/useRoleGuard';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_ERROR, PERMISSIONS_MESSAGE } from 'utills/globalVars';

const InvitesTableDT = ({
    data,
    isLoading,
    handleResendinvitation,
    handleRevoke,
    handleDeleteinvitation,
    toggleEditClient
}) => {
    const [clientList, setClientList] = useState([]);
    const dispatch = useDispatch();
    const { hasPermissions } = useRoleGuard();

    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [selectedPeople, setSelectedPeople] = useState([]);

    function toggleAll() {
        setSelectedPeople(checked || indeterminate ? [] : DummyTeamMembersList);
        setChecked(!checked && !indeterminate);
        setIndeterminate(false);
    }

    useEffect(() => {
        if (data) {
            setClientList(data);
        }
    }, [data]);

    const editClient = (client) => {
        dispatch(handleEditClient(client));
        toggleEditClient();
    };
    return (
        <div className="px-10 pt-5 min-h-[500px]">
            <div className="">
                <div className="">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <div className="relative">
                            {selectedPeople.length > 0 && (
                                <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 bg-[#FAF9F6] sm:left-12">
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    >
                                        Bulk edit
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                    >
                                        Delete all
                                    </button>
                                </div>
                            )}

                            <div className="">
                                <div className="px-4 sm:px-6 rounded-xl  bg-white lg:px-8">
                                    <div className="mt-5 flow-root">
                                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-3.5 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-0"
                                                            >
                                                                Name
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                            >
                                                                Phone
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                            >
                                                                Email
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                            >
                                                                Last Update
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                            >
                                                                Status
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-md font-semibold text-gray-900"
                                                            >
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {clientList &&
                                                            clientList?.map((person, index) => (
                                                                <tr key={person?.email}>
                                                                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-md sm:pl-0">
                                                                        <div className="flex items-center">
                                                                            <div className="">
                                                                                <div className="font-medium text-gray-900">
                                                                                    {person?.first_name}{' '}
                                                                                    {person?.last_name}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                                        <div className="text-gray-900">
                                                                            {person?.phone_number}
                                                                        </div>
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                                        {person?.email || '--'}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                                        {person?.created_at
                                                                            ? convertDateFormatToLocal(
                                                                                  person?.created_at,
                                                                                  'DD-MM-YYYYTHH:mm:ssZ',

                                                                                  'DD/MM/YYYY hh:mm A'
                                                                              )
                                                                            : 'N/A'}
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                                        <h3
                                                                            className={`font-[500]  ${
                                                                                person.status === 'sent'
                                                                                    ? '!text-orange-500'
                                                                                    : person.status === 'accepted'
                                                                                    ? '!text-green-500'
                                                                                    : person.status === 'error'
                                                                                    ? '!text-red-500'
                                                                                    : person.status === 'revoke'
                                                                                    ? 'text-thirdbg'
                                                                                    : '!text-gray-500'
                                                                            } `}
                                                                        >
                                                                            {person?.status ? (
                                                                                <span>
                                                                                    {' '}
                                                                                    Invitation{' '}
                                                                                    {person?.status === 'pending'
                                                                                        ? 'sent'
                                                                                        : person?.status === 'revoke'
                                                                                        ? ' revoked'
                                                                                        : person?.status ===
                                                                                          'invitation_error'
                                                                                        ? 'error'
                                                                                        : person?.status}
                                                                                </span>
                                                                            ) : (
                                                                                'N/A'
                                                                            )}
                                                                        </h3>
                                                                    </td>
                                                                    <td className="whitespace-nowrap px-3 py-5 text-md text-gray-500">
                                                                        <div className="flex justify-center items-center relative">
                                                                            <div className="cursor-pointer  text-black flex items-center">
                                                                                <DropdownMenu>
                                                                                    {person?.status !== 'accepted' && (
                                                                                        <div>
                                                                                            <DropdownMenuItem
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        hasPermissions(
                                                                                                            'Clients',
                                                                                                            [
                                                                                                                'write',
                                                                                                                'admin'
                                                                                                            ]
                                                                                                        )
                                                                                                    ) {
                                                                                                        handleResendinvitation(
                                                                                                            person?.client_invite_id
                                                                                                        );
                                                                                                    } else {
                                                                                                        toastHandler(
                                                                                                            PERMISSIONS_MESSAGE,
                                                                                                            ERROR_TYPE_ERROR
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <div className="flex items-center gap-2 cursor-pointer">
                                                                                                    <span className="w-5 h-5">
                                                                                                        <RecycleIcon className="w-4 h-4 bg-transparent" />
                                                                                                    </span>
                                                                                                    Resend Invitation
                                                                                                </div>
                                                                                            </DropdownMenuItem>

                                                                                            {person?.status !==
                                                                                                'revoke' &&
                                                                                            person?.status !==
                                                                                                'invitation_error' &&
                                                                                            person?.status !==
                                                                                                'expired' ? (
                                                                                                <DropdownMenuItem
                                                                                                    onClick={() => {
                                                                                                        if (
                                                                                                            hasPermissions(
                                                                                                                'Clients',
                                                                                                                [
                                                                                                                    'write',
                                                                                                                    'admin'
                                                                                                                ]
                                                                                                            )
                                                                                                        ) {
                                                                                                            handleRevoke(
                                                                                                                person?.client_invite_id
                                                                                                            );
                                                                                                        } else {
                                                                                                            toastHandler(
                                                                                                                PERMISSIONS_MESSAGE,
                                                                                                                ERROR_TYPE_ERROR
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <div className="flex items-center gap-2  cursor-pointer">
                                                                                                        <span className="w-5 h-5 -ml-1">
                                                                                                            <RevokeIcon className="w-5 h-5 bg-transparent" />
                                                                                                        </span>
                                                                                                        Revoke
                                                                                                    </div>
                                                                                                </DropdownMenuItem>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}

                                                                                            <DropdownMenuItem
                                                                                                onClick={() => {
                                                                                                    if (
                                                                                                        hasPermissions(
                                                                                                            'Clients',
                                                                                                            [
                                                                                                                'write',
                                                                                                                'admin'
                                                                                                            ]
                                                                                                        )
                                                                                                    ) {
                                                                                                        editClient(
                                                                                                            person
                                                                                                        );
                                                                                                    } else {
                                                                                                        toastHandler(
                                                                                                            PERMISSIONS_MESSAGE,
                                                                                                            ERROR_TYPE_ERROR
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <div className="flex items-center gap-2  cursor-pointer">
                                                                                                    <span className="w-5 h-5">
                                                                                                        <EditPencilIcon className="w-4 h-4 bg-transparent" />
                                                                                                    </span>
                                                                                                    Edit
                                                                                                </div>
                                                                                            </DropdownMenuItem>
                                                                                        </div>
                                                                                    )}

                                                                                    <DropdownMenuItem
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                hasPermissions(
                                                                                                    'Clients',
                                                                                                    ['write', 'admin']
                                                                                                )
                                                                                            ) {
                                                                                                handleDeleteinvitation(
                                                                                                    person?.client_invite_id
                                                                                                );
                                                                                            } else {
                                                                                                toastHandler(
                                                                                                    PERMISSIONS_MESSAGE,
                                                                                                    ERROR_TYPE_ERROR
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <div className="flex items-center gap-2  cursor-pointer">
                                                                                            <span className="w-5 h-5">
                                                                                                <TrashIcon className="w-4 h-4 bg-transparent" />
                                                                                            </span>
                                                                                            Remove
                                                                                        </div>
                                                                                    </DropdownMenuItem>
                                                                                </DropdownMenu>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!isLoading && clientList?.length === 0 && (
                                <NoDataFound
                                    classes="border-b border-gray-300"
                                    message="No Data Found!"
                                    height="420px"
                                />
                            )}
                            {isLoading && <SkeletonTable columns={7} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvitesTableDT;
