/* eslint-disable no-unused-vars */
import ProgressBar from '@ramonak/react-progress-bar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const CompletedTaskTypesCard = () => {
    const { data } = useSelector((state) => state.taskCountByType);

    const [totalCompleted, setTotalCompleted] = useState(0);

    useEffect(() => {
        let newCount = 0;

        if (data) {
            data?.forEach((type) => {
                newCount = newCount + parseInt(type.count);
            });
        }

        setTotalCompleted(newCount);
    }, [data]);

    return (
        <div className="p-5">
            {' '}
            <div className="xl-title text-blueish font-fira">Completed Task Types</div>
            <div className="mt-10">
                {' '}
                {data?.map((item, index) => {
                    const completionPercentage = (item.completed / item.total) * 100;

                    return (
                        <div className="completed-task-type-strip mt-7" key={index}>
                            <div className="flex justify-between items-center">
                                <div className="buttons-font font-poppins">{item.name}</div>
                                <div className="buttons-font font-poppins">
                                    {item.completed}/{item.total}
                                </div>
                            </div>
                            <div className="mt-2">
                                <ProgressBar
                                    completed={completionPercentage}
                                    bgColor={'#b695f8'}
                                    height="5px"
                                    width="100%"
                                    animateOnRender={true}
                                    isLabelVisible={false}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
