import { useDispatch } from 'react-redux';
import { setLoader } from 'store/global/globalReducer';
import { taskCountByTypeThunk } from 'store/workspace/workspaceEmployeeTasks';
import { BUSINESS_ID } from 'utills/globalVars';

export const useGetTasksCountByType = () => {
    const dispatch = useDispatch();
    const business_id = localStorage.getItem(BUSINESS_ID);
    const fetchCountByType = (id) => {
        dispatch(setLoader(true));
        dispatch(taskCountByTypeThunk({ employee_id: id, business_id }))
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
    return { fetchCountByType };
};
