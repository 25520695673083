import { useDispatch } from 'react-redux';
import { base64ToBlob } from './../../utills/filesHandlers';
import { USER_TYPE } from 'utills/globalVars';

import { updateClientProfileImageThunk } from 'store/client';
import { setLoader } from 'store/global/globalReducer';

export const useUploadProfile = () => {
    const dispatch = useDispatch();

    const handleUpdateProfile = (id, profileImage, handleaddComplete) => {
        const mimeType = profileImage.match(/data:([^;]+);base64,/)[1];

        const blob = base64ToBlob(profileImage);
        const formData = new FormData();

        formData.append('business_client_id', id);
        formData.append('image', blob, `image.${mimeType.split('/')[1]}`);
        formData.append('uploaded_by', USER_TYPE);

        dispatch(setLoader(true));

        dispatch(updateClientProfileImageThunk(formData))
            .then((response) => {
                if (response.payload) {
                    handleaddComplete();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return { handleUpdateProfile };
};
