import React from 'react';
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';

import { useFieldArray, useForm } from 'react-hook-form';

import { getBoundingClientRect } from './hooks/helper';
import { useDocumentElement } from './hooks/use-document-element';
import { PDF_VIEWER_PAGE_SELECTOR } from './hooks/helper';

import { cn } from './hooks/helper';
import { Card, CardContent } from './hooks/card';

import { DocumentFlowFormContainerContent, DocumentFlowFormContainerFooter } from './hooks/document-flow-root';
import { FieldItem } from './hooks/field-item';
import { FRIENDLY_FIELD_TYPE } from './hooks/types';
import { Button } from 'components/atoms/buttons/Button';

import { toast } from 'react-toastify';
import { SelectWithprofileChip } from 'components/molecules/SelectWithprofileChip';
import { formateDate } from 'utills/moment';
import { toastHandler } from 'responseHanlder';
import { TOAST_TYPE_ERROR } from 'utills/globalVars';

const DEFAULT_HEIGHT_PERCENT = 5;
const DEFAULT_WIDTH_PERCENT = 15;

const MIN_HEIGHT_PX = 60;
const MIN_WIDTH_PX = 200;

const AddFields = ({
    documentFlow,
    hideRecipients = false,
    recipients,
    fields,
    onSubmit,
    onBack,
    title,
    isExpand,
    scale,
    zoomPercentage
}) => {
    const [showModal, setShowModal] = useState(false);

    const { isWithinPageBounds, getFieldPosition, getPage } = useDocumentElement();
    const SendStatus = '';
    const [selectedField, setSelectedField] = useState(null);
    const [selectedSigner, setSelectedSigner] = useState(null);
    const [docTitle, setDocTitle] = useState('');
    const [isFieldWithinBounds, setIsFieldWithinBounds] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [coords, setCoords] = useState({
        x: 0,
        y: 0
    });

    let fieldBounds = useRef({
        height: 0,
        width: 0
    });

    const {
        control,
        handleSubmit,
        formState: { isSubmitting }
    } = useForm({
        defaultValues: {
            fields: fields.map((field) => ({
                nativeId: field.id,
                formId: `${field.id}-${field.documentId}`,
                pageNumber: field.page,
                type: field.type,
                pageX: Number(field.positionX),
                pageY: Number(field.positionY),
                pageWidth: Number(field.width),
                pageHeight: Number(field.height),
                signerEmail: recipients.find((recipient) => recipient.id === field.recipientId)?.email ?? ''
            }))
        }
    });

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const onFormSubmit = () => {
        if (!title) {
            toast.error('Kindly add document name.', { autoClose: 1000 });
            return;
        }

        const recipMial = recipients.map((item) => item.email);
        const recipId = localFields.map((item) => item.user_id);
        for (const id of recipId) {
            const filteredSignatures = localFields.filter((fe) => {
                return fe.user_id === id;
            });
            if (filteredSignatures.length === 0) {
                toast.error('Kindly add Atleas one field for each user.', {
                    autoClose: 1000
                });
                return;
            }
        }

        let localMial = [];
        localFields.forEach((item) => {
            if (!localMial.includes(item?.user_id)) {
                localMial.push(item?.user_id);
            }
        });

        let hasError = false;
        recipients.forEach((recipient) => {
            if (!localMial?.includes(recipient?.user_id)) {
                hasError = true;
            } else {
                hasError = false;
            }
        });

        if (hasError) {
            return toastHandler('Kindly add fields for all recipients.', TOAST_TYPE_ERROR);
        }

        const data = {
            fields: localFields,
            title: title
        };
        onSubmit(data);
    };
    const {
        append,
        remove,
        update,
        fields: localFields
    } = useFieldArray({
        control,
        name: 'fields'
    });

    const onSelectSigner = (val) => {
        const recip = recipients.find((item) => item.id === val);
        setSelectedSigner(recip);
    };
    const onAddTitle = (val) => {
        setDocTitle(val);
    };
    const onMouseMove = useCallback(
        (event) => {
            setIsFieldWithinBounds(
                isWithinPageBounds(
                    event,
                    PDF_VIEWER_PAGE_SELECTOR,
                    fieldBounds.current.width,
                    fieldBounds.current.height
                )
            );
            setCoords({
                x: event.clientX - fieldBounds.current.width / 2,
                y: event.clientY - fieldBounds.current.height / 2
            });
        },
        [isWithinPageBounds]
    );
    const onMouseClick = useCallback(
        (event) => {
            if (!selectedField || !selectedSigner) {
                return;
            }

            const $page = getPage(event, PDF_VIEWER_PAGE_SELECTOR);

            if (
                !$page ||
                !isWithinPageBounds(
                    event,
                    PDF_VIEWER_PAGE_SELECTOR,
                    fieldBounds.current.width,
                    fieldBounds.current.height
                )
            ) {
                setSelectedField(null);
                return;
            }

            const { top, left, height, width } = getBoundingClientRect($page);
            const pageNumber = parseInt($page.getAttribute('data-page-number') ?? '1', 10);

            let pageX = ((event.pageX - left) / width) * 100;
            let pageY = ((event.pageY - top) / height) * 100;

            const fieldPageWidth = (fieldBounds.current.width / width) * 100;
            const fieldPageHeight = (fieldBounds.current.height / height) * 100;

            pageX -= fieldPageWidth / 2;
            pageY -= fieldPageHeight / 2;
            append({
                type: selectedField,
                user_id: selectedSigner.user_id,
                pageNumber,
                pageX,
                pageY,
                pageWidth: fieldPageWidth,
                pageHeight: fieldPageHeight,
                signerEmail: selectedSigner.email,
                signerName: selectedSigner?.name,
                shadowItem: selectedSigner?.colors?.backgroundColor
            });
            setIsFieldWithinBounds(false);
            setSelectedField(null);
        },
        [append, isWithinPageBounds, selectedField, selectedSigner, getPage]
    );
    const onFieldResize = useCallback(
        (node, index) => {
            const field = localFields[index];
            const $page = window.document.querySelector(
                `${PDF_VIEWER_PAGE_SELECTOR}[data-page-number="${field.pageNumber}"]`
            );
            if (!$page) {
                return;
            }
            const { x: pageX, y: pageY, width: pageWidth, height: pageHeight } = getFieldPosition($page, node);
            update(index, {
                ...field,
                pageX,
                pageY,
                pageWidth,
                pageHeight
            });
        },
        [getFieldPosition, localFields, update]
    );
    const onFieldMove = useCallback(
        (node, index) => {
            const field = localFields[index];
            const $page = window.document.querySelector(
                `${PDF_VIEWER_PAGE_SELECTOR}[data-page-number="${field.pageNumber}"]`
            );
            if (!$page) {
                return;
            }
            const { x: pageX, y: pageY } = getFieldPosition($page, node);
            update(index, {
                ...field,
                pageX,
                pageY
            });
        },
        [getFieldPosition, localFields, update]
    );
    useEffect(() => {
        if (selectedField) {
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseClick);
        }
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseClick);
        };
    }, [onMouseClick, onMouseMove, selectedField]);
    useEffect(() => {
        const observer = new MutationObserver((_mutations) => {
            const $page = document.querySelector(PDF_VIEWER_PAGE_SELECTOR);
            if (!$page) {
                return;
            }
            const { height, width } = $page.getBoundingClientRect();
            fieldBounds.current = {
                height: Math.max(height * (DEFAULT_HEIGHT_PERCENT / 100), MIN_HEIGHT_PX),
                width: Math.max(width * (DEFAULT_WIDTH_PERCENT / 100), MIN_WIDTH_PX)
            };
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
        return () => {
            observer.disconnect();
        };
    }, []);
    useEffect(() => {
        setSelectedSigner(recipients.find((r) => r.sendStatus !== SendStatus.SENT) ?? recipients[0]);
    }, [recipients]);

    const handleDate = () => {
        const date = new Date();
        const parsetDate = formateDate(date);

        return parsetDate;
    };
    return (
        <div className="px-5 mt-3 ">
            <DocumentFlowFormContainerContent>
                <div className="flex flex-col">
                    {selectedField && (
                        <Card
                            className={cn(
                                'bg-background pointer-events-none fixed z-50 cursor-pointer transition-opacity',
                                {
                                    'border-primary': isFieldWithinBounds,
                                    'opacity-50': !isFieldWithinBounds
                                }
                            )}
                            style={{
                                top: coords.y,
                                left: coords.x,
                                height: fieldBounds.current.height,
                                width: fieldBounds.current.width
                            }}
                        >
                            <CardContent className="text-foreground flex h-full w-full items-center justify-center p-2">
                                {FRIENDLY_FIELD_TYPE[selectedField]}
                            </CardContent>
                        </Card>
                    )}

                    <div>
                        {' '}
                        {!isExpand &&
                            zoomPercentage === 100 &&
                            localFields.map((field, index) => (
                                <FieldItem
                                    key={index}
                                    field={field}
                                    disabled={selectedSigner?.email !== field.signerEmail}
                                    colors={selectedSigner?.colors}
                                    minHeight={fieldBounds.current.height}
                                    minWidth={fieldBounds.current.width}
                                    passive={isFieldWithinBounds && !!selectedField}
                                    onResize={(options) => onFieldResize(options, index)}
                                    onMove={(options) => onFieldMove(options, index)}
                                    onRemove={() => remove(index)}
                                    scale={scale}
                                />
                            ))}
                    </div>

                    <div className="primary-fonts text-xl">Add Fields</div>
                    <div className="secondary-fonts text-sm">Add all relevant fields for each recipient</div>

                    <div className="mt-2">
                        <label className="primary-fonts text-md">Select Signee</label>

                        <div>
                            <SelectWithprofileChip
                                hideIcon={true}
                                data={recipients?.filter((item) => item?.id !== selectedSigner?.id)}
                                showModal={showModal}
                                toggleModal={toggleModal}
                                signee={selectedSigner}
                                selectedSignee={selectedSigner}
                                onSelect={(id) => {
                                    onSelectSigner(id);
                                }}
                            />
                        </div>
                    </div>

                    <div className="-mx-2 flex-1 overflow-y-auto px-2 mt-5 py-3">
                        <div className="grid grid-cols-2 gap-x-4 gap-y-8">
                            <button
                                type="button"
                                className="group h-full w-full"
                                disabled={!selectedSigner}
                                onClick={() => setSelectedField(FRIENDLY_FIELD_TYPE.SIGNATURE)}
                                onMouseDown={() => setSelectedField(FRIENDLY_FIELD_TYPE.SIGNATURE)}
                                data-selected={selectedField === FRIENDLY_FIELD_TYPE.SIGNATURE ? true : undefined}
                            >
                                <Card
                                    style={{ boxShadow: `0 2px 7px 0 ${selectedSigner?.colors?.backgroundColor}` }}
                                    className="group-data-[selected]:border-documenso  shadow-custom border-none h-full w-full cursor-pointer group-disabled:opacity-50"
                                >
                                    <CardContent className="flex flex-col items-center justify-center rounded-md px-6 py-4">
                                        <p
                                            className={cn(
                                                'text-muted-foreground group-data-[selected]:text-foreground w-full truncate text-md font-medium'
                                            )}
                                        >
                                            {'Signature'}
                                        </p>
                                        <p className="text-muted-foreground mt-2 text-center text-sm">
                                            {selectedSigner?.name}
                                        </p>
                                    </CardContent>
                                </Card>
                            </button>
                            <button
                                type="button"
                                className="group h-full w-full"
                                disabled={!selectedSigner}
                                onClick={() => setSelectedField(FRIENDLY_FIELD_TYPE.EMAIL)}
                                onMouseDown={() => setSelectedField(FRIENDLY_FIELD_TYPE.EMAIL)}
                                data-selected={selectedField === FRIENDLY_FIELD_TYPE.EMAIL ? true : undefined}
                            >
                                <Card
                                    style={{ boxShadow: `0 2px 7px 0 ${selectedSigner?.colors?.backgroundColor}` }}
                                    className="group-data-[selected]:border-documenso shadow-custom border-none h-full w-full cursor-pointer group-disabled:opacity-50"
                                >
                                    <CardContent className="flex flex-col items-center justify-center px-6 py-4">
                                        <p
                                            className={cn(
                                                'text-muted-foreground group-data-[selected]:text-foreground text-md font-medium'
                                            )}
                                        >
                                            {'Email'}
                                        </p>
                                        <p className="text-muted-foreground mt-2 text-sm break-all">
                                            {selectedSigner?.email}
                                        </p>
                                    </CardContent>
                                </Card>
                            </button>
                            <button
                                type="button"
                                className="group h-full w-full"
                                disabled={!selectedSigner}
                                onClick={() => setSelectedField(FRIENDLY_FIELD_TYPE.NAME)}
                                onMouseDown={() => setSelectedField(FRIENDLY_FIELD_TYPE.NAME)}
                                data-selected={selectedField === FRIENDLY_FIELD_TYPE.NAME ? true : undefined}
                            >
                                <Card
                                    style={{ boxShadow: `0 2px 7px 0 ${selectedSigner?.colors?.backgroundColor}` }}
                                    className="group-data-[selected]:border-documenso shadow-custom border-none h-full w-full cursor-pointer group-disabled:opacity-50"
                                >
                                    <CardContent className="flex flex-col items-center justify-center px-6 py-4">
                                        <p
                                            className={cn(
                                                'text-muted-foreground group-data-[selected]:text-foreground text-md font-medium'
                                            )}
                                        >
                                            {'Name'}
                                        </p>
                                        <p className="text-muted-foreground mt-2 text-sm">{selectedSigner?.name}</p>
                                    </CardContent>
                                </Card>
                            </button>
                            <button
                                type="button"
                                className="group h-full w-full"
                                disabled={!selectedSigner}
                                onClick={() => setSelectedField(FRIENDLY_FIELD_TYPE.DATE)}
                                onMouseDown={() => setSelectedField(FRIENDLY_FIELD_TYPE.DATE)}
                                data-selected={selectedField === FRIENDLY_FIELD_TYPE.DATE ? true : undefined}
                            >
                                <Card
                                    style={{ boxShadow: `0 2px 7px 0 ${selectedSigner?.colors?.backgroundColor}` }}
                                    className="group-data-[selected]:border-documenso shadow-custom border-none h-full w-full cursor-pointer group-disabled:opacity-50"
                                >
                                    <CardContent className="flex flex-col items-center justify-center px-6 py-4">
                                        <p
                                            className={cn(
                                                'text-muted-foreground group-data-[selected]:text-foreground text-md font-medium'
                                            )}
                                        >
                                            {'Date'}
                                        </p>
                                        <p className="text-muted-foreground mt-2 text-sm"> {handleDate()} </p>
                                    </CardContent>
                                </Card>
                            </button>
                        </div>
                    </div>
                </div>
            </DocumentFlowFormContainerContent>
            <DocumentFlowFormContainerFooter>
                <div className="w-full mt-16 justify-center flex gap-2 items-center my-5 pb-2">
                    <Button
                        title="Back"
                        classes="blue-border-btn px-12 h-size8"
                        buttonType="button"
                        key="button"
                        atClick={() => onBack('two')}
                    />{' '}
                    <Button
                        title="Next"
                        classes="ann-btn px-12"
                        buttonType="button"
                        key="button"
                        atClick={() => void onFormSubmit()}
                    />
                </div>
            </DocumentFlowFormContainerFooter>

            <div></div>
        </div>
    );
};
export default AddFields;
