import { endpoint, methods } from '../../apiEndpoints';
import generateThunkAndSlice from '../thunk/thunk';

const { slice: signup, request: signupUserThunk } = generateThunkAndSlice('signup', endpoint.createUser, methods.POST);
const { slice: otpVerification, request: otpVerificationThunk } = generateThunkAndSlice(
    'otpVerification',
    endpoint.verifyEmail,
    methods.POST
);

const { request: resendOtpThunk } = generateThunkAndSlice('resendOtp', endpoint.resendOtp, methods.POST);

const { slice: getSecurityQuestions, request: getSecurityQuestionsThunk } = generateThunkAndSlice(
    'getSecurityQuestions',
    endpoint.getSecurityQuestions,
    methods.POST
);

const { request: sendOtpPhoneRegisterThunk } = generateThunkAndSlice(
    'getSecurityQuestions',
    endpoint.sendOtpPhoneRegister,
    methods.POST
);

const { slice: verifySecurityQuestions, request: verifySecurityQuestionsThunk } = generateThunkAndSlice(
    'verifySecurityQuestions',
    endpoint.verifySecurityQuestions,
    methods.POST
);

const { slice: setPassword, request: setPasswordThunk } = generateThunkAndSlice(
    'setPassword',
    endpoint.setPassword,
    methods.POST
);

const { request: authUpdatepasswordThunk } = generateThunkAndSlice(
    'authUpdatepassword',
    endpoint.authUpdatepassword,
    methods.POST
);

const { request: setEmployeePasswordThunk } = generateThunkAndSlice(
    'setPassword',
    endpoint.updateEmployeePassword,
    methods.POST
);

const { slice: login, request: loginThunk } = generateThunkAndSlice('login', endpoint.login, methods.POST);

const { slice: forgotPassword, request: forgotPasswordThunk } = generateThunkAndSlice(
    'forgotPassword',
    endpoint.forgotPassword,
    methods.PUT
);

const { request: accessauthEmailSendOtpThunk } = generateThunkAndSlice(
    'forgotPassword',
    endpoint.accessauthEmailSendOtp,
    methods.POST
);

const { slice: verifyForgetPass, request: verifyForgetPassThunk } = generateThunkAndSlice(
    'verifyForgotPass',
    endpoint.forgetPassOtp,
    methods.PUT
);

const { request: forgetPassOtpAccessThunk } = generateThunkAndSlice(
    'forgetPassOtpAccess',
    endpoint.forgetPassOtpAccess,
    methods.POST
);

const { slice: loginGetSecurityQuestions, request: loginGetSecurityQuestionsThunk } = generateThunkAndSlice(
    'loginGetSecurityQuestions',
    endpoint.loginGetSecurityQuestions,
    methods.POST
);

const { request: loginVerifyAnswersThunk } = generateThunkAndSlice(
    'loginVerifyAnswers',
    endpoint.loginVerifyAnswers,
    methods.POST
);

const { request: resetPasswordThunk } = generateThunkAndSlice('resetPassword', endpoint.resetPassword, methods.PUT);

const { request: authEmailSendOtpThunk } = generateThunkAndSlice(
    'authEmailSendOtp',
    endpoint.authEmailSendOtp,
    methods.POST
);
const { request: PreauthEmailSendOtpThunk } = generateThunkAndSlice(
    'PreauthEmailSendOtp',
    endpoint.PreauthEmailSendOtp,
    methods.POST
);

const { request: authEmailSendOtpAccessThunk } = generateThunkAndSlice(
    'authEmailSendOtpAccess',
    endpoint.authEmailSendOtpAccess,
    methods.POST
);

const { request: authEmailVerifyOtpThunk } = generateThunkAndSlice(
    'authEmailVerifyOtp',
    endpoint.authEmailVerifyOtp,
    methods.PUT
);
const { request: verifyPhoneThunk } = generateThunkAndSlice('authEmailVerifyOtp', endpoint.verifyPhone, methods.POST);
const { request: sendOtpPhoneThunk } = generateThunkAndSlice('authEmailVerifyOtp', endpoint.sendOtpPhone, methods.POST);

const { slice: googleAuth, request: googleAuthThunk } = generateThunkAndSlice(
    'googleAuth',
    endpoint.googleAuth,
    methods.POST
);
const { slice: googleAuthAccess, request: googleAuthAccessThunk } = generateThunkAndSlice(
    'googleAuthAccess',
    endpoint.googleAuthAccess,
    methods.POST
);

const { request: googleAuthPreThunk } = generateThunkAndSlice('googleAuth', endpoint.googleAuthPre, methods.POST);

const { request: verifyGoogleAuthThunk } = generateThunkAndSlice(
    'verifyGoogleAuth',
    endpoint.verifyGoogleAuth,
    methods.POST
);

const { request: verifyGoogleAuthAtSetupThunk } = generateThunkAndSlice(
    'verifyGoogleAuthAtSetup',
    endpoint.verifyGoogleAuthAtSetup,
    methods.POST
);

const { request: verifyGoogleAuthAccessThunk } = generateThunkAndSlice(
    'verifyGoogleAuthAccess',
    endpoint.verifyGoogleAuthAccess,
    methods.POST
);

const { request: verifyGoogleAuthPreThunk } = generateThunkAndSlice(
    'verifyGoogleAuth',
    endpoint.verifyGoogleAuthPre,
    methods.POST
);

const { slice: fetchUserData, request: fetchUserDataThunk } = generateThunkAndSlice(
    'fetchUserData',
    endpoint.getUserInfo,
    methods.POST
);
const { request: getLoggedInUserInfoThunk } = generateThunkAndSlice(
    'getLoggedInUserInfo',
    endpoint.getLoggedInUserInfo,
    methods.POST
);

const { request: generateBackupCodesThunk } = generateThunkAndSlice(
    'generateBackupCodes',
    endpoint.generateBackupCodes,
    methods.POST
);

const { request: generateBackupCodesAccessThunk } = generateThunkAndSlice(
    'generateBackupCodesAccess',
    endpoint.generateBackupCodesAccess,
    methods.POST
);

const { request: generateBackupCodesPreThunk } = generateThunkAndSlice(
    'generateBackupCodes',
    endpoint.generateBackupCodesPre,
    methods.POST
);

const { request: regenerateBackupCodesThunk } = generateThunkAndSlice(
    'regenerateBackupCodes',
    endpoint.regenrateBackupCodes,
    methods.PUT
);

const { request: verifyBackupCodeThunk } = generateThunkAndSlice(
    'verifyBackupCodes',
    endpoint.verifyBackupCode,
    methods.PUT
);

const { request: businessOwnerAddProfileThunk } = generateThunkAndSlice(
    'businessOwnerAddProfile',
    endpoint.businessOwnerAddProfile,
    methods.POST
);

const { slice: businessOwnerGetProfile, request: businessOwnerGetProfileThunk } = generateThunkAndSlice(
    'businessOwnerGetprofile',
    endpoint.businessOwnerGetProfile,
    methods.POST
);

const { request: addBranchLocationThunk } = generateThunkAndSlice(
    'addBranchLocation',
    endpoint.addBranchLocation,
    methods.POST
);

const { slice: getBranchLocations, request: getBranchLocationsThunk } = generateThunkAndSlice(
    'getBranchLocations',
    endpoint.getBranchLocations,
    methods.POST
);

const { request: addBusinessThunk } = generateThunkAndSlice('addBusiness', endpoint.addBusiness, methods.POST);

const { slice: getBusinessprofile, request: getBusinessprofileThunk } = generateThunkAndSlice(
    'getBusinessprofile',
    endpoint.getBusinessprofile,
    methods.POST
);

const { request: addServiceThunk } = generateThunkAndSlice('addService', endpoint.addService, methods.POST);
const { request: serviceUpdate } = generateThunkAndSlice('updateService', endpoint.updateService, methods.POST);

const { slice: getAllServices, request: getAllServicesThunk } = generateThunkAndSlice(
    'getAllServices',
    endpoint.getAllServices,
    methods.POST
);

const { slice: viewOnBoardingDetails, request: viewOnBoardingDetailsThunk } = generateThunkAndSlice(
    'viewOnboardingDetails',
    endpoint.viewOnBoardingDetails,
    methods.POST
);

const { slice: getOnboardingStepDetails, request: getOnboardingStepDetailsThunk } = generateThunkAndSlice(
    'getOnboardingStepDetails',
    endpoint.getOnboardingStepDetails,
    methods.POST
);

const { request: deleteBranchThunk } = generateThunkAndSlice('deleteBranch', endpoint.deleteBranch, methods.DELETE);

const { request: deleteServiceThunk } = generateThunkAndSlice('deleteService', endpoint.deleteService, methods.POST);
const { request: deactivateUserThunk } = generateThunkAndSlice(
    'deactivateUser',
    endpoint.deactiveateUsers,
    methods.POST
);
const { request: updateUserProfileImageThunk } = generateThunkAndSlice(
    'updateUserProfileImage',
    endpoint.updateUserProfileImage,
    methods.POST
);
const { slice: getBusinessHours, request: getBusinessHoursThunk } = generateThunkAndSlice(
    'getBusinessHours',
    endpoint.getBusinessHours,
    methods.POST
);

const { request: updateBusinessHoursThunk } = generateThunkAndSlice(
    'updateBusinessHours',
    endpoint.updateBusinessHours,
    methods.POST
);
const { request: uploadBusinessPhotoThunk } = generateThunkAndSlice(
    'uploadBusinessPhoto',
    endpoint.uploadBusinessPhoto,
    methods.POST
);
const { request: updatePasswordThunk } = generateThunkAndSlice('updatePassword', endpoint.updatePassword, methods.POST);

const { slice: getWorkspacephotos, request: getWorkspacephotosThunk } = generateThunkAndSlice(
    'getWorkspacephotos',
    endpoint.getWorkspacephotos,
    methods.POST
);

const { request: deleteBusinessPhotoThunk } = generateThunkAndSlice(
    'deleteBusinessPhoto',
    endpoint.deleteBusinessPhoto,
    methods.POST
);

const { slice: totalPermissions, request: totalPermissionsThunk } = generateThunkAndSlice(
    'totalPermissions',
    endpoint.totalPermissions,
    methods.POST
);

const { request: uploadBusinessLogoThunk } = generateThunkAndSlice(
    'uploadBusinessLogo',
    endpoint.uploadBusinessLogo,
    methods.POST
);

const { request: updateBranchThunk } = generateThunkAndSlice('updateBranch', endpoint.updateBranch, methods.POST);

const { request: logoutThunk } = generateThunkAndSlice('logout', endpoint.logout, methods.DELETE);
const { request: getCSRFThunk } = generateThunkAndSlice('getCSRF', endpoint.getCSRF, methods.GET);
const { request: removeFCMThunk } = generateThunkAndSlice('removeFCM', endpoint.removeFCM, methods.POST);
const { request: getOnboardingPreThunk } = generateThunkAndSlice(
    'getOnboardingPre',
    endpoint.getOnboardingPre,
    methods.POST
);
const { request: verifyTwoFactorEmailThunk } = generateThunkAndSlice(
    'verifyTwoFactorEmail',
    endpoint.verifyTwoFactorEmail,
    methods.POST
);
const { request: preverifyTwoFactorEmailThunk } = generateThunkAndSlice(
    'preverifyTwoFactorEmail',
    endpoint.preverifyTwoFactorEmail,
    methods.PUT
);

const { request: AccessverifyTwoFactorEmailThunk } = generateThunkAndSlice(
    'AccessverifyTwoFactorEmail',
    endpoint.AccessverifyTwoFactorEmail,
    methods.POST
);

const { request: skipSetupThunk } = generateThunkAndSlice('skipSetup', endpoint.skipSetup, methods.POST);
const { request: sentOtpToPhoneThunk } = generateThunkAndSlice('sentOtpToPhone', endpoint.sentOtpToPhone, methods.POST);
const { request: sentOtpToPhoneTempThunk } = generateThunkAndSlice(
    'sentOtpToPhone',
    endpoint.sentOtpToPhoneTemp,
    methods.POST
);

const { request: verifyPhoneOtpThunk } = generateThunkAndSlice('verifyPhoneOtp', endpoint.verifyPhoneOtp, methods.POST);

const { request: preverifyPhoneOtpthunk } = generateThunkAndSlice(
    'preverifyPhoneOtp',
    endpoint.preverifyPhoneOtp,
    methods.POST
);

const { request: verifyPhoneOtpTempThunk } = generateThunkAndSlice(
    'verifyPhoneOtp',
    endpoint.verifyPhoneOtpTemp,
    methods.POST
);

const { slice: getAuthInfo, request: getAuthInfothunk } = generateThunkAndSlice(
    'getAuthInfo',
    endpoint.getAuthInfo,
    methods.POST
);
const { request: resetSecurityQuestionsThunk } = generateThunkAndSlice(
    'resetSecurityQuestions',
    endpoint.resetSecurityQuestions,
    methods.POST
);
const { request: addSecurityQuestionsThunk } = generateThunkAndSlice(
    'addSecurityQuestions',
    endpoint.addSecurityQuestions,
    methods.POST
);

const { request: disableGoogleAuthThunk } = generateThunkAndSlice(
    'disableGoogleAuth',
    endpoint.disableGoogleAuth,
    methods.POST
);
const { request: disableEmailAuthThunk } = generateThunkAndSlice(
    'disableEmailAuth',
    endpoint.disableEmailAuth,
    methods.POST
);
const { request: addFCMTokenThunk } = generateThunkAndSlice('addFCMToken', endpoint.addFCMToken, methods.POST);

export {
    signupUserThunk,
    updateBranchThunk,
    signup,
    otpVerification,
    otpVerificationThunk,
    setPassword,
    setPasswordThunk,
    login,
    loginThunk,
    googleAuth,
    googleAuthThunk,
    resendOtpThunk,
    getSecurityQuestions,
    getSecurityQuestionsThunk,
    verifySecurityQuestions,
    verifySecurityQuestionsThunk,
    forgotPassword,
    forgotPasswordThunk,
    verifyForgetPass,
    verifyForgetPassThunk,
    loginGetSecurityQuestions,
    loginGetSecurityQuestionsThunk,
    loginVerifyAnswersThunk,
    resetPasswordThunk,
    authEmailSendOtpThunk,
    authEmailVerifyOtpThunk,
    verifyGoogleAuthThunk,
    fetchUserData,
    fetchUserDataThunk,
    generateBackupCodesThunk,
    regenerateBackupCodesThunk,
    verifyBackupCodeThunk,
    businessOwnerAddProfileThunk,
    addBranchLocationThunk,
    getBranchLocationsThunk,
    addBusinessThunk,
    businessOwnerGetProfile,
    businessOwnerGetProfileThunk,
    getBusinessprofile,
    getBusinessprofileThunk,
    addServiceThunk,
    getAllServicesThunk,
    viewOnBoardingDetails,
    viewOnBoardingDetailsThunk,
    getAllServices,
    getBranchLocations,
    deleteBranchThunk,
    deleteServiceThunk,
    deactivateUserThunk,
    updateUserProfileImageThunk,
    getBusinessHoursThunk,
    getBusinessHours,
    updateBusinessHoursThunk,
    uploadBusinessPhotoThunk,
    updatePasswordThunk,
    getWorkspacephotos,
    getWorkspacephotosThunk,
    deleteBusinessPhotoThunk,
    totalPermissions,
    totalPermissionsThunk,
    uploadBusinessLogoThunk,
    serviceUpdate,
    setEmployeePasswordThunk,
    logoutThunk,
    getCSRFThunk,
    removeFCMThunk,
    verifyPhoneThunk,
    sendOtpPhoneThunk,
    getOnboardingPreThunk,
    verifyTwoFactorEmailThunk,
    skipSetupThunk,
    sentOtpToPhoneThunk,
    verifyPhoneOtpThunk,
    getAuthInfothunk,
    getAuthInfo,
    resetSecurityQuestionsThunk,
    addSecurityQuestionsThunk,
    sendOtpPhoneRegisterThunk,
    verifyGoogleAuthPreThunk,
    googleAuthPreThunk,
    generateBackupCodesPreThunk,
    sentOtpToPhoneTempThunk,
    verifyPhoneOtpTempThunk,
    disableGoogleAuthThunk,
    googleAuthAccessThunk,
    googleAuthAccess,
    verifyGoogleAuthAccessThunk,
    generateBackupCodesAccessThunk,
    accessauthEmailSendOtpThunk,
    forgetPassOtpAccessThunk,
    authUpdatepasswordThunk,
    verifyGoogleAuthAtSetupThunk,
    disableEmailAuthThunk,
    addFCMTokenThunk,
    authEmailSendOtpAccessThunk,
    AccessverifyTwoFactorEmailThunk,
    PreauthEmailSendOtpThunk,
    preverifyTwoFactorEmailThunk,
    getLoggedInUserInfoThunk,
    preverifyPhoneOtpthunk,
    getOnboardingStepDetails,
    getOnboardingStepDetailsThunk
};
