'use client';

import React from 'react';

import { motion } from 'framer-motion';

import { cn } from './helper';

export const DocumentFlowFormContainer = ({ children, id = 'document-flow-form-container', className, ...props }) => {
    return (
        <form
            id={id}
            className={cn(
                'dark:bg-background border-border bg-widget sticky top-20 flex h-full max-h-[64rem] flex-col rounded-xl border px-4 py-6',
                className
            )}
            {...props}
        >
            <div className={cn('-mx-2 flex flex-1 flex-col overflow-hidden px-2')}>{children}</div>
        </form>
    );
};

export const DocumentFlowFormContainerHeader = ({ title, description }) => {
    return (
        <>
            <h3 className="text-foreground text-2xl font-semibold">{title}</h3>

            <p className="text-muted-foreground mt-2 text-sm">{description}</p>

            <hr className="border-border mb-8 mt-4" />
        </>
    );
};

export const DocumentFlowFormContainerContent = ({ children, className, ...props }) => {
    return (
        <div className={cn(' -mx-2 flex flex-1 flex-col overflow-x-hidden px-2', className)} {...props}>
            <div className="flex flex-1 flex-col">{children}</div>
        </div>
    );
};

export const DocumentFlowFormContainerFooter = ({ children, className, ...props }) => {
    return (
        <div className={cn('mt-4 flex-shrink-0', className)} {...props}>
            {children}
        </div>
    );
};
export const DocumentFlowFormContainerStep = ({ step, maxStep }) => {
    return (
        <div>
            <p className="text-muted-foreground text-sm">
                Step <span>{`${step} of ${maxStep}`}</span>
            </p>

            <div className="bg-muted relative mt-4 h-[2px] rounded-md">
                <motion.div
                    layout="size"
                    layoutId="document-flow-container-step"
                    className="bg-documenso absolute inset-y-0 left-0"
                    style={{
                        width: `${(100 / maxStep) * step}%`
                    }}
                />
            </div>
        </div>
    );
};

export const DocumentFlowFormContainerActions = ({
    _canGoBack = true,
    canGoNext = true,
    goNextLabel = 'Continue',
    _goBackLabel = 'Go Back',
    _onGoBackClick,
    onGoNextClick,
    loading,
    disabled
}) => {
    return (
        <div className="mt-4 flex gap-x-4">
            <button
                type="button"
                className="bg-documenso flex-1"
                size="lg"
                disabled={disabled || loading || !canGoNext}
                loading={loading}
                onClick={onGoNextClick}
            >
                {goNextLabel}
            </button>
        </div>
    );
};
