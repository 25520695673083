/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleProfileUpdating } from 'store/global/globalReducer';
import { addAppThunk } from 'store/workspace/workspaceApps';
import { toastHandler } from 'responseHanlder';
import { ERROR_TYPE_SUCCESS, USER_TYPE, acceptImages } from 'utills/globalVars';
import { SmallLoaderWhite } from 'components/common/SmallLoaderWhite';
import { ImageCropper } from 'global-components/ImageCropper';
import { DataURIToBlob } from 'utills/dataValidation';
import { DragFile } from 'components/atoms/inputs/DragFile';
import SelectedFiles from 'components/molecules/SelectedFiles';
import { UploadPhotoIcon } from 'utills/svgs/UploadPhotoIcon';
import WebsiteLinkInput from 'components/atoms/inputs/WebsiteLinkInput';
import { DescriptionInput } from 'components/molecules/DescriptionInput';
import Slideover from 'components/atoms/SlideOver';

const AddApp = ({ open, setOpen, toggleShowAddApp, toggleAppsUpdated }) => {
    const dispatch = useDispatch();

    const { profileUpdating } = useSelector((state) => state.global);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [link, setLink] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [appLoader, setAppLoader] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [appFile, setAppFile] = useState(null);
    const [showCroper, setShowCroper] = useState(false);

    const handleFileSelect = (files) => {
        const file = files[0];
        setSelectedFile(files);
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setSelectedImage(reader.result);
                setAppFile(reader.result);
            };

            reader.readAsDataURL(file);
            setShowCroper(true);
        }
    };

    const addApp = (e) => {
        e.preventDefault();

        if (linkError) {
            toastHandler('invalid link', 'error');
            return;
        }
        if (!link) {
            toastHandler('Please enter a link', 'error');
            return;
        }
        if (!link?.includes('.')) {
            toastHandler('Please enter a valid link. ', 'error');
            return;
        }
        const formData = new FormData();

        if (appFile) {
            const file = DataURIToBlob(selectedImage);
            formData.append('image', file, 'image.jpg');
        }

        formData.append('uploaded_by', USER_TYPE);
        formData.append('name', name.trim());
        formData.append('description', description.trim());
        formData.append('link', link.trim());

        setAppLoader(true);
        dispatch(addAppThunk(formData))
            .then((response) => {
                if (response.payload) {
                    toastHandler('App added successfully', ERROR_TYPE_SUCCESS);
                    toggleAppsUpdated();
                    handleProfileUpdating(!profileUpdating);
                    toggleShowAddApp();
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setAppLoader(false);
            });
    };

    const [linkError, setLinkError] = useState(false);
    const handleAppLink = (value) => {
        setLink(value);
    };

    const handleSelectedFiles = () => {
        setSelectedFile(null);
        setSelectedImage(null);
    };

    const saveImage = (image) => {
        setSelectedImage(image);
    };

    const croperHeightWidth = 150;

    return (
        <>
            <Slideover title="Add New App" open={open} setOpen={setOpen} showLoader hideBackArrow>
                {/* <div className="jumbo-dir">
                    Workspace &gt; Apps <span className="special-jumbo-text"> &gt; Add New App</span>
                </div> */}
                <form onSubmit={addApp}>
                    {' '}
                    <div>
                        <div>
                            <div className="add-ann-form mt-5 px-5">
                                <div>
                                    <div>
                                        <label>App Name</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            maxLength={40}
                                            placeholder="App Name"
                                            className="px-3 rounded-xl mt-1 placeholder:text-sm "
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <div>
                                        <label>App Link</label>
                                    </div>
                                    <WebsiteLinkInput onChange={(e) => handleAppLink(e)} value={link} required={true} />
                                </div>

                                <div className="mt-2">
                                    <DescriptionInput
                                        title="Description"
                                        placeholder="Enter App description"
                                        onChange={(e) => setDescription(e)}
                                        value={description}
                                        maxCount={80}
                                    />
                                </div>

                                <div className="mt-2">
                                    <div>
                                        <label>Upload App Image (optional)</label>
                                    </div>

                                    <div className="mt-2">
                                        <DragFile
                                            iconSet={{ icon: UploadPhotoIcon }}
                                            atChange={(file) => {
                                                handleFileSelect(file);
                                            }}
                                            accept={acceptImages}
                                            selectedFiles={selectedFile}
                                            isMultiple={false}
                                            hideSelectedFiles
                                            dragMessage="Drag files here to upload image, or browse files"
                                            dropMessage="Drop the selected files in this box... "
                                            onlyPdf={false}
                                            message="Only images are allowed"
                                        />
                                        <SelectedFiles
                                            files={selectedFile}
                                            updateSelectedFiles={(files) => setSelectedFile(files || [])}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center my-5">
                        <button
                            disabled={appLoader ? true : false}
                            type="submit"
                            className="ann-btn px-5 md:px-20 py-2 cursor-pointer text-white rounded-lg"
                        >
                            {appLoader ? <SmallLoaderWhite /> : 'Upload'}
                        </button>
                    </div>{' '}
                </form>
                {appFile && showCroper ? (
                    <>
                        <ImageCropper
                            width={croperHeightWidth}
                            height={croperHeightWidth}
                            setShowCropper={setShowCroper}
                            image={appFile}
                            handleCroppedImage={(image) => saveImage(image)}
                        />
                    </>
                ) : (
                    ''
                )}
            </Slideover>
        </>
    );
};

export default AddApp;
