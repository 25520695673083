/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slideover from 'components/atoms/SlideOver';
import { Loader } from 'components/common/Loader';
import { setLoader } from 'store/global/globalReducer';
import { toastHandler } from 'responseHanlder';
import { BUSINESS_ID, TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from 'utills/globalVars';
import { checkCollaborationEmailExistThunk, saveCollaborationEmailExistThunk } from 'store/JobManager';
import { PrimaryLabel } from 'components/atoms/typography/labels/PrimaryLabel';
import { InputField } from 'components/atoms/inputs/InputField';
import { SecondaryParagraph } from 'components/atoms/typography/paragraphs/SecondaryParagraph';
import { getBusinessprofileThunk } from 'store/auth/slices';
import useRoleGuard from 'Hooks/useRoleGuard';

const CollaborationManager = ({ open, setOpen, onSubmitHandler }) => {
    const dispatch = useDispatch();
    const [taskLoader, setTaskLoader] = useState(false);
    const { isLoading } = useSelector((state) => state.global);
    const { data: getBusinessprofile } = useSelector((state) => state.getBusinessprofile);
    const [emailError, setEmailError] = useState(null);
    const [email, setEmail] = useState('');
    const { hasPermissions } = useRoleGuard();
    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        const handleTypingStopped = () => {
            handleCheckEmailExistance(signal);
        };
        const timeoutId = setTimeout(handleTypingStopped, 500);
        return () => {
            clearTimeout(timeoutId);
            controller.abort();
        };
    }, [email]);

    const business_id = localStorage.getItem(BUSINESS_ID);

    useEffect(() => {
        if (!getBusinessprofile) {
            fetchBusinessDef();
        } else {
            setEmail(
                getBusinessprofile?.business_email ? getEmailNameFromBusiness(getBusinessprofile?.business_email) : ''
            );
        }
    }, [getBusinessprofile]);

    const getEmailNameFromBusiness = (emailInput) => {
        return emailInput?.slice(0, emailInput?.indexOf('@'));
    };

    const fetchBusinessDef = () => {
        dispatch(setLoader(true));
        dispatch(getBusinessprofileThunk()).finally(() => {
            dispatch(setLoader(false));
        });
    };

    const handleCheckEmailExistance = (_signal) => {
        if (email !== '' && email !== getEmailNameFromBusiness(getBusinessprofile?.business_email)) {
            dispatch(setLoader(true));
            dispatch(checkCollaborationEmailExistThunk({ business_email: email + '@practices.synkli.com.au' }))
                .then((response) => {
                    if (response.payload) {
                        setEmailError('Email is already Exist');
                    } else {
                        setEmailError(null);
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }
    };

    const handleCreate = () => {
        if (!hasPermissions('Job', ['admin'])) {
            return toastHandler("You don't have permission to perform this action", TOAST_TYPE_ERROR);
        }

        if (email === '') {
            return toastHandler('Email Name is Required', TOAST_TYPE_ERROR);
        }
        if (email !== '' && !emailError) {
            setTaskLoader(true);
            const payload = {
                business_email: email + '@practices.synkli.com.au',
                business_id: business_id
            };
            dispatch(setLoader(true));
            dispatch(saveCollaborationEmailExistThunk(payload))
                .then((response) => {
                    if (response.payload) {
                        toastHandler('Successfully Updated!', TOAST_TYPE_SUCCESS);
                        if (onSubmitHandler) {
                            onSubmitHandler();
                        }
                        setOpen(false);
                    }
                })
                .catch((_error) => {
                    toastHandler('Somethng went wrong!', TOAST_TYPE_ERROR);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                    setTaskLoader(false);
                });
        } else {
            toastHandler('Please Resolve Errors First', TOAST_TYPE_ERROR);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <Slideover
                open={open}
                setOpen={setOpen}
                hideBackArrow
                footer={
                    <div className="mt-10 flex justify-center gap-5 items-center bg-white py-2">
                        <button
                            disabled={taskLoader ? true : false}
                            type="submit"
                            className="ann-btn text-white md:px-20 py-2 flex gap-2 items-center cursor-pointer  rounded-xl"
                            onClick={handleCreate}
                        >
                            <span>Save</span>
                        </button>
                    </div>
                }
            >
                <h3 className="flex text-3xl font-semibold leading-9 text-slate-900 break-words max-w-[80%] font-fira">
                    Collaboration Manager
                </h3>

                <div className="add-status-container1">
                    <div className="add-ann-form flex flex-col gap-5 mt-8 mb-5">
                        <div>
                            <div>
                                <PrimaryLabel>Email</PrimaryLabel>
                            </div>
                            <div className="flex items-baseline gap-2">
                                <div className="flex-1 !w-full">
                                    <InputField
                                        maxLength={30}
                                        onChange={(e) => setEmail(e?.target?.value)}
                                        allowSpaces={false}
                                        allowCharatersOnly={false}
                                        preventSpecialCharactors={true}
                                        name={'text'}
                                        inputValue={email}
                                        placeholder={'Enter Email Name'}
                                        classes={emailError ? '!border-red-500' : ' '}
                                    />
                                </div>
                                <PrimaryLabel sx="text-sm text-primary">@practices.synkli.com.au</PrimaryLabel>
                            </div>
                            <SecondaryParagraph sx={'ml-1 mt-1 text-xs text-red-600'}>{emailError}</SecondaryParagraph>
                        </div>
                    </div>
                </div>
            </Slideover>
        </>
    );
};

export default CollaborationManager;
