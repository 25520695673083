/* eslint-disable no-unused-vars */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useRef, useState } from 'react';
import Slideover from 'components/atoms/SlideOver';
import { TasksIconDashboard } from 'utills/svgs/TasksIconDashboard';
import RentalPropertyIcon from 'utills/svgs/RentalPropertyIcon';
import { useDispatch, useSelector } from 'react-redux';
import { PAGINATION_PAGE_SIZE } from 'utills/envVars';
import {
    getAllNotificationsThunk,
    getNotificationCount,
    markNotificationAsReadThunk
} from 'store/workspace/workspaceNotifications';
import InfiniteScrollComp from 'components/atoms/InfiniteScrollComp';
import SkeletonTable from 'components/atoms/SkeletonTable';
import { AbnExpenseIcon } from 'utills/svgs/AbnExpenseIcon';
import { Link, useNavigate } from 'react-router-dom';
import { handleRecentNotification } from 'store/global/globalReducer';
import { BgGSTIcon } from 'utills/svgs/BgGSTIcon';
import { FileIconSvg } from 'utills/svgs/FileIconSvg';
import { RolsSvg } from 'utills/svgs/RolsSvg';
import { ClientsActive } from 'utills/svgs/ClientsActive';
import moment from 'moment';
import { cn } from 'utills/tailwindUtil';
import NotificationLink from 'components/atoms/NotificationLink';
import AddAnouncementsIcon from 'utills/svgs/AddAnouncementsIcon';
import AddTDIcon from 'utills/svgs/AddTDIcon';
import { CodeIcon } from 'utills/svgs/CodeIcon';
import { MessagesIcon } from 'utills/svgs/MessagesIcon';
import { MobileIcon } from 'utills/svgs/MobileIcon';
import { AddDeductionIcon } from 'utills/svgs/AddDeductionIcon';
import { SearchInput } from 'components/molecules/SearchInput';

import InfiniteScroll from 'react-infinite-scroll-component';
import { JobsIcon } from 'utills/svgs/JobsIcon';

const NotificationSlider = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { recentNotification } = useSelector((state) => state.global);

    const [filters, setFilters] = useState({
        search_term: ''
    });
    const [activeTab, setActiveTab] = useState('all');

    const [hasMoreContent, setHasMoreContent] = useState({
        all: true,
        filtered: false
    });

    const [allPage, setAllPage] = useState(1);
    const [filteredPage, setFilteredPage] = useState(1);

    const [allPageData, setAllPageData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);

    useEffect(() => {
        if (recentNotification) {
            setAllPageData([recentNotification, ...(allPageData || [])]);
            dispatch(handleRecentNotification(null));
        }
    }, [recentNotification]);

    useEffect(() => {
        getNotificationData('all');
    }, [allPage]);

    useEffect(() => {
        const hasValue = Object.values(filters).some((value) => value !== '');
        if (hasValue) {
            getNotificationData('filtered');
        } else {
            setActiveTab('all');
        }
    }, [filteredPage, filters]);

    const handlePageChange = () => {
        if (activeTab === 'all') {
            setAllPage(allPage + 1);
        } else if (activeTab === 'filtered') {
            setFilteredPage(filteredPage + 1);
        }
    };

    const getNotificationData = async (type, hasReset = false) => {
        let page;
        let dataSetter;
        let pageSetter;
        let stateData;

        switch (type) {
            case 'all':
                page = allPage;
                dataSetter = setAllPageData;
                stateData = allPageData;
                pageSetter = 'all';
                break;
            case 'filtered':
                page = filteredPage;
                dataSetter = setFilteredData;
                stateData = filteredData;
                pageSetter = 'filtered';
                break;
            default:
                return;
        }

        const payload = {
            page: page,
            page_size: Number(PAGINATION_PAGE_SIZE),
            search_term: filters?.search_term || ''
        };

        setHasMoreContent({ ...hasMoreContent, [pageSetter]: true });

        try {
            const response = await dispatch(getAllNotificationsThunk(payload));
            if (response.payload) {
                const cloneData = [...(!hasReset ? stateData || [] : []), ...response.payload.rows];
                dataSetter(cloneData);
                setHasMoreContent({
                    ...hasMoreContent,
                    [pageSetter]: cloneData.length >= response.payload.count ? false : true
                });
            }
        } catch (error) {
            console.error('Error fetching businesses:', error);
        }
    };

    const dataLength =
        (() => {
            if (activeTab === 'all') {
                if (allPageData?.length === 0) {
                    return hasMoreContent?.all ? 1 : 0;
                } else {
                    return allPageData?.length;
                }
            } else {
                if (filteredData?.length === 0) {
                    return hasMoreContent?.filtered ? 1 : 0;
                } else {
                    return filteredData?.length;
                }
            }
        })() || 0;

    const hasMore =
        activeTab === 'all'
            ? allPageData?.length !== 0 && hasMoreContent?.all
            : filteredData?.length !== 0 && hasMoreContent?.filtered;

    const displayIcon = (type) => {
        switch (type) {
            case 'task_assign':
            case 'task_completed':
            case 'task_delayed':
                return <TasksIconDashboard className={'w-5'} />;
                break;
            case 'rentalProperty':
                return <RentalPropertyIcon className={'w-6'} />;
                break;
            case 'summary_sent':
                return <AbnExpenseIcon className={'w-5 fill-white'} />;
                break;
            case 'document_for_signature':
            case 'document_signed_submitted':
            case 'document_for_signature_job':
                return <BgGSTIcon className={'w-16 inside-fill-white'} />;

            case 'customer_form_submission':
            case 'file_upload_accountant_folder':
                return <FileIconSvg className={'w-5 inside-fill-white'} />;
            case 'employee_permission_updated':
                return <RolsSvg className={'w-6 inside-fill-white'} />;
            case 'employee_invitation_accepted':
            case 'client_invitation_accepted':
                return <ClientsActive className={'w-6 !brightness-[4] inside-fill-white'} />;
                break;
            case 'ws_news':
                return <AddAnouncementsIcon className={'!w-10'} />;
                break;
            case 'ws_qr_code':
                return <CodeIcon className={'scale-125'} />;
                break;
            case 'ws_thread':
                return <MessagesIcon className={'scale-100'} />;
                break;
            case 'ws_app':
                return <MobileIcon className={'scale-125'} />;
                break;
            case 'ws_deduction':
                return <AddDeductionIcon className={'scale-100'} />;
                break;
            case 'ws_telephonic_directory':
                return <AddTDIcon className={'!w-10'} />;
                break;
            case 'document_requested':
            case 'delayed_document_requested':
            case 'delayed_document_rejected':
            case 'delayed_document_completed':
            case 'document_requestes_uploaded':
            case 'document_requestes_rejected_uploaded':
            case 'document_requestes_pending':
            case 'new_message_from_client':
            case 'reply_message_from_client':
            case 'new_message_from_accountant':
            case 'reply_message_from_accountant':
            case 'new_job_created':
            case 'job_passed_deadline':
            case 'review_pending_job':
            case 'reminder_complete_job':
            case 'reminder_complete_overdue_job':
                return <JobsIcon className={'!w-10 fill-white'} />;
                break;
            default:
                break;
        }
    };

    const handleNotifyAction = async (notify_id = 'all', url = null) => {
        const response = await dispatch(markNotificationAsReadThunk({ notification_id: notify_id }));

        if (notify_id === 'all' && response.payload === true) {
            setAllPageData((preData) => {
                return (
                    preData &&
                    preData.map((pre_obj) => ({
                        ...pre_obj,
                        is_read: true
                    }))
                );
            });
            dispatch(getNotificationCount.actions.handleUpdate({ count: 0 }));
        }
        if (url) {
            let path = url.replace(/^https?:\/\/[^/]+/, '');
            navigate(path);
        }
    };

    const generateState = (type) => {
        switch (type) {
            case 'rentalProperty':
                return {
                    summary_id: 'a220b17c-9f35-43a3-81c3-10e3c8c8c92e'
                };
                break;

            case 'customer_form_submission':
                return {};
                break;

            default:
                return {};
                break;
        }
    };
    const scrollParentRef = useRef();

    return (
        <>
            <Slideover id={'scrollableDiv'} open={open} setOpen={setOpen} title={'Notifications'} hideBackArrow>
                <InfiniteScroll
                    height={'calc(100vh - 100px)'}
                    style={{
                        transform: 'translate(10px, 0px)'
                    }}
                    next={handlePageChange}
                    dataLength={dataLength}
                    hasMore={hasMore}
                    loader={<SkeletonTable columns={1} />}
                >
                    <div className="pr-4">
                        <div className="flex items-center justify-between flex-wrap border-b-2 border-gray-300 my-3 pb-2 mt-6">
                            <div className="my-2">
                                <SearchInput
                                    atChange={(e) => {
                                        if (e.target.value !== '') {
                                            setActiveTab('filtered');
                                            setFilteredPage(1);
                                            setFilteredData([]);
                                        } else {
                                            setActiveTab('all');
                                        }
                                        setFilters({ ...filters, search_term: e.target.value });
                                    }}
                                    placeholder={'Search'}
                                />
                            </div>
                            <span
                                className="text-sm text-gray-700 underline underline-offset-4 cursor-pointer"
                                onClick={() => handleNotifyAction()}
                            >
                                Mark all as read
                            </span>
                        </div>

                        <ul role="list">
                            {(activeTab === 'all' ? allPageData && allPageData : filteredData || [])?.map(
                                (notifyObj, index) => (
                                    <li
                                        key={notifyObj?.notification_id || 'notify_' + index}
                                        className={cn(
                                            'relative flex justify-between gap-x-6 py-5 cursor-pointer px-2 rounded-lg my-2',
                                            !notifyObj?.is_read
                                                ? 'bg-[#c8affa2b] summary-bg-color_'
                                                : 'hover:bg-gray-50' //dccdf921
                                        )}
                                    >
                                        <Link
                                            to={notifyObj?.url || undefined}
                                            state={generateState(notifyObj?.type)}
                                            className="flex min-w-0 gap-x-4 w-full"
                                            onClick={() => handleNotifyAction(notifyObj?.notification_id)}
                                        >
                                            <div className="relative h-fit">
                                                {notifyObj?.imageUrl ? (
                                                    <img
                                                        className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                                        src={notifyObj?.imageUrl}
                                                        alt={notifyObj?.imageUrl}
                                                    />
                                                ) : (
                                                    <span className=" bg-third rounded-full flex items-center justify-center h-12 w-12 flex-none cursor-pointer">
                                                        {displayIcon(notifyObj?.type)}
                                                    </span>
                                                )}
                                                {!notifyObj?.is_read && (
                                                    <div className="absolute h-2 w-2 rounded-full bg-primary shadow-md right-1 ring-1 ring-white top-[0px] "></div>
                                                )}
                                            </div>

                                            <div className="min-w-0 flex-auto">
                                                <div className="flex items-center justify-between">
                                                    <p className="text-md font-semibold leading-6 text-gray-900">
                                                        <span className="absolute inset-x-0 -top-px bottom-0" />
                                                        {notifyObj?.title}
                                                    </p>
                                                    <span className="text-xs text-gray-500 px-2">
                                                        {moment(
                                                            notifyObj?.created_at,
                                                            'DD-MM-YYYYTHH:mm:ssZ'
                                                        ).fromNow()}
                                                    </span>
                                                </div>
                                                <p className="mt-1 flex text-sm leading-5 text-gray-800">
                                                    <span className="relative">{notifyObj?.message}</span>
                                                </p>
                                            </div>
                                        </Link>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </InfiniteScroll>
            </Slideover>
        </>
    );
};

export default NotificationSlider;
