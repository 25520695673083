import React from 'react';

export const EditIcon2 = ({ className }) => {
    return (
        <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="#0A1E46"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.49441 13.8887L12.9943 4.38881C12.9943 4.38881 12.9943 4.3888 12.9943 4.3888C13.1318 4.25128 13.209 4.06479 13.209 3.87034C13.209 3.67589 13.1318 3.48941 12.9943 3.35189L3.49441 13.8887ZM3.49441 13.8887H0.1V10.4943L9.59987 0.994397C9.59987 0.994394 9.59987 0.99439 9.59988 0.994387C9.7374 0.856915 9.92388 0.779688 10.1183 0.779688C10.3128 0.779688 10.4993 0.856915 10.6368 0.994387C10.6368 0.99439 10.6368 0.994394 10.6368 0.994397L12.9943 3.35188L3.49441 13.8887ZM2.845 12.422H2.88642L2.91571 12.3927L11.3674 3.94105L11.4381 3.87034L11.3674 3.79963L10.189 2.6213L10.1183 2.55059L10.0476 2.6213L1.59596 11.073L1.56667 11.1023V11.1437V12.322V12.422H1.66667H2.845ZM0.1 15.7553H14.9V17.222H0.1V15.7553Z"
                stroke="white"
                strokeWidth="0.2"
            />
        </svg>
    );
};
