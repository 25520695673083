import React, { useState } from 'react';
import '../css/atoms.css';
export const TrippleSwitch = ({ titleOne, titleTwo, titleThree, titleFour, atClick, border, sxOne }) => {
    const [selectedItem, setSelectedItem] = useState(titleOne);

    const handleSelect = (title) => {
        setSelectedItem(title);
        atClick(title);
    };
    return (
        <div
            style={{ border: border && border }}
            className="flex gap-1 items-center tripple-switch1 mx-5 rounded-xl justify-between"
        >
            <div
                onClick={() => handleSelect(titleOne)}
                className={`tripple-switch-child cursor-pointer min-w-[150px] !px-3 lg:!px-6 xl:!px-10 ${
                    selectedItem === titleOne && 'tripple-switch-child-selected'
                } ${sxOne || ''}`}
            >
                {titleOne}
            </div>
            <div
                onClick={() => handleSelect(titleTwo)}
                className={`tripple-switch-child cursor-pointer min-w-[150px] !px-3 lg:!px-6 xl:!px-10 ${
                    selectedItem === titleTwo && 'tripple-switch-child-selected'
                }`}
            >
                {titleTwo}
            </div>
            <div
                onClick={() => handleSelect(titleThree)}
                className={`tripple-switch-child cursor-pointer min-w-[150px] !px-3 lg:!px-6 xl:!px-10 ${
                    selectedItem === titleThree && 'tripple-switch-child-selected'
                } `}
            >
                {titleThree}
            </div>
            {titleFour && (
                <div
                    onClick={() => handleSelect(titleFour)}
                    className={`tripple-switch-child cursor-pointer !px-3 lg:!px-6 xl:!px-10 ${
                        selectedItem === titleFour ? 'tripple-switch-child-selected' : ''
                    } `}
                >
                    {titleFour}
                </div>
            )}
        </div>
    );
};
