import { GridCard } from 'components/molecules/GridCard';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LockChangeIcon } from 'utills/LockChangeIcon';
import { ArrowLeft } from 'utills/svgs/ArrowLeft';
import { MultiFactorIcon } from 'utills/svgs/MultiFactorIcon';

export const SecurityAndAuthentication = () => {
    const navigate = useNavigate();
    const backArrowProp = {
        icon: ArrowLeft,

        atClick: () => {
            navigate(`/settings`);
        }
    };
    return (
        <div>
            <SidebarLayout>
                <div>
                    <Jumbotron
                        title="Security and Authentication"
                        directory={`Settings`}
                        path={[
                            { title: 'Settings', link: 'settings' },
                            {
                                title: 'Security and Authentication',
                                link: 'security-and-authentication'
                            }
                        ]}
                        backArrowProp={backArrowProp}
                    />
                    <div className="mx-5 md:mx-10">
                        <div className="flex gap-5 items-center flex-wrap">
                            <GridCard
                                text="Change Password"
                                link={'/change-password'}
                                iconSet={{ icon: LockChangeIcon }}
                            />

                            <GridCard
                                text="Multi factor Authentication"
                                link={'/multi-factor-auth'}
                                iconSet={{ icon: MultiFactorIcon }}
                            />
                        </div>
                    </div>
                </div>
            </SidebarLayout>
        </div>
    );
};
