import React from 'react';

export const UnderlineIcon = () => {
    return (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.19995 3V0H1.19995V3V6V7.5C1.19995 10.5376 3.66238 13 6.69995 13C9.73752 13 12.2 10.5376 12.2 7.5V6V3V0H10.2V3V6V7.5C10.2 9.433 8.63295 11 6.69995 11C4.76695 11 3.19995 9.433 3.19995 7.5V6V3ZM1.19995 15C0.647666 15 0.199951 15.4477 0.199951 16C0.199951 16.5523 0.647666 17 1.19995 17H12.2C12.7522 17 13.2 16.5523 13.2 16C13.2 15.4477 12.7522 15 12.2 15H1.19995Z"
                fill="#656565"
            />
        </svg>
    );
};
