export function formatFileSize(sizeInKB) {
    var sizeInMB = sizeInKB / 1024.0;

    if (sizeInMB < 1) {
        return Number(sizeInKB)?.toFixed(2) + ' KB';
    } else {
        return Number(sizeInMB)?.toFixed(2) + ' MB';
    }
}

export function filterByType(data, filterType) {
    if (!Array.isArray(data)) {
        throw new Error('Input must be an array');
    }

    const validTypes = ['jpg', 'png', 'pdf'];

    if (!validTypes.includes(filterType)) {
        throw new Error('Invalid filter type');
    }

    return data.filter((obj) => {
        switch (filterType) {
            case 'jpg':
                return obj.file_type === 'image/jpeg' || obj.file_type === 'image/jpg';
            case 'png':
                return obj.file_type === 'image/png';
            case 'pdf':
                return obj.file_type === 'application/pdf';
            default:
                return false;
        }
    });
}

export function kbsToGb(kilobytes) {
    /**
     * Convert kilobytes to gigabytes.
     *
     * @param {number} kilobytes - The amount of kilobytes to be converted.
     * @returns {number} The equivalent amount in gigabytes.
     */
    const gigabytes = kilobytes / (1024 * 1024);
    return gigabytes;
}

export function bytesToMB(bytes) {
    if (typeof bytes !== 'number') {
        return 'Invalid input, please provide a numeric value';
    }

    if (bytes < 1024) {
        return bytes.toFixed(2) + ' B';
    } else {
        const kilobytes = bytes / 1024;
        if (kilobytes < 1024) {
            return kilobytes.toFixed(2) + ' KB';
        } else {
            const megabytes = kilobytes / 1024;
            return megabytes.toFixed(2) + ' MB';
        }
    }
}

export function formatFileSizekbs(kilobytes) {
    if (typeof kilobytes !== 'number') {
        return 'Invalid input, please provide a numeric value';
    }

    if (kilobytes < 1024) {
        return kilobytes.toFixed(2) + ' KB';
    } else if (kilobytes < 1024 * 1024) {
        const megabytes = kilobytes / 1024;
        return megabytes.toFixed(2) + ' MB';
    } else {
        const gigabytes = kilobytes / (1024 * 1024);
        return gigabytes.toFixed(2) + ' GB';
    }
}

export const downloadFile = (url) => {
    fetch(url, {
        method: 'GET',
        responseType: 'blob'
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then((blob) => {
            const urlParts = url.split('/');
            const fileName = urlParts[urlParts.length - 1];

            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(downloadUrl);
        })
        .catch((error) => console.error('Error downloading file:', error));
};

export function base64ToBlob(base64) {
    const contentType = base64.match(/data:([^;]+);base64,/)[1];

    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const getFileExtenstion = (file) => {
    return file.name.split('.').pop().toLowerCase();
};
