/* eslint-disable no-unused-vars */
import { DefaultProfileViewer } from 'components/molecules/DefaultProfileViewer';
import React from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from '@ramonak/react-progress-bar';
import { PointRightIcon } from 'utills/svgs/PointRightIcon';
import { Link, useNavigate } from 'react-router-dom';

export const TopEmployees = () => {
    const { data } = useSelector((state) => state.fetchRegisteredEmployees);
    const colors = ['#DC7B7B', '#2AD4AF', ' #657CEE', '#92D268', '#b695f8'];
    const navigate = useNavigate();

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    const randomBgColor = getRandomColor();

    const getPercentage = (completed, assigned) => {
        return (completed / assigned) * 100;
    };
    const handleNavigate = (business_employee_id) => {
        navigate(`/employee/tasks/${business_employee_id}`);
    };
    return (
        <div className="p-8">
            <div className="xl-title font-fira  mt-1">All Employees</div>
            {data?.map((user, index) => (
                <Link
                    to={`/employee/tasks/${user?.user_id}`}
                    className={`grid grid-cols-2 gap-5 shadow-lg rounded-xl cursor-pointer  hover:bg-[#f5f5f5] ${
                        index < 1 && 'mt-5'
                    }`}
                    key={index}
                >
                    <div className="p-5 ">
                        <div className="flex gap-2 items-center w-full">
                            <DefaultProfileViewer
                                image={user?.image}
                                width="55px"
                                height="55px"
                                name={user?.first_name + ' ' + user?.last_name}
                            />
                            <div className="w-full">
                                <div className="buttons-font font-poppins">
                                    {user?.first_name} {user?.last_name}
                                </div>
                                <div className="flex gap-5 justify-between flex-wrap w-full">
                                    <div className="small-text font-[400] font-poppins">{user?.email}</div>
                                    <div className="small-text font-[400] font-poppins">
                                        {user?.task_completed}/{user?.task_assign}
                                    </div>
                                </div>
                                <div className="mt-4 w-full">
                                    <ProgressBar
                                        completed={getPercentage(user?.task_completed, user?.task_assign)}
                                        bgColor={
                                            index < 1
                                                ? colors[0]
                                                : index === 1
                                                ? colors[1]
                                                : index === 2
                                                ? colors[2]
                                                : index === 3
                                                ? colors[3]
                                                : index === 4
                                                ? colors[4]
                                                : colors[5]
                                        }
                                        height="5px"
                                        width="100%"
                                        animateOnRender={true}
                                        isLabelVisible={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-5 w-full">
                        <div className="flex gap-2 items-center">
                            <div className=" flex items-center justify-between  gap-3 mt-5 px-3 w-[90%]">
                                <div>
                                    {' '}
                                    <div className="assigned-tasks small-text font-poppins">
                                        <div>Assigned Task </div>
                                        <div className="emp-task-count table-title font-poppins">
                                            {' '}
                                            {user?.task_assign ? user.task_assign : 0}{' '}
                                        </div>{' '}
                                    </div>{' '}
                                </div>
                                <div className="completed-tasks small-text font-poppins">
                                    {' '}
                                    <div>Completed Task </div>{' '}
                                    <div className="emp-task-count table-title font-poppins">
                                        {' '}
                                        {user?.task_completed ? user.task_completed : 0}{' '}
                                    </div>
                                </div>
                                <div className="delayed-tasks small-text font-poppins">
                                    {' '}
                                    <div> Delayed Task </div>{' '}
                                    <div className="emp-task-count table-title font-poppins">
                                        {user?.task_overdue ? user.task_overdue : 0}{' '}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <PointRightIcon />
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};
