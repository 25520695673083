/* eslint-disable react-hooks/exhaustive-deps */
import { NoDataFound } from 'components/common/NoDataFound';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTaskHistoryThunk } from 'store/workspace/workspaceTasks';
import { convertDateFormatToLocal } from 'utills/moment';
import { generateId } from 'utills/uid';

export const TaskHistory = ({ taskId }) => {
    const dispatch = useDispatch();
    const { data } = useSelector((state) => state.getTaskHistory);

    useEffect(() => {
        dispatch(getTaskHistoryThunk({ task_id: taskId }));
    }, []);
    return (
        <div className="task-detail-main-card  mt-5 mx-5 p-5 rounded-lg relative">
            <div>
                <label className="text-lg font-semibold font-poppins px-2 text-blueish">Task History</label>
            </div>

            <div className="mt-7">
                <div className="grid grid-cols-5 gap-5 buttons-font font-poppins px-3">
                    <div className=" flex items-center  break-all">Employee Name</div>
                    <div className="flex  items-center break-all">Last Status</div>

                    <div className="flex  items-center break-all">New Status</div>
                    <div className="flex  items-center  break-all ">Date and Time</div>
                    <div className="flex  items-center  break-all">Shift Duration</div>
                </div>
                <hr className="mt-3" />
                {data?.length < 1 ? (
                    <NoDataFound height="30vh" message="No history found!." />
                ) : (
                    data?.map((item, index) => (
                        <div key={generateId() + index}>
                            <div className="grid grid-cols-5 gap-5 buttons-font-lighter font-poppins mt-3 px-3">
                                <div className=" flex items-center  break-all">{item?.name}</div>
                                <div className="flex  items-center  break-all">
                                    {item?.last_status ? item?.last_status : '-'}
                                </div>
                                <div className="flex  items-center  break-all">
                                    {item?.current_status ? item?.current_status : '-'}
                                </div>
                                <div className="flex items-center  break-all">
                                    {convertDateFormatToLocal(
                                        item?.updated_at,
                                        'DD-MM-YYYYTHH:mm:ssZ',
                                        'DD/MM/YYYY hh:mm:ss A'
                                    )}
                                </div>
                                <div className="flex  items-center  break-all">
                                    {item?.duration ? item?.duration : '-'}
                                </div>
                            </div>
                            <hr className="mt-3" />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
