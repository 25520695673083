import React from 'react';

export const SignIcon = ({ className }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="currentColor"
        >
            <g clipPath="url(#clip0_18500_2577)">
                <path d="M15.9745 3.50931L14.3192 5.16925L11.3934 6.90802C9.62222 7.96056 8.44913 8.68388 8.42595 8.73952C8.40277 8.79053 7.71653 11.2109 6.89583 14.1181L5.40281 19.404L5.09679 19.7193C4.52647 20.3081 4.13235 20.5029 3.49248 20.5029C2.22202 20.5029 1.27613 19.302 1.58679 18.0825C1.69807 17.6606 1.83254 17.4519 2.43995 16.7889C3.14009 16.0238 3.30701 15.8105 3.49712 15.4303C4.04425 14.35 3.95615 13.0239 3.27919 12.0223C3.19573 11.9018 2.68105 11.3454 2.12929 10.789L1.13239 9.78279L0.631625 10.2789L0.130859 10.775L1.1463 11.7998C1.95773 12.6205 2.18029 12.8708 2.2823 13.0841C2.51877 13.5942 2.51413 14.2479 2.27302 14.758C2.17565 14.962 1.95309 15.2495 1.49405 15.7549C0.75218 16.5709 0.552801 16.8538 0.367332 17.3406C-0.0963399 18.5508 0.15868 19.8769 1.03502 20.8089C1.44769 21.2494 1.83717 21.5137 2.34721 21.6992C3.36265 22.0701 4.52647 21.9264 5.40281 21.3236C5.52336 21.2401 5.80157 20.999 6.01949 20.795L6.41361 20.4194L11.5372 18.9449C14.3563 18.1335 16.7999 17.4334 16.9668 17.3824L17.2682 17.2896L18.8864 14.3592L20.5 11.4288L22.1877 9.73642L23.8709 8.04402L20.7735 4.94669C19.0719 3.24501 17.6716 1.854 17.6577 1.854C17.6437 1.854 16.888 2.60051 15.9745 3.50931ZM20.9358 8.98063L19.976 9.94507L17.8663 7.83537L15.7566 5.72566L16.7164 4.76586L17.6808 3.80142L19.7905 5.91113L21.9003 8.02083L20.9358 8.98063ZM19.0255 11.2387C18.9652 11.3454 18.3393 12.4721 17.6345 13.7472C16.9343 15.0223 16.3316 16.0887 16.2991 16.1165C16.2481 16.1629 8.51405 18.4535 8.4074 18.4535C8.38422 18.4535 9.26056 17.5586 10.3548 16.4597L12.3486 14.4659H13.0534H13.7628V13.2603V12.0548L12.5712 12.0641L11.3749 12.078L11.361 12.7967L11.3517 13.5153L10.4105 14.4566C8.56969 16.3066 7.36414 17.4473 7.40587 17.3082C7.42442 17.2386 7.92982 15.472 8.52332 13.3855L9.60368 9.59268L11.0689 8.72098C11.8757 8.2434 13.0117 7.5618 13.6005 7.21404L14.667 6.58345L16.9019 8.81835L19.1368 11.0532L19.0255 11.2387Z" />
            </g>
        </svg>
    );
};
