import React from 'react';

export const MileStoneIcon = ({ className, fill = '#0A1E46' }) => {
    return (
        <svg
            className={className}
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M43.0135 89.0641C66.2072 89.0641 85.0885 70.1828 85.0885 46.9891C85.0885 40.5578 83.626 34.3328 80.7572 28.4828C80.3072 27.5641 79.1822 27.1703 78.2447 27.6203C78.0228 27.7283 77.8244 27.8791 77.6609 28.0639C77.4974 28.2487 77.3719 28.4641 77.2918 28.6975C77.2117 28.9309 77.1784 29.1779 77.1939 29.4242C77.2094 29.6705 77.2734 29.9113 77.3822 30.1328C80.0072 35.4578 81.3385 41.1391 81.3385 46.9891C81.3385 68.1203 64.1447 85.3141 43.0135 85.3141C21.8822 85.3141 4.68848 68.1203 4.68848 46.9891C4.68848 25.8578 21.8822 8.66406 43.0135 8.66406C48.8635 8.66406 54.5447 9.99531 59.8697 12.6203C60.8072 13.0703 61.9322 12.6953 62.3822 11.7578C62.8322 10.8203 62.4572 9.71406 61.5197 9.24531C55.6697 6.37656 49.4447 4.91406 43.0135 4.91406C19.8197 4.91406 0.938477 23.7953 0.938477 46.9891C0.938477 70.1828 19.8197 89.0641 43.0135 89.0641Z"
                fill={fill}
            />
            <path
                d="M76.0888 26.1155C76.5763 26.1155 77.0638 25.9092 77.42 25.5717L88.52 14.4717C89.0638 13.928 89.2138 13.1217 88.9325 12.428C88.7894 12.082 88.5479 11.7857 88.2379 11.5757C87.9279 11.3657 87.5631 11.2513 87.1888 11.2467H78.7513V2.80921C78.7513 2.05921 78.3013 1.36546 77.5888 1.08421C76.895 0.802959 76.0888 0.952959 75.545 1.49671L64.445 12.5967C64.0888 12.953 63.8825 13.4405 63.9013 13.9467L64.0138 23.3592L41.7013 45.6717C41.4396 45.9336 41.2617 46.2673 41.1901 46.6304C41.1185 46.9936 41.1564 47.3699 41.299 47.7114C41.4417 48.053 41.6827 48.3445 41.9913 48.5489C42.2999 48.7532 42.6624 48.8612 43.0325 48.8592C43.52 48.8592 43.9888 48.6717 44.3638 48.3155L66.6763 26.003L76.0888 26.1155C76.07 26.1155 76.07 26.1155 76.0888 26.1155ZM67.7263 22.2717L67.6513 14.6967L75.0013 7.32796V13.1217C75.0013 14.153 75.845 14.9967 76.8763 14.9967H82.67L75.32 22.3467L67.7263 22.2717Z"
                fill={fill}
            />
            <path
                d="M45.6187 37.6297C45.8551 37.6962 46.1023 37.7154 46.3461 37.6864C46.5899 37.6574 46.8257 37.5806 47.0398 37.4605C47.254 37.3403 47.4424 37.1792 47.5942 36.9862C47.7461 36.7933 47.8585 36.5723 47.925 36.3359C47.9915 36.0996 48.0108 35.8524 47.9817 35.6086C47.9527 35.3647 47.8759 35.129 47.7558 34.9149C47.6356 34.7007 47.4745 34.5123 47.2815 34.3604C47.0886 34.2086 46.8676 34.0962 46.6313 34.0297C45.4313 33.6922 44.2125 33.5234 43.0125 33.5234C35.5688 33.5234 29.5312 39.5797 29.5312 47.0047C29.5312 54.4297 35.5875 60.4859 43.0125 60.4859C50.4563 60.4859 56.4937 54.4297 56.4937 47.0047C56.4937 45.8047 56.325 44.5859 55.9875 43.3859C55.921 43.1496 55.8086 42.9286 55.6567 42.7356C55.5049 42.5427 55.3165 42.3816 55.1023 42.2614C54.8882 42.1413 54.6524 42.0645 54.4086 42.0355C54.1648 42.0064 53.9176 42.0257 53.6812 42.0922C53.4449 42.1587 53.2239 42.2711 53.031 42.4229C52.838 42.5748 52.6769 42.7632 52.5567 42.9774C52.4366 43.1915 52.3598 43.4272 52.3308 43.6711C52.3017 43.9149 52.321 44.1621 52.3875 44.3984C52.6313 45.2797 52.7625 46.1609 52.7625 47.0234C52.7625 52.3859 48.3938 56.7547 43.0313 56.7547C37.6688 56.7547 33.3 52.3859 33.3 47.0234C33.2625 40.7609 39.2625 35.8484 45.6187 37.6297Z"
                fill={fill}
            />
            <path
                d="M58.4054 26.1141C58.9679 25.2328 58.7054 24.0891 57.8241 23.5266C53.3928 20.7019 48.2479 19.1994 42.9929 19.1953C27.6741 19.1953 15.2241 31.6641 15.2241 46.9641C15.2241 62.2828 27.6929 74.7516 42.9929 74.7516C58.2929 74.7516 70.7804 62.2828 70.7804 46.9641C70.7804 41.7328 69.2804 36.5953 66.4491 32.1328C65.8866 31.2516 64.7241 31.0078 63.8616 31.5516C62.9804 32.1141 62.7179 33.2578 63.2804 34.1391C65.7366 38.0016 67.0304 42.4266 67.0304 46.9453C67.0304 60.2016 56.2491 70.9828 42.9929 70.9828C29.7366 70.9828 18.9741 60.2016 18.9741 46.9453C18.9741 33.6891 29.7554 22.9266 42.9929 22.9266C47.5116 22.9266 51.9554 24.2203 55.7991 26.6766C56.006 26.8146 56.2385 26.9098 56.4828 26.9562C56.7271 27.0027 56.9783 26.9996 57.2214 26.9472C57.4645 26.8947 57.6945 26.7939 57.8979 26.6508C58.1013 26.5077 58.2739 26.3252 58.4054 26.1141Z"
                fill={fill}
            />
        </svg>
    );
};
