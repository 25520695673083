import React from 'react';
import '../../dashboard-css/auth-css/backupCodes.css';

import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { setLoader } from '../../../store/global/globalReducer';
import { generateBackupCodesThunk } from '../../../store/auth/slices';
import { Loader } from '../../common/Loader';
import { useNavigate } from 'react-router-dom';
import AuthCardLayout from 'layouts/AuthCardLayout';
const BackupCodesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.global.isLoading);
    const [codes, setCodes] = useState(null);
    const { data } = useSelector((state) => state.login);
    const [codesCopied, setCodesCopied] = useState(false);
    const [codesDownloaded, setCodesDoanloaded] = useState(false);

    const firstLineCodes = codes?.slice(0, 5);
    const lastLineCodes = codes?.slice(5, 10);

    const contentRef = useRef(null);
    const secondContentRef = useRef(null);

    //download content
    const handleDownload = () => {
        const paragraphs = contentRef.current.getElementsByTagName('p');
        const secondP = secondContentRef.current.getElementsByTagName('p');
        let content = '';
        for (let i = 0; i < paragraphs.length; i++) {
            content += paragraphs[i].textContent + ' , \r\n';
        }

        for (let i = 0; i < secondP.length; i++) {
            content += secondP[i].textContent + ' , \r\n';
        }

        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'content.txt';
        downloadLink.click();
        URL.revokeObjectURL(url);
        setCodesDoanloaded(true);
    };

    //copy content

    const handleMerge = () => {
        const paragraphs1 = contentRef.current.getElementsByTagName('p');
        const paragraphs2 = secondContentRef.current.getElementsByTagName('p');
        let content = '';
        for (let i = 0; i < paragraphs1.length; i++) {
            content += paragraphs1[i].textContent + ' , \r\n';
        }
        for (let i = 0; i < paragraphs2.length; i++) {
            content += paragraphs2[i].textContent + ' , \r\n';
        }

        navigator.clipboard
            .writeText(content)
            .then(() => {
                NotificationManager.success('Codes copied to clipboard', 'Success');
                setCodesCopied(true);
            })
            .catch((error) => {
                console.error('Failed to copy content to clipboard:', error);
            });
    };

    useEffect(() => {
        const codeConfig = {
            user_id: data?.user_id,
            user_type: 'employee'
        };
        dispatch(setLoader(true));

        setTimeout(() => {
            dispatch(generateBackupCodesThunk(codeConfig))
                .then((response) => {
                    dispatch(setLoader(false));
                    if (response.payload) {
                        setCodes(response.payload);
                    }
                })
                .catch((error) => {
                    dispatch(setLoader(false));
                    console.error(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        }, 500);
    }, []);

    const handleRegenrateCodes = async () => {
        const codeConfig = {
            user_id: data?.user_id,
            user_type: 'employee'
        };
        dispatch(setLoader(true));

        await dispatch(generateBackupCodesThunk(codeConfig))
            .then((response) => {
                dispatch(setLoader(false));
                if (response.payload) {
                    setCodes(response.payload);
                    setCodesCopied(false);
                    setCodesDoanloaded(false);
                }
            })
            .catch((error) => {
                dispatch(setLoader(false));
                console.error(error);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    return (
        <div className="flex min-h-[100vh] flex-col justify-center pb-10 sm:px-6 lg:px-8">
            <AuthCardLayout>
                {isLoading && <Loader />}
                <div>
                    <div>
                        <div className="text-center  mt-3  card-layout-title-text">Backup Codes</div>

                        <div>
                            <div className="px-5 mt-3 text-sm backup-text">
                                You have the option to copy or download the backup codes. These backup codes will be
                                necessary if you need to reset your Google 2FA later on.
                            </div>
                        </div>

                        <div className="main-codes  text-xl grid grid-cols-2 mx-20 mt-7 gap-16 shadow-md py-3">
                            <div ref={contentRef} className="justify-self-end ">
                                {firstLineCodes &&
                                    firstLineCodes.map((code) => <p key={code.user_backup_code_id}>{code.code}</p>)}
                            </div>
                            <div ref={secondContentRef} className=" ">
                                {lastLineCodes &&
                                    lastLineCodes.map((code) => <p key={code.user_backup_code_id}>{code.code}</p>)}
                            </div>
                        </div>

                        <div className="flex justify-center items-center gap-3 mt-7">
                            <button onClick={handleMerge} className=" blue-border-btn px-10 py-2 rounded-lg">
                                Copy Codes
                            </button>
                            <NotificationContainer />
                            <button onClick={handleDownload} className="btn-2 px-12 py-2 rounded-md">
                                Download
                            </button>
                        </div>

                        <div className="px-5 backup-text mt-5 text-sm">
                            You can utilize any one of the codes provided above to reset your Google 2FA.
                        </div>

                        <div className="flex justify-between items-center  mb-10 mt-8 mx-20">
                            <button
                                onClick={handleRegenrateCodes}
                                className="btn-2 px-5 cursor-pointer py-2 rounded-md"
                            >
                                Get New Codes
                            </button>{' '}
                            <button
                                disabled={!codesCopied && !codesDownloaded ? true : false}
                                onClick={() => navigate('/authentication-and-security')}
                                className={`btn-2 px-12  py-2 rounded-md ${
                                    !codesCopied && !codesDownloaded ? 'opacity-50' : ''
                                }`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </AuthCardLayout>
        </div>
    );
};

export default BackupCodesPage;
