import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { cn } from 'utills/tailwindUtil';
import { Portal } from '../Portal';

const DropdownMenu = ({
    children,
    icon,
    iconSx,
    className,
    extraProps,
    preventOpenTop = false,
    preventOnClickFuns = false,
    disabled = false
}) => {
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isDropUp, _setIsDropUp] = useState(false);
    const [show, setShow] = useState(true);

    const handleMenuToggle = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Function to handle child menu item clicks
    const handleMenuItemClick = (event, onClickAction = null) => {
        event.stopPropagation(); // Prevent event from bubbling up
        if (!onClickAction && preventOnClickFuns) {
            return false;
        }
        if (onClickAction) {
            onClickAction();
        }
        setShow(false);
        setTimeout(() => {
            setShow(true);
        }, 50);
        setIsOpen(false);
    };

    return (
        <>
            {show && (
                <Menu as="div" className="relative inline-block text-left">
                    <div ref={!disabled ? buttonRef : null}>
                        <Menu.Button
                            className={` ${
                                disabled && 'cursor-not-allowed'
                            } flex items-center rounded-full text-black hover:text-gray-600`}
                            {...extraProps?.button}
                            onClick={handleMenuToggle}
                        >
                            <span className="sr-only">Open options</span>
                            {icon ? (
                                icon
                            ) : (
                                <EllipsisVerticalIcon
                                    className={cn(iconSx ? iconSx : '', 'h-5 w-5')}
                                    aria-hidden="true"
                                />
                            )}
                        </Menu.Button>
                    </div>

                    {isOpen && !disabled && (
                        <Portal parentRef={buttonRef} isOpen={isOpen}>
                            <div ref={dropdownRef}>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className={cn(
                                            'absolute z-10 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                                            isDropUp && !preventOpenTop
                                                ? '!bottom-full mb-2 origin-bottom-left !right-0'
                                                : 'mt-2 !origin-top-left !right-0',
                                            className
                                        )}
                                    >
                                        <div className="py-1">
                                            {React.Children.map(children, (child) => {
                                                if (React.isValidElement(child)) {
                                                    return React.cloneElement(child, {
                                                        onClick: (event) =>
                                                            handleMenuItemClick(event, child.props.onClick)
                                                    });
                                                }
                                                return child;
                                            })}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </div>
                        </Portal>
                    )}
                </Menu>
            )}
        </>
    );
};

export default DropdownMenu;
