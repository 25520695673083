import React from 'react';

export const SpouseIcon = ({ className }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_12094_6617)" />
            <g clipPath="url(#clip0_12094_6617)">
                <path
                    d="M19.1211 10.0588C18.0546 10.2229 17.1015 10.7307 16.3554 11.5237C15.5351 12.3948 15.1093 13.4026 15.0507 14.6096C14.9687 16.2971 15.7968 17.9104 17.2265 18.844C17.9296 19.3049 18.6054 19.5393 19.4257 19.6057C20.9453 19.7229 22.2421 19.2463 23.289 18.1799C24.3125 17.1369 24.7695 15.8518 24.6445 14.3752C24.5351 13.1096 23.9765 12.0198 23.0078 11.1838C22.2421 10.5276 21.539 10.1994 20.5664 10.0588C20.0976 9.99242 19.5507 9.98851 19.1211 10.0588ZM20.7851 11.2854C21.3984 11.4416 21.8593 11.7112 22.375 12.2073C23.0468 12.844 23.3867 13.5471 23.4765 14.4573C23.582 15.5354 23.25 16.5198 22.5195 17.301C22.0664 17.7854 21.3984 18.1955 20.832 18.3401C19.9921 18.551 18.9921 18.4612 18.2734 18.1057C17.2929 17.6213 16.5664 16.7151 16.3007 15.6448C16.1992 15.2346 16.2109 14.3088 16.3242 13.8869C16.625 12.7541 17.6093 11.7034 18.7031 11.3479C19.2968 11.1565 20.1836 11.1291 20.7851 11.2854Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.8"
                />
                <path
                    d="M15.5742 19.6914C14.9766 19.7539 14.3594 19.9805 13.8711 20.3086C12.6523 21.1367 11.8633 22.9883 11.6797 25.4492C11.5781 26.832 11.6602 27.5156 12.0156 28.2305C12.2109 28.6289 12.582 29.0664 12.9609 29.3477C13.25 29.5586 13.7344 29.7773 14.1602 29.8867L14.5117 29.9805H19.9805H25.4492L25.8008 29.8906C27.0352 29.5664 27.8984 28.7344 28.207 27.5586C28.3672 26.9531 28.3477 25.5078 28.1602 24.3672C27.75 21.8281 26.7109 20.3047 25.0664 19.8281C24.6914 19.7188 24.0195 19.6367 23.8203 19.6758C23.6289 19.7109 23.3633 19.8516 22.7617 20.2383C22.1133 20.6562 22.0117 20.7109 21.5273 20.8867C20.957 21.0938 20.5313 21.1758 20.0039 21.1758C19.457 21.1758 19.0352 21.0977 18.4805 20.9023C17.957 20.7148 17.9336 20.7031 17.1133 20.1836C16.6836 19.9102 16.3633 19.7344 16.2383 19.6992C16.0195 19.6445 16.0195 19.6445 15.5742 19.6914ZM16.5898 21.2344C17.3242 21.707 17.6328 21.8594 18.2773 22.0703C19.957 22.6211 21.6719 22.3555 23.2891 21.2969C24.0195 20.8164 24.0039 20.8242 24.3125 20.8633C25.7305 21.043 26.6172 22.2344 26.9922 24.4727C27.1445 25.3945 27.1914 26.6797 27.0898 27.168C26.9258 27.9531 26.3945 28.5078 25.582 28.7383C25.3477 28.8047 25.0391 28.8086 19.9805 28.8086C14.9102 28.8086 14.6172 28.8047 14.375 28.7383C13.793 28.5742 13.2891 28.1758 13.0547 27.6953C12.8516 27.2812 12.8008 26.9336 12.832 26.0938C12.8594 25.2461 12.8984 24.8516 13.0352 24.1602C13.1367 23.6211 13.375 22.8555 13.543 22.5234C14.0469 21.5234 14.7305 20.9766 15.6172 20.8633C15.7617 20.8438 15.8945 20.8281 15.9102 20.8242C15.9258 20.8242 16.2305 21.0078 16.5898 21.2344Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.8"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_12094_6617"
                    x1="0.618555"
                    y1="3.19416e-05"
                    x2="39.8035"
                    y2="1.58737"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#101828" />
                </linearGradient>
                <clipPath id="clip0_12094_6617">
                    <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                </clipPath>
            </defs>
        </svg>
    );
};
