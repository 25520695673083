import React from 'react';

export const SingleUserIcon = () => {
    return (
        <svg width="15" height="20" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.49968 0C6.28287 0 3.67248 2.57334 3.67248 5.75098C3.67248 8.92862 6.28287 11.502 9.49968 11.502C12.7165 11.502 15.3269 8.92862 15.3269 5.75098C15.3269 2.57334 12.7165 0 9.49968 0ZM5.52896 5.75098C5.52896 3.58933 7.30543 1.83464 9.49968 1.83464C11.6939 1.83464 13.4704 3.58933 13.4704 5.75098C13.4704 7.91263 11.6939 9.66732 9.49968 9.66732C7.30543 9.66732 5.52896 7.91263 5.52896 5.75098ZM3.37722 14.498C1.51356 14.498 0 15.9891 0 17.8317V19.7033C0 21.8154 0.841711 23.3676 2.43782 24.4059C4.0534 25.4569 6.47132 26 9.6471 26C12.834 26 15.235 25.4494 16.8006 24.3901C18.3441 23.3458 19.1084 21.7851 18.9876 19.6562L18.9871 19.6479L18.989 17.8317C18.9887 15.9893 17.4754 14.498 15.6118 14.498H3.37722ZM1.85648 17.8317C1.85648 17.005 2.5362 16.3327 3.37722 16.3327H15.6118C16.4528 16.3327 17.1326 17.005 17.1326 17.8317L17.1323 19.6939L17.1329 19.7053C17.1721 20.4365 17.0623 21.0938 16.7766 21.6664C16.4883 22.2444 16.0299 22.7175 15.4041 23.0887C14.1658 23.8233 12.2613 24.1654 9.6471 24.1654C7.02018 24.1654 5.08241 23.8146 3.79286 23.0788C3.14273 22.7078 2.65437 22.2372 2.33041 21.6645C2.00689 21.0926 1.85648 20.4352 1.85648 19.7033V17.8317Z"
                fill="white"
            />
        </svg>
    );
};
