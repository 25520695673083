import React from 'react';

import StepSix from '../../components/signup-steps/StepSix';
export const TermsAndConditions = ({ handleCondition }) => {
    return (
        <div>
            <StepSix handleCondition={handleCondition} />
        </div>
    );
};
