/* eslint-disable react-hooks/exhaustive-deps */
import { AppCards } from 'components/molecules/AppCards';
import { Jumbotron } from 'components/organisms/Jumbotron';
import { SidebarLayout } from 'layouts/SidebarLayout';
import React, { useEffect, useState } from 'react';
import XeroSvg from 'files/xero.png';
import OutlookSvg from 'files/outlook.png';
// import OutlookSvg2 from 'files/outlook2.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllConnectedTenantsThunk } from 'store/appConnectoins';
import { setLoader } from 'store/global/globalReducer';
import { Loader } from 'components/common/Loader';
import { RoleBasedGuard } from 'layouts/RoleBasedGuard';
import useRoleGuard from 'Hooks/useRoleGuard';

export const AppConnections = () => {
    const [appsList, setAppsList] = useState(null);
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.global);

    const { hasPermissions } = useRoleGuard();

    useEffect(() => {
        if (hasPermissions('App_connections', ['read', 'write', 'admin'])) {
            dispatch(setLoader(true));
            dispatch(fetchAllConnectedTenantsThunk()).then((res) => {
                if (res.payload) {
                    setAppsList(res.payload);
                }
                dispatch(setLoader(false));
            });
        }
    }, []);

    return (
        <div>
            <SidebarLayout>
                {isLoading && <Loader />}
                <Jumbotron
                    title="App Connections"
                    path={[
                        {
                            link: 'settings',
                            title: 'Settings'
                        },

                        {
                            link: '',
                            title: 'App Connections'
                        }
                    ]}
                />

                <RoleBasedGuard module={'App_connections'} permissions={['read', 'write', 'admin']} sx="h-[50vh]">
                    {appsList && (
                        <div className="md:mx-10 mx-5 mt-5">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                                <AppCards
                                    title="Xero"
                                    text="Cloud accounting for easy invoicing."
                                    iconSet={{ icon: XeroSvg }}
                                    isConnected={appsList.length > 0 && appsList[0]?.status === 'active' ? true : false}
                                    link={
                                        appsList.length > 0 && appsList[0]?.status === 'active'
                                            ? '/app-connections/xero/connected'
                                            : '/app-connections/xero'
                                    }
                                />

                                <AppCards
                                    title="Outlook Calendar"
                                    text="Easily stay on top of your outlook calendar appointments"
                                    iconSet={{ icon: OutlookSvg }}
                                    isConnected={appsList.length > 0 && appsList[1]?.status === 'active' ? true : false}
                                    link={
                                        appsList.length > 0 && appsList[1]?.status === 'active'
                                            ? '/app-connections/microsoft/connected'
                                            : '/app-connections/microsoft'
                                    }
                                />
                                {/* <AppCards
                                    title="Outlook Messages"
                                    text="Easily stay on top of your Outlook Messages"
                                    iconSet={{ icon: OutlookSvg2 }}
                                    isConnected={appsList.length > 0 && appsList[1]?.status === 'active' ? true : false}
                                    link={
                                        appsList.length > 0 && appsList[1]?.status === 'active'
                                            ? '/app-connections/outlook/connected'
                                            : '/app-connections/outlook'
                                    }
                                /> */}
                            </div>
                        </div>
                    )}
                </RoleBasedGuard>
            </SidebarLayout>
        </div>
    );
};
