import generateThunkAndSlice from 'store/thunk/thunk';
import { endpoint } from 'apiEndpoints';
import { methods } from 'apiEndpoints';

const { request: createTelephonicDirectoryThunk } = generateThunkAndSlice(
    'createTelephonicDirectory',
    endpoint.createTelephonicDirectory,
    methods.POST
);

const { slice: getAllTelephonicDirectories, request: getAllTelephonicDirectoriesThunk } = generateThunkAndSlice(
    'getAllTelephonicDirectories',
    endpoint.getAllTelephonicDirectories,
    methods.POST
);

const { request: archiveTelephonicDirectoryThunk } = generateThunkAndSlice(
    'archiveTelephonicDirectory',
    endpoint.archiveTelephonicDirectory,
    methods.POST
);

const { request: updateDirectoryThunk } = generateThunkAndSlice(
    'updateDirectory',
    endpoint.updateDirectory,
    methods.POST
);

export {
    createTelephonicDirectoryThunk,
    getAllTelephonicDirectories,
    getAllTelephonicDirectoriesThunk,
    archiveTelephonicDirectoryThunk,
    updateDirectoryThunk
};
