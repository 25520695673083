import Breadcrumb from 'components/atoms/Breadcrumb';
import React from 'react';

export const TasksCompletedJumbo = ({ path }) => {
    return (
        <div>
            <div className="profle-jumbo ann-jumbo  app-jumbo md:flex md:flex-row flex-col px-10 py-10 md:justify-between ">
                <div className="profile-jumbo-flex">
                    <div className="jumbo-flex-1 ">
                        <div className="flex justify-between">
                            <div className="page-title font-secondary">Tasks Completed</div>
                        </div>
                        {path ? (
                            <Breadcrumb
                                path={[
                                    {
                                        title: 'Tasks',
                                        link: 'tasks'
                                    },
                                    {
                                        title: 'Completed Tasks',
                                        link: ''
                                    }
                                ]}
                            />
                        ) : (
                            <div className="jumbo-dir mt-2">
                                Workspace <span className="special-jumbo-text">&gt; Tasks</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
