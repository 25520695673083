import React from 'react';

import AuthSuccessPage from '../../../../components/dashboard-components/auth/AuthSuccessPage';
const AuthSuccess = () => {
    return (
        <div>
            <AuthSuccessPage />
        </div>
    );
};

export default AuthSuccess;
